import * as React from "react"

export const Rarrow3 = (props) => {

    const {
        width = "15.459",
        height = "22.151",
        color="#6f6f6f"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 15.459 22.151"
        >
                <path id="icons8-right" d="M116.483,71.954a.537.537,0,0,1-.38-.157l-3.846-3.846a.537.537,0,0,1,0-.76l6.312-6.312-6.312-6.312a.537.537,0,0,1,0-.76l3.846-3.846a.537.537,0,0,1,.76,0L127.4,60.5a.537.537,0,0,1,0,.76L116.863,71.8A.537.537,0,0,1,116.483,71.954ZM113.4,67.571l3.086,3.086,9.779-9.779L116.483,51.1,113.4,54.186l6.312,6.312a.537.537,0,0,1,0,.76Z" transform="translate(-112.1 -49.803)" 
                    fill={color}
                />
        </svg>
    );
};
