import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || "#9297a5",
  })
}))

export const AdministrationNewIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon fontSize={'small'} >
      {/* <defs>
        <style>
          .cls-1{fill:#9297a5}
        </style>
      </defs> */}
      <g id="Administration" transform="translate(-3 -3)">
        <path id="Path_551" d="M12 3a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm3.507 6.74L14 18.473l-1.964-3.42zm-7.014 0L10 18.473l1.964-3.42z" className={classes.cls1} data-name="Path 551" />
        <path id="Path_552" d="M3 18.5V21h18v-2.5c0-2.954-5.859-4.5-9-4.5s-9 1.546-9 4.5zm2 0c0-.869 3.708-2.5 7-2.5s7 1.631 7 2.5v.5H5z" className={classes.cls1} data-name="Path 552" />
      </g>
    </SvgIcon >
  )
}

AdministrationNewIcon.propTypes = {
  color: PropTypes.string,
};