import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { isNumberKey, isNumberKeyDown } from "../../utils/validations";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
  },

  input: {
    borderRadius: 8,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: `1.8px solid ${theme.palette.secondary.light}`,
    fontSize: 16,
    width: "100%",
    padding: (props) => (props.padding ? props.padding : "12px 12px"),
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),

    "&:focus": {
      border: `1.8px solid ${theme.palette.ternary.main}`,
      // borderColor: theme.palette.primary.dark,
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& .MuiInputBase-multiline": {
      padding: 0,
    },
    // height:100
  },
  inputerror: {
    "& input": {
      border: `1.8px solid ${theme.palette.error.light} `,
    },
    "& textArea": {
      border: `1.8px solid ${theme.palette.error.light} `,
    },
  },

  disabled: {
    pointerEvents: "none",
    cursor: "not-allowed"
  },

  margin: {
    margin: "8px 0px 8px 0px",
    // marginTop: 0,
    "& .MuiFormLabel-root": {
      color: theme.palette.secondary.main,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
}));

export function Bmi(props) {
  const classes = useStyles();

  return (
    // <form className={classes.root} noValidate style={{background:'white'}}>

    <FormControl
      className={`${classes.margin} ${classes.root}`}
    // style={{ ...props.style }}
    >
      {props.title &&
        (props.withBoldLabel ? (
          <Typography variant="h6" style={{ marginBottom: 8 }}>
            {props.title}
          </Typography>
        ) : (
            <InputLabel shrink htmlFor="bootstrap-input">
              {props.withoutUppercase ? props.title : props.title.toUpperCase()}
            </InputLabel>
          ))}
      <BootstrapInput
        id={props.id}
        multiline={props.multiline}
        rows={props.rowsMax}
        disabled={props.disabled}
        // onKeyPress={props.handleOnEnter}
        onKeyPress={(event) => {
          isNumberKey(event, props.type === "number" ? true : false);
          // isTextKeyDown(event, props.onlyText? true : false)
          if (props.handleOnEnter) props.handleOnEnter(event);
        }}
        onKeyDown={(event) => {
          // isTextKeyDown(event, props.onlyText? true : false)
          isNumberKeyDown(event, props.type === "number" ? true : false);
        }}
        autoComplete="off"
        onInvalid={(e) => {
          e.preventDefault();
        }}
        onChange={(e) => {
          props.handleOnChange(e, props.limit);
        }}
        value={props.value}
        required
        type={props.type}
        placeholder={props.placeholder}
        error={true}
        className={`${props.error ? classes.inputerror : ""} ${props.disabled ? classes.disabled : ''}`}
        {...props}
      />
      {props.error && (
        <Typography
          variant="body1"
          component="div"
          className={classes.errorText}
        >
          {props.errorText}
        </Typography>
      )}
    </FormControl>

    // </form>
  );
}

// Specifies the default values for props:
Bmi.defaultProps = {
  type: "text",
  error: false,
  rowsMax: 1,
  multiline: false,
};

// Specifies the required props for the component:
Bmi.propTypes = {
  style: PropTypes.object,
  multiline: PropTypes.bool,
  rowsMax: PropTypes.number,
  handleOnEnter: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
};
