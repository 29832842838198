import React from 'react'
import { withStyles, Grid } from '@material-ui/core'
import Monitor from './monitor'
const styles = (theme) => ({
  root: {
    background: 'white',
    borderRadius: 8,
    padding: 30,
  },
  // noBottomRadius: {
  //   background: 'white',
  //   borderTopLeftRadius: 8,
  //   borderTopRightRadius: 8,
  //   padding: 30,
  // }
})

class MonitorCard extends React.Component {
  render() {
    const { classes, data } = this.props
    return (
      <div className={classes.root} >
        <Grid container direction="row" >
          <Monitor icons={this.props.icons} data={data} />
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(MonitorCard)
