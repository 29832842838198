import * as React from "react"

export const TriangleIcon = (props) => {

    const {
        width = "23",
        height = "20",
        color="#6f6f6f"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 23 20"
        >
            <g id="Polygon_47" data-name="Polygon 47" fill="#fff">
                <path d="M 22.1357364654541 19.5 L 0.8642629981040955 19.5 L 11.5 1.003066062927246 L 22.1357364654541 19.5 Z" stroke="none" />
                <path d="M 11.5 2.006139755249023 L 1.728530883789062 19 L 21.27146911621094 19 L 11.5 2.006139755249023 M 11.5 0 L 23 20 L 0 20 L 11.5 0 Z" stroke="none" fill={color} />
            </g>
        </svg>
    );
};


// import * as React from "react";
// import { SvgIcon } from "@material-ui/core";

// export const TriangleIcon = (props) => {
    
//     return (
//         <SvgIcon
//             width="1em"
//             height="1em"
//             viewBox="0 0 24 24"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//             {...props}
//         >
//             <path d="M18.681 17.7H5.92L12.3 6.602 18.681 17.7Z" fill="#fff" />
//             <path
//                 d="M12.3 7.204 6.437 17.4h11.726L12.3 7.204ZM12.3 6l6.9 12H5.4l6.9-12Z"
//                 fill="#6F6F6F"
//             />
//         </SvgIcon>
//     );
// }

