import React from "react";
import { Paper, Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import { ICONS } from "../../../../components/hybridTypography/typography";
import { HybridTypography } from "../../../../components";

const useStyles = makeStyles((theme) => ({
  cardPaper: {
    // marginBottom: "12px",
    borderRadius: "16px",
    boxShadow: theme.palette.primary.boxShadow,
  },
  configurationWrapper: {
    borderRadius: "16px",
    padding: theme.spacing(1.5, 4, 4),
  },
}));

export function ServiceConfigurationCard({ serviceDetail: data }) {
  const classes = useStyles();
  const theme = useTheme()

  const getModeOfActivityString = (modeOfActivity) => {
    
    return modeOfActivity.map((x) => (x?.label ? x?.label : "N/A")).join(", ");
  };

  const durationCalculator = (duration) => {
    duration = parseInt(duration);
    if (duration) {
      if (duration === 60) {
        return "1 hr";
      } else if (duration > 60) {
        let hours = parseInt(duration / 60);
        let minutes = duration % 60;
        return `${hours > 0 ? `${hours} hrs` : ""}${minutes > 0 ? " " : ""}${
          minutes > 0 ? `${minutes} mins` : ""
        }`;
      } else {
        return `${duration} mins`;
      }
    } else {
      return "N/A";
    }
  };

  return (
    <Paper className={classes.cardPaper}>
      <div className={classes.configurationWrapper}>
        <Typography variant="h6">Configuration</Typography>
        <Grid container>
          {/* Service Type */}
          {data?.selectedServiceType && data?.selectedServiceType?.length > 0 && (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              // className={classes.bottomSectionContainer}
            >
              <HybridTypography
                icon={ICONS.BUSINESS(theme.palette.primary.main)}
                loading={false}
                text={
                  data?.selectedServiceType?.[0]?.label
                    ? data?.selectedServiceType?.[0]?.label
                    : "N/A"
                }
                header="SERVICE TYPE"
                islisttop={true}
              />
            </Grid>
          )}

          {/* Mode of activity */}
          {data?.modeOfActivity && data?.modeOfActivity?.length > 0 && (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              // className={classes.bottomSectionContainer}
            >
              <HybridTypography
                icon={ICONS.BUSINESS(theme.palette.primary.main)}
                loading={false}
                text={
                  data?.modeOfActivity?.length === 1
                    ? data?.modeOfActivity?.[0]?.label
                      ? data?.modeOfActivity?.[0]?.label
                      : "N/A"
                    : getModeOfActivityString(data?.modeOfActivity)
                }
                header="MODE OF ACTIVITY"
                islisttop={true}
              />
            </Grid>
          )}

          {/* RESOURCE TYPE */}
          {data?.resourceTypeCategory && <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <HybridTypography
              icon={ICONS.BUSINESS(theme.palette.primary.main)}
              loading={false}
              header="RESOURCE TYPE"
              isPopperType
              popperList={data?.resourceTypeCategory?.map(resourceType => resourceType?.label)}
              avoidNA
              islisttop={true}
            />
          </Grid>}

          {/* DURATION */}
          {data?.duration && (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              // className={classes.bottomSectionContainer}
            >
              <HybridTypography
                icon={ICONS.BUSINESS(theme.palette.primary.main)}
                loading={false}
                text={durationCalculator(data?.duration)}
                header="DURATION"
                islisttop={false}
                isListMiddle={true}
              />
            </Grid>
          )}

          {/* Bookable */}
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              // className={classes.bottomSectionContainer}
            >
              <HybridTypography
                icon={ICONS.BUSINESS(theme.palette.primary.main)}
                loading={false}
                text={data?.isBookable?"Yes":"No"}
                header="BOOKABLE"
                islisttop={false}
                isListMiddle={true}
              />
            </Grid>

          {/* allowToBundle */}
          <Grid
              item
              xs={12}
              sm={6}
              md={4}
              // className={classes.bottomSectionContainer}
            >
              <HybridTypography
                icon={ICONS.BUSINESS(theme.palette.primary.main)}
                loading={false}
                text={data?.allowToBundle?"Yes":"No"}
                header="ALLOW TO BUNDLE"
                islisttop={false}
                isListMiddle={true}
              />
            </Grid>
          <Grid
              item
              xs={12}
              sm={6}
              md={4}
              // className={classes.bottomSectionContainer}
            >
              <HybridTypography
                icon={ICONS.BUSINESS(theme.palette.primary.main)}
                loading={false}
                text={data?.allowToShow?"Yes":"No"}
                header="ALLOW TO SHOW"
                islisttop={false}
                isListMiddle={true}
              />
            </Grid>

          {/* PREASSESSMENT */}
          {/* {(data?.preAssessment && data?.preAssessment?.isChecked) && <Grid
            item
            xs={12}
            sm={6}
            md={4}
            // className={classes.bottomSectionContainer}
          >
            <HybridTypography
              icon={ICONS.BUSINESS(theme.palette.primary.main)}
              loading={false}
              header="PREASSESSMENT"
              isPopperType
              // popperTypeText={data?.preAssessment?.dropdown1Value?.label ? data?.preAssessment?.dropdown1Value?.label : "N/A"}
              // popperList={[data?.preAssessment?.dropdown2Value?.label ? data?.preAssessment?.dropdown2Value?.label : "N/A"]}
              popperList={Array?.isArray(data?.preAssessment?.dropdown1Value) ? data?.preAssessment?.dropdown1Value?.map(x => x?.label) : []}
              avoidNA
              islisttop={true}
            />
          </Grid>} */}

          {/* ASSESSMENT */}
          {(data?.assessment && data?.assessment?.isChecked) && <Grid
            item
            xs={12}
            sm={6}
            md={4}
            // className={classes.bottomSectionContainer}
          >
            <HybridTypography
              icon={ICONS.BUSINESS(theme.palette.primary.main)}
              loading={false}
              header="ASSESSMENT"
              isPopperType
              // popperTypeText={data?.assessment?.dropdown1Value?.label ? data?.assessment?.dropdown1Value?.label : "N/A"}
              // popperList={[data?.assessment?.dropdown1Value?.label ? data?.assessment?.dropdown2Value?.label : "N/A"]}
              popperList={Array?.isArray(data?.assessment?.dropdown1Value) ? data?.assessment?.dropdown1Value?.map(x => x?.label) : []}
              avoidNA
              islisttop={false}
              isListMiddle={true}
            />
          </Grid>}

          {/* Service Delivery Report */}
          {(data?.serviceDeliverables && data?.serviceDeliverables?.isChecked) && <Grid
            item
            xs={12}
            sm={6}
            md={4}
            // className={classes.bottomSectionContainer}
          >
            <HybridTypography
              icon={ICONS.BUSINESS(theme.palette.primary.main)}
              loading={false}
              header="SERVICE DELIVERY REPORT"
              isPopperType
              // popperTypeText={data?.serviceDeliverables?.dropdown1Value?.label ? data?.serviceDeliverables?.dropdown1Value?.label : "N/A"}
              // popperList={[
              //   data?.serviceDeliverables?.dropdown2Value?.label ? data?.serviceDeliverables?.dropdown2Value?.label : "N/A"
              // ]}
              popperList={Array?.isArray(data?.serviceDeliverables?.dropdown1Value) ? data?.serviceDeliverables?.dropdown1Value?.map(x => x?.label) : []}

              avoidNA
              islisttop={false}
              isListMiddle={true}
            />
          </Grid>}

          {/* Service Delivery */}
          {(data?.service_delivery && data?.service_delivery?.isChecked) && <Grid
            item
            xs={12}
            sm={6}
            md={4}
            // className={classes.bottomSectionContainer}
          >
            <HybridTypography
              icon={ICONS.BUSINESS(theme.palette.primary.main)}
              loading={false}
              header="SERVICE DELIVERY"
              isPopperType
              // popperTypeText={data?.service_delivery?.dropdown1Value?.label ? data?.service_delivery?.dropdown1Value?.label : "N/A"}
              // popperList={[
              //   data?.service_delivery?.dropdown2Value?.label ? data?.service_delivery?.dropdown2Value?.label : "N/A"
              // ]}
              popperList={Array?.isArray(data?.service_delivery?.dropdown1Value) ? data?.service_delivery?.dropdown1Value?.map(x => x?.label) : []}
              avoidNA
              islisttop={false}
              isListMiddle={true}
            />
          </Grid>}

          {/* PROMOTIONAL CONTENT */}
          {/* Hidden Temporarily on March 5th */}
          {/* <Grid
            item
            xs={12}
            sm={6}
            md={4}
            // className={classes.bottomSectionContainer}
          >
            <HybridTypography
              icon={ICONS.BUSINESS(theme.palette.primary.main)}
              loading={false}
              text={"content"}
              header="PROMOTIONAL CONTENT"
              islisttop={true}
            />
          </Grid> */}
        </Grid>
      </div>
    </Paper>
  );
}
