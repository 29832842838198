import React from "react";
import {
  Grid,
  withStyles,
  Typography,
  Chip,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { PopperListRenderer } from "./popperListRenderer";
import {
  UserIcon,
  CallIcon,
  EmailIcon,
  LocationIcon,
  ProductsIcon,
  UsersIcon,
  LinkIcon,
  OfferingsIcon,
  SkillsIcon,
  ClientAddressIcon,
  ClientBloodPressureIcon,
  ClientBMIIcon,
  ClientHeightIcon,
  ClientIDIcon,
  ClientMailIcon,
  ClientMedicalDiagnosisIcon,
  ClientOccupationIcon,
  ClientPersonIcon,
  ClientPhoneIcon,
  // ClientRelationIcon,
  ClientSpecialPrecautionsIcon,
  ClientWeightIcon,
  LandlineIcon,
  TermsAndConditionsIcon,
} from "../../assets";
import { Relation } from "../../assets/viewScreenIcons/Relation";
import { MailNew } from "../../assets/ClientDetailScreenIcons/mailNew";
import { Person } from "../../assets/viewScreenIcons/person";
import { Language } from "../../assets/AddEditScreenIcons/language";
import { ReceivedAndAssignedCall } from "../../assets/viewScreenIcons/ReceivedAndAssignedCall";
import Skeleton from "@material-ui/lab/Skeleton";
import BusinessIcon from "@material-ui/icons/Business";
import { IntelligenceIcon } from "../../assets";
import ViewUploadedFile from "../fileUploader/viewUploadedFile";
export const ICONS = {
  INTELLIGENCE: (colorCode) => {
    return (
      <IntelligenceIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `6px 8px 8px 0px`,
        }}
      />
    );
  },
  USER: (colorCode) => {
    return (
      <UserIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },
  RELATION: (colorCode) => {
    return (
      <Relation
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },

  MAILNEW: (colorCode) => {
    return (
      <MailNew
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `6px 8px 8px 0px`,
        }}
      />
    );
  },
  LANGUAGE: (colorCode) => {
    return (
      <Language
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },
  PERSON: (colorCode) => {
    return (
      <Person
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },
  RECEIVEDANDASSIGNEDCALL: (colorCode) => {
    return (
      <ReceivedAndAssignedCall
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },
  CALL: (colorCode) => {
    return (
      <CallIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },
  EMAIL: (colorCode) => {
    return (
      <EmailIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `10px 8px 8px 0px`,
        }}
      />
    );
  },
  LOCATION: (colorCode) => {
    return (
      <LocationIcon
        htmlColor={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },
  PRODUCT: (colorCode) => {
    return (
      <ProductsIcon
        htmlColor={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },
  USERS: (colorCode) => {
    return (
      <UsersIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },
  LINK: (colorCode) => {
    return (
      <LinkIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },
  OFFERING: (colorCode) => {
    return (
      <OfferingsIcon
        htmlColor={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },
  SKILL: (colorCode) => {
    return (
      <SkillsIcon
        htmlColor={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },
  CLIENTADDRESS: (colorCode) => {
    return (
      <ClientAddressIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `7px 8px 8px 0px`,
        }}
      />
    );
  },
  CLIENTBP: (colorCode) => {
    return (
      <ClientBloodPressureIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `7px 8px 8px 0px`,
        }}
      />
    );
  },
  CLIENTBMI: (colorCode) => {
    return (
      <ClientBMIIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `7px 8px 8px 0px`,
        }}
      />
    );
  },
  CLIENTHEIGHT: (colorCode) => {
    return (
      <ClientHeightIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `7px 8px 8px 0px`,
        }}
      />
    );
  },
  CLIENTID: (colorCode) => {
    return (
      <ClientIDIcon
        htmlColor={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `11px 8px 8px 0px`,
        }}
      />
    );
  },
  CLIENTMAIL: (colorCode) => {
    return (
      <ClientMailIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `7px 8px 8px 0px`,
        }}
      />
    );
  },
  CLIENTMD: (colorCode) => {
    return (
      <ClientMedicalDiagnosisIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `7px 8px 8px 0px`,
        }}
      />
    );
  },
  CLIENTOCCUPATION: (colorCode) => {
    return (
      <ClientOccupationIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `7px 8px 8px 0px`,
        }}
      />
    );
  },
  CLIENTPERSON: (colorCode) => {
    return (
      <ClientPersonIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `7px 8px 8px 0px`,
        }}
      />
    );
  },
  CLIENTPHONE: (colorCode) => {
    return (
      <ClientPhoneIcon
        htmlColor={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `7px 8px 8px 0px`,
        }}
      />
    );
  },

  CLIENTRELATION: (colorCode) => {
    return (
      // <ClientRelationIcon
      //   color={colorCode ? colorCode : "secondary"}
      //   style={{
      //     height: 16,
      //     width: 16,
      //     margin: `7px 8px 8px 0px`,
      //   }}
      // />
      <img
        src="/svg/Relation.svg"
        alt="relation"
        height="18px"
        width="18px"
        style={{ position: "relative", top: "8px", left: "-4px" }}
      />
    );
  },
  CLIENTSP: (colorCode) => {
    return (
      <ClientSpecialPrecautionsIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `7px 8px 8px 0px`,
        }}
      />
    );
  },
  BUSINESS: (colorCode) => {
    return (
      <BusinessIcon
        color={colorCode ? "" : "secondary"}
        htmlColor={colorCode ? colorCode : ""}
        style={{
          height: 16,
          width: 16,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },
  CLIENTWEIGHT: (colorCode) => {
    return (
      <ClientWeightIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 16,
          width: 16,
          margin: `7px 8px 8px 0px`,
        }}
      />
    );
  },
  LANDLINE: (colorCode) => {
    return (
      <LandlineIcon
        color={colorCode ? colorCode : "secondary"}
        // htmlColor={colorCode ? colorCode : ''}
        style={{
          height: 16,
          width: 16,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },
  TC: (colorCode) => {
    return (
      <TermsAndConditionsIcon
        color={colorCode}
        style={{
          height: 16,
          width: 16,
          margin: `8px 0px 0px 6px`,
        }}
      />
    );
  },
};

const styles = (theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "28px 1fr",
  },
  avatar: {
    borderRadius: 0,
    width: "18px",
    height: "18px",
    margin: "4px 0px 0px 5px",
  },
  secondary: {
    color: theme.palette.secondary.main,
    fontSize: "13px",
    paddingTop: "4px",
  },
  user_name: {
    display: "inline-block",
    paddingTop: 4,
    fontSize: "1rem",
  },
  stringlist: {
    paddingTop: 4,
    fontSize: "1rem",
  },
  bigFont: {
    fontSize: "1.25rem",
    fontWeight: 500
  },
  chip: {
    // borderRadius: "4px",
    // marginRight: "10px",
    // marginBottom: "5px",
  },
  fontsize10: {
    fontSize: "10px",
    color: "white",
  },
  sectionLabel: {
    marginBottom: "10px",
    marginTop: "10px",
    fontSize: "19px",
  },
  chipRoot: {
    paddingTop: "7px",
  },
  marginBottom: {
    marginBottom: "26px",
  },
  marginTop: {
    marginTop: "26px",
  },
  isListMiddle: {
    marginTop: 8,
    marginBottom: 8
  }
});

const StyledChip = withStyles((props) => {
  const theme = useTheme();
  return {
    root: {
      height: 24,
      backgroundColor: theme.palette.chip.main,
      // backgroundColor: "#D7E2E9",
      color: "unset",
      borderRadius: 8,
      // marginLeft: 5,
    },
  };
})((props) => <Chip {...props} />);
class HybridTypography extends React.Component {
  render() {
    const {
      classes,
      theme,
      header,
      text,
      list,
      islistbottom,
      isListMiddle,
      islisttop,
      stringlist,
      icon,
      loading,
      avoidNA,
      textVariant,
      isSecondary,
      nolabel,
      isdangerouslySetInnerHTML,
      isPopperType,
      popperTypeText,
      popperList,
      handleViewMore,
      isnogrid,
      bigFont,
      isbigFont,
      link,
      extension,
      isTextAComponent
    } = this.props;
    //

    console.log("\n\n")
    console.log(header)
    console.log(this.props?.id)

    return (
      <Grid
        item
        zeroMinWidth
        className={
          ` ${islistbottom ? classes.marginBottom : ""} ${isListMiddle ? classes.isListMiddle : ""} ${islisttop ? classes.marginTop : ""} ${isnogrid ? "" : classes.root} `
        }
      >

        {icon}
        {!loading && (
          <Grid item zeroMinWidth>
            {isSecondary ? (
              <>
                {text && (
                  <Typography
                    variant="h6"
                    className={`${classes.user_name} ${nolabel ? classes.fontsize10 : ""
                      }`}
                  >
                    {text}
                  </Typography>
                )}
                {header && (
                  <Typography className={`${bigFont ? classes.bigFont : ""} ` + classes.secondary}>
                    {header}
                  </Typography>
                )}
              </>
            ) : (
              <>
                {header && (
                  <React.Fragment>
                    {bigFont ? <Typography
                      variant="h6"
                      component="h6"
                      className={`${isbigFont ? classes.sectionLabel : ''}`}
                    >
                      {header}
                    </Typography> :
                      <Typography className={classes.secondary}>
                        {header}
                      </Typography>}

                  </React.Fragment>
                )}

                {text &&
                  (text && isdangerouslySetInnerHTML ? (
                    <Typography
                      variant="h6"
                      style={{ color: bigFont ? "#949393" : "" }}
                      className={`${classes.user_name} ${nolabel ? classes.fontsize10 : ""
                        }`}
                      dangerouslySetInnerHTML={{
                        __html: `<div style="width: 100%;overflow-wrap: anywhere;">${text}</div>`,
                      }}
                    >
                      {/* {text} */}
                    </Typography>
                  )
                    :
                    text && link ?
                      !link?.length > 0 &&
                      //   <Typography
                      //   variant="a"
                      //   component="a"
                      //   target="_blank"
                      //   href={text}
                      // className={`${classes.user_name} ${nolabel ? classes.fontsize10 : ""
                      //   }`}
                      // >

                      //     Click to View

                      // </Typography>
                      <div className={`${classes.user_name} ${nolabel ? classes.fontsize10 : ""
                        }`}>

                        <ViewUploadedFile id={this.props?.id} fileUrl={text} fileExtension={extension} />
                      </div>
                      : isTextAComponent ?
                        <div >
                          {text}
                        </div>
                        : (
                          <Typography
                            variant="h6"
                            className={`${classes.user_name} ${nolabel ? classes.fontsize10 : ""
                              }`}
                          >
                            {text ? text : "N/A"}
                          </Typography>
                        ))}
                {isPopperType && (
                  <div>
                    <Grid container>
                      {popperTypeText && (
                        <Grid item>
                          <Typography
                            variant="body1"
                            style={{ marginRight: 8 }}
                          >
                            {popperTypeText}
                          </Typography>
                        </Grid>
                      )}
                      {popperList && (
                        <Grid item style={{ display: "flex" }}>
                          <div style={{ width: "100%", paddingTop: 4, display: "flex", lineHeight: 1.6, gap: 8 }}>
                            <StyledChip label={popperList[0]} />
                            {popperList?.length > 1 && (
                              <PopperListRenderer
                                popperList={[
                                  ...popperList.slice(1, popperList?.length),
                                ]}
                                count={popperList?.length - 1}
                              />
                            )}
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                )}
              </>
            )}
            {list && (
              <Grid item className={classes.chipRoot}>
                {list.slice(0, 1).map((val, i) => (
                  <>
                    <StyledChip key={i} className={classes.chip} label={val} />
                    {list?.length > 1 && (
                      <Typography
                        id={this.props?.id}
                        variant="caption"
                        // color={theme.palette.ternary.main}
                        style={{
                          marginLeft: 10,
                          textTransform: "upperCase",
                          cursor: "pointer",
                          color: theme.palette.ternary.main
                        }}
                        onClick={handleViewMore}
                      >
                        View More
                      </Typography>
                    )}
                  </>
                ))}
              </Grid>
            )}
            {list && list?.length === 0 && (
              <Typography
                variant={textVariant ? textVariant : "h6"}
                className={classes.stringlist}
              >
                {"N/A"}
              </Typography>
            )}
            {stringlist && (
              <Grid item className={classes.chipRoot}>
                {stringlist.map((val, i) => (
                  <Typography
                    key={i}
                    variant={textVariant ? textVariant : "h6"}
                    className={classes.stringlist}
                  >
                    <span style={{ paddingRight: "4px" }}>&#8226;</span>
                    {val}
                  </Typography>
                ))}
                {stringlist?.length === 0 && (
                  <Typography
                    variant={textVariant ? textVariant : "h6"}
                    className={classes.stringlist}
                  >
                    {"N/A"}
                  </Typography>
                )}
              </Grid>
            )}


            {!text && !list && !stringlist && !avoidNA && (
              <Typography variant="h6" className={classes.user_name}>
                {"N/A"}
              </Typography>
            )}
          </Grid>
        )}
        {loading && (
          <Grid item>
            {header && (
              <Typography className={classes.secondary}>{header}</Typography>
            )}
            {loading && (
              <Skeleton
                variant="text"
                className={classes.user_name}
                style={{ width: "150px" }}
              />
            )}
          </Grid>
        )}
        {/* {JSON.stringify(link)} */}
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HybridTypography);

HybridTypography.propTypes = {
  /*
   * Set the variant of the string list array
   */
  textVariant: PropTypes.string,
};
