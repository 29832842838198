import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || "#9297a5",
  }),
  cls2: (props) => ({
    fill: "none",
    stroke: props.color || "#9297a5",
    strokeMiterlimit: 10,
    strokeWidth: 2
  })
}))

export const HelpNewIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon fontSize={'small'} >
      <g id="Help" transform="translate(-2 -2)">
        <path id="Path_558" className={classes.cls2} d="M12 3a9 9 0 1 0 9 9 9 9 0 0 0-9-9z" data-name="Path 558" />
        <path id="Path_559" className={classes.cls1} d="M12 6a4 4 0 0 0-4 4h2a2 2 0 0 1 4 0c0 2-3 2.367-3 5h2c0-1.651 3-2.5 3-5a4 4 0 0 0-4-4zm-1 10h2v2h-2z" data-name="Path 559" />
      </g>
    </SvgIcon >
  )
}

HelpNewIcon.propTypes = {
  color: PropTypes.string,
};