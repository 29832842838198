import React from "react";
import ReportComponent from "./report";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "tanyacare-middleware";

const { GET_ENQUIRY_REPORT } = actions;

const EnquiryReport = () => {
  const ref = React.useRef(null);
  const [fromDate, setFrom] = React.useState();
  const [toDate, setTo] = React.useState();
  const [applied, setApplied] = React.useState(false);

  const dispatch = useDispatch();

  const getData = useSelector(
    (state) => state?.reportManagementSlice?.getEnquiry
  );

  const type = {
    "Date and Time": {
      type: "string",
    },
    "User Type": {
      type: "string",
    },
    "Enquiry Id": {
      type: "string",
    },
    "Enquiry Source": {
      type: "string",
    },
    "Client Name": {
      type: "string",
    },
    "Client Id": {
      type: "string",
    },
    "Client Email": {
      type: "string",
    },
    "Service Group": {
      type: "string",
    },
    "Subscription Id": {
      type: "string",
    },
    "Enquiry Status": {
      type: "string",
    },
  };

  const getFormattedData = (getData) => {
    return {
      ...getData,
      data: getData?.data?.map((x) => {
        return {
          ...x,
          "Date and Time": moment(new Date(x["Date and Time"])).format("DD.MM.YYYY hh:mm A"),
        };
      }),
    };
  };

  const fetchData = () => {
    dispatch(
      GET_ENQUIRY_REPORT({
        fromDate: moment(fromDate).startOf("day").toISOString(),
        toDate: moment(toDate).endOf("day").toISOString(),
      })
    );
  };
  return (
    <ReportComponent
      type={type}
      getData={getFormattedData(getData)}
      fromDate={fromDate}
      toDate={toDate}
      fetchData={fetchData}
      applied={applied}
      setApplied={setApplied}
      setTo={setTo}
      setFrom={setFrom}
      tableRef={ref}
    />
  );
};
export default EnquiryReport;
