import * as React from "react"

export const CustomChoose = (props) => {
 let width = props.width ? props.width : "36.233";
 let height = props.height ? props.height : "41.21";
 let color = props.color ? props.color : "#0063e7";
 return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36.233 41.21">
      <path id="icons8-choose" d="M9.474,4A3.478,3.478,0,0,0,6,7.474V17.4a3.478,3.478,0,0,0,3.474,3.474h3.474a5.914,5.914,0,0,1,.806-2.978H9.474a.5.5,0,0,1-.5-.5V7.474a.5.5,0,0,1,.5-.5H19.4a.5.5,0,0,1,.5.5v7.534a5.934,5.934,0,0,1,2.978,1.439V7.474A3.478,3.478,0,0,0,19.4,4ZM28.333,4a3.5,3.5,0,0,0-3.474,3.474V17.4a3.5,3.5,0,0,0,3.474,3.474h9.926A3.5,3.5,0,0,0,41.733,17.4V7.474A3.5,3.5,0,0,0,38.259,4Zm0,2.978h9.926a.474.474,0,0,1,.5.5V17.4a.474.474,0,0,1-.5.5H28.333a.474.474,0,0,1-.5-.5V7.474A.474.474,0,0,1,28.333,6.978ZM17.386,8.949a1.489,1.489,0,0,0-1.024.45l-2.918,2.918-.933-.932a1.489,1.489,0,1,0-2.105,2.105l1.985,1.985a1.489,1.489,0,0,0,2.105,0l3.97-3.97a1.489,1.489,0,0,0-1.082-2.555ZM18.9,16.9a3.993,3.993,0,0,0-3.97,3.97v8.772c-.5.695-1.1,1.526-1.661,2.46a6.283,6.283,0,0,0-1.316,3.41,7.683,7.683,0,0,0,1.574,4.226,23.508,23.508,0,0,0,2.7,3.263v0a5.62,5.62,0,0,0,4.982,1.6l5.923-1.026A6.142,6.142,0,0,0,31.8,39.772c.649-1.658,1.417-3.65,1.747-4.8v0A6.759,6.759,0,0,0,28.6,26.75a1.237,1.237,0,0,0-.169-.039l-.238-.052-.8-.169L25.053,26l-2.179-.457V20.874A3.993,3.993,0,0,0,18.9,16.9Zm0,2.978a.97.97,0,0,1,.993.993V26.75a1.489,1.489,0,0,0,1.185,1.456l3.36.706,2.34.492.791.167.229.05c.054.012.173.043.033.006a3.779,3.779,0,0,1,2.856,4.525c-.232.808-1.018,2.9-1.658,4.536a3.154,3.154,0,0,1-2.4,1.964l-5.923,1.026a2.633,2.633,0,0,1-2.342-.752,21.075,21.075,0,0,1-2.359-2.836,5.733,5.733,0,0,1-1.072-2.573c0,.183.318-.921.892-1.879s1.3-1.981,1.8-2.654a1.489,1.489,0,0,0,.291-.884V20.874A.97.97,0,0,1,18.9,19.881Z" 
        transform="translate(-5.75 -3.75)"
        fill={color}
        stroke={color}
        stroke-width="0.5"
        opacity="0.55"
    />
    </svg>
  );
};
