import React from "react";
import { Grid, makeStyles, Divider } from "@material-ui/core";
import { QuickListMobileView } from "./quickListMobileView";
import { QuickList } from "./quickList";

const useStyles = makeStyles((theme) => ({
  mobileViewSpacing: {
    padding: theme.spacing(1),
  },
  desktopScreenWrapper: {
    display: "flex",
    alignItems: "center",
  },
  dividerWrapper: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    // height: 36,
  },
}));

export function QuickListWrapper({
  isSmAndDown,
  isBetween420And600,
  isBelow420,
  isSmOnly,
  orderList,
  orderListStyle,
  loading,
  handleItemEdit,
  handleItemDeactivate,
  status,
  isQuickAccessNeeded,
  ...props
}) {
  const classes = useStyles(props);
  console.log(props?.isLabelNeeded)
  return (
    <>
      {isSmAndDown &&
        orderList?.map((item, index) => (
          <>
            {/* <Grid
              item
              xs={
                isBetween420And600 && orderList?.length > 1
                  ? 6
                  : isSmOnly && orderList?.length > 1
                  ? 4
                  : 12
              }
              className={classes.mobileViewSpacing}
            >
              <div style={{ display: "flex", gap: 10 }}>
                <QuickListMobileView item={item} loading={loading} />
                <Divider orientation={"vertical"} flexItem />
              </div>
            </Grid> */}

            <Grid item xs={!isBelow420 ? true : 12}>
              <QuickListMobileView item={item} loading={loading} />
            </Grid>
            {!isBelow420 && orderList?.length - 1 !== index && (
              <Divider orientation={"vertical"} flexItem />
            )}
            {isBelow420 && orderList?.length - 1 !== index && (
              <Grid item xs={12} style={{display: "flex", justifyContent: "center", }}>
                <Divider style={{maxWidth: 30, width: 30, margin: "6px 0px"}}  />
              </Grid>
            )}
          </>
        ))}
      {!isSmAndDown && (
        <Grid item xs className={classes.desktopScreenWrapper}>
          <QuickList isLabelNeeded={props?.isLabelNeeded ? props?.isLabelNeeded : true} list={orderList} loading={loading} handleItemEdit={handleItemEdit} handleItemDeactivate={handleItemDeactivate} status={status} isQuickAccessNeeded={isQuickAccessNeeded} />
        </Grid>
      )}
    </>
  );
}
