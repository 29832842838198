import React from 'react'
import { useTheme, withStyles, Chip } from "@material-ui/core";

export const CustomChip = withStyles((props) => {
  const theme = useTheme()
  return {
    root: {
      height: 24,
      backgroundColor: theme.palette.chip.main,
      // backgroundColor: "#D7E2E9",
      color: "unset",
      borderRadius: 8,
    },
  };
})((props) => <Chip {...props} />);