import React from 'react'

import RecommendDetail from './detail'

export * from './recommendedEquipmentLoaders'
export * from './recommendedEquipmentErrors'

class RecommendCard extends React.Component {
  render() {
    return (
      <>
        <RecommendDetail {...this.props} />
      </>
    )
  }
}


export default RecommendCard
