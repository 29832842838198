import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: "none",
    stroke: props.color || "#9297a5",
    strokeMiterlimit: 10,
    strokeWidth: 2
  }),
  cls2: (props) => ({
    fill: props.color || "#9297a5",
  })
}))

export const LogoutNewIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon fontSize={'small'} >
      {/* <defs>
        <style>
          .cls-1{fill:none;stroke:#9297a5;stroke-miterlimit:10;stroke-width:2px}
        </style>
      </defs> */}
      <g id="Logout" transform="translate(-2 -2)">
        <path id="Path_562" d="M10 12h6" className={classes.cls1} data-name="Path 562" />
        <path id="Path_563" className={classes.cls2} d="M16 8l4 4-4 4z" data-name="Path 563" />
        <path id="Path_564" d="M12 3a9 9 0 1 0 9 9 9 9 0 0 0-9-9z" className={classes.cls1} data-name="Path 564" />
      </g>
    </SvgIcon >
  )
}

LogoutNewIcon.propTypes = {
  color: PropTypes.string,
};