import React from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
// import Typography from "material-ui/styles/typography";
import { CustomTypography } from "../customTypography";

const useStyles = makeStyles((theme) => ({
  oppositeContent: {
    flex: 0,
    padding: "6px",
  },
  connectorStyle: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export function CustomTimeline({
  events,
  status,
  transaction_id,
  amount,
  searchKey,
}) {
  const classes = useStyles();
  let arr_data = [];
  if (!status && events.length > 2 && events[0]?.message !== "Refund Not Approved") {
    arr_data = [{ message: "Refund Initiated" }];
  }
  arr_data.push(...events);
  return (
    <Timeline>
      {arr_data?.map((event, index) => (
        <TimelineItem>
          {/* {}  */}
          {/* {console.log(!((events.length-1) === index))} */}
          {console.log(events)}
          <TimelineOppositeContent
            className={classes.oppositeContent}
          ></TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color={"primary"} />
            {arr_data?.length - 1 === index ? (
              ""
            ) : (
              <TimelineConnector classes={{ root: classes.connectorStyle }} />
            )}
          </TimelineSeparator>
          <TimelineContent>
            {index === 0 ? (
              <>
                <CustomTypography
                  variant="row1"
                  weight="normal"
                  styles={{ fontSize: "1.1rem" }}
                >
                  {event?.[searchKey]}
                </CustomTypography>
               {amount ? <CustomTypography
                  variant="row2"
                  weight="normal"
                >{`Refund Amount - MYR: ${amount}`}</CustomTypography> : <></> }
                {transaction_id ? <CustomTypography
                  variant="row3"
                  weight="normal"
                >{`Transaction ID - ${transaction_id}`}</CustomTypography> :<></>}
              </>
            ) : (
              `${event?.[searchKey]}`
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}

CustomTimeline.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  searchKey: PropTypes.string,
};
