import React from "react";
import PropTypes from "prop-types";

import { SvgIcon } from "@material-ui/core";

function LocationIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="Group 95632">
        <path
          d="M11.716 2.5a6.49 6.49 0 0 0-6.478 6.478c0 1.6.947 3.372 2.02 5.137a50 50 0 0 0 3.1 4.442 1.74 1.74 0 0 0 2.718 0 50 50 0 0 0 3.1-4.442c1.073-1.765 2.02-3.54 2.02-5.137a6.49 6.49 0 0 0-6.48-6.478Zm0 1.619a4.846 4.846 0 0 1 4.858 4.858 11.357 11.357 0 0 1-1.785 4.3 48.81 48.81 0 0 1-2.99 4.287c-.054.069-.1.069-.158 0a48.808 48.808 0 0 1-2.994-4.291 11.357 11.357 0 0 1-1.785-4.3 4.846 4.846 0 0 1 4.854-4.854Zm0 3.239a1.619 1.619 0 1 0 1.619 1.619 1.619 1.619 0 0 0-1.619-1.619ZM5.09 16a1.891 1.891 0 0 0-1.727 1.122l-1.2 2.7a1.889 1.889 0 0 0 1.726 2.657h15.652a1.89 1.89 0 0 0 1.727-2.657l-1.2-2.7A1.891 1.891 0 0 0 18.342 16h-1.757q-.516.781-1.12 1.619h2.877a.27.27 0 0 1 .247.16l1.2 2.7a.269.269 0 0 1-.246.38H3.89a.27.27 0 0 1-.247-.38l1.2-2.7a.271.271 0 0 1 .247-.16h2.876q-.6-.839-1.119-1.619Z"
          fill={props.color}
          stroke={props.color}
          strokeWidth={0.25}
        />
        <path data-name="Rectangle 47999" fill="none" d="M0 0h24v24H0z" />
      </g>
    </SvgIcon>
  );
}

LocationIcon.propTypes = {
  color: PropTypes.string,
};

LocationIcon.defaultProps = {
  color: "#4a90e2",
};

export default LocationIcon;
