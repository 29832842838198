import React from "react";
import {
    withStyles,
    Grid,
    Typography,
    FormHelperText,
    IconButton
} from "@material-ui/core";
import withAlelrtAndDialog from "../../../HOCs/withAlelrtAndDialog";
// import EmailComponent from "../emailComponent";
import ViewAllCards from "./viewAllCard";
import { textClampStyleGenerator } from "../../../utils";
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import TaxComponent from "../taxComponent";
import { formatDate } from "../../../utils/common";


const styles = (theme) => ({
    title: {
        fontSize: "20px",
        // fontWeight: 600,
        textAlign: "left",
        marginBottom: 20,
        marginTop: 8,
    },
    add_title: {
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "left",
        // color: "#f27d35",
        color: theme.palette.ternary.main,
        textTransform: "uppercase",
        cursor: "pointer",
        width: "max-content",
    },
    detail_title: {
        fontSize: "0.8rem",
        fontWeight: "bold",
        textAlign: "left",
        // color: "#f27d35",
        color: theme.palette.ternary.main,
        textTransform: "uppercase",
        width: "max-content",
    },
    view_more_title: {
        fontSize: "0.8rem",
        fontWeight: "bold",
        color: theme.palette.ternary.main,
        textTransform: "uppercase",
        width: "max-content",
    },
    helperTextRoot: {
        color: "#bc2d4f",
        fontSize: "0.8rem",
    },
    listItemGutters: {
        paddingLeft: "0px"
    },
    primaryText: {
        fontWeight: 500,
        ...textClampStyleGenerator(2)
    },
    listTextPrimary: {
        fontWeight: "600",
    },
    listItemSecondaryAction: {
        paddingRight: 90
    },
    customListWrapper: {
        padding: "4px 16px 4px 0px"
    }
});

class ModernViewTaxCardDetail extends React.Component {

    state = {
        tax: []
    }

    componentDidMount() {
        this.executeMountInitialization()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.editData !== this.props.editData) {
            this.executeMountInitialization();
        }
    }

    executeMountInitialization = () => {
        if (this.props?.editData && Object.keys(this.props?.editData)?.length > 0) {

            this.setState({
                tax: [...this.props.editData]
            })
        }
    }


    onEdit = (editData) => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: (
                <TaxComponent
                    id={this?.props?.id}
                    editData={editData}
                    isEdit={true}
                    list={this.props.editData}
                    callBackOnSave={this.props.callBackOnSave}
                />
            ),
        });
    };

    handleOpenMoreDrawer = () => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: (
                <ViewAllCards
                    id={this?.props?.id}
                    items={this.props.editData}
                    onEdit={this.onEdit}
                    onRemove={this.props.callBackOnSave}
                    onAdd={() => this.props.handledrawer("Add Tax ID", null, this.props.editData)}
                />
            ),
        });
    }

    callBackOnSaveTax = (data, isremove) => {
        if (isremove) {
            let isUpdateData = this.state.tax?.filter(
                (val) => val.value !== data?.value
            );

            this.setState({ tax: isUpdateData }, () => {
                this.props.callBackOnSave(data, true)
            });
        }
    };

    render() {
        const {
            classes,
            handledrawer,
            // ondltList,
            // onchanged,
            // editData,
            // cardmd,
            // cardsm,
            disabled
        } = this.props;
        const { tax } = this.state
        return (
            <>
                {tax?.length > 0 ? (
                    <>
                        {/* <Typography className={classes.title}>{"ID Types"}</Typography> */}
                        <Grid container {...this.props?.breakPoints}>

                            {/* <Grid item xs={12}>
                                <Typography
                                    onClick={() => handledrawer("Add New Email Address", null, editData)}
                                    className={classes.add_title}
                                >
                                    {"+ Add Email Address"}
                                </Typography>
                            </Grid> */}
                            <Grid item container xs={12}>
                                <Grid item xs style={{ display: "flex", alignItems: "center" }}>
                                    {tax?.length >= 1 ? <Typography
                                        className={classes.detail_title}
                                    >
                                        {"Tax ID"}
                                    </Typography> : <Typography
                                        onClick={() => handledrawer("Add Tax ID", null, tax)}
                                        className={classes.add_title}
                                        id={this.props?.id?.add_tax_id}
                                    >
                                        {"+ Add Tax ID"}
                                    </Typography>}
                                </Grid>
                                <Grid item>
                                    <IconButton id={this.props?.id?.add_tax_icon_id} onClick={() => handledrawer("Add Tax ID", null, tax)} size={"small"} style={{ border: "1px solid #E8E8EC", margin: "0px 4px", padding: "4px" }}>
                                        <AddIcon fontSize={"small"} />
                                    </IconButton>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                {tax?.map((item, index) => {
                                    if (index < 1) {
                                        return (
                                            <div className={classes.customListWrapper} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <div style={{ flex: 1 }}>
                                                    <Typography variant={"body2"} >{item?.taxRegistrationNumber ? item?.taxRegistrationNumber : "NA"}</Typography>
                                                    <Typography variant={"body2"} color={"textSecondary"} >{item?.label ? item?.label : null}</Typography>
                                                    <Typography variant={"body2"} color={"textSecondary"} >{`${item?.effective ? formatDate(item?.effective) : "NA"} - ${item?.expiry ? formatDate(item?.expiry) : "NA"}`}</Typography>
                                                    {/* <Typography variant={"body2"} color={"textSecondary"} >{`${item?.effective ? formatDate(item?.effective) : "NA"}`}</Typography> */}
                                                    {/* <Typography variant={"body2"} color={"textSecondary"} >{`${item?.expiry ? formatDate(item?.expiry) : "NA"}`}</Typography> */}
                                                </div>
                                                <div style={{ height: "100%" }}>
                                                    <IconButton id={this.props?.id?.edit_tax_icon_id} onClick={() => this.onEdit(item)} edge="end" aria-label="edit" style={{ border: "1px solid #E8E8EC", margin: "0px 4px", padding: "4px" }}>
                                                        <EditOutlinedIcon fontSize={"small"} />
                                                    </IconButton>
                                                    <IconButton id={this.props?.id?.delete_tax_icon_id} onClick={() => { this.callBackOnSaveTax(item, true) }} edge="end" aria-label="delete" style={{ border: "1px solid #E8E8EC", margin: "0px 4px", padding: "4px" }}>
                                                        <DeleteOutlinedIcon fontSize={"small"} />
                                                    </IconButton>
                                                </div>


                                                {/* <List dense={true} style={{ width: "100%" }} disablePadding>
                                                    <ListItem divider classes={{ secondaryAction: classes.listItemSecondaryAction, gutters: classes.listItemGutters }}>
                                                        <ListItemText
                                                            classes={{ primary: classes.primaryText }}
                                                            primary={item?.label ? item?.label : null}
                                                            secondary={item?.taxRegistrationNumber ? item?.taxRegistrationNumber : "NA"}
                                                        />
                                                    </ListItem>
                                                    <ListItemSecondaryAction>
                                                        <IconButton id={this.props?.id?.edit_tax_icon_id} onClick={() => this.onEdit(item)} edge="end" aria-label="edit" style={{ border: "1px solid #E8E8EC", margin: "0px 4px", padding: "4px" }}>
                                                            <EditOutlinedIcon fontSize={"small"} />
                                                        </IconButton>
                                                        <IconButton id={this.props?.id?.delete_tax_icon_id} onClick={() => { this.callBackOnSaveTax(item, true) }} edge="end" aria-label="delete" style={{ border: "1px solid #E8E8EC", margin: "0px 4px", padding: "4px" }}>
                                                            <DeleteOutlinedIcon fontSize={"small"} />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </List> */}
                                            </div>
                                        )
                                    } else {
                                        return <></>
                                    }
                                })}
                            </Grid>
                            {tax?.length > 1 &&
                                <Typography
                                    id={this.props?.id?.view_more_tax_icon_id}
                                    onClick={() => this.handleOpenMoreDrawer()}
                                    className={classes.view_more_title}
                                    style={{ cursor: "pointer", paddingTop: 8, width: "100%" }}
                                    align="right"
                                >
                                    {"VIEW MORE"}
                                </Typography>
                            }
                        </Grid>
                    </>
                ) : (
                    <>
                        {!disabled && (
                            <div style={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}>
                                <Typography>
                                    {!this.props.hideActionBtn && (
                                        <Typography
                                            id={this.props?.id?.add_tax_id}
                                            onClick={() => handledrawer("Add Tax ID", null, tax)}
                                            className={classes.add_title}
                                        >
                                            {"+ Add Tax ID"}
                                        </Typography>

                                    )}
                                </Typography>
                            </div>
                        )}
                        {this.props.error && (
                            <FormHelperText classes={{ root: classes.helperTextRoot }}>
                                {this.props.errorText}
                            </FormHelperText>
                        )}
                    </>
                )}
            </>
        );
    }
}

export default withAlelrtAndDialog(withStyles(styles)(ModernViewTaxCardDetail));
