import React from "react";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import withAlelrtAndDialog from "../../../HOCs/withAlelrtAndDialog";
import { withRouter } from "react-router-dom";
import {
    FormControl,
    Grid,
    Hidden,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    withStyles,
} from "@material-ui/core";
import {
    genericStyles,
    ConfigurationMaster,
} from "../../../utils";
import { ConfigurationPanel } from './configuration';


const styles = (theme) => ({
    root: {
        padding: 24,
    },
    list: {
        boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
        backgroundColor: "white",
        borderRadius: 8,
        height: "calc(100vh - 108px)",
        overflow: "scroll",
    },
    masterSelect: {
        boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
        backgroundColor: "white",
        borderRadius: 8,
        padding: "12px 30px 12px 12px",
        // height: "calc(100vh - 108px)",
        // overflow: "scroll"
    },
    table: {
        boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
        backgroundColor: "white",
        borderRadius: 8,
        height: "calc(100vh - 108px)",
        overflow: "scroll",
    },
    menuList: {
        padding: 8,
        "& .Mui-selected": {
            borderRadius: 16,
            backgroundColor: theme.palette.primary.main,
            color: `${theme.palette.common.white} !important`,
            "&:hover": {
                backgroundColor: theme.palette.primary.main,
            },
        },
    },
    menuListItem: {
        marginTop: 4,
        marginBottom: 4,
        borderRadius: 16,
        padding: "4px 16px",
    },
    formControl: {
        margin: "0px 8px",
        "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme.palette.ternary.main} !important`,
                borderWidth: "1.6px !important",
            },
        },
    },
    customSelect: {
        padding: "10.500px 14px",
    },
    label: {
        color: theme.palette.text.label,
        textTransform: "uppercase",
    },
    ...genericStyles.cardWrapperShadows(theme),
});

const initialState = {
    selected: 1,
};

class Configuration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    componentDidMount() {
        this.getEssentials()
    }

    getEssentials = () => {
        // this.props.GET_TRACKING_TIME_SETUP({})
    }

    onMenuItemSelected = (selected, selectedObj) => {
        this.onChangeState('selected',selected)
    }

    onChangeState = (key,value) =>{
        this.setState({
            ...this.state,[key]:value
        })
    }


    render() {
        const { classes } = this.props;
        
        return (
            <div className={classes.root}>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                        {/* List component here */}
                        <Hidden xsDown>
                            <div className={`${classes.list} ${classes.cardWrapperShadows}`}>
                                <List component="nav" className={classes.menuList}>
                                    {ConfigurationMaster.map((md, key) => {
                                        return (
                                            <ListItem
                                                className={classes.menuListItem}
                                                key={key}
                                                button
                                                selected={this.state.selected === md.id}
                                                onClick={() => this.onMenuItemSelected(md.id, md)}
                                                id={`${md?.label}`}
                                            >
                                                <ListItemText
                                                    primary={md.label}
                                                    disableTypography
                                                    className={`${classes.typography} ${this.state.selected === md.id
                                                            ? classes.typoSelected
                                                            : ""
                                                        }`}
                                                />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </div>
                        </Hidden>

                        <Hidden smUp>
                            <div
                                className={`${classes.masterSelect} ${classes.cardWrapperShadows}`}
                            >
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    style={{ marginLeft: 8, width: "100%" }}
                                >
                                    <Select
                                        value={this.state.selected}
                                        onChange={(e) =>
                                            this.onMenuItemSelected(
                                                e.target.value,
                                                ConfigurationMaster.filter((x) => x.id === e.target.value)[0]
                                            )
                                        }
                                        style={{
                                            width: "100%",
                                        }}
                                        name="masterData"
                                        classes={{ outlined: classes.customSelect }}
                                    >
                                        {ConfigurationMaster?.map((item, index) => {
                                            return (
                                                <MenuItem key={item.value} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </Hidden>
                    </Grid>

                    <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                        <div className={`${classes.table} ${classes.cardWrapperShadows}`}>
                            <div className={classes.root}>
                                <ConfigurationPanel />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    // getTrackingTimeList: state?.configuration?.getTrackingTimeList,
});

export default connect(
    mapStateToProps,
    actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(Configuration))));
