import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import AddEditProductManagement from "../addEditForm";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
import AddIcon from "@material-ui/icons/Add";
import { errorMessage } from "../../utils/errorMessages";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    // height:100
  },
  margin: {
    margin: "8px 0px 8px 0px",
    // marginTop: 0,
    "& .MuiFormLabel-root": {
      color: theme.palette.secondary.main,
    },
  },
  add: {
    color: theme.palette.ternary.main,
  },
  label: {
    display: "block",
    color: theme.palette.secondary.main,
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
    textTransform:'upperCase'
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
}));

export const SimpleLabel = withAlelrtAndDialog((props) => {
  console.log(props, "---...props---");
  const classes = useStyles();

  const toggleDrawer = () => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      component: (
        <AddEditProductManagement
          onSaveButtonClicked={props.callback}
          components={props.component?.components}
          saveButtonLabel={"Add"}
          cancelForm={()=>{
            props.drawer.setDrawer({
              ...props.drawer,
              open: false,
              component: <></>
            })
          }}
          withoutBoxShadow
          spacing={1}
          sectionGutterBottom={16}
          saveButtonId={"saveNewClientButtonFromAddEditEnquiry"}
          cancelButtonId={"cancelNewClientButtonFromAddEditEnquiry"}
        />
      ),
    });
  };

  return (
    // <form className={classes.root} noValidate style={{background:'white'}}>

    <FormControl
      className={`${classes.margin} ${classes.root}`}
      style={{ ...props.style }}
    >
      {props.withBoldLabel ? (
        <Typography variant="body1" style={{ marginBottom: 8 }}>
          {props.title}
        </Typography>
      ) : (
        <InputLabel shrink htmlFor="bootstrap-input">
          {props.title}
        </InputLabel>
      )} 

      <div style={{ marginTop: 24 }}>
        <label className={`${classes.label} MuiFormLabel-root`}>
          Client Not Found?
        </label>
        <Typography
          variant="body1"
          component="div"
          style={{ display: "flex", alignItems:"center" }}
          className={classes.add}
          // onClick={toggleDrawer}
        >
          <AddIcon style={{cursor:'pointer', fontSize: "1rem" }} onClick={toggleDrawer}/>
          <span style={{cursor:'pointer', fontSize: "1rem", fontWeight:'bold'}} onClick={toggleDrawer} id={props?.id?.add_new_client_id}>{"Add Client".toUpperCase()}</span>
        </Typography>
      </div>
      {props.isError && (
        <Typography
          variant="body1"
          component="div"
          className={classes.errorText}
        >
          {errorMessage.requiredMessage}
        </Typography>
      )}
    </FormControl>

    // </form>
  );
});

// Specifies the default values for props:
SimpleLabel.defaultProps = {
  type: "text",
  error: false,
  rowsMax: 1,
  multiline: false,
};

// Specifies the required props for the component:
SimpleLabel.propTypes = {
  style: PropTypes.object,
  multiline: PropTypes.bool,
  rowsMax: PropTypes.number,
  handleOnEnter: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
};
