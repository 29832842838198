import * as React from "react"

export function CalenderEditIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.739" height="26" viewBox="0 0 23.739 26" {...props}>
            <path fill="#fff" d="M6.391 1v2.261h-1.13A2.268 2.268 0 0 0 3 5.522v15.826a2.268 2.268 0 0 0 2.261 2.261H14.5l2.261-2.261h-11.5v-11.3h15.826v6.972l2.261-2.261V5.522a2.268 2.268 0 0 0-2.261-2.261h-1.13V1H17.7v2.261H8.652V1zm-1.13 4.522h15.826v2.261H5.261zm19.451 11.3a.564.564 0 0 0-.4.166l-1.13 1.13 2.261 2.261 1.13-1.13a.566.566 0 0 0 0-.8l-1.462-1.462a.565.565 0 0 0-.399-.161zm-2.329 2.1l-5.818 5.818V27h2.261l5.818-5.818z" transform="translate(-3 -1)" />
        </svg>
    )
}

