import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from 'prop-types';

const CustomTypographyStyle = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(0, 1),
    },
    avatarSize: {
        width: theme.spacing(6.25),
        height: theme.spacing(6.25),
    },
    skeleton: {
        margin: theme.spacing(0, 1),
    },
    wrapper: (props) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: props.align ? props.align : "center",
        gap: theme.spacing(0.75)
    })
}));

export function CustomTypography({ loading, variant, color, styles, startIcon, weight, iconStyles, textAlign, ...props }) {
    const classes = CustomTypographyStyle(props);
    const theme = useTheme()

    return (
        <>
            {loading && (
                <Skeleton variant="text" className={classes.skeleton} width={theme.spacing(6.25)} />
            )}
            {!loading && (
                <>
                    {
                        startIcon
                            ?
                            <>
                                {variant === "row1" && (
                                    <div className={classes.wrapper}>
                                        {React.cloneElement(startIcon, { style: { fontSize: "1rem", ...iconStyles } })}
                                        <Typography style={{ color: color ? color : theme.palette.text.primary, ...styles }} >{props.children}</Typography>
                                    </div>
                                )}
                                {variant === "row2" && (
                                    <div className={classes.wrapper}>
                                        {React.cloneElement(startIcon, { style: { fontSize: "0.875rem", ...iconStyles } })}
                                        <Typography style={{ color: color ? color : theme.palette.text.secondary, fontSize: "0.875rem", ...styles }} >{props.children}</Typography>
                                    </div>
                                )}
                                {variant === "row3" && (
                                    <div className={classes.wrapper}>
                                        {React.cloneElement(startIcon, { style: { fontSize: "0.8rem", ...iconStyles } })}
                                        <Typography style={{ color: color ? color : theme.palette.text.secondary, fontSize: "0.8rem", ...styles }} >{props.children}</Typography>
                                    </div>
                                )}
                            </>
                            :
                            <>
                                {variant === "row1" && <Typography style={{ color: color ? color : theme.palette.text.primary, fontWeight: weight==="bold" ? 600 : 400, ...styles }} align={textAlign} >{props.children}</Typography>}
                                {variant === "row2" && <Typography style={{ color: color ? color : theme.palette.text.secondary, fontSize: "0.875rem", fontWeight: weight==="bold" ? 600 : 400, ...styles }} align={textAlign} >{props.children}</Typography>}
                                {variant === "row3" && <Typography style={{ color: color ? color : theme.palette.text.secondary, fontSize: "0.8rem", fontWeight: weight==="bold" ? 600 : 400, ...styles }} align={textAlign} >{props.children}</Typography>}
                            </>
                    }

                </>
            )}
        </>
    );
}

CustomTypography.propTypes = {
    loading: PropTypes.bool,
    variant: PropTypes.oneOf(['row1', 'row2', 'row3']),
    color: PropTypes.string,
    weight: PropTypes.oneOf(['normal', 'bold']),
    startIcon: PropTypes.any,
    align: PropTypes.string,
    textAlign: PropTypes.string,
    styles: PropTypes.object,
    iconStyles: PropTypes.object,
}

// CustomTypography.defaultProps = {
//     loading: false,
//     variant: "row1",
//     weight: "normal",
//     align: "flex-start"
// }

