import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { MiniGraphOne, MiniGraphTwo, MiniGraphThree, MiniGraphFour } from '../../assets'
import { genericStyles } from '../../utils';

export * from './loading';
export * from './countCardLoaders';
export * from './countCardsErrors';


export const CountCardIcons = {
    miniGraphOne: <MiniGraphOne />,
    miniGraphTwo: <MiniGraphTwo />,
    miniGraphThree: <MiniGraphThree />,
    miniGraphFour: <MiniGraphFour />,
}

const useStyles = makeStyles((theme) => ({
    root: {
        // boxShadow: `0px 0.5px 2px 1px #e0e0e0d6`,
        padding: 16,
        backgroundColor: "white",
        height: 90,
        borderRadius: 8,
    },
    graphHolder: {
        height: 70,
        width: 90,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    ifGraphTwo: {
        position: 'relative',
        top: -3
    },
    withsubtxt: {
        fontWeight: "bold",
    },
    ...genericStyles.cardWrapperShadows(theme)
}))

export const CountCards = (props) => {

    const classes = useStyles();

    const {
        count,
        label,
        // graph,
        // index,
        isprofessional,
        withSubtext
        // graphData
    } = props;

    return <div className={`${classes.root} ${classes.cardWrapperShadows}`}>
        <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={isprofessional ? 12 : 7}>
                <Typography variant="h5">{count !== null && count !== undefined ? count : '0'} {withSubtext && <Typography variant="subtitle1" component="span" className={classes.withsubtxt}>{withSubtext}</Typography>} </Typography>
                <Typography style={{whiteSpace:"nowrap"}} variant="body2" color={"textSecondary"}>{label ? label : ''}</Typography>
            </Grid>
            {/* <Grid item xs={5}>
                <div className={`${classes.graphHolder} + ${index === 1 ? classes.ifGraphTwo : ''}`}>
                    {graph}
                </div>
            </Grid> */}
        </Grid>
    </div>
}

CountCards.propTypes = {
    count: PropTypes.number.isRequired,
    label: PropTypes.string,
    graphData: PropTypes.object
}
