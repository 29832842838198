import * as React from "react"

export const FolderIcon = (props) => {
 let width = props.width ? props.width : "65.862";
 let height = props.height ? props.height : "52.69";
 let color = props.color ? props.color : "#0063e7";
 return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 65.862 52.69">
      <g id="icons8-kawaii-folders" transform="translate(-4 -8)">
        <path id="Path_96714" data-name="Path 96714" d="M63.276,14.586H33.638L27.052,8H10.586A6.606,6.606,0,0,0,4,14.586V27.759H69.862V21.172A6.606,6.606,0,0,0,63.276,14.586Z" transform="translate(0 0)" fill={color}/>
        <path id="Path_96715" data-name="Path 96715" d="M63.276,12H10.586A6.606,6.606,0,0,0,4,18.586V51.517A6.606,6.606,0,0,0,10.586,58.1h52.69a6.606,6.606,0,0,0,6.586-6.586V18.586A6.606,6.606,0,0,0,63.276,12Z" transform="translate(0 2.586)" fill={color} opacity="0.5"/>
    </g>
    </svg>
  );
};