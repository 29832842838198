import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { routes } from "../../router/routes";

import { withNavBars } from "./../../HOCs";

import { ListServices, AddEditService, ServiceDetail } from "./services";
import { ListPackages, AddEditPackage, PackageDetail } from "./packages";
import { ListProducts, AddEditProduct, ProductDetail } from "./products";
import { AddEditPromotionalBanner, ListPromotionalBanner, ViewPromotionalBanner } from "./promotionalBanners";

class ProductManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateNavBarProps: true,
    };
  }

  render() {
    const { match } = this.props;

    return (
      <>
        {/* Services routes */}
        {match.path === routes.manage_service && <ListServices userRole={this.props?.userRole} />}
        {match.path === routes.add_service && <AddEditService />}
        {match.path === routes.edit_service && <AddEditService isEdit={true} />}
        {match.path === routes.view_service && <ServiceDetail />}

        {/* Package routes */}
        {match.path === routes.manage_package && <ListPackages userRole={this.props?.userRole} />}
        {match.path === routes.add_package && <AddEditPackage />}
        {match.path === routes.edit_package && <AddEditPackage isEdit={true} />}
        {match.path === routes.view_package && <PackageDetail />}

        {/* Product routes */}
        {match.path === routes.manage_product && <ListProducts userRole={this.props?.userRole} />}
        {match.path === routes.add_product && <AddEditProduct />}
        {match.path === routes.edit_product && <AddEditProduct isEdit={true} />}
        {match.path === routes.view_product && <ProductDetail />}

        {/* Promotional Banner routes */}
        {match.path === routes.manage_promotional_banner && <ListPromotionalBanner userRole={this.props?.userRole} />}
        {match.path === routes.add_promotional_banner && <AddEditPromotionalBanner />}
        {match.path === routes.edit_promotional_banner && <AddEditPromotionalBanner isEdit={true} />}
        {match.path === routes.view_promotional_banner && <ViewPromotionalBanner />}
      </>
    );
  }
}

ProductManagement.propTypes = {
  title: PropTypes.string,
  withBackButton: PropTypes.bool,
  navBarArr: PropTypes.array,
};

ProductManagement.defaultProps = {
  title: "Product Managment",
};

export default withRouter(withNavBars(ProductManagement));
