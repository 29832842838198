import React from "react";
import {
  withStyles,
  Grid, Typography, Button, Divider,
} from "@material-ui/core";
import ExperiencecardList from "./index";
import ExperiencecardsLoading from "./ExperiencecardsLoading";
// import { DragDropContext, Droppable } from "react-beautiful-dnd";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
// import PropTypes from "prop-types";
export * from "./ExperiencecardsLoad";
export * from "./error";

const styles = (theme) => ({
  title: {
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "left",
    marginBottom: 20,
    marginTop: 8,
  },
  add_title: {
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "left",
    // color: "#f27d35",
    color: theme.palette.ternary.main,
    textTransform: "uppercase",
    cursor: "pointer",
    width: "max-content"
  },
  view_more_title: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    color: theme.palette.ternary.main,
    textTransform: "uppercase",
    width: "max-content",
  },
  addIdButtonText: {
    color: theme.palette.ternary.main,
    fontWeight: 600
  },
  gutter: {
    padding: "16px 16px"
  },
});
class Experiencecard extends React.Component {

  handleOpenMoreDrawer = () => {

    const { classes, experience, handledrawer, ondltList, onDragEnd1 } = this.props;

    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <div>
          <Grid container xs={12} className={classes.gutter}>
            <Grid item xs={6}>
              <Typography variant={"h6"}>Experience</Typography>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: "flex-end" }}>
              <Button style={{ boxShadow: "none" }}>
                <Typography id={this.props?.id?.add_experience_id} onClick={() => handledrawer("Add Experience", experience)} variant={"body2"} className={classes.addIdButtonText}>+ ADD Experience</Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider light />
          </Grid>
          <Grid className={classes.gutter}>
            <ExperiencecardList
              data={experience}
              handledrawer={handledrawer}
              ondltList={(label, index) => ondltList(label, index, "drawer")}
              onDragEnd1={onDragEnd1}
              breakpoints={{
                grid1: { md: 2, lg: 2 },
                grid2: { md: 10, lg: 10 }
              }}
            />
          </Grid>
        </div>
      ),
    });
  };





  render() {
    const { classes, experience, handledrawer, ondltList, onDragEnd1 } = this.props;

    return (
      <Grid>
        {experience?.length > 0 &&
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography className={classes.title}>{"Experience"}</Typography>
            {experience?.length > 2 && (
              <Typography
                id={this.props?.id?.view_more_experience}
                onClick={() => this.handleOpenMoreDrawer()}
                className={classes.view_more_title}
                style={{ cursor: "pointer", }}
              >
                {"VIEW MORE"}
              </Typography>
            )}
          </div>
        }
        {experience && <ExperiencecardList data={experience?.filter((_, i) => i < 2)} handledrawer={handledrawer} onDragEnd1={onDragEnd1} ondltList={ondltList} />}
        {!experience && <ExperiencecardsLoading count={2} />}
        <Typography id={this.props?.id?.add_experience_id} onClick={() => handledrawer("Add Experience", experience)} style={{ marginTop: experience?.length > 0 ? 30 : 10 }} className={classes.add_title}>{"+ Add experience"}</Typography>
      </Grid>
    );
  }
}

Experiencecard.propTypes = {

};

Experiencecard.defaultProps = {
  classes: {},
  experience: [],
  handledrawer: () => { },
  ondltList: () => { },
  onDragEnd1: () => { }

};

export default withAlelrtAndDialog(withStyles(styles)(Experiencecard));