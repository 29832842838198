import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import { AsyncSelectComponent } from "./asyncSelect";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  margin: (props) => ({
    margin: "8px 0px 8px 0px",
    width: "100%",
    "& .MuiInputBase-root": {
      width: "100% !important",
    },
    "& .css-2b097c-container": {
      marginTop: props.marginTop ? `${props.marginTop}px !important` : "0px !important",
    },
    "& .css-yk16xz-control": {
      minHeight: "53px !important",
      borderRadius: "8px !important",
    },
    "& .css-g1d714-ValueContainer": {
      maxHeight: 'none'
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: "none",
    },
    "& .css-14jk2my-container": {
      // marginTop: "8px !important",
    },
    "& .MuiInputLabel-asterisk": {
      color: theme.palette.error.main
    }
  }),
  label: {
    color: theme.palette.text.label,
    textTransform: "uppercase",
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
  labelsize: {
    fontSize: 17
  }
}));

export const SimpleSelect = (props) => {
  const classes = useStyles(props);

  const { label, options, required=false } = props;
  // console.log(options)
  
  return (
    <div className={classes.margin} style={{ margin: "8px 0px 8px 0px !important" }}>
      <InputLabel
        shrink
        htmlFor="demo-customized-select-native"
        className={classes.label}
        required={required}
      >
        {label}
      </InputLabel>
      <AsyncSelectComponent
        {...props}
        value={props.value}
        isDisabled={props?.disabled}
        handleOnChange={props.handleOnChange}
        error={props.error}
        input_id={props.input_id}
        isMulti={props.isMulti}
        className={classes.async}
        options={options && Array.isArray(options) && options.length > 0 ? options : []}
        defaultValue={props.defaultValue}
        hideCustomValueContainerHeight={props.hideCustomValueContainerHeight}
        disableTime={props.disableTime}
      />
      {props.error && (
        <Typography
          variant="body1"
          component="div"
          className={classes.errorText}
        >
          {props.errorText}
        </Typography>
      )}
    </div>
  );
};
