import React from "react";
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import { Relation } from "../../assets/viewScreenIcons/Relation";
import moment from "moment";
import { routes } from "../../router/routes";
import { useHistory } from "react-router-dom";
// import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px 5px 20px 10px",
  },
  captionStyle: {
    color: theme.palette.text.secondary,
  },

  unread: {
    color: "#000000",
  },

  read: {
    color: "#a6b1b8",
  },

  professional: {
    fontSize: "0.75rem !important",
  },

  noProofessional: {
    display: "none",
  },

  avatar: {
    background: "rgb(40, 169, 193,0.1)",
    height: 50,
    width: 50,
  },
}));

const NOTIFICATION_MODULE = {
  PURCHASE: "PURCHASE",
};

const Component = (props) => {
  const classes = useStyles();
  const { data } = props;
  const history = useHistory();

  // console.log(data.is_read)

  //   onBackBtnClicked = () => {
  //     this.props.drawer.setDrawer({
  //         ...this.props.drawer,
  //         open: false
  //     })
  // }

  const handleRedirect = async (data) => {
    if (data?.module === NOTIFICATION_MODULE.PURCHASE) {
      // 1. Change the notification as not new i.e. isNewNotification - false
      props.updateNotification();

      // 2. Redirect to the Service info page
      history.push(
        `${routes.monitor_subscription_detail_parent}${data?.InfoId}`
      );

      // 3. Close the drawer
      props.handleDrawer();
    } else {
      // 1. Change the notification as not new i.e. isNewNotification - false
      props.updateNotification();

      // 2. Close the drawer
      props.handleDrawer();

      // 3. Redirect to Availability management and open drawer
      props.openActivityDrawer(data);
    }
  };

  const handleDelete = async (data) => {
    console.log(data);
    props.deleteNotification(data.id);
    // props.updateNotification()
    props.handleDrawer();
  };

  const getTitleWithFormattedDateForPurchaseModule = (text, module) => {
    if (module !== NOTIFICATION_MODULE.PURCHASE) return text;

    const dateSplitList = text?.toString().split("%d") || [];

    if (dateSplitList.length === 1) return text;

    let updatedText = [...dateSplitList];

    const rawDate = dateSplitList[1];
    if (moment(rawDate).isValid()) {
      let formattedDate = moment(rawDate).format("DD/MM/YYYY HH:mm A");
      updatedText[1] = formattedDate;
    } else {
      updatedText[1] = "-";
    }

    return updatedText.join("");
  };

  return (
    <>
      <Grid container className={classes.root}>
        {!data.is_read ? (
          <FiberManualRecordRoundedIcon
            fontSize="inherit"
            style={{ color: "#f5bca0" }}
          />
        ) : (
          <FiberManualRecordRoundedIcon
            fontSize="inherit"
            style={{ visibility: "hidden" }}
          />
        )}
        <Grid item xs={2}>
          <Avatar src="" alt="A" className={classes.avatar}>
            <Relation
              color={"secondary"}
              style={{
                height: 20,
                width: 20,
              }}
            />
          </Avatar>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              className={data.is_read ? classes.read : classes.title}
            >
              {getTitleWithFormattedDateForPurchaseModule(
                data?.title,
                data?.module
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              className={
                data.serviceProfessional
                  ? classes.professional
                  : classes.noProofessional
              }
            >
              {data.serviceProfessional}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.captionStyle}>
              {moment(data.created_at).fromNow()}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={3}
          justify="flex-end"
          style={{ paddingTop: 20, paddingBottom: 20 }}
        >
          <IconButton
            onClick={() => handleRedirect(data)}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <ChevronRightIcon
              htmlColor={data.is_read ? "#a6b1b8" : "#252b43"}
              fontSize="small"
            />
          </IconButton>
          <IconButton onClick={() => handleDelete(data)}>
            <DeleteOutlineIcon htmlColor="#9297a5" fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default Component;
