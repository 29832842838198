import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { ToggleGroupField } from '../../../../components/availabilityApplyFiltersComponents'

function Filters() {

    const [filterType, setFilterType] = React.useState('')
    // const [filterActivity, setFilterActivity] = React.useState([])


    const handleTypeChange = (e, newValue) => {
        setFilterType(newValue)
        console.log(newValue)
    }


    // const handleActivityChange = (e, newValue) => {
    //     console.log('My new value is ', newValue)
    //     // setFilterActivity(newValue)
    //     newValue.map(item => {
    //         setFilterActivity([...filterActivity, item.title])
    //         return null
    //     })
    // }


    // render() {

    const toggleTypes = [
        {
            label: 'Activity',
            value: 'activity'
        },
        {
            label: 'Availability',
            value: 'availability'
        },
        {
            label: 'Unavailability',
            value: 'unavailability'
        },
    ]

    // const availableOptions = [
    //     { title: 'Initial assessment' },
    //     { title: 'In Appointment' },
    //     { title: 'Daily Activity' },
    // ]


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6" style={{ padding: 12 }}>Filters</Typography>
                </Grid>
                <Grid item xs={12} style={{ padding: `12px 24px 10px 28px` }}>
                    <ToggleGroupField toggleTypes={toggleTypes} selectedType={filterType}
                        handleFilterTypeChange={handleTypeChange}
                    />
                </Grid>
                {/* <Grid item xs={12} style={{ padding: `12px 24px 10px 28px` }}>
                    <AutocompleteInput
                        availableOptions={availableOptions}
                        handleFilterTypeChange={handleActivityChange}
                    />
                </Grid> */}
            </Grid>
        </>
    )
    // }
}

export default Filters;