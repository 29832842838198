import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Step, Stepper, StepLabel, StepConnector, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment'

const MyConnector = withStyles({
  alternativeLabel: {
    top: 8,
    left: 'calc(-50% + 10px)',
    right: 'calc(50% + 10px)'
  },
  active: {
    '& $line': {
      backgroundColor: '#f27d35',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#f27d35',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);


const customStepperStyles = makeStyles({
  myInnerCircle: {
    height: 12,
    width: 12,
    borderRadius: '50%',
    backgroundColor: 'white'
  },
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    padding: 4,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  //Active and completed both are needed classes
  active: {
    backgroundColor: '#f27d35',
    padding: 4,
    borderRadius: "50%",
    zIndex: 1
  },
  completed: {
    backgroundColor: '#f27d35',
    padding: 4,
    borderRadius: "50%",
    zIndex: 1
  }
})

function customStepperMC(props) {
  const classes = customStepperStyles()
  const { active, completed } = props;

  return (
    <div className={`${classes.root} ${active ? classes.active : ''} ${completed ? classes.completed : ''}`}>
      <div className={`${classes.myInnerCircle}`}></div>
    </div>
  )
}

customStepperMC.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: `80px 0px`,
    backgroundColor: 'white',
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
  makePerfectSpacing: {
    padding: 0
  }
}));


export default function ProductTracker(props) {
  const classes = useStyles();
  const { timeline, current_status } = props;


  /**
   * Get the steps array with label and date time. returns an Array of Objects [{}]
   */
  const getSteps = () => {
    var steps = []

    if(timeline){
      timeline.map((item) => {
        return steps.push(item)
      })
    }
    return steps
  }

  /**
   * Get the steps array with label only. returns an Array []
   */
  const getStepsStatus = () => {
    const finalValues = []
    
    if(timeline){
      timeline.map((item) => {
        return finalValues.push(item.status)
      })
    }
    return finalValues
  }

  /**
   * Get the current active step. returns a Number
   */
  const getActiveSteps = () => {
    const status = getStepsStatus()
    return status.findIndex(element => current_status === element)
  }

  const steps = getSteps();
  const [activeStep] = React.useState(getActiveSteps());

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<MyConnector />} classes={{ root: classes.makePerfectSpacing }} >
        {steps?.map((label, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={customStepperMC} >{label.status}
              <Typography variant="subtitle2" color={"textSecondary"} style={{ marginTop: 8 }}>
                {`${label.date_time ? moment(label.date_time).format('MMM DD, YYYY') : 'N/A'} at `}
                < br />
                {`${label.date_time ? moment(label.date_time).format('kk:mm A') : 'N/A'}`}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}