/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-01-28
 * @modify date 2021-06-28
 * @desc Form Builder
 */
 import {
  makeStyles,
  Typography,
  Grid,
  Button,
  Divider,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { LinearProgressWithLabel } from "../../components";
import { ComponentToRender } from "./componentToRender";
import { actions } from "tanyacare-middleware";
import { useDispatch, useSelector } from "react-redux";
import { AlertContext, BackdropContext } from "../../contexts";
import { alertProps } from "../../utils";
import { useHistory } from "react-router-dom";
import { routes } from "../../router/routes";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import {  giveMeRoleDataSecurity } from "../formConfigurator/utils";
import { getMobileLimitBasedOnCC } from "../../utils/validations";
import {
  checkStringValidations,
  checkNumericValidations,
  checkDobValidations
} from "./checkValidation";
import { Prompt } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formTitle: {
    fontWeight: "bold",
  },
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 2,
  },
  uiComponentWrapper: {
    marginTop: 10,
    padding: "8px 28px",
  },
  savebtn: {
    padding: "6px 12px",
    borderRadius: 6,
  },
  draftbtn: {
    padding: "6px 12px",
    borderRadius: 6,
    marginLeft: "5px",
    marginRight: "5px",
  },
  nxtBtn: {
    fontSize: "0.8125rem",
    display: "flex",
  },
}));

export const FormBuilder = ({
  form_id,
  forms,
  getSectionId,
  parent_form_id,
  isEdit,
  entry_id,
  answer = {},
  setHistoryOfViewedForms,
  secName = "",
  isReadyOnly = false,
  onAnswerUpdated = () => false,
  setView,
  viewSection = () => false,
  statusUpdateData = {},
  entryPatientDetail = {},
  patientDetail = {},
  restForm = () => false
}) => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const backDrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);


  const [allowDraft, setAllowDraft] = React.useState(false);
  const [isChangesSaved, setIsChangesSaved] = React.useState(false);
  const [smartAnswer, setSmartAnswer] = React.useState(true);
  const [formsWithAnswer, setFormsWithAnswer] = React.useState(answer);
  const [errorValidation, setErrorValidation] = React.useState({});

  const savingJSON = useSelector((state) => state?.renderSlice?.upsertEntry ?? { loading: false, data: [], error: false, });
  const userData = useSelector(state => state?.authSlice?.login?.data);

  let currentButton;
  let currentForm = forms[0];
  let currentIndex = 1;
  let validated;

  if (form_id) {
    for (let index = 0; index < forms.length; index++) {
      const fr = forms[index];
      if (fr.id === form_id) {
        currentForm = fr;
        currentIndex = index + 1;
        break;
      }
    }
  }

  const canIableToMoveNextForm = (node) => {
    if (node?.dependent) {
      let valueArr = node?.dependent?.option?.map((_) => _.value) ?? [];

      if (
        valueArr.indexOf(
          formsWithAnswer[node?.dependent?.parent_question_id]?.answer
        ) > -1
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  const mobileValidations = (answer, id) => {
    if (answer && answer?.phoneCode?.length > 0) {
      const limit = getMobileLimitBasedOnCC(answer.phoneCode);
      if (limit === answer?.number?.length) {
        return "";
      } else {
        errorValidation[id] = {
          error: true,
          errorMessage: ``,
        };
      }
    } else {
      errorValidation[id] = {
        error: true,
        errorMessage: `Please fill the required field`,
      };
    }
  };

  const locationLevelValidations = (answer, id) => {
    if (answer?.country) {
      return "";
    } else {
      errorValidation[id] = {
        error: true,
        errorMessage: `Please select the country field`,
      };
    }
  };

  const storeErrorValidations = (id, msg) => {
    errorValidation[id] = {
      error: true,
      errorMessage: msg,
    };
  };

  const stringValidations = (item, val, id) => {
    if (val || item[0]?.is_required) {
      checkStringValidations(
        item[0]?.validation,
        val,
        id,
        storeErrorValidations,
        item[0].question
      );
    }
  };

  const numericValidations = (item, val, id) => {
    if (val || item[0]?.is_required) {
      checkNumericValidations(
        item[0]?.validation,
        val,
        id,
        storeErrorValidations,
        item[0].question
      );
    }
  };

  const dobValidations = (item, val, id) => {
    if (val?.age >= 0 || item[0]?.is_required) {
      checkDobValidations(
        item[0]?.validation,
        val?.age,
        id,
        storeErrorValidations,
      );
    }
  };

  const tableValidations = (table_scheme, val, id) => {
    errorValidation[id] = [];

    table_scheme.row.map((l, i) => {
      //get the all index that has validations property
      let findIndexKeys = l.validations && Object.keys(l.validations);

      //get the index that has date validations
      let removeDateValidations = table_scheme.column
        .map((l, j) => {
          if (l.ui_component === "date") {
            return j;
          }
          return undefined;
        })
        .filter((l) => l !== undefined);

      // remove date valiadtions from findIndexKeys because date validations done automatically in UI
      findIndexKeys = findIndexKeys.filter(
        (l, i) => l !== removeDateValidations[i]?.toString()
      );

      if (findIndexKeys.length > 0) {
        findIndexKeys.map((f) => {
          checkStringValidations(
            l.validations[f],
            val.tableData[i][f],
            id,
            (id, msg) => tableErrorValidations(id, msg, i, f)
          );
          return f;
        });
      }
      return l;
    });

    if (errorValidation[id]?.length === 0) {
      delete errorValidation[id];
    }
  };

  const tableErrorValidations = (id, msg, rowIndex, dataIndex) => {
    errorValidation[id][rowIndex] = [];

    errorValidation[id][rowIndex][dataIndex] = {
      error: true,
      errorMessage: msg,
    };
  };

  const checkRequired = (form, answer) => {
    const requiredTrueids = [];
    const answerdetails = answer;
    const availabletrues = [];

    if (requiredTrueids.length > 0 && Object.keys(answer).length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.error,
        msg: "Please fill the required fields !",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.center,
      });
      return false;
    }

    let isSmartSkip = "";

    const FramedQuestions = form.framed_questions;

    const checkSmartQuesionAvailable = FramedQuestions.map((item) => {
      return item.is_required === true && item.ui_component === "popup_switch" ? true : false
    });
    // eslint-disable-next-line
    const mapFramedQuestion = FramedQuestions.map((item) => {
      if (
        (item.is_required === true ||
          (item?.validation && Object.keys(item?.validation)?.length > 0)) &&
        item.ui_component !== "label"
      ) {
        if (
          item.ui_component === "input_date_picker" &&
          item.is_required === true
        ) {
          requiredTrueids.push(item);
        } else if (item.ui_component !== "input_date_picker") {
          requiredTrueids.push(item);
        }
      }
    });

    const requiredId = requiredTrueids.map((item) => {
      return item.id;
    });

    //empty the errorValidation object to check
    for (var item in errorValidation) delete errorValidation[item];

    // eslint-disable-next-line
    const checking = requiredId.map((item) => {
      if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "input_number"
      ) {
        numericValidations(
          requiredTrueids?.filter((l) => l.id === item),
          answerdetails[item]?.answer,
          item
        );
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "input_text" ||
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "input_textarea"
      ) {
        stringValidations(
          requiredTrueids?.filter((l) => l.id === item),
          answerdetails[item]?.answer,
          item
        );
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "mobile_no"
      ) {
        mobileValidations(answerdetails[item]?.answer, item);
      }
      else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "location_level"
      ) {
        locationLevelValidations(answerdetails[item]?.answer, item);
      }
      else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "date_of_birth"
      ) {
        dobValidations(requiredTrueids?.filter((l) => l.id === item),
          answerdetails[item]?.answer,
          item);
      }
      else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "custom_table" ||
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "custom_table_2"
      ) {
        tableValidations(
          requiredTrueids?.filter((l) => l.id === item)[0]?.table_scheme,
          answerdetails[item]?.answer,
          item
        );
      } else {
        if (
          answerdetails[item]?.answer?.length > 0 ||
          answerdetails[item]?.answer?.value?.length > 0 ||
          answerdetails[item]?.answer?.tableData?.length > 0 ||
          answerdetails[item]?.answer?.address?.length > 0
        ) {
          availabletrues.push(answerdetails[item]);
        } else {
          errorValidation[item] = {
            error: true,
            errorMessage: `Please fill the required field`,
          };
        }
      }
      // validation for smart skip
      if (checkSmartQuesionAvailable && answerdetails[item]?.answer === "No") {
        // let requiredTrueidsLength = requiredTrueids.length;
        // availabletrues.length = requiredTrueidsLength;

        //empty the errorValidation object to check
        for (var items in errorValidation) delete errorValidation[items];

        isSmartSkip = true;
      }

      // }
    });

    setErrorValidation(errorValidation);

    if (checkSmartQuesionAvailable && isSmartSkip) {
      return true;
    } else {
      if (errorValidation && Object.keys(errorValidation)?.length === 0) {
        return true;
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.warning,
          msg: "Please fill the required fields !",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.center,
        });
        return false;
      }
    }
  };

  const onSkipBtnClicked = (currentIndex, currentForm) => {
    skipFunction(currentIndex, currentForm);
  };

  const skipFunction = (currentIndex, currentForm) => {
    let isSkipCheck = currentForm?.is_skippable;
    if (isSkipCheck) {
      onNextBtnClicked(currentIndex, currentForm, false, true);
    }
  }

  const giveMeNotifyType = (isDraft) => {
    if (isDraft) {
      return 1
    } else {
      switch (secName) {
        case 0:
          return 2;
        case 1:
          return 3;
        case 2:
          return 4;
        case 4:
          return 5;

        default:
          return 0;
      }
    }
  }

  const onNextBtnClicked = async (currentIndex, currentForm, isDraft = false, isSkip = false) => {
    //Checking wheather the section is skipable or not
    if (isSkip) {
      validated = true;
      setErrorValidation({});
    } else {
      validated = checkSectionType()
        ? true
        : checkRequired(currentForm, formsWithAnswer);
    }


    if (validated) {

      const dataForViewSection = [currentIndex, currentForm];
      setView(dataForViewSection);

      if (currentIndex === forms.length || isDraft) {

        const roleDataSecurity = giveMeRoleDataSecurity(userData);

        backDrop.setBackDrop({
          ...backDrop,
          open: true,
          message: "Saving your entry...",
        });

        let params = {
          form_id: parent_form_id,
          data: formsWithAnswer,
          notifier_status: isDraft ? 9 : 1,
          notifyType: giveMeNotifyType(isDraft),
          roles: roleDataSecurity.myRole,
          patient_id: patientDetail?.patient_id,
          secName
        };

        if (isEdit) {
          params.entry_id = entry_id;
          params.verifier_status = secName === 1 ? 3 : null;
          params.registration_status = secName === 2 ? 8 : null;
          params.investigator_status = secName === 3 ? 6 : null;
        } else {
          params.location = {
            country: roleDataSecurity.country,
            level1: roleDataSecurity.level1,
            level2: roleDataSecurity.level2,
            level3: roleDataSecurity.level3,
            level4: roleDataSecurity.level4,
            level5: roleDataSecurity.level5,
            level6: roleDataSecurity.level6,
            level7: roleDataSecurity.level7,
            level8: roleDataSecurity.level8,
            level9: roleDataSecurity.level9,
            level10: roleDataSecurity.level10,
            level11: roleDataSecurity.level11,
            level12: roleDataSecurity.level12,
          }
        }

        Promise.resolve(dispatch(actions.UPSERT_ENTRY(params))).then((res) => {

          backDrop.setBackDrop({
            ...backDrop,
            open: false,
          });

          if (!res.payload.error) {

            if (!isDraft && !isEdit) {
              setIsChangesSaved(true);
            }

            alert.setSnack({
              ...alert,
              open: true,
              severity: alertProps.severity.success,
              msg: `Notification has been ${isDraft ? "saved as draft" : isEdit ? "updated" : "submitted"} Successfully!!!`,
              vertical: alertProps.vertical.top,
              horizontal: alertProps.horizontal.center,
            });

            if (!isDraft) {
              getSectionId(forms[0]);
            }

            if (isDraft) {
              setAllowDraft(true);
              getSectionId(forms[0]);
              history.push({
                pathname: routes.notifications,
                search: `?id=${parent_form_id}&tab=1`,
              });
            }

            if (isEdit) {
              navigateToListingPage(parent_form_id);
            } else {
              if (!isDraft) {
                setHistoryOfViewedForms([]);
                setFormsWithAnswer({});
                setView([]);
                restForm();
              }
            }
          } else {
            alert.setSnack({
              ...alert,
              open: true,
              severity: alertProps.severity.error,
              msg: "Something went wrong! Try Again",
              vertical: alertProps.vertical.top,
              horizontal: alertProps.horizontal.center,
            });
          }
        });
      } else {
        if (canIableToMoveNextForm(forms[currentIndex])) {
          getSectionId(forms[currentIndex]);
        } else {
          onNextBtnClicked(currentIndex + 1, forms[currentIndex], false);
        }
      }
    }
  };

  const navigateToListingPage = (parent_form_id) => {
    let route = '';
    let tab = ''

    switch (secName) {
      case 0://Notifications
        route = routes.notifications;
        tab = 0;
        break;

      case 1://Vertifications
        route = routes.verifications;
        tab = 1;
        break;

      case 2://Investigations
        route = routes.case_registration;
        tab = 1;
        break;

      case 3://Case Registrations
        route = routes.investigations;
        tab = 1;
        break;

      default:
        break;
    }
    history.push({
      pathname: route,
      search: `?id=${parent_form_id}&tab=${tab}`,
    });
  }

  const onPrevBtnClicked = (currentIndex, currentForm) => {
    if (currentIndex !== 1) {
      if (canIableToMoveNextForm(forms[currentIndex - 2])) {
        getSectionId(forms[currentIndex - 2]);
      } else {
        onNextBtnClicked(currentIndex - 3, forms[currentIndex], false, true);
      }
    }
  };

  const onChange = (index, data, value) => {
    let newForms = formsWithAnswer;

    newForms[data?.id] = {
      id: data?.id,
      answer: value,
    };

    setFormsWithAnswer({
      ...newForms,
    });
    onAnswerUpdated(newForms);
  };

  React.useEffect(() => {
    if (currentForm?.framed_questions?.[0]?.priority === 0) {
      let answer =
        formsWithAnswer?.[currentForm?.framed_questions?.[0]?.id]?.answer ??
        "No";
      setSmartAnswer(answer === "Yes");
    } else {
      setSmartAnswer(false);
    }

    // setView([currentIndex, currentForm]);
    // eslint-disable-next-line
  }, [currentForm, formsWithAnswer]);

  const canIshowSmartQuestion = (currentForm) => {
    return currentForm?.framed_questions?.[0]?.priority === 0;
  };

  const isSmartAnswerCheckNeed = (currentForm) => {
    if (currentForm?.framed_questions?.[0]?.priority === 0) {
      return smartAnswer;
    } else {
      return true;
    }
  };

  const canIVisibleHere = (question) => {
    if (question?.dependent) {
      let valueArr = question?.dependent?.option?.map((_) => _.value) ?? [];

      let ansIndex =
        formsWithAnswer[question?.dependent?.parent_question_id]?.answer
          ?.value ??
        formsWithAnswer[question?.dependent?.parent_question_id]?.answer;

      if (valueArr.indexOf(ansIndex) > -1) {
        return false;
      }

      return true;
    }
    return false;
  };
  // eslint-disable-next-line
  const getButtonName = () => {
    if (currentIndex === forms.length) {
      if (savingJSON.loading) {
        if (isEdit) {
          return "Updating...";
        } else {
          return "Submitting...";
        }
      } else {
        if (isEdit) {
          if (isReadyOnly) {
            if (currentForm.is_investigator_section) {
              return "Investigated";
            } else {
              return (
                <Typography variant="body1" className={classes.nxtBtn}>
                  Next <ChevronRightIcon fontSize="small" />
                </Typography>
              );
            }
          } else {
            return "Submit";
          }
        } else {
          return "Submit";
        }
      }
    } else {
      if (isReadyOnly) {
        if (currentForm.is_investigator_section) {
          return "Next";
        } else {
          return (
            <Typography variant="body1" className={classes.nxtBtn}>
              Next <ChevronRightIcon fontSize="small" />
            </Typography>
          );
        }
      } else {
        return "Next";
      }
    }
  };

  React.useEffect(() => {
    // eslint-disable-next-line
    currentButton = getButtonName();

    if (Object.keys(formsWithAnswer).length > 4) {
      setIsChangesSaved(false);
    }

    // eslint-disable-next-line
  }, [getButtonName])


  const giveFormLayoutType = () => {
    if (currentForm?.formLayoutType === "1 Column Layout") {
      return 12;
    } else if (currentForm?.formLayoutType === "2 Column Layout") {
      return 6;
    } else {
      return 4;
    }
  };

  const giveMeSM = (ui_component) => {
    if (
      ui_component === "custom_table" ||
      ui_component === "custom_table_2" ||
      ui_component === "location_level"
    ) {
      return 12;
    } else {
      return 6;
    }
  };

  const giveMeMD = (ui_component) => {
    if (
      ui_component === "custom_table" ||
      ui_component === "custom_table_2" ||
      ui_component === "location_level"
    ) {
      return 12;
    } else {
      return giveFormLayoutType();
    }
  }

  const checkSectionType = () => {
    if (statusUpdateData?.verifier_status === 3 && secName === 0) {
      return true;
    } else if (statusUpdateData?.registration_status === 8 && secName === 1) {
      return true;
    } else if (statusUpdateData?.investigator_status === 6 && secName === 2) {
      return true;
    } else if (currentForm?.section_type && currentForm?.section_type >= 0) {
      return currentForm?.section_type === secName ? false : true
    } else if (!currentForm?.section_type && secName === 0) {
      return false;
    } else if (currentForm?.is_investigator_section && secName === 3) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <LinearProgressWithLabel
            total={forms?.length ?? 1}
            progress={currentIndex}
          />
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.header}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.formTitle}
            >
              <Tooltip title="View Section" placement="top">
                <IconButton
                  onClick={() => {
                    viewSection();
                  }}
                >
                  <MenuIcon color="secondary" />
                </IconButton>
              </Tooltip>
              {currentForm?.section_name}
            </Typography>
          </Grid>
          {
            <Grid item style={{ paddingRight: 8 }}>
              {currentForm?.is_skippable && (
                <Button
                  style={{ marginRight: 8 }}
                  size="small"
                  variant="text"
                  color="textSecondary"
                  disabled={savingJSON.loading}
                  onClick={() => {
                    onSkipBtnClicked(currentIndex, currentForm);
                  }}
                >
                  {"Skip"}
                </Button>
              )}

              {currentIndex !== 1 && (
                <Button
                  style={{ marginLeft: 8, marginRight: 8 }}
                  size="small"
                  variant="outlined"
                  color="secondary"
                  className={classes.savebtn}
                  disabled={savingJSON.loading}
                  onClick={() => onPrevBtnClicked(currentIndex, currentForm)}
                >
                  {"Prev"}
                </Button>
              )}

              {<Button
                id="objectbutton"
                size="small"
                variant="outlined"
                color="primary"
                className={classes.savebtn}
                disabled={
                  savingJSON.loading ? savingJSON.loading : currentIndex === forms.length && checkSectionType() ? true : false
                }
                onClick={() => onNextBtnClicked(currentIndex, currentForm)}
              >
                {getButtonName()}
              </Button>}

              {secName === 0 && !isReadyOnly && (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  className={classes.draftbtn}
                  disabled={checkSectionType()}
                  onClick={() =>
                    onNextBtnClicked(currentIndex, currentForm, true)
                  }
                >
                  Save as Draft
                </Button>
              )}
            </Grid>
          }
        </Grid>

        <Grid>
          <Prompt
            when={isChangesSaved === false && !allowDraft && (currentButton === "Submit" || (!checkSectionType() && !isReadyOnly))}
            message={
              `Are you sure you want to Leave the Form ? (click OK to Exit)`
            }
          />
        </Grid>

        {canIshowSmartQuestion(currentForm) && (
          <Grid item xs={12} className={classes.uiComponentWrapper}>
            <ComponentToRender
              data={currentForm?.framed_questions?.[0]}
              value={
                formsWithAnswer?.[currentForm?.framed_questions?.[0]?.id]
                  ?.answer ?? ""
              }
              index={0}
              onChange={onChange}
            />
          </Grid>
        )}

        <Divider variant="fullWidth" />
        <Grid container>
          {isSmartAnswerCheckNeed(currentForm) &&
            currentForm?.framed_questions &&
            currentForm?.framed_questions
              ?.filter((frQ) => frQ?.priority !== 0)
              .map((frQ, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={giveMeSM(frQ?.ui_component)}
                    md={giveMeMD(frQ?.ui_component)}
                    key={i}
                    className={classes.uiComponentWrapper}
                  >
                    <ComponentToRender
                      data={frQ}
                      isrequired={frQ.is_required}
                      value={formsWithAnswer?.[frQ.id]?.answer ?? ""}
                      index={i}
                      onChange={onChange}
                      isReadyOnly={checkSectionType()}
                      errorValidation={errorValidation[frQ.id]}
                      disabled={canIVisibleHere(frQ)}
                    />
                  </Grid>
                );
              })}
        </Grid>
      </Grid>
    </div>
  );
};
