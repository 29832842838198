import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ActivityError } from './error';

export const ActivityErrors = (props) => {


  const {
    count
  } = props;


  return <div>
    <Grid container direction='row' spacing={2}>
      {
        Array.apply(null, Array(count)).map((value, index) => <Grid key={index} item xs={12} >
          <ActivityError />
        </Grid>)
      }
    </Grid>
  </div>
}

ActivityErrors.propTypes = {
  count: PropTypes.number
}

ActivityErrors.defaultProps = {
  count: 1
}