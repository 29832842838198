import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { TrackerError } from './error';

export const ProductErrors = (props) => {


  const {
    count
  } = props;


  return <div>
    <Grid container direction='row'>
      {
        Array.apply(null, Array(count)).map((value, index) => <Grid key={index} item xs={12}>
          <TrackerError />
        </Grid>)
      }
    </Grid>
  </div>
}

ProductErrors.propTypes = {
  count: PropTypes.number
}

ProductErrors.defaultProps = {
  count: 1
}