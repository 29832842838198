import React from "react";
import { Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import ActivityAndMembersCard from "../../../../components/availabilityActivityComponents/ActivityAndMembersCard";
import moment from "moment";
import AddEditActivity from "../addEditActivity";
import AddEditAvailability from "../addEditAvailability";

export const colorCode = {
  PENDING: "#bc2d4f",
  COMPLETED: "#31b198",
};

class ConflictList extends React.Component {
  onBackBtnClicked = () => {
    const parentState = this.props.parentState;
    if(this.props.isActivity){

      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: true,
        component: (
          <AddEditActivity
            {...this.props}
            persistState={true}
            parentState={parentState?.currentAllComponentState}
          />
        ),
      });
    }
    else{
      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: true,
        component: (
          <AddEditAvailability
            {...this.props}
            persistState={true}
            parentState={parentState?.currentAllComponentState}
          />
        ),
      }); 
    }
  };

  render() {
    const { activityConflict } = this.props;
    
    const { Activities, UnAvailability } = activityConflict?.data?.data;

    return (
      <Grid container>
        {/* Activity + Backbutton */}
        <div style={{ padding: 4, width: "100%" }}>
          <Grid item container xs={12} spacing={1}>
            <Grid item>
              <IconButton onClick={this.onBackBtnClicked}>
                <ChevronLeft />
              </IconButton>
            </Grid>
            <Grid item xs container alignItems="center">
              <Typography variant={"h6"}>Conflicts</Typography>
            </Grid>
            <Grid item xs={12} container alignItems="center">
              <Typography
                variant={"h6"}
                style={{ marginLeft: 12, fontSize: "1.20rem" }}
              >
                {Activities?.length ?? 0} in Activity
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Grid item Container xs={12}>
          {Activities?.map((activity, index) => {
            return (
              <ActivityAndMembersCard
                key={index}
                isLastItem={UnAvailability?.length - 1 === index ? true : false}
                title={activity?.title}
                activityStatus={activity?.activityType?.[0]?.activityTypeName}
                activityStatusColor="#28a9c1"
                timing={`${moment(activity?.fromTimeStamp).format(
                  "DD/MM/YYYY hh:mm A"
                )} - 
                   ${moment(activity?.toTimeStamp).format(
                     "DD/MM/YYYY hh:mm A"
                   )}`}
                currentStatus={activity?.activityStatus}
                currentStatusColor={colorCode[activity?.activityStatus]}
                hideIsActive={true}
                hideEndIcon
              />
            );
          })}
        </Grid>

        <Grid item xs={12}>
          <Divider light style={{ margin: "20px 0px" }} />
        </Grid>

        {/* Unavailability */}
        <div style={{ padding: 4, width: "100%" }}>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} container alignItems="center">
              <Typography
                variant={"h6"}
                style={{ marginLeft: 12, fontSize: "1.20rem" }}
              >
                {UnAvailability?.length ?? 0} in Unavailability
              </Typography>
            </Grid>
          </Grid>
        </div>

        <Grid item Container xs={12}>
          {UnAvailability?.map((unav, index) => {
            return (
              <ActivityAndMembersCard
                key={index}
                isLastItem={UnAvailability?.length - 1 === index ? true : false}
                useActivityAsViewCaregivers
                title={
                  unav?.service_professional?.label !== null
                    ? unav?.service_professional?.label
                    : ""
                }
                profilePicture={null}
                timing={`${moment(unav?.from).format("DD/MM/YYYY hh:mm A")} - 
                   ${moment(unav?.to).format("DD/MM/YYYY hh:mm A")}`}
                hideEndIcon={true}
              />
            );
          })}
        </Grid>
      </Grid>
    );
  }
}

export default withAlelrtAndDialog(ConflictList);
