import React from "react";
import {
  Grid,
  Avatar,
  Typography,
  withStyles,
  Paper,
  Badge,
  Divider,
} from "@material-ui/core";
// import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
// import Tooltip from '@material-ui/core/Tooltip';
// import WarningIcon from '@material-ui/icons/Warning';
import ListComponent from "./listComponent";
import PropTypes from "prop-types";
import { avatarColorBgById, capitalizeFirstLetter } from "../../utils";
import PopperPopupState from "../popper/PopperPopupState";
import styles from "./styles/cardListStyles";
import { StyledChip } from "./styles/cardListStyles";
import moment from "moment";
import { CustomAvatar } from "../niceComponents";
// import { ItemStatus } from "../catalogue/catalogueDetail";
class CardList extends React.Component {
  constructor(props) {
    super();
    this.state = {
      anchorEl: null,
    };
  }

  //<----- Menu open and close Handlers Starts
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleEdit = () => {
    this.setState({ anchorEl: null });
    this.props.handleEdit(this.props?.list?.id);
  };

  // const handleDelete = () => {
  //     setAnchorEl(null);
  //     props.handleDelete(props.data);
  // }

  handleDeactivate = () => {
    this.setState({ anchorEl: null });
    this.props.handleDeactivate(this.props.list);
  };
  // -----> Menu open and close Handlers Ends

  handleResend = () => {
    this.setState({ anchorEl: null });
    this.props.handleResend(this.props?.list?.id);
  };

  render() {
    const {
      classes,
      list,
      componentOrder,
      propsList,
      hideDeactivateEditButtons,
      // handlePopperClose,
      // handlePopperOpen,
      open,
      handleCategoryPopoverClose,
      handleCategoryPopoverOpen,
      isShowGender,
      isShowParent,
      isShowAge,
      isShowRole,
      isShowGenderAge,
      avatarFallbackAsImage = false,
      // emailVerified,
      // isShowVerified
    } = this.props;

    const {
      isonlycard,
      isbottom,
      isCard,
      // temporaryClientChip,
      isSalutationNeeded,
    } = propsList; //labelProvided
    let isActive =
      String(list?.isactive).toLowerCase() === "InActive".toLowerCase()
        ? false
        : list?.isactive;

    return (
      <Paper
        id={this.props?.id}
        className={
          isonlycard
            ? isbottom
              ? classes.noCardBottom
              : classes.noCardTop
            : classes.cardPaper
        }
      >
        <Grid container>
          {/* Left Pole */}
          <Grid
            item
            container
            xs={12}
            sm
            onClick={() => {
              if (this.props?.navigateToView)
                return this.props?.navigateToView(list.id);
            }}
          >
            {/* Avatar */}
            <Grid item className={classes.avatarWrapper}>
              {/* <Avatar
                className={classes.avatar}
                style={{
                  backgroundColor: !list?.profile_pic
                    ? avatarColorBgById(
                        list?.id ??
                          Math.floor(Math.random() * (999 - 100 + 1) + 100)
                      )
                    : "",
                }}
                src={list?.profile_pic}
              >
                {list?.name?.trim()?.[0]?.toUpperCase()}
              </Avatar> */}
              <CustomAvatar 
                src={list?.profile_pic}
                name={list?.name?.trim()?.[0]?.toUpperCase()}
                id={list?.id}
                loading={false}
                fallbackAsImage={avatarFallbackAsImage}
              />
            </Grid>
            <Grid item xs container alignItems={"center"}>
              <Grid item>
                <div className={classes.typoSpacing}>
                  <Typography className={classes.user_name}>
                    {!isSalutationNeeded
                      ? list?.name
                        ? list?.name
                        : "N/A"
                      : `${list?.salutation ? `${list?.salutation}.` : ""} ${
                          list?.name ? list?.name : "N/A"
                        }
                        `}
                    {/* {
                      // console.log(list)
                      list.subscription_status ? (
                        <span
                          className={
                            list.subscription_status
                              ? ""
                              : classes.email_not_verified
                          }
                          style={{marginLeft: 10}}
                        >
                          <Badge
                            overlap="circle"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={
                              <div
                                className={`${classes.status_circle} ${
                                  !list.subscription_status
                                    ? classes.email_not_verified_circle
                                    : classes.email_verified_circle
                                }`}
                              />
                            }
                          />
                          <Typography
                            className={`${classes.status_name} ${
                              !list.subscription_status
                                ? classes.email_not_verified_name
                                : classes.email_verified_name
                            }`}
                          >
                            {list.subscription_status ? "ACTIVE" : "INACTIVE"}
                          </Typography>
                        </span>
                      ) : (
                        <></>
                      )
                    } */}
                  </Typography>

                  {list.emailVerified || list.emailVerified == null ? (
                    <>
                      {isActive !== undefined && (
                        <div
                          className={
                            isActive ? classes.isactive : classes.inactive
                          }
                        >
                          <Badge
                            overlap="circle"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={
                              <div
                                className={`${classes.status_circle} ${
                                  isActive
                                    ? classes.activecircle
                                    : classes.inactivecircle
                                }`}
                              />
                            }
                          />
                          <Typography
                            className={`${classes.status_name} ${
                              isActive
                                ? classes.active_name
                                : classes.inactive_name
                            }`}
                          >
                            {isActive ? "ACTIVE" : "INACTIVE"}
                          </Typography>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      className={
                        list.emailVerified ? "" : classes.email_not_verified
                      }
                    >
                      <Badge
                        overlap="circle"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <div
                            className={`${classes.status_circle} ${
                              list.emailVerified
                                ? classes.email_not_verified_circle
                                : classes.email_verified_circle
                            }`}
                          />
                        }
                      />
                      <Typography
                        className={`${classes.status_name} ${
                          list.emailVerified
                            ? classes.email_not_verified_name
                            : classes.email_verified_name
                        }`}
                      >
                        {list.emailVerified ? "EMAIL FAILED" : "INVITED"}
                      </Typography>
                    </div>
                  )}

                  {/* Email Verified Tag */}
                  {/* {isShowVerified && (

                        <Typography
                          color={"textSecondary"}
                          variant={"caption"}
                          alignItems={"center"}
                        > 
                          {list.activestatus 
                            ? <Tooltip title="Email Verified" placement="right"><VerifiedUserIcon fontSize={"small"} style={{color:"green", marginLeft: 5}}/></Tooltip> 
                            : <Tooltip title="Email Not Verified" placement="right"><WarningIcon fontSize={"small"} style={{color:"red", marginLeft: 5}}/></Tooltip>
                          }
                        </Typography>

                    
                  )} */}
                </div>
              </Grid>

              {list?.category?.length === 0 ? (
                <> </>
              ) : (
                (this.props.showCareGiver ||
                  list?.category?.length > 0 ||
                  this.props.displayProvider ||
                  this.props.showCareGiver) && (
                  <Grid item container>
                    {/* Changing the service text background */}
                    {/* Here a condition can be added at the place of rendering from the map return as if (category count < 3 then render else render a null .) */}
                    <>
                      {this.props.showCareProvider ? (
                        <>
                          {list?.care_provider?.constructor === Array ? (
                            // If the care provider is an array then render this.
                            list?.care_provider?.length > 0 ? (
                              <>
                                {list?.care_provider.slice(0, 1).map((val) => {
                                  return (
                                    <Grid
                                      item
                                      // className={
                                      //   isCard
                                      //     ? classes.categoryAsCard
                                      //     : classes.categoryAsText
                                      // }
                                    >
                                      {/* <Typography className={classes.secondary}>
                                        {val?.name ? val?.name : "N/A"}
                                      </Typography> */}
                                      {/* TODO: If needed, add a no card version too.  */}
                                      <StyledChip
                                        label={
                                          list?.care_provider?.name
                                            ? list?.care_provider?.name
                                            : "N/A"
                                        }
                                        className={`${
                                          !isCard && classes.chipAsText
                                        }`}
                                      />
                                    </Grid>
                                  );
                                })}
                                <PopperPopupState
                                  open={open}
                                  handleClose={() =>
                                    handleCategoryPopoverClose()
                                  }
                                  handleOpen={(e) => {
                                    handleCategoryPopoverOpen(
                                      e,
                                      list?.care_provider
                                    );
                                  }}
                                  data={this.props?.popperData}
                                  count={list?.care_provider?.length - 2}
                                />
                              </>
                            ) : (
                              <></>
                              // <Grid
                              //   item
                              //   className={
                              //     isCard
                              //       ? classes.categoryAsCard
                              //       : classes.categoryAsText
                              //   }
                              // >
                              //   <Typography className={classes.secondary}>
                              //     {""}
                              //   </Typography>
                              // </Grid>
                            )
                          ) : (
                            // If the care provider is not an array then render this.
                            <Grid
                              item
                              // className={
                              //   isCard
                              //     ? classes.categoryAsCard
                              //     : classes.categoryAsText
                              // }
                            >
                              {/* <Typography className={classes.secondary}>
                                {list?.care_provider?.name
                                  ? list?.care_provider?.name
                                  : "N/A"}
                                  
                              </Typography> */}
                              <StyledChip
                                label={
                                  list?.care_provider?.name
                                    ? list?.care_provider?.name
                                    : "N/A"
                                }
                                className={`${!isCard && classes.chipAsText}`}
                              />
                            </Grid>
                          )}
                        </>
                      ) : list?.category?.length > 0 ? (
                        <>
                          {isShowRole && list?.role?.length > 0 && (
                            <>
                              <Grid item>
                                <Typography
                                  variant={"subtitle2"}
                                  color="textSecondary"
                                  style={{ fontWeight: "700" }}
                                >
                                  {list?.role?.[0]?.label}
                                </Typography>
                              </Grid>
                              <Grid item style={{ margin: "0px 12px" }}>
                                <Divider orientation="vertical" light />
                              </Grid>
                            </>
                          )}
                          {isShowGender &&
                            (list?.gender?.label || list?.gender) && (
                              <>
                                <Grid item>
                                  <Typography
                                    variant={"subtitle2"}
                                    color="textSecondary"
                                  >
                                    {list?.gender?.label
                                      ? list?.gender?.label
                                      : list?.gender
                                      ? list?.gender
                                      : "N/A"}
                                  </Typography>
                                </Grid>
                                <Grid item style={{ margin: "0px 12px" }}>
                                  <Divider orientation="vertical" light />
                                </Grid>
                              </>
                            )}

                          {isShowAge && list?.dob && (
                            <>
                              <Grid item>
                                <Typography
                                  variant={"subtitle2"}
                                  color="textSecondary"
                                >
                                  {moment().diff(list?.dob, "years") === 0
                                    ? moment().diff(list?.dob, "days") +
                                      " days old"
                                    : moment().diff(list?.dob, "years") +
                                      " years old"}
                                </Typography>
                              </Grid>
                              <Grid item style={{ margin: "0px 12px" }}>
                                <Divider orientation="vertical" light />
                              </Grid>
                            </>
                          )}
                          {isShowParent && (
                            <>
                              <Grid item>
                                <Typography
                                  variant={"subtitle2"}
                                  color="textSecondary"
                                >
                                  {list?.parent?.name}
                                </Typography>
                              </Grid>
                              <Grid item style={{ margin: "0px 12px" }}>
                                <Divider orientation="vertical" light />
                              </Grid>
                            </>
                          )}
                          {(list?.category?.length > 2
                            ? [...list?.category].splice(0, 2)
                            : list?.category
                          ).map((categoryItem, index) => {
                            // if (index < 3) {
                            return (
                              <>
                                <Grid
                                  item
                                  key={"category" + index}
                                  style={{ marginRight: "5px" }}
                                  // className={
                                  //   isCard
                                  //     ? list.category.length > 3
                                  //       ? classes.minLimitExceeded
                                  //       : classes.categoryAsCard
                                  //     : classes.categoryAsText
                                  // }
                                >
                                  {categoryItem.label && (
                                    // <Typography className={classes.secondary}>
                                    //   {categoryItem.label
                                    //     ? categoryItem.label
                                    //     : "N/A"}
                                    // </Typography>
                                    <StyledChip
                                      label={
                                        categoryItem.label
                                          ? categoryItem.label
                                          : "N/A"
                                      }
                                      className={`${
                                        !isCard && classes.chipAsText
                                      }`}
                                    />
                                  )}
                                </Grid>
                              </>
                            );
                            // }
                            // return <></>;
                          })}
                          {list?.category?.length > 2 && (
                            <PopperPopupState
                              open={open}
                              handleClose={() => handleCategoryPopoverClose()}
                              handleOpen={(e) => {
                                handleCategoryPopoverOpen(e, list?.category);
                              }}
                              data={this.props?.popperData}
                              count={list?.category?.length - 2}
                              paddingX={0}
                            />
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {/* Checking if any category exists + is provider need to be displayed + is careprovider name exists. */}
                      {list?.category?.length > 0 &&
                        this.props.displayProvider &&
                        list?.care_provider?.[0]?.name && (
                          <>
                            <Grid item style={{ margin: "0px 12px" }}>
                              <Divider orientation="vertical" light />
                            </Grid>
                          </>
                        )}

                      {/* Initially added to display provider in service coordinator and service professional. 
                          Use this to display service provider next to category chips. */}
                      {this.props.displayProvider &&
                        // Check if provider name is available
                        list?.care_provider?.[0]?.name && (
                          <>
                            <Grid item xs container alignItems={"center"}>
                              <Typography
                                color={"textSecondary"}
                                variant={"caption"}
                              >
                                {list.care_provider[0].name
                                  ? list.care_provider[0].name
                                  : "N/A"}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      {/* displayProvider block ends */}
                    </>

                    {/* This UI Code block shows only care giver name */}
                    {this.props?.showCareGiver && (
                      <>
                        {this.props?.list?.care_giver?.map?.((cg, key) => (
                          <>
                            {cg.name ? (
                              <Grid
                                item
                                style={{ marginBottom: 2 }}
                                key={key}
                                className={
                                  isCard
                                    ? classes.categoryAsCard
                                    : classes.categoryAsText
                                }
                              >
                                <Typography className={classes.secondary}>
                                  {'cg.name ? cg.name : "N/A"'}
                                </Typography>
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </>
                    )}
                  </Grid>
                )
              )}
              {isShowGenderAge && (
                <Grid item container>
                  {(list?.gender?.label || list?.gender) && (
                    <>
                      <Grid item>
                        <Typography variant={"subtitle2"} color="textSecondary">
                          {list?.gender?.label
                            ? capitalizeFirstLetter(list?.gender?.label)
                            : list?.gender
                            ? capitalizeFirstLetter(list?.gender)
                            : "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item style={{ margin: "0px 12px" }}>
                        <Divider orientation="vertical" light />
                      </Grid>
                    </>
                  )}
                  {list?.dob ? (
                    <>
                      <Grid item>
                        <Typography variant={"subtitle2"} color="textSecondary">
                          {moment().diff(list?.dob, "years") === 0
                            ? moment().diff(list?.dob, "days") + " days old"
                            : moment().diff(list?.dob, "years") + " years old"}
                          {/* {`${list?.age}  ${list?.age > 1 ? ' years' : ' year '}`} */}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              )}
              {/* This code block is used in Monitoring subscriptions */}
              {list?.client_info && (
                <div style={{ display: "flex", paddingLeft: 8 }}>
                  <Avatar
                    src={list?.client_info?.client_profile_picture}
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 8,
                      backgroundColor: !list?.client_info
                        ?.client_profile_picture
                        ? avatarColorBgById(
                            list?.id ??
                              Math.floor(Math.random() * (999 - 100 + 1) + 100)
                          )
                        : "",
                    }}
                  >
                    {list?.client_info?.client_name?.trim()?.[0].toUpperCase()}
                  </Avatar>
                  <Typography variant="caption" color="textSecondary">
                    {list?.client_info?.client_name
                      ? list?.client_info?.client_name
                      : "N/A"}
                  </Typography>
                </div>
              )}
              {/* </Grid> */}
            </Grid>
          </Grid>

          {/* Right Pole */}
          <Grid item container xs={12} sm spacing={3} justify={"flex-end"}>
            {list?.orderList &&
              list?.orderList.map((val, i) => {
                return (
                  <>
                    <Grid item>
                      <Divider light orientation={"vertical"} />
                    </Grid>
                    <Grid
                      item
                      key={"cardCount" + i}
                      // md={sizes.md ? sizes.md : 3}
                      // sm={sizes.sm ? sizes.sm : 6}
                      // xs={sizes.xs ? sizes.xs : 3}
                      // xs={12}
                      // sm
                      className={classes.orderList}
                      onClick={() => {
                        if (this.props?.navigateToView)
                          return this.props?.navigateToView(list.id);
                      }}
                    >
                      <Grid item className={classes.marginAuto}>
                        <Typography
                          variant={this.props.countAsBody1 ? "body1" : "h6"}
                        >
                          {val.count !== undefined
                            ? val.count
                            : Array.isArray(val?.value)
                            ? 0
                            : parseInt(val.value)}
                        </Typography>
                        <Typography className={classes.secondary}>
                          {val.name !== undefined ? val.name : val.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                );
              })}

            {
              <>
                <Grid item>
                  <div
                    onClick={() => {
                      if (this.props?.navigateToView)
                        return this.props?.navigateToView(list.id);
                    }}
                    className={classes.rightPoleDetailsButton}
                  >
                    <ListComponent
                      order={componentOrder}
                      onMapResourceClicked={this.props?.onMapResourceClicked}
                      mapResourceLabel={this.props?.mapResourceLabel}
                      {...this.props}
                      navigateToView={this.props?.navigateToView}
                      hideDeactivateEditButtons={hideDeactivateEditButtons}
                      emailVerified={list.emailVerified}
                      isInEmailVerified={!list.emailVerified}
                      isInActive={!isActive}
                      handleAddServiceProfessional={
                        this.props.handleAddServiceProfessional
                      }
                      // isSendingReinvite={this.props.isSendingReinvite}
                      hideQuickSummary={this.props.hideQuickSummary}
                      isdontshowactivebtn={this.props.isdontshowactivebtn}
                      hideQuickSummaryLabel={this.props.hideQuickSummaryLabel}
                      showAddIcon={this.props.showAddIcon}
                      onAddIconClicked={this.props.onAddIconClicked}
                      showThirdBtn={this.props?.showThirdBtn}
                      canShowButton={this.props?.canShowButton}
                      thirdBtnTitle={this.props?.thirdBtnTitle}
                      onThirdBtnClicked={this.props.onThirdBtnClicked}
                      isShowActivate={this.props?.isShowActivate}
                      deactivateActivateButtonId={
                        this.props?.deactivateActivateButtonId
                      }
                      editButtonId={this.props?.editButtonId}
                      addBranchButtonId={this.props?.addBranchButtonId}
                      resendButtonId={this.props?.resendButtonId}
                      ThirdButtonId={this.props?.ThirdButtonId}
                    />
                  </div>
                </Grid>
              </>
            }
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(CardList);

CardList.propTypes = {
  /**Used to display provider in card. */
  displayProvider: PropTypes.bool,
  /**
   * To hide quick summary portion of the card
   */
  hideQuickSummary: PropTypes.bool,
  /**
   * To hide Quick Summary Label
   */
  hideQuickSummaryLabel: PropTypes.bool,
};
