import React from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core'
import { PriceTagIcon } from '../../../assets'
import moment from 'moment'

export * from './productTrackLoaders'
export * from './productErrors'

const styles = (theme) => ({
  root: {
    background: 'white',
    padding: theme.spacing(4),
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    borderBottom: `2px solid ${theme.palette.secondary.light}`
  },
  imageStyle: {
    marginRight: 15
  },
  flexRightSide: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  iconStyle: {
    marginRight: 10
  },
  noImageHolder: {
    display: 'block',
    margin: 'auto',
    height: 60,
    width: 60,
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 4,
    marginRight: 15
  },
})

class ProductsTracking extends React.Component {
  render() {
    const { classes } = this.props
    const { product_name, product_price, current_status, current_status_date, product_image } = this.props
    return (
      <Grid container className={classes.root} >
        <Grid item container xs>
          <Grid item>
            {product_image ? <img src={product_image} alt="image1" height="60px" width="60px" className={classes.imageStyle} /> : <div className={classes.noImageHolder} > </div>}
          </Grid>
          <Grid item style={{ margin: 'auto 0px' }}>
            <Typography variant="body1">{product_name}</Typography>
            <Typography variant="subtitle2" color={"textSecondary"} style={{ marginTop: 4 }}>{`${current_status} on ${current_status_date ? moment(current_status_date).format('MMM DD, YYYY') : 'N/A'}`}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <div className={classes.flexRightSide}>
            <PriceTagIcon className={classes.iconStyle} />
            <Typography variant="body1">{product_price}</Typography>
          </div>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(ProductsTracking)