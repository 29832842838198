import React from "react";
import { makeStyles, InputLabel, Typography } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  container: {
    margin: "8px 0px",
  },
  root: {
    display: "block",
    padding: "0px !important",
    margin: "8px 0px 0px 0px",
    "& ul": {
      listStyle: "none",
    },
    "& li": {
      display: "inline-flex",
      marginRight: 16,
      marginBottom: 16,
    },
    "& label": {
      cursor: "pointer",
      borderRadius: 8,
      border: `1.5px solid ${theme.palette.secondary.light}`,
      padding: "6px 20px",
      transition: "all 0.2s ease-out",
    },
    "& input:checked + label": {
      transition: "all 0.3s ease-in",
      background: theme.palette.ternary.main,
      border: `1.5px solid ${theme.palette.ternary.main}`,
      color: "white",
    },
  },
  fixMargin: {
    marginBottom: "0px !important",
  },
  label: {
    color: theme.palette.text.label,
    textTransform: "uppercase",
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
    marginBottom: (props) =>
      props?.removeMarginBetweenErrorAndComponent ? 14 : 0,
  },
  disabled: {
    background: '#f2f2f2',
    // cursor: 'not-allowed !important',
    color: '#999'
  },
}));

export const CustomizedRadioButton = (props) => {
  const {
    data,
    handleOnChange,
    value,
    error,
    errorText,
    removeMarginBetweenErrorAndComponent,
    disabled,
    id,
  } = props;
  const classes = styles(props);
  // 
  // console.log(disabled)

  const handleChange = (id) => {
    const getElement = document.getElementById(id);
    let checkedElement;
    if (getElement.checked) checkedElement = getElement.value;
    else checkedElement = null;

    handleOnChange(checkedElement, data.key);
  };
  return (
    <div className={classes.container}>
      <InputLabel shrink htmlFor={data.label} className={classes.label}>
        {data.label}
      </InputLabel>
      <ul
        className={`${classes.root} ${
          error && removeMarginBetweenErrorAndComponent ? classes.fixMargin : ""
        } `}
        // id={data.label}
        id={id}
      >
        {data.options?.map((opt, index) => {
          return (
            <li
              className={`${
                index === data.options.length - 1 ? classes.fixMargin : ""
              } `}
              onClick={
                !disabled
                  ? () => {
                      handleChange(`${data.label} ${index}`);
                    }
                  : () => {
                      return false;
                    }
              }
            >
              <input
                type="radio"
                checked={opt.value === value ? true : false}
                value={opt.value}
                name={data.label}
                id={`${data.label} ${index}`}
                // id={props.id}
                hidden
                disabled={disabled ?? false}
              />
              <label className={`${disabled && classes.disabled}`} for={`${data.label} ${index}`}>{opt.label}</label>
            </li>
          );
        })}
      </ul>
      {error && (
        <Typography
          variant="body1"
          component="div"
          className={classes.errorText}
        >
          {errorText}
        </Typography>
      )}
    </div>
  );
};
