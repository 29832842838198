import React, { useRef } from "react";
import {
  Typography,
} from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FamilyMemberCard } from "../../../../components";
import { withAlertAndDialog } from "../../../../HOCs";
import ViewFamilyMemberDetails from "./viewFamilyMemberDetails";

function Carousel(props) {
  // const theme = useTheme();
  // const above700 = useMediaQuery(theme.breakpoints.up("700"));

  const { data } = props;

  const getFormattedData = () => {
    debugger;
    if (data) {
      if (data?.length < 3) {
        let sld = [...data];
        let fill = 3 - data?.length;
        for (let i = 0; i < fill; i++) {
          sld.push("");
        }
        console.log(
          "=================================SLD============================"
        );
        console.log(sld);
        return sld;
      }
      return data;
    }
    return [];
  };

  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    // centerPadding: "30px",
    // autoplay: true,
    // autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };

  // const previous = () => {
  //   sliderRef.current.slickPrev();
  // };

  // const next = () => {
  //   sliderRef.current.slickNext();
  // };

  const handleViewMore = (data) => {
    props.drawer.setDrawer({
      open: true,
      component: <ViewFamilyMemberDetails data={data} />,
      isMedium: true,
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 12,
        }}
      >
        <Typography style={{fontWeight: "bold"}}>Family Members Details</Typography>
        {/* {above700 && data?.length > 3 &&  (
          <div style={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
            <IconButton onClick={previous} aria-label="previous" size="small">
              <ChevronLeftIcon sx={{ color: "text.primary" }} />
            </IconButton>
            <IconButton onClick={next} aria-label="next" size="small">
              <ChevronRightIcon sx={{ color: "text.primary" }} />
            </IconButton>
          </div>
        )} */}
      </div>

      {Array.isArray(data) && data?.length > 0 ? (
        <div style={{ maxWidth: "100%" }}>
          <Slider ref={(slider) => (sliderRef.current = slider)} {...settings}>
            {getFormattedData()?.map((mem, index) => {
              if (index + 1 > data?.length) {
                return <div></div>;
              }
              return (
                <FamilyMemberCard
                  key={mem?.id}
                  id={mem?.id}
                  data={mem}
                  onViewMoreClicked={() => handleViewMore(mem)}
                />
              );
            })}
          </Slider>
        </div>
      ) : (
        <Typography color="textSecondary" style={{ fontStyle: "italics" }}>
          Not updated
        </Typography>
      )}
    </div>
  );
}

export default withAlertAndDialog(Carousel);
