import React from 'react'
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { companyDetails, companyDetailsQuotation } from '../../../../../utils/constants'
import NiceTable from '../../../../../components/niceTable';
import moment from 'moment'

const quoteStyles = makeStyles((theme) => ({
    root: {
        gap: 18
    },
    toplogo: {
        // paddingLeft: 16,
        // paddingTop: 16,
        width: 150,
    },
    boldText: {
        fontWeight: 600
    },
    underlineText: {
        textDecoration: "underline"
    },
    gutterBottom: {
        marginBottom: 18
    },
    quoteTitle: {
        // border: `4px solid ${theme.palette.primary.main}`
    },
    clientInfo: {
        display: "flex",
        justifyContent: "space-between",
        marginRight: 16
    },
    clientInfoGutterRight: {
        marginRight: 20
    },
    clientInfoRightWrapper: {
        alignSelf: "flex-start"
    },
    tcRoot: {
        marginTop: 8,
        paddingLeft: 20,
        
    },
    tcItem: {
        fontSize: "0.875rem",
        fontStyle: "italic",
        // fontWeight: "bold",
        width: "fit-content",
        margin: "auto"
    },
    tcSubConditionRoot: {
        listStyleType: "none",
        paddingLeft: 16,
        "& li": {
            "&:before": {
                content: `"-"`,
            }
        },
    },
    tcSubConditionItem: {
        display: "flex"
    }
}))

export const getQuoteData = (data) => {
    
    return [
        {
            label: "Quotation No.",
            value: data?.quotesTag
        },
        {
            label: "Date",
            value: moment(data?.dateandTime)?.format("DD-MMM-YYYY")
        }
    ]
}

const getInvoiceData = (data) => {
    return [
        {
            label: "Invoice No.",
            value: "I123"
        },
        {
            label: "Invoice Date",
            value: "27/06/2021"
        },
        {
            label: "Quotation No.",
            value: "Q123"
        },
        {
            label: "Customer A/C No.",
            value: "546662235412"
        },
        {
            label: "Payor A/C No.",
            value: "116662235412"
        },
        {
            label: "Payment Terms",
            value: "Billing"
        },
    ]
}

export function QuoteInvoiceRenderer({ data, columnsToPrint, createRows, handleRemove, isInvoice }) {
    const classes = quoteStyles()
    const quoteDetails = isInvoice ? getInvoiceData(data) : getQuoteData(data)
    
    // console.log(data?.taxAmount)
    
    return (
        <Grid container className={classes.root}>
            {/* Header */}
            <Grid item xs={12}>
                {/* Logo */}
                <img
                    src="/images/tanyaCare/TanyaHorizontal.png"
                    alt="Tanya.Care"
                    className={classes.toplogo}
                />
                <Typography><span className={classes.boldText}>{companyDetails.name}</span>{` (Company Registration No : ${companyDetails.regNumber})`}</Typography>
                <Typography>{companyDetails.address}</Typography>
            </Grid>

            {/* Body Content */}
            <Grid container item xs={12}>
                <Grid item xs={12} className={classes.gutterBottom}>
                    <Typography align="center" className={`${classes.boldText} ${classes.quoteTitle}`}>{isInvoice ? "OFFICIAL INVOICE" : "QUOTATION"}</Typography>
                </Grid>

                {/* Quote Client left side details */}
                {!isInvoice && <Grid item container xs={6}>
                    <QuoteClientDetails data={data} />
                </Grid>}


                {/* Invoice Client left side details */}
                {isInvoice && <Grid item container xs={6}>
                    <InvoiceClientDetails data={data} />
                </Grid>}

                {/* Quote right side details */}
                <Grid container item xs={6} className={`${classes.gutterBottom} ${classes.clientInfoRightWrapper}`}>
                    {quoteDetails.map(detail => (
                        <Grid container item xs={12} justify="flex-end">
                            <Grid item xs={3}>
                                <div className={classes.clientInfo}>
                                    <Typography>{detail?.label}</Typography>
                                    <Typography>:</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>{detail?.value}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>

                {/* Table */}
                <Grid item xs={12}>
                    {/* Render Table here */}
                    <NiceTable columns={columnsToPrint} rows={createRows(data ? data : [])} total={data?.total} taxAmount={data?.taxAmount} discount={data?.discount} />
                </Grid>

            </Grid>

            {/* Footer */}
            <Grid item xs={12}>
                {/* <Typography className={classes.boldText}>Terms & Conditions:</Typography> */}
                <ol className={classes.tcRoot}>
                {isInvoice ?
                    companyDetails.termsAndConditions.map(tc => (
                        <>
                            <li style={{"list-style-type" : "none"}}>
                                <Typography className={classes.tcItem}>** {tc.condition} **</Typography>
                            </li>
                            {tc?.subConditions?.length > 0 &&
                                <ul className={classes.tcSubConditionRoot}>
                                    {tc.subConditions.map(sub => (
                                        <li className={classes.tcSubConditionItem}>
                                            <Typography className={classes.tcItem}>{sub.label}</Typography>
                                            &nbsp;
                                            <Typography className={`${classes.boldText} ${classes.tcItem}`}>{sub.value}</Typography>
                                        </li>
                                    ))}
                                </ul>
                            }
                        </>
                    ))
                    : companyDetailsQuotation.termsAndConditions.map(tc => (
                        <>
                            <li style={{"list-style-type" : "none"}}>
                                <Typography className={classes.tcItem}>** {tc.condition} **</Typography>
                            </li>
                            {tc?.subConditions?.length > 0 &&
                                <ul className={classes.tcSubConditionRoot}>
                                    {tc.subConditions.map(sub => (
                                        <li className={classes.tcSubConditionItem}>
                                            <Typography className={classes.tcItem}>{sub.label}</Typography>
                                            &nbsp;
                                            <Typography className={`${classes.boldText} ${classes.tcItem}`}>{sub.value}</Typography>
                                        </li>
                                    ))}
                                </ul>
                            }
                        </>
                    ))}
                </ol>
            </Grid>
        </Grid>
    )
}

export function QuoteClientDetails({ data }) {
    
    const classes = quoteStyles()
    let name = data?.name.split(".")
    const clientInfo = [
        {
            label: "Name",
            value: name[0] === "other" ? name[1] : data?.name
        },
        {
            label: "Address",
            value: data?.address ?? "N/A"
        },
        {
            label: "Client ID",
            value: data?.clientHealthId ?? "N/A"
        },
        {
            label: "Contact No.",
            value: data?.mobile
        },
        {
            label: "Email ID",
            value: data?.email
        },
    ]
    return (
        <>
            <Grid item xs={6}>
                <Typography className={`${classes.boldText} ${classes.underlineText}`} >Client</Typography>
            </Grid>
            <Grid container item xs={12} className={classes.gutterBottom}>
                {clientInfo?.map(info => (
                    < Grid container item xs={12} >
                        <Grid item xs={3}>
                            <div className={classes.clientInfo}>
                                <Typography>{info?.label}</Typography>
                                <Typography>:</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography className={classes.clientInfoGutterRight}>{info?.value}</Typography>
                        </Grid>
                    </Grid >
                ))}
            </Grid>
        </>
    )
}

function InvoiceClientDetails() {
    const classes = quoteStyles()
    return (
        <>
            <Grid item xs={12}>
                <Typography className={`${classes.boldText} ${classes.underlineText}`} >By</Typography>
            </Grid>
            <Grid container item xs={12} className={classes.gutterBottom}>
                {/* Name */}
                <Grid container item xs={12} >
                    <Grid item xs={3}>
                        <div className={classes.clientInfo}>
                            <Typography>Name</Typography>
                            <Typography>:</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.clientInfoGutterRight}>JENNIFER RICHARD</Typography>
                    </Grid>
                </Grid>

                {/* Address */}
                <Grid container item xs={12} >
                    <Grid item xs={3}>
                        <div className={classes.clientInfo}>
                            <Typography>Address</Typography>
                            <Typography>:</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.clientInfoGutterRight}>NO.5 JALAN PASIT PUTIH TELUK PANGLIMA selangor Darul Ehsan, 40190</Typography>
                    </Grid>
                </Grid>

                {/* Client ID */}
                <Grid container item xs={12} >
                    <Grid item xs={3}>
                        <div className={classes.clientInfo}>
                            <Typography>Client ID</Typography>
                            <Typography>:</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.clientInfoGutterRight}>CL123</Typography>
                    </Grid>
                </Grid>

                {/* Contact Number */}
                <Grid container item xs={12} >
                    <Grid item xs={3}>
                        <div className={classes.clientInfo}>
                            <Typography>Contact No.</Typography>
                            <Typography>:</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.clientInfoGutterRight}>012-9988456</Typography>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12}>
                <Typography className={`${classes.boldText} ${classes.underlineText}`} >Payor</Typography>
            </Grid>
            <Grid container item xs={12} className={classes.gutterBottom}>
                {/* Name */}
                <Grid container item xs={12} >
                    <Grid item xs={3}>
                        <div className={classes.clientInfo}>
                            <Typography>Name</Typography>
                            <Typography>:</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.clientInfoGutterRight}>JENNIFER RICHARD</Typography>
                    </Grid>
                </Grid>

                {/* Address */}
                <Grid container item xs={12} >
                    <Grid item xs={3}>
                        <div className={classes.clientInfo}>
                            <Typography>Address</Typography>
                            <Typography>:</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.clientInfoGutterRight}>NO.5 JALAN PASIT PUTIH TELUK PANGLIMA selangor Darul Ehsan, 40190</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
