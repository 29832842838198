import React from 'react'
import { Chip, Grid, makeStyles, Typography, withStyles } from '@material-ui/core'
import { SkillsIcon } from '../../assets'

const CustomChip = withStyles((theme) => ({
  root: {
    borderRadius: 4,
    backgroundColor: "rgba(233, 233, 233, 0.48)",
    margin: "4px 8px 8px 0px"
  },
  colorPrimary: {
    backgroundColor: theme.palette.quaternary.main
  },

}))((props) => <Chip {...props} />)

const useStyle = makeStyles((theme) => ({
  root: {
    margin: "30px 0px 30px 0px"
  },
  labelStyle: {
    marginLeft: 12,
    color: theme.palette.text.secondary,
    fontSize: '0.8rem'
  },
  labelDetailStyle: {
    marginLeft: 12,
  },
}))

export default function Skills(props) {
  const classes = useStyle()
  let skill_data = props?.data

  return (
    <Grid item container justify="center" className={classes.root}>
      <Grid item>
        <SkillsIcon htmlColor={"#31b198"} />
      </Grid>
      <Grid item container xs>
        <Grid item xs={12}>
          <Typography className={classes.labelStyle}>SKILLS</Typography>
        </Grid>
        <Grid item xs={12} className={classes.labelDetailStyle}>
          {
            skill_data?.map((x) => <CustomChip label={x}/>)
          }
          {/* <CustomChip label={"Physical awareness"} />
          <CustomChip label={"Science acumen"} />
          <CustomChip label={"Motor skills"} /> */}
        </Grid>
      </Grid>
    </Grid>
  )
}
