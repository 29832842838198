/* eslint-disable eqeqeq */
import React from "react";
import AddEditProductManagement from "../../../../components/addEditForm";
import {
  formComponentTypes,
  alertProps,
  user_roles,
  // converToReactSelect,
  productTableColumns as columns,
  options,
  getUniques,
  base64MimeType,
  upload_file,
  exoticColumnType,
} from "../../../../utils/index";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routes } from "../../../../router/routes";
import {
  LoadingSection,
  InternatlServerError,
  NotFoundSection,
} from "../../../../components";
import moment from "moment";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  name: "",
  frequency: "",
  valdity: ["", ""],
  provider: null,
  category: null,
  services: [],
  profile_pic: "",
  productValidity: { label: "Custom", value: "custom" },
  terms_conditions: [],
  inclusions: [],
  description: "",
  open: false,
  popperData: [],
  productInfo: { loading: true, error: false, data: {} },
  allowToShow: false,
};

// const taxIdOptions = [{name: "Sales Tax", value: 10, type: "variable"}, {name: "Service Tax", value: 25, type: "fixed"}]

const exoticRow = [
  null,
  null,
  {
    type: exoticColumnType.TAX_TYPE,
  },
  {
    type: exoticColumnType.TAX_PERCENTAGE,
  },
  null,
  null,
  {
    type: exoticColumnType.DISCOUNT,
  },
  {
    type: exoticColumnType.TOTAL_TEXT,
  },
  {
    type: exoticColumnType.TOTAL_VALUE,
  },
];

class AddEditProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      discount: 0,
      tax: 0,
      taxType: null,
      total: 0,
      provider: null,
      category: null,
      isEditInitialClearServices: false,
      isEditInitialClearProviders: false,
      startDate: null,
      endDate: null,
      validity: { label: "Custom", value: "custom" },
      isDisableFields: false,
      ...initialState,
      pre_assessment_required: false,
      assessment_required: false,
      service_deliverable_required: false,
      promotional_content: null,
      currency: {
        label: "MYR",
        value: "MYR",
      },
      localProductLoading: false,
    };
  }

  componentDidMount() {
    this.getEssentials();
    this.checkForRole();
  }

  handleRowsReorder = (rows, sourceIndex, destinationIndex) => {
    const newRowsCopy = rows?.map((row, index) => {
      if (destinationIndex === 0 && index === 0) {
        return { ...row, dependentService: [] };
      }
      return { ...row };
    });

    // if (destinationIndex === 0) {
    //   rows[destinationIndex].dependentService = [];
    // }

    this.setState({
      rows: newRowsCopy,
    });
    // const draggedItem = rows[destinationIndex];

    // if (rows?.length > 0) {
    //   // 1. search dependentservice of the dragged Item first
    //   let draggedItemDependentServiceIndexes = [];
    //   rows.map((x, i) => {
    //     if (
    //       draggedItem?.dependentService?.filter((e) => e?.value === x?.value)
    //         .length > 0
    //     ) {
    //       draggedItemDependentServiceIndexes = [
    //         ...draggedItemDependentServiceIndexes,
    //         { id: x?.value, index: i },
    //       ];
    //     }
    //     return x;
    //   });

    //   if (draggedItemDependentServiceIndexes?.length > 0) {
    //     draggedItemDependentServiceIndexes.map((dependent) => {
    //       if (destinationIndex < dependent.index) {
    //         rows = rows?.map((_, i) => {
    //           if (i === destinationIndex) {
    //             return {
    //               ..._,
    //               dependentService: _?.dependentService?.filter(
    //                 (x) => x?.value !== dependent?.id
    //               ),
    //             };
    //           }
    //           return _;
    //         });
    //       }
    //       return dependent;
    //     });
    //   }

    //   // 2. check if the dragged item is a dependent service for any other service
    //   let draggedItemId = draggedItem.value;

    //   rows = rows?.map((_, i) => {
    //     if (
    //       _?.dependentService?.filter((e) => e?.value === draggedItemId)
    //         .length > 0
    //     ) {
    //       if (i < destinationIndex) {
    //         return {
    //           ..._,
    //           dependentService: _?.dependentService?.filter(
    //             (x) => x?.value !== draggedItemId
    //           ),
    //         };
    //       }
    //       return _;
    //     }
    //     return _;
    //   });

    //   this.setState({
    //     rows: rows,
    //   });
    // }
  };

  checkForRole = () => {
    let auth_data = this.props.auth_data;

    if (
      user_roles.provider === auth_data.user_role &&
      auth_data?.other_roles?.indexOf("cross-bundling") === -1
    ) {
      let select_care_provider = [
        {
          value: auth_data?.user_id,
          label: auth_data?.profile_name,
        },
      ];

      // let select_category = auth_data?.category?.[0] ?? {};
      // this.servicesPackageByCareProvider(select_care_provider, {
      //   select_category,
      // });

      this.categoriesOptions(select_care_provider);
    }
  };

  getEssentials = async () => {
    // this.props.GET_CATEGORIES({});
    // get all provider list
    this.props.GET_PARTNER_DROPDOWN({});
    this.props.GET_MASTER_PRODUCT_MANAGEMENT_GROUP_TYPES({});

    await this.props.GET_ALL_VALDITY({});
    await this.props.GET_TAX_INTEGRATION({});

    if (this.props.isEdit) {
      const id = this.props?.match?.params?.id;
      const versionId = this.props?.match?.params?.versionId;
      await this.props.GET_PRODUCTS_INFO({ id, versionId });
      const { data: editData } = this.props?.productInfo;
      // const { data: taxTypeOptions=[] } = this.props?.taxTypeMaster || {}

      this.setState({
        taxType: editData?.data?.[0]?.resourceMeta?.taxType,
        tax: editData?.data?.[0]?.resourceMeta?.tax,
      });
    }
  };

  // validate the provider based categories and service list, selected values filter
  careProviderByCategory = (categories, state, isEdit = false) => {
    const getProvidersBasedOnCategories = () => {
      if (categories) {
        this.props.GET_PROVIDER_LIST_BASEDON_CATEGORY({
          categories: Array.isArray(categories)
            ? categories.map((_) => _.value)
            : [categories?.value],
        });
      }
    };

    const settingState = () => {
      this.setState(
        {
          isResetState: state,
          name: state?.product_name,
          frequency: state?.type,
          valdity: [state?.startDate, state?.endDate],
          group_type: state?.group_type,
          provider: provider,
          category: category,
          rows: rows,
          profile_pic: state?.uploadImage,
          terms_conditions: state?.terms_conditions,
          // group_type: state?.group_type,
          inclusions: state?.inclusions,
          productValidity: state?.productValidity,
          description: state?.description,
          allowToShow: state?.allowToShow,
        },
        () => {
          getProvidersBasedOnCategories();
        }
      );
    };

    let { category, provider, rows } = this.state;

    if (categories) {
      if (category?.constructor === Array) {
        let removedCategory = category.filter((x) => !categories.includes(x));

        const handleOnClose = () => {
          category = this.state.category;
          provider = this.state.provider;
          rows = this.state.rows;

          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: false,
            title: "Remove Category",
            body: "Removing category will remove selected providers and services",
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            onOk: () => {},
            onCancel: () => {},
          });

          settingState();
        };

        const handleOnOkModal = () => {
          category = categories;

          let removedProvider = provider.filter(
            (x) => x.categoryId === Number(removedCategory[0].value)
          );

          if (provider) {
            // find which provider to be removed
            let updatedProvider = provider.filter(
              (x) => x.categoryId !== Number(removedCategory[0].value)
            );
            console.log("updatedProvider: ", updatedProvider);
            provider = updatedProvider;
          } else {
            provider = [];
          }

          if (rows) {
            // find which provider to be removed
            let updatedService = rows.filter(
              (x) => x.providerId !== Number(removedProvider?.[0]?.value)
            );
            console.log("updatedService: ", updatedService);
            rows = updatedService;
          } else {
            rows = [];
          }

          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: false,
            title: "Remove Category",
            body: "Removing category will remove selected providers and services",
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            onOk: () => {},
            onCancel: () => {},
          });

          settingState();
        };

        if (removedCategory?.length > 0) {
          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: true,
            title: "Remove Category",
            body: "Removing category will remove selected providers and services",
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            onOk: handleOnOkModal,
            onCancel: handleOnClose,
          });
          return false;
        } else {
          category = categories;
          provider = this.state.provider;
          rows = this.state.rows;
          settingState();
        }
      } else {
        category = categories;
        provider = [];
        rows = [];
        settingState();
      }
    } else {
      const handleOnOkModal = () => {
        category = [];
        provider = [];
        rows = [];

        this.props.dialog.setDialog({
          ...this.props.dialog,
          open: false,
          title: "Remove Category",
          body: "Removing category will remove selected providers and services",
          positiveBtn: "Ok",
          negativeBtn: "CANCEL",
          onOk: handleOnOkModal,
          onCancel: handleOnClose,
        });
        settingState();
      };

      const handleOnClose = () => {
        category = this.state.category;
        provider = this.state.provider;
        rows = this.state.rows;
        this.props.dialog.setDialog({
          ...this.props.dialog,
          open: false,
          title: "Remove Category",
          body: "Removing category will remove selected providers and services",
          positiveBtn: "Ok",
          negativeBtn: "CANCEL",
          onOk: handleOnOkModal,
          onCancel: handleOnClose,
        });
        settingState();
      };

      this.props.dialog.setDialog({
        ...this.props.dialog,
        open: true,
        title: "Remove Category",
        body: "Removing category will remove selected providers and services",
        positiveBtn: "Ok",
        negativeBtn: "CANCEL",
        onOk: handleOnOkModal,
        onCancel: handleOnClose,
      });
    }
  };

  getPackageAndServiceByIds = (categories, state, isEdit = false) => {
    let { provider } = this.state;
    let params = {
      categories: Array.isArray(categories)
        ? categories.map((_) => _.value)
        : [categories?.value],
      providers: Array.isArray(provider)
        ? provider.map((_) => _.value)
        : [provider?.value],
    };
    // console.log("param", JSON.stringify(params))
    this.props.SER_PGK_BASED_ON_CAT_PARTNER(params);
  };

  getCategoriesAndProviders = (categories, providerDetails) => {
    if (categories) {
      this.props.GET_PROVIDER_LIST_BASEDON_CATEGORY({
        categories: Array.isArray(categories)
          ? categories.map((_) => _.value)
          : [categories?.value],
      });
    }

    if (categories && providerDetails) {
      this.props.GET_SERVICES_AND_PACKAGES_FOR_PRODUCT({
        categories: categories
          ? Array.isArray(categories)
            ? categories.map((_) => _.value)
            : [categories?.value]
          : null,
        provider: providerDetails
          ? Array.isArray(providerDetails)
            ? providerDetails.map((_) => _.id)
            : [providerDetails?.value]
          : null,
      });
    }
  };

  onSaveButtonClicked = async (isError, data, isUrl) => {
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      this.setState({ localProductLoading: true });
      let serviceMap = [];
      let packageIds = [];

      this.state.rows.map((_) => {
        if (_.type === "service") {
          serviceMap.push({
            serviceId: parseInt(_.value),
            quantity: _?.quantity ?? 1,
            serviceFinalPrice: _.total ? _.total : 1,
          });
        } else if (_.type === "package") {
          packageIds.push(parseInt(_.value));
        }
        return _;
      });

      let auth_data = this.props.auth_data;

      if (
        user_roles.provider === auth_data.user_role &&
        auth_data?.other_roles?.indexOf("cross-bundling") == -1
      ) {
        data.provider = [
          {
            value: auth_data?.user_id,
            label: auth_data?.profile_name,
          },
        ];
      }

      let productId = this.props?.match?.params?.id;

      const getFlagValue = () => {
        const { data: editData } = this.props?.productInfo;
        // const dbStartDate = editData?.data?.[0]?.resourceMeta?.resource_validity?.value?.resourceEffectiveFrom
        // const dbEndDate = editData?.data?.[0]?.resourceMeta?.resource_validity?.value?.resourceEndsOn

        const editedEffective = data?.startDate;
        const dbEffective =
          editData?.data?.[0]?.resourceMeta?.resource_validity?.value
            ?.resourceEffectiveFrom;

        const editedEndsOn = data?.endDate;
        const dbEndsOn =
          editData?.data?.[0]?.resourceMeta?.resource_validity?.value
            ?.resourceEndsOn;

        // if(editData){
        //   if(moment(data?.startDate)?.isSame(dbStartDate) && moment(data?.endDate)?.isSame(dbEndDate)){
        //     return false
        //   } else {
        //     return true
        //   }
        // } else {
        //   return false
        // }

        if (editData) {
          if (!moment(editedEffective)?.isSame(dbEffective)) {
            return true;
          } else if (editedEndsOn && dbEndsOn) {
            if (!moment(editedEndsOn)?.isSame(dbEndsOn)) {
              return true;
            }
          } else if (
            (!editedEndsOn && dbEndsOn) ||
            (editedEndsOn && !dbEndsOn)
          ) {
            return true;
          }

          return false;
        } else {
          return true;
        }
      };

      let payload = {
        partnerId: Array.isArray(data?.provider)
          ? data.provider.map((val) => val.value)
          : data.provider.value,
        resourceCategory: data.category.map((val) => val.value),
        resourceName: data.product_name,
        effectiveFrom: moment.utc(data?.startDate)?.format(),
        effectiveTo: data?.endDate ? moment.utc(data?.endDate)?.format() : "",
        productIds: productId,
        resourceMeta: {
          select_category: data.category,
          group_type: data?.group_type,
          price: this.state.total,
          ...this.state,
          ...data,
          select_provider: Array.isArray(data.provider)
            ? data.provider
            : [data.provider],
        },
        allowToShow: data?.allowToShow,
        isEffectiveFromToValidation: getFlagValue(),
      };
      if (data?.uploadImage) {
        if (base64MimeType(data?.uploadImage)?.length > 0) {
          let { fileName, withoutType } = upload_file(data?.uploadImage);
          await this.props
            .FILE_UPLOAD({
              file: withoutType,
              fileName: fileName,
              isbase64: true,
            })
            .then(async (val) => {
              payload["resourceMeta"]["uploadImage"] = await val?.payload?.data
                ?.fileid;
            });
        } else {
          payload["resourceMeta"]["uploadImage"] =
            data?.uploadImage?.split("/")[
              data?.uploadImage?.split("/").length - 1
            ];
        }
      }

      if (!this.props.isEdit) {
        // If the mode is add. Then Check UI validation using query.
        const isErrorFree = await this.handleActivateDeactivateValidations(
          payload?.partnerId,
          {
            effectiveFrom: payload?.effectiveFrom,
            effectiveTo: payload?.effectiveTo,
          },
          payload
        );

        if (isErrorFree) {
          this.props.ADD_PRODUCTS({ ...payload });
        }
      } else {
        // If the mode is Edit, check UI validtion using API.
        this.props.ADD_PRODUCTS({ ...payload });
      }
    }
  };

  handleActivateDeactivateValidations = async (
    partnerId,
    resource_validity,
    payload
  ) => {
    const startDate = moment(resource_validity?.effectiveFrom)
      .startOf("day")
      .toISOString();
    const endDate =
      resource_validity?.effectiveTo?.length > 0
        ? moment(resource_validity?.effectiveTo).endOf("day").toISOString()
        : "";
    const { auth_data } = this.props;
    let isCrossBundling =
      user_roles.provider === auth_data.user_role &&
      auth_data?.other_roles?.indexOf("cross-bundling") != -1;
    await this.props?.GET_PARTNER_ACTIVE_DATE_BY_SUPERVISOR({
      partnerId: partnerId,
      startDate: startDate,
      endDate: endDate,
      isCrossBundling,
    });
    const { data } = this.props?.getPartnerActiveStatusBySupervisor;

    // eslint-disable-next-line no-mixed-operators
    if (
      (isCrossBundling &&
        Array.isArray(data) &&
        data?.length == partnerId?.length) ||
      (Array.isArray(data) && data?.length > 0)
    ) {
      // No issue in adding Product

      // Check if the services/packages selected are not less than the product validity.
      const servicePackageDates =
        payload?.resourceMeta?.select_package_service?.map((servPack) => {
          return {
            effectiveFrom: servPack?.resourceMeta?.resourceMeta?.effectiveFrom,
            effectiveTo: servPack?.resourceMeta?.resourceMeta?.effectiveTo,
          };
        });

      const isDateTestPassed = (effectiveFrom, effectiveTo) => {
        effectiveFrom = moment(effectiveFrom);
        effectiveTo = effectiveTo?.length > 0 ? moment(effectiveTo) : "";
        let _endsON =
          effectiveTo?.length > 0 && endDate?.length > 0
            ? moment(endDate)?.isSameOrBefore(effectiveTo, "day")
            : endDate?.length == 0 && effectiveTo?.length > 0
            ? false
            : true;
        return moment(startDate)?.isSameOrAfter(effectiveFrom, "day") && _endsON
          ? true
          : false;
      };

      let truthy = servicePackageDates.every((element) => {
        const element_startDate = moment(element?.effectiveFrom).startOf("day");
        const element_endDate =
          element?.effectiveTo?.length > 0
            ? moment(element?.effectiveTo).startOf("day")
            : "";
        return isDateTestPassed(element_startDate, element_endDate);
      });

      if (!truthy) {
        // To avoid multiple product add, the loading state below is used.
        this.setState({ localProductLoading: false });
        // Issue occured while adding package
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `Some services/packages in the table expires before selected product expiry. Please remove those items.`,
          severity: alertProps.severity.error,
        });
      }

      return truthy;
    } else {
      // To avoid multiple product add, the loading state below is used.
      this.setState({ localProductLoading: false });
      // Issue occured while adding Product
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: `Partner may not be active on the selected dates. Please select different date.`,
        severity: alertProps.severity.error,
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Add Product
    const { addProduct } = nextProps;
    const oldAddProduct = this.props.addProduct;

    //Checking for Add Package messages
    if (oldAddProduct?.loading !== addProduct?.loading) {
      if (!addProduct.loading && !addProduct.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: addProduct.message,
          severity: alertProps.severity.success,
        });
        this.props.history.push(routes.manage_product);
      } else if (addProduct.error) {
        this.setState({ localProductLoading: false });
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong!",
          severity: alertProps.severity.error,
        });
      }
    }

    //Update Product
    const { updateProductInfo } = nextProps;
    const oldUpdateProductInfo = this.props.updateProductInfo;

    //Checking for Add Package messages
    if (oldUpdateProductInfo?.loading !== updateProductInfo?.loading) {
      if (!updateProductInfo.loading && !updateProductInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Product Updated successfully!",
          severity: alertProps.severity.success,
        });
        this.props.history.push(routes.manage_product);
      } else if (updateProductInfo.error) {
        this.setState({ localProductLoading: false });
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Update Product.",
          severity: alertProps.severity.error,
        });
      }
    }

    //Get Package
    const { productInfo } = nextProps;
    const oldProductInfo = this.props.productInfo;

    //Checking for Add Package messages
    if (oldProductInfo?.loading !== productInfo?.loading) {
      const { data } = productInfo;

      if (data?.data?.length > 0) {
        let datas = data?.data?.[0]?.resourceMeta;
        let categoriesList = [];
        this.setState(
          {
            productInfo,
            rows: datas.rows,
            tax: datas.tax,
            total: datas.total,
            discount: datas.discount ?? 0,
            provider: datas.provider,
            category: datas.category,
            categories: categoriesList,
            // categories: datas.provider?.map(val => val?.partnerCategory).reduce((r, e) => (r.push(...e), r), []),
            name: datas.product_name,
            // startDate,
            // endDate,
            profile_pic: datas?.uploadImage,
            productValidity: datas?.productValidity,
            valdity: [datas?.effectiveFrom, datas?.effectiveTo],
            group_type: datas?.group_type,
            // currency: datas?.currency, //Temporarily commented to make currency MYR as default
            currency: {
              label: "MYR",
              value: "MYR",
            },
            description: datas?.description,
            terms_conditions: datas?.terms_conditions,
            originalTotal: this.getTotalAmount(datas.rows, null),
            quantityEditable: datas?.entityType === "service" ? true : false,
            allowToShow: datas?.allowToShow,
          },
          () => {
            this.getPackageAndServiceByIds(datas.category);
            let params = {
              partnerArray: Array.isArray(datas?.provider)
                ? datas?.provider?.map((val) => val.value)
                : [datas.provider?.value],
            };
            this.props.GET_CATEGORY_LIST_BASEDON_PROVIDERS(params);
          }
        );
      }

      return false;
      // const newRows = data?.details?.rows.map((_) => {
      //   let quantity = _.quantity;
      //   if (_.type !== "service") {
      //     quantity = 1;
      //   }
      //   return {
      //     ..._,
      //     label: _.name,
      //     value: (_.id).toString(),
      //     total: parseFloat(_.amount).toFixed(2),
      //     serviceAmountPrice: _?.serviceAmountPrice ? parseFloat(_.serviceAmountPrice).toFixed(2) : parseFloat(_.amount).toFixed(2),
      //     originalAmount: parseFloat(_.amount).toFixed(2),
      //     amount: _?.serviceAmountPrice ? parseFloat(_.serviceAmountPrice * quantity).toFixed(2) : parseFloat(_.amount * quantity).toFixed(2)
      //   };
      // });

      // const findShouldDisable = () => {
      //   let shouldDisable = false
      //   if (this.props?.isEdit) {
      //     if (this.props?.auth_data?.providerDetails?.length > 0 && data?.care_provider?.length > 0) {

      //       let auth_provider_ids = this.props?.auth_data?.providerDetails.map(val => { return Number(val.provider_id) })
      //       let current_product_details_provider_ids = data?.care_provider.map(val => { return val.id })
      //       shouldDisable = (auth_provider_ids.filter(val => current_product_details_provider_ids.includes(val))).length > 0 ? false : true
      //     }
      //     else {
      //       shouldDisable = false
      //     }
      //   }
      //   else {
      //     shouldDisable = false
      //   }
      //   return shouldDisable
      // }
      // const formattedDiscount = parseFloat(data?.details?.discount).toFixed(2);
      // console.log('auth', this.props.auth_data)
      // console.log('product details', data)
      // this.setState(
      //   {
      //     isDisableFields: findShouldDisable(),
      //     isResetState: data,
      //     name: data?.name,
      //     frequency:
      //       this.props?.frequency?.data?.length > 0
      //         ? this.props?.frequency?.data.filter((val) => {
      //           return val.label === data?.type;
      //         })[0]
      //         : [],
      //     valdity: [
      //       moment(data?.validation_date?.start_date),
      //       moment(data?.validation_date?.end_date),
      //     ],
      //     profile_pic: data?.profile_pic,
      //     terms_conditions: data?.details?.terms_conditions,
      //     inclusions: data?.details?.inclusion,
      //     description: data?.description,

      //     rows: newRows,
      //     discount: formattedDiscount,
      //     provider:
      //       data?.care_provider?.length > 0
      //         ? converToReactSelect(data?.care_provider, "id", "name")
      //         : [],
      //     category: data?.category,
      //     productValidity: data?.validityPeriod
      //       ? JSON.parse(data?.validityPeriod)
      //       : [],
      //     total: this.getTotalAmount(newRows, data?.details?.discount),
      //   },
      //   () => {
      //     // this.careProviderByCategory(data?.category?.[0],this.state, true);
      //     // this.servicesPackageByCareProvider(
      //     //   {
      //     //     value: data?.care_provider?.id,
      //     //     label: data?.care_provider?.name,
      //     //   },
      //     //   this.state,
      //     //   true
      //     // );
      //     this.getCategoriesAndProviders(data?.category, data?.care_provider)
      //   }
      // );
    }

    return true;
  }

  // provider based service list and clear selected values
  servicesPackageByCareProvider = (providerDetails, state, isEdit = false) => {
    const settingState = () => {
      this.setState(
        {
          isResetState: state,
          name: state?.product_name,
          frequency: state?.type,
          valdity: [state?.startDate, state?.endDate],
          provider: provider,
          category: state?.category,
          rows: rows,
          profile_pic: state?.uploadImage,
          terms_conditions: state?.terms_conditions,
          inclusions: state?.inclusions,
          productValidity: state?.productValidity,
          description: state?.description,
          allowToShow: state?.allowToShow,
        },
        () => {
          getServices();
        }
      );
    };

    const getServices = () => {
      const categories = state?.category || state?.select_category;
      if (categories && providerDetails) {
        this.props.GET_SERVICES_AND_PACKAGES_FOR_PRODUCT({
          categories: categories
            ? Array.isArray(categories)
              ? categories.map((_) => _.value)
              : [categories?.value]
            : null,
          provider: providerDetails
            ? Array.isArray(providerDetails)
              ? providerDetails.map((_) => _.value)
              : [providerDetails?.value]
            : null,
        });
      }
    };

    let { provider, rows } = this.state;

    if (providerDetails) {
      if (provider?.constructor === Array) {
        // to find which category has removed
        let removedProvider = provider.filter(
          (x) => !providerDetails.includes(x)
        ); // calculates diff
        console.log("Removed: ", removedProvider);
        console.log("updated category:", providerDetails);
        const handleOnOkModal = () => {
          provider = providerDetails;
          let removedServices = rows.filter(
            (x) => x.providerId === Number(removedProvider[0].value)
          );
          let removedServicesIds = removedServices.map((val) =>
            Number(val.value)
          );
          if (rows) {
            if (removedServices?.length > 0) {
              // find which services to be removed
              let updatedService = rows.filter(
                (x) => removedServicesIds.indexOf(Number(x.value)) === -1
              );
              console.log("updatedService: ", updatedService);
              rows = updatedService;
            } else {
              rows = this.state.rows;
            }
          } else {
            rows = [];
          }
          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: false,
            title: "Remove Provider",
            body: "Removing provider will remove selected services",
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            onOk: () => {},
            onCancel: () => {},
          });
          settingState();
        };
        const handleOnClose = () => {
          provider = this.state.provider;
          rows = this.state.rows;
          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: false,
            title: "Remove Provider",
            body: "Removing provider will remove selected services",
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            onOk: () => {},
            onCancel: () => {},
          });
          settingState();
        };
        if (removedProvider?.length > 0) {
          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: true,
            title: "Remove Provider",
            body: "Removing provider will remove selected services",
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            onOk: handleOnOkModal,
            onCancel: handleOnClose,
          });
        } else {
          provider = providerDetails;
          rows = this.state.rows;
          settingState();
        }
      } else {
        provider = [];
        rows = [];
        settingState();
      }
    } else {
      const handleOnOkModal = () => {
        provider = [];
        rows = [];

        this.props.dialog.setDialog({
          ...this.props.dialog,
          open: false,
          title: "Remove Category",
          body: "Removing category will remove selected services",
          positiveBtn: "Ok",
          negativeBtn: "CANCEL",
          onOk: handleOnOkModal,
          onCancel: handleOnClose,
        });
        settingState();
      };
      const handleOnClose = () => {
        provider = this.state.provider;
        rows = this.state.services;
        this.props.dialog.setDialog({
          ...this.props.dialog,
          open: false,
          title: "Remove Category",
          body: "Removing category will remove selected services",
          positiveBtn: "Ok",
          negativeBtn: "CANCEL",
          onOk: handleOnOkModal,
          onCancel: handleOnClose,
        });
        settingState();
      };
      this.props.dialog.setDialog({
        ...this.props.dialog,
        open: true,
        title: "Remove Category",
        body: "Removing category will remove selected providers and services",
        positiveBtn: "Ok",
        negativeBtn: "CANCEL",
        onOk: handleOnOkModal,
        onCancel: handleOnClose,
      });

      // provider = [];
      // services = [];
      // settingState();
    }
  };

  updateServiceRow = (s, state) => {
    //Merge both stateRows and unModifiedRows arrays.
    let final = s ? [...s] : [];

    let addedService = [];
    let removedService = [];

    if (final?.length > this.state.rows?.length) {
      addedService = getUniques(
        final,
        this.state.rows?.length > 0 ? this.state.rows : [],
        "value"
      );
    } else if (final?.length < this.state.rows?.length) {
      removedService = getUniques(
        this.state.rows?.length > 0 ? this.state.rows : [],
        final,
        "value"
      );
    }

    let finalRows = this.state.rows?.length > 0 ? this.state.rows : [];

    if (addedService?.length > 0) {
      if (this.props.isEdit) {
        finalRows = Object.assign([], finalRows);
      }
      finalRows.push({
        ...addedService[0],
        quantity: addedService[0]?.quantity ?? 1,
        price_editable: addedService[0]?.isPriceEditable,
        quantityEditable:
          addedService[0]?.entityType === "service" ? true : false,
        type: "Category_l",
        providerDetails: addedService[0]?.providerInfo?.label,
        serviceAmountPrice: addedService[0].serviceAmountPrice
          ? parseFloat(addedService[0].serviceAmountPrice)?.toFixed(2)
          : parseFloat(addedService[0].amount)?.toFixed(2),
        total: addedService[0].serviceAmountPrice
          ? parseFloat(addedService[0].serviceAmountPrice)?.toFixed(2)
          : parseFloat(addedService[0].amount)?.toFixed(2),
        uniqueId: uuidv4(),
        strict: addedService[0]?.strict ?? true,
        dependentService:
          addedService[0]?.dependentService?.length > 0
            ? addedService[0]?.dependentService
            : [],
      });
    } else if (removedService?.length > 0) {
      finalRows = finalRows.filter((x) => x.value !== removedService[0]?.value);
    }

    //Check the dependent service of all the services and remove the removed service
    if (this.state.rows?.length > s?.length) {
      if (removedService?.length === 1) {
        if (removedService?.[0]?.value) {
          finalRows = finalRows.map((x) => {
            return {
              ...x,
              dependentService: x?.dependentService?.filter(
                (_) => _?.value !== removedService?.[0]?.value
              ),
            };
          });
        }
      }
    }

    this.setState({
      isResetState: state,
      name: state?.product_name,
      frequency: state?.type,
      valdity: [state?.startDate, state?.endDate],
      provider: state?.provider,
      productValidity: state.productValidity,
      category: state?.category,
      description: state?.description,
      profile_pic: state?.uploadImage,
      terms_conditions: state?.terms_conditions,
      group_type: state?.group_type,
      inclusions: state?.inclusions,
      rows: finalRows,
      total: this.getTotalAmount(finalRows, this.state.discount),
      originalTotal: this.getTotalAmount(finalRows, null),
      allowToShow: state?.allowToShow,
    });
  };

  handleRemove = (e, state) => {
    let rows = this.state.rows.filter((_) => _.value !== e.value);
    let { total, originalTotal } = this.totalWithTaxDisAmount(
      rows,
      this.state.tax,
      this.state.discount
    );
    let state_ = {
      select_package_service: rows.length > 0 ? rows : [],
    };
    let removedServiceId = this.state.rows.filter(
      (_) => _.value === e.value
    )?.[0]?.value;
    if (removedServiceId) {
      rows = rows.map((x) => {
        return {
          ...x,
          dependentService: x?.dependentService?.filter(
            (_) => _?.value !== removedServiceId
          ),
        };
      });
    }

    this.setState({
      ...this.state,
      isResetState: state_,
      rows: rows.length > 0 ? rows : [],
      discount: rows.length > 0 ? this.state.discount : 0,
      total: total,
      originalTotal,
    });
  };

  onDiscountChange = (value) => {
    if (value < 100) {
      let { total, originalTotal } = this.totalWithTaxDisAmount(
        this.state.rows,
        this.state.tax,
        value
      );

      this.setState({
        ...this.state,
        discount: value,
        total: total,
        originalTotal,
      });
    }

    // const preDecimal = value.split(".")[0] ?? 0;
    // const postDecimal = value.split(".")[1] ?? 0;
    // const noFirstDigitZero = /^[^0]\d*/;

    // if (value.length === 0) {
    //   this.setState((prevState) => {
    //     if (value < 0 || value > 100) {
    //       return;
    //     } else {
    //       return {
    //         discount: value,
    //         total: this.getTotalAmount(this.state.rows, value),
    //         originalTotal: this.getTotalAmount(this.state.rows, null),
    //       };
    //     }
    //   });
    // } else if (noFirstDigitZero?.test(value)) {
    //   if (preDecimal.length <= 10) {
    //     if (postDecimal) {
    //       if (postDecimal.length <= 2) {
    //         this.setState((prevState) => {
    //           if (value < 0 || value > 100) {
    //             return;
    //           } else {
    //             return {
    //               discount: value,
    //               total: this.getTotalAmount(
    //                 this.state.rows,
    //                 parseFloat(value).toFixed(2)
    //               ),
    //               originalTotal: this.getTotalAmount(this.state.rows, null),
    //             };
    //           }
    //         });
    //       } else {
    //         // post decimal exceeds limit
    //         return;
    //       }
    //     } else {
    //       this.setState((prevState) => {
    //         if (value < 0 || value > 100) {
    //           return;
    //         } else {
    //           return {
    //             discount: value,
    //             total: this.getTotalAmount(this.state.rows, value),
    //             originalTotal: this.getTotalAmount(this.state.rows, null),
    //           };
    //         }
    //       });
    //     }
    //   }
    // } else {
    //   return;
    // }
  };

  onTaxChange = (tax) => {
    if (tax <= 100) {
      let checkTax = tax.length === 0 ? "0" : tax;
      let { total, originalTotal } = this.totalWithTaxDisAmount(
        this.state.rows,
        checkTax,
        this.state.discount
      );
      this.setState({
        ...this.state,
        total: total,
        tax: tax,
        originalTotal,
      });
    }
    // const parsedTax = parseFloat(tax).toFixed(2);
    // if (tax.length > 1 && (parsedTax < 0 || parsedTax > 100)) {
    //   return;
    // }
  };

  onTaxTypeChange = (event) => {
    const { data: taxTypeOptions } = this.props?.taxTypeMaster;

    let { tax } = this.state;
    const value = event.target.value;
    const selectedTaxType = taxTypeOptions?.filter(
      (tax) => tax?.value === value
    )?.[0];

    // if(selectedTaxType?.type === TAXTYPE.FIXED){
    // }
    if (selectedTaxType?.taxPercentage !== undefined) {
      tax = selectedTaxType?.taxPercentage;
    }

    this.setState({
      tax: tax,
      taxType: value,
    });
  };

  totalWithTaxDisAmount = (totalServiceData, taxPer, dis) => {
    let _arrOfAmount = [];
    // let tax = taxPer?.length > 0 ? taxPer : 0;
    if (totalServiceData && totalServiceData?.length > 0) {
      totalServiceData.map((val) => {
        if (val?.amount) {
          _arrOfAmount.push(Number(val.amount.trim().replace("RM", "")));
        }
        return val;
      });
    }
    let _totalAmount = _arrOfAmount.reduce((a, b) => a + b, 0);
    // let parsedTax = parseFloat(tax).toFixed(2);
    let disPer = Number(dis) / 100;
    let amountWithDis = _totalAmount - _totalAmount * disPer;
    // let total =
    //   (parseFloat(amountWithDis) * parseFloat(parsedTax)) / 100 +
    //   parseFloat(amountWithDis);
    return {
      total: parseFloat(amountWithDis).toFixed(2),
      originalTotal: _totalAmount.toFixed(2),
    };
  };

  getTotalAmount = (data, discount) => {
    let discountedAmountOld = discount > 0 ? 0 : discount;
    let _arrOfAmount = [];

    if (data?.length > 0) {
      data.map((val) => {
        if (val?.amount) {
          _arrOfAmount.push(Number(val.amount.trim().replace("RM", "")));
        }
        return val;
      });

      let _totalAmount = _arrOfAmount.reduce((a, b) => a + b, 0);

      if (discount) {
        let _discount = Number(discount) / 100;
        let _discountedPrice = _totalAmount - _totalAmount * _discount;
        let isNegativeCheck =
          Math.sign(_discountedPrice) === 1 ? _discountedPrice : 0;
        return isNegativeCheck.toFixed(2);
      } else {
        return _totalAmount.toFixed(2);
      }
    } else {
      if (Number(discount) !== discountedAmountOld) return 0;
    }
  };

  onTotalChange = (e) => {
    this.setState({
      total: e,
    });
  };

  validateAmount = (value, key) => {
    if (value < 0) {
      return false;
    } else if (value.length === 1 && value === ".") {
      return false;
    } else if (
      value.split(".").length - 1 === 1 ||
      value.split(".").length - 1 === 0
    ) {
      const preDecimal = value.split(".")[0];
      const postDecimal = value.split(".")[1];

      if (preDecimal.length <= 10) {
        if (postDecimal) {
          if (postDecimal.length <= 2) {
            return true;
          } else return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  onTextFieldChange = (index, key, value) => {
    // Less than zero Check
    if (value < 0) return null;
    let rows;

    if (key === "quantity") {
      let newValue = value;
      if ((value === "0" || value === "") && key === "quantity") {
        newValue = "1";
        if (value === "0") {
          value = "";
        }
      }
      newValue = parseInt(newValue);
      rows = this.state.rows.map((_, i) => {
        let originalAmount = _.originalAmount ? _.originalAmount : _.amount;
        originalAmount = parseFloat(originalAmount);
        if (i === index) {
          return {
            ..._,
            originalAmount: originalAmount,
            [key]: value,
            amount: String(
              ((_.total ? _.total : originalAmount) * newValue).toFixed(2)
            ),
          };
        }
        return _;
      });
    } else if (key === "serviceAmountPrice") {
      if (this.validateAmount(value, key)) {
        let editedPriceValue = value ? value : 1;

        if (value.charAt(0) === "0") {
          value = value.replace("0", "");
        }

        rows = this.state.rows.map((_, i) => {
          let originalAmount = _.originalAmount ? _.originalAmount : _.amount;
          originalAmount = parseFloat(originalAmount);
          if (i === index) {
            return {
              ..._,
              originalAmount: originalAmount,
              [key]: value,
              total: value,
              amount: String(
                (editedPriceValue * (_.quantity ? _.quantity : 1)).toFixed(2)
              ),
            };
          }
          return _;
        });
        // }
      }
    }

    if (rows) {
      this.setState({
        rows: rows,
        discount: rows?.length ? this.state.discount : 0,
        total: rows?.length
          ? // ? this.getTotalAmount(rows, this.state.discount)
            this.totalWithTaxDisAmount(
              rows,
              this.state.tax,
              this.state.discount
            ).total
          : 0,
        originalTotal: rows?.length ? this.getTotalAmount(rows, null) : 0,
      });
    }
  };

  canIHaveCategory = () => {
    if (this.props?.auth_data?.category?.length === 1) {
      return this.props?.auth_data?.category[0];
    } else {
      return [];
    }
  };

  canIHaveProviderOptions = () => {
    const { auth_data } = this.props;

    if (
      user_roles.supervisor === auth_data.user_role ||
      user_roles.service_coordinator === auth_data.user_role ||
      user_roles.service_professional === auth_data.user_role
    ) {
      return (
        auth_data?.providerDetails?.map((_) => {
          return { value: _.provider_id, label: _.provider_name };
        }) ?? []
      );
    } else {
      return this.props?.providerBasedOnCategory?.data ?? [];
    }
  };

  handleValidityOptions = (selectedValidity) => {
    this.setState({
      productValidity: selectedValidity,
    });
  };

  calculateStartDateMin = () => {
    // Destructure validity (contains selected validity option) from the state
    const { validity } = this.state;

    // Check if the value of the selected validity is "custom", then no min value should be set. User can select any past date.
    if (validity.value === "custom") {
      return null;
    } else {
      // If validity value is not "custom"
      // If validity value exists
      if (validity.value) {
        // parseInt from the value
        const daysCount = parseInt(validity.value);

        // Calculate min date to restrict user to select
        let min = moment().subtract(daysCount - 1, "days");

        // return new Date from the calculated moment object
        return new Date(min);
      } else {
        // If the validity value does not exists then return null
        return null;
      }
    }
  };

  giveMeSelectBoxValue = () => {
    let {
      loading: editLoading,
      error: editError,
      data: editData,
    } = this.props?.productInfo;

    if (!editLoading && !editError && editData) {
      return editData?.details?.rows.map((_) => {
        let quantity = _.quantity;
        if (_.type !== "service") {
          quantity = 1;
        }
        return {
          ..._,
          label: _.name,
          value: _.id.toString(),
          total: parseFloat(_.amount).toFixed(2),
          originalAmount: parseFloat(_.amount).toFixed(2),
          amount: parseFloat(_.amount * quantity).toFixed(2),
        };
      });
    }
    return [];
  };

  handlePopperClose = () => {
    this.setState({ open: false });
  };

  handlePopperOpen = (e, data) => {
    this.setState({ open: e.currentTarget, popperData: data });
  };

  handleCheckBox = (state, key) => {
    this.setState({ [key]: state });
  };

  // get categories Options list based provider id
  categoriesOptions = (provider) => {
    // const { data } = this.props?.dropPartner;
    if (provider) {
      // let joinArrays = [];
      // let categoryList = provider
      //   ?.map((val) => val?.partnerCategory)
      //   .map((e) => joinArrays.push(...e));
      let params = {
        partnerArray: Array.isArray(provider)
          ? provider?.map((val) => val.value)
          : [provider.value],
      };
      this.props.GET_CATEGORY_LIST_BASEDON_PROVIDERS(params);
      this.setState({
        provider: Array.isArray(provider) ? provider : [provider],
        category: [],
      });
    } else {
      this.setState({
        categories: [],
        category: [],
        rows: null,
        tax: null,
        total: "",
        discount: null,
      });
    }
  };

  handleStrictStatusChange = (index, key, value) => {
    let rows;
    rows = this.state.rows.map((_, i) => {
      if (i === index) {
        return {
          ..._,
          [key]: value,
          dependentService: value ? [] : _.dependentService,
        };
      }
      return _;
    });

    this.setState({
      rows: rows,
    });
  };

  handleDependentServiceChange = (index, key, value) => {
    let rows;
    rows = this.state.rows.map((_, i) => {
      if (i === index) {
        return {
          ..._,
          [key]: value?.map((_) => JSON.parse(_)),
        };
      }
      return _;
    });

    this.setState({
      rows: rows,
    });
  };

  render() {
    let { isEdit, auth_data } = this.props;

    let { data } = this.props?.getValidity || {};
    let validityOptions = data ? data : [];
    const { data: taxTypeOptions = [] } = this.props?.taxTypeMaster || {};

    console.log(this.props);

    const components = {
      section_1: {
        label: "",
        fields: {
          uploadImage: {
            key: "uploadImage",
            type: formComponentTypes.PROFILE_UPLOAD_BUTTON,
            componentProps: {
              label: "UPLOAD PHOTO",
            },
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            label: "PICTURE",
            value: this.state.profile_pic,
            isUrl: !isEdit ? false : true,
            id: "addPhotoFromAddProduct",
          },
          select_care_provider: {
            key: "provider",
            id: "selectProviderFromAddProduct",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              style: {
                margin: "auto 0",
              },
            },
            label: "SELECT PARTNER",
            value: this.state.provider,
            // options: this.canIHaveProviderOptions(),
            options: this.props?.dropPartner?.data ?? [],
            isLoading: this.props?.providerBasedOnCategory?.loading,
            cleanUpKeys: ["select_package_service", "category"],
            onSelectedCallback: this.categoriesOptions,
            isMandatory: true,
            isMulti: true,
            isDisabled: this.state.isDisableFields,
            // id: "5146452f-851e-4f14-b618-7942b80ca918_SELECT_PARTNER"
          },
          select_category: {
            key: "category",
            id: "selectCategoryFromAddProduct",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              style: {
                margin: "auto 0",
              },
            },
            label: "SELECT CATEGORY",
            value: this.state.category,
            // onSelectedCallback: this.careProviderByCategory,
            onSelectedCallback: this.getPackageAndServiceByIds,
            options: this.props?.categoriesList?.data ?? [],
            isLoading: this.props?.categoriesList?.loading,
            // cleanUpKeys: ["select_care_provider", "select_package_service"],
            isMandatory: true,
            isMulti: true,
            isDisabled: this.state.isDisableFields,
            // id: "19be10ad-3339-444b-914a-5fc1d9d7b569_SELECT_CATEGORY"
          },
          product_name: {
            key: "product_name",
            id: "addProductNameFromAddProduct",
            type: formComponentTypes.INPUT_BOX,
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "PRODUCT NAME",
            value: this.state.name,
            isMandatory: true,
            // id: "f384544c-8a4a-436f-a517-a1bc4ff75739_PRODUCT_NAME"
          },
          group_type: {
            id: "selectGroupTypesFromAddProduct",
            key: "group_type",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              required: true,
            },
            label: "SELECT GROUP TYPE",
            value: this.state.group_type,
            // onSelectedCallback: this.careProviderByCategory,
            options: this.props?.product_management_group_types ?? [],
            // isLoading: this.props?.categories?.loading,
            isMulti: false,
            isMandatory: false,
            // isDisabled: !this.state.assessment_required,
            // cleanUpKeys: ["select_provider"]
            // isDontShow: !this.state.assessment_required,
          },

          product_validity: {
            type: formComponentTypes.PRODUCT_VALIDITY,
            key: {
              productValidity: "productValidity",
              startDate: "startDate",
              endDate: "endDate",
            },
            id: {
              validity_id: "selectValidityFromAddProduct",
              effectiveFrom_id: "addEffectiveFromAddProduct",
              end_id: "addEndFromAddProduct",
            },
            value: {
              productValidity: this.state.productValidity,
              startDate: this.state.valdity[0],
              endDate: this.state.valdity[1],
            },
            breakPoints: {
              xs: 12,
              sm: 8,
              md: 8,
              lg: 8,
              xl: 8,
            },
            isMandatory: true,
            componentProps: {
              isEdit: isEdit,
              dropdownProps: {
                options:
                  validityOptions.length > 0
                    ? validityOptions?.map((x) => ({
                        label: x.label,
                        value: x.days,
                        id: x.value,
                      }))
                    : [],
                label: "VALIDITY PERIOD",
                value: this.state.productValidity,
                callback: () => this.handleValidityOptions("selected"),
                isDisabled: this.state.isDisableFields,
                id: "3e72625e-238e-46fd-a086-e0e0c48e6862_VALIDITY_PERIOD",
              },
              startDateProps: {
                label: "EFFECTING FROM",
                value: this.state.valdity[0],
              },
              endDateProps: {
                label: "ENDS ON",
                value: this.state.valdity[1],
              },
            },
          },
          /* group_type: {
            key: "group_type",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              required: true,
            },
            label: "SELECT GROUP TYPE",
            value: this.state.group_type,
            // onSelectedCallback: this.careProviderByCategory,
            options: this.props?.product_management_group_types ?? [],
            // isLoading: this.props?.categories?.loading,
            isMulti: false,
            isMandatory: true,
            // isDisabled: !this.state.assessment_required,
            // cleanUpKeys: ["select_provider"]
            // isDontShow: !this.state.assessment_required,
          },
          allowToShow: {
            key: "allowToShow",
            type: formComponentTypes.SWITCH_COMPONENT,
            componentProps: {
              style: {
                alignSelf: "center"
              },
              fixMargin: true
            },
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            withBoldHeading: true,
            onChangeCallBack: this.handleCheckBox,
            value: this.state.allowToShow,
            label: "Allow to show",
            // isMandatory: true,
          }, */
          // pre_assessment_required: {
          //   key: "pre_assessment_required",
          //   type: formComponentTypes.SWITCH_COMPONENT,
          //   componentProps: {
          //     style: {
          //       alignSelf: "center"
          //     }
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: this.state.pre_assessment_required ? 4 : 12,
          //     md: this.state.pre_assessment_required ? 4 : 12,
          //     lg: this.state.pre_assessment_required ? 4 : 12,
          //     xl: this.state.pre_assessment_required ? 4 : 12
          //   },
          //   withBoldHeading: true,
          //   onChangeCallBack: this.handleCheckBox,
          //   label: "Pre-Assessment Required"
          // },
          // pre_assessment: {
          //   key: "pre_assessment",
          //   type: formComponentTypes.SELECT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4
          //   },
          //   label: "SELECT PRE-ASSESSMENT",
          //   options: [
          //     { label: "pre_assessment1", value: 1 },
          //     { label: "pre_assessment2", value: 2 }
          //   ],
          //   isMandatory: false,
          //   isDisabled: !this.state.pre_assessment_required,
          //   isDontShow: !this.state.pre_assessment_required
          // },
          // pre_assessment_resource_type: {
          //   key: "pre_assessment_resource_type",
          //   type: formComponentTypes.SELECT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4
          //   },
          //   label: "SELECT RESOURCE TYPE",
          //   options: [
          //     { label: "pre_resourceType1", value: 1 },
          //     { label: "pre_resourceType2", value: 2 }
          //   ],
          //   isMandatory: false,
          //   isDisabled: !this.state.pre_assessment_required,
          //   isDontShow: !this.state.pre_assessment_required
          // },
          // assessment_required: {
          //   key: "assessment_required",
          //   type: formComponentTypes.SWITCH_COMPONENT,
          //   componentProps: {
          //     style: {
          //       alignSelf: "center"
          //     }
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: this.state.assessment_required ? 4 : 12,
          //     md: this.state.assessment_required ? 4 : 12,
          //     lg: this.state.assessment_required ? 4 : 12,
          //     xl: this.state.assessment_required ? 4 : 12
          //   },
          //   withBoldHeading: true,
          //   onChangeCallBack: this.handleCheckBox,
          //   label: "Assessment Required"
          // },
          // assessment: {
          //   key: "assessment",
          //   type: formComponentTypes.SELECT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4
          //   },
          //   label: "SELECT ASSESSMENT",
          //   options: [
          //     { label: "assessment1", value: 1 },
          //     { label: "assessment2", value: 2 }
          //   ],
          //   isMulti: true,
          //   isMandatory: false,
          //   isDisabled: !this.state.assessment_required,
          //   isDontShow: !this.state.assessment_required
          // },
          // assessment_resource_type: {
          //   key: "assessment_resource_type",
          //   type: formComponentTypes.SELECT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4
          //   },
          //   label: "SELECT RESOURCE TYPE",
          //   options: [
          //     { label: "resourceType1", value: 1 },
          //     { label: "resourceType2", value: 2 }
          //   ],
          //   isMulti: true,
          //   isMandatory: false,
          //   isDisabled: !this.state.assessment_required,
          //   isDontShow: !this.state.assessment_required
          // },
          // service_deliverable_required: {
          //   key: "service_deliverable_required",
          //   type: formComponentTypes.SWITCH_COMPONENT,
          //   componentProps: {
          //     style: {
          //       alignSelf: "center"
          //     }
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: this.state.service_deliverable_required ? 4 : 12,
          //     md: this.state.service_deliverable_required ? 4 : 12,
          //     lg: this.state.service_deliverable_required ? 4 : 12,
          //     xl: this.state.service_deliverable_required ? 4 : 12
          //   },
          //   withBoldHeading: true,
          //   onChangeCallBack: this.handleCheckBox,
          //   label: "Service Deliverable Required"
          // },
          // service_deliverables: {
          //   key: "service_deliverables",
          //   type: formComponentTypes.SELECT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4
          //   },
          //   label: "SERVICE DELIVERABLES",
          //   options: [
          //     { label: "notes", value: 1 },
          //     { label: "reports", value: 2 },
          //     { label: "upload", value: 3 },
          //     { label: "forms", value: 4 }
          //   ],
          //   isMulti: true,
          //   isMandatory: false,
          //   isDisabled: !this.state.service_deliverable_required,
          //   isDontShow: !this.state.service_deliverable_required
          // },
          // service_deliverable_resource_type: {
          //   key: "service_deliverable_resource_type",
          //   type: formComponentTypes.SELECT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4
          //   },
          //   label: "SELECT RESOURCE TYPE",
          //   options: [
          //     { label: "resourceType1", value: 1 },
          //     { label: "resourceType2", value: 2 }
          //   ],
          //   isMulti: true,
          //   isMandatory: false,
          //   isDisabled: !this.state.service_deliverable_required,
          //   isDontShow: !this.state.service_deliverable_required
          // },
          select_package_service: {
            key: "select_package_service",
            type: formComponentTypes.SELECT_BOX,
            id: "selectServiceFromAddProduct",
            componentProps: {},
            breakPoints: {
              xs: 12,
              sm: 6,
              md: 6,
              lg: 6,
              xl: 6,
            },
            label: "SELECT PACKAGE/SERVICE",
            value: this.state.rows,
            options:
              this.props?.servicePackageByCatPat?.data?.length > 0
                ? this.props?.servicePackageByCatPat?.data
                : [],
            isLoading: this.props?.servicesBasedOnCatAndPro?.loading,
            onSelectedCallback: this.updateServiceRow,
            isMulti: true,
            isMandatory: true,
            isDisabled: this.state.isDisableFields,
            isNotClearable: true,
          },
          currency: {
            key: "currency",
            type: formComponentTypes.SELECT_BOX,
            id: "selectCurrencyFromAddProduct",
            componentProps: {},
            breakPoints: {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            onSelectedCallback: (value) =>
              this.handleCheckBox(value, "currency"),
            label: "Currency Type",
            value: this.state.currency,
            options: options.currency,
            isMandatory: true,
            isDisabled: true,
          },
          allowToShow: {
            key: "allowToShow",
            type: formComponentTypes.SWITCH_COMPONENT,
            id: "switchAllowToShowFromAddProduct",
            componentProps: {
              style: {
                alignSelf: "center",
              },
              fixMargin: true,
            },
            breakPoints: {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            withBoldHeading: true,
            onChangeCallBack: this.handleCheckBox,
            value: this.state.allowToShow,
            label: "Allow to show",
            // isMandatory: true,
          },
        },
      },
      section_2: {
        label: "",
        fields: {
          product_name: {
            key: "select_package_service",
            type: formComponentTypes.TABLE,
            id: {
              tax_id: "addTaxFromAddProduct",
              discountId: "addDiscountFromAddProduct",
            },
            componentProps: {
              type: "text",
              colWidths: ["15%", "15%", "15%", "15%", "20%", "20%"],
              exoticRow: exoticRow,
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "PROCUCT NAME",
            disableRemove: this.state.isDisableFields,
            value: this.state.rows,
            columns: columns,
            rows: this.state.rows,
            handleRowsReorder: this.handleRowsReorder,
            handleRemove: this.handleRemove,
            discount: this.state.discount === 0 ? null : this.state.discount,
            tax: this.state.tax,
            taxType: this.state.taxType,
            taxTypeOptions: taxTypeOptions,
            total: this.state.total,
            onDiscountChange: this.onDiscountChange,
            onTaxChange: this.onTaxChange,
            onTaxTypeChange: this.onTaxTypeChange,
            onTotalChange: this.onTotalChange,
            onTextFieldChange: this.onTextFieldChange,
            handlePopperClose: this.handlePopperClose,
            handlePopperOpen: this.handlePopperOpen,
            currency: this.state.currency,
            openPopper: this.state.open,
            popperData: this.state.popperData,
            withDiscount: true,
            withTotal: true,
            withTax: false,
            withTaxAndTaxType: true,
            handleStrictStatusChange: this.handleStrictStatusChange,
            handleDependentServiceChange: this.handleDependentServiceChange,
          },
          description: {
            key: "description",
            id: "addDescriptionFromAddProduct",
            type: formComponentTypes.RICHTEXTEDITOR,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            componentProps: {
              label: "DESCRIPTION",
            },
            value: this.state.description,
          },
          terms_conditions: {
            key: "terms_conditions",
            id: {
              text_id: "addTermsConditionsFromAddProduct",
              button_id: "addTermsConditionsButtonFromAddProduct",
            },
            type: formComponentTypes.BUTTON_WITH_INPUT_FIELD,
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            buttonLabel: "+ Add",
            label: "TERMS & CONDITIONS",
            value: this.state.terms_conditions,
          },
        },
      },
    };

    if (
      user_roles.provider === auth_data.user_role &&
      auth_data?.other_roles?.indexOf("cross-bundling") == -1
    ) {
      delete components.section_1.fields.select_care_provider;
    }
    // super_admin MC user issues fix
    if (
      user_roles.super_admin === auth_data.user_role &&
      auth_data?.other_roles?.indexOf("cross-bundling") == -1
    ) {
      components.section_1.fields.select_care_provider.isMulti = false;
      components.section_1.fields.select_care_provider.value = Array.isArray(
        this.state.provider
      )
        ? this.state.provider?.[0]
        : this.state.provider;
      // return components
    }

    let { loading } = this.props?.addProduct;
    let {
      loading: infoLoading,
      error: infoError,
      data: infoData,
    } = this?.state?.productInfo;

    // let { loading: updateLoading } = this.props?.updateProductInfo;
    // console.log("this?.props?.productInfo",this?.props?.productInfo)
    return (
      <div style={{ padding: 20 }}>
        {isEdit ? (
          <>
            {infoLoading && (
              <LoadingSection message={"Loading product details..."} />
            )}
            {!infoLoading && infoError && (
              <InternatlServerError
                message={"Unable to fetch product details"}
                onRetry={this.getEssentials}
              />
            )}
            {!infoLoading && !infoError && !infoData && (
              <NotFoundSection message={"404 - Product Detail Not Found."} />
            )}
            {!infoLoading &&
              !infoError &&
              infoData &&
              Object.keys(infoData).length > 0 && (
                <AddEditProductManagement
                  components={components}
                  onSaveButtonClicked={this.onSaveButtonClicked}
                  // loading={updateLoading}
                  loading={infoLoading || this.state.localProductLoading}
                  isUrl={true}
                  saveButtonLabel={
                    loading || this.state.localProductLoading
                      ? "Updating..."
                      : "Update"
                  }
                  isResetState={this.state.isResetState}
                  saveButtonId={"saveButtonFromAddProduct"}
                  cancelButtonId={"cancelButtonFromAddProduct"}
                />
              )}
          </>
        ) : (
          <AddEditProductManagement
            components={components}
            onSaveButtonClicked={this.onSaveButtonClicked}
            loading={loading || this.state.localProductLoading}
            saveButtonLabel={
              loading || this.state.localProductLoading ? "Saving..." : "Save"
            }
            isResetState={this.state.isResetState}
            saveButtonId={"saveButtonFromAddProduct"}
            cancelButtonId={"cancelButtonFromAddProduct"}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state?.masterSet?.categories,
  providerBasedOnCategory: state?.careProvider?.providerBasedOnCategory,
  packagesAndServices: state?.productState?.packagesAndServices,
  addProduct: state?.productState?.addProduct,
  updateProductInfo: state?.productState?.updateProductInfo,
  productInfo: state?.productState?.productInfo,
  auth_data: state?.authState?.loginAuth?.data ?? {},
  getValidity: state?.masterSet?.validity ?? null,
  product_management_group_types:
    state?.masterSet?.product_management_group_types.data ?? null,
  dropPartner: state?.careProvider?.dropPartner,
  servicesBasedOnCatAndPro: state?.dependency_api?.subscription_mapping,
  servicePackageByCatPat: state?.dependency_api?.servicePackageByCatPat,
  categoriesList: state?.careProvider?.catProvider,
  getPartnerActiveStatusBySupervisor:
    state?.resourceReducer?.getPartnerActiveStatusBySupervisor,
  taxTypeMaster: state?.masterTax?.get_tax_integration,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(AddEditProduct)));
