/* eslint-disable eqeqeq */
import { Avatar, IconButton } from "@material-ui/core";
import { actions } from "tanyacare-middleware";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withAlertAndDialog } from "../../HOCs";
import ProfileDrawer from "../profileDrawer";

class Profile extends Component {
  state = {
    user: "",
    user_data: "",
  };

  componentDidMount() {
    this.getUserData();
    // this.getData()
  }

  getData = async () => {
    // let _ = await this.props.GET_LOGIN_USER_INFO(this.props.loginData?.user_id)
    // _ = _?.payload?.data;
    let pro_data =
      this.props.loginData?.user_role == "super-admin"
        ? "/images/tanyaCare/profile_logo/Tanya_Care_logo.png"
        : this.props.loginData?.user_role == "provider_admin"
        ? this.props.userInfo?.partnerMetav1[0]?.uploadImage?.value
        : this.props.userInfo?.resourceMetav1?.[0]?.uploadImage?.value;
    this.setState({ user: pro_data, user_data: this.props.userInfo });
    // console.log(this.props.userInfo)
  };

  getUserData = async () => {
    // let _ = await this.props.user_data();
    await this.props.GET_LOGIN_USER_INFO(this?.props?.loginData?.user_id)
    let { data: _ } = this.props?.profileInfo
    // _ = _?.payload?.data;
    // alert(_?.partnerMeta?.uploadImage?.value)
    // let user_data = _
    let pro_data =
      this.props.loginData?.user_role == "super-admin" || this.props.loginData?.user_role == "business-admin"
        ? "/images/tanyaCare/Tanya.png"
        : this.props.loginData?.user_role == "provider_admin"
        ?  `${process.env.REACT_APP_PROTOCAL}://${process.env.REACT_APP_FILE_HOST}/${process.env.REACT_APP_DB}/${_?.partnerMeta?.uploadImage?.value}`
        // :  `${process.env.REACT_APP_PROTOCAL}://${process.env.REACT_APP_FILE_HOST}/${process.env.REACT_APP_DB}/${_?.resourceMeta?.uploadImage?.value}`;
        :_?.resourceMeta?.uploadImage?.value
    this.setState({ user: pro_data, user_data: _ });
  };

  handleProfileDrawer = async () => {
    // let _ = await this.props.user_data();
    // _ = _?.payload?.data;
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ProfileDrawer
          loginData={this.props.loginData}
          // user_data={this.state.user_data}
          // reload={this.props.user_data}
        />
      ),
    });
  };

  render() {
    const { loginData } = this.props;
    return (
      <IconButton
        onClick={this.handleProfileDrawer}
        id={"ProfileDrawerButtonClick"}
        style={{ marginRight: 16, padding: 0 }}
      >
        {/* {} */}
        {/* {console.log(this?.state?.user)} */}
        {loginData?.profile_name ? (
          <Avatar style={{ borderRadius: 12 }} src={this?.state?.user}>
            {loginData?.profile_name?.[0]?.toUpperCase()}
          </Avatar>
        ) : (
          <Avatar style={{ borderRadius: 12 }} />
        )}
      </IconButton>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state?.custom_widget_dashboard?.userprofile?.data,
  profileInfo: state?.profile?.info,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAlertAndDialog(Profile)));
