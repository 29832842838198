import React from "react";
import {
  Button,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import ActivityAndMembersCard from "../../components/availabilityActivityComponents/ActivityAndMembersCard";
import ProfileCardActivitySummary from "../../components/availabilityActivityComponents/activitySummaryCards/ProfileCardActivitySummary";
// import CheckInCheckOutCard from '../../components/availabilityActivityComponents/activitySummaryCards/checkInCheckOutCard'
// import SummaryTextCard from '../../components/availabilityActivityComponents/activitySummaryCards/summaryTextCard'
import moment from "moment";
// import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LoadingSection } from "../../components/integerateHelper/loading";
import { InternatlServerError } from "../../components/integerateHelper/500";
import { colorCode } from "../../components/notifications/ActivityList";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
import { alertProps } from "../../utils";
import { loadScript } from "../../components/map/loadScriptFile";
import { TextField } from "../../components";
// import { SwitchComponent } from '../../components/switch';
// import { RadioButtonGroup } from 'material-ui';
// import { Radio } from '@material-ui/icons';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from '@material-ui/core/FormControl';
import FormLabel from "@material-ui/core/FormLabel";
import { DateTimePickersCustom } from "../../components";

const styles = (theme) => ({
  icon: {
    width: 20,
    height: 20,
  },
  cancelButton: {
    boxShadow: "unset !important",
  },
});

const CustomRadio = withStyles((theme) => ({
  root: {
    color: theme.palette.ternary.main,
    "&$checked": {
      color: theme.palette.ternary.dark,
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

class ActivityRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientLocation: null,
      addressLoading: false,
    };
  }

  handleScriptLoad = (latitude, longitude) => {
    // Google Location - Geocoder API
    const geocoder = new window.google.maps.Geocoder();
    const latlng = {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          //   results[0].formatted_address;
          this.setState({
            addressLoading: false,
            clientLocation: results[0].formatted_address,
          });
        } else {
          this.setState({
            clientLocation: null,
          });
        }
      } else {
        this.setState({
          clientLocation: null,
        });
      }
    });
  };

  componentDidMount = () => {
    this.getSummaryDetail();
  };

  getSummaryDetail = async () => {
    const activityID = this.props.data;
    await this.props.GET_ACTIVITY_SUMMARY({ activityID: activityID });
    const { data } = this.props?.summaryDetail;
    if (data?.client_info?.latitude && data?.client_info?.longitude) {
      this.getLocationFromLatlong();
    }
  };

  getLocationFromLatlong = () => {
    this.loadGoogleApi();
  };

  loadGoogleApi = () => {
    const { data } = this.props?.summaryDetail;
    const latitude = data?.client_info?.latitude;
    const longitude = data?.client_info?.longitude;
    if (
      latitude &&
      longitude &&
      latitude > -90 &&
      latitude < 90 &&
      longitude > -180 &&
      longitude < 180
    ) {
      this.setState(
        {
          addressLoading: true,
        },
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=AIzaSyBqeACJgp12OLU6EkHeUtFgya2naH1LhrU&callback=initMap&libraries=&v=weekly`,
          () => this.handleScriptLoad(latitude, longitude)
        )
      );
    }
  };

  closeDrawer = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
    // this.props.changeRequest(false, {target: {value: "cancel_request"}})
  };

  handleActivityDelete = async (activityId) => {
    // this.props.dialog.setDialog({
    //   open: true,
    //   title: "Confirmation",
    //   body: "Are you sure, you want to cancel this Activity?",
    //   positiveBtn: "Confirm",
    //   negativeBtn: "Cancel",
    //   onOk: () => {
    //     this.props.dialog.setDialog({ open: false });
    //     this.props.backDrop.setBackDrop({
    //       ...this.props.backDrop,
    //       open: true,
    //       message: "Deleting Activity..."
    //     });
    //     this.props.DELETE_ACTIVITY({ activityId: activityId });
    //   }
    // });
    console.log("HANDLE ACTIVITY DELETE");
    console.log(this.state?.cancelation_reason);
    console.log(this.state?.checked);
    if (!this?.props?.isReschedule) {
      if (this.state?.checked === "No") {
        // reject activity
        const param = {
          appointmentId: this.props?.summaryDetail?.data?.activity_id,
          remarks: this.state?.cancelation_reason,
          reschedule: false,
          fromTime: "",
          toTime: "",
          id: this.props.id,
          reject: true,
        };
        this.props.ADD_APPOINTMENT_REQUEST(param);
        this.closeDrawer();
      } else {
        //approve
        const param = {
          appointmentId: this.props?.summaryDetail?.data?.activity_id,
          remarks: "",
          reschedule: false,
          fromTime: "",
          toTime: "",
          id: this.props.id,
          approve: true,
        };

        this.props.dialog.setDialog({
          open: true,
          title: "Confirmation",
          body: "Are you sure, you want to cancel this Activity?",
          positiveBtn: "Confirm",
          negativeBtn: "Cancel",
          onOk: () => {
            this.props.dialog.setDialog({ open: false });
            this.props.backDrop.setBackDrop({
              ...this.props.backDrop,
              open: true,
              message: "Deleting Activity...",
            });
            this.props.DELETE_ACTIVITY({
              activityId: this.props?.summaryDetail?.data?.activity_id,
            });
            this.props.ADD_APPOINTMENT_REQUEST(param);
          },
        });
      }
    } else {
      if (this.state?.checked === "No") {
        // reject activity
        const param = {
          appointmentId: this.props?.summaryDetail?.data?.activity_id,
          remarks: this.state?.cancelation_reason,
          reschedule: true,
          fromTime: this.props.dataSum?.from_date,
          toTime: this.props.dataSum?.to_date,
          id: this.props.id,
          reject: true,
        };
        this.props.ADD_APPOINTMENT_REQUEST(param);
        this.closeDrawer();
      } else {
        const payload = {
          activityId: this.props?.summaryDetail?.data?.activity_id,
          title: this.props?.summaryDetail?.data?.activity_name,
          cliendId: this.props?.summaryDetail?.data?.client_id,
          serviceprofessionalId:
            this.props?.summaryDetail?.data?.service_professional_info.id,
          activity_type_id:
            this.props?.summaryDetail?.data?.activity_type.value,
          activityTypeName:
            this.props?.summaryDetail?.data?.activity_type.label,
          // from_timestamp: moment(data.from_timestamp).set("second", "00"),
          // to_timestamp: moment(data.to_timestamp).set("second", "00"),
          resourceId:
            this.props?.summaryDetail?.data?.service_professional_info.id,
          occurence: this.props?.summaryDetail?.data?.occurence,
          occurence_type: this.props?.summaryDetail?.data?.occurence,
          repeat_on: this.props?.summaryDetail?.data?.occurence,
          from_timestamp: new Date(
            new Date(this.props.dataSum?.from_date)?.setSeconds(0)
          ),

          // this.getFromTimeStampPayload(data),

          to_timestamp: new Date(
            new Date(this.props.dataSum?.to_date)?.setSeconds(0)
          ),

          //  this.getToTimeStampPayload(data),

          // occurence_type: data?.occurence_type ? data?.occurence_type : "",
          mode: this.props?.summaryDetail?.data?.mode
            ? this.props?.summaryDetail?.data?.mode
            : "VIDEO_CALL",
          videoCallUrl: this.props?.summaryDetail?.data?.videoCallUrl
            ? this.props?.summaryDetail?.data?.videoCallUrl
            : "",
          description: this.props?.summaryDetail?.data.activity_description,
          selectedVenue: this.props?.summaryDetail?.data?.selectedVenue,
          address: this.props?.summaryDetail?.data?.address
            ? this.props?.summaryDetail?.data?.address
            : null,
          longitude: this.props?.summaryDetail?.data?.longitude
            ? this.props?.summaryDetail?.data?.longitude?.toString()
            : null,
          latitude: this.props?.summaryDetail?.data?.latitude
            ? this.props?.summaryDetail?.data?.latitude?.toString()
            : null,
          subscriptionId: this.props?.summaryDetail?.data?.subscription?.value
            ? this.props?.summaryDetail?.data.subscription.value
            : "",
          subscriptionType: this.props?.summaryDetail?.data?.subscription
            ?.type_id
            ? this.props?.summaryDetail?.data?.subscription?.type_id
            : "",
          serviceId: this.props?.summaryDetail?.data?.service?.value
            ? this.props?.summaryDetail?.data?.service?.value
            : "",
        };
        // console.log(
        //   " 🛺payload_EDITACTIVITY---------------------------- ",
        //   payload
        // );
        console.log("------------------------------------------\n");
        console.log(payload);
        let res_data = await this.props.EDIT_ACTIVITY({ ...payload });

        console.clear();
        console.log(res_data?.payload?.error);
        if (!res_data?.payload?.error) {
          const param = {
            appointmentId: this.props?.summaryDetail?.data?.activity_id,
            remarks: this.state?.cancelation_reason,
            reschedule: true,
            fromTime: this.props.dataSum?.from_date,
            toTime: this.props.dataSum?.to_date,
            id: this.props.id,
            approve: true,
          };
          this.props.ADD_APPOINTMENT_REQUEST(param);
          this.props.GET_ACTIVITY_REQUESTS({
            status: "",
          });
          this.props.alert.setSnack({
            open: true,
            horizontal: alertProps.horizontal.center,
            vertical: alertProps.vertical.top,
            msg: "Activity Rescheduled Successfully!",
            severity: alertProps.severity.success,
          });
        } else {
          this.props.alert.setSnack({
            open: true,
            horizontal: alertProps.horizontal.center,
            vertical: alertProps.vertical.top,
            msg: "Something went wrong! Unable to Reschedule Activity.",
            severity: alertProps.severity.error,
          });
        }

        this.closeDrawer();
      }
    }
  };

  shouldComponentUpdate(nextProps) {
    // Delete Activity
    const { deleteActivity } = nextProps;
    const oldDeleteActivity = this.props.deleteActivity;

    if (oldDeleteActivity?.loading !== deleteActivity?.loading) {
      if (!deleteActivity.loading && !deleteActivity.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Activity Deleted Successfully!",
          severity: alertProps.severity.success,
        });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
        });
        // TODO:RELOAD and CLOSE DRAWER
        // this.props.reload();
        this.closeDrawer();
      } else if (deleteActivity.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Delete Activity.",
          severity: alertProps.severity.error,
        });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
        });
      }
    }

    return true;
  }

  render() {
    const { hideServiceProfessional, hideClient, classes } = this.props;

    const { data, loading, error } = this.props?.summaryDetail;

    return (
      <>
        {loading && <LoadingSection message="Loading activity summary..." />}
        {!loading && error && (
          <InternatlServerError
            message="Something went wrong, Try Again!"
            onRetry={this.getSummaryDetail}
          />
        )}
        {!loading && !error && !data && (
          <InternatlServerError
            message="Something went wrong, Try Again!"
            onRetry={this.getSummaryDetail}
          />
        )}
        {!loading && !error && data && (
          <>
            <Grid container>
              <Grid
                item
                container
                style={{ padding: "12px 12px 12px 0px", background: "white" }}
              >
                <Grid item>
                  <IconButton onClick={this.props.onBackBtnClicked}>
                    {/* <ChevronLeft /> */}
                  </IconButton>
                </Grid>
                <Grid item xs>
                  <Typography variant="h6" style={{ marginTop: 8 }}>
                    Activity Summary
                  </Typography>
                </Grid>
              </Grid>

              <ActivityAndMembersCard
                hideProfilePicture
                title={data?.activity_name}
                // date={moment(data?.from_time_stamp).format('dddd, MMMM D')}
                timing={`${moment(data?.from_time_stamp).format(
                  "Do MMM hh:mm A"
                )} - ${moment(data?.to_time_stamp).format("Do MMM hh:mm A")}`}
                hideIsActive
                activityStatus={data?.activity_type?.label} //activity_type will be a object with {value, label}
                activityStatusColor="#28a9c1"
                currentStatus={data?.activity_status}
                currentStatusColor={colorCode[data?.activity_status]}
                // useActivityAsSummaryCard
                hideEndIcon
                padding={2}
              />

              {/* <SummaryTextCard
                description={data?.activity_description}
                padding={2}
              /> */}

              {!hideClient && (
                <ProfileCardActivitySummary
                  profilePicture={data?.client_info?.profile_pic}
                  name={data?.client_info?.client_name}
                  gender={data?.client_info?.gender}
                  age={data?.client_info?.age}
                  address={this.state.clientLocation}
                  //videoCallUrl
                  meetingUrl={
                    data?.mode === "VIDEO_CALL" ? data?.videoCallUrl : false
                  }
                  addressLoading={this.state.addressLoading}
                  padding={2}
                  hideQuickIcons
                  hideAddressSection={false}
                />
              )}

              {!hideServiceProfessional && (
                <div style={{ display: "block", padding: "16px 16px 0px" }}>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                  >
                    SERVICE PROFESSIONAL:
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    {data?.service_professional_info?.label}
                  </Typography>
                </div>
              )}

              <Grid item xs={12}>
                {!this?.props?.isReschedule ? (
                  <>
                    <div style={{ display: "block", padding: 16 }}>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        gutterBottom
                      >
                        REASON FOR CANCELLATION:
                      </Typography>
                      <Typography variant="body1" color="textPrimary">
                        {data?.activity_cancellation_reason
                          ? data?.activity_cancellation_reason
                          : "N/A"}
                      </Typography>
                    </div>

                    {/* <SwitchComponent
                        data={""}
                        id={'switch_component'}
                        size={'sm'}
                        checked={this.state.checked}
                        //  handleOnChange={component.handleChange}
                        handleOnChange={(newValue) => {
                          this.setState({checked: !this.state?.checked});
                        }}
                        label={"Do you wish to Reject ?"}
                        fixMargin={false}
                    /> */}
                    {/* <Grid container> */}
                    <div
                      style={{
                        padding: "12px 16px 12px 16px",
                        background: "white",
                      }}
                    >
                      <FormLabel component="legend">
                        {this?.props?.isReschedule
                          ? "Service Professional requesting to reschedule the appointment. Do you want to approve?"
                          : "Service Professional requesting to cancel the appointment. Do you want to approve?"}
                      </FormLabel>
                      <RadioGroup
                        aria-label="approve"
                        style={{ flexDirection: "row" }}
                        name="approve"
                        value={this.state.checked || "Yes"}
                        onChange={(event) => {
                          this.setState({ checked: event?.target.value });
                        }}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<CustomRadio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<CustomRadio />}
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                    {/* </Grid> */}

                    {this.state?.checked === "No" && (
                      <Grid container>
                        <Grid
                          item
                          container
                          style={{
                            padding: "12px 12px 12px 12px",
                            background: "white",
                          }}
                        >
                          <TextField
                            id={`reasonForCancellation`}
                            required={true}
                            type={"text"}
                            // withBoldLabel={component.withBoldLabel}
                            // withoutUppercase={component?.withoutUppercase}
                            onlyText={false}
                            // limit={component.limit}
                            // state={component.key}
                            // isLimitHelperTextNeeded={
                            //   component?.componentProps?.isLimitHelperTextNeeded
                            // }
                            // overrideDecimalCheckForDiscount={
                            //   component?.componentProps?.overrideDecimalCheckForDiscount
                            // }
                            rowsMax={5}
                            multiline={true}
                            title={"Reason For Reject"}
                            placeholder={"Reason For Reject"}
                            defaultValue=""
                            value={this.state.cancelation_reason}
                            handleOnChange={(e) => {
                              console.log(e.target?.value);
                              this.setState({
                                cancelation_reason: e.target?.value,
                              });
                            }}
                            error={false}
                            errorText={"Reason Needed"}
                            disabled={false}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid container>
                      <Grid
                        item
                        container
                        style={{
                          padding: "12px 16px 12px 16px",
                          background: "white",
                        }}
                        justify={"flex-end"}
                      >
                        <div className={classes.saveBtnContainer}>
                          {/* {
            !this.state.openDrawer.open && */}
                          <Button
                            variant={"outlined"}
                            color={"primary"}
                            onClick={this.closeDrawer}
                            style={
                              this.props.cancelButtonStyles
                                ? {
                                    marginRight: 12,
                                    ...this.props.cancelButtonStyles,
                                  }
                                : { marginRight: 12 }
                            }
                            id={this.props?.cancelButtonId}
                          >
                            {"Cancel"}
                          </Button>
                          {/* } */}
                          <Button
                            variant={"contained"}
                            color={"primary"}
                            className={classes.saveBtn}
                            style={
                              this.props.saveButtonStyles
                                ? {
                                    margin: 16,
                                    padding: "5px 40px",
                                    ...this.props.saveButtonStyles,
                                  }
                                : { margin: 16, padding: "5px 40px" }
                            }
                            disabled={false}
                            id={this.props?.saveButtonId}
                            onClick={this.handleActivityDelete}
                          >
                            {this.state?.checked === "No"
                              ? "Reject"
                              : " Approve "}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "block",
                        padding: "16px 16px 0px 16px",
                      }}
                    >
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        gutterBottom
                      >
                        PROPOSED NEW TIME SLOT:
                      </Typography>
                      {/* <Typography variant="body1" color="textPrimary">
                        {data?.service_professional_info?.label}
                      </Typography> */}
                    </div>
                    <div style={{ padding: "0px 16px 16px 16px" }}>
                      <DateTimePickersCustom
                        value={new Date(this.props.dataSum?.from_date)}
                        label={"From Date & Time"}
                        disabled={true}
                        isDateTime={true}
                        onDeleteRemainder={() => {}}
                        hideClearIcon
                      />
                      <DateTimePickersCustom
                        value={new Date(this.props.dataSum?.to_date)}
                        label={"To Date & Time"}
                        disabled={true}
                        isDateTime={true}
                        onDeleteRemainder={() => {}}
                        hideClearIcon
                      />
                    </div>
                    <div
                      style={{
                        padding: "12px 16px 12px 16px",
                        background: "white",
                      }}
                    >
                      <FormLabel component="legend">
                        {this?.props?.isReschedule
                          ? "Service Professional requesting to reschedule the appointment. Do you want to approve?"
                          : "Service Professional requesting to cancel the appointment. Do you want to approve?"}
                      </FormLabel>
                      <RadioGroup
                        aria-label="approve"
                        style={{ flexDirection: "row" }}
                        name="approve"
                        value={this.state.checked || "Yes"}
                        onChange={(event) => {
                          this.setState({ checked: event?.target.value });
                        }}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<CustomRadio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<CustomRadio />}
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                    {this.state?.checked === "No" && (
                      <Grid container>
                        <Grid
                          item
                          container
                          style={{
                            padding: "12px 16px 12px 16px",
                            background: "white",
                          }}
                        >
                          <TextField
                            id={`reasonForCancellation`}
                            required={true}
                            type={"text"}
                            // withBoldLabel={component.withBoldLabel}
                            // withoutUppercase={component?.withoutUppercase}
                            onlyText={false}
                            // limit={component.limit}
                            // state={component.key}
                            // isLimitHelperTextNeeded={
                            //   component?.componentProps?.isLimitHelperTextNeeded
                            // }
                            // overrideDecimalCheckForDiscount={
                            //   component?.componentProps?.overrideDecimalCheckForDiscount
                            // }
                            rowsMax={5}
                            multiline={true}
                            title={"Reason For Reject"}
                            placeholder={"Reason For Reject"}
                            defaultValue=""
                            value={this.state.cancelation_reason}
                            handleOnChange={(e) => {
                              console.log(e.target?.value);
                              this.setState({
                                cancelation_reason: e.target?.value,
                              });
                            }}
                            error={false}
                            errorText={"Reason Needed"}
                            disabled={false}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid container>
                      <Grid
                        item
                        container
                        style={{
                          padding: "12px 16px 12px 16px",
                          background: "white",
                        }}
                        justify={"flex-end"}
                      >
                        <div className={classes.saveBtnContainer}>
                          {/* {
            !this.state.openDrawer.open && */}
                          <Button
                            variant={"outlined"}
                            color={"primary"}
                            onClick={this.closeDrawer}
                            style={
                              this.props.cancelButtonStyles
                                ? {
                                    marginRight: 12,
                                    ...this.props.cancelButtonStyles,
                                  }
                                : { marginRight: 12 }
                            }
                            id={this.props?.cancelButtonId}
                          >
                            {"Cancel"}
                          </Button>
                          {/* } */}
                          <Button
                            variant={"contained"}
                            color={"primary"}
                            className={classes.saveBtn}
                            style={
                              this.props.saveButtonStyles
                                ? {
                                    margin: 16,
                                    padding: "5px 40px",
                                    ...this.props.saveButtonStyles,
                                  }
                                : { margin: 16, padding: "5px 40px" }
                            }
                            disabled={false}
                            id={this.props?.saveButtonId}
                            onClick={this.handleActivityDelete}
                          >
                            {" Approve "}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  summaryDetail: state?.clientActivity?.activitySummary,
  deleteActivity: state?.activity_app?.delete_activity ?? {
    data: [],
    loading: false,
    error: false,
  },
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(ActivityRequest))));
