import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
// import { Print } from "../../assets/viewScreenIcons/print";
// import { Edit } from "../../assets/viewScreenIcons/edit";
// import { Download } from "../../assets/viewScreenIcons/download";
import PropTypes from "prop-types";
import { routes } from "../../router/routes";
import { Edit } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
// import { routes } from "../../router/routes";
// import { useHistory } from "react-router-dom";

const styles = makeStyles((theme) => ({
  type: {
    padding: "0px 10px",
    // background: "#ff0000",
    background: "#F44336",
    color: "white",
    borderRadius: 4,
    margin: "0px 10px",
    textTransform: "upperCase",
  },
  id: {
    color: "white",
    lineHeight: "1.43",
  },
  date: {
    color: "white",
  },
  svg: {
    fill: "white",
    width: 15,
    height: 15,
  },
  svgGrid: {
    // background: theme.palette.ternary.light,
    background: "rgba(233, 233, 233, 0.48)",
    margin: "0 5px",
    textAlign: "center",
    padding: 5,
    borderRadius: 4,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      margin: "0 2px",
    },
  },
  status: {
    background: "white",
    borderRadius: 4,
    // color: theme.palette.success.main,
    textAlign: "center",
    padding: "5px 8px",
    width: "fit-content",
    marginTop: 5,
  },
  openStatus: {
    color: theme.palette.ternary.main,
  },
  resolvedStatus: {
    color: "#31b198",
  },
  pendingStatus: {
    color: "#bc2d4f",
  },
}));

const RenderIcons = (props) => {
  const classes = styles();
  const history = useHistory();
  let { data } = props;
  return (
    <Grid container item xs={12} justify="flex-end">
      {props.enquiry_status && (
        <>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={2}
            className={classes.svgGrid}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={(id) => {
              history.push(routes.enquiry_list + "/edit/" + props.editkey);
            }}
          >
            <Edit className={classes.svg} id={props?.editButtonId} />
          </Grid>
          {!data?.clientstatus && (
            <Grid
              item
              xs={9}
              sm={9}
              md={9}
              lg={8}
              className={classes.svgGrid}
              id={props?.ConvertAsClientButtonId}
              onClick={() => {
                // props.handleDownload();
                // props.handleRedirect(data.redirectClientId);
                props.handleRedirect(data.redirectClientId, true);
              }}
            >
              <Typography
                variant="caption"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {/* {convert as client} */}
                View Client
              </Typography>
            </Grid>
          )}
          {data?.clientstatus && (
            <Grid
              item
              xs={9}
              sm={9}
              md={9}
              lg={8}
              className={classes.svgGrid}
              id={props?.handleRedirectButtonId}
              onClick={() => {
                // props.handleDownload();
                props.handleRedirect(data.redirectClientId, true);
              }}
            >
              <Typography
                variant="caption"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
                className={classes.ternaryButton}
              >
                View Client
              </Typography>
            </Grid>
          )}
        </>
      )}
      {!props.enquiry_status && data?.clientstatus && (
        <Grid
          item
          xs={9}
          sm={9}
          md={9}
          lg={8}
          className={classes.svgGrid}
          id={props?.handleRedirectButtonId}
          onClick={() => {
            // props.handleDownload();
            props.handleRedirect(data.redirectClientId, true);
          }}
        >
          <Typography
            variant="caption"
            style={{
              color: "white",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            View Client
          </Typography>
        </Grid>
      )}
      {/* <Grid
        item
        xs={3}
        sm={3}
        md={3}
        lg={2}
        className={classes.svgGrid}
        onClick={() => {
          props.handlePrint();
        }}
      >
        <Print className={classes.svg} />
      </Grid>
     */}
    </Grid>
  );
};
const customDateFormat = (date) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  let _date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let _month = date.getMonth();
  let _year = date.getFullYear();
  // new Date().getFullYear
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return (
    _date +
    " " +
    monthNames[_month] +
    ", " +
    _year +
    " at " +
    (hours < 10 ? "0" + hours : hours) +
    ":" +
    minutes +
    " " +
    ampm
  );
  // console.log(_date,_month,_year,"_date,_month,_year")
};
export const Header = (props) => {
  const classes = styles();
  const {
    data,
    handlePrint,
    handleDownload,
    handleRedirect,
    editkey,
    showButtons = true,
  } = props;

  return (
    <Grid container>
      <Grid container item xs={8} sm={9}>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          style={{ paddingBottom: 5 }}
        >
          <Typography variant="subtitle1" className={classes.id}>
            {data?.ref_id ? data.ref_id : ""}
          </Typography>
          {data?.lead_type?.label ? (
            <Typography variant="caption" className={classes.type}>
              {data?.lead_type?.label ? data.lead_type.label : ""}
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="caption"
            className={classes.date}
            style={{ paddingBottom: 5 }}
          >
            {data?.enquired_on
              ? customDateFormat(new Date(data.enquired_on))
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="caption"
            component="div"
            className={`${classes.status} ${
              data?.enquiry_status?.toLowerCase() === "enquiry created" ||
              data?.enquiry_status?.toLowerCase() === "quote submitted"
                ? classes.openStatus
                : data?.enquiry_status?.toLowerCase() === "quote finalized" ||
                  data?.enquiry_status?.toLowerCase() === "payment completed"
                ? classes.resolvedStatus
                : data?.enquiry_status?.toLowerCase() === "enquiry cancelled" ||
                  data?.enquiry_status?.toLowerCase() === "enquiry closed"
                ? classes.pendingStatus
                : ""
            }`}
            style={{ paddingBottom: 5 }}
          >
            {data?.enquiry_status ? data?.enquiry_status : ""}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={4} sm={3}>
        {showButtons ? (
          <RenderIcons
            handlePrint={handlePrint}
            handleDownload={handleDownload}
            editkey={editkey}
            editButtonId={props?.editButtonId}
            ConvertAsClientButtonId={props?.ConvertAsClientButtonId}
            handleRedirectButtonId={props?.handleRedirectButtonId}
            handleRedirect={handleRedirect}
            enquiry_id={data?.enquiryId ? data.enquiryId : 0}
            data={data}
            enquiry_status={
              data?.enquiry_status
                ? data.enquiry_status.toLowerCase() === "closed"
                  ? false
                  : true
                : true
            }
          />
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  id: PropTypes.string,
  listData: PropTypes.array,
  handlePrint: PropTypes.func,
  handleDownload: PropTypes.func,
};
