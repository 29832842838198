/* eslint-disable eqeqeq */
import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import SubHeader from "../../../../components/subHeader";
import { routes } from "../../../../router/routes";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ListLoader from "../../../../components/listLoader";
// import List from "../../../../components/userList/cardList";
import { alertProps, filterTagType, fontStyleClass, productSortDirectionOption, productSortFieldOption, scrollToTop, subHeaderSortOptionWithValue, user_roles } from "../../../../utils";
import { filterComponent } from "./filterComponent";
import FilterTags from "../../../../components/filters/filterTags";
import { FilterBuilder, InternatlServerError, NotFoundSection,
  // ListCard
} from "../../../../components";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { ProductCatalogue } from "../../../../components/productCatalogue/productCatalogue";


// const sizes = {
//   md: 3,
//   sm: 3,
//   xs: 3,
// };
const styles = (theme) => ({
  root: {
    padding: 25,
  },
  ...fontStyleClass,
});

const initialState = {
  pageNo: 0,
  pageSize: 25,
  anchorEl: null,
  searchText: "",
  // sortfield: productSortFieldOption[0],
  // sortDirec: productSortDirectionOption[1],
  sortDirec: subHeaderSortOptionWithValue[2],

  // Filter States
  category: [],
  provider: [],
  services: [],
  packages: [],
  status: undefined,
  isFilterApplied: false,
  open:false,
  popperData:[]
}
class ListProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      id:"AddProducts",
    };
  }

  componentDidMount() {
    this.getProducts();
    scrollToTop();
    window.addEventListener("scroll", this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  }

  shouldComponentUpdate(nextProps, nextState) {

    //Update Status
    const { updateProductStatusInfo } = nextProps;
    const oldupdateProductStatusInfo = this.props.updateProductStatusInfo;

    //Checking for Update Status
    if (
      oldupdateProductStatusInfo?.loading !== updateProductStatusInfo?.loading
    ) {
      this.props.backDrop.setBackDrop({ open: updateProductStatusInfo.loading, message: "Updating the product status..." })
      if (
        !updateProductStatusInfo.loading &&
        !updateProductStatusInfo.error
      ) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Product Status updated successfully!",
          severity: alertProps.severity.success,
        });
        this.getProducts();
      } else if (updateProductStatusInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to update product status.",
          severity: alertProps.severity.error,
        });
      }
    }

    return true;
  }
  scrolling = () => {
    let { loading, data, error } = this.props?.products;

    let listCount = data?.length ?? 0;
    let totalCount = data?.[0]?.total_count ?? 0;

    if (loading || error || totalCount === 0 || listCount === 0) {
      return false;
    }

    if ((Math.round(window.scrollY + window.innerHeight) >= document.getElementsByTagName('body')[0].scrollHeight) && (listCount !== totalCount)) {
      this.setState((state, props) => ({
        pageNo: state.pageNo + 25,
      })
        , () => {
          this.getProducts();
        });
    }
  }

  getProducts = () => {
    let _status = undefined
    if(this.state?.status){
      if(this.state.status.toLowerCase() === "Active".toLowerCase()) _status = true
      if(this.state.status.toLowerCase() === "InActive".toLowerCase()) _status = false
    }
    const payload = {
      pageNo: this.state.pageNo,
      pageSize: this.state.pageSize,
      // sortDirec: this.state.sortDirec === null ? productSortDirectionOption[0].value : this.state.sortDirec.value,
      // sortfield: this.state.sortfield === null ? productSortFieldOption[0].value : this.state.sortfield.value,
      // category: this.state.category && this.state.category?.map(_ => _.value)?.join(','),
      // providerId: this.state.provider && this.state.provider?.map(_ => _.value)?.join(','),
      // status: this.state.status,
      filter:{sort: this.state.sortDirec.value,
        category: this.state.category && this.state.category?.map(_ => _.value),
        partnerIds: this.state.provider && this.state.provider?.map(_ => _.value),
        status: _status},
      searchName: this.state.searchText,
      limit:this.state.pageSize,
      offset:this.state.pageNo,
      //TODO: services state is not USED.
      //TODO: packages state is not USED.
    }

    this.props.GET_ALL_PRODUCTS(payload);
  };

  navigateToView = (id, versionId) => {
    this.props.history.push(routes.manage_product + "/view/" + id + "/" + versionId);
  };

  // storeInputSearchValue = (value) => {
  //   if (!value) {
  //     this.setState({ searchText: "" }, () => {
  //       // THIS LINE OF CALLING FUNCTION IS USED TO FETCH LIST IF INPUT IS EMPTY
  //       // this.getCareCoordinatorList();
  //     });
  //   } else this.setState({ searchText: value });
  // };

  storeInputSearchValue = (value) => {
    this.setState(
      { searchText: value, pageNo: 0, pageSize: 25 },
      () => {
        // if (value.length % 3 === 0) {
        this.getProducts();
        // }
      }
    );
  };

  // -----------------------FILTERS-------------------
  // FILTERS FUNCTION
  // Filter Handlers
  onFilterIconClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: <FilterBuilder
        components={filterComponent(this.state, this.props)}
        applyButtonLabel="Apply "
        loading={this.props?.products?.loading}
        onChange={this.onFilterChange}
        onApplyButtonClicked={this.applyFilter}
        resetFilter={this.resetFilter}
        onCancelClick={this.closeFilter}
        spacing={1}
        saveButtonId={"saveFilterButtonFromAddProduct"}
        cancelButtonId={"cancelFilterButtonFromAddProduct"}
        clearAllButtonId={"clearAllFilterButtonFromAddProduct"}
      />
    })
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  anyFilterApplied = (state) => {
    let { category = [], provider = [], status = "", sortDirec = productSortDirectionOption[0], sortfield = productSortFieldOption[0] } = state
    if(category === null) category = initialState.category
    if(provider === null) provider = initialState.provider
    if (JSON.stringify(category) === JSON.stringify(initialState.category) &&
      JSON.stringify(provider) === JSON.stringify(initialState.provider) &&
      status === initialState.status &&
      sortDirec.value === initialState.sortDirec.value &&
      sortfield.value === initialState.sortfield.value) {
      return false
    } else {
      return true
    }
  }

  applyFilter = (state) => {
    this.setState({
      ...state,
      pageNo: 0,
      pageSize: 25,
      isFilterApplied: this.anyFilterApplied(state),
    }, () => {
      this.getProducts();
      this.closeFilter()
    })
  }

  closeFilter = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    })
  }

  resetFilter = () => {
    this.setState({
      ...initialState
    }, () => {
      this.getProducts();
      this.closeFilter();
    })
  }


  // FILTER TAG FUNCTION
  // Filter Tag Utils
  checkIsThereAnyFilter = () => {
    let newState = {
      ...this.state,
      category: this.state.category === null ? initialState.category : this.state.category,
      provider: this.state.provider === null ? initialState.provider : this.state.provider,
    }
    if (
      newState[filterTagType.Category].length === 0 && 
      newState[filterTagType.Provider].length === 0 && 
      //TODO: // newState[filterTagType.Services].length === 0 && 
      //TODO: // newState[filterTagType.Packages].length === 0 && 
      newState[filterTagType.Status] === initialState.status &&
      newState[filterTagType.SortDirection].value === initialState.sortDirec.value 
      // &&newState[filterTagType.SortField].value === initialState.sortfield.value
      ) {
      return false
    } else return true
  }

  handleChipDelete = (key, index) => {
    if (key === filterTagType.Category || 
        key === filterTagType.Provider
        //TODO: //|| key === filterTagType.Services
        //TODO: //|| key === filterTagType.Packages
        ) {
      let newState = this.state
      newState[key].splice(index, 1)
      this.setState({
        ...newState,
        pageNo: 0,
        pageSize: 25,
        isFilterApplied: this.checkIsThereAnyFilter()
      }, () => {
        this.getProducts()
      })

    }
    if (key === filterTagType.Status) {
      this.setState({
        status: initialState.status,
        pageNo: 0,
        pageSize: 25,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getProducts()
      })
    }
    if (key === filterTagType.SortDirection) {
      this.setState({
        sortDirec: initialState.sortDirec,
        pageNo: 0,
        pageSize: 25,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getProducts()
      })
    }
    if (key === filterTagType.SortField) {
      this.setState({
        sortfield: initialState.sortfield,
        pageNo: 0,
        pageSize: 25,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getProducts()
      })
    }

  }
  handleEdit = (id, versionId) => {
    this.props.history.push(routes.manage_product + "/edit/" + id + "/" + versionId);
  };

  handleDeactivate = (data) => {
    
    const Oldstatus = data?.isactive;
    let status = "Activate";
    if (Oldstatus === "active") {
      status = "Deactivate";
    }
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body: "Do you want to " + status + " Product : " + data?.name,
      positiveBtn: status,
      negativeBtn: "Cancel",
      onOk: () => this.deactivateProduct(data),
    });
  };

  deactivateProduct = (data) => {
    
    const id = data?.id;
    const status = data?.isactive?.toLowerCase() === "active" ? true : false;
    const versionId = data?.versionId;

    this.props.dialog.setDialog({ open: false });
    this.props.UPDATE_STATUS_OF_PRODUCT({ productID: id, status: !status, versionId: versionId });
  };
  // -----------------------------------------------

  handlePopperClose = () =>{
    
    this.setState({open:false})
  }
  handlePopperOpen = (e, data) => {
    
    this.setState({open:e.currentTarget,popperData:data})
  }

  roleBasedAccess = (id) => {
    const data = this.props?.auth_data?.data;
    
    // Return True disable edit button
    // Return False enable edit button

    let disable = null;
    if(Boolean(data?.user_role === user_roles.service_coordinator || data?.user_role === user_roles.help_desk)){
      /*SC can;t edit a Product */
      disable = true
    }

    if (data?.user_role == user_roles.provider) {
      if(data?.user_id == id?.partnerId){
        /*User can edit a Product*/
        disable = false
      }else{
        disable = true
      } 
    }
    if (data?.user_role == user_roles.supervisor) { 
      
        if (data?.partnerId == id?.partnerId) { 
            disable = false 
        } else{
          disable = true 
        } 
    }
    if (data?.user_role == user_roles.super_admin && data?.other_roles?.indexOf("cross-bundling") > -1) { 
      if (id?.care_provider?.[0]?.crossBundle) { 
        // id?.partnerId !== "5cd8f293-1e49-473e-af6b-bf27e986566f"
          /* no one can edit MC User's product except him*/
          disable = false
      }else{
          disable = true 
      }
    }

    if (data?.user_role == user_roles.super_admin && data?.other_roles?.indexOf("cross-bundling") === -1) { 
      if (id?.care_provider?.[0]?.crossBundle) { 
          /* no one can edit MC User's product except him*/
          disable = true
        }else{
          disable = false
      }
    }

    return disable;

  }


  addStyles = (products) => {
    let styledProducts = products.map((product) => {
      let orderList = product.orderList.map(list => {
        if(list?.label?.toString()?.toLowerCase() === "total"){
          return {...list, style: {minWidth: 140}}
        }
        return list
      })
      return {...product, orderList, isactive: product?.isactive ? "active" : "inactive"}
    })
    return Array.isArray(styledProducts) ? styledProducts : []
  }

  render() {
    let { loading, data, error } = this.props?.products;
    
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          container
          // direction="column"
          // justify="flex-start"
          // alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <SubHeader
              isbutton={!Boolean(this.props?.auth_data?.data?.user_role === user_roles.service_coordinator || this.props?.auth_data?.data?.user_role === user_roles.help_desk || this.props.auth_data.data.user_role === user_roles.business_admin)}
              placeholder={"Search for Products"}
              button_name={"+ ADD PRODUCT"}
              countText={`Total Products (${data?.length ?? 0})`}
              entityName={"Product"}
              link={routes.add_product}
              handleSearch={() => {this.getProducts()}}
              handleChangeInputSearch={this.storeInputSearchValue}
              value={this.state.searchText}
              useNewFilterIcon
              placeSearchIconToLeft
              // hideFilter
              // hideSearch

              isFilterApplied={this.state.isFilterApplied}
              onFilterIconClicked={this.onFilterIconClicked}
              id={"AddProducts"}
            />
          </Grid>

          {/* Filter Tag */}
          {
            this.state.isFilterApplied &&
            <Grid item xs={12} style={{ marginTop: 25 }} >
              <FilterTags
                filterData={this.state}
                handleDelete={this.handleChipDelete}
              />
            </Grid>
          }

          {/*TOTAL COUNT  */}
          {/* <Grid item xs={12}>
            <Typography variant="h6" style={{ paddingTop: 25 }} className={classes.mediumFont} >Total Products ({data?.total_count ?? 0})</Typography>
          </Grid> */}


          {loading && (
            <Grid item xs={12}>
              <ListLoader />
            </Grid>
          )}
          {!loading && error && (
            <Grid item xs={12}>
              <InternatlServerError
                message="Unable to fetch products"
                onRetry={this.getProducts}
                top="20vh"
              />
            </Grid>
          )}
          {!loading && !error && !data?.length && (
            <Grid item xs={12}>
              <NotFoundSection message="You don't have any products" top="25vh" />
            </Grid>
          )}
          {!error && !loading && data?.length > 0 && (
            <Grid item xs={12}>
              <ProductCatalogue
                id={"Products"}
                catalogues={this.addStyles(data)}
                redirectUrl={routes.manage_product + "/view/"}
                handleItemEdit={this.handleEdit}
                handleItemDeactivate={this.handleDeactivate}
                loading={loading}
                showProvider
              />
              {/* {data.map((list, key) => {
                return (
                  <div key={key} style={{ cursor: "pointer", marginBottom: 12 }} 
                  // onMouseLeave={this.handlePopperClose}
                  onMouseLeave={(e)=> {
                    if(e?.relatedTarget?.id === "popper"){
                      return false
                    } else {
                      this.handlePopperClose()
                    }
                  }}
                  >
                    <ListCard
                      data={list}
                      open={this.state.open}
                      handlePopperClose={this.handlePopperClose}
                      handlePopperOpen={this.handlePopperOpen}
                      popperData={this.state.popperData}
                      // isMoreBtn={true}
                      isMoreBtn={!this.roleBasedAccess(list)}
                      handleEdit={this.handleEdit}
                      handleDelete={this.handleDelete}
                      handleDeactivate={this.handleDeactivate}
                      navigateToView={this.navigateToView}
                      // isCountLabelAPrice
                      setPriceTrailingDecimal
                    />
                  </div>
                );
              })} */}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state?.productState?.allProducts,
  updateProductStatusInfo: state?.productState?.updateProductStatusInfo,
  auth_data: state?.authState?.loginAuth,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(ListProducts))));
