import React, { Component } from 'react'
import ActivityAndMembersCard from '../../../../components/availabilityActivityComponents/ActivityAndMembersCard'
import { Typography } from '@material-ui/core'

export default class ViewServiceCoordinators extends Component {

  onEndIconClickHandler = (id) => {
    if(id) this.props.onEndIconClickHandler(id,false)
  }

  onRemoveAssigned = (id, data) => {
    // console.log(this.props.persona_details[0])
    // let professional_name = this.props.persona_details[0].resourceName;
    // console.log(professional_name)
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: true,
      title: `Are you sure you want to remove ${data?.name }  ?`,
      body: "",
      positiveBtn: "OK",
      negativeBtn: "CANCEL",
      dontShowButtons: false,
      onOk: () => {
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          open: false,
        })
        this.props.dialog.setDialog({
          open: false
        });
        this.props.onRemoveAssigned(id)
      },
      onCancel: () => {
        this.props.dialog.setDialog({
        open: false
        }); 
      },
    });
    // alert("DATA")
  }

  render() {
    const { data } = this.props
    // console.log(this.props)

    return (
      <div>
        {/* Heading */}
        <Typography variant="h6" style={{ padding: "14px 14px 14px 14px" }}>
          {data?.length ?? 0} Service Coordinator(s)
        </Typography>

        {/* Service Coordinator List */}
        {data?.map((item, index) => {
          return (
            <ActivityAndMembersCard
              key={index}
              profilePicture={item?.profile_pic}
              title={item?.name}
              name={item?.care_provider?.map(_=>_.name).toString()}
              onEndIconClickHandler={()=>this.onEndIconClickHandler(item?.id)}
              onRemoveAssigned={()=> this.onRemoveAssigned()}
              showRemoveAssigned={this.props.showRemoveAssigned}
              status={item?.isactive}
              // hideEndIcon
            />
          )
        })}
        {/* <Typography>View all Service Coordinator Screen Needed</Typography> */}
      </div>
    )
  }
}
