import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { routes } from "../../router/routes";

import { withNavBars } from "./../../HOCs";

// import { AddEditBranch, BranchList, BranchDetail } from "./branches";
import { AddEditProvider, ProviderDetail, ProviderList } from "./providers";

class PartnerManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateNavBarProps: true,
    };
  }

  render() {
    const { match } = this.props;

    return (
      <>
        {/* Services routes */}
        {match.path === routes.partner_management_branch && <ProviderList userRole={this.props?.userRole} id={"ListingBranch"}/>}
        {match.path === routes.partner_management_branch_add && <AddEditProvider id={"AddBranch"}/>}
        {match.path === routes.partner_management_branch_edit && <AddEditProvider isEdit={true} />}
        {match.path === routes.partner_management_branch_detail && <ProviderDetail />}

        {/* Package routes */}
        {match.path === routes.partner_management_provider && <ProviderList userRole={this.props?.userRole} id={"ListingProvider"}/>}
        {match.path === routes.partner_management_provider_add && <AddEditProvider id={"AddProvider"}/>}
        {match.path === routes.partner_management_provider_edit && <AddEditProvider isEdit={true} />}
        {match.path === routes.partner_management_provider_detail && <ProviderDetail />}

        
      </>
    );
  }
}

PartnerManagement.propTypes = {
  title: PropTypes.string,
  withBackButton: PropTypes.bool,
  navBarArr: PropTypes.array,
};

PartnerManagement.defaultProps = {
  title: "Partner Managment",
};

export default withRouter(withNavBars(PartnerManagement));
