import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  removetext: {
    color: theme.palette.ternary.main,
    transform: "translate(0, 1.5px) scale(0.85)",
    transformOrigin: "top left",
    cursor: "pointer",
  },
  mainText: {
    margin: "8px 0px",
  },
  text: {
    // whiteSpace: "nowrap",
    // width: "100%",
    // height: '3em',  
    overflow: "hidden",
    // textOverflow: "ellipsis",
    display: '-webkit-box',
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
}));
