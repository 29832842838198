import React from "react";
import ReportComponent from "./report";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "tanyacare-middleware";

const { GET_ORDER_REPORT } = actions;

const OrderReport = () => {
  const ref = React.useRef(null);
  const [fromDate, setFrom] = React.useState();
  const [toDate, setTo] = React.useState();
  const [applied, setApplied] = React.useState(false);

  const dispatch = useDispatch();

  const getData = useSelector(
    (state) => state?.reportManagementSlice?.getOrder
  );

  const type = {
    "Parent ID": {
      type: "string",
    },
    "Partner Type": {
      type: "string",
    },
    "Partner ID": {
      type: "string",
    },
    "Partner Name": {
      type: "string",
    },
    Country: {
      type: "string",
    },
    Region: {
      type: "string",
    },
    State: {
      type: "string",
    },
    Industry: {
      type: "string",
    },
    "Business Nature": {
      type: "string",
    },
    "Client ID": {
      type: "string",
    },
    "Client Name": {
      type: "string",
    },
    "Mode of Activity": {
      type: "string",
    },
    "Service ID": {
      type: "string",
    },
    "Service Name": {
      type: "string",
    },
    "Service Category": {
      type: "string",
    },
    "Service Type": {
      type: "string",
    },
    "Service Group": {
      type: "string",
    },
    "Invoice No": {
      type: "string",
    },
    "Order ID": {
      type: "string",
    },
    "Order Status": {
      type: "string",
    },
    Quantity: {
      type: "number",
    },
    "Unit Price": {
      type: "number",
    },
    "Gross Amount": {
      type: "number",
    },
  };

  const fetchData = () => {
    dispatch(
      GET_ORDER_REPORT({
        fromDate: moment(fromDate).startOf("day").toISOString(),
        toDate: moment(toDate).endOf("day").toISOString(),
      })
    );
  };
  return (
    <ReportComponent
      type={type}
      getData={getData}
      fromDate={fromDate}
      toDate={toDate}
      fetchData={fetchData}
      applied={applied}
      setApplied={setApplied}
      setTo={setTo}
      setFrom={setFrom}
      tableRef={ref}
    />
  );
};
export default OrderReport;
