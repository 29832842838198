import React from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  withStyles,
  InputLabel,
  makeStyles,
  Popper,
  Typography,
  Fade,
  Paper,
  Button,
  Slider,
  Grid,
  // TextField,
} from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { FormHelperText } from "@material-ui/core";
import ClickAwayListener from "material-ui/internal/ClickAwayListener";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
    borderRadius: 8,
    border: (props) =>
      `${theme.form.borderSize} solid ${
        props.error
          ? theme.palette.error.main
          : theme.palette.secondary.borderColor
      }`,
    "&$focused": {
      border: (props) =>
        `${theme.form.borderSize} solid ${
          props.error ? theme.palette.error.main : theme.palette.ternary.main
        }`,
    },
  },
  // Don't remove this class "focused"
  focused: {},
  input: {
    borderRadius: 8,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    // border: `${theme.form.borderSize} solid ${theme.palette.secondary.borderColor}`,
    fontSize: 16,
    width: "100%",
    padding: (props) => (props.padding ? props.padding : "10px 12px"),
    height: "20.4px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}))(InputBase);

const DurationSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "8px 0px 8px 0px",
    "& .MuiFormLabel-root.Mui-focused": {
      color: "unset !important",
    },
    "& .MuiInputLabel-asterisk": {
      color: theme.palette.error.main,
    },
  },
  showTime: {
    width: "100%",
    textAlign: "center",
  },
  timeRoot: {
    display: "inline-block",
    borderRadius: 3,
  },
  time: {
    width: 65,
    // height: 65,
    fontSize: 38,
    // lineHeight: 65,
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    color: "white",
  },
  seperator: {
    display: "inline-block",
    fontSize: 32,
    fontWeight: 700,
    color: theme.palette.primary.main,
    width: 32,
    height: 65,
    // lineHeight: 65,
    textAlign: "center",
  },
  errorText: {
    fontSize: "0.8rem",
  },
}));

const sliderType = {
  hours: "hours",
  minutes: "minutes",
};

export default function DurationPicker({
  label,
  value,
  handleOnSave,
  required,
  error,
  errorText,
  id,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [durationText, setDurationText] = React.useState("");
  const classes = useStyles();

  React.useEffect(() => {
    const durationInt = parseInt(value ? value : 0);
    const hours = parseInt(durationInt / 60);
    const minutes = parseInt(durationInt % 60);
    setHours(hours);
    setMinutes(minutes);
    let duration = `${hours > 0 ? hours : ""}${
      hours > 0 ? (hours <= 1 ? " hour" : " hours") : ""
    }${minutes > 0 ? (hours > 0 ? " " : "") + minutes : ""}${
      minutes > 0 ? (minutes <= 1 ? " minute" : " minutes") : ""
    }`;
    setDurationText(duration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePopperStatus = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  const handleSliderClick = (whichSlider, newValue) => {
    switch (whichSlider) {
      case sliderType.hours: {
        setHours(newValue);
        break;
      }
      case sliderType.minutes: {
        setMinutes(newValue);
        break;
      }
      default:
        return false;
    }
  };

  const handleDurationInputChange = (e) => {
    
    // 19
    // 7
    // 11 Hours 22 Minutes

    let value = e?.target?.value ?? "";

    const validator = (value) => {
      
      if (value?.length > 8) {
        if (value?.length > 10) {
          if (value?.length === 11) {
            let minutes = value.split(" ")[2];
            let minutes_appender =
              minutes === 0 ? " minutes" : minutes === 1 ? " minute" : " minutes";
            return value?.[11] > 9 ? value?.[10] : value.concat(minutes_appender);
          } else return value.slice(0, 1);
        }
        else if(value?.length === 10){
         return value?.[10] > 6 ? value?.[9] : value
        }
        else return value;
      } else if (value?.length === 8) return value.slice(0, 1);
      else if (value?.length === 2) {
        let hours = value.split(" ")[0];
        let hours_appender =
          hours === 0 ? " hours " : hours === 1 ? " hour " : " hours ";
        return value?.[1] > 4 ? value?.[0] : value.concat(hours_appender);
      } else return value?.[0] > 2 ? "" : value;
    };

    if (value?.length < 20) {
      let _getValue = validator(value);
      let hours = _getValue.split(" ")[0];
      let minutes = _getValue.split(" ")[2];
      setHours(hours);
      setMinutes(minutes);
      setDurationText(_getValue);
    }
  };

  const handleKeyPress = (e) => {
    
    if (
      !(
        (e.which >= 48 && e.which <= 57) ||
        e.which === 8 ||
        (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true))
      )
    )
      e.preventDefault();
  };
  const onDurationSave = () => {
    setDurationText(
      `${hours > 0 ? hours : ""}${
        hours > 0 ? (hours <= 1 ? " hour" : " hours") : ""
      }${minutes > 0 ? (hours > 0 ? " " : "") + minutes : ""}${
        minutes > 0 ? (minutes <= 1 ? " minute" : " minutes") : ""
      }`
    );
    handlePopperStatus();
    let duration = hours * 60 + minutes;
    handleOnSave(parseInt(duration) === 0 ? "" : duration + "");
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <FormControl className={classes.root}>
        <InputLabel shrink required={required}>
          {label}
        </InputLabel>
        <BootstrapInput
          // id="duration"
          id={id}
          // onClick={handlePopperStatus}
          onKeyDown={(e) => {
            handleKeyPress(e);
          }}
          onKeyPress={(e) => {
            handleKeyPress(e);
          }}
          onChange={(e) => {
            handleDurationInputChange(e);
          }}
          value={durationText}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handlePopperStatus}>
                <ScheduleIcon />
              </IconButton>
            </InputAdornment>
          }
          error={error}
        />
        {
          <FormHelperText error={error} className={classes.errorText}>
            This field is required if "is Bookable" is turned on
          </FormHelperText>
        }
      </FormControl>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={"bottom"}
        transition
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper style={{ width: 320 }}>
                <div style={{ padding: 20 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className={classes.showTime}>
                        <span className={`${classes.time} ${classes.timeRoot}`}>
                          {hours}
                        </span>
                        <span className={classes.seperator}>:</span>
                        <span className={`${classes.time} ${classes.timeRoot}`}>
                          {minutes}
                        </span>
                      </div>
                      {/* <TextField value={durationText} onChange={(event, newValue) =>
                        handleDurationInputChange(newValue)
                      } maxlength={5}/> */}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>Hours:</Typography>
                      <DurationSlider
                        // valueLabelDisplay="auto"
                        value={hours}
                        onChange={(event, newValue) =>
                          handleSliderClick(sliderType.hours, newValue)
                        }
                        max={24}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>Minutes:</Typography>
                      <DurationSlider
                        // valueLabelDisplay="auto"
                        aria-label="pretto slider"
                        value={minutes}
                        onChange={(event, newValue) =>
                          handleSliderClick(sliderType.minutes, newValue)
                        }
                        max={59}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginTop: 8 }}></div>
                      <Button
                        onClick={onDurationSave}
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}
