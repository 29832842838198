import React from "react";
import { Grid, Dialog, DialogContent, makeStyles } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import EditIcon from "@material-ui/icons/Edit";
import RenderPdf from "./components/renderPdf";
import SendQuote from "./index";
import { QuoteInvoiceRenderer } from "./quote";
import { createTestProps } from "../../../../../utils/common";

const newColumnsToPrint = [
  {
    id: "sno",
    label: "S.No",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "serviceId",
    label: "SERVICE ID",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "description",
    label: "DESCRIPTION",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "quantity",
    label: "QUANTITY",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "unitPrice",
    label: "UNIT PRICE (RM)",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "discount",
    label: "DISCOUNT %",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "amount",
    label: "AMOUNT (RM)",
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
  },
  icon: {
    fill: theme.palette.secondary.main,
  },
}));

export default function QuoteActions(props) {
  const classes = useStyles();
  const { data, enquiry_id } = props;

  const returnAmount = (quantity, discount, amount) => {
    quantity = quantity ? parseInt(quantity) : 1;
    discount = discount ? parseFloat(discount) : 0;
    amount = parseFloat(amount);

    let totalQuantityAmt = quantity * amount;
    let returnAmt = totalQuantityAmt - (totalQuantityAmt * discount) / 100;

    return returnAmt;
  };

  const createRows = (data, cat) => {
    // return data?.metaData?.services?.map((val) => ({
    //     provider: val.providerInfo.label,
    //     product_name: val.label,
    //     type: val.type,
    //     frequency: val?.frequency ?? "N/A",
    //     category: val?.providerInfo?.category?.[0]?.label,
    //     amount: parseFloat(val.amount).toFixed(2),
    //     value: val.value,
    // }));
    return data?.metaData?.services?.map((val) => ({
      serviceId: val?.aliasId ?? "-",
      description: val.label,
      quantity: val?.quantity ? val?.quantity : 1,
      unitPrice:
        val.amount && val.isPriceEditable
          ? parseFloat(val.amount).toFixed(2)
          : val?.resourceMeta?.resourceMeta?.originalTotal
          ? parseFloat(val?.resourceMeta?.resourceMeta?.originalTotal).toFixed(
              2
            )
          : parseFloat(val?.resourceMeta?.resourceMeta?.price).toFixed(2),
      discount: val?.resourceMeta?.resourceMeta?.discount
        ? val?.resourceMeta?.resourceMeta?.discount
        : "-",
      // amount: data.discount ? (
      //     parseFloat(val.amount) -
      //     parseFloat(val.amount) * (parseInt(data.discount) / 100)
      // ).toFixed(2) : val.amount,
      // amount: val.amount && val.isPriceEditable ? parseFloat(val.amount).toFixed(2) : val?.resourceMeta?.resourceMeta?.total ? val?.resourceMeta?.resourceMeta?.total : val?.resourceMeta?.resourceMeta?.price ?? '-',
      amount:
        parseFloat(
          returnAmount(
            val?.quantity,
            val?.resourceMeta?.resourceMeta?.discount,
            val.isPriceEditable
              ? val.amount
                ? val.amount
                : 0
              : val?.resourceMeta?.resourceMeta?.originalTotal ??
                  val?.resourceMeta?.resourceMeta?.price
          )
        ).toFixed(2) ?? "-",
      value: val?.value,
    }));
  };
  // const downloadData = async () => {
  //
  //     await printQuote("print");
  // }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickEdit = () => {
    let dataList = {
      categories: data?.categories,
      provider: data?.provider,
      resourceId: data?.resourceId,
      discount: data?.discount,
      metaData: data?.metaData,
      resourceType: data?.metaData?.services?.[0]?.resourceType?.[0],
    };

    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      isLarge: true,
      component: (
        <SendQuote
          isEditForm={true}
          id={enquiry_id}
          data={data}
          dataList={dataList}
          closeDrawer={closeDrawer}
        />
      ),
    });
  };

  const closeDrawer = () => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: false,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <React.Fragment>
        {props.isMarked ? (
          <React.Fragment>
            {props?.showEdit && (
              <EditIcon
                className={classes.icon}
                onClick={() => {
                  handleClickEdit();
                }}
                {...createTestProps("edit quote icon", props?.id)}
                style={{ cursor: "pointer" }}
              />
            )}
          </React.Fragment>
        ) : (
          <></>
        )}
        <VisibilityIcon
          className={classes.icon}
          onClick={() => {
            handleClickOpen();
          }}
          {...createTestProps("view quote icon", props?.id)}
          style={{ cursor: "pointer" }}
        />

        {props?.showDownload && (
          <a
            href={data?.downloadUrl ?? "#"}
            download
            {...createTestProps("download quote icon", props?.id)}
          >
            <GetAppOutlinedIcon
              style={{ cursor: "pointer" }}
              className={classes.icon}
            />
          </a>
        )}
        {/* <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            {console.log(data)}
            <QuoteInvoiceRenderer
              data={data}
              columnsToPrint={newColumnsToPrint}
              createRows={createRows}
              isInvoice={false}
            />
          </DialogContent>
        </Dialog> */}

        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent
            style={{
              width: "100%",
              height: "100%",
              overflow: "auto",
            }}
          >
            <RenderPdf fileUrl={data?.downloadUrl} />
          </DialogContent>
        </Dialog>

        <Grid container style={{ display: "none" }} id="print">
          <QuoteInvoiceRenderer
            data={data}
            columnsToPrint={newColumnsToPrint}
            createRows={createRows}
            isInvoice={false}
          />
        </Grid>
      </React.Fragment>
    </div>
  );
}
