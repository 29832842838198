import React, { Component } from 'react'
import ActivityAndMembersCard from '../../../../components/availabilityActivityComponents/ActivityAndMembersCard'
import { Typography } from '@material-ui/core'

export default class ViewProviderBranches extends Component {

  onEndIconClickHandler = (id, data) => {
    if(id) this.props.onEndIconClickHandler(id,false, data)
  }

  render() {
    const { data } = this.props

    return (
      <div>
        {/* Heading */}
        <Typography variant="h6" style={{ padding: "14px 14px 14px 14px" }}>
          {data?.length ?? 0} Branches
        </Typography>

        {/* Service Coordinator List */}
        {data?.map((item, index) => {
          
          return (
            <ActivityAndMembersCard
              key={index}
              profilePicture={item?.profile_pic}
              title={item?.name}
              name={item?.care_provider?.map(_=>_.name).toString()}
              onEndIconClickHandler={()=>this.onEndIconClickHandler(item?.id, item)}
              useActivityAsSummaryCard={false}
              timing={item?.branchCategories?.map(val=>{return val.label}).join(' | ')}
              status={item?.isActive}
              emailVerified = {item?.emailVerified}
            />
          )
        })}
        {/* <Typography>View all Service Coordinator Screen Needed</Typography> */}
      </div>
    )
  }
}
