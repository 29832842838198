/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-02-10
 * @modify date 2021-06-26
 * @desc Collection of all the message used in the application.
 */
import React from "react";
//Normal messagess
export let Message = {
    //Validation Messages
    requiredMessage: "This field is required",
  
    //List Country Message
    countries_fetching: "Fetching the countries...",
    countries_not_found: "Well! You don't have any country to show, Please Add One",
    countries_error: "Opps! Something went wrong, Try Again",
  
    country_add: "Adding New Country...",
    country_update: "Updating Country Details...",
    country_delete: "Deleting Country...",
  
    country_delete_title: "Are you sure you want to delete country?",
    country_delete_message: "If you delete this country all the disease and forms tagged to this country will be UnMapped.",
    country_delete_btn: "I'm aware delete",
  
    country_disease_fetching: "Fetching mapped diseases...",
    country_disease_not_found: "Well! You are yet to Map a disease to this country. Go back and map a disease.",
    country_disease_form_not_found: "Well! You are yet to Map a form to this disease.",
  
    //Manage locations Messages
    manage_location_fetching: "Fetching the locations level...",
    manage_location_not_found: "Well! You don't have any locations level to show, Please Add one.",
    manage_location_error: "Opps! Something went wrong, Try Again",
  
    //Renderer Message
    form_detail_loading: "Fetching the form details...",
    form_responses_loading: "Fetching the form responses...",
    forms_empty: "Well! You don't have any forms, Please add one.",
  
    //Diseases List Message
    diseases_fetching: "Fetching the diseases...",
    diseases_not_found: "Wel! You don't have any diseas to show, Please Add One.",
    diseases_error: "Opps! Something went wrong, Try Again",
  
    disease_add: "Adding New Disease...",
    disease_update: "Updating Disease Details...",
    disease_delete: "Deleting Disease...",
  
    disease_delete_title: "Are you sure you want to delete Disease?",
    disease_delete_message: "If you delete this Disease all the disease and forms tagged to this Disease will be UnMapped.",
    disease_delete_btn: "I'm aware delete",
  
    //Manage Forms Messages
    manage_form_fetching: "Fetching the forms...",
    manage_form_not_found: "Well! You don't have any forms to show, Please Add one.",
    manage_form_error: "Opps! Something went wrong, Try Again",
  
    manage_form_add: "Addding New Form...",
    manage_form_update: "Updating Form Details...",
    manage_form_delete: "Deleting Form...",
  
    manage_form_delete_title: "Are you sure you want to delete the form?",
    manage_form_delete_message: "If you delete this form all the Sections and Forms tagged to this form will be deleted.",
    manage_form_delete_btn: "I'm aware delete",
  
    //Section Message
    section_add: (sec) => `Addding New ${sec}...`,
    section_update: (sec) => `Updating ${sec} details...`,
    section_delete: (sec) => `Deleting ${sec}...`,
  
    section_delete_title: "Are you sure?",
    section_delete_message: "If you delete this section/form all the forms/questions mapped to this section/form will be removed.",
    section_delete_btn: "I'm aware delete",
  
  
    // managelocation
  
    manageLocation_delete_title: "Are you sure?",
    manageLocation_delete_message: "Are you sure you want to delete the Location?",
    manageLocation_delete_btn: "I'm aware delete",
  
  
    //Notification
    notification_fetching: "Fetching the forms...",
    notification_not_found: "Well! You don't have any forms assigned to you.",
    notification_error: "Opps! Something went wrong, Try Again",
  
    notification_delete_title: "Are you sure?",
    notification_delete_message: "This notification will be deleted permantly, Are you aware?",
    notification_delete_btn: "I'm aware delete",
    notification_delete: "Deleting the notification...",
  
    //Notification Detail
    notification_detail_fetching: "Fetching the notified form...",
    notification_detail_not_found: "Well! You don't have any forms to notified, Please Notify.",
    notification_detail_error: "Opps! Something went wrong, Try Again",
  
    //Verification Detail
    verification_detail_fetching: "Fetching the verification form...",
    verification_detail_not_found: "Well! You don't have any forms to verified, Please Verify.",
    verification_detail_error: "Opps! Something went wrong, Try Again",
  
    //Investigation Detail
    investigation_detail_fetching: "Fetching the investigation form...",
    investigation_detail_not_found: "Well! You don't have any forms to investigated, Please investigate.",
    investigation_detail_error: "Opps! Something went wrong, Try Again",
  
    //Case Registration Detail
    case_registration_detail_fetching: "Fetching the case_registration form...",
    case_registration_detail_not_found: "Well! You don't have any forms to case register, Please regsiter.",
    case_registration_detail_error: "Opps! Something went wrong, Try Again",
  
    //pending,verification,investigation,caseregistration, message
    pending_notification_not_found: 'No Pending Notification found !!!',
    verified_notification_not_found: 'No Verified Notification found !!!',
    archived_notification_not_found: 'No Archived Notification found !!!',
    transfer_notification_not_found: 'No Transfered Notification found !!!',
    reject_notification_not_found: 'No Rejected Notification found !!!',
    no_case_registered: 'No Case Registered!!!',
    no_notification_register_yet: 'No Notification Registered yet!!!',
    no_notification_found_for_investigation: 'No Notification found for Investigation!!!',
    no_notification_investigated_yet: 'No Notification Investigated yet!!!',
  
    //Manage Roles
  
    add_roles: 'Addding New Role...',
    update_role: "Updating Role Details...",
    delete_role: "Deleting Role...",
    manage_role_delete_title: "Are you sure you want to delete the Role?",
    manage_role_not_found: "Well! You don't have any Roles to show, Please Add one.",
    manage_role_fetching: "Fetching the role detail...",
    manage_role_error: "Opps! Something went wrong, Try Again",
  
    // Manage Users
  
    add_users: 'Addding New User...',
    update_user: "Updating User Details...",
    delete_user: "Deleting User...",
    manage_user_delete_title: "Are you sure you want to delete the User?",
    manage_user_not_found: "Well! You don't have any User to show, Please Add one.",
  
    //Archived Reason
    archive_reason: 'Are you sure you want to change to Archive?',
    transfer_reason: 'Are you sure you want to change to transfer?',
    reject_reason: 'Are you sure you want to change to reject?',
  
    //Disease Definition
    dd_success_message: "Disease definition Saved!!!",
    dd_error_message: "Unable to save Disease definition!!!",
  
    //Todo
    add_todo: "Addding New Todo...",
    update_todo: "Updating Todo Details...",
    delete_todo: "Deleting Todo...",
    todo_delete_title: "Are you sure you want to delete the Todo?",
    todo_not_found: "Well! You don't have any Todos to show, Please Add one.",
  
    //Add Level
    add_level: "Addding New Level...",
    add_level_success: "New Level Added Successfully!!!",
    update_level: "Updating Level Details...",
    update_level_success: "Successfully Updated Level Details!!!",
    delete_level: "Deleting Level...",
    level_delete_title: "Are you sure you want to delete the Level?",
  
    //Data Security
    data_security_loading: "Fetching mappings...",
    data_security_error: "Something went wrong, Try Again",
    data_security_not_found: "Opps! 404 - Role not found",
    data_security_saving: "Saving data security mappings...",
    data_security_delete_title: "Are you sure?",
    data_security_delete_message: (mappingDetail) => <>Do you want to delete this mapping? <br /><br />{mappingDetail}</>,
    data_security_delete_btn: "Delete",
  
    // Manage Notification
    Manage_Notification_loading: "Manage Notification Loading...",
    Manage_Notification_error: "Something went wrong, Try Again",
    Manage_Notification_saving: "Saving Manage Notification ...",
    Manage_Notification_delete_title: "Are you sure?",
    Manage_Notification_not_found: "Opps! 404 - Role not found",
    Manage_Notification_delete_btn: "Delete",
  };
  