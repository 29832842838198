import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { genericStyles } from '../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        // boxShadow: `0px 0.5px 2px 1px #e0e0e0d6`,
        padding: 16,
        backgroundColor: "white",
        height: 90,
        borderRadius: 8,
    },
    ...genericStyles.cardWrapperShadows(theme)
}))

export const CountCardLoading = (props) => {

    const classes = useStyles();

    return <div className={`${classes.root} ${classes.cardWrapperShadows}`}>
        <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
                <Skeleton variant="text" width="80px"/>
                <Skeleton variant="text" width="80px" style={{marginTop:8}}/>
            </Grid>      
            <Grid item>
                <Skeleton variant="rect" width="70px" height="40px"/>
            </Grid>     
        </Grid>
    </div>
}
