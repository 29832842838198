import React from 'react';
import { ListForms } from './manageForms';
import { withNavBars } from '../../HOCs';
import { withStyles } from '@material-ui/core';
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
// import { giveMeRoleDataSecurity } from '../formConfigurator/utils';

const styles = ((theme) => ({
    root: {
        paddingTop: 2
    }
}))
class ManageFormsParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: "all",
        }
    }

    handleSortChange = (sort) => {
        this.setState({
            sort
        }, () => {
            this.getForms();
        })
    }

    componentDidMount() {
        this.getForms();
        this.getFormTypes();
    }

    getFormTypes = () => {
        this.props.GET_ALL_FORM_TYPES();
    }

    getForms = (searchKey = "", created_at = false) => {

        // const roleDataSecurity = giveMeRoleDataSecurity(this.props.userData);
        const createdBy = this.props.userData.user_profile_id;

        let sort = {
            sortField: "form_name",
            sortDirection: "ASC",
            diseases: [],
            countryIds: [],
            formIds: [],
        };

        if (created_at) {
            sort.sortField = "updated_at";
            sort.sortDirection = "DESC";
        }

        let formTypeId = "";
        if (this.state.sort !== "all") {
            formTypeId = this.state.sort;
        }

        if (createdBy) {
            sort.created_by = createdBy;
        }

        this.props.GET_ALL_FORMS({
            formName: searchKey.trim(),
            formTypeId,
            ...sort
        });
    };

    render() {

        const { classes, getAllForms } = this.props;

        return <div className={classes.root}>
            <ListForms
                {...getAllForms}
                getForms={this.getForms}
                sort={this.state.sort}
                handleSortChange={this.handleSortChange}
            />
        </div>
    }
}

const mapStateToProps = (state) => ({
    getAllForms: state?.formSlice?.getAllForms,
    userData: state?.authState?.loginAuth?.data ?? {},
});

export default connect(
    mapStateToProps,
    actions
)(withNavBars(
        withStyles(styles)(ManageFormsParent)
));

