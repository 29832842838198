/* eslint-disable eqeqeq */
import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import SubHeader from "../../../../components/subHeader";
import { routes } from "../../../../router/routes";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ListLoader from "../../../../components/listLoader";
import {
  // ListCard,
  InternatlServerError,
  NotFoundSection,
  FilterBuilder,
} from "../../../../components";
import { withAlertAndDialog } from "../../../../HOCs";
import { alertProps, filterTagType, fontStyleClass, productSortDirectionOption,  scrollToTop, subHeaderSortOptionWithValue, user_roles } from "../../../../utils";
import { filterComponent } from "./filterComponent";
import FilterTags from "../../../../components/filters/filterTags";
import { getPersonaId } from "../../../resourceManagement/resources/utils";
import { ProductCatalogue } from "../../../../components/productCatalogue";

const styles = (theme) => ({
  root: {
    padding: 25,
  },
  ...fontStyleClass,
});

const initialState = {
  pageNo: 0,
  pageSize: 15,
  anchorEl: null,
  searchText: "",
  // sortfield: productSortFieldOption[0],
  sortDirec: subHeaderSortOptionWithValue[2],

  // Filter States
  category: [],
  provider: [],
  status: undefined,
  isFilterApplied: false, 

  //popper states
  open: false,
  popperData: []
}

class ListServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      id:"AddService"
    };
  }

  componentDidMount() {
    this.getServices();
    scrollToTop();
    window.addEventListener("scroll", this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  }

  scrolling = () => {
    let { loading, data, error } = this.props?.services;

    let listCount = data?.length ?? 0;
    let totalCount = data?.[0]?.totalCount ?? 0;

    if (loading || error || totalCount === 0 || listCount === 0) {
      return false;
    }

    if (
      Math.round(window.scrollY + window.innerHeight) >=
      document.getElementsByTagName("body")[0].scrollHeight &&
      listCount !== totalCount
    ) {
      this.setState(
        (state, props) => ({
          pageNo: state.pageNo + 15,
        }),
        () => {
          this.getServices();
        }
      );
    }
  };

  getServices = () => {
    let getIdNameType = getPersonaId(this.props?.match?.path)
    let _status = undefined
    if(this.state?.status){
      if(this.state.status.toLowerCase() === "Active".toLowerCase()) _status = true
      if(this.state.status.toLowerCase() === "InActive".toLowerCase()) _status = false
    }
    
    const payload = {
      pageNo: this.state.pageNo,
      pageSize: this.state.pageSize,
      // sortfield: this.state.sortfield === null ? productSortFieldOption[0].value : this.state.sortfield.value,
      filter:{sort: this.state.sortDirec.value,
      category: this.state.category && this.state.category?.map(_ => _.value),
      parentPartnerId: this.state.provider && this.state.provider?.map(_ => _.value).join(','),
      status: _status},
      searchName: this.state.searchText,
      resourceTypeId: getIdNameType.id,
      limit:this.state.pageSize,
      offset:this.state.pageNo,
    }
    
    this.props.GET_ALL_SERVICES(payload);
  };

  handleEdit = (id, versionId) => {
    this.props.history.push(routes.manage_service + "/edit/" + id + "/" + versionId);
  };

  handleDelete = (service) => {
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body: "Do you want to delete the Service : " + service?.name,
      positiveBtn: "Delete",
      negativeBtn: "Cancel",
      onOk: () => this.deleteUser(service),
    });
  };

  deleteUser = (service) => {
    this.props.dialog.setDialog({ open: false });
  };

  handleDeactivate = (service) => {
    const Oldstatus = service?.isactive;
    let status = "Activate";
    if (Oldstatus === "active") {
      status = "Deactivate";
    }
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body: "Do you want to " + status + " Service : " + service?.name,
      positiveBtn: status,
      negativeBtn: "Cancel",
      onOk: () => this.deactiveUser(service),
    });
  };

  deactiveUser = (service) => {
    
    const id = service?.id;
    const status = service?.isactive?.toLowerCase() === "active" ? true : false;

    this.props.dialog.setDialog({ open: false });
    this.props.UPDATE_STATUS_OF_SERVICE({ serviceID: id, status: !status, versionId: service?.versionId });
  };

  navigateToView = (id, versionId) => {
    this.props.history.push(routes.manage_service + "/view/" + id +"/" + versionId);
  };

  storeInputSearchValue = (value) => {
    this.setState(
      { searchText: value, pageNo: 0, pageSize: 15 },
      () => {
        // if (value.length % 3 === 0) {
        this.getServices();
        // }
      }
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Update Status
    const { updateServiceStatusInfo } = nextProps;
    const oldUpdateServiceStatusInfo = this.props.updateServiceStatusInfo;

    //Checking for Update Status
    if (
      oldUpdateServiceStatusInfo?.loading !== updateServiceStatusInfo?.loading
    ) {
      this.props.backDrop.setBackDrop({
        open: updateServiceStatusInfo.loading,
        message: "Updating the service status...",
      });
      if (!updateServiceStatusInfo.loading && !updateServiceStatusInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Service Status updated successfully!",
          severity: alertProps.severity.success,
        });
        this.getServices();
      } else if (updateServiceStatusInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to update service status.",
          severity: alertProps.severity.error,
        });
      }
    }

    return true;
  }

  // -----------------------FILTERS-------------------
  // FILTERS FUNCTION
  // Filter Handlers
  onFilterIconClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: <FilterBuilder
        components={filterComponent(this.state, this.props)}
        applyButtonLabel="Apply"
        loading={this.props?.services?.loading}
        onChange={this.onFilterChange}
        onApplyButtonClicked={this.applyFilter}
        resetFilter={this.resetFilter}
        onCancelClick={this.closeFilter}
        spacing={1}
        id={"AddService"}
        saveButtonId={"saveFilterButtonFromAddService"}
        cancelButtonId={"cancelFilterButtonFromAddService"}
        clearAllButtonId={"clearAllFilterButtonFromAddService"}
      />
    })
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  anyFilterApplied = (state) => {
    
    let { category = [], provider = [], status = "", sortDirec = productSortDirectionOption[0]} = state
    if(category === null) category = initialState.category
    if(provider === null) provider = initialState.provider
    if(JSON.stringify(category) === JSON.stringify(initialState.category) &&
        JSON.stringify(provider) === JSON.stringify(initialState.provider) &&
        status === initialState.status &&
        sortDirec.value === initialState.sortDirec.value 
        // &&  sortfield.value === initialState.sortfield.value
        ){
          
      return false
    } else {
      
      return true
    }
  }


  applyFilter = (state) => {
    
    this.setState({
      ...state,
      pageNo: 0,
      pageSize: 15,
      isFilterApplied: this.anyFilterApplied(state),
    }, () => {
      this.getServices();
      this.closeFilter();
    })
  }

  closeFilter = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    })
  }

  resetFilter = () => {
    this.setState({
      ...initialState
    }, () => {
      this.getServices();
      this.closeFilter();
    })
  }


  // FILTER TAG FUNCTION
  // Filter Tag Utils
  checkIsThereAnyFilter = () => {
    let newState = {
      ...this.state,
      category: this.state.category === null ? initialState.category : this.state.category,
      provider: this.state.provider === null ? initialState.provider : this.state.provider,
    }
    if (
      newState[filterTagType.Category].length === 0 &&
      newState[filterTagType.Provider].length === 0 &&
      newState[filterTagType.Status] === initialState.status &&
      newState[filterTagType.SortDirection].value === initialState.sortDirec.value 
      // &&newState[filterTagType.SortField].value === initialState.sortfield.value
      ) 
      {
      return false
    } else return true
  }

  handleChipDelete = (key, index) => {
    if (key === filterTagType.Category ||
      key === filterTagType.Provider) {
      let newState = this.state
      newState[key].splice(index, 1)
      this.setState({
        ...newState,
        pageNo: 0,
        pageSize: 15,
        isFilterApplied: this.checkIsThereAnyFilter()
      }, () => {
        this.getServices()
      })

    }
    if (key === filterTagType.Status) {
      this.setState({
        status: initialState.status,
        pageNo: 0,
        pageSize: 15,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getServices()
      })
    }
    if (key === filterTagType.SortDirection) {
      this.setState({
        sortDirec: initialState.sortDirec,
        pageNo: 0,
        pageSize: 15,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getServices()
      })
    }
    if (key === filterTagType.SortField) {
      this.setState({
        sortfield: initialState.sortfield,
        pageNo: 0,
        pageSize: 15,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getServices()
      })
    }
  }

  //-----------------------------------------------------------
  handlePopperClose = () => {

    this.setState({ open: false })
  }
  handlePopperOpen = (e, data) => {

    this.setState({ open: e.currentTarget, popperData: data })
  }

  roleBasedAccess = (id) => {
    const data = this.props?.auth_data?.data;
    
    // Return True disable edit button
    // Return False enable edit button

    let disable = null;
    if(Boolean(data?.user_role === user_roles.service_coordinator || data?.user_role === user_roles.help_desk)){
      /*SC can;t edit a Product */
      disable = true
    }

    if (data?.user_role == user_roles.provider) {
      if(data?.user_id == id?.partnerId){
        /*User can edit a Product*/
        disable = false
      }else{
        disable = true
      } 
    }
    if (data?.user_role == user_roles.supervisor) { 
      
        if (data?.partnerId == id?.partnerId) { 
            disable = false 
        } else{
          disable = true 
        } 
    }
    if (data?.user_role == user_roles.super_admin && data?.other_roles?.indexOf("cross-bundling") > -1) { 
      if (id?.care_provider?.[0]?.crossBundle) { 
        // id?.partnerId !== "5cd8f293-1e49-473e-af6b-bf27e986566f"
          /* no one can edit MC User's product except him*/
          disable = false
      }else{
          disable = true 
      }
    }

    if (data?.user_role == user_roles.super_admin && data?.other_roles?.indexOf("cross-bundling") === -1) { 
      if (id?.care_provider?.[0]?.crossBundle) { 
          /* no one can edit MC User's product except him*/
          disable = true
        }else{
          disable = false
      }
    }

    return disable;

  }

  addStyles = (services) => {
    let styledServices = services.map((service) => {
      let orderList = service.orderList.map(list => {
        return {...list, style: {minWidth: 140}}
      })
      return {...service, orderList, isactive: service?.isactive ? "active" : "inactive"}
    })
    return Array.isArray(styledServices) ? styledServices : []
  }

  render() {
    let { loading, data, error } = this.props?.services;
    const { classes } = this.props;
    
    return (
      <div className={classes.root}>
        <Grid
          container
          // direction="column"
          // justify="flex-start"
          // alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <SubHeader
              isbutton={!Boolean(this.props?.auth_data?.data?.user_role === user_roles.service_coordinator || this.props?.auth_data?.data?.user_role === user_roles.help_desk || this.props.auth_data.data.user_role === user_roles.business_admin)}
              placeholder={"Search for Services"}
              button_name={"+ ADD SERVICE"}
              countText={`Total Services (${data?.[0]?.totalCount ?? 0})`}
              entityName={"Service"}
              link={routes.add_service}
              handleSearch={() => this.getServices()}
              handleChangeInputSearch={this.storeInputSearchValue}
              value={this.state.searchText}
              useNewFilterIcon
              placeSearchIconToLeft
              // hideFilter
              // hideSearch

              // Filter Props
              isFilterApplied={this.state.isFilterApplied}
              onFilterIconClicked={this.onFilterIconClicked}
              id={"AddService"}
            />
          </Grid>


          {/* Filter Tag */}
          {
            this.state.isFilterApplied &&
            <Grid item xs={12} style={{ marginTop: 25 }} >
              <FilterTags
                filterData={this.state}
                handleDelete={this.handleChipDelete}
              />
            </Grid>
          }

          {/*TOTAL COUNT  */}
          {/* <Grid item xs={12}>
            <Typography variant="h6" style={{ paddingTop: 25 }} className={classes.mediumFont} >Total Services ({data?.total_count ?? 0})</Typography>
          </Grid> */}

          {loading && (
            <Grid item xs={12}>
              <ListLoader />
            </Grid>
          )}
          {!loading && error && (
            <Grid item xs={12}>
              <InternatlServerError
                message="Unable to fetch services"
                onRetry={this.getServices}
                top="20vh"
              />
            </Grid>
          )}
          {!loading && !error && !data?.length && (
            <Grid item xs={12}>
              <NotFoundSection message="You don't have any services" top="25vh" />
            </Grid>
          )}
          {!error && !loading && data?.length > 0 && (
            <Grid item xs={12}>
              <ProductCatalogue
                id={"service"}
                isLabelNeeded={false}
                catalogues={this.addStyles(data)}
                redirectUrl={routes.manage_service + "/view/"}
                handleItemEdit={this.handleEdit}
                handleItemDeactivate={this.handleDeactivate}
                loading={loading}
                showProvider
              />
              {/* {data.map((list, key) => {
                
                return (
                  <div
                    key={key}
                    style={{ cursor: "pointer", marginBottom: 12 }}
                    onMouseLeave={(e) => {
                      if (e?.relatedTarget?.id === "popper") {
                        return false
                      } else {
                        this.handlePopperClose()
                      }
                    }}
                  >
                    <ListCard
                      data={list}
                      open={this.state.open}
                      handlePopperClose={this.handlePopperClose}
                      handlePopperOpen={this.handlePopperOpen}
                      popperData={this.state.popperData}
                      isMoreBtn={!this.roleBasedAccess(list)}
                      // isMoreBtn={true}
                      handleEdit={this.handleEdit}
                      handleDelete={this.handleDelete}
                      handleDeactivate={this.handleDeactivate}
                      navigateToView={this.navigateToView}
                      // isCountLabelAPrice
                      setPriceTrailingDecimal
                    />
                  </div>
                );
              })} */}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  services: state?.serviceState?.allServices,
  updateServiceStatusInfo: state?.serviceState?.updateServiceStatusInfo,
  auth_data: state?.authState?.loginAuth,
});

export default connect(
  mapStateToProps,
  actions
)(withAlertAndDialog(withRouter(withStyles(styles)(ListServices))));
