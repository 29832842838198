import React from "react";
import { DateRangePickerCustom } from "./datePicker";

export const DateRangePicker = (props) => {
  
  return (
    <DateRangePickerCustom
      variant={props.variant}
      label={props.label}
      value={props.value}
      isHidePastDate={props?.isHidePastDate}
      isHideFutureDate={props?.isHideFutureDate}
      handleDateRange={props.handleDateRange}
      {...props}
    />
  );
};
