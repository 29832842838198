import * as React from "react"

export const UnderLineIcon = (props) => {

    const {
        width = "16.401",
        height = "21.333",
        color = "#6f6f6f"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 16.401 21.333"
        >
            <path id="icons8-underline" d="M9.807,4.978A.82.82,0,0,0,9,5.81V15.1a7.927,7.927,0,0,0,15.854,0V5.81a.82.82,0,1,0-1.64,0V15.1a6.287,6.287,0,0,1-12.574,0V5.81a.82.82,0,0,0-.833-.832ZM9.82,24.671a.82.82,0,1,0,0,1.64H24.58a.82.82,0,1,0,0-1.64Z" transform="translate(-9 -4.978)" 
                fill={color}
            />
        </svg>
    );
};