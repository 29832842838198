/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-02-03 18:31:26
 * @modify date 2022-02-03 18:31:26
 * @desc Dialog for deleting a promotional banner from the list
 */

import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const CustomButton = withStyles((theme) => {
  return {
    root: {
      padding: "10px 32px",
      textTransform: "unset",
      [theme.breakpoints.down("xs")]: {
        padding: "10.5px 26px",
      },
      width: "90px",
    },
    label: {
      lineHeight: "19px",
    },
  };
})(Button);

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "18px",
    fontWeight: 500,
    marginBottom: 24,
  },
  description: {
    fontSize: "16px",
    fontWeight: 400,
    marginBottom: 24,
  },
  buttonContainer: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function DeletePromotionalBannerDialog(props) {
  const classes = useStyles();
  const {
    title = "",
    description = "",
    onCancel = () => {},
    onDelete = () => {},
  } = props;

  return (
    <div style={{ textAlign: "center" }}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.description} color="textSecondary">
        {description}
      </Typography>
      <div className={classes.buttonContainer}>
        <CustomButton variant="outlined" color="primary" onClick={onCancel}>
          No
        </CustomButton>
        <CustomButton variant="contained" color="primary" onClick={onDelete}>
          Yes
        </CustomButton>
      </div>
    </div>
  );
}

export default DeletePromotionalBannerDialog;
