import React from 'react';
import { 
  Button,
  Grid, 
  IconButton, 
  Typography, 
  withStyles 
} from '@material-ui/core';
import ActivityAndMembersCard from '../availabilityActivityComponents/ActivityAndMembersCard'
import ProfileCardActivitySummary from '../../components/availabilityActivityComponents/activitySummaryCards/ProfileCardActivitySummary'
import CheckInCheckOutCard from '../../components/availabilityActivityComponents/activitySummaryCards/checkInCheckOutCard'
import SummaryTextCard from '../../components/availabilityActivityComponents/activitySummaryCards/summaryTextCard'
import moment from 'moment';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { actions } from 'tanyacare-middleware'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { LoadingSection } from '../../components/integerateHelper/loading';
import { InternatlServerError } from '../../components/integerateHelper/500';
import { colorCode } from './ActivityList';
import withAlelrtAndDialog from '../../HOCs/withAlelrtAndDialog';
import { alertProps, activityStatus } from '../../utils';
import { loadScript } from "../../components/map/loadScriptFile";

const styles = (theme) => ({
    icon: {
        width: 20,
        height: 20
    },
    cancelButton: {
        boxShadow: "unset !important"
    }
})

class ActivitySummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientLocation: null,
      addressLoading: false
    };
  }

  handleScriptLoad = (latitude, longitude) => {
    // Google Location - Geocoder API
    const geocoder = new window.google.maps.Geocoder();
    const latlng = {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude)
    };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
        //   results[0].formatted_address;
          this.setState({
            addressLoading: false,
            clientLocation: results[0].formatted_address
          })  
        } else {
          this.setState({
            clientLocation: null
          })
        }
      } else {
        this.setState({
          clientLocation: null
        })
      }
    });
  };

  componentDidMount = () => {
    this.getSummaryDetail();
  }

  getSummaryDetail = async() => {
    const activityID = this.props.data;
    await this.props.GET_ACTIVITY_SUMMARY({ activityID: activityID });
    const { data } = this.props?.summaryDetail;
    if(data?.client_info?.latitude && data?.client_info?.longitude){
      this.getLocationFromLatlong()
    }
  }

  getLocationFromLatlong = () => {
    this.loadGoogleApi()
  }

  loadGoogleApi = () => {
    const { data } = this.props?.summaryDetail;
    const latitude = data?.client_info?.latitude;
    const longitude = data?.client_info?.longitude;
    if (
      latitude &&
      longitude &&
      latitude > -90 &&
      latitude < 90 &&
      longitude > -180 &&
      longitude < 180
    ) {
      this.setState({
        addressLoading: true
      }, loadScript(
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyBqeACJgp12OLU6EkHeUtFgya2naH1LhrU&callback=initMap&libraries=&v=weekly`,
        () => this.handleScriptLoad(latitude, longitude)
      ))
      
    }
  };

  closeDrawer = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    });
  };

  handleActivityDelete(activityId) {
    this.props.dialog.setDialog({
      open: true,
      title: "Confirmation",
      body: "Are you sure, you want to cancel this Activity?",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => {
        this.props.dialog.setDialog({ open: false });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: true,
          message: "Deleting Activity..."
        });
        this.props.DELETE_ACTIVITY({ activityId: activityId });
      }
    });
  }

  shouldComponentUpdate(nextProps) {
    // Delete Activity
    const { deleteActivity } = nextProps;
    const oldDeleteActivity = this.props.deleteActivity;

    if (oldDeleteActivity?.loading !== deleteActivity?.loading) {
      if (!deleteActivity.loading && !deleteActivity.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Activity Deleted Successfully!",
          severity: alertProps.severity.success
        });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false
        });
        // TODO:RELOAD and CLOSE DRAWER
        this.props.reload();
        this.closeDrawer();
      } else if (deleteActivity.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Delete Activity.",
          severity: alertProps.severity.error
        });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false
        });
      }
    }

    return true;
  }

  render() {

    const { hideServiceProfessional, hideClient, classes } = this.props;

    const { data, loading, error } = this.props?.summaryDetail;
    
    return (
      <>
        {loading && <LoadingSection message="Loading activity summary..." />}
        {!loading && error && (
          <InternatlServerError
            message="Something went wrong, Try Again!"
            onRetry={this.getSummaryDetail}
          />
        )}
        {!loading && !error && !data && (
          <InternatlServerError
            message="Something went wrong, Try Again!"
            onRetry={this.getSummaryDetail}
          />
        )}
        {!loading && !error && data && (
          <>
            <Grid container>
              <Grid
                item
                container
                style={{ padding: "12px 12px 12px 0px", background: "white" }}
              >
                <Grid item>
                  <IconButton onClick={this.props.onBackBtnClicked}>
                    {/* <ChevronLeft /> */}
                  </IconButton>
                </Grid>
                <Grid item xs>
                  <Typography variant="h6" style={{ marginTop: 8 }}>
                    Activity Summary
                  </Typography>
                </Grid>
                {data?.activity_status !== activityStatus.completed &&
                  data?.activity_status !== activityStatus.inProgress &&
                  data?.activity_status !== activityStatus.closed&& (
                    <>
                      <Grid
                        item
                        style={{
                          marginLeft: 20,
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            this.props.onEditBtnClicked(1, this.props.data);
                          }}
                        >
                          <CreateOutlinedIcon
                            htmlColor="#9297a5"
                            classes={{ root: classes.icon }}
                          />
                        </IconButton>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end"
                        }}
                      >
                        {/* onClick={() => this.props.onEditBtnClicked(this.props.data)} */}
                        <Button
                          onClick={() =>
                            this.handleActivityDelete(data?.activity_id)
                          }
                          style={{ color: "#f27d35" }}
                          className={classes.cancelButton}
                          // startIcon={<DeleteOutlineIcon htmlColor="#f27d35" classes={{ root: classes.icon }} />}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </>
                  )}
              </Grid>

              <ActivityAndMembersCard
                hideProfilePicture
                title={data?.activity_name}
                // date={moment(data?.from_time_stamp).format('dddd, MMMM D')}
                timing={`${moment(data?.from_time_stamp).format(
                  "Do MMM hh:mm A"
                )} - ${moment(data?.to_time_stamp).format(
                  "Do MMM hh:mm A"
                )}`}
                hideIsActive
                activityStatus={data?.activity_type?.label} //activity_type will be a object with {value, label}
                activityStatusColor="#28a9c1"
                currentStatus={data?.activity_status}
                currentStatusColor={colorCode[data?.activity_status]}
                // useActivityAsSummaryCard
                hideEndIcon
                padding={2}
              />

              <SummaryTextCard
                description={data?.activity_description}
                padding={2}
              />

              {!hideClient && (
                <ProfileCardActivitySummary
                  profilePicture={data?.client_info?.profile_pic}
                  name={data?.client_info?.client_name}
                  gender={data?.client_info?.gender}
                  age={data?.client_info?.age}
                  address={this.state.clientLocation}
                  //videoCallUrl
                  meetingUrl={data?.mode === "VIDEO_CALL" ? data?.videoCallUrl : false}
                  addressLoading={this.state.addressLoading}
                  padding={2}
                  hideQuickIcons
                  hideAddressSection={false}
                />
              )}

              {!hideServiceProfessional && (
                <div style={{ display: "block", padding: 16 }}>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                  >
                    SERVICE PROFESSIONAL:
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    {data?.service_professional_info?.label}
                  </Typography>
                </div>
              )}

              <Grid item xs={12}>
                <CheckInCheckOutCard
                  leftText="Check - In"
                  leftSubText={
                    data?.check_in
                      ? moment(data?.check_in).format("hh:mm A")
                      : " - "
                  }
                  rightText="Check - Out"
                  rightSubText={
                    data?.check_out
                      ? moment(data?.check_out).format("hh:mm A")
                      : " - "
                  }
                  padding={2}
                />
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
    summaryDetail: state?.clientActivity?.activitySummary,
    deleteActivity: state?.activity_app?.delete_activity ?? { data: [], loading: false, error: false}
})


export default connect(mapStateToProps, actions)(withAlelrtAndDialog(withRouter(withStyles(styles)(ActivitySummary))));