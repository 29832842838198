/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-01-27 20:47:14
 * @modify date 2022-03-01 13:11:33
 */
import PropTypes from "prop-types";
import React from "react";
import { SvgIcon } from "@material-ui/core";

function ClassicCalendarIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.6 0H7v4.8H4.6V0Zm12 0H19v4.8h-2.4V0Z" fill={props.color} />
      <path
        d="M20.2 2.4H3.4A2.407 2.407 0 0 0 1 4.8v16.8A2.407 2.407 0 0 0 3.4 24h16.8a2.407 2.407 0 0 0 2.4-2.4V4.8a2.407 2.407 0 0 0-2.4-2.4Zm0 19.2H3.4v-12h16.8v12Zm0-14.4H3.4V4.8h16.8v2.4Z"
        fill={props.color}
      />
    </SvgIcon>
  );
}

ClassicCalendarIcon.propTypes = {
  color: PropTypes.string,
};

ClassicCalendarIcon.defaultProps = {
  color: "#4A90E2",
};

export default ClassicCalendarIcon;
