import React from 'react';
import RatingsModule from '../../../../components/ratingsComp'

import { actions } from 'tanyacare-middleware'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { NotFoundSection, InternatlServerError, LoadingSection } from '../../../../components';

const styles = (theme) => ({

})

class Feedbacks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 1,
            pageNo: 0,
            pageSize: 10,
            serviceProfessionalId: this.props?.match?.params?.id
        }
    }

    componentDidMount() {
        this.getClientRatings()
        window.addEventListener("scroll", this.scrolling);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrolling);
    }

    scrolling = () => {
        
        let { data, loading, error } = this.props?.feedbacks;

        if ((window.scrollY + window.innerHeight >= document.getElementsByTagName('body')[0].scrollHeight)
            && !loading
            && !error
            && data?.list?.length % 10 === 0) {
            this.setState((state, props) => ({
                pageNo: state.pageNo++,
            })
                , () => {
                    this.getClientRatings();
                });
        }
    }

    getClientRatings = () => {
        this.props.GET_ALL_SERVICE_PROFESSIONAL_FEEDBACKS({ ...this.state })
    }

    onFilterChange = (type) => {
        this.setState({
            type
        }, () => {
            this.getClientRatings()
        })
    }

    render() {
        let { data, loading, error } = this.props?.feedbacks;

        return (<>
            {loading && <LoadingSection message="Loading Service Professional feedbacks..." />}
            {!loading && error && <InternatlServerError onRetry={this.getClientRatings} />}
            {!loading && !error && !data && <NotFoundSection message='No Feedbacks found' />}
            {!loading && !error && data && <div>
                <RatingsModule {...data} onFilterChange={this.onFilterChange} filter={this.state.type} title="Feedback(s)" />
            </div>}
        </>)
    }
}

const mapStateToProps = (state) => ({
    feedbacks: state?.service_professional_monitor?.feedbacks
})

export default connect(mapStateToProps, actions)(withRouter(withStyles(styles)(Feedbacks)));