import React, { useState, useEffect } from 'react';
import { DurationPicker } from '../../../components/v2';
import Grid from "@material-ui/core/Grid";
import { useDispatch,useSelector } from 'react-redux';
import { actions } from 'tanyacare-middleware';
import { LoadingSection } from "../../../components";

const {
    GET_TRACKING_TIME_SETUP,
    UPDATE_TRACKING_TIME_SETUP
} = actions;

export const ConfigurationPanel = (props) => {

    const dispatch = useDispatch();

    const getTrackingTimeList = useSelector(state=>state?.configuration?.getTrackingTimeList)

    const [state, setState] = useState({
        trackingTime: '30'
    })

    const [loading, setLoading] = useState(null);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [])

    const fetchData = async () => {
        setLoading('screen')
        const getTrackingTimeList = await dispatch(GET_TRACKING_TIME_SETUP({}));
        onChangeState('trackingTime',getTrackingTimeList?.payload?.data?.[0]?.duration)
        setLoading(null)

    }

    const onChangeState = (key, value) => {
        setState({ ...state, [key]: value })
    }

    const changetrackingTime = async (key,value) => {

        setLoading(key);

        await dispatch(UPDATE_TRACKING_TIME_SETUP({
            id:getTrackingTimeList?.data?.[0]?.id,
            duration:value
        }))

        onChangeState(key, value);

        setLoading(null);
    }

    return (
        <div>
            {
                loading === "screen" ? (
                    <LoadingSection message="Loading Configuration ..." />
                ) : (
                    <>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                        >
                            <Grid item xs={12} md={6} lg={4}>
                                <DurationPicker
                                    id={"durationPickerFromConfiguration"}
                                    label={"Tracking Time"}
                                    value={state?.trackingTime}
                                    handleOnSave={(value) => {
                                        changetrackingTime('trackingTime',value);
                                    }}
                                    isEdit
                                    disabled
                                    isReadOnly={loading === "trackingTime"}
                                />
                            </Grid>
                        </Grid>
                    </>
                )
            }
        </div>
    )
}