import React from "react";

import { components } from "react-select";
import AsyncSelect from "react-select";
import { useTheme } from '@material-ui/core/styles';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};
// const Menu = props => {
//   const optionSelectedLength = props.getValue().length || 0;
//   return (
//     <components.Menu {...props}>
//       {optionSelectedLength < 5 ? (
//         props.children
//       ) : (
//         <div style={{ margin: 15, textAlign:"center" }}>Max limit achieved</div>
//       )}
//     </components.Menu>
//   );
// };
// const ValueContainer = ({ children, getValue, ...props }) => {
//   let maxToShow = 3;
//   var length = getValue().length;
//   let displayChips = React.Children.toArray(children).slice(0, maxToShow);
//   let shouldBadgeShow = length > maxToShow;
//   let displayLength = length - maxToShow;

//   return (
//     <components.ValueContainer {...props}>
//       {!props.selectProps.inputValue && displayChips}
//       <div className="root">
//         {shouldBadgeShow &&
//           `+ ${displayLength} item${length != 1 ? "s" : ""} selected`}
//       </div>
//     </components.ValueContainer>
//   );
// };
export const AsyncSelectComponent = ({overflow, responsive, ...props}) => {
  const theme = useTheme();
  return (
    <AsyncSelect
      tabSelectsValue={false}
      placeholder={props.placeholder ? props.placeholder : ""}
      isMulti={props.isMulti ? true : false}
      // hideSelectedOptions={props?.value?.length < 3 ? true : false}
      hideSelectedOptions={true}
      // closeMenuOnSelect={props.isMulti ? false : true}
      closeMenuOnSelect={true}
      inputId={props.id}
      isSearchable
      defaultValue={props.defaultValue}
      onChange={props.handleOnChange}
      value={props.value}
      isClearable={props.isNotClearable ? false : true}
      // id={props.id}
      isDisabled={props.isDisabled}
      styles={{
        //   option: (base, value) => {
        //     
        //     return (props?.value?.length < 3 ? { ...base } : { display: 'none'});
        // },
        // input:() => ({marginTop: 1, marginBottom: 1}),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        control: (base) => ({
          ...base,
          border: props.error
            ? "1.8px solid rgba(188, 45, 79, 1)"
            : `1.8px solid ${props.borderColor ? props.borderColor : "#DFDFE2"}`,
          // This line disable the blue border
          boxShadow: 0,
          borderRadius: `${props.borderRadius ? props.borderRadius : '8px'} !important`,
          // minHeight: !props.hideCustomValueContainerHeight ? props.height ? props.height : 53 : 'unset',
          maxHeight:responsive ? "32px" : "62.4px",
          "& .css-g1d714-ValueContainer":{
            overflowX: "hidden",
            overflowY:'auto',
            maxHeight:"32px"
          },
          overflow: overflow ? overflow : "scroll",
          "&:hover": {
            border: `1.8px solid ${props.hoverBorderColor ? props.hoverBorderColor : theme.palette.ternary.main} !important`,
            // minHeight: !props.hideCustomValueContainerHeight ? props.height ? props.height : 53 : 'unset',
            maxHeight:responsive ? "32px" : "62.4px",
            borderRadius: `${props.borderRadius ? props.borderRadius : '8px'} !important`,
            
          },
        }),
      }}
      cacheOptions
      defaultOptions={props.options}
      loadOptions={props.options}
      isLoading={props.isLoading}
      isOptionDisabled={(option) => option.isdisabled}
      components={{ DropdownIndicator }}
      {...props}
      id={null}
    />
  );
};
