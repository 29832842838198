/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-01-27 18:38:31
 * @modify date 2022-01-28 13:27:59
 * @desc Component for Promotional Banner View Page - Info Item
 */

import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { IconComponent } from "../../../../../assets";
import { textClampStyleGenerator } from "../../../../../utils";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: "grid",
    gridTemplateColumns: props.hideIcon ? "1fr" : "24px 1fr",
    gridGap: "16px",
  }),
  label: {
    fontSize: "14px",
    fontWeight: 400,
    marginBottom: "12px",
  },
  value: {
    fontSize: "16px",
    fontWeight: 400,
    ...textClampStyleGenerator(2),
  },
}));

function InfoItem(props) {
  const classes = useStyles(props);
  const { label, value, iconName, hideIcon, valueComponent } = props;
  return (
    <div className={classes.root}>
      {!hideIcon && (
        <div>
          <IconComponent name={iconName} />
        </div>
      )}
      <div>
        <Typography color="textSecondary" className={classes.label}>
          {label}
        </Typography>
        {React.isValidElement(valueComponent) ? (
          valueComponent
        ) : (
          <Typography color="textPrimary" className={classes.value}>
            {value}
          </Typography>
        )}
      </div>
    </div>
  );
}

InfoItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  iconName: PropTypes.string,
  hideIcon: PropTypes.bool,
  valueComponent: PropTypes.element,
};

InfoItem.defaultProps = {
  label: "-",
  value: "-",
  iconName: "application",
  hideIcon: false,
};

export default InfoItem;
