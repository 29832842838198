import React from 'react'
import {
    Grid, Typography, List,
    ListItem,
    ListItemText,
    Button,
    IconButton,
    ListItemSecondaryAction,
    Divider,
    withStyles
} from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const style = ((theme) => ({
    listWrapper: {
        width: "100%",
        display: "flex"
    },
    addIdButtonText: {
        color: theme.palette.ternary.main,
        fontWeight: 600
    },
    gutter: {
        padding: "16px 16px"
    },
    gutterTop: {
        padding: "16px 16px 0px"
    },
    gutterMiddle: {
        padding: "0px 16px"
    },
    gutterBottom: {
        padding: "0px 16px 16px"
    },
    listItemSecondaryAction: {
        paddingRight: 90
    }
}))

class ViewAllCards extends React.Component {

    state = {
        email: []
    }

    componentDidMount() {
        this.setState({
            email: [...this.props.items]
        })
    }


    callBackOnSaveEmail = (data, isremove) => {
        if (isremove) {
            let isUpdateData = this.state.email?.filter(
                (val) => val.value !== data?.value
            );

            this.setState({ email: isUpdateData }, () => {
                this.props.onRemove(data, true)
            });
        }
    };

    render() {
        const { classes } = this.props
        const { email } = this.state
        const { onAdd, onEdit } = this.props
        return (
            <div>
                <Grid container>
                    <Grid item container xs={12} className={classes.gutter}>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>Email Address</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', justifyContent: "flex-end" }}>
                            <Button style={{boxShadow: "none"}}>
                                <Typography id={this.props?.id?.add_drawer_email_id} onClick={onAdd} variant={"body2"} className={classes.addIdButtonText}>+ ADD EMAIL ADDRESS</Typography>
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider light />
                    </Grid>

                    {email?.map(item => {
                        return (
                            <Grid item xs={12} >
                                <div className={classes.listWrapper}>
                                    <List dense={true} style={{ width: "100%" }} >
                                        <ListItem divider classes={{ secondaryAction: classes.listItemSecondaryAction}}>
                                            <ListItemText
                                                primary={item?.label ? item?.label : null}
                                                secondary={item?.emailId ? item?.emailId : "NA"}
                                            />
                                            <ListItemSecondaryAction>
                                                <div style={{ display: "flex", gap: 16 }}>
                                                    <IconButton id={this.props?.id?.edit_drawer_email_icon_id} onClick={() => onEdit(item)} edge="end" aria-label="edit" size={"small"}>
                                                        <EditOutlinedIcon fontSize={"small"} />
                                                    </IconButton>
                                                    <IconButton id={this.props?.id?.delete_drawer_email_icon_id} onClick={() => {this.callBackOnSaveEmail(item, true)}} edge="end" aria-label="delete" size={"small"}>
                                                        <DeleteOutlinedIcon fontSize={"small"} />
                                                    </IconButton>
                                                </div>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        )
    }
}

export default withStyles(style)(ViewAllCards)
