import React, { useState, useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  Select,
  Input,
  DatePickers,
  CustomCheckBox,
} from "../../../../components/v2";
import makeStyles from "@material-ui/styles/makeStyles";
import Divider from "@material-ui/core/Divider";
import {
  SubscriptionDetails,
  UploadImage,
  ImagePreview,
  WarningDialog,
} from "./components";
import { InfoIcon } from "../../../../assets";
import { DialogContext, AlertContext } from "../../../../contexts";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "tanyacare-middleware";
import { errorMessage } from "../../../../utils/errorMessages";
import { alertProps, upload_file, timeStamp } from "../../../../utils";
import { createTestProps } from "../../../../utils/common";
import { FILE_URI } from "../../../../utils/constants";
import { LoadingSection } from "../../../../components";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../router/routes";
import isEqual from "react-fast-compare";
import { Prompt } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  gridItemContainer: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 4px 25px #31313114",
    border: "1px solid #3B3F5B29",
    borderRadius: 8,
    padding: `${theme.spacing(1)}px `,
    alignContent: "baseline",
  },
  gridItem: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  gridParentItemContainer: {
    padding: theme.spacing(1 / 2),
  },
  divider: {
    backgroundColor: "#F0F0F0",
    width: "98%",
    marginBottom: theme.spacing(2),
    marginInline: "auto",
  },
  actionBtn: {
    padding: "10.25px",
    textTransform: "capitalize",
    borderRadius: 8,
  },
  outlinedBtn: {
    color: "#FF4D4A",
    borderColor: "#D8D8D8",
    minWidth: 99,
    marginRight: theme.spacing(2),
  },
  actionGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  hasAnotherBanner: {
    "& .MuiPaper-root": {
      [theme.breakpoints.down("xs")]: {
        minWidth: "90%",
      },
    },
  },
}));

export const AddEditPB = (props) => {
  const classes = useStyles(props);

  const dialog = useContext(DialogContext);
  const alert = useContext(AlertContext);
  const history = useHistory();

  const dispatch = useDispatch();

  const [initialState, setInitialState] = useState({});
  const [state, setState] = useState({
    applicationType: null,
    bannerType: null,
    bannerName: "",
    subscription: null,
    effectiveDate: null,
    endDate: null,
    priorityOrder: null,
    expiryDate: false,
    uploadImage: null,
    loading: null,
    subscriptionDetails: null,
  });

  const [error, setError] = React.useState({});

  const getApplicationType = useSelector(
    (state) => state?.promotionalMaster?.applicationType
  );
  const getBannerType = useSelector(
    (state) => state?.promotionalMaster?.bannerType
  );
  const getSubscriptionsAliasId = useSelector(
    (state) => state?.promotionalMaster?.subscriptionsAliasId
  );
  const getPriorityOrder = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [props?.id]);

  const fetchData = async () => {
    onChangeState("loading", "screen");

    const appType = await dispatch(actions?.GET_APPLICATION_TYPE({}));
    const bannerType = await dispatch(actions?.GET_BANNER_TYPE({}));
    await dispatch(actions?.GET_SUBSCRIPTIONS_ALIAS_ID({}));

    let updateState = {};

    if (props?.id) {
      let getInfo = await dispatch(
        actions?.GET_PROMOTIONAL_BANNER_INFO({ bannerId: props?.id })
      );
      if (!getInfo?.payload?.error) {
        const { data } = getInfo.payload;

        updateState = {
          ...state,
          promoId: data?.aliasId,
          id: data?.id,
          applicationType: data?.applicationType,
          bannerType: data?.bannerType,
          bannerName: data?.bannerName,
          subscription: {
            label: data?.subscriptionDetail?.aliasId,
            value: data?.subscriptionDetail?.id,
            versionId: data?.subscriptionDetail?.versionId,
          },
          effectiveDate: new Date(data?.effectiveFrom),
          endDate: data?.effectiveTo ? new Date(data?.effectiveTo) : null,
          priorityOrder: getPriorityOrder.find(
            (_) => _?.value === data?.priority
          ),
          expiryDate: !Boolean(data?.effectiveTo),
          uploadImage: {
            name: `${data?.bannerImageInfo?.name}.${data?.bannerImageInfo?.type}`,
            src: `${FILE_URI}/${data?.bannerImage}`,
          },
          subscriptionDetails: returnSubscriptionDetails(
            data?.subscriptionDetail,
            data?.subscriptionDetail?.type,
            data?.subscriptionDetail?.id,
            data?.subscriptionDetail?.versionId,
            true
          ),
          loading: null,
        };
      }
    } else {
      updateState = {
        ...state,
        applicationType: appType?.payload?.data?.[0] ?? null,
        bannerType: bannerType?.payload?.data?.[0] ?? null,
        loading: null,
      };
    }
    setState(updateState);
    setInitialState(updateState);
  };

  const onChangeState = (key, value) => {
    if (key === "expiryDate") {
      setState({
        ...state,
        [key]: value,
        endDate: null,
      });
      setError({ ...error, endDate: false });
      return;
    }
    setState({
      ...state,
      [key]: value,
    });
  };

  const checkValidation = () => {
    let toCheck = [
      "applicationType",
      "bannerType",
      "bannerName",
      "subscription",
      "effectiveDate",
      "endDate",
      "priorityOrder",
      "uploadImage",
    ];

    const errorState = {};

    toCheck.forEach((key) => {
      if (state["expiryDate"] && key === "endDate") return;
      else if (
        !state[key] ||
        (state[key]?.constructor === Object &&
          !Object?.keys(state[key])?.length) ||
        (state[key]?.constructor === Date && !state[key]) ||
        (state[key]?.constructor === Array && !state[key]?.length) ||
        (state[key]?.constructor === String && !state[key]?.trim()?.length)
      ) {
        errorState[key] = true;
      }
    });
    setError(errorState);
    return Boolean(Object.keys(errorState)?.length);
  };

  const handleSubscription = async (item) => {
    onChangeState("loading", "SubscriptionDetails");

    let info;
    const { type, value, versionId } = item || {};

    if (type) {
      switch (type) {
        case "Service":
          info = await dispatch(
            actions.GET_SERVICES_INFO({ id: value, versionId })
          );
          break;
        case "Package":
          info = await dispatch(
            actions.GET_PACKAGES_INFO({ id: value, versionId, isDetail: true })
          );
          break;
        case "Product":
          info = await dispatch(
            actions.GET_PRODUCTS_INFO({ id: value, versionId, isDetail: true })
          );
          break;
        default:
          return null;
      }
    }
    setState({
      ...state,
      subscription: item,
      loading: null,
      subscriptionDetails: type
        ? type === "Service"
          ? returnSubscriptionDetails(
              info?.payload?.[0],
              type,
              value,
              versionId,
              false
            )
          : returnSubscriptionDetails(
              info?.payload,
              type,
              value,
              versionId,
              false
            )
        : null,
    });
  };

  const addPromotionalBanner = async () => {
    const validate = checkValidation();

    if (!validate) {
      onChangeState("loading", "addButton");

      //file uploading api
      let fileUpload;
      let fileCheck = state?.uploadImage?.src?.split(":")?.[0] === "https";

      if (!fileCheck) {
        let file = upload_file(state?.uploadImage?.src);
        let filename = state?.uploadImage?.name?.split(".");
        fileUpload = await dispatch(
          actions.FILE_UPLOAD({
            file: file?.withoutType,
            fileName: filename?.[0]
              .toString()
              ?.concat(timeStamp() + "." + filename?.[1]),
            isbase64: true,
          })
        );

        if (fileUpload?.payload?.error) {
          onChangeState("loading", null);
          alert.setSnack({
            open: true,
            horizontal: alertProps.horizontal.center,
            vertical: alertProps.vertical.top,
            msg: "Unable to upload file",
            severity: alertProps.severity.error,
          });
          return;
        }
      }

      const returnBannerImage = () => {
        if (!fileCheck) return fileUpload?.payload?.data?.fileid;
        let splitFileId = state?.uploadImage?.src?.split("/");
        return splitFileId?.[splitFileId?.length - 1];
      };

      //add promotional banner api
      let payload = {
        applicationType: state?.applicationType?.value,
        bannerType: state?.bannerType?.value,
        bannerName: state?.bannerName,
        bannerImage: returnBannerImage(),
        subscriptionId: state?.subscription?.value,
        effectiveFrom: state?.effectiveDate,
        effectiveTo: state?.endDate,
        priority: state?.priorityOrder?.value,
        bannerImageInfo: {
          name: state?.uploadImage?.name?.split(".")?.[0],
          type: state?.uploadImage?.name?.split(".")?.[1],
        },
      };

      if (state?.id) {
        payload["id"] = state?.id;
      }

      let data = await dispatch(actions.UPDATE_PROMOTIONAL_BANNER(payload));
      onChangeState("loading", null);

      if (data?.payload?.error) {
        if (data?.payload?.hasAnotherBanner) {
          dialog.setDialog({
            ...dialog,
            open: true,
            body: (
              <WarningDialog
                icon={<InfoIcon />}
                title="Choose Different Priority Order or Effective Date Period"
                description="Looks like, there is another promotional banner activated on the same time period. Please choose accordingly."
                buttonName="Ok,Got it"
                buttonAction={() => dialog.setDialog({ open: false })}
              />
            ),
            className: {
              Dialog: classes.hasAnotherBanner,
            },
            dontShowButtons: true,
            hideAllButtons: true,
          });
        } else {
          alert.setSnack({
            open: true,
            horizontal: alertProps.horizontal.center,
            vertical: alertProps.vertical.top,
            msg: data?.payload?.message
              ? data?.payload?.message
              : `Unable to ${state?.id ? "update" : "add"} promotional banner`,
            severity: alertProps.severity.error,
          });
        }
      } else {
        alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `Promotional Banner ${
            state?.id ? "updated" : "added"
          } successfully`,
          severity: alertProps.severity.success,
        });
        setInitialState({ ...state });
        redirectToListingPB();
      }
    }
  };

  const returnSubscriptionDetails = (data, type, id, versionId, isEdit) => {
    const discountedPrice = isEdit
      ? data?.discount
        ? data?.discount
        : "-"
      : data?.price
      ? data?.price
      : "-";
    return {
      profile_pic: data?.profile_pic ?? "-",
      name: data?.resourceName ? data?.resourceName : data?.name ?? "-",
      title: data?.resourceName ? data?.resourceName : data?.name ?? "-",
      amount: discountedPrice,
      currency: data?.currency,
      id,
      versionId,
      link: `${routes.product_management}/${
        type === "Service"
          ? "services"
          : type === "Package"
          ? "packages"
          : "products"
      }/view/${id}/${versionId}`,
      ...data,
    };
  };

  const cancelPromotionalBanner = () => {
    redirectToListingPB();
  };

  const redirectToListingPB = () => {
    history.push({
      pathname: routes.manage_promotional_banner,
    });
  };

  return (
    <div className={classes.root}>
      {state?.loading === "screen" ? (
        <LoadingSection message="Loading Promotional Banner..." />
      ) : (
        <>
          <Grid container>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={8}
              className={classes.gridParentItemContainer}
            >
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                className={classes.gridItemContainer}
              >
                {state?.promoId && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    className={classes.gridItem}
                  >
                    <Input
                      // value={state?.promoId}
                      // handleChange={(value) => null}
                      isRequired
                      label="Promo ID"
                      placeHolder={state?.promoId}
                      maxLength={30}
                      disabled={true}
                      {...createTestProps("promo id promotional banner")}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={state?.promoId ? 4 : 6}
                  className={classes.gridItem}
                >
                  <Select
                    value={state?.applicationType}
                    handleChange={(value) =>
                      onChangeState("applicationType", value)
                    }
                    options={getApplicationType?.data ?? []}
                    label="Application Type"
                    isRequired
                    error={error?.applicationType}
                    helperText={errorMessage?.requiredMessage}
                    isReadOnly={props?.isReadOnly}
                    {...createTestProps("application type promotional banner")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={state?.promoId ? 4 : 6}
                  className={classes.gridItem}
                >
                  <Select
                    value={state?.bannerType}
                    handleChange={(value) => onChangeState("bannerType", value)}
                    options={getBannerType?.data ?? []}
                    label="Banner Type"
                    isRequired
                    error={error?.bannerType}
                    helperText={errorMessage?.requiredMessage}
                    isReadOnly={props?.isReadOnly}
                    {...createTestProps("banner type promotional banner")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
                  <Input
                    value={state?.bannerName}
                    handleChange={(value) => onChangeState("bannerName", value)}
                    isRequired
                    label="Banner Name (30 Characters only)"
                    placeHolder="Enter Banner Name"
                    maxLength={30}
                    error={error?.bannerName}
                    helperText={errorMessage?.requiredMessage}
                    isReadOnly={props?.isReadOnly}
                    {...createTestProps("banner name promotional banner")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
                  <Select
                    value={state?.subscription}
                    handleChange={(value) => handleSubscription(value)}
                    options={Array.isArray(getSubscriptionsAliasId?.data) ? getSubscriptionsAliasId?.data?.filter(_=>_?.label) : []}
                    label="Choose Subscription"
                    isRequired
                    error={error?.subscription}
                    helperText={errorMessage?.requiredMessage}
                    isReadOnly={props?.isReadOnly}
                    {...createTestProps("choose subscription promotional banner")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} className={classes.gridItem}>
                  <DatePickers
                    value={state?.effectiveDate}
                    handleChange={(value) =>
                      onChangeState("effectiveDate", value)
                    }
                    label="Effective Date"
                    isRequired
                    placeHolder="Select Date"
                    error={error?.effectiveDate}
                    helperText={errorMessage?.requiredMessage}
                    disablePast={true}
                    maxDate={state?.endDate && new Date(state?.endDate)}
                    isReadOnly={props?.isReadOnly}
                    isStartOfDay
                    {...createTestProps("effective date promotional banner")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} className={classes.gridItem}>
                  <DatePickers
                    value={state?.endDate}
                    handleChange={(value) => onChangeState("endDate", value)}
                    label="End Date"
                    isRequired
                    placeHolder="Select Date"
                    disabled={state?.expiryDate}
                    error={error?.endDate}
                    helperText={errorMessage?.requiredMessage}
                    disablePast={!!state?.effectiveDate ? false : true}
                    minDate={new Date(state?.effectiveDate)}
                    isReadOnly={props?.isReadOnly}
                    isEndOfDay
                    {...createTestProps("end date promotional banner")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} className={classes.gridItem}>
                  <Select
                    value={state?.priorityOrder}
                    handleChange={(value) =>
                      onChangeState("priorityOrder", value)
                    }
                    options={getPriorityOrder}
                    label="Priority Order"
                    isRequired
                    error={error?.priorityOrder}
                    helperText={errorMessage?.requiredMessage}
                    isReadOnly={props?.isReadOnly}
                    {...createTestProps("priority order promotional banner")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  className={classes.gridItem}
                  style={{ margin: 0 }}
                >
                  <CustomCheckBox
                    checked={state?.expiryDate}
                    label="No Expiry Date"
                    handleChange={(value) => onChangeState("expiryDate", value)}
                    isReadOnly={props?.isReadOnly}
                    {...createTestProps("expiry promotional banner")}
                  />
                </Grid>
                <Divider className={classes.divider} />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={
                    state?.loading === "SubscriptionDetails" ||
                    state?.subscription?.value
                      ? 6
                      : 12
                  }
                  className={classes.gridItem}
                >
                  <SubscriptionDetails
                    description="Choose Subscription ID/Name to view the details"
                    data={
                      state?.subscription?.value
                        ? {
                            ...state?.subscriptionDetails,
                          }
                        : null
                    }
                    loading={
                      state?.loading === "SubscriptionDetails" ? true : false
                    }
                    label="Subscription Details"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={4}
              className={classes.gridParentItemContainer}
            >
              <Grid container className={classes.gridItemContainer}>
                <Grid item xs={12} className={classes.gridItem}>
                  <UploadImage
                    uploadData={state?.uploadImage}
                    handleChange={(value) =>
                      onChangeState("uploadImage", value)
                    }
                    handleDelete={() => onChangeState("uploadImage", null)}
                    error={error?.uploadImage}
                    helperText={errorMessage?.requiredMessage}
                    isReadOnly={props?.isReadOnly}
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                  <ImagePreview
                    preview={state?.uploadImage?.src}
                    handleChange={(value) =>
                      onChangeState("uploadImage", {
                        ...state.uploadImage,
                        src: value,
                      })
                    }
                    isReadOnly={props?.isReadOnly}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.gridParentItemContainer}>
            <Grid
              item
              xs={12}
              className={`${classes.gridItemContainer} ${classes.actionGrid}`}
            >
              <Button
                variant="outlined"
                className={`${classes.actionBtn} ${classes.outlinedBtn}`}
                onClick={cancelPromotionalBanner}
                disabled={props?.isReadOnly}
                {...createTestProps("cancel promotional banner")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.actionBtn}
                style={{ minWidth: 136 }}
                onClick={() => addPromotionalBanner()}
                disabled={state?.loading === "addButton" || props?.isReadOnly}
                {...createTestProps("add promotional banner")}
              >
                {state?.loading === "addButton"
                  ? state?.id
                    ? "Updating..."
                    : "Adding..."
                  : state?.id
                  ? "Update"
                  : "Add"}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <Prompt
        when={!isEqual(state, initialState)}
        message={JSON.stringify({
          title: "Confirmation",
          positiveButton: {
            name: "Yes,Cancel",
          },
          negativeButton: { name: "No" },
        })}
      />
    </div>
  );
};
