import { Grid, withStyles, LinearProgress } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import CalenderHeader from "./header/CalenderHeader";
import DayContainer from "./dayWise/dayContainer";
import DayCalenderArea from "./dayWise/dayCalenderArea";
import WeekContainer from "./weekWise/weekContainer";
import MonthlyCalender from "./monthWise/monthlyCalender";
import { InternatlServerError } from "./../integerateHelper";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  container: {
    width: "100%",
    position: "relative",
  },
  header: {
    padding: "8px 16px 8px 16px",
    // position: 'static',
  },
  daysSection: {
    borderTop: `0.5px solid rgba(0, 0, 0, 0.23)`,
    borderBottom: `0.5px solid rgba(0, 0, 0, 0.23)`,
    position: "sticky",
    top: 60,
    zIndex: 4,
    backgroundColor: "white",
  },
  sticky: {
    position: "sticky",
    top: 60,
    zIndex: 4,
    backgroundColor: "white",
  },
});

class Calender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, variant, loading, error, onRetry } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          className={classes.container}
          container
          direction="row"
          alignItems="center"
          justify="stretch"
        >
          
            <Grid
              item
              xs={12}
              className={`${classes.header} ${classes.sticky} ${classes.daysSection}`}
            >
              {/* Header */}
              <CalenderHeader hideFilters={this.props.hideFilters} {...this.props} />
              {variant === "day" && (
                <>
                  <DayContainer {...this.props} />
                {loading && <LinearProgress color="primary" />}
                {error && <InternatlServerError top={16} onRetry={onRetry} />}
                </>
              )}
            </Grid>
          {/* )} */}
          {variant === "day" && (
            <>
             {/* { <Grid item xs={12} >
                <Grid item xs={12} className={classes.header}>
                  <CalenderHeader hideFilters={this.props.hideFilters} {...this.props} />
                </Grid>
                
                <DayContainer {...this.props} />
                {loading && <LinearProgress color="primary" />}
                {error && <InternatlServerError top={16} onRetry={onRetry} />}
              </Grid>} */}
              
              <Grid item xs={12}>
              
                {/* Row of a calender */}
                <DayCalenderArea {...this.props} />
              </Grid>
            </>
          )}
          {variant === "week" && (
            <>
              {/* Weekwise calendar container  */}
              <Grid item xs={12} style={{overflow: 'scroll'}}>
                {loading && <LinearProgress color="primary" />}
                {error && <InternatlServerError top={16} onRetry={onRetry} />}
                <WeekContainer {...this.props} />
              </Grid>
            </>
          )}
          {variant === "month" && (
            <Grid item xs={12} style={{overflow: 'scroll'}}>
              {/* Row of a calender */}
              {loading && <LinearProgress color="primary" />}
              {error && <InternatlServerError top={16} onRetry={onRetry} />}
              <MonthlyCalender {...this.props} />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

Calender.propTypes = {
  selectedDate: PropTypes.instanceOf(moment),
  currentDate: PropTypes.instanceOf(moment),
  onDateSelected: PropTypes.func,

  categoryList: PropTypes.array,
  category: PropTypes.object,
  onCategorySelected: PropTypes.func,

  serviceProfessionalList: PropTypes.array,
  serviceProfessional: PropTypes.object,
  onServieProfessionalSelected: PropTypes.func,

  onFilterClicked: PropTypes.func,
  isFilterApplied: PropTypes.bool,

  onNextClicked: PropTypes.func,
  onPrevClicked: PropTypes.func,

  variant: PropTypes.oneOf(["week", "day", "month"]),
  onVariantChange: PropTypes.func,

  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onRetry: PropTypes.func,

  onActivityClicked: PropTypes.func,
  onAvailabilityClicked: PropTypes.func,
  onUnAvailabilityClicked: PropTypes.func,

  calendarType: PropTypes.oneOf(["schedules", "availability"]),
};

Calender.defaultProps = {
  selectedDate: moment(),
  currentDate: moment(),
  variant: "day",
};

export default withStyles(styles)(Calender);
