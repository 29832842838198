import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, withStyles, Grid } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import Day from './day';
import CalenderCard from './calenderCard';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
    },
    avatarSelected: {
        backgroundColor: theme.palette.ternary.main,
        color: 'white',
        textAlign: "center",
    },
    avatarUnSelected: {
        backgroundColor: theme.palette.secondary.light,
        color: 'black',
        textAlign: "center",
    },
    tabs: {
        width: '100%',
    },
    calenderItem: {
    },
    table: {
        backgroundColor: 'white',

        [theme.breakpoints.down("lg")]: {
            height: 'calc(100vh - 230px)',
        },
        [theme.breakpoints.up("lg")]: {
            height: 'calc(100vh - 166px)'
        },
        border: `0.2px solid ${theme.palette.secondary.light}`,
    },
    tableCell: {
        minWidth: 154,
        height: 150,
        border: `0.2px solid ${theme.palette.secondary.light}`,
        fontWeight: "bold",
        verticalAlign: 'top',
        padding: 0,
        paddingRight: 20
    },
    tableHeaderCell: {
        border: `unset`,
        backgroundColor: 'white',
        position: 'relative',
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
    },
    otherMonth: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.light
    },
    nextBtn: {
        position: 'absolute',
        right: 1,
        top: 30
    },
    prevBtn: {
        position: 'absolute',
        left: 1,
        top: 30
    }
})

class WeekContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClicked = (e, value) => {
        this.props.onDateSelected(moment(value, 'DD/MM/YYYY'))
    }

    isSelected = (date) => {

        let {
            currentDate: selectedDate
        } = this.props;

        selectedDate = moment(selectedDate.format('DD/MM/YYYY'), 'DD/MM/YYYY');
        date = moment(date.format('DD/MM/YYYY'), 'DD/MM/YYYY');

        if (selectedDate.isSame(date)) {
            return true
        } else {
            return false
        }
    }

    giveMeTheWeed = () => {

        const {
            selectedDate
        } = this.props;

        let weekDays = [];
        const lastDayOfWeek = moment(selectedDate).endOf('week').add(1, 'days');

        for (let i = 6; i >= 0; i--) {

            const day = moment(lastDayOfWeek).subtract(i, 'days');

            weekDays.push({
                value: day.format('DD'),
                label: day.format('ddd'),
                date: day,
            })
        }

        return weekDays;
    }

    onNextButtonClicked = () => {

        const {
            selectedDate
        } = this.props;

        this.props.onDateSelected(moment(selectedDate).add(1, 'week'));
    }

    onPrevButtonClicked = () => {

        const {
            selectedDate
        } = this.props;

        this.props.onDateSelected(moment(selectedDate).subtract(1, 'week'));
    }


    giveMeCalenderCard = (key) => {
        const { data } = this.props;

        if (!data || data.length === 0 || !data[key]) {
            return <></>;
        }

        let { events, availability, unavailability } = data[key];
        // let { fromTime, toTime } = data[key];
        const {
            classes,
            onActivityClicked,
            onAvailabilityClicked,
            onUnAvailabilityClicked,
        } = this.props;

        let activity = <></>;
        let avilable = <></>;
        let unavailable = <></>;

        if (events?.length) {
            activity = <Grid item className={classes.pointerCursor} onClick={() => onActivityClicked(events)}>
                <CalenderCard
                    variant="activities"
                    title={`${events.length ? events.length : 0} IN ACTIVITY`}
                    // subTitle={`${moment(fromTime).format('hh:mm A')} - ${moment(toTime).endOf('day').format('hh:mm A')}`}
                    cards={events} />
            </Grid>
        }

        if (availability?.length) {
            avilable = <Grid item className={classes.pointerCursor} onClick={() => onAvailabilityClicked(availability)}>
                <CalenderCard
                    variant="available"
                    title={`${availability.length ? availability.length : 0} AVAILABLE`}
                    // subTitle={`${moment(fromTime).format('hh:mm A')} - ${moment(toTime).endOf('day').format('hh:mm A')}`}
                    cards={availability} />
            </Grid>
        }

        if (unavailability?.length) {
            unavailable = <Grid item className={classes.pointerCursor} onClick={() => onUnAvailabilityClicked(unavailability)}>
                <CalenderCard
                    variant="unavailable"
                    title={`${unavailability.length ? unavailability.length : 0} UNAVAILABLE`}
                    // subTitle={`${moment(fromTime).format('hh:mm A')} - ${moment(toTime).endOf('day').format('hh:mm A')}`}
                    cards={unavailability} />
            </Grid>
        }

        return <Grid container direction="column" justify={"flex-start"} alignItems={"flex-start"} spacing={3}>
            {activity}
            {avilable}
            {unavailable}
        </Grid>

    }

    render() {

        const {
            classes,
        } = this.props;

        const rows = {};

        const headers = this.giveMeTheWeed();

        return <div className={classes.root}>
            <Table
                className={classes.table}
                stickyHeader={true}>
                <TableHead>
                    <TableRow >
                        {headers.map((header, key) => {
                            return <TableCell id={header.value} key={key} className={classes.tableHeaderCell} align="center">
                                {key === 0 && <IconButton className={classes.prevBtn} onClick={this.onPrevButtonClicked}>
                                    <ChevronLeft />
                                </IconButton>}
                                {key === 6 && <IconButton className={classes.nextBtn} onClick={this.onNextButtonClicked}>
                                    <ChevronRight />
                                </IconButton>}
                                <Day {...header} isSelected={this.isSelected(header.date)} />
                            </TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {headers.map((header, key) => (<TableCell key={key} className={`${classes.tableCell} ${rows.isOtherMonth ? classes.otherMonth : ''}`}>
                            {this.giveMeCalenderCard(key)}
                        </TableCell>)
                        )}
                    </TableRow>
                </TableBody>
            </Table>

        </div>
    }
}

WeekContainer.propTypes = {
    currentDate: PropTypes.instanceOf(moment()),
    selectedDate: PropTypes.instanceOf(moment()),
    onDateSelected: PropTypes.func
}

export default withStyles(styles)(WeekContainer);
