import * as React from "react"

export const InfoIcon = (props) => {
 let width = props.width ? props.width : "56.707";
 let height = props.height ? props.height : "56.707";
 let color = props.color ? props.color : "#ffa811";
 return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 56.707 56.707">
      <path id="icons8-info_1_" data-name="icons8-info (1)" d="M31.354,3A28.354,28.354,0,1,0,59.707,31.354,28.355,28.355,0,0,0,31.354,3Zm2.363,42.53H28.991V28.991h4.726ZM31.354,23.084A3.544,3.544,0,1,1,34.9,19.54,3.545,3.545,0,0,1,31.354,23.084Z" transform="translate(-3 -3)" 
        fill={color}
        />
    </svg>
  );
};
