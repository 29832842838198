import React from 'react'
import { withStyles, Grid, Typography, makeStyles } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    borderRadius: `8px !important`,
    textTransform: 'capitalize'
    // width: `100px !important`
  },
  sectionName: {
    // marginTop: 20,
    textTransform: 'uppercase'
  },
  toggleButtonGroup: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  }
}))

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    '&:nth-child(2)': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    marginTop: theme.spacing(1.5)
  },
}))(ToggleButtonGroup);


const StyledButton = withStyles((theme) => ({
  root: {
    color: `${theme.palette.text.primary} !important`,
    border: `1.6px solid rgba(0, 0, 0, 0.12) !important`,
    [theme.breakpoints.down('xs')]: {
      padding: 4
    }
  },
  selected: {
    color: `white !important`,
    background: `${theme.palette.ternary.main} !important`,
  },

}))(ToggleButton)





export function ToggleGroupField(props) {
  const classes = useStyles()
  let { toggleTypes } = props
  let { selectedType, handleFilterTypeChange } = props
  //Data
  // const { issue_description, issue_status } = this.props.data
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary" className={classes.sectionName}>Type</Typography>
      </Grid>
      <Grid item container xs={12}>
        {/* value={issue_status && !(!!selected) ? issue_status.toLowerCase() : selected} */}
        <StyledToggleButtonGroup value={selectedType} exclusive onChange={handleFilterTypeChange} className={classes.toggleButtonGroup}>
          {toggleTypes?.map((button, index) => {
            return <StyledButton key={index} className={classes.buttonStyle} value={button.value}>
              {button.label}
            </StyledButton>
          })}
        </StyledToggleButtonGroup>
      </Grid>
    </Grid >
  )
}

export default ToggleGroupField

ToggleGroupField.propTypes = {
  /**Sets the Buttons for the button group. It should be an array of objects where each object should have a ```value``` and ```label``` key. 
  * ```
  * [
  *   {
  *     label: String,
  *     value: String
  *   }
  * ]
  * ```
  */
  toggleTypes: PropTypes.array,

}