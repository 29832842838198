import * as React from "react"

export const CloseRoundedIcon = (props) => {
 let width = props.width ? props.width : "32";
 let height = props.height ? props.height : "32";
 let borderColor = props?.borderColor ? props?.borderColor : '#707070'
 let backgroundColor = props?.backgroundColor ? props?.backgroundColor : '#fff'
 let color = props.color ? props.color : "#ff4d4a";
 return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
      <g id="Group_93432" data-name="Group 93432" transform="translate(-902 -20)">
        <g
            id="Ellipse_128886" 
            data-name="Ellipse 128886" 
            transform="translate(902 20)"
            fill={backgroundColor}
            stroke={borderColor}
            stroke-width="1"
            opacity="0.32"
        >
      <circle cx={width/2} cy={width/2} r={width/2} stroke="none"/>
      <circle cx={width/2} cy={width/2} r={width/2-0.5} fill="none"/>
    </g>
    <path id="icons8-multiply_7_" data-name="icons8-multiply (7)" d="M5.228,4.293l-.935.935L8.454,9.389,4.293,13.551l.935.935,4.161-4.161,4.161,4.161.935-.935L10.325,9.389l4.161-4.161-.935-.935L9.389,8.454Z" 
        transform="translate(908.767 26.767)"
        fill={color}
    />
  </g>
    </svg>
  );
};

