import React from "react";
import List from "./cardList";
import ListLoading from "./cardSkeleton";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Fade from "@material-ui/core/Fade";

class CardList extends React.Component {
  constructor(props) {
    super();
    this.state = {
      anchorEl: null,
      categoryAnchorEl: null,
      openpopper: false,
      popperData: []
    };
  }
  navigateToDetail = (url, val) => {
    const count = val?.orderList?.filter(
      (v) => v.name === this.props.countName
    )?.[0];

    this.props.history.push(url, {
      header: [
        {
          id: val?.id,
          name: val?.name,
          isactive: val?.isactive,
          emailverified: val?.emailVerified,
          category: val?.category,
          profile_pic: val?.profile_pic,
        },
      ],
      footer: [
        {
          name: count?.count + " " + count?.name,
        },
      ],
    });
    return true
  };

  handleCards = () => {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  };
  handleCategoryPopoverOpen = (event, data) => {

    if (event) {
      this.setState({ categoryAnchorEl: event.currentTarget, popperData: data });
      event.stopPropagation()
    }
    return true
  };

  handleCategoryPopoverClose = () => {

    this.setState({ categoryAnchorEl: false });
    return true
  };
  render() {
    const { list, componentOrder, sizes, Url, hideDeactivateEditButtons } = this.props;
    return (
      < >
        {(<>
          {list?.map((val, i) => {
            if (val) {
              if (Url) {
                console.log(val.emailVerified)
                return (
                  <div
                    key={"card" + i}
                    onClick={() => this.navigateToDetail(Url + val.id, val)}
                    style={{ textDecoration: "none", cursor: "pointer" }}
                    onMouseLeave={(e) => {
                      if (e?.relatedTarget?.id === "popper") {
                        return false
                      } else {
                        this.handleCategoryPopoverClose()
                      }
                    }}
                  >
                    <List
                    isShowGenderAge={this.props?.isShowGenderAge}
                      isShowRole={this.props.isShowRole}
                      isShowGender={this.props.isShowGender}
                      isdontshowactivebtn={this.props.isdontshowactivebtn}
                      isShowAge={this.props.isShowAge}
                      isShowVerified={this.props.isShowVerified}
                      list={val}
                      isShowEditButton={this.props.isShowEditButton}
                      isShowParent={this.props.isShowParent}
                      componentOrder={componentOrder}
                      hideDeactivateEditButtons={hideDeactivateEditButtons}
                      sizes={sizes}
                      propsList={this.props}
                      link={this.props.link}
                      link2={this.props?.link2}
                      providerId={this.props?.providerId}
                      onDeactivateClicked={this.props.onDeactivateClicked}

                      handleResend={this.props.handleResend}
                      // isSendingReinvite={this.props.isSendingReinvite}

                      isDeactivating={this.props.isDeactivating}
                      hideQuickSummary={this.props.hideQuickSummary}
                      hideQuickSummaryLabel={this.props.hideQuickSummaryLabel}
                      showCareGiver={this.props.showCareGiver}
                      handleCategoryPopoverOpen={this.handleCategoryPopoverOpen}
                      handleCategoryPopoverClose={this.handleCategoryPopoverClose}
                      open={this.state.categoryAnchorEl}
                      popperData={this.state.popperData}
                      categoryAnchorEl={this.state.categoryAnchorEl}
                      openpopper={this.state.openpopper}
                      displayProvider={this.props.displayProvider}
                      isSalutationNeeded={this.props.isSalutationNeeded}
                      client_listing_info={this.props?.client_listing_info}
                      onMapResourceClicked={this.props?.onMapResourceClicked}
                      mapResourceLabel={this.props?.mapResourceLabel}
                      showThirdBtn={this.props?.showThirdBtn}
                      thirdBtnTitle={this.props?.thirdBtnTitle}
                      onThirdBtnClicked={this.props.onThirdBtnClicked}
                      isShowActivate={this.props?.isShowActivate}
                      deactivateActivateButtonId={this.props?.deactivateActivateButtonId}
                      editButtonId={this.props?.editButtonId}
                      addBranchButtonId={this.props?.addBranchButtonId}
                      resendButtonId={this.props?.resendButtonId}
                      ThirdButtonId={this.props?.ThirdButtonId}
                    />
                  </div>
                );
              } else {
                console.log("INSIDE ELSE")
                return (
                  <div key={i} onMouseLeave={(e) => {
                    if (e?.relatedTarget?.id === "popper") {
                      return false
                    } else {
                      this.handleCategoryPopoverClose()
                    }
                  }} >
                    <List
                    isShowGenderAge={this.props?.isShowGenderAge}
                      isShowRole={this.props.isShowRole}
                      isShowEditButton={this.props.isShowEditButton}
                      isdontshowactivebtn={this.props.isdontshowactivebtn}
                      isShowGender={this.props.isShowGender}
                      isShowAge={this.props.isShowAge}
                      isShowParent={this.props.isShowParent}
                      list={val}
                      componentOrder={componentOrder}
                      hideDeactivateEditButtons={hideDeactivateEditButtons}
                      sizes={sizes}
                      propsList={this.props}
                      link={this.props.link}
                      link2={this.props?.link2}
                      providerId={this.props?.providerId}
                      onDeactivateClicked={this.props.onDeactivateClicked}
                      isDeactivating={this.props.isDeactivating}

                      handleResend={this.props.handleResend}
                      // isSendingReinvite={this.props.isSendingReinvite}

                      handleAddServiceProfessional={
                        this.props.handleAddServiceProfessional
                      }
                      hideQuickSummaryLabel={this.props.hideQuickSummaryLabel}
                      showCareGiver={this.props.showCareGiver}
                      handleCategoryPopoverOpen={this.handleCategoryPopoverOpen}
                      handleCategoryPopoverClose={this.handleCategoryPopoverClose}
                      open={this.state.categoryAnchorEl}
                      popperData={this.state.popperData}
                      categoryAnchorEl={this.state.categoryAnchorEl}
                      openpopper={this.state.openpopper}
                      isSalutationNeeded={this.props.isSalutationNeeded}
                      client_listing_info={this.props?.client_listing_info}
                      onMapResourceClicked={this.props?.onMapResourceClicked}
                      mapResourceLabel={this.props?.mapResourceLabel}
                      showThirdBtn={this.props?.showThirdBtn}
                      canShowButton={this.props?.canShowButton}
                      thirdBtnTitle={this.props?.thirdBtnTitle}
                      onThirdBtnClicked={this.props.onThirdBtnClicked}
                      isShowActivate={this.props?.isShowActivate}
                      deactivateActivateButtonId={this.props?.deactivateActivateButtonId}
                      editButtonId={this.props?.editButtonId}
                      addBranchButtonId={this.props?.addBranchButtonId}
                      resendButtonId={this.props?.resendButtonId}
                      ThirdButtonId={this.props?.ThirdButtonId}
                    />
                  </div>
                );
              }
            }
            return true
          })}
        </>
        )}
        {this.props.loading && (
          <Fade in={this.props.loading ? true : false} timeout="auto">
            <>
              {this.handleCards().map((val, i) => (
                <div key={i} style={{ cursor: "progress" }}>
                  <ListLoading loading={this.props.loading} />
                </div>
              ))}
            </>
          </Fade>
        )}
      </>
    );
  }
}

export default withRouter(CardList);

CardList.propTypes = {
  /*
   * To hide quick summary portion of the card
   */
  hideQuickSummary: PropTypes.bool,
  /*
   * To hide Quick Summary Label
   */
  hideQuickSummaryLabel: PropTypes.bool,
  /**To hide the edit details and deactivate button in component order 2 */
  hideDeactivateEditButtons: PropTypes.bool
};
