/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-01-27 20:47:14
 * @modify date 2022-01-27 20:47:14
 */
import PropTypes from "prop-types";
import React from "react";
import { SvgIcon } from "@material-ui/core";

function CalendarIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="icons8-calendar icon">
        <path
          data-name="icons8-calendar"
          d="M6.085 3.266a3.08 3.08 0 0 0-3.069 3.069v10.862a3.08 3.08 0 0 0 3.069 3.069h10.862a3.08 3.08 0 0 0 3.069-3.069V6.335a3.08 3.08 0 0 0-3.069-3.069Zm0 1.417h10.862A1.643 1.643 0 0 1 18.6 6.336v.708H4.433v-.709a1.643 1.643 0 0 1 1.652-1.652ZM4.433 8.46h14.166v8.736a1.643 1.643 0 0 1-1.653 1.653H6.085a1.643 1.643 0 0 1-1.653-1.653Zm3.069 1.889a1.181 1.181 0 1 0 1.181 1.181 1.181 1.181 0 0 0-1.181-1.181Zm4.014 0a1.181 1.181 0 1 0 1.181 1.181 1.181 1.181 0 0 0-1.181-1.181Zm4.014 0a1.181 1.181 0 1 0 1.181 1.181 1.181 1.181 0 0 0-1.181-1.181Zm-8.028 4.25a1.181 1.181 0 1 0 1.181 1.181 1.181 1.181 0 0 0-1.181-1.181Zm4.014 0a1.181 1.181 0 1 0 1.181 1.181 1.181 1.181 0 0 0-1.181-1.181Z"
          fill={props.color}
        />
      </g>
    </SvgIcon>
  );
}

CalendarIcon.propTypes = {
  color: PropTypes.string,
};

CalendarIcon.defaultProps = {
  color: "#0063e7",
};

export default CalendarIcon;
