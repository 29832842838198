import React from "react";
import { CreateQuote } from './createQuote';
class GenerateQuote extends React.Component {

  render() {
    const { data } = this.props;
    return (
      <CreateQuote
        isEditForm={this.props.isEditForm}
        data={data}
        id={this.props?.id}
        reloadListing={this.props?.reloadListing}
        dataList={this.props?.dataList}
        closeDrawer={this.props?.closeDrawer}
      />
    );
  }
}

export default GenerateQuote