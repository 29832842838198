import React from "react";
import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

export const FilterIcon = (props) => {
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
      htmlColor={props.color}
      style={props.style}
    >
      <g
        id="filter_1_"
        data-name="filter (1)"
        transform="rotate(90 8.781 8.782)"
      >
        <path
          id="Path_264"
          d="M22.818 2.533H11.036a3.714 3.714 0 0 0-7.043 0H1.182a1.182 1.182 0 1 0 0 2.365h2.812a3.714 3.714 0 0 0 7.043 0h11.781a1.182 1.182 0 1 0 0-2.365zM7.515 5.066a1.351 1.351 0 1 1 1.351-1.351 1.352 1.352 0 0 1-1.351 1.351z"
          class="cls-1"
          data-name="Path 264"
        />
        <path
          id="Path_265"
          d="M22.817 2.533h-2.811a3.714 3.714 0 0 0-7.043 0H1.182a1.182 1.182 0 1 0 0 2.365h11.781a3.714 3.714 0 0 0 7.043 0h2.811a1.182 1.182 0 1 0 0-2.365zm-6.332 2.533a1.351 1.351 0 1 1 1.351-1.351 1.352 1.352 0 0 1-1.351 1.351z"
          class="cls-1"
          data-name="Path 265"
          transform="translate(0 10.132)"
        />
      </g>
    </SvgIcon>
  );
};

FilterIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};
