import React from "react";
import PropTypes from "prop-types";

import { SvgIcon } from "@material-ui/core";

function FullscreenIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.972 0a1 1 0 0 0-.108.009h-6.533a1 1 0 0 0 0 1.999h4.248L2.008 20.581v-4.25a1 1 0 0 0-1.999 0v6.535a1 1 0 0 0 1.126 1.125H7.67a1 1 0 0 0 0-2H3.42l18.572-18.57v4.25a1 1 0 1 0 1.999 0V1.135A1 1 0 0 0 22.972 0ZM1.01.01a.98.98 0 0 0-.357.067l-.03.012a1 1 0 0 0-.148.079l-.016.01a1 1 0 0 0-.147.12 1 1 0 0 0-.118.146l-.01.013A.996.996 0 0 0 .044.8v.023a1 1 0 0 0-.017.183v6.666a1 1 0 1 0 2 0V3.42l7.636 7.638 1.413-1.413-7.654-7.638h4.25a1 1 0 0 0 0-1.999H1.008Zm13.345 12.934-1.412 1.412 7.637 7.638h-4.248a1 1 0 0 0 0 2h6.665a1.003 1.003 0 0 0 .35-.067l.031-.012c.049-.021.096-.046.14-.074l.03-.017a1 1 0 0 0 .14-.117l.01-.008c.041-.042.079-.087.112-.134l.02-.03c.029-.043.053-.089.074-.136l.014-.03c.02-.052.035-.106.046-.16v-.025A.99.99 0 0 0 23.98 23v-6.67a1 1 0 0 0-1.998 0v4.25l-7.627-7.637Z"
        fill={props.color}
      />
    </SvgIcon>
  );
}

FullscreenIcon.propTypes = {
  color: PropTypes.string,
};

FullscreenIcon.defaultProps = {
  color: "#0063e7",
};

export default FullscreenIcon;
