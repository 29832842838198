import PropTypes from "prop-types";
import React from "react";
import {
  Paper,
  Grid,
  Typography,
  Button,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { CustomAvatar } from "../niceComponents";
import { styled } from "@material-ui/core/styles";
// import CreateIcon from "@material-ui/icons/Create";
// import DeleteIcon from "@material-ui/icons/Delete";
import { CreateIconV2, DeleteIconV2 } from "../../assets";
import { getAgeText } from "../../utils/common";
import FamilyMemberIcon from "../../assets/familyMember.svg"

const StyledPaper = styled(Paper)(({ emergencyContact }) => ({
  margin: "0px 8px",
  border: "1px solid #E0E0E0",
  borderRadius: 8,
  padding: "8px 8px 8px 0px",
  position: "relative",
  "&:after": {
    ...(emergencyContact && {
      content: `"Emergency Contact"`,
      position: "absolute",
      top: 0,
      right: 0,
      backgroundColor: "#F67280",
      color: "white",
      borderRadius: 8,
      borderBottomRightRadius: 0,
      padding: "0px 8px",
      fontSize: "0.75rem",
    }),
  },
}));

// const StyledPaper = withStyles((theme) => ({
//   root: {

//
//   },
// }))(Paper);

const useStyle = makeStyles((theme) => ({
  viewMoreButton: {
    textTransform: "capitalize",
  },
}));

function FamilyMemberCard(props) {
  const classes = useStyle();
  const {
    data,
    onViewMoreClicked,
    editMode,
    onEdit,
    onDelete,
  } = props;
  debugger;
  const getGenderAgeText = (data) => {
    if (data?.gender?.label || data?.dob) {
      return `${data?.gender?.label ? data?.gender?.label : ""}${
        data?.gender?.label && data?.dob ? ", " : ""
      }${data?.dob ? getAgeText(data?.dob) : ""}`;
    }
    return "";
  };

  return (
    <StyledPaper elevation={0} emergencyContact={data?.isEmergencyContact}>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item container xs wrap="nowrap">
          <Grid item>
            <div style={{ marginRight: 8, marginTop: 4 }}>
              <CustomAvatar src={FamilyMemberIcon} size={38} />
            </div>
          </Grid>
          <Grid item>
            <Typography style={{ fontWeight: "bold" }}>
              {data?.relationship?.label}
            </Typography>
            <Typography variant="body2">{data?.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {getGenderAgeText(data)}
            </Typography>
            {!getGenderAgeText(data) && (
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ visibility: "hidden" }}
              >
                "fill"
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item>
          {editMode ? (
            <div style={{ display: "flex", gap: 1 }}>
              <IconButton size="small" onClick={onEdit}>
                <CreateIconV2 />
              </IconButton>
              <IconButton size="small" onClick={onDelete}>
                <DeleteIconV2 />
              </IconButton>
            </div>
          ) : (
            <Button
              variant="text"
              color="primary"
              className={classes.viewMoreButton}
              size="small"
              onClick={() => onViewMoreClicked(data?.id)}
            >
              View More
            </Button>
          )}
        </Grid>
      </Grid>
      {/* <Box sx={{p}}></Box> */}
    </StyledPaper>
  );
}

FamilyMemberCard.propTypes = {
  onViewMoreClicked: PropTypes.func,
};

export default FamilyMemberCard;
