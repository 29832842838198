import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import { avatarColorBgById } from "../../../utils";
import PropTypes from "prop-types";
import { PlaceholderIcon } from "../../../assets";

const customAvatarStyle = makeStyles((theme) => ({
  root: (props) => ({
    margin: theme.spacing(0, 1),
    ...props?.classes?.root,
  }),
  // avatarSize: {
  //   width: theme.spacing(6.25),
  //   height: theme.spacing(6.25),
  // },
  avatarSize: (props) => ({
    width: props.size ? props.size : 50,
    height: props.size ? props.size : 50,
  }),
  skeleton: {
    margin: theme.spacing(0, 1),
  },
  placeholderWrapper: {
    background: "#F2FFFF",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export function CustomAvatar({
  src,
  name,
  id,
  loading = false,
  fallbackAsImage,
  ...props
}) {
  // const defaultImage = "/images/tanyaCare/Tanya.png"
  const defaultImage = PlaceholderIcon;
  const classes = customAvatarStyle(props);
  const theme = useTheme();
  const fallbackText = name?.toString()?.toUpperCase()?.trim()?.[0];
  const fallbackId =
    id?.[id?.toString()?.search(/\d/)] *
    id?.toString().split("").reverse()?.[
      id?.toString().split("").reverse().join("")?.search(/\d/)
    ];

  return (
    <>
      {loading && (
        <Skeleton
          variant="circle"
          className={classes.skeleton}
          width={theme.spacing(6.25)}
          height={theme.spacing(6.25)}
        />
      )}
      {!loading && (
        <Avatar
          src={src ? src : fallbackAsImage ? defaultImage : null}
          className={`${classes.root} ${classes.avatarSize}`}
          // style={{
          //   // backgroundColor: "unset",
          //   background: fallbackAsImage ? "#F2FFFF" : "unset"
          // }}
        >
          {fallbackAsImage ? (
            <div className={classes.placeholderWrapper}>
              <PlaceholderIcon />
            </div>
          ) : (
            <Avatar
              src={null}
              className={`${classes.root} ${classes.avatarSize}`}
              style={{
                backgroundColor: avatarColorBgById(fallbackId),
              }}
            >
              {fallbackText}
            </Avatar>
          )}
        </Avatar>
      )}
    </>
  );
}

CustomAvatar.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  loading: PropTypes.bool,
  fallbackAsImage: PropTypes.bool,
  size: PropTypes.number,
};
