import React, { Component } from 'react'
import { Avatar, Button, Grid, Tooltip, Typography, withStyles, Zoom } from '@material-ui/core'
import { AvatarStackIcon } from '../../assets'
import { AvatarGroup, Skeleton } from '@material-ui/lab'
import PropTypes from 'prop-types'
import { avatarColorBgById } from '../../utils'

const styles = (theme) => ({
  title: {
    color: 'rgba(146, 151, 165, 1)',
    fontSize: 13
  },
  buttonStyle: {
    color: theme.palette.ternary.main,
    alignSelf: 'flex-end',
    boxShadow: 'unset !important',
    marginBottom: theme.spacing(0.25),
  },
  buttonContainer: {
    display: 'flex',
    [theme.breakpoints.between(320, 402)]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.between('sm', 676)]: {
      justifyContent: 'center'
    }
  },
  avatarGroup: {
    marginTop: theme.spacing(1)
  },
  skeletonStyle: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  emptyMsg: {
    marginTop: theme.spacing(0.5)
  },
  listMiddle: {
    margin: "8px 0px"
  }
})

class AvatarStack extends Component {
  render() {
    const { classes, title, width, height, style, data, onButtonClickHandler, emptyMessage, loading, onAvatarClicked, viewButtonName, hideViewButton } = this.props
    return (
      <>
        <Grid container spacing={1} style={{marginTop: this.props.isListTop ? 24 : 0}} className={this.props.isListMiddle && classes.listMiddle} >
          <Grid item>
            <AvatarStackIcon style={{ width: width ? width : 24, height: height ? height : 20, ...style }} />
          </Grid>
          <Grid item>
            <Typography variant="body1" className={classes.title}>
              {title ? title : 'N/A'}
            </Typography>
            {loading &&
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Skeleton variant="circle" width={40} height={40} className={classes.skeletonStyle} />
                <Skeleton variant="circle" width={40} height={40} className={classes.skeletonStyle} style={{ marginLeft: '-32px' }} />
                <Skeleton variant="circle" width={40} height={40} className={classes.skeletonStyle} style={{ marginLeft: '-32px' }} />
                <Skeleton variant="circle" width={40} height={40} className={classes.skeletonStyle} style={{ marginLeft: '-32px' }} />
                <Skeleton variant="circle" width={40} height={40} className={classes.skeletonStyle} style={{ marginLeft: '-32px' }} />
              </div>
            }

            {
              !loading &&
              <Grid container>
                {data && data?.length > 0 ? <Grid item xs>
                  <AvatarGroup max={5} className={classes.avatarGroup}>
                    {data?.map((avatar, index) => {
                      
                      return (
                        <Tooltip
                          TransitionComponent={Zoom}
                          title={avatar?.name}
                          placement="bottom"
                          enterDelay={100}
                          leaveDelay={50}
                          onClick={() => onAvatarClicked(avatar.id, avatar)}
                          arrow
                          key={index}
                        >
{/* style={{ backgroundColor: !list?.profile_pic ? avatarColorBgById(list?.id ?? Math.floor(Math.random() * (999 - 100 + 1) + 100)) : "" }} */}
{/* {list?.name?.[0]?.toUpperCase()} */}
                        {/* {alert(avatar?.profile_pic)} */}
                          <Avatar
                            style={{ zIndex: `${index + 1} !important`, cursor: "pointer", backgroundColor: !avatar?.profile_pic ? avatarColorBgById(avatar?.id ?? Math.floor(Math.random() * (999 - 100 + 1) + 100)) : "" }}

                            src={avatar?.profile_pic}>
                                {avatar?.name?.trim()?.[0]?.toUpperCase()}
                          </Avatar>

                        </Tooltip>
                      )
                    })}
                  </AvatarGroup>
                </Grid>
                  :
                  <Typography variant="body1" className={classes.emptyMsg} >{emptyMessage ? emptyMessage : 'N/A'}</Typography>
                }
                { !hideViewButton &&  (data && data?.length > 0) && <Grid item className={classes.buttonContainer} >
                  <Button variant="text" id={this?.props?.id} className={classes.buttonStyle} onClick={onButtonClickHandler}>
                  {viewButtonName ? viewButtonName : "VIEW DETAILS"}
                  </Button>
                </Grid>}
              </Grid>
            }
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withStyles(styles)(AvatarStack)

AvatarStack.propTypes = {
  /**Sets the title of the component */
  title: PropTypes.string,
  /**Sets the height of the icon. */
  height: PropTypes.number,
  /**Sets the width of the icon. */
  width: PropTypes.number,
  /**onButtonClick handler. Create a function and pass here to handle text button click. */
  onButtonClickHandler: PropTypes.func,
  /** Data to show. */
  data: PropTypes.array,//It's a array of object must contain a key profile_pic,
  /**If no user then the value will be defaulted to N/A. To avoid this, send your custom value to this prop. */
  emptyMessage: PropTypes.string,
  /**Whether the data is loading or not. */
  loading: PropTypes.bool,
  /**An Avator is clicked */
  onAvatarClicked: PropTypes.func,
  /**View Button Name */
  viewButtonName: PropTypes.string
}