import React, { Component } from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  activityTextContainer: (props) => ({
    padding: theme.spacing(props.padding) || theme.spacing(1.5),
    background: 'white'
  }),
  activityText: (props) => ({
    lineHeight: '24px'
  }),
  dashedBorderContainer: (props) => ({
    padding: theme.spacing(0, props.padding, 1.5, props.padding) || theme.spacing(0, 1.5, 1.5, 1.5),
    background: 'white'
  })
})


class SummaryTextCard extends Component {
  render() {
    const { classes, description, hideBorders } = this.props
    // const { } = this.props
    return (
      <Grid container>
        <Grid item xs={12} className={classes.activityTextContainer}>
          <Typography variant="body2" className={classes.activityText} >
            <div dangerouslySetInnerHTML={{__html: description}}>
            </div>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.dashedBorderContainer}>
          <div style={{
            borderBottom: `${!hideBorders ? "1px dashed rgba(146, 151, 165, 0.3)" : 0}`,
          }}></div>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(SummaryTextCard)

SummaryTextCard.propTypes = {
  /**Set padding for CheckIn CheckOut card, example ```padding={2} will transform to 2*8px = 16px```. Consider this while setting the padding */
  padding: PropTypes.number,
  /**Hides applied borders. */
  hideBorders: PropTypes.bool
}