import React from 'react'
import { Typography } from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiToggleButton-root': {
      padding: '10px',
      borderRadius: 10,
      backgroundColor: '#F5F5F5',
      borderColor: '#F5F5F5',
      [theme.breakpoints.down('xs')]: {
        margin: 10
      },
      '& .MuiToggleButton-label': {
        color: '#000',
        textTransform: 'capitalize'
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        '& .MuiToggleButton-label': {
          color: '#fff !important',
        }
      }

    },
    '& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)': {
      borderLeft: '1px solid #F5F5F5'
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      color: '#fff',
      fontSize: 14,
      '& .MuiToggleButton-label': {
        color: '#fff !important'
      }
    },
    '& .MuiToggleButton-root.Mui-selected:hover': {
      // backgroundColor:theme.palette.secondary.main
    },
    '& .MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected': {
      marginLeft: 10,
      borderLeft: `1px solid ${theme.palette.secondary.main}`
    },
    '& .MuiToggleButtonGroup-root': {
      // flexWrap: 'wrap',
    }
  },
}))

export const ToggleButtonComponent = props => {
  
  const classes = useStyles(props);

  const [formats, setFormats] = React.useState(() => props?.isSelected ?? []);

  React.useEffect(() => {
    setFormats(props.isSelected)
    // eslint-disable-next-line
  }, [props.isSelected])

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
    props.onSelected(newFormats);
  };


  return (
    <div className={`${classes.root}`}>
      <ToggleButtonGroup value={formats} onChange={handleFormat} aria-label="text formatting" exclusive={props?.exclusive ?? false}>

        {
          props?.toggleButtonData && props?.toggleButtonData.map((_, key) => {
            return (
              <ToggleButton disabled={props.disabled} key={key} style={{ marginLeft: key === 0 ? 0 : 10 }} value={_.value} aria-label={_.value}>
                <Typography variant="subtitle2">{_.label}</Typography>
              </ToggleButton>
            )
          })
        }

      </ToggleButtonGroup>
    </div>
  )
}

ToggleButton.propTypes = {
  toggleButtonData: PropTypes.array,
  isSelected: PropTypes.array,
  exclusive: PropTypes.bool,
  onSelected: PropTypes.bool,
  disabled: PropTypes.bool
}
