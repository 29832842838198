import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

export const CustomTooltip = (props) =>{

    const { 
        children,
        title="",
        placement="left",
        arrow=true,
        popperClassName=""
     } = props;

    return(
        <>
        {children && 
            <Tooltip 
                title={title}
                placement={placement}
                arrow={arrow}
                classes={{
                    popper:popperClassName
                }}
                >
                {children}
            </Tooltip>
        }
        </>
    )
}

CustomTooltip.propTypes={
    children:PropTypes.node,
    title:PropTypes.string,
    placement:PropTypes.oneOf([
        'bottom-end',
        'bottom-start',
        'bottom',
        'left-end',
        'left-start',
        'left',
        'right-end',
        'right-start',
        'right',
        'top-end',
        'top-start',
        'top',
    ]),
    arrow:PropTypes.bool,
}