import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { routes } from "../../router/routes";

import { withNavBars } from "./../../HOCs";

// import {
//   CareProvider,
//   CareProviderDetails,
//   CareProviderAdd,
//   CareProviderEdit,
// } from "./careProvider";
import {
  CareGiver,
  CareGiverDetails,
  // CareGiverAdd,
  // CareGiverEdit,
  AddEditCareGiver,
} from "./careGiver";
import {
  CareCoordinator,
  CareCoordinatorDetails,
  // CareCoordinatorAdd,
  // CareCoordinatorEdit,
  AddEditCareCoordinator,
} from "./careCoordinator";
import { ListClient, ClientDetail, AddEditClient } from "./clients";
import {
  AddEditSupervisor,
  SupervisorDetail,
  SupervisorList,
} from "./supervisor";

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateNavBarProps: true,
    };
  }

  render() {
    const { match } = this.props;

    return (
      <>
        {/* Care Provider Routes */}
        {/* {match.path === routes.manage_care_providers && <CareProvider userRole={this.props.userRole} />}
        {match.path === routes.view_care_providers && <CareProviderDetails />}
        {match.path === routes.add_care_providers && <CareProviderAdd />}
        {match.path === routes.edit_care_providers && <CareProviderEdit />} */}

        {/* Care giver Routes */}
        {match.path === routes.manage_care_givers && (
          <CareGiver userRole={this.props.userRole} />
        )}
        {match.path === routes.view_care_givers && <CareGiverDetails />}
        {match.path === routes.add_care_givers && <AddEditCareGiver />}
        {match.path === routes.edit_care_givers && (
          <AddEditCareGiver isEdit={true} />
        )}

        {/* Care Coordinator Routes */}
        {match.path === routes.manage_care_coordinators && (
          <CareCoordinator userRole={this.props.userRole} />
        )}
        {match.path === routes.view_care_coordinators && (
          <CareCoordinatorDetails />
        )}
        {match.path === routes.add_care_coordinators && <AddEditCareCoordinator />}
        {match.path === routes.edit_care_coordinators && (
          <AddEditCareCoordinator isEdit={true} />
        )}

        {/* Client Routes */}
        {match.path === routes.manage_clients && (
          <ListClient userRole={this.props.userRole} />
        )}
        {match.path === routes.view_clients && <ClientDetail />}
        {match.path === routes.add_clients && <AddEditClient />}
        {match.path === routes.edit_clients && <AddEditClient isEdit={true} />}

        {/* Supervisor Routes */}
        {match.path === routes.manage_supervisors && (
          <SupervisorList userRole={this.props.userRole} />
        )}
        {match.path === routes.view_supervisors && <SupervisorDetail />}
        {match.path === routes.add_supervisors && <AddEditSupervisor />}
        {match.path === routes.edit_supervisors && (
          <AddEditSupervisor isEdit={true} />
        )}
      </>
    );
  }
}

UserManagement.propTypes = {
  title: PropTypes.string,
  withBackButton: PropTypes.bool,
  navBarArr: PropTypes.array,
};

UserManagement.defaultProps = {
  title: "User Managment",
};

export default withRouter(withNavBars(UserManagement));
