import React from "react";
import AddEditForm from "../addEditForm";
import {
  formComponentTypes,
  alertProps,
  // timezoneOld as timezone
} from "../../utils";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
// import ConflictList from "../../conflictList";
import PropTypes from "prop-types";
class AddressComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {

    };
  }

  componentDidMount() {
    this.getActivityData()
  }

  getActivityData = () => {
    this.props.GET_MASTER_EMAIL_TYPE({});
  };

  onSaveButtonClicked = (isError, data, isUrl) => {
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      if (this.props?.editData) {
        data.id = this.props?.editData.id
      }
      this.props.callBackOnSave(data)
    }
  };

  onCancelButtonClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  render() {
    let isEdit = this.props.isEdit;

    let list = this.props.list;
    let selectedList = []
    let data = this.props?.editData;
    let id = this.props?.id;
    if (list && list.length > 0) {
      selectedList = list.map(val => val.email_type.value)
    }
    if (data && data.length > 0 && selectedList.length > 0) {
      selectedList = selectedList.filter(val => val !== data.value)
    }
    const components = {
      section_1: {
        label: !isEdit ? "Add New Email" : "Edit Email",
        fields: {
          email_type: {
            key: "email_type",
            type: formComponentTypes.SELECT_BOX,
            id:`selectAdditionalEmailTypeFrom${id ? id : ''}`,
            componentProps: {},
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "Email Type",
            value: data?.email_type || { value: data?.value, label: data?.label },
            // options: this.props.emailType?.data
            //   ? this.props.emailType?.data
            //   : [],
            options: this.props.emailType?.data
              ? selectedList.length > 0 ? this.props.emailType?.data.filter(val => selectedList.indexOf(val.value) === -1)
                : this.props.emailType?.data
              : [],
            isMandatory: true,
          },
          email: {
            key: "email",
            type: formComponentTypes.INPUT_BOX,
            id:`addAdditionalEmailFrom${id ? id : ''}`,
            componentProps: {
              type: "email",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "EMAIL ADDRESS",
            value: data?.email || data?.emailId,
            isMandatory: true
          },
        },
      },
    };
    return (
      <>
        {isEdit ? (
          <>
            <AddEditForm
              components={components}
              onSaveButtonClicked={this.onSaveButtonClicked}
              cancelForm={this.onCancelButtonClicked}
              // loading={updateLoading}
              isUrl={true}
              saveButtonLabel={"Update"}
              activityEdit={isEdit}
              spacing={1}
              sectionGutterBottom={16}
              saveButtonId= {`addSaveEmailButtonFrom${id ? id : ''}`}
              cancelButtonId= {`addCancelEmailButtonFrom${id ? id : ''}`}
            />
          </>
        ) : (
            <AddEditForm
              components={components}
              onSaveButtonClicked={this.onSaveButtonClicked}
              cancelForm={this.onCancelButtonClicked}
              // loading={addLoading}
              saveButtonLabel={"Add Email"}
              withoutBoxShadow={true}
              spacing={1}
              sectionGutterBottom={16}
              saveButtonId= {`addSaveEmailButtonFrom${id ? id : ''}`}
              cancelButtonId= {`addCancelEmailButtonFrom${id ? id : ''}`}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  emailType: state?.masterSet?.emailType,
});

// export default AddEditAvailabiity
export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(AddressComponent));

AddressComponent.propTypes = {
  /**To persist State, after viewing all conflicts state and returning back to add/Edit activity screen. */
  persistState: PropTypes.bool,
  /**State to set while persisting. */
  parentState: PropTypes.object,
};
