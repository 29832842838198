import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: "none",
    stroke: props.color || "#9297a5",
    strokeMiterlimit: 10,
    strokeWidth: 2
  })
}))

export const ManagementNewIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon fontSize={'small'} style={{ marginTop: 8 }}> {/*The margin Top style is added to position the icon to center */}
      {/* <defs>
        <style>
          .cls-1{fill:none;stroke:#9297a5;stroke-miterlimit:10;stroke-width:2px}
        </style>
      </defs> */}
      <g id="Management" transform="translate(0 -5)">
        <path id="Path_546" d="M5 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm7 0a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm7 0a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm-4 8.423A10.012 10.012 0 0 1 18 14c2.731 0 5 .909 5 2.217V18H1v-1.783C1 14.909 3.269 14 6 14a10.012 10.012 0 0 1 3 .423" className={classes.cls1} data-name="Path 546" />
        <path id="Path_547" d="M17 18v-1.783C17 14.909 14.731 14 12 14s-5 .909-5 2.217V18" className={classes.cls1} data-name="Path 547" />
      </g>
    </SvgIcon >
  )
}

ManagementNewIcon.propTypes = {
  color: PropTypes.string,
};