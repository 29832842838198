import * as React from "react"

import { SvgIcon, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || theme.palette.primary.main,
  })
}))


function MonitorMailIcon(props) {
  const classes = useStyles(props)
  return (
    <SvgIcon fontSize={"small"}
      {...props}
      style={props.style}>
      <g id="prefix__Mail" transform="translate(-2 -4)">
        <path
          id="prefix__Path_670"
          d="M2 10.092V18a2 2 0 002 2h16a2 2 0 002-2v-7.87l-2 1.245V18H4v-6.654zm2-1.106V6h16v3.02l2-1.245V6a2 2 0 00-2-2H4a2 2 0 00-2 2v1.732z"
          className={classes.cls1}
          data-name="Path 670"
        />
        <path
          id="prefix__Path_671"
          d="M12 11.749L3 6.108v2.251L12 14l9-5.6V6.146z"
          className={classes.cls1}
          data-name="Path 671"
        />
      </g>
    </SvgIcon>
  )
}

export default MonitorMailIcon

MonitorMailIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};