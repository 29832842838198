import React from "react";
import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

export const ClientHeightIcon = (props) => {
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
      htmlColor={props.color}
      style={props.style}
    >
      <path id="Path_610" d="M12 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm1 13h2v7h-2zm-4 0h2v7H9z" class="cls-1" data-name="Path 610" />
      <path id="Path_611" d="M14 9v5h-4V9h4m0-2h-4a2 2 0 0 0-2 2v7h8V9a2 2 0 0 0-2-2z" class="cls-1" data-name="Path 611" />
    </SvgIcon>
  )
}

ClientHeightIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};
