import React from "react";
import CardList from "./CardList";
// import CountCardLoaders from "./CardLoaders";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import ListLoader from "../../components/listLoader";
import {  InternatlServerError, NotFoundSection } from "../../components";

// const data = [1, 2];
class Reportcard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      pageNo: 0,
      pageSize: 10,
    };
  }

  componentDidMount() {
    this.getClientList();
  }

  getClientList = () => {
    // this.props.GET_ALL_CARE_PROVIDER({ ...this.state });
    this.props.GET_CLIENT_ALL_REPORTS({ id:parseInt(this.props.match.params.client_id) });
  };
  render() {
    let { loading, data, error } = this.props?.clientReport?.clientAllReports;
    return (
    
      <div style={{padding:25}}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={3}
        >
          {loading && (<Grid item><ListLoader /></Grid>)}
          {!loading && error && (<InternatlServerError message="Unable to fetch reports" onRetry={this.getClientList} />)}
          {!loading && !error && !data?.reports?.length && (<NotFoundSection message="You don't have any reports" />)}
          {!error && !loading && data?.reports?.length > 0 && (
            <Grid item container direction="row" xs={12}>
              {data.reports.map((report, key) => {
                return (
                  <div key={key} style={{ cursor: "pointer", width:'100%', marginBottom: 12}}>
                    <CardList
                      data={report}                     
                    />
                  </div>
                );
              })}
            </Grid>
          )}
        </Grid>
       
       </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clientReport: state.clientReport,
});

export default connect (mapStateToProps,actions)(withRouter(Reportcard));
