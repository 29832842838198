import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography, Divider } from "@material-ui/core";
import { fontStyleClass } from "../../../utils";

const styles = makeStyles((theme) => ({
  header: {
    marginBottom: 10,
  },
  ...fontStyleClass
}));

export function ListWithDivider(props) {
  const classes = styles();
  const { title, listData } = props;
   
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" className={`${classes.header} ${classes.mediumFont}`}>
          {title}
        </Typography>
      </Grid>
      {listData?.length>0 ?
      listData.map((val, index) => {
        return (
          <>
            <Grid item xs={12} style={{ padding: "5px 0px" }}>
              <Typography variant="caption" style={{ float: "left" }}>
                {val.title}
              </Typography>
              <Typography variant="caption" style={{ float: "right" }}>
                {val.value}
              </Typography>
            </Grid>
            {listData.length - 1 > index && (
              <Divider style={{ width: "100%" }} />
            )}
          </>
        );
      })
    :
    <Typography variant="body1" color="textSecondary" style={{ float: "left" }}>
                Not Updated
              </Typography>
    }
    </Grid>
  );
}

ListWithDivider.propTypes = {
  title: PropTypes.string,
  listData: PropTypes.array,
};
