import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { IconText } from './iconText';
import { RectangleIcon,CircleIcon,TriangleIcon } from '../svg/shape';
import { ColorCodes } from './colorCode';
import Slider from '@material-ui/core/Slider';
import PropTypes from 'prop-types';
import { createTestProps } from '../../../../../../../utils/common';
const styles = makeStyles(()=>({
   flexRow:{
       display:'flex',
       alignItems:'flex-end',
       justifyContent:'center'
   },
   divider:{
        height: 1,
        backgroundColor: '#707070',
        opacity: 0.35,
        marginBlock:20
   },
   sliderText:{
       width:40,
       height:33,
       backgroundColor:'#E2E2E2',
       border:'1px solid #E2E2E2',
       borderRadius:4,
       display:'flex',
       alignItems:'center',
       justifyContent:'center',
       color:'#252B43',
       fontSize:14,
       fontWeight:400,       
   },
   slider:{
       display:'flex',
       alignItems:'center',
       padding:10,
       marginTop:30,
       '& .MuiSlider-root':{
           flex:1,
           marginRight:20
       }
   }
}))

export const ShapesMenu = (props) =>{

    const {
        activeObject={},
        activeObjectValues={},
        handleShapeSubMenu=null
    } = props;

    const classes = styles(props);

    const ICON_LIST = [
        {
            icon:<RectangleIcon />,
            text:'Rectangle'
        },
        {
            icon:<CircleIcon width={22} height={22} />,
            text:'Circle'
        },
        {
            icon:<TriangleIcon />,
            text:'Triangle'
        },
    ]

    const returnValue = (key) =>{
        if(key === "fill"){
            return activeObjectValues?.fill ? activeObjectValues?.fill : activeObject?.fill?.color
        }
        else{
            return activeObjectValues?.[key] ? activeObjectValues?.[key] : activeObject?.[key]
        }
    }

    return(
        <div>
            <div className={classes.flexRow}>
               {ICON_LIST?.map((_,i)=>(
                   <div 
                    style={{
                        //    marginRight:i < ICON_LIST.length - 1 ? 25 : 0
                        flex:1,
                        padding:5
                    }}
                    key={_?.text}
                   >
                        <IconText 
                            icon={_?.icon}
                            text={_?.text}
                            handleClick={()=>handleShapeSubMenu(_?.text)}
                            {...createTestProps(`shapesIcon${_?.text}`)}
                        />
                   </div>
               ))}
            </div>
            <div className={classes.divider}></div>
            <div className={classes.flexRow}>
                <div style={{marginRight:25}}>
                    <ColorCodes 
                        text="Fill"
                        value={returnValue('fill')}
                        handleChange={(value)=>handleShapeSubMenu(value,'fill')}
                        {...createTestProps('shapes menu fill code')}
                    />
                </div>
                <div>
                    <ColorCodes 
                        text="Stroke"
                        value={returnValue('stroke')}
                        handleChange={(value)=>handleShapeSubMenu(value,'stroke')}
                        {...createTestProps('shapes menu color code')}
                    />
                </div>
            </div>
            <div className={classes.divider}></div>
            <div className={classes.slider}>
                <Slider 
                    value={activeObject?.id ? returnValue('strokeWidth') : 0}
                    onChange={(event,value)=>handleShapeSubMenu(value,'strokeWidth')}
                    {...createTestProps('stroke continuous slider')}
                />
                <div className={classes.sliderText}>{activeObject?.id ? returnValue('strokeWidth') : 0}</div>
            </div>
        </div>
    )
}

ShapesMenu.propTypes={
    activeObject:PropTypes.object,
    activeObjectValues:PropTypes.object,
    handleShapeSubMenu:PropTypes.func,
}