export const components = {
  PROVIDER: {
    uploadImage: {
      key: "uploadImage",
      element: "PROFILE_UPLOAD_BUTTON",
      elementType: "",
      componentProps: {
        type: "text",
        label: "UPLOAD PHOTO",
      },

      value: "",
    },
    business_registered_name: {
      key: "business_registered_name",
      element: "INPUT_BOX",
      elementType: "",

      componentProps: {
        type: "text",
      },
      label: "Business Registered Name",
      value: "",
      required: true,
    },
    business_trading_name: {
      key: "business_trading_name",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Business Trading Name",
      value: "",
      required: true,
    },
    business_registred_number: {
      key: "business_registred_number",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Business Registration Number",
      value: "",
      required: true,
    },
    permise_registration_number: {
      key: "permise_registration_number",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Premise Registration Number",
      value: "",
      required: true,
    },
    date_of_incorporation: {
      key: "date",
      element: "DATE_TIME_PICKER",
      elementType: "",
      componentProps: {
        isHidePastDate: true,
        isHideFutureDate: false,
        // isDisable: true,
        isDate: true,
      },
      label: "Date of Incorporation",
      value: null,
      required: true,
    },
    website: {
      key: "website",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
        isUrlValidation: true,
      },
      label: "Website",
      value: "",
      required: true,
    },
    category: {
      key: "category",
      element: "SELECT_BOX",
      elementType: "",
      label: "CATEGORY",
      value: "",
      requiredAction:["GET_CATEGORIES"],
      requiredPayload:{ categoryId:null },
      options:[],
      componentProps: {},
      required: true,
      isMulti: true,
    },

    primary_email_address: {
      key: "primary_email_address",
      element: "INPUT_BOX",
      elementType: "email",
      componentProps: {},
      label: "Primary Email Address",
      value: "",
      required: true,
    },

    primary_address: {
      key: "primary_address",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Primary Address",
      value: "",
      required: true,
    },

    primary_phone_number: {
      key: "primary_phone_number",
      label: "Primary Phone number",
      value: "",
      element: "MOBILE_INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "number",
      },
      options: [
        { label: "Mr", value: "mr" },
        { label: "Mrs", value: "mrs" },
      ],
      required: true,
    },
    address_component: {
      key: "address_component",
      label: "Address",
      value: "",
      element: "ADD_ADDRESS",
      elementType: "",
      componentProps: {},
      required: true,
    },
    phone_number: {
      key: "phone_number",
      label: "Phone Number",
      value: "",
      element: "ADD_PHONE_NUMBER",
      elementType: "",
      componentProps: {},
      required: true,
    },
    email: {
      key: "email",
      label: "Email",
      value: "",
      element: "ADD_EMAIL",
      elementType: "",
      componentProps: {},
      required: true,
    },
  },
  BRANCH: {
    uploadImage: {
      key: "uploadImage",
      element: "PROFILE_UPLOAD_BUTTON",
      elementType: "",
      componentProps: {
        type: "text",
        label: "UPLOAD PHOTO",
      },

      value: "",
    },
    select_provider: {
      key: "select_provider",
      element: "SELECT_BOX",
      elementType: "",
      label: "SELECT PROVIDER",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },
    business_registered_name: {
      key: "business_registered_name",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Business Registered Name",
      value: "",
      required: true,
    },
    business_trading_name: {
      key: "business_trading_name",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Business Trading Name",
      value: "",
      required: true,
    },
    business_registred_number: {
      key: "business_registred_number",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Business Registration Number",
      value: "",
      required: true,
    },
    permise_registration_number: {
      key: "permise_registration_number",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Premise Registration Number",
      value: "",
      required: true,
    },
    date_of_incorporation: {
      key: "date",
      element: "DATE_TIME_PICKER",
      elementType: "",
      componentProps: {
        isHidePastDate: true,
        isHideFutureDate: false,
        // isDisable: true,
        isDate: true,
      },
      label: "Date of Incorporation",
      value: null,
      required: true,
    },
    website: {
      key: "website",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
        isUrlValidation: true,
      },
      label: "Website",
      value: "",
      required: true,
    },
    category: {
      key: "category",
      element: "SELECT_BOX",
      elementType: "",
      label: "CATEGORY",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },

    primary_email_address: {
      key: "primary_email_address",
      element: "INPUT_BOX",
      elementType: "email",
      componentProps: {
        type: "email",
      },
      label: "Primary Email Address",
      value: "",
      required: true,
    },

    primary_address: {
      key: "primary_address",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Primary Address",
      value: "",
      required: true,
    },

    primary_phone_number: {
      key: "primary_phone_number",
      label: "Primary Phone number",
      value: "",
      element: "MOBILE_INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "number",
        onlyText: true,
        customsize: 3,
      },
      options: [
        { label: "Mr", value: "mr" },
        { label: "Mrs", value: "mrs" },
      ],
      required: true,
    },
  },
  COORDINATOR: {
    // uploadImage: {
    //   key: "uploadImage",
    //   element: "PROFILE_UPLOAD_BUTTON",
    //   elementType: "",
    //   componentProps: {
    //     type: "text",
    //     label: "UPLOAD PHOTO",
    //   },

    //   value: "",
    // },
    first_name_last_name: {
      key: "first_name_last_name",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "First Name",
      value: "",
      required: true,
    },
    middle_name: {
      key: "middle_name",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Middle Name",
      value: "",
      required: true,
    },
    date_of_birth: {
      key: "date",
      element: "DATE_TIME_PICKER",
      elementType: "",
      componentProps: {
        isHidePastDate: true,
        isHideFutureDate: false,
        // isDisable: true,
        isDate: true,
      },
      label: "DOB",
      value: null,
      required: true,
    },
    age: {
      key: "age",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "AGE",
      value: "",
      required: true,
    },
    gender: {
      key: "gender",
      element: "SELECT_BOX",
      elementType: "",
      label: "GENDER",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },
    id_type: {
      key: "id_type",
      element: "SELECT_BOX",
      elementType: "",
      label: "ID TYPE",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },
    id_number: {
      key: "id_number",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "ID NUMBER",
      value: "",
      required: true,
    },
    select_category: {
      key: "select_category",
      element: "SELECT_BOX",
      elementType: "",
      label: "SELECT CATEGORY",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },
    select_provider: {
      key: "select_provider",
      element: "SELECT_BOX",
      elementType: "",
      label: "SELECT PROVIDER",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },

    primary_email_address: {
      key: "primary_email_address",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Primary Email Address",
      value: "",
      required: true,
    },

    primary_address: {
      key: "primary_address",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Primary Address",
      value: "",
      required: true,
    },

    primary_phone_number: {
      key: "primary_phone_number",
      label: "Primary Phone number",
      value: "",
      element: "MOBILE_INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "number",
        onlyText: true,
        customsize: 3,
      },
      options: [
        { label: "Mr", value: "mr" },
        { label: "Mrs", value: "mrs" },
      ],
      required: true,
    },
    skills: {
      key: "skills",
      element: "SELECT_BOX",
      elementType: "",
      label: "SELECT SKILLS",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },
  },
  PROFESSIONAL: {

    first_name_last_name: {
      key: "first_name_last_name",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "First Name",
      value: "",
      required: true,
    },
    middle_name: {
      key: "middle_name",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Middle Name",
      value: "",
      required: true,
    },
    date_of_birth: {
      key: "date",
      element: "DATE_TIME_PICKER",
      elementType: "",
      componentProps: {
        isHidePastDate: true,
        isHideFutureDate: false,
        // isDisable: true,
        isDate: true,
      },
      label: "DOB",
      value: null,
      required: true,
    },
    age: {
      key: "age",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "AGE",
      value: "",
      required: true,
    },
    gender: {
      key: "gender",
      element: "SELECT_BOX",
      elementType: "",
      label: "GENDER",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },
    id_type: {
      key: "id_type",
      element: "SELECT_BOX",
      elementType: "",
      label: "ID TYPE",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },
    id_number: {
      key: "id_number",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "ID NUMBER",
      value: "",
      required: true,
    },
    select_category: {
      key: "select_category",
      element: "SELECT_BOX",
      elementType: "",
      label: "SELECT CATEGORY",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },
    select_provider: {
      key: "select_provider",
      element: "SELECT_BOX",
      elementType: "",
      label: "SELECT PROVIDER",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },

    primary_email_address: {
      key: "primary_email_address",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Primary Email Address",
      value: "",
      required: true,
    },

    primary_address: {
      key: "primary_address",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Primary Address",
      value: "",
      required: true,
    },

    primary_phone_number: {
      key: "primary_phone_number",
      label: "Primary Phone number",
      value: "",
      element: "MOBILE_INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "number",
        onlyText: true,
        customsize: 3,
      },
      options: [
        { label: "Mr", value: "mr" },
        { label: "Mrs", value: "mrs" },
      ],
      required: true,
    },
    certifications: {
      key: "add_certification",
      element: "ADD_CERTIFICATION",
      elementType: "",
      label: "SELECT CERTIFICATE",
      value: "",
      options: [],
      componentProps: {},
      child:{
        
      },
      required: true,
      isMulti: true,
    },
    experience: {
      key: "experience",
      element: "ADD_EXPERIENCE",
      elementType: "",
      label: "SELECT EXPERIENCE",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },
    skills: {
      key: "skills",
      element: "SELECT_BOX",
      elementType: "",
      label: "SELECT SKILLS",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },
  },
  SUPERVISOR: {
    uploadImage: {
      key: "uploadImage",
      element: "PROFILE_UPLOAD_BUTTON",
      elementType: "",
      componentProps: {
        type: "text",
        label: "UPLOAD PHOTO",
      },

      value: "",
    },
    first_name_last_name: {
      key: "first_name_last_name",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "First Name",
      value: "",
      required: true,
    },
    middle_name: {
      key: "middle_name",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Middle Name",
      value: "",
      required: true,
    },
    date_of_birth: {
      key: "date",
      element: "DATE_TIME_PICKER",
      elementType: "",
      componentProps: {
        isHidePastDate: true,
        isHideFutureDate: false,
        isDate: true,
      },
      label: "DOB",
      value: null,
      required: true,
    },
    age: {
      key: "age",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "AGE",
      value: "",
      required: true,
    },
    gender: {
      key: "gender",
      element: "SELECT_BOX",
      elementType: "",
      label: "GENDER",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },
    id_type: {
      key: "id_type",
      element: "SELECT_BOX",
      elementType: "",
      label: "ID TYPE",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },
    id_number: {
      key: "id_number",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "ID NUMBER",
      value: "",
      required: true,
    },
    select_category: {
      key: "select_category",
      element: "SELECT_BOX",
      elementType: "",
      label: "SELECT CATEGORY",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },
    select_provider: {
      key: "select_provider",
      element: "SELECT_BOX",
      elementType: "",
      label: "SELECT PROVIDER",
      value: "",
      options: [],
      componentProps: {},
      required: true,
      isMulti: true,
    },

    primary_email_address: {
      key: "primary_email_address",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Primary Email Address",
      value: "",
      required: true,
    },

    primary_address: {
      key: "primary_address",
      element: "INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "text",
      },
      label: "Primary Address",
      value: "",
      required: true,
    },

    primary_phone_number: {
      key: "primary_phone_number",
      label: "Primary Phone number",
      value: "",
      element: "MOBILE_INPUT_BOX",
      elementType: "",
      componentProps: {
        type: "number",
        onlyText: true,
        customsize: 3,
      },
      options: [
        { label: "Mr", value: "mr" },
        { label: "Mrs", value: "mrs" },
      ],
      required: true,
    },
  },
};
