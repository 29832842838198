import React, { Component } from "react";
import PropTypes from "prop-types";
import AddEditDynamicFromRenderer from "../../../addEditDynamicFromRenderer";
import { components } from "../../../addEditDynamicFromRenderer/components";

class AddEditSupervisor extends Component {
  render() {
    return <AddEditDynamicFromRenderer component={components.SUPERVISOR} />;
  }
}

AddEditSupervisor.propTypes = {
  data: PropTypes.array,
};

export default AddEditSupervisor;
