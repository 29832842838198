import React from "react";
import PropTypes from "prop-types";

// Components
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

// Icons
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const statusColor = {
  active: "#31B198",
  inActive: "#FF4D4A",
};

function StatusLabel({ active, ...props }) {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        gap: "2px",
      }}
    >
      <FiberManualRecordIcon
        {...props.iconProps}
        {...(active && { htmlColor: statusColor.active })}
        {...(!active && { htmlColor: statusColor.inActive })}
        style={{
          fontSize: "0.938rem",
          ...props.iconProps.style,
        }}
      />
      <Typography
        {...props.labelProps}
        style={{
          color: active ? statusColor.active : statusColor.inActive,
          fontWeight: "400",
          ...props.labelProps.style,
        }}
      >
        {active ? "Active" : "Inactive"}
      </Typography>
    </Box>
  );
}

StatusLabel.propTypes = {
  active: PropTypes.bool,
  iconProps: PropTypes.object,
  labelProps: PropTypes.object,
};

StatusLabel.defaultProps = {
  iconProps: {
    style: {},
  },
  labelProps: {
    style: {},
  },
};

export default StatusLabel;
