import React from 'react'
import { Grid, Switch, Typography, withStyles } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    "& .MuiSwitch-track": {
      background: theme.palette.secondary.main,
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      background: "#00E000",
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "white",
    },
  },
  switch: {
    "& .MuiSwitch-track": {
      borderRadius: 13,
    },

    "& .MuiSwitch-root": { padding: 6 },
  },
  switchLabel: {
    color: theme.palette.secondary.main,
    textAlign: "right",
  },
})

class HeaderWithSwitch extends React.Component {
  render() {
    const { classes } = this.props
    const { headerTitle, switchLabel, responsive, checked } = this.props
    return (
      <Grid container alignItems="center"  >
        <Grid item xs={6}>
          <Typography variant="h6">{headerTitle ? headerTitle : null}</Typography>
        </Grid>
        <Grid
          container
          item
          xs={responsive ? true : 6}
          className={classes.switch}
          alignItems="center"
          justify={responsive ? "flex-end": "flex-start"}
        >
          <Grid item xs={responsive ? true: 10}>
            <Typography
              variant="subtitle2"
              component="div"
              className={classes.switchLabel}
            >
              {switchLabel ? switchLabel : null}
            </Typography>
          </Grid>
          <Grid item xs={responsive ? null : 2}>
            <Switch
              checked={checked}
              disableRipple
              onChange={this.props?.handleSwithIconToggle}
              color="secondary"
              className={classes.root}
              inputProps={{
                "aria-label": "checkbox with default color",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(HeaderWithSwitch)