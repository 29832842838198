import React from 'react'
import { Avatar, Grid, IconButton, Typography, makeStyles } from '@material-ui/core'
import { VideoCallIcon, ChatIconIssueDetail, DirectionsIcon } from '../../../assets'
import PropTypes from 'prop-types'
import { AddressSection } from './profileAddress'
import { MeetingComponent } from './meeting'
import { avatarColorBgById, getIdForAvatarColor } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    background: 'white',
    padding: theme.spacing(props.padding) || theme.spacing(1.5),
  }),
  avatar: {
    margin: theme.spacing(0, 1, 0, 0),
    height: theme.spacing(6),
    width: theme.spacing(6)
  },
  name: {
    marginTop: 2
  },
  subText: {
    marginTop: 2
  },
  iconsHolder: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      marginTop: 10
    }
  },
  icons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: '50%',
    margin: theme.spacing(0, 0.5),
  },
  addressPadding: (props) => ({
    background: 'white',
    padding: theme.spacing(props.padding) || theme.spacing(1.5),
  })
}))

function ProfileCardActivitySummary(props) {
  const classes = useStyles(props)
  const { profilePicture, name, id, gender, age, address, hideAddressSection, hideQuickIcons, color, addressLoading, meetingUrl } = props
  const fallbackText = name?.toString()?.toUpperCase()?.trim()?.[0];
  const fallbackId = getIdForAvatarColor(id ?? Math.floor(Math.random() * (999 - 100 + 1) + 100));
  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        <Grid item container xs>
          <Grid item>
            <Avatar
              className={classes.avatar}
              src={profilePicture}
              style={{
                backgroundColor: !!!profilePicture
                  ? avatarColorBgById(fallbackId)
                  : "#bdbdbd",
              }}
            >
              {fallbackText}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={classes.name}>
              {name ? name : "N/A"}
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              className={classes.subText}
            >
              {gender ? gender : ""}
              {gender && age ? "," : ""} {age ? age + " Years" : ""}
            </Typography>
          </Grid>
        </Grid>
        {!hideQuickIcons && (
          <Grid item>
            <div className={classes.iconsHolder}>
              <IconButton className={classes.icons}>
                <VideoCallIcon height={16} width={16} />
              </IconButton>
              <IconButton className={classes.icons}>
                <ChatIconIssueDetail height={16} width={16} />
              </IconButton>
              <IconButton className={classes.icons}>
                <DirectionsIcon height={16} width={16} />
              </IconButton>
            </div>
          </Grid>
        )}
      </Grid>
      {!hideAddressSection && (
        <Grid container className={classes.addressPadding}>
          {!!meetingUrl && (
            <MeetingComponent
              padding={props.padding}
              meetingLink={meetingUrl ? meetingUrl : "--/--"}
              color={color}
            />
          )}
          {address && !meetingUrl && (
            <AddressSection
              padding={props.padding}
              addressLoading={addressLoading}
              address={address ? address : "--/--"}
              color={color}
            />
          )}
        </Grid>
      )}
    </>
  );
}

export default ProfileCardActivitySummary

ProfileCardActivitySummary.propTypes = {
  /**URL of the profile picture, defaults to ```avatar``` icon from material UI*/
  profilePicture: PropTypes.string,
  /**Displays the name, defaults to ```N/A``` */
  name: PropTypes.string,
  /**Displays the gender, defaults to ```null``` */
  gender: PropTypes.string,
  /**Displays the age, defaults to ```null``` */
  age: PropTypes.number,
  /**Displays the address, defaults to ```null``` */
  address: PropTypes.string,
  /**Hides the address section, defaults to ```false``` */
  hideAddressSection: PropTypes.bool,
  /**Hides the Quick Icons which has videocam, chat, directions icons, defaults to ```false``` */
  hideQuickIcons: PropTypes.bool,
  /**Set padding for activity Profile card, example ```padding={2} will transform to 2*8px = 16px```. Consider this while setting the padding */
  padding: PropTypes.number,
  /**Sets the color of address icon. */
  color: PropTypes.string
}