import React from "react";
import {
  Paper,
  withStyles,
  Typography,
  Grid,
  Avatar,
  ListItemText,
  List,
  ListItem,
  Divider,
} from "@material-ui/core";
import { HybridTypography } from "../../../../components";
import { ICONS } from "../../../../components/hybridTypography/typography";
import moment from "moment";
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { CertificateIcon, ExperienceIcon } from '../../../../assets'
import PropTypes from 'prop-types'
import { customAddress } from "../../../../utils";

const styles = (theme) => ({
  paperCard: {
    borderRadius: "0px",
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
  },
  isNotBottom: {
    borderRadius: "0px",
    padding: "25px 25px 0px 32px",
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
  },
  strong_name: {
    display: "inline-block",
    paddingTop: 12,
    color: theme.palette.primary.dark,
    fontSize: 14,
    paddingLeft: "40px",
  },
  attachroot: {
    padding: 10,
    marginLeft: 30,
    border: "1px dashed " + theme.palette.primary.light,
    background: theme.palette.secondary.light,
    marginTop: "10px",
    borderRadius: "6px",
  },
  user_list: {
    display: "grid",
    gridTemplateColumns: "80px 1fr",
  },
  avatarhead: {
    display: "flex",
  },
  user_name: {
    display: "inline-block",
    paddingRight: "12px",
    fontSize: "1rem",
  },
  avatar: {
    width: "50px",
    height: "50px",
    margin: "auto",
  },
  secondary: {
    color: theme.palette.secondary.main,
    fontSize: "14px",
    paddingTop: "7px",
  },
  marginHeight: {
    marginTop: "auto",
    marginBottom: "auto",
    background: "white",
  },
  bottomRounded: {
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  avatar__: {
    width: "100%",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    height: "100%",
    display: "flex",
    "& .MuiAvatar-root": {
      margin: "25% auto 0%",
      background: "rgba(0, 0, 0, 0.03)",
      "& img": {
        objectFit: "scale-down"
      }
    }
  },

  title_: {
    fontSize: 15,
    lineHeight: 1.7,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  sub_text: {
    display: 'inline-block',
    fontSize: 12,
    margin: "0px 0px 8px 0px",
    lineHeight: 1.6,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  footer: {
    fontWeight: 400,
    fontSize: 12,
  },
  title__: {
    fontSize: "17px",
    fontWeight: 600,
    textAlign: "left",
    marginBottom: 8,
    marginTop: 8,
  },
  add_title__: {
    marginTop: 20,
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "left",
    color: "#f27d35",
    cursor: "pointer",
    width: "max-content"
  },
  list: {
    "& li": {
      padding: 0
    },
    "& .MuiListItem-gutters": {
      borderBottom: "1px solid #f3f3f3",
    }
  },
  listWithOutBorderBottok: {
    "& li": {
      padding: 0
    },
    // "& .MuiListItem-gutters": {
    //   borderBottom: "1px solid #f3f3f3",
    // }
  },
  dividerBackground: {
    width: "100%",
    marginTop: 8, backgroundColor: theme.palette.secondary.light,
  },
  arr: {
    marginBottom: -6,
    fontSize: 20
  },
  listTextSpacing: {
    marginBottom: theme.spacing(1)
  }
});

class DetailsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slice_start: 0,
      slice_end: 2,
      slice_start_e: 0,
      slice_end_e: 2
    };
  }
  dateformat = (st, snd) => {
    const b11 = moment(st).format("YYYY").split("/")[0];
    const b21 = moment(snd).format("YYYY").split("/")[0];
    const ck = JSON.parse(b21) - JSON.parse(b11)
    const cks = ck > 100
    if (cks) {
      const a = moment(st).format("DD MMM, YYYY")
      const c = a + " - Present"
      return c
    } else {
      const a = moment(st).format("DD MMM, YYYY");
      const b = moment(snd).format("DD MMM, YYYY");
      const a1 = moment(st).format("YYYY");
      const a2 = moment(snd).format("YYYY");
      const a3 = JSON.parse(a2) - JSON.parse(a1)
      const b1 = moment(st).format("l").split("/")[0];
      const b2 = moment(snd).format("l").split("/")[0];
      const b3 = JSON.parse(b2) - JSON.parse(b1)
      const c = a + " - " + b + " • " + a3 + " yr " + b3 + " mos ";
      return c
    }
  }
  dateformat1 = (st, snd) => {
    const b11 = moment(st).format("YYYY").split("/")[0];
    const b21 = moment(snd).format("YYYY").split("/")[0];
    const ck = JSON.parse(b21) - JSON.parse(b11)
    const cks = ck > 100
    if (cks) {
      const a = moment(st).format("DD MMM, YYYY")
      const c = a + " - No Expiration date"
      return c
    } else {
      const a = moment(st).format("DD MMM, YYYY");
      const b = moment(snd).format("DD MMM, YYYY");
      const a1 = moment(st).format("YYYY");
      const a2 = moment(snd).format("YYYY");
      const a3 = JSON.parse(a2) - JSON.parse(a1)
      const b1 = moment(st).format("l").split("/")[0];
      const b2 = moment(snd).format("l").split("/")[0];
      const b3 = JSON.parse(b2) - JSON.parse(b1)
      const c = a + " - " + b + " • " + a3 + " yr " + b3 + " mos ";
      return c
    }
  }
  handleoffset = (end) => {
    if (end === this.state.slice_end) {
      return this.setState({
        ...this.state,
        slice_start: 0,
        slice_end: 2
      })
    } else {
      this.setState({
        ...this.state,
        slice_start: 0,
        slice_end: end
      })
    }
  }
  handleoffset_e = (end) => {
    if (end === this.state.slice_end_e) {
      return this.setState({
        ...this.state,
        slice_start_e: 0,
        slice_end_e: 2
      })
    } else {
      this.setState({
        ...this.state,
        slice_start_e: 0,
        slice_end_e: end
      })
    }

  }
  render() {
    const { classes, data, loading, isBottomRounded, cardIsNotBottom } = this.props;
    let { slice_end, slice_start_e, slice_start, slice_end_e } = this.state;

    return (
      <Paper
        className={
          `${isBottomRounded
            ? classes.bottomRounded + " " + classes.paperCard
            : classes.paperCard} ${cardIsNotBottom ? classes.isNotBottom : ''}`
        }
      >
        <div style={{ padding: "25px 25px 25px 32px" }}>
          <Grid container direction="row" spacing={4}>
            <Grid item md={6} sm={6} xs={12}>
              <HybridTypography
                icon={ICONS.CLIENTPHONE("#28a9c1")}
                text={data?.mobile_no_isd_code?.toString()?.includes("+") ? data?.mobile_no_isd_code : `+${data?.mobile_no_isd_code} ${data?.mobile_no}`}
                loading={loading}
                header="MOBILE"
              // islistbottom={true}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <HybridTypography
                icon={ICONS.EMAIL("#28a9c1")}
                text={data?.email}
                header="EMAIL ADDRESS"
                loading={loading}
              // islistbottom={true}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>

              <HybridTypography
                icon={ICONS.LOCATION("#28a9c1")}
                loading={loading}
                text={customAddress(data?.addressLine1, data?.addressLine2, data?.area, data?.state, data?.country, data?.postalCode)}
                header="ADDRESS"
              // islistbottom={true}
              /> 
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <HybridTypography
                icon={ICONS.SKILL("#28a9c1")}
                list={data?.skills?.map((_) => _.label)}
                loading={loading}
                header="SKILLS"
              />
            </Grid>
          </Grid>
        </div>
        <Divider className={classes.dividerBackground} />
        <div style={{ padding: "25px 25px 25px 32px" }}>
          <Grid container direction="row" spacing={4}>
            {/* License Name */}
            <Grid item md={6} sm={6} xs={12}>
              <HybridTypography
                icon={ICONS.CLIENTID("#28a9c1")}
                loading={loading}
                text={data?.license_desc}
                header="LICENSE NAME"
              // islistbottom={true}
              />
            </Grid>

            {/* License Number */}
            <Grid item md={6} sm={6} xs={12}>
              <HybridTypography
                icon={ICONS.CLIENTID("#28a9c1")}
                loading={loading}
                text={data?.license_no}
                header="LICENSE NUMBER"
              // islistbottom={true}
              />
            </Grid>

            {/* Specialization */}
            <Grid item md={6} sm={6} xs={12}>
              <HybridTypography
                icon={ICONS.INTELLIGENCE("#28a9c1")}
                list={data?.specialization?.map((_) => _.label)}
                loading={loading}
                header="SPECIALIZATION"
              />
            </Grid>
          </Grid>
        </div>
        {/* <Grid item md={6} sm={6} xs={12}>
            <HybridTypography
              icon={ICONS.SKILL("#28a9c1")}
              list={data?.certification?.map((_) => _.label)}
              loading={loading}
              header="CERTIFICATION"
            />
          </Grid> */}

        <Divider className={classes.dividerBackground} />

        <div style={{ padding: "25px 25px 25px 32px" }}>
          <Grid container direction="row" spacing={4}>
            <div style={{ padding: "20px", width: "100%" }}>
              <Typography className={classes.title__}>{"Certification"}</Typography>
              {data?.certification?.slice(slice_start, slice_end).map((_list,key) => {
                return <Grid container direction="row" spacing={4} xs={12}>
                  <Grid item>
                    {/* mapping avatar */}
                    <div className={classes.avatar__}>
                      {/* src="/images/icons8-certificate-24.png" */}
                      <Avatar style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 60, width: 60 }}>
                        <CertificateIcon style={{ height: 24, width: 24 }} />
                      </Avatar>
                    </div>
                    {/* mapping avatar end*/}
                  </Grid>
                  <Grid item xs>
                    {/* mapping details */}
                    <List className={data?.certification?.length === (key+1) ? classes.listWithOutBorderBottok : classes.list}>
                      <ListItem className={classes.listItem}>
                        <ListItemText classes={{ primary: classes.listTextSpacing }} primary={<span className={classes.title_}>{_list?.certificateName}</span>} secondary={<React.Fragment>
                          <span className={classes.sub_text}>{_list?.organizationName}</span>
                          <br />
                          <span className={classes.footer}>
                            {(_list?.startingFrom, _list?.endingIn) &&
                              <>
                                {this.dateformat1(_list?.startingFrom, _list?.endingIn)}</>}
                          </span>
                        </React.Fragment>} />
                      </ListItem>
                    </List>
                    {/* mapping details end */}
                  </Grid>
                </Grid>
              })}

              {data?.certification?.length === 0 && <Typography variant="body2" color="textSecondary" >{"No certificate added yet"}</Typography>}
              {/* Removed the show more section, if, the items length is less than or equal to 2 */}
              {slice_end > 2 &&
                <>
                  <Typography className={classes.add_title__} onClick={() => this.handleoffset(data?.certification?.length)}>
                    {slice_end > 2 ? <>Show Less Certificates <ExpandLess className={classes.arr} /></> :
                      <>Show More Certificates<ExpandMore className={classes.arr} /></>}
                  </Typography>
                </>
              }
            </div>
          </Grid>
        </div>

        <Divider className={classes.dividerBackground} />

        <div style={{ padding: "25px 25px 25px 32px" }}>
          <Grid container direction="row" spacing={4}>

            <div style={{ padding: "20px", width: "100%" }}>
              <Typography className={classes.title__}>{"Experience"}</Typography>
              {data?.experience?.slice(slice_start_e, slice_end_e).map((_list,key) => {
                return <Grid container direction="row" spacing={4} xs={12}>
                  <Grid item >
                    {/* mapping avatar */}
                    <div className={classes.avatar__}>
                      {/* src="/images/icons8-toolbox-24.png" */}
                      <Avatar style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 60, width: 60 }}>
                        <ExperienceIcon style={{ height: 24, width: 24 }} />
                      </Avatar>
                    </div>
                    {/* mapping avatar end*/}
                  </Grid>
                  <Grid item xs>
                    {/* mapping details */}
                    <List className={data?.experience?.length === (key+1) ? classes.listWithOutBorderBottok : classes.list}>
                      <ListItem className={classes.listItem}>
                        <ListItemText classes={{ primary: classes.listTextSpacing }} primary={<span className={classes.title_}>{_list?.organizationName}</span>} secondary={<React.Fragment>
                          <span className={classes.sub_text}>{_list?.roleName}</span>
                          <br />
                          <span className={classes.footer}>
                            {(_list?.startingFrom, _list?.endingIn) &&
                              <>
                                {this.dateformat(_list?.startingFrom, _list?.endingIn)}</>}
                          </span>
                        </React.Fragment>} />
                      </ListItem>
                    </List>
                    {/* mapping details end */}
                  </Grid>
                </Grid>
              })}

              {data?.experience?.length === 0 && <Typography variant="body2" color="textSecondary" >{"No experience added yet"}</Typography>}

              {/* Removed the show more section, if, the items length is less than or equal to 2 */}
              {slice_end_e > 2 &&
                <Typography className={classes.add_title__} onClick={() => this.handleoffset_e(data?.experience?.length)}>
                  <>
                    {(slice_end_e > 2) ? <>Show Less Experience <ExpandLess className={classes.arr} /></> :
                      <>Show More Experience<ExpandMore className={classes.arr} /></>}
                  </>
                </Typography>
              }
            </div>
          </Grid>
        </div>

        <Divider className={classes.dividerBackground} />

      </Paper >
    );
  }
}


export default withStyles(styles)(DetailsCard);

DetailsCard.propTypes = {
  /**Removes the bottom padding of 25px. Use it if you want to add an extra section below this component. */
  cardIsNotBottom: PropTypes.bool
}