import React from 'react';
import { withAlertAndDialog, } from '../../../../HOCs';
import { Calender } from '../../../../components';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import { SpeedDial } from '@material-ui/lab';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Clock from '../../../../assets/AddEditScreenIcons/clock';
import { CalenderEditIcon } from '../../../../assets';
import { withRouter } from 'react-router-dom';
import AddEditActivity from '../../../availability/calendar/addEditActivity/index';
import AddEditAvailability from '../../../availability/calendar/addEditAvailability/index';
import AvailabilityList from '../../../availability/calendar/availableList';
import ActivityList from '../../../availability/calendar/activityList';
import AddEditRescheduleActivity from '../../../availability/calendar/addEditActivity/activityReschedule'
import ActivityLog from '../../../availability/calendar/addEditActivity/activityLog'

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { routes } from '../../../../router/routes';

const styles = (theme) => ({
    root: {
        margin: '16px',
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04)",
        borderRadius: "4px",
        background: "#ffffff",
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        '& .MuiSpeedDialAction-staticTooltipLabel': {
            whiteSpace: 'nowrap',
            backgroundColor: 'black',
            color: 'white'
        },
        '& .MuiSpeedDialAction-fab': {
            background: theme.palette.ternary.main,
            "&:hover": {
                background: theme.palette.ternary.dark
            }
        },
        '& .MuiSpeedDial-fab': {
            background: theme.palette.ternary.main,
            "&:hover": {
                background: theme.palette.ternary.dark
            }
        }
    },
    fabIcon: {
        fill: 'white',
        fontSize: 10
    },
})

const fabId = {
    'ADD_ACTIVITY': 1,
    'ADD_AVAILABILITY': 2
}
class Schedule extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            variant: this.props?.match?.params?.variant ?? 'day',
            currendDate: moment(),
            selectedDate: moment(),
            hideShowFAB: false,
            id: this.props?.match?.params?.id,
            data: this.props.data[0]
        }
    }

    //Getting the calendar data
    componentDidMount() {
        this.getCalendarData();
        console.log(this.props.data)
        // this.getCareProviderInfo();
    }

    // getCareProviderInfo = async() => {
    //     try{
    //         // let _ = await this.props.GET_CARE_GIVER_INFO({id:this.props?.match?.params?.id})
    //         // console.log(await this.props.GET_CARE_GIVER_INFO({id:this.props?.match?.params?.id}))
    //     }
    //     catch(e){}
    // }

    reload = (selectedDate) => {
        if (selectedDate) {
            this.setState({
                selectedDate
            }, () => {
                this.getCalendarData();
                setTimeout(() => {
                    this.scrollToCurrentTime()
                }, 3000);
            })
        } else {
            this.getCalendarData();
        }
    }

    scrollToCurrentTime = () => {
        const currentTime = document.getElementById(this.state.selectedDate.format('hh-A').toString());

        if (currentTime) currentTime.scrollIntoView({ block: "center" })
    }


    //Getting the calendar data function
    getCalendarData = () => {

        const {
            variant,
        } = this.state;

        const id = this.props?.match?.params?.id;

        const {
            from_datetime,
            to_datetime
        } = this.giveMeFromToTime(variant);

        this.props.GET_ALL_CALENDER_ACTIVITIES({
            from_datetime,
            to_datetime,
            req_role: 'service_professional',
            timeline: variant,
            id,
        })
    }

    //Get the from and to time based on variant
    giveMeFromToTime = (variant) => {
        const {
            selectedDate,
        } = this.state;

        const id = this.props?.match?.params?.id;


        this.props.history.replace(routes.monitor_service_professionals_schedules_parent + id + "/" + variant);

        if (variant === 'day') {
            return {
                from_datetime: moment(selectedDate).startOf(variant),
                to_datetime: moment(selectedDate).endOf(variant)
            }
        } else if (variant === 'week') {
            return {
                from_datetime: moment(selectedDate).startOf(variant).add(1, 'day'),
                to_datetime: moment(selectedDate).endOf(variant)
            }
        } else if (variant === 'month') {
            return {
                from_datetime: moment(selectedDate).startOf(variant),
                to_datetime: moment(selectedDate).endOf(variant)
            }
        } else {
            return {
                from_datetime: moment(selectedDate).startOf(variant),
                to_datetime: moment(selectedDate).endOf(variant)
            }
        }
    }

    //Generice function to change the state which gets key and value
    onChange = (key, value, hitAPI) => {
        this.setState({
            [key]: value
        }, () => {
            if (hitAPI) {
                this.getCalendarData();
            }
        })
    }

    //On Next/Prev month clicked
    onNextPrevMonthBtnClicked = (isNext) => {
        let { selectedDate } = this.state;
        if (isNext) {
            selectedDate.add(1, 'M').startOf('M');
        } else {
            selectedDate.subtract(1, 'M').startOf('M')
        }

        this.setState({
            selectedDate
        }, () => {
            this.getCalendarData()
        })
    }

    //On Availability card clicked
    onAvailabilityClicked = (currentData) => {
        const { data, ...props } = this.props
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: <AvailabilityList
                {...props}
                reload={this.reload}
                data={currentData}
                drawer={this.props.drawer}
                onEditBtnClicked={this.onEditBtnClicked}
                onAvailabilityClicked={this.onAvailabilityClicked}
            />
        })
    }

    //On Un Availability card clicked
    onUnAvailabilityClicked = (currentData) => {
        const { data, ...props } = this.props
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: <AvailabilityList
                isUnavailable={true}
                {...props}
                reload={this.reload}
                data={currentData}
                drawer={this.props.drawer}
                onEditBtnClicked={this.onEditBtnClicked}
                onUnAvailabilityClicked={this.onUnAvailabilityClicked}
            />
        })
    }

    //On Activity card clicked
    onActivityClicked = (currentData) => {
        // console.log("INSIDE \n")
        const { data, ...props } = this.props //Extracting all props except data. Cause we are using our own data(currentData).
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: <ActivityList
                {...props}
                data={currentData}
                onEditBtnClicked={this.onEditBtnClicked}
                onActivityClicked={this.onActivityClicked}
                onRescheduleBtnClicked={this.onRescheduleBtnClicked}
                activityLogBtnClicked={this.activityLogBtnClicked}
                />
        })
    }

    onRescheduleBtnClicked = (selectedFabId, data) => {
        if (selectedFabId === fabId.ADD_ACTIVITY) {
           
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                // component: <AddEditActivity {...this.props} data={data} reload={this.reload} isEdit={true} id={data?.activity_id} field_id={"EditActivity"}/>
                component: <AddEditRescheduleActivity {...this.props} isReschedule={true} data={data} reload={this.reload} isEdit={true} id={data?.activity_id} field_id={"EditActivity"}/>
            })
        } else if (selectedFabId === fabId.ADD_AVAILABILITY) {
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                component: <AddEditAvailability {...this.props} data={data} isReschedule={true} reload={this.reload} isEdit={true} id={data?.availability_id} field_id={"EditAvailability"}/>
            })
        }
    }

    activityLogBtnClicked = (selectedFabId, data) => {
        if (selectedFabId === fabId.ADD_ACTIVITY) {
           
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                //component: <AddEditActivity reload={props.getClientActivities} data={data} isEdit={true} id={data?.activity_id} />
                component: <ActivityLog id={data?.activity_id} {...this.props}/>
            })
        } else if (selectedFabId === fabId.ADD_AVAILABILITY) {
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                component: <AddEditAvailability reload={this.reload} data={data} isEdit={true} id={data?.availability_id} />
            })
        }
    }

    //On Edit Activity/Availability
    onEditBtnClicked = (id, data) => {
        if (id === fabId.ADD_ACTIVITY) {
            console.log("EDI")
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                component: <AddEditActivity {...this.props}
                    data={data}
                    reload={this.reload}
                    serviceProfessionalID={this.state.id}
                    isEdit={true}
                    id={data?.activity_id}
                    redirectFrom={routes.monitor_service_professionals}
                />
            })
        } else if (id === fabId.ADD_AVAILABILITY) {
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                component: <AddEditAvailability {...this.props}
                    data={data}
                    reload={this.reload}
                    serviceProfessionalID={this.state.id}
                    isEdit={true}
                    id={data?.availability_id}
                    redirectFrom={routes.monitor_service_professionals}
                />
            })
        }
    }

    //On Fab Icon clicked
    onFabIconClicked = (action) => {
        if (action.id === fabId.ADD_ACTIVITY) {
            console.log("INSIDE \n")
            console.log(this.state)
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                component: <AddEditActivity {...this.props}
                    reload={this.reload}
                    serviceProfessionalID={this.state.id}
                    data = {{name: this.state.data.resourceName}}
                    redirectFrom={routes.monitor_service_professionals}
                />
            })
        } else if (action.id === fabId.ADD_AVAILABILITY) {
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                component: <AddEditAvailability {...this.props}
                    reload={this.reload}
                    serviceProfessionalID={this.state.id}
                    data = {{name: this.state.data.resourceName}}
                    redirectFrom={routes.monitor_service_professionals}
                />
            })
        }
    }

    render() {

        const {
            classes
        } = this.props;

        const actions = [
            { id: fabId.ADD_ACTIVITY, icon: <CalenderEditIcon className={classes.fabIcon} width="20px" height="20px" />, name: 'Add Activity', button_id: "addActivityButtonFromMonitoringServiceProfessionalSchedules" },
            { id: fabId.ADD_AVAILABILITY, icon: <Clock className={classes.fabIcon} width="20px" height="20px" />, name: 'Mark Unavailability', button_id: "addVailabilityButtonFromMonitoringServiceProfessionalSchedules" }
        ];

        const {
            currendDate,
            selectedDate,
            category,
            serviceProfessional,
            variant,
            hideShowFAB
        } = this.state;

        let { data, loading, error } = this.props?.getAllActivities;

        return <div className={classes.root}>
            <Calender
                calendarType="schedules"
                from_id={"ViewSchedulesFromProfessionalMonitoring"}
                currentDate={currendDate}
                selectedDate={selectedDate}
                onDateSelected={(e) => this.onChange('selectedDate', e, true)}

                category={category}
                serviceProfessional={serviceProfessional}

                variant={variant}
                onVariantChange={(e) => this.onChange('variant', e.target.value, true)}

                onCategorySelected={(e) => this.onChange('category', e, true)}
                onServieProfessionalSelected={(e) => this.onChange('serviceProfessional', e, true)}

                onNextClicked={() => this.onNextPrevMonthBtnClicked(true)}
                onPrevClicked={() => this.onNextPrevMonthBtnClicked(false)}

                data={data}
                loading={loading}
                error={error}
                onRetry={this.getCalendarData}

                onActivityClicked={this.onActivityClicked}
                onAvailabilityClicked={this.onAvailabilityClicked}
                onUnAvailabilityClicked={this.onUnAvailabilityClicked}

                hideFilters={true}
            />
            <SpeedDial
                ariaLabel="Add Activity/Available button"
                className={classes.speedDial}
                icon={<SpeedDialIcon />}
                id={"addActivityAvailabilityFromMonitoringServiceProfessionalSchedules"}
                onClose={() => this.onChange('hideShowFAB', false)}
                onOpen={() => this.onChange('hideShowFAB', true)}
                open={hideShowFAB}
                onClick={() => this.onChange('hideShowFAB', true)}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        // id={action.button_id}
                        tooltipTitle={action.name}
                        tooltipOpen
                        FabProps={{
                            color: "primary",
                            size: "large",
                            id:action.button_id
                        }}
                        onClick={() => this.onFabIconClicked(action)}
                    />
                ))}
            </SpeedDial>
        </div>
    }
}

const mapStateToProps = (state) => ({
    getAllActivities: state?.cg_cc_calender?.getAllActivities,
    careGivers: state.careGiver,
});

export default connect(mapStateToProps, actions)(withStyles(styles, {withTheme: true})(
    withAlertAndDialog(
        withRouter(
            Schedule
        )
    )
)
);