import React from "react";
import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { InfoWrapper } from "./infoWrapper";
import { InfoCTA } from "./infoCta";
import { MobileViewInfoCtas } from "./mobileViewInfoCtas";

// const ctas = [
//   {
//     label: "Resend",
//     onClickHandler: () => {},
//     additionalProps: {
//       variant: "outlined",
//     },
//   },
//   {
//     label: "Deactivate",
//     onClickHandler: () => {},
//     additionalProps: {
//       variant: "outlined",
//     },
//   },
//   {
//     label: "Edit Details",
//     onClickHandler: () => {},
//     additionalProps: {
//       variant: "contained",
//     },
//   },
// ];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 12,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    background: "white",
    borderBottom: "1px solid #f3f3f3",
  },
}));

export function Information({ details, ctas, showctas = false, ...props }) {
  const classes = useStyles();
  const theme = useTheme();

  // Breakpoints helper
  const isXsAndDown = useMediaQuery(theme.breakpoints.down("xs"));
  const is1080AndDown = useMediaQuery(theme.breakpoints.down(1080));

  const whenToWrapCTA =
    ctas?.filter(
      (cta) =>
        cta?.additionalProps?.display ||
        cta?.additionalProps?.display === undefined
    )?.length > 2
      ? is1080AndDown
      : isXsAndDown;

  return (
    <div className={classes.root}>
      <Grid
        container
        style={{ gap: isXsAndDown ? theme.spacing(0.5) : theme.spacing(1.5) }}
      >
        <Grid
          container
          item
          xs={12}
          sm
          alignItems={"center"}
          wrap="nowrap"
          zeroMinWidth
        >
          <InfoWrapper
            details={Array.isArray(details) ? details?.[0] : details}
            isCategoryAChip={props.isCategoryAChip}
            showTrialExpiry={props.showTrialExpiry}
            createdAt={props.createdAt}
            activation={props.activation}
            loading={props.loading}
          />
        </Grid>
        {!showctas ? <>
        {!whenToWrapCTA && (
          <Grid item>
            <InfoCTA ctas={ctas}/>
          </Grid>
        )}
        {whenToWrapCTA && (
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={is1080AndDown ? 12 : true}
            alignItems={"center"}
            wrap="nowrap"
            zeroMinWidth
          >
            <MobileViewInfoCtas ctas={ctas}/>
          </Grid>
        )}
        </> : <></> }
      </Grid>
    </div>
  );
}
