import { routes } from "./routes";
import { user_roles } from "../utils";

export const managementRedirection = (user_role) => {
  switch (user_role) {
    case user_roles.super_admin:
      return routes.manage_care_providers;

    case user_roles.provider:
      return routes.manage_care_coordinators;

    case user_roles.supervisor:
      return routes.manage_care_coordinators;

    case user_roles.service_coordinator:
      return routes.manage_care_givers;
    case user_roles.help_desk:
      return routes.sales_operation;

    default:
      return "";
  }
};

export const loginSuccess = (role) => {
  switch (role) {
    case user_roles.super_admin:
      return routes.dashboard;

    case user_roles.provider:
      return routes.dashboard;

    case user_roles.supervisor:
      return routes.dashboard;

    case user_roles.service_coordinator:
      return routes.dashboard;

    case user_roles.help_desk:
      return routes.sales_operation;

    case user_roles.business_admin:
        return routes.dashboard;

    default:
      return "";
  }
};

export const access = (role, path) => {
  switch (role) {
    case user_roles.super_admin: //Managed Care (Super Admin)
      return [...Object.values(routes)].indexOf(path);

    case user_roles.provider: //Provider
      return [
        routes.dashboard,

        routes.management,

        //1. User Management routes
        routes.user_management,

        //1.a Care Provider routes
        routes.manage_care_providers,
        routes.add_care_providers,
        routes.edit_care_providers,
        routes.view_care_providers,

        //1.b Care Giver routes
        routes.manage_care_givers,
        routes.add_care_givers,
        routes.edit_care_givers,
        routes.view_care_givers,

        //1.c Care Coordinator routes
        routes.manage_care_coordinators,
        routes.add_care_coordinators,
        routes.edit_care_coordinators,
        routes.view_care_coordinators,

        //1.d Client routes
        routes.manage_clients,
        routes.add_clients,
        routes.edit_clients,
        routes.view_clients,

        routes.resource_management,
        routes.resource_management_add,
        routes.resource_management_edit,
        routes.resource_management_detail,

        //2. Product Management
        routes.product_management,

        //2.a Service routes
        routes.manage_service,
        routes.add_service,
        routes.edit_service,
        routes.view_service,

        //2.b Package routes
        routes.manage_package,
        routes.add_package,
        routes.edit_package,
        routes.view_package,

        //2.a Products routes
        routes.manage_product,
        routes.add_product,
        routes.edit_product,
        routes.view_product,

        //2.d Promotional Banner routes
        routes.manage_promotional_banner,
        routes.add_promotional_banner,
        routes.edit_promotional_banner,
        routes.view_promotional_banner,

        //3. Availability
        routes.availability_calendar,
        routes.availability_calendar_main,
        routes.manage_availability,
        routes.add_exception,
        routes.edit_exception,
        routes.set_availability,
        routes.edit_availability,
        routes.view_availabiliy_history,

        //Monitoring routes
        routes.monitoring,

        //1. Service Professionals routes
        routes.monitor_service_professionals,

        //1.a Service Professional Summary
        routes.monitor_service_professionals_summary,
        routes.monitor_service_professionals_summary_parent,

        //1.b Service Professional Schedules
        routes.monitor_service_professionals_schedules,
        routes.monitor_service_professionals_schedules_parent,

        //1.c Service Professional Tracking
        routes.monitor_service_professionals_tracking,
        routes.monitor_service_professionals_tracking_parent,

        //1.d Service Professional Clients
        routes.monitor_service_professionals_clients,
        routes.monitor_service_professionals_clients_parent,

        //1.e Service Professional Feebacks
        routes.monitor_service_professionals_feebacks,
        routes.monitor_service_professionals_feebacks_parent,

        //2. Clients routes
        routes.monitor_clients,

        //2.a Details routes
        routes.monitor_client_details,
        routes.monitor_client_detail_parent,

        //2.b Report routes
        routes.monitor_client_reports,
        routes.monitor_client_report_parent,

        //2.c Issues routes
        routes.monitor_client_issues,
        routes.monitor_client_issue_parent,
        routes.monitor_client_issue_detail,

        //2.d Recommendation routes
        routes.monitor_client_recommendation,
        routes.monitor_client_recommendation_parent,
        routes.monitor_client_recommended_products,
        routes.monitor_client_recommended_product_tracking,

        //2.e Activities routes
        routes.monitor_client_activities,
        routes.monitor_client_activitie_parent,

        //2.f Rating routes
        routes.monitor_client_ratings,
        routes.monitor_client_rating_parent,

        //3. Subscription
        routes.monitor_subscription,
        routes.monitor_subscription_detail,
      ].indexOf(path);

    case user_roles.supervisor: //Supervisor
      return [
        routes.dashboard,

        routes.management,

        //1. User Management routes
        routes.user_management,

        //1.a Care Provider routes
        // routes.manage_care_providers,
        // routes.add_care_providers,
        // routes.edit_care_providers,
        // routes.view_care_providers,

        //1.b Care Giver routes
        routes.manage_care_givers,
        routes.add_care_givers,
        routes.edit_care_givers,
        routes.view_care_givers,

        //1.c Care Coordinator routes
        routes.manage_care_coordinators,
        routes.add_care_coordinators,
        routes.edit_care_coordinators,
        routes.view_care_coordinators,

        //1.d Client routes
        routes.manage_clients,
        routes.add_clients,
        routes.edit_clients,
        routes.view_clients,

        //2. Product Management
        routes.product_management,

        //2.a Service routes
        routes.manage_service,
        routes.add_service,
        routes.edit_service,
        routes.view_service,

        //2.b Package routes
        routes.manage_package,
        routes.add_package,
        routes.edit_package,
        routes.view_package,

        //2.a Products routes
        routes.manage_product,
        routes.add_product,
        routes.edit_product,
        routes.view_product,

        //3. Availability
        routes.availability_calendar,
        routes.availability_calendar_main,

        //Monitoring routes
        routes.monitoring,

        //1. Service Professionals routes
        routes.monitor_service_professionals,

        //1.a Service Professional Summary
        routes.monitor_service_professionals_summary,
        routes.monitor_service_professionals_summary_parent,

        //1.b Service Professional Schedules
        routes.monitor_service_professionals_schedules,
        routes.monitor_service_professionals_schedules_parent,

        //1.c Service Professional Tracking
        routes.monitor_service_professionals_tracking,
        routes.monitor_service_professionals_tracking_parent,

        //1.d Service Professional Clients
        routes.monitor_service_professionals_clients,
        routes.monitor_service_professionals_clients_parent,

        //1.e Service Professional Feebacks
        routes.monitor_service_professionals_feebacks,
        routes.monitor_service_professionals_feebacks_parent,

        //2. Clients routes
        routes.monitor_clients,

        //2.a Details routes
        routes.monitor_client_details,
        routes.monitor_client_detail_parent,

        //2.b Report routes
        routes.monitor_client_reports,
        routes.monitor_client_report_parent,

        //2.c Issues routes
        routes.monitor_client_issues,
        routes.monitor_client_issue_parent,
        routes.monitor_client_issue_detail,

        //2.d Recommendation routes
        routes.monitor_client_recommendation,
        routes.monitor_client_recommendation_parent,
        routes.monitor_client_recommended_products,
        routes.monitor_client_recommended_product_tracking,

        //2.e Activities routes
        routes.monitor_client_activities,
        routes.monitor_client_activitie_parent,

        //2.f Rating routes
        routes.monitor_client_ratings,
        routes.monitor_client_rating_parent,

        //3. Subscription
        routes.monitor_subscription,
        routes.monitor_subscription_detail,
      ].indexOf(path);

    case user_roles.service_coordinator: //Service Coordinaotr
      return [
        routes.dashboard,

        routes.management,

        //1. User Management routes
        routes.user_management,

        //1.b Care Giver routes
        routes.manage_care_givers,
        routes.add_care_givers,
        routes.edit_care_givers,
        routes.view_care_givers,

        //1.d Client routes
        routes.manage_clients,
        routes.add_clients,
        routes.edit_clients,
        routes.view_clients,

        //2. Product Management
        routes.product_management,

        //2.a Service routes
        routes.manage_service,
        routes.add_service,
        routes.edit_service,
        routes.view_service,

        //2.b Package routes
        routes.manage_package,
        routes.add_package,
        routes.edit_package,
        routes.view_package,

        //2.a Products routes
        routes.manage_product,
        routes.add_product,
        routes.edit_product,
        routes.view_product,

        //3. Availability
        routes.availability_calendar,
        routes.availability_calendar_main,

        //Monitoring routes
        routes.monitoring,

        //1. Service Professionals routes
        routes.monitor_service_professionals,

        //1.a Service Professional Summary
        routes.monitor_service_professionals_summary,
        routes.monitor_service_professionals_summary_parent,

        //1.b Service Professional Schedules
        routes.monitor_service_professionals_schedules,
        routes.monitor_service_professionals_schedules_parent,

        //1.c Service Professional Tracking
        routes.monitor_service_professionals_tracking,
        routes.monitor_service_professionals_tracking_parent,

        //1.d Service Professional Clients
        routes.monitor_service_professionals_clients,
        routes.monitor_service_professionals_clients_parent,

        //1.e Service Professional Feebacks
        routes.monitor_service_professionals_feebacks,
        routes.monitor_service_professionals_feebacks_parent,

        //2. Clients routes
        routes.monitor_clients,

        //2.a Details routes
        routes.monitor_client_details,
        routes.monitor_client_detail_parent,

        //2.b Report routes
        routes.monitor_client_reports,
        routes.monitor_client_report_parent,

        //2.c Issues routes
        routes.monitor_client_issues,
        routes.monitor_client_issue_parent,
        routes.monitor_client_issue_detail,

        //2.d Recommendation routes
        routes.monitor_client_recommendation,
        routes.monitor_client_recommendation_parent,
        routes.monitor_client_recommended_products,
        routes.monitor_client_recommended_product_tracking,

        //2.e Activities routes
        routes.monitor_client_activities,
        routes.monitor_client_activitie_parent,

        //2.f Rating routes
        routes.monitor_client_ratings,
        routes.monitor_client_rating_parent,

        //3. Subscription
        routes.monitor_subscription,
        routes.monitor_subscription_detail,
      ].indexOf(path);

    case user_roles.help_desk:
      return [
        routes.sales_operation,
        routes.enquiry_list,
        routes.add_enquiry,
        routes.edit_enquiry,
        routes.view_enquiry,
         //2.a Service routes
         routes.manage_service,
         
         routes.view_service,
 
         //2.b Package routes
         routes.manage_package,
         
         routes.view_package,
 
         //2.a Products routes
         routes.manage_product,
         
         routes.view_product,
      ].indexOf(path);

    case user_roles.business_admin:
      return [...Object.values(routes)].indexOf(path);

    default:
      return false;
  }
};
