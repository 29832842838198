import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: `0px 0.5px 2px 1px #e0e0e0d6`,
    padding: 16,
    backgroundColor: "white",
    borderRadius: 8,
  },
  centerTheLoaders: {
    margin: 'auto'
  },
  leaveSomeSpaceTop: {
    marginTop: 8
  }
}))

export const RecommendEquipmentLoading = (props) => {

  const classes = useStyles();

  return <div className={classes.root}>
    <Grid container direction="column">
      <Grid item xs={12}>
        <Skeleton variant="rect" width="150px" height="150px" className={classes.centerTheLoaders} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" width="150px" className={`${classes.centerTheLoaders} + ${classes.leaveSomeSpaceTop}`} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" width="120px" className={`${classes.centerTheLoaders} + ${classes.leaveSomeSpaceTop}`} />
      </Grid>
    </Grid>
  </div>
}
