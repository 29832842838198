import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    padding: theme.spacing(4),
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    borderBottom: `2px solid ${theme.palette.secondary.light}`
  },
  flexRightSide: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
}))

export const TrackerError = (props) => {

  const classes = useStyles();

  return <>
    <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h6" color="textSecondary" align="center">{"Something went wrong!"}</Typography>
        <Typography variant="body2" color="textSecondary" align="center">{"Unable to Fetch"}</Typography>
      </Grid>
    </Grid>
  </>
}
