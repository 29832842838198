import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { BoldIcon,ItalicIcon,UnderLineIcon,LeftAlignIcon,CenterAlignIcon,RightAlignIcon } from '../svg/text';
import { ColorCodes } from './colorCode';
import { IconText } from './iconText';
import PropTypes from 'prop-types';
import { createTestProps } from '../../../../../../../utils/common';

const styles = makeStyles((theme)=>({
    flexRow:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexWrap:'wrap'
    },
    divider:{
         height: 1,
         backgroundColor: '#707070',
         opacity: 0.35,
         marginBlock:20
    },
}))

export const TextMenu = (props) =>{

    const {
        handleTextSubMenu=null,
        activeObjectValues={}
    } = props;

    const classes = styles(props);

    const FORMATTING_LIST = [
        {
            icon:(color)=><BoldIcon color={color} />,
            text:'Bold'
        },
        {
            icon:(color)=><ItalicIcon color={color} />,
            text:'Italic'
        },
        {
            icon:(color)=><UnderLineIcon color={color} />,
            text:'Underline'
        },
    ];

    const ALIGN_LIST = [
        {
            icon:(color)=><LeftAlignIcon color={color} />,
            text:'Left'
        },
        {
            icon:(color)=><CenterAlignIcon color={color} />,
            text:'Center'
        },
        {
            icon:(color)=><RightAlignIcon color={color} />,
            text:'Right'
        },
    ];

    const returnActiveStyle = (val) =>{
        if(activeObjectValues && Object.keys(activeObjectValues)?.length){
            let key;
            if(val === "Bold"){
                key = 'fontWeight';
            }
            else if(val === "Italic"){
                key = 'fontStyle';
            }
            else if(val === "Underline"){
                key = 'textDecoration';
            }
            else if(val === "Left" || val === "Center" || val === "Right"){
                key = 'textAlign';
            }
            return activeObjectValues?.[key]?.toLowerCase() === val?.toLowerCase()  ? '#0063e7' : '#3b3f5b'
        }
        return '#3b3f5b'
    }


    return(
        <div>
            <div className={classes.flexRow}>
               {FORMATTING_LIST?.map((_,i)=>(
                   <div style={{
                    //    marginRight:i < FORMATTING_LIST.length - 1 ? 25 : 0
                    flex:1,
                    padding:5
                   }}
                   key={_?.text}
                   >
                        <IconText 
                            icon={_?.icon(returnActiveStyle(_?.text))}
                            text={_?.text}
                            handleClick={()=>handleTextSubMenu(_?.text)}
                            {...createTestProps(`textMenu${_?.text}`)}
                        />
                   </div>
               ))}
            </div>
            <div className={classes.divider}></div>
            <div className={classes.flexRow}>
               {ALIGN_LIST?.map((_,i)=>(
                   <div style={{
                    //    marginRight:i < ALIGN_LIST.length - 1 ? 25 : 0
                    flex:1,
                    padding:5
                   }}
                   key={_?.text}
                   >
                        <IconText 
                            icon={_?.icon(returnActiveStyle(_?.text))}
                            text={_?.text}
                            handleClick={()=>handleTextSubMenu(_?.text)}
                            {...createTestProps(`textMenu${_?.text}`)}
                        />
                   </div>
               ))}
            </div>
            <div className={classes.divider}></div>
            <div className={classes.flexRow}>
                <ColorCodes 
                    text="Color"
                    value={activeObjectValues?.fill}
                    handleChange={(value)=>handleTextSubMenu(value,'fill')}
                    {...createTestProps(`text menu color code`)}
                />
            </div>
        </div>
    )
}

TextMenu.propTypes={
    handleTextSubMenu:PropTypes.func,
    activeObjectValues:PropTypes.object,
}