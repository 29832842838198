import React from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || theme.palette.primary.main,
  }),
}))

export const ClientIDIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
      style={props.style}
    >
      <path id="Identification_Documents" className={classes.cls1} d="M2 6v12a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V6a2.006 2.006 0 0 0-2-2H4a2 2 0 0 0-2 2zm7 2a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm-3 7.1c0-1 2-1.5 3-1.5s3 .5 3 1.5v.9H6zM18 11h-4V9h4zm0 4h-4v-2h4zm2 3H4V6h16z" data-name="Identification Documents" transform="translate(-2 -4)" />
    </SvgIcon>
  )
}

ClientIDIcon.propTypes = {
  htmlColor: PropTypes.string,
  style: PropTypes.object,
};
