import React from "react";
import AddEditActivityForm from "../../../../components/addEditForm";
import {
  converToReactSelect,
  formComponentTypes,
  alertProps,
  options,
  weekDays,
  user_roles,
  // timezoneOld as timezone
} from "../../../../utils";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import {
  InternatlServerError,
  LoadingSection,
  NotFoundSection,
} from "../../../../components";
import moment from "moment";
import ConflictList from "../conflictList";
import PropTypes from "prop-types";
import {
  allPersonaIds,
  resourceTypeName,
} from "../../../resourceManagement/resources/utils";
import { routes } from "../../../../router/routes";

class AddEditActivity extends React.Component {
  constructor(props) {
    super();
    this.state = {
      clientLoading: false,
      serviceprofessionalLoading: false,
      activityLoading: false,
      cliendId: null,
      subscriptionId: null,
      from_timestamp: null,
      to_timestamp: null,
      dailyDate: null,
      selectedDate: moment(),
      isShowAvailabilities: false,
      isChecked: false,
      serviceProfessionalId: null,
      editactivity_serviceProfessionalId: null,
      editactivity_from_timestamp: null,
      editactivity_to_timestamp: null,
      subscriptionType: "",
      occurence_type: null,
      activityMode: null,
      resourceTypeCategoryId: null,
      partnerId: null,
      activity_mode: null,
      resetState: false,
    };
  }

  componentDidMount() {
    if (this.props.isEdit) {
      this.getActivityData();
    }
    if (this.props.persistState) {
      this.showConflictCardOnReturn();
    }
    if (this.props.parentState) {
      this.loadServices(this.props.parentState);
    }
    // if(this.props.data)
    // {
    //   this.loadServiceFromData(this.props.data)
    // }

    if (this.props?.auth_data?.user_role !== "super-admin") {
      this.props.GET_ALL_CLIENTS_LIST({
        pageNo: 0,
        pageSize: 25,
        clientName: "",
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    //Add Activity
    const { addActivity } = nextProps;
    const oldadd_client = this.props.addActivity;

    // Checking for Add Activity
    if (oldadd_client?.loading !== addActivity?.loading) {
      if (!addActivity.loading && !addActivity.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Activity Added successfully!",
          severity: alertProps.severity.success,
        });
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          open: false,
          component: <></>,
        });
        this.props.reload(this.state.selectedDate, true, false);
        // this.props.history.push(routes.manage_clients);
      } else if (addActivity.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: addActivity?.message
            ? addActivity?.message
            : "Something went wrong! Unable to Add activity.",
          severity: alertProps.severity.error,
        });
      }
    }

    //Update Activity
    const { updateActivity } = nextProps;
    const oldUpdateActivity = this.props.updateActivity;

    //Checking for update Activity messages
    if (oldUpdateActivity?.loading !== updateActivity?.loading) {
      if (!updateActivity.loading && !updateActivity.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Activity Updated successfully!",
          severity: alertProps.severity.success,
        });
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          open: false,
          component: <></>,
        });
        this.props.reload(this.state.selectedDate, true, false);
      } else if (updateActivity.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: updateActivity?.message
            ? updateActivity?.message
            : "Something went wrong! Unable to Update Activity.",
          severity: alertProps.severity.error,
        });
      }
    }

    //Get Activity
    const { editActivity } = nextProps;
    const oldeditActivity = this.props.editActivity;

    //Checking for get Activity messages
    if (oldeditActivity?.loading !== editActivity?.loading) {
      if (!editActivity.loading && !editActivity.error) {
        this.setState(
          {
            cliendId: editActivity?.data?.client_id,
            serviceProfessionalId:
              editActivity?.data?.service_professional_info?.value,
            from_timestamp: editActivity?.data?.from_time_stamp,
            to_timestamp: editActivity?.data?.to_time_stamp,
            editactivity_serviceProfessionalId:
              editActivity?.data?.service_professional_info?.value,
            editactivity_from_timestamp: editActivity?.data?.from_time_stamp,
            editactivity_to_timestamp: editActivity?.data?.to_time_stamp,
            subscriptionType: editActivity?.data?.subscription?.type,
          },
          () => {
            this.getClientId(
              converToReactSelect(
                [editActivity?.data?.client_info],
                "id",
                "client_name"
              )[0]
            );
          }
        );
      } else if (editActivity.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to get activity detail.",
          severity: alertProps.severity.error,
        });
      }
    }

    //Conflict API for unavailability
    const { activityConflict } = nextProps;
    const oldactivityConflict = this.props.activityConflict;
    //Checking for confilts on unAvailability messages
    if (oldactivityConflict?.loading !== activityConflict?.loading) {
      if (!activityConflict.loading && !activityConflict.error) {
        if (
          activityConflict?.data?.data?.Activities?.length > 0 ||
          activityConflict?.data?.data?.UnAvailability?.length > 0
        ) {
          this.setState({
            isShowAvailabilities: true,
            activityCount:
              activityConflict?.data?.data?.Activities?.length > 0
                ? activityConflict?.data?.data?.Activities?.length
                : 0,
            availabilityCount:
              activityConflict?.data?.data?.UnAvailability?.length > 0
                ? activityConflict?.data?.data?.UnAvailability?.length
                : 0,
          });
        } else {
          this.setState({
            isShowAvailabilities: false,
            activityCount: 0,
            availabilityCount: 0,
          });
        }
      } else if (activityConflict.error) {
        // this.props.alert.setSnack({
        //   open: true,
        //   horizontal: alertProps.horizontal.center,
        //   vertical: alertProps.vertical.top,
        //   msg: "Something went wrong! Unable to fetch conflicts on activity.",
        //   severity: alertProps.severity.error
        // });
      }
    }

    return true;
  }

  // USED TO SHOW THE CONFLICT CARD ON CLICKING BACK BUTTON FROM CONFLICT LIST
  showConflictCardOnReturn = () => {
    this.setState(
      {
        from_timestamp: this.props?.parentState?.from_timestamp,
        to_timestamp: this.props?.parentState?.to_timestamp,
        serviceProfessionalId: !this.props?.isEdit
          ? this.props?.parentState?.serviceprofessionalId?.id
          : this.props?.parentState?.serviceprofessionalId?.value,
      },
      () => this.checkForExistingActivities()
    );
  };

  getActivityData = async () => {
    let _ = await this.props.GET_ACTIVITY_SUMMARY({
      activityID: this?.props?.id,
    });

    // ----------------------------------------------------
    // Enable only on edit activity
    // this is used to load the drop downs for Clients, Subscriptions, services, Activity & Professional on Component did Mount
    // Without calling call back functions
    if (this.props.isEdit) {
      _ = _?.payload?.data;

      this.setState(
        { partnerId: _?.service_professional_info?.provider?.partnerId },
        () => {
          this.props.GET_ALL_CLIENTS_LIST({
            pageNo: 0,
            pageSize: 25,
            clientName: "",
            partnerId: _?.service_professional_info?.provider?.partnerId,
          });
        }
      );

      this.setState({ clientId: _.client_id, services: [] }, () => {
        this.props.GET_SUBSCRIPTION_BY_CLIENT_ID({
          clientId: _.client_id,
          partnerId: this.state.partnerId,
        });
      });

      this.setState(
        {
          subscriptionId: _?.subscription?.value,
          subscriptionType: _?.subscription?.type_id,
          // resourceTypeCategoryId: _?.subscription?.type_id
        },
        async () => {
          let newData = await this.props.SERVICES_BY_SUBSCRIPTION_ID({
            subscriptionId: _?.subscription?.value,
          });
          this.setState({
            resourceTypeCategoryId: newData?.payload?.[0]?.resourceTypeCategory,
          });
        }
      );

      this.setState({ from_timestamp: _.from_time_stamp }, () => {
        this.checkForExistingActivities();
        this.loadServiceProfessionalOptions_New();
      });

      this.setState({ to_timestamp: _?.to_time_stamp }, () => {
        this.checkForExistingActivities();
        this.loadServiceProfessionalOptions_New();
      });

      this.setState(
        {
          serviceId: _?.service?.value,
          // resourceTypeCategoryId: {value:_?.subscription?.type_id, label:_?.subscription?.type}
          // resourceTypeCategoryId: _?.subscription?.type_id
        },
        async () => {
          this.props.ACTIVITY_DROPDOWN_AND_SEARCH({
            pageNo: 25,
            pageSize: 10,
            careGiverName: "",
            subscriptionId: this.state.subscriptionId,
            service: { label: _?.service?.label, value: _?.service?.value },
            type: this.state.subscriptionType,
          });
          // console.log(this.state)
          this.loadServiceProfessionalOptions_New();
          let res_data = await this.props.GET_CLIENT_SUBSCRIPTION_DETAILS({
            clientId: this.state.clientId,
            id: this?.state?.subscriptionId,
          });
          res_data = res_data?.payload?.data?.services;
          // 
          let time = res_data?.map((x) => {
            if (x.id === _?.service?.value) {
              return x.duration;
            }
            return x;
          });
          this.setState({ duration: time && time[0] ? time[0] : 30 });
          // console.log(time[0])
        }
      );
    }
    // -----------------------------------------------
  };

  // getCareGiverList = () => {
  //   this.props.GET_ALL_CARE_GIVER({ ...this.state });
  // };

  getFromTimeStampPayload = (data) => {
    if (data?.recurrence === "DO NOT REPEAT") {
      if (data?.from_timestamp) {
        const date = moment(data.from_timestamp).set("second", 0);
        return new Date(date);
      }
    }
    if (data?.recurrence === "DAILY") {
      if (data?.daily_date_from && data?.daily_start_time) {
        const fromDate = moment(data?.daily_date_from);
        const startTime = moment(data?.daily_start_time);

        const finalfromDate = moment().set({
          year: fromDate.get("year"),
          month: fromDate.get("month"),
          date: fromDate.get("date"),
          hour: startTime.get("hour"),
          minute: startTime.get("minute"),
          second: 0,
          millisecond: 0,
        });
        return new Date(finalfromDate);
      }
    }
    if (data?.recurrence === "WEEKLY") {
      if (data?.weekly_date_from && data?.weekly_start_time) {
        const fromDate = moment(data?.weekly_date_from);
        const startTime = moment(data?.weekly_start_time);

        const finalfromDate = moment().set({
          year: fromDate.get("year"),
          month: fromDate.get("month"),
          date: fromDate.get("date"),
          hour: startTime.get("hour"),
          minute: startTime.get("minute"),
          second: 0,
          millisecond: 0,
        });
        return new Date(finalfromDate);
      }
    }
  };
  //  && data?.daily_date_to && data?.daily_end_time
  // const toDate = data?.daily_date_to
  // const endTime = data?.daily_end_time
  //
  getToTimeStampPayload = (data) => {
    if (data?.recurrence === "DO NOT REPEAT") {
      if (data?.to_timestamp) {
        const date = moment(data.to_timestamp).set("second", 0);
        return new Date(date);
      }
    }
    if (data?.recurrence === "DAILY") {
      if (data?.daily_date_to && data?.daily_end_time) {
        const toDate = moment(data?.daily_date_to);
        const endTime = moment(data?.daily_end_time);

        const finaltoDate = moment().set({
          year: toDate.get("year"),
          month: toDate.get("month"),
          date: toDate.get("date"),
          hour: endTime.get("hour"),
          minute: endTime.get("minute"),
          second: 0,
          millisecond: 0,
        });
        return new Date(finaltoDate);
      }
    }
    if (data?.recurrence === "WEEKLY") {
      if (data?.weekly_date_to && data?.weekly_end_time) {
        const toDate = moment(data?.weekly_date_to);
        const endTime = moment(data?.weekly_end_time);

        const finaltoDate = moment().set({
          year: toDate.get("year"),
          month: toDate.get("month"),
          date: toDate.get("date"),
          hour: endTime.get("hour"),
          minute: endTime.get("minute"),
          second: 0,
          millisecond: 0,
        });
        return new Date(finaltoDate);
      }
    }
  };

  getWeeklyTime = (data, fromOrTo) => {
    if (
      data?.weekly_date_range &&
      data?.weekly_start_time &&
      data?.weekly_end_time
    ) {
      const fromDate = moment(data?.weekly_date_range?.[0]);
      const toDate = moment(data?.weekly_date_range?.[1]);
      const startTime = moment(data?.weekly_start_time);
      const endTime = moment(data?.weekly_end_time);

      if (fromOrTo === "from") {
        const finalfromDate = moment().set({
          year: fromDate.get("year"),
          month: fromDate.get("month"),
          date: fromDate.get("date"),
          hour: startTime.get("hour"),
          minute: startTime.get("minute"),
          second: startTime.get("second"),
          millisecond: 0,
        });
        return new Date(finalfromDate);
      } else if (fromOrTo === "to") {
        const finaltoDate = moment().set({
          year: toDate.get("year"),
          month: toDate.get("month"),
          date: toDate.get("date"),
          hour: endTime.get("hour"),
          minute: endTime.get("minute"),
          second: endTime.get("second"),
          millisecond: 0,
        });
        return new Date(finaltoDate);
      }
    }
  };

  onSaveButtonClicked = async (isError, data, url) => {
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else if (this.state.isShowAvailabilities && !this.state.isChecked) {
      // if(this.state.isChecked){

      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, tick the check box",
        severity: alertProps.severity.warning,
      });
      // }
    } else {
      var now = moment(new Date(data.from_timestamp)); //todays date
      var end = moment(new Date(data.to_timestamp)); // another date
      var duration = moment.duration(end.diff(now));
      // console.log(Math.floor(duration.minutes()))

      if (moment(data.from_timestamp).isAfter(moment(data.to_timestamp))) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.right,
          vertical: alertProps.vertical.bottom,
          msg: "To Time, must be greater than from time.",
          severity: alertProps.severity.warning,
        });
        return false;
      } else if (duration?.days() === 0) {
        if (
          !Boolean(
            Math.floor(duration.hours()) > 0 ||
              Math.floor(duration.minutes()) > 29
          )
        ) {
          this.props.alert.setSnack({
            open: true,
            horizontal: alertProps.horizontal.center,
            vertical: alertProps.vertical.top,
            msg: "Activity duration must be atleaset 30 minutes.",
            severity: alertProps.severity.warning,
          });
          return false;
        }
      }

      //Todo: change daily_date_from state to from_timestamp : STATUS: COMPLETED
      this.setState(
        {
          // moment(data.from_timestamp)
          selectedDate: moment(this.getFromTimeStampPayload(data)).set(
            "second",
            "00"
          ),
        },
        () => {
          if (this.props.isEdit) {
            const payload = {
              activityId: this.props?.id,
              title: data?.title,
              cliendId: data?.cliendId.value,
              serviceprofessionalId:
                this.props?.redirectFrom !== undefined &&
                this.props?.redirectFrom ===
                  routes.monitor_service_professionals
                  ? this.props?.serviceProfessionalID
                  : data.serviceprofessionalId.value,
              activity_type_id: this.props.activity_template?.data,
              activityTypeName: data?.services?.label,
              // from_timestamp: moment(data.from_timestamp).set("second", "00"),
              // to_timestamp: moment(data.to_timestamp).set("second", "00"),
              resourceId: data?.serviceprofessionalId?.value,
              occurence: data?.recurrence
                ? data?.recurrence === "DO NOT REPEAT"
                  ? options.occurence.noRepeat
                  : options.occurence.repeat
                : options.occurence.noRepeat,
              occurence_type: data?.recurrence
                ? data?.recurrence === "DO NOT REPEAT"
                  ? null
                  : data?.recurrence
                : null,
              repeat_on: data?.recurrence
                ? data?.recurrence === "WEEKLY"
                  ? data?.repeat_on?.map((x) => x.label)
                  : []
                : [],
              from_timestamp: new Date(
                new Date(data?.from_timestamp)?.setSeconds(0)
              ),

              // this.getFromTimeStampPayload(data),

              to_timestamp: new Date(
                new Date(data?.to_timestamp)?.setSeconds(0)
              ),

              //  this.getToTimeStampPayload(data),

              // occurence_type: data?.occurence_type ? data?.occurence_type : "",
              mode: data?.activity_mode ? data?.activity_mode : "VIDEO_CALL",
              videoCallUrl: data?.meeting_link ? data?.meeting_link : "",
              description: data.description,
              selectedVenue: data?.venue,
              address: data?.add_location?.address
                ? data?.add_location?.address
                : null,
              longitude: data?.add_location?.longitude
                ? data?.add_location?.longitude?.toString()
                : null,
              latitude: data?.add_location?.latitude
                ? data?.add_location?.latitude?.toString()
                : null,
              subscriptionId: data?.subscriptions?.value
                ? data.subscriptions.value
                : "",
              subscriptionType: this.state?.subscriptionType
                ? this.state?.subscriptionType
                : "",
              serviceId: data?.services?.value ? data?.services?.value : "",
            };
            // console.log(
            //   " 🛺payload_EDITACTIVITY---------------------------- ",
            //   payload
            // );
            console.log("------------------------------------------\n");
            console.log(payload);
            this.props.EDIT_ACTIVITY({ ...payload });
          } else {
            const payload = {
              title: data?.title,
              cliendId: data?.cliendId.value,
              serviceprofessionalId:
                this.props?.redirectFrom !== undefined &&
                this.props?.redirectFrom ===
                  routes.monitor_service_professionals
                  ? this.props?.serviceProfessionalID
                  : data.serviceprofessionalId.value,
              activity_type_id: this.props.activity_template?.data,
              activityTypeName: data?.services?.label,

              occurence: data?.recurrence
                ? data?.recurrence === "DO NOT REPEAT"
                  ? options.occurence.noRepeat
                  : options.occurence.repeat
                : options.occurence.noRepeat,
              occurence_type: data?.recurrence
                ? data?.recurrence === "DO NOT REPEAT"
                  ? null
                  : data?.recurrence
                : null,
              repeat_on: data?.recurrence
                ? data?.recurrence === "WEEKLY"
                  ? data?.repeat_on?.map((x) => x.label)
                  : []
                : [],
              from_timestamp: this.getFromTimeStampPayload(data),
              // data?.recurrence !== "WEEKLY"
              //   ? this.getFromTimeStampPayload(data)
              //   : this.getWeeklyTime(data, "from"),
              to_timestamp: this.getToTimeStampPayload(data),
              // data?.recurrence !== "WEEKLY"
              //   ? this.getToTimeStampPayload(data)
              //   : this.getWeeklyTime(data, "to"),

              mode: data?.activity_mode ? data?.activity_mode : "VIDEO_CALL",
              videoCallUrl: data?.meeting_link ? data?.meeting_link : "",
              description: data.description,
              selectedVenue: data?.venue,
              address: data?.add_location?.address
                ? data?.add_location?.address
                : null,
              longitude: data?.add_location?.longitude
                ? data?.add_location?.longitude?.toString()
                : null,
              latitude: data?.add_location?.latitude
                ? data?.add_location?.latitude?.toString()
                : null,
              subscriptionId: data?.subscriptions?.value
                ? data.subscriptions.value
                : "",
              subscriptionType: this.state?.subscriptionType
                ? this.state?.subscriptionType
                : "",
              serviceId: data?.services?.value ? data?.services?.value : "",
              // from_timestamp: new Date(moment(data.from_timestamp).set("second", "00").utc()),
              // from_timestamp: "2021-01-11T10:34:49.396Z",
              // to_timestamp: new Date(moment(data.to_timestamp).set("second", "00")),
              // to_timestamp: "2021-01-25T10:40:49.396Z",
            };
            console.log("------------------------------------------\n");
            console.log(payload);
            this.props.CREATE_ACTIVITY({ ...payload });
          }
        }
      );
    }
  };

  onCancelButtonClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  // CALL BACKS

  getClientId = (data) => {
    this.setState({ clientId: data?.value, services: [] }, () => {
      this.props.GET_SUBSCRIPTION_BY_CLIENT_ID({
        clientId: this.state.clientId,
        partnerId: this.state?.partnerId,
      });
      this.props.GET_CLIENT_ADDRESS({
        clientId: this.state.clientId,
      });
    });
  };

  getPartnerId = (data) => {
    this.setState({ partnerId: data?.value }, () => {
      this.props.GET_ALL_CLIENTS_LIST({
        pageNo: 0,
        pageSize: 25,
        clientName: "",
        partnerId: data?.value,
      });
    });
  };

  getSubscriptionId = (data) => {
    this.setState(
      {
        subscriptionId: data?.value ? data?.value : [],
        subscriptionType: data?.type ? data?.type : [],
        // services:
        //   data?.type.label === "Service"
        //     ? [{ ...data }]
        //     : data?.services
        //     ? data.services
        //     : [],
      },
      () => {
        // alert("Select Service")
        // this.props.GET_CLIENT_SUBSCRIPTION_DETAILS({clientId: this.state.clientId, id:data?.value})
        this.props.GET_CLIENT_SUBSCRIPTION_DETAILS({
          clientId: this.state.clientId,
          id: data?.value,
        });
        // this.props.SERVICES_BY_SUBSCRIPTION_ID({ subscriptionId: data?.value });
      }
    );
  };

  handleCalBackDate = (date) => {
    this.setState({ from_timestamp: date }, () => {
      let to_time = moment(date).add(this.state.duration, "minutes").toDate();
      console.log(to_time);
      this.setState({ to_timestamp: to_time });
      this.checkForExistingActivities();
    });
  };

  handleCalBackMaxDate = (date) => {
    this.setState({ to_timestamp: date }, () => {
      this.checkForExistingActivities();
    });
  };

  onFromDateChange = (date) => {
    // 
    console.log(date);
    this.setState({ from_timestamp: date }, () => {
      console.log(this.state.duration);
      let to_time = moment(date).add(this.state.duration, "minutes").toDate();
      console.log(to_time);
      this.setState({ to_timestamp: to_time });
      this.checkForExistingActivities();
    });
  };

  onToDateChange = (date) => {
    this.setState({ to_timestamp: date }, () => {
      this.checkForExistingActivities();
    });
  };

  onDailyDateChange = (date) => {
    //TODO: Call Conflicts
    this.setState({ dailyDate: date });
  };

  // onTimechange = (time, key) => {
  //   this.setState({ [key]: time }, () => {
  //     this.checkForExistingActivities();
  //   });
  // };

  loadServiceProfessionalOptions_New = async (search, loadedOptions) => {
    
    if (
      this.state.resourceTypeCategoryId &&
      this.state.from_timestamp &&
      this.state.to_timestamp
    ) {
      // console.log("INSIDE")
      // alert(this.props?.auth_data?.partnerId)
      this.props.SERVICE_PROFESSIONAL_BY_RESOURCE_TYPE_CATEGORY({
        // pageNo: Math.round(loadedOptions.length / 10),
        // pageSize: 10,
        // careGiverName: search,
        // serviceId: this.state.serviceId,
        // status:"active",
        searchValue: "",
        resourceTypeId: allPersonaIds.service_professional,
        resourceTypeName: resourceTypeName.persona,
        resourceTypeCategoryId: this.state.resourceTypeCategoryId,
        fromTime: this.state.from_timestamp,
        toTime: this.state.to_timestamp,
        // partnerId: this.state.partnerId
        partnerId:
          user_roles.supervisor === this.props?.auth_data?.user_role
            ? this.props?.auth_data?.partnerId
            : this.state.partnerId,
      });
    }
  };

  loadServiceProfessionalOptions = async (search, loadedOptions) => {
    
    if (
      this.state.resourceTypeCategoryId &&
      this.state.from_timestamp &&
      this.state.to_timestamp
    ) {
      this.props.SERVICE_PROFESSIONAL_BY_RESOURCE_TYPE_CATEGORY({
        // pageNo: Math.round(loadedOptions.length / 10),
        // pageSize: 10,
        // careGiverName: search,
        // serviceId: this.state.serviceId,
        // status:"active",
        searchValue: "",
        resourceTypeId: allPersonaIds.service_professional,
        resourceTypeName: resourceTypeName.persona,
        resourceTypeCategoryId: this.state.resourceTypeCategoryId,
        fromTime: this.state.from_timestamp,
        toTime: this.state.to_timestamp,
        // partnerId: this.state.partnerId
        partnerId:
          // user_roles.supervisor === this.props?.auth_data?.user_role
          ((user_roles.supervisor === this.props?.auth_data?.user_role) || (user_roles.provider === this.props?.auth_data?.user_role) || (user_roles.service_coordinator === this.props?.auth_data?.user_role))
            ? this.props?.auth_data?.partnerId
            : typeof this.state.partnerId === "object" ?  this.state?.partnerId?.value : this.state?.partnerId,
      });
    }
  };

  // loadServiceModeOfActivity = async () => {}

  getServiceId = (data, addEditFormState) => {
    debugger
    // console.log(data)
    this.setState(
      {
        serviceId: data?.value,
        resourceTypeCategoryId: data?.resourceTypeCategoryId ,
      },
      async () => {
        this.props.ACTIVITY_DROPDOWN_AND_SEARCH({
          pageNo: 25,
          pageSize: 10,
          careGiverName: "",
          subscriptionId: this.state.subscriptionId,
          service: { label: data?.label, value: data?.value },
          type: this.state.subscriptionType,
        });
        this.loadServiceProfessionalOptions();
        let res_data = await this.props.GET_CLIENT_SUBSCRIPTION_DETAILS({
          clientId: this.state.clientId,
          id: this?.state?.subscriptionId,
        });
        res_data = res_data?.payload?.data?.services;
        
        console.log(res_data)
        let time = Array.isArray(res_data) ? res_data?.map((x) => {if(x?.id === data?.value) {
          return x?.duration
        }
      return x}) : []
        // let activity_mode = Array.isArray(res_data) ? res_data?.map((x) => {if(x?.id === data?.value){ return x?.modeOfActivity}})?.[0] : []
        let activity_mode = Array.isArray(res_data) ? res_data?.filter((x) => x?.id === data?.value)?.[0]?.modeOfActivity : [];
        
        this.setState({
          ...addEditFormState,
          duration: Array.isArray(time) && time?.[0] ? time?.[0] : 30, 
          activity_mode: activity_mode?.length===1 ? activity_mode?.[0].value : '' ,
          activity_mode_count: activity_mode?.length, 
          field_values: ['activity_mode'],
          // resetState:{ ...addEditFormState,
          //   activity_mode: (activity_mode?.length===1 ? activity_mode?.[0].value : '') },
            // partnerId:addEditFormState?.partnerId, cliendId:addEditFormState?.id, subscriptions:addEditFormState?.value, services:addEditFormState?.id, title:addEditFormState?.title, 
        })
        // 
        // console.log(this.state.activity_mode)
      }
    );
  };

  // updateResetState = () => {
  //   this.setState((prev_state) => ({
  //     resetState: !prev_state?.resetState
  //   }
  //   ), () => {console.log()}
  //   )
  //   // 
  //   this.forceUpdate()
    
  //   
  //   console.log(this.state.resetState)
  // }

  checkForExistingActivities = () => {
    /*  console.log(
      this.state.from_timestamp,
      this.state.to_timestamp,
      this.state.serviceProfessionalId
    ); */
    const {
      editavailability_serviceProfessionalId,
      editavailability_from_timestamp,
      editavailability_to_timestamp,
      serviceProfessionalId,
      from_timestamp,
      to_timestamp,
    } = this.state;

    if (this.props.isEdit) {
      let from = new Date(from_timestamp);
      from.setSeconds("00");
      let to = new Date(to_timestamp);
      to.setSeconds("00");
      if (
        editavailability_serviceProfessionalId !== serviceProfessionalId ||
        editavailability_from_timestamp !== from.toISOString() ||
        editavailability_to_timestamp !== to.toISOString()
      ) {
        if (
          this.state.from_timestamp &&
          this.state.to_timestamp &&
          this.state.serviceProfessionalId
        ) {
          this.props.CONFLICT_CHECK_AT_AVAILABILTY({
            fromTimeStamp: this.state.from_timestamp,
            toTimeStamp: this.state.to_timestamp,
            serviceProfessionalId: this.state.serviceProfessionalId,
            activityId: this.props?.id,
          });
        }
      }
    } else {
      if (
        this.state.from_timestamp &&
        this.state.to_timestamp &&
        this.state.serviceProfessionalId
      ) {
        this.props.CONFLICT_CHECK_AT_AVAILABILTY({
          fromTimeStamp: this.state.from_timestamp,
          toTimeStamp: this.state.to_timestamp,
          serviceProfessionalId: this.state.serviceProfessionalId,
        });
      }
    }
  };

  viewButtonCallback = (parentState) => {
    // console.table(parentState);
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ConflictList
          {...this.props}
          parentState={parentState}
          isActivity={true}
        />
      ),
    });
  };

  // Handle FROM TO DATE TIME PICKERS CALLBACKS
  handleOnFromDateAccept = (data, state) => {
    // 
    this.setState({ ...state, from_timestamp: data }, () => {
      let to_time = moment(data).add(this.state.duration, "minutes").toDate();
      console.log(to_time);
      this.setState({ to_timestamp: to_time });
      this.checkForExistingActivities();
      this.loadServiceProfessionalOptions();
    });
  };
  handleOnToDateAccept = (data, state) => {
    this.setState({...state, to_timestamp: data }, () => {
      this.checkForExistingActivities();
      this.loadServiceProfessionalOptions();
    });
  };

  handleCancelDate = () => {

    this.setState({ from_timestamp: "" });
    
  };
  /**------------------------------------------- */

  loadServices = (data) => {
    if (data) {
      // console.log(data)

      if (data?.partnerId?.value) {
        console.log("INSIDE");
        this.setState({ partnerId: data?.partnerId?.value }, () => {
          this.props.GET_ALL_CLIENTS_LIST({
            pageNo: 0,
            pageSize: 25,
            clientName: "",
            partnerId: data?.partnerId?.value,
          });
        });
      }

      if (data?.cliendId?.value) {
        console.log("GET SUBS BY CLIENT ID");
       
        this.setState({ clientId: data?.cliendId?.value, services: [] }, () => {
          this.props.GET_SUBSCRIPTION_BY_CLIENT_ID({
            clientId: data?.cliendId?.value,
            partnerId: data?.partnerId?.value,
          });
        });
      }

      if (data?.subscriptions?.value) {
        console.log("INSIDE SERVICE BT SUBSCRIPTION ID");
        this.setState(
          {
            subscriptionId: data?.subscriptions?.value
              ? data?.subscriptions?.value
              : [],
            subscriptionType: data?.subscriptions?.type
              ? data?.subscriptions?.type
              : [],
          },
          () => {
            
            this.props.SERVICES_BY_SUBSCRIPTION_ID({
              subscriptionId: data?.subscriptions?.value,
            });
            // let newData = await this.props.SERVICES_BY_SUBSCRIPTION_ID({subscriptionId:data?.subscriptions?.value})
            // this.setState({resourceTypeCategoryId : newData?.payload?.[0]?.resourceTypeCategory})
          }
        );
      }
      if (data?.services?.value) {
        console.log("INSIDE ACTIVITY DROPDOWN\n");
        this.setState(
          {
            serviceId: data?.services?.value,
            resourceTypeCategoryId: data?.resourceTypeCategory,
          },
          () => {
            this.props.ACTIVITY_DROPDOWN_AND_SEARCH({
              pageNo: 25,
              pageSize: 10,
              careGiverName: "",
              subscriptionId: this.state.subscriptionId,
              service: {
                label: data?.services?.label,
                value: data?.services?.value,
              },
              type: this.state.subscriptionType,
            });
            this.loadServiceProfessionalOptions();
          }
        );
      }
    }
  };

  render() {
    let isEdit = this.props.isEdit;
    let isReschedule = this.props?.isReschedule;
    let persistState = this.props?.persistState;
    let parentState = this.props?.parentState;
    let field_id = this?.props?.field_id;
    let {
      data: editData,
      loading: editLoading,
      error: editError,
    } = this.props?.editActivity;

    // alert(this.state.activity_mode_count)

    const { loading: addLoading } = this.props?.addActivity;
    const { loading: updateLoading } = this.props?.updateActivity;


    const loadAllPartnerOptions = async (search, loadedOptions) => {
      const response = await this.props.GET_ALL_CARE_PROVIDER_DROPDOWN({
        pageNo: Math.round(loadedOptions?.length / 25),
        pageSize: 25,
        careProviderName: search,
        category: [],
        date: null,
        isFilterApplied: false,
        parentPartnerId: "",
        products: [],
        sort: "most_recent",
        sortDirec: "desc",
        sortfield: "createdDate",
        status: "Both",
      });

      return {
        options: converToReactSelect(response?.payload?.data, "id", "name"),
        hasMore: response?.payload?.data?.length % 25 === 0,
      };
    };

    const getServiceProfessionalId = (data , addEditFormState) => {
      debugger
      this.setState({...addEditFormState, serviceProfessionalInfo:data, serviceProfessionalId: data?.value }, () => {
        this.checkForExistingActivities();
      });
    };

    const checkNullInSelectData = (data) => {
      let _array = [];
      if (data?.constructor === Array && data?.length > 0) {
        data.map((title) => {
          if (
            title?.options?.constructor === Array &&
            title?.options?.length > 0
          ) {
            title.options.map((options) => {
              if (options?.label && options.value) {
                _array.push(title);
              }
              return options;
            });
          }
          return title;
        });
      }
      return [...new Set(_array)];
    };


    let components = {
      section_1: {
        label: !isEdit ? "Add Activity" : "Edit Activity",
        fields: {
          title: {
            key: "title",
            type: formComponentTypes.INPUT_BOX,
            disabled: isReschedule ? true : false,
            id: `addTitleForActivityFrom${field_id ? field_id : ""}`,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "TITLE",
            // && parentState?.activity_name
            value:
              persistState === false
                ? 
                !isEdit
                  ? this.state.title
                  : editData?.activity_name
                : parentState?.title
                ? parentState?.title
                : !isEdit
                ? this.state.title

                : editData?.activity_name,
            componentProps: {
              type: "text",
            },
            isMandatory: true,
          },
          client: {
            key: "cliendId",
            type: formComponentTypes.SELECT_BOX,
            id: `selectClientForActivityFrom${field_id ? field_id : ""}`,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            isDisabled: isEdit ? true : false,
            label: "CHOOSE CLIENT",
            value:
              persistState === false
                ? !isEdit
                  ? this.state.cliendId
                  : {
                      value: !isEdit ? "" : editData?.client_info?.id,
                      label: !isEdit ? "" : editData?.client_info?.client_name,
                    }
                : parentState?.cliendId?.value && parentState?.cliendId?.label
                ? {
                    value: parentState?.cliendId?.value,
                    label: parentState?.cliendId?.label,
                  }
                : !isEdit
                ? this.state.cliendId
                : {
                    value: !isEdit ? "" : editData?.client_info?.id,
                    label: !isEdit ? "" : editData?.client_info?.client_name,
                  },
            onSelectedCallback: this.getClientId,
            options: converToReactSelect(
              this.props?.careGiverClient?.data,
              "id",
              "name"
            ),

            isLoading: this.props.careGiverClient.loading,
            isMandatory: true,
            cleanUpKeys:
              this.props?.redirectFrom !== undefined &&
              this.props?.redirectFrom === routes.monitor_service_professionals
                ? ["select_provider", "subscriptions", "services"]
                : [
                    "select_provider",
                    "subscriptions",
                    "services",
                    "serviceprofessionalId",
                  ],
          },
          subscriptions: {
            key: "subscriptions",
            type: formComponentTypes.SELECT_BOX,
            id: `selectClientSubscriptionForActivityFrom${
              field_id ? field_id : ""
            }`,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "CHOOSE SUBSCRIPTION",
            isDisabled: isEdit ? true : false,
            // value: this.loadSubscriptions(parentState),
            value:
              persistState === false
                ? !isEdit
                  ? this.state.subscriptions
                  : {
                      value: !isEdit ? "" : editData?.subscription?.value,
                      label: !isEdit ? "" : editData?.subscription?.label,
                    }
                : parentState?.subscriptions?.value &&
                  parentState?.subscriptions?.label
                ? {
                    value: parentState?.subscriptions?.value,
                    label: parentState?.subscriptions?.label,
                  }
                : !isEdit
                ? this.state.subscriptions
                : {
                    value: !isEdit ? "" : editData?.subscription?.value,
                    label: !isEdit ? "" : editData?.subscription?.label,
                  },
            onSelectedCallback: this.getSubscriptionId,

            options: this.props.subscriptions?.data
              ? checkNullInSelectData(this.props.subscriptions?.data)
              : [],
            isLoading: this.props.subscriptions?.loading ?? false,
            isMandatory: true,
            cleanUpKeys:
              this.props?.redirectFrom !== undefined &&
              this.props?.redirectFrom === routes.monitor_service_professionals
                ? ["subscriptions", "services"]
                : ["subscriptions", "services", "serviceprofessionalId"],
          },
          services: {
            key: "services",
            id: `selectServiceForActivityFrom${field_id ? field_id : ""}`,
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "CHOOSE SERVICE ",
            isDisabled: isEdit ? true : false,
            value:
              persistState === false
                ? !isEdit
                  ? this.state.services
                  : editData?.service?.label && editData?.service?.value
                  ? {
                      value: !isEdit ? "" : editData?.service?.value,
                      label: !isEdit ? "" : editData?.service?.label,
                    }
                  : ""
                : parentState?.services?.value && parentState?.services?.label
                ? {
                    value: parentState?.services?.value,
                    label: parentState?.services?.label,
                  }
                : !isEdit
                ? this.state.services
                : editData?.service?.label && editData?.service?.value
                ? {
                    value: !isEdit ? "" : editData?.service?.value,
                    label: !isEdit ? "" : editData?.service?.label,
                  }
                : "",
            options: Array.isArray(
              this.props?.servicesBySubscriptionIdandClientId?.data?.services
            )
              ? this.props?.servicesBySubscriptionIdandClientId?.data?.services?.map(
                  (service) => {
                    if (service?.pending_services_count > 0) {
                      return { label: service?.name, value: service?.id, ...service };
                    } else {
                      return null;
                    }
                  }
                )?.filter(services=>services)
              : [],
            // this.props?.servicesBySubscriptionId?.data,
            // [{isdisabled:true,label:"Sfd", value:"SDf"}],
            // this.props?.servicesBySubscriptionId?.data,
            // isLoading: this.props?.servicesBySubscriptionId?.loading,
            onSelectedCallback: this.getServiceId,
            isMandatory: true,
            cleanUpKeys:
              this.props?.redirectFrom !== undefined &&
              this.props?.redirectFrom === routes.monitor_service_professionals
                ? ["services"]
                : ["services", "serviceprofessionalId"],
          },
          activity_recurrence: {
            isEdit: isEdit,
            editData: editData,
            id: {
              recurrence_type_id: `selectActiviyRecurrenceForActivityFrom${
                field_id ? field_id : ""
              }`,
              recurrence_from_time_id: `selectActiviyRecurrenceFromTimeForActivityFrom${
                field_id ? field_id : ""
              }`,
              recurrence_to_time_id: `selectActiviyRecurrenceToTimeForActivityFrom${
                field_id ? field_id : ""
              }`,
            },
            type: formComponentTypes.ACTIVITY_RECURRENCE,
            key: {
              recurrence: "recurrence",
              dnrFrom: "from_timestamp",
              dnrTo: "to_timestamp",
              repeatOn: "repeat_on",
              // weeklyDateRange: "weekly_date_range",
              weeklyDateFrom: "weekly_date_from",
              weeklyDateTo: "weekly_date_to",
              startTime: "weekly_start_time",
              endTime: "weekly_end_time",
              // dailyDate: "daily_date",
              dailyDateFrom: "daily_date_from",
              dailyDateTo: "daily_date_to",
              dailyStartTime: "daily_start_time",
              dailyEndTime: "daily_end_time",
            },
            value: {
              recurrence:
                persistState === false
                  ? !isEdit
                    ? "DO NOT REPEAT"
                    : editData?.occurence_type === null
                    ? "DO NOT REPEAT"
                    : editData?.occurence_type
                  : parentState?.occurence_type
                  ? parentState?.occurence_type === null
                    ? "DO NOT REPEAT"
                    : parentState?.occurence_type
                  : !isEdit
                  ? "DO NOT REPEAT"
                  : editData?.occurence_type === null
                  ? "DO NOT REPEAT"
                  : editData?.occurence_type,
              // recurrence: "DO NOT REPEAT", //Value of recurrence field
              //Value of DO NOT REPEAT from timestamp
              from_timestamp:
                persistState === false
                  ? !isEdit
                    ? this.state.from_timestamp
                    : editData?.from_time_stamp
                  : parentState?.from_timestamp
                  ? parentState?.from_timestamp
                  : !isEdit
                  ? this.state.from_timestamp
                  : editData?.from_time_stamp,
              //Value of DO NOT REPEAT to timestamp
              to_timestamp:
                persistState === false
                  ? !isEdit
                    ? this.state.to_timestamp
                    : editData?.to_time_stamp
                  : parentState?.to_timestamp
                  ? parentState?.to_timestamp
                  : !isEdit
                  ? this.state.to_timestamp
                  : editData?.to_time_stamp,
              // isEdit ? editData?.to_time_stamp : this.state.to_timestamp,

              //Weekly Values
              repeat_on:
                persistState === false
                  ? !isEdit
                    ? []
                    : editData?.recurrence !== "DO NOT REPEAT"
                    ? editData?.repeat_on
                    : []
                  : parentState?.repeat_on
                  ? parentState?.repeat_on
                  : !isEdit
                  ? []
                  : editData?.recurrence !== "DO NOT REPEAT"
                  ? editData?.repeat_on
                  : [],

              weekly_date_from:
                persistState === false
                  ? !isEdit
                    ? null
                    : editData?.from_time_stamp
                  : parentState?.from_timestamp
                  ? parentState?.from_timestamp
                  : !isEdit
                  ? null
                  : editData?.from_time_stamp,
              weekly_date_to:
                persistState === false
                  ? !isEdit
                    ? null
                    : editData?.to_time_stamp
                  : parentState?.to_timestamp
                  ? parentState?.to_timestamp
                  : !isEdit
                  ? null
                  : editData?.to_time_stamp,
              // weekly_date_range: [
              //   persistState === false
              //     ? !isEdit
              //       ? ""
              //       : editData?.from_time_stamp
              //     : parentState?.from_timestamp
              //     ? parentState?.from_timestamp
              //     : !isEdit
              //     ? ""
              //     : editData?.from_time_stamp,
              //   persistState === false
              //     ? !isEdit
              //       ? ""
              //       : editData?.to_time_stamp
              //     : parentState?.to_timestamp
              //     ? parentState?.to_timestamp
              //     : !isEdit
              //     ? ""
              //     : editData?.to_time_stamp
              // ],
              weekly_start_time:
                persistState === false
                  ? !isEdit
                    ? null
                    : editData?.from_time_stamp
                  : parentState?.from_timestamp
                  ? parentState?.from_timestamp
                  : !isEdit
                  ? null
                  : editData?.from_time_stamp,
              weekly_end_time:
                persistState === false
                  ? !isEdit
                    ? null
                    : editData?.to_time_stamp
                  : parentState?.to_timestamp
                  ? parentState?.to_timestamp
                  : !isEdit
                  ? null
                  : editData?.to_time_stamp,

              //Daily value
              // daily_date: null,
              daily_date_from:
                persistState === false
                  ? !isEdit
                    ? null
                    : editData?.from_time_stamp
                  : parentState?.from_timestamp
                  ? parentState?.from_timestamp
                  : !isEdit
                  ? null
                  : editData?.from_time_stamp,
              daily_date_to:
                persistState === false
                  ? !isEdit
                    ? null
                    : editData?.to_time_stamp
                  : parentState?.to_timestamp
                  ? parentState?.to_timestamp
                  : !isEdit
                  ? null
                  : editData?.to_time_stamp,
              daily_start_time:
                persistState === false
                  ? !isEdit
                    ? null
                    : editData?.from_time_stamp
                  : parentState?.from_timestamp
                  ? parentState?.from_timestamp
                  : !isEdit
                  ? null
                  : editData?.from_time_stamp,
              daily_end_time:
                persistState === false
                  ? !isEdit
                    ? null
                    : editData?.to_time_stamp
                  : parentState?.to_timestamp
                  ? parentState?.to_timestamp
                  : !isEdit
                  ? null
                  : editData?.to_time_stamp,
            },
            isMandatory: true,

            // Main Recurrence Props
            // recurrenceProps: {
            //   label: "RECURRENCE",
            //   // options: options.repeat,   //Uncomment to enbale All the Options
            //   options: options.repeat_demo,
            //   // cleanUpKeys: [ "from_timestamp", "to_timestamp", "repeat_on",  "weekly_start_time", "weekly_end_time", "daily_date", "daily_start_time", "daily_end_time"],
            // }, //"weekly_date_range",

            // DO_NOT_REPEAT props
            componentProps: {
              duration: this.state?.duration,
              disableToTime: true,
            },
            dnrProps: {
              label: {
                dnrFrom: "FROM DATE & TIME",
                dnrTo: "TO DATE & TIME",
              },
              onFromDateChange: this.onFromDateChange,
              onToDateChange: this.onToDateChange,
              handleOnFromDateAccept: this.handleOnFromDateAccept,
              handleOnToDateAccept: this.handleOnToDateAccept,
              handleCancelDate: this.handleCancelDate,
              minDateTime: {
                minFromDateTime: isEdit
                  ? editData?.from_time_stamp
                    ? moment(editData?.from_time_stamp).isAfter()
                      ? new Date()
                      : new Date(editData?.from_time_stamp)
                    : new Date()
                  : new Date(),
                minToDateTime: this.state.from_timestamp,
              },
              disablePast: {
                disableFromDatePast: isEdit ? false : true,
                disableToDatePast: isEdit ? false : true,
              },
            },

            // WEEKLY props
            weeklyProps: {
              // Repeat on props
              repeatOnProps: {
                label: "REPEAT ON",
                options: weekDays,
                isMulti: true,
                breakPoints: {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12,
                },
              },
              // Date range props
              dateRangeProps: {
                // key: "weekly_date_range",
                // type: formComponentTypes.DATE_RANGE_PICKER,
                // label: "SELECT DATE",
                // // isHidePastDate: true,
                // // isHideFutureDate: false,

                // //Date new
                // // type: formComponentTypes.DATE_RANGE_PICKER,
                // componentProps: {
                //   isHidePastDate: true,
                //   isHideFutureDate: false
                // },

                //DATE RANGE PROPS - NEW
                label: {
                  weeklyDateFrom: "SELECT START DATE",
                  weeklyDateTo: "SELECT END DATE",
                },
                onFromDateChange: this.onFromDateChange,
                onToDateChange: this.onToDateChange,
                handleOnFromDateAccept: this.handleOnFromDateAccept,
                handleOnToDateAccept: this.handleOnToDateAccept,
                minDateTime: {
                  minFromDateTime: isEdit
                    ? editData?.from_time_stamp
                      ? moment(editData?.from_time_stamp).isAfter()
                        ? new Date()
                        : new Date(editData?.from_time_stamp)
                      : new Date()
                    : new Date(),
                  minToDateTime: this.state.from_timestamp,
                },
                disablePast: {
                  disableFromDatePast: isEdit ? false : true,
                  disableToDatePast: isEdit ? false : true,
                },
              },
              //Time range props
              timeRangeProps: {
                startLabel: "SELECT START TIME",
                startMin: isEdit
                  ? editData?.from_time_stamp
                    ? new Date(editData?.from_time_stamp)
                    : new Date()
                  : new Date(),
                startChange: this.onFromDateChange,
                disableStartPast: isEdit ? false : true,
                handleStartAccept: this.handleOnFromDateAccept,

                endLabel: "SELECT END TIME",
                endMin: this.state.from_timestamp,
                endChange: this.onToDateChange,
                handleEndAccept: this.handleOnToDateAccept,
                disableEndPast: isEdit ? false : true,
              },
            },

            // Daily props
            dailyProps: {
              dateProps: {
                label: {
                  dailyDateFrom: "SELECT START DATE",
                  dailyDateTo: "SELECT END DATE",
                },
                onFromDateChange: this.onFromDateChange,
                onToDateChange: this.onToDateChange,
                handleOnFromDateAccept: this.handleOnFromDateAccept,
                handleOnToDateAccept: this.handleOnToDateAccept,
                minDateTime: {
                  minFromDateTime: isEdit
                    ? editData?.from_time_stamp
                      ? moment(editData?.from_time_stamp).isAfter()
                        ? new Date()
                        : new Date(editData?.from_time_stamp)
                      : new Date()
                    : new Date(),
                  minToDateTime: this.state.from_timestamp,
                },
                disablePast: {
                  disableFromDatePast: isEdit ? false : true,
                  disableToDatePast: isEdit ? false : true,
                },
              },
              // dateProps: {
              //   hidePast: false,
              //   hideFuture: true,
              //   isDate: true,
              //   label: "SELECT DATE",
              //   onChange: this.onDailyDateChange
              // },
              timeRangeProps: {
                startLabel: "SELECT START TIME",
                startMin: isEdit
                  ? editData?.from_time_stamp
                    ? moment(editData?.from_time_stamp).isAfter()
                      ? new Date()
                      : new Date(editData?.from_time_stamp)
                    : new Date()
                  : new Date(),
                startChange: this.onFromDateChange,
                disableStartPast: isEdit ? false : true,
                handleStartAccept: this.handleOnFromDateAccept,

                endLabel: "SELECT END TIME",
                endMin: this.state.from_timestamp,
                endChange: this.onToDateChange,
                handleEndAccept: this.handleOnToDateAccept,
                disableEndPast: isEdit ? false : true,
              },
            },
          },

          service_professional: {
            key: "serviceprofessionalId",
            type: formComponentTypes.SELECT_BOX,
            id: `selectProfessionalsForActivityFrom${field_id ? field_id : ""}`,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            isDisabled:
              (this.props?.redirectFrom !== undefined &&
                this.props?.redirectFrom ===
                  routes.monitor_service_professionals) ||
              isReschedule
                ? true
                : false,
            label: "CHOOSE SERVICE PROFESSIONAL",
            value:
              persistState === false
                ? !isEdit
                  ? this.state.serviceProfessionalInfo
                  : editData?.service_professional_info
                : parentState?.serviceprofessionalId?.value &&
                  parentState?.serviceprofessionalId?.label
                ? parentState?.serviceprofessionalId
                : !isEdit
                ? this.state.serviceProfessionalInfo
                : editData?.service_professional_info,
            isLoading: this.props?.careGiver?.loading,
            isMandatory:
              this.props?.redirectFrom !== undefined &&
              this.props?.redirectFrom === routes.monitor_service_professionals
                ? false
                : true,
            // isDisabled: this.props?.serviceProfessionalID,
            options: this.props?.serviceProfessionalByResourceTypeCategory
              ?.data,
            onSelectedCallback: getServiceProfessionalId,
            // isMulti: true,
            cacheUniqs: [
              this.state.clientId,
              this.state.services,
              this.state.subscriptionId,
              this.state.serviceId,
            ],
          },

          activity_mode: {
            type: formComponentTypes.ACTIVITY_MODE,
            key: {
              activityMode: "activity_mode",
              meetingLink: "meeting_link",
              location: "add_location",
              venue: "venue",
            },
            isEdit: isEdit,
            isReschedule: isReschedule,
            setDisable: isEdit ? editData?.service?.modeOfActivity?.length : this.state.activity_mode_count>1 ? false : true ,
            value: {
              activity_mode: isEdit
                ? editData?.mode
                  ? editData?.mode === "VEDIO_CALL"
                    ? "VIDEO_CALL"
                    : editData?.mode
                  : ""
                : this.state.activity_mode,

              meeting_link:
                editData?.mode === ("VIDEO_CALL" || "VEDIO_CALL")
                  ? persistState === false
                    ? !isEdit
                      ? ""
                      : editData?.videoCallUrl
                    : parentState?.videoCallUrl
                    ? parentState?.videoCallUrl
                    : !isEdit
                    ? ""
                    : editData?.videoCallUrl
                  : "",

              add_location:
                editData?.mode === "IN_PERSON"
                  ? persistState === false
                    ? !isEdit
                      ? ""
                      : {
                          address: editData?.address,
                          latitude: editData?.latitude,
                          longitude: editData?.longitude,
                        }
                    : parentState?.add_location?.address &&
                      parentState?.add_location?.latitude &&
                      parentState?.add_location?.longitude
                    ? {
                        address: parentState?.add_location?.address,
                        latitude: parentState?.add_location?.latitude,
                        longitude: parentState?.add_location?.longitude,
                      }
                    : !isEdit
                    ? ""
                    : {
                        address: editData?.address,
                        latitude: editData?.latitude,
                        longitude: editData?.longitude,
                      }
                  : "",

              venue:
                persistState === false
                  ? !isEdit
                    ? options.newAddress.value
                    : editData?.selectedVenue
                  : !!parentState?.selectedVenue
                  ? parentState?.selectedVenue
                  : !isEdit
                  ? options.newAddress.value
                  : editData?.selectedVenue,

            },
            isMandatory: true,
            activityModeProps: {
              label: "ACTIVITY MODE",
              id: `addActivityModeFrom${field_id ? field_id : ""}`,
              options: options.activity_mode,
              // cleanUpKeys: ["activity_mode", "meeting_link", "location"]
            },
            meetingProps: {
              // type: formComponentTypes.INPUT_BOX,
              label: "MEETING LINK",
              id: `addMeetingLinkFrom${field_id ? field_id : ""}`,
              placeholder: "",
              // value:
              //   persistState === false
              //     ? !isEdit
              //       ? ""
              //       : editData?.activity_name
              //     : parentState?.title
              //     ? parentState?.title
              //     : !isEdit
              //     ? ""
              //     : editData?.activity_name,
              componentProps: {
                type: "text",
              },
              isMandatory: true,
            },
            locationProps: {
              label: "ADD LOCATION",
              id: `addLocaionFrom${field_id ? field_id : ""}`,
              // type: formComponentTypes.GOOGLE_MAP_AUTOCOMPLETE,
              componentProps: {
                showLocationViaMaps: true,
              },
              isMandatory: true,
              // value:
              //   persistState === false
              //     ? !isEdit
              //       ? ""
              //       : {
              //           address: editData?.client_info?.address,
              //           latitude: editData?.client_info?.latitude,
              //           longitude: editData?.client_info?.longitude
              //         }
              //     : parentState?.add_location?.address &&
              //       parentState?.add_location?.latitude &&
              //       parentState?.add_location?.longitude
              //     ? {
              //         address: parentState?.add_location?.address,
              //         latitude: parentState?.add_location?.latitude,
              //         longitude: parentState?.add_location?.longitude
              //       }
              //     : !isEdit
              //     ? ""
              //     : {
              //         address: editData?.client_info?.address,
              //         latitude: editData?.client_info?.latitude,
              //         longitude: editData?.client_info?.longitude
              //       },
            },
            venueProps: {
              label: "CHOOSE VENUE",
              id: `addChooseVenueFrom${field_id ? field_id : ""}`,
              options:
                this.props?.clientAddress?.data?.length > 0
                  ? this.props?.clientAddress?.data
                  : [],
              isMandatory: false,
            },
          },
          description: {
            key: "description",
            type: formComponentTypes.RICHTEXTEDITOR,
            isReadOnly: isReschedule ? true : false,
            id: `addDescriptionForActivityFrom${field_id ? field_id : ""}`,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "ADD DESCRIPTION",
            value:
              persistState === false
                ? !isEdit
                  ? ""
                  : editData?.activity_description
                : parentState?.description
                ? parentState?.description
                : !isEdit
                ? ""
                : editData?.activity_description,
            componentProps: {
              limit: 300,
            },
            isMandatory: true,
          },
          activitiesFound: {
            key: "activitiesFound",
            type: formComponentTypes.CARD_WITH_TEXT_AND_CALLBACK,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            isShowAvailabilities: this.state.isShowAvailabilities,
            label: "ADD DESCRIPTION",
            title: `
            ${
              this.state?.activityCount > 0 && this.state?.availabilityCount > 0
                ? ` ${
                    this.state?.activityCount ? this.state?.activityCount : 0
                  } ${
                    this.state.activityCount > 1 ? "Activities" : "Activity"
                  }  and ${
                    this.state.availabilityCount
                      ? this.state.availabilityCount
                      : 0
                  } ${
                    this.state.availabilityCount > 1
                      ? "Unavailabilities"
                      : "Unavailability"
                  } Found.`
                : this.state?.activityCount > 0
                ? ` ${
                    this.state.activityCount ? this.state.activityCount : 0
                  } ${
                    this.state.activity > 1 ? "Activities" : "Activity"
                  } Found.`
                : this.state?.availabilityCount > 0
                ? `${
                    this.state.availabilityCount
                      ? this.state.availabilityCount
                      : 0
                  } ${
                    this.state.availabilityCount > 1
                      ? "Unavailabilities"
                      : "Unavailability"
                  } Found.`
                : ""
            }`,
            mainText:
              "Please re-schedule them to avoid conflicts and better client experience.",
            // "Please re-schedule them to avoid conflicts and better client experience. Otherwise it'll be pushed to free slots.",
            buttonText: "View Conflicts",
            // navigationLink: "#",
            viewButtonCallback: this.viewButtonCallback,
            isMandatory: true,
          },
          // other_activities: {
          //   key: "other_activities",
          //   type: formComponentTypes.CHECK_BOX,
          //   isHide: !this.state.isShowAvailabilities,
          //   componentProps: {},
          //   breakPoints: {
          //     xs: 12,
          //     sm: 12,
          //     md: 12,
          //     lg: 12,
          //     xl: 12,
          //   },
          //   withBoldHeading: false,
          //   onChangeCallBack: handleCheckBox,
          //   // isMandatory: true,
          //   options: [
          //     { label: "Yes, I'm Aware of the schedule", value: "yes" },
          //   ],
          //   value: [],
          //   label: "",
          // },
        },
      },
    };
    //
    let partner = {
      key: "partnerId",
      type: formComponentTypes.SELECT_BOX_WITH_PAGINATION,
      id: "selectPatnerFromAddEditActivity",
      breakPoints: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
      isDisabled: isEdit ? true : false,
      label: "CHOOSE PARTNER",
      value:
        persistState === false
          ? !isEdit
            ? this.state.partnerId
            : {
                value: !isEdit ? "" : editData?.partner?.id,
                label: !isEdit ? "" : editData?.partner?.client_name,
              }
          : parentState?.partnerId?.value && parentState?.partnerId?.label
          ? {
              value: parentState?.partnerId?.value,
              label: parentState?.partnerId?.label,
            }
          : !isEdit
          ? this.state.partnerId
          : {
              value: !isEdit ? "" : editData?.partner?.id,
              label: !isEdit ? "" : editData?.partner?.client_name,
            },
      onSelectedCallback: this.getPartnerId,
      options: loadAllPartnerOptions,
      isLoading: this.props.provider.loading,
      isMandatory: true,
      cleanUpKeys:
        this.props?.redirectFrom !== undefined &&
        this.props?.redirectFrom === routes.monitor_service_professionals
          ? ["cliendId", "select_provider", "subscriptions", "services"]
          : [
              "cliendId",
              "select_provider",
              "subscriptions",
              "services",
              "serviceprofessionalId",
            ],
    };

    if (
      (this.props.loginState?.data?.user_role === "super-admin" ||
        this.props.loginState?.data?.user_role === user_roles.help_desk) &&
      !isEdit
    ) {
      let keyValues = Object.entries(components.section_1.fields); //convert object to keyValues ["key1", "value1"] ["key2", "value2"]

      keyValues.splice(1, 0, ["partner", partner]); // insert key value at the index you want like 1.
      components = {
        ...components,
        section_1: {
          ...components.section_1,
          fields: Object.fromEntries(keyValues),
        },
      }; // convert key values to obj {key1: "value1", newKey: "newValue", key2: "value2"}
    }
    return (
      <>
        {isEdit ? (
          <>
            {editLoading && <LoadingSection message={"Loading  Activity..."} />}
            {!editLoading && editError && (
              <InternatlServerError
                message={"Unable to fetch  details"}
                onRetry={this.getEssentials}
              />
            )}
            {!editLoading && !editError && !editData && (
              <NotFoundSection message={"404 - Activity Not Found."} />
            )}
            {!editLoading && !editError && editData && (
              <AddEditActivityForm
                components={components}
                onSaveButtonClicked={this.onSaveButtonClicked}
                cancelForm={this.onCancelButtonClicked}
                loading={
                  updateLoading
                  // this.state?.activityCount <= 0 || this.state.availabilityCount <= 0 ? true : updateLoading
                }
                disabled={this.state.isShowAvailabilities}
                isUrl={true}
                saveButtonLabel={updateLoading ? "Updating..." : "Update"}
                activityEdit={isEdit}
                isReschedule={this.props?.isReschedule}
                withoutBoxShadow
                spacing={1}
                sectionGutterBottom={16}
                saveButtonId={"saveActivityButtonFromAddEditActivity"}
                cancelButtonId={"cancelActivityButtonFromAddEditActivity"}
              />
            )}
          </>
        ) : (
          <AddEditActivityForm
            components={components}
            isResetState={this.state}
            field_values={this.state.field_values}
            onSaveButtonClicked={this.onSaveButtonClicked}
            cancelForm={this.onCancelButtonClicked}
            loading={
              addLoading
              // this.state?.activityCount <= 0 || this.state.availabilityCount <= 0 ? true : addLoading
            }
            disabled={this.state.isShowAvailabilities}
            saveButtonLabel={addLoading ? "Adding Activity..." : "Add Activity"}
            withoutBoxShadow={true}
            spacing={1}
            sectionGutterBottom={16}
            saveButtonId={"saveActivityButtonFromAddEditActivity"}
            cancelButtonId={"cancelActivityButtonFromAddEditActivity"}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addActivity: state?.activity_app?.add_activity,
  editActivity: state?.clientActivity?.activitySummary,
  updateActivity: state?.activity_app?.edit_activity,
  subscriptions: state?.activity_app?.get_subscription_by_client,
  careGiverClient: state?.careGiverClient?.getAllClientLists,
  clientAddress: state?.clientAddress?.getAddress,
  careGiver: state?.careGiver?.allCareGiver,
  activityConflict: state?.availability_app?.conflict,
  activity_template: state?.dependency_api?.activity_dropdown_and_search,
  loginState: state?.authState?.loginAuth,
  provider: state?.careProvider?.allCareProvider,
  serviceProfessionalByResourceTypeCategory:
    state?.dependency_api?.serviceProfessionalByResourceTypeCategory,
  servicesBySubscriptionIdandClientId:
    state?.clientSubscription?.getSubscriptionDetails,
  // servicesBySubscriptionId: state?.dependency_api?.getSubscriptionDetails,
  auth_data: state?.authState?.loginAuth?.data ?? {},
});

// export default AddEditAvailabiity
export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(AddEditActivity));

AddEditActivity.propTypes = {
  /**To persist State, after viewing all conflicts state and returning back to add/Edit activity screen. */
  persistState: PropTypes.bool,
  /**State to set while persisting. */
  parentState: PropTypes.object,
};
