import React from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: "none",
    stroke: props.color || theme.palette.primary.main, 
    "stroke-linejoin": "round", 
    "stroke-miterlimit": "10", 
    "stroke-width": "2px"
  }),
  cls2: (props) => ({
    fill: props.color || theme.palette.primary.main
  })
}))


export const ClientWeightIcon = (props) => {
  const classes = useStyles(props);
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
      style={props.style}
    >
      <path id="Path_612" d="M4 4h16v16H4z" data-name="Path 612" className={classes.cls1} />
      <path id="Path_613" className={classes.cls2} d="M18 8.71L15.43 13h-1.81l1.27-2.55-1.78-.9L11.39 13H8.57L6 8.71A11.872 11.872 0 0 1 12 7a11.872 11.872 0 0 1 6 1.71z" data-name="Path 613" />
    </SvgIcon>
  )
}

ClientWeightIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};
