import React from "react";
import { calcAge } from "../../../../components";
import {
  // converToReactSelect,
  formComponentTypes,
  options,
  // bmiCalculation as otherBmiCalculation,
  // getCountryCodeOptions,
  seenADoctorOptions,
  bloodGroupOptions,
} from "../../../../utils";
import { errorMessage } from "../../../../utils/errorMessages";

const viewDetailsAddressEditDetails = (
  isEdit,
  editData,
  data,
  salutation,
  phonecode
) => {
  let _arrayOfJson = [];

  if (data?.length > 0) {
    _arrayOfJson = data;
  }
  return _arrayOfJson;
};

const formatOptionLabel = ({
  value,
  label,
  email,
  mobile,
  customAbbreviation,
}) => (
  <div>
    <div style={{ width: "100%" }}>{label}</div>
    <div style={{ width: "100%", color: "#ccc" }}>
      {email}
      {` | `} {mobile}
    </div>
  </div>
);

const drawerAddContact = (
  isEdit,
  editData,
  currentState,
  salutation,
  state,
  language,
  masterPhoneCodeData
) => {
  // console.log(editData)
  return {
    section_1: {
      label: isEdit ? "Edit Client" : "Add Client",
      fields: {
        // clientId: {
        //   key: "clientId",
        //   type: formComponentTypes.INPUT_BOX,
        //   id:"addNewClientEmailIdFromAdddEditEnquiry",
        //   componentProps: {
        //     type: "text",
        //   },
        //   breakPoints: {
        //     xs: 12,
        //     sm: 12,
        //     md: 12,
        //     lg: 12,
        //     xl: 12,
        //   },
        //   // disabled: isEdit,
        //   label: "Client ID",
        //   value: isEdit
        //     ? editData?.contact_information?.clientId
        //       ? editData.contact_information.clientId
        //       : ""
        //     : currentState
        //       ? currentState?.clientId
        //         ? currentState?.clientId
        //         : ""
        //       : "",
        //   isMandatory: true,
        // },
        caller_name: {
          key: { select_key: "salutation", input_value_key: "caller_name" },
          // value: { caller_name_salutation: "", caller_name: "" },
          value: {
            salutation: isEdit
              ? editData?.contact_information?.salutation
                ? editData?.contact_information?.salutation
                : ""
              : currentState
              ? currentState?.salutation
                ? currentState?.salutation
                : ""
              : "",
            caller_name: isEdit
              ? editData?.contact_information?.name
                ? editData?.contact_information?.name
                : ""
              : currentState
              ? currentState?.caller_name
                ? currentState?.caller_name
                : ""
              : "",
          },
          isComponentAMobileField: false,
          type: formComponentTypes.MOBILE_INPUT_BOX,
          onlyText: true,
          customsize: 3,
          componentProps: {
            type: "text",
            id: "addNewClientNameFromAddEditEnquiry",
            select_id: "selectNewSalutationFromAddEditEnquiry",
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          customize: 3,
          label: "NAME",
          options: salutation,
          isMandatory: true,
        },
        date_of_birth: {
          key: "date_of_birth",
          type: formComponentTypes.DATE_TIME_PICKER,
          id: "addClientDateOfBirthFromAddEditEnquiry",
          componentProps: {
            isHidePastDate: false,
            isHideFutureDate: true,
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          isDate: true,
          label: "DATE OF BIRTH",
          value: isEdit
            ? editData?.contact_information?.date_of_birth
              ? editData.contact_information.date_of_birth
              : null
            : currentState
            ? currentState?.date_of_birth
              ? currentState?.date_of_birth
              : null
            : null,
          isMandatory: true,
        },
        gender: {
          key: "gender",
          type: formComponentTypes.SELECT_BOX,
          id: "selectClientGenderFromAddEditEnquiry",
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          label: "GENDER",
          value: isEdit
            ? editData?.contact_information?.gender
              ? [
                  { value: "male", label: "MALE" },
                  { value: "female", label: "FEMALE" },
                  { value: "other", label: "OTHER" },
                ].filter((val) => {
                  return val.value === editData.contact_information.gender
                    ? val
                    : "";
                })[0]
              : ""
            : currentState
            ? currentState?.gender
              ? currentState?.gender
              : ""
            : "",
          options: [
            { value: "male", label: "MALE" },
            { value: "female", label: "FEMALE" },
            { value: "other", label: "OTHER" },
          ],
          isMandatory: true,
        },
        mobile: {
          key: { select_key: "mobile_code", input_value_key: "mobilenumber" },
          isFieldAPhoneNumber: true,

          value: {
            mobile_code: isEdit
              ? editData?.contact_information?.mobNo
                ? editData.contact_information.mobNo.split(" ")[0]
                : ""
              : currentState
              ? currentState?.mobile_code
                ? currentState?.mobile_code
                : "+60"
              : "+60",
            mobilenumber: isEdit
              ? editData?.contact_information?.mobNo
                ? editData.contact_information.mobNo.split(" ")[1]
                : ""
              : currentState
              ? currentState?.mobilenumber
                ? Number(currentState?.mobilenumber)
                : ""
              : "",
          },
          isComponentAMobileField: true,
          type: formComponentTypes.MOBILE_INPUT_BOX,
          customsize: 3,
          componentProps: {
            type: "number",
            id: "addNewClientNumberFromAddEditEnquiry",
            select_id: "selectNewClientCountryCodeFromAddEditEnquiry",
            allScreenCustomSize: {
              xs: 6,
              sm: 6,
              md: 6,
              lg: 6,
              xl: 6,
            },
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          label: "MOBILE NUMBER",
          // options: [
          //   {
          //     label: "+60",
          //     value: "60",
          //   },
          // ],
          options: masterPhoneCodeData,
          // isComponentAMobileField:true,
          // isFieldAPhoneNumber:true,
          isMandatory: true,
        },
        emailid: {
          key: "email",
          type: formComponentTypes.INPUT_BOX,
          id: "addNewClientEmailIdFromAdddEditEnquiry",
          componentProps: {
            type: "email",
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          // disabled: isEdit,
          label: "Email Address",
          value: isEdit
            ? editData?.contact_information?.email
              ? editData.contact_information.email
              : ""
            : currentState
            ? currentState?.email
              ? currentState?.email
              : ""
            : "",
          isMandatory: true,
        },
        occupation: {
          key: "occupation",
          type: formComponentTypes.INPUT_BOX,
          id: "addNewClientOccupationFromAddEditEnquiry",
          componentProps: {
            type: "text",
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          // disabled: isEdit,
          label: "Occupation",
          value: isEdit
            ? editData?.contact_information?.occupation
              ? editData.contact_information.occupation
              : ""
            : currentState
            ? currentState?.occupation
              ? currentState?.occupation
              : ""
            : "",
          isMandatory: false,
        },
        language: {
          key: "preferred_language",
          type: formComponentTypes.SELECT_BOX,
          id: "selectNewClientLanguageFromAddEditEnquiry",
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          isMulti: true,
          label: "PREFERRED LANGUAGE",
          value: isEdit
            ? editData?.languages && typeof editData?.languages == "string"
              ? JSON.parse(editData.languages)
              : ""
            : currentState
            ? currentState?.preferred_language
            : "",
          options:
            language?.data && Object.keys(language?.data).length > 0
              ? language?.data
              : [{}],
          // isMandatory: state.isContactAndCallerSame,
          isMandatory: state.isContactAndCallerSame,
        },
      },
    },
  };
};

const viewDetailsEditDetails = (
  isEdit,
  editData,
  currentState,
  salutation,
  state,
  callBackOnSaveAddress1,
  onAddEditDrawerAddress1,
  masterPhoneCodeData
) => {
  // let dob = new Date(state.date_of_birth)
  // `${dob.getDate() + '-' + dob.getMonth()+ '-' +dob.getFullYear()}`
  debugger;
  return {
    section_1: {
      label: "Edit Contact",
      fields: {
        // clientId: {
        //   key: "clientId",
        //   id: ``,
        //   type: formComponentTypes.INPUT_BOX,
        //   componentProps: {
        //     //   type: "date",
        //     multiline: true,
        //     rowsMax: 4,
        //   },
        //   breakPoints: {
        //     xs: 12,
        //     sm: 12,
        //     md: 12,
        //     lg: 12,
        //     xl: 12,
        //   },
        //   label: "User ID",
        //   value: currentState
        //     ? currentState?.loginUserId
        //       ? currentState?.loginUserId
        //       : ""
        //     : "",
        // },
        caller_name: {
          key: {
            select_key: "caller_name_salutation",
            input_value_key: "caller_name",
          },
          value: {
            caller_name_salutation: currentState
              ? currentState?.salutation
                ? salutation.filter(
                    (val) => val.value === currentState?.salutation
                  )?.[0]?.label !== "other"
                  ? salutation.filter(
                      (val) => val.value === currentState?.salutation
                    )?.[0]?.label + "."
                  : ""
                : ""
              : "",
            caller_name: currentState
              ? currentState?.caller_name
                ? currentState?.caller_name
                : ""
              : "",
          },
          isComponentAMobileField: false,
          type: formComponentTypes.MOBILE_INPUT_BOX,
          componentProps: {
            type: "text",
            id: ``,
            select_id: ``,
          },
          isSecondary: true,
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          label: currentState
            ? currentState?.gender
              ? currentState?.gender.label
                  .toLowerCase()
                  .charAt(0)
                  .toUpperCase() +
                currentState?.gender.label.toLowerCase().slice(1) +
                ", " +
                calcAge(currentState?.date_of_birth) +
                " old"
              : ""
            : "",
          options: [
            {
              label: "Mr",
              value: "Mr",
            },
            {
              label: "Mrs",
              value: "Mrs",
            },
          ],
        },

        mobile: {
          key: { select_key: "mobile_code", input_value_key: "mobile" },
          isFieldAPhoneNumber: true,
          value: {
            mobile_code: currentState
              ? currentState?.mobile_code
                ? `${currentState?.mobile_code}`
                : ""
              : "",
            mobile: currentState
              ? currentState?.mobilenumber
                ? Number(currentState?.mobilenumber)
                : ""
              : "",
          },
          isComponentAMobileField: true,
          type: formComponentTypes.MOBILE_INPUT_BOX,
          componentProps: {
            type: "number",
            id: ``,
            select_id: ``,
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          label: "MOBILE NUMBER",

          // options: [
          //   {
          //     label: "+60",
          //     value: "60",
          //   },
          // ],
          options: masterPhoneCodeData,
        },

        email: {
          key: "email",
          id: ``,
          type: formComponentTypes.INPUT_BOX,
          componentProps: {
            //   type: "date",
            multiline: true,
            rowsMax: 4,
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          label: "EMAIL",
          value: currentState
            ? currentState?.email
              ? currentState?.email
              : ""
            : "",
        },
        occupation: {
          key: "occupation",
          id: ``,
          type: formComponentTypes.INPUT_BOX,
          componentProps: {
            type: "text",
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          // disabled: isEdit,
          label: "Occupation",
          value: currentState
            ? currentState?.occupation
              ? currentState?.occupation
              : ""
            : "",
          isMandatory: true,
        },
        language: {
          key: "preferred_language",
          id: ``,
          type: formComponentTypes.SELECT_BOX,
          breakPoints: {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 6,
            xl: 6,
          },
          isMulti: true,
          label: "PREFERRED LANGUAGE",
          value: currentState
            ? currentState?.preferred_language
              ? currentState?.preferred_language
                  ?.map((val) => val.label)
                  .join(", ")
              : ""
            : "",
          // options: language?.data && Object.keys(language?.data).length > 0 ? language?.data : [{}],
          isMandatory: state.isContactAndCallerSame,
        },
        // client_language: {
        //   key: "client_languages",
        //   type: formComponentTypes.SELECT_BOX,
        //   componentProps: {
        //     type: "text",
        //   },
        //   breakPoints: {
        //     xs: 12,
        //     sm: 12,
        //     md: 12,
        //     lg: 12,
        //     xl: 12,
        //   },
        //   isMulti: true,
        //   label: "LANGUAGE",
        //   value: isEdit
        //     ? editData?.contact_information?.client_language
        //       ? JSON.parse(editData?.contact_information?.client_language)
        //       : ""
        //     : currentState
        //       ? currentState?.occupation
        //         ? currentState?.occupation
        //         : ""
        //       : "",
        //   isMandatory: true,
        // },
      },
    },
  };
};

export const components = (
  getOptions,
  columns,
  state,
  updateServiceRow,
  handleRemove,
  categoryData,
  categoryLoading,
  enquiryTypeData,
  enquiryTypeLoading,
  enquiryRelationshipData,
  enquirySourceOfReferrelLoading,
  enquirySourceOfReferrelData,
  enquiryAssignedToLoading,
  enquiryenquiryAssignedToData,
  addContactCallFunction,
  handleSeenAdoctorCallback,
  handleOtherActivitiesCallback,
  isEdit,
  editLoading,
  editData,
  editError,
  bmiCalculation,
  cancel,
  salutation,
  language,
  allclients,
  handleSelectExitsClient,
  onAddEditDrawerAddress,
  callBackOnSaveAddress,
  callBackOnSaveAddress1,
  onAddEditDrawerAddress1,
  handleSelectExitsClient1,
  masterPhoneCodeData,
  onAddEditDrawerId,
  callBackOnSaveId,
  callBackOnSaveContact,
  onAddEditDrawerContact,
  onAddEditDrawerContact1,
  callBackOnSaveContact1,
  onAddEditDrawerId1,
  callBackOnSaveId1
) => {
  console.log(state);
  return {
    section_1: {
      label: "",
      fields: {
        date: {
          key: "date",
          type: formComponentTypes.DATE_TIME_PICKER,
          id: "addDateFromAddEditEnquiry",
          componentProps: {
            isHidePastDate: true,
            isHideFutureDate: false,
            // isDate : true
            // isDisable:isEdit ? true : false
            isDisable: true,
          },
          isDate: true,
          breakPoints: {
            xs: 12,
            sm: 6,
            // md: isEdit ? 3 : 3,
            // lg: isEdit ? 2 : 2,
            // xl: isEdit ? 2 : 2,
            md: 3,
            lg: 2,
            xl: 2,
          },
          label: "DATE",

          value: isEdit
            ? editData?.enquired_on
              ? editData?.enquired_on
              : new Date()
            : new Date(),
          isMandatory: false,
        },
        time: {
          key: "time",
          type: formComponentTypes.DATE_TIME_PICKER,
          id: "addTimeFromAddEditEnquiry",
          // minTime:new Date(),
          componentProps: {
            // isDate:false
            // isDisable:isEdit ? true : false
            isDisable: true,
          },
          breakPoints: {
            xs: 12,
            sm: 6,
            // md: isEdit ? 3 : 3,
            // lg: isEdit ? 2 : 2,
            // xl: isEdit ? 2 : 2,
            md: 3,
            lg: 2,
            xl: 2,
          },
          isDate: false,
          label: "TIME",
          value: isEdit
            ? editData?.enquired_on
              ? editData?.enquired_on
              : new Date()
            : new Date(),
          isMandatory: true,
        },
        enquiry_type: {
          key: "enquiry_type",
          type: formComponentTypes.SELECT_BOX,
          id: "selectEnquiryTypeFromAddEditEnquiry",
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 6,
            // md: isEdit ? 3 : 3,
            // lg: isEdit ? 3 : 3,
            // xl: isEdit ? 3 : 3,
            md: 3,
            lg: 3,
            xl: 3,
          },
          label: "LEAD TYPE",
          value: isEdit ? (editData?.lead_type ? editData?.lead_type : []) : [],
          options: Boolean(enquiryTypeData?.length > 0) ? enquiryTypeData : [],
          isMandatory: true,
        },
        assigned_to: {
          key: "assigned_to",
          type: formComponentTypes.SELECT_BOX,
          id: "selectAssignedToFromAddEditEnquiry",
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 6,
            // md: isEdit ? 3 : 3,
            // lg: isEdit ? 3 : 3,
            // xl: isEdit ? 3 : 3,
            md: 3,
            lg: 3,
            xl: 3,
          },
          label: "ASSIGNED TO",
          value: isEdit
            ? editData?.assigned_To
              ? editData?.assigned_To
              : []
            : [],
          options: Boolean(enquiryenquiryAssignedToData?.length > 0)
            ? enquiryenquiryAssignedToData
            : [],
        },
        ...(isEdit &&
          {
            /* status: {
            key: "status",
            isDisabled :  false ,
            type: formComponentTypes.SELECT_BOX,
            id:"selectStatusFromAddEditEnquiry",
            componentProps: {
              disabled: editData?.enquiry_status === "CLOSED" ? true : false,
            },
            breakPoints: {
              xs: 12,
              sm: 6,
              md: 3,
              lg: 2,
              xl: 2,
            },
            label: "ENQUIRY STATUS",
            value: isEdit
              ? {
                value: editData?.enquiry_status,
                label: editData?.enquiry_status,
              }
              : [],
            options: [
              // {
              //   label: "OPEN",
              //   value: "OPEN",
              // },
              {
                label: "CLOSED",
                value: "CLOSED",
              },
            ],
            isMandatory: true,
          }, */
          }),
      },
    },
    section_2: {
      label: "Caller Information",
      key: "caller_information_switch",
      // id:"isCallerContactSameSwitchFromAddEditEnquiry",
      withHeaderContent: isEdit ? false : true,
      cleanUpKeys: ["relationship"],
      setkeyValue: [
        { key: "relationship", value: { value: "Self", label: "Self" } },
      ],
      fields: {
        select_caller: {
          key: "selected_caller",
          isDontShow: isEdit ? true : !state.isContactAndCallerSame,
          type: formComponentTypes.SELECT_BOX,
          id: "selectCallerFromDropDownFromAddEditEnquiry",
          formatOptionLabel: formatOptionLabel,
          componentProps: {
            hideCustomValueContainerHeight: true,
          },
          onSelectedCallback: handleSelectExitsClient1,
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          label: "SEARCH CLIENT",
          value: "",
          options:
            allclients?.data && allclients?.data.length > 0
              ? allclients?.data
              : [{}],
          isMandatory: false,
          cleanUpKeys: [
            "date_of_birth_caller",
            "caller_name",
            "caller_name_salutation",
            "gender_caller",
            "mobilenumber",
            "email",
            "ic_passport",
          ],
          setkeyValue: [
            { key: "date_of_birth_caller", steKey: "dob" },
            { key: "bloodGroup", steKey: "bloodGroup" },
            { key: "occupation", steKey: "occupation" },
            { key: "caller_name", steKey: "name" },
            { key: "caller_name_salutation", steKey: "salutation" },
            { key: "gender_caller", steKey: "gender" },
            { key: "mobile_code", steKey: "mobile", split: 0, splited: " " },
            { key: "mobilenumber", steKey: "mobile", split: 1, splited: " " },
            { key: "email", steKey: "email" },
            { key: "preferred_language", steKey: "languages" },
            { key: "ic_passport", steKey: "ic_passport" },
            { key: "idTypes", steKey: "idTypes" },
          ],
        },
        caller_name: {
          // key: "caller_name",
          key: {
            select_key: "caller_name_salutation",
            input_value_key: "caller_name",
          },

          value: {
            caller_name_salutation: isEdit
              ? editData?.caller_information?.salutation
                ? //22c4baf4-b32e-4941-af33-8fc005836f70 - dev 'other' Salutation ID || 9bad9a41-d1f3-48f1-8688-7067a8d672bc - SIT 'other Salutation ID
                  editData?.caller_information?.salutation ===
                    "22c4baf4-b32e-4941-af33-8fc005836f70" ||
                  editData?.caller_information?.salutation ===
                    "9bad9a41-d1f3-48f1-8688-7067a8d672bc"
                  ? ""
                  : editData?.caller_information?.salutation
                : ""
              : "",
            caller_name: isEdit
              ? editData?.caller_information?.name
                ? editData?.caller_information?.name
                : ""
              : "",
          },
          isComponentAMobileField: false,
          type: formComponentTypes.MOBILE_INPUT_BOX,
          disabled: state.isdisableCaller,
          onlyText: true,
          customsize: 4,
          componentProps: {
            type: "text",
            id: "addCallerNameFromAddEditEnquiry",
            select_id: "selectCallerSalutationFromAddEditEnquiry",
          },

          breakPoints: {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 4,
            xl: 4,
          },
          label: "CALLER NAME",
          // value: "",
          options:
            salutation?.data && Object.keys(salutation?.data).length > 0
              ? salutation?.data.filter((x) => x.label !== "other")
              : [{}],
          isMandatory: true,
        },
        date_of_birth: {
          key: "date_of_birth_caller",
          type: formComponentTypes.DATE_TIME_PICKER,
          id: "addCallerDateOfBirthFromAddEditEnquiry",
          componentProps: {
            isDisable: state.isdisableCaller,
            isHidePastDate: false,
            isHideFutureDate: true,
          },
          breakPoints: {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 4,
            xl: 4,
          },
          isDate: true,
          label: "DATE OF BIRTH",
          value: isEdit
            ? editData?.caller_information?.date_of_birth
              ? editData.caller_information.date_of_birth
              : null
            : null,
          isMandatory: true,
        },
        gender: {
          key: "gender_caller",
          type: formComponentTypes.SELECT_BOX,
          id: "selectCallerGenderFromAddEditEnquiry",
          isDisabled: state.isdisableCaller,
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 4,
            xl: 4,
          },
          label: "GENDER",
          value: isEdit
            ? editData?.caller_information?.gender
              ? [
                  { value: "male", label: "MALE" },
                  { value: "female", label: "FEMALE" },
                  { value: "other", label: "OTHER" },
                ].filter((val) => {
                  return (
                    val.value ===
                    editData.caller_information.gender.toLowerCase()
                  );
                })[0]
              : ""
            : "",
          options: [
            { value: "male", label: "MALE" },
            { value: "female", label: "FEMALE" },
            { value: "other", label: "OTHER" },
          ],
          isMandatory: false,
        },
        mobile: {
          key: { select_key: "mobile_code", input_value_key: "mobilenumber" },
          isFieldAPhoneNumber: true,
          disabled: state.isdisableCaller,
          value: {
            mobile_code: isEdit
              ? editData?.caller_information?.mobNo
                ? editData.caller_information.mobNo.split(" ")[0]
                : ""
              : "+60",
            mobilenumber: isEdit
              ? editData?.caller_information?.mobNo
                ? Number(
                    editData?.caller_information?.mobNo?.split(" ")?.[1]?.trim()
                  )
                : ""
              : "",
          },
          isComponentAMobileField: true,
          type: formComponentTypes.MOBILE_INPUT_BOX,
          customsize: 3,
          componentProps: {
            type: "number",
            id: "addCallerNumberFromAddEditEnquiry",
            select_id: "selectCallerCountryCodeFromAddEditEnquiry",
            allScreenCustomSize: {
              xs: 6,
              sm: 4,
              md: 6,
              lg: 6,
              xl: 5,
            },
            codeSize: {
              xs: 6,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
          },
          breakPoints: {
            xs: 12,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
          },
          label: "MOBILE NUMBER",
          // options: [
          //   {
          //     label: "+60",
          //     value: "60",
          //   },
          // ],
          //           isComponentAMobileField:true,
          // isFieldAPhoneNumber:true,
          options: masterPhoneCodeData,
          isMandatory: true,
        },
        emailid: {
          key: "email",
          type: formComponentTypes.INPUT_BOX,
          id: "addCallerEmailAddressFromAddEditEnquiry",
          disabled: (state.isdisableCaller || isEdit),
          componentProps: {
            type: "email",
          },
          breakPoints: {
            xs: 12,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
          },
          label: "Email Address",
          value: isEdit
            ? editData?.caller_information?.email
              ? editData.caller_information.email
              : ""
            : "",
          isMandatory: true,
        },
        occupation: {
          key: "occupation",
          type: formComponentTypes.INPUT_BOX,
          id: "addCallerOccupationFromAddEditEnquiry",
          disabled: state.isdisableCaller,
          isDontShow: !state.isContactAndCallerSame,
          componentProps: {
            type: "text",
          },
          breakPoints: {
            xs: 12,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
          },
          // disabled: isEdit,
          label: "Occupation",
          value: isEdit
            ? editData?.caller_information?.occupation
              ? editData.caller_information.occupation
              : ""
            : "",
          isMandatory: isEdit ? false : state.isContactAndCallerSame,
        },
        relationship: {
          key: "relationship",
          type: formComponentTypes.SELECT_BOX,
          id: "addCallerRelationshipFromAddEditEnquiry",
          isDisabled:
            isEdit && editData?.contact_or_caller === "same" ? true : false,
          breakPoints: {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 4,
            xl: 4,
          },
          label: "RELATIONSHIP",

          value: isEdit
            ? editData.contact_or_caller === "same"
              ? { value: "Self", label: "Self" }
              : editData?.relationship
              ? enquiryRelationshipData.filter(
                  (val) => val.value === editData?.relationship
                )[0]
              : ""
            : // state.isContactAndCallerSame ? {value:"Self", label:"Self"}
              "",
          options: isEdit
            ? editData.contact_or_caller === "same"
              ? { value: "Self", label: "Self" }
              : Boolean(enquiryRelationshipData)
              ? state.isContactAndCallerSame
                ? [
                    {
                      value: "Self",
                      label: "Self",
                    },
                  ]
                : enquiryRelationshipData
              : []
            : Boolean(enquiryRelationshipData)
            ? state.isContactAndCallerSame
              ? [
                  {
                    value: "Self",
                    label: "Self",
                  },
                ]
              : enquiryRelationshipData
            : [],
          isMandatory: true,
        },

        // ic: {
        //   key: "ic_passport",
        //   id:{
        //     ic_id: "addICNumberFromAddEditEnquiry",
        //     passport_id: "addPassportNumberFromAddEditEnquiry"
        //   },
        //   type: formComponentTypes.ICNUMBERPASSPORT,
        //   ic_disabled:state?.isdisableCaller,
        //     // editData?.icNumber
        //     //   ? true
        //     //   : false,
        //   passport_disabled:state?.isdisableCaller,
        //     // editData?.passportNumber
        //     //   ? true
        //     //   : false,
        //   componentProps: {
        //     type: "text"
        //   },
        //   breakPoints: {
        //     xs: 12,
        //     sm: 6,
        //     md: 8,
        //     lg: 8,
        //     xl: 8
        //   },
        //   ic_label: "I/C NUMBER",
        //   passport_label: "PASSPORT NUMBER",
        //   // isMandatoryWithMultipleFieldsRelation:"ic_number_passport",
        //   value: isEdit
        //     ? {
        //       ic_number: editData?.icNumber ? editData?.icNumber : "",
        //       passport: editData?.passportNumber ? editData?.passportNumber : ""
        //     }
        //     : { passport: "", ic_number: "" },
        //   isMandatory: true
        // },
        language: {
          key: "preferred_language",
          type: formComponentTypes.SELECT_BOX,
          id: "selectLanguageFromAddEditEnquiry",
          isDisabled: state.isdisableCaller,
          isDontShow: !state.isContactAndCallerSame,
          breakPoints: {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 6,
            xl: 6,
          },
          isMulti: true,
          label: "PREFERRED LANGUAGE",
          value: isEdit
            ? editData?.languages
              ? JSON.parse(editData.languages)
              : ""
            : "",
          options:
            language?.data && Object.keys(language?.data).length > 0
              ? language?.data
              : [{}],
          // isMandatory: state.isContactAndCallerSame,
          isMandatory: false,
        },
        addAddress: {
          key: "addAddress",
          type: formComponentTypes.ADD_ADDRESS,
          id: {
            add_address_id: "addCallerAddressButtonFromAddEditEnquiry",
          },
          componentProps: {
            component: viewDetailsAddressEditDetails(
              isEdit,
              state.address,
              state.address,
              [{}],
              masterPhoneCodeData
            ),
            callBackOnSave: callBackOnSaveAddress,
            editData: state.address,
            disabled: state.isdisableCaller,
            isEdit: isEdit,
            handledrawer: onAddEditDrawerAddress,
            error: state.error ? state.error["add_address"] : null,
            errorText: state.error ? errorMessage.atleastOne("address") : null,
          },
          breakPoints: {
            xs: 12,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
          },
          required: true,
        },
        addId: {
          key: "id",
          // label: "Id Type",
          value: "",
          id: {
            add_id_type_id:
              "addIdTypeButtonIdFromAddServicesupervisorcd76f5e5-643a-40a9-b313-74c171ed8aec",
          },
          type: formComponentTypes.ADD_ID_TYPE,
          componentProps: {
            component: viewDetailsAddressEditDetails(
              isEdit,
              state.id,
              state.id,
              [{}],
              masterPhoneCodeData
            ),
            callBackOnSave: callBackOnSaveId,
            editData: state.id,
            disabled: state.isdisableCaller,
            isEdit: isEdit,
            handledrawer: onAddEditDrawerId,
            error: state.error ? state.error["add_id"] : null,
            errorText: state.error ? errorMessage.atleastOne("ID") : null,
          },
          breakPoints: {
            xs: 12,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
          },
          required: true,
        },

        addOtherContact: {
          key: "OtherContact_multiple",
          // label: "Id Type",
          value: "",
          id: {
            add_Other_contact_id:
              "addIdTypeButtonIdFromAddServicesupervisorcd76f5e5-643a-40a9-b313-74c171ed8aec",
          },
          type: formComponentTypes.ADD_PHONE_NUMBER,
          componentProps: {
            component: viewDetailsAddressEditDetails(
              isEdit,
              state.phone,
              state.phone,
              [{}],
              masterPhoneCodeData
            ),
            callBackOnSave: onAddEditDrawerContact,
            editData: state.phone,
            disabled: state.isdisableCaller,
            isEdit: isEdit,
            handledrawer: callBackOnSaveContact,
            error: state.error ? state.error["add_phone"] : null,
            errorText: state.error ? errorMessage.atleastOne("contact") : null,
          },
          breakPoints: {
            xs: 12,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
          },
          required: false,
        },
        select_client: {
          key: "selected_client",
          isDontShow: isEdit
            ? true
            : state.isContactAndCallerSame ||
              Object.keys(state.addedContactData).length > 0,
          type: formComponentTypes.SELECT_BOX,
          id: "selectClientFromAddEditEnquiry",
          formatOptionLabel: formatOptionLabel,
          componentProps: {
            hideCustomValueContainerHeight: true,
          },
          onSelectedCallback: handleSelectExitsClient,
          breakPoints: {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 6,
            xl: 6,
          },
          label: "SEARCH CLIENT",
          value: "",
          options:
            allclients?.data && allclients?.data.length > 0
              ? allclients?.data
              : [{}],
          isMandatory: false,
          setkeyValue: [
            { key: "height", steKey: "height" },
            { key: "weight", steKey: "weight" },
            { key: "bloodGroup", steKey: "bloodGroup" },
          ],
          // cleanUpKeys: [
          //   "gender", "date_of_birth", "relationship", "mobilenumber",
          //   "email", "addressLine1", "addressLine2", "area",
          //   "state", "country", "postalCode", "salutation"
          // ],
        },
        add_contact: {
          key: "add_contact",
          id: {
            select_client_id: "selectExistingClientFromAddEditEnquiry",
            add_new_client_id: "addNewClientButtonFromAddEditEnquiry",
          },
          isDontShow: isEdit
            ? true
            : // ? editData?.contact_information
              //   ? editData.contact_information
              //   : state.isContactAndCallerSame ||
              //     Object.keys(state.addedContactData).length > 0
              state.isContactAndCallerSame ||
              Object.keys(state.addedContactData).length > 0,
          type: formComponentTypes.ADD_CONTACT_DRAWER,
          callback: addContactCallFunction,
          componentProps: {},
          contactCallerError: state.contactCallerError,
          components: drawerAddContact(
            isEdit,
            editData,
            state.addedContactData,
            salutation?.data && Object.keys(salutation?.data).length > 0
              ? salutation?.data
              : [{}],
            state,
            language,
            masterPhoneCodeData
          ),
          breakPoints: {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 2,
            xl: 2,
          },
        },
      },
    },
    section_3: {
      label: "Contact Information",
      isDontShow: state.isShowContactDetails || state.isContactAndCallerSame,
      fields: {
        details: {
          key: "details",
          isEdit: state.isSelectClient,
          type: formComponentTypes.DETAILS_CARD,
          id: "editDetailsButtonFromAddEditEnquiry",
          // isEdit: true,
          // data:state.addedContactData,
          callback: addContactCallFunction,
          editcomponent: drawerAddContact(
            isEdit,
            editData,
            state.addedContactData,
            salutation?.data && Object.keys(salutation?.data).length > 0
              ? salutation?.data
              : [{}],
            state,
            language,
            masterPhoneCodeData
          ),
          components: viewDetailsEditDetails(
            isEdit,
            editData,
            state.addedContactData,
            salutation?.data && Object.keys(salutation?.data).length > 0
              ? salutation?.data
              : [{}],
            state,
            callBackOnSaveAddress1,
            onAddEditDrawerAddress1,
            masterPhoneCodeData
          ),
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        addAddress_edit: {
          key: "caller_address_edit",
          type: formComponentTypes.ADD_ADDRESS,
          id: {
            add_address_id: "addNewClientAddressButtonFromAddEditEnquiry",
          },
          componentProps: {
            component: viewDetailsAddressEditDetails(
              isEdit,
              state.address_caller,
              state.address_caller,
              [{}],
              masterPhoneCodeData
            ),
            callBackOnSave: callBackOnSaveAddress1,
            editData: state.address_caller,
            disabled: state.isdisableCantact,
            isEdit: isEdit,
            handledrawer: onAddEditDrawerAddress1,
            error: state.error ? state.error["address_caller"] : null,
            errorText: state.error ? errorMessage.atleastOne("address") : null,
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
            xl: 4,
          },
          required: true,
        },
        addId: {
          key: "id_caller",
          // label: "Id Type",
          value: "",
          id: {
            add_id_type_id:
              "add1IdTypeButtonIdFromAddServicesupervisorcd76f5e5-643a-40a9-b313-74c171ed8aec",
          },
          type: formComponentTypes.ADD_ID_TYPE,
          componentProps: {
            component: viewDetailsAddressEditDetails(
              isEdit,
              state.id_caller,
              state.id_caller,
              [{}],
              masterPhoneCodeData
            ),
            callBackOnSave: callBackOnSaveId1,
            editData: state.id_caller,
            disabled: state.isdisableCaller,
            isEdit: isEdit,
            handledrawer: onAddEditDrawerId1,
            error: state.error ? state.error["id_caller"] : null,
            errorText: state.error ? errorMessage.atleastOne("ID") : null,
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
            xl: 4,
          },
          required: true,
        },

        addOtherContact: {
          key: "OtherContact_multiple",
          // label: "Id Type",
          value: "",
          id: {
            add_Other_contact_id:
              "addIdTypeButtonIdFromAddServicesupervisorcd76f5e5-643a-40a9-b313-74c171ed8aec",
          },
          type: formComponentTypes.ADD_PHONE_NUMBER,
          componentProps: {
            component: viewDetailsAddressEditDetails(
              isEdit,
              state.phone_caller,
              state.phone_caller,
              [{}],
              masterPhoneCodeData
            ),
            callBackOnSave: callBackOnSaveContact1,
            editData: state.phone_caller,
            disabled: state.isdisableCaller,
            isEdit: isEdit,
            handledrawer: onAddEditDrawerContact1,
            error: state.error ? state.error["phone_caller"] : null,
            errorText: state.error ? errorMessage.atleastOne("contact") : null,
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
            xl: 4,
          },
          required: false,
        },
      },
    },
    section_4: {
      label: "",
      fields: {
        source_of_referral: {
          key: "source_of_referral",
          type: formComponentTypes.SELECT_BOX,
          id: "selectEnquirySourceOfReferralFromAddeditEnquiry",
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 6,
            xl: 6,
          },
          label: "SOURCE OF REFERRAL",
          value: isEdit ? editData?.source_of_referreal : "",

          options: Boolean(enquirySourceOfReferrelData?.length > 0)
            ? enquirySourceOfReferrelData
            : [],
          isMandatory: true,
        },
        // remarks: {
        //   key: "remarks",
        //   type: formComponentTypes.INPUT_BOX,
        //   componentProps: {},
        //   breakPoints: {
        //     xs: 12,
        //     sm: 6,
        //     md: 6,
        //     lg: 6,
        //     xl: 6,
        //   },
        //   label: "REMARKS",
        //   value: "",
        //   isMandatory: true,
        // },
      },
    },
    section_5: {
      label: "",
      fields: {
        complaint: {
          key: "chief_complaint_current_complaint",
          id: "addChiefComplaingFromAddEditEnquiry",
          type: formComponentTypes.RICHTEXTEDITOR,
          labelSize: 25,
          componentProps: {
            multiline: true,
            rowsMax: 4,
            type: "textArea",
            isLimitHelperTextNeeded: true,
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          limit: 5000,
          withLabel: false,
          withBoldLabel: true,
          label: "Chief Complaint",
          value: isEdit ? (editData?.complain ? editData.complain : "") : "",
          isMandatory: true,
        },
        medical_history: {
          key: "medical_history",
          id: "addMedicalHistoryFromAddEditEnquiry",
          type: formComponentTypes.RICHTEXTEDITOR,
          labelSize: 25,
          componentProps: {
            multiline: true,
            rowsMax: 4,
            type: "textArea",
            isLimitHelperTextNeeded: true,
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          limit: 5000,
          withLabel: false,
          withBoldLabel: true,
          label: "Medical History",
          value: isEdit
            ? editData?.medicalHistory
              ? editData.medicalHistory
              : ""
            : "",
          isMandatory: true,
        },

        seen_a_doctor: {
          key: "seen_a_doctor",
          type: formComponentTypes.RADIO_BUTTON,
          id: "selectSeenADoctorFromAddEditEnquiry",
          componentProps: {
            style: { paddingBottom: 0 },
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          withBoldHeading: false,
          options: options.seen_a_doctor,
          onChangeCallBack: handleSeenAdoctorCallback,
          value: isEdit ? editData?.seenDoctor : "",
          label: "SEEN A DOCTOR ?",
          isMandatory: true,
        },
        // advice_client: {
        //   key: "advice_client",
        //   type: formComponentTypes.INPUT_BOX,
        //   withoutUppercase: true,
        //   componentProps: {
        //     style: { paddingTop: 0 },
        //   },
        //   breakPoints: {
        //     xs: 12,
        //     sm: 12,
        //     md: 12,
        //     lg: 12,
        //     xl: 12,
        //   },
        //   isDontShow: state.isSeenADoctor,
        //   withLabel: false,
        //   label:
        //     "If No, Advice client to seek medical attention. If symptoms are stable, continue with assessment",
        //   value: isEdit
        //     ? editData?.seenDoctor === "yes"
        //       ? editData.seen_a_doctor_remarks
        //       : ""
        //     : "",
        //   isMandatory: state?.isSeenADoctor ? false : true,
        // },
        advice_client: {
          key: "advice_client",
          type: formComponentTypes.SELECT_BOX,
          id: "selectAlreadySeenBeforeTimeFromAddEditEnquiry",
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
            xl: 6,
          },
          label: "If Yes, Please Select",
          value: isEdit
            ? editData?.seen_a_doctor_remarks &&
              JSON.parse(editData?.seen_a_doctor_remarks)?.constructor ===
                Object
              ? JSON.parse(editData?.seen_a_doctor_remarks)
              : editData?.seen_a_doctor_remarks
            : "",
          isDontShow: (
            isEdit ? (editData?.seenDoctor === "yes" ? true : false) : false
          )
            ? false
            : state.isSeenADoctor,
          options: seenADoctorOptions,
          // isMandatory: true,
        },
      },
    },
    section_6: {
      label: "Height/Weight (CM/KG)",
      fields: {
        complaint: {
          key: "height",
          type: formComponentTypes.INPUT_BOX,
          id: "addHeightFromAddEditEnquiry",
          componentProps: {
            type: "number",
          },
          limit: 3,
          breakPoints: {
            xs: 12,
            sm: 6,
            md: 3,
            lg: 3,
            xl: 3,
          },
          onCallback: bmiCalculation,
          withLabel: false,
          label: "Height (cm)",
          value: isEdit ? (editData?.height ? editData.height : "") : "",
          isMandatory: false,
        },
        weight: {
          key: "weight",
          id: "addWeightFromAddEditEnquiry",
          type: formComponentTypes.INPUT_BOX,
          componentProps: {
            type: "number",
          },
          limit: 3,
          breakPoints: {
            xs: 12,
            sm: 6,
            md: 3,
            lg: 3,
            xl: 3,
          },
          onCallback: bmiCalculation,
          withLabel: false,
          label: "WEIGHT (kg)",
          value: isEdit ? (editData?.weight ? editData.weight : "") : "",
          isMandatory: false,
        },
        BMI: {
          key: "BMI",
          id: "addBmiFromAddEditEnquiry",
          type: formComponentTypes.BMI,
          componentProps: {
            type: "number",
            disabled: true,
          },
          limit: 3,
          breakPoints: {
            xs: 12,
            sm: 3,
            md: 3,
            lg: 3,
            xl: 3,
          },
          label: "BMI (KG/M2)",
          value: state.bmi,
          data: state.bmi,
          // isMandatory: true,
        },
        bloodGroup: {
          key: "bloodGroup",
          id: "selectBloodGroupFromAddEditEnquiry",
          type: formComponentTypes.SELECT_BOX,
          breakPoints: {
            xs: 12,
            sm: 3,
            md: 3,
            lg: 3,
            xl: 3,
          },
          label: "BLOOD GROUP",
          value: isEdit
            ? editData?.bloodGroup
              ? editData.bloodGroup
              : ""
            : "",
          options: bloodGroupOptions(),
        },
      },
    },
    section_7: {
      label: "Activities of Daily Living (ADL)",
      fields: {
        feeding: {
          key: "feeding",
          id: "addFeedingStatusFromAddEditEnquiry",
          type: formComponentTypes.RADIO_BUTTON,
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          withBoldHeading: false,
          options: options.feeding,
          value: isEdit
            ? editData?.adl && Object.keys(JSON.parse(editData.adl)).length > 0
              ? JSON.parse(editData.adl)["feeding"]
              : ""
            : "",
          label: "FEEDING:",
          isMandatory: true,
        },
        bathing: {
          key: "bathing",
          id: "addBathingStatusFromAddEditEnquiry",
          type: formComponentTypes.RADIO_BUTTON,
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          withBoldHeading: false,
          options: options.bathing,
          value: isEdit
            ? editData?.adl && Object.keys(JSON.parse(editData.adl)).length > 0
              ? JSON.parse(editData.adl)["bathing"]
              : ""
            : "",
          label: "BATHING:",
          isMandatory: true,
        },
        toileting: {
          key: "toileting",
          id: "addToiletingStatusFromAddEditEnquiry",
          type: formComponentTypes.RADIO_BUTTON,
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          withBoldHeading: false,
          options: options.toileting,
          value: isEdit
            ? editData?.adl && Object.keys(JSON.parse(editData.adl)).length > 0
              ? JSON.parse(editData.adl)["toileting"]
              : ""
            : "",
          label: "TOILETING:",
          isMandatory: true,
        },
        grooming: {
          key: "grooming",
          id: "addGroomingStatusFromAddEditEnquiry",
          type: formComponentTypes.RADIO_BUTTON,
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          withBoldHeading: false,
          options: options.grooming,
          value: isEdit
            ? editData?.adl && Object.keys(JSON.parse(editData.adl)).length > 0
              ? JSON.parse(editData.adl)["grooming"]
              : ""
            : "",
          label: "GROOMING:",
          isMandatory: true,
        },
        ambulation: {
          key: "ambulation",
          id: "addAmbulationStatusFromAddEditEnquiry",
          type: formComponentTypes.RADIO_BUTTON,
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          withBoldHeading: false,
          options: options.ambulation,
          value: isEdit
            ? editData?.adl && Object.keys(JSON.parse(editData.adl)).length > 0
              ? JSON.parse(editData.adl)["ambulation"]
              : ""
            : "",
          label: "AMBULATION:",
          isMandatory: true,
        },
        transfer: {
          key: "transfer",
          id: "addTransferStatusFromAddEditEnquiry",
          type: formComponentTypes.RADIO_BUTTON,
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          withBoldHeading: false,
          options: options.transfer,
          value: isEdit
            ? editData?.adl && Object.keys(JSON.parse(editData.adl)).length > 0
              ? JSON.parse(editData.adl)["transfer"]
              : ""
            : "",
          label: "TRANSFER:",
          isMandatory: true,
        },
        bed_mobility: {
          key: "bed_mobility",
          id: "addBedMobilityStatusFromAddEditEnquiry",
          type: formComponentTypes.RADIO_BUTTON,
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          withBoldHeading: false,
          options: options.bed_mobility,
          value: isEdit
            ? editData?.adl && Object.keys(JSON.parse(editData.adl)).length > 0
              ? JSON.parse(editData.adl)["bed_mobility"]
              : ""
            : "",
          label: "BED MOBILITY:",
          isMandatory: true,
        },
        other_activities: {
          key: "other_activities",
          id: "addOtherActivitiesFromAddEditEnquiry",
          type: formComponentTypes.CHECK_BOX,
          onChangeCallBack: handleOtherActivitiesCallback,
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          withBoldHeading: true,
          options: options.other_activities,
          // checkbox editData.activities.split(',')
          // radiobutton editData.activities
          value: isEdit
            ? editData?.activities
              ? editData.activities.split(",")
              : []
            : [],
          label: "Other Issues",
          isMandatory: true,
        },
        other_activities_input: {
          key: "other_activities_input",
          type: formComponentTypes.INPUT_BOX,
          id: "addOtherActivitiesInputFromAddEditEnquiry",
          isDontShow: !state?.isOtherActivitiesChecked,
          componentProps: {},
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          withLabel: true,
          label: "",
          value: isEdit
            ? editData?.other_activities
              ? editData.other_activities
              : ""
            : "",
          isMandatory: state?.isOtherActivitiesChecked,
        },
      },
    },
    section_8: {
      label: "",
      fields: {
        remarks: {
          key: "remarks",
          id: "addremarksFromAddEditEnquiry",
          type: formComponentTypes.RICHTEXTEDITOR,
          labelSize: 25,
          componentProps: {
            multiline: true,
            rowsMax: 4,
          },
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          label: "REMARKS",
          value: isEdit ? (editData?.remarks ? editData.remarks : "") : "",
          isMandatory: true,
        },
      },
    },
  };
};
