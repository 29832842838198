import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from 'prop-types';

const customChipStyle = makeStyles((theme) => ({
    skeleton: {
        margin: theme.spacing(0, 1),
    },
    chip: {
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5, 1),
        backgroundColor: "rgba(37, 43, 67, 0.04)",
        color: theme.palette.text.primary,
        border: `1px solid rgba(37, 43, 67, 0.06)`
    }
}));

export function CustomChip({ label, size="small", loading=false }) {
    const classes = customChipStyle();
    const theme = useTheme()

    return (
        <>
            {loading && (
                <Skeleton variant="text" className={classes.skeleton} width={theme.spacing(6.25)}  />
            )}
            {!loading && (
                <Chip size={size} label={label} className={classes.chip} />
            )}
        </>
    );
}

CustomChip.propTypes = {
    label: PropTypes.string
}