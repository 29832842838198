import * as React from "react"

export function MiniGraphThree(props) {
  return (
    <svg width={79.038} height={62.524} viewBox="0 0 79.038 62.524" {...props}>
      <defs>
        <style>
          {
            ".prefix__c,.prefix__e{fill:none}.prefix__b,.prefix__c{stroke:#f27d35}.prefix__b{fill:#fff}.prefix__c{stroke-width:.5px;opacity:.48}.prefix__d{stroke:none}"
          }
        </style>
      </defs>
      <path
        d="M3.258 46.591l8.651-7.187 4.658 3.677 9.316-9.026 3.889-8.928 14.743 4.582 4.6-7.935s15.226 9.7 15.405 8.821.954-14.592.954-14.592l5.316-6.4 4.668-5.967"
        strokeLinecap="round"
        strokeWidth={2}
        stroke="#f27d35"
        fill="none"
      />
      <g className="prefix__b" transform="translate(71.609)">
        <circle className="prefix__d" cx={3.714} cy={3.714} r={3.714} />
        <circle className="prefix__e" cx={3.714} cy={3.714} r={3.214} />
      </g>
      <g className="prefix__b" transform="translate(-.001 42.524)">
        <ellipse className="prefix__d" cx={4} cy={3.5} rx={4} ry={3.5} />
        <ellipse className="prefix__e" cx={4} cy={3.5} rx={3.5} ry={3} />
      </g>
      <path
        className="prefix__c"
        d="M9.452 41.476v21.048M14.404 41.476v21.048M19.356 39v23.524M24.309 35.286v27.238M29.262 26.619v35.905M34.214 26.619v35.905M39.166 27.857v34.667M44.119 30.333v32.19M49.071 22.904v39.619M54.023 25.381v37.143M58.976 27.857v34.667M63.928 30.333v32.19M68.881 13v49.524M73.833 6.809v55.714"
      />
    </svg>
  )
}

