import React from "react";
import {
    withStyles,
    Grid,
    ListItem,
    List,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
} from "@material-ui/core";
import moment from "moment";
import { Draggable } from "react-beautiful-dnd";

const styles = (theme) => ({
    root: {
        boxShadow: "0px 0px 0px 1px #e0e0e0d6",
        borderRadius: 8,
        background: "#fff",
        // marginBottom: 12
    },
    upperPart: {
        borderBottom: `solid 1px #e9e9e9`,
    },
    list: {
        width: "100%",
        padding: "0px 12px",
        height: "94px",
        alignItem: "center",
        background: "#fafafa",
        [theme.breakpoints.down("xs")]: {
            padding: "0px 0px",
        },
    },
    listItem: {
        padding: "0px 12px",
        height: "94px"
    },
    avatar: {
        width: "100%",
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        height: "100%",
        background: "#e9e9e9",
        display: "flex",
        "& img": {
            margin: "auto"
        }
    },
    title_: {
        fontSize: 17,
        lineHeight: 1.7,
        [theme.breakpoints.down("xs")]: {
            fontSize: 16,
        },
    },
    sub_text: {
        fontSize: 14,
        margin: "8px 0px",
        lineHeight: 1.6,
        [theme.breakpoints.down("xs")]: {
            fontSize: 14,
        },
    },
    footer: {
        fontWeight: 400,
        fontSize: 12,
    },
    right_lg: {
        // marginRight: 30,
        [theme.breakpoints.down("xs")]: {
            marginRight: 0,
        },
    },
    right_: {
        marginRight: "-9px !important",
        [theme.breakpoints.down("xs")]: {
            marginRight: 0,
        },
    }

});


class CertificationcardList extends React.Component {

    dateformat = (st, snd,noExpiry) => {
        
        if (noExpiry) {
            const a = moment(st).format("DD MMM, YYYY");
            const c = a + " - No Expiration date";
            return c
        } else {
            const a = moment(st).format("DD MMM, YYYY");
            const b = moment(snd).format("DD MMM, YYYY");
            // const a1 = moment(st).format("YYYY");
            // const a2 = moment(snd).format("YYYY");
            // const a3 = JSON.parse(a2) - JSON.parse(a1)
            // const b1 = moment(st).format("l").split("/")[0];
            // const b2 = moment(snd).format("l").split("/")[0];
            // const b3 = JSON.parse(b2) - JSON.parse(b1)
            // const c = a + " - " + b + " • " + a3 + " yr " + b3 + " mos ";

            const finalStart = moment(a)
            const finalEnd = moment(b)

            const diffYears = finalEnd.diff(finalStart, "years")
            const diffMonths = finalEnd.diff(finalStart, "months")
            const remMonths = diffMonths - (diffYears * 12)
            const diffDays = finalEnd.diff(finalStart, "days")

            // const finalStart = moment([moment(a).year(), moment(a).month()+1, moment(a).date()])
            // const finalEnd = moment([moment(b).year(), moment(b).month()+1, moment(b).date()])
            // const diffDuration = moment.duration(finalEnd.diff(finalStart))
            // const diffYears = diffDuration.years()
            // const diffMonths = diffDuration.months()
            // const diffDays = diffDuration.days()
            const c = (!diffYears && !diffMonths) ? (diffDays ? a + " - " + b + " • " + diffDays : a + " - No Expiration date" ) + ` ${(diffDays > 1) ? `days` : (diffDays === 0 ? "" : `day`) } ` :  a + " - " + b + " • " + diffYears + ` ${diffYears > 1 ? "yrs" : "yr"} ${remMonths > 0 ? `${remMonths} ${remMonths > 1 ? "months" : "month"}` : ""}` ;

            // console.log(diffDuration.years())
            // console.log(diffDuration.months())
            // console.log(diffDuration.days())
            // 

            return c
        }
    }

    getItemStyle = (isDragging, draggableStyle) => ({
        marginBottom:12,
        ...draggableStyle
      });

    render() {
        const { classes, data, handledrawer,DroppableProvided } = this.props;
        return (
            <div>
                {/* mapping */}

                {data?.map((list, inx) => {
                    return <Draggable key={list?.dnd} draggableId={list?.dnd} index={inx}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps} 
                                style={this.getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                            >
                                <Grid key={list.id} container className={classes.root}>
                                    <Grid item xs={2} sm={2} md={1} lg={1}>

                                        {/* mapping avatar */}
                                        <div className={classes.avatar}>
                                            {list?.pic ? (
                                                <img src={list?.pic} alt="profile" />
                                            ) : (
                                                    <img src="/images/q.svg" alt="profile" />
                                                )}
                                        </div>
                                        {/* mapping avatar end*/}

                                    </Grid>
                                    <Grid item xs={10} sm={10} md={11} lg={11}>

                                        {/* mapping details */}

                                        <List className={classes.list}>
                                            <ListItem className={classes.listItem}>
                                                <ListItemText primary={<span className={classes.title_}>{list?.certificateName}</span>} secondary={<React.Fragment>
                                                    <span className={classes.sub_text}>{list?.organizationName}</span>
                                                    <br />
                                                    
                                                    <span className={classes.footer}>
                                                        {(list?.startingFrom) &&
                                                            <>
                                                                {this.dateformat(list?.startingFrom, list?.endingIn,list?.noExpiry)}</>}</span>
                                                </React.Fragment>} />
                                            </ListItem>

                                            {/* edit icon */}
                                            <ListItemSecondaryAction className={classes.right_lg}>
                                                <IconButton className={classes.right_} edge="end" onClick={() => handledrawer("Edit Certification", list, list?.id, list?.organizationId, list?.certificateName, data)}>
                                                    <img src="/images/icons8-edit.svg" width="75%" alt="edit" />
                                                </IconButton>
                                                <IconButton edge="end" onClick={() => this.props.ondltList("Add Certification", inx)}>
                                                    <img width="73%" src="/images/icons8-trash-can.svg" alt="delete" />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                            {/* enFd */}

                                        </List>

                                        {/* mapping details end */}

                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </Draggable>
                })}
                {/* mapping end */}

                {DroppableProvided.placeholder}
            </div>

        );
    }
}

export default withStyles(styles)(CertificationcardList);