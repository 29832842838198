

import React from 'react';
import { makeStyles, Grid, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    padding: 25,
  },
  dividerStyle: {
    backgroundColor: theme.palette.secondary.light,
    height: 1.3
  }
}))

const IssueDetailLoading = (props) => {

  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs>
          <Skeleton variant="text" width={300} />
          <Skeleton variant="text" width={100} />
        </Grid>
        <Grid item xs={12} sm>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Skeleton variant="circle" width={40} height={40} style={{ margin: '0px 4px' }} />
            <Skeleton variant="circle" width={40} height={40} style={{ margin: '0px 4px' }} />
            <Skeleton variant="circle" width={40} height={40} style={{ margin: '0px 4px' }} />
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.dividerStyle} />
      </Grid>
      <Grid container className={classes.root}>
        <Grid item container xs>
          <Grid item>
            <Skeleton variant="circle" width={48} height={48} style={{ marginRight: 10 }} />
          </Grid>
          <Grid item>
            <Skeleton variant="text" width={100} />
            <Skeleton variant="text" width={80} />
          </Grid>
        </Grid>
        <Grid item className={classes.flexTheButton}>
          <Skeleton variant="rect" width={160} height={40} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.dividerStyle} />
      </Grid>
      <Grid container className={classes.root}>
        <Grid item container xs={12}>
          <Grid item xs={12} md={10}>
            <Skeleton variant="text" />
          </Grid>
          <Grid item xs={12} md={9}>
            <Skeleton variant="text" />
          </Grid>
          <Grid item xs={12} md={9}>
            <Skeleton variant="text" />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Skeleton variant="text" width={80} />
        </Grid>
        <Grid item container xs={12} style={{ marginTop: 12 }}>
          <Skeleton variant="rect" width={120} height={30} style={{ marginRight: 16 }} />
          <Skeleton variant="rect" width={120} height={30} style={{ marginRight: 16 }} />
          <Skeleton variant="rect" width={120} height={30} style={{ marginRight: 16 }} />
        </Grid>
      </Grid >
    </>
  )
}

export default IssueDetailLoading
