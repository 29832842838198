import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IconButton, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const MonthNavigation = (props) => {

    const {
        selectedDate,
        onNextClicked,
        onPrevClicked,
        previous_button_id,
        next_button_id,
    } = props;

    return <div style={{ display: 'flex' }}>
        {/* Prev Button */}
        <IconButton onClick={onPrevClicked} id={previous_button_id}>
            <ChevronLeft fontSize="small" htmlColor="black"/>
        </IconButton>

        {/* Month */}
        <Typography variant="body1" color="textPrimary" component="p" style={{marginTop:10}}>{moment(selectedDate).format('MMMM YYYY')}</Typography>

        {/* Next Button */}
        <IconButton onClick={onNextClicked} id={next_button_id}>
            <ChevronRight fontSize="small" htmlColor="black"/>
        </IconButton>
    </div>
}

MonthNavigation.propTypes = {
    selectedDate: PropTypes.instanceOf(moment()),
    onNextClicked: PropTypes.func,
    onPrevClicked: PropTypes.func
}

export default MonthNavigation;