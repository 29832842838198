import * as React from "react"
import PropTypes from 'prop-types'
import { makeStyles, SvgIcon } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || "#9297a5"
  }),
  setSize: (props) => ({
    height: props.height || 20,
    width: props.width || 20
  })
}))

export function AddressIcon(props) {
  const classes = useStyles(props)
  return (
    <SvgIcon classes={{ root: classes.setSize }}>
      <g id="prefix__Address" transform="translate(-2 0)">
        <path
          id="prefix__Path_587"
          className={classes.cls1}
          d="M12 2a7 7 0 00-7 7c0 5 7 13 7 13s7-8 7-13a7 7 0 00-7-7zM7 9a5 5 0 0110 0c0 2.518-2.656 6.788-5 9.838-2.344-3.05-5-7.32-5-9.838z"
        />
        <path
          id="prefix__Path_588"
          className={classes.cls1}
          d="M16 10h-1v2a1 1 0 01-1 1h-1v-3h-2v3h-1a1 1 0 01-1-1v-2H8V9l4-3 4 3z"
        />
      </g>
    </SvgIcon>

  )
}

AddressIcon.propTypes = {
  /**Sets the color of the icon */
  color: PropTypes.string,
  /**Sets the height of the icon [Optional] */
  height: PropTypes.number,
  /**Sets the width of the icon [Optional] */
  width: PropTypes.number
}
