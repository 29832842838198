import React from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: "none",
    stroke: props.color || theme.palette.primary.main,
    "stroke-linejoin": "round",
    "stroke-miterlimit": "10",
    "stroke-width": "2px"
  }),
  cls2: (props) => ({
    fill: props.color || theme.palette.primary.main,
    stroke: props.color || theme.palette.primary.main,
    "stroke-miterlimit": "10",
    "stroke-width": "2px"
  }),
  cls3: (props) => ({
    fill: props.color || theme.palette.primary.main,
  }),
  prefix__cls: (props) => ({
    fill: "none",
    stroke: props.color || theme.palette.primary.main,
    "stroke-miterlimit": "10",
    "stroke-width": "2px"
  })
}))

export const ClientBMIIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon fontSize={"small"}
      {...props}
      style={props.style}>
      <g id="prefix__BMI" transform="translate(-5.081 -2.024)">
        <path
          id="prefix__Path_614"
          className={classes.cls1}
          d="M9 8h6l-1 7h-4z"
          data-name="Path 614"
        />
        <path
          id="prefix__Path_615"
          d="M10 22v-7"
          className={classes.prefix__cls}
          data-name="Path 615"
        />
        <path
          id="prefix__Path_616"
          className={classes.cls2}
          d="M14 22v-7"
          data-name="Path 616"
        />
        <ellipse
          id="prefix__Ellipse_277"
          cx={2}
          cy={1.979}
          className={classes.cls3}
          data-name="Ellipse 277"
          rx={2}
          ry={1.979}
          transform="translate(10 2.024)"
        />
        <path
          id="prefix__Path_617"
          d="M6 15l3-7"
          className={classes.prefix__cls}
          data-name="Path 617"
        />
        <path
          id="prefix__Path_618"
          d="M15 8l3 7"
          className={classes.prefix__cls}
          data-name="Path 618"
        />
      </g>
    </SvgIcon>
  )
}

ClientBMIIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

