import * as React from "react";
import { makeStyles, SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

export function CopyIcon(props) {
  const useStyle = makeStyles((theme) => ({
    prefix__cls: {
      fill: (props) => props.color,
    },
  }));
  const classes = useStyle(props);

  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
      htmlColor={props.color}
      style={props.style}
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      data-testid="ContentCopyIcon"
      tabindex="-1"
      title="ContentCopy"
    >
      <path className={classes.prefix__cls} d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path>
    </SvgIcon>
  );
}
CopyIcon.defaultProps = {
  color: "#9297a5",
};

CopyIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};