import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { CollapseIcon, ExpandIcon } from "./../../assets";

import { drawerWidth, navBarTopOptions } from "./utils";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Collapse,
  Typography,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { actions } from "tanyacare-middleware";
// import { routes } from "../../router/routes";
import { AuthContext } from "../../contexts";
import { localStorageKeys } from "../../utils";
import { storeTrialPopupState } from "../../utils/constants";
const { LOGOUT_AUTH } = actions;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    boxShadow: `2px 0px 4px 1px #e0e0e052`,
  },
  drawerPaper: {
    width: drawerWidth,
    border: "unset",
    boxShadow: "10px 3px 15px #0000000d"
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing(2),
  },
  topNavBarOptions: {
    // maxHeight: "70vh",
    // overflow: "auto",
  },
  bottomNavBarOptions: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    backgroundColor: "white",
  },
  parentMenu: {
    fontWeight: "500",
  },
  listItem: {
    // borderRadius: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 20,
    "&:hover ": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  activeClass: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    boxShadow: "0px 15px 25px #00000012",
    // borderRadius: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 20,
    "&:hover ": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  expandSelected: {
    color: theme.palette.ternary.main,
  },
  toplogo: {
    paddingLeft: 16,
    paddingTop: 16,
    width: 150,
  },
  list: {
    // paddingLeft: 8,
    paddingRight: 8,
  },
  listIcon: {
    paddingLeft: 12,
  },
}));

export const SideNavbar = (props) => {
  if(!(localStorage.getItem("version"))) {
    window.location.href.replace(window.location.href)
    window.location.reload()
  }
  const classes = useStyles();
  const theme = useTheme()
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state?.authState?.loginAuth);

  const [state, setState] = React.useState({
    expandend: [],
    checkedFromUrl: false,
  });

  const [navOptions, setNavOptions] = React.useState([])

  React.useEffect(()=>{
    setNavOptions(navBarTopOptions(loginData?.data?.user_role, loginData, loginData?.data?.other_roles))
  },
  // eslint-disable-next-line
  [])
  const history = useHistory();
  const location = useLocation();

  // const navOptions = navBarTopOptions(loginData?.data?.user_role);

  //Function will expand the menu if the route is a submenu
  const expandNavBar = () => {
    navOptions.map((option) => {
      //Checking the menu has a sub menu or not
      if (option.sub_menus.length) {
        //Looping the sub menu for the match of the url
        option.sub_menus.map((opt) => {
          //Determining the exact path of the url
          const path = location.pathname.split("/")?.[1];

          //Determining the exact path of the menu
          const link = option.link?.split("/")?.[1];

          if (path === link) {
            setState({
              ...state,
              expandend: [option.id],
              checkedFromUrl: true,
            });
          }
          return opt;
        });
      }
      return option;
    });
  };

  React.useEffect(() => {
    if (!state.checkedFromUrl) {
      expandNavBar();
    }
  });
  const { expandend } = state;

  const updateExpandedMenus = (option) => {

    if (!option.sub_menus.length) {
      onMenuClicked(option);
      return true;
    }

    let { expandend } = state;
    if (expandend.includes(option.id)) {
      expandend = [];
    } else {
      expandend = [option.id];
    }

    setState({
      ...state,
      expandend,
    });
  };
  const authContext = React.useContext(AuthContext);
  const onMenuClicked = (option) => {
    if (option.name === "Logout") {

      authContext.setAuth({...authContext.auth,refresh: false });
      dispatch(LOGOUT_AUTH());
      localStorage.removeItem(localStorageKeys._mGdRe);
      storeTrialPopupState(true)
      // window.location.replace(routes.login);
      return false;
    }

    history.push(option.link ? option.link : location.pathname);
  };

  const activeClassCheck = (option) => {
    if (option.sub_menus?.length) {
      return false;
    }
    if (location.pathname === option.link) {
      return true;
    } else {
      const path =
        location.pathname?.split("/")?.[1] + location.pathname?.split("/")?.[2];
      const ln = option.link?.split("/")?.[1] + option.link?.split("/")?.[2];
      return path === ln;
    }
  };

  const isMySubMenuSelected = (option) => {
    if (!option.sub_menus.length) {
      return false;
    }
    const path = location.pathname.split("/")?.[1];
    const ln = option.link?.split("/")?.[1];
    return path === ln;
  };

  const { variant, open, handelClose } = props;

  return (
    <Drawer
      className={classes.drawer}
      variant={variant}
      open={open}
      onClose={handelClose}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar} style={{cursor: "pointer"}} onClick={() => {updateExpandedMenus(navOptions.filter(op => op.name === "Dashboard")[0])}}
      >
        {/* <img
          src="/images/top_logo/toplogo.png"
          srcSet="/images/top_logo/toplogo@2x.png 2x,
             /images/top_logo/toplogo@3x.png 3x"
          className={classes.toplogo}
          alt="/images/top_logo/toplogo@2x.png"
        /> */}
        {/* <img
            src="/images/tanyaCare/login_top_logo/tanyaCareTopLogo-light.png"
            alt="Tanya.Care"
            // className={classes.logo}
            className={classes.toplogo}
          /> */}
        <img
            src="/images/tanyaCare/TanyaHorizontal.png"
            // src="/svg/tanyacareLogo/tanyacareLight.svg"
            alt="Tanya.Care"
            // className={classes.logo}
            className={classes.toplogo}
          />
        <Typography variant="caption" color="textSecondary">
          {"v" + localStorage.getItem("version") }
        </Typography>
      </div>

      {/* Navbar Top Options */}

      <List
        className={`${classes.topNavBarOptions} ${classes.list}`}
        disablePadding
        dense
      >
        {navOptions.map((option, index) => (
          <div key={index}>
            <ListItem
              style={{
                marginTop: 12,
                marginBottom: 8,
                height: 36,
              }}
              button
              disableGutters
              id={option?.button_id}
              onClick={() => updateExpandedMenus(option)}
              className={`${classes.listItem} ${
                activeClassCheck(option) ? classes.activeClass : ""
              } ${
                !expandend.includes(option.id) && isMySubMenuSelected(option)
                  ? classes.expandSelected
                  : ""
              }`}
            >
              {/* Icon of the Menu */}
              <ListItemIcon className={classes.listIcon}>
                {activeClassCheck(option)
                  ? option.selectedIcon
                  : !expandend.includes(option.id) &&
                    isMySubMenuSelected(option)
                  ? option.selectedIcon
                  : option.icon}
              </ListItemIcon>

              {/* Menu name */}
              <ListItemText
                primaryTypographyProps={{
                  variant: "body1",
                  style: {
                    color: `${activeClassCheck(option) ? "white" : ""}`,
                  },
                  className: `${classes.parentMenu} ${
                    !expandend.includes(option.id) &&
                    isMySubMenuSelected(option)
                      ? classes.expandSelected
                      : ""
                  }`,
                }}
                primary={option.name}
              />

              {/* Icon for Sub Menus */}
              {option.sub_menus.length ? (
                expandend.includes(option.id) ? (
                  <CollapseIcon style={{ marginTop: 14 }} />
                ) : (
                  <ExpandIcon
                    style={{ marginTop: 12 }}
                    htmlColor={`${
                      !expandend.includes(option.id) &&
                      isMySubMenuSelected(option)
                        ? theme.palette.ternary.main
                        : ""
                    }`}
                  />
                )
              ) : (
                ""
              )}
            </ListItem>

            {/* Expand/Collapse of Sub Menus */}
            <Collapse
              in={expandend.includes(option.id) ? true : false}
              timeout="auto"
              unmountOnExit
              disableStrictModeCompat={true}
            >
              <List component="div" disablePadding={true} dense={true}>
                {option.sub_menus.map((sub_menu, index) => (
                  <ListItem
                    style={{
                      marginTop: 2,
                    }}
                    key={index}
                    button
                    id={sub_menu?.id}
                    onClick={() => onMenuClicked(sub_menu)}
                    className={`${classes.nested} ${
                      activeClassCheck(sub_menu)
                        ? classes.activeClass
                        : classes.listItem
                    }`}
                  >
                    {/* Sub Menu name */}
                    <ListItemText
                      secondary={sub_menu.name}
                      secondaryTypographyProps={{
                        style: {
                          paddingLeft: 38,
                          color: `${activeClassCheck(sub_menu) ? "white" : ""}`,
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </div>
        ))}
      </List>

      {/* Nav Bar Bottom Options */}

    </Drawer>
  );
};

SideNavbar.propTypes = {
  variant: PropTypes.oneOf(["temporary", "permanent"]),
  open: PropTypes.bool,
  handelClose: PropTypes.func,
};

SideNavbar.defaultProps = {
  variant: "permanent",
  open: true,
  handelClose: () => true,
};


//  <List
//         className={`${classes.bottomNavBarOptions} ${classes.list}`}
//         disablePadding
//         dense
//       >
//         {navBarBottomOptions["1"].map((option, index) => (
//           <ListItem
//             style={{
//               marginTop: 12,
//               marginBottom: 8,
//               height: 36,
//             }}
//             button
//             disableGutters
//             key={index}
//             onClick={() => onMenuClicked(option)}
//             className={`${classes.listItem} ${
//               activeClassCheck(option) ? classes.activeClass : ""
//             }`}
//           >
            // {/* Icon of the Menu */}
            // <ListItemIcon className={classes.listIcon}>
            //   {activeClassCheck(option) ? option.selectedIcon : option.icon}
            // </ListItemIcon>

            // {/* Menu Name */}
      //       <ListItemText
      //         primaryTypographyProps={{
      //           variant: "body1",
      //           className: classes.parentMenu,
      //         }}
      //         primary={option.name}
      //       />
      //     </ListItem>
      //   ))}
      // </List>
