import { Grid, Typography } from "@material-ui/core";
import React from "react";
import Component from "./component";
class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  seperateReadUnread = (data) => {
    return {
      unread: data.filter((val) => val.is_read === false),
      read: data.filter((val) => val.is_read === true)
    }
    
  }

  render() {
      const {data, handleDrawer, updateNotification, openActivityDrawer, deleteNotification} = this.props

      // let sep_data = this.seperateReadUnread(data)
      
    return (
      <Grid container>
        <Grid item xs={12} style={{ padding: "10px 5px 5px 10px" }}>
          <Typography variant="h6">Notifications</Typography>
        </Grid>
        {/* <Grid item xs={12} style={{ padding: "10px 5px 5px 10px" }}>
          <Typography variant="subtitle1">Unread ({sep_data.unread.length})</Typography>
        </Grid> */}
        <Grid item xs={12}>
          {data.map((val) => (
            <Component data={val} handleDrawer={() => handleDrawer()} deleteNotification={(data) => deleteNotification(data)} updateNotification={() => updateNotification()} openActivityDrawer={(data) => openActivityDrawer(data)}/>
          ))}
        </Grid>
        {/* <Grid item xs={12} style={{ padding: "10px 5px 5px 10px" }}>
          <Typography variant="subtitle1">Read ({sep_data.read.length})</Typography>
        </Grid>
        <Grid item xs={12}>
          {sep_data.read.map((val) => (
            <Component data={val} handleDrawer={() => handleDrawer()} updateNotification={() => updateNotification()} openActivityDrawer={(data) => openActivityDrawer(data)}/>
          ))}
        </Grid> */}
        {/* <Grid item xs={12}>
          {data.map((val) => (
            <Component data={val} handleDrawer={() => handleDrawer()} updateNotification={() => updateNotification()}/>
          ))}
        </Grid> */}
      </Grid>
    );
  }
}

export default Notifications;
