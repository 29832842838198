import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { IconText } from './iconText';
import { ColorCodes } from './colorCode';
import { Rarrow, Rarrow2, Rarrow3, HeartIcon, PolygonIcon, AddImageIcon } from '../svg/star';
import { StarIcon } from '../svg/starIcon';
import { toBase64 } from '../../../../../../../utils';
import PropTypes from 'prop-types';
import { createTestProps } from '../../../../../../../utils/common';

const styles = makeStyles((theme) => ({
    flexRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    divider: {
        height: 1,
        backgroundColor: '#707070',
        opacity: 0.35,
        marginBlock: 20
    },
    customIcon:{
        position:'relative',
        '& input':{
            position:'absolute',
            inset:0,
            opacity:0,
            width:'100%',
            height:'100%',
            cursor:'pointer'
        }
    },
    customIconLabel:{
        fontSize: 12,
        color: '#6F6F6F',
        fontWeight: 400,
        marginTop:theme.spacing(3),
    },
    flexColumn:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        alignSelf:'self-end',
        marginRight:25
    }
}))

export const StarMenu = (props) => {

    const {
        activeObject = {},
        activeObjectValues = {},
        handleStarSubMenu = null
    } = props;

    const classes = styles(props);

    const ICON_LIST_ONE = [
        {
            icon: <Rarrow />,
            text: 'Arrow',
            path: `M23.714,11a.825.825,0,0,0-.7.827V18.44H4.827A.828.828,0,0,0,4,19.267v6.614a.828.828,0,0,0,.827.827H23.016v6.614a.826.826,0,0,0,1.318.646L38.39,23.22a.828.828,0,0,0,0-1.292L24.334,11.18A.829.829,0,0,0,23.714,11Zm.956,2.48,11.885,9.095L24.67,31.669V25.881a.828.828,0,0,0-.827-.827H5.654V20.094H23.843a.828.828,0,0,0,.827-.827Z`
        },
        {
            icon: <Rarrow2 />,
            text: 'Arrow 2',
            path: `M30.132,20a.394.394,0,0,0-.273.116L26.31,23.664a.394.394,0,0,0,0,.558l4.453,4.453H12.394a.394.394,0,0,0-.394.394v5.52a.394.394,0,0,0,.394.394H30.763L26.31,39.436a.394.394,0,0,0,0,.558l3.549,3.549a.394.394,0,0,0,.558,0L41.851,32.108a.394.394,0,0,0,0-.558L30.417,20.115A.394.394,0,0,0,30.132,20Zm.006.952L41.015,31.829,30.138,42.706l-2.991-2.991,4.847-4.847a.394.394,0,0,0-.279-.673H12.789V29.463H31.715a.394.394,0,0,0,.279-.673l-4.847-4.847Zm.195,2a.2.2,0,0,0-.137.339l4.14,4.14a.2.2,0,1,0,.279-.279l-4.14-4.14a.2.2,0,0,0-.142-.06Zm6.112,6.112a.2.2,0,0,0-.137.339l.986.986a.2.2,0,1,0,.279-.279l-.986-.986A.2.2,0,0,0,36.444,29.066Zm1.971,1.971a.2.2,0,0,0-.137.339l.591.591a.2.2,0,1,0,.279-.279l-.591-.591a.2.2,0,0,0-.142-.06ZM37.231,33.4a.2.2,0,0,0-.136.059l-6.9,6.9a.2.2,0,1,0,.279.279l6.9-6.9a.2.2,0,0,0-.143-.338Z`
        },
        {
            icon: <Rarrow3 />,
            text: 'Arrow 3',
            path: `M116.483,71.954a.537.537,0,0,1-.38-.157l-3.846-3.846a.537.537,0,0,1,0-.76l6.312-6.312-6.312-6.312a.537.537,0,0,1,0-.76l3.846-3.846a.537.537,0,0,1,.76,0L127.4,60.5a.537.537,0,0,1,0,.76L116.863,71.8A.537.537,0,0,1,116.483,71.954ZM113.4,67.571l3.086,3.086,9.779-9.779L116.483,51.1,113.4,54.186l6.312,6.312a.537.537,0,0,1,0,.76Z`
        },
    ]
    const ICON_LIST_TWO = [
        {
            icon: <StarIcon />,
            text: 'Star 1',
            path: 'M13.522,5a.714.714,0,0,0-.643.393l-2.452,4.881-5.822.892a.71.71,0,0,0-.4,1.2L8.236,16.38l-.9,5.8a.709.709,0,0,0,.29.686.717.717,0,0,0,.746.053l5.142-2.671,5.142,2.671a.717.717,0,0,0,.746-.053.709.709,0,0,0,.29-.686l-.9-5.8,4.027-4.009a.71.71,0,0,0-.4-1.2l-5.822-.892L14.156,5.393A.714.714,0,0,0,13.522,5Zm0,2.3L15.5,11.238a.714.714,0,0,0,.53.385l4.794.735-3.286,3.271a.709.709,0,0,0-.2.611l.737,4.77-4.222-2.193a.717.717,0,0,0-.66,0L8.965,21.01,9.7,16.24a.709.709,0,0,0-.2-.611L6.215,12.358l4.794-.735a.714.714,0,0,0,.53-.385Z'
        },
        {
            icon: <PolygonIcon />,
            text: 'Polygon',
            path: 'M 7.009174346923828 1.700000762939453 L 1.936447143554688 11 L 7.009174346923828 20.29999923706055 L 16.99082565307617 20.29999923706055 L 22.06355285644531 11 L 16.99082565307617 1.700000762939453 L 7.009174346923828 1.700000762939453 M 6 0 L 18 0 L 24 11 L 18 22 L 6 22 L 0 11 L 6 0 Z'
        },
        {
            icon: <HeartIcon />,
            text: 'Heart',
            path: 'M9.31,7A7.319,7.319,0,0,0,2,14.31c0,8.3,9.389,12.4,12.582,15.06l.351.3.351-.3c3.194-2.662,12.582-6.759,12.582-15.06A7.264,7.264,0,0,0,14.934,9.724,7.25,7.25,0,0,0,9.31,7Zm0,1.125A6.165,6.165,0,0,1,14.46,10.9l.474.7.475-.7A6.178,6.178,0,0,1,26.743,14.31c0,7.253-8.1,11.007-11.809,13.936C11.226,25.318,3.125,21.564,3.125,14.31A6.178,6.178,0,0,1,9.31,8.125Z'
        },
    ];

    const returnValue = (key) => {
        return activeObjectValues?.[key] ? activeObjectValues?.[key] : activeObject?.[key]
    }

    const handleUpload = async (e) =>{
        let file = e.target?.files[0];
        if(file){
        if(file?.type === "image/svg+xml"){
            let base = await toBase64(file);
            var decode = window.atob( base?.split(',')?.[1] );
            if(decode){
                //splitting path tag from svg
                let sliceLastPathIndex = decode.slice(decode?.lastIndexOf('<path'),decode.length);
                let sliceLastDIndex = sliceLastPathIndex?.trim() && sliceLastPathIndex?.slice(sliceLastPathIndex?.lastIndexOf('d='),sliceLastPathIndex?.length);
                // let path = sliceLastDIndex?.slice(0,sliceLastDIndex?.indexOf(" ")-1);
                let path = sliceLastDIndex?.trim() && sliceLastDIndex?.split('"')?.[1];
                if(path?.trim()){
                    handleStarSubMenu({
                        text:file?.name?.split('.')?.[0],
                        path,
                    })
                }
                else{
                    alert("unable to find path in svg")
                }
            }
            else{
                alert('unable to add icon')
            }
        }
        else{
            alert('Select only svg file')
        }
    }
    }


    return (
        <div>
            <div className={classes.flexRow}>
                {ICON_LIST_ONE?.map((_, i) => (
                    <div 
                        style={{
                            //    marginRight:i < ICON_LIST.length - 1 ? 25 : 0
                            flex: 1,
                            padding: 5
                        }}
                        key={_?.text}
                    >
                        <IconText
                            icon={_?.icon}
                            text={_?.text}
                            handleClick={() => handleStarSubMenu(_)}
                            {...createTestProps(`starIconOne${_?.text}`)}
                        />
                    </div>
                ))}
            </div>
            <div className={classes.flexRow} style={{ marginTop: 20 }}>
                {ICON_LIST_TWO?.map((_, i) => (
                    <div
                        key={_?.text}
                        style={{
                            //    marginRight:i < ICON_LIST.length - 1 ? 25 : 0
                            flex: 1,
                            padding: 5
                        }}
                    >
                        <IconText
                            icon={_?.icon}
                            text={_?.text}
                            handleClick={() => handleStarSubMenu(_)}
                            {...createTestProps(`shapesIconTwo${_?.text}`)}
                        />
                    </div>
                ))}
            </div>
            <div className={classes.divider}></div>
            <div className={classes.flexRow}>
                <div className={classes.flexColumn}>
                    <div className={classes.customIcon}>
                        <AddImageIcon />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleUpload}
                            {...createTestProps(`add custom icon`)}
                        />
                    </div>
                    <Typography className={classes.customIconLabel}>{'Custom Icon'}</Typography>
                </div>

                <ColorCodes
                    text="Color"
                    value={returnValue('fill')}
                    handleChange={(value) => handleStarSubMenu(value, 'fill')}
                    {...createTestProps(`star menu color code`)}
                />
            </div>
        </div>
    )
}

StarMenu.propTypes = {
    activeObject: PropTypes.object,
    activeObjectValues: PropTypes.object,
    handleStarSubMenu: PropTypes.func,
}