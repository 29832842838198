import * as React from "react"

export const RightAlignIcon = (props) => {

    const {
        width = "20",
        height = "20",
        color = "#6f6f6f"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 20 20"
        >
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_48014" data-name="Rectangle 48014" width="20" height="20" fill="#6f6f6f" />
                </clipPath>
            </defs>
            <g id="Mask_Group_7" data-name="Mask Group 7" clip-path="url(#clip-path)">
                <path id="icons8-left-align" d="M21.854-6.126H40.409A.6.6,0,0,1,41-5.533a.6.6,0,0,1-.593.593H21.854a.6.6,0,0,1-.593-.593A.6.6,0,0,1,21.854-6.126ZM40.409-.02H27.367a.6.6,0,0,1-.593-.593.6.6,0,0,1,.593-.593H40.409A.6.6,0,0,1,41-.613.6.6,0,0,1,40.409-.02ZM21.854,3.774H40.409a.593.593,0,0,1,0,1.186H21.854a.593.593,0,0,1,0-1.186Zm10.2,4.92h8.359a.593.593,0,0,1,0,1.186H32.05a.593.593,0,1,1,0-1.186Z" transform="translate(-21.603 8.513)" 
                fill={color}
            />
            </g>
        </svg>
    );
};


