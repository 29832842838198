
import React from "react";
import {
    Button,
    Grid,
    Typography,
    Container,
    // TextField,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
// import { routes } from "../../router/routes";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./style";

// import { NavLink } from "react-router-dom";
import Card from "@material-ui/core/Card";

// SubScreenComponents
import HeaderLogo from '../../components/login/headerLogo'
import LeftPane from '../../components/login/LeftPane'

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";

// validation - utils
// import { validateEmail } from "../../utils/validations";

//Icons
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';

// import { AuthContext } from '../../contexts';

// const style = (theme) => ({
//     svg_icon: {
//         fontSize: "6 rem"
//     }
// })

class PageExpired extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            isError: {
                username: null,
                // error: false,
            },
            data: "",
            message: "",
        };
    }

    /* // Handles validation
    handleValidation = (validation, value) => {
        if (value.length === 0) {
            return false;
        } else {
            if (validation === "username") {
                if (validateEmail(value)) {
                    return true;
                } 
                else {
                //  this.setState({message: "Invalid Email ID"})
                 return false;
                }
            } else {
                return true;
            }
        }
    };

    // Handles input change
    handleChange = (e, currentstate) => {
        const state = this.state;
        state.isError[currentstate] = this.handleValidation(
            currentstate,
            e.target.value
        );
        state[currentstate] = e.target.value;
        this.setState(state);
    };

    handleKeyPress = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            document.getElementById("sendCodeButton").click();
        }
    };

    handleSendCode = async() => {
        if(this.state.username !== "")
        {
            const data = await this.props.FORGET_PASSWORD({email:this.state.username})
            console.log(data.payload)
            if(data.payload.state === 200){
                this.setState({ data: data.payload.data.data.message})
            }
        }
        else
        {
            this.setState({message: "Email ID cannot be empty"})
        }
    }; */

    // Handle back to signin
    handleBackToSignin = () => {
        this.setState({data: ""})
        this.props.history.push('/');
    }

    endAdornmentHandler = () => {
        const usernameError = this.state.isError.username
        if (usernameError !== null) {
            return usernameError ?
                <DoneIcon htmlColor="rgb(90, 192, 172)" />
                : <CloseIcon htmlColor="rgba(201, 87, 114, 1)" />
        }
    }

    render() {
        const { classes } = this.props;
        // const { username } = this.state;

        

        // const { loading} = this.props?.forgetPassword;

        return (
            <Container maxWidth="lg">
                <Grid container className={classes.root}>
                    <Card className={classes.rootCard}>
                        {/* Header Logo */}
                        <HeaderLogo />

                        {/* Main Card Container */}
                        <Grid container item xs={12} className={classes.mainContent}>

                        
                            <LeftPane />

                           
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                className={classes.formGrid}
                            >
                                
                                    
                                        <>
                                        <Grid item xs={12} className={classes.formHeader} style={{marginBottom: 25}}>
                                        <BlockOutlinedIcon htmlColor="#f5bca0" classes={{root: classes.svg_icon}}/>
                                        <Typography
                                                variant="h2"
                                                component="h2"
                                                className={classes.mainHeading}
                                            >
                                                Link Expired !
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="p"
                                                className={classes.subHeading}
                                            >
                                                This link is expired since it is not used for more than 24 hours. <br/> Please contact out support team for further help.
                                            </Typography>
                                        </Grid>

                                {/* ------------------------------  */}
                                <Grid container item xs={12} justify="center">
                                    <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>

                                        
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                id="sendCodeButton"
                                                // disabled={loading}
                                                onClick={this.handleBackToSignin}
                                                className={classes.sendCodeButton}
                                                style={{marginTop: 0}}
                                            >
                                                Back to Sign In
                                               
                                            </Button>
                                        </Grid>

                                        
                                        
                                    </Grid>



                                    {/* ---------------------------- */}
                                </Grid>
                                </>
                                
                               
                                
                            </Grid>


                        </Grid>
                    </Card>
                </Grid>
            </Container>
        );
    }
}

// Login.contextType = AuthContext;

const mapStateToProps = (state) => ({
    loginState: state?.authState?.loginAuth,
    forgetPassword: state?.authState?.forgetPassword
});

export default connect(
    mapStateToProps,
    actions
)(withStyles(styles, { withTheme: true })(withRouter(PageExpired)));
