import React, { Component } from "react";
import PropTypes from "prop-types";
import AddEditDynamicFromRenderer from "../../../addEditDynamicFromRenderer";
// import { components } from "../../../addEditDynamicFromRenderer/components";
import { withRouter } from "react-router-dom";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { alertProps, base64MimeType, converToReactSelect, formComponentTypes, upload_file, user_roles } from "../../../../utils";
// options
import { routes } from "../../../../router/routes";
import { Grid, Paper, withStyles, Typography } from "@material-ui/core";
import {
  LoadingSection,
  SelectFromPlaceholder,
  SimpleSelect,
} from "../../../../components";
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment'
import {
  checkBranchOrProvider,
  labelforProvider,
  checkBranchOrProviderError,
  redirectURL,
} from "../../utils";

const style = (theme) => ({
  // "& .MuiPaper-elevation1": (props) => ({ boxShadow: !props.withBoxShadow ? 'none !important' : '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }),
  root: {
    width: "100%",
    // padding:"10px",
    padding: "8px 22px 16px 22px",
    marginBottom: 50,
    boxShadow: (props) =>
      props.withoutBoxShadow
        ? "none !important"
        : "0 3px 6px 0 rgb(0 0 0 / 4%)",
    background: (props) =>
      props.withoutBoxShadow ? "transparent !important" : "white",
  },
});

class AddEditProvider extends Component {
  constructor(props) {
    super();
    this.state = {
      domain: null,
      domainCategory: null,
      editFormJson: {},
      loadedDomainCategoryOptions: [],
      loadedDomainOptions: [],
      componentForm: {},
      loading: false,
    };
  }

  async componentDidMount() {
    if (
      routes.partner_management_branch_add === this.props?.match?.path
      // routes.partner_management_branch_edit === this.props?.match?.path
    ) {
      if (this.props?.match?.params?.id) {
        const id = this.props?.match?.params?.id;
        this.setState({ loading: true });
        await this.props.COLLECT_PARTNER_META({ partnerId: id });
      }
    } else {
      if (this.props?.match?.params?.id) {
        const id = this.props?.match?.params?.id;
        this.setState({ loading: true });
        await this.props.GET_CARE_PROVIDER_INFO({ id });
      }
    }
    this.getEssentials();
  }

  // ComponentDidMount End
  getEssentials = async () => {
    // this.props.GET_MASTER_CERT_ORGANIZATION();
    // this.props.GET_MASTER_EXP_ORGANIZATION();
    // this.props.GET_MASTER_CERTIFICATES();
    // this.props.GET_MASTER_EXP_ROLES();
    this.props.GET_ALL_DOMAIN();
    this.props.GET_ALL_CARE_PROVIDER_DROPDOWN()
    this.props.GET_ALL_CARE_PROVIDER({
      ...this.state,
      // category: this.state.category.map((_) => _.value),
      // products: this.state.products.map((_) => _.value),
      // status: this.state.status,
      // sortDirec:
      //   this.state.sort === "az" ? sortDirection.asc : sortDirection.desc,
      // sortfield:
      //   this.state.sort === "az" || this.state.sort === "za"
      //     ? sortFields.provider.name
      //     : sortFields.provider.created_at,
      parentPartnerId: "",
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Add Product
    const { careProvider } = nextProps;
    const oldcareProvider = this.props.careProvider;
    //Checking for Add Package messages

    if (oldcareProvider?.loading !== careProvider?.loading) {
      if (!careProvider.loading && !careProvider.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: careProvider.message
            ? careProvider.message
            : checkBranchOrProvider(this.props.match.path),
          severity: alertProps.severity.success,
        });
        // this.props.history.push(routes.partner_management_provider);
        this.props.history.push(redirectURL(this.props?.match?.path));
      } else if (careProvider.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: careProvider.message
            ? careProvider.message
            : checkBranchOrProviderError(this.props.match.path),
          severity: alertProps.severity.error,
        });
      }
    }

    //UPDATE PARTNER
    const { updateCareProvider } = nextProps;
    const oldupdateCareProvider = this.props.updateCareProvider;
    //Checking for Add Package messages
    if (oldupdateCareProvider?.loading !== updateCareProvider?.loading) {
      if (!updateCareProvider.loading && !updateCareProvider.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: updateCareProvider.message
            ? updateCareProvider.message
            : checkBranchOrProvider(this.props.match.path),
          severity: alertProps.severity.success,
        });
        this.props.history.push(
          `${redirectURL(this.props?.match?.path)}view/${this.props?.match?.params?.id
          }`
        );
      } else if (updateCareProvider.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: updateCareProvider?.message ?? "Something went wrong! Unable to Update Provider.",
          severity: alertProps.severity.error,
        });
      }
    }

    //Add provider info
    const { careProviderInfo } = nextProps;
    const oldcareProviderInfo = this.props.careProviderInfo;
    //Checking for Add Package messages

    if (oldcareProviderInfo?.loading !== careProviderInfo?.loading) {
      if (!careProviderInfo.loading && !careProviderInfo.error) {
        console.log(careProviderInfo?.data);

        let { data: careProviderInfoData } = careProviderInfo;
        this.setState({
          domain: converToReactSelect(
            careProviderInfoData?.[0]?.partnerDomain,
            "domainId",
            "domainName"
          ),
          domainCategory: converToReactSelect(
            careProviderInfoData?.[0]?.partnerCategory,
            "categoryId",
            "categoryName"
          ),
          componentForm: this.dropDownCallBacks(
            null,
            null,
            careProviderInfoData?.[0]?.partnerMeta,
            null
          ),
          // careProviderInfoData?.[0]?.partnerMeta,
        });
      }
    }

    //Add provider info
    const { branchGetFromInfo } = nextProps;
    const oldbranchGetFromInfo = this.props.branchGetFromInfo;
    //Checking for Add Package messages
    if (oldbranchGetFromInfo?.loading !== branchGetFromInfo?.loading) {
      if (!branchGetFromInfo.loading && !branchGetFromInfo.error) {
        console.log(branchGetFromInfo?.data);
        let { data: branchFormData } = branchGetFromInfo;

        this.setState({
          domain: branchFormData?.[0],
          domainCategory: branchFormData?.[0]?.partnerCategory?.[0],
          componentForm: this.dropDownCallBacks(
            null,
            null,
            branchFormData?.[0]?.meta,
            null
          ),
        });
      }
    }
    return true;
  }

  onSave = async (formWithData, data, partnerDomain, partnerCategory) => {
    let actionToDispatch = {};
    // actionToDispatch['actionName'] = "ADD_CARE_PROVIDER"
    actionToDispatch["bodyParams"] = {};
    actionToDispatch["bodyParams"]["partnerName"] =
      data?.business_registered_name;
    actionToDispatch["bodyParams"]["partnerResource"] = [];

    actionToDispatch["bodyParams"]["primaryEmail"] =
      data?.primary_email_address;
    actionToDispatch["bodyParams"]["isActive"] = true;

    if (routes.partner_management_branch_add === this.props?.match?.path) {
      actionToDispatch["bodyParams"][
        "parentPartnerId"
      ] = this.props?.match?.params?.id;
      actionToDispatch["bodyParams"]["partnerDomain"] = [
        this.state.domain?.value,
      ];
    } else {
      actionToDispatch["bodyParams"]["parentPartnerId"] = "";
      actionToDispatch["bodyParams"]["partnerDomain"] = [
        this.state.domainCategory?.value,
      ];
    }

    // disabling fields here ....
    if (Object.keys(formWithData).includes("primary_email_address")) {
      let { primary_email_address } = formWithData;
      primary_email_address.componentProps = {
        ...primary_email_address.componentProps,
        disabled: true,
      };

      actionToDispatch["bodyParams"]["partnerMeta"] = formWithData;
    } else {
      actionToDispatch["bodyParams"]["partnerMeta"] = formWithData;
    }


    // UPLOADING IMAGE BEFORE SAVING THE DATA
    if (formWithData?.uploadImage?.value) {
      if (base64MimeType(formWithData?.uploadImage?.value)?.length > 0) {
        let { fileName, withoutType } = upload_file(
          formWithData?.uploadImage?.value
        );
        await this.props
          .FILE_UPLOAD({
            file: withoutType,
            fileName: fileName,
            isbase64: true,
          })
          .then(async (val) => {
            actionToDispatch["bodyParams"]["partnerMeta"]["uploadImage"][
              "value"
            ] = await val?.payload?.data?.fileid;
          });
      } else {
        actionToDispatch["bodyParams"]["partnerMeta"]["uploadImage"][
          "value"
        ] = formWithData?.uploadImage?.value?.split("/")[
          formWithData?.uploadImage?.value?.split("/").length - 1
          ];
      }
    }



    // CHECK IS EDIT OR NOT AND CALL THE ACTION
    if (this.props.isEdit) {
      const { data } = this.props?.careProviderInfo || {}
      actionToDispatch["bodyParams"][
        "partnerId"
      ] = this.props?.match?.params?.id;
      actionToDispatch["bodyParams"]["partnerCategory"] = (Array.isArray(data?.["category"]) ? data?.["category"] : []).map(
        (value) => value.value
      );

      const getFlagValue = () => {

        const editedEffective =
          actionToDispatch["bodyParams"]["partnerMeta"]["resource_validity"]
            ?.value?.resourceEffectiveFrom;
        const dbEffective =
          data?.[0]?.["partnerMeta"]?.["resource_validity"]?.value
            ?.resourceEffectiveFrom;

        const editedEndsOn =
          actionToDispatch["bodyParams"]["partnerMeta"]["resource_validity"]
            ?.value?.resourceEndsOn;
        const dbEndsOn =
          data?.[0]?.["partnerMeta"]?.["resource_validity"]?.value
            ?.resourceEndsOn;

        if (!moment(editedEffective)?.isSame(dbEffective)) {
          return true;
        } else if (editedEndsOn && dbEndsOn) {
          if (!moment(editedEndsOn)?.isSame(dbEndsOn)) {
            return true;
          }
        } else if ((!editedEndsOn && dbEndsOn) || (editedEndsOn && !dbEndsOn)) {
          return true;
        }

        return false
      };
      if(formWithData?.["category"]?.["value"]?.length > 0 ){

        actionToDispatch["bodyParams"]["partnerCategory"] = (formWithData?.["category"]?.["value"] ?? [])?.map(
          (value) => value.value
        );
      }

      let payload = {
        ...actionToDispatch.bodyParams,
        effectiveFrom: actionToDispatch["bodyParams"]["partnerMeta"]["resource_validity"]?.value?.resourceEffectiveFrom,
        EndsOn: actionToDispatch["bodyParams"]["partnerMeta"]["resource_validity"]?.value?.resourceEndsOn ?? "",
        isEffectiveFromToValidation: getFlagValue()
      }
      if (!data?.[0]?.emailVerified) {
        if (
          payload.effectiveFrom.getTime() ===
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          ).getTime()
        ) {
          alert("INSIDE")
          payload.isActive = true;
        }
      }
      else{
        payload.isActive = data?.[0]?.isActive
      }

      console.clear()
      console.log(payload)
      this.props.UPDATE_CARE_PROVIDER_INFO(payload);
    } else {
      actionToDispatch["bodyParams"]["partnerCategory"] = data["category"].map(
        (value) => value.value
      );

      // if(data?.uploadImage){

      //   this.props.FILE_UPLOAD({FileBase64:data?.uploadImage, fileName:"fileName"}).then(val=>{
      //     this.props.ADD_CARE_PROVIDER(actionToDispatch.bodyParams);
      //   })
      // }
      // else{
      //   this.props.ADD_CARE_PROVIDER(actionToDispatch.bodyParams);
      // }

      // Check if the adding resource is a branch, if so then validate. Else skip validation.
      const isErrorFree = (user_roles.provider !== this.props.auth_data.data.user_role &&
        this.props?.match?.path === routes.partner_management_branch_add) ? await this.handleActivateDeactivateValidations(actionToDispatch["bodyParams"]["parentPartnerId"], actionToDispatch["bodyParams"]["partnerMeta"]["resource_validity"]) : true

      if (isErrorFree) {
        this.props.ADD_CARE_PROVIDER(actionToDispatch.bodyParams);
      }
    }
  };


  handleActivateDeactivateValidations = async (partnerId, resource_validity) => {
    const startDate = new Date(resource_validity?.value?.resourceEffectiveFrom)?.toISOString()
    const endDate = resource_validity?.value?.resourceEndsOn ? new Date(resource_validity?.value?.resourceEndsOn)?.toISOString() : ""

    await this.props?.GET_PARTNER_ACTIVE_DATE_BY_SUPERVISOR({ partnerId: partnerId, startDate: startDate, endDate: endDate })
    const { data } = this.props?.getPartnerActiveStatusBySupervisor

    if (Array.isArray(data) && data?.length > 0) {
      // No issue in adding Branch
      return true
    } else {
      // Issue occured while adding branch
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: `Parent Partner may not be active on the selected dates. Please select different date.`,
        severity: alertProps.severity.error,
      });
    }

  }


  // DROPDOWN CALLBACKS AND DROPDOWN PRELOADING

  dropDownCallBacks = (value, key, formJson, currentDynamicFormState) => {

    if (value) {
      let _componentJSON = formJson;
      let _componentJSONKeys = Object.keys(formJson);

      if (_componentJSON?.[key]?.["depend"]?.length > 0) {
        _componentJSON[key]["depend"].map((key) => {
          if (_componentJSON[key]?.requiredAction) {
            let _actionName = _componentJSON[key]?.requiredAction[0];
            return this.props[_actionName](
              _componentJSON[key]?.requiredPayload ?? {}
            ).then((val) => {
              let _obj = { ..._componentJSON[key] };
              _obj["options"] = val?.payload?.data ?? [];
              _componentJSON = { ..._componentJSON, [key]: _obj };
              return val;
            });
          }
          return true;
        });
        let promises = _componentJSONKeys.map((key) => {
          return true;
        });
        Promise.all(promises).then((results) => {
          this.setState({ loading: false, componentForm: _componentJSON });
        });
      } else {


        _componentJSONKeys.map((val) => {
          let _obj = {}
          if (
            String(val).toLocaleLowerCase() === String(key).toLocaleLowerCase()
          ) {
            _obj = { ..._componentJSON[key] };
            _obj["value"] = value;
            _componentJSON = { ..._componentJSON, [key]: _obj };
          } else {
            _obj = { ..._componentJSON[val] };
            if (_obj['element'] === formComponentTypes.MOBILE_INPUT_BOX) {
              _obj["value"] = { mobile_code: Array.isArray(currentDynamicFormState[_obj['key']['select_key']]) ? currentDynamicFormState[_obj['key']['select_key']]?.[0] : currentDynamicFormState[_obj['key']['select_key']], mobilenumber: currentDynamicFormState[_obj['key']['input_value_key']] };
              _componentJSON = { ..._componentJSON, [val]: _obj };
            } else if (_obj['element'] === formComponentTypes.RESOURCE_VALIDITY) {
              _obj["value"] = { resourceEffectiveFrom: currentDynamicFormState[_obj['key']['startDate']], resourceEndsOn: currentDynamicFormState[_obj['key']['endDate']] };
              _componentJSON = { ..._componentJSON, [val]: _obj };
            } else {
              if (currentDynamicFormState[val]) {
                _obj["value"] = currentDynamicFormState[val];
                _componentJSON = { ..._componentJSON, [val]: _obj };
              }
            }
          }
          return true;
        });
      }

      this.setState({ loading: false, componentForm: _componentJSON });
      // else{
      //   let _componentJSON = formJson;
      //   _componentJSON.[key].["value"] = value
      // }
    } else {
      // SETTING THE DROPDOWN OPTIONS AT THE TIME OF FORM RENDERING

      let _componentJSON = formJson?.["meta"] ? formJson["meta"] : formJson;
      let _componentJSONKeys = _componentJSON ? Object.keys(_componentJSON) ?? [] : [];

      let promises = _componentJSONKeys.map((key) => {
        if (_componentJSON[key]?.requiredAction) {
          let _actionName = _componentJSON[key]?.requiredAction[0];
          if (
            _componentJSON[key]?.options?.constructor === Array &&
            _componentJSON[key]?.options?.length > 0
          ) {
            return true;
          }

          return this.props[_actionName]({}).then((val) => {
            let _obj = { ..._componentJSON[key] };

            _obj["options"] = val?.payload?.data ?? [];
            _componentJSON = { ..._componentJSON, [key]: _obj };
            return val;
          });
        }
        return true;
      });
      Promise.all(promises).then((results) => {
        this.setState({ loading: false, componentForm: _componentJSON });
      });
    }
  };

  // FOR CHOOSING DOMAIN AND DOMAINCATEGORY

  onChangeDropDown = (value, key, cleanUpKeys) => {
    if (value) {
      let _obj = {};
      _obj[key] = value;
      if (cleanUpKeys?.length > 0) cleanUpKeys.map((val) => (_obj[val] = null));
      this.setState({ ..._obj }, () => {
        if (key === "domainCategory") {
          this.setState({ loading: true }, () => {
            this.dropDownCallBacks(null, null, this.state.domainCategory);
          });
        }
      });
    } else {
      let _obj = {};
      _obj[key] = value;
      if (cleanUpKeys?.length > 0) cleanUpKeys.map((val) => (_obj[val] = null));
      this.setState({ ..._obj });
    }
  };
  render() {
    const { classes } = this.props;
    const loadOptions = (selectedDomain) => {
      if (selectedDomain) {
        const allDomains = this.props?.allDomains?.data;
        // error, loading

        const subDomains = allDomains?.filter(
          (domain) => domain.value === selectedDomain.value
        );
        this.setState({
          loadedCategoryOptions: subDomains[0].domainCat,
        });
      } else {
        this.setState({
          loadedCategoryOptions: [],
        });
      }
    };

    const allDomains = this.props?.allDomains?.data;

    //
    console.log(this.props?.careProviderDropDownListing)

    // error, loading
    return (
      <div style={{ padding: 25 }}>
        {!this.props?.match?.params?.id && (
          <Paper className={classes.root}>
            {/* <Typography
              variant="h6"
              component={"h6"}
            >
              Please choose the below dropdowns
            </Typography> */}
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <SimpleSelect
                  label="Industry"
                  isMulti={false}
                  id="domain"
                  options={allDomains}
                  value={this.state?.domain}
                  handleOnChange={(e) => {
                    loadOptions(e);
                    this.onChangeDropDown(e, "domain", ["domainCategory"]);
                  }}
                // error={this.state.error.provider ? true : false}
                // errorText={errorMessage.requiredMessage}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SimpleSelect
                  label="Business nature"
                  isMulti={false}
                  id="domain_category"
                  // options={this.state.allCategoryLists}
                  options={this.state.loadedCategoryOptions}
                  value={this.state?.domainCategory}
                  handleOnChange={(e) => {
                    this.onChangeDropDown(e, "domainCategory");
                  }}
                // error={this.state.error.provider ? true : false}
                // errorText={errorMessage.requiredMessage}
                />
              </Grid>
            </Grid>
          </Paper>
        )}
        {user_roles.provider !== this.props.auth_data.data.user_role &&
          this.props?.match?.path === routes.partner_management_branch_add && (
            <Paper className={classes.root}>
              {/* <Typography
              variant="h6"
              component={"h6"}
            >
              Please choose the below dropdowns
            </Typography> */}
              <Grid
                container
                direction="row"
                // justify="space-around"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={12} sm={6}>
                  {
                    this.props?.careProviderDropDownListing?.data?.length > 0
                      ?
                      <Grid container item xs={12}>
                        <Typography variant="h6" component="h6">
                          <b>Partner<span style={{ padding: '0 5px' }}>:</span></b>
                        </Typography>
                        <Typography variant="h6" component="h6" >
                          {

                            this.props?.careProviderDropDownListing?.data?.length > 0
                              ?
                              this.props?.careProviderDropDownListing?.data
                                .filter(
                                  (val) => val.id === this.props?.match?.params?.id
                                )[0]?.name
                              : 'N/A'}
                        </Typography>
                      </Grid>
                      :
                      <Skeleton variant="text" width={150} height={30} />
                  }


                  {/* <SimpleSelect
                    label="Partner"
                    isMulti={false}
                    id="partner"
                    options={converToReactSelect(
                      this.props?.careProviderDropDownListing?.data,
                      "id",
                      "name"
                    )}
                    disabled
                    value={
                      this.props?.careProviderDropDownListing?.data?.length > 0
                        ? converToReactSelect(
                            this.props?.careProviderDropDownListing?.data,
                            "id",
                            "name"
                          ).filter(
                            (val) => val.id === this.props?.match?.params?.id
                          )[0]
                        : []
                    }
                    handleOnChange={(e) => {
                      loadOptions(e);
                      this.onChangeDropDown(e, "partner");
                    }}

                  /> */}
                </Grid>
              </Grid>
            </Paper>
          )}
        {this.state.loading && <LoadingSection message="Fetching data..." />}
        {!this.state.loading &&
          (!this.state?.domain || !this.state?.domainCategory) && (
            <SelectFromPlaceholder message="Please choose the form type" />
          )}
        {!this.state.loading &&
          this.state?.domain &&
          this.state?.domainCategory && this.state?.componentForm?.constructor === Object && Object.keys(this.state?.componentForm).length === 0 && (
            <SelectFromPlaceholder message="Sorry can't load the form at the moment!" />
          )}
        {!this.state.loading &&
          this.state?.domain &&
          this.state?.domainCategory &&
          this.state?.componentForm?.constructor === Object && Object.keys(this.state?.componentForm).length > 0
          && (
            <AddEditDynamicFromRenderer
              actionToDispatch={this.onSave}
              dropDownCallBacks={this.dropDownCallBacks}
              componentForm={this.state.componentForm}
              saveButtonLabel={labelforProvider(
                this.props?.match?.path,
                this.props?.careProvider?.loading,
                this.props?.updateCareProvider?.loading
              )}
              loading={
                this.props?.careProvider?.loading ||
                this.props?.updateCareProvider?.loading
              }
              cancelButtonId={`CancelPartnerButton`}
              saveButtonId={`AddPartnerButton`}
              {...this.props}
            />
          )}
      </div>
    );
  }
}

AddEditProvider.propTypes = {
  data: PropTypes.array,
};
const mapStateToProps = (state) => ({
  careProvider: state?.careProvider?.addCareProvider,
  auth_data: state?.authState?.loginAuth,
  careProviderInfo: state.careProvider?.careProviderInfo,
  allDomains: state?.careProvider?.allDomain,
  branchGetFromInfo: state?.careProvider?.collectMeta,
  updateCareProvider: state?.careProvider?.updateCareProvider,
  careProviderDropDownListing: state.careProvider?.allCareProvider,
  getPartnerActiveStatusBySupervisor: state.resourceReducer?.getPartnerActiveStatusBySupervisor,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withStyles(style)(withRouter(AddEditProvider))));
