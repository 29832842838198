import React from "react";
import "date-fns";
import { Paper, withStyles, Grid, Button, Divider } from "@material-ui/core";
import {
  UploadProfileImage,
  TextField,
  InputAdornmentComponent,
  SimpleSelect,
} from "../../../../components";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

// import { SelectandFilter } from "../../../../components/SelectandFilters/index"
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import Certificationcard from "../../../../components/certification/certification_card";
import Experiencecard from "../../../../components/experience/experience_card";
import { withRouter, Prompt } from "react-router-dom";
import {
  alertProps,
  getCountryCodeOptions,
  user_roles,
} from "../../../../utils";
import { checkFirstZeroAndAllZero } from "../../../../utils/validations";
import { errorMessage } from "../../../../utils/errorMessages";
import {
  validateEmail,
  getMobileLimitBasedOnCC,
} from "../../../../utils/validations";
import { AlertContext } from "../../../../contexts";
import { routes } from "../../../../router/routes";
// import AppDrawer from "../../../../App.drawer"
// import { DateTimePickersCustom } from "../../../../components/dateTimePicker/dateTimePicker";
import moment from "moment";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import CertificateDrawer from "./certificateDrawer";
// import { parsePhoneNumber } from "libphonenumber-js";
// import { routes } from "../../../../router/routes";

const styles = (theme) => ({
  paperCard: {
    borderRadius: "0px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04)",
  },
  paddins: {
    padding: "25px",
  },
  strong_name: {
    display: "inline-block",
    paddingTop: 12,
    color: theme.palette.primary.dark,
    fontSize: 14,
    paddingLeft: "40px",
  },
  saveButton: {
    padding: "5px 40px",
  },
  addbtn: {
    marginTop: 16,
    padding: "8px 18px",
    fontSize: 15,
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      padding: "6px 10px",
    },
    "&:hover": {
      marginTop: 16,
      padding: "8px 18px",
      fontSize: 15,
      margin: "auto",
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
        padding: "6px 10px",
      },
    },
  },
  addbtn1: {
    marginTop: 16,
    padding: "8px 18px",
    fontSize: 15,
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      padding: "6px 10px",
    },
    "&:hover": {
      marginTop: 16,
      padding: "8px 18px",
      fontSize: 15,
      margin: "auto",
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
        padding: "6px 10px",
      },
    },
  },
  saveButtonGrid: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  certification: {
    padding: 12,
  },
  dividerBackground: {
    width: "100%",
    marginTop: 8,
    backgroundColor: theme.palette.secondary.light,
  },
  large: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    position: "fixed",
    right: "410px",
    cursor: "pointer",
    top: 10,
    "& img": {
      objectFit: "scale-down",
      backgroundColor: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      right: "313px",
    },
  },
  drawer_title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 12,
  },
  svgColor: {
    fill: theme.palette.secondary.main,
    fontSize: 10,
  },
  textField: {
    marginTop: theme.spacing(3),
  },
  dlt: {
    bottom: 0,
    position: "absolute",
    height: "60px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: 16,
    textTransform: "uppercase",
    "& span": {
      color: "#f27d35",
      marginLeft: 8,
    },
  },
  errmsg: {
    color: "rgba(188, 45, 79, 1)",
    fontSize: "0.8rem",
    marginLeft: 22,
  },
});

const initialState = {
  profile_pic: "",
  uploadImage: "",
  name: "",
  mobileNo: "",
  phoneCode: "",
  email: "",
  // address: "",
  addressLine1: "",
  addressLine2: "",
  area: "",
  state: "",
  country: "",
  postalCode: "",
  // ADDRESS FIELDS NEWLY ADDED
  license_description: [],
  license_no: "",
  skills: [],
  category: [],
  careProvider: [],
  allPhoneCodes: [],
  isBlock: false,
  iseditexperience: null,
  open: false,
  drawer_title: null,
  isedit: null,
  name_ck: null,
  isdelete: false,
  isdialog: true,
  name_ckm: null,
  cc_isNew: null,
  co_isNew: null,
  eo_isNew: null,
  ec_isNew: null,
  role: null,
  organisation_name: null,
  start_date: null,
  end_date: null,
  certificate: [],
  experience: [],
  specialization: [],
  present: false,
  service_provider: [],
  service_coordinator: [],
};

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      allSalutationList: [],
      isSaving: false,
      err: {},
      error: {},
      searchKey: "",
      sortKey: 1,
    };
  }

  // Used in Adding certificate/Experience to state
  addListToState = (data) => {
    let list = data?.map((val, index) => {
      let tempList = Object.assign({}, val);
      tempList.dnd = `"${index + 1}"`;
      tempList.orderNo = index + 1;
      return tempList;
    });
    return list;
  };

  getFormattedMobileCodeState = (phoneCode) => {
    let flag = phoneCode.toString().includes("+");
    if (!flag) {
      return `+${phoneCode}`;
    } else {
      return phoneCode.toString();
    }
  };

  // ComponentDidMount Start
  async componentDidMount() {
    if (this.props.data) {
      await this.getSalutation();
      this.setState(
        {
          ...this.props.data,
          salutation: this.state?.allSalutationList?.filter(
            (sal) => this.props?.data?.salutation === sal?.label
          )?.[0]?.value,
          certificate: this.addListToState(this.props.data.certificate),
          experience: this.addListToState(this.props.data.experience),
          isBlock: false,
        },
        () => {
          this.getEssentials();
          this.careProviderByCategory(this.state.category);
          this.serviceCoordinatorByProvider();
        }
      );
    } else {
      await this.getSalutation();
      if (this.props?.location?.state?.provider) {
        const state = this.state;
        state.careProvider = this.props.location.state.provider;
        state.category = this.props.location.state.category;
        this.setState({ state }, () => {
          this.careProviderByCategory(state.category);
          this.serviceCoordinatorByProvider();
        });
      }
      this.getEssentials();
    }
  }

  getSalutation = () => {
    try {
      return new Promise(async (resolve, reject) => {
        let salutation = await this.props.GET_SAL({});
        
        this.setState({
          allSalutationList: salutation?.payload?.data,
        });
        resolve();
      });
    } catch (error) {
      console.log(error);
    }
  };

  // ComponentDidMount End
  getEssentials = async () => {
    // const options = [
    //   {
    //     label: "+60",
    //     value: "60",
    //   },
    // ];
    const options = getCountryCodeOptions();
    this.setState({
      allPhoneCodes: options,
      phoneCode: this.props.data?.phoneCode
        ? this.getFormattedMobileCodeState(this.props.data?.phoneCode)
        : "+60",
    });
    this.props.GET_CATEGORIES({});
    this.props.GET_SKILLS({
      searchKey: this.state.searchKey,
      sortKey: this.state.sortKey,
    });
    this.props.GET_MASTER_CERT_ORGANIZATION();
    this.props.GET_MASTER_EXP_ORGANIZATION();
    this.props.GET_MASTER_CERTIFICATES();
    this.props.GET_MASTER_EXP_ROLES();
    this.props.GET_MASTER_SPECIALIZATION();
    await this.props.GET_LIC();
    if (this.props?.data) {
      // License description in care giver detail api is getting a string return value, so formatting it auto-populate the license name field.
      // license_description: {label: this.props?.data?.license_description ?? "", value: this.props?.data?.license_description ?? ""},
      const { data: license } = this.props?.license;
      this.setState({
        license_description: license.filter(
          (lic) => lic.label === this.state.license_description
        ),
      });
    }
  };

  // Other Handlers/loaders
  canIHaveCategory = () => {
    if (this.props?.auth_data?.data?.category?.length > 0) {
      return this.props?.auth_data?.data?.category;
    } else {
      return [];
    }
  };

  serviceCoordinatorByProvider = () => {
    const provider = this.state.careProvider;
    this.props.SERVICE_COORDINATOR_BY_PROVIDER({
      provider: Array.isArray(provider)
        ? provider.map((_) => _.value)
        : [provider?.value],
    });
  };

  updateImageDataInStateAndPreviewImage = async (input) => {
    // const base64 = await toBase64(input.target.files[0]);
    this.setState({ ...this.state, uploadImage: input.target.files[0] }, () => {
      const { error } = this.state;
      error["uploadImage"] = false;
      this.setState({ ...this.state, isBlock: true });
    });
  };

  careProviderByCategory = (categories) => {
    this.props.GET_PROVIDER_LIST_BASEDON_CATEGORY({
      categories: Array.isArray(categories)
        ? categories.map((_) => _.value)
        : [categories?.value],
    });
  };

  canIHaveProviderOptions = () => {
    const { data: auth_data } = this.props?.auth_data;

    if (
      user_roles.supervisor === auth_data.user_role ||
      user_roles.service_coordinator === auth_data.user_role ||
      user_roles.service_professional === auth_data.user_role
    ) {
      return (
        auth_data?.providerDetails?.map((_) => {
          return { value: _.provider_id, label: _.provider_name };
        }) ?? []
      );
    } else {
      return this.props?.providerBasedOnCategory?.data ?? [];
    }
  };

  canIShow = (key) => {
    const { data } = this.props.auth_data;

    if (key === "provider") {
      if (user_roles.provider === data.user_role) {
        return false;
      } else {
        return true;
      }
    }
  };
  // Other Handlers/loaders ENDS----------

  // ON SUBMIT VALIDATION
  handleValidation = () => {
    const { email, error } = this.state;

    let fieldsToValidate = [
      // file field
      // "uploadImage",
      //  text field
      "specialization",
      "name",
      "mobileNo",
      "skills",
      // "careProvider",
      // "phoneCode",
      // "experience",
      // "address",
      "addressLine1",
      // "addressLine2",
      "area",
      "state",
      "country",
      "postalCode",
      // "certificate",
      "license_description",
      "license_no",
      // "currOffering",
      // "Offerings",
      // email field
      "email",
      // select field
      "category",
      "careProvider",
      // "service_coordinator"
    ];

    fieldsToValidate.map((val) => {
      if (val === "email") {
        if (email && email.length > 0) {
          if (validateEmail(email)) {
            error[val] = false;
            // return true;
          } else {
            error[val] = true;
            error[`message-email`] = errorMessage.emailMessage;
            // return false;
          }
        } else {
          error[val] = true;
          // return true;
        }
      } else if (val === "mobileNo") {
        if (
          this.state[val].toString() &&
          this.state[val].toString().length > 0
        ) {
          const limit = getMobileLimitBasedOnCC(this.state?.phoneCode);
          if (
            this.state[val].toString() &&
            this.state[val].toString().length < limit
          ) {
            error[val] = true;
            error[`message-mobile`] = errorMessage.mobileNoLimitMessage(limit);
            // return false;
          } else if (
            this.state[val].toString() &&
            this.state[val].toString().length > limit
          ) {
            error[val] = true;
            error[`message-mobile`] = errorMessage.mobileNoExceedsMessage(
              limit
            );
            // return false;
          } else if (
            this.state[val].toString() &&
            this.state[val].toString().length === limit
          ) {
            // DONT REMOVE - CHECKS COUNTRYWISE MOBILE NUMBER VALIDITY
            // if(this.state.phoneCode && this.state[val]){
            //   if (
            //     !parsePhoneNumber(
            //       `${this.state.phoneCode}${this.state[val]}`
            //     )?.isValid()
            //   ) {
            //     error[val] = true;
            //     error[`message-mobile`] = errorMessage.mobileNoValidMessage;
            //   }
            // }
            // CHECKS FIRST AND ALL DIGIT ZERO
            if (this.state[val]) {
              if (checkFirstZeroAndAllZero(this.state[val])) {
                error[val] = true;
                error[`message-mobile`] = errorMessage.mobileNoValidMessage;
              }
            }
          } else {
            error[val] = false;
            // return true;
          }
        } else {
          error[val] = true;
          // return false;
        }
      } else if (val === "profile_pic" || val === "uploadImage") {
        if (this.state["profile_pic"] || this.state["uploadImage"]) {
          error[val] = false;
        } else {
          error[val] = true;
        }
      }

      //  else if (val === "phoneCode") {
      //   error[val] = false;
      //   return true;
      // }
      else {
        if (this.state?.[val]?.constructor === Array) {
          if (this.state[val].length > 0) {
            error[val] = false;
          } else {
            error[val] = true;
          }

          // return true;
        } else if (this.state?.[val]?.constructor === Object) {
          if (Object.keys(this.state[val]).length > 0) {
            error[val] = false;
          } else {
            error[val] = true;
          }
        } else if (this.state[val]) {
          error[val] = false;
        } else {
          error[val] = true;
        }
      }
      return val;
    });
    this.setState({ ...this.state });
  };

  //On Submit Callback
  onSave = async () => {
    try {
      this.handleValidation();
      if (Object.values(this.state.error).indexOf(true) > -1) {
        return false;
      } else {
        this.setState({ ...this.state, isSaving: true });
        const payload = {
          ...this.state,
          license_description: this.state.license_description?.label,
        };

        if (user_roles.provider === this.props.auth_data?.data?.user_role) {
          payload.careProvider = {
            value: this.props.auth_data?.data?.user_id,
            label: this.props.auth_data?.data?.profile_name,
          };
        }
        
        // payload.imageBase64 =await toBase64(payload.uploadImage);
        const res = this.props.data
          ? await this.props.UPDATE_CARE_GIVER_INFO(payload)
          : await this.props.ADD_CARE_GIVER(payload);
        // const res = {}
        
        const { error } = res;
        const { error: payloadError } = res.payload;
        if (error || payloadError) {
          this.setState(
            {
              isSaving: false,
            },
            () => {
              this.context.setSnack({
                ...this.context,
                open: true,
                vertical: alertProps.vertical.top,
                horizontal: alertProps.horizontal.center,
                severity: alertProps.severity.error,
                msg:
                  res?.payload?.message ?? "Something went wrong! Try again.",
              });
            }
          );
        } else {
          if (this.props.data) {
            this.setState(
              {
                isSaving: false,
                isBlock: false,
              },
              () => {
                this.context.setSnack({
                  open: true,
                  vertical: alertProps.vertical.top,
                  horizontal: alertProps.horizontal.center,
                  severity: alertProps.severity.success,
                  msg: "Service Professional detail updated!",
                });
                this.setState({ certificate: [], experience: [] }, () => {
                  const id = this.props?.match?.params?.id;

                  this.props.history.push(
                    routes.manage_care_givers + "/view/" + id
                  );
                });
              }
            );
          } else {
            this.setState(
              {
                ...initialState,
                isSaving: false,
                isBlock: false,
              },
              () => {
                this.context.setSnack({
                  open: true,
                  vertical: alertProps.vertical.top,
                  horizontal: alertProps.horizontal.center,
                  severity: alertProps.severity.success,
                  msg: "Service Professional Added Successfully!",
                });
                this.setState({ certificate: [], experience: [] }, () => {
                  this.props.history.push(routes.manage_care_givers);
                });
              }
            );
          }
        }
      }
    } catch (err) {
      console.log("Error while adding care provider:", err);
      this.setState(
        {
          isSaving: false,
        },
        () => {
          this.context.setSnack({
            ...this.context,
            open: true,
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.center,
            severity: alertProps.severity.error,
            msg: "Something went wrong! Try again.",
          });
        }
      );
    }
  };

  // Update value to states on change
  updateDataInState = (value, key, type, ck, cleanUpKeys = []) => {
    const state = this.state;
    if (ck?.includes("Certification")) {
      if (key === "organisation_name") {
        state.cc_isNew = value.__isNew__ ? 0 : null;
        this.setState({ ...state, [key]: value?.label }, () => {
          const { err } = this.state;
          err[key] = false;
          this.setState({ ...state });
        });
      }
      if (key === "role") {
        state.co_isNew = value.__isNew__ ? 0 : null;
        this.setState({ ...state, [key]: value?.label }, () => {
          const { err } = this.state;
          err[key] = false;
          this.setState({ ...state });
        });
      }
    } else {
      if (key === "organisation_name") {
        state.ec_isNew = value.__isNew__ ? 0 : null;
        this.setState({ ...state, [key]: value?.label }, () => {
          const { err } = this.state;
          err[key] = false;
          this.setState({ ...state });
        });
      }
      if (key === "role") {
        state.eo_isNew = value.__isNew__ ? 0 : null;
        this.setState({ ...state, [key]: value?.label }, () => {
          const { err } = this.state;
          err[key] = false;
          this.setState({ ...state });
        });
      }
    }
    if (
      type === "number" &&
      value.length > getMobileLimitBasedOnCC(this.state?.phoneCode)
    ) {
      return false;
    }
    else if (
      type === "postalCode" &&
      value.length > 10
    ) {
      return false;
    }
    const { error } = this.state;
    error[key] = false;
    let newState = this.state;
    newState[key] = value;
    cleanUpKeys.map((_) => {
      newState[_] = "";
      return _;
    });

    if (user_roles.provider === this.props.auth_data?.data?.user_role) {
      newState.careProvider = {
        value: this.props.auth_data?.data?.user_id,
        label: this.props.auth_data?.data?.profile_name,
      };
      this.serviceCoordinatorByProvider();
    }

    this.setState({ ...newState, isBlock: true }, () => {
      if (key === "category") {
        this.careProviderByCategory(this.state.category);
      }
      if (key === "careProvider") {
        this.serviceCoordinatorByProvider();
      }
    });
  };

  updateStateInParent = (childState) => {
    this.setState({
      ...childState,
    });
  };

  // CERTIFICATE AND EXPERIENCE CALLBACKS
  handleCertExpAdd = (drawerName) => {
    const state = this.state;
    //Decide if it is certificate (or) experience
    let certOrExp =
      drawerName === "Edit Certification" || drawerName === "Add Certification"
        ? "certificate"
        : "experience";
    let orglabel = state?.organisation_name?.label;
    let orgvalue = state?.organisation_name?.value;
    let roleLabel = state?.role?.label;
    let roleValue = state?.role?.value;

    // Error Handling
    // Checking if Organisation name and role - label and value exists
    if (!state.organisation_name?.label) {
      state.err["organisation_name"] = true;
      state.err["organisation_namemsg"] = "Please enter Organisation Name";
      this.setState({ ...state });
      return false;
    } else if (!state.role?.label) {
      state.err["role"] = true;
      state.err["rolemsg"] = "Please enter Role";
      this.setState({ ...state });
      return false;
    } else if (!state.start_date) {
      state.err["start_date"] = true;
      state.err["start_datemsg"] = "Please enter Date";
      this.setState({ ...state });
      return false;
    } else if (!state.end_date) {
      state.err["end_date"] = true;
      state.err["end_datemsg"] = "Please enter Date";
      this.setState({ ...state });
      return false;
    } else {
      // if does not exists

      // Checking if isEdit is 0 (or) above 0
      if (state.isedit === 0 || state.isedit > 0) {
        state[certOrExp] = state[certOrExp].map(
          (certificateEditData, index) => {
            // Take copy of certificare Edit Data
            let tempCertificateStore = Object.assign({}, certificateEditData);

            // Check if the drawer name includes certification, then it is certification drawer
            if (drawerName.includes("Certification")) {
              if (
                tempCertificateStore.certificateId === state.isedit &&
                tempCertificateStore.organizationId === state.name_ck &&
                tempCertificateStore.certificateName === state.name_ckm
              ) {
                tempCertificateStore.certificate =
                  state.cc_isNew === 0 ? 0 : orgvalue;
                tempCertificateStore.certificateName = orglabel;
                tempCertificateStore.organizationName = roleLabel;
                tempCertificateStore.organizationId =
                  state.co_isNew === 0 ? 0 : roleValue;
                tempCertificateStore.startingFrom = state.start_date;
                tempCertificateStore.endingIn = state.end_date;
                tempCertificateStore.dnd = `"${tempCertificateStore.dnd}"`;
              }
              return tempCertificateStore;
            } else {
              // Else it is experience
              if (
                tempCertificateStore.roleId === state.isedit &&
                tempCertificateStore.organizationId === state.name_ck &&
                tempCertificateStore.organizationName === state.name_ckm
              ) {
                tempCertificateStore.roleId =
                  state.ec_isNew === 0 ? 0 : roleValue;
                tempCertificateStore.roleName = roleLabel;
                tempCertificateStore.organizationName = orglabel;
                tempCertificateStore.organizationId =
                  state.eo_isNew === 0 ? 0 : orgvalue;
                tempCertificateStore.startingFrom = state.start_date;
                tempCertificateStore.endingIn = state.end_date;
                tempCertificateStore.dnd = `"${tempCertificateStore.dnd}"`;
              }
              return tempCertificateStore;
            }
          }
        );
      } else {
        if (drawerName.includes("Certification")) {
          let arr = Object.assign([], state[certOrExp]);
          arr.push({
            certificateId: state.cc_isNew === 0 ? 0 : orgvalue,
            certificateName: orglabel,
            organizationName: roleLabel,
            organizationId: state.co_isNew === 0 ? 0 : roleValue,
            startingFrom: state.start_date,
            endingIn: state.end_date,
            dnd: `"${state.certificate?.length + 1}"`,
            orderNo: state.certificate?.length + 1,
          });
          state[certOrExp] = arr;
        } else {
          let arr = Object.assign([], state[certOrExp]);
          arr.push({
            roleId: state.eo_isNew === 0 ? 0 : roleValue,
            roleName: roleLabel,
            organizationName: orglabel,
            organizationId: state.ec_isNew === 0 ? 0 : orgvalue,
            startingFrom: state.start_date,
            endingIn: state.end_date,
            orderNo: state.experience?.length + 1,
            dnd: `"${state.experience?.length + 1}"`,
          });
          state[certOrExp] = arr;
        }
      }
    }
    this.setState(
      {
        ...state,
      },
      () => {
        const { error } = this.state;
        if (drawerName?.includes("Certification")) {
          error["certificate"] = false;
        } else {
          error["experience"] = false;
        }
        this.setState({
          ...state,
          isedit: null,
          name_ck: null,
          name_ckm: null,
          open: !this.state.open,
        });
      }
    );
  };

  handleCertExpDelete = (drawerName, selectedIndex) => {
    const state = this.state;
    //Checking for the name
    if (drawerName.includes("Certification")) {
      this.props.dialog.setDialog({
        open: this.state.isdialog,
        title: "Are you sure you want to delete this Certification ?",
        positiveBtn: "Delete",
        negativeBtn: "Cancel",
        onOk: () => {
          this.props.dialog.setDialog({ open: false });
          this.setState({ isdelete: true }, () => {
            if (this.state.isdelete) {
              const certOrExp =
                drawerName === "Edit Certification" ||
                drawerName === "Add Certification"
                  ? "certificate"
                  : "experience";
              let arr = Object.assign([], state[certOrExp]);
              // var x = arr.map(function (item) { return item.certificateId }).indexOf(i);
              arr.splice(selectedIndex, 1);
              state[certOrExp] = arr;
              this.setState({
                ...state,
              });
            }
          });
        },
      });
    } else {
      this.props.dialog.setDialog({
        open: this.state.isdialog,
        title: "Are you sure you want to delete this Experience ?",
        negativeBtn: "Cancel",
        positiveBtn: "Delete",
        onOk: () => {
          this.props.dialog.setDialog({ open: false });
          this.setState({ isdelete: true }, () => {
            if (this.state.isdelete) {
              const certOrExp =
                drawerName === "Edit Certification" ||
                drawerName === "Add Certification"
                  ? "certificate"
                  : "experience";
              let arr = Object.assign([], state[certOrExp]);
              // var x = arr.map(function (item) { return item.roleId }).indexOf(i);
              arr.splice(selectedIndex, 1);
              state[certOrExp] = arr;
              this.setState({
                ...state,
              });
            }
          });
        },
      });
    }
  };

  handledrawer = (name, data, i, nm, nme) => {
    const b11 = moment(data?.startingFrom).format("YYYY").split("/")[0];
    const b21 = moment(data?.endingIn).format("YYYY").split("/")[0];
    const ck = JSON.parse(b21) - JSON.parse(b11);
    const cks = ck > 100;
    if (name?.includes("Certification")) {
      const v = data?.certificateName;
      const v_ = data?.certificateId;
      const s = data?.organizationName;
      const s_ = data?.organizationId;
      this.setState(
        {
          start_date: data?.startingFrom ? moment(data?.startingFrom) : null,
          end_date: cks
            ? moment("01/01/2170")
            : data?.endingIn
            ? moment(data?.endingIn)
            : null,
          drawer_title: name,
          isedit: i,
          name_ck: nm,
          name_ckm: nme,
          role: s ? { value: s_, label: s } : null,
          organisation_name: v ? { value: v_, label: v } : null,
          open: !this.state.open,
          present: ck > 100 ? true : false,
        },
        () => this.openDrawer()
      );
    } else {
      const v1 = data?.roleName;
      const v_1 = data?.roleId;
      const s1 = data?.organizationName;
      const s_1 = data?.organizationId;
      this.setState(
        {
          start_date: data?.startingFrom ? moment(data?.startingFrom) : null,
          end_date: cks
            ? moment("01/01/2170")
            : data?.endingIn
            ? moment(data?.endingIn)
            : null,
          drawer_title: name,
          isedit: i,
          name_ck: nm,
          name_ckm: nme,
          role: s1 ? { value: v_1, label: v1 } : null,
          organisation_name: v1 ? { value: s_1, label: s1 } : null,
          open: !this.state.open,
          present: ck > 100 ? true : false,
        },
        () => this.openDrawer()
      );
    }
  };

  onchanged = (items) => {
    this.setState({
      certificate: items,
    });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = this.reorder(
      this.state?.certificate,
      result.source.index,
      result.destination.index
    );
    let update = items.map((val, index) => {
      let v_ = Object.assign({}, val);
      v_.orderNo = index + 1;
      return v_;
    });
    this.setState({
      certificate: update,
    });
  };

  onDragEnd1 = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = this.reorder(
      this.state?.experience,
      result.source.index,
      result.destination.index
    );
    let update = items.map((val, index) => {
      let v_ = Object.assign({}, val);
      v_.orderNo = index + 1;
      return v_;
    });
    this.setState({
      experience: update,
    });
  };

  cancelForm = () => {
    this.props.history.push(routes.manage_care_givers);
  };

  onClose_ = () => {
    this.setState({
      ...this.state,
      open: !this.state.open,
    });
  };

  Checkboxhandle = () => {
    const state = this.state;
    if (state.present) {
      this.setState({
        ...this.state,
        end_date: null,
        present: !this.state.present,
      });
    } else {
      this.setState({
        ...this.state,
        end_date: moment("01/01/2170"),
        present: !this.state.present,
      });
    }
  };

  openDrawer = () => {
    const {
      drawer_title,
      start_date,
      end_date,
      isedit,
      name_ck,
      name_ckm,
      role,
      organisation_name,
      present,
      certificate,
      experience,
      iseditexperience,
    } = this.state;
    const checklist =
      drawer_title === "Edit Certification" ||
      drawer_title === "Add Certification";
    const {
      data: certificates,
      loading: certificatesLoading,
    } = this.props?.certificates;

    const {
      data: cert_organization,
      loading: cert_organizationLoading,
    } = this.props?.cert_organization;

    const {
      data: exp_roles,
      loading: exp_rolesLoading,
    } = this.props?.exp_roles;

    const {
      data: exp_organization,
      loading: exp_organizationLoading,
    } = this.props?.exp_organization;

    const actionProps = {
      certificates,
      certificatesLoading,
      cert_organization,
      cert_organizationLoading,
      exp_roles,
      exp_rolesLoading,
      exp_organization,
      exp_organizationLoading,
    };

    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <CertificateDrawer
          initialStateInParent={{
            start_date: start_date,
            end_date: end_date,
            isedit: isedit,
            name_ck: name_ck,
            name_ckm: name_ckm,
            role: role,
            organisation_name: organisation_name,
            present: present,
            certificate: certificate,
            experience: experience,
            iseditexperience: iseditexperience,
          }}
          mandatoryProps={{
            drawer_title: this.state.drawer_title,
            checklist: checklist,
          }}
          actionProps={actionProps}
          // handleCertExpAdd={this.handleCertExpAdd}
          // handleCertExpDelete={this.handleCertExpDelete}
          updateStateInParent={this.updateStateInParent}
        />
      ),
    });
  };

  //-------------------------END----------------

  render() {
    const { classes } = this.props;
    const {
      // data: category,
      loading: categoryLoading,
    } = this.props?.category;

    const { data: skills, loading: skillsLoading } = this.props?.skills;

    const {
      data: specialization,
      loading: specializationLoading,
    } = this.props?.specialization;

    const {
      data: serviceCoordinator,
      loading: serviceCoordinatorLoading,
    } = this.props?.service_coordinator;

    const { data: license, loading: licenseLoading } = this.props?.license;

    const salutation = this.state?.allSalutationList;

    const {
      // eslint-disable-next-line no-unused-vars
      data: careProvider,
      // eslint-disable-next-line no-unused-vars
      loading: careProviderLoading,
      // error: certificateError,
    } = this.props?.providerBasedOnCategory;

    const { data } = this.props;

    const { isSaving } = this.state; //drawer_title, present
    // const checklist = (drawer_title === "Edit Certification" || drawer_title === "Add Certification")

    return (
      <Paper className={classes.paperCard}>
        <Grid container spacing={3} className={classes.paddins}>
          {/**Profile Picture  */}
          <Grid item md="12" sm="12" xs="12">
            <UploadProfileImage
              label="UPLOAD PHOTO"
              value={this.state.uploadImage}
              id="uploadImage"
              profile_pic={this.state.profile_pic}
              handleFileChange={(e) => {
                this.updateImageDataInStateAndPreviewImage(e);
              }}
              error={this.state.error.uploadImage ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>

          {/* Select Service Professional */}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {/* <TextField
              type="text"
              title="Service Professional Name"
              placeholder=""
              value={this.state.name}
              id="name"
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "name");
              }}
              error={this.state.error["name"] ? true : false}
              errorText={errorMessage.requiredMessage}
            /> */}
            <InputAdornmentComponent
              options={salutation}
              valuePhoneCode={
                this.state?.salutation ? this.state?.salutation : ""
              }
              valueMObileNo={this.state?.name}
              customsize={4}
              allScreenCustomSize={{
                xs: 6,
                sm: 4,
                md: 6,
              }}
              title="Service Professional Name"
              id="name"
              type="text"
              handleChangeSelect={(e) => {
                this.updateDataInState(e.target.value, "salutation");
              }}
              handleMobileNo={(e) => {
                this.updateDataInState(e.target.value, "name");
              }}
              error={this.state.error["name"] ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>

          {/* Category */}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <SimpleSelect
              label={"Category"}
              // options={category}
              options={this.canIHaveCategory()}
              isMulti={true}
              id="category"
              value={this.state.category}
              loading={categoryLoading}
              handleOnChange={(e) => {
                this.updateDataInState(e, "category", null, null, [
                  "careProvider",
                  "service_coordinator",
                ]);
              }}
              error={this.state.error.category ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>

          {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <SimpleSelect
              label={"Care Provider"}
              options={careProvider}
              isMulti={false}
              id="careProvider"
              value={this.state.careProvider}
              isLoading={careProviderLoading}
              handleOnChange={(e) => {
                this.updateDataInState(e, "careProvider");
              }}
              error={this.state.error.careProvider ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid> */}

          {/* Mobile Number */}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <InputAdornmentComponent
              options={this.state.allPhoneCodes}
              value={""}
              customsize={4}
              allScreenCustomSize={{
                xs: 6,
                sm: 4,
                md: 6,
              }}
              id="mobile"
              title="Mobile No"
              type="number"
              valueMObileNo={this.state.mobileNo}
              valuePhoneCode={this.state.phoneCode}
              handleChangeSelect={(e) => {
                this.updateDataInState(e.target.value, "phoneCode", null, [
                  "mobileNo",
                ]);
              }}
              handleMobileNo={(e) => {
                this.updateDataInState(e.target.value, "mobileNo", "number");
              }}
              error={this.state.error.mobileNo ? true : false}
              errorText={
                this.state.error[`message-mobile`]
                  ? this.state.error[`message-mobile`]
                  : errorMessage.requiredMessage
              }
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              type="text"
              title="Email ADDRESS"
              id="email"
              placeholder=""
              disabled={data ? true : false}
              value={this.state.email}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "email");
              }}
              error={this.state.error.email ? true : false}
              errorText={
                this.state.error[`message-email`]
                  ? this.state.error[`message-email`]
                  : errorMessage.requiredMessage
              }
            />
          </Grid>

          {/* Address */}
          {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              type="text"
              title="Address"
              id="address"
              placeholder=""
              value={this.state.address}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "address");
              }}
              error={this.state.error.address ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid> */}

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              type="text"
              title="Address Line 1"
              id="addressLine1"
              placeholder=""
              value={this.state.addressLine1}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "addressLine1");
              }}
              error={this.state.error.addressLine1 ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              type="text"
              title="Address Line 2"
              id="addressLine2"
              placeholder=""
              value={this.state.addressLine2}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "addressLine2");
              }}
              error={this.state.error.addressLine2 ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              type="text"
              title="City"
              id="area"
              placeholder=""
              value={this.state.area}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "area");
              }}
              error={this.state.error.area ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              type="text"
              title="State"
              id="state"
              placeholder=""
              value={this.state.state}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "state");
              }}
              error={this.state.error.state ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              type="text"
              title="Country"
              id="country"
              placeholder=""
              value={this.state.country}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "country");
              }}
              error={this.state.error.country ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              type="number"
              limit={5}
              title="Postal Code"
              id="postalCode"
              placeholder=""
              value={this.state.postalCode}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "postalCode", "postalCode");
              }}
              error={this.state.error.postalCode ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>

          {/* Skills */}
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <SimpleSelect
              label={"Skills"}
              options={skills}
              id="skills"
              isMulti={true}
              value={this.state.skills}
              isLoading={skillsLoading}
              handleOnChange={(e) => {
                this.updateDataInState(e, "skills");
              }}
              error={this.state.error.skills ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>
        </Grid>

        {/* ----Divider---- */}
        <Divider className={classes.dividerBackground} />
        {/* ----Divider---- */}

        {/* Row 2 */}
        <Grid container spacing={3} className={classes.paddins}>
          {/* Specialization */}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <SimpleSelect
              label={"Specialization"}
              options={specialization}
              id="specialization"
              isMulti={true}
              value={this.state.specialization}
              isLoading={specializationLoading}
              handleOnChange={(e) => {
                this.updateDataInState(e, "specialization");
              }}
              error={this.state.error.specialization ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>

          {/* License Name */}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {/* <TextField
              type="text"
              title="License Name"
              id="license_description"
              placeholder=""
              value={this.state.license_description}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "license_description");
              }}
              error={this.state.error.license_description ? true : false}
              errorText={errorMessage.requiredMessage}
            /> */}
            <SimpleSelect
              label={"License Name"}
              options={license}
              id="license"
              value={this.state.license_description}
              isLoading={licenseLoading}
              handleOnChange={(e) => {
                this.updateDataInState(e, "license_description");
              }}
              error={this.state.error.license_description ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>

          {/* License Number */}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              type="text"
              title="License Number"
              id="license_no"
              placeholder=""
              value={this.state.license_no}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "license_no");
              }}
              error={this.state.error.license_no ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>
        </Grid>

        {/* ----Divider---- */}
        <Divider className={classes.dividerBackground} />
        {/* ----Divider---- */}

        {/* Row 3 */}
        <Grid container spacing={3} className={classes.paddins}>
          {/* {add and edit certificate Component} */}
          {/* CERTIFICATE */}
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.certification}
            >
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <Certificationcard
                        certification={this.state.certificate}
                        // handledrawer={this.handledrawer}
                        handledrawer={this.handledrawer}
                        ondltList={this.handleCertExpDelete}
                        onchanged={this.onchanged}
                      />
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </Grid>
          {/* end */}
          <div className={classes.errmsg}>
            {this.state.error.certificate ? errorMessage.requiredMessage : ""}
          </div>
        </Grid>

        {/* ----Divider---- */}
        <Divider className={classes.dividerBackground} />
        {/* ----Divider---- */}

        <Grid container spacing={3} className={classes.paddins}>
          {/* {add and edit experience Component} */}
          {/* EXPERIENCE */}
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.certification}
            >
              <DragDropContext onDragEnd={this.onDragEnd1}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <Experiencecard
                        experience={this.state.experience}
                        handledrawer={this.handledrawer}
                        ondltList={this.handleCertExpDelete}
                      />
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </Grid>
          {/* end */}
          <div className={classes.errmsg}>
            {this.state.error.experience ? errorMessage.requiredMessage : ""}
          </div>

          {/* drawer */}
          {/* <AppDrawer
            onClose_={this.onClose_}
            open={this.state.open}
            // component={}
          /> */}
          {/* end */}
        </Grid>

        {/* ----Divider---- */}
        <Divider className={classes.dividerBackground} />
        {/* ----Divider---- */}

        <Grid container spacing={3} className={classes.paddins}>
          {/* Select Provider */}
          {this.canIShow("provider") === true && (
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <SimpleSelect
                label={"Select Provider"}
                options={this.canIHaveProviderOptions()}
                id="careProvider"
                isMulti={false}
                value={this.state.careProvider}
                isLoading={this.props?.providerBasedOnCategory?.loading}
                handleOnChange={(e) => {
                  this.updateDataInState(e, "careProvider");
                }}
                error={this.state.error.careProvider ? true : false}
                errorText={errorMessage.requiredMessage}
              />
            </Grid>
          )}

          {/* Select Service Coordinator */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SimpleSelect
              label={"Add Service Coordinator"}
              options={serviceCoordinator}
              id="service_coordinator"
              isMulti={true}
              value={this.state.service_coordinator}
              loading={serviceCoordinatorLoading}
              handleOnChange={(e) => {
                this.updateDataInState(e, "service_coordinator");
              }}
              error={this.state.error.service_coordinator ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>

          {/* CTA --- SAVE/UPDATE --- CANCEL */}
          <Grid container item xs={12} className={classes.saveButtonGrid}>
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={this.cancelForm}
              style={{ marginRight: 16 }}
            >
              {"Cancel"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.onSave()}
              className={classes.saveButton}
              disabled={isSaving}
            >
              {`${
                isSaving
                  ? `${data ? "Updating..." : "Saving..."}`
                  : `${data ? "Update" : "Save"}`
              }`}
            </Button>
          </Grid>
        </Grid>

        <Prompt
          when={this.state.isBlock}
          message={(location) => errorMessage.promptMessage}
        />
      </Paper>
    );
  }
}

Card.contextType = AlertContext;

const mapStateToProps = (state) => ({
  category: state.masterSet?.categories,
  skills: state.masterSet?.skills,
  cert_organization: state.masterSet?.cert_organization,
  exp_organization: state.masterSet?.exp_organization,
  certificates: state.masterSet?.certificates,
  exp_roles: state.masterSet?.exp_roles,
  specialization: state.masterSet?.specialization,
  providerBasedOnCategory: state?.careProvider?.providerBasedOnCategory,
  service_coordinator: state?.dependency_api?.serviceCoordinatorByProvider,
  auth_data: state?.authState?.loginAuth,
  license: state?.masterModule?.getlicence,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(Card))));
