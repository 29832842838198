import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { withNavBars } from "../../HOCs";
// import MiniCards from "../../components/miniCards";
import MiniCharts from "../../components/miniCharts";
import Todo from "../../components/todo";
import DashboardListView from "../../components/dashboardListView";
import DashboardPendngRequestListView from "../../components/dashboardListView/dashboardRequestPendingListView";
import AddTodo from "./addTodo";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  alertProps,
  user_roles,
  fontStyleClass,
  commonShadows,
  DoughnutHoverColor,
  DoughnutColor,
} from "../../utils";
import moment from "moment";
import MiniChartsSkeleton from "../../components/miniCharts/MiniChartsSkeleton";
import DashboardListviewLoadingSection from "../../components/dashboardListView/dashboardListViewLoading";
import ActivityRequest from "./activityRequest";
import SubscriptionRequest from "./SubscriptionRequest";
// import AddEditRescheduleActivity from "../../screens/availability/calendar/addEditActivity/activityReschedule"

const style = (theme) => ({
  ...commonShadows,
  ...fontStyleClass,
});

// const users = {
//   // Providers: "Providers",
//   ServiceSupervisors: "Service Supervisors",
//   ServiceCoordinators: "Service Coordinators",
//   ServiceProfessionals: "Service Professionals",
//   // OtherResources: "Other Resources"
// };

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: moment().startOf("day").toISOString(),
      to: moment().endOf("day").toISOString(),
      // filter: this.props?.filterOptions?.[0].value || "", //Sets the first elements value from array of objects of filterOptions prop
      filter_label: "All",
      filter: "All",
      filter_id: "All",
    };
  }

  componentDidMount() {
    this.getAllDashboardData();
    // this.props.ENQUIRY_PAGE_ADD({"enquire": {"remarks": "Price looks high"}, "isCart": false, "isClientApp": true, "resources": ["e578d33c-9d6a-4201-9995-4800f6d52faf"]})
  }

  getAllDashboardData = () => {
    let user_id = this.props.auth_data.user_id;
    let user_role = this.props.auth_data.user_role;
    let request_payload = {
      from: this.state.from,
      to: this.state.to,
      user_id,
      user_role,
      // component: ["persona_catalogue", "services_catalogue", "client_catalogue", "provider_catalogue", "top_service"]
      // component: "services_catalogue"
    };
    this.props.GET_ALL_DASHBOARD_DATA({
      component: "persona_catalogue",
      ...request_payload,
    });
    this.props.GET_ALL_DASHBOARD_DATA({
      component: "services_catalogue",
      ...request_payload,
    });
    this.props.GET_ALL_DASHBOARD_DATA({
      component: "client_catalogue",
      ...request_payload,
    });
    this.props.GET_ALL_DASHBOARD_DATA({
      component: "provider_catalogue",
      ...request_payload,
    });
    this.props.GET_ALL_DASHBOARD_DATA({
      component: "top_service",
      ...request_payload,
    });
    this.props.GET_ALL_TODO_DATA(user_id);
    if (user_role !== user_roles.business_admin) {
      this.props.GET_ACTIVITY_REQUESTS({
        status: "",
      });
    } else {
      this.props.GET_ACTIVITY_REQUESTS({
        status: "Subscription Cancel",
      });
      this.setState({
        filter: "Subscription Cancel",
        filter_id: "Refund Request",
      });
    }
    //  this.props.GET_RECOMMENDATION({pageNo: 0, pageSize: 5});
    // this.props.GET_LOGIN_USER_INFO(user_id);
  };

  changeRequest = (reschedule, event) => {
    console.log("ada");

    console.log(reschedule);
    this.props.GET_ACTIVITY_REQUESTS({
      status: reschedule,
    });
    this.setState({
      filter: event.target.value === "All" ? "All" : event.target.value,
      filter_label:
        event.target.value === "Cancel"
          ? "Cancellation"
          : event.target.value === "All"
          ? "All"
          : event.target.value === "All"
          ? "All"
          : "Reschedule",
      filter_id:
        event.target.value === "Cancel"
          ? "Cancel"
          : event.target.value === "Subscription Cancel"
          ? "Refund Request"
          : "Reschedule",
    });
  };

  getSpecficData = () => {
    this.props.GET_ACTIVITY_REQUESTS({
      status: "",
    });
    this.setState({ reload: true });
  };

  getSpecificDashboardData = (component) => {
    this.props.GET_ALL_DASHBOARD_DATA(component);
  };

  getAllTodoData = () => {
    let user_id = this.props.auth_data.user_id;
    this.props.GET_ALL_TODO_DATA(user_id);
  };

  handleAddTodo = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <AddTodo
          // reload={this.getAllDashboardData}
          reload={this.getAllTodoData}
          // getAllDashboardData={this.getAllDashboardData}
          getAllDashboardData={this.getAllTodoData}
        />
      ),
    });
  };

  handleEdit = (data) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <AddTodo
          isEdit={true}
          data={data}
          id={data.id}
          // reload={this.getAllDashboardData}
          reload={this.getAllTodoData}
          // getAllDashboardData={this.getAllDashboardData}
          getAllDashboardData={this.getAllTodoData}
        />
      ),
    });
  };

  handleTaskToggle = (item) => {
    console.log(item);
    let payload = {
      ...item,
      scheduled_time: item?.scheduled_time
        ? new Date(item?.scheduled_time).toISOString()
        : "",
      is_completed: !item.is_completed,
    };

    payload.is_completed = !item.is_completed;

    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Updating Todo...",
    });
    this.props.UPDATE_TODO({ ...payload });
  };

  handleDelete = (data) => {
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body: "Do you want to delete the ToDo : " + data?.title,
      positiveBtn: "Delete",
      negativeBtn: "Cancel",
      onOk: () => {
        this.props.dialog.setDialog({ open: false });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: true,
          message: "Deleting Todo...",
        });
        this.props.DELETE_TODO({ todoId: data.id });
      },
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Delete Todo
    const { deleteTodo } = nextProps;
    const oldDeleteTodo = this.props.deleteTodo;

    //Checking for Delete Todo messages
    if (oldDeleteTodo?.loading !== deleteTodo?.loading) {
      if (!deleteTodo.loading && !deleteTodo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Todo Deleted successfully!",
          severity: alertProps.severity.success,
        });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
        });
        // this.getAllDashboardData();
        this.getAllTodoData();
      } else if (deleteTodo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Delete Todo.",
          severity: alertProps.severity.error,
        });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
        });
      }
    }

    //Update Package
    const { updateTodo } = nextProps;
    const oldUpdateTodo = this.props.updateTodo;

    //Checking for Add Package messages
    if (oldUpdateTodo?.loading !== updateTodo?.loading) {
      if (!updateTodo.loading && !updateTodo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Todo Status Updated successfully!",
          severity: alertProps.severity.success,
        });
        // this.getAllDashboardData();
        this.getAllTodoData();
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
        });
      } else if (updateTodo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Update Todo Status.",
          severity: alertProps.severity.error,
        });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
        });
      }
    }

    // Activity Request Update
    const { new_request } = nextProps;
    const oldNewRequest = this.props.new_request;
    if (oldNewRequest?.loading !== new_request?.loading) {
      if (!new_request.loading && !new_request.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Request updated successfully!",
          severity: alertProps.severity.success,
        });
        // this.getAllDashboardData();
        this.getSpecficData();
        // this.props.backDrop.setBackDrop({
        //   ...this.props.backDrop,
        //   open: false,
        // });
      } else if (new_request.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to process the request.",
          severity: alertProps.severity.error,
        });
        // this.props.backDrop.setBackDrop({
        //   ...this.props.backDrop,
        //   open: false,
        // });
      }
    }

    return true;
  }

  // Filter Options
  formatTopServicesFilterOptions = () => {
    // We can change the value for each object according to the API.
    let filter = [
      {
        label: "Product",
        value: "Product",
      },
      {
        label: "Package",
        value: "Package",
      },
      {
        label: "Service",
        value: "Service",
      },
    ];
    return filter;
  };

  formatResourceCountFilterOptions = (userType) => {
    //userType can be Admin, Provider
    if (userType === "Admin") {
      let filter = [
        {
          label: "Provider",
          value: "Provider",
        },
        {
          label: "Service Supervisor",
          value: "Service Supervisor",
        },
        {
          label: "Service Coordinator",
          value: "Service Coordinator",
        },
        {
          label: "Service Professional",
          value: "Service Professional",
        },
      ];
      return filter;
    } else if (userType === "Provider") {
      let filter = [
        {
          label: "Service Supervisor",
          value: "Service Supervisor",
        },
        {
          label: "Service Coordinator",
          value: "Service Coordinator",
        },
        {
          label: "Service Professional",
          value: "Service Professional",
        },
      ];
      return filter;
    } else {
      console.warn(
        "Provide any of these two in the function call ('Admin' / 'Provider')"
      );
    }
  };

  formatTodayActivityFilterOptions = () => {
    let filter = [
      {
        label: "Pending",
        value: "Pending",
      },
      {
        label: "In-Progress",
        value: "In-Progress",
      },
      {
        label: "Completed",
        value: "Completed",
      },
    ];
    return filter;
  };

  giveTableData = (userType, tableType) => {
    if (userType === "Admin") {
      if (tableType === "TopServices") {
        let td = {
          header: ["Name", "Partner Name", "Category", "Subscribed Users"],
          data: [
            {
              Name: "John Beckham",
              ProviderName: "Marlie Queen",
              Category: "cat1",
              SubscriptionCount: "23",
            },
            {
              Name: "Jonathan Steele",
              ProviderName: "Mustafa Ismail",
              Category: "cat2",
              SubscriptionCount: "45",
            },
            {
              Name: "Jordane Balistreri",
              ProviderName: "Mr. Brenda Marvin",
              Category: "cat3",
              SubscriptionCount: "23",
            },
            {
              Name: "Lizzie Christiansen",
              ProviderName: "Miss Marta Hettinger",
              Category: "cat1",
              SubscriptionCount: "11",
            },
            {
              Name: "Declan Zulauf",
              ProviderName: "Austyn Cruickshank",
              Category: "cat5",
              SubscriptionCount: "21",
            },
            {
              Name: "Clemens Goldner",
              ProviderName: "Jessika Blanda",
              Category: "cat1",
              SubscriptionCount: "21",
            },
          ],
        };
        return td;
      } else if (tableType === "ResourceCount") {
        let td = {
          header: ["Name", "Provider Name", "Category", "Status"],
          data: [
            {
              Name: "John Beckham",
              ProviderName: "Marlie Queen",
              Category: "cat1",
              Status: "Active",
            },
          ],
        };
        return td;
      }
    } else if (userType === "Provider") {
      if (tableType === "TopServices") {
        let td = {
          header: ["Name", "Category", "Subscription Count"],
          data: [
            {
              Name: "John Beckham",
              Category: "cat1",
              SubscriptionCount: "Active",
            },
          ],
        };
        return td;
      } else if (tableType === "ResourceCount") {
        let td = {
          header: ["Name", "Category", "Status"],
          data: [
            {
              Name: "John Beckham",
              Category: "cat1",
              Status: "Active",
            },
          ],
        };
        return td;
      }
    } else if (userType === "ServiceSupervisor") {
      if (tableType === "TopServices") {
        let td = {
          header: ["Name", "Category", "Subscription Count"],
          data: [
            {
              Name: "John Beckham",
              Category: "cat1",
              SubscriptionCount: "Active",
            },
          ],
        };
        return td;
      } else if (tableType === "TodayActivities") {
        let td = {
          header: [
            "Activity Title",
            "Activity Type",
            "Professional Name",
            "Client Name",
            "Status",
          ],
          data: [
            {
              ActivityTitle: "Initial Assessment",
              ActivityType: "acttype1",
              ProfessionalName: "John Beckham",
              ClientName: "Marlie Queen",
              Status: "Active",
            },
          ],
        };
        return td;
      }
    } else if (userType === "ServiceCoordinator") {
      if (tableType === "TodayActivities") {
        let td = {
          header: [
            "Activity Title",
            "Activity Type",
            "Professional Name",
            "Client Name",
            "Status",
          ],
          data: [
            {
              ActivityTitle: "Initial Assessment",
              ActivityType: "acttype1",
              ProfessionalName: "John Beckham",
              ClientName: "Marlie Queen",
              Status: "Active",
            },
          ],
        };
        return td;
      }
    }
  };

  canThisPersonaVisible = (data) => {
    const auth_data = this.props.auth_data;

    if (user_roles.provider === auth_data.user_role) {
      if (data.name === "Service Supervisors" || data.name === "Providers") {
        return false;
      }
    }

    if (user_roles.supervisor === auth_data.user_role) {
      if (data.name === "Service Supervisors" || data.name === "Providers") {
        return false;
      }
    }

    if (user_roles.service_coordinator === auth_data.user_role) {
      if (
        data.name === "Service Supervisors" ||
        data.name === "Providers" ||
        data.name === "Service Coordinators"
      ) {
        return false;
      }
    }

    return true;
  };

  compareValues = (key, order = "asc") => {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  };

  mappedDataForPartner = (data) => {
    let ourData = [...data];
    let sortedData = ourData.sort(this.compareValues("count", "desc"));
    let label = sortedData?.map((x) => x.name);
    let count = sortedData?.map((x) => x.count);
    let total_count = count?.reduce((a, b) => a + b, 0);

    return {
      total_count: total_count,
      labels: label,
      datasets: [
        {
          label: "Partner by domain Category",
          backgroundColor: DoughnutColor,
          hoverBackgroundColor: DoughnutHoverColor,
          data: count,
        },
      ],
    };
  };

  mapperDataForResource = (data) => {
    let ourData = [...data];
    let sortedData = ourData.sort(this.compareValues("count", "desc"));
    let label = sortedData?.map((x) => x.name);
    let count = sortedData?.map((x) => x.count);
    let total_count = count?.reduce((a, b) => a + b, 0);
    // console.log(total_count)
    return {
      total_count: total_count,
      labels: label,
      datasets: [
        {
          label: "Resource",
          backgroundColor: DoughnutColor,
          hoverBackgroundColor: DoughnutHoverColor,

          data: count,
        },
      ],
    };
  };

  mapperDataForService = (data) => {
    let ourData = [...data];
    let sortedData = ourData.sort(this.compareValues("count", "desc"));
    let label = sortedData?.map((x) => x.name);
    let count = sortedData?.map((x) => x.count);
    let total_count = count?.reduce((a, b) => a + b, 0);
    return {
      total_count: total_count,
      labels: label,
      datasets: [
        {
          label: "Service",
          backgroundColor: DoughnutColor,
          hoverBackgroundColor: DoughnutHoverColor,
          data: count,
        },
      ],
    };
  };

  mapperDataForClient = (data) => {
    let ourData = [...data];

    let new_cnt = ourData?.filter((x) => x?.name !== "Subscribed");
    // if(this.props.auth_data.user_role !== user_roles.super_admin)
    // {
    //   ourData = ourData?.filter((x) => x?.name !== 'Invited')
    // }
    let sortedData = ourData.sort(this.compareValues("count", "desc"));
    let label = sortedData?.map((x) => x.name);
    // let temp_zone = sortedData.filter((x) => x.name !== 'Subscribed')
    let count = ourData?.map((x) => x.count);
    let total_count = new_cnt?.map((x) => x?.count)?.reduce((a, b) => a + b, 0);
    // console.log(count);

    return {
      total_count: total_count,
      labels: label,
      datasets: [
        {
          label: "Service",
          // backgroundColor: ["#28a9c1", "#353596", "#f27d35"],
          // hoverBackgroundColor: ["#00d7ff", "#0027a7", "#ff7723"],
          backgroundColor: DoughnutColor,
          hoverBackgroundColor: DoughnutHoverColor,
          data: count,
        },
      ],
    };
  };

  ViewRequest = (id, data, mode) => {
    console.log("DATA");
    console.log(id);
    console.log(data);
    if (mode === "Reschedule") {
      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: true,
        // component: (<AddEditRescheduleActivity isReschedule={true} fullDisable={} reload={""} data={data} isEdit={true} id={id} />)
        component: (
          <ActivityRequest
            onBackBtnClicked={""}
            onEditBtnClicked={""}
            reload={""}
            drawer={this.props?.drawer}
            showServiceProfessional={true}
            hideClient={true}
            data={id}
            id={data?.id}
            dataSum={data}
            isReschedule={true}
            changeRequest={this.changeRequest}
            getSpecficData={this.getSpecficData}
          />
        ),
      });
    } else if (mode === "Refund Request") {
      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: true,
        // component: (<AddEditRescheduleActivity isReschedule={true} fullDisable={} reload={""} data={data} isEdit={true} id={id} />)
        component: (
          <SubscriptionRequest
            onBackBtnClicked={""}
            onEditBtnClicked={""}
            reload={""}
            drawer={this.props?.drawer}
            showServiceProfessional={true}
            hideClient={false}
            data={id}
            isReschedule={false}
            id={data?.id}
            subscriptionId={data?.subscriptionId}
            changeRequest={this.changeRequest}
            getSpecficData={this.getSpecficData}
          />
        ),
      });
    } else {
      this.props.drawer.setDrawer({
        ...this.props?.drawer,
        open: true,
        component: (
          <ActivityRequest
            onBackBtnClicked={""}
            onEditBtnClicked={""}
            reload={""}
            drawer={this.props?.drawer}
            showServiceProfessional={true}
            hideClient={true}
            data={id}
            isReschedule={false}
            id={data?.id}
            changeRequest={this.changeRequest}
            getSpecficData={this.getSpecficData}
          />
        ),
      });
    }
  };

  render() {
    // const { classes } = this.props;
    console.log(this.props.addQuote);

    let tododata = this.props.listtodos.data;

    let newtempServiceCatalogue =
      this?.props?.dashboard?.["services_catalogue"];
    let newtempPersonaCatalogue = this?.props?.dashboard?.["persona_catalogue"];
    let newtempProviderCatalogue =
      this?.props?.dashboard?.["provider_catalogue"];
    let newtempClientCatalogue = this?.props?.dashboard?.["client_catalogue"];
    let newtempTopService = this?.props?.dashboard?.["top_service"];
    //
    let newAll_requests = this?.props?.all_requests;
    console.log(newAll_requests);
    return (
      <div style={{ margin: 25, overflow: "hidden" }}>
        {/* {loading && (
          <LoadingSection message="Preparing your personalized dashboard..." />
        )}
        {error && (
          <InternatlServerError
            message="Something went wrong, Try Again!"
            onRetry={this.getAllDashboardData}
          />
        )}

        {!loading && !error && data && ( */}
        <>
          {/* Page Layout */}
          <Grid container spacing={4}>
            {/* Mini cards title */}
            {/* <Typography
                variant="h6"
                style={{ paddingLeft: 16, paddingTop: 16, zIndex: 1000 }}
                className={classes.mediumFont}
              >
                Overall Personas
              </Typography> */}
            <Grid item container spacing={4}>
              {(this.props.auth_data.user_role === user_roles.super_admin || this.props.auth_data.user_role === user_roles.business_admin)  ? (
                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  {!newtempProviderCatalogue.loading ? (
                    <MiniCharts
                      data={this.mappedDataForPartner(
                        newtempProviderCatalogue?.data
                      )}
                      cardLabel={"PARTNER BY DOMAIN CATEGORY"}
                    />
                  ) : (
                    <MiniChartsSkeleton messages="Domain Category Loading" />
                  )}
                </Grid>
              ) : (
                <></>
              )}

              <Grid
                item
                xl={
                  this.props.auth_data.user_role === user_roles.super_admin || this.props.auth_data.user_role === user_roles.business_admin
                    ? 3
                    : 4
                }
                lg={
                  this.props.auth_data.user_role === user_roles.super_admin || this.props.auth_data.user_role === user_roles.business_admin
                    ? 3
                    : 4
                }
                md={6}
                sm={6}
                xs={12}
              >
                {!newtempPersonaCatalogue.loading ? (
                  <MiniCharts
                    data={this.mapperDataForResource(
                      newtempPersonaCatalogue?.data
                    )}
                    cardLabel={"RESOURCES"}
                  />
                ) : (
                  <MiniChartsSkeleton messages="Resources Loading" />
                )}
              </Grid>
              <Grid
                item
                xl={
                  this.props.auth_data.user_role === user_roles.super_admin || this.props.auth_data.user_role === user_roles.business_admin
                    ? 3
                    : 4
                }
                lg={
                  this.props.auth_data.user_role === user_roles.super_admin || this.props.auth_data.user_role === user_roles.business_admin
                    ? 3
                    : 4
                }
                md={6}
                sm={6}
                xs={12}
              >
                {!newtempServiceCatalogue.loading ? (
                  <MiniCharts
                    data={this.mapperDataForService(
                      newtempServiceCatalogue?.data
                    )}
                    cardLabel={"PRODUCTS"}
                  />
                ) : (
                  <MiniChartsSkeleton messages="Products Loading" />
                )}
              </Grid>
              <Grid
                item
                xl={
                  this.props.auth_data.user_role === user_roles.super_admin || this.props.auth_data.user_role === user_roles.business_admin
                    ? 3
                    : 4
                }
                lg={
                  this.props.auth_data.user_role === user_roles.super_admin || this.props.auth_data.user_role === user_roles.business_admin
                    ? 3
                    : 4
                }
                md={6}
                sm={6}
                xs={12}
              >
                {!newtempClientCatalogue.loading ? (
                  <MiniCharts
                    data={this.mapperDataForClient(
                      newtempClientCatalogue?.data
                    )}
                    cardLabel={"CLIENTS"}
                  />
                ) : (
                  <MiniChartsSkeleton messages="Clients Loading" />
                )}
              </Grid>

              {/* { this.props.auth_data.user_role === user_roles.super_admin ?  */}

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                container
                // alignItems="stretch"
              >
                {!this?.props?.all_requests?.loading &&
                this?.props?.all_requests?.data ? (
                  <DashboardPendngRequestListView
                    // filterOptions={newtempTopService?.data?.filters}
                    onResendClick={this.ViewRequest}
                    tableData={this?.props?.all_requests?.data}
                    hideFilterAndClose
                    hideTotalCount
                    showActionButton={true}
                    disableFilter={this.props.auth_data.user_role === user_roles.business_admin ? true : false}
                    changeRequest={this.changeRequest}
                    getSpecficData={this.getSpecficData}
                    filter_label={this.state.filter_label}
                    filter={this.state.filter}
                    filter_id={this.state.filter_id}
                  />
                ) : (
                  <DashboardListviewLoadingSection
                    message="Fetching Pending Cancellation "
                    top="5%"
                  />
                )}
              </Grid>
              {/* : <></> } */}
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={4}
                container
                // alignItems="stretch"
              >
                <Todo
                  handleDelete={this.handleDelete}
                  handleEdit={this.handleEdit}
                  // todos={data.todos}
                  todos={tododata}
                  handleTaskToggle={this.handleTaskToggle}
                  handleAddTodo={this.handleAddTodo}
                  updateTodoStatus={this.props.updateTodo}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                container
                alignItems="stretch"
              >
                {!newtempTopService.loading && newtempTopService.data ? (
                  <DashboardListView
                    filterOptions={newtempTopService?.data?.filters}
                    // listTitle={"Top Services"}
                    // totalCount={52}
                    // filterDateRange={"From Aug 01 to Aug 31, 2020"}
                    tableData={newtempTopService?.data?.table_data}
                    hideFilterAndClose
                    hideTotalCount
                  />
                ) : (
                  <DashboardListviewLoadingSection
                    message="Fetching Top Services, Products and Packages"
                    top="5%"
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
        {/* )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state?.custom_widget_dashboard?.dashboard,
  listtodos: state?.custom_widget_dashboard?.dashboardTodo,
  deleteTodo: state?.todo?.deleteTodo,
  updateTodo: state?.todo?.updateTodo,
  auth_data: state?.authState?.loginAuth?.data ?? {},
  userprofile: state?.custom_widget_dashboard?.userprofile,
  getAllRecommendation: state?.purchase?.getAllRecommendation,
  all_requests: state?.activity_app?.all_requests,
  addQuote: state?.quotenq?.updateDocStatus,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAlelrtAndDialog(withNavBars(withStyles(style)(Dashboard)))));
