import React from 'react';
import ProductsTracking from '../../../../components/recommended/productTracking/recommendedProductTracking'
import { TrackerLoaders, ProductErrors } from '../../../../components/recommended/productTracking/recommendedProductTracking'
import ProductTracker from '../../../../components/recommended/productTracking/ProductTracker'
import { Grid } from '@material-ui/core';

import { LoadingSection, InternatlServerError, NotFoundSection } from '../../../../components/integerateHelper'

import { connect } from 'react-redux'
import { actions } from 'tanyacare-middleware'
import { withRouter } from 'react-router-dom'
class TrackRecommendedProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.getTrackingDetails()
    }

    getTrackingDetails = () => {
        const id = this.props?.match?.params?.client_id
        const pid = this.props?.match?.params?.product_id
        this.props.GET_RECOMMENDATION_TRACK({ cliendID: id, productID: pid })
    }

    render() {
        let { data, loading, error } = this.props.tracking
        // loading = true
        // error = true
        return (
            <div style={{ margin: 25 }}>
                <Grid container alignItems="center">
                    {loading && (
                        <Grid item xs={12}>
                            <TrackerLoaders />
                        </Grid>
                    )}
                    {error && (
                        <Grid item xs={12}>
                            <ProductErrors />
                        </Grid>
                    )}
                    {!loading && !error && data && <Grid item xs={12}>
                        <ProductsTracking {...data} />
                    </Grid>}

                    {loading && (
                        <Grid item xs={12}
                            style={{
                                background: 'white',
                                paddingBottom: 100,
                                borderBottomLeftRadius: 8,
                                borderBottomRightRadius: 8
                            }}>
                            <LoadingSection top="15vh" />
                        </Grid>
                    )}

                    {error && (
                        <Grid item xs={12}
                            style={{
                                background: 'white',
                                paddingBottom: 100,
                                borderBottomLeftRadius: 8,
                                borderBottomRightRadius: 8
                            }}>
                            <InternatlServerError top="10vh" />
                        </Grid>
                    )}

                    {!loading && !error && data && <Grid item xs={12}>
                        <ProductTracker {...data} />
                    </Grid>}

                    {!loading && !error && !data && <div style={{ width: "100%" }}>
                        <NotFoundSection message="Nothing to track"  />
                    </div>}
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tracking: state?.clientRecommendation?.recommendationTracking
})

export default connect(mapStateToProps, actions)(withRouter(TrackRecommendedProducts));