import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const CountLabel = (props) => {
    let { isCountLabelAPrice, setPriceTrailingDecimal } = props
    
    return (
        <Grid container direction="column" alignItems="center" justify="center" style={{ padding: !props.label ? `0px 8px 0px 24px` : `0px 12px 0px 12px`, flexWrap: 'unset' }}>
            {!setPriceTrailingDecimal ? 
                <>
                    {/* Use this for Persona management list card Component */}
                    {props.value !== null && (
                        <Grid item xs={12} style={{ flexBasis: 'unset' }}>
                            <Typography variant="h6" align="center">
                                {isCountLabelAPrice && props.label}
                                {' '}
                                {props?.value}
                            </Typography>
                        </Grid>
                    )}
                    {!isCountLabelAPrice && props.label !== null && (
                        <Grid item xs={12} style={{ flexBasis: 'unset' }}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                align="center"
                            >
                                {'props.label'}
                            </Typography>
                        </Grid>
                    )}
                </>
                :
                <>
                    {/* Use this for Product management list card Component */}
                    {
                        props.value !== null && (
                            <Grid item xs={12} style={{ flexBasis: 'unset' }}>
                                {props?.label !== "Services" ? <Typography variant="h6" align="center">
                                    {isCountLabelAPrice && props.label}
                                    {" "}
                                    {(props?.value[0] === 'R' && props?.value[1] === 'M') ? `RM ${(parseFloat((props.value).replaceAll('RM', ''))).toFixed(2)}`
                                        :
                                        // props?.value?.length > 0 ? parseFloat(props?.value).toFixed(2) : ""
                                        props?.value
                                    }
                                </Typography>
                                    :
                                    <Typography variant="h6" align="center">
                                        {props?.value}
                                    </Typography>
                                }
                            </Grid>
                        )
                    }
                    {!isCountLabelAPrice && props.label !== null && (
                        <Grid item xs={12} style={{ flexBasis: 'unset' }}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                align="center"
                            >
                                {props.label}
                            </Typography>
                        </Grid>
                    )}
                </>
            
            }
        </Grid>
    );
};

CountLabel.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    /*
     *  Whether the count label a price, to set price and currency unit side by side 
     */
    isCountLabelAPrice: PropTypes.bool
}

export default CountLabel;