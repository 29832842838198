import { Button } from "@material-ui/core";
import React from "react";

import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import TablePagination from '@material-ui/core/TablePagination';
import Paper from "@material-ui/core/Paper";
import moment from "moment";
// import { Badge } from "material-ui";
import { Lens } from "@material-ui/icons";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import IconButton from "@material-ui/core/IconButton";
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Pagination } from "@material-ui/lab";
// import { productSortFieldOption } from "../../utils";

// const filterOptions = [
//   {
//     label: "Z to A",
//     value: 2
//   },
//   {
//     label: "A to Z",
//     value: 1
//   }
// ];

const columns = [
  {
    id: "serial_number",
    label: "#",
    align: "center",
    minWidth: 50,
    isButtonNeeded: false,
  },
  {
    id: "user_name",
    label: `User Name`,
    align: "left",
    minWidth: 120,
    isButtonNeeded: true,
    button_id: "sortUserNameButtonAdministration",
  },
  
  {
    id: "email_id",
    label: "Email Address",
    minWidth: 120,
    align: "left",
    isButtonNeeded: true,
    button_id: "sortEmailButtonAdministration",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "role",
    label: "Role",
    minWidth: 120,
    align: "left",
    isButtonNeeded: true,
    button_id: "sortRoleButtonAdministration",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 70,
    align: "center",
    isButtonNeeded: false,
    button_id: "sortStatusButtonAdministration",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "date_created",
    label: "Date Created",
    align: "center",
    minWidth: 100,
    isButtonNeeded: true,
    button_id: "sortDateCreatedButtonAdministration",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 140,
    align: "center",
    isButtonNeeded: false,
    format: (value) => value.toFixed(2),
  },
];

const style = (theme) => ({
  formControl: {
    // margin: "0px 8px",
    [theme.breakpoints.down("xs")]: {
      marginTop: 8,
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.ternary.main} !important`,
        borderWidth: "1.6px !important",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 16,
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      borderRadius: 16,
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  textField: {
    width: "100%",
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.ternary.main} !important`,
        borderWidth: "1.6px !important",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  customSelect: {
    padding: "10.500px 14px",
  },
  colorPrimary: {
    color: "white",
  },
  errorPosition: {
    position: "relative",
    top: -10,
  },
  root: {
    width: "100%",
    // padding: "3px 10px"
  },
});

class UserTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 5,
      users: this.props.users
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.users !== this.props.users) {
      this.setState({users: this.props.users});
    }
  }
  getSortCatg(id) {
    // console.log("INSIDE", id)
    switch (id) {
      case "email_id":
        return "emailId";
      case "user_name":
        return "name";
      case "role":
        return "role"
      // case "status":
      //   return "enabled, emailVerified";
      case "date_created":
        return "createdAt";
      default:
        return "";
    }
  }

  getRole(role){
    switch(role){
      case "client":
        return "Client"
      case "service_professional":
        return "Service Professional"
      case "help_desk":
        return "Help Desk"
      case "provider_supervisor":
        return "Provider Supervisor"
      case "service_coordinator":
        return "Service Coordinator"
      case "provider_admin":
        return "Provider"
      case "cross-bundling":
        return "Provider Admin"
      default:
        return ""
    }
  }

  emailStatus = (status, enabled) => {
    return status ? (
      enabled ? (
        <span style={{whiteSpace: "nowrap"}}>
          <Lens
            style={{
              fontSize: "0.75rem",
              marginRight: 5,
              marginTop: 2,
              color: "#74B1B5",
              whiteSpace: "nowrap"
            }}
          />
          <span>Active</span>
        </span>
      ) : (
        <span style={{whiteSpace: "nowrap"}}>
          <Lens
            style={{
              fontSize: "0.75rem",
              marginRight: 5,
              marginTop: 2,
              color: "red",
              whiteSpace: "nowrap"
            }}
          />
          <span>Inactive</span>
        </span>
      )
    ) : (
      <span style={{whiteSpace: "nowrap"}}>
        <Lens
          style={{
            fontSize: "0.75rem",
            marginRight: 5,
            marginTop: 2,
            color: "#f5bca0",
          }}
        />
        <span>Invited</span>
      </span>
    );
  };

  createRowData(x, index, onResendClick, page) {
    // 
    // console.log(index, page)
    // console.log((index + 1) + (page * 8))
    return {
      serial_number: (index + 1) + (page * 8) ,
      user_name: x.name,
      email_id: x.emailId,
      role: this.getRole(x.role),
      status: this.emailStatus(x.emailVerified, x.enabled),
      action: (
        <Button
          color="primary"
          variant="outlined"
          id={x.id}
          onClick={() => onResendClick(x?.id, x?.role)}
          style={{"whiteSpace": "nowrap"}}
          // disabled={this.props.loading && x?.id ? true : false}
        >
          {" "}
          Reset Password
        </Button>
      ),
      date_created: moment(x.createdAt).format("DD/MM/YYYY"),
    };
  }

  createTableData = (users, onResendClick, page) => {
    let data = [];
   
    if(Array.isArray(users) && users?.length > 0)
    {
      users.map((x, index) => {
         return data.push(this.createRowData(x, index, onResendClick, page));
      }) ;
    }
    
    return data;
  };

  // handleChangePage = (event, newPage) => {
  //   console.log(newPage)
  //   console.log(event)
  //   // this.setState({page: newPage});
  // };

  handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(+event.target.value);
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 0 });
  };

  render() {
    let {
      classes,
      total,
      page,
      rowsPerPage,
      onResendClick,
      onRearrange,
      handleChangePage,
      // ind_sort,
      sortField,
      sortDirec
    } = this.props;
    // console.log(users)
    page = page - 1;

    const rows = this.createTableData(this.state.users, onResendClick, page);

    // const handleChangePage = (event, newPage) => {
    //   console.log(newPage)
    //   console.log(event)
    //   // this.setState({page: parseInt(parseInt(this.state.page) + 1)});
    //   // // 
    //   // console.log(this.state.page)
    //   // loadNewData(this.state.page)
    //   // this.setState({page: newPage});
    // };

    // console.log(sortField, sortDirec)
    return (
      <div style={{ width: "100%" }}>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ cursor: "pointer", width: column.minWidth }}
                      onClick={() => onRearrange(column.id)}
                    >
                      {column.label}
                    
                      {column.isButtonNeeded ? (
                        <IconButton
                          color="black"
                          aria-label="sorting icon"
                          component="span"
                          id={column?.button_id}
                          style={{ padding: 0 }}
                        >
                          { sortField === this.getSortCatg(column.id) ? sortDirec==="asc" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon/> : <ArrowDropDownIcon style={{opacity:0.5 }}/> }
                        </IconButton>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {/* <TablePagination
                  rowsPerPageOptions={[5 ,10, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                /> */}
        {/* <ButtonGroup variant="text" color="primary" style={{paddingTop: 5}} align={"right"}> */}
        <div style={{ paddingTop: 20, position: "relative", float: "right" }}>
          {/* <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => handleChangePage()}>
                <ArrowBackIosIcon style={{fontSize:"medium"}} />
              </IconButton>
              <IconButton color="primary"  aria-label="upload picture" component="span" onClick={() => handleChangePage()}>
                <span style={{fontSize:"medium"}}>1</span>
              </IconButton>
              <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => handleChangePage()}>
                <span style={{fontSize:"medium"}}>2</span>
              </IconButton>
              <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => handleChangePage()}>
                <span style={{fontSize:"medium"}}>3</span>
              </IconButton>
              <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => handleChangePage()}>
                <span style={{fontSize:"medium"}}>4</span>
              </IconButton>
              <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => handleChangePage()}>
                <span style={{fontSize:"medium"}}>5</span>
              </IconButton>
              <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => handleChangePage()}>
                <ArrowForwardIosIcon style={{fontSize:"medium"}} />
              </IconButton> */}
          <Pagination
            count={Math.ceil(total/rowsPerPage)}
            defaultPage={1}
            color="primary"
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
        {/* </ButtonGroup> */}
      </div>
    );
  }
}

export default withStyles(style)(UserTable);

