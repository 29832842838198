import { filterType, user_roles } from "../../../../utils"

export const filterComponent = (state, props) => {

  let id = state?.id ;
  switch (props?.auth_data?.data?.user_role) {
    case user_roles.super_admin: {
      return {
        section_1: {
          label: "Filters",
          fields: {
            category: {
              key: "category",
              id:`selectCategoryFilterFrom${id ? id : ""}`,
              type: filterType.CATEGORY,
              breakPoints: { 
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY CATEGORY",
              value: state.category,
              isMulti: true
            },
            provider: {
              key: "provider",
              id:`selectProviderFilterFrom${id ? id : ""}`,
              type: filterType.PROVIDER,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY PARTNER",
              value: state.provider,
              isMulti: true
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY  STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMandatory: true,
            },
          }
        }
      }
    }

    case user_roles.help_desk: {
      return {
        section_1: {
          label: "Filters",
          fields: {
            category: {
              key: "category",
              id:`selectCategoryFilterFrom${id ? id : ""}`,
              type: filterType.CATEGORY,
              breakPoints: { 
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY CATEGORY",
              value: state.category,
              isMulti: true
            },
            provider: {
              key: "provider",
              id:`selectProviderFilterFrom${id ? id : ""}`,
              type: filterType.PROVIDER,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY PARTNER",
              value: state.provider,
              isMulti: true
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY  STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMandatory: true,
            },
          }
        }
      }
    }

    case user_roles.business_admin: {
      return {
        section_1: {
          label: "Filters",
          fields: {
            category: {
              key: "category",
              id:`selectCategoryFilterFrom${id ? id : ""}`,
              type: filterType.CATEGORY,
              breakPoints: { 
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY CATEGORY",
              value: state.category,
              isMulti: true
            },
            provider: {
              key: "provider",
              id:`selectProviderFilterFrom${id ? id : ""}`,
              type: filterType.PROVIDER,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY PARTNER",
              value: state.provider,
              isMulti: true
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY  STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMandatory: true,
            },
          }
        }
      }
    }

    case user_roles.provider: {
      return {
        section_1: {
          label: "Filters",
          fields: {
            category: {
              key: "category",
              id:`selectCategoryFilterFrom${id ? id : ""}`,
              type: filterType.CATEGORY,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY CATEGORY",
              value: state.category,
              isMulti: true
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMulti: false,
              isMandatory: true,
            },
          }
        }
      }
    }

    case user_roles.supervisor: {
      return {
        section_1: {
          label: "Filters",
          fields: {
            category: {
              key: "category",
              id:`selectCategoryFilterFrom${id ? id : ""}`,
              type: filterType.CATEGORY,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY CATEGORY",
              value: state.category,
              isMulti: true
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMulti: false,
              isMandatory: true,
            },
          }
        }
      }
    }

    case user_roles.service_coordinator: {
      return {
        section_1: {
          label: "Filters",
          fields: {
            category: {
              key: "category",
              id:`selectCategoryFilterFrom${id ? id : ""}`,
              type: filterType.CATEGORY,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY CATEGORY",
              value: state.category,
              isMulti: true
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMulti: false,
              isMandatory: true,
            },
          }
        }
      }
    }

    default: return {}
  }
}