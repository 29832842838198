import React from 'react';
// import DetailsCard from './DetailsCard'
import DetailsCard from '../../../resourceManagement/resources/details/detailCard'
import ViewProviderBranches from '../../../resourceManagement/resources/details/ViewProviderBranches'
// import CardList from "../../../../components/userList";
import { routes } from "../../../../router/routes";
import { Grid, withStyles, Divider } from '@material-ui/core';
import AvatarStack from "../../../../components/avatarStack";
// import QuickCards from "../../../../components/quickCards";
// import { ICONS } from "../../../../components/quickCards";
import { HybridTypography } from "../../../../components";
import { ICONS } from "../../../../components/hybridTypography/typography";
import ViewServiceCoordinators from '../../../userManagement/careGiver/details/ViewServiceCoordinators';
import ViewServiceSupervisors from '../../../userManagement/careGiver/details/ViewServiceSupervisors';
import { withAlertAndDialog } from '../../../../HOCs';
import { withRouter } from 'react-router-dom';
import { converToReactSelect, alertProps } from '../../../../utils'
import { viewScreensBasedOnPersonaByResourceTypeId } from '../../../resourceManagement/resources/utils';
import {actions} from 'tanyacare-middleware';
import { connect } from "react-redux";
import { allResourceLabel} from "../../../resourceManagement/resources/utils";
import { Information } from '../../../../components/information';



// const sizes = {
//   md: 12,
//   sm: 6,
//   xs: 12,
// };

const styles = (theme) => ({
  paperCard: {
    borderRadius: "0px",
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
  },
  sectionContainer: {
    padding: 25,
    background: "white",
  },
  isNotBottom: {
    borderRadius: "0px",
    padding: "25px 25px 0px 32px",
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
  },
  strong_name: {
    display: "inline-block",
    paddingTop: 12,
    color: theme.palette.primary.dark,
    fontSize: 14,
    paddingLeft: "40px",
  },
  attachroot: {
    padding: 10,
    marginLeft: 30,
    border: "1px dashed " + theme.palette.primary.light,
    background: theme.palette.secondary.light,
    marginTop: "10px",
    borderRadius: "6px",
  },
  user_list: {
    display: "grid",
    gridTemplateColumns: "80px 1fr",
  },
  avatarhead: {
    display: "flex",
  },
  user_name: {
    display: "inline-block",
    paddingRight: "12px",
    fontSize: "1rem",
  },
  avatar: {
    width: "50px",
    height: "50px",
    margin: "auto",
  },
  secondary: {
    color: theme.palette.secondary.main,
    fontSize: "14px",
    paddingTop: "7px",
  },
  marginHeight: {
    marginTop: "auto",
    marginBottom: "auto",
    background: "white",
  },
  bottomRounded: {
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  avatar__: {
    width: "100%",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    height: "100%",
    display: "flex",
    "& .MuiAvatar-root": {
      margin: "25% auto 0%",
      background: "rgba(0, 0, 0, 0.03)",
      "& img": {
        objectFit: "scale-down"
      }
    }
  },

  title_: {
    fontSize: 15,
    lineHeight: 1.7,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  sub_text: {
    display: 'inline-block',
    fontSize: 12,
    margin: "0px 0px 8px 0px",
    lineHeight: 1.6,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  footer: {
    fontWeight: 400,
    fontSize: 12,
  },
  title__: {
    fontSize: "17px",
    fontWeight: 600,
    textAlign: "left",
    marginBottom: 8,
    marginTop: 8,
  },
  add_title__: {
    marginTop: 20,
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "left",
    color: "#f27d35",
    cursor: "pointer",
    width: "max-content"
  },
  list: {
    "& li": {
      padding: 0
    },
    "& .MuiListItem-gutters": {
      borderBottom: "1px solid #f3f3f3",
    }
  },
  dividerBackground: {
    width: "100%",
    marginTop: 8, backgroundColor: theme.palette.secondary.light,
  },
  arr: {
    marginBottom: -6,
    fontSize: 20
  },
  listTextSpacing: {
    marginBottom: theme.spacing(1)
  }
});

class Summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    this.giveMeNecessaryData()
  }

  getNeededData = () => {
    const id = this.props?.match?.params?.id;

    this.props.GET_PERSONA_INFO({ resoureId: id });
  }

  giveMeNecessaryData = () => {
    const { location = {}, personaResourceInfo } = this.props;

    const { data } = personaResourceInfo;

    let necessaryData = {
      ...personaResourceInfo,
    };

    if (location?.state) {
      necessaryData = {
        ...location.state,
        ...personaResourceInfo,
      };
    }

    if (data) {
      const _ = data[0];

      console.log(_)

      const header = [
        {
          id: _?.resourceId ?? "",
          name: _?.resourceName ?? "",
          salutation: _?.resourceSalutation ?? "",
          category: _?.resourceCategory ? converToReactSelect(_?.resourceCategory, "categoryId", "categoryName") : [],
          isactive: _?.isActive,
          emailVerified: _?.emailVerified,
          profile_pic: _?.['resourceMeta']?.['uploadImage']?.['value'] ?? "",
          aliasId: _?.aliasId,
          aliasLabel: "Professional ID"
        },
      ];

      // const footer = [
      //   {
      //     name: `${_.care_giver_count ? _.care_giver_count : "0"} Service Professionals`,
      //   },
      // ];

      necessaryData = {
        ...personaResourceInfo,
        header,
        // footer,
      };
    }

    return necessaryData;
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { updateCareProviderStatus } = nextProps;
    const oldUpdateCareProviderStatus = this.props.updateCareProviderStatus;

    if (
      oldUpdateCareProviderStatus?.loading !== updateCareProviderStatus?.loading
    ) {
      if (
        !updateCareProviderStatus.loading &&
        !updateCareProviderStatus.error
      ) {
        this.giveMeNecessaryData();
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Care Provider status updated successfully!",
          severity: alertProps.severity.success,
        });
      } else if (updateCareProviderStatus.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to update.",
          severity: alertProps.severity.error,
        });
      }
    }

    // assign coordinator to professional
    const { mapCoordinatorToProf } = nextProps;
    const oldmapCoordinatorToProf = this.props.mapCoordinatorToProf;

    if (
      oldmapCoordinatorToProf?.loading !== mapCoordinatorToProf?.loading
    ) {
      if (
        !mapCoordinatorToProf.loading &&
        !mapCoordinatorToProf.error
      ) {
        this.giveMeNecessaryData();
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `Coordinator Removed`,
          severity: alertProps.severity.success,
        });
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          open: false,
          component: (
            <></>
          ),
        });
      } else if (mapCoordinatorToProf.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Assign Coordinator.",
          severity: alertProps.severity.error,
        });
      }
    }


    // STATUS UPDATE
    const { updatePersonaResource } = nextProps;
    const oldupdatePersonaResource = this.props.updatePersonaResource;
    //Checking for Add resource messages
    if (oldupdatePersonaResource?.loading !== updatePersonaResource?.loading) {
      if (!updatePersonaResource?.loading && !updatePersonaResource?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `Status updated successfully!`,
          severity: alertProps.severity.success,
        });
        this.giveMeNecessaryData()
      } else if (updatePersonaResource.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `Something went wrong! Unable to update status ${allResourceLabel(this.props?.match?.path)}.`,
          severity: alertProps.severity.error,
        });
      }
    }

    const { unmapCoordinatorToProf } = nextProps;
    const oldunmapCoordinatorToProf = this.props.unmapCoordinatorToProf;
    if(oldunmapCoordinatorToProf?.loading !== unmapCoordinatorToProf?.loading){
      if (
        !unmapCoordinatorToProf.loading &&
        !unmapCoordinatorToProf.error
      ) {
        this.giveMeNecessaryData();
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `${routes.view_care_coordinators === this.props.match.path ? 'Professional Unassigned successfully!' : 'Coordinator Unassigned successfully!'}`,
          severity: alertProps.severity.success,
        });
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          open: false,
          component: (
            <></>
          ),
        });
        this.getNeededData()
      } else if (unmapCoordinatorToProf.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Assign Coordinator.",
          severity: alertProps.severity.error,
        });
      }
    }


    return true;
  }

  handleViewAllClick = () => {
    console.log('Open drawer');
  }

  handleViewAllClick = (data) => {
    // console.log(data)
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: <ViewServiceCoordinators
        data={data?.service_coordinators ?? []}
        drawer={this.props.drawer}
        onEndIconClickHandler={this.onEndIconClickHandler}
        onRemoveAssigned={this.onRemoveAssigned}
      />
    })
  }

  handleViewAllSupervisorClick = (data) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: <ViewServiceSupervisors
        data={data?.service_supervisors ?? []}
        drawer={this.props.drawer}
        onEndIconClickHandler={this.onSupervisorEndIconClickHandler}
        // onRemoveAssigned={this.onRemoveAssigned}
      />
    })
  }

  onEndIconClickHandler = (id, isCareGiver) => {
    // window.open(`${isCareGiver ? routes.manage_care_givers : routes.manage_care_coordinators}/view/${id}`, '_blank').focus()
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    })
    this.props.history.push(`${isCareGiver ? routes.manage_care_givers : routes.manage_care_coordinators}/view/${id}`)
  }

  onRemoveAssigned =  (id) => {
    // console.log(this.props.UN_MAP_COORD_TO_PROF)
    let is_professional;
    let professional_id;
    let coordinator_id;
    // console.log(this.props.match.path.includes('care_giver'))
    if(this.props.match.path.includes('service_professionals'))
    {
      is_professional = true
      coordinator_id = id
      professional_id = this.props.personaResourceInfo.data[0].resourceId 
    }
    else
    {
      is_professional = false
      professional_id = id
      coordinator_id = this.props.personaResourceInfo.data[0].resourceId
    }
    this.props.UN_MAP_COORD_TO_PROF({professional_id, is_professional, coordinator_id})
    // this.setState({})
    // this.props.UN_MAP_COORD_TO_PROF({professionalId:this.props.personaResourceInfo.data[0].resourceId})
  }

  onSupervisorEndIconClickHandler = (id, isCareGiver, data) => {
    
    let _data = data
    // window.open(`${isCareGiver ? routes.manage_care_givers : routes.manage_care_coordinators}/view/${id}`, '_blank').focus()
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    }) 

    this.props.history.push(`${viewScreensBasedOnPersonaByResourceTypeId(_data?.resourceTypeId)}${id}`)
  }


  handleServiceProfessionalsViewAllClick = (data) => {
    // console.log(data)
    // this.props.drawer.setDrawer({
    //   ...this.props.drawer,
    //   open: true,
    //   component: (
    //     <ViewProviderBranches
    //       data={data?.value ?? []}
    //       label={data?.label}
    //       drawer={this.props.drawer}
    //       onEndIconClickHandler={this.onSupervisorEndIconClickHandler}
    //     />
    //   ),
    // });
    if(data.label === 'Service Supervisor'){
      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: true,
        component: (
          <ViewProviderBranches
            {...this.props}
            data={data?.value ?? []}
            label={data?.label}
            drawer={this.props.drawer}
            onEndIconClickHandler={this.onSupervisorEndIconClickHandler}
            // onRemoveAssigned={this.onRemoveAssigned}
            showRemoveAssigned={false}
            persona_details = {this.props.personaResourceInfo.data}

          />
        ),
      });
    }
    else
    {
      console.log(this.props.personaResourceInfo.data)
      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: true,
        component: (
          <ViewProviderBranches
            {...this.props}
            data={data?.value ?? []}
            label={data?.label}
            drawer={this.props.drawer}
            onEndIconClickHandler={this.onSupervisorEndIconClickHandler}
            onRemoveAssigned={this.onRemoveAssigned}
            showRemoveAssigned={false}
            persona_details = {this.props.personaResourceInfo.data}

          />
        ),
      });
    }
  };


  render() {

    const header = this?.giveMeNecessaryData()?.header;
    // const bottomCard = this?.giveMeNecessaryData()?.bottomCard;

    const { data, loading, classes, theme } = this.props
    const id = this.props?.match?.params?.id;
    // const versionId = this.props?.match?.params?.versionId;


    return (
      <div style={{ padding: 25 }}>
        {/* <CardList
          list={header}
          componentOrder={2}
          hideDeactivateEditButtons={true}
          sizes={sizes}
          isonlycard={true}
          link={routes.manage_care_givers + "/edit/" + id}
          onDeactivateClicked={this.onDeactivateClicked}
          isDeactivating={this.props?.updateCareGiverStatus?.loading}
        /> */}
        {/* <CardList
          list={header}
          componentOrder={5}
          // componentOrder={2}
          sizes={sizes}
          isonlycard={true}
          isCard={true}
          //   link={routes.partner_management_provider + "edit/" + id}
          // link={actionbuttonUrlBasedOnPersona(this.props?.match?.path, id)}
          //   link2={routes.partner_management_branch + "add/" + id}
          isShowEditButton={true}
          isdontshowactivebtn={true}
          hideDeactivateEditButtons={true}
          providerId={{
            value: data.id,
            label: data.name,
            provider_id: data.id,
          }}
          data={data[0]}
          onDeactivateClicked={this.onDeactivateClicked}
          isDeactivating={this.props?.updateCareGiverStatus?.loading}
          handleAddServiceProfessional={this.handleAddServiceProfessional}
          onMapResourceClicked={this.mapResource}
          showThirdBtn={false}
          isSalutationNeeded={true}
        /> */}
        <Information loading={loading} details={header} ctas={[]} />
        {/* <DetailsCard
              data={data}
              loading={loading}
              isBottomRounded={false}
              detachCareProvider={this.detachCareProvider}
              detachLoading={this.props?.detachProviderInfo?.loading}
            /> */}
        <DetailsCard dontshow={["Name"]} showkeys={["skills"]} data={data} loading={loading} />

        {
          data?.[0]?.otherInfo?.length > 0 &&
          <>
            <Divider style={{ background: "rgba(244, 244, 245, 1)" }} />
            <div className={classes.sectionContainer}>
              {
                <Grid container spacing={3}>
                  {data[0]?.otherInfo.map(val => {
                    if (Object.keys(val).length > 0) {

                      return (
                        <Grid item xs={12} sm={6} md={4}>
                          <HybridTypography
                            icon={ICONS.BUSINESS(theme.palette.primary.main)}
                            text={val?.value?.label}
                            header={(val?.label)?.toUpperCase() ?? "N/A"}
                            loading={loading}
                          // islistbottom={true}
                          // islisttop={true}
                          />
                        </Grid>
                      )
                    }
                    return true
                  })}
                  {data?.[0]?.orderList?.map((val, index) => {
                    return (

                      <Grid item xs={12} sm={6} md={4}>
                        <AvatarStack
                          title={val?.label?.toUpperCase()}
                          height={16}
                          width={16}
                          style={{ position: "relative", top: 2 }}
                          data={val?.value}
                          emptyMessage="Not yet added"
                          loading={loading}
                          onAvatarClicked={(id, data) => this.onClickIcon(id, false, data)}
                          id={`ViewMappedResourcesFrom${index ? index+1 : ""}${id ? id : ''}`}
                          onButtonClickHandler={() =>
                            this.handleServiceProfessionalsViewAllClick(val)
                          }
                        />
                      </Grid>
                    )
                  })
                  }

                </Grid>
              }
            </div>
          </>
        }
        {/* <QuickCards items={bottomCard} isBottom={true}></QuickCards> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  personaResourceInfo: state.resourceReducer?.personaResourceInfo,
  updateCareProviderStatus: state.careProvider?.updateCareProviderStatus,
  updatePersonaResource: state?.resourceReducer?.updatePersonaResource,
  mapCoordinatorToProf: state?.careCoordinator?.mapCDToProf,
  unmapCoordinatorToProf: state?.careCoordinator?.unmapCDToProf
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAlertAndDialog(withStyles(styles, {withTheme: true})(Summary))));