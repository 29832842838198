import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { routes } from "../../router/routes";

import { withNavBars } from "./../../HOCs";
import { AddEditResource, ResourceDetail, ResourceList } from "./resources";

class ResourceManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateNavBarProps: true,
    };
  }

  // NOTE:

  // ***IF YOU NEED TO ADD A NEW RESOURCE***
  //-> ADD A ROUTE - FILE->ROUTER.JS
  //-> ADD TOP BARS IN ROUTE - FILE->ROUTER/INDEX.JS
  //-> UPDATE UTILS WITH NEWLEY CREATED ROUTE - FILE-> resourcemanagement->resource->utils

  render() {
    const { match } = this.props;

    return (
      <>
        {/* Care Coordinator */}
        {match.path === routes.add_care_coordinators && <AddEditResource id={"AddEditCoordinator"}/>}
        {match.path === routes.edit_care_coordinators && (
          <AddEditResource isEdit={true} />
        )}
        {match.path === routes.view_care_coordinators && <ResourceDetail />}
        {match.path === routes.manage_care_coordinators && <ResourceList id={"ListCoordinator"}/>}

        {/* Care Supervisor */}
        {match.path === routes.add_supervisors && <AddEditResource id={"AddEditSupervisor"}/>}
        {match.path === routes.edit_supervisors && (
          <AddEditResource isEdit={true} />
        )}
        {match.path === routes.view_supervisors && <ResourceDetail />}
        {match.path === routes.manage_supervisors && <ResourceList id={"ListSupervisor"}/>}

        {/* Service Professional */}
        {match.path === routes.add_care_givers && <AddEditResource id={"AddEditProfessional"}/>}
        {match.path === routes.edit_care_givers && (
          <AddEditResource isEdit={true} />
        )}
        {match.path === routes.view_care_givers && <ResourceDetail />}
        {match.path === routes.manage_care_givers && <ResourceList id={"ListProfessionals"}/>}

        {/* Other Resource */}
        {match.path === routes.add_other_resources && <AddEditResource id={"AddEditotherResources"}/>}
        {match.path === routes.edit_other_resources && (
          <AddEditResource isEdit={true} />
        )}
        {match.path === routes.view_other_resources && <ResourceDetail />}
        {match.path === routes.manage_other_resources && <ResourceList id={"ListOtherResources"}/>}
      </>
    );
  }
}

ResourceManagement.propTypes = {
  title: PropTypes.string,
  withBackButton: PropTypes.bool,
  navBarArr: PropTypes.array,
};

ResourceManagement.defaultProps = {
  title: "Resource Management",
};

export default withRouter(withNavBars(ResourceManagement));
