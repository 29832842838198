/* eslint-disable eqeqeq */
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { actions } from "tanyacare-middleware";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withAlertAndDialog } from "../../../../HOCs";
import { routes } from "../../../../router/routes";
import { alertProps, scrollToTop, user_roles } from "../../../../utils";
import { ServiceCard } from "./serviceCard";
import { ServiceConfigurationCard } from "./serviceConfigurationCard";

const styles = (theme) => ({});

class ServiceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getServices();
    scrollToTop();
  }

  getServices = () => {
    const id = this.props?.match?.params?.id;
    const versionId = this.props?.match?.params?.versionId;
    this.props.GET_SERVICES_INFO({ id, versionId });
  };

  navigateToEdit = () => {
    const service_id = this.props?.match?.params?.id;
    const versionId = this.props?.match?.params?.versionId;
    // const versionId = this.props?.serviceInfo?.data?.[0]?.versionId;
    const route =
      routes.manage_service + "/edit/" + service_id + "/" + versionId;
    this.props.history.push(route);
  };

  onStatusChangeClick = () => {
    let service = this.props?.serviceInfo?.data?.[0];

    const oldStatus = service?.isActive;

    let status = "Activate";
    if (oldStatus) {
      status = "Deactivate";
    }

    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body: "Do you want to " + status + " Service : " + service?.resourceName,
      positiveBtn: status,
      negativeBtn: "Cancel",
      onOk: () => this.changeServiceStatus(service),
    });
  };

  changeServiceStatus = (service) => {
    const id = this.props?.match?.params?.id;
    const status = service?.isActive;

    this.props.dialog.setDialog({ open: false });
    this.props.UPDATE_STATUS_OF_SERVICE({ serviceID: id, status: !status });
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Update Status
    const { updateServiceStatusInfo } = nextProps;
    const oldUpdateServiceStatusInfo = this.props.updateServiceStatusInfo;

    //Checking for Update Status
    if (
      oldUpdateServiceStatusInfo?.loading !== updateServiceStatusInfo?.loading
    ) {
      this.props.backDrop.setBackDrop({
        open: updateServiceStatusInfo.loading,
        message: "Updating the service status...",
      });
      if (!updateServiceStatusInfo.loading && !updateServiceStatusInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Service Status updated successfully!",
          severity: alertProps.severity.success,
        });
        this.getServices();
      } else if (updateServiceStatusInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Unable to update service status. Please Try again.",
          severity: alertProps.severity.error,
        });
      }
    }

    return true;
  }

  roleBasedAccess = (id) => {
    const data = this.props?.auth_data?.data;
    
    // Return True disable edit button
    // Return False enable edit button

    let disable = null;
    if(Boolean(data?.user_role === user_roles.service_coordinator|| this.props?.auth_data?.data?.user_role === user_roles.help_desk || this.props?.auth_data?.data?.user_role === user_roles.business_admin)){
      /*SC can;t edit a Product */
      disable = true
    }

    if (data?.user_role == user_roles.provider) {
      if(data?.user_id == id?.partnerId){
        /*User can edit a Product*/
        disable = false
      }else{
        disable = true
      } 
    }
    if (data?.user_role == user_roles.supervisor) { 
        if (data?.partnerId == id?.partnerId) { 
           disable = false 
        } else{
           disable = true 
        } 
    }
    if (data?.user_role == user_roles.super_admin && data?.other_roles?.indexOf("cross-bundling") > -1) { 
      if (id?.care_provider?.[0]?.crossBundle) { 
        // id?.partnerId !== "5cd8f293-1e49-473e-af6b-bf27e986566f"
          /* no one can edit MC User's product except him*/
          disable = false
      }else{
          disable = true 
      }
    }

    if (data?.user_role == user_roles.super_admin && data?.other_roles?.indexOf("cross-bundling") === -1) { 
      if (id?.care_provider?.[0]?.crossBundle) { 
          /* no one can edit MC User's product except him*/
          disable = true
        }else{
          disable = false
      }
    }

    return disable;

  }

  render() {
    const { loading, error } = this.props?.serviceInfo;
    const serviceDetail = this.props?.serviceInfo?.data?.[0];
    const { loading: statusChangeLoading } = this.props?.updateServiceStatusInfo
    const id = this.props?.match?.params?.id;
    const versionId = this.props?.match?.params?.versionId;
    // const { classes } = this.props;
    return (
      <div>
        {loading && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <CircularProgress />
            </Grid>
            <Grid item>
              <Typography variant="h6" align="center">
                Loading the product detail
              </Typography>
            </Grid>
          </Grid>
        )}
        {!loading && error && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <Typography variant="h6" align="center">
                Something went wrong!
              </Typography>
            </Grid>
            <Grid item>
              <Button
                style={{ marginTop: 16, textAlign: "center" }}
                variant={"outlined"}
                onClick={this.getDetail}
              >
                Try Again
              </Button>
            </Grid>
          </Grid>
        )}
        {!loading && !error && !serviceDetail && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <Typography variant="h6" align="center">
                404 - Product detail not found
              </Typography>
            </Grid>
          </Grid>
        )}
        {!loading && !error && serviceDetail && (
          <div style={{ padding: "25px" }}>
            <ServiceCard
              navigateToEdit={this.navigateToEdit}
              serviceDetail={serviceDetail}
              onStatusChangeClick={this.onStatusChangeClick}
              statusChangeLoading={statusChangeLoading}
              isbuttonHide={this.roleBasedAccess(serviceDetail)}
              id={`${id}${versionId}`}
            />
            <div style={{ padding: 16 }}></div>
            <ServiceConfigurationCard serviceDetail={serviceDetail} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  serviceInfo: state?.serviceState?.serviceInfo,
  updateServiceStatusInfo: state?.serviceState?.updateServiceStatusInfo,
  auth_data: state?.authState?.loginAuth,
});

export default connect(
  mapStateToProps,
  actions
)(withAlertAndDialog(withRouter(withStyles(styles)(ServiceDetail))));
