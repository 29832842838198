import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { NextArrow } from "../../../assets";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { TextField } from "../../inputs/textField";
import { options } from "../../../utils";

// eslint-disable-next-line no-unused-vars
let geocoder;
// const libraries = ["places"];

const useStyles = makeStyles((theme) => ({
  search_location: {
    color: theme.palette.ternary.main,
    textTransform: "uppercase",
    padding: "10px 0px",
    cursor: "pointer",
  },
}));

export default function SearchLocationInput(props) {
  const { isLoaded } = useLoadScript({
    id: "google-map-script", //This id is helpful in avoiding multiple js loading.
    googleMapsApiKey: "AIzaSyBqeACJgp12OLU6EkHeUtFgya2naH1LhrU",
    libraries: ["places"],
  });
  
  const classes = useStyles();
  const { handleOnChange } = props;
  const {
    value,
    label,
    showLocationViaMaps,
    openDialogMap,
    error,
    errorText,
  } = props;

  React.useEffect(() => {
    setAddress(value?.address ? value?.address : "");
  }, [value]);


  const [autocomplete, setAutocomplete] = React.useState(null);
  const [address, setAddress] = React.useState("");
  const [, setAddressComponents] = React.useState("");

  const onAutocompleteLoad = React.useCallback(function callback(autocomplete) {
    geocoder = new window.google.maps.Geocoder();
    setAutocomplete(autocomplete);
  }, []);

  // const generateAddress = (latlng) => {
  //   geocoder.geocode({ location: latlng }, (results, status) => {
  //     if (status === "OK") {
  //       if (results[0]) {
  //         setAddress(results[0].formatted_address);
  //         setAddressComponents(results[0].address_components);
  //       } else {
  //         window.alert("No results found");
  //       }
  //     } else {
  //       window.alert("Geocoder failed due to: " + status);
  //     }
  //   });
  // };

  const generateAndSetAddress = (place) => {
    setAddress(place.formatted_address);
    setAddressComponents(place.address_components);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      let place = autocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        console.error("Returned place contains no geometry");
        return;
      } else {
        // let selected = {
        //   lat: place.geometry.location.lat(),
        //   lng: place.geometry.location.lng(),
        // };
        // generateAddress(selected);
        generateAndSetAddress(place);
        let address = {};
        address["address"] = place?.formatted_address;
        address["latitude"] = place?.geometry?.location?.lat();
        address["longitude"] = place?.geometry?.location?.lng();
        address["address_components"] = place?.address_components;
        handleOnChange(address);
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  return (
    <>
        {isLoaded ? <div className="search-location-input">
          <Autocomplete
            onLoad={onAutocompleteLoad}
            onPlaceChanged={onPlaceChanged}
          >
            <TextField
              type="text"
              title={label}
              id={props?.id}
              placeholder="Search location"
              value={address}
              handleOnChange={(event) => setAddress(event.target.value)}
              error={error}
              errorText={errorText}
              required={props.required}
              disabled={(!!props.venue && props.venue !== options.newAddress.value) ? true : false}
            />
          </Autocomplete>
          {showLocationViaMaps && (
            <Typography
              variant="caption"
              component="div"
              className={classes.search_location}
              onClick={openDialogMap}
            >
              Search Location via Maps{" "}
              <span>
                <NextArrow />
              </span>
            </Typography>
          )}
          {/* </LoadScript> */}
        </div> : <></>}
    </>
  );
}
