import * as React from "react"

export const Rarrow2 = (props) => {

    const {
        width = "29.967",
        height = "23.658",
        color="#6f6f6f"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 29.967 23.658"
        >
              <path id="icons8-arrow_3_" data-name="icons8-arrow (3)" d="M30.132,20a.394.394,0,0,0-.273.116L26.31,23.664a.394.394,0,0,0,0,.558l4.453,4.453H12.394a.394.394,0,0,0-.394.394v5.52a.394.394,0,0,0,.394.394H30.763L26.31,39.436a.394.394,0,0,0,0,.558l3.549,3.549a.394.394,0,0,0,.558,0L41.851,32.108a.394.394,0,0,0,0-.558L30.417,20.115A.394.394,0,0,0,30.132,20Zm.006.952L41.015,31.829,30.138,42.706l-2.991-2.991,4.847-4.847a.394.394,0,0,0-.279-.673H12.789V29.463H31.715a.394.394,0,0,0,.279-.673l-4.847-4.847Zm.195,2a.2.2,0,0,0-.137.339l4.14,4.14a.2.2,0,1,0,.279-.279l-4.14-4.14a.2.2,0,0,0-.142-.06Zm6.112,6.112a.2.2,0,0,0-.137.339l.986.986a.2.2,0,1,0,.279-.279l-.986-.986A.2.2,0,0,0,36.444,29.066Zm1.971,1.971a.2.2,0,0,0-.137.339l.591.591a.2.2,0,1,0,.279-.279l-.591-.591a.2.2,0,0,0-.142-.06ZM37.231,33.4a.2.2,0,0,0-.136.059l-6.9,6.9a.2.2,0,1,0,.279.279l6.9-6.9a.2.2,0,0,0-.143-.338Z" transform="translate(-12 -20)" 
                fill={color}
            />
        </svg>
    );
};
