import { routes } from "../router/routes";
// import * as ct from "countries-and-timezones";
import moment from "moment-timezone";
import countryCode from "./countryCode.json";
const ct = require("countries-and-timezones");

export const user_roles = {
  super_admin: "super-admin",
  provider: "provider_admin",
  supervisor: "provider_supervisor",
  service_professional: "service_professional",
  service_coordinator: "service_coordinator",
  business_admin: "business-admin",
  help_desk: "help_desk",
};

export const user_roles_name = {
  "super-admin": "Admin",
  provider_admin: "Partner",
  provider_supervisor: "Supervisor",
  service_professional: "Service Professional",
  service_coordinator: "Service Coordinator",
  "business-admin": "Business Admin",
  help_desk: "Help Desk",
};

export const OTHER_ROLES = {
  crossBundling: "cross-bundling",
};

export const themes = {
  default: "default",
  dark: "dark",
};

export const alertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

export const drawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

export const localStorageKeys = {
  auth_token: "auth_token",
  _mGdRe: "_mGdRe",
};

export const netWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

const userManagementObj = {
  provider: {
    id: 1,
    link: routes.manage_care_providers,
    label: "Provider",
  },
  supervisor: {
    id: 5,
    link: routes.manage_supervisors,
    label: "Service Supervisor",
  },
  service_coordinator: {
    id: 3,
    link: routes.manage_care_coordinators,
    label: "Service Coordinator",
  },
  service_professional: {
    id: 2,
    link: routes.manage_care_givers,
    label: "Service Professional",
  },
  clients: {
    id: 4,
    link: routes.manage_clients,
    label: "Client",
  },
};
const userManagementObjShouldNotShowForSuperAdmin = {
  branch: {
    id: 1,
    link: routes.manage_care_providers,
    label: "Branches",
  },
};
export const userManagementTopNavBars = (user_role) => {
  switch (user_role) {
    case user_roles.super_admin:
      return Object.values(userManagementObj);

    case user_roles.provider:
      return [
        userManagementObjShouldNotShowForSuperAdmin.branch,
        userManagementObj.service_coordinator,
        userManagementObj.service_professional,
        userManagementObj.clients,
      ];

    case user_roles.supervisor:
      return [
        userManagementObj.service_coordinator,
        userManagementObj.service_professional,
        userManagementObj.clients,
      ];

    case user_roles.service_coordinator:
      return [
        userManagementObj.service_professional,
        userManagementObj.clients,
      ];

    // case user_roles.business_admin:
    //   return Object.values(userManagementObj);

    default:
      return [];
  }
};

const productManagementObjSuperAdmin = {
  services: {
    id: 1,
    link: routes.manage_service,
    button_id: "TopNavServices",
    label: "Services",
  },
  packages: {
    id: 2,
    link: routes.manage_package,
    button_id: "TopNavPackages",
    label: "Packages",
  },
  products: {
    id: 3,
    link: routes.manage_product,
    button_id: "TopNavProducts",
    label: "Products",
  },
  promotionalBanner: {
    id: 4,
    link: routes.manage_promotional_banner,
    button_id: "TopNavPromotionalBanner",
    label: "Promotional Banners",
  },
};

const productManagementObjManagedcareAdmin = {
  services: {
    id: 1,
    link: routes.manage_service,
    button_id: "TopNavServices",
    label: "Services",
  },
  packages: {
    id: 2,
    link: routes.manage_package,
    button_id: "TopNavPackages",
    label: "Packages",
  },
  products: {
    id: 3,
    link: routes.manage_product,
    button_id: "TopNavProducts",
    label: "Products",
  },
  promotionalBanner: {
    id: 4,
    link: routes.manage_promotional_banner,
    button_id: "TopNavPromotionalBanner",
    label: "Promotional Banners",
  },
};

const productManagementObj = {
  services: {
    id: 1,
    link: routes.manage_service,
    button_id: "TopNavServices",
    label: "Services",
  },
  packages: {
    id: 2,
    link: routes.manage_package,
    button_id: "TopNavPackages",
    label: "Packages",
  },
  products: {
    id: 3,
    link: routes.manage_product,
    button_id: "TopNavProducts",
    label: "Products",
  },
};

const partnerManagementObj = {
  provider: {
    id: 1,
    link: routes.partner_management_provider,
    button_id: "TopNavPartner",
    label: "Partners",
  },
  branch: {
    id: 2,
    link: routes.partner_management_branch,
    button_id: "TopNavBranch",
    label: "Branches",
  },
};

const partnerManagementObjProvider = {
  branch: {
    id: 1,
    link: routes.partner_management_branch,
    button_id: "TopNavBranches",
    label: "Branches",
  },
};
const resourceManagementObj = {
  supervisor: {
    id: 1,
    link: routes.manage_supervisors,
    button_id: "TopNavServiceSupervisor",
    label: "Service Supervisor",
  },
  coordinator: {
    id: 2,
    link: routes.manage_care_coordinators,
    button_id: "TopNavServiceCoordinator",
    label: "Service Coordinator",
  },
  professional: {
    id: 3,
    link: routes.manage_care_givers,
    button_id: "TopNavServiceProfessional",
    label: "Service Professional",
  },
  other_resources: {
    id: 4,
    link: routes.manage_other_resources,
    button_id: "TopNavOtherResources",
    label: "Other Resources",
  },
};

const resourceManagementObjSupervisor = {
  coordinator: {
    id: 1,
    link: routes.manage_care_coordinators,
    button_id: "TopNavServiceCoordinator",
    label: "Service Coordinator",
  },
  professional: {
    id: 2,
    link: routes.manage_care_givers,
    button_id: "TopNavServiceProfessional",
    label: "Service Professional",
  },
  other_resources: {
    id: 3,
    link: routes.manage_other_resources,
    button_id: "TopNavOtherResources",
    label: "Other Resources",
  },
};

const resourceManagementObjCoordinator = {
  professional: {
    id: 1,
    link: routes.manage_care_givers,
    button_id: "TopNavServiceProfessional",
    label: "Service Professional",
  },
  other_resources: {
    id: 2,
    link: routes.manage_other_resources,
    button_id: "TopNavOtherResources",
    label: "Other Resources",
  },
};

const reportManagementObj = {
  transaction: {
    id: 1,
    link: routes.transactionReport,
    button_id: "TopNavTransactionReport",
    label: "Transaction Report",
  },
  orderReport: {
    id: 2,
    link: routes.orderReport,
    button_id: "TopNavOrderReport",
    label: "Order Activity Report",
  },
  enquiryReport:{
    id: 3,
    link:routes.enquiryReport,
    button_id:"TopNavEnquiryReport",
    label:"Enquiry Report"
  },
};

export const productManagementTopNavBars = (user_role, otherRoles) => {
  switch (user_role) {
    case user_roles.super_admin:
      return Object.values(productManagementObjSuperAdmin);

    case user_roles.provider: {
      if (otherRoles.includes(OTHER_ROLES.crossBundling)) {
        return Object.values(productManagementObjManagedcareAdmin);
      }
      return Object.values(productManagementObj);
    }

    case user_roles.supervisor:
      return Object.values(productManagementObj);

    case user_roles.service_coordinator:
      return Object.values(productManagementObj);
    case user_roles.help_desk:
      return Object.values(productManagementObj);
    
    case user_roles.business_admin:
        return Object.values(productManagementObj);

    default:
      return [];
  }
};

export const partnerManagementTopNavBars = (user_role) => {
  switch (user_role) {
    case user_roles.super_admin:
      return Object.values(partnerManagementObj);

    case user_roles.provider:
      return Object.values(partnerManagementObjProvider);

    case user_roles.supervisor:
      return Object.values(partnerManagementObj);

    case user_roles.service_coordinator:
      return Object.values(partnerManagementObj);
    
    case user_roles.business_admin:
      return Object.values(partnerManagementObj);

    default:
      return [];
  }
};

export const resourceManagementTopNavBars = (user_role) => {
  switch (user_role) {
    case user_roles.super_admin:
      return Object.values(resourceManagementObj);

    case user_roles.provider:
      return Object.values(resourceManagementObj);

    case user_roles.supervisor:
      return Object.values(resourceManagementObjSupervisor);

    case user_roles.service_coordinator:
      return Object.values(resourceManagementObjCoordinator);
    
    case user_roles.business_admin:
        return Object.values(resourceManagementObj);

    default:
      return [];
  }
};

export const reportManagementTopNavBars = (user_role, otherRoles) => {
  switch (user_role) {
    case user_roles.super_admin:
      return Object.values(reportManagementObj);

    case user_roles.provider:
      // alert(JSON.stringify(Object.values(reportManagementObj)))
      let arr = []
      arr.push(reportManagementObj.transaction)
      arr.push(reportManagementObj.orderReport)
      if (otherRoles.includes(OTHER_ROLES.crossBundling)) {
        arr.push(reportManagementObj.enquiryReport)
      }
      return arr;

    case user_roles.supervisor:
      return Object.values(reportManagementObj);

    case user_roles.service_coordinator:
      return Object.values(reportManagementObj);
    
    case user_roles.business_admin:
        return Object.values(reportManagementObj);

    default:
      return [];
  }
};

const administrationManagementObj = {
  masters: {
    id: 1,
    link: routes.administration_masters,
    button_id: "TopNavMaster",
    label: "General Masters",
  },
  usermanagement: {
    id: 2,
    link: routes.administration_users,
    button_id: "TopNavUserManagement",
    label: "User Management",
  },
  taxmanagement: {
    id: 3,
    link: routes.administation_tax,
    button_id: "TopNavTaxManagement",
    label: "Maintenance",
  },
  configuration: {
    id: 4,
    link: routes.administation_configuration,
    button_id: "TopNavTaxManagement",
    label: "Configuration",
  },
};

export const administrationManagementTopNavBars = (user_role) => {
  switch (user_role) {
    case user_roles.super_admin:
      return Object.values(administrationManagementObj);

    case user_roles.provider:
      return Object.values(administrationManagementObj);

    case user_roles.supervisor:
      return Object.values(administrationManagementObj);

    case user_roles.service_coordinator:
      return Object.values(administrationManagementObj);

    default:
      return [];
  }
};
const availabilityManagementObj = {
  calendar: {
    id: 1,
    link: routes.availability_calendar_main + "day",
    button_id: "TopNavCalender",
    label: "Calendar",
  },
};

const availabilityManagementObjProvider = {
  calendar: {
    id: 1,
    link: routes.availability_calendar_main + "day",
    button_id: "TopNavCalender",
    label: "Calendar",
  },
  manage_availability: {
    id: 3,
    link: routes.manage_availability,
    button_id: "TopNavManageAvailability",
    label: "Manage Availability",
  },
};

export const availabilityManagementTopNavBars = (user_role) => {
  switch (user_role) {
    case user_roles.super_admin:
      return Object.values(availabilityManagementObj);

    case user_roles.provider:
      return Object.values(availabilityManagementObjProvider);

    case user_roles.supervisor:
      return [availabilityManagementObj.calendar];

    case user_roles.service_coordinator:
      return [availabilityManagementObj.calendar];

    case user_roles.help_desk:
      return Object.values(availabilityManagementObj);

    default:
      return [];
  }
};

export const clientDetailTabs = (id, type) => [
  {
    id: 1,
    link: routes.monitor_client_detail_parent + id,
    button_id: "TopNavClientDetails",
    label: "Details",
  },
  // {
  //   id: 2,
  //   link: routes.monitor_client_report_parent + id,
  //   label: "Reports",
  // },
  // {
  //   id: 3,
  //   link: routes.monitor_client_issue_parent + id,
  //   label: "Issues",
  // },
  // {
  //   id: 4,
  //   link: routes.monitor_client_recommendation_parent + id,
  //   label: "Recommendations",
  // },
  {
    id: 5,
    link: routes.monitor_client_activitie_parent + id + "/" + type,
    button_id: "TopNavClientActivities",
    label: "Activities",
  },
  // {
  //   id: 6,
  //   link: routes.monitor_client_rating_parent + id,
  //   label: "Ratings",
  // },
];
export const serviceProfessionalTabs = (id, variant) => [
  {
    id: 1,
    link: routes.monitor_service_professionals_summary_parent + id,
    button_id: "TopNavProfessionalSummary",
    label: "Summary",
  },
  {
    id: 2,
    link:
      routes.monitor_service_professionals_schedules_parent +
      id +
      "/" +
      variant,
    button_id: "TopNavProfessionalSchedules",
    label: "Schedules",
  },
  {
    id: 3,
    link: routes.monitor_service_professionals_tracking_parent + id,
    label: "Real-Time Location",
  },
  {
    id: 4,
    link: routes.monitor_service_professionals_clients_parent + id,
    button_id: "TopNavProfesionalClient",
    label: "Client",
  },
  // {
  //   id: 5,
  //   link: routes.monitor_service_professionals_feebacks_parent + id,
  //   label: "Feedbacks",
  // },
];

export const salesOperationTabs = [
  {
    id: 1,
    link: routes.enquiry_list,
    button_id: "TopNavEnquiry",
    label: "Enquiries",
  },
];

export const serviceSupervisorDetailsUrl = "/management/user/supervisor/view/";

export const careProviderDetailsUrl = "/management/user/care_provider/view/";

export const careGiverDetailsUrl = "/management/user/care_giver/view/";

export const careCoordinatorDetailsUrl =
  "/management/user/care_coordinator/view/";

export const clientDetailsUrl = "/management/user/clients/view/";

/**
 * The below function will help in decide the component type
 */

export const ComponentType = (type) => {
  const optionComponentsList = [
    "smart skip",
    "popup_switch",
    "list_text",
    "custom_multi_select_tag",
    "input_text",
    "input_textarea",
    "input_date_picker",
    "input_number",
    "hours_minutes_range",
    "label",
    "note",
    "location",
    "custom_table",
    "custom_table_2",
    "mobile_no",
    "date_of_birth",
    "file_upload",
    "Image_only_upload",
  ];
  if (optionComponentsList.includes(type)) {
    return false;
  } else {
    return true;
  }
};

export const FormComponents = {
  single_tab_select: "single_tab_select",
  multi_tab_select: "multi_tab_select",
  input_text: "input_text",
  input_textarea: "input_textarea",
  input_date_picker: "input_date_picker",
  multi_select_tag: "multi_select_tag",
  custom_multi_select_tag: "custom_multi_select_tag",
  select_modal_dropdown: "select_modal_dropdown",
  list_text: "list_text",
  input_number: "input_number",
  hours_minutes_range: "hours_minutes_range",
  grade: "grade",
  image_picker: "image_picker",
  switch_tab: "switch_tab",
  range_picker: "range_picker",
  popup_switch: "popup_switch",
  label: "label",
  note: "note",
  check_box: "check_box",
  radio_button: "radio_button",
  location: "location",
  custom_table: "custom_table",
  custom_table_2: "custom_table_2",
  mobile_no: "mobile_no",
  // 'location_level': 'location_level',
  date_of_birth: "date_of_birth",
  file_upload: "file_upload",
  Image_upload: "Image_upload",
  Image_only_upload: "Image_only_upload",
};

export const converToReactSelect = (data, valueKey, labelKey) => {
  if (!data || !data?.length) {
    return [];
  }

  return data.map((val) => {
    return {
      ...val,
      value: val?.[valueKey],
      label: val?.[labelKey],
    };
  });
};

export const formComponentTypes = {
  PROFILE_UPLOAD_BUTTON: "PROFILE_UPLOAD_BUTTON",
  INPUT_BOX: "INPUT_BOX",
  INPUT_BOX_WITH_ADORNMENT: "INPUT_BOX_WITH_ADORNMENT",
  INPUT_BOX_CHIP: "INPUT_BOX_CHIPS",
  MOBILE_INPUT_BOX: "MOBILE_INPUT_BOX",
  CHIP_BOX: "CHIP_BOX",
  SELECT_BOX: "SELECT_BOX",
  SELECT_BOX_WITH_PAGINATION: "SELECT_BOX_WITH_PAGINATION",
  BUTTON: "BUTTON",
  LIST: "LIST",
  DATE_RANGE_PICKER: "DATE_RANGE_PICKER",
  TABLE: "TABLE",
  BUTTON_WITH_INPUT_FIELD: "BUTTON_WITH_INPUT_FIELD",
  SUMMARY_CARD: "SUMMARY_CARD",
  SUBSCRIPTIONS_CARD: "SUBSCRIPTIONS_CARD",
  RECOMMENDATIONS_CARD: "RECOMMENDATIONS_CARD",
  ADD_CONTACT_DRAWER: "ADD_CONTACT_DRAWER",
  DETAILS_CARD: "DETAILS_CARD",
  RADIO_BUTTON: "RADIO_BUTTON",
  CHECK_BOX: "CHECK_BOX",
  DATE_TIME_PICKER: "DATE_TIME_PICKER",
  CUSTOMIZED_RADIO_BUTTON: "CUSTOMIZED_RADIO_BUTTON",
  CARD_WITH_TEXT_AND_CALLBACK: "CARD_WITH_TEXT_AND_CALLBACK",
  GOOGLE_MAP_AUTOCOMPLETE: "GOOGLE_MAP_AUTOCOMPLETE",
  TIME_RANGE_PICKER: "TIME_RANGE_PICKER",
  CUSTOMIZED_TOGGLE_GROUP: "CUSTOMIZED_TOGGLE_GROUP",
  BMI: "BMI",
  ICNUMBERPASSPORT: "ICNUMBERPASSPORT",
  SERVICECONFIGURATION: "SERVICECONFIGURATION",
  FROM_TO_DATE_TIME_PICKER: "FROM_TO_DATE_TIME_PICKER",
  PRODUCT_VALIDITY: "PRODUCT_VALIDITY",
  RESOURCE_VALIDITY: "RESOURCE_VALIDITY",
  ACTIVITY_RECURRENCE: "ACTIVITY_RECURRENCE",
  ACTIVITY_MODE: "ACTIVITY_MODE",
  SWITCH_COMPONENT: "SWITCH_COMPONENT",
  SELECT_COMPONENT_WITH_SUB_COMPONENT: "SELECT_COMPONENT_WITH_SUB_COMPONENT",
  ADD_CERTIFICATION: "ADD_CERTIFICATION",
  ADD_EXPERIENCE: "ADD_EXPERIENCE",
  ADD_ADDRESS: "ADD_ADDRESS",
  ADD_EMAIL: "ADD_EMAIL",
  ADD_PHONE_NUMBER: "ADD_PHONE_NUMBER",
  ADD_TAX_ID: "ADD_TAX_ID",
  DURATION: "DURATION",
  FILEUPLOADER: "FILEUPLOADER",
  FILEUPLOADER_VARIANT_2: "FILEUPLOADER_VARIANT_2",
  UPLOADER_WITH_PREVIEW: "UPLOADER_WITH_PREVIEW",
  SWITCHBOXDROPDOWN: "SWITCHBOXDROPDOWN",
  RICHTEXTEDITOR: "RICHTEXTEDITOR",
  ADD_ID_TYPE: "ADD_ID_TYPE",
  ADMINTABLE: "ADMINTABLE",
  DURATION_PICKER: "DURATION_PICKER",
  EFFECTIVE_EXPIRY: "EFFECTIVE_EXPIRY",
  FAMILY_MEMBERS: "FAMILY_MEMBERS",
};

export const metaType = {
  currency: "currency",
};

export const filterType = {
  CATEGORY: "CATEGORY",
  SKILLS: "SKILLS",
  SPECIALIZATION: "SPECIALIZATION",
  PRODUCTS: "PRODUCTS",
  PROVIDER: "PROVIDER",
  SERVICE_PROFESSIONAL: "SERVICE_PROFESSIONAL",
  SERVICE_CORDINATOR: "SERVICE_CORDINATOR",
  SORT: "SORT",
  STATUS: "STATUS",
  GENDER: "GENDER",
  DATE_SORT: "DATE_SORT",
  PRICE_SORT: "PRICE_SORT",
  SERVICES: "SERVICES",
  PACKAGES: "PACKAGES",
  SORTDIRECTION: "SORTDIRECTION",
  SORTFIELD: "SORTFIELD",
  ENQUIRY_TYPE: "ENQUIRY_TYPE",
  ASSIGN_TO: "ASSIGN_TO",
  ROLE: "ROLE",
  CREATED_BY: "CREATED_BY",
};

export const filterTagType = {
  Category: "category",
  Products: "products",
  Provider: "provider",
  Skills: "skills",
  Specialization: "specialization",
  Professional: "professional",
  Coordinator: "coordinator",
  Status: "status",
  Gender: "gender",
  Services: "services",
  Packages: "packages",
  SortDirection: "sortDirec",
  SortField: "sortfield",
  Assigned_to: "assigned_to",
  Enquiry_type: "enquiry_type",
  assigned_to: "assigned to",
  enquiry_type: "lead type",
  role: "role",
};

export const cardedTextView = {
  only_card: 1,
  card_with_subtext: 2,
  icon_list_text: 3,
  list_divider: 4,
  bullet_list: 5,
  card_text_list: 6,
};

export const options = {
  subscription_status: [
    { label: "All", value: "1" },
    { label: "Active", value: "2" },
    { label: "InActive", value: "3" },
  ],
  subscription_expiring_in: [
    { label: "No range", value: "1" },
    { label: "< 3 days", value: "2" },
    { label: "< 7 days", value: "3" },
    { label: "< 30 days", value: "4" },
  ],
  seen_a_doctor: [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ],
  feeding: [
    { label: "Independent", value: "independent" },
    { label: "Minor Assistance", value: "minor_assistance" },
    { label: "Spoon Feeding", value: "spoonfeeding" },
    { label: "NGT / PEG", value: "NGT_PEG" },
  ],
  bathing: [
    { label: "Independent", value: "independent" },
    { label: "Minor Assistance", value: "minor_assistance" },
    { label: "Major Assistance", value: "major_assistance" },
    { label: "Dependent", value: "Dependent" },
  ],
  toileting: [
    { label: "Independent", value: "independent" },
    { label: "Minor Assistance", value: "minor_assistance" },
    { label: "Major Assistance", value: "major_assistance" },
    { label: "Dependent", value: "Dependent" },
  ],
  grooming: [
    { label: "Independent", value: "independent" },
    { label: "Minor Assistance", value: "minor_assistance" },
    { label: "Major Assistance", value: "major_assistance" },
    { label: "Dependent", value: "Dependent" },
  ],
  ambulation: [
    { label: "Independent", value: "independent" },
    { label: "Walking Aid", value: "walking_aid" },
    { label: "Wheelchair", value: "wheelchair" },
    { label: "None", value: "none" },
  ],
  transfer: [
    { label: "Independent", value: "independent" },
    { label: "Minor Assistance", value: "minor_assistance" },
    { label: "Major Assistance", value: "major_assistance" },
    { label: "Dependent", value: "Dependent" },
  ],
  bed_mobility: [
    { label: "Independent", value: "independent" },
    { label: "Minor Assistance", value: "minor_assistance" },
    { label: "Major Assistance", value: "major_assistance" },
    { label: "Dependent", value: "Dependent" },
  ],
  other_activities: [
    { label: "NG Tube/ PEG tube", value: "ng_peg_tube" },
    { label: "Oxygen/Suctioning", value: "oxygen_suctioning" },
    {
      label: "Confusion / Memory Problems",
      value: "confusion_memory_problems",
    },
    { label: "Tracheostomy", value: "tracheostomy" },
    { label: "Urinary Catheter", value: "urinary_catheter" },
    { label: "Wound/Pressure ulcers", value: "wound_pressure_ulcers" },
    { label: "Pain", value: "pain" },
    { label: "Stoma", value: "stoma" },
    { label: "Injections", value: "injections" },
    { label: "Other Issues / requests", value: "other" },
  ],
  recommendations_service: [
    { label: "Care Planner Package", value: "care_planner_package" },
    { label: "Care Coordination", value: "care_coordination" },
    { label: "Direct Referral", value: "direct_referral" },
    { label: "None", value: "None" },
  ],
  regen_rehab_criteria: [
    { label: "Independent", value: "independent" },
    { label: "Minor Assistance", value: "minor_assistance" },
    { label: "Spoon Feeding", value: "spoonfeeding" },
    { label: "NGT / PEG", value: "NGT_PEG" },
  ],
  mark_as: [
    { label: "Available", value: "available" },
    { label: "Unavailable", value: "unavailable" },
  ],
  mark_for: [
    { label: "Service Professional", value: "service_professional" },
    { label: "Other Resources", value: "other_resources" },
  ],
  occurence: { repeat: "REPEAT", noRepeat: "NO_REPEAT" },
  repeat: [
    { label: "Does Not Repeat", value: "DO NOT REPEAT" },
    { label: "Daily", value: "DAILY" },
    { label: "Weekly", value: "WEEKLY" },
  ],
  repeat_demo: [{ label: "Does Not Repeat", value: "DO NOT REPEAT" }],
  activity_mode: [
    { label: "Video Call", value: "VIDEO_CALL" },
    { label: "In - Person", value: "IN_PERSON" },
  ],
  recurrence: [
    {
      label: "Does Not Repeat",
      value: "doesnotrepeat",
    },
    {
      label: "Daily",
      value: "daily",
    },
    {
      label: "Weekly",
      value: "weekly",
    },
  ],
  exception: [
    {
      label: "Category",
      value: "category",
    },
    {
      label: "Persona",
      value: "persona",
    },
    {
      label: "Individuals",
      value: "individuals",
    },
  ],
  daysOfWeek: [
    { label: "Mon", value: "monday" },
    { label: "Tue", value: "tuesday" },
    { label: "Wed", value: "wednesday" },
    { label: "Thu", value: "thursday" },
    { label: "Fri", value: "friday" },
    { label: "Sat", value: "saturday" },
    { label: "Sun", value: "sunday" },
  ],
  availableWeekDays: {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  },
  relationship: [
    {
      value: "Friend",
      label: "Friend",
    },
    {
      value: "Father",
      label: "Father",
    },
    {
      value: "Mother",
      label: "Mother",
    },
    {
      value: "Son",
      label: "Son",
    },
    {
      value: "Daughter",
      label: "Daughter",
    },
    {
      value: "Husband",
      label: "Husband",
    },
    {
      value: "Wife",
      label: "Wife",
    },
    {
      value: "Brother",
      label: "Brother",
    },
    {
      value: "Sister",
      label: "Sister",
    },
    {
      value: "Grandfather",
      label: "Grandfather",
    },
    {
      value: "Grandmother",
      label: "Grandmother",
    },
    {
      value: "Grandson",
      label: "Grandson",
    },
    {
      value: "Granddaughter",
      label: "Granddaughter",
    },
    {
      value: "Uncle",
      label: "Uncle",
    },
    {
      value: "Aunt",
      label: "Aunt",
    },
    {
      value: "Nephew",
      label: "Nephew",
    },
    {
      value: "Niece",
      label: "Niece",
    },
    {
      value: "Cousin",
      label: "Cousin",
    },
    {
      value: "Others",
      label: "Others",
    },
  ],
  languages: [
    { language: "Afar", code: "aa" },
    { language: "Abkhazian", code: "ab" },
    { language: "Afrikaans", code: "af" },
    { language: "Akan", code: "ak" },
    { language: "Amharic", code: "am" },
    { language: "Aragonese", code: "an" },
    { language: "Arabic", code: "ar" },
    { language: "Assamese", code: "as" },
    { language: "Avar", code: "av" },
    { language: "Aymara", code: "ay" },
    { language: "Azerbaijani", code: "az" },
    { language: "Bashkir", code: "ba" },
    { language: "Belarusian", code: "be" },
    { language: "Bulgarian", code: "bg" },
    { language: "Bihari", code: "bh" },
    { language: "Bislama", code: "bi" },
    { language: "Bambara", code: "bm" },
    { language: "Bengali", code: "bn" },
    { language: "Tibetan", code: "bo" },
    { language: "Breton", code: "br" },
    { language: "Bosnian", code: "bs" },
    { language: "Catalan", code: "ca" },
    { language: "Chechen", code: "ce" },
    { language: "Chamorro", code: "ch" },
    { language: "Corsican", code: "co" },
    { language: "Cree", code: "cr" },
    { language: "Czech", code: "cs" },
    { language: "Old Church Slavonic / Old Bulgarian", code: "cu" },
    { language: "Chuvash", code: "cv" },
    { language: "Welsh", code: "cy" },
    { language: "Danish", code: "da" },
    { language: "German", code: "de" },
    { language: "Divehi", code: "dv" },
    { language: "Dzongkha", code: "dz" },
    { language: "Ewe", code: "ee" },
    { language: "Greek", code: "el" },
    { language: "English", code: "en" },
    { language: "Esperanto", code: "eo" },
    { language: "Spanish", code: "es" },
    { language: "Estonian", code: "et" },
    { language: "Basque", code: "eu" },
    { language: "Persian", code: "fa" },
    { language: "Peul", code: "ff" },
    { language: "Finnish", code: "fi" },
    { language: "Fijian", code: "fj" },
    { language: "Faroese", code: "fo" },
    { language: "French", code: "fr" },
    { language: "West Frisian", code: "fy" },
    { language: "Irish", code: "ga" },
    { language: "Scottish Gaelic", code: "gd" },
    { language: "Galician", code: "gl" },
    { language: "Guarani", code: "gn" },
    { language: "Gujarati", code: "gu" },
    { language: "Manx", code: "gv" },
    { language: "Hausa", code: "ha" },
    { language: "Hebrew", code: "he" },
    { language: "Hindi", code: "hi" },
    { language: "Hiri Motu", code: "ho" },
    { language: "Croatian", code: "hr" },
    { language: "Haitian", code: "ht" },
    { language: "Hungarian", code: "hu" },
    { language: "Armenian", code: "hy" },
    { language: "Herero", code: "hz" },
    { language: "Interlingua", code: "ia" },
    { language: "Indonesian", code: "id" },
    { language: "Interlingue", code: "ie" },
    { language: "Igbo", code: "ig" },
    { language: "Sichuan Yi", code: "ii" },
    { language: "Inupiak", code: "ik" },
    { language: "Ido", code: "io" },
    { language: "Icelandic", code: "is" },
    { language: "Italian", code: "it" },
    { language: "Inuktitut", code: "iu" },
    { language: "Japanese", code: "ja" },
    { language: "Javanese", code: "jv" },
    { language: "Georgian", code: "ka" },
    { language: "Kongo", code: "kg" },
    { language: "Kikuyu", code: "ki" },
    { language: "Kuanyama", code: "kj" },
    { language: "Kazakh", code: "kk" },
    { language: "Greenlandic", code: "kl" },
    { language: "Cambodian", code: "km" },
    { language: "Kannada", code: "kn" },
    { language: "Korean", code: "ko" },
    { language: "Kanuri", code: "kr" },
    { language: "Kashmiri", code: "ks" },
    { language: "Kurdish", code: "ku" },
    { language: "Komi", code: "kv" },
    { language: "Cornish", code: "kw" },
    { language: "Kirghiz", code: "ky" },
    { language: "Latin", code: "la" },
    { language: "Luxembourgish", code: "lb" },
    { language: "Ganda", code: "lg" },
    { language: "Limburgian", code: "li" },
    { language: "Lingala", code: "ln" },
    { language: "Laotian", code: "lo" },
    { language: "Lithuanian", code: "lt" },
    { language: "Latvian", code: "lv" },
    { language: "Malagasy", code: "mg" },
    { language: "Marshallese", code: "mh" },
    { language: "Maori", code: "mi" },
    { language: "Macedonian", code: "mk" },
    { language: "Malayalam", code: "ml" },
    { language: "Mongolian", code: "mn" },
    { language: "Moldovan", code: "mo" },
    { language: "Marathi", code: "mr" },
    { language: "Malay", code: "ms" },
    { language: "Maltese", code: "mt" },
    { language: "Burmese", code: "my" },
    { language: "Nauruan", code: "na" },
    { language: "North Ndebele", code: "nd" },
    { language: "Nepali", code: "ne" },
    { language: "Ndonga", code: "ng" },
    { language: "Dutch", code: "nl" },
    { language: "Norwegian Nynorsk", code: "nn" },
    { language: "Norwegian", code: "no" },
    { language: "South Ndebele", code: "nr" },
    { language: "Navajo", code: "nv" },
    { language: "Chichewa", code: "ny" },
    { language: "Occitan", code: "oc" },
    { language: "Ojibwa", code: "oj" },
    { language: "Oromo", code: "om" },
    { language: "Oriya", code: "or" },
    { language: "Ossetian", code: "os" },
    { language: "Punjabi", code: "pa" },
    { language: "Pali", code: "pi" },
    { language: "Polish", code: "pl" },
    { language: "Pashto", code: "ps" },
    { language: "Portuguese", code: "pt" },
    { language: "Quechua", code: "qu" },
    { language: "Raeto Romance", code: "rm" },
    { language: "Kirundi", code: "rn" },
    { language: "Romanian", code: "ro" },
    { language: "Russian", code: "ru" },
    { language: "Rwandi", code: "rw" },
    { language: "Sanskrit", code: "sa" },
    { language: "Sardinian", code: "sc" },
    { language: "Sindhi", code: "sd" },
    { language: "Sango", code: "sg" },
    { language: "Serbo-Croatian", code: "sh" },
    { language: "Sinhalese", code: "si" },
    { language: "Slovak", code: "sk" },
    { language: "Slovenian", code: "sl" },
    { language: "Samoan", code: "sm" },
    { language: "Shona", code: "sn" },
    { language: "Somalia", code: "so" },
    { language: "Albanian", code: "sq" },
    { language: "Serbian", code: "sr" },
    { language: "Swati", code: "ss" },
    { language: "Southern Sotho", code: "st" },
    { language: "Sundanese", code: "su" },
    { language: "Swedish", code: "sv" },
    { language: "Swahili", code: "sw" },
    { language: "Tamil", code: "ta" },
    { language: "Telugu", code: "te" },
    { language: "Tajik", code: "tg" },
    { language: "Thai", code: "th" },
    { language: "Tigrinya", code: "ti" },
    { language: "Turkmen", code: "tk" },
    { language: "Tagalog", code: "tl" },
    { language: "Tswana", code: "tn" },
    { language: "Tonga", code: "to" },
    { language: "Turkish", code: "tr" },
    { language: "Tsonga", code: "ts" },
    { language: "Tatar", code: "tt" },
    { language: "Twi", code: "tw" },
    { language: "Tahitian", code: "ty" },
    { language: "Uyghur", code: "ug" },
    { language: "Ukrainian", code: "uk" },
    { language: "Urdu", code: "ur" },
    { language: "Venda", code: "ve" },
    { language: "Vietnamese", code: "vi" },
    { language: "Volapük", code: "vo" },
    { language: "Walloon", code: "wa" },
    { language: "Wolof", code: "wo" },
    { language: "Xhosa", code: "xh" },
    { language: "Yiddish", code: "yi" },
    { language: "Yoruba", code: "yo" },
    { language: "Zhuang", code: "za" },
    { language: "Chinese", code: "zh" },
    { language: "Zulu", code: "zu" },
  ],
  gender: [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ],
  currency: [
    {
      label: "USD",
      value: "USD",
      symbol: "$",
      name: "US Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "USD",
      name_plural: "US dollars",
    },
    {
      label: "CAD",
      value: "CAD",
      symbol: "CA$",
      name: "Canadian Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "CAD",
      name_plural: "Canadian dollars",
    },
    {
      label: "EUR",
      value: "EUR",
      symbol: "€",
      name: "Euro",
      symbol_native: "€",
      decimal_digits: 2,
      rounding: 0,
      code: "EUR",
      name_plural: "euros",
    },
    {
      label: "AED",
      value: "AED",
      symbol: "AED",
      name: "United Arab Emirates Dirham",
      symbol_native: "د.إ.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "AED",
      name_plural: "UAE dirhams",
    },
    {
      label: "AFN",
      value: "AFN",
      symbol: "Af",
      name: "Afghan Afghani",
      symbol_native: "؋",
      decimal_digits: 0,
      rounding: 0,
      code: "AFN",
      name_plural: "Afghan Afghanis",
    },
    {
      label: "ALL",
      value: "ALL",
      symbol: "ALL",
      name: "Albanian Lek",
      symbol_native: "Lek",
      decimal_digits: 0,
      rounding: 0,
      code: "ALL",
      name_plural: "Albanian lekë",
    },
    {
      label: "AMD",
      value: "AMD",
      symbol: "AMD",
      name: "Armenian Dram",
      symbol_native: "դր.",
      decimal_digits: 0,
      rounding: 0,
      code: "AMD",
      name_plural: "Armenian drams",
    },
    {
      label: "ARS",
      value: "ARS",
      symbol: "AR$",
      name: "Argentine Peso",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "ARS",
      name_plural: "Argentine pesos",
    },
    {
      label: "AUD",
      value: "AUD",
      symbol: "AU$",
      name: "Australian Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "AUD",
      name_plural: "Australian dollars",
    },
    {
      label: "AZN",
      value: "AZN",
      symbol: "man.",
      name: "Azerbaijani Manat",
      symbol_native: "ман.",
      decimal_digits: 2,
      rounding: 0,
      code: "AZN",
      name_plural: "Azerbaijani manats",
    },
    {
      label: "BAM",
      value: "BAM",
      symbol: "KM",
      name: "Bosnia-Herzegovina Convertible Mark",
      symbol_native: "KM",
      decimal_digits: 2,
      rounding: 0,
      code: "BAM",
      name_plural: "Bosnia-Herzegovina convertible marks",
    },
    {
      label: "BDT",
      value: "BDT",
      symbol: "Tk",
      name: "Bangladeshi Taka",
      symbol_native: "৳",
      decimal_digits: 2,
      rounding: 0,
      code: "BDT",
      name_plural: "Bangladeshi takas",
    },
    {
      label: "BGN",
      value: "BGN",
      symbol: "BGN",
      name: "Bulgarian Lev",
      symbol_native: "лв.",
      decimal_digits: 2,
      rounding: 0,
      code: "BGN",
      name_plural: "Bulgarian leva",
    },
    {
      label: "BHD",
      value: "BHD",
      symbol: "BD",
      name: "Bahraini Dinar",
      symbol_native: "د.ب.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "BHD",
      name_plural: "Bahraini dinars",
    },
    {
      label: "BIF",
      value: "BIF",
      symbol: "FBu",
      name: "Burundian Franc",
      symbol_native: "FBu",
      decimal_digits: 0,
      rounding: 0,
      code: "BIF",
      name_plural: "Burundian francs",
    },
    {
      label: "BND",
      value: "BND",
      symbol: "BN$",
      name: "Brunei Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "BND",
      name_plural: "Brunei dollars",
    },
    {
      label: "BOB",
      value: "BOB",
      symbol: "Bs",
      name: "Bolivian Boliviano",
      symbol_native: "Bs",
      decimal_digits: 2,
      rounding: 0,
      code: "BOB",
      name_plural: "Bolivian bolivianos",
    },
    {
      label: "BRL",
      value: "BRL",
      symbol: "R$",
      name: "Brazilian Real",
      symbol_native: "R$",
      decimal_digits: 2,
      rounding: 0,
      code: "BRL",
      name_plural: "Brazilian reals",
    },
    {
      label: "BWP",
      value: "BWP",
      symbol: "BWP",
      name: "Botswanan Pula",
      symbol_native: "P",
      decimal_digits: 2,
      rounding: 0,
      code: "BWP",
      name_plural: "Botswanan pulas",
    },
    {
      label: "BYN",
      value: "BYN",
      symbol: "Br",
      name: "Belarusian Ruble",
      symbol_native: "руб.",
      decimal_digits: 2,
      rounding: 0,
      code: "BYN",
      name_plural: "Belarusian rubles",
    },
    {
      label: "BZD",
      value: "BZD",
      symbol: "BZ$",
      name: "Belize Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "BZD",
      name_plural: "Belize dollars",
    },
    {
      label: "CDF",
      value: "CDF",
      symbol: "CDF",
      name: "Congolese Franc",
      symbol_native: "FrCD",
      decimal_digits: 2,
      rounding: 0,
      code: "CDF",
      name_plural: "Congolese francs",
    },
    {
      label: "CHF",
      value: "CHF",
      symbol: "CHF",
      name: "Swiss Franc",
      symbol_native: "CHF",
      decimal_digits: 2,
      rounding: 0.05,
      code: "CHF",
      name_plural: "Swiss francs",
    },
    {
      label: "CLP",
      value: "CLP",
      symbol: "CL$",
      name: "Chilean Peso",
      symbol_native: "$",
      decimal_digits: 0,
      rounding: 0,
      code: "CLP",
      name_plural: "Chilean pesos",
    },
    {
      label: "CNY",
      value: "CNY",
      symbol: "CN¥",
      name: "Chinese Yuan",
      symbol_native: "CN¥",
      decimal_digits: 2,
      rounding: 0,
      code: "CNY",
      name_plural: "Chinese yuan",
    },
    {
      label: "COP",
      value: "COP",
      symbol: "CO$",
      name: "Colombian Peso",
      symbol_native: "$",
      decimal_digits: 0,
      rounding: 0,
      code: "COP",
      name_plural: "Colombian pesos",
    },
    {
      label: "CRC",
      value: "CRC",
      symbol: "₡",
      name: "Costa Rican Colón",
      symbol_native: "₡",
      decimal_digits: 0,
      rounding: 0,
      code: "CRC",
      name_plural: "Costa Rican colóns",
    },
    {
      label: "CVE",
      value: "CVE",
      symbol: "CV$",
      name: "Cape Verdean Escudo",
      symbol_native: "CV$",
      decimal_digits: 2,
      rounding: 0,
      code: "CVE",
      name_plural: "Cape Verdean escudos",
    },
    {
      label: "CZK",
      value: "CZK",
      symbol: "Kč",
      name: "Czech Republic Koruna",
      symbol_native: "Kč",
      decimal_digits: 2,
      rounding: 0,
      code: "CZK",
      name_plural: "Czech Republic korunas",
    },
    {
      label: "DJF",
      value: "DJF",
      symbol: "Fdj",
      name: "Djiboutian Franc",
      symbol_native: "Fdj",
      decimal_digits: 0,
      rounding: 0,
      code: "DJF",
      name_plural: "Djiboutian francs",
    },
    {
      label: "DKK",
      value: "DKK",
      symbol: "Dkr",
      name: "Danish Krone",
      symbol_native: "kr",
      decimal_digits: 2,
      rounding: 0,
      code: "DKK",
      name_plural: "Danish kroner",
    },
    {
      label: "DOP",
      value: "DOP",
      symbol: "RD$",
      name: "Dominican Peso",
      symbol_native: "RD$",
      decimal_digits: 2,
      rounding: 0,
      code: "DOP",
      name_plural: "Dominican pesos",
    },
    {
      label: "DZD",
      value: "DZD",
      symbol: "DA",
      name: "Algerian Dinar",
      symbol_native: "د.ج.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "DZD",
      name_plural: "Algerian dinars",
    },
    {
      label: "EEK",
      value: "EEK",
      symbol: "Ekr",
      name: "Estonian Kroon",
      symbol_native: "kr",
      decimal_digits: 2,
      rounding: 0,
      code: "EEK",
      name_plural: "Estonian kroons",
    },
    {
      label: "EGP",
      value: "EGP",
      symbol: "EGP",
      name: "Egyptian Pound",
      symbol_native: "ج.م.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "EGP",
      name_plural: "Egyptian pounds",
    },
    {
      label: "ERN",
      value: "ERN",
      symbol: "Nfk",
      name: "Eritrean Nakfa",
      symbol_native: "Nfk",
      decimal_digits: 2,
      rounding: 0,
      code: "ERN",
      name_plural: "Eritrean nakfas",
    },
    {
      label: "ETB",
      value: "ETB",
      symbol: "Br",
      name: "Ethiopian Birr",
      symbol_native: "Br",
      decimal_digits: 2,
      rounding: 0,
      code: "ETB",
      name_plural: "Ethiopian birrs",
    },
    {
      label: "GBP",
      value: "GBP",
      symbol: "£",
      name: "British Pound Sterling",
      symbol_native: "£",
      decimal_digits: 2,
      rounding: 0,
      code: "GBP",
      name_plural: "British pounds sterling",
    },
    {
      label: "GEL",
      value: "GEL",
      symbol: "GEL",
      name: "Georgian Lari",
      symbol_native: "GEL",
      decimal_digits: 2,
      rounding: 0,
      code: "GEL",
      name_plural: "Georgian laris",
    },
    {
      label: "GHS",
      value: "GHS",
      symbol: "GH₵",
      name: "Ghanaian Cedi",
      symbol_native: "GH₵",
      decimal_digits: 2,
      rounding: 0,
      code: "GHS",
      name_plural: "Ghanaian cedis",
    },
    {
      label: "GNF",
      value: "GNF",
      symbol: "FG",
      name: "Guinean Franc",
      symbol_native: "FG",
      decimal_digits: 0,
      rounding: 0,
      code: "GNF",
      name_plural: "Guinean francs",
    },
    {
      label: "GTQ",
      value: "GTQ",
      symbol: "GTQ",
      name: "Guatemalan Quetzal",
      symbol_native: "Q",
      decimal_digits: 2,
      rounding: 0,
      code: "GTQ",
      name_plural: "Guatemalan quetzals",
    },
    {
      label: "HKD",
      value: "HKD",
      symbol: "HK$",
      name: "Hong Kong Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "HKD",
      name_plural: "Hong Kong dollars",
    },
    {
      label: "HNL",
      value: "HNL",
      symbol: "HNL",
      name: "Honduran Lempira",
      symbol_native: "L",
      decimal_digits: 2,
      rounding: 0,
      code: "HNL",
      name_plural: "Honduran lempiras",
    },
    {
      label: "HRK",
      value: "HRK",
      symbol: "kn",
      name: "Croatian Kuna",
      symbol_native: "kn",
      decimal_digits: 2,
      rounding: 0,
      code: "HRK",
      name_plural: "Croatian kunas",
    },
    {
      label: "HUF",
      value: "HUF",
      symbol: "Ft",
      name: "Hungarian Forint",
      symbol_native: "Ft",
      decimal_digits: 0,
      rounding: 0,
      code: "HUF",
      name_plural: "Hungarian forints",
    },
    {
      label: "IDR",
      value: "IDR",
      symbol: "Rp",
      name: "Indonesian Rupiah",
      symbol_native: "Rp",
      decimal_digits: 0,
      rounding: 0,
      code: "IDR",
      name_plural: "Indonesian rupiahs",
    },
    {
      label: "ILS",
      value: "ILS",
      symbol: "₪",
      name: "Israeli New Sheqel",
      symbol_native: "₪",
      decimal_digits: 2,
      rounding: 0,
      code: "ILS",
      name_plural: "Israeli new sheqels",
    },
    {
      label: "INR",
      value: "INR",
      symbol: "Rs",
      name: "Indian Rupee",
      symbol_native: "টকা",
      decimal_digits: 2,
      rounding: 0,
      code: "INR",
      name_plural: "Indian rupees",
    },
    {
      label: "IQD",
      value: "IQD",
      symbol: "IQD",
      name: "Iraqi Dinar",
      symbol_native: "د.ع.‏",
      decimal_digits: 0,
      rounding: 0,
      code: "IQD",
      name_plural: "Iraqi dinars",
    },
    {
      label: "IRR",
      value: "IRR",
      symbol: "IRR",
      name: "Iranian Rial",
      symbol_native: "﷼",
      decimal_digits: 0,
      rounding: 0,
      code: "IRR",
      name_plural: "Iranian rials",
    },
    {
      label: "ISK",
      value: "ISK",
      symbol: "Ikr",
      name: "Icelandic Króna",
      symbol_native: "kr",
      decimal_digits: 0,
      rounding: 0,
      code: "ISK",
      name_plural: "Icelandic krónur",
    },
    {
      label: "JMD",
      value: "JMD",
      symbol: "J$",
      name: "Jamaican Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "JMD",
      name_plural: "Jamaican dollars",
    },
    {
      label: "JOD",
      value: "JOD",
      symbol: "JD",
      name: "Jordanian Dinar",
      symbol_native: "د.أ.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "JOD",
      name_plural: "Jordanian dinars",
    },
    {
      label: "JPY",
      value: "JPY",
      symbol: "¥",
      name: "Japanese Yen",
      symbol_native: "￥",
      decimal_digits: 0,
      rounding: 0,
      code: "JPY",
      name_plural: "Japanese yen",
    },
    {
      label: "KES",
      value: "KES",
      symbol: "Ksh",
      name: "Kenyan Shilling",
      symbol_native: "Ksh",
      decimal_digits: 2,
      rounding: 0,
      code: "KES",
      name_plural: "Kenyan shillings",
    },
    {
      label: "KHR",
      value: "KHR",
      symbol: "KHR",
      name: "Cambodian Riel",
      symbol_native: "៛",
      decimal_digits: 2,
      rounding: 0,
      code: "KHR",
      name_plural: "Cambodian riels",
    },
    {
      label: "KMF",
      value: "KMF",
      symbol: "CF",
      name: "Comorian Franc",
      symbol_native: "FC",
      decimal_digits: 0,
      rounding: 0,
      code: "KMF",
      name_plural: "Comorian francs",
    },
    {
      label: "KRW",
      value: "KRW",
      symbol: "₩",
      name: "South Korean Won",
      symbol_native: "₩",
      decimal_digits: 0,
      rounding: 0,
      code: "KRW",
      name_plural: "South Korean won",
    },
    {
      label: "KWD",
      value: "KWD",
      symbol: "KD",
      name: "Kuwaiti Dinar",
      symbol_native: "د.ك.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "KWD",
      name_plural: "Kuwaiti dinars",
    },
    {
      label: "KZT",
      value: "KZT",
      symbol: "KZT",
      name: "Kazakhstani Tenge",
      symbol_native: "тңг.",
      decimal_digits: 2,
      rounding: 0,
      code: "KZT",
      name_plural: "Kazakhstani tenges",
    },
    {
      label: "LBP",
      value: "LBP",
      symbol: "LB£",
      name: "Lebanese Pound",
      symbol_native: "ل.ل.‏",
      decimal_digits: 0,
      rounding: 0,
      code: "LBP",
      name_plural: "Lebanese pounds",
    },
    {
      label: "LKR",
      value: "LKR",
      symbol: "SLRs",
      name: "Sri Lankan Rupee",
      symbol_native: "SL Re",
      decimal_digits: 2,
      rounding: 0,
      code: "LKR",
      name_plural: "Sri Lankan rupees",
    },
    {
      label: "LTL",
      value: "LTL",
      symbol: "Lt",
      name: "Lithuanian Litas",
      symbol_native: "Lt",
      decimal_digits: 2,
      rounding: 0,
      code: "LTL",
      name_plural: "Lithuanian litai",
    },
    {
      label: "LVL",
      value: "LVL",
      symbol: "Ls",
      name: "Latvian Lats",
      symbol_native: "Ls",
      decimal_digits: 2,
      rounding: 0,
      code: "LVL",
      name_plural: "Latvian lati",
    },
    {
      label: "LYD",
      value: "LYD",
      symbol: "LD",
      name: "Libyan Dinar",
      symbol_native: "د.ل.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "LYD",
      name_plural: "Libyan dinars",
    },
    {
      label: "MAD",
      value: "MAD",
      symbol: "MAD",
      name: "Moroccan Dirham",
      symbol_native: "د.م.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "MAD",
      name_plural: "Moroccan dirhams",
    },
    {
      label: "MDL",
      value: "MDL",
      symbol: "MDL",
      name: "Moldovan Leu",
      symbol_native: "MDL",
      decimal_digits: 2,
      rounding: 0,
      code: "MDL",
      name_plural: "Moldovan lei",
    },
    {
      label: "MGA",
      value: "MGA",
      symbol: "MGA",
      name: "Malagasy Ariary",
      symbol_native: "MGA",
      decimal_digits: 0,
      rounding: 0,
      code: "MGA",
      name_plural: "Malagasy Ariaries",
    },
    {
      label: "MKD",
      value: "MKD",
      symbol: "MKD",
      name: "Macedonian Denar",
      symbol_native: "MKD",
      decimal_digits: 2,
      rounding: 0,
      code: "MKD",
      name_plural: "Macedonian denari",
    },
    {
      label: "MMK",
      value: "MMK",
      symbol: "MMK",
      name: "Myanma Kyat",
      symbol_native: "K",
      decimal_digits: 0,
      rounding: 0,
      code: "MMK",
      name_plural: "Myanma kyats",
    },
    {
      label: "MOP",
      value: "MOP",
      symbol: "MOP$",
      name: "Macanese Pataca",
      symbol_native: "MOP$",
      decimal_digits: 2,
      rounding: 0,
      code: "MOP",
      name_plural: "Macanese patacas",
    },
    {
      label: "MUR",
      value: "MUR",
      symbol: "MURs",
      name: "Mauritian Rupee",
      symbol_native: "MURs",
      decimal_digits: 0,
      rounding: 0,
      code: "MUR",
      name_plural: "Mauritian rupees",
    },
    {
      label: "MXN",
      value: "MXN",
      symbol: "MX$",
      name: "Mexican Peso",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "MXN",
      name_plural: "Mexican pesos",
    },
    {
      label: "MYR",
      value: "MYR",
      symbol: "RM",
      name: "Malaysian Ringgit",
      symbol_native: "RM",
      decimal_digits: 2,
      rounding: 0,
      code: "MYR",
      name_plural: "Malaysian ringgits",
    },
    {
      label: "MZN",
      value: "MZN",
      symbol: "MTn",
      name: "Mozambican Metical",
      symbol_native: "MTn",
      decimal_digits: 2,
      rounding: 0,
      code: "MZN",
      name_plural: "Mozambican meticals",
    },
    {
      label: "NAD",
      value: "NAD",
      symbol: "N$",
      name: "Namibian Dollar",
      symbol_native: "N$",
      decimal_digits: 2,
      rounding: 0,
      code: "NAD",
      name_plural: "Namibian dollars",
    },
    {
      label: "NGN",
      value: "NGN",
      symbol: "₦",
      name: "Nigerian Naira",
      symbol_native: "₦",
      decimal_digits: 2,
      rounding: 0,
      code: "NGN",
      name_plural: "Nigerian nairas",
    },
    {
      label: "NIO",
      value: "NIO",
      symbol: "C$",
      name: "Nicaraguan Córdoba",
      symbol_native: "C$",
      decimal_digits: 2,
      rounding: 0,
      code: "NIO",
      name_plural: "Nicaraguan córdobas",
    },
    {
      label: "NOK",
      value: "NOK",
      symbol: "Nkr",
      name: "Norwegian Krone",
      symbol_native: "kr",
      decimal_digits: 2,
      rounding: 0,
      code: "NOK",
      name_plural: "Norwegian kroner",
    },
    {
      label: "NPR",
      value: "NPR",
      symbol: "NPRs",
      name: "Nepalese Rupee",
      symbol_native: "नेरू",
      decimal_digits: 2,
      rounding: 0,
      code: "NPR",
      name_plural: "Nepalese rupees",
    },
    {
      label: "NZD",
      value: "NZD",
      symbol: "NZ$",
      name: "New Zealand Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "NZD",
      name_plural: "New Zealand dollars",
    },
    {
      label: "OMR",
      value: "OMR",
      symbol: "OMR",
      name: "Omani Rial",
      symbol_native: "ر.ع.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "OMR",
      name_plural: "Omani rials",
    },
    {
      label: "PAB",
      value: "PAB",
      symbol: "B/.",
      name: "Panamanian Balboa",
      symbol_native: "B/.",
      decimal_digits: 2,
      rounding: 0,
      code: "PAB",
      name_plural: "Panamanian balboas",
    },
    {
      label: "PEN",
      value: "PEN",
      symbol: "S/.",
      name: "Peruvian Nuevo Sol",
      symbol_native: "S/.",
      decimal_digits: 2,
      rounding: 0,
      code: "PEN",
      name_plural: "Peruvian nuevos soles",
    },
    {
      label: "PHP",
      value: "PHP",
      symbol: "₱",
      name: "Philippine Peso",
      symbol_native: "₱",
      decimal_digits: 2,
      rounding: 0,
      code: "PHP",
      name_plural: "Philippine pesos",
    },
    {
      label: "PKR",
      value: "PKR",
      symbol: "PKRs",
      name: "Pakistani Rupee",
      symbol_native: "₨",
      decimal_digits: 0,
      rounding: 0,
      code: "PKR",
      name_plural: "Pakistani rupees",
    },
    {
      label: "PLN",
      value: "PLN",
      symbol: "zł",
      name: "Polish Zloty",
      symbol_native: "zł",
      decimal_digits: 2,
      rounding: 0,
      code: "PLN",
      name_plural: "Polish zlotys",
    },
    {
      label: "PYG",
      value: "PYG",
      symbol: "₲",
      name: "Paraguayan Guarani",
      symbol_native: "₲",
      decimal_digits: 0,
      rounding: 0,
      code: "PYG",
      name_plural: "Paraguayan guaranis",
    },
    {
      label: "QAR",
      value: "QAR",
      symbol: "QR",
      name: "Qatari Rial",
      symbol_native: "ر.ق.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "QAR",
      name_plural: "Qatari rials",
    },
    {
      label: "RON",
      value: "RON",
      symbol: "RON",
      name: "Romanian Leu",
      symbol_native: "RON",
      decimal_digits: 2,
      rounding: 0,
      code: "RON",
      name_plural: "Romanian lei",
    },
    {
      label: "RSD",
      value: "RSD",
      symbol: "din.",
      name: "Serbian Dinar",
      symbol_native: "дин.",
      decimal_digits: 0,
      rounding: 0,
      code: "RSD",
      name_plural: "Serbian dinars",
    },
    {
      label: "RUB",
      value: "RUB",
      symbol: "RUB",
      name: "Russian Ruble",
      symbol_native: "₽.",
      decimal_digits: 2,
      rounding: 0,
      code: "RUB",
      name_plural: "Russian rubles",
    },
    {
      label: "RWF",
      value: "RWF",
      symbol: "RWF",
      name: "Rwandan Franc",
      symbol_native: "FR",
      decimal_digits: 0,
      rounding: 0,
      code: "RWF",
      name_plural: "Rwandan francs",
    },
    {
      label: "SAR",
      value: "SAR",
      symbol: "SR",
      name: "Saudi Riyal",
      symbol_native: "ر.س.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "SAR",
      name_plural: "Saudi riyals",
    },
    {
      label: "SDG",
      value: "SDG",
      symbol: "SDG",
      name: "Sudanese Pound",
      symbol_native: "SDG",
      decimal_digits: 2,
      rounding: 0,
      code: "SDG",
      name_plural: "Sudanese pounds",
    },
    {
      label: "SEK",
      value: "SEK",
      symbol: "Skr",
      name: "Swedish Krona",
      symbol_native: "kr",
      decimal_digits: 2,
      rounding: 0,
      code: "SEK",
      name_plural: "Swedish kronor",
    },
    {
      label: "SGD",
      value: "SGD",
      symbol: "S$",
      name: "Singapore Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "SGD",
      name_plural: "Singapore dollars",
    },
    {
      label: "SOS",
      value: "SOS",
      symbol: "Ssh",
      name: "Somali Shilling",
      symbol_native: "Ssh",
      decimal_digits: 0,
      rounding: 0,
      code: "SOS",
      name_plural: "Somali shillings",
    },
    {
      label: "SYP",
      value: "SYP",
      symbol: "SY£",
      name: "Syrian Pound",
      symbol_native: "ل.س.‏",
      decimal_digits: 0,
      rounding: 0,
      code: "SYP",
      name_plural: "Syrian pounds",
    },
    {
      label: "THB",
      value: "THB",
      symbol: "฿",
      name: "Thai Baht",
      symbol_native: "฿",
      decimal_digits: 2,
      rounding: 0,
      code: "THB",
      name_plural: "Thai baht",
    },
    {
      label: "TND",
      value: "TND",
      symbol: "DT",
      name: "Tunisian Dinar",
      symbol_native: "د.ت.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "TND",
      name_plural: "Tunisian dinars",
    },
    {
      label: "TOP",
      value: "TOP",
      symbol: "T$",
      name: "Tongan Paʻanga",
      symbol_native: "T$",
      decimal_digits: 2,
      rounding: 0,
      code: "TOP",
      name_plural: "Tongan paʻanga",
    },
    {
      label: "TRY",
      value: "TRY",
      symbol: "TL",
      name: "Turkish Lira",
      symbol_native: "TL",
      decimal_digits: 2,
      rounding: 0,
      code: "TRY",
      name_plural: "Turkish Lira",
    },
    {
      label: "TTD",
      value: "TTD",
      symbol: "TT$",
      name: "Trinidad and Tobago Dollar",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "TTD",
      name_plural: "Trinidad and Tobago dollars",
    },
    {
      label: "TWD",
      value: "TWD",
      symbol: "NT$",
      name: "New Taiwan Dollar",
      symbol_native: "NT$",
      decimal_digits: 2,
      rounding: 0,
      code: "TWD",
      name_plural: "New Taiwan dollars",
    },
    {
      label: "TZS",
      value: "TZS",
      symbol: "TSh",
      name: "Tanzanian Shilling",
      symbol_native: "TSh",
      decimal_digits: 0,
      rounding: 0,
      code: "TZS",
      name_plural: "Tanzanian shillings",
    },
    {
      label: "UAH",
      value: "UAH",
      symbol: "₴",
      name: "Ukrainian Hryvnia",
      symbol_native: "₴",
      decimal_digits: 2,
      rounding: 0,
      code: "UAH",
      name_plural: "Ukrainian hryvnias",
    },
    {
      label: "UGX",
      value: "UGX",
      symbol: "USh",
      name: "Ugandan Shilling",
      symbol_native: "USh",
      decimal_digits: 0,
      rounding: 0,
      code: "UGX",
      name_plural: "Ugandan shillings",
    },
    {
      label: "UYU",
      value: "UYU",
      symbol: "$U",
      name: "Uruguayan Peso",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
      code: "UYU",
      name_plural: "Uruguayan pesos",
    },
    {
      label: "UZS",
      value: "UZS",
      symbol: "UZS",
      name: "Uzbekistan Som",
      symbol_native: "UZS",
      decimal_digits: 0,
      rounding: 0,
      code: "UZS",
      name_plural: "Uzbekistan som",
    },
    {
      label: "VEF",
      value: "VEF",
      symbol: "Bs.F.",
      name: "Venezuelan Bolívar",
      symbol_native: "Bs.F.",
      decimal_digits: 2,
      rounding: 0,
      code: "VEF",
      name_plural: "Venezuelan bolívars",
    },
    {
      label: "VND",
      value: "VND",
      symbol: "₫",
      name: "Vietnamese Dong",
      symbol_native: "₫",
      decimal_digits: 0,
      rounding: 0,
      code: "VND",
      name_plural: "Vietnamese dong",
    },
    {
      label: "XAF",
      value: "XAF",
      symbol: "FCFA",
      name: "CFA Franc BEAC",
      symbol_native: "FCFA",
      decimal_digits: 0,
      rounding: 0,
      code: "XAF",
      name_plural: "CFA francs BEAC",
    },
    {
      label: "XOF",
      value: "XOF",
      symbol: "CFA",
      name: "CFA Franc BCEAO",
      symbol_native: "CFA",
      decimal_digits: 0,
      rounding: 0,
      code: "XOF",
      name_plural: "CFA francs BCEAO",
    },
    {
      label: "YER",
      value: "YER",
      symbol: "YR",
      name: "Yemeni Rial",
      symbol_native: "ر.ي.‏",
      decimal_digits: 0,
      rounding: 0,
      code: "YER",
      name_plural: "Yemeni rials",
    },
    {
      label: "ZAR",
      value: "ZAR",
      symbol: "R",
      name: "South African Rand",
      symbol_native: "R",
      decimal_digits: 2,
      rounding: 0,
      code: "ZAR",
      name_plural: "South African rand",
    },
    {
      label: "ZMK",
      value: "ZMK",
      symbol: "ZK",
      name: "Zambian Kwacha",
      symbol_native: "ZK",
      decimal_digits: 0,
      rounding: 0,
      code: "ZMK",
      name_plural: "Zambian kwachas",
    },
    {
      label: "ZWL",
      value: "ZWL",
      symbol: "ZWL$",
      name: "Zimbabwean Dollar",
      symbol_native: "ZWL$",
      decimal_digits: 0,
      rounding: 0,
      code: "ZWL",
      name_plural: "Zimbabwean Dollar",
    },
  ],
  newAddress: { label: "New Address", value: "NEW_ADDRESS" },
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    if (window.FileReader) {
      const reader = new FileReader();
      if (file && file.type.match("image.*")) {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }
    }
  });

export const findImageSize = (file) =>
  new Promise((resolve, reject) => {
    var _URL = window.URL || window.webkitURL;
    let img = new Image();
    img.src = _URL.createObjectURL(file);
    img.onload = (e) => {
      resolve({
        width: e.target.width,
        height: e.target.height,
      });
    };
    img.onerror = (e) => {
      reject(e);
    };
  });

export const capitalizeString = (string) => {
  if (!string || typeof string != "string") {
    return string;
  }
  return string?.charAt(0).toUpperCase() + string.slice(1);
};

export const sortFields = {
  provider: {
    created_at: "createdDate",
    name: "careProviderName",
  },
  supervisor: {
    created_at: "createdDate",
    name: "careSupervisorName",
  },
  professional: {
    created_at: "createdDate",
    name: "careGiverName",
  },
  coordinator: {
    created_at: "createdDate",
    name: "careCoordinatorName",
  },
  client: {
    created_at: "createdDate",
    name: "name",
  },
  services: {
    created_at: "createdDate",
    name: "name",
  },
  package: {
    created_at: "createdDate",
    name: "name",
  },
  product: {
    created_at: "createdDate",
    name: "name",
  },
};

export const sortDirection = {
  asc: "asc",
  desc: "desc",
};

export const convertHexToRGBA = (hex, opacity) => {
  if (hex) {
    const tempHex = hex.replace("#", "");
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  }
  return null;
};

export const openFullscreen = (id) => {
  var elem = document.getElementById(id);
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

export const closeFullscreen = (id) => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

// convert time to this format
//  22:23 to 10:12 PM

export const timeCustomConverter = (date) => {
  let _date = new Date(date);
  let _hours = _date.getHours() % 12 === 0 ? 12 : _date.getHours() % 12;
  let _minutes = _date.getMinutes() % 12;

  let AmOrPm = _date.getHours() >= 12 ? "Pm" : "Am";

  return (
    ("0" + _hours).slice(-2) + ":" + ("0" + _minutes).slice(-2) + " " + AmOrPm
  );
};

export const months = [
  {
    id: 1,
    monthName: "January",
    monthShortName: "Jan",
  },
  {
    id: 2,
    monthName: "February",
    monthShortName: "Feb",
  },
  {
    id: 3,
    monthName: "March",
    monthShortName: "Mar",
  },
  {
    id: 4,
    monthName: "April",
    monthShortName: "Apr",
  },
  {
    id: 5,
    monthName: "May",
    monthShortName: "May",
  },
  {
    id: 6,
    monthName: "June",
    monthShortName: "Jun",
  },
  {
    id: 7,
    monthName: "July",
    monthShortName: "Jul",
  },
  {
    id: 8,
    monthName: "August",
    monthShortName: "Aug",
  },
  {
    id: 9,
    monthName: "September",
    monthShortName: "Sep",
  },
  {
    id: 10,
    monthName: "October",
    monthShortName: "Oct",
  },
  {
    id: 11,
    monthName: "November",
    monthShortName: "Nov",
  },
  {
    id: 12,
    monthName: "December",
    monthShortName: "Dec",
  },
];

export const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
].map((val, i) => ({
  label: val,
  value: i,
}));

// export const masterDataOptions = [
//   { label: "Skills", value: "Skills" },
//   { label: "Issuing Authority", value: "Issuing Authority" },
//   { label: "Employers", value: "Employers" },
//   { label: "Categories", value: "Categories" },
//   { label: "Certifications", value: "Certifications" },
//   { label: "Job Roles", value: "Job Roles" },
//   { label: "Specializations", value: "Specializations" },
//   { label: "Language", value: "Language" },
//   { label: "Frequency", value: "Frequency" },
//   { label: "Salutation", value: "Salutation" },
//   { label: "License", value: "License" },
//   { label: "Validity", value: "Validity" },
//   { label: "Address", value: "Address" },
//   { label: "Email", value: "Email" },
//   { label: "Phone", value: "Phone" }
// ];

export const masterData = [
  {
    id: 1,
    label: "Skills",
    editDataLabel: ["label"],
    tableColumnName: "Skill Name",
    singularVersion: "Skill",
    value: 1,
  },
  {
    id: 2,
    label: "Issuing Authority",
    editDataLabel: ["label"],
    tableColumnName: "Issuing Authority Name",
    singularVersion: "ID Type",
    value: 2,
  },
  {
    id: 3,
    label: "Employers",
    editDataLabel: ["label"],
    tableColumnName: "Employer Name",
    singularVersion: "ID Type",
    value: 3,
  },
  {
    id: 4,
    label: "Categories",
    editDataLabel: ["label"],
    tableColumnName: "Category Name",
    singularVersion: "Category",
    value: 4,
  },
  {
    id: 5,
    label: "Certifications",
    editDataLabel: ["label"],
    tableColumnName: "Certification Name",
    singularVersion: "Certification",
    value: 5,
  },
  {
    id: 6,
    label: "Job Roles",
    editDataLabel: ["label"],
    tableColumnName: "Job Role Name",
    singularVersion: "Job Role",
    value: 6,
  },
  {
    id: 7,
    label: "Specializations",
    editDataLabel: ["label"],
    tableColumnName: "Specialization Name",
    singularVersion: "Specialization",
    value: 7,
  },
  {
    id: 8,
    label: "Languages",
    editDataLabel: ["label"],
    tableColumnName: "Language",
    singularVersion: "Language",
    value: 8,
  },
  // {
  //   id: 9,
  //   label: "Frequency Types",
  //   editDataLabel: ["label"],
  //   tableColumnName: "Frequency",
  //   singularVersion: "Frequency",
  //   value: 9,
  // },
  {
    id: 9,
    label: "Salutation Types",
    editDataLabel: ["label"],
    tableColumnName: "Salutation",
    singularVersion: "Salutation",
    value: 9,
  },
  {
    id: 10,
    label: "License Types",
    editDataLabel: ["label"],
    tableColumnName: "License Name",
    singularVersion: "License Name",
    value: 10,
  },
  // {
  //   id: 12,
  //   label: "Validity Types",
  //   editDataLabel: ["label", "days"],
  //   tableColumnName: "Validity Name",
  //   singularVersion: "Validity Name",
  //   value: 12,
  //   additionalFields: [
  //     {
  //       tableColumnName: "Number of Days",
  //       singularVersion: "Number of Days",
  //     },
  //   ],
  // },
  {
    id: 11,
    label: "Address Types",
    editDataLabel: ["label"],
    tableColumnName: "Address Type",
    singularVersion: "Address Type",
    value: 11,
  },
  {
    id: 12,
    label: "Email Types",
    editDataLabel: ["label"],
    tableColumnName: "Email Type",
    singularVersion: "Email Type",
    value: 12,
  },
  {
    id: 13,
    label: "Contact Number Types",
    editDataLabel: ["label"],
    tableColumnName: "Phone Type",
    singularVersion: "Phone Type",
    value: 13,
  },
  {
    id: 14,
    label: "Identification Types",
    editDataLabel: ["label"],
    tableColumnName: "ID Type",
    singularVersion: "ID Type",
    value: 14,
  },
  {
    id: 15,
    label: "Source of Referral",
    editDataLabel: ["label"],
    tableColumnName: "Source of Referral",
    singularVersion: "Source of Referral",
    value: 15,
  },
  /* {
    id: 18,
    label: "Enquiry Status",
    editDataLabel: ["label"],
    tableColumnName: "Enquiry Status",
    singularVersion: "Enquiry Status",
    value: 18,
  }, */
  {
    id: 16,
    label: "Enquiry Lead Types",
    editDataLabel: ["label"],
    tableColumnName: "Enquiry Lead Types",
    singularVersion: "Enquiry Lead Types",
    value: 16,
  },
  {
    id: 17,
    label: "Product Group Types",
    editDataLabel: ["label"],
    tableColumnName: "Product Group Type",
    singularVersion: "Product Group Type",
    value: 17,
  },
  {
    id: 18,
    label: "Leave Types",
    editDataLabel: ["label"],
    tableColumnName: "Leave Type",
    singularVersion: "Leave Type",
    value: 18,
  },
  {
    id: 19,
    label: "Tax Types",
    editDataLabel: ["label"],
    tableColumnName: "Tax Type",
    singularVersion: "Tax Type",
    value: 19,
  },
  // {
  //   id: 21,
  //   label: "Unavailable Types",
  //   editDataLabel: ["label"],
  //   tableColumnName: "Unavailable Type",
  //   singularVersion: "Unavailable Type",
  //   value: 21,
  // },
];

export const TaxMaster = [
  {
    id: 1,
    label: "Tax Management",
    editDataLabel: ["label"],
    tableColumnName: "Tax Management",
    singularVersion: "Tax Management",
    value: 1,
  },
];

export const ConfigurationMaster = [
  {
    id: 1,
    label: "Tracking SP Setup",
    editDataLabel: ["label"],
    tableColumnName: "Tracking SP Setup",
    singularVersion: "Tracking SP Setup",
    value: 1,
  },
];

export const formatString = (exceptionData) => {
  if (exceptionData?.days?.length > 0) {
    let dayString = exceptionData.days.join(", ");
    let index = dayString.lastIndexOf(",");
    let replaceChar = " &";
    let firstPart = dayString.substr(0, index);
    let lastPart = dayString.substr(index + 1);
    return firstPart + replaceChar + lastPart;
  }
  return "NA";
};

/**
 * @param {Boolean} activityTimezoneOptions Whether options needed are sufficient for activity Add/Edit Timezone.
 * @returns {Object}
 */
export const timezoneOld = (activityTimezoneOptions) => {
  const allTz = ct?.default?.getAllTimezones()
    ? ct?.default?.getAllTimezones()
    : ct?.getAllTimezones();
  console.log(allTz);
  let tzAllOptions = [];
  activityTimezoneOptions
    ? Object.keys(allTz).map((timezone, index) => {
        let tzOptions = {};
        tzOptions["label"] = `(UTC ${allTz[timezone]?.utcOffsetStr}) ${
          isNaN(parseInt(moment.tz(allTz[timezone]?.name).zoneAbbr()))
            ? moment.tz(allTz[timezone]?.name).zoneAbbr()
            : ""
        } - ${
          allTz[timezone]?.name.split("/")[1] === undefined
            ? allTz[timezone]?.name.split("/")[0]
            : allTz[timezone]?.name.split("/")[1]
        }`;
        tzOptions["value"] = allTz[timezone]?.name;
        tzAllOptions.push(tzOptions);
        return null;
      })
    : Object.keys(allTz).map((timezone, index) => {
        let tzOptions = {};
        tzOptions["label"] = allTz[timezone]?.name;
        tzOptions["value"] = allTz[timezone]?.name;
        tzOptions["name"] = allTz[timezone]?.name;
        tzOptions["country"] = allTz[timezone]?.country;
        tzOptions["difference"] = allTz[timezone]?.utcOffsetStr?.charAt(0);
        tzOptions["hour"] =
          allTz[timezone]?.utcOffsetStr?.charAt(1) +
          allTz[timezone].utcOffsetStr?.charAt(2);
        tzOptions["minute"] =
          allTz[timezone]?.utcOffsetStr?.charAt(4) +
          allTz[timezone].utcOffsetStr?.charAt(5);
        tzAllOptions.push(tzOptions);
        return null;
      });
  return tzAllOptions;
};
export const timezoneIncoming = () => {
  const allTz = ct?.default?.getAllTimezones()
    ? ct?.default?.getAllTimezones()
    : ct?.getAllTimezones();
  let tzAllOptions = [];
  Object.keys(allTz).map((timezone, index) => {
    let tzOptions = {};
    tzOptions["label"] = allTz[timezone]?.name;
    tzOptions["value"] = allTz[timezone]?.name;
    tzOptions["name"] = allTz[timezone]?.name;
    tzOptions["country"] = allTz[timezone]?.country;
    tzOptions["difference"] = allTz[timezone]?.utcOffsetStr?.charAt(0);
    tzOptions["hour"] =
      allTz[timezone]?.utcOffsetStr?.charAt(1) +
      allTz[timezone].utcOffsetStr?.charAt(2);
    tzOptions["minute"] =
      allTz[timezone]?.utcOffsetStr?.charAt(4) +
      allTz[timezone].utcOffsetStr?.charAt(5);
    tzAllOptions.push(tzOptions);
    return null;
  });
  return tzAllOptions?.filter((x) => x?.country !== null);
};

export const bmiCalculation = (height = 1, weight = 1) => {
  weight = parseFloat(weight);
  height = parseFloat(height);
  let BMI = Math.round((weight / Math.pow(height, 2)) * 10000);
  return BMI;
};

export const scrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

export const subHeaderSortOption = [
  {
    value: "az",
    label: "A - Z",
  },
  {
    value: "za",
    label: "Z - A",
  },
  {
    value: "most_recent",
    label: "Most Recent",
  },
];
// added this because for in adminstration they used **subHeaderSortOption** so added newly
export const subHeaderSortOptionWithValue = [
  {
    value: 2,
    label: "A - Z",
  },
  {
    value: 1,
    label: "Z - A",
  },
  {
    value: 3,
    label: "Most Recent",
  },
];
export const productSortDirectionOption = [
  {
    value: "asc",
    label: "Ascending",
  },
  {
    value: "desc",
    label: "Descending",
  },
];
export const productSortFieldOption = [
  {
    value: "createdAt",
    label: "Created Date",
  },
  {
    value: "resourceName",
    label: "Name",
  },
];

const colorJson = {
  0: "#F3A683",
  1: "#778BEB",
  2: "#E77F67",
  3: "#786FA6",
  4: "#F8A5C2",
  5: "#EA8685",
  7: "#D1CCC0",
  8: "#F7D794",
  9: "#06BEE1",
};

export const avatarColorBgById = (id) => {
  let idLast = id?.toString()?.split("")?.pop();
  return colorJson[idLast] ?? "#778BEB";
};

export const getIdForAvatarColor = (id) => {
  return (
    id?.[id?.toString()?.search(/\d/)] *
    id?.toString()?.split("")?.reverse()?.[
      id?.toString().split("").reverse().join("")?.search(/\d/)
    ]
  );
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const activityStatus = {
  completed: "Completed",
  inProgress: "In Progress",
  closed: "Closed",
  cancelled: "Cancelled",
  rescheduled: "Rescheduled",
};

export const convertToBase64URL = (url, fileName) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
};

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function getCountryCodeOptions() {
  const options = converToReactSelect(countryCode, "dial_code", "countryName");
  return options;
}

export const fontStyleClass = {
  mediumFont: {
    // fontFamily: "manage_care_semibold",
  },
};
export const commonShadows = {
  commonShadows: {
    // boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04)",
  },
};

export const genericStyles = {
  cardWrapperShadows: (theme) => ({
    cardWrapperShadows: {
      background: "white",
      borderRadius: 16,
      boxShadow: theme?.palette?.primary?.boxShadow,
    },
  }),
};

export const customAddress = (
  address1 = "",
  address2 = "",
  area = "",
  state = "",
  country = "",
  postalCode = ""
) => {
  return `
  ${address1},\n
  ${address2},\n
  ${area},\n
  ${state},\n
  ${country},\n
  ${postalCode}`;
};

export const customMobileNumber = (mobileNoIsdCode = "", mobileNo = "") => {
  return `${mobileNoIsdCode} ${mobileNo}`;
};
export const seenADoctorOptions = [
  { label: "Lesser than a month", value: "lesserThanAMonth" },
  { label: "3 months ago", value: "threeMonthsAgo" },
  { label: "6 months ago", value: "sixMonthsAgo" },
  { label: "1 Year ago", value: "oneYearAgo" },
];
export const SelectComponentWithSubComponentJSON = {
  address: {
    addressLine1: "",
    addressLine2: "",
    area: "",
    state: "",
    country: "",
    postalCode: "",
  },
  email: {
    emailId: "",
  },
  phone: {
    mobileNoIsdCode: "",
    mobileNo: "",
  },
};

export const customDateConverter = (date) => {
  let _customDate = new Date(date);
  if (date) return moment(_customDate).format("DD/MM/YYYY");
  else return null;
};

export const customBreakPointsComponents = [
  formComponentTypes.TABLE,
  // formComponentTypes.PROFILE_UPLOAD_BUTTON,
  formComponentTypes.ADD_CERTIFICATION,
  formComponentTypes.ADD_EXPERIENCE,
  // formComponentTypes.ADD_ADDRESS,
  // formComponentTypes.ADD_EMAIL,
  // formComponentTypes.ADD_PHONE_NUMBER,
  // formComponentTypes.ADD_ID_TYPE,
  formComponentTypes.RICHTEXTEDITOR,
];

export const exoticComponents = [
  {
    componentType: formComponentTypes.ADD_CERTIFICATION,
    propName: "certificate",
  },
  {
    componentType: formComponentTypes.ADD_EXPERIENCE,
    propName: "experience",
  },
  {
    componentType: formComponentTypes.ADD_ADDRESS,
    propName: "address",
  },
  {
    componentType: formComponentTypes.ADD_EMAIL,
    propName: "email",
  },
  {
    componentType: formComponentTypes.ADD_PHONE_NUMBER,
    propName: "phone",
  },
  {
    componentType: formComponentTypes.ADD_ID_TYPE,
    propName: "idType",
  },
];

export const tableType = {
  package: "package",
  product: "product",
};

export const packageTableColumns = [
  {
    id: "drag",
    label: "",
    minWidth: {
      xs: "fit-content",
      sm: "fit-content",
      md: "fit-content",
      lg: "fit-content",
      xl: "fit-content",
    },
  },
  {
    id: "sno",
    label: "S.No",
    minWidth: {
      xs: "fit-content",
      sm: "fit-content",
      md: "fit-content",
      lg: "fit-content",
      xl: "fit-content",
    },
  },
  {
    id: "label",
    label: "Service",
    minWidth: {
      xs: 100,
      sm: 100,
      md: 100,
      lg: 140,
      xl: 200,
    },
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "providerDetails",
    label: "Partner",
    minWidth: {
      xs: 100,
      sm: 100,
      md: 120,
      lg: 120,
      xl: 120,
    },
    align: "left",
  },
  {
    id: "strict",
    label: "Strict",
    minWidth: {
      xs: "fit-content",
      sm: "fit-content",
      md: "fit-content",
      lg: "fit-content",
      xl: "fit-content",
    },
    align: "center",
    type: formComponentTypes.CHECK_BOX,
  },
  {
    id: "dependentService",
    label: "Dependent Service",
    minWidth: {
      xs: 100,
      sm: 100,
      md: 120,
      lg: 130,
      xl: 200,
    },
    align: "left",
    type: formComponentTypes.SELECT_BOX,
  },
  {
    id: "quantity",
    label: "Quantity",
    minWidth: {
      xs: 100,
      sm: 100,
      md: 80,
      lg: 80,
      xl: 80,
    },
    align: "left",
    type: formComponentTypes.INPUT_BOX,
    props: {
      type: "number",
    },
  },
  {
    id: "serviceAmountPrice",
    label: "Amount",
    minWidth: {
      xs: 100,
      sm: 100,
      md: 100,
      lg: 100,
      xl: 100,
    },
    align: "left",
  },
  {
    id: "amount",
    label: "Total",
    minWidth: {
      xs: 100,
      sm: 100,
      md: 100,
      lg: 100,
      xl: 100,
    },
    align: "left",
    format: (value) => {
      return parseFloat(value).toFixed(2);
    },
  },
];

export const productTableColumns = [
  {
    id: "drag",
    label: "",
    minWidth: {
      xs: "fit-content",
      sm: "fit-content",
      md: "fit-content",
      lg: "fit-content",
      xl: "fit-content",
    },
  },
  {
    id: "sno",
    label: "S.No",
    minWidth: {
      xs: "fit-content",
      sm: "fit-content",
      md: "fit-content",
      lg: "fit-content",
      xl: "fit-content",
    },
  },
  {
    id: "label",
    label: "Package/Service",
    minWidth: {
      xs: 100,
      sm: 100,
      md: 100,
      lg: 200,
      xl: 200,
    },
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "providerDetails",
    label: "Partner",
    minWidth: {
      xs: 100,
      sm: 100,
      md: 120,
      lg: 120,
      xl: 120,
    },
    align: "left",
  },
  // {
  //   id: "type",
  //   label: "Category",
  //   minWidth: {
  //     xs: 100,
  //     sm: 100,
  //     md: 100,
  //     lg: 200,
  //     xl: 200
  //   },
  //   align: "left",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "strict",
    label: "Strict",
    minWidth: {
      xs: 100,
      sm: 100,
      md: 150,
      lg: 180,
      xl: 280,
    },
    align: "center",
    type: formComponentTypes.CHECK_BOX,
  },
  {
    id: "dependentService",
    label: "Dependent Service",
    minWidth: {
      xs: 100,
      sm: 100,
      md: 120,
      lg: 130,
      xl: 200,
    },
    align: "left",
    type: formComponentTypes.SELECT_BOX,
  },
  {
    id: "quantity",
    label: "Quantity",
    minWidth: {
      xs: 100,
      sm: 100,
      md: 80,
      lg: 80,
      xl: 80,
    },
    align: "left",
    type: formComponentTypes.INPUT_BOX,
    props: {
      type: "number",
    },
  },
  {
    id: "serviceAmountPrice",
    label: "Amount",
    minWidth: {
      xs: 100,
      sm: 100,
      md: 100,
      lg: 100,
      xl: 100,
    },
    align: "left",
  },
  {
    id: "amount",
    label: "Total",
    minWidth: {
      xs: 100,
      sm: 100,
      md: 100,
      lg: 100,
      xl: 100,
    },
    align: "left",
    format: (value) => {
      return parseFloat(value).toFixed(2);
    },
  },
];

export const minColumnSizeStyles = (theme) => {
  let allClasses = {};

  //Add Classes of PackagetableColumns to allClasses Object
  packageTableColumns.map((column) => {
    allClasses[`${column.id}-${tableType.package}-Style`] = {
      [theme.breakpoints.only("xs")]: {
        minWidth: column.minWidth.xs ?? "fit-content",
      },
      [theme.breakpoints.only("sm")]: {
        minWidth: column.minWidth.sm ?? "fit-content",
      },
      [theme.breakpoints.only("md")]: {
        minWidth: column.minWidth.md ?? "fit-content",
      },
      [theme.breakpoints.only("lg")]: {
        minWidth: column.minWidth.lg ?? "fit-content",
      },
      [theme.breakpoints.only("xl")]: {
        minWidth: column.minWidth.xl ?? "fit-content",
      },
    };
    return column;
  });

  productTableColumns.map((column) => {
    allClasses[`${column.id}-${tableType.product}-Style`] = {
      [theme.breakpoints.only("xs")]: {
        minWidth: column.minWidth.xs ?? "fit-content",
      },
      [theme.breakpoints.only("sm")]: {
        minWidth: column.minWidth.sm ?? "fit-content",
      },
      [theme.breakpoints.only("md")]: {
        minWidth: column.minWidth.md ?? "fit-content",
      },
      [theme.breakpoints.only("lg")]: {
        minWidth: column.minWidth.lg ?? "fit-content",
      },
      [theme.breakpoints.only("xl")]: {
        minWidth: column.minWidth.xl ?? "fit-content",
      },
    };
    return column;
  });

  return allClasses;
};

export const fileLessThanTenMb = (fileSize) => {
  return fileSize <= 10485760;
};

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const formTempeletesTypeId = {
  pre_assignment: "03736fcc-9e2a-4e34-80b5-bc2b39241e37",
  assignment: "d7d74c74-c078-4e71-98c6-00c5acc079e2",
  service_deliverables: "abfa9f16-1a8e-40f4-932b-78d71a02dc6d",
  service_delivery: "d978cea4-f416-45e5-92c4-3d19cf8f0413",
};

export const mappingResourceNames = {
  PROFESSIONAL: "PROFESSIONAL",
  COORDINATOR: "COORDINATOR",
};

export const checkBackBasedOnRole = (role) => {
  switch (role) {
    case user_roles.super_admin:
      return routes.partner_management_branch;
    case user_roles.provider:
      return routes.partner_management_branch;
    case user_roles.business_admin:
      return routes.partner_management_branch;
    default:
      return routes.dashboard;
  }
};

export const bloodGroupOptions = () => {
  return [
    {
      value: "A Positive (A+)",
      label: "A Positive (A+)",
    },
    {
      value: "A Negative (A-)",
      label: "A Negative (A-)",
    },
    {
      value: "B Positive (B+)",
      label: "B Positive (B+)",
    },
    {
      value: "B Negative (B-)",
      label: "B Negative (B-)",
    },
    {
      value: "O Positive (O+)",
      label: "O Positive (O+)",
    },
    {
      value: "O Negative (O-)",
      label: "O Negative (O-)",
    },
    {
      value: "AB Positive (AB+)",
      label: "AB Positive (AB+)",
    },
    {
      value: "AB Negative (AB-)",
      label: "AB Negative (AB-)",
    },
  ];
};

export const getSplicedData = (data, spliceBy = 1) => {
  let x = [...data];
  x.splice(0, spliceBy);

  return x;
};
export const DoughnutColor = [
  "#28a9c1",
  "#353596",
  "#f27d35",
  "#FFD328",
  "#E03347",
  "#603082",
  "#39a2eb",
  "#5dc1c1",
  "#00AF54",
  "#643a52",
  "#716893",
];
export const DoughnutHoverColor = [
  "#00d7ff",
  "#0027a7",
  "#ff7723",
  "#FFDA47",
  "#E44E5F",
  "#6E3795",
  "#2095e9",
  "#43b1b1",
  "#008f45",
  "#A66489",
  "#5C5577",
];

export const capitalizeFirstLetterOfString = (string) => {
  if (string !== undefined && string !== null)
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const addressComponentTypes = {
  addressLine1: "addressLine1",
  addressLine2: "addressLine2",
  area: "area", //city
  state: "state",
  country: "country",
  postalCode: "postalCode",
};

/**
 *
 * @param {Array with higher length than arr2} arr1
 * @param {Array with lesser length than arr1} arr2
 * @param {Property to check for in the array of objects} uniqueVariableName
 * @returns <Array>
 */
export const getUniques = (arr1, arr2, uniqueVariableName) => {
  return arr1
    .filter(function (o1) {
      // filter out (!) items in arr2
      return !arr2.some(function (o2) {
        return o1?.[uniqueVariableName] === o2?.[uniqueVariableName]; // assumes unique id
      });
    })
    .map(function (o) {
      return o;
    });
};

export const isbase64 = (_string) => {
  var base64 =
    /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/;
  return base64.test(_string);
};

export function base64MimeType(encoded) {
  var result = null;

  if (typeof encoded !== "string") {
    return result;
  }

  var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) {
    result = mime[1];
  }

  return result;
}

export const upload_file = (file) => {
  let base64encode = file?.split(",");
  let fileType = base64MimeType(file);

  // var dateObj = new Date();
  // var month = dateObj.getUTCMonth() + 1; //months from 1-12
  // var day = dateObj.getUTCDate();
  // var year = dateObj.getUTCFullYear();
  // var year = dateObj.getUTCFullYear();
  // var newdate = year + "_" + month + "_" + day
  let fileName = `tc_profile_${timeStamp()}.${fileType.split("/")[1]}`;
  let withoutType = base64encode[1];
  return { fileName, withoutType };
};

/**
 * Return a timestamp with the format "m/d/yy h:MM:ss TT"
 * @type {Date}
 */

export function timeStamp() {
  // Create a date object with the current time
  var now = new Date();

  // Create an array with the current month, day and time
  var date = [now.getMonth() + 1, now.getDate(), now.getFullYear()];

  // Create an array with the current hour, minute and second
  var time = [now.getHours(), now.getMinutes(), now.getSeconds()];

  // Determine AM or PM suffix based on the hour
  // var suffix = ( time[0] < 12 ) ? "AM" : "PM";

  // Convert hour from military time
  time[0] = time[0] < 12 ? time[0] : time[0] - 12;

  // If hour is 0, set it to 12
  time[0] = time[0] || 12;

  // If seconds and minutes are less than 10, add a zero
  for (var i = 1; i < 3; i++) {
    if (time[i] < 10) {
      time[i] = "0" + time[i];
    }
  }

  // Return the formatted string
  return date.join("_") + "_" + time.join("_");
}

export const videoFormats = [
  "m1v",
  "mpeg",
  "mov",
  "qt",
  "mpa",
  "mpg",
  "mpe",
  "avi",
  "movie",
  "mp4",
];

export const imageFormats = ["png", "jpg", "jpeg", "bmp", "gif", "webp", "psd"];

export const textClampStyleGenerator = (lines = 1) => {
  return {
    display: "-webkit-box",
    WebkitLineClamp: lines,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
};

export const availableWeekDays = {
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
};
export const exceptionAvailableWeekDays = {
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const TAXTYPE = {
  VARIABLE: "Variable",
  FIXED: "Fixed",
};

export const taxAmountCalculation = (taxValue, amount) => {
  // let tax = taxValue * Amount;
  // // round with 2 decimal places
  // return Math.round(tax * 100) / 100;
  return parseFloat(amount * (taxValue / 100));
};

export const exoticColumnType = {
  DISCOUNT: "DISCOUNT",
  TAX_PERCENTAGE: "TAX_PERCENTAGE",
  TAX_TYPE: "TAX_TYPE",
  TAX_AMOUNT: "TAX_AMOUNT",
  TOTAL_TEXT: "TOTAL_TEXT",
  TOTAL_VALUE: "TOTAL_VALUE",
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export let ToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => resolve(null);
  });

export async function createFile(url, type, name) {
  let response = await fetch(url);
  let data = await response.blob();
  let metadata = {
    type: type,
  };
  let file = new File([data], `${name}.${type.split("/")[1]}`, metadata);
  let Url = ToBase64(file);
  return Url;
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

export const fixTwoDecimal = (value) => {
  return isNaN(parseFloat(value))
    ? parseFloat("0").toFixed(2)
    : parseFloat(value)?.toFixed(2);
};

// Function to sort an array of date using moment
export const sortByDateComparator = (a, b) => {
  if (moment(a).isValid() && moment(b).isValid()) {
    if (moment(a).isBefore(b)) {
      return -1;
    }
    if (moment(a).isAfter(b)) {
      return 1;
    }
    if (moment(a).isSame(b)) {
      return 0;
    }
  } else {
    throw new Error("Invalid date");
  }
};

// Function to sort an array of number given that if the argument passed is a string convert to a number and sort. If the parsed string is not a number then return 1
export const sortByNumberComparator = (a, b) => {
  if (isNaN(parseFloat(a)) && isNaN(parseFloat(b))) {
    return 1;
  }
  if (isNaN(parseFloat(a))) {
    return 1;
  }
  if (isNaN(parseFloat(b))) {
    return 1;
  }
  if (parseFloat(a) < parseFloat(b)) {
    return -1;
  }
  if (parseFloat(a) > parseFloat(b)) {
    return 1;
  }
  return 0;
};

export const isSuperAdmin = (authData) => {
  const { user_role: role } = authData;
  if (role) {
    return role === user_roles.super_admin;
  }
  return false;
};

export const isManagedCareAdmin = (authData) => {
  const { other_roles: roles = [] } = authData;
  if (roles && Array.isArray(roles)) {
    return roles.includes(OTHER_ROLES.crossBundling);
  }
  return false;
};

export const isCreatedByManagedCareAdmin = (createdBy) => {
  return createdBy === "Managedcare Admin";
};

export const isCreatedByManagedCareAdminById = (createdById, authData) => {
  const { user_id } = authData;
  return user_id === createdById;
};

// Using two arguments initialDistance and currentDistance to calculate percentage covered
export const calculateDistanceTravelledPercentage = (
  initialDistance,
  currentDistance
) => {
  const travelledDistance = initialDistance - currentDistance;
  const percentage = (travelledDistance / initialDistance) * 100;
  return percentage;
}
