import React from "react";
import {
  Grid,
  Switch,
  makeStyles,
  Typography,
} from "@material-ui/core";

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.ternary.main,
    },
    "& .MuiSwitch-track": {
        background: theme.palette.secondary.main
      },
      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        // "& .MuiSwitch-track":{
        background: "#80f10e"
        // }
      },
      "& .MuiSwitch-colorSecondary.Mui-checked": {
        color: "white"
      }
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
  label: {
    color: theme.palette.text.label
  },
  switch: {
    "& .MuiSwitch-track": {
      borderRadius: 13,
    },
    marginTop:(props) => !props.fixMargin ? 20 : 35,
    marginLeft:(props) => !props.fixMargin ? 25 : 0,

    "& .MuiSwitch-root": { padding: 6 }
  },

  masterSwitch:{
    "& .MuiSwitch-track": {
      borderRadius: 13,
    },

    marginTop:0,
    marginLeft:25,

    "& .MuiSwitch-root": { padding: 6 }
  },

  switchLabel: {
    color: theme.palette.secondary.dark,
    textAlign: "left",
    fontSize: "1rem",
  },
}));

export const SwitchComponent = (props) => {
  const { checked, handleOnChange, label, id, marginLeft} = props;

  const classes = styles(props);
  return (
    <div className={classes.root}>
        <Grid
    container
    item
    xs={12}
    className={props.hideMarginTop ? classes.masterSwitch : classes.switch}
    style={{marginLeft: marginLeft ? `${marginLeft}px` : `unset`, transition: "margin 700ms",}}
    alignItems="center"
    // justify="center"
  >
    {/* <Grid item xs={10}> */}
      <Typography
        variant="subtitle2"
        component="div"
        className={classes.switchLabel}
      >
        {label}
      </Typography>
      <Switch
        // id = {props.id}
        disableRipple
        checked={checked}
        onChange={handleOnChange}
        color="secondary"
        inputProps={{
          "aria-label": "checkbox with default color",
        }}
        id={id}
      />
    {/* </Grid> */}
    {/* <Grid item xs={1}>
      <Switch
        disableRipple
        checked={checked}
        onChange={handleOnChange}
        color="secondary"
        inputProps={{
          "aria-label": "checkbox with default color",
        }}
      />
    </Grid> */}
  </Grid>
    </div>

 );
};


