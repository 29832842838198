import React from 'react';
import AvatarStack from '../../../../components/avatarStack'
import SimpleAvatarCard from '../../../../components/simpleAvatarCard'
import DetailsCard from './detailCard'
import CardList from "../../../../components/userList";

import { Button, Divider, Grid, Typography, withStyles } from '@material-ui/core';

import { routes } from "../../../../router/routes";
import { alertProps, scrollToTop } from "../../../../utils";
import withAlelrtAndDialog from '../../../../HOCs/withAlelrtAndDialog';
import { actions } from 'tanyacare-middleware';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ViewServiceCoordinators from './ViewServiceCoordinators'
import ViewServiceProfessionals from './ViewServiceProfessional'

const style = (theme) => ({
    sectionContainer: {
        padding: 25,
        background: 'white',
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
    },
    sectionColumnTwo: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(4)
        }
    }
})

const sizes = {
    md: 12,
    sm: 6,
    xs: 12,
};

class SupervisorDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.getSupervisorDetail();
        this.props.GET_SAL({})
        scrollToTop();
    }

    getSupervisorDetail = () => {
        const id = this.props?.match?.params?.id;
        this.props.GET_CARE_SUPERVISOR_INFO({ id });
    }

    // Deactivate Functions Starts
    onDeactivateClicked = () => {
        const Oldstatus = this.props?.careSupervisorInfo?.data?.isactive;
        let status = "Activate";
        if (Oldstatus === "Active") {
            status = "Deactivate";
        }
        this.props.dialog.setDialog({
            open: true,
            title: "Are you sure?",
            body:
                "Do you want to " +
                status +
                " Care Supervisor : " +
                this?.props?.careSupervisorInfo?.data?.name,
            positiveBtn: status,
            negativeBtn: "Cancel",
            onOk: this.deactiveUser,
        });
    };

    deactiveUser = () => {
        const id = this.props?.match?.params?.id;
        const Oldstatus = this.props?.careSupervisorInfo?.data?.isactive;
        let newStatus = "Active";
        if (Oldstatus === "Active") {
            newStatus = "InActive";
        }
        this.props.dialog.setDialog({ open: false });

        this.props.UPDATE_CARE_SUPERVISOR_STATUS({ id, status: newStatus });
    };
    // Deactivate Functions ends


    // Add Function Starts
    handleAddServiceProfessional = () => {
        let { data } = this.giveMeNecessaryData();
        this.props.history.push({
            pathname: routes.add_care_givers,
            state: {
                provider: { label: data.name, value: data.id },
                category: data?.category
            }
        })
    }
    // Add Function Ends


    // Snackbar function Starts
    shouldComponentUpdate(nextProps, nextState) {
        const { updateCareSupervisorStatus } = nextProps;
        const oldupdateCareSupervisorStatus = this.props.updateCareSupervisorStatus;

        if (
            oldupdateCareSupervisorStatus?.loading !== updateCareSupervisorStatus?.loading
        ) {
            if (
                !updateCareSupervisorStatus.loading &&
                !updateCareSupervisorStatus.error
            ) {
                this.getSupervisorDetail();
                this.props.alert.setSnack({
                    open: true,
                    horizontal: alertProps.horizontal.center,
                    vertical: alertProps.vertical.top,
                    msg: "Care Supervisor status updated successfully!",
                    severity: alertProps.severity.success,
                });
            } else if (updateCareSupervisorStatus.error) {
                this.props.alert.setSnack({
                    open: true,
                    horizontal: alertProps.horizontal.center,
                    vertical: alertProps.vertical.top,
                    msg: "Something went wrong! Unable to update.",
                    severity: alertProps.severity.error,
                });
            }
        }

        return true;
    }
    // Snackbar function Ends
    onEndIconClickHandler = (id, isCareGiver) => {
        window.open(`${isCareGiver ? routes.manage_care_givers : routes.manage_care_coordinators}/view/${id}`, '_blank').focus()
    }

    onClickIcon = (data, isCareGiver) => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: false,
            component: <></>
        })
        this.props.history.push(`${isCareGiver ? routes.manage_care_givers : routes.manage_care_coordinators}/view/${data.data.id}`)
    }

    // Data Function Starts
    giveMeNecessaryData = () => {
        const { location, careSupervisorInfo } = this.props;
        const {data: salutationOptions} = this.props?.salutation ?? [{}]
        const { data } = careSupervisorInfo;

        let necessaryData = {
            ...careSupervisorInfo,
        };

        if (location?.state) {
            necessaryData = {
                ...location.state,
                ...careSupervisorInfo,
            };
        }

        if (data.id) {
            const _ = data;
            const header = [
                {
                    id: _?.id,
                    name: _?.name,
                    salutation: salutationOptions?.length > 0 ? salutationOptions?.filter(sal => _?.salutation === sal?.label)?.[0]?.label : [{}],
                    category: _?.category,
                    isactive: _?.isactive,
                    profile_pic: _?.profile_pic,
                },
            ];


            necessaryData = {
                ...careSupervisorInfo,
                header,
            };
        }

        return necessaryData;
    };
    // Data Function Ends


    // SP and SC view all handlers start
    handleServiceProfessionalsViewAllClick = (data) => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: <ViewServiceProfessionals data={data} drawer={this.props.drawer} onEndIconClickHandler={this.onClickIcon}/>
        })
    }
    handleServiceCoordinatorsViewAllClick = (data) => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: <ViewServiceCoordinators data={data} drawer={this.props.drawer} onEndIconClickHandler={this.onClickIcon}/>
        })
    }
    // SP and SC view all handlers end


    // Simple avatar click handler
    handleOnProviderClicked = (id) => {
        this.props.history.push(`${routes.manage_care_providers}/view/${id}`)
    }


    render() {
        const { classes } = this.props
        let { header, data, loading, error } = this.giveMeNecessaryData();

        const id = this.props?.match?.params?.id;

        return (
            <div style={{ padding: 25 }}>

                {(error || !data) && (
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        style={{ marginTop: `30vh` }}
                    >
                        <Grid item>
                            <Typography variant="h6" align="center">
                                Something went wrong!
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                style={{ marginTop: 16, textAlign: "center" }}
                                variant={"outlined"}
                                onClick={this.getCareProviderDetail}
                            >
                                Try Again
                            </Button>
                        </Grid>
                    </Grid>
                )}

                {!error && data && (
                    <>
                        <CardList
                            list={header}
                            componentOrder={2}
                            sizes={sizes}
                            isonlycard={true}
                            isCard={true}
                            link={routes.manage_supervisors + "/edit/" + id}
                            data={data[0]}
                            onDeactivateClicked={this.onDeactivateClicked}
                            isDeactivating={this.props?.updateCareSupervisorStatus?.loading}
                            handleAddServiceProfessional={this.handleAddServiceProfessional}
                            isSalutationNeeded={true}
                        />

                        <DetailsCard data={data} loading={loading} />

                        <Divider style={{ background: 'rgba(244, 244, 245, 1)' }} />

                        {/* Provider Section */}
                        <div className={classes.sectionContainer}>
                            <SimpleAvatarCard
                                id={data?.care_provider?.[0]?.id}
                                onAvatarClicked={(id) => this.handleOnProviderClicked(id)}
                                title={"PROVIDER"}
                                loading={loading}
                                emptyText="Not Mapped"
                                name={data?.care_provider?.[0]?.name ?? ""}
                                src={data?.care_provider?.[0]?.profile_pic ?? ""} />
                        </div>

                        {/* Service Professional and Coordinator Section */}
                        <div className={classes.sectionContainer}>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <AvatarStack title={"SERVICE PROFESSIONALS"} height={16} width={16}
                                        style={{ position: 'relative', top: 2 }}
                                        onAvatarClicked={(id) => this.onEndIconClickHandler(id, true)}
                                        data={data?.service_professionals}
                                        emptyMessage="Not Mapped"
                                        onButtonClickHandler={() => this.handleServiceProfessionalsViewAllClick(data)} />
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.sectionColumnTwo} >
                                    <AvatarStack title={"SERVICE COORDINATORS"} height={16} width={16}
                                        style={{ position: 'relative', top: 2 }}
                                        data={data?.service_coordinators}
                                        emptyMessage="Not Mapped"
                                        onAvatarClicked={(id) => this.onEndIconClickHandler(id, false)}
                                        onButtonClickHandler={() => this.handleServiceCoordinatorsViewAllClick(data)} />
                                </Grid>
                            </Grid>
                        </div>
                    </>
                )}

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    careSupervisorInfo: state.careSupervisor?.careSupervisorInfo,
    updateCareSupervisorStatus: state.careSupervisor?.updateCareSupervisorStatus,
    salutation: state?.masterModule?.getsal ?? {
        data: [],
        loading: false,
        error: false,
      },
});

export default connect(
    mapStateToProps,
    actions
)(withRouter(withAlelrtAndDialog(withStyles(style)(SupervisorDetail))))