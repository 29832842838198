import React from 'react'

import { withStyles, Grid, Avatar, Typography } from '@material-ui/core'
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import { StarEmptyRating, StarFilledRating } from '../../assets'

// import StarIcon from '@material-ui/icons/Star';
const styles = (theme) => ({
  bottomContainer: {
    padding: theme.spacing(3),
    borderTop: `1px solid #cdcdcd`,
  },
  firstBottomContainer: {
    padding: theme.spacing(3),
  },
  avatar: {
    height: 50,
    width: 50
  },
  starIcon: {
    color: theme.palette.ternary?.main,
  },
  content: {
    marginTop: 8
  }
})

class RatingsCard extends React.Component {
  render() {
    const { classes, index, review_description, review_title, reviewed_on, rating_out_of_five } = this.props
    return (
      <div className={index !== 0 ? `${classes.bottomContainer}` : `${classes.firstBottomContainer}`
      }>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar className={classes.avatar}></Avatar>
          </Grid>
          <Grid item xs={12} sm container spacing={2}>
            <Grid item xs={12} sm={9} md={10}>
              <Typography variant="body1">{review_title}</Typography>
              <div style={{ marginTop: 6, marginLeft: '-2px' }}>
                <Rating name="read-only" value={parseInt(rating_out_of_five)} readOnly className={classes.starIcon} icon={<StarFilledRating color={"#F27D35"} />} emptyIcon={<StarEmptyRating color={"#F27D35"} style={{ padding: 2 }} />}></Rating>
              </div>
              <Typography variant="body2" color="textSecondary" className={classes.content}>{review_description}</Typography>
            </Grid>
            <Grid item xs={12} sm>
              <Typography variant="subtitle2" align="right" color="secondary">{`${reviewed_on ? moment(reviewed_on, "DD-MM-YYYY kk:mm:ss").format('MMM DD, YYYY').toString() : 'None'}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </div >
    )
  }
}

export default withStyles(styles)(RatingsCard)