/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-02-18 12:49:24
 * @modify date 2022-02-18 12:49:24
 * @desc [description]
 */
import React from "react";
import LocationTrackerUsingDate from "./locationTrackerUsingDate";
import Timeline from "./timeline";
import styled from "styled-components";

import Grid from "@material-ui/core/Grid";

const Wrapper = styled.section`
  padding: 16px;
`;

function MapWithTimeline({
  loading,
  error,
  appointments,
  spEmittedData,
  selectedDate,
  onDateChange,
}) {
  const leftPaneRef = React.createRef(null);
  return (
    <Wrapper>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md>
          <LocationTrackerUsingDate
            ref={leftPaneRef}
            date={selectedDate}
            onDateChange={onDateChange}
            appointments={appointments}

            // Map Props
            spEmittedData={spEmittedData}
          />
        </Grid>
        <Grid item>
          <Timeline
            appointments={appointments}
            loading={loading}
            error={error}
            leftPaneRef={leftPaneRef}
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default MapWithTimeline;
