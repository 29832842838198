import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  FormHelperText,
  Grid,
  InputAdornment,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import {
  isNumberKey,
  isNumberDecimalAndNoE,
  isNumberKeyDown,
  checkScroll,
  // checkScrollEvent
} from "../../../utils/validations";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2)
    }
  },

  input: {
    borderRadius: 8,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: `${theme.form.borderSize} solid ${theme.palette.secondary.borderColor}`,
    fontSize: 16,
    width: "100%",
    padding: (props) => (props.padding ? props.padding : "10px 12px"),
    height: "20.4px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),

    "&:focus": {
      border: `${theme.form.borderSize} solid ${theme.palette.ternary.main}`
      // borderColor: theme.palette.primary.dark,
    }
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    "& .MuiInputBase-multiline": {
      padding: 0
    },
    // height:100
    "& .MuiInputLabel-asterisk": {
      color: theme.palette.error.main
    }
  },
  inputerror: {
    "& input": {
      border: `${theme.form.borderSize} solid ${theme.palette.error.light} `
    },
    "& textArea": {
      border: `${theme.form.borderSize} solid ${theme.palette.error.light} `
    }
  },

  margin: {
    margin: "8px 0px 8px 0px",
    // marginTop: 0,
    "& .MuiFormLabel-root": {
      color: theme.palette.text.label,
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: theme.palette.text.disabled,
    }
  },
  formHelperTextRoot: {
    textAlign: "right"
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem"
  },
}));

export function TextField({required, endAdornmentText, ...props}) {
  const classes = useStyles();
  const { overrideDecimalCheckForDiscount } = props;

  // function handleScroll(e){
  //   console.log(e)
  //   e.preventDefault()
  // }

 
  // const {helperFlagNeeded = false, helperFlagControllers = null} = props

  return (
    // <form className={classes.root} noValidate style={{background:'white'}}>

    <FormControl
      className={`${classes.margin} ${classes.root}`}
      // style={{ ...props.style }}
    >
      {props.title &&
        (props.withBoldLabel ? (
          <Typography variant="h6" style={{ marginBottom: 8 }}>
            {props.title}
          </Typography>
        ) : (
          <InputLabel shrink htmlFor="bootstrap-input" required={required} disabled={props?.disabled}>
            {props.withoutUppercase ? props.title : props.title.toUpperCase()}
          </InputLabel>
        ))}
      <BootstrapInput
        id={props.id}
        multiline={props.multiline}
        rows={props.rowsMax}
        // onKeyPress={props.handleOnEnter}
        onScroll={(event) => {checkScroll(event)}}
        // onWheel={(event) => {checkScroll(event)}}
        onWheel={(event) => {event.target.blur()}}
        onKeyPress={(event) => {
          !overrideDecimalCheckForDiscount &&
            isNumberKey(event, props.type === "number" ? true : false);
          overrideDecimalCheckForDiscount &&
            isNumberDecimalAndNoE(
              event,
              props.type === "number" ? true : false
            );
          // isTextKeyDown(event, props.onlyText? true : false)
          if (props.handleOnEnter) props.handleOnEnter(event);
        }}
        onKeyDown={(event) => {
          // isTextKeyDown(event, props.onlyText? true : false)
          isNumberKeyDown(event, props.type === "number" ? true : false);
        }}
        onInvalid={(e) => {
          e.preventDefault();
        }}
        
        onChange={(event) => {

            props.handleOnChange(event, props.limit)
        }}
        
        value={props.value}
        required
        type={props.type}
        placeholder={props.placeholder}
        error={true}
        className={props.error ? classes.inputerror : ""}
        disabled={props?.disabled}
        {...props}
        autoComplete={"no"}
        // inputProps={{autoComplete:'off'}}
        endAdornment={endAdornmentText ? <InputAdornment position="end">{endAdornmentText}</InputAdornment> : null}
      />
      <Grid
        container
        direction={props?.isLimitHelperTextNeeded ? "row-reverse" : "row"}
      >
        {props?.isLimitHelperTextNeeded && props?.limit && (
          <Grid item xs={12} sm={6}>
            <FormHelperText classes={{ root: classes.formHelperTextRoot }}>
              Approx. {props?.limit} characters
            </FormHelperText>
          </Grid>
        )}
        {props.titleHelper && (
          <Grid item xs>
            <FormHelperText>{props.titleHelper}</FormHelperText>
          </Grid>
        )}
        {props.error && (
          <Grid item xs={12} sm={10}>
            <Typography
              variant="body1"
              component="div"
              className={classes.errorText}
            >
              {props.errorText}
            </Typography>
          </Grid>
        )}
      </Grid>
    </FormControl>

    // </form>
  );
}

// Specifies the default values for props:
TextField.defaultProps = {
  type: "text",
  error: false,
  rowsMax: 1,
  multiline: false
};

// Specifies the required props for the component:
 TextField.propTypes = {
  style: PropTypes.object,
  multiline: PropTypes.bool,
  rowsMax: PropTypes.number,
  handleOnEnter: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string
}; 
