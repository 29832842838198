import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function OccupationIconV2(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.043 3a.626.626 0 100 1.251h.209v7.939A1.461 1.461 0 004 13.634v4.587a.625.625 0 00.626.626h5.421a.622.622 0 00.626-.626v-2.294h1.667v2.294a.626.626 0 00.626.626h.784a2.335 2.335 0 01-.159-.834V15.3a.626.626 0 00-.626-.626h-2.918a.626.626 0 00-.626.626v2.3h-4.17v-3.966a.206.206 0 01.209-.209h.417A.625.625 0 006.5 12.8V4.251h10.01v6.464a2.722 2.722 0 011.252-.201V4.251h.209A.626.626 0 1017.97 3H5.043zM8.17 5.919a.417.417 0 00-.417.417v.834a.417.417 0 00.417.417H9a.417.417 0 00.417-.417v-.834A.417.417 0 009 5.919h-.83zm2.919 0a.417.417 0 00-.417.417v.834a.417.417 0 00.417.417h.834a.417.417 0 00.417-.417v-.834a.417.417 0 00-.417-.417h-.834zm2.919 0a.417.417 0 00-.417.417v.834a.417.417 0 00.417.417h.834a.417.417 0 00.417-.417v-.834a.417.417 0 00-.417-.417h-.834zM8.17 8.838a.417.417 0 00-.417.417v.834a.417.417 0 00.417.417H9a.417.417 0 00.417-.417v-.834A.417.417 0 009 8.838h-.83zm2.919 0a.417.417 0 00-.417.417v.834a.417.417 0 00.417.417h.834a.417.417 0 00.417-.417v-.834a.417.417 0 00-.417-.417h-.834zm2.919 0a.417.417 0 00-.417.417v.834a.417.417 0 00.417.417h.834a.417.417 0 00.417-.417v-.834a.417.417 0 00-.417-.417h-.834zm3.545 2.5a1.877 1.877 0 100 3.754 1.877 1.877 0 000-3.754zm-9.383.417a.417.417 0 00-.417.417v.834a.417.417 0 00.417.417H9a.417.417 0 00.417-.417v-.834A.417.417 0 009 11.757l-.83-.002zm2.919 0a.417.417 0 00-.417.417v.834a.417.417 0 00.417.417h.834a.417.417 0 00.417-.417v-.834a.417.417 0 00-.417-.417h-.834zm2.919 0a.418.418 0 00-.417.417v.834a.418.418 0 00.417.417h.842c0-.067-.008-.138-.008-.209a2.7 2.7 0 01.329-1.293.41.41 0 00-.329-.163l-.834-.003zm-7.089 2.921a.417.417 0 00-.417.417v.834a.417.417 0 00.417.417h.834a.417.417 0 00.417-.417v-.834a.417.417 0 00-.417-.417h-.834zm8.757 1.251a1.25 1.25 0 00-1.251 1.251v.834c0 1.151 1.4 2.085 3.128 2.085 1.728 0 3.128-.934 3.128-2.085v-.834a1.252 1.252 0 00-1.251-1.251h-3.754z"
        fill="#0063E7"
      />
    </SvgIcon>
  );
}

export default OccupationIconV2;
