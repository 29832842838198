const webdatarockslocal = {
  fieldsList: {
    flatHierarchyBox: "Select and arrange columns",
    hierarchyBox: "Drag Dimensions",
    filterBox: "Drop & Arrange Report Filter",
    rowBox: "Drop & Arrange Rows",
    columnBox: "Drop & Arrange Columns",
    measureBox: "Drop & Arrange Values",
    values: "Values",
    addCalculatedMeasure: "Add calculated value",
    expandAll: "Expand All",
    collapseAll: "Collapse All",
    formulasGroupName: "Calculated Values",
    allFields: "All Fields",
    rows: "Rows",
    columns: "Columns",
    filters: "Report Filters",
    dropField: "Drop field here",
    title: "Fields",
    subtitle: "Drag and drop fields to arrange",
  },
  filter: {
    all: "All",
    multipleItems: "Multiple Items",
    selectAll: "Select All",
    selectAllResults: "Select All Results",
    top: "Top",
    bottom: "Bottom",
    ascSort: "Az",
    descSort: "zA",
    topX: "Top 10",
    clearTopX: "Clear",
    measuresPrompt: "Choose value",
    search: "Search",
    amountSelected: "{0} of {1} selected",
    amountFound: "{0} of {1} found are selected",
    sort: "Sort:",
    addGroup: "Add group",
    groupName: "Group 1",
    ungroup: "Ungroup",
  },
  drillThrough: {
    title: "Details",
    row: "Row: <b>{0}</b>",
    column: "Column: <b>{0}</b>",
    value: "{0}: <b>{1}</b>",
  },
  calculatedView: {
    title: "Calculated Value",
    measureBox: "Drag values to formula",
    measureName: "Value name",
    formula: "Formula",
    formulaPrompt: "Drop values and edit formula here",
    calculateIndividualValues: "Calculate individual values",
    removeValue: "Remove",
    removeValueTitle: "Remove {0}?",
    removeValueMessage:
      "Are you sure you want to remove this calculated value?",
    header: "Add calculated value",
    allValues: "All values",
  },
  grid: {
    total: "Total",
    totals: "Totals",
    grandTotal: "Grand Total",
    blankMember: "",
    dateInvalidCaption: "Invalid date",
    reportInformation: "Report Information",
  },
  tooltips: {
    row: "Row:",
    column: "Column:",
    headerResize: "Drag to resize",
    headerFit: "Double click to fit",
    filterIcon: "Click to filter",
    filtered: "Filtered",
    expandIcon: "Click to expand",
    collapseIcon: "Click to collapse",
    drillDown: "Click to drill down",
    drillUp: "Click to drill up",
    sortIcon: "Click to sort Desc",
    sortedDescIcon: "Click to sort Asc",
    sortedAscIcon: "Click to sort Desc",
    close: "Click to close",
  },
  aggregations: {
    sum: {
      caption: "Sum",
      totalCaption: "Sum of {0}",
      grandTotalCaption: "Total Sum of {0}",
    },
    count: {
      caption: "Count",
      totalCaption: "Count of {0}",
      grandTotalCaption: "Total Count of {0}",
    },
    distinctCount: {
      caption: "Distinct Count",
      totalCaption: "Distinct Count of {0}",
      grandTotalCaption: "Total Distinct Count of {0}",
    },
    difference: {
      caption: "Difference",
      totalCaption: "Difference of {0}",
      grandTotalCaption: "Difference of {0}",
    },
    percentDifference: {
      caption: "% Difference",
      totalCaption: "% Difference of {0}",
      grandTotalCaption: "% Difference of {0}",
    },
    average: {
      caption: "Average",
      totalCaption: "Average of {0}",
      grandTotalCaption: "Total Average of {0}",
    },
    product: {
      caption: "Product",
      totalCaption: "Product of {0}",
      grandTotalCaption: "Total Product of {0}",
    },
    min: {
      caption: "Min",
      totalCaption: "Min of {0}",
      grandTotalCaption: "Total Min of {0}",
    },
    max: {
      caption: "Max",
      totalCaption: "Max of {0}",
      grandTotalCaption: "Total Max of {0}",
    },
    percent: {
      caption: "% of Grand Total",
      totalCaption: "% of Grand Total of {0}",
      grandTotalCaption: "Total % of Grand Total of {0}",
    },
    percentOfColumn: {
      caption: "% of Column",
      totalCaption: "% of Column of {0}",
      grandTotalCaption: "Total % of Column of {0}",
    },
    percentOfRow: {
      caption: "% of Row",
      totalCaption: "% of Row of {0}",
      grandTotalCaption: "Total % of Row of {0}",
    },
    index: {
      caption: "Index",
      totalCaption: "Index of {0}",
      grandTotalCaption: "Index of {0}",
    },
    none: {
      caption: "No Calculation",
    },
  },
  messages: {
    error: "Error!",
    warning: "Warning!",
    limitation: "Limitation!",
    browse: "Browse",
    confirmation: "Confirmation",
    reportFileType: "Flexmonster report file",
    loading: "Loading...",
    loadingConfiguration: "",
    loadingData: "Loading data...",
    waiting: "Waiting for the response {0} sec.",
    progress: "{0}K",
    progressUnknown: "loaded {0}K",
    analyzing: "Analyzing data...",
    analyzingProgress: "{0} records of {1} ({2}%)",
    analyzingRecords: "{0}% records",
    saving: "Saving...",
    loadingDimensions: "Loading dimensions...",
    loadingHierarchies: "Loading hierarchies...",
    loadingMeasures: "Loading measures...",
    loadingKPIs: "Loading KPIs...",
    loadingMembers: "Loading members...",
    loadingLevels: "Loading levels...",
    loadingProperties: "Loading properties...",
    fullscreen: "Open report in full screen?",
    exportComplete:
      'Export data is created, please click "Save" button to save the _data.',
    exportProgress: "Export in progress...",
    exportError: "Failed to export. An unexpected error occurred.",
    generatingPDF: "Generating PDF",
    pleaseWait: "Please wait.",
    pagesWereGenerated: "pages were generated.",
    uploading: "Uploading...",
    cantSaveFile: "Cannot save file.",
    cantSaveToClipboard: "Error: Cannot write to the clipboard.",
    saveReportToFile:
      'Report is ready to be saved to file, please click "Save" button to save the report.',
    loadReportFromFile: "Select report file to be loaded.",
    inputNewName: "Input new name",
    inputReportName: "Please input report name",
    invalidDataSource:
      "Invalid datasource or catalog. Please check. <br/><br/><u><a href='https://www.flexmonster.com/doc/typical-errors/#invalid-datasource' target='_blank'>Read more info about this error</a></u>",
    dataStreamError:
      "Stream error occurred while loading '{0}'<br/><br/><u><a href='https://www.flexmonster.com/doc/typical-errors/#stream-error' target='_blank'>Read more info about this error</a></u>",
    unableToOpenFile:
      "Unable to open file {0}.<br/><br/>It seems that this file doesn't exist or 'Access-Control-Allow-Origin' header is absent in the resource requested.<br/><br/><u><a href='https://www.flexmonster.com/doc/typical-errors/#unable-to-open-file' target='_blank'>Read more info about this error</a></u>",
    unableTwoFileBrowsingSessions: "Browse file dialog is already opened.",
    inappropriateFileFormat: "The data file is of inappropriate format.",
    invalidJSONdata: "JSON data is invalid.",
    wrongFormulaFormat: "Wrong formula format. Please check.",
    excelCsvChartsExportError:
      "Export to Microsoft Excel or CSV is not available for charts.",
    excelPdfExportLimitation:
      "Export to Microsoft Excel or PDF is not available in the current edition.",
    excelExportLimitation: "Export is not available in the current edition.",
    noDataAvailable: "Data source is empty. Please check the CSV file.",
    saveDataToFile:
      'Data is ready to be saved to file, please click "Save" button to save the file.',
    dataWasUpdated:
      "Data source has been updated on the server. Refresh the report?",
    ocsvIncompatible:
      "Unable to read data source. It seems that OCSV file was compressed with a newer version. Please update the component to version {0} or newer.",
    unknownError: "Unknown error occurred.",
    invalidReportFormat: "Invalid report format or access to file is denied.",
    csvHeaderParsingError: "CSV header parsing error.",
    tooManyColumnsInClassicMode:
      "Too many columns for classic form. Switched layout to compact form.",
    cantExpand:
      "Some fields cannot be expanded. Please narrow down the data set.",
    cantExpandTitle: "Data set is too large",
  },
  buttons: {
    ok: "OK",
    apply: "Apply",
    cancel: "Cancel",
    save: "Save",
    clear: "Clear",
    select: "Select",
    yes: "Yes",
    no: "No",
  },
  contextMenu: {
    clearSorting: "Clear sorting",
    collapse: "Collapse",
    drillThrough: "Drill through",
    expand: "Expand",
    openFilter: "Open filter",
    sortColumnAsc: "Sort column asc",
    sortColumnDesc: "Sort column desc",
    sortRowAsc: "Sort row asc",
    sortRowDesc: "Sort row desc",
  },
  date: {
    year: "Year",
    quarter: "Quarter",
    month: "Month",
    day: "Day",
  },
  quarters: {
    q1: "Quarter 1",
    q2: "Quarter 2",
    q3: "Quarter 3",
    q4: "Quarter 4",
  },
  months: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  monthsShort: {
    january: "Jan",
    february: "Feb",
    march: "Mar",
    april: "Apr",
    may: "May",
    june: "Jun",
    july: "Jul",
    august: "Aug",
    september: "Sep",
    october: "Oct",
    november: "Nov",
    december: "Dec",
  },
  weekdays: {
    first: "Sunday",
    second: "Monday",
    third: "Tuesday",
    fourth: "Wednesday",
    fifth: "Thursday",
    sixth: "Friday",
    seventh: "Saturday",
  },
  weekdaysShort: {
    first: "Sun",
    second: "Mon",
    third: "Tue",
    fourth: "Wed",
    fifth: "Thu",
    sixth: "Fri",
    seventh: "Sat",
  },
  toolbar: {
    connect: "Connect",
    connect_local_csv: "To local CSV",
    connect_local_ocsv: "To local OCSV",
    connect_local_json: "To local JSON",
    connect_remote_csv: "To remote CSV",
    connect_remote_csv_mobile: "CSV",
    connect_remote_json: "To remote JSON",
    connect_remote_json_mobile: "JSON",
    open: "Open",
    local_report: "Local report",
    remote_report: "Remote report",
    remote_report_mobile: "Report",
    save: "Save",
    save_json: null,
    load_json: "JSON report",
    grid: "Grid",
    grid_flat: "Flat",
    grid_classic: "Classic",
    grid_compact: "Compact",
    format: "Format",
    format_cells: "Format cells",
    format_cells_mobile: "Format",
    conditional_formatting: "Conditional formatting",
    conditional_formatting_mobile: "Conditional",
    options: "Options",
    fullscreen: "Fullscreen",
    minimize: "Minimize",
    export: "Export",
    export_print: "Print",
    export_html: "To HTML",
    export_excel: "To Excel",
    export_pdf: "To PDF",
    fields: "Fields",
    ok: "OK",
    apply: "Apply",
    done: "Done",
    cancel: "Cancel",
    value: "Value",
    delete: "Delete",
    if: "If",
    then: "Then",
    open_remote_csv: "Open remote CSV",
    open_remote_json: "Open remote JSON",
    csv: "CSV",
    open_remote_report: "Open remote report",
    choose_value: "Choose value",
    text_align: "Text align",
    align_left: "left",
    align_right: "right",
    none: "None",
    space: "(Space)",
    thousand_separator: "Thousand separator",
    decimal_separator: "Decimal separator",
    decimal_places: "Decimal places",
    currency_symbol: "Currency symbol",
    currency_align: "Currency align",
    null_value: "Null value",
    is_percent: "Format as percent",
    true_value: "true",
    false_value: "false",
    conditional: "Conditional",
    add_condition: "Add condition",
    less_than: "Less than",
    less_than_or_equal: "Less than or equal to",
    greater_than: "Greater than",
    greater_than_or_equal: "Greater than or equal to",
    equal_to: "Equal to",
    not_equal_to: "Not equal to",
    between: "Between",
    is_empty: "Empty",
    all_values: "All values",
    and: "and",
    and_symbole: "&",
    cp_text: "Text",
    cp_highlight: "Highlight",
    layout_options: "Layout options",
    layout: "Layout",
    compact_view: "Compact form",
    classic_view: "Classic form",
    flat_view: "Flat form",
    grand_totals: "Grand totals",
    grand_totals_off: "Do not show grand totals",
    grand_totals_on: "Show grand totals",
    grand_totals_on_rows: "Show for rows only",
    grand_totals_on_columns: "Show for columns only",
    subtotals: "Subtotals",
    subtotals_off: "Do not show subtotals",
    subtotals_on: "Show subtotals",
    subtotals_on_rows: "Show subtotal rows only",
    subtotals_on_columns: "Show subtotal columns only",
    choose_page_orientation: "Choose page orientation",
    landscape: "Landscape",
    portrait: "Portrait",
  },
};
export default webdatarockslocal;
