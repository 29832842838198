import moment from 'moment';

export const dateTime = [
  { id: '12-AM', label: '12:00 AM', startTime: moment('12:00 AM', 'HH:mm A'), endTime: moment('12:30 AM', 'HH:mm A') },
  { id: '12-AM', label: '12:30 AM', startTime: moment('12:30 AM', 'HH:mm A'), endTime: moment('01:00 AM', 'HH:mm A') },
  { id: '01-AM', label: '01:00 AM', startTime: moment('01:00 AM', 'HH:mm A'), endTime: moment('01:30 AM', 'HH:mm A') },
  { id: '01-AM', label: '01:30 AM', startTime: moment('01:30 AM', 'HH:mm A'), endTime: moment('02:00 AM', 'HH:mm A') },
  { id: '02-AM', label: '02:00 AM', startTime: moment('02:00 AM', 'HH:mm A'), endTime: moment('02:30 AM', 'HH:mm A') },
  { id: '02-AM', label: '02:30 AM', startTime: moment('02:30 AM', 'HH:mm A'), endTime: moment('03:00 AM', 'HH:mm A') },
  { id: '03-AM', label: '03:00 AM', startTime: moment('03:00 AM', 'HH:mm A'), endTime: moment('03:30 AM', 'HH:mm A') },
  { id: '03-AM', label: '03:30 AM', startTime: moment('03:30 AM', 'HH:mm A'), endTime: moment('04:00 AM', 'HH:mm A') },
  { id: '04-AM', label: '04:00 AM', startTime: moment('04:00 AM', 'HH:mm A'), endTime: moment('04:30 AM', 'HH:mm A') },
  { id: '04-AM', label: '04:30 AM', startTime: moment('04:30 AM', 'HH:mm A'), endTime: moment('05:00 AM', 'HH:mm A') },
  { id: '05-AM', label: '05:00 AM', startTime: moment('05:00 AM', 'HH:mm A'), endTime: moment('05:30 AM', 'HH:mm A') },
  { id: '05-AM', label: '05:30 AM', startTime: moment('05:30 AM', 'HH:mm A'), endTime: moment('06:00 AM', 'HH:mm A') },
  { id: '06-AM', label: '06:00 AM', startTime: moment('06:00 AM', 'HH:mm A'), endTime: moment('06:30 AM', 'HH:mm A') },
  { id: '06-AM', label: '06:30 AM', startTime: moment('06:30 AM', 'HH:mm A'), endTime: moment('07:00 AM', 'HH:mm A') },
  { id: '07-AM', label: '07:00 AM', startTime: moment('07:00 AM', 'HH:mm A'), endTime: moment('07:30 AM', 'HH:mm A') },
  { id: '07-AM', label: '07:30 AM', startTime: moment('07:30 AM', 'HH:mm A'), endTime: moment('08:00 AM', 'HH:mm A') },
  { id: '08-AM', label: '08:00 AM', startTime: moment('08:00 AM', 'HH:mm A'), endTime: moment('08:30 AM', 'HH:mm A') },
  { id: '08-AM', label: '08:30 AM', startTime: moment('08:30 AM', 'HH:mm A'), endTime: moment('09:00 AM', 'HH:mm A') },
  { id: '09-AM', label: '09:00 AM', startTime: moment('09:00 AM', 'HH:mm A'), endTime: moment('09:30 AM', 'HH:mm A') },
  { id: '09-AM', label: '09:30 AM', startTime: moment('09:30 AM', 'HH:mm A'), endTime: moment('10:00 AM', 'HH:mm A') },
  { id: '10-AM', label: '10:00 AM', startTime: moment('10:00 AM', 'HH:mm A'), endTime: moment('10:30 AM', 'HH:mm A') },
  { id: '10-AM', label: '10:30 AM', startTime: moment('10:30 AM', 'HH:mm A'), endTime: moment('11:00 AM', 'HH:mm A') },
  { id: '11-AM', label: '11:00 AM', startTime: moment('11:00 AM', 'HH:mm A'), endTime: moment('11:30 AM', 'HH:mm A') },
  { id: '11-AM', label: '11:30 AM', startTime: moment('11:30 AM', 'HH:mm A'), endTime: moment('12:00 PM', 'HH:mm A') },
  { id: '12-PM', label: '12:00 PM', startTime: moment('12:00 PM', 'HH:mm A'), endTime: moment('12:30 PM', 'HH:mm A') },
  { id: '12-PM', label: '12:30 PM', startTime: moment('12:30 PM', 'HH:mm A'), endTime: moment('01:00 PM', 'HH:mm A') },
  { id: '01-PM', label: '01:00 PM', startTime: moment('01:00 PM', 'HH:mm A'), endTime: moment('01:30 PM', 'HH:mm A') },
  { id: '01-PM', label: '01:30 PM', startTime: moment('01:30 PM', 'HH:mm A'), endTime: moment('02:00 PM', 'HH:mm A') },
  { id: '02-PM', label: '02:00 PM', startTime: moment('02:00 PM', 'HH:mm A'), endTime: moment('02:30 PM', 'HH:mm A') },
  { id: '02-PM', label: '02:30 PM', startTime: moment('02:30 PM', 'HH:mm A'), endTime: moment('03:00 PM', 'HH:mm A') },
  { id: '03-PM', label: '03:00 PM', startTime: moment('03:00 PM', 'HH:mm A'), endTime: moment('03:30 PM', 'HH:mm A') },
  { id: '03-PM', label: '03:30 PM', startTime: moment('03:30 PM', 'HH:mm A'), endTime: moment('04:00 PM', 'HH:mm A') },
  { id: '04-PM', label: '04:00 PM', startTime: moment('04:00 PM', 'HH:mm A'), endTime: moment('04:30 PM', 'HH:mm A') },
  { id: '04-PM', label: '04:30 PM', startTime: moment('04:30 PM', 'HH:mm A'), endTime: moment('05:00 PM', 'HH:mm A') },
  { id: '05-PM', label: '05:00 PM', startTime: moment('05:00 PM', 'HH:mm A'), endTime: moment('05:30 PM', 'HH:mm A') },
  { id: '05-PM', label: '05:30 PM', startTime: moment('05:30 PM', 'HH:mm A'), endTime: moment('06:00 PM', 'HH:mm A') },
  { id: '06-PM', label: '06:00 PM', startTime: moment('06:00 PM', 'HH:mm A'), endTime: moment('06:30 PM', 'HH:mm A') },
  { id: '06-PM', label: '06:30 PM', startTime: moment('06:30 PM', 'HH:mm A'), endTime: moment('07:00 PM', 'HH:mm A') },
  { id: '07-PM', label: '07:00 PM', startTime: moment('07:00 PM', 'HH:mm A'), endTime: moment('07:30 PM', 'HH:mm A') },
  { id: '07-PM', label: '07:30 PM', startTime: moment('07:30 PM', 'HH:mm A'), endTime: moment('08:00 PM', 'HH:mm A') },
  { id: '08-PM', label: '08:00 PM', startTime: moment('08:00 PM', 'HH:mm A'), endTime: moment('08:30 PM', 'HH:mm A') },
  { id: '08-PM', label: '08:30 PM', startTime: moment('08:30 PM', 'HH:mm A'), endTime: moment('09:00 PM', 'HH:mm A') },
  { id: '09-PM', label: '09:00 PM', startTime: moment('09:00 PM', 'HH:mm A'), endTime: moment('09:30 PM', 'HH:mm A') },
  { id: '09-PM', label: '09:30 PM', startTime: moment('09:30 PM', 'HH:mm A'), endTime: moment('10:00 PM', 'HH:mm A') },
  { id: '10-PM', label: '10:00 PM', startTime: moment('10:00 PM', 'HH:mm A'), endTime: moment('10:30 PM', 'HH:mm A') },
  { id: '10-PM', label: '10:30 PM', startTime: moment('10:30 PM', 'HH:mm A'), endTime: moment('11:00 PM', 'HH:mm A') },
  { id: '11-PM', label: '11:00 PM', startTime: moment('11:00 PM', 'HH:mm A'), endTime: moment('11:30 PM', 'HH:mm A') },
  { id: '11-PM', label: '11:30 PM', startTime: moment('11:30 PM', 'HH:mm A'), endTime: moment('12:00 AM', 'HH:mm A') },
];


export const monthHeader = [
  {
    value: 1,
    label: 'MON'
  },
  {
    value: 2,
    label: 'TUE'
  },
  {
    value: 3,
    label: 'WED'
  },
  {
    value: 4,
    label: 'THU'
  },
  {
    value: 5,
    label: 'FRI'
  },
  {
    value: 6,
    label: 'SAT'
  },
  {
    value: 0,
    label: 'SUN'
  }
]