import React from "react";
import { SideNavbar, TopNavbar } from "../components";
import { Hidden, makeStyles } from "@material-ui/core";
import { appBarHeight } from "../components/navBars/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    width: '100%',
    [theme.breakpoints.up("md")]: {
      paddingTop: appBarHeight,
      width: "calc(100% - 240px)",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: props => props?.navBarArr?.length ? appBarHeight + 48 : appBarHeight,
    }
  },
}));


const withNavBars = (Component) => (props) => {
  const classes = useStyles({ navBarArr: props.navBarArr });

  const TopNavBarProps = {
    title: props.title,
    withBackButton: props.withBackButton,
    navBarArr: props.navBarArr,
    isAvoidGoBack: props.isAvoidGoBack,
    backUrl: props.backUrl
  };

  const SideNavBarProps = {};

  return (
    <div className={classes.root}>
      {/* Top Navigation bar */}
      <TopNavbar {...TopNavBarProps} SideNavBarProps />

      {/* Side Navigation bar */}
      <Hidden smDown>
        <SideNavbar {...SideNavBarProps} />
      </Hidden>

      {/* Content */}
      <div className={classes.content}>
        <Component {...props}>{props.children}</Component>
      </div>
    </div>
  );
};

export default withNavBars;
