import React, { Component } from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import { SimpleSelect } from "../inputs";
import { errorMessage } from "../../utils/errorMessages";
import AddressComponent from "./addressComponent";
import EmailComponent from "./emailComponent";
import PhoneComponent from "./phoneComponent";
import PropTypes from "prop-types";
import { getCountryCodeOptions, SelectComponentWithSubComponentJSON } from "../../utils";
import IdTypeComponent from "./idTypeComponent";

class SelectComponentWithSubComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      data: [],
    };
  }

  createData = (newData, oldData, type) => {
    
    let updatedArray = [];
    let removedObject = [];
    let addedObject = newData;
    if (oldData?.length > 0 && newData?.length > 0) {
      removedObject = oldData.filter((x) => !newData.includes(x));
      addedObject = newData.filter((x) => !oldData.includes(x));
    } else {
      removedObject = [];
    }
    if (removedObject?.length > 0) {
      updatedArray = newData;
    } else {
      if (addedObject?.length > 0) {
        updatedArray = addedObject[0];
        updatedArray = [
          {
            ...updatedArray,
            ...SelectComponentWithSubComponentJSON[type],
            isInitial: true,
          },
          ...oldData,
        ];
      }
    }

    return updatedArray;
  };

  updateData = (e, key, index, error, type, allData) => {
    let {data} = this.props;
    
    let newObj = {...data[index]}
    newObj[key] = e.target.value;
    newObj['isInitial'] = false
    data = [{...newObj}]
    // data[index][key] = e.target.value;
    // data[index]["isInitial"] = false;
    this.props.handleOnChangeInput(data, type);
  };

  

  renderSubComponent = (type, subData, error, handleOnChangeInput, index) => {
    switch (type) {
      case "address":
        return (
          <Grid container style={{ padding: "10px 0px" }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
                {subData?.label}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AddressComponent
                type={type}
                data={subData}
                error={error} 
                handleOnChangeInput={handleOnChangeInput}
                index={index}
              />
            </Grid>
          </Grid>
        );
        case "email":
          return (
            <Grid container style={{ padding: "10px 0px" }}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
                  {subData?.label}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <EmailComponent
                  type={type}
                  data={subData}
                  error={error}
                  handleOnChangeInput={handleOnChangeInput}
                  index={index}
                />
              </Grid>
            </Grid>
          );
          case "phone":
        return (
          <Grid container style={{ padding: "10px 0px" }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
                {subData?.label}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PhoneComponent
                type={type}
                data={subData}
                error={error}
                handleOnChangeInput={handleOnChangeInput}
                index={index}
                options={getCountryCodeOptions()}
              />
            </Grid>
          </Grid> 
        );
        case "idType":
          return (
            <Grid container style={{ padding: "10px 0px" }}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
                  {subData?.label}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <IdTypeComponent
                  type={type}
                  data={subData}
                  error={error}
                  handleOnChangeInput={handleOnChangeInput}
                  index={index}
                  options={getCountryCodeOptions()}
                />
              </Grid>
            </Grid>
          );
      default:
        return <div>N/A</div>;
    }
  };

  render() {
    const {
      data,
      error,
      type,
      selectBoxLabel,
      selectBoxKey,
      options,
      handleChangeSelect,
    } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} style={{marginBottom:10}}>
          <SimpleSelect
            label={selectBoxLabel}
            // isMulti={true}
            id={selectBoxKey}
            // options={this.state.allCategoryLists}
            options={options}
            value={data}
            handleOnChange={(e) => {
              handleChangeSelect(this.createData(e, data, type), selectBoxKey);
            }}
            error={false}
            errorText={errorMessage.requiredMessage}
          />
        </Grid>
        {data?.length > 0 && (
          <Grid
            item
            xs={12}
            style={{
              boxShadow:
                "0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            {data.map((subData, index) => {
              return (
                <>
                  {this.renderSubComponent(
                    type,
                    subData,
                    error,
                    this.updateData,
                    index
                  )}
                  {data.length-1 !== index && <Divider style={{margin:"10px 0px"}}/>}
                </>
              );
            })}
          </Grid>
        )}
      </Grid>
    );
  }
}
SelectComponentWithSubComponent.propTypes = {
  type: PropTypes.string,
  data: PropTypes.array,
  error: PropTypes.array,
  selectBoxLabel: PropTypes.string,
  selectBoxKey: PropTypes.string,
  options: PropTypes.array,
  handleChangeSelect: PropTypes.func,
};
export default SelectComponentWithSubComponent;
