import React from 'react'
import { Grid, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Typography, IconButton, Button, withStyles } from '@material-ui/core'
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { FILE_URI } from '../../utils/constants';
import withAlelrtAndDialog from '../../HOCs/withAlelrtAndDialog';
import { PreviewPngIcon } from '../../assets';
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";

const styles = ((theme) => ({
    iconSize: {
        fontSize: "1.05rem"
    },
    listItemSecondaryAction: {
        paddingRight: 90
    },
    viewImageButton: {
        color: theme.palette.ternary.main,
        boxShadow: "unset"
    },
    imageStyle: {
        [theme.breakpoints.down("md")]: {
            width: 320,
            height: 250,
        },
        [theme.breakpoints.up("md")]: {
            width: "100%",
            height: "100%",
            objectFit: "cover"
        },
    },
}))

class IdView extends React.Component {
    state = {
        previewImage: null,
        imageSrcError: false
    }

    componentDidMount() {
        this.renderPreviewImage()
    }

    renderPreviewImage = () => {
        const { data } = this.props
        if (data?.id_photo) {
            this.setState({
                previewImage: `${FILE_URI}/${data?.id_photo}`
            })
        }
    }

    handleImagePreviewDialog = (e, item, image) => {
        e.stopPropagation();
        const { classes } = this.props

        // Display the content in a model
        this.props.dialog.setDialog({
            ...this.props.dialog,
            open: true,
            title: item?.label,
            body: <div style={{ paddingTop: 16 }}>
                <img src={image} className={classes.imageStyle} alt={`Failed to show document.`} />
            </div>,
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            dontShowButtons: true,
            onOk: () => { },
            onCancel: () => {
                this.props.dialog.setDialog({
                    ...this.props.dialog,
                    open: false,
                    title: ``,
                    body: "",
                    positiveBtn: "Ok",
                    negativeBtn: "CANCEL",
                    dontShowButtons: false,
                });
            },
        });
    }

    render() {
        const { data: item, classes, isChildItem, isAccordionOpen, isMoreThanOne } = this.props
        const { previewImage, imageSrcError } = this.state
        return (
            <>
                <Grid container>
                    <Grid item xs={12}>
                        {!isChildItem && <Grid item container>
                            <Grid item style={{ display: "flex", alignItems: "center" }}>
                                <PermIdentityIcon fontSize={"small"} htmlColor={"#28a9c1"} classes={{ fontSizeSmall: classes.iconSize }} />
                            </Grid>
                            <Grid item xs>
                                <Typography style={{ color: "rgba(146, 151, 165, 1)", fontSize: 13, marginLeft: 8, textTranform: "uppercase" }} >ID Type</Typography>
                                {/* <InputLabel shrink>{this.getTitle(element)}</InputLabel> */}
                            </Grid>
                        </Grid>}

                        <Grid item container>

                            {!isChildItem && <Grid item>
                                <PermIdentityIcon fontSize={"small"} htmlColor={"#28a9c1"} classes={{ fontSizeSmall: classes.fontSize }} style={{ visibility: "hidden" }} />
                            </Grid>}
                            <Grid item xs>
                                <div style={{ display: "flex", marginLeft: 8 }}>
                                    <List dense={true} disablePadding style={{ width: "100%" }}>
                                        <ListItem style={{ paddingLeft: 0 }} divider classes={{ secondaryAction: classes.listItemSecondaryAction }}>
                                            {/* <ListItemIcon style={{ justifyContent: "center" }}>
                                                <PhoneIcon />
                                            </ListItemIcon> */}
                                            <ListItemIcon style={{ justifyContent: "flex-start" }}>
                                                {/* <PreviewPngIcon style={{ visibility: Boolean(item?.id_photo) ? "unset" : "hidden" }} /> */}

                                                {imageSrcError && <PreviewPngIcon style={{ visibility: Boolean(item?.id_photo) ? "unset" : "hidden" }} />}
                                                {previewImage && !imageSrcError && <img src={previewImage} alt={"Failed to show preview"}
                                                    onError={() => {
                                                        this.setState({
                                                            imageSrcError: true
                                                        })
                                                    }}
                                                    style={{
                                                        visibility: Boolean(item?.id_photo) ? "unset" : "hidden",
                                                        height: "34px",
                                                        width: "34px",
                                                        borderRadius: 4,
                                                        objectFit: "cover",
                                                        cursor: "pointer"
                                                    }}
                                                />}
                                            </ListItemIcon>

                                            <ListItemText
                                                primary={item?.label}
                                                secondary={item?.id_type}
                                            />
                                            <ListItemSecondaryAction>
                                                <div style={{ display: "flex", gap: 16 }}>
                                                    {!isChildItem && isMoreThanOne && <IconButton edge="end" aria-label="edit" size={"small"}>
                                                        {/* <EditOutlinedIcon fontSize={"small"} /> */}
                                                        {isAccordionOpen && <ExpandLessRoundedIcon />}
                                                        {!isAccordionOpen && <ExpandMoreRoundedIcon />}
                                                    </IconButton>}
                                                    <Button onClick={(e) => this.handleImagePreviewDialog(e, item, previewImage)} className={classes.viewImageButton} aria-label="view_image" size={"small"}>
                                                        View
                                                    </Button>
                                                </div>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </>
        )
    }
}

export default withAlelrtAndDialog(withStyles(styles)(IdView))
