import {
  Divider,
  FormControl,
  Grid,
  // IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  Button,
} from "@material-ui/core";

import React, { Component } from "react";
import { Avatar, makeStyles, useTheme } from "@material-ui/core";
import { avatarColorBgById } from "../../utils";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  fontStyleClass,
  commonShadows,
  genericStyles,
  textClampStyleGenerator,
} from "../../utils";
// import moment from "moment";
import { StyledTableRow } from "./styledTableRow";
import { formatDate,createTestProps } from "../../utils/common";
// import { CustomAvatar } from "../information/infoWrapper";
// import { NotFoundSection } from "../integerateHelper";

const columns = [
  // {
  //   id: "request",
  //   label: "Request",
  //   align: "left",
  //   minWidth: 25,
  //   isButtonNeeded: false,
  // },
  {
    id: "request_type",
    label: "Type of Request",
    align: "left",
    minWidth: 25,
    isButtonNeeded: false,
  },
  {
    id: "professional_name",
    label: "Requested By",
    align: "left",
    minWidth: 50,
    isButtonNeeded: false,
  },
  // {
  //   id: "activity",
  //   label: "Appointment Title",
  //   minWidth: 120,
  //   align: "left",
  //   isButtonNeeded: true,
  //   button_id: "sortEmailButtonAdministration",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  // {
  //   id: "from_date",
  //   label: "From Date & Time",
  //   minWidth: 120,
  //   align: "left",
  //   isButtonNeeded: true,
  //   button_id: "sortRoleButtonAdministration",
  //   format: (value) => moment(value).format("Do MMMM YYYY hh:mm"),
  // },
  // {
  //   id: "to_date",
  //   label: "To Date & Time",
  //   minWidth: 70,
  //   align: "center",
  //   isButtonNeeded: true,
  //   button_id: "sortStatusButtonAdministration",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "date_time",
    label: "Requested Date",
    align: "left",
    minWidth: 100,
    isButtonNeeded: true,
    button_id: "sortDateCreatedButtonAdministration",
  },
  {
    id: "reason",
    label: "Reason",
    align: "left",
    minWidth: 50,
    isButtonNeeded: false,
  },
  // {
  //   id: "client_name",
  //   label: `Client Name`,
  //   align: "left",
  //   minWidth: 120,
  //   isButtonNeeded: true,
  //   button_id: "sortUserNameButtonAdministration",
  // },
  {
    id: "action",
    label: "Action",
    minWidth: 140,
    align: "left",
    isButtonNeeded: false,
    format: (value) => value.toFixed(2),
  },
];
const businessadmin_columns = [
  // {
  //   id: "request",
  //   label: "Request",
  //   align: "left",
  //   minWidth: 25,
  //   isButtonNeeded: false,
  // },
  // {
  //   id: "request_type",
  //   label: "Type of Request",
  //   align: "left",
  //   minWidth: 25,
  //   isButtonNeeded: false,
  // },
  {
    id: "professional_name",
    label: "Requested By",
    align: "left",
    minWidth: 50,
    isButtonNeeded: false,
  },
  // {
  //   id: "activity",
  //   label: "Appointment Title",
  //   minWidth: 120,
  //   align: "left",
  //   isButtonNeeded: true,
  //   button_id: "sortEmailButtonAdministration",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  // {
  //   id: "from_date",
  //   label: "From Date & Time",
  //   minWidth: 120,
  //   align: "left",
  //   isButtonNeeded: true,
  //   button_id: "sortRoleButtonAdministration",
  //   format: (value) => moment(value).format("Do MMMM YYYY hh:mm"),
  // },
  // {
  //   id: "to_date",
  //   label: "To Date & Time",
  //   minWidth: 70,
  //   align: "center",
  //   isButtonNeeded: true,
  //   button_id: "sortStatusButtonAdministration",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "date_time",
    label: "Requested Date",
    align: "left",
    minWidth: 100,
    isButtonNeeded: true,
    button_id: "sortDateCreatedButtonAdministration",
  },
  {
    id: "reason",
    label: "Reason",
    align: "left",
    minWidth: 50,
    isButtonNeeded: false,
  },
  // {
  //   id: "client_name",
  //   label: `Client Name`,
  //   align: "left",
  //   minWidth: 120,
  //   isButtonNeeded: true,
  //   button_id: "sortUserNameButtonAdministration",
  // },
  {
    id: "action",
    label: "Action",
    minWidth: 140,
    align: "left",
    isButtonNeeded: false,
    format: (value) => value.toFixed(2),
  },
];

const filter_options = [
  {
    label: "All",
    value: "All",
    id: "All",
  },
  {
    label: "Cancellation",
    value: "Cancel",
    id: "Cancel",
  },
  {
    label: "Reschedule",
    value: "Reschedule",
    id: "Reschedule",
  },
  {
    label: "Refund Request",
    value: "Subscription Cancel",
    id: "Subscription Cancel",
  },
];

const style = (theme) => ({
  rowOneIcons: {
    border: "1px solid #E8E8EC",
    margin: "0px 4px",
    padding: 8,
    width: 46,
  },
  customTableStickyHeader: {
    backgroundColor: "white",
  },
  typereason: {
    ...textClampStyleGenerator(),
  },
  formControl: {
    margin: "3px 8px",
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.ternary.main} !important`,
        borderWidth: "1.6px !important",
      },
    },
  },
  ...genericStyles.cardWrapperShadows(theme),
  ...commonShadows,
  ...fontStyleClass,
});

const customAvatarStyle = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(0, 1),
    margin: "5px 8px",
  },
  avatarSize: {
    width: 30,
    height: 30,
  },
  skeleton: {
    margin: theme.spacing(0, 1),
  },
}));

// const StyledTableRow = withStyles((theme) => ({
//   root: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
// }))(TableRow);

export function CustomAvatar({ src, name, id, loading = false }) {
  const classes = customAvatarStyle();
  const theme = useTheme();
  const fallbackText = name?.toString()?.toUpperCase()?.trim()?.[0];
  const fallbackId =
    id?.[id?.toString()?.search(/\d/)] *
    id?.[id?.toString().split("").reverse().join("")?.search(/\d/)];

  return (
    <>
      {loading && (
        <Skeleton
          variant="circle"
          className={classes.skeleton}
          width={theme.spacing(6.25)}
          height={theme.spacing(6.25)}
        />
      )}
      {!loading && (
        <Avatar
          src={src}
          className={`${classes.root} ${classes.avatarSize}`}
          style={{
            backgroundColor: !!!src ? avatarColorBgById(fallbackId) : "unset",
          }}
        >
          {fallbackText}
        </Avatar>
      )}
    </>
  );
}

class DashboardPendngRequestListView extends Component {
  handleChange = (event) => {
    //   console.log(event.target.value);
    // let data = event.target.value === "cancel_request" ? "Cancellation" : event.target.value === "All" ? "" : "Reschedule";
    this.props.changeRequest(event.target.value, event);
  };

  createRowData(x, index, onResendClick, page) {
    let picture = `${process.env.REACT_APP_PROTOCAL}://${process.env.REACT_APP_FILE_HOST}/${process.env.REACT_APP_FILE_PORT_DB_NAME}/${x.profile_pic}`;
    console.log(this.props);
    let res =  {
      // type: "",
      request_type: x?.requestChange,
      professional_name: (
        <Grid container justify="flex-start">
          <Grid item>
            <CustomAvatar
              src={x?.profile_pic ? picture : x?.profile_pic}
              name={x.requestedBy ? x.requestedBy : "N/A"}
              id={x?.id}
            />
          </Grid>
          <Grid item>
            <Grid container direction="column" style={{ marginRight: 12 }}>
              <Typography
                item
                xs={12}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 130,
                  whiteSpace: "nowrap",
                }}
              >
                {x.requestedBy ? x.requestedBy : "N/A"}
              </Typography>
              <Typography item xs={12} variant="caption" color="textSecondary">
                {x.requestChange === "Refund Request"
                  ? "Client"
                  : "Service Professional"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ),
      // activity: <p style={{ "overflow": "hidden", "textOverflow": "ellipsis", "width": 150, "whiteSpace":"nowrap"}}>{x.appointmentTitle}</p>,
      // date_time: moment(x.from_date).format("Do MMM YYYY hh:mm"),
      date_time: x?.requested_time ? formatDate(x?.requested_time, true) : "-",
      reason: (
        <p
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: 130,
            whiteSpace: "nowrap",
          }}
        >
          {" "}
          {x?.reason ? x?.reason : "-"}{" "}
        </p>
      ),

      // client_name: x.clientName || "",
      action: (
        <Button
          // color="#FFA811"
          style={{
            color: "white",
            background: this.props.showActionButton ? "#FFA811" : "#ffdc9e",
            padding: 1,
            boxShadow: "none",
          }}
          variant={"contained"}
          //color={"#FFA811"}
          disabled={!this.props.showActionButton}
          id={x.id}
          onClick={() => onResendClick(x?.appointmentId, x, x?.requestChange)}
          // disabled={this.props.loading && x?.id ? true : false}
        >
          {" "}
          View
        </Button>
      ),
    };
    if(this.props.disableFilter){
      delete res.request_type
    }
    
    return res
  }

  createTableData = (users, onResendClick, page) => {
    let data = [];
    console.log(users);
    if (Array.isArray(users) && users?.length > 0) {
      //

      users.map((x, index) => {
        if (this.props?.filter_id === "All") {
          console.log("INSIDE");
          return data.push(this.createRowData(x, index, onResendClick, page));
        } else {
          console.log("OUTSIDE");
          if (x?.requestChange === this.props?.filter_id) {
            return data.push(this.createRowData(x, index, onResendClick, page));
          }
          return x;
        }
      });
    }

    return data;
  };

  render() {
    const { classes } = this.props;
    const { filter } = this.props;
    const {
      // listTitle,
      // filterOptions,
      totalCount,
      filterDateRange,
      tableData,
      // hideFilterAndClose,
      hideTotalCount,
      // page,
      onResendClick,
      disableFilter = false,
    } = this.props;

    //   console.log()

    console.log(tableData);

    const rows = this.createTableData(
      tableData,
      onResendClick,
      this.props?.filter
    );
    //   console.log(rows)
    return (
      <div
        style={{ width: "100%" }}
        className={`${classes.commonShadows} ${classes.cardWrapperShadows}`}
      >
        <Grid container>
          {/* Header */}
          <div
            style={{
              padding: 7,
              paddingLeft: 16,
              paddingRight: 16,
              width: "100%",
            }}
          >
            <Grid item container xs={12}>
              <Grid item container>
                <Grid item>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" className={classes.mediumFont}>
                      {/* {listTitle ? listTitle : "Resource(s) Count"} */}
                      {disableFilter
                        ? "Refund Approval Request"
                        : "Approval Request"}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs>
                  {/* This Grid is needed for the spacing in-between header text and the icon */}
                </Grid>
                <>
                  <Grid item>
                    <div style={{ display: "flex" }}>
                      {/* <IconButton className={classes.rowOneIcons}> 
                      <FilterListRoundedIcon htmlColor={"#B0B4BE"} />
                    </IconButton> */}

                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Select
                          value={filter}
                          disabled={disableFilter}
                          onChange={this.handleChange}
                          inputProps={{...createTestProps(`select approval request`)}}
                          style={{
                            height: 30,
                            width: 200,
                            overflow: "hidden",
                          }}
                        >
                          {filter_options?.map((item, index) => {
                            return (
                              <MenuItem
                                key={item.value}
                                value={item.value}
                                name={item.label}
                                {...createTestProps(`approval request ${item.value}`)}
                              >
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                </>
                {/* </Grid> */}
              </Grid>
            </Grid>
          </div>

          {/* Header Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Main container row 2 */}
          <div style={{ padding: "16 0", width: "100%" }}>
            <Grid item container xs={12}>
              {!hideTotalCount && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      {totalCount ? totalCount : 0}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="secondary"
                      style={{ marginBottom: 16 }}
                    >
                      {filterDateRange ? filterDateRange : ""}
                    </Typography>
                    {/**From Aug 01 to Aug 31, 2020 */}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}

              {/* Table Starts */}
              <Grid item xs={12} style={{ maxHeight: 350, overflow: "scroll" }}>
                {/* Table */}
                {columns ? (
                  <Table stickyHeader style={{ padding: 0 }}>
                    <TableHead>
                      <TableRow>
                        {disableFilter ? (
                          <>
                            {businessadmin_columns.map((item, index) => (
                              <TableCell
                                classes={{
                                  stickyHeader: classes.customTableStickyHeader,
                                }}
                                style={{ padding: "7px 16px" }}
                                align={item.align}
                              >
                                <Typography
                                  color="secondary"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {item?.label}
                                </Typography>
                              </TableCell>
                            ))}
                          </>
                        ) : (
                          <>
                            {columns.map((item, index) => (
                              <TableCell
                                classes={{
                                  stickyHeader: classes.customTableStickyHeader,
                                }}
                                style={{ padding: "7px 16px" }}
                                align={item.align}
                              >
                                <Typography
                                  color="secondary"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {item?.label}
                                </Typography>
                              </TableCell>
                            ))}
                          </>
                        )}

                        {!tableData && !tableData?.length > 0 && (
                          <TableCell
                            classes={{
                              stickyHeader: classes.customTableStickyHeader,
                            }}
                          >
                            <Typography color="secondary">Category</Typography>
                          </TableCell>
                        )}
                        {/* The following two cells are for spacing purpose don't remove */}
                        {/* <TableCell classes={{ stickyHeader: classes.customTableStickyHeader }}></TableCell>
                        <TableCell classes={{ stickyHeader: classes.customTableStickyHeader }}></TableCell> */}

                        {/* <TableCell classes={{ stickyHeader: classes.customTableStickyHeader }} >
                          <Typography color="secondary">
                            Category
                          </Typography>
                        </TableCell> */}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {rows &&
                        rows.map((row, index) => (
                          <StyledTableRow key={index}>
                            {Object.keys(row).map((columns, index) => (
                              <TableCell
                                style={{ padding: "0px 16px" }}
                                align={index === row.length ? "center" : "left"}
                              >
                                <Typography>{row[columns]}</Typography>
                              </TableCell>
                            ))}

                            {/* The following two cells are for spacing purpose don't remove */}
                            {/* <TableCell></TableCell>
                          <TableCell></TableCell> */}
                          </StyledTableRow>
                        ))}
                    </TableBody>
                    {!rows || !tableData?.length > 0 ? (
                      <StyledTableRow key={"nodata"}>
                        <TableCell style={{ borderBottom: "none" }} colSpan={6}>
                          <Typography
                            align="center"
                            variant="h6"
                            color="textSecondary"
                          >
                            No Data
                          </Typography>
                        </TableCell>
                      </StyledTableRow>
                    ) : null}
                  </Table>
                ) : (
                  <>
                    <Typography
                      align="center"
                      variant="body1"
                      color="textSecondary"
                    >
                      No Data
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </div>
    );
  }
}

export default withStyles(style)(DashboardPendngRequestListView);

DashboardPendngRequestListView.propTypes = {
  /**Sets the title of the table. Renders <Typography></Typography> component with variant ```h6```. */
  listTitle: PropTypes.string,
  /**Uses the array of value and label to render filter options. */
  // filterOptions: PropTypes.arrayOf(Object),
  /**Sets the total count of entity. */
  totalCount: PropTypes.number,
  /**Sets the filter date range. */
  filterDateRange: PropTypes.string,
  /**Renders the table header and table data. */
  tableData: PropTypes.array,
  // Hiding Elements Props
  /**Hides Filter and close card items. */
  hideFilterAndClose: PropTypes.bool,
  /**Hides the Total Count section. */
  hideTotalCount: PropTypes.bool,
};
