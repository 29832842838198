import React from "react";
import {
  Paper,
  withStyles,
  Grid,
  Button,
  Hidden,
  IconButton,
} from "@material-ui/core";
import { HybridTypography } from "..";
// import { ICONS } from "../hybridTypography/typography";
import EditIcon from "@material-ui/icons/Edit";
// import Divider from "@material-ui/core/Divider";
import AddEditProductManagement from "../addEditForm"
import { withAlertAndDialog } from "../../HOCs";
import {
  // ClientAddressIcon,
 // DOBIconV2,
 EmailIconV2,
 // GenderIconV2,
 // IdCardIconV2,
 // LocationIconV2,
 MobileIconV2,
 NameIconV2,
 OccupationIconV2,
 LanguageIconV2,
 LocationIconV2,
//  WeightIconV2,
//  HeightIconV2,
//  BmiIconV2,
//  BloodgroupIconV2,
} from "../../assets"
const styles = (theme) => ({
  paperCard: {
    position: "relative",
    borderRadius: "0px",
    boxShadow: `none`,
    backgroundColor: theme.palette.secondary.light,
  },
  strong_name: {
    paddingLeft: 0,
    paddingRight: 12,
    color: theme.palette.ternary.main,
    marginLeft: "28px",
    boxShadow: "unset !important",
  },
  gridItems: {
    padding: "25px 25px 40px 32px",
  },
  gridTopItems: {
    padding: "25px 25px 0px 32px",
  },
  gridMiddleItems: {
    padding: "0px 25px 0px 32px",
  },
  gridBottomItems: {
    padding: "0px 25px 40px 32px",
  },
  dividerBackground: {
    backgroundColor: theme.palette.secondary.light,
  },
});

class DetailsCard extends React.Component {
  handleIcons = (iconName) => {
    switch (iconName) {
      case "PREFERRED LANGUAGE":
        // return ICONS.LANGUAGE("#28a9c1");
        return <LanguageIconV2 fontSize={"small"}/>
      case "Male, 30 Years":
        // return ICONS.PERSON("#28a9c1");
        return <NameIconV2 fontSize={"small"}/>
      case "MOBILE NUMBER":
        // return ICONS.CLIENTPHONE("#28a9c1");
        return <MobileIconV2 fontSize={"small"}/>
      case "CURRENT LOCATION":
        // return ICONS.LOCATION("#28a9c1");
        return <LocationIconV2 fontSize={"small"}/>
      case "HOME ADDRESS":
        // return ICONS.LOCATION("#28a9c1");
        return <LocationIconV2 fontSize={"small"}/>
      case "EMAIL":
        // return ICONS.CLIENTMAIL("#28a9c1");
        return <EmailIconV2 fontSize={"small"}/>
      case "Occupation":
        // return ICONS.CLIENTOCCUPATION("#28a9c1");
        return <OccupationIconV2 fontSize={"small"}/>

      default: 
        // return ICONS.CLIENTID("#28a9c1");
        return <NameIconV2 fontSize={"small"}/>
    }
  };

  cancelForm = () =>{
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    }); 
  }


  render() {
    const { classes, loading, component, isDontShowEdit, callback, id } = this.props;
    const toggleDrawer = (component, isEdit=false) =>{
      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open:true,
        component:<AddEditProductManagement  onSaveButtonClicked={callback} components={component?.editcomponent} cancelForm={this.cancelForm} saveButtonId={"saveNewClientButtonFromAddEditEnquiry"} cancelButtonId={"cancelNewClientButtonFromAddEditEnquiry"}/>
      })
    }
    
    return (
      <Paper className={classes.paperCard}>
        {!isDontShowEdit &&
          <div style={{ position: "absolute", right: 30, top: 16 }}>
          <Hidden xsDown>
            <Button
              variant="contained"
              color="primary"
              id={id}
              onClick={() => {
                toggleDrawer(this.props.component, true);
              }}
            >
              Edit
            </Button>
          </Hidden>
          <Hidden smUp>
            <IconButton
              aria-label="edit"
              color="primary"
              id={id}
              onClick={() => {
                toggleDrawer(this.props.component, true);
              }} 
            >
              <EditIcon />
            </IconButton>
          </Hidden>
        </div>}
        <Grid container>
          {/* Block-1 */}

          <Grid container item xs={12}>
            {/* <Typography
              className={classes.gridTopItems}
            >Personal Information</Typography> */}
            {/* Row-1 */}
            <Grid
              container
              item
              direction="row"
              md={12}
              sm={12}
              xs={12}
              className={classes.gridTopItems}
            >
              {Object.keys(component.components).map((section, index) => {
                // return (
                // <div key={index}>
                // {
                return Object.values(component.components[section].fields)?.map(
                  (components) => {
                    // alert(JSON.stringify(components?.value))
                    return (
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <HybridTypography
                          text={
                            components.value
                              ? components.value.constructor === Object
                                ? Object.values(components.value).join(" ")
                                : components.value.constructor === Array
                                ? components.value.join(", ")
                                : components.value
                              : "N/A"
                          }
                          header={components.label  ? components.label : ""}
                          isSecondary={components.isSecondary}
                          loading={loading}
                          islistbottom={true}
                          icon={this.handleIcons(components.label)}
                        />
                      </Grid>
                    );
                  }
                );
                // }
                // </div>
                // );

                // return section;
              })}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(withAlertAndDialog((DetailsCard)));
