import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function GenderIconV2(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.87 2a.76.76 0 00-.082.007h-3.44a.758.758 0 000 1.516h1.707L12.12 7.458a4.426 4.426 0 00-2.573-.9A4.559 4.559 0 005 11.102a4.5 4.5 0 003.79 4.394v3.186H7.274a.758.758 0 000 1.516H8.79v1.77a.758.758 0 101.516 0v-1.77h1.516a.758.758 0 000-1.517h-1.516v-3.185a4.5 4.5 0 003.79-4.394 4.426 4.426 0 00-.9-2.573l3.935-3.935v1.708a.758.758 0 101.516 0v-3.44A.758.758 0 0017.874 2h-.004zM9.547 8.07a3.032 3.032 0 11-3.032 3.032A3.02 3.02 0 019.548 8.07h-.001z"
        fill="#0063E7"
      />
    </SvgIcon>
  );
}

export default GenderIconV2;
