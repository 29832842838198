/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-01-27 13:40:45
 * @modify date 2022-01-28 13:28:08
 * @desc View Page - Promotional Banner
 */

import React, { Component } from "react";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";

// Material UI Helpers
import { withStyles } from "@material-ui/core/styles";

// Custom Components
import InfoSummaryCard from "./components/infoSummaryCard";
import InfoItem from "./components/infoItem";
import SubscriptionInfoItem from "./components/subscriptionInfoItem";
import BannerPreview from "./components/bannerPreview";
import SubscriptionDetailCard from "./components/subscriptionDetailCard";
import { connect } from "react-redux";
import { actions } from "tanyacare-middleware";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { routes } from "../../../../router/routes";
import { alertProps } from "../../../../utils";
import { withAlertAndDialog } from "../../../../HOCs";
import { DeletePromotionalBannerDialog } from "../tableList/components";

const StyledPaper = withStyles((theme) => ({
  root: {
    boxShadow: "0px 4px 25px 0px rgba(49,49,49,0.14)",
    borderRadius: "8px",
    border: `1px solid rgba(59, 63, 91, 0.16)`,
    height: "100%",
  },
}))(
  React.forwardRef((props, ref) => <Paper elevation={0} ref={ref} {...props} />)
);

const styles = (theme) => ({});

const SubscriptionCardRenderer = ({ data, loading }) => {
  const handleViewMoreClick = () => {
    const { id, versionId, type } = data?.subscriptionDetail;

    const link = `${routes.product_management}/${
      type === "Service"
        ? "services"
        : type === "Package"
        ? "packages"
        : "products"
    }/view/${id}/${versionId}`;

    const win = window.open(link, "_blank");
    win.focus();
  };

  const susbscriptionInfoProps = {
    id: data?.subscriptionDetail?.id,
    image: data?.subscriptionDetail?.image,
    title: data?.subscriptionDetail?.name,
    aliasId: data?.subscriptionDetail?.aliasId,
    currency: data?.subscriptionDetail?.currency,
    price: data?.subscriptionDetail?.discount,
    onViewMoreClick: handleViewMoreClick,
    loading: loading,
  };

  return (
    <>
      <Hidden smUp>
        <SubscriptionInfoItem
          label={"Subscription Details"}
          iconName={"idCardWithClip"}
          hideIcon={false}
          subscriptionData={susbscriptionInfoProps}
        />
      </Hidden>
      <Hidden xsDown>
        <InfoItem
          label={"Subscription Details"}
          iconName={"idCardWithClip"}
          hideIcon={false}
          valueComponent={
            <SubscriptionDetailCard {...susbscriptionInfoProps} />
          }
        />
      </Hidden>
    </>
  );
};

class ViewPromotionalBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // Lifecycle Methods
  componentDidMount() {
    this.getPromotionalBannerInfo();
  }

  getPromotionalBannerInfo = () => {
    this.props.GET_PROMOTIONAL_BANNER_INFO({
      bannerId: this.props.match.params.id,
    });
  };

  shouldComponentUpdate(nextProps) {
    const { deleteBannerInfo } = this.props;
    const { deleteBannerInfo: nextDeleteBannerInfo } = nextProps;

    if (deleteBannerInfo?.loading !== nextDeleteBannerInfo?.loading) {
      this.props.backDrop.setBackDrop({
        open: nextDeleteBannerInfo.loading,
        message: "Deleting the banner...",
      });

      if (!nextDeleteBannerInfo?.loading && !nextDeleteBannerInfo?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Banner deleted successfully!",
          severity: alertProps.severity.success,
        });
        this.props.history.push({ pathname: routes.manage_promotional_banner });
      } else if (nextDeleteBannerInfo?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to delete the banner.",
          severity: alertProps.severity.error,
        });
      }
    }

    return true;
  }

  handleDeleteClick = (bannerId) => {
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: true,
      body: (
        <DeletePromotionalBannerDialog
          title="Confirmation"
          description={
            <>
              By clicking yes, this banner will be removed from active
              <br /> promotional banner carousel listing.
            </>
          }
          onDelete={() => this.handleDelete(bannerId)}
          onCancel={this.handleDialogClose}
        />
      ),
      dontShowButtons: true,
      hideAllButtons: true,
      fitContentWidth: true,
      className: {
        Dialog: {
          borderRadius: "10px",
        },
        DialogContent: {
          padding: "0px",
        },
      },
    });
  };

  handleDelete = (bannerId) => {
    try {
      this.props.DELETE_PROMOTIONAL_BANNER({ bannerId });
      this.handleDialogClose();
    } catch (e) {
      console.error("Error in deleting the banner", e);
    }
  };

  handleDialogClose = () => {
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: false,
      component: <></>,
    });
  };

  handleEditClick = (id) => {
    this.props.history.push(`${routes.manage_promotional_banner}/edit/${id}`);
  };

  render() {
    // const { classes } = this.props;
    const {
      data,
      // loading, error
    } = this.props?.bannerInfo;

    const getFormattedDate = (date) => moment(date).format("DD/MM/YYYY");

    return (
      <div style={{ margin: "16px 16px 16px 12px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <StyledPaper style={{ padding: "24px 32px 22px 24px" }}>
              <InfoSummaryCard
                id={data?.id}
                title={data?.bannerName ? data?.bannerName : "-"}
                promoId={data?.aliasId ? data?.aliasId : "-"}
                status={data?.isActive}
                createdBy={data?.createdBy}
                onDeleteClick={this.handleDeleteClick}
                onEditClick={this.handleEditClick}
              />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <StyledPaper style={{ padding: "32px 24px" }}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6} md={4}>
                  <InfoItem
                    iconName={"application"}
                    label={"Application Type"}
                    value={
                      data?.applicationType?.label
                        ? data?.applicationType?.label
                        : "-"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <InfoItem
                    iconName={"idCard"}
                    label={"Banner Type"}
                    value={
                      data?.bannerType?.label ? data?.bannerType?.label : "-"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <InfoItem
                    iconName={"priority"}
                    label={"Priority Order"}
                    value={data?.priority ? data?.priority?.toString() : "-"}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <InfoItem
                    iconName={"calendar"}
                    label={"Effective Date"}
                    value={
                      data?.effectiveFrom
                        ? getFormattedDate(data?.effectiveFrom)
                        : "-"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <InfoItem
                    iconName={"calendar"}
                    label={"End Date"}
                    value={
                      data?.effectiveTo
                        ? getFormattedDate(data?.effectiveTo)
                        : "-"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <SubscriptionCardRenderer data={data} loading={false} />
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>
          <Grid item xs={12} sm={8} md>
            <StyledPaper style={{ padding: "32px 16px" }}>
              <BannerPreview src={data?.bannerImage ? data?.bannerImage : ""} />
            </StyledPaper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bannerInfo: state?.promotionalBanner?.getBannerInfo,
  deleteBannerInfo: state?.promotionalBanner?.deleteBanner,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAlertAndDialog(withStyles(styles)(ViewPromotionalBanner))));
