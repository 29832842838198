import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles, withStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  buttonWidth: {
    width: 200,
    transition: `all .4s ease-out`
  },
  tabs: {

    width: 350,
    margin: "auto",
    marginTop: 50,
    marginBottom: 50,
  },

}))

const StyledButtonGroup = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white !important'
  },

}))(ToggleButton)


export function ToggleGroup(props) {

  const classes = useStyles()

  const {
    selected,
    toggleTypes,
    handleToggleClick
  } = props;

  return (
    <div className={classes.tabs}>
      <ToggleButtonGroup
        value={selected}
        exclusive
        onChange={handleToggleClick}
      >
        {toggleTypes?.map((toggle, key) => {
          return (
            <StyledButtonGroup id={toggle?.id} key={key} className={classes.buttonWidth} value={toggle.value}>
              {toggle.label}
            </StyledButtonGroup>)
        })}
      </ToggleButtonGroup>
    </div>
  );
}
