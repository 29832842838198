import { filterType, user_roles } from "../../../../utils"

export const filterComponent = (state, props) => {
  switch (props?.userRole) {
    case user_roles.super_admin: {
      return {
        section_1: {
          label: `Service Professional Filters`,
          fields: {
            category: {
              key: "category",
              type: filterType.CATEGORY,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY CATEGORY",
              value: state.category,
              isMulti: true
            },
            provider: {
              key: "provider",
              type: filterType.PROVIDER,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY PROVIDER",
              value: state.provider,
              isMulti: true
            },
            status: {
              key: "status",
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SUPERVISOR STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            skills: {
              key: "skills",
              type: filterType.SKILLS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SKILLS",
              value: state.skills,
              isMulti: true
            },
            specialization: {
              key: "specialization",
              type: filterType.SPECIALIZATION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SPECIALIZATION",
              value: state.specialization,
              isMulti: true
            },
          }
        }
      }
    }

    case user_roles.provider: {
      return {
        section_1: {
          label: "Service Professional Filters",
          fields: {
            category: {
              key: "category",
              type: filterType.CATEGORY,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY CATEGORY",
              value: state.category,
              isMulti: true
            },
            status: {
              key: "status",
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SUPERVISOR STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            skills: {
              key: "skills",
              type: filterType.SKILLS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SKILLS",
              value: state.skills,
              isMulti: true
            },
            specialization: {
              key: "specialization",
              type: filterType.SPECIALIZATION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SPECIALIZATION",
              value: state.specialization,
              isMulti: true
            },
          }
        }
      }
    }

    case user_roles.supervisor: {
      return {
        section_1: {
          label: "Service Professional Filters",
          fields: {
            category: {
              key: "category",
              type: filterType.CATEGORY,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY CATEGORY",
              value: state.category,
              isMulti: true
            },
            status: {
              key: "status",
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SUPERVISOR STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            skills: {
              key: "skills",
              type: filterType.SKILLS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SKILLS",
              value: state.skills,
              isMulti: true
            },
            specialization: {
              key: "specialization",
              type: filterType.SPECIALIZATION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SPECIALIZATION",
              value: state.specialization,
              isMulti: true
            },
          }
        }
      }
    }

    case user_roles.service_coordinator: {
      return {
        section_1: {
          label: "Service Professional Filters",
          fields: {
            category: {
              key: "category",
              type: filterType.CATEGORY,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY CATEGORY",
              value: state.category,
              isMulti: true
            },
            status: {
              key: "status",
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SUPERVISOR STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            skills: {
              key: "skills",
              type: filterType.SKILLS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SKILLS",
              value: state.skills,
              isMulti: true
            },
            specialization: {
              key: "specialization",
              type: filterType.SPECIALIZATION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SPECIALIZATION",
              value: state.specialization,
              isMulti: true
            },
          }
        }
      }
    }

    default: return {}
  }
}