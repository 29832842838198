import React from "react";

import { Grid, withStyles, Button } from "@material-ui/core";
import { fontStyleClass } from "../../utils";

import { withRouter } from "react-router-dom";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";

import * as WebDataRocks from "./webdatarocks.react";
import "webdatarocks/webdatarocks.css";
import webdatarockslocal from "../../assets/json/webdatarocks";

import {
  DateTimePickersCustom,
  LoadingSection,
  SelectFromPlaceholder,
} from "../../components";
import Paper from "@material-ui/core/Paper";

const style = (theme) => ({
  ...fontStyleClass,
  table: {
    minHeight: 650,
    minWidth: 700,
  },
  formControl: {
    // margin: "0px 8px",
    [theme.breakpoints.down("xs")]: {
      marginTop: 8,
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.ternary.main} !important`,
        borderWidth: "1.6px !important",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 16,
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      borderRadius: 16,
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  textField: {
    width: "100%",
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.ternary.main} !important`,
        borderWidth: "1.6px !important",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  customSelect: {
    padding: "10.500px 14px",
  },
  colorPrimary: {
    color: "white",
  },
  errorPosition: {
    position: "relative",
    top: -10,
  },
  root: {
    width: "100%",
    padding: "8px 22px 16px 22px",
    marginBottom: 30,
    boxShadow: (props) =>
      props.withoutBoxShadow
        ? "none !important"
        : "0 3px 6px 0 rgb(0 0 0 / 4%)",
    background: (props) =>
      props.withoutBoxShadow ? "transparent !important" : "white",
  },
  saveBtnContainer: {
    // position: "absolute",
    // left: 16,
    // bottom: 16,
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-end",
    gap: 12,
  },
});

const ReportComponent = (props) => {
  const customizeToolbar = (toolbar) => {
    var tabs = toolbar.getTabs(); // get all tabs from the toolbar
    toolbar.getTabs = function () {
      delete tabs[0]; // delete the first tab
      delete tabs[1]; // delete the second tab
      return tabs;
    };
  };

  const {
    classes,
    type,
    getData,
    fromDate,
    setFrom,
    setTo,
    toDate,
    setApplied,
    applied,
    tableRef,
    fetchData,
  } = props;

  const data = [type, ...getData?.data];

  return (
    <Grid style={{ padding: "25px" }}>
      <Paper className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item direction="row" style={{ display: "flex" }}>
            <Grid item direction="row" style={{ marginRight: 20 }}>
              <DateTimePickersCustom
                id={`FromDatePickerReports`}
                label="From Date"
                value={fromDate ?? null}
                dontCloseOnChange
                handleOnChange={(newEffectiveDate) => setFrom(newEffectiveDate)}
                isDate={true}
                variant={"mobile"}
              />
            </Grid>
            <Grid item direction="row">
              <DateTimePickersCustom
                id={`ToDatePickerReports`}
                label="To Date"
                value={toDate ?? null}
                dontCloseOnChange
                handleOnChange={(newExpiryDate) => setTo(newExpiryDate)}
                minDate={fromDate}
                isDate={true}
                variant={"mobile"}
              />
            </Grid>
          </Grid>
          <Grid item>
            <div className={classes.saveBtnContainer}>
              <Button
                variant={"text"}
                color={"primary"}
                onClick={() => {
                  setFrom(null);
                  setTo(null);
                }}
                style={{ marginLeft: 12 }}
                id={"CancelTransactionFetch"}
              >
                {"Cancel"}
              </Button>
              <Button
                variant={"contained"}
                color={"primary"}
                className={classes.saveBtn}
                // disabled={loading}
                onClick={() => {
                  setApplied(true);
                  fetchData();
                }}
                id={"ApplyTransactionFetch"}
              >
                {"Apply"}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
      {!applied ? (
        <SelectFromPlaceholder message="Please apply date to display report" />
      ) : fromDate && toDate && !getData.loading ? (
        <Paper className={classes.root}>
          <WebDataRocks.Pivot
            ref={tableRef}
            toolbar={true}
            beforetoolbarcreated={customizeToolbar}
            width="100%"
            height={700}
            global={{
              localization: webdatarockslocal,
            }}
            // reportcomplete={() => onReportComplete()}
            report={{
              dataSource: {
                data: data,
                dataSourceType: "json",
              },
              options: {
                showTotals: false,
                showGrandTotals: false,
                grid: {
                  type: "flat",
                },
                slice: {
                  expands: {
                    expandAll: true,
                  },
                },
              },
              formats: [
                {
                  name: "",
                  decimalPlaces: 2,
                  nullValue: "bl",
                  // "currencySymbol": ""
                },
              ],
            }}
          ></WebDataRocks.Pivot>
        </Paper>
      ) : (
        <LoadingSection message="Fetching data..." />
      )}
    </Grid>
  );
};

export default withAlelrtAndDialog(
  withRouter(withStyles(style, { withTheme: true })(ReportComponent))
);
