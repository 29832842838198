import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPickersDesktopDateRangeCalendar-calendar": {
      minWidth: "250px !important",
      minHeight: "230px !important",
    },
    "& .MuiInputLabel-asterisk": {
      color: theme.palette.error.main
    }
  },

  label: {
    color: theme.palette.text.label,
    textTransform: "uppercase",
  },

  inputGrid: {
    marginTop: 8,
    // '&:hover': {
    //   border: '1.8px solid #f4975d !important',
    //   borderRadius: 8
    // },
    "& .MuiOutlinedInput-input": {
      padding: "16px 12px !important",
      pointerEvents: "none !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 8,
      border: `solid 1.5px ${theme.palette.secondary.light} !important`,
    },
  },
  inputGridError: {
    marginTop: 8,
    // '&:hover': {
    //   border: '1.8px solid #f4975d !important',
    //   borderRadius: 8
    // },
    "& .MuiOutlinedInput-input": {
      padding: "16px 12px !important",
      pointerEvents: "none !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 8,
      border: `solid 1.5px ${theme.palette.error.main} !important`,
    },
  },
}));
