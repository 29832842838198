import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
} from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { connect } from "react-redux";
import { actions } from "tanyacare-middleware";

import withAlelrtAndDialog from "../../../../../HOCs/withAlelrtAndDialog";
import {
  InternatlServerError,
  LoadingSection,
} from "../../../../../components";
import moment from "moment";
// import { convertToBase64URL } from "../../../../../utils";
import QuoteActions from "./quoteActions";
import MarkPdf from "./markPdf";
import {
  isCreatedByManagedCareAdminById,
  isJson,
  OTHER_ROLES,
  user_roles,
} from "../../../../../utils";
// import { Quotes } from "../../../../../assets/Quotes/quotes";
// import { MarkedQuotes } from "../../../../../assets/Quotes/markedQuotes";

const styles = (theme) => ({
  root: {
    width: "100%",
    padding: 20,
    "& .MuiListItem-gutters": {
      padding: "0px !important",
      paddingBottom: "10px !important",
    },
    "& svg": {
      fontSize: "1.2rem",
    },
    "& .MuiListItemIcon-root": {
      minWidth: 45,
    },
  },
  detailSection: {
    marginTop: 20,
  },
  customActionButtons: {
    padding: 8,
    border: "0.5px solid #e9e9e9",
  },
  accordions: {
    marginTop: 20,
    background: "white",
  },
  enqId: {
    color: theme.palette.ternary.main,
  },
  enqDate: {
    color: theme.palette.primary.main,
  },
  enqHeader: {
    color: theme.palette.primary.main,
  },
  enqlist: {
    "& span": {
      color: theme.palette.primary.main,
    },
  },

  finalized: {
    color: theme.palette.success.contrastText,
    background: theme.palette.success.light,
    padding: "2px 5px",
    margin: "0px 0px 2px 5px",
  },
});

const PDFVIEWER = (props) => {
  return (
    <object
      title={props.data.destnationFileLocation}
      style={{ height: "90vh" }}
      data={props.data.url}
      width="100%"
      height="100%"
    >
      <p>
        Can't Open - download here <a href={props.data.url}>to the PDF!</a>
      </p>
    </object>
  );
};

class ViewQuoteHistory extends React.Component {
  constructor(props) {
    super();
    this.state = {
      expanded: false,
    };
  }

  componentDidMount() {
    this.getQuotes();
  }

  getQuotes = () => {
    // this.props.GET_ALL_DOCS({ this.state });
    if (this.props.id) {
      this.props.GET_DOCS_BY_ENQ({ enquiry_id: this.props.id });
      //  this.props.GET_DOCS_BY_ENQ({ enquiry_id: "0827463b-548f-4c6e-b852-6b843c86c844"})
    }
  };

  updateStatus = async (enq_id, quoteId, quote_status) => {
    console.log("id");
    console.log(enq_id, "----", quoteId);
    await this.props.UPDATE_DOC_STATUS_BY_ENQ_ID({
      enquiryId: enq_id,
      quoteStatus: quote_status ? "quote_submitted" : "quote_finalized",
      quoteId: quoteId,
      // isEnquiry: true,
    });
    //
    // console.log(req_data)
    // if(req_data)
    // {
    this.props.closeDrawer();
    this.props.getEnquiries();
    // }
    // GET_DOCS_BY_ENQ
  };

  closeModal = () => {
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: false,
      title: "QUOTE001",
      body: <></>,
      positiveBtn: "SAVE",
      negativeBtn: "CANCEL",
      onOk: () => {},
      onCancel: () => {},
    });
  };
  viewQuote = (data) => {
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: true,
      title: "QUOTE001",
      body: <PDFVIEWER data={data} />,
      positiveBtn: "SAVE",
      negativeBtn: "CANCEL",
      onOk: this.closeModal,
      onCancel: this.closeModal,
    });
  };

  render() {
    const { classes } = this.props;
    const { data, error, loading } = this.props.quotes;

    const isBusinessAdmin = this.props?.auth_data?.data?.user_role === user_roles.business_admin

    const isMCAdmin = this.props?.auth_data?.data?.other_roles?.includes(
      OTHER_ROLES.crossBundling
    );
    const isCreatedByMC = isCreatedByManagedCareAdminById(
      this.props?.createdBy,
      this.props?.auth_data?.data
    );

    const total = (amount) => {
      if (isJson(amount)) {
        let _parseFloat;
        _parseFloat = JSON.parse(amount);
        return (_parseFloat = Number(_parseFloat));
      } else return amount;
    };
    return (
      <div className={classes.root}>
        <Grid
          container
          style={{
            marginBottom: 20,
            padding: "5px 0px 10px 0px",
            position: "sticky",
            top: 0,
            zIndex: 5,
            background: "white",
          }}
        >
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="h6"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
                className={classes.enqHeader}
              >
                View Quote(s)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                component="div"
                className={classes.enqId}
              >
                {this?.props?.data?.tag ? this?.props?.data?.tag : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {loading && <LoadingSection message="Loading Quote(s) History..." />}
        {!loading && error && (
          <InternatlServerError
            message="Something went wrong, Try Again!"
            onRetry={this.getQuotes}
          />
        )}
        {!loading && !error && !data?.length > 0 && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <Typography variant="h6" align="center">
                You don't have any Quotes!
              </Typography>
            </Grid>
          </Grid>
        )}
        {!loading && !error && data && data.length > 0 && (
          <List>
            {data.map((val, i) => {
              return (
                <>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon style={{ paddingTop: 8 }}>
                      <PictureAsPdfIcon color="secondary" />
                      {/* <Quotes color="#FFA811" />
                      {val?.quoteStatus === "payment_completed" ? (
                        <MarkedQuotes />
                      ) : (
                        <></>
                      )} */}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Grid container item xs={12}>
                          <Grid item xs={12}>
                            <Typography
                              component="div"
                              variant="body1"
                              className={classes.inline}
                              // color="primary"
                            >
                              {val.quotesTag
                                ? val.quotesTag
                                : `TCQ-2021-${val.quoteId.substr(0, 8)}`}
                              {/* {val?.quoteStatus === "quote_finalized" &&
                              <Typography
                                component="span"
                                variant="caption"
                                className={classes.finalized}
                                >FINALIZED</Typography>} */}
                            </Typography>

                            <Grid container>
                              <Grid item xs={8}>
                                <Grid container>
                                  <Grid item>
                                    <Typography
                                      component="div"
                                      variant="caption"
                                      className={classes.inline}
                                      // color="secondary"
                                      style={{
                                        color: "#FFA811",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {val?.total
                                        ? `MYR. ${parseFloat(
                                            total(val?.total)
                                          ).toFixed(2)}`
                                        : ""}
                                      &nbsp;&nbsp;
                                    </Typography>
                                  </Grid>

                                  <Grid item>
                                    <Typography
                                      component="div"
                                      variant="caption"
                                      className={classes.inline}
                                      color="secondary"
                                    >
                                      {/* 01 Jan, 1970 amt 02:11 AM */}
                                      {val?.dateandTime
                                        ? moment(val?.dateandTime)
                                            // .format("DD MMM, YYYY [at] hh:mm A")
                                            .format("DD MMM, YYYY")
                                            .toString()
                                            .replace("pmt", "at")
                                        : ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography variant="body1" component="span">
                                  {val?.quoteStatus !== "payment_completed" ? (
                                    <QuoteActions
                                      {...this.props}
                                      data={val}
                                      enquiry_id={this.props.id}
                                      createRows={this.createRows}
                                      handleRemove={this.handleRemove}
                                      isMarked={
                                        val?.quoteStatus !== "quote_finalized"
                                          ? true
                                          : false
                                      }
                                      showEdit={
                                        isBusinessAdmin ? false :
                                        isMCAdmin
                                          ? isCreatedByMC
                                            ? true
                                            : false
                                          : true
                                      }
                                      showDownload={
                                        isBusinessAdmin ? true :
                                        isMCAdmin
                                          ? isCreatedByMC
                                            ? true
                                            : false
                                          : true
                                      }
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container item xs={12}>
                            {/* <Typography variant="body1" component="span"> */}
                            {console.log(val?.quoteStatus)}
                            {val?.quoteStatus !== "payment_completed" ? (
                              <MarkPdf
                                {...this.props}
                                data={val}
                                enquiry_id={this.props.id}
                                quoteId={val.quoteId}
                                classes={classes}
                                createRows={this.createRows}
                                handleRemove={this.handleRemove}
                                updateStatus={this.updateStatus}
                                isMarked={
                                  val?.quoteStatus === "quote_finalized"
                                    ? true
                                    : false
                                }
                                disabled={
                                  isBusinessAdmin ? true :
                                  isMCAdmin
                                    ? isCreatedByMC
                                      ? false
                                      : true
                                    : false
                                }
                                // isMarked={false}
                              />
                            ) : (
                              <></>
                            )}
                            {/* </Typography> */}
                          </Grid>
                        </Grid>
                      }
                      secondary={
                        <></>
                        // <MarkPdf
                        // {...this.props}
                        // data={val}
                        // enquiry_id={this.props.id}
                        // classes={classes}
                        // createRows={this.createRows}
                        // handleRemove={this.handleRemove}
                        // />
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              );
            })}
          </List>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    quotes: state?.quotenq?.getdocsforenq,
    auth_data: state?.authState?.loginAuth,
  };
};

export default connect(
  mapStateToProps,
  actions
)(
  withAlelrtAndDialog(withStyles(styles, { withTheme: true })(ViewQuoteHistory))
);
