import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  // Button
} from "@material-ui/core";
import { IssueChatIcon } from "../../assets";
import Badge from "@material-ui/core/Badge";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { actions } from "tanyacare-middleware";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
import moment from "moment";
// capitalizeFirstLetter
import { createFile, genericStyles } from "../../utils";
// import { QuoteInvoiceRenderer } from "../../screens/salesOperation/enquiry/list/createQuote/quote";
// import { routes } from "../../router/routes";
// import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
export * from "./issueCardLoaders";
export * from "./issueCardErrors";

// NOTE: Comments in this file are temporary, DO NOT DELETE IT

const styles = (theme) => ({
  root: {
    boxShadow: `0px 0.5px 2px 1px #e0e0e0d6`,
    borderRadius: 8,
    background: "white",
  },
  upperPart: {
    // borderColor: theme.palette.secondary.main,
    borderBottom: `solid 1px #e9e9e9`,
    padding: 16,
  },
  issueNumber: {
    marginBottom: 4,
  },
  issueDesc: {
    fontWeight: "bold",
    marginBottom: 4,
  },
  iconContainer: {
    border: "1px solid #cdcdcd",
  },
  bottomPart: {
    padding: 16,
  },
  bottomPartEnquiry: {
    padding: "8px 16px",
  },
  iconButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  statusText: {
    color: theme.palette.secondary.main,
  },
  viewQuotesButton: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  openStatus: {
    color: theme.palette.ternary.main,
  },
  resolvedStatus: {
    color: "#31b198",
  },
  pendingStatus: {
    color: "#bc2d4f",
  },
  lead_time: {
    display: "inline-block",
    fontSize: "1rem",
    padding: "0px 25px",
  },
  lead_time_padding: {
    display: "inline-block",
    fontSize: "1rem",
    padding: "0px",
  },
  button: {
    "& span": {
      textTransform: "none !important",
    },
  },
  ...genericStyles.cardWrapperShadows(theme),
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 0,
    top: -10,
    padding: "4px 4px",
    backgroundColor: "#bc2d4f",
  },
}))(Badge);

class MainIssueCard extends React.Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      invoiceUrl: "",
    };
  }

  async componentDidMount() {
    // this.props.GET_DOCS_BY_ENQ({ enquiry_id: "0827463b-548f-4c6e-b852-6b843c86c844"})
    let _invoiceUrl = async () => {
      let url = "";
      if (this.props?.invoiceurl?.length > 0) {
        url = await new Promise(async (resolve, reject) => {
          return resolve(
            await createFile(
              this.props?.invoiceurl,
              "application/pdf",
              "item.issue_tag"
            )
          );
        });
      } else {
        url = "";
      }
      this.setState({ invoiceUrl: url });
    };
    await _invoiceUrl();
  }

  render() {
    const {
      classes,
      hideStatusBar,
      issue_status = " - ",
      issue_tag,
      issue_title,
      // lead_time,
      subscription_name,
      showTextWithbutton,
      link,
      handleClick,
      id,
      enquiryList,
      // data,
      raised_on,
      // enquiryList
      // showTextWithbutton,
      data,
      enquiry_status,
      showCreateQuoteButton = true,
      showViewQuoteButton = true,
      // showQuoteButton,
      // invoiceurl,
      // isExitsClient,
      // clientId,
      // clientstatus,
      // quotesList
    } = this.props;
    // const { quote_data } = this.props.quotes

    const handleClickOpen = () => {
      this.setState({ open: true });
    };
    const handleClose = () => {
      this.setState({ open: false });
    };

    // const createRows = (data, cat) => {
    //   return data?.metaData?.services?.map((val) => ({
    //       serviceId: "SID",
    //       description: val.label,
    //       quantity: 2,
    //       unitPrice: parseFloat(val.amount).toFixed(2),
    //       discount: 2,
    //       amount: (325).toFixed(2),
    //   }));
    // };

    /* const newColumnsToPrint = [
      { id: "sno", label: "S.No" },
      {
          id: "serviceId",
          label: "SERVICE ID",
          align: "left",
          format: (value) => value.toLocaleString("en-US"),
      },
      {
          id: "description",
          label: "DESCRIPTION",
          align: "left",
          format: (value) => value.toLocaleString("en-US"),
      },
      {
          id: "quantity",
          label: "QUANTITY",
          align: "left",
          format: (value) => value.toLocaleString("en-US"),
      },
      {
          id: "unitPrice",
          label: "UNIT PRICE (RM)",
          align: "left",
          format: (value) => value.toLocaleString("en-US"),
      },
      {
          id: "discount",
          label: "DISCOUNT %",
          align: "left",
          format: (value) => value.toLocaleString("en-US"),
      },
      {
          id: "amount",
          label: "AMOUNT (RM)",
          align: "right",
          format: (value) => value.toLocaleString("en-US"),
      },
  ] */

    return (
      <Grid container className={classes.cardWrapperShadows}>
        <Grid
          item
          xs={12}
          container
          justify="space-between"
          className={classes.upperPart}
        >
          {/* xs={12} sm={12} md={6} lg={6} xl={6} */}
          <Grid item>
            <NavLink to={link ? link : "#"} style={{ textDecoration: "none" }}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.issueNumber}
              >
                {issue_tag} |{" "}
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{ fontSize: "0.875rem" }}
                >
                  {moment(subscription_name)
                    .format("DD MMM, YYYY [at] hh:mm A")
                    .toString()
                    .replace("pmt", "at")}
                </Typography>
              </Typography>
              <Typography variant="body1" className={classes.issueDesc}>
                {issue_title}
              </Typography>
            </NavLink>
          </Grid>

          {/* NOTE: The following code was commented temporarily, do not remove this code unintentionally */}

          {showTextWithbutton ? (
            <Grid
              item
              xs={12}
              sm={12}
              // md={true} will set it to responsive
              md
              // md={6}
              // lg={6}
              // xl={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                variant="h6"
                className={
                  enquiry_status ? classes.lead_time : classes.lead_time_padding
                }
              >
                {data.closed_time
                  ? // capitalizeFirstLetter(
                    moment(data.closed_time)
                      .from(raised_on)
                      .concat(" lead time")
                  : // )
                    moment(raised_on).fromNow().concat(" lead time")}
              </Typography>
              {enquiry_status && (
                  <>
                  {showCreateQuoteButton && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleClick(id, this.props);
                      }}
                      className={classes.button}
                      id={`CreateQuoteButton${this.props?.id}`}
                    >
                      Create Quote
                    </Button>
                  )}
                  {showViewQuoteButton && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        // this.props.handleRedirect(clientId);
                        this.props.viewAllQuotes(id, data);
                      }}
                      style={{ marginLeft: 10 }}
                      id={`ViewQuoteButton${this.props?.id}`}
                    >
                      View Quote(s)
                    </Button>
                  )}
                </>
              )}
            </Grid>
          ) : (
            <Grid item className={classes.iconButtonContainer}>
              <IconButton className={classes.iconContainer}>
                <StyledBadge variant={"dot"}>
                  <IssueChatIcon />
                </StyledBadge>
              </IconButton>
            </Grid>
          )}
        </Grid>
        {!hideStatusBar && (
          <Grid item xs={12} className={classes.bottomPart}>
            <Typography
              variant="body2"
              align="center"
              className={classes.statusText}
            >
              Status :{" "}
              <span
                className={`${
                  issue_status?.toLowerCase() === "Enquiry Created" ||
                  issue_status?.toLowerCase() === "Quote Submitted"
                    ? classes.openStatus
                    : issue_status?.toLowerCase() === "Quote Finalized" ||
                      issue_status?.toLowerCase() === "Payment Completed"
                    ? classes.resolvedStatus
                    : issue_status?.toLowerCase() === "Enquiry Cancelled" ||
                      issue_status?.toLowerCase() === "Enquiry Closed"
                    ? classes.pendingStatus
                    : ""
                }`}
              >
                {issue_status ? issue_status : " -N/A- "}
              </span>
            </Typography>
          </Grid>
        )}
        {enquiryList && (
          <Grid container className={classes.bottomPartEnquiry}>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                align="left"
                className={classes.statusText}
              >
                Status :{" "}
                <span
                  className={`${
                    issue_status?.toLowerCase() === "enquiry created" ||
                    issue_status?.toLowerCase() === "quote submitted" ||
                    issue_status?.toLowerCase() === "quote finalized"
                      ? classes.openStatus
                      : issue_status?.toLowerCase() === "payment completed"
                      ? classes.resolvedStatus
                      : issue_status?.toLowerCase() === "enquiry cancelled" ||
                        issue_status?.toLowerCase() === "enquiry closed"
                      ? classes.pendingStatus
                      : ""
                  }`}
                >
                  {issue_status ? issue_status : " N/A "}
                </span>
              </Typography>
            </Grid>
            {issue_status?.toLowerCase() === "payment completed" ||
            issue_status?.toLowerCase() === "enquiry cancelled" ? (
              <Grid
                container
                item
                xs={6}
                justify="flex-end"
                // className={classes.bottomPart}
              >
                {/* <Typography
                variant="body2"
                component="span"
                onClick={() => {
                  this.props.viewAllQuotes(id, data);
                }}
                className={classes.viewQuotesButton}
                id={`ViewQuoteButton${this.props?.id}`}
              >
                View Quote(s)
              </Typography> */}
                {issue_status?.toLowerCase() === "enquiry cancelled" ? (
                  <Typography
                    variant="body2"
                    component="span"
                    onClick={() => {
                      // this.props.handleViewInvoice(id, data);
                      handleClickOpen();
                    }}
                    className={classes.viewQuotesButton}
                    id={`ViewRemarks${this.props?.id}`}
                  >
                    View Remarks / Reason for cancellation
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    component="span"
                    onClick={() => {
                      // this.props.handleViewInvoice(id, data);
                      handleClickOpen();
                    }}
                    className={classes.viewQuotesButton}
                    id={`ViewInvoiceButton${this.props?.id}`}
                  >
                    View Invoice(s)
                  </Typography>
                )}

                <Dialog
                  fullWidth={
                    issue_status?.toLowerCase() === "enquiry cancelled"
                      ? false
                      : true
                  }
                  maxWidth={"md"}
                  open={this.state.open}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogContent
                    style={{
                      width:
                        issue_status?.toLowerCase() === "enquiry cancelled"
                          ? 350
                          : "100%",
                      height:
                        issue_status?.toLowerCase() === "enquiry cancelled"
                          ? 200
                          : "100%",
                      overflow: "auto",
                    }}
                  >
                    {/* {console.log(data?.invoice)} */}
                    {/* <QuoteInvoiceRenderer data={quote_data} columnsToPrint={newColumnsToPrint} createRows={createRows} isInvoice={true}/> */}
                    {issue_status?.toLowerCase() === "enquiry cancelled" ? (
                      <>
                        <DialogContentText id="alert-dialog-slide-description">
                          {/* <CheckCircleIcon style={{color:"rgb(90, 192, 172)"}}/> */}
                          <Typography
                            variant="h6"
                            style={{
                              textAlign: "center",
                              textDecoration: "underline",
                            }}
                            color="primary"
                          >
                            Enquiry Cancelled Reason
                          </Typography>
                          <Typography
                            variant="body1"
                            color="text"
                            style={{
                              display: "flex",
                              marginTop: 10,
                            }}
                          >
                            {data.cancelledRemarks}
                          </Typography>
                        </DialogContentText>
                      </>
                    ) : (
                      <object
                        data={this.state.invoiceUrl.concat("#toolbar=0")}
                        type="application/pdf"
                        width="100%"
                        height={400}
                        name={data.issue_tag}
                      >
                        <embed
                          src={this.state.invoiceUrl.concat("#toolbar=0")}
                          type="application/pdf"
                        />
                      </object>
                    )}

                    {/* // https://blog.bitsrc.io/best-practices-in-using-iframes-with-react-6193feaa1e08 */}
                    {/* <div dangerouslySetInnerHTML={{ __html: `<iframe src='${data?.invoice}' sandbox=''/>`}} /> */}
                  </DialogContent>
                  {/* <p>Invoice Details</p> */}
                </Dialog>
              </Grid>
            ) : null}
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    quotes: state?.quotenq?.getdocsforenq,
  };
};

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withStyles(styles, { withTheme: true })(MainIssueCard)));

// export default withStyles(styles)(withRouter(MainIssueCard));
