import * as React from "react"

export function PreviewPngIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={42}
      viewBox="0 0 32 42"
      {...props}
    >
      <path d="M32 42H0V0h22l10 10z" fill="#3f51b5" />
      <path
        d="M29.5 11H20V1.5zM6.81 26.5V30H4.8v-9.953h3.391a3.113 3.113 0 012.355.916 3.3 3.3 0 01.878 2.379 3.085 3.085 0 01-.868 2.311 3.293 3.293 0 01-2.407.847zm0-1.675h1.381a1.094 1.094 0 00.889-.376 1.663 1.663 0 00.314-1.094 1.979 1.979 0 00-.321-1.187 1.032 1.032 0 00-.861-.447h-1.4zM19.723 30h-2.01l-2.939-6.528V30h-2.01v-9.953h2.01l2.946 6.535v-6.535h2V30zm8.377-1.128a3.674 3.674 0 01-1.426.947 5.228 5.228 0 01-1.849.317 3.39 3.39 0 01-2.666-1.063 4.59 4.59 0 01-.978-3.093v-1.791a4.932 4.932 0 01.9-3.169 3.178 3.178 0 012.622-1.11 3.378 3.378 0 012.444.8 3.781 3.781 0 01.953 2.509h-1.953a2.289 2.289 0 00-.4-1.3 1.272 1.272 0 00-.984-.345 1.287 1.287 0 00-1.183.595 3.79 3.79 0 00-.383 1.894v1.805a3.706 3.706 0 00.407 1.979 1.48 1.48 0 001.336.618 1.752 1.752 0 00.964-.239l.178-.123v-1.821h-1.404v-1.518H28.1v4.108z"
        fill="#e8eaf6"
      />
    </svg>
  )
}
