import React, { useRef, useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  // Divider,
  // useTheme,
  CircularProgress,
  Typography
} from "@material-ui/core";
// import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { fontStyleClass, commonShadows, genericStyles } from "../../utils";
// import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  rowOneIcons: {
    border: "1px solid #E8E8EC",
    margin: "0px 4px",
    padding: 8,
  },
  customListItemIcon: {
    minWidth: 36,
  },
  legendWrapper: {
    listStyle: "none",
    // display: "flex",
    justifyContent: "space-around",
    padding: "8px 0px 8px 14px",
    flexWrap: "wrap",

    // --------
    maxHeight: "150px",
  },
  legendList: {
    display: "flex",
    // justifyContent: "center",
    // justifyContent: "flex-start",
    margin: "8px 0px",
  },

  legendListNorm: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "8px 0px",
    // paddingLeft:"15px"
  },

  legendListAdmin: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "8px 0px",
  },

  legendClass: {
    // maxHeight:"100px",
    overflow: "auto",
    justifyContent: "flex-start",
    flex: 1,
  },

  ...genericStyles.cardWrapperShadows(theme),
  ...commonShadows,
  ...fontStyleClass,
}));

function MiniChartsSkeleton({ cardLabel, ...props }) {
  const classes = useStyles(props);
  const inputEl = useRef(null);
  const [legend, setLegend] = useState([]);

  // const theme = useTheme();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setLegend(inputEl?.current?.chartInstance?.legend?.legendItems);
  });

  console.log(legend)
  return (
    <div
      style={{ height: "100%" }}
      className={`${classes.commonShadows} ${classes.cardWrapperShadows}`}
    >
      <Grid container style={{ height: "100%", textAlign: "center"}}>
        <Grid item container xs={12}>
          {/* <div style={{ padding: "12px 16px", width: "100%" }}>
            <Grid item container>
              <Grid item xs={12} container>
                <Grid item>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    
                      <Skeleton variant="rect" animation="wave" />
                  </div>
                </Grid>
                <Grid item xs>
                </Grid>
              </Grid>
            </Grid>
          </div>

 
          <Grid item xs={12}>
            <Divider />
          </Grid> */}


          <Grid item xs >
           {/*  <div style={{ margin: "8px 16px" }}>
              <Skeleton variant="rect" animation="wave" style={{ height: 135}} />
             
            </div> */}

            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                
                style={{ height: "100%", textAlign: "center", marginTop: 15, marginBottom: 15}}
              >
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <Typography variant="h6" align="center">
                    {props.messages}
                  </Typography>
                </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </div>
  );
}

export default MiniChartsSkeleton;
