import React from "react";
import {
  // Button,
  Grid,
  IconButton,
  Typography,
  withStyles,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core";
import ActivityAndMembersCard from "../../../../components/availabilityActivityComponents/ActivityAndMembersCard";
import ProfileCardActivitySummary from "../../../../components/availabilityActivityComponents/activitySummaryCards/ProfileCardActivitySummary";
import CheckInCheckOutCard from "../../../../components/availabilityActivityComponents/activitySummaryCards/checkInCheckOutCard";
import SummaryTextCard from "../../../../components/availabilityActivityComponents/activitySummaryCards/summaryTextCard";
import { ChevronLeft } from "@material-ui/icons";
import moment from "moment";
// import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LoadingSection } from "../../../../components/integerateHelper/loading";
import { InternatlServerError } from "../../../../components/integerateHelper/500";
import { colorCode } from "../activityList";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { alertProps, activityStatus } from "../../../../utils";
import { loadScript } from "../../../../components/map/loadScriptFile";
import MoreVert from "@material-ui/icons/MoreVert";
import { TextField } from "../../../../components/inputs";

const styles = (theme) => ({
  icon: {
    width: 20,
    height: 20,
  },
  cancelButton: {
    boxShadow: "unset !important",
  },
});

class ActivitySummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientLocation: null,
      addressLoading: false,
      anchorEl: false,
      setDelete: false,
    };
  }

  handleScriptLoad = (latitude, longitude) => {
    // Google Location - Geocoder API
    const geocoder = new window.google.maps.Geocoder();
    const latlng = {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          //   results[0].formatted_address;
          this.setState({
            addressLoading: false,
            clientLocation: results[0].formatted_address,
          });
        } else {
          this.setState({
            clientLocation: null,
          });
        }
      } else {
        this.setState({
          clientLocation: null,
        });
      }
    });
  };

  componentDidMount = () => {
    this.getSummaryDetail();
  };

  getSummaryDetail = async () => {
    const activityID = this.props.data.activity_id;
    await this.props.GET_ACTIVITY_SUMMARY({ activityID: activityID });
    const { data } = this.props?.summaryDetail;
    if (data?.latitude && data?.longitude) {
      this.getLocationFromLatlong();
    }
  };

  getLocationFromLatlong = () => {
    this.loadGoogleApi();
  };

  loadGoogleApi = () => {
    const { data } = this.props?.summaryDetail;
    const latitude = parseFloat(data?.latitude);
    const longitude = parseFloat(data?.longitude);
    if (
      latitude &&
      longitude &&
      latitude > -90 &&
      latitude < 90 &&
      longitude > -180 &&
      longitude < 180
    ) {
      this.setState(
        {
          addressLoading: true,
        },
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=AIzaSyBqeACJgp12OLU6EkHeUtFgya2naH1LhrU&callback=initMap&libraries=&v=weekly`,
          () => this.handleScriptLoad(latitude, longitude)
        )
      );
    }
  };

  closeDrawer = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  handleActivityDelete = async () => {
    
    console.log(this.state?.cancelation_reason);
    this.closeDrawer();
    // this.props.dialog.setDialog({ open: false });

    this.props.dialog.setDialog({
      open: true,
      title: "Confirmation",
      body: (
        <p>
          Are you sure, you want to cancel this Activity?
          <br />
          {this.state?.cancelation_reason
            ? `Reason: ${this.state?.cancelation_reason}`
            : ""}
        </p>
      ),
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: async () => {
        this.props.dialog.setDialog({ open: false });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: true,
          message: "Deleting Activity...",
        });
        let res_data = await this.props.DELETE_ACTIVITY({
          activityId: this.props.data.activity_id,
          remarks: this.state?.cancelation_reason,
        });
        console.log(res_data);
        if (res_data?.payload) {
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false,
            message: "Deleting Activity...",
          });
        }
        this.props.reload();
        // this.props.DELETE_ACTIVITY({ activityId: this.props.data.activity_id });
      },
    });
  };

  shouldComponentUpdate(nextProps) {
    // Delete Activity
    const { deleteActivity } = nextProps;
    const oldDeleteActivity = this.props.deleteActivity;

    if (oldDeleteActivity?.loading !== deleteActivity?.loading) {
      if (!deleteActivity.loading && !deleteActivity.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Activity Deleted Successfully!",
          severity: alertProps.severity.success,
        });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
        });
        // TODO:RELOAD and CLOSE DRAWER
        this.props.reload();
        this.closeDrawer();
      } else if (deleteActivity.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Delete Activity.",
          severity: alertProps.severity.error,
        });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
        });
      }
    }

    return true;
  }

  render() {
    const { hideServiceProfessional, hideClient, classes } = this.props;

    const { data, loading, error } = this.props?.summaryDetail;

    if (
      !loading &&
      !error &&
      data?.constructor === Object &&
      Object.keys(data)?.length > 0
    ) {
      console.log(
        data,
        "data?.from_time_stampdata?.from_time_stampdata?.from_time_stampdata?.from_time_stampdata?.from_time_stampdata?.from_time_stamp"
      );
    }
    return (
      <>
        {loading && <LoadingSection message="Loading activity summary..." />}
        {!loading && error && (
          <InternatlServerError
            message="Something went wrong, Try Again!"
            onRetry={this.getSummaryDetail}
          />
        )}
        {!loading && !error && !data && (
          <InternatlServerError
            message="Something went wrong, Try Again!"
            onRetry={this.getSummaryDetail}
          />
        )}
        {!loading &&
          !error &&
          data?.constructor === Object &&
          Object.keys(data)?.length > 0 && (
            <>
              <Grid container>
                <Grid
                  item
                  container
                  style={{ padding: "12px 12px 12px 0px", background: "white" }}
                >
                  <Grid item>
                    <IconButton onClick={this.props.onBackBtnClicked}>
                      <ChevronLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h6" style={{ marginTop: 8 }}>
                      Activity Summary
                    </Typography>
                  </Grid>
                  {
                    /*data?.activity_status !== activityStatus.completed &&
                    data?.activity_status !== activityStatus.inProgress &&
                    data?.activity_status !== activityStatus.cancelled &&
                    data?.activity_status !== activityStatus.closed && */
                    <>
                      <IconButton
                        id="edit_deactivate_menu_activity"
                        onClick={(event) => {
                          event.stopPropagation();
                          this.setState({ anchorEl: event.currentTarget });
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={(e) => {
                          e.stopPropagation();
                          this.setState({ anchorEl: null });
                        }}
                      >
                        {data?.activity_status !== activityStatus.completed &&
                          data?.activity_status !== activityStatus.inProgress &&
                          data?.activity_status !== activityStatus.cancelled &&
                          data?.activity_status !== activityStatus.closed && (
                            <>
                              <MenuItem
                                id="edit_menu_activity"
                                onClick={(e) => {
                                  this.props.onEditBtnClicked(
                                    1,
                                    this.props.data
                                  );
                                }}
                              >
                                Edit Activity
                              </MenuItem>
                              <MenuItem
                                id="cancel_menu_activity"
                                onClick={(e) => {
                                  this.setState({ setDelete: true });
                                  this.setState({ anchorEl: false });
                                  // this.handleActivityDelete(data?.activity_id)
                                }}
                              >
                                Cancel Activity
                              </MenuItem>
                              <MenuItem
                                id="reschedule_menu_activity"
                                onClick={(e) => {
                                  // this.setState({anchorEl: false}, () => {this.props.onRescheduleBtnClicked(this.props, data)})
                                  this.setState({ anchorEl: false }, () => {
                                    this.props.onRescheduleBtnClicked(
                                      1,
                                      this.props.data
                                    );
                                  });
                                }}
                              >
                                Reschedule Activity
                              </MenuItem>

                              <MenuItem
                                id="reschedule_menu_activity"
                                onClick={(e) => {
                                  // this.setState({anchorEl: false}, () => {this.props.onRescheduleBtnClicked(this.props, data)})
                                  this.setState({ anchorEl: false }, () => {
                                    this.props.activityLogBtnClicked(
                                      1,
                                      this.props.data
                                    );
                                  });
                                }}
                              >
                                Activity Log
                              </MenuItem>
                            </>
                          )}

                        {data?.activity_status === activityStatus.completed ||
                          data?.activity_status === activityStatus.cancelled ||
                          data?.activity_status === activityStatus.closed ? (
                            <>
                              <MenuItem
                                id="reschedule_menu_activity"
                                onClick={(e) => {
                                  // this.setState({anchorEl: false}, () => {this.props.onRescheduleBtnClicked(this.props, data)})
                                  this.setState({ anchorEl: false }, () => {
                                    this.props.activityLogBtnClicked(
                                      1,
                                      this.props.data
                                    );
                                  });
                                }}
                              >
                                Activity Log
                              </MenuItem>
                            </>
                          ) : (<></>)}
                      </Menu>
                    </>
                  }
                </Grid>

                <ActivityAndMembersCard
                  hideProfilePicture
                  title={data?.activity_name}
                  // date={moment(data?.from_time_stamp).format('dddd, MMMM D')}
                  timing={
                    data?.from_time_stamp && data?.to_time_stamp
                      ? `${moment(data?.from_time_stamp).format(
                          "Do MMM hh:mm A"
                        )} - ${moment(data?.to_time_stamp).format(
                          "Do MMM hh:mm A"
                        )}`
                      : undefined
                  }
                  hideIsActive
                  activityStatus={data?.activity_type?.label} //activity_type will be a object with {value, label}
                  activityStatusColor="#28a9c1"
                  currentStatus={data?.activity_status}
                  currentStatusColor={colorCode[data?.activity_status]}
                  // useActivityAsSummaryCard
                  hideEndIcon
                  padding={2}
                />

                <SummaryTextCard
                  description={data?.activity_description}
                  padding={2}
                />

                {!hideClient && (
                  <ProfileCardActivitySummary
                    id={data?.client_info?.id}
                    profilePicture={data?.client_info?.profile_pic}
                    name={data?.client_info?.client_name}
                    gender={data?.client_info?.gender}
                    age={data?.client_info?.age}
                    address={this.state.clientLocation}
                    //videoCallUrl
                    meetingUrl={
                      data?.mode === "VIDEO_CALL" ? data?.videoCallUrl : false
                    }
                    addressLoading={this.state.addressLoading}
                    padding={2}
                    hideQuickIcons
                    hideAddressSection={false}
                  />
                )}

                {!hideServiceProfessional && (
                  <div style={{ display: "block", padding: 16 }}>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      gutterBottom
                    >
                      SERVICE PROFESSIONAL:
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {data?.service_professional_info?.label}
                    </Typography>
                  </div>
                )}

                <Grid item xs={12}>
                  <CheckInCheckOutCard
                    leftText="Check - In"
                    leftSubText={
                      data?.check_in?.updatedAt
                        ? moment(data?.check_in?.updatedAt).format("hh:mm A")
                        : " - "
                    }
                    rightText="Check - Out"
                    rightSubText={
                      data?.check_out?.updatedAt
                        ? moment(data?.check_out?.updatedAt).format("hh:mm A")
                        : " - "
                    }
                    padding={2}
                  />
                </Grid>

                {this.state?.setDelete && (
                  <>
                    <Grid container>
                      <Grid
                        item
                        container
                        style={{
                          padding: "12px 12px 12px 12px",
                          background: "white",
                        }}
                      >
                        <TextField
                          id={`reasonForCancellation`}
                          required={true}
                          type={"text"}
                          // withBoldLabel={component.withBoldLabel}
                          // withoutUppercase={component?.withoutUppercase}
                          onlyText={false}
                          // limit={component.limit}
                          // state={component.key}
                          // isLimitHelperTextNeeded={
                          //   component?.componentProps?.isLimitHelperTextNeeded
                          // }
                          // overrideDecimalCheckForDiscount={
                          //   component?.componentProps?.overrideDecimalCheckForDiscount
                          // }
                          rowsMax={5}
                          multiline={true}
                          title={"Cancelation Reason"}
                          placeholder={"Reason For Cancelation"}
                          defaultValue=""
                          value={this.state.cancelation_reason}
                          handleOnChange={(e) => {
                            console.log(e.target?.value);
                            this.setState({
                              cancelation_reason: e.target?.value,
                            });
                          }}
                          error={false}
                          errorText={"Reason Needed"}
                          disabled={false}
                        />
                        <div className={classes.saveBtnContainer}>
                          {/* {
            !this.state.openDrawer.open && */}
                          <Button
                            variant={"outlined"}
                            color={"primary"}
                            onClick={() => this.setState({ setDelete: false })}
                            style={
                              this.props.cancelButtonStyles
                                ? {
                                    marginRight: 12,
                                    ...this.props.cancelButtonStyles,
                                  }
                                : { marginRight: 12 }
                            }
                            id={this.props?.cancelButtonId}
                          >
                            {"Cancel"}
                          </Button>
                          {/* } */}
                          <Button
                            variant={"contained"}
                            color={"primary"}
                            className={classes.saveBtn}
                            style={
                              this.props.saveButtonStyles
                                ? {
                                    margin: 16,
                                    padding: "5px 40px",
                                    ...this.props.saveButtonStyles,
                                  }
                                : { margin: 16, padding: "5px 40px" }
                            }
                            disabled={false}
                            id={this.props?.saveButtonId}
                            onClick={this.handleActivityDelete}
                          >
                            {"Done"}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  summaryDetail: state?.clientActivity?.activitySummary,
  deleteActivity: state?.activity_app?.delete_activity ?? {
    data: [],
    loading: false,
    error: false,
  },
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(ActivitySummary))));
