import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { genericStyles } from '../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: `0px 0.5px 2px 1px #e0e0e0d6`,
        padding: 16,
        backgroundColor: "white",
        height: 90,
        borderRadius: 8,
    },
    ...genericStyles.cardWrapperShadows(theme)
}))

export const CountCardError = (props) => {

    const classes = useStyles();

    return <div className={`${classes.root} ${classes.cardWrapperShadows}`}>
        <Grid container direction="row" justify="center" alignItems="center">              
            <Grid item xs={12}>
                <Typography variant="h6" color="textSecondary" align="center">{"Something went wrong!"}</Typography>
                <Typography variant="body2" color="textSecondary" align="center">{"Unable to Fetch"}</Typography>
            </Grid>     
        </Grid>
    </div>
}
