import { makeStyles } from "@material-ui/core"
import * as React from "react"

const useStyles = makeStyles((theme) => ({
  prefix__cls: (props) => ({
    fill: props.color || "#28a9c1"
  })
}))


export function UserIcon(props) {
  const classes = useStyles(props)
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" style={props.style} {...props}>
      <path
        d="M7.458 18l.667-4-.5-1L9 12l1.375 1-.5 1 .667 4zM9 0a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"
        className={classes.prefix__cls}
        data-name="Path 584"
      />
      <path
        d="M9 11c-3.141 0-9 1.545-9 4.5V18h18v-2.5c0-2.955-5.859-4.5-9-4.5zm7 5H2v-.5c0-.869 3.708-2.5 7-2.5s7 1.631 7 2.5z"
        className={classes.prefix__cls}
        data-name="Path 585"
      />
      <path d="M12 15h2v2h-2z" className={classes.prefix__cls} data-name="Path 586" />
    </svg>
  )
}

