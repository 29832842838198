import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: `0px 0.5px 2px 1px #e0e0e0d6`,
    // padding: 16,
    backgroundColor: "white",
    // height: 90,
    borderRadius: 8,
  },
  upperPart: {
    borderBottom: `solid 1px #e9e9e9`,
    padding: 16
  },
  iconLoaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10
  },
  bottomLoader: {
    margin: "auto"
  }
}))

export const IssueCardLoading = (props) => {

  const classes = useStyles();

  return <div className={classes.root}>
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item xs={12} container justify="space-between" className={classes.upperPart}>
        <Grid item>
          <Skeleton variant="text" width="100px" />
          <Skeleton variant="text" width="220px" style={{ marginTop: 8 }} />
          <Skeleton variant="text" width="160px" style={{ marginTop: 8 }} />
        </Grid>
        <Grid item className={classes.iconLoaderContainer}>
          <Skeleton variant="circle" width="40px" height="40px" />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ padding: 16 }}>
        <Skeleton variant="text" width="100px" className={classes.bottomLoader} />
      </Grid>
    </Grid>
  </div>
}
