import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import makeStyles from '@material-ui/styles/makeStyles';
import Slide from '@material-ui/core/Slide';
import { Typography } from '@material-ui/core';
// import { printQuote } from "./printQuote";
import { TickIcon, DownloadIcon } from '../../../../../assets';
import { createTestProps } from '../../../../../utils/common';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      borderRadius: 12,
      backgroundColor: '#FFFFFF',
      border: '2px solid #E0E0E0',
      '& .MuiDialogContent-root': {
        padding: theme.spacing(4),
        textAlign: 'center'
      }
    }
  },
  typo: {
    color: '#363A57',
    fontSize: 18,
    fontWeight: 400,
    textAlign: 'center',
    marginBlock: theme.spacing(3)
  },
  downloadBtn: {
    border: '1px dashed #31B198',
    borderRadius: 8,
    padding: '11px 30px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#31B198',
    fontSize: 16,
    fontWeight: 400,
    justifyContent: 'center',
    maxWidth: 240,
    margin: 'auto',
    marginBottom: theme.spacing(3),
    cursor: 'pointer'
  },
  closeBtn: {
    minWidth: 94,
    textTransform: 'capitalize',
    padding: '7.75px'
  }
}))

export default function DialogSuccess(props) {
  const { open, handleClose, downloadData, } = props
  const classes = useStyles(props);
  const downloadPdf = () => {
    downloadData()
    // if(pdfUrl && fileName){
    //   convertToBase64URL(pdfUrl, fileName)
    // }
    // else{
    //   this.props.alert.setSnack({
    //     open: true,
    //     horizontal: alertProps.horizontal.center,
    //     vertical: alertProps.vertical.top,
    //     msg: "Something went wrong!",
    //     severity: alertProps.severity.error,
    //   });
    // }
    handleClose()

  }
  return (
    <div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        classes={{
          root: classes.dialog
        }}
      >

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TickIcon style={{marginTop:20}} />
            <Typography className={classes.typo}>
              Quote has been emailed successfully
            </Typography>
            <Typography  {...createTestProps("click to download")} className={classes.downloadBtn} variant="body1" color="primary" onClick={() => downloadPdf()}>
              <DownloadIcon id="5852e632-5e21-4156-9213-c27666b11941_CLICK_TO_DOWNLOAD_ICON" style={{ marginRight: 16 }} /> Click to download
            </Typography>
          </DialogContentText>
          <Button className={classes.closeBtn} id="1a2b7cb7-519a-435f-9944-894809e45bf4_CLOSE_CLICK_TO_DOWNLOAD" onClick={handleClose} variant="contained" color="primary">
            close
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
