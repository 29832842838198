import React from "react";

import { Grid, withStyles } from "@material-ui/core";

import PriceCard from "./priceCard";

const styles = (theme) => ({
  root: {
    // height: 600,
    // maxWidth: 480,
    // margin: 10,
    // background: 'white',
    // borderRadius: 8,
    // boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04)",
    marginTop: 30,
  },
});

const color = [
  {color:"#353596",background:'lightViolet'},
  {color:"#28a9c1",background:'pacificBlue'},
  {color:"#f27d35" ,background:'pumpkinOrange'}
]

class ProductPriceCard extends React.Component {
  render() {
    
    const { classes, items } = this.props;
    
    return <div className={classes.root}>
      <Grid container direction="row" spacing={4}>
        {items && items?.rows?.map((ob, key) => {          
          return <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={key}>
            <PriceCard {...ob} {...color[Math.floor(Math.random() * color.length)]}/>
          </Grid>
        })}
      </Grid>
    </div>
  }
}

export default withStyles(styles)(ProductPriceCard);
