import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || "#9297a5"
  })
}))

export function BackIcon(props) {
  const classes = useStyles(props)
  return (
    <SvgIcon style={{ height: props.height, width: props.width }}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="8.73" height="14" viewBox="0 0 8.73 14"> */}
      <path className={classes.cls1} d="M13.93 5.93l-7 7 7 7 1.73-1.73-5.27-5.27 5.27-5.27z" />
    </SvgIcon>
  )
}

BackIcon.propTypes = {
  color: PropTypes.string
}