import React from "react";
import {
  makeStyles,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Button,
  Hidden
} from "@material-ui/core";
import DetailSection from "./detailSection";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";
import CountLabel from "./countLabel";
import { genericStyles } from "../../utils";
// import { QuickSummaryIcon } from "../../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 12,
    // boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    [theme.breakpoints.down("sm")]: {
        position: "relative"
    },
  },
  menuItem: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`
  },
  dividerBackground: {
    background: theme.palette.secondary.light,
    width: 0.5
  },
  // To remove padding in top and bottom in menu open
  myListStyle: {
    paddingTop: 0,
    paddingBottom: 0
  },
  //To set a fixed width of popover
  myListStyleRoot: {
    width: 120
  },
  buttonStyle: {
      width: 100
  },
  ...genericStyles.cardWrapperShadows(theme)
}));

const ListCard = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    props.handleEdit(props?.data?.id, props?.data?.versionId);
  };

  // const handleDelete = () => {
  //     setAnchorEl(null);
  //     props.handleDelete(props.data);
  // }

  const handleDeactivate = () => {
    setAnchorEl(null);
    props.handleDeactivate(props.data);
  };
  const classes = useStyles();
  
  return (
    <div className={`${classes.root} ${classes.cardWrapperShadows}`}>
      <Grid container alignItems="center" spacing={1}>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md
          alignItems={"center"}
          onClick={() => props.navigateToView(props?.data?.id, props?.data?.versionId)}
        >
          <DetailSection
            data={props.data}
            hideStatus={props.hideStatus}
            hideDivider={props.hideDivider}
            fixCategoryForSupervisorList={props.fixCategoryForSupervisorList}
            open={props?.open}
            handlePopperClose={props?.handlePopperClose}
            handlePopperOpen={props?.handlePopperOpen}
            popperData={props?.popperData}
          />
        </Grid>
        <Grid item container xs={12} sm={12} md={6} alignItems={"center"}>
          {props?.data?.orderList?.map((list, index) => {
            
            return (
              <Grid item xs style={{ display: "flex" }}>
                <CountLabel
                  {...list}
                  isCountLabelAPrice={props.isCountLabelAPrice}
                  setPriceTrailingDecimal={props.setPriceTrailingDecimal}
                />
                {index !== props?.data?.orderList?.length - 1 && (
                  <Divider
                    orientation="vertical"
                    className={classes.dividerBackground}
                    style={{ height: 60 }}
                  />
                )}
              </Grid>
            );
          })}

          <Hidden smDown>
            {/* {props?.isMoreBtn && ( */}
              <div style={{
                display: "flex", justifyContent: "flex-end"}}>
              <Grid item >
                <IconButton disabled={props?.isMoreBtn ? false : true} onClick={handleClick}>
                  <MoreVertIcon style={{color: props?.isMoreBtn ? "gray" : "#fff"}} />
                </IconButton>
              </Grid>
              </div>
            {/* )} */}
          </Hidden>
          {/* <Hidden mdUp>
            {props?.isMoreBtn && (
              <Grid item xs={12} container spacing={2} alignItems={"center"} style={{position: "absolute", right: 0, top: 0}}>
                <Grid item xs style={{display: "flex", justifyContent: "flex-end"}}>
                  <Button variant="contained" color="primary"  onClick={handleEdit} className={classes.buttonStyle}>Edit</Button>
                  
                </Grid>
                <Grid item xs>
                  <Button variant="outlined" color="primary"  onClick={handleDeactivate} className={classes.buttonStyle}>
                    {props?.data?.isactive ? "Deactivate" : "Activate"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Hidden> */}
          <Hidden mdUp>
            {/* {props?.isMoreBtn && ( */}
              <Grid item style={{position: "absolute", right: 0, top: 0}}>
                <IconButton disabled={props?.isMoreBtn ? false : true} onClick={handleClick}>
                  <MoreVertIcon style={{color: props?.isMoreBtn ? "gray" : "#fff"}} />
                </IconButton>
              </Grid>
            {/* )} */}
          </Hidden>

          {props?.isRecommendBtnNeeded && (
            <Grid item style={{ display: "flex" }}>
              <Divider
                orientation="vertical"
                className={classes.dividerBackground}
                style={{ height: 60 }}
              />
              <Button
                variant="outlined"
                color="primary"
                style={{ marginLeft: 25 }}
              >
                RECOMMEND
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Menu */}
      <Menu
        id="more_card"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          list: classes.myListStyle
        }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={handleEdit}
          classes={{
            root: classes.myListStyleRoot
          }}
        >
          Edit
        </MenuItem>
        {/* <MenuItem className={classes.menuItem} onClick={handleDelete}>Delete</MenuItem> */}
        <MenuItem
          className={classes.menuItem}
          onClick={handleDeactivate}
          classes={{
            root: classes.myListStyleRoot
          }}
        >
          {props?.data?.isactive ? "Deactivate" : "Activate"}
        </MenuItem>
      </Menu>
    </div>
  );
};

ListCard.propTypes = {
  data: PropTypes.object,
  isMoreBtn: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDeactivate: PropTypes.func,
  navigateToView: PropTypes.func
};

export default ListCard;

ListCard.propTypes = {
  /**Use if the Orderlist label is too long and stacking up. This prop will make the breakpoint ```xs={5}``` instead of the default ```xs={3}```. */
  // isOrderlistLong: PropTypes.bool,
  /**Use if Quick summary icon needs to be displayed. This defaults to ```false``` */
  changeMoreToSummaryButton: PropTypes.bool,
  /**Change category as card and careprovider as not a card. */
  fixCategoryForSupervisorList: PropTypes.bool
};
