import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { AddressIcon } from '../../../assets'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  // root: (props) => ({
  //   background: 'white',
  //   padding: theme.spacing(props.padding) || theme.spacing(1.5),
  // }),
  icon: {
    margin: theme.spacing(0, 1, 0, 0.4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative',
    top: -1
  }
}))

export function AddressSection(props) {
  const classes = useStyles()
  let { address, color, addressLoading } = props
  return (
    <Grid container alignItems="center"> {/*className={classes.root} */}
      <Grid item>
        <div className={classes.icon} >
          <AddressIcon color={color ? color : ''} /> {/*width={18} height={18} */}
        </div>
      </Grid>
      <Grid item xs>
      {/* addressLoading */}
        {addressLoading && <Skeleton variant="text" width="200px" /> }
        {!addressLoading && <Typography variant="body1">{address ? address : ''}</Typography>}
      </Grid>
    </Grid>
  )
}
