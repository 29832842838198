import React from "react";
import { Grid,
  //  Avatar, makeStyles, useTheme
   } from "@material-ui/core";
import { CatalogueDetail } from "./catalogueDetail";
import { CustomAvatar } from "../niceComponents";
// import { avatarColorBgById } from "../../utils";
// import Skeleton from "@material-ui/lab/Skeleton";

// const customAvatarStyle = makeStyles((theme) => ({
//   root: {
//     margin: theme.spacing(0, 1),
//   },
//   avatarSize: {
//     width: theme.spacing(6.25),
//     height: theme.spacing(6.25),
//   },
//   skeleton: {
//     margin: theme.spacing(0, 1),
//   }
// }));

// function CustomAvatar({ src, name, id, loading, fallbackAsImage }) {
//   const defaultImage = "/images/tanyaCare/Tanya.png"
//   const classes = customAvatarStyle();
//   const theme = useTheme()
//   const fallbackText = name?.toString()?.toUpperCase()?.trim()?.[0];
//   const fallbackId =
//     id?.[id?.toString()?.search(/\d/)] *
//     id?.toString().split("").reverse()?.[id?.toString().split("").reverse().join("")?.search(/\d/)];

//   return (
//     <>
//       {loading && (
//         <Skeleton variant="circle" className={classes.skeleton} width={theme.spacing(6.25)} height={theme.spacing(6.25)} />
//       )}
//       {!loading && (
//         <Avatar
//           src={src ? src : fallbackAsImage ? defaultImage : null}
//           className={`${classes.root} ${classes.avatarSize}`}
//           style={{
//             backgroundColor: "unset"
//           }}
//         >
//           <Avatar
//             src={fallbackAsImage ? defaultImage : null}
//             className={`${classes.root} ${classes.avatarSize}`}
//             style={{
//               backgroundColor: fallbackAsImage ? "unset" : avatarColorBgById(fallbackId)
//             }}
//           >
//             {fallbackAsImage ? "" : fallbackText}
//           </Avatar>
//         </Avatar>
//       )}
//     </>
//   );
// }

export function CatalogueDetailWrapper({ catalogue, loading, showProvider, showTrialExpiry, avatarFallbackAsImage, hideStatus, ...props }) {
  console.log(catalogue)
  return (
    <>
      <Grid item>
        <CustomAvatar
          src={catalogue?.profile_pic}
          name={catalogue?.name || catalogue?.label}
          id={catalogue?.id}
          loading={loading}
          fallbackAsImage={avatarFallbackAsImage}
        />
      </Grid>
      <Grid item xs>
        <CatalogueDetail detail={catalogue} loading={loading} showProvider={showProvider} showTrialExpiry={showTrialExpiry} trialExpiry={catalogue?.resourceEndsOn} hideStatus={hideStatus} />
      </Grid>
    </>
  );
}
