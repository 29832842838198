import React, { Component } from "react";
import PropTypes from "prop-types";
import AddEditDynamicFromRenderer from "../../../addEditDynamicFromRenderer";
import { components } from "../../../addEditDynamicFromRenderer/components";

class AddEditCareGiver extends Component {
  render() {
    return <AddEditDynamicFromRenderer component={components.PROFESSIONAL} />;
  }
}

AddEditCareGiver.propTypes = {
  data: PropTypes.array,
};

export default AddEditCareGiver;
