import * as React from "react"

export const CenterAlignIcon = (props) => {

    const {
        width = "24.768",
        height = "20.082",
        color = "#6f6f6f"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24.768 20.082"
        >
            <g id="Group_95498" data-name="Group 95498" transform="translate(-7.056 -11.696)">
                <g id="Group_95499" data-name="Group 95499" transform="translate(9.139 11.477)">
                    <g id="Group_95494" data-name="Group 95494" transform="translate(2.103 6.44)">
                        <g id="Group_95493" data-name="Group 95493">
                            <path id="icons8-left-align" d="M21.9-.253H35.866A.637.637,0,0,0,36.5-.888a.637.637,0,0,0-.635-.635H21.9a.637.637,0,0,0-.635.635A.637.637,0,0,0,21.9-.253Z" transform="translate(-21.261 1.523)" fill={color} />
                        </g>
                    </g>
                    <g id="Group_95496" data-name="Group 95496" transform="translate(2.103 18.745)">
                        <g id="Group_95495" data-name="Group 95495">
                            <path id="icons8-left-align-2" data-name="icons8-left-align" d="M21.9-.253H35.866A.637.637,0,0,0,36.5-.888a.637.637,0,0,0-.635-.635H21.9a.637.637,0,0,0-.635.635A.637.637,0,0,0,21.9-.253Z" transform="translate(-21.261 1.523)" fill={color} />
                        </g>
                    </g>
                </g>
                <g id="Group_95497" data-name="Group 95497" transform="translate(7.056 11.696)">
                    <path id="icons8-left-align-3" data-name="icons8-left-align" d="M45.286-6.126H22.005a.746.746,0,0,0-.744.744.746.746,0,0,0,.744.744H45.286a.746.746,0,0,0,.744-.744A.746.746,0,0,0,45.286-6.126Zm0,12.421H22.005a.744.744,0,1,0,0,1.488H45.286a.744.744,0,0,0,0-1.488ZM22.005,13.956h0Z" transform="translate(-21.261 6.126)" fill={color} />
                </g>
            </g>
        </svg>
    );
};
