import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || "#9297a5"
  })
}))

export const CertificateIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon style={props.style} viewBox={"0 0 30 30"}>
      <path className={classes.cls1} d="M16.5 2a1.11 1.11 0 0 0-.773.314L13.6 4.364l-2.92-.413a.991.991 0 0 0-.157-.011 1.113 1.113 0 0 0-1.1.922l-.502 2.906L6.317 9.15a1.113 1.113 0 0 0-.479 1.472l1.3 2.65-1.3 2.65a1.113 1.113 0 0 0 .479 1.478l2.119 1.123L1 25.955l4.227 1.409 1.409 4.227 8.21-8.21.881.85a1.11 1.11 0 0 0 1.547 0l.881-.85 8.21 8.21 1.409-4.227L32 25.955l-7.436-7.436 2.119-1.119a1.113 1.113 0 0 0 .479-1.472l-1.3-2.65 1.3-2.65a1.113 1.113 0 0 0-.479-1.472l-2.606-1.388-.509-2.906a1.114 1.114 0 0 0-1.1-.922 1.19 1.19 0 0 0-.157.011l-2.923.413-2.119-2.05A1.107 1.107 0 0 0 16.5 2zm0 3.484l.936.905.991.958 1.365-.193 1.285-.182.226 1.28.239 1.357 1.216.647 1.15.611-.572 1.167-.605 1.238.605 1.238.57 1.167-1.15.611-1.216.644-.24 1.358-.226 1.283-1.288-.182-1.362-.2-.991.961-.933.905-.936-.905-.991-.958-1.365.193-1.285.182-.226-1.28-.239-1.357-1.214-.647-1.15-.611.57-1.167.605-1.238-.605-1.238-.57-1.167 1.15-.611 1.216-.647.24-1.353.226-1.28 1.288.182 1.362.193.991-.958zM9.405 21.535l.028.149a1.114 1.114 0 0 0 1.1.922 1.191 1.191 0 0 0 .157-.011l1.123-.16-3.946 3.941-.413-1.238-1.238-.413zm14.19 0l3.19 3.19-1.238.413-.413 1.238-3.941-3.941 1.123.16a1.173 1.173 0 0 0 .157.011 1.113 1.113 0 0 0 1.1-.922z" transform="translate(-1 -2)" />
    </SvgIcon>
  )
}

CertificateIcon.propTypes = {
  /**Sets the color of the icon. */
  color: PropTypes.string,
  /**Adds additional styles for the icon.*/
  style: PropTypes.object
}