import React from "react";
import {
  Card,
  CardContent,
  makeStyles,
  Typography,
  Divider,
  Menu,
  MenuItem,
  IconButton
} from "@material-ui/core";
import PropTypes from "prop-types";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const OrderListStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: "flex",
    justifyContent: "center",
    boxShadow: "unset",
    ...props.style
  }),
  groot: (props) => ({
    display: "flex",
    height:"fit-content",
    justifyContent: "center",
    padding:"auto",
    boxShadow: "unset",
    ...props.style
  }),
  content: {
    // flex: "1 0 auto",
    padding: "0px 12px",
    "&:last-child": {
      paddingBottom: 0
    }
  }
}));

function OrderList(props) {
  const classes = OrderListStyles(props);
  
  console.log(props?.isLabelNeeded)
  return (
    <>
      {props.label && props.value?.toString() ? (
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6" align={"center"}>
              {props.value}
            </Typography>
            
            <Typography
              variant="body2"
              color="textSecondary"
              align={"center"}
              noWrap
            >
              {props?.label}
            </Typography>
          </CardContent>
        </Card>
      ):
        props.value?.toString() && (
          <div style={{height:"fit-content", margin:"auto"}}>
          <div className={classes.groot}>
          {/* <div className={classes.content}> */}
            <Typography component="h6" variant="h6" align={"center"} >
              {props.value}
            </Typography>
          {/* </div> */}
        </div>
        </div>
        )
      }
    </>
  );
}

OrderList.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

OrderList.defaultProps = {
  label: "",
  value: ""
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  dividerWrapper: {
    display: "flex",
    alignItems: "center"
  },
  divider: {
    // height: 36,
  }
}));

export function QuickList({ list, loading, handleItemEdit, handleItemDeactivate, isQuickAccessNeeded, ...props }) {
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = React.useState(null);
  return (
    <>
      {!loading && (
        <div className={classes.root}>
          {list?.map((item, index) => (
            <>
              {item?.value?.toString() && (
                <div key={index} className={classes.dividerWrapper}>
                  <Divider
                    orientation={"vertical"}
                    className={classes.divider}
                  />
                </div>
              )}
              <OrderList
                isLabelNeeded={props?.isLabelNeeded}
                key={"List"+index}
                label={item?.label}
                value={item?.value}
                style={item?.style ?? {}}
              />
            </>
          ))}
          {isQuickAccessNeeded && <IconButton onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
          }}>
            <MoreVertIcon />
          </IconButton>}
          
          {/* Menu */}
          {isQuickAccessNeeded && <Menu
            id="edit_deactivate_menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={(e) => {e.stopPropagation(); setAnchorEl(null)}}
            // classes={{
            //   list: classes.myListStyle
            // }}
          >
            <MenuItem
              // className={classes.menuItem}
              onClick={(e) => {e.stopPropagation(); handleItemEdit()}}
              // classes={{
              //   root: classes.myListStyleRoot
              // }}
            >
              Edit
            </MenuItem>
            <MenuItem
              // className={classes.menuItem}
              onClick={(e) => {e.stopPropagation(); handleItemDeactivate()}}
              // classes={{
              //   root: classes.myListStyleRoot
              // }}
            >
              {props?.status?.toLowerCase() === "active" ? "Deactivate" : "Activate"}
            </MenuItem>
          </Menu>}
        </div>
      )}
    </>
  );
}

QuickList.propTypes = {
  list: PropTypes.array
};

QuickList.defaultProps = {
  list: []
};
