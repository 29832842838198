import React, { useEffect, useState } from "react";
import { alertProps } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "tanyacare-middleware";
// import { auditGetMessage } from "../../utils/common";
// import JsonFormBuilder from "../addEditForm";
import { genderOptions, relationOptions } from "../../utils/constants";
// import { v4 as uuidv4 } from "uuid";
// import IdTypeComponent from "../SelectComponentWithSubComponent/idTypeComponent";
// import PhoneComponent from "../SelectComponentWithSubComponent/phoneComponent";
// import AddressComponent from "../SelectComponentWithSubComponent/addressComponent";
import { withAlertAndDialog } from "../../HOCs";
import { DateTimePickersCustom } from "..";
import {
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  withStyles,
  Divider,
} from "@material-ui/core";
import { InputAdornmentComponent, SimpleSelect, TextField } from "../inputs";
import ModernViewIdCardDetail from "../SelectComponentWithSubComponent/idType/modernViewCard";
import ModernViewMobileCardDetail from "../SelectComponentWithSubComponent/mobile/modernViewCard";
import ModernViewAddressCardDetail from "../SelectComponentWithSubComponent/address/modernViewCard";

const { GET_SAL, GET_LANG, MASTER_PHONE_CODE } = actions;

const GreenCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.success.main,
    "&$checked": {
      color: theme.palette.success.main,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

function FamilyMembersAddForm(props) {
  const dispatch = useDispatch();

  const {
    list,
    isEdit,
    editData, //Used for edit mode
    persistData, //Used to persist state
    updateState,
    // callBackOnSave,

    // Back
    goBackHandler,

    // Id
    callBackOnSaveId,
    onAddEditDrawerId,

    // Mobile
    callBackOnSavePhone,
    onAddEditDrawerPhone,

    // Address
    callBackOnSaveAddress,
    onAddEditDrawerAddress,
  } = props;

  // Store States
  const salutation = useSelector((state) => state?.masterModule?.getsal);
  const salutationOptions = salutation?.data ?? [];

  // const loginData = useSelector((state) => state?.authState?.loginAuth.data);

  // Component States
  //   const [idType, setIdType] = useState([]);
  //   const [phone, setPhone] = useState([]);
  //   const [address, setAddress] = useState([]);
  const [state, setState] = useState({
    isEmergencyContact: false,
    salutation: "",
  });
  const [error, setError] = useState({});
  const [memberList, setMemberList] = useState([]);

  useEffect(() => {
    setMemberList(list);
  }, [list]);

  // Update Form State on edit mode
  //   useEffect(() => {
  //     setState({ ...editData });
  //   }, [editData]);

  // Update Form State on persist mode
  useEffect(() => {
    if (isEdit) {
      setState({
        ...persistData,
        ...editData,
        salutation:
          editData?.salutation &&
          editData?.salutation?.label &&
          editData?.salutation?.value
            ? editData?.salutation?.value
            : editData?.salutation,
      });
    } else {
      setState({ ...persistData });
    }
  }, [isEdit, editData, persistData]);

  const auditLogPayload = (action) => ({
    // Audit Log Payload
    logFile: "userManagement/clients/addEdit/index.js",
    // logMessage: auditGetMessage(
    //   loginData?.data?.email,
    //   action,
    //   `Client ${isEdit ? "Edit" : "Add"}`
    // ),
  });

  useEffect(() => {
    dispatch(GET_SAL({ ...auditLogPayload("GET_SAL") }));
    dispatch(GET_LANG({ ...auditLogPayload("GET_LANG") }));
    dispatch(MASTER_PHONE_CODE({ ...auditLogPayload("MASTER_PHONE_CODE") }));
  }, [dispatch]);

  // Id Handlers
  //   const callBackOnSaveId = (data, isremove) => {
  //     if (isremove) {
  //       let isUpdateData = idType?.filter((val) => val.value !== data?.value);
  //       setIdType(isUpdateData);
  //     } else {
  //       props.drawer.setDrawer({
  //         component: <></>,
  //         open: false,
  //       });
  //       if (data) {
  //         if (idType?.length > 0) {
  //           let isUpdateData = idType?.findIndex((val) => val.id === data?.id);
  //           if (isUpdateData > -1) {
  //             let _newData = {
  //               ...data,
  //               id_type: data.id_type,
  //               label: data.id_type_name.label,
  //               value: data.id_type_name.value,
  //               id_photo: data.id_photo,
  //               file: data.id_photo,
  //               id: uuidv4(),
  //             };
  //             let newArray = [...idType];
  //             newArray[isUpdateData] = _newData;
  //             setIdType(newArray);
  //           } else {
  //             // let {idType} = this.state
  //             let _newData = {
  //               ...data,
  //               id_type: data.id_type,
  //               label: data.id_type_name.label,
  //               value: data.id_type_name.value,
  //               id_photo: data.id_photo,
  //               file: data.id_photo,
  //               id: uuidv4(),
  //             };
  //             // idType.push(_newData)
  //             setIdType([...idType, _newData]);
  //           }
  //         } else {
  //           let _newData = {
  //             ...data,
  //             id_type: data.id_type,
  //             label: data.id_type_name.label,
  //             value: data.id_type_name.value,
  //             id_photo: data.id_photo,
  //             file: data.id_photo,
  //             id: uuidv4(),
  //           };
  //           setIdType([...idType, _newData]);
  //         }
  //       }
  //     }
  //   };

  //   const onAddEditDrawerId = (name, e, list) => {
  //     // console.log(list);
  //     props.drawer.setDrawer({
  //       ...props.drawer,
  //       component: (
  //         <IdTypeComponent
  //           id="AddEditClient"
  //           list={list}
  //           callBackOnSave={callBackOnSaveId}
  //           backNeeded
  //           backHandler={() => {}}
  //         />
  //       ),
  //       open: true,
  //       isMedium: false,
  //     });
  //   };

  //   const viewDetailsIdEditDetails = (isEdit, editData, data, salutation) => {
  //     let _arrayOfJson = [];
  //     if (data?.length > 0) {
  //       _arrayOfJson = data;
  //     }
  //     return _arrayOfJson;
  //   };

  // Contact Handlers
  //   const callBackOnSavePhone = (data, isremove) => {
  //     if (isremove) {
  //       let isUpdateData = phone?.filter((val) => val.value !== data?.value);
  //       setPhone(isUpdateData);
  //     } else {
  //       props.drawer.setDrawer({
  //         component: <></>,
  //         open: false,
  //       });
  //       if (data) {
  //         if (phone?.length > 0) {
  //           let isUpdateData = phone?.findIndex((val) => val.id === data?.id);
  //           if (isUpdateData > -1) {
  //             let _newData = {
  //               ...data,
  //               mobileNo: data?.mobilenumber,
  //               mobileNoIsdCode: data?.mobilenumberISDcode,
  //               label: data.mobile_type.label,
  //               value: data.mobile_type.value,
  //               id: uuidv4(),
  //             };
  //             let newArray = [...phone];
  //             newArray[isUpdateData] = _newData;
  //             setPhone(newArray);
  //           } else {
  //             let _newData = {
  //               ...data,
  //               mobileNo: data?.mobilenumber,
  //               mobileNoIsdCode: data?.mobilenumberISDcode,
  //               label: data.mobile_type.label,
  //               value: data.mobile_type.value,
  //               id: uuidv4(),
  //             };
  //             setPhone([...phone, _newData]);
  //           }
  //         } else {
  //           let _newData = {
  //             ...data,
  //             mobileNo: data?.mobilenumber,
  //             mobileNoIsdCode: data?.mobilenumberISDcode,
  //             label: data.mobile_type.label,
  //             value: data.mobile_type.value,
  //             id: uuidv4(),
  //           };
  //           
  //           if (!Array.isArray(phone)) {
  //             setPhone([...phone, _newData]);
  //           } else {
  //             setPhone([...phone, _newData]);
  //           }
  //         }
  //       }
  //     }
  //   };

  //   const onAddEditDrawerPhone = (name, e, list) => {
  //     props.drawer.setDrawer({
  //       ...props.drawer,
  //       component: (
  //         <PhoneComponent
  //           type="Phone"
  //           id="AddEditClient"
  //           list={list}
  //           callBackOnSave={callBackOnSavePhone}
  //         />
  //       ),
  //       open: true,
  //     });
  //   };

  //   const viewDetailsPhoneEditDetails = (isEdit, editData, data, salutation) => {
  //     let _arrayOfJson = [];
  //     if (data?.length > 0) {
  //       _arrayOfJson = data;
  //     }
  //     return _arrayOfJson;
  //   };

  //   Address Handlers
  //   const callBackOnSaveAddress = (data, isremove) => {
  //     if (isremove) {
  //       let isUpdateData = address?.filter((val) => val.value !== data?.value);
  //       setAddress(isUpdateData);
  //     } else {
  //       props.drawer.setDrawer({
  //         component: <></>,
  //         open: false,
  //       });
  //       if (data) {
  //         if (address?.length > 0) {
  //           let isUpdateData = address?.findIndex((val) => val.id === data?.id);
  //           if (isUpdateData > -1) {
  //             let _newData = {
  //               ...data,
  //               label: data.address_type.label,
  //               value: data.address_type.value,
  //               id: uuidv4(),
  //             };
  //             let newArray = [...address];
  //             newArray[isUpdateData] = _newData;
  //             setAddress(newArray);
  //           } else {
  //             let _newData = {
  //               ...data,
  //               label: data.address_type.label,
  //               value: data.address_type.value,
  //               id: uuidv4(),
  //             };
  //             setAddress([...address, _newData]);
  //           }
  //         } else {
  //           let _newData = {
  //             ...data,
  //             label: data.address_type.label,
  //             value: data.address_type.value,
  //             id: uuidv4(),
  //           };
  //           setAddress([...address, _newData]);
  //         }
  //       }
  //     }
  //   };

  //   const onAddEditDrawerAddress = (name, e, list) => {
  //     props.drawer.setDrawer({
  //       ...props.drawer,
  //       component: (
  //         <AddressComponent
  //           id="AddEditClient"
  //           type="address"
  //           list={list}
  //           callBackOnSave={callBackOnSaveAddress}
  //         />
  //       ),
  //       open: true,
  //     });
  //   };

  //   const viewDetailsAddressEditDetails = (
  //     isEdit,
  //     editData,
  //     data,
  //     salutation
  //   ) => {
  //     let _arrayOfJson = [];
  //     if (data?.length > 0) {
  //       _arrayOfJson = data;
  //     }
  //     return _arrayOfJson;
  //   };

  const onSaveButtonClicked = () => {
    debugger;
    // Check Validations and Throw error
    // if (isError && false) {
    //   props.alert.setSnack({
    //     open: true,
    //     horizontal: alertProps.horizontal.center,
    //     vertical: alertProps.vertical.top,
    //     msg: "Please, fill the mandatory fields",
    //     severity: alertProps.severity.warning,
    //   });
    // }
    const fieldsToValidate = ["name", "relationship"];
    const finalState = isEdit
      ? {
          ...editData,
          ...state,
        }
      : {
          ...state,
          ...persistData,
        };

    const validate = () => {
      let errorState = {};
      fieldsToValidate.map((field) => {
        switch (field) {
          case "name": {
            if (!finalState[field]) {
              errorState[field] = true;
            }
            break;
          }
          case "relationship": {
            if (!finalState[field]) {
              errorState[field] = true;
            }
            break;
          }
          default: {
            break;
          }
        }
        return field
      });
      setError(errorState);
      return errorState;
    };

    if (Object.keys(validate())?.length > 0) {
      return props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields.",
        severity: alertProps.severity.warning,
      });
    } else if (
      finalState?.isEmergencyContact &&
      (!finalState?.otherPhoneNumbers ||
        finalState?.otherPhoneNumbers?.length === 0)
    ) {
      return props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Emergency contact must have atleast one contact number.",
        severity: alertProps.severity.warning,
      });
    } else {
      if (isEdit) {
        return props.callBackOnSave({
          ...editData,
          ...state,
        });
      } else {
        return props.callBackOnSave({
          ...state,
          ...persistData,
        });
      }
      //   let finalData = { ...persistData };
      //   if (editData) {
      //     // data.id = editData.id;
      //     finalData = {
      //       ...persistData,
      //       id: editData.id,
      //     };
      //   }
      //   props.callBackOnSave(finalData);
    }
  };

  // const onCancelButtonClicked = () => {
  //   props.drawer.setDrawer({
  //     ...props.drawer,
  //     open: false,
  //     component: <></>,
  //   });
  // };

  //   const components = {
  //     section_1: {
  //       label: !isEdit ? "Add New Family Member" : "Edit Family Member",
  //       fields: {
  //         name: {
  //           key: {
  //             select_key: "salutation",
  //             input_value_key: "name",
  //           },
  //           label: "Full Name",
  //           value: {
  //             salutation: editData?.salutation ? editData?.salutation : "",
  //             name: editData?.name ? editData?.name : "",
  //           },
  //           type: formComponentTypes.MOBILE_INPUT_BOX,
  //           options: [],
  //           options:
  //             salutationOptions && Object.keys(salutationOptions).length > 0
  //               ? salutationOptions?.filter((x) => x.label !== "other")
  //               : [{}],
  //           isMandatory: true,
  //           onlyText: true,
  //           customsize: 3,
  //           componentProps: {
  //             type: "text",
  //             id: "addClientNameFromAddClinet",
  //             select_id: "selectSalutationFromAddClient",
  //             style: {
  //               margin: "auto 0",
  //             },
  //           },
  //           breakPoints: {
  //             xs: 12,
  //             sm: 6,
  //             md: 6,
  //             lg: 6,
  //             xl: 6,
  //           },
  //         },
  //         relationship: {
  //           key: "relationship",
  //           type: formComponentTypes.SELECT_BOX,
  //           id: "selectRelationshipFromAddEditClient",
  //           breakPoints: {
  //             xs: 12,
  //             sm: 6,
  //             md: 6,
  //             lg: 6,
  //             xl: 6,
  //           },
  //           label: "Relationship Type",
  //           value: editData?.relationship ? editData?.relationship : "",
  //           options: relationOptions,
  //           isMandatory: true,
  //         },
  //         emailid: {
  //           key: "emailid",
  //           type: formComponentTypes.INPUT_BOX,
  //           id: "addEmailIdFromAddClients",
  //           componentProps: {
  //             type: "email",
  //           },
  //           breakPoints: {
  //             xs: 12,
  //             sm: 6,
  //             md: 6,
  //             lg: 6,
  //             xl: 6,
  //           },
  //           label: "Primary Email Address",
  //           value: editData?.emailid ? editData?.emailid : "",
  //           isMandatory: false,
  //         },
  //         gender: {
  //           key: "gender",
  //           type: formComponentTypes.SELECT_BOX,
  //           id: "selectGenderFromAddClient",
  //           breakPoints: {
  //             xs: 12,
  //             sm: 6,
  //             md: 6,
  //             lg: 6,
  //             xl: 6,
  //           },
  //           label: "GENDER",
  //           value: editData?.gender ? editData?.gender : "",
  //           options: genderOptions,
  //           isMandatory: false,
  //         },
  //         dob: {
  //           key: "dob",
  //           type: formComponentTypes.DATE_TIME_PICKER,
  //           id: "addDateOfBirthFromAddClient",
  //           componentProps: {
  //             isHideFutureDate: true,
  //             style: {
  //               margin: "auto 0",
  //             },
  //           },
  //           isDate: true,
  //           breakPoints: {
  //             xs: 12,
  //             sm: 6,
  //             md: 4,
  //             lg: 4,
  //             xl: 4,
  //           },
  //           label: "DATE OF BIRTH",
  //           value: editData?.dob ? editData?.dob : "",
  //           isMandatory: false,
  //         },
  //         addId: {
  //           key: "addId",
  //           type: formComponentTypes.ADD_ID_TYPE,
  //           id: {
  //             add_email_id: "addIdButtonFromAddClient",
  //           },
  //           breakPoints: {
  //             xs: 12,
  //             sm: 6,
  //             md: 4,
  //             lg: 4,
  //             xl: 4,
  //           },
  //           componentProps: {
  //             // component: viewDetailsIdEditDetails(isEdit, idType, idType, [{}]),
  //             callBackOnSave: callBackOnSaveId,
  //             editData: idType,
  //             isEdit: isEdit,
  //             handledrawer: onAddEditDrawerId,
  //           },
  //         },
  //         addPhoneNumber: {
  //           key: "addPhoneNumber",
  //           type: formComponentTypes.ADD_PHONE_NUMBER,
  //           id: {
  //             add_phone_id: "addPhoneButtonFromAddClient",
  //           },
  //           breakPoints: {
  //             xs: 12,
  //             sm: 6,
  //             md: 6,
  //             lg: 6,
  //             xl: 6,
  //           },
  //           componentProps: {
  //             // component: viewDetailsPhoneEditDetails(isEdit, phone, phone, [{}]),
  //             callBackOnSave: callBackOnSavePhone,
  //             editData: phone,
  //             isEdit: isEdit,
  //             handledrawer: onAddEditDrawerPhone,
  //           },
  //         },
  //         addAddress: {
  //           key: "addAddress",
  //           type: formComponentTypes.ADD_ADDRESS,
  //           id: {
  //             add_address_id: "addAddressButtonFromAddClient",
  //           },
  //           componentProps: {
  //             // component: viewDetailsAddressEditDetails(isEdit, address, address, [
  //             //   {},
  //             // ]),
  //             callBackOnSave: callBackOnSaveAddress,
  //             editData: address,
  //             isEdit: isEdit,
  //             handledrawer: onAddEditDrawerAddress,
  //           },
  //           breakPoints: {
  //             xs: 12,
  //             sm: 4,
  //             md: 4,
  //             lg: 4,
  //             xl: 4,
  //           },
  //         },
  //       },
  //     },
  //   };

  //   State

  const handleChange = (value, key) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    updateState(value, key);
  };

  const confirmChangeEmergencyContact = (emergencyIndex, value, key) => {
    props.dialog.setDialog({
      ...props.dialog,
      open: false,
      title: ``,
      body: "",
      positiveBtn: "Ok",
      negativeBtn: "CANCEL",
      dontShowButtons: false,
    });

    let updatedList = [...memberList];

    // Set old member isEmergencyContact flag to false
    updatedList[emergencyIndex] = {
      ...updatedList[emergencyIndex],
      isEmergencyContact: false,
    };

    setMemberList(updatedList);
    setState({
      ...state,
      isEmergencyContact: true,
    });
    props.updateFamilyMembers(updatedList);
    handleChange(value, key);

    // Set new member isEmergencyContact flag to true - will be changed as itself
    // handleChange(value, key);
  };

  const handleEmergencyContact = (value, key) => {
    debugger;
    if (value) {
      const emergencyMemberIndex = memberList?.findIndex(
        (x) => x?.isEmergencyContact === true
      );

      // If there is already an emergency contact then, confirm dialog is used
      if (
        emergencyMemberIndex > -1 &&
        (!state.id ||
          (state.id && state.id !== memberList?.[emergencyMemberIndex]?.id))
      ) {
        props.dialog.setDialog({
          ...props.dialog,
          open: true,
          title: "Are you Sure?",
          body: (
            <Typography>
              Another family member is saved as emergency contact, Do you want
              to choose this contact as emergency contact?
            </Typography>
          ),
          positiveBtn: "Change",
          negativeBtn: "Cancel",
          onOk: () =>
            confirmChangeEmergencyContact(emergencyMemberIndex, value, key),
          onCancel: () => {
            props.dialog.setDialog({
              ...props.dialog,
              open: false,
              title: ``,
              body: "",
              positiveBtn: "Ok",
              negativeBtn: "CANCEL",
              dontShowButtons: false,
            });
          },
        });
      } else {
        // else change emergency contact
        handleChange(value, key);
      }
    } else {
      handleChange(value, key);
    }
  };

  return (
    // <JsonFormBuilder
    //   components={components}
    //   onSaveButtonClicked={onSaveButtonClicked}
    //   cancelForm={onCancelButtonClicked}
    //   saveButtonLabel={isEdit ? "Update" : "Add"}
    //   spacing={1}
    //   sectionGutterBottom={16}
    //   //   saveButtonId={`addSaveEmailButtonFrom${id ? id : ""}`}
    //   //   cancelButtonId={`addCancelEmailButtonFrom${id ? id : ""}`}
    // />
    <div style={{ minHeight: "100vh" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              margin: "12px 0px",
              padding: "0px 16px",
            }}
          >
            {isEdit
              ? "Edit Family Member"
              : "Add Family Member"}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>

        <div style={{ padding: 16 }}>
          <Grid container item xs={12} spacing={2}>
            {/* Name */}
            <Grid item xs={12} sm={6}>
              <InputAdornmentComponent
                required={true}
                title={"Full Name"}
                options={salutationOptions}
                type={"text"}
                onlyText={true}
                customsize={3}
                valueMObileNo={state?.name}
                valuePhoneCode={state?.salutation}
                handleChangeSelect={(newValue) => {
                  debugger;
                  handleChange(newValue, "salutation");
                }}
                handleMobileNo={(e) => {
                  handleChange(e.target.value, "name");
                }}
                error={error["name"]}
                errorText={"Please fill this required field"}
              />
            </Grid>

            {/* Relation Type */}
            <Grid item xs={12} sm={6}>
              <SimpleSelect
                required={true}
                label={"Relationship Type"}
                options={relationOptions}
                //   isLoading={component.isLoading}
                value={state?.relationship}
                handleOnChange={(selectedValue) => {
                  handleChange(selectedValue, "relationship");
                }}
                error={error["relationship"]}
                errorText={"Please fill this required field"}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6}>
              <TextField
                type={"email"}
                title={"Primary Email Address"}
                value={state?.email}
                handleOnChange={(e) => {
                  handleChange(e.target.value, "email");
                }}
              />
            </Grid>

            {/* Gender */}
            <Grid item xs={12} sm={6}>
              <SimpleSelect
                label={"Gender"}
                options={genderOptions}
                //   isLoading={component.isLoading}
                value={state?.gender}
                handleOnChange={(selectedValue) => {
                  handleChange(selectedValue, "gender");
                }}
                //   error={this.state.error[component.key] ? true : false}
                //   errorText={errorMessage.requiredMessage}
                //   input_id={component?.id}
              />
            </Grid>

            {/* DOB */}
            <Grid item xs={12} sm={6}>
              <DateTimePickersCustom
                isDate={true}
                isHideFutureDate={true}
                label={"Date Of Birth"}
                value={state?.dob}
                handleOnChange={(newValue) => {
                  handleChange(newValue, "dob");
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ModernViewIdCardDetail
                callBackOnSave={callBackOnSaveId}
                editData={state?.idTypes}
                handledrawer={onAddEditDrawerId}
                backNeeded={true}
                goBackHandler={goBackHandler}
                hideRequired={true}
                //   error={component.componentProps.error}
                //   errorText={component.componentProps.errorText}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ModernViewMobileCardDetail
                callBackOnSave={callBackOnSavePhone}
                editData={state?.otherPhoneNumbers}
                handledrawer={onAddEditDrawerPhone}
                backNeeded={true}
                goBackHandler={goBackHandler}
                //   error={component.componentProps.error}
                //   errorText={component.componentProps.errorText}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ModernViewAddressCardDetail
                callBackOnSave={callBackOnSaveAddress}
                editData={state?.otherAddress}
                handledrawer={onAddEditDrawerAddress}
                backNeeded={true}
                goBackHandler={goBackHandler}
                hideRequired={true}
                //   error={component.componentProps.error}
                //   errorText={component.componentProps.errorText}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={state?.isEmergencyContact}
                    onChange={(event) => {
                      handleEmergencyContact(
                        event.target.checked,
                        "isEmergencyContact"
                      );
                    }}
                    name="isEmergencyContact"
                  />
                }
                label="Same for emergency contact details"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={onSaveButtonClicked}
                fullWidth
              >
                {isEdit ? "Update" : "Add"}
              </Button>
              {/* <div
                style={{ display: "flex", gap: 2, justifyContent: "flex-end" }}
              >
                <Button
                  variant={"text"}
                  color={"primary"}
                  onClick={onCancelButtonClicked}
                >
                  {"Cancel"}
                </Button>

                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={onSaveButtonClicked}
                  fullWidth
                >
                  {isEdit ? "Update" : "Add"}
                </Button>
              </div> */}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}

export default withAlertAndDialog(FamilyMembersAddForm);
