import React from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";
import { routes } from "../../../router/routes";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ListLoader from "../../../components/listLoader";
import { FilterBuilder, InternatlServerError } from "../../../components";
import { withAlertAndDialog } from "../../../HOCs";
import SubHeader from "../../../components/subHeader";
import List from "../../../components/userList/cardList";
import moment from "moment";
import AddEditActivity from "../../availability/calendar/addEditActivity";
import { filterTagType, scrollToTop, user_roles } from "../../../utils";
import { filterComponent } from "./filterComponent";
import FilterTags from "../../../components/filters/filterTags";

const styles = (theme) => ({
  root: {
    padding: 25
  },
  subHeader: {
    marginTop: 12,
    marginBottom: 12
  }
});

const sizes = {
  md: 3,
  sm: 3,
  xs: 3
};


const initialState = {
  subscription_status:{}
};
class SubscriptionMonitoring extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 0,
      pageSize: 25,
      clientName: "",
      // sorDirec:"desc",
      // sortfield:'subscriptionName',
      fromTimeStamp: moment().startOf("day").toISOString(),
      toTimeStamp: moment().endOf("day").toISOString(),
      loading: true,
      expires_in: {},
      // subscription_status: {}
      status:undefined,
      id: "MonitoringListingSubscriptions"
    };
  }

  componentDidMount() {
    this.getEssentials();
    scrollToTop()
    window.addEventListener("scroll", this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  }

  scrolling = () => {
    let { loading, data, error } = this.props?.subscription;

    let listCount = data?.list?.length ?? 0;
    let totalCount = data?.total_count ? data?.total_count[0] :0;
    if (loading || error || totalCount === 0 || listCount === 0) {
      return false;
    }

    if ((Math.round(window.scrollY + window.innerHeight) >= document.getElementsByTagName('body')[0].scrollHeight) && listCount !== totalCount) {
      this.setState((state, props) => ({
        pageNo: state.pageNo + 25,
      }) 
        , () => {
          this.getEssentials();
        });
    }
  }


  getEssentials = () => {
    let _status = undefined
    if(this.state?.status){
      if(this.state.status.toLowerCase() === "Active".toLowerCase()) _status = true
      if(this.state.status.toLowerCase() === "InActive".toLowerCase()) _status = false
    }
    this.props.GET_ALL_SUBSCRIPTION({ ...this.state,
      offset:this.state?.pageNo,
      limit:this.state?.pageSize, searchValue:this.state.clientName, status:_status });
  };

  navigateToView = (id) => {
    this.props.history.push(routes.monitor_subscription_detail_parent + id);
  };

  mapperToList = (_) => {
    
    return {
      id: _?.subscription_id,
      isactive: undefined,
      name: _?.subscription_name,
      orderList: [
        // {
        //   count:
        //     moment(_?.expired_on).diff(moment(), "days") > 0
        //       ? `Expire ${moment(_?.expired_on).from()}` 
        //       : `Expired ${moment(_?.expired_on).from()}`,
        //   name: ""
        // },
        {
          count: _?.mapped_service?.length,
          name: "Service Pending"
        }
      ],
      profile_pic: _?.subscription_picture,
      care_provider: {
        name: `${_?.validity_type ? `${_?.validity_type} / ` : "" }${_?.subscription_type ? `${_?.subscription_type} / ` : "" }${
          moment(_?.expired_on).diff(moment(), "days") > 0
            ? `Expire in ${moment(_?.expired_on).format("Do MMM YYYY")}`
            : `Expired on ${moment(_?.expired_on).format("Do MMM YYYY")}`
        }`,
      },
      ..._
    };
  };

  onAddActivityClicked = async(_) => {
    // console.log(this.props.subscription.data.list)
    let data =await this.props.GET_SUBSCRIPTION_DETAILS({
      subscription_id: _.subscription_id,
    });

   /*  let partnerData = await this.props.COLLECT_PARTNER_META({
      partnerId: ""
    }) */

    // let coortdinator = data.payload.data.service_coord[0]
    let partner = _?.partner_info
    let mapped_service = data.payload.data.mapped_service
    // console.log(mapped_service.map((x) => {return {label: x.service_name, value: x.service_id}}))

    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <AddEditActivity
          {...this.props}
          isEdit={false}
          // persistState
          parentState={
            {
              cliendId:
              {
                label:_?.client_info?.client_name, 
                value:_?.client_info?.client_id
              },
              subscriptions:
              {
                label:_?.subscription_name,
                value:_?.subscription_id,
                type: _?.subscription_type
              }, 
              partnerId:
              {
                label:partner.partnerName, 
                value:partner.partnerId 
              },
              services:mapped_service
            }}
          reload={this.getEssentials}
          // serviceProfessionalID={this.state.id}
        />
      )
    });
  };

  onFilterIconClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <FilterBuilder
          components={filterComponent(this.state, this.props)}
          applyButtonLabel="Apply"
          loading={this.props?.subscription?.loading}
          onChange={this.onFilterChange}
          onApplyButtonClicked={this.applyFilter}
          resetFilter={this.resetFilter}
          onCancelClick={this.closeFilter}
          saveButtonId={`saveFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
          cancelButtonId={`cancelFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
          clearAllButtonId={`clearAllFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
        />
      ),
    });
  };


  // onFilterIconClicked = () => {
  //   this.props.drawer.setDrawer({
  //     ...this.props.drawer,
  //     open: true,
  //     component: (
  //       <SubscriptionFilter
  //         {...this.props}
  //         expires_in={this.state.expires_in}
  //         subscription_status={this.state.subscription_status}
  //         onFiltersChanged={this.onChange}
  //       />
  //     )
  //   });
  // };

  onChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  onFilterChange = (key, value) => {
    
    this.setState({
      [key]: value,
    });
  };

  applyFilter = (state) => {
    
    this.setState(
      {
        ...state, //This is obtained from FilterBuilder Component
        pageNo: 0,
        pageSize: 25,
        isFilterApplied: true,
      },
      () => {
        this.getEssentials();
        this.closeFilter();
      }
    );
  };

  resetFilter = () => {
    this.setState(
      {
        ...initialState,
      },
      () => {
        this.getEssentials();
        this.closeFilter();
      }
    );
  };

  closeFilter = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  storeInputSearchValue = (value) => {
    this.setState(
      { clientName: value, pageNo: 0, pageSize: 25 },
      () => {
        // if (value.length % 3 === 0) {
        this.getEssentials();
        // }
      }
    );
  };

  handleChipDelete = (key, index) => {
    console.log("Key ", key);
    console.log("index ", index);
   if (key === filterTagType.Status) {
      this.setState(
        {
          status: undefined,
          pageNo: 0,
          pageSize: 25,
        },
        () => {
          
          this.getEssentials();
        }
      );
    }
  
  };
  render() {
    const { classes } = this.props;

    let { data, loading, error } = this.props?.subscription;

    return (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12} className={classes.subHeader}>
            <SubHeader
              useNewFilterIcon
              placeSearchIconToLeft 
              placeholder={"Search for Clients"}
              entityName={"Client"}
              countText={`Subscriptions (${data?.total_count ?? "0"})`}
              onFilterIconClicked={this.onFilterIconClicked}
              handleSearch={() => {this.getEssentials()}}
              handleChangeInputSearch={this.storeInputSearchValue}
              value={this.state.clientName}
              id={this.state?.id}
            />
          </Grid>
          {this.state.isFilterApplied && (
            <Grid item xs={12} style={{ marginTop: 25 }}>
              <FilterTags
                filterData={this.state}
                handleDelete={this.handleChipDelete}
              />
            </Grid>
          )}
          {loading && (
            <Grid item xs={12}>
              <ListLoader />
            </Grid>
          )}
          {!loading && error && (
            <Grid item xs={12}>
              <InternatlServerError
                message={"Something Went Wrong!"}
                onRetry={this.getEssentials}
                top={"20vh"}
              />
            </Grid>
          )}
          {!loading && !error && !data && data?.length === 0 && (
            <Grid item xs={12} style={{ marginTop: "20vh" }}>
              <Typography variant="h6" align="center">
                You don't have any subscriptions!
              </Typography>
            </Grid>
          )}
          {!loading && !error && data?.list?.length > 0 && (
            <Grid item xs={12} style={{ cursor: "pointer" }}>
              {data?.list?.map((_, i) => {
                
                return (
                  <div key={i}>
                    <List 
                      id={`ListMonitoringSubscription${i}`}
                      list={this.mapperToList(_)}
                      showCareProvider={true}
                      displayProvider={true}
                      componentOrder={Boolean(this.props?.auth_data?.data?.user_role === user_roles.service_coordinator) ? null : 1}
                      sizes={sizes}
                      navigateToView={() =>
                        this.navigateToView(_.subscription_id)
                      }
                      propsList={{
                        isCard: false
                      }}
                      showAddIcon
                      onAddIconClicked={() => this.onAddActivityClicked(_)}
                      countAsBody1={true}
                      avatarFallbackAsImage={true}
                    />
                  </div>
                );
              })}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subscription: state?.subscription_tracking?.allsub ?? {
    data: [],
    loading: false,
    error: false
  },
  auth_data: state?.authState?.loginAuth
});

export default connect(
  mapStateToProps,
  actions
)(withAlertAndDialog(withRouter(withStyles(styles)(SubscriptionMonitoring))));
