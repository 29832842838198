import React from "react";

import { makeStyles, Grid, InputAdornment, useTheme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import EditableTextField from "../niceComponents/editableTextField";
import { CustomAvatar, CustomTypography } from "../niceComponents";
import { extractFloats } from "../../utils/common";

const styles = makeStyles((theme) => ({
  // root: {
  //   padding: theme.spacing(0, 2)
  // },
  title: {
    fontWeight: 600,
    margin: theme.spacing(2, 0),
  },
  content: {
    padding: theme.spacing(0, 1),
  },
  underline: {
    display: "flex",
    gap: theme.spacing(1),
  },
  profileCard: {
    border: `1px solid #d8dce5`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: "unset",
  },
  defaultPriceWrapper: {
    margin: "0px 8px",
  },
  specialPriceWrapper: {
    margin: "12px 0px 0px 0px",
  },
}));

export const Summary = (props) => {
  const classes = styles();
  const theme = useTheme();
  // const isActive = props?.items?.isactive === "Active" ? true : false;

  const getFormattedTotal = (total) => {
    const totals = total.split('.')
    if(totals?.[1]){
      if(totals?.[1]?.length > 2){
        return parseFloat(total).toFixed(2) 
      } else {
        return parseFloat(total) 
      }
    } else {
      return parseFloat(total)
    }
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Paper className={classes.profileCard}>
            <Grid container alignItems="center">
              <Grid
                container
                item
                xs={12}
                sm={props?.isSmallScreen ? 12 : 8}
                md={props?.isSmallScreen ? 12 : 9}
                alignItems="center"
              >
                <Grid
                  item
                  style={{
                    alignSelf: props?.isSmallScreen ? "flex-start" : "unset",
                  }}
                >
                  <CustomAvatar src={props?.profile_pic} name={props?.item} />
                </Grid>
                <Grid item xs>
                  <CustomTypography variant="row1" weight={"bold"}>
                    {props?.item ?? "N/A"}
                  </CustomTypography>
                  <CustomTypography variant="row2">
                    {props?.category ?? "N/A"}
                  </CustomTypography>
                  <CustomTypography variant="row2">
                    {props?.provider ?? "N/A"}
                  </CustomTypography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={props?.isSmallScreen ? 12 : 4}
                sm={props?.isSmallScreen ? 12 : 4}
                md={props?.isSmallScreen ? 12 : 3}
              >
                {!props.isPriceEditable && (
                  <div
                    className={`${
                      props?.isSmallScreen
                        ? classes.specialPriceWrapper
                        : classes.defaultPriceWrapper
                    }`}
                  >
                    <CustomTypography
                      variant="row1"
                      align={props?.isSmallScreen ? "flex-end" : "flex-start"}
                      startIcon={
                        <LocalOfferIcon fontSize={"small"} color={"primary"} />
                      }
                      styles={{ fontSize: "1.25rem" }}
                      iconStyles={{ fontSize: "1.25rem" }}
                    >
                      {props?.amount}
                    </CustomTypography>
                  </div>
                )}
                {props.isPriceEditable && (
                  <EditableTextField
                    type={"number"}
                    value={
                      props?.total
                        ? getFormattedTotal(props?.total)
                        : props?.defaultAmount
                        ? props?.defaultAmount
                        : extractFloats(props?.amount)?.[0]
                    }
                    handleChange={(e) => {
                      props.onTotalChange(e.target.value, props.value);
                    }}
                    startAdornment={
                      <InputAdornment
                        position="start"
                        style={{ gap: theme.spacing(0.75) }}
                      >
                        <LocalOfferIcon fontSize={"small"} color={"primary"} />
                        <CustomTypography
                          variant="row1"
                          styles={{ fontSize: "1.25rem" }}
                        >
                          {"MYR"}
                        </CustomTypography>
                      </InputAdornment>
                    }
                  />
                )}
                {/* <LocalOfferIcon /> */}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Summary;

// PROPTYPES
Summary.propTypes = {
  total: PropTypes.string,
  packageCount: PropTypes.number,
  servicesCount: PropTypes.number,
  provider: PropTypes.string,
  category: PropTypes.string,
  item: PropTypes.string,
  profile_pic: PropTypes.string,
};
