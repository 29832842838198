import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function ScrollArrowLeftIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#b)" clipPath="url(#a)">
        <path
          opacity={0.75}
          d="M12 6a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm3 6.6h-3.951l1.375 1.376-.848.848L8.75 12l2.824-2.824.849.848-1.375 1.376h3.95v1.2Z"
          fill="#3B3F5B"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="rotate(180 12 12)" d="M0 0h24v24H0z" />
        </clipPath>
        <filter
          id="b"
          x={0}
          y={3}
          width={24}
          height={24}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} />
          <feColorMatrix values="0 0 0 0 0.231373 0 0 0 0 0.247059 0 0 0 0 0.356863 0 0 0 0.161 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_303_5" />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_303_5"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  );
}

export default ScrollArrowLeftIcon;
