import React from 'react'
import { Grid, IconButton, ListItem, makeStyles, Typography } from '@material-ui/core'
import { months } from '../../utils'
import PropTypes from 'prop-types'
import moment from 'moment'
// import { v4 as uuidv4 } from 'uuid'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  listStyleDot: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    // background: '#f27d35',
    background: theme.palette.ternary.main,
    marginRight: 8
  },
  listItem: {
    transition: "0.3s all",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.02)",
      borderRadius: 8,
    }
  },
  customListItem: {
    display: "flex",
    flex: "1 0 auto",
    justifyContent: "space-between"
  },
  moreActions: {
    display: "flex"
  },
  margin: {
    margin: theme.spacing(1),
  }
}))


/**
 * 
 * 18 January 2021
1 June 2021
29 October 2021
20 January 2023
10 April 2023
27 May 2024
1 July 2024
2 November 2024
23 December 2024
15 February 2021
 */


// const holidays = {
//   "2021": {
//     "1": [
//       {
//         "id": uuidv4(),
//         "title": "Deepavali",
//         "occurs_on": new Date(`18 January 2021`)
//       },
//       {
//         "id": uuidv4(),
//         "title": "Deepavali2",
//         "occurs_on": new Date(`18 January 2021`)
//       },
//       {
//         "id": uuidv4(),
//         "title": "Deepavali3",
//         "occurs_on": new Date(`19 January 2021`)
//       },
//       {
//         "id": uuidv4(),
//         "title": "Deepavali4",
//         "occurs_on": new Date(`20 January 2021`)
//       },
//       {
//         "id": uuidv4(),
//         "title": "Deepavali5",
//         "occurs_on": new Date(`21 January 2021`)
//       },
//       {
//         "id": uuidv4(),
//         "title": "Deepavali6",
//         "occurs_on": new Date(`22 January 2021`)
//       },
//     ],
//     "2": [
//       {
//         "id": uuidv4(),
//         "title": "Deepavali",
//         "occurs_on": new Date(`15 February 2021`)
//       }
//     ],
//     "4": [
//       {
//         "id": uuidv4(),
//         "title": "Deepavali",
//         "occurs_on": new Date(`10 April 2021`)
//       }
//     ],
//   },
//   "2022": {
//     "1": [
//       {
//         "id": uuidv4(),
//         "title": "Deepavali",
//         "occurs_on": new Date(`18 January 2022`)
//       }
//     ],
//     "2": [
//       {
//         "id": uuidv4(),
//         "title": "Deepavali",
//         "occurs_on": new Date(`15 February 2022`)
//       }
//     ],
//     "4": [
//       {
//         "id": uuidv4(),
//         "title": "Deepavali",
//         "occurs_on": new Date(`10 April 2022`)
//       }
//     ],
//   }
// }


const giveMeNecessaryHolidays = (holidays, selectedYear, selectedmonth) => {
  if(holidays !== null){
    const year = selectedYear && selectedYear?.toString();
    const month = selectedmonth && selectedmonth?.toString();
    console.log(holidays?.[year]?.[month]);
    return holidays?.[year]?.[month];
  }
}


function Holidays(props) {
  const classes = useStyles()
  const [mouseOver, setMouseOver] = React.useState(false)

  // Props
  const { selectedMonth, selectedYear, holidays, editHoliday, deleteHoliday, hideMoreActions } = props

  const events = giveMeNecessaryHolidays(holidays, selectedYear, selectedMonth)


  return (
    <div style={{ padding: 16, width: '100%' }}>
      <Grid item style={{ marginBottom: 8 }}>
        <Typography variant="body1" style={{ padding: '0px 16px' }}>
          {`${months.find(month => month.id === selectedMonth)?.monthName} ${selectedYear}`}
        </Typography>
      </Grid>
      <Grid item style={{ maxHeight: 200, overflow: 'scroll', }}>
        {/* NOTE: Integrate the Holdidays array here */}
        {events?.length > 0
          ?
          events?.map((event, index) => {
            return (
              <ListItem key={index} className={classes.listItem} onMouseOver={() => setMouseOver(event.id)} onMouseLeave={() => setMouseOver(null)}>
                <div className={classes.customListItem}>
                  <div>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className={classes.listStyleDot}></div>
                    <Typography variant="body2" color="secondary" style={{ userSelect: "none" }} >{moment(event.occurs_on)?.format("Do MMMM, YYYY")}</Typography>
                  </div>
                  <Typography variant="body1" style={{ marginLeft: 14, userSelect: "none" }} >{event.holiday_title}</Typography>
                  </div>
                  { !hideMoreActions && (mouseOver === event.id) && <div className={classes.moreActions}>
                    <IconButton size={"small"} color={"primary"} className={classes.margin} onClick={(e) => editHoliday(event?.id, event?.localId)}><EditIcon fontSize={"small"} /></IconButton>
                    <IconButton size={"small"} color={"primary"} className={classes.margin} onClick={(e) => deleteHoliday(event?.id, event?.localId)}><DeleteIcon fontSize={"small"} /></IconButton>
                  </div>}
                </div>
              </ListItem>
            )
          })
          :
          <Typography style={{ paddingLeft: 16 }} color="secondary">You don't have any events!</Typography>
        }
      </Grid>
    </div>
  )
}

export default Holidays

Holidays.propTypes = {
  /**Sets the selected month as active. */
  selectedMonth: PropTypes.number,
  /**Sets the selected year as active. */
  selectedYear: PropTypes.number,
}
