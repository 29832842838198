import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { CustomizedRadioButton } from "../inputs";
import SearchLocationInput from "../map/inputFieldSearch/SearchLocationInput";
import { options } from "../../utils";

const style = (theme) => ({});

class ActivityMode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activity_mode: null,
      meeting_link: "",
      location: "",
      error: {
        activity_mode: false,
        meeting_link: false,
        location: false
      }
    };
  }

  // componentDidMount() {
  //   
  //   this.setState({
  //     activity_mode: this.props.activityModeProps.value
  //   });
  // }

  /** Lifted the Activity Mode State to the parent (AddEditForm)*/
  activityModeChange = (selectedMode, key) => {
    const { handleActivityModeChange } = this.props.activityModeProps;
    handleActivityModeChange(selectedMode, key)
    // this.setState(
    //   {
    //     activity_mode: selectedMode
    //   },
    //   () => handleActivityModeChange(selectedMode, key)
    // );
  };

  formatAddress = (selectedVenue) => {
    const {addressLine1, addressLine2, area, state, country, postalCode} = selectedVenue
    return `${addressLine1 ? addressLine1 + ", " : ""}${
      addressLine2 ? addressLine2 + ", " : ""
    }${area ? area + ", " : ""}${state ? state + ", " : ""}${
      country ? country + ", " : ""
    }${postalCode ? postalCode : ""}`;
    //     addressLine1 + "," + addressLine2 + "," + area + "," + state + "," + country + "," + postalCode + ""; 
  }

  onVenueChange = (venue, key) => {
    const { handleVenueChange, options: venueOptions } = this.props.venueProps;
    const { handleOnChange: handleLocationChange, } = this.props.locationProps
    this.setState(
      {
        venue: venue
      },
      () => {
        if(venue !== null){
          if(venue === "NEW_ADDRESS"){
            // if(!(!!locationValue))
            handleLocationChange("")
          } else {
            const selectedVenue = venueOptions?.filter(address => address?.value === venue)?.[0]
            const address = {}
            const formatted_address = this.formatAddress(selectedVenue)
            address["address"] = formatted_address;
            address["latitude"] = selectedVenue?.add_location?.latitude;
            address["longitude"] = selectedVenue?.add_location?.longitude;
            address["address_components"] = selectedVenue?.add_location?.address_components;
            handleLocationChange(address)
          }
          handleVenueChange(venue, key)
        }
      }
    );
  }

  render() {
    // const { classes } = this.props;

    let isReschedule = this.props?.isReschedule

    //Activity mode props
    const {
      id: activityModeId,
      key: activityModeKey,
      label: activityModeLabel,
      value: activityModeValue,
      options: activityModeOptions,
      error: activityModeError,
      errorText: activityModeErrorText
    } = this.props.activityModeProps || {};
    // const {
    //   type: meetingInputType,
    //   limit: meetingLimit,
    //   state: meetingState,
    //   title: meetingTitle,
    //   placeholder: meetingPlaceholder,
    //   value: meetingValue,
    //   handleOnChange: handleMeetingChange,
    //   // error: meetingError,
    //   errorText: meetingErrorText
    // } = this.props.meetingProps;

    const {
      // label: locationLabel,
      id: locationId,
      value: locationValue,
      handleOnChange: handleLocationChange,
      openDialogMap,
      showLocationViaMaps,
      error: locationError,
      errorText: locationErrorText,
    } = this.props.locationProps;

    const {
      key: venueKey,
      id: venueId,
      label: venueLabel,
      value: venueValue,
      options: venueOptions = [],
      isAnyClientSelected,
      error: venueError,
      errorText: venueErrorText
    } = this.props.venueProps || {};
    
    console.log(activityModeId, locationId,  venueId)
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <CustomizedRadioButton
              data={{
                key: activityModeKey,
                label: activityModeLabel,
                options: activityModeOptions,
                id: activityModeId
              }} //needs label, options, handleOnChange
              value={activityModeValue}
              id={activityModeId}
              handleOnChange={this.activityModeChange}
              error={activityModeError}
              errorText={activityModeErrorText}
              disabled = {
                typeof this.props.setDisable !== 'boolean'
                ? this.props.setDisable === 2 ? false : true
                : this.props.setDisable ? true : isReschedule || this.props.isEdit ? true : false
              }
              // disabled={true}
            />
          </Grid>
          {/* {this.state.activity_mode === "VIDEO_CALL" && this.props?.isEdit && (
            <Grid item xs={12}>
              <TextField
                type={meetingInputType}
                limit={meetingLimit}
                state={meetingState}
                title={meetingTitle}
                placeholder={meetingPlaceholder}
                value={meetingValue}
                handleOnChange={handleMeetingChange}
                error={false}
                errorText={meetingErrorText}
                disabled={true}
              />
            </Grid>
          )} */}
          {/* {this.state.activity_mode === "IN_PERSON" && ( */}
          {this.props.activityModeProps.value === "IN_PERSON" && (
            <>
            <Grid item xs={12}>
            <CustomizedRadioButton
              data={{
                key: venueKey,
                label: venueLabel,
                options: isAnyClientSelected ? [...venueOptions, options.newAddress] : [options.newAddress]
              }} //needs label, options, handleOnChange
              value={venueValue}
              id={venueId}
              handleOnChange={this.onVenueChange}
              error={venueError}
              errorText={venueErrorText}
              disabled={isReschedule ? true : false}
            />
          </Grid>
            <Grid item xs={12}>
              {/* GOOGLE LOCATION FIELD */}
              <SearchLocationInput
                // label={locationLabel}
                value={locationValue}
                id={locationId}
                handleOnChange={handleLocationChange}
                openDialogMap={openDialogMap}
                showLocationViaMaps={showLocationViaMaps}
                venue={venueValue}
                error={locationError}
                errorText={locationErrorText}
              />
            </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(style)(ActivityMode);
