import React from "react";
import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

export const ClientSpecialPrecautionsIcon = (props) => {
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
      htmlColor={props.color}
      style={props.style}
    >
      <path id="Path_626" d="M16 7H8a1 1 0 0 1-1-1V4l5 1 5-1v2a1 1 0 0 1-1 1z" class="cls-1" data-name="Path 626" />
      <path id="Path_627" d="M19 3h-4.184a2.982 2.982 0 0 0-5.632 0H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zM5 5h7a1 1 0 1 1 1-1 1 1 0 0 1-1 1h7v14H5z" class="cls-1" data-name="Path 627" />
      <path id="Path_628" fill="none" stroke="#28a9c1" stroke-miterlimit="10" stroke-width="2px" d="M12 9v8m3.441-6.039l-6.882 4.078m0-4.078l6.882 4.078" data-name="Path 628" />
    </SvgIcon>
  )
}

ClientSpecialPrecautionsIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};
