import React from "react";
import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

export const SkillsIcon = (props) => {
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
    >
      <path d="M17,14c-0.151,0-0.3-0.013-0.449-0.023c-0.755,1.121-1.566,2.577-1.566,5.263V20h-5v-3h-4v-4.306l-1.223-0.516l-0.241-0.101l1.201-1.884L5.973,9.8l0.051-0.463c0.282-2.566,2.156-4.582,4.572-5.156c0.361-0.819,0.873-1.556,1.504-2.175C12.061,2.005,12.023,2,11.985,2c-4.12,0-7.51,3.115-7.948,7.118l-1.893,2.969c-0.305,0.477-0.106,1.114,0.415,1.333l1.426,0.601V17c0,1.105,0.895,2,2,2h2v3h9v-2.76c0-3.13,1.268-3.986,2.155-5.576C18.465,13.881,17.747,14,17,14z" />
      <path d="M20.906,7.76C20.953,7.513,20.982,7.26,20.982,7s-0.029-0.513-0.077-0.76l0.806-0.599c0.103-0.077,0.133-0.219,0.068-0.33l-0.936-1.621c-0.064-0.112-0.202-0.157-0.32-0.106l-0.936,0.405c-0.379-0.328-0.817-0.584-1.301-0.752L18.17,2.224C18.155,2.096,18.047,2,17.918,2h-1.872c-0.129,0-0.237,0.096-0.252,0.224l-0.117,1.012c-0.484,0.168-0.921,0.423-1.301,0.752L13.44,3.583c-0.118-0.051-0.256-0.006-0.32,0.106l-0.936,1.621c-0.064,0.112-0.035,0.254,0.068,0.33l0.806,0.599c-0.047,0.246-0.077,0.5-0.077,0.76s0.029,0.513,0.077,0.76l-0.806,0.599c-0.103,0.077-0.133,0.219-0.068,0.33l0.936,1.621c0.064,0.112,0.202,0.157,0.32,0.106l0.936-0.405c0.379,0.328,0.817,0.584,1.301,0.752l0.117,1.012C15.809,11.904,15.918,12,16.046,12h1.872c0.129,0,0.237-0.096,0.252-0.224l0.117-1.012c0.484-0.168,0.921-0.423,1.301-0.752l0.936,0.405c0.118,0.051,0.256,0.005,0.32-0.106l0.936-1.621c0.064-0.112,0.035-0.254-0.068-0.33L20.906,7.76z M16.982,8.75c-0.966,0-1.75-0.784-1.75-1.75c0-0.967,0.784-1.75,1.75-1.75s1.75,0.783,1.75,1.75C18.732,7.966,17.949,8.75,16.982,8.75z" />
    </SvgIcon>
  );
};

SkillsIcon.propTypes = {
  htmlColor: PropTypes.string,
  style: PropTypes.object,
};
