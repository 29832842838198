import React from "react";
import AddEditProductManagemet from "../../../../components/addEditForm";
import {
  // converToReactSelect,
  formComponentTypes,
  // options,
  alertProps,
  // options,
  // options,
} from "../../../../utils";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import TrashIcon from "../../../../assets/trashCan";
// import { Grid } from "@material-ui/core";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import {
  InternatlServerError,
  LoadingSection,
  NotFoundSection,
} from "../../../../components";
// import { routes } from "../../../../router/routes";
import moment from "moment";
import {
  allPersonaIds,
  resourceTypeName,
} from "../../../resourceManagement/resources/utils";
import ConflictList from "../conflictList";
import { routes } from "../../../../router/routes";

class AddEditAvailabiity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 0,
      pageSize: 10,
      careGiverName: "",
      isShowAvailabilities: false,
      from_timestamp: null,
      to_timestamp: null,
      isChecked: false,
      selectedDate: moment(),
      serviceProfessionalId: null,
      editavailability_serviceProfessionalId: null,
      editavailability_from_timestamp: null,
      editavailability_to_timestamp: null,
      unavailability_for: "service_professional",
    };
  }

  componentDidMount() {
    if (this.props.isEdit) {
      this.getAvailabilityData();
    }
    this.getEssentials();
  }

  getEssentials = async () => {
    this.props.GET_MASTER_LEAVE_TYPES({});
    this.props.GET_MASTER_UNAVAILABLE_TYPES({});
    let data = await this.props.GET_ALL_PERSONA_RESOURCE({
      searchValue: "",
      resourceTypeId: allPersonaIds.service_professional,
      resourceTypeName: resourceTypeName.persona,
    });
    this.setState({ professional_options: data?.payload?.data });
  };

  getAvailabilityData = () => {
    this.props.GET_AVAILABILITY({ availabilityId: this?.props?.id });
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Add Availability
    const { addAvailability } = nextProps;
    const oldadd_availability = this.props.addAvailability;

    // Checking for Add Availability
    if (oldadd_availability?.loading !== addAvailability?.loading) {
      if (!addAvailability.loading && !addAvailability.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Unavailability Marked successfully!",
          severity: alertProps.severity.success,
        });
        this.props.reload(this.state.selectedDate);
        this.onCancelButtonClicked();
        // this.props.history.push(routes.manage_clients);
      } else if (addAvailability.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: addAvailability?.message
            ? addAvailability?.message
            : "Something went wrong! Unable to Mark Unavailability.",
          severity: alertProps.severity.error,
        });
      }
    }

    //Update Availability
    const { updateAvailability } = nextProps;
    const oldUpdateActivity = this.props.updateAvailability;

    //Checking for update Availability messages
    if (oldUpdateActivity?.loading !== updateAvailability?.loading) {
      if (!updateAvailability.loading && !updateAvailability.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Unavailability Updated successfully!",
          severity: alertProps.severity.success,
        });
        this.props.reload(this.state.selectedDate);
        this.onCancelButtonClicked();
      } else if (updateAvailability.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: updateAvailability?.message
            ? updateAvailability?.message
            : "Something went wrong! Unable to Mark Unavailability.",
          severity: alertProps.severity.error,
        });
      }
    }

    //Get data for
    const { editAvailability } = nextProps;
    const oldeditAvailability = this.props.editAvailability;

    //Checking for confilts on unAvailability messages
    if (oldeditAvailability?.loading !== editAvailability?.loading) {
      if (!editAvailability.loading && !editAvailability.error) {
        if (editAvailability?.data) {
          this.setState({
            serviceProfessionalId:
              editAvailability?.data?.service_professional?.value,
            from_timestamp: editAvailability?.data?.from,
            to_timestamp: editAvailability?.data?.to,
            editavailability_serviceProfessionalId:
              editAvailability?.data?.service_professional?.value,
            editavailability_from_timestamp: editAvailability?.data?.from,
            editavailability_to_timestamp: editAvailability?.data?.to,
          });
        }
      } else if (editAvailability.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to fetch data.",
          severity: alertProps.severity.error,
        });
      }
    }

    //Conflict API for unavailability
    const { unavailabilityConflict } = nextProps;
    const oldunavailabilityConflict = this.props.unavailabilityConflict;

    //Checking for confilts on unAvailability messages
    if (
      oldunavailabilityConflict?.loading !== unavailabilityConflict?.loading
    ) {
      if (!unavailabilityConflict.loading && !unavailabilityConflict.error) {
        if (
          unavailabilityConflict?.data?.data?.Activities?.length > 0 ||
          unavailabilityConflict?.data?.data?.UnAvailability?.length > 0
        ) {
          this.setState({
            isShowAvailabilities: true,
            activityCount:
              unavailabilityConflict?.data?.data?.Activities?.length > 0
                ? unavailabilityConflict?.data?.data?.Activities?.length
                : 0,
            availabilityCount:
              unavailabilityConflict?.data?.data?.UnAvailability?.length > 0
                ? unavailabilityConflict?.data?.data?.UnAvailability?.length
                : 0,
          });
        } else {
          this.setState({
            isShowAvailabilities: false,
            activityCount: 0,
            availabilityCount: 0,
          });
        }
      } else if (unavailabilityConflict.error) {
        // this.props.alert.setSnack({
        //   open: true,
        //   horizontal: alertProps.horizontal.center,
        //   vertical: alertProps.vertical.top,
        //   msg:
        //     "Something went wrong! Unable to fetch conflicts on Unavailability.",
        //   severity: alertProps.severity.error,
        // });
      }
    }

    return true;
  }

  // getCareGiverList = () => {
  //   this.props.GET_ALL_CARE_GIVER({ ...this.state });
  // };

  onSaveButtonClicked = async (isError, data, url) => {
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else if (this.state.isShowAvailabilities && !this.state.isChecked) {
      // if(this.state.isChecked){

      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, tick the check box",
        severity: alertProps.severity.warning,
      });
      // }
    } else if (
      moment
        .duration(moment(data.to_timestamp).diff(moment(data.from_timestamp)))
        .asMinutes() < 5
    ) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Duration should be minumum of 05 minutes",
        severity: alertProps.severity.warning,
      });
    } else {
      this.setState(
        {
          selectedDate: moment(data.from_timestamp).set("second", "00"),
        },
        () => {
          if (this.props.isEdit) {
            const payload = {
              availabilityId: this.props?.id,
              status: data?.status !== "unavailable" ? false : true,
              serviceprofessionalId:
                this.props?.redirectFrom !== undefined &&
                this.props?.redirectFrom ===
                  routes.monitor_service_professionals
                  ? this.props?.serviceProfessionalID
                  : data.serviceprofessionalId.value,
              from_timestamp: moment(data.from_timestamp).set("second", "00"),
              to_timestamp: moment(data.to_timestamp).set("second", "00"),
              description: data.description,
              leaveType: data?.leaveType?.value,
              unavailability_for: data?.unavailability_for,
            };
            this.props.EDIT_AVAILABILITY({ ...payload });

            // CALL UPDATE AVAILABILITY FUNCTION HERE...
          } else {
            //
            console.log(data);
            const payload = {
              status: data?.status !== "unavailable" ? false : true,
              serviceprofessionalId:
                this.props?.redirectFrom !== undefined &&
                this.props?.redirectFrom ===
                  routes.monitor_service_professionals
                  ? this.props?.serviceProfessionalID
                  : data.serviceprofessionalId.value,
              from_timestamp: moment(data.from_timestamp).set("second", "00"),
              to_timestamp: moment(data.to_timestamp).set("second", "00"),
              description: data.description,
              leaveType: data?.leaveType?.value,
              unavailability_for: data?.unavailability_for,
            };
            this.props.CREATE_AVAILABILITY({ ...payload });
          }
        }
      );
    }
  };

  onCancelButtonClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };
  viewButtonCallback = (parentState) => {
    console.table(parentState);
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ConflictList
          {...this.props}
          activityConflict={this.props?.unavailabilityConflict}
          parentState={parentState}
        />
      ),
    });
  };

  handleCheckBox = async (state, key) => {
    console.log(state);
    let data_load =
      state !== "other_resources"
        ? allPersonaIds.service_professional
        : allPersonaIds.other_resources;
    if (state) {
      this.setState({ [key]: state });
      // this.props.options = []
      let data = await this.props.GET_ALL_PERSONA_RESOURCE({
        searchValue: "",
        resourceTypeId: data_load,
        resourceTypeName: resourceTypeName.persona,
      });
      console.log(data?.payload?.data);
      this.setState({ professional_options: [] });
      this.setState({ professional_options: data?.payload?.data });
    }

    // console.log(this.state)
  };

  render() {
    let isEdit = this.props.isEdit;
    let field_id = this?.props?.field_id;
    let {
      data: editData,
      loading: editLoading,
      error: editError,
    } = this.props?.editAvailability;
    const { loading: updateLoading } = this.props?.updateAvailability;
    const { loading } = this.props?.addAvailability;
    const { loading: leaveTypesLoading, data: leaveTypes = [] } =
      this.props?.leaveTypes;
    const { data: unavailable_types = [] } = this.props.unavailable_types;
    let { parentState, persistState } = this.props;

    // const {
    //   data: professional_options = []
    // } = this.props?.options;

    /*  const loadServiceProfessionalOptions = async (search, loadedOptions) => {
      const response = await this.props.GET_ALL_PERSONA_RESOURCE({
        searchValue: search,
        resourceTypeId: allPersonaIds.service_professional,
        resourceTypeName: resourceTypeName.persona,
      });
      console.log(await response?.payload?.data)
      return  converToReactSelect(await response?.payload?.data, "id", "name")
    }; */

    /* const loadOtherResourceOptions = async (search, loadedOptions) => {
      const response = await this.props.GET_ALL_PERSONA_RESOURCE({
        searchValue: search,
        resourceTypeId: allPersonaIds.other_resources,
        resourceTypeName: resourceTypeName.persona,
      });
      console.log(converToReactSelect(await response?.payload?.data, "id", "name"))
      return converToReactSelect(await response?.payload?.data, "id", "name")
    } */

    const getServiceProfessionalId = (data) => {
      this.setState({ serviceProfessionalId: data?.value }, () => {
        checkForExistingActivities();
      });
    };

    // const getProfessionalOrOtherResourcesDropDown = async(state) => {
    //   return state !== "other_resources" ? await loadServiceProfessionalOptions() : await loadOtherResourceOptions()

    // }

    const getLeaveValues = () => {
      // console.log(leaveTypes)
      // console.log(leaveTypes?.filter((x) => x.value === editData?.leaveType)?.[0])
      console.log(
        unavailable_types?.filter((x) => x.value === editData?.leaveType)?.[0]
      );
      let res = leaveTypes?.filter((x) => x.value === editData?.leaveType)?.[0];
      if (res === undefined) {
        return unavailable_types?.filter(
          (x) => x.value === editData?.leaveType
        )?.[0];
      }
      return res;
    };

    // onAccept

    const onDateChange = (state, key) => {
      this.setState({
        [key]: state,
      });
    };
    const checkForExistingActivities = (state, key) => {
      if (this.state.otherResourceId) {
        const {
          editavailability_otherResourceId,
          editavailability_from_timestamp,
          editavailability_to_timestamp,
          otherResourceId,
          from_timestamp,
          to_timestamp,
        } = this.state;

        if (isEdit) {
          let from = new Date(from_timestamp);
          from.setSeconds("00");
          let to = new Date(to_timestamp);
          to.setSeconds("00");
          if (
            editavailability_otherResourceId !== otherResourceId ||
            editavailability_from_timestamp !== from.toISOString() ||
            editavailability_to_timestamp !== to.toISOString()
          ) {
            if (
              this.state.from_timestamp &&
              this.state.to_timestamp &&
              this.state.otherResourceId
            ) {
              this.props.CONFLICT_CHECK_AT_AVAILABILTY({
                fromTimeStamp: this.state.from_timestamp,
                toTimeStamp: this.state.to_timestamp,
                otherResourceId: this.state.otherResourceId,
                activityId: this.props?.id,
              });
            }
          }
        } else {
          if (
            this.state.from_timestamp &&
            this.state.to_timestamp &&
            this.state.otherResourceId
          ) {
            this.props.CONFLICT_CHECK_AT_AVAILABILTY({
              fromTimeStamp: this.state.from_timestamp,
              toTimeStamp: this.state.to_timestamp,
              otherResourceId: this.state.otherResourceId,
            });
          }
        }
      } else {
        const {
          editavailability_serviceProfessionalId,
          editavailability_from_timestamp,
          editavailability_to_timestamp,
          serviceProfessionalId,
          from_timestamp,
          to_timestamp,
        } = this.state;

        if (isEdit) {
          let from = new Date(from_timestamp);
          from.setSeconds("00");
          let to = new Date(to_timestamp);
          to.setSeconds("00");
          if (
            editavailability_serviceProfessionalId !== serviceProfessionalId ||
            editavailability_from_timestamp !== from.toISOString() ||
            editavailability_to_timestamp !== to.toISOString()
          ) {
            if (
              this.state.from_timestamp &&
              this.state.to_timestamp &&
              this.state.serviceProfessionalId
            ) {
              this.props.CONFLICT_CHECK_AT_AVAILABILTY({
                fromTimeStamp: this.state.from_timestamp,
                toTimeStamp: this.state.to_timestamp,
                serviceProfessionalId: this.state.serviceProfessionalId,
                activityId: this.props?.id,
              });
            }
          }
        } else {
          if (
            this.state.from_timestamp &&
            this.state.to_timestamp &&
            this.state.serviceProfessionalId
          ) {
            this.props.CONFLICT_CHECK_AT_AVAILABILTY({
              fromTimeStamp: this.state.from_timestamp,
              toTimeStamp: this.state.to_timestamp,
              serviceProfessionalId: this.state.serviceProfessionalId,
            });
          }
        }
      }
    };

    // const handleCheckBox = (state, key) => {
    //   this.setState({ isChecked: state });
    // };

    const components = {
      section_1: {
        label: !isEdit ? "Mark Unavailability" : "Edit Unavailability",
        isChecked: this.state.isChecked,
        fields: {
          /* availability: {
            key: "unavailability_for",
            type: formComponentTypes.CUSTOMIZED_RADIO_BUTTON,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "",
            value: !isEdit ? this.state.unavailability_for : editData?.unavailability_for,
            isDisabled: Boolean(isEdit && editData?.unavailability_for)
             ? true
             : false,
            // isDisabled: true,
            options: options.mark_for,
            componentProps: {
              style: {
                alignSelf: "center"
              },
              fixMargin: true
            },
            onChangeCallBack: this.handleCheckBox,
            // cleanUpKeys: ["serviceprofessionalId"]
            // isMandatory: true,
          }, */

          service_professional: {
            key: "serviceprofessionalId",
            id: `selectServiceProfessionalFrom${field_id ? field_id : ""}`,
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            isDisabled: Boolean(isEdit && editData?.service_professional?.value)
              ? true
              : this.props?.redirectFrom !== undefined &&
                this.props?.redirectFrom ===
                  routes.monitor_service_professionals
              ? true
              : false,
            // isDisabled: Boolean(
            //   isEdit && editData?.service_professional?.value
            // ),
            //label: `CHOOSE ${(this.state.unavailability_for !== "other_resources" && editData?.unavailability_for !== "other_resources") ? `SERVICE PROFESSIONAL` : `OTHER RESOURCE` }` ,
            label: "CHOOSE SERVICE PROFESSIONAL",
            value: persistState
              ? parentState?.serviceprofessionalId
              : !isEdit
              ? {
                  value: this.props?.serviceProfessionalID,
                  label: this.props?.data?.name,
                }
              : editData?.service_professional
              ? {
                  value: !isEdit
                    ? ""
                    : editData?.service_professional?.value
                    ? editData?.service_professional?.value
                    : "",
                  label: !isEdit
                    ? ""
                    : editData?.service_professional?.label
                    ? editData?.service_professional?.label
                    : "N/A",
                }
              : {
                  value: "",
                  label: "",
                },
            onSelectedCallback: getServiceProfessionalId,
            isLoading: this.props?.providerBasedOnCategory?.loading,
            isMandatory:
              this.props?.redirectFrom !== undefined &&
              this.props?.redirectFrom === routes.monitor_service_professionals
                ? false
                : true,
            // isDisabled: this.props?.serviceProfessionalID, //Obsolete
            options: this.state.professional_options,
            // isMulti: true,

            // options: this.state.unavailability_for !== "other_resources" ? loadServiceProfessionalOptions : professional_options

            // options: getProfessionalOrOtherResourcesDropDown(this.state.unavailability_for)
          },
          leaveType: {
            key: "leaveType",
            id: `selectLeaveTypesFrom${field_id ? field_id : ""}`,
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            // label: `CHOOSE ${(this.state.unavailability_for !== "other_resources" && editData?.unavailability_for !== "other_resources") ? `LEAVE TYPES` : `TYPES`}`,
            label: "CHOOSE LEAVE TYPES",
            value: persistState
              ? parentState?.leaveType
              : !isEdit
              ? ""
              : getLeaveValues(),
            isMandatory: true,
            isLoading: leaveTypesLoading,
            // options: (this.state.unavailability_for !== "other_resources" && editData?.unavailability_for !== "other_resources") ? leaveTypes : unavailable_types,
            options: leaveTypes,
            // isMulti: true,
          },

          /*  other_resources: {
            key: "otherresourceid",
            type: formComponentTypes.SELECT_BOX_WITH_PAGINATION,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            isDisabled: Boolean(isEdit && editData?.service_professional?.value)
              ? true
              : this.props?.redirectFrom !== undefined &&
                this.props?.redirectFrom ===
                  routes.monitor_service_professionals
              ? true
              : false,
            // isDisabled: Boolean(
            //   isEdit && editData?.service_professional?.value
            // ),
            label: "CHOOSE OTHER RESOURCES",
            value: persistState
              ? parentState?.otherResourceId
              : !isEdit
              ? {
                  value: this.props?.otherResourceId,
                  label: this.props?.data?.name,
                }
              : editData?.service_professional
              ? {
                  value: !isEdit
                    ? ""
                    : editData?.service_professional?.value
                    ? editData?.service_professional?.value
                    : "",
                  label: !isEdit
                    ? ""
                    : editData?.service_professional?.label
                    ? editData?.service_professional?.label
                    : "N/A",
                }
              : {
                  value: "",
                  label: "",
                },
            onSelectedCallback: getOtherResourceId,
            isLoading: this.props?.providerBasedOnCategory?.loading,
            isMandatory:
              this.props?.redirectFrom !== undefined &&
              this.props?.redirectFrom === routes.monitor_service_professionals
                ? false
                : true,
            // isDisabled: this.props?.serviceProfessionalID, //Obsolete
            options: loadOtherResourceOptions,
            // isMulti: true,
          }, */
          //  unavailableType: {
          //   key: "unavailableType",
          //   type: formComponentTypes.SELECT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 12,
          //     md: 12,
          //     lg: 12,
          //     xl: 12,
          //   },
          //   label: "CHOOSE Unavailable TYPE",
          //   value: persistState
          //     ? parentState?.unavailable_types
          //     : !isEdit
          //     ? ""
          //     : unavailable_types?.filter((x) => x.value === editData?.unavailable_types)?.[0],
          //   isMandatory: true,
          //   isLoading: leaveTypesLoading,
          //   options: unavailable_types,
          //   // isMulti: true,
          // },

          // availability_from: {
          //   key: "from_timestamp",
          //   type: formComponentTypes.DATE_TIME_PICKER,
          //   handleOnAccept: checkForExistingActivities,
          //   isDateTime: true,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 12,
          //     md: 12,
          //     lg: 12,
          //     xl: 12
          //   },
          //   label: "FROM DATE & TIME",
          //   value: !isEdit ? null : editData.from,
          //   onChangeCallBack: onDateChange,
          //   isMandatory: true
          //   // isMulti: true,
          // },
          // availability_to: {
          //   key: "to_timestamp",
          //   handleOnAccept: checkForExistingActivities,
          //   type: formComponentTypes.DATE_TIME_PICKER,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 12,
          //     md: 12,
          //     lg: 12,
          //     xl: 12
          //   },
          //   label: "TO DATE & TIME",
          //   isDateTime: true,
          //   value: !isEdit ? null : editData.to,
          //   onChangeCallBack: onDateChange,
          //   isMandatory: true
          //   // isMulti: true,
          // },

          availability_duration: {
            type: formComponentTypes.FROM_TO_DATE_TIME_PICKER,
            id: {
              // recurrence_type_id: `selectActiviyRecurrenceForActivityFrom${field_id ? field_id : ""}`,
              recurrence_from_time_id: `selectUnavailabilityFromTimeForActivityFrom${
                field_id ? field_id : ""
              }`,
              recurrence_to_time_id: `selectUnavailabilityToTimeForActivityFrom${
                field_id ? field_id : ""
              }`,
            },
            key: {
              fromDateKey: "from_timestamp",
              toDateKey: "to_timestamp",
            },
            label: {
              fromDateKey: "From date & time",
              toDateKey: "To date & time",
            },
            value: {
              from_timestamp: persistState
                ? parentState?.from_timestamp
                : !isEdit
                ? null
                : editData.from,
              to_timestamp: persistState
                ? parentState?.to_timestamp
                : !isEdit
                ? null
                : editData.to,
            },
            componentProps: {
              onFromDateChange: onDateChange,
              onToDateChange: onDateChange,
              handleOnFromDateAccept: checkForExistingActivities,
              handleOnToDateAccept: checkForExistingActivities,
              minDateTime: {
                minFromDateTime: isEdit
                  ? editData?.from
                    ? moment(editData?.from).isAfter()
                      ? new Date()
                      : new Date(editData?.from)
                    : new Date()
                  : new Date(),
                minToDateTime: this.state.from_timestamp,
              },
              disablePast: {
                disableFromDatePast: isEdit ? false : true,
                disableToDatePast: isEdit ? false : true,
              },
            },
            isMandatory: true,
          },

          description: {
            key: "description",
            id: `addDescriptionFrom${field_id ? field_id : ""}`,
            type: formComponentTypes.RICHTEXTEDITOR,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "ADD DESCRIPTION",
            value: persistState
              ? parentState?.description
              : !isEdit
              ? ""
              : editData?.description,
            componentProps: {
              limit: 300,
            },
            isMandatory: true,
          },

          activitiesFound: {
            key: "activitiesFound",
            id: `addConflictAvailabilitiesFrom${field_id ? field_id : ""}`,
            type: formComponentTypes.CARD_WITH_TEXT_AND_CALLBACK,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            isShowAvailabilities: this.state.isShowAvailabilities,
            label: "ADD DESCRIPTION",
            title: `
            ${
              this.state?.activityCount > 0 && this.state?.availabilityCount > 0
                ? ` ${
                    this.state?.activityCount ? this.state?.activityCount : 0
                  } ${
                    this.state.activityCount > 1 ? "Activities" : "Activity"
                  }  and ${
                    this.state.availabilityCount
                      ? this.state.availabilityCount
                      : 0
                  } ${
                    this.state.availabilityCount > 1
                      ? "Unavailabilities"
                      : "Unavailability"
                  } Found.`
                : this.state?.activityCount > 0
                ? ` ${
                    this.state.activityCount ? this.state.activityCount : 0
                  } ${
                    this.state.activity > 1 ? "Activities" : "Activity"
                  } Found.`
                : this.state?.availabilityCount > 0
                ? `${
                    this.state.availabilityCount
                      ? this.state.availabilityCount
                      : 0
                  } ${
                    this.state.availabilityCount > 1
                      ? "Unavailabilities"
                      : "Unavailability"
                  } Found.`
                : ""
            }`,
            mainText:
              "Please re-schedule them to avoid conflicts and better client experience.",
            // "Please re-schedule them to avoid conflicts and better client experience. Otherwise it'll be pushed to free slots.",.
            buttonText: "View Conflicts",
            // navigationLink: "# ",
            viewButtonCallback: this.viewButtonCallback,
            isMandatory: true,
          },
          // other_activities: {
          //   key: "other_activities",
          //   type: formComponentTypes.CHECK_BOX,
          //   isHide: !this.state.isShowAvailabilities,
          //   componentProps: {},
          //   breakPoints: {
          //     xs: 12,
          //     sm: 12,
          //     md: 12,
          //     lg: 12,
          //     xl: 12
          //   },
          //   withBoldHeading: false,
          //   onChangeCallBack: handleCheckBox,
          //   // isMandatory: true,
          //   options: [
          //     { label: "Yes, I'm Aware of the schedule", value: "yes" }
          //   ],
          //   value: [],
          //   label: ""
          // }
        },
      },
    };
    return (
      <>
        {isEdit ? (
          <>
            {editLoading && (
              <LoadingSection message={"Loading  Unavailability..."} />
            )}
            {!editLoading && editError && (
              <InternatlServerError
                message={"Unable to fetch  details"}
                onRetry={this.getEssentials}
              />
            )}
            {!editLoading && !editError && !editData && (
              <NotFoundSection message={"404 - Unavailability Not Found."} />
            )}
            {!editLoading && !editError && editData && (
              <AddEditProductManagemet
                components={components}
                onSaveButtonClicked={this.onSaveButtonClicked}
                cancelForm={this.onCancelButtonClicked}
                loading={updateLoading}
                disabled={this.state.isShowAvailabilities || moment(this.state.from_timestamp)?.seconds(0).isSame(moment(this.state.to_timestamp)?.seconds(0))}
                isUrl={true}
                saveButtonLabel={updateLoading ? "Updating..." : "Update"}
                withoutBoxShadow
                spacing={1}
                sectionGutterBottom={16}
                saveButtonId={"saveAvailabilityButtonFromAddEditAvailability"}
                cancelButtonId={
                  "cancelAvailabilityButtonFromAddEditAvailability"
                }
              />
            )}
          </>
        ) : (
          <AddEditProductManagemet
            components={components}
            onSaveButtonClicked={this.onSaveButtonClicked}
            cancelForm={this.onCancelButtonClicked}
            loading={!loading || !this.state.isChecked ? false : true}
            disabled={this.state.isShowAvailabilities || moment(this.state.from_timestamp)?.seconds(0).isSame(moment(this.state.to_timestamp)?.seconds(0))}
            saveButtonLabel={
              loading ? "Marking Unavailablitity..." : "Mark Unavailablitity"
            }
            withoutBoxShadow={true}
            spacing={1}
            sectionGutterBottom={16}
            saveButtonId={"saveAvailabilityButtonFromAddEditAvailability"}
            cancelButtonId={"cancelAvailabilityButtonFromAddEditAvailability"}
          />
        )}
        {/* {isEdit && (
          <Grid
            container
            alignItems={"flex-start"}
            direction="row"
            spacing={3}
            style={{ padding: "8px 22px 16px 22px" }}
          >
            <Grid item xs={12}>
              <div
                style={{ color: "#f27d35", cursor: "pointer" }}
                onClick={() => {
                  this.handleAvailabilityDelete();
                }}
              >
                <TrashIcon color="#f27d35" /> Delete this Unavailability
              </div>
            </Grid>
          </Grid>
        )} */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  careGiver: state.careGiver,
  addAvailability: state?.availability_app?.add_availability,
  editAvailability: state?.availability_app?.get_availability ?? {
    data: [],
    error: false,
    loading: false,
  },
  updateAvailability: state?.availability_app?.edit_availability ?? {
    data: [],
    error: false,
    loading: false,
  },
  unavailabilityConflict: state?.availability_app?.conflict,
  leaveTypes: state?.masterSet?.leave_types,
  unavailable_types: state?.masterSet?.unavailable_types,
  options: state?.resourceReducer?.allPersonaResource,
});

// export default AddEditAvailabiity
export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(AddEditAvailabiity)));
