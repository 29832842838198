import React, { Component } from "react";
import PropTypes from "prop-types";
import AddEditDynamicFromRenderer from "../../../addEditDynamicFromRenderer";
import { components } from "../../../addEditDynamicFromRenderer/components";

class AddEditCareCoordinator extends Component {
  render() {
    return <AddEditDynamicFromRenderer component={components.COORDINATOR} />;
  }
}

AddEditCareCoordinator.propTypes = {
  data: PropTypes.array,
};

export default AddEditCareCoordinator;
