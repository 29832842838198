import * as React from "react";
import { makeStyles, SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyle = makeStyles((theme) => ({
  prefix__cls: {
    fill: (props) => props.color,
  },
}));

export const KeyIcon = (props) => {
  const classes = useStyle(props);
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
      htmlColor={props.color}
      style={props.style}
    >
      <path className={classes.prefix__cls} d="M22 14H11v-4h11a2 2 0 110 4z" />
      <path
        className={classes.prefix__cls}
        d="M18 15v-3h4v3a2 2 0 11-4 0zM8.37 5.13a7.012 7.012 0 00-8.24 8.24c.522 2.755 2.744 4.978 5.5 5.5a7.011 7.011 0 008.24-8.24c-.522-2.756-2.744-4.978-5.5-5.5zM7 15a3 3 0 110-6 3 3 0 010 6z"
      />
    </SvgIcon>
  );
};

KeyIcon.defaultProps = {
  color: "#9297a5",
};

KeyIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};