import { FormControlLabel, Grid, Checkbox, withStyles } from '@material-ui/core'
import React from 'react'
import { DateTimePickersCustom } from '../dateTimePicker'

const VARIANT = "mobile"

const CustomCheckbox = withStyles((theme) => ({
    root: {
        color: theme.palette.success.main,
        '&$checked': {
            color: theme.palette.success.dark,
        },
    },
    checked: {},
}))((props) => <Checkbox color="default" {...props} />);



export function EffectiveDateExpiryDate({effective, noExpiry, expiry, keys, handleChange, effectiveError, expiryError}) {
    return (
        <Grid container>
            <Grid item xs={12}>
                <DateTimePickersCustom
                    label="Effective Date"
                    value={effective}
                    handleOnChange={(newEffectiveDate) => handleChange(newEffectiveDate, keys?.effective)}
                    isDate={true}
                    variant={VARIANT}
                    dontCloseOnChange
                    isHidePastDate
                    error={effectiveError?.status}
                    errorText={effectiveError?.text}
                />
                <FormControlLabel
                    control={<CustomCheckbox checked={noExpiry} onChange={(evt) => handleChange(evt.target.checked, keys?.noExpiry)} name="noExpiryDate" />}
                    label="No Expiry Date"
                    labelPlacement="end"
                /> 
            </Grid>
            <Grid item xs={12}>
                <DateTimePickersCustom
                    label="Expiry Date"
                    value={expiry}
                    handleOnChange={(newExpiryDate) => handleChange(newExpiryDate, keys?.expiry)}
                    minDate={effective}
                    isDate={true}
                    variant={VARIANT}
                    dontCloseOnChange
                    isHidePastDate
                    error={expiryError?.status}
                    errorText={expiryError?.text}
                    disabled={noExpiry}
                />
            </Grid>
        </Grid>
    )
}