import React from 'react';
import { makeStyles, Avatar, Typography, Grid, Button } from '@material-ui/core';
import { ActivitiesIcon } from "../../assets";
// import ActivitiesIcon from '../../assets/product Management/listingScreen/activities'
import moment from 'moment'
import { DrawerContext } from '../../contexts';
import ActivitySummary from '../../screens/availability/calendar/activitySummary';
import AddEditActivity from '../../screens/availability/calendar/addEditActivity/index';
import AddEditRescheduleActivity from '../../screens/availability/calendar/addEditActivity/activityReschedule';
import  ActivityLog  from "../../screens/availability/calendar/addEditActivity/activityLog";
import AddEditAvailability from '../../screens/availability/calendar/addEditAvailability/index';
// import { formComponentTypes } from '../../utils'
// import { DateTimePickersCustom } from '../dateTimePicker';

const useStyle = makeStyles((theme) => ({
    avatar: (props) => ({
        height: 50,
        width: 50,
        padding: 16,
        backgroundColor: `${props.is_attended ? 'rgba(49, 177, 152, 0.08)' : '#F4F4F4'}`,
        color: `${props.is_attended ? 'rgba(49, 177, 152, 0.08)' : '#F4F4F4'}`
        // backgroundColor: "rgba(49, 177, 152, 0.08)",

    }),
    textArea: {
        marginLeft: 15,
        marginTop: 2,

    },
    text1: {
        display: 'flex',
        fontSize: 16,
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.85,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#252b43",
    },
    text: {
        display: 'flex',
        color: "#9297a5",
    },

    activecircle: {
        marginLeft: 10,
        marginTop: 6,
        width: 9,
        height: 9,
        background: theme.palette.success.main,
        borderRadius: "50%"
    },

    active_name: {
        display: "inline",
        paddingLeft: "6px",
        fontSize: "14px",
        color: theme.palette.success.main
    },
    righttext:
    {
        color: theme.palette.text.secondary,
        marginRight: 20
    },
    bottomcards:
    {
        backgroundColor: "#ffffff",
        boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
        padding: 16,
        borderRadius: 8,
        marginBottom: theme.spacing(2)
    },
}))

const fabId = {
    'ADD_ACTIVITY': 1,
    'ADD_AVAILABILITY': 2
}


// const RescheduleComponent = ({setFromDateTimeParent, setToDateTimeParent, ...props}) => {
//     const [from_date_time, setFromDateTime] = React.useState(new Date())
//     const [to_date_time, setToDateTime ] = React.useState(new Date())

//     const [available, setAvailable] = React.useState(false)
//     const [errorText, setErrorText] = React.useState("")
//     const [fieldError, setFieldErrorText] = React.useState("")

//     let {data, parent_props} = props;

//     // 
//     // console.log(setFromDateTimeParent)
//     const onDeleteRemainder = (label) => {
//         if(label === "from_time_date")
//         {
//             setFromDateTime("")
//         }
//         else
//         {
//             setToDateTime("")
//         }
//     }

//     const timeChange = async ( value, label) => {
//         try{
//         // console.log(value)
//         if(label === "from_time_date")
//         {
//             // console.log("INSIDE")
//             setFromDateTime(new Date(value))
//             console.log(value)
//             if (moment(from_date_time).isAfter(moment(to_date_time))) {
//                 setAvailable(false)
//                 setFieldErrorText("To Time, must be greater than from time.")
//             }
//             setFromDateTimeParent(value)
//             // 
            
//         }
//         else
//         {
//             // 
//             setToDateTime(value)
//             // setFromDateTimeParent(from_date_time)
//             // setToDateTimeParent(value)
//             var now = moment(new Date(from_date_time)); //todays date
//             var end = moment(new Date(to_date_time)); // another date
//             var duration = moment.duration(end.diff(now));
//             console.log(duration)
//             if (moment(from_date_time).isAfter(moment(to_date_time))) {
//                 setAvailable(false)
//                 setFieldErrorText("To Time, must be greater than from time.")
//             }
//             else if (duration?.days() === 0) {
//                 // Half An Hour Validity
//                 if ( !Boolean(Math.floor(duration.hours()) > 0 || Math.floor(duration.minutes()) > 29)) 
//                 {
//                     setAvailable(false)
//                     setErrorText("Activity duration must be atleaset 30 minutes.")
//                     // console.log(available)
//                 }
//                 else
//                 {
                    
//                     let response_data = await parent_props.CONFLICT_CHECK_AT_AVAILABILTY({
//                         fromTimeStamp: from_date_time,
//                         toTimeStamp: to_date_time,
//                         serviceProfessionalId: data?.service_professional_info?.id,
//                         activityId: data?.activity_id,
//                     })
//                     if(response_data?.payload?.data?.status === "success")
//                     {
//                         setAvailable(true)
//                         setErrorText("Service Professional Is Available at the selected Time")
                        
//                     }
//                 }
//             }
            
//         }
//         }
//         catch(e){}
//     }
//     return (<>
//         <DateTimePickersCustom 
//             isHidePastDates={true} 
//             isDateTime={true} 
//             minDate = {new Date()}
//             value = {new Date(from_date_time)}
//             // onDeleteRemainder={onDeleteRemainder("from_time_date")}
//             onDeleteRemainder={console.log("")}
//             handleOnChange={(newvalue) => { timeChange(newvalue, "from_time_date" )}} 
//             label={"From Date and Time"}/>
//         <DateTimePickersCustom 
//             isHidePastDates={true} 
//             isDateTime={true}
//             minDate= {new Date(from_date_time)}
//             value = {new Date(to_date_time)}
//             // onDeleteRemainder={() => {onDeleteRemainder("to_time_date")}}
//             onDeleteRemainder={console.log("")}
//             handleOnChange={(newvalue) => { timeChange(newvalue, "to_time_date" )}}
//             errorText={fieldError}
//             label={"To Date and Time"}/>
//         <Typography variant="caption" style={{ visibility: available ?? "hidden" ,display: available ?? "none" , color: available ? "green" : "red" }}>{errorText}</Typography>
//         </>)
// }

export const Bottomcard = (props) => {

    const drawer = React.useContext(DrawerContext);
    // const modal = React.useContext(DialogContext);

    // const [from_date_time_parent, setFromDateTimeParent] = React.useState()
    // const [to_date_time_parent, setToDateTimeParent ] = React.useState()

    

    const onBackBtnClicked = () => {
        drawer.setDrawer({
            ...drawer,
            open: false
        })
    }


    const onEditBtnClicked = (selectedFabId, data) => {
        console.log(data)
        if (selectedFabId === fabId.ADD_ACTIVITY) {
           
            drawer.setDrawer({
                ...drawer,
                open: true,
                component: <AddEditActivity reload={props.getClientActivities} data={data} isEdit={true} id={data?.activity_id} />
            })
        } else if (selectedFabId === fabId.ADD_AVAILABILITY) {
            drawer.setDrawer({
                ...drawer,
                open: true,
                component: <AddEditAvailability reload={props.getClientActivities} data={data} isEdit={true} id={data?.availability_id} />
            })
        }
    }


    // const onRescheduleBtnClicked = (props, data ) => {
    const onRescheduleBtnClicked = (selectedFabId, data) => {
        console.log(data)
        if (selectedFabId === fabId.ADD_ACTIVITY) {
           
            drawer.setDrawer({
                ...drawer,
                open: true,
                //component: <AddEditActivity isReschedule={true} reload={props.getClientActivities} data={data} isEdit={true} id={data?.activity_id} />
                component: <AddEditRescheduleActivity isReschedule={true} reload={props.getClientActivities} data={data} isEdit={true} id={data?.activity_id} />
            })
        } else if (selectedFabId === fabId.ADD_AVAILABILITY) {
            drawer.setDrawer({
                ...drawer,
                open: true,
                component: <AddEditAvailability isReschedule={true} reload={props.getClientActivities} data={data} isEdit={true} id={data?.availability_id} />
            })
        }

        // 
        // console.log(props)
        
        // drawer.setDrawer({
        //     ...drawer,
        //     open: false,
        //     component: <></>,
        //     }
        // )

        // // from_date_time_parent
        // // setFromDateTimeParent
        // // to_date_time_parent
        // // setToDateTimeParent
        
       

        // const editActivity = async(data, props) => 
        // {
        //     // 
        //     // console.log(data)
        //     console.log(from_date_time_parent)
        //     console.log(to_date_time_parent)
        //     const payload = {
        //         activityId: data?.activity_id,
        //         title: data?.activity_name,
        //         cliendId: data?.client_id,
        //         serviceprofessionalId: data?.service_professional_info?.id,
        //         activity_type_id: data.activity_type?.value,
        //         activityTypeName: data.activity_type?.label,
        //         // from_timestamp: moment(data.from_timestamp).set("second", "00"),
        //         // to_timestamp: moment(data.to_timestamp).set("second", "00"),
        //         resourceId: data?.service_professional_info?.id,
        //         occurence: data?.occurence,
        //         occurence_type: data?.occurence_type,
        //         repeat_on: data?.recurrence || [],
        //         from_timestamp: new Date(
        //           new Date(from_date_time_parent)?.setSeconds(0)
        //         ),
  
        //         // this.getFromTimeStampPayload(data),
  
        //         to_timestamp: new Date(
        //           new Date(to_date_time_parent)?.setSeconds(0)
        //         ),
  
        //         //  this.getToTimeStampPayload(data),
  
        //         // occurence_type: data?.occurence_type ? data?.occurence_type : "",
        //         mode: data?.mode ? data?.mode : "VIDEO_CALL",
        //         videoCallUrl: data?.videoCallUrl ? data?.videoCallUrl : "",
        //         description: data.activity_description,
        //         selectedVenue: data?.selectedVenue,
        //         address: data?.address
        //           ? data?.address
        //           : "",
        //         longitude: data?.longitude
        //           ? data?.longitude?.toString()
        //           : "",
        //         latitude: data?.latitude
        //           ? data?.latitude?.toString()
        //           : "",
        //         subscriptionId: data?.subscriptions?.value
        //           ? data.subscriptions.value
        //           : "",
        //         subscriptionType: data?.subscription?.type_id
        //           ? data?.subscription?.type_id
        //           : "",
        //         serviceId: data?.services?.value ? data?.services?.value : "",
        //       };
        //     //   
        //       console.log(payload)
        //     //   await props.EDIT_ACTIVITY({ ...payload });
        // }
                
        // modal.setDialog({
        //     open: true,
        //     title: "Reschedule Activity",
        //     body: <RescheduleComponent data={data} parent_props={props} setFromDateTimeParent={(value)=>setFromDateTimeParent(value)} setToDateTimeParent={(value)=> setToDateTimeParent(value)}/>,
        //     positiveBtn: "Reschedule",
        //     negativeBtn: "Cancel",
        //     onOk: () => {
        //     editActivity(data, props)
        //     console.log("HAI")
        //     console.log(from_date_time_parent)
        //     },
        // })
    }


    const activityLogBtnClicked = (selectedFabId, data) => {
        if (selectedFabId === fabId.ADD_ACTIVITY) {
           
            drawer.setDrawer({
                ...drawer,
                open: true,
                //component: <AddEditActivity reload={props.getClientActivities} data={data} isEdit={true} id={data?.activity_id} />
                component: <ActivityLog id={data?.activity_id} {...props}/>
            })
        } else if (selectedFabId === fabId.ADD_AVAILABILITY) {
            drawer.setDrawer({
                ...drawer,
                open: true,
                component: <AddEditAvailability reload={props.getClientActivities} data={data} isEdit={true} id={data?.availability_id} />
            })
        }
    }




    const onViewSummaryClicked = () => {
        drawer.setDrawer({
            ...drawer,
            open: true,
            component: <ActivitySummary
                onBackBtnClicked={onBackBtnClicked}
                onEditBtnClicked={onEditBtnClicked}
                onRescheduleBtnClicked={onRescheduleBtnClicked}
                activityLogBtnClicked={activityLogBtnClicked}
                reload={props.getClientActivities}
                drawer={drawer}
                showServiceProfessional={true}
                hideClient={true}
                data={props} />
        })
    }

    const classes = useStyle(props);
    const { activity_name, is_attended, date_time } = props
    return (
        <div className={classes.bottomcards}>
            <Grid container>
                <Grid item container xs>
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <ActivitiesIcon chosenColor={`${props.is_attended ? '#31b198' : '#F4F4F4'}`}
                                chosenFill={`${props.is_attended ? '#31b198' : '#9297A5'}`}
                            />
                        </Avatar>
                    </Grid>
                    <Grid item className={classes.textArea}>
                        <div>
                            <Typography variant="body1" className={classes.text1} >{activity_name}</Typography>
                        </div>
                        <div className={classes.text}>
                            <Typography variant="body2" className={classes.text}>{`Status : ${is_attended}`}</Typography>
                        </div>
                    </Grid>
                </Grid>
                <Grid item style={{ margin: 'auto' }}>
                    <Typography variant={"subtitle2"} className={classes.righttext}>
                        {date_time ? `${moment(date_time).format('MMM DD, YYYY')} at ${moment(date_time).format('hh:mm A')}` : 'N/A'}
                    </Typography>
                </Grid>
                <Grid item style={{ margin: 'auto' }}>
                    <Button variant="outlined" color="primary" onClick={onViewSummaryClicked} >View Summary</Button>
                </Grid>
            </Grid>
        </div>
    )
}