import React, { useState, useEffect } from "react";
import { createFile } from "../../../../../../utils";
import { LoadingSection } from "../../../../../../components/integerateHelper";

export default function RenderPdf({ fileUrl = "", name = "Tanyacare" }) {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const generatePdfUrl = () => {
      if (fileUrl) {
        new Promise((resolve) => {
          resolve(createFile(fileUrl, "application/pdf", "item.issue_tag"));
        }).then((value) => {
          setUrl(value);
          setLoading(false);
        });
      }
    };
    generatePdfUrl();
  }, [fileUrl]);

  return (
    <>
      {loading ? (
        <LoadingSection message="Loading Quote..." top="20.5vh" bottom="20.5vh" />
      ) : (
        <object
          data={url.concat("#toolbar=0")}
          type="application/pdf"
          width="100%"
          height={400}
          name={name}
        >
          <embed src={url.concat("#toolbar=0")} type="application/pdf" />
        </object>
      )}
    </>
  );
}
