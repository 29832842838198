import * as React from "react"

export const DeleteIcon = (props) => {
 let width = props.width ? props.width : "13.3";
 let height = props.height ? props.height : "14";
 let color = props.color ? props.color : "#ff4d4a";
 return (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 13.3 14"
        {...props}
    >
        <path id="icons8-trash" d="M11.65,4A2.458,2.458,0,0,0,9.228,6.1H6.833a.472.472,0,0,0-.168,0H5.525a.525.525,0,1,0,0,1.05h.749l.881,9.11A1.932,1.932,0,0,0,9.071,18h5.158a1.931,1.931,0,0,0,1.916-1.74l.882-9.11h.749a.525.525,0,1,0,0-1.05h-1.14a.525.525,0,0,0-.168,0h-2.4A2.458,2.458,0,0,0,11.65,4Zm0,1.05A1.387,1.387,0,0,1,13,6.1H10.3A1.387,1.387,0,0,1,11.65,5.05ZM7.328,7.15h8.644L15.1,16.159a.868.868,0,0,1-.871.791H9.071a.869.869,0,0,1-.871-.791Zm3.089,1.742a.525.525,0,0,0-.517.533v5.25a.525.525,0,1,0,1.05,0V9.425a.525.525,0,0,0-.533-.533Zm2.45,0a.525.525,0,0,0-.517.533v5.25a.525.525,0,1,0,1.05,0V9.425a.525.525,0,0,0-.533-.533Z" transform="translate(-5 -4)" 
            fill={color}
        />
    </svg>
  );
};
