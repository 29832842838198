import React from "react";
import CustomTable from "./table";
import QuoteTable from "./quoteTable";

export const Table = (props) => {
  const {
    isQuoteVariantTable = false,
    rows,
    handleRowsReorder,
    columns,
    handleRemove,
    state,
    withDiscount,
    withTotal,
    needActionsAsIcons,
    withoutRemove,
    colSpanforAmount,
    handlePopperClose,
    handlePopperOpen,
    popperData,
    openPopper,
    isEnquiry,
    isdownload,
    onBlurTextField,
    // id,
  } = props;

  return (
    <>
      {/* 
          Temporarily Used quote table for create quote drawer in sales operation, 
          since new UI has been used while redesigning. Looking forward to update this component 
      */}
      {isQuoteVariantTable ? (
        <QuoteTable
          columns={columns ? columns : [{}]}
          rows={rows ? rows : []}
          handleRowsReorder={handleRowsReorder}
          handleRemove={handleRemove}
          state={state}
          withDiscount={withDiscount}
          isEnquiry={isEnquiry}
          isdownload={isdownload}
          withTotal={withTotal}
          needActionsAsIcons={needActionsAsIcons}
          withoutRemove={withoutRemove}
          colSpanforAmount={colSpanforAmount}
          handlePopperClose={handlePopperClose}
          handlePopperOpen={handlePopperOpen}
          popperData={popperData}
          openPopper={openPopper}
          onBlurTextField={onBlurTextField}
          {...props}
        />
      ) : (
        <CustomTable
          columns={columns ? columns : [{}]}
          rows={rows ? rows : []}
          handleRowsReorder={handleRowsReorder}
          handleRemove={handleRemove}
          state={state}
          withDiscount={withDiscount}
          isEnquiry={isEnquiry}
          isdownload={isdownload}
          withTotal={withTotal}
          needActionsAsIcons={needActionsAsIcons}
          withoutRemove={withoutRemove}
          colSpanforAmount={colSpanforAmount}
          handlePopperClose={handlePopperClose}
          handlePopperOpen={handlePopperOpen}
          popperData={popperData}
          openPopper={openPopper}
          onBlurTextField={onBlurTextField}
          {...props}
        />
      )}
    </>
  );
};
