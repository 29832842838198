import React from "react";
import AddEditProductManagemet from "../../../../components/addEditForm";
import { formComponentTypes } from "../../../../utils";

export class HolidayDrawer extends React.Component {
  constructor(props){
    super()
    this.state = {
      minDate:new Date()
    }
  }
  componentDidMount (){
    
    this.getMindate()
  }

  getMindate(){
    
    const {selectedMonth, selectedYear} = this.props
    let _date = new Date()
    if(selectedMonth && selectedYear){
      _date.setDate(_date.getDate())
      _date.setMonth(selectedMonth - 1)
      _date.setFullYear(selectedYear) 
    }
    this.setState({minDate:_date})
  }
  render() {
    const {events, eventId, isDBEventEdit} = this.props

    let selectedEvent = {}
    if(eventId){
      selectedEvent = events?.filter(event =>(isDBEventEdit ? event?.id === eventId : event?.localId === eventId))?.[0]
    }
    


    
    const components = {
      section_1: {
        label: eventId ? "Edit Holiday" : "Set Holiday",
        fields: {
          holiday_title: {
            key: "holiday_title",
            type: formComponentTypes.INPUT_BOX,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12
            },
            label: "TITLE",
            value: eventId ? selectedEvent?.holiday_title : "",
            componentProps: {
              type: "text",
              multiline: false,
              placeholder: ""
            },
            isMandatory: true
          },
          occurs_on: {
            key: "occurs_on",
            type: formComponentTypes.DATE_TIME_PICKER,
            isDate: true,
            breakPoints: {
              xs: 12, 
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12
            },
            onChangeCallBack: this.handleCalBackDate,
            label: "OCCURS ON",
            value: eventId ? selectedEvent?.occurs_on : this.state.minDate,
            minDate:this.state.minDate,
            componentProps: {
              // isHidePastDate: true
            },
            isMandatory: true
          }
        }
      }
    };

    return (
      <>
        <AddEditProductManagemet
          components={components}
          onSaveButtonClicked={(isError, data, url, e) => {
            debugger
            this.props.onHolidayAdded(isError, data, url, eventId, isDBEventEdit)
          }}
          cancelForm={this.props.onCancelButtonClicked}
          loading={false}
          isResetState={this.state.minDate}
          saveButtonLabel={eventId ? "Update" : "Add"}
        />
      </>
    );
  }
}
