import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withAlertAndDialog } from "../../HOCs";
import {
  converToReactSelect,
  filterTagType,
  filterType,
  options,
  productSortDirectionOption,
  productSortFieldOption,
  subHeaderSortOptionWithValue,
} from "../../utils";
import { allPersonaIds } from "../../screens/resourceManagement/resources/utils";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import {
  Grid,
  Button,
  Typography,
  Divider,
  withStyles,
} from "@material-ui/core";
import { AsyncPaginateSelect } from "../inputs/select/asyncPaginateSelect";
import { SimpleSelect, CustomizedRadioButton } from "../inputs";
import {
  actionsCall,
  dateOptions,
  statusOptions,
  openStatus,
  createdByOptions,
} from "./utils";
import { routes } from "../../router/routes";

const style = (theme) => ({
  root: {
    // padding: 20,
    // padding: "8px 20px",
    // height: "100vh",
  },
  saveBtnContainer: {
    // position: "absolute",
    // left: 16,
    // bottom: 16,
    marginTop: 10,
    padding: "0px 20px",
    display: "flex",
    justifyContent: "flex-end",
    gap: 12,
  },
  clearAllBtnd: {
    color: theme.palette.ternary.main,
  },
  spacing: {
    // padding: "12px 24px"
  },
  field: {
    padding: "0px 20px",
  },
});

const initialState = {
  category: [],
  products: [],
  provider: [],
  skills: [],
  specialization: [],
  professional: [],
  coordinator: [],
  status: "",
  enquiry_type: null,
  assigned_to: null,
  gender: null,
  role: [],
  createdBy: "",
};

class FilterBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      providerOptionList: null,
    };
  }

  componentDidMount() {
    this.hitDependencyAPI();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { providerOptionList: nextProviders } = nextState;
    const { providerOptionList: prevProviders } = this.state;

    if (JSON.stringify(prevProviders) !== JSON.stringify(nextProviders)) {
      return true;
    }
    return true;
  }

  hitDependencyAPI = () => {
    let state = {};
    Object.keys(this.props?.components).map((section) => {
      console.log(section);
      Object.values(this.props.components[section]?.fields).map((component) => {
        actionsCall(this.props, this.state)[component.type]();
        state[component.key] = component.value;
        console.log(component.key);
        return component;
      });
      return section;
    });

    this.setState({ ...state });
  };

  onChange = (key, value) => {
    if (key === filterTagType.SortDirection && value === null) {
      this.setState({
        [key]: productSortDirectionOption[0],
      });
    } else if (key === filterTagType.SortField && value === null) {
      this.setState({
        [key]: productSortFieldOption[0],
      });
    } else if (key === filterTagType.Category) {
      if (value !== null) {
        this.setState({
          [key]: value,
        });
      } else {
        this.setState({
          [key]: value,
          providerOptionList: null,
        });
      }
    } else {
      this.setState({
        [key]: value,
      });
    }
  };

  resetFilter = () => {
    this.setState({ ...initialState }, () => {
      this.props.resetFilter();
    });
  };

  onApplyButtonClicked = () => {
    this.props.onApplyButtonClicked(this.state);
  };

  categoriesOptionsGettingFromAuthData = () => {
    this.props.GET_CATEGORIES({});
  };

  loadProducts = async (search, loadedOptions) => {
    const response = await this.props.GET_ALL_PRODUCTS({
      pageNo: Math.round(loadedOptions.length / 25),
      pageSize: 25,
      productName: search,
    });
    return {
      options: converToReactSelect(response?.payload?.data?.list, "id", "name"),
      hasMore: response?.payload?.data?.list?.length % 25 === 0,
    };
  };

  loadProvidersBasedOnCategory = async () => {
    //  this.props.GET_PROVIDER_LIST_BASEDON_CATEGORY({ categories: this.state?.category })
    const response = await this.props.GET_PROVIDER_LIST_BASEDON_CATEGORY({
      categories: Array.isArray(this.state?.category)
        ? this.state?.category.map((_) => _.value)
        : [this.state?.category?.value],
    });
    console.log(response);
    return {
      options: response?.payload,
      hasMore:
        response?.payload?.length > 0
          ? response?.payload?.length % 25 === 0
          : false,
    };
  };

  loadProviders = async (search, loadedOptions) => {
    const response = await this.props.GET_ALL_CARE_PROVIDER({
      pageNo: Math.round(loadedOptions?.length / 25),
      pageSize: 25,
      careProviderName: search,
      isInvitedNeeded: true,
      parentPartnerId:
        routes.partner_management_branch === this.props?.match?.path
          ? null
          : "",
    });
    return {
      options: converToReactSelect(response?.payload?.data, "id", "name"),
      hasMore: response?.payload?.data?.length % 25 === 0,
    };
  };

  loadProfessionals = async (search, loadedOptions) => {
    // const response = await this.props.GET_ALL_CARE_GIVER({
    //   pageNo: Math.round(loadedOptions.length / 25),
    //   pageSize: 25,
    //   careGiverName: search,
    // });
    const response = await this.props.GET_ALL_PERSONA_RESOURCE({
      pageNo: Math.round(loadedOptions.length / 25),
      pageSize: 25,
      // careGiverName: search,
      isInvitedNeeded: true,
      resourceTypeId: allPersonaIds.service_professional,
    });

    return {
      options: converToReactSelect(response?.payload?.data, "id", "name"),
      hasMore: response?.payload?.data?.length % 25 === 0,
    };
  };

  loadCoordinators = async (search, loadedOptions) => {
    // const response = await this.props.GET_ALL_CARE_COORDINATOR({
    //   pageNo: Math.round(loadedOptions.length / 25),
    //   pageSize: 25,
    //   careCoordinatorName: search,
    // });
    const response = await this.props.GET_ALL_PERSONA_RESOURCE({
      pageNo: Math.round(loadedOptions.length / 25),
      pageSize: 25,
      // careGiverName: search,
      isInvitedNeeded: true,
      resourceTypeId: allPersonaIds.service_coordinator,
    });
    return {
      options: converToReactSelect(response?.payload?.data, "id", "name"),
      hasMore: response?.payload?.data?.length % 25 === 0,
    };
  };

  loadServices = async (search, loadedOptions) => {
    const response = await this.props.GET_ALL_SERVICES({
      pageNo: Math.round(loadedOptions.length / 25),
      pageSize: 25,
      // [searchKey]: search,
    });
    return {
      options: converToReactSelect(response?.payload?.data?.list, "id", "name"),
      hasMore: response?.payload?.data?.list?.length % 25 === 0,
    };
  };

  loadPackages = async (search, loadedOptions) => {
    const response = await this.props.GET_ALL_PACKAGES({
      pageNo: Math.round(loadedOptions.length / 25),
      pageSize: 25,
      // [searchKey]: search,
    });
    return {
      options: converToReactSelect(response?.payload?.data?.list, "id", "name"),
      hasMore: response?.payload?.data?.list?.length % 25 === 0,
    };
  };

  giveMeTheComponent = (component) => {
    switch (component.type) {
      case filterType.CATEGORY:
        return (
          <SimpleSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            options={this.props?.category?.data}
            isLoading={this.props?.category?.loading ?? false}
            // options={this.props?.auth_data?.data?.category ?? []}
            hideCustomValueContainerHeight={
              component?.componentProps?.hideCustomValueContainerHeight
            }
            value={this.state?.[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
          />
        );

      case filterType.PROVIDER:
        return (
          <AsyncPaginateSelect
            {...component}
            title={component.label}
            id={component?.id}
            label={component.label}
            isMulti={component.isMulti}
            // loadOptions={this.loadProviders}
            loadOptions={
              // this.state[component.cacheUniqs]?.length > 0
              //   ? this.loadProvidersBasedOnCategory
              //   : this.loadProviders
              this.loadProviders
            }
            value={this.state[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
            cacheUniqs={[this.state[component.cacheUniqs]]}
          />
        );

      case filterType.STATUS:
        if (component.component === "SimpleSelect") {
          return (
            <SimpleSelect
              {...component}
              id={component?.id}
              title={component.label}
              label={component.label}
              isMulti={component.isMulti}
              options={component.openstatus ? openStatus : statusOptions}
              hideCustomValueContainerHeight={
                component?.componentProps?.hideCustomValueContainerHeight
              }
              value={this.state?.[component.key]}
              handleOnChange={(e) => {
                this.onChange(component.key, e);
              }}
            />
          );
        } else {
          return (
            <CustomizedRadioButton
              data={{
                label: component.label,
                options: component.openstatus ? openStatus : statusOptions,
              }}
              id={component?.id}
              value={this.state?.[component.key]}
              handleOnChange={(e) => {
                this.onChange(component.key, e);
              }}
            />
          );
        }

      case filterType.GENDER:
        return (
          <SimpleSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            options={options.gender}
            hideCustomValueContainerHeight={
              component?.componentProps?.hideCustomValueContainerHeight
            }
            value={this.state?.[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
          />
        );

      case filterType.DATE_SORT:
        return (
          <CustomizedRadioButton
            data={{
              label: component.label,
              options: dateOptions,
            }}
            value={this.state?.[component.key]}
            id={component?.id}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
          />
        );

      case filterType.PRODUCTS:
        return (
          <AsyncPaginateSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            loadOptions={this.loadProducts}
            value={this.state[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
          />
        );

      case filterType.SERVICE_PROFESSIONAL:
        return (
          <AsyncPaginateSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            loadOptions={this.loadProfessionals}
            value={this.state[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
          />
        );

      case filterType.SERVICE_CORDINATOR:
        return (
          <AsyncPaginateSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            loadOptions={this.loadCoordinators}
            value={this.state[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
          />
        );

      case filterType.SKILLS:
        return (
          <SimpleSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            options={this.props?.skills?.data ?? []}
            isLoading={this.props?.skills?.loading ?? false}
            hideCustomValueContainerHeight={
              component?.componentProps?.hideCustomValueContainerHeight
            }
            value={this.state?.[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
          />
        );

      case filterType.SPECIALIZATION:
        return (
          <SimpleSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            options={this.props?.specialization?.data ?? []}
            isLoading={this.props?.specialization?.loading ?? false}
            hideCustomValueContainerHeight={
              component?.componentProps?.hideCustomValueContainerHeight
            }
            value={this.state?.[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
          />
        );

      case filterType.SERVICES:
        return (
          <AsyncPaginateSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            loadOptions={this.loadServices}
            value={this.state[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
          />
        );

      case filterType.PACKAGES:
        return (
          <AsyncPaginateSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            loadOptions={this.loadPackages}
            value={this.state[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
          />
        );

      case filterType.SORTDIRECTION: {
        return (
          <SimpleSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            options={subHeaderSortOptionWithValue ?? []}
            hideCustomValueContainerHeight={
              component?.componentProps?.hideCustomValueContainerHeight
            }
            value={this.state?.[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
            defaultValue={subHeaderSortOptionWithValue[2]}
            isNotClearable={
              this.state?.[component.key]?.value ===
              subHeaderSortOptionWithValue[2].value
                ? true
                : false
            }
          />
        );
      }

      case filterType.SORTFIELD: {
        return (
          <SimpleSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            options={productSortFieldOption ?? []}
            hideCustomValueContainerHeight={
              component?.componentProps?.hideCustomValueContainerHeight
            }
            value={this.state?.[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
            defaultValue={this.state?.[component.key]}
            isNotClearable={
              this.state?.[component.key]?.value ===
              productSortFieldOption[0].value
                ? true
                : false
            }
          />
        );
      }
      case filterType.ASSIGN_TO: {
        return (
          <SimpleSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            options={this.props?.enquiry_assigned_to?.data ?? []}
            hideCustomValueContainerHeight={
              component?.componentProps?.hideCustomValueContainerHeight
            }
            value={this.state?.[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
            defaultValue={this.state?.[component.key]}
            isNotClearable={
              this.state?.[component.key]?.value ===
              productSortFieldOption[0].value
                ? true
                : false
            }
          />
        );
      }
      case filterType.ENQUIRY_TYPE: {
        return (
          <SimpleSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            options={this.props?.enquiry_type?.data ?? []}
            hideCustomValueContainerHeight={
              component?.componentProps?.hideCustomValueContainerHeight
            }
            value={this.state?.[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
            defaultValue={this.state?.[component.key]}
            isNotClearable={
              this.state?.[component.key]?.value ===
              productSortFieldOption[0].value
                ? true
                : false
            }
          />
        );
      }
      case filterType.ROLE: {
        console.log(this.props.role);
        return (
          <SimpleSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            options={this.props?.role?.data}
            isLoading={this.props?.role?.loading ?? false}
            // options={this.props?.auth_data?.data?.category ?? []}
            hideCustomValueContainerHeight={
              component?.componentProps?.hideCustomValueContainerHeight
            }
            value={this.state?.[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
          />
        );
      }

      case filterType.CREATED_BY: {
        return (
          <SimpleSelect
            {...component}
            id={component?.id}
            title={component.label}
            label={component.label}
            isMulti={component.isMulti}
            options={createdByOptions}
            hideCustomValueContainerHeight={
              component?.componentProps?.hideCustomValueContainerHeight
            }
            value={this.state?.[component.key]}
            handleOnChange={(e) => {
              this.onChange(component.key, e);
            }}
          />
        );
      }

      default:
        return <></>;
    }
  };

  render() {
    const { classes } = this.props;
    const { components, loading } = this.props;

    return (
      <div className={classes.root}>
        {Object.keys(components).map((sections, index) => {
          return (
            <div
              key={index}
              style={{
                display: components?.[sections]?.isDontShow ? "none" : "block",
              }}
            >
              <Grid
                container
                alignItems={"flex-start"}
                className={`${!this.props.fixPadding ? classes.container : ""}`}
                direction="row"
                // spacing={`${this.props.spacing ? this.props.spacing : this.props.fixPadding ? 0 : 3}`}
              >
                {components?.[sections]?.label && (
                  <Grid
                    container
                    item
                    xs={12}
                    justify="space-between"
                    style={{ margin: "20px 20px 22px" }}
                  >
                    <Grid item>
                      <Typography
                        variant="h6"
                        component="h6"
                        style={{ fontWeight: 500 }}
                      >
                        {components[sections].label}
                      </Typography>
                    </Grid>
                    {!this.props.hideClear && (
                      <Grid item>
                        <Button
                          variant="text"
                          className={classes.clearAllBtnd}
                          size="small"
                          onClick={this.resetFilter}
                          id={this?.props?.clearAllButtonId}
                        >
                          CLEAR ALL
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Divider style={{ backgroundColor: "#1010101A" }} />
                </Grid>

                {Object.values(components[sections].fields)?.map(
                  (component, index) => {
                    return (
                      <Grid
                        item
                        {...component.breakPoints}
                        style={{
                          ...(index === 0 && { marginTop: "22px" }),
                          ...component?.componentProps?.style,
                        }}
                        // className={this.props.fullWidth ? classes.item : ""}
                        className={classes.field}
                      >
                        {this.giveMeTheComponent(component)}
                      </Grid>
                    );
                  }
                )}
              </Grid>
              {Object.keys(components).length - 1 > index && (
                <Divider className={classes.divider} />
              )}
            </div>
          );
        })}

        {this.props.isCancelApplyButtonsNeeded && (
          <div className={classes.saveBtnContainer}>
            <Button
              variant={"text"}
              color={"primary"}
              onClick={this.resetFilter}
              style={{ marginLeft: 12 }}
              id={this?.props?.cancelButtonId}
            >
              {"Cancel"}
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              className={classes.saveBtn}
              disabled={loading}
              onClick={this.onApplyButtonClicked}
              id={this?.props?.saveButtonId}
            >
              {this.props.applyButtonLabel
                ? this.props.applyButtonLabel
                : "Apply"}
            </Button>
          </div>
        )}

        {this.props.customCTAComponent &&
          React.cloneElement(this.props.customCTAComponent, {
            handlers: {
              resetFilter: this.resetFilter,
              onApplyButtonClicked: this.onApplyButtonClicked,
            },
          })}
      </div>
    );
  }
}

FilterBuilder.propTypes = {
  components: PropTypes.object,
  applyButtonLabel: PropTypes.string,
  isCancelApplyButtonsNeeded: PropTypes.bool,
  fixPadding: PropTypes.bool,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onApplyButtonClicked: PropTypes.func,
  resetFilter: PropTypes.func,
};

FilterBuilder.defaultProps = {
  components: {},
  isCancelApplyButtonsNeeded: true,
  hideClear: false,
};

const mapStateToProps = (state) => ({
  category: state.masterSet?.categories,
  role: state.masterSet?.user_roles,
  skills: state.masterSet?.skills,
  specialization: state.masterSet?.specialization,
  providersBasedOnCategory: state.careProvider?.providerBasedOnCategory,
  auth_data: state?.authState?.loginAuth,
  // enquiry_type: state?.enquiry_management?.enquiry_type,
  enquiry_type: state?.masterSet?.lead_types,
  enquiry_assigned_to: state?.enquiry_management?.assigned_to,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAlertAndDialog(withStyles(style)(FilterBuilder))));
