import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || "#9297a5",
  }),
  cls2: (props) => ({
    fill: "none",
    stroke: props.color || "#9297a5",
    strokeMiterlimit: 10,
    strokeWidth: 2
  })
}))

export const AboutNewIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon fontSize={'small'} >
      <g id="About" transform="translate(-2 -2)">
        <path id="Path_556" className={classes.cls1} d="M13 17h-2v-6h2zm0-8h-2V7h2z" data-name="Path 556" />
        <path id="Path_557" className={classes.cls2} d="M12 3a9 9 0 1 0 9 9 9 9 0 0 0-9-9z" data-name="Path 557" />
      </g>
    </SvgIcon >
  )
}

AboutNewIcon.propTypes = {
  color: PropTypes.string,
};