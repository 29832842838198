/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-02-03 18:32:29
 * @modify date 2022-03-08 12:34:39
 * @desc Component to render the list of promotional banners in a table with search, filter, sort and pagination
 */

import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import {
  FilterBuilder,
  NotFoundSection,
  PromotionalBannerTable,
} from "../../../../components";
import { withStyles } from "@material-ui/core/styles";
import SubHeader from "../../../../components/subHeader";
import { routes } from "../../../../router/routes";
import { withRouter } from "react-router-dom";
import { alertProps, user_roles } from "../../../../utils";
import { connect } from "react-redux";
import { actions } from "tanyacare-middleware";
import { filterComponent } from "./filterComponent";
import { withAlertAndDialog } from "../../../../HOCs";
import CustomFilterCTA from "./customFilterCTA";
import isEqual from "react-fast-compare";
import { DeletePromotionalBannerDialog } from "./components";
import { createTestProps } from "../../../../utils/common";

const StyledPagination = withStyles((theme) => ({
  ul: {
    "& .MuiPaginationItem-root": {
      fontWeight: "400",
    },
  },
}))(Pagination);

const initialFilterState = {
  status: "",
  createdBy: "",
};

const initialSortState = {
  sortField: "createdAt",
  sortDirection: "ASC",
};

class ListPromotionalBanner extends Component {
  constructor(props) {
    super(props);
    this.pageSize = 8;
    this.state = {
      search: "",
      page: 1,

      // filter States
      ...initialFilterState,
      isFilterApplied: false,

      // Sort States
      ...initialSortState,
    };
  }

  // Lifecycle methods
  componentDidMount() {
    this.getAllPromotionalBanners();
  }

  getAllPromotionalBanners = (searchQuery = "") => {
    this.props.GET_ALL_PROMOTIONAL_BANNER({
      pageNo: this.state.page > 0 ? this.state.page - 1 : 0,
      // pageNo: 0,
      pageSize: this.pageSize,
      searchValue: searchQuery,
      filter: {
        filterRole: this.state.createdBy?.value,
        // filterStatus: this.state.status?.value === "Active",
        filterStatus: this.state.isFilterApplied
          ? this.state.status?.value === "Active"
          : "",

        // ? true
        // : this.state.status?.value === "InActive"
        // ? false
        // : "",
      },
      sort: {
        // Sortfield is effectiveFrom, because we have to sort by effectiveFrom in query
        // and we used effectiveFrom and effectiveTo in table
        sortField:
          this.state.sortField === "effectiveDate"
            ? "effectiveFrom"
            : this.state.sortField,
        sortDirection: this.state.sortDirection,
      },
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { deleteBannerInfo } = this.props;
    const { deleteBannerInfo: nextDeleteBannerInfo } = nextProps;

    if (deleteBannerInfo?.loading !== nextDeleteBannerInfo?.loading) {
      this.props.backDrop.setBackDrop({
        open: nextDeleteBannerInfo?.loading,
        message: "Deleting the banner...",
      });

      if (!nextDeleteBannerInfo?.loading && !nextDeleteBannerInfo?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Banner deleted successfully!",
          severity: alertProps.severity.success,
        });
        this.getAllPromotionalBanners();
      } else if (nextDeleteBannerInfo?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to delete the banner.",
          severity: alertProps.severity.error,
        });
      }
    }

    return true;
  }

  handleSort = (event, property) => {
    const { sortDirection, sortField } = this.state;
    const isAsc = sortField === property && sortDirection === "ASC";
    this.setState(
      {
        sortDirection: isAsc ? "DESC" : "ASC",
        sortField: property,
      },
      () => {
        this.getAllPromotionalBanners();
      }
    );
  };

  handleSearch = (searchQuery) => {
    this.setState({ search: searchQuery });
    this.getAllPromotionalBanners(searchQuery);
  };

  handleFilterClick = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <FilterBuilder
          components={filterComponent(this.state, this.props)}
          applyButtonLabel="Apply Filter"
          loading={false}
          onChange={this.handleFilterChange}
          onApplyButtonClicked={this.handleFilterApplyButtonClick}
          resetFilter={this.handleResetFilter}
          onCancelClick={this.handleFilterCancelClick}
          isCancelApplyButtonsNeeded={false}
          hideClear={true}
          customCTAComponent={<CustomFilterCTA />}
          saveButtonId={`saveFilterButtonFrom${
            this.state?.id ? this.state?.id : ""
          }`}
          cancelButtonId={`cancelFilterButtonFrom${
            this.state?.id ? this.state?.id : ""
          }`}
          clearAllButtonId={`clearAllFilterButtonFrom${
            this.state?.id ? this.state?.id : ""
          }`}
        />
      ),
    });
  };

  handleFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleFilterApplyButtonClick = (filterState) => {
    const filterApplied = () => {
      debugger;
      const appliedFilter = {
        status: filterState.status,
        createdBy: filterState.createdBy,
      };
      return isEqual(appliedFilter, initialFilterState) ? false : true;
    };
    this.setState(
      {
        status: filterState.status,
        createdBy: filterState.createdBy,
        isFilterApplied: filterApplied(filterState) ? true : false,
      },
      () => {
        this.getAllPromotionalBanners();
        this.handleFilterCancelClick();
      }
    );
  };

  handleResetFilter = () => {
    debugger;
    this.setState({
      ...initialFilterState,
      isFilterApplied: false,
    },
    () => {
      this.getAllPromotionalBanners();
      this.handleFilterCancelClick();
    });
   
  };

  handleFilterCancelClick = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  handleViewClick = (id = 0) => {
    this.props.history.push(`${routes.manage_promotional_banner}/view/${id}`);
  };

  handleEditClick = (id) => {
    this.props.history.push(`${routes.manage_promotional_banner}/edit/${id}`);
  };

  /**
   *
   * @returns bool
   */
  shouldNavigatePreviousPageOnEmpty = () => {
    const { data, totalCount } = this.props?.allPromotionalBanners;

    // Return if there are more than 1 items in the last page
    if (totalCount % this.pageSize !== 1) return false;

    if (data?.length > 1) return false;

    return true;
  };

  handleDeleteClick = (bannerId) => {
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: true,
      body: (
        <DeletePromotionalBannerDialog
          title="Confirmation"
          description={
            <>
              By clicking yes, this banner will be removed from active
              <br /> promotional banner carousel listing.
            </>
          }
          onDelete={() => {
            // If there is a single item in a page that is being deleted, then decrease page count to avoid showing "No Promotional banner text"
            const shouldIDecreasePageNumber =
              this.shouldNavigatePreviousPageOnEmpty();

            if (shouldIDecreasePageNumber) {
              this.setState((prevState) => ({
                page: prevState.page - 1,
              }));
            }

            this.props.DELETE_PROMOTIONAL_BANNER({ bannerId });
            this.props.dialog.setDialog({
              ...this.props.dialog,
              open: false,
              component: <></>,
            });
          }}
          onCancel={() => {
            this.props.dialog.setDialog({
              ...this.props.dialog,
              open: false,
              component: <></>,
            });
          }}
        />
      ),
      dontShowButtons: true,
      hideAllButtons: true,
      fitContentWidth: true,
      className: {
        Dialog: {
          borderRadius: "10px",
        },
        DialogContent: {
          padding: "0px",
        },
      },
    });
  };

  handlePageChange = (event, newPage) => {
    this.setState(
      {
        page: newPage,
      },
      () => {
        this.getAllPromotionalBanners();
      }
    );
  };

  render() {
    const {
      data: bannerRows,
      // loading,
      // error,
      totalCount,
    } = this.props?.allPromotionalBanners;

    return (
      <Box sx={{ p: 2 }}>
        {/* Subheader */}
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginBottom: "6px" }}>
            <SubHeader
              isbutton={
                !Boolean(
                  this.props?.auth_data?.data?.user_role ===
                    user_roles.service_coordinator ||
                    this.props?.auth_data?.data?.user_role ===
                      user_roles.help_desk
                )
              }
              placeholder={"Search for Banner Name"}
              countText={`Total Promotional Banners (${totalCount || 0})`}
              entityName={"Promotional Banner"}
              link={routes.add_promotional_banner}
              // handleSearch={() => {
              //   this.search();
              // }}
              handleChangeInputSearch={this.handleSearch}
              value={this.state.search}
              useNewFilterIcon
              placeSearchIconToLeft
              // hideFilter
              // hideSearch

              isFilterApplied={this.state.isFilterApplied}
              onFilterIconClicked={this.handleFilterClick}
              id={"AddPromotionalBanner"}
            />
          </Grid>
          {bannerRows.length > 0 ? (
            <>
              <Grid item xs={12}>
                <PromotionalBannerTable
                  rows={bannerRows}
                  onViewClick={this.handleViewClick}
                  onEditClick={this.handleEditClick}
                  onDeleteClick={this.handleDeleteClick}
                  onSort={this.handleSort}
                  order={this.state.sortDirection.toLowerCase()}
                  orderBy={this.state.sortField}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledPagination
                  count={Math.ceil(totalCount / this.pageSize)}
                  page={this.state.page}
                  onChange={this.handlePageChange}
                  color="primary"
                  showFirstButton
                  showLastButton
                  style={{ float: "right" }}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      {...createTestProps(item?.["aria-label"])}
                    />
                  )}
                  {...createTestProps(`promotional banner pagination`)}
                />
              </Grid>
            </>
          ) : (
            <NotFoundSection message={"No Promotional Banners added yet"} />
          )}
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  allPromotionalBanners: state?.promotionalBanner?.getAllBanner,
  deleteBannerInfo: state?.promotionalBanner?.deleteBanner,
});

export default connect(
  mapStateToProps,
  actions
)(withAlertAndDialog(withRouter(ListPromotionalBanner)));
