import React, { useState } from "react";
import ReactDOM from "react-dom";
import { DatePickers } from "../../../../../components/v2";
import { createTestProps } from "../../../../../utils/common";

export default (WrappedComponent) => {
  const WithCustomMapUI = ({ ...props }) => {
    const [fullScreen, setFullScreen] = useState(false);

    // Full Screen Handlers
    function initFullscreenControl(map) {
      const elementToSendFullscreen = map.getDiv().firstChild;
      const fullscreenControl = document.querySelector(".fullscreen-control");

      map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(
        fullscreenControl
      );
      fullscreenControl.onclick = function () {
        if (isFullscreen(elementToSendFullscreen)) {
          exitFullscreen();
        } else {
          requestFullscreen(elementToSendFullscreen);
        }
      };

      document.onwebkitfullscreenchange =
        document.onmsfullscreenchange =
        document.onmozfullscreenchange =
        document.onfullscreenchange =
          function () {
            if (isFullscreen(elementToSendFullscreen)) {
              fullscreenControl.classList.add("is-fullscreen");
            } else {
              fullscreenControl.classList.remove("is-fullscreen");
            }
          };
    }

    function isFullscreen(element) {
      return (
        (document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement) === element
      );
    }

    function requestFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
        setFullScreen(true);
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
        setFullScreen(true);
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
        setFullScreen(true);
      } else if (element.msRequestFullScreen) {
        element.msRequestFullScreen();
        setFullScreen(true);
      }
    }

    function exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setFullScreen(false);
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
        setFullScreen(false);
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
        setFullScreen(false);
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
        setFullScreen(false);
      }
    }

    // GPS & Zoom Handlers
    function initGpsZoomControl(map) {
      document.querySelector(".gps-control").onclick = function () {
        requestCurrentLocation(map);
      };

      document.querySelector(".zoom-control-in").onclick = function () {
        const zoomLevel = map.getZoom() + 1;
        map.setZoom(zoomLevel);
      };

      document.querySelector(".zoom-control-out").onclick = function () {
        map.setZoom(map.getZoom() - 1);
      };

      map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
        document.querySelector(".gps-zoom-control")
      );
    }

    function requestCurrentLocation(map) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            map.setCenter(pos);
          },
          () => {
            console.error(
              "Tanyacare does not have permission to use your location."
            );
          }
        );
      } else {
        // Browser doesn't support Geolocation
        console.error("Browser doesn't support Geolocation");
      }
    }

    function deleteDateControl() {
      const dateControl = document.querySelector(
        "#tracking-map-fullscreen-date-control"
      );
      if(dateControl) dateControl.remove();
    }

    // Date Control Handlers
    function initDateControl(map) {
      debugger;
      // const dateControl = document.querySelector(".date-control");

      if (fullScreen) {
        deleteDateControl()
        const dateControl = document.createElement("div");
        dateControl.classList.add("date-control");
        dateControl.setAttribute("id", "tracking-map-fullscreen-date-control");

        ReactDOM.render(
          <div
            style={{
              width: "164px",
              marginBottom: "24px",
              background: "white",
              borderRadius: 10,
              margin: "40px 0px 0px 40px",
              boxShadow: "0px 10px 20px #00000029",
            }}
          >
            <DatePickers
              value={props?.dateProps?.date}
              handleChange={props?.dateProps?.onDateChange}
              isRequired
              placeHolder="Select Date"
              disablePast={true}
              popperProps={{
                disablePortal: true,
              }}
              {...createTestProps(
                "real time location tracking date field full screen"
              )}
            />
          </div>,
          dateControl
        );

        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
          dateControl
        );
      } else {
        deleteDateControl()

        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(null);
      }
    }

    // Zoom to Fit Marker
    function zoomToFitMarker(map, location) {
      console.log("zoomToFitMarker", location);
      map.setOptions({
        zoom: 15,
        center: location,
      });
    }

    function createMapOptions(maps) {
      return {
        disableDefaultUI: true,
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
                // color: "#e6e4e0",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.highway",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      };
    }

    return (
      <WrappedComponent
        // Fullscreen Props
        initFullscreenControl={initFullscreenControl}
        initGpsZoomControl={initGpsZoomControl}
        fullScreen={fullScreen}
        initDateControl={initDateControl}
        zoomToFitMarker={zoomToFitMarker}
        createMapOptions={createMapOptions}
        {...props}
      />
    );
  };

  WithCustomMapUI.propTypes = {};

  return WithCustomMapUI;
};
