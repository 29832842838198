/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-02-18 12:49:31
 * @modify date 2022-02-18 12:49:31
 * @desc [description]
 */
import React, { forwardRef } from "react";
import TrackingMap from "./trackingMap";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { DatePickers } from "../../../../../components/v2";
import { createTestProps } from "../../../../../utils/common";
import styled from "styled-components";

const DateContainer = styled.div`
  width: 164px;
  margin-bottom: 24px;
`;

const MapContainer = styled.div`
  height: 627px;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(1.5),
    padding: "21px 16px 57px 16px",
  },
}));

function LocationTrackerUsingDate(
  { date, onDateChange, appointments, spEmittedData },
  ref
) {
  const classes = useStyles();
  return (
    <Paper ref={ref} elevation={0} className={classes.paper}>
      <DateContainer>
        <DatePickers
          value={date}
          handleChange={onDateChange}
          isRequired
          placeHolder="Select Date"
          disablePast={true}
          {...createTestProps("real time location tracking date field")}
        />
      </DateContainer>
      <MapContainer>
        <TrackingMap
          markers={Array.isArray(appointments) ? appointments : []}
          currentAppointmentData={spEmittedData}
          // currentAppointmentData={{
          //   latitude: 3.1615476,
          //   longitude: 101.696153,
          //   appointmentId: "f8e16cbc-a1f1-4913-a95c-20422482590d",
          // }}
          // Date Props
          dateProps={{
            date: date,
            onDateChange: onDateChange,
          }}
        />
      </MapContainer>
    </Paper>
  );
}

export default forwardRef(LocationTrackerUsingDate);
