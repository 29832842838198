import React from "react";
import {
  Button,
  Grid,
  // InputBase,
  // InputAdornment,
  Typography,
  FormControlLabel,
  Checkbox,
  Container,
  TextField,
  Hidden,
  // IconButton,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { routes } from "../../router/routes";
import { withStyles } from "@material-ui/core/styles";
// import { styles } from "./style";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { NavLink } from "react-router-dom";
// import Card from "@material-ui/core/Card";

// SubScreenComponents
import HeaderLogo from "../../components/login/headerLogo";
// import LeftPane from "../../components/login/LeftPane";
import LeftPane from "./leftPane";

import { actions, utils } from "tanyacare-middleware";
import { connect } from "react-redux";

// validation - utils

import { validateEmail } from "../../utils/validations";
// import { encrypt_local, decrypt_local } from "../../utils/index";

// LOGIN ICONS

// import { EmailSvg, KeySvg } from "../../assets";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { AuthContext } from "../../contexts";
import { access, loginSuccess } from "../../router/access";
import { withAlertAndDialog } from "../../HOCs";
import { getLoginTrialText, isTrialAccountType } from "../../utils/common";
import {
  storeTrialPopupState,
  // trialPopupStorageIdentifier,
} from "../../utils/constants";
import { user_roles } from "../../utils";
// import { CryptoJS } from 'crypto-js'

const styles = (theme) => ({
  wrapper: {
    height: "100vh",
    background: "white",
    overflowY: "hidden",
  },
  root: {
    height: "100%",
  },
  hero: {
    // height: "85%",
    height: "100%",
  },
  rightPaneWrapper: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "flex-start",
    justifyContent: "center",
    alignItems: "center",
  },
  rightPane: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginTop: 60,
    },
  },
  mainHeading: {
    [theme.breakpoints.only("xs")]: {
      marginTop: 4,
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: 32,
    },
    marginTop: 68,
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  subHeading: {
    fontSize: "1rem",
    paddingTop: 10,
    color: theme.palette.secondary.main,
  },
  emailField: {
    width: "100%",
    // marginTop: 64,
    marginTop: 32,
  },
  passwordField: {
    width: "100%",
    marginTop: 30,
  },
  errorMessage: {
    color: `${theme.palette.error.main}`,
  },
  signInButton: {
    // marginTop: 22,
    marginTop: 20,
    padding: 8,
    "&:hover": {
      transition: "0.5s",
      background: `${theme.palette.primary.main}`,
      opacity: 0.9,
    },
  },
  forgotPassword: {
    textAlign: "center",
    padding: 15,
  },
  link: {
    textDecoration: "none",
  },
  rememberMe: {
    marginTop: 6,
    textAlign: "right",
  },
});
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      passwordVisibility: false,
      isChecked: false,
      isError: {
        username: null,
        password: null,
        error: false,
      },
    };
  }

  initializePopupStorage = () => {
    // Set trial Popup local storage state
    isTrialAccountType() && storeTrialPopupState(true);
  };

  componentDidMount() {
    // console.log(this.props?.userprofile)

    this.initializePopupStorage();

    if (this.props?.loginState?.data?.user_role) {
      let _ = access(
        this.props?.loginState?.data?.user_role,
        this.props?.location?.state?.from?.pathname
      );
      if (this.props?.location?.state?.from?.pathname) {
        if (!this?.context?.auth?.refresh) {
          this.context.setAuth({ ...this.context.auth, refresh: true });
          this.props.history.push(
            loginSuccess(this.props?.loginState?.data?.user_role)
          );
        } else {
          if (_ >= 0) {
            isTrialAccountType() && storeTrialPopupState(true);
            this.props.history.push(this.props.location.state.from.pathname);
          } else {
            this.props.history.push(
              loginSuccess(this.props?.loginState?.data?.user_role)
            );
          }
        }
      } else {
        this.props.history.push(
          loginSuccess(this.props?.loginState?.data?.user_role)
        );
      }
    }

    this.checkisChecked();
    // console.log(this.context);
  }

  checkisChecked() {
    var isChecked = localStorage.getItem("tcc_ic");

    // console.log(CryptoJS.AES.decrypt(credentials.password, CryptoJS.enc.Hex.parse("36ebe205bcdfc499a25e6923f4450fa8"),
    // {
    //   iv: CryptoJS.dec.Hex.parse("be410fea41df7162a679875ec131cf2c"),
    //   mode: CryptoJS.mode.CBC,
    //   padding: CryptoJS.pad.Pkcs7,
    // }).toString())

    if (isChecked) {
      var credentials = JSON.parse(
        utils.decrypt_local(localStorage.getItem("tcc"))
      );
      // console.log(JSON.parse(utils.decrypt_local(localStorage.getItem("tcc"))))
      this.setState({
        username: credentials.username,
        password: credentials.password,
        isChecked: true,
      });
    }
  }

  executeTrialPopup = ({ expiryDate }) => {
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: true,
      title: (
        <Typography variant="h6" align="center">
          {getLoginTrialText(expiryDate)}
        </Typography>
      ),
      body: (handleClose) => (
        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          <Typography variant="body2" color="textSecondary" align="center">
            Please contact our tanya.care support at 1300-13-2511 <br />
            or email reach.us@tanya.care for further assistance
          </Typography>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                storeTrialPopupState(false);
                handleClose();
              }}
            >
              Got it
            </Button>
          </div>
        </div>
      ),
      closeCallbackNeeded: true,
      dontShowButtons: true,
      hideAllButtons: true,
      fitContentWidth: true,
      // style: {
      //   Dialog: {
      //     "&.MuiDialog-paper": {
      //       minWidth: "unset !important",
      //     },
      //   },
      // },
      // onOk: () => {},
      // onCancel: () => {},
    });
  };

  shouldComponentUpdate(props, state) {
    if (!props?.loginState?.error && !props?.loginState?.loading) {
      if (props?.loginState?.data?.user_role) {
        // Show Trial Account popup on login (trial account & non-super-admin users only)
        isTrialAccountType() &&
          props?.loginState?.data?.user_role !== user_roles.super_admin &&
          this.executeTrialPopup({expiryDate: props?.loginState?.data?.resourceEndsOn});
        if (props?.location?.state?.from?.pathname) {
          props.history.push(props.location.state.from.pathname);
        } else {
          props.history.push(loginSuccess(props?.loginState?.data?.user_role));
        }
      }
    }
    return true;
  }

  /* Here you can pass your data towards reactive core */

  handleRememberPassword = () => {
    const { isChecked } = this.state;
    this.setState({ isChecked: !isChecked }, () => {
      if (this.state.isChecked) {
        console.log("INSIDE");
        localStorage.setItem("tcc_ic", true);
        localStorage.setItem(
          "tcc",
          utils.encrypt_local(
            JSON.stringify({
              username: this.state.username,
              password: this.state.password,
            })
          )
        );
      } else {
        localStorage.setItem("tcc_ic", false);
      }
    });
    // console.log(this.state.username)
    // console.log(this.state.password)
    // if(!isChecked)
    // {
    //   console.log("INSIDE")
    //   localStorage.setItem("isChecked", true);
    //   localStorage.setItem("tcc" , encrypt_local(JSON.stringify({
    //     username : this.state.username,
    //     password: this.state.password
    //   })))
    // }
    // else{
    //   localStorage.setItem("isChecked", false);
    //   localStorage.setItem("tcc" , encrypt_local(JSON.stringify({
    //     username : "",
    //     password : ""
    //   })))
    // }
  };

  handleValidation = (validation, value) => {
    if (value.length === 0) {
      return false;
    } else {
      if (validation === "username") {
        if (validateEmail(value)) {
          return true;
        } else return false;
      } else {
        return true;
      }
    }
  };

  /* Here you can pass your data towards reactive core */

  handleLogin = () => {
    const { username, password, isError } = this.state;

    if (
      this.handleValidation("username", username) &&
      this.handleValidation("password", password)
    ) {
      const _piload = {
        email: username,
        password: password,
        device: "web",
      };
      this.props.LOGIN_AUTH(_piload);
    } else {
      isError.username = false;
      isError.password = false;
      this.setState({ ...this.state, isError });
    }
  };

  handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      document.getElementById("submitButton").click();
    }
  };

  handleChange = (e, currentstate) => {
    const state = this.state;
    state.isError[currentstate] = this.handleValidation(
      currentstate,
      e.target.value
    );
    state[currentstate] = e.target.value;
    this.setState(state);
  };

  emailEndAdornmentHandler = () => {
    const usernameError = this.state.isError.username;
    if (usernameError !== null) {
      return usernameError ? (
        <DoneIcon htmlColor="rgb(90, 192, 172)" />
      ) : (
        <CloseIcon htmlColor="rgba(201, 87, 114, 1)" />
      );
    }
  };

  passwordVisibilityHandler = () => {
    this.setState(
      (prevState) => {
        return {
          passwordVisibility: !prevState.passwordVisibility,
        };
      },
      () => {
        console.log(this.state.passwordVisibility);
      }
    );
  };

  render() {
    const { username, password, passwordVisibility } = this.state;
    const { classes } = this.props;

    const { loading, error, message } = this.props?.loginState;

    return (
      <Container maxWidth="lg" className={classes.wrapper}>
        <div className={classes.root}>
          {/* <Hidden xsDown>
            <HeaderLogo />
          </Hidden> */}
          <Grid container alignItems={"stretch"} className={classes.hero}>
            <Hidden smDown>
              {/* Left pane illustration */}
              <LeftPane />
            </Hidden>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.rightPaneWrapper}
            >
              <Grid container item xs={12} sm={12} md={10}>
                <div className={classes.rightPane}>
                  <Hidden mdUp>
                    <HeaderLogo />
                  </Hidden>
                  <Typography
                    variant="h2"
                    component="h2"
                    align={"center"}
                    className={classes.mainHeading}
                  >
                    Welcome
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    align={"center"}
                    className={classes.subHeading}
                  >
                    Enter your credentials to proceed
                  </Typography>

                  {/* Email Field */}
                  {/**className={classes.formInputs} */}
                  {/* <Text */}
                  <TextField
                    variant="outlined"
                    label="Email Address"
                    id="LoginEmailAddress"
                    autoComplete="off"
                    value={username}
                    onChange={(e) => this.handleChange(e, "username")}
                    onKeyUp={this.handleKeyPress}
                    autoFocus
                    InputProps={{
                      endAdornment: this.emailEndAdornmentHandler(),
                    }}
                    className={classes.emailField}
                  />
                  {/* Password Field */}
                  <TextField
                    variant="outlined"
                    label="Password"
                    id="LoginPassword"
                    autoComplete="off"
                    type={passwordVisibility ? "text" : "password"}
                    value={password}
                    onChange={(e) => this.handleChange(e, "password")}
                    onKeyUp={this.handleKeyPress}
                    // autoFocus
                    className={classes.passwordField}
                    InputProps={{
                      endAdornment: (
                        <>
                          {/* <IconButton onClick={this.passwordVisibilityHandler}> */}
                          {passwordVisibility ? (
                            <VisibilityOffIcon
                              htmlColor={"#7F7F7F"}
                              id="VisibilityOffButton"
                              onClick={this.passwordVisibilityHandler}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <VisibilityIcon
                              htmlColor={"#7F7F7F"}
                              id="VisibilityOnButton"
                              onClick={this.passwordVisibilityHandler}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                          {/* </IconButton> */}
                        </>
                      ),
                    }}
                  />
                  {/* Common Error message */}

                  <Grid container item xs={12} className={classes.userHelpGrid}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      // className={classes.forgotPassword}
                    >
                      {error && (
                        <Typography
                          align={"left"}
                          className={classes.errorMessage}
                          style={{ marginTop: 20 }}
                        >
                          {message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      justify="flex-end"
                      className={classes.rememberMe}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.isChecked}
                            id="RememberMe"
                            onChange={this.handleRememberPassword}
                            name="Remember Me"
                            color="secondary"
                          />
                        }
                        label="Remember Me"
                        style={{ fontSize: "0.80rem !important" }}
                      />
                    </Grid>
                  </Grid>

                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    id="submitButton"
                    // color="primary"
                    disabled={loading}
                    className={classes.signInButton}
                    onClick={this.handleLogin}
                    fullWidth
                    style={{}}
                  >
                    {loading ? "Loading..." : "Sign In"}
                  </Button>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.forgotPassword}
                  >
                    <NavLink
                      to={routes.forgotPassword}
                      className={classes.link}
                    >
                      <Typography
                        variant="body1"
                        component="div"
                        color={"primary"}
                      >
                        Forgot Password?
                      </Typography>
                    </NavLink>
                  </Grid>
                </div>

                {/* <Grid container item xs={12} sm={12} md={10} lg={10} xl={10}>
                    Email and Password
                    <Grid container item xs={12} spacing={2}>
                      {" "}
                      *className={classes.form}
                    </Grid>
                  </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ height: "5%" }}></Grid>
        </div>
      </Container>
    );
  }
}

Login.contextType = AuthContext;

const mapStateToProps = (state) => ({
  loginState: state?.authState?.loginAuth,
  // userprofile: state?.custom_widget_dashboard?.userprofile,
});

export default connect(
  mapStateToProps,
  actions
)(
  withStyles(styles, { withTheme: true })(withRouter(withAlertAndDialog(Login)))
);
