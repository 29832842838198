import React from "react";
import { Grid, Avatar, Typography, Button, IconButton } from "@material-ui/core";
import { StarRating } from "../../assets/ClientDetailScreenIcons";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { RecommendedCardsLoaders } from "./recommendCardLoaders";
import { RecommendedCardErrors } from "./recommendCardsErrors";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "8px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04)",
    border: `solid 0.3px #f4f4f5;`,
    backgroundColor: "#ffffff",
    width: 200,
    height: 250,
    margin: "0px 10px",
  },
  fontFamilyCard: {
    fontWeight: "bold",
  },
  availability: {
    borderRadius: "4px",
    backgroundColor: "rgba(242, 125, 53, 0.08)",
    padding: "5px 10px",
    margin: "auto",
    color: theme.palette.ternary.main,
  },
  borderColorActive: {
    border: `1.6px solid ${theme.palette.ternary.main}`,
  },
  borderColor: {
    border: `1.6px solid ${theme.palette.secondary.light}`,
  },
  label: {
    marginBottom: 15,
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
}));
export const Recommended = (props) => {

  const [state, setState] = React.useState({ start: 0, end: 4 })

  const classes = useStyles();

  const prevBtnClicked = () => {
    let { start, end } = state;
    end = start;
    start = state - 4;
    setState({
      ...state,start,end
    })

  }

  const nextBtnClicked = () => {
    let { start, end } = state;
    start = end;
    end = end + 4;
    setState({
      ...state,start,end
    })
  }
  return (
    <>
      <Typography variant="body1" component="div" className={classes.label}>
        Recommended Caregivers
      </Typography>
      <div style={{ display: 'flex' }}>

        <div style={{ paddingTop: 96 }}>
          <IconButton onClick={prevBtnClicked}>
            <ChevronLeft />
          </IconButton>
        </div>

        {!props.loading && !props.error && <Grid container spacing={2}>
          {props?.data?.slice(state.start, state.end)?.map((val, item) => {
            return (
              <Grid item className={classes.card}>
                {props.is_known_to_client && (
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Known to Client
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: props.label ? 0 : 20,
                  }}
                >
                  <Avatar src={val.profile_pic} alt="Placeholder" />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px 0px 10px",
                  }}
                >
                  <Typography
                    variant="body1"
                    component="div"
                    className={classes.fontFamilyCard}
                  >
                    {val?.label}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography
                    variant="body1"
                    component="div"
                    className={classes.fontFamilyCard}
                  >
                    <StarRating /> {val?.rating ? val.rating : '-'}/5
                </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px 0px",
                  }}
                >
                  <Typography
                    variant="body1"
                    component="div"
                    className={`${classes.availability}`}
                  >
                    {val?.availability ? val?.availability : '-'}% Availability
                </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px 0px 10px",
                  }}
                >
                  <Button
                    variant="outlined"
                    className={`${classes.fontFamilyCard} ${val.value !== props.value
                      ? classes.borderColor
                      : classes.borderColorActive
                      }`}
                    style={{ minWidth: 170 }}
                    onClick={() => props.onClick(val.value !== props.value ? val : {})}
                  >
                    {val.value !== props.value ? "SELECT" : "UNSELECT"}
                  </Button>
                </Grid>
              </Grid>
            );
          })}
        </Grid>}

        <div style={{ paddingTop: 96 }}>
          <IconButton onClick={nextBtnClicked}>
            <ChevronRight />
          </IconButton>
        </div>
      </div>
      {props.loading && <RecommendedCardsLoaders count={4} />}
      {props.error && <RecommendedCardErrors count={4} />}
    </>
  );
};

// PROPTYPES

Recommended.propTypes = {
  profile_pic: PropTypes.string,
  name: PropTypes.string,
  rating: PropTypes.string,
  availability: PropTypes.number,
  isSelect: PropTypes.bool,
};
