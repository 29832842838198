import React from 'react'
import { Grid, Hidden, makeStyles } from '@material-ui/core'
import { LoginIllustrator } from '../../assets'

const useStyles = makeStyles((theme) => ({
  illustratorGrid: {
    borderRight: `1px solid ${theme.palette.secondary.light}`,
  },
  illustrator: {
    textAlign: "center",
  },
  illustratorImage: {
    width: "55%",
  },
}))

export default function LeftPane() {
  const classes = useStyles()
  return (
    <>
      <Grid item xs={6} >
        {/* className={classes.illustratorGrid} */}
        <Hidden smDown>
          <Grid item xs={12} >
            {/* className={classes.illustrator} */}
            <LoginIllustrator
              alt="managed care"
              className={classes.illustratorImage}
            />
            {/* <img
            src="/images/tanyaCare/login_page_logo/tanyaCareLogo-light.png"
            alt="Tanya.Care"
          /> */}
          {/* className={classes.illustratorImage} */}
          </Grid>
        </Hidden>
      </Grid>
    </>
  )
}
