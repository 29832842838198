import { filterType } from "../../../utils"

export const filterComponent = (state, props) => {
  let id= state?.id
    return {
        section_1: {
            label: "Filters",
            fields: {
              role: {
                key: "role",
                id:`selectRoleFilterFrom${id ? id : ""}`,
                type: filterType.ROLE,
                breakPoints: { 
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12,
                },
                label: "BY ROLE",
                value: state.role,
                isMulti: true
              },
              // sort: {
              //   key: "sortDirec",
              //   type: filterType.SORTDIRECTION,
              //   breakPoints: {
              //     xs: 12,
              //     sm: 12,
              //     md: 12,
              //     lg: 12,
              //     xl: 12,
              //   },
              //   label: "SORT BY",
              //   value: state.sortDirec,
              //   isMandatory: true,
              // },
            }
          }
    }
}