import { alertProps } from "../../../utils";

/**
 * Check state updates and re-renders react app.
 * @param  {Object}   oldData               Previous Entity data ({data, loading, error}).
 * @param  {Object}   newData               Updated Entity data ({data, loading, error}).
 * @param  {Object}   props                 Extra props for necessaries.
 * @param  {Object}   snackMessage          Snack Message to set.
 * @param  {function} reload                Reloads the page.
 * @param  {function} cleanupStates         Add Field State cleanup.
 * @param  {function} onCancelButtonClicked Used to close edit drawer.
 */
const checkShouldUpdate = (
  oldData,
  newData,
  props,
  snackMessage,
  reload,
  cleanupStates,
  onCancelButtonClicked
) => {
  //Checking for add entity
  if (oldData?.loading !== newData?.loading) {
    if (!newData?.loading && !newData?.error) {
      props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: snackMessage.success,
        severity: alertProps.severity.success,
      });
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: false,
      });
      // Call the get data here again
      //eg: this.getAllDashboardData()
      reload();
      // onCancelButtonClicked - is used only in updating
      onCancelButtonClicked && onCancelButtonClicked();
      cleanupStates();
    } else if (newData.error) {
      props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: newData.message ? newData.message : snackMessage.error,
        severity: alertProps.severity.error,
      });
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: false,
      });
    }
  }
};

// snack Message
const setAddSnackMessage = (success, error) => {
  return {
    success: `${success} Added Successfully!`,
    error: `Unable to add ${error}. Please try again.`,
  };
};
const setDeleteSnackMessage = (success, error) => {
  return {
    success: `${success} Deleted Successfully!`,
    error: `Unable to delete ${error}. Please try again.`,
  };
};
const setUpdateSnackMessage = (success, error) => {
  return {
    success: `${success} Updated Successfully!`,
    error: `Unable to update ${error}. Please try again.`,
  };
};

//<--------------------------------------- Add ----------------------------------->
// Add
export const addShouldComponentUpdate = (
  oldProps,
  newProps,
  getEssentials,
  cleanupStates
) => {
  return {
    // Skills
    1: () => {
      const { addSkill: newAddSkill } = newProps;
      const oldAddSkill = oldProps.addSkill;
      const snackMessage = setAddSnackMessage("Skill", "Skill");
      checkShouldUpdate(
        oldAddSkill,
        newAddSkill,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Certificate Organization / Issuing Authority
    2: () => {
      const { addCertOrg: newAddCertOrg } = newProps;
      const oldAddCertOrg = oldProps.addCertOrg;
      const snackMessage = setAddSnackMessage(
        "Issuing Authority",
        "Issuing Authority"
      );
      checkShouldUpdate(
        oldAddCertOrg,
        newAddCertOrg,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Exp Organization
    3: () => {
      const { addExpOrg: newAddExpOrg } = newProps;
      const oldAddExpOrg = oldProps.addExpOrg;
      const snackMessage = setAddSnackMessage("Employer", "Employer");
      checkShouldUpdate(
        oldAddExpOrg,
        newAddExpOrg,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Category
    4: () => {
      const { addCategory: newAddCategory } = newProps;
      const oldAddCategory = oldProps.addCategory;
      const snackMessage = setAddSnackMessage("Category", "Category");
      checkShouldUpdate(
        oldAddCategory,
        newAddCategory,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Certificate
    5: () => {
      const { addCertificate: newAddCertificate } = newProps;
      const oldAddCertificate = oldProps.addCertificate;
      const snackMessage = setAddSnackMessage("Certificate", "Certificate");
      checkShouldUpdate(
        oldAddCertificate,
        newAddCertificate,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Experience Roles
    6: () => {
      const { addExpRole: newAddExpRole } = newProps;
      const oldAddExpRole = oldProps.addExpRole;
      const snackMessage = setAddSnackMessage("Job Role", "Job Role");
      checkShouldUpdate(
        oldAddExpRole,
        newAddExpRole,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Specialization
    7: () => {
      const { addSpecialization: newAddSpecialization } = newProps;
      const oldAddSpecialization = oldProps.addSpecialization;
      const snackMessage = setAddSnackMessage(
        "Specialization",
        "Specialization"
      );
      checkShouldUpdate(
        oldAddSpecialization,
        newAddSpecialization,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Language
    8: () => {
      const { addLanguage: newAddLanguage } = newProps;
      const oldAddLanguage = oldProps.addLanguage;
      const snackMessage = setAddSnackMessage("Language", "Language");
      checkShouldUpdate(
        oldAddLanguage,
        newAddLanguage,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    // Frequency
    // 9: () => {
    //   const { addFrequency: newAddFrequency } = newProps;
    //   const oldAddFrequency = oldProps.addFrequency;
    //   const snackMessage = setAddSnackMessage("Frequency", "Frequency");
    //   checkShouldUpdate(
    //     oldAddFrequency,
    //     newAddFrequency,
    //     oldProps,
    //     snackMessage,
    //     getEssentials,
    //     cleanupStates
    //   );
    // },
    // Salutation
    9: () => {
      const { addSalutation: newAddSalutation } = newProps;
      const oldAddSalutation = oldProps.addSalutation;
      const snackMessage = setAddSnackMessage("Salutation", "Salutation");
      checkShouldUpdate(
        oldAddSalutation,
        newAddSalutation,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    // License
    10: () => {
      const { addLicense: newAddLicense } = newProps;
      const oldAddLicense = oldProps.addLicense;
      const snackMessage = setAddSnackMessage("License", "License");
      checkShouldUpdate(
        oldAddLicense,
        newAddLicense,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    // Validity
    // 12: () => {
    //   const { addValidity: newAddValidity } = newProps;
    //   const oldAddValidity = oldProps.addValidity;
    //   const snackMessage = setAddSnackMessage("Validity", "Validity");
    //   checkShouldUpdate(
    //     oldAddValidity,
    //     newAddValidity,
    //     oldProps,
    //     snackMessage,
    //     getEssentials,
    //     cleanupStates
    //   );
    // },
    // Address
    11: () => {
      const { addAddress: newAdd } = newProps;
      const oldAdd = oldProps.addAddress;
      const snackMessage = setAddSnackMessage("Address Type", "Address Type");
      checkShouldUpdate(
        oldAdd,
        newAdd,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    // Email
    12: () => {
      const { addEmail: newAdd } = newProps;
      const oldAdd = oldProps.addEmail;
      const snackMessage = setAddSnackMessage("Email Type", "Email Type");
      checkShouldUpdate(
        oldAdd,
        newAdd,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    // Phone
    13: () => {
      const { addPhone: newAdd } = newProps;
      const oldAdd = oldProps.addPhone;
      const snackMessage = setAddSnackMessage("Phone Type", "Phone Type");
      checkShouldUpdate(
        oldAdd,
        newAdd,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Id types
    14: () => {
      const { addIdType: newAdd } = newProps;
      const oldAdd = oldProps.addIdType;
      const snackMessage = setAddSnackMessage("ID Type", "ID Type");
      checkShouldUpdate(
        oldAdd,
        newAdd,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    15: () => {
      const { add_master_enquiry_source_of_referral: newDelete } = newProps;
      const oldDelete = oldProps.add_master_enquiry_source_of_referral;
      const snackMessage = setAddSnackMessage("Source of Referral", "Source of Referral");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

   /*  18: () => {
      const { add_enquiry_status: newDelete } = newProps;
      const oldDelete = oldProps.add_enquiry_status;
      const snackMessage = setAddSnackMessage("Enquiry Status", "Enquiry Status");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
 */
    16: () => {
      const { add_lead_types: newDelete } = newProps;
      const oldDelete = oldProps.add_lead_types;
      const snackMessage = setAddSnackMessage("Enquiry Lead Types", "Enquiry Lead Types");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    17: () => {
      const { add_product_management_group_types: newDelete } = newProps;
      const oldDelete = oldProps.add_product_management_group_types;
      const snackMessage = setAddSnackMessage("Product Group Type", "Product Group Type");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    18: () => {
      const { add_leave_types: newDelete } = newProps;
      const oldDelete = oldProps.add_leave_types;
      const snackMessage = setAddSnackMessage("Leave Type", "Leave Type");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    19: () => {
      const { add_tax: newDelete } = newProps;
      const oldDelete = oldProps.add_tax;
      const snackMessage = setAddSnackMessage("Tax", "Tax");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    // 21: () => {
    //   const { add_unavailable_types: newDelete } = newProps;
    //   const oldDelete = oldProps.add_unavailable_types;
    //   const snackMessage = setAddSnackMessage("Unavailable Type", "Unavailable Type");
    //   checkShouldUpdate(
    //     oldDelete,
    //     newDelete,
    //     oldProps,
    //     snackMessage,
    //     getEssentials,
    //     cleanupStates
    //   );
    // },


    
  };
};

//<--------------------------------------- Delete ----------------------------------->
// Delete
export const deleteShouldComponentUpdate = (
  oldProps,
  newProps,
  getEssentials,
  cleanupStates
) => {
  return {
    // Skills
    1: () => {
      const { deleteSkill: newDeleteSkill } = newProps;
      const oldDeleteSkill = oldProps.deleteSkill;
      const snackMessage = setDeleteSnackMessage("Skill", "Skill");
      checkShouldUpdate(
        oldDeleteSkill,
        newDeleteSkill,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Certificate Organization / Issuing Authority
    2: () => {
      const { deleteCertOrg: newDeleteCertOrg } = newProps;
      const oldDeleteCertOrg = oldProps.deleteCertOrg;
      const snackMessage = setDeleteSnackMessage(
        "Issuing Authority",
        "Issuing Authority"
      );
      checkShouldUpdate(
        oldDeleteCertOrg,
        newDeleteCertOrg,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Exp Organization
    3: () => {
      const { deleteExpOrg: newDeleteExpOrg } = newProps;
      const oldDeleteExpOrg = oldProps.deleteExpOrg;
      const snackMessage = setDeleteSnackMessage("Employer", "Employer");
      checkShouldUpdate(
        oldDeleteExpOrg,
        newDeleteExpOrg,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Category
    4: () => {
      const { deleteCategory: newDeleteCategory } = newProps;
      const oldDeleteCategory = oldProps.deleteCategory;
      const snackMessage = setDeleteSnackMessage("Category", "Category");
      checkShouldUpdate(
        oldDeleteCategory,
        newDeleteCategory,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Certificate
    5: () => {
      const { deleteCertificate: newDeleteCertificate } = newProps;
      const oldDeleteCertificate = oldProps.deleteCertificate;
      const snackMessage = setDeleteSnackMessage("Certificate", "Certificate");
      checkShouldUpdate(
        oldDeleteCertificate,
        newDeleteCertificate,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Experience Roles
    6: () => {
      const { deleteExpRole: newDeleteExpRole } = newProps;
      const oldDeleteExpRole = oldProps.deleteExpRole;
      const snackMessage = setDeleteSnackMessage("Job Role", "Job Role");
      checkShouldUpdate(
        oldDeleteExpRole,
        newDeleteExpRole,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Specialization
    7: () => {
      const { deleteSpecialization: newDeleteSpecialization } = newProps;
      const oldDeleteSpecialization = oldProps.deleteSpecialization;
      const snackMessage = setDeleteSnackMessage(
        "Specialization",
        "Specialization"
      );
      checkShouldUpdate(
        oldDeleteSpecialization,
        newDeleteSpecialization,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Language
    8: () => {
      const { deleteLanguage: newDeleteLanguage } = newProps;
      const oldDeleteLanguage = oldProps.deleteLanguage;
      const snackMessage = setDeleteSnackMessage("Language", "Language");
      checkShouldUpdate(
        oldDeleteLanguage,
        newDeleteLanguage,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    // Frequency
    // 9: () => {
    //   const { deleteFrequency: newDeleteFrequency } = newProps;
    //   const oldDeleteFrequency = oldProps.deleteFrequency;
    //   const snackMessage = setDeleteSnackMessage("Frequency", "Frequency");
    //   checkShouldUpdate(
    //     oldDeleteFrequency,
    //     newDeleteFrequency,
    //     oldProps,
    //     snackMessage,
    //     getEssentials,
    //     cleanupStates
    //   );
    // },
    // Salutation
    9: () => {
      const { deleteSalutation: newDeleteSalutation } = newProps;
      const oldDeleteSalutation = oldProps.deleteSalutation;
      const snackMessage = setDeleteSnackMessage("Salutation", "Salutation");
      checkShouldUpdate(
        oldDeleteSalutation,
        newDeleteSalutation,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    // License
    10: () => {
      const { deleteLicense: newDeleteLicense } = newProps;
      const oldDeleteLicense = oldProps.deleteLicense;
      const snackMessage = setDeleteSnackMessage("License", "License");
      checkShouldUpdate(
        oldDeleteLicense,
        newDeleteLicense,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    // Validity
    // 12: () => {
    //   const { deleteValidity: newDeleteValidity } = newProps;
    //   const oldDeleteValidity = oldProps.deleteValidity;
    //   const snackMessage = setDeleteSnackMessage("Validity", "Validity");
    //   checkShouldUpdate(
    //     oldDeleteValidity,
    //     newDeleteValidity,
    //     oldProps,
    //     snackMessage,
    //     getEssentials,
    //     cleanupStates
    //   );
    // },
    // Address
    11: () => {
      const { deleteAddress: newDelete } = newProps;
      const oldDelete = oldProps.deleteAddress;
      const snackMessage = setDeleteSnackMessage("Address Type", "Address Type");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    // Email
    12: () => {
      const { deleteEmail: newDelete } = newProps;
      const oldDelete = oldProps.deleteEmail;
      const snackMessage = setDeleteSnackMessage("Email Type", "Email Type");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    // Phone
    13: () => {
      const { deletePhone: newDelete } = newProps;
      const oldDelete = oldProps.deletePhone;
      const snackMessage = setDeleteSnackMessage("Phone Type", "Phone Type");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    // Id Type
    14: () => {
      const { deleteIdType: newDelete } = newProps;
      const oldDelete = oldProps.deleteIdType;
      const snackMessage = setDeleteSnackMessage("ID Type", "ID Type");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    15: () => {
      const { delete_master_enquiry_source_of_referral: newDelete } = newProps;
      const oldDelete = oldProps.delete_master_enquiry_source_of_referral;
      const snackMessage = setDeleteSnackMessage("Source of Referrel", "Source of Referrel");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

   /*  18: () => {
      const { delete_enquiry_status: newDelete } = newProps;
      const oldDelete = oldProps.delete_enquiry_status;
      const snackMessage = setDeleteSnackMessage("Enquiry Status", "Enquiry Status");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    }, */

    16: () => {
      const { delete_lead_types: newDelete } = newProps;
      const oldDelete = oldProps.delete_lead_types;
      const snackMessage = setDeleteSnackMessage("Enquiry Lead Types", "Enquiry Lead Types");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    17: () => {
      const { delete_product_management_group_types: newDelete } = newProps;
      const oldDelete = oldProps.delete_product_management_group_types;
      const snackMessage = setDeleteSnackMessage("Product Group Type", "Product Group Type");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    18: () => {
      const { delete_leave_types: newDelete } = newProps;
      const oldDelete = oldProps.delete_leave_types;
      const snackMessage = setDeleteSnackMessage("Leave Type", "Leave Type");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    19: () => {
      const { delete_tax: newDelete } = newProps;
      const oldDelete = oldProps.delete_tax;
      const snackMessage = setDeleteSnackMessage("Tax", "Tax");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    // 21: () => {
    //   const { delete_unavailable_types: newDelete } = newProps;
    //   const oldDelete = oldProps.delete_unavailable_types;
    //   const snackMessage = setDeleteSnackMessage("Unavailable Type", "Unavailable Type");
    //   checkShouldUpdate(
    //     oldDelete,
    //     newDelete,
    //     oldProps,
    //     snackMessage,
    //     getEssentials,
    //     cleanupStates
    //   );
    // },



  };
};

//<--------------------------------------- Update/Edit ----------------------------------->
// Update/Edit
export const updateShouldComponentUpdate = (
  oldProps,
  newProps,
  getEssentials,
  cleanupStates,
  onCancelButtonClicked
) => {
  return {
    // Skills
    1: () => {
      const { updateSkill: newUpdateSkill } = newProps;
      const oldUpdateSkill = oldProps.updateSkill;
      const snackMessage = setUpdateSnackMessage("Skill", "Skill");
      checkShouldUpdate(
        oldUpdateSkill,
        newUpdateSkill,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates,
        onCancelButtonClicked
      );
    },

    // Certificate Organization / Issuing Authority
    2: () => {
      const { updateCertOrg: newUpdateCertOrg } = newProps;
      const oldUpdateCertOrg = oldProps.updateCertOrg;
      const snackMessage = setUpdateSnackMessage(
        "Issuing Authority",
        "Issuing Authority"
      );
      checkShouldUpdate(
        oldUpdateCertOrg,
        newUpdateCertOrg,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates,
        onCancelButtonClicked
      );
    },

    // Exp Organization
    3: () => {
      const { updateExpOrg: newUpdateExpOrg } = newProps;
      const oldUpdateExpOrg = oldProps.updateExpOrg;
      const snackMessage = setUpdateSnackMessage("Employer", "Employer");
      checkShouldUpdate(
        oldUpdateExpOrg,
        newUpdateExpOrg,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates,
        onCancelButtonClicked
      );
    },

    // Category
    4: () => {
      const { updateCategory: newUpdateCategory } = newProps;
      const oldUpdateCategory = oldProps.updateCategory;
      const snackMessage = setUpdateSnackMessage("Category", "Category");
      checkShouldUpdate(
        oldUpdateCategory,
        newUpdateCategory,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates,
        onCancelButtonClicked
      );
    },

    // Certificate
    5: () => {
      const { updateCertificate: newUpdateCertificate } = newProps;
      const oldUpdateCertificate = oldProps.updateCertificate;
      const snackMessage = setUpdateSnackMessage("Certificate", "Certificate");
      checkShouldUpdate(
        oldUpdateCertificate,
        newUpdateCertificate,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates,
        onCancelButtonClicked
      );
    },

    // Experience Roles
    6: () => {
      const { updateExpRole: newUpdateExpRole } = newProps;
      const oldUpdateExpRole = oldProps.updateExpRole;
      const snackMessage = setUpdateSnackMessage("Job Role", "Job Role");
      checkShouldUpdate(
        oldUpdateExpRole,
        newUpdateExpRole,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates,
        onCancelButtonClicked
      );
    },

    // Specialization
    7: () => {
      const { updateSpecialization: newUpdateSpecialization } = newProps;
      const oldUpdateSpecialization = oldProps.updateSpecialization;
      const snackMessage = setUpdateSnackMessage(
        "Specialization",
        "Specialization"
      );
      checkShouldUpdate(
        oldUpdateSpecialization,
        newUpdateSpecialization,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates,
        onCancelButtonClicked
      );
    },

    // Language
    8: () => {
      const { updateLanguage: newUpdateLanguage } = newProps;
      const oldUpdateLanguage = oldProps.updateLanguage;
      const snackMessage = setUpdateSnackMessage("Language", "Language");
      checkShouldUpdate(
        oldUpdateLanguage,
        newUpdateLanguage,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates,
        onCancelButtonClicked
      );
    },
    // Frequency
    // 9: () => {
    //   const { updateFrequency: newUpdateFrequency } = newProps;
    //   const oldUpdateFrequency = oldProps.updateFrequency;
    //   const snackMessage = setUpdateSnackMessage("Frequency", "Frequency");
    //   checkShouldUpdate(
    //     oldUpdateFrequency,
    //     newUpdateFrequency,
    //     oldProps,
    //     snackMessage,
    //     getEssentials,
    //     cleanupStates,
    //     onCancelButtonClicked
    //   );
    // },
    // Salutation
    9: () => {
      const { updateSalutation: newUpdateSalutation } = newProps;
      const oldUpdateSalutation = oldProps.updateSalutation;
      const snackMessage = setUpdateSnackMessage("Salutation", "Salutation");
      checkShouldUpdate(
        oldUpdateSalutation,
        newUpdateSalutation,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates,
        onCancelButtonClicked
      );
    },
    // License
    10: () => {
      const { updateLicense: newUpdateLicense } = newProps;
      const oldUpdateLicense = oldProps.updateLicense;
      const snackMessage = setUpdateSnackMessage("License", "License");
      checkShouldUpdate(
        oldUpdateLicense,
        newUpdateLicense,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates,
        onCancelButtonClicked
      );
    },
    // Validity
    // 12: () => {
    //   const { updateValidity: newUpdateValidity } = newProps;
    //   const oldUpdateValidity = oldProps.updateValidity;
    //   const snackMessage = setUpdateSnackMessage("Validity", "Validity");
    //   checkShouldUpdate(
    //     oldUpdateValidity,
    //     newUpdateValidity,
    //     oldProps,
    //     snackMessage,
    //     getEssentials,
    //     cleanupStates,
    //     onCancelButtonClicked
    //   );
    // },
    // Address
    11: () => {
      const { updateAddress: newUpdate } = newProps;
      const oldUpdate = oldProps.updateAddress;
      const snackMessage = setUpdateSnackMessage("Address Type", "Address Type");
      checkShouldUpdate(
        oldUpdate,
        newUpdate,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates,
        onCancelButtonClicked
      );
    },
    // Email
    12: () => {
      const { updateEmail: newUpdate } = newProps;
      const oldUpdate = oldProps.updateEmail;
      const snackMessage = setUpdateSnackMessage("Email Type", "Email Type");
      checkShouldUpdate(
        oldUpdate,
        newUpdate,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates,
        onCancelButtonClicked
      );
    },
    // Phone
    13: () => {
      const { updatePhone: newUpdate } = newProps;
      const oldUpdate = oldProps.updatePhone;
      const snackMessage = setUpdateSnackMessage("Phone Type", "Phone Type");
      checkShouldUpdate(
        oldUpdate,
        newUpdate,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates,
        onCancelButtonClicked
      );
    },

    14: () => {
      const { updateIdType: newUpdate } = newProps;
      const oldUpdate = oldProps.updateIdType;
      const snackMessage = setUpdateSnackMessage("ID Type", "ID Type");
      checkShouldUpdate(
        oldUpdate,
        newUpdate,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates,
        onCancelButtonClicked
      );
    },

    15: () => {
      const { update_master_enquiry_source_of_referral: newDelete } = newProps;
      const oldDelete = oldProps.update_master_enquiry_source_of_referral;
      const snackMessage = setUpdateSnackMessage("Source of Referral", "Source of Referral");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    /* 18: () => {
      const { update_enquiry_status: newDelete } = newProps;
      const oldDelete = oldProps.update_enquiry_status;
      const snackMessage = setUpdateSnackMessage("Enquiry Status", "Enquiry Status");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    }, */

    16: () => {
      const { update_lead_types: newDelete } = newProps;
      const oldDelete = oldProps.update_lead_types;
      const snackMessage = setUpdateSnackMessage("Enquiry Lead Types", "Enquiry Lead Types");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },

    17: () => {
      const { update_product_management_group_types: newDelete } = newProps;
      const oldDelete = oldProps.update_product_management_group_types;
      const snackMessage = setUpdateSnackMessage("Product Group Type", "Product Group Type");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    18: () => {
      const { update_leave_types: newDelete } = newProps;
      const oldDelete = oldProps.update_leave_types;
      const snackMessage = setUpdateSnackMessage("Leave Type", "Leave Type");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    19: () => {
      const { update_tax: newDelete } = newProps;
      const oldDelete = oldProps.update_tax;
      const snackMessage = setUpdateSnackMessage("Tax", "Tax");
      checkShouldUpdate(
        oldDelete,
        newDelete,
        oldProps,
        snackMessage,
        getEssentials,
        cleanupStates
      );
    },
    // 21: () => {
    //   const { update_unavailable_types: newDelete } = newProps;
    //   const oldDelete = oldProps.update_unavailable_types;
    //   const snackMessage = setUpdateSnackMessage("Unavailable Type", "Unavailable Type");
    //   checkShouldUpdate(
    //     oldDelete,
    //     newDelete,
    //     oldProps,
    //     snackMessage,
    //     getEssentials,
    //     cleanupStates
    //   );
    // },


  };
};
