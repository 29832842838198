import React from "react";
import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

export const NotificationBellIcon = (props) => {
  return (
    <SvgIcon fontSize={"small"} {...props} htmlColor={props.color}>
      <g id="bell" transform="translate(-3.137 .001)">
        <g
          id="Group_423"
          data-name="Group 423"
          transform="translate(3.137 -.001)"
        >
          <path
            id="Path_257"
            fill="#252b43"
            d="M26.792 16.831a2.562 2.562 0 0 0-.1-.414 2.632 2.632 0 0 0-2.919-1.7.763.763 0 0 1-.872-.635l-.633-4.049a8.83 8.83 0 0 0-6.328-7.174 3.4 3.4 0 0 0-6.73 1.035 8.52 8.52 0 0 0-3.859 8.532l.678 4.309a.769.769 0 0 1-.635.872 2.633 2.633 0 0 0-2.255 2.518 2.562 2.562 0 0 0 2.534 2.585H5.7a2.627 2.627 0 0 0 .4-.031l2.629-.413A3.388 3.388 0 0 0 15 21.279l9.655-1.519a2.562 2.562 0 0 0 2.137-2.929zM12.327 1.816a1.794 1.794 0 0 1 .269-.021 1.717 1.717 0 0 1 1.422.769h-.041a8.14 8.14 0 0 0-.955.027 8.674 8.674 0 0 0-.995.154c-.071.015-.141.035-.211.052-.269.063-.533.138-.792.225-.026.008-.052.012-.078.021a1.708 1.708 0 0 1 1.381-1.227zm-.649 20.475a1.685 1.685 0 0 1-1.005-.331l2.391-.375a1.708 1.708 0 0 1-1.387.706zm13.311-4.618a.854.854 0 0 1-.6.4L14.1 19.691l-5 .787-3.271.512a.844.844 0 0 1-.69-.2.854.854 0 0 1-.3-.661.927.927 0 0 1 .813-.842 2.477 2.477 0 0 0 2.056-2.823l-.677-4.309a6.85 6.85 0 0 1 3.455-7.052l.044-.017a6.832 6.832 0 0 1 2.642-.8 6.167 6.167 0 0 1 .844-.016l.14.007a6.716 6.716 0 0 1 .867.109h.027a7.1 7.1 0 0 1 5.52 5.9l.637 4.049a2.471 2.471 0 0 0 2.83 2.065.927.927 0 0 1 1.032.55.854.854 0 0 1-.08.724z"
            data-name="Path 257"
            transform="translate(-3.137 .001)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

NotificationBellIcon.propTypes = {
  color: PropTypes.string,
};
