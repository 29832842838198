import React from "react";
import { Paper, InputBase, IconButton, makeStyles } from "@material-ui/core";
// import { SearchIcon } from "../../assets";
import { SearchNewIcon } from "../../assets";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    borderRadius: props => props.borderRadius || 8,
    // boxShadow:
    //   "0px 6px 6px -10px rgb(0 0 0 / 9%), 0px 0px 0px 0px rgb(0 0 0 / 12%), 0px 0px 0px 0px rgba(0,0,0,0.12)",
    // boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    // boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
    // boxShadow: `0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)`, //elevation 1
    // boxShadow: `0 0 11px rgba(33,33,33,.2)`,
    boxShadow: theme.palette.primary.boxShadow,
    height: 48,
  },
  searchField: {
    width: "100%",
  },
  defaultMargin: {
    marginLeft: 16,
  },
  fixMargin: {
    marginLeft: 0,
  },
  fixPadding: {
    "&::placeholder": {
      fontSize: "0.9rem",
      textOverflow: 'ellipsis'
    },
    paddingTop: "8px !important",
  },
}))

const handleKeyPress = (event) => {
  if (event.keyCode === 13) {
    event.preventDefault();
    document.getElementById("search").click();
  }
};

const InputField = (props) => {
  const {
    // classes,
    placeholder,
    value,
    handleSearch,
    handleChangeInputSearch,
    placeSearchIconToLeft,
  } = props;
  const classes = useStyles(props)
  return (
    <Paper className={classes.paper}>
      {placeSearchIconToLeft && (
        <IconButton id="search" onClick={handleSearch}>
          <SearchNewIcon height={20} />
        </IconButton>
      )}

      <InputBase
        className={`${classes.searchField} ${
          placeSearchIconToLeft ? classes.fixMargin : classes.defaultMargin
        }`}
        id={props?.id}
        placeholder={placeholder ? placeholder : ""}
        value={value}
        onChange={(e) => {
          handleChangeInputSearch(e.target.value);
        }}
        onKeyUp={handleKeyPress}
        classes={{
          input: classes.fixPadding,
        }}
      />
      {!placeSearchIconToLeft && (
        <IconButton id="search" onClick={handleSearch}>
          <SearchNewIcon height={20} />
        </IconButton>
      )}
    </Paper>
  );
}


export default InputField;
