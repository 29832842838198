import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || "#9297a5"
  }),
  cls2: (props) => ({
    fill: "none",
    stroke: props.color || "#9297a5",
    strokeMiterlimit: 10,
    strokeWidth: 2
  })
}))

export const MonitoringNewIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon fontSize={'small'} style={{ marginTop: 6 }}> {/*The margin Top style is added to position the icon to center */}
      {/* <defs>
        <style>
          .cls-1{fill:#9297a5}
        </style>
      </defs> */}
      <g id="Monitoring" transform="translate(-2 -4)">
        <path id="Path_548" d="M16 13h-2v4.979A2.065 2.065 0 0 0 16.047 20H22v-2h-6z" className={classes.cls1} data-name="Path 548" />
        <path id="Path_549" d="M20 13H8a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1z" className={classes.cls2} data-name="Path 549" />
        <path id="Path_550" d="M4 7.775l2 .667v1.117l-2 .667V7.775M2 5v8l6-2V7L2 5zm11.277 8a2 2 0 1 0 3.446 0zM10 7a1 1 0 1 0 1 1 1 1 0 0 0-1-1z" className={classes.cls1} data-name="Path 550" />
      </g>
    </SvgIcon >
  )
}

MonitoringNewIcon.propTypes = {
  color: PropTypes.string,
};