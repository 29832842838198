import React, { Component } from 'react'
import ActivityAndMembersCard from '../../../../components/availabilityActivityComponents/ActivityAndMembersCard'
import { Typography } from '@material-ui/core'

export default class ViewProviderBranches extends Component {

  onEndIconClickHandler = (id, data) => {
    if(id) this.props.onEndIconClickHandler(id,false, data)
  }

  onRemoveAssigned = (id, data) => {
    // let professional_name = this.props.persona_details[0].resourceName;
    // console.log(professional_name)
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: true,
      title: `Are you sure you want to remove ${data?.name } ?`,
      body: "",
      positiveBtn: "OK",
      negativeBtn: "CANCEL",
      dontShowButtons: false,
      onOk: () => {
        this.props.dialog.setDialog({
          open: false
        });
        this.props.onRemoveAssigned(id)
      },
      onCancel: () => {this.props.dialog.setDialog({
        open: false
      }); },
    });
    // this.props.onRemoveAssigned();
    // alert("DATA")
  }


  render() {
    const { data, label } = this.props

    return (
      <div>
        {/* Heading */}
        <Typography variant="h6" style={{ padding: "14px 14px 14px 14px" }}>
          {data?.length ?? 0} {label ?? "N/A"}
        </Typography>

        {/* Service Coordinator List */}
        {data?.map((item, index) => {
          console.log(this.props?.id)
          return (
            <ActivityAndMembersCard
              key={index}
              field_id={`${this.props?.id}Button${index}For${label?.replace(/\s/g, "")}`}
              profilePicture={item?.profile_pic}
              title={item?.name}
              id={`${item?.id}`}
              // name={item?.resourceCategory?.map(_=>_.label).toString()}
              onEndIconClickHandler={()=>this.onEndIconClickHandler(item?.id, item)}
              // useActivityAsSummaryCard={false}
              onRemoveAssigned={()=> this.onRemoveAssigned(item?.id, item)}
              showRemoveAssigned = {this.props.showRemoveAssigned}
              timing={item?.resourceCategory?.map(val=>{return val.label}).join(' | ')}
              status={item?.isactive} 
            />
          )
        })}
        {/* <Typography>View all Service Coordinator Screen Needed</Typography> */}
      </div>
    )
  }
}
