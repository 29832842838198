import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, withStyles } from "@material-ui/core";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import ImageIcon from '@material-ui/icons/Image';
import { withAlertAndDialog } from "../../HOCs";
import { imageFormats, videoFormats } from "../../utils";
const styles = (theme) => ({
  videoRoot: {
    cursor: "pointer",
  },
  video: {
    textTransform: "upperCase",
    paddingRight: 10,
    margin: "auto",
    textDecoration: "underline",
  },
  video2: {
    textTransform: "upperCase",
    paddingRight: 10,
    paddingLeft: 10,
    // margin: "auto",
    textDecoration: "underline",
  },
  video3: {
    textTransform: "upperCase",
    paddingRight: 10,
    paddingLeft: 0,
    // margin: "auto",
    textDecoration: "underline",
  },
  videoTag: {
    [theme.breakpoints.down("md")]: {
      width: 320,
      height: 250, 
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "100%",
    },
  },
});
class ViewUploadedFile extends Component {


  viewFileContent = (url, extension, actualFile) => {
    const { classes } = this.props;

    // Component to render based on format
    const getComponentToView = (url, extension, actualFile) => {
      if(url && extension){
        if (
          videoFormats.includes(extension?.toLowerCase())
        ) {
          return (
            <video className={classes.videoTag} controls>
              <source src={url} type="video/mp4" />
              <source src={url} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          );
        }
        else if (
          imageFormats.includes(extension?.toLowerCase())
        ) {
          return (
            <img src = {url} className={classes.videoTag} alt={`Sorry Unable to view - .${extension} format !`}/>
          );
        }
         else {
          return (
            <Grid
              container
              item
              xs={12}
              alignContent="center"
              alignItems="center"
              justify="center"
              direction="column"
            >
              <SentimentVeryDissatisfiedIcon color="primary" />
              <Typography variant="body1">Sorry Unable to view!</Typography>
            </Grid>
          );
        }
      } else if (actualFile) {

        let tempObjURL = URL.createObjectURL(actualFile)

        return (
          <img src = {tempObjURL} onLoad={() => URL.revokeObjectURL(tempObjURL)} className={classes.videoTag} alt={`Sorry Unable to view document!`}/>
        );
      }
    };

    // Display the content in a model
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: true,
      title: this.props.viewDialogTitle ? this.props.viewDialogTitle : `Promotional Content`,
      body: getComponentToView(url, extension, actualFile),
      positiveBtn: "Ok",
      negativeBtn: "CANCEL",
      dontShowButtons: true,
      onOk: () => {},
      onCancel: () => {
        this.props.dialog.setDialog({
          ...this.props.dialog,
          open: false,
          title: ``,
          body: "",
          positiveBtn: "Ok",
          negativeBtn: "CANCEL",
          dontShowButtons: false,
        });
      },
    });
  };

  //  Displaying label component
  getComponentToRender = (url, extension, actualFile) => {
    const { classes, applyAlternateStyle } = this.props;
    if(url && extension){
      if (
          videoFormats.includes(extension?.toLowerCase())
      ) {
        return (
          <Grid container>
            <Grid container item xs={12} className={classes.videoRoot}>
              <Typography
                variant="body2"
                className={applyAlternateStyle ? classes.video2 : classes.video}
                onClick={() => this.viewFileContent(url, extension)}
                id={this.props?.id}
              >
               {this.props.clickText ? this.props.clickText : "Click to view"} 
              </Typography>
              <OndemandVideoIcon
                color="primary"
                onClick={() => this.viewFileContent(url, extension)}
              />
            </Grid>
          </Grid>
        );
      } 
      else if (
          imageFormats.includes(extension?.toLowerCase())
      ) {
        return (
          <Grid container>
            <Grid container item xs={12} className={classes.videoRoot}>
              <Typography
                variant="body2"
                className={applyAlternateStyle ? this.props.profileDrawerStyle ? classes.video3 : classes.video2 : classes.video}
                onClick={() => this.viewFileContent(url, extension)}
                id={this.props?.id}
              >
                {this.props.clickText ? this.props.clickText : "Click to view"} 
              </Typography>
              {!this.props.hideImageIcon && <ImageIcon
                color="primary"
                onClick={() => this.viewFileContent(url, extension)}
              />}
            </Grid>
          </Grid>
        );
      } 
      else {
        return (
          <Grid container item xs={12} justify="center">
            <Typography variant="body1" color="error">
              Sorry unable to show the preview!
            </Typography>
          </Grid>
        );
      }
    } else if (actualFile){
      return (
        <Grid container>
        <Grid container item xs={12} className={classes.videoRoot}>
          <Typography
            variant="body2"
            className={applyAlternateStyle ? classes.video2 : classes.video}
            onClick={() => this.viewFileContent(url, extension, actualFile)}
          >
            {this.props.clickText ? this.props.clickText : "Click to view"}
          </Typography>
          {!this.props.hideImageIcon && <ImageIcon
            color="primary"
            onClick={() => this.viewFileContent(url, extension, actualFile)}
          />}
        </Grid>
      </Grid>
      )
    }
  };
  render() {
    const { fileUrl, fileExtension, actualFile } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          {this.getComponentToRender(fileUrl, fileExtension, actualFile)}
        </Grid>
      </Grid>
    );
  }
}

ViewUploadedFile.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  fileExtension: PropTypes.string.isRequired,
};

export default withAlertAndDialog(withStyles(styles)(ViewUploadedFile));
