import React from "react";
import {
  Grid,
  makeStyles,
  Avatar,
  fade,
  IconButton,
  Typography,
} from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: `0px 0.5px 2px 1px #e0e0e0d6`,
    padding: 16,
    backgroundColor: "white",
    height: 180,
    borderRadius: 8,
  },
  avator: {
    height: 50,
    width: 50,
  },
  arrowIcon: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 12,
  },
  header: {
    display: "flex",
  },
  textArea: {
    marginLeft: 8,
  },
  iconBtn: {
    position: "relative",
    top: "-12px",
    left: 8,
  },
}));

const GraphCard = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems={"stretch"}
      justify="space-between"
      className={classes.root}
    >
      <Grid
        item
        container
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item className={classes.header}>
          <Avatar
            className={classes.avator}
            style={{ backgroundColor: fade(props.backgroundColor ? props.backgroundColor : props.color, 0.03) }}
          >
            {props.icon}
          </Avatar>
          <div className={classes.textArea}>
            <Typography variant="h6">{props.count}</Typography>
            <Typography variant="subtitle2">{props.label}</Typography>
          </div>
        </Grid>
        <Grid item className={classes.iconBtn}>
          <IconButton>
            <ArrowRightAltIcon className={classes.arrowIcon} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item>{props.graph}</Grid>
    </Grid>
  );
};

GraphCard.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  count: PropTypes.string,
  label: PropTypes.string,
  graph: PropTypes.element.isRequired,
};

export default GraphCard;
