import { Grid, Typography, useMediaQuery, Button } from '@material-ui/core'
import React from 'react'
import DaysToggleGroup from '../../daysToggleGroup';
import { SimpleSelect } from '../../inputs';
import { useTheme } from '@material-ui/core/styles';
import { timezoneIncoming as timezone } from '../../../utils'
import { DateTimePickersCustom } from '../../dateTimePicker';
import TimeRangePicker from './timeRange'
// import {TimePicker} from '../../../components/timepicker'
// TimeZone



// const useStyles = makeStyles(theme => ({
//   customFormControlLabel: {
//     color: "#9297a5",
//     fontSize: "0.860rem"
//   },
//   customSelect: {
//     '&:focus': {
//       background: 'white'
//     }
//   },
//   selectFormControlRoot: {
//     margin: (props) => props.marginTop ? `${props.marginTop}px 0px 8px 0px` : "8px 0px 8px 0px",
//     width: "100%",
//     "& .MuiOutlinedInput-input": {
//       padding: "16px 12px",
//     },
//     "& .Mui-focused": {
//       "& .MuiOutlinedInput-notchedOutline": {
//         border: `1.8px solid ${theme.palette.ternary.light}`,
//       },
//     },
//     "& .MuiOutlinedInput-notchedOutline": {
//       border: `1.8px solid ${theme.palette.secondary.light}`,
//       borderRadius: 8,
//     },
//     "& .MuiOutlinedInput-root:hover": {
//       "& .MuiOutlinedInput-notchedOutline": {
//         border: `1.8px solid ${theme.palette.ternary.light}`,
//       },
//     },
//   },
//   label: {
//     color: "rgba(146, 151, 165, 1)",
//     textTransform: "uppercase",
//   },
// }))



export function TimeSlot(props) {

console.log(props.daysGroup)


  // const classes = useStyles(props)
  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const fieldSwitch = props.useSeperateFieldsForTimeRange
  return (
    <Grid container>
      <Grid item xs={12} container alignItems="center">
        <Grid item>
          <Typography style={{ fontSize: "1.15rem", margin: "20px 0px" }} >{props.timeSlotTitle ? props.timeSlotTitle : null}</Typography>
        </Grid>
        <Grid item xs style={{ marginLeft: 12 }}>
          {!props.hideRemoveButton ? <Button style={{ color: "#f27d35", boxShadow: "none" }}
            onClick={props.removeSlotHandler} >remove</Button> : null}
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <DaysToggleGroup
          value={props.daysGroup}
          onChangeHandler={props.handleDaysChange}
          availableAllDay={props.availableAllDay}
          handleAvailableAllDayChange={props.handleAvailableAllDayChange}
          isDisabled={props.isDisabled}
          availableWeekDays={props.availableWeekDays}
          errorObject={props.toggleGroupError}
        />
      </Grid>
      <Grid item container xs={12} style={{ margin: "10px 0px" }}>
        {!props.useSeperateFieldsForTimeRange && <Grid item xs={12} sm={5} md={4} lg={3} style={{ marginRight: isXsDown ? 0 : 8 }}>
        {/* Don't Remove this comment */}
          <DateTimePickersCustom label="TIME SLOT" value={props.timeSlot} handleOnChange={props.handleTimeSlotChange}
            isDisabled={props.isDisabled || props.disableTime}
          />
          {/* <TimePicker label={"TIME SLOT"} isDisabled={props.isDisabled || props.disableTime}  /> */}
        </Grid>}
        {props.useSeperateFieldsForTimeRange && <>
          <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} >
            <TimeRangePicker 
              fromTimeLabel={props.fromTimeLabel}
              fromTimeValue={props.fromTimeValue}
              onFromTimeChange={props.onFromTimeChange}
              disableFromTimePast={props.disableFromTimePast || props.daysGroup?.length === 0}
              fromTimeMin={props.fromTimeMin}
              disableFromTimeField={props.disableFromTimeField || props.daysGroup?.length === 0}
              fromTimeErrorObject={props.fromTimeError}

              toTimeLabel={props.toTimeLabel}
              toTimeValue={props.toTimeValue}
              onToTimeChange={props.onToTimeChange}
              disableToTimePast={props.disableToTimePast || props.daysGroup?.length === 0}
              toTimeMin={props.toTimeMin}
              disableToTimeField={props.disableToTimeField || props.daysGroup?.length === 0}
              toTimeErrorObject={props.toTimeError}
            />
            <Grid item xs={12} sm={4} md={4} lg={4} >
          <SimpleSelect
            placeholder=""
            label="Time zone"
            options={timezone()}
            value={props.timeZone}
            handleOnChange={props.handleTimeZoneChange}
            isDisabled={props.disableTimeZone || props.daysGroup?.length === 0}
            overflow={"hidden"}
            responsive
            error={props.timeZoneError?.error}
            errorText={props.timeZoneError?.message}
          />
        </Grid>
        </Grid>
        </>}
        {!props.useSeperateFieldsForTimeRange && <Grid item xs={12} sm={fieldSwitch ? 12 : 5} md={fieldSwitch ? 6 : 4} lg={fieldSwitch ? 6 : 3} style={{ marginLeft: !props.useSeperateFieldsForTimeRange ?  isXsDown ? 0 : 8 : 0 }}>
          <SimpleSelect
            placeholder=""
            label="Time zone"
            options={timezone()}
            value={props.timeZone}
            handleOnChange={props.handleTimeZoneChange}
            isDisabled={props.isDisabled || props.disableTime || props.daysGroup?.length === 0}
            height={51}
          />
        </Grid>}
      </Grid>

      {props.showAddSlotButton ? <Grid item xs={12} sm={5} md={5} lg={4} xl={4}>
        <Button disabled={props.disableAddSlotButton} variant="contained" color="primary" onClick={props.addSlotHandler} style={{ margin: "20px 0px" }} >+ Add Time Slot</Button>
      </Grid> : null}

      <Grid item xs={12} style={{ margin: "16px 0px", borderBottom: `0.5px dashed rgba(146, 151, 165, 0.5)` }}></Grid>

    </Grid>
  )
}

export default TimeSlot

// hideRemoveButton