import { withStyles } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions } from "tanyacare-middleware";
import { LoadingSection } from "../../../components";
import AddEditProductManagemet from "../../../components/addEditForm";
import withAlelrtAndDialog from "../../../HOCs/withAlelrtAndDialog";
import { formComponentTypes } from "../../../utils";

const styles = (theme) => ({});

class TaxDrawer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      searchKey: "",
      sortKey: 1,
      //   minDate:new Date()
    };
  }
  componentDidMount() {
    this.getEssentials();
    this.getMindate();
  }

  getEssentials = async () => {
    if (this.props.isEdit) {
      this.props.GET_TAX_SETUP_INFO({ tax_id: this.props.tax_id });
    }
    this.props.GET_MASTER_TAX({ ...this.state });
  };

  getMindate() {
    const { selectedMonth, selectedYear } = this.props;
    let _date = new Date();
    if (selectedMonth && selectedYear) {
      _date.setDate(_date.getDate());
      _date.setMonth(selectedMonth - 1);
      _date.setFullYear(selectedYear);
    }
    this.setState({ minDate: _date });
  }

  onSaveButtonClicked = async (isError, data, tax_id) => {
    
    // console.log(isError);
    // console.log(data);
    // console.log(tax_id);
    if (!isError) 
      {
        if(tax_id)
        {
          this.props.UPDATE_TAX_SETUP({ data: data, tax_id: tax_id })
          this.props.onCloseDrawer(
            !this.props.update_tax_setup?.error ? true : false,
            this.props.update_tax_setup.error
              ? "Cannot Update Tax Details"
              : "Tax Details Updated "
          )
        }
        else{
          this.props.ADD_TAX_SETUP(data);
          this.props.onCloseDrawer(
            !this.props.add_tax_setup?.error ? true : false,
              this.props.add_tax_setup?.error ? "Cannot Add Tax Details" : "Tax Details Addedd "
            );
        }

      }
    
  };

  render() {
    const { tax_id } = this.props;

    let req_data = this.props?.get_tax_setup_info?.data[0];
    let req_loading = this.props?.get_tax_setup_info?.loading;

    // let { error: add_tax_error } = this.props.add_tax_setup;
    // let { error: update_tax_error } = this.props.update_tax_setup;

    // let selectedEvent = {};
    // if (eventId) {
    //   selectedEvent = events?.filter((event) =>
    //     isDBEventEdit ? event?.id === eventId : event?.localId === eventId
    //   )?.[0];
    // }

    const fullWidth = {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    };

    const components = {
      section_1: {
        label: tax_id ? "Edit Tax" : "Add New Tax",
        fields: {
          tax_type: {
            key: "tax_type",
            type: formComponentTypes.SELECT_BOX,
            // isDisabled: tax_id ? true : false,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "Tax Type",
            options: this.props.tax?.data,
            value: tax_id ? req_data?.taxType : "",
            componentProps: {
              type: "text",
              multiline: false,
              placeholder: "",
            },
            isMandatory: true,
          },
          tax_code: {
            key: "tax_code",
            type: formComponentTypes.INPUT_BOX,
            // disabled: tax_id ? true : false,
            id: `addTaxCodeForAddTax`,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "Tax Code",
            // && parentState?.activity_name
            value: tax_id ? req_data?.taxCode : "",
            componentProps: {
              type: "text",
            },
            isMandatory: true,
          },
          type: {
            key: "type",
            type: formComponentTypes.SELECT_BOX,
            // isDisabled: tax_id ? true : false,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "Type",
            options: [
              { label: "Variable", value: "Variable" },
              { label: "Fixed", value: "Fixed" },
            ],
            value: tax_id
              ? { label: req_data?.type, value: req_data?.type }
              : "",
            componentProps: {
              type: "text",
              multiline: false,
              placeholder: "",
            },
            isMandatory: true,
          },
          tax_rate: {
            key: "tax_rate",
            type: formComponentTypes.INPUT_BOX,
            disabled: false,
            id: `addTaxCodeForAddTax`,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "Tax Rate %",
            // && parentState?.activity_name
            value: tax_id ? req_data?.ratePercentage : "",
            componentProps: {
              type: "text",
            },
            isMandatory: true,
          },
          effective_expiry: {
            key: {
              effective: "effective",
              noExpiry: "noExpiry",
              expiry: "expiry",
            },
            type: formComponentTypes.EFFECTIVE_EXPIRY,
            value: {
              effective: tax_id ? req_data?.effective : null,
              noExpiry: tax_id ? req_data?.isExpiry : true,
              expiry: tax_id ? req_data?.expiryDate : null,
            },
            breakPoints: fullWidth,
            isMandatory: true,
          },
        },
      },
    };

    return (
      <>
        {req_loading && <LoadingSection message="Loading Tax Data..." />}
        {!req_loading && (
          <AddEditProductManagemet
            components={components}
            // onSaveButtonClicked={(isError, data, url, e) => console.log(e, url)}
            onSaveButtonClicked={(isError, data, url, e) => {
              this.onSaveButtonClicked(isError, data, tax_id);
              // {tax_id
              //   ? this.props.UPDATE_TAX_SETUP({ data: data, tax_id: tax_id })
              //   : this.props.ADD_TAX_SETUP(data)}
              // {
              //   tax_id ?
              //   this.props.onCloseDrawer(!update_tax_error ? true : false, update_tax_error ? "Cannot Update Tax Details" : "Tax Details Updated ")
              //   : this.props.onCloseDrawer(add_tax_error ? true : false, !add_tax_error ? "Cannot Add Tax Details" : "Tax Details Addedd ")
              // }
            }}
            //cancelForm={this.props.onCancelButtonClicked}
            cancelForm={() => {this.props.drawer.setDrawer({
              ...this.props.drawer,
              open: false,
            })}}
            loading={false}
            isResetState={this.state.minDate}
            saveButtonLabel={tax_id ? "Update" : "Add"}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tax: state?.masterSet?.tax,
  add_tax_setup: state?.masterTax?.add_tax_setup,
  get_tax_setup_info: state?.masterTax?.get_tax_setup_info,
  update_tax_setup: state?.masterTax?.update_tax_setup,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(TaxDrawer))));
