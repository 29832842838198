import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { fontStyleClass } from "../../../utils";

const styles = makeStyles((theme) => ({
  root: {
    marginBottom: 26,
  },
  header: {
    marginBottom: 10,
  },
  value: {
    padding: 10,
    background: theme.palette.secondary.light,
    width: "100%",
    color: theme.palette.secondary.main,
    borderRadius: 8,
  },
  subText: {
    color: theme.palette.secondary.main,
    marginBottom: 10,
  },
  ...fontStyleClass
}));

export const CardWithSubText = (props) => {
  
  const classes = styles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h6" className={`${classes.header} ${classes.mediumFont}`}>
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="caption"
          component="div"
          className={classes.subText}
        >
          {props.subText}
        </Typography>
      </Grid>
      {props.subText === "Yes" && (
        <Grid item xs={12}>
          <Typography
            variant="caption"
            component="div"
            className={classes.value}
          >
            {props.value}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
