import React, { Component } from 'react'
import { Divider, Grid, IconButton, List, Typography, withStyles } from '@material-ui/core'
import AddRoundedIcon from '@material-ui/icons/AddRounded';
// import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import TodoItem from './TodoItem'
import PropTypes from 'prop-types'
import { fontStyleClass, commonShadows, genericStyles } from '../../utils';

const style = (theme) => ({
  rowOneIcons: {
    border: '1px solid #E8E8EC',
    margin: '0px 4px',
    padding: 8,
    backgroundColor: theme.palette.ternary.main,
    "&:hover": {
      backgroundColor: theme.palette.ternary.dark,
    },
  },
  customListItemIcon: {
    minWidth: 36
  },
  ...genericStyles.cardWrapperShadows(theme),
  ...commonShadows,
  ...fontStyleClass
})

class Todo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      todos: this.props.todos,
    }
  }


  render() {
    const { classes, handleAddTodo, todos } = this.props

    const { tasks } = this.state

    return (
      <div style={{ width: "100%" }} className={`${classes.commonShadows} ${classes.cardWrapperShadows}`}>
        <Grid container>

          {/* Header */}
          <div style={{ padding: 5 ,paddingLeft: 16, paddingRight: 16, width: "100%" }} >
            <Grid item container>
              <Grid item xs={12} container>
                <Grid item>
                  <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" className={classes.mediumFont}>To-Do</Typography>
                  </div>
                </Grid>
                <Grid item xs>
                  {/* This Grid is needed for the spacing in-between header text and the icon */}
                </Grid>
                <Grid item>
                  <div style={{ display: 'flex' }}>
                    <IconButton id={"AddTodoButton"} className={classes.rowOneIcons} onClick={handleAddTodo} > {/*style={{ width: 50 }} */}
                      <AddRoundedIcon htmlColor={"white"} />
                    </IconButton>
                    {/* <IconButton className={classes.rowOneIcons}>
                      <CloseRoundedIcon htmlColor={"#B0B4BE"} />
                    </IconButton> */}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>

          {/* Header Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <div style={{ paddingLeft: 16, paddingRight: 16, width: "100%" }}>
            <Grid item container>
              <Grid item xs={12} style={{ maxHeight: 350, overflow: 'scroll' }} >
                <List>
                  <TodoItem
                    handleDelete={this.props.handleDelete}
                    handleEdit={this.props.handleEdit}
                    tasks={tasks}
                    todos={todos}
                    handleTaskToggle={this.props.handleTaskToggle}
                    updateTodoStatus={this.props.updateTodoStatus}
                  />
                </List>
              </Grid>
            </Grid>
          </div>


        </Grid>
      </div>
    )
  }
}

export default withStyles(style)(Todo)

Todo.propTypes = {
  /**To handle add todo drawer open/close. */
  handleAddTodo: PropTypes.func.isRequired
}