import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { fontStyleClass } from "../../../utils";

const styles = makeStyles((theme) => ({
  header: {
    // marginBottom: 10,
  },
  ...fontStyleClass
}));

export function ListWithBullets(props) {
  const classes = styles();
  const { title, listData } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" className={`${classes.header} ${classes.mediumFont}`}>
          {title}
        </Typography>
      </Grid>
      {listData.length>0 ?
        <ul style={{paddingLeft:16}}>
        {listData.map((val, index) => {
          return (
            <>
              <Grid item xs={12}>
                <li>
                  <Typography variant="caption" style={{ float: "left" }}>
                    {val}
                  </Typography>
                </li>
              </Grid>
            </>
          );
        })}
      </ul>
      :
      <Grid item xs={12}>
              <Typography variant="caption" style={{ float: "left" }}>
                N/A
              </Typography>
          </Grid>
      }
    </Grid>
  );
}

ListWithBullets.propTypes = {
  title: PropTypes.string,
  listData: PropTypes.array,
};
