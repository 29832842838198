import * as React from "react";
import {
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Typography
} from "@material-ui/core";
import {
  MobileDateRangePicker,
  DateRangePicker,
  LocalizationProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Calendar from "../../assets/product Management/addEditScreen/calendar.svg";
import { useStyles } from "./style";

const getCustomizedDateRange = (value) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec"
  ];

  let startDate = new Date(value[0]);
  let endDate = new Date(value[1]);
  // return monthNames[d.getMonth()]
  if (value[0] && value[1]) {
    let _tempStartDate =
      monthNames[startDate.getMonth()] +
      " " +
      startDate.getDate() +
      " , " +
      startDate.getFullYear();
    let _tempEndDate =
      monthNames[endDate.getMonth()] +
      " " +
      endDate.getDate() +
      " , " +
      endDate.getFullYear();
    let actualDate = _tempStartDate + " - " + _tempEndDate;
    startDate = startDate.getDate();
    endDate = endDate.getDate();
    return actualDate;
  }
};
const handleKeysDisable = () => {
  return false;
};
export const DateRangePickerCustom = ({required, ...props}) => {
  const classes = useStyles();
  return (
    <div style={{ margin: "8px 0px 8px 0px" }} className={classes.root}>
      <InputLabel shrink htmlFor={props.label} className={classes.label} required={required} >
        {props.label.toUpperCase()}
      </InputLabel>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        {props?.variant !== "mobile" ? (
          <DateRangePicker
            value={props.value}
            disableFuture={props?.isHideFutureDate}
            disablePast={props?.isHidePastDate}
            className={classes.root}
            onChange={(newValue) => {
              props.handleDateRange(newValue);
            }}
            renderInput={(startProps, endProps) => {
              const { inputProps } = startProps;
              startProps["id"] = props.label;
              startProps.className = props.error
                ? classes.inputGridError
                : classes.inputGrid;
              startProps.style = { width: "100%" };

              startProps.autoComplete = "off";
              startProps["endAdornment"] = (
                <InputAdornment
                  onClick={() => {
                    document.getElementById(props.label).focus();
                  }}
                  style={{ cursor: "pointer" }}
                  position="end"
                >
                  <img src={Calendar} alt="calendar" />
                </InputAdornment>
              );
              delete startProps.label;
              inputProps.value = getCustomizedDateRange(props.value);
              inputProps.placeholder = "";
              // input:Props.style={pointerEvents:'none'}
              inputProps.onChange = { handleKeysDisable };

              return (
                <React.Fragment>
                  <OutlinedInput {...startProps} style={{ width: "100%" }} />
                </React.Fragment>
              );
            }}
          />
        ) : (
          <>
            <MobileDateRangePicker
              value={props.value}
              disableFuture={props?.isHideFutureDate}
              disablePast={props?.isHidePastDate}
              className={classes.root}
              onChange={(newValue) => {
                props.handleDateRange(newValue);
              }}
              renderInput={(startProps, endProps) => {
                const { inputProps } = startProps;
                startProps["id"] = props.label;
                startProps.className = props.error
                  ? classes.inputGridError
                  : classes.inputGrid;
                startProps.style = { width: "100%" };

                startProps.autoComplete = "off";
                startProps["endAdornment"] = (
                  <InputAdornment
                    onClick={() => {
                      document.getElementById(props.label).click();
                    }}
                    style={{ cursor: "pointer" }}
                    position="end"
                  >
                    <img src={Calendar} alt="calendar" />
                  </InputAdornment>
                );
                delete startProps.label;
                inputProps.value = getCustomizedDateRange(props.value);
                inputProps.placeholder = "";
                // input:Props.style={pointerEvents:'none'}
                inputProps.onChange = { handleKeysDisable };

                return (
                  <React.Fragment>
                    <OutlinedInput {...startProps} style={{ width: "100%" }} />
                  </React.Fragment>
                );
              }}
              //theirs
              // renderInput={(startProps, endProps) => (
              //   <React.Fragment>
              //     <TextField {...startProps} />
              //     <DateRangeDelimiter> to </DateRangeDelimiter>
              //     <TextField {...endProps} />
              //   </React.Fragment>
              // )}
            />
          </>
        )}
      </LocalizationProvider>
      {props.error && (
        <Typography variant="caption" color={"error"}>
          {props.errorText}
        </Typography>
      )}
    </div>
  );
};
