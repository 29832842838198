/* eslint-disable eqeqeq */
import React from "react";

import {
  Grid,
  Typography,
  withStyles,
//   Divider,
} from "@material-ui/core";

// import ProductEditCard from "../../../../components/productEditCard";
// 
import { 
  // ListCard,
  LoadingSection,NotFoundSection,InternatlServerError } from "../../../../components";
// import { ICONS } from "../../../../components/hybridTypography/typography";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routes } from "../../../../router/routes";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { alertProps, scrollToTop, user_roles } from "../../../../utils";
// import moment from "moment";
import { PackageCard } from "./packageCard";
import ViewSequence from "./viewSequence";
import { ProductCatalogue } from "../../../../components/productCatalogue/productCatalogue";
// import { tr } from "date-fns/locale";

const styles = (theme) => ({
  gridItems: {
    padding: "25px 25px 40px 32px",
    background: "white",
  },
  gridTopItems: {
    padding: "25px 25px 0px 32px",
    background: "white",
  },
  gridMiddleItems: {
    padding: "16px 25px 0px 32px",
    background: "white",
  },
  gridBottomItems: {
    padding: "0px 25px 40px 32px",
    background: "white",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  dividerRoot: {
    backgroundColor: "rgba(244, 244, 245, 1)",
  },
});

class PackageDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getDetail();
    scrollToTop();
  }

  getDetail = () => {
    const id = this.props?.match?.params?.id;
    const versionId = this.props?.match?.params?.versionId;
    this.props.GET_PACKAGES_INFO({ id, versionId, isDetail: true });
  };

  onStatusChangeClick = () => {
    let pg = this.props?.packageInfo?.data ?? null

    const Oldstatus = pg?.isActive;

    let status = "Activate";
    if (Oldstatus) {
      status = "Deactivate";
    }

    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body: "Do you want to " + status + " package : " + pg?.name,
      positiveBtn: status,
      negativeBtn: "Cancel",
      onOk: () => this.deactivate(pg),
    });
  };

  deactivate = (pg) => {
    const id = this.props?.match?.params?.id;
    const status = pg?.isActive;
    this.props.dialog.setDialog({ open: false });
    this.props.UPDATE_STATUS_OF_PACKAGE({ packageID: id, status: !status });
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Update Status
    const { updatePackageStatusInfo } = nextProps;
    const oldupdatePackageStatusInfo = this.props.updatePackageStatusInfo;

    //Checking for Update Status
    if (
      oldupdatePackageStatusInfo?.loading !== updatePackageStatusInfo?.loading
    ) {
      this.props.backDrop.setBackDrop({
        open: updatePackageStatusInfo.loading,
        message: "Updating the package status...",
      });
      if (!updatePackageStatusInfo.loading && !updatePackageStatusInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Package Status updated successfully!",
          severity: alertProps.severity.success,
        });
        this.getDetail();
      } else if (updatePackageStatusInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to update package status.",
          severity: alertProps.severity.error,
        });
      }
    }

    return true;
  }

  navigateToEdit = () => {
    const package_id = this.props?.match?.params?.id;
    const versionId = this.props?.match?.params?.versionId;
    this.props.history.push(routes.manage_package + "/edit/" + package_id + "/" + versionId);
  };

  navigateToView = (id, versionId) => {
    this.props.history.push(routes.manage_service+"/view/"+id + "/" + versionId)
  };


  handleTimelineOpen = () => {
    let { data } = this.props?.packageInfo
    // ProductTimeline
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: <ViewSequence sequenceData={data} />,
    });
  }

  roleBasedAccess = (id) => {
    const data = this.props?.auth_data?.data;
    
    // Return True disable edit button
    // Return False enable edit button

    let disable = null;
    if(Boolean(data?.user_role === user_roles.service_coordinator || data?.user_role === user_roles.help_desk || this.props?.auth_data?.data?.user_role === user_roles.business_admin)){
      /*SC can;t edit a Product */
      disable = true
    }

    if (data?.user_role == user_roles.provider) {
      if(data?.user_id == id?.partnerId){
        /*User can edit a Product*/
        disable = false
      }else{
        disable = true
      } 
    }
    if (data?.user_role == user_roles.supervisor) { 
        if (data?.partnerId == id?.partnerId) { 
            disable = false 
        } else{
          disable = true 
        } 
    }
    if (data?.user_role == user_roles.super_admin && data?.other_roles?.indexOf("cross-bundling") > -1) { 
      if (id?.care_provider?.[0]?.crossBundle) { 
        // id?.partnerId !== "5cd8f293-1e49-473e-af6b-bf27e986566f"
          /* no one can edit MC User's product except him*/
          disable = false
      }else{
          disable = true 
      }
    }

    if (data?.user_role == user_roles.super_admin && data?.other_roles?.indexOf("cross-bundling") === -1) { 
      if (id?.care_provider?.[0]?.crossBundle) { 
          /* no one can edit MC User's product except him*/
          disable = true
        }else{
          disable = false
      }
    }

    return disable;

  }

  formatData = (services) => {
    let styledServices = services?.map((service) => {
      let orderList = [
        {
          label: "Quantity",
          value: service?.quantity,
        },
        {
          value: `${service?.currency} ${service?.amount}`,
          style: {minWidth: 140}
        }
      ]
      return {...service, orderList, care_provider: [{...service?.providerDetails}]}
    })

    return Array.isArray(styledServices) ? styledServices : []
  }

  render() {
    // const { classes } = this.props;
    let { data , loading, error } = this.props?.packageInfo;
    let { loading: statusChangeLoading } = this.props?.updatePackageStatusInfo;
    data = data ?? null;
    const id = this.props?.match?.params?.id;
    const versionId = this.props?.match?.params?.versionId;
    return (
      <div>
        {loading && <LoadingSection message="Fetching Package Detail..." />}
        {!loading && error && <InternatlServerError message="Something went wrong! Try Again" onRetry={this.getDetail} />}
        {!loading && !error && !data && <NotFoundSection message="404 - Package not found" />}

        {!loading && !error && data && Object.keys(data)?.length > 0 && (
          <Grid item style={{ padding: "25px" }}>

            <PackageCard 
              navigateToEdit={this.navigateToEdit}
              packageDetail={data}
              onStatusChangeClick={this.onStatusChangeClick}
              statusChangeLoading={statusChangeLoading}
              isbuttonHide={this.roleBasedAccess(data)}
              id={`${id}${versionId}`}
              handleTimelineOpen={this?.props?.packageInfo?.data?.order_sequence?.length > 1 ? this.handleTimelineOpen : null}
            />

            <Typography style={{ margin: "30px 0px 15px 0px" }}>
              Services
            </Typography>
            <ProductCatalogue
                id={"PackageIncludedServices"}
                catalogues={this.formatData(data?.included_service)}
                redirectUrl={routes.manage_service + "/view/"}
                loading={loading}
                showProvider
                hideStatus
              />
            {/* {data?.included_service?.map((l, key) => {
              return (
                <div style={{ marginBottom: 12, cursor: "pointer" }}>
                  <ListCard
                    data={{
                      id: l?.value,
                      isactive: true,
                      name: l?.label,
                      versionId: l?.versionId,
                      care_provider: [l?.providerDetails],
                      category: l?.category,
                      profile_pic: l?.profile_pic,
                      orderList: [{label: "Quantity", value: l.quantity},{label: l?.frequency, value:  `${l?.currency + " " + l?.amount}`}]
                    }}
                    isMoreBtn={false}
                    navigateToView={this.navigateToView}
                    setPriceTrailingDecimal
                    hideDivider={false}
                  />
                </div>
              );
            })} */}
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  packageInfo: state?.packageState?.packageInfo,
  updatePackageStatusInfo: state?.packageState?.updatePackageStatusInfo,
  auth_data: state?.authState?.loginAuth,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(PackageDetail))));
