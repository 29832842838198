import * as React from "react";
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyle = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.htmlColor
  })
}))

export const CameraSvg = (props) => {
  const classes = useStyle(props)
  return (
    <SvgIcon>
      <svg width={20} height={18.002} viewBox="0 0 20 18.002" {...props}>
        {/* <defs>
          <style>{".prefix__cls-1{fill:#9297a5}"}</style>
        </defs> */}
        <g id="prefix__Camera" transform="translate(-2 -1.998)">
          <path
            id="prefix__Path_629"
            d="M12 7a5 5 0 105 5 5 5 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"
            className={classes.cls1}
            data-name="Path 629"
          />
          <path
            id="prefix__Path_630"
            d="M20 4h-3.157L15 2H9L7.164 4H4a2.006 2.006 0 00-2 2v12a2.006 2.006 0 002 2h16a2.006 2.006 0 002-2V6a2.006 2.006 0 00-2-2zm0 14H4V6h4.043l1.835-2h4.244l1.844 2H20z"
            className={classes.cls1}
            data-name="Path 630"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

CameraSvg.defaultProps = {
  htmlColor: "#9297a5"
}

CameraSvg.propTypes = {
  htmlColor: PropTypes.string
}