import React from "react";
import { Grid, withStyles } from "@material-ui/core";
// import CardList from "../../../../components/userList";
import SubHeader from "../../../../components/subHeader";
import {
  // careProviderDetailsUrl,
  filterTagType,
  fontStyleClass,
  // productSortDirectionOption,
  scrollToTop,
  // sortDirection,
  // sortFields,
  // subHeaderSortOption,
  subHeaderSortOptionWithValue,
  user_roles,
} from "../../../../utils";
import { routes } from "../../../../router/routes";
import {
  FilterBuilder,
  InternatlServerError,
  NotFoundSection,
} from "../../../../components";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { filterComponent } from "./filterComponent";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { errorMessage } from "../../../../utils/errorMessages";
import FilterTags from "../../../../components/filters/filterTags";
import { totalCountLabel, entityLabel } from "../../utils";
import { Catalogue } from "../../../../components/catalogue/catalogue";
import { isTrialAccountType,checkTrialExpiryRole } from "../../../../utils/common";

// const sizes = {
//   md: 3,
//   sm: 3,
//   xs: 3,
// };

const styles = theme => ({
  ...fontStyleClass
})


const initialState = {
  pageNo: 0,
  pageSize: 25,
  careProviderName: "",
  category: [],
  status: undefined,
  products: [],
  date: null,
  isFilterApplied: false,
  sortDirec: subHeaderSortOptionWithValue[2],
  // sort: subHeaderSortOption[2].value,
  // sortDirec: productSortDirectionOption[1]
};

class ProviderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      id: this.props?.id
    };
  }

  componentDidMount() {
    console.log(this.props.id)
    this.getCareProviderList();
    scrollToTop();
    // this.props.VERIFY_OTP({otp:"111111", emailid:"jayasoorya+03@gmail.com"})
    window.addEventListener("scroll", this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  }

  scrolling = () => {
    let { loading, data, error } = this.props?.careProvider?.allCareProvider;
    let listCount = data?.length ?? 0;
    let totalCount = data?.[0]?.total_count ?? 0;

    if (loading || error || totalCount === 0 || listCount === 0) {
      return false;
    }

    if((Math.round(window.scrollY + window.innerHeight) >= document.getElementsByTagName('body')[0].scrollHeight) && listCount !== totalCount)
     {
      this.setState(
        (state, props) => ({
          pageNo: state.pageNo + 25,
        }),
        () => {
          this.getCareProviderList();
        }
      );
    }
  };


//   parentPartnerId - null - all branches
// parentPartnerId - "" - all Partners
// parentPartnerId - UUID - partcular branches
  getCareProviderList = () => {
    console.log(this.props.auth_data, "auth_datav");
    let _status = undefined
    if(this.state?.status){
      if(this.state.status.toLowerCase() === "Active".toLowerCase()) _status = true
      if(this.state.status.toLowerCase() === "InActive".toLowerCase()) _status = false
    }
    this.props.GET_ALL_CARE_PROVIDER({
      ...this.state,
      filter:{
        category: this.state?.category?.length > 0 ? this.state.category.map((_) => _.value) : [],
        status: _status,
        sort:this.state?.sortDirec?.value,
      },
      offset:this.state?.pageNo,
      limit:this.state?.pageSize,
      // products: this.state.products.map((_) => _.value),
      // sortDirec:
      //   this.state.sort === "az" ? sortDirection.asc : sortDirection.desc,
      // sortfield:
      //   this.state.sort === "az" || this.state.sort === "za"
      //     ? sortFields.provider.name
      //     : sortFields.provider.created_at,
          parentPartnerId:
          routes.partner_management_branch === this.props?.match?.path ? null : "",
          // user_roles.provider === this.props.auth_data.data.user_role
          // ? this.props.auth_data.data.user_id
          // : routes.partner_management_branch === this.props?.match?.path ? null : ""
    });
  };

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  applyFilter = (state) => {
    this.setState(
      {
        ...state, //This is obtained from FilterBuilder Component
        pageNo: 0,
        pageSize: 25,
        isFilterApplied: true,
      },
      () => {
        this.getCareProviderList();
        this.closeFilter();
      }
    );
  };

  resetFilter = () => {
    this.setState(
      {
        ...initialState,
      },
      () => {
        this.getCareProviderList();
        this.closeFilter();
      }
    );
  };

  closeFilter = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  storeInputSearchValue = (value) => {
    this.setState({ careProviderName: value, pageNo: 0, pageSize: 25 }, () => {
      // if (value.length % 3 === 0) {
      this.getCareProviderList();
      // }
    });
  };

  onFilterIconClicked = () => {

    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <FilterBuilder
          components={filterComponent(this.state, this.props)}
          applyButtonLabel="Apply"
          loading={this.props?.careProvider?.allCareProvider?.loading}
          onChange={this.onFilterChange}
          onApplyButtonClicked={this.applyFilter}
          resetFilter={this.resetFilter}
          onCancelClick={this.closeFilter}
          saveButtonId={`saveFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
          cancelButtonId={`cancelFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
          clearAllButtonId={`clearAllFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
        />
      ),
    });
  };

  handleSortChange = (e) => {
    this.setState(
      {
        sort: e,
        pageNo: 0,
        pageSize: 25,
      },
      () => {
        this.getCareProviderList();
      }
    );
  };

  checkIsThereAnyFilter = () => {
    const newState = this.state;

    if (
      newState?.[filterTagType.Category]?.length === 0 &&
      newState?.[filterTagType.Status] === initialState?.status
    ) {
      return false;
    } else return true;
  };

  handleChipDelete = (key, index) => {
    console.log("Key ", key);
    console.log("index ", index);
    if (key === filterTagType.Category) {
      let newState = this.state;
      newState[key].splice(index, 1);
      console.log(newState);

      this.setState(
        {
          ...newState,
          pageNo: 0,
          pageSize: 25,
          isFilterApplied: this.checkIsThereAnyFilter(),
        },
        () => {
          this.getCareProviderList();
        }
      );
    } else if (key === filterTagType.Status) {
      this.setState(
        {
          status: undefined,
          pageNo: 0,
          pageSize: 25,
        },
        () => {
          this.setState({
            isFilterApplied: this.checkIsThereAnyFilter(),
          });
          this.getCareProviderList();
        }
      );
    }
    else if (key === filterTagType.SortDirection) {
      this.setState(
        {
          sortDirec: subHeaderSortOptionWithValue[2],
          pageNo: 0,
          pageSize: 25,
        },
        () => {
          this.setState({
            isFilterApplied: this.checkIsThereAnyFilter(),
          });
          this.getCareProviderList();
        }
      );
    }
  };

  //Error Messages
  giveMeEmptyMessage = () => {
    if (this.state.careProviderName && this.state.isFilterApplied) {
      return errorMessage.notFoundFilterSearch;
    } else if (this.state.careProviderName) {
      return errorMessage.notFoundSearch;
    } else if (this.state.isFilterApplied) {
      return errorMessage.notFoundFilter;
    } else {
      return routes.partner_management_branch === this.props?.match?.path ? "You don't have any branches yet":"You don't have any partners yet";
    }
  };

  mapper = (data = []) => {
    // REMOVING BRANCHES FOR PROVIDER LOGIN
    let newArray;
    if (data?.length > 0) {
      newArray = data.map((val) => {
        if (user_roles.provider === this.props.auth_data.data.user_role) {
          let newOrderList = val.orderList.filter(
            (val) => val.name !== "Branches"
          );


          val = { ...val, orderList: newOrderList };
        }
        return val;
      });

      // Intechanging "parent" key to "care_provider" for the purpose of the Catalogue component.
      // Needed only for Branch route.
      if(this.props.match.path === routes.partner_management_branch){
        newArray = newArray?.map((provider) => {
          return ({...provider, care_provider: [{...provider?.parent}]})
        })
      }
    }
    return newArray;
  };

  render() {
    let { loading, data, error } = this.props?.careProvider?.allCareProvider;

    // const {classes} = this.props
    return (
      <div style={{ padding: "25px" }}>
        <Grid>
          <Grid item xs={12}>
            <SubHeader
              isbutton={
                (this.props.auth_data.data.user_role !== user_roles.provider &&
                routes.partner_management_branch === this.props?.match?.path) || this.props.auth_data.data.user_role === user_roles.business_admin
                  ? false
                  : true
              }
              placeholder={`Search for ${entityLabel(
                this.props?.match?.path
              )} Name`}
              // Email and Mobile Number
              button_name={"+ ADD PARTNER"}
              countText={`${totalCountLabel(this.props?.match?.path)} (${
                data?.[0]?.total_count ?? 0
              })`}
              entityName={"Partner"}
              link={
                this.props?.auth_data?.data?.user_role !== user_roles?.provider
                  ? routes?.partner_management_provider_add
                  : routes?.partner_management_branch +
                    "add/" +
                    this.props?.auth_data?.data?.user_id
              }
              handleSearch={this.getCareProviderList}
              handleChangeInputSearch={this.storeInputSearchValue}
              value={this.state.careProviderName}
              useNewFilterIcon
              placeSearchIconToLeft
              isFilterApplied={this.state.isFilterApplied}
              onFilterIconClicked={this.onFilterIconClicked}
              showSortOption={true}
              sort={this.state.sort}
              handleSortChange={this.handleSortChange}
              loading={loading}
              id={this.state?.id}
              // Temporarily hidden
              // hideFilter
              // hideSearch
            />
          </Grid>

          {this.state.isFilterApplied && (
            <Grid item xs={12} style={{ marginTop: 25 }}>
              <FilterTags
                filterData={this.state}
                handleDelete={this.handleChipDelete}
              />
            </Grid>
          )}

          {/*TOTAL COUNT  */}
          {/* <Grid item xs={12}>
            <Typography variant="h6" style={{ paddingTop: 25 }} className={classes.mediumFont}>
             {`${totalCountLabel(this.props?.match?.path)} (${data?.[0]?.total_count ?? 0})`}
            </Typography>
          </Grid> */}

          {!loading && error && (
            <InternatlServerError
              message="Something went wrong, Try Again!"
              onRetry={this.getCareProviderList}
            />
          )}
          {!loading && !error && !data?.length && (
            <NotFoundSection message={this.giveMeEmptyMessage()} />
          )}

          {/* List */}
          {!error && !loading && data && (
            <Grid item xs={12} style={{ paddingTop: "25px" }}>
              {/* <CardList
                list={this.mapper(data)}
                loading={loading}
                componentOrder={1}
                isShowParent={Boolean(
                  this.props.match.path === routes.partner_management_branch
                )}
                sizes={sizes}
                Url={
                  this.props.match.path === routes.partner_management_branch
                    ? `${routes.partner_management_branch}view/`
                    : `${routes.partner_management_provider}view/`
                }
                isCard={true}
                countName="Caregivers"
                hideQuickSummaryLabel
                hideQuickSummary
              /> */}
              <Catalogue
                catalogues={this.mapper(data)}
                redirectUrl={this.props.match.path === routes.partner_management_branch
                  ? `${routes.partner_management_branch}view/`
                  : `${routes.partner_management_provider}view/`}
                loading={loading}
                showProvider={this.props.match.path === routes.partner_management_branch}
                showTrialExpiry={isTrialAccountType() && checkTrialExpiryRole(this.props.auth_data.data.user_role)}
                id={this.props?.id}
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  careProvider: state.careProvider,
  auth_data: state?.authState?.loginAuth,
  clientAuth:state?.clientAuth?.otpVerify
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(ProviderList))));
