import React from "react";
import { makeStyles, SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyle = makeStyles((theme) => ({
  prefix__cls: {
    fill: (props) => props.color
  }
}))

export const ClientAddressIcon = (props) => {
  const classes = useStyle(props)
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
      htmlColor={props.color}
      style={props.style}
    >
      <path
        id="prefix__Path_604"
        d="M12 2a7 7 0 00-7 7c0 5 7 13 7 13s7-8 7-13a7 7 0 00-7-7zM7 9a5 5 0 0110 0c0 2.518-2.656 6.788-5 9.838-2.344-3.05-5-7.32-5-9.838z"
        className={classes.prefix__cls}
        data-name="Path 604"
      />
      <path
        id="prefix__Path_605"
        d="M16 10h-1v2a1 1 0 01-1 1h-1v-3h-2v3h-1a1 1 0 01-1-1v-2H8V9l4-3 4 3z"
        className={classes.prefix__cls}
        data-name="Path 605"
      />
    </SvgIcon>
  )
}

ClientAddressIcon.defaultProps = {
  color: "#9297a5"
}

ClientAddressIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};
