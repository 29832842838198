/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-01-27 20:47:35
 * @modify date 2022-02-28 09:19:36
 */
import PropTypes from "prop-types";
import React from "react";

// Custom Icons
import ApplicationIcon from "./applicationIcon";
import CalendarIcon from "./calendarIcon";
import ClassicCalendarIcon from "./classicCalendarIcon";
import FullscreenIcon from "./fullscreenIcon";
import IdCardIcon from "./idCardIcon";
import IdCardWithClipIcon from "./idCardWithClipIcon";
import PriorityIcon from "./priorityIcon";
import LocationIcon from "./locationIcon";
import HealthCareIcon from "./healthCareIcon";
import CheckIcon from "./checkIcon";
import MultiplyIcon from "./multiplyIcon";
import MaximizeIcon from "./maximizeIcon";
import GpsIcon from "./gpsIcon";
import ZoomInIcon from "./zoomInIcon";
import ZoomOutIcon from "./zoomOutIcon";

/** All icon components that are imported should have 'color' and 'secondaryColor' as optional props. */
const iconTypes = {
  application: ApplicationIcon,
  calendar: CalendarIcon,
  classicCalendar: ClassicCalendarIcon,
  fullscreen: FullscreenIcon,
  idCard: IdCardIcon,
  idCardWithClip: IdCardWithClipIcon,
  priority: PriorityIcon,
  location: LocationIcon,
  healthcare: HealthCareIcon,
  check: CheckIcon,
  multiply: MultiplyIcon,
  maximize: MaximizeIcon,
  gps: GpsIcon,
  zoomIn: ZoomInIcon,
  zoomOut: ZoomOutIcon,
};

// export const icons = Object.keys(iconTypes).reduce((current, item) => {
//   current[item] = `${item}`;
//   return current;
// }, {});

function IconComponent({ name, ...props }) {
  const Icon = iconTypes[name];
  return <Icon {...props} />;
}

export default IconComponent;

IconComponent.propTypes = {
  /** Name of the icon to be rendered. */
  name: PropTypes.string.isRequired,
};
