import React from 'react'

import { withStyles, Grid, Typography, Button } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    background: 'white',
    boxShadow: `0px 0.5px 2px 1px #e0e0e0d6`,
    padding: 15,
    borderRadius: 8
  },
  imageholder: {
    display: 'block',
    margin: 'auto',
    height: 150,
    width: 150
  },
  noImageHolder: {
    display: 'block',
    margin: 'auto',
    height: 150,
    width: 150,
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 4
  },
  deviceName: {
    marginTop: theme.spacing(2)
  },
  deviceAmount: {
    marginTop: theme.spacing(1.5)
  },
  recommendButton: {
    marginTop: theme.spacing(2)
  }
})

class RecommendDetail extends React.Component {
  render() {
    const { classes } = this.props
    const { equipment_name, equipment_image, equipment_code, equipment_price } = this.props
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          {equipment_image ? <img src={equipment_image} alt={equipment_code} className={classes.imageholder} /> : <div className={classes.noImageHolder} > </div>}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center" className={classes.deviceName}>{equipment_name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" align="center" color="secondary" className={classes.deviceAmount}>{equipment_price}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" color="primary" fullWidth className={classes.recommendButton}>Recommend</Button>
        </Grid>
      </Grid>
    )
  }
}


export default withStyles(styles)(RecommendDetail)