import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';


const useStyles = makeStyles((theme)=>({
    checkboxColor: {
        color: theme.palette.ternary.main,
    },
    checkboxColorChecked: {
        color: `${theme.palette.ternary.main} !important`,
    },
    root:{
        '& .MuiFormControlLabel-label':{
            fontWeight:400,
            fontSize:14,
            color:'#878C9A'
        }
    }
}))

export const CustomCheckBox = (props) =>{

    const classes = useStyles(props);

    const {
        id="",
        checked=false,
        handleChange=null,
        label="",
        color="primary",
        isReadOnly=false
    } = props;

    return(
        <div className={classes.root}>
            <FormControlLabel
                // classes={{ label: classes.expiryLabel_root }}
                control={
                <Checkbox
                    color={color}
                    id={`checkbox${id}`}
                    // style={{ margin: "12px 0px 8px 0px" }}
                    checked={checked}
                    onChange={e=>handleChange(e.target.checked)}
                    disabled={isReadOnly}
                    classes={{ root: classes.checkboxColor, checked: classes.checkboxColorChecked }}
                    // name="Present"
                />
                }
                label={label}
            />
        </div>
        )
}

CustomCheckBox.propTypes={
    id:PropTypes.string,
    checked:PropTypes.bool,
    handleChange:PropTypes.func,
    label:PropTypes.string,
    color:PropTypes.oneOf(['primary','secondary','ternary']),
    isReadOnly:PropTypes.bool,
}