import React, { Component } from 'react'
import ActivityAndMembersCard from '../../../../components/availabilityActivityComponents/ActivityAndMembersCard'
import { Divider, Grid, IconButton, Typography } from '@material-ui/core'
import { BackIcon } from '../../../../assets'
import { withAlertAndDialog } from '../../../../HOCs'

class ServiceProfessionalActivity extends Component {

  render() {
    const { data, service_professional, handleBackIconClick } = this.props;

    return (
      <>
        <Grid container>
          <Grid item container>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={handleBackIconClick}>
                <BackIcon color="#9297a5" height={18} width={20} />
              </IconButton>
            </Grid>
            <Grid item style={{ display: 'flex' }}>
              <Typography variant="h6" style={{ padding: 12 }}>{`Activities of ${service_professional?.name ?? "a Service Professional"}`}</Typography>
            </Grid>
          </Grid>
          <Grid item container >
            <Grid item xs={12}>
              {data?.map((item, index) => {
                return (
                  <div key={index}>
                    <div style={{ paddingLeft: 40 }} >
                      <ActivityAndMembersCard
                        title="Appointment for Initial Assessment"
                        timing="08:00 AM - 09:00 AM"
                        name="Austin Santiago"
                        activityStatus="Initial Assessment"
                        activityStatusColor="#28a9c1"
                        hideProfilePicture
                        hideEndIcon
                        padding={2}
                        useAsViewActivityOfSP
                      />
                    </div>
                    <Divider style={{ background: 'rgba(244, 244, 245, 1)' }} />
                  </div>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
}


export default withAlertAndDialog(ServiceProfessionalActivity)