import React from "react";
import {
  // DateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker,
  MobileDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  // makeStyles,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { errorMessage } from "../../utils/errorMessages";
import moment from "moment";

const styles = (theme) => ({
  label: {
    color: theme.palette.text.label,
    textTransform: "uppercase"
  },
  mobilePickerHeader: {
    "& .MuiPickersToolbar-dateTitleContainer": {
      justifyContent: "space-between !important",
      "& h4": {
        color: "white !important"
      }
    }
  },
  root: {
    borderRadius: "8px !important",
    margin: (props) =>
      props.marginTop ? `${props.marginTop}px 0px 8px 0px` : "8px 0px 8px 0px",
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "16px 12px"
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.secondary.light}`,
      borderRadius: 8
    },
    "& .MuiOutlinedInput-root:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`
      }
    }
  },

  textField: {
    marginTop: (props) =>
      props.marginTop ? `${props.marginTop}px` : theme.spacing(2),

    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      // border: '1.8px solid rgba(204,204,204, 0.3)',
      border: "none"
    },
    "& .MuiOutlinedInput-adornedEnd": {
      height: 43.6
    },

    "& .MuiOutlinedInput-root.Mui-disabled ": {
      backgroundColor: "#f2f2f2",
      borderRadius: "8px !important"
    }
  },
  svgColor: {
    fill: theme.palette.secondary.main,
    fontSize: 20
  },
  errorBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.error.light} !important`
    }
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem"
  },
  root2: {
    "& .MuiPickersModalDialog-dialogRoot": {
      "&:focus": {
        outline: "unset !important"
      }
    },
    "& .MuiDialog-scrollPaper": {
      "&:focus": {
        outline: "unset !important"
      }
    }
  }
});

class FromToDateTimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickerOneOpen: false,
      pickerTwoOpen: false,

      initialFromDate: null,
      initialToDate: null,

      fromDateError: false,
      toDateError: false,
      fromDateErrorText: "",
      toDateErrorText: "",

      fErr: false,
      tErr: false
    };
  }

  onFromDateError = (reason, value) => {
    const { fromDateParentErrorStateUpdate = () => {} } = this.props;
    switch (reason) {
      case "invalidDate":
        this.setState(
          {
            fromDateError: true,
            fromDateErrorText: "Invalid date format"
          },
          () => {
            fromDateParentErrorStateUpdate(true);
          }
        );
        break;

      case "disablePast":
        this.setState(
          {
            fromDateError: true,
            fromDateErrorText: "Values in the past are not allowed"
          },
          () => {
            fromDateParentErrorStateUpdate(true);
          }
        );
        break;

      case "maxDate":
        this.setState(
          {
            fromDateError: true,
            fromDateErrorText: `Date should not be after`
            //   ${format(maxDate, "P")}
          },
          () => {
            fromDateParentErrorStateUpdate(true);
          }
        );
        break;

      case "minDate":
        this.setState(
          {
            fromDateError: true,
            fromDateErrorText: `Date should not be before`
            //   ${format(minDate, "P")}
          },
          () => {
            fromDateParentErrorStateUpdate(true);
          }
        );
        break;
      case null:
      default:
        this.setState(
          {
            fromDateError: false,
            fromDateErrorText: ``
            //   ${format(minDate, "P")}
          },
          () => {
            fromDateParentErrorStateUpdate(false);
          }
        );
    }
  };
  onToDateError = (reason, value) => {
    const { toDateParentErrorStateUpdate = () => {} } = this.props;
    switch (reason) {
      case "invalidDate":
        this.setState(
          {
            toDateError: true,
            toDateErrorText: "Invalid date format"
          },
          () => {
            toDateParentErrorStateUpdate(true);
          }
        );
        break;

      case "disablePast":
        this.setState(
          {
            toDateError: true,
            toDateErrorText: "Values in the past are not allowed"
          },
          () => {
            toDateParentErrorStateUpdate(true);
          }
        );
        break;

      case "maxDate":
        this.setState(
          {
            toDateError: true,
            toDateErrorText: `Date should not be after`
            //   ${format(maxDate, "P")}
          },
          () => {
            toDateParentErrorStateUpdate(true);
          }
        );
        break;

      case "minDate":
        this.setState(
          {
            toDateError: true,
            toDateErrorText: `Date should not be before from date & time`
            //   ${format(minDate, "P")}
          },
          () => {
            toDateParentErrorStateUpdate(true);
          }
        );
        break;
      case "maxTime":
        this.setState(
          {
            toDateError: true,
            toDateErrorText: `Time should not be after`
            //   ${format(maxDate, "P")}
          },
          () => {
            toDateParentErrorStateUpdate(true);
          }
        );
        break;

      case "minTime":
        this.setState(
          {
            toDateError: true,
            toDateErrorText: `Time should not be before From date & time`
            //   ${format(minDate, "P")}
          },
          () => {
            toDateParentErrorStateUpdate(true);
          }
        );
        break;
      case null:
      default:
        
        this.setState(
          {
            toDateError: false,
            toDateErrorText: ``
            //   ${format(minDate, "P")}
          },
          () => {
            toDateParentErrorStateUpdate(false);
          }
        );
    }
  };

  handleMinValidation = (selected, isFromDate) => {
    const { fromDateValue = null, toDateValue = null } = this.props || {};

    const a = moment(fromDateValue);
    const b = moment(toDateValue);
    //

    if (Math.sign(moment.duration(a.diff(b)).asMinutes()) === 1) {
      this.setState(
        {
          tErr: true
        },
        () => {
          if (isFromDate) {
            this.props.onFromDateChange(selected);
          } else {
            this.props.onToDateChange(selected);
          }
        }
      );
    } else {
      this.setState(
        {
          tErr: false
        },
        () => {
          if (isFromDate) {
            this.props.onFromDateChange(selected);
          } else {
            this.props.onToDateChange(selected);
          }
        }
      );
    }
  };

  render() {
    const { classes } = this.props;
    const { pickerOneOpen, pickerTwoOpen } = this.state
    const {
      fromDateValue = null,
      toDateValue = null,
      onFromDateChange = () => {},
      onToDateChange = () => {},
      fromDateLabel = "From Date & Time",
      toDateLabel = "To Date & Time",
      onFromDateAccept = () => {},
      // handleCancelDate = () => {},
      onToDateAccept = () => {},
      disabled = false,
      minDateTime: {
        minFromDateTime = new Date("01-01-1900"),
        minToDateTime = new Date("01-02-1900")
      } = {},
      maxDateTime: {
        maxFromDateTime = new Date("12-30-2099"),
        maxToDateTime = new Date("12-31-2099")
      } = {},
      disablePast: {
        disableFromDatePast = false,
        disableToDatePast = false
      } = {},
      disableFuture: {
        disableFromDateFuture = false,
        disableToDateFuture = false
      } = {},
      // inputFormat = this.props.isDate ? "dd/MM/yyyy" : "dd/MM/yyyy hh:mm a",
      customStyle = {},
      mandatoryErrorFromDate = false,
      mandatoryErrorToDate = false,
      id,
      // disableToTime = false,
    } = this.props || {};
    
    let {
      recurrence_from_time_id = "",
      recurrence_to_time_id = "",
    } = this.props || {}

    const {
      fromDateError,
      fromDateErrorText,
      toDateError,
      toDateErrorText
    } = this.state;
    // 
    // console.log(this.props.id)
    if(!recurrence_from_time_id && !recurrence_to_time_id)
    {
      recurrence_from_time_id = id?.recurrence_from_time_id
      recurrence_to_time_id = id?.recurrence_to_time_id
    }
    return (
      <>
        <FormControl
          className={`${classes.root} ${
            fromDateError ? classes.errorBorder : ""
          }`}
        >
          <InputLabel shrink htmlFor="date">
            {fromDateLabel && (
              <div className={classes.label}>{fromDateLabel}</div>
            )}
          </InputLabel>
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            {this.props.isDate ? (
              <MobileDatePicker
                open={pickerOneOpen}
                onOpen={() => this.setState({pickerOneOpen: true, initialFromDate: fromDateValue})}
                onClose={() => {this.setState({pickerOneOpen: false})}}
                onDismiss={() => {this.setState({pickerOneOpen: false}, () => onFromDateChange(this.state.initialFromDate ? fromDateValue : null))}}
                className={classes.mobilePickerHeader}
                value={fromDateValue}
                onChange={(selected) => this.handleMinValidation(selected, true)}
                disabled={disabled}
                minDateTime={minFromDateTime}
                maxDateTime={maxFromDateTime}
                disablePast={disableFromDatePast}
                disableFuture={disableFromDateFuture}
                inputFormat="dd/MM/yyyy"
                onAccept={(dateValue) => {
                  onFromDateAccept(dateValue);
                }}
                onError={this.onFromDateError}
                renderInput={(props) => (
                  <>
                    <TextField
                      {...props}
                      disabled={disabled}
                      variant="outlined"
                      inputProps={{
                        ...props.inputProps,
                        disabled: disabled,
                        placeholder: "",
                        id: fromDateLabel?.replaceAll(" ", ""),
                      }}
                      onError={this.onFromDateError}
                      InputProps={{
                        ...props.InputProps,
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => {
                              document
                                .getElementById(
                                  fromDateLabel?.replaceAll(" ", "")
                                )
                                .click();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            
                            <CalendarTodayIcon color="secondary" />
                          </InputAdornment>
                        )
                      }}
                      autoComplete="off"
                      helperText={null}
                      style={{ ...customStyle }}
                      className={`${classes.textField} ${
                        fromDateError ? classes.errorBorder : ""
                      }`}
                    />

                    {/* REQUIRED DATE ERROR */}
                    {mandatoryErrorFromDate && !fromDateError && (
                      <Typography
                        variant="body1"
                        component="div"
                        className={classes.errorText}
                      >
                        {errorMessage.requiredMessage}
                      </Typography>
                    )}

                    {/* ON ERROR CALLBACK ERRORS */}
                    {fromDateError && (
                      <Typography
                        variant="body1"
                        component="div"
                        className={classes.errorText}
                      >
                        {fromDateErrorText}
                      </Typography>
                    )}
                  </>
                )}
              />
            ) : (
              <MobileDateTimePicker
                open={pickerOneOpen}
                onOpen={() => this.setState({pickerOneOpen: true, initialFromDate: fromDateValue})}
                onClose={() => {this.setState({pickerOneOpen: false})}}
                onDismiss={() => {this.setState({pickerOneOpen: false}, () => onFromDateChange(this.state.initialFromDate ? fromDateValue : null))}}
                className={classes.mobilePickerHeader}
                value={fromDateValue}
                onChange={onFromDateChange}
                disabled={disabled}
                minDateTime={minFromDateTime}
                maxDateTime={maxFromDateTime}
                disablePast={disableFromDatePast}
                disableFuture={disableFromDateFuture}
                inputFormat={"dd/MM/yyyy hh:mm aaa"}
                onAccept={(dateValue) => {
                  onFromDateAccept(dateValue);
                }}
                // onClose = {() => {handleCancelDate()}}
                onError={this.onFromDateError}
                renderInput={(props) => (
                  <>
                    <TextField
                      {...props}
                      disabled={disabled}
                      variant="outlined"
                      id= {recurrence_from_time_id}
                      inputProps={{
                        ...props.inputProps,
                        disabled: disabled,
                        placeholder: "",
                        // id: fromDateLabel?.replaceAll(" ", "")
                        id: recurrence_from_time_id,
                      }}
                      onError={this.onFromDateError}
                      InputProps={{
                        ...props.InputProps,
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => {
                              document
                                .getElementById(
                                  // fromDateLabel?.replaceAll(" ", "")
                                  recurrence_from_time_id
                                )
                                .click();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            
                            <CalendarTodayIcon color="secondary" />
                          </InputAdornment>
                        )
                      }}
                      autoComplete="off"
                      helperText={null}
                      style={{ ...customStyle }}
                      className={`${classes.textField} ${
                        fromDateError ? classes.errorBorder : ""
                      }`}
                    />

                    {/* REQUIRED DATE ERROR */}
                    {mandatoryErrorFromDate && !fromDateError && (
                      <Typography
                        variant="body1"
                        component="div"
                        className={classes.errorText}
                      >
                        {errorMessage.requiredMessage}
                      </Typography>
                    )}

                    {/* ON ERROR CALLBACK ERRORS */}
                    {fromDateError && (
                      <Typography
                        variant="body1"
                        component="div"
                        className={classes.errorText}
                      >
                        {fromDateErrorText}
                      </Typography>
                    )}
                  </>
                )}
              />
            )}
          </LocalizationProvider>
        </FormControl>
        <FormControl
          className={`${classes.root} ${
            toDateError ? classes.errorBorder : ""
          }`}
        >
          <InputLabel shrink htmlFor="date">
            {toDateLabel && <div className={classes.label}>{toDateLabel}</div>}
          </InputLabel>
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            {this.props.isDate ? (
              <MobileDatePicker
                open={pickerTwoOpen}
                onOpen={() => this.setState({pickerTwoOpen: true, initialToDate: toDateValue})}
                onClose={() => {this.setState({pickerTwoOpen: false})}}
                onDismiss={() => {this.setState({pickerTwoOpen: false}, () => onToDateChange(this.state.initialToDate ? toDateValue : null))}}
                className={classes.mobilePickerHeader}
                value={toDateValue}
                onChange={(selected) => this.handleMinValidation(selected, false)}
                disabled={disabled}
                minDateTime={minToDateTime}
                maxDateTime={maxToDateTime}
                disablePast={disableToDatePast}
                disableFuture={disableToDateFuture}
                inputFormat="dd/MM/yyyy"
                onAccept={(dateValue) => {
                  onToDateAccept(dateValue);
                }}
                onError={this.onToDateError}
                renderInput={(props) => (
                  <>
                    <TextField
                      {...props}
                      disabled={disabled}
                      variant="outlined"
                      inputProps={{
                        ...props.inputProps,
                        disabled: disabled,
                        placeholder: "",
                        id: toDateLabel?.replaceAll(" ", "")
                      }}
                      onError={this.onToDateError}
                      InputProps={{
                        ...props.InputProps,
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => {
                              document
                                .getElementById(
                                  toDateLabel?.replaceAll(" ", "")
                                )
                                .click();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            
                            <CalendarTodayIcon color="secondary" />
                          </InputAdornment>
                        )
                      }}
                      autoComplete="off"
                      error={!!this.state.tErr}
                      helperText={ !!this.state.tErr ? errorMessage.minTime : ""}
                      style={{ ...customStyle }}
                      className={`${classes.textField} ${
                        toDateError ? classes.errorBorder : ""
                      }`}
                    />
                    {/* REQUIRED DATE ERROR */}
                    {mandatoryErrorToDate && !toDateError && (
                      <Typography
                        variant="body1"
                        component="div"
                        className={classes.errorText}
                      >
                        {errorMessage.requiredMessage}
                      </Typography>
                    )}

                    {/* ON ERROR CALLBACK ERRORS */}
                    {toDateError && (
                      <Typography
                        variant="body1"
                        component="div"
                        className={classes.errorText}
                      >
                        {toDateErrorText}
                      </Typography>
                    )}
                  </>
                )}
              />
            ) : (
              <MobileDateTimePicker
                open={pickerTwoOpen}
                onOpen={() => this.setState({pickerTwoOpen: true, initialToDate: toDateValue})}
                onClose={() => {this.setState({pickerTwoOpen: false})}}
                onDismiss={() => {this.setState({pickerTwoOpen: false}, () => onToDateChange(this.state.initialToDate ? toDateValue : null))}}
                className={classes.mobilePickerHeader}
                value={toDateValue}
                onChange={onToDateChange}
                disabled={disabled}
                id = {recurrence_to_time_id}
                minDateTime={minToDateTime}
                maxDateTime={maxToDateTime}
                disablePast={disableToDatePast}
                disableFuture={disableToDateFuture}
                inputFormat={"dd/MM/yyyy hh:mm aaa"}
                onAccept={(dateValue) => {
                  onToDateAccept(dateValue);
                }}
                // onClose = {() => {}}
                onError={this.onToDateError}
                renderInput={(props) => (
                  <>
                    <TextField
                      {...props}
                      disabled={disabled}
                      variant="outlined"
                      id = {recurrence_to_time_id}
                      inputProps={{
                        ...props.inputProps,
                        disabled: disabled,
                        placeholder: "",
                        // id: toDateLabel?.replaceAll(" ", "")
                        id: recurrence_to_time_id,
                      }}
                      onError={this.onToDateError}
                      InputProps={{
                        ...props.InputProps,
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => {
                              document
                                .getElementById(
                                  // toDateLabel?.replaceAll(" ", "")
                                  recurrence_to_time_id
                                )
                                .click();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <CalendarTodayIcon color="secondary" />
                          </InputAdornment>
                        )
                      }}
                      autoComplete="off"
                      helperText={null}
                      style={{ ...customStyle }}
                      className={`${classes.textField} ${
                        toDateError ? classes.errorBorder : ""
                      }`}
                    />

                    {/* REQUIRED DATE ERROR */}
                    {mandatoryErrorToDate && !toDateError && (
                      <Typography
                        variant="body1"
                        component="div"
                        className={classes.errorText}
                      >
                        {errorMessage.requiredMessage}
                      </Typography>
                    )}

                    {/* ON ERROR CALLBACK ERRORS */}
                    {toDateError && (
                      <Typography
                        variant="body1"
                        component="div"
                        className={classes.errorText}
                      >
                        {toDateErrorText}
                      </Typography>
                    )}
                  </>
                )}
              />
            )}
          </LocalizationProvider>
        </FormControl>
      </>
    );
  }
}

export default withStyles(styles)(FromToDateTimePicker);

FromToDateTimePicker.propTypes = {
  onFromDateChange: PropTypes.func,
  onToDateChange: PropTypes.func,
  disabled: PropTypes.bool
};
