import {
  Button,
  Divider,
  Grid,
  Typography,
  withStyles,
  // IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions } from "tanyacare-middleware";
import {
  DateTimePickersCustom,
  HybridTypography,
  InternatlServerError,
  LoadingSection,
} from "../../../../components";
// import ViewServiceProfessionals from './ViewServiceProfessional'
import AvatarStack from "../../../../components/avatarStack";
import { ICONS } from "../../../../components/hybridTypography/typography";
import { Information } from "../../../../components/information";
import ExceptionAccordion from "../../../../components/managedAvailability/exceptions";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { routes } from "../../../../router/routes";
import {
  alertProps,
  converToReactSelect,
  mappingResourceNames,
  options,
  scrollToTop,
  user_roles,
  // user_roles,
} from "../../../../utils";
import {
  actionbuttonUrlBasedOnPersona,
  allResourceAliasLabel,
  allResourceLabel,
  getPersonaId,
  viewScreensBasedOnPersonaByResourceTypeId,
} from "../utils";
// import CardList from "../../../../components/userList";
import DetailsCard from "./detailCard";
import Mapping from "./mapping";
import ViewProviderBranches from "./ViewProviderBranches";
// import AvatarStack from '../../../../components/avatarStack'
// import OperationalHours from '../../../../components/operationalHours'
// import NonOperationalDays from '../../../../components/nonOperationalDays'
import ViewServiceCoordinators from "./ViewServiceCoordinators";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment";
import { MobileDatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isTrialAccountType,checkTrialExpiryRole } from "../../../../utils/common";
// import { Clear as ClearIcon } from "@material-ui/icons";
// const sizes = {
//   md: 12,
//   sm: 6,
//   xs: 12,
// };

const styles = (theme) => ({
  sectionContainer: {
    padding: 25,
    background: "white",
  },
  middleSectionContainer: {
    padding: "10px 25px",
    background: "white",
  },
  mainSectionContainer: {
    padding: "10px 0px",
    background: "white",
  },
  bottomSectionContainer: {
    padding: "10px 25px 20px",
    background: "white",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  sectionColumnTwo: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4),
    },
  },
  dataContainer: {
    padding: 24,
    [theme.breakpoints.down("xs")]: {
      padding: 16,
    },
  },
  accordions: {
    marginTop: 20,
    background: "white",
    borderRadius: 8,
  },
  calendarIconRoot: {
    fontSize: "1.05rem",
  },
  endDateClear: {
    color: theme.palette.ternary.main,
  },
});

class ResourceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionError: false,
      // Activation states
      effectiveFrom: null,
      endsOn: null,
      isBusinessAdmin : false,
    };
  }
  componentDidMount() {
    this.getCareProviderDetail();
    if (this.props?.match?.path === routes.view_care_givers)
      this.getOperationalExceptionData();
    scrollToTop();
    this.setState({isBusinessAdmin: this.props?.auth_data?.data?.user_role === user_roles.business_admin})
  }

  getOperationalExceptionData = () => {
    console.log(this.props?.auth_data);
    try {
      this.props.GET_OPERATIONAL_HOURS({
        userId: this?.props?.match?.params?.id,
        isExceptionNeeded: true,
      });
      this.setState({
        actionError: false,
      });
    } catch (err) {
      this.setState({
        actionError: true,
      });
    }
  };
  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.getCareProviderDetail();
      scrollToTop();
    }
  }
  getCareProviderDetail = async () => {
    const id = this.props?.match?.params?.id;

    await this.props.GET_PERSONA_INFO({ resoureId: id });

    const { data } = this.props?.personaResourceInfo;
    const effectiveFrom =
      data[0]?.resourceMeta?.["resource_validity"]?.value?.[
        data[0]?.resourceMeta?.["resource_validity"]?.key?.["startDate"]
      ];
    const endsOn = Boolean(
      data[0]?.resourceMeta?.["resource_validity"]?.value?.[
        data[0]?.resourceMeta?.["resource_validity"]?.key?.["endDate"]
      ]
    )
      ? data[0]?.resourceMeta?.["resource_validity"]?.value?.[
          data[0]?.resourceMeta?.["resource_validity"]?.key?.["endDate"]
        ]
      : null;
    this.setState({
      effectiveFrom,
      endsOn,
    });
  };

  // onDeactivateClicked = () => {
  //   const Oldstatus = this.props?.personaResourceInfo?.data?.[0]?.isActive;

  //   let status = "Activate";
  //   if (Oldstatus) {
  //     status = "Deactivate";
  //   }
  //   this.props.dialog.setDialog({
  //     open: true,
  //     title: "Are you sure?",
  //     body:
  //       "Do you want to " +
  //       status +
  //       " " +
  //       personaName(this.props?.match?.path) +
  //       " : " +
  //       this?.props?.personaResourceInfo?.data?.[0]?.resourceName,
  //     positiveBtn: status,
  //     negativeBtn: "Cancel",
  //     onOk: this.deactiveUser,
  //   });
  // };

  // deactiveUser = () => {
  //   const id = this.props?.match?.params?.id;
  //   const Oldstatus = this.props?.personaResourceInfo?.data?.[0]?.isActive;
  //   // let newStatus = true;
  //   // if (Oldstatus === true) {
  //   //   newStatus = false;
  //   // }
  //   this.props.dialog.setDialog({ open: false });

  //   this.props.UPDATE_PERSONA_RESOURCE({
  //     resourceId: id,
  //     isActive: !Oldstatus,
  //   });
  //   // this.props.UPDATE_CARE_PROVIDER_STATUS({ id, status: !Oldstatus });
  // };

  handleResend = async () => {
    const id = this.props?.match?.params?.id;
    let role = "resource";
    let data = await this.props.RESEND_INVITE({ user_id: id, role });
    if (data?.payload?.state === 200) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Invite Sent successfully!",
        severity: alertProps.severity.success,
      });
    } else {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Something went wrong! Unable to invite.",
        severity: alertProps.severity.error,
      });
    }
  };

  giveMeNecessaryData = () => {
    const { location, personaResourceInfo } = this.props;

    const { data } = personaResourceInfo;

    let necessaryData = {
      ...personaResourceInfo,
    };

    if (location?.state) {
      necessaryData = {
        ...location.state,
        ...personaResourceInfo,
      };
    }

    if (data) {
      const _ = data[0];

      console.log(_?.resourceMeta?.first_name_last_name?.value?.salutation);

      const header = [
        {
          id: _?.resourceId ?? "",
          name: _?.resourceName ?? "",
          salutation: _?.resourceSalutation ?? "",
          category: _?.resourceCategory
            ? converToReactSelect(
                _?.resourceCategory,
                "categoryId",
                "categoryName"
              )
            : [],
          isactive: _?.isActive,
          emailVerified: _?.emailVerified,
          profile_pic: _?.["resourceMeta"]?.["uploadImage"]?.["value"] ?? "",
          dob:
            routes.view_other_resources === this.props?.match?.path
              ? null
              : _?.["resourceMeta"]?.["date_of_birth"]?.["value"] ?? null,
          gender: _?.["resourceMeta"]?.["gender"]?.["value"] ?? null,
          role: _?.role,
          aliasId: _?.aliasId,
          aliasLabel: allResourceAliasLabel(this.props?.match?.path),
          trialExpiry:
            _?.resourceMeta?.resource_validity?.value?.resourceEndsOn ?? "",
        },
      ];

      // const footer = [
      //   {
      //     name: `${_.care_giver_count ? _.care_giver_count : "0"} Service Professionals`,
      //   },
      // ];

      necessaryData = {
        ...personaResourceInfo,
        header,
        // footer,
      };
    }

    return necessaryData;
  };

  handleAddServiceProfessional = () => {
    let { data } = this.giveMeNecessaryData();
    this.props.history.push({
      pathname: routes.add_care_givers,
      state: {
        provider: { label: data.name, value: data.id },
        category: data?.category,
      },
    });
  };
  shouldComponentUpdate(nextProps, nextState) {
    const { updateCareProviderStatus } = nextProps;
    const oldUpdateCareProviderStatus = this.props.updateCareProviderStatus;

    if (
      oldUpdateCareProviderStatus?.loading !== updateCareProviderStatus?.loading
    ) {
      if (
        !updateCareProviderStatus.loading &&
        !updateCareProviderStatus.error
      ) {
        this.getCareProviderDetail();
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Care Provider status updated successfully!",
          severity: alertProps.severity.success,
        });
      } else if (updateCareProviderStatus.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to update.",
          severity: alertProps.severity.error,
        });
      }
    }

    // assign coordinator to professional
    const { mapCoordinatorToProf } = nextProps;
    const oldmapCoordinatorToProf = this.props.mapCoordinatorToProf;

    if (oldmapCoordinatorToProf?.loading !== mapCoordinatorToProf?.loading) {
      if (!mapCoordinatorToProf.loading && !mapCoordinatorToProf.error) {
        this.getCareProviderDetail();
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `${
            routes.view_care_coordinators === this.props.match.path
              ? "Professional Assigned successfully!"
              : "Coordinator Assigned successfully!"
          }`,
          severity: alertProps.severity.success,
        });
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          open: false,
          component: <></>,
        });
      } else if (mapCoordinatorToProf.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Assign Coordinator.",
          severity: alertProps.severity.error,
        });
      }
    }

    // STATUS UPDATE
    const { updatePersonaResource } = nextProps;
    const oldupdatePersonaResource = this.props.updatePersonaResource;
    //Checking for Add resource messages
    if (oldupdatePersonaResource?.loading !== updatePersonaResource?.loading) {
      if (!updatePersonaResource?.loading && !updatePersonaResource?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `Status updated successfully!`,
          severity: alertProps.severity.success,
        });
        this.getCareProviderDetail();
      } else if (updatePersonaResource.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `Something went wrong! Unable to update status ${allResourceLabel(
            this.props?.match?.path
          )}.`,
          severity: alertProps.severity.error,
        });
      }
    }

    const { unmapCoordinatorToProf } = nextProps;
    const oldunmapCoordinatorToProf = this.props.unmapCoordinatorToProf;
    if (
      oldunmapCoordinatorToProf?.loading !== unmapCoordinatorToProf?.loading
    ) {
      if (!unmapCoordinatorToProf.loading && !unmapCoordinatorToProf.error) {
        this.getCareProviderDetail();
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `${
            routes.view_care_coordinators === this.props.match.path
              ? "Professional Unassigned successfully!"
              : "Coordinator Unassigned successfully!"
          }`,
          severity: alertProps.severity.success,
        });
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          open: false,
          component: <></>,
        });
      } else if (unmapCoordinatorToProf.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Assign Coordinator.",
          severity: alertProps.severity.error,
        });
      }
    }

    // Update Activation/Deactivation dates
    const { updateResourceInfo } = nextProps;
    const oldUpdateResourceInfo = this.props.updateResourceInfo;

    if (oldUpdateResourceInfo?.loading !== updateResourceInfo?.loading) {
      if (!updateResourceInfo.loading && !updateResourceInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `${allResourceLabel(
            this.props?.match?.path
          )} updated successfully!`,
          severity: alertProps.severity.success,
        });
        // this.props.history.push(
        //   `${redirectURL(this.props?.match?.path)}/view/${
        //     this.props?.match?.params?.id
        //   }`
        // );
        this.props.dialog.setDialog({ open: false });
      } else if (updateResourceInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: updateResourceInfo?.message
            ? updateResourceInfo?.message
            : `Something went wrong! Unable to update ${allResourceLabel(
                this.props?.match?.path
              )}.`,
          severity: alertProps.severity.error,
        });
        this.revertDateChanges();
      }
    }

    return true;
  }

  revertDateChanges = () => {
    let { effectiveFrom, endsOn } = this.state;
    const { data } = this.props?.personaResourceInfo;
    const effectiveFromDb =
      data[0]?.resourceMeta?.["resource_validity"]?.value?.[
        data[0]?.resourceMeta?.["resource_validity"]?.key?.["startDate"]
      ];
    const endsOnDb =
      data[0]?.resourceMeta?.["resource_validity"]?.value?.[
        data[0]?.resourceMeta?.["resource_validity"]?.key?.["endDate"]
      ];

    if (!moment(effectiveFrom)?.isSame(effectiveFromDb)) {
      effectiveFrom = effectiveFromDb;
    }
    if (!moment(endsOn)?.isSame(endsOnDb)) {
      endsOn = endsOnDb;
    }

    this.setState({
      effectiveFrom,
      endsOn,
    });
  };

  onClickIcon = (id, isCareGiver, data) => {
    // let _data = data ? data : data?.[0]
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
    this.props.history.push(
      `${viewScreensBasedOnPersonaByResourceTypeId(data?.resourceTypeId)}${id}`
    );
  };

  onEndIconClickHandler = (id, isCareGiver, data) => {
    let _data = data?.value ? data?.value : data;
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
    this.props.history.push(
      `${viewScreensBasedOnPersonaByResourceTypeId(
        _data?.[0]?.resourceTypeId
      )}${id}`
    );
  };

  onRemoveAssigned = (id) => {
    let is_professional;
    let professional_id;
    let coordinator_id;
    // console.log(this.props.match.path.includes('care_giver'))
    if (this.props.match.path.includes("care_giver")) {
      is_professional = true;
      coordinator_id = id;
      professional_id = this.props.personaResourceInfo.data[0].resourceId;
    } else {
      is_professional = false;
      professional_id = id;
      coordinator_id = this.props.personaResourceInfo.data[0].resourceId;
    }
    // console.log(this.props.personaResourceInfo.data[0].resourceId)
    this.props.UN_MAP_COORD_TO_PROF({
      professional_id,
      is_professional,
      coordinator_id,
    });

    // alert("Removed ")
  };

  handleServiceProfessionalsViewAllClick = (data) => {
    // console.log(this.props.match.path.includes('supervisor'))
    const id = this.props?.match?.params?.id;

    if (this.props.match.path.includes("supervisor")) {
      console.log("SUPPERVISOR");
      
      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: true,
        component: (
          <ViewProviderBranches
            {...this.props}
            id={`ViewMappedResourcesFrom${id ? id : ""}`}
            data={data?.value ?? []}
            label={data?.label}
            drawer={this.props.drawer}
            onEndIconClickHandler={this.onClickIcon}
            // onRemoveAssigned={this.onRemoveAssigned}
            showRemoveAssigned={this.state.isBusinessAdmin ? false : true}
            persona_details={this.props.personaResourceInfo.data}
          />
        ),
      });
    } else {
      if (data.label === "Service Supervisor") {
        console.log("SUPERViSOR ZOYA");
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          open: true,
          component: (
            <ViewProviderBranches
              {...this.props}
              id={`ViewMappedResourcesFrom${id ? id : ""}`}
              data={data?.value ?? []}
              label={data?.label}
              drawer={this.props.drawer}
              onEndIconClickHandler={this.onClickIcon}
              // onRemoveAssigned={this.onRemoveAssigned}
              showRemoveAssigned={this.state.isBusinessAdmin ? false : true}
              persona_details={this.props.personaResourceInfo.data}
            />
          ),
        });
      } else {
        console.log("SUPPERVISOR MOTI");
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          open: true,
          component: (
            <ViewProviderBranches
              {...this.props}
              id={`ViewMappedResourcesFrom${id ? id : ""}`}
              data={data?.value ?? []}
              label={data?.label}
              drawer={this.props.drawer}
              onEndIconClickHandler={this.onClickIcon}
              onRemoveAssigned={this.onRemoveAssigned}
              showRemoveAssigned={this.state.isBusinessAdmin ? false : true}
              persona_details={this.props.personaResourceInfo.data}
            />
          ),
        });
      }
    }
  };

  handleServiceCoordinatorsViewAllClick = (data) => {
    console.log("COORDINATOR");
    const id = this.props?.match?.params?.id;
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewServiceCoordinators
          id={`ViewMappedResourcesFrom${id ? id : ""}`}
          data={data?.service_coordinators ?? []}
          drawer={this.props.drawer}
          onEndIconClickHandler={this.onEndIconClickHandler}
          // onRemoveAssigned={this.onRemoveAssigned}
        />
        // <></>
      ),
    });
  };

  onMappingSaveButtonClicked = (error, data) => {
    if (error) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      let requestPayload = {};
      if (routes.view_care_coordinators === this.props.match.path) {
        requestPayload["professionalId"] = Array.isArray(
          data?.coordinator?.value
        )
          ? data?.coordinator?.map((val) => val.value)
          : data?.coordinator?.value;
        requestPayload["coordinatorId"] = this.props.match.params.id;
      } else {
        requestPayload["coordinatorId"] = Array.isArray(
          data?.coordinator?.value
        )
          ? data?.coordinator?.map((val) => val.value)
          : data?.coordinator?.value;
        requestPayload["professionalId"] = this.props.match.params.id;
      }

      this.props.MAP_COORD_TO_PROF(requestPayload);
    }
  };
  mapResource = () => {
    const { personaResourceInfo } = this.props;

    const { data } = personaResourceInfo;
    // console.log(data)

    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <Mapping
          resourceType={
            routes.view_care_coordinators === this.props.match.path
              ? mappingResourceNames.COORDINATOR
              : mappingResourceNames.PROFESSIONAL
          }
          partnerId={data?.[0]?.resourceMeta?.select_provider?.value?.value}
          onMappingSaveButtonClicked={this.onMappingSaveButtonClicked}
        />
      ),
    });
  };

  handleExceptionEdit = (event, exceptionId) => {
    event.stopPropagation();
    this.props.history.push(routes.edit_exception_main + exceptionId);
  };

  handleExceptionDelete = (event, exceptionId) => {
    event.stopPropagation();
    this.props.DELETE_EXCEPTION({ exceptionId: exceptionId });
  };

  getOpDays = (operationalHours) => {
    if (operationalHours?.length > 0) {
      return operationalHours?.map((x) => x?.days)?.flat();
    }
  };

  getExceptionList = () => {
    let { data: opData } = this.props?.getOperationalHours;
    let { exceptions } = opData;
    let myException = [];
    if (Array.isArray(exceptions) && exceptions?.length > 0) {
      myException = Array.from(new Array(exceptions?.length));

      let exceptionList = exceptions?.map((raw) => ({
        exception: raw?.exceptions,
        id: raw?.id,
      }));

      myException = exceptionList?.map((ex, index) => {
        if (
          ex?.exception?.personas?.data?.[0]?.value ===
          this.props.match.params.id
        ) {
          return {
            days: ex?.exception?.available247
              ? options.daysOfWeek.map((x) => x.value)
              : this.getOpDays(ex?.exception?.operationalHours),
            based_on: "Individuals",
            data: {
              service_professional: {
                label: "SERVICE PROFESSIONAL",
                value: ex?.exception?.personas?.data?.map(
                  (persona) => persona?.label
                ),
              },
            },
            id: ex?.id,
            operationalHours: ex?.exception?.operationalHours,
          };
        } else return null;
      });
    }
    return myException?.filter((x) => x);
  };

  // Effective From & ends on handler //
  handleOnChange = (value, key, handleOnDialogClose = () => {}) => {
    this.setState(
      {
        [key]: value,
      },
      () => handleOnDialogClose()
    );
  };

  handleOnDialogClose = (e) => {
    const { effectiveFrom, endsOn } = this.state;
    const { data } = this.props?.personaResourceInfo;
    const actualData = data?.[0];

    let getIdNameType = getPersonaId(this.props?.match?.path);

    let payload = {
      ...actualData,
      resourceMeta: {
        ...actualData?.resourceMeta,
        resource_validity: {
          ...actualData?.resourceMeta?.resource_validity,
          value: {
            [actualData?.resourceMeta?.resource_validity?.key?.["startDate"]]:
              moment(effectiveFrom)?.startOf("day")?.utc()?.format(),
            [actualData?.resourceMeta?.resource_validity?.key?.["endDate"]]:
              endsOn ? moment(endsOn)?.endOf("day")?.utc()?.format() : "",
          },
        },
      },
      effectiveFrom: moment(effectiveFrom)?.startOf("day")?.utc()?.format(),
      effectiveTo: endsOn ? moment(endsOn)?.endOf("day")?.utc()?.format() : "",
      // EndsOn: endsOn ? moment(endsOn)?.endOf("day")?.utc()?.format() : "",
      resourceCategory: actualData?.resourceMeta?.select_category?.value?.map(
        (cat) => cat?.value
      ),
      partnerId: actualData?.resourceMeta?.select_provider?.value?.value,
      resourceType: [getIdNameType?.id],
      isEffectiveFromToValidation:
        moment(effectiveFrom).isSame(
          actualData?.resourceMeta?.resource_validity?.value?.[
            actualData?.resourceMeta?.resource_validity?.key?.["startDate"]
          ]
        ) &&
        moment(endsOn).isSame(
          actualData?.resourceMeta?.resource_validity?.value?.[
            actualData?.resourceMeta?.resource_validity?.key?.["endDate"]
          ]
        )
          ? false
          : true,
    };

    console.clear();
    console.log(payload);
    if (new Date(effectiveFrom) < new Date(endsOn)) {
      if (actualData?.isActive === false && actualData.emailVerified === true) {
        // IF expiry date is greater than current date we set it as true
        
        if (endsOn > new Date()) {
          payload.isActive = true;
        }
        // if the Effective date is today's date then we set the payload as active
        if(new Date(effectiveFrom)?.getTime() === new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())?.getTime())
        {
          payload.isActive = true;
        }
      }

      this.props.UPDATE_PERSONA_RESOURCE(payload);
    }
    else{
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Effective Date is greater than to Expiry Date",
        severity: alertProps.severity.warning,
      })
    }

    // if (actualData?.isActive === false) {
    //   if (endsOn > new Date()) {
    //     payload.isActive = true;
    //   }
    // }
    // console.clear();
    // console.log(payload);

    // this.props.UPDATE_PERSONA_RESOURCE(payload);
  };

  handleEndsOnClear = () => {
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body: "Do you want to remove expiry date?",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => this.handleOnChange(null, "endsOn", this.handleOnDialogClose),
    });
  };

  render() {
    const { classes, theme } = this.props;
    // let { footer } = this.giveMeNecessaryData()
    let { header, data, error, loading } = this.giveMeNecessaryData();
    // let { inviteloading} = this.props.resendInvite;
    const id = this.props?.match?.params?.id;
    const isBusinessAdmin = this.props?.auth_data?.data?.user_role === user_roles.business_admin

    // const activationData = {
    //   resourceEffectiveFrom: data?.[0]?.resourceMeta?.resource_validity?.value?.resourceEffectiveFrom,
    //   resourceEndsOn: data?.[0]?.resourceMeta?.resource_validity?.value?.resourceEndsOn,
    // }

    console.log(header?.[0]);
    // isActive & emailVerified

    // CTA Builder
    const ctas = [
      {
        label: "Invite",
        id: `ResendButtonFrom${id ? id : ""}`,
        onClickHandler: this.handleResend,
        additionalProps: {
          variant: "outlined",
          display:
            header?.[0]?.emailVerified ||
            header?.[0]?.emailVerified === undefined ||
            header?.[0]?.emailVerified === null
              ? false
              : true,
        },
      },
      // {
      //   label:
      //     (header?.[0]?.emailVerified ||
      //       header?.[0]?.emailVerified === undefined ||
      //       header?.[0]?.emailVerified === null) &&
      //     header?.[0]?.isactive
      //       ? "Deactivate"
      //       : "Activate",
      //   onClickHandler: this.onDeactivateClicked,
      //   id:`ActivateDeactivateButtonFrom${id ? id : ""}`,
      //   additionalProps: {
      //     variant: "outlined",
      //     display:
      //       header?.[0]?.emailVerified ||
      //       header?.[0]?.emailVerified === undefined ||
      //       header?.[0]?.emailVerified === null
      //         ? true
      //         : false,
      //     loading: this.props?.updateCareProviderStatus?.loading,
      //     loadingLabel:
      //       (header?.[0]?.emailVerified ||
      //         header?.[0]?.emailVerified === undefined ||
      //         header?.[0]?.emailVerified === null) &&
      //       header?.[0]?.isactive
      //         ? "Deactivating"
      //         : "Activating",
      //   },
      // },
      {
        label: "Edit Details",
        id: `EditDetailsButtonFrom${id ? id : ""}`,
        onClickHandler: () =>
          this.props.history.push(
            actionbuttonUrlBasedOnPersona(this.props?.match?.path, id)
          ),
        additionalProps: {
          variant: "contained",
          // display: !Boolean(
          //   !header?.[0]?.isactive &&
          //     !Boolean(
          //       this.props?.auth_data?.data?.user_role ===
          //         user_roles.service_coordinator
          //     )
          // ),
          display: true,
        },
      },
      {
        label:
          routes.view_care_coordinators === this.props.match.path
            ? "Assign Professional"
            : "Assign Coordinator",
        onClickHandler: this.mapResource,
        id: `AssignButtonFrom${id ? id : ""}`,
        additionalProps: {
          variant: "contained",
          display:
            (routes.view_care_coordinators === this.props.match.path ||
              routes.view_care_givers === this.props.match.path) &&
            header?.[0]?.isactive &&
            header?.[0]?.emailVerified
              ? true
              : false,
        },
      },
    ];

    // console.log("==============================================")
    // console.log(data)
    // console.log("==============================================")
    // Network call destructors
    let {
      data: opData,
      loading: opLoading,
      error: opError,
    } = this.props?.getOperationalHours;

    let exceptions = opData?.exceptions;
    return (
      <>
        {(error || !data) && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <Typography variant="h6" align="center">
                Something went wrong!
              </Typography>
            </Grid>
            <Grid item>
              <Button
                style={{ marginTop: 16, textAlign: "center" }}
                variant={"outlined"}
                onClick={this.getCareProviderDetail}
              >
                Try Again
              </Button>
            </Grid>
          </Grid>
        )}
        {(this.state.actionError || error) && <InternatlServerError />}
        {loading && <LoadingSection message="Fetching data..." />}
        {!error && !loading && data && (
          <Grid item className={classes.dataContainer}>
            {/* {console.log(this.props.match.path.includes("other_resources"))} */}
            {/* {console.log(user_roles.service_coordinator)} */}
            {/* <CardList
              list={header}
              componentOrder={((routes.view_care_givers === this.props.match.path) || (routes.view_care_coordinators === this.props.match.path) ) ? 5 : 2}
              // componentOrder={2}
              sizes={sizes}
              isonlycard={true}
              isCard={true}
              //   link={routes.partner_management_provider + "edit/" + id}
              link={actionbuttonUrlBasedOnPersona(this.props?.match?.path, id)}
              //   link2={routes.partner_management_branch + "add/" + id}
              isShowEditButton={!data?.[0]?.isActive && !Boolean(this.props?.auth_data?.data?.user_role === user_roles.service_coordinator)}
              canShowButton = {Boolean(this.props?.auth_data?.data?.user_role === user_roles.service_coordinator)}
              // showThirdBtn = {!data?.[0]?.isActive && Boolean(this.props?.auth_data?.data?.user_role === user_roles.service_coordinator)}
              // providerId={{
              //   value: data.id,
              //   label: data.name,
              //   provider_id: data.id,
              // }}
              // data={data[0]}
              onDeactivateClicked={this.onDeactivateClicked}
              showResend={this.props.match.path.includes("other_resources")}
              handleResend={this.handleResend}
              isDeactivating={this.props?.updateCareProviderStatus?.loading}
              handleAddServiceProfessional={this.handleAddServiceProfessional}
              onMapResourceClicked={this.mapResource}
              isSalutationNeeded={true}
              // isSendingReinvite = {inviteloading}
              mapResourceLabel={routes.view_care_coordinators === this.props.match.path ? "Assign Professional" : "Assign Coordinator"}
            /> */}

            <Information
              loading={loading}
              details={header}
              ctas={ctas}
              showctas={isBusinessAdmin ? true : false}
              showTrialExpiry={
                // If it is trial Account Type
                isTrialAccountType() && checkTrialExpiryRole(this.props.auth_data.data.user_role) &&
                // If the url path is not other resource detail (no need to show expiry for other resource listing & detail)
                this.props?.match?.path !== routes.view_other_resources
              }
            />

            <div className={classes.mainSectionContainer}>
              <DetailsCard
                dontshow={["Name"]}
                id={id}
                showkeys={["skills"]}
                data={data}
                loading={loading}
              />
            </div>
            {data?.[0]?.otherInfo?.length > 0 && (
              <>
                <Divider style={{ background: "rgba(244, 244, 245, 1)" }} />
                <div className={classes.middleSectionContainer}>
                  {
                    <Grid container>
                      {data[0]?.otherInfo.map((val, index) => {
                        if (Object.keys(val).length > 0) {
                          return (
                            <Grid item xs={12} sm={6} md={4}>
                              <HybridTypography
                                icon={ICONS.BUSINESS(
                                  theme.palette.primary.main
                                )}
                                text={val?.value?.label}
                                header={val?.label?.toUpperCase() ?? "N/A"}
                                loading={loading}
                                id={`ViewMappedPhoneFrom${index}${
                                  id ? id : ""
                                }`}
                                // islistbottom={true}
                                // islisttop={true}
                                isListMiddle={true}
                              />
                            </Grid>
                          );
                        }
                        return true;
                      })}
                      {data?.[0]?.orderList?.map((val, index) => {
                        // console.log(val)
                        return (
                          <Grid item xs={12} sm={6} md={4}>
                            <div style={{ margin: "8px 0px" }}>
                              <AvatarStack
                                title={val?.label?.toUpperCase()}
                                height={16}
                                width={16}
                                style={{ position: "relative", top: 2 }}
                                data={val?.value}
                                emptyMessage="Not yet added"
                                loading={loading}
                                onAvatarClicked={(id, data) =>
                                  this.onClickIcon(id, false, data)
                                }
                                id={`ViewMappedResourcesFrom${index}${
                                  id ? id : ``
                                }`}
                                onButtonClickHandler={() =>
                                  this.handleServiceProfessionalsViewAllClick(
                                    val
                                  )
                                }
                              />
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  }
                </div>
              </>
            )}

            {/* Activation Details */}
            {routes.view_other_resources !== this?.props?.match?.path && (
              <>
                <Divider style={{ background: "rgba(244, 244, 245, 1)" }} />
                <div className={classes.bottomSectionContainer}>
                  <Grid container>
                    {/* Created on */}
                    <Grid
                      item
                      sm={6}
                      md={4}
                      xs={4}

                      // className={classes.sectionContainer}
                    >
                      <HybridTypography
                        icon={
                          <CalendarTodayIcon
                            classes={{ root: classes.calendarIconRoot }}
                            fontsize={"small"}
                            htmlColor={theme.palette.primary.main}
                            style={{
                              "font-size": "1.05rem !important",
                              marginTop: 6,
                            }}
                          />
                        }
                        loading={loading}
                        text={
                          data[0]?.createdAt
                            ? moment(data[0]?.createdAt)?.format("DD/MM/YYYY")
                            : "N/A"
                        }
                        header="CREATED ON"
                      />
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      md={4}
                      xs={4}
                      // className={classes.sectionContainer}
                    >
                      <HybridTypography
                        icon={
                          <CalendarTodayIcon
                            classes={{ root: classes.calendarIconRoot }}
                            fontsize={"small"}
                            htmlColor={theme.palette.primary.main}
                            style={{
                              "font-size": "1.05rem !important",
                              marginTop: 6,
                            }}
                          />
                        }
                        loading={loading}
                        // text={effectiveFrom ? moment(effectiveFrom)?.format("DD/MM/YYYY") : "N/A"}
                        text={
                          <DateTimePickersCustom
                            variant={"mobile"}
                            id={id ? id : ''}
                            value={this.state.effectiveFrom}
                            handleOnChange={(value) =>
                              this.handleOnChange(value, "effectiveFrom")
                            }
                            isDate
                            withoutLabel
                            hidden
                            dontCloseOnChange
                            handleOnDialogClose={this.handleOnDialogClose}
                            width={"80%"}
                            disabled={isBusinessAdmin ? true : false}
                          />
                        }
                        isTextAComponent={true}
                        header="EFFECTIVE DATE"
                      />
                    </Grid>

                    <Grid
                      item
                      sm={6}
                      md={4}
                      xs={12}

                      // className={classes.sectionContainer}
                    >
                      <HybridTypography
                        icon={
                          <CalendarTodayIcon
                            classes={{ root: classes.calendarIconRoot }}
                            fontsize={"small"}
                            htmlColor={theme.palette.primary.main}
                            style={{
                              "font-size": "1.05rem !important",
                              marginTop: 6,
                            }}
                          />
                        }
                        loading={loading}
                        // text={endsOn ? moment(endsOn)?.format("DD/MM/YYYY") : "N/A"}
                        text={
                          <div
                            style={{
                              display: "flex",
                              gap: 2,
                              margin: "8px 0px",
                            }}
                          >
                            {/* ExpiryDe */}
                            <DatePicker
                              value={this.state.endsOn}
                              id={`endsOnDatePicker${id ? id : ''}`}
                              handleOnChange={(value) =>
                                this.handleOnChange(value, "endsOn")
                              }
                              disabled={isBusinessAdmin ? true : false}
                              handleOnClose={this.handleOnDialogClose}
                            />
                            {/* <DateTimePickersCustom
                          variant={"mobile"}
                          value={this.state.endsOn}
                          handleOnChange={(value) =>
                            this.handleOnChange(value, "endsOn")
                          }
                          isDate
                          withoutLabel
                          hidden
                          dontCloseOnChange
                          handleOnDialogClose={this.handleOnDialogClose}
                          minDate={this.state.effectiveFrom}
                          width={"50%"}
                          widthBelow400={"70%"}
                        /> */}
                            {this.state.endsOn && (
                              <Button
                                id={`ExpiryDateClearButton${id ? id : ''}`}
                                style={{ height: 35, width: 35 }}
                                className={classes.endDateClear}
                                onClick={this.handleEndsOnClear}
                              >
                                Clear
                              </Button>
                            )}
                          </div>
                        }
                        isTextAComponent={true}
                        header="EXPIRY DATE"
                      />
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
            {
              // <>
              //   <Divider style={{ background: "rgba(244, 244, 245, 1)" }} />
              //   <div className={classes.sectionContainer}>
              //     <Grid container>
              //       {data?.[0]?.orderList?.map(val=>{
              //         return(
              //         <Grid item xs={12} sm={6} md={4}>
              //         <AvatarStack
              //           title={val?.label?.toUpperCase()}
              //           height={16}
              //           width={16}
              //           style={{ position: "relative", top: 2 }}
              //           data={val?.value}
              //           emptyMessage="Not yet added"
              //           loading={loading}
              //           onAvatarClicked={(id) => this.onClickIcon(id, false, val)}
              //           onButtonClickHandler={() =>
              //             this.handleServiceProfessionalsViewAllClick(val)
              //           }
              //         />
              //       </Grid>
              //         )
              //       })
              //       }
              //     </Grid>
              //   </div>
              // </>
            }

            {this.props?.match?.path === routes.view_care_givers &&
              (this.state.actionError || opError) && <InternatlServerError />}
            {this.props?.match?.path === routes.view_care_givers &&
              opLoading &&
              !opError &&
              !this.state.actionError && (
                <LoadingSection message="Fetching data..." />
              )}
            {this.props?.match?.path === routes.view_care_givers &&
              exceptions?.length !== 0 && (
                <Grid container>
                  {/* Map the different exception accordion here */}
                  {/* TODO: While mapping apply class ```accordions``` to the grid items except the first one */}
                  {this.getExceptionList()?.length > 0 && (
                    <Grid item xs={12} style={{ margin: "16px 0px 16px 0px" }}>
                      <Typography variant="h6">Available Hours</Typography>
                    </Grid>
                  )}
                  {this.getExceptionList().map((exception, index) => (
                    <Grid
                      item
                      container
                      className={index !== 0 ? classes.accordions : null}
                    >
                      <Grid container item xs={12}>
                        <Grid item xs={12}>
                          <ExceptionAccordion
                            exceptionId={exception?.id}
                            exceptionData={exception}
                            handleEdit={this.handleExceptionEdit}
                            handleDelete={this.handleExceptionDelete}
                            withCustomBoxShadow={true}
                            isAccordian={false}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
          </Grid>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  personaResourceInfo: state.resourceReducer?.personaResourceInfo,
  updateCareProviderStatus: state.careProvider?.updateCareProviderStatus,
  updatePersonaResource: state?.resourceReducer?.updatePersonaResource,
  mapCoordinatorToProf: state?.careCoordinator?.mapCDToProf,
  unmapCoordinatorToProf: state?.careCoordinator?.unmapCDToProf,
  auth_data: state?.authState?.loginAuth,
  resendInvite: state?.authState?.resendInvite,
  getOperationalHours: state?.operational_hours?.get_operational_hours,
  updateResourceInfo: state?.resourceReducer?.updatePersonaResource,
});

export default connect(
  mapStateToProps,
  actions
)(
  withRouter(
    withAlelrtAndDialog(withStyles(styles, { withTheme: true })(ResourceDetail))
  )
);

const datePickerStyles = makeStyles((theme) => ({
  textField: {
    borderRadius: 4,
    "& .MuiOutlinedInput-root": {
      padding: "8px 12x !important",
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`,
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.secondary.borderColor}`,
      borderRadius: 8,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 12px",
    },
  },
  mobilePicker: {
    "& .MuiPickersToolbar-dateTitleContainer": {
      justifyContent: "space-between !important",
      "& h4": {
        color: "white",
      },
    },
  },
  svgColor: {
    fill: theme.palette.secondary.main,
    fontSize: 20,
  },
  // label: {
  //   color: theme.palette.text.label,
  //   textTransform: "uppercase",
  // },
  errorBorder: {
    //   "& .MuiOutlinedInput-root:hover": {
    //     "& .MuiOutlinedInput-notchedOutline": {
    //       border: `1.8px solid ${theme.palette.error.light} !important`,
    //     },
    // },
    // "& .Mui-focused": {
    //   "& .MuiOutlinedInput-notchedOutline": {
    //     border: `1.8px solid ${theme.palette.error.light} !important`,
    //   },
    // },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.error.light} !important`,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
  root2: {
    "& .MuiPickersModalDialog-dialogRoot": {
      "&:focus": {
        outline: "unset !important",
      },
    },
    "& .MuiDialog-scrollPaper": {
      "&:focus": {
        outline: "unset !important",
      },
    },
  },
  timePickerRoot: {
    "& .MuiPickersClock-amButton": {
      display: "none",
    },
    "& .MuiPickersClock-pmButton": {
      display: "none",
    },
  },
}));

function DatePicker({ value, handleOnChange, handleOnClose, id,  disabled=false }) {
  const classes = datePickerStyles();
  return (
    <>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <MobileDatePicker
          openPickerIcon={<CalendarTodayIcon />}
          PopperProps={{ disablePortal: true }}
          value={value}
          onChange={(newDate) => {
            handleOnChange(newDate);
          }}
          disabled={disabled}
          onClose={handleOnClose}
          inputFormat={"dd/MM/yyyy"}
          disablePast={true}
          renderInput={(props) => {
            return (
              <TextField
                {...props}
                variant="outlined"
                id={id}
                placeholder=" "
                helperText={null}
                autoComplete="off"
                className={classes.textField}
              />
            );
          }}
          // onOpen={(e) => {
          //   handleClose(e);
          // }}
          // minDate={minDate ? minDate : new Date("1900-01-01")}
          // maxDate={new Date()}
          // maxDate={!hideMaxDateLogic ? new Date() : null}

          // onDismiss={() => {
          //   setOpen(false);
          // }}
          // onClose={(e)=>console.log("ON CLOSE", e)}
          // onAccept={(e)=>{console.log(e,"ON ACCEPTED DATE")}}
          // onError={(e) => {
          //   console.log("error-picker", e);
          // }}
          // disableFuture={isHideFutureDate}
          // disabled={disabled}
          // open={open}
          className={classes.mobilePicker}
        />
        {/* {error && (
        <Typography
          variant="body1"
          component="div"
          className={classes.errorText}
        >
          {errorText}
        </Typography>
      )} */}
      </LocalizationProvider>
    </>
  );
}
