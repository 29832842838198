import React from "react";
import { makeStyles} from "@material-ui/core/styles";
// import NativeSelect from "@material-ui/core/NativeSelect";
// import InputBase from "@material-ui/core/InputBase";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Tooltip from '@material-ui/core/Tooltip';

// WITHOUT BORDER

// const BootstrapInputWithOutBorder = withStyles((theme) => ({
//   root: {
//     "& .MuiFormLabel-root": {
//       color: `${theme.palette.secondary.main} !important`,
//     },
//     "& .MuiNativeSelect-select.MuiNativeSelect-select": {
//       paddingLeft: 20,
//       paddingRight: 24,
//       height: "100%",
//     },

//     "& .MuiNativeSelect-icon": {
//       right: "5px !important",
//     },
//   },
//   input: {
//     borderRadius: 8,
//     position: "relative",
//     "& .MuiInputBase-root": {
//       width: "100%",
//     },
//     // backgroundColor: theme.palette.background.paper,
//     // border: `1.8px solid ${theme.palette.secondary.light}`,
//     fontSize: 16,
//     // padding: "8px 5px",
//     padding: "0px", //Updated to fix Mobile ISD Code UI.
//     transition: theme.transitions.create(["border-color", "box-shadow"]),
//     // Use the system font instead of the default Roboto font.
//     fontFamily: [
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       "Roboto",
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(","),
//     "&:focus": {
//       // borderRadius: 8,
//       // border: `1.8px solid ${theme.palette.ternary.light}`,
//       // background: "white",
//       "& svg": {
//         transform: "rotate(180deg) !important",
//       },
//       // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
//     },
//   },
// }))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    // margin: "8px 0px 8px 0px", //Updated to fix Mobile ISD Code UI.
    margin: "0px",
    height: "100%",
    width: "100%",
    "& .MuiInputBase-root": {
      width: "100% !important",
      height: "100%",
    },
  },
  label: {
    color: "rgba(146, 151, 165, 1)",
    textTransform: "uppercase",
    margin: "0px 8px",
  },
  autocomplete:{
    "& fieldset":{
      border:"none"
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']":{
      padding:"0px 9px"
    },

  }

}));


const getValue = (value, options) =>{
  
  if(value && options?.length > 0){
    console.log(options.filter(val=>val.value === value)?.[0])
    return options.filter(val=>val.value === value)?.[0]
  }
  else {
    return value
  }
}



export const SimpleSelect = (props) => {
  
  const classes = useStyles();
  return (
    <div className={classes.margin}>
      {/* <> */}

      {/* <FormControl className={classes.margin}> */}
      {/* <NativeSelect
        id={props.idSelect}
        // value={age}
        // onChange={handleChange}
        
        disabled={props.disabled}
        onChange={props.handleChangeSelect}
        value={props.valuePhoneCode}
        input={
          <BootstrapInputWithOutBorder
            onChange={props.handleChangeSelect}
            value={props.valueMObileNo}
          />
        }
      >
        <option value="" disabled selected="selected">
          {"-"}
        </option>
        {(props?.options ? props?.options : []).map((val) => {
          return <option value={val.value}>{`${val.label}${val?.dial_code ? ` (${val.dial_code})` : "" }`}</option>;
        })}
      </NativeSelect> */}
      {/* {console.log(props?.valuePhoneCode)}
      {console.log(props?.options)} */}
      <Autocomplete
        // id={props.idSelect}
        id={props?.id}
        options={props?.options ? props?.options : []}
        getOptionLabel={(option) => option.label}
        disabled={props.disabled}
        onChange={(event, value) => props.handleChangeSelect(value?.value)}
        defaultValue={getValue(props?.valuePhoneCode, props?.options)}
        value={getValue(props?.valuePhoneCode, props?.options)}
        className={classes.autocomplete}
        disableClearable
        renderOption={(option) => {
          return <div style={{fontSize:'0.85rem', padding:0}}>{`${option.label}`}</div>; //display value
        }}
        renderInput={
          (params) => {
            let value = getValue(props?.valuePhoneCode, props?.options)
            return(
              <Tooltip title={value?.label ? value?.label?.toString() : ""} placement="bottom">
              <TextField
                {...params}
                label=""
                variant="outlined"
                // onChange={props.handleChangeSelect}
                // value={getValue(props?.valuePhoneCode, props?.options)}
                // value={getValue(props?.valuePhoneCode, props?.options)}
                inputProps={{...params?.inputProps,autoComplete: 'no'}}
  
              />
              </Tooltip>
            )
          }
          // <TextField {...params} label="Combo box" variant="outlined" />
        }
      />
      {/* </FormControl> */}
      {/* </> */}
    </div>
  );
};
