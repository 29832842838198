import * as React from "react"
import { makeStyles, SvgIcon } from "@material-ui/core"
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  cls1: (props) => ({
    fill: props.color || "#000000"
  })
})

export function StarEmptyRating(props) {
  const classes = useStyles(props)
  return (
    <SvgIcon width={20} height={20} viewBox="0 0 25.044 24" {...props}>
      <path
        class={classes.cls1}
        d="M5.605 24a1.333 1.333 0 01-1.3-1.628l1.611-7.1-5.461-4.788a1.332 1.332 0 01.755-2.329l7.229-.657 2.86-6.69a1.331 1.331 0 012.448 0l2.852 6.69 7.228.656a1.332 1.332 0 01.757 2.329l-5.464 4.791 1.611 7.1a1.331 1.331 0 01-1.98 1.438l-6.234-3.725-6.23 3.721a1.334 1.334 0 01-.683.189zm6.916-5.521a1.334 1.334 0 01.683.189l5.883 3.519-1.521-6.7a1.331 1.331 0 01.422-1.3l5.159-4.525-6.826-.62a1.33 1.33 0 01-1.106-.807l-2.694-6.307-2.7 6.313a1.326 1.326 0 01-1.1.8l-6.827.62 5.159 4.525a1.328 1.328 0 01.422 1.3l-1.519 6.7 5.883-3.518a1.334 1.334 0 01.683-.189zM8.384 7.627zm8.273 0v-.003zm0 0"
        data-name="star (1)"
      />
    </SvgIcon>
  )
}

StarEmptyRating.propTypes = {
  /** Set color for the icon */
  color: PropTypes.string
}