import React from 'react'
import { withStyles, Grid, Typography, Avatar, Button } from '@material-ui/core'
import moment from 'moment'

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
    padding: 25,
  },
  avatarContainer: {
    marginRight: 10,
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  flexTheButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

class IssueProfileBar extends React.Component {
  render() {
    const { classes } = this.props
    //Data
    const { issued_client_name, issued_time, profile_pic } = this.props.data
    return (
      <Grid container className={classes.root}>
        <Grid item container xs>
          <Grid item>
            <Avatar className={classes.avatarContainer}>{profile_pic ? profile_pic : ''}</Avatar>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ marginTop: 2 }}>{issued_client_name ? issued_client_name : 'N/A'}</Typography>
            <Typography variant="subtitle2" color={"textSecondary"} >{issued_time ? moment(issued_time).format('MMM DD, YYYY') : 'N/A'}</Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.flexTheButton}>
          <Button variant="outlined" color="primary">VIEW PROFILE</Button>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(IssueProfileBar)