/* eslint-disable eqeqeq */
import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  // Switch,
  Typography,
  IconButton,
  withStyles,
} from "@material-ui/core";
import ProfilePicture from "./profilePicture";
import PersonalDetail from "./personalDetail";
// import { UploadProfileImage } from "../";
import {
  ClientMailIcon,
  ClientPhoneIcon,
  ClientAddressIcon,
  KeyIcon,
  // ChainIcon
} from "../../assets";
import SimpleAvatarCard from "../simpleAvatarCard";
import Skills from "./skills";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// import AvatarStack from "../avatarStack";
import { withAlertAndDialog } from "../../HOCs";
import { routes } from "../../router/routes";
import { alertProps, user_roles_name } from "../../utils";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { v4 as uuidv4 } from 'uuid';

// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withRouter } from "react-router-dom";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import IdView from './idView'
// import TaxComponent from "../SelectComponentWithSubComponent/taxComponent";
// import AddEditTax from "./addEditTax";
import { formatDate } from "../../utils/common";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
// import { CustomTypography } from "../niceComponents";
import TaxComponent from "../SelectComponentWithSubComponent/taxComponent";
import ViewAllCards from "../SelectComponentWithSubComponent/tax/viewAllCard";
import { InternatlServerError, LoadingSection } from "../integerateHelper";

const style = (theme) => ({
  gutter: {
    margin: "0px 30px",
  },

  position2faLeft: {
    margin: "30px 0px 30px 30px",
  },

  position2faRight: {
    margin: "30px 30px 30px 0px",
  },

  // Custom Switch Style
  root2fa: {
    marginBottom: 60,
    background: (props) =>
      props.withoutBoxShadow ? "transparent !important" : "white",
    "& .MuiSwitch-track": {
      background: theme.palette.secondary.main,
      borderRadius: 13,
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      // "& .MuiSwitch-track":{
      background: "#33ff33",
      // }
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "white",
    },
    "& .MuiSwitch-root": {
      padding: 6,
    },
  },
  resetPasswordButton: {
    boxShadow: "none !important",
    color: theme.palette.ternary.main,
  },
  accordRoot: {
    boxShadow: "none",
    marginTop: `16px !important`,
    marginBottom: "0px !important",
    "&:before": {
      opacity: "1 !important",
    },
    width: "100%"
  },
  accordianDetailsRoot: {
    padding: "8px 8px 16px 20px"
  },
  accordionSummaryContent: {
    margin: "8px 0px",
  },
  accordionSummaryExpanded: {
    margin: "8px 0px 0px !important"
  },
  iconSize: {
    fontSize: "1.05rem"
  },
  view_more_title: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    color: theme.palette.ternary.main,
    textTransform: "uppercase",
    width: "max-content",
  },
});

class ProfileDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profilePicture: "",
      twoFactorAuthentication: false,
      emailAccordion: null,
      changesMade: false,
      user_role: "",
      showKey: false
    };
  }

  componentDidMount() {
    this.getUserData();
    this.fetchSecretKey();
  }

  fetchSecretKey = () => {
    this.props.FETCH_PARTNER_SECRET_KEY();
  }

  getUserData = async () => {
    let user_data = this?.props?.loginData?.user_role;
    // console.log(this.props.user_data);
    const { data: profileData } = this.props?.profileInfo || {}

    await this.props.GET_LOGIN_USER_INFO(this?.props?.loginData?.user_id)

    if (user_data === "super-admin") {
      this.setState({
        profilePicture: "/images/tanyaCare/Tanya.png",
        user_role: "super-admin"
      });
    }
    else if(user_data === "business-admin"){
      this.setState({
        profilePicture: "/images/tanyaCare/Tanya.png",
        user_role: "business-admin"
      })
    }
    else if (user_data == "provider_admin") {

      let profile_img = `${process.env.REACT_APP_PROTOCAL}://${process.env.REACT_APP_FILE_HOST}/${process.env.REACT_APP_DB}/${profileData?.partnerMeta?.uploadImage?.value}`
      this.setState({
        // profilePicture: this.props.user_data?.partnerMeta?.uploadImage?.value
        profilePicture: profile_img
      })
      this.props.GET_TAX_INTEGRATION({});
    }
    else {
      let profile_img = `${process.env.REACT_APP_PROTOCAL}://${process.env.REACT_APP_FILE_HOST}/${process.env.REACT_APP_DB}/${profileData?.resourceMeta?.uploadImage?.value}`
      this.setState({
        profilePicture: profile_img
      })
    }

  }

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleProfilePictureUpload = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const base64 = await this.convertBase64(file);
      this.setState({
        name: file.name,
        profilePicture: base64,
        changesMade: true
      });
      // console.log(file)
    }
  };

  updateProfilePicture = async (event) => {
    try {

      // let _ = this.props.FILE_UPLOAD(this.state.name, this.state.profilePicture)
    }
    catch (e) { }
  };

  handleProfilePictureRemove = () => {
    this.setState({
      profilePicture: "",
    });
  };

  handle2faToggle = (event) => {
    this.setState({
      twoFactorAuthentication: event.target.checked,
    });
  };

  onProviderClickHandler = (id) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
    this.props.history.push(`${routes.partner_management_provider}view/${id}`);
  };

  handleChange = (panel) => (event, newExpanded) => {
    this.setState({
      emailAccordion: newExpanded ? panel : false,
    });
  };

  handleResetPassword = (id = 0) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
    this.props.history.push(routes.manageResetPassword + id);
  };

  handleAddTax = (list) => {

    const { loginData } = this.props;

    const { data: user_data } = this.props?.profileInfo || {}


    const { data: taxOptions } = this.props?.taxTypeMaster || {}
    const taxTypeOptions = taxOptions?.map(x => ({ ...x, label: x?.name }))

    let data = [];
    if (loginData?.user_role === "provider_admin") {
      data = user_data?.partnerMetav1?.[0];
    }


    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <TaxComponent
          id={loginData?.user_id}
          options={taxTypeOptions}
          list={data?.["tax"]?.value}
          callBackOnSave={this.callBackOnSaveTax}
          profileDrawerProps={{ ...this.props }}
        />
      ),
      open: true,
    });
  }

  onEdit = (editData) => {
    const { loginData } = this.props;
    const { data: user_data } = this.props?.profileInfo || {}
    const { data: taxOptions } = this.props?.taxTypeMaster || {}
    const taxTypeOptions = taxOptions?.map(x => ({ ...x, label: x?.name }))

    let data = [];
    if (loginData?.user_role === "provider_admin") {
      data = user_data?.partnerMetav1?.[0];
    }

    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <TaxComponent
          id={this?.props?.id}
          editData={editData}
          isEdit={true}
          list={data?.["tax"]?.value}
          callBackOnSave={this.callBackOnSaveTax}
          options={taxTypeOptions}
          profileDrawerProps={{ ...this.props }}
        />
      ),
    });
  };

  callBackOnSaveTax = (newTaxData, isDelete) => {

    const { data: user_data } = this.props?.profileInfo || {}

    let meta = []
    meta = user_data?.partnerMetav1?.[0];
    let taxState = meta?.["tax"]?.value

    if (isDelete) {
      let isUpdateData = taxState?.filter(
        (val) => val.value !== newTaxData?.value
      );

      // this.setState({ tax: isUpdateData }, () => {
      //   console.log("tax", this.state.tax);
      // });
      console.log(isUpdateData)
      
      this.saveTaxToDB(Array.isArray(isUpdateData)?.length > 0 ? isUpdateData : [...isUpdateData] )
    } else {
      this.props.drawer.setDrawer({
        component: <></>,
        open: false
      });
      if (newTaxData) {
        if (taxState?.length > 0) {
          let isUpdateData = taxState?.findIndex(
            (val) => val.id === newTaxData?.id
          );
          if (isUpdateData > -1) {
            let _newData = {
              // data contains effective, noExipry, expiry, taxRegistrationNumber.
              ...newTaxData,
              label: newTaxData.tax_name.label,
              value: newTaxData.tax_name.value,
              taxRegistrationNumber: newTaxData.taxRegistrationNumber,
              expiry: newTaxData.noExpiry ? null : newTaxData.expiry,
              id: uuidv4()
            };
            let tax = taxState;
            let newArray = [...tax];
            newArray[isUpdateData] = _newData;
            tax = newArray;
            // this.setState({ tax }, () => {
            //   console.log("tax", this.state.tax);
            // });
            this.saveTaxToDB([...tax])
          } else {
            let _newData = {
              // data contains effective, noExipry, expiry, taxRegistrationNumber.
              ...newTaxData,
              label: newTaxData.tax_name.label,
              value: newTaxData.tax_name.value,
              expiry: newTaxData.noExpiry ? null : newTaxData.expiry,
              id: uuidv4()
            };

            this.saveTaxToDB([...taxState, _newData])
            // this.setState({ tax: [...this.state.tax, _newData] }, () => {
            //   console.log("tax", this.state.tax);
            // });
          }
        } else {
          let _newData = {
            // data contains effective, noExipry, expiry, taxRegistrationNumber.
            ...newTaxData,
            label: newTaxData.tax_name.label,
            value: newTaxData.tax_name.value,
            expiry: newTaxData.noExpiry ? null : newTaxData.expiry,
            id: uuidv4()
          };
          // this.setState({ tax: [...taxState, _newData] });
          this.saveTaxToDB([...taxState, _newData])
        }
      }
    }

  }

  saveTaxToDB = (newState) => {
    const { loginData } = this.props
    const { data: user_data } = this.props?.profileInfo || {}

    let meta = []
    meta = user_data?.partnerMetav1?.[0];
    // let taxState = meta?.["tax"]?.value

    const payload = {
      partnerId: loginData?.user_id,
      partnerMeta: {
        ...meta,
        tax: {
          ...meta?.["tax"],
          value: [...newState]
        }
      }
    }
    this.props.UPDATE_CARE_PROVIDER_TAX_INFO(payload)
    this.props.dialog.setDialog({ open: false });
  }

  // reload = () => {
  //   const { loginData } = this.props
  //   this.props.GET_LOGIN_USER_INFO(loginData?.user_id)
  // }

  shouldComponentUpdate(nextProps) {

    //UPDATE PARTNER TAX
    const { updatePartnerData } = nextProps;
    const oldUpdatePartnerData = this.props.updatePartnerData;

    if (oldUpdatePartnerData?.loading !== updatePartnerData?.loading) {
      if (!updatePartnerData.loading && !updatePartnerData.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: updatePartnerData.message
            ? updatePartnerData.message
            : "Tax updated successfully!",
          severity: alertProps.severity.success,
        });
        this.getUserData()
      } else if (updatePartnerData.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: updatePartnerData?.message ?? "Something went wrong! Unable to Update Tax.",
          severity: alertProps.severity.error,
        });
      }
    }

    return true
  }

  getAddTaxVisibility = () => {
    const { loginData } = this.props;
    const { data: user_data } = this.props?.profileInfo || {}

    const { data: taxOptions=[] } = this.props?.taxTypeMaster || {}
    const taxTypeOptions = taxOptions?.map(x => ({ ...x, label: x?.name }))

    let data = [];
    if (loginData?.user_role === "provider_admin") {
      data = user_data?.partnerMetav1?.[0];
    }
    let list = data?.["tax"]?.value;
    if(!list || list?.length === 0){
      return true
    }
    let selectedList = []
    if (list && list.length > 0) {
      selectedList = list.map(val => val.value)
    }

    if (taxTypeOptions) {
      if (selectedList.length > 0) {
        return taxTypeOptions?.filter(val => selectedList.indexOf(val.value) === -1)?.length > 0 ? true : false
      }
    } else {
      return false
    }
  }


  handleOpenMoreDrawer = () => {
    const { loginData } = this.props;
    const { data: user_data } = this.props?.profileInfo || {}

    let data = [];
    if (loginData?.user_role === "provider_admin") {
      data = user_data?.partnerMetav1?.[0];
    }
    let list = data?.["tax"]?.value;
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewAllCards
          hideAdd={true}
          id={this?.props?.id}
          items={list}
          onEdit={this.onEdit}
          onRemove={this.callBackOnSaveTax}
          profileDrawerProps={{ ...this.props }}
        />
      ),
    });
  }

  handleDeleteDialog = (item, isRemove) => {
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body: `Do you want to delete this Tax: ${item?.label}`,
      positiveBtn: "Delete",
      negativeBtn: "Cancel",
      onOk: () => this.callBackOnSaveTax(item, isRemove),
    });
  }


  render() {
    const { classes, loginData } = this.props;
    const { data: user_data, loading, error } = this.props?.profileInfo || {}

    let data = [];
    if (loginData?.user_role === "provider_admin") {
      data = user_data?.partnerMetav1?.[0];
    } else {
      data = user_data?.resourceMetav1?.[0];
    }
    // const data = user_data?.resourceMetav1?.[0];
    const primary_email = data?.primary_email_address;
    const email = data?.email?.value;
    const primary_phone_number = data?.primary_phone_number;
    const phone = data?.phone?.value;
    const primary_address = data?.address_component?.value;
    const other_address = data?.address_component?.value?.slice(1);
    const id = data?.id_type_multiple?.value
    const skills = data?.skills?.value?.map((x) => {
      return x.label;
    });
    // const phone = data?.phone?.value?.map((x) => {return x.label});
    const provider = data?.select_provider?.value.label;
    // const select_category = data?.select_category?.value?.[0]?.label;

    const currentUserRole = user_roles_name[loginData?.user_role];
    const role = {
      isAdmin: currentUserRole === user_roles_name["super-admin"],
      isProvider: currentUserRole === user_roles_name.provider_admin,
      isSupervisor: currentUserRole === user_roles_name.provider_supervisor,
      isProfessional: currentUserRole === user_roles_name.service_professional,
      isCoordinator: currentUserRole === user_roles_name.service_coordinator,
      isBusinessAdmin: currentUserRole === user_roles_name["business-admin"]
    };
    return (
      <div>
        {loading && !error && <LoadingSection />}
        {!loading && error && <InternatlServerError onRetry={this.getUserData} />}
        {(!loading && !error && user_data) &&
          <Grid container>
            <Grid item container xs={12} justify="center" alignItems="center">
              <ProfilePicture
                profilePicture={this.state.profilePicture}
                handleProfilePictureUpload={this.handleProfilePictureUpload}
                handleProfilePictureRemove={
                  !role.isAdmin ?? this.handleProfilePictureRemove
                }
                loginData={loginData}
                user_data={user_data}
                isEditProfileButtonNeeded={false} // Set it to true to show
              />
            </Grid>
            <Grid item container xs={12} className={classes.gutter}>
              {/* {role.isAdmin && (
              <PersonalDetail
                icon={<ClientMailIcon color="#31b198" />}
                labelName={"EMAIL ADDRESS"}
                labelContent={loginData?.email}
                marginX={16}
                verifyButtonNeeded
              />
            )} */}
              {!role.isAdmin && !role.isBusinessAdmin && (
                <Accordion
                  className={classes.accordRoot}
                  expanded={this.state.emailAccordion === "emailPanel"}
                  onChange={this.handleChange(email?.length > 1 ? "emailPanel" : "")}
                >
                  <AccordionSummary style={{ padding: 0 }} classes={{ content: classes.accordionSummaryContent, expanded: classes.accordionSummaryExpanded }}>
                    <PersonalDetail
                      icon={<ClientMailIcon color="#31b198" />}
                      labelName={primary_email?.label}
                      // labelContent={loginData?.email}
                      labelContent={primary_email?.value}
                      verifyButtonNeeded={false}
                      hideMarginX
                      isEditable={false}
                      isAccordionNeeded={true}
                      accordionVisibility={email?.length > 1 ? "on" : "off"}
                      isAccordionOpen={this.state.emailAccordion === "emailPanel"}
                      loginData={loginData}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      {email?.map((x) => {
                        return (
                          <>
                            <Grid item xs={12}>
                              <PersonalDetail
                                icon={<ClientMailIcon color="#31b198" />}
                                labelName={x?.label}
                                labelContent={x?.email}
                                verifyButtonNeeded={false}
                                hideMarginX={false}
                                marginX={12}
                                isEditable={false}
                                isEmail={true}
                                isAddress={false}
                                rawData={x}
                                user_data={user_data}
                                loginData={loginData}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Divider light />
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}

              {
                role.isBusinessAdmin && (
                  <div style={{margin:" 10% 0"}}>
                  <PersonalDetail
                      icon={<ClientMailIcon color="#31b198" />}
                      labelName={"Primary Email ID"}
                      // labelContent={loginData?.email}
                      labelContent={loginData?.email}
                      verifyButtonNeeded={false}
                      hideMarginX
                      isEditable={false}
                      isAccordionNeeded={true}
                      accordionVisibility={email?.length > 1 ? "on" : "off"}
                      isAccordionOpen={this.state.emailAccordion === "emailPanel"}
                      loginData={loginData}
                    />
                  </div>
                )
              }

              {/* {role.isAdmin && (
              <Grid item xs={12}>
                <Divider light />
              </Grid>
            )} */}

              {/* Phone */}
              {/* {role.isAdmin && (
              <PersonalDetail
                icon={<ClientPhoneIcon color="#31b198" />}
                labelName={"MOBILE"}
                labelContent={"+60-195-5550"}
                marginX={16}
                verifyButtonNeeded
              />
            )} */}

              {!role.isAdmin && !role.isBusinessAdmin && (
                <Accordion
                  className={classes.accordRoot}
                  expanded={this.state.emailAccordion === "phonePanel"}
                  onChange={this.handleChange(phone?.length > 1 ? "phonePanel" : "")}
                >
                  <AccordionSummary style={{ padding: 0 }} classes={{ content: classes.accordionSummaryContent, expanded: classes.accordionSummaryExpanded }}>
                    <PersonalDetail
                      icon={<ClientPhoneIcon color="#31b198" />}
                      labelName={primary_phone_number?.label}
                      labelContent={
                        primary_phone_number?.value?.mobile_code +
                        "-" +
                        primary_phone_number?.value?.mobilenumber
                      }
                      mobileCode={primary_phone_number?.value?.mobile_code}
                      mobileNumber={primary_phone_number?.value?.mobilenumber}
                      verifyButtonNeeded={false}
                      hideMarginX
                      rawData={primary_phone_number}
                      isEditable={false}
                      isPhone={true}
                      isAccordionNeeded={true}
                      accordionVisibility={phone?.length > 1 ? "on" : "off"}
                      isAccordionOpen={this.state.emailAccordion === "phonePanel"}
                      loginData={loginData}
                      user_data={user_data}
                      isPrimary={true}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      {phone?.map((x) => {
                        return (
                          <>
                            <Grid item xs={12}>
                              <PersonalDetail
                                icon={<ClientPhoneIcon color="#31b198" />}
                                labelName={x.label}
                                labelContent={
                                  x.mobileNoIsdCode + "-" + x.mobilenumber
                                }
                                verifyButtonNeeded={false}
                                hideMarginX={false}
                                marginX={12}
                                rawData={x}
                                isEditable={false}
                                isPhone={true}
                                loginData={loginData}
                                user_data={user_data}
                                isPrimary={false}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Divider light />
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}

              {/* {role.isAdmin && (
              <Grid item xs={12}>
                <Divider light />
              </Grid>
            )} */}

              {/* Address */}
              {/* {role.isAdmin && (
              <PersonalDetail
                icon={<ClientAddressIcon color="#31b198" />}
                labelName={"ADDRESS"}
                labelContent={
                  "No. 40 Jalan 20/14, Malaysia, Petaling Jaya, 46300"
                }
                marginX={16}
              />
            )} */}
              {!role.isAdmin && !role.isBusinessAdmin && (
                <Accordion
                  className={classes.accordRoot}
                  expanded={this.state.emailAccordion === "addressPanel"}
                  onChange={this.handleChange(primary_address?.length > 1 ? "addressPanel" : "")}
                >
                  <AccordionSummary style={{ padding: 0 }} classes={{ content: classes.accordionSummaryContent, expanded: classes.accordionSummaryExpanded }}>
                    <PersonalDetail
                      icon={<ClientAddressIcon color="#31b198" />}
                      labelName={primary_address?.[0]?.label}
                      labelContent={`${primary_address?.[0]?.addressLine1},\n ${primary_address?.[0]?.addressLine2},\n ${primary_address?.[0]?.area},\n ${primary_address?.[0]?.state},\n ${primary_address?.[0]?.country},\n ${primary_address?.[0]?.postalCode}`}
                      verifyButtonNeeded={false}
                      isAddress={true}
                      isEditable={false}
                      rawData={primary_address?.[0]}
                      hideMarginX
                      isAccordionNeeded={other_address?.length > 0}
                      isAccordionOpen={
                        this.state.emailAccordion === "addressPanel"
                      }
                      loginData={loginData}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      {other_address?.map((x) => {
                        return (
                          <>
                            <Grid item xs={12}>
                              <PersonalDetail
                                icon={<ClientAddressIcon color="#31b198" />}
                                labelName={x?.label}
                                labelContent={`${x?.addressLine1},\n ${x?.addressLine2},\n ${x?.area},\n ${x?.state},\n ${x?.country},\n ${x?.postalCode}`}
                                verifyButtonNeeded={false}
                                hideMarginX={false}
                                marginX={12}
                                isEditable={false}
                                loginData={loginData}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Divider light />
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {role.isProvider && this.props.secretKey?.length > 0 && this.props.secretKey?.[0] !== "null" ? (
                <Accordion
                  className={classes.accordRoot}
                  expanded={this.state.emailAccordion === "addressPanel"}
                  onChange={this.handleChange(primary_address?.length > 1 ? "addressPanel" : "")}
                >
                  <AccordionSummary style={{ padding: 0 }} classes={{ content: classes.accordionSummaryContent, expanded: classes.accordionSummaryExpanded }}>
                    <PersonalDetail
                      icon={<KeyIcon color="#31b198" />}
                      labelName={"Referral Key"}
                      // labelContent={`${process.env.REACT_APP_PROTOCAL}://${process.env.REACT_APP_ENQUIRY_LANDING_PAGE}?ref=${this.props.secretKey?.[0]}`}
                      labelContent={`${this.props.secretKey?.[0]}`}
                      copyText={`${process.env.REACT_APP_PROTOCAL}://${process.env.REACT_APP_ENQUIRY_LANDING_PAGE}?ref=${this.props.secretKey?.[0]}`}
                      // showTip={!this.state.showKey}
                      showCopy={true}
                      verifyButtonNeeded={false}
                      // onLabelClick={() => this.setState({showKey: true})}
                      isAddress={false}
                      isEditable={false}
                      rawData={null}
                      hideMarginX
                      isAccordionNeeded={false}
                      isAccordionOpen={false}
                      loginData={loginData}
                    />
                  </AccordionSummary>
                </Accordion>
              ): null}
              {/* {role.isAdmin && (
              <Grid item xs={12}>
              <Divider light />
              </Grid>
            )} */}



              {/* ID TYPE - id */}
              {!role.isAdmin && !role.isBusinessAdmin && id?.length > 0 && (
                <Accordion
                  className={classes.accordRoot}
                  expanded={this.state.emailAccordion === "idPanel"}
                  onChange={this.handleChange(id?.length > 1 ? "idPanel" : "")}
                >
                  <AccordionSummary style={{ padding: 0 }} classes={{ content: classes.accordionSummaryContent, expanded: classes.accordionSummaryExpanded }}>
                    <IdView data={id?.[0]} isMoreThanOne={id?.length > 1 ? true : false} isAccordionOpen={this.state.emailAccordion === "idPanel"} />
                  </AccordionSummary>
                  <AccordionDetails classes={{ root: classes.accordianDetailsRoot }}>
                    <Grid container>
                      {id?.map((x, index) => {
                        if (index > 0) {
                          return (
                            <>
                              <Grid item xs={12}>
                                <IdView data={id?.[index]} isChildItem={true} />
                                {/* <PersonalDetail
                                icon={<ClientIDIcon color="#31b198" />}
                                labelName={x?.label}
                                labelContent={<ViewUploadedFile actualFile={null} fileUrl={FILE_URI + "/" + x?.id_photo} fileExtension={x?.id_photo_filetype} applyAlternateStyle={true} viewDialogTitle={x?.label ?? "DOCUMENT"} clickText={"View document"} hideImageIcon={true} profileDrawerStyle={true} />}
                                verifyButtonNeeded={false}
                                hideMarginX={false}
                                marginX={12}
                                rawData={x}
                                isEditable={true}
                                isID={true}
                                loginData={loginData}
                                user_data={user_data}
                                isPrimary={false}
                              /> */}
                              </Grid>
                              {/* <Grid item xs={12}>
                              <Divider light />
                            </Grid> */}
                            </>
                          );
                        } else return <></>
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}

              {/* Provider */}
              {!role.isAdmin && !role.isBusinessAdmin && !role.isProvider && (
                <Grid item xs={12} style={{ margin: "30px 0px" }}>
                  <SimpleAvatarCard
                    title={"PROVIDER"}
                    name={provider}
                    // src={data?.care_provider?.[0]?.profile_pic}
                    // loading={loading}
                    height={16}
                    width={16}
                    fixForProfileDrawer
                    style={{ position: "relative", top: 2 }}
                    id={loginData?.id ?? 1}
                  />
                  {/* <AvatarStack
                title={"PROVIDER"}
                data={loginData?.providerDetails?.map((x) => ({
                  id: x?.provider_id,
                  name: x?.provider_name,
                }))}
                onAvatarClicked={this.onProviderClickHandler}
                height={16}
                width={16}
                emptyMessage="No Providers Mapped"
                style={{ position: "relative", top: 2 }}
                hideViewButton={true}
                // onButtonClickHandler={() =>
                //   this.handleViewAllSupervisorClick(data)
                // }
              /> */}
                </Grid>
              )}


              {/* Skills */}
              {!role.isAdmin && !role.isBusinessAdmin && skills !== undefined && (
                <>
                  <Grid item xs={12}>
                    <Divider light />
                  </Grid>

                  <Grid item container xs={12}>
                    <Skills
                      icon={<ClientPhoneIcon color="#31b198" />}
                      data={skills}
                    />
                  </Grid>
                </>
              )}

              {role.isProvider  && (
                <>
                  <Grid item xs={12}>
                    <Divider light />
                  </Grid>

                  {data?.tax?.value?.length > 0 && <Grid item container style={{ marginLeft: 2, marginTop: 8 }}>
                    <Grid item style={{ display: "flex", alignItems: "center" }}>
                      <PermIdentityIcon fontSize={"small"} htmlColor={"#31b198"} classes={{ fontSizeSmall: classes.iconSize }} />
                    </Grid>
                    <Grid item xs>
                      <Typography style={{ color: "rgba(146, 151, 165, 1)", fontSize: 13, marginLeft: 12, textTranform: "uppercase" }} >Tax Type</Typography>
                      {/* <InputLabel shrink>{this.getTitle(element)}</InputLabel> */}
                    </Grid>
                  </Grid>}
                  <Grid item xs={12}>
                    {data?.tax?.value?.map((item, index) => {
                      if (index < 1) {
                        return (
                          <div className={classes.customListWrapper} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Grid item style={{ display: "flex", alignItems: "center" }}>
                              <PermIdentityIcon fontSize={"small"} htmlColor={"#31b198"} classes={{ fontSizeSmall: classes.iconSize }} style={{ visibility: "hidden", marginRight: 14 }} />
                            </Grid>
                            <div style={{ flex: 1 }}>
                              <Typography variant={"body2"} >{item?.taxRegistrationNumber ? item?.taxRegistrationNumber : "NA"}</Typography>
                              <Typography variant={"body2"} color={"textSecondary"} >{item?.label ? item?.label : null}</Typography>
                              <Typography variant={"body2"} color={"textSecondary"} >{`${item?.effective ? formatDate(item?.effective) : "NA"} - ${item?.expiry ? formatDate(item?.expiry) : "NA"}`}</Typography>
                              {/* <Typography variant={"body2"} color={"textSecondary"} >{`${item?.effective ? formatDate(item?.effective) : "NA"}`}</Typography> */}
                              {/* <Typography variant={"body2"} color={"textSecondary"} >{`${item?.expiry ? formatDate(item?.expiry) : "NA"}`}</Typography> */}
                            </div>
                            <div style={{ height: "100%" }}>
                              <IconButton id={this.props?.id?.edit_tax_icon_id} onClick={() => this.onEdit(item)} edge="end" aria-label="edit" style={{ border: "1px solid #E8E8EC", margin: "0px 4px", padding: "4px" }}>
                                <EditOutlinedIcon fontSize={"small"} />
                              </IconButton>
                              <IconButton id={this.props?.id?.delete_tax_icon_id} onClick={() => { this.handleDeleteDialog(item, true)}} edge="end" aria-label="delete" style={{ border: "1px solid #E8E8EC", margin: "0px 4px", padding: "4px" }}>
                                <DeleteOutlinedIcon fontSize={"small"} />
                              </IconButton>
                            </div>
                          </div>
                        )
                      } else {
                        return <></>
                      }
                    })}

                    {data?.tax?.value?.length > 1 &&
                      <Typography
                        id={this.props?.id?.view_more_tax_icon_id}
                        onClick={() => this.handleOpenMoreDrawer()}
                        className={classes.view_more_title}
                        style={{ cursor: "pointer", paddingTop: 8, width: "100%" }}
                        align="right"
                      >
                        {"VIEW MORE"}
                      </Typography>
                    }
                  </Grid>


                  {this.getAddTaxVisibility() && <Grid item xs={12}>
                    <Button onClick={this.handleAddTax} fullWidth color="primary" variant="contained" style={{ margin: "20px 0px" }} >ADD TAX ID</Button>
                  </Grid>}
                </>
              )
              }

            </Grid>
            {!role.isAdmin && !role.isBusinessAdmin && (
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                className={classes.root2fa}
              >
                <Grid item xs={12}>
                  <Divider light />
                </Grid>

                {/* <Grid item xs className={classes.position2faLeft}>
                <Typography>Two Factor Authentification</Typography>
              </Grid>
              <Grid item className={classes.position2faRight}>
                <Switch
                  disableRipple
                  onChange={this.handle2faToggle}
                  color="secondary"
                  inputProps={{
                    "aria-label": "checkbox with default color",
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider light />
              </Grid> */}
              </Grid>
            )}



            <Grid
              item
              container
              alignItems
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0px 20px",
              }}
            >
              {this.state.changesMade && (<Button
                endIcon={<ChevronRightIcon />}
                className={classes.resetPasswordButton}
                onClick={() => this.updateProfilePicture()}
              >
                Update Profile
              </Button>)}
              {(this.state.user_role !== "super-admin") && (
                <Button
                  endIcon={<ChevronRightIcon />}
                  className={classes.resetPasswordButton}
                  id={"ProfileMenuChangePasswordButtonClick"}
                  onClick={() => this.handleResetPassword(loginData?.user_id)}
                >
                  Change Password
                </Button>
              )}

            </Grid>
          </Grid>
        }

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  addressType: state?.masterSet?.addressType,
  fileUpload: state?.dependency_api?.fileUpload,
  taxTypeMaster: state?.masterTax?.get_tax_integration,
  updatePartnerData: state.careProvider?.partnerTaxInfo,
  profileInfo: state?.profile?.info,
  secretKey: state?.profile?.secretKey?.data
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAlertAndDialog(withStyles(style)(ProfileDrawer))));
