import React from "react";
import ReactDOM from "react-dom";
import { ConfirmationRequired, CustomDialog } from '../'

const UserLeaveConfirmation = (
    message,
    callback,
    confirmOpen,
    setConfirmOpen
) => {

    const container = document.createElement("div");

    container.setAttribute("custom-confirm-view", "");

    const handleConfirm = (callbackState) => {
        ReactDOM.unmountComponentAtNode(container);
        callback(callbackState);
        setConfirmOpen(false);
    };

    const handleCancel = () => {
        ReactDOM.unmountComponentAtNode(container);
        callback();
        setConfirmOpen(false);
    };

    document.body.appendChild(container);
    let parseMessage = () => {
        try {
            return JSON.parse(message);
        } catch (error) {
            return '{}'
        }
    }
    const { title, description, positiveButton, negativeButton } =  parseMessage();
    ReactDOM.render(
        <>
            {
                confirmOpen &&
                <CustomDialog
                    open={confirmOpen}
                    onClose={handleCancel}
                >
                    <ConfirmationRequired
                        title={title}
                        description={description}
                        positiveButton={{
                            name: positiveButton?.name,
                            onClick: handleConfirm
                        }}
                        negativeButton={{
                            name: negativeButton?.name,
                            onClick: handleCancel
                        }}
                    />
                </CustomDialog>
            }
        </>,container
    );
};

export default UserLeaveConfirmation;