import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function LocationIconV2(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.374 5.282A6.084 6.084 0 0012.079 3.5a6.085 6.085 0 00-6.084 6.073c0 1.626 1.037 3.239 2.595 5.162.273.337.558.679.833 1.01l.021.026a6.266 6.266 0 00-1.914.8 2.1 2.1 0 00-1.106 1.7c0 .805.626 1.537 1.765 2.061a10.5 10.5 0 007.768 0c1.138-.524 1.765-1.256 1.765-2.061a2.094 2.094 0 00-1.1-1.691 6.225 6.225 0 00-1.9-.8 46.156 46.156 0 001.6-2.011 7.706 7.706 0 001.834-4.192 6.084 6.084 0 00-1.782-4.295z"
        fill="#fff"
      />
      <path
        d="M9.997 16.435c-1.66.344-2.8 1.085-2.8 1.835 0 .482.481.979 1.32 1.365 2.29.9 4.837.9 7.128 0 .839-.386 1.32-.883 1.32-1.365 0-.744-1.128-1.481-2.77-1.828l-.043.053-.109.134-.136.169-.1.127-.143.18-.1.134-.136.176-.1.137-.14.188-.11.148-.034.046a7.39 7.39 0 00-.191.268l-.053.076-.049.071-.067.1-.04.06-.061.093-.046.07-.1.152a.384.384 0 01-.277.178v0a.364.364 0 01-.049 0v0a.383.383 0 01-.3-.14M12.079 3.5a6.084 6.084 0 016.077 6.077 7.706 7.706 0 01-1.834 4.192 46.156 46.156 0 01-1.6 2.011c.673.16 1.316.43 1.9.8a2.094 2.094 0 011.1 1.691c0 .805-.627 1.537-1.765 2.061a10.5 10.5 0 01-7.768 0c-1.139-.524-1.765-1.256-1.765-2.061a2.1 2.1 0 011.106-1.7 6.266 6.266 0 011.914-.8l-.021-.026c-.275-.331-.56-.673-.833-1.01-1.558-1.923-2.595-3.536-2.595-5.162A6.085 6.085 0 0112.079 3.5zm0 .766a5.318 5.318 0 00-5.313 5.311c0 1.4.969 2.884 2.424 4.68.269.333.552.673.826 1l.425.512.24.291.23.283.221.276a33.219 33.219 0 01.608.793 17.38 17.38 0 01.362.51 52.231 52.231 0 01.606-.835c.067-.091.136-.182.209-.273l.21-.268.21-.263.41-.506.381-.465c.54-.658 1.1-1.338 1.586-2 1.162-1.575 1.68-2.728 1.68-3.738a5.317 5.317 0 00-5.315-5.305v-.003zm0 2.015a3.31 3.31 0 11-3.226 3.308 3.272 3.272 0 013.223-3.308h.003zm0 .766a2.544 2.544 0 102.462 2.542 2.5 2.5 0 00-2.465-2.542h.003z"
        stroke="#0063E6"
      />
    </SvgIcon>
  );
}

export default LocationIconV2;
