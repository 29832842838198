import React from "react";
import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

export const ClientMailIcon = (props) => {
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
      htmlColor={props.color}
      style={props.style}
    >
      <path id="Path_602" d="M2 10.092V18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-7.87l-2 1.245V18H4v-6.654zm2-1.106V6h16v3.02l2-1.245V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v1.732z" class="cls-1" data-name="Path 602" />
      <path id="Path_603" d="M12 11.749L3 6.108v2.251L12 14l9-5.6V6.146z" class="cls-1" data-name="Path 603" />
    </SvgIcon>
  )
}

ClientMailIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};
