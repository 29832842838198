import React, { useState } from "react";
import { Popper, Fade, Paper, makeStyles, Typography } from "@material-ui/core";
import { genericStyles } from "../../utils";

const popperStyle = makeStyles((theme) => ({
  cardPaper: {
    borderRadius: "8px",
    boxShadow: theme.palette.primary.boxShadow,
    maxHeight: 250,
    overflow: "auto",
    minWidth: 100,
  },
  typography: {
    padding: theme.spacing(1),
  },
  secondary: {
    color: "white",
    fontSize: "14px",
    paddingTop: "0px",
  },
  countStyle: {
    // backgroundColor: theme.palette.primary.light,
    textAlign: "center",
    width: "fit-content",
    // padding: "0px 10px",
    marginRight: "0px",
    borderRadius: "3px",
    color: theme.palette.primary.dark,
  },
  ...genericStyles.cardWrapperShadows(theme)
}));

export function PopperListRenderer(props) {
  const classes = popperStyle();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    if (event) {
      setOpen(event.currentTarget);
      event.stopPropagation();
    }
    return true;
  };

  const handleClose = (e) => {
    setOpen(false);
    return true;
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      
      <Typography
        className={`${classes.countStyle} ${classes.secondary}`}
        onMouseEnter={handleOpen}
        style={{ cursor: "default" }}
        onMouseLeave={(e) => {
          if (e?.relatedTarget?.id === "popper") {
            return false;
          } else {
            handleClose();
          }
        }}
      >
        +{props.count}
      </Typography>
      <Popper
        open={open}
        anchorEl={open}
        placement={"bottom-start"}
        transition
        style={{ zIndex: 1000 }}
        onClick={(e) => {
          e.stopPropagation();
          return false;
        }}
        onMouseLeave={handleClose}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={`${classes.cardPaper} ${classes.cardWrapperShadows}`}>
              {props.popperList.map((val) => {
                return (
                  <Typography
                    id={`popper`}
                    className={classes.typography}
                    style={{ cursor: "default" }}
                  >
                    {val}
                  </Typography>
                );
              })}
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
