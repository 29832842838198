import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { CustomizedRadioButton, SimpleSelect } from "../inputs";
import FromToDateTimePicker from "../fromToDatePicker";
// import { DateRangePicker } from "../datePicker";
import FromToTimePicker from "../fromToTimePicker";
// import { DateTimePickersCustom } from "../dateTimePicker";
import moment from "moment";
const style = (theme) => ({});

const initialState = {
  startDate: null,
  startDateMin: moment()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toDate(),
  // startDateMax: null,
  endDate: null,
  endDateMin: null, //handle null in place of sending props
  // endDateMax: null,
  startTime: null,
  startTimeMin: moment()
    .set({
      hour: 0,
      minute: moment().get("minute") - 1,
      second: 0,
      millisecond: 0,
    })
    .toDate(),
  // startTimeMax: null,
  endTime: null,
  endTimeMin: moment()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toDate(),
  // endTimeMax: null,
  timeDifferenceError: false, //currently going to check 30 min difference
};
class ActivityRecurrence extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recurrence: null,
      ...initialState,
    };
  }

  componentDidMount() {
    // isEdit={component?.isEdit}
    //         editData={component?.editData}
    this.setState({
      recurrence: this.props.values.recurrenceValue,
    }, () => {
      if(this.state.recurrence === "WEEKLY"){
        this.setState({
          startTimeMin: null,
          endTimeMin: null
        })
      }
    });
    if (this.props.isEdit) {
      // this.setState({
      //   recurrence: "DO NOT REPEAT"
      // })
      this.setInitialState();
    }
  }

  setInitialState = () => {
    const { editData } = this.props;
    const { from_time_stamp, to_time_stamp } = editData;
    let from = moment(from_time_stamp).toDate();
    let to = moment(to_time_stamp).toDate();

    this.setState({
      startDate: moment(from)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
      endDate: moment(to)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
      startTime: moment(from).toDate(),
      endTime: moment(to).toDate(),
    });
  };

  recurrenceStateChange = (selectedRecurrence, key) => {
    const {
      handleRecurrenceChange,
      handleDnrFromDateChange,
      handleDailyFromDateChange,
      handleWeeklyFromDateChange,
      handleDnrToDateChange,
      handleDailyToDateChange,
      handleWeeklyToDateChange,
      dailyStartChange,
      weeklyStartChange,
      dailyEndChange,
      weeklyEndChange,
    } = this.props.onChanges;
    this.setState(
      {
        recurrence: selectedRecurrence,
      },
      () => {
        this.setState({
          ...initialState,
        });
        if(this.state.recurrence === "WEEKLY"){
          this.setState({
            startTimeMin: null,
            endTimeMin: null
          })
        }
        // resetting parent states
        if (!this.props.isEdit) {
          if (this.state.recurrence === "DO NOT REPEAT") {
            handleDnrFromDateChange(null);
            handleDnrToDateChange(null);
          } else if (this.state.recurrence === "DAILY") {
            handleDailyFromDateChange(null);
            handleDailyToDateChange(null);
          } else if (this.state.recurrence === "WEEKLY") {
            handleWeeklyFromDateChange(null);
            handleWeeklyToDateChange(null);
          }
        } else {
          handleDnrFromDateChange(null);
          handleDnrToDateChange(null);
        }
        dailyStartChange(null);
        weeklyStartChange(null);
        dailyEndChange(null);
        weeklyEndChange(null);

        handleRecurrenceChange(selectedRecurrence, key);
      }
    );
  };

  handleStartDateChange = (selectedStartDate) => {
    //1. Change start date in this component state
    //2. Update min max of endDate, startTime, endTime
    //3. Call json file callback to update state in parent - handleDailyFromDateChange()
    const {
      handleDailyFromDateChange,
      handleWeeklyFromDateChange,
      handleDnrFromDateChange,
    } = this.props.onChanges;

    let duration = this.props?.componentProps?.duration
    // 
    // console.log((duration))
    
      // this.setState({to_timestamp: to_time})


    // handleDnrFromDateChange,
    // handleDnrToDateChange,
    let {
      // startDateMin, // Always today is minimum
      // startDateMax, //Endless
      endDateMin,
      // endDateMax, //Endless
      startTimeMin,
      // startTimeMax, //sorted by itself
      endTimeMin,
      // endTimeMax //sorted by itself
    } = this.state;

    const isToday = (date) => {
      if (moment(date).isValid())
        return moment(date).get("date") === moment().get("date");
    };

    if (moment(selectedStartDate).isValid()) {
      let cleanStartDate = moment(selectedStartDate)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate();
      endDateMin = cleanStartDate;
    }

    if (isToday(selectedStartDate)) {
      startTimeMin = moment()
        .set({ minute: moment().get("minute") - 1, second: 0, millisecond: 0 })
        .toDate();
      endTimeMin = moment().set({ second: 0, millisecond: 0 }).toDate();
    } else {
      startTimeMin = moment(selectedStartDate)
        .set({
          hour: 0,
          minute: moment().get("minute") - 1,
          second: 0,
          millisecond: 0,
        })
        .toDate();
      endTimeMin = moment(selectedStartDate)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate();
    }

    this.setState(
      {
        startDate: selectedStartDate,
        endDateMin,
        startTimeMin,
        endTimeMin,
      },
      () => {
        if (this.state.startTime) {
          this.handleStartTimeChange(this.state.startTime, false, true);
        }
        if (!this.props.isEdit) {
          if (this.state.recurrence === "DO NOT REPEAT") {
            handleDnrFromDateChange(selectedStartDate);
            let to_time = moment(selectedStartDate).add(duration, "minutes").toDate()
            console.log(to_time)
            this.handleEndDateChange(to_time)

          } else if (this.state.recurrence === "DAILY") {
            handleDailyFromDateChange(selectedStartDate);
          } else if (this.state.recurrence === "WEEKLY") {
            handleWeeklyFromDateChange(selectedStartDate);
          }
        } else {
          handleDnrFromDateChange(selectedStartDate);
          let to_time = moment(selectedStartDate).add(duration, "minutes").toDate()
            console.log(to_time)
            this.handleEndDateChange(to_time)
        }
      }
    );
  };

  handleEndDateChange = (selectedEndDate) => {
    //Just set the endDate when end date is changed, no need to validate anything + call parent state update
    const {
      handleDailyToDateChange,
      handleWeeklyToDateChange,
      handleDnrToDateChange,
    } = this.props.onChanges;
    this.setState(
      {
        endDate: selectedEndDate,
      },
      () => {
        if (this.state.endTime) {
          this.handleStartTimeChange(this.state.startTime, true);
          this.handleEndTimeChange(this.state.endTime, true);
        }
        if (!this.props.isEdit) {
          if (this.state.recurrence === "DO NOT REPEAT") {
            handleDnrToDateChange(selectedEndDate);
          } else if (this.state.recurrence === "DAILY") {
            handleDailyToDateChange(selectedEndDate);
          } else if (this.state.recurrence === "WEEKLY") {
            handleWeeklyToDateChange(selectedEndDate);
          }
        } else {
          handleDnrToDateChange(selectedEndDate);
        }
      }
    );
  };

  handleStartTimeChange = (selectedStartTime) => {
    // This function affects only endTime
    const { dailyStartChange, weeklyStartChange } = this.props.onChanges;
    let {
      endTimeMin,
      startDate,
      // endTimeMax //sorted by itself
    } = this.state;

    if (selectedStartTime && startDate) {
      let year = moment(startDate).get("year");
      let month = moment(startDate).get("month");
      let date = moment(startDate).get("date");
      selectedStartTime = moment(selectedStartTime)
        .set({ year: year, month: month, date: date })
        .toDate();
    }

    if (this.state.endDate) {
      if (
        moment(selectedStartTime).get("date") ===
        moment(this.state.endDate).get("date")
      ) {
        endTimeMin = moment(selectedStartTime).toDate();
      } else {
        endTimeMin = null;
      }
    } else {
      endTimeMin = moment(selectedStartTime).toDate();
    }
    // else {
    //   endTimeMin = null
    // }

    // endTimeMin = if(moment(selectedStartTime).get("date") === (this.state.endDate)  )

    this.setState(
      {
        startTime: selectedStartTime,
        endTimeMin,
      },
      () => {
        if (this.state.recurrence === "DAILY") {
          this.checkTimeDifference("dailyStartTime");
          dailyStartChange(selectedStartTime);
        } else if (this.state.recurrence === "WEEKLY") {
          this.checkTimeDifference("startTime");
          weeklyStartChange(selectedStartTime);
        }
      }
    );
  };

  handleEndTimeChange = (selectedEndTime) => {
    const { dailyEndChange, weeklyEndChange } = this.props.onChanges;
    const { endDate } = this.state;
    let year = moment(endDate).get("year");
    let month = moment(endDate).get("month");
    let date = moment(endDate).get("date");
    selectedEndTime = moment(selectedEndTime)
      .set({ year: year, month: month, date: date })
      .toDate();

    this.setState(
      {
        endTime: selectedEndTime,
      },
      () => {
        this.checkTimeDifference("dailyEndTime");
        dailyEndChange(selectedEndTime);

        if (this.state.recurrence === "DAILY") {
          this.checkTimeDifference("dailyEndTime");
          dailyEndChange(selectedEndTime);
        } else if (this.state.recurrence === "WEEKLY") {
          this.checkTimeDifference("endTime");
          weeklyEndChange(selectedEndTime);
        }
      }
    );
  };

  // Weekly ONCHANGES
  handleStartDateChangeWeekly = (selectedStartDate) => {
    //1. Change start date in this component state
    //2. Update min max of endDate, startTime, endTime
    //3. Call json file callback to update state in parent - handleDailyFromDateChange()
    const {
      handleDailyFromDateChange,
      handleWeeklyFromDateChange,
      handleDnrFromDateChange,
    } = this.props.onChanges;
    // handleDnrFromDateChange,
    // handleDnrToDateChange,
    let {
      startTime,
      // startDateMin, // Always today is minimum
      // startDateMax, //Endless
      endDateMin,
      // endDateMax, //Endless
      startTimeMin,
      // startTimeMax, //sorted by itself
      endTimeMin,
      // endTimeMax //sorted by itself
    } = this.state;

    // const isToday = (date) => {
    //   if (moment(date).isValid())
    //     return moment(date).get("date") === moment().get("date");
    // };

    if (moment(selectedStartDate).isValid()) {
      let cleanStartDate = moment(selectedStartDate)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate();
      endDateMin = cleanStartDate;
    }

    // if (isToday(selectedStartDate)) {
    //   startTimeMin = moment()
    //     .set({ minute: moment().get("minute") - 1, second: 0, millisecond: 0 })
    //     .toDate();
    //   endTimeMin = moment().set({ second: 0, millisecond: 0 }).toDate();
    // } else {
    //   startTimeMin = moment(selectedStartDate)
    //     .set({
    //       hour: 0,
    //       minute: moment().get("minute") - 1,
    //       second: 0,
    //       millisecond: 0,
    //     })
    //     .toDate();
    //   endTimeMin = moment(selectedStartDate)
    //     .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    //     .toDate();
    // }
    startTimeMin= null
    endTimeMin = startTime && endTimeMin ? endTimeMin : null

    this.setState(
      {
        startDate: selectedStartDate,
        endDateMin,
        startTimeMin,
        endTimeMin,
      },
      () => {
        if (!this.props.isEdit) {
          if (this.state.recurrence === "DO NOT REPEAT") {
            handleDnrFromDateChange(selectedStartDate);
          } else if (this.state.recurrence === "DAILY") {
            handleDailyFromDateChange(selectedStartDate);
          } else if (this.state.recurrence === "WEEKLY") {
            handleWeeklyFromDateChange(selectedStartDate);
          }
        } else {
          handleDnrFromDateChange(selectedStartDate);
        }
      }
    );
  };

  handleEndDateChangeWeekly = (selectedEndDate) => {
    //Just set the endDate when end date is changed, no need to validate anything + call parent state update
    const {
      handleDailyToDateChange,
      handleWeeklyToDateChange,
      handleDnrToDateChange,
    } = this.props.onChanges;
    this.setState(
      {
        endDate: selectedEndDate,
      },
      () => {
        if (!this.props.isEdit) {
          if (this.state.recurrence === "DO NOT REPEAT") {
            handleDnrToDateChange(selectedEndDate);
          } else if (this.state.recurrence === "DAILY") {
            handleDailyToDateChange(selectedEndDate);
          } else if (this.state.recurrence === "WEEKLY") {
            handleWeeklyToDateChange(selectedEndDate);
          }
        } else {
          handleDnrToDateChange(selectedEndDate);
        }
      }
    );
  };

  handleStartTimeChangeWeekly = (selectedStartTime) => {
    // This function affects only endTime
    const { weeklyStartChange } = this.props.onChanges;
    let {
      endTimeMin,
      // endTimeMax //sorted by itself
    } = this.state;

    endTimeMin = moment(selectedStartTime).toDate();
    this.setState(
      {
        startTime: selectedStartTime,
        endTimeMin,
      },
      () => {
         if (this.state.recurrence === "WEEKLY") {
          this.checkTimeDifference("startTime");
          weeklyStartChange(selectedStartTime);
        }
      }
    );
  };

  handleEndTimeChangeWeekly = (selectedEndTime) => {
    const { dailyEndChange, weeklyEndChange } = this.props.onChanges;
    this.setState(
      {
        endTime: selectedEndTime,
      },
      () => {
        this.checkTimeDifference("dailyEndTime");
        dailyEndChange(selectedEndTime);

        if (this.state.recurrence === "DAILY") {
          this.checkTimeDifference("dailyEndTime");
          dailyEndChange(selectedEndTime);
        } else if (this.state.recurrence === "WEEKLY") {
          this.checkTimeDifference("endTime");
          weeklyEndChange(selectedEndTime);
        }
      }
    );
  };

  checkTimeDifference = (fromWhere) => {
    const {
      handleFromTimeError,
      handleToTimeError,
    } = this.props.dailyProps.dateProps;
    const { startTime, endTime } = this.state;
    if (!!startTime && !!endTime) {
      let a = moment(startTime);
      let b = moment(endTime);
      let difference = Math.abs(moment.duration(a.diff(b)).asMinutes());
      if (difference < 29) {
        // Clock difference is 30 minutes.
        this.setState(
          {
            timeDifferenceError: true,
          },
          () => {
            if (fromWhere === "dailyStartTime") {
              handleFromTimeError(true);
            } else if (fromWhere === "dailyEndTime") {
              handleToTimeError(true);
            }
          }
        );
      } else {
        this.setState(
          {
            timeDifferenceError: false,
          },
          () => {
            if (fromWhere === "dailyStartTime") {
              handleFromTimeError(false);
            } else if (fromWhere === "dailyEndTime") {
              handleToTimeError(false);
            }
          }
        );
      }
    }
  };


  render() {
    //values-----------------------------------
    const {
      recurrenceValue,
      dnrValue,
      repeatOnValue,
      // weeklyDateRangeValue,
      weeklyDateFromValue,
      weeklyDateToValue,
      weeklyStartTimeValue,
      weeklyEndTimeValue,
      //   dailyDateValue,
      dailyDateFromValue,
      dailyDateToValue,
      dailyStartTimeValue,
      dailyEndTimeValue,
    } = this.props.values;

    //recurrence -------------------------------------------
    const {
      key: recurrenceKey,
      label: recurrenceLabel,
      options: recurrenceOptions,
      error: recurrenceError,
      errorText: recurrenceErrorText,
    } = this.props.recurrenceProps;

    //DO NOT REPEAT--------------------------------------------
    const {
      dnrFromLabel,
      dnrToLabel,
      handleOnFromDateAccept,
      handleOnToDateAccept,
      handleCancelDate,
      minDateTime: dnrMinDateTime,
      disablePast: dnrDisablePast,
      mandatoryErrorFromDate: mandatoryDnrErrorFromDate,
      mandatoryErrorToDate: mandatoryDnrErrorToDate,
      fromDateParentErrorStateUpdate: weeklyfromDateParentErrorStateUpdate,
      toDateParentErrorStateUpdate: weeklytoDateParentErrorStateUpdate,
    } = this.props.dnrProps;

    //Weekly--------------------------------------
    const {
      repeatOnProps: {
        label: repeatOnLabel,
        options: repeatOnOptions,
        isMulti: isRepeatOnMulti,
        error: repeatOnError,
        errorText: repeatOnErrorText,
      } = {},
      dateRangeProps: {
        // label: weeklyDateLabel,
        // type: weeklyDateType,
        // isHidePastDate: weeklyDateHidePast,
        // isHideFutureDate: weeklyDateHideFuture,
        // error: weeklyDateError,
        // errorText: weeklyDateErrorText,

        //
        weeklyFromDateLabel,
        weeklyToDateLabel,
        handleOnFromDateAccept: handleWeeklyOnFromDateAccept,
        handleOnToDateAccept: handleWeeklyOnToDateAccept,
        // minDateTime: weeklyMinDateTime, // calculating minimum dateTime in this component
        disablePast: weeklyDisablePast, // true
        mandatoryErrorFromDate: mandatoryWeeklyErrorFromDate, //Mandatory error
        mandatoryErrorToDate: mandatoryWeeklyErrorToDate, //Mandatory error
        fromDateParentErrorStateUpdate: weekfromDateParentErrorStateUpdate, //parent error state update
        toDateParentErrorStateUpdate: weektoDateParentErrorStateUpdate, //parent error state update
      } = {},
      timeRangeProps: {
        startLabel,
        handleStartAccept,
        // startMin,
        disableStartPast,
        reqStartError,
        fromDateParentErrorStateUpdate: weeklyTimefromDateParentErrorStateUpdate,

        endLabel,
        handleEndAccept,
        // endMin,
        disableEndPast,
        reqEndError,
        toDateParentErrorStateUpdate: weeklyTimetoDateParentErrorStateUpdate,
      },
    } = this.props.weeklyProps || {};

    //Daily props
    const {
      dateProps: {
        dailyFromDateLabel,
        dailyToDateLabel,
        handleOnFromDateAccept: handleDailyOnFromDateAccept,
        handleOnToDateAccept: handleDailyOnToDateAccept,
        // minDateTime: dailyMinDateTime, // calculating minimum dateTime in this component
        disablePast: dailyDisablePast, // true
        mandatoryErrorFromDate: mandatoryDailyErrorFromDate, //Mandatory error
        mandatoryErrorToDate: mandatoryDailyErrorToDate, //Mandatory error
        fromDateParentErrorStateUpdate: datefromDateParentErrorStateUpdate, //parent error state update
        toDateParentErrorStateUpdate: datetoDateParentErrorStateUpdate, //parent error state update
      } = {},
      //   dailyProps: {
      //     label: dailyLabel,
      //     state: dailyState,
      //     isHidePastDate: dailyisHidePastDate,
      //     isHideFutureDate: dailyisHideFutureDate,
      //     isDate: dailyisDate
      //   } = {},
      timeRangeProps: {
        startLabel: dailystartLabel,
        handleStartAccept: dailyhandleStartAccept,
        // startMin: dailystartMin, // calculating minimum dateTime in this component
        disableStartPast: dailydisableStartPast,
        reqStartError: dailyreqStartError, //Mandatory error
        fromDateParentErrorStateUpdate: dailyfromDateParentErrorStateUpdate, //parent error state update

        endLabel: dailyendLabel,
        handleEndAccept: dailyhandleEndAccept,
        // endMin: dailyendMin, // calculating minimum dateTime in this component
        disableEndPast: dailydisableEndPast,
        reqEndError: dailyreqEndError, //Mandatory error
        toDateParentErrorStateUpdate: dailytoDateParentErrorStateUpdate, //parent error state update
      } = {},
    } = this.props.dailyProps;

    //onChange Callbacks
    const {
      // handleDnrFromDateChange,
      // handleDnrToDateChange,
      handleWeeklyRepeatOnChange,
      // handleWeeklyDateRangeChange,
      // handleWeeklyFromDateChange,
      // handleWeeklyToDateChange,
      // weeklyStartChange,
      // weeklyEndChange,
      // //   dailyDateChange,
      // handleDailyFromDateChange,
      // handleDailyToDateChange,
      // dailyStartChange,
      // dailyEndChange
    } = this.props.onChanges;

    const {
      recurrence_type_id,
      recurrence_from_time_id,
      recurrence_to_time_id,
    } = this.props.id;

    return (
      <div>
        <Grid container>
          {!this.props.isEdit && (
            <Grid item xs={12}>
              <CustomizedRadioButton
                data={{
                  key: recurrenceKey,
                  label: recurrenceLabel,
                  options: recurrenceOptions,
                }} //needs label, options, handleOnChange
                value={recurrenceValue}
                id={recurrence_type_id}
                handleOnChange={this.recurrenceStateChange}
                disabled={this.props.isEdit}
                error={recurrenceError}
                errorText={recurrenceErrorText}
                removeMarginBetweenErrorAndComponent={true}
              />
            </Grid>
          )}
          {(this.state.recurrence === "DO NOT REPEAT" || this.props.isEdit) && (
            <Grid item xs={12}>
              <FromToDateTimePicker
                recurrence_from_time_id={recurrence_from_time_id}
                recurrence_to_time_id={recurrence_to_time_id}
                disableToTime={this.props?.componentProps?.disableToTime}
                fromDateLabel={dnrFromLabel} //LABEL
                toDateLabel={dnrToLabel} //LABEL
                fromDateValue={dnrValue.dnrFromValue} //VALUE
                toDateValue={dnrValue.dnrToValue} //VALUE
                onFromDateChange={this.handleStartDateChange} //onChange
                onToDateChange={this.handleEndDateChange} //onChange
                onFromDateAccept={handleOnFromDateAccept} //onAccept
                onToDateAccept={handleOnToDateAccept} //onAccept
                handleCancelDate={handleCancelDate}
                // disabled
                minDateTime={dnrMinDateTime} //Min Date
                disablePast={dnrDisablePast} //Disable past
                mandatoryErrorFromDate={mandatoryDnrErrorFromDate} //Req error
                mandatoryErrorToDate={mandatoryDnrErrorToDate} //Req error
                fromDateParentErrorStateUpdate={
                  weeklyfromDateParentErrorStateUpdate
                } //State update
                toDateParentErrorStateUpdate={
                  weeklytoDateParentErrorStateUpdate
                } //State update
              />
            </Grid>
          )}
          {this.state.recurrence === "WEEKLY" && !this.props.isEdit && (
            <>
              <Grid item xs={12}>
                <SimpleSelect
                  {...this.props.weeklyProps.repeatOnProps}
                  label={repeatOnLabel}
                  options={repeatOnOptions}
                  isMulti={isRepeatOnMulti}
                  handleOnChange={handleWeeklyRepeatOnChange}
                  value={repeatOnValue}
                  error={repeatOnError}
                  errorText={repeatOnErrorText}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <DateRangePicker
                  label={weeklyDateLabel}
                  type={weeklyDateType}
                  value={weeklyDateRangeValue}
                  isHidePastDate={weeklyDateHidePast}
                  isHideFutureDate={weeklyDateHideFuture}
                  handleDateRange={handleWeeklyDateRangeChange}
                  error={weeklyDateError}
                  errorText={weeklyDateErrorText}
                  variant={"mobile"}
                /> */}
                <FromToDateTimePicker
                  isDate={true}
                  fromDateLabel={weeklyFromDateLabel} //LABEL
                  toDateLabel={weeklyToDateLabel} //LABEL
                  fromDateValue={weeklyDateFromValue} //VALUE
                  toDateValue={weeklyDateToValue} //VALUE
                  onFromDateChange={this.handleStartDateChangeWeekly} //onChange
                  onToDateChange={this.handleEndDateChangeWeekly} //onChange
                  onFromDateAccept={handleWeeklyOnFromDateAccept} //onAccept
                  onToDateAccept={handleWeeklyOnToDateAccept} //onAccept
                  minDateTime={{
                    minFromDateTime: this.state.startDateMin,
                    minToDateTime: this.state.endDateMin,
                  }} //Min Date
                  disablePast={weeklyDisablePast} //Disable past
                  mandatoryErrorFromDate={mandatoryWeeklyErrorFromDate} //Req error
                  mandatoryErrorToDate={mandatoryWeeklyErrorToDate} //Req error
                  fromDateParentErrorStateUpdate={
                    weekfromDateParentErrorStateUpdate
                  } //State update
                  toDateParentErrorStateUpdate={
                    weektoDateParentErrorStateUpdate
                  } //State update
                />
              </Grid>
              <Grid item xs={12}>
                <FromToTimePicker
                  timeDifferenceError={this.state.timeDifferenceError}
                  //Start
                  startLabel={startLabel}
                  startValue={weeklyStartTimeValue}
                  startChange={this.handleStartTimeChangeWeekly}
                  handleStartAccept={handleStartAccept}
                  startMin={this.state.startTimeMin}
                  disableStartPast={disableStartPast}
                  reqStartError={reqStartError}
                  fromDateParentErrorStateUpdate={
                    weeklyTimefromDateParentErrorStateUpdate
                  }
                  //End
                  endLabel={endLabel}
                  endValue={weeklyEndTimeValue}
                  endChange={this.handleEndTimeChangeWeekly}
                  handleEndAccept={handleEndAccept}
                  endMin={this.state.endTimeMin}
                  disableEndPast={disableEndPast}
                  reqEndError={reqEndError}
                  toDateParentErrorStateUpdate={
                    weeklyTimetoDateParentErrorStateUpdate
                  }
                />
              </Grid>
            </>
          )}
          {this.state.recurrence === "DAILY" && !this.props.isEdit && (
            <>
              <Grid item xs={12}>
                <FromToDateTimePicker
                  isDate={true}
                  fromDateLabel={dailyFromDateLabel} //LABEL
                  toDateLabel={dailyToDateLabel} //LABEL
                  fromDateValue={dailyDateFromValue} //VALUE
                  toDateValue={dailyDateToValue} //VALUE
                  onFromDateChange={this.handleStartDateChange} //onChange
                  onToDateChange={this.handleEndDateChange} //onChange
                  onFromDateAccept={handleDailyOnFromDateAccept} //onAccept
                  onToDateAccept={handleDailyOnToDateAccept} //onAccept
                  minDateTime={{
                    minFromDateTime: this.state.startDateMin,
                    minToDateTime: this.state.endDateMin,
                  }} //Min Date
                  disablePast={dailyDisablePast} //Disable past
                  mandatoryErrorFromDate={mandatoryDailyErrorFromDate} //Req error
                  mandatoryErrorToDate={mandatoryDailyErrorToDate} //Req error
                  fromDateParentErrorStateUpdate={
                    datefromDateParentErrorStateUpdate
                  } //State update
                  toDateParentErrorStateUpdate={
                    datetoDateParentErrorStateUpdate
                  } //State update
                />
              </Grid>
              <Grid item xs={12}>
                <FromToTimePicker
                  timeDifferenceError={this.state.timeDifferenceError}
                  //Start
                  startLabel={dailystartLabel}
                  startValue={dailyStartTimeValue}
                  startChange={this.handleStartTimeChange}
                  handleStartAccept={dailyhandleStartAccept}
                  startMin={this.state.startTimeMin}
                  disableStartPast={dailydisableStartPast}
                  reqStartError={dailyreqStartError}
                  fromDateParentErrorStateUpdate={
                    dailyfromDateParentErrorStateUpdate
                  }
                  //End
                  endLabel={dailyendLabel}
                  endValue={dailyEndTimeValue}
                  endChange={this.handleEndTimeChange}
                  handleEndAccept={dailyhandleEndAccept}
                  endMin={this.state.endTimeMin}
                  disableEndPast={dailydisableEndPast}
                  reqEndError={dailyreqEndError}
                  toDateParentErrorStateUpdate={
                    dailytoDateParentErrorStateUpdate
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(style)(ActivityRecurrence);
