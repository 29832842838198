import React from "react";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import withAlelrtAndDialog from "../../../HOCs/withAlelrtAndDialog";
import { withRouter } from "react-router-dom";
import {
  FormControl,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  withStyles,
} from "@material-ui/core";
import { alertProps, genericStyles, masterData } from "../../../utils";
import AdminTable from "../../../components/adminTable";
import {
  actionsCall,
  addMasterCall,
  editMasterCall,
  stateCall,
  updateStateCall,
  tableColumn,
  addStateCall,
} from "./utils";
import {
  addShouldComponentUpdate,
  deleteShouldComponentUpdate,
  updateShouldComponentUpdate,
} from "./shouldUpdate";
import EditMasterData from "./editData";

const styles = (theme) => ({
  root: {
    padding: 24,
  },
  list: {
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    backgroundColor: "white",
    borderRadius: 8,
    height: "calc(100vh - 108px)",
    overflow: "scroll",
  },
  masterSelect: {
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    backgroundColor: "white",
    borderRadius: 8,
    padding: "12px 30px 12px 12px",
    // height: "calc(100vh - 108px)",
    // overflow: "scroll"
  },
  table: {
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    backgroundColor: "white",
    borderRadius: 8,
    height: "calc(100vh - 108px)",
    overflow: "scroll",
  },
  menuList: {
    padding: 8,
    "& .Mui-selected": {
      borderRadius: 16,
      backgroundColor: theme.palette.primary.main,
      color: `${theme.palette.common.white} !important`,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  menuListItem: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 16,
    padding: "4px 16px",
  },
  // typography: {
  //   "&:hover": {
  //     color: `${theme.palette.ternary.main} !important`
  //   }
  // },
  // typoSelected: {
  //   color: `${theme.palette.ternary.main} !important`
  // },
  formControl: {
    margin: "0px 8px",
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.ternary.main} !important`,
        borderWidth: "1.6px !important",
      },
    },
  },
  customSelect: {
    padding: "10.500px 14px",
  },
  label: {
    color: theme.palette.text.label,
    textTransform: "uppercase",
  },
  ...genericStyles.cardWrapperShadows(theme),
});

class Masters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: masterData[0].id,
      selectedObj: masterData[0],
      searchKey: "",
      sortKey: 1,
      newMaster: "",
      newMaster2: "",
      // countryName: "",
      error: {},
      fieldsToValidate: ["newMaster"],
    };
  }

  componentDidMount() {
    this.getEssentials();
  }

  getEssentials = () => {
    let _ = actionsCall(this.props, this.state)[this.state.selected]();
    console.log(_)
    this.cleanupStates();
  };

  cleanupStates = () => {
    this.setState({
      newMaster: "",
      newMaster2: "",
      // countryName: "",
    });
  };

  onMenuItemSelected = (selected, selectedObj) => {
    // let multipleFieldIds = [12];
    // let multipleFieldName = ["newMaster2"];
    let fieldsToValidate = ["newMaster"];

    // let fieldsToValidate = selected !== 8 ? ["newMaster"] : ["newMaster", "countryName"];
    // if (multipleFieldIds.includes(selected)) {
    //   fieldsToValidate = [...fieldsToValidate, ...multipleFieldName];
    // }
    this.setState(
      {
        selected,
        selectedObj,
        searchKey: "", //Resets the search parameter when other List is clicked
        fieldsToValidate: fieldsToValidate,
        error: {},
      },
      () => {
        this.getEssentials();
      }
    );
  };

  onChange = (key, value, type) => {
    if (type?.toLowerCase() === "number") {
      if (value?.length > 5) {
        return false;
      }
    }
    this.setState(
      (prevState) => {
        return {
          [key]: value,
          // fieldsToValidate: prevState.fieldsToValidate.includes(key) ? [...prevState.fieldsToValidate] : [...prevState.fieldsToValidate, key]
          // fieldsToValidate: [...prevState.fieldsToValidate, ...finalFTV]
        };
      },
      () => {
        if (key === "searchKey" || key === "sortKey") {
          this.getEssentials();
        }
      }
    );
  };

  addMaster = () => {
    addMasterCall(this.props, this.state)[this.state.selected]();
    this.setState({
      searchKey: "", //Resets the search parameter when other List is clicked (or) a new entry is added to database
    });
  };

  handleValidation = () => {
    let isErrorOccured = false;

    const { fieldsToValidate } = this.state;

    fieldsToValidate.map((_) => {
      if (!this.state[_]) {
        isErrorOccured = true;
        this.setState((prevState) => {
          return {
            error: {
              ...prevState.error,
              [_]: true,
            },
          };
        });
      } else {
        isErrorOccured = false;
        this.setState((prevState) => {
          return {
            error: {
              ...prevState.error,
              [_]: false,
            },
          };
        });
      }
      return _;
    });
    return isErrorOccured;

    // if (!this.state.newMaster) {
    //   isErrorOccured = true;
    //   this.setState((prevState) => {
    //     return {
    //       error: {
    //         newMaster: true,
    //       },
    //     };
    //   });
    // } else if (this.state?.selected === 8 && !this.state?.countryCode) {
    //   isErrorOccured = true;
    //   this.setState((prevState) => {
    //     return {
    //       error: {
    //         countryName: true,
    //       },
    //     };
    //   });
    // } else {
    //   isErrorOccured = false;
    //   this.setState((prevState) => {
    //     return {
    //       error: {
    //         newMaster: false,
    //         countryName: false,
    //       },
    //     };
    //   });
    // }
    // return isErrorOccured;
  };

  checkErrorAndApiCall = () => {
    const isError = this.handleValidation();
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      this.addMaster();
    }
  };

  handleAddClick = () => {
    this.checkErrorAndApiCall();
  };

  handleAddEnter = (event) => {
    if (event) {
      if (event.charCode === 13) {
        event.preventDefault();
        this.checkErrorAndApiCall();
      }
    }
  };

  editMaster = (isError, updatedData, url, previousData) => {
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      // We have the ID from the previous data and the actual data in updated Data
      editMasterCall(
        this.props,
        updatedData,
        previousData,
        this.state.selected
      )[this.state.selected]();

      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: false,
        component: <></>,
      });

    }
  };

  onCancelButtonClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  handleEditDrawer = (rowData) => {
    console.log(rowData);
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <EditMasterData
          // isEdit={true}
          // fieldLabel={
          //   masterData.filter((_) => _.id === this.state.selected)[0]
          //     .tableColumnName
          // }
          fieldLabel={this.getPlaceholder()}
          // editDataLabel
          valueLabels={
            masterData.filter((_) => _.id === this.state.selected)[0]
              .editDataLabel
          }
          selected={this.state.selected}
          data={rowData}
          onSave={this.editMaster}
          // getData={{ ...stateCall(this.props)[this.state.selected]()}}
          updateState={{
            ...updateStateCall(this.props)[this.state.selected](),
          }}
          onCancelButtonClicked={this.onCancelButtonClicked}
        />
      ),
    });
  };

  deleteMaster = (e, data) => {
    // this.props.dialog.setDialog({
    //   open: true,
    //   title: "Confirmation",
    //   body: "Are you sure, you want to delete this item?",
    //   positiveBtn: "Delete",
    //   negativeBtn: "Cancel",
    //   onOk: () => {
    //     this.props.dialog.setDialog({ open: false })
    //     this.props.backDrop.setBackDrop({
    //       ...this.props.backDrop,
    //       open: true,
    //       message: "Deleting..."
    //     });
    //     deleteMasterCall(this.props, data)[this.state.selected]();
    //   }
    // })


    const dt = {
      value: data.value,
      label: data.label,
      isActive: e.target.checked,
    };
    const valdt = {
      value: data.value,
      label: data.label,
      isActive: e.target.checked,
      days: data.days,
    };
    let updatedData = {};
    if (data.days) {
      updatedData = valdt;
    } else {
      updatedData = dt;
    }

    editMasterCall(this.props, updatedData, data, this.state.selected)[
      this.state.selected
    ]();

    // deleteMasterCall(this.props, data)[this.state.selected]();
  };

  // Checking should state update on Add, Delete (or) Update/Edit
  shouldComponentUpdate(nextProps, nextState) {
    addShouldComponentUpdate(
      this.props,
      nextProps,
      this.getEssentials,
      this.cleanupStates
    )[this.state.selected]();
    deleteShouldComponentUpdate(
      this.props,
      nextProps,
      this.getEssentials,
      this.cleanupStates
    )[this.state.selected]();
    updateShouldComponentUpdate(
      this.props,
      nextProps,
      this.getEssentials,
      this.cleanupStates,
      this.onCancelButtonClicked
    )[this.state.selected]();

    return true;
  }

  getPlaceholder = (isSingularName) => {
    const objectKey = isSingularName ? "singularVersion" : "tableColumnName";
    const currentMenu = masterData.filter(
      (_) => _.id === this.state.selected
    )[0];
    if (!("additionalFields" in currentMenu)) {
      return [currentMenu?.[objectKey]];
    } else {
      let placeholders = [currentMenu?.[objectKey]];
      let additionalPlaceholders = currentMenu?.additionalFields?.map(
        (x) => x?.[objectKey]
      );
      return [...placeholders, ...additionalPlaceholders];
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            {/* List component here */}
            <Hidden xsDown>
              <div className={`${classes.list} ${classes.cardWrapperShadows}`}>
                {/* <Typography
                  variant="h6"
                  style={{ padding: 20 }}
                  color="textPrimary"
                >
                  Master Data
                </Typography>
                <Divider variant="fullWidth" /> */}
                <List component="nav" className={classes.menuList}>
                  {masterData.map((md, key) => {
                    return (
                      <ListItem
                        className={classes.menuListItem}
                        key={key}
                        button
                        selected={this.state.selected === md.id}
                        onClick={(event) => this.onMenuItemSelected(md.id, md)}
                        id={`${md?.label}`}
                      >
                        <ListItemText
                          primary={md.label}
                          disableTypography
                          className={`${classes.typography} ${
                            this.state.selected === md.id
                              ? classes.typoSelected
                              : ""
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </Hidden>

            <Hidden smUp>
              <div
                className={`${classes.masterSelect} ${classes.cardWrapperShadows}`}
              >
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ marginLeft: 8, width: "100%" }}
                >
                  {/* <InputLabel
                    shrink
                    htmlFor="masterData"
                    className={classes.label}
                  >
                    Master Data
                  </InputLabel> */}
                  <Select
                    value={this.state.selected}
                    // onChange={(e) =>
                    //   ("sortKey", e.target.value)
                    // }
                    onChange={(e) =>
                      this.onMenuItemSelected(
                        e.target.value,
                        masterData.filter((x) => x.id === e.target.value)[0]
                      )
                    }
                    style={{
                      width: "100%",
                    }}
                    name="masterData"
                    classes={{ outlined: classes.customSelect }}
                  >
                    {masterData?.map((item, index) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </Hidden>
          </Grid>

          <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
            {/* Table component here */}
            <div className={`${classes.table} ${classes.cardWrapperShadows}`}>
              <AdminTable
                //Both Field and Table row is taken from the response data returned by this state function call
                {...stateCall(this.props)[this.state.selected]()}
                //Table specific props
                columns={tableColumn()[this.state.selected]()}
                editMaster={this.handleEditDrawer}
                deleteMaster={this.deleteMaster}
                //Field Specific Props
                title={this.state.selectedObj.label}
                isSelectedCC={this.state.selected}
                selectedObj={this.state.selectedObj}
                // buttonName={
                //   masterData.filter((_) => _.id === this.state.selected)[0]
                //     .singularVersion
                // }
                placeholdersArray={this.getPlaceholder(true)}
                // secondaryButtonName={this.state.selected === 8 ? "Country Name" : ""}
                onRetry={this.getEssentials}
                searchKey={this.state.searchKey}
                sortKey={this.state.sortKey}
                onChange={this.onChange}
                // addMaster={this.addMaster}
                handleAddClick={this.handleAddClick}
                handleAddEnter={this.handleAddEnter}
                // editMaster={this.editMaster}
                // keysArray={}
                valuesArray={this.getPlaceholder()?.map(
                  (x, index) =>
                    this.state?.[`newMaster${index !== 0 ? index + 1 : ""}`]
                )}
                // countryName={this.state.countryName}
                addLoadingAndError={{
                  ...addStateCall(this.props)[this.state.selected](),
                }}
                validationError={this.state.error}
                // Hiding the Margin top only for the Master Data
                hideMarginTop={true}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  skills: state?.masterSet?.skills ?? {
    data: [],
    loading: false,
    error: false,
  },
  cert_organization: state?.masterSet?.cert_organization ?? {
    data: [],
    loading: false,
    error: false,
  },
  exp_organization: state?.masterSet?.exp_organization ?? {
    data: [],
    loading: false,
    error: false,
  },
  categories: state?.masterSet?.categories ?? {
    data: [],
    loading: false,
    error: false,
  },
  certificates: state?.masterSet?.certificates ?? {
    data: [],
    loading: false,
    error: false,
  },
  exp_roles: state?.masterSet?.exp_roles ?? {
    data: [],
    loading: false,
    error: false,
  },
  specialization: state?.masterSet?.specialization ?? {
    data: [],
    loading: false,
    error: false,
  },
  // countryCode: state?.masterModule?.getcc ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },
  language: state?.masterModule?.getlang ?? {
    data: [],
    loading: false,
    error: false,
  },
  salutation: state?.masterModule?.getsal ?? {
    data: [],
    loading: false,
    error: false,
  },
  // frequency: state?.masterModule?.getfreq ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },
  license: state?.masterModule?.getlicence ?? {
    data: [],
    loading: false,
    error: false,
  },
  // validity: state?.masterSet?.validity ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },
  address: state?.masterSet?.addressType ?? {
    data: [],
    loading: false,
    error: false,
  },
  email: state?.masterSet?.emailType ?? {
    data: [],
    loading: false,
    error: false,
  },
  phone: state?.masterSet?.phoneNoType ?? {
    data: [],
    loading: false,
    error: false,
  },
  idType: state?.masterSet?.idType ?? {
    data: [],
    loading: false,
    error: false,
  },

  enquiry_source_of_referral: state?.masterSet?.enquiry_source_of_referral ?? {
    data: [],
    loading: false,
    error: false,
  },
  enquiry_status: state?.masterSet?.enquiry_status ?? {
    data: [],
    loading: false,
    error: false,
  },

  lead_types: state?.masterSet?.lead_types ?? {
    data: [],
    loading: false,
    error: false
  },

  product_management_group_types: state?.masterSet?.product_management_group_types ?? {
    data: [],
    loading: false,
    error: false,
  },

  leave_types: state?.masterSet?.leave_types ?? {
    data: [],
    loading: false,
    error: false,
  },
  tax: state?.masterSet?.tax ?? {
    data: [],
    loading: false,
    error: false,
  },
  // unavailable_types: state?.masterSet?.unavailable_types ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },

  // Add
  addSkill: state?.masterModule?.addSkill ?? {
    data: [],
    loading: false,
    error: false,
  },
  addCertOrg: state?.masterModule?.addIA ?? {
    data: [],
    loading: false,
    error: false,
  },
  addExpOrg: state?.masterModule?.addEmpOrg ?? {
    data: [],
    loading: false,
    error: false,
  },
  addCategory: state?.masterModule?.addcategory ?? {
    data: [],
    loading: false,
    error: false,
  },
  addCertificate: state?.masterModule?.addcert ?? {
    data: [],
    loading: false,
    error: false,
  },
  addExpRole: state?.masterModule?.addjobrole ?? {
    data: [],
    loading: false,
    error: false,
  },
  addSpecialization: state?.masterModule?.addSpecialization ?? {
    data: [],
    loading: false,
    error: false,
  },
  // addCountryCode: state?.masterModule?.addcc ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },
  addLanguage: state?.masterModule?.addlang ?? {
    data: [],
    loading: false,
    error: false,
  },
  addSalutation: state?.masterModule.addsal ?? {
    data: [],
    loading: false,
    error: false,
  },
  // addFrequency: state?.masterModule?.addfreq ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },
  addLicense: state?.masterModule?.addlicence ?? {
    data: [],
    loading: false,
    error: false,
  },
  // addValidity: state?.masterModule?.addValidity ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },
  addAddress: state?.masterModule?.addAddress ?? {
    data: [],
    loading: false,
    error: false,
  },
  addEmail: state?.masterModule?.addEmail ?? {
    data: [],
    loading: false,
    error: false,
  },
  addPhone: state?.masterModule?.addPhone ?? {
    data: [],
    loading: false,
    error: false,
  },
  addIdType: state?.masterModule?.addIdType ?? {
    data: [],
    loading: false,
    error: false,
  },

  add_master_enquiry_source_of_referral: state?.masterModule?.add_master_enquiry_source_of_referral ?? {
    data: [],
    loading: false,
    error: false,
  },
  add_enquiry_status: state?.masterModule?.add_enquiry_status ?? {
    data: [],
    loading: false,
    error: false,
  },

  add_lead_types: state?.masterModule?.add_lead_types ?? {
    data: [],
    loading: false,
    error: false,
  },

  add_product_management_group_types: state?.masterModule?.add_product_management_group_types ?? {
    data: [],
    loading: false,
    error: false,
  },
  add_leave_types: state?.masterModule?.add_leave_types ?? {
    data: [],
    loading: false,
    error: false,
  },
  add_tax: state?.masterModule?.add_tax ?? {
    data: [],
    loading: false,
    error: false,
  },
  // add_unavailable_types: state?.masterModule?.add_unavailable_types ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },

  // Update/Edit
  updateSkill: state?.masterModule?.updateSkill ?? {
    data: [],
    loading: false,
    error: false,
  },
  updateCertOrg: state?.masterModule?.updateIA ?? {
    data: [],
    loading: false,
    error: false,
  },
  updateExpOrg: state?.masterModule?.updateEmpOrg ?? {
    data: [],
    loading: false,
    error: false,
  },
  updateCategory: state?.masterModule?.updatecategory ?? {
    data: [],
    loading: false,
    error: false,
  },
  updateCertificate: state?.masterModule?.updatecert ?? {
    data: [],
    loading: false,
    error: false,
  },
  updateExpRole: state?.masterModule?.updatejobrole ?? {
    data: [],
    loading: false,
    error: false,
  },
  updateSpecialization: state?.masterModule?.updateSpecialization ?? {
    data: [],
    loading: false,
    error: false,
  },
  // updateCountryCode: state?.masterModule?.updatecc ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },
  updateLanguage: state?.masterModule?.updatelang ?? {
    data: [],
    loading: false,
    error: false,
  },
  updateSalutation: state?.masterModule.updatesal ?? {
    data: [],
    loading: false,
    error: false,
  },
  // updateFrequency: state?.masterModule?.updatefreq ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },
  updateLicense: state?.masterModule?.updatelicence ?? {
    data: [],
    loading: false,
    error: false,
  },
  // updateValidity: state?.masterModule?.updateValidity ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },
  updateAddress: state?.masterModule?.updateAddress ?? {
    data: [],
    loading: false,
    error: false,
  },
  updateEmail: state?.masterModule?.updateEmail ?? {
    data: [],
    loading: false,
    error: false,
  },
  updatePhone: state?.masterModule?.updatePhone ?? {
    data: [],
    loading: false,
    error: false,
  },
  updateIdType: state?.masterModule?.updateIdType ?? {
    data: [],
    loading: false,
    error: false,
  },

  update_master_enquiry_source_of_referral: state?.masterModule?.update_master_enquiry_source_of_referral ?? {
    data: [],
    loading: false,
    error: false,
  },
  update_enquiry_status: state?.masterModule?.update_enquiry_status ?? {
    data: [],
    loading: false,
    error: false,
  },

  update_lead_types: state?.masterModule?.update_lead_types ?? {
    data: [],
    loading: false,
    error: false
  },

  update_product_management_group_types: state?.masterModule?.update_product_management_group_types ?? {
    data: [],
    loading: false,
    error: false,
  },
  update_leave_types: state?.masterModule?.update_leave_types ?? {
    data: [],
    loading: false,
    error: false,
  },
  update_tax: state?.masterModule?.update_tax ?? {
    data: [],
    loading: false,
    error: false,
  },
  // update_unavailable_types: state?.masterModule?.update_unavailable_types ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },

  // delete
  deleteSkill: state?.masterModule?.deleteSkill ?? {
    data: [],
    loading: false,
    error: false,
  },
  deleteCertOrg: state?.masterModule?.deleteIA ?? {
    data: [],
    loading: false,
    error: false,
  },
  deleteExpOrg: state?.masterModule?.deleteEmpOrg ?? {
    data: [],
    loading: false,
    error: false,
  },
  deleteCategory: state?.masterModule?.deletecategory ?? {
    data: [],
    loading: false,
    error: false,
  },
  deleteCertificate: state?.masterModule?.deletecert ?? {
    data: [],
    loading: false,
    error: false,
  },
  deleteExpRole: state?.masterModule?.deletejobrole ?? {
    data: [],
    loading: false,
    error: false,
  },
  deleteSpecialization: state?.masterModule?.deleteSpecialization ?? {
    data: [],
    loading: false,
    error: false,
  },
  // deleteCountryCode: state?.masterModule?.deletecc ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },
  deleteLanguage: state?.masterModule?.deletelang ?? {
    data: [],
    loading: false,
    error: false,
  },
  deleteSalutation: state?.masterModule.deletesal ?? {
    data: [],
    loading: false,
    error: false,
  },
  // deleteFrequency: state?.masterModule?.deletefreq ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },
  deleteLicense: state?.masterModule?.deletelicence ?? {
    data: [],
    loading: false,
    error: false,
  },
  // deleteValidity: state?.masterModule?.deleteValidity ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },
  deleteAddress: state?.masterModule?.deleteAddress ?? {
    data: [],
    loading: false,
    error: false,
  },
  deleteEmail: state?.masterModule?.deleteEmail ?? {
    data: [],
    loading: false,
    error: false,
  },
  deletePhone: state?.masterModule?.deletePhone ?? {
    data: [],
    loading: false,
    error: false,
  },

  deleteIdType: state?.masterModule?.deleteIdType ?? {
    data: [],
    loading: false,
    error: false,
  },

  delete_master_enquiry_source_of_referral: state?.masterModule?.delete_master_enquiry_source_of_referral ?? {
    data: [],
    loading: false,
    error: false
  },
  delete_enquiry_status: state?.masterModule?.delete_enquiry_status ?? {
    data: [],
    loading: false,
    error: false
  },

  delete_lead_types: state?.masterModule?.delete_lead_types ?? {
    data: [],
    loading: false,
    error: false,
  },

  delete_product_management_group_types: state?.masterModule?.delete_product_management_group_types ?? {
    data: [],
    loading: false,
    error: false,
  },

  delete_leave_types: state?.masterModule?.delete_leave_types ?? {
    data: [],
    loading: false,
    error: false,
  },
  delete_tax: state?.masterModule?.delete_tax ?? {
    data: [],
    loading: false,
    error: false,
  },
  // delete_unavailable_types: state?.masterModule?.delete_unavailable_types ?? {
  //   data: [],
  //   loading: false,
  //   error: false,
  // },
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(Masters))));
