import React from "react";
import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

export const ExpandIcon = (props) => {
  return (
    <SvgIcon fontSize={"small"} {...props} >
      <g id="next" transform="translate(.419 .375)">
        <g id="Group_2" data-name="Group 2">
          <path
            id="Path_8"
            stroke={props.htmlColor ? props.htmlColor : "#000"}
            strokeWidth="0.75px"
            d="M5.873 5.671L.735.136a.409.409 0 0 0-.609 0 .492.492 0 0 0 0 .658L4.959 6 .127 11.206a.492.492 0 0 0 0 .658.414.414 0 0 0 .304.136.414.414 0 0 0 .3-.136l5.142-5.535a.492.492 0 0 0 0-.659z"
            data-name="Path 8"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

ExpandIcon.propTypes = {
  htmlColor: PropTypes.string,
};
