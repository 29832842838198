import React from "react";
import PropTypes from "prop-types";

import { SvgIcon } from "@material-ui/core";

function CheckIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
        fill={props.color}
      />
      <path
        d="m8.505 12.463 2.43 2.43 5.147-5.147"
        stroke="white"
        fill="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

CheckIcon.propTypes = {
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
};

CheckIcon.defaultProps = {
  color: "#31B198",
  secondaryColor: "#fff",
};

export default CheckIcon;
