import * as React from "react"

export const CircleIcon = (props) => {

    const {
        width = "20",
        height = "20",
        borderColor = "#6f6f6f",
        borderWidth = "1",
        bgColor = "#fff"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 20 20"
        >
            <g id="Rectangle_48016" data-name="Rectangle 48016" fill={bgColor} stroke={borderColor} stroke-width={borderWidth}>
                <rect width="20" height="20" rx="10" stroke="none" />
                <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="none" />
            </g>
        </svg>
    );
};


// import * as React from "react";
// import { SvgIcon } from "@material-ui/core";

// export const CircleIcon = (props) => {

//     return (
//         <SvgIcon
//             width="1em"
//             height="1em"
//             viewBox="0 0 24 24"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//             {...props}
//         >
//             <path
//                 d="M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12Z"
//                 fill="#fff"
//             />
//             <path
//                 d="M23.4 12C23.4 5.704 18.296.6 12 .6S.6 5.704.6 12 5.704 23.4 12 23.4 23.4 18.296 23.4 12Z"
//                 stroke="#6F6F6F"
//             />
//         </SvgIcon>
//     );
// }

