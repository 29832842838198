import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  TextField,
  withStyles
} from "@material-ui/core";
import { LocalizationProvider, MobileTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types";

const styles = (theme) => ({
  root: {
    borderRadius: "8px !important",
    margin: (props) =>
      props.marginTop ? `${props.marginTop}px 0px 8px 0px` : "8px 0px 8px 0px",
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px"
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.secondary.light}`,
      borderRadius: 8
    },
    "& .MuiOutlinedInput-root:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`
      }
    }
  },
  errorBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.error.light} !important`
    }
  },
  svgColor: {
    fill: theme.palette.secondary.main,
    fontSize: 20
  },
  textField: {
    marginTop: (props) =>
      props.marginTop ? `${props.marginTop}px` : theme.spacing(3),

    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      // border: '1.8px solid rgba(204,204,204, 0.3)',
      border: "none"
    },

    "& .MuiOutlinedInput-root.Mui-disabled ": {
      backgroundColor: "#f2f2f2",
      borderRadius: "8px !important"
    }
  },
  label: {
    color: theme.palette.text.label,
    textTransform: "uppercase"
  }
});

class TimeRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromTimeValue: null,
      toTimeValue: null,
      fromTimeMin: null,
      toTimeMin: null,
      fromTimeError: false,
      fromTimeErrorText: "",
      toTimeError: false,
      toTimeErrorText: ""
    };
  }

  componentDidMount() {
    // this.setInitialState();
  }

  setInitialState = () => {
    if (this.props.fromTimeValue && this.props.toTimeValue) {
      this.setState({
        fromTimeValue: this.props.fromTimeValue,
        toTimeValue: this.props.toTimeValue
      });
    }
  };

  handleOnChange = (value, key) => {
    this.setState(
      {
        [key]: value
      },
      () => {
        if (key === "fromTimeValue") {
          this.props.onFromTimeChange(value);
        } else if (key === "toTimeValue") {
          this.props.onToTimeChange(value);
        }
      }
    );
  };

  handleOnError = (reason, value, errorKey, errorTextKey) => {
    const {
      fromParentUpdate = () => {},
      toParentUpdate = () => {}
    } = this.props;
    switch (reason) {
      case "invalidDate":
        this.setState(
          {
            [errorKey]: true,
            [errorTextKey]: "Invalid time format"
          },
          () => {
            if (
              errorKey === "startError" &&
              errorTextKey === "startErrorText"
            ) {
              fromParentUpdate(true);
            } else {
              toParentUpdate(true);
            }
          }
        );
        break;

      case "disablePast":
        this.setState(
          {
            [errorKey]: true,
            [errorTextKey]: "Values in the past are not allowed"
          },
          () => {
            if (
              errorKey === "startError" &&
              errorTextKey === "startErrorText"
            ) {
              fromParentUpdate(true);
            } else {
              toParentUpdate(true);
            }
          }
        );
        break;

      case "maxTime":
        this.setState(
          {
            [errorKey]: true,
            [errorTextKey]: `Time should not be after`
            //   ${format(maxDate, "P")}
          },
          () => {
            if (
              errorKey === "startError" &&
              errorTextKey === "startErrorText"
            ) {
              fromParentUpdate(true);
            } else {
              toParentUpdate(true);
            }
          }
        );
        break;

      case "minTime":
        this.setState(
          {
            [errorKey]: true,
            [errorTextKey]: `Time should not be before start time`
          },
          () => {
            if (
              errorKey === "startError" &&
              errorTextKey === "startErrorText"
            ) {
              fromParentUpdate(true);
            } else {
              toParentUpdate(true);
            }
          }
        );
        break;
      case null:
        this.setState(
          {
            [errorKey]: false,
            [errorTextKey]: ``
            //   ${format(minDate, "P")}
          },
          () => {
            if (
              errorKey === "startError" &&
              errorTextKey === "startErrorText"
            ) {
              fromParentUpdate(false);
            } else {
              toParentUpdate(false);
            }
          }
        );
        break;
      default:
        this.setState(
          {
            [errorKey]: false,
            [errorTextKey]: ``
            //   ${format(minDate, "P")}
          },
          () => {
            if (
              errorKey === "startError" &&
              errorTextKey === "startErrorText"
            ) {
              fromParentUpdate(false);
            } else {
              toParentUpdate(false);
            }
          }
        );
    }
  };

  render() {
    const { classes, fromTimeErrorObject, toTimeErrorObject } = this.props;

    return (
      <>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl
            className={`${classes.root} ${
              this.state.fromTimeError ? classes.errorBorder : ""
            }`}
          >
            <InputLabel shrink htmlFor="date">
              {this.props.fromTimeLabel && (
                <div className={classes.label}>{this.props.fromTimeLabel}</div>
              )}
            </InputLabel>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <MobileTimePicker
                value={this.props.fromTimeValue}
                // onChange={(value) =>
                //   this.handleOnChange(value, "fromTimeValue")
                // }
                onChange={this.props.onFromTimeChange}
                //   onAccept={handleStartAccept}
                disablePast={this.props.disableFromTimePast}
                minTime={this.props.fromTimeMin}
                disabled={this.props.disableFromTimeField}
                renderInput={(props) => {
                  return (
                    <TextField
                      {...props}
                      variant="outlined"
                      id="fromTime"
                      placeholder=""
                      autoComplete="off"
                      error={fromTimeErrorObject?.error}
                      helperText={fromTimeErrorObject?.message}
                      className={classes.textField}
                      disabled={this.props.disableFromTimeField}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl
            className={`${classes.root} ${
              this.state.toTimeError ? classes.errorBorder : ""
            }`}
          >
            <InputLabel shrink htmlFor="date">
              {this.props.toTimeLabel && (
                <div className={classes.label}>{this.props.toTimeLabel}</div>
              )}
            </InputLabel>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <MobileTimePicker
                value={this.props.toTimeValue}
                // onChange={(value) => this.handleOnChange(value, "toTimeValue")}
                onChange={this.props.onToTimeChange}
                //   onAccept={handleStartAccept}
                disablePast={this.props.disableToTimePast}
                minTime={this.props.toTimeMin}
                disabled={this.props.disableToTimeField}
                renderInput={(props) => {
                  return (
                    <TextField
                      {...props}
                      variant="outlined"
                      id="toTime"
                      placeholder=""
                      autoComplete="off"
                      error={toTimeErrorObject?.error}
                      helperText={toTimeErrorObject?.message}
                      className={classes.textField}
                      disabled={this.props.disableToTimeField}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(TimeRangePicker);

TimeRangePicker.defaultProps = {
  disableFromTimePast: false,
  fromTimeMin: null,
  disableToTimePast: false,
  toTimeMin: null
};

TimeRangePicker.propTypes = {
  fromTimeLabel: PropTypes.string,
  fromTimeValue: PropTypes.any,
  onFromTimeChange: PropTypes.func,
  disableFromTimePast: PropTypes.any,
  fromTimeMin: PropTypes.any,

  toTimeLabel: PropTypes.string,
  toTimeValue: PropTypes.any,
  onToTimeChange: PropTypes.func,
  disableToTimePast: PropTypes.any,
  toTimeMin: PropTypes.any
};
