import React from 'react'
import {
    Grid, Typography,
    Button,
    IconButton,
    Divider,
    withStyles
} from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { formatDate } from '../../../utils/common';
import withAlelrtAndDialog from '../../../HOCs/withAlelrtAndDialog';
import ProfileDrawer from '../../profileDrawer';

const style = ((theme) => ({
    listWrapper: {
        width: "100%",
        display: "flex"
    },
    addIdButtonText: {
        color: theme.palette.ternary.main,
        fontWeight: 600
    },
    gutter: {
        padding: "16px 16px"
    },
    gutterTop: {
        padding: "16px 16px 0px"
    },
    gutterMiddle: {
        padding: "0px 16px"
    },
    gutterBottom: {
        padding: "0px 16px 16px"
    },
    listItemSecondaryAction: {
        paddingRight: 90
    },
    customListWrapper: {
        padding: "4px 16px 4px 16px"
    }
}))

class ViewAllCards extends React.Component {

    state = {
        tax: []
    }

    componentDidMount() {
        this.setState({
            tax: [...this.props.items]
        })
    }


    callBackOnSaveTax = (data, isremove) => {
        if (isremove) {
            let isUpdateData = this.state.tax?.filter(
                (val) => val.value !== data?.value
            );

            this.setState({ tax: isUpdateData }, () => {
                this.handleBack(data, isremove)
            });
        }
    };

    handleBack = (data, isremove) => {
        this.props.dialog.setDialog({ open: false })
        this.props.onRemove(data, isremove)
        if (this.props?.profileDrawerProps) {
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                component: (
                    <ProfileDrawer
                        loginData={{ ...this.props?.profileDrawerProps?.loginData }}
                    />
                ),
                open: true,
            });
        }
    }

    handleDeleteDialog = (data, isremove) => {
        this.props.dialog.setDialog({
            open: true,
            title: "Are you sure?",
            body: `Do you want to delete this Tax: ${data?.label}`,
            positiveBtn: "Delete",
            negativeBtn: "Cancel",
            onOk: () => this.callBackOnSaveTax(data, isremove),
        });
    }

    render() {
        const { classes } = this.props
        const { tax } = this.state
        const { onAdd, onEdit, hideActionButtons, hideAdd } = this.props
        return (
            <div>
                <Grid container>
                    <Grid item container xs={12} className={classes.gutter}>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>TAX ID</Typography>
                        </Grid>
                        {!hideAdd && <Grid item xs={6} style={{ display: 'flex', justifyContent: "flex-end" }}>
                            <Button style={{ boxShadow: "none" }}>
                                <Typography id={this.props?.id?.add_drawer_tax_id} onClick={onAdd} variant={"body2"} className={classes.addIdButtonText}>+ ADD TAX ID</Typography>
                            </Button>
                        </Grid>}
                    </Grid>

                    <Grid item xs={12}>
                        <Divider light />
                    </Grid>

                    {tax?.map(item => {
                        return (
                            <Grid item xs={12} >
                                <div className={classes.customListWrapper} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ flex: 1 }}>
                                        <Typography variant={"body1"} >{item?.taxRegistrationNumber ? item?.taxRegistrationNumber : "NA"}</Typography>
                                        <Typography variant={"body2"} color={"textSecondary"} gutterBottom >{item?.label ? item?.label : null}</Typography>
                                        <div style={{ display: "flex", gap: 8 }}>
                                            <div>
                                                <Typography variant={"body2"}>Effective Date</Typography>
                                                <Typography variant={"body2"} color={"textSecondary"} >{item?.effective ? formatDate(item?.effective) : "NA"}</Typography>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Divider light orientation="vertical" style={{ maxHeight: 24 }} /></div>
                                            <div>
                                                <Typography variant={"body2"}>Expiry Date</Typography>
                                                <Typography variant={"body2"} color={"textSecondary"} >{item?.expiry ? formatDate(item?.expiry) : "NA"}</Typography>
                                            </div>
                                        </div>
                                        {/* <Typography variant={"body2"} color={"textSecondary"} >{`${item?.effective ? formatDate(item?.effective) : "NA"}`}</Typography> */}
                                        {/* <Typography variant={"body2"} color={"textSecondary"} >{`${item?.expiry ? formatDate(item?.expiry) : "NA"}`}</Typography> */}
                                    </div>
                                    {!hideActionButtons && <div style={{ height: "100%" }}>
                                        <IconButton id={this.props?.id?.edit_tax_icon_id} onClick={() => onEdit(item)} edge="end" aria-label="edit" style={{ border: "1px solid #E8E8EC", margin: "0px 4px", padding: "4px" }}>
                                            <EditOutlinedIcon fontSize={"small"} />
                                        </IconButton>
                                        <IconButton id={this.props?.id?.delete_tax_icon_id} onClick={() => { this.handleDeleteDialog(item, true) }} edge="end" aria-label="delete" style={{ border: "1px solid #E8E8EC", margin: "0px 4px", padding: "4px" }}>
                                            <DeleteOutlinedIcon fontSize={"small"} />
                                        </IconButton>
                                    </div>}


                                    {/* <List dense={true} style={{ width: "100%" }} disablePadding>
                                                    <ListItem divider classes={{ secondaryAction: classes.listItemSecondaryAction, gutters: classes.listItemGutters }}>
                                                        <ListItemText
                                                            classes={{ primary: classes.primaryText }}
                                                            primary={item?.label ? item?.label : null}
                                                            secondary={item?.taxRegistrationNumber ? item?.taxRegistrationNumber : "NA"}
                                                        />
                                                    </ListItem>
                                                    <ListItemSecondaryAction>
                                                        <IconButton id={this.props?.id?.edit_tax_icon_id} onClick={() => this.onEdit(item)} edge="end" aria-label="edit" style={{ border: "1px solid #E8E8EC", margin: "0px 4px", padding: "4px" }}>
                                                            <EditOutlinedIcon fontSize={"small"} />
                                                        </IconButton>
                                                        <IconButton id={this.props?.id?.delete_tax_icon_id} onClick={() => { this.callBackOnSaveTax(item, true) }} edge="end" aria-label="delete" style={{ border: "1px solid #E8E8EC", margin: "0px 4px", padding: "4px" }}>
                                                            <DeleteOutlinedIcon fontSize={"small"} />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </List> */}
                                </div>
                                <Divider light />
                                {/* <div className={classes.listWrapper}>
                                    <List dense={true} style={{ width: "100%" }} >
                                        <ListItem divider classes={{ secondaryAction: classes.listItemSecondaryAction}}>
                                            <ListItemText
                                                primary={item?.label ? item?.label : null}
                                                secondary={item?.taxRegistrationNumber ? item?.taxRegistrationNumber : "NA"}
                                            />
                                            <ListItemSecondaryAction>
                                                <div style={{ display: "flex", gap: 16 }}>
                                                    <IconButton id={this.props?.id?.edit_drawer_tax_icon_id} onClick={() => onEdit(item)} edge="end" aria-label="edit" size={"small"}>
                                                        <EditOutlinedIcon fontSize={"small"} />
                                                    </IconButton>
                                                    <IconButton id={this.props?.id?.delete_drawer_tax_icon_id} onClick={() => {this.callBackOnSaveTax(item, true)}} edge="end" aria-label="delete" size={"small"}>
                                                        <DeleteOutlinedIcon fontSize={"small"} />
                                                    </IconButton>
                                                </div>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </div> */}
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        )
    }
}

export default withAlelrtAndDialog(withStyles(style)(ViewAllCards))
