import React from "react";
import { Grid, withStyles } from "@material-ui/core";
// import CardList from "../../../../components/userList";
import SubHeader from "../../../../components/subHeader";
import {
  // careProviderDetailsUrl,
  filterTagType,
  fontStyleClass,
  // productSortDirectionOption,
  scrollToTop,
  // sortDirection,
  // sortFields,
  // subHeaderSortOption,
  subHeaderSortOptionWithValue,
  user_roles,
} from "../../../../utils";
import { routes } from "../../../../router/routes";
import {
  FilterBuilder,
  InternatlServerError,
  NotFoundSection,
  // ListCard
} from "../../../../components";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { filterComponent } from "./filterComponent";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
// import { errorMessage } from "../../../../utils/errorMessages";
import FilterTags from "../../../../components/filters/filterTags";
import {
  actionbuttonNameBasedOnPersona,
  viewScreensBasedOnPersona,
  actionbuttonUrlBasedOnPersona,
  totalCountLabel,
  getPersonaId,
  giveMeEmptyMessage,
  personaName,
} from "../utils";
import { Catalogue } from "../../../../components/catalogue/catalogue";
import { isTrialAccountType,checkTrialExpiryRole } from "../../../../utils/common";

// const sizes = {
//   md: 3,
//   sm: 3,
//   xs: 3,
// };

const styles = (theme) => ({
  root: {
    padding: 24,
    [theme.breakpoints.down("xs")]: {
      padding: 16,
    },
  },
  ...fontStyleClass,
});

const initialState = {
  pageNo: 0,
  pageSize: 25,
  careProviderName: "",
  category: [],
  provider: [],
  status: undefined,
  products: [],
  date: null,
  isFilterApplied: false,
  sortDirec: subHeaderSortOptionWithValue[2],
  // sortDirec: productSortDirectionOption[1],
  searchValue: "",
};

class ResourceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      id: this?.props?.id,
    };
  }

  componentDidMount() {
    this.getCareProviderList();
    scrollToTop();
    window.addEventListener("scroll", this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  }

  scrolling = () => {
    let { loading, data, error } = this.props?.allPersonaResource;
    let listCount = data?.length ?? 0;
    let totalCount = data?.[0]?.total_count ?? 0;

    if (loading || error || totalCount === 0 || listCount === 0) {
      return false;
    }

    if (
      Math.round(window.scrollY + window.innerHeight) >=
        document.getElementsByTagName("body")[0].scrollHeight &&
      listCount !== totalCount
    ) {
      this.setState(
        (state, props) => ({
          pageNo: state.pageNo + 25,
        }),
        () => {
          this.getCareProviderList();
        }
      );
    }
  };

  //   parentPartnerId - null - all branches
  // parentPartnerId - "" - all Partners
  // parentPartnerId - UUID - partcular branches
  getCareProviderList = () => {
    console.log(this.props.auth_data, "auth_datav");

    let getIdNameType = getPersonaId(this.props?.match?.path);
    let _status = undefined;
    if (this.state?.status) {
      if (this.state.status.toLowerCase() === "Active".toLowerCase())
        _status = true;
      if (this.state.status.toLowerCase() === "InActive".toLowerCase())
        _status = false;
    }

    this.props.GET_ALL_PERSONA_RESOURCE({
      // ...this.state,
      // category: this.state.category.map((_) => _.value),
      // products: this.state.products.map((_) => _.value),
      // status: this.state.status,
      // sortDirec:
      //   this.state.sort === "az" ? sortDirection.asc : sortDirection.desc,
      // sortfield:
      //   this.state.sort === "az" || this.state.sort === "za"
      //     ? sortFields.provider.name
      //     : sortFields.provider.created_at,
      filter: {
        category:
          this.state?.category?.length > 0
            ? this.state.category.map((_) => _.value)
            : [],
        status: _status,
        partnerIds: this.state?.provider
          ? this.state.provider.map((_) => _.value)
          : [],
        sort: this.state?.sortDirec?.value,
      },
      limit: this.state.pageSize,
      offset: this.state.pageNo,
      searchValue: this.state.searchValue,
      resourceTypeId: getIdNameType.id,
      resourceTypeName: getIdNameType.name,
    });
  };

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  applyFilter = (state) => {
    this.setState(
      {
        ...state, //This is obtained from FilterBuilder Component
        pageNo: 0,
        pageSize: 25,
        isFilterApplied: true,
      },
      () => {
        this.getCareProviderList();
        this.closeFilter();
      }
    );
  };

  resetFilter = () => {
    this.setState(
      {
        ...initialState,
      },
      () => {
        this.getCareProviderList();
        this.closeFilter();
      }
    );
  };

  closeFilter = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  storeInputSearchValue = (value) => {
    this.setState({ searchValue: value, pageNo: 0, pageSize: 25 }, () => {
      // if (value.length % 3 === 0) {
      this.getCareProviderList();
      // }
    });
  };

  onFilterIconClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <FilterBuilder
          components={filterComponent(this.state, this.props)}
          applyButtonLabel="Apply"
          loading={this.props?.allPersonaResource?.loading}
          onChange={this.onFilterChange}
          onApplyButtonClicked={this.applyFilter}
          resetFilter={this.resetFilter}
          onCancelClick={this.closeFilter}
          saveButtonId={`saveFilterButtonFrom${
            this.state?.id ? this.state?.id : ""
          }`}
          cancelButtonId={`cancelFilterButtonFrom${
            this.state?.id ? this.state?.id : ""
          }`}
          clearAllButtonId={`clearAllFilterButtonFrom${
            this.state?.id ? this.state?.id : ""
          }`}
        />
      ),
    });
  };

  handleSortChange = (e) => {
    this.setState(
      {
        sort: e,
        pageNo: 0,
        pageSize: 25,
      },
      () => {
        this.getCareProviderList();
      }
    );
  };

  checkIsThereAnyFilter = () => {
    const newState = this.state;
    if (
      newState[filterTagType.Category].length === 0 &&
      newState[filterTagType.Provider].length === 0 &&
      newState[filterTagType.Status] === initialState.status
    ) {
      return false;
    } else return true;
  };

  handleChipDelete = (key, index) => {
    console.log("Key ", key);
    console.log("index ", index);
    if (key === filterTagType.Category || key === filterTagType.Provider) {
      let newState = this.state;

      newState[key].splice(index, 1);
      console.log(newState);

      this.setState(
        {
          ...newState,
          pageNo: 0,
          pageSize: 25,
          isFilterApplied: this.checkIsThereAnyFilter(),
        },
        () => {
          this.getCareProviderList();
        }
      );
    } else if (key === filterTagType.Status) {
      this.setState(
        {
          status: undefined,
          pageNo: 0,
          pageSize: 25,
        },
        () => {
          this.setState({
            isFilterApplied: this.checkIsThereAnyFilter(),
          });
          this.getCareProviderList();
        }
      );
    } else if (key === filterTagType.SortDirection) {
      this.setState(
        {
          sortDirec: subHeaderSortOptionWithValue[2],
          pageNo: 0,
          pageSize: 25,
        },
        () => {
          this.setState({
            isFilterApplied: this.checkIsThereAnyFilter(),
          });
          this.getCareProviderList();
        }
      );
    }
  };

  mapper = (data = []) => {
    // REMOVING BRANCHES FOR PROVIDER LOGIN
    let newArray;
    if (data?.length > 0) {
      newArray = data.map((val) => {
        if (user_roles.provider === this.props.auth_data.data.user_role) {
          let newOrderList = val.orderList.filter(
            (val) => val.name !== "Branches"
          );

          val = { ...val, orderList: newOrderList };
        }
        return val;
      });
    }

    return newArray;
  };
  //Navigate to detail page
  navigateToView = (id) => {
    this.props.history.push(
      viewScreensBasedOnPersona(this.props.match.path) + id
    );
  };
  render() {
    console.log(this.props?.allPersonaResource);

    let { loading, data, error } = this.props?.allPersonaResource;

    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid>
          <Grid item xs={12}>
            <SubHeader
              id={`${this.state?.id ? this.state?.id : ""}`}
              isbutton={
                routes.partner_management_branch === this.props?.match?.path ||
                Boolean(
                  this.props?.auth_data?.data?.user_role ===
                    user_roles.service_coordinator
                ) || Boolean(this.props.auth_data.data.user_role === user_roles.business_admin)
                  ? false
                  : true
              }
              placeholder={
                this.props.match.path === routes.manage_other_resources
                  ? `Search for other resources`
                  : `Search for ${personaName(this.props.match.path)} Name`
              }
              // , Email and Mobile Number
              entityName={
                this.props.match.path === routes.manage_other_resources
                  ? `other resources`
                  : `${personaName(this.props.match.path)}`
              }
              countText={`${totalCountLabel(this.props?.match?.path)} (${
                data?.[0]?.total_count ?? 0
              })`}
              button_name={actionbuttonNameBasedOnPersona(
                this.props.match.path
              )}
              link={actionbuttonUrlBasedOnPersona(this.props?.match?.path)}
              //   (this.props.match.path)
              handleSearch={this.getCareProviderList}
              handleChangeInputSearch={this.storeInputSearchValue}
              value={this.state.searchValue}
              useNewFilterIcon
              placeSearchIconToLeft
              isFilterApplied={this.state.isFilterApplied}
              onFilterIconClicked={this.onFilterIconClicked}
              showSortOption={true}
              sort={this.state.sort}
              handleSortChange={this.handleSortChange}
              loading={loading}
              // Temporarily hidden
              // hideFilter
              // hideSearch
            />
          </Grid>

          {this.state.isFilterApplied && (
            <Grid item xs={12} style={{ marginTop: 25 }}>
              <FilterTags
                filterData={this.state}
                handleDelete={this.handleChipDelete}
              />
            </Grid>
          )}

          {/*TOTAL COUNT  */}
          {/* <Grid item xs={12}>
            <Typography variant="h6" style={{ paddingTop: 25 }} className={classes.mediumFont}>
              {`${totalCountLabel(this.props?.match?.path)} ${data?.[0]?.total_count ?? 0}`}
            </Typography>
          </Grid> */}

          {!loading && error && (
            <InternatlServerError
              message="Something went wrong, Try Again!"
              onRetry={this.getCareProviderList}
            />
          )}
          {!loading && !error && !data?.length && (
            <NotFoundSection
              message={giveMeEmptyMessage(
                this.state.filter,
                this.state.careProviderName,
                this.props.match.path
              )}
            />
          )}

          {/* {console.log(data)} */}

          {/* List */}
          {!error && (
            <Grid item xs={12} style={{ paddingTop: "25px" }}>
              {/* <CardList
                isShowGender={data}
                isShowAge={data}
                isShowRole={data}
                isShowVerified={data}
                list={data?.constructor === Array ? data : []}
                loading={loading}
                componentOrder={1}
                sizes={sizes}
                Url={viewScreensBasedOnPersona(this.props.match.path)}
                countName="Clients"
                isCard={true}
                hideQuickSummaryLabel={true}
                hideQuickSummary={true}
                displayProvider={true}
              /> */}
              <Catalogue
                id={this.props?.id}
                catalogues={Array.isArray(data) ? data : []}
                redirectUrl={viewScreensBasedOnPersona(this.props.match.path)}
                loading={loading}
                showProvider
                showTrialExpiry={
                  // If it is trial Account Type
                  isTrialAccountType() && checkTrialExpiryRole(this.props.auth_data.data.user_role) && 
                  // If the url path is not other resource listing (no need to show expiry for other resource listing & detail)
                  this.props?.match?.path !== routes.manage_other_resources
                }
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allPersonaResource: state?.resourceReducer?.allPersonaResource,
  auth_data: state?.authState?.loginAuth,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(ResourceList))));
