import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, IconButton, withStyles, withTheme } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DenseSwitch from "../switch/denseSwitch";

// const MINWIDTH = {
//   xs: 100,
//   sm: "fit-content",
//   md: "fit-content",
//   lg: "fit-content",
//   xl: "fit-content",
// };

const styles = (theme) => ({
  root: {
    width: "100%",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#fafafa",
      // padding: "8px",
      padding: theme.spacing(0.5, 1),
    },
    "& th": {
      borderBottom: "none !important",
      color: theme.palette.secondary.main,
    },
    "& td": {
      // padding: 12,
      padding: theme.spacing(0.25, 1.5),
      borderBottom: `1px solid ${theme.palette.secondary.light} !important`,
    },
  },
  remove: {
    color: theme.palette.ternary.main,
    fontSize: "0.7rem",
    boxShadow: "none !important",
  },
  removeIconButtonsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  removeIconButtons: {
    padding: 4, //sets the ripple radius
  },
  iconColor: {
    color: theme.palette.secondary.main,
  },

  // tableCellMinWidths should be before the spreaded classes to avoid overriding the custom widths
  tableCellMinWidths: {
    minWidth: "fit-content",
  },
});

class AdminTable extends React.Component {
  render() {
    const { classes, theme } = this.props;
    const {
      rows,
      columns,
      handleRemove,
      needActionsAsIcons,
      withoutRemove,
      editAction,
      deleteAction,
    } = this.props;

    const TableHeadCustomRow = (props) => {
      const { classes } = this.props;
      const { column, i } = props;
      return (
        <>
          <TableCell
            key={column.id}
            align={column.align}
            className={classes.tableCellMinWidths}
            style={{width: column.id === "sno" ? 50 : "fit-content"}}
          >
            {column.label}
          </TableCell>
          {!needActionsAsIcons && columns.length - 1 === i && (
            <TableCell key="remove" align="right"></TableCell>
          )}
        </>
      );
    };
    return (
      <>
        <TableContainer className={classes.root}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{ backgroundColor: "red" }}>
                {columns.map((column, i) => (
                  <TableHeadCustomRow
                    {...this.props}
                    column={column}
                    i={i}
                    minWidth={column.minWidth}
                  />
                ))}
                {needActionsAsIcons && (
                  <TableCell key="remove" align="center">
                    Actions
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows?.length > 0 ? (
                rows.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row?.uniqueId}
                    >
                      {columns.map((column, i) => {
                        console.log(column)
                        const value = row[column.id] ? row[column.id] : "N/A";
                        return (
                          <>
                            {column.id === "sno" ? (
                              <TableCell key={column.id} align={column.align}>
                                {index + 1}
                              </TableCell>
                            ) : (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            )}

                            {!withoutRemove &&
                              !needActionsAsIcons &&
                              columns.length - 1 === i && (
                                <TableCell key="remove" align="right">
                                  <Button
                                    variant="text"
                                    className={classes.remove}
                                    disabled={this.props.disableRemove}
                                    onClick={(e) => {
                                      handleRemove(row);
                                    }}
                                    id={`removeAdminRecord${index}`}
                                  >
                                    REMOVE
                                  </Button>
                                </TableCell>
                              )}

                            {!withoutRemove &&
                              needActionsAsIcons &&
                              columns.length - 1 === i && (
                                <TableCell key="remove" align="center">
                                  <div
                                    className={
                                      classes.removeIconButtonsContainer
                                    }
                                  >
                                    <IconButton
                                      onClick={() => editAction(row)}
                                      id={`editAdminRecord${index}`}
                                      className={classes.removeIconButtons}
                                    >
                                      <CreateOutlinedIcon
                                        fontSize="small"
                                        htmlColor={theme.palette.secondary.main}
                                      />
                                    </IconButton>
                                    <DenseSwitch
                                      id={`removeAdminRecord${index}`}
                                      value={row?.isActive}
                                      handleOnChange={(e) =>
                                        deleteAction(e, row)
                                      }
                                    />
                                  </div>
                                </TableCell>
                              )}
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                // If length of rows array of object is 0(zero), then render this
                <TableRow style={{ textAlign: "center" }}>
                  <TableCell
                    key="remove"
                    colSpan={this.props.columns.length || 0}
                    align="center"
                    style={{ width: "100%" }}
                  >
                    No Records found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default withTheme(withStyles(styles)(AdminTable));
