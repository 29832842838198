import React, { useState } from "react";
import {
  Grid,
  // Button,
  // withStyles,
  Typography,
  // Hidden,
  IconButton,
  // Paper,
  // Select,
  // MenuItem,
  // FormControl,
  LinearProgress,
  makeStyles,
  Tooltip,
  // FormControl,
  // Select,
  // MenuItem,
} from "@material-ui/core";
import InputField from "./InputField";
import Filter from "./filter";
import { NavLink } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
// import { SimpleSelect } from "../../screens/formConfigurator/components/mobile/select";
import { StyledMaterialUISelect } from "../styledInputs";
// import { subHeaderSortOption } from "../../utils";
// import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
// import SortIcon from "@material-ui/icons/Sort";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import { useOutlineSelectStyles } from "./sortStyle";
// import SlidingSearchBox from "./slidingSearchBox";

const filterOptions = [
  {
    label: "Z to A",
    name: "Z to A",
    value: 2,
  },
  {
    label: "A to Z",
    name: "A to Z",
    value: 1,
  },
];


const useStyles = makeStyles((theme) => ({
  headerPos: {
    // position: "fixed",
    // width: "calc(100% - 290px)",
    // background: "#f4f4f5",
    // paddingBottom: "8px",
    zIndex: 3,
    // [theme.breakpoints.between('xs', 'sm')]: {
    //     width: "90%",
    // },
    // [theme.breakpoints.between('sm', 'md')]: {
    //     width: "95%",
    // },
  },
  flexEnd: {
    // marginTop: 4,
    justifyContent: "flex-end",
    display: "flex",
  },
  navlink: {
    textDecoration: "none",
  },
  paperStyle: {
    borderRadius: 8, //"50%"
    boxShadow:
      "0px 6px 6px -10px rgb(0 0 0 / 9%), 0px 0px 0px 0px rgb(0 0 0 / 12%), 0px 0px 0px 0px rgba(0,0,0,0.12)",
    backgroundColor: theme.palette.primary.main,
    height: 48,
  },
  addIconButton: {
    backgroundColor: theme.palette.ternary.main,
    "&:hover": {
      backgroundColor: theme.palette.ternary.dark,
    },
  },
  formControl: {
    margin: "0px 16px 0px 8px",
  },
  customSelect: {
    padding: "10.500px 14px",
  },
  verticalCenter: {
    margin: "auto",
  },
  addIconButtonRoot: {
    boxShadow: theme.palette.primary.boxShadow,
  },
  formSelectControl: {
    [theme.breakpoints.down("xs")]: {
      // marginTop: 8,
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        // borderColor: `${theme.palette.ternary.main} !important`,
        borderWidth: "1.6px !important",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 16,
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      borderRadius: 16,
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  colorPrimary: {
    color: "white"
  },
}));
// const sortStyle = makeStyles((theme) => useOutlineSelectStyles(theme));
const SubHeader = (props) => {
  // const outlineSelectClasses = sortStyle();
  // const iconComponent = (props) => {
  //   return (
  //     <ExpandMoreRoundedIcon
  //       className={props.className + " " + outlineSelectClasses.icon}
  //     />
  //   );
  // };
  // const menuProps = {
  //   classes: {
  //     paper: outlineSelectClasses.paper,
  //     list: outlineSelectClasses.list,
  //   },
  //   anchorOrigin: {
  //     vertical: "bottom",
  //     horizontal: "left",
  //   },
  //   transformOrigin: {
  //     vertical: "top",
  //     horizontal: "left",
  //   },
  //   getContentAnchorEl: null,
  // };
  const {
    // classes,
    isbutton,
    isDrawerButton=false,
    // button_name,
    link,
    // cornerCount,
    hideFilter,
    hideSearch,
    // showSortOption,
    // sort,
    // handleSortChange,
    loading,
    id,
    showSort,
  } = props;
  const classes = useStyles(props);
  const [sortValue, setSortValue] = useState(1)
  return (
    <div>
      {" "}
      {/**style={{ height: 45 }} */}
      <Grid container className={classes.headerPos} spacing={1}>
        <Grid container item xs={12} spacing={1}>
          {props.countText && (
            <Grid item style={{ margin: "auto" }}>
              <Typography variant={"h6"}>{props.countText}</Typography>
            </Grid>
          )}

          {/* TODO: Revert This hidden prop - xsDown */}
          {/* <Hidden xsDown>
            {!hideFilter && <Grid item>
              <div style={{ marginLeft: 16 }}>
                <Filter
                  isFilterApplied={props.isFilterApplied}
                  useNewFilterIcon={props.useNewFilterIcon}
                  onFilterIconClicked={props.onFilterIconClicked} />
              </div>
            </Grid>}
          </Hidden> */}
          <Grid item xs></Grid>

          {/* {!hideSearch && (
            <Grid item>
              <SlidingSearchBox {...props} />
            </Grid>
          )} */}

          {!hideFilter && (
            <Grid item>
              <div style={{ marginRight: 8 }}>
                <Filter
                  id={`addFilterButtonFrom${id ? id : ""}`}
                  field_id={id}
                  isFilterApplied={props.isFilterApplied}
                  useNewFilterIcon={props.useNewFilterIcon}
                  onFilterIconClicked={props.onFilterIconClicked}
                />
              </div>
            </Grid>
          )}

          {!hideSearch && (
            <Grid item xs={12} sm>
              <InputField
                {...props}
                borderRadius={16}
                id={`addInputSearchFrom${id ? id : ""}`}
              />
            </Grid>
          )}
          {showSort && (
            // <FormControl
            //   variant="outlined"
            //   {...props}
            //   className={classes.formSelectControl}
            // >
            //   <Select
            //     value={sortValue}
            //     id={`sortAdmintable`}
            //     onChange={(e) => {props.onSortChange("sortKey", e.target.value);setSortValue(e.target.value)}}
            //     style={{
            //       width: 150,
            //       height: 48,
            //     }}
            //     name="filter"
            //     classes={{ outlined: classes.customSelect }}
            //   >
            //     {filterOptions?.map((item, index) => {
            //       return (
            //         <MenuItem key={item.value} value={item.value}>
            //           {item.label}
            //         </MenuItem>
            //       );
            //     })}
            //   </Select>
            // </FormControl>
            // -----------------
            <Grid item>
              <div style={{minWidth: 120, height: "100%" ,display: "flex", alignItems: "center"}}>
            <StyledMaterialUISelect
              value={sortValue}
              handleChange={(e) => {props.onSortChange("sortKey", e.target.value);setSortValue(e.target.value)}}
              options={filterOptions}
            />
            </div>
            </Grid>
          )}

          {isbutton && (
            <Grid item>
              <NavLink to={link ? link : "#"} className={classes.navlink}>
                {/* <Paper className={classes.paperStyle}> */}
                <Tooltip title={`Add ${props.entityName ?? ""}`}>
                  <IconButton
                    aria-label={`Add ${props.entityName}`}
                    className={classes.addIconButton}
                    classes={{ root: classes.addIconButtonRoot }}
                    id={`addNewButtonFrom${id ? id : ""}`}
                    field_id={id}
                  >
                    <AddIcon htmlColor="white" />
                  </IconButton>
                </Tooltip>
                {/* </Paper> */}
              </NavLink>
            </Grid>
          )}

          {
            isDrawerButton && (
              <Grid item>
              {/* <NavLink to={link ? link : "#"} className={classes.navlink}> */}
                {/* <Paper className={classes.paperStyle}> */}
                <Tooltip title={`Add ${props.entityName ?? ""}`}>
                  <IconButton
                    aria-label={`Add ${props.entityName}`}
                    className={classes.addIconButton}
                    classes={{ root: classes.addIconButtonRoot }}
                    id={`addNewButtonFrom${id ? id : ""}`}
                    field_id={id}
                    style={{padding: 5, marginTop: 7}}
                    onClick={() => props.onDrawerButtonClicked()}
                  >
                    <AddIcon htmlColor="white" />
                  </IconButton>
                </Tooltip>
                {/* </Paper> */}
              {/* </NavLink> */}
            </Grid>
            )
          }

          {/* {!hideSearch && (
            <Grid item>
              <InputField {...props} borderRadius={20} />
            </Grid>
          )} */}

          {/* {showSortOption && (
            <Hidden xsDown>
              <Grid item>
                <FormControl>
                  <Select
                    value={sort}
                    onChange={(e) => handleSortChange(e.target.value)}
                    disableUnderline
                    classes={{ root: outlineSelectClasses.select }}
                    MenuProps={menuProps}
                    IconComponent={iconComponent}
                  >
                    {subHeaderSortOption.map((sortOption, key) => (
                      <MenuItem key={key} value={sortOption.value}>
                        <ListItemIcon
                          classes={{ root: outlineSelectClasses.listIcon }}
                        >
                          <SortIcon />
                        </ListItemIcon>
                        <span style={{ marginTop: 3 }}>{sortOption.label}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Hidden>
          )} */}
        </Grid>

        {/* <Grid container item xs={12} justify={"flex-start"}> */}

        {/* </Grid> */}

        {/* THis corner count is shown only in subscriptions */}
        {/* {cornerCount && (
          <Grid
            container
            item
            className={classes.flexEnd}
            md={6}
            sm={6}
            xs={6}
          >
            <Typography variant="body1" align="right">
              {cornerCount}
            </Typography>
          </Grid>

        )}  */}
      </Grid>
      {loading && <LinearProgress />}
    </div>
  );
};

export default SubHeader;

SubHeader.propTypes = {
  /**
   * To use new filter Icon
   */
  useNewFilterIcon: PropTypes.bool,
  /**
   *  Whether the search icon is placed to the left or right
   */
  placeSearchIconToLeft: PropTypes.bool,
  /**
   * Whether any filters applied
   */
  isFilterApplied: PropTypes.bool,
  /**
   * Opens the filter drawer.
   */
  onFilterIconClicked: PropTypes.func,
  /**
   * Whether sort option should be shown or not.
   */
  showSortOption: PropTypes.bool,
  /**
   * Either of these values az, za, most_recent.
   */
  sort: PropTypes.string,
  /**
   * Handles the sort change.
   */
  handleSortChange: PropTypes.func,
  /**
   * Loading of data by applying the sort parameters.
   */
  loading: PropTypes.bool,
};
