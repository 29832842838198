import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types';
import { createTestProps } from '../../utils/common';

const useStyles = makeStyles((theme)=>({
    closeSectionWrapper:{
        '& .title':{
            fontSize:18,
            fontWeight:500,
            marginBottom:24,//theme.spacing(3),
            textAlign:'center'
        },
        '& .desc':{
            fontSize:16,
            fontWeight:400,
            marginBottom:24,//theme.spacing(3),
            textAlign:'center'
        },
    },
    actionBtns:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        gap:16,//theme.spacing(2),
        '& .MuiButtonBase-root':{
            textTransform:'capitalize',
            height:40,
        },
        '& .MuiButton-outlined':{
            minWidth:84,
            // color:'#FF4D4A',
            // borderColor:'#D8D8D8',
        },
        '& .MuiButton-contained':{
            minWidth:113,
        },
    },
}))

export const ConfirmationRequired = (props) =>{

    const classes = useStyles(props); 

    const {
        title="Confirmation Required",
        description="Are you sure want to proceed without saving added details",
    } = props;

    const positiveButton={
        onClick:null,
        ...props?.positiveButton,
        name: props?.positiveButton?.name ?? 'Yes,Cancel',
    }

    const negativeButton={
        onClick:null,
        ...props?.negativeButton,
        name: props?.negativeButton?.name ?? 'No',
    }

    return(
        <div className={classes.closeSectionWrapper}>
            {title && <Typography color="textPrimary" className="title">{title}</Typography>}
            {description && <Typography color="textSecondary" className="desc">{description}</Typography>}
            <div className={classes.actionBtns}>
                {negativeButton?.name && <Button color="primary" variant="outlined" onClick={negativeButton?.onClick} {...createTestProps("confirmation reject button")}>{negativeButton?.name}</Button>}
                {positiveButton?.name && <Button color="primary" variant="contained" onClick={positiveButton?.onClick} {...createTestProps("confirmation accept button")}>{positiveButton?.name}</Button>}
            </div>
        </div>
    )
}

ConfirmationRequired.propTypes={
    title:PropTypes.string,
    description:PropTypes.string,
    positiveButton:PropTypes.object,
    negativeButton:PropTypes.object,
}