import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import React from 'react';
import { monthHeader } from '../utils';
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval'
import moment from 'moment';
import CalenderCard from './calenderCard';

const styles = (theme) => ({
    monthly_root: {
        width: '100%'
    },
    table: {
        [theme.breakpoints.down("md")]: {
            // width: 1094,
        },
        backgroundColor: 'white',
    },
    tableCell: {
        minWidth: 154,
        height: 150,
        border: `0.2px solid ${theme.palette.secondary.light}`,
        fontWeight: "bold",
        verticalAlign: 'top'
    },
    tableHeaderCell: {
        border: `0.2px solid ${theme.palette.secondary.light}`,
        backgroundColor: '#e9e9e9',
        fontWeight: "bold",
    },
    otherMonth: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.light
    }
})

class MonthlyCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    giveMeRows = () => {
        const {
            selectedDate,
            data,
            loading,
            onActivityClicked,
            onAvailabilityClicked,
            onUnAvailabilityClicked,
        } = this.props;


        let weeks = [];
        const month = selectedDate.get('month');

        const weekDays = eachWeekOfInterval({
            start: new Date(moment(selectedDate).startOf('month')),
            end: new Date(moment(selectedDate).endOf('month'))
        }, {
            weekStartsOn: 1
        })

        weeks = weekDays.map((weekfirstDay, i) => {
            const week = [];
            for (let index = 0; index < 7; index++) {
                const day = moment(weekfirstDay).add(index, 'days');

                let component = <></>;

                if (!data || data.length === 0 || !data[day.get('date') - 1]) {
                    component = <></>;
                } else if (month === day.get('month')) {
                    let { events, availability, unavailability } = data[day.get('date') - 1];
                    component = <>
                        {!loading && events?.length > 0 && <div style={{ cursor: "pointer" }} onClick={() => onActivityClicked(events)}><CalenderCard title={`${events.length} IN ACTIVITY`} style={{ marginTop: 8 }} variant="activities" /></div>}
                        {!loading && availability?.length > 0 && <div style={{ cursor: "pointer" }} onClick={() => onAvailabilityClicked(availability)}><CalenderCard title={`${availability.length} AVAILABLE`} style={{ marginTop: 8 }} variant="available" /></div>}
                        {!loading && unavailability?.length > 0 && <div style={{ cursor: "pointer" }} onClick={() => onUnAvailabilityClicked(unavailability)}><CalenderCard title={`${unavailability.length} UNAVAILABLE`} style={{ marginTop: 8 }} variant="unavailable" /></div>}
                    </>
                }

                week.push({
                    label: day.format('DD'),
                    value: day,
                    isOtherMonth: month !== day.get('month'),
                    component
                })
            }
            return week;
        })

        return weeks;
    }

    render() {

        const { classes } = this.props;

        const headers = monthHeader;
        const rows = this.giveMeRows();

        return <div className={classes.monthly_root}>
            <Table
                className={classes.table}
                stickyHeader={true}>
                <TableHead>
                    <TableRow >
                        {headers.map((header, key) => {
                            return <TableCell id={header.value} key={key} className={classes.tableHeaderCell} align="center">{header.label}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows.map((row, key) => (
                            <TableRow key={key}>
                                {row.map((date, key) => (
                                    <TableCell key={key} className={`${classes.tableCell} ${date.isOtherMonth ? classes.otherMonth : ''}`}>
                                        {date.label}
                                        {date.component}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </div>
    }
}

export default withStyles(styles)(MonthlyCalendar);