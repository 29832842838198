import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    padding: theme.spacing(4),
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    borderBottom: `2px solid ${theme.palette.secondary.light}`
  },
  flexRightSide: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
}))

export const TrackerLoading = (props) => {

  const classes = useStyles();

  return <>
    <Grid container className={classes.root} >
      <Grid item container xs>
        <Grid item>
          <Skeleton variant="rect" width="60px" height="60px" style={{ marginRight: 15 }} />
        </Grid>
        <Grid item style={{ margin: 'auto 0px' }}>
          <Skeleton variant="text" width="160px" />
          <Skeleton variant="text" width="120px" style={{ marginTop: 8 }} />
        </Grid>
      </Grid>
      <Grid item>
        <div className={classes.flexRightSide}>
          <Skeleton variant="text" width="100px" />
        </div>
      </Grid>
    </Grid>
  </>
}
