import React, { Component } from 'react'
import ActivityAndMembersCard from '../../../../components/availabilityActivityComponents/ActivityAndMembersCard'
import { Typography } from '@material-ui/core'

export default class ViewServiceCoordinators extends Component {

  onEndIconClickHandler = (id) => {
    
    if(id) this.props.onEndIconClickHandler(id,false)
  }


  render() {
    const { data, label } = this.props

    return (
      <div>
        <Typography variant="h6" style={{ padding: "14px 14px 14px 14px" }}>
          {data?.length ? data?.length : 0} {label}</Typography>
        {data && data?.map((item, index) => {
          
          return (
            <ActivityAndMembersCard
              key={index}
              data={item}
              id={item.value}
              profilePicture={item?.profile_pic}
              title={item?.label}
              // name={item?.label}
              timing={item?.resourceCategory?.map(val=>{return val.label}).join(' | ')}
              onEndIconClickHandler={this.onEndIconClickHandler}
              status={item?.isActive}
            />
          )
        })}
        {/* <Typography>View all Service Coordinator Screen Needed</Typography> */}
      </div>
    )
  }
}
