import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { routes } from "./routes";
import PrivateRoute from "./PrivateRoute";
import { useSelector } from "react-redux";

import {
  Login,
  UserManagement,
  NotFound,
  Dashboard,
  ProductManagement,
  Monitoring,
  ClientInfos,
  SalesOperation,
  TestingRoute,
  ChangePassword,
  PrivacyPolicy,
  Availability,
  ServiceProfessionalInfos,
  Administration,
  ResetPassword,
  ForgotPassword,
  PageExpired,
  PartnerManagement,
  ResourceManagement,
  FormConfigurator,
  // ResourceManagement,
  ManageForms,
  MobilePaymentRedirect,
  Reporting,
} from "./../screens";

import {
  // userManagementTopNavBars,
  // productManagementTopNavBars,
  salesOperationTabs,
  administrationManagementTopNavBars,
  availabilityManagementTopNavBars,
  productManagementTopNavBars,
  partnerManagementTopNavBars,
  resourceManagementTopNavBars,
  checkBackBasedOnRole,
  reportManagementTopNavBars,
} from "../utils";
import { managementRedirection } from "./access";
import TermsAndConditions from "../screens/TermsAndConditions";
import UserLeaveConfirmation from '../components/customLeaveConfirmationPopup';

const RouterApp = (props) => {
  const auth = useSelector((state) => state?.authState?.loginAuth);
  const user_role = auth?.data?.user_role;
  const other_roles = auth?.data?.other_roles

   // custom prompt state
   const [confirmOpen, setConfirmOpen] = React.useState(true);

   const toggleConfirmOpen = async (value=false) =>{
     setConfirmOpen(value);  
  }

  return (
    <Router getUserConfirmation={(message, callback) => {
      return UserLeaveConfirmation(
        message,
        callback,
        confirmOpen,
        toggleConfirmOpen
      );
    }

    }>
      <Switch>
        <Route exact path="/" component={Login} />

        {/* Login path */}
        <Route path={routes.login} component={Login} />
        <Route path={routes.forgotPassword} component={ForgotPassword} />
        <Route path={routes.expiredLink} component={PageExpired} />
        {/* <Route path={routes.resetPassword} component={ResetPassword} /> */}

        {/* New Client Enrollment Path */}
        <Route path={routes.changePassword} component={ChangePassword} />
        <Route path={routes.privacypolicy} component={PrivacyPolicy} />
        <Route
          path={routes.termsandconditions}
          component={TermsAndConditions}
        />

        {/* Client app mobile payment redirect path */}
        <Route
          path={routes.mobile_payment_redirect_ios}
          component={MobilePaymentRedirect}
        />
        <Route
          path={routes.mobile_payment_redirect}
          component={MobilePaymentRedirect}
        />

        <PrivateRoute path={routes.resetPassword}>
          <ResetPassword />
        </PrivateRoute>

        {/* Temporary Route */}
        <Route path={routes.testing_route} component={TestingRoute} />

        {/* Dashboard Route */}
        <PrivateRoute exact path={routes.dashboard}>
          <Dashboard title="Dashboard" />
        </PrivateRoute>

        {/* 5. Manage Form Router */}
        {/* 5.1 Manage Form */}
        <PrivateRoute exact path={routes.manage_forms}>
          <ManageForms title="Form Configurator" />
        </PrivateRoute>

        {/* 5.2 Form Configurator */}
        <PrivateRoute exact path={routes.manage_form_detail}>
          <FormConfigurator
            title="Form Configurator Details"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_forms}
          />
        </PrivateRoute>

        {/*User Management: Care Provider routes */}
        <PrivateRoute exact path={routes.manage_care_providers}>
          <UserManagement
            title="Resource Management"
            navBarArr={resourceManagementTopNavBars(user_role)}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.view_care_providers}>
          <UserManagement
            title="Partner Details"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_care_providers}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.add_care_providers}>
          <UserManagement
            title="Add Partner"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_care_providers}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.edit_care_providers}>
          <UserManagement title="Edit Partner" withBackButton={true} />
        </PrivateRoute>

        {/*User Management: Caregiver routes */}
        <PrivateRoute exact path={routes.manage_care_givers}>
          <ResourceManagement
            title="Resource Management"
            navBarArr={resourceManagementTopNavBars(user_role)}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.view_care_givers}>
          <ResourceManagement
            title="Service Professional Details"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_care_givers}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.add_care_givers}>
          <ResourceManagement
            title="Add Service Professional"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_care_givers}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.edit_care_givers}>
          <ResourceManagement
            title="Edit Service Professional"
            withBackButton={true}
          />
        </PrivateRoute>

        {/* RESOURCE MANAGEMENT ------------ OTHER RESOURCES */}
        <PrivateRoute exact path={routes.manage_other_resources}>
          <ResourceManagement
            title="Resource Management"
            navBarArr={resourceManagementTopNavBars(user_role)}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.add_other_resources}>
          <ResourceManagement
            title="Add Other Resource"
            // navBarArr={resourceManagementTopNavBars(user_role)}
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_other_resources}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.edit_other_resources}>
          <ResourceManagement
            title="Edit Other Resource"
            // navBarArr={resourceManagementTopNavBars(user_role)}
            withBackButton={true}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.view_other_resources}>
          <ResourceManagement
            title="Resource Management"
            // navBarArr={resourceManagementTopNavBars(user_role)}
            // userRole={user_role}
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_other_resources}
          />
        </PrivateRoute>

        {/*User Management: Care Coordinator routes */}
        <PrivateRoute exact path={routes.manage_care_coordinators}>
          <ResourceManagement
            title="Resource Management"
            navBarArr={resourceManagementTopNavBars(user_role)}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.view_care_coordinators}>
          <ResourceManagement
            title="Service Coordinator Details"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_care_coordinators}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.add_care_coordinators}>
          <ResourceManagement
            title="Add Service Coordinator"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_care_coordinators}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.edit_care_coordinators}>
          <ResourceManagement
            title="Edit Service Coordinator"
            withBackButton={true}
          />
        </PrivateRoute>

        {/*User Management: Client routes */}
        <PrivateRoute exact path={routes.manage_clients}>
          <UserManagement
            title="Client Management"
            // navBarArr={userManagementTopNavBars(user_role)}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.transactionReport}>
          <Reporting
            title="Reports"
            navBarArr={reportManagementTopNavBars(user_role, other_roles)}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.orderReport}>
          <Reporting
            title="Reports"
            navBarArr={reportManagementTopNavBars(user_role, other_roles)}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.enquiryReport}>
          <Reporting
            title="Reports"
            navBarArr={reportManagementTopNavBars(user_role, other_roles)}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.view_clients}>
          <UserManagement
            title="Client Details"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_clients}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.add_clients}>
          <UserManagement
            title="Add Client"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_clients}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.edit_clients}>
          <UserManagement title="Edit Client" withBackButton={true} />
        </PrivateRoute>

        {/*User Management: Supervisor routes */}
        <PrivateRoute exact path={routes.manage_supervisors}>
          <ResourceManagement
            title="Resource Management"
            navBarArr={resourceManagementTopNavBars(user_role)}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.view_supervisors}>
          <ResourceManagement
            title="Service Supervisor Details"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_supervisors}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.add_supervisors}>
          <ResourceManagement
            title="Add Service Supervisor"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_supervisors}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.edit_supervisors}>
          <ResourceManagement
            title="Edit Service Supervisor"
            withBackButton={true}
          />
        </PrivateRoute>

        {/*Product Management: Services routes */}
        <PrivateRoute exact path={routes.manage_service}>
          <ProductManagement
            title="Product Management"
            navBarArr={productManagementTopNavBars(user_role, other_roles)}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.view_service}>
          <ProductManagement
            title="Service Details"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_service}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.add_service}>
          <ProductManagement
            title="Add Service"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_service}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.edit_service}>
          <ProductManagement title="Edit Service" withBackButton={true} />
        </PrivateRoute>

        {/*Product Management: Packages routes */}
        <PrivateRoute exact path={routes.manage_package}>
          <ProductManagement
            title="Product Management"
            navBarArr={productManagementTopNavBars(user_role, other_roles)}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.view_package}>
          <ProductManagement
            title="Package Details"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_package}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.add_package}>
          <ProductManagement
            title="Add Package"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_package}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.edit_package}>
          <ProductManagement title="Edit Package" withBackButton={true} />
        </PrivateRoute>

        {/*Product Management: Products routes */}
        <PrivateRoute exact path={routes.manage_product}>
          <ProductManagement
            title="Product Management"
            navBarArr={productManagementTopNavBars(user_role, other_roles)}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.view_product}>
          <ProductManagement
            title="Product Details"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_product}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.add_product}>
          <ProductManagement
            title="Add Product"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_product}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.edit_product}>
          <ProductManagement
            title="Edit Product"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_product}
          />
        </PrivateRoute>
        
        {/*Product Management: Promotional Banner routes */}
        <PrivateRoute exact path={routes.manage_promotional_banner}>
          <ProductManagement
            title="Product Management"
            navBarArr={productManagementTopNavBars(user_role, other_roles)}
            userRole={user_role}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.view_promotional_banner}>
          <ProductManagement
            title="Promotional Banner Details"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_promotional_banner}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.add_promotional_banner}>
          <ProductManagement
            title="Add Promotional Banner"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_promotional_banner}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.edit_promotional_banner}>
          <ProductManagement
            title="Edit Promotional Banner"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_promotional_banner}
          />
        </PrivateRoute>

        {/* Availability Routes */}

        {/* 1. Calendar Routes */}
        <PrivateRoute exact path={routes.availability_calendar}>
          <Availability
            title="Availability"
            navBarArr={availabilityManagementTopNavBars(user_role)}
          />
        </PrivateRoute>

        {/*2. Manage Availibility Routes */}
        <PrivateRoute exact path={routes.manage_availability}>
          <Availability
            title="Availability"
            navBarArr={availabilityManagementTopNavBars(user_role)}
          />
        </PrivateRoute>

        {/* 2.a. Set New Availability Route */}
        <PrivateRoute exact path={routes.set_availability}>
          <Availability
            title="Set New Availability"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_availability}
          />
        </PrivateRoute>

        {/* 2.b. Edit Availability Route */}
        <PrivateRoute exact path={routes.edit_availability}>
          <Availability
            title="Edit Availability"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_availability}
          />
        </PrivateRoute>

        {/* 2.c. Add Exception Route */}
        <PrivateRoute exact path={routes.add_exception}>
          <Availability
            title="Add Exceptions"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_availability}
          />
        </PrivateRoute>

        {/* 2.b. Edit Exception Route */}
        <PrivateRoute exact path={routes.edit_exception}>
          <Availability
            title="Edit Exceptions"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_availability}
          />
        </PrivateRoute>

        {/* 3. View Availability History Route */}
        <PrivateRoute exact path={routes.view_availabiliy_history}>
          <Availability
            title="Availability History"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.manage_availability}
          />
        </PrivateRoute>

        {/* Monitoring routes */}

        {/* 1. Monitoring Service Professional routes */}
        <PrivateRoute exact path={routes.monitor_service_professionals}>
          <Monitoring title="Monitoring" />
        </PrivateRoute>

        {/* 1.a Monitoring Service Professional summary */}
        <PrivateRoute exact path={routes.monitor_service_professionals_summary}>
          <ServiceProfessionalInfos />
        </PrivateRoute>

        {/* 1.b Monitoring Service Professional schedule */}
        <PrivateRoute
          exact
          path={routes.monitor_service_professionals_schedules}
        >
          <ServiceProfessionalInfos />
        </PrivateRoute>

        {/* 1.c Monitoring Service Professional real time location */}
        <PrivateRoute
          exact
          path={routes.monitor_service_professionals_tracking}
        >
          <ServiceProfessionalInfos />
        </PrivateRoute>

        {/* 1.d Monitoring Service Professional clients */}
        <PrivateRoute exact path={routes.monitor_service_professionals_clients}>
          <ServiceProfessionalInfos />
        </PrivateRoute>

        {/* 1.e Monitoring Service Professional feedbacks */}
        <PrivateRoute
          exact
          path={routes.monitor_service_professionals_feebacks}
        >
          <ServiceProfessionalInfos />
        </PrivateRoute>

        {/* 2. Monitoring client routes */}
        <PrivateRoute exact path={routes.monitor_clients}>
          <Monitoring title="Monitoring" user_role={user_role} />
        </PrivateRoute>

        {/* 2.a Client Info routes */}
        <PrivateRoute exact path={routes.monitor_client_details}>
          <ClientInfos />
        </PrivateRoute>

        {/* 2.b Client Report routes */}
        <PrivateRoute exact path={routes.monitor_client_reports}>
          <ClientInfos />
        </PrivateRoute>

        {/* 2.c Client Issues routes */}
        <PrivateRoute exact path={routes.monitor_client_issues}>
          <ClientInfos />
        </PrivateRoute>

        {/* 2.c.1 Client Issues details */}
        <PrivateRoute exact path={routes.monitor_client_issue_detail}>
          <ClientInfos
            title={"Issue Details"}
            backUrl={routes.monitor_client_issue_parent}
          />
        </PrivateRoute>

        {/* 2.d Client Recommendation routes */}
        <PrivateRoute exact path={routes.monitor_client_recommendation}>
          <ClientInfos />
        </PrivateRoute>

        {/* 2.d.1 Client Recommendation Products routes */}
        <PrivateRoute exact path={routes.monitor_client_recommended_products}>
          <ClientInfos
            title={"Recommended Products"}
            backUrl={routes.monitor_client_recommendation_parent}
          />
        </PrivateRoute>

        {/* 2.d.2 Client Recommendation Product track routes */}
        <PrivateRoute
          exact
          path={routes.monitor_client_recommended_product_tracking}
        >
          <ClientInfos title={"Tracking"} />
        </PrivateRoute>

        {/* 2.e Client Activities routes */}
        <PrivateRoute exact path={routes.monitor_client_activities}>
          <ClientInfos />
        </PrivateRoute>

        {/* 2.f Client Rating routes */}
        <PrivateRoute exact path={routes.monitor_client_ratings}>
          <ClientInfos />
        </PrivateRoute>

        {/* 3.a Client Subscription */}
        <PrivateRoute exact path={routes.monitor_subscription}>
          <Monitoring title="Monitoring" />
        </PrivateRoute>

        {/* 3.b Client Subscription detail */}
        <PrivateRoute exact path={routes.monitor_subscription_detail}>
          <Monitoring
            title="Subscription Details"
            withBackButton
            isAvoidGoBack
            backUrl={routes.monitor_subscription}
          />
        </PrivateRoute>

        {/* Sales Operation routes */}
        <PrivateRoute exact path={routes.enquiry_list}>
          <SalesOperation
            title="Sales Operations"
            navBarArr={salesOperationTabs}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.add_enquiry}>
          <SalesOperation
            title="Add Enquiry"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.enquiry_list}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.edit_enquiry}>
          <SalesOperation title="Edit Enquiry" withBackButton={true} />
        </PrivateRoute>
        <PrivateRoute exact path={routes.view_enquiry}>
          <SalesOperation
            title="Enquiry details"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.enquiry_list}
          />
        </PrivateRoute>

        <PrivateRoute exact path={routes.administration_masters}>
          <Administration
            title="Administration"
            navBarArr={administrationManagementTopNavBars(user_role)}
          />
        </PrivateRoute>

        {/* -------------------------- */}
        <PrivateRoute exact path={routes.administration_users}>
          <Administration
            title="Administration"
            navBarArr={administrationManagementTopNavBars(user_role)}
          />
        </PrivateRoute>

        <PrivateRoute exact path={routes.administation_tax}>
          <Administration
            title="Administration"
            navBarArr={administrationManagementTopNavBars(user_role)}
          />
        </PrivateRoute>

        <PrivateRoute exact path={routes.administation_configuration}>
          <Administration
            title="Administration"
            navBarArr={administrationManagementTopNavBars(user_role)}
          />
        </PrivateRoute>
        {/* -------------------------- */}

        {/* VERSION 2.0 */}

        {/* BRANCHES */}

        <PrivateRoute exact path={routes.partner_management_branch_add}>
          <PartnerManagement
            title="Add Branch"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.partner_management_branch}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.partner_management_branch_edit}>
          <PartnerManagement
            title="Edit Branch"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.partner_management_branch}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.partner_management_branch}>
          <PartnerManagement
            title="Branches"
            // withBackButton={true}
            // isAvoidGoBack
            navBarArr={partnerManagementTopNavBars(user_role)}
            // backUrl={routes.partner_management_branch}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.partner_management_branch_detail}>
          <PartnerManagement
            title="Branch Details"
            withBackButton={true}
            isAvoidGoBack
            backUrl={checkBackBasedOnRole(user_role)}
          />
        </PrivateRoute>

        {/* PROVIDERS */}

        <PrivateRoute exact path={routes.partner_management_provider_add}>
          <PartnerManagement
            title="Add Partner"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.partner_management_provider}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.partner_management_provider_edit}>
          <PartnerManagement
            title="Edit Partner"
            withBackButton={true}
            // isAvoidGoBack
            // backUrl={routes.partner_management_provider}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.partner_management_provider}>
          <PartnerManagement
            title="Partner Management"
            // withBackButton={true}
            // isAvoidGoBack
            navBarArr={partnerManagementTopNavBars(user_role)}
            // backUrl={routes.partner_management_provider}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.partner_management_provider_detail}>
          <PartnerManagement
            title="Partner Details"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.partner_management_provider}
          />
        </PrivateRoute>

        {/* form configurator */}
        {/* <PrivateRoute exact path={routes.form_configurator}>
          <FormConfigurator
            title="Form Configurator"
            // withBackButton={true}
            // isAvoidGoBack
            // backUrl={routes.partner_management_provider}
          />
        </PrivateRoute>
        <PrivateRoute exact path={routes.form_configurator_detail}>
          <FormConfigurator
            title="Form Configurator Details"
            withBackButton={true}
            isAvoidGoBack
            backUrl={routes.form_configurator}
          />
        </PrivateRoute> */}

        {/* Redirections */}
        <Redirect
          exact
          from={routes.management}
          to={managementRedirection(user_role)}
        />
        <Redirect
          exact
          from={routes.user_management}
          to={managementRedirection(user_role)}
        />
        <Redirect
          exact
          from={routes.product_management}
          to={routes.manage_service}
        />
        <Redirect
          exact
          from={routes.sales_operation}
          to={routes.enquiry_list}
        />

        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
