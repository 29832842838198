import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import { InputAdornment, OutlinedInput, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  margin: {
    width: "100%",
    "& .MuiInputBase-root": {
      width: "100% !important",
    },
    "& .css-2b097c-container": {
      marginTop: "8px !important",
    },
    "& .css-yk16xz-control": {
      minHeight: "53px !important",
      borderRadius: "8px !important",
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: "none",
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      //   "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`,
      },
      //   },
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
      //   "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.secondary.light}`,
      },
      //   },
    },
    "& .MuiOutlinedInput-root": {
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1.8px solid ${theme.palette.secondary.light}`,
        },
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 12px",
    }
  },
  label: {
    color: theme.palette.text.label,
    textTransform: "uppercase",
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
  labelsize: {
    fontSize: 17,
  },
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  outlinedInput: {
    "& fieldset": {
      borderRadius: 8,
      // position: "relative",
      border: `1.8px solid ${theme.palette.secondary.light}`,
      fontSize: 16,
      width: "100%",
      // padding: "16px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
    },
  },
}));

export const TextFieldAdornment = (props) => {
  const classes = useStyles(props);

  const { inputLabel, value, handleChange, adornment } = props;

  return (
    <div
      className={classes.margin}
    >
      <InputLabel
        shrink
        htmlFor="demo-customized-select-native"
        className={classes.label}
      >
        {inputLabel}
      </InputLabel>
      <OutlinedInput
        {...props}
        disabled={props.isDisabled}
        // label={null}
        autoComplete={"no"}
        id={`textField-${inputLabel}`}
        value={value}
        onChange={handleChange}
        className={`${classes.root} ${classes.outlinedInput}`}
        endAdornment={
          <InputAdornment position="end">{adornment}</InputAdornment>
        }
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          "aria-label": "wesdfsdfight",
          "aria-autocomplete":"none"
        }}
        // labelWidth={0}
      />
      {props.error && (
        <Typography
          variant="body1"
          component="div"
          className={classes.errorText}
        >
          {props.errorText}
        </Typography>
      )}
    </div>
  );
};
