import React from "react";
import SubHeader from "../../../../components/subHeader";
import { Grid, Typography, withStyles } from "@material-ui/core";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { routes } from "../../../../router/routes";
import { FilterBuilder, InternatlServerError, NotFoundSection } from "../../../../components";
import { serviceSupervisorDetailsUrl, filterTagType, fontStyleClass, scrollToTop, sortDirection, sortFields, subHeaderSortOption } from '../../../../utils';
import withAlelrtAndDialog from '../../../../HOCs/withAlelrtAndDialog';
import { filterComponent } from './filterComponent'
import FilterTags from "../../../../components/filters/filterTags";
import { errorMessage } from "../../../../utils/errorMessages";
import CardList from "../../../../components/userList";


const sizes = {
  md: 3,
  sm: 6,
  xs: 6,
};

const styles = theme => ({
  ...fontStyleClass
})

const initialState = {
  pageNo: 0,
  pageSize: 25,
  careSupervisorName: "",
  category: [],
  provider: [],
  status: "Both",
  date: null,
  isFilterApplied: false,
  sort: subHeaderSortOption[2].value,
  open:false,
  popperData:[]
}

class SupervisorList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState
    }
  }

  componentDidMount() {
    this.getSupervisorList()
    scrollToTop();
    window.addEventListener("scroll", this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  }

  scrolling = () => {
    let { loading, data, error } = this.props?.allCareSupervisor;
    let listCount = data?.list?.length ?? 0;
    let totalCount = data?.totalcount ?? 0;

    if (loading || error || totalCount === 0 || listCount === 0) {
      return false;
    }

    if ((Math.round(window.scrollY + window.innerHeight) >= document.getElementsByTagName('body')[0].scrollHeight) && (listCount <= 25)) {
      this.setState((state, props) => ({
        pageNo: state.pageNo + 1,
      })
        , () => {
          this.getSupervisorList();
        });
    }
  }


  getSupervisorList = () => {
    this.props.GET_ALL_CARE_SUPERVISOR({
      ...this.state,
      category: this.state.category.map(_ => _.value),
      provider: this.state.provider.map(_ => _.value),
      status: this.state.status,
      sortDirec: this.state.sort === "az" ? sortDirection.asc : sortDirection.desc,
      sortfield: this.state.sort === "az" || this.state.sort === "za" ? sortFields.supervisor.name : sortFields.supervisor.created_at
    });
  };


  // SETTING THE STATE FOR SEARCH AND CALLING THE SEARCH FUNCTION
  storeInputSearchValue = (value) => {
    this.setState({ careSupervisorName: value, pageNo: 0, pageSize: 25 }, () => {
      //removed 3 key search : Commented temporarily
      // if (value.length % 3 === 0) {
        this.getSupervisorList();
      // }
    });
  };

  // Sort Handlers
  handleSortChange = (searchValue) => {
    this.setState({
      sort: searchValue,
      pageNo: 0,
      pageSize: 25
    }, () => {
      this.getSupervisorList();
    })
  }


  // Filter Handlers
  onFilterIconClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: <FilterBuilder
        components={filterComponent(this.state, this.props)}
        applyButtonLabel="Apply"
        loading={this.props?.allCareSupervisor?.loading}
        onChange={this.onFilterChange}
        onApplyButtonClicked={this.applyFilter}
        resetFilter={this.resetFilter}
        onCancelClick={this.closeFilter}
      />
    })
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  applyFilter = (state) => {
    this.setState({
      ...state,
      pageNo: 0,
      pageSize: 25,
      isFilterApplied: true,
    }, () => {
      this.getSupervisorList();
      this.closeFilter()
    })
  }

  closeFilter = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    })
  }

  resetFilter = () => {
    this.setState({
      ...initialState
    }, () => {
      this.getSupervisorList();
      this.closeFilter();
    })
  }

  // Filter Tag Utils
  checkIsThereAnyFilter = () => {
    const newState = this.state
    if (newState[filterTagType.Category].length === 0 && newState[filterTagType.Provider].length === 0 && newState[filterTagType.Status] === initialState.status) {
      return false
    } else return true
  }

  handleChipDelete = (key, index) => {
    
    console.log("Key ", key)
    console.log("index ", index)
    if (key === filterTagType.Category || key === filterTagType.Provider) {
      let newState = this.state
      newState[key].splice(index, 1)
      console.log(newState)
      
      this.setState({
        ...newState,
        pageNo: 0,
        pageSize: 25,
        isFilterApplied: this.checkIsThereAnyFilter()
      }, () => {
        this.getSupervisorList()
      })

    } else if (key === filterTagType.Status) {
      this.setState({
        status: "Both",
        pageNo: 0,
        pageSize: 25,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getSupervisorList()
      })
    }
  }

  // Error Messages
  giveMeEmptyMessage = () => {
    if (this.state.careSupervisorName && this.state.isFilterApplied) {
      return errorMessage.notFoundFilterSearch;
    } else if (this.state.careSupervisorName) {
      return errorMessage.notFoundSearch;
    } else if (this.state.isFilterApplied) {
      return errorMessage.notFoundFilter;
    } else {
      return "You don't have any supervisors yet"
    }
  }

  //Navigate to detail page
  navigateToView = (id) => {
    this.props.history.push(routes.manage_supervisors + "/view/" + id);
  };

  handlePopperClose = () =>{
    
    this.setState({open:false})
  }
  handlePopperOpen = (e, data) => {
    
    this.setState({open:e.currentTarget,popperData:data})
  }

  render() {
    let { loading, data, error } = this.props?.allCareSupervisor;
    const {classes} = this.props

    return (
      <div style={{ padding: 25 }}>
        <Grid container>

          {/* Search Bar */}
          <Grid item xs={12}>
            <SubHeader
              isbutton={true}
              placeholder={"Search for Service Supervisor Name, Email and Mobile Number"}
              button_name={"+ ADD SERVICE SUPERVISOR"}
              link={routes.add_supervisors}
              handleSearch={this.getSupervisorList}
              handleChangeInputSearch={this.storeInputSearchValue}
              value={this.state.careSupervisorName}
              useNewFilterIcon
              placeSearchIconToLeft
              isFilterApplied={this.state.isFilterApplied}
              onFilterIconClicked={this.onFilterIconClicked}
              showSortOption={true}
              sort={this.state.sort}
              handleSortChange={this.handleSortChange}
              loading={loading}
              // Temporarily hidden
              hideFilter
            // hideSearch
            />
          </Grid>

          {
            this.state.isFilterApplied &&
            <Grid item xs={12} style={{ marginTop: 25 }} >
              <FilterTags
                filterData={this.state}
                handleDelete={this.handleChipDelete}
              />
            </Grid>
          }

          {/*TOTAL COUNT  */}
          <Grid item xs={12}>
            <Typography variant="h6" style={{ paddingTop: 25 }} className={classes.mediumFont} >Total Service Supervisors ({data?.totalcount ?? 0})</Typography>
          </Grid>


          {!loading && error && <InternatlServerError message="Something went wrong, Try Again!" onRetry={this.getSupervisorList} />}
          {!loading && !error && !data?.list?.length && <NotFoundSection message={this.giveMeEmptyMessage()} />}

          {/* List */}
          {!error && (
            <Grid item xs={12} style={{ paddingTop: "25px" }}>
              <CardList
                list={data?.list}
                loading={loading}
                componentOrder={1}
                sizes={sizes}
                Url={serviceSupervisorDetailsUrl}
                isCard={true}
                countName="Service Supervisors"
                hideQuickSummaryLabel
                hideQuickSummary
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allCareSupervisor: state?.careSupervisor?.allCareSupervisor,
});

export default connect(mapStateToProps, actions)(withAlelrtAndDialog(withRouter(withStyles(styles)(SupervisorList))));
