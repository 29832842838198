import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "8px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04)",
    // border: "0.3px solid #9297a5",
    backgroundColor: "#ffffff",
    width: 200,
  },
}));
export const RecommendCardLoading = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.card}>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      >
        <Skeleton variant="circle" width={40} height={40} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px 0px 10px",
        }}
      >
        <Skeleton variant="text" width={150} height={25} />
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Skeleton variant="text" width={150} height={25} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px 0px",
          margin:'auto'
        }}
      >
        <Skeleton variant="text" width={150} height={50} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px 0px 10px",
        }}
      >
        <Skeleton variant="text" width={150} height={50} />
      </Grid>
    </Grid>
  );
};
