import React from 'react';
import
{
    Grid,
    makeStyles,
    Card,
    FormControl,
    MenuItem,
    Select,
    Typography,
    Divider
} from "@material-ui/core";

import {DateValidations,StringValidations} from '../validations';

const useStyles = makeStyles(theme => ({
    root:{
        flex: 1
    },
    gridItem:{
        padding:theme.spacing(2),
    },
    flexStyle:{
        display:'flex',
        alignItems:'center', 
        '&:not(:last-child)':{
         marginBottom:20
       },  
       '& .MuiFormControl-root':{
            flex:1,
            paddingLeft:theme.spacing(2)
       }
      },
      dropdownWrapper:{
        padding:theme.spacing(2),
        marginBottom:theme.spacing(2)
       },
       columnTypesTitle:{
           marginBottom:5
       },
       divider:{
           margin:'10px 0px'
       }
}))

export const TableValidations = (props) =>{

    const classes = useStyles(props);

    const onChange = (val,key,rowIndex,columnIndex) =>{  
        
        let newRow = props?.data?.tableScheme?.row[rowIndex]?.validations  ?? {};
        newRow[columnIndex]={...newRow[columnIndex],[key]:val};
        props.data.tableScheme.row[rowIndex]['validations']=newRow;

        props.onChangeComponents(props.data.tableScheme,'tableScheme')
    
    }

    const uiComponentPopover=[
        {component_name:'Checkbox',ui_component:'check_box',icon:'CheckBoxOutlinedIcon'},
        {component_name:'Input Text',ui_component:'input_text',icon:'TextFieldsOutlinedIcon'},
        {component_name:'Date',ui_component:'date',icon:'CalendarTodayOutlinedIcon'},
        {component_name:'Dropdown',ui_component:'dropdown',icon:'ArrowDropDownIcon'},
        {component_name:'Mobile',ui_component:'mobile',icon:'CallIcon'},
      ]

    const changeDropDownColumn = (e,index) =>{
        let col = JSON.parse(e.currentTarget.getAttribute("data-click"));
        Object.assign(props?.data?.tableScheme.column[index], col);
      
        //delete the validation key because of changing column type
        props.data.tableScheme.row.filter(l=> {
          if(l?.validations?.hasOwnProperty(index)){
            delete l.validations[index]
          }
          return l
        })

        //delete the option key if ui.component n!== Dropdown
        if(props?.data?.tableScheme?.column[index]?.ui_component !== 'Dropdown'){
            let deleteOption = props?.data?.tableScheme.column[index];
            delete deleteOption['options'];
            Object.assign(deleteOption, col)  
          }
        props.onChangeComponents(props.data.tableScheme,'tableScheme')
      }

      const stringComponentType = (type) => {
        const optionComponentsList = ["input_text","input_textarea"];
        return optionComponentsList.includes(type) ? true : false
    }

    const dateComponentType = (type) => {
        const optionComponentsList = ["date"];
        return optionComponentsList.includes(type) ? true : false
    }

    return(
        <div className={classes.root}>
            <Grid container>
            <Grid item xs={12} className={classes.gridItem}>
                  <Typography variant="h6" className={classes.columnTypesTitle}>Column Types</Typography>
                  <Card className={classes.dropdownWrapper}>
                      {
                         props?.data?.tableScheme?.column && props?.data?.tableScheme?.column?.map((colData,index)=>{
                           return(
                             <div key={index} className={classes.flexStyle}>
                                <Typography variant="subtitle1">{colData?.name}</Typography>
                                <FormControl>
                                  <Select
                                    id="demo-dropdown-select"
                                    value={colData?.component_name ? colData?.component_name : props?.data?.component === "custom_table_2" ? '' : 'Input Text'}
                                    onChange={(e)=>changeDropDownColumn(e,index)}
                                    disabled={index ===0 && props?.data?.component === "custom_table_2"}
                                  >
                                    {
                                      uiComponentPopover && uiComponentPopover.map((l,i)=>{
                                        return(
                                          <MenuItem value={l?.component_name} key={i} data-click={JSON.stringify(l)}>{l?.component_name}</MenuItem>
                                        )
                                      })
                                    }
                                  </Select>
                                </FormControl>
                             </div>
                           )
                         })
                      }
                      </Card>
                     
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                  <Typography variant="h6" className={classes.columnTypesTitle}>Table Input Validations</Typography>
                  {
                      props?.data?.tableScheme?.row && props?.data?.tableScheme?.row?.map((rowData,rowIndex)=>{
                        return (
                        <Card className={classes.dropdownWrapper} key={rowIndex}>
                            <Typography variant="body1">Row-{rowIndex+1}</Typography>
                            <Divider className={classes.divider}/>
                        {
                            props?.data?.tableScheme?.column && props?.data?.tableScheme?.column?.map((columnData,columnIndex)=>{
                                return(
                                    <div key={columnIndex}>
                                        {
                                        stringComponentType(columnData?.ui_component ? columnData?.ui_component : props?.data?.component === "custom_table_2" ? '' : 'input_text') && 
                                        <>
                                        <Typography variant="body1">{columnData?.name}</Typography>
                                        <StringValidations 
                                            validationState={props?.data?.tableScheme?.row[rowIndex]?.validations?.[columnIndex] ?? {}}
                                            onChange={(val,key)=>onChange(val,key,rowIndex,columnIndex)}
                                        />
                                        </>
                                        }
                                        {
                                        dateComponentType(columnData?.ui_component ?? '') && 
                                        <>
                                        <Typography variant="body1">{columnData?.name}</Typography>
                                        <DateValidations 
                                            validationState={props?.data?.tableScheme?.row[rowIndex]?.validations?.[columnIndex] ?? {}}
                                            onChange={(val,key)=>onChange(val,key,rowIndex,columnIndex)}
                                        />
                                        </>
                                        }
                                    </div>
                                )
                            })
                        }   
                    </Card>
                    )})
                  }
              </Grid>
           </Grid>
        </div>
    )
}

export default TableValidations;
