import React from 'react';
import Reportcard from '../../../../components/report/index';


class Reports extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){
        return <Reportcard/>
    }
}

export default Reports