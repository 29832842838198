import React from "react";
import {
  makeStyles,
  // Avatar,
  Typography,
  Divider,
} from "@material-ui/core";
// import Icon from "../../assets/product Management/listingScreen/premium.svg";
import { PopperListRenderer } from "../hybridTypography/popperListRenderer";
import {StyledChip} from '../styledChip/index'
import {getSplicedData} from '../../utils'
import { CustomAvatar } from "../niceComponents";

const useStyle = makeStyles((theme) => ({
  avatar: {
    height: 60,
    width: 60,
    backgroundColor: theme.palette.secondary.light,
  },
  textArea: {
    marginLeft: 16,
  },
  textAreaHeader: {
    display: "flex",
  },
  textAreaFooter: {
    display: "flex",
    marginTop: 6,
  },
  activecircle: {
    marginLeft: 10,
    marginTop: 6,
    width: 9,
    height: 9,
    background: theme.palette.success.main,
    borderRadius: "50%",
  },
  inactivecircle: {
    marginLeft: 10,
    marginTop: 6,
    width: 9,
    height: 9,
    background: theme.palette.error.main,
    borderRadius: "50%",
  },
  active_name: {
    display: "inline",
    paddingLeft: "6px",
    fontSize: "14px",
    color: theme.palette.success.main,
  },
  inactive_name: {
    display: "inline",
    paddingLeft: "6px",
    fontSize: "14px",
    color: theme.palette.error.main,
  },
  careProviderName: {
    backgroundColor: theme.palette.secondary.light,
    padding: "4px 8px 4px 8px",
    borderRadius: 4,
  },
  divider: {
    margin: "0px 8px",
    // height: 27,
  },
  category: {
    padding: "4px 0px 4px 0px",
    marginLeft: 16,
  },
  product: {
    color: theme.palette.secondary.main,
  },
}));

const DetailSection = (props) => {
  const classes = useStyle();

  const data = props?.data;

  const isActive = data?.isactive ? true : false;

  return (
    <>
      {/* <Avatar
        src={data?.profile_pic ? data?.profile_pic : "/images/tanyaCare/Tanya.png"}
        alt="pic"
        className={classes.avatar}
        // style={{ padding: data?.profile_pic ? 0 : 16 }}
      /> */}
      <CustomAvatar src={data?.profile_pic} name={data?.name} fallbackAsImage />
      <div className={classes.textArea}>
        <div className={classes.textAreaHeader}>
          <Typography
            variant="caption"
            component="div"
            className={classes.product}
          >
            {`${
              data?.subscriptionType ? `${data?.subscriptionType} :` : ""
            }`.toUpperCase()}
          </Typography>
        </div>
        <div className={classes.textAreaHeader}>
          <Typography variant="body1">{data?.name}</Typography>
          <div
            className={isActive ? classes.activecircle : classes.inactivecircle}
          />

          <Typography
            variant="h6"
            className={isActive ? classes.active_name : classes.inactive_name}
          >
            {isActive ? "ACTIVE" : "INACTIVE"}
          </Typography>
        </div>
        <div className={classes.textAreaFooter}>
          <StyledChip maxWidth={100} label={data?.category?.[0]?.label ?? "N/A"} />
          {data?.category?.length > 1 && (
            <PopperListRenderer
              popperList={getSplicedData(data?.category, 1)?.map((_) => _.label)}
              count={getSplicedData(data?.category, 1)?.length}
            />
          )}

          <Divider orientation="vertical" className={classes.divider} flexItem />

          <StyledChip label={data?.provider ?? "N/A"} maxWidth={100} transparent />

          {/* <Typography
            variant="body2"
            color="textSecondary"
            className={classes.category}
          >
            {data?.provider}
          </Typography> */}
        </div>
      </div>
    </>
  );
};
export default DetailSection;
