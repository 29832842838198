import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  prefix__cls1: (props) => ({
    fill: "none",
    stroke: props.color || "#9297a5",
    strokeMiterlimit: 10,
    strokeWidth: 2
  })
}))

export const SettingsNewIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon>
      <g id="Settings" transform="translate(-2.173 -2)">
        <path id="Path_560" d="M12 15a3 3 0 1 1 3-3 3 3 0 0 1-3 3z" className={classes.prefix__cls1} data-name="Path 560" />
        <path id="Path_561" d="M18.9 10.868a6.394 6.394 0 0 1 0 2.264l-.089.549 1.738 1.512-1.51 2.614-2.172-.748-.432.354a6.942 6.942 0 0 1-1.965 1.128l-.521.2-.44 2.259h-3.018l-.44-2.261-.521-.2a6.942 6.942 0 0 1-1.965-1.128l-.432-.354-2.172.748-1.51-2.614 1.738-1.512-.089-.546A7.141 7.141 0 0 1 5 12a7.013 7.013 0 0 1 .1-1.132l.089-.549-1.738-1.512 1.51-2.614 2.172.748.432-.354A6.942 6.942 0 0 1 9.53 5.459l.521-.2.44-2.259h3.018l.44 2.261.521.2a6.942 6.942 0 0 1 1.965 1.128l.432.354 2.172-.748 1.51 2.614-1.738 1.512z" className={classes.prefix__cls1} data-name="Path 561" />
      </g>
    </SvgIcon>
  )
}

SettingsNewIcon.propTypes = {
  color: PropTypes.string,
};