import React, { useState, useEffect } from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import { FamilyMemberCard, FamilyMembersAddForm } from ".";
import { withAlertAndDialog } from "../../HOCs";

const useStyles = makeStyles((theme) => ({
  add_title: {
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "left",
    // color: "#f27d35",
    color: theme.palette.ternary.main,
    textTransform: "uppercase",
    cursor: "pointer",
    width: "max-content",
  },
}));

function FamilyMembers(props) {
  const classes = useStyles();
  const { onAddClick, handleSave, editData: membersList } = props;

  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (membersList) {
      setMembers(membersList);
    }
  }, [membersList]);

  const onEdit = (editData) => {
    debugger;
    props.updateParentMember(editData);
    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      component: (
        <FamilyMembersAddForm
          editData={editData}
          isEdit={true}
          list={membersList}
          callBackOnSave={handleSave}
          updateState={props.updateState}
          updateFamilyMembers={props.updateFamilyMembers}
          // BACK
          goBackHandler={props.onFamilyMemberAddClick}
          // ID Handlers
          callBackOnSaveId={props.callBackOnSaveId}
          onAddEditDrawerId={props.onAddEditDrawerId}
          // Contact Handlers
          callBackOnSavePhone={props.callBackOnSavePhone}
          onAddEditDrawerPhone={props.onAddEditDrawerPhone}
          // Address Handlers
          callBackOnSaveAddress={props.callBackOnSaveAddress}
          onAddEditDrawerAddress={props.onAddEditDrawerAddress}
        />
      ),
      isMedium: true,
    });
  };

  return (
    <>
      {(!members || (Array.isArray(members) && members?.length === 0)) && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100%",
            marginBottom: 8,
          }}
        >
          <Typography
            id={props?.id}
            color="secondary"
            onClick={() => onAddClick(members)}
            className={classes.add_title}
          >
            {"+ Add Family Member"}
          </Typography>
        </div>
      )}

      {Array.isArray(members) && members?.length > 0 && (
        <Grid container alignItems="center">
          {members.map((member, index) => (
            <Grid item xs={12} sm={6} md={4}>
              <FamilyMemberCard
                data={member}
                onEdit={() => onEdit(member)}
                onDelete={() => handleSave(member, true)}
                editMode
              />
            </Grid>
          ))}
          {Array.isArray(members) && members?.length > 0 && (
            <Typography
              id={props?.id}
              color="secondary"
              onClick={() => onAddClick(members)}
              className={classes.add_title}
            >
              {"+ Add Family Member"}
            </Typography>
          )}
        </Grid>
      )}
    </>
  );
}

export default withAlertAndDialog(FamilyMembers);
