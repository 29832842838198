import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function EmailIconV2(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 4h16a2.006 2.006 0 012 2v12a2.006 2.006 0 01-2 2H4a2.006 2.006 0 01-2-2V6a2.006 2.006 0 012-2v0z"
        stroke="#0063E7"
        fill={props.secondaryColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 6.381l-10 6.89-10-6.89"
        stroke="#0063E7"
        fill={props.secondaryColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default EmailIconV2;

EmailIconV2.defaultProps = {
  secondaryColor: "#ffffff",
}
