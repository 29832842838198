import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: "#101010",
  },
  tooltip: {
    backgroundColor: "#101010",
  },
}));

function ToolTipGenerator({ title, ariaLabel, children, ...props }) {
  const classes = useStyles();

  return (
    <Tooltip
      TransitionComponent={Zoom}
      title={title}
      aria-label={ariaLabel ? ariaLabel : title}
      arrow
      placement="top"
      classes={classes}
      {...props}
    >
      {children}
    </Tooltip>
  );
}

export default ToolTipGenerator;
