import * as React from "react"

export function ClientIcon(props) {
  return (
    <svg  style={props.style} fill={props.color} {...props}>
      <path d="M8 5a3 3 0 100 6 3 3 0 000-6zm8 0a3 3 0 100 6 3 3 0 000-6zM8 7c.551 0 1 .449 1 1 0 .551-.449 1-1 1-.551 0-1-.449-1-1 0-.551.449-1 1-1zm8 0c.551 0 1 .449 1 1 0 .551-.449 1-1 1-.551 0-1-.449-1-1 0-.551.449-1 1-1zm-8 6c-3 0-7 1.464-7 3.5V19h22v-2.5c0-2.036-4-3.5-7-3.5-1.284 0-2.738.283-3.996.75C10.744 13.282 9.287 13 8 13zm0 2c2.36 0 4.62 1.026 5 1.6v.4H3v-.4c.38-.574 2.64-1.6 5-1.6zm8 0c2.36 0 4.62 1.026 5 1.6v.4h-6v-.5c0-.482-.23-.93-.611-1.336A8.952 8.952 0 0116 15z" />
    </svg>
  )
}

