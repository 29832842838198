import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui//styles/makeStyles';
import { FolderIcon,PngLogo,DeleteIcon } from '../../../../../assets';
import { toBase64,findImageSize,alertProps } from "../../../../../utils";
import PropTypes from 'prop-types';
import { createTestProps } from '../../../../../utils/common';
import { AlertContext } from '../../../../../contexts';

const useStyles = makeStyles((theme)=>({
    root:props=>({
        backgroundColor:'#CCE1FF29',
        border:`1px dashed ${props?.error ? theme.palette.error.main : '#C7C7C7'}`,
        borderRadius:10,
        padding:theme.spacing(2),
        textAlign:'center'
    }),
    labelStyle:{
        fontSize:14,
        fontWeight:400,
        marginBottom:theme.spacing(2)-theme.spacing(1/2),
        color:'#878C9A',
        '& .MuiTypography-caption':{
            color:'#F76E8E',
            fontWeight:400
        }
    },
    title:{
        color:theme.palette.text.secondary,
        fontSize:14,
        fontWeight:400,
        marginTop:theme.spacing(2)
    },
    desc:{
        color:theme.palette.text.secondary,
        fontSize:12,
        fontWeight:400,
        marginTop:theme.spacing(2) - theme.spacing(1/2)
    },
    nameStyle:{
        fontSize:14,
        fontWeight:400,
        color:'#3B3F5B',
        marginInline:theme.spacing(2),
        flex:1,
        textAlign:'left'
    },
    rowCenter:{
        display:'flex',
        alignItems:'center',
    },
    empty:props=>({
        cursor:props?.isReadOnly ? 'auto' : 'pointer',
        marginBlock:theme.spacing(3),
        position:'relative',
    }),
    inputImage:props=>({
        position:'absolute',
        inset:0,
        opacity:0,
        width:'100%',
        cursor:props?.isReadOnly ? 'auto' : 'pointer',
        height:'100%',
    }),
    error:{
        fontSize:'0.75rem',
        fontWeight:300,
        marginTop:3,
        color:theme.palette.error.main
    }
}));

export const UploadImage = (props) =>{

    const classes = useStyles(props);
    const alert = React.useContext(AlertContext);

    const {
        uploadData={},
        handleChange=null,
        handleDelete=null,
        error=false,
        helperText="",
        isReadOnly=false
    } = props;

    const handleUpload = async (e) =>{

        let file = e.target?.files[0];

        let { width=null,height=null } = await findImageSize(file)

        if(width === 337 && height === 175){
        let base = await toBase64(file);
        
        const data = {
            name: file?.name,
            src: base,
            // size:file?.size,
        };

        handleChange && handleChange(data)
    }
    else{
        alert.setSnack({
            open: true,
            horizontal: alertProps.horizontal.center,
            vertical: alertProps.vertical.top,
            msg: `Please select the image size in ("337x175")`,
            severity: alertProps.severity.error,
        });
    }

    }

    return(
        <div>
            <Typography className={classes.labelStyle}>
                Upload Image <Typography variant="caption">&nbsp;*</Typography>
            </Typography>
            <div className={classes.root}>
                {
                    uploadData && Object.keys(uploadData)?.length ? (
                        <div className={classes.rowCenter}>
                            <PngLogo />
                            <Typography className={classes.nameStyle}>{uploadData?.name}</Typography>
                            <DeleteIcon 
                                style={{cursor:isReadOnly ? 'auto' : 'pointer'}} 
                                onClick={!isReadOnly && handleDelete}
                                {...createTestProps("delete upload image")} 
                                />
                        </div>
                    ) : (
                        <div className={classes.empty}>
                            <FolderIcon />
                            <Typography className={classes.title}>Click here to upload image</Typography>
                            <Typography className={classes.desc}>Recommended size 337 X 175 PNG format</Typography>
                            <input 
                                type="file"
                                accept="image/png" 
                                className={classes.inputImage}
                                disabled={isReadOnly}
                                onChange={handleUpload}
                                {...createTestProps("upload png image")}
                            />
                        </div>
                    )
                }
            </div>
            {error && <Typography className={classes.error}>{helperText}</Typography>}
        </div>
    )
}


UploadImage.propTypes={
    uploadData:PropTypes.object,
    handleChange:PropTypes.func,
    handleDelete:PropTypes.func,
    error:PropTypes.bool,
    helperText:PropTypes.string,
    isReadOnly:PropTypes.bool,
}