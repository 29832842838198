import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        width: "auto"
    },
    activities: {
        padding: 4,
        backgroundColor: '#f9f9f9',
        borderLeft: '4px solid #252b43',
        borderRadius: 4
    },
    available: {
        padding: 4,
        backgroundColor: '#dbeef3',
        borderLeft: '4px solid #28a9c1',
        borderRadius: 4
    },
    unavailable: {
        padding: 4,
        backgroundColor: '#fdece1',
        borderLeft: '4px solid #f27d35',
        borderRadius: 4
    },
})

class CalenderCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        const {
            classes,
            variant,
            title,
            height,
            style
        } = this.props;

        return <div className={classes.root} style={style}>
            <Grid container direction="column" justify="flex-start" alignItems="flex-start" className={classes[variant]} style={{ height }}>
                <Grid item>
                    <Typography variant="subtitle2" color="textPrimary">{title}</Typography>
                </Grid>               
            </Grid>
        </div>
    }
}

CalenderCard.propTypes = {
    variant: PropTypes.oneOf(['activities', 'available', 'unavailable']),
    title: PropTypes.string,
}

export default withStyles(styles)(CalenderCard);