import React from "react";
import AddEditForm from "../addEditForm";
import {
  formComponentTypes,
  alertProps,
  // getCountryCodeOptions,
  // timezoneOld as timezone
} from "../../utils";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
// import ConflictList from "../../conflictList";


class EditPhoneComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {

    };
  }

  componentDidMount() {
    this.getActivityData()
  }

  getActivityData = () => {
    this.props.MASTER_PHONE_CODE({})
    this.props.GET_MASTER_PHONE_NO_TYPE({});
  };

  getFormattedMobileCodeState = (phoneCode) => {
    if (phoneCode) {
      let flag = phoneCode?.toString()?.includes("+");
      if (!flag) {
        return `+${phoneCode}`;
      } else {
        return phoneCode.toString();
      }
    }
  };

  onSaveButtonClicked = (isError, data, isUrl) => {
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      console.log("data")
      console.log(this.props?.editData)
      if (this.props?.editData) {
        data.id = this.props?.editData.id
      }
      console.log("AFTER")
      console.log(this?.props?.loginData?.user_id)
      data.isPartner = (this?.props?.loginData?.user_role === 'provider_admin') ? true : false;
      data.isMobile = true;
      data.isPrimary = this?.props?.isPrimary;
      data.user_id = this?.props?.loginData?.user_id;
      data.user_data = this?.props?.user_data
      this.props.UPDATE_PROFILE(data);
    }
  };

  onCancelButtonClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  render() {
    let isEdit = this.props.isEdit;

    let list = this.props.list;
    let selectedList = []
    let data = this.props?.editData;
    let {data:masterPhoneCodeData} = this.props?.masterPhoneCode
    if (list && list.length > 0) {
      selectedList = list.map(val => val.mobile_type.value)
    }
    if (data && data.length > 0 && selectedList.length > 0) {
      selectedList = selectedList.filter(val => val !== data.value)
    }

    const components = {
      section_1: {
        label: !isEdit ? "Add Mobile Number" : "Edit Phone Number",
        fields: {
          email_type: {
            key: "mobile_type",
            type: formComponentTypes.SELECT_BOX,
            componentProps: {},
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "Mobile Number Type",
            value: data?.mobile_type || { value: data?.value, label: data?.label },
            // options: this.props.phoneType?.data
            //   ? this.props.phoneType?.data
            //   : [],
            options: this.props.phoneType?.data
              ? selectedList.length > 0 ? this.props.phoneType?.data.filter(val => selectedList.indexOf(val.value) === -1)
                : this.props.phoneType?.data
              : [],
            isMandatory: true,
          },
          phone: {
            key: {
              select_key: "mobilenumberISDcode",
              input_value_key: "mobilenumber",
            },
            isFieldAPhoneNumber: true,
            value: {
              // mobilenumberISDcode: {
              //   label: !isEdit ? "+60" : data?.mobile_no_isd_code ? `+${data?.mobile_no_isd_code}` : "+60",
              //   value: !isEdit ? '60' : data?.mobile_no_isd_code ? data?.mobile_no_isd_code : '60',
              // },
              mobilenumberISDcode: this.getFormattedMobileCodeState(data?.mobilenumberISDcode) || this.getFormattedMobileCodeState(data?.mobileNoIsdCode),
              mobilenumber: data?.mobilenumber || data?.mobileNo
              ,
            },
            isComponentAMobileField: true,
            type: formComponentTypes.MOBILE_INPUT_BOX,
            componentProps: {
              type: 'number',
              allScreenCustomSize: {
                xs: 6,
                sm: 4,
                md: 6
              }
            },
            customsize: 4,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "MOBILE",
            //   value: [null, null],
            options: masterPhoneCodeData ,
            isMandatory: true
          },
        },
      },
    };
    return (
      <>
        {isEdit ? (
          <>
            <AddEditForm
              components={components}
              onSaveButtonClicked={this.onSaveButtonClicked}
              cancelForm={this.onCancelButtonClicked}
              // loading={updateLoading}
              isUrl={true}
              saveButtonLabel={"Update"}
              activityEdit={isEdit}
            />
          </>
        ) : (
            <AddEditForm
              components={components}
              onSaveButtonClicked={this.onSaveButtonClicked}
              cancelForm={this.onCancelButtonClicked}
              // loading={addLoading}
              saveButtonLabel={"Add Mobile Number"}
              withoutBoxShadow={true}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  phoneType: state?.masterSet?.phoneNoType,
  updateProfile: state?.resourceReducer?.updateProfileData,
  masterPhoneCode:state?.resourceReducer?.masterPhoneCode
});

// export default AddEditAvailabiity
export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(EditPhoneComponent));
