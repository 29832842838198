import * as React from "react"

export const StarRating = (props) => {
  return (
    <svg width={16} height={15.333} viewBox="0 0 16 15.333" {...props}>
      <path
        fill="#f27d35"
        d="M3.581 15.334a.852.852 0 01-.829-1.04L3.781 9.76.291 6.698a.851.851 0 01.482-1.49l4.619-.419L7.218.517a.85.85 0 011.564 0l1.826 4.274 4.618.419a.851.851 0 01.483 1.488l-3.491 3.061 1.029 4.534a.85.85 0 01-1.265.919l-3.983-2.381-3.982 2.377a.852.852 0 01-.436.121zm4.418-3.526a.852.852 0 01.436.121l3.764 2.246-.971-4.28a.85.85 0 01.269-.829l3.3-2.891-4.361-.4a.849.849 0 01-.707-.515l-1.73-4.028-1.723 4.033a.847.847 0 01-.7.513l-4.361.4 3.3 2.891a.849.849 0 01.269.829l-.971 4.279 3.759-2.247a.852.852 0 01.427-.122zM5.356 4.873zm5.285 0zm0 0"
        data-name="star (1)"
      />
    </svg>
  )
}

export default StarRating
