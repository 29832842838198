import React from "react";
import {
  Grid,
  Paper,
  withStyles,
  Divider,
  Button,
  Typography,
  Switch,
  IconButton,
} from "@material-ui/core";
import { addressComponentTypes, formComponentTypes, options } from "../../utils";
import {
  checkFirstZeroAndAllZero,
  getMobileLimitBasedOnCC,
  // checkLimit
} from "../../utils/validations";
import { UploadProfileImage } from "../";
import PropTypes from "prop-types";
import {
  TextField,
  InputAdornmentComponent,
  SimpleSelect,
  RadioButtonsGroup,
  CheckboxCustom,
  CustomizedRadioButton,
} from "../inputs";
import { ButtonWithInputField } from "../buttonWithInputField";
import { SimpleList } from "../simpleList";
import { DateRangePicker } from "../datePicker";
import { errorMessage } from "../../utils/errorMessages";
import { Table } from "../table";
import AdminTable from "../table/adminTable";
import Summary from "../summary/summary";
import { Recommended } from "../recommendedCard";
// import { DrawerComponent } from "../addContactDrawer";
import { DetailsCard } from "../detailCard";
import { SimpleLabel } from "../simpleLabel";
import { DateTimePickersCustom } from "../dateTimePicker";
import { Prompt, withRouter } from "react-router-dom";
import { CardWithTextAndCallBack } from "../cardWithTextAndCallBack";
import { AsyncPaginateSelect } from "../inputs/select/asyncPaginateSelect";
import { validateEmail } from "../../utils/validations";
import SearchLocationInput from "../map/inputFieldSearch/SearchLocationInput";
import { withAlertAndDialog } from "../../HOCs";
// import MapDragabble from "../map/draggableMarker";
import { CustomizedToggleGroup } from "../inputs/radioButton/customizedToggleGroup";
import { Bmi } from "../bmiComponent";
import { ICNumberPassport } from "../../screens/userManagement/clients/addEdit/icNumberPassport";
import { ServiceConfiguration } from "../serviceConfiguration";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";
// import { isValidPhoneNumber } from "libphonenumber-js";
import { isValidPhoneNumber as isValidNumber } from "libphonenumber-js";
import FromToDateTimePicker from "../fromToDatePicker";
import ActivityRecurrence from "../activityRecurrence";
import ActivityMode from "../activityMode";
import { SwitchComponent } from "../switch";
import ProductValidity from "../productValidity";
import SelectComponentWithSubComponent from "../SelectComponentWithSubComponent";
import ModernViewAddressCardDetail from "../SelectComponentWithSubComponent/address/modernViewCard";
import ModernViewIdCardDetail from "../SelectComponentWithSubComponent/idType/modernViewCard";
import ModernViewEmailCardDetail from "../SelectComponentWithSubComponent/email/modernViewCard";
import ModernViewMobileCardDetail from "../SelectComponentWithSubComponent/mobile/modernViewCard";
// import { DurationComponent } from "../duration";
import DurationPicker from "../durationPicker";
import FileUploader from "../fileUploader";
// import validator from "validator";
import SwitchBoxWithDropDown from "../switchBoxWithDropDown";
import countryCode from "../../utils/countryCode.json";

import moment from "moment";
import RichTextEditor from "../richTextEditor";
import CustomGoogleMap from "../googleMap";
import ResourceValidity from "../resourceValidity";
import FileUploaderVariant from "../fileUploader/fileUploaderVariant";
import UploaderWithPreview from "../uploaderWithPreview";
import ActivityAndMembersCard from "../availabilityActivityComponents/ActivityAndMembersCard";
import { colorCode } from "../../screens/availability/calendar/activityList";
import { EffectiveDateExpiryDate } from "../effectiveExpiryDate";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { FamilyMembers } from "../familyMembers";
import { isClientAddEditForm, isTrialAccountType } from "../../utils/common";
const style = (theme) => ({
  // "& .MuiPaper-elevation1": (props) => ({ boxShadow: !props.withBoxShadow ? 'none !important' : '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }),
  fixWidth: {
    width: "100%"
  },
  root: {
    boxShadow: (props) =>
      props.withoutBoxShadow
        ? "none !important"
        : "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    background: (props) =>
      props.withoutBoxShadow ? "transparent !important" : "white",
    "& .MuiSwitch-track": {
      background: theme.palette.secondary.main,
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      // "& .MuiSwitch-track":{
      background: "#80f10e",
      // }
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "white",
    },
  },
  container: {
    padding: "8px 22px 16px 22px",
  },
  item: {
    width: "100%",
  },
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
  saveBtnContainer: {
    width: "100%",
    textAlign: "end",
  },
  switch: {
    "& .MuiSwitch-track": {
      borderRadius: 13,
    },

    "& .MuiSwitch-root": { padding: 6 },
  },
  switchLabel: {
    color: theme.palette.secondary.main,
    textAlign: "right",
  },
  summaryCardLabel: {
    marginBottom: 15,
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
});

const setInitialState = (props) => {
  let initialState = {};
  let fieldsToValidate = [];
  let multipleFieldsToValidateWithRelation = {};
  Object.keys(props?.components).map((section) => {
    Object.values(props?.components[section].fields).map((components) => {
      debugger
      if (components?.key?.constructor === Object) {
        Object.values(components?.key).map((val) => {
          return (initialState = {
            ...initialState,
            [val]: components.value[val],
          });
        });
      } else {
        initialState = { ...initialState, [components.key]: components.value };
      }

      if (components.isMandatory) {
        if (components?.key?.constructor === Object) {
          Object.values(components.key)
            .filter((val) => components.type === "MOBILE_INPUT_BOX")
            .map((val) => {
              return fieldsToValidate.push({
                key: val,
                type: components.type,
                componentType: components?.componentProps?.type,
                mobilenumberISDcodeKey: components.key?.["select_key"],
                isFieldAPhoneNumber:
                  val === components.key?.["input_value_key"] &&
                    components?.isFieldAPhoneNumber
                    ? components?.isFieldAPhoneNumber
                    : false,
              });
            });
          Object.values(components.key)
            .filter((val) => components.type === formComponentTypes.RESOURCE_VALIDITY)
            .map((val) => {
              return fieldsToValidate.push({
                ...components
              });
            });
          Object.values(components.key)
            .filter((val) => components.type === formComponentTypes.PRODUCT_VALIDITY)
            .map((val) => {
              return fieldsToValidate.push({
                ...components
              });
            });
          // fromToDateTimePicker
          Object.values(components.key)
            .filter(
              () =>
                components.type === formComponentTypes.FROM_TO_DATE_TIME_PICKER
            )
            .map((val) => {
              return fieldsToValidate.push({
                key: val,
                type: components.type,
              });
            });
          Object.values(components.key)
            .filter(
              () => components.type === formComponentTypes.ACTIVITY_RECURRENCE
            )
            .map((val) => {
              return fieldsToValidate.push({
                key: val,
                type: components.type,
              });
            });
          Object.values(components.key)
            .filter(
              (val) => components.type === formComponentTypes.ACTIVITY_MODE
            )
            .map((val) => {
              return fieldsToValidate.push({
                key: val,
                type: components.type,
              });
            });
          // Object.values(components.key)
          //   .filter((val) => components.type === formComponentTypes.DURATION)
          //   .map((val) => {
          //     return fieldsToValidate.push({
          //       key: val,
          //       type: components.type,
          //     });
          //   });
          Object.values(components.key)
            .filter((val) => components.type === formComponentTypes.EFFECTIVE_EXPIRY)
            .map((val) => {
              return fieldsToValidate.push({
                key: val,
                type: components.type,
              });
            });
        } else {
          fieldsToValidate.push({
            key: components.key,
            type: components.type,
            componentType: components?.componentProps?.type,
            ...components,
          });
        }
      }
      // if (components.isMandatoryWithMultipleFieldsRelation) {
      //   multipleFieldsToValidateWithRelation[components.isMandatoryWithMultipleFieldsRelation] = multipleFieldsToValidateWithRelation[components.isMandatoryWithMultipleFieldsRelation] ? multipleFieldsToValidateWithRelation[components.isMandatoryWithMultipleFieldsRelation] : []
      //   if (components.key.constructor === Object) {
      //     Object.values(components.key)
      //       .filter((val) => components.type === "MOBILE_INPUT_BOX")
      //       .map((val) => {
      //         multipleFieldsToValidateWithRelation[components.isMandatoryWithMultipleFieldsRelation].push({
      //           key: val,
      //           type: components.type,
      //           componentType: components?.componentProps?.type,
      //           ...components,
      //         })
      //         return multipleFieldsToValidateWithRelation;
      //       });
      //   } else {

      //     multipleFieldsToValidateWithRelation[components.isMandatoryWithMultipleFieldsRelation].push({
      //       key: components.key,
      //       type: components.type,
      //       componentType: components?.componentProps?.type,
      //       ...components,
      //     })
      //   }
      // }
      return initialState;
    });
    return section;
  });

  return {
    initialState,
    fieldsToValidate,
    multipleFieldsToValidateWithRelation,
  };
};

class AddEditProductManagement extends React.Component {
  constructor(props) {
    super(props);
    let { initialState, fieldsToValidate } = setInitialState(props);
    this.state = {
      currentAllComponentState: {
        // validity_period: [null, null],
        ...initialState,
      },
      openDrawer: {
        open: false,
        component: {},
      },
      fieldsToValidate: fieldsToValidate,
      // multipleFieldsToValidateWithRelation:multipleFieldsToValidateWithRelation,
      error: {},
      errorText: {},
      errorIndex: 1,
      id: "",
      isUrl: props.isUrl,
      isBlock: false,
      isErrorOccured: false,
      locationViaMap: "",
      timeDifferenceError: false,
    };
  }

  onChange = (key, value) => {
    const { components } = this.state;

    components[key].value = value;

    this.setState({
      components,
    });
  };

  // custom timerange
  update_time_slot_slot__from_to_time = (week, slotdata, key, time) => {
    // let _obj = {
    //   id: slotdata.id,
    //   category_ids: slotdata.category_ids,
    //   timezone: slotdata.timezone,
    //   slot_from_time: time.startTime,
    //   slot_to_time: time.endTime,
    // };
  };

  //UPLOAD PROFILE IMAGE
  // updateImageDataInStateAndPreviewImage = (input, state) => {
  //   const { currentAllComponentState, error } = this.state;
  //   currentAllComponentState[state] = input.target.files[0];
  //   error[state] = false;
  //   this.setState({
  //     ...this.state,
  //     currentAllComponentState,
  //     isUrl: false,
  //     isBlock: true
  //   });
  // };

  //UPLOAD PROFILE IMAGE
  updateImageDataInStateAndPreviewImage = (input, state) => {
    const { currentAllComponentState, error } = this.state;
    // currentAllComponentState[state] = input.target.files[0];

    error[state] = false;

    if (input) {
      if (input?.constructor === String) {
        currentAllComponentState[state] = input;
        this.props.dialog.setDialog({
          ...this.props.dialog,
          open: false,
          title: "Edit",
          body: "",
          positiveBtn: "Ok",
          negativeBtn: "CANCEL",
          dontShowButtons: true,
          onOk: () => { },
          onCancel: () => { },
        });
        this.setState({
          ...this.state,
          currentAllComponentState,
          isUrl: false,
          isBlock: true,
        });
      } else {
        const reader = new FileReader();

        reader.addEventListener(
          "load",
          async () => {
            // convert image file to base64 string
            currentAllComponentState[state] = await reader.result;
            this.setState({
              ...this.state,
              currentAllComponentState,
              isUrl: false,
              isBlock: true,
            });
          },
          false
        );

        if (input?.target?.files?.[0]) {
          reader.readAsDataURL(input?.target?.files?.[0]);
        }
      }
    } else {
      currentAllComponentState[state] = "";
      this.setState({
        ...this.state,
        currentAllComponentState,
        isUrl: false,
        isBlock: true,
      });
    }
  };

  dateValidation = (value, state, type, isFutureHideDate, isPastHideDate) => {
    let { currentAllComponentState, error, isErrorOccured } = this.state;
    isErrorOccured = false;
    var varDate = new Date(value); //dd-mm-YYYY
    var today = new Date();
    varDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (varDate) {
      if (isNaN(varDate.getTime())) {
        // d.valueOf() could also work
        // date is not valid
        currentAllComponentState[state] = varDate;
        error[state] = true;
        isErrorOccured = true;
        this.setState({
          ...this.state,
          error: error,
          isBlock: isErrorOccured,
          currentAllComponentState,
        });
      } else {
        if (isFutureHideDate && isPastHideDate) {
          if (varDate > today || varDate < today) {
            // date is valid
            currentAllComponentState[state] = varDate;
            error[state] = true;
            isErrorOccured = true;
            this.setState({
              ...this.state,
              error: error,
              isBlock: isErrorOccured,
              currentAllComponentState,
            });
          } else {
            // date is valid
            currentAllComponentState[state] = varDate;
            error[state] = false;
            this.setState({
              ...this.state,
              error: error,
              isBlock: isErrorOccured,
              currentAllComponentState,
            });
          }
        } else if (isFutureHideDate) {

          if (varDate.getFullYear() >= 1900) {
            if (varDate > today) {
              // date is valid
              currentAllComponentState[state] = varDate;
              error[state] = true;
              isErrorOccured = true;
              this.setState({
                ...this.state,
                error: error,
                isBlock: isErrorOccured,
                currentAllComponentState,
              });
            } else {
              // date is valid
              currentAllComponentState[state] = varDate;
              error[state] = false;
              this.setState({
                ...this.state,
                error: error,
                isBlock: isErrorOccured,
                currentAllComponentState,
              });
            }
          } else {
            currentAllComponentState[state] = varDate;
            error[state] = true;
            isErrorOccured = true;
            this.setState({
              ...this.state,
              error: error,
              isBlock: isErrorOccured,
              currentAllComponentState,
            });
          }
        } else if (isPastHideDate) {
          // var selelctedDate = new Date(value); //dd-mm-YYYY
          // var today = new Date();

          if (varDate < today) {
            // date is valid
            currentAllComponentState[state] = varDate;
            error[state] = true;
            isErrorOccured = true;
            this.setState({
              ...this.state,
              error: error,
              isBlock: isErrorOccured,
              currentAllComponentState,
            });
          } else {
            // date is valid
            currentAllComponentState[state] = varDate;
            error[state] = false;
            this.setState({
              ...this.state,
              error: error,
              isBlock: isErrorOccured,
              currentAllComponentState,
            });
          }
        } else {
          // date is valid
          currentAllComponentState[state] = varDate;
          error[state] = false;
          this.setState({
            ...this.state,
            error: error,
            isBlock: isErrorOccured,
            currentAllComponentState,
          });
        }
      }
    }
    return isErrorOccured;
  };

  updateDataInStateWithErrorHandler = (
    value,
    state,
    objKey,
    component,
    cleanUpKeys
  ) => {
    const { currentAllComponentState, error, fieldsToValidate } = this.state;

    let _obj = fieldsToValidate;
    if (objKey === "isChecked") {
      if (!value) {
        error[state] = false;
        _obj = fieldsToValidate.filter((val) => val.key !== state);
      } else {

        error[state] = false;
        _obj.push({ ...component });
      }
      currentAllComponentState[state] = {
        ...component.value,
        [objKey]: value,
      };

      this.setState({
        currentAllComponentState,
        error,
        fieldsToValidate: _obj,
      });
    } else {
      error[state] = false;

      // currentAllComponentState[state]={...currentAllComponentState[state][objKey]:d,[objKey] : value};
      currentAllComponentState[state] = {
        ...currentAllComponentState[state],
        [objKey]: value,
      };

      if (objKey === "dropdown1Value") {
        currentAllComponentState[state] = {
          ...currentAllComponentState[state],
          "dropdown2Value": null,
        };
      }
      this.setState({ currentAllComponentState, error });
    }
  };

  //TEXT FIELD, INPUTADORNMENT, SIMPLE SELECT, DATE RANGE PICKER
  /**
   *
   * @param {*} value - New value to be updated to the state
   * @param {*} key - Name of the state
   * @param {*} type - Type derived from the component props. Usually number, enquiryForm.
   * @param {*} cleanUpKeys - States to be cleaned on change.
   * @param {*} limit - Limit of the digits, if type is a number.
   * @param {*} isDecimalCheckNeeded - Checks for the decimals (10 characters pre decimal & 2 characters post decimal)
   * @param {*} isFutureHideDate - Hides future date
   * @param {*} isPastHideDate - Hides past date
   * @param {*} mobileISDCodeKey - mobile number isd code key
   * @param {*} component - The whole component json.
   */
  updateDataInState = (
    value,
    key,
    type,
    cleanUpKeys,
    limit,
    isDecimalCheckNeeded,
    isFutureHideDate,
    isPastHideDate,
    mobileISDCodeKey,
    component,
    setkeyValue,
    maxNumberInputLimit
  ) => {
    const { mandatoryExceptions } = this.props
    let { fieldsToValidate } = this.state
    const { currentAllComponentState, error } = this.state;


    if( typeof mandatoryExceptions !== "undefined" ) {
      if(Array.isArray(mandatoryExceptions)){
        mandatoryExceptions.forEach(element => {
          //All the needed mandatory scenarios when state update.
          // Example: In service add/Edit, Duration should be mandatory when isBookable is true/checked.
          let mandatoryKey = element?.key
          let dependents = element?.dependents
          if(Array.isArray(dependents) && dependents?.length > 0 ){
            if(mandatoryKey === key) {
              dependents.forEach(dep => {
                if(this.state.currentAllComponentState?.[key] === false && value === true ){
                  fieldsToValidate.push({...dep})
                } else if(this.state.currentAllComponentState?.[key] === true && value === false){
                  fieldsToValidate = fieldsToValidate.filter(x => x.key !== dep.key)
                  if(error[dep.key] === true){
                    error[dep.key] = false
                  }
                }
              })
            }
          }
        });
      }

      // this.setState({fieldsToValidate: finalFieldsToValidate}, () => {
      //   console.log("%c BLUE BOY :boy:", "color:blue; font-size:42px")
      //   console.log(this.state.fieldsToValidate)
      // })
    }

    this.setState({
      error: error,
      fieldsToValidate: fieldsToValidate,
    });

    if (component?.type === formComponentTypes.GOOGLE_MAP_AUTOCOMPLETE) {
      // If none of these property exists in the address value object, then return;
      if(!(!!value?.address && !!value?.address_components && !!value?.latitude && !!value?.longitude)){
        currentAllComponentState[key] = ""
        this.setState({
          currentAllComponentState
        })
        return;
      }
      else {
        if (Array.isArray(component?.dependentKeys)) {
          if (component?.dependentKeys?.length > 0) {
            //street_number
            //route
            //sublocality
            //locality  (or) political
            //administrative_area_level_2 (or) political
            //administrative_area_level_1 (or) political
            //country (or) political
            //postal_code
            let addressLine1 = `${value?.address_components?.filter(_ => _?.types?.includes("street_number"))?.[0]?.long_name ?? ""} ${value?.address_components?.filter(_ => _?.types?.includes("route"))?.[0]?.long_name ?? ""}`
            let addressLine2 = `${value?.address_components?.filter(_ => _?.types?.includes("sublocality"))?.[0]?.long_name ?? ""}`
            // let street_number = value?.address_components?.filter(_ => _?.types?.includes("street_number"))?.[0]?.long_name ?? ""
            // let route = value?.address_components?.filter(_ => _?.types?.includes("route"))?.[0]?.long_name ?? ""
            let locality = value?.address_components?.filter(_ => _?.types?.includes("locality"))?.[0]?.long_name ?? ""
            let administrative_area_level_2 = value?.address_components?.filter(_ => _?.types?.includes("administrative_area_level_2"))?.[0]?.long_name ?? ""
            let administrative_area_level_1 = value?.address_components?.filter(_ => _?.types?.includes("administrative_area_level_1"))?.[0]?.long_name ?? ""
            let country = value?.address_components?.filter(_ => _?.types?.includes("country"))?.[0]?.long_name ?? ""
            let postal_code = value?.address_components?.filter(_ => _?.types?.includes("postal_code"))?.[0]?.long_name ?? ""

            component.dependentKeys.map(_ => {
              if(_ === addressComponentTypes.addressLine1){
                currentAllComponentState[_] = addressLine1;
                error[_] = false;
              }
              if(_ === addressComponentTypes.addressLine2){
                currentAllComponentState[_] = addressLine2;
                error[_] = false;
              }
              if(_ === addressComponentTypes.area){
                if(locality === null){
                  currentAllComponentState[_] = administrative_area_level_2;
                } else if (administrative_area_level_2 === null){
                  currentAllComponentState[_] = locality;
                } else {
                  currentAllComponentState[_] = administrative_area_level_2;
                }
                error[_] = false;
              }
              if(_ === addressComponentTypes.state){
                currentAllComponentState[_] = administrative_area_level_1;
                error[_] = false;
              }
              if(_ === addressComponentTypes.country){
                currentAllComponentState[_] = country;
                error[_] = false;
              }
              if(_ === addressComponentTypes.postalCode){
                currentAllComponentState[_] = postal_code;
                error[_] = false;
              }
              return _
            })
            this.setState({ currentAllComponentState, isBlock: true });
          }
        }
      }
    }

    if (Array.isArray(cleanUpKeys)) {
      cleanUpKeys.map((_) => {
        if (typeof currentAllComponentState[_] === typeof "") {
          currentAllComponentState[_] = "";
        } else if (Array.isArray(currentAllComponentState[_])) {
          currentAllComponentState[_] = [];
        } else if (moment(currentAllComponentState[_]).isValid()) {
          currentAllComponentState[_] = null;
        } else if (typeof currentAllComponentState[_] === typeof {}) {
          currentAllComponentState[_] = null;
        }
        return _;
      });
    }
    if (setkeyValue && Array.isArray(setkeyValue)) {
      setkeyValue.map((_) => {
        let splitedValue = null;
        if (_.split || _.split === 0) {
          splitedValue = value?.[_.steKey]?.split(_?.splited)?.[_.split]
        }
        currentAllComponentState[_.key] = splitedValue ? splitedValue : value ? _?.value ? _?.value : value ? value?.[_?.steKey] : null : null;
        return _;
      });
    }
    if(component?.type === formComponentTypes.INPUT_BOX && limit){
      if (value.length <= limit) {
        currentAllComponentState[key] = value;
        error[key] = false;
        this.setState({
          currentAllComponentState,
          isBlock: true,
        });
      } else {
        return
      }
    }
    if (
      type === "number" ||
      type === "textArea" ||
      (component?.type === formComponentTypes.MOBILE_INPUT_BOX &&
        isDecimalCheckNeeded)
    ) {
      /* let isLimit = limit
        ? limit
        : getMobileLimitBasedOnCC(
          this.state.currentAllComponentState?.[mobileISDCodeKey]
        ); */
      if (isDecimalCheckNeeded) {
        const preDecimal = value.split(".")[0];
        const postDecimal = value.split(".")[1];
        const noFirstDigitZero = /^[^0]\d*/;
        if (value.length === 0) {
          currentAllComponentState[key] = value;
          error[key] = false;
          this.setState({
            currentAllComponentState,
            isBlock: true,
          });
        } else {
          if (noFirstDigitZero?.test(value)) {
            if (preDecimal.length <= 10) {
              if (postDecimal) {
                if (postDecimal.length <= 2) {
                  currentAllComponentState[key] = value;
                  error[key] = false;
                  this.setState({
                    currentAllComponentState,
                    isBlock: true,
                  });
                } else {
                  // post decimal exceeds limit
                  return;
                }
              } else {
                currentAllComponentState[key] = value;
                error[key] = false;
                this.setState({
                  currentAllComponentState,
                  isBlock: true,
                });
              }
            }
          } else {
            return;
          }
        }
      } else if(maxNumberInputLimit !== undefined){
        if(parseInt(value) <= parseInt(maxNumberInputLimit)){
          currentAllComponentState[key] = value;
          error[key] = false;
          this.setState({
            ...this.state,
            currentAllComponentState,
            isBlock: true,
          });
        }
      } else {
        // if (value.length <= isLimit) {

          // console.log(`${this.state.currentAllComponentState?.[mobileISDCodeKey]}${value}`)
          // console.log(value)
          // console.log(parsePhoneNumber(`${this.state.currentAllComponentState?.[mobileISDCodeKey]}${value}`)?.isValid())

        /*   if(Array.isArray(getMobileLimitBasedOnCC(this.state.currentAllComponentState?.[mobileISDCodeKey])))
          {
            if(checkLimit(getMobileLimitBasedOnCC(this.state.currentAllComponentState?.[mobileISDCodeKey]), value) ||
            value.length <= getMobileLimitBasedOnCC(this.state.currentAllComponentState?.[mobileISDCodeKey])?.[1]  )
            { */
              currentAllComponentState[key] = value;
              error[key] = false;
              this.setState({
                ...this.state,
                currentAllComponentState,
                isBlock: true,
              });
           /*  }
          }
          else
          {
            if (value.length <= isLimit)
            {
              currentAllComponentState[key] = value;
              error[key] = false;
              this.setState({
                ...this.state,
                currentAllComponentState,
                isBlock: true,
              });
            }
          } */

          // if(value.isValid)


          /* // console.log( checkLimit(getMobileLimitBasedOnCC(this.state.currentAllComponentState?.[mobileISDCodeKey]), value) || value.length <= getMobileLimitBasedOnCC(this.state.currentAllComponentState?.[mobileISDCodeKey])?.[1] )
          // if(checkLimit(getMobileLimitBasedOnCC(this.state.currentAllComponentState?.[mobileISDCodeKey]), value) ||
          //   value.length <= Array.isArray(getMobileLimitBasedOnCC(this.state.currentAllComponentState?.[mobileISDCodeKey]))
          //   ? getMobileLimitBasedOnCC(this.state.currentAllComponentState?.[mobileISDCodeKey])?.[1] : getMobileLimitBasedOnCC(this.state.currentAllComponentState?.[mobileISDCodeKey]) )
          // ? true : true )
        //   {
        //     console.log("INSIDE ----------")
        //   currentAllComponentState[key] = value;
        //   error[key] = false;
        //   this.setState({
        //     ...this.state,
        //     currentAllComponentState,
        //     isBlock: true,
        //   });
        // } */
      }
    } else if (type === "array") {
      if (currentAllComponentState[key].indexOf(value) > -1) {
        let _array = currentAllComponentState[key];
        error[key] = false;
        currentAllComponentState[key] = _array.filter((val) => {
          return val !== value;
        });
      } else {
        error[key] = false;
        currentAllComponentState[key].push(value);
      }
      this.setState({  currentAllComponentState, isBlock: true });
    } else if (type === "date") {
      this.dateValidation(value, key, type, isFutureHideDate, isPastHideDate);
    } else if (key === "recurrence") {
      let finalerror = {
        from_timestamp: false,
        to_timestamp: false,
        daily_date_from: false,
        daily_date_to: false,
        daily_start_time: false,
        daily_end_time: false,
        weekly_date_range: false,
        weekly_start_time: false,
        weekly_end_time: false,
      };
      currentAllComponentState[key] = value;
      error[key] = false;
      this.setState({
        error: { ...error, ...finalerror },
        currentAllComponentState,
        isBlock: true,
      });
    } else if (key === "activity_mode") {
      let finalerror = {
        meeting_link: false,
        add_location: false,
      };
      currentAllComponentState[key] = value;
      error[key] = false;
      this.setState({
        error: { ...error, ...finalerror },
        currentAllComponentState,
        isBlock: true,
      });
    } else {
      currentAllComponentState[key] = value;
      error[key] = false;
      this.setState({  currentAllComponentState, isBlock: true });
      this.forceUpdate()
    }
  };

  // ON SUBMIT VALIDATION
  handleValidation = () => {
    //
    const { error, errorText, fieldsToValidate } = this.state;
    // let {errorIndex} = this.state

    let isErrorOccured = this.state.isErrorOccured;
    console.log(isErrorOccured)

    const validators = (val) => {
      if (val.type === formComponentTypes.SWITCHBOXDROPDOWN) {
        if (val?.value?.constructor === Object) {
          let _keys = Object.keys(val.value);
          _keys.map((vals) => {

            // if (this.state.currentAllComponentState[val.key][vals])
            if (
              this.state.currentAllComponentState?.[val.key]?.[vals]
                ?.constructor === Array
            ) {
              if (!this.state.currentAllComponentState[val.key][vals].length > 0) {
                error[val.key] = true
                isErrorOccured = true
              }
              // error[val.key] = !Boolean(
              //   this.state.currentAllComponentState[val.key][vals].length > 0
              // );
              // isErrorOccured = !Boolean(
              //   this.state.currentAllComponentState[val.key][vals].length > 0
              // );
            } else if (
              this.state.currentAllComponentState?.[val.key]?.[vals]?.constructor === Object
            ) {
              if (!Boolean(
                Object.keys(
                  this.state.currentAllComponentState[val.key][vals]
                ).length > 0
              )) {

                error[val.key] = true
                isErrorOccured = true
              }
            } else {
              if (!Boolean(
                this.state.currentAllComponentState?.[val.key]?.[vals]
              )) {
                error[val.key] = true
                isErrorOccured = true
              }

            }
            return true
          });
          // let _keys = Object.keys(fieldsToValidate[10].value)
          // _keys.map()
        }
      }
      if (val.type === formComponentTypes.INPUT_BOX) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        } else if (val.componentType === "email") {
          if (!validateEmail(this.state.currentAllComponentState[val.key])) {
            error[val.key] = true;
            isErrorOccured = true;
          }
        }
      }
      if (val.type === formComponentTypes.PROFILE_UPLOAD_BUTTON) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }
      if (val.type === formComponentTypes.SELECT_BOX) {
        if (
          val.isMulti
            ? !this.state.currentAllComponentState[val.key]?.length
            : !this.state.currentAllComponentState[val.key]?.value
        ) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }

      // if(val.type === formComponentTypes.)

      if (val.type === formComponentTypes.DATE_RANGE_PICKER) {
        if (!this.state.currentAllComponentState[val.key][0]) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }
      if (val.type === formComponentTypes.RADIO_BUTTON) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }

      if (val.type === formComponentTypes.SELECT_BOX_WITH_PAGINATION) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        }
        if (Array.isArray(this.state.currentAllComponentState[val.key])) {
          if (this.state.currentAllComponentState[val.key].length === 0) {
            error[val.key] = true;
            isErrorOccured = true;
          }
        } else if (!this.state?.currentAllComponentState?.[val.key]?.value) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }
      if (val.type === formComponentTypes.DATE_TIME_PICKER) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        } else {
          if (val.isDate) {
            // isErrorOccured = true;
            isErrorOccured = this.dateValidation(
              this.state.currentAllComponentState[val.key],
              val.key,
              "date",
              val.componentProps.isHideFutureDate,
              val.componentProps.isHidePastDate
            );
          }
        }
      }
      if (val.type === formComponentTypes.CUSTOMIZED_RADIO_BUTTON) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }
      if (val.type === formComponentTypes.CHECK_BOX) {
        if (!this.state.currentAllComponentState[val.key].length) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }
      // if (val.type === formComponentTypes.DATE_TIME_PICKER) {
      //   if (!this.state.currentAllComponentState[val.key]) {
      //     error[val.key] = true;
      //     isErrorOccured = true;
      //   }
      // }
      // if (val.type === formComponentTypes.ICNUMBERPASSPORT) {
      //   //
      //   console.log(val)
      //   let obj = Object.keys(
      //     this.state.currentAllComponentState[val.key]
      //   ).filter((val) => {
      //     return this.state.currentAllComponentState.ic_passport[val];
      //   });
      //   console.log(obj)
      //   if(obj.length > 0)
      //   {
      //     obj.map((x) => {
      //       // if(val?.value?.[x]?.length < 12)
      //       if(this.state.currentAllComponentState.ic_passport?.[x]?.length < 12)
      //       {
      //         //
      //         console.log("INSIDE")
      //         error[val.key] = true;
      //         isErrorOccured = true
      //         this.setState({ errorIndex : 2})
      //       }
      //       return x
      //     })
      //   }
      //   if (!(obj.length > 0)) {
      //     error[val.key] = true;
      //     isErrorOccured = true;
      //     this.setState({ errorIndex : 1})
      //   }
      // }
      if (val.type === formComponentTypes.MOBILE_INPUT_BOX) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        } else {
          if (val.isFieldAPhoneNumber) {
            // const limit = getMobileLimitBasedOnCC(
            //   this.state.currentAllComponentState?.[val?.mobilenumberISDcodeKey]
            // );
            const selectedCountry = countryCode?.filter(
              (cc) => cc.dial_code === this.state.currentAllComponentState?.[val?.mobilenumberISDcodeKey]
            );
            const ISO2Code = selectedCountry?.[0]?.code;
            //
            console.log(this.state.currentAllComponentState)
            // console.log(val)


            console.log(this.state.currentAllComponentState.mobile_type!== undefined)
            console.log("\n")
            if(this.state.currentAllComponentState.mobile_type !== undefined)
            {
              if(!isValidNumber(this.state.currentAllComponentState?.[val?.key].toString(), ISO2Code?.toString()))
              {
                error[val.key] = true;
              isErrorOccured = true;
              }
            }
            else{
            if (
              !isValidPhoneNumber(this.state.currentAllComponentState?.[val?.key].toString(), ISO2Code?.toString())
            ) {
              error[val.key] = true;
              isErrorOccured = true;
            }
          }
        }
        }
      }
      if (val.type === formComponentTypes.FROM_TO_DATE_TIME_PICKER) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }

      // Location field validation
      if (val.type === formComponentTypes.GOOGLE_MAP_AUTOCOMPLETE) {
        const field = this.state.currentAllComponentState[val.key];
        if (!field) {
          error[val.key] = true;
          isErrorOccured = true;
        } else if (field) {
          if (typeof field !== "object") {
            error[val.key] = true;
            isErrorOccured = true;
          } else if (typeof field === "object") {
            if (
              !field.hasOwnProperty("latitude") &&
              !field.hasOwnProperty("longitude")
            ) {
              error[val.key] = true;
              isErrorOccured = true;
            }
          }
        }
      }

      //Recurrence Validation
      if (val.type === formComponentTypes.ACTIVITY_RECURRENCE) {

        if (val.key === "recurrence") {
          if (!this.state.currentAllComponentState[val.key]) {
            error[val.key] = true;
            isErrorOccured = true;
          }
        }
        if (val.key === "from_timestamp" || val.key === "to_timestamp") {
          if (!this.state.currentAllComponentState[val.key]) {
            if (
              this.state.currentAllComponentState["recurrence"] ===
              "DO NOT REPEAT"
            ) {
              error[val.key] = true;
              isErrorOccured = true;
            } else {
              error[val.key] = false;
            }
          }
        }
        if (
          val.key === "daily_date_from" ||
          val.key === "daily_date_to" ||
          val.key === "daily_start_time" ||
          val.key === "daily_end_time"
        ) {
          if (!this.state.currentAllComponentState[val.key]) {
            if (this.state.currentAllComponentState["recurrence"] === "DAILY") {
              error[val.key] = true;
              isErrorOccured = true;
            } else {
              error[val.key] = false;
            }
          }
        }
        if (
          val.key === "repeat_on" ||
          val.key === "weekly_date_range" ||
          val.key === "weekly_start_time" ||
          val.key === "weekly_end_time"
        ) {
          if (this.props.activityEdit) {
            if (val.key === "repeat_on") {
              error[val.key] = false;
              isErrorOccured = false;
            }
          } else {
            if (
              !this.state.currentAllComponentState[val.key] ||
              (val.key === "weekly_date_range" &&
                this.state.currentAllComponentState[val.key]?.[0] === "" &&
                this.state.currentAllComponentState[val.key]?.[1] === "")
            ) {
              if (
                this.state.currentAllComponentState["recurrence"] === "WEEKLY"
              ) {
                error[val.key] = true;
                isErrorOccured = true;
              } else {
                error[val.key] = false;
              }
            }
          }
        }
      }

      if (val.type === formComponentTypes.ACTIVITY_MODE) {
        if (val.key === "activity_mode") {
          if (!this.state.currentAllComponentState[val.key]) {
            error[val.key] = true;
            isErrorOccured = true;
          }
        }
        // if (val.key === "meeting_link") {
        //   if (!this.state.currentAllComponentState[val.key]) {
        //     if (
        //       this.state.currentAllComponentState["activity_mode"] ===
        //       "VIDEO_CALL"
        //     ) {
        //       error[val.key] = true;
        //       isErrorOccured = true;
        //     } else {
        //       error[val.key] = false;
        //     }
        //   }
        // }
        if (val.key === "add_location") {
          if (!this.state.currentAllComponentState[val.key]) {
            if (
              this.state.currentAllComponentState["activity_mode"] ===
              "IN_PERSON"
            ) {
              error[val.key] = true;
              isErrorOccured = true;
            } else {
              error[val.key] = false;
            }
          }
        }
      }
      if (val.type === formComponentTypes.DURATION_PICKER) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }
      if (val.type === formComponentTypes.PRODUCT_VALIDITY) {
        // If Start Date is null
        if (!this.state.currentAllComponentState[val?.key?.["startDate"]]) {
          error[val.key?.["startDate"]] = true;
          isErrorOccured = true;
        }

        // If End Date is null
        // if (!this.state.currentAllComponentState[val?.key?.["endDate"]]) {
        //   error[val.key] = true;
        //   isErrorOccured = true;
        // }

        // If start date is less than the end date
        if(this.state.currentAllComponentState[val?.key?.["startDate"]] && this.state.currentAllComponentState[val?.key?.["endDate"]]){
          if(moment(this.state.currentAllComponentState[val?.key?.["startDate"]]).isAfter(moment(this.state.currentAllComponentState[val?.key?.["endDate"]]))){
            error[val?.key?.["endDate"]] = true;
            isErrorOccured = true;
          }
        }
      }
      if (val.type === formComponentTypes.RESOURCE_VALIDITY) {
        // If Start Date is null
        if (!this.state.currentAllComponentState[val?.key?.["startDate"]]) {
          error[val?.key?.["startDate"]] = true;
          isErrorOccured = true;
        }

        // If End Date is null
        if (isTrialAccountType() && !isClientAddEditForm(this.props.match.path) && !this.state.currentAllComponentState[val?.key?.["endDate"]]) {
          error[val?.key?.["endDate"]] = true;
          isErrorOccured = true;
        }

        // If start date is less than the end date
        if(this.state.currentAllComponentState[val?.key?.["startDate"]] && this.state.currentAllComponentState[val?.key?.["endDate"]]){
          if(moment(this.state.currentAllComponentState[val?.key?.["startDate"]]).isAfter(moment(this.state.currentAllComponentState[val?.key?.["endDate"]]))){
            error[val?.key?.["endDate"]] = true;
            isErrorOccured = true;
          }
        }
      }
      if(val.type === formComponentTypes.EFFECTIVE_EXPIRY){
        const { currentAllComponentState: formState } = this.state
        // const { key } = val
        const effective = formState?.["effective"]
        const noExpiry = formState?.["noExpiry"]
        const expiry = formState?.["expiry"]

        // Validation Starts
        // Check if expiry date is not null
        if(!effective) {
          error["effective"] = true
          errorText["effective"] = errorMessage.requiredMessage
        }
        if(!Boolean(noExpiry) && !expiry) {
          error["expiry"] = true
          errorText["expiry"] = errorMessage.requiredMessage
        } else if(Boolean(noExpiry) ) {
          error["expiry"] = false
          errorText["expiry"] = ""
        }

        if(effective && expiry && !Boolean(noExpiry)){
          if(moment(effective).isAfter(expiry, 'day')){
            error["expiry"] = true
            errorText["expiry"] = errorMessage.dateIsBeforeError("Effective", "Expiry")
          }
        }
      }
    };


    fieldsToValidate.map((val) => {
      validators(val);
      return val;
    });

    //Time Difference Error
    // let tdError = false;
    // Object.keys(this.state.timeDifferenceError).map((x) => {
    //   if (this.state.timeDifferenceError[x] === true) {
    //     tdError = true;
    //   }
    //   return x;
    // });

    // let isError = !Object.values(error).every(x => !x)
    let isError = Object.values(error)?.length > 0 ? !Object.values(error)?.every(x => !x) : false

    this.setState({
      error: error,
      errorText: errorText,
      // isBlock: isErrorOccured,
      isBlock: isError,
      // isErrorOccured: isErrorOccured,
      isErrorOccured: isError
    });
    // return isErrorOccured;
    return isError;
  };

  //On Save
  onSaveButtonClicked = () => {
    this.props.onSaveButtonClicked(
      this.handleValidation(),
      // false,
      this.state.currentAllComponentState,
      this.state.isUrl
    );
  };

  toggleDrawer = (bool, component, callback) => {
    const { openDrawer } = this.state;
    const currentState = openDrawer;
    currentState["open"] = bool;
    currentState["component"] = component;
    currentState["callback"] = callback;
    this.setState({ openDrawer: currentState });
  };

  handleOnOkModal = (key, callback) => {
    let _obj = {};
    _obj["address"] = this.state.locationViaMap?.address;
    _obj["latitude"] = this.state.locationViaMap?.lat;
    _obj["longitude"] = this.state.locationViaMap?.lng;

    _obj["address_components"] = this.state.locationViaMap?.addressComponents;
    const { currentAllComponentState } = this.state;
    currentAllComponentState["add_location"] = _obj;
    if(callback){
      callback(_obj, key)
    }
    this.updateDataInState(
      {
        ..._obj
      },
      key,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      {
        type: formComponentTypes.GOOGLE_MAP_AUTOCOMPLETE,
        dependentKeys: Object.values(addressComponentTypes)
      },
      null,
    );
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: false,
      title: "",
      body: "",
      positiveBtn: "SAVE",
      negativeBtn: "CANCEL",
      onOk: this.handleOnOkModal,
      onCancel: this.handleOnClose,
    });
  };

  handleOnClose = () => {
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: false,
      title: "",
      body: "",
      positiveBtn: "SAVE",
      negativeBtn: "CANCEL",
      onOk: this.handleOnOkModal,
      onCancel: this.handleOnClose,
    });
  };

  getUserLocation = (key) => {
    const add_location = this.state.currentAllComponentState?.[key]
    if(add_location?.latitude && add_location?.longitude) {
      let coords = {
        lat: parseFloat(add_location?.latitude),
        lng: parseFloat(add_location?.longitude),
      }
      return coords
    }
    else if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        let coords = {
          lat: parseFloat(position.coords.latitude),
          lng: parseFloat(position.coords.longitude),
        }
        return coords
      });
    }
  }

  openDialogMap = (key, callback) => {
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: true,
      title: <div>Find Location</div>,
      body: (
        <div style={{ width: "100%", height: "500px" }}>
          {/* <MapDragabble
            getState={(data) => {
              this.setState({ locationViaMap: data });
            }}
            address={this.state.locationViaMap.address}
          /> */}

          <CustomGoogleMap setParentState={(data) => {
              this.setState({ locationViaMap: data });
            }}
            address={this.state.currentAllComponentState?.[key]}
            userLocation={this.getUserLocation(key)}
            isVenueSelected={!!this.state.currentAllComponentState?.["venue"] ? this.state.currentAllComponentState?.["venue"] === options.newAddress.value ? false : true  : false}
            />
        </div>
      ),
      positiveBtnDontShow: !!this.state.currentAllComponentState?.["venue"] ? this.state.currentAllComponentState?.["venue"] === options.newAddress.value ? false : true  : false,
      positiveBtn: "SAVE",
      negativeBtn: !!this.state.currentAllComponentState?.["venue"] ? this.state.currentAllComponentState?.["venue"] === options.newAddress.value ? "CANCEL" : "GO BACK"  : "CANCEL" ,
      onOk: () => this.handleOnOkModal(key, callback),
      onCancel: this.handleOnClose,
    });
  };

  /**
   *
   * @param {object} component To send select key state value to the getMobileLimitBasedOnCC function to retrieve limit for the mobile field.
   * @returns {number} Limit based on the selected mobileISDcode to check for errors.
   */
  getLimit = (component) => {
    const limit = getMobileLimitBasedOnCC(
      this.state.currentAllComponentState?.[component?.key["select_key"]]
    );
    //
    return limit;
  };

  // getMobileNumberValidity = (component) => {
  //   //
  //     if(this.state.currentAllComponentState?.[component?.key["select_key"]] && this.state.currentAllComponentState?.[component?.key["input_value_key"]])
  //     {
  //       const isNumberValid = parsePhoneNumber(`${this.state.currentAllComponentState?.[component?.key["select_key"]]}${this.state.currentAllComponentState?.[component?.key["input_value_key"]]}`)?.isValid()
  //       return isNumberValid
  //     }
  //   }

  getErrorTextForMobileInputBox = (component) => {
    if (
      this.state.currentAllComponentState[component?.key["input_value_key"]]
    ) {
      //
      // console.log(checkLimit(getMobileLimitBasedOnCC(this.state.currentAllComponentState?.["mobileISDCodeKey"]), this.state.currentAllComponentState?.[component?.key["input_value_key"]]))
      // !checkLimit(getMobileLimitBasedOnCC(this.state.currentAllComponentState?.["mobileISDCodeKey"]), this.state.currentAllComponentState?.[component?.key["input_value_key"]]) ||
      // if(this.state.currentAllComponentState?.[component?.key["input_value_key"]].length <= getMobileLimitBasedOnCC(this.state.currentAllComponentState?.["mobileISDCodeKey"])?.[1]  )
      // if (!checkLimit(this.getLimit(component), this.state.currentAllComponentState?.[component?.key["input_value_key"]]))
      // if(
      //   this.state.currentAllComponentState?.[component?.key["input_value_key"]]
      //     ?.length < this.getLimit(component)
      // )
      // {
      //   return errorMessage.mobileNoLimitMessage(this.getLimit(component));
      // } else if (
      //   this.state.currentAllComponentState?.[component?.key["input_value_key"]]
      //     ?.length > this.getLimit(component)
      // ) {
      //   return errorMessage.mobileNoExceedsMessage(this.getLimit(component));
      // }
      /* if(!checkLimit(getMobileLimitBasedOnCC(this.state.currentAllComponentState?.["mobileISDCodeKey"]), this.state.currentAllComponentState?.[component?.key["input_value_key"]]))
      {
        console.log("INSIDE")
        if(Array.isArray(this.getLimit(component)))
        {
          return errorMessage.mobileFixedLimitMessage(this.getLimit(component))
        }
        else
        {
          if(
            this.state.currentAllComponentState?.[component?.key["input_value_key"]]
              ?.length < this.getLimit(component)
          )
          {
            return errorMessage.mobileNoLimitMessage(this.getLimit(component));
          } else if (
            this.state.currentAllComponentState?.[component?.key["input_value_key"]]
              ?.length > this.getLimit(component)
          ) {
            return errorMessage.mobileNoExceedsMessage(this.getLimit(component));
          }
        }

      } */

      const selectedCountry = countryCode?.filter(
        (cc) => cc.dial_code === this.state.currentAllComponentState?.["mobileISDCodeKey"]
      );
      const ISO2Code = selectedCountry?.[0]?.code;
      if(!isValidPhoneNumber(this.state.currentAllComponentState?.[component?.key["input_value_key"]].toString(), ISO2Code?.toString()))
      {
        return errorMessage.mobileNoValidMessage;
      }
      // else if (
      //   this.state.currentAllComponentState?.[component?.key["input_value_key"]]
      //     ?.length === this.getLimit(component)
      // )
      else
       {
        // console.log("CHECKING LENGTH", this.state.currentAllComponentState?.[component.key["input_value_key"]]?.length === this.getLimit(component))
        // Handle a valid mobile number error message.
        // if(!this.getMobileNumberValidity()){
        //     return errorMessage.mobileNoValidMessage
        //   }

        //Check first digit zero and all digit zero
        let value = this.state.currentAllComponentState?.[
          component?.key["input_value_key"]
        ];
        if (!checkFirstZeroAndAllZero(value)) {
          return errorMessage.mobileNoValidMessage;
        }
      }
    } else {
      return errorMessage.requiredMessage;
    }
  };

  getLocationErrorMessage = (component, optionalKeySpecification) => {
    const field = optionalKeySpecification
      ? this.state.currentAllComponentState[optionalKeySpecification]
      : this.state.currentAllComponentState[component.key];
    if (!field) {
      return errorMessage.requiredMessage;
    } else if (field) {
      if (typeof field !== "object") {
        return errorMessage.locationInvalid;
      } else if (typeof field === "object") {
        if (
          !field.hasOwnProperty("latitude") &&
          !field.hasOwnProperty("longitude")
        ) {
          return errorMessage.locationInvalid;
        }
      }
    }
  };

  handleSelectDependency = (selectedOption, state, dependentKeys, dependentKeySelector="value") => {
    const { currentAllComponentState, error } = this.state;

    if(Array.isArray(dependentKeys) && dependentKeys?.length > 0){
      dependentKeys.forEach(key => {
        if(selectedOption){
          currentAllComponentState[key] = selectedOption?.[`${dependentKeySelector}`]
        } else {
          currentAllComponentState[key] = ""
        }
        error[key] = false;
      })
      this.setState({ currentAllComponentState, isBlock: true });
      this.forceUpdate()
    }
    return false;
  }

  giveMeTheComponent = (component) => {
    const { currentAllComponentState: formState, error: formError, errorText } = this.state
    switch (component.type) {
      case formComponentTypes.PROFILE_UPLOAD_BUTTON:
        return (
          <UploadProfileImage
            value={
              this.state.isUrl
                ? ""
                : this.state.currentAllComponentState[component.key]
            }
            label={component?.componentProps?.label}
            profile_pic={this.state.isUrl ? component.value : ""}
            handleFileChange={(e) => {
              this.updateImageDataInStateAndPreviewImage(e, component.key);
            }}
            error={this.state.error[component.key] ? true : false}
            errorText={errorMessage.requiredMessage}
            id={component?.id}
          />
        );

      case formComponentTypes.INPUT_BOX:
        if (!component.isDontShow) {
          return (
            <TextField
              id={component?.id}
              required={component?.required || component?.isMandatory}
              type={component.componentProps.type}
              withBoldLabel={component.withBoldLabel}
              withoutUppercase={component?.withoutUppercase}
              onlyText={component?.onlyText}
              limit={component.limit}
              state={component.key}
              isLimitHelperTextNeeded={
                component?.componentProps?.isLimitHelperTextNeeded
              }
              overrideDecimalCheckForDiscount={
                component?.componentProps?.overrideDecimalCheckForDiscount
              }
              rowsMax={
                component.componentProps.rowsMax
                  ? component.componentProps.rowsMax
                  : 1
              }
              multiline={component.componentProps.multiline}
              title={component.label}
              placeholder={
                component.componentProps.placeholder
                  ? component.componentProps.placeholder
                  : ""
              }
              defaultValue=""
              value={this.state.currentAllComponentState[component.key]}
              handleOnChange={(e, limit) => {
                const { isDecimalCheckNeeded } = component;
                if (component?.onCallback) {
                  component.onCallback(e.target.value, component.key);
                }
                this.updateDataInState(
                  e.target.value,
                  component.key,
                  component?.componentProps?.type,
                  () => { },
                  limit,
                  isDecimalCheckNeeded,
                  null,
                  null,
                  null,
                  component,
                  null,
                  component?.componentProps?.maxNumberInputLimit
                );
              }}
              error={this.state.error[component.key] ? true : false}
              errorText={
                this.state.currentAllComponentState[component.key]
                  ? component?.componentProps?.type === "email"
                    ? errorMessage.emailMessage
                    : ""
                  : errorMessage.requiredMessage
              }
              disabled={component.disabled}
              endAdornmentText={component?.componentProps?.endAdornmentText}
            />
          );
        } else {
          return null;
        }
      case formComponentTypes.ICNUMBERPASSPORT:
        if (!component.isDontShow) {
          return (
            <ICNumberPassport
              id={component?.id}
              // ic_id={component?.ic_id}
              // passport_id={component?.passport_id}
              type={component.componentProps.type}
              withBoldLabel={component.withBoldLabel}
              withoutUppercase={component?.withoutUppercase}
              onlyText={component?.onlyText}
              limit={component.limit}
              state={component.key}
              rowsMax={
                component.componentProps.rowsMax
                  ? component.componentProps.rowsMax
                  : 1
              }
              multiline={component.componentProps.multiline}
              title={component.label}
              placeholder={
                component.componentProps.placeholder
                  ? component.componentProps.placeholder
                  : ""
              }
              defaultValue=""
              key={component.key}
              ic_label="I/C NUMBER"
              passport_label="PASSPORT NUMBER"
              value={this.state.currentAllComponentState[component.key]}
              handleOnChange={(value, key, type, limit) => {
                let { currentAllComponentState } = this.state;
                currentAllComponentState.ic_passport[key] = value;
                this.updateDataInState(
                  currentAllComponentState.ic_passport,
                  "ic_passport",
                  type,
                  () => { },
                  limit
                );
              }}
              error={this.state.error[component.key] ? true : false}
              errorText={errorMessage.icNumberPassport(this.state.errorIndex)}
              // seperateErrorText={errorMessage.icNumberPassport(this.state.errorIndex)}
              passport_disabled={component.passport_disabled}
              ic_disabled={component.ic_disabled}
            />
          );
        } else {
          return null;
        }
      case formComponentTypes.MOBILE_INPUT_BOX:
       console.log(component)
        return (
          <InputAdornmentComponent
            id={component?.componentProps?.id}
            select_id={component?.componentProps?.select_id}
            meta={component?.componentProps?.meta}
            required={component?.required || component?.isMandatory}
            title={component.label}
            options={component.options}
            type={component?.componentProps?.type}
            onlyText={component?.onlyText}
            disabled={component?.disabled}
            disableDropdown={component?.componentProps?.disableDropdown}
            customsize={component?.customsize}
            codeSize={component?.componentProps?.codeSize}
            helperFlagNeeded={component?.componentProps?.helperFlagNeeded}
            helperFlagControllers={
              component?.componentProps?.helperFlagControllers
            }
            allScreenCustomSize={component?.componentProps?.allScreenCustomSize}
            valueMObileNo={
              this.state.currentAllComponentState[
              component.key["input_value_key"]
              ]
            }
            valuePhoneCode={
              this.state.currentAllComponentState[component.key["select_key"]]
            }
            handleChangeSelect={(e) => {
              if(component.selectCallback){
                component.selectCallback(e, component.key["select_key"])
              }
              this.updateDataInState(
                e,
                component.key["select_key"],
                null, // adding the type "number" here will check for validation. So don't add it.
                component?.isComponentAMobileField
                  ? [component?.key?.["input_value_key"]] ?? null
                  : null,
                null,
                null,
                null,
                null,
                component?.key["select_key"]
              );
            }}
            handleMobileNo={(e) => {
              if(component.inputCallback){
                component.inputCallback(e.target.value, component.key["input_value_key"])
              }
              const { isDecimalCheckNeeded } = component;
              this.updateDataInState(
                e.target.value,
                component.key["input_value_key"],
                component?.componentProps?.type,
                null,
                null,
                isDecimalCheckNeeded,
                null,
                null,
                component?.key["select_key"],
                component
              );
            }}
            error={
              this.state.error[component.key["input_value_key"]] ||
                this.state.error[component.key["select_key"]]
                ? true
                : false
            }
            errorText={
              this.state.error[component.key["select_key"]]
                ? errorMessage.requiredMessage
                : this.getErrorTextForMobileInputBox(component)
            }
          />
        );

      case formComponentTypes.SELECT_BOX:
        if (component.isDontShow) {
          return <></>;
        } else {
          return (
            <SimpleSelect
              {...component}
              required={component?.required || component?.isMandatory}
              title={component.label}
              label={component.label}
              disabled={component?.isDisabled}
              isMulti={component.isMulti}
              options={component.options}
              isLoading={component.isLoading}
              hideCustomValueContainerHeight={
                component?.componentProps?.hideCustomValueContainerHeight
              }
              value={this.state.currentAllComponentState[component.key]}
              handleOnChange={(selectedValue) => {
                if (component.onSelectedCallback) {
                  component.onSelectedCallback(
                    selectedValue,
                    this.state.currentAllComponentState,
                    component?.key
                  );
                }
                if(component?.dependentKeys){
                  this.handleSelectDependency(selectedValue, this.state.currentAllComponentState, component?.dependentKeys, component?.dependentKeySelector)
                }
                this.updateDataInState(
                  selectedValue,
                  component.key,
                  component.type,
                  component.cleanUpKeys,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  component.setkeyValue
                );
              }}
              error={this.state.error[component.key] ? true : false}
              errorText={errorMessage.requiredMessage}
              input_id={component?.id}
            />
          );
        }
      case formComponentTypes.SELECT_BOX_WITH_PAGINATION:
        return (
          <AsyncPaginateSelect
            {...component}
            required={component?.required || component?.isMandatory}
            title={component.label}
            id={component?.id}
            label={component.label}
            isMulti={component.isMulti}
            isDisabled={component.isDisabled}
            loadOptions={component.options}
            cacheUniqs={component.cacheUniqs}
            // options={component.options}
            value={this.state.currentAllComponentState[component.key]}
            handleOnChange={(e) => {
              if (component.onSelectedCallback) {
                component.onSelectedCallback(
                  e,
                  this.state.currentAllComponentState
                );
              }
              this.updateDataInState(
                e,
                component.key,
                component.type,
                component.cleanUpKeys
              );
            }}
            error={this.state.error[component.key] ? true : false}
            errorText={errorMessage.requiredMessage}
          />
        );

      case formComponentTypes.DATE_RANGE_PICKER:
        if (component.isDontShow) {
          return <></>;
        } else {
          return (
            <DateRangePicker
              id={component?.id}
              isHidePastDate={component?.componentProps?.isHidePastDate}
              isHideFutureDate={component?.componentProps?.isHideFutureDate}
              value={this.state.currentAllComponentState[component.key]}
              label={component?.label}
              handleDateRange={(e) => {
                this.updateDataInState(e, component.key, component.type);
              }}
              error={this.state.error[component.key] ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          );
        }
      case formComponentTypes.BUTTON:
        return (
          <Button  id={component?.id} variant={"contained"} color={"primary"}>
            {component.label}
          </Button>
        );

      case formComponentTypes.LIST:
        return (
          <>
            {component.value.map((v, i) => (
              <div key={i}>
                <SimpleList title={v.title} />
              </div>
            ))}
          </>
        );
      case formComponentTypes.TABLE:
        return (
          <Table
            id={component?.id}
            columns={component.columns}
            exoticRow={component?.componentProps?.exoticRow}
            // ------------------------------------------
            hideMarginTop={component.hideMarginTop}
            // ------------------------------------------
            rows={component.rows}
            handleRowsReorder={component.handleRowsReorder}
            state={component.key}
            disableRemove={component.disableRemove}
            handleRemove={(s) => {
              component.handleRemove(s);
              if (component.removeUpdateKey) {
                const _ = this.state.currentAllComponentState[
                  component.removeUpdateKey
                ].filter((_) => _.value !== s.value);
                let currentAllComponentState = this.state
                  .currentAllComponentState;
                currentAllComponentState[component.removeUpdateKey] = _;
                this.setState({
                  currentAllComponentState,
                });
              }
            }}
            withoutRemove={component?.withoutRemove ? true : false}
            discount={component.discount}
            tax={component.tax}
            taxType={component.taxType}
            taxTypeOptions={component.taxTypeOptions}
            total={component.total}
            onDiscountChange={component.onDiscountChange}
            onTaxChange={component.onTaxChange}
            onTaxTypeChange={component.onTaxTypeChange}
            onTotalChange={component.onDiscountChange}
            withDiscount={component.withDiscount}
            withTax={component.withTax}
            withTaxAndTaxType={component.withTaxAndTaxType}
            withTotal={component.withTotal}
            currency={component.currency}
            onTextFieldChange={component.onTextFieldChange}
            needActionsAsIcons={component.needActionsAsIcons}
            editAction={component.editAction}
            deleteAction={component.deleteAction}
            colWidths={component?.componentProps?.colWidths}
            handlePopperOpen={component?.handlePopperOpen}
            handlePopperClose={component?.handlePopperClose}
            popperData={component?.popperData}
            openPopper={component?.openPopper}
            handleStrictStatusChange={component?.handleStrictStatusChange}
            handleDependentServiceChange={component?.handleDependentServiceChange}
            tableType={component?.componentProps?.tableType}
          />
        );

      case formComponentTypes.ADMINTABLE:
        return (
          <AdminTable
            rows={component?.rows}
            columns={component?.columns}
            handleRemove={component?.handleRemove}
            needActionsAsIcons={component?.needActionsAsIcons}
            withoutRemove={component?.withoutRemove ?? false}
            editAction={component?.editAction}
            deleteAction={component?.deleteAction}
            id={component?.id}
          />
        );

      case formComponentTypes.BUTTON_WITH_INPUT_FIELD:
        return (
          <ButtonWithInputField
            title={component.label}
            arrOfValues={this.state.currentAllComponentState[component.key]}
            buttonValue={component.buttonLabel}
            componentState={component.key}
            removeListFunc={this.removeItemFromAnArrayCommonFunction}
            error={this.state.error[component.key] ? true : false}
            errorMessage={errorMessage.requiredMessage}
            id={component?.id}

            handleButtonClick={(state) => {
              if (state) {
                let oldState =
                  this.state?.currentAllComponentState?.[component.key]
                    ?.length > 0
                    ? this.state.currentAllComponentState[component.key]
                    : [];
                oldState = [...oldState, state];
                let currAllCS = this.state.currentAllComponentState;
                currAllCS[component.key] = oldState;
                this.setState({
                  currentAllComponentState: currAllCS,
                }, () => {
                  if(component?.onSaveCallback){
                    component.onSaveCallback(currAllCS[component.key], component.key)
                  }
                });
              }
            }}
          />
        );
      case formComponentTypes.SUMMARY_CARD:

        let summaryData = this.state?.currentAllComponentState?.[
          component.value
        ] ?? {};
        if (component.isquote) {
          summaryData = component?.selectedItem ?? {}
        }
        if (!(summaryData?.constructor === Array && summaryData?.length > 0) && component?.isEnquiry) {
          summaryData = component?.selectedItem ?? {}
        }
        let providerDetails = summaryData;
        if (Array.isArray(summaryData)) {
          providerDetails = summaryData[0]
        }
        const provider = providerDetails?.providerInfo;
        const category = providerDetails?.providerInfo?.category?.map(_ => _.label)?.toString();
        return (
          <>
            {summaryData &&
              ((summaryData?.constructor === Array && summaryData?.length > 0) ||
                (summaryData?.constructor === Object &&
                  Object.keys(summaryData).length > 0)) ? (
              <>
                <Typography
                  variant="div"
                  component="div"
                  className={this.props.classes.summaryCardLabel}
                >
                  Summary
                </Typography>
                {summaryData?.constructor === Array ? (
                  summaryData.map((val) => {
                    return (
                      <div style={{ marginBottom: 10 }}>
                        <Summary
                          profile_pic={val.profile_pic}
                          withTextAvatar={component?.withTextAvatar}
                          item={val.label}
                          value={val.value}
                          defaultamount={val.defaultamount}
                          amount={`${val?.currency ?? "RM"} ${val.defaultamount ? parseFloat(val.defaultamount)?.toFixed(2) : parseFloat(val.amount)?.toFixed(2)}
                            ${val.term ? `(${val.term})` : ""}`}
                          packageCount={val.package_count}
                          servicesCount={val.service_count}
                          provider={provider?.label}
                          category={category}
                          total={component.total}
                          onTotalChange={component.onTotalChange}
                          isPriceEditable={val?.isPriceEditable}
                          isSmallScreen={component.isSmallScreen}
                        />
                      </div>
                    );
                  })
                ) : (
                  <>
                    {Object.keys(
                      this.state.currentAllComponentState[component.value]
                    ).length > 0 ? (
                      <Summary
                        profile_pic={summaryData.profile_pic}
                        item={summaryData.label}
                        amount={`${summaryData?.currency ?? ""} ${parseFloat(summaryData.amount)?.toFixed(2)}
                              ${summaryData.term ? `(${summaryData.term})` : ""}`}
                        packageCount={summaryData?.package_count}
                        servicesCount={summaryData?.service_count}
                        provider={provider?.label}
                        category={category}
                        currency={summaryData.currency}
                        total={component.total}
                        onTotalChange={component.onTotalChange}
                        isPriceEditable={summaryData?.isPriceEditable}
                        isSmallScreen={component.isSmallScreen}
                      />
                    ) : null}
                  </>
                )}
              </>
            ) : null}
          </>
        );

      case formComponentTypes.RECOMMENDATIONS_CARD:
        const item = this.state.currentAllComponentState.select_an_item;
        const value = this.state.currentAllComponentState?.select_caregiver
          ?.value;

        return (
          <>
            {item && (
              <Recommended
                id={component?.id}
                data={component.data}
                loading={component.loading}
                error={component.error}
                value={value}
                onClick={(val) => {
                  if (component.onSelectedCallback) {
                    component.onSelectedCallback(
                      val,
                      this.state.currentAllComponentState
                    );
                  }
                  this.updateDataInState(
                    val,
                    component.value,
                    formComponentTypes.SELECT_BOX,
                    component.cleanUpKeys
                  );
                }}
              />
            )}
          </>
        );
      case formComponentTypes.ADD_CONTACT_DRAWER:
        if (component.isDontShow) {
          return <></>;
        } else {
          console.log("\n\n")
          console.log(component)
          console.log("\n\n")
          return (
            <SimpleLabel
              callback={component.callback}
              component={component}
              id={component?.id}
              isError={component.contactCallerError}
            />
            // toggleDrawer={this.toggleDrawer}
          );
        }
      case formComponentTypes.DETAILS_CARD:
        if (component.isDontShow) {
          return <></>;
        } else {

          return (
            <DetailsCard
              component={component}
              id={component?.id}
              callback={component?.callback}
              editcomponent={component?.editcomponent}
              isDontShowEdit={component?.isEdit}
            />
            // toggleDrawer={this.toggleDrawer}
          );
        }
      case formComponentTypes.RADIO_BUTTON:
        return (
          <RadioButtonsGroup
            data={component}
            id={component?.id}
            value={this.state.currentAllComponentState[component.key]}
            handleOnChange={(value, key) => {
              if (component?.onChangeCallBack) {
                component.onChangeCallBack(value, key);
              }
              this.updateDataInState(value, key);
            }}
            error={this.state.error[component.key] ? true : false}
            errorText={errorMessage.requiredMessage}
          />
        );
      case formComponentTypes.CUSTOMIZED_RADIO_BUTTON:

        return (
          <CustomizedRadioButton
            data={component}
            id={component?.id}
            value={this.state.currentAllComponentState[component.key]}
            handleOnChange={(value, key) => {
              if (component?.onChangeCallBack) {
                component.onChangeCallBack(value, key);
              }
              this.updateDataInState(value, key);
            }}
            error={this.state.error[component.key] ? true : false}
            errorText={errorMessage.requiredMessage}
            disabled={component?.isDisabled}
          />
        );
      case formComponentTypes.CUSTOMIZED_TOGGLE_GROUP:
        return (
          <CustomizedToggleGroup
            data={component}
            id={component?.id}
            value={this.state.currentAllComponentState[component.key]}
            handleOnChange={this.updateDataInState}
            error={this.state.error[component.key] ? true : false}
            errorText={errorMessage.requiredMessage}
          />
        );
      case formComponentTypes.CHECK_BOX:
        if (component?.isHide) {
          return <></>;
        } else {
          return (
            <CheckboxCustom
              data={component}
              id={component?.id}
              isHide={component?.isHide}
              value={this.state.currentAllComponentState[component.key]}
              handleOnChange={(e) => {
                const value =
                  this.state.currentAllComponentState[component.key].indexOf(
                    e.target.value
                  ) > -1
                    ? false
                    : true;
                // val.value, data.key, "array"
                if (component.onChangeCallBack) {
                  component.onChangeCallBack(
                    value,
                    component.key,
                    e.target.value,
                    this.state.currentAllComponentState[component.key]
                  );
                }
                this.updateDataInState(e.target.value, component.key, "array");
              }}
              error={this.state.error[component.key] ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          );
        }
      // IF  YOU ARE USING DATATIME PICKER FOR ADD FORM-
      // KEEP INITIAL VALUE AS **NULL** not as **EMPTY STRING**
      case formComponentTypes.DATE_TIME_PICKER:

        return (
          <DateTimePickersCustom
            id={component?.id}
            required={component?.required || component?.isMandatory}
            state={component.key}
            onAccept={component?.handleOnAccept}
            isDate={component.isDate}
            isHideFutureDate={component?.componentProps?.isHideFutureDate}
            isHidePastDate={component?.componentProps?.isHidePastDate}
            inputFormat={component.inputFormat}
            minDate={component?.minDate ? component.minDate : null}
            isDateTime={component.isDateTime}
            label={component.label}
            onDeleteRemainder={component.onDeleteRemainder}
            error={this.state.error[component.key] ? true : false}
            disabled={component?.componentProps?.isDisable}
            errorText={
              component.isDate
                ? this.state.currentAllComponentState[component.key]
                  ? component?.componentProps?.isHideFutureDate &&
                    component?.componentProps?.isHidePastDate
                    ? errorMessage.pastFutureDateMessage
                    : component?.componentProps?.isHideFutureDate
                      ? new Date(
                        this.state.currentAllComponentState[component.key]
                      ).getFullYear() >= 1900
                        ? errorMessage.futureDateMessage
                        : errorMessage.validateyears
                      : component?.componentProps?.isHidePastDate
                        ? errorMessage.pastDateMessage
                        : errorMessage.validDate
                  : errorMessage.validDate
                : errorMessage.requiredMessage
            }
            value={this.state.currentAllComponentState[component.key]}
            handleOnChange={(newValue) => {
              if (component.onChangeCallBack) {
                component.onChangeCallBack(newValue, component.key);
              }
              this.updateDataInState(
                newValue,
                component.key,
                component?.isDate ? "date" : "",
                component.cleanUpKeys,
                null,
                null,
                component?.componentProps?.isHideFutureDate,
                component?.componentProps?.isHidePastDate
              );
            }}
          />
        );

      case formComponentTypes.CARD_WITH_TEXT_AND_CALLBACK:
        if (component.isShowAvailabilities) {
          return (
            <CardWithTextAndCallBack
              data={component}
              id={component?.id}
              parentState={{ ...this.state }}
            />
          );
        } else {
          return null;
        }
      case formComponentTypes.GOOGLE_MAP_AUTOCOMPLETE:
        {
          return (
            <SearchLocationInput
              required={component?.isMandatory}
              handleOnChange={(newValue) => {
                if (component?.onChangeCallBack) {
                    component.onChangeCallBack(newValue, component.key);
                }
                this.updateDataInState(
                  newValue,
                  component.key,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  component,
                  null,
                );

              }}
              label={component.label}
              value={this.state.currentAllComponentState[component.key]}
              openDialogMap={() => this.openDialogMap(component.key, component?.onChangeCallBack)}
              showLocationViaMaps={component.componentProps?.showLocationViaMaps}
              error={this.state.error[component.key] ? true : false}
              errorText={this.getLocationErrorMessage(component)}
              id={component?.id}
            />
          );
        }
      case formComponentTypes.BMI:
        if (!component.isDontShow) {
          return (
            <Bmi
              id={component?.id}
              type={component.componentProps.type}
              withBoldLabel={component.withBoldLabel}
              withoutUppercase={component?.withoutUppercase}
              onlyText={component?.onlyText}
              limit={component.limit}
              state={component.key}
              rowsMax={
                component.componentProps.rowsMax
                  ? component.componentProps.rowsMax
                  : 1
              }
              multiline={component.componentProps.multiline}
              title={component.label}
              placeholder={
                component.componentProps.placeholder
                  ? component.componentProps.placeholder
                  : ""
              }
              defaultValue=""
              data={component.data}
              value={component.data}
              handleOnChange={(e, limit) => {
                if (component?.onChangeCallBack) {
                  component.onChangeCallBack(e.target.value, component.key);
                }
                this.updateDataInState(
                  e.target.value,
                  component.key,
                  component?.componentProps?.type,
                  () => { },
                  limit
                );
              }}
              error={this.state.error[component.key] ? true : false}
              errorText={
                this.state.currentAllComponentState[component.key]
                  ? component?.componentProps?.type === "email"
                    ? errorMessage.emailMessage
                    : ""
                  : errorMessage.requiredMessage
              }
              disabled={component.componentProps.disabled}
            />
          );
        } else {
          return null;
        }
      case formComponentTypes.SERVICECONFIGURATION:
        return <ServiceConfiguration />;
      case formComponentTypes.FROM_TO_DATE_TIME_PICKER:
        return (
          <FromToDateTimePicker
            id={component?.id}
            fromDateLabel={
              this.state.currentAllComponentState?.[
              component?.label?.["fromDateKey"]
              ]
            } //TODO: CHANGE LABEL SOURCE
            toDateLabel={
              this.state.currentAllComponentState?.[
              component?.label?.["toDateKey"]
              ]
            } //TODO: CHANGE LABEL SOURCE
            fromDateValue={
              this.state.currentAllComponentState?.[
              component?.key?.["fromDateKey"]
              ]
            }
            toDateValue={
              this.state.currentAllComponentState?.[
              component?.key?.["toDateKey"]
              ]
            }
            onFromDateChange={(newFromDate) => {
              if (component?.componentProps?.onFromDateChange) {
                component.componentProps.onFromDateChange(
                  newFromDate,
                  component?.key?.["fromDateKey"]
                );
              }
              this.updateDataInState(
                newFromDate,
                component?.key?.["fromDateKey"]
              );
            }}
            onToDateChange={(newToDate) => {
              if (component?.componentProps?.onToDateChange) {
                component.componentProps.onToDateChange(
                  newToDate,
                  component?.key?.["toDateKey"]
                );
              }
              this.updateDataInState(newToDate, component?.key?.["toDateKey"]);
            }}
            onFromDateAccept={(newDate) => {
              if (component?.componentProps?.handleOnFromDateAccept) {
                component.componentProps.handleOnFromDateAccept(
                  newDate,
                  component?.key?.["fromDateKey"]
                );
              }
            }}
            onToDateAccept={(newDate) => {
              if (component?.componentProps?.handleOnToDateAccept) {
                component.componentProps.handleOnToDateAccept(
                  newDate,
                  component?.key?.["toDateKey"]
                );
              }
            }}
            disabled={component?.componentProps?.disabled}
            minDateTime={{ ...component?.componentProps?.minDateTime }}
            maxDateTime={{ ...component?.componentProps?.maxDateTime }}
            disableFuture={{ ...component?.componentProps?.disableFuture }}
            disablePast={{ ...component?.componentProps?.disablePast }}
            inputFormat={component?.componentProps?.inputFormat}
            mandatoryErrorFromDate={
              this.state.error[component?.key?.["fromDateKey"]] ? true : false
            }
            mandatoryErrorToDate={
              this.state.error[component?.key?.["toDateKey"]] ? true : false
            }
            fromDateParentErrorStateUpdate={(isDateError) =>
              this.updateErrorStateFromToDatePicker(
                isDateError,
                component?.key?.["fromDateKey"]
              )
            }
            toDateParentErrorStateUpdate={(isDateError) =>
              this.updateErrorStateFromToDatePicker(
                isDateError,
                component?.key?.["toDateKey"]
              )
            }
          />
        );
      case formComponentTypes.ACTIVITY_RECURRENCE:
        return (
          <ActivityRecurrence
            isEdit={component?.isEdit}
            editData={component?.editData}
            id={component?.id}
            componentProps={component?.componentProps}
            //Value Props
            values={{
              recurrenceValue: this.state.currentAllComponentState?.[
                component?.key?.["recurrence"]
              ],
              dnrValue: {
                dnrFromValue: this.state.currentAllComponentState?.[
                  component?.key?.["dnrFrom"]
                ],
                dnrToValue: this.state.currentAllComponentState?.[
                  component?.key?.["dnrTo"]
                ],
              },
              repeatOnValue: this.state.currentAllComponentState?.[
                component?.key?.["repeatOn"]
              ],

              //Weekly
              // weeklyDateRangeValue: this.state.currentAllComponentState?.[
              //   component?.key?.["weeklyDateRange"]
              // ],
              weeklyDateFromValue: this.state.currentAllComponentState?.[
                component?.key?.["weeklyDateFrom"]
              ],
              weeklyDateToValue: this.state.currentAllComponentState?.[
                component?.key?.["weeklyDateTo"]
              ],
              weeklyStartTimeValue: this.state.currentAllComponentState?.[
                component?.key?.["startTime"]
              ],
              weeklyEndTimeValue: this.state.currentAllComponentState?.[
                component?.key?.["endTime"]
              ],

              //Daily
              dailyDateFromValue: this.state.currentAllComponentState?.[
                component?.key?.["dailyDateFrom"]
              ],
              dailyDateToValue: this.state.currentAllComponentState?.[
                component?.key?.["dailyDateTo"]
              ],
              // dailyDateValue: this.state.currentAllComponentState?.[
              //   component?.key?.["dailyDate"]
              // ],
              dailyStartTimeValue: this.state.currentAllComponentState?.[
                component?.key?.["dailyStartTime"]
              ],
              dailyEndTimeValue: this.state.currentAllComponentState?.[
                component?.key?.["dailyEndTime"]
              ],
            }}
            //RECURRENCE PROPS
            recurrenceProps={{
              key: component?.key?.["recurrence"],
              label: component?.recurrenceProps?.label,
              options: component?.recurrenceProps?.options,
              error: this.state.error[component?.key?.["recurrence"]]
                ? true
                : false,
              errorText: errorMessage.requiredMessage,
            }}

            //DO NOT REPEAT PROPS
            dnrProps={{
              dnrFromLabel: component?.dnrProps?.label?.dnrFrom, //TODO: CHANGE LABEL SOURCE
              dnrToLabel: component?.dnrProps?.label?.dnrTo, //TODO: CHANGE LABEL SOURCE
              handleOnFromDateAccept:
                component?.dnrProps?.handleOnFromDateAccept,
              handleOnToDateAccept: component?.dnrProps?.handleOnToDateAccept,
              handleCancelDate: component?.dnrProps?.handleCancelDate,
              minDateTime: { ...component?.dnrProps?.minDateTime },
              disablePast: { ...component?.dnrProps?.disablePast },
              mandatoryErrorFromDate: this.state.error[
                component?.key?.["dnrFrom"]
              ]
                ? true
                : false,
              mandatoryErrorToDate: this.state.error[component?.key?.["dnrTo"]]
                ? true
                : false,
              fromDateParentErrorStateUpdate: (isDateError) =>
                this.updateErrorStateFromToDatePicker(
                  isDateError,
                  component?.key?.["dnrFrom"]
                ),
              toDateParentErrorStateUpdate: (isDateError) =>
                this.updateErrorStateFromToDatePicker(
                  isDateError,
                  component?.key?.["dnrTo"]
                ),
            }}
            //WEEKLY PROPS
            weeklyProps={{
              repeatOnProps: {
                ...component?.weeklyProps?.repeatOnProps,
                label: component?.weeklyProps?.repeatOnProps?.label,
                options: component?.weeklyProps?.repeatOnProps?.options,
                isMulti: component?.weeklyProps?.repeatOnProps?.isMulti,
                error: this.state.error[component?.key?.["repeatOn"]]
                  ? true
                  : false,
                errorText: errorMessage.requiredMessage,
              },
              dateRangeProps: {
                // label: component?.weeklyProps?.dateRangeProps?.label,
                // type: component?.weeklyProps?.dateRangeProps?.type,
                // isHidePastDate:
                //   component?.weeklyProps?.dateRangeProps?.componentProps
                //     ?.isHidePastDate,
                // isHideFutureDate:
                //   component?.weeklyProps?.dateRangeProps?.componentProps
                //     ?.isHideFutureDate,
                // error: this.state.error[component?.key?.["weeklyDateRange"]]
                //   ? true
                //   : false,
                // errorText: errorMessage.requiredMessage,

                // NEW WEEKLY COMPONENT PROPS
                weeklyFromDateLabel:
                  component?.weeklyProps?.dateRangeProps?.label?.weeklyDateFrom, //TODO: CHANGE LABEL SOURCE
                weeklyToDateLabel:
                  component?.weeklyProps?.dateRangeProps?.label?.weeklyDateTo, //TODO: CHANGE LABEL SOURCE
                handleOnFromDateAccept:
                  component?.weeklyProps?.dateRangeProps
                    ?.handleOnFromDateAccept,
                handleOnToDateAccept:
                  component?.weeklyProps?.dateRangeProps?.handleOnToDateAccept,
                minDateTime: {
                  ...component?.weeklyProps?.dateRangeProps?.minDateTime,
                },
                disablePast: {
                  ...component?.weeklyProps?.dateRangeProps?.disablePast,
                },
                mandatoryErrorFromDate: this.state.error[
                  component?.key?.["weeklyDateFrom"]
                ]
                  ? true
                  : false,
                mandatoryErrorToDate: this.state.error[
                  component?.key?.["weeklyDateTo"]
                ]
                  ? true
                  : false,
                fromDateParentErrorStateUpdate: (isDateError) =>
                  this.updateErrorStateFromToDatePicker(
                    isDateError,
                    component?.key?.["weeklyDateFrom"]
                  ),
                toDateParentErrorStateUpdate: (isDateError) =>
                  this.updateErrorStateFromToDatePicker(
                    isDateError,
                    component?.key?.["weeklyDateTo"]
                  ),
                handleFromTimeError: (isError) => {
                  this.handleTimeError(
                    component?.key?.["weeklyDateFrom"],
                    isError
                  );
                },
                handleToTimeError: (isError) => {
                  this.handleTimeError(
                    component?.key?.["weeklyDateTo"],
                    isError
                  );
                },
              },
              timeRangeProps: {
                startLabel: component?.weeklyProps?.timeRangeProps?.startLabel,
                handleStartAccept:
                  component?.weeklyProps?.timeRangeProps?.handleStartAccept,
                startMin: component?.weeklyProps?.timeRangeProps?.startMin,
                disableStartPast:
                  component?.weeklyProps?.timeRangeProps?.disableStartPast,
                reqStartError: this.state.error[component?.key?.["startTime"]]
                  ? true
                  : false,
                endLabel: component?.weeklyProps?.timeRangeProps?.endLabel,
                handleEndAccept:
                  component?.weeklyProps?.timeRangeProps?.handleEndAccept,
                endMin: component?.weeklyProps?.timeRangeProps?.endMin,
                disableEndPast:
                  component?.weeklyProps?.timeRangeProps?.disableEndPast,
                reqEndError: this.state.error[component?.key?.["endTime"]]
                  ? true
                  : false,
              },
            }}
            //DAILY PROPS
            dailyProps={{
              dateProps: {
                dailyFromDateLabel:
                  component?.dailyProps?.dateProps?.label?.dailyDateFrom, //TODO: CHANGE LABEL SOURCE
                dailyToDateLabel:
                  component?.dailyProps?.dateProps?.label?.dailyDateTo, //TODO: CHANGE LABEL SOURCE
                handleOnFromDateAccept:
                  component?.dailyProps?.dateProps?.handleOnFromDateAccept,
                handleOnToDateAccept:
                  component?.dailyProps?.dateProps?.handleOnToDateAccept,
                minDateTime: {
                  ...component?.dailyProps?.dateProps?.minDateTime,
                },
                disablePast: {
                  ...component?.dailyProps?.dateProps?.disablePast,
                },
                mandatoryErrorFromDate: this.state.error[
                  component?.key?.["dailyDateFrom"]
                ]
                  ? true
                  : false,
                mandatoryErrorToDate: this.state.error[
                  component?.key?.["dailyDateTo"]
                ]
                  ? true
                  : false,
                fromDateParentErrorStateUpdate: (isDateError) =>
                  this.updateErrorStateFromToDatePicker(
                    isDateError,
                    component?.key?.["dailyDateFrom"]
                  ),
                toDateParentErrorStateUpdate: (isDateError) =>
                  this.updateErrorStateFromToDatePicker(
                    isDateError,
                    component?.key?.["dailyDateTo"]
                  ),
                handleFromTimeError: (isError) => {
                  this.handleTimeError(
                    component?.key?.["dailyDateFrom"],
                    isError
                  );
                },
                handleToTimeError: (isError) => {
                  this.handleTimeError(
                    component?.key?.["dailyDateTo"],
                    isError
                  );
                },
              },
              timeRangeProps: {
                startLabel: component?.dailyProps?.timeRangeProps?.startLabel,
                handleStartAccept:
                  component?.dailyProps?.timeRangeProps?.handleStartAccept,
                startMin: component?.dailyProps?.timeRangeProps?.startMin,
                disableStartPast:
                  component?.dailyProps?.timeRangeProps?.disableStartPast,
                reqStartError: this.state.error[
                  component?.key?.["dailyStartTime"]
                ]
                  ? true
                  : false,
                endLabel: component?.dailyProps?.timeRangeProps?.endLabel,
                handleEndAccept:
                  component?.dailyProps?.timeRangeProps?.handleEndAccept,
                endMin: component?.dailyProps?.timeRangeProps?.endMin,
                disableEndPast:
                  component?.dailyProps?.timeRangeProps?.disableEndPast,
                reqEndError: this.state.error[component?.key?.["dailyEndTime"]]
                  ? true
                  : false,
                fromDateParentErrorStateUpdate: (isDateError) =>
                  this.updateErrorStateFromToDatePicker(
                    isDateError,
                    component?.key?.["dailyStartTime"]
                  ),
                toDateParentErrorStateUpdate: (isDateError) =>
                  this.updateErrorStateFromToDatePicker(
                    isDateError,
                    component?.key?.["dailyEndTime"]
                  ),
              },
            }}
            //ON CHANGE PROPS
            onChanges={{
              handleRecurrenceChange: (selectedRecurrence, key) => {
                this.updateDataInState(
                  selectedRecurrence,
                  key,
                  null,
                  component?.recurrenceProps?.cleanUpKeys,
                  null,
                  null,
                  null,
                  null,
                  null,
                  component
                );
                // cleanUpKeys
              },

              // DO NOT REPEAT
              handleDnrFromDateChange: (newFromDate) => {
                if (component?.dnrProps?.onFromDateChange) {
                  component.dnrProps.onFromDateChange(new Date(newFromDate));
                }
                this.updateDataInState(
                  newFromDate,
                  component?.key?.["dnrFrom"]
                );
              },
              handleDnrToDateChange: (newToDate) => {
                if (component?.dnrProps?.onToDateChange) {
                  component.dnrProps.onToDateChange(new Date(newToDate));
                }
                this.updateDataInState(newToDate, component?.key?.["dnrTo"]);
              },

              //WEEKLY
              handleWeeklyRepeatOnChange: (value) => {
                this.updateDataInState(
                  value,
                  component?.key?.["repeatOn"],
                  component?.weeklyProps?.repeatOnProps?.type,
                  component?.weeklyProps?.repeatOnProps?.cleanUpKeys //Cleanupkeys
                );
              },
              // handleWeeklyDateRangeChange: (value) => {
              //   ;
              //   this.updateDataInState(
              //     value,
              //     component?.key?.["weeklyDateRange"],
              //     component?.weeklyProps?.dateRangeProps?.type
              //   );
              // },
              handleWeeklyFromDateChange: (newFromDate) => {
                if (component?.weeklyProps?.dateProps?.onFromDateChange) {
                  component.weeklyProps.dateProps.onFromDateChange(
                    new Date(newFromDate)
                  );
                }
                this.updateDataInState(
                  newFromDate,
                  component?.key?.["weeklyDateFrom"]
                );
              },
              handleWeeklyToDateChange: (newToDate) => {
                if (component?.weeklyProps?.dateProps?.onToDateChange) {
                  component.weeklyProps.dateProps.onToDateChange(
                    new Date(newToDate)
                  );
                }
                this.updateDataInState(
                  newToDate,
                  component?.key?.["weeklyDateTo"]
                );
              },
              weeklyStartChange: (newStartTime) => {
                if (component?.weeklyProps?.timeRangeProps?.startChange) {
                  component.weeklyProps.timeRangeProps.startChange(
                    new Date(newStartTime)
                  );
                }
                this.updateDataInState(
                  newStartTime,
                  component?.key?.["startTime"]
                );
              },
              weeklyEndChange: (newEndTime) => {
                if (component?.weeklyProps?.timeRangeProps?.endChange) {
                  component.weeklyProps.timeRangeProps.endChange(
                    new Date(newEndTime)
                  );
                }
                this.updateDataInState(newEndTime, component?.key?.["endTime"]);
              },

              //DAILY
              handleDailyFromDateChange: (newFromDate) => {
                if (component?.dailyProps?.dateProps?.onFromDateChange) {
                  component.dailyProps.dateProps.onFromDateChange(
                    new Date(newFromDate)
                  );
                }
                this.updateDataInState(
                  newFromDate,
                  component?.key?.["dailyDateFrom"]
                );
              },
              handleDailyToDateChange: (newToDate) => {
                if (component?.dailyProps?.dateProps?.onToDateChange) {
                  component.dailyProps.dateProps.onToDateChange(
                    new Date(newToDate)
                  );
                }
                this.updateDataInState(
                  newToDate,
                  component?.key?.["dailyDateTo"]
                );
              },

              // dailyDateChange: (newValue) => {
              //   if (component?.dailyProps?.dateProps?.onChange) {
              //     component.dailyProps.dateProps.onChange(new Date(newValue));
              //   }
              //   this.updateDataInState(
              //     newValue,
              //     component?.key?.["dailyDate"],
              //     component?.dailyProps?.dateProps?.isDate ? "date" : "",
              //     component?.dailyProps?.dateProps?.cleanUpKeys,
              //     null,
              //     null,
              //     component?.dailyProps?.dateProps?.hideFuture,
              //     component?.dailyProps?.dateProps?.hidePast
              //   );
              // },
              dailyStartChange: (newStartTime) => {
                if (component?.dailyProps?.timeRangeProps?.startChange) {
                  component.dailyProps.timeRangeProps.startChange(
                    new Date(newStartTime)
                  );
                }
                this.updateDataInState(
                  newStartTime,
                  component?.key?.["dailyStartTime"]
                );
              },
              dailyEndChange: (newEndTime) => {
                if (component?.dailyProps?.timeRangeProps?.endChange) {
                  component.dailyProps.timeRangeProps.endChange(
                    new Date(newEndTime)
                  );
                }
                this.updateDataInState(
                  newEndTime,
                  component?.key?.["dailyEndTime"]
                );
              },
            }}
          />
        );

      case formComponentTypes.ACTIVITY_MODE:
        console.log(component)
        return (
          <ActivityMode
          isEdit={component.isEdit}
          isReschedule={component?.isReschedule}
          setDisable={component?.setDisable}
            activityModeProps={{
              key: component?.key?.["activityMode"],
              id: component?.activityModeProps?.id,
              label: component?.activityModeProps?.label,
              value: this.state.currentAllComponentState?.[
                component?.key?.["activityMode"]
              ],
              options: component?.activityModeProps?.options,
              handleActivityModeChange: (selectedMode, key) => {
                this.updateDataInState(
                  selectedMode,
                  key,
                  null,
                  component?.activityModeProps?.cleanUpKeys
                );
              },
              error: this.state.error[component?.key?.["activityMode"]]
                ? true
                : false,
              errorText: errorMessage.requiredMessage,
            }}
            meetingProps={{
              id: component?.meetingProps?.id,
              type: component?.meetingProps?.componentProps?.type,
              limit: component?.meetingProps?.componentProps?.limit,
              state: component?.key?.["meetingLink"],
              title: component?.meetingProps?.label,
              placeholder: component?.meetingProps?.placeholder,
              value: this.state.currentAllComponentState?.[
                component?.key?.["meetingLink"]
              ],
              handleOnChange: (e, limit) => {
                this.updateDataInState(
                  e.target.value,
                  component?.key?.["meetingLink"],
                  component?.meetingProps?.componentProps?.type,
                  () => { },
                  limit
                );
              },
              error: this.state.error[component?.key?.["meetingLink"]]
                ? true
                : false,
              errorText: errorMessage.requiredMessage,
            }}
            locationProps={{
              id: component?.locationProps?.id,
              label: component?.locationProps?.label,
              value: this.state.currentAllComponentState?.[
                component?.key?.["location"]
              ],
              handleOnChange: (newValue) => {
                this.updateDataInState(newValue, component?.key?.["location"]);
              },
              openDialogMap: () => this.openDialogMap(component?.key?.["location"]),
              showLocationViaMaps:
                component?.locationProps?.componentProps?.showLocationViaMaps,
              error: this.state.error[component.key?.["location"]]
                ? true
                : false,
              errorText: this.getLocationErrorMessage(
                null,
                component.key?.["location"]
              ),
            }}
            venueProps={{
              key: component?.key?.["venue"],
              label: component?.venueProps?.label,
              id: component?.venueProps?.id,
              value: this.state.currentAllComponentState?.[
                component?.key?.["venue"]
              ],
              isAnyClientSelected: this.state.currentAllComponentState?.["cliendId"] !== null ? true : false,
              options: component?.venueProps?.options,
              handleVenueChange: (selectedMode, key) => {
                this.updateDataInState(
                  selectedMode,
                  key,
                  null,
                  // component?.activityModeProps?.cleanUpKeys
                );
              },
              error: this.state.error[component?.key?.["venue"]]
                ? true
                : false,
              errorText: errorMessage.requiredMessage,
            }}
          />
        );

      case formComponentTypes.SWITCH_COMPONENT:
        return (
          <SwitchComponent
            data={component}
            id={component?.id}
            checked={this.state.currentAllComponentState[component.key]}
            //  handleOnChange={component.handleChange}
            handleOnChange={(newValue) => {
              component.onChangeCallBack(
                newValue.target.checked,
                component.key
              );
              this.updateDataInState(newValue.target.checked, component.key);
            }}
            label={component.label}
            fixMargin={component?.componentProps?.fixMargin}
            marginLeft={component?.componentProps?.marginLeft}
          />
        );

      case formComponentTypes.PRODUCT_VALIDITY:
        //
        // console.log(component)
        return (
          <ProductValidity

            isEdit={component?.componentProps?.isEdit}
            // id={component?.id}
            dropdownProps={{
              id: component?.id?.validity_id,
              options: component?.componentProps?.dropdownProps?.options,
              label: component?.componentProps?.dropdownProps?.label,
              value: this.state.currentAllComponentState?.[
                component?.key?.["productValidity"]
              ],
              onChange: (selected) => {
                if (component?.componentProps?.dropdownProps?.callback) {
                  component.componentProps.dropdownProps.callback(selected);
                }
                this.updateDataInState(
                  selected,
                  component?.key?.["productValidity"]
                );
              },
              error: this.state.error[component?.key?.["productValidity"]]
                ? true
                : false,
            }}
            // checkboxProps={{
            //   label: component?.componentProps?.checkboxProps?.label,
            //   value: component?.componentProps?.checkboxProps?.value,
            //   error: component?.componentProps?.checkboxProps?.error,
            // }}
            startDateProps={{
              id: component?.id?.effectiveFrom_id,
              label: component?.componentProps?.startDateProps?.label,
              value: this.state.currentAllComponentState?.[
                component?.key?.["startDate"]
              ],
              onChange: (selectedDate) => {
                if (component?.componentProps?.startDateProps?.callback) {
                  component.componentProps.startDateProps.callback(
                    selectedDate
                  );
                }
                this.updateDataInState(
                  selectedDate,
                  component?.key?.["startDate"]
                );
              },
              min: component?.componentProps?.startDateProps?.min,
              max: component?.componentProps?.startDateProps?.max,
              disablePast:
                component?.componentProps?.startDateProps?.disablePast,
              disableFuture:
                component?.componentProps?.startDateProps?.disableFuture,
              inputFormat:
                component?.componentProps?.startDateProps?.inputFormat,
              error: this.state.error[component?.key?.["startDate"]]
                ? true
                : false,
              fromDateParentErrorStateUpdate: (isDateError) =>
                this.updateErrorStateFromToDatePicker(
                  isDateError,
                  component?.key?.["startDate"]
                ),
            }}
            endDateProps={{
              label: component?.componentProps?.endDateProps?.label,
              id: component?.id?.end_id,
              value: this.state.currentAllComponentState?.[
                component?.key?.["endDate"]
              ],
              onChange: (selectedDate) => {
                if (component?.componentProps?.endDateProps?.callback) {
                  component.componentProps.endDateProps.callback(selectedDate);
                }
                this.updateDataInState(
                  selectedDate,
                  component?.key?.["endDate"]
                );
              },
              min: component?.componentProps?.endDateProps?.min,
              max: component?.componentProps?.endDateProps?.max,
              disablePast: component?.componentProps?.endDateProps?.disablePast,
              disableFuture:
                component?.componentProps?.endDateProps?.disableFuture,
              inputFormat: component?.componentProps?.endDateProps?.inputFormat,
              error: this.state.error[component?.key?.["endDate"]]
                ? true
                : false,
              toDateParentErrorStateUpdate: (isDateError) =>
                this.updateErrorStateFromToDatePicker(
                  isDateError,
                  component?.key?.["endDate"]
                ),
            }}
          ></ProductValidity>
        );

      case formComponentTypes.RESOURCE_VALIDITY:
        return (
          <ResourceValidity

            isEdit={component?.componentProps?.isEdit}
            startDateProps={{
              id: component?.id?.effectiveFrom_id,
              label: component?.componentProps?.startDateProps?.label,
              value: this.state.currentAllComponentState?.[
                component?.key?.["startDate"]
              ],
              onChange: (selectedDate) => {
                if (component?.componentProps?.startDateProps?.callback) {
                  component.componentProps.startDateProps.callback(
                    selectedDate,
                    component?.key?.["startDate"]
                  );
                }
                this.updateDataInState(
                  selectedDate,
                  component?.key?.["startDate"]
                );
              },
              min: component?.componentProps?.startDateProps?.min,
              max: component?.componentProps?.startDateProps?.max,
              disablePast:
                component?.componentProps?.startDateProps?.disablePast,
              disableFuture:
                component?.componentProps?.startDateProps?.disableFuture,
              inputFormat:
                component?.componentProps?.startDateProps?.inputFormat,
              error: this.state.error[component?.key?.["startDate"]]
                ? true
                : false,
              fromDateParentErrorStateUpdate: (isDateError) =>
                this.updateErrorStateFromToDatePicker(
                  isDateError,
                  component?.key?.["startDate"]
                ),
            }}
            endDateProps={{
              label: component?.componentProps?.endDateProps?.label,
              id: component?.id?.end_id,
              value: this.state.currentAllComponentState?.[
                component?.key?.["endDate"]
              ],
              onChange: (selectedDate) => {
                if (component?.componentProps?.endDateProps?.callback) {
                  component.componentProps.endDateProps.callback(selectedDate,
                    component?.key?.["endDate"]);
                }
                this.updateDataInState(
                  selectedDate,
                  component?.key?.["endDate"]
                );
              },
              min: component?.componentProps?.endDateProps?.min,
              max: component?.componentProps?.endDateProps?.max,
              disablePast: component?.componentProps?.endDateProps?.disablePast,
              disableFuture:
                component?.componentProps?.endDateProps?.disableFuture,
              inputFormat: component?.componentProps?.endDateProps?.inputFormat,
              error: this.state.error[component?.key?.["endDate"]]
                ? true
                : false,
              toDateParentErrorStateUpdate: (isDateError) =>
                this.updateErrorStateFromToDatePicker(
                  isDateError,
                  component?.key?.["endDate"]
                ),
            }}
          ></ResourceValidity>
        );

        case formComponentTypes.SELECT_COMPONENT_WITH_SUB_COMPONENT:
        return (
          <SelectComponentWithSubComponent
            type="address"
            data={[1, 2]}
            error={[]}
          />
        );

      case formComponentTypes.ADD_ADDRESS:
        return (
          <ModernViewAddressCardDetail
            id={component?.id}
            callBackOnSave={component.componentProps.callBackOnSave}
            editData={component.componentProps.editData}
            handledrawer={component.componentProps.handledrawer}
            error={component.componentProps.error}
            errorText={component.componentProps.errorText}
            required={component?.required}
          />
        );

      case formComponentTypes.ADD_ID_TYPE:
        return (
          <ModernViewIdCardDetail
            id={component?.id}
            callBackOnSave={component.componentProps.callBackOnSave}
            editData={component.componentProps.editData}
            handledrawer={component.componentProps.handledrawer}
            error={component.componentProps.error}
            errorText={component.componentProps.errorText}
            // required={component?.required}
          />
        );

      case formComponentTypes.ADD_EMAIL:
        return (
          <ModernViewEmailCardDetail
            id={component?.id}
            callBackOnSave={component.componentProps.callBackOnSave}
            editData={component.componentProps.editData}
            handledrawer={component.componentProps.handledrawer}
          />
        );
      case formComponentTypes.ADD_PHONE_NUMBER:
        return (
          <ModernViewMobileCardDetail
            id={component?.id}
            callBackOnSave={component.componentProps.callBackOnSave}
            editData={component.componentProps.editData}
            handledrawer={component.componentProps.handledrawer}
            error={component.componentProps.error}
            errorText={component.componentProps.errorText}
            required={component?.required}
          />
        );

      case formComponentTypes.FAMILY_MEMBERS:
        return (
          <FamilyMembers
            onAddClick={component.componentProps.onAddClick}
            handleSave={component.componentProps.handleSave}
            editData={component.componentProps.editData}
            updateState={component.componentProps.updateState}
            updateFamilyMembers={component.componentProps.updateFamilyMembers}
            updateParentMember={component.componentProps.updateParentMember}
            // Back
            goBackHandler={component.componentProps.onFamilyMemberAddClick}
            // Id
            callBackOnSaveId={
              component.componentProps.callBackOnSaveId
            }
            onAddEditDrawerId={
              component.componentProps.onAddEditDrawerId
            }
            // Phone
            callBackOnSavePhone={
              component.componentProps.callBackOnSavePhone
            }
            onAddEditDrawerPhone={
              component.componentProps.onAddEditDrawerPhone
            }
            // Address
            callBackOnSaveAddress={
              component.componentProps.callBackOnSaveAddress
            }
            onAddEditDrawerAddress={
              component.componentProps.onAddEditDrawerAddress
            }
          />
        );

      // case formComponentTypes.DURATION:
      //   return (
      //     <DurationComponent
      //       primaryLabel={component?.primaryLabel}
      //       secondaryLabel={component?.secondaryLabel}
      //       hourValue={
      //         this.state.currentAllComponentState?.[component?.key?.["hour"]]
      //       }
      //       minuteValue={
      //         this.state.currentAllComponentState?.[component?.key?.["minute"]]
      //       }
      //       handleOnHoursChange={(selectedHour) => {
      //         this.updateDataInState(selectedHour, component?.key?.["hour"]);
      //       }}
      //       handleOnMinutesChange={(selectedMinute) => {
      //         this.updateDataInState(
      //           selectedMinute,
      //           component?.key?.["minute"]
      //         );
      //       }}
      //       errorHour={this.state.error?.[component?.key?.["hour"]]}
      //       errorMinute={this.state.error?.[component?.key?.["minute"]]}
      //       errorMessage={
      //         this.state.error?.[component?.key?.["hour"]] &&
      //           this.state.error?.[component?.key?.["minute"]]
      //           ? errorMessage.requiredMessage
      //           : this.state.error?.[component?.key?.["hour"]]
      //             ? errorMessage.hourRequired
      //             : errorMessage.minuteRequired
      //       }
      //     />
      //   );

      case formComponentTypes.FILEUPLOADER:
        return (
          <FileUploader
            label={component.label}
            id={component?.id}
            handleFile={(value)=>{

              if(component.handleFileUpload){
                component.handleFileUpload(value)
              }
              this.updateDataInState(
                value,
                component?.key
              );
            }}
            handleRemove={()=>{

              if(component.handleFileRemove){
                component.handleFileRemove()
              }
              this.updateDataInState(
                null,
                component?.key
              );
            }}
            value={this.state.currentAllComponentState[component.key]}
            customExtensions={component?.componentProps?.customExtensions}
          />
        );

      case formComponentTypes.FILEUPLOADER_VARIANT_2:
        return (
          <FileUploaderVariant
            label={component.label}
            handleFile={(value)=>{

              if(component.handleFileUpload){
                component.handleFileUpload(value)
              }
              this.updateDataInState(
                value,
                component?.key
              );
            }}
            handleRemove={()=>{

              if(component.handleFileRemove){
                component.handleFileRemove()
              }
              this.updateDataInState(
                null,
                component?.key
              );
            }}
            value={this.state.currentAllComponentState[component.key]}

            fileExtension={component?.componentProps?.fileExtension}

            customExtensions={component?.componentProps?.customExtensions}
            file={component?.componentProps?.file}
            fileUrl={component?.componentProps?.fileUrl}

          />
        );

      case formComponentTypes.UPLOADER_WITH_PREVIEW:
        return (
          <UploaderWithPreview
          label={component.label}
          handleFile={(value)=>{

            if(component.handleFileUpload){
              component.handleFileUpload(value)
            }
            this.updateDataInState(
              value,
              component?.key
            );
          }}
          handleRemove={()=>{

            if(component.handleFileRemove){
              component.handleFileRemove()
            }
            this.updateDataInState(
              null,
              component?.key
            );
          }}
          value={this.state.currentAllComponentState[component.key]}
          customExtensions={component?.componentProps?.customExtensions}
          // file={component?.componentProps?.file}
          // fileUrl={component?.componentProps?.fileUrl}
          idData={component?.componentProps?.idData}
          />
        )

      case formComponentTypes.SWITCHBOXDROPDOWN:
        return (
          <SwitchBoxWithDropDown
            id={component?.id?.check_id}
            onChange={(value, key, tempeleteTypeId, onChangeCallBack) => {
              if (component?.onChangeCallBack) {
                onChangeCallBack(value, tempeleteTypeId);
              }
              this.updateDataInStateWithErrorHandler(
                value,
                component?.key,
                key,
                component
                // errorKeys
              );
            }}
            hideMarginTop={component?.componentProps?.hideMarginTop}
            // error={}
            onChangeCallBackDropDown={component?.onChangeCallBackDropDown}
            onChangeCallBack={component?.onChangeCallBack}
            tempeleteTypeId={component?.tempeleteTypeId}
            isChecked={
              this.state.currentAllComponentState[component.key]?.isChecked
            }
            dropdown1Label={component?.dropdown1Label}
            dropdown1Key={component?.id?.dropdown1Key}
            dropdown2Key={component?.id?.dropdown2Key}
            dropdown2Label={component?.dropdown2Label}
            dropdown1Options={component?.dropdown1Options}
            dropdown2Options={component?.dropdown2Options}
            dropdown1Value={
              this.state.currentAllComponentState[component.key]?.dropdown1Value
            }
            dropdown2Value={
              this.state.currentAllComponentState[component.key]?.dropdown2Value
            }
            label={component?.label}
            componentKey={component?.key}
            isMulti={component?.componentProps?.isMulti}
            error={this.state.error?.[component.key]}
          />
        );

      case formComponentTypes.RICHTEXTEDITOR:
        console.log(component?.id)
        return (
          <RichTextEditor
            id={component?.id}
            isReadOnly={component?.isReadOnly}
            value={component.value}
            label={component.label}
            keys={component?.key}
            labelSize={component.labelSize}
            saveOnBlur={(value) => {
              if(component?.onChangeCallBack){
                component.onChangeCallBack(value, component?.key)
              }
              this.updateDataInState(value, component?.key)
            }}
            {...component?.componentProps}
          />
        );

      case formComponentTypes.DURATION_PICKER:
      return (
        <DurationPicker
          id={component?.id}
          label={component?.label}
          value={component?.value}
          handleOnSave={(value) => {
            this.updateDataInState(value, component.key);
          }}
          required={component?.isMandatory}
          error={this.state.error?.[component?.key]}
          errorText={this.state.error?.[component?.key] ? errorMessage.newRequiredMessage : ``}
        />
      );

      case "CUSTOM":
        console.log(component)
        return (
          <>
          <ActivityAndMembersCard
                    hideProfilePicture
                    title={component?.value?.activity_name}
                    // date={moment(data?.from_time_stamp).format('dddd, MMMM D')}
                    timing={
                      component?.value?.from_time_stamp && component?.value?.to_time_stamp
                        ? `${moment(component?.value?.from_time_stamp).format(
                            "Do MMM hh:mm A"
                          )} - ${moment(component?.value?.to_time_stamp).format(
                            "Do MMM hh:mm A"
                          )}`
                        : undefined
                    }
                    hideIsActive
                    activityStatus={component?.value?.activity_type?.label} //activity_type will be a object with {value, label}
                    activityStatusColor="#28a9c1"
                    currentStatus={component?.value?.activity_status}
                    currentStatusColor={colorCode[component?.value?.activity_status]}
                    // useActivityAsSummaryCard
                    hideEndIcon
                    padding={2}
                />

                <summaryTextCard
                    description={component?.value?.activity_description}
                    padding={2}
                />
                <></>
              </>
        )

      case formComponentTypes.EFFECTIVE_EXPIRY : return (
        <EffectiveDateExpiryDate
          effective={formState?.[component?.key?.["effective"]]}
          noExpiry={formState?.[component?.key?.["noExpiry"]]}
          expiry={formState?.[component?.key?.["expiry"]]}
          handleChange={this.handleEffectiveExpiryChange}
          effectiveError={{
            status: formError?.[component?.key?.["effective"]],
            text: errorText?.[component?.key?.["effective"]],
          }}
          expiryError={{
            status: formError?.[component?.key?.["expiry"]],
            text: errorText?.[component?.key?.["expiry"]],
          }}
          keys={{
            effective: component?.key?.["effective"],
            noExpiry: component?.key?.["noExpiry"],
            expiry: component?.key?.["expiry"]
          }}
        />
      )

      default:
        return <></>;
    }
  };

  handleEffectiveExpiryChange = (value, key) => {
    const { currentAllComponentState, error } = this.state

    currentAllComponentState[key] = value
    error[key] = false

    this.setState({
      currentAllComponentState,
      error
    })
  }


  // REMOVE A ITEM FROM A ARRAY
  removeItemFromAnArrayCommonFunction = (
    arrayOfItems,
    removeItem,
    state,
    deleteUsingID
  ) => {
    const { currentAllComponentState } = this.state;

    let filteredArray;

    // This if condition checks if it wants to delete an item using its index.
    if (deleteUsingID) {
      const tempArray = [...arrayOfItems];
      tempArray.splice(removeItem, 1);
      filteredArray = [...tempArray];
    } else {
      // This else condition checks if it wants to delete an item using its value.
      filteredArray = arrayOfItems.filter((val) => {
        return val !== removeItem;
      });
    }

    currentAllComponentState[state] = filteredArray;

    this.setState({ ...this.state, isBlock: true });
  };

  cancelForm = () => {
    if (this.props.cancelForm) {
      this.props.cancelForm();
    } else {
      if (this.props.open) {
        this.props.toggleDrawer(false, {});
      } else {
        this.props.history.goBack();
      }
    }
  };

  // shouldComponentUpdate(nextProps, nextState) {

  //   const { isResetState } = nextProps;
  //   const oldIsResetState = this.props.isResetState;
  //   if (isResetState !== oldIsResetState) {
  //     debugger
  //     let { initialState } = setInitialState(nextProps);
  //    /** Dependent Field Update (Activity Mode in Add Activity) */
  //     // console.log(this.state.currentAllComponentState)
  //     let { currentAllComponentState: stateValues } = this.state
  //     let final_obj = {}
  //     let state_obj = Object.keys(stateValues)?.map((x) => {
  //       debugger
  //       if(stateValues[x])
  //         final_obj={...final_obj, [x]: stateValues[x]}
  //       return x
  //     })
  //     console.log(state_obj)
  //     let finalState = { ...initialState, ...final_obj }
  //     this.setState({
  //       currentAllComponentState: {
  //         // validity_period: [null, null],
  //         // ...initialState,
  //         ...finalState
  //       },
  //     });
  //   }
  //   return true;
  // }
  shouldComponentUpdate(nextProps, nextState) {
    const { isResetState } = nextProps;
    const oldIsResetState = this.props.isResetState;
    if (isResetState !== oldIsResetState) {
      debugger

      let { initialState } = setInitialState(nextProps);
      this.setState({
        currentAllComponentState: {
          // validity_period: [null, null],
          ...initialState,
        },
      });
    }
    return true;
  }
  // FROM TO DATE PICKER COMPONENT CALLBACKS
  updateErrorStateFromToDatePicker = (isDateError, key) => {
    const { error } = this.state;
    let isErrorOccured = this.state.isErrorOccured;
    if (isDateError) {
      error[key] = true;
      isErrorOccured = true;
    } else {
      error[key] = false;
      isErrorOccured = false;
    }
    this.setState({
      error: error,
      isBlock: isErrorOccured,
      isErrorOccured: isErrorOccured,
    });
  };
  /**--------------------------------- */

  /**------------------------------------ */
  handleTimeError = (key, isError) => {

    let { timeDifferenceError } = this.state;
    timeDifferenceError = {
      [key]: isError,
    };
    this.setState({
      timeDifferenceError: timeDifferenceError,
    });
  };

  render() {
    const { classes, loading, disabled, backNeeded=false, backHandler=() => {} } = this.props;

    const { components } = this.props;

    return (
      <>
        <Paper className={classes.root}>
          {Object.keys(components).map((sections, index) => {
            const labelBreakPoints = components?.[sections]?.labelBreakPoints
              ? { ...components?.[sections]?.labelBreakPoints }
              : { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 };
            return (
              <div
                key={index}
                style={{
                  display: components?.[sections]?.isDontShow
                    ? "none"
                    : "block",
                }}
              >
                <Grid
                  container
                  alignItems={"flex-start"}
                  className={`${!this.props.fixPadding ? classes.container : ""
                    } ${classes.fixWidth}`}
                  direction="row"
                  spacing={`${this.props.spacing !== undefined
                    ? this.props.spacing
                    : this.props.fixPadding
                      ? 1
                      : 3
                    }`}
                >
                  {components?.[sections]?.label && (
                    <Grid container item xs={12}>
                      <Grid item {...labelBreakPoints}>
                        <div style={{display: "flex", gap: 1, alignItems: "Center"}}>
                          {backNeeded && (
                            <IconButton size="small" onClick={backHandler}>
                              <ChevronLeftIcon />
                            </IconButton>
                          )}
                        <Typography
                          variant="h6"
                          component="h6"
                          style={{marginBottom: `${this.props.sectionGutterBottom ? this.props.sectionGutterBottom+"px" : 0}`, flex: 1 }}
                        >
                          {components[sections].label}
                        </Typography>
                        </div>
                      </Grid>
                      {components?.[sections]?.withHeaderContent && (
                        <Grid
                          container
                          item
                          xs={6}
                          className={classes.switch}
                          alignItems="center"
                        >
                          <Grid item xs={10}>
                            <Typography
                              variant="subtitle2"
                              component="div"
                              className={classes.switchLabel}
                            >
                              {components?.[sections]?.headerContentText
                                ? components?.[sections]?.headerContentText
                                : "Is the caller and contact are the same?"}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Switch
                              disableRipple
                              id={"isCallerContactSameSwitchFromAddEditEnquiry"}
                              onChange={(e) => {
                                if (components?.[sections]?.cleanUpKeys) {
                                  this.updateDataInState(
                                    e.target.checked,
                                    null,
                                    null,
                                    components?.[sections]?.cleanUpKeys,
                                    null,
                                    null,
                                    null,
                                    null,
                                    null,
                                    null,
                                    components?.[sections]?.setkeyValue
                                  );
                                }
                                if (this.props?.handleSwithIconToggle)
                                  this.props.handleSwithIconToggle(e);
                              }}
                              color="secondary"
                              inputProps={{
                                "aria-label": "checkbox with default color",
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {Object.values(components[sections].fields)?.map(
                    (component) => {
                      let componentBreakpoints = component.breakPoints
                      if(component?.type === formComponentTypes.SWITCHBOXDROPDOWN){
                        if(!this.state.currentAllComponentState[component?.key]?.isChecked){
                          componentBreakpoints = {
                            xs: 12,
                            sm: 4,
                            md: 4,
                            lg: 4,
                            xl: 4,
                          };
                        }
                      }

                      if (!component?.isDontShow) {
                        return (
                          <Grid
                            item
                            {...componentBreakpoints}
                            // adjust here - isChecked
                            style={{alignSelf: "stretch", ...component?.componentProps?.style}}
                          // className={this.props.fullWidth ? classes.item : ""}
                          >
                            {this.giveMeTheComponent(component)}
                          </Grid>
                        );
                      } else {
                        return <></>;
                      }
                    }
                  )}
                </Grid>
                {Object.keys(components).length - 1 > index && (
                  <Divider className={classes.divider} />
                )}
              </div>
            );
          })}

          {!this.props.isCancelSaveButtonsNeeded && (
            <div className={classes.saveBtnContainer}>
              {/* {
            !this.state.openDrawer.open && */}
              <Button
                variant={"text"}
                color={"primary"}
                onClick={this.cancelForm}
                style={
                  this.props.cancelButtonStyles
                    ? { marginRight: 12, ...this.props.cancelButtonStyles }
                    : { marginRight: 12 }
                }
                id={this.props?.cancelButtonId}
              >
                {"Cancel"}
              </Button>
              {/* } */}
              <Button
                variant={"contained"}
                color={"primary"}
                className={classes.saveBtn}
                style={
                  this.props.saveButtonStyles
                    ? {
                      margin: 16,
                      padding: "5px 40px",
                      ...this.props.saveButtonStyles,
                    }
                    : { margin: 16, padding: "5px 40px" }
                }
                disabled={loading || disabled}
                id={this.props?.saveButtonId}
                onClick={this.onSaveButtonClicked}
              >
                {this.props.saveButtonLabel
                  ? this.props.saveButtonLabel
                  : "Save"}
              </Button>
            </div>
          )}
          {this.props.isShowOnly && (
            <div className={classes.saveBtnContainer} >
              <Button
                variant={"contained"}
                color={"primary"}
                className={classes.saveBtn}
                disabled={loading || disabled}
                id={this.props?.saveButtonId}
                onClick={this.onSaveButtonClicked}
                style={{marginRight: this.props.saveButtonGutterRight ? this.props.saveButtonGutterRight : 0}}
              >
                {this.props.saveButtonLabel
                  ? this.props.saveButtonLabel
                  : "Save"}
              </Button>
            </div>
          )}
        </Paper>
        {/* <DrawerComponent
          open={this.state.openDrawer.open}

          component={this.state.openDrawer.component}
          callback={this.state.openDrawer.callback}
          toggleDrawer={this.toggleDrawer}
        /> */}
        <Prompt
          when={this.state.isBlock}
          message={(location) => errorMessage.promptMessage}
        />
      </>
    );
  }
}

AddEditProductManagement.propTypes = {
  components: PropTypes.object,
};

AddEditProductManagement.defaultProps = {
  components: {},
};

export default withRouter(
  withAlertAndDialog(withStyles(style)(AddEditProductManagement))
);
