import * as React from "react";
import { SvgIcon } from "@material-ui/core";

export const StarIcon = (props) => {

    const {
        color = "#3b3f5b"
    } = props;

    return (
        <SvgIcon
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="Group_95504" data-name="Group 95504" transform="translate(-1071 -414)">
                <g id="Component_92_2" data-name="Component 92 – 2" transform="translate(1071 414)">
                    <g id="Rectangle_48008" data-name="Rectangle 48008" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                        <rect width="24" height="24" stroke="none" />
                        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                    </g>
                    <path id="icons8-star_1_" data-name="icons8-star (1)" d="M13.522,5a.714.714,0,0,0-.643.393l-2.452,4.881-5.822.892a.71.71,0,0,0-.4,1.2L8.236,16.38l-.9,5.8a.709.709,0,0,0,.29.686.717.717,0,0,0,.746.053l5.142-2.671,5.142,2.671a.717.717,0,0,0,.746-.053.709.709,0,0,0,.29-.686l-.9-5.8,4.027-4.009a.71.71,0,0,0-.4-1.2l-5.822-.892L14.156,5.393A.714.714,0,0,0,13.522,5Zm0,2.3L15.5,11.238a.714.714,0,0,0,.53.385l4.794.735-3.286,3.271a.709.709,0,0,0-.2.611l.737,4.77-4.222-2.193a.717.717,0,0,0-.66,0L8.965,21.01,9.7,16.24a.709.709,0,0,0-.2-.611L6.215,12.358l4.794-.735a.714.714,0,0,0,.53-.385Z" transform="translate(-1.517 -2)"
                        fill={color}
                    />
                </g>
            </g>
        </SvgIcon>
    );
}
