import React from "react";
import { AddEditForm } from "../addEditForm/index";
import { Grid, Button, Typography, CircularProgress } from "@material-ui/core";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routes } from "../../../../router/routes";

class EditCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    this.getCaregiversDetail();
  }

  getCaregiversDetail = async () => {
    try {
      const id = this.props?.match?.params?.id;
      const res = await this.props.GET_CARE_GIVER_INFO({ id });
      this.setState({
        data: this.massageDataToEdit(res?.payload?.data),
        loading: false,
        error: false,
      });
    } catch (err) {
      this.setState({
        data: null,
        loading: false,
        error: true,
      });
    }
  };

  navigateToAdd = () => {
    this.props.history.push(routes.add_care_givers);
  };

  massageDataToEdit = (data) => {
    if (!data) {
      return null;
    }
    
    return {
      careGiverId: data?.id,
      profile_pic: data?.profile_pic,
      name: data?.name,
      mobileNo: data?.mobile_no,
      phoneCode: data?.mobile_no_isd_code,
      email: data?.email,
      // address: data?.address,
      addressLine1: data?.addressLine1,
      addressLine2: data?.addressLine2,
      area:data?.area,
      state:data?.state,
      country:data?.country,
      postalCode:data?.postalCode,
      category: data?.category,
      skills: data?.skills,
      license_no: data?.license_no,
      license_description: data?.license_desc,
      specialization: data?.specialization,
      certificate: data?.certification,
      experience: data?.experience,
      careProvider: data.care_provider && data.care_provider.length > 0 ? { value: data.care_provider[0].id, label: data.care_provider[0].name } : {},
      service_coordinator: data?.service_coordinators ?? [],
      salutation: data?.salutation ?? ""
    };
  };

  render() {
    let { loading, error, data } = this.state;

    return (
      <>
        {loading && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <CircularProgress />
            </Grid>
            <Grid item>
              <Typography variant="h6" align="center">
                Loading the Service Professional detail
              </Typography>
            </Grid>
          </Grid>
        )}
        {!loading && error && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <Typography variant="h6" align="center">
                Something went wrong!
              </Typography>
            </Grid>
            <Grid item>
              <Button
                style={{ marginTop: 16, textAlign: "center" }}
                variant={"outlined"}
                onClick={this.getCaregiversDetail}
              >
                Try Again
              </Button>
            </Grid>
          </Grid>
        )}
        {!loading && !error && !data && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <Typography variant="h6" align="center">
                404 - Caregiver details not found
              </Typography>
            </Grid>
            <Grid item>
              <Button
                style={{ marginTop: 16, textAlign: "center" }}
                variant={"outlined"}
                onClick={this.navigateToAdd}
              >
                + Add Careprovider
              </Button>
            </Grid>
          </Grid>
        )}
        {!error && data && <AddEditForm data={data} />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  careGivers: state.careGiver,
});

export default connect(mapStateToProps, actions)(withRouter(EditCard));
