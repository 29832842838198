/* eslint-disable eqeqeq */
import React from "react";
import AddEditProductManagement from "../../../../components/addEditForm";
import {
  formComponentTypes,
  alertProps,
  user_roles,
  // converToReactSelect,
  packageTableColumns as columns,
  options,
  tableType,
  getUniques,
  base64MimeType,
  upload_file,
  exoticColumnType,
  // TAXTYPE,
} from "../../../../utils/index";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routes } from "../../../../router/routes";
import {
  LoadingSection,
  InternatlServerError,
  NotFoundSection,
} from "../../../../components";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { withWidth, isWidthDown } from "@material-ui/core";

const initialState = {
  name: "",
  frequency: "",
  valdity: ["", ""],
  provider: null,
  category: null,
  services: [],
  profile_pic: "",
  productValidity: { label: "Custom", value: "custom" },
  terms_conditions: [],
  inclusions: [],
  open: false,
  popperData: [],
  categories: [],
};

// const taxIdOptions = [{name: "Sales Tax", value: 10, type: "variable"}, {name: "Service Tax", value: 25, type: "fixed"}]

const exoticRow = [
  null,
  null,
  {
    type: exoticColumnType.TAX_TYPE,
  },
  {
    type: exoticColumnType.TAX_PERCENTAGE,
  },
  null,
  null,
  {
    type: exoticColumnType.DISCOUNT,
  },
  {
    type: exoticColumnType.TOTAL_TEXT,
  },
  {
    type: exoticColumnType.TOTAL_VALUE,
  },
];

class AddEditPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discount: 0,
      tax: 0,
      taxType: null,
      total: 0,
      isEditInitialClearServices: false,
      isEditInitialClearProviders: false,
      isResetState: false,
      ...initialState,
      startDate: null,
      endDate: null,
      validity: { label: "Custom", value: "custom" },
      isDisableFields: false,
      pre_assessment_required: false,
      assessment_required: false,
      service_deliverable_required: false,
      promotional_content: null,
      currency: {
        label: "MYR",
        value: "MYR",
      },
      localPackageLoading: false,
    };
  }

  handleRowsReorder = (rows, sourceIndex, destinationIndex) => {
    const newRowsCopy = rows?.map((row, index) => {
      if (destinationIndex === 0 && index === 0) {
        return { ...row, dependentService: [] };
      }
      return { ...row };
    });

    // if (destinationIndex === 0) {
    //   rows[destinationIndex].dependentService = [];
    // }

    this.setState({
      services: newRowsCopy,
    });

    // const draggedItem = rows[destinationIndex];

    // if (rows?.length > 0) {
    //   // 1. search dependentservice of the dragged Item first
    //   let draggedItemDependentServiceIndexes = [];
    //   rows.map((x, i) => {
    //     if (
    //       draggedItem?.dependentService?.filter((e) => e?.value === x?.value)
    //         .length > 0
    //     ) {
    //       draggedItemDependentServiceIndexes = [
    //         ...draggedItemDependentServiceIndexes,
    //         { id: x?.value, index: i },
    //       ];
    //     }
    //     return x;
    //   });

    //   if (draggedItemDependentServiceIndexes?.length > 0) {
    //     draggedItemDependentServiceIndexes.map((dependent) => {
    //       if (destinationIndex < dependent.index) {
    //         rows = rows?.map((_, i) => {
    //           if (i === destinationIndex) {
    //             return {
    //               ..._,
    //               dependentService: _?.dependentService?.filter(
    //                 (x) => x?.value !== dependent?.id
    //               ),
    //             };
    //           }
    //           return _;
    //         });
    //       }
    //       return dependent;
    //     });
    //   }

    //   // 2. check if the dragged item is a dependent service for any other service
    //   let draggedItemId = draggedItem.value;

    //   rows = rows?.map((_, i) => {
    //     if (
    //       _?.dependentService?.filter((e) => e?.value === draggedItemId)
    //         .length > 0
    //     ) {
    //       if (i < destinationIndex) {
    //         return {
    //           ..._,
    //           dependentService: _?.dependentService?.filter(
    //             (x) => x?.value !== draggedItemId
    //           ),
    //         };
    //       }
    //       return _;
    //     }
    //     return _;
    //   });

    //   this.setState({
    //     services: rows,
    //   });
    // }
  };

  componentDidMount() {
    this.getEssentials();

    this.checkForRole();
  }

  checkForRole = () => {
    let auth_data = this.props.auth_data;

    if (
      user_roles.provider === auth_data.user_role &&
      auth_data?.other_roles?.indexOf("cross-bundling") == -1
    ) {
      let select_provider = [
        {
          value: auth_data?.user_id,
          label: auth_data?.profile_name,
        },
      ];

      // let select_category = auth_data.category;

      // this.servicesByCareProvider(select_provider, { select_category });
      this.categoriesOptions(select_provider);
    }
  };

  getEssentials = async () => {
    this.props.GET_CATEGORIES({});
    this.props.GET_FREQ({});
    this.props.GET_ALL_VALDITY({});
    this.props.GET_MASTER_PRODUCT_MANAGEMENT_GROUP_TYPES({});
    this.props.GET_PARTNER_DROPDOWN({});
    this.props.GET_RESOURCE_TYPES({});
    await this.props.GET_TAX_INTEGRATION({});

    if (this.props.isEdit) {
      const id = this.props?.match?.params?.id;
      const versionId = this.props?.match?.params?.versionId;
      await this.props.GET_PACKAGES_INFO({ id, versionId });
      const { data: editData } = this.props?.packageInfo;
      // const { data: taxTypeOptions=[] } = this.props?.taxTypeMaster || {}
      this.setState({
        taxType: editData?.data?.[0]?.resourceMeta?.taxType,
        tax: editData?.data?.[0]?.resourceMeta?.tax,
      });
    }
  };

  getProvidersBasedOnCategories = (categories) => {
    if (categories) {
      this.props.GET_PROVIDER_LIST_BASEDON_CATEGORY({
        categories: Array.isArray(categories)
          ? categories.map((_) => _.value)
          : [categories?.value],
      });
    }
  };

  getServices = (state, providerDetails) => {
    const categories = state?.select_category || state?.category;
    if (categories && providerDetails) {
      this.props.GET_SERVICES_BASEDON_PROVIDER_CATEGORY({
        categories: categories
          ? Array.isArray(categories)
            ? categories.map((_) => _.value)
            : [categories?.value]
          : null,
        provider: providerDetails
          ? Array.isArray(providerDetails)
            ? providerDetails.map((_) => _.value)
            : [providerDetails?.value]
          : null,
      });
    }
  };

  careProviderByCategory = (categories, state, isEdit = false) => {
    const settingState = () => {
      this.setState(
        {
          isResetState: state,
          name: state?.package_name,
          frequency: state?.type,
          valdity: [state?.startDate, state?.endDate],
          provider: provider,
          category: category,
          services: services,
          profile_pic: state?.uploadImage,
          terms_conditions: state?.terms_conditions,
          group_type: state?.group_type,
          inclusions: state?.inclusions,
          productValidity: state?.productValidity,
        },
        () => {
          this.getProvidersBasedOnCategories(categories);
        }
      );
    };

    let { category, provider, services } = this.state;

    if (categories) {
      if (category?.constructor === Array) {
        // to find which category has removed
        let removedCategory = category.filter((x) => !categories.includes(x)); // calculates diff

        const handleOnClose = () => {
          category = this.state.category;
          provider = this.state.provider;
          services = this.state.services;

          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: false,
            title: "Remove Category",
            body: "Removing category will remove selected providers and services",
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            onOk: () => {},
            onCancel: () => {},
          });

          settingState();
        };

        const handleOnOkModal = () => {
          category = categories;
          let removedProvider = provider.filter(
            (x) => x.categoryId === Number(removedCategory[0].value)
          );

          if (provider) {
            // find which provider to be removed
            let updatedProvider = provider.filter(
              (x) => x.categoryId !== Number(removedCategory[0].value)
            );
            provider = updatedProvider;
          } else {
            provider = [];
          }

          if (services) {
            // find which provider to be removed
            if (removedProvider?.length > 0) {
              let updatedService = services.filter(
                (x) =>
                  Number(x.providerDetails?.[0]?.value) !==
                  Number(removedProvider[0].value)
              );
              services = updatedService;
            } else {
              services = this.state.services;
            }
          } else {
            services = [];
          }

          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: false,
            title: "Remove Category",
            body: "Removing category will remove selected providers and services",
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            onOk: () => {},
            onCancel: () => {},
          });
          settingState();
        };

        if (removedCategory?.length > 0) {
          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: true,
            title: "Remove Category",
            body: "Removing category will remove selected providers and services",
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            onOk: handleOnOkModal,
            onCancel: handleOnClose,
          });
          return false;
        } else {
          category = categories;
          provider = this.state.provider;
          services = this.state.services;
          settingState();
        }
      } else {
        category = categories;
        provider = [];
        services = [];
        settingState();
      }
    } else {
      const handleOnOkModal = () => {
        category = [];
        provider = [];
        services = [];

        this.props.dialog.setDialog({
          ...this.props.dialog,
          open: false,
          title: "Remove Category",
          body: "Removing category will remove selected providers and services",
          positiveBtn: "Ok",
          negativeBtn: "CANCEL",
          onOk: handleOnOkModal,
          onCancel: handleOnClose,
        });
        settingState();
      };
      const handleOnClose = () => {
        category = this.state.category;
        provider = this.state.provider;
        services = this.state.services;
        this.props.dialog.setDialog({
          ...this.props.dialog,
          open: false,
          title: "Remove Category",
          body: "Removing category will remove selected providers and services",
          positiveBtn: "Ok",
          negativeBtn: "CANCEL",
          onOk: handleOnOkModal,
          onCancel: handleOnClose,
        });
        settingState();
      };
      this.props.dialog.setDialog({
        ...this.props.dialog,
        open: true,
        title: "Remove Category",
        body: "Removing category will remove selected providers and services",
        positiveBtn: "Ok",
        negativeBtn: "CANCEL",
        onOk: handleOnOkModal,
        onCancel: handleOnClose,
      });
    }
  };

  servicesByCareProvider = (providerDetails, state, isEdit = false) => {
    const settingState = () => {
      this.setState(
        {
          isResetState: state,
          name: state?.package_name,
          frequency: state?.type,
          valdity: [state?.startDate, state?.endDate],
          provider: provider,
          category: state?.select_category,
          services: services,
          profile_pic: state?.uploadImage,
          terms_conditions: state?.terms_conditions,
          group_type: state?.group_type,
          inclusions: state?.inclusions,
          productValidity: state?.productValidity,
        },
        () => {
          this.getServices(state, providerDetails);
        }
      );
    };

    let { provider, services } = this.state;

    if (providerDetails) {
      if (provider?.constructor === Array) {
        // to find which category has removed
        let removedProvider = provider.filter(
          (x) => !providerDetails.includes(x)
        ); // calculates diff

        const handleOnOkModal = () => {
          provider = providerDetails;
          let removedServices = services.filter(
            (x) =>
              Number(x.providerDetails?.[0]?.value) ===
              Number(removedProvider[0].value)
          );
          let removedServicesIds = removedServices.map((val) =>
            Number(val.value)
          );

          if (services) {
            if (removedServices?.length > 0) {
              // find which services to be removed
              let updatedService = services.filter(
                (x) => removedServicesIds.indexOf(Number(x.value)) === -1
              );
              console.log("updatedService: ", updatedService);
              services = updatedService;
            } else {
              services = this.state.services;
            }
          } else {
            services = [];
          }

          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: false,
            title: "Remove Provider",
            body: "Removing provider will remove selected services",
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            onOk: () => {},
            onCancel: () => {},
          });
          settingState();
        };

        const handleOnClose = () => {
          provider = this.state.provider;
          services = this.state.services;
          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: false,
            title: "Remove Provider",
            body: "Removing provider will remove selected services",
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            onOk: () => {},
            onCancel: () => {},
          });
          settingState();
        };

        if (removedProvider?.length > 0) {
          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: true,
            title: "Remove Provider",
            body: "Removing provider will remove selected services",
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            onOk: handleOnOkModal,
            onCancel: handleOnClose,
          });
        } else {
          provider = providerDetails;
          services = this.state.services;
          settingState();
        }
      } else {
        provider = [];
        services = [];
        settingState();
      }
    } else {
      const handleOnOkModal = () => {
        provider = [];
        services = [];

        this.props.dialog.setDialog({
          ...this.props.dialog,
          open: false,
          title: "Remove Category",
          body: "Removing category will remove selected services",
          positiveBtn: "Ok",
          negativeBtn: "CANCEL",
          onOk: handleOnOkModal,
          onCancel: handleOnClose,
        });
        settingState();
      };

      const handleOnClose = () => {
        provider = this.state.provider;
        services = this.state.services;
        this.props.dialog.setDialog({
          ...this.props.dialog,
          open: false,
          title: "Remove Category",
          body: "Removing category will remove selected services",
          positiveBtn: "Ok",
          negativeBtn: "CANCEL",
          onOk: handleOnOkModal,
          onCancel: handleOnClose,
        });
        settingState();
      };

      this.props.dialog.setDialog({
        ...this.props.dialog,
        open: true,
        title: "Remove Category",
        body: "Removing category will remove selected providers and services",
        positiveBtn: "Ok",
        negativeBtn: "CANCEL",
        onOk: handleOnOkModal,
        onCancel: handleOnClose,
      });
    }
  };

  updateServiceRow = (s, state) => {
    //There is an issue in the order of the services, that we are not updating in the addEditform component. So there is a fix in reordering the array that is sent by react-select which is available through the variable 's'

    let final = s ? [...s] : [];

    let addedService = [];
    let removedService = [];

    if (final?.length > this.state.services?.length) {
      addedService = getUniques(
        final,
        this.state.services?.length > 0 ? this.state.services : [],
        "value"
      );
    } else if (final?.length < this.state.services?.length) {
      removedService = getUniques(
        this.state.services?.length > 0 ? this.state.services : [],
        final,
        "value"
      );
    }

    let finalRows = this.state.services?.length > 0 ? this.state.services : [];

    if (addedService?.length > 0) {
      if (this.props.isEdit) {
        finalRows = Object.assign([], finalRows);
      }
      let amount = addedService[0]?.amount;
      finalRows.push({
        ...addedService[0],
        quantity: 1,
        price_editable: addedService[0]?.isPriceEditable,
        quantityEditable:
          addedService[0]?.entityType === "service" ? true : false,
        providerDetails: addedService[0]?.providerInfo?.label,
        amount: parseFloat(amount)?.toFixed(2),
        serviceAmountPrice: addedService[0].serviceAmountPrice
          ? parseFloat(addedService[0].serviceAmountPrice)?.toFixed(2)
          : parseFloat(amount)?.toFixed(2),
        total: addedService[0].serviceAmountPrice
          ? parseFloat(addedService[0].serviceAmountPrice)?.toFixed(2)
          : parseFloat(amount)?.toFixed(2),
        uniqueId: uuidv4(),
        strict: addedService[0]?.strict ?? true,
        dependentService:
          addedService[0]?.dependentService?.length > 0
            ? addedService[0]?.dependentService
            : [],
      });
    } else if (removedService?.length > 0) {
      finalRows = finalRows.filter((x) => x.value !== removedService[0]?.value);
    }

    //Check the dependent service of all the services and remove the removed service
    if (this.state.services?.length > s?.length) {
      if (removedService?.length === 1) {
        if (removedService?.[0]?.value) {
          finalRows = finalRows.map((x) => {
            return {
              ...x,
              dependentService: x?.dependentService?.filter(
                (_) => _?.value !== removedService?.[0]?.value
              ),
            };
          });
        }
      }
    }

    let auth_data = this.props.auth_data;
    let select_provider;
    if (
      user_roles.provider === auth_data.user_role &&
      auth_data?.other_roles?.indexOf("cross-bundling") == -1
    ) {
      select_provider = [
        {
          value: auth_data?.user_id,
          label: auth_data?.profile_name,
        },
      ];
    } else {
      select_provider = state?.select_provider ?? "";
    }
    this.setState({
      isResetState: state,
      name: state?.package_name,
      frequency: state?.type,
      valdity: [state?.startDate, state?.endDate],
      productValidity: state.productValidity,
      provider: select_provider,
      category: state?.select_category,
      profile_pic: state?.uploadImage,
      terms_conditions: state?.terms_conditions,
      group_type: state?.group_type,
      inclusions: state?.inclusions,
      services: finalRows,
      ...this.totalWithTaxDisAmount(
        finalRows,
        this.state.tax,
        this.state.discount
      ),
    });
  };

  handleRemove = (e) => {
    let removedServiceId = this.state.services.filter(
      (_) => _.value === e.value
    )?.[0]?.value;
    let services = this.state.services.filter((_) => _.value !== e.value);

    if (removedServiceId) {
      services = services.map((x) => {
        return {
          ...x,
          dependentService: x?.dependentService?.filter(
            (_) => _?.value !== removedServiceId
          ),
        };
      });
    }
    this.setState({
      services: services,
      discount: services.length ? this.state.discount : 0,
      total: services.length
        ? // ? this.getTotalAmount(services, this.state.discount)
          this.totalWithTaxDisAmount(
            services,
            this.state.tax,
            this.state.discount
          ).total
        : 0,
    });
  };

  onDiscountChange = (value) => {
    //
    // console.log(x.on)
    // let value = x?.target?.value
    // console.log(e.type)
    if (value < 100) {
      let { total, originalTotal } = this.totalWithTaxDisAmount(
        this.state.services,
        this.state.tax,
        value
      );

      this.setState({
        ...this.state,
        discount: value,
        total: total,
        originalTotal,
      });
      return false;
    }
  };

  totalWithTaxDisAmount = (totalServiceData, taxPer, dis) => {
    let _arrOfAmount = [];
    // let tax = taxPer?.length > 0 ? taxPer : 0;
    if (totalServiceData && totalServiceData?.length > 0) {
      totalServiceData.map((val) => {
        if (val?.amount) {
          _arrOfAmount.push(Number(val.amount.trim().replace("RM", "")));
        }
        return val;
      });
    }
    let _totalAmount = _arrOfAmount.reduce((a, b) => a + b, 0);
    // let parsedTax = parseFloat(tax).toFixed(2);
    let disPer = Number(dis) / 100;
    let amountWithDis = _totalAmount - _totalAmount * disPer;
    // let total =
    //   (parseFloat(amountWithDis) * parseFloat(parsedTax)) / 100 +
    //   parseFloat(amountWithDis);
    return {
      total: parseFloat(amountWithDis).toFixed(2),
      originalTotal: _totalAmount.toFixed(2),
    };
  };

  onTaxChange = (tax) => {
    if (tax <= 100) {
      let checkTax = tax.length === 0 ? "0" : tax;
      let { total, originalTotal } = this.totalWithTaxDisAmount(
        this.state.services,
        checkTax,
        this.state.discount
      );
      this.setState({
        ...this.state,
        total: total,
        tax: tax,
        originalTotal,
      });
    }
  };

  onTaxTypeChange = (event) => {
    const { data: taxTypeOptions } = this.props?.taxTypeMaster;

    let { tax } = this.state;
    const value = event.target.value;
    const selectedTaxType = taxTypeOptions?.filter(
      (tax) => tax?.value === value
    )?.[0];

    // if(selectedTaxType?.type === TAXTYPE.FIXED){
    // }
    if (selectedTaxType?.taxPercentage !== undefined) {
      tax = selectedTaxType?.taxPercentage;
    }

    this.setState({
      tax: tax,
      taxType: value,
    });
  };

  getTotalAmount = (data, discount) => {
    let discountedAmountOld = discount > 0 ? 0 : discount;
    let _arrOfAmount = [];
    if (data?.length > 0) {
      data.map((val) => {
        if (val?.amount) {
          _arrOfAmount.push(Number(val.amount.trim().replace("RM", "")));
        }
        return val;
      });
      let _totalAmount = _arrOfAmount.reduce((a, b) => a + b, 0);
      if (discount) {
        let _discount = Number(discount) / 100;
        let _discountedPrice = _totalAmount - _totalAmount * _discount;
        let isNegativeCheck =
          Math.sign(_discountedPrice) === 1 ? _discountedPrice : 0;
        return isNegativeCheck.toFixed(2);
      } else {
        return _totalAmount.toFixed(2);
      }
    } else {
      if (Number(discount) !== discountedAmountOld)
        // setDiscountedAmount(0);
        return 0;
    }
  };

  onTotalChange = (e) => {
    this.setState({
      total: e,
    });
  };

  onDropdownChange = (value, key) => {
    this.setState({
      [key]: value,
    });
  };

  onSaveButtonClicked = async (isError, data, isUrl) => {
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      this.setState({ localPackageLoading: true });
      let auth_data = this.props.auth_data;

      if (
        user_roles.provider === auth_data.user_role &&
        auth_data?.other_roles?.indexOf("cross-bundling") == -1
      ) {
        data.select_provider = [
          {
            value: auth_data?.user_id,
            label: auth_data?.profile_name,
          },
        ];
      }

      let packageId = this.props?.match?.params?.id;

      const getFlagValue = () => {
        const { data: editData } = this.props?.packageInfo;
        // const dbStartDate = editData?.data?.[0]?.resourceMeta?.resource_validity?.value?.resourceEffectiveFrom
        // const dbEndDate = editData?.data?.[0]?.resourceMeta?.resource_validity?.value?.resourceEndsOn

        const editedEffective = data?.startDate;
        const dbEffective =
          editData?.data?.[0]?.resourceMeta?.resource_validity?.value
            ?.resourceEffectiveFrom;

        const editedEndsOn = data?.endDate;
        const dbEndsOn =
          editData?.data?.[0]?.resourceMeta?.resource_validity?.value
            ?.resourceEndsOn;

        // if(editData){
        //   if(moment(data?.startDate)?.isSame(dbStartDate) && moment(data?.endDate)?.isSame(dbEndDate)){
        //     return false
        //   } else {
        //     return true
        //   }
        // } else {
        //   return false
        // }

        if (editData) {
          if (!moment(editedEffective)?.isSame(dbEffective)) {
            return true;
          } else if (editedEndsOn && dbEndsOn) {
            if (!moment(editedEndsOn)?.isSame(dbEndsOn)) {
              return true;
            }
          } else if (
            (!editedEndsOn && dbEndsOn) ||
            (editedEndsOn && !dbEndsOn)
          ) {
            return true;
          }

          return false;
        } else {
          return true;
        }
      };

      let payload = {
        partnerId: Array.isArray(data.select_provider)
          ? data.select_provider.map((val) => val.value)
          : data.select_provider.value,
        // partnerId:  auth_data?.user_id,
        resourceCategory: data.select_category.map((val) => val.value),
        resourceName: data.package_name,
        effectiveFrom: moment.utc(data?.startDate)?.format(),
        effectiveTo: data?.endDate ? moment.utc(data?.endDate)?.format() : "",
        packageId: packageId,
        resourceMeta: {
          ...this.state,
          ...data,
          price: this.state.total,
          group_type: data.group_type,
          select_provider: Array.isArray(data.select_provider)
            ? data.select_provider
            : [data.select_provider],
        },
        allowToBundle: data?.allowToBundle,
        allowToShow: data?.allowToShow,
        isEffectiveFromToValidation: getFlagValue(),
      };
      // UPLOADING PROFILE IMAGE BEFORE SAVING DATA
      if (data?.uploadImage) {
        if (base64MimeType(data?.uploadImage)?.length > 0) {
          let { fileName, withoutType } = upload_file(data?.uploadImage);
          await this.props
            .FILE_UPLOAD({
              file: withoutType,
              fileName: fileName,
              isbase64: true,
            })
            .then(async (val) => {
              payload["resourceMeta"]["uploadImage"] = await val?.payload?.data
                ?.fileid;
            });
        } else {
          payload["resourceMeta"]["uploadImage"] =
            data?.uploadImage?.split("/")[
              data?.uploadImage?.split("/").length - 1
            ];
        }
      }

      if (!this.props.isEdit) {
        // If the mode is add. Then Check UI validation using query.
        const isErrorFree = await this.handleActivateDeactivateValidations(
          payload?.partnerId,
          {
            effectiveFrom: payload?.effectiveFrom,
            effectiveTo: payload?.effectiveTo,
          },
          payload
        );

        if (isErrorFree) {
          this.props.ADD_PACKAGES({ ...payload });
        }
      } else {
        // If the mode is Edit, check UI validtion using API.
        this.props.ADD_PACKAGES({ ...payload });
      }
    }
  };

  handleActivateDeactivateValidations = async (
    partnerId,
    resource_validity,
    payload
  ) => {
    const startDate = moment(resource_validity?.effectiveFrom)
      .startOf("day")
      .toISOString();
    const endDate =
      resource_validity?.effectiveTo?.length > 0
        ? moment(resource_validity?.effectiveTo).endOf("day").toISOString()
        : "";
    const { auth_data } = this.props;
    let isCrossBundling =
      user_roles.provider === auth_data.user_role &&
      auth_data?.other_roles?.indexOf("cross-bundling") != -1;
    await this.props?.GET_PARTNER_ACTIVE_DATE_BY_SUPERVISOR({
      partnerId: partnerId,
      startDate: startDate,
      endDate: endDate,
      isCrossBundling,
    });
    const { data } = this.props?.getPartnerActiveStatusBySupervisor;

    // eslint-disable-next-line no-mixed-operators
    if (
      (isCrossBundling &&
        Array.isArray(data) &&
        data?.length == partnerId?.length) ||
      (Array.isArray(data) && data?.length > 0)
    ) {
      // No issue in adding Package

      // Check if the services selected are not less than the package validity.
      const serviceDates = payload?.resourceMeta?.services?.map((service) => {
        return {
          effectiveFrom: service?.resourceMeta?.resourceMeta?.effectiveFrom,
          effectiveTo: service?.resourceMeta?.resourceMeta?.effectiveTo,
        };
      });

      const isDateTestPassed = (effectiveFrom, effectiveTo) => {
        effectiveFrom = moment(effectiveFrom);
        effectiveTo = effectiveTo?.length > 0 ? moment(effectiveTo) : "";

        let _endsON =
          effectiveTo?.length > 0 && endDate?.length > 0
            ? moment(endDate)?.isSameOrBefore(effectiveTo, "day")
            : endDate?.length == 0 && effectiveTo?.length > 0
            ? false
            : true;
        return moment(startDate)?.isSameOrAfter(effectiveFrom, "day") && _endsON
          ? true
          : false;
      };

      let truthy = serviceDates.every((element) => {
        const element_startDate = moment(element?.effectiveFrom).startOf("day");
        const element_endDate =
          element?.effectiveTo?.length > 0
            ? moment(element?.effectiveTo).startOf("day")
            : "";

        return isDateTestPassed(element_startDate, element_endDate);
      });

      if (!truthy) {
        // To avoid multiple package add, the loading state below is used.
        this.setState({ localPackageLoading: false });
        // Issue occured while adding package
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `Some services in the table expires before selected package expiry. Please remove those services.`,
          severity: alertProps.severity.error,
        });
      }

      return truthy;
    } else {
      // To avoid multiple package add, the loading state below is used.
      this.setState({ localPackageLoading: false });
      // Issue occured while adding package
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: `Partner may not be active on the selected dates. Please select different date.`,
        severity: alertProps.severity.error,
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Add Package
    const { addPackage } = nextProps;
    const oldAddPackage = this.props.addPackage;
    //Checking for Add Package messages
    if (oldAddPackage?.loading !== addPackage?.loading) {
      if (!addPackage.loading && !addPackage.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: addPackage.message,
          severity: alertProps.severity.success,
        });
        this.props.history.push(routes.manage_package);
      } else if (addPackage.error) {
        this.setState({ localPackageLoading: false });
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong!",
          severity: alertProps.severity.error,
        });
      }
    }

    //Update Package
    const { updatePackageInfo } = nextProps;
    const oldUpdatePackageInfo = this.props.updatePackageInfo;

    //Checking for Add Package messages
    if (oldUpdatePackageInfo?.loading !== updatePackageInfo?.loading) {
      if (!updatePackageInfo.loading && !updatePackageInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Package Updated successfully!",
          severity: alertProps.severity.success,
        });
        this.props.history.push(routes.manage_package);
      } else if (updatePackageInfo.error) {
        this.setState({ localPackageLoading: false });
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Update Package.",
          severity: alertProps.severity.error,
        });
      }
    }

    //Get Package
    const { packageInfo } = nextProps;
    const oldPackageInfo = this.props.packageInfo;

    //Checking for Add Package messages
    if (oldPackageInfo?.loading !== packageInfo?.loading) {
      const { data } = packageInfo;

      if (data?.data?.length > 0) {
        this.setState(
          {
            services: data?.data?.[0]?.resourceMeta?.services,
            tax: data?.data?.[0]?.resourceMeta?.tax,
            total: data?.data?.[0]?.resourceMeta?.total,
            discount: data?.data?.[0]?.resourceMeta?.discount,
            originalTotal: this.totalWithTaxDisAmount(
              data?.data?.[0]?.resourceMeta?.services,
              data?.data?.[0]?.resourceMeta?.tax,
              data?.data?.[0]?.resourceMeta?.total
            ).originalTotal,
          },
          () => {
            let params = {
              type: "b6aea2a2-f752-4e82-9db4-bc6514f9f2ea",
              isServiceOnly: true,
              categories:
                data?.data?.[0]?.resourceMeta?.select_category?.length > 0
                  ? data?.data?.[0]?.resourceMeta?.select_category.map(
                      (val) => val?.value
                    )
                  : [],
              providers: Array.isArray(
                data?.data?.[0]?.resourceMeta?.select_provider
              )
                ? data?.data?.[0]?.resourceMeta?.select_provider.map(
                    (val) => val?.value
                  )
                : [data?.data?.[0]?.resourceMeta?.select_provider?.value],
            };
            if (
              data?.data?.[0]?.resourceMeta?.select_category &&
              data?.data?.[0]?.resourceMeta?.select_category?.length > 0
            ) {
              this.props.SER_PGK_BASED_ON_CAT_PARTNER(params);
            }

            let paramsPodiver = {
              partnerArray: Array.isArray(
                data?.data?.[0]?.resourceMeta?.select_provider
              )
                ? data?.data?.[0]?.resourceMeta?.select_provider?.map(
                    (val) => val?.value
                  )
                : [data?.data?.[0]?.resourceMeta?.select_provider?.value],
            };
            this.props.GET_CATEGORY_LIST_BASEDON_PROVIDERS(paramsPodiver);
          }
        );
      }

      // return false;
      // const findShouldDisable = () =>{
      //   let shouldDisable = false
      //   if(this.props?.isEdit){
      //     if(this.props?.auth_data?.providerDetails?.length > 0 && data?.care_provider?.length > 0){

      //       let auth_provider_ids = this.props?.auth_data?.providerDetails.map(val=>{return Number(val.provider_id)})
      //       let current_product_details_provider_ids= data?.care_provider.map(val=>{return val.id})
      //       shouldDisable = (auth_provider_ids.filter(val=>current_product_details_provider_ids.includes(val))).length > 0 ? false : true
      //     }
      //     else{
      //       shouldDisable = false
      //     }
      //   }
      //   else{
      //     shouldDisable = false
      //   }
      //   return shouldDisable
      // }

      // const newRows = data?.details?.rows.map((_) => {

      //   return {
      //     ..._,
      //     label: _.name,
      //     value: _.id.toString(),
      //     type: "service",
      //     total: parseFloat(_.serviceAmountPrice).toFixed(2),
      //     serviceAmountPrice: _.serviceAmountPrice
      //       ? parseFloat(_.serviceAmountPrice).toFixed(2)
      //       : parseFloat(_.amount).toFixed(2),
      //     originalAmount: parseFloat(_.amount).toFixed(2),
      //     amount: parseFloat(
      //       (_.serviceAmountPrice ? _.serviceAmountPrice : _.amount) *
      //         _.quantity
      //     ).toFixed(2),
      //   };
      // });

      // const formattedDiscount = parseFloat(data?.details?.discount).toFixed(2);

      // this.setState(
      //   {
      //     isDisableFields:findShouldDisable(),
      //     isResetState: data,
      //     name: data?.name,
      //     frequency:
      //       this.props?.frequency?.data?.length > 0
      //         ? this.props?.frequency?.data.filter((val) => {
      //             return val.label === data?.type;
      //           })[0]
      //         : [],
      //     valdity: [
      //       moment(data?.validation_date?.start_date),
      //       moment(data?.validation_date?.end_date),
      //     ],
      //     profile_pic: data?.profile_pic,
      //     terms_conditions: data?.details?.terms_conditions,
      //     inclusions: data?.details?.inclusion,

      //     services: newRows,
      //     discount: formattedDiscount,
      //     provider:
      //       data?.care_provider?.length > 0
      //         ? converToReactSelect(data?.care_provider, "id", "name")
      //         : [],
      //     category: data?.category,
      //     productValidity: data?.validityPeriod
      //       ? JSON.parse(data?.validityPeriod)
      //       : [],

      //     total: this.getTotalAmount(newRows, data?.details?.discount),
      //     tax: ''
      //   },
      //   () => {

      //     this.getProvidersBasedOnCategories(data?.category)
      //     this.getServices(this.state,  converToReactSelect(data?.care_provider,'id','name'))
      //   }
      // );
    }

    return true;
  }

  validateAmount = (value, key) => {
    if (value < 0) {
      return false;
    } else if (value.length === 1 && value === ".") {
      return false;
    } else if (
      value.split(".").length - 1 === 1 ||
      value.split(".").length - 1 === 0
    ) {
      const preDecimal = value.split(".")[0];
      const postDecimal = value.split(".")[1];

      if (preDecimal.length <= 10) {
        if (postDecimal) {
          if (postDecimal.length <= 2) {
            return true;
          } else return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  onTextFieldChange = (index, key, value, event) => {
    // Less than zero Check
    console.log(event);
    if (value < 0) return null;

    let service;

    if (key === "quantity") {
      let newValue = value;

      if ((value === "0" || value === "") && key === "quantity") {
        newValue = "1";
        if (value === "0") {
          value = "";
        }
      }

      newValue = parseInt(newValue);

      service = this.state.services.map((_, i) => {
        let originalAmount = _.originalAmount ? _.originalAmount : _.amount;
        originalAmount = parseFloat(originalAmount);
        if (i === index) {
          return {
            ..._,
            originalAmount: originalAmount,
            [key]: value,
            amount: String(
              ((_.total ? _.total : originalAmount) * newValue).toFixed(2)
            ),
          };
        }
        return _;
      });
    } else if (key === "serviceAmountPrice") {
      if (this.validateAmount(value)) {
        let editedPriceValue = value ? value : 1;

        service = this.state.services.map((_, i) => {
          let originalAmount = _.originalAmount ? _.originalAmount : _.amount;

          originalAmount = parseFloat(originalAmount);

          if (i === index) {
            return {
              ..._,
              originalAmount: originalAmount,
              [key]: value ? value : "",
              total: value ? value : "",
              amount: String(
                (editedPriceValue * (_.quantity ? _.quantity : 1)).toFixed(2)
              ),
            };
          }
          return _;
        });
      }
    }

    if (service) {
      this.setState({
        services: service,
        discount: service?.length ? this.state.discount : 0,
        total: service?.length
          ? // ? this.getTotalAmount(service, this.state.discount)
            this.totalWithTaxDisAmount(
              service,
              this.state.tax,
              this.state.discount
            ).total
          : 0,
      });
    }
  };

  canIHaveCategory = () => {
    if (this.props?.auth_data?.category?.length === 1) {
      return this.props?.auth_data?.category[0];
    } else {
      return [];
    }
  };

  categoriesOptionsGettingFromAuthData = () => {
    if (this.props?.auth_data?.category?.length > 0) {
      return this.props?.auth_data?.category;
    } else {
      return [];
    }
  };

  canIHaveProviderOptions = () => {
    const { auth_data } = this.props;

    if (
      user_roles.supervisor === auth_data.user_role ||
      user_roles.service_coordinator === auth_data.user_role ||
      user_roles.service_professional === auth_data.user_role
    ) {
      return (
        auth_data?.providerDetails?.map((_) => {
          return { value: _.provider_id, label: _.provider_name };
        }) ?? []
      );
    } else {
      return this.props?.providerBasedOnCategory?.data ?? [];
    }
  };

  handleValidityOptions = (selectedValidity) => {
    this.setState({
      productValidity: selectedValidity,
    });
  };

  calculateStartDateMin = () => {
    // Destructure productValidity (contains selected validity option) from the state
    const { productValidity } = this.state;

    // Check if the value of the selected productValidity is "custom", then no min value should be set. User can select any past date.
    if (productValidity.value === "custom") {
      return null;
    } else {
      // If productValidity value is not "custom"
      // If productValidity value exists
      if (productValidity.value) {
        // parseInt from the value
        const daysCount = parseInt(productValidity.value);

        // Get today date
        // const today = moment();

        // Calculate min date to restrict user to select
        let min = moment().subtract(daysCount - 1, "days");

        // return new Date from the calculated moment object
        return new Date(min);
      } else {
        // If the productValidity value does not exists then return null
        return null;
      }
    }
  };

  handlePopperClose = () => {
    this.setState({ open: false });
  };

  handlePopperOpen = (e, data) => {
    this.setState({ open: e.currentTarget, popperData: data });
  };

  handleCheckBox = (state, key) => {
    this.setState({ [key]: state });
  };

  handleFileUpload = (fileUploaded) => {
    this.setState({
      promotional_content: fileUploaded,
    });
  };

  handleFileRemove = () => {
    this.setState({
      promotional_content: null,
    });
  };

  categoriesOptions = (provider) => {
    // const { data } = this.props?.dropPartner;

    if (provider) {
      // let joinArrays = [];
      // let categoryList = provider?.map(val => val?.partnerCategory).map((e) => (joinArrays.push(...e)));
      let params = {
        partnerArray: Array.isArray(provider)
          ? provider?.map((val) => val.value)
          : [provider.value],
      };
      this.props.GET_CATEGORY_LIST_BASEDON_PROVIDERS(params);
      this.setState({ provider: provider, services: [] });
    } else {
      this.setState({ categories: [], services: [] });
    }
  };

  careCategoryByService = (categories, state, isEdit = false) => {
    let { provider } = this.state;
    let params = {
      type: "b6aea2a2-f752-4e82-9db4-bc6514f9f2ea",
      isServiceOnly: true,
      categories:
        categories?.length > 0 ? categories.map((val) => val?.value) : [],
      providers: Array.isArray(provider)
        ? provider.map((val) => val?.value)
        : [provider?.value],
    };

    if (categories && categories?.length > 0) {
      this.props.SER_PGK_BASED_ON_CAT_PARTNER(params);
    } else {
      this.setState({
        ...this.state,
        services: [],
        tax: "",
        discount: "",
        total: "",
        // services: []
      });
    }
  };

  components = (editableData) => {
    // let { data: editData } = this.props?.packageInfo;
    let editData = editableData?.data?.[0]?.resourceMeta;
    let { isEdit, auth_data } = this.props;
    const { data: taxTypeOptions = [] } = this.props?.taxTypeMaster || {};
    let { data } = this.props?.getValidity || {};
    let validityOptions = data ? data : [];

    let _json = {
      section_1: {
        label: "",
        fields: {
          uploadImage: {
            key: "uploadImage",
            id: "addImageFromAddPackage",
            type: formComponentTypes.PROFILE_UPLOAD_BUTTON,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            label: "PICTURE",
            value: isEdit ? editData?.uploadImage : this.state.profile_pic,
            isUrl: !isEdit ? false : true,
          },
          select_provider: {
            key: "select_provider",
            id: "selectProviderFromAddPackage",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              style: {
                margin: "auto 0",
              },
            },
            label: "SELECT PARTNER",
            value: isEdit ? editData?.select_provider : this.state.provider,
            onSelectedCallback: this.categoriesOptions,
            options: this.props?.dropPartner?.data ?? [],
            isMulti: true,
            isMandatory: true,
            isDisabled: this.state.isDisableFields,
            cleanUpKeys: ["select_category", "service"],
          },
          select_category: {
            key: "select_category",
            id: "selectCategoryFromAddPackage",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              style: {
                margin: "auto 0",
              },
            },
            label: "SELECT CATEGORY",
            value: isEdit ? editData?.select_category : this.state.category,
            // onSelectedCallback: this.categoriesOptions,
            onSelectedCallback: this.careCategoryByService,
            // options: isEdit ? editData?.select_provider?.partnerCategory : this.state.categories,
            options: this.props?.categoriesList?.data ?? [],
            isLoading: this.props?.categoriesList?.loading,
            isDisabled: this.state.isDisableFields,
            // cleanUpKeys: ["select_provider"],
            cleanUpKeys: ["service"],
            isMulti: true,
            isMandatory: true,
          },
          package_name: {
            key: "package_name",
            type: formComponentTypes.INPUT_BOX,
            id: "addPackageNameFromAddPackage",
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "PACKAGE NAME",
            value: isEdit ? editData?.package_name : this.state.name,
            isMandatory: true,
          },
          /* type: {
            key: "type",
            type: formComponentTypes.SELECT_BOX,
            id:"selectFrequencyFromAddPackage",
            componentProps: {},
            breakPoints: {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            label: "Frequency",
            value: isEdit ? editData?.type : this.state.frequency,
            options: this.props?.frequency?.data
              ? this.props?.frequency?.data
              : [],
            isMandatory: true,
          }, */
          group_type: {
            key: "group_type",
            type: formComponentTypes.SELECT_BOX,
            id: "selectGroupTypeFromAddPackage",
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              required: true,
            },
            label: "SELECT GROUP TYPE",
            value: isEdit ? editData?.group_type : this.state.group_type,
            // onSelectedCallback: this.careProviderByCategory,
            options: this.props?.product_management_group_types ?? [],
            // isLoading: this.props?.categories?.loading,
            isMulti: false,
            isMandatory: false,
            // isDisabled: !this.state.assessment_required,
            // cleanUpKeys: ["select_provider"]
            // isDontShow: !this.state.assessment_required,
          },
          product_validity: {
            type: formComponentTypes.PRODUCT_VALIDITY,
            key: {
              productValidity: "productValidity",
              startDate: "startDate",
              endDate: "endDate",
            },
            id: {
              validity_id: "selectValidityFromAddPackage",
              effectiveFrom_id: "addEffectiveFromAddPackage",
              end_id: "addEndFromAddPackage",
            },
            value: {
              productValidity: isEdit
                ? editData?.productValidity
                : this.state.productValidity,
              startDate: isEdit
                ? editData?.effectiveFrom
                : this.state.valdity[0],
              endDate: isEdit ? editData?.effectiveTo : this.state.valdity[1],
            },
            breakPoints: {
              xs: 12,
              sm: 8,
              md: 8,
              lg: 8,
              xl: 8,
            },
            isMandatory: true,
            componentProps: {
              isEdit: isEdit,
              dropdownProps: {
                options:
                  validityOptions.length > 0
                    ? validityOptions?.map((x) => ({
                        label: x.label,
                        value: x.days,
                        id: x.value,
                      }))
                    : [],
                // options: [
                //   { label: "Today", value: "1" },
                //   { label: "Week", value: "7" },
                //   { label: "Month", value: "30" },
                // ],
                label: "VALIDITY PERIOD",
                value: isEdit
                  ? editData?.productValidity
                  : this.state.productValidity,
                callback: () => this.handleValidityOptions("selected"),
              },
              // checkboxProps: {
              //   label: "CHECK",
              //   value: true
              // },
              startDateProps: {
                label: "EFFECTING FROM",
                value: isEdit ? editData?.effectiveFrom : this.state.valdity[0],
                // value: isEdit
                //   ? new Date(moment().subtract(2, "days"))
                //   : new Date(),
                // min: () => this.calculateStartDateMin(),
                // min: isEdit ? editData : null
                // value: () => this.getDateValue("startDate"),
                // callback: (value) => this.handleDateChange(value, "startDate"),
                // max: new Date(),
                // disablePast: false,
                // disableFuture: false,
                // inputFormat: "dd/MM/yyyy"
              },
              endDateProps: {
                label: "ENDS ON",
                value: isEdit ? editData?.effectiveTo : this.state.valdity[1],
                // value: isEdit ? new Date(moment().add(4, "days")) : new Date(),
                // min: isEdit ? editData : null
                // value: () => this.getDateValue("endDate"),
                // callback: (value) => this.handleDateChange(value, "endDate"),
                // max: isEdit ? editData : new Date(),
                // disablePast: false,
                // disableFuture: false,
                // inputFormat: "dd/MM/yyyy"
              },
            },
          },
          /* group_type: {
            key: "group_type",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              required: true,
            },
            label: "SELECT GROUP TYPE",
            value: isEdit ? editData?.group_type : this.state.group_type,
            // onSelectedCallback: this.careProviderByCategory,
            options: this.props?.product_management_group_types ?? [],
            // isLoading: this.props?.categories?.loading,
            isMulti: false,
            isMandatory: true,
            // isDisabled: !this.state.assessment_required,
            // cleanUpKeys: ["select_provider"]
            // isDontShow: !this.state.assessment_required,
          }, */

          currency: {
            key: "currency",
            type: formComponentTypes.SELECT_BOX,
            id: "selectCurrencyFromAddPackage",
            componentProps: {},
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            onSelectedCallback: (value) =>
              this.onDropdownChange(value, "currency"),
            label: "Currency Type",
            // value: isEdit ? editData?.currency : this.state.currency,
            value: {
              label: "MYR",
              value: "MYR",
            },
            isDisabled: true,
            options: options.currency,
            isMandatory: true,
          },

          allowToBundle: {
            key: "allowToBundle",
            type: formComponentTypes.SWITCH_COMPONENT,
            id: "switchAllowToBundleFromAddPackage",
            componentProps: {
              style: {
                alignSelf: "center",
              },
              fixMargin: isWidthDown("xs", this.props.width) ? true : false,
            },
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            withBoldHeading: true,
            onChangeCallBack: this.handleCheckBox,
            value: isEdit ? editData?.allowToBundle : false,
            label: "Allow to bundle",
            // isMandatory: true,
          },
          allowToShow: {
            key: "allowToShow",
            type: formComponentTypes.SWITCH_COMPONENT,
            id: "switchAllowToShowFromAddPackage",
            componentProps: {
              style: {
                alignSelf: "center",
              },
              fixMargin: isWidthDown("xs", this.props.width) ? true : false,
            },
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            withBoldHeading: true,
            onChangeCallBack: this.handleCheckBox,
            value: isEdit ? editData?.allowToShow : false,
            label: "Allow to show",
            // isMandatory: true,
          },
          service: {
            key: "service",
            type: formComponentTypes.SELECT_BOX,
            componentProps: {},
            id: "selectServiceFromAddPackage",
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "SELECT SERVICE",
            value: isEdit ? editData?.services : this.state.services,
            options:
              this.props?.servicePackageByCatPat?.data?.length > 0
                ? this.props?.servicePackageByCatPat?.data
                : [],
            // options: testOptionBySevice,
            isLoading: this.props?.servicesBasedOnCatAndPro?.loading,
            onSelectedCallback: this.updateServiceRow,
            isMulti: true,
            isMandatory: true,
            isDisabled: this.state.isDisableFields,
            isNotClearable: true,
          },
        },
      },
      section_2: {
        label: "",
        fields: {
          product_name: {
            // key: "product_name",
            key: "service",
            type: formComponentTypes.TABLE,
            id: {
              tax_id: "addTaxFromAddPackage",
              discountId: "addDiscountFromAddPackage",
            },
            // serviceFieldKey: "service", //To Identify the service drop to get an array of selected services
            componentProps: {
              type: "text",
              tableType: tableType.package,
              exoticRow: exoticRow,
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            value: isEdit ? editData?.services : this.state.services,
            removeUpdateKey: "service",
            columns: columns,
            rows: this.state.services,
            handleRowsReorder: this.handleRowsReorder,
            handleRemove: this.handleRemove,
            disableRemove: this.state.isDisableFields,
            discount: this.state.discount,
            tax: this.state.tax,
            taxType: this.state.taxType,
            taxTypeOptions: taxTypeOptions,
            total: this.state.total,
            onDiscountChange: this.onDiscountChange,
            onTaxChange: this.onTaxChange,
            onTaxTypeChange: this.onTaxTypeChange,
            onTotalChange: this.onTotalChange,
            onTextFieldChange: this.onTextFieldChange,
            handlePopperClose: this.handlePopperClose,
            handlePopperOpen: this.handlePopperOpen,
            currency: this.state.currency,
            openPopper: this.state.open,
            popperData: this.state.popperData,
            isMandatory: true,
            withDiscount: true,
            withTax: false,
            withTaxAndTaxType: true,
            withTotal: true,
            handleStrictStatusChange: this.handleStrictStatusChange,
            handleDependentServiceChange: this.handleDependentServiceChange,
          },
          // promotional_content: {
          //   key: "promotional_content",
          //   type: formComponentTypes.FILEUPLOADER,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 6,
          //     md: 6,
          //     lg: 6,
          //     xl: 6
          //   },
          //   label: "PROMOTIONAL CONTENT",
          //   // value: !isEdit ? "" : editData?.name,
          //   handleFileUpload: this.handleFileUpload,
          //   handleFileRemove: this.handleFileRemove,
          //   isMandatory: false
          // },
          // inclusions: {
          //   // key: "product_name",
          //   key: "inclusions",
          //   type: formComponentTypes.BUTTON_WITH_INPUT_FIELD,
          //   componentProps: {
          //     type: "text",
          //   },
          //   buttonLabel: "+ Add",
          //   breakPoints: {
          //     xs: 12,
          //     sm: 12,
          //     md: 12,
          //     lg: 12,
          //     xl: 12,
          //   },
          //   label: "INCLUSIONS",
          //   // editData?.details?.inclusion.split(',') // don't remove
          //   value: this.state.inclusions,
          //   // handleRemove: handleRemove,
          // },
          description: {
            key: "description",
            type: formComponentTypes.RICHTEXTEDITOR,
            id: "addDescriptionFromAddPackage",
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "DESCRIPTION",
            value: isEdit ? editData?.description : this.state?.description,
            isMandatory: false,
          },
          terms_conditions: {
            // key: "product_name",
            key: "terms_conditions",
            id: {
              text_id: "addTermsConditionsFromAddPackage",
              button_id: "addTermsConditionsButtonFromAddPackage",
            },
            type: formComponentTypes.BUTTON_WITH_INPUT_FIELD,
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            buttonLabel: "+ Add",
            label: "TERMS & CONDITIONS",
            // editData?.details?.terms_conditions.split(',') // don't remove
            value: isEdit
              ? editData?.terms_conditions
              : this.state.terms_conditions,
            // handleRemove: handleRemove,
          },
        },
      },
    };
    if (
      user_roles.provider === auth_data.user_role &&
      auth_data?.other_roles?.indexOf("cross-bundling") == -1
    ) {
      delete _json.section_1.fields.select_provider;
    }
    // _json.section_1.fields.select_provider.isMulti = false;
    // _json.section_1.fields.select_provider.value = isEdit ? editData?.select_provider?.[0] : this.state.provider?.[0];
    // return _json

    // super_admin MC user issues fix
    if (
      user_roles.super_admin === auth_data.user_role &&
      auth_data?.other_roles?.indexOf("cross-bundling") === -1
    ) {
      _json.section_1.fields.select_provider.isMulti = false;
      _json.section_1.fields.select_provider.value = isEdit
        ? Array.isArray(editData?.select_provider)
          ? editData?.select_provider?.[0]
          : editData?.select_provider
        : Array.isArray(this.state.provider)
        ? this.state.provider?.[0]
        : this.state.provider;
      // return _json
    }

    return _json;
  };

  handleStrictStatusChange = (index, key, value) => {
    let services;
    services = this.state.services.map((_, i) => {
      if (i === index) {
        return {
          ..._,
          [key]: value,
          dependentService: value ? [] : _.dependentService,
        };
      }
      return _;
    });

    this.setState({
      services: services,
    });
  };

  handleDependentServiceChange = (index, key, value) => {
    let services;
    services = this.state.services.map((_, i) => {
      if (i === index) {
        return {
          ..._,
          [key]: value?.map((_) => JSON.parse(_)),
        };
      }
      return _;
    });

    this.setState({
      services: services,
    });
  };

  render() {
    let {
      loading: editLoading,
      error: editError,
      data: editData,
    } = this.props?.packageInfo;

    let { isEdit } = this.props;
    // let { auth_data } = this.props;

    let { loading } = this.props?.addPackage;

    let { loading: updateLoading } = this.props?.updatePackageInfo;

    return (
      <div style={{ padding: 20 }}>
        {isEdit ? (
          <>
            {editLoading && (
              <LoadingSection message={"Loading package details..."} />
            )}
            {!editLoading && editError && (
              <InternatlServerError
                message={"Unable to fetch package details"}
                onRetry={this.getEssentials}
              />
            )}
            {!editLoading && !editError && !editData && (
              <NotFoundSection message={"404 - Package Detail Not Found."} />
            )}
            {!editLoading && !editError && editData && (
              <AddEditProductManagement
                components={this.components(editData)}
                onSaveButtonClicked={this.onSaveButtonClicked}
                loading={updateLoading || this.state.localPackageLoading}
                isUrl={true}
                saveButtonLabel={
                  updateLoading || this.state.localPackageLoading
                    ? "Updating..."
                    : "Update"
                }
                isResetState={this.state.isResetState}
                saveButtonId={"saveButtonFromAddPackage"}
                cancelButtonId={"cancelButtonFromAddPackage"}
              />
            )}
          </>
        ) : (
          <AddEditProductManagement
            components={this.components()}
            onSaveButtonClicked={this.onSaveButtonClicked}
            loading={loading || this.state.localPackageLoading}
            saveButtonLabel={
              loading || this.state.localPackageLoading ? "Saving..." : "Save"
            }
            isResetState={this.state.isResetState}
            saveButtonId={"saveButtonFromAddPackage"}
            cancelButtonId={"cancelButtonFromAddPackage"}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state?.masterSet?.categories,
  providerBasedOnCategory: state?.careProvider?.providerBasedOnCategory,
  servicesBasedOnCatAndPro: state?.dependency_api?.subscription_mapping,
  addPackage: state?.packageState?.addPackage,
  packageInfo: state?.packageState?.packageInfo,
  updatePackageInfo: state?.packageState?.updatePackageInfo,
  auth_data: state?.authState?.loginAuth?.data ?? {},
  frequency: state?.masterModule?.getfreq ?? {
    data: [],
    loading: false,
    error: false,
  },
  getValidity: state?.masterSet?.validity ?? null,
  product_management_group_types:
    state?.masterSet?.product_management_group_types.data ?? null,
  dropPartner: state?.careProvider?.dropPartner,
  dropResourceTypes: state?.careProvider?.dropResourceTypes,
  servicePackageByCatPat: state?.dependency_api?.servicePackageByCatPat,
  categoriesList: state?.careProvider?.catProvider,
  getPartnerActiveStatusBySupervisor:
    state?.resourceReducer?.getPartnerActiveStatusBySupervisor,
  taxTypeMaster: state?.masterTax?.get_tax_integration,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withWidth()(AddEditPackage))));
