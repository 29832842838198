import React from "react";
import { Button, makeStyles, withStyles } from "@material-ui/core";

export const CustomButton = withStyles((props) => {
  return {
    root: {},
  };
})((props) => <Button color="primary" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    width: "100%",
    gap: theme.spacing(1.5),
    margin: theme.spacing(1.5, 0),
    paddingRight: theme.spacing(1)
  },
}));

export function InfoCTA({ ctas, ...props }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {ctas?.map((cta, index) => {
        const {display, loading, loadingLabel="Loading.."} = cta?.additionalProps
        if (display) {
          return (
            <CustomButton
              key={cta?.label}
              id={cta?.id}
              onClick={cta?.onClickHandler}
              variant={cta?.additionalProps?.variant}
            >
              {loading ? loadingLabel : cta?.label}
            </CustomButton>
          );
        } else {
          return <></>;
        }
      })}
    </div>
  );
}
