import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStylesforCheckbox = makeStyles({
  root: {
    color: '#f27d35',
  }
});


//customized checkbox
function StyledCheckbox(props) {
  const classes = useStylesforCheckbox();
  // console.log('these are the props', props)
  return (
    <Checkbox
      classes={{ root: classes.root }}
      {...props}
    />
  );
}

//customized Autocomplete
const useStylesforAutocomplete = makeStyles(theme => ({
  rootAutoComplete: {
    marginTop: 12
  },
  inputRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 8
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#A4A6B3",
      borderRadius: 8
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f27d35",
      borderRadius: 8
    }
  },
  fixChipBorder: {
    borderRadius: `4px !important`,
    "&.MuiChip-deletable": {
      backgroundColor: '#f4f4f4'
    }
  },
  sectionName: {
    marginTop: 28,
    textTransform: 'uppercase'
  },
}))


export function AutocompleteInput(props) {
  const classes = useStylesforAutocomplete()
  // const allOptions = props.allOptions
  // const [value, setValue] = React.useState([]);

  // const handleChange = (e, newValue) => {
  //   newValue.map(item => {
  //     setValue([...value, item.title])
  //     return null
  //   })
  // }

  const { availableOptions, handleFilterTypeChange } = props

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary" className={classes.sectionName}>Activity</Typography>
      </Grid>
      <Grid item container xs={12}>
        <Autocomplete
          multiple
          className={classes.rootAutoComplete}
          limitTags={1}
          ChipProps={{ deleteIcon: <CloseIcon /> }}
          onChange={(event, newValue) => handleFilterTypeChange(event, newValue)}
          options={availableOptions}
          disableCloseOnSelect
          getOptionLabel={(option) => option.title}
          renderOption={(option, state) => (
            <React.Fragment>
              <StyledCheckbox
                color={"#f27d35"}
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={state.selected}
              />
              {option.title}
            </React.Fragment>
          )}
          style={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" placeholder="Select Activity"
            />
          )
          }
          classes={{
            inputRoot: classes.inputRoot,
            tag: classes.fixChipBorder
          }}
        />
      </Grid>
    </Grid >

  );
}

export default AutocompleteInput