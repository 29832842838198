import React, { useEffect } from "react";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
// import { NavLink } from "react-router-dom";
import { CustomAvatar, CustomTypography, HybridTypography } from "../../../../components";
import { ICONS } from "../../../../components/hybridTypography/typography";
import serviceCardStyle from "./serviceCardStyle.module.css";
// import Icon from "../../../../assets/product Management/listingScreen/premium.svg";
import { PopperListRenderer } from "../../../../components/hybridTypography/popperListRenderer";
import moment from "moment";
import { getSplicedData } from "../../../../utils";
import { StyledChip } from "../../../../components/styledChip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { isImageLinkBogus } from "../../../../utils/common.js";

const useStyles = makeStyles((theme) => ({
  cardPaper: {
    // marginBottom: "12px",
    borderRadius: "16px",
    boxShadow: theme.palette.primary.boxShadow,
  },
  serviceHeaderWrapper: {
    borderTopRightRadius: "16px",
    borderTopLeftRadius: "16px",
    padding: theme.spacing(1.5, 1.5, 0, 1.5),
  },
  serviceDescriptionWrapper: {
    borderBottomRightRadius: "16px",
    borderBottomLeftRadius: "16px",

  },
  noCardTop: {
    padding: 16,
    borderRadius: "0px",
    borderTopRightRadius: "16px",
    borderTopLeftRadius: "16px",
    borderBottom: "1px solid #f3f3f3",
    boxShadow: "0px 15px 25px #00000012",
  },
  noCardBottom: {
    padding: 16,
    borderRadius: "0px",
    borderBottomRightRadius: "16px",
    borderBottomLeftRadius: "16px",
    borderTop: "1px solid #f3f3f3",
    boxShadow: "0px 15px 25px #00000012",
  },
  avatarWrapper: {
    margin: "auto",
  },
  avatar: {
    width: "50px",
    height: "50px",
    margin: theme.spacing(0, 1),
    // margin: "auto auto auto 0",
  },

  rightPoleDetailsButton: {
    height: "100%",
    display: "flex",
  },
  flex: {
    display: "flex",
  },
  flexEnd: {
    justifyContent: "flex-end",
  },
  marginHeight: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  paddingRight: {
    paddingRight: "15px",
  },
  typoSpacing: {
    paddingBottom: 4,
    display: "flex",
  },
  user_name: {
    display: "inline-block",
    paddingRight: theme.spacing(1.5),
    fontSize: "1.1rem",
  },
  isactive: {
    display: "inline",
    fontSize: "1rem",
    color: theme.palette.success.main,
  },
  inactive: {
    display: "inline",
    fontSize: "1rem",
    color: theme.palette.error.main,
  },
  status_circle: {
    marginLeft: 10,
    width: 6,
    height: 6,
    borderRadius: "50%",
    position: "relative",
  },
  activecircle: {
    background: theme.palette.success.main,
  },
  inactivecircle: {
    background: theme.palette.error.main,
  },
  status_name: {
    display: "inline",
    paddingLeft: "8px",
    fontSize: "14px",
  },
  active_name: {
    color: theme.palette.success.main,
  },
  inactive_name: {
    color: theme.palette.error.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
    fontSize: "14px",
    paddingTop: "0px",
  },
  chipAsText: {
    backgroundColor: "transparent",
    paddingLeft: 0,
  },
  amountContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  descriptionViewMoreButton: {
    boxShadow: "unset !important",
    color: theme.palette.ternary.main,
  },
}));

export function ServiceCard({ serviceDetail: data, navigateToEdit, ...props }) {
  const classes = useStyles();
  const theme = useTheme();
  // const [fallbackImage, setFallbackImage] = React.useState(null);
  const [viewOpen, setViewOpen] = React.useState(false);
  const [descriptionHeight, setDescriptionHeight] = React.useState(0);
  const matches = useMediaQuery((theme) => theme.breakpoints.down(721));
  const isBelow400 = useMediaQuery((theme) => theme.breakpoints.down(401))
  useEffect(() => {
    setDescriptionHeight(document.getElementById("description")?.clientHeight);
  }, []);

  const getValidityRange = (from, to) => {
    let value = `${from ? moment(from).format("DD, MMM YYYY") : "N/A"} to ${to ? moment(to).format("DD, MMM YYYY") : "N/A"
      }`;
    return value;
  };


  console.log(data)

  // const avatarErrorHandler = () => {
  //   const image = "/images/tanyaCare/Tanya.png"
  //   setFallbackImage(image);
  // }

  return (
    <Paper
      // className={
      //   isonlycard
      //     ? isbottom
      //       ? classes.noCardBottom
      //       : classes.noCardTop
      //     : classes.cardPaper
      // }
      className={classes.cardPaper}
    >

      <div className={classes.serviceHeaderWrapper}>
        <Grid container style={{ marginBottom: theme.spacing(1.5) }}>
          {/* Left pole */}
          <Grid item container xs wrap={"nowrap"}>
            <Grid item className={classes.avatarWrapper}>
              {/* <Avatar
                className={classes.avatar}
                style={{
                  padding: data?.uploadImage?.length > 0 ? 0 : 8,
                }}
                src={!fallbackImage ? isImageLinkBogus(data?.uploadImage) || data?.uploadImage?.length === 0 ? "/images/tanyaCare/Tanya.png" : data?.uploadImage : fallbackImage}
                imgProps={{
                  onError: avatarErrorHandler,
                }}
              >
              </Avatar> */}
              <CustomAvatar src={data?.uploadImage} name={data?.resourceName} fallbackAsImage />
            </Grid>

            <Grid item xs container alignItems={"center"}>
              <Grid item xs={12}>
                <div className={classes.typoSpacing}>
                  <Typography className={classes.user_name}>
                    {data?.resourceName ? data?.resourceName : "N/A"}
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className={`${classes.status_circle} ${data?.isActive
                        ? classes.activecircle
                        : classes.inactivecircle
                        }`}
                    ></div>
                    <Typography
                      className={`${classes.status_name} ${data?.isActive
                        ? classes.active_name
                        : classes.inactive_name
                        }`}
                    >
                      {data?.isActive ? "ACTIVE" : "INACTIVE"}
                    </Typography>
                  </div>
                </div>
              </Grid>

              <Grid item container>

                {/* Render this grid only if the selected category is available and its length is more than 0 */}
                {data?.selectedCategory && data?.selectedCategory?.length > 0 && (
                  <Grid item>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {data?.selectedCategory?.map((category, index) => {
                        if (index < 2) {
                          return (
                            <StyledChip
                              label={category?.label ? category?.label : "N/A"}
                              style={{ marginRight: index >= 2 ? 0 : 8 }}
                              maxWidth={190}
                            />
                          );
                        } else {
                          return <></>;
                        }
                      })}
                      {data?.selectedCategory?.length > 2 && (
                        <PopperListRenderer
                          popperList={getSplicedData(
                            data?.selectedCategory,
                            2
                          )?.map((x) => (x?.label ? x?.label : "N/A"))}
                          count={
                            getSplicedData(data?.selectedCategory, 2)?.length
                          }
                        />
                      )}
                    </div>
                  </Grid>
                )}

                <Grid item style={{ margin: "0px 12px" }}>
                  <Divider orientation="vertical" light />
                </Grid>

                {/* row 2 provider name */}
                {data?.selectedProvider && data?.selectedProvider?.length > 0 && (
                  <Grid item zeroMinWidth>
                    {/* <div style={{ display: "flex", alignItems: "center" }}>
                      {data?.selectedProvider?.map((provider, index) => {
                        if (index < 2) {
                          return (
                            <> */}
                    {data?.selectedProvider?.map((provider, index) =>
                    (<Typography style={{
                      // marginRight: index >= 2 ? 0 : 8,
                      fontSize: "0.8125rem", display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>{provider?.label ? provider?.label : "N/A"}</Typography>)
                    )}

                    {/* {index < 1 &&
                                data?.selectedProvider?.length - 1 !== index && (
                                  <Divider
                                    orientation="vertical"
                                    light
                                    flexItem
                                    style={{
                                      margin: index >= 2 ? 0 : "0px 6px",
                                    }}
                                  />
                                )} */}
                    {/* </>
                          );
                        } else {
                          return <></>;
                        }
                      })}

                      {data?.selectedProvider?.length > 2 && (
                        <PopperListRenderer
                          popperList={getSplicedData(
                            data?.selectedProvider,
                            2
                          )?.map((x) => (x?.label ? x?.label : "N/A"))}
                          count={
                            getSplicedData(data?.selectedProvider, 2)?.length
                          }
                        />
                      )}
                    </div> */}
                  </Grid>
                )}

                {data?.aliasId && <Grid item container xs>
                  <>
                    <Grid item style={{ margin: "0px 12px" }}>
                      <Divider orientation="vertical" light />
                    </Grid>
                    <Grid item>
                      <CustomTypography variant="row2">
                        {`Service ID : ${data?.aliasId}`}
                      </CustomTypography>
                    </Grid>
                  </>
                </Grid>}
              </Grid>


            </Grid>
          </Grid>

          {/* Right Pole */}
          {(!props?.isbuttonHide && data.eligibleToEdit) && (
            <>
              {!matches && <Grid item justify={"flex-end"}>
                <Grid item container xs>
                  <Grid
                    item
                    xs
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-start",
                    }}
                  >
                    <Button
                      disabled={props.statusChangeLoading}
                      variant="outlined"
                      color="primary"
                      onClick={props.onStatusChangeClick}
                      style={{
                        marginRight: 20,
                        // marginRight: matches ? 0 : 20,
                        // marginBottom: matches ? 12 : 0,
                      }}
                      id={`ActivateDeactiateButtonFrom${props?.id}`}
                    >
                      {data?.isActive
                        ? `${props.statusChangeLoading
                          ? "Deactivating..."
                          : "Deactivate"
                        }`
                        : `${props.statusChangeLoading
                          ? "Activating..."
                          : "Activate"
                        }`}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: "20px" }}
                      onClick={navigateToEdit}
                      id={`EditDetailButtonFrom${props?.id}`}
                    >
                      Edit Details
                    </Button>
                  </Grid>
                </Grid>
              </Grid>}
              {matches && <Grid item container xs={12} sm={12} justify={"flex-end"}>
                {!isBelow400 && <Grid item className={classes.avatarWrapper}>
                  <Avatar
                    className={classes.avatar}
                    style={{ opacity: 0 }}
                  ></Avatar>
                </Grid>}

                <Grid item container xs style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                }}>
                  <Grid
                    item
                    xs={isBelow400 ? 12 : 6}
                    style={{
                      display: "flex",
                      justifyContent: isBelow400 ? "flex-start" : "flex-end",
                      alignItems: "flex-start",
                    }}
                  >
                    <Button
                      disabled={props.statusChangeLoading}
                      variant="outlined"
                      color="primary"
                      onClick={props.onStatusChangeClick}
                      style={{
                        marginRight: isBelow400 ? 0 : 20,
                        marginTop: 8
                      }}
                      fullWidth={isBelow400 ? true : false}
                      id={`ActivateDeactiateButtonFrom${props?.id}`}
                    >
                      {data?.isActive
                        ? `${props.statusChangeLoading
                          ? "Deactivating..."
                          : "Deactivate"
                        }`
                        : `${props.statusChangeLoading
                          ? "Activating..."
                          : "Activate"
                        }`}
                    </Button>
                  </Grid>
                  <Grid item xs={isBelow400 && 12}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: isBelow400 ? 0 : 20, marginTop: 8 }}
                      onClick={navigateToEdit}
                      fullWidth={isBelow400 ? true : false}
                      id={`EditDetailButtonFrom${props?.id}`}
                    >
                      Edit Details
                    </Button>
                  </Grid>
                </Grid>
              </Grid>}
            </>
          )}
        </Grid>
      </div>

      {/* <Divider light style={{background: "#f3f3f3", height: 1}}/> */}

      <div className={classes.serviceDescriptionWrapper} style={{ padding: isBelow400 ? theme.spacing(0, 4, 2, 4) : theme.spacing(0, 1.5, 2, 1.5) }} >
        <Grid container>
          {/* Left pole */}
          <Grid item container xs={12} sm>
            {!isBelow400 && <Grid item className={classes.avatarWrapper}>
              <Avatar
                className={classes.avatar}
                style={{ opacity: 0 }}
              ></Avatar>
            </Grid>}

            <Grid container item xs>
              <Grid item xs={12}>
                <div className={classes.amountContainer}>
                  <img
                    src={"/svg/Price.svg"}
                    height={14}
                    width={14}
                    alt={"price-tag"}
                    style={{ marginRight: 8 }}
                  />

                  <Typography
                    variant={"h5"}
                    style={{ fontWeight: "bold", marginRight: 4 }}
                  >
                    {data?.currency ? data?.currency : "N/A"}{" "}
                    {data?.price ? data?.price : "N/A"}{" "}
                  </Typography>
                  <Typography variant={"body1"}>
                    {data?.frequency?.label
                      ? "(" + data?.frequency?.label + ")"
                      : ""}
                  </Typography>
                </div>
              </Grid>
              <Grid item container xs={12}>
                {/* <RichTextEditor isReadOnly /> */}
                {data?.description && (
                  <Grid item xs={12}>
                    <div
                      id={"description"}
                      className={!viewOpen ? serviceCardStyle.description : ""}
                      style={{
                        width: "100%",
                        maxHeight: !viewOpen ? 140 : "unset",
                        maxWidth: "90%",
                        overflow: !viewOpen ? "hidden" : "unset",
                        transition: "max-height 0.2s ease-out",
                        overflowWrap: "anywhere",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: data?.description,
                      }}
                    ></div>
                  </Grid>
                )}
                {descriptionHeight > 139 && (
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      color={"primary"}
                      variant={"text"}
                      // style={{ justifySelf: "center" }}
                      classes={{ root: classes.descriptionViewMoreButton }}
                      onClick={() =>
                        setViewOpen((prevViewOpen) => !prevViewOpen)
                      }
                      id={`ViewMoreButtonFrom${props?.id}`}
                    >
                      {!viewOpen ? "+ View More" : "- View Less"}
                    </Button>
                  </Grid>
                )}
              </Grid>

              {/* <Grid item xs={12}>
              <Divider></Divider>
              </Grid> */}

              {/* Validity Period */}
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
              // className={classes.bottomSectionContainer}
              >
                <HybridTypography
                  icon={ICONS.BUSINESS(theme.palette.primary.main)}
                  loading={false}
                  text={
                    data?.effectiveFrom && data?.effectiveTo
                      ? `${getValidityRange(
                        data?.effectiveFrom,
                        data?.effectiveTo
                      )} ${data?.validityPeriod?.label
                        ? `(${data?.validityPeriod?.label})`
                        : ""
                      }`
                      : "N/A"
                  }
                  // subtext={"(12,Feb 2020 to 10,Dec 2020)"}
                  header="VALIDITY PERIOD"
                  islisttop={false}
                  isListMiddle={true}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <HybridTypography
                  icon={ICONS.TC(theme.palette.primary.main)}
                  loading={false}
                  stringlist={
                    data?.termsAndConditions &&
                      data?.termsAndConditions?.length > 0
                      ? data?.termsAndConditions
                      : []
                  }
                  header="TERMS AND CONDITIONS"
                  textVariant={"body1"}
                  islisttop={false}
                  isListMiddle={true}
                />
              </Grid>
              {data?.promotionalContent &&
                <Grid item xs={12} sm={6} md={4}>
                  <HybridTypography
                    icon={ICONS.TC(theme.palette.primary.main)}
                    loading={false}
                    text={
                      data?.promotionalContent
                    }
                    link
                    extension={data?.promotionalFileType}
                    header="PROMOTIONAL CONTENT"
                    textVariant={"body1"}
                    islisttop={false}
                    isListMiddle={true}
                    id={`PromotionalContentButtonFrom${props?.id}`}
                  />
                </Grid>}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
