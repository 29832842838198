import * as React from "react"

export function MiniGraphTwo(props) {
  return (
    <svg width={79.37} height={65} viewBox="0 0 79.37 65" {...props}>
      <defs>
        <style>
          {
            ".prefix__c,.prefix__e{fill:none}.prefix__b,.prefix__c{stroke:#f27d35}.prefix__b{fill:#fff}.prefix__c{stroke-width:.5px;opacity:.48}.prefix__d{stroke:none}"
          }
        </style>
      </defs>
      <path
        d="M3.59 46.591l13.308-3.51h0l10.887 5.5 6.339-9.178 12.19-2.685 5.788-8.441s8.4-1.439 8.829-1.58 6.506-9.942 6.506-9.942l5.358-8.105 2.995-5.014"
        strokeLinecap="round"
        strokeWidth={2}
        stroke="#f27d35"
        fill="none"
      />
      <g className="prefix__b" transform="translate(71.941)">
        <circle className="prefix__d" cx={3.714} cy={3.714} r={3.714} />
        <circle className="prefix__e" cx={3.714} cy={3.714} r={3.214} />
      </g>
      <g className="prefix__b" transform="translate(0 42)">
        <circle className="prefix__d" cx={4} cy={4} r={4} />
        <circle className="prefix__e" cx={4} cy={4} r={3.5} />
      </g>
      <path
        className="prefix__c"
        d="M9.784 43.952V65M14.736 43.952V65M19.689 43.952V65M24.641 46.429V65M29.593 45.19V65M34.546 40.238V65M39.499 37.762V65M44.451 37.762V65M49.403 32.81V65M54.355 27.857V65M59.308 27.857V65M64.26 20.429V65M69.212 15.476V65M74.165 6.81V65"
      />
    </svg>
  )
}
