import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Paper, withStyles, withTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { HistoryIcon } from '../../../../assets'
import OperationalHours from '../../../../components/managedAvailability/operationalHours';
import NonOperationalDays from '../../../../components/nonOperationalDays';
import ExceptionAccordion from '../../../../components/managedAvailability/exceptions'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 20
  },
  detailSection: {
    marginTop: 20
  },
  customActionButtons: {
    padding: 8,
    border: '0.5px solid #e9e9e9'
  },
  accordions: {
    marginTop: 20,
    background: "white",
    "&.MuiAccordion-root:before": {
      opacity: 0
    }
  }
}));

const CustomAccordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04) !important",
    '&:not(:last-child)': {
      borderBottom: 0,
    },
  },
})(Accordion);

const CustomAccordionSummary = withStyles((theme) => ({
  root: {
    padding: 20,
    '&.Mui-expanded': {
      borderBottom: `0.3px dashed rgba(0, 0, 0, 0.1)`,
    }
  },
  content: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0
    }
  }
}))((props) => <AccordionSummary {...props} />);

const CustomAccordionDetails = withStyles((theme) => ({
  root: {
    padding: "20px 20px 28px 20px",
  },
}))((props) => <AccordionDetails {...props} />);


const handleNonOperationalDays = () => {

}


function ViewAvailabilityHistory(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const exceptionList = [
    {
      days: ["Monday", "Tuesday", "Wednesday"],
      based_on: "Individuals",
      data: {
        service_coordinator: {
          label: "SERVICE COORDINATOR",
          value: ["Sarah Najwa", "Mohamed Javid", "Adam John", "Irdina Maryam", "Zikri Ahammed"]
        },
        service_professional: {
          label: "SERVICE PROFESSIONAL",
          value: ["Sarah Najwa", "Mohamed Javid", "Adam John", "Irdina Maryam", "Zikri Ahammed", "Sarah Najwa", "Mohamed Javid", "Adam John"]
        },
        category: {
          label: "CATEGORY",
          value: ["Health Care", "Physio"]
        },
        time_slot: {
          label: "TIME SLOT",
          value: ["9 AM - 12 PM (IST)", "1 PM - 6 PM (IST)"]
        },
      }
    },
    {
      days: ["Thursday", "Friday"],
      based_on: "Category",
      data: {
        category: {
          label: "CATEGORY",
          value: ["Health Care", "Physio"]
        },
        time_slot: {
          label: "TIME SLOT",
          value: ["9 AM - 12 PM (IST)", "1 PM - 6 PM (IST)"]
        },
      }
    },
  ]

  const history = ["Aug 14, 2020", "Aug 11, 2020", "Aug 9, 2020", "Aug 7, 2020"]


  return (
    <div className={classes.root}>

      {
        history.map((accord, index) => (
          <CustomAccordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} className={index !== 0 ? classes.accordions : null} style={{ borderRadius: 8 }}>

            <CustomAccordionSummary>
              <Grid container alignItems="center">
                <Grid item container xs={9}>
                  <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <HistoryIcon />
                  </Grid>
                  <Grid item xs>
                    <Typography style={{ marginLeft: 20 }}>{accord}</Typography>
                  </Grid>
                </Grid>
                {/* <Grid item container xs></Grid> */}
                <Grid item container xs={3} justify="flex-end">
                  <Grid item>
                    <IconButton classes={{ root: classes.customActionButtons }} >
                      <ExpandMoreIcon fontSize="small" style={{ transform: expanded === `panel${index}` ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </CustomAccordionSummary>

            <CustomAccordionDetails>
              {/* TODO: While mapping apply class ```detailSection``` to the grid items except the first one */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Operational Hours</Typography>
                </Grid>

                <Grid item md={10} lg={8} >
                  <OperationalHours />
                </Grid>

                <Grid item xs={12} style={{ marginTop: 8 }}>
                  <Typography variant="h6">Holidays</Typography>
                </Grid>

                {/* Added margin bottom temporarily on November 10 */}
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                  <NonOperationalDays handleChange={handleNonOperationalDays} hideAddHolidayClickHandler={true} />
                </Grid>


                {
                  exceptionList.map((exception, index) => (
                    <Grid item container className={index !== 0 ? classes.accordions : null}>
                      <Grid container item xs={12}>
                        <Grid item xs={12}>
                          <Paper>
                            <ExceptionAccordion exceptionData={exception} hideEdit={true} hideDelete={true} />
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
                }

              </Grid>

              {/* <Grid container> */}
              {/* Map the different exception accordion here */}
              {/* TODO: While mapping apply class ```accordions``` to the grid items except the first one */}

              {/* </Grid> */}
            </CustomAccordionDetails>

          </CustomAccordion>
        ))
      }

    </div>
  );
}

export default withTheme(ViewAvailabilityHistory)
