import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: "none",
    stroke: props.color || "#9297a5",
    strokeMiterlimit: 10,
    strokeWidth: 2
  })
}))

export const DashboardNewIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon fontSize={'small'} htmlColor={props.color}>
      {/* <defs>
        <style>
          .cls-1{fill:none;stroke:#9297a5;stroke-miterlimit:10;stroke-width:2px}
        </style>
      </defs> */}
      <g id="Dashboard" transform="translate(-2 -2)">
        <path id="Path_542" d="M4 12h5a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1z" className={classes.cls1} data-name="Path 542" />
        <path id="Path_543" d="M15 8h5a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1z" className={classes.cls1} data-name="Path 543" />
        <path id="Path_544" d="M4 21h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1z" className={classes.cls1} data-name="Path 544" />
        <path id="Path_545" d="M15 21h5a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1z" className={classes.cls1} data-name="Path 545" />
      </g>
    </SvgIcon >
  )
}

DashboardNewIcon.propTypes = {
  color: PropTypes.string,
};