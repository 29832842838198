import React from "react";
import AddEditForm from "../addEditForm";
import {
  formComponentTypes,
  alertProps,
  // timezoneOld as timezone
} from "../../utils";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
// import ConflictList from "../../conflictList";

class EditAddressComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {

    };
  }

  componentDidMount() {
    this.getActivityData()
  }

  getActivityData = () => {

    this.props.GET_MASTER_ADDRESS_TYPE({});
  };

  onSaveButtonClicked = (isError, data, isUrl) => {
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      if (this.props?.editData) {
        data.id = this.props?.editData.id
      }
    //   this.props.callBackOnSave(data)  //------- Call the Action
    }
  };

  onCancelButtonClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  render() {
    let isEdit = this.props.isEdit;
    let list = this.props.list;
    let selectedList = []
    let data = this.props?.editData;

    if (list && list.length > 0) {
      selectedList = list.map(val => val.address_type.value)
    }
    if(isEdit){
      if (data  && selectedList.length > 0) {
        selectedList = selectedList.filter(val => val !== data.value)
      }
    }

    const components = {
      section_1: {
        label: !isEdit ? "Add Address" : "Edit Address",
        fields: {
          address_type: {
            key: "address_type",
            type: formComponentTypes.SELECT_BOX,
            componentProps: {},
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "Address Type",
            value: !isEdit ? "" : this.props.addressType?.data?.filter(x => x.value === data?.value)[0],
            options: this.props.addressType?.data
              ? selectedList.length > 0 ? this.props.addressType?.data.filter(val => selectedList.indexOf(val.value) === -1)
                : this.props.addressType?.data
              : [],
            isMandatory: true,
          },
          addressLine1: {
            key: "addressLine1",
            type: formComponentTypes.INPUT_BOX,
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "ADDRESS LINE 1",
            value: !isEdit ? "" : data?.addressLine1,
            isMandatory: true,
          },
          addressLine2: {
            key: "addressLine2",
            type: formComponentTypes.INPUT_BOX,
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "ADDRESS LINE 2",
            value: !isEdit ? "" : data?.addressLine2,
            // isMandatory: true,
          },
          area: {
            key: "area",
            type: formComponentTypes.INPUT_BOX,
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "CITY",
            value: !isEdit ? "" : data?.area,
            isMandatory: true,
          },
          state: {
            key: "state",
            type: formComponentTypes.INPUT_BOX,
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "STATE",
            value: !isEdit ? "" : data?.state,
            isMandatory: true,
          },
          country: {
            key: "country",
            type: formComponentTypes.INPUT_BOX,
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "COUNTRY",
            value: !isEdit ? "" : data?.country,
            isMandatory: true,
          },
          postalCode: {
            key: "postalCode",
            type: formComponentTypes.INPUT_BOX,
            componentProps: {
              type: "number",
            },
            limit: 10,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "POSTAL CODE",
            value: !isEdit ? "" : data?.postalCode,
            isMandatory: true,
          },
        },
      },
      
    };
    return (
      <>
        {isEdit ? (
          <>
            <AddEditForm
              components={components}
              onSaveButtonClicked={this.onSaveButtonClicked}
              cancelForm={this.onCancelButtonClicked}
              // loading={updateLoading}
              isUrl={true}
              saveButtonLabel={"Update"}
              activityEdit={isEdit}
            />
          </>
        ) : (
            <AddEditForm
              components={components}
              onSaveButtonClicked={this.onSaveButtonClicked}
              cancelForm={this.onCancelButtonClicked}
              // loading={addLoading}
              saveButtonLabel={"Add Address"}
              withoutBoxShadow={true}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addressType: state?.masterSet?.addressType,
});

// export default AddEditAvailabiity
export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(EditAddressComponent));
