import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { CustomTooltip } from './tooltip';
import { ShapesIcon, StarIcon, TextIcon } from './svg';
import PropTypes from 'prop-types';

const styles = makeStyles((theme)=>({
    popper:{
        left:'23px !important'
    },
    icon: {
        padding: theme.spacing(2) + theme.spacing(1 / 2),
        cursor: 'pointer',
        display:'flex',
    },
    menu: {
        backgroundColor: '#EDF5FF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom:theme.spacing(3),
        [theme.breakpoints.down('sm')]:{
            flexDirection:'row'
        },
    },
}))

export const Menu = (props) =>{

    const {
        handleChange=null,
        activeMenu=""
    } = props;

    const classes = styles(props);

    return(
        <div className={classes.menu}>
             <CustomTooltip title="Shapes" popperClassName={classes.popper}>
                <div className={classes.icon} onClick={() => handleChange('shapes')}>
                    <ShapesIcon color={activeMenu === "shapes" ? "#0063e7" : "#3b3f5b"} />
                </div>
            </CustomTooltip>
            <CustomTooltip title="Icon" popperClassName={classes.popper}>
                <div className={classes.icon} onClick={() => handleChange('star')}>
                    <StarIcon color={activeMenu === "star" ? "#0063e7" : "#3b3f5b"} />
                </div>
            </CustomTooltip>
            <CustomTooltip title="Text" popperClassName={classes.popper}>
                <div className={classes.icon} onClick={() => handleChange('text')}>
                    <TextIcon color={activeMenu === "text" ? "#0063e7" : "#3b3f5b"} />
                </div>
            </CustomTooltip>
        </div>
    )
}

Menu.propTypes={
    handleChange:PropTypes.func,
    activeMenu:PropTypes.string
}