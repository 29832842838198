
import React from 'react';
import { makeStyles, Dialog, DialogContent, DialogActions, Button, DialogTitle, Divider } from '@material-ui/core';
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    root: {
    },
    dialog: { minWidth: 600 },
    heightDialog: { minWidth: 600, minHeight: 700, overflowY: 'auto', maxHeight: 700 },

}))

export const ArchivedDialog = (props) => {

    const classes = useStyles();

    const [open, setOpen] = React.useState(props?.open);

    React.useEffect(() => {
        setOpen(props?.open)
        // eslint-disable-next-line
    }, [props?.open])


    const handleClose = () => {
        setOpen(false);
        if (props?.negativeButton) {
            props.negativeButtonAction()
        }
    };

    return <div className={classes.root}>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
            classes={
                {
                    paper: props?.height ? classes.heightDialog : classes.dialog
                }
            }
        >
            <DialogTitle id="form-dialog-title">{props?.dialogTitle}</DialogTitle>
            <Divider />
            <DialogContent>
                {props?.dialogContent}
            </DialogContent>
            <DialogActions>
                {
                    props?.negativeButton &&
                    <Button onClick={handleClose} color="primary">{props?.negativeButtonText}</Button>
                }
                {
                    props?.positiveButton &&
                    <Button onClick={props?.positiveButtonAction ?? false} color="primary">{props?.positiveButtonText}</Button>
                }
            </DialogActions>
        </Dialog>

    </div>
}

ArchivedDialog.propTypes = {
    dialogTitle: PropTypes.string,
    dialogContent: PropTypes.node,
    negativeButton: PropTypes.bool,
    negativeButtonText: PropTypes.string,
    negativeButtonAction: PropTypes.func,
    positiveButton: PropTypes.bool,
    positiveButtonText: PropTypes.string,
    positiveButtonAction: PropTypes.func,
    height: PropTypes.bool,
}

ArchivedDialog.defaultProps = {
    dialogTitle: 'Title',
    negativeButtonText: 'Cancel',
    positiveButtonText: 'Ok'
}