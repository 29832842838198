import React from "react";
import {
  Grid,
  Typography,
  Button,
  withStyles,
  Divider,
  // IconButton,
  TextField,
  makeStyles,
  //  Divider
} from "@material-ui/core";
// import CardList from "../../../../components/userList";
import DetailsCard from "./detailCard";
import { routes } from "../../../../router/routes";
import { withRouter } from "react-router-dom";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import {
  alertProps,
  converToReactSelect,
  scrollToTop,
  user_roles,
  // weekDays,
} from "../../../../utils";
import {
  checkUrl,
  providerActivationSuccessMessage,
  providerDetailErrorMessage,
  providerDetailSuccessMessage,
} from "../../utils";
// import AvatarStack from '../../../../components/avatarStack'
import OperationalHours from "../../../../components/managedAvailability/operationalHours";
import NonOperationalDays from "../../../../components/nonOperationalDays";
// import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import ViewServiceCoordinators from "./ViewServiceCoordinators";
// import ViewServiceProfessionals from './ViewServiceProfessional'
import AvatarStack from "../../../../components/avatarStack";
import ViewProviderBranches from "./ViewProviderBranches";
// import SimpleAvatarCard from "../../../../components/simpleAvatarCard";
import {
  DateTimePickersCustom,
  HybridTypography,
} from "../../../../components";
import { ICONS } from "../../../../components/hybridTypography/typography";
import { viewScreensBasedOnPersonaByResourceTypeId } from "../../../resourceManagement/resources/utils";
import { Information } from "../../../../components/information";
// import { ICONS } from "../../../../components/hybridTypography/typography";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
// import { Clear as ClearIcon } from "@material-ui/icons";
import { MobileDatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isTrialAccountType,checkTrialExpiryRole } from "../../../../utils/common";

// const sizes = {
//   md: 12,
//   sm: 6,
//   xs: 12,
// };

const styles = (theme) => ({
  sectionContainer: {
    padding: "15px 25px 20px 32px",
    background: "white",
  },
  bottomSectionContainer: {
    padding: "15px 25px 20px 32px",
    background: "white",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  sectionColumnTwo: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4),
    },
  },
  customMarginSupervisor: {
    marginTop: 15,
    [theme.breakpoints.down("md")]: {
      marginTop: 12,
    },
  },
  calendarIconRoot: {
    fontSize: "1.05rem",
  },
  sectionTitle: {
    marginBottom: 20,
    fontSize: "1.1rem",
  },
  operationalHoursContainer: {
    width: "75%",
    [theme.breakpoints.only("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.between("xs", 800)]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  endDateClear: {
    color: theme.palette.ternary.main,
  },
});

class ProviderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Activation states
      effectiveFrom: null,
      endsOn: null,

      // Holiday States
      selectedMonth: parseInt(moment(Date.now()).format("M")),
      selectedYear: parseInt(moment(Date.now()).format("YYYY")),
    };
  }
  componentDidMount() {
    this.getCareProviderDetail();
    try {
      this.props.GET_OPERATIONAL_HOURS({
        providerId: this.props?.match?.params?.id,
      });
      this.setState({
        actionError: false,
      });
    } catch (err) {
      this.setState({
        actionError: true,
      });
    }
    scrollToTop();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.getCareProviderDetail();
      scrollToTop();
    }
  }
  getCareProviderDetail = async () => {
    const id = this.props?.match?.params?.id;

    await this.props.GET_CARE_PROVIDER_INFO({ id });

    const { data } = this.props.careProviderInfo;
    const effectiveFrom =
      data[0]?.partnerMeta?.["resource_validity"]?.value?.[
        data[0]?.partnerMeta?.["resource_validity"]?.key?.["startDate"]
      ] ?? null;
    const endsOn = Boolean(
      data[0]?.partnerMeta?.["resource_validity"]?.value?.[
        data[0]?.partnerMeta?.["resource_validity"]?.key?.["endDate"]
      ]
    )
      ? data[0]?.partnerMeta?.["resource_validity"]?.value?.[
          data[0]?.partnerMeta?.["resource_validity"]?.key?.["endDate"]
        ]
      : null;
    this.setState({
      effectiveFrom,
      endsOn,
    });
  };

  onDeactivateClicked = () => {
    const Oldstatus = this.props?.careProviderInfo?.data?.[0]?.isActive;

    let status = "Activate";
    if (Oldstatus) {
      status = "Deactivate";
    }
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body:
        "Do you want to " +
        status +
        " Provider : " +
        this?.props?.careProviderInfo?.data?.[0]?.partnerName,
      positiveBtn: status,
      negativeBtn: "Cancel",
      onOk: this.deactiveUser,
    });
  };

  deactiveUser = () => {
    const id = this.props?.match?.params?.id;
    const Oldstatus = this.props?.careProviderInfo?.data?.[0]?.isActive;
    // let newStatus = true;
    // if (Oldstatus === true) {
    //   newStatus = false;
    // }
    this.props.dialog.setDialog({ open: false });

    this.props.UPDATE_CARE_PROVIDER_STATUS({ id, status: !Oldstatus });
  };

  handleResend = async () => {
    const id = this.props?.match?.params?.id;
    let role = "partner";
    let data = await this.props.RESEND_INVITE({ user_id: id, role });
    if (data?.payload?.state === 200) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Invite Sent successfully!",
        severity: alertProps.severity.success,
      });
    } else {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Something went wrong! Unable to invite.",
        severity: alertProps.severity.error,
      });
    }
  };

  giveMeNecessaryData = () => {
    const { location, careProviderInfo } = this.props;

    const { data } = careProviderInfo;

    let necessaryData = {
      ...careProviderInfo,
    };

    if (location?.state) {
      necessaryData = {
        ...location.state,
        ...careProviderInfo,
      };
    }

    if (data) {
      const _ = data[0];

      const header = [
        {
          id: _?.partnerId,
          name: _?.partnerName,
          category: converToReactSelect(
            _?.partnerCategory,
            "categoryId",
            "categoryName"
          ),
          isactive: _?.isActive,
          emailVerified: _?.emailVerified,
          profile_pic: _?.["partnerMeta"]["uploadImage"]["value"],
          aliasId: _?.aliasId,
          aliasLabel:
            routes.partner_management_branch_detail === this.props.match.path
              ? "Branch ID"
              : "Partner ID",
          trialExpiry: _?.partnerMeta?.resource_validity?.value?.resourceEndsOn ?? ""
        },
      ];

      // const footer = [
      //   {
      //     name: `${_.care_giver_count ? _.care_giver_count : "0"} Service Professionals`,
      //   },
      // ];

      necessaryData = {
        ...careProviderInfo,
        header,
        // footer,
      };
    }

    return necessaryData;
  };

  handleAddServiceProfessional = () => {
    let { data } = this.giveMeNecessaryData();
    this.props.history.push({
      pathname: routes.add_care_givers,
      state: {
        provider: { label: data.name, value: data.id },
        category: data?.category,
      },
    });
  };
  shouldComponentUpdate(nextProps, nextState) {
    const { updateCareProviderStatus } = nextProps;
    const oldUpdateCareProviderStatus = this.props.updateCareProviderStatus;

    if (
      oldUpdateCareProviderStatus?.loading !== updateCareProviderStatus?.loading
    ) {
      if (
        !updateCareProviderStatus.loading &&
        !updateCareProviderStatus.error
      ) {
        this.getCareProviderDetail();
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: providerDetailSuccessMessage(this.props.match.path),
          severity: alertProps.severity.success,
        });
      } else if (updateCareProviderStatus.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: updateCareProviderStatus.message
            ? providerDetailErrorMessage(
                this.props.match.path,
                updateCareProviderStatus.message
              )
            : providerDetailErrorMessage(this.props.match.path),
          severity: alertProps.severity.error,
        });
      }
    }

    // Updated Provider Detail
    const { updateCareProvider } = nextProps;
    const oldUpdateCareProvider = this.props.updateCareProvider;

    if (oldUpdateCareProvider?.loading !== updateCareProvider?.loading) {
      if (!updateCareProvider.loading && !updateCareProvider.error) {
        this.getCareProviderDetail();
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: providerActivationSuccessMessage(this.props.match.path),
          severity: alertProps.severity.success,
        });
        this.props.dialog.setDialog({ open: false });
      } else if (updateCareProvider.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: updateCareProvider.message
            ? providerDetailErrorMessage(
                this.props.match.path,
                updateCareProvider.message
              )
            : providerDetailErrorMessage(this.props.match.path),
          severity: alertProps.severity.error,
        });
        this.revertDateChanges();
      }
    }

    return true;
  }

  revertDateChanges = () => {
    let { effectiveFrom, endsOn } = this.state;
    const { data } = this.props?.careProviderInfo;
    const effectiveFromDb =
      data[0]?.partnerMeta?.["resource_validity"]?.value?.[
        data[0]?.partnerMeta?.["resource_validity"]?.key?.["startDate"]
      ];
    const endsOnDb =
      data[0]?.partnerMeta?.["resource_validity"]?.value?.[
        data[0]?.partnerMeta?.["resource_validity"]?.key?.["endDate"]
      ];

    if (!moment(effectiveFrom)?.isSame(effectiveFromDb)) {
      effectiveFrom = effectiveFromDb;
    }
    if (!moment(endsOn)?.isSame(endsOnDb)) {
      endsOn = endsOnDb;
    }

    this.setState({
      effectiveFrom,
      endsOn,
    });
  };

  onClickIcon = (id, isCareGiver, data) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
    this.props.history.push(
      `${viewScreensBasedOnPersonaByResourceTypeId(data.resourceTypeId)}${
        data.id
      }`
    );
  };

  onEndIconClickHandler = (id, isCareGiver) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
    this.props.history.push(
      `${routes.partner_management_branch_detail}/view/${id}`
    );
  };

  onEndIconClickHandlerBasedOnRoutes = (data, isCareGiver) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
    this.props.history.push(
      `${viewScreensBasedOnPersonaByResourceTypeId(data.data.resourceTypeId)}${
        data.id
      }`
    );
  };

  handleServiceProfessionalsViewAllClick = (data) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewProviderBranches
          data={data?.[0]?.partnerBranch ?? []}
          drawer={this.props.drawer}
          onEndIconClickHandler={this.onClickIcon}
        />
      ),
    });
  };
  handleServiceCoordinatorsViewAllClick = (data) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewServiceCoordinators
          label={data?.label}
          data={data?.value ?? []}
          drawer={this.props.drawer}
          onEndIconClickHandler={this.onEndIconClickHandlerBasedOnRoutes}
        />
      ),
    });
  };

  // Non-Operational hours Handlers Start
  onMonthChange = (month) => {
    this.setState({
      selectedMonth: month,
    });
  };

  // NOTE: Callbacks added for testing purposes, Remove it, if needed
  onPreviousYearClickHandler = () => {
    this.setState((prevState, prevProps) => ({
      selectedYear: prevState.selectedYear - 1,
    }));
  };

  onNextYearClickHandler = () => {
    this.setState((prevState, prevProps) => ({
      selectedYear: prevState.selectedYear + 1,
    }));
  };
  // Non-Operational hours Handlers End

  // Effective From & ends on handler //
  handleOnChange = (value, key, handleOnDialogClose = () => {}) => {
    this.setState(
      {
        [key]: value,
      },
      () => handleOnDialogClose()
    );
  };

  handleOnDialogClose = () => {
    const { effectiveFrom, endsOn } = this.state;
    const { data } = this.props?.careProviderInfo;
    // const effectiveFromDb = data[0]?.partnerMeta?.["resource_validity"]?.value?.[data[0]?.partnerMeta?.["resource_validity"]?.key?.["startDate"]]
    // const endsOnDb = data[0]?.partnerMeta?.["resource_validity"]?.value?.[data[0]?.partnerMeta?.["resource_validity"]?.key?.["endDate"]]

    const actualData = data?.[0];

    let payload = {
      ...actualData,
      partnerMeta: {
        ...actualData?.partnerMeta,
        resource_validity: {
          ...actualData?.partnerMeta?.resource_validity,
          value: {
            [actualData?.partnerMeta?.resource_validity?.key?.["startDate"]]:
              moment(effectiveFrom)?.startOf("day")?.utc()?.format(),
            [actualData?.partnerMeta?.resource_validity?.key?.["endDate"]]:
              endsOn ? moment(endsOn)?.endOf("day")?.utc()?.format() : "",
          },
        },
      },
      EndsOn: endsOn ? moment(endsOn)?.endOf("day")?.utc()?.format() : "",
      partnerCategory: actualData?.partnerMeta?.category?.value?.map(
        (cat) => cat?.value
      ),
      isEffectiveFromToValidation:
        moment(effectiveFrom).isSame(
          actualData?.partnerMeta?.resource_validity?.value?.[
            actualData?.partnerMeta?.resource_validity?.key?.["startDate"]
          ]
        ) &&
        moment(endsOn).isSame(
          actualData?.partnerMeta?.resource_validity?.value?.[
            actualData?.partnerMeta?.resource_validity?.key?.["endDate"]
          ]
        )
          ? false
          : true,
    };


    if (new Date(effectiveFrom) < new Date(endsOn)) {
      if (actualData?.isActive === false && actualData.emailVerified === true) {
        // IF expiry date is greater than current date we set it as true
        if (endsOn > new Date()) {
          payload.isActive = true;
        }
        // if the Effective date is today's date then we set the payload as active
        if(new Date(effectiveFrom).getTime() === new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime())
        {
          payload.isActive = true;
        }
      }

      console.clear()
      console.log(payload)
      this.props.UPDATE_CARE_PROVIDER_INFO(payload);
    }
    else{
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Effective Date is greater than to Expiry Date",
        severity: alertProps.severity.warning,
      })
    }


    
  };

  handleEndsOnClear = () => {
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body: "Do you want to remove expiry date?",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => this.handleOnChange(null, "endsOn", this.handleOnDialogClose),
    });
  };

  render() {
    const { classes, theme } = this.props;
    const iconColor = theme.palette.primary.main;
    // let { footer } = this.giveMeNecessaryData()
    let { header, data, error, loading } = this.giveMeNecessaryData();
    const id = this.props?.match?.params?.id;
    console.log(data[0]);

    const isSuperAdmin =
      this.props?.auth_data?.data?.user_role === user_roles.super_admin;
    
    const isBusinessAdmin = this.props?.auth_data?.data?.user_role === user_roles.business_admin

    const {
      data: { operationalHours: opHours, nonOperationalHours: holidays },
    } = this.props?.getOperationalHours;

    const available247 = opHours?.operationalHours?.available247;
    const operationalHours = opHours?.operationalHours?.operationalHours;

    // const activationData = {
    //   resourceEffectiveFrom: data?.[0]?.partnerMeta?.resource_validity?.value?.resourceEffectiveFrom,
    //   resourceEndsOn: data?.[0]?.partnerMeta?.resource_validity?.value?.resourceEndsOn,
    // }

    // CTA Builder
    const ctas = [
      {
        label: "Invite",
        id: `AddResendButtonFrom${id ? id : ""}`,
        onClickHandler: this.handleResend,
        additionalProps: {
          variant: "outlined",
          display:
            header?.[0]?.emailVerified ||
            header?.[0]?.emailVerified === undefined ||
            header?.[0]?.emailVerified === null
              ? false
              : true,
        },
      },
      {
        label: "Edit Details",
        id: `EditButtonFrom${id ? id : ""}`,
        onClickHandler: () => {
          this.props.history.push(
            checkUrl(this.props.match.path) + "edit/" + id
          );
        },
        additionalProps: {
          variant: "contained",
          display: true,
        },
      },
      {
        label: "Add Branch",
        onClickHandler: () => {
          this.props.history.push({
            pathname: routes.partner_management_branch + "add/" + id,
            state: { partnerId: data[0] },
          });
        },
        id: `AddBranchButtonFrom${id ? id : ""}`,
        additionalProps: {
          variant: "contained",
          display:
            routes.partner_management_branch_detail === this.props.match.path
              ? false
              : true,
        },
      },
    ];

    return (
      <>
        {(error || !data) && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <Typography variant="h6" align="center">
                Something went wrong!
              </Typography>
            </Grid>
            <Grid item>
              <Button
                style={{ marginTop: 16, textAlign: "center" }}
                variant={"outlined"}
                onClick={this.getCareProviderDetail}
              >
                Try Again
              </Button>
            </Grid>
          </Grid>
        )}
        {!error && data && (
          <Grid item style={{ padding: "25px" }}>
            {/* <CardList
              list={header}
              componentOrder={
                !data?.[0]?.parentPartnerId && data?.[0]?.isActive ? 4 : 2
              }
              // componentOrder={2}
              isShowEditButton={!data?.[0]?.isActive}
              sizes={sizes}
              isonlycard={true}
              isCard={true}
              link={(checkUrl(this.props.match.path)) + "edit/" + id}
              link2={{
                pathname: routes.partner_management_branch + "add/" + id,
                state: { partnerId: data[0] },
              }}
              providerId={{
                value: data.id,
                label: data.name,
                provider_id: data.id,
              }}
              data={data[0]}
              onDeactivateClicked={this.onDeactivateClicked}
              handleResend={this.handleResend}
              isDeactivating={this.props?.updateCareProviderStatus?.loading}
              handleAddServiceProfessional={this.handleAddServiceProfessional}
              deactivateActivateButtonId={`ActivateDeactivateButtonFrom${id ? id : ""}`}
              editButtonId={`EditButtonFrom${id ? id : ""}`}
              addBranchButtonId={`AddBranchButtonFrom${id ? id : ""}`}
              resendButtonId={`AddResendButtonFrom${id ? id : ""}`}
            /> */}

            <Information
              loading={loading}
              details={header}
              ctas={ctas}
              showctas={isBusinessAdmin ? true : false}
              showTrialExpiry={isTrialAccountType() && checkTrialExpiryRole(this.props?.auth_data?.data?.user_role)}
            />

            <DetailsCard data={data} loading={loading} />

            <Divider style={{ background: "rgba(244, 244, 245, 1)" }} />

            <div className={classes.sectionContainer}>
              <Grid container>
                {data?.[0]?.parentPartnerId && (
                  <>
                    <Grid
                      item
                      sm={6}
                      md={4}
                      xs={12}
                      className={classes.gridBottomItems}
                    >
                      <HybridTypography
                        icon={ICONS.BUSINESS(iconColor)}
                        loading={loading}
                        text={data?.[0]?.parentPartnerInfo?.label}
                        header="PROVIDER NAME"
                        // islisttop={true}
                      />
                    </Grid>
                  </>
                )}

                <Grid
                  item
                  sm={6}
                  md={4}
                  xs={12}
                  className={`${classes.customMarginSupervisor}`}
                >
                  <HybridTypography
                    icon={ICONS.OFFERING(iconColor)}
                    loading={loading}
                    text={
                      data?.length > 0 && data?.[0]?.partnerDomain?.length > 0
                        ? data?.[0]?.partnerDomain?.[0]?.label
                        : ""
                    }
                    header="DOMAIN CATEGORY"
                    // islisttop={true}
                  />
                </Grid>

                <Grid
                  item
                  sm={6}
                  md={4}
                  xs={12}
                  className={`${classes.customMarginSupervisor}`}
                >
                  {/* <SimpleAvatarCard
                    id={
                      data?.[0]?.supervisor?.[0]?.value
                    }
                    onAvatarClicked={(id) => this.handleOnSupervisorClicked(id)}
                    title={"SERVICE SUPERVISOR(S)"}
                    loading={loading}
                    name={data?.[0]?.supervisor?.[0]?.label ?? ""}
                    src={data?.[0]?.supervisor?.[0]?.profile_pic ?? ""}
                    isactive={
                      data?.[0]?.supervisor?.[0]?.isActive
                    }
                  /> */}
                  <AvatarStack
                    title={"SERVICE SUPERVISOR(S)"}
                    height={16}
                    width={16}
                    style={{ position: "relative", top: 2 }}
                    data={data?.[0]?.supervisor}
                    emptyMessage="Not Mapped"
                    loading={loading}
                    id={`ViewMappedServiceSupervisorFrom${id ? id : ""}`}
                    onAvatarClicked={(id, data) =>
                      this.onClickIcon(id, false, {
                        label: data.label,
                        value: [data],
                      })
                    }
                    onButtonClickHandler={() =>
                      this.handleServiceCoordinatorsViewAllClick({
                        label: "Service Supervisor(s)",
                        value: [data?.[0]?.supervisor?.[0]],
                      })
                    }
                  />
                </Grid>
                {data?.[0]?.orderList?.length > 0 &&
                  data?.[0]?.orderList.map((val) => {
                    return (
                      <Grid
                        item
                        sm={6}
                        md={4}
                        xs={12}
                        className={`${classes.customMarginSupervisor}`}
                      >
                        <AvatarStack
                          title={val?.label?.toUpperCase()}
                          height={16}
                          width={16}
                          style={{ position: "relative", top: 2 }}
                          data={val?.value}
                          emptyMessage="Not Mapped"
                          loading={loading}
                          id={`ViewMappedServiceCoordinatorFrom${id ? id : ""}`}
                          onAvatarClicked={(id, data) =>
                            this.onClickIcon(id, false, data)
                          }
                          onButtonClickHandler={() =>
                            this.handleServiceCoordinatorsViewAllClick(val)
                          }
                        />
                      </Grid>
                    );
                  })}
                {!data?.[0]?.parentPartnerId && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    className={`${classes.customMarginSupervisor}`}
                  >
                    <AvatarStack
                      title={"BRANCH(S)"}
                      height={16}
                      width={16}
                      style={{ position: "relative", top: 2 }}
                      data={data?.[0]?.partnerBranch}
                      emptyMessage="Not Mapped"
                      loading={loading}
                      id={`ViewMappedBranchesFrom${id ? id : ""}`}
                      onAvatarClicked={(id, data) =>
                        this.onClickIcon(id, false, data)
                      }
                      onButtonClickHandler={() =>
                        this.handleServiceProfessionalsViewAllClick(data)
                      }
                    />
                  </Grid>
                )}
                {/* <Grid
                  item
                  sm={6}
                  md={4}
                  xs={12}
                  className={`${classes.customMarginSupervisor}`}
                >
                  <HybridTypography
                    list={
                      data?.product_list?.length
                        ? data.product_list.map((c) => {
                          return c.product_name;
                        })
                        : ["No products mapped to you"]
                    }
                    header="PRODUCT(S)"
                    loading={loading}
                    icon={ICONS.PRODUCT(iconColor)}
                  // islisttop={true}
                  />
                </Grid> */}
              </Grid>
            </div>
            <Divider style={{ background: "rgba(244, 244, 245, 1)" }} />
            <div className={classes.sectionContainer}>
              <Grid container>
                {/* Created on */}
                <Grid
                  item
                  sm={6}
                  md={4}
                  xs={12}

                  // className={classes.sectionContainer}
                >
                  <HybridTypography
                    icon={
                      <CalendarTodayIcon
                        classes={{ root: classes.calendarIconRoot }}
                        fontsize={"small"}
                        htmlColor={theme.palette.primary.main}
                        style={{
                          "font-size": "1.05rem !important",
                          marginTop: 6,
                        }}
                      />
                    }
                    loading={loading}
                    text={
                      data[0]?.createdAt
                        ? moment(data[0]?.createdAt)?.format("DD/MM/YYYY")
                        : "N/A"
                    }
                    header="CREATED ON"
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  md={4}
                  xs={12}

                  // className={classes.sectionContainer}
                >
                  <HybridTypography
                    icon={
                      <CalendarTodayIcon
                        classes={{ root: classes.calendarIconRoot }}
                        fontsize={"small"}
                        htmlColor={theme.palette.primary.main}
                        style={{
                          "font-size": "1.05rem !important",
                          marginTop: 6,
                        }}
                      />
                    }
                    loading={loading}
                    // text={effectiveFrom ? moment(effectiveFrom)?.format("DD/MM/YYYY") : "N/A"}
                    text={
                      <DateTimePickersCustom
                        variant={"mobile"}
                        value={this.state.effectiveFrom}
                        handleOnChange={(value) =>
                          this.handleOnChange(value, "effectiveFrom")
                        }
                        isDate
                        disabled={isBusinessAdmin ? true : false}
                        withoutLabel
                        hidden
                        dontCloseOnChange
                        handleOnDialogClose={this.handleOnDialogClose}
                        width={"50%"}
                        widthBelow400={"70%"}
                        id={`effectiveDate${id}`}
                      />
                    }
                    isTextAComponent={true}
                    header="EFFECTIVE DATE"
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  md={4}
                  xs={12}

                  // className={classes.sectionContainer}
                >
                  <HybridTypography
                    icon={
                      <CalendarTodayIcon
                        classes={{ root: classes.calendarIconRoot }}
                        fontsize={"small"}
                        htmlColor={theme.palette.primary.main}
                        style={{
                          "font-size": "1.05rem !important",
                          marginTop: 6,
                        }}
                      />
                    }
                    loading={loading}
                    // text={endsOn ? moment(endsOn)?.format("DD/MM/YYYY") : "N/A"}
                    text={
                      <div
                        style={{ display: "flex", gap: 2, margin: "8px 0px" }}
                      >
                        {/* ExpiryDe */}
                        <DatePicker
                          value={this.state.endsOn}
                          disabled={isBusinessAdmin ? true : false}
                          handleOnChange={(value) =>
                            this.handleOnChange(value, "endsOn")
                          }
                          handleOnClose={this.handleOnDialogClose}
                          id={id}
                        />
                        {/* <DateTimePickersCustom
                          variant={"mobile"}
                          value={this.state.endsOn}
                          handleOnChange={(value) =>
                            this.handleOnChange(value, "endsOn")
                          }
                          isDate
                          withoutLabel
                          hidden
                          dontCloseOnChange
                          handleOnDialogClose={this.handleOnDialogClose}
                          minDate={this.state.effectiveFrom}
                          width={"50%"}
                          widthBelow400={"70%"}
                        /> */}
                        {this.state.endsOn && (
                          <Button
                            style={{ height: 35, width: 35 }}
                            className={classes.endDateClear}
                            onClick={this.handleEndsOnClear}
                            disabled={isBusinessAdmin ? true : false}
                          >
                            Clear
                          </Button>
                        )}
                      </div>
                    }
                    isTextAComponent={true}
                    header="EXPIRY DATE"
                  />
                </Grid>
              </Grid>
            </div>
            <Divider style={{ background: "rgba(244, 244, 245, 1)" }} />
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              className={
                isSuperAdmin
                  ? classes.sectionContainer
                  : classes.bottomSectionContainer
              }
            >
              <HybridTypography
                icon={ICONS.OFFERING(iconColor)}
                loading={loading}
                text={
                  data[0]?.partnerMeta?.remarks?.value &&
                  data[0]?.partnerMeta?.remarks?.value !==
                    "<p></p><h1></h1><h1><br></h1><p></p>"
                    ? [data[0]?.partnerMeta?.remarks?.value]
                    : null
                }
                header="REMARKS"
                // islisttop={true}
                isdangerouslySetInnerHTML={true}
              />
            </Grid>

            {isSuperAdmin && !this.state.actionError && (
              <>
                <Divider style={{ background: "rgba(244, 244, 245, 1)" }} />

                {/* Operational Hours */}
                <div className={classes.sectionContainer}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    Operational Hours
                  </Typography>
                  <div className={classes.operationalHoursContainer}>
                    {available247 && (
                      <OperationalHours
                        available247={available247}
                        hideClockIcon
                      />
                    )}
                    {!available247 &&
                      Array.isArray(operationalHours) &&
                      operationalHours?.length > 0 && (
                        <OperationalHours
                          operationalHours={operationalHours}
                          hideClockIcon
                        />
                      )}
                    {!(
                      Array.isArray(operationalHours) &&
                      operationalHours?.length > 0
                    ) &&
                      !available247 && (
                        <Typography variant="body1" color={"textSecondary"}>
                          Provider does not have any operational hours
                        </Typography>
                      )}
                  </div>
                </div>

                {/* NON OPERATIONAL DAYS */}
                <div className={classes.bottomSectionContainer}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    Non Operational Days
                  </Typography>
                  <NonOperationalDays
                    hideAddHolidayClickHandler={true}
                    holidays={holidays}
                    selectedMonth={this.state.selectedMonth}
                    selectedYear={this.state.selectedYear}
                    onMonthChange={this.onMonthChange}
                    onPreviousYearClickHandler={this.onPreviousYearClickHandler}
                    onNextYearClickHandler={this.onNextYearClickHandler}
                    hideMoreActions={true}
                    previousButtonId={`PreviousButtonForNonOperationalDaysFrom${
                      id ? id : ""
                    }`}
                    nextButtonId={`NextButtonForNonOperationalDaysFrom${
                      id ? id : ""
                    }`}
                    id={id}
                  />
                </div>
              </>
            )}
          </Grid>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth_data: state?.authState?.loginAuth,
  careProviderInfo: state.careProvider?.careProviderInfo,
  updateCareProvider: state?.careProvider?.updateCareProvider,
  updateCareProviderStatus: state.careProvider?.updateCareProviderStatus,
  getOperationalHours: state?.operational_hours?.get_operational_hours,
});

export default connect(
  mapStateToProps,
  actions
)(
  withRouter(
    withAlelrtAndDialog(withStyles(styles, { withTheme: true })(ProviderDetail))
  )
);

const datePickerStyles = makeStyles((theme) => ({
  textField: {
    borderRadius: 4,
    "& .MuiOutlinedInput-root": {
      padding: "8px 12x !important",
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`,
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.secondary.borderColor}`,
      borderRadius: 8,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 12px",
    },
  },
  mobilePicker: {
    "& .MuiPickersToolbar-dateTitleContainer": {
      justifyContent: "space-between !important",
      "& h4": {
        color: "white",
      },
    },
  },
  svgColor: {
    fill: theme.palette.secondary.main,
    fontSize: 20,
  },
  // label: {
  //   color: theme.palette.text.label,
  //   textTransform: "uppercase",
  // },
  errorBorder: {
    //   "& .MuiOutlinedInput-root:hover": {
    //     "& .MuiOutlinedInput-notchedOutline": {
    //       border: `1.8px solid ${theme.palette.error.light} !important`,
    //     },
    // },
    // "& .Mui-focused": {
    //   "& .MuiOutlinedInput-notchedOutline": {
    //     border: `1.8px solid ${theme.palette.error.light} !important`,
    //   },
    // },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.error.light} !important`,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
  root2: {
    "& .MuiPickersModalDialog-dialogRoot": {
      "&:focus": {
        outline: "unset !important",
      },
    },
    "& .MuiDialog-scrollPaper": {
      "&:focus": {
        outline: "unset !important",
      },
    },
  },
  timePickerRoot: {
    "& .MuiPickersClock-amButton": {
      display: "none",
    },
    "& .MuiPickersClock-pmButton": {
      display: "none",
    },
  },
}));

function DatePicker({ value, handleOnChange, handleOnClose, disabled = false,id }) {
  const classes = datePickerStyles();
  return (
    <>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <MobileDatePicker
          openPickerIcon={<CalendarTodayIcon />}
          PopperProps={{ disablePortal: true }}
          value={value}
          disabled={disabled}
          onChange={(newDate) => {
            handleOnChange(newDate);
          }}
          onClose={handleOnClose}
          id={`datePicker${id}`}
          inputFormat={"dd/MM/yyyy"}
          disablePast={true}
          renderInput={(props) => {
            return (
              <TextField
                {...props}
                variant="outlined"
                id={`date${id}`}
                placeholder=" "
                helperText={null}
                autoComplete="off"
                className={classes.textField}
              />
            );
          }}
          // onOpen={(e) => {
          //   handleClose(e);
          // }}
          // minDate={minDate ? minDate : new Date("1900-01-01")}
          // maxDate={new Date()}
          // maxDate={!hideMaxDateLogic ? new Date() : null}

          // onDismiss={() => {
          //   setOpen(false);
          // }}
          // onClose={(e)=>console.log("ON CLOSE", e)}
          // onAccept={(e)=>{console.log(e,"ON ACCEPTED DATE")}}
          // onError={(e) => {
          //   console.log("error-picker", e);
          // }}
          // disableFuture={isHideFutureDate}
          // disabled={disabled}
          // open={open}
          className={classes.mobilePicker}
        />
        {/* {error && (
        <Typography
          variant="body1"
          component="div"
          className={classes.errorText}
        >
          {errorText}
        </Typography>
      )} */}
      </LocalizationProvider>
    </>
  );
}
