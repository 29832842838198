import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { IconComponent } from "../../../../../../assets";
import { createTestProps } from "../../../../../../utils/common";
// import Tooltip from '@material-ui/core/Tooltip';

function CustomMapUIControls({ dateProps }) {
  return (
    <div>
      <div className="controls fullscreen-control">
        <IconButton
          disableRipple
          style={{
            background: "white",
            margin: "38px 38px 0px 0px",
            padding: "10.4px",
          }}
          {...createTestProps("real time monitoring map full screen button")}
        >
          <IconComponent name={"maximize"} style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </div>
      <div className="controls gps-zoom-control">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: 16,
            marginRight: "38px",
            marginBottom: "52px",
          }}
        >
          {/* <Tooltip title="Locate SP" arrow placement="top"> */}
            <IconButton
              disableRipple
              style={{
                background: "white",
                padding: "10.4px",
              }}
              className="gps-control"
              {...createTestProps("real time monitoring map gps button")}
            >
              <IconComponent name={"gps"} style={{ fontSize: "1.2rem" }} />
            </IconButton>
          {/* </Tooltip> */}

          <IconButton
            disableRipple
            style={{
              background: "white",
              padding: "12.4px",
            }}
            className="zoom-control-in"
            {...createTestProps(
              "real time monitoring map zoom control in button"
            )}
          >
            <IconComponent name={"zoomIn"} style={{ fontSize: "1rem" }} />
          </IconButton>
          <IconButton
            disableRipple
            style={{
              background: "white",
              padding: "12.4px",
            }}
            className="zoom-control-out"
            {...createTestProps(
              "real time monitoring map zoom control out button"
            )}
          >
            <IconComponent name={"zoomOut"} style={{ fontSize: "1rem" }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default CustomMapUIControls;
