import React from "react";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import {
  ClientAddressIcon,
  DOBIconV2,
  EmailIconV2,
  GenderIconV2,
  IdCardIconV2,
  LocationIconV2,
  MobileIconV2,
  NameIconV2,
  // WeightIconV2,
} from "../../../../assets";
import moment from "moment";
import { Chip, useTheme, withStyles } from "@material-ui/core";

export const EmergencyChip = withStyles((props) => {
  const theme = useTheme();
  return {
    root: {
      height: 24,
      backgroundColor: "#F67280",
      color: theme.palette.common.white,
      borderRadius: 8,
      // alignItems: (props) => props.transparent ? "flex-start" : "center",
      maxWidth: (props) => (props.maxWidth ? props.maxWidth : "unset"),
    },
    label: {
      paddingLeft: (props) => (props.transparent ? 0 : 12),
      paddingRight: (props) => (props.transparent ? 0 : 12),
    },
  };
})((props) => <Chip title={props?.label ? props?.label : "N/A"} {...props} />);

function Element({ icon, name, value, valueSuffix, sx }) {
  return (
    <Box sx={{ width: "100%", height: "100%", ...sx }}>
      <Grid container style={{ gap: 8 }} wrap="nowrap">
        <Grid item style={{ minWidth: 24 }}>
          {icon ? icon : <ClientAddressIcon />}
        </Grid>
        <Grid item xs>
          <Typography
            variant="body2"
            color="textSecondary"
            gutterBottom
            style={{ whiteSpace: "nowrap" }}
          >
            {name}
          </Typography>
          {value ? (
            <Typography style={{ overflowWrap: "anywhere" }}>
              {value}
            </Typography>
          ) : (
            <Typography style={{ fontStyle: "italic" }} color="textSecondary">
              Not Updated
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

function IdElement({ data = [], name, icon }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <div style={{ display: "flex", gap: 8 }}>
          <div style={{ width: 24 }}>{icon ? icon : <ClientAddressIcon />}</div>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {name}
          </Typography>
        </div>
      </Grid>
      <Grid container item xs={12} style={{ marginLeft: 32 }}>
        {data?.length > 0 ? (
          data?.map((id, index) => (
            <>
              <Grid item>
                <Typography style={{ fontStyle: "italic", fontWeight: "bold" }}>
                  {id?.label}
                </Typography>
                <Typography>{id?.id_type}</Typography>
              </Grid>
              {index + 1 < data?.length && (
                <Grid item xs={2}>
                  <Divider orientation="vertical" style={{ margin: "auto" }} />
                </Grid>
              )}
            </>
          ))
        ) : (
          <Typography color="textSecondary" style={{ fontStyle: "italic" }}>
            {"Not Updated"}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

function MobileElement({ data = [], name, icon }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <div style={{ display: "flex", gap: 8 }}>
          <div style={{ width: 24 }}>{icon ? icon : <ClientAddressIcon />}</div>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {name}
          </Typography>
        </div>
      </Grid>
      <Grid container item xs={12} style={{ marginLeft: 32 }}>
        {data?.length > 0 ? (
          data?.map((phone, index) => (
            <>
              <Grid item>
                <Typography style={{ fontStyle: "italic", fontWeight: "bold" }}>
                  {phone?.label}
                </Typography>
                <Typography>{`${
                  phone?.mobileNoIsdCode ? phone?.mobileNoIsdCode : ""
                } ${phone?.mobileNo ? phone?.mobileNo : ""}`}</Typography>
              </Grid>
              {index + 1 < data?.length && (
                <Grid item xs={2}>
                  <Divider orientation="vertical" style={{ margin: "auto" }} />
                </Grid>
              )}
            </>
          ))
        ) : (
          <Typography color="textSecondary" style={{ fontStyle: "italic" }}>
            {"Not Updated"}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

function AddressElement({ data = [], name, icon }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <div style={{ display: "flex", gap: 8 }}>
          <div style={{ width: 24 }}>{icon ? icon : <ClientAddressIcon />}</div>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {name}
          </Typography>
        </div>
      </Grid>
      <Grid container item xs={12} style={{ marginLeft: 32 }}>
        {data?.length > 0 ? (
          data?.map((address, index) => (
            <>
              <Grid item>
                <Typography style={{ fontStyle: "italic", fontWeight: "bold" }}>
                  {address?.label}
                </Typography>
                <Typography>{address?.add_location?.address}</Typography>
              </Grid>
              {index + 1 < data?.length && (
                <Grid item xs={2}>
                  <Divider orientation="vertical" style={{ margin: "auto" }} />
                </Grid>
              )}
            </>
          ))
        ) : (
          <Typography color="textSecondary" style={{ fontStyle: "italic" }}>
            {"Not Updated"}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

function ViewFamilyMemberDetails(props) {
  const { data } = props;
  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 12, padding: 16 }}>
        <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
          {data?.relationship?.label ? data?.relationship?.label : "Detail"}
        </Typography>
        {data?.isEmergencyContact ? (
          <EmergencyChip label={"Emergency Contact"} />
        ) : (
          ""
        )}
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Grid container spacing={3} xs={12}>
          <Grid item xs={12} sm={6} md={"auto"}>
            <Element name={"Name"} value={data?.name} icon={<NameIconV2 />} />
          </Grid>
          <Grid item xs={12} sm={6} md>
            <Element
              name={"Primary Email Address"}
              value={data?.email}
              icon={<EmailIconV2 />}
              sx={{
                maxWidth: { md: "220px" },
                overflowWrap: { md: "anywhere" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={"auto"}>
            <Element
              name={"DOB"}
              value={data?.dob ? moment(data?.dob).format("DD/MM/YYYY") : ""}
              icon={<DOBIconV2 />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={"auto"}>
            <Element
              name={"Gender"}
              value={data?.gender}
              icon={<GenderIconV2 />}
            />
          </Grid>

          <Grid item xs={12}>
            <IdElement
              data={data?.idTypes}
              name={"ID Number"}
              icon={<IdCardIconV2 />}
            />
          </Grid>
          <Grid item xs={12}>
            <MobileElement
              data={data?.otherPhoneNumbers}
              name={"Contact Number"}
              icon={<MobileIconV2 />}
            />
          </Grid>
          <Grid item xs={12}>
            <AddressElement
              data={data?.otherAddress}
              name={"Address Details"}
              icon={<LocationIconV2 />}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewFamilyMemberDetails;
