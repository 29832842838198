import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';

const styles = makeStyles((theme)=>({
    columnCenter:props=>({
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        cursor:props?.handleClick ? 'pointer' : 'auto'
    }),
    text:{
        marginTop:theme.spacing(3),
        '& .MuiTypography-root': {
            fontSize: 12,
            color: '#6F6F6F',
            fontWeight: 400
        }
    }
}));

export const IconText = (props) =>{

    const classes = styles(props);

    const {
        icon="",
        text="",
        handleClick=null,
        id="",
    } = props;

    return(
        <div 
            className={classes.columnCenter}
            onClick={handleClick}
            id={id}    
        >
            {icon && <div style={{display:'flex'}}>{icon}</div>}
            {text && 
            <div className={classes.text}>
                <Typography>{text}</Typography>
            </div>
            }
        </div>
    )
}

IconText.propTypes={
    icon:PropTypes.node,
    text:PropTypes.string,
    handleClick:PropTypes.func,
    id:PropTypes.string,
}