import React from "react";
import {
  Button,
  // Checkbox,
  FormControl,
  // FormControlLabel,
  // FormGroup,
  Grid,
  // IconButton,
  InputLabel,
  TextField,
  // Typography,
  withStyles,
} from "@material-ui/core";
// import { SimpleSelect } from "../inputs/select";
// import { CheckboxCustom } from "../inputs";
import { LocalizationProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { errorMessage } from "../../utils/errorMessages";
import moment from "moment";
import {
  // Clear as ClearIcon,
  // InsertInvitation as CalendarIcon,
} from "@material-ui/icons";

const styles = (theme) => ({
  checkboxColor: {
    color: theme.palette.ternary.main,
  },
  checkboxColorChecked: {
    color: `${theme.palette.ternary.main} !important`,
  },
  // Date Classes
  dateRoot: {
    borderRadius: "8px !important",
    margin: (props) =>
      props.marginTop ? `${props.marginTop}px 0px 8px 0px` : "8px 0px 8px 0px",
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "16px 12px",
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.secondary.light}`,
      borderRadius: 8,
    },
    "& .MuiOutlinedInput-root:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`,
      },
    },
  },
  dateErrorBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.error.light} !important`,
    },
  },
  dateLabel: {
    color: theme.palette.text.label,
    textTransform: "uppercase",
  },
  dateMobilePickerHeader: {
    "& .MuiPickersToolbar-dateTitleContainer": {
      justifyContent: "space-between !important",
    },
  },
  dateTextField: {
    marginTop: (props) =>
      props.marginTop ? `${props.marginTop}px` : theme.spacing(2),

    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      // border: '1.8px solid rgba(204,204,204, 0.3)',
      border: "none",
    },

    "& .MuiOutlinedInput-adornedEnd": {
      height: 43.6,
    },

    "& .MuiOutlinedInput-root.Mui-disabled ": {
      backgroundColor: "#f2f2f2",
      borderRadius: "8px !important",
    },
  },
  dateErrorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
  endDateClear: {
    color: theme.palette.ternary.main
  }
});

class ProductValidity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      check: false,
      validity: null,
      start: null,
      end: null,
      minStartDate: null,
      minEndDate: null,
      fromError: false,
      toError: false,
      startError: false,
      startErrorText: "",
      endError: false,
      endErrorText: "",
      isShowErrorStart: true,
      isShowErrorEnd: true,
    };
  }

  componentDidMount() {
    this.setMinOnMount();
  }

  componentDidUpdate(prevProps) {
    if (this.props.dropdownProps !== prevProps.dropdownProps) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      this.setMinOnMount();
    }
  }

  //If the component is being rendered While editing
  setMinOnMount = (dropdownProps) => {
    const { isEdit } = this.props;
    // const { startDateMin, endDateMin } = this.props;
    // if (this.props?.dropdownProps?.value) {
    // }
    if (isEdit) {
      let { value: validityValue } = dropdownProps
        ? dropdownProps
        : this.props?.dropdownProps;
      const { value: startValue } = this.props?.startDateProps;
      const { value: endValue } = this.props?.endDateProps;
      // validityValue = { value: 3, label: "3 Months" };
      this.setMin(validityValue, startValue, endValue);
      this.setState({
        // minStartDate: startDateMin,
        // minEndDate: endDateMin,
        //TODO: Temporarily using 3 months
        // {value: 1, label: "Two Months", days: 60}
        // {value: 60, label: "Two Months"}
        validity: validityValue,
        start: startValue,
        end: endValue,
        isShowErrorStart: false,
        isShowErrorEnd: false,
      });
    }
  };

  setMin = (validityValue) => {
    //Get the validity value
    const days = parseInt(validityValue?.value);

    let calcStartMin = moment()
      .subtract(days - 1, "days")
      .toDate();
    let calcEndMin = moment().startOf("day").toDate();

    this.setState({
      minStartDate: calcStartMin,
      minEndDate: calcEndMin,
    });
  };
  setMinForCustom = () => {
    let calcEndMin = moment().startOf("day").toDate();
    this.setState({
      minStartDate: null,
      minEndDate: calcEndMin,
      check: true,
    });
  };

  onCheckboxChange = (e) => {
    this.setState({
      check: e.target.checked,
      // validity: { label: "Custom", value: "custom" },
    });
  };

  setDate = (validity, isCustom) => {
    
    console.log(validity);
    const { onChange: startCallback } = this.props.startDateProps;
    const { onChange: endCallback } = this.props.endDateProps;
    let days = parseInt(this.state.validity?.value);
    // let days = 91
    let start = this.state.start
      ? moment(this.state.start).toDate()
      : moment().toDate();
    let end = this.state.start
      ? moment(this.state.start)
          .add(days - 1, "days")
          .toDate()
      : moment()
          .add(days - 1, "days")
          .toDate();

    if (!isCustom) {
      this.setState(
        {
          start: start,
          end: end,
          check: false,
        },
        () => {
          startCallback(this.state.start);
          endCallback(this.state.end);
        }
      );
    } else {
      this.setState(
        {
          start: this.state.start
            ? moment(this.state.start).toDate()
            : moment().toDate(),
          end: this.state.end
            ? moment(this.state.end).toDate()
            : moment().toDate(),
        },
        () => {
          startCallback(this.state.start);
          endCallback(this.state.end);
        }
      );
    }
  };

  validityOnChange = (selected) => {
    this.setState(
      {
        validity: selected,
      },
      () => {
        this.props.dropdownProps.onChange(selected);
        if (selected?.value !== "custom") {
          this.setMin(this.state.validity);
          this.setDate(this.state.validity);
        }
        if (selected?.value === "custom") {
          this.setMinForCustom();
          this.setDate(this.state.validity, true);
        }
        // this.finalFunct();
      }
    );
  };

  onDateChange = (newDate, key) => {
    if (newDate === null && key === "end") {
      this.setState(
        {
          [key]: null,
          minEndDate: this.state.start ? this.state.start : null,
        },
        () => {
          const { onChange: endCallback } = this.props.endDateProps;
          endCallback(this.state.end);
        }
      );
      return;
    }
    this.setState(
      {
        [key]: moment(newDate)
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .toDate(),
      },
      () => {
        // key === "start" ? this.onDateFix() : this.onEndDateFix();
        key === "start" ? this.fixOnStartChange() : this.fixOnEndChange();
      }
    );
  };

  fixOnStartChange = () => {
    const { onChange: startCallback } = this.props.startDateProps;
    startCallback(this.state.start);

    // const { onChange: endCallback } = this.props.endDateProps;

    // let days = parseInt(this.state.validity?.value);
    // let days = 91;
    // let end = moment(this.state.start).add(days - 1, "days");
    // let start = moment(this.state.start);
    // this.setState(
    //   {
    //     end: end.toDate(),
    //     toError: start.isAfter(end) ? true : false,
    //   },
    //   () => {
    //     startCallback(this.state.start);
    //     endCallback(this.state.end);
    //   }
    // );
  };

  fixOnEndChange = () => {
    const { onChange: startCallback } = this.props.startDateProps;
    const { onChange: endCallback } = this.props.endDateProps;
    this.setState(
      {
        validity: { label: "Custom", value: "custom" },
        check: true,
        minStartDate: null,
        minEndDate: moment()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate(),
      },
      () => {
        startCallback(this.state.start);
        endCallback(this.state.end);
      }
    );
  };

  //On Error Functions
  onStartError = (reason, value) => {
    const {
      fromDateParentErrorStateUpdate = () => {},
    } = this.props.startDateProps;
    if (!this.state.isShowError) {
      this.setState({
        isShowErrorStart: true,
      });
    } else {
      switch (reason) {
        case "invalidDate":
          this.setState(
            {
              startError: true,
              startErrorText: "Invalid date format",
            },
            () => {
              fromDateParentErrorStateUpdate(true);
            }
          );
          break;

        case "disablePast":
          this.setState(
            {
              startError: true,
              startErrorText: "Values in the past are not allowed",
            },
            () => {
              fromDateParentErrorStateUpdate(true);
            }
          );
          break;

        case "maxDate":
          this.setState(
            {
              startError: true,
              startErrorText: `Date should not be after`,
              //   ${format(maxDate, "P")}
            },
            () => {
              fromDateParentErrorStateUpdate(true);
            }
          );
          break;

        case "minDate":
          this.setState(
            {
              startError: true,
              startErrorText: `Date should not be before minimal date.`,
              //   ${format(minDate, "P")}
            },
            () => {
              fromDateParentErrorStateUpdate(true);
            }
          );
          break;
        case null:
          this.setState(
            {
              startError: false,
              startErrorText: ``,
              //   ${format(minDate, "P")}
            },
            () => {
              fromDateParentErrorStateUpdate(false);
            }
          );
          break;
        default:
          this.setState(
            {
              startError: false,
              startErrorText: ``,
              //   ${format(minDate, "P")}
            },
            () => {
              fromDateParentErrorStateUpdate(false);
            }
          );
      }
    }
  };

  onEndError = (reason, value) => {
    const { toDateParentErrorStateUpdate = () => {} } = this.props.endDateProps;
    switch (reason) {
      case "invalidDate":
        this.setState(
          {
            endError: true,
            endErrorText: "Invalid date format",
          },
          () => {
            toDateParentErrorStateUpdate(true);
          }
        );
        break;

      case "disablePast":
        this.setState(
          {
            endError: true,
            endErrorText: "Values in the past are not allowed",
          },
          () => {
            toDateParentErrorStateUpdate(true);
          }
        );
        break;

      case "maxDate":
        this.setState(
          {
            endError: true,
            endErrorText: `Date should not be after`,
            //   ${format(maxDate, "P")}
          },
          () => {
            toDateParentErrorStateUpdate(true);
          }
        );
        break;

      case "minDate":
        this.setState(
          {
            endError: true,
            endErrorText: `Date should not be before minimal date.`,
            //   ${format(minDate, "P")}
          },
          () => {
            toDateParentErrorStateUpdate(true);
          }
        );
        break;

      case null:
        this.setState(
          {
            endError: false,
            endErrorText: ``,
            //   ${format(minDate, "P")}
          },
          () => {
            toDateParentErrorStateUpdate(false);
          }
        );
        break;
      default:
        this.setState(
          {
            endError: false,
            endErrorText: ``,
            //   ${format(minDate, "P")}
          },
          () => {
            toDateParentErrorStateUpdate(false);
          }
        );
    }
  };

  render() {
    const { classes } = this.props;
    const {
      // options = [],
      // label: validityLabel = "Validity",
      // value: validityValue = [{ label: "Custom", value: "custom" }],
      // onChange: validityOnChange = () => {},
      // error: validityError = false,
      id: validityId = "",
    } = this.props?.dropdownProps;

    // const {
    //   label: checkboxLabel = "",
    //   value: checkboxValue = false,
    //   error: checkboxError = false
    // } = checkboxProps;

    const {
      label: startDateLabel = "",
      // value: startDateValue = new Date(),
      // onChange: startDateOnChange = () => {},
      // min: startDateMin = new Date(),
      // max: startDateMax = new Date(),
      // disablePast: startDateDisablePast = false,
      // disableFuture: startDateDisableFuture = false,
      // inputFormat: startDateFormat = "dd/MM/yyyy",
      error: mandatoryStartDateError = false,
      id: startValidity = "",
    } = this.props?.startDateProps;

    const {
      label: endDateLabel = "",
      // value: endDateValue = new Date(),
      // onChange: endDateOnChange = () => {},
      // min: endDateMin = new Date(),
      // max: endDateMax = new Date(),
      // disablePast: endDateDisablePast = false,
      // disableFuture: endDateDisableFuture = false,
      // inputFormat: endDateFormat = "dd/MM/yyyy",
      error: mandatoryEndDateError = false,
      id: endDateId = "",
    } = this.props?.endDateProps;

    console.log(validityId, endDateId, startValidity);
    return (
      // <div style={{ margin: 12 }}>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <Grid container spacing={3}>
          {/* DropDown */}
          {/*<Grid item xs={12} sm={6} md={4}>
          
           <SimpleSelect
            options={[...options, { label: "Custom", value: "custom" }]}
            label={validityLabel}
            id={validityId}
            value={this.state.validity}
            onChange={(selected) => this.validityOnChange(selected)}
            error={validityError}
            errorText={errorMessage.requiredMessage}
          /> 
        </Grid>*/}

          {/* Checkbox */}
          {/* <Grid item container xs={12} sm={6} md={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  value={this.state.check}
                  checked={this.state.check}
                  onChange={(e) => this.onCheckboxChange(e)}
                />
              }
              label={"Checked"}
            />
          </FormGroup>
        </Grid> */}

          {/* Effective From Date Picker */}
          <Grid item xs={12} sm={6} md={6}>
            {/* Start Date */}
            <FormControl
              className={`${classes.dateRoot} ${
                mandatoryStartDateError ? classes.dateErrorBorder : ""
              }`}
            >
              <InputLabel
                // style={{ position: "absolute", top: "-11px" }}
                shrink
                htmlFor="date"
                required
                style={{ display: "flex" }}
              >
                <div className={classes.dateLabel}>
                  {startDateLabel}
                  {/* <Checkbox
                  value={this.state.check}
                  checked={this.state.check}
                  onChange={(e) => this.onCheckboxChange(e)}
                  disableRipple
                  className={classes.effectiveFromCheckbox}
                  classes={{root: classes.checkboxColor, checked: classes.checkboxColorChecked}}
                /> */}
                </div>
              </InputLabel>
              {/* <Checkbox
                  value={this.state.check}
                  onChange={(e) => this.onCheckboxChange(e)}
                /> */}
              <DatePicker
                className={classes.dateMobilePickerHeader}
                value={this.state.start}
                // id={startValidity}
                onChange={(selectedDate) =>
                  this.onDateChange(selectedDate, "start")
                }
                onError={this.onStartError}
                minDate={this.state.minStartDate}
                disablePast
                // disabled={!this.state.check}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      disabled: !this.state.check,
                      placeholder: "",
                      // id: startDateLabel?.replaceAll(" ", ""),
                      id: startValidity,
                    }}
                    required={true}
                    autoComplete="off"
                    error={this.state.startError || mandatoryStartDateError}
                    helperText={
                      this.state.startError
                        ? this.state.startErrorText
                        : mandatoryStartDateError
                        ? errorMessage.requiredMessage
                        : ""
                    }
                    className={`${classes.dateTextField} ${
                      mandatoryStartDateError ? classes.dateErrorBorder : ""
                    }`}
                  />
                )}
              />
            </FormControl>
          </Grid>

          {/* Ends on Date Picker */}
          <Grid item xs={12} sm={6} md={6}>
            {/* End Date */}
            <FormControl
              className={`${classes.dateRoot} ${
                mandatoryEndDateError ? classes.dateErrorBorder : ""
              }`}
            >
              <InputLabel shrink required={false} htmlFor="date">
                <div className={classes.dateLabel}>{endDateLabel}</div>
              </InputLabel>
              <div style={{ display: "flex", gap: 2 }}>
                <DatePicker
                  className={classes.dateMobilePickerHeader}
                  value={this.state.end}
                  onChange={(selectedDate) =>
                    this.onDateChange(selectedDate, "end")
                  }
                  // onError={this.onEndError}
                  // minDate={this.state.minEndDate}
                  minDate={this.state?.start}
                  inputFormat="dd/MM/yyyy"
                  disablePast
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        //   disabled: disabled,
                        placeholder: "",
                        // id: endDateLabel?.replaceAll(" ", ""),
                        id: endDateId,
                      }}
                      required={false}
                      autoComplete="off"
                      error={this.state.endError || mandatoryEndDateError}
                      helperText={
                        this.state.endError
                          ? this.state.endErrorText
                          : mandatoryEndDateError
                          ? errorMessage.requiredMessage
                          : ""
                      }
                      className={`${classes.dateTextField} ${
                        mandatoryEndDateError ? classes.dateErrorBorder : ""
                      }`}
                    />
                  )}
                />
                {this.state.end && (
                  <Button
                    style={{ height: 43, width: 43, marginTop: 16 }}
                    className={classes.endDateClear}
                    onClick={() => this.onDateChange(null, "end")}
                  >
                    Clear
                  </Button>
                )}
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </LocalizationProvider>
      //{" "}
      // </div>
    );
  }
}

export default withStyles(styles)(ProductValidity);
