import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function LanguageIconV2(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 4c-4.4 0-8 3.255-8 7.3a6.886 6.886 0 001.75 4.543h-.011a1.1 1.1 0 01.076.837c-.1.375-.254.734-.457 1.065-.19.315-.397.62-.62.913-.092.124-.177.228-.239.315a1.468 1.468 0 00-.076.13c-.024.042-.076.039-.076.228a.65.65 0 00.2.457.793.793 0 00.562.212c1.6 0 3.063-1.192 4.174-1.848a8.598 8.598 0 002.717.457c4.4 0 8-3.255 8-7.3S16.4 4 12 4zm0 .7c4.056 0 7.3 2.973 7.3 6.609s-3.249 6.609-7.3 6.609a7.97 7.97 0 01-2.663-.457l-.152-.061-.13.087c-1.207.69-2.659 1.8-3.924 1.815l.163-.217c.238-.318.46-.648.663-.989.244-.39.427-.814.543-1.259a1.8 1.8 0 00-.163-1.348l-.022-.033-.015-.021A6.2 6.2 0 014.7 11.3c0-3.632 3.244-6.6 7.3-6.6zM8.989 7.478a.349.349 0 00-.272.239l-1.054 3.044a.348.348 0 00-.065.2l-.446 1.283a.348.348 0 10.652.217l.4-1.152H9.88l.4 1.152a.348.348 0 10.652-.217L9.37 7.717a.347.347 0 00-.381-.239v0zm.054 1.4l.6 1.728h-1.2l.6-1.728zM14.75 9.9a.406.406 0 00-.043.011.35.35 0 00-.272.348v.7h-1.8a.352.352 0 00.065.7h3.033a4.695 4.695 0 01-1.152 1.935 5.136 5.136 0 01-.859-1.076.347.347 0 00-.65.208c.005.045.019.089.041.129.261.432.575.83.935 1.185a5.23 5.23 0 01-1.457.772.348.348 0 10.217.652 5.8 5.8 0 001.75-.957c.447.376.94.694 1.467.946a.35.35 0 10.3-.63 6.437 6.437 0 01-1.25-.783 5.573 5.573 0 001.37-2.38h.424a.35.35 0 000-.7H15.13v-.7a.347.347 0 00-.38-.359V9.9z"
        fill="#0063E7"
        stroke="#0063E7"
        strokeWidth={0.5}
      />
    </SvgIcon>
  );
}

export default LanguageIconV2;
