import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { InputLabel, Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: theme.palette.ternary.main,
    },
    "& .MuiRadio-root": {
      color: theme.palette.ternary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "0.9em",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "0.9rem",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  label: {
    color: theme.palette.text.label,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
}));
export function RadioButtonsGroup(props) {
  // PROPS
  const { data, value, handleOnChange, error,
    errorText, id} = props;
  // CLASSES
  const classes = styles();

  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        {/* {data.label &&<Grid item xs={data.withBoldHeading ? 12 : 2}>
          <InputLabel shrink component="legend" className={classes.label}>
            {data.label}
          </InputLabel>
        </Grid>} */}
        {data.label && <>
          {data.withBoldHeading ? (
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginBottom: 8 }}>
                {data.label}
              </Typography>
            </Grid>
          ) : (
              <>
                {data.label ? (
                  <Grid item xs={2}>
                    <InputLabel shrink component="legend" className={classes.label}>
                      {data.label}
                    </InputLabel>
                  </Grid>
                ) : null}
              </>
            )}
        </>
        }
        <Grid item xs={data.withBoldHeading ? 12 : 10}>
          <FormControl component="span">
            {/* RADIO GROUP ------------------------------------------------- */}
            <RadioGroup
              row
              aria-label={value}
              name={data.key}
              value={value}
              id={id}
              onChange={(e) => handleOnChange(e.target.value, data.key)}
            >
              {/* RADIO OPTIONS ------------------------------------------------- */}
              {data.options?.map((val, index) => {
                return (
                  <FormControlLabel
                    value={val.value}
                    control={
                      <Radio
                      id={`${id}${index}`}
                      // icon={<span className={classes.icon} />}
                      />
                    }
                    label={val.label}
                  />
                );
              })}
              {/* RADIO OPTIONS ------------------------------------------------- */}
            </RadioGroup>
            {/* RADIO GROUP ------------------------------------------------- */}
            {error && (
              <Typography
                variant="body1"
                component="div"
                className={classes.errorText}
              >
                {errorText}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

// PROPTYPES

RadioButtonsGroup.propTypes = {
  data: PropTypes.object,
  handleOnChange: PropTypes.func,
  value: PropTypes.string,
};
