import React from "react";
import {
  Grid,
  Hidden,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { Calendar } from "../../../assets";
import Clock from "../../../assets/manageAvailability/Clock.png";
// import { months, weekDays, options } from "../../../utils";
import { weekDays } from "../../../utils";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    background: "#fafafa",
    borderRadius: 8
  },
  days: {
    marginLeft: 8
  },
  dayRow: {
    margin: "8px 0px"
  },
  dayRowMargin: {
    margin: "8px 0px"
  },
}));

const OperationalHours = ({ operationalHours, hideClockIcon=false, available247=false }) => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isBelow500 = useMediaQuery(theme.breakpoints.down(500));
  const iconColor = theme.palette.primary.main

  const calendarStyle = {
    fontSize: "1.3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };

  const classes = useStyles();

  const getFormattedDays = (slot) => {
    let days = slot?.days
    if(available247){
      days = weekDays?.map(x => x.label)
      if(days?.length > 1){
        return `${days.slice(0, -1).join(', ')} & ${days.slice(-1)}`
      } else {
        return `${days.slice(0).join()}`
      }
    }
    let formattedDays = weekDays.filter(day => days?.includes(day.label?.toLowerCase()))?.map(x => (x.label))
    if(formattedDays?.length > 1){
      return `${formattedDays.slice(0, -1).join(', ')} & ${formattedDays.slice(-1)}`
    } else {
      return `${formattedDays.slice(0).join()}`
    }
  }

  const getFormattedTime = (slot) => {
    if(available247){
      return `Available all day (24/7)`
    }
    let fromTime = slot?.timeSlot?.[0]
    let toTime = slot?.timeSlot?.[1]
    const getTimeString = (time) => {
      return moment(time).isValid() ? moment(time)?.format('hh:mm A') : "NA"
    }
    return `${getTimeString(fromTime)} - ${getTimeString(toTime)} (${slot?.timeZone?.country})`
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {/* <Grid item container> */}
          {/* Days of week */}
          <Grid item container xs={12} md={hideClockIcon ? 12 : 8}>
            {!available247 && operationalHours?.map((slot) => {
              return (
                <>
                  {/* Row 1 */}
                  <Grid
                    item
                    container
                    alignItems="center"
                    className={`${isSmDown && classes.dayRow} ${classes.dayRowMargin}`}
                  >
                    <Grid item>
                      <Calendar htmlColor={iconColor} style={calendarStyle} />
                    </Grid>
                    <Grid item xs>
                      <Typography className={classes.days}>
                        {/* {slot?.days?.join(", ")} */}
                        {getFormattedDays(slot)}
                      </Typography>
                    </Grid>
                    <Grid item xs={isBelow500 ? 12 : true} style={{display: "flex"}}>
                      {isBelow500 && <Calendar htmlColor={iconColor} style={{...calendarStyle, opacity: 0}} />}
                      <Typography style={{paddingLeft: isBelow500 ? 8 : 16}} >{getFormattedTime(slot)}</Typography>
                    </Grid>
                  </Grid>
                </>
              );
            })}
            {available247 && <>
                  {/* Row 1 */}
                  <Grid
                    item
                    container
                    alignItems="center"
                    className={`${isSmDown && classes.dayRow} ${classes.dayRowMargin}`}
                  >
                    <Grid item>
                      <Calendar htmlColor={iconColor} style={calendarStyle} />
                    </Grid>
                    <Grid item xs>
                      <Typography className={classes.days}>
                        {/* {slot?.days?.join(", ")} */}
                        {getFormattedDays()}
                      </Typography>
                    </Grid>
                    <Grid item xs={isBelow500 ? 12 : true} style={{display: "flex"}}>
                      {isBelow500 && <Calendar htmlColor={iconColor} style={{...calendarStyle, opacity: 0}} />}
                      <Typography style={{paddingLeft: isBelow500 ? 8 : 16}}>{getFormattedTime()}</Typography>
                    </Grid>
                  </Grid>
                </>}
          </Grid>
          {/* Clock UI */}
          <Hidden smDown>
            {!hideClockIcon && <Grid item container md alignItems="stretch" justify="center">
              <img src={Clock} alt="Clock" style={{ maxHeight: "10rem" }} />
            </Grid>}
          </Hidden>
        {/* </Grid> */}
      </Grid>
    </div>
  );
};

export default OperationalHours;
