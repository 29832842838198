import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || "#9297a5"
  }),
  cls2: (props) => ({
    fill: "none",
    stroke: props.color || "#9297a5",
    strokeMiterlimit: 10,
    strokeWidth: 2
  }),
  cls3: (props) => ({
    fill: "none",
    stroke: props.color || "#9297a5",
    strokeMiterlimit: 10,
  }),
  setSize: (props) => ({
    height: props.height,
  })
}))

export const SearchNewIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon className={classes.setSize}>
      <g id="Search">
        <path id="Path_674" className={classes.cls1} d="M22 20l-2 2-6-6v-2h2z" data-name="Path 674" />
        <path id="Path_675" className={classes.cls2} d="M9 3a6 6 0 1 0 6 6 6 6 0 0 0-6-6z" data-name="Path 675" />
        <path id="Path_676" className={classes.cls3} d="M13 13l2.5 2.5" data-name="Path 676" />
      </g>
    </SvgIcon>
  )
}

SearchNewIcon.propTypes = {
  /*
   * Set color for the icon 
   */
  color: PropTypes.string,
  /*
   * Set height for the icon 
   */
  height: PropTypes.number
}