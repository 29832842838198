import { FILE_URI } from "./constants";
import moment from "moment";
import { routes } from "../router/routes";

/**
 *
 * @param {string} price Price of an item
 * @returns {string} Price with 2 decimals added to the input
 */
export const priceDecimalAdder = (price) => {
  return parseFloat(price)?.toFixed(2)?.toString();
};

/**
 *
 * @param {string} src Hyperlink of the image
 * @returns {Boolean} Returns if src is like https://domain/dbname/
 */
export const isImageLinkBogus = (src) => {
  const imageURIRegex = new RegExp(`^${FILE_URI}/$`);
  return imageURIRegex.test(src);
};

/**
 *
 * @param {Date} date
 * @param {Boolean} timeNeeded
 * @returns {string} Returns string of format Do MMM YYYY, h:mm a (Eg. 22nd Jul 2021, 2:34 pm). If {@link timeNeeded} is truthy, then it returns string of format Do MMM YYYY (Eg. 22nd Jul 2021).
 */
export const formatDate = (date, timeNeeded = false) => {
  const givenDate = moment(date);
  const isValid = givenDate?.isValid();
  if (isValid) {
    return timeNeeded
      ? givenDate?.format("Do MMM YYYY, h:mm a")
      : givenDate?.format("Do MMM YYYY");
  }
};

/**
 *
 * @param {string} content The string that needs to be checked for floats.
 * @returns {number[]} Returns array of floats in the content string.
 */
export const extractFloats = (content) => {
  const regex = /[+-]?\d+(\.\d+)?/g;
  let floats = content.match(regex).map(function (v) {
    return parseFloat(v);
  });
  return floats;
};

export const getAgeText = (dob) => {
  return moment().diff(dob, "years") === 0
    ? moment().diff(dob, "days") + " days old"
    : moment().diff(dob, "years") + " years old";
};

/**
 *
 * @param {object} param0 Shape of { date: moment(), momentObject?: moment(), unitOfTime?: "days" }
 * @returns {number}
 */
export const getDateDifferenceText = ({
  date,
  momentObject = moment(),
  unitOfTime = "days",
}) => {
  return moment(date).diff(momentObject, unitOfTime);
};

/**
 * Returns expiry text message to show
 * @param {Date} trialExpiry
 * @returns {string} expiryText
 */
export const getTrialText = (trialExpiry) => {
  const difference = getDateDifferenceText({
    date: trialExpiry,
    unitOfTime: "days",
  });
  const now = moment();

  if (difference > 0 || now.isBefore(trialExpiry, "day")) {
    // let dateDifference = Math.abs(now.get("date") - moment(trialExpiry).get("date"));
    return difference === 1
      ? `Trial Expires in ${difference} day`
      : `Trial Expires in ${difference} days`;
  }

  if (difference < 0) return `Trial Expired`;

  if (difference === 0) {
    const now = moment();
    if (now.isBefore(trialExpiry)) return `Trial Expires today`;
    else return `Trial Expired`;
  }
};

export const getLoginTrialText = (trialExpiry) => {
  if (trialExpiry && moment(trialExpiry).isValid()) {
    const difference = getDateDifferenceText({
      date: trialExpiry,
      unitOfTime: "days",
    });

    const now = moment();

    if (now.isBefore(trialExpiry, "day")) {
      // let dateDifference = Math.abs(now.get("date") - moment(trialExpiry).get("date"));
      return `Your Trial Account is expiring in ${`${difference} ${
        difference === 1 ? "day" : "days"
      }`}`;
    } else if (now.isBefore(trialExpiry)) {
      return `Your Trial Account is expiring today`;
    } else {
      return `Your Trial Account is expired`;
    }
  }
};

export const isTrialAccountType = () => {
  return process.env.REACT_APP_IS_TRIAL === "true";
};

export const isClientAddEditForm = (path) => {
  return [routes.add_clients, routes.edit_clients].includes(path);
};

export const checkTrialExpiryRole = (role) => {
  return [
    "super-admin",
    "provider_admin",
    "provider_supervisor",
    "service_coordinator",
  ]?.includes(role);
};

// Function that takes a space seperated string and returns camelcase string with whitespace removed and append a ID at the end
export const createTestProps = (str = "default test id", id) => {
  let testId = str
    ?.toLowerCase()
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "");

  if (!!id) {
    testId = testId.concat(`${id}`);
  }

  // console.log(`%c ${testId}`, "background: #4B3F72; color: #ffffff");

  return { id: testId, "aria-labelledby": testId };
};

/**
 * @param {string} imageId
 */
export const createProfileUrl = (imageId) => {
  if (!!imageId) return null;
  return `${FILE_URI}/${imageId}`;
};

// Function to capitalize words
export const toCapitalize = (text) => {
  return text?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getAgeTextInYears = (ageValue) => {
  const age = typeof ageValue === "number" ? ageValue : parseInt(ageValue);
  return age === 1 ? `${age} year` : `${age} years`;
};

export const getAgeTextInLeastGranularUnit = (date) => {
  const now = moment();
  const years = now.diff(date, "years");
  const months = now.diff(date, "months");
  const days = now.diff(date, "days");

  if (years >= 1) {
    return `${years} ${years === 1 ? "year" : "years"} old`;
  } else if (months >= 1) {
    return `${months} ${months === 1 ? "month" : "months"} old`;
  } else if (days >= 1) {
    return `${days} ${days === 1 ? "day" : "days"} old`;
  }
};

export const getFormattedGenderAgeString = (gender, dob) => {
  const textArray = [];
  if (gender) textArray.push(toCapitalize(gender));
  if (dob) textArray.push(getAgeTextInLeastGranularUnit(dob));
  return textArray.join(", ");
};

export const getFormattedAddress = (address = {}) => {
  const { addressLine1, addressLine2, area, state, country, postalCode } =
    address;

  let formattedAddress = "";

  if (addressLine1) {
    formattedAddress += `${addressLine1},`;
  }
  if (addressLine2) {
    formattedAddress += ` ${addressLine2},`;
  }
  if (area) {
    formattedAddress += ` ${area},`;
  }
  if (state) {
    formattedAddress += ` ${state},`;
  }
  if (country) {
    formattedAddress += ` ${country},`;
  }
  if (postalCode) {
    formattedAddress += ` ${postalCode}`;
  }

  return formattedAddress;
};

export const getFormmattedNameWithSalutation = (name, salutation) => {
  if (!salutation) return name;

  const hasDot = /\./;
  if (hasDot.test(salutation)) return `${toCapitalize(salutation)} ${name}`;
  return `${toCapitalize(salutation)}. ${name}`;
};
