import * as React from "react"

export function MiniGraphOne(props) {
  return (
    <svg width={78.703} height={62.524} viewBox="0 0 78.703 62.524" {...props}>
      <defs>
        <style>
          {
            ".prefix__c,.prefix__e{fill:none}.prefix__b,.prefix__c{stroke:#f27d35}.prefix__b{fill:#fff}.prefix__c{stroke-width:.5px;opacity:.48}.prefix__d{stroke:none}"
          }
        </style>
      </defs>
      <path
        d="M2.924 46.591l8.651-7.187 4.658 3.677 2.047-18.258 16.049 9.778 11.933 6.07h0s.808-27.1 1.238-27.238 17.644 2.571 17.644 2.571l6.987-7.354 2.993-5.014"
        strokeLinecap="round"
        strokeWidth={2}
        stroke="#f27d35"
        fill="none"
      />
      <g className="prefix__b" transform="translate(71.274)">
        <circle className="prefix__d" cx={3.714} cy={3.714} r={3.714} />
        <circle className="prefix__e" cx={3.714} cy={3.714} r={3.214} />
      </g>
      <g className="prefix__b" transform="translate(0 42.524)">
        <ellipse className="prefix__d" cx={4} cy={3.5} rx={4} ry={3.5} />
        <ellipse className="prefix__e" cx={4} cy={3.5} rx={3.5} ry={3} />
      </g>
      <path
        className="prefix__c"
        d="M9.118 41.476v21.048M14.07 41.476v21.048M19.022 25.381v37.143M23.975 29.095v33.429M28.927 31.571v30.952M33.879 35.286v27.238M38.832 36.523v26M43.784 40.238v22.286M48.736 13v49.524M53.689 14.238v48.286M58.641 14.238v48.286M63.594 15.476v47.048M68.546 13v49.524M73.499 6.809v55.714"
      />
    </svg>
  )
}
