import * as React from "react"

export const AddImageIcon = (props) => {

    const {
        width = "22",
        height = "20",
        color = "#6f6f6f"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 22 20"
        >
            <path id="icons8-add-image" d="M4,4A2.015,2.015,0,0,0,2,6V18a2.015,2.015,0,0,0,2,2h8V18H4V6H20v6h2V6a2.015,2.015,0,0,0-2-2Zm10.5,7L11,15,8.5,12.5,5.777,16H16V13ZM18,14v4H14v2h4v4h2V20h4V18H20V14Z" transform="translate(-2 -4)"
                fill={color} 
            />
        </svg>
    );
};
