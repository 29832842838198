import React from 'react'

import { Grid } from '@material-ui/core'
import RecommendCard from '../../../../components/recommendDeviceCard'
import { RecommendedEquipmentLoaders, RecommendedEquipmentErrors } from '../../../../components/recommendDeviceCard'
import { NotFoundSection } from '../../../../components'

export const RecommendSection = (props) => {
  let { data, loading, error } = props?.data;
  // loading = true
  // error = true
  return (
    <Grid container spacing={4}>
      {loading && <RecommendedEquipmentLoaders count={4} />}
      {error && <RecommendedEquipmentErrors count={4} />}
      {!loading && !error && data?.length > 0 && data?.map((_, key) => {
        return (
          <Grid key={key} item xs={12} sm={6} md={6} lg={3}>
            <RecommendCard {..._} />
          </Grid>
        )
      })}
      {!loading && !error && data?.length === 0 && <div style={{ width: "100%" }}>
        <NotFoundSection message="No equipments to recommend" top="20px"/>
      </div>}
    </Grid>
  )
}