import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CustomAvatar } from "../../../../../../components";
import {
  getFormattedGenderAgeString,
  getFormmattedNameWithSalutation,
} from "../../../../../../utils/common";
import InfoWithIcon from "./infoWithIcon";
import ArrivalTime from "./arrivalTime";
import PropTypes from "prop-types";

const UserSummaryContainer = styled.div`
  display: flex;
  gap: 16px;
  margin: 0px 0px 17px 0px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    // bottom: 150,
    // left: '-45px',
    minWidth: 290,
    backgroundColor: "white",
    // border: `0.6px solid #C7C7C7`,
    borderRadius: 10,
    // boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    boxShadow: `0 15px 50px #0000002E`,
    padding: "14px 20px 24px 16px",
    zIndex: 100,
  },
  title: {
    marginBottom: 17,
    fontSize: "18px",
    fontWeight: 400,
  },
  name: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 6,
    lineHeight: "21px",
  },
  genderAge: {
    fontSize: 14,
    fontWeight: 400,
    fontStyle: "italic",
    lineHeight: "19px",
  },
}));

function CustomMapInfoWindow({ appointmentDetails, arrivalTime, travelledPercentage }) {
  const classes = useStyles();

  const {
    client: { profile_pic, salutation, name, gender, dob },
    name: appointmentName,
    fromTime,
    toTime,
  } = appointmentDetails;

  return (
    <div className={classes.root}>
      {/* Arrival Time */}
      <ArrivalTime durationText={arrivalTime} travelledPercentage={travelledPercentage} />

      {/* User Summary */}
      <UserSummaryContainer>
        <CustomAvatar
          src={profile_pic}
          size={40}
          classes={{ root: { margin: 0 } }}
        />
        <div>
          <Typography className={classes.name}>
            {getFormmattedNameWithSalutation(name, salutation)}
          </Typography>
          <Typography className={classes.genderAge}>
            {getFormattedGenderAgeString(gender, dob)}
          </Typography>
        </div>
      </UserSummaryContainer>

      {/* Appointment details */}
      <InfoWithIcon
        iconName={"classicCalendar"}
        title={appointmentName}
        fromTime={fromTime}
        toTime={toTime}
      />
    </div>
  );
}

export default CustomMapInfoWindow;

CustomMapInfoWindow.propTypes = {
  name: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  age: PropTypes.number.isRequired,
};

CustomMapInfoWindow.defaultProps = {
  name: "Miss. Helen Angel",
  gender: "female",
  age: 19,
};
