import { routes } from "../../../router/routes"

export const notifyTypeBesedOnEntryStatus = {
    "notify": {
        "1": "1",
        "9": "2",
    },
    "verify": {
        "2": "6",
        "3": "3",
        "10": "7",
        "11": "8",
    },
    "register": {
        "7": "9",
        "8": "4",
        "10": "10",
        "11": "11",
    },
    "investigate": {
        "5": "12",
        "6": "5"
    }
}

export const DependencyType = [
    {
        value: "form",
        label: "Form"
    },
    {
        value: "section",
        label: "Sections"
    },
    {
        value: "question",
        label: "Question"
    }
]

export const crud = {
    'create': 'create',
    'read': 'read',
    'update': 'update',
    'delete': 'delete',
};

export const subNavBarArr = [
    {
        id: 'disease_masters',
        prioriy: 2,
        name: 'Manage Disease',
        url: routes.list_diseases,
    },
    {
        id: 'manage_forms',
        prioriy: 3,
        name: 'Manage Forms',
        url: routes.manage_forms,
    },
    {
        id: 'country_masters',
        prioriy: 4,
        name: 'Manage Countries',
        url: routes.list_countries,
    },
    {
        id: 'manage_roles',
        prioriy: 5,
        name: 'Manage Roles',
        url: routes.roles,
    },
    {
        id: 'manage_user_groups',
        prioriy: 6,
        name: 'Manage User',
        url: routes.users,
    },
    {
        id: 'notify',
        prioriy: 7,
        name: 'Notification',
        url: routes.notify
    },
    {
        id: 'notification',
        prioriy: 8,
        name: 'Notification Registry',
        url: routes.notifications
    },
    {
        id: 'Caseverification',
        prioriy: 9,
        name: 'Case Verification',
        url: routes.verifications
    },
    {
        id: 'investigation',
        prioriy: 11,
        name: 'Investigation',
        url: routes.investigations
    },
    {
        id: 'caseRegistration',
        prioriy: 10,
        name: 'Case Registrations',
        url: routes.case_registration
    },
    {
        id: 'powerLens',
        prioriy: 12,
        name: 'Power Lens',
        url: routes.power_lens
    },
    {
        id: 'r3Menus',
        prioriy: 13,
        name: 'R3',
        url: routes.r3
    },
    {
        id: 'managePatients',
        prioriy: 14,
        name: 'Manage Patients',
        url: routes.manage_patients
    },
    {
        id: 'dashboard',
        prioriy: 1,
        name: 'Dashboard',
        url: routes.dashboard_page
    },
    {
        id: 'home',
        prioriy: 0,
        name: 'Home',
        url: routes.home_page,
    },
]
