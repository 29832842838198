import React from "react";
import {
  Button,
  Grid,
  Typography,
  Container,
  TextField,
  IconButton,
  Hidden
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { routes } from "../../router/routes";
import { withStyles } from "@material-ui/core/styles";
// import { styles } from "./style";

// import { NavLink } from "react-router-dom";
// import Card from "@material-ui/core/Card";

// SubScreenComponents
import HeaderLogo from "../../components/login/headerLogo";
// import LeftPane from "../../components/login/LeftPane";
import LeftPane from "../login/leftPane";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { AuthContext } from "../../contexts";
import { alertProps, localStorageKeys } from "../../utils";

// validation - utils

import {
  minCheck,
  minNumberAndSymbolCheck,
  charCaseCheck
} from "../../utils/validations";

// Icons
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { errorMessage } from "../../utils/errorMessages";
import { withAlertAndDialog } from "../../HOCs";

// import { AuthContext } from '../../contexts';
// const { LOGOUT_AUTH } = actions;

const styles = (theme) => ({
  wrapper: {
    height: "100vh",
    background: "white"
  },
  root: {
    height: "100%"
  },
  hero: {
    height: "85%"
  },
  rightPaneWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  rightPane: {
    width: "100%"
  },
  mainHeading: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 4
    },
    marginTop: 68,
    fontSize: "1.5rem",
    fontWeight: "bold"
  },
  subHeading: {
    fontSize: "1rem",
    color: theme.palette.secondary.main
  },
  passwordField: {
    width: "100%",
    marginTop: 32
  },
  //   passwordField: {
  //     width: "100%",
  //     marginTop: 24
  //   },
  errorMessage: {
    color: `${theme.palette.error.main}`
  },
  resetButton: {
    marginTop: 32,
    padding: 8,
    "&:hover": {
      transition: "0.5s",
      background: `${theme.palette.primary.main}`,
      opacity: 0.9
    }
  },
  validationListContainer: {
    listStyle: "none",
    textAlign: "left",
    paddingLeft: 0
  },
  validationListItemStartIcon: {
    display: "inline",
    margin: "0px 3px"
  },
  
  // Custom Header Style
  header: {
    height: "10%",
    // height: 44,
    [theme.breakpoints.down("sm")]: {
      height: 60,
      textAlign: "center",
      marginTop: 32
    }
  },
});

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldpassword:"",
      password: "",
      confirmPassword: "",
      passwordVisibility: false,
      checkPasswordValidity: {
        minCharCheck: {
          desc: "contains at least 8 characters",
          validity: null
        },
        minNumberAndSymbolCheck: {
          desc: "contains at least one number (0-9) and a symbol",
          validity: null
        },
        charCaseCheck: {
          desc: "contains both lower (a-z) and upper case letter (A-Z)",
          validity: null
        }
        // nameEmailAsPasswordCheck: {
        //   desc: "does not contain your name or email address",
        //   validity: null
        // },
        // commonPasswordCheck: {
        //   desc: "is not commonly used or a previous password",
        //   validity: null
        // }
      },
      fieldsToValidate: ["password", "confirmPassword"],
      error: {},
      errorText: {},
      isError: false
    };
  }

  // Handles validation

  // Handles input change
  handleChange = (e, inputField) => {
    if (!e.target.value?.includes(" ")) {
      this.setState({
        [inputField]: e.target.value
      });
    }
    if (inputField === "password") {
      this.setState((prevState) => {
        // console.log(prevState)
        return {
          checkPasswordValidity: {
            minCharCheck: {
              ...prevState.checkPasswordValidity.minCharCheck,
              validity: minCheck(prevState.password) //call the validity function testing regex
            },
            minNumberAndSymbolCheck: {
              ...prevState.checkPasswordValidity.minNumberAndSymbolCheck,
              validity: minNumberAndSymbolCheck(prevState.password)
            },
            charCaseCheck: {
              ...prevState.checkPasswordValidity.charCaseCheck,
              validity: charCaseCheck(prevState.password)
            }
            // nameEmailAsPasswordCheck: {
            //   ...prevState.checkPasswordValidity.nameEmailAsPasswordCheck,
            //   validity: true
            // },
            // commonPasswordCheck: {
            //   ...prevState.checkPasswordValidity.commonPasswordCheck,
            //   validity: true
            // }
          }
        };
      });
    }
  };

  endAdornmentDisplay = () => {
    let checkPasswordValidity = this.state.checkPasswordValidity;
    if (
      (checkPasswordValidity.minCharCheck.validity ||
        checkPasswordValidity.minNumberAndSymbolCheck.validity ||
        checkPasswordValidity.charCaseCheck.validity) !==
      // || checkPasswordValidity.nameEmailAsPasswordCheck.validity ||
      // checkPasswordValidity.commonPasswordCheck.validity
      null
    ) {
      if (
        checkPasswordValidity.minCharCheck.validity &&
        checkPasswordValidity.minNumberAndSymbolCheck.validity &&
        checkPasswordValidity.charCaseCheck.validity
        // && checkPasswordValidity.nameEmailAsPasswordCheck.validity &&
        // checkPasswordValidity.commonPasswordCheck.validity
      ) {
        return (
          <>
            <DoneIcon htmlColor="#31b198" />
          </>
        );
      } else {
        return (
          <>
            <CloseIcon htmlColor="rgba(188, 45, 79, 1)" />
          </>
        );
      }
    } else {
      return "";
    }
  };

  passwordVisibilityHandler = () => {
    this.setState(
      (prevState) => {
        return {
          passwordVisibility: !prevState.passwordVisibility
        };
      },
      () => {
        console.log(this.state.passwordVisibility);
      }
    );
  };

  handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      document.getElementById("resetButton").click();
    }
  };

  handleValidation() {
    const { error, errorText, fieldsToValidate } = this.state;
    let isError = false;
    const validate = (field) => {
        
      if (!this.state[field]) {
        error[field] = true;
        errorText[field] = errorMessage.requiredMessage;
        isError = true;
      } else {

        if (field === "password"){
            const { checkPasswordValidity } = this.state
            
            let isPasswordValid = true

            Object.keys(checkPasswordValidity).map(key => {
                isPasswordValid = isPasswordValid && checkPasswordValidity[key]?.validity
                return key
            })

            if(!isPasswordValid){
                error[field] = true;
                errorText[field] = errorMessage.strongerPassword;
                isError = true;
            } else {
                error[field] = false;
            }
        }

        if (field === "confirmPassword") {
            if(this.state["password"] !== this.state[field]){
                error[field] = true;
                errorText[field] = errorMessage.passwordMismatch;
                isError = true;
            } else {
                error[field] = false;
            }
        }
      }
    };

    fieldsToValidate.map((val) => {
      validate(val);
      return val
    });

    this.setState({
        error: error,
        errorText: errorText,
        isError: isError
    })

    return isError
  }

  handleResetButton = () => {
    
    if(!this.handleValidation()){
      const query = this.useQuery()
      const emailSecret = query.get("emailSecretKey")
      const id = this.props.match.params?.id
      //
      //console.log("emailId:", this.props.match)

      const payload = {
        user_id: id,
        oldpassword: this.state.oldpassword,
        password: this.state.password,
        emailsecret: emailSecret
      }

      this.props.RESET_AUTH(payload);

      } else {
        // window.alert("Failed")
        console.log("Failed")
    }
  };

  logoutAndRefresh = () => {
      // const authContext = React.useContext(AuthContext); 
      this.context.setAuth({...this.context.auth,refresh: false });
      // dispatch(LOGOUT_AUTH());
      this.props.LOGOUT_AUTH()
      localStorage.removeItem(localStorageKeys._mGdRe);
      this.props.history.push(routes.login);
  }

  useQuery = () => {
    //console.log(this.props.location)
    return new URLSearchParams(this.props.location.search);
  }

  shouldComponentUpdate(nextProps, nextState) {
    //Add Supervisor
    const { resetPassword } = nextProps;
    const oldresetPassword = this.props.resetPassword;

    //Checking for Add Supervisor messages
    if (oldresetPassword?.loading !== resetPassword?.loading) {
      if (!resetPassword.loading && !resetPassword.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Password Changed successfully!",
          severity: alertProps.severity.success,
        });
        //Call logout

        //------------------------------Temporary Disabled ----------------------------------------
        this.logoutAndRefresh()
      } else if (resetPassword.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: resetPassword?.message ?? "Something went wrong! Unable to change password.",
          severity: alertProps.severity.error,
        });
      }
    }
    return true
  }


  render() {
    // const { username, password, passwordVisibility } = this.state;
    // const { classes } = this.props;

    const { loading} = this.props?.resetPassword;
    const { classes } = this.props;
    const { password, confirmPassword, passwordVisibility, oldpassword } = this.state;
    const { checkPasswordValidity } = this.state;

    return (
      <Container maxWidth="lg" className={classes.wrapper}>
        <div className={classes.root}>
          <Hidden smDown>
            <HeaderLogo />
          </Hidden>
          <Grid container alignItems={"stretch"} className={classes.hero}>
            <Hidden smDown>
              {/* Left pane illustration */}
              <LeftPane />
            </Hidden>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.rightPaneWrapper}
            >
              <Grid container item xs={12} sm={12} md={10}>
                <div className={classes.rightPane}>
                  <Hidden mdUp>
                    <HeaderLogo customHeaderStyle={classes.header}  />
                  </Hidden>
                  <Typography
                    variant="h2"
                    component="h2"
                    align={"center"}
                    className={classes.mainHeading}
                  >
                    Change Password
                  </Typography>

                  {/* Old Password */}
                  <TextField
                    variant="outlined"
                    label="Old Password"
                    type={passwordVisibility ? "text" : "password"}
                    autoComplete="off"
                    value={oldpassword}
                    onChange={(e) => this.handleChange(e, "oldpassword")}
                    onKeyUp={this.handleKeyPress}
                    style={{ width: "100%" }}
                    error={this.state.error["oldpassword"] ? true : false}
                    helperText={this.state.error["oldpassword"] ? this.state.errorText["oldpassword"]: ""}
                  />




                  {/* Password Field */}
                  <TextField
                    variant="outlined"
                    label="New Password"
                    type={passwordVisibility ? "text" : "password"}
                    autoComplete="off"
                    className={classes.passwordField}
                    InputProps={{
                      endAdornment: (
                        <>
                          {this.endAdornmentDisplay()}
                          <IconButton onClick={this.passwordVisibilityHandler}>
                            {passwordVisibility ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </>
                      )
                    }}
                    value={password}
                    onChange={(e) => this.handleChange(e, "password")}
                    onKeyUp={this.handleKeyPress}
                    style={{ width: "100%" }}
                    error={this.state.error["password"] ? true : false}
                    helperText={this.state.error["password"] ? this.state.errorText["password"]: ""}
                  />

                  {/* Validations Check ✓ */}
                  <ul className={classes.validationListContainer}>
                    {Object.keys(checkPasswordValidity).map(
                      (objectKey, index) => {
                        return (
                          <li
                            key={index}
                            style={{
                              color: `${
                                checkPasswordValidity[objectKey].validity
                                  ? "#31b198"
                                  : "#9297a5"
                              }`,
                              margin: "4px 0px"
                            }}
                          >
                            <div
                              className={classes.validationListItemStartIcon}
                            >
                              {checkPasswordValidity[objectKey].validity ? (
                                <>&#10003;</>
                              ) : (
                                <>&bull;</>
                              )}{" "}
                            </div>
                            {checkPasswordValidity[objectKey].desc}
                          </li>
                        );
                      }
                    )}
                  </ul>

                  {/* Confirm Password Field */}
                  <TextField
                    variant="outlined"
                    label="Confirm Password"
                    type={passwordVisibility ? "text" : "password"}
                    autoComplete="off"
                    value={confirmPassword}
                    onChange={(e) => this.handleChange(e, "confirmPassword")}
                    onKeyUp={this.handleKeyPress}
                    style={{ width: "100%" }}
                    error={this.state.error["confirmPassword"] ? true : false}
                    helperText={this.state.error["confirmPassword"] ? this.state.errorText["confirmPassword"]: ""}
                  />

                  {/* Common Error message */}
                  {/* {error && ( 
                    <Typography
                      align={"center"}
                      className={classes.errorMessage}
                      style={{ marginTop: 8 }}
                    >
                      {message}
                    </Typography>
                  )} */}

                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    id="resetButton"
                    disabled={loading}
                    fullWidth
                    className={classes.resetButton}
                    onClick={this.handleResetButton}
                  >
                    {loading ? "Loading..." : "RESET"}
                    
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ height: "5%" }}></Grid>
        </div>
      </Container>
    );
  }
}

ResetPassword.contextType = AuthContext;

const mapStateToProps = (state) => ({
  loginState: state?.authState?.loginAuth,
  resetPassword: state?.authState?.resetPasswordFromEmail
});

export default connect(
  mapStateToProps,
  actions
)(withAlertAndDialog(withStyles(styles, { withTheme: true })(withRouter(ResetPassword))));
