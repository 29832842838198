import React from "react";
import {
  CountCardLoaders,
  CountCardErrors,
  CountCards,
  InternatlServerError,
  FilterBuilder,
} from "../../../../components";
import { Grid, Typography, withStyles } from "@material-ui/core";
import { CountCardIcons } from "../../../../components/countCard/index";
import MainIssueCard from "../../../../components/clientIssueCard";
import SubHeader from "../../../../components/subHeader";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withAlertAndDialog } from "../../../../HOCs";
import { routes } from "../../../../router/routes";
import { withRouter } from "react-router-dom";
import { scrollToTop, sortDirection, sortFields, fontStyleClass, filterTagType, subHeaderSortOptionWithValue, isCreatedByManagedCareAdminById, OTHER_ROLES, user_roles } from "../../../../utils";
import ListLoader from "../../../../components/listLoader";
import SendQuote from "./createQuote/index"
import ViewQuoteHistory from "./createQuote/viewQuoteHistory";
import { filterComponent } from "./filterComponent";
import FilterTags from "../../../../components/filters/filterTags";
import AddEditSubscription from "../../../userManagement/clients/detail/addEditSubscription"
const styles = (theme) => ({
  root: {
    padding: 25,
  },
  trackerCardsContainer: {
    marginBottom: 30,
  },
  ...fontStyleClass,
});
const initialState = {
  pageNo: 0,
  pageSize: 25,
  // anchorEl: null,
  // searchText: "",
  searchValue: "",
  // sortfield: sortFields.product.name,
  // sortfield: sortFields.product.name,
  sortDirec: subHeaderSortOptionWithValue[2],
  // sort: subHeaderSortOption[2].value,
  // Filter States
  category: [],
  provider: [],
  services: [],
  status: undefined,
  enquiry_type: null,
  assigned_to: null,
  isFilterApplied: false,
  open: false,
  popperData: []
}
class ListEnquiry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      id:"ListingEnquiry"
    };
  }

  componentDidMount() {
    this.getEnquiries();
    scrollToTop();
    window.addEventListener("scroll", this.scrolling);
  }

  getEnquiries = () => {
    let _status = this.state?.status
    this.props.GET_ALL_ENQUIRIES({
      ...this.state,
      enquiry_type: this.state?.enquiry_type ? this.state?.enquiry_type.map(val => val.value) : [],
      sort: this.state?.sortDirec?.value,
      status: _status?.replace(" ", "_")?.toLowerCase(),
      assignedResource: this?.state?.assigned_to ? this?.state?.assigned_to.map(val => val.value) : null,
      limit:this.state.pageSize,
      offset:this.state.pageNo,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  }

  scrolling = () => {
    let { loading, data, error } = this.props?.enquiry;

    let listCount = data?.list?.length ?? 0;
    let totalCount = data?.total_count ?? 0;

    if (loading || error || totalCount === 0 || listCount === 0) {
      return false;
    }

    if (
      Math.round(window.scrollY + window.innerHeight) >=
        document.getElementsByTagName("body")[0].scrollHeight &&
        listCount !== totalCount
    ) {
      this.setState((state, props) => ({
        pageNo: state.pageNo + 25,
      })
        , () => {
          this.getEnquiries();
        });
    }
  }


  storeInputSearchValue = (value) => {
    this.setState({ searchValue: value, pageNo: 0, pageSize: 25 }, () => {
      // if (value.length % 3 === 0) {
      this.getEnquiries();
      // }
    });
  };
  handleSortChange = (e) => {
    this.setState(
      {
        sort: e,
        pageNo: 0,
        pageSize: 25,
      },
      () => {
        this.getEnquiries();
      }
    );
  };

  // -----------------------FILTERS-------------------
  // FILTERS FUNCTION
  // Filter Handlers
  onFilterIconClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: <FilterBuilder
        components={filterComponent(this.state, this.props)}
        applyButtonLabel="Apply"
        loading={this.props?.package?.loading}
        onChange={this.onFilterChange}
        onApplyButtonClicked={this.applyFilter}
        resetFilter={this.resetFilter}
        onCancelClick={this.closeFilter}
        saveButtonId={`saveFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
        cancelButtonId={`cancelFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
        clearAllButtonId={`clearAllFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
      />
    })
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  anyFilterApplied = (state) => {
    let { category = [], assigned_to, enquiry_type, provider = [], status = "", sortDirec = sortDirection.desc, sortfield = sortFields.product.name } = state
    if (category === null) category = initialState.category
    if (provider === null) provider = initialState.provider
    if (assigned_to === null) assigned_to = initialState.assigned_to
    if (enquiry_type === null) enquiry_type = initialState.enquiry_type
    if (JSON.stringify(category) === JSON.stringify(initialState.category) &&
      JSON.stringify(provider) === JSON.stringify(initialState.provider) &&
      JSON.stringify(enquiry_type) === JSON.stringify(initialState.enquiry_type) &&
      JSON.stringify(assigned_to) === JSON.stringify(initialState.assigned_to) &&
      status === initialState.status &&
      sortDirec.value === initialState.sortDirec.value &&
      sortfield.value === initialState.sortfield.value) {
      return false
    } else {
      return true
    }
  }

  applyFilter = (state) => {
    this.setState({
      ...state,
      pageNo: 0,
      pageSize: 25,
      isFilterApplied: this.anyFilterApplied(state),
    }, () => {
      this.getEnquiries();
      this.closeFilter()
    })
  }

  closeFilter = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    })
  }

  resetFilter = () => {
    this.setState({
      ...initialState
    }, () => {
      this.getEnquiries();
      this.closeFilter();
    })
  }


  // FILTER TAG FUNCTION
  // Filter Tag Utils
  checkIsThereAnyFilter = () => {
    let filterTagType1 = filterTagType;
    let newState = {
      ...this.state,
      category: this.state.category === null ? initialState.category : this.state.category,
      provider: this.state.provider === null ? initialState.provider : this.state.provider,
      assigned_to: !this.state.assigned_to ? initialState.assigned_to : this.state.assigned_to,
      enquiry_type: !this.state.enquiry_type ? initialState.enquiry_type : this.state.enquiry_type,
      status: !this.state.status ? initialState.enquiry_type : this.state.status,
    }
    if (newState[filterTagType1.Category]?.length === 0 &&
      newState[filterTagType1.Provider]?.length === 0 &&
      newState[filterTagType1.Enquiry_type] === null &&
      newState[filterTagType1.Assigned_to] === null &&
      //TODO: //newState[filterTagType1.Services].length === 0 && 
      newState[filterTagType1.Status] === null
      // newState[filterTagType1.SortDirection]?.value === initialState.sortDirec?.value &&
      // newState[filterTagType1.SortField]?.value === initialState.sortfield?.value
    ) {

      return false
    } else {

      return true
    }
  }

  handleChipDelete = (key, index) => {
    if (key === filterTagType.Enquiry_type ||
      key === filterTagType.Assigned_to
      //TODO: //|| key === filterTagType.Services
    ) {
      let newState = this.state
      newState[key].splice(index, 1)
      if (newState[key].length === 0) {
        newState[key] = null
      }
      this.setState({
        ...newState,
        pageNo: 0,
        pageSize: 25,

      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter(newState)
        }, () => {
          this.getEnquiries()
        })
      })

    }
    if (key === filterTagType.Status) {
      this.setState({
        status: initialState.status,
        pageNo: 0,
        pageSize: 25,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter(this.state)
        })
        this.getEnquiries()
      })
    }
    if (key === filterTagType.SortDirection) {
      this.setState({
        sortDirec: initialState.sortDirec,
        pageNo: 0,
        pageSize: 25,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter(this.state)
        })
        this.getEnquiries()
      })
    }
    // if (key === filterTagType.SortField) {
    //   this.setState({
    //     sortfield: initialState.sortfield,
    //     pageNo: 0,
    //     pageSize: 25,
    //   }, () => {
    //     this.setState({
    //       isFilterApplied: this.checkIsThereAnyFilter()
    //     })
    //     this.getEnquiries()
    //   })
    // }
  }

  // -----------------------------------------------
  render() {
    const { classes } = this.props;
    let { data, loading, error } = this.props?.enquiry;

    const isBusinessAdmin = this.props?.auth_data?.data?.user_role === user_roles.business_admin
    const isMCAdmin = this.props?.auth_data?.data?.other_roles?.includes(OTHER_ROLES.crossBundling)

    // MAPPING BECAUSE THE CHILD COMPONENT IS WITH SPECIFIC KEYS
    // SO WE ARE MAPPING TO ACTUAL KEYS TO COMPONENT SPECIFIC KEYS

    let mapper = (data) => {
      let arr = [];
      data.map((val) => {
        // console.log(val.status)
        let tempObj = {};
        let name = val?.name?.split(".")
        
        tempObj["issue_status"] = val.status;
        tempObj["issue_tag"] = val.tag;
        tempObj["issue_title"] = name?.[0] === "other" ? name?.[1] : val.name;
        tempObj["lead_time"] = val.lead_Time;
        tempObj["subscription_name"] = val.raised_on;
        tempObj["id"] = val.id;
        tempObj["cancelledRemarks"] = val.cancelledRemarks;

        arr.push({
          ...tempObj,
          ...val,
        });

        return val;
      });
      return arr;
    };

    const graphs = [
      CountCardIcons.miniGraphOne,
      CountCardIcons.miniGraphTwo,
      CountCardIcons.miniGraphThree,
      CountCardIcons.miniGraphFour,
    ];
    const handleSendQuote = (id, data) => {
      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: true,
        isLarge: true,
        component: <SendQuote id={id} data={data} field_id={this.state?.id} reloadListing={this.getEnquiries} closeDrawer={closeDrawer}/>
      })
    };
    const handleRedirectConvertAsClient = (id, exitClient) => {
      if (exitClient) {
        this.props.history.push({
          pathname: routes.manage_clients + "/view/" + id
        })
      } else {
        this.props.history.push({
          pathname: routes.manage_clients + "/edit/" + id
        })
      }
    }

    const closeDrawer = () => {
      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: false,
        component: <></>
      })
    }

    const handleViewQuote = (id, data) => {

      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: true,
        isLarge: false,
        component: <ViewQuoteHistory id={id} data={data} createdBy={data?.createdBy} getEnquiries={() => this.getEnquiries()} closeDrawer={closeDrawer}/>
      })
    }
    const onAddSubscriptionClicked = (clientId) => {
      
      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: true,
        component: <AddEditSubscription
          isEnquiry={true}
          id={this.state?.id}
          clientId={clientId}
          getClientDetail={closeDrawer}
          cancelForm={closeDrawer}
        />,
        onclose: closeDrawer
      })
    }
    

    
    const handleViewInvoice = (id, data) => {
      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: true,
        isLarge: false,
        component: <ViewQuoteHistory id={id} data={data} />
        // component: <p>TESTING DATA</p>
      })
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid
            item
            container
            spacing={3}
            className={classes.trackerCardsContainer}
          >
            {loading && (
              <Grid item xs={12}>
                <CountCardLoaders count={3} />
              </Grid>
            )}
            {error && (
              <Grid item xs={12}>
                <CountCardErrors count={3} />
              </Grid>
            )}
            {data?.stats && (
              <Grid key={2} item xs={12} sm={4} md={4} lg={2} xl={2}>
                {/* {console.log(data)} */}
                <CountCards
                  count={data?.total_count }
                  label={"Total"}
                  graph={graphs[2]}
                  index={2}
                  withSubtext={false}
                />
              </Grid>
            )}
            {!loading &&
              !error &&
              data?.stats?.length &&
              (data?.stats?.slice(0, 7)).map((card, index) => {
                const mylooper = index > 3 ? index % 4 : index;
                // 
                
                return (
                  <Grid key={index} item xs={12} sm={4} md={4} lg={2} xl={2}>
                    <CountCards
                      count={card.count}
                      label={card.label}
                      graph={graphs[mylooper]}
                      index={mylooper}
                      // withSubtext={index === 3 ? "Hours" : null}
                    />
                  </Grid>
                );
              })}
            {/* {data?.stats && (
              <Grid key={2} item xs={12} sm={6} md={6} lg={3} xl={3}>
                <CountCards
                  count={data?.stats?.[0].count + data?.stats?.[1]?.count}
                  label={"Total"}
                  graph={graphs[2]}
                  index={2}
                  withSubtext={false}
                />
              </Grid>
            )} */}
            {/* {!loading && !error && data.map((card, index) => {
                            const mylooper = index > 3 ? (index % 4) : index
                            return <Grid key={index} item xs={12} sm={6} md={6} lg={3} xl={3}>
                                <CountCards count={card.count} label={card.label} graph={graphs[mylooper]} index={mylooper} />
                            </Grid>
                        })} */}
          </Grid>

          {/* Main Issue Card */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SubHeader
              isbutton={this.props.auth_data.data.user_role === user_roles.business_admin ? false : true}
              // isbutton={true}
              placeholder={"Search for Contact Names"}
              entityName={"Enquiry"}
              countText={`Total Enquiries (${data?.total_count ?? 0})`}
              button_name={"+ ADD ENQUIRY"}
              link={routes.add_enquiry}
              handleSearch={this.getEnquiries}
              value={this.state.searchValue}
              handleChangeInputSearch={this.storeInputSearchValue}
              handleSortChange={this.handleSortChange}
              showSortOption={false}
              sort={this.state.sort}
              // hideFilter
              useNewFilterIcon
              placeSearchIconToLeft
              // hideSearch

              isFilterApplied={this.state.isFilterApplied}
              onFilterIconClicked={this.onFilterIconClicked}
              id={this.state?.id}
            />
          </Grid>

          {/*TOTAL COUNT  */}
          {/* <Grid item xs={12}>
            <Typography variant="h6" className={classes.mediumFont}>
              Total Enquiries ({data?.list?.length ?? 0})
            </Typography>
          </Grid> */}
          {this.state.isFilterApplied && (
            <Grid item xs={12} style={{ marginTop: 25 }}>
              <FilterTags
                filterData={this.state}
                handleDelete={this.handleChipDelete}
              />
            </Grid>
          )}
          {loading && (
            <Grid item xs={12}>
              <ListLoader />
            </Grid>
          )}
          {!loading && error && (
            <Grid item xs={12}>
              <InternatlServerError
                message={"Something Went Wrong!"}
                onRetry={this.getEnquiries}
                top={"10vh"}
              />
            </Grid>
          )}

          {/* {!loading && !error && !data?.list?.length && (
            <Grid item xs={12}>
              <NotFoundSection
                message="You don't have any enquiry"
                top="25vh"
              />
            </Grid>
          )} */}

          {!loading && !error && !data?.list?.length > 0 && (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{ marginTop: `30vh` }}
            >
              <Grid item>
                <Typography variant="h6" align="center">
                  You don't have any Enquiries!
                </Typography>
              </Grid>
            </Grid>
          )}
          {!loading && !error && data?.list?.length > 0 &&
            mapper(data?.list).map((item, key) => {
              const isCreatedByMC = isCreatedByManagedCareAdminById(item?.createdBy, this.props?.auth_data?.data);
              return (
                <Grid
                  id={`ListOfEnquiry${key}`}
                  item
                  xs={12}
                  key={key}
                  className={classes.mainIssueCardContainer}
                >
                  <MainIssueCard
                    {...item}
                    // id={`ListOfEnquiry${key}`}
                    data={item}
                    index={key}
                    showTextWithbutton={true}
                    link={routes.enquiry_list + "/view/" + item.id}
                    id={item.id}
                    invoiceurl={item?.invoice}
                    clientId={item.clientId}
                    isExitsClient={item.isExitsClient}
                    handleRedirect={handleRedirectConvertAsClient}
                    handleClick={handleSendQuote}
                    viewAllQuotes={handleViewQuote}
                    showCreateQuoteButton={isBusinessAdmin ? false : isMCAdmin ? (isCreatedByMC ? true : false) : true}
                    showViewQuoteButton={
                      item?.quotesList?.length > 0 ? true : false
                    }
                    handleViewInvoice={handleViewInvoice}
                    onAddSubscriptionClicked={onAddSubscriptionClicked}
                    hideStatusBar={true}
                    enquiryList={true}
                    itemStatus={item.issue_status}
                    enquiry_status={
                      item?.issue_status
                        ? item.issue_status.toLowerCase() ===
                            "payment completed" ||
                          item.issue_status.toLowerCase() ===
                            "enquiry cancelled"
                          ? false
                          : true
                        : true
                    }
                  />
                </Grid>
              );
            })}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    enquiry: state?.enquiry_management?.getAllEnquires,
    auth_data: state?.authState?.loginAuth,
  };
};

export default connect(
  mapStateToProps,
  actions
)(withAlertAndDialog(withRouter(withStyles(styles)(ListEnquiry))));
