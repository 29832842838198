import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || "#9297a5"
  })
}))

export const ExperienceIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon style={props.style} viewBox={"0 0 30 30"}>
      <path className={classes.cls1} d="M11.882 3v1.412H4.824A2.823 2.823 0 0 0 2 7.235v16.941A2.826 2.826 0 0 0 4.824 27h22.588a2.826 2.826 0 0 0 2.824-2.824V7.235a2.823 2.823 0 0 0-2.824-2.824h-7.059V3zM4.824 7.235h22.588v9.882h-2.824a1.412 1.412 0 1 0-2.824 0H10.471a1.412 1.412 0 1 0-2.824 0H4.824zm0 12.706h22.588v4.235H4.824z" transform="translate(-2 -3)" />
    </SvgIcon>
  )
}

ExperienceIcon.propTypes = {
  /**Sets the color of the icon. */
  color: PropTypes.string,
  /**Adds additional styles for the icon.*/
  style: PropTypes.object
}