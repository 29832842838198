import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";
import { CustomAvatar } from "../../../../../../components/niceComponents/customAvatar";
import { PopperList } from "../../../../../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    boxShadow: "0px 5px 35px #0000000F",
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(2) - theme.spacing(1 / 2),
    border: "0.2px solid #ede5e5",
    height: "100%",
  },
  flexRow: {
    display: "flex",
  },
  regular14: {
    color: "#252B43",
    fontWeight: 400,
    fontSize: 14,
  },
  italic14: {
    color: "#9297A5",
    fontWeight: 400,
    fontSize: 14,
    fontStyle: "italic",
    marginTop: theme.spacing(1),
  },
  regular12: {
    color: "#31B198",
    fontWeight: 400,
    fontSize: 12,
    // wordBreak: "break-word",
    whiteSpace: "nowrap",
  },
  mediumFont: {
    color: theme.palette.ternary.main,
    fontWeight: 500,
    fontSize: 16,
    whiteSpace: "nowrap",
  },
  spaceBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
  },
  wrapper: {
    borderRadius: 4,
    backgroundColor: "#DEF2EE",
    padding: "5px 12px",
    width: "fit-content",
    marginRight: theme.spacing(2),
  },
  avatarWrapper: {
    display: "flex",
    marginTop: theme.spacing(1 / 2),
    marginRight: theme.spacing(1),
  },
}));

const CategoryChip = ({ category, classes }) => (
  <div className={classes.wrapper}>
    <Typography className={classes.regular12}>{category ?? "-"}</Typography>
  </div>
);

CategoryChip.defaultProps = {
  classes: {},
};

CategoryChip.propTypes = {
  category: PropTypes.string,
  classes: PropTypes.object,
};

export const SummaryCard = (props) => {
  const classes = useStyles(props);
  const maxCategoryCount = 1;

  const {
    profile_pic = "",
    label = "",
    amount = "",
    partner = "",
    categoryList = [],
    loading = false,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.flexRow}>
        <div className={classes.avatarWrapper}>
          {!loading ? (
            <CustomAvatar src={profile_pic} name={label} size={40} />
          ) : (
            <Skeleton variant="circle" width={40} height={40} />
          )}
        </div>
        <div style={{ flex: 1 }}>
          {loading ? (
            <Skeleton variant="text" width={"50%"} />
          ) : (
            <Typography className={classes.regular14}>
              {label ?? "-"}
            </Typography>
          )}
          {loading ? (
            <Skeleton variant="text" width={"50%"} />
          ) : (
            <Typography className={classes.italic14}>
              {partner ?? "-"}
            </Typography>
          )}
          <div className={classes.spaceBtn}>
            {loading ? (
              <Skeleton variant="text" width={"50%"} />
            ) : Array.isArray(categoryList) && categoryList?.length > 0 ? (
              <div style={{ display: "flex", marginRight: 12 }}>
                {categoryList?.slice(0, maxCategoryCount)?.map((category) => (
                  <CategoryChip category={category} classes={classes} />
                ))}
                {categoryList?.length > maxCategoryCount && (
                  <PopperList
                    count={categoryList?.length - maxCategoryCount}
                    list={categoryList?.slice(
                      maxCategoryCount,
                      categoryList?.length
                    )}
                  />
                )}
              </div>
            ) : (
              ""
            )}
            {loading ? (
              <Skeleton variant="text" width={"30%"} />
            ) : (
              <Typography className={classes.mediumFont}>
                {amount ?? "-"}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SummaryCard.propTypes = {
  profile_pic: PropTypes.string,
  label: PropTypes.string,
  amount: PropTypes.string,
  partner: PropTypes.string,
  categoryList: PropTypes.array,
  loading: PropTypes.bool,
};
