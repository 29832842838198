import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Hidden,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import React from "react";

import AddEditProductManagement from "../addEditForm";
import { formComponentTypes } from "../../utils";
import { TextField as CustomTextField } from "..//inputs/textField";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import { InternatlServerError, LoadingSection } from "../integerateHelper";
import { errorMessage } from "../../utils/errorMessages";
// import { SimpleSelect } from "../inputs";

const filterOptions = [
  {
    label: "Z to A",
    value: 2
  },
  {
    label: "A to Z",
    value: 1
  }
];

const style = (theme) => ({
  formControl: {
    // margin: "0px 8px",
    [theme.breakpoints.down("xs")]: {
      marginTop: 8,
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.ternary.main} !important`,
        borderWidth: "1.6px !important"
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 16
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      borderRadius: 16,
      paddingTop: 8,
      paddingBottom: 8,
    }
  },
  textField: {
    width: "100%",
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.ternary.main} !important`,
        borderWidth: "1.6px !important"
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: 8,
      paddingBottom: 8,
    }
  },
  customSelect: {
    padding: "10.500px 14px"
  },
  colorPrimary: {
    color: "white"
  },
  errorPosition: {
    position: "relative",
    top: -10
  }
});

class AdminTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      classes,
      title,
      placeholdersArray, //alternative for button name
      columns = [],
      data,
      loading,
      error,
      onRetry,
      deleteMaster,
      editMaster,
      isTax = false,
      // selectedObj,


      hideMarginTop
    } = this.props;

    // if(selectedObj.id === 8) {
    //   data = data.map((_) => {
    //     return {
    //       label: _.countryCode,
    //       value: _.countryId,
    //       countryName: _.countryName
    //     }
    //   })
    // }

    let { loading: addLoading } = this.props?.addLoadingAndError;

    const components = {
      section_1: {
        label: "",
        fields: {
          table: {
            key: "table",
            type: formComponentTypes.ADMINTABLE,
            componentProps: {
              type: "text",
              
              // This colWidths length should be equal to the number of columns in the table.
              colWidths:
                columns?.length === 4
                  ? ["10%", "25%", "45%", "20%"]
                  : ["10%", "70%", "20%"]
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12
            },
            label: "SKILLS",
            value: "",
            columns: columns,
            rows: data,
            handleRemove: this.handleRemove, //obsolete
            needActionsAsIcons: true,
            editAction: editMaster,
            deleteAction: deleteMaster,
            hideMarginTop: hideMarginTop,
          }
        }
      }
    };

    return (
      <>
        <div style={{ padding: "12px 20px 20px 20px", background: "white", borderRadius: 8 }}>
          <Grid container spacing={2}>
            {/* Top bar */}
            <Grid item container xs={12}>
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>{`${title} (${data?.length ?? 0})`}</Typography>
                </Grid>

                {/* Search and Filter Grid */}
                <Hidden xsDown>
                  <Grid item xs container style={{ display: "flex" }}>
                    <Grid item xs={isTax ? 4 : 5}></Grid>
                    <Grid item xs={ 5}>
                      <TextField
                        className={`${classes.textField}`}
                        size={"small"}
                        id={'searchAdminTable'}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon color="secondary" fontSize="small" />
                            </InputAdornment>
                          )
                        }}
                        placeholder="Search"
                        onChange={(e) =>
                          this.props.onChange("searchKey", e.target.value)
                        }
                        value={this.props.searchKey}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        style={{ marginLeft: 8, width: "100%" }}
                      >
                        <Select
                          value={this.props.sortKey}
                          id={`sortAdmintable`}
                          onChange={(e) =>
                            this.props.onChange("sortKey", e.target.value)
                          }
                          style={{
                            width: "100%"
                          }}
                          name="filter"
                          classes={{ outlined: classes.customSelect }}
                        >
                          {filterOptions?.map((item, index) => {
                            return (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {
                      isTax ? (
                        <Grid item xs={1}>
                  <Button
                    variant={"contained"}
                    id={'AddAdminRecords'}
                    color={"primary"}
                    // disabled={addLoading ? true : false}
                    disabled={(this.props.valuesArray?.includes("") || addLoading) ? true : false}
                    fullWidth
                    onClick={this.props.handleAddClick}
                    style={{
                      marginLeft: 16,
                      height: 33,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    className={`${
                      this.props.validationError[
                        placeholdersArray.map(
                          (_, index) =>
                            `newMaster${index !== 0 ? index + 1 : ""}`
                        )
                      ]
                        ? classes.errorPosition
                        : null
                    }`}
                  >
                    {addLoading ? (
                      <>
                        {`+ ADDING`}
                        <span
                          style={{
                            marginLeft: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <CircularProgress
                            size={20}
                            classes={{ colorPrimary: classes.colorPrimary }}
                          />
                        </span>
                      </>
                    ) : (
                      `+ ADD`
                    )}
                  </Button>
                  </Grid>
                      ) : <></>
                    }
                  </Grid>
                </Hidden>
                <Hidden smUp>
                  <Grid item xs={12}>
                    <TextField
                      className={`${classes.textField}`}
                      size={"small"}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon color="secondary" />
                          </InputAdornment>
                        )
                      }}
                      placeholder="Search"
                      onChange={(e) =>
                        this.props.onChange("searchKey", e.target.value)
                      }
                      value={this.props.searchKey}
                      variant="outlined"
                      style={{marginTop: 8}}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      style={{  width: "100%" }}
                    >
                    <Select
                      // value={
                      //   filterOptions.filter(
                      //     (x) => x.value === this.props?.sortKey
                      //   )[0]
                      // }
                      value={this.props?.sortKey}
                      handleOnChange={(value) =>
                        this.props.onChange("sortKey", value)
                      }
                      style={{
                        width: "100%"
                      }}
                      name="filter"
                      // options={filterOptions}
                      // isNotClearable
                      classes={{ outlined: classes.customSelect }}
                    >
                    {filterOptions?.map((item, index) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  {
                      isTax ? (
                        <Grid item xs={12}>
                  <Button
                    variant={"contained"}
                    id={'AddAdminRecords'}
                    color={"primary"}
                    // disabled={addLoading ? true : false}
                    disabled={(this.props.valuesArray?.includes("") || addLoading) ? true : false}
                    fullWidth
                    onClick={this.props.handleAddClick}
                    style={{
                      // marginLeft: 16,
                      marginTop: 8,
                      height: 33,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    className={`${
                      this.props.validationError[
                        placeholdersArray?.map(
                          (_, index) =>
                            `newMaster${index !== 0 ? index + 1 : ""}`
                        )
                      ]
                        ? classes.errorPosition
                        : null
                    }`}
                  >
                    {addLoading ? (
                      <>
                        {`+ ADDING`}
                        <span
                          style={{
                            marginLeft: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <CircularProgress
                            size={20}
                            classes={{ colorPrimary: classes.colorPrimary }}
                          />
                        </span>
                      </>
                    ) : (
                      `+ ADD`
                    )}
                  </Button>
                  </Grid>
                      ) : <></>
                    }
                </Hidden>
              </Grid>
            </Grid>

            {/* Divider */}
            <div style={{ width: "100%" }}>
              <Divider />
            </div>

            {/* Add field */}
            {/* Input Field + Button------------------ */}

            {
              !isTax ? (
                <Grid item container xs={12} spacing={2}>
              {placeholdersArray.map((placeholder, index) => (
                <Grid key={index} item xs={12} sm={12} 
                  // md={this.props.isSelectedCC === 12 ? 8 : 8}
                  md={8}
                >
                  {/* Breakpoints are temporary, Feel free to change as per need. */}
                  {/* <Grid key={index} item xs={12} sm={6} md={7} xl={7} lg={7}> */}
                  <CustomTextField
                    type={
                      // this.props.isSelectedCC === 12 &&
                      // placeholder === "Number of Days"
                      //   ? "number"
                      //   : "text"
                      "text"
                    }
                    id={`${placeholder}Input`}
                    onChange={(e) =>
                      this.props.onChange(
                        `newMaster${index !== 0 ? index + 1 : ""}`,
                        e.target.value,
                        // this.props.isSelectedCC === 12 &&
                        //   placeholder === "Number of Days"
                        //   ? "number"
                        //   : "text"
                        "text"
                      )
                    }
                    value={this.props.valuesArray[index]}
                    handleOnEnter={this.props.handleAddEnter}
                    // placeholder={"Enter a " + placeholder}
                    placeholder={`${
                      placeholder !== "Number of Days" ? "Enter a " : ""
                    } ${placeholder}`}
                    padding={"4px 16px"}
                    error={
                      this.props.validationError[
                        `newMaster${index !== 0 ? index + 1 : ""}`
                      ]
                    }
                    errorText={
                      this.props.validationError[
                        `newMaster${index !== 0 ? index + 1 : ""}`
                      ] && errorMessage.requiredMessage
                    }
                  />
                </Grid>
              ))}
              <Grid
                item
                xs={12}
                sm={12}
                // md={placeholdersArray?.length % 2 === 0 ? 12 : 6}
                md={4}
              >
                {/* Breakpoints are temporary, Feel free to change as per need. */}
                {/* <Grid item xs={12} sm={6} md={4} xl={3} lg={3}> */}
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                    // padding: "0px 0px 0px 8px",
                  }}
                >
                  <Button
                    variant={"contained"}
                    id={'AddAdminRecords'}
                    color={"primary"}
                    // disabled={addLoading ? true : false}
                    disabled={(this.props.valuesArray?.includes("") || addLoading) ? true : false}
                    fullWidth
                    onClick={this.props.handleAddClick}
                    style={{
                      height: 33,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    className={`${
                      this.props.validationError[
                        placeholdersArray.map(
                          (_, index) =>
                            `newMaster${index !== 0 ? index + 1 : ""}`
                        )
                      ]
                        ? classes.errorPosition
                        : null
                    }`}
                  >
                    {addLoading ? (
                      <>
                        {`+ ADDING`}
                        <span
                          style={{
                            marginLeft: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <CircularProgress
                            size={20}
                            classes={{ colorPrimary: classes.colorPrimary }}
                          />
                        </span>
                      </>
                    ) : (
                      `+ ADD`
                    )}
                  </Button>
                </div>
              </Grid>
            </Grid>
              ) : <></>
            }
            

            {/* END Input Field + Button -------------------------- */}

            {/* Add field FOR COUNTRY CODE*/}
            {/* Input Field + Button------------------ */}

            {/* END Input Field + Button -------------------------- */}

            {/* Table Item */}
            <Grid item container xs={12}>
              {loading && (
                <div style={{ textAlign: "center", width: "100%" }}>
                  <LoadingSection
                    top={"20vh"}
                    message={`Loading ${title}...`}
                  />
                </div>
              )}
              {error && !loading && (
                <div style={{ textAlign: "center", width: "100%" }}>
                  <InternatlServerError
                    message={`Something went wrong! Try Again`}
                    onRetry={onRetry}
                  />
                </div>
              )}
              {!loading && !error && data && (
                <Grid item xs={12}>
                  <AddEditProductManagement
                    components={components}
                    isCancelSaveButtonsNeeded={true}
                    withoutBoxShadow={true}
                    fixPadding={true}
                    fullWidth={true}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

AdminTable.propTypes = {
  /**Sets the title of the table. */
  title: PropTypes.string,
  count: PropTypes.number,
  data: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  columns: PropTypes.array,
  onRetry: PropTypes.func,
  searchKey: PropTypes.string,
  sortKey: PropTypes.number,
  onChange: PropTypes.func,
  valuesArray: PropTypes.string,
  addMaster: PropTypes.func,
  editMaster: PropTypes.func,
  deleteMaster: PropTypes.func,
  isSelectedCC: PropTypes.string,
  selectedObj: PropTypes.object
};

export default withStyles(style)(AdminTable);
