import React from "react";
import { Grid, Typography, withStyles } from "@material-ui/core";
import CardList from "../../../../components/userList";
import SubHeader from "../../../../components/subHeader";
import {  filterTagType, fontStyleClass, scrollToTop, sortDirection, sortFields, subHeaderSortOption } from "../../../../utils";
// careCoordinatorDetailsUrl
import { routes } from "../../../../router/routes";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FilterBuilder, InternatlServerError, NotFoundSection } from "../../../../components";
import { filterComponent } from "./filterComponent";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import FilterTags from "../../../../components/filters/filterTags";
import { errorMessage } from "../../../../utils/errorMessages";

const sizes = {
  md: 4,
  sm: 4,
  xs: 4,
};

const styles = theme => ({
  ...fontStyleClass
})

const initialState = {
  pageNo: 0,
  pageSize: 25,
  careCoordinatorName: "",
  category: [],
  provider: [],
  skills: [],
  specialization: [],
  status: "Both",
  date: null,
  isFilterApplied: false,
  sort: subHeaderSortOption[2].value
}

class CareCoordinator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState
    };
  }
  componentDidMount() {
    this.getCareCoordinatorList();
    scrollToTop();
    window.addEventListener("scroll", this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  }

  scrolling = () => {
    let {
      loading,
      data,
      error,
    } = this.props?.careCoordinator?.allCareCoordinator;

    let listCount = data?.list?.length ?? 0;
    let totalCount = data?.total_count ?? 0;

    if (loading || error || totalCount === 0 || listCount === 0) {
      return false;
    }

    if (
      Math.round(window.scrollY + window.innerHeight) >=
      document.getElementsByTagName("body")[0].scrollHeight &&
      listCount !== totalCount
    ) {
      this.setState(
        (state, props) => ({
          pageNo: state.pageNo + 1,
        }),
        () => {
          this.getCareCoordinatorList();
        }
      );
    }
  };

  getCareCoordinatorList = () => {
    this.props.GET_ALL_CARE_COORDINATOR({
      ...this.state,
      category: this.state.category.map(_ => _.value),
      provider: this.state.provider.map(_ => _.value),
      skills: this.state.skills.map(_ => _.value),
      specialization: this.state.specialization.map(_ => _.value),
      status: this.state.status,
      sortDirec: this.state.sort === "az" ? sortDirection.asc : sortDirection.desc,
      sortfield: this.state.sort === "az" || this.state.sort === "za" ? sortFields.coordinator.name : sortFields.coordinator.created_at
    });
  };

  // SETTING THE STATE FOR SEARCH AND CALLING THE SEARCH FUNCTION

  storeInputSearchValue = (value) => {
    this.setState(
      { careCoordinatorName: value, pageNo: 0, pageSize: 25 },
      () => {
        // if (value.length % 3 === 0) {
        this.getCareCoordinatorList();
        // }
      }
    );
  };

  // Sort Handlers
  handleSortChange = (searchValue) => {
    this.setState({
      sort: searchValue,
      pageNo: 0,
      pageSize: 25
    }, () => {
      this.getCareCoordinatorList();
    })
  }


  // Filter Handlers
  onFilterIconClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: <FilterBuilder
        components={filterComponent(this.state, this.props)}
        applyButtonLabel="Apply"
        loading={this.props?.allCareSupervisor?.loading}
        onChange={this.onFilterChange}
        onApplyButtonClicked={this.applyFilter}
        resetFilter={this.resetFilter}
        onCancelClick={this.closeFilter}
      />
    })
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  applyFilter = (state) => {
    this.setState({
      ...state,
      pageNo: 0,
      pageSize: 25,
      isFilterApplied: true,
    }, () => {
      this.getCareCoordinatorList();
      this.closeFilter()
    })
  }

  closeFilter = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    })
  }

  resetFilter = () => {
    this.setState({
      ...initialState
    }, () => {
      this.getCareCoordinatorList();
      this.closeFilter();
    })
  }

  // Filter Tag Utils
  checkIsThereAnyFilter = () => {
    const newState = this.state
    if (
      newState[filterTagType.Category].length === 0 &&
      newState[filterTagType.Provider].length === 0 &&
      newState[filterTagType.Skills].length === 0 &&
      newState[filterTagType.Specialization].length === 0 &&
      newState[filterTagType.Status] === initialState.status) {
      return false
    } else return true
  }

  handleChipDelete = (key, index) => {
    
    console.log("Key ", key)
    console.log("index ", index)
    if (
      key === filterTagType.Category ||
      key === filterTagType.Provider ||
      key === filterTagType.Skills ||
      key === filterTagType.Specialization) {
      let newState = this.state
      newState[key].splice(index, 1)
      console.log(newState)
      
      this.setState({
        ...newState,
        pageNo: 0,
        pageSize: 25,
        isFilterApplied: this.checkIsThereAnyFilter()
      }, () => {
        this.getCareCoordinatorList()
      })

    } else if (key === filterTagType.Status) {
      this.setState({
        status: "Both",
        pageNo: 0,
        pageSize: 25,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getCareCoordinatorList()
      })
    }
  }


  giveMeEmptyMessage = () => {
    if (this.state.careCoordinatorName && this.state.isFilterApplied) {
      return errorMessage.notFoundFilterSearch;
    } else if (this.state.careCoordinatorName) {
      return errorMessage.notFoundSearch;
    } else if (this.state.isFilterApplied) {
      return errorMessage.notFoundFilter;
    } else {
      return "You don't have any coordinators yet"
    }
  }


  // -----------------------------------------------

  render() {
    let {
      loading,
      data,
      error,
    } = this.props?.careCoordinator?.allCareCoordinator;
    const {classes} = this.props
    return (
      <Grid style={{ padding: "25px" }}>
        <Grid>
          <SubHeader
            isbutton={true}
            placeholder={"Search for Service Coordinator Name, Email and Mobile Number"}
            button_name={"+ ADD SERVICE COORDINATOR"}
            link={routes.add_care_coordinators}
            handleSearch={this.getCareCoordinatorList}
            handleChangeInputSearch={this.storeInputSearchValue}
            value={this.state.careCoordinatorName}
            useNewFilterIcon
            placeSearchIconToLeft
            isFilterApplied={this.state.isFilterApplied}
            onFilterIconClicked={this.onFilterIconClicked}
            showSortOption={true}
            sort={this.state.sort}
            handleSortChange={this.handleSortChange}
            loading={loading}
            // Temporarily hidden
            hideFilter
            // hideSearch
          />
        </Grid>

        {
          this.state.isFilterApplied &&
          <Grid item xs={12} style={{ marginTop: 25 }} >
            <FilterTags
              filterData={this.state}
              handleDelete={this.handleChipDelete}
            />
          </Grid>
        }

        {/*TOTAL COUNT  */}
        <Grid item xs={12}>
          <Typography variant="h6" style={{ paddingTop: 25 }} className={classes.mediumFont} >Total Service Coordinators ({data?.total_count ?? 0})</Typography>
        </Grid>


        {!loading && error && <InternatlServerError message="Something went wrong, Try Again!" onRetry={this.getCareCoordinatorList} />}
        {!loading && !error && !data?.list?.length && <NotFoundSection message={this.giveMeEmptyMessage()} />}

        {/* List */}
        {!error && (
          <Grid style={{ paddingTop: "25px" }}>
            <CardList
              list={data?.list}
              loading={loading}
              componentOrder={1}
              sizes={sizes}
              Url={`${routes.manage_care_coordinators}/view/`}
              countName="Clients"
              isCard={true}
              hideQuickSummaryLabel={true}
              hideQuickSummary={true}
              displayProvider={true}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  careCoordinator: state.careCoordinator,
});

export default connect(mapStateToProps, actions)(withAlelrtAndDialog(withRouter(withStyles(styles)(CareCoordinator))));
