/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-01-25 19:46:52
 * @modify date 2022-01-25 19:46:52
 * @desc Table component to display list of promotional banners
 */

import React, { useState, useRef, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

// Icons
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {
  CreateIconV2,
  DeleteIconV2,
  VisibilityOutlinedIcon,
  ScrollArrowLeftIcon,
  ScrollArrowRightIcon,
} from "../../assets";

// Helpers
import moment from "moment";
import {
  isSuperAdmin,
  isManagedCareAdmin,
  isCreatedByManagedCareAdmin,
} from "../../utils";
import ToolTipGenerator from "./toolTipGenerator";
import { useSelector } from "react-redux";
import { createTestProps } from "../../utils/common";

const tableHeadBgColor = "rgba(204, 225, 255, 0.35)";

const promotionColumnKeys = {
  bannerName: "bannerName",
  actions: "actions",
  priority: "priority",
  isActive: "isActive",
  createdBy: "createdBy",
  effectiveDate: "effectiveDate",
  subscriptionId: "subscriptionId",
  createdAt: "createdAt",
  updatedAt: "updatedAt",
};

const columns = [
  {
    key: promotionColumnKeys.bannerName,
    label: "Banner Name",
    minWidth: "280px",
  },
  {
    key: promotionColumnKeys.actions,
    label: "Actions",
    align: "center",
    minWidth: "124px",
  },
  {
    key: promotionColumnKeys.priority,
    label: "Priority",
    align: "center",
    minWidth: "120px",
  },
  {
    key: promotionColumnKeys.isActive,
    label: "Status",
    minWidth: "120px",
  },
  {
    key: promotionColumnKeys.createdBy,
    label: "Created By",
    minWidth: "160px",
  },
  {
    key: promotionColumnKeys.effectiveDate,
    label: "Effective Date",
    minWidth: "220px",
  },
  {
    key: promotionColumnKeys.subscriptionId,
    label: "Subscription ID",
    minWidth: "160px",
  },
  {
    key: promotionColumnKeys.createdAt,
    label: "Created Date",
    minWidth: "150px",
  },
  {
    key: promotionColumnKeys.updatedAt,
    label: "Last Updated",
    minWidth: "150px",
  },
];

// Function that return the value given that percentage and total value
const getValue = (percentage, total) => {
  return Math.ceil((percentage * total) / 100);
};

const ScrollArrowButtonFill = ({ shrink }) => {
  return <div style={{ width: shrink ? "6px" : "41px", height: "41px" }}></div>;
};

const ScrollArrowWrapper = ({
  variant,
  tableHead,
  scrollLeft,
  scrollRight,
  onArrowClick,
  classes,
}) => {
  const isLeft = variant === "left";

  const iconButtonProps = {
    className: classes.arrowButton,
    disableRipple: true,
    size: "small",
    ...createTestProps(`promotional banner table scroll ${isLeft ? "left" : "right"}`),
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          minHeight:
            tableHead?.getBoundingClientRect()?.height ??
            tableHead?.clientHeight,
          backgroundColor: tableHeadBgColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...(isLeft
            ? { borderTopLeftRadius: "12px" }
            : { borderTopRightRadius: "12px" }),
        }}
      >
        {isLeft ? (
          <>
            {!!scrollLeft ? (
              <IconButton
                id={"LeftArrow"}
                onClick={() => onArrowClick("left")}
                {...iconButtonProps}
              >
                <ScrollArrowLeftIcon fontSize={"large"} />
              </IconButton>
            ) : (
              <ScrollArrowButtonFill shrink />
            )}
          </>
        ) : (
          <>
            {!!scrollRight ? (
              <IconButton
                id={"RightArrow"}
                onClick={() => onArrowClick("right")}
                {...iconButtonProps}
              >
                <ScrollArrowRightIcon fontSize={"large"} />
              </IconButton>
            ) : (
              <ScrollArrowButtonFill />
            )}
          </>
        )}
      </div>
      <div
        style={{
          justifyContent: "stretch",
          ...(isLeft
            ? { borderBottomLeftRadius: "12px" }
            : { borderBottomRightRadius: "12px" }),
        }}
      ></div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  // arrowTransition: {
  //   transition: `${theme.transitions.create(["transform"], {
  //     duration: theme.transitions.duration.standard,
  //   })}`,
  // },
  arrowButton: {
    margin: theme.spacing(0, 0.25),
  },
}));

const StyledTableContainer = withStyles((theme) => ({
  root: {
    boxShadow: "unset",
    scrollBehavior: "smooth",
    // boxShadow: "0px 12px 50px 0px rgba(0,0,0,0.12)",
    // borderRadius: "12px",
  },
}))(
  React.forwardRef((props, ref) => (
    <TableContainer component={Paper} ref={ref} {...props} />
  ))
);

const StyledTableHead = withStyles((theme) => ({
  root: {
    backgroundColor: tableHeadBgColor,
    "& > .MuiTableRow-root .MuiTableCell-root": {
      fontWeight: 500,
    },
    "& $td, & $th": {
      border: 0,
    },
  },
}))(TableHead);

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#fafafa",
    },
    // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
    "& $td, & $th": {
      border: 0,
    },
  },
}))(TableRow);

const StyledTableSortLabel = withStyles((theme) => ({
  // root: {
  //   "&$active": {
  //     "& .MuiTableSortLabel-icon": {
  //       color: theme.palette.text.primary,
  //     },
  //   },
  // },
  icon: {
    // fontSize: "22px",
    color: `${theme.palette.text.primary} !important`,
  },
}))(TableSortLabel);

const StyledIconButton = withStyles((theme) => ({
  sizeSmall: {
    padding: 0,
  },
  root: {
    "& .MuiIconButton-label": {
      "& .MuiSvgIcon-root": {
        color: "#ABACB9",
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
}))(
  React.forwardRef((props, ref) => (
    <IconButton disableRipple size="small" ref={ref} {...props} />
  ))
);

const ActionContainer = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    gap: 6,
  },
}))(Box);

const EnhancedTableHead = React.forwardRef((props, ref) => {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead ref={ref}>
      <TableRow>
        {columns?.map((column) => (
          <TableCell
            key={column.key}
            sortDirection={orderBy === column.key ? order : false}
            style={{
              whiteSpace: "nowrap",
              textAlign: column?.align ? column.align : "left",
              minWidth: column?.minWidth ? column.minWidth : "auto",
            }}
          >
            {column.key === promotionColumnKeys.actions ? (
              <>{column.label}</>
            ) : (
              <StyledTableSortLabel
                active={orderBy === column.key}
                direction={orderBy === column.key ? order : "asc"}
                onClick={createSortHandler(column.key)}
                {...createTestProps(`promotional banner table column ${column.key}`)}
                // IconComponent={ArrowDropDownIcon}
              >
                {column.label}
                {orderBy === column.key ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </StyledTableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
});

export default function PromotionalBannerTable({ rows = [], ...props }) {
  const classes = useStyles();

  // State Selectors
  const authData = useSelector((state) => state?.authState?.loginAuth?.data);

  // Component Refs
  const tableHeadRef = useRef(null);
  const containerRef = useRef(null);

  // Component State
  const [scrollLeft, setScrollLeft] = useState(false);
  const [scrollRight, setScrollRight] = useState(true);

  // Ref States
  const [tableHead, setTableHead] = useState(null);

  const { onViewClick, onEditClick, onDeleteClick, onSort, order, orderBy } =
    props;

  // Component Handlers
  const handleArrowClick = (direction) => {
    const { current: element } = containerRef;
    if (direction === "left") {
      element.scrollLeft -= getValue(10, element.clientWidth);
    } else {
      element.scrollLeft += getValue(10, element.clientWidth);
    }
  };

  const handleScroll = () => {
    const { current: element } = containerRef;

    // Setting Left Arrow Visibility
    if (element.scrollLeft > 0) {
      setScrollLeft(true);
    } else {
      setScrollLeft(false);
    }

    // Setting Right Arrow Visibility
    if (element.scrollWidth > element.clientWidth) {
      if (element.scrollLeft === 0) {
        setScrollRight(true);
      } else {
        if (
          element.scrollWidth - Math.ceil(element.scrollLeft) ===
          element.clientWidth
        ) {
          setScrollRight(false);
        } else {
          setScrollRight(true);
        }
      }
    } else {
      setScrollRight(false);
    }
  };

  const getMinWidthStyle = (column) => {
    if (!column?.minWidth) return {};
    return {
      minWidth: column?.minWidth,
    };
  };

  // Component Lifecycle
  useEffect(() => {
    const { current: containerElement } = containerRef;
    function watchScroll() {
      containerElement.addEventListener("scroll", handleScroll);
    }
    watchScroll();
    return () => {
      containerElement.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    if (tableHeadRef.current) {
      setTableHead(tableHeadRef.current);
    }
  }, [tableHeadRef]);

  // ----------------------------------------------------------Component Render-------------------------------------------------
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "white",
        boxShadow: "0px 12px 50px 0px rgba(0,0,0,0.12)",
        borderRadius: "12px",
      }}
    >
      <ScrollArrowWrapper
        variant={"left"}
        tableHead={tableHead}
        scrollLeft={scrollLeft}
        onArrowClick={handleArrowClick}
        classes={classes}
      />

      <StyledTableContainer ref={containerRef}>
        <Table className={classes.table} aria-label="promotional banner table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={onSort}
            ref={tableHeadRef}
          />
          <TableBody>
            {Array.isArray(rows) &&
              rows.map((row) => (
                <StyledTableRow key={row.id}>
                  {columns.map((column) => {
                    const columnKey = column["key"];
                    switch (columnKey) {
                      case promotionColumnKeys.actions: {
                        return (
                          <TableCell
                            align="left"
                            style={{ ...getMinWidthStyle(column) }}
                          >
                            <ActionContainer>
                              <ToolTipGenerator
                                title="View Details"
                                id={"viewBannerDetailsTooltip"}
                              >
                                <StyledIconButton
                                  aria-label="view banner"
                                  onClick={() => onViewClick(row?.id)}
                                  {...createTestProps(`promotional banner item view`, row?.id)}
                                >
                                  <VisibilityOutlinedIcon
                                    fontSize="small"
                                    htmlColor="inherit"
                                  />
                                </StyledIconButton>
                              </ToolTipGenerator>

                              {/* Rendering the Edit and Delete action buttons only if the logged in user is super admin or 
                              if the logged in user is managedcare admin and the entry is created by managedcare admin */}
                              {(isSuperAdmin(authData) ||
                                (isManagedCareAdmin(authData) &&
                                  isCreatedByManagedCareAdmin(
                                    row[promotionColumnKeys.createdBy]
                                  ))) && (
                                <>
                                  <ToolTipGenerator
                                    title="Edit"
                                    id={"editBannerDetailsTooltip"}
                                  >
                                    <StyledIconButton
                                      aria-label="edit banner"
                                      onClick={() => onEditClick(row?.id)}
                                  {...createTestProps(`promotional banner item edit`, row?.id)}
                                    >
                                      <CreateIconV2 fontSize="small" />
                                    </StyledIconButton>
                                  </ToolTipGenerator>

                                  <ToolTipGenerator
                                    title="Delete"
                                    id={"deleteBannerDetailsTooltip"}
                                  >
                                    <StyledIconButton
                                      aria-label="delete banner"
                                      onClick={() => onDeleteClick(row?.id)}
                                  {...createTestProps(`promotional banner item delete`, row?.id)}
                                    >
                                      <DeleteIconV2 fontSize="small" />
                                    </StyledIconButton>
                                  </ToolTipGenerator>
                                </>
                              )}
                            </ActionContainer>
                          </TableCell>
                        );
                      }
                      case promotionColumnKeys.priority: {
                        return (
                          <TableCell
                            align="center"
                            style={{ ...getMinWidthStyle(column) }}
                          >
                            {row[columnKey] ?? ""}
                          </TableCell>
                        );
                      }
                      case promotionColumnKeys.isActive: {
                        return (
                          <TableCell
                            align="left"
                            style={{ ...getMinWidthStyle(column) }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "6px",
                                alignItems: "center",
                              }}
                            >
                              <FiberManualRecordIcon
                                fontSize="small"
                                htmlColor={
                                  row[columnKey] ? "#31B198" : "#FF4D4A"
                                }
                                style={{ fontSize: "0.6rem" }}
                              />
                              <Typography>
                                {row[columnKey] ? "Active" : "Inactive"}
                              </Typography>
                            </Box>
                          </TableCell>
                        );
                      }

                      case promotionColumnKeys.effectiveDate: {
                        const { from = "", to = "" } = row[columnKey];
                        const formatDate = (date) =>
                          moment(date).format("DD/MM/YYYY");
                        return (
                          <TableCell
                            align="left"
                            style={{ ...getMinWidthStyle(column) }}
                          >
                            {`${formatDate(from)}${
                              to ? ` to ${formatDate(to)}` : ``
                            }`}
                          </TableCell>
                        );
                      }

                      case promotionColumnKeys.createdAt:
                      case promotionColumnKeys.updatedAt: {
                        return (
                          <TableCell
                            align="left"
                            style={{ ...getMinWidthStyle(column) }}
                          >
                            {moment(row[columnKey]).format("DD/MM/YYYY")}
                          </TableCell>
                        );
                      }

                      default:
                        return (
                          <TableCell
                            align="left"
                            style={{ ...getMinWidthStyle(column) }}
                          >
                            {row[columnKey] ?? ""}
                          </TableCell>
                        );
                    }
                  })}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <ScrollArrowWrapper
        variant={"right"}
        tableHead={tableHead}
        scrollRight={scrollRight}
        onArrowClick={handleArrowClick}
        classes={classes}
      />
    </div>
  );
}
