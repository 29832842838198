import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { RecommendCardError } from './error';

export const RecommendedCardErrors = (props) => {


    const {
        count
    } = props;


    return <div>
        <Grid container direction='row' spacing={4}>
            {
                Array.apply(null, Array(count)).map((value, index) => <Grid key={index} item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <RecommendCardError />
                </Grid>)
            }
        </Grid>
    </div>
}

RecommendedCardErrors.propTypes = {
    count: PropTypes.number
}

RecommendedCardErrors.defaultProps = {
    count: 1
}