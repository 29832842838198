import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { createTestProps } from '../../../../../utils/common';

const useStyles = makeStyles((theme) => ({
  finalButton: {
    padding: "0px 5px",
    borderRadius: 6,
    lineHeight: "1.5",
    color: "#0063E7",
    borderColor: "#0063E7",
    width: 70,
  },
  finalContained: {
    "background-color": theme.palette.success.main,
    "&:hover": {
      "background-color": theme.palette.success.dark,
    },
    color: theme.palette.common.white,
  },
  finalOutlined: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
}));

export default function MarkPdf(props) {
  const { enquiry_id, quoteId, isMarked, loading } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      {loading && <Skeleton />}
      {!loading && !isMarked ? (
        <Button
          variant="contained"
          className={`${classes.finalButton} ${classes.finalContained}`}
          onClick={() => props.updateStatus(enquiry_id, quoteId, false)}
          disabled={props?.disabled}
          {...createTestProps("final button",props?.id)}
        >
          Final
        </Button>
      ) : (
        <Button
          variant="outlined"
          className={`${classes.finalButton} ${classes.finalOutlined}`}
          onClick={() => props.updateStatus(enquiry_id, quoteId, true)}
          disabled={props?.disabled}
          {...createTestProps("finalized button",props?.id)}
        >
          Finalized
        </Button>
      )}
    </React.Fragment>
  );
}
