import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: "8px",
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04)",
        // border: "0.3px solid #9297a5",
        backgroundColor: "#ffffff",
        width: 200,
      },
}))

export const RecommendCardError = (props) => {

    const classes = useStyles();

    return <div className={classes.card}>
        <Grid container direction="row" justify="center" alignItems="center" style={{height:"100%"}}>              
            <Grid item xs={12}>
                <Typography variant="h6" color="textSecondary" align="center">{"Something went wrong!"}</Typography>
                <Typography variant="body2" color="textSecondary" align="center">{"Unable to Fetch"}</Typography>
            </Grid>     
        </Grid>
    </div>
}
