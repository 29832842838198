/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-01-27 20:47:03
 * @modify date 2022-01-27 20:47:03
 */
import React from "react";
import PropTypes from "prop-types";

import { SvgIcon } from "@material-ui/core";

function ApplicationIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.6 3.25A1.6 1.6 0 0 0 2 4.86v15.29a.8.8 0 0 0 .8.8h17.6a.8.8 0 0 0 .8-.8V4.86a1.6 1.6 0 0 0-1.6-1.61h-16Zm0 4.024h16V19.35h-16V7.274ZM6 9.689a.805.805 0 1 0 0 1.61h3.2a.805.805 0 1 0 0-1.61H6Zm0 3.219a.805.805 0 1 0 0 1.61h3.2a.805.805 0 1 0 0-1.61H6Zm0 3.219a.805.805 0 1 0 0 1.61h3.2a.805.805 0 1 0 0-1.61H6Z"
        fill={props.color}
      />
      <path d="M15 15.375a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" fill={props.color} />
    </SvgIcon>
  );
}

ApplicationIcon.propTypes = {
  color: PropTypes.string,
};

ApplicationIcon.defaultProps = {
  color: "#0063E7",
};

export default ApplicationIcon;
