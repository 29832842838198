import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Grid, Typography, IconButton, withStyles } from '@material-ui/core'
import ViewAllTax from './viewAllTax';
import withAlelrtAndDialog from '../../HOCs/withAlelrtAndDialog';
import BusinessIcon from '@material-ui/icons/Business';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { formatDate } from '../../utils/common';

const style = ((theme) => ({
    iconSize: {
        fontSize: "1.05rem"
    },
    listTop: {
        marginTop: 26,
    },
    listMiddle: {
        marginTop: 8,
        marginBottom: 8,
    },
    customListWrapper: {
        padding: "4px 16px 4px 0px",
        marginLeft: 8,
    }
}))

class ViewTax extends Component {

    handleOpenMoreDrawer = () => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: (
                <ViewAllTax items={this.props.data}/>
            ),
        });
    }

    render() {
        const { data, classes, theme } = this.props;
        const item = data?.[0]

        return (
            <>
                <Grid container>
                    <Grid item xs={12} className={`${this.props.isListTop && classes.listTop} ${this.props.isListMiddle && classes.listMiddle}`}>
                        <Grid item container style={{ paddingTop: 4 }}>
                            <Grid item style={{ display: "flex", alignItems: "center" }}>
                                <BusinessIcon fontSize={"small"} htmlColor={theme.palette.primary.main} classes={{ fontSizeSmall: classes.iconSize }} />
                            </Grid>
                            <Grid item xs>
                                <Typography style={{ color: "rgba(146, 151, 165, 1)", fontSize: 13, marginLeft: 8, textTranform: "uppercase" }} >{"TAX"}</Typography>
                            </Grid>
                        </Grid>

                        <Grid item container>

                            <Grid item>
                                <PermIdentityIcon fontSize={"small"} htmlColor={"#28a9c1"} classes={{ fontSizeSmall: classes.fontSize }} style={{ visibility: "hidden" }} />
                            </Grid>
                            <Grid item xs>
                                <div className={classes.customListWrapper} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ flex: 1 }}>
                                        <Typography variant={"body2"} >{item?.taxRegistrationNumber ? item?.taxRegistrationNumber : "NA"}</Typography>
                                        <Typography variant={"body2"} color={"textSecondary"} >{item?.label ? item?.label : null}</Typography>
                                        <Typography variant={"body2"} color={"textSecondary"} >{`${item?.effective ? formatDate(item?.effective) : "NA"} - ${item?.expiry ? formatDate(item?.expiry) : "NA"}`}</Typography>
                                    </div>

                                    <Typography variant="body1" style={{ display: "flex", alignItems: "center" }}>
                                        {data?.length > 1 && <IconButton onClick={() => this.handleOpenMoreDrawer()} size={"small"} style={{ border: "solid 1px #D3DADD", backgroundColor: "#252B430D", fontSize: "0.925rem" }}>+{data?.length - 1}</IconButton>}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </>
        )
    }
}


export default withAlelrtAndDialog(withStyles(style, { withTheme: true })(ViewTax));


