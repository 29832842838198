import React from 'react';
import { Grid } from '@material-ui/core';

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CountCardLoaders, CountCardErrors, CountCards } from "../../../components";
import { withAlertAndDialog } from "../../../HOCs";
import { CountCardIcons } from '../../../components/countCard/index'

class ServiceProfessionalListingStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {

    let {
      data,
      loading,
      error
    } = this.props;

    const graphs = [CountCardIcons.miniGraphOne, CountCardIcons.miniGraphTwo, CountCardIcons.miniGraphThree, CountCardIcons.miniGraphFour]

    return <>
      {loading && <Grid item xs={12}>
        <CountCardLoaders count={4} />
      </Grid>}
      {error && <Grid item xs={12}>
        <CountCardErrors count={4} />
      </Grid>}
      {!loading && !error && data?.slice(0,2)?.map((card, index) => {
        const mylooper = index > 3 ? (index % 4) : index
        return <Grid key={index} item xs={12} sm={6} md={6} lg={3} xl={3}>
          <CountCards isprofessional={true} count={card.count} label={card.label} graph={graphs[mylooper]} index={mylooper} />
        </Grid>
      })}
    </>
  }
}

const mapStateToProps = (state) => ({
  services: state?.serviceState?.allServices,
});

export default connect(
  mapStateToProps,
  actions
)(withAlertAndDialog(withRouter(ServiceProfessionalListingStats)));