import React from "react";
import { Paper, withStyles, Grid, Button, Divider } from "@material-ui/core";
import {
  UploadProfileImage,
  TextField,
  InputAdornmentComponent,
  SimpleSelect,
} from "../../../../components";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter, Prompt } from "react-router-dom";
import {
  alertProps,
  user_roles,
  getCountryCodeOptions,
} from "../../../../utils";
import { checkFirstZeroAndAllZero } from "../../../../utils/validations";
import { errorMessage } from "../../../../utils/errorMessages";
import {
  getMobileLimitBasedOnCC,
  validateEmail,
} from "../../../../utils/validations";
import { AlertContext } from "../../../../contexts";
import { routes } from "../../../../router/routes";
// import { parsePhoneNumber } from "libphonenumber-js";
// import { routes } from "../../../../router/routes";

const styles = (theme) => ({
  paperCard: {
    borderRadius: "0px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04)",
    // padding: "25px",
  },
  strong_name: {
    display: "inline-block",
    paddingTop: 12,
    color: theme.palette.primary.dark,
    fontSize: 14,
    paddingLeft: "40px",
  },
  saveButton: {
    padding: "5px 40px",
  },
  saveButtonGrid: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
});

const initialState = {
  profile_pic: "",
  uploadImage: "",
  name: "",
  mobileNo: "",
  phoneCode: "",
  email: "",
  addressLine1: "",
  addressLine2: "",
  area: "",
  state: "",
  country: "",
  postalCode: "",
  skills: [],
  category: [],
  service_provider: [],
  allPhoneCodes: [],
  isBlock: false,
  service_supervisor: [],
  service_professional: [],
};

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      allCategoryLists: [],
      allSkillsList: [],
      allSalutationList: [],
      error: {},
      searchKey: "",
      sortKey: 1,
    };
  }

  getFormattedMobileCodeState = (phoneCode) => {
    let flag = phoneCode.toString().includes("+");
    if (!flag) {
      return `+${phoneCode}`;
    } else {
      return phoneCode.toString();
    }
  };

  async componentDidMount() {
    if (this.props.data) {
      await this.getSalutation();
      this.setState(
        {
          ...this.props.data,
          salutation: this.state?.allSalutationList?.filter(
            (sal) => this.props?.data?.salutation === sal?.label
          )?.[0]?.value,
          isBlock: false,
        },
        async () => {
          this.careProviderByCategory();
          this.serviceProfessionalByProvider();
          await this.getEssentials();
        }
      );
    } else {
      await this.getSalutation();
      await this.getEssentials();
    }
  }

  getSalutation = () => {
    try {
      return new Promise(async (resolve, reject) => {
        let salutation = await this.props.GET_SAL({});
        
        this.setState({
          allSalutationList: salutation.payload.data,
        });
        resolve();
      });
    } catch (error) {
      console.log(error);
    }
  };

  getEssentials = async () => {
    // const options = [
    //   {
    //     label: "+60",
    //     value: "60",
    //   },
    // ];
    const options = getCountryCodeOptions();
    this.setState({
      allPhoneCodes: options,
      phoneCode: this.props.data?.phoneCode
        ? this.getFormattedMobileCodeState(this.props.data?.phoneCode)
        : "+60",
    });
    try {
      return new Promise(async (resolve, reject) => {
        let category = await this.props.GET_CATEGORIES({});
        let skils = await this.props.GET_SKILLS({
          searchKey: this.state.searchKey,
          sortKey: this.state.sortKey,
        });
        this.setState({
          allCategoryLists: category.payload.data,
          allSkillsList: skils.payload.data,
        });
        resolve();
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateDataInState = (value, key, type, cleanUpKeys = [], numberDigits) => {
    if (
      type === "number" &&
      value.length > getMobileLimitBasedOnCC(this.state?.phoneCode)
    ) {
      return false;
    }
    else if (
      type === "postalCode" &&
      value.length > 10
    ) {
      return false;
    }
    const { error } = this.state;
    error[key] = false;
    let state = this.state;
    state[key] = value;
    cleanUpKeys.map((_) => {
      state[_] = "";
      return _;
    });

    if (user_roles.provider === this.props.auth_data?.data?.user_role) {
      state.service_provider = {
        value: this.props.auth_data?.data?.user_id,
        label: this.props.auth_data?.data?.profile_name,
      };
      this.serviceProfessionalByProvider();
    }

    this.setState({ ...state, isBlock: true }, () => {
      if (key === "category") {
        this.careProviderByCategory();
      }
      if (key === "service_provider") {
        this.serviceProfessionalByProvider();
      }
    });
  };

  canIShow = (key) => {
    const { data } = this.props.auth_data;

    if (key === "provider") {
      if (user_roles.provider === data.user_role) {
        return false;
      } else {
        return true;
      }
    }
  };

  serviceProfessionalByProvider = () => {
    const provider = this.state.service_provider;
    this.props.SERVICE_PROFESSIONAL_BY_PROVIDER({
      provider: Array.isArray(provider)
        ? provider.map((_) => _.value)
        : [provider?.value],
    });
  };

  careProviderByCategory = () => {
    const categories = this.state.category;
    this.props.GET_PROVIDER_LIST_BASEDON_CATEGORY({
      categories: Array.isArray(categories)
        ? categories.map((_) => _.value)
        : [categories?.value],
    });
  };

  updateImageDataInStateAndPreviewImage = async (input) => {
    // const base64 = await toBase64(input.target.files[0]);
    this.setState({ ...this.state, uploadImage: input.target.files[0] }, () => {
      const { error } = this.state;
      error["uploadImage"] = false;
      this.setState({ ...this.state, isBlock: true });
    });
  };

  canIHaveCategory = () => {
    if (this.props?.auth_data?.data?.category?.length > 0) {
      return this.props?.auth_data?.data?.category
        ? this.props?.auth_data?.data?.category
        : [];
    } else {
      return [];
    }
  };

  // ON SUBMIT VALIDATION
  handleValidation = () => {
    const { email, error } = this.state;
    let fieldsToValidate = [
      // file field
      // "uploadImage",
      //  text field
      "name",
      "mobileNo",
      "skills",
      // "phoneCode",
      "service_provider",
      // "address",
      "addressLine1",
  // "addressLine2",
  "area",
  "state",
  "country",
  "postalCode",
      // "currOffering",
      // "Offerings",
      // email field
      "email",
      // select field
      "category",
      // "service_supervisor",
      // "service_professional"
    ];

    fieldsToValidate.map((val) => {
      if (val === "email") {
        if (email && email.length > 0) {
          if (validateEmail(email)) {
            error[val] = false;
            // return true;
          } else {
            error[val] = true;
            error[`message-email`] = errorMessage.emailMessage;
            // return false;
          }
        } else {
          error[val] = true;
          // return true;
        }
      } else if (val === "mobileNo") {
        if (
          this.state[val].toString() &&
          this.state[val].toString().length > 0
        ) {
          const limit = getMobileLimitBasedOnCC(this.state?.phoneCode);
          if (
            this.state[val].toString() &&
            this.state[val].toString().length < limit
          ) {
            error[val] = true;
            error[`message-mobile`] = errorMessage.mobileNoLimitMessage(limit);
            // return false;
          } else if (
            this.state[val].toString() &&
            this.state[val].toString().length > limit
          ) {
            error[val] = true;
            error[`message-mobile`] = errorMessage.mobileNoExceedsMessage(
              limit
            );
            // return false;
          } else if (
            this.state[val].toString() &&
            this.state[val].toString().length === limit
          ) {
            // DONT REMOVE - CHECKS COUNTRYWISE MOBILE NUMBER VALIDITY
            // if(this.state.phoneCode && this.state[val]){
            //   if (
            //     !parsePhoneNumber(
            //       `${this.state.phoneCode}${this.state[val]}`
            //     )?.isValid()
            //   ) {
            //     error[val] = true;
            //     error[`message-mobile`] = errorMessage.mobileNoValidMessage;
            //   }
            // }
            // CHECKS FIRST AND ALL DIGIT ZERO
            if (this.state[val]) {
              if (checkFirstZeroAndAllZero(this.state[val])) {
                error[val] = true;
                error[`message-mobile`] = errorMessage.mobileNoValidMessage;
              }
            }
          } else {
            error[val] = false;
            // return true;
          }
        } else {
          error[val] = true;
          // return false;
        }
      } else if (val === "profile_pic" || val === "uploadImage") {
        if (this.state["profile_pic"] || this.state["uploadImage"]) {
          error[val] = false;
        } else {
          error[val] = true;
        }
      }
      //  else if (val === "phoneCode") {
      //   error[val] = false;
      //   return true;
      // }
      else {
        if (this.state?.[val]?.constructor === Array) {
          if (this.state[val].length > 0) {
            error[val] = false;
          } else {
            error[val] = true;
          }

          // return true;
        } else if (this.state?.[val]?.constructor === Object) {
          if (Object.keys(this.state[val]).length > 0) {
            error[val] = false;
          } else {
            error[val] = true;
          }
        } else if (this.state[val]) {
          error[val] = false;
        } else {
          error[val] = true;
        }
      }
      return val;
    });
    this.setState({ ...this.state });
  };

  onSave = async () => {
    
    try {
      this.handleValidation();
      if (Object.values(this.state.error).indexOf(true) > -1) {
        return false;
      } else {
        this.setState({ isSaving: true });
        const payload = {
          ...this.state,
          phoneCode: this.state?.phoneCode.replace("+", ""),
          service_professional: this.state.service_professional ?? [],
        };

        if (user_roles.provider === this.props.auth_data?.data?.user_role) {
          payload.service_provider = {
            value: this.props.auth_data?.data?.user_id,
            label: this.props.auth_data?.data?.profile_name,
          };
        }
        
        // payload.imageBase64 =await toBase64(payload.uploadImage);

        const res = this.props.data
          ? await this.props.UPDATE_CARE_COORDINATOR_INFO(payload)
          : await this.props.ADD_CARE_COORDINATOR(payload);
        
        const { error } = res;

        const { error: payloadError } = res.payload;

        if (error || payloadError) {
          this.setState(
            {
              isSaving: false,
            },
            () => {
              this.context.setSnack({
                ...this.context,
                open: true,
                vertical: alertProps.vertical.top,
                horizontal: alertProps.horizontal.center,
                severity: alertProps.severity.error,
                msg:
                  res?.payload?.message ?? "Something went wrong! Try again.",
              });
            }
          );
        } else {
          if (this.props.data) {
            this.setState(
              {
                isSaving: false,
                isBlock: false,
              },
              () => {
                this.context.setSnack({
                  open: true,
                  vertical: alertProps.vertical.top,
                  horizontal: alertProps.horizontal.center,
                  severity: alertProps.severity.success,
                  msg: "Service Coordinator detail updated!",
                });
                const id = this.props?.match?.params?.id;

                this.props.history.push(
                  routes.manage_care_coordinators + "/view/" + id
                );
              }
            );
          } else {
            this.setState(
              {
                ...initialState,
                isSaving: false,
                isBlock: false,
              },
              () => {
                this.context.setSnack({
                  ...this.context,
                  open: true,
                  vertical: alertProps.vertical.top,
                  horizontal: alertProps.horizontal.center,
                  severity: alertProps.severity.success,
                  msg: "Service Coordinator Added Successfully!",
                });
                this.props.history.push(routes.manage_care_coordinators);
              }
            );
          }
        }
      }
    } catch (err) {
      console.log("Error while adding care coordinator:", err);
      this.setState(
        {
          isSaving: false,
        },
        () => {
          this.context.setSnack({
            ...this.context,
            open: true,
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.center,
            severity: alertProps.severity.error,
            msg: "Something went wrong! Try again.",
          });
        }
      );
    }
  };

  cancelForm = () => {
    this.props.history.push(routes.manage_care_coordinators);
  };

  canIHaveProviderOptions = () => {
    const { data: auth_data } = this.props?.auth_data;

    if (user_roles.supervisor === auth_data.user_role) {
      return (
        auth_data?.providerDetails?.map((_) => {
          return { value: _.provider_id, label: _.provider_name };
        }) ?? []
      );
    } else {
      return this.props?.providerBasedOnCategory?.data ?? [];
    }
  };

  render() {
    const { classes } = this.props;

    // const category = this.state.allCategoryLists;
    const skills = this.state.allSkillsList;
    const salutation = this.state.allSalutationList;

    const { data } = this.props;

    const { isSaving } = this.state;

    return (
      <Paper className={classes.paperCard}>
        <Grid container spacing={3} style={{ padding: "25px" }}>
          <Grid item md="12" sm="12" xs="12">
            <UploadProfileImage
              label="UPLOAD PHOTO"
              value={this.state.uploadImage}
              profile_pic={this.state.profile_pic}
              handleFileChange={(e) => {
                this.updateImageDataInStateAndPreviewImage(e);
              }}
              // id="64297418-8a5b-4211-9071-b669f3f692e3_UPLOAD_PHOTO_USER_MANAGEMENT "
              id="uploadImage"
              error={this.state.error.uploadImage ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <InputAdornmentComponent
              options={salutation}
              valuePhoneCode={
                this.state.salutation ? this.state.salutation : ""
              }
              valueMObileNo={this.state.name}
              customsize={4}
              allScreenCustomSize={{
                xs: 6,
                sm: 4,
                md: 6,
              }}
              title="Service Coordinator Name"
              id="name"
              // id="ac53a18c-6975-41b4-bcbb-b19656accb05_SERVICE_COORDINATOR_NAME_USER_MANAGEMENT"
              type="text"
              handleChangeSelect={(e) => {
                this.updateDataInState(e.target.value, "salutation");
              }}
              handleMobileNo={(e) => {
                this.updateDataInState(e.target.value, "name");
              }}
              error={this.state.error["name"] ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <SimpleSelect
              label={"Category"}
              options={this.canIHaveCategory()}
              id="category"
              isMulti={true}
              value={this.state.category}
              handleOnChange={(e) => {
                this.updateDataInState(e, "category", null, [
                  "service_provider",
                  "service_supervisor",
                  "service_professional",
                ]);
              }}
              error={this.state.error.category ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <InputAdornmentComponent
              options={this.state.allPhoneCodes}
              valueMObileNo={this.state.mobileNo}
              customsize={4}
              allScreenCustomSize={{
                xs: 6,
                sm: 4,
                md: 6,
              }}
              title="Mobile No"
              id="mobile"
              type="number"
              valuePhoneCode={this.state.phoneCode}
              handleChangeSelect={(e) => {
                this.updateDataInState(e.target.value, "phoneCode", null, [
                  "mobileNo",
                ]);
              }}
              handleMobileNo={(e) => {
                this.updateDataInState(e.target.value, "mobileNo", "number");
              }}
              error={this.state.error.mobileNo ? true : false}
              errorText={
                this.state.error[`message-mobile`]
                  ? this.state.error[`message-mobile`]
                  : errorMessage.requiredMessage
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              type="text"
              title="Email Address"
              id="email"
              placeholder=""
              disabled={data ? true : false}
              value={this.state.email}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "email");
              }}
              error={this.state.error.email ? true : false}
              errorText={
                this.state.error[`message-email`]
                  ? this.state.error[`message-email`]
                  : errorMessage.requiredMessage
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              type="text"
              title="Address Line 1"
              id="addressLine1"
              placeholder=""
              value={this.state.addressLine1}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "addressLine1");
              }}
              error={this.state.error.addressLine1 ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              type="text"
              title="Address Line 2"
              id="addressLine2"
              placeholder=""
              limit={10}
              value={this.state.addressLine2}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "addressLine2");
              }}
              error={this.state.error.addressLine2 ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              type="text"
              title="City"
              id="area"
              placeholder=""
              value={this.state.area}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "area");
              }}
              error={this.state.error.area ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              type="text"
              title="State"
              id="state"
              placeholder=""
              value={this.state.state}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "state");
              }}
              error={this.state.error.state ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              type="text"
              title="Country"
              id="country"
              placeholder=""
              value={this.state.country}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "country");
              }}
              error={this.state.error.country ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              type="number"
              limit={5}
              title="Postal Code"
              id="postalCode"
              placeholder=""
              value={this.state.postalCode}
              handleOnChange={(e) => {
                this.updateDataInState(e.target.value, "postalCode", "postalCode");
              }}
              error={this.state.error.postalCode ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <SimpleSelect
              label={"Skills"}
              id="skills"
              options={skills}
              isMulti={true}
              value={this.state.skills}
              handleOnChange={(e) => {
                this.updateDataInState(e, "skills");
              }}
              error={this.state.error.skills ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={3} style={{ padding: "25px" }}>
          {this.canIShow("provider") && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <SimpleSelect
                label={"Select Provider"}
                options={this.canIHaveProviderOptions()}
                loading={this.props?.providerBasedOnCategory?.loading}
                id="service_provider"
                isMulti={false}
                value={this.state.service_provider}
                handleOnChange={(e) => {
                  this.updateDataInState(e, "service_provider", null, [
                    "service_supervisor",
                    "service_professional",
                  ]);
                }}
                error={this.state.error.service_provider ? true : false}
                errorText={errorMessage.requiredMessage}
              />
            </Grid>
          )}
          {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <SimpleSelect
              label={"Select Service Supervisor"}              
              id="service_supervisor"
              isMulti={false}
              disabled={true}
              value={this.state?.service_provider?.service_supervisor?.[0]}
              handleOnChange={(e) => {
                this.updateDataInState(e, "service_supervisor");
              }}
              error={this.state.error.service_supervisor ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SimpleSelect
              label={"Add Service Professional"}
              options={this.props?.service_professional?.data}
              loading={this.props?.service_professional?.loading}
              id="service_professional"
              isMulti={true}
              value={this.state.service_professional}
              handleOnChange={(e) => {
                this.updateDataInState(e, "service_professional");
              }}
              error={this.state.error.service_professional ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="flex-end"
            className={classes.saveButtonGrid}
          >
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={this.cancelForm}
              style={{ marginRight: 16 }}
            >
              {"Cancel"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.onSave()}
              className={classes.saveButton}
              disabled={isSaving}
            >
              {`${
                isSaving
                  ? `${data ? "Updating..." : "Saving..."}`
                  : `${data ? "Update" : "Save"}`
              }`}
            </Button>
          </Grid>
        </Grid>

        <Prompt
          when={this.state.isBlock}
          message={(location) => errorMessage.promptMessage}
        />
      </Paper>
    );
  }
}

Card.contextType = AlertContext;

const mapStateToProps = (state) => ({
  category: state.masterSet?.categories,
  skills: state.masterSet?.skills,
  providerBasedOnCategory: state?.careProvider?.providerBasedOnCategory,
  service_professional: state?.dependency_api?.serviceProfessionalByProvider,
  auth_data: state?.authState?.loginAuth,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withStyles(styles)(Card)));
