import React from 'react'
import { connect } from 'react-redux';
import { actions } from 'tanyacare-middleware';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { CustomTimeline, InternatlServerError, LoadingSection } from '../../../components'

class SubscriptionRefundLogs extends React.Component {

    componentDidMount() {
        this.getLogs()

    }

    getLogs = async() => {
        await this.props.GET_SUBSCRIPTION_CANCEL_REFUND_LOGS({ subscriptionId: this.props.subscriptionId })
        if(this.props.isCancelled)
        {
            // alert("INSIDE")
            await this.props.REFUND_PAYMENT_STATUS({ transaction_id: this.props.transaction_id})
        }
    }

    render() {
        const { data, loading, error } = this.props?.refundLogs
        const { data: statusdata } = this.props?.subscription_payment_status
        return (
            <div>
                <Typography variant="h6" style={{margin: "12px 24px 10px"}}>{this.props.title}</Typography>
                <Divider light />
                {loading && !error && <LoadingSection />}
                {!loading && error && <InternatlServerError onRetry={this.getLogs} />}
                {!loading && !error && data && <CustomTimeline events={data} transaction_id={statusdata?.transaction?.refund_transaction_id} amount={statusdata?.amount} status={statusdata?.success} searchKey={"message"} />}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    refundLogs: state?.subscription_tracking?.subscriptionCancelRefundLogs || {},
    subscription_payment_status: state?.subscription_tracking?.subscription_payment_status,
});


export default connect(
    mapStateToProps,
    actions
)(SubscriptionRefundLogs)
