import React from "react";

//HOC's
import AppAuth from "./App.auth";
import AppTheme from "./AppTheme";
// import AppGQLClient from "./App.gqlclient";
import AppAlert from "./App.alert";
import AppErrorBoundary from "./App.error_boundry";
import RouterApp from "./router";
 
import { store as ReduxStore } from "./redux/store";
import { Provider } from "react-redux"; 
import { CssBaseline } from "@material-ui/core"; 
import AppDialog from "./App.dialog";
import AppBackdrop from "./App.backdrop";
// import AppDrawer from "./App.drawer2";
 
function App() {
  console.log = function () {};
  return ( 
    <Provider store={ReduxStore}> 
      <AppAuth>
        <AppErrorBoundary>
          <AppTheme> 
            <CssBaseline />
            {/* <AppGQLClient> */}
            <AppAlert>
              <AppDialog>
                <AppBackdrop>
                  {/* <AppDrawer> */}
                  <RouterApp />
                  {/* </AppDrawer> */} 
                </AppBackdrop>
              </AppDialog>
            </AppAlert>
            {/* </AppGQLClient> */}
          </AppTheme> 
        </AppErrorBoundary>
      </AppAuth>
    </Provider>
  );
}

export default App;
