import React from 'react';
import RecommendedProducts from '../../../../components/recommended/recommendedProducts/recommendedProducts'
import { Grid } from '@material-ui/core';
// import { ListCard } from '../../../../components/listGrid'

import { routes } from '../../../../router/routes'

import { actions } from 'tanyacare-middleware'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NotFoundSection } from '../../../../components';

class RecommendedProductDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.getRecommendedProductDetails()
    }

    getRecommendedProductDetails = () => {
        const id = this.props?.match?.params?.client_id
        this.props.GET_CLIENT_ALL_RECOMMENDED({ cliendID: id })
    }

    onProductClick = (product) => {
        const id = this.props?.match?.params?.client_id
        this.props.history.push(routes.monitor_client_recommendation_parent + 'tracking/' + id + '/' + product?.product_id)
    }

    render() {
        const { data } = this.props.recommendedProducts
        return (
            <div style={{ padding: 25 }}>
                <Grid container spacing={2}>
                    {data?.recommended_products_list?.map((product, index) => {
                        return (
                            <Grid onClick={() => this.onProductClick(product)} key={index} style={{ cursor: 'pointer' }} item xs={12}>
                                <RecommendedProducts {...product} />
                            </Grid>
                        )
                    })}

                    {data?.recommended_products_list?.length === 0 && <div style={{ width: "100%" }}>
                        <NotFoundSection message="Nothing to track" />
                    </div>}

                    {/* The following code is the alternate version of above mapping */}
                    {/*
                    <Grid item xs={12}>
                        <RecommendedProducts isAvatar={true} />
                    </Grid> */}
                </Grid>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    recommendedProducts: state?.clientRecommendation?.cliendAllRecommended
})

export default connect(mapStateToProps, actions)(withRouter(RecommendedProductDetails));