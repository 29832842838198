import React from "react";
import {
  Autocomplete,
  GoogleMap,
  // InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
// import { InputBase } from "@material-ui/core";
import { TextField } from "../inputs/textField";

// const containerStyle = {
//   width: "100vw",
//   height: "100vh",
// };

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};
const mapCenter = { lat: 4.210484, lng: 101.975769 };
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

let geocoder;

function CustomGoogleMap({
  setParentState,
  address: selectedAddress,
  userLocation,
  ...props
}) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script", //This id is helpful in avoiding multiple js loading.
    googleMapsApiKey: "AIzaSyBqeACJgp12OLU6EkHeUtFgya2naH1LhrU",
    libraries: libraries,
  });

  const [map, setMap] = React.useState(null);
  const [autocomplete, setAutocomplete] = React.useState(null);
  const [marker, setMarker] = React.useState(null);
  const [, setMarkerSelected] = React.useState(null);
  const [address, setAddress] = React.useState("");
  const [addressComponents, setAddressComponents] = React.useState("");
  const [latLng, setLatLng] = React.useState(null);

  React.useEffect(() => {
    setParentState({
      address: address,
      addressComponents: addressComponents,
      lat: latLng?.lat,
      lng: latLng?.lng,
    });
  }, [address, addressComponents, latLng, setParentState]);


  const onLoad = React.useCallback(function callback(map) {
    geocoder = new window.google.maps.Geocoder();
    setMap(map);
    if(selectedAddress?.latitude && selectedAddress?.longitude){
      let coords = {
        lat: parseFloat(selectedAddress?.latitude),
        lng: parseFloat(selectedAddress?.longitude),
      }
      setMarker(coords);
      generateAndSetAddress({...selectedAddress, formatted_address: selectedAddress?.address})
    } else {
      setMarker(userLocation);
      generateAddress(userLocation)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onAutocompleteLoad = React.useCallback(function callback(autocomplete) {
    setAutocomplete(autocomplete);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const generateAddress = (latlng) => {
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          console.log(results[0]);
          if(props.isVenueSelected) {
            setAddress(selectedAddress?.address)
          } else {
            setAddress(results[0]?.formatted_address);
          }
          setAddressComponents(results[0]?.address_components);
          setLatLng({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
          if(map){
            map.panTo(results[0].geometry.location);
            // map.setZoom(13);
          }
        } else {
          console.log("No results found");
        }
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  };

  // const generateAndSetAddress = (place) => {
  //   setAddress(place.formatted_address);
  //   setAddressComponents(place.address_components);
  // };

  const generateAndSetAddress = (place, isDialogPlaceChanged) => {
    setAddress(place.formatted_address);
    setAddressComponents(place.address_components);
    let coord = isDialogPlaceChanged ? {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    } : {
      lat: place.latitude,
      lng: place.longitude,
    }
    if(props.isVenueSelected) {
      setAddress(selectedAddress?.address)
    } else {
      setAddress(place?.formatted_address);
    }
    setAddressComponents(place?.address_components);
    setLatLng(coord);
    if(map){
      map.panTo(coord);
      // map.setZoom(13);
    }
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      let place = autocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        console.error("Returned place contains no geometry");
        return;
      } else {
        let selected = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        // generateAddress(selected);
        generateAndSetAddress(place, true)
        setMarker(selected);
        map.panTo(selected);
        // map.setZoom(13);
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const onLocationChanged = (event) => {
    if(!props.isVenueSelected){
      setMarker({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
      generateAddress({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    } 
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={userLocation ?? mapCenter}
      options={options}
      zoom={18}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={onLocationChanged}
    >
      <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
        <TextField
          type="text"
          placeholder="Search location"
          value={address}
          handleOnChange={(event) => setAddress(event.target.value)}
          style={{ margin: "0px 16px" }}
          disabled={props.isVenueSelected ? true : false}
        />
      </Autocomplete>
      <Marker
        position={{ lat: marker?.lat ?? 0, lng: marker?.lng ?? 0 }}
        onClick={() => setMarkerSelected(marker)}
      />
      {/* {markerSelected ? (
        <InfoWindow
          position={{ lat: markerSelected.lat, lng: markerSelected.lng }}
          onCloseClick={() => setMarkerSelected(null)}
        >
          <div>
            <h2>Location is here</h2>
          </div>
        </InfoWindow>
      ) : null} */}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default CustomGoogleMap
