import React from "react";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#E9E9E9",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.success.main,
  },
}))(LinearProgress);

function ArrivalTime({ durationText, travelledPercentage }) {
  return (
    <div style={{ marginBottom: 16 }}>
      <Typography
        style={{
          fontSize: 12,
          fontWeight: 500,
          marginBottom: 8,
          textAlign: "left",
        }}
      >{`Reaching in ${durationText}`}</Typography>
      <BorderLinearProgress variant="determinate" value={travelledPercentage} />
    </div>
  );
}

export default ArrivalTime;

ArrivalTime.propTypes = {
  time: PropTypes.number,
};
