import React from "react";
import { Paper, withStyles, Grid, Typography } from "@material-ui/core";
import { HybridTypography } from "../../../../components";
import { ICONS } from "../../../../components/hybridTypography/typography";
import Divider from "@material-ui/core/Divider";
// import { customAddress } from "../../../../utils";
import ViewCardAddressEmailPhone from "../../../../components/viewAddressEmailPhone/viewCard";
// import ViewTax from "../../../../components/viewAddressEmailPhone/viewTax";
import { formComponentTypes } from "../../../../utils";
import ViewAddressEmailPhone from "../../../../components/viewAddressEmailPhone";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import {
  // ClientAddressIcon,
  // DOBIconV2,
  EmailIconV2,
  // GenderIconV2,
  // IdCardIconV2,
  // LocationIconV2,
  MobileIconV2,
  // NameIconV2,
  OccupationIconV2,
  LanguageIconV2,
  WeightIconV2,
  HeightIconV2,
  BmiIconV2,
  BloodgroupIconV2,
} from "../../../../assets";
// import { FamilyMemberCard } from "../../../../components";
import Carousel from "./carousel";

const styles = (theme) => ({
  paperCard: {
    borderRadius: "0px",
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
  },
  strong_name: {
    paddingLeft: 0,
    paddingRight: 12,
    color: theme.palette.ternary.main,
    marginLeft: "28px",
    boxShadow: "unset !important",
  },
  gridItems: {
    padding: "25px 25px 40px 32px",
  },
  gridTopItems: {
    padding: "25px 25px 0px 32px",
  },
  gridMiddleItems: {
    padding: "0px 25px 0px 32px",
  },
  gridBottomItems: {
    padding: "0px 25px 40px 32px",
  },
  dividerBackground: {
    backgroundColor: theme.palette.secondary.light,
  },
});

class DetailsCard extends React.Component {
  handleServiceProfessionalsViewAllClick = (
    data,
    type,
    label,
    isDontAppendString
  ) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewAddressEmailPhone
          data={data ?? []}
          drawer={this.props.drawer}
          onEndIconClickHandler={this.onClickIcon}
          type={type}
          label={
            label
              ? label?.toLowerCase()?.replace("select ", "").toUpperCase()
              : ""
          }
          isDontAppendString={isDontAppendString}
        />
      ),
    });
  };
  render() {
    const { classes, data, loading, theme } = this.props;

    return (
      <Paper className={classes.paperCard}>
        <Grid container>
          {/* Block-1 */}
          <Grid container item xs={12}>
            <Typography
              className={classes.gridTopItems}
              style={{ paddingTop: 20, fontWeight: "bold" }}
            >
              Personal Information
            </Typography>
            {/* Row-1 */}
            <Grid
              container
              item
              direction="row"
              md={12}
              sm={12}
              xs={12}
              className={classes.gridTopItems}
            >
              {/* <Grid item xs={6} sm={4} md={4}>
                <HybridTypography
                  text={
                    data?.ic_number
                      ? (data?.ic_number).toLowerCase() === "null"
                        ? "N/A"
                        : data?.ic_number
                      : "N/A"
                  }
                  header="I/C NUMBER"
                  loading={loading}
                  islistbottom={true}
                  icon={ICONS.CLIENTID(theme.palette.primary.main)}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <HybridTypography
                  text={
                    data?.passport_number
                      ? (data?.passport_number).toLowerCase() === "null"
                        ? "N/A"
                        : data?.passport_number
                      : "N/A"
                  }
                  header="PASSPORT NUMBER"
                  loading={loading}
                  islistbottom={true}
                  icon={ICONS.CLIENTID(theme.palette.primary.main)}
                />
              </Grid> */}
              <Grid item xs={6} sm={4} md={4}>
                <HybridTypography
                  icon={<EmailIconV2 fontSize="small" />}
                  text={data?.email ? data?.email : "N/A"}
                  header="PRIMARY EMAIL ADDRESS"
                  loading={loading}
                  islistbottom={true}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <HybridTypography
                  icon={<MobileIconV2 fontSize="small" />}
                  text={`${data?.mobile_no_isd_code ?? ""} ${data?.mobile_no}`}
                  header="PRIMARY MOBILE NUMBER"
                  loading={loading}
                  islistbottom={true}
                  islisttop={false}
                />
              </Grid>

              {data?.idTypes?.length > 0 && (
                <Grid item xs={6} sm={4} md={4}>
                  <ViewCardAddressEmailPhone
                    title={data?.idTypes?.[0]?.label ?? ""}
                    height={16}
                    width={16}
                    style={{ position: "relative", top: 2 }}
                    data={data?.idTypes ?? []}
                    emptyMessage="Not yet added"
                    element={formComponentTypes.ADD_ID_TYPE}
                    label={`${data?.idTypes?.[0]?.label} ID Number`}
                    onButtonClickHandler={() =>
                      this.handleServiceProfessionalsViewAllClick(
                        data?.idTypes,
                        formComponentTypes.ADD_TAX_ID,
                        "Tax"
                      )
                    }
                    // isListMiddle={true}
                  />
                </Grid>
              )}

              {data?.otherAddress?.length > 0 && (
                <Grid item xs={6} sm={4} md={4}>
                  <ViewCardAddressEmailPhone
                    title={data?.otherAddress?.[0]?.label ?? ""}
                    height={16}
                    width={16}
                    style={{ position: "relative", top: 2 }}
                    data={data?.otherAddress ?? []}
                    emptyMessage="Not yet added"
                    element={formComponentTypes.ADD_ADDRESS}
                    label={`${data?.otherAddress?.[0]?.label} Address`}
                    onButtonClickHandler={() =>
                      this.handleServiceProfessionalsViewAllClick(
                        data?.otherAddress,
                        formComponentTypes.ADD_ADDRESS,
                        "Address"
                      )
                    }
                  />
                </Grid>
              )}

              {data?.otherPhoneNumbers?.length > 0 && (
                <Grid item xs={6} sm={4} md={4}>
                  <ViewCardAddressEmailPhone
                    title={data?.otherPhoneNumbers?.[0]?.label ?? ""}
                    height={16}
                    width={16}
                    style={{ position: "relative", top: 2 }}
                    data={data?.otherPhoneNumbers ?? []}
                    emptyMessage="Not yet added"
                    element={formComponentTypes.ADD_PHONE_NUMBER}
                    label={`${data?.otherPhoneNumbers?.[0]?.label} Phone Number`}
                    onButtonClickHandler={() =>
                      this.handleServiceProfessionalsViewAllClick(
                        data?.otherPhoneNumbers,
                        formComponentTypes.ADD_PHONE_NUMBER,
                        "Phone Number"
                      )
                    }
                  />
                </Grid>
              )}
              {data?.otherEmailIds?.length > 0 && (
                <Grid item xs={6} sm={4} md={4}>
                  <ViewCardAddressEmailPhone
                    title={data?.otherEmailIds?.[0]?.label ?? ""}
                    height={16}
                    width={16}
                    style={{ position: "relative", top: 2 }}
                    data={data?.otherEmailIds ?? []}
                    emptyMessage="Not yet added"
                    element={formComponentTypes.ADD_EMAIL}
                    label={`${data?.otherEmailIds?.[0]?.label} Email`}
                    onButtonClickHandler={() =>
                      this.handleServiceProfessionalsViewAllClick(
                        data?.otherEmailIds,
                        formComponentTypes.ADD_EMAIL,
                        "Email"
                      )
                    }
                  />
                </Grid>
              )}
              <Grid item xs={6} sm={4} md={4}>
                <HybridTypography
                  // icon={ICONS.CLIENTOCCUPATION(theme.palette.primary.main)}
                  // icon={<PermIdentityIcon fontSize={"small"} htmlColor={theme.palette.primary.main} classes={{ fontSizeSmall: classes.iconSize }} />}
                  icon={<OccupationIconV2 fontSize={"small"} />}
                  text={data?.occupation ? data?.occupation : "N/A"}
                  header="OCCUPATION"
                  loading={loading}
                  islistbottom={true}
                  islisttop={loading ? false : true}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <HybridTypography
                  icon={<LanguageIconV2 fontSize={"small"} />}
                  list={
                    data?.languages
                      ? data?.languages && typeof data?.languages == "string"
                        ? JSON.parse(data?.languages).map((val) => val.label)
                        : []
                      : []
                  }
                  header="PREFERRED LANGUAGE"
                  loading={loading}
                  islistbottom={true}
                  islisttop={true}
                  handleViewMore={() => {
                    this.handleServiceProfessionalsViewAllClick(
                      JSON.parse(data?.languages),
                      "",
                      "Language"
                    );
                  }}
                />
              </Grid>
            </Grid>

            {/* Divider Row */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider className={classes.dividerBackground} />
            </Grid>
            {/* Block-1 COntent ends here */}
          </Grid>

          {/* Block-Address */}
          {/* <Grid container item xs={12} >
            <Grid
              container
              item
              direction="row"
              md={12}
              sm={12}
              xs={12}
              className={classes.gridTopItems}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ViewCardDetails
                  component={data?.otherAddress}
                  editData={data?.otherAddress}
                  hideActionBtn={true}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
              <Divider className={classes.dividerBackground} />
            </Grid>
          </Grid> */}

          {/* Block-Mobile */}
          {/* <Grid container item xs={12} >
            <Grid
              container
              item
              direction="row"
              md={12}
              sm={12}
              xs={12}
              className={classes.gridTopItems}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ViewCardDetailsMobile
                  component={data?.otherPhoneNumbers}
                  editData={data?.otherPhoneNumbers}
                  hideActionBtn={true}
                />
              </Grid>
            </Grid> */}

          {/* Divider Row */}
          {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
              <Divider className={classes.dividerBackground} />
            </Grid> */}
          {/* Block-1 COntent ends here */}
          {/* </Grid> */}

          {/* Block-Emails */}
          {/* <Grid container item xs={12} >
            <Grid
              container
              item
              direction="row"
              md={12}
              sm={12}
              xs={12}
              className={classes.gridTopItems}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ViewCardDetailsEmail
                  component={data?.otherEmailIds}
                  editData={data?.otherEmailIds}
                  hideActionBtn={true}
                />
              </Grid>
            </Grid> */}

          {/* Divider Row */}
          {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
              <Divider className={classes.dividerBackground} />
            </Grid> */}
          {/* Block-1 COntent ends here */}
          {/* </Grid> */}

          {/* Block-2 */}
          <Grid container item xs={12}>
            <Grid
              container
              item
              xs={12}
              // spacing={2}
              style={{ padding: "20px 25px 25px 32px" }}
            >
              <Carousel data={data?.familyMembers} />
            </Grid>
            {/* Row-1 */}
            {/* <Grid
              container
              item
              direction="row"
              md={12}
              sm={12}
              xs={12}
              className={classes.gridTopItems}
            >
              <Grid item xs={6} sm={4} md={4}>
                <HybridTypography
                  text={
                    data?.emergency_contact?.contact_name
                      ? data?.emergency_contact?.contact_name
                      : "N/A"
                  }
                  header="NAME"
                  loading={loading}
                  islistbottom={true}
                  icon={ICONS.CLIENTPERSON(theme.palette.primary.main)}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <HybridTypography
                  icon={ICONS.CLIENTRELATION(theme.palette.primary.main)}
                  text={
                    data?.emergency_contact?.relationship
                      ? data?.emergency_contact?.relationship
                      : "N/A"
                  }
                  header="RELATIONSHIP"
                  loading={loading}
                  islistbottom={true}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <HybridTypography
                  icon={ICONS.CLIENTMAIL(theme.palette.primary.main)}
                  text={
                    data?.emergency_contact?.email
                      ? data?.emergency_contact?.email
                      : "N/A"
                  }
                  header="EMAIL ADDRESS"
                  loading={loading}
                  islistbottom={true}
                />
              </Grid>
            </Grid> */}

            {/* Row-2 */}
            {/* <Grid
              container
              item
              direction="row"
              md={12}
              sm={12}
              xs={12}
              className={classes.gridMiddleItems}
            >
              <Grid item xs={6} sm={4} md={4}>
                <HybridTypography
                  icon={ICONS.CLIENTPHONE(theme.palette.primary.main)}
                  text={
                    data?.emergency_contact?.mobile_no_isd_code +
                    " " +
                    data?.emergency_contact?.mobile_no
                  }
                  header="MOBILE"
                  loading={loading}
                  islistbottom={true}
                />
              </Grid>
            </Grid> */}

            {/* Divider Row */}
            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider className={classes.dividerBackground} />
            </Grid> */}
            {/* Block-2 COntent ends here */}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider className={classes.dividerBackground} />
          </Grid>

          {/* Block-3 Health Information */}
          <Grid container item xs={12}>
            <Typography
              className={classes.gridTopItems}
              style={{ fontWeight: "bold" }}
            >
              Health Information
            </Typography>
            {/* Row-1 */}
            <Grid
              container
              item
              direction="row"
              md={12}
              sm={12}
              xs={12}
              className={classes.gridTopItems}
            >
              <Grid item xs={6} sm={4} md={4}>
                <HybridTypography
                  text={
                    data?.health_information?.height
                      ? data?.health_information?.height
                      : "N/A"
                  }
                  header="HEIGHT (CM)"
                  loading={loading}
                  islistbottom={true}
                  icon={<HeightIconV2 fontSize="small" />}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <HybridTypography
                  icon={<WeightIconV2 fontSize="small" />}
                  text={
                    data?.health_information?.weight
                      ? data?.health_information?.weight
                      : "N/A"
                  }
                  header="WEIGHT (KG)"
                  loading={loading}
                  islistbottom={true}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <HybridTypography
                  icon={<BmiIconV2 fontSize="small" />}
                  text={
                    data?.health_information?.height &&
                    data?.health_information?.weight
                      ? Math.round(
                          (data?.health_information?.weight /
                            Math.pow(data?.health_information?.height, 2)) *
                            10000
                        )
                      : "N/A"
                  }
                  header="BMI (KG/M2)"
                  loading={loading}
                  islistbottom={true}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <HybridTypography
                  icon={<BloodgroupIconV2 fontSize="small" />}
                  text={data?.bloodGroup ?? "N/A"}
                  header="BLOOD GROUP"
                  loading={loading}
                  islistbottom={true}
                />
              </Grid>
            </Grid>

            {/* Row-2 */}
            {/* <Grid
              container
              item
              direction="row"
              md={12}
              sm={12}
              xs={12}
              className={classes.gridMiddleItems}
            >

              //<Grid item xs={6} sm={6} md={6}>
              //  <HybridTypography
              //    icon={ICONS.CLIENTBP(theme.palette.primary.main)}
              //    text={data?.health_information?.blood_pressure ? data?.health_information?.blood_pressure : 'N/A'}
              //    header="BLOOD PRESSURE"
              //    loading={loading}
              //    islistbottom={true}
              //  />
              //</Grid>
            </Grid> */}

            {/* Divider Row */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider className={classes.dividerBackground} />
            </Grid>
            {/* Block-3 COntent ends here */}
          </Grid>

          {/* Block-4*/}
          <Grid container item xs={12}>
            {/* Row-1 */}
            <Grid item xs={12} sm={12} md={12} className={classes.gridTopItems}>
              <HybridTypography
                text={
                  data?.chief_complaint_current_complaint
                    ? data?.chief_complaint_current_complaint
                    : "N/A"
                }
                header="CHIEF COMPLAINT"
                loading={loading}
                isdangerouslySetInnerHTML={true}
                icon={ICONS.CLIENTMD(theme.palette.primary.main)}
              />
            </Grid>
            {/* Row-2 */}
            <Grid item xs={12} sm={12} md={12} className={classes.gridTopItems}>
              <HybridTypography
                text={data?.medical_history ? data?.medical_history : "N/A"}
                header="MEDICAL HISTORY"
                loading={loading}
                islistbottom={true}
                isdangerouslySetInnerHTML={true}
                icon={ICONS.CLIENTMD(theme.palette.primary.main)}
              />
            </Grid>

            {/* Row-2 */}
            {/* Offering like */}
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              className={classes.gridBottomItems}
            >
              <HybridTypography
                icon={ICONS.CLIENTSP(theme.palette.primary.main)}
                loading={loading}
                stringlist={data?.special_precaution}
                header="SPECIAL PRECAUTION"
                islistbottom={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withAlelrtAndDialog(
  withStyles(styles, { withTheme: true })(DetailsCard)
);
