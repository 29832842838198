import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { isJson } from '../../utils';

const useStyles = makeStyles((theme) => ({
    tableContainerRoot: {
        boxShadow: "none"
    },
    table: {
        minWidth: 700,
    },
    tableHeaderCellRoot: {
        padding: 12,
        color: theme.palette.secondary.main,
    },
    row: {
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)"
        }
    },
    cellNoWrap: {
        whiteSpace: "noWrap"
    }
}));

export default function NiceTable({ columns, rows, total, discount, taxAmount }) {
    const classes = useStyles();

    function ccyFormat(num=0) {
        
        let _num = Number(num)
        if(isJson(num)){
            _num = JSON.parse(num)
            _num = Number(_num)
        }
        // return _num?.length>0 ? `${parseFloat(_num).toFixed(2)}` : `${parseFloat(_num).toFixed(2)}`;
        return `${parseFloat(_num).toFixed(2)}`;
    }
     
    const getSubTotal = (rows) => {
        let sum = 0
        rows.map(x => {
            
            let _parseFloat = Number(x.amount)
            if(isJson(x.amount)){
                _parseFloat = JSON.parse(x.amount)
                _parseFloat = Number(_parseFloat)
            }
            sum += _parseFloat
            return x
        })
        return ccyFormat(sum)
    }

    return (
        <TableContainer component={Paper} classes={{ root: classes.tableContainerRoot }}>
            <Table stickyHeader className={classes.table} aria-label="spanning table">
                <TableHead>
                    <TableRow>
                        {columns?.map(column => (
                            <TableCell key={column?.id} align={column?.align} classes={{ root: classes.tableHeaderCellRoot }} >{column?.label}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((row, rowIndex) => (
                        <TableRow key={row.desc} className={classes.row}>
                            {columns?.map((column, index) => (
                                column?.id === "sno"
                                    ? <TableCell>{rowIndex + 1}</TableCell>
                                    : <TableCell key={column?.id} align={column?.align}>{row[column?.id]}</TableCell>
                            ))}
                        </TableRow>
                    ))}

                    <TableRow>
                        <TableCell rowSpan={4} colSpan={columns?.length - 2} />
                        <TableCell colSpan={1} className={classes.cellNoWrap} >Subtotal</TableCell>
                        <TableCell align="right">{getSubTotal(rows)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={1} className={classes.cellNoWrap} >Discount %</TableCell>
                        <TableCell align="right">{ccyFormat(discount)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={1} className={classes.cellNoWrap} >Tax Amount</TableCell>
                        <TableCell align="right">{ccyFormat(taxAmount)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={1} className={classes.cellNoWrap} >Total</TableCell>
                        <TableCell align="right">{ccyFormat(total)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}