import React, { Component } from "react";
import PropTypes from "prop-types";
import DynamicForm from "../../components/dynamicForm";
import { Grid, withStyles } from "@material-ui/core";
// Typography
// Paper
// import {
//   LoadingSection,
//   SelectFromPlaceholder,
//   SimpleSelect,
// } from "../../components";
import moment from "moment";
import CertificateDrawer from "./certificateDrawer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
import { actions } from "tanyacare-middleware";
import PhoneComponent from "../../components/SelectComponentWithSubComponent/phoneComponent";
import AddressComponent from "../../components/SelectComponentWithSubComponent/addressComponent";
import EmailComponent from "../../components/SelectComponentWithSubComponent/emailComponent";
import { alertProps, formComponentTypes } from "../../utils";
import IdTypeComponent from "../../components/SelectComponentWithSubComponent/idTypeComponent";
import { v4 as uuidv4 } from 'uuid';
import TaxComponent from "../../components/SelectComponentWithSubComponent/taxComponent";
const style = (theme) => ({
  // "& .MuiPaper-elevation1": (props) => ({ boxShadow: !props.withBoxShadow ? 'none !important' : '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }),
  root: {
    width: "100%",
    // padding:"10px",
    padding: "8px 22px 16px 22px",
    marginBottom: 50,
    boxShadow: (props) =>
      props.withoutBoxShadow
        ? "none !important"
        : "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    background: (props) =>
      props.withoutBoxShadow ? "transparent !important" : "white",
  },
});
class AddEditDynamicFromRenderer extends Component {
  constructor(props) {
    super();
    this.state = {
      experience: [],
      certificate: [],
      address: [],
      email: [],
      phone: [],
      idType: [],
      tax: [],
      isFirstTime: false,
      isAddressRequired: false,
      isAddressError: false,
      isIdTypeRequired: false,
      isIdTypeError: false,
    };
  }


  componentDidMount() {
    this.props.GET_MASTER_CERT_ORGANIZATION({});
    this.props.GET_MASTER_EXP_ORGANIZATION({});
    this.props.GET_MASTER_CERTIFICATES({});
    this.props.GET_MASTER_EXP_ROLES({});
  }

  // FOR SAVING THE DATA RETURNED FROM DYNAMIC COMPONENT
  // YOU WILL GET ERROR STATUS, DATA OBJECT AND URL PARAMATERS
  // YOU CAN HIT YOUR ACTIONS HERE..
  onSaveCallback = async (error, data, url) => {

    if (error) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please fill the mandatory fields.",
        severity: alertProps.severity.error,
      });
      if (Boolean(this.state.isAddressRequired)) {
        if (Boolean(this.state?.address?.length <= 0)) {
          this.setState({ isAddressError: true })
        }
        else {
          this.setState({ isAddressError: false })
        }
      }
      if (Boolean(this.state.isIdTypeRequired)) {
        if (Boolean(this.state?.idType?.length <= 0)) {
          this.setState({ isIdTypeError: true })
        }
        else {
          this.setState({ isIdTypeError: false })
        }
      }
    }
    else if ((Boolean(this.state.isAddressRequired)) && (Boolean(this.state?.address?.length <= 0))) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please fill the mandatory fields.",
        severity: alertProps.severity.error,
      });
      this.setState({ isAddressError: true })
    }
    else if ((Boolean(this.state.isIdTypeRequired)) && (Boolean(this.state?.idType?.length <= 0))) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please fill the mandatory fields.",
        severity: alertProps.severity.error,
      });
      this.setState({ isIdTypeError: true })
    }


    else {


      let {idType} = this.state

      // Need to upload photos from array of id_types && set fileId to id_photo
      const uploadPromotionalContent = async (payloadNew) => {


        if(idType?.length > 0){


          for (const [index, element] of idType?.entries()){
            const photo = element?.id_photo

            // If id_photo has a fileId already, then break.
            if(parseInt(element?.id_photo)){
              continue;
            }


            // ID UPLOAD
            if(photo){
              if(photo?.constructor === File && photo?.name){
                let _filename = photo.name.trim();
                let error = false
                await this.props
              .FILE_UPLOAD({
                file: photo,
                fileName: _filename,
              })
              .then(async (val) => {
                let _val = await val;
                if (_val?.payload?.data?.fileid) {
                  idType[index]["id_photo"] =
                    _val?.payload?.data?.fileid ?? "";
                    idType[index]["id_photo_filetype"] =
                    _val?.payload?.data?.filetype ?? "";
                    idType[index]["id_photo_size"] =
                    photo?.size ?? "";
                } else {
                  console.log(val, "promotional content error");
                  this.props.alert.setSnack({
                    open: true,
                    horizontal: alertProps.horizontal.center,
                    vertical: alertProps.vertical.top,
                    msg:
                      `File Id not received after uploading ${element?.label} ID type. Try again!`,
                    severity: alertProps.severity.error,
                  });
                }
              })
              .catch((val) => {
                console.log(val, "promotional content error");
                this.props.alert.setSnack({
                  open: true,
                  horizontal: alertProps.horizontal.center,
                  vertical: alertProps.vertical.top,
                  msg: `Couldn't upload ${element?.label} ID type. Try again!`,
                  severity: alertProps.severity.error,
                });
                error = true

              });

              if(error) break;

              }
            }
          }
        }
      };

      await uploadPromotionalContent();

      this.setState({
        idType: idType
      })

      let { componentForm } = this.props;
      let _keys = Object.keys(componentForm);
      _keys.map((val) => {
        let _obj = { ...componentForm[val] };

        if (formComponentTypes.ADD_ADDRESS === _obj.element) {
          _obj["value"] = this.state.address;
        } else if (formComponentTypes.ADD_PHONE_NUMBER === _obj.element) {
          _obj["value"] = this.state.phone;
        } else if (formComponentTypes.ADD_EMAIL === _obj.element) {
          _obj["value"] = this.state.email;
        } else if (formComponentTypes.ADD_CERTIFICATION === _obj.element) {
          _obj["value"] = this.state.certificate;
        } else if (formComponentTypes.ADD_EXPERIENCE === _obj.element) {
          _obj["value"] = this.state.experience;

        } else if (formComponentTypes.ADD_ID_TYPE === _obj.element) {
          _obj["value"] = this.state.idType;
        } else if (formComponentTypes.ADD_TAX_ID === _obj.element) {
          _obj["value"] = this.state.tax;
        }

        else if (formComponentTypes.MOBILE_INPUT_BOX === _obj.element) {
          let __newObj = {}
          let __mobileObjOptions = { ..._obj["options"] }
          __mobileObjOptions = []
          __newObj[_obj['key']['select_key']] = data[_obj['key']['select_key']]
          __newObj[_obj['key']['input_value_key']] = data[_obj['key']['input_value_key']]
          // __newObj['mobile_code'] = data?.mobile_code ?? ""
          // __newObj['mobilenumber'] = data?.mobilenumber ?? ""
          _obj["value"] = __newObj;
          _obj["options"] = __mobileObjOptions;
        }
        else if (formComponentTypes.RESOURCE_VALIDITY === _obj.element) {
          let __newObj = {}
          __newObj[_obj['key']['startDate']] = data[_obj['key']['startDate']]
          __newObj[_obj['key']['endDate']] = data[_obj['key']['endDate']] ? data[_obj['key']['endDate']] : ""
          _obj["value"] = __newObj;
        }
        else if (formComponentTypes.SELECT_BOX === _obj.element) {
         if(_obj?.['requiredAction']?.length > 0){

           _obj["options"] = [];
         } else {
           //This else condition was coded by sridhar for fixing origin of country issue.
          _obj["value"] = data[val] ?? "";
         }
        }
        else {
          _obj["value"] = data[val] ?? "";
        }
        componentForm = { ...componentForm, [val]: _obj };
        return true
      });
      console.log(componentForm, "componentForm---");

      this.props.actionToDispatch(componentForm, data);
      // this.props[actionName](bodyParams);
    }
  };

  // ADD CERTIFICATION COMPONENT PARENT CALLBACKS
  commonExperienceCertificationFunctions = () => {

    const updateStateInParent = (childState) => {
      this.setState({
        ...childState,
      });
    };
    const openDrawer = (list, exp_lists, id) => {
      const {
        drawer_title,
        start_date,
        end_date,
        isedit,
        name_ck,
        name_ckm,
        role,
        organisation_name,
        present,
        certificate,
        experience,
        iseditexperience,
      } = this.state;
      const checklist =
        drawer_title === "Edit Certification" ||
        drawer_title === "Add Certification";
      const {
        data: certificates,
        loading: certificatesLoading,
      } = this.props?.certificates;

      const {
        data: cert_organization,
        loading: cert_organizationLoading,
      } = this.props?.cert_organization;

      const {
        data: exp_roles,
        loading: exp_rolesLoading,
      } = this.props?.exp_roles;

      const {
        data: exp_organization,
        loading: exp_organizationLoading,
      } = this.props?.exp_organization;
      let cer_list = certificates;
      if (list && list.length > 0) {
        let ids = list.map(val => val.certificateId)
        cer_list = certificates.filter(val => ids.indexOf(val.value) === -1)
      }
      // let exp_list = exp_organization;
      // if (exp_lists && exp_lists.length > 0) {
      //   let ids = exp_lists.map(val => val.organizationId)
      //   exp_list = exp_organization.filter(val => ids.indexOf(val.value) === -1)
      // }
      const actionProps = {
        certificates: cer_list,
        certificatesLoading,
        cert_organization,
        cert_organizationLoading,
        exp_roles,
        exp_rolesLoading,
        exp_organization: exp_organization,
        exp_organizationLoading,
      };

      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: true,
        component: (
          <CertificateDrawer
            id={id}
            list={list}
            initialStateInParent={{
              start_date: start_date,
              end_date: end_date,
              isedit: isedit,
              name_ck: name_ck,
              name_ckm: name_ckm,
              role: role,
              organisation_name: organisation_name,
              present: present,
              certificate: certificate,
              experience: experience,
              iseditexperience: iseditexperience,
            }}
            mandatoryProps={{
              drawer_title: this.state.drawer_title,
              checklist: checklist,
            }}
            actionProps={actionProps}
            // handleCertExpAdd={this.handleCertExpAdd}
            // handleCertExpDelete={this.handleCertExpDelete}
            updateStateInParent={updateStateInParent}
          />
        ),
      });
    };

    return {
      handledrawer: (name, data, i, nm, nme, listdata, id) => {      
        if (name?.includes("Certification")) {
          const v = data?.certificateName;
          const v_ = data?.certificateId;
          const s = data?.organizationName;
          const s_ = data?.organizationId;
          this.setState(
            {
              start_date: data?.startingFrom
                ? moment(data?.startingFrom)
                : null,
              end_date: data?.noExpiry ? null : data?.endingIn ? moment(data?.endingIn) : null,
              drawer_title: name,
              isedit: i,
              name_ck: nm,
              name_ckm: nme,
              role: s ? { value: s_, label: s } : null,
              organisation_name: v ? { value: v_, label: v } : null,
              open: !this.state.open,
              present: data?.noExpiry,
            },
            () => openDrawer(listdata ? listdata : data, null,id)
          );
        } else {
          const v1 = data?.roleName;
          const v_1 = data?.roleId;
          const s1 = data?.organizationName;
          const s_1 = data?.organizationId;
          this.setState(
            {
              start_date: data?.startingFrom
                ? moment(data?.startingFrom)
                : null,
              end_date: data?.present ? null : data?.endingIn ? moment(data?.endingIn) : null,
              drawer_title: name,
              isedit: i,
              name_ck: nm,
              name_ckm: nme,
              role: s1 ? { value: v_1, label: v1 } : null,
              organisation_name: v1 ? { value: s_1, label: s1 } : null,
              open: !this.state.open,
              present: data?.present,
            },
            () => openDrawer(null, listdata ? listdata : data)
          );
        }
      },
      ondltList: (drawerName, selectedIndex,from="") => {
        const state = this.state;
        //Checking for the name
        if (drawerName.includes("Certification")) {
          this.props.dialog.setDialog({
            open: true,
            title: "Are you sure you want to delete this Certification ?",
            positiveBtn: "Delete",
            negativeBtn: "Cancel",
            onOk: () => {
              this.props.dialog.setDialog({ open: false });
              this.setState({ isdelete: true }, () => {
                if (this.state.isdelete) {
                  const certOrExp =
                    drawerName === "Edit Certification" ||
                      drawerName === "Add Certification"
                      ? "certificate"
                      : "experience";
                  let arr = Object.assign([], state[certOrExp]);
                  // var x = arr.map(function (item) { return item.certificateId }).indexOf(i);
                  arr.splice(selectedIndex, 1);
                  state[certOrExp] = arr;
                  this.setState({
                    ...state,
                  });
                }
              });
            },
          });
        } else {
          this.props.dialog.setDialog({
            open: true,
            title: "Are you sure you want to delete this Experience ?",
            negativeBtn: "Cancel",
            positiveBtn: "Delete",
            onOk: () => {
              this.props.dialog.setDialog({ open: false });
              if(from === "drawer"){
                this.props.drawer.setDrawer({
                  ...this.props.drawer,
                  open: false
                }) 
              }
              this.setState({ isdelete: true }, () => {
                if (this.state.isdelete) {
                  const certOrExp =
                    drawerName === "Edit Certification" ||
                      drawerName === "Add Certification"
                      ? "certificate"
                      : "experience";
                  let arr = Object.assign([], state[certOrExp]);
                  // var x = arr.map(function (item) { return item.roleId }).indexOf(i);
                  arr.splice(selectedIndex, 1);
                  state[certOrExp] = arr;
                  this.setState({
                    ...state,
                  });
                }
              });
            },
          });
        }
      },
    };
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  experience = () => {
    return {
      onDragEnd1: (result) => {
        // // dropped outside the list
        // if (!result.destination) {
        //   return;
        // }
        // const items = this.reorder(
        //   this.state?.experience,
        //   result.source.index,
        //   result.destination.index
        // );
        // let update = items.map((val, index) => {
        //   let v_ = Object.assign({}, val);
        //   v_.orderNo = index + 1;
        //   return v_;
        // });
        this.setState({
          experience: result,
        });
      },
      experience: this.state.experience,

    };
  };

  certification = () => {
    return {
      onDragEnd: (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
        const items = this.reorder(
          this.state?.certificate,
          result.source.index,
          result.destination.index
        );
        let update = items.map((val, index) => {
          let v_ = Object.assign({}, val);
          v_.orderNo = index + 1;
          return v_;
        });
        this.setState({
          certificate: update,
        });
      },
      certification: this.state.certificate,
    };
  };
  //

  // FOR ADDING/VIEWING/EDITING/DELETING ADDRESS EMAIL AND PHONE NUMBERS
  addressEmailPhoneNumberfuntions = () => {

    const callBackOnSaveAddress = (data, isremove) => {
      // const { error } = this.state;
      if (isremove) {
        let isUpdateData = this.state.address?.filter(
          (val) => val.value !== data?.value
        );

        this.setState({ address: isUpdateData }, () => {
          console.log("addressaddress", this.state.address);
        });
      } else {
        this.props.drawer.setDrawer({
          component: <></>,
          open: false,
        });
        //  error["add_address"] = false
        //  this.setState({error})
        if (data) {
          if (this.state.address?.length > 0) {
            let isUpdateData = this.state.address?.findIndex(
              (val) => val.id === data?.id
            );
            if (isUpdateData > -1) {
              let _newData = {
                ...data,
                label: data.address_type.label,
                value: data.address_type.value,
                id: uuidv4()
              };
              let { address } = this.state;
              let newArray = [...address];
              newArray[isUpdateData] = _newData;
              address = newArray;
              this.setState({ address }, () => {
                console.log("addressaddress", this.state.address);
              });
            } else {
              let _newData = {
                ...data,
                label: data.address_type.label,
                value: data.address_type.value,
                id: uuidv4()
              };
              this.setState(
                { address: [...this.state.address, _newData] },
                () => {
                  console.log("addressaddress", this.state.address);
                }
              );
            }
          } else {
            let _newData = {
              ...data,
              label: data.address_type.label,
              value: data.address_type.value,
              id: uuidv4()
            };
            // let {address} = this.state

            // address.push(_newData)
            this.setState(
              { address: [...this.state.address, _newData] },
            );
          }
        }
      }
    };
    const callBackOnSaveEmail = (data, isremove) => {

      if (isremove) {
        let isUpdateData = this.state.email?.filter(
          (val) => val.value !== data?.value
        );

        this.setState({ email: isUpdateData }, () => {
          console.log("addressaddress", this.state.email);
        });
      } else {
        this.props.drawer.setDrawer({
          component: <></>,
          open: false,
        });
        if (data) {
          if (this.state.email?.length > 0) {
            let isUpdateData = this.state.email?.findIndex(
              (val) => val.id === data?.id
            );
            if (isUpdateData > -1) {
              let _newData = {
                ...data,
                emailId: data.email,
                label: data.email_type.label,
                value: data.email_type.value,
                id: uuidv4()
              };
              let { email } = this.state;
              let newArray = [...email];
              newArray[isUpdateData] = _newData;
              email = newArray;
              this.setState({ email }, () => {
                console.log("addressaddress", this.state.email);
              });
            } else {
              let _newData = {
                ...data,
                emailId: data.email,
                label: data.email_type.label,
                value: data.email_type.value,
                id: uuidv4()
              };
              this.setState({ email: [...this.state.email, _newData] }, () => {
                console.log("addressaddress", this.state.email);
              });
            }
          } else {
            let _newData = {
              ...data,
              emailId: data.email,
              label: data.email_type.label,
              value: data.email_type.value,
              id: uuidv4()
            };
            this.setState({ email: [...this.state.email, _newData] }, () => {
              console.log("addressaddress", this.state.email);
            });
          }
        }
      }
    };
    const callBackOnSavePhone = (data, isremove) => {
      // const { error } = this.state;
      if (isremove) {
        let isUpdateData = this.state.phone?.filter(
          (val) => val.value !== data?.value
        );

        this.setState({ phone: isUpdateData }, () => {
          console.log("addressaddress", this.state.phone);
        });
      } else {
        this.props.drawer.setDrawer({
          component: <></>,
          open: false,
        });
        // error["add_phone"] = false
        // this.setState({error})
        if (data) {
          if (this.state.phone?.length > 0) {
            let isUpdateData = this.state.phone?.findIndex(
              (val) => val.id === data?.id
            );
            if (isUpdateData > -1) {
              let _newData = {
                ...data,
                mobileNo: data?.mobilenumber,
                mobileNoIsdCode: data?.mobilenumberISDcode,
                label: data.mobile_type.label,
                value: data.mobile_type.value,
                id: uuidv4()
              };
              let { phone } = this.state;
              let newArray = [...phone];
              newArray[isUpdateData] = _newData;
              phone = newArray;
              this.setState({ phone }, () => {
                console.log("addressaddress", this.state.phone);
              });
            } else {
              let _newData = {
                ...data,
                mobileNo: data?.mobilenumber,
                mobileNoIsdCode: data?.mobilenumberISDcode,
                label: data.mobile_type.label,
                value: data.mobile_type.value,
                id: uuidv4()
              };
              this.setState({ phone: [...this.state.phone, _newData] }, () => {
                console.log("addressaddress", this.state.phone);
              });
            }
          } else {
            let _newData = {
              ...data,
              mobileNo: data?.mobilenumber,
              mobileNoIsdCode: data?.mobilenumberISDcode,
              label: data.mobile_type.label,
              value: data.mobile_type.value,
              id: uuidv4()
            };
            this.setState({ phone: [...this.state.phone, _newData] }, () => {
              // console.log("addressaddress", this.state.phone);
            });
          }
        }
      }
    };
    const callBackOnSaveIdType = (data, isremove) => {

      if (isremove) {
        let isUpdateData = this.state.idType?.filter(
          (val) => val.value !== data?.value
        );

        this.setState({ idType: isUpdateData }, () => {
          // console.log("addressaddress", this.state.idType);
        });
      } else {
        this.props.drawer.setDrawer({
          component: <></>,
          open: false,
        });
        if (data) {
          if (this.state.idType?.length > 0) {

            let isUpdateData = this.state.idType?.findIndex(
              (val) => val.id === data?.id
            );
            if (isUpdateData > -1) {
              let _newData = {
                ...data,
                id_type: data.id_type,
                label: data.id_type_name.label,
                value: data.id_type_name.value,
                id_photo: data.id_photo,
                file: data.id_photo,
                id: uuidv4()
              };
              let { idType } = this.state;
              let newArray = [...idType];
              newArray[isUpdateData] = _newData;
              idType = newArray;
              this.setState({ idType }, () => {
                console.log("addressaddress", this.state.idType);
              });
            } else {
              // let {idType} = this.state
              let _newData = {
                ...data,
                id_type: data.id_type,
                label: data.id_type_name.label,
                value: data.id_type_name.value,
                id_photo: data.id_photo,
                file: data.id_photo,
                id: uuidv4()
              };
              // this.state.idType.push(_newData)
              this.setState({ idType: [...this.state.idType, _newData] }, () => {
                console.log("addressaddress", this.state.idType);
              });
            }
          } else {
            let _newData = {
              ...data,
              id_type: data.id_type,
              label: data.id_type_name.label,
              value: data.id_type_name.value,
              id_photo: data.id_photo,
              file: data.id_photo,
              id: uuidv4()
            };
            this.setState({ idType: [...this.state.idType, _newData] }, () => {
              console.log("addressaddress", this.state.idType);
            });
          }
        }
      }
    };
    const callBackOnSaveTax = (data, isremove) => {

      if (isremove) {
        let isUpdateData = this.state.tax?.filter(
          (val) => val.value !== data?.value
        );

        this.setState({ tax: isUpdateData }, () => {
          console.log("tax", this.state.tax);
        });
      } else {
        this.props.drawer.setDrawer({
          component: <></>,
          open: false,
        });
        if (data) {
          if (this.state.tax?.length > 0) {
            let isUpdateData = this.state.tax?.findIndex(
              (val) => val.id === data?.id
            );
            if (isUpdateData > -1) {
              let _newData = {
                // data contains effective, noExipry, expiry, taxRegistrationNumber.
                ...data,
                label: data.tax_name.label,
                value: data.tax_name.value,
                taxRegistrationNumber: data.taxRegistrationNumber,
                expiry: data.noExpiry ? null : data.expiry,
                id: uuidv4()
              };
              let { tax } = this.state;
              let newArray = [...tax];
              newArray[isUpdateData] = _newData;
              tax = newArray;
              this.setState({ tax }, () => {
                console.log("tax", this.state.tax);
              });
            } else {
              let _newData = {
                // data contains effective, noExipry, expiry, taxRegistrationNumber.
                ...data,
                label: data.tax_name.label,
                value: data.tax_name.value,
                expiry: data.noExpiry ? null : data.expiry,
                id: uuidv4()
              };
              this.setState({ tax: [...this.state.tax, _newData] }, () => {
                console.log("tax", this.state.tax);
              });
            }
          } else {
            let _newData = {
              // data contains effective, noExipry, expiry, taxRegistrationNumber.
              ...data,
              label: data.tax_name.label,
              value: data.tax_name.value,
              expiry: data.noExpiry ? null : data.expiry,
              id: uuidv4()
            };
            this.setState({ tax: [...this.state.tax, _newData] }, () => {
              console.log("tax", this.state.tax);
            });
          }
        }
      }
    };

    return {
      // id type
      callBackOnSaveIdType: callBackOnSaveIdType,
      onAddEditDrawerIdType: (name, e, list) => {
        console.log(this.props.id)
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          component: (
            <IdTypeComponent
              id={this?.props?.id}
              type="idType"
              list={list}
              callBackOnSave={callBackOnSaveIdType}
            />
          ),
          open: true,
        });
      },

      viewDetailsIdTypeEditDetails: (isEdit, editData, data, salutation) => {
        let _arrayOfJson = [];

        if (data?.length > 0) {
          _arrayOfJson = data;
        }
        return _arrayOfJson;
      },


      // address
      callBackOnSaveAddress: callBackOnSaveAddress,
      onAddEditDrawerAddress: (name, e, list) => {
        console.log(this.props.id)
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          component: (
            <AddressComponent
              id={this?.props?.id}
              type="address"
              list={list}
              callBackOnSave={callBackOnSaveAddress}
            />

          ),
          open: true,
        });
      },

      viewDetailsAddressEditDetails: (isEdit, editData, data, salutation) => {
        let _arrayOfJson = [];

        if (data?.length > 0) {
          _arrayOfJson = data;
        }
        return _arrayOfJson;
      },

      // EMAIL

      callBackOnSaveEmail: callBackOnSaveEmail,
      onAddEditDrawerEmail: (e, val, list) => {
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          component: (
            <EmailComponent id={this?.props?.id} type="email" list={list} callBackOnSave={callBackOnSaveEmail} />
          ),
          open: true,
        });
      },

      viewDetailsEmailEditDetails: (isEdit, editData, data, salutation) => {
        let _arrayOfJson = [];

        if (data?.length > 0) {
          _arrayOfJson = data;
        }
        return _arrayOfJson;
      },

      // PHONE
      callBackOnSavePhone: callBackOnSavePhone,

      onAddEditDrawerPhone: (e, val, list) => {
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          component: (
            <PhoneComponent id={this?.props?.id} type="Phone" list={list} callBackOnSave={callBackOnSavePhone} />
          ),
          open: true,
        });
      },

      viewDetailsPhoneEditDetails: (isEdit, editData, data, salutation) => {
        let _arrayOfJson = [];

        if (data?.length > 0) {
          _arrayOfJson = data;
        }
        return _arrayOfJson;
      },

      // TAX
      callBackOnSaveTax: callBackOnSaveTax,
      onAddEditDrawerTax: (e, val, list) => {
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          component: (
            <TaxComponent id={this?.props?.id} type="tax" list={list} callBackOnSave={callBackOnSaveTax} />
          ),
          open: true,
        });
      },

      viewDetailsTaxEditDetails: (isEdit, editData, data, salutation) => {
        let _arrayOfJson = [];

        if (data?.length > 0) {
          _arrayOfJson = data;
        }
        return _arrayOfJson;
      },


      tax: this.state.tax,
      phone: this.state.phone,
      address: this.state.address,
      isAddressRequired: this.state.isAddressError,
      isIdTypeRequired: this.state.isIdTypeError,
      email: this.state.email,
      idType: this.state.idType,
    };
  };
  componentWillReceiveProps(nextProps) {
    console.log("came in...")

    let currentState = { ...this.state };
    let { address, phone, email, certificate, experience } = currentState
    if (!this.state.isFirstTime) {
      if (this.props.isEdit) {

        if (address?.length > 0 || email?.length > 0 || phone?.length > 0 || certificate?.length > 0 || experience?.length > 0) {
          return true
        }
        if (this.props?.match?.params?.id) {
          const { componentForm } = nextProps;
          if (componentForm) {
            if (Object.keys(componentForm).length > 0) {

              let _keys = Object.keys(componentForm);
              _keys.map((val) => {
                if (formComponentTypes.ADD_ADDRESS === componentForm[val].element) {
                  currentState.address = componentForm[val].value;
                  currentState.isAddressRequired = componentForm[val].required;
                } else if (
                  formComponentTypes.ADD_PHONE_NUMBER === componentForm[val].element
                ) {
                  currentState.phone = componentForm[val].value;
                } else if (
                  formComponentTypes.ADD_EMAIL === componentForm[val].element
                ) {
                  currentState.email = componentForm[val].value;
                }
                else if (
                  formComponentTypes.ADD_CERTIFICATION === componentForm[val].element
                ) {
                  currentState.certificate = componentForm[val].value;
                } else if (
                  formComponentTypes.ADD_EXPERIENCE === componentForm[val].element
                ) {
                  currentState.experience = componentForm[val].value;
                } else if (
                  formComponentTypes.ADD_ID_TYPE === componentForm[val].element
                ) {
                  currentState.idType = componentForm[val].value;
                  currentState.isIdTypeRequired = componentForm[val].required;
                } else if (
                  formComponentTypes.ADD_TAX_ID === componentForm[val].element
                ) {
                  currentState.tax = componentForm[val].value;
                }
                return true
              });

              this.setState({ ...currentState, isFirstTime: true });
            }
          }
        }
      }
      else {
        const { componentForm } = nextProps;
        if (componentForm) {
          if (Object.keys(componentForm).length > 0) {

            let _keys = Object.keys(componentForm);
            _keys.map((val) => {
              if (formComponentTypes.ADD_ADDRESS === componentForm[val].element) {
                currentState.isAddressRequired = componentForm[val].required;
              } else if (
                formComponentTypes.ADD_ID_TYPE === componentForm[val].element
              ) {
                currentState.isIdTypeRequired = componentForm[val].required;
              }
              // else if (
              //   formComponentTypes.ADD_PHONE_NUMBER === componentForm[val].element
              // ) {
              //   currentState.phone = componentForm[val].value;
              // } else if (
              //   formComponentTypes.ADD_EMAIL === componentForm[val].element
              // ) {
              //   currentState.email = componentForm[val].value;
              // }
              // else if (
              //   formComponentTypes.ADD_CERTIFICATION === componentForm[val].element
              // ) {
              //   currentState.certificate = componentForm[val].value;
              // }else if (
              //   formComponentTypes.ADD_EXPERIENCE === componentForm[val].element
              // ) {
              //   currentState.experience = componentForm[val].value;
              // }
              return true
            });

            this.setState({ ...currentState, isFirstTime: true });
          }
        }
      }

    }
  }

  render() {
    //
    console.log(this.props)
    return (
      <Grid container>
        <Grid item xs={12} sm={12}>
          <DynamicForm
            isEdit={this.props?.isEdit}
            components={this.props?.componentForm ?? {}}
            onSaveButtonClicked={this.onSaveCallback}
            // CERTIFICATE AND EXPERIENCE CALLBACK
            experience={this.experience()}
            certificate={this.certification()}
            experienceCertification={this.commonExperienceCertificationFunctions()}
            // DROPDOWN CALLBACK FUNCTION
            dropDownCallBacks={this.props.dropDownCallBacks}
            // FOR ADDRESS/EMAIL/PHONENUMBERS MULTIPLE
            addressEmailPhoneNumberfuntions={this.addressEmailPhoneNumberfuntions()}
            isResetState={this.props?.componentForm ?? {}}
            saveButtonLabel={this.props?.saveButtonLabel}
            loading={this.props?.loading}
            address= {this.state.address}
            email= {this.state.email}
            phone= {this.state.phone}
            idType= {this.state.idType}
            tax= {this.state.tax}
            cancelButtonId = {this.props?.cancelButtonId}
            saveButtonId={this.props?.saveButtonId}
          />
        </Grid>
      </Grid>
    );
  }
}

AddEditDynamicFromRenderer.propTypes = {
  component: PropTypes.object,
  actionToDispatch: PropTypes.object,
};

AddEditDynamicFromRenderer.defaultProps = {
  component: {},
};

const mapStateToProps = (state) => {
  return {
    category: state.masterSet?.categories,
    skills: state.masterSet?.skills,
    cert_organization: state.masterSet?.cert_organization,
    exp_organization: state.masterSet?.exp_organization,
    certificates: state.masterSet?.certificates,
    exp_roles: state.masterSet?.exp_roles,
    specialization: state.masterSet?.specialization,
    providerBasedOnCategory: state?.careProvider?.providerBasedOnCategory,
    service_coordinator: state?.dependency_api?.serviceCoordinatorByProvider,
    auth_data: state?.authState?.loginAuth,
    license: state?.masterModule?.getlicence,
    allDomains: state?.careProvider?.allDomain,
  };
};
export default connect(
  mapStateToProps,
  actions
)(
  withAlelrtAndDialog(withRouter(withStyles(style)(AddEditDynamicFromRenderer)))
);