import React from "react";
import { Redirect, Route } from "react-router-dom";
import { routes } from "./routes";

import { useSelector } from "react-redux";
import { access } from "./access";
import AppDrawer from "../App.drawer2";
import { AuthContext } from "../contexts";

const PrivateRoute = ({ children, ...rest }) => {
  const loginState = useSelector((state) => state?.authState?.loginAuth);

  const authContext = React.useContext(AuthContext); 
  const isAuthenticated = (router) => {
    const user_role = loginState?.data?.user_role?.[0];

    if (user_role) {
      const _ = access(user_role, router?.match?.path);
      if (_ >= 0) {
        
        if(!authContext?.auth?.refresh){
          
          return false
        }
        else{
        return true;

      }
      // return true;
    }
      return false;
    } else {
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={(_) =>
        isAuthenticated(_) ? (
          <AppDrawer>{children}</AppDrawer>
        ) : (
          <Redirect
            to={{
              pathname: routes.login,
              state: { from: _?.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
