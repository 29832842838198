import React from "react";

import MonitorCard from "../../../../components/clientMonitorDetailCard";
import { Grid, Typography, withStyles } from "@material-ui/core";
// import VitalCard from '../../../../components/vitalCard'
// import CardList from "../../../../components/userList";
import { ListCard } from "../../../../components/subscriptions";
// import { VitalCardIcons } from '../../../../components/vitalCard/vitalDetail'
import { routes } from "../../../../router/routes";
// import SectionName from '../../../../components/sectionNameBlock'
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { alertProps } from "../../../../utils";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { NotFoundSection } from "../../../../components";
import { commonStyle } from "../../style";
import { withRouter } from "react-router-dom";
import AssignCoordinatorDrawer from "../../subscriptions/AssignCoordinator";

const styles = (theme) => ({
  sectionText: {
    marginTop: 25,
    marginBottom: 10,
  },
  ...commonStyle,
});

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // dataMapper = (data) => {
  //     return data?.subscriptions?.map(item => {
  //         const returnData = {
  //             name: item.subscriptionName
  //         }
  //         const list = []
  //         list.push(returnData)

  //         return returnData
  //     })
  // }

  handleDeactivateSubscription = (subscription) => {
    const Oldstatus = subscription?.active;
    let status = "Activate";
    if (Oldstatus) {
      status = "Deactivate";
    }
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body:
        "Do you want to " +
        status +
        " Subscription: " +
        subscription.subscriptionName,
      positiveBtn: status,
      negativeBtn: "Cancel",
      onOk: () => this.deactiveSubscription(subscription),
    });
  };

  navigateToView = (id) => {
    this.props.history.push(routes.monitor_subscription_detail_parent + id);
  };

  deactiveSubscription = (subscription) => {
    this.props.dialog.setDialog({ open: false });

    this.props.UPDATE_SUBSCRIPTION_STATUS({
      subscriptionId: subscription.id,
      status: !subscription.active,
      clientSalutation: this.props.careGiverClientInfo?.data[0]?.salutationName,
      subscriptionType: subscription?.subscriptionType,
      clientEmail: this.props.careGiverClientInfo?.data[0].email,
      subscriptionName: subscription?.subscriptionName,
      clientName: this.props.careGiverClientInfo?.data[0].name,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Deactivate/Activate Subscription
    const { status_update } = nextProps;
    const old_status_update = this.props.status_update;

    //Checking for Deactivate/Activate subscription
    if (old_status_update?.loading !== status_update?.loading) {
      if (!status_update.loading && !status_update.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Subscription Status updated successfully!",
          severity: alertProps.severity.success,
        });
        this.props.getClientDetail(true);
      } else if (status_update.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to update subscription status",
          severity: alertProps.severity.error,
        });
      }
    }
    return true;
  }

  assignCoordinatorDrawer = (partnerId, subscriptionId) => {
    console.log(subscriptionId);
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <AssignCoordinatorDrawer
          partnerId={partnerId}
          subscriptionId={subscriptionId}
          // coordinatorId={
          //   this.props.sub_details?.data?.service_coord?.[0]?.value
          //     ? this.props.sub_details?.data?.service_coord?.[0]?.value
          //     : ""
          // }
          assigncoordinator={this.assignCoordinator}
          onCancel={this.closeDrawer}
        />
      ),
      onclose: this.closeDrawer,
    });
  };

  assignCoordinator = async (error, data, url, subscriptionId) => {
    await this.props.UPDATE_SUBSCRIPTION({
      subscriptionId: subscriptionId,
      coordinatorId: data?.coordinator?.value,
    });
    this.closeDrawer();
    this.props.getClientDetail(true);
    // this.getClientDetail();
  };

  closeDrawer = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  /*  navigateToView = (id) => {
        this.props.history.push(routes.manage_package + "/view/" + id);
    }; */

  render() {
    let { classes, data } = this.props;
    data = data[0];
    // console.log(data)
    // const graphs = [VitalCardIcons.miniGraphOne, VitalCardIcons.miniGraphTwo, VitalCardIcons.miniGraphThree, VitalCardIcons.miniGraphFour]

    return (
      <Grid container style={{ padding: "25px" }}>
        <Grid item xs={12}>
          <MonitorCard data={data} />
        </Grid>
        {/* <SectionName title={'Recent Vital Signs'} actionText={'VIEW HISTORY'} variant={'h6'} hideViewBtn /> */}
        {/* <Grid item xs={12}>
                    <VitalCard footerNeeded={false} data={data} icons={graphs} />
                </Grid> */}
        <Typography
          variant="h6"
          className={`${classes.sectionText} ${classes.mediumFont}`}
        >
          Subscriptions
        </Typography>
        {!data?.subscriptions?.length && (
          <Grid item xs={12}>
            <NotFoundSection
              message="The client doesn't have any subscriptions"
              top="20vh"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {data?.subscriptions?.map((_, key) => {
            return (
              <div key={key} style={{ cursor: "pointer", marginBottom: 12 }}>
                <ListCard
                  data={{
                    profile_pic: _.subscriptionProfile,
                    name: _.subscriptionName,
                    isactive: _.active,
                    category: _.category,
                    provider: _.provider?.name,
                    profiles: [
                      // {
                      //     name: _.serviceProffessional?.name,
                      //     profile_pic: _.serviceProffessional?.profile_pic,
                      //     role: "Service Professional",
                      // },
                      {
                        name: _.careCoordiantor?.name,
                        profile_pic: _.careCoordiantor?.profile_pic,
                        role: "Service Coordinator",
                      },
                    ],
                  }}
                  onClick={() => this.navigateToView(_.subscriptionId)}
                  handleClick={() => this.handleDeactivateSubscription(_)}
                  isDisableEditButton={true}
                  assignCoordinatorDrawer={() =>
                    this.assignCoordinatorDrawer(
                      _.provider?.value,
                      _?.subscriptionId
                    )
                  }
                />
              </div>
            );
          })}
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  status_update: state?.client_subscription_mapping?.status_update,
  careGiverClientInfo: state.careGiverClient?.careGiverClientInfo,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withStyles(styles)(withAlelrtAndDialog(Details))));
