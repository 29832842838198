import React from 'react'
import {
    Grid, Typography,
    Divider,
    withStyles
} from '@material-ui/core'
import withAlelrtAndDialog from '../../HOCs/withAlelrtAndDialog';
import { formatDate } from '../../utils/common';

const style = ((theme) => ({
    gutter: {
        padding: "16px 16px"
    },
    customListWrapper: {
        padding: "4px 16px 4px 16px"
    }
}))

class ViewAllTax extends React.Component {
    render() {
        const { classes, items } = this.props
        return (
            <div>
                <Grid container>
                    <Grid item xs={12} className={classes.gutter}>
                        <Typography variant={"h6"}>{"Tax Detail"}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider light />
                    </Grid>

                    {items?.map((item, index) => {
                        return (
                            <Grid item xs={12} >
                                <div className={classes.customListWrapper} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ flex: 1 }}>
                                        <Typography variant={"body1"} >{item?.taxRegistrationNumber ? item?.taxRegistrationNumber : "NA"}</Typography>
                                        <Typography variant={"body2"} color={"textSecondary"} gutterBottom >{item?.label ? item?.label : null}</Typography>
                                        <div style={{ display: "flex", gap: 8 }}>
                                            <div>
                                                <Typography variant={"body2"}>Effective Date</Typography>
                                                <Typography variant={"body2"} color={"textSecondary"} >{item?.effective ? formatDate(item?.effective) : "NA"}</Typography>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Divider light orientation="vertical" style={{ maxHeight: 24 }} /></div>
                                            <div>
                                                <Typography variant={"body2"}>Expiry Date</Typography>
                                                <Typography variant={"body2"} color={"textSecondary"} >{item?.expiry ? formatDate(item?.expiry) : "NA"}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Divider light />
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        )
    }
}

export default withAlelrtAndDialog(withStyles(style)(ViewAllTax));
