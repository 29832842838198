import React from "react";
import {
  Button,
  Divider,
  Grid,
  Typography,
  // FormControl,
  // Select,
  // MenuItem
  Select,
  MenuItem,
} from "@material-ui/core";
import OperationalHours from "../../../components/managedAvailability/operationalHours";
import NonOperationalDays from "../../../components/nonOperationalDays";
import { withStyles, withTheme } from "@material-ui/core/styles";
import ExceptionAccordion from "../../../components/managedAvailability/exceptions";

import moment from "moment";
// import { v4 as uuidv4 } from "uuid";

// Routes and RouteFunction Imports
import { withRouter } from "react-router-dom";
import { routes } from "../../../router/routes";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { alertProps, options } from "../../../utils";
import withAlelrtAndDialog from "../../../HOCs/withAlelrtAndDialog";
import { LoadingSection, InternatlServerError } from "../../../components";

// Checkbox
// NOTE: Commented Temporarily on November 10
// const CustomCheckbox = withStyles((theme) => ({
//     root: {
//         color: theme.palette.ternary.main,
//         '&$checked': {
//             color: theme.palette.ternary.dark,
//         },
//     },
// }))((props) => <Checkbox color="default" {...props} />);

const style = (theme) => ({
  root: {
    padding: 20,
    [theme.breakpoints.down("xs")]: {
      padding: "20px 8px",
    },
  },
  formControl: {
    background: "white",
    width: "100%",
  },
  customSelect: {
    "&:focus": {
      background: "white",
    },
  },
  // resizeSelect: {
  //     padding: "9.5px 14px"
  // },
  accordions: {
    marginTop: 20,
    background: "white",
    borderRadius: 8,
  },
  variant: {
    width: 150,
    height: 43,
    overflow: 'hidden',
},
});

class ManageAvailability extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isCurrentWorkingHour: true
      actionError: false,

      // Holiday States
      selectedMonth: parseInt(moment(Date.now()).format("M")),
      selectedYear: parseInt(moment(Date.now()).format("YYYY")),
      variant: "Slot",
    };
  }
  // Lifecycle Method handlers
  componentDidMount() {
    this.getOperationalExceptionData();
  }

  getOperationalExceptionData = () => {
    try {
      this.props.GET_OPERATIONAL_HOURS({
        providerId: this.props?.authdata?.user_id,
        isExceptionNeeded: true,
      });
      this.setState({
        actionError: false,
      });
    } catch (err) {
      this.setState({
        actionError: true,
      });
    }
  };

  shouldComponentUpdate(nextProps) {
    // Delete Exception
    const { deleteException } = nextProps;
    const oldDeleteException = this.props.deleteException;
    if (oldDeleteException?.loading !== deleteException?.loading) {
      if (!deleteException?.loading && !deleteException?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Exception deleted successfully!",
          severity: alertProps.severity.success,
        });
        this.getOperationalExceptionData();
        // this.props.history.push(routes.manage_availability);
      } else if (deleteException?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: deleteException?.message
            ? deleteException?.message
            : "Failed to delete exception!",
          severity: alertProps.severity.error,
        });
      }
    }

    return true;
  }

  // Handlers
  onMonthChange = (month) => {
    this.setState({
      selectedMonth: month,
    });
  };

  onPreviousYearClickHandler = () => {
    console.log("Previous");
    this.setState((prevState, prevProps) => ({
      selectedYear: prevState.selectedYear - 1,
    }));
  };

  onNextYearClickHandler = () => {
    console.log("Next");
    this.setState((prevState, prevProps) => ({
      selectedYear: prevState.selectedYear + 1,
    }));
  };

  isCurrentWorkingHourChange = () => {
    this.setState((prevState) => ({
      isCurrentWorkingHour: !prevState.isCurrentWorkingHour,
    }));
  };

  handleExceptionEdit = (event, exceptionId) => {
    event.stopPropagation();
    this.props.history.push(routes.edit_exception_main + exceptionId);
  };

  handleExceptionDelete = (event, exceptionId) => {
    event.stopPropagation();
    this.props.DELETE_EXCEPTION({ exceptionId: exceptionId });
  };

  getOpDays = (operationalHours) => {
    if (operationalHours?.length > 0) {
      return operationalHours?.map((x) => x?.days)?.flat();
    }
  };

  getExceptionList = () => {
    let { data: opData } = this.props?.getOperationalHours;
    let { exceptions } = opData;
    let myException = [];
    if (Array.isArray(exceptions) && exceptions?.length > 0) {
      myException = Array.from(new Array(exceptions?.length));

      let exceptionList = exceptions?.map((raw) => ({
        exception: raw?.exceptions,
        id: raw?.id,
      }));

      myException = exceptionList?.map((ex, index) => {
        
        return {
          days: ex?.exception?.available247
            ? options.daysOfWeek.map((x) => x.value)
            : this.getOpDays(ex?.exception?.operationalHours),
          based_on: "Individuals",
          data: {
            service_professional: {
              label: "SERVICE PROFESSIONAL",
              value: ex?.exception?.personas?.data?.map(
                (persona) => persona?.label
              ),
            },
          },
          id: ex?.id,
          operationalHours:ex?.exception?.operationalHours
        }
      })
    }
    return myException?.filter((x) => x);
  };
  onVariantChange = (e) => {
    this.setState({
      variant: e.target.value,
    }, () => {
      this.getOperationalExceptionData()
    });
  };

  render() {
    const { classes } = this.props;
    // const { availabilityNumber, isCurrentWorkingHour } = this.state;

    // const exceptionList = [
    //   {
    //     days: ["Monday", "Tuesday", "Wednesday"],
    //     based_on: "Individuals",
    //     data: {
    //       service_coordinator: {
    //         label: "SERVICE COORDINATOR",
    //         value: [
    //           "Sarah Najwa",
    //           "Mohamed Javid",
    //           "Adam John",
    //           "Irdina Maryam",
    //           "Zikri Ahammed"
    //         ]
    //       },
    //       service_professional: {
    //         label: "SERVICE PROFESSIONAL",
    //         value: [
    //           "Sarah Najwa",
    //           "Mohamed Javid",
    //           "Adam John",
    //           "Irdina Maryam",
    //           "Zikri Ahammed",
    //           "Sarah Najwa",
    //           "Mohamed Javid",
    //           "Adam John"
    //         ]
    //       },
    //       category: {
    //         label: "CATEGORY",
    //         value: ["Health Care", "Physio"]
    //       },
    //       time_slot: {
    //         label: "TIME SLOT",
    //         value: ["9 AM - 12 PM (IST)", "1 PM - 6 PM (IST)"]
    //       }
    //     }
    //   },
    //   {
    //     days: ["Thursday", "Friday"],
    //     based_on: "Category",
    //     data: {
    //       category: {
    //         label: "CATEGORY",
    //         value: ["Health Care", "Physio"]
    //       },
    //       time_slot: {
    //         label: "TIME SLOT",
    //         value: ["9 AM - 12 PM (IST)", "1 PM - 6 PM (IST)"]
    //       }
    //     }
    //   }
    // ];

    // Network call destructors
    let { data: opData, loading, error } = this.props?.getOperationalHours;

    let opHours = opData?.operationalHours?.operationalHours?.operationalHours;
    let opAvailable247 =
      opData?.operationalHours?.operationalHours?.available247;

    let exceptions = opData?.exceptions;

    // let data = {
    //   // available247: true,
    //   available247: false,
    //   // operationalHours: [],
    //   operationalHours: [
    //     {
    //       isAllDayChecked: false,
    //       days: ["monday", "tuesday"],
    //       timeSlot: ["2021-06-18T03:30:00.000Z", "2021-06-18T10:30:00.000Z"],
    //       timeZone: "MYT"
    //     },
    //     {
    //       isAllDayChecked: false,
    //       days: ["wednesday", "thursday"],
    //       timeSlot: ["2021-06-18T05:30:00.000Z", "2021-06-18T10:30:00.000Z"],
    //       timeZone: "IST"
    //     }
    //   ],
    //   holidays: {
    //     2021: {
    //       1: [
    //         {
    //           id: uuidv4(),
    //           title: "Deepavali",
    //           occurs_on: new Date(`18 January 2021`)
    //         },
    //         {
    //           id: uuidv4(),
    //           title: "Deepavali2",
    //           occurs_on: new Date(`18 January 2021`)
    //         },
    //         {
    //           id: uuidv4(),
    //           title: "Deepavali3",
    //           occurs_on: new Date(`19 January 2021`)
    //         },
    //         {
    //           id: uuidv4(),
    //           title: "Deepavali4",
    //           occurs_on: new Date(`20 January 2021`)
    //         },
    //         {
    //           id: uuidv4(),
    //           title: "Deepavali5",
    //           occurs_on: new Date(`21 January 2021`)
    //         },
    //         {
    //           id: uuidv4(),
    //           title: "Deepavali6",
    //           occurs_on: new Date(`22 January 2021`)
    //         }
    //       ],
    //       2: [
    //         {
    //           id: uuidv4(),
    //           title: "Deepavali",
    //           occurs_on: new Date(`15 February 2021`)
    //         }
    //       ],
    //       4: [
    //         {
    //           id: uuidv4(),
    //           title: "Deepavali",
    //           occurs_on: new Date(`10 April 2021`)
    //         }
    //       ]
    //     },
    //     2022: {
    //       1: [
    //         {
    //           id: uuidv4(),
    //           title: "Deepavali",
    //           occurs_on: new Date(`18 January 2022`)
    //         }
    //       ],
    //       2: [
    //         {
    //           id: uuidv4(),
    //           title: "Deepavali",
    //           occurs_on: new Date(`15 February 2022`)
    //         }
    //       ],
    //       4: [
    //         {
    //           id: uuidv4(),
    //           title: "Deepavali",
    //           occurs_on: new Date(`10 April 2022`)
    //         }
    //       ]
    //     }
    //   }
    // };

    return (
      <div className={classes.root}>
        {(this.state.actionError || error) && <InternatlServerError />}
        {loading && !error && (
          <LoadingSection message="Fetching provider availability..." />
        )}
        {!error && !this.state.actionError && !loading && opData && (
          <>
            {!opData?.isProviderAvailable &&
              (opAvailable247 || opHours?.length !== 0) && (
                <>
                  {/* OPERATIONAL HOURS */}
                  {/* Header */}
                  <div
                    style={{
                      padding: 20,
                      background: "white",
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} sm>
                        <Typography variant="h6">Availability</Typography>
                        <Typography variant="body2" color="textSecondary">
                          Last Updated on{" "}
                          {moment(opData?.operationalHours?.updatedAt)?.format(
                            "MMM DD, YYYY"
                          )}
                        </Typography>
                      </Grid>
                      {/* Commented View History Button - Future implementations may occur */}
                      {/* <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      this.props.history.push(routes.view_availabiliy_history)
                    }
                    style={{ marginRight: 24, width: 160 }}
                  >
                    view history
                  </Button>
                </Grid> */}
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            this.props.history.push(
                              routes.edit_availability_main +
                                this.props?.authdata?.user_id
                            )
                          }
                          style={{ width: 160 }}
                        >
                          edit Availability
                        </Button>
                      </Grid>
                    </Grid>
                  </div>

                  <Divider light />

                  <div
                    style={{
                      padding: 20,
                      background: "white",
                      borderBottomLeftRadius: 8,
                      borderBottomRightRadius: 8,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">Operational Hours</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        {opAvailable247 && (
                          <OperationalHours available247={opAvailable247} />
                        )}
                        {!opAvailable247 &&
                          Array.isArray(opHours) &&
                          opHours?.length > 0 && (
                            <OperationalHours
                              operationalHours={opHours ?? []}
                            />
                          )}
                        {!(Array.isArray(opHours) && opHours?.length > 0) &&
                          !opData?.operationalHours?.operationalHours
                            ?.available247 && (
                            <Typography variant="body1" color={"textSecondary"}>
                              Provider does not have any operational hours
                            </Typography>
                          )}
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 8 }}>
                        <Typography variant="h6">Holidays</Typography>
                      </Grid>

                      {/* Added margin bottom temporarily on November 10 */}
                      <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <NonOperationalDays
                          addHolidayClickHandler={this.addHolidayClickHandler}
                          handleChange={this.handleNonOperationalDays}
                          hideAddHolidayClickHandler={true}
                          selectedMonth={this.state.selectedMonth}
                          selectedYear={this.state.selectedYear}
                          holidays={opData?.nonOperationalHours}
                          onMonthChange={this.onMonthChange}
                          onPreviousYearClickHandler={
                            this.onPreviousYearClickHandler
                          }
                          onNextYearClickHandler={this.onNextYearClickHandler}
                          hideMoreActions
                        />
                      </Grid>
                    </Grid>
                  </div>
                </>
              )}

            {/* New Availability button */}
            {/* NOTE: Commented Temporarily on November 10 */}
            {opData?.isProviderAvailable && (
              <>
                <Grid
                  container
                  style={{ marginBottom: 20 }}
                  alignItems="center"
                >
                  <Grid item xs></Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() =>
                        this.props.history.push(routes.set_availability)
                      }
                    >
                      + SET NEW AVAILABILITY
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}

            {/* EXCEPTION */}
            {!opData?.isProviderAvailable && (
              <>
                <div style={{ padding: "20px 0px" }}>
                  <Grid container style={{ marginTop: 8, alignItems: "center" }}>
                    <Grid item xs>
                      <Typography variant="h6">Exceptions</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2} style={{alignItems: "center"}}>
                        <Grid item>
                            {/* <Typography variant="body1">View as: </Typography> */}
                            <Select
                              value={this.state.variant}
                              variant="outlined"
                              onChange={this.onVariantChange}
                              className={classes.variant}
                              color="secondary"
                            >
                              <MenuItem value={"Slot"}>Slots</MenuItem>
                              <MenuItem value={"Individual"}>
                                Individuals
                              </MenuItem>
                            </Select>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              this.props.history.push(routes.add_exception)
                            }
                          >
                            + Add Exceptions
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>

                {exceptions?.length !== 0 && (
                  <Grid container>
                    {/* Map the different exception accordion here */}
                    {/* TODO: While mapping apply class ```accordions``` to the grid items except the first one */}
                    {this.getExceptionList().map((exception, index) => (
                      <Grid
                        item
                        container
                        className={index !== 0 ? classes.accordions : null}
                      >
                        <Grid container item xs={12}>
                          <Grid item xs={12}>
                            <ExceptionAccordion
                              exceptionId={exception?.id}
                              exceptionData={exception}
                              handleEdit={this.handleExceptionEdit}
                              handleDelete={this.handleExceptionDelete}
                              withCustomBoxShadow={true}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authdata: state?.authState?.loginAuth?.data,
  getOperationalHours: state?.operational_hours?.get_operational_hours,
  deleteException: state?.exception?.delete_exception,
});

export default connect(
  mapStateToProps,
  actions
)(
  withAlelrtAndDialog(
    withStyles(style)(withRouter(withTheme(ManageAvailability)))
  )
);
