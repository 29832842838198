import React from 'react'
import AddEditProductManagement from '../../../components/addEditForm'

// masterData
import { formComponentTypes } from '../../../utils'
import withAlelrtAndDialog from '../../../HOCs/withAlelrtAndDialog';
import { withRouter } from 'react-router-dom';
// import { componentData } from './utils'

class EditMasterData extends React.Component {


  //Generate multiple field json
  // getFieldObjects = (fieldLabelArray, valueLabelsArray) => {
  //   let field = {}
  //   fieldLabelArray.map((fieldLabel,index) => {
  //       field.[`masterData${index !== 0 ? index + 1 : ""}`] = {
  //         key: fieldLabel,
  //         type: formComponentTypes.INPUT_BOX,
  //         withoutUppercase: true,
  //         breakPoints: {
  //           xs: 12,
  //           sm: 12,
  //           md: 12,
  //           lg: 12,
  //           xl: 12,
  //         },
  //         label: this.props?.fieldLabel?.toUpperCase(),
  //         //Here there is no api call to get the edit info, instead we are using the table data sent from the table component.
  //         //For instance, data will be {label: "Lab Supervisor", value: 1005}
  //         value: editData?.[valueLabelsArray[index]],
  //         componentProps: {
  //           type: "text",
  //           multiline: false,
  //           placeholder: "",
  //         },
  //         isMandatory: true
  //       }
  //     return fieldLabel
  //   })
  //   return field
  // }

  render() {
    const { data: editData } = this.props;
    const { loading: editLoading } = this.props?.updateState;
    // const currentCompData = componentData[this.props?.selected]
    const { valueLabels} = this.props
    
    // fieldLabel
    // const newComponents = {
    //   section_1: {
    //     label: `Edit`,
    //     labelBreakPoints: {
    //       xs: 12,
    //       sm: 12,
    //       md: 12,
    //       lg: 12,
    //       xl: 12,
    //     },
    //     fields: this.getFieldObjects(fieldLabel, valueLabels)
    //   }
    // }


    const components = {
      section_1: {
        label: `Edit`,
        labelBreakPoints: {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12,
          xl: 12,
        },
        fields: {
          masterData: {
            key: valueLabels?.[0],
            type: formComponentTypes.INPUT_BOX,
            id: "EditLabel1",
            withoutUppercase: true,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: this.props?.fieldLabel?.[0]?.toUpperCase(),
            //Here there is no api call to get the edit info, instead we are using the table data sent from the table component.
            //For instance, data will be {label: "Lab Supervisor", value: 1005}
            value: editData?.[valueLabels?.[0]],
            componentProps: {
              type: "text",
              multiline: false,
              placeholder: "",
            },
            isMandatory: true
          },
          masterData2: {
            key: valueLabels?.[1],
            type: formComponentTypes.INPUT_BOX,
            limit: 5,
            withoutUppercase: true,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: this.props?.fieldLabel[1]?.toUpperCase(),
            //Here there is no api call to get the edit info, instead we are using the table data sent from the table component.
            //For instance, data will be {label: "Lab Supervisor", value: 1005}
            value: editData?.[valueLabels?.[1]],
            componentProps: {
              type: "number",
              multiline: false,
              placeholder: "",
            },
            isMandatory: true
          }
        }
      }
    }
    if (valueLabels?.length <2) {
      
      delete components.section_1.fields.masterData2
    }
    return (
      <>
        <AddEditProductManagement
          // components={ this.props?.selected === 8 ? countryComponent : components}
          components={ components }
          onSaveButtonClicked={(isError, data, url) => this.props.onSave(isError, data, url, editData)}
          cancelForm={this.props.onCancelButtonClicked}
          loading={editLoading}
          saveButtonLabel={editLoading ? "Updating..." : "Update"}
          saveButtonStyles={{ marginTop: 16, marginBottom: 16, marginRight: 22, marginLeft: 16 }}
          cancelButtonStyles={{ marginRight: 'unset' }}
          saveButtonId={`EditDataSaveButton`}
          cancelButtonId={`EditDataCancelButton`}
        />
      </>
    )
  }



}

export default withAlelrtAndDialog(withRouter(EditMasterData))