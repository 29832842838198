import React from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: "none",
    stroke: props.color || theme.palette.primary.main,
    "stroke-linejoin": "round",
    "stroke-linecap": "round",
    "stroke-miterlimit": "10",
    "stroke-width": "2px",
  }),
  cls4: (props) => ({
    fill: "none",
    stroke: props.color || theme.palette.primary.main,
    "stroke-miterlimit": "10",
    "stroke-width": "2px"
  }),
  prefix__cls: (props) => ({
    fill: "none",
    stroke: props.color || theme.palette.primary.main,
    "stroke-linejoin": "round",
    "stroke-linecap": "round",
    "stroke-miterlimit": "10",
    "stroke-width": "2px",
  })
}))

export const ClientBloodPressureIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon fontSize={"small"}
    {...props}
    style={props.style}>
      <g
        id="prefix__Blood_Pressure"
        data-name="Blood Pressure"
        transform="translate(-3 -2)"
      >
        <path
          id="prefix__Path_619"
          className={classes.cls1}
          d="M12 3a7 7 0 107 7 7 7 0 00-7-7z"
          data-name="Path 619"
        />
        <path
          id="prefix__Path_620"
          d="M3 21h18"
          className={classes.prefix__cls}
          data-name="Path 620"
        />
        <path
          id="prefix__Path_621"
          className={classes.cls4}
          d="M12 17.1V21"
          data-name="Path 621"
        />
        <path
          id="prefix__Path_622"
          d="M15 7l-3.7 3.7"
          className={classes.prefix__cls}
          data-name="Path 622"
        />
      </g>
    </SvgIcon>
  )
}

ClientBloodPressureIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};
