import * as React from "react"

export const DownloadIcon = (props) => {
    let width = props.width ? props.width : "12.564";
    let height = props.height ? props.height : "17.396";
    let color = props.color ? props.color : "#31b198";
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12.564 17.396" {...props}>
            <path id="icons8-download_1_" data-name="icons8-download (1)" d="M12.584,4V16.005L8.9,12.177l-1,1.042,4.886,5.074.5.5.5-.5,4.886-5.074-1-1.042L13.98,16.005V4ZM7,19.947V21.4H19.564v-1.45Z" transform="translate(-7 -4)" fill={color} />
        </svg>
    );
};