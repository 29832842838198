import React from "react";
import { TextField } from "../inputs";
import { Button, Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import { SimpleList } from "../simpleList";
import { makeStyles } from "@material-ui/styles";

export const ButtonWithInputField = (props) => {
  const {
    arrOfValues,
    error,
    errorMessage,
    buttonValue,
    handleButtonClick,
    style,
    title,
    removeListFunc,
    componentState,
    id
  } = props;
  const [state, setState] = React.useState("");

  //   ADDING DIVIDER
  const addDividerAtTheEnd = (offerList, key, classes) => {
    return offerList.length - 1 === key ? (
      ""
    ) : (
      <Divider className={classes.dividerBackground} />
    );
  };

  //   onEnter

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (document.getElementById(id?.button_id)) {
        document.getElementById(id?.button_id).click();
      }
    }
  };
  //   STYLES

  const useStyles = makeStyles((theme) => ({
    dividerWidth: {
      width: "100%",
      marginTop: 10,
      marginBottom: 10,
    },
    listingOfferings: {
      marginTop: 20,
    },
    addButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.ternary.main,
      "&:hover": {
        backgroundColor: theme.palette.ternary.dark,
      },
    }
  }));
  const classes = useStyles();
  return (
    <>
      <TextField
        type={"text"}
        rowsMax={1}
        multiline={false}
        title={title}
        id={id?.text_id}
        placeholder=""
        value={state}
        onKeyUp={handleKeyPress}
        handleOnChange={(e) => {
          setState(e.target.value);
        }}
        error={error}
        errorText={errorMessage}
        style={style}
        {...props}
      />
      <Button
        variant="contained"
        // color="primary"
        // id={title}
        id={id?.button_id}
        style={{margin:'5px 0 10px  0'}}
        className={classes.addButton}
        onClick={() => {
          handleButtonClick(state);
          setState("");
        }}

      >
        {buttonValue}
      </Button>

      {(arrOfValues?.length > 0 ? arrOfValues : [] )?.map((item, key) => {
        return (
          <div>
            <div key={key}>
              <SimpleList
                text={item}
                key={key}
                handleRemove={() => {
                  // removeListFunc(arrOfValues, item, componentState);
                  removeListFunc(arrOfValues, key, componentState, true);
                }}
              />
            </div>
            {addDividerAtTheEnd(arrOfValues, key, classes)}
          </div>
        );
      })}
    </>
  );
};

// Specifies the required props for the component:
ButtonWithInputField.propTypes = {
  value: PropTypes.string.isRequired,
  buttonValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  style: PropTypes.object,
};
