import React from "react";
import {
  Button,
  Grid,
  Typography,
  Container,
  TextField,
  IconButton,
  FormControlLabel,
  Checkbox,
  Hidden,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { routes } from "../../router/routes";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./style";

// import { NavLink } from "react-router-dom";
// import Card from "@material-ui/core/Card";

// SubScreenComponents
import HeaderLogo from "../../components/login/headerLogo";
// import LeftPane from "../../components/login/LeftPane";
import LeftPane from "../login/leftPane";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { AuthContext } from "../../contexts";
import { alertProps, localStorageKeys, parseJwt } from "../../utils";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

// validation - utils

import {
  minCheck,
  minNumberAndSymbolCheck,
  charCaseCheck,
} from "../../utils/validations";

// Icons
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { errorMessage } from "../../utils/errorMessages";
import { isTrialAccountType } from "../../utils/common";
import { withAlertAndDialog } from "../../HOCs";

// import { AuthContext } from '../../contexts';
// const { LOGOUT_AUTH } = actions;

// const styles = (theme) => ({
//   wrapper: {
//     height: "100vh",
//     background: "white"
//   },
//   root: {
//     height: "100%"
//   },
//   hero: {
//     height: "85%"
//   },
//   rightPaneWrapper: {
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "flex-start",
//     alignItems: "center"
//   },
//   rightPane: {
//     width: "100%"
//   },
//   mainHeading: {
//     [theme.breakpoints.down("sm")]: {
//       marginTop: 4
//     },
//     // marginTop: 68,
//     fontSize: "1.5rem",
//     fontWeight: "bold"
//   },
//   subHeading: {
//     fontSize: "1rem",
//     color: theme.palette.secondary.main
//   },
//   passwordField: {
//     width: "100%",
//     marginTop: 32
//   },
//   //   passwordField: {
//   //     width: "100%",
//   //     marginTop: 24
//   //   },
//   errorMessage: {
//     color: `${theme.palette.error.main}`
//   },
//   resetButton: {
//     marginTop: 32,
//     padding: 8,
//     "&:hover": {
//       transition: "0.5s",
//       background: `${theme.palette.primary.main}`,
//       opacity: 0.9
//     }
//   },
//   validationListContainer: {
//     listStyle: "none",
//     textAlign: "left",
//     paddingLeft: 0
//   },
//   validationListItemStartIcon: {
//     display: "inline",
//     margin: "0px 3px"
//   },

//   // Custom Header Style
//   header: {
//     height: "10%",
//     // height: 44,
//     [theme.breakpoints.down("sm")]: {
//       height: 60,
//       textAlign: "center",
//       marginTop: 32
//     }
//   },
// });

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldpassword: "",
      password: "",
      confirmPassword: "",
      passwordVisibility: false,
      success: false,
      checkPasswordValidity: {
        minCharCheck: {
          desc: "Contains at least 8 characters",
          validity: null,
        },
        minNumberAndSymbolCheck: {
          desc: "Contains at least one number (0-9) and a symbol",
          validity: null,
        },
        charCaseCheck: {
          desc: "Contains both lower (a-z) and upper case letter (A-Z)",
          validity: null,
        },
        // nameEmailAsPasswordCheck: {
        //   desc: "does not contain your name or email address",
        //   validity: null
        // },
        // commonPasswordCheck: {
        //   desc: "is not commonly used or a previous password",
        //   validity: null
        // }
      },
      fieldsToValidate: ["password", "confirmPassword"],
      error: {},
      errorText: {},
      isError: false,
    };
  }

  async componentDidMount() {
    // console.log(this.props?.match?.params?.id)
    let role = await parseJwt(this.props?.match?.params?.id)?.role;

    this.setState({ role: role });
    // console.log(role)
  }

  // Handles validation

  // Handles input change
  handleChange = (e, inputField) => {
    if (!e.target.value?.includes(" ")) {
      this.setState({
        [inputField]: e.target.value,
      });
    }
    if (inputField === "password") {
      this.setState((prevState) => {
        // console.log(prevState)
        return {
          checkPasswordValidity: {
            minCharCheck: {
              ...prevState.checkPasswordValidity.minCharCheck,
              validity: minCheck(prevState.password), //call the validity function testing regex
            },
            minNumberAndSymbolCheck: {
              ...prevState.checkPasswordValidity.minNumberAndSymbolCheck,
              validity: minNumberAndSymbolCheck(prevState.password),
            },
            charCaseCheck: {
              ...prevState.checkPasswordValidity.charCaseCheck,
              validity: charCaseCheck(prevState.password),
            },
            // nameEmailAsPasswordCheck: {
            //   ...prevState.checkPasswordValidity.nameEmailAsPasswordCheck,
            //   validity: true
            // },
            // commonPasswordCheck: {
            //   ...prevState.checkPasswordValidity.commonPasswordCheck,
            //   validity: true
            // }
          },
        };
      });
    }
  };

  endAdornmentDisplay = () => {
    let checkPasswordValidity = this.state.checkPasswordValidity;
    if (
      (checkPasswordValidity.minCharCheck.validity ||
        checkPasswordValidity.minNumberAndSymbolCheck.validity ||
        checkPasswordValidity.charCaseCheck.validity) !==
      // || checkPasswordValidity.nameEmailAsPasswordCheck.validity ||
      // checkPasswordValidity.commonPasswordCheck.validity
      null
    ) {
      if (
        checkPasswordValidity.minCharCheck.validity &&
        checkPasswordValidity.minNumberAndSymbolCheck.validity &&
        checkPasswordValidity.charCaseCheck.validity
        // && checkPasswordValidity.nameEmailAsPasswordCheck.validity &&
        // checkPasswordValidity.commonPasswordCheck.validity
      ) {
        return (
          <>
            <DoneIcon htmlColor="#31b198" />
          </>
        );
      } else {
        return (
          <>
            <CloseIcon htmlColor="rgba(188, 45, 79, 1)" />
          </>
        );
      }
    } else {
      return "";
    }
  };

  passwordVisibilityHandler = () => {
    this.setState(
      (prevState) => {
        return {
          passwordVisibility: !prevState.passwordVisibility,
        };
      },
      () => {
        console.log(this.state.passwordVisibility);
      }
    );
  };

  handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      document.getElementById("resetButton").click();
    }
  };

  agreeTerms = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  handleValidation() {
    const { error, errorText, fieldsToValidate } = this.state;
    let isError = false;
    const validate = (field) => {
      if (!this.state[field]) {
        error[field] = true;
        errorText[field] = errorMessage.requiredMessage;
        isError = true;
      } else {
        if (field === "password") {
          const { checkPasswordValidity } = this.state;

          let isPasswordValid = true;

          Object.keys(checkPasswordValidity).map((key) => {
            isPasswordValid =
              isPasswordValid && checkPasswordValidity[key]?.validity;
            return key;
          });

          if (!isPasswordValid) {
            error[field] = true;
            errorText[field] = errorMessage.strongerPassword;
            isError = true;
          } else {
            error[field] = false;
          }
        }

        if (field === "confirmPassword") {
          if (this.state["password"] !== this.state[field]) {
            error[field] = true;
            errorText[field] = errorMessage.passwordMismatch;
            isError = true;
          } else {
            error[field] = false;
          }
        }
      }
    };

    fieldsToValidate.map((val) => {
      validate(val);
      return val;
    });

    this.setState({
      error: error,
      errorText: errorText,
      isError: isError,
    });

    return isError;
  }

  handleResetButton = () => {
    console.log(this.props?.match?.params?.id);

    // this.setState({success: true})

    if (!this.handleValidation()) {
      const query = this.useQuery();
      const emailSecret = query.get("emailSecretKey");
      const id = this.props.match.params?.id;
      //
      //console.log("emailId:", this.props.match)

      const payload = {
        user_id: id,
        oldpassword: "",
        password: this.state.password,
        token: this.props?.match?.params?.id,
        emailsecret: emailSecret,
      };

      // console.log(payload)

      this.props.RESET_AUTH(payload);
      // this.setState({success: true})
    } else {
      // window.alert("Failed")
      console.log("FAILED");
    }
  };

  logoutAndRefresh = () => {
    // const authContext = React.useContext(AuthContext);
    this.context.setAuth({ ...this.context.auth, refresh: false });
    // dispatch(LOGOUT_AUTH());
    this.props.LOGOUT_AUTH();
    localStorage.removeItem(localStorageKeys._mGdRe);
    this.props.history.push(routes.login);
  };

  useQuery = () => {
    //console.log(this.props.location)
    return new URLSearchParams(this.props.location.search);
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Add Supervisor
    const { resetPassword } = nextProps;
    const oldresetPassword = this.props.resetPassword;

    //Checking for Add Supervisor messages
    if (oldresetPassword?.loading !== resetPassword?.loading) {
      if (!resetPassword.loading && !resetPassword.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Password Changed successfully!",
          severity: alertProps.severity.success,
        });
        //Call logout
        console.log("INSIDE_EDGE");
        this.setState({ success: true });

        //------------------------------Temporary Disabled ----------------------------------------
        // this.logoutAndRefresh()
      } else if (resetPassword.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg:
            resetPassword?.message ??
            "Something went wrong! Unable to change password.",
          severity: alertProps.severity.error,
        });
      }
    }
    return true;
  }

  handleBackToSignin = () => {
    localStorage.clear();
    this.props.history.push("/");
  };

  render() {
    // const { username, password, passwordVisibility } = this.state;
    // const { classes } = this.props;

    // const { loading, error, message } = this.props?.loginState;
    const { classes } = this.props;
    const { password, confirmPassword, passwordVisibility } = this.state;
    const { checkPasswordValidity } = this.state;

    const { loading } = this.props?.resetPassword;

    const linkPlayStore =
      this.state?.role === "client"
        ? "https://play.google.com/store/apps/details?id=com.my_tanya_care"
        : "https://play.google.com/store/apps/details?id=com.tanya_care_network";
    const linkPlayStoreTrialEnviroment =
      this.state?.role === "client"
        ? "https://appdistribution.firebase.dev/i/8ccb875612e8547a"
        : "https://appdistribution.firebase.dev/i/4492bd1ceff57971";
    const linkAppleStore =
      this.state?.role === "client"
        ? " https://apps.apple.com/"
        : "https://apps.apple.com/us/app/tanya-care-network/id1569556424";
    const linkHuawei =
      this.state?.role === "client"
        ? "https://appgallery.huawei.com/#/app/C104504033"
        : "https://appgallery.huawei.com/#/app/C104504027";

    // const linkPlayStoreTrialEnviroment =
    //   "https://play.google.com/apps/testing/com.tanya_care_network";
    const linkAppleStoreTrialEnviroment =
      "https://testflight.apple.com/join/RJdknaDQ";

    return (
      <Container maxWidth="lg" className={classes.wrapper}>
        <div className={classes.root}>
          <Hidden smDown>
            <HeaderLogo />
          </Hidden>
          <Grid container alignItems={"stretch"} className={classes.hero}>
            <Hidden smDown>
              {/* Left pane illustration */}
              <LeftPane />
            </Hidden>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.rightPaneWrapper}
              style={{ margin: "auto" }}
            >
              {/* {this.state.success  */}
              {this.state.success ? (
                <>
                  <Grid
                    item
                    xs={12}
                    className={classes.formHeader}
                    // style={{ marginBottom: 25, marginTop: "4rem" }}
                  >
                    <CheckCircleOutlineIcon
                      htmlColor="rgb(90, 192, 172)"
                      classes={{ root: classes.svg_icon }}
                    />
                    <Typography
                      variant="h2"
                      component="h2"
                      className={classes.mainHeading}
                    >
                      Password Set Successfully
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.subHeading}
                    >
                      You have successfully set your login password <br /> for
                      your Tanya.Care Account.
                    </Typography>
                    {(this.state?.role === "client" ||
                      this.state?.role === "service_professional") && (
                      <>
                        <br />
                        <Typography
                          variant="body1"
                          component="p"
                          className={classes.subHeading}
                        >
                          Please click to download the apps from below.
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {this.state?.role !== "client" &&
                    this.state?.role !== "service_professional" ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          id="sendCodeButton"
                          // disabled={loading}
                          onClick={this.handleBackToSignin}
                          className={classes.sendCodeButton}
                          style={{ marginTop: 0 }}
                        >
                          Back to Sign In
                        </Button>
                      </>
                    ) : (
                      <>
                        {/* <img
                          src="https://fileupload.dev.ainqaplatform.in/mcarangov2/50944491"
                          alt="#"
                          width="210"
                        />
                        <img
                          src="https://fileupload.dev.ainqaplatform.in/mcarangov2/50965366"
                          style={{ padding: 15 }}
                          alt="#"
                          width="180"
                        /> */}
                        <a
                          href={
                            isTrialAccountType()
                              ? linkPlayStoreTrialEnviroment
                              : linkPlayStore
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#3498db",
                            textDecoration: "underline",
                          }}
                        >
                          <img
                            src="https://fileupload.dev.ainqaplatform.in/mcarangov2/41947054"
                            width={70}
                            alt="Logo"
                            align="center"
                            style={{
                              border: "none",
                              "-ms-interpolation-mode": "bicubic",
                              maxWidth: "100%",
                              pointerEvents: "auto",
                              padding: "0px 15px",
                            }}
                          />
                        </a>
                        {/* https://play.google.com/store/apps/details?id=com.tanya_care_network */}
                        {this.state?.role === "service_professional" && (
                          <a
                            href={
                              isTrialAccountType()
                                ? linkAppleStoreTrialEnviroment
                                : linkAppleStore
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#3498db",
                              textDecoration: "underline",
                            }}
                          >
                            <img
                              src="https://fileupload.dev.ainqaplatform.in/mcarangov2/41949402"
                              width={70}
                              alt="Logo"
                              align="center"
                              style={{
                                border: "none",
                                "-ms-interpolation-mode": "bicubic",
                                maxWidth: "100%",
                                pointerEvents: "auto",
                                padding: "0px 15px",
                              }}
                            />
                          </a>
                        )}
                        {!isTrialAccountType() && (
                          <a
                            href={linkHuawei}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#3498db",
                              textDecoration: "underline",
                            }}
                          >
                            <img
                              src="https://fileupload.dev.ainqaplatform.in/mcarangov2/41948144"
                              width={70}
                              alt="Logo"
                              align="center"
                              style={{
                                border: "none",
                                "-ms-interpolation-mode": "bicubic",
                                maxWidth: "100%",
                                pointerEvents: "auto",
                                padding: "0px 15px",
                              }}
                            />
                          </a>
                        )}
                      </>
                    )}
                  </Grid>

                  <Grid container item xs={12} justify="center">
                    <Grid item xs={12} sm={12} md={10} lg={10} xl={10}></Grid>
                  </Grid>
                </>
              ) : (
                <Grid container item xs={12} sm={12} md={10}>
                  <div className={classes.rightPane}>
                    <Hidden mdUp>
                      <HeaderLogo customHeaderStyle={classes.header} />
                    </Hidden>
                    <Typography
                      variant="h2"
                      component="h2"
                      align={"center"}
                      className={classes.mainHeading}
                    >
                      Welcome
                    </Typography>

                    <Typography
                      variant="body1"
                      component="p"
                      align={"center"}
                      className={classes.subHeading}
                    >
                      This is your first time here! Please set a password for
                      your account.
                    </Typography>

                    {/* Password Field */}
                    <TextField
                      variant="outlined"
                      label="New Password"
                      id={`NewPassword`}
                      type={passwordVisibility ? "text" : "password"}
                      autoComplete="off"
                      className={classes.passwordField}
                      InputProps={{
                        endAdornment: (
                          <>
                            {this.endAdornmentDisplay()}
                            <IconButton
                              id={`ViewPasswordButton`}
                              onClick={this.passwordVisibilityHandler}
                            >
                              {passwordVisibility ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </>
                        ),
                      }}
                      value={password}
                      onChange={(e) => this.handleChange(e, "password")}
                      onKeyUp={this.handleKeyPress}
                      style={{ width: "100%" }}
                      error={this.state.error["password"] ? true : false}
                      helperText={
                        this.state.error["password"]
                          ? this.state.errorText["password"]
                          : ""
                      }
                    />

                    {/* Validations Check ✓ */}
                    <ul className={classes.validationListContainer}>
                      {Object.keys(checkPasswordValidity).map(
                        (objectKey, index) => {
                          return (
                            <li
                              key={index}
                              style={{
                                color: `${
                                  checkPasswordValidity[objectKey].validity
                                    ? "#31b198"
                                    : "#9297a5"
                                }`,
                                margin: "4px 0px",
                              }}
                            >
                              <div
                                className={classes.validationListItemStartIcon}
                              >
                                {checkPasswordValidity[objectKey].validity ? (
                                  <>&#10003;</>
                                ) : (
                                  <>&bull;</>
                                )}{" "}
                              </div>
                              {checkPasswordValidity[objectKey].desc}
                            </li>
                          );
                        }
                      )}
                    </ul>

                    {/* Confirm Password Field */}
                    <TextField
                      variant="outlined"
                      label="Confirm Password"
                      id={`ConfirmPassword`}
                      type={passwordVisibility ? "text" : "password"}
                      autoComplete="off"
                      value={confirmPassword}
                      onChange={(e) => this.handleChange(e, "confirmPassword")}
                      onKeyUp={this.handleKeyPress}
                      style={{ width: "100%" }}
                      error={this.state.error["confirmPassword"] ? true : false}
                      helperText={
                        this.state.error["confirmPassword"]
                          ? this.state.errorText["confirmPassword"]
                          : ""
                      }
                    />

                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      justify="flex-start"
                      // className={classes.rememberMe}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="ConfirmtationForTermsOfUseandPrivacyPolicy"
                            checked={this.state.isChecked}
                            onChange={this.agreeTerms}
                            name="Remember Me"
                            color="secondary"
                          />
                        }
                        label={
                          <span>
                            I agree to the{" "}
                            {/* <NavLink to="/privacy_policy"> */}
                            <a
                              href="https://www.tanya.care/privacy-policy/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy Policy
                            </a>
                            {/* </NavLink> */} and{" "}
                            {/* <NavLink to="/terms_and_conditions"> */}
                            <a
                              href="https://www.tanya.care/terms-of-use/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {" "}
                              Terms of Use
                            </a>
                            {/* </NavLink> */}
                          </span>
                        }
                        style={{
                          fontSize: "0.80rem !important",
                          marginTop: "0.50rem",
                          marginLeft: "0.15rem",
                        }}
                      />
                    </Grid>

                    {this.state.isChecked ? (
                      <Button
                        variant="contained"
                        color="primary"
                        // id={'ChangePasswordDisabled'}
                        disableElevation
                        disabled={loading}
                        id="resetButton"
                        fullWidth
                        className={classes.resetButton}
                        onClick={this.handleResetButton}
                      >
                        {loading ? "Loading..." : "CREATE PASSWORD"}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        id="resetButton"
                        disabled
                        fullWidth
                        className={classes.resetButton}
                        onClick={this.handleResetButton}
                      >
                        CREATE PASSWORD
                      </Button>
                    )}
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ height: "5%" }}></Grid>
        </div>
      </Container>
    );
  }
}

ChangePassword.contextType = AuthContext;

const mapStateToProps = (state) => ({
  loginState: state?.authState?.loginAuth,
  resetPassword: state?.authState?.resetPasswordFromEmail,
});

export default connect(
  mapStateToProps,
  actions
)(
  withAlertAndDialog(
    withStyles(styles, { withTheme: true })(withRouter(ChangePassword))
  )
);
