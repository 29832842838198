import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(()=>({
  root:{
    '& .MuiDialogTitle-root':{
        display:'none'
    },
    '& .MuiDialogContent-root':{
        padding:'30px 25px'
    },
    '& .MuiPaper-root':{
        '& .MuiDialogContentText-root':{
            marginBottom:0
        },
        minWidth:'90%',
        //there is a reason to write insted of using theme
        '@media (min-width:600px)': { 
            minWidth:386,
            width:386,
        },
        borderRadius:10,
        padding:24,
       
    }
  }
}))

//This is a custom prompt component (get user confirmation react prompt)

export const CustomDialog = (props) =>{

    const classes = useStyles(props);

    const {
        open=false,
        onClose=null,
        children="",
        className={
            Dialog:''
        }
    } = props;

    return(
        <>
        {open &&
            <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                root:className?.Dialog ? className?.Dialog : classes.root
            }}
            >   
            <div>
                {children}
            </div>
            </Dialog>
        }
        </>
    )
}

CustomDialog.propTypes={
    open:PropTypes.bool,
    onClose:PropTypes.func,
    children:PropTypes.node,
    className:PropTypes.object
}