import React from 'react'
import { Chip, Grid, IconButton, withStyles } from '@material-ui/core'
import { filterTagType, productSortFieldOption } from '../../utils'
// productSortDirectionOption
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({

})

// Styled Chip
const StyledChip = withStyles({
  root: {
    backgroundColor: 'white',
    borderRadius: 4,
  },
  deleteIcon: {
    color: "#bc2d4f"
  },
})((props) => <Chip {...props} deleteIcon={<IconButton><StyledCloseIcon /></IconButton>} />)

// Styled Close Icon
const StyledCloseIcon = withStyles({
  fontSizeSmall: {
    fontSize: "1rem"
  }
})((props) => <CloseIcon fontSize="small" htmlColor={"#bc2d4f"} {...props} />)

class FilterTags extends React.Component {
  render() {
    const {
      category, products, provider, status, skills, specialization,
      professional, coordinator, services, packages,
      sortfield, gender, assigned_to, enquiry_type, role
    } = this.props.filterData
    
    // const { classes } = this.props
    return (
      <div>
        <Grid container spacing={1}>

          {/* CATEGORY */}
          {category?.map((cat, index) => {
            return (
              <Grid item>
                <StyledChip
                  label={`${filterTagType.Category[0]?.toUpperCase()}${filterTagType.Category.slice(1, filterTagType.Category?.length)} : ${cat.label}`}
                  onDelete={() => this.props.handleDelete(filterTagType.Category, index)}
                />
              </Grid>
            )
          })}

          {/* PRODUCTS */}
          {products?.map((pro, index) => {
            return (
              <Grid item>
                <StyledChip
                  label={`${filterTagType.Products[0]?.toUpperCase()}${filterTagType.Products.slice(1, filterTagType.Products?.length)} : ${pro.label}`}
                  onDelete={() => this.props.handleDelete(filterTagType.Products, index)}
                />
              </Grid>
            )
          })}

          {/* PROVIDER */}
          {provider?.map((pro, index) => {
            return (
              <Grid item>
                <StyledChip
                  label={`${filterTagType.Provider[0]?.toUpperCase()}${filterTagType.Provider.slice(1, filterTagType.Provider?.length)} : ${pro.label}`}
                  onDelete={() => this.props.handleDelete(filterTagType.Provider, index)}
                />
              </Grid>
            )
          })}

          {/* SKILLS */}
          {skills?.map((skill, index) => {
            return (
              <Grid item>
                <StyledChip
                  label={`${filterTagType.Skills[0]?.toUpperCase()}${filterTagType.Skills.slice(1, filterTagType.Skills?.length)} : ${skill.label}`}
                  onDelete={() => this.props.handleDelete(filterTagType.Skills, index)}
                />
              </Grid>
            )
          })}

          {/* SPECIALIZATION */}
          {specialization?.map((special, index) => {
            return (
              <Grid item>
                <StyledChip
                  label={`${filterTagType.Specialization[0]?.toUpperCase()}${filterTagType.Specialization.slice(1, filterTagType.Specialization?.length)} : ${special.label}`}
                  onDelete={() => this.props.handleDelete(filterTagType.Specialization, index)}
                />
              </Grid>
            )
          })}

          {/* PROFESSIONAL */}
          {professional?.map((pro, index) => {
            return (
              <Grid item>
                <StyledChip
                  label={`${filterTagType.Professional[0]?.toUpperCase()}${filterTagType.Professional.slice(1, filterTagType.Professional?.length)} : ${pro.label}`}
                  onDelete={() => this.props.handleDelete(filterTagType.Professional, index)}
                />
              </Grid>
            )
          })}

          {/* COORDINATOR */}
          {coordinator?.map((coord, index) => {
            return (
              <Grid item>
                <StyledChip
                  label={`${filterTagType.Coordinator[0]?.toUpperCase()}${filterTagType.Coordinator.slice(1, filterTagType.Coordinator?.length)} : ${coord.label}`}
                  onDelete={() => this.props.handleDelete(filterTagType.Coordinator, index)}
                />
              </Grid>
            )
          })}

          {/* SERVICES */}
          {services?.map((serv, index) => {
            return (
              <Grid item>
                <StyledChip
                  label={`${filterTagType.Services[0]?.toUpperCase()}${filterTagType.Services.slice(1, filterTagType.Services?.length)} : ${serv.label}`}
                  onDelete={() => this.props.handleDelete(filterTagType.Services, index)}
                />
              </Grid>
            )
          })}
          {assigned_to?.map((serv, index) => {
            return (
              <Grid item>
                <StyledChip
                  label={`${filterTagType.assigned_to[0]?.toUpperCase()}${filterTagType.assigned_to.slice(1, filterTagType.assigned_to?.length)} : ${serv.label}`}
                  onDelete={() => this.props.handleDelete(filterTagType.Assigned_to, index)}
                />
              </Grid>
            )
          })}
          
          {/* {(assigned_to && assigned_to.length > 0) &&
            <Grid item>
              <StyledChip
                label={`${filterTagType.assigned_to[0]?.toUpperCase()} ${filterTagType.assigned_to.slice(1, filterTagType.assigned_to?.length)} : ${assigned_to.map(va => va?.label).join(", ")}`}
                onDelete={() => this.props.handleDelete(filterTagType.Assigned_to)}
              />
            </Grid>
          } */}
          {enquiry_type?.map((serv, index) => {
            return (
              <Grid item>
                <StyledChip
                  label={`${filterTagType.enquiry_type[0]?.toUpperCase()}${filterTagType.enquiry_type.slice(1, filterTagType.enquiry_type?.length)} : ${serv.label}`}
                  onDelete={() => this.props.handleDelete(filterTagType.Enquiry_type, index)}
                />
              </Grid>
            )
          })}
          
          {/* {(enquiry_type && enquiry_type.length > 0) &&
            <Grid item>
              <StyledChip
                label={`${filterTagType.enquiry_type[0]?.toUpperCase()} ${filterTagType.enquiry_type.slice(1, filterTagType.enquiry_type?.length)} : ${enquiry_type.map(va => va?.label).join(", ")}`}
                onDelete={() => this.props.handleDelete(filterTagType.Enquiry_type)}
              />
            </Grid>
          } */}

          {/* PACKAGES */}
          {packages?.map((pack, index) => {
            return (
              <Grid item>
                <StyledChip
                  label={`${filterTagType.Packages[0]?.toUpperCase()}${filterTagType.Packages.slice(1, filterTagType.Packages?.length)} : ${pack.label}`}
                  onDelete={() => this.props.handleDelete(filterTagType.Packages, index)}
                />
              </Grid>
            )
          })}

          {/* SORT DIRECTION */}
          {/* {(sortDirec && sortDirec.value !== subHeaderSortOptionWithValue[2].value) &&
            <Grid item>
              <StyledChip
                label={`Sort Direction : ${sortDirec.label}`}
                onDelete={() => this.props.handleDelete(filterTagType.SortDirection)}
              />
            </Grid>
          } */}
          {/* {JSON.stringify(sortfield)} */}
          {/* {JSON.stringify(sortfield && sortfield.value !== productSortFieldOption[0].value)} */}
          {/* SORT FIELD */}
          {(sortfield && sortfield.value !== productSortFieldOption[0].value) &&
            <Grid item>
              <StyledChip
                label={`Sort Field : ${sortfield.label}`}
                onDelete={() => this.props.handleDelete(filterTagType.SortField)}
              />
            </Grid>
          }

          {/* STATUS */}
          {(status !== "Both" && status !== "" && status !== undefined) &&
            <Grid item>
              <StyledChip
                label={`${filterTagType.Status[0]?.toUpperCase()}${filterTagType.Status.slice(1, filterTagType.Status?.length)} : ${status}`}
                onDelete={() => this.props.handleDelete(filterTagType.Status)}
              />
            </Grid>
          }

          {/* GENDER */}
          {gender &&
            <Grid item>
              <StyledChip
                label={`${filterTagType.Gender[0]?.toUpperCase()}${filterTagType.Gender.slice(1, filterTagType.Services?.length)} : ${gender.label}`}
                onDelete={() => this.props.handleDelete(filterTagType.Gender)}
              />
            </Grid>
          }

          {role?.map((cat, index) => {
            return (
              <Grid item>
                <StyledChip
                  label={`${filterTagType.role[0]?.toUpperCase()}${filterTagType.role.slice(1, filterTagType.role?.length)} : ${cat.label}`}
                  onDelete={() => this.props.handleDelete(filterTagType.role, index)}
                />
              </Grid>
            )
          })}
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(FilterTags)

// PERSONA MANAGEMENT          FILTER TYPE USED
// Service Provider          - Category, Provider, Status
// Service Supervisor        - Category, Provider, Status
// Service Coordinator       - Category, Provider, Skills, Specialization, Status
// Service Professional      - Category, Provider, Skills, Specialization, Status
// Client                    - Provider, Professional, Coordinator, Status

// PRODUCT MANAGEMENT          FILTER TYPE USED
// Service                   - Category, Provider, Status
// Package                   - Category, Provider, Services, Status
// Product                   - Category, Provider, Services, Packages, Status