import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import { routes } from "../../../router/routes";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ListLoader from "../../../components/listLoader";
import { InternatlServerError, NotFoundSection, FilterBuilder } from "../../../components";
import { withAlertAndDialog } from "../../../HOCs";
import ClientListingStats from "./stats";
import SubHeader from "../../../components/subHeader";
// import List from "../../../components/userList/cardList";
import moment from "moment";
import { filterTagType, options, productSortDirectionOption, productSortFieldOption, scrollToTop, subHeaderSortOption, subHeaderSortOptionWithValue  } from "../../../utils";
import {commonStyle} from '../style'
import FilterTags from "../../../components/filters/filterTags";
import { Catalogue } from "../../../components/catalogue/catalogue";

import { filterComponent } from "./filterComponent";


const styles = (theme) => ({
  root: {
    padding: 25,
  },
  subHeader: {
    marginTop: 12,
    // marginBottom: 12,
  },
  ...commonStyle
});

// const sizes = {
//   md: 3,
//   sm: 3,
//   xs: 3,
// };

const initialState = {
  pageNo: 0,
  pageSize: 25,
  clientName: "",
  fromTimeStamp: moment().startOf('day').toISOString(),
  toTimeStamp: moment().endOf('day').toISOString(),
  loading: true,
  provider: [],
  professional: [],
  coordinator: [],
  status: "Both",
  date: null,
  isFilterApplied: false,
  sort: subHeaderSortOption[2].value,
  sortDirec: subHeaderSortOptionWithValue[2],
  gender: null
}



class ClientListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      pageNo: 0,
      pageSize: 25,
      clientName: "",
      fromTimeStamp: moment().startOf("day").toISOString(),
      toTimeStamp: moment().endOf("day").toISOString(),
      loading: true,
      id: "MonitoringClientsListing"
    };
  }


  componentDidMount() {
    this.getEssentials();
    
    scrollToTop()
    window.addEventListener("scroll", this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  }

  scrolling = () => {
    let { data, loading, error } = this.props?.getAllClientMonitor;
    
    let listCount = data?.list?.length ?? 0;
    let totalCount = data?.total_clients ?? 0;

    if (loading || error || totalCount === 0 || listCount === 0) {
      return false;
    }

    if (
        Math.round(window.scrollY + window.innerHeight) >=
      document.getElementsByTagName("body")[0].scrollHeight &&
      listCount !== totalCount
    ) {
      this.setState(
        (state, props) => ({
          pageNo: state.pageNo + 25,
        }),
        () => {
          this.getEssentials(); 
        }
      );
    }
  };

  getClientList = () => {
    let _status = undefined
    if(this.state?.status){
      if(this.state.status.toLowerCase() === "Active".toLowerCase()) _status = true
      if(this.state.status.toLowerCase() === "InActive".toLowerCase()) _status = false
    }
    
    this.props.GET_ALL_CLIENT_MONITOR({
      ...this.state,
      searchValue:this.state.clientName,
      isprofessional: true,
      offset:this.state?.pageNo,
      limit:this.state?.pageSize,
      filter:{ 
        provider: this.state.provider?.map(_ => _.value),
        professional: this.state.professional?.map(_ => _.value),
        coordinator: this.state.coordinator?.map(_ => _.value),
        status: _status,
        sort:this.state?.sortDirec?.value,
        gender: this.state?.gender?.value,
      },
      
    });
  };


  onFilterIconClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: <FilterBuilder
        components={filterComponent(this.state, this.props)}
        applyButtonLabel="Apply"
        loading={this.props?.allCareSupervisor?.loading}
        onChange={this.onFilterChange}
        onApplyButtonClicked={this.applyFilter}
        resetFilter={this.resetFilter}
        onCancelClick={this.closeFilter}
        spacing={1}
        saveButtonId={`saveFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
        cancelButtonId={`cancelFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
        clearAllButtonId={`clearAllFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
      />
    })
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  anyFilterApplied = (state) => {
    let { provider = [], professional = [], coordinator = [], status = "", sortDirec = productSortDirectionOption[0], sortfield = productSortFieldOption[0] } = state
    if(provider === null) provider = initialState.provider
    if(professional === null) professional = initialState.professional
    if(coordinator === null) coordinator = initialState.coordinator
    if(
        JSON.stringify(professional) === initialState?.professional &&
        JSON.stringify(provider) === initialState?.provider &&
        JSON.stringify(coordinator) === initialState?.coordinator &&
        status === initialState.status &&
        sortDirec.value === initialState.sortDirec.value && 
        sortfield.value === initialState.sortfield.value){
      return false
    } else {
      return true
    }
  }

  applyFilter = (state) => {

    console.log(state)
    this.setState({
      ...state,
      pageNo: 0,
      pageSize: 25,
      isFilterApplied: this.anyFilterApplied(state),
    }, () => {
      this.getClientList();
      this.closeFilter()
    })
  }

  closeFilter = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    })
  }

  resetFilter = () => {
    this.setState({
      ...initialState
    }, () => {
      this.getClientList();
      this.closeFilter();
    })
  }

  handleSortChange = (searchValue) => {
    this.setState({
      sort: searchValue,
      pageNo: 0,
      pageSize: 25
    }, () => {
      this.getClientList();
    })
  }

  handleChipDelete = (key, index) => {

    if (
      key === filterTagType.Provider ||
      key === filterTagType.Professional ||
      key === filterTagType.Coordinator) {
      let newState = this.state
      newState[key].splice(index, 1)

      
      this.setState({
        ...newState,
        pageNo: 0,
        pageSize: 25,
        isFilterApplied: this.checkIsThereAnyFilter()
      }, () => {
        this.getClientList()
      })

    } else if (key === filterTagType.Status) {
      this.setState({
        status: "Both",
        pageNo: 0,
        pageSize: 25,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getClientList()
      })
    } else if(key === filterTagType.Gender){
      this.setState({
        gender: initialState.gender,
        pageNo: 0,
        pageSize: 25,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getClientList()
      })
    }
  }


  // Filter Tag Utils
  checkIsThereAnyFilter = () => {
    // const newState = this.state
    let newState = {
      ...this.state,
      provider: this.state.provider === null ? initialState.provider : this.state.provider,
      professional: this.state.professional === null ? initialState.professional : this.state.professional,
      coordinator: this.state.coordinator === null ? initialState.coordinator : this.state.coordinator,
    }
    if ( 
      newState[filterTagType.Provider].length === 0 &&
      newState[filterTagType.Professional].length === 0 &&
      newState[filterTagType.Coordinator].length === 0 &&
      newState[filterTagType.Status] === initialState.status &&
      newState[filterTagType.Gender] === initialState.gender) {
      return false
    } else return true
  }

  getEssentials = () => {
    let _status = undefined
    if(this.state?.status){
      if(this.state.status.toLowerCase() === "Active".toLowerCase()) _status = true
      if(this.state.status.toLowerCase() === "InActive".toLowerCase()) _status = false
    }
    this.props.GET_ALL_CLIENT_MONITOR({
      ...this.state,
      searchValue:this.state.clientName,
      isprofessional: true,
      offset:this.state?.pageNo,
      limit:this.state?.pageSize,
      filter:{ 
        provider: this.state.provider?.map(_ => _.value),
        professional: this.state.professional?.map(_ => _.value),
        coordinator: this.state.coordinator?.map(_ => _.value),
        status: _status,
        sort:this.state?.sortDirec?.value,
        gender: this.state?.gender?.value,
      },
      
    
      // ...this.state, offset:this.state?.pageNo,
      // limit:this.state?.pageSize, searchValue:this.state.clientName 
    });
  };

  navigateToView = (id) => {
    this.props.history.push(routes.monitor_client_detail_parent + id);
  };

  storeInputSearchValue = (value) => {
    
    this.setState({ clientName: value, pageNo: 0, pageSize: 25 }, () => {
      // if (value.length % 3 === 0) {
      this.getEssentials();
      // }
    });
  };


  formatData = (clients) => {
    if (Array.isArray(clients)) {
      return clients?.map((client) => ({
        ...client,
        isactive: client?.isactive ? "active": "inactive",
        gender: options.gender.filter(
          (gender) => gender.value === client?.gender?.toLowerCase()
        )?.[0]
      }));
    }
    return [];
  }


  render() {
    const { classes } = this.props;

    var { data, loading, error } = this.props?.getAllClientMonitor;

    
    return (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={3}
        >
          <ClientListingStats
            loading={loading}
            error={error=false}
            data={data?.stats}
          />

          <Grid item xs={12} className={classes.subHeader}>
            <SubHeader
              isbutton={false}
              placeholder={"Search for Clients"}
              entityName={"Client"}
              countText={`Total Clients (${data?.total_clients ?? 0})`}
              handleSearch={this.getEssentials}
              handleChangeInputSearch={this.storeInputSearchValue}
              value={this.state.clientName}
              useNewFilterIcon
              placeSearchIconToLeft
              hideFilter={false}
              isFilterApplied={this.state.isFilterApplied}
              onFilterIconClicked={this.onFilterIconClicked}
              showSortOption={true}
              sort={this.state.sort}
              handleSortChange={this.handleSortChange}
              id={this.state?.id}
            />
          </Grid>

          {
          this.state.isFilterApplied &&
          <Grid item xs={12} style={{ marginTop: 25 }} >
            <FilterTags
              filterData={this.state}
              handleDelete={this.handleChipDelete}
            />
          </Grid>
        }

          {/* TOTAL COUNT  */}
          {/* <Grid item xs={12}>
            <Typography variant="h6" style={{ paddingTop: 25 }} className={classes.mediumFont} >Total Clients ({data?.list?.length ?? 0})</Typography>
          </Grid> */}

          {loading && (
            <Grid item xs={12}>
              <ListLoader />
            </Grid>
          )}
          {!loading && error && (
            <Grid item xs={12}>
              <InternatlServerError
                message={"Something Went Wrong!"}
                onRetry={this.getEssentials}
                top={"20vh"}
              />
            </Grid>
          )}
          {!loading && !error && !data?.list?.length && (
            <Grid item xs={12}>
              <NotFoundSection message="You don't have any clients" top="15vh" />
            </Grid>
          )}
          {!loading && !error && data?.list?.length > 0 && (
            <>
              <Grid item xs={12} style={{ cursor: "pointer" }}>
              <Catalogue
                id={"MonitoringClients"}
                catalogues={this.formatData(data?.list)}
                redirectUrl={routes.monitor_client_detail_parent}
                loading={loading}
              />
                {/* {data?.list?.map((_, i) => {
                  return (
                    <div key={i} onClick={() => this.navigateToView(_.id)}>
                      <List
                        list={_}
                        isShowGenderAge={true}
                        componentOrder={1}
                        sizes={sizes}
                        propsList={{
                          isCard: true,
                        }}
                        hideQuickSummaryLabel
                        hideQuickSummary
                        showCareGiver={true}
                      />
                    </div>
                  );
                })} */}
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  services: state?.serviceState?.allServices,
  getAllClientMonitor: state?.client_monitor?.getAllClientMonitor,
  auth_data: state?.authState?.loginAuth
});

export default connect(
  mapStateToProps,
  actions
)(withAlertAndDialog(withRouter(withStyles(styles)(ClientListing))));
