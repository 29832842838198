import React from "react";
import { Container, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../contexts";

const styles = () => ({
  wrapper: {
    height: "100vh",
    background: "white",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flexDirection: "column",
  },
});

class MobilePaymentRedirect extends React.Component {
  render() {
    const { classes } = this.props;
    let status =
      new URLSearchParams(this.props.location.search).get("status") ?? "fail";
    return (
      <>
        <Container maxWidth="lg" className={classes.wrapper}>
          <div className={classes.root}>
            <h3>You are being redirect, please wait...</h3>
            <p>If you haven't been redirected, Please click this button</p>

            {this.props.match.params.id === "ios" ? (
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={() =>
                  status?.toLowerCase() === "success"
                    ? window.location.replace(
                        "mytanyacare://main/payment/SUCCESS"
                      )
                    : window.location.replace("mytanyacare://main/payment/FAIL")
                }
              >
                {"Continue"}
              </Button>
            ) : (
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={() =>
                  window.ReactNativeWebView?.postMessage("Redirect")
                }
              >
                {"Continue"}
              </Button>
            )}
          </div>
        </Container>
      </>
    );
  }
}

MobilePaymentRedirect.contextType = AuthContext;

export default withStyles(styles, { withTheme: true })(
  withRouter(MobilePaymentRedirect)
);
