import React from "react";
import { Grid, Avatar, makeStyles, useTheme } from "@material-ui/core";
import { avatarColorBgById } from "../../utils";
import Skeleton from "@material-ui/lab/Skeleton";
import { InfoDetail } from "./infoDetail";
import { InfoMeta } from "./infoMeta";

const customAvatarStyle = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 1),
  },
  avatarSize: {
    width: theme.spacing(8.75),
    height: theme.spacing(8.75),
  },
  skeleton: {
    margin: theme.spacing(0, 1),
  },
}));

export function CustomAvatar({ src, name, id, loading = false }) {
  const classes = customAvatarStyle();
  const theme = useTheme();
  const fallbackText = name?.toString()?.toUpperCase()?.trim()?.[0];
  const fallbackId =
    id?.[id?.toString()?.search(/\d/)] *
    id?.[id?.toString().split("").reverse().join("")?.search(/\d/)];

  return (
    <>
      {loading && (
        <Skeleton
          variant="circle"
          className={classes.skeleton}
          width={theme.spacing(8.75)}
          height={theme.spacing(8.75)}
        />
      )}
      {!loading && (
        <Avatar
          src={src}
          className={`${classes.root} ${classes.avatarSize}`}
          style={{
            backgroundColor: !!!src ? avatarColorBgById(fallbackId) : "unset",
          }}
        >
          {fallbackText}
        </Avatar>
      )}
    </>
  );
}

export function InfoWrapper({ details, loading, ...props }) {
  return (
    <>
      <Grid item style={{alignSelf: "flex-start"}}>
        <CustomAvatar
          src={details?.profile_pic}
          name={details?.name}
          id={details?.id}
          loading={loading}
        />
      </Grid>
      <Grid item style={{marginRight: 12}}>
        <InfoDetail loading={loading} details={details} isCategoryAChip={props.isCategoryAChip} showTrialExpiry={props.showTrialExpiry} />
      </Grid>
      {props.createdAt && props.activation && <Grid item xs>
        <InfoMeta loading={loading} details={details} createdAt={props.createdAt} activation={props.activation} />
      </Grid>}
    </>
  );
}
