import React, { Component } from "react";
// import ActivityAndMembersCard from "../availabilityActivityComponents/ActivityAndMembersCard";
import {
  Divider,
  Grid,
  ListItemIcon,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { customAddress, formComponentTypes } from "../../utils";
import BeenhereSharpIcon from "@material-ui/icons/BeenhereSharp";
import MenuBookSharpIcon from "@material-ui/icons/MenuBookSharp";
export default class ViewAddressEmailPhone extends Component {
  onEndIconClickHandler = (id) => {
    if (id) this.props.onEndIconClickHandler(id, false);
  };

  customConverter = (data) => {
    switch (this.props.type) {
      case formComponentTypes.ADD_ADDRESS: {
        return (
          <Grid container>
            {/* <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                {data.label}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {customAddress(
                  data.addressLine1,
                  data.addressLine2,
                  data.area,
                  data.state,
                  data.country,
                  data.postalCode
                )}
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <List
                disablePadding
                component="nav"
                style={{ padding: 0 }}
                // className={classes.root}
              >
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookSharpIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.label}
                    secondary={customAddress(
                      data.addressLine1,
                      data.addressLine2,
                      data.area,
                      data.state,
                      data.country,
                      data.postalCode
                    )}
                  />
                </ListItem>
              </List>
              {/* <Typography variant="subtitle1">{`${data.id_type}`}</Typography> */}
            </Grid>
          </Grid>
        );
      }
      case formComponentTypes.ADD_EMAIL: {
        return (
          <Grid container>
            {/* <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                {data.label}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{data.email}</Typography>
            </Grid> */}
            <Grid item xs={12}>
              <List
                disablePadding
                component="nav"
                style={{ padding: 0 }}
                // className={classes.root}
              >
                <ListItem button>
                  <ListItemIcon>
                    <BeenhereSharpIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={data.label} secondary={data.email} />
                </ListItem>
              </List>
              {/* <Typography variant="subtitle1">{`${data.id_type}`}</Typography> */}
            </Grid>
          </Grid>
        );
      }
      case formComponentTypes.ADD_PHONE_NUMBER: {
        return (
          <Grid container>
            {/* <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                {data.label}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {`${data.mobileNoIsdCode} ${data.mobileNo}`}
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <List
                disablePadding
                component="nav"
                style={{ padding: 0 }}
                // className={classes.root}
              >
                <ListItem button>
                  <ListItemIcon>
                    <BeenhereSharpIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.label}
                    secondary={`${data.mobileNoIsdCode} ${data.mobileNo}`}
                  />
                </ListItem>
              </List>
              {/* <Typography variant="subtitle1">{`${data.id_type}`}</Typography> */}
            </Grid>
          </Grid>
        );
      }
      case formComponentTypes.ADD_ID_TYPE: {
        return (
          <Grid container>
            {/* <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                {data.label}
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <List
                disablePadding
                component="nav"
                style={{ padding: 0 }}
                // className={classes.root}
              >
                <ListItem button>
                  <ListItemIcon>
                    <BeenhereSharpIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={data.label} secondary={data.id_type} />
                </ListItem>
              </List>
              {/* <Typography variant="subtitle1">{`${data.id_type}`}</Typography> */}
            </Grid>
          </Grid>
        );
      }
      case "array_list": {
        return (
          <Grid container>
            {/* <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                {data.label}
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              {/* <Typography variant="subtitle1">
              {data.label}
              </Typography> */}
              <List
                disablePadding
                component="nav"
                style={{ padding: 0 }}
                // className={classes.root}
              >
                <ListItem button>
                  <ListItemIcon>
                    <BeenhereSharpIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={data.label} />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        );
      }
      default: {
        return (
          <Grid container>
            {/* <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                {data.label}
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <List
                disablePadding
                component="nav"
                style={{ padding: 0 }}
                // className={classes.root}
              >
                <ListItem button>
                  <ListItemIcon>
                    <BeenhereSharpIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={data.label} />
                </ListItem>
              </List>
              {/* <Typography variant="subtitle1">{`${data.id_type}`}</Typography> */}
            </Grid>
          </Grid>
        );
      }
    }
  };
  render() {
    const { data, label, isDontAppendString } = this.props;
    return (
      <div style={{ padding: "14px 14px 14px 14px" }}>
        {/* Heading */}
        <Typography
          variant="h6"
          style={{
            fontWeight: "bold",
            textAlign: "center",
            textDecoration: "underline",
          }}
        >
          {`${data?.length ?? 0} ${
            data?.length > 1
              ? isDontAppendString
                ? label
                : String(label).toLowerCase().includes("address")
                ? label.concat("ES")
                : label.concat("S")
              : label
          } `}
        </Typography>

        {/* Service Coordinator List */}
        {(data?.constructor === Array ? data : [])?.map((item, index) => {
          return (
            <>
              <div>{this.customConverter(item)}</div>
              <Divider />
            </>
          );
        })}
        {/* <Typography>View all Service Coordinator Screen Needed</Typography> */}
      </div>
    );
  }
}
