import { subNavBarArr } from "./constants";

/**
 * The below function convert the normal array of object to 
 * {label: "",value:1} pair which is suitable for React Select
 * component.
 */
 export let ConvertToReactSelect = (data, valueKey, labelKey) => {
    if (!data || !data?.length) {
        return [];
    }

    return data.map((val) => {
        return {
            ...val,
            value: val[valueKey],
            label: val[labelKey],
        };
    });
};

export const amIableToDoThis = (module, op, data) => {

    let isIamOk = true;

    // let mapped_roles = data?.mapped_roles;
    // for (const key in mapped_roles) {
    //     if (Object.hasOwnProperty.call(mapped_roles, key)) {
    //         const element = mapped_roles[key];
    //         isIamOk = element?.previlages?.[module]?.indexOf(op) > -1;
    //         break;
    //     }
    // }

    return isIamOk;
}

export const giveMeRoleDataSecurity = (data) => {

    let roleDataSecurity = {
        country: [],
        level1: [],
        level2: [],
        level3: [],
        level4: [],
        level5: [],
        level6: [],
        level7: [],
        level8: [],
        level9: [],
        level10: [],
        level11: [],
        level12: [],
        disease: [],
        form: [],
        myRole: [],
        isOnlyNotifier: false
    };

    let mapped_roles = [data?.mapped_roles?.[0] ?? {}];

    for (const key in mapped_roles) {

        const role = mapped_roles[key];

        //Checking is Notifier Only
        if (role?.previlages?.notify?.length > 0
            && role?.previlages?.notification?.length > 0
            && role?.previlages?.Caseverification?.length === 0
            && role?.previlages?.caseRegistration?.length === 0
            && role?.previlages?.investigation?.length === 0
        ) {
            roleDataSecurity.isOnlyNotifier = true;
        }

        roleDataSecurity.myRole.push(role.role_id);

        //Country
        if (role.data_security?.country?.value) {
            roleDataSecurity.country.push(role.data_security.country.value);
        }

        //Disease & Forms
        role.data_security.mappingTable.forEach(mapTable => {
            //Disease
            if (mapTable.disease.value.toLowerCase() !== 'all'
                && roleDataSecurity.disease[0].value?.toLowerCase() !== 'all') {
                roleDataSecurity.disease.push(mapTable?.disease?.value)
            } else {
                roleDataSecurity.disease = ['all']
            }

            //Forms
            if (mapTable.form.value.toLowerCase() !== 'all'
                && roleDataSecurity.form[0].value?.toLowerCase() !== 'all') {
                roleDataSecurity.form.push(mapTable?.form?.value)
            } else {
                roleDataSecurity.form = ['all']
            }

        })

        //SelectedLevel
        role.mapped_location.forEach(loc => {
            Object.keys(loc).forEach(mapLocKey => {
                if (loc[mapLocKey].value?.toLowerCase() !== 'all'
                    && roleDataSecurity?.[mapLocKey]?.[0]?.value?.toLowerCase() !== 'all') {
                    roleDataSecurity[mapLocKey].push(loc[mapLocKey].value)
                } else {
                    roleDataSecurity[mapLocKey] = ['all']
                }
            })
        });
    }

    return roleDataSecurity;
}

export const giveMeSubNavBars = (role) => {
    let navBarIds = [];
    let mapped_roles = role?.mapped_roles;
    for (const key in mapped_roles) {
        if (Object?.hasOwnProperty?.call(mapped_roles, key)) {
            const element = mapped_roles?.[key];
            let rolePrivilages = element?.previlages ?? {};
            let previlageKeys = Object.keys(rolePrivilages).filter(pk => element?.previlages?.[pk].length > 0);
            navBarIds.push(...previlageKeys)
        }
        break;
    }
    return subNavBarArr.filter(sb => navBarIds.indexOf(sb.id) > -1).sort((a, b) => (a.prioriy > b.prioriy) ? 1 : ((b.prioriy > a.prioriy) ? -1 : 0))

}