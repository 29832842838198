import React from "react";
import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

export const LocationIcon = (props) => {
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
    >
      <g
        id="location_1_"
        data-name="location (1)"
        transform="translate(-90.825 .175)"
      >
        <path
          id="Path_344"
          d="M161.793 93.32l-4.779-3.186a.8.8 0 0 0-.884 0l-4.779 3.186a.8.8 0 0 0 .884 1.325l.355-.236v4.406a.8.8 0 0 0 .8.8h6.372a.8.8 0 0 0 .8-.8v-4.406l.355.236a.8.8 0 1 0 .884-1.325zm-2.831 4.7h-4.779v-4.673l2.389-1.593 2.389 1.593z"
          className="cls-1"
          data-name="Path 344"
          transform="translate(-56.812 -85.222)"
        />
        <path
          id="Path_345"
          d="M99.761 0A8.771 8.771 0 0 0 91 8.761c0 5.061 4.845 11.608 8.194 15a.8.8 0 0 0 1.134 0c3.4-3.443 8.194-9.966 8.194-15A8.771 8.771 0 0 0 99.761 0zm0 22.039c-1.785-1.956-7.168-8.268-7.168-13.278a7.168 7.168 0 1 1 14.336 0c0 5.004-5.383 11.322-7.168 13.278z"
          className="cls-1"
          data-name="Path 345"
        />
      </g>
    </SvgIcon>
  );
};

LocationIcon.propTypes = {
  htmlColor: PropTypes.string,
  style: PropTypes.object,
};
