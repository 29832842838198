import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, SvgIcon } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.htmlColor || theme.palette.primary.main
  })
}))

export const Calendar = (props) => {
  const classes = useStyles(props)

  return <SvgIcon style={props.style}>
    {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20"> */}
    <g id="Calendar" transform="translate(-3 -1)">
      <path id="Path_689" d="M6 1h2v4H6zm10 0h2v4h-2z" className={classes.cls1} />
      <path id="Path_690" d="M19 3H5a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm0 16H5V9h14zm0-12H5V5h14z" className={classes.cls1} />
    </g>
    {/* </svg> */}
  </SvgIcon>
}

Calendar.propTypes = {
  /**Sets the icon color */
  htmlColor: PropTypes.string,
  /**Additional Styles to apply for the SvgIcon tag */
  style: PropTypes.object
}