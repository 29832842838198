import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Phone } from "../../assets/viewScreenIcons/phone";
import { CurrentLocation } from "../../assets/viewScreenIcons/currentLocation";
import { ClientBMIIcon, EmailIcon, LocationIcon, ClientHeightIcon, ClientWeightIcon } from "../../assets/";
import { Height } from "../../assets/viewScreenIcons/height";
import { Weight } from "../../assets/viewScreenIcons/weight";
import PropTypes from "prop-types";

export const ICONS = {
  CALL: (colorCode) => {
    return (
      <Phone
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 12,
          width: 12,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },

  LOCATION: (colorCode) => {
    return (
      <LocationIcon
        htmlColor={colorCode ? colorCode : "secondary"}
        style={{
          height: 12,
          width: 12,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },

  CURRENTLOCATION: (colorCode) => {
    return (
      <CurrentLocation
        color={colorCode ? colorCode : "secondary"}
        // htmlColor={colorCode ? colorCode : ''}
        style={{
          height: 12,
          width: 12,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },

  HEIGHT: (colorCode) => {
    return (
      <Height
        color={colorCode ? colorCode : "secondary"}
        // htmlColor={colorCode ? colorCode : ''}
        style={{
          height: 12,
          width: 12,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },

  CLIENTHEIGHT: (colorCode) => {
    return (
      <ClientHeightIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 12,
          width: 12,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },

  WEIGHT: (colorCode) => {
    return (
      <Weight
        color={colorCode ? colorCode : "secondary"}
        // htmlColor={colorCode ? colorCode : ''}
        style={{
          height: 12,
          width: 12,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },

  CLIENTWEIGHT: (colorCode) => {
    return (
      <ClientWeightIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 12,
          width: 12,
          margin: `4px 8px 8px 0px`,
        }}
      />
    );
  },

  CLIENTBMI: (colorCode) => {
    return (
      <ClientBMIIcon
        color={colorCode ? colorCode : "secondary"}
        // htmlColor={colorCode ? colorCode : ''}
        style={{
          height: 12,
          width: 12,
          margin: `4px 8px 8px 0px`,
        }}
      />
    )
  },
  EMAIL: (colorCode) => {
    return (
      <EmailIcon
        color={colorCode ? colorCode : "secondary"}
        // htmlColor={colorCode ? colorCode : ''}
        style={{
          height: 12,
          width: 12,
          margin: `4px 8px 8px 0px`,
        }}
      />
    )
  }
};

const styles = makeStyles((theme) => ({
  text: {
    color: "white",
    overflowWrap: 'break-word',
    width:"70%"
  },
}));

export const SimpleListWithIconText = (props) => {
  const { icon, text } = props;
  const classes = styles();
  return (
    <Grid container>
      <Grid container item xs={12}>
        {icon}
        <Typography variant="caption" className={classes.text} {...props}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

SimpleListWithIconText.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
};
