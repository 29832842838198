import * as React from "react"

export const ItalicIcon = (props) => {

    const {
        width = "19.524",
        height = "20.767",
        color = "#6f6f6f"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 19.524 20.767"
        >
            <path id="icons8-italic" d="M20.691,6.982a.915.915,0,0,0-.1.011H14.016a.915.915,0,1,0,0,1.83h5.375L12.779,25.906H7.915a.915.915,0,1,0,0,1.83h5.34a.915.915,0,0,0,.292,0h7.181a.915.915,0,1,0,0-1.83H14.742L21.354,8.823h4.254a.915.915,0,1,0,0-1.83h-4.73a.915.915,0,0,0-.188-.011Z" transform="translate(-7 -6.981)"
                fill={color}
            />
        </svg>
    );
};


