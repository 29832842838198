import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function WeightIconV2(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.716 4.001A3.089 3.089 0 008.63 7.087H6.315a1.53 1.53 0 00-1.533 1.334l-.765 10.07a1.462 1.462 0 00.377 1.216 1.567 1.567 0 001.15.5H17.89a1.567 1.567 0 001.148-.5 1.426 1.426 0 00.384-1.16l-.77-10.127-.008-.059a1.52 1.52 0 00-1.526-1.274h-2.314a3.089 3.089 0 00-3.087-3.086zm0 1.543a1.545 1.545 0 011.541 1.543h-3.084a1.545 1.545 0 011.543-1.543zM6.313 8.629H17.12l.77 10.026H5.55l.763-10.026zm1.295 1.51v5.76H8.87v-1.155l.357-.356.885 1.51h1.446l-1.514-2.387 1.42-1.668H9.95l-.953 1.186-.127.169v-3.059H7.608zm5.965 1.63a1.533 1.533 0 00-.88.256c-.26.181-.463.435-.582.729-.14.345-.207.715-.2 1.087v.045a2.35 2.35 0 00.457 1.519 1.466 1.466 0 001.2.567 1.184 1.184 0 00.918-.4v.128a.751.751 0 01-.84.86 1.157 1.157 0 01-.929-.416l-.529.743c.173.197.393.347.64.437.273.112.566.17.862.169a2.64 2.64 0 001.094-.211c.296-.125.547-.336.72-.606a1.7 1.7 0 00.25-.931v-3.9h-1.147l-.045.353a1.213 1.213 0 00-.989-.429zm.322.974a.653.653 0 01.588.277v1.7a.672.672 0 01-.6.274.591.591 0 01-.524-.291 1.438 1.438 0 01-.188-.788 1.656 1.656 0 01.191-.876.6.6 0 01.533-.296z"
        fill="#0063E7"
      />
    </SvgIcon>
  );
}

export default WeightIconV2;
