import React from "react";
import PropTypes from "prop-types";

import { SvgIcon } from "@material-ui/core";

function ZoomOutIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.006 13.941H1.41a1.472 1.472 0 0 1 0-2.941h20.597a1.472 1.472 0 0 1 0 2.944l-.002-.003Z"
        fill={props.color}
      />
    </SvgIcon>
  );
}

ZoomOutIcon.propTypes = {
  color: PropTypes.string,
};

ZoomOutIcon.defaultProps = {
  color: "#6F6F6F",
};

export default ZoomOutIcon;
