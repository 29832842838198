import React from 'react'
import { withStyles, Grid, Typography, Button } from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    marginTop: 15,
    marginBottom: 5
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  viewAllButton: {
    color: theme.palette.ternary.main,
    boxShadow: 'none !important',
  }
})

class SectionName extends React.Component {
  render() {
    const { classes, title, actionText, variant,hideViewBtn } = this.props

    return (
      <Grid container justify={'space-between'} className={classes.root}>
        <Grid item className={classes.textContainer}>
          <Typography variant={variant ? variant : "body1"}>{title}</Typography>
        </Grid>
        <Grid item>
          {!hideViewBtn && <Button className={classes.viewAllButton} >{actionText}<ArrowRightAltIcon /></Button>}
        </Grid>        
      </Grid>
    )
  }
}

export default withStyles(styles)(SectionName)

SectionName.propTypes = {
  /**
   * The Section title
   */
  title: PropTypes.string,
  /**
   * The Text for the button on the right side
   */
  actionText: PropTypes.string,
  /**
   * The Variant for the title
   */
  variant: PropTypes.string
}