import React from "react";
import { withStyles, Chip, useTheme } from "@material-ui/core";

export default (theme) => ({
  headerSection: {
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    backgroundColor: "white",
    borderRadius: 8,
    marginBottom:8,
  },
  flex: {
    display: "flex",
  },
  displayInline: {
    display: "inline",
  },
  colorSecondary: {
    color: theme.palette.primary.dark,
    marginRight: "20px",
  },
  // marginRight: {
  //     marginRight: '20px'
  marginRight: {
    margin: "5px",
  },
  paddingRight: {
    paddingRight: "15px",
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  avatarWrapper: {
    margin: "auto",
  },
  avatar: {
    width: "50px",
    height: "50px",
    margin: theme.spacing(0, 1),
    // margin: "auto auto auto 0",
  },
  user_name: {
    // display: "inline-block",
    paddingRight: theme.spacing(1.5),
    fontSize: "1.1rem",
  },
  count_name:{
    paddingRight: theme.spacing(1.5),
    // fontSize
  },
  isactive: {
    display: "inline",
    fontSize: "1rem",
    color: theme.palette.success.main,
  },
  email_verified: {
    display: "inline",
    fontSize: "1rem",
    color: theme.palette.success.main,
  },
  inactive: {
    display: "inline",
    fontSize: "1rem",
    color: theme.palette.error.main,
  },
  email_not_verified: {
    display: "inline",
    fontSize: "1rem",
    color: theme.palette.error.main,
  },
  status_name: {
    display: "inline",
    paddingLeft: "16px",
    fontSize: "14px",
  },
  active_name: {
    color: theme.palette.success.main,
  },
  email_verified_name: {
    color: theme.palette.success.main,
  },
  inactive_name: {
    color: theme.palette.error.main,
  },
  email_not_verified_name: {
    color: theme.palette.error.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
    fontSize: "14px",
    paddingTop: "0px",
  },
  secondaryAddActivity: {
    color: theme.palette.secondary.main,
    fontSize: "14px",
    paddingTop: "0px",
    display:"flex",
    textAlign:"center",
  },
  // left_border:{
  //   borderLeft: "1px solid rgba(0, 0, 0, 0.08)",

  // },
  iconColor: {
    fill: theme.palette.secondary.main,
  },
  status_circle: {
    marginLeft: 10,
    width: 6,
    height: 6,
    borderRadius: "50%",
  },
  activecircle: {
    background: theme.palette.success.main,
  },
  email_verified_circle: {
    background: theme.palette.success.main,
  },

  inactivecircle: {
    background: theme.palette.error.main,
  },
  email_not_verified_circle: {
    background: theme.palette.error.main,
  },
  marginAuto: {
    margin: "auto",
  },
  marginHeight: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  orderList: {
    // borderLeft: "0.5px solid #f7f7f7",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    // [theme.breakpoints.down("md")]: {
    //   marginTop: "20px",
    // },
  },
  cardPaper: {
    marginBottom: "12px",
    borderRadius: "16px",
    // boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    padding: theme.spacing(1.5),
    // boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`, //elevation 2
    // boxShadow: `0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)`, //elevation 1
    // boxShadow: `0 0 11px rgba(33,33,33,.2)`,
    boxShadow: theme.palette.primary.boxShadow
  },
  NextGencardPaper: {
    // marginBottom: "12px",
    // borderRadius: "16px",
    // boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    padding: theme.spacing(2.5),
    // boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`, //elevation 2
    // boxShadow: `0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)`, //elevation 1
    // boxShadow: `0 0 11px rgba(33,33,33,.2)`,
    boxShadow: theme.palette.primary.boxShadow
  },
  noCardTop: {
    padding: 16,
    borderRadius: "0px",
    borderTopRightRadius: "16px",
    borderTopLeftRadius: "16px",
    borderBottom: "1px solid #f3f3f3",
    // boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    // boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`, //elevation 2
    // boxShadow: `0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)`, //elevation 1
    // boxShadow: `0 0 11px rgba(33,33,33,.2)`,
    boxShadow: "0px 15px 25px #00000012"
  },
  noCardBottom: {
    padding: 16,
    borderRadius: "0px",
    borderBottomRightRadius: "16px",
    borderBottomLeftRadius: "16px",
    borderTop: "1px solid #f3f3f3",
    // boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    // boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`, //elevation 2
    // boxShadow: `0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)`, //elevation 1
    // boxShadow: `0 0 11px rgba(33,33,33,.2)`,
    boxShadow: "0px 15px 25px #00000012"
  },
  flexEnd: {
    justifyContent: "flex-end",
  },
  bottom_avatar: {
    backgroundColor: "#fafafa",
    height: 55,
    width: 55,
    padding: 8,
    // border: `1px solid ${theme.palette.ternary.light}`
  },
  typoSpacing: {
    paddingBottom: 4,
    paddingLeft: 12
  },
  // Categorytext Background
  categoryAsCard: {
    backgroundColor: "#f4f4f5",
    textAlign: "center",
    width: "fit-content",
    padding: "5px 10px",
    marginRight: "8px",
    borderRadius: "3px",
    // [theme.breakpoints.down("md")]: {
    //   marginBottom: "8px",
    // },
  },

  // Categorytext Background
  minLimitExceeded: {
    backgroundColor: "#f4f4f5",
    textAlign: "center",
    width: "fit-content",
    padding: "5px 10px",
    marginRight: "8px",
    borderRadius: "3px",
    marginBottom: "12px",
    // [theme.breakpoints.down("md")]: {
    //   marginBottom: "8px",
    // },
  },

  categoryAsText: {
    marginRight: "8px",
  },
  chipAsText: {
    backgroundColor: "transparent",
    paddingLeft: 0,
  },
  rightPoleDetailsButton: {
    height: "100%",
    display: "flex",
  },


  /* ribbon: {
    width: "150px";
    height: "150px";
    overflow: "hidden";
    position: "absolute";
  }
  ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  } */
});

export const StyledChip = withStyles((props) => {
  const theme = useTheme()
  return {
    root: {
      height: 24,
      backgroundColor: theme.palette.chip.main,
      // backgroundColor: "#D7E2E9",
      color: "unset",
      borderRadius: 8,
    },
  };
})((props) => <Chip {...props} />);
