import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles, alpha } from "@material-ui/core/styles";
import { createTestProps } from "../../../../utils/common";

const CustomOutlinedButton = withStyles((theme) => {
  const error = "rgb(240, 62, 80)";
  return {
    root: {
      color: error,
      border: `1px solid ${alpha(error, 0.5)}`,
      padding: "14.5px 26px",
      textTransform: "unset",
      "&:hover": {
        border: `1px solid ${error}`,
        backgroundColor: alpha(error, 0.04),
      },
      [theme.breakpoints.down("xs")]: {
        padding: "10.5px 26px",
      },
    },
    label: {
      lineHeight: "19px",
    },
  };
})(Button);

const CustomContainedButton = withStyles((theme) => {
  return {
    root: {
      padding: "14.5px 26px",
      textTransform: "unset",
      [theme.breakpoints.down("xs")]: {
        padding: "10.5px 26px",
      },
    },
    label: {
      lineHeight: "19px",
    },
  };
})(Button);

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "24px",
    marginTop: "80px",
    padding: "0px 20px",
    "& > button": {
      flex: 1,
    },
  },
}));

function CustomFilterCTA(props) {
  const classes = useStyles();

  const {
    handlers: { resetFilter = () => {}, onApplyButtonClicked = () => {} },
  } = props;

  return (
    <div className={classes.buttonContainer}>
      <CustomOutlinedButton
        variant="outlined"
        onClick={resetFilter}
        {...createTestProps(`reset promotional banner filter`)}
      >
        Reset
      </CustomOutlinedButton>
      <CustomContainedButton
        variant="contained"
        color="primary"
        onClick={onApplyButtonClicked}
        {...createTestProps(`apply promotional banner filter`)}
      >
        Apply Filter
      </CustomContainedButton>
    </div>
  );
}

export default CustomFilterCTA;
