import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
// import { ShapesIcon, StarIcon, TextIcon } from './svg';
import { ShapesMenu } from './shapesMenu';
import { StarMenu } from './starMenu';
import { TextMenu } from './textMenu';
import PropTypes from 'prop-types';

const styles = makeStyles((theme)=>({
    subMenu:{
        padding:theme.spacing(3)
    }
}))

export const SubMenu = (props) =>{

    const {
       activeMenu="",
       handleTextSubMenu=null,
       handleShapeSubMenu=null,
       handleStarSubMenu=null,
       activeObject={},
       activeObjectValues={},
    } = props;

    const classes = styles(props);


    const switchSubMenu = (menu) =>{
        switch(menu){
            case 'shapes':
                return(
                    <ShapesMenu 
                        handleShapeSubMenu={handleShapeSubMenu} 
                        activeObject={activeObject}
                        activeObjectValues={activeObjectValues}
                    />
                )
            case 'star':
                return(
                    <StarMenu 
                        handleStarSubMenu={handleStarSubMenu}
                        activeObject={activeObject}
                        activeObjectValues={activeObjectValues}
                    />
                )
            case 'text':
                return(
                    <TextMenu 
                        handleTextSubMenu={handleTextSubMenu}
                        activeObjectValues={activeObjectValues}
                    />
                )
            default:
                return null
        }
    }

    return(
        <div className={classes.subMenu}>
            {switchSubMenu(activeMenu)}
        </div>
    )
}

SubMenu.propTypes={
    activeMenu:PropTypes.string,
    handleTextSubMenu:PropTypes.func,
    handleShapeSubMenu:PropTypes.func,
    handleStarSubMenu:PropTypes.func,
    activeObject:PropTypes.object,
    activeObjectValues:PropTypes.object,
}