import { Grid } from "@material-ui/core";
import React from "react";
import { TextField } from "../../../../components";
// import { errorMessage } from "../../../../utils/errorMessages";

export const ICNumberPassport = (props) => {
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          id={props?.id?.ic_id}
          type={props.type}
          withBoldLabel={props.withBoldLabel}
          withoutUppercase={props?.withoutUppercase}
          onlyText={props?.onlyText}
          limit={props.limit}
          state={props.state}
          rowsMax={props.rowsMax ? props.rowsMax : 1}
          multiline={props.multiline}
          title={props.ic_label}
          placeholder={props.placeholder ? props.placeholder : ""}
          defaultValue=""
          value={props?.value?.ic_number ? props?.value?.ic_number !== "null" ? props?.value?.ic_number : "": "" }
          handleOnChange={(e, limit) => {
            
            props.handleOnChange(
              e.target.value,
              "ic_number",
              props?.type,
              () => {},
              limit
            );
          }}
          error={props.error}
          errorText={
            props.errorText
          }
          disabled={props.ic_disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={props?.id?.passport_id}
          type={props.type}
          withBoldLabel={props.withBoldLabel}
          withoutUppercase={props?.withoutUppercase}
          onlyText={props?.onlyText}
          limit={props.limit}
          state={props.state}
          rowsMax={props.rowsMax ? props.rowsMax : 1}
          multiline={props.multiline}
          title={props.passport_label}
          placeholder={props.placeholder ? props.placeholder : ""}
          defaultValue=""
          value={props?.value?.passport ? props?.value?.passport !== "null" ? props?.value?.passport : "" : ""}
          handleOnChange={(e, limit) => {
            
            props.handleOnChange(
              e.target.value,
              "passport",
              props?.type,
              () => {},
              limit
            );
          }}
          error={props.error}
          errorText={
            props.errorText
          }
          disabled={props.passport_disabled}
        />
      </Grid>
    </Grid>
  );
};
