import React from 'react'
import { Button, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { months } from '../../utils'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({

  //NOTE: Apply this style if the year needs to be a button of text variant
  // yearButton: {
  //   "&.MuiButton-root": {
  //     "borderRadius": "8px",
  //     "boxShadow": "none !important",
  //     "&:hover": {
  //       "boxShadow": "none !important"
  //     }
  //   }
  // },

  addButtonContainer: {
    background: "#f27d35",
    margin: '8px 12px',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  addButtonRoot: {
    minWidth: 'unset'
  },
  monthsContainer: {
    marginTop: 32
  },
  month: {
    width: 48
  },
  activeMonthClass1: {
    background: 'rgba(242, 125, 53, 0.08)'
  },
  activeMonthClass2: {
    color: theme.palette.ternary.main,
  },
  topMonthRow: {
    marginBottom: 20,
  },
  bottomMonthRow: {
    marginTop: 20
  },
  xsScreenTopMiddleMonth: {
    marginTop: 20,
    marginBottom: 10,
  },
  xsScreenBottomMiddleMonth: {
    marginTop: 10,
    marginBottom: 20,
  },

}))


function Calendar(props) {
  const classes = useStyles()
  const theme = useTheme();
  const screensSmAndUp = useMediaQuery(theme.breakpoints.up('sm'))

  //Props
  const { selectedMonth, onMonthChange, selectedYear, onPreviousYearClickHandler, onNextYearClickHandler, addHolidayClickHandler, hideAddHolidayClickHandler } = props
  const isDisable = () =>{
    if(hideAddHolidayClickHandler){
      return false
    } else {
      let _current_year = new Date().getFullYear()
      return selectedYear && selectedYear < _current_year
    }
  }
  return (
    <div style={{ padding: `4px 16px 16px`, width: '100%' }}>
      <Grid item container>
        <Grid item xs container justify="flex-end" alignItems="center">
          <IconButton id={props?.previousButtonId} onClick={onPreviousYearClickHandler} disabled={isDisable()}>
            <ChevronLeftIcon />
          </IconButton>
        </Grid>
        <Grid item style={{ margin: '0 8px', padding: '0 8px', display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" >{selectedYear}</Typography>
        </Grid>
        <Grid item xs container justify="flex-start" alignItems="center">
          <IconButton id={props?.nextButtonId} onClick={onNextYearClickHandler}>
            <ChevronRightIcon />
          </IconButton>
        </Grid>
        <Grid item>
          {!hideAddHolidayClickHandler && <div className={classes.addButtonContainer}>
            <Button size="small" onClick={(e) => addHolidayClickHandler(null, null, selectedYear, selectedMonth)} classes={{ root: classes.addButtonRoot }}>
              <AddIcon htmlColor="#ffffff" />
            </Button>
          </div>}
        </Grid>
      </Grid>
      <Grid item container className={classes.monthsContainer}>
        {months.map((month, index) => {
          return (
            <Grid key={index} item xs={4} sm={2} container justify="center" alignItems="center"
              className={
                `${screensSmAndUp
                  ?
                  index < 6 ? classes.topMonthRow : classes.bottomMonthRow
                  :
                  index > 2 && index < 6 ? classes.xsScreenTopMiddleMonth
                    :
                    index > 5 && index < 9 ? classes.xsScreenBottomMiddleMonth
                      : ''}`} >

              <IconButton
                className={`${selectedMonth === month.id ? classes.activeMonthClass1 : ''} ${classes.month}`}
                onClick={() => onMonthChange(month.id)} 
                id={`${props?.id}${month.id}${index}`}
                >
                <Typography variant="body1" className={`${selectedMonth === month.id ? classes.activeMonthClass2 : ''}`}>
                  {month.monthShortName}
                </Typography>
              </IconButton>
            </Grid>
          )
        })}
      </Grid>
    </div >
  )
}

export default (Calendar)

Calendar.propTypes = {
  /**Sets the selected month as active. */
  selectedMonth: PropTypes.number,
  /**Callback for month change. */
  onMonthChange: PropTypes.func,
  /**Sets the selected year as active. */
  selectedYear: PropTypes.number,
  /**Callback for previous year click. */
  onPreviousYearClickHandler: PropTypes.func,
  /**Callback for next year click. */
  onNextYearClickHandler: PropTypes.func,
  /**Callback for add holiday click. */
  addHolidayClickHandler: PropTypes.func,
}
