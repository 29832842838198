import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { routes } from "../../router/routes";

import { withNavBars } from "./../../HOCs";
import Masters from "./masters";
import Users from "./users";
import Tax from "./tax";
import Configuration from "./configuration";

class Administration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updateNavBarProps: true,
        };
    }

    render() {
        const { match } = this.props;

        return (
            <>
                {/* Masters */}
                {match.path === routes.administration_masters && <Masters />}
                {match.path === routes.administration_users && <Users />}
                {match.path === routes.administation_tax && <Tax/>}
                {match.path === routes.administation_configuration && <Configuration />}

            </>
        );
    }
}

Administration.propTypes = {
    title: PropTypes.string,
    withBackButton: PropTypes.bool,
    navBarArr: PropTypes.array,
};

Administration.defaultProps = {
    title: "Administration",
};

export default withRouter(withNavBars(Administration));

