import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Button } from "@material-ui/core";

export const InternatlServerError = (props) => {
  const { message, onRetry, retryBtnName, hideRetryButton, top } = props;

  return (
    <div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ marginTop: top }}
      >
        <Grid item>
          <Typography variant="h6" align="center">
            {message}
          </Typography>
        </Grid>
        {!hideRetryButton && (
          <Grid item>
            <Button
              style={{ marginTop: 16, textAlign: "center" }}
              variant={"outlined"}
              onClick={onRetry}
            >
              {retryBtnName}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

InternatlServerError.propTypes = {
  message: PropTypes.string,
  onRetry: PropTypes.func.isRequired,
  retryBtnName: PropTypes.string,
  top: PropTypes.string,
};

InternatlServerError.defaultProps = {
  message: "Something went wrong!",
  retryBtnName: "Try Again",
  top: "30vh",
  hideRetryButton: false,
};
