import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import HeaderWithSwitch from "../../../../components/managedAvailability/addEditAvailability/headerWithSwitch";
import TimeSlot from "../../../../components/managedAvailability/addEditAvailability/timeSlot";
// import { options } from '../../../../utils'
import NonOperationalDays from "../../../../components/nonOperationalDays";
import { v4 as uuidv4 } from "uuid";
import { alertProps, options, availableWeekDays, scrollToTop } from "../../../../utils";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { HolidayDrawer } from "./holidayDrawer";
// import { FormatColorResetRounded } from "@material-ui/icons";
import moment from "moment";
import { connect } from "react-redux";
import { actions } from "tanyacare-middleware";
import { withRouter } from "react-router-dom";
import { routes } from "../../../../router/routes";
import { LoadingSection, InternatlServerError } from "../../../../components";

// const data = {
//   // available247: true,
//   available247: false,
//   // operationalHours: [],
//   operationalHours: [
//     {
//       isAllDayChecked: false,
//       days: ["monday", "tuesday"],
//       timeSlot: ["2021-06-18T03:30:00.000Z", "2021-06-18T10:30:00.000Z"],
//       timeZone: "MYT"
//     },
//     {
//       isAllDayChecked: false,
//       days: ["wednesday", "thursday"],
//       timeSlot: ["2021-06-18T05:30:00.000Z", "2021-06-18T10:30:00.000Z"],
//       timeZone: "IST"
//     }
//   ],
//   holidays: {
//     2021: {
//       1: [
//         {
//           id: uuidv4(),
//           title: "Deepavali",
//           occurs_on: new Date(`18 January 2021`)
//         },
//         {
//           id: uuidv4(),
//           title: "Deepavali2",
//           occurs_on: new Date(`18 January 2021`)
//         },
//         {
//           id: uuidv4(),
//           title: "Deepavali3",
//           occurs_on: new Date(`19 January 2021`)
//         },
//         {
//           id: uuidv4(),
//           title: "Deepavali4",
//           occurs_on: new Date(`20 January 2021`)
//         },
//         {
//           id: uuidv4(),
//           title: "Deepavali5",
//           occurs_on: new Date(`21 January 2021`)
//         },
//         {
//           id: uuidv4(),
//           title: "Deepavali6",
//           occurs_on: new Date(`22 January 2021`)
//         }
//       ],
//       2: [
//         {
//           id: uuidv4(),
//           title: "Deepavali",
//           occurs_on: new Date(`15 February 2021`)
//         }
//       ],
//       4: [
//         {
//           id: uuidv4(),
//           title: "Deepavali",
//           occurs_on: new Date(`10 April 2021`)
//         }
//       ]
//     },
//     2022: {
//       1: [
//         {
//           id: uuidv4(),
//           title: "Deepavali",
//           occurs_on: new Date(`18 January 2022`)
//         }
//       ],
//       2: [
//         {
//           id: uuidv4(),
//           title: "Deepavali",
//           occurs_on: new Date(`15 February 2022`)
//         }
//       ],
//       4: [
//         {
//           id: uuidv4(),
//           title: "Deepavali",
//           occurs_on: new Date(`10 April 2022`)
//         }
//       ]
//     }
//   }
// };

class AddEditAvailability extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      available247: false,
      availableWeekDays: availableWeekDays,
      slots: [
        {
          id: uuidv4(),
          isAllDayChecked: false,
          days: [],
          timeSlot: [null, null],
          timeZone: null
        }
      ],
      opError: [],

      // Holiday States
      selectedMonth: parseInt(moment(Date.now()).format("M")),
      selectedYear: parseInt(moment(Date.now()).format("YYYY")),
      events: [],
      holidayCalendar: {},
    };
  }

  componentDidMount() {
    if (this.props.isEdit) {
      this.props.GET_OPERATIONAL_HOURS({ providerId: this.props?.match?.params?.id })
      this.prefillFormData();
    }
    scrollToTop();
  }

  shouldComponentUpdate(nextProps) {
    // Create Operational Hours
    const { createOperationalHours } = nextProps;
    const oldCreateOperationalHours = this.props.createOperationalHours;

    if (oldCreateOperationalHours?.loading !== createOperationalHours?.loading) {
      if (!createOperationalHours?.loading && !createOperationalHours?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Provider Availability Added successfully!",
          severity: alertProps.severity.success
        });
        this.props.history.push(routes.manage_availability);
      } else if (createOperationalHours?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: createOperationalHours?.message
            ? createOperationalHours?.message
            : "Failed to add provider availability!",
          severity: alertProps.severity.error
        });
      }
    }

    // Update Operational Hours
    const { updateOperationalHours } = nextProps;
    const oldUpdateOperationalHours = this.props.updateOperationalHours;

    if (oldUpdateOperationalHours?.loading !== updateOperationalHours?.loading) {
      if (!updateOperationalHours?.loading && !updateOperationalHours?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Provider Availability Updated successfully!",
          severity: alertProps.severity.success
        });
        this.props.history.push(routes.manage_availability);
      } else if (updateOperationalHours?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: updateOperationalHours?.message
            ? updateOperationalHours?.message
            : "Failed to update provider availability!",
          severity: alertProps.severity.error
        });
      }
    }

    return true;
  }

  prefillFormData = () => {
    // const { data } = this.props?.getOperationalHours
    const data = JSON.parse(JSON.stringify(this.props?.getOperationalHours?.data))
    const opHours = data?.operationalHours?.operationalHours 

    const getMyEventsArray = (holidays={}) => {
      let events = []
      // Deconstructing to build and set holiday json to events array
      Object.entries(holidays).map(entry => {
        Object.values(entry[1]).map(day => {
          events = [...events, ...day]
          return day
        })
        return entry
      })
      return events
    }

    if (opHours?.available247) {
      this.setState({
        available247: opHours?.available247,
        holidayCalendar: data?.nonOperationalHours,
        events: getMyEventsArray(data?.nonOperationalHours)
      });
    } else {
      const getAvailableWeekDays = (operationalHours) => {
        
        if (Array.isArray(operationalHours)) {
          if (operationalHours?.length > 0) {
            
            let currentAvailable = JSON.parse(JSON.stringify(availableWeekDays));
            let usedDays = operationalHours.map((slot) => slot?.days)?.flat();
            usedDays.forEach((day) => (currentAvailable[day] = false));
            return currentAvailable;
          } else {
            return availableWeekDays;
          }
        } else {
          return availableWeekDays;
        }
      };
      
      

      const payload = {
        available247: opHours?.available247,
        slots:
          Array.isArray(opHours?.operationalHours) &&
          opHours?.operationalHours?.length > 0
            ? opHours?.operationalHours
            : this.state.slots,
        availableWeekDays: getAvailableWeekDays(opHours?.operationalHours),
        holidayCalendar: data?.nonOperationalHours, //Need to change this key
        events: getMyEventsArray(data?.nonOperationalHours)
      };
      this.setState(payload);
    }
  };

  // Handlers
  handleSwithIconToggle(event) {
    this.setState({
      available247: event.target.checked
    });
  }

  // isAllDayCheckedHandler
  handleAvailableAllDayChange(event, mapIndex) {
    // persist function is needed to retrieve the target
    event.persist();
    let { slots, availableWeekDays } = this.state;

    // Setting allDayAvailable
    if (event.target.checked === false) {
      slots[mapIndex].days = [];
      for (const [key] of Object.entries(availableWeekDays)) {
        availableWeekDays = { ...availableWeekDays, [key]: true };
      }
    } else {
      const checkAnyOtherSlotsWithDays = () => {
        return slots[mapIndex].days?.length ===
        Object.values(availableWeekDays)?.filter((day) => !day)?.length ? false : true
      };

      // Check if there are any other slots than the current one and if any days selected in those slots
      if (slots?.length > 1 && checkAnyOtherSlotsWithDays()) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Remove other slots to set available all day",
          severity: alertProps.severity.warning
        });
        return;
      }

      slots[mapIndex].days = options.daysOfWeek.map((day) => day.value);
      // availableWeekDays = Object.keys(availableWeekDays).map(day => ({[day]: false}))
      for (const [key] of Object.entries(availableWeekDays)) {
        availableWeekDays = { ...availableWeekDays, [key]: false };
      }
    }
    slots[mapIndex].isAllDayChecked = event.target.checked;

    this.setState(
      {
        slots: slots,
        availableWeekDays: availableWeekDays
      },
      () => console.log(this.state.slots)
    );
  }

  // daysHandler
  handleDaysChange(event, newDays, mapIndex) {
    let { slots } = this.state;

    // Check if days exists in master state of 7 days. Then proceed with updating state
    // 1. Adding/Toggling extra day
    if (newDays?.length > slots[mapIndex].days?.length) {
      let { availableWeekDays } = this.state;
      // Block the newly added days from the resource
      newDays.forEach((day) => {
        availableWeekDays[day] = false;
      });

      slots[mapIndex].days = [...newDays];
      if (newDays?.length === 7) {
        slots[mapIndex].isAllDayChecked = true;
      }
      this.setState({
        slots: slots,
        availableWeekDays: availableWeekDays
      });
    }
    // 2. Removing a day
    else if (newDays?.length < slots[mapIndex].days?.length) {
      let { availableWeekDays } = this.state;
      let removedDay = slots[mapIndex].days?.filter(
        (day) => !newDays.includes(day)
      );
      // Unblock the removed days from the resource
      removedDay.forEach((day) => {
        availableWeekDays[day] = true;
      });

      slots[mapIndex].days = [...newDays];
      this.setState({
        slots: slots,
        availableWeekDays: availableWeekDays
      });
    }
  }

  // timeSlotHandler
  handleTimeSlotChange() { }

  // timeZonehandler
  handleTimeZoneChange(zone, mapIndex) {
    let { slots } = this.state;
    if (Array.isArray(slots)) {
      slots[mapIndex].timeZone = zone;
      this.setState({
        slots: slots
      });
    }
  }

  addSlotHandler = (event, mapIndex) => {
    this.setState((prevState) => ({
      slots: [
        ...prevState.slots,
        {
          id: uuidv4(),
          isAllDayChecked: false,
          days: [],
          timeSlot: [null, null],
          timeZone: ""
        }
      ]
    }));
  };

  removeSlotHandler(mapIndex) {
    let { slots } = this.state;

    // Before removing the slot from the array. Do the cleanup first.
    let { availableWeekDays } = this.state;
    slots[mapIndex].days.forEach((day) => {
      availableWeekDays[day] = true;
    });

    // Now, remove the current slot from the array.
    slots.splice(mapIndex, 1);

    this.setState({
      slots: slots,
      availableWeekDays: availableWeekDays
    });
  }

  // Handle Time slot change (using seperate fields for time slots)
  timeSlotHandler = (newTime, mapIndex, index) => {
    let { slots } = this.state;
    if (Array.isArray(slots)) {
      slots[mapIndex].timeSlot[index] = newTime;
      this.setState({
        slots: slots
      });
    }
  };


  handleValidation = () => {
    // Needed to return to avoid error Checking
    // If available247 is true, then don't validate other fields. Just return true.
    if(this.state.available247){
      return true
    }
    const { slots } = this.state
    let globalError = false
    let opError = Array.from(new Array(slots?.length))
    opError = opError.map((slot, mapIndex) => {
      let thisError = {
        toggleGroupError: {
          error: false,
          message: ""
        },
        fromTimeError: {
          error: false,
          message: ""
        },
        toTimeError: {
          error: false,
          message: ""
        },
        timeZoneError: {
          error: false,
          message: ""
        },
      }

      // Checking Toggle group error
      if (slots?.[mapIndex]?.days?.length === 0) {
        thisError["toggleGroupError"] = {
          error: true,
          message: "Please choose atleast one operating day."
        }
        globalError = true
      }

      if(slots?.[mapIndex]?.timeSlot?.[0] && slots?.[mapIndex]?.timeSlot?.[1]){
        let fromTime = moment(slots?.[mapIndex]?.timeSlot?.[0])
        let toTime = moment(slots?.[mapIndex]?.timeSlot?.[1])

        if(toTime.diff(fromTime, 'minutes') < 30){
          thisError["fromTimeError"] = {
            error: true,
            message: "Start Time should have atleast 30 mins difference."
          };
          thisError["toTimeError"] = {
            error: true,
            message: "End Time should have atleast 30 mins difference."
          };
          globalError = true;
        }
      }

      // Checking From time error
      if (slots?.[mapIndex]?.timeSlot?.[0] === null) {
        thisError["fromTimeError"] = {
          error: true,
          message: "Please choose start time."
        }
        globalError = true
      }

      // Checking To time error
      if (slots?.[mapIndex]?.timeSlot?.[1] === null) {
        thisError["toTimeError"] = {
          error: true,
          message: "Please choose end time."
        }
        globalError = true
      }

      // Checking time Zone error
      if (slots?.[mapIndex]?.timeZone === null) {
        thisError["timeZoneError"] = {
          error: true,
          message: "Please choose a time zone."
        }
        globalError = true
      }

      return thisError
    })

    if (!globalError) {
      if (!this.state.available247 && this.state.slots?.length === 0) {
        globalError = true
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Please provide a time slot or select available 24/7",
          severity: alertProps.severity.error
        });
      }
    }

    this.setState({
      opError: opError
    })

    // Validation Succeeded returns true
    // Validation Failed returns false
    return !globalError
  }

  handleOperationaHoursSubmit = () => {
    debugger
    if (this.handleValidation()) {
      if (this.props.isEdit) {
        let payload = {
          available247: this.state.available247,
          operationalHours: this.state.slots,
          providerId: this.props?.authdata?.user_id,
          events: this.state.events?.map((event) => {
            // removing localId before sending payload
            let { localId=0, ...rest } = event
            console.log(localId)
            return {...rest}
          }) ?? []
        };
        this.props.UPDATE_OPERATIONAL_HOURS(payload);
      } else {
        let payload = {
          available247: this.state.available247,
          operationalHours: this.state.slots,
          providerId: this.props?.authdata?.user_id,
          events: this.state.events?.map((event) => {
            // removing localId before sending payload
            let { localId=0, ...rest } = event
            console.log(localId)
            return {...rest}
          }) ?? []
        };
        this.props.CREATE_OPERATIONAL_HOURS(payload);
      }
    } else {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please clear field errors.",
        severity: alertProps.severity.error
      });
    }
  };

  //   HOliday handlers
  holidayHandler = (eventId, localId, selectedYear, selectedMonth) => {
    
    // If eventId exists then, it is edit
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <HolidayDrawer
          isDBEventEdit={eventId ? true : false}
          eventId={eventId ? eventId : localId}
          events={this.state.events}
          onHolidayAdded={this.addEditHoliday}
          onCancelButtonClicked={this.handleDrawerClose}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />
      )
    });
  };

  formHolidayJson = (events) => {
    let holidayCalendar = {}
    debugger
    events.map(event => {
      let { occurs_on } = event
      let eventYear = moment(occurs_on).isValid() ? moment(occurs_on)?.get('year') : null
      let eventMonth = moment(occurs_on).isValid() ? moment(occurs_on)?.get('month') + 1 : null  //Setting +1 because moment returns month value from 0 and we have set month value from 1
      if(eventYear && eventMonth){
        let selectedMonthEvents = []
        if(holidayCalendar?.[eventYear] && holidayCalendar?.[eventYear]?.[eventMonth] && Array.isArray(holidayCalendar?.[eventYear]?.[eventMonth])){
          selectedMonthEvents = [...holidayCalendar[eventYear][eventMonth]]
        }
        if(!holidayCalendar[eventYear]){
          holidayCalendar[eventYear] = {}
        }
        holidayCalendar[eventYear][eventMonth] = [...selectedMonthEvents, {...event}] 
      }
      return event
    })

    return holidayCalendar
  }

  addEditHoliday = (isError, data, url, eventId, isDBEventEdit) => {
    debugger
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      let { events } = this.state
      let myCurrentEvent = {}
      // Doing these below with dates because of below mention issue
      // https://stackoverflow.com/questions/28344408/why-does-js-subtract-a-day-from-a-date-object-with-a-certain-format
      var date = String(data.occurs_on.getDate())?.length > 1 ? data.occurs_on.getDate() : "0" + String(data.occurs_on.getDate())  
      var month = data.occurs_on.getMonth() + 1
      month = String(month)?.length > 1 ? month : "0" + String(month)
      var year = data.occurs_on.getFullYear()
      debugger
      var fulldate = year + '-' + month + '-' + date
      var D = new Date(fulldate+'T00:00:00Z');
      D = D?.toISOString()
      // Checking if eventId exists in events array, if exists then the method is edit else add.
      if(!eventId){
        // create a new event and append to events array.
        let event = {
          holiday_title: data?.holiday_title,
          occurs_on: data?.occurs_on ? D : "",
          localId: uuidv4()
        }
        events = [...events, event]
        myCurrentEvent = {...event}
      } else {
        // events array should be greater than 0 to edit a holiday
        if(events?.length > 0){
          // Taking the event edited using eventId and updating holiday_title & occurs_on.
          let editedEvent = events?.filter(event =>(isDBEventEdit ? event?.id === eventId : event?.localId === eventId))?.[0]
          editedEvent = {
            ...editedEvent,
            holiday_title: data?.holiday_title,
            occurs_on: data?.occurs_on ? D : ""
          }

          // Now removing the original unedited event from events array & appending the newly edited event.
          events = [...events?.filter(event => (isDBEventEdit ? event?.id !== eventId : event?.localId !== eventId))]
          events = [...events, editedEvent]
          myCurrentEvent = {...editedEvent}
        }
      }

      // Create a json from this events array to display the holidays in the calendar
      let holidayCalendar = this.formHolidayJson(events)
      
      this.setState({
        events: events,
        holidayCalendar: holidayCalendar,

        // Setting below states to change view to added holiday
        selectedMonth: parseInt(moment(myCurrentEvent?.occurs_on).format("M")),
        selectedYear: parseInt(moment(myCurrentEvent?.occurs_on).format("YYYY")),
      }, () => {
        console.log(this.state)
        
        this.handleDrawerClose()
      })
    }
  };

  handleDrawerClose = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    });
  };

  deleteHoliday = (deleteEventId, deleteLocalId) => {
    let { events } = this.state
    let eventId = deleteEventId ? deleteEventId : deleteLocalId
    let isDBEventEdit = deleteEventId ? true : false
    
    events = events?.filter(event => (isDBEventEdit ? event?.id !== eventId : event?.localId !== eventId))

    // Create a json from this events array to display the holidays in the calendar
    let holidayCalendar = this.formHolidayJson(events)

    this.setState({
      events: events,
      holidayCalendar: holidayCalendar,
    })
  }


  onMonthChange = (month) => {
    console.log("Month function");
    console.log(month);
    console.log("Selected Month is", month);
    this.setState({
      selectedMonth: month
    });
  };

  // NOTE: Callbacks added for testing purposes, Remove it, if needed
  onPreviousYearClickHandler = () => {
    console.log("Previous");
    console.log(this.state.selectedYear)
    
    let _current_year = new Date().getFullYear()
    if(this.state.selectedYear > _current_year){
      this.setState((prevState, prevProps) => ({
        selectedYear: prevState.selectedYear - 1
      }));
    }
  };

  onNextYearClickHandler = () => {
    console.log("Next");
    this.setState((prevState, prevProps) => ({
      selectedYear: prevState.selectedYear + 1
    }));
  };



  render() {
    // const { isEdit=false } = this.props
    let { data, loading, error } = this.props?.getOperationalHours;

    return (
      <div style={{ padding: 20 }}>
        {error && !loading && <InternatlServerError /> }
        {loading && !error && <LoadingSection message="Fetching data..." />}
        {!error && !loading && data && Object.entries(data).length > 0 && <div style={{ padding: 20, background: "white" }}>
          <Grid container>
            <Grid item xs={12}>
              <HeaderWithSwitch
                checked={this.state.available247}
                handleSwithIconToggle={(e) => this.handleSwithIconToggle(e)}
                headerTitle={"Operational Hours"}
                switchLabel={"Available 24/7"}
                responsive
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                margin: "12px 0px",
                borderBottom: `0.5px dashed rgba(146, 151, 165, 0.5)`
              }}
            ></Grid>

            {this.state.slots?.length === 0 ? (
              <Grid item xs={12} sm={5} md={5} lg={4} xl={4}>
                <Button
                  disabled={this.state.available247}
                  variant="contained"
                  color="primary"
                  onClick={this.addSlotHandler}
                  style={{ margin: "20px 0px" }}
                >
                  + Add Time Slot
                </Button>
              </Grid>
            ) : null}

            {/* TimeSlot */}
            {this.state.slots.map((slot, mapIndex) => {
              return (
                <Grid item xs={12}>
                  {/* { console.log(this.state.slots[mapIndex])} */}
                  <TimeSlot
                    availableWeekDays={this.state.availableWeekDays}
                    availableAllDay={
                      this.state.slots[mapIndex]?.isAllDayChecked
                    }
                    handleAvailableAllDayChange={(e) =>
                      this.handleAvailableAllDayChange(e, mapIndex)
                    }
                    daysGroup={this.state.slots[mapIndex]?.days}
                    handleDaysChange={(e, newDays) =>
                      this.handleDaysChange(e, newDays, mapIndex)
                    }
                    // timeSlot={this.state.timeSlot}
                    // handleTimeSlotChange={() => this.handleTimeSlotChange()}
                    // timeZone={this.state.timeZone}
                    timeZone={this.state.slots[mapIndex]?.timeZone}
                    handleTimeZoneChange={(e) =>
                      this.handleTimeZoneChange(e, mapIndex)
                    }
                    disableTimeZone={this.state.available247}
                    showAddSlotButton={
                      mapIndex === this.state.slots.length - 1 ? true : false
                    }
                    disableAddSlotButton={
                      this.state.available247
                        ? true
                        : typeof this.state.availableWeekDays === "object" &&
                          Object.values(this.state.availableWeekDays).some(
                            (day) => day
                          )
                          ? false
                          : true
                    }
                    // hideRemoveButton={mapIndex === 0 ? true : false}
                    hideRemoveButton={false}
                    addSlotHandler={(e) => this.addSlotHandler(e, mapIndex)}
                    removeSlotHandler={() => this.removeSlotHandler(mapIndex)}
                    timeSlotTitle={`Time Slot ${mapIndex + 1}`}
                    isDisabled={this.state.available247}
                    disableTime={this.state.slots[mapIndex]?.isAllDayChecked}
                    useSeperateFieldsForTimeRange={true}
                    disableFromTimeField={this.state.available247}
                    fromTimeLabel={"START TIME"}
                    fromTimeValue={this.state.slots[mapIndex]?.timeSlot?.[0]}
                    onFromTimeChange={(e) =>
                      this.timeSlotHandler(e, mapIndex, 0)
                    }
                    disableFromTimePast={null}
                    fromTimeMin={null}
                    disableToTimeField={this.state.available247}
                    toTimeLabel={"END TIME"}
                    toTimeValue={this.state.slots[mapIndex]?.timeSlot?.[1]}
                    onToTimeChange={(e) => this.timeSlotHandler(e, mapIndex, 1)}
                    disableToTimePast={null}
                    toTimeMin={this.state.slots[mapIndex]?.timeSlot?.[0]}
                    toggleGroupError={
                      this.state.opError[mapIndex]?.toggleGroupError
                    }
                    fromTimeError={this.state.opError[mapIndex]?.fromTimeError}
                    toTimeError={this.state.opError[mapIndex]?.toTimeError}
                    timeZoneError={this.state.opError[mapIndex]?.timeZoneError}
                  />
                </Grid>
              );
            })}

            <Grid item xs={12}>
              <Typography style={{ fontSize: "1.15rem", margin: "20px 0px" }}>
                Set Holidays
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <NonOperationalDays
                handleChange={() => { }}
                holidays={this.state.holidayCalendar}
                addHolidayClickHandler={this.holidayHandler}
                editHolidayClickHandler={(eventId, localId) => this.holidayHandler(eventId, localId)}
                deleteHoliday={(eventId, localId) => this.deleteHoliday(eventId, localId)}
                selectedMonth={this.state.selectedMonth}
                selectedYear={this.state.selectedYear}
                onMonthChange={this.onMonthChange}
                onPreviousYearClickHandler={this.onPreviousYearClickHandler}
                onNextYearClickHandler={this.onNextYearClickHandler}
              />
            </Grid>

            <Grid item xs={12} container>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "40px 0px 20px"
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ width: 100, marginRight: 24, padding: "5px 15px" }}
                  onClick={() => this.props.history.push(routes.manage_availability)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 100, padding: "5px 15px" }}
                  onClick={this.handleOperationaHoursSubmit}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authdata: state?.authState?.loginAuth?.data,
  getOperationalHours: state?.operational_hours?.get_operational_hours,
  createOperationalHours: state?.operational_hours?.add_operational_hours,
  updateOperationalHours: state?.operational_hours?.update_operational_hours
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(AddEditAvailability)));
