import React, { Component } from 'react'
import { Divider, Grid, Hidden } from '@material-ui/core'
import Calendar from './calendar'
import Holidays from './holidays'
import moment from 'moment'
import PropTypes from 'prop-types'

class NonOperationalDays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMonth: parseInt(moment(Date.now()).format('M')),
      selectedYear: parseInt(moment(Date.now()).format('YYYY')),
    }
  }


  render() {
    const { selectedMonth, selectedYear } = this.props
    return (
      <div>
        <Grid container direction={"row"} style={{ boxShadow: '0px 0.5px 2px 1px #e0e0e0d6', borderRadius: 8 }}>
          {/* NOTE: Add some padding to this div, inner border space */}
          {/* <div style={{ padding: 16 }}> */}

          {/* Calendar */}
          <Grid item container xs={12} sm>
            <Calendar
              selectedMonth={selectedMonth}
              onMonthChange={this.props.onMonthChange}
              selectedYear={selectedYear}
              onPreviousYearClickHandler={this.props.onPreviousYearClickHandler}
              onNextYearClickHandler={this.props.onNextYearClickHandler}
              addHolidayClickHandler={this.props.addHolidayClickHandler}
              hideAddHolidayClickHandler={this.props.hideAddHolidayClickHandler}
              previousButtonId={this.props.previousButtonId}
              nextButtonId={this.props.nextButtonId}
              id={this?.props?.id}
            />
          </Grid>

          {/* Divider Starts */}
          <Hidden xsDown>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
          </Hidden>

          <Hidden smUp>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Hidden>
          {/* Divider Ends */}

          {/* Holidays */}
          <Grid item container xs={12} sm={4}>
            <Holidays
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              holidays={this.props.holidays} 
              editHoliday={this.props.editHolidayClickHandler}
              deleteHoliday={this.props.deleteHoliday}
              hideMoreActions={this.props.hideMoreActions}
              />
          </Grid>
          {/* </div> */}
        </Grid>
      </div >
    )
  }
}

export default NonOperationalDays

NonOperationalDays.propTypes = {
  /**Callback executed on Add holiday button click. Add holiday click handler */
  addHolidayClickHandler: PropTypes.func
}