export const styles = (theme) => ({
    root: {
      padding: 20,
      // height: "100vh",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        padding: 10,
      },
    },
    rootCard: {
      margin: "auto",
      width: "100%",
      boxShadow: "0 25px 50px 0 rgba(0, 0, 0, 0.08) !important",
      padding: 20,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    mainContent: {
      display: "flex",
      alignItems: "center",
    },
    svg_icon: {
      fontSize: "6rem"
    },
  
    mainHeading: {
      [theme.breakpoints.down("sm")]: {
        marginTop: 4
      },
      marginTop: 68,
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    subHeading: {
      
      fontSize: "1rem",
      color: theme.palette.secondary.main,
    },
    formGrid: {
      textAlign: "center",
    },
    formHeader: {
      textAlign: "center",
      marginBottom: 30,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 40,
      },
    },
    // successIcon: {
    //   fill: theme.palette.success.light,
    // },
    // errorIcon: {
    //   fill: theme.palette.error.light,
    // },
    userHelpGrid: {
      margin: "10px 0px",
    },
    sendCodeButton: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: 10,
      },
      width: "100%",
      marginTop: 40,
      marginBottom: 10,
      padding: "8px",
      background: `${theme.palette.primary.main}`,
      transition: "0.5s",
      "& button": {
        padding: 8,
      },
      "&:hover": {
        transition: "0.5s",
        background: `${theme.palette.primary.main}`,
        opacity: 0.9,
      },
    },
    resetButton: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: 22,
      },
      width: "100%",
      marginTop: 20,
      marginBottom: 44,
      background: `${theme.palette.primary.main}`,
      transition: "0.5s",
      "& button": {
        padding: 8,
      },
      "&:hover": {
        transition: "0.5s",
        background: `${theme.palette.primary.main}`,
        opacity: 0.9,
      },
      // marginTop: 32,
      padding: 8,
    },
    // errorMessage: {
    //   color: `${theme.palette.error.main}`,
    // },
    validationListContainer: {
      listStyle: 'none',
      textAlign: 'left',
      paddingLeft: 0,
    },
    validationListItemStartIcon: {
      display: "inline",
      margin: '0px 3px',
    },

    wrapper: {
      height: "100vh",
      background: "white"
    },
    /* root: {
      height: "100%"
    }, */
    hero: {
      height: "85%"
    },
    rightPaneWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    rightPane: {
      width: "100%"
    },
    // mainHeading: {
    //   [theme.breakpoints.down("sm")]: {
    //     marginTop: 4
    //   },
    //   // marginTop: 68,
    //   fontSize: "1.5rem",
    //   fontWeight: "bold"
    // },
    /* subHeading: {
      fontSize: "1rem",
      color: theme.palette.secondary.main
    }, */
    passwordField: {
      width: "100%",
      marginTop: 32
    },
    //   passwordField: {
    //     width: "100%",
    //     marginTop: 24
    //   },
    errorMessage: {
      color: `${theme.palette.error.main}`
    },
    /* resetButton: {
      marginTop: 32,
      padding: 8,
      "&:hover": {
        transition: "0.5s",
        background: `${theme.palette.primary.main}`,
        opacity: 0.9
      }
    }, */
    // validationListContainer: {
    //   listStyle: "none",
    //   textAlign: "left",
    //   paddingLeft: 0
    // },
    /* validationListItemStartIcon: {
      display: "inline",
      margin: "0px 3px"
    }, */
    
    // Custom Header Style
    header: {
      height: "10%",
      // height: 44,
      [theme.breakpoints.down("sm")]: {
        height: 60,
        textAlign: "center",
        marginTop: 32
      }
    },
    
  
  });
  