import React from "react";
import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import moment from "moment";
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  customListItemIcon: {
    minWidth: 36
  },
  strike: {
    textDecoration: "line-through"
  },
  customListItem: {
    paddingRight: 92
  }
}));

export default function TodoItem({
  todos,
  handleTaskToggle,
  handleEdit,
  handleDelete,
  updateTodoStatus = { loading: false }
}) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      {todos?.length > 0 && todos?.map((item, index) => (
        <ListItem
          key={index}
          classes={{ secondaryAction: classes.customListItem }}
        >
          <ListItemIcon classes={{ root: classes.customListItemIcon }}>
            <Checkbox
              edge="start"
              id={`checkTodo${item?.id ? item?.id : index}`}
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled htmlColor={"#31B198"} />}
              checked={item.is_completed}
              tabIndex={-1}
              disableRipple
              onChange={() => handleTaskToggle(item)}
              // inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          {/* secondary={`${item.completed ? '' : "July 23, Mon 09:00 AM"}` */}
          <ListItemText
            primary={item.title}
            secondary={`${
              item.is_completed
                ? ""
                : (item?.scheduled_time ? moment(item?.scheduled_time).format("MMM D, ddd, hh:mm A") : "")
            }`}
            primaryTypographyProps={{
              style: {
                textDecoration: `${
                  item.is_completed ? "line-through" : "none"
                }`,
                textOverflow: "ellipsis",
                whiteSpace: "noWrap",
                overflow: "hidden"
              }
            }}
          />

          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              style={{ padding: 12 }}
              onClick={() => handleEdit(item)}
              id={`editTodo${item?.id ? item?.id : index}`}
            >
              <CreateOutlinedIcon fontSize="small" htmlColor={theme.palette.ternary.main} />
            </IconButton>
            <IconButton
              edge="end"
              style={{ marginLeft: 8, padding: 12 }}
              onClick={() => handleDelete(item)}
              id={`deleteTodo${item?.id ? item?.id : index}`}
            >
              <DeleteOutlineRoundedIcon fontSize="small" htmlColor={theme.palette.error.light} />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
      {todos?.length === 0 && <Typography align="center" variant="body1" color="textSecondary">You are yet to add ToDo's</Typography>}
    </>
  );
}
