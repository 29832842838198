import React from "react";
import { makeStyles, Typography, useTheme } from "@material-ui/core";
import PropTypes from 'prop-types';

const statusStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
    },
    dot: {
        height: "8px",
        width: "8px",
        borderRadius: "50%",
        display: "inline-block",
    },
    activeDot: {
        backgroundColor: theme.palette.success.main,
    },
    inactiveDot: {
        backgroundColor: theme.palette.error.main,
    },
    invitedDot: {
        backgroundColor: theme.palette.warning.main,
    },
    active: {
        color: theme.palette.success.main,
    },
    inactive: {
        color: theme.palette.error.main,
    },
    invited: {
        color: theme.palette.warning.light,
    },
    status: {
        lineHeight: 1.5,
    },
}));

export function CustomStatus({ active = false, loading }) {
    const classes = statusStyles();
    const theme = useTheme();

    // Handlers
    const getStatusColor = (status) => {
        switch (status) {
            case 1:
                return theme.palette.success.main;
            case 2:
                return theme.palette.error.main;
            default: {
                throw new Error(`Unexpected status: ${status}`);
            }
        }
    };

    // Component Integration
    const activeStatus = active ? 1 : 2
    const statusText = ["ACTIVE", "INACTIVE"];

    return (
        <>
            {loading && <></>}
            {!loading && (
                <div className={classes.root}>
                    <span
                        className={classes.dot}
                        style={{ backgroundColor: getStatusColor(activeStatus) }}
                    ></span>
                    <Typography
                        variant="body2"
                        className={classes.status}
                        style={{ color: getStatusColor(activeStatus) }}
                    >
                        {statusText[activeStatus - 1]}
                    </Typography>
                </div>
            )}
        </>
    );
}

CustomStatus.propTypes = {
    active: PropTypes.bool
}