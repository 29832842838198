import React from "react";
import { Grid, Divider } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CatalogueDetailWrapper } from "./catalogueDetailWrapper";
import { QuickListWrapper } from "./quickListWrapper";
import { useHistory } from "react-router-dom";
import { createTestProps } from '../../utils/common';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 12,
    borderRadius: 16,
    background: "white",
    margin: theme.spacing(0, 0, 1.5, 0),
  },
}));

export function Catalogue({ redirectUrl, loading, ...props }) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  // Breakpoints helper
  const isSmAndDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmOnly = useMediaQuery(theme.breakpoints.only("sm"));
  const isBelow420 = useMediaQuery(theme.breakpoints.down(420));
  const isBetween420And600 = useMediaQuery(theme.breakpoints.between(420, 600));

  // Hanlders
  const handleRedirectUrl = (redirectUrl, catalogue) => {
    history.push(redirectUrl, {
      header: [
        {
          id: catalogue?.id,
          name: catalogue?.name,
          isactive: catalogue?.isactive,
          emailverified: catalogue?.emailVerified,
          category: catalogue?.category,
          profile_pic: catalogue?.profile_pic,
        },
      ],
      footer: [
        {
          name: "",
        },
      ],
    });
  };

  // Style Util
  const getStyleProps = () => {
    if (isSmAndDown) {
      return {
        xs: isSmAndDown ? 12 : null,
        container: isSmAndDown ? true : null,
        spacing: isSmAndDown ? 0 : null,
        justify: isSmAndDown ? "center" : null,
        alignItems: isSmAndDown ? "center" : null,
      };
    } else {
      return {};
    }
  };

  const { catalogues, showProvider, showTrialExpiry } = props;

  return (
    <>
      {catalogues?.map((catalogue, index) => {
        if (!!catalogue) {
          return (
            <div
              key={catalogue?.id}
              className={classes.root}
              // id={`ListOf${showProvider ? 'branches' : "Partners"}${index}`}
              {...createTestProps(`listOf${props?.id ?? ''}`,catalogue?.id)}
              onClick={
                !!redirectUrl
                  ? () =>
                      handleRedirectUrl(redirectUrl + catalogue?.id, catalogue)
                  : null
              }
            >
              <Grid container wrap={isSmAndDown ? "wrap" : "nowrap"}>
                <Grid
                  container
                  item
                  xs={12}
                  md
                  // alignItems={"center"}
                  wrap="nowrap"
                  zeroMinWidth
                >
                  <CatalogueDetailWrapper
                    catalogue={catalogue}
                    loading={loading}
                    showProvider={showProvider}
                    showTrialExpiry={showTrialExpiry}
                  />
                </Grid>
                {isSmAndDown &&
                  (
                    !!catalogue?.orderList &&
                    Array.isArray(catalogue?.orderList) &&
                    catalogue?.orderList?.length > 0
                  ) && (
                    <Grid item xs={12}>
                      <Divider style={{ margin: "12px 0px" }} />
                    </Grid>
                  )}
                <Grid item {...getStyleProps()} style={{ display: "flex", gap: theme.spacing(0.5) }}>
                  <QuickListWrapper
                    orderList={
                      !!catalogue?.orderList &&
                      Array.isArray(catalogue?.orderList)
                        ? catalogue?.orderList
                        : []
                    }
                    loading={loading}
                    // Breakpoint Helpers
                    isSmAndDown={isSmAndDown}
                    isBetween420And600={isBetween420And600}
                    isBelow420={isBelow420}
                    isSmOnly={isSmOnly}
                  />
                </Grid>
              </Grid>
            </div>
          );
        }
        return <></>;
      })}
    </>
  );
}
