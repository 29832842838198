import React from "react";
import PropTypes from "prop-types";

import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Badge,
  Menu,
  // MenuItem,
  // Avatar,
  Tabs,
  Tab,
  // Avatar,
  // InputBase,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import MoreIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";

import { SideNavbar } from "./sideNavbar";
import { drawerWidth, appBarHeight } from "./utils";
import { useHistory, useLocation } from "react-router-dom";
import { NotificationBellIcon } from "../../assets";
// SearchIcon,
// MicIcon

import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
import Notifications from "../notifications";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "tanyacare-middleware";
import { fontStyleClass, user_roles_name } from "../../utils";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { AuthContext } from "../../contexts";
import { localStorageKeys } from "../../utils";
import Profile from "./profile";
import ActivitySummary from "../notifications/ActivitySummary";
import AddEditActivity from "../../screens/availability/calendar/addEditActivity/index";
import { storeTrialPopupState } from "../../utils/constants";
import SubscriptionRequest from "../../screens/dashboard/SubscriptionRequest";

const { LOGOUT_AUTH } = actions;
// import ProfileDrawer from "../profileDrawer";
const { GET_ALL_IN_APP_NOTIFICATIONS, DELETE_IN_APP_NOTIFICATIONS } = actions;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    background: theme.palette.appbar.main,
    height: appBarHeight,
    boxShadow: `0px 0.5px 2px 1px #e0e0e0d6`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  navBars: {
    marginLeft: 32,
    display: "none",
    color: theme.palette.text.primary,
    textTransform: "unset",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  navBarsMobile: {
    display: "flex",
    textTransform: "unset",
    backgroundColor: theme.palette.background.default,
    borderTop: "1px solid rgba(0, 0, 0, 0.04)",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  tabButton: {
    height: appBarHeight - 5 + "px",
    minWidth: 100,
  },
  desktopSearch: {
    width: 250,
    height: 36,
    marginTop: 10,
    marginRight: 8,
    paddingLeft: 12,
    display: "flex",
    borderRadius: 25,
    backgroundColor: "#ffffff",
  },
  desktopSearchIcons: {
    padding: 1,
  },
  desktopSearchInput: {
    width: 190,
  },
  mobileSearchIcon: {
    padding: 1,
  },
  ...fontStyleClass,
}));

export const Component = (props) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const loginData = useSelector((state) => state?.authState?.loginAuth);
  const user_data = useSelector((state) => state?.profile?.info);
  //
  // console.log(user_data);
  // console.log(userData)

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [state, setState] = React.useState({
    notificationCount: 0,
    isNewNotification: true,
    notificationData: [],
    searchText: "",
  });
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const authContext = React.useContext(AuthContext);
  const handleLogout = () => {
    authContext.setAuth({ ...authContext.auth, refresh: false });
    dispatch(LOGOUT_AUTH());
    localStorage.removeItem(localStorageKeys._mGdRe);
    storeTrialPopupState(true);
  };

  const getNotificationsCount = (notifications) => {
    if (notifications)
      return notifications.filter((val) => val.is_read === false).length;
    else return 0;
  };

  const updateNotificationCount = async () => {
    let response = await dispatch(
      GET_ALL_IN_APP_NOTIFICATIONS({
        pageSize: 100,
        pageNo: 0,
      })
    );
    // console.log(getNotificationsCount(response?.payload?.data))
    if (response?.payload?.data?.length > 0) {
      setState({
        ...state,
        notificationCount: getNotificationsCount(response?.payload?.data),
        isNewNotification: false,
        notificationData: response?.payload?.data,
      });
    }
  };

  const deleteNotification = async (id) => {
    console.log(id);
    await dispatch(
      DELETE_IN_APP_NOTIFICATIONS({
        id,
      })
    );
    updateNotificationCount();
    // console.log(getNotificationsCount(response?.payload?.data))
    // if (response?.payload?.data?.length > 0) {
    //   setState({
    //     ...state,
    //     notificationCount: getNotificationsCount(response?.payload?.data),
    //     isNewNotification: false,
    //     notificationData: response?.payload?.data,
    //   });
    // }
  };
  // const getProfileData = async () => {
  //   let res = await dispatch(
  //     GET_LOGIN_USER_INFO(loginData?.data?.user_id)
  //   )
  //   console.log(res?.payload?.data)
  // };

  React.useEffect(() => {
    if (state.isNewNotification) {
      updateNotificationCount();
    }
    updateNotificationCount();
    // eslint-disable-next-line
  }, []);

  // React.useEffect(() => {
  //   getProfileData();
  // }, [state])

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onBackButtonClick = () => {
    if (props.isAvoidGoBack) {
      history.replace(props.backUrl);
    } else {
      history.goBack();
    }
  };

  const { withBackButton, navBarArr } = props;

  // const { notificationCount } = state;

  //Function find the selected tab from the URL
  const giveMeSelectedTab = () => {
    let id = "";
    navBarArr.map((nav) => {
      if (location.pathname === nav.link) {
        id = nav.id;
      }
      return nav;
    });

    return id;
  };

  //On Tab clicked
  const onTabClicked = (link) => {
    history.push(link);
  };

  // On Notifications Icon Clicked

  const closeNotificationDrawer = () => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: false,
    });
  };

  const openNotificationsDrawer = (data) => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      component: (
        <Notifications
          data={state.notificationData}
          openActivityDrawer={(data) => openActivityDrawer(data)}
          handleDrawer={() => closeNotificationDrawer()}
          deleteNotification={(data) => deleteNotification(data)}
          updateNotification={() => updateNotificationCount()}
        />
      ),
    });
  };

  const reload = () => {
    updateNotificationCount();
  };

  const onEditBtnClicked = (data) => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      component: (
        <AddEditActivity
          {...props}
          data={data}
          reload={reload}
          isEdit={true}
          id={data?.InfoId}
        />
      ),
    });
  };

  const openActivityDrawer = (data) => {
    // alert(JSON.stringify(data))

    if (data?.module === "REFUND_REQUEST") {
      props.drawer.setDrawer({
        ...props.drawer,
        open: true,
        component: (
          <SubscriptionRequest
            onBackBtnClicked={""}
            onEditBtnClicked={""}
            reload={""}
            drawer={props?.drawer}
            showServiceProfessional={true}
            hideClient={false}
            data={data?.InfoId}
            isReschedule={false}
            id={data?.InfoId}
            subscriptionId={data?.InfoId}
            changeRequest={() => {}}
            getSpecficData={() => {}}
          />
        )
      })
    } else {
      props.drawer.setDrawer({
        ...props.drawer,
        open: true,
        component: (
          <ActivitySummary
            // onBackBtnClicked={onBackBtnClicked}
            onEditBtnClicked={() => onEditBtnClicked(data)}
            drawer={props.drawer}
            data={data.InfoId}
            reload={reload}
          />
        ),
      });
      history.push('/management/availability/calendar/day')
    }

    updateNotificationCount()
  };

  // const openProfileDrawer = (data) => {
  //   props.drawer.setDrawer({
  //     ...props.drawer,
  //     open: true,
  //     component: <ProfileDrawer />,
  //   });
  // };

  //Renders when more button is clicked
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleLogout}>Logout</MenuItem>

      {/* Notification menu with badge */}
      {/* <MenuItem>
        <IconButton
          aria-label={`show ${notificationCount} new notifications`}
          color="inherit"
          onClick={openNotificationsDrawer}
        >
          <Badge variant="dot" invisible={!state.notificationCount} badgeContent={notificationCount} color="error">
            <NotificationBellIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}

      {/* Profile menu */}
      {/* <MenuItem>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar className={classes.small} />
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}
    </Menu>
  );

  // const updateState = (key, value) => {
  //   setState({ ...state, [key]: value });
  // };

  return (
    <div>
      <AppBar
        color="default"
        elevation={0}
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
          {/* Menu Button to open Drawer */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          {/* Back Button */}
          {withBackButton && (
            <IconButton
              id={"TopNavBackIcon"}
              onClick={onBackButtonClick}
              style={{ marginLeft: -12 }}
            >
              <KeyboardBackspaceIcon htmlColor={"#363a57"} />
            </IconButton>
          )}

          {/* Title */}
          <Typography
            variant="h6"
            noWrap
            style={{ overflow: "unset" }}
            className={classes.mediumFont}
          >
            {props.title}
          </Typography>

          {/* Title Icon */}
          <IconButton
            disabled={!props.titleIcon}
            onClick={props.onTitleIconClicked}
          >
            {props.titleIcon}
          </IconButton>

          {/* Navbars at the Top Navbars in Desktop*/}
          {navBarArr?.length > 0 && (
            <Tabs
              value={giveMeSelectedTab()}
              indicatorColor={"primary"}
              className={classes.navBars}
              selectionFollowsFocus={true}
              scrollButtons="auto"
              variant="scrollable"
            >
              {navBarArr.map((nav, key) => {
                return (
                  <Tab
                    key={key}
                    className={classes.tabButton}
                    value={nav.id}
                    id={nav?.button_id}
                    disableFocusRipple={true}
                    disableRipple={true}
                    label={nav.label}
                    style={{ textTransform: "unset" }}
                    onClick={() => onTabClicked(nav.link)}
                  />
                );
              })}
            </Tabs>
          )}

          {/* To provide the space between the title and notificaiton, profile Icon  */}
          <div className={classes.grow} />

          {/* Visible only in desktop */}
          <div className={classes.sectionDesktop}>
            {/* Universal Search bar */}
            {/* <div className={classes.desktopSearch}>
              <InputBase
                className={classes.desktopSearchInput}
                placeholder="Search"
                value={state.searchStr}
                onChange={(e) => updateState("searchStr", e.target.value)}
              />
              <IconButton>
                <SearchIcon
                  color="secondary"
                  className={classes.desktopSearchIcons}
                />
              </IconButton>
              <IconButton>
                <MicIcon
                  color="secondary"
                  className={classes.desktopSearchIcons}
                />
              </IconButton>
            </div> */}

            {/* Name and role */}
            <div style={{ display: "flex" }}>
              {/* {console.log(loginData?.data)} */}
              <Profile
                loginData={loginData?.data}
                // user_data={() =>
                //   dispatch(GET_LOGIN_USER_INFO(loginData?.data?.user_id))
                // }
              />
              <div style={{ marginRight: 8, textAlign: "left" }}>
                <Typography variant="body2" color="textPrimary" align="left">
                  {/* {loginData?.data?.profile_name ?? "Welcome!"} */}
                  {user_data?.data?.partnerName
                    ? user_data?.data?.partnerName
                    : user_data?.data?.resourceName
                    ? user_data?.data?.resourceName
                    : loginData?.data?.profile_name}
                </Typography>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    align="right"
                  >
                    {user_roles_name[loginData?.data?.user_role]}
                  </Typography>
                  <IconButton
                    style={{ padding: 0 }}
                    ref={anchorRef}
                    onClick={handleToggle}
                    id={"SessionMenuButtonClick"}
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                id={"SessionMenuLogoutButtonClick"}
                                onClick={handleLogout}
                              >
                                Logout
                              </MenuItem>
                              {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                              <MenuItem onClick={handleClose}>
                                My account
                              </MenuItem>
                              <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </div>
            </div>

            {/* Notification Icon with badge */}
            {loginData?.data?.user_role !== "super-admin" ? (
              <IconButton
                aria-label={`show ${state.notificationCount} new notifications`}
                color="inherit"
                onClick={openNotificationsDrawer}
                id={"NotificationIconButtonClick"}
              >
                <Badge
                  // variant="dot"
                  // invisible={!state.notificationCount}
                  badgeContent={state.notificationCount}
                  color="error"
                  overlap="circle"
                >
                  <NotificationBellIcon />
                </Badge>
              </IconButton>
            ) : null}

            {/* Profile Icon with badge */}
            {/* <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              onClick={openProfileDrawer}
            >
              <Avatar className={classes.small} />
            </IconButton> */}
          </div>

          {/* Visible only in mobile */}
          <div className={classes.sectionMobile}>
            {/* <IconButton>
              <SearchIcon
                htmlColor="black"
                className={classes.mobileSearchIcon}
              />
            </IconButton> */}

            {/* More button */}
            <IconButton
              aria-label="show more"
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
        {/* Navbars at the Top Navbars in Mobile*/}
        {navBarArr?.length > 0 && (
          <Tabs
            value={giveMeSelectedTab()}
            indicatorColor={"primary"}
            className={classes.navBarsMobile}
            scrollButtons="auto"
            variant="scrollable"
          >
            {navBarArr.map((nav, key) => {
              return (
                <Tab
                  key={key}
                  value={nav.id}
                  label={nav.label}
                  style={{ textTransform: "unset" }}
                  onClick={() => onTabClicked(nav.link)}
                />
              );
            })}
          </Tabs>
        )}
      </AppBar>

      {/* Side Navbar drawer */}
      <Hidden mdUp implementation="css">
        <SideNavbar
          variant="temporary"
          open={mobileOpen}
          handelClose={handleDrawerToggle}
          {...props.SideNavBarProps}
        />
      </Hidden>

      {/* Renders when the more button is clicked on mobile screen */}
      {renderMobileMenu}
    </div>
  );
};

Component.propTypes = {
  title: PropTypes.string,
  withBackButton: PropTypes.bool,
  navBarArr: PropTypes.array,
  titleIcon: PropTypes.element,
  onTitleIconClicked: PropTypes.func,
  /*E.g.: navBarArr
        [
            {
                link:'./management/user/',
                label:"label"
            }
        ]
    */
};

Component.defaultProps = {
  withBackButton: false,
};

export const TopNavbar = withAlelrtAndDialog((props) => (
  <Component {...props} />
));
