import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ActivityLoading } from './loading';

export const ActivityLoaders = (props) => {


  const {
    count
  } = props;


  return <div>
    <Grid container direction='row' spacing={2}>
      {
        Array.apply(null, Array(count)).map((value, index) => <Grid key={index} item xs={12}>
          <ActivityLoading />
        </Grid>)
      }
    </Grid>
  </div>
}

ActivityLoaders.propTypes = {
  count: PropTypes.number
}

ActivityLoaders.defaultProps = {
  count: 1
}