import React from 'react'
import { Avatar, Grid, IconButton, Typography, makeStyles, Button } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types'
import { avatarColorBgById, convertHexToRGBA } from '../../utils'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { ItemStatus } from '../information/infoDetail';
import { createTestProps } from '../../utils/common';
// import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    background: 'white',
    borderBottom: props.removeBorder ||  (!props.isLastItem && `0.5px solid ${theme.palette.secondary.light}`),
    padding: theme.spacing(props?.padding) || theme.spacing(1.5),
  }
  ),
  rootForUseActivityAsSummaryCard: (props) => ({
    background: 'white',
    padding: theme.spacing(props?.padding) || theme.spacing(1.5),
  }
  ),
  avatar: {
    margin: theme.spacing(0, 1, 0, 0)
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    marginRight: theme.spacing(2)
  },
  statusCircle: {
    display: 'inline-block',
    height: theme.spacing(1),
    width: theme.spacing(1),
    borderRadius: "50%",
    alignSelf: 'center',
    marginRight: theme.spacing(0.5)
  },
  statusActiveCircle: {
    background: "#31b198",
  },
  statusInactiveCircle: {
    background: "#bc2d4f",
  },
  statusText: {
    fontSize: 12,
  },
  statusActive: {
    color: "#31b198",
  },
  statusInactive: {
    color: "#bc2d4f",
  },
  captionStyle: {
    color: theme.palette.text.secondary
  },
  dot: {
    margin: theme.spacing(0, 1),
    color: theme.palette.text.secondary
  },
  statusBox: {
    display: 'flex',
    margin: theme.spacing(0.8, 0, 0, 0),
    // [theme.breakpoints.down(370)]: {
    //   flexDirection: 'column',
    // }
  },
  status: (props) => ({
    color: props.activityStatusColor || theme.palette.secondary.main,
    background: convertHexToRGBA(props.activityStatusColor, 8) || theme.palette.secondary.light,
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.5, 1),
    // [theme.breakpoints.down(370)]: {
    //   textAlign: 'center',
    //   marginBottom: theme.spacing(2),
    // }
  }),
  currentStatus: (props) => ({
    color: props.currentStatusColor || theme.palette.secondary.main,
    background: convertHexToRGBA(props.currentStatusColor, 8) || theme.palette.secondary.light,
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.5, 1),
    // [theme.breakpoints.down(370)]: {
    //   textAlign: 'center',
    //   marginBottom: theme.spacing(2),
    // }
  }),
  titleHoverStyles: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cancelButton: {
    boxShadow: "unset !important"
  }
}))

function ActivityAndMembersCard(props) {
  const classes = useStyles(props);
  const { showRemoveAssigned, onRemoveAssigned, id, emailVerified, hideProfilePicture, status, profilePicture, hideIsActive, title, timing, date, name, activityStatus, currentStatus, hideEndIcon, useActivityAsSummaryCard, useActivityAsViewCaregivers, useAsViewActivityOfSP, onEndIconClickHandler, onTitleClickHandler, customEndIcon } = props

  return (
    <Grid
      container
      alignItems="center"
      className={
        useActivityAsSummaryCard || useAsViewActivityOfSP
          ? classes.rootForUseActivityAsSummaryCard
          : classes.root
      }
    >
      {!useActivityAsViewCaregivers && (
        <Grid item container xs>
          {!useActivityAsSummaryCard && !hideProfilePicture && (
            <Grid item>
              {/* style={{ backgroundColor: !list?.profile_pic ? avatarColorBgById(list?.id ?? Math.floor(Math.random() * (999 - 100 + 1) + 100)) : "" }} */}
{/* {list?.name?.[0]?.toUpperCase()} */}
              {profilePicture ? (
                <Avatar
                  className={classes.avatar}
                  src={profilePicture}
                >
                  {name ? name?.trim()?.[0]?.toUpperCase() : title ? title?.trim()?.[0]?.toUpperCase() : ""}
                </Avatar>
              ) : (
                <Avatar className={classes.avatar} style={{ backgroundColor: !profilePicture ? avatarColorBgById(Math.floor(Math.random() * (999 - 100 + 1) + 100)) : "" }} >{title?.trim()?.[0]?.toUpperCase()}</Avatar>
              )}
            </Grid>
          )}
          <Grid item xs>
            <div className={classes.titleContainer}>
              {title ? (
                <Typography variant="body1" className={classes.title}>
                  {title ? title : ""}
                </Typography>
              ) : (
                ""
              )}
              {!hideIsActive && (
                <>
                  {/* <div
                    className={`${classes.statusCircle} ${
                      status
                        ? classes.statusActiveCircle
                        : classes.statusInactiveCircle
                    }`}
                  ></div>
                  <Typography
                    variant="body1"
                    className={`${classes.statusText} ${
                      status ? classes.statusActive : classes.statusInactive
                    }`}
                  >
                    {status ? "ACTIVE" : "INACTIVE"}
                  </Typography> */}
                  <ItemStatus 
                    isactive={status}
                    emailVerified={emailVerified}
                    loading={false}
                  />
                </>
              )}
            </div>
            <div>
              {!useActivityAsSummaryCard && (
                <Typography variant="caption" className={classes.captionStyle}>
                  {timing ? timing : ""}
                </Typography>
              )}
              {/* {(timing && name) && <span className={classes.dot}>•</span>} */}
              {name && (
                <>
                  <br />
                  <Typography
                    variant="caption"
                    className={classes.captionStyle}
                  >
                    {name ? name : ""}
                  </Typography>
                </>
              )}
            </div>
            {useActivityAsSummaryCard && (
              <Typography variant="caption" className={classes.captionStyle}>
                {date ? date : "Monday, Jan 01"}
                {date && timing && <span className={classes.dot}>•</span>}
                {timing ? timing : "00:00 AM"}
              </Typography>
            )}
            <div className={classes.statusBox}>
              <Typography
                variant="body2"
                className={activityStatus ? classes.status : ""}
              >
                {activityStatus ? activityStatus : ""}
              </Typography>
              {currentStatus && (
                <Typography variant="body2" className={classes.currentStatus}>
                  {currentStatus ? currentStatus : ""}
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
      )}

      {/* View Available caregivers card type */}

      {useActivityAsViewCaregivers && (
        <Grid item container xs alignItems="center">
          {!useActivityAsSummaryCard && !hideProfilePicture && (
            <Grid item>
              {profilePicture ? (
                <Avatar
                  className={classes.avatar}
                  src={profilePicture}
                >{title?.trim()?.[0]?.toUpperCase()}</Avatar>
              ) : (
                <Avatar className={classes.avatar} style={{ backgroundColor: !profilePicture ? avatarColorBgById(id ?? Math.floor(Math.random() * (999 - 100 + 1) + 100)) : "" }} >{title?.trim()?.[0]?.toUpperCase()}</Avatar>
              )}
            </Grid>
          )}
          <Grid item xs container alignItems="center">
            <Grid item xs={12}>
              {" "}
              {/**className={classes.title} */}
              <Typography
                variant="body1"
                onClick={onTitleClickHandler ? onTitleClickHandler : null}
                className={
                  onTitleClickHandler ? `${classes.titleHoverStyles}` : ""
                }
                {...createTestProps(`${title}`,props?.id)}
              >
                {title ? title : ""}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant="caption" className={classes.captionStyle}>
                {timing ? timing : "00:00 AM - 00:00 PM"}
                {name && <span className={classes.dot}>•</span>}
                {name ? name : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* End Icon */}
      {!hideEndIcon && (

        <>{showRemoveAssigned && (<Grid item>
          {/* <IconButton
            onClick={
              onRemoveAssigned ? () => onRemoveAssigned(props): null
            }
          >
              <RemoveCircleOutlineIcon htmlColor="#252b43" fontSize="small" />
          </IconButton> */}
          <Button size="small" {...createTestProps(`Unassign`,props?.id)} onClick={ onRemoveAssigned ? () => onRemoveAssigned(props): null } style={{ textTransform: "none", fontSize: "0.850 rem", color: "#f36363"}}> Unassign </Button>
          </Grid>)}
        
        
        <Grid item>
          <IconButton
            onClick={
              onEndIconClickHandler ? () => onEndIconClickHandler(props) : null
            }
            {...createTestProps(`clickIcon`,props?.field_id ? props?.field_id : props?.id)}
          >
            {!useActivityAsViewCaregivers && (
              <ChevronRightIcon htmlColor="#252b43" fontSize="small" />
            )}
            {!customEndIcon && useActivityAsViewCaregivers
              ? useActivityAsViewCaregivers && (
                  <CreateOutlinedIcon htmlColor="#9297a5" fontSize="small" />
                )
              : customEndIcon}
          </IconButton>
        </Grid>
        </>
      )}
    </Grid>
  );
}

export default ActivityAndMembersCard

ActivityAndMembersCard.propTypes = {
  /**ID of the item. Used to select an avatar background if not present. */
  id: PropTypes.number,
  /**Hides the profile picture. */
  hideProfilePicture: PropTypes.bool,
  /**URL of the profile picture, defaults to ```avatar``` icon from material UI.*/
  profilePicture: PropTypes.string,
  /**Title of the card. */
  title: PropTypes.string,
  /**Date for activity summary card, provide it if you use this component in the activity summary card */
  date: PropTypes.string,
  /**Time range to display, returns ```00:00 AM - 00:00 PM``` by default. */
  timing: PropTypes.string,
  /**Displays the name. */
  name: PropTypes.string,
  /**Displays the activity status. */
  activityStatus: PropTypes.string,
  /**Sets the activity status color and background with an alpha of 0.08. Pass only hexColorCode. */
  activityStatusColor: PropTypes.string,
  /**Displays the current status. */
  currentStatus: PropTypes.string,
  /**Sets the current status color and background with an alpha of 0.08. Pass only hexColorCode. */
  currentStatusColor: PropTypes.string,
  /**Whether the end icon should be hidden. */
  hideEndIcon: PropTypes.bool,
  /**To use the card UI for activity summary screens. Even if hideProfilePicture prop is set to false, the profile picture will be hidden. So please check once before modifying.*/
  useActivityAsSummaryCard: PropTypes.bool,
  /**Reworked for view available caregivers 5.6, Set this to true to use it. */
  useActivityAsViewCaregivers: PropTypes.bool,
  /**To Fix the card layout for View activity of a specific user */
  useAsViewActivityOfSP: PropTypes.bool,
  /**Set padding for activity summary card, example ```padding={2} will transform to 2*8px = 16px```. Consider this while setting the padding */
  padding: PropTypes.number,
  /**Handles Click of the end icon */
  onEndIconClickHandler: PropTypes.func,
  onRemoveAssigned:PropTypes.func,
  /**Status of the user. Active (or) Inactive. */
  status: PropTypes.bool,
  /**Hides the Status. */
  hideIsActive: PropTypes.bool,
  /**Removes the bottom border */
  removeBorder: PropTypes.bool,
  /**Use it to change the end icon, if you are using useActivityAsViewCaregivers version of the component. */
  customEndIcon: PropTypes.object,
  /**Set style for last Item. */
  isLastItem: PropTypes.number,
  
};