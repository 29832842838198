import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { routes } from "../../router/routes";

import { withNavBars } from "./../../HOCs";
import { ListEnquiry, AddEditEnquiry, ViewEnquiry } from "./enquiry";


class SalesOperation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateNavBarProps: true,
    };
  }

  render() {
    const { match } = this.props;

    return (
      <>
        {/* Enquiry Routes */}
        {match.path === routes.sales_operation && <ListEnquiry />}
        {match.path === routes.enquiry_list && <ListEnquiry />}
        {match.path === routes.add_enquiry && <AddEditEnquiry />}
        {match.path === routes.edit_enquiry && <AddEditEnquiry isEdit={true}/>}
        {match.path === routes.view_enquiry && <ViewEnquiry />}
       
      </>
    );
  }
}

SalesOperation.propTypes = {
  title: PropTypes.string,
  withBackButton: PropTypes.bool,
  navBarArr: PropTypes.array,
};

SalesOperation.defaultProps = {
  title: "Sales Operations",
};

export default withRouter(withNavBars(SalesOperation));
