import React from "react";
import { LocalizationProvider, MobileTimePicker } from "@material-ui/pickers";
import {
  FormControl,
  InputLabel,
  TextField,
  withStyles
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
// import { Clock } from "../../assets/AddEditScreenIcons/clock";
import { errorMessage } from "../../utils/errorMessages";

const style = (theme) => ({
  root: {
    borderRadius: "8px !important",
    margin: (props) =>
      props.marginTop ? `${props.marginTop}px 0px 8px 0px` : "8px 0px 8px 0px",
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "16px 12px"
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.secondary.light}`,
      borderRadius: 8
    },
    "& .MuiOutlinedInput-root:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`
      }
    }
  },
  errorBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.error.light} !important`
    }
  },
  svgColor: {
    fill: theme.palette.secondary.main,
    fontSize: 20
  },
  textField: {
    marginTop: (props) =>
      props.marginTop ? `${props.marginTop}px` : theme.spacing(3),

    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      // border: '1.8px solid rgba(204,204,204, 0.3)',
      border: "none"
    },

    "& .MuiOutlinedInput-root.Mui-disabled ": {
      backgroundColor: "#f2f2f2",
      borderRadius: "8px !important"
    }
  },
  label: {
    color: theme.palette.text.label,
    textTransform: "uppercase"
  }
});

class FromToTimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start: null,
      end: null,
      startError: false,
      startErrorText: "",
      endError: false,
      endErrorText: ""
    };
  }

  handleOnChange = (value, key) => {
    this.setState({
      [key]: value
    });
  };

  handleOnError = (reason, value, errorKey, errorTextKey) => {
    const { fromDateParentErrorStateUpdate = () => {}, toDateParentErrorStateUpdate = () => {} } = this.props;
    switch (reason) {
      case "invalidDate":
        this.setState(
          {
            [errorKey]: true,
            [errorTextKey]: "Invalid time format"
          },
          () => {
            if (
              errorKey === "startError" &&
              errorTextKey === "startErrorText"
            ) {
              fromDateParentErrorStateUpdate(true);
            } else {
              toDateParentErrorStateUpdate(true);
            }
          }
        );
        break;

      case "disablePast":
        this.setState(
          {
            [errorKey]: true,
            [errorTextKey]: "Values in the past are not allowed"
          },
          () => {
            if (
              errorKey === "startError" &&
              errorTextKey === "startErrorText"
            ) {
              fromDateParentErrorStateUpdate(true);
            } else {
              toDateParentErrorStateUpdate(true);
            }
          }
        );
        break;

      case "maxTime":
        this.setState(
          {
            [errorKey]: true,
            [errorTextKey]: `Time should not be after`
            //   ${format(maxDate, "P")}
          },
          () => {
            if (
              errorKey === "startError" &&
              errorTextKey === "startErrorText"
            ) {
              fromDateParentErrorStateUpdate(true);
            } else {
              toDateParentErrorStateUpdate(true);
            }
          }
        );
        break;

      case "minTime":
        this.setState(
          {
            [errorKey]: true,
            [errorTextKey]: errorKey === "startError" ? `Time should not be before current time`  : `Time should not be before start time`
            //   ${format(minDate, "P")}
          },
          () => {
            if (
              errorKey === "startError" &&
              errorTextKey === "startErrorText"
            ) {
              fromDateParentErrorStateUpdate(true);
            } else {
              toDateParentErrorStateUpdate(true);
            }
          }
        );
        break;
      case null:
        this.setState(
          {
            [errorKey]: false,
            [errorTextKey]: ``
            //   ${format(minDate, "P")}
          },
          () => {
            if (
              errorKey === "startError" &&
              errorTextKey === "startErrorText"
            ) {
              fromDateParentErrorStateUpdate(false);
            } else {
              toDateParentErrorStateUpdate(false);
            }
          }
        );
        break;
      default:
        this.setState(
          {
            [errorKey]: false,
            [errorTextKey]: ``
            //   ${format(minDate, "P")}
          },
          () => {
            if (
              errorKey === "startError" &&
              errorTextKey === "startErrorText"
            ) {
              fromDateParentErrorStateUpdate(false);
            } else {
              toDateParentErrorStateUpdate(false);
            }
          }
        );
    }
  };

  getStartTimeHelperText = () => {
    const { reqStartError, timeDifferenceError} = this.props;
    if(this.state.startError){
      return this.state.startErrorText
    } else if(timeDifferenceError){
      return errorMessage.timeDifferenceError
    } else if(reqStartError){
      return errorMessage.requiredMessage
    } else {
      return ""
    }
  }

  getEndTimeHelperText = () => {
    const { reqEndError, timeDifferenceError} = this.props;
    if(this.state.endError){
      return this.state.endErrorText
    } else if(timeDifferenceError){
      return errorMessage.timeDifferenceError
    } else if(reqEndError){
      return errorMessage.requiredMessage
    } else {
      return ""
    }
  }

  render() {
    const { classes } = this.props;
    const {
      startLabel,
      startValue,
      startChange,
      handleStartAccept,
      startMin,
      disableStartPast,
      reqStartError, //Mandatory Error TODO:

      endLabel,
      endValue,
      endChange,
      handleEndAccept,
      endMin,
      disableEndPast,
      reqEndError, //Mandatory Error TODO:
      timeDifferenceError, //Time difference error
    } = this.props;
    return (
      <>
        {/* Start Time */}
        <FormControl
          className={`${classes.root} ${
            this.state.startError ? classes.errorBorder : ""
          }`}
        >
          <InputLabel shrink htmlFor="date">
            {startLabel && <div className={classes.label}>{startLabel}</div>}
          </InputLabel>
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <MobileTimePicker
              value={startValue}
              onChange={startChange}
              onAccept={handleStartAccept}
              disablePast={disableStartPast}
              onError={(reason, value) =>
                this.handleOnError(
                  reason,
                  value,
                  "startError",
                  "startErrorText"
                )
              }
              minTime={startMin}
              // disableIgnoringDatePartForTimeValidation
              // openPickerIcon={<Clock className={classes.svgColor} />}
              // disabled={disabled}
              renderInput={(props) => {
                return (
                  <TextField
                    {...props}
                    //   disabled={disabled}
                    variant="outlined"
                    id="startDate"
                    placeholder=""
                    autoComplete="off"
                    error={this.state.startError || reqStartError || timeDifferenceError}
                    helperText={ this.getStartTimeHelperText() }
                    className={classes.textField}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </FormControl>

        {/* End Time */}
        <FormControl
          className={`${classes.root} ${
            this.state.endError ? classes.errorBorder : ""
          }`}
        >
          <InputLabel shrink htmlFor="date">
            {endLabel && <div className={classes.label}>{endLabel}</div>}
          </InputLabel>
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <MobileTimePicker
              value={endValue}  
              onChange={endChange}
              onAccept={handleEndAccept}
              disablePast={disableEndPast}
              onError={(reason, value) =>
                this.handleOnError(reason, value, "endError", "endErrorText")
              }
              minTime={endMin}
              // disableIgnoringDatePartForTimeValidation
              // openPickerIcon={<Clock className={classes.svgColor} />}
              // disabled={disabled}
              renderInput={(props) => {
                return (
                  <TextField
                    {...props}
                    //   disabled={disabled}
                    variant="outlined"
                    id="endDate"
                    placeholder=""
                    autoComplete="off"
                    error={this.state.endError || reqEndError || timeDifferenceError}
                    helperText={ this.getEndTimeHelperText()}
                    className={classes.textField}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </>
    );
  }
}

export default withStyles(style)(FromToTimePicker);
