import React from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || theme.palette.primary.main,
  }),
}))

export const ClientPersonIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
      style={props.style}
    >
      <path id="Person" className={classes.cls1} d="M15.189 14.392a3.966 3.966 0 0 1-6.378 0C5.994 15.016 3 16.385 3 18.5V21h18v-2.5c0-2.115-2.994-3.484-5.811-4.108zM19 19H5v-.5c0-.313.932-1.213 3.183-1.882a5.962 5.962 0 0 0 7.634 0C18.068 17.287 19 18.187 19 18.5zM12 3a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2z" transform="translate(-3 -3)" />
    </SvgIcon>
  )
}

ClientPersonIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};
