/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-01-27 20:46:41
 * @modify date 2022-01-27 20:46:41
 * @desc Component for Promotional Banner View Page - Banner Preview
 */
import React from "react";
import PropTypes from "prop-types";

// Material UI Components
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// Custom Components
import { IconComponent, CloseRoundedIcon } from "../../../../../assets";
import { FILE_URI } from "../../../../../utils/constants";
import { DialogContext } from "../../../../../contexts";
import { createTestProps } from "../../../../../utils/common";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "14px",
    fontWeight: 400,
    marginBottom: "16px",
  },
  imageContainer: {
    // width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "& div": {
      borderRadius: "6px",
    },
    "& img": {
      objectFit: "fill",
      borderRadius: "6px",
      maxWidth: "100%",
      minWidth: "100%",
      minHeight: "100%",
      maxHeight: "180px",
    },
  },
  button: {
    backgroundColor: "#FFFFFF",
    opacity: "0.5",
    borderRadius: "8px",
    textTransform: "none",
    color: theme.palette.primary.main,
  },

  //
  imgView: {
    width: "100%",
    height: "175px",
    objectFit: "cover",
    // objectPosition:'center',
    borderRadius: 6,
  },
  imgSection: {
    position: "relative",
    "&:hover": {
      "& img": {
        filter: "blur(2.5px) brightness(0.8)",
        transition: "all 0.2s",
      },
      "& $hoverOnShow": {
        display: "block",
      },
    },
  },
  fullViewText: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 400,
    paddingLeft: theme.spacing(1),
  },
  hoverOnShow: {
    position: "absolute",
    cursor: "pointer",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    display: "none",
  },
  rowCenter: {
    display: "flex",
    alignItems: "center",
    paddingBlock: "10px",
    paddingInline: theme.spacing(2),
    backgroundColor: "#FFFFFF",
    opacity: 0.8,
    justifyContent: "center",
    borderRadius: 8,
  },

  // Dialog Classes
  fullViewDialog: {
    "& .MuiDialogTitle-root": {
      display: "none",
    },
    "& .MuiDialogContent-root": {
      padding: 24,
      paddingBottom: 0,
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // marginBottom:theme.spacing(3)
  },
  dialogTitle: {
    fontWeight: 500,
    fontSize: 18,
    color: "#3B3F5B",
  },
  dialogImgPreview: {
    height: 284,
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
    borderRadius: 6,
  },
}));

function BannerPreview({ src }) {
  const classes = useStyles();
  const imageUrl = FILE_URI.concat(`/${src}`);
  const dialog = React.useContext(DialogContext);

  const closeFullView = () => {
    dialog.setDialog({
      open: false,
    });
  };

  const showFullView = () => {
    dialog.setDialog({
      ...dialog,
      open: true,
      className: {
        Dialog: classes.fullViewDialog,
      },
      body: (
        <div style={{ paddingBottom: "24px", textAlign: "center" }}>
          <div className={classes.header} style={{ marginBottom: 24 }}>
            <Typography className={classes.dialogTitle}>Preview</Typography>
            <div
              style={{ cursor: "pointer", display: "flex" }}
              onClick={closeFullView}
              {...createTestProps("close full view image")}
            >
              <CloseRoundedIcon />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <img
              src={imageUrl}
              className={classes.dialogImgPreview}
              alt="img-preview"
            />
          </div>
        </div>
      ),
      dontShowButtons: true,
      hideAllButtons: true,
    });
  };

  return (
    <div>
      <Typography color="textSecondary" className={classes.label}>
        Banner Preview
      </Typography>

      {/* <div className={classes.imageContainer}>
        <div>
          <img src={imageUrl} alt="Banner image" />
        </div>
      </div> */}

      <div className={classes.imgSection} id="imgSection">
        <img src={imageUrl} className={classes.imgView} alt="img-preview" />
        <div className={classes.hoverOnShow} onClick={showFullView}>
          {/**onClick={showFullView} */}
          <div className={classes.rowCenter}>
            <IconComponent name={"fullscreen"} style={{ fontSize: "0.8rem" }} />
            <Typography className={classes.fullViewText}>Full View</Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

BannerPreview.propTypes = {
  src: PropTypes.string,
};

BannerPreview.defaultProps = {
  src: "",
};

export default BannerPreview;
