import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import AddEditUserManagement from "../../../../components/addEditForm";
import { formComponentTypes, mappingResourceNames } from "../../../../utils";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actions } from "tanyacare-middleware";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { withRouter } from "react-router";

class Mapping extends Component {
  onSaveButtonClicked = () => {
    
  };
  componentDidMount() {
    if (this?.props?.resourceType === mappingResourceNames.PROFESSIONAL) {
      
      this.props.GET_CARE_COORDINATORS_TO_MAP({
        professionalId:this.props.match.params.id,
        partnerId: this.props.partnerId,
      });
    }
    if(this?.props?.resourceType === mappingResourceNames.COORDINATOR){
      this.props.GET_PROF_TO_MAP({
        coordinatorId:this.props.match.params.id,
        partnerId: this.props.partnerId,
      });
      
    }
  }
  loadComponentJSON = (resourceType) => {
    switch (resourceType) {
      case mappingResourceNames.PROFESSIONAL:
        return { 
          label: "",
          fields: {
            coordinator: {
              key: "coordinator",
              type: formComponentTypes.SELECT_BOX,
              isDisabled: false,

              label: "SELECT COORDINATOR",
              breakPoints: {
                xs: 12,
                sm: 12, 
                md: 12,
                lg: 12,
                xl: 12,
              },
              value: "",
              options: 
              this.props?.getCoordinatorsList?.data,
              isMandatory: true,
            },
          },
        };
        case mappingResourceNames.COORDINATOR:
        return { 
          label: "",
          fields: {
            coordinator: {
              key: "coordinator",
              type: formComponentTypes.SELECT_BOX,
              isDisabled: false,

              label: "SELECT PROFESSIONAL",
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              value: "",
              options: 
              this.props?.getProfessionalsList?.data,
              isMandatory: true,
            },
          },
        };
      default:
        return {};
    }
  };
  cancelForm = () =>{
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    }); 
  }
  render() {
    console.log("this.props.getCoordinatorsList",this.props.getCoordinatorsList)
    const components = {
      section_1: this.loadComponentJSON(this.props.resourceType),
    };
    return (
      <Grid container>
        <Grid item xs={12}>
          {this.props.resourceType ? (
            <AddEditUserManagement
              components={components}
              onSaveButtonClicked={this.props.onMappingSaveButtonClicked}
              cancelForm={this.cancelForm}
              //   loading={addLoading}
              // disabled={}
              //   saveButtonLabel={addLoading ? "Saving..." : "Save"}
            />
          ) : (
            <div>Nothig to render</div>
          )}
        </Grid>
      </Grid>
    );
  }
}

Mapping.propTypes = {
  resourceType: PropTypes.string,
};

const mapStateToProps = (state) => ({
  getCoordinatorsList: state?.careCoordinator?.collectCDTomap,
  getProfessionalsList:state?.careCoordinator?.profToMap
});

export default withRouter(withAlelrtAndDialog(connect(mapStateToProps, actions)(Mapping)));
