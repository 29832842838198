import React from "react";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
// import { LinearProgress } from "@material-ui/core";
import { networkCall } from "./networkcall";
import { AuthContext } from "./contexts";

class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: { email: "", password: "", device: "web", refresh: true },
      setAuth: this.setAuth,
    };
  }
  setAuth = (auth) => {
    this.setState({ auth });
  };
  componentDidMount() {
    networkCall("meta.json", "GET", null, null, false, true)
      .then((_) => {
        
        localStorage.setItem("version", _.data.version);
      })
      .catch((err) => {
        console.log("err:", err);
      });
    
    if (localStorage.getItem("_mGdRe"))
      this.props.LOGIN_AUTH({ ...this.state.auth });
  }

  render() {
    // let { loading, error, data } = this.props?.loginState;

    return (
      // loading && error && !data ? <LinearProgress /> :
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  loginState: state?.authState?.loginAuth,
});
export default connect(mapStateToProps, actions)(AppAuth);
