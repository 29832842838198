/* eslint-disable eqeqeq */
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ProductPriceCard from "../../../../components/productPriceCard";
import {
  // ListCard,
  LoadingSection,
  InternatlServerError,
  NotFoundSection,
} from "../../../../components";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routes } from "../../../../router/routes";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { alertProps, scrollToTop, user_roles } from "../../../../utils";
import { ProductCard } from "./productCard";
import ViewSequence from "./viewSequence";
import { ProductCatalogue } from "../../../../components/productCatalogue/productCatalogue";

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getDetail();
    scrollToTop();
  }

  getDetail = () => {
    const id = this.props?.match?.params?.id;
    const versionId = this.props?.match?.params?.versionId;
    this.props.GET_PRODUCTS_INFO({ id, versionId, isDetail: true });
  };

  onStatusChangeClick = () => {
    let pg = this.props?.productInfo?.data;

    const Oldstatus = pg?.isActive;

    let status = "Activate";
    if (Oldstatus) {
      status = "Deactivate";
    }

    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body: "Do you want to " + status + " Product : " + pg?.name,
      positiveBtn: status,
      negativeBtn: "Cancel",
      onOk: () => this.deactivateProduct(pg),
    });
  };

  deactivateProduct = (pg) => {
    const id = this.props?.match?.params?.id;
    const status = pg?.isActive;
    this.props.dialog.setDialog({ open: false });
    this.props.UPDATE_STATUS_OF_PRODUCT({ productID: id, status: !status });
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Update Status
    const { updateProductStatusInfo } = nextProps;
    const oldupdateProductStatusInfo = this.props.updateProductStatusInfo;

    //Checking for Update Status
    if (
      oldupdateProductStatusInfo?.loading !== updateProductStatusInfo?.loading
    ) {
      this.props.backDrop.setBackDrop({
        open: updateProductStatusInfo.loading,
        message: "Updating the product status...",
      });
      if (!updateProductStatusInfo.loading && !updateProductStatusInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Product Status updated successfully!",
          severity: alertProps.severity.success,
        });
        this.getDetail();
      } else if (updateProductStatusInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to update product status.",
          severity: alertProps.severity.error,
        });
      }
    }

    return true;
  }

  navigateToEdit = () => {
    const product_id = this.props?.match?.params?.id;
    const versionId = this.props?.match?.params?.versionId;

    this.props.history.push(routes.manage_product + "/edit/" + product_id + "/" + versionId);
  };

  navigateToView = (id, versionId) => {
    this.props.history.push(routes.manage_service+"/view/"+id + "/" + versionId)
  };

  handleTimelineOpen = () => {
    let { data } = this.props?.productInfo
    // ProductTimeline
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: <ViewSequence sequenceData={data} />,
    });
  }

  roleBasedAccess = (id) => {
    const data = this.props?.auth_data?.data;
    
    // Return True disable edit button
    // Return False enable edit button

    let disable = null;
    if(Boolean(data?.user_role === user_roles.service_coordinator || data?.user_role === user_roles.help_desk || this.props?.auth_data?.data?.user_role === user_roles.business_admin)){
      /*SC can;t edit a Product */
      disable = true
    }

    if (data?.user_role == user_roles.provider) {
      if(data?.user_id == id?.partnerId){
        /*User can edit a Product*/
        disable = false
      }else{
        disable = true
      } 
    }
    if (data?.user_role == user_roles.supervisor) { 
        if (data?.partnerId == id?.partnerId) { 
            disable = false 
        } else{
          disable = true 
        } 
    }
    
    if (data?.user_role == user_roles.super_admin && data?.other_roles?.indexOf("cross-bundling") > -1) { 
      if (id?.care_provider?.[0]?.crossBundle) { 
        // id?.partnerId !== "5cd8f293-1e49-473e-af6b-bf27e986566f"
          /* no one can edit MC User's product except him*/
          disable = false
      }else{
          disable = true 
      }
    }

    if (data?.user_role == user_roles.super_admin && data?.other_roles?.indexOf("cross-bundling") === -1) { 
      if (id?.care_provider?.[0]?.crossBundle) { 
          /* no one can edit MC User's product except him*/
          disable = true
        }else{
          disable = false
      }
    }

    return disable;

  }

  formatData = (services) => {
    let styledServices = services?.map((service) => {
      let orderList = [
        {
          label: "Quantity",
          value: service?.quantity,
        },
        {
          value: `${service?.currency} ${service?.amount}`,
          style: {minWidth: 140}
        }
      ]
      return {...service, orderList, care_provider: [{...service?.providerDetails}]}
    })

    return Array.isArray(styledServices) ? styledServices : []
  }

  render() {
    let { data, loading, error } = this.props?.productInfo;
    let { loading: statusChangeLoading } = this.props?.updateProductStatusInfo;
    const id = this.props?.match?.params?.id;
    const versionId = this.props?.match?.params?.versionId;

    return (
      <div>
        {loading && <LoadingSection message="Fetching product details..." />}
        {!loading && error && (
          <InternatlServerError
            message="Something went wrong! Try Again."
            onRetry={this.getDetail}
          />
        )}
        {!loading && !error && !data && (
          <NotFoundSection message="404 - Product Not Found" />
        )}
        {!loading && !error && data && Object.keys(data)?.length > 0 &&(
          <Grid item style={{ padding: "25px" }}>

          <ProductCard
              navigateToEdit={this.navigateToEdit}
              productDetail={data}
              onStatusChangeClick={this.onStatusChangeClick}
              statusChangeLoading={statusChangeLoading}
              isbuttonHide={this.roleBasedAccess(data)}
              id={`${id}${versionId}`}
              handleTimelineOpen={this?.props?.productInfo?.data?.order_sequence?.length > 0 ? this.handleTimelineOpen : null}
            />

            {data?.included_package?.length > 0 && (
              <Typography style={{ margin: "30px 0px 15px 0px" }}>
                Packages
              </Typography>
            )}

            <ProductPriceCard items={{ rows: data?.included_package }} />

            {data?.included_service?.length > 0 && (
              <Typography style={{ margin: "30px 0px 15px 0px" }}>
                Services
              </Typography>
            )}
            <ProductCatalogue
                id={"ProductIncludedServices"}
                catalogues={this.formatData(data?.included_service)}
                redirectUrl={routes.manage_service + "/view/"}
                loading={loading}
                showProvider
                hideStatus
              />
            {/* {data?.included_service?.map((l, key) => {
              return (
                <div style={{ marginBottom: 12, cursor: "pointer" }}>
                  <ListCard
                    data={{
                      id: l?.value,
                      isactive: true,
                      name: l?.label,
                      versionId: l?.versionId,
                      care_provider: [l?.providerDetails],
                      category: l?.category,
                      profile_pic: l?.profile_pic,
                      orderList: [{label: "Quantity", value: l.quantity},{ label: l?.frequency, value:  `${l?.currency + " " + l?.amount}` }],
                    }}
                    isMoreBtn={false}
                    navigateToView={this.navigateToView}
                    setPriceTrailingDecimal
                    hideDivider={false}
                  />
                </div>
              );
            })} */}
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productInfo: state?.productState?.productInfo,
  updateProductStatusInfo: state?.productState?.updateProductStatusInfo,
  auth_data: state?.authState?.loginAuth,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(ProductDetail)));
