import * as React from "react"

export const PngLogo = (props) => {
 let width = props.width ? props.width : "28.849";
 let height = props.height ? props.height : "36.697";
 let color = props.color ? props.color : "#0063e7";
 return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28.849 36.697">
     <path id="icons8-png" d="M15.4,20.429a1.233,1.233,0,0,0-.385.059v2.49a.917.917,0,0,0,.254.022c.792,0,1.155-.561,1.155-1.315C16.419,20.978,16.131,20.429,15.4,20.429ZM24.764,2H7V38.7H35.849V12.717ZM17.3,23.493a2.84,2.84,0,0,1-1.972.763H15.01v3H13.436V19.317a8.2,8.2,0,0,1,1.646-.156,3.082,3.082,0,0,1,2.2.62,2.324,2.324,0,0,1,.7,1.773A2.715,2.715,0,0,1,17.3,23.493Zm6.442,3.768H22.295L21.058,24.2a22.332,22.332,0,0,1-.817-2.427h-.025c.034.813.1,2.057.1,3.674V27.26H18.929V19.2h1.443l1.249,3.16c.241.623.639,1.664.867,2.356h.022c-.047-.848-.157-2.25-.157-3.733V19.2h1.384Zm5.891-.215a5.356,5.356,0,0,1-1.684.287,2.886,2.886,0,0,1-2.163-.8,4.418,4.418,0,0,1-1.046-3.253c.034-2.932,1.718-4.154,3.437-4.154a3.18,3.18,0,0,1,1.321.24l-.275,1.352a1.964,1.964,0,0,0-.927-.181c-1.071,0-1.922.729-1.922,2.836,0,1.938.758,2.633,1.49,2.633a1.052,1.052,0,0,0,.313-.034V24.019H27.45V22.738h2.179Zm-5-13.876V3.6l9.616,9.573Z" 
        transform="translate(-7 -2)"
        fill={color}
        opacity="0.75"
    />
    </svg>
  );
};
