import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import CertificationcardsLoading from './CertificationcardsLoading';

 const CertificationCardLoaders = (props) => {

  const {
    count
  } = props;

  return <>
    {
      Array.apply(null, Array(count)).map((value, index) => <Grid key={index} item xs={12}>
        <CertificationcardsLoading />
      </Grid>)
    }
  </>
}

CertificationCardLoaders.propTypes = {
  count: PropTypes.number
}

CertificationCardLoaders.defaultProps = {
  count: 1
}
export default CertificationCardLoaders;