import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import moment from'moment'
import { capitalizeFirstLetter } from "../../utils";

const styles = makeStyles((theme) => ({
  chip: {
    // background: theme.palette.primary.light,
    background: "rgba(233, 233, 233, 0.48)",
    color: "white",
    textAlign: "center",
    padding: "0px 5px",
    borderRadius: 4,
    margin: "0px 8px 0px 0px",
  },
  header: {
    color: "white",
    marginBottom: 5,
    textTransform: 'capitalize'
  },
  subHeader: {
    color: "white",
    marginBottom: 10,
  },
}));
export const _calculateAge = (birthday) => { // birthday is a date
  let age = moment().diff(new Date(birthday), 'years');
  
  if(age <= 0){
    let month = 12 - (new Date(birthday).getMonth()+1)  
    if(month > 0){
      age = (month) + (month === 1 ? " Month" : " Months") 
    }
    else{
      let days = moment().diff(new Date(birthday), 'days');
      if(days === 0){
        age = "New Born"
      }
      else{
        age = (days) + (days > 1 ? " Days" : " Day")
      }
    }
  }
  else{
    age = (moment().diff(new Date(birthday), 'years')) + (age >1 ? " Years" : " Year")
  }
  return age
}

// New Function for calculating age
export const calcAge = (birthday) => {
  const today = new Date()
  const bday = new Date(birthday)
  const yearDiff = today.getFullYear() - bday.getFullYear()
  const monthDiff = (today.getMonth() + 1) - (bday.getMonth() + 1)
  const dayDiff = today.getDate() - bday.getDate()
  if(yearDiff > 0){
    return yearDiff > 1 ? `${yearDiff} years` : `${yearDiff} year`
  } else {
    if(monthDiff > 0){
      
      return monthDiff > 1 ? `${monthDiff} months` : `${monthDiff} month`
    } else {
      return dayDiff === 0 ? `${dayDiff} day` : (dayDiff > 1 ? `${dayDiff} days` : `${dayDiff} day`)
    }
  }
}







export const ClientDetail = (props) => {
  const classes = styles();
  const {
    data
  } = props;
  let isContactInformation= data?.contact_information ? data?.contact_information : data?.caller_information
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.header}>
          {isContactInformation?.sName ? isContactInformation?.sName === 'other' ? ` ` : `${isContactInformation?.sName}. ` : ""}
          {isContactInformation?.name ? isContactInformation?.name : ""}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.subHeader}>
        <Typography variant="caption" component="div">
          {
          isContactInformation?.gender && isContactInformation?.date_of_birth ?
          capitalizeFirstLetter(isContactInformation?.gender) + ", " + calcAge(isContactInformation.date_of_birth) 
          :
          isContactInformation?.gender ? capitalizeFirstLetter(isContactInformation?.gender) : isContactInformation?.date_of_birth && calcAge(isContactInformation?.date_of_birth) 
          }
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        {data?.languages && typeof data?.languages == "string" && JSON.parse(data?.languages).length>0 ? 
        JSON.parse(data?.languages).map((val) => (
          <Grid item>
            <Typography
              variant="caption"
              component="div"
              className={classes.chip}
            >
              {val.label}
            </Typography>
          </Grid>
        ))
        :
        <Grid item>
        <Typography
          variant="caption"
          component="div"
          className={classes.chip}
        >
        {}
        </Typography>
      </Grid>
        
      }
      </Grid>
    </Grid>
  );
};

ClientDetail.propTypes = {
  data: PropTypes.object,
};
