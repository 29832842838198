import * as React from "react"

export const RectangleIcon = (props) => {

    const {
        width = "36",
        height = "20",
        borderColor = "#6f6f6f",
        borderWidth = "1",
        bgColor = "#fff"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 36 20"
        >
            <g id="Rectangle_48015" data-name="Rectangle 48015" fill={bgColor} stroke={borderColor} stroke-width={borderWidth}>
                <rect width="36" height="20" stroke="none" />
                <rect x="0.4" y="0.4" width="35.2" height="19.2" fill="none" />
            </g>
        </svg>
    );
};

// import * as React from "react";
// import { SvgIcon } from "@material-ui/core";

// export const RectangleIcon = (props) => {
//     debugger
//     return (
//         <SvgIcon
//             width="1em"
//             height="1em"
//             viewBox="0 0 24 24"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//             {...props}
//         >
//             <g clipPath="url(#a)">
//                 <path d="M24 5H0v13.333h24V5Z" fill="#fff" />
//                 <path
//                     d="M23.733 5.267H.267v12.8h23.466v-12.8Z"
//                     stroke="#6F6F6F"
//                     strokeWidth={0.8}
//                 />
//             </g>
//             <defs>
//                 <clipPath id="a">
//                     <path fill="#fff" d="M0 0h24v24H0z" />
//                 </clipPath>
//             </defs>
//         </SvgIcon>
//     );
// }

