import React from "react";
import {  IconButton, Badge, Tooltip } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// import { FilterIcon } from "../../assets";
// import { FilterIcon } from "../../assets/newFilterIcon";
// import { FilterIcon } from "../../assets";
// import { FilterIconNew } from '../../assets'
import PropTypes from 'prop-types'
import FilterListIcon from '@material-ui/icons/FilterList';
const useStyles = makeStyles((theme) => ({
  root: {
    width: 48,
    height: 48,
    borderRadius: 8,
    // paddingLeft: 2,
    // paddingTop: 2,
    boxShadow:
      "0px 6px 6px -10px rgb(0 0 0 / 9%), 0px 0px 0px 0px rgb(0 0 0 / 12%), 0px 0px 0px 0px rgba(0,0,0,0.12)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filterApplied: {
    border: `1px solid ${theme.palette.ternary.light}`,
    color: theme.palette.ternary.main,
  },
  badge: {
    // marginTop: '-4px',
    // marginRight: '-2px',
    '& .MuiBadge-colorError': {
      backgroundColor: `${theme.palette.ternary.main} !important`,
    }
  },
  customizeBadge: {
    marginTop: '-6px',
    marginRight: '-2px',
  },
  filterIconButton: {
    backgroundColor: "white",
    boxShadow: theme.palette.primary.boxShadow
  },
  // filterIconButtonRoot: {
  //   "& :hover": {
  //     backgroundColor: "rgba(0, 0, 0, 0.04)"
  //   }
  // },
  // filterIconButtonLabel: {
  //   "& :hover": {
  //     backgroundColor: "unset !important"
  //   }
  // }
}));

export default function Filter(props) {
  const classes = useStyles(props);
  // const { useNewFilterIcon } = props
  const theme = useTheme()
  return (
    // <Paper className={`${classes.root} ${props.isFilterApplied ? classes.filterApplied : ''}`} >
    //   <Tooltip title={props.isFilterApplied ? "Filter Applied" : "Filter"} placement="top-end">
    //     <Badge className={classes.badge} classes={{ badge: classes.customizeBadge}} color="error" variant="dot" invisible={!props.isFilterApplied}>
    //       <IconButton onClick={props.onFilterIconClicked}>
    //         {useNewFilterIcon && <FilterIconNew color={props.isFilterApplied ? "#f4975d" : null} height={16} width={16} />}
    //         {!useNewFilterIcon && <FilterIcon color="black" style={{ paddingLeft: 4 }} />}
    //         <FilterListIcon />
    //       </IconButton>
    //     </Badge>
    //   </Tooltip>
    // </Paper>

    <Tooltip title={props.isFilterApplied ? "Filter Applied" : "Filter"} placement="bottom">
           <IconButton id={props?.id} onClick={props.onFilterIconClicked} className={classes.filterIconButton} classes={{root: classes.filterIconButtonRoot, label: classes.filterIconButtonLabel}}>
      <Badge className={classes.badge} classes={{ badge: classes.customizeBadge}} color="error" variant="dot" invisible={!props.isFilterApplied}>
             {/* {useNewFilterIcon && <FilterIconNew color={props.isFilterApplied ? "#f4975d" : null} height={16} width={16} />}
             {!useNewFilterIcon && <FilterIcon color="black" style={{ paddingLeft: 4 }} />} */}
             <FilterListIcon htmlColor={props.isFilterApplied && theme.palette.ternary.main} />
         </Badge>
           </IconButton>
    </Tooltip>

  );
}

Filter.propTypes = {
  /**
   * To use the new filter icon provided by the design team
   */
  useNewFilterIcon: PropTypes.bool
}