import * as React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    cls1: (props) => ({
        fill: props.chosenFill || theme.palette.text.secondary
    }),
    cls2: (props) => ({
        fill: 'none',
        stroke: props.chosenFill || theme.palette.text.secondary,
        strokeMiterlimit: 10,
        strokeWidth: 2
    }),
}))

export function ActivitiesIcon(props) {
    const classes = useStyles()
    return (
        <svg width={18} height={20} viewBox="0 0 18 20" {...props} >
            <g data-name="Activities Completed">
                <path
                    class={classes.cls1}
                    d="M16 2h-4.184a2.982 2.982 0 00-5.632 0H2a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2zm0 16H2V4h7a1 1 0 111-1 1 1 0 01-1 1h7z"
                    data-name="Path 662"
                />
                <path
                    class={classes.cls2}
                    d="M13 8l-5 5-3-3"
                    data-name="Path 663"
                />
            </g>
        </svg>
    )
}
export default ActivitiesIcon