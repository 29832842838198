import { routes } from "../../router/routes";
import { allPersonaIds } from "../resourceManagement/resources/utils";

export const totalCountLabel = (url) => {
  switch (url) {
    case routes.partner_management_branch:
      return "Total Branches";
    case routes.partner_management_provider:
      return "Total Partners";
    default:
      return "N/A";
  }
};
export const entityLabel = (url) => {
  switch (url) {
    case routes.partner_management_branch:
      return "Branch";
    case routes.partner_management_provider:
      return "Partner";
    default:
      return "N/A";
  }
};

export const checkBranchOrProvider = (url) => {
  switch (url) {
    case routes.partner_management_provider_add:
      return "Partner Added successfully!";
    case routes.partner_management_branch_add:
      return "Branch Added successfully!";
    case routes.partner_management_provider_edit:
      return "Partner Updated successfully!";
    case routes.partner_management_branch_edit:
      return "Branch Updated successfully!";
    default:
      return "N/A";
  }
};

export const checkBranchOrProviderError = (url) => {
  switch (url) {
    case routes.partner_management_provider_add:
      return "Unable to Add Partner.";
    case routes.partner_management_branch_add:
      return "Unable to Add Branch.";
    case routes.partner_management_provider_edit:
      return "Unable to Update Partner.!";
    case routes.partner_management_branch_edit:
      return "Unable to Update Branch.";
    default:
      return "N/A";
  }
};

export const providerDetailSuccessMessage = (url) => {
  switch (url) {
    case routes.partner_management_provider_detail:
      return "Partner status updated successfully!";
    case routes.partner_management_branch_detail:
      return "Branch status updated successfully!";
    default:
      return "N/A";
  }
};
export const providerActivationSuccessMessage = (url) => {
  switch (url) {
    case routes.partner_management_provider_detail:
      return "Partner activation/deactivation dates updated successfully!";
    case routes.partner_management_branch_detail:
      return "Branch activation/deactivation dates updated successfully!";
    default:
      return "N/A";
  }
};
export const providerDetailErrorMessage = (url, message) => {
  switch (url) {
    case routes.partner_management_provider_detail:
      if(message){
        return message;
      }
      else{
        return "Unable to Update Partner.";
      }
    case routes.partner_management_branch_detail:
      if(message){
        return message;
      }
      else{
      return "Unable to Update Branch.";
      }
    default:
      return "N/A";
  }
};

export const labelforProvider = (url, loadingAdd, loadingEdit) => {
  switch (url) {
    case routes.partner_management_provider_add:
      return loadingAdd ? "Adding..." : "Add";
    case routes.partner_management_branch_add:
      return loadingAdd ? "Adding..." : "Add";
    case routes.partner_management_provider_edit:
      return loadingEdit ? "Updating..." : "Update";
    case routes.partner_management_branch_edit:
      return loadingEdit ? "Updating..." : "Update";
    default:
      return url;
  }
};

export const getPersonaName = (url) => {
  switch (url) {
    case routes.partner_management_branch:
      return "Branch";
    case routes.partner_management_provider:
      return "Partner";
    default:
      return "";
  }
};


export const viewScreensBasedOnPersonaByResourceTypeId = (resourceTypeId) => {
  switch (resourceTypeId) {
    case allPersonaIds.service_supervisor:
      return `${routes.manage_supervisors}/view/`;
    case allPersonaIds.service_coordinator:
      return `${routes.manage_care_coordinators}/view/`;
    case allPersonaIds.service_professional:
      return `${routes.manage_care_givers}/view/`;
    case allPersonaIds.other_resources:
      return `${routes.manage_other_resources}/view/`;
    default:
      return routes.manage_supervisors;
  }
  // `${routes.partner_management_provider}view/`
};


export const redirectURL = (url) => {
  switch (url) {
    case routes.partner_management_provider_add:
      return routes.partner_management_provider;
   
      case routes.partner_management_branch_add:
      return routes.partner_management_branch;

      case routes.partner_management_provider_edit:
      return routes.partner_management_provider;
      case routes.partner_management_branch_edit:
      return routes.partner_management_branch;
      
    default:
      return url;
  }
  // "+ ADD PROVIDER"
};

export const checkUrl = (url) =>{
  switch (url) {

      case routes.partner_management_provider_detail:
      return routes.partner_management_provider;
      case routes.partner_management_branch_detail:
      return routes.partner_management_branch;
      
    default:
      return url;
  }
  
}