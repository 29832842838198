import React, { useEffect } from "react";
import {
    Button,
    Grid,
    IconButton,
    InputLabel,
    Tooltip,
    useTheme,
} from "@material-ui/core";
import { makeStyles, Typography } from "@material-ui/core";
import { fileLessThanTenMb, imageFormats, videoFormats } from "../../utils";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
    container: {
        // border: `1.5px dashed ${theme.palette.primary.main}`,
        borderRadius: 8,
        // padding: 12,
        // backgroundColor: "rgba(255,190,159, 0.04)", // peach shade
        // backgroundColor: "rgba(0, 148, 144, 0.04)", // Turqousie shade
        marginTop: 8,
    },
    // uploadButtonFlex: {
    //   color: theme.palette.primary.main,
    //   display: "flex",
    //   justifyContent: "flex-start",
    //   [theme.breakpoints.down("xs")]: {
    //     justifyContent: "flex-start",
    //   },
    // },
    closeBtn: {
        padding: '0 !important',
        "&.MuiSvgIcon-root": {
            fontSize: '1rem !important'
        }
    }
}));

const FileUploaderVariant = ({file, fileUrl, ...props}) => {


    // const [filename, setFilename] = React.useState("");
    const [uploadError, setUploadError] = React.useState(false);
    const [image, setImage] = React.useState(null);
    const theme = useTheme();
    const classes = useStyles(props);

    useEffect(() => {
        if(file && file.constructor !== Object){
            try{
                let tempObjURL = URL.createObjectURL(file)
                setImage(tempObjURL)
            }
            catch(error){
                console.log(error)
            }
        } else if (fileUrl) {
            setImage(fileUrl)
        }
    }, [file, fileUrl]);
    

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = (event) => {
        //   If the user press cancel while browsing for a file
        if (event.target.files[0]) {
            const fileUploaded = event.target.files[0];
            if (fileLessThanTenMb(fileUploaded.size)) {
                // setFilename(fileUploaded.name);
                setUploadError(false);

                let tempObjURL = URL.createObjectURL(fileUploaded)
                setImage(tempObjURL)
                props.handleFile(fileUploaded);
            } else {
                setUploadError(true);
            }
        }
    };

    const handleRemove = () => {
        // setFilename("");
        hiddenFileInput.current.value = "";
        setImage(null)
        props.handleRemove();
    };


    let filename = props?.value?.constructor === File ? props?.value?.name : file?.constructor === File && image ? file?.name : props?.value
    
    const getFormattedFormats = (formatArray = []) => {
        return formatArray.map((format, index) => { return `.${format}` }).join()
    }

    let acceptedFormats = `${getFormattedFormats(videoFormats)},${getFormattedFormats(imageFormats)}`

    return (
        <>
            <InputLabel shrink>{props.label}</InputLabel>

            <Grid container className={classes.container}>
                <Grid item xs={12} container justify={"center"} className={classes.uploadButtonFlex}>
                    <Button
                        variant={"outlined"}
                        color={"primary"}
                        onClick={handleClick}
                        disabled={filename ? true : false}
                        fullWidth
                    >
                        {filename ? 'Ready to upload' : 'Upload Certificate'}
                    </Button>
                    <input
                        type="file"
                        // accept="video/*,image/*"
                        accept={props?.customExtensions ? props?.customExtensions : acceptedFormats}
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: "none" }}
                    />
                </Grid>
                <Grid container justify="center" item xs={12}>
                    <Typography
                        align="center"
                        variant="caption"
                    // style={{ color: theme.palette.primary.main }}
                    >
                        (Max upload 1 file, size is 10MB)
                    </Typography>
                </Grid>
                {uploadError && (
                    <Grid container justify="center" item xs={12}>
                        <Typography style={{ color: theme.palette.ternary.main }}>
                            File exceeds limit
                        </Typography>
                    </Grid>
                )}
                {filename && (
                    <>
                        <Grid container item xs={12} justify="center" alignContent="center">

                            <Typography align={"center"} style={{ fontWeight: 500 }}>{filename}
                                <Tooltip
                                    title={"Remove"}
                                    placement="bottom"
                                >
                                    <IconButton
                                        className="closeBtn"
                                        onClick={handleRemove}
                                    >
                                        <CloseIcon htmlColor={theme.palette.error.main} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>


                        </Grid>
                    </>
                )}

                {image && <img src={image} alt={"Failed to show preview"} onLoad={() => {
                    if(file){
                        URL.revokeObjectURL(image)
                    }
                }} 
                    style={{
                        margin: "auto",
                        maxWidth: "100%",
                        height: "auto"
                    }}
                />}

                {/* <Avatar src="http://13.71.3.63:5000/DB_AINQA/19268952"/> */}

            </Grid>
        </>
    );
};
export default FileUploaderVariant;
