import React from "react";
import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

export const CollapseIcon = (props) => {
  return (
    <SvgIcon fontSize={"small"} {...props} htmlColor={props.color}>
      <g id="next" transform="rotate(90 5.978 6.397)">
        <g id="Group_2" data-name="Group 2">
          <path
            id="Path_8"
            stroke="#000"
            strokeWidth="0.75px"
            d="M5.873 6.329L.735 11.864a.409.409 0 0 1-.609 0 .492.492 0 0 1 0-.658L4.959 6 .127.794a.492.492 0 0 1 0-.658A.414.414 0 0 1 .431 0a.414.414 0 0 1 .3.136l5.142 5.535a.492.492 0 0 1 0 .659z"
            data-name="Path 8"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

CollapseIcon.propTypes = {
  color: PropTypes.string,
};
