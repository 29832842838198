import React from "react";
import { makeStyles, Grid, Divider, Button, useTheme } from "@material-ui/core";
import DetailSection from "./detailSection";
import PropTypes from "prop-types";
import ProfileWithLabel from "./profileWithLabel";
import { CustomTypography } from "../niceComponents";
import { formatDate } from "../../utils/common";
// import Typography from "material-ui/styles/typography";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    borderRadius: 8,
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    backgroundColor: theme.palette.background.paper,
  },
  detailSection: {
    display: "flex",
  },
  menuItem: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  divider: {
    marginLeft: 16,
    height: 27,
  },
}));

const ListCard = (props) => {
  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleEdit = () => {
  //   setAnchorEl(null);
  //   props.handleEdit(props?.data?.id);
  // };

  // const handleDelete = () => {
  //   setAnchorEl(null);
  //   props.handleDelete(props.data);
  // };

  // const handleDeactivate = () => {
  //   setAnchorEl(null);
  //   props.handleDeactivate(props.data);
  // };

  const classes = useStyles();
  const { onClick = () => {}, data } = props;
  const theme = useTheme();
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid
          item
          className={classes.detailSection}
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={4}
          onClick={() => onClick()}
          // onClick={()=>props.navigateToView(props?.data?.id)}
        >
          {/* {console.log(props.data)} */}

          <DetailSection data={props.data} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          justify={"flex-end"}
          alignItems={"center"}
        >
          {props.data.profiles.map((list) => {
            return list.name === undefined ? (
              !data?.isCancelled ? (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => props.assignCoordinatorDrawer()}
                >
                  Assign Coordinator
                </Button>
              ) : (
                <CustomTypography variant='row1' color={"rgba(146, 151, 165, 1)"}>Unassigned</CustomTypography>
              )
            ) : (
              <Grid item style={{ display: "flex" }}>
                <ProfileWithLabel data={list} />
              </Grid>
            )
          })}

          {true && (
            <Grid item style={{ display: "flex" }}>
              <Divider
                orientation="vertical"
                flexItem
                style={{ marginLeft: 16, marginRight: 16 }}
              />
              {data?.isCancelled ? (
                <div>
                  <CustomTypography
                    variant="row2"
                    weight="bold"
                    color={theme.palette.error.main}
                    align={"flex-end"}
                    textAlign={"right"}
                  >{`Cancelled on ${formatDate(
                    data?.cancelledOn
                  )}`}</CustomTypography>
                  <CustomTypography
                    variant="row3"
                    color={theme.palette.success.main}
                    align={"flex-end"}
                    textAlign={"right"}
                  >
                    {data?.subscriptionStatus?.label}
                  </CustomTypography>
                </div>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={props.disableDeactivateBtn}
                  onClick={props.handleClick}
                  size={"small"}
                  style={{ width: 112 }}
                >
                  {`${props.data?.isactive ? "Deactivate" : "Activate"}`}
                </Button>
              )}

              {!props.isDisableEditButton && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 16, marginRight: 16 }}
                  onClick={props.onEditBtnClicked}
                >
                  {"Edit"}
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

ListCard.propTypes = {
  data: PropTypes.object,
};

export default ListCard;
