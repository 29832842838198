import * as React from "react"

export function CalendarIcon(props) {
  return (
    <svg width={21} height={21} viewBox="0 0 21 21" {...props}>
      <defs>
        <style>{".prefix__cls-1{fill:#353596}"}</style>
      </defs>
      <g
        id="prefix__Upcoming_Activities"
        data-name="Upcoming Activities"
        transform="translate(-3 -3)"
      >
        <path
          id="prefix__Path_599"
          d="M7 11h2v2H7zM4 7h16v2H4zm3 8h2v2H7zm11-3a6 6 0 106 6 6.018 6.018 0 00-6-6zm0 10a4 4 0 114-4 4.012 4.012 0 01-4 4zm.925-1.764l-2.1-2.1 1.6-3.022 1.325.7-1.081 2.042 1.314 1.314z"
          className="prefix__cls-1"
          data-name="Path 599"
        />
        <g id="prefix__Group_3236" data-name="Group 3236">
          <path
            id="prefix__Path_600"
            d="M10.069 19H5V5h14v5.069a7.909 7.909 0 012 .518V5a2 2 0 00-2-2H5a2 2 0 00-2 2v14a2 2 0 002 2h5.587a7.909 7.909 0 01-.518-2z"
            className="prefix__cls-1"
            data-name="Path 600"
          />
          <path
            id="prefix__Path_601"
            d="M11 13h.76a8.025 8.025 0 012.37-2H11z"
            className="prefix__cls-1"
            data-name="Path 601"
          />
        </g>
      </g>
    </svg>
  )
}

