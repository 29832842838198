import React from "react";
import PropTypes from "prop-types";

import { SvgIcon } from "@material-ui/core";

function HealthCareIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="Group 95905">
      <path data-name="Rectangle 47999" fill="none" d="M0 0h24v24H0z" />
      <g data-name="Group 95455" fill={props.color}>
        <path d="M14.347 1.942a3.118 3.118 0 0 0-2.49 1.228 3.108 3.108 0 0 0-2.473-1.228 3.237 3.237 0 0 0-3.227 3.241 4.669 4.669 0 0 0 .192 1.326h1.722l.394-.787a.233.233 0 0 1 .216-.126.229.229 0 0 1 .2.148l.407 1.086.64-3.106a.228.228 0 0 1 .229-.182.228.228 0 0 1 .222.187l.768 4.223.848-2.21a.228.228 0 0 1 .213-.146.229.229 0 0 1 .213.148l.524 1.398.326-.521a.228.228 0 0 1 .193-.107h1.2a.457.457 0 1 1 0 .457H13.59l-.5.806a.233.233 0 0 1-.216.106.229.229 0 0 1-.191-.147l-.478-1.269-.926 2.407a.228.228 0 0 1-.213.146h-.021a.228.228 0 0 1-.2-.187l-.7-3.864-.568 2.727a.229.229 0 0 1-.206.182.226.226 0 0 1-.232-.148l-.5-1.337-.223.449a.228.228 0 0 1-.2.127H6.507c.756 1.851 2.589 3.086 4.016 4.045.462.311 1.188.919 1.2.925a.217.217 0 0 0 .141.049.261.261 0 0 0 .165-.059 14.18 14.18 0 0 1 1.183-.914c1.843-1.239 4.367-2.936 4.367-5.828a3.237 3.237 0 0 0-3.232-3.245Z" />
        <path
          data-name="Path 92946"
          d="M20.215 12.88a2.725 2.725 0 0 0-1.444.458c-.468.3-1.877 1.2-3.095 1.983a1.819 1.819 0 0 0-.747-.907 2.316 2.316 0 0 0-1.227-.353h-2.027a12.117 12.117 0 0 1-2.425-.185 8.872 8.872 0 0 0-1.861-.212 6.081 6.081 0 0 0-2.257.563 5.235 5.235 0 0 0-1.772 1.336 8.933 8.933 0 0 0-1.366 1.821.518.518 0 0 0-.018.455.642.642 0 0 0 .362.333.8.8 0 0 0 .53.016.662.662 0 0 0 .388-.311 7.762 7.762 0 0 1 1.164-1.551 3.956 3.956 0 0 1 1.295-1.019 4.6 4.6 0 0 1 1.67-.458 7.066 7.066 0 0 1 1.574.185 14.147 14.147 0 0 0 2.71.212h2.027a.775.775 0 0 1 .4.108.617.617 0 0 1 .253.283.516.516 0 0 1 .02.356.592.592 0 0 1-.221.3l-.019.012-.039.027a.783.783 0 0 1-.388.1H10.47a.754.754 0 0 0-.49.174.537.537 0 0 0 0 .841.754.754 0 0 0 .49.174h3.231a2.3 2.3 0 0 0 1.173-.317.7.7 0 0 0 .083-.046s3.946-2.53 4.637-2.972a1.226 1.226 0 0 1 .631-.223.6.6 0 0 1 .235.044.527.527 0 0 1 .19.126.591.591 0 0 1 .152.19.508.508 0 0 1 0 .452.587.587 0 0 1-.149.191c-2.471 1.785-4.026 2.973-5.053 3.67s-1.476.874-1.9.874c-1.686 0-3.553-.4-5.538-.4a3.39 3.39 0 0 0-1.34.269 2.923 2.923 0 0 0-1.057.757 2.954 2.954 0 0 0-.582 1.005.514.514 0 0 0-.019.235.541.541 0 0 0 .089.223.635.635 0 0 0 .182.176.747.747 0 0 0 .248.1.8.8 0 0 0 .274.011.766.766 0 0 0 .257-.082.662.662 0 0 0 .2-.161.558.558 0 0 0 .112-.215 1.766 1.766 0 0 1 .343-.583 1.418 1.418 0 0 1 .561-.424 1.667 1.667 0 0 1 .732-.131c1.757 0 3.617.4 5.538.4a4.9 4.9 0 0 0 2.762-1.123c1.092-.741 2.627-1.916 5.083-3.692a.686.686 0 0 0 .084-.061.01.01 0 0 1 .003-.007l.024-.022a.588.588 0 0 0 .152-.212 1.6 1.6 0 0 0 .394-1.17 1.666 1.666 0 0 0-.58-1.111 2.046 2.046 0 0 0-.647-.376 2.29 2.29 0 0 0-.76-.136Z"
        />
      </g>
    </g>
    </SvgIcon>
  );
}

HealthCareIcon.propTypes = {
  color: PropTypes.string,
};

HealthCareIcon.defaultProps = {
  color: "#4a90e2",
};

export default HealthCareIcon;
