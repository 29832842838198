import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Grid, List, ListItem, ListItemText, Typography, IconButton, withStyles } from '@material-ui/core'
// Grid
// import { HybridTypography } from '../hybridTypography'
// import { ICONS } from '../hybridTypography/typography'
import { customAddress, formComponentTypes } from '../../utils';
import ViewAllCards from './viewAllCards';
import { FILE_URI } from '../../utils/constants';
import withAlelrtAndDialog from '../../HOCs/withAlelrtAndDialog';
// import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';
// import EmailIcon from '@material-ui/icons/Email';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { EmailIconV2, IdCardIconV2, LocationIconV2, MobileIconV2 } from '../../assets';
// import { LocationIconV2 } from '../../assets';

const style = ((theme) => ({
    iconSize: {
        fontSize: "1.05rem"
    },
    listTop: {
        marginTop: 26,
    },
    listMiddle: {
        marginTop: 8,
        marginBottom: 8,
    },
}))

class ViewCardAddressEmailPhone extends Component {
    state = {
        previewImage: null,
        imageSrcError: false
    }

    componentDidMount() {
        if (this.props.isTypeId) {
            this.renderPreviewImage()
        }
    }


    renderPreviewImage = () => {
        const { data } = this.props
        const firstElement = data?.[0]
        if (firstElement?.id_photo) {
            this.setState({
                previewImage: `${FILE_URI}/${firstElement?.id_photo}`
            })
        }
    }

    primaryTextRenderer = (data) => {
        if (data) {
            switch (this.props.element) {
                case formComponentTypes.ADD_ADDRESS:
                    return data.label
                case formComponentTypes.ADD_EMAIL:
                    return data.label
                case formComponentTypes.ADD_PHONE_NUMBER:
                    return data.label
                case formComponentTypes.ADD_ID_TYPE:
                    return data.label
                default: return <></>
            }
        }
        else {
            return null
        }
    }


    secondaryTextRenderer = (data) => {

        if (data) {

            switch (this.props.element) {
                case formComponentTypes.ADD_ADDRESS:
                    return customAddress(
                        data.addressLine1,
                        data.addressLine2,
                        data.area,
                        data.state,
                        data.country,
                        data.postalCode
                    )
                case formComponentTypes.ADD_EMAIL:
                    return data.email
                case formComponentTypes.ADD_PHONE_NUMBER:
                    return `${data.mobileNoIsdCode} ${data.mobileNo}`
                case formComponentTypes.ADD_ID_TYPE:
                    return data.id_type
                default: return <></>
            }
        }
        else {
            return 'N/A'
        }
    }

    handleOpenMoreDrawer = () => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: (
                <ViewAllCards
                    isTypeId={formComponentTypes.ADD_ID_TYPE === this.props.element}
                    elementType={this.props.element}
                    items={this.props.data}
                />
            ),
        });
    }

    getItemDetail = (elementType) => {
        const { classes, theme } = this.props
        switch (elementType) {
            case formComponentTypes.ADD_ADDRESS:
                return {
                    title: "ADDRESS",
                    icon: <LocationIconV2 fontSize="small" />
                }
            case formComponentTypes.ADD_EMAIL:
                return {
                    title: "EMAIL ADDRESS",
                    icon: <EmailIconV2 fontSize="small" />
                }
            case formComponentTypes.ADD_PHONE_NUMBER:
                return {
                    title: "CONTACT NUMBER",
                    icon: <MobileIconV2 fontSize="small" />
                }
            case formComponentTypes.ADD_ID_TYPE:
                return {
                    title: "ID DETAIL",
                    icon: <IdCardIconV2 fontSize="small" />
                }
            default: return {
                title: "DETAIL",
                icon: <BusinessIcon fontSize={"small"} htmlColor={theme.palette.primary.main} classes={{ fontSizeSmall: classes.iconSize }} />
            }
        }
    }

    render() {
        const { data, element, classes } = this.props;
        // const { label, onButtonClickHandler, nolabel, labels } = this.props;

        // const isTypeID = formComponentTypes.ADD_ID_TYPE === element

        return (
            <>
                {/* <HybridTypography
                    icon={ICONS.BUSINESS(nolabel ? "white" : "#28a9c1")}
                    id={this.props?.id}
                    nolabel={nolabel}
                    text={
                        <>
                            <div style={{ display: "flex" }}>
                                {this.customConverter(data?.[0])}
                                {(nolabel && <span onClick={() => { onButtonClickHandler() }} style={{ padding: 0, marginLeft: 10, cursor: "pointer", textTransform: "capitalize" }}>View More</span>)}
                            </div>
                            {(!nolabel && data?.length > 1) && <Button variant="text" color="primary" style={{ padding: 0, marginLeft: 10 }} onClick={() => { onButtonClickHandler() }}>View More</Button>}
                        </>
                    }
                    header={label ? (`${label}`).toUpperCase() : labels ? (`${labels}`).toUpperCase() : nolabel ? "" : "N/A"}
                    // loading={loading}
                    islistbottom={nolabel ? false : true}
                    islisttop={nolabel ? false : true}
                /> */}


                {/* <Typography className={classes.title}>{"ID Types"}</Typography> */}
                <Grid container>
                    <Grid item xs={12} className={`${this.props.isListTop && classes.listTop} ${this.props.isListMiddle && classes.listMiddle}`}>
                        <Grid item container style={{paddingTop: 4}}>
                            <Grid item style={{ display: "flex", alignItems: "center" }}>
                                {this.getItemDetail(element)?.icon}
                            </Grid>
                            <Grid item xs>
                                <Typography style={{ color: "rgba(146, 151, 165, 1)", fontSize: 13, marginLeft: 8, textTranform: "uppercase" }} >{this.getItemDetail(element)?.title}</Typography>
                                {/* <InputLabel shrink>{this.getTitle(element)}</InputLabel> */}
                            </Grid>
                        </Grid>

                        <Grid item container>

                            <Grid item>
                            <PermIdentityIcon fontSize={"small"} htmlColor={"#28a9c1"} classes={{ fontSizeSmall: classes.fontSize }} style={{visibility: "hidden"}} />
                            </Grid>
                            <Grid item xs>
                                <div style={{ display: "flex", marginLeft: 8 }}>
                                    <List dense={true} disablePadding>
                                        <ListItem style={{ paddingLeft: 0 }}>
                                            {/* <ListItemIcon style={{ justifyContent: "center" }}>
                                        <PhoneIcon />
                                    </ListItemIcon> */}
                                            <ListItemText
                                                primary={this.primaryTextRenderer(data?.[0])}
                                                secondary={this.secondaryTextRenderer(data?.[0])}
                                            />
                                        </ListItem>
                                    </List>

                                    <Typography variant="body1" style={{ display: "flex", alignItems: "center" }}>
                                        {data?.length > 1 && <IconButton onClick={() => this.handleOpenMoreDrawer()} id={`viewMoreButton${this.props.id}`} size={"small"} style={{ border: "solid 1px #D3DADD", backgroundColor: "#252B430D", fontSize: "0.925rem" }}>+{data?.length - 1}</IconButton>}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </>
        )
    }
}

ViewCardAddressEmailPhone.propTypes = {

};

export default withAlelrtAndDialog(withStyles(style, {withTheme: true})(ViewCardAddressEmailPhone));


