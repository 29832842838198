import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { EquipmentError } from './error';

export const RecommendedEquipmentErrors = (props) => {


  const {
    count
  } = props;


  return <>
    {
      Array.apply(null, Array(count)).map((value, index) => <Grid key={index} item xs={12} sm={6} md={3} lg={3} xl={3}>
        <EquipmentError />
      </Grid>)
    }
  </>
}

RecommendedEquipmentErrors.propTypes = {
  count: PropTypes.number
}

RecommendedEquipmentErrors.defaultProps = {
  count: 1
}