import { filterType } from "../../utils";

export const actionsCall = (props, state) => {
  return {
    [filterType.SKILLS]: () => props.GET_SKILLS({ ...state }),
    [filterType.CATEGORY]: () => props.GET_CATEGORIES({ ...state }),
    [filterType.SPECIALIZATION]: () =>
      props.GET_MASTER_SPECIALIZATION({ ...state }),
    [filterType.STATUS]: () => {},
    [filterType.SORT]: () => {},
    [filterType.DATE_SORT]: () => {},
    [filterType.PRICE_SORT]: () => {},
    [filterType.PRODUCTS]: () => {},
    [filterType.SERVICE_PROFESSIONAL]: () => {},
    [filterType.SERVICE_CORDINATOR]: () => {},
    [filterType.PROVIDER]: () => {},
    [filterType.SERVICES]: () => {},
    [filterType.PACKAGES]: () => {},
    [filterType.SORTDIRECTION]: () => {},
    [filterType.SORTFIELD]: () => {},
    [filterType.GENDER]: () => {},
    [filterType.ASSIGN_TO]: () => {
      props.ASSIGNED_TO({ ...state });
    },
    [filterType.ENQUIRY_TYPE]: () => {
      props.GET_MASTER_LEAD_TYPES({ ...state });
    },
    [filterType.ROLE]: () => {
      props.GET_MASTER_ROLES({ ...state });
    },
    [filterType.CREATED_BY]: () => {},
  };
};

export const statusOptions = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "InActive",
    label: "In Active",
  },
  // {
  //     value: "Invited",
  //     label: "Invited"
  // }
  //Commented temporarily
  // {
  //     value: "Both",
  //     label: "Both"
  // }
];
export const openStatus = [
  // {
  //     value: "OPEN",
  //     label: "Open"
  // },
  // {
  //     value: "CLOSED",
  //     label: "Closed"
  // },
  //Commented temporarily
  // {
  //     value: "Both",
  //     label: "Both"
  // }
  {
    value: "Enquiry Created",
    label: "Enquiry Created",
  },
  {
    value: "Quote Submitted",
    label: "Quote Submitted",
  },
  {
    value: "Quote Finalized",
    label: "Quote Finalized",
  },
  {
    value: "Enquiry Cancelled",
    label: "Enquiry Cancelled",
  },
  {
    value: "Payment Completed",
    label: "Payment Completed",
  },
];

export const dateOptions = [
  {
    value: "most_recent",
    label: "Most Recent",
  },
  {
    value: "least_recent",
    label: "Least Recent",
  },
];

export const createdByOptions = [
  { label: "Super Admin", value: "super-admin" },
  { label: "Managedcare Admin", value: "cross-bundling" },
];
