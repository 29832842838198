import React from 'react'
import {
    Grid, Typography, List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Button,
    IconButton,
    ListItemSecondaryAction,
    Divider,
    withStyles
} from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { PreviewPngIcon } from "../../../assets";
import { FILE_URI } from '../../../utils/constants';

import withAlelrtAndDialog from '../../../HOCs/withAlelrtAndDialog';

const style = ((theme) => ({
    listWrapper: {
        width: "100%",
        display: "flex"
    },
    addIdButtonText: {
        color: theme.palette.ternary.main,
        fontWeight: 600
    },
    gutter: {
        padding: "16px 16px"
    },
    gutterTop: {
        padding: "16px 16px 0px"
    },
    gutterMiddle: {
        padding: "0px 16px"
    },
    gutterBottom: {
        padding: "0px 16px 16px"
    },
    imageStyle: {
        [theme.breakpoints.down("md")]: {
            width: 320,
            height: 250,
        },
        [theme.breakpoints.up("md")]: {
            width: "100%",
            height: "100%",
            objectFit: "cover"
        },
    },
    listItemSecondaryAction: {
        paddingRight: 90
    }
}))

class ViewAllCards extends React.Component {

    state = {
        idType: [],
        previewImage: [],
        imageSrcError: {}
    }

    componentDidMount() {
        this.setState({
            idType: [...this.props.items]
        }, () => {
            this.renderPreviewImage()
        })
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.editData !== this.props.editData) {
    //         this.renderPreviewImage();
    //     }
    // }


    renderPreviewImage = () => {
        const { idType } = this.state

        let previewImage = Array.from(new Array(idType?.length))

        if (idType?.length > 0) {
            idType.map((item, index) => {
                if (item?.file && item?.file.constructor !== Object) {
                    try {
                        let tempObjURL = URL.createObjectURL(item?.file)
                        previewImage[index] = tempObjURL
                    }
                    catch (error) {
                        console.log(error)
                    }
                } else if (item?.id_photo) {
                    previewImage[index] = `${FILE_URI}/${item?.id_photo}`
                }
                return item
            })
        }

        this.setState({
            previewImage: previewImage
        })
    }

    handleImageError = (index) => {
        let { imageSrcError } = this.state
        imageSrcError[index] = true
        this.setState({
            imageSrcError: imageSrcError
        })
    }

    callBackOnSaveIdType = (data, isremove) => {
        if (isremove) {
            let isUpdateData = this.state.idType?.filter(
                (val) => val.value !== data?.value
            );

            this.setState({ idType: isUpdateData }, () => {
                this.props.onRemove(data, true)
            });
        }
    };

    handleImagePreviewDialog = (image, item) => {
        const { classes } = this.props

        // Display the content in a model
        this.props.dialog.setDialog({
            ...this.props.dialog,
            open: true,
            title: item?.label,
            body: <div style={{paddingTop: 16}}>
                <img src={image} className={classes.imageStyle} alt={`Failed to show document.`}
                    onLoad={() => {
                        if (item?.file) {
                            URL.revokeObjectURL(image)
                        }
                    }}
                />
            </div>,
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            dontShowButtons: true,
            onOk: () => { },
            onCancel: () => {
                this.props.dialog.setDialog({
                    ...this.props.dialog,
                    open: false,
                    title: ``,
                    body: "",
                    positiveBtn: "Ok",
                    negativeBtn: "CANCEL",
                    dontShowButtons: false,
                });
            },
        });
    }

    render() {
        const { classes } = this.props
        const { idType, previewImage, imageSrcError = {} } = this.state
        const { onAdd, onEdit } = this.props
        return (
            <div>
                <Grid container>
                    <Grid item container xs={12} className={classes.gutter}>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>ID Detail</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', justifyContent: "flex-end" }}>
                            <Button style={{boxShadow: "none"}}>
                                <Typography id={this.props?.id?.add_drawer_id_type_id} onClick={onAdd} variant={"body2"} className={classes.addIdButtonText}>+ ADD ID</Typography>
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider light />
                    </Grid>

                    {idType?.map((item, index) => {
                        return (
                            <Grid item xs={12}>
                                <div className={classes.listWrapper}>
                                    <List dense={true} style={{ width: "100%" }} >
                                        <ListItem divider classes={{ secondaryAction: classes.listItemSecondaryAction}}>
                                            <ListItemIcon style={{ justifyContent: "flex-start" }}>
                                                {/* <PreviewPngIcon style={{ visibility: Boolean(item?.id_photo) ? "unset" : "hidden" }} /> */}

                                                {(imageSrcError?.[index] ? imageSrcError?.[index] : false) && <PreviewPngIcon style={{ visibility: Boolean(item?.id_photo) ? "unset" : "hidden" }} />}
                                                {(previewImage?.length >= index) && previewImage?.[index] && !imageSrcError?.[index] && <img src={previewImage[index]} alt={"Failed to show preview"}

                                                    onError={() => {
                                                        this.handleImageError(index, item)
                                                    }}
                                                    style={{
                                                        visibility: Boolean(item?.id_photo) ? "unset" : "hidden",
                                                        height: "34px",
                                                        width: "34px",
                                                        borderRadius: 4,
                                                        objectFit: "cover",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => this.handleImagePreviewDialog(previewImage[index])}
                                                    id={this.props?.id?.view_document_drawer_id_type_id}
                                                />}


                                            </ListItemIcon>
                                            <ListItemText
                                                primary={item?.label ? item?.label : "NA"}
                                                secondary={item?.id_type ? item?.id_type : null}
                                            />
                                            <ListItemSecondaryAction>
                                                <div style={{ display: "flex", gap: 16 }}>
                                                    <IconButton id={this.props?.id?.edit_drawer_id_type_icon_id} onClick={() => onEdit(item)} edge="end" aria-label="edit" size={"small"}>
                                                        <EditOutlinedIcon fontSize={"small"} />
                                                    </IconButton>
                                                    <IconButton id={this.props?.id?.delete_drawer_id_type_icon_id} onClick={() => { this.callBackOnSaveIdType(item, true) }} edge="end" aria-label="delete" size={"small"}>
                                                        <DeleteOutlinedIcon fontSize={"small"} />
                                                    </IconButton>
                                                </div>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        )
    }
}

export default withAlelrtAndDialog(withStyles(style)(ViewAllCards));
