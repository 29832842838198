import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import ActivityAndMembersCard from '../../../../components/availabilityActivityComponents/ActivityAndMembersCard'
import { withAlertAndDialog } from '../../../../HOCs';
import ServiceProfessionalActivity from './serviceProfessionalActivity';
import moment from 'moment';
import AvailabilitySummary from '../availabilitySummary';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

class AvailableList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    onEndIconClickHandler = (data) => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: <AvailabilitySummary
                onBackBtnClicked={this.onBackBtnClicked}
                onEditBtnClicked={this.onEditBtnClicked}
                drawer={this.props.drawer}
                availabilityData={data}
                reload={this.props.reload}
            />
        })
    }

    onBackBtnClicked = () => {
        this.props.onUnAvailabilityClicked(this.props.data)
    }

    onEditBtnClicked = (data) => {
        // This id is fabId, used to choose, activity (or) availability.
        // 1 - activity
        // 2 - availability
        this.props.onEditBtnClicked(2, data)
    }

    // DEAD LINK FUNCTION
    onTitleClickHandler = (data) => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: <ServiceProfessionalActivity
                drawer={this.props.drawer}
                service_professional={data?.service_professional_info}
                handleBackIconClick={this.handleBackIconClick}
            />
        })
    }

    // DEAD LINK FUNCTION
    handleBackIconClick = () => {
        const {
            data,
            isUnavailable,
            onUnAvailabilityClicked,
            onAvailabilityClicked
        } = this.props;

        if (isUnavailable) {
            onUnAvailabilityClicked(data);
        } else {
            onAvailabilityClicked(data)
        }
    }

    render() {

        const {
            data,
            isUnavailable,
        } = this.props;

        return <>
            <Grid container>
                <Grid item xs={12} style={{ padding: 12 }}>
                    <Typography variant="h6">{`${data?.length ?? "0"} ${isUnavailable ? "Unavailability" : "Availability"}`}</Typography>
                </Grid>
                {data?.length > 0 && data?.map((item, index) => {
                    return <ActivityAndMembersCard
                        {...item}
                        key={index}
                        useActivityAsViewCaregivers
                        profilePicture={item?.service_professional_info?.profile_pic}
                        title={item?.service_professional_info?.name}
                        timing={`${moment(item?.fromTime).format('ddd, MMM D [•] hh:mm A')} - ${moment(item?.toTime).format('ddd, MMM D [•] hh:mm A')}`}
                        onEndIconClickHandler={this.onEndIconClickHandler}
                        // onTitleClickHandler={() => this.onTitleClickHandler(item)}
                        hideEndIcon={false}
                        customEndIcon={<ChevronRightIcon htmlColor="#252b43" fontSize="small" />}
                    />
                })}
            </Grid>
        </>
    }
}

export default withAlertAndDialog(AvailableList);