import React from "react";
import { Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import { Underline } from "./underline";
import { textClampStyleGenerator } from "../../utils";
import Skeleton from "@material-ui/lab/Skeleton";
import { getTrialText } from "../../utils/common";

const statusStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
  },
  dot: {
    height: "8px",
    width: "8px",
    borderRadius: "50%",
    display: "inline-block",
  },
  activeDot: {
    backgroundColor: theme.palette.success.main,
  },
  inactiveDot: {
    backgroundColor: theme.palette.error.main,
  },
  invitedDot: {
    backgroundColor: theme.palette.warning.main,
  },
  active: {
    color: theme.palette.success.main,
  },
  inactive: {
    color: theme.palette.error.main,
  },
  invited: {
    color: theme.palette.warning.light,
  },
  status: {
    lineHeight: 1.5,
  },
}));

export function ItemStatus({ isactive, emailVerified, loading }) {
  const classes = statusStyles();
  const theme = useTheme();

  // Handlers
  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return theme.palette.success.main;
      case 2:
        return theme.palette.error.main;
      case 3:
        return theme.palette.warning.light;
      default: {
        throw new Error(`Unexpected status: ${status}`);
      }
    }
  };

  // Component Integration
  const activeStatus =
    emailVerified === false
      ? 3
      : isactive?.toString()?.toLowerCase() === "active"
      ? 1
      : 2;
  const statusText = ["ACTIVE", "INACTIVE", "INVITED"];

  return (
    <>
      {loading && <></>}
      {!loading && (
        <div className={classes.root}>
          <span
            className={classes.dot}
            style={{ backgroundColor: getStatusColor(activeStatus) }}
          ></span>
          <Typography
            variant="body2"
            className={classes.status}
            style={{ color: getStatusColor(activeStatus) }}
          >
            {statusText[activeStatus - 1]}
          </Typography>
        </div>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  overline: {
    display: "flex",
    // alignItems: "center",
    gap: 8,
  },
  itemTitle: {
    fontSize: "1.1rem",
  },
  underline: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 8,
    color: theme.palette.text.secondary,
  },
  providerLine: {
    // display: "flex",
    // alignItems: "center",
    // gap: 8,
    marginTop: 4,
    color: theme.palette.text.secondary,
  },
}));

export function CatalogueDetail({
  detail,
  loading,
  showProvider,
  trialExpiry,
  showTrialExpiry,
  hideStatus = false,
  ...props
}) {
  const classes = useStyles(props);
  let name = detail?.name?.split(".");
  return (
    <Grid
      item
      xs={12}
      container
      direction="column"
      justify="center"
      wrap="nowrap"
    >
      <Grid item xs={12} className={classes.overline}>
        {loading && (
          <Skeleton variant="text" className={classes.skeleton} width={120} />
        )}
        {!loading && (
          <Typography className={classes.itemTitle}>
            {name?.[0] === "other" ? name?.[1] : detail?.name}
          </Typography>
        )}
        {!hideStatus && (
          <ItemStatus
            isactive={detail?.isactive}
            emailVerified={detail?.emailVerified}
            loading={loading}
          />
        )}
      </Grid>
      <Grid item xs={12} className={classes.underline}>
        {/* list?.role?.[0]?.label */}
        <Underline
          type={detail?.role}
          gender={detail?.gender}
          age={detail?.dob}
          category={detail?.category}
          loading={loading}
        />
      </Grid>

      {showProvider && (
        <Grid item xs={12} className={classes.providerLine} zeroMinWidth>
          {/* Grid item to render PROVIDER of the card */}
          {showProvider && (
            <ItemProviderRenderer
              providerName={detail?.care_provider?.[0]?.name}
              loading={loading}
            />
          )}
        </Grid>
      )}

      {showTrialExpiry && trialExpiry && (
        <Grid item xs={12} className={classes.providerLine} zeroMinWidth>
          {/* Grid item to render trialExpiry of the card */}
          {showTrialExpiry && trialExpiry && (
            <ItemExpiryRenderer trialExpiry={trialExpiry} loading={loading} />
          )}
        </Grid>
      )}
    </Grid>
  );
}

const itemProviderStyles = makeStyles((theme) => ({
  root: {
    ...textClampStyleGenerator(),
    color: "inherit",
    userSelect: "none",
  },
}));

function ItemProviderRenderer({ providerName, loading }) {
  const classes = itemProviderStyles();
  return (
    <>
      {loading && (
        <Skeleton variant="text" className={classes.skeleton} width={120} />
      )}
      {!loading && (
        <Typography
          variant="body2"
          // noWrap
          className={classes.root}
          title={providerName ?? ""}
        >
          {!!providerName ? providerName : "N/A"}
        </Typography>
      )}
    </>
  );
}

const itemExpiryStyles = makeStyles((theme) => ({
  root: {
    ...textClampStyleGenerator(),
    color: "#F03E50",
    // fontStyle: "italic",
  },
}));

function ItemExpiryRenderer({ trialExpiry, loading }) {
  const classes = itemExpiryStyles();

  return (
    <>
      {loading && (
        <Skeleton variant="text" className={classes.skeleton} width={120} />
      )}
      {!loading && (
        <Typography
          variant="caption"
          className={classes.root}
          title={getTrialText(trialExpiry)}
        >
          {getTrialText(trialExpiry)}
        </Typography>
      )}
    </>
  );
}
