import React from "react";
import { makeStyles, Typography, Grid, Zoom } from "@material-ui/core";
import PropTypes from "prop-types";
import Tooltip from '@material-ui/core/Tooltip';
import { CustomAvatar } from "../niceComponents";

const useStyle = makeStyles((theme) => ({
  avatar: {
    height: 40,
    width: 40,
    padding: 8,
    // margin: "auto",
    backgroundColor: theme.palette.secondary.light,
  },
  textArea: {
    marginLeft: 16,
  },
  textAreaHeader: {
    display: "flex",
    flexDirection: "column",
  },
  textAreaFooter: {
    display: "flex",
    marginTop: 6,
  },
  activecircle: {
    marginLeft: 10,
    marginTop: 6,
    width: 9,
    height: 9,
    background: theme.palette.success.main,
    borderRadius: "50%",
  },
  inactivecircle: {
    marginLeft: 10,
    marginTop: 6,
    width: 9,
    height: 9,
    background: theme.palette.error.main,
    borderRadius: "50%",
  },
  active_name: {
    display: "inline",
    paddingLeft: "6px",
    fontSize: "14px",
    color: theme.palette.success.main,
  },
  inactive_name: {
    display: "inline",
    paddingLeft: "6px",
    fontSize: "14px",
    color: theme.palette.error.main,
  },
  careProviderName: {
    backgroundColor: theme.palette.secondary.light,
    padding: "4px 8px 4px 8px",
    borderRadius: 4,
  },
  divider: {
    marginLeft: 16,
    height: 27,
  },
  category: {
    padding: "4px 0px 4px 0px",
    marginLeft: 16,
  },
  textCommonClass: {
    width: 120,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  profile: {
    color: theme.palette.secondary.main,
    fontSize: '0.8rem'
  }
}));
const ProfileWithLabel = (props) => {
  const classes = useStyle();

  const data = props?.data;

  return (
    <>
      <Grid item>
        {/* {data.profile_pic ?
          <Avatar src={data.profile_pic} alt="pic"
            className={data.profile_pic ? '' : classes.avatar} />
          :
          <Avatar>
            {data?.name?.[0]?.toUpperCase()}
          </Avatar>
        } */}

        <CustomAvatar src={data.profile_pic} name={data?.name} size={40} />

      </Grid>
      <Grid item xs>
        <div className={classes.textArea}>
          <div className={classes.textAreaHeader}>
            <Tooltip title={data?.name ? data?.name : 'N/A'} placement="top-start" TransitionComponent={Zoom}>
              <Typography variant="body1" className={classes.textCommonClass}>
                {data?.name ? data?.name : 'N/A'}
              </Typography>
            </Tooltip>
            <Typography variant="body1" className={`${classes.textCommonClass} ${classes.profile}`}>
              {data?.role}
            </Typography>
          </div>
        </div>
      </Grid>
    </>
  );
};

ProfileWithLabel.propTypes = {
  name: PropTypes.string,
  profile: PropTypes.string,
};

export default ProfileWithLabel;
