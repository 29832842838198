// MyGoogleMaps.js
//  https://www.freakyjolly.com/google-maps-in-react-example-application/#.X5z9vYgzZPY
import React, { Component } from "react";

import GoogleMapReact from "google-map-react";

import styled from "styled-components";

import AutoComplete from "./AutoCompleteSearchLocationInGoogleMap";
import Marker from "./Marker";
import { Typography, withStyles } from "@material-ui/core";
import { TextField } from "../../inputs";

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

const styles = (theme) => ({
  autocomplete: {
    margin: "20px 0px",
  },
});

class MyGoogleMap extends Component {
  state = {
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: [],
    zoom: 9,
    address: "",
    draggable: true,
    lat: null,
    lng: null,
    addressComponents: null,
  };

  componentWillMount() {
    this.setCurrentLocation();
  }

  onMarkerInteraction = (childKey, childProps, mouse) => {
    this.setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    },()=>{this.props.getState(this.state)});
  };
  onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    this.setState({ draggable: true });
    this._generateAddress();
  };

  _onChange = ({ center, zoom }) => {
    this.setState({
      center: center,
      zoom: zoom,
    },()=>{this.props.getState(this.state)});
  };

  _onClick = (value) => {
    this.setState({
      lat: value.lat,
      lng: value.lng,
    },()=>{this.props.getState(this.state)});
  };

  apiHasLoaded = (map, maps) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });

    this._generateAddress();
  };

  addPlace = (place) => {
    this.setState({
      places: [place],
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    this._generateAddress();
  };

  _generateAddress() {
    const { mapApi } = this.state;

    const geocoder = new mapApi.Geocoder();

    geocoder.geocode(
      { location: { lat: this.state.lat, lng: this.state.lng } },
      (results, status) => {
        console.log(results);
        console.log(status);
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            
            this.setState({ address: results[0].formatted_address, addressComponents: results[0].address_components  },()=>{this.props.getState(this.state)});
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  // Get Current Location Coordinates
  setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          center: [position.coords.latitude, position.coords.longitude],
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }

  render() {
    const {  mapApiLoaded, mapInstance, mapApi } = this.state;
    const { classes } = this.props;

    return (
      <Wrapper>
        {mapApiLoaded && (
          <div className={classes.autocomplete}>
            <AutoComplete
              map={mapInstance}
              mapApi={mapApi}
              addplace={this.addPlace}
              label={"SEARCH BY PLACE"}
            />
          </div>
        )}
        <GoogleMapReact
          center={this.state.center}
          zoom={this.state.zoom}
          draggable={this.state.draggable}
          onChange={this._onChange}
          onChildMouseDown={this.onMarkerInteraction}
          onChildMouseUp={this.onMarkerInteractionMouseUp}
          onChildMouseMove={this.onMarkerInteraction}
          onChildClick={() => console.log("child click")}
          onClick={this._onClick}
          bootstrapURLKeys={{
            key: "AIzaSyBqeACJgp12OLU6EkHeUtFgya2naH1LhrU",
            libraries: ["places", "geometry"],
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
        >
          <Marker
            text={this.state.address}
            lat={this.state.lat}
            lng={this.state.lng}
          />
        </GoogleMapReact>
        <Typography variant="caption" component="div">
          Location : {this.state.lat}, {this.state.lng}
        </Typography>
        <div style={{ margin: "20px 0px" }}>
          <TextField
            type="text"
            title="Flat No (Optional)"
            placeholder=""
            // id="name"
            id="00e30110-0452-4c71-ac80-b0830c295b73_FLAT_NO"
            value={this.props.address}
            handleOnChange={(e) => {
              this.props.getState({...this.state,address:e.target.value});
            }}
          />
        </div>
      </Wrapper>
    );
  }
}

export default withStyles(styles)(MyGoogleMap);
