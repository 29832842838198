/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-01-27 20:47:19
 * @modify date 2022-01-27 20:47:19
 */
import React from "react";
import PropTypes from "prop-types";

import { SvgIcon } from "@material-ui/core";

function IdCardIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="id card icon" stroke={props.color}>
        <g
          data-name="Rectangle 47943"
          transform="translate(0 4.25)"
          fill="#fff"
          strokeWidth={1.5}
        >
          <rect width={24} height={16} rx={3} stroke="none" />
          <rect
            x={0.75}
            y={0.75}
            width={22.5}
            height={14.5}
            rx={2.25}
            fill="none"
          />
        </g>
        <path
          data-name="icons8-id-card (2)"
          d="M3.933 8.398a.548.548 0 0 0-.565.528V15.9a.548.548 0 0 0 .565.528h6.283a.548.548 0 0 0 .565-.528V8.925a.548.548 0 0 0-.565-.528Zm.565 1.056H9.65v5.919H4.498Zm9.328.843a.529.529 0 1 0 0 1.056h3.153a.529.529 0 1 0 0-1.056Zm0 3.371a.529.529 0 1 0 0 1.056h5.982a.529.529 0 1 0 0-1.056Z"
          fill={props.color}
          strokeWidth={0.5}
        />
      </g>
    </SvgIcon>
  );
}

IdCardIcon.propTypes = {
  color: PropTypes.string,
};

IdCardIcon.defaultProps = {
  color: "#0063e7",
};

export default IdCardIcon;
