import React from "react";
import {
    makeStyles,
    Grid,
    ListItem,
    List,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
} from "@material-ui/core";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const styles = makeStyles((theme) => ({
    root: {
        boxShadow: "0px 0px 0px 1px #e0e0e0d6",
        borderRadius: 8,
        background: "#fff",
        // marginBottom: 12
    },
    upperPart: {
        borderBottom: `solid 1px #e9e9e9`,
    },
    list: {
        width: "100%",
        padding: "0px 12px",
        height: "94px",
        background: "#fafafa",
        [theme.breakpoints.down("xs")]: {
            padding: "0px 0px",
        },
    },
    listItem: {
        height: "94px",
        padding: "0px 12px"
    },
    avatar: {
        width: "100%",
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        height: "100%",
        background: "#e9e9e9",
        display: "flex",
        "& img": {
            margin: "auto"
        }
    },
    title_: {
        fontSize: 17,
        lineHeight: 1.7,
        [theme.breakpoints.down("xs")]: {
            fontSize: 16,
        },
    },
    sub_text: {
        fontSize: 14,
        lineHeight: 1.6,
        margin: "8px 0px",
        [theme.breakpoints.down("xs")]: {
            fontSize: 14,
        },
    },
    footer: {
        fontWeight: 400,
        fontSize: 12,
    },
    right_lg: {
        // marginRight: 30,
        [theme.breakpoints.down("xs")]: {
            marginRight: 0,
        },
    },
    right_: {
        marginRight: "-9px !important",
        [theme.breakpoints.down("xs")]: {
            marginRight: 0,
        },
    }
}))


const ExperiencecardList = (props) => {

    const [state, setState] = React.useState({
        data: props.data
    })

    React.useEffect(() => {
        if (props.data) {
            setState({ ...state, data: props.data })
        }
        // eslint-disable-next-line
    }, [props.data])

    const classes = styles(props);


    const dateformat = (st, snd, present) => {
        if (present) {
            const a = moment(st).format("DD MMM, YYYY");
            const c = a + " - Present";
            return c
        } else {
            const a = moment(st).format("DD MMM, YYYY");
            const b = moment(snd).format("DD MMM, YYYY");
            // const a1 = moment(st).format("YYYY");
            // const a2 = moment(snd).format("YYYY");
            // const a3 = JSON.parse(a2) - JSON.parse(a1)
            // const b1 = moment(st).format("l").split("/")[0];
            // const b2 = moment(snd).format("l").split("/")[0];
            // const b3 = JSON.parse(b2) - JSON.parse(b1)
            // const c = a + " - " + b + " • " + a3 + " yr " + b3 + " mos ";


            const finalStart = moment(a)
            const finalEnd = moment(b)

            const diffYears = finalEnd.diff(finalStart, "years")
            const diffMonths = finalEnd.diff(finalStart, "months")
            const remMonths = diffMonths - (diffYears * 12)
            const diffDays = finalEnd.diff(finalStart, "days")

            // const finalStart = moment([moment(a).year(), moment(a).month()+1, moment(a).date()])
            // const finalEnd = moment([moment(b).year(), moment(b).month()+1, moment(b).date()])
            // const diffDuration = moment.duration(finalEnd.diff(finalStart))
            // const diffYears = diffDuration.years()
            // const diffMonths = diffDuration.months()
            // const c = a + " - " + b + " • " + diffYears + " yr " + diffMonths + " mos ";
            const c = (!diffYears && !diffMonths) ? a + " - " + b + " • " + diffDays + ` ${diffDays > 1 ? `days` : `day`} ` : a + " - " + b + " • " + diffYears + ` ${diffYears > 1 ? "yrs" : "yr"} ${remMonths > 0 ? `${remMonths} ${remMonths > 1 ? "months" : "month"}` : ""}`;

            return c
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {

        const { data } = state;

        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            data,
            result.source.index,
            result.destination.index
        );
        let update = items.map((val, index) => {
            let v_ = Object.assign({}, val);
            v_.orderNo = index + 1;
            return v_;
        });

        setState({
            ...state, data: update
        })

        props.onDragEnd1 && props.onDragEnd1(update);
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        marginBottom:12,
        ...draggableStyle
      });

    // render() {
    const { breakpoints = null } = props;
    return (
        <div>
            {/* mapping */}

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div 
                            {...provided.droppableProps} 
                            ref={provided.innerRef}
                        >

                            {state.data?.map((list, inx) => {
                                return <Draggable key={list?.dnd} draggableId={list?.dnd} index={inx}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                        >
                                            <Grid key={list?.organizationName} container className={classes.root}>
                                                <Grid item xs={2} sm={2} md={breakpoints?.grid1?.md ?? 1} lg={breakpoints?.grid1?.lg ?? 1}>

                                                    {/* mapping avatar */}
                                                    <div className={classes.avatar}>
                                                        {list?.pic ? (
                                                            <img src={list?.pic} alt="profile" />
                                                        ) : (
                                                            <img src="/images/q.svg" alt="profile" />
                                                        )}
                                                    </div>
                                                    {/* mapping avatar end*/}

                                                </Grid>
                                                <Grid item xs={10} sm={10} md={breakpoints?.grid2?.md ?? 11} lg={breakpoints?.grid2?.md ?? 11}>

                                                    {/* mapping details */}

                                                    <List className={classes.list}>
                                                        <ListItem className={classes.listItem}>
                                                            <ListItemText primary={<span className={classes.title_}>{list?.organizationName}</span>} secondary={<React.Fragment>
                                                                <span className={classes.sub_text}>{list?.roleName}</span>
                                                                <br />
                                                                <span className={classes.footer}>
                                                                    {(list?.startingFrom) &&
                                                                        <>
                                                                            {dateformat(list?.startingFrom, list?.endingIn, list?.present)}</>}</span>
                                                            </React.Fragment>} />
                                                        </ListItem>

                                                        {/* edit icon */}
                                                        <ListItemSecondaryAction className={classes.right_lg}>
                                                            <IconButton className={classes.right_} edge="end" onClick={() => props.handledrawer("Edit Experience", list, list?.id, list?.organizationId, list?.organizationName, state.data)}>
                                                                <img src="/images/icons8-edit.svg" width="75%" alt="edit" />
                                                            </IconButton>
                                                            <IconButton edge="end" onClick={() => props.ondltList("Add Experience", inx)}>
                                                                <img width="73%" src="/images/icons8-trash-can.svg" alt="delete" />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                        {/* enFd */}
                                                    </List>

                                                    {/* mapping details end */}

                                                </Grid>
                                            </Grid>

                                        </div>
                                    )}
                                </Draggable>
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            {/* mapping end */}

        </div>

    );
}

export default ExperiencecardList;