import React from 'react'
import { Avatar, Grid, Tooltip, Typography, withStyles, Zoom, } from '@material-ui/core'
import { BusinessOrganizationIcon, ClientPersonIcon } from '../../assets'
import PropTypes from 'prop-types'
import { Skeleton } from '@material-ui/lab'
import { avatarColorBgById } from '../../utils'

const Styles = (theme) => ({
  avatarContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
  },
  title: {
    color: 'rgba(146, 151, 165, 1)',
    fontSize: 13
  },
  avatar: {
    marginRight: theme.spacing(1.5)
  },
  name: {
    alignSelf: 'center'
  },
  skeletonStyle: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  activecircle: {
    marginLeft: 10,
    // marginTop: 6,
    alignSelf: 'center',
    width: 9,
    height: 9,
    background: theme.palette.success.main,
    borderRadius: "50%"
  },
  inactivecircle: {
    marginLeft: 10,
    marginTop: 6,
    width: 9,
    height: 9,
    background: theme.palette.error.main,
    borderRadius: "50%"
  },
  active_name: {
    display: "inline",
    paddingLeft: "6px",
    fontSize: "14px",
    color: theme.palette.success.main,
    alignSelf: 'center',
  },
  inactive_name: {
    display: "inline",
    paddingLeft: "6px",
    fontSize: "14px",
    color: theme.palette.error.main
  },
  sub_details:{
    display: "inline",
    paddingLeft: "6px",
    fontSize: "14px",
    alignSelf: 'center',
  }
})

class SimpleAvatarCard extends React.Component {
  render() {
    const { classes, loading } = this.props
    const { id, title, name, width, height, style, icon, src, sub_title, showUserIcon, isactive, fixForProfileDrawer, onAvatarClicked } = this.props;
    const isActive = (isactive === true || isactive === "Active") ? true : false;

    return (
      <Grid container spacing={fixForProfileDrawer ? 0 : 1}>
        {!showUserIcon && (
          <Grid item>
            {icon ? (
              icon
            ) : (
              <BusinessOrganizationIcon
                style={{
                  width: width ? width : 24,
                  height: height ? height : 20,
                  ...style
                }}
              />
            )}
          </Grid>
        )}
        {showUserIcon && (
          <Grid item>
            {icon ? (
              icon
            ) : (
              <ClientPersonIcon
                style={{
                  width: width ? width : 24,
                  height: height ? height : 20,
                  ...style
                }}
              />
            )}
          </Grid>
        )}
        <Grid item style={{ marginLeft: fixForProfileDrawer ? 12 : 0 }}>
          <Typography variant="body1" className={classes.title}>
            {title ? title : "N/A"}
          </Typography>
          {loading && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Skeleton
                variant="circle"
                width={40}
                height={40}
                className={classes.skeletonStyle}
              />
              <Skeleton
                variant="text"
                width={100}
                className={classes.skeletonStyle}
              />
            </div>
          )}
          {!loading && (
            <div className={classes.avatarContainer}>
              {name &&
              <Tooltip
                TransitionComponent={Zoom}
                title={name}
                placement="bottom"
                enterDelay={100}
                leaveDelay={50}
                onClick={() => {if(id) onAvatarClicked(id);}}
                arrow
              >
                <Avatar
                  src={src}
                  className={classes.avatar}
                  style={{
                    backgroundColor: !src
                      ? avatarColorBgById(
                          id ??
                            Math.floor(Math.random() * (999 - 100 + 1) + 100)
                        )
                      : ""
                  }}
                >
                  {name?.trim()?.[0]?.toUpperCase()}
                </Avatar>
              </Tooltip>
              }
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  <Typography variant="body1" className={classes.name}>
                    {name ? name : "Not Mapped"}
                  </Typography>
                  {isactive !== undefined && (
                    <div
                      className={
                        isActive ? classes.activecircle : classes.inactivecircle
                      }
                    />
                  )}

                  {isactive !== undefined && (
                    <Typography
                      variant="h6"
                      className={
                        isActive ? classes.active_name : classes.inactive_name
                      }
                    >
                      {isActive ? "ACTIVE" : "INACTIVE"}
                    </Typography>
                  )}
                </div>
                <Typography variant="body2" color="textSecondary" className={classes.sub_details}>
                  {sub_title ? sub_title : ""}
                </Typography>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(Styles)(SimpleAvatarCard)

SimpleAvatarCard.propTypes = {
  /**ID of the item. Used to select an avatar background if not present. */
  id: PropTypes.number,
  /**Sets the title of the section. Defaults to ```N/A``` */
  title: PropTypes.string,
  /**Sets the name of the person. Defaults to ```N/A``` */
  name: PropTypes.string,
  /**Sets the width of the icon. */
  width: PropTypes.number,
  /**Sets the height of the icon. */
  height: PropTypes.number,
  /**Additional styles for icon can be added here */
  style: PropTypes.object,
  /**Prop to change the icon. Styles should be explicitly specified. */
  icon: PropTypes.elementType,
  /**Prop to set the picture to the avatar */
  src: PropTypes.string,
  /**Loading */
  loading: PropTypes.bool,
  /**empty text */
  emptyText: PropTypes.string,
  /**show user icon */
  showUserIcon: PropTypes.bool,
  /**sub title */
  sub_title: PropTypes.string
}