import { Grid, Hidden, IconButton, makeStyles } from '@material-ui/core'
import React from 'react'
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles((theme) => ({
  header: {
    height: "10%",
    // height: 44,
    [theme.breakpoints.down("xs")]: {
    //   height: 60,
      textAlign: "center",
    //   marginTop: 64
    }
  },
  logo: {
    width: 150,
  },
  ButtonAlike:{
      padding: "6px 16px",
      marginRight: 10,
      border: "1px solid #0063E7",
      borderRadius: "8px",
      color: "#0063E7",
      [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          width: "100%",
          paddingLeft: 1,
          paddingRight: 1,

        //   marginRight: "auto",
        //   marginLeft: "auto"
      }
  },
  icons:{
      marginTop: 8,
      marginBottom:-8,
      color: "#0063E7",
  },
}))

export default function HeaderLogo(props) {
  const classes = useStyles(props);
  return (
    <>
      <Grid
        container
        xs={12}
        justify="flex-start"
        className={ props.customHeaderStyle ? props.customHeaderStyle : classes.header}
      >
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  style={{marginTop: 8}}>
          <img
            src="/images/tanyaCare/TanyaHorizontal.png"
            alt="Tanya.Care"
            className={classes.logo}
          />
        
          {/* <img
            src="/images/login_top_logo/toplogo.png"
            srcSet="/images/login_top_logo/toplogo@2x.png 2x,
                          /images/login_top_logo/toplogo@3x.png 3x"
            alt="managed care"
            className={classes.logo}
          /> */}
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{marginTop: 8, marginBottom: 8}}>
            <Hidden mdUp>
            <div style={{width:"fit-content", marginLeft:"auto", marginTop:"inherit",}}>
            <IconButton style={{paddingTop:0}}><EmailIcon className={classes.icons}/></IconButton> <IconButton style={{paddingTop:0}}> <PhoneIcon className={classes.icons}/></IconButton>
            </div>
            </Hidden>
            <Hidden xlUp mdDown>
            <div style={{width:"fit-content", marginLeft:"auto", marginTop:"inherit",}}>
            <a style={{textDecoration:"none"}} href="mailto:reach.us@tanya.care"><span className={classes.ButtonAlike}><EmailIcon className={classes.icons}/> reach.us@tanya.care</span></a>
                <a style={{textDecoration:"none"}} href="tel:+601300132511"><span className={classes.ButtonAlike}><PhoneIcon className={classes.icons}/> 1300-13-2511</span></a>
            </div>
            </Hidden>
        </Grid>
      </Grid>
    </>
  )
}
