import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { routes } from "../../router/routes";

import { withNavBars } from "./../../HOCs";
import TransactionReport from "./transactionReport";
import OrderReport from "./orderReport";
import EnquiryReport from "./enquiryReport";

const Reports = (props) => {
  const { match } = props;
  return (
    <>
      {/* Transaction report */}
      {match.path === routes.transactionReport ? (
        <TransactionReport />
      ) : match.path === routes.orderReport ? (
        <OrderReport />
      ) : match.path === routes.enquiryReport ? (
        <EnquiryReport />
      ) : null}
    </>
  );
};

Reports.propTypes = {
  title: PropTypes.string,
  withBackButton: PropTypes.bool,
  navBarArr: PropTypes.array,
};

Reports.defaultProps = {
  title: "Reports",
};

export default withRouter(withNavBars(Reports));
