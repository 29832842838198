import React from "react";
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  IconButton,
  Divider
} from "@material-ui/core";
import { QuickSummaryIcon } from "../../assets";
// import DescriptionIcon from '@material-ui/icons/Description';
import { NavLink } from "react-router-dom";
import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  deactivateBtn: {
    color: `${theme.palette.ternary.main} !important`,
    boxShadow: "unset !important"
  },
  centerIcon: {
    display: "flex",
    alignItems: "center"
  },
}));

//propsList - get all list props
const ListComponent = (props) => {
  const Optclasses = useStyles();

  const {
    order,
    classes,
    defultOrder,
    sizes,
    link,
    link2,
    // providerId,
    hideDeactivateEditButtons
    
  } = props;

  let checkorder = defultOrder ? defultOrder : order;
  console.log(checkorder)
  switch (checkorder) {
    case 1:
      if (!props.hideQuickSummary) {
        return (
          <>
          <Grid item>
          <Divider light orientation={"vertical"} />
          </Grid>
          <Grid
            item
            md={props.hideQuickSummaryLabel ? 2 : sizes.md ? sizes.md : 3}
            sm={props.hideQuickSummaryLabel ? 2 : sizes.sm ? sizes.sm : 6}
            xs={props.hideQuickSummaryLabel ? 2 : sizes.xs ? sizes.xs : 3}
            className={`${classes.left_border + " " + classes.listPart} ${!props.hideQuickSummaryLabel ?` ${classes.orderList}` : ""}`}
          >
            
            <Grid
              item
              className={`${classes.marginAuto} ${props.hideQuickSummaryLabel ? `${Optclasses.centerIcon}` : ""
                } `}
            >
              {/* <DescriptionIcon className={classes.iconColor} /> */}
              {props.showAddIcon ? (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onAddIconClicked();
                  }}
                >
                  <Add htmlColor="#9297a5" />
                </IconButton>
              ) : (
                <QuickSummaryIcon htmlColor="#9297a5" />
              )}
              {!props.hideQuickSummaryLabel && (
                <Typography className={classes.secondaryAddActivity}>
                  Add Activity
                </Typography> 
              )}
            </Grid>
          </Grid>
        </>
        );
      }
      return null;
    case 2:
      // hideDeactivateEditButtons
      return (
        <>
          {!hideDeactivateEditButtons && (
            <Grid
              item
              md={sizes.md ? sizes.md : 6}
              sm={sizes.sm ? sizes.sm : 6}
              xs={sizes.xs ? sizes.xs : 6}
              className={
                classes.listPart + " " + classes.flex + " " + classes.flexEnd
              }
            >
              <Grid
                item
                className={classes.marginHeight + " " + classes.paddingRight}
              >
                {(props.emailVerified || props.emailVerified == null) ? <>
             {!props.isShowActivate &&   <Button
                  disabled={props.isDeactivating}
                  variant="outlined"
                  color="primary"
                  onClick={props.onDeactivateClicked}
                  style={{ marginRight: "20px" }}
                  id={props?.deactivateActivateButtonId}
                   // deactivateActivateButtonId={this.props?.deactivateActivateButtonId}
                  //     editButtonId={this.props?.editButtonId}
                  //     addBranchButtonId={this.props?.addBranchButtonId}
                  //     resendButtonId={this.props?.resendButtonId}
                >
                  {props.isDeactivating
                    ? props.isInActive
                      ? "ACTIVATING..."
                      : "DEACTIVATING..."
                    : props.isInActive
                      ? "ACTIVATE"
                      : "DEACTIVATE"}
                </Button>}
                </> 
                :
                <Button
                disabled={props.isDeactivating}
                variant="outlined"
                color="primary"
                onClick={props.handleResend}
                style={{ marginRight: "20px" }}
                id={props?.resendButtonId}
              >
                {props.isDeactivating
                  ? props.isInEmailVerified
                    ? "SENDING..."
                    : "DEACTIVATING..."
                  : props.isInEmailVerified
                    ? "RESEND"
                    : "DEACTIVATE"}
              </Button>
                }
                {!props?.isShowEditButton && <NavLink to={link ? link : "#"} style={{ textDecoration: "none" }}>
                  <Button variant="contained" color="primary" id={props?.editButtonId}>
                    Edit Details
              </Button>
                </NavLink>}
                {props.emailVerified && <>
                {props?.showThirdBtn && <Button style={{ marginLeft: 20 }} variant="contained" color="primary" onClick={props?.onThirdBtnClicked} id={props?.ThirdButtonId}>
                  {props?.thirdBtnTitle}
                </Button>}
                </>}
              </Grid>
            </Grid>
          )}
        </>
      );
    case 3:
      return (
        <Grid
          item
          md={sizes.md ? sizes.md : 6}
          sm={sizes.sm ? sizes.sm : 6}
          xs={sizes.xs ? sizes.xs : 6}
          className={
            classes.listPart + " " + classes.flex + " " + classes.flexEnd
          }
        >
          <Grid
            item
            className={classes.marginHeight + " " + classes.paddingRight}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.marginRight}
              style={{ padding: 8 }}
              onClick={props.handleAddServiceProfessional}
            >
              + Add Service Professional
            </Button>
          </Grid>
          {/* <Grid item className={classes.marginHeight + " " + classes.paddingRight}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.marginRight}
              style={{padding:8}}
            >
              View Service Professionals
            </Button>
          </Grid> */}
        </Grid>
      );
    case 4:
      // hideDeactivateEditButtons
      return (
        <>
          {!hideDeactivateEditButtons && (
            
            <Grid
              item
              md={sizes.md ? sizes.md : 6}
              sm={sizes.sm ? sizes.sm : 6}
              xs={sizes.xs ? sizes.xs : 6}
              className={
                classes.listPart + " " + classes.flex + " " + classes.flexEnd
              }
            >
              <Grid
                item
                className={classes.marginHeight + " " + classes.paddingRight}
              >
                {(props.emailVerified || props.emailVerified == null) ? 
                <Button
                  disabled={props.isDeactivating}
                  variant="outlined"
                  color="primary"
                  onClick={props.onDeactivateClicked}
                  id={props?.deactivateActivateButtonId}
                  // deactivateActivateButtonId={this.props?.deactivateActivateButtonId}
                  //     editButtonId={this.props?.editButtonId}
                  //     addBranchButtonId={this.props?.addBranchButtonId}
                  //     resendButtonId={this.props?.resendButtonId}
                  style={{ marginRight: "20px" }}
                >
                  {props.isDeactivating
                    ? props.isInActive
                      ? "ACTIVATING..."
                      : "DEACTIVATING..."
                    : props.isInActive
                      ? "ACTIVATE"
                      : "DEACTIVATE"}
                </Button>
                :
                <Button
                  disabled={props.isDeactivating}
                  variant="outlined"
                  color="primary"
                  onClick={props.handleResend}
                  id={props?.resendButtonId}
                  style={{ marginRight: "20px" }}
                >
                  {props.isDeactivating
                    ? props.isInEmailVerified
                      ? "SENDING..."
                      : "DEACTIVATING..."
                    : props.isInEmailVerified
                      ? "RESEND"
                      : "DEACTIVATE"}
                </Button>
                }
                <NavLink
                  to={link ? link : "#"}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    id={props?.editButtonId}
                    style={{ marginRight: "20px" }}
                  >
                    Edit Details
                  </Button>
                </NavLink>
                <NavLink
                  to={link2}
                  style={{ textDecoration: "none" }}
                >
                  <Button variant="contained" color="primary" id={props?.addBranchButtonId}>
                    Add Branch
                  </Button>
                </NavLink>
              </Grid>
            </Grid>
          )}
        </>
      );
    case 5:
      // hideDeactivateEditButtons
      return (
        <>
          {!hideDeactivateEditButtons && (
            <Grid
              item
              md={sizes.md ? sizes.md : 6}
              sm={sizes.sm ? sizes.sm : 6}
              xs={sizes.xs ? sizes.xs : 6}
              className={
                classes.listPart + " " + classes.flex + " " + classes.flexEnd
              }
            >
              <Grid
                item
                className={classes.marginHeight + " " + classes.paddingRight}
              >
                {(props.emailVerified || props.emailVerified == null) ? <>
                {!props.isdontshowactivebtn &&
                  <React.Fragment>
                    <Button
                      disabled={props.isDeactivating}
                      variant="outlined"
                      color="primary"
                      onClick={props.onDeactivateClicked}
                      style={{ marginRight: "20px" }}
                      id={props?.deactivateActivateButtonId}
                      // deactivateActivateButtonId={this.props?.deactivateActivateButtonId}
                  //     editButtonId={this.props?.editButtonId}
                  //     addBranchButtonId={this.props?.addBranchButtonId}
                  //     resendButtonId={this.props?.resendButtonId}
                    >
                      {props.isDeactivating
                        ? props.isInActive
                          ? "ACTIVATING..."
                          : "DEACTIVATING..."
                        : props.isInActive
                          ? "ACTIVATE"
                          : "DEACTIVATE"}
                    </Button></React.Fragment>}
                    </>
                    :
                    <Button
                    disabled={props.isDeactivating}
                    variant="outlined"
                    color="primary"
                    onClick={props.handleResend}
                    style={{ marginRight: "20px" }}
                    id={props?.resendButtonId}
                  >
                    {props.isDeactivating
                      ? props.isInEmailVerified
                        ? "SENDING..."
                        : "DEACTIVATING..."
                      : props.isInEmailVerified
                        ? "RESEND"
                        : "DEACTIVATE"}
                  </Button>
                    }
                    {!props.canShowButton && (<>
                {!props?.isShowEditButton && <NavLink
                  to={link ? link : "#"}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "20px" }}
                    id={props?.editButtonId}
                  >
                    Edit Details
                  </Button>
                </NavLink>}
                {/* <NavLink
                  to={{ pathname: link2 ? link2 : "#", state: { providerId } }}
                  style={{ textDecoration: "none" }}
                > */}
                {console.log(props.emailVerified)}
                {props.emailVerified && <>
                  {!props?.isShowEditButton && 
                    <Button variant="contained" color="primary" onClick={props.onMapResourceClicked}>
                      {props.mapResourceLabel}
                    </Button>
                  } 
                  </>}  
                </>)}
                {/* </NavLink> */}
              </Grid>
            </Grid>
          )}
        </>
      );
    default:
      return <></>;
  }
};

export default ListComponent;
