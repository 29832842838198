import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {},
  labelStyle: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 8,
    color: "#878C9A",
    "& .MuiTypography-caption": {
      color: "#F76E8E",
      fontWeight: 400,
    },
  },
  autoComplete: (props) => ({
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
    },
    "& .MuiInputBase-root": {
      padding: "6.19px 39px 6.19px 6.19px !important",
    },
    "& input": {
      fontWeight: 500,
      fontSize: 14,
      "&::placeholder": {
        fontWeight: 400,
      },
    },
    "& fieldset": {
      borderColor: "#C7C7C7",
      borderRadius: 10,
    },
    "& .MuiFormHelperText-root": {
      marginInline: 0,
    },
    "& .MuiChip-root": {
      backgroundColor: "rgb(43 49 69 / 5%)",
    },
  }),
}));

export const Select = (props) => {
  const classes = useStyles(props);

  const [open, setOpen] = React.useState(false); // use state for readonly

  const {
    multiple = false,
    limitTags = -1,
    handleChange = null,
    id = "",
    options = [],
    showOptionLabel = "label",
    selectedOption = "value",
    value,
    disabled = false,
    filterSelectedOptions = false,
    label = "",
    isRequired = false,
    placeHolder = "",
    error = false,
    helperText = "",
    isReadOnly = false,
    loading = false,
    disableClearable = true,
  } = props;

  const returnPlaceHolder = () => {
    if (!multiple) return placeHolder;
    return Array.isArray(value) && value?.length === 0 ? placeHolder : "";
  };

  return (
    <div className={classes.root}>
      {label && (
        <Typography className={classes.labelStyle}>
          {label}
          {isRequired && <Typography variant="caption">&nbsp;*</Typography>}
        </Typography>
      )}
      <Autocomplete
        value={value} // array for multiple select, object for single select
        multiple={multiple}
        limitTags={limitTags}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        open={open}
        onOpen={() => !isReadOnly && setOpen(true)}
        onClose={() => setOpen(false)}
        disableClearable={disableClearable || isReadOnly}
        id={`autoComplete${id}`}
        options={loading ? [] : options}
        className={classes.autoComplete}
        disabled={disabled}
        filterSelectedOptions={filterSelectedOptions}
        getOptionLabel={(option) => option[showOptionLabel]}
        getOptionSelected={(option, value) =>
          option[selectedOption] === value[selectedOption]
        }
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={returnPlaceHolder()}
            error={error}
            helperText={error ? helperText : ""}
            inputProps={{
              ...params.inputProps,
              readOnly: isReadOnly,
            }}
          />
        )}
        popupIcon={
          <KeyboardArrowDownIcon
            fontSize="small"
            style={{ color: "#C7C7C7" }}
          />
        }
        closeIcon={<CloseIcon fontSize="small" style={{ color: "#C7C7C7" }} />}
      />
    </div>
  );
};

Select.propTypes = {
  multiple: PropTypes.bool,
  limitTags: PropTypes.number,
  disableClearable: PropTypes.bool,
  handleChange: PropTypes.func,
  id: PropTypes.string,
  options: PropTypes.array,
  showOptionLabel: PropTypes.string,
  selectedOption: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object, //single select
    PropTypes.array, //multiple select
  ]),
  disabled: PropTypes.bool,
  filterSelectedOptions: PropTypes.bool,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  placeHolder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isReadOnly: PropTypes.bool,
  loading: PropTypes.bool,
};
