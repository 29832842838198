import React from 'react'
import {
    Grid, Typography, List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    withStyles
} from '@material-ui/core'

import { PreviewPngIcon } from "../../assets";
import { FILE_URI } from '../../utils/constants';

import withAlelrtAndDialog from '../../HOCs/withAlelrtAndDialog';
import { customAddress, formComponentTypes } from '../../utils';

const style = ((theme) => ({
    listWrapper: {
        width: "100%",
        display: "flex"
    },
    addIdButtonText: {
        color: theme.palette.ternary.main
    },
    gutter: {
        padding: "16px 16px"
    },
    gutterTop: {
        padding: "16px 16px 0px"
    },
    gutterMiddle: {
        padding: "0px 16px"
    },
    gutterBottom: {
        padding: "0px 16px 16px"
    },
    imageStyle: {
        [theme.breakpoints.down("md")]: {
            width: 320,
            height: 250,
        },
        [theme.breakpoints.up("md")]: {
            width: "100%",
            height: "100%",
            objectFit: "cover"
        },
    },
}))

class ViewAllCards extends React.Component {

    state = {
        previewImage: [],
        imageSrcError: {}
    }

    componentDidMount() {
        if (this.props.isTypeId) {
            this.renderPreviewImage()
        }
    }

    renderPreviewImage = () => {
        const { items } = this.props

        let previewImage = Array.from(new Array(items?.length))

        if (items?.length > 0) {
            items.map((item, index) => {
                if (item?.id_photo) {
                    previewImage[index] = `${FILE_URI}/${item?.id_photo}`
                }
                return items
            })
        }

        this.setState({
            previewImage: previewImage
        })
    }

    handleImageError = (index) => {
        let { imageSrcError } = this.state
        imageSrcError[index] = true
        this.setState({
            imageSrcError: imageSrcError
        })
    }

    handleImagePreviewDialog = (image, item) => {
        const { classes } = this.props

        // Display the content in a model
        this.props.dialog.setDialog({
            ...this.props.dialog,
            open: true,
            title: item?.label,
            body: <div style={{ paddingTop: 16 }}>
                <img src={image} className={classes.imageStyle} alt={`Failed to show document.`} />
            </div>,
            positiveBtn: "Ok",
            negativeBtn: "CANCEL",
            dontShowButtons: true,
            onOk: () => { },
            onCancel: () => {
                this.props.dialog.setDialog({
                    ...this.props.dialog,
                    open: false,
                    title: ``,
                    body: "",
                    positiveBtn: "Ok",
                    negativeBtn: "CANCEL",
                    dontShowButtons: false,
                });
            },
        });
    }

    primaryTextRenderer = (data) => {
        if (data) {
            switch (this.props.elementType) {
                case formComponentTypes.ADD_ADDRESS:
                    return data.label
                case formComponentTypes.ADD_EMAIL:
                    return data.label
                case formComponentTypes.ADD_PHONE_NUMBER:
                    return data.label
                case formComponentTypes.ADD_ID_TYPE:
                    return data.label
                default: return <></>
            }
        }
        else {
            return null
        }
    }

    
    secondaryTextRenderer = (data) => {
        if (data) {

            switch (this.props.elementType) {
                case formComponentTypes.ADD_ADDRESS:
                    return customAddress(
                        data.addressLine1,
                        data.addressLine2,
                        data.area,
                        data.state,
                        data.country,
                        data.postalCode
                    )
                case formComponentTypes.ADD_EMAIL:
                    return data.email
                case formComponentTypes.ADD_PHONE_NUMBER:
                    return `${data.mobileNoIsdCode} ${data.mobileNo}`
                case formComponentTypes.ADD_ID_TYPE:
                    return data.id_type
                default: return <></>
            }
        }
        else {
            return 'N/A'
        }
    }

    getDrawerTitle = (elementType) => {
        switch (elementType) {
            case formComponentTypes.ADD_ADDRESS:
                return "Address"
            case formComponentTypes.ADD_EMAIL:
                return "Email Address"
            case formComponentTypes.ADD_PHONE_NUMBER:
                return "Contact Number"
            case formComponentTypes.ADD_ID_TYPE:
                return "ID Detail"
            default: return "Detail"
        }
    }

    render() {
        const { classes, isTypeId, items, elementType } = this.props
        const {  previewImage, imageSrcError = {} } = this.state
        return (
            <div>
                <Grid container>
                    <Grid item xs={12} className={classes.gutter}>
                        <Typography variant={"h6"}>{this.getDrawerTitle(elementType)}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider light />
                    </Grid>

                    {items?.map((item, index) => {
                        return (
                            <Grid item xs={12}>
                                <div className={classes.listWrapper}>
                                    <List dense={true} style={{ width: "100%" }} >
                                        <ListItem divider>
                                            {isTypeId && <ListItemIcon style={{ justifyContent: "flex-start" }}>
                                                {/* <PreviewPngIcon style={{ visibility: Boolean(item?.id_photo) ? "unset" : "hidden" }} /> */}

                                                {(imageSrcError?.[index] ? imageSrcError?.[index] : false) && <PreviewPngIcon style={{ visibility: Boolean(item?.id_photo) ? "unset" : "hidden" }} />}
                                                {(previewImage?.length >= index) && previewImage?.[index] && !imageSrcError?.[index] && <img src={previewImage[index]} alt={"Failed to show preview"}

                                                    onError={() => {
                                                        this.handleImageError(index, item)
                                                    }}
                                                    style={{
                                                        visibility: Boolean(item?.id_photo) ? "unset" : "hidden",
                                                        height: "34px",
                                                        width: "34px",
                                                        borderRadius: 4,
                                                        objectFit: "cover",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => this.handleImagePreviewDialog(previewImage[index])}
                                                />}
                                            </ListItemIcon>}
                                            <ListItemText
                                                primary={this.primaryTextRenderer(item)}
                                                secondary={this.secondaryTextRenderer(item)}
                                            />
                                        </ListItem>
                                    </List>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        )
    }
}

export default withAlelrtAndDialog(withStyles(style)(ViewAllCards));
