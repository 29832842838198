import React from "react";
import AddEditForm from "../addEditForm";
import {
  formComponentTypes,
  alertProps,
  imageFormats,
  // timezoneOld as timezone
} from "../../utils";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
// import ConflictList from "../../conflictList";
import PropTypes from "prop-types";
import { FILE_URI } from "../../utils/constants";
class IdTypeComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {

    };
  }

  componentDidMount() {
    this.getActivityData()
  }

  getActivityData = () => {

    this.props.GET_ID_TYPES({});
  };

  onSaveButtonClicked = (isError, data, isUrl) => {
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      if (this.props?.editData) {
        data.id = this.props?.editData.id
      }
      this.props.callBackOnSave(data)
    }
  };

  onCancelButtonClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  handleFileUpload = (fileUploaded) => {

    this.setState({
      promotional_content: fileUploaded,
    });
  };

  handleFileRemove = () => {
    this.setState({
      promotional_content: null,
    });
  };

  getFormattedFormats = (formatArray=[]) => {
    return formatArray.map((format, index) => {return `.${format}`}).join()
  }

  render() {
    let isEdit = this.props.isEdit;

    let list = this.props.list;
    let selectedList = []

    let data = this.props?.editData;
    if (list && list.length > 0) {
      selectedList = list.map(val => val.id_type_name?.value)
    }
    if (data && data.length > 0 && selectedList.length > 0) {
      selectedList = selectedList.filter(val => val !== data.value)
    }
    console.log("+++++++++++++++++++++++++++")
    console.log(this.props?.id)
    console.log("+++++++++++++++++++++++++++")
    const components = {
      section_1: {
        label: !isEdit ? "Add ID" : "Edit ID",
        labelBreakPoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        fields: {
          id_type_name: {
            key: "id_type_name",
            id: `selectIdTypesFrom${this.props?.id ? this.props?.id : ""}`,
            type: formComponentTypes.SELECT_BOX,
            componentProps: {},
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "ID TYPE",
            value: !isEdit ? "" : this.props?.idType?.data?.length > 0 ? this.props?.idType?.data?.filter(x => x.value === data?.value)[0] : [],
            // options: this.props?.idType?.data
            //   ? this.props?.idType?.data
            //   : [],
            options: (this.props?.idType?.data && Array.isArray(this.props?.idType?.data))
              ? selectedList.length > 0 ? this.props?.idType?.data?.filter(val => selectedList.indexOf(val.value) === -1)
                : this.props?.idType?.data
              : [],
            isMandatory: true,
          },

          id_type: {
            key: "id_type",
            id: `addIdTypesFrom${this.props?.id ? this.props?.id : ""}`,
            type: formComponentTypes.INPUT_BOX,
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "ID NUMBER",
            value: !isEdit ? "" : data?.id_type,
            isMandatory: true,
          },

          id_photo: {
            key: "id_photo",
            type: formComponentTypes.UPLOADER_WITH_PREVIEW,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "ID UPLOAD",
            value: isEdit ? parseInt(data?.id_photo) ? `${data?.id_type}${data?.id_photo_filetype ? "."+data?.id_photo_filetype : ""}` : data?.id_photo : "",
            handleFileUpload: this.handleFileUpload,
            handleFileRemove: this.handleFileRemove,
            isMandatory: false,
            componentProps: {
              customExtensions: `${this.getFormattedFormats(imageFormats)}`,
              // file: data?.file ? data?.file : null,
              // fileUrl: data?.id_photo ? `${FILE_URI}/${data?.id_photo}` : null,
              idData: {...data, file: data?.file ? data?.file : null, fileUrl: data?.id_photo ? `${FILE_URI}/${data?.id_photo}` : null }
            }
          },
          // id_photo_preview: {
          //   key: "id_photo_preview",
          //   type: formComponentTypes.IMAGE_PREVIEW,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 12,
          //     md: 12,
          //     lg: 12,
          //     xl: 12,
          //   },
          //   label: "ID UPLOAD",
          //   value: isEdit ? parseInt(data?.id_photo) ? `${data?.id_type}${data?.id_photo_filetype ? "."+data?.id_photo_filetype : ""}` : "" : "",
          //   handleFileUpload: this.handleFileUpload,
          //   handleFileRemove: this.handleFileRemove,
          //   isMandatory: false,
          //   componentProps: {
          //     customExtensions: `${this.getFormattedFormats(imageFormats)}`
          //   }
          // },


        },
      },
    };
    return (
      <>
        {isEdit ? (
          <>
            <AddEditForm
              components={components}
              onSaveButtonClicked={this.onSaveButtonClicked}
              cancelForm={
                this.props.backHandler
                  ? this.props.backHandler
                  : this.onCancelButtonClicked
              }
              // loading={updateLoading}
              isUrl={true}
              saveButtonLabel={"Update"}
              activityEdit={isEdit}
              spacing={1}
              // sectionGutterBottom={16}
              saveButtonId={`saveIdTypeidFrom${this.props.id ? this.props.id : ""}`}
              cancelButtonId={`cancelIdTypeidFrom${this.props.id ? this.props.id : ""}`}
              backNeeded={this.props.backNeeded}
              backHandler={this.props.backHandler}
            />
          </>
        ) : (
            <AddEditForm
              components={components}
              onSaveButtonClicked={this.onSaveButtonClicked}
              cancelForm={
                this.props.backHandler
                  ? this.props.backHandler
                  : this.onCancelButtonClicked
              }
              // loading={addLoading}
              saveButtonLabel={"Add ID Type"}
              withoutBoxShadow={true}
              spacing={1}
              // sectionGutterBottom={16}
              saveButtonId={`saveIdTypeidFrom${this.props.id ? this.props.id : ""}`}
              cancelButtonId={`cancelIdTypeidFrom${this.props.id ? this.props.id : ""}`}
              backNeeded={this.props.backNeeded}
              backHandler={this.props.backHandler}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idType: state?.masterSet?.idType,
});

// export default AddEditAvailabiity
export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(IdTypeComponent));

IdTypeComponent.propTypes = {
  /**To persist State, after viewing all conflicts state and returning back to add/Edit activity screen. */
  persistState: PropTypes.bool,
  /**State to set while persisting. */
  parentState: PropTypes.object,
};
