import { filterType, user_roles } from "../../../../utils"

export const filterComponent = (state, props) => {
  let id = state?.id
  switch (props?.auth_data?.data?.user_role) {
    case user_roles.super_admin: {
      return {
        section_1: {
          label: `Enquiry Filters`,
          fields: {
            enquiry_type: {
              key: "enquiry_type",
              id:`selectEnquiryTypeFilterFrom${id ? id : ""}`,
              type: filterType.ENQUIRY_TYPE,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "ENQUIRY TYPE",
              value: state.enquiry_type,
              isMulti: true,
              isMandatory: true,
            },
            assigned_to: {
              key: "assigned_to",
              id:`selectAssignToFilterFrom${id ? id : ""}`,
              type: filterType.ASSIGN_TO,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "ASSIGNED TO",
              value: state.assigned_to,
              isMulti: true,
              isMandatory: true,
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              openstatus: true,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMandatory: true,
            },

          }
        }
      }
    }

    case user_roles.provider: {
      return {
        section_1: {
          label: "Enquiry Filters",
          fields: {
            enquiry_type: {
              key: "enquiry_type",
              id:`selectEnquiryTypeFilterFrom${id ? id : ""}`,
              type: filterType.ENQUIRY_TYPE,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "ENQUIRY TYPE",
              value: state.enquiry_type,
              isMulti: true,
              isMandatory: true,
            },
            assigned_to: {
              key: "assigned_to",
              id:`selectAssignToFilterFrom${id ? id : ""}`,
              type: filterType.ASSIGN_TO,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "ASSIGNED TO",
              value: state.assigned_to,
              isMulti: true,
              isMandatory: true,
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              openstatus: true,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMandatory: true,
            },
          }
        }
      }
    }

    case user_roles.supervisor: {
      return {
        section_1: {
          label: "Enquiry Filters",
          fields: {
            enquiry_type: {
              key: "enquiry_type",
              id:`selectEnquiryTypeFilterFrom${id ? id : ""}`,
              type: filterType.ENQUIRY_TYPE,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "ENQUIRY TYPE",
              value: state.enquiry_type,
              isMulti: true,
              isMandatory: true,
            },
            assigned_to: {
              key: "assigned_to",
              id:`selectAssignToFilterFrom${id ? id : ""}`,
              type: filterType.ASSIGN_TO,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "ASSIGNED TO",
              value: state.assigned_to,
              isMulti: true,
              isMandatory: true,
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              openstatus: true,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMandatory: true,
            },
          }
        }
      }
    }

    case user_roles.help_desk: {
      return {
        section_1: {
          label: "Enquiry Filters",
          fields: {
            enquiry_type: {
              key: "enquiry_type",
              id:`selectEnquiryTypeFilterFrom${id ? id : ""}`,
              type: filterType.ENQUIRY_TYPE,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "ENQUIRY TYPE",
              value: state.enquiry_type,
              isMulti: true,
              isMandatory: true,
            },
            assigned_to: {
              key: "assigned_to",
              id:`selectAssignToFilterFrom${id ? id : ""}`,
              type: filterType.ASSIGN_TO,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "ASSIGNED TO",
              value: state.assigned_to,
              isMulti: true,
              isMandatory: true,
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              openstatus: true,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMandatory: true,
            },
          }
        }
      }
    }

    case user_roles.business_admin: {
      return {
        section_1: {
          label: `Enquiry Filters`,
          fields: {
            enquiry_type: {
              key: "enquiry_type",
              id:`selectEnquiryTypeFilterFrom${id ? id : ""}`,
              type: filterType.ENQUIRY_TYPE,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "ENQUIRY TYPE",
              value: state.enquiry_type,
              isMulti: true,
              isMandatory: true,
            },
            assigned_to: {
              key: "assigned_to",
              id:`selectAssignToFilterFrom${id ? id : ""}`,
              type: filterType.ASSIGN_TO,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "ASSIGNED TO",
              value: state.assigned_to,
              isMulti: true,
              isMandatory: true,
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              openstatus: true,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMandatory: true,
            },

          }
        }
      }
    }

    default: return {}
  }
}
