import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";
import { SimpleListWithIconText } from "../listWithIcons";
import { ICONS } from "../../../components";
import { fontStyleClass } from "../../../utils";
const styles = makeStyles((theme) => ({
  root: {
    marginBottom: 26,
  },
  header: {
    marginBottom: 10,
  },
  simpleListWithIconText:{
      color:theme.palette.secondary.main
  },
  ...fontStyleClass
}));



export const TextWithIcon = (props) => {
  const { title, list } = props;
  const classes = styles();
  const theme = useTheme()

  const handleIcon = (iconName) => {
    switch (iconName) {
      case "height":
        return ICONS.CLIENTHEIGHT(theme.palette.primary.main);
      case "weight":
        return ICONS.CLIENTWEIGHT(theme.palette.primary.main);
      case "bmi":
        return ICONS.CLIENTBMI(theme.palette.primary.main); 
  
      default:
        return ICONS.CLIENTHEIGHT(theme.palette.primary.main);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h6" className={`${classes.header} ${classes.mediumFont}`}>
          {title}
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing="2">
        {list.map((val) => {
          return (
            <Grid item>
              <SimpleListWithIconText text={val.label} icon={handleIcon(val.value)}  className={classes.simpleListWithIconText}/>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  );
};

TextWithIcon.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
};
