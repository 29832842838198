import React from "react";
import { Grid, withStyles } from "@material-ui/core";

import { ClientIcon, CalendarIcon, StarIcon } from "../../assets";
import GraphCard from "./graphCard";

export const ICONS = {
  CLIENT: (colorCode) => {
    
    return (
      <ClientIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 20,
          width: 24,
        }}
      />
    );
  },
  CALENDAR: (colorCode) => {
    return (
      <CalendarIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 20,
          width: 24,
        }}
      />
    );
  },
  STAR: (colorCode) => {
    return (
      <StarIcon
        color={colorCode ? colorCode : "secondary"}
        style={{
          height: 20,
          width: 24,
        }}
      />
    );
  },
};

export const GRAPH = {
  ONE: (
    <img
      src="/svg/graphOne.svg"
      alt="graphOne"
      style={{ height: "100%", width: "100%", objectFit: "contain" }}
    />
  ),
  TWO: (
    <img
      src="/svg/graphTwo.svg"
      alt="graphTwo"
      style={{ height: "100%", width: "100%", objectFit: "contain" }}
    />
  ),
  THREE: (
    <img
      src="/svg/graphThree.svg"
      alt="graphThree"
      style={{ height: "100%", width: "100%", objectFit: "contain" }}
    />
  ),
};

const styles = (theme) => ({
  root: {
    boxShadow: `0px 0.5px 2px 1px #e0e0e0d6`,
    borderRadius: 8,
    padding: 24,
    marginTop: 16,
    backgroundColor: "white",
  },
});

class QuickCards extends React.Component {
  render() {
    const { classes, items } = this.props;
    return (
      <div className={classes.root}>
        <Grid container direction="row" spacing={4}>
          {items &&
            items.length > 0 &&
            items.map((item, key) => {
              return (
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={key}>
                  <GraphCard {...item} />
                </Grid>
              );
            })}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(QuickCards);
