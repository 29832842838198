// import { deepPurple } from '@material-ui/core/colors';
export default (theme) => ({
  labelFocused: {
    color: `${theme.palette.text.primary} !important`
  },
  formControl: {
    minWidth: "100%",
  },
  select: {
    // minWidth: 120,
    background: 'white',
    fontWeight:200,
    border: `1px solid rgba(0,0,0, 0.2)`,
    borderRadius: 8,
    paddingLeft: 12,
    paddingTop: 6,
    paddingBottom: 6,
    "&:focus":{
      borderRadius: 8,
      background: 'white',
      borderColor: theme.palette.ternary.main
    },
  },
  icon:{
    color: `1px solid ${theme.palette.ternary.light}`,
    right: 12,
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none'
  },
  paper: {
    borderRadius: 8,
    marginTop: 8
  },
  list: {
    paddingTop:0,
    paddingBottom:0,
    background:'white',
    "& li":{
      fontWeight:200,
      paddingTop:12,
      paddingBottom:12,
    },
    "& li:hover":{
      // background: deepPurple[100]
    },
    "& li.Mui-selected":{
      color:'white',
      background: theme.palette.ternary.light
    },
    "& li.Mui-selected:hover":{
      background: theme.palette.ternary.dark
    }
  }
});