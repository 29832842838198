import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import CalenderCard from './calenderCard';
import { dateTime } from '../utils';
import moment from 'moment';

const current_time_id = "day_calender_area_current_time_";
const secondCardMarginleft = 252;
const thirdCardMarginleft = 504;

const styles = (theme) => ({
    root: {
        padding: '32px 0px 16px 32px'
    },
    row: {
        height: 124,
        position: 'relative'
    },
    typography: {
        width: '5.5%'
    },
    hr: {
        width: '94.5%',
        height: 1,
        borderStyle: 'dashed',
        borderWidth: 'thin',
        borderBottom: 'unset',
        borderLeft: 'unset',
        borderRight: 'unset',
        borderColor: theme.palette.secondary.main
    },
    currentHr: {
        width: '100%',
        height: 1,
        borderWidth: 'medium',
        borderBottom: 'unset',
        borderLeft: 'unset',
        borderRight: 'unset',
        borderColor: theme.palette.ternary.main,
        borderStyle: 'solid'
    },
    currentHrCircle: {
        height: 16,
        width: 16,
        borderRadius: 8,
        backgroundColor: theme.palette.ternary.main,
        zIndex: 3
    },
    pointerCursor: {
        cursor: 'pointer'
    }
})

class DayCalenderArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        setTimeout(() => {
            this.scrollToCurrentTime();
        }, 2000);
    }

    scrollToCurrentTime = () => {
        const currentTime = document.getElementById(current_time_id);

        if (currentTime) currentTime.scrollIntoView(false)
    }

    giveMeCalenderCard = (currentSpan, key) => {
        const { data } = this.props;

        if (!data || data.length === 0 || !data[key]) {
            return <></>;
        }

        let { events, availability, unavailability, fromTime, toTime } = data[key];
       

        const {
            classes,
            onActivityClicked,
            onAvailabilityClicked,
            onUnAvailabilityClicked,
        } = this.props;

        let activity = <></>;
        let isBeforeActivity = false;

        let avilable = <></>;
        let isBeforeAvilable = false;

        let unavailable = <></>;

        isBeforeActivity = events?.length > 0 ? true : false;
        if (events?.length) {
            activity = <Grid item className={classes.pointerCursor} id={this.generateID(currentSpan?.id,key,'activities')} onClick={() => onActivityClicked(events)}>
                <CalenderCard
                    variant="activities"
                    title={`${events.length ? events.length : 0} IN ACTIVITY`}
                    subTitle={`${moment(fromTime).format('hh:mm A')} - ${moment(toTime).format('hh:mm A')}`}
                    height={30 * 4}
                    style={{ zIndex: 1 }}
                    cards={events} />
            </Grid>
        }

        isBeforeAvilable = availability?.length > 0 ? true : false;
        if (availability?.length) {
            avilable = <Grid item className={classes.pointerCursor} id={this.generateID(currentSpan?.id,key,'available')} onClick={() => onAvailabilityClicked(availability)}>
                <CalenderCard
                    variant="available"
                    title={`${availability.length ? availability.length : 0} AVAILABLE`}
                    subTitle={`${moment(fromTime).format('hh:mm A')} - ${moment(toTime).format('hh:mm A')}`}
                    height={30 * 4}
                    style={{ zIndex: 1, marginLeft: isBeforeActivity ? secondCardMarginleft : 0 }}
                    cards={availability} />
            </Grid>
        }

        if (unavailability?.length) {
            unavailable = <Grid item className={classes.pointerCursor} id={this.generateID(currentSpan?.id,key,'unavailable')} onClick={() => onUnAvailabilityClicked(unavailability)}>
                <CalenderCard
                    variant="unavailable"
                    title={`${unavailability.length ? unavailability.length : 0} UNAVAILABLE`}
                    subTitle={`${moment(fromTime).format('hh:mm A')} - ${moment(toTime).format('hh:mm A')}`}
                    height={30 * 4}
                    style={{ zIndex: 1, marginLeft: isBeforeActivity && isBeforeAvilable ? thirdCardMarginleft : isBeforeAvilable || isBeforeActivity ? secondCardMarginleft : 0 }}
                    cards={unavailability} />
            </Grid>
        }

        return <Grid container direction="row" justify={"flex-start"} alignItems={"center"} spacing={3}>
            {activity}
            {avilable}
            {unavailable}
        </Grid>

    }

    giveMeHorizontalLine = (currentSpan) => {
        const fromTime = moment(moment(currentSpan.startTime).format('HH:mm A'), 'HH:mm A');
        const toTime = moment(moment(currentSpan.endTime).format('HH:mm A'), 'HH:mm A');

        const now = moment(moment().format('HH:mm A'), 'HH:mm A');
        const selectedDate =moment(this.props.selectedDate).startOf('day')

        const { classes } = this.props;

        if (now.isBetween(fromTime, toTime) && selectedDate.isSame(moment().startOf('day')) ) {
            return <div id={current_time_id} style={{ display: 'flex', width: '94.5%' }}>
                <div className={classes.currentHrCircle} />
                <hr className={classes.currentHr} id={`hr__${currentSpan.label}`} />
            </div>
        } else {
            return <hr className={classes.hr} id={`hr__${currentSpan.label}`} />
        }
    }

    generateID = (id,key,variant) => id ? id?.toLowerCase()?.split('-')?.join('')?.concat(key)?.concat(variant ?? '') : `events${key}`

    render() {

        const { classes} = this.props;

        return <div>            
            <div className={classes.root}>

                <Grid container direction='row' justify="center" alignItems="center">
                    {dateTime.map((_, key) => {
                        return <Grid id={this?.generateID(_.id,key)} key={key} container direction='row' justify="space-between" item xs={12} className={classes.row}>
                            <Grid item xs={12} style={{ display: 'flex', position: 'relative' }}>
                                <Typography variant="caption" color="textSecondary" className={classes.typography}>{_.label}</Typography>
                                {this.giveMeHorizontalLine(_)}
                            </Grid>
                            <Grid item xs={12} style={{ marginLeft: 62, marginTop: 10, position: 'absolute' }}>
                                {this.giveMeCalenderCard(_, key)}
                            </Grid>
                        </Grid>
                    })}
                </Grid>
            </div>
        </div>
    }
}

DayCalenderArea.propTypes = {
    availables: PropTypes.object,
    activities: PropTypes.object,
    unAvailables: PropTypes.object
}

export default withStyles(styles)(DayCalenderArea);