import React from 'react'
import { connect } from 'react-redux';
import { actions } from 'tanyacare-middleware';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
import { Grid } from "@material-ui/core";
import { formComponentTypes } from "../../../utils";
// import { CustomTimeline, InternatlServerError, LoadingSection } from '../../../components'
import AddEditUserManagement from "../../../components/addEditForm";

class AssignCoordinator extends React.Component {

    componentDidMount() {
        this.getLogs()
    }

    getLogs = () => {
        console.log(this.props)
        this.props.GET_SUBSCRIPTION_MAP_COORDINATOR_LIST({
            // subscriptionId:this.props.subscriptionId,
            partnerId: this.props.partnerId,
            coordinatorId: this.props.coordinatorId
          });
    }

    render() {
        const components = {
            section_1: { 
                label: "",
                fields: {
                  coordinator: {
                    key: "coordinator",
                    type: formComponentTypes.SELECT_BOX,
                    isDisabled: false,
      
                    label: "SELECT COORDIddfsdfsdfNATOR",
                    breakPoints: {
                      xs: 12,
                      sm: 12,
                      md: 12,
                      lg: 12,
                      xl: 12,
                    },
                    value: "",
                    options: 
                    this.props?.getCoordinatorsList?.data,
                    isMandatory: true,
                  },
                },
              },
          };
        // const { data, loading, error } = this.props?.refundLogs
        return (
            <Grid container>
            <Grid item xs={12}>
              {/* {this.props.resourceType ? ( */}
                <AddEditUserManagement
                  components={components}
                  onSaveButtonClicked={(isError, data, isUrl) => this.props.assigncoordinator(isError, data, isUrl, this.props.subscriptionId)}
                  cancelForm={this.props.onCancel}
                  //   loading={addLoading}
                  //   saveButtonLabel={addLoading ? "Saving..." : "Save"}
                />
              {/* ) : (
                <div>Nothig to render</div>
              )} */}
            </Grid>
          </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    getCoordinatorsList: state?.subscription_tracking?.subscription_coordinator_mapping_list,
});


export default connect(
    mapStateToProps,
    actions
)(AssignCoordinator)
