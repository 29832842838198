import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || theme.palette.primary.main
  })
}))


export const AvatarStackIcon = (props) => {
  const classes = useStyles(props);
  return (
    <SvgIcon style={props.style}>
      <path className={classes.cls1} d="M12 4a3.5 3.5 0 1 0 3.5 3.5A3.5 3.5 0 0 0 12 4zm0 2a1.5 1.5 0 1 1-1.5 1.5A1.5 1.5 0 0 1 12 6zM4.5 7A2.5 2.5 0 1 0 7 9.5 2.5 2.5 0 0 0 4.5 7zm15 0A2.5 2.5 0 1 0 22 9.5 2.5 2.5 0 0 0 19.5 7zm-15 1.723a.777.777 0 1 1-.777.777.778.778 0 0 1 .777-.777zm15 0a.777.777 0 1 1-.777.777.778.778 0 0 1 .777-.777zM12 13a12.157 12.157 0 0 0-5.445 1.4A5.841 5.841 0 0 0 4.5 14C2.263 14 0 15.4 0 16.932V20h24v-3.068C24 15.4 21.737 14 19.5 14a5.841 5.841 0 0 0-2.055.4A12.157 12.157 0 0 0 12 13zm0 2c2.134 0 4.622.961 5 1.566V18H7v-1.434C7.378 15.961 9.866 15 12 15zm-7.5 1a3.5 3.5 0 0 1 .559.063A1.711 1.711 0 0 0 5 16.5V18H2v-.99A3.319 3.319 0 0 1 4.5 16zm15 0a3.319 3.319 0 0 1 2.5 1.01V18h-3v-1.5a1.709 1.709 0 0 0-.059-.437A3.5 3.5 0 0 1 19.5 16z" transform="translate(0 -4)" />
    </SvgIcon>
  )
}

AvatarStackIcon.propTypes = {
  /**Sets the color of the icon. */
  color: PropTypes.string,
  /**Sets the additional styles of the icon. */
  style: PropTypes.object
}