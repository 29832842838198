import React from "react";
import AddEditProductManagement from "../../../../components/addEditForm";
import {
  formComponentTypes,
  alertProps,
  user_roles,
  options,
  formTempeletesTypeId,
  converToReactSelect,
  metaType,
  base64MimeType,
  upload_file,
  TAXTYPE,
} from "../../../../utils/index";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { routes } from "../../../../router/routes";
import {
  LoadingSection,
  InternatlServerError,
  NotFoundSection,
} from "../../../../components";
import moment from "moment";
import { FILE_URI } from "../../../../utils/constants";
import { priceDecimalAdder } from "../../../../utils/common";

// const taxIdOptions = [{label: "Sales Tax", value: 10, type: "variable"}, {label: "Service Tax", value: 25, type: "fixed"}]
class AddEditService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      validity: { label: "Custom", value: "custom" },
      taxType: null,
      price_editable: false,
      pre_assessment_required: false,
      assessment_required: false,
      service_deliverable_required: false,
      promotional_content: null,
      categories: [],
      serviceType: [],
      preAssignmentOptions: [],
      assessment: false,
      assessmentOptions: [],
      serviceDeliverablesOptions: [],
      preAssignmentOptionsResourceType: [],
      assessmentOptionsResourceType: [],
      serviceDeliverablesOptionsResourceType: [],
      serviceDelivery: false,
      serviceDeliveryOptions: [],
      serviceDeliveryOptionsResourceType: [],
      isBookable: false,
      allowToBundle: false,
      allowToShow: false,
      provider: null,
      selectedServiceType: null,
      category: [],
      localServiceLoading: false,
    };
  }

  componentDidMount() {
    this.getEssentials();
    this.checkForRole();
  }

  checkForRole = () => {
    let auth_data = this.props.auth_data;

    if (user_roles.provider === auth_data.user_role) {
      let select_provider = [
        {
          value: auth_data?.user_id,
          label: auth_data?.profile_name,
        },
      ];

      // let select_category = auth_data.category;

      // this.servicesByCareProvider(select_provider, { select_category });
      this.categoriesOptions(select_provider);
    }
  };

  getEssentials = async () => {
    this.props.GET_CATEGORIES({});
    this.props.GET_FREQ({});
    this.props.GET_ALL_VALDITY({});
    this.props.GET_MASTER_PRODUCT_MANAGEMENT_GROUP_TYPES({});
    this.props.GET_PARTNER_DROPDOWN({});
    this.props.GET_RESOURCE_TYPES({});
    await this.props.GET_TAX_INTEGRATION({});

    if (this.props.isEdit) {
      const id = this.props?.match?.params?.id;
      const versionId = this.props?.match?.params?.versionId;
      await this.props.GET_SERVICES_INFO({ id, versionId });
      this.setState({
        validity: this.props?.serviceInfo?.data?.[0]?.validityPeriod,
        //Setting is price editable state on Edit
        price_editable: this.props?.serviceInfo?.data?.[0]?.isPriceEditable,
        selectedCategory:
          this.props?.serviceInfo?.data?.[0]?.selectedCategory?.[0],
        resourceName: this.props?.serviceInfo?.data?.[0]?.resourceName,
        categories:
          this.props?.serviceInfo?.data?.[0]?.provider?.partnerCategory,
        assessment: this.props?.serviceInfo?.data?.[0]?.assessment,
        service_deliverables:
          this.props?.serviceInfo?.data?.[0]?.serviceDeliverables,
        pre_assessment: this.props?.serviceInfo?.data?.[0]?.preAssessment,
        category: this.props?.serviceInfo?.data?.[0]?.selectedCategory?.[0],
        isBookable: this.props?.serviceInfo?.data?.[0]?.isBookable,
        allowToBundle: this.props?.serviceInfo?.data?.[0]?.allowToBundle,
        frequency: this.props?.serviceInfo?.data?.[0]?.frequency,
        taxType: this.props?.serviceInfo?.data?.[0]?.taxType,
        tax: this.props?.serviceInfo?.data?.[0]?.tax,
      });
      // get service type list based on category id
      this.props.GET_SERVICE_TYPE({
        categoryId: this.props?.serviceInfo?.data?.[0]?.selectedCategory,
      });

      // get categories List based on provider id
      let params = {
        partnerArray:
          this.props?.serviceInfo?.data?.[0]?.selectedProvider?.map(
            (val) => val.value
          ) ?? [],
      };
      this.props.GET_CATEGORY_LIST_BASEDON_PROVIDERS(params);
      // this.props.GET_CATEGORIES(params)

      // get template assessment options
      if (
        this.props?.serviceInfo?.data?.[0]?.assessment?.dropdown1Value
        // ?.templateId
      ) {
        this.handlePreassement("", formTempeletesTypeId.assignment);
        // this.handledropDownOnChangePreassement(
        //   "update",
        //   formTempeletesTypeId.assignment
        // );
      }
      if (
        this.props?.serviceInfo?.data?.[0]?.preAssessment?.dropdown1Value
        // ?.templateId
      ) {
        this.handlePreassement("", formTempeletesTypeId.pre_assignment);
        // this.handledropDownOnChangePreassement(
        //   "update",
        //   formTempeletesTypeId.pre_assignment
        // );
      }
      if (
        this.props?.serviceInfo?.data?.[0]?.serviceDeliverables?.dropdown1Value
        // ?.templateId
      ) {
        this.handlePreassement("", formTempeletesTypeId.service_deliverables);
        // this.handledropDownOnChangePreassement(
        //   "update",
        //   formTempeletesTypeId.service_deliverables
        // );
      }
      if (
        this.props?.serviceInfo?.data?.[0]?.service_delivery?.dropdown1Value
        // ?.templateId
      ) {
        this.handlePreassement("", formTempeletesTypeId.service_delivery);
        // this.handledropDownOnChangePreassement(
        //   "update",
        //   formTempeletesTypeId.service_delivery
        // );
      }
    }
  };

  careProviderByCategory = (categories) => {
    this.props.GET_PROVIDER_LIST_BASEDON_CATEGORY({
      categories: Array.isArray(categories)
        ? categories.map((_) => _.value)
        : [categories?.value],
    });
  };

  onSaveButtonClicked = async (isError, data, isUrl) => {
    let { data: editData } = this.props?.serviceInfo;

    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      this.setState({ localServiceLoading: true });
      let auth_data = this.props.auth_data;

      if (user_roles.provider === auth_data.user_role) {
        data.select_provider = {
          value: auth_data?.user_id,
          label: auth_data?.profile_name,
        };
      }

      const uploadPromotionalContent = async (payloadNew) => {
        // PROMOTIONAL CONTENT UPLOAD
        if (data?.promotional_content) {
          if (
            data?.promotional_content?.constructor === File &&
            data?.promotional_content?.name
          ) {
            let _filename = data.promotional_content.name.trim();

            await this.props
              .FILE_UPLOAD({
                file: data?.promotional_content,
                fileName: _filename,
              })
              .then(async (val) => {
                let _val = await val;
                if (_val?.payload?.data?.fileid) {
                  payloadNew["promotionalContent"] =
                    _val?.payload?.data?.fileid ?? "";
                  payloadNew["promotionalFileType"] =
                    _val?.payload?.data?.filetype ?? "";
                } else {
                  console.log(val, "promotional content error");
                  this.props.alert.setSnack({
                    open: true,
                    horizontal: alertProps.horizontal.center,
                    vertical: alertProps.vertical.top,
                    msg: "File Id not received after uploaded promotional content. Try again!",
                    severity: alertProps.severity.error,
                  });
                  return false;
                }
              })
              .catch((val) => {
                console.log(val, "promotional content error");
                this.props.alert.setSnack({
                  open: true,
                  horizontal: alertProps.horizontal.center,
                  vertical: alertProps.vertical.top,
                  msg: "Couldn't upload the promotional Content. Try again!",
                  severity: alertProps.severity.error,
                });
                return false;
              });
          }
        } else {
          console.log(data?.promotional_content);
          payloadNew["promotionalContent"] =
            data?.promotional_content?.split("/")[
              data?.promotional_content?.split("/").length - 1
            ];
        }
      };

      if (this.props.isEdit) {
        const serviceId = this.props?.match?.params?.id;
        const getFlagValue = () => {
          const { data: editData } = this.props?.serviceInfo;
          const editedEffective = data?.effectiveFrom;
          const dbEffective = editData?.[0]?.effectiveFrom;

          const editedEndsOn = data?.endsOn;
          const dbEndsOn = editData?.[0]?.effectiveTo;

          // if (
          //   moment(data?.effectiveFrom)?.isSame(dbStartDate) &&
          //   moment(data?.endsOn)?.isSame(dbEndDate)
          // ) {
          //   return false;
          // } else {
          //   return true;
          // }
          if (!moment(editedEffective)?.isSame(dbEffective)) {
            return true;
          } else if (editedEndsOn && dbEndsOn) {
            if (!moment(editedEndsOn)?.isSame(dbEndsOn)) {
              return true;
            }
          } else if (
            (!editedEndsOn && dbEndsOn) ||
            (editedEndsOn && !dbEndsOn)
          ) {
            return true;
          }

          return false;
        };
        let payloadNew = {
          selectedProvider: data?.select_provider?.value,
          selectedCategory: Array.isArray(data?.select_category)
            ? data?.select_category?.map((_) => _.value)
            : [data?.select_category?.value],
          resourceName: data?.service_name,
          selectedServiceType: data?.select_service_type?.value,
          currency: data?.currencyType,
          price: priceDecimalAdder(data?.price),
          isPriceEditable: this.state.price_editable,
          validityPeriod: data?.productValidity?.id, // need to integrate the master api list on this component option
          effectiveFrom: moment.utc(data?.effectiveFrom)?.format(),
          effectiveTo: data?.endsOn ? moment.utc(data?.endsOn)?.format() : "",
          modeOfActivity: data?.mode_of_activity,
          resourceTypeCategory:
            data?.service_resource_type.length > 0
              ? data?.service_resource_type?.map((val) => val.value)
              : [],
          duration: data?.duration, //data?.duration // check duration convertion fuction or component value
          serviceTemplate: [], // key auto creat in blow fuction
          promotionalContent:
            data?.promotional_content?.constructor === File
              ? data?.promotional_content
              : data?.promotional_content?.replace(FILE_URI + "/", ""),
          promotionalFileType: editData?.[0]?.promotionalFileType ?? "",
          description: data?.description,
          termsAndConditions: data?.termsAndConditions,
          terms_conditions: data?.termsAndConditions,
          uploadImage: data?.profilePic,
          assessment: data?.assessment,
          pre_assessment: data?.pre_assessment,
          service_deliverables: data?.service_deliverables,
          service_delivery: data?.service_delivery,
          provider: data?.select_provider,
          serviceId: serviceId,
          isBookable: data?.isBookable,
          allowToBundle: data?.allowToBundle,
          allowToShow: data?.allowToShow,
          frequency: data?.frequency,
          frequencyObject: data?.frequency,
          group_type: data?.group_type,
          tax: data?.tax,
          taxType: data?.taxType,
          isEffectiveFromToValidation: getFlagValue(),
        };

        if (data?.pre_assessment?.isChecked) {
          payloadNew["serviceTemplate"].push({
            templateTypeId: data?.pre_assessment?.dropdown1Value?.templateType, //Need to confirm
            templateId: data?.pre_assessment?.dropdown1Value?.templateId, //Need to confirm
            resourceTypeCategoryId: data?.pre_assessment?.dropdown2Value?.value, //Need to confirm
          });
        }
        if (data?.assessment?.isChecked) {
          payloadNew["serviceTemplate"].push({
            templateTypeId: data?.assessment?.dropdown1Value?.templateType, //Need to confirm
            templateId: data?.assessment?.dropdown1Value?.templateId, //Need to confirm
            resourceTypeCategoryId: data?.assessment?.dropdown2Value?.value, //Need to confirm
          });
        }
        if (data?.service_deliverables?.isChecked) {
          payloadNew["serviceTemplate"].push({
            templateTypeId:
              data?.service_deliverables?.dropdown1Value?.templateType, //Need to confirm
            templateId: data?.service_deliverables?.dropdown1Value?.templateId, //Need to confirm
            resourceTypeCategoryId:
              data?.service_deliverables?.dropdown2Value?.value, //Need to confirm
          });
        }
        if (data?.service_delivery?.isChecked) {
          payloadNew["serviceTemplate"].push({
            templateTypeId:
              data?.service_delivery?.dropdown1Value?.templateType, //Need to confirm
            templateId: data?.service_delivery?.dropdown1Value?.templateId, //Need to confirm
            resourceTypeCategoryId:
              data?.service_delivery?.dropdown2Value?.value, //Need to confirm
          });
        }

        // UPLOADING PROFILE IMAGE BEFORE SAVING DATA
        if (data?.profilePic) {
          if (base64MimeType(data?.profilePic)?.length > 0) {
            let { fileName, withoutType } = upload_file(data?.profilePic);
            await this.props
              .FILE_UPLOAD({
                file: withoutType,
                fileName: fileName,
                isbase64: true,
              })
              .then(async (val) => {
                payloadNew["uploadImage"] = await val?.payload?.data?.fileid;
              });
          } else {
            payloadNew["uploadImage"] =
              data?.profilePic?.split("/")[
                data?.profilePic?.split("/").length - 1
              ];
          }
        }

        await uploadPromotionalContent(payloadNew);

        this.props.ADD_SERVICES(payloadNew);
        // })
      } else {
        let payloadNew = {
          selectedProvider: data?.select_provider?.value,
          selectedCategory: Array.isArray(data?.select_category)
            ? data?.select_category?.map((_) => _.value)
            : [data?.select_category?.value],
          resourceName: data?.service_name,
          selectedServiceType: data?.select_service_type?.value,
          currency: data?.currencyType,
          price: priceDecimalAdder(data?.price),
          isPriceEditable: this.state.price_editable,
          // validityPeriod: data?.productValidity,
          validityPeriod: data?.productValidity?.id, // need to integrate the master api list on this component option
          effectiveFrom: moment.utc(data?.effectiveFrom)?.format(),
          effectiveTo: data?.endsOn ? moment.utc(data?.endsOn)?.format() : "",
          modeOfActivity: data?.mode_of_activity,
          resourceTypeCategory:
            data?.service_resource_type.length > 0
              ? data?.service_resource_type?.map((val) => val.value)
              : [],
          duration: data?.duration, //data?.duration // check duration convertion fuction or component value
          serviceTemplate: [], // key auto creat in blow fuction
          promotionalContent: data?.promotional_content ?? "",
          promotionalFileType: data?.promotionalFileType ?? "",
          description: data?.description,
          termsAndConditions: data?.termsAndConditions,
          uploadImage: data?.profilePic,
          assessment: data?.assessment,
          pre_assessment: data?.pre_assessment,
          service_deliverables: data?.service_deliverables,
          service_delivery: data?.service_delivery,
          provider: data?.select_provider,
          isBookable: data?.isBookable,
          allowToBundle: data?.allowToBundle,
          allowToShow: data?.allowToShow,
          frequency: data?.frequency,
          frequencyObject: data?.frequency,
          group_type: data?.group_type,
          tax: data?.tax,
          taxType: data?.taxType,
          isEffectiveFromToValidation: true,
        };

        if (data?.pre_assessment?.isChecked) {
          payloadNew["serviceTemplate"].push({
            templateTypeId: data?.pre_assessment?.dropdown1Value?.templateType, //Need to confirm
            templateId: data?.pre_assessment?.dropdown1Value?.templateId, //Need to confirm
            resourceTypeCategoryId: data?.pre_assessment?.dropdown2Value?.value, //Need to confirm
          });
        }
        if (data?.assessment?.isChecked) {
          payloadNew["serviceTemplate"].push({
            templateTypeId: data?.assessment?.dropdown1Value?.templateType, //Need to confirm
            templateId: data?.assessment?.dropdown1Value?.templateId, //Need to confirm
            resourceTypeCategoryId: data?.assessment?.dropdown2Value?.value, //Need to confirm
          });
        }
        if (data?.service_deliverables?.isChecked) {
          payloadNew["serviceTemplate"].push({
            templateTypeId:
              data?.service_deliverables?.dropdown1Value?.templateType, //Need to confirm
            templateId: data?.service_deliverables?.dropdown1Value?.templateId, //Need to confirm
            resourceTypeCategoryId:
              data?.service_deliverables?.dropdown2Value?.value, //Need to confirm
          });
        }
        if (data?.service_delivery?.isChecked) {
          payloadNew["serviceTemplate"].push({
            templateTypeId:
              data?.service_delivery?.dropdown1Value?.templateType, //Need to confirm
            templateId: data?.service_delivery?.dropdown1Value?.templateId, //Need to confirm
            resourceTypeCategoryId:
              data?.service_delivery?.dropdown2Value?.value, //Need to confirm
          });
        }
        // UPLOADING PROFILE IMAGE BEFORE SAVING DATA

        if (data?.profilePic) {
          if (base64MimeType(data?.profilePic)?.length > 0) {
            let { fileName, withoutType } = upload_file(data?.profilePic);
            await this.props
              .FILE_UPLOAD({
                file: withoutType,
                fileName: fileName,
                isbase64: true,
              })
              .then(async (val) => {
                payloadNew["uploadImage"] = await val?.payload?.data?.fileid;
              });
          }
        }
        await uploadPromotionalContent(payloadNew);

        // If the mode is add. Then Check UI validation using query.
        const isErrorFree = await this.handleActivateDeactivateValidations(
          payloadNew?.provider?.value,
          {
            effectiveFrom: payloadNew?.effectiveFrom,
            effectiveTo: payloadNew?.effectiveTo,
          }
        );
        if (isErrorFree) {
          this.props.ADD_SERVICES(payloadNew);
        }
      }
    }
  };

  handleActivateDeactivateValidations = async (
    partnerId,
    resource_validity
  ) => {
    const startDate = resource_validity?.effectiveFrom;
    const endDate = resource_validity?.effectiveTo;

    await this.props?.GET_PARTNER_ACTIVE_DATE_BY_SUPERVISOR({
      partnerId: partnerId,
      startDate: startDate,
      endDate: endDate,
    });
    const { data } = this.props?.getPartnerActiveStatusBySupervisor;

    if (Array.isArray(data) && data?.length > 0) {
      // No issue in adding Branch
      return true;
    } else {
      // To avoid multiple service add, the loading state below is used.
      this.setState({ localServiceLoading: false });
      // Issue occured while adding branch
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: `Partner may not be active on the selected dates. Please select different date.`,
        severity: alertProps.severity.error,
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Add Services
    const { addService } = nextProps;
    const oldAddService = this.props.addService;

    //Checking for Add Service messages
    if (oldAddService?.loading !== addService?.loading) {
      if (!addService.loading && !addService.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: addService.message,
          severity: alertProps.severity.success,
        });
        this.props.history.push(routes.manage_service);
      } else if (addService.error) {
        this.setState({ localServiceLoading: false });
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: addService.message,
          severity: alertProps.severity.error,
        });
      }
    }

    //Update Package
    const { updateServiceInfo } = nextProps;
    const oldUpdateServiceInfo = this.props.updateServiceInfo;

    //Checking for Add Package messages
    if (oldUpdateServiceInfo?.loading !== updateServiceInfo?.loading) {
      if (!updateServiceInfo.loading && !updateServiceInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Service Updated successfully!",
          severity: alertProps.severity.success,
        });
        this.props.history.push(routes.manage_service);
      } else if (updateServiceInfo.error) {
        this.setState({ localServiceLoading: false });
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Update service.",
          severity: alertProps.severity.error,
        });
      }
    }

    //Get Services info
    const { serviceInfo } = nextProps;
    const oldserviceInfo = this.props.serviceInfo;

    //Checking for get service info
    if (oldserviceInfo?.loading && !serviceInfo?.loading) {
      const { data } = serviceInfo;
      // this.state.price_editable
      // this.setState({price_editable:data?.data?.[0]?.resourceMeta?.priceEditable})

      if (!this.props?.careProviderByCategory?.data?.length) {
        this.careProviderByCategory(data?.category);
      }
    }

    return true;
  }

  canIHaveCategory = () => {
    if (this.props?.auth_data?.category?.length === 1) {
      return this.props?.auth_data?.category?.[0];
    } else {
      return [];
    }
  };

  categoriesOptions = (getProvider, state) => {
    if (getProvider) {
      this.setState({ provider: getProvider });
      let params = {
        partnerArray: Array.isArray(getProvider)
          ? getProvider?.map((val) => val?.value)
          : [getProvider?.value],
      };
      this.props.GET_CATEGORY_LIST_BASEDON_PROVIDERS(params);
      this.props.GET_RESOURCE_TYPES(params);
      // this.props.GET_CATEGORIES(params)
    } else {
      this.setState({ provider: getProvider });
      let params = {
        partnerArray: [getProvider?.value],
      };
      this.props.GET_CATEGORY_LIST_BASEDON_PROVIDERS(params);
      // this.props.GET_CATEGORIES(params)
      this.props.GET_SERVICE_TYPE({ categoryId: [] });
    }
    return false;
  };

  getServiceTypeBasedOnCategory = (category, state) => {
    this.setState({ ...this.state, category });
    this.props.GET_SERVICE_TYPE({
      categoryId: Array.isArray(category)
        ? category?.map((_) => _.value)
        : [category?.value],
    });

    // const resetData = (state) => {
    //   this.setState({
    //     isResetState:state,
    //     preAssignmentOptions: [],
    //     assignmentOptions: [],
    //     serviceDeliverablesOptions: [],
    //     preAssignmentOptionsResourceType: [],
    //     assessmentOptionsResourceType: [],
    //     serviceDeliverablesOptionsResourceType: [],
    //     serviceDeliveryOptions: [],
    //     serviceDeliveryOptionsResourceType: [],
    //     provider:state?.provider,
    //     category:state?.select_category,
    //     selectedServiceType:state?.selectedServiceType
    //   }, () => {
    //     this.props.GET_SERVICE_TYPE({ categoryId: Array.isArray(category) ? category?.map(_ => _.value) : [category?.value] });
    //   });
    // }

    // const handleOnOkModal = () => {
    //   let _state = this.state
    //   _state.category = [];
    //   _state.select_category = {};
    //   _state.selectedServiceType = {};

    //   this.props.dialog.setDialog({
    //     ...this.props.dialog,
    //     open: false,
    //     title: "Remove Provider",
    //     body: "Removing provider will remove selected services",
    //     positiveBtn: "Ok",
    //     negativeBtn: "CANCEL",
    //     onOk: () => { },
    //     onCancel: () => { },
    //   });
    //   // resetData(state)
    // };

    // const handleOnClose = () => {
    //   let _state = this.state;
    //
    //   _state.category = [];
    //   _state.select_category = {};
    //   _state.selectedServiceType = {};

    //   this.props.dialog.setDialog({
    //     ...this.props.dialog,
    //     open: false,
    //     title: "Remove Provider",
    //     body: "Removing provider will remove selected services",
    //     positiveBtn: "Ok",
    //     negativeBtn: "CANCEL",
    //     onOk: () => { },
    //     onCancel: () => { },
    //   });
    //   resetData(_state);
    // };
    // let removedCategory = state?.select_category?.length;
    // if(category === null || category?.length < removedCategory){
    //   this.props.dialog.setDialog({
    //     ...this.props.dialog,
    //     open: true,
    //     title: "Remove Provider",
    //     body: "Removing provider will remove selected category and Service type, Assessment also",
    //     positiveBtn: "Ok",
    //     negativeBtn: "CANCEL",
    //     onOk: handleOnOkModal,
    //     onCancel: handleOnClose,
    //   });
    // }else{
    //   this.setState({...this.state, category})
    //   this.props.GET_SERVICE_TYPE({ categoryId: Array.isArray(category) ? category?.map(_ => _.value) : [category?.value] });
    // }
  };

  canIHaveProviderOptions = () => {
    // const { auth_data } = this.props;

    // if (
    //   user_roles.supervisor === auth_data.user_role ||
    //   user_roles.service_coordinator === auth_data.user_role ||
    //   user_roles.service_professional === auth_data.user_role
    // ) {
    //   return (
    //     auth_data?.providerDetails?.map((_) => {
    //       return { value: _.provider_id, label: _.provider_name };
    //     }) ?? ""
    //   );
    // } else {
    //   return this.props?.providerBasedOnCategory?.data ?? "";
    // }
    this.props.GET_PARTNER_DROPDOWN({});
    return [{ label: "vasan", value: "sdf" }];
  };

  handleValidityOptions = (selectedValidity) => {
    this.setState({
      validity: selectedValidity,
    });
  };

  handleDateChange = (value, key) => {
    this.setState({
      [key]: value,
    });
  };
  handleCheckBox = (state, key) => {
    this.setState({ [key]: state });
  };
  calculateStartDateMin = () => {
    // Destructure validity (contains selected validity option) from the state
    const { validity } = this.state;

    // Check if the value of the selected validity is "custom", then no min value should be set. User can select any past date.
    if (validity.value === "custom") {
      return null;
    } else {
      // If validity value is not "custom"
      // If validity value exists
      if (validity.value) {
        // parseInt from the value
        const daysCount = parseInt(validity.value);

        // Get today date
        // const today = moment();

        // Calculate min date to restrict user to select
        let min = moment().subtract(daysCount - 1, "days");

        // return new Date from the calculated moment object
        return new Date(min);
      } else {
        // If the validity value does not exists then return null
        return null;
      }
    }
  };

  getDateValue = (valueFor) => {
    const { startDate, validity } = this.state;
    if (startDate && validity.value !== "custom") {
      if (valueFor === "startDate") {
        new Date(moment().startOf("day"));
      }
      if (valueFor === "endDate") {
        const daysCount = parseInt(validity.value);
        new Date(moment().add(daysCount, "days"));
      }
    } else {
      return new Date(moment().startOf("day"));
    }
  };
  addAssementSection = async () => {
    const { data } = (await this.props?.collectAllTemplateTypes?.data) ?? {
      data: [],
    };

    const fields = data?.map((x) => ({
      [x.value]: {
        key: x.value,
        type: formComponentTypes.SWITCHBOXDROPDOWN,
        isMandatory: true,
        dropdown1Label: `SELECT ${x?.label?.toUpperCase()}`,
        dropdown2Label: "SELECT RESOURCE TYPE",
        dropdown1Options: [],
        dropdown2Options: [],
        dropdown1Value: [],
        dropdown2Value: [],
        label: x?.label,
        // value: { dropdown1Value: [], isChecked: false, dropdown2Value: [] },
        breakPoints: {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12,
          xl: 12,
        },
      },
    }));
    return {
      fields: {
        ...fields,
        // pre_assessment: {
        //   key: "pre_assessment",
        //   type: formComponentTypes.SWITCHBOXDROPDOWN,

        //   isMandatory: true,
        //   dropdown1Label: "SELECT PRE-ASSESSMENT",
        //   dropdown2Label: "SELECT RESOURCE TYPE",
        //   dropdown1Options: [],
        //   dropdown2Options: [],
        //   dropdown1Value: [],
        //   dropdown2Value: [],
        //   label: "Pre-Assessment",
        //   value: { dropdown1Value: [], isChecked: false, dropdown2Value: [] },

        //   breakPoints: {
        //     xs: 12,
        //     sm: 12,
        //     md: 12,
        //     lg: 12,
        //     xl: 12,
        //   },
        // },
        // assessment: {
        //   key: "assessment",
        //   type: formComponentTypes.SWITCHBOXDROPDOWN,

        //   isMandatory: true,
        //   dropdown1Label: "SELECT ASSESSMENT",
        //   dropdown2Label: "SELECT RESOURCE TYPE",
        //   dropdown1Options: [],
        //   dropdown2Options: [{ label: "pre1", value: "pre1" }],
        //   dropdown1Value: [],
        //   dropdown2Value: [],
        //   label: "Assessment",
        //   value: { dropdown1Value: [], isChecked: false, dropdown2Value: [] },

        //   breakPoints: {
        //     xs: 12,
        //     sm: 12,
        //     md: 12,
        //     lg: 12,
        //     xl: 12,
        //   },
        // },
        // service_deliverables: {
        //   key: "service_deliverables",
        //   type: formComponentTypes.SWITCHBOXDROPDOWN,

        //   isMandatory: true,
        //   dropdown1Label: "SELECT SERVICE DELIVERY REPORT",
        //   dropdown2Label: "SELECT RESOURCE TYPE",
        //   dropdown1Options: [],
        //   dropdown2Options: [{ label: "pre1", value: "pre1" }],
        //   dropdown1Value: [],
        //   dropdown2Value: [],
        //   label: "Service Delivery Report",
        //   value: { dropdown1Value: [], isChecked: false, dropdown2Value: [] },
        //   breakPoints: {
        //     xs: 12,
        //     sm: 12,
        //     md: 12,
        //     lg: 12,
        //     xl: 12,
        //   },
        // },
      },
    };
  };

  handleFileUpload = (fileUploaded) => {
    this.setState({
      promotional_content: fileUploaded,
    });
  };

  handleFileRemove = () => {
    this.setState({
      promotional_content: null,
    });
  };

  // encodeDuration = (time) => {
  //   const hours =
  //     new Date(time)?.getHours() % 12 === 0
  //       ? 12
  //       : new Date(time)?.getHours() % 12;
  //   const minutes = new Date(time)?.getMinutes();
  //   return moment.duration(`${hours}:${minutes}`).asMinutes() + "";
  // };

  // decodeDuration = (duration) => {
  //   const durationInt = parseInt(duration);
  //   const hours = parseInt(durationInt / 60);
  //   const minutes = parseInt(durationInt % 60);
  //   let cleanDate = moment().toDate();
  //   cleanDate.setSeconds(0);
  //   cleanDate.setMilliseconds(0);
  //   cleanDate.setHours(hours);
  //   cleanDate.setMinutes(minutes);
  //   return cleanDate;
  // };
  handlePreassement = async (value, templateTypeId) => {
    // templateType Id is selectedServiceType id
    //
    let data = await this.props.GET_SERVICE_TEMPLATES({
      categoryId: Array.isArray(this.state?.category)
        ? this.state?.category?.map((_) => _.value)
        : [this.state?.category?.value],
      templateTypeId: templateTypeId,
    });
    switch (templateTypeId) {
      case formTempeletesTypeId.pre_assignment:
        this.setState({
          preAssignmentOptions: converToReactSelect(
            data?.payload?.data
              ? data?.payload?.data?.filter(
                  (data) => data?.type === "Assessment"
                )
              : [],
            "templateId",
            "templateName"
          ),
        });
        break;
      case formTempeletesTypeId.assignment:
        this.setState({
          assessment: value,
          assessmentOptions: converToReactSelect(
            data?.payload?.data
              ? data?.payload?.data?.filter(
                  (data) => data?.type === "Assessment"
                )
              : [],
            "templateId",
            "templateName"
          ),
        });
        break;
      case formTempeletesTypeId.service_deliverables:
        this.setState({
          serviceDeliverablesOptions: converToReactSelect(
            data?.payload?.data
              ? data?.payload?.data?.filter((data) => data?.type === "Report")
              : [],
            "templateId",
            "templateName"
          ),
        });
        break;
      case formTempeletesTypeId.service_delivery:
        this.setState({
          serviceDelivery: value,
          serviceDeliveryOptions: converToReactSelect(
            data?.payload?.data
              ? data?.payload?.data?.filter((data) => data?.type === "Report")
              : [],
            "templateId",
            "templateName"
          ),
        });
        break;
      default:
        return null;
    }
  };
  handledropDownOnChangePreassement = async (value, templateTypeId) => {
    let dataList = [];
    if (value) {
      let data = await this.props.GET_RESOURCE_TYPES({
        categoryId: this.state?.category?.value,
        templateTypeId: templateTypeId,
      });
      dataList = data?.payload?.data?.[0]?.resources;
    }

    switch (templateTypeId) {
      case formTempeletesTypeId.pre_assignment:
        this.setState({
          preAssignmentOptionsResourceType: dataList,
        });
        break;
      case formTempeletesTypeId.assignment:
        this.setState({
          assessmentOptionsResourceType: dataList,
        });
        break;
      case formTempeletesTypeId.service_deliverables:
        this.setState({
          serviceDeliverablesOptionsResourceType: dataList,
        });
        break;
      case formTempeletesTypeId.service_delivery:
        this.setState({
          serviceDeliveryOptionsResourceType: dataList,
        });
        break;
      default:
    }
  };

  handleTaxTypeChange = (newValue) => {
    this.setState({
      taxType: newValue,
    });
  };

  render() {
    let {
      loading: editLoading,
      error: editError,
      data: editData,
    } = this.props?.serviceInfo;

    const { data: taxTypeOptions } = this.props?.taxTypeMaster;

    let { isEdit, auth_data } = this.props;
    let { data } = this.props?.getValidity;
    let validityOptions = data ? data : [];

    console.log(this.props.product_management_group_types);

    const components = {
      section_1: {
        label: "",
        fields: {
          profilePic: {
            key: "profilePic",
            type: formComponentTypes.PROFILE_UPLOAD_BUTTON,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            label: "PICTURE",
            value: !isEdit ? "" : editData?.[0]?.uploadImage,
            isUrl: !isEdit ? false : true,
            id: "addImageFromAddService",
          },
          select_provider: {
            key: "select_provider",
            id: "selectProviderFromAddService",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              style: {
                margin: "auto 0",
              },
            },
            label: "SELECT PARTNER",
            value: !isEdit
              ? this.state.provider
              : editData?.[0]?.selectedProvider?.[0],
            onSelectedCallback: this.categoriesOptions,
            options: this.props?.dropPartner?.data
              ? this.props?.dropPartner?.data
              : [],
            isLoading: this.props?.dropPartner?.loading,
            isMandatory: true,
            // isMulti: true,
            cleanUpKeys: [
              "select_category",
              "pre_assessment",
              "assessment",
              "service_delivery",
              "service_deliverables",
              "select_service_type",
            ],
          },
          select_category: {
            key: "select_category",
            id: "selectCategoryFromAddService",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              style: {
                margin: "auto 0",
              },
              required: true,
            },
            label: "SELECT SERVICE CATEGORY",
            value: !isEdit
              ? this.state.category
              : editData?.[0]?.selectedCategory,
            onSelectedCallback: this.getServiceTypeBasedOnCategory,
            options: this.props?.categoriesList?.data ?? [],
            isLoading: this.props?.categoriesList?.loading,
            isMandatory: true,
            // cleanUpKeys: ["select_provider"],
            cleanUpKeys: [
              "pre_assessment",
              "assessment",
              "service_deliverables",
              "select_service_type",
            ],
            isMulti: true,
          },
          service_name: {
            key: "service_name",
            id: "addServiceNameFromAddService",
            type: formComponentTypes.INPUT_BOX,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "SERVICE NAME",
            value: !isEdit ? "" : editData?.[0]?.resourceName,
            componentProps: {
              type: "text",
              required: true,
            },
            isMandatory: true,
          },
          /* frequency: {
            key: "frequency",
            id: "selectFrequencyFromAddService",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 6,
              md: 6,
              lg: 6,
              xl: 6,
            },
            componentProps: {
              required: true,
            },
            label: "SELECT FREQUENCY",
            value: isEdit ? editData?.[0]?.frequency : this.state.frequency,
            options: this.props?.frequency?.data
              ? this.props?.frequency?.data
              : [],
            // isMandatory: true,
          }, */
          group_type: {
            key: "group_type",
            id: "selectGroupTypesFromAddService",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              required: true,
            },
            label: "SELECT GROUP TYPE",
            value: !isEdit ? [] : editData?.[0]?.group_type,
            // onSelectedCallback: this.careProviderByCategory,
            options: this.props?.product_management_group_types ?? [],
            // isLoading: this.props?.categories?.loading,
            isMulti: false,
            isMandatory: false,
            // isDisabled: !this.state.assessment_required,
            // cleanUpKeys: ["select_provider"]
            // isDontShow: !this.state.assessment_required,
          },
          select_service_type: {
            key: "select_service_type",
            id: "selectServiceTypeFromAddService",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              required: true,
            },
            label: "SELECT SERVICE TYPE",
            value: !isEdit
              ? this.state.selectedServiceType
              : editData?.[0]?.selectedServiceType?.[0],
            // onSelectedCallback: this.careProviderByCategory,
            options: this.props?.dropServiceType?.data ?? [],
            // isLoading: this.props?.categories?.loading,
            isMandatory: true,
            // cleanUpKeys: ["select_provider"]
          },
          add_price: {
            key: {
              select_key: "currencyType",
              input_value_key: "price",
            },
            value: {
              // currencyType: !isEdit ? "" : editData?.[0]?.currency,
              currencyType: "MYR",
              price: !isEdit ? "" : parseFloat(editData?.[0]?.price).toFixed(2),
            },
            type: formComponentTypes.MOBILE_INPUT_BOX,
            componentProps: {
              type: "number",
              id: "addPriceFromAddService",
              meta: metaType.currency,
              overrideDecimalCheckForDiscount: true,
              helperFlagNeeded: true,
              helperFlagControllers: {
                label: "Is Price Editable",
                id: "switchPriceEditableFromAddService",
                value: this.state.price_editable,
                handleOnChange: (newValue) =>
                  this.handleCheckBox(
                    newValue.target.checked,
                    "price_editable"
                  ),
              },
              allScreenCustomSize: {
                xs: 4,
                sm: 5,
                md: 5,
                lg: 3,
                xl: 3,
              },
              required: true,
              disableDropdown: true, // disabled the Currency drop down temporarily
            },
            // customsize: 4,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            label: "ADD PRICE",
            options: options.currency,
            // options: [
            //   { label: "INR", value: "INR" },
            //   { label: "DOLLAR", value: "DOLLAR" },
            //   { label: "POUNDS", value: "POUNDS" }
            // ],
            isMandatory: true,
            isDecimalCheckNeeded: true,
          },
          // add_price: {
          //   key: "add_price",
          //   type: formComponentTypes.INPUT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 3,
          //     md: 3,
          //     lg: 3,
          //     xl: 3
          //   },
          //   label: "ADD PRICE (RM)",
          //   componentProps: {
          //     type: "number",
          //     overrideDecimalCheckForDiscount: true,
          //     helperFlagNeeded: true,
          //     helperFlagControllers: {
          //       label: "Is Price Editable",
          //       value: this.state.price_editable,
          //       handleOnChange: (newValue) => this.handleCheckBox(newValue.target.checked, "price_editable"),
          //     }
          //   },
          //   value: !isEdit
          //     ? ""
          //     : parseFloat(editData?.data?.[0]?.resourceMeta?.price?.split(" ")[1]).toFixed(2),
          //   isMandatory: true,
          //   isDecimalCheckNeeded: true
          // },
          product_validity: {
            type: formComponentTypes.PRODUCT_VALIDITY,
            key: {
              productValidity: "productValidity",
              startDate: "effectiveFrom",
              endDate: "endsOn",
            },
            id: {
              validity_id: "selectValidityFromAddService",
              effectiveFrom_id: "addEffectiveFromAddService",
              end_id: "addEndFromAddService",
            },
            value: {
              productValidity: isEdit
                ? editData?.[0]?.validityPeriod
                  ? editData?.[0]?.validityPeriod
                  : { label: "Custom", value: "custom" }
                : {},
              effectiveFrom: isEdit ? editData?.[0]?.effectiveFrom : null,
              endsOn: isEdit ? editData?.[0]?.effectiveTo : null,
            },
            breakPoints: {
              xs: 12,
              sm: 8,
              md: 8,
              lg: 8,
              xl: 8,
            },
            isMandatory: true,
            componentProps: {
              isEdit: isEdit,
              dropdownProps: {
                options:
                  validityOptions.length > 0
                    ? validityOptions?.map((x) => ({
                        label: x.label,
                        value: x.days,
                        id: x.value,
                      }))
                    : [],
                // options: [
                //   { label: "Today", value: "1" },
                //   { label: "Week", value: "7" },
                //   { label: "Month", value: "30" },
                // ],
                label: "VALIDITY PERIOD",
                value: isEdit
                  ? editData?.[0]?.validityPeriod
                    ? editData?.[0]?.validityPeriod
                    : []
                  : [],
                callback: () => this.handleValidityOptions("selected"),
              },
              startDateProps: {
                label: "EFFECTING FROM",

                // value: isEdit
                //   ? new Date(moment().subtract(2, "days"))
                //   : new Date(),
                value: isEdit ? editData?.[0]?.effectiveFrom : null,
                effectiveFrom_id: "addEffectiveFromAddService",
                // min: isEdit ? editData?.data?.[0]?.resourceMeta : null
                // min: () => this.calculateStartDateMin()
                // value: () => this.getDateValue("startDate"),
                // callback: (value) => this.handleDateChange(value, "startDate"),
                // max: new Date(),
                // disablePast: false,
                // disableFuture: false,
                // inputFormat: "dd/MM/yyyy"
              },
              endDateProps: {
                label: "ENDS ON",
                // value: isEdit ? new Date(moment().add(4, "days")) : new Date(),
                value: isEdit ? editData?.[0]?.effectiveTo : null,
                end_id: "addEndFromAddService",
                // min: isEdit ? editData?.data?.[0]?.resourceMeta : null
                // value: () => this.getDateValue("endDate"),
                // callback: (value) => this.handleDateChange(value, "endDate"),
                // max: isEdit ? editData?.data?.[0]?.resourceMeta : new Date(),
                // disablePast: false,
                // disableFuture: false,
                // inputFormat: "dd/MM/yyyy"
              },
            },
          },
          duration: {
            key: "duration",
            id: "addDurationFromAddService",
            type: formComponentTypes.DURATION_PICKER,
            label: "DURATION",
            value: !isEdit ? null : editData?.[0]?.duration,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            isMandatory: false,
          },
          mode_of_activity: {
            key: "mode_of_activity",
            id: "selectModeOfActivityFromAddService",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              required: true,
            },
            label: "MODE OF ACTIVITY",
            value: !isEdit ? [] : editData?.[0]?.modeOfActivity,
            // onSelectedCallback: this.careProviderByCategory,
            isMulti: true,
            options: options.activity_mode,
            // isLoading: this.props?.categories?.loading,
            isMandatory: true,
          },
          service_resource_type: {
            key: "service_resource_type",
            id: "selectResourceTypeFromAddService",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 8,
              lg: 8,
              xl: 8,
            },
            componentProps: {
              required: true,
            },
            label: "SELECT RESOURCE TYPE",
            value: !isEdit ? [] : editData?.[0]?.resourceTypeCategory,
            // onSelectedCallback: this.careProviderByCategory,
            options: this.props?.dropResourceTypes?.data
              ? this.props?.dropResourceTypes?.data
                  ?.map((res) => [...res?.resources])
                  ?.flat()
              : [],
            // isLoading: this.props?.categories?.loading,
            isMulti: true,
            isMandatory: true,
            // isDisabled: !this.state.assessment_required,
            // cleanUpKeys: ["select_provider"]
            // isDontShow: !this.state.assessment_required,
          },
          taxType: {
            key: "taxType",
            type: formComponentTypes.SELECT_BOX,
            id: `selectAdditionalTaxTypeFrom${
              this.props?.match?.params?.id ? this.props?.match?.params?.id : ""
            }`,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
            },
            label: "Tax Type",
            value: !isEdit ? [] : editData?.[0]?.taxType,
            onSelectedCallback: this.handleTaxTypeChange,
            // options: this.props.emailType?.data
            //   ? selectedList.length > 0 ? this.props.emailType?.data.filter(val => selectedList.indexOf(val.value) === -1)
            //     : this.props.emailType?.data
            //   : [],
            options: taxTypeOptions?.map((x) => ({ ...x, label: x?.name })),
            isMandatory: true,
            dependentKeys: ["tax"],
            dependentKeySelector: "taxPercentage",
          },
          tax: {
            key: "tax",
            id: "addServiceTaxFromAddService",
            type: formComponentTypes.INPUT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 2,
            },
            label: "TAX",
            value: !isEdit ? null : editData?.[0]?.tax,
            componentProps: {
              type: "number",
              required: true,
              maxNumberInputLimit: 100,
              endAdornmentText: "%",
            },
            isMandatory: true,
            disabled:
              this.state.taxType?.type === TAXTYPE.VARIABLE ? false : true,
          },
          // duration: {
          //   key: "duration",
          //   type: formComponentTypes.DATE_TIME_PICKER,
          //   // isDateTime: true,
          //   inputFormat: "hh:mm",
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4,
          //   },
          //   // onChangeCallBack: this.handleCalBackDate,
          //   label: "DURATION",
          //   value: !isEdit
          //     ? null
          //     : this.decodeDuration(
          //         editData?.[0]?.duration
          //       ),
          //   isMandatory: false,
          //   // cleanUpKeys: ["to_timestamp"],
          // },
          // duration: {
          //   key: {
          //     hour: "hour",
          //     minute: "minute"
          //   },
          //   value: {
          //     hour: isEdit? 3 : "",
          //     minute: isEdit ? 7 : ""
          //   },
          //   type: formComponentTypes.DURATION,
          //   // handleOnAccept: ,
          //   // isDateTime: true,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4
          //   },
          //   label: "DURATION",
          //   // value: !isEdit ? null : editData?.data?.[0]?.resourceMeta.from,
          //   // onChangeCallBack: onDateChange,
          //   isMandatory: true
          //   // isMulti: true,
          // },
          /* group_type: {
            key: "group_type",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              required: true,
            },
            label: "SELECT GROUP TYPE",
            value: !isEdit ? [] : editData?.[0]?.group_type,
            // onSelectedCallback: this.careProviderByCategory,
            options: this.props?.product_management_group_types ?? [],
            // isLoading: this.props?.categories?.loading,
            isMulti: false,
            isMandatory: true,
            // isDisabled: !this.state.assessment_required,
            // cleanUpKeys: ["select_provider"]
            // isDontShow: !this.state.assessment_required,
          }, */
          // allowToBundle: {
          //   key: "allowToBundle",
          //   type: formComponentTypes.SWITCH_COMPONENT,
          //   componentProps: {
          //     style: {
          //       alignSelf: "center"
          //     },
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4,
          //   },
          //   withBoldHeading: true,
          //   onChangeCallBack: this.handleCheckBox,

          //   // value: !isEdit ? false : editData?.data?.[0]?.resourceMeta?.price_editable,
          //   label: "Allow To Bundle",
          //   // isMandatory: true,
          // },
          // pre_assessment_required: {
          //   key: "pre_assessment_required",
          //   type: formComponentTypes.SWITCHBOXDROPDOWN,

          //   isMandatory:true,
          //   dropdown1Label: "DrohgjhgpDown1",
          //   dropdown2Label: "DrojhgjhgpDown2",
          //   dropdown1Options: [],
          //   dropdown2Options: [],
          //   dropdown1Value: [],
          //   dropdown2Value: [],
          //   label: "Pre-Assessment",
          //   value:{dropdown1Value: [],
          //     isChecked: false,
          //     dropdown2Value: [],},

          //   breakPoints: {
          //     xs: 12,
          //     sm: 12,
          //     md: 12,
          //     lg: 12,
          //     xl: 12,
          //   },
          // },
          // pre_assessment_required: {
          //   key: "pre_assessment_required",
          //   type: formComponentTypes.SWITCH_COMPONENT,
          //   componentProps: {
          //     style: {
          //       alignSelf: "center",
          //     },
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: this.state.pre_assessment_required ? 4 : 12,
          //     md: this.state.pre_assessment_required ? 4 : 12,
          //     lg: this.state.pre_assessment_required ? 4 : 12,
          //     xl: this.state.pre_assessment_required ? 4 : 12,
          //   },
          //   withBoldHeading: true,
          //   onChangeCallBack: this.handleCheckBox,

          //   // value: !isEdit ? false : editData?.data?.[0]?.resourceMeta?.price_editable,
          //   label: "Pre-Assessment Required",
          //   // isMandatory: true,
          // },
          //  ...(this.addAssementSection()[0]),
          // pre_assessment: {
          //   key: "pre_assessment",
          //   type: formComponentTypes.SELECT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4,
          //   },
          //   label: "SELECT PRE-ASSESSMENT",
          //   // value: !isEdit ? this.canIHaveCategory() : editData?.data?.[0]?.resourceMeta?.category?.[0],
          //   // onSelectedCallback: this.careProviderByCategory,
          //   options: [
          //     { label: "pre_assessment1", value: 1 },
          //     { label: "pre_assessment2", value: 2 },
          //   ],
          //   // isLoading: this.props?.categories?.loading,
          //   isMandatory: true,
          //   isDisabled: !this.state.pre_assessment_required,
          //   isDontShow: !this.state.pre_assessment_required,
          //   // cleanUpKeys: ["select_provider"]
          // },

          // pre_assessment_resource_type: {
          //   key: "pre_assessment_resource_type",
          //   type: formComponentTypes.SWITCHBOXDROPDOWN,

          //   isMandatory:true,
          //   dropdown1Label: "DrohgjhgpDown1",
          //   dropdown2Label: "SELECT RESOURCE TYPE",
          //   dropdown1Options: [],
          //   dropdown2Options: [],
          //   dropdown1Value: [],
          //   dropdown2Value: [],
          //   label: "Pre-Assessment Required",
          //   value:{dropdown1Value: [],
          //     isChecked: false,
          //     dropdown2Value: [],},

          //   breakPoints: {
          //     xs: 12,
          //     sm: 12,
          //     md: 12,
          //     lg: 12,
          //     xl: 12,
          //   },
          // },

          // pre_assessment_resource_type: {
          //   key: "pre_assessment_resource_type",
          //   type: formComponentTypes.SELECT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4,
          //   },
          //   label: "SELECT RESOURCE TYPE",
          //   // value: !isEdit ? this.canIHaveCategory() : editData?.data?.[0]?.resourceMeta?.category?.[0],
          //   // onSelectedCallback: this.careProviderByCategory,
          //   options: [
          //     { label: "pre_resourceType1", value: 1 },
          //     { label: "pre_resourceType2", value: 2 },
          //   ],
          //   // isLoading: this.props?.categories?.loading,
          //   isMandatory: true,
          //   isDisabled: !this.state.pre_assessment_required,
          //   isDontShow: !this.state.pre_assessment_required,
          //   // cleanUpKeys: ["select_provider"]
          // },
          // assessment_required: {
          //   key: "assessment_required",
          //   type: formComponentTypes.SWITCH_COMPONENT,
          //   componentProps: {
          //     style: {
          //       alignSelf: "center",
          //     },
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: this.state.assessment_required ? 4 : 12,
          //     md: this.state.assessment_required ? 4 : 12,
          //     lg: this.state.assessment_required ? 4 : 12,
          //     xl: this.state.assessment_required ? 4 : 12,
          //   },
          //   withBoldHeading: true,
          //   onChangeCallBack: this.handleCheckBox,

          //   // value: !isEdit ? false : editData?.data?.[0]?.resourceMeta?.price_editable,
          //   label: "Assessment Required",
          //   // isMandatory: true,
          // },
          // assessment: {
          //   key: "assessment",
          //   type: formComponentTypes.SELECT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4,
          //   },
          //   label: "SELECT ASSESSMENT",
          //   // value: !isEdit ? this.canIHaveCategory() : editData?.data?.[0]?.resourceMeta?.category?.[0],
          //   // onSelectedCallback: this.careProviderByCategory,
          //   options: [
          //     { label: "assessment1", value: 1 },
          //     { label: "assessment2", value: 2 },
          //   ],
          //   // isLoading: this.props?.categories?.loading,
          //   isMulti: true,
          //   isMandatory: true,
          //   isDisabled: !this.state.assessment_required,
          //   // cleanUpKeys: ["select_provider"]
          //   isDontShow: !this.state.assessment_required,
          // },
          // assessment_resource_type: {
          //   key: "assessment_resource_type",
          //   type: formComponentTypes.SELECT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4,
          //   },
          //   label: "SELECT RESOURCE TYPE",
          //   // value: !isEdit ? this.canIHaveCategory() : editData?.data?.[0]?.resourceMeta?.category?.[0],
          //   // onSelectedCallback: this.careProviderByCategory,
          //   options: [
          //     { label: "resourceType1", value: 1 },
          //     { label: "resourceType2", value: 2 },
          //   ],
          //   // isLoading: this.props?.categories?.loading,
          //   isMulti: true,
          //   isMandatory: true,
          //   isDisabled: !this.state.assessment_required,
          //   // cleanUpKeys: ["select_provider"]
          //   isDontShow: !this.state.assessment_required,
          // },
          // service_deliverable_required: {
          //   key: "service_deliverable_required",
          //   type: formComponentTypes.SWITCH_COMPONENT,
          //   componentProps: {
          //     style: {
          //       alignSelf: "center",
          //     },
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: this.state.service_deliverable_required ? 4 : 12,
          //     md: this.state.service_deliverable_required ? 4 : 12,
          //     lg: this.state.service_deliverable_required ? 4 : 12,
          //     xl: this.state.service_deliverable_required ? 4 : 12,
          //   },
          //   withBoldHeading: true,
          //   onChangeCallBack: this.handleCheckBox,

          //   // value: !isEdit ? false : editData?.data?.[0]?.resourceMeta?.price_editable,
          //   label: "Service Deliverable Required",
          //   // isMandatory: true,
          // },
          // service_deliverables: {
          //   key: "service_deliverables",
          //   type: formComponentTypes.SELECT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4,
          //   },
          //   label: "SERVICE DELIVERABLES",
          //   // value: !isEdit ? this.canIHaveCategory() : editData?.data?.[0]?.resourceMeta?.category?.[0],
          //   // onSelectedCallback: this.careProviderByCategory,
          //   options: [
          //     { label: "notes", value: 1 },
          //     { label: "reports", value: 2 },
          //     { label: "upload", value: 3 },
          //     { label: "forms", value: 4 },
          //   ],
          //   // isLoading: this.props?.categories?.loading,
          //   isMulti: true,
          //   isMandatory: true,
          //   isDisabled: !this.state.service_deliverable_required,
          //   isDontShow: !this.state.service_deliverable_required,
          //   // cleanUpKeys: ["select_provider"]
          // },
          // service_deliverable_resource_type: {
          //   key: "service_deliverable_resource_type",
          //   type: formComponentTypes.SELECT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4,
          //   },
          //   label: "SELECT RESOURCE TYPE",
          //   // value: !isEdit ? this.canIHaveCategory() : editData?.data?.[0]?.resourceMeta?.category?.[0],
          //   // onSelectedCallback: this.careProviderByCategory,
          //   options: [
          //     { label: "resourceType1", value: 1 },
          //     { label: "resourceType2", value: 2 },
          //   ],
          //   // isLoading: this.props?.categories?.loading,
          //   isMulti: true,
          //   isMandatory: true,
          //   isDisabled: !this.state.service_deliverable_required,
          //   // cleanUpKeys: ["select_provider"]
          //   isDontShow: !this.state.service_deliverable_required,
          // },
        },
      },
      section_2: {
        label: "",
        // ...this.addAssementSection(),
        fields: {
          /* pre_assessment: {
            key: "pre_assessment",
            id:{
              check_id: "checkPreAssessmentFromAddService",
              dropdown1Key: "selectPreAssementFromAddService",
              dropdown2Key: "selectPreAssessmentResourceTypeFromAddService"
            },
            type: formComponentTypes.SWITCHBOXDROPDOWN,
            tempeleteTypeId: formTempeletesTypeId.pre_assignment,
            dropdown1Label: "SELECT PRE-ASSESSMENT",
            // dropdown2Label: "SELECT RESOURCE TYPE",
            dropdown1Options: this.state.preAssignmentOptions,
            // dropdown2Options: this.state.preAssignmentOptionsResourceType,
            dropdown1Value: [],
            // dropdown2Value: [],
            label: "Pre-Assessment",
            value: {
              dropdown1Value: !isEdit
                ? []
                : editData?.[0]?.preAssessment?.dropdown1Value,
              // dropdown2Value: !isEdit
              //   ? []
              //   : editData?.[0]?.preAssessment?.dropdown2Value,
              isChecked: !isEdit
                ? false
                : editData?.[0]?.preAssessment?.isChecked,
            },
            onChangeCallBack: this.handlePreassement,
            onChangeCallBackDropDown: this.handledropDownOnChangePreassement,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            componentProps: {
              isMulti: true
            }
          }, */
          assessment: {
            key: "assessment",
            type: formComponentTypes.SWITCHBOXDROPDOWN,
            id: {
              check_id: "checkAssessmentFromAddService",
              dropdown1Key: "selectAssementFromAddService",
              dropdown2Key: "selectAssessmentResourceTypeFromAddService",
            },
            tempeleteTypeId: formTempeletesTypeId.assignment,
            dropdown1Label: "SELECT ASSESSMENT",
            // dropdown2Label: "SELECT RESOURCE TYPE",
            dropdown1Options: this.state.assessmentOptions,
            // dropdown2Options: this.state.assessmentOptionsResourceType,
            dropdown1Value: [],
            // dropdown2Value: [],
            label: "Assessment",
            value: {
              dropdown1Value: !isEdit
                ? []
                : editData?.[0]?.assessment?.dropdown1Value,
              // dropdown2Value: !isEdit
              //   ? []
              //   : editData?.[0]?.assessment?.dropdown2Value,
              isChecked: !isEdit ? false : editData?.[0]?.assessment?.isChecked,
            },
            onChangeCallBack: this.handlePreassement,
            onChangeCallBackDropDown: this.handledropDownOnChangePreassement,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            componentProps: {
              isMulti: true,
              hideMarginTop: !this.state.assessment,
            },
          },
          // service_deliverables: {
          //   key: "service_deliverables",
          //   type: formComponentTypes.SWITCHBOXDROPDOWN,
          //   tempeleteTypeId: formTempeletesTypeId.service_deliverables,
          //   dropdown1Label: "SELECT SERVICE DELIVERY REPORT",
          //   dropdown2Label: "SELECT RESOURCE TYPE",
          //   dropdown1Options: this.state.serviceDeliverablesOptions,
          //   dropdown2Options: this.state.serviceDeliverablesOptionsResourceType,
          //   dropdown1Value: [],
          //   dropdown2Value: [],
          //   label: "Service Delivery Report",
          //   value: {
          //     dropdown1Value: !isEdit ? [] : editData?.[0]?.serviceDeliverables?.dropdown1Value,
          //     dropdown2Value: !isEdit ? [] : editData?.[0]?.serviceDeliverables?.dropdown2Value,
          //     isChecked: !isEdit ? false : editData?.[0]?.serviceDeliverables?.isChecked,
          //   },
          //   onChangeCallBack: this.handlePreassement,
          //   onChangeCallBackDropDown: this.handledropDownOnChangePreassement,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 12,
          //     md: 12,
          //     lg: 12,
          //     xl: 12,
          //   },
          // },
          service_delivery: {
            key: "service_delivery",
            id: {
              check_id: "checkServiceDeliveryFromAddService",
              dropdown1Key: "selectServiceDeliveryFromAddService",
              dropdown2Key: "selectServiceDeliveryResourceTypeFromAddService",
            },
            type: formComponentTypes.SWITCHBOXDROPDOWN,
            tempeleteTypeId: formTempeletesTypeId.service_delivery,
            dropdown1Label: "SELECT SERVICE DELIVERY",
            // dropdown2Label: "SELECT RESOURCE TYPE",
            dropdown1Options: this.state.serviceDeliveryOptions,
            // dropdown2Options: this.state.serviceDeliveryOptionsResourceType,
            dropdown1Value: [],
            // dropdown2Value: [],
            label: "Service Delivery",
            value: {
              dropdown1Value: !isEdit
                ? []
                : editData?.[0]?.service_delivery?.dropdown1Value,
              // dropdown2Value: !isEdit
              //   ? []
              //   : editData?.[0]?.service_delivery?.dropdown2Value,
              isChecked: !isEdit
                ? false
                : editData?.[0]?.service_delivery?.isChecked,
            },
            onChangeCallBack: this.handlePreassement,
            onChangeCallBackDropDown: this.handledropDownOnChangePreassement,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            componentProps: {
              isMulti: true,
              hideMarginTop: !this.state.serviceDelivery,
            },
          },
        },
      },
      section_3: {
        label: "",
        fields: {
          promotional_content: {
            key: "promotional_content",
            type: formComponentTypes.FILEUPLOADER,
            id: "uploadPromotionalContentFromAddService",
            breakPoints: {
              xs: 12,
              sm: 6,
              md: 3,
              lg: 3,
              xl: 3,
            },
            label: "PROMOTIONAL CONTENT",
            value: !isEdit
              ? this.state.promotional_content
              : editData?.[0]?.promotionalContent,
            handleFileUpload: this.handleFileUpload,
            handleFileRemove: this.handleFileRemove,
            isMandatory: false,
            componentProps: {
              fileExtension: !isEdit
                ? null
                : editData?.[0]?.promotionalFileType,
            },
          },
          isBookable: {
            key: "isBookable",
            type: formComponentTypes.SWITCH_COMPONENT,
            id: "switchIsBookableFromAddService",
            componentProps: {
              style: {
                alignSelf: "center",
              },
              fixMargin: true,
              marginLeft: 28,
            },
            breakPoints: {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            withBoldHeading: true,
            onChangeCallBack: this.handleCheckBox,
            value: isEdit ? editData?.[0]?.isBookable : this.state.isBookable, //TODO: While edit,  add isBookable value from edit data to state in componentDidMount()
            label: "Is Bookable",
            // isMandatory: true,
          },
          allowToBundle: {
            key: "allowToBundle",
            type: formComponentTypes.SWITCH_COMPONENT,
            id: "switchAllowToBundleFromAddService",
            componentProps: {
              style: {
                alignSelf: "center",
              },
              fixMargin: true,
            },
            breakPoints: {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            withBoldHeading: true,
            onChangeCallBack: this.handleCheckBox,
            value: isEdit
              ? editData?.[0]?.allowToBundle
              : this.state.allowToBundle, //TODO: While edit,  add allowToBundle value from edit data to state in componentDidMount()
            label: "Allow to bundle",
            // isMandatory: true,
          },
          allowToShow: {
            key: "allowToShow",
            type: formComponentTypes.SWITCH_COMPONENT,
            id: "switchAllowToShowFromAddService",
            componentProps: {
              style: {
                alignSelf: "center",
              },
              fixMargin: true,
            },
            breakPoints: {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            withBoldHeading: true,
            onChangeCallBack: this.handleCheckBox,
            value: isEdit ? editData?.[0]?.allowToShow : this.state.allowToShow, //TODO: While edit,  add allowToShow value from edit data to state in componentDidMount()
            label: "Allow to show",
            // isMandatory: true,
          },
          description: {
            key: "description",
            id: "addDescripptionFromAddService",
            type: formComponentTypes.RICHTEXTEDITOR,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "DESCRIPTION",
            value: !isEdit ? "" : editData?.[0]?.description,
            isMandatory: true,
          },
          termsAndConditions: {
            key: "termsAndConditions",
            type: formComponentTypes.BUTTON_WITH_INPUT_FIELD,
            id: {
              text_id: "addTermsConditionsFromAddService",
              button_id: "addTermsConditionsButtonFromAddService",
            },
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            buttonLabel: "+ Add",
            label: "TERMS & CONDITIONS",
            value: !isEdit ? "" : editData?.[0]?.termsAndConditions,
            // editData?.details?.terms_conditions.split(',') // don't remove
            // value: this.state.terms_conditions,
            // handleRemove: handleRemove,
          },
        },
      },
    };

    if (user_roles.provider === auth_data.user_role) {
      delete components.section_1.fields.select_provider;
    }

    let { loading } = this.props?.addService;

    // let { loading: updateLoading } = this.props?.updateServiceInfo;

    return (
      <div style={{ margin: 25 }}>
        {isEdit ? (
          <>
            {editLoading && (
              <LoadingSection message={"Loading service details..."} />
            )}
            {!editLoading && editError && (
              <InternatlServerError
                message={"Unable to fetch Service details"}
                onRetry={this.getEssentials}
              />
            )}
            {!editLoading && !editError && !editData && (
              <NotFoundSection message={"404 - Service Detail Not Found."} />
            )}
            {!editLoading && !editError && editData && (
              <AddEditProductManagement
                mandatoryExceptions={[
                  {
                    key: "isBookable",
                    dependents: [
                      { ...components?.section_1?.fields?.duration },
                    ],
                  },
                ]}
                components={components}
                onSaveButtonClicked={this.onSaveButtonClicked}
                loading={loading || this.state.localServiceLoading}
                isUrl={true}
                saveButtonLabel={
                  loading || this.state.localServiceLoading
                    ? "Updating..."
                    : "Update"
                }
                saveButtonId={"saveButtonFromAddService"}
                cancelButtonId={"cancelButtonFromAddService"}
                isResetState={this.state.isResetState}
              />
            )}
          </>
        ) : (
          <AddEditProductManagement
            mandatoryExceptions={[
              {
                key: "isBookable",
                dependents: [{ ...components?.section_1?.fields?.duration }],
              },
            ]}
            components={components}
            onSaveButtonClicked={this.onSaveButtonClicked}
            loading={loading || this.state.localServiceLoading}
            saveButtonLabel={
              loading || this.state.localServiceLoading ? "Saving..." : "Save"
            }
            isResetState={this.state.isResetState}
            saveButtonId={"saveButtonFromAddService"}
            cancelButtonId={"cancelButtonFromAddService"}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state?.masterSet?.categories,
    providerBasedOnCategory: state?.careProvider?.providerBasedOnCategory,
    addService: state?.serviceState?.addService,
    serviceInfo: state?.serviceState?.serviceInfo,
    updateServiceInfo: state?.serviceState?.updateServiceInfo,
    auth_data: state?.authState?.loginAuth?.data ?? {},
    getValidity: state?.masterSet?.validity ?? null,
    product_management_group_types:
      state?.masterSet?.product_management_group_types.data ?? null,
    dropPartner: state?.careProvider?.dropPartner,
    dropServiceType: state?.careProvider?.dropServiceType,
    dropResourceTypes: state?.careProvider?.dropResourceTypes,
    collectAllTemplateTypes: state?.serviceState?.collectAllTemplateTypes,
    serviceTemplateType: state?.serviceState?.collectServiceTemplate,
    frequency: state?.masterModule?.getfreq ?? {
      data: [],
      loading: false,
      error: false,
    },
    categoriesList: state?.careProvider?.catProvider,
    // categoriesList: state?.masterSet?.categories,
    getPartnerActiveStatusBySupervisor:
      state?.resourceReducer?.getPartnerActiveStatusBySupervisor,
    taxTypeMaster: state?.masterTax?.get_tax_integration,
  };
};

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(AddEditService)));

// GET_AVAILABLE_TEMPLATE_TYPES - TO Get all the Templates
// GET_SERVICE_TEMPLATES - To get all the service template type (individual - Pre-assessment/Assessment/Service Delivery Report)
// GET_RESOURCE_TYPES
