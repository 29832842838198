import React from "react";
import AddEditUserManagement from "../../../../components/addEditForm";
import { components } from "./component";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  alertProps,
  bmiCalculation,
  options,
  bmiCalculation as otherBmiCalculation,
  OTHER_ROLES,
} from "../../../../utils";
import { SOURCE_TYPE } from "../../../../utils/constants";
import { v4 as uuidv4 } from "uuid";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { routes } from "../../../../router/routes";
import {
  InternatlServerError,
  LoadingSection,
  NotFoundSection,
} from "../../../../components";
import AddressComponent from "../../../../components/SelectComponentWithSubComponent/addressComponent";
import IdTypeComponent from "../../../../components/SelectComponentWithSubComponent/idTypeComponent";
import PhoneComponent from "../../../../components/SelectComponentWithSubComponent/phoneComponent";
class AddEditEnquiry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      isContactAndCallerSame: false,
      isShowContactDetails: true,
      addedContactData: {},
      isSeenADoctor: true,
      isOtherActivitiesChecked: false,
      bmi: null,
      height: null,
      weight: null,
      passport_number: "",
      ic_number: "",
      contactCallerError: false,
      isSelectClient: false,
      address: [],
      id: [],
      phone: [],
      address_caller: [],
      id_caller: [],
      phone_caller: [],
      error: {},
      isdisableCantact: false,
      isdisableCaller: false,
      selectedExitsClients: null,
    };
  }

  componentDidMount() {
    this.getEssentials();
    if (this.props.isEdit) {
      const id = this.props?.match?.params?.id;
      this.props.GET_ENQUIRY_INFO({ enquiry_id: id });
    }

    if (
      this.props?.auth_data?.data?.other_roles?.includes(
        OTHER_ROLES.crossBundling
      )
    ) {
      this.props.GET_ALL_CLIENTS({
        partnerId: this.props?.auth_data?.data?.user_id,
      });
    } else {
      this.props.GET_ALL_CLIENTS({});
    }
  }

  getEssentials = async () => {
    this.props.GET_CATEGORIES({});
    this.props.GET_ENQUIRY_TYPE({});
    this.props.GET_MASTER_ENQUIRY_SOURCE_OF_REFERRAL({});
    this.props.GET_MASTER_LEAD_TYPES({});
    // this.props.GET_ENQUIRY_RELATONSHIP({})
    this.props.SOURCE_OF_REFERRAL({});
    this.props.ASSIGNED_TO({});
    this.props.GET_SAL({});
    this.props.GET_LANG({});
    this.props.MASTER_PHONE_CODE({});
    // this.props.CALL_RECEIVED_BY({})
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Add Contact
    const {
      enquiry_add_contact,
      enquiry_add_enquiry,
      updateEnquiry,
      enquiryDetails,
    } = nextProps;

    // console.log(nextProps)
    const oldadd_contact = this.props.enquiry_add_contact;
    const oldenquiry_add_enquiry = this.props.enquiry_add_enquiry;
    const oldupdateEnquiry = this.props.updateEnquiry;
    const oldenquiryDetails = this.props.enquiryDetails;

    if (oldenquiryDetails?.loading !== enquiryDetails?.loading) {
      if (!enquiryDetails.loading && !enquiryDetails.error) {
        if (enquiryDetails?.data) {
          // console.log(enquiryDetails?.data?.caller_information?.gender)
          this.setState({
            bmi: otherBmiCalculation(
              enquiryDetails?.data?.height,
              enquiryDetails?.data?.weight
            ),
            gender: enquiryDetails?.data?.caller_information?.gender
              ? {
                  value: enquiryDetails?.data?.caller_information?.gender
                    .charAt(0)
                    .toLowerCase(),
                  label:
                    enquiryDetails?.data?.caller_information?.gender
                      .charAt(0)
                      .toUpperCase() +
                    enquiryDetails?.data?.caller_information?.gender.slice(1),
                }
              : "",
            height: enquiryDetails?.data?.height,
            width: enquiryDetails?.data?.width,
            bloodGroup: enquiryDetails?.data?.bloodGroup,
            address: enquiryDetails?.data?.caller_information?.address,
            id: enquiryDetails?.data?.caller_information?.idTypes,
            phone: enquiryDetails?.data?.caller_information?.otherPhoneNumbers,
            isOtherActivitiesChecked:
              enquiryDetails?.data?.activities &&
              enquiryDetails?.data?.activities
                .split(",")
                .filter((_) => _ === "other").length > 0,
            isSeenADoctor:
              enquiryDetails?.data?.seenDoctor === "no" ? true : false,
            isContactAndCallerSame: enquiryDetails?.data?.contact_or_caller
              ? enquiryDetails?.data?.contact_or_caller === "same"
                ? true
                : false
              : false,
          });

          console.log(this.state.gender);
          if (enquiryDetails?.data?.contact_information) {
            // console.log(enquiryDetails?.data?.caller_information)

            this.setState({
              isContactAndCallerSame: false,
              address_caller:
                enquiryDetails?.data?.contact_information?.address,
              id_caller:
                enquiryDetails?.data?.contact_information?.idTypes?.map((x) => {
                  return { ...x, id_type: x.id_type };
                }),
              phone_caller:
                enquiryDetails?.data?.contact_information?.otherPhoneNumbers,
              isShowContactDetails: false,
              addedContactData: {
                contactId: enquiryDetails?.data?.contact_information?.contactId,
                salutation:
                  enquiryDetails?.data?.contact_information?.salutation,
                caller_name: enquiryDetails?.data?.contact_information?.name,
                date_of_birth:
                  enquiryDetails?.data?.contact_information?.date_of_birth,
                gender: enquiryDetails?.data?.contact_information?.gender
                  ? {
                      value: enquiryDetails?.data?.contact_information?.gender,
                      label:
                        enquiryDetails?.data?.contact_information?.gender
                          .charAt(0)
                          .toUpperCase() +
                        enquiryDetails?.data?.contact_information?.gender.slice(
                          1
                        ),
                    }
                  : "",
                mobile_code:
                  enquiryDetails?.data?.contact_information?.mobNo?.split(
                    " "
                  )[0],
                mobilenumber:
                  enquiryDetails?.data?.contact_information?.mobNo?.split(
                    " "
                  )[1],
                email: enquiryDetails?.data?.contact_information?.email,
                // permanentAddress:
                //   enquiryDetails?.data?.contact_information?.address,
                addressLine1:
                  enquiryDetails?.data?.contact_information?.addressLine1,
                addressLine2:
                  enquiryDetails?.data?.contact_information?.addressLine2,
                area: enquiryDetails?.data?.contact_information?.area,
                state: enquiryDetails?.data?.contact_information?.state,
                country: enquiryDetails?.data?.contact_information?.country,
                postalCode:
                  enquiryDetails?.data?.contact_information?.postalCode,
                occupation:
                  enquiryDetails?.data?.contact_information?.occupation,
                // occupation: enquiryDetails?.data?.contact_information?.occupation,
                // current_location:
                //   enquiryDetails?.data?.contact_information?.current_location,
                currentAddressLine1:
                  enquiryDetails?.data?.contact_information
                    ?.currentAddressLine1,
                currentAddressLine2:
                  enquiryDetails?.data?.contact_information
                    ?.currentAddressLine2,
                currentArea:
                  enquiryDetails?.data?.contact_information?.currentArea,
                currentState:
                  enquiryDetails?.data?.contact_information?.currentState,
                currentCountry:
                  enquiryDetails?.data?.contact_information?.currentCountry,
                currentPostalCode:
                  enquiryDetails?.data?.contact_information?.currentPostalCode,
                preferred_language:
                  enquiryDetails?.data?.contact_information?.preferredLanguage,
                id_caller:
                  enquiryDetails?.data?.contact_information?.idTypes?.map(
                    (x) => {
                      return { ...x, id_type: x.id_type };
                    }
                  ),
                phone_caller:
                  enquiryDetails?.data?.contact_information?.otherPhoneNumbers,
              },
              height: enquiryDetails?.data?.height,
              weight: enquiryDetails?.data?.weight,
              bmi: bmiCalculation(
                enquiryDetails?.data?.height,
                enquiryDetails?.data?.weight
              ),
            });
            console.log(this.state.addedContactData.gender);
          }
        }
      }
    }

    // Checking for Add Contact
    if (oldadd_contact?.loading !== enquiry_add_contact?.loading) {
      if (!enquiry_add_contact.loading && !enquiry_add_contact.error) {
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          open: false,
          component: <></>,
        });
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Contact Add successfully!",
          severity: alertProps.severity.success,
        });
        this.setState({
          isShowContactDetails: false,
          addedContactData: enquiry_add_contact?.data,
        });

        // this.props.history.push(routes.manage_clients);
      } else if (enquiry_add_contact.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Add Contact.",
          severity: alertProps.severity.error,
        });
      }
    }

    // Checking for Adding Enquiry
    if (oldenquiry_add_enquiry?.loading !== enquiry_add_enquiry?.loading) {
      if (!enquiry_add_enquiry.loading && !enquiry_add_enquiry.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Enquiry Add successfully!",
          severity: alertProps.severity.success,
        });

        this.props.history.push(routes.enquiry_list);
      } else if (enquiry_add_enquiry.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Add Enquiry.",
          severity: alertProps.severity.error,
        });
      }
    }

    // Checking for Update Enquiry
    if (oldupdateEnquiry?.loading !== updateEnquiry?.loading) {
      if (!updateEnquiry.loading && !updateEnquiry.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Enquiry Updated successfully!",
          severity: alertProps.severity.success,
        });

        this.props.history.push(routes.enquiry_list);
      } else if (updateEnquiry.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Update Enquiry.",
          severity: alertProps.severity.error,
        });
      }
    }

    return true;
  }

  careProviderByCategory = () => {};

  servicesByCareProvider = () => {};

  updateServiceRow = (s) => {
    let rows = this.state.rows;
    if (!this.state.rows.filter((_) => _.value === s.value).length) {
      rows.push(s);
    }
    this.setState({
      rows,
    });
  };

  handleRemove = (e) => {
    let rows = this.state.rows.filter((_) => _.value !== e.value);

    this.setState({
      rows: rows,
    });
  };

  handleCallerAndContactSame = (e) => {
    let isdisableCaller = this.state.isdisableCaller;
    if (isdisableCaller && !e.target.checked) {
      isdisableCaller = false;
    }
    this.setState({
      isdisableCaller,
      isContactAndCallerSame: e.target.checked,
    });
  };
  cancelForm = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  // ID Image Upload
  uploadFile = async (data, ic_passport) => {
    let arr = [...data];
    data.map(async (x, index) => {
      if (x?.id_photo) {
        let res = await this.props.FILE_UPLOAD({
          file: x?.id_photo,
          fileName: x?.id_photo?.name?.trim(),
        });
        arr[index] = {
          ...arr[index],
          idProof: res?.payload?.data?.fileid,
        };
        // arr[index].idProof = res?.payload?.data?.fileid;
      }
    });

    if (ic_passport?.ic_number) {
      if (!this.checkContains(arr, "IC_NUMBER")) {
        arr.push({
          label: "IC Number",
          value: "",
          id_type_name: {
            value: "",
            label: "IC Number",
            isActive: true,
            constIdentifier: "IC_NUMBER",
          },
          constIdentifier: "IC_NUMBER",
          id_type: ic_passport?.ic_number ?? "",
          id: uuidv4(),
        });
      }
    }
    if (ic_passport?.passport) {
      if (!this.checkContains(arr, "PASSPORT_ID")) {
        arr.push({
          label: "Passport ID",
          value: "",
          constIdentifier: "PASSPORT_ID",
          id_type_name: {
            value: "",
            label: "Passport ID",
            isActive: true,
            constIdentifier: "PASSPORT_ID",
          },
          id_type: ic_passport?.passport ?? "",
          id: uuidv4(),
        });
      }
    }
    return await arr;
  };

  // IC & Passport Extraction
  // extractICPassport = (data) => {
  //   let extract_arr = {};
  //   let ic_number = data.filter(
  //     (x) => x.id_type_name.constIdentifier === "IC_NUMBER"
  //   );
  //   let passport_number = data.filter(
  //     (x) => x.id_type_name.constIdentifier === "PASSPORT_ID"
  //   );
  //   extract_arr.ic_number = ic_number.length > 0 ? ic_number?.[0]?.id_type : "";
  //   extract_arr.passport_number =
  //     passport_number.length > 0 ? passport_number?.[0]?.id_type : "";
  //   return extract_arr;
  // };

  // ON SAVE BUTTON CLICKED
  onSaveButtonClicked = async (isError, state, url) => {
    // console.log("state-save-button-clicked", state, url, this.state);
    const { addedContactData } = this.state;
    if (this.state.isContactAndCallerSame) {
      if (
        Array.isArray(this.state.preferredLanguage) &&
        this.state.preferredLanguage.length === 0
      ) {
        console.log("HI");
      }
    }

    const getIsNewUser = async (clientId) => {
      const newUser = { isNewUser: true };
      const existingUser = { isNewUser: false };

      if (clientId) {
        const clientEnquiries = await this.props.GET_ENQUIRY_BY_CLIENT_ID({
          clientId: clientId,
        });

        if (this.props.isEdit) {
          // If the client has enquiries more than 1 in edit mode, then the client is not new user
          if (clientEnquiries?.payload?.data?.length <= 1) {
            return newUser;
          } else {
            return existingUser;
          }
        } else {
          // If the client has enquiries more than 0 in add mode, then the client is not new user
          if (clientEnquiries?.payload?.data?.length === 0) {
            return newUser;
          } else {
            return existingUser;
          }
        }
      } else {
        return newUser;
      }
    };



    const _payload = async () => {
      if (this.props.isEdit) {
        let { data: editData } = this.props?.enquiryDetails;
        const { addedContactData } = this.state;

        console.log(editData.enquiry_status?.replace(" ", "_").toLowerCase());
        const enqDate = new Date(state.date);
        const _enqDate = enqDate.getDate();
        const _enqYear = enqDate.getFullYear();
        const _enqMonth = enqDate.getMonth();
        const enqTime = new Date(state.time);
        const _enqMinutes = enqTime.getMinutes();
        const _enqHours = enqTime.getHours();
        const enqDateTime = new Date();
        enqDateTime.setDate(_enqDate);
        enqDateTime.setMonth(_enqMonth);
        enqDateTime.setFullYear(_enqYear);
        enqDateTime.setHours(_enqHours);
        enqDateTime.setMinutes(_enqMinutes);

        let updated_id =
          (await this.uploadFile(this.state.id, state.ic_passport)) ?? "";
        let updated_caller_id =
          (await this.uploadFile(this.state.id_caller, state.ic_passport)) ??
          "";
       
        let addedContactInformation = () => {
          if (!Boolean(this.state.isContactAndCallerSame)) {
            if (
              // addedContactData?.permanentAddress &&
              addedContactData?.caller_name &&
              addedContactData?.mobile_code &&
              addedContactData.email
            ) {
              return {
                complain: state.chief_complaint_current_complaint,
                medicalHistory: state.medical_history,
                relationship: state.relationship.value,

                contact_or_caller: "",
                contact_information: {
                  address: this.state?.address_caller,
                  bloodGroup: state?.bloodGroup?.value,
                  occupation: addedContactData?.occupation
                    ? addedContactData.occupation
                    : "",
                  contactId:
                    this.props.enquiryDetails?.data?.contact_information
                      ?.contactId,
                  salutation: addedContactData?.salutation
                    ? addedContactData.salutation
                    : "",
                  name: addedContactData?.caller_name
                    ? addedContactData.caller_name
                    : "",
                  // passportNumber: state.ic_passport.passport,
                  // icNumber: state.ic_passport.ic_number,

                  mobNo: `${
                    addedContactData?.mobile_code
                      ? addedContactData.mobile_code
                      : ""
                  } ${
                    addedContactData?.mobilenumber
                      ? addedContactData.mobilenumber
                      : ""
                  }`,
                  email: addedContactData.email,
                  // address: addedContactData?.permanentAddress
                  //   ? addedContactData.permanentAddress
                  //   : "",
                  // address: state.address,
                  addressLine1: addedContactData?.addressLine1
                    ? addedContactData.addressLine1
                    : "",
                  addressLine2: addedContactData?.addressLine2
                    ? addedContactData.addressLine2
                    : "",
                  area: addedContactData?.area ? addedContactData.area : "",
                  state: addedContactData?.state ? addedContactData.state : "",
                  country: addedContactData?.country
                    ? addedContactData.country
                    : "",
                  postalCode: addedContactData?.postalCode
                    ? addedContactData.postalCode
                    : "",
                  date_of_birth: addedContactData?.date_of_birth
                    ? new Date(addedContactData.date_of_birth).toISOString()
                    : "",
                  // current_location: addedContactData?.current_location
                  //   ? addedContactData.current_location
                  //   : "",
                  currentAddressLine1: addedContactData?.currentAddressLine1,
                  currentAddressLine2: addedContactData?.currentAddressLine2,
                  currentArea: addedContactData?.currentArea,
                  currentState: addedContactData?.currentState,
                  currentCountry: addedContactData?.currentCountry,
                  currentPostalCode: addedContactData?.currentPostalCode,
                  gender: addedContactData?.gender
                    ? addedContactData.gender.value
                    : "",
                  languages:
                    addedContactData?.preferred_language &&
                    addedContactData.preferred_language !== "[]"
                      ? JSON.stringify(addedContactData?.preferred_language)
                      : "[]", //new field
                  idTypes: updated_caller_id,
                  otherPhoneNumbers: this.state.phone_caller,
                  // passportNumber: ic_passport.passport_number,
                  // icNumber: ic_passport.ic_number,
                },
              };
            } else {
              return {
                relationship: "",
                contact_information: null,
                contact_or_caller: "same",
              };
            }
          } else {
            return {
              relationship: "",
              contact_information: null,
              contact_or_caller: "same",
            };
          }
        };
        const _payloaddata = {
          ticketId: editData?.ticketId,
          ticketStatus: editData?.ticketStatus,
          status: editData?.status,
          enquired_on: `${enqDateTime.toISOString()}`,
          lead_type_id: state.enquiry_type.value, // ENQUIRY TYPE
          logined_id: this?.props?.auth_data?.data?.user_id
            ? this.props.auth_data.data.user_id
            : null, //state.call_received_by.value
          assigned_to: state?.assigned_to?.value,
          source_of_referreal: state.source_of_referral.value,
          ...await getIsNewUser(editData?.contact_information?.clientId || editData?.caller_information?.clientId || editData?.caller_information?.contactId ),
          sourceType: SOURCE_TYPE.PORTAL,
          chief_complaint_current_complaint:
            state.chief_complaint_current_complaint,
          medicalHistory: state.medical_history,
          seenDoctor: state?.seen_a_doctor,
          height: state.height,
          weight: state.weight,
          enquiry_status: editData.enquiry_status
            ?.replace(" ", "_")
            .toLowerCase(),
          seen_a_doctor_remarks: state?.advice_client
            ? JSON.stringify(state.advice_client)
            : "",
          remarks: state.remarks,
          // languages: state.preferred_language.value, //new field
          languages:
            state?.preferred_language && state.preferred_language !== "[]"
              ? JSON.stringify(state?.preferred_language)
              : "[]", //new field
          language:
            state?.preferred_language && state.preferred_language !== "[]"
              ? JSON.stringify(state?.preferred_language)
              : "[]", //new field
          other_activities: this.state.isOtherActivitiesChecked
            ? state.other_activities_input
            : "",
          ...addedContactInformation(),

          caller_information: {
            callerId: this.props.enquiryDetails?.data?.caller_information
              ?.callerId
              ? this.props.enquiryDetails?.data?.caller_information?.callerId
              : this.props.enquiryDetails?.data?.caller_information?.contactId,
            salutation: state.caller_name_salutation,

            idTypes: updated_id,
            otherPhoneNumbers: this.state.phone,
            name: state.caller_name,
            mobNo: `${state.mobile_code} ${state.mobilenumber}`,
            email: state.email,
            relationship_to_contact: state.relationship.value,
            bloodGroup: state?.bloodGroup?.value,
            occupation: state?.occupation,
            address: this.state?.address,
            date_of_birth: new Date(state.date_of_birth_caller).toISOString(), //NEW FIELD
            gender: state.gender_caller.value, //NEW FIELD,
          },

          adl: JSON.stringify({
            feeding: state.feeding,
            bathing: state.bathing,
            toileting: state.toileting,
            grooming: state.grooming,
            ambulation: state.ambulation,
            transfer: state.transfer,
            bed_mobility: state.bed_mobility,
          }),
          closed_time:
            state?.status?.value === "CLOSED" ? new Date().toISOString() : "",
          relationship_to_contact: state.relationship.value,
          activities:
            state?.other_activities?.constructor === Array
              ? state?.other_activities
              : [state?.other_activities],
          updatedAt: new Date().toISOString(),
        };
        if (!Boolean(this.state.isContactAndCallerSame)) {
          _payloaddata["language"] =
            addedContactData.preferred_language &&
            addedContactData.preferred_language !== "[]"
              ? JSON.stringify(addedContactData.preferred_language)
              : "";
          _payloaddata["languages"] =
            addedContactData.preferred_language &&
            addedContactData.preferred_language !== "[]"
              ? JSON.stringify(addedContactData.preferred_language)
              : "";
        } else {
          _payloaddata["language"] =
            state.preferred_language && state.preferred_language !== "[]"
              ? JSON.stringify(state.preferred_language)
              : "";
          _payloaddata["languages"] =
            state.preferred_language && state.preferred_language !== "[]"
              ? JSON.stringify(state.preferred_language)
              : "";
        }

        console.log(_payloaddata);

        this.props.UPDATE_ENQUIRY({
          enquiry_id: this.props?.match?.params?.id,
          data: _payloaddata,
        });
      } else {
        const { addedContactData } = this.state;
        console.log(addedContactData);
        const enqDate = new Date(state.date);
        const _enqDate = enqDate.getDate();
        const _enqYear = enqDate.getFullYear();
        const _enqMonth = enqDate.getMonth();
        const enqTime = new Date(state.time);
        const _enqMinutes = enqTime.getMinutes();
        const _enqHours = enqTime.getHours();
        const enqDateTime = new Date();
        enqDateTime.setDate(_enqDate);
        enqDateTime.setMonth(_enqMonth);
        enqDateTime.setFullYear(_enqYear);
        enqDateTime.setHours(_enqHours);
        enqDateTime.setMinutes(_enqMinutes);
        let updated_id = await this.uploadFile(
          this.state.id,
          state.ic_passport
        );
        let updated_caller_id = await this.uploadFile(
          this.state.id_caller,
          state.ic_passport
        );

        // let ic_passport = this.extractICPassport(this.state.id);
        // let caller_ic_passport = this.extractICPassport(this.state.id_caller);
        let addedContactInformation = () => {
          if (!Boolean(this.state.isContactAndCallerSame)) {
            if (
              // addedContactData?.permanentAddress &&
              addedContactData?.caller_name &&
              addedContactData?.mobile_code &&
              addedContactData.email
            ) {
              return {
                complain: state.chief_complaint_current_complaint,
                medicalHistory: state.medical_history,
                relationship: state.relationship.value,

                contact_or_caller: "",
                contact_information: {
                  bloodGroup: state?.bloodGroup?.value,
                  occupation: addedContactData?.occupation
                    ? addedContactData.occupation
                    : "",
                  address: this.state?.address_caller,
                  // contactId:this.props.enquiryDetails?.data?.contact_information?.contactId,
                  salutation: addedContactData?.salutation
                    ? addedContactData.salutation
                    : "",
                  name: addedContactData?.caller_name
                    ? addedContactData.caller_name
                    : "",
                  clientId: addedContactData?.clientId
                    ? addedContactData?.clientId
                    : "",
                  mobNo: `${
                    addedContactData?.mobile_code
                      ? addedContactData.mobile_code
                      : ""
                  } ${
                    addedContactData?.mobilenumber
                      ? addedContactData.mobilenumber
                      : ""
                  }`,
                  email: addedContactData.email,

                  // passportNumber: state.ic_passport.passport,
                  // icNumber: state.ic_passport.ic_number,

                  // address: addedContactData?.permanentAddress
                  //   ? addedContactData.permanentAddress
                  //   : "",
                  // address: state.address,
                  addressLine1: addedContactData?.addressLine1
                    ? addedContactData.addressLine1
                    : "",
                  addressLine2: addedContactData?.addressLine2
                    ? addedContactData.addressLine2
                    : "",
                  area: addedContactData?.area ? addedContactData.area : "",
                  state: addedContactData?.state ? addedContactData.state : "",
                  country: addedContactData?.country
                    ? addedContactData.country
                    : "",
                  postalCode: addedContactData?.postalCode
                    ? addedContactData.postalCode
                    : "",
                  date_of_birth: addedContactData?.date_of_birth
                    ? new Date(addedContactData.date_of_birth).toISOString()
                    : "",
                  currentAddressLine1: addedContactData?.currentAddressLine1,
                  currentAddressLine2: addedContactData?.currentAddressLine2,
                  currentArea: addedContactData?.currentArea,
                  currentState: addedContactData?.currentState,
                  currentCountry: addedContactData?.currentCountry,
                  currentPostalCode: addedContactData?.currentPostalCode,
                  gender: addedContactData?.gender
                    ? addedContactData.gender.value
                    : "",
                  languages:
                    addedContactData?.preferred_language &&
                    addedContactData.preferred_language !== "[]"
                      ? JSON.stringify(addedContactData?.preferred_language)
                      : "[]", //new field
                  idTypes: updated_caller_id,
                  otherPhoneNumbers: this.state.phone_caller,

                  // passportNumber: caller_ic_passport.passport_number,
                  // icNumber: caller_ic_passport.ic_number,
                },
              };
            } else {
              return {
                relationship: "",
                contact_information: null,
                contact_or_caller: "same",
              };
            }
          } else {
            return {
              relationship: "",
              contact_information: null,
              contact_or_caller: "same",
            };
          }
        };

        const _payload = {
          // contact form
          // "salutation_added_contact": addedContactData?.title ? addedContactData.title : "",
          // "name_added_contact": addedContactData?.callerName ? addedContactData.callerName : "",
          // "date_of_birth_added_contact": addedContactData?.dob ? addedContactData.dob : "",
          // "gender_added_contact": addedContactData?.gender ? addedContactData.gender : "",
          // "mobile_code_added_contact": addedContactData?.code ? addedContactData.code : "",
          // "mobile_number_added_contact": addedContactData?.mobNumber ? addedContactData.mobNumber : "",
          // "address_added_contact": addedContactData?.permanentAddress ? addedContactData.permanentAddress :"",
          // "current_location_added_contact": addedContactData?.currentAddress ? addedContactData.currentAddress : "",
          // "email_added_contact":addedContactData.email,

          // enquiry form
          // ${state.time}
          enquired_on: `${enqDateTime.toISOString()}`,
          lead_type_id: state.enquiry_type.value, // ENQUIRY TYPE
          logined_id: this?.props?.auth_data?.data?.user_id
            ? this.props.auth_data.data.user_id
            : null, //state.call_received_by.value
          assigned_to: state?.assigned_to?.value,
          source_of_referreal: state.source_of_referral.value,
          ...await getIsNewUser(this.state.selectedExitsClients),
          sourceType: SOURCE_TYPE.PORTAL,
          complain: state.chief_complaint_current_complaint,
          medical_history: state.medical_history,
          seen_a_doctor: state.seen_a_doctor,
          // ? state?.seen_a_doctor === "yes"
          //   ? true
          //   : false
          // : false
          height: state.height,
          weight: state.weight,

          // passportNumber: state.ic_passport?.passport,
          // icNumber: state.ic_passport?.ic_number,

          enquiry_status: "enquiry_created",
          status: "enquiry_created",
          seen_a_doctor_remarks: state?.advice_client
            ? JSON.stringify(state.advice_client)
            : "",
          remarks: state.remarks,
          // languages: state.preferred_language.value, //new field
          languages:
            state?.preferred_language && state.preferred_language !== "[]"
              ? JSON.stringify(state?.preferred_language)
              : "[]", //new field
          language:
            state?.preferred_language && state.preferred_language !== "[]"
              ? JSON.stringify(state?.preferred_language)
              : "[]", //new field
          other_activities: this.state.isOtherActivitiesChecked
            ? state.other_activities_input
            : "",
          ...addedContactInformation(),

          caller_information: {
            // callerId:this.props.enquiryDetails?.data?.caller_information?.callerId,
            salutation: state.caller_name_salutation,
            name: state.caller_name,
            mobNo: `${state.mobile_code} ${state.mobilenumber}`,
            email: state.email,
            // address: state.address,
            // addressLine1: state.addressLine1,
            // addressLine2: state.addressLine2,
            // area: state.area,
            // state: state.state,
            // country: state.country,
            // postalCode: state.postalCode,
            date_of_birth: new Date(state.date_of_birth_caller).toISOString(), //NEW FIELD
            // current_location: state.current_location_caller, //NEW FIELD
            // currentAddressLine1: state?.currentAddressLine1,
            // currentAddressLine2: state?.currentAddressLine2,
            // currentArea: state?.currentArea,
            // currentState: state?.currentState,
            // currentCountry: state?.currentCountry,
            // currentPostalCode: state?.currentPostalCode,
            bloodGroup: state?.bloodGroup?.value,
            occupation: state?.occupation,
            address: this.state?.address,
            gender: state.gender_caller.value, //NEW FIELD,
            languages:
              state?.preferred_language && state.preferred_language !== "[]"
                ? JSON.stringify(state?.preferred_language)
                : "[]",
            relationship_to_contact: state.relationship.value,

            // idTypes: !this.state.isContactAndCallerSame
            //   ? updated_caller_id
            //   : updated_id,
            // otherPhoneNumbers: !this.state.isContactAndCallerSame
            //   ? this.state.phone_caller
            //   : this.state.phone,

            idTypes: updated_id,
            otherPhoneNumbers: this.state.phone,

            // passportNumber: Boolean(this.state.isContactAndCallerSame)
            //   ? caller_ic_passport.passport_number
            //   : ic_passport.passport_number,
            // icNumber: Boolean(this.state.isContactAndCallerSame)
            //   ? caller_ic_passport.ic_number
            //   : ic_passport.ic_number,
          },
          adl: JSON.stringify({
            feeding: state.feeding,
            bathing: state.bathing,
            toileting: state.toileting,
            grooming: state.grooming,
            ambulation: state.ambulation,
            transfer: state.transfer,
            bed_mobility: state.bed_mobility,
          }),
          relationship_to_contact: state.relationship.value,
          relationship: state.relationship.value,
          activities:
            state?.other_activities?.constructor === Array
              ? state?.other_activities
              : [state?.other_activities],
          closed_time: "",
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        if (this.state.selectedExitsClients) {
          _payload["selectedExitsClients"] = this.state.selectedExitsClients;
        }
        if (!Boolean(this.state.isContactAndCallerSame)) {
          _payload["language"] =
            addedContactData.preferred_language &&
            addedContactData.preferred_language !== "[]"
              ? JSON.stringify(addedContactData.preferred_language)
              : "";
          _payload["languages"] =
            addedContactData.preferred_language &&
            addedContactData.preferred_language !== "[]"
              ? JSON.stringify(addedContactData.preferred_language)
              : "";
          // ----------------------------------------------------
          // _payload["otherPhoneNumbers"] = this.state.phone_caller;
          // _payload['idTypes'] = updated_caller_id;
          // ----------------------------------------------------
        } else {
          console.clear();
          console.log(state.preferred_language);
          _payload["language"] =
            state.preferred_language && state.preferred_language !== "[]"
              ? JSON.stringify(state.preferred_language)
              : "";
          _payload["languages"] =
            state.preferred_language && state.preferred_language !== "[]"
              ? JSON.stringify(state.preferred_language)
              : "";
          // ----------------------------------------------------
          // _payload["otherPhoneNumbers"] = this.state.phone;
          // _payload['idTypes'] = updated_id;
          // ----------------------------------------------------
        }

        console.clear();
        console.log(_payload);
        this.props.ADD_ENQUIRY(_payload);
      }
    };
    let errorBlock = false;
    const { error } = this.state;
    if (this.state.isContactAndCallerSame) {
      if (!this.state.address || this.state.address.length === 0) {
        errorBlock = true;
        error["add_address"] = true;
        this.setState({ error });
      }
      // if (!this.state.phone || this.state.phone.length === 0) {
      //   errorBlock = true;
      //   error["add_phone"] = true;
      //   this.setState({ error });
      // }
      if (!this.state.id || this.state.id.length === 0) {
        errorBlock = true;
        error["add_id"] = true;
        this.setState({ error });
      }
    } else {
      // if (!this.state.address || this.state.address.length === 0) {
      //   errorBlock = true;
      //   error["add_address"] = true;
      //   this.setState({ error });
      // }
      if (!this.state.id || this.state.id.length === 0) {
        errorBlock = true;
        error["add_id"] = true;
        this.setState({ error });
      }

      if (
        !this.state.address_caller ||
        this.state.address_caller.length === 0
      ) {
        errorBlock = true;
        error["address_caller"] = true;
        this.setState({ error });
      }
      if (!this.state.id_caller || this.state.id_caller.length === 0) {
        errorBlock = true;
        error["id_caller"] = true;
        this.setState({ error });
      }
    }
    if (
      isError &&
      !Boolean(
        this.state.isContactAndCallerSame &&
          addedContactData?.permanentAddress &&
          addedContactData?.caller_name &&
          addedContactData?.mobile_code &&
          addedContactData.email &&
          addedContactData?.salutation &&
          addedContactData?.date_of_birth &&
          // addedContactData?.current_location &&
          addedContactData?.gender
      )
    ) {
      this.setState({ contactCallerError: true }, () => {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Please, fill the mandatory fields",
          severity: alertProps.severity.warning,
        });
      });
    } else if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else if (errorBlock) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, add minimun one address",
        severity: alertProps.severity.warning,
      });
    } else if (
      !this.props.isEdit &&
      !Boolean(this.state.isContactAndCallerSame)
    ) {
      const { addedContactData } = this.state;
      if (
        !Boolean(
          // addedContactData?.permanentAddress &&
          addedContactData?.caller_name &&
            addedContactData?.mobile_code &&
            addedContactData.email &&
            addedContactData?.salutation &&
            addedContactData?.date_of_birth &&
            // addedContactData?.current_location &&
            addedContactData?.gender
        )
      ) {
        this.setState({ contactCallerError: true }, () => {
          this.props.alert.setSnack({
            open: true,
            horizontal: alertProps.horizontal.center,
            vertical: alertProps.vertical.top,
            msg: "Please, fill the contact information",
            severity: alertProps.severity.warning,
          });
        });
      } else {
        this.setState({ contactCallerError: false });
        _payload();
      }
    } else {
      _payload();
    }
  };

  addContactCallFunction = (isError, data, url) => {
    console.log(data);
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      // let dob = new Date(data.date_of_birth)

      // const _payload =  {
      //   // "salutation": data.salutation,
      //   // "caller_name": data.caller_name,
      //   "date_of_birth": `${dob.getDate() + '-' + dob.getMonth()+ '-' +dob.getFullYear()}`,
      //   "gender": data.gender.value,
      //   // "mobile_code": data.mobile_code,
      //   // "mobile_number": data.mobile_number,
      //   // "address": data.address,
      //   // "current_location": data.current_location,
      //   // "email":data.email
      // }
      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: false,
        component: <></>,
      });
      this.setState({ isShowContactDetails: false, addedContactData: data });
      // this.props.ADD_CONTACT_ENQUIRY(_payload)
    }
  };

  onAddEditDrawerAddress = (name, e, list) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <AddressComponent
          id="NewCallerAddEditEnquiry"
          type="address"
          list={list}
          callBackOnSave={this.callBackOnSaveAddress}
        />
      ),
      open: true,
    });
  };

  onAddEditDrawerAddress1 = (name, e, list) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <AddressComponent
          id="NewClientAddEditEnquiry"
          type="address"
          list={list}
          callBackOnSave={this.callBackOnSaveAddress1}
        />
      ),
      on_close_fun: () => {},
      open: true,
    });
  };

  onAddEditDrawerId = (name, e, list) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <IdTypeComponent
          id="NewClientAddEditEnquiry"
          type="address"
          list={list}
          callBackOnSave={this.callBackOnSaveId}
        />
      ),
      on_close_fun: () => {},
      open: true,
    });
  };

  callBackOnSaveId = (data, isremove) => {
    const { error } = this.state;
    if (isremove) {
      let isUpdateData = this.state.id?.filter(
        (val) => val.value !== data?.value
      );

      this.setState({ id: isUpdateData }, () => {
        console.log("addressaddress", this.state.id);
      });
    } else {
      this.props.drawer.setDrawer({
        component: <></>,
        open: false,
      });

      error["add_id"] = false;
      this.setState({ error });
      if (data) {
        if (this.state.id?.length > 0) {
          let isUpdateData = this.state.id?.findIndex(
            (val) => val.id === data?.id
          );
          if (isUpdateData > -1) {
            let _newData = {
              ...data,
              label: data.id_type_name.label,
              value: data.id_type_name.value,
              id_photo: data.id_photo,
              file: data.id_photo,
              id: uuidv4(),
            };
            let { id } = this.state;
            let newArray = [...id];
            newArray[isUpdateData] = _newData;
            id = newArray;
            this.setState({ id }, () => {
              console.log("addressaddress", this.state.id);
            });
          } else {
            let _newData = {
              ...data,
              label: data.id_type_name.label,
              value: data.id_type_name.value,
              id_photo: data.id_photo,
              file: data.id_photo,
              id: uuidv4(),
            };
            this.setState({ id: [...this.state.id, _newData] }, () => {
              console.log("addressaddress", this.state.id);
            });
          }
        } else {
          let _newData = {
            ...data,
            label: data.id_type_name.label,
            value: data.id_type_name.value,
            id_photo: data.id_photo,
            file: data.id_photo,
            id: uuidv4(),
          };
          this.setState({ id: [...this.state.id, _newData] }, () => {
            console.log("addressaddress", this.state.id);
          });
        }
      }
    }
  };

  onAddEditDrawerContact = (name, e, list) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <PhoneComponent
          id="NewClientAddEditEnquiry"
          type="address"
          list={list}
          callBackOnSave={this.callBackOnSaveContact}
        />
      ),
      on_close_fun: () => {},
      open: true,
    });
  };

  callBackOnSaveContact = (data, isremove) => {
    const { error } = this.state;

    if (isremove) {
      let isUpdateData = this.state.phone?.filter(
        (val) => val.value !== data?.value
      );

      this.setState({ phone: isUpdateData }, () => {
        console.log("addressaddress", this.state.phone);
      });
    } else {
      this.props.drawer.setDrawer({
        component: <></>,
        open: false,
      });
      error["add_phone"] = false;
      this.setState({ error });
      if (data) {
        if (this.state.phone?.length > 0) {
          let isUpdateData = this.state.phone?.findIndex(
            (val) => val.id === data?.id
          );
          if (isUpdateData > -1) {
            let _newData = {
              ...data,
              mobileNo: data?.mobilenumber,
              mobileNoIsdCode: data?.mobilenumberISDcode,
              label: data.mobile_type?.label,
              value: data.mobile_type?.value,
              id: uuidv4(),
            };
            let { phone } = this.state;
            let newArray = [...phone];
            newArray[isUpdateData] = _newData;
            phone = newArray;
            this.setState({ phone }, () => {
              console.log("addressaddress", this.state.phone);
            });
          } else {
            let _newData = {
              ...data,
              mobileNo: data?.mobilenumber,
              mobileNoIsdCode: data?.mobilenumberISDcode,
              label: data.mobile_type?.label,
              value: data.mobile_type?.value,
              id: uuidv4(),
            };
            this.setState({ phone: [...this.state.phone, _newData] }, () => {
              console.log("addressaddress", this.state.phone);
            });
          }
        } else {
          let _newData = {
            ...data,
            mobileNo: data?.mobilenumber,
            mobileNoIsdCode: data?.mobilenumberISDcode,
            label: data.mobile_type?.label,
            value: data.mobile_type?.value,
            id: uuidv4(),
          };

          if (!Array.isArray(this.state.phone)) {
            this.setState({ phone: [] }, () => {
              this.setState({ phone: [...this.state.phone, _newData] }, () => {
                console.log("addressaddress", this.state.phone);
              });
            });
          } else {
            this.setState({ phone: [...this.state.phone, _newData] }, () => {
              console.log("addressaddress", this.state.phone);
            });
          }
        }
      }
    }
  };

  // ------------------------------------------
  onAddEditDrawerContact1 = (name, e, list) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <PhoneComponent
          id="NewClientAddEditEnquiry"
          type="address"
          list={list}
          callBackOnSave={this.callBackOnSaveContact1}
        />
      ),
      on_close_fun: () => {},
      open: true,
    });
  };

  callBackOnSaveContact1 = (data, isremove) => {
    const { error } = this.state;

    if (isremove) {
      let isUpdateData = this.state.phone_caller?.filter(
        (val) => val.value !== data?.value
      );

      this.setState({ phone_caller: isUpdateData }, () => {
        console.log("addressaddress", this.state.phone_caller);
      });
    } else {
      this.props.drawer.setDrawer({
        component: <></>,
        open: false,
      });
      error["add_phone"] = false;
      this.setState({ error });
      if (data) {
        if (this.state.phone_caller?.length > 0) {
          let isUpdateData = this.state.phone_caller?.findIndex(
            (val) => val.id === data?.id
          );
          if (isUpdateData > -1) {
            let _newData = {
              ...data,
              mobileNo: data?.mobilenumber,
              mobileNoIsdCode: data?.mobilenumberISDcode,
              label: data.mobile_type?.label,
              value: data.mobile_type?.value,
              id: uuidv4(),
            };
            let { phone_caller } = this.state;
            let newArray = [...phone_caller];
            newArray[isUpdateData] = _newData;
            phone_caller = newArray;
            this.setState({ phone_caller }, () => {
              console.log("addressaddress", this.state.phone_caller);
            });
          } else {
            let _newData = {
              ...data,
              mobileNo: data?.mobilenumber,
              mobileNoIsdCode: data?.mobilenumberISDcode,
              label: data.mobile_type?.label,
              value: data.mobile_type?.value,
              id: uuidv4(),
            };
            this.setState(
              { phone_caller: [...this.state.phone_caller, _newData] },
              () => {
                console.log("addressaddress", this.state.phone_caller);
              }
            );
          }
        } else {
          let _newData = {
            ...data,
            mobileNo: data?.mobilenumber,
            mobileNoIsdCode: data?.mobilenumberISDcode,
            label: data.mobile_type?.label,
            value: data.mobile_type?.value,
            id: uuidv4(),
          };

          if (!Array.isArray(this.state.phone_caller)) {
            this.setState({ phone_caller: [] }, () => {
              this.setState(
                { phone_caller: [...this.state.phone_caller, _newData] },
                () => {
                  console.log("addressaddress", this.state.phone_caller);
                }
              );
            });
          } else {
            this.setState(
              { phone_caller: [...this.state.phone_caller, _newData] },
              () => {
                console.log("addressaddress", this.state.phone_caller);
              }
            );
          }
        }
      }
    }
  };

  onAddEditDrawerId1 = (name, e, list) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <IdTypeComponent
          id="NewClientAddEditEnquiry"
          type="address"
          list={list}
          callBackOnSave={this.callBackOnSaveId1}
        />
      ),
      on_close_fun: () => {},
      open: true,
    });
  };

  callBackOnSaveId1 = (data, isremove) => {
    const { error } = this.state;
    // alert(JSON.stringify(data))
    if (isremove) {
      let isUpdateData = this.state.id_caller?.filter(
        (val) => val.value !== data?.value
      );

      this.setState({ id_caller: isUpdateData }, () => {
        console.log("addressaddress", this.state.id_caller);
      });
    } else {
      this.props.drawer.setDrawer({
        component: <></>,
        open: false,
      });

      error["id_caller"] = false;
      this.setState({ error });
      if (data) {
        if (this.state.id_caller?.length > 0) {
          let isUpdateData = this.state.id_caller?.findIndex(
            (val) => val.id === data?.id
          );
          debugger;
          if (isUpdateData > -1) {
            let _newData = {
              ...data,
              label: data.id_type_name.label,
              value: data.id_type_name.value,
              id_photo: data.id_photo,
              file: data.id_photo,
              id: uuidv4(),
            };
            let { id_caller } = this.state;
            let newArray = [...id_caller];
            newArray[isUpdateData] = _newData;
            id_caller = newArray;
            this.setState({ id_caller }, () => {
              console.log("addressaddress", this.state.id_caller);
            });
          } else {
            let _newData = {
              ...data,
              label: data.id_type_name.label,
              value: data.id_type_name.value,
              id_photo: data.id_photo,
              file: data.id_photo,
              id: uuidv4(),
            };
            this.setState(
              { id_caller: [...this.state.id_caller, _newData] },
              () => {
                console.log("addressaddress", this.state.id_caller);
              }
            );
          }
        } else {
          let _newData = {
            ...data,
            label: data.id_type_name.label,
            value: data.id_type_name.value,
            id_photo: data.id_photo,
            file: data.id_photo,
            id: uuidv4(),
          };
          this.setState(
            { id_caller: [...this.state.id_caller, _newData] },
            () => {
              console.log("addressaddress", this.state.id_caller);
            }
          );
        }
      }
    }
  };
  // ------------------------------------------

  callBackOnSaveAddress = (data, isremove) => {
    const { error } = this.state;
    if (isremove) {
      let isUpdateData = this.state.address?.filter(
        (val) => val.value !== data?.value
      );

      this.setState({ address: isUpdateData }, () => {
        console.log("addressaddress", this.state.address);
      });
    } else {
      this.props.drawer.setDrawer({
        component: <></>,
        open: false,
      });

      error["add_address"] = false;
      this.setState({ error });
      if (data) {
        if (this.state.address?.length > 0) {
          let isUpdateData = this.state.address?.findIndex(
            (val) => val.id === data?.id
          );
          if (isUpdateData > -1) {
            let _newData = {
              ...data,
              label: data.address_type.label,
              value: data.address_type.value,
              id: uuidv4(),
            };
            let { address } = this.state;
            let newArray = [...address];
            newArray[isUpdateData] = _newData;
            address = newArray;
            this.setState({ address }, () => {
              console.log("addressaddress", this.state.address);
            });
          } else {
            let _newData = {
              ...data,
              label: data.address_type.label,
              value: data.address_type.value,
              id: uuidv4(),
            };
            this.setState(
              { address: [...this.state.address, _newData] },
              () => {
                console.log("addressaddress", this.state.address);
              }
            );
          }
        } else {
          let _newData = {
            ...data,
            label: data.address_type.label,
            value: data.address_type.value,
            id: uuidv4(),
          };
          this.setState({ address: [...this.state.address, _newData] }, () => {
            console.log("addressaddress", this.state.address);
          });
        }
      }
    }
  };

  callBackOnSaveAddress1 = (data, isremove) => {
    const { error } = this.state;
    if (isremove) {
      let isUpdateData = this.state.address_caller?.filter(
        (val) => val.value !== data?.value
      );

      this.setState({ address_caller: isUpdateData }, () => {
        console.log("addressaddress", this.state.address_caller);
      });
    } else {
      this.props.drawer.setDrawer({
        component: <></>,
        open: false,
      });

      error["address_caller"] = false;
      this.setState({ error });
      if (data) {
        if (this.state.address_caller?.length > 0) {
          let isUpdateData = this.state.address_caller?.findIndex(
            (val) => val.id === data?.id
          );
          if (isUpdateData > -1) {
            let _newData = {
              ...data,
              label: data.address_type.label,
              value: data.address_type.value,
              id: uuidv4(),
            };
            let { address_caller } = this.state;
            let newArray = [...address_caller];
            newArray[isUpdateData] = _newData;
            address_caller = newArray;
            this.setState({ address_caller }, () => {
              console.log("addressaddress", this.state.address_caller);
            });
          } else {
            let _newData = {
              ...data,
              label: data.address_type.label,
              value: data.address_type.value,
              id: uuidv4(),
            };
            this.setState(
              { address_caller: [...this.state.address_caller, _newData] },
              () => {
                console.log("addressaddress", this.state.address_caller);
              }
            );
          }
        } else {
          let _newData = {
            ...data,
            label: data.address_type.label,
            value: data.address_type.value,
            id: uuidv4(),
          };
          this.setState(
            { address_caller: [...this.state.address_caller, _newData] },
            () => {
              console.log("addressaddress", this.state.address_caller);
            }
          );
        }
      }
    }
  };

  checkContains = (data = [], value) => {
    let flag = false;
    data.map((x) => {
      if (x.id_type_name.constIdentifier === value) {
        flag = true;
        return null;
      }
      return null;
    });
    return flag;
  };

  handleSelectExitsClient = (e, value) => {
    console.log(e);
    let mn = e ? e?.mobile?.split(" ") ?? [] : [];

    let formatPhone = e?.otherPhoneNumbers?.map((x) => {
      return {
        ...x,
        mobileNoIsdCode: x.mobilenumberISDcode,
        mobileNo: x.mobileNo,
      };
    });
    let formatId = [];

    if (e?.ic_passport?.ic_number) {
      if (!this.checkContains(e.idTypes, "IC_NUMBER")) {
        formatId.push({
          id_type: e?.ic_passport?.ic_number,
          label: "IC Number",
          id_type_name: {
            value: "",
            label: "IC Number",
            isActive: true,
            constIdentifier: "IC_NUMBER",
          },
          constIdentifier: "IC_NUMBER",
          id: uuidv4(),
        });
      }
    }
    if (e?.ic_passport?.passport) {
      if (!this.checkContains(e.idTypes, "PASSPORT_ID")) {
        formatId.push({
          id_type: e?.ic_passport?.passport,
          label: "Passport ID",
          id_type_name: {
            value: "",
            label: "Passport ID",
            isActive: true,
            constIdentifier: "PASSPORT_ID",
          },
          constIdentifier: "PASSPORT_ID",
          id: uuidv4(),
        });
      }
    }

    let tempArr = e?.idTypes?.map((x) => {
      return { ...x, id_type: x.id_type };
    });

    if (tempArr && Array.isArray(tempArr)) {
      formatId = [...formatId, ...tempArr];
    }
    let json = {
      caller_name: e?.name,
      date_of_birth: e?.dob,
      email: e?.email,
      gender: e?.gender,
      mobile_code: mn[0],
      mobilenumber: mn[1],
      salutation: e?.salutation,
      occupation: e?.occupation,
      address: e?.address,
      loginUserId: e?.loginUserId,
      height: e?.height,
      weight: e?.weight,
      preferred_language: e?.languages,
      id_caller: formatId ?? [],
      phone_caller: formatPhone ?? [],
    };
    this.setState({
      isSelectClient: true,
      selectedExitsClients: e?.id ?? null,
      isdisableCantact: e ? true : false,
      isShowContactDetails: false,
      addedContactData: json,
      address_caller: e?.address,
      id_caller: formatId,
      phone_caller: formatPhone,
    });
  };
  handleSelectExitsClient1 = (e, value) => {
    // console.log(e)

    console.log("\n--------------------");
    console.log(e);

    let formatPhone = e?.otherPhoneNumbers?.map((x) => {
      return {
        ...x,
        mobileNoIsdCode: x.mobilenumberISDcode,
        mobileNo: x.mobileNo,
      };
    });

    let formatId = [];

    if (e?.icNumber) {
      if (!this.checkContains(e.idTypes, "IC_NUMBER")) {
        formatId.push({
          id_type: e?.icNumber,
          label: "IC Number",
          id_type_name: {
            value: "",
            label: "IC Number",
            isActive: true,
            constIdentifier: "IC_NUMBER",
          },
          constIdentifier: "IC_NUMBER",
          id: uuidv4(),
        });
      }
    }
    if (e?.passportNumber) {
      if (!this.checkContains(e.idTypes, "PASSPORT_ID")) {
        formatId.push({
          id_type: e?.passportNumber,
          label: "Passport ID",
          id_type_name: {
            value: "",
            label: "Passport ID",
            isActive: true,
            constIdentifier: "PASSPORT_ID",
          },
          constIdentifier: "PASSPORT_ID",
          id: uuidv4(),
        });
      }
    }

    let tempArr = e?.idTypes?.map((x) => {
      return { ...x, id_type: x.id_type };
    });

    if (tempArr && Array.isArray(tempArr)) {
      formatId = [...formatId, ...tempArr];
    }
    //

    let mn = e ? e?.mobile?.split(" ") ?? [] : [];
    this.setState({
      address: e?.address,
      salutation: this.props?.salutation?.data?.filter((val) => {
        return val.value === e?.salutation;
      })[0],
      mobile_code: mn[0],
      mobilenumber: mn[1],
      selectedExitsClients: e?.id ?? null,
      passportNumber: e?.passportNumber,
      icNumber: e?.icNumber,
      // id:[{id_type: e?.icNumber, label:"IC Number"},{id_type: e?.passportNumber, label:"Passport Number"}],
      id: formatId ?? [],
      phone: formatPhone ?? [],
      height: e?.height,
      weight: e?.weight,
      ic_passport: { ic_number: e?.icNumber, passport: e?.passportNumber },
      isdisableCaller: e ? true : false,
    });
  };
  // error:categoryError,
  // error:enquiryTypeError,
  // error:enquiryRelationshipError,
  // error:enquirySourceOfReferrelError,
  // error:enquiryenquiryAssignedToError,
  // error:enquiryenquiryCalledByError,

  // callback for seen a doctor
  handleSeenAdoctorCallback = (data) => {
    this.setState({ isSeenADoctor: data === "yes" ? false : true });
  };
  handleOtherActivitiesCallback = (data, key, currentValue, componentState) => {
    if (currentValue === "other") {
      this.setState({
        isOtherActivitiesChecked: data,
      });
    }
  };
  // handleOtherActivitiesCallback = (data, key, currentValue, componentState) => {

  //     this.setState({
  //       isOtherActivitiesChecked: data === "other" ? true : false,
  //     });

  // };
  bmiCalculation = (data, key) => {
    let BMI;
    const { weight, height } = this.state;
    if (key === "height") {
      if (this.state.weight && data) {
        BMI = Math.round((weight / Math.pow(data, 2)) * 10000);
      }
    } else {
      if (this.state.height && data) {
        BMI = Math.round((data / Math.pow(height, 2)) * 10000);
      }
    }

    this.setState({ bmi: BMI, [key]: data });
  };
  render() {
    let {
      loading: editLoading,
      error: editError,
      data: editData,
    } = this.props?.enquiryDetails;

    // console.log(this.props)
    let { isEdit } = this.props;

    // console.log(this.props.enquiryDetails)

    const { loading: categoryLoading, data: categoryData } =
      this.props?.categories;
    const { loading: enquiryTypeLoading, data: enquiryTypeData } =
      this.props?.enquiry_type;
    // const {loading:enquiryRelationshipLoading, data:enquiryRelationshipData} = this.props?.enquiry_relationship
    const {
      loading: enquirySourceOfReferrelLoading,
      data: enquirySourceOfReferrelData,
    } = this.props?.enquiry_source_of_referrel;
    const {
      loading: enquiryAssignedToLoading,
      data: enquiryenquiryAssignedToData,
    } = this.props?.enquiry_assigned_to;
    const { loading: updateLoading } = this.props?.updateEnquiry;
    // const {loading:enquiryCalledByLoading,  data:enquiryenquiryCalledByData} = this.props?.enquiry_assigned_to
    let { data: masterPhoneCodeData, loading: masterPhoneCodeLoading } =
      this.props?.masterPhoneCode;
    let { loading: masterSalutationLoading } = this.props?.salutation;
    let { loading: masterLanguageLoading } = this.props?.language;
    let { loading: masterReferrelLoading } =
      this.props?.enquiry_source_of_referrel;

    const columns = [
      { id: "sno", label: "S.No" },
      {
        id: "label",
        label: "Service",
        minWidth: 300,
        align: "left",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "amount",
        label: "Amount",
        minWidth: 100,
        align: "left",
        format: (value) => value,
      },
    ];

    function createData(sno, service, amount) {
      return { sno, service, amount };
    }

    const tableOptions = () => {
      let _arr = [];
      for (var i = 0; i < 15; i++) {
        let _SELECT_SERVICE_DROPDOWN_DATA = {};

        _SELECT_SERVICE_DROPDOWN_DATA["value"] = `service-${i}`;
        _SELECT_SERVICE_DROPDOWN_DATA["label"] = `Service-${i}`;
        _SELECT_SERVICE_DROPDOWN_DATA["data"] = createData(
          i,
          `Physiotherapy-TCM + ${i}${i}`,
          // `Package (5 Services) + ${i}${i}`,
          "RM 2500"
        );
        _arr.push(_SELECT_SERVICE_DROPDOWN_DATA);
      }

      return _arr;
    };

    return (
      <div style={{ padding: 20 }}>
        {isEdit ? (
          <>
            {editLoading && (
              <LoadingSection message={"Loading enquiry details..."} />
            )}
            {!editLoading && editError && (
              <InternatlServerError
                message={"Unable to fetch enquiry details"}
                onRetry={this.getEssentials}
              />
            )}
            {!editLoading && !editError && !editData && (
              <NotFoundSection message={"404 - Enquiry Detail Not Found."} />
            )}
            {!editLoading &&
              !editError &&
              editData &&
              !masterPhoneCodeLoading &&
              !masterSalutationLoading &&
              !masterLanguageLoading &&
              !masterReferrelLoading && (
                <AddEditUserManagement
                  components={components(
                    tableOptions,
                    columns,
                    this.state,
                    this.updateServiceRow,
                    this.handleRemove,
                    categoryData,
                    categoryLoading,
                    enquiryTypeData,
                    enquiryTypeLoading,
                    options.relationship,
                    enquirySourceOfReferrelLoading,
                    enquirySourceOfReferrelData,
                    enquiryAssignedToLoading,
                    enquiryenquiryAssignedToData,
                    this.addContactCallFunction,
                    this.handleSeenAdoctorCallback,
                    this.handleOtherActivitiesCallback,
                    isEdit,
                    editLoading,
                    editData,
                    editError,
                    this.bmiCalculation,
                    this.cancelForm,
                    this.props.salutation,
                    this.props.language,
                    this.props.allclients,
                    this.handleSelectExitsClient,
                    this.onAddEditDrawerAddress,
                    this.callBackOnSaveAddress,
                    this.callBackOnSaveAddress1,
                    this.onAddEditDrawerAddress1,
                    this.handleSelectExitsClient1,
                    masterPhoneCodeData,
                    this.onAddEditDrawerId,
                    this.callBackOnSaveId,
                    this.onAddEditDrawerContact,
                    this.callBackOnSaveContact,
                    this.onAddEditDrawerContact1,
                    this.callBackOnSaveContact1,
                    this.onAddEditDrawerId1,
                    this.callBackOnSaveId1
                  )}
                  onSaveButtonClicked={this.onSaveButtonClicked}
                  loading={updateLoading}
                  saveButtonLabel={updateLoading ? "Updating..." : "Update"}
                  handleSwithIconToggle={(e) => {
                    this.handleCallerAndContactSame(e);
                  }}
                  isResetState={this.state.isContactAndCallerSame}
                  saveButtonId={"saveButtonFromAddEditEnquiry"}
                  cancelButtonId={"cancelButtonFromAddEditEnquiry"}
                />
              )}
          </>
        ) : (
          <AddEditUserManagement
            components={components(
              tableOptions,
              columns,
              this.state,
              this.updateServiceRow,
              this.handleRemove,
              categoryData,
              categoryLoading,
              enquiryTypeData,
              enquiryTypeLoading,
              options.relationship,
              enquirySourceOfReferrelLoading,
              enquirySourceOfReferrelData,
              enquiryAssignedToLoading,
              enquiryenquiryAssignedToData,
              this.addContactCallFunction,
              this.handleSeenAdoctorCallback,
              this.handleOtherActivitiesCallback,
              isEdit,
              editLoading,
              editData,
              editError,
              this.bmiCalculation,
              this.cancelForm,
              this.props.salutation,
              this.props.language,
              this.props.allclients,
              this.handleSelectExitsClient,
              this.onAddEditDrawerAddress,
              this.callBackOnSaveAddress,
              this.callBackOnSaveAddress1,
              this.onAddEditDrawerAddress1,
              this.handleSelectExitsClient1,
              masterPhoneCodeData,
              this.onAddEditDrawerId,
              this.callBackOnSaveId,
              this.onAddEditDrawerContact,
              this.callBackOnSaveContact,
              this.onAddEditDrawerContact1,
              this.callBackOnSaveContact1,
              this.onAddEditDrawerId1,
              this.callBackOnSaveId1
            )}
            onSaveButtonClicked={this.onSaveButtonClicked}
            handleSwithIconToggle={(e) => {
              this.handleCallerAndContactSame(e);
            }}
            isResetState={this.state.isContactAndCallerSame}
            loading={this.props?.enquiry_add_enquiry?.loading}
            saveButtonLabel={
              this.props?.enquiry_add_enquiry?.loading ? "Adding..." : "Add"
            }
            saveButtonId={"saveButtonFromAddEditEnquiry"}
            cancelButtonId={"cancelButtonFromAddEditEnquiry"}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth_data: state?.authState?.loginAuth,
  categories: state?.masterSet?.categories,
  // enquiry_type: state?.enquiry_management?.enquiry_type,
  enquiry_type: state?.masterSet?.lead_types,
  allclients: state?.enquiry_management?.all_clients,
  // enquiry_relationship:state?.enquiry_management?.enquiry_relationship,
  // enquiry_source_of_referrel: state?.enquiry_management?.source_of_referral,
  enquiry_source_of_referrel: state?.masterSet?.enquiry_source_of_referral,
  enquiry_assigned_to: state?.enquiry_management?.assigned_to,
  // enquiry_received_by:state?.enquiry_management?.call_received_by,
  enquiry_add_contact: state?.enquiry_management?.add_contact,
  enquiry_add_enquiry: state?.enquiry_management?.add_enquiry,
  enquiryDetails: state.enquiry_management?.enquiry_details,
  updateEnquiry: state?.enquiry_management?.update_enquiry,
  salutation: state?.masterModule?.getsal ?? {
    data: [],
    loading: false,
    error: false,
  },
  language: state?.masterModule?.getlang ?? {
    data: [],
    loading: false,
    error: false,
  },
  masterPhoneCode: state?.resourceReducer?.masterPhoneCode,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(AddEditEnquiry)));
