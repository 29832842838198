import React from "react";
import {
  // Button,
  Grid,
  Typography,
  Container,
//   TextField,
//   IconButton,
//   FormControlLabel,
//   Checkbox,
//   Hidden
} from "@material-ui/core";
import {  withRouter } from "react-router-dom";
// import { routes } from "../../router/routes";
import { withStyles } from "@material-ui/core/styles";
// import { styles } from "./style";

// import { NavLink } from "react-router-dom";
// import Card from "@material-ui/core/Card";

// SubScreenComponents
// import HeaderLogo from "../../components/login/headerLogo";
import HeaderLogo from "./headerLogo"
import Footer from "./Footoer"
// import LeftPane from "../../components/login/LeftPane";
// import LeftPane from "../login/leftPane";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { AuthContext } from "../../contexts";
// import { alertProps, localStorageKeys } from "../../utils";

// // validation - utils

// import {
//   minCheck,
//   minNumberAndSymbolCheck,
//   charCaseCheck
// } from "../../utils/validations";

// // Icons
// import DoneIcon from "@material-ui/icons/Done";
// import CloseIcon from "@material-ui/icons/Close";
// import VisibilityIcon from "@material-ui/icons/Visibility";
// import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// import { errorMessage } from "../../utils/errorMessages";
import { withAlertAndDialog } from "../../HOCs";
import { white } from "material-ui/styles/colors";
import IconButton from '@material-ui/core/IconButton';
import  ArrowBack  from "@material-ui/icons/ArrowBack";


// import { AuthContext } from '../../contexts';
// const { LOGOUT_AUTH } = actions;

const styles = (theme) => ({
  
  wrapper: {
    height: "100%",
    background: "white"
  },
  root: {
    height: "100%"
  },
  hero: {
    height: "85%"
  },
  rightPaneWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
        padding: "0.25rem",
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    paddingBottom: "2rem",
    paddingLeft: "24px",
    paddingRight: "24px",
    // alignItems: "center"
  },
  rightPane: {
    width: "100%"
  },
  mainHeading: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 4
    },
    marginTop: 68,
    fontSize: "1.5rem",
    fontWeight: "bold"
  },
  subHeading: {
    fontSize: "1rem",
    color: theme.palette.secondary.main
  },
  passwordField: {
    width: "100%",
    marginTop: 32
  },
  //   passwordField: {
  //     width: "100%",
  //     marginTop: 24
  //   },
  errorMessage: {
    color: `${theme.palette.error.main}`
  },
  resetButton: {
    marginTop: 32,
    padding: 8,
    "&:hover": {
      transition: "0.5s",
      background: `${theme.palette.primary.main}`,
      opacity: 0.9
    }
  },
  validationListContainer: {
    listStyle: "none",
    textAlign: "left",
    paddingLeft: 0
  },
  validationListItemStartIcon: {
    display: "inline",
    margin: "0px 3px"
  },
  
  // Custom Header Style
  header: {
    height: "10%",
    // height: 44,
    [theme.breakpoints.down("sm")]: {
      // height: 60,
      textAlign: "center",
      // marginTop: 32
    }
  },
  list:{
      paddingLeft:"1.5rem",
      paddingBottom:5,
      paddingTop:5,
      fontSize:18,
      [theme.breakpoints.down("sm")]: {
        paddingLeft:"0.5rem",
      }
  },
  listText:{
    marginLeft:"1.5rem",
    paddingBottom:5,
    paddingTop:5,
    fontSize:18,
    [theme.breakpoints.down("sm")]: {
        marginLeft:"0.5rem",
    }
  },
  innerlist:{
    paddingLeft:"3rem",
    paddingBottom:5,
    paddingTop:5,
    fontSize:18,
    [theme.breakpoints.down("sm")]: {
      paddingLeft:"1rem",
    }
  },
  innerText:{
    marginLeft:"3rem",
    paddingBottom:5,
    paddingTop:5,
    fontSize:18,
    [theme.breakpoints.down("sm")]: {
        marginLeft:"1rem",
    }
  },
  innerinnerlist:{
    paddingLeft:"4.5rem",
    paddingBottom:5,
    paddingTop:5,
    fontSize:18,
    [theme.breakpoints.down("sm")]: {
      paddingLeft:"1.5rem",
    }
  },
  ul:{
    paddingLeft:"2rem",
    fontSize:18,
      [theme.breakpoints.down("sm")]: {
        paddingLeft:"0rem",
      }
  },
  alphanumber:{
    listStyle: "none",
    fontWeight: "bold",
    paddingTop: "1rem",
    fontSize:18,
},
backButton:{
  // marginTop: 0,
  // marginBottom:"1rem",
  // marginTop: "1rem",
  // width:"10%",
  // background:"#74b1b5",
  // color:"black",
  // background:"white",
  // color:"#74b1b5",
  [theme.breakpoints.down("sm")]: {
    // width:"35%",
    marginBottom: "0.25rem"
  },
  [theme.hover]:{
    background:"#74b1b5",
    color:"white",
  },
  position:"sticky", 
  top:10, 
  // marginLeft:"auto"
},
navType:{
  position: "sticky",
  top: 1,
  background: white
},

});

class TermsAndConditions extends React.Component {
  


  render() {
    // const { username, password, passwordVisibility } = this.state;
    // const { classes } = this.props;

    // const { loading, error, message } = this.props?.loginState;
    const { classes } = this.props;
    // console.log(this.props.history)

    return (
      <>
      <Container maxWidth="lg" className={classes.wrapper}>
        <div className={classes.root}>
          {/* <Hidden smDown> */}
            <HeaderLogo />
          {/* </Hidden> */}
          <Grid container alignItems={"stretch"} className={classes.hero}>
            {/* <Hidden smDown>

              <LeftPane />
            </Hidden> */}
            
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.rightPaneWrapper}
            >
              <div className={classes.navType}>
              
                <Grid container>
                  <Grid item xs={2} sm={2} md={6} lg={5} xl={5}>
                <IconButton color="primary" variant="contained" className={classes.backButton}  id="sendCodeButton" disableElevation onClick={() => this.props.history.goBack()}>
                  <ArrowBack/>
                  
                </IconButton>
                </Grid>
                <Grid item xs={10} sm={10} md={6} lg={7} xl={7}>
                <div textAlign="center" style={{padding:12}}>
                <Typography variant="h5" >Terms of Use</Typography>
                  </div>
                  </Grid>
                </Grid>
              
                </div>
                <div>
                    {/* <Typography variant="h5" style={{maxWidth:"fit-content"}}>Terms and Conditions</Typography> */}
                    
                    <Typography variant="subtitle1">PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS WEBSITE. IF YOU DO NOT AGREE TO THESE TERMS OF USE KINDLY REFRAIN FROM USING THIS WEBSITE. BY CONTINUING TO USE THIS WEBSITE AND BY CLICKING THE ACCEPTANCE BOX BELOW:</Typography>
                </div>
                <ul>
                    <li>YOU ARE DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE TERMS OF USE SET OUT HERE.</li>
                    <li>YOU WARRANT THAT YOU ARE OF MAJORITY AGE AND HAVE LEGAL CAPACITY TO ENTER INTO A BINDING CONTRACT UNDER THE APPLICABLE LAWS OF THE COUNTRY IN WHICH YOU RESIDE.</li>
                </ul>
                <div className={classes.ul}>
                    <div className={classes.alphanumber}>A. GENERAL</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> These Terms of Use (“Terms”) govern the access to and usage of online platforms which are operated by or for and on behalf of Ainqa Care Sdn Bhd (Company No. 202001019330 (1375650-U) a private limited company incorporated under the laws of Malaysia (“AINQA”).</div>
                            <div className={classes.list}><b>2.</b> These Terms apply to:-</div>
                            <div className={classes.innerlist}><b>2.1</b> any third party provider of goods and services (whether an individual, a corporation, a professional, a practitioner or any other form of entity ) on the platform known as tanya.care (“Platform”), directly or indirectly, including designated or authorised associates, employees, partners and practitioners (“Merchant Partner”)</div>
                            <div className={classes.innerlist}><b>2.2</b> any individual, corporation or other entity who accesses and/or registers on the Platform and/or places a product or service request on the Platform (“End-User”)</div>
                            <div className={classes.innerlist}>All references to “you” and “yours” shall include a reference to Merchant Partner and End-Users or any one of them as may be applicable.</div>
                            <div className={classes.list}><b>3.</b> AINQA has the irrevocable right to  review, amend, modify, supplement, delete and replace any of the Terms periodically (“Varied Terms”). Varied Terms shall take place with immediate effect upon posting of the same on the Platform. You hereby agree to review the Terms regularly to be aware of any Varied Terms. Your continued access to or use of the Platform after the implementation of any Varied Terms shall be deemed a conclusive and irrefutable acceptance on your part of the Varied Terms in respect of the use of the Platform.</div>
                            <div className={classes.list}><b>4.</b> You acknowledge and accept that AINQA is a TECHNOLOGY COMPANY which provides the Platform or any other platform for End-Users to obtain products and services from Merchant Partners. AINQA’s role is merely to link the End-Users with Merchant Partners and is NOT RESPONSIBLE for the acts and/or omissions of any Merchant Partner. ANY AND ALL LIABILITY IN RELATION TO ANY PRODUCT OR SERVICE MARKETED ON THE PLATFORM SHALL BE BORNE BY THE MERCHANT PARTNER ABSOLUTELY. NO MERCHANT PARTNER ON THE PLATFORM SHALL REPRESENT ITSELF TO BE AN AGENT, EMPLOYEE OR PERSONNEL OF AINQA. NONE OF THE PRODUCTS AND SERVICES PROVIDED ON THE PLATFORM SHALL BE DEEMED TO BE PROVIDED BY AINQA IN ANY WAY WHATSOEVER.</div>
                            {/* <div className={classes.list}>5. This Privacy Policy applies to Third Party Providers and End-Users (collectively, “you”, “your” or “yours”).</div>
                            <div className={classes.list}>6. The Company is committed to ensuring that your personal information is kept private and confidential. If you have any additional concerns or questions, you are encouraged to contact the Company and the Company will address your concerns to the best of its ability. If you have any queries, please address them to [.]</div>
                            <div className={classes.list}>7. Please read this Privacy Policy carefully and check back periodically for updates and modification. The Company reserves the right to update, vary, modify, add and remove terms and conditions contained in this Privacy Policy</div> */}
                        </div>
                    <div class={classes.alphanumber}>B. DEFINITIONS</div>
                        <div className={classes.ol}>
                            <p>‘Account’ has the meaning ascribed to in clause 2.1 of Item F.</p>
                            <p>‘AINQA’ means Ainqa Care Sdn Bhd (Company No. 202001019330 (1375650-U);</p>
                            <p>‘Application’ means the mobile application made available for download by AINQA (or its licensors) to End-Users and Merchant Partners;</p>
                            <p>‘End-User’ has the meaning defined in A2.2;</p>
                            <p>‘End-User Charges’ means the charges incurred by the End-User for the products and services obtained through the Platform as provided by Merchant Partners;</p>
                            <p>‘Merchant Partner’ has the meaning set out in A2.1;</p>
                            <p>‘Merchant Partner Agreement’ means the agreement entered into between the Merchant Partners and AINQA and shall include this Terms of Use and tanya.care Policies;</p>
                            <p>‘PDPA’ means the Personal Data Protection Act 2010 and all amendments thereto;</p>
                            <p>‘Personal Data’ means any personal information provided by you via the Platform which includes but is not limited to your name, your national registration identification card number, your passport number, health information, your image, biometric data, marital status, religion, race, insurance information, medical information and any other information not specifically mentioned herein but is defined as personal data under the PDPA;</p>
                            <p>‘Platform’ means tanya.care;</p>
                            <p>‘Service’ means healthcare products and/or services provided by Merchant Partners to End-Users through the Application, Platform and/or Software;</p>
                            <p>‘Software’ means the software associated with the Application which is supplied and made available for download and installation, by AINQA.</p>
                            <p>‘tanya.care Fee’ means the fee payable by Merchant Partners to AINQA pursuant to these Terms;</p>
                            <p>‘tanya.care Policies’ mean:-</p>
                            <ul>
                                <li>The Privacy Policy</li>
                                <li>The Refund Policy</li>
                                <li>all other forms, policies, notices, guidelines, frequently asked questions (FAQs) or agreements provided on the Platform or entered by you from time to time;</li>
                            </ul>
                            <p>‘Terms’ means these Terms of Use and shall include tanya.care Policies;</p>
                            {/*  */}
                        </div>

                    <div class={classes.alphanumber}>C. THE SERVICE</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> You acknowledge that AINQA has developed the Platform, Application and Software for the purposes of providing a means for End-Users to avail to Services directly from Merchant Partners who are independent contractors.</div>
                            <div className={classes.list}><b>2.</b> The Platform is made available to End-Users solely for personal and non-commercial use. </div>
                            <div className={classes.list}><b>3.</b> You acknowledge that AINQA does not provide or offer any services or products and is only engaged in providing a Platform for the Merchant Partners’ provision of Service and all products and services appearing on the Platform and the Application are provided by independent contractors who are not employed by AINQA or any of its affiliates.</div>
                            <div className={classes.list}><b>4.</b> You acknowledge and agree that the use of the Platform and/or Application, your rights and obligations in relation to the Platform and/or Application and any liabilities arising out of the use of the Platform and/or Application shall be governed by this Terms and tanya.care Policies and shall be applicable to End-Users and Merchant Partners respectively.</div>
                        </div>
                    <div class={classes.alphanumber}>D. COMPATIBILITY</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> Different models or versions of routers, browsers and devices may have firmware or settings that are not compatible with the Application, Platform and Software. While we continuously develop the Application, Platform and Software in order to, as far as possible, support all commonly used devices and models in markets and all browsers where the Application, Platform and Software is likely to be accessed from, we do not warrant compatibility of the Application, Platform and Software with specific mobile devices or other hardware.</div>
                        </div>
                    <div class={classes.alphanumber}>E. GRANT OF LICENSE AND RESTRICTIONS</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> AINQA and its licensors, where applicable hereby grant you limited a revocable, non-exclusive, non-transferable, limited license to use and access the Platform, Application and the Software for the purposes outlined in the tanya.care Policies, subject to these Terms and any liabilities of either party arising out of the use of the Platform and/or Application shall be subject to separate terms and conditions arising from the Service, as between the End-User and Merchant Partners exclusively. All rights not expressly granted to you pursuant to these Terms are hereby reserved by AINQA and its respective licensors.</div>
                            <div className={classes.list}><b>2.</b> You shall not:-</div>
                                <div className={classes.innerlist}><b>2.1 </b> license, sub-license, sell, transfer, assign, distribute or otherwise deal with or commercially exploit or in any way make available the Application and/or the Software to any third party;</div>
                                <div className={classes.innerlist}><b>2.2 </b> reproduce, modify or make any derivative works based on the Platform, the Application and/or the Software;</div>
                                <div className={classes.innerlist}><b>2.3 </b> remove, post or distribute any copyright, trademark or other proprietary notices from any portion of the description of Service, the Platform, the Application or the Software;</div>
                                <div className={classes.innerlist}><b>2.4 </b> link to, mirror or frame any part of the proffered Service, the Platform, the Application or the Software in any way;</div>
                                <div className={classes.innerlist}><b>2.5 </b> cause or launch any manual or programs or scripts (including but not limited to web spiders, web crawlers, web robots, web ants, web indexes, bots, viruses or worms or any service which may make multiple server requests per second, for the purpose of (a) scraping, indexing, surveying or otherwise data mining any portion of the Platform, the Application or the Software; or (b) unduly burdening or hindering the operation and/or functionality of any aspect of the Platform, the Application or the Software; or (c) in any way reproduce or circumvent the navigational structure or presentation of the Platform, the Application or the Software;</div>
                                <div className={classes.innerlist}><b>2.6 </b> disassemble, decompile, reverse engineer, decrypt, or attempt to derive and code or extract software from, the Platform, the Application or the Software;</div>
                                <div className={classes.innerlist}><b>2.7 </b> use the Platform, the Application or the Software to: (a) send spam or otherwise duplicative or unsolicited messages; (b) send or store infringing, obscene, threatening, libelous, or otherwise unlawful or tortious material, including but not limited to materials harmful to children or violative of third party privacy rights; (c) send material containing software viruses, worms, trojan horses or other harmful computer code, files, scripts, agents or programs; (d) interfere with or disrupt the integrity or performance of the Platform, the Application, the Software or the data contained therein; (e) attempt to gain unauthorized access to the Platform, the Application, the Software or its related software, systems or networks; (f) impersonate any person or entity or otherwise misrepresent your affiliation with a person or entity; or (g) engage in any conduct that could possibly damage our reputation or amount to being disreputable;</div>
                                <div className={classes.innerlist}><b>2.8 </b> defame, abuse, harass, threaten or otherwise violate the legal rights of others;</div>
                                <div className={classes.innerlist}><b>2.9 </b> publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information;</div>
                                <div className={classes.innerlist}><b>2.10</b>  conduct or forward surveys, contests, pyramid schemes or chain letters;</div>
                                <div className={classes.innerlist}><b>2.11</b>  upload or distribute files that contain software or other material protected by applicable intellectual property laws unless you own or control the rights thereto or have received all necessary consents;</div>
                                <div className={classes.innerlist}><b>2.12</b>  upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Platform, the Application or another’s computer or provide you undue advantage or benefit in purchasing Services;</div>
                                <div className={classes.innerlist}><b>2.13</b>  engage in any activity that interferes with or disrupts access to the Platform, Application or Service or any content within the same;</div>
                                <div className={classes.innerlist}><b>2.14</b>  attempt to gain unauthorised access to any portion or feature of the Platform, Application or any other systems or networks connected to the Platform or Application, to any company, server or to any Service offered on or through the Platform or Application,  by hacking, password mining or any other illegitimate means;</div>
                                <div className={classes.innerlist}><b>2.15</b>  probe, scan or test the vulnerability of the Platform and Application or any network connected to the same, breach the security or authentication measures on the Platform or Application or any network connected to the same. You may not reverse look-up, trace or seek to trace any information on any End-User or Merchant Partner on the Platform or Application, to its source. You may not exploit any content on the Platform, Application or information made available or offered by or through the Platform or Application in any way, whether or not the purpose is to reveal any information, including but not limited to personal identification information, other than your own information as provided on the Platform or the Application;</div>
                                <div className={classes.innerlist}><b>2.16</b>  disrupt or interfere with the security of, or otherwise cause harm to, the Platform, systems, resources, accounts, passwords, servers or networks connected to or accessible through the Platform or any affiliated or linked sites;</div>
                                <div className={classes.innerlist}><b>2.17</b>  collect or store data about other End-Users or Merchant Partners in connection with the prohibited conduct and activities set out in this paragraph E2;</div>
                                <div className={classes.innerlist}><b>2.18</b>  use the Platform or Application for any purpose that is unlawful or prohibited by these Terms, or to solicit the performance of any illegal activity or other activity which infringes the rights of AINQA or other third parties;</div>
                                <div className={classes.innerlist}><b>2.19</b>  falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded;</div>
                                <div className={classes.innerlist}><b>2.20</b>  violate any applicable laws or regulations for the time being in force within or outside Malaysia or anyone’s right to privacy or personality; and</div>
                                <div className={classes.innerlist}><b>2.21</b>  violate any of these Terms.</div>
                        </div>
                    <div class={classes.alphanumber}>F. USE OF THE PLATFORM AND APPLICATION </div>
                        <div className={classes.ol}>

                            <div className={classes.list}><b>1.</b> Eligibility, Suspension and Termination of Use</div>
                                <div className={classes.innerlist}><b>1.1</b> In order to use the Platform and the Application:</div>
                                    <div className={classes.innerinnerlist}>(a) you must register and activate an active End-User account or a Merchant Partner Account;</div>
                                    <div className={classes.innerinnerlist}>(b) In the case of an individual, you must be (i) at least 18 years of age or the age of legal majority in the country of your domicile and (ii) of sound mind and not suffer any incapacities preventing you from entering into and being bound by contracts under applicable laws;</div>
                                    <div className={classes.innerinnerlist}>(c) In the case of an organisation, you must be duly established and existing under the applicable laws of the country of your incorporation with legal capacity to enter into and be bound by contracts.</div>
                                    {/* <div className={classes.innerinnerlist}>(d) In the case of an individual you must be of sound mind and not suffer any incapacities preventing you from entering into and being bound by contracts under applicable laws</div> */}

                                <div className={classes.innerlist}><b>1.2</b> AINQA reserves the right to suspend or terminate access to the Platform or the Application on any End-User Account or Merchant Partner Account on account of ineligibility or for any other reason which AINQA deems fit, necessary or expedient.</div>
                                <div className={classes.innerlist}><b>1.3</b> You may terminate or suspend your End-User Account or Merchant Partner Account by giving notice to AINQA. Any termination or suspension of the End-User Account or Merchant Partner Account will not prejudice or prevent AINQA from exercising any rights it may have against you for breach or non-conformance of these Terms.</div>

                            <div className={classes.list}>2. User Account, Password and Security</div>
                                <div className={classes.innerlist}><b>2.1</b> In order to avail the Services on the Platform or the Application, you will have to register as an End-User or a Merchant Partner and provide inter alia , your name, address, contact details and such other details as may be required within the Platform or Application for registration purposes (“the Account”).</div>
                                <div className={classes.innerlist}><b>2.2</b> You shall ensure and confirm that the Account information provided by you is true, accurate, complete and up-to-date. If there is a change in the Account information, you shall promptly update your Account information on the Platform or Application (as is applicable). If you provide any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete, AINQA has the right to terminate or suspend your Account and refuse any and all current or future use of the Platform or the Application (or any portion thereof) at its discretion, in addition to any other right AINQA may have against you in law or equity for any misrepresentation of information provided by you and AINQA is not liable for your inability to avail yourself of the Services as a result thereof.</div>
                                <div className={classes.innerlist}><b>2.3</b> You will be responsible for maintaining the confidentiality of the Account information and are fully responsible for all activities that occur under your Account. You agree to (a) immediately notify AINQA of any unauthorised use of your Account information or any other breach of security, and (b) ensure that you exit from your Account at the end of each session. AINQA cannot and will not be liable for any loss or damage arising from your failure to comply with this provision. You may be held liable for losses incurred by AINQA or any other End-User or Merchant Partner of the Platform or Application due to authorised or unauthorised use of your Account as a result of your failure in keeping your Account information and transaction(s) secure and/or confidential. </div>
                                <div className={classes.innerlist}><b>2.4</b> You must not use the tanya.care Platform in any way that causes, or may cause, damage to the tanya.care Platform or impairment of the availability or accessibility of the tanya.care Platform.</div>
                                <div className={classes.innerlist}><b>2.5</b> You must not use the tanya.care Platform:</div>
                                  <div className={classes.innerinnerlist}>(a) in any way that is unlawful, illegal, fraudulent or harmful; or</div>
                                  <div className={classes.innerinnerlist}>(b) in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</div>
                                <div className={classes.innerlist}><b>2.6</b> You have no right to access the software code (including object code, intermediate code and source code) of the Platform.</div>
                                <div className={classes.innerlist}><b>2.7</b> Use or availing yourself of another End-User or Merchant Partner Account is expressly prohibited.</div>


                            <div className={classes.list}><b>3.</b> Promotions and Promotional Codes</div>
                                <div className={classes.innerlist}><b>3.1</b> AINQA may, at its absolute discretion create promotions and promotional codes that may be redeemed for account credit or other features or benefits related to the Services featured on the Platform and the Application subject to additional terms that AINQA establishes on a per promotional code basis (“Promo Codes”). You agree that Promo Codes:-</div>
                                    <div className={classes.innerinnerlist}>- must be used for the intended audience and purpose, and in a lawful manner;</div>
                                    <div className={classes.innerinnerlist}>- may not be duplicated, sold or transferred in any manner, or made available to the general public (whether posted to a public forum or otherwise), unless expressly permitted by AINQA;</div>
                                    <div className={classes.innerinnerlist}>- may be disabled by AINQA at any time for any reason without liability to AINQA;</div>
                                    <div className={classes.innerinnerlist}>- may only be used pursuant to the specific terms and conditions that AINQA establishes for the Promo Code;</div>
                                    <div className={classes.innerinnerlist}>- are not valid for cash; and</div>
                                    <div className={classes.innerinnerlist}>- may expire prior to your use of any applicable Service on the Platform or Application.</div>

                                <div className={classes.innerlist}><b>3.2</b> AINQA reserves the right to withhold or deduct credits or other features or benefits obtained through the use of Promo Codes by you or any other End-User or Merchant Partner.</div>


                            <div className={classes.list}><b>4.</b> Complaints and Help Desk</div>
                                <div className={classes.innerlist}><b>4.1</b> AINQA shall make available a helpdesk as a support service for the utilization of the tanya.care Platform.</div>
                                <div className={classes.innerlist}><b>4.2</b> The helpdesk may be used for the purposes of requesting and, where applicable, receiving technical support services; and the helpdesk must not be used for any other purpose.</div>
                                <div className={classes.innerlist}><b>4.3</b> Any complaints pertaining to the Service offered to End-Users by Merchant Partners must be taken up with each other directly and AINQA will not entertain any such complaints.</div>

                            <div className={classes.list}><b>5.</b> Third Party Interactions</div>
                                <div className={classes.innerlist}><b>5.1</b> During use of the Platform or Application, an End-User may enter into correspondence or transactions with Merchant Partners who provide Service through the Platform or Application and vice versa. Any such communication or agreement is strictly between End-User and Merchant Partner and AINQA and its licensors shall have no liability or obligation for any such communication or agreement. Neither AINQA nor any of its affiliate companies endorses any applications or sites on the Internet that are linked through the Platform or Application, and in no event shall AINQA, its licensors or its affiliate companies be responsible for any content, products, services or other materials on or available from such sites or Merchant Partners. Certain Merchant Partners may require your agreement to additional or different terms of use and privacy policies prior to your use of or access to such Service, and AINQA is not a party to and disclaims any and all responsibility and/or liability arising from such agreements between End-Users and Merchant Partners. You acknowledge that such additional or different terms of use and privacy policies may apply to your use, sale or provision of the Service (as is applicable). AINQA is not liable for any information that is provided and exchanged between End-User and Merchant Partner whether directly or via your authorisation to AINQA for the exchange.</div>
                                <div className={classes.innerlist}><b>5.2</b> AINQA may rely on third party advertising and marketing supplied through the Platform and other mechanisms to subsidize the Platform and/or to earn additional revenue. You  agree to receive such advertising and marketing. If you do not want to receive such advertising and marketing, please refer to our Privacy Policy for instructions to unsubscribe or update your privacy settings. You agree and allow AINQA to compile and release anonymised information regarding you and your use of the Service as part of a customer profile or Merchant Partner profile or similar report or analysis. You agree that it is your responsibility to take all precautions in all actions and interactions with any Merchant Partner you interact with through the Platform and/or advertising or marketing material supplied by Merchant Partners through the Platform.</div>
                                <div className={classes.innerlist}><b>5.3</b> AINQA may include hyperlinks to other websites or content on the Internet that are owned or operated by third parties (“Third Party Links“). Such Third Party Links are not under AINQA’s control and we are not liable for any errors, omissions, delays, defamation, libel, slander, falsehood, obscenity, pornography, profanity, inaccuracy or any other objectionable material contained in the content, or the consequences of accessing, any linked website. Any hyperlinks to any other websites or content is not an endorsement or verification by AINQA of such websites or content and you agree that your access to or use of such linked websites or content is entirely at your own risk.</div>
                                <div className={classes.innerlist}><b>5.4</b> Any Merchant Partner requiring placement of a hyperlink to other websites must obtain the prior written approval of AINQA. No use of the Platform or Application or any content therein shall be linked or used for commercial purposes save in the manner provided herein.</div>


                            <div className={classes.list}><b>6.</b> CONTENT AND INFORMATION</div>
                                <div className={classes.innerlist}><b>6.1</b> AINQA does not claim ownership of any content or information that a Merchant Partner uploads on the Platform or Application. When a Merchant Partner uploads or provides AINQA with any content that is covered by intellectual property rights (including without limitation photos or videos), the Merchant Partner hereby (a) grants AINQA a non-exclusive, royalty free, transferable, sub-licensable, worldwide license to host, use distribute, modify, run, copy, publicly perform or display, translate and create derivative works of your content; and (b) undertakes that it has the right to grant AINQA such license.</div>
                                <div className={classes.innerlist}><b>6.2</b> AINQA may provide a Merchant Partner an option to vary or amend the content or information submitted by it to AINQA pertaining to the product or service being offered on the Platform and Application or certain content submitted by the End-Users in order to facilitate the product or services provided including without limitation details of an order placed by an End-User. You acknowledge that any such content or information shall be made available in real time to End-Users via an application operated by us or otherwise, and undertake that you as the relevant Merchant Partner will and shall be responsible to handle any complaints or queries by the End-Users in respect of the product or service in question without any liability on the part of AINQA.</div>
                                <div className={classes.innerlist}><b>6.3</b> AINQA reserves the right to remove, vary or modify any content or information which appears on the Platform or Application without notice including but not limited to information relating to the availability of a product or service, pricing, promotions, offers, shipping charges and transit time. In addition AINQA further reserves the right to remove, vary or modify any content or information which appears on the Platform or Application for errors, omissions or inaccuracies, changing and updating information, cancelling orders (on receive of notice by any relevant Merchant Partner) at any time without prior notice. Notwithstanding the foregoing, AINQA shall not be obligated or liable in any way to update, amend or clarify information on the Platform or Application or carry out any rectification measures except as required by law.; and we shall, in no event, be liable to you or any third party for any losses, damages or consequences arising from (i) any variation, modification or removal of the content on the Platform or Application; and (ii) any rectification measure taken.</div>


                            <div className={classes.list}><b>7.</b> COMMUNICATION</div>
                                <div className={classes.innerlist}><b>7.1</b> By creating an Account, you agree that the Service may send you informational text (i.e. “SMS” or “WhatsApp”) messages, in-app push notifications and/or email messages as part of the normal business operation of your use of the Service. You may opt out of using text messages from AINQA at any time by emailing AINQA that you no longer wish to receive text message along with the number of the device receiving the messages. You acknowledge that opting out of receiving text messages may impact your use of the Service.</div>
                            
                            <div className={classes.list}><b>8.</b> INTERNET DELAYS</div>
                                <div className={classes.innerlist}><b>8.1</b> THE SERVICE, PLATFORM, APPLICATION AND/OR THE SOFTWARE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS INCLUDING THE DEVICE USED BY YOU OR THE MERCHANT PARTNER BEING FAULTY, NOT CONNECTED, OUT OF RANGE, SWITCHED OFF OR NOT FUNCTIONING. AINQA IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, DAMAGES OR LOSSES RESULTING FROM SUCH PROBLEMS.</div>

                            <div className={classes.list}><b>9.</b> RATINGS</div>
                                <div className={classes.innerlist}><b>9.2</b> You shall be entitled to rate certain Services provided on the Platform and the Application.  All ratings shall be reflected on the Platform and Application against the relevant Service provided and shall be subject to AINQA’s analysis and review. In the event AINQA is of the view that any rating is inappropriate, made fraudulently or is intended to damage the reputation of AINQA, the Merchant Partner, the Service, the Platform and/or the Application, AINQA retains the right to remove such ratings and suspend or terminate access to the Platform, the Application and the Software.</div>

                        </div>
                    <div class={classes.alphanumber}>G. CHARGES</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> FOR MERCHANT PARTNERS</div>
                            {/* <div className={classes.listText} style={{"maxWidth":"max-content"}} >[PLEASE PROVIDE US WITH CONFIRMATION ON WHETHER THIS IS HOW THIRD PARTY PROVIDERS WILL BE CHARGED]</div> */}
                                <div className={classes.innerlist}><b>1.1</b> You shall pay AINQA the tanya.care Fee which shall be a percentage of the End- User Charges, as determined by AINQA from time to time. All tanya.care Fees are immediately payable and non-refundable and deductible at source by AINQA, in accordance with the terms and conditions of your Merchant Partner agreement.</div>
                                <div className={classes.innerlist}><b>1.2</b> The tanya.care Fees shall not be refunded in the event you elect to discontinue use or terminate your access to the Platform, the Application or use of the Software OR in the event AINQA elects to terminate or suspend your license and/or access to the Platform, the Application or use of the Software OR in the event there is a disruption to the Service howsoever caused.</div>
                                <div className={classes.innerlist}><b>1.3</b> You acknowledge and accept that AINQA may administer and act as your collection agent to pay to you the total amount of the End-User Charges due to you in respect of the provision of any product or service on the Platform or Application less the applicable Service Fee.</div>
                                <div className={classes.innerlist}><b>1.4</b> AINQA shall have the right to terminate or suspend any transaction which it suspects to be fraudulent, illegal or involves criminal activity or where you have breached any of the Terms. In such event, you shall not have any claims against AINQA for any withholding of, delay in, suspension, forfeiture or cancellation of any payment(s) to you.</div>
                                {/* <div className={classes.innerlist}><b>1.5</b> <span style={{fontWeight:"bold"}}>[Please advise how you intend to pay the Third Party Providers]</span></div> */}

                                
                            <div className={classes.list}><b>2.</b> FOR END-USERS</div>
                                <div className={classes.innerlist}><b>2.1</b> End-Users shall make full payment of all applicable End-User charges for any Services offered on the Platform and/or the Application by the available methods of payment selected and in accordance with the terms set out within the payment methods.</div>
                                <div className={classes.innerlist}><b>2.2</b> Automated payments may be made by credit card or debit card as made available on the Platform or the Application and will be governed by terms set out within the payment methods.</div>
                                <div className={classes.innerlist}><b>2.3</b> The charges indicated on the Platform and/or the Application for the relevant Service will be as determined by AINQA or the Merchant Partners (as the case may be), and is excluding taxes and other applicable charges as is mentioned on the Platform and/or the Application. The charges specified for Services offered by Merchant Partners may change at AINQA’s or Merchant Partner’s sole discretion and AINQA and the Merchant Partners do not guarantee that the charges will be lowest in the territory or region or if the products or services will be available for delivery or performance at all times.</div>
                                <div className={classes.innerlist}><b>2.4</b> There may be offers for (a) pay per session; (b) subscription options which could be monthly, quarterly, half yearly or annually. This may be paid through standing instructions against your credit card or debit card respectively in accordance with the payment terms set out within the payment methods. </div>
                                <div className={classes.innerlist}><b>2.5</b> Any purchase of Services through the Platform or the Application shall be, in addition to the terms mentioned herein and subject to additional terms and conditions of the relevant Merchant Partner including but not limited to tanya.care Policies, payment terms, cancellation policies, refund policies, exchange policies and return policies relevant to a specific Service as is determined by AINQA or the Merchant Partner, which you are presumed to have read and accepted at the time of placing an order for the same.</div>

                            <div className={classes.list}><b>3.</b> CANCELLATION & REFUND POLICY</div>
                                <div className={classes.innerlist}><b>3.1</b> For Merchant Partners:</div>
                                    <div className={classes.innerinnerlist}><b>3.1.1</b> You acknowledge that the End-Users rely on you for efficient, prompt and professional delivery of Services. You agree that high and/or frequent cancellation rates will result in a loss of reputation and negatively impact the reputation and branding of AINQA. </div>
                                    <div className={classes.innerinnerlist}><b>3.1.2</b> While you may cancel a booking, the cancellation shall be based on acceptable cancellation reasons as provided in the Refund Policy or pursuant to paragraph 3.1.3 below. AINQA reserves the right to amend the acceptable cancellation reasons from time to time. A cancellation that is not based on one of the acceptable reasons or ignoring a booking may be counted in determining if your access to the Platform will be temporarily restricted.</div>
                                    <div className={classes.innerinnerlist}><b>3.1.3</b> Any refunds shall be as per the policies provided by the Merchant Partner to the End-User as part of their Service offering on the Platform and Application respectively. AINQA will not be liable for any refunds or returns of Services procured via the Platform.</div>

                                <div className={classes.innerlist}><b>3.2</b> For End-Users:</div>
                                    <div className={classes.innerinnerlist}><b>3.2.1</b> You may cancel a booking for reasons that qualify for cancellation under the Refund Policy.</div>
                                    <div className={classes.innerinnerlist}><b>3.2.2</b> You may cancel a booking by notifying AINQA through:</div>
                                    <div className={classes.innerinnerlist} >- our Helpdesk line number 1300 13 2511 or </div>
                                    <div className={classes.innerinnerlist} >- email: reach.us@tanya.care.</div>
                                    <div className={classes.innerinnerlist}><b>3.2.3</b> You may request for a refund through the Application in accordance with the Refund Policy. </div>


                            <div className={classes.list}><b>4.</b> TAXES</div>
                                <div className={classes.innerlist}><b>4.1</b> You agree that these Terms including but not limited to any transactions and payments made or received by you pursuant to the use of the Service is subject to all prevailing statutory taxes, duties, fees, charges and/or costs however denominated as may be applicable from time to time.</div>
                                <div className={classes.innerlist}><b>4.2</b> You agree to bear all taxes, duties and fees that may be applicable to you under the prevailing laws and regulations with respect to taxes, duties and fees. You shall further provide AINQA with any and all relevant documents, notices, filings, receipts and such other information not specifically mentioned herein to enable AINQA to respond to any queries or defend, set-off, claim for rebate of any taxes paid or payable in connection with the Service.</div>


                            {/*<div className={classes.list}>4. If your communication is very sensitive, or includes information such as your credit card number and/or bank account information, you should not send it electronically unless the email is encypted or the access to the Platform or the Application is secure. Materials posted to online forums such as bulletin boards or chat rooms are public, not secure and may be viewed by anyone. Any personal information you post may be collected and used by anyone and may result in unsolicited messages from other internet users</div>
                            <div className={classes.list}>5. In accordance with applicable laws, you may be entitled to:</div>
                                <ul>
                                    <li>Ask us about processing of your Personal Data, including to be provided with a copy of your Personal Data</li>
                                    <li>Request the correction and/or (in some cases) the deletion of your Personal Data</li>
                                    <li>In some cases, request that restriction of your Personal Data (where we are processing your Personal Data based on your consent)</li>
                                    <li>Request receipt or transmission to another organisation, in a machine readable form, of the Personal Data that you have provided to us where we are using your Personal Data that you have provided to us where we are using your Personal Data based on consent or performance of a contract</li>
                                    <li>Complain to the relevant data privacy authority if your data privacy rights are violated, or if you have suffered as a result of unlawful processing of your Personal Data.</li>
                                </ul>
                            <div className={classes.list}>6. Where you are given an option to share your Personal Data with us, you can always choose not to do so. If we have requested your consent to processing and you later choose to withdraw it, we will respect that choice in accordance with our legal obligations</div>
                            <div className={classes.list}>7. Note that choosing not to share your Personal Data with us or withdrawing your consent could mean that we are unable to perform certain actions necessary to archive the purposes of processing set out in B above or you may not be able to use the Service. After you have withdrawn your consent, we may be able to continue to process your Personal Data to the extent required or otherwise permitted by applicable laws and regulations.</div>
                            <div className={classes.list}>8. If you wish to request to exercise your rights, you can contact us through our contact details set out in Section I (CONTACT US) below.</div>
                            <div className={classes.list}>9. We will screen and verify all requests beforehand. In order to verify your authority to make a request, we may require you to provide supporting information or documentation to corroborate your request.</div>
                        <div className={classes.list}>10. Once verified, we will give effect to your request within the timelines prescribed by applicable laws.</div>*/}
                        </div>


                    <div class={classes.alphanumber}>H. INTELLECTUAL PROPERTY</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> OWNERSHIP</div>
                                <div className={classes.innerlist}><b>1.1</b> AINQA and its licensors (if applicable) shall own all rights, interest, title including but not limited to all intellectual property rights in and to the Platform, the Application, the Software and the Service respectively.</div>
                                <div className={classes.innerlist}><b>1.2</b> These rights shall extend to any and all tradenames, logos, brand names, processes, systems, product names of AINQA which is associated with the Platform, the Application, the Software and the Service respectively.</div>
                                <div className={classes.innerlist}><b>1.3</b> Nothing in these Terms or your  use of the Platform, the Application, the Software or the Service will confer or be construed as granting you, by implication, estoppel or otherwise, any licence or rights, interest, title, including but not limited to any intellectual property rights to use any intellectual property of AINQA. You may not, without AINQA’s prior written consent, use any trademarks, tradenames, logos, brands, service marks, product names as a hyperlink to AINQA’s website, the Platform or the Application.</div>
                                <div className={classes.innerlist}><b>1.4</b> For the avoidance of doubt, the term Software shall include its respective components, processes, architecture and design in its entirety.</div>

                            <div className={classes.list}><b>2.</b> INFRINGEMENT</div>
                                <div className={classes.innerlist}><b>2.1</b> If you believe the Platform, the Application or the Service violates your intellectual property, you must promptly notify AINQA in writing at reach.us@tanya.care. These notifications should only be submitted by the owner of the intellectual property or an agent authorised to act on his/her behalf. Any false claim by you may result in the termination of your access to the Platform. You are required to provide the following details in your notice:-</div>
                                    <div className={classes.innerinnerlist}>- The intellectual property that you believe is being infringed;</div>
                                    <div className={classes.innerinnerlist}>- The item that you think is infringing and include sufficient information about where the material is located on the Platform or the Application;</div>
                                    <div className={classes.innerinnerlist}>- A statement that you believe in good faith that item you have identified as infringing is not authorised by the intellectual property owner, its agent or the law to be used in connection with the Platform, the Application and the Service;</div>
                                    <div className={classes.innerinnerlist}>- Your contact details such as your address, telephone number and email address;</div>
                                    <div className={classes.innerinnerlist}>- A statement that the information you provided in your notice is accurate and that you are the intellectual property owner or an agent authorised to act on behalf of the intellectual property owner whose intellectual property is being infringed; and</div>
                                    <div className={classes.innerinnerlist}>- Your physical or electronic signature</div>
                                <div className={classes.innerlist}><b>2.2</b> AINQA may at its absolute discretion on a case by case basis, choose to suspend usage of the allegedly infringing intellectual property or otherwise maintain the same on the Platform until completion of investigations.</div>


                        </div>
                    <div class={classes.alphanumber}>I. REPRESENTATIONS AND WARRANTIES</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> By using the Service, you represent, warrant / undertake that:</div>
                                <div className={classes.innerlist}><b>1.1 </b> You have legal capacity to enter into the Agreement and that you are at least eighteen (18) years old OR you are a lawful entity incorporated under the law of the jurisdiction in which you are established capable of entering into and being bound by these Terms, of being sued and suing. You cannot enter into the Agreement if you are below eighteen (18) years old or you are not established as an entity under applicable laws which has the legal capacity to enter into and be bound by contracts;</div>
                                <div className={classes.innerlist}><b>1.2 </b> All the information which you provide shall be true and accurate;</div>
                                <div className={classes.innerlist}><b>1.3 </b> You will only use the Application, Platform and Service for their intended and lawful purposes;</div>
                                <div className={classes.innerlist}><b>1.4 </b> You will keep your Account password or any identification we provide you which allows access to the Service secure and confidential;</div>
                                <div className={classes.innerlist}><b>1.5 </b> You agree to notify us immediately of any unauthorized use of your Account or any other breach of security;</div>
                                <div className={classes.innerlist}><b>1.6 </b> You will not try to interrupt or harm the Service, Application, Platform and/or the Software in any way;</div>
                                <div className={classes.innerlist}><b>1.7 </b> You will not attempt to commercially exploit any part of the Application without our permission, including without limitation modify any of the Application’s content in any way, or copy, reproduce, publicly display, distribute or otherwise use or communicate them for any public or commercial purpose without our permission;</div>
                                <div className={classes.innerlist}><b>1.8 </b> You will not authorize others to use your identity or End-User or Merchant Partner status, and you may not assign or otherwise transfer your End-User or Merchant Partner Account to any other person or entity;</div>
                                <div className={classes.innerlist}><b>1.9 </b> 	You will provide us with whatever proof of identity or any other documents, permits, licenses or approvals which we may reasonably request or require from time to time;</div>
                                <div className={classes.innerlist}><b>1.10</b>  You will not use the Application, Platform or the Service for sending or storing any unlawful material or for fraudulent purposes;</div>
                                <div className={classes.innerlist}><b>1.11</b>  You will not use the Application, Platform, the Service and/or the Software to cause nuisance or behave in an inappropriate or disrespectful manner towards AINQA or any third party;</div>
                                <div className={classes.innerlist}><b>1.12</b>  When using the Service, you agree to comply with all laws applicable to you and/or your use of the Service;</div>
                                <div className={classes.innerlist}><b>1.13</b>  You will not copy, or distribute the Software or other content without written permission from AINQA;</div>
                                <div className={classes.innerlist}><b>1.14</b>  You will provide accurate, current and complete information as required for the Service and undertake the responsibility to maintain and update your information in a timely manner to keep it accurate, current and complete at all times during the term of the Agreement. You agree that AINQA may rely on your information as accurate, current and complete. You acknowledge that if your information is untrue, inaccurate, not current or incomplete in any respect, AINQA has the right but not the obligation to terminate this Agreement and your use of the Service at any time with or without notice;</div>
                                <div className={classes.innerlist}><b>1.15</b>  You will only use an access point or data account which you are authorized to use;</div>
                                <div className={classes.innerlist}><b>1.16</b>  You agree that the Service is provided on a reasonable effort basis;</div>
                                <div className={classes.innerlist}><b>1.17</b>  You agree that your use of the Service will be subject to tanya.care Policies and you further agree to be bound by the terms thereof which are expressed to be applicable to you;</div>
                                <div className={classes.innerlist}><b>1.18</b>  You agree to assist AINQA with any internal or external investigations as may be required by AINQA in complying with any prevailing laws or regulations in place;</div>
                                <div className={classes.innerlist}><b>1.19</b>  You agree to assume full responsibility and liability for all loss or damage suffered by yourself, AINQA or any other party as a result of your breach of this Agreement;</div>
                                <div className={classes.innerlist}><b>1.20</b>  You will not utilise modified devices or applications with the intent of evading detections or facilitating any activities intended to defraud AINQA or to disrupt the natural functions of the Application, the Platform, the Software or the Service;</div>
                                <div className={classes.innerlist}><b>1.21</b>  You provide us the phone numbers and contact details of End-Users and other contact details in your digital address book on a regular basis. You confirm that you are authorised to provide us with such numbers to enhance your use of the Service.</div>
                                {/* <div className={classes.innerText} style={{ "maxWidth":"max-content"}}> NOTE TO CLIENT: PLEASE REVIEW THIS AND ADVISE IF THERE ARE ADDITIONAL SPECIFIC REPRESENTATIONS YOU MAY WANT TO IMPOSE ON THIRD PARTY PROVIDERS OR END-USERS</div> */}
                        </div>

                    <div class={classes.alphanumber}>J. CONFIDENTIALITY</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> You shall maintain in confidence all information and data relating to AINQA, the Platform, the Application and the Service, confidential information, business affairs, marketing and promotion plans or other operations of AINQA or its affiliate and  associated companies which are disclosed to you by or on behalf of AINQA (whether orally or in writing and whether before, on or after the date of your acceptance of these Terms) or which are otherwise directly or indirectly acquired by you from AINQA, or any of its associated and affiliate companies, or created in the course of your participation in the Platform, the Application or the Service. You shall further ensure that you only use such confidential information in order to use the Service, and shall not without AINQA’s prior written consent, disclose such information to any third party nor use it for any other purpose.</div>
                            <div className={classes.list}><b>2.</b> The above obligations of confidentiality shall not apply to the extent that you can show that the relevant information:</div>
                                <div className={classes.innerlist}><b>2.1</b> was at the time of receipt already in your possession;</div>
                                <div className={classes.innerlist}><b>2.2</b> is, or becomes in the future, public knowledge through no fault or omission on your part;</div>
                                <div className={classes.innerlist}><b>2.3</b> was received from a third party having the right to disclose it; or</div>
                                <div className={classes.innerlist}><b>2.4</b> is required to be disclosed by law.</div>
                        </div>

                    
                    <div class={classes.alphanumber}>K. DATA PRIVACY AND PRIVACY POLICY</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> AINQA collects and processes your Personal Data in accordance with its Privacy Policy. The Privacy Policy applies to the Service and its terms are made a part of this Agreement by this reference.</div>
                            <div className={classes.list}><b>2.</b> Where applicable, you agree and consent to AINQA and any of its affiliate companies collecting, using, processing and disclosing Personal Data as further described in our Privacy Policy.</div> 
                        </div>

                    <div class={classes.alphanumber}>L. DISCLAIMERS AND LIMITATION OF LIABILITY</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> Disclaimers</div>
                                <div className={classes.innerlist}><b>1.1</b> The use of the Platform, Application and the Service is at your own risk.</div>
                                <div className={classes.innerlist}><b>1.2</b> AINQA takes certain industry-accepted precaution to secure the Platform, the Application or portions thereof. However AINQA does not warrant:-</div>
                                    <div className={classes.innerinnerlist}><b>1.2.1</b> the access to or use of the Platform or Application will meet your requirements;</div>
                                    <div className={classes.innerinnerlist}><b>1.2.2</b> the Platform or Application will always be available;</div>
                                    <div className={classes.innerinnerlist}><b>1.2.3</b> the access to or use of the Platforms will be uninterrupted, timely, secure, error-free or virus-free or free from other invasive or damaging code;</div>
                                    <div className={classes.innerinnerlist}><b>1.2.4</b> the quality of any products, services, information or other material (including the content and Services) purchased or obtained by you through your access to or use of the Platform or the Application will meet your expectations;</div>
                                    <div className={classes.innerinnerlist}><b>1.2.5</b> any errors in the Platform or the Application will be corrected.</div>
                                    <div className={classes.innerlist}><b>1.3</b> All express exclusions made by AINQA and stipulated in these Terms are repeated here.</div>

                            <div className={classes.list}><b>2.</b> Limitation of Liability</div>
                                <div className={classes.innerlist}><b>2.1</b> Unless otherwise stated, and to the fullest extent allowed by law, any claims against AINQA by you shall be limited to (i) termination of the right to access the Application and Platform and (ii) any refund to you shall be limited to the refund of the tanya.care Fees, not exceeding the maximum amount paid for any Service provided. AINQA and/or its licensors shall not be liable for any loss, damage or injury which may be incurred by or caused to you arising from the purchase, use, sale or provision of Services offered on the Platform or Application, including but not limited to:</div>
                                    <div className={classes.innerinnerlist}><b>2.1.1</b> Loss, damage or injury arising out of, or in any way connected with the Service, the Platform, Application and/or the software; </div>
                                    <div className={classes.innerinnerlist}><b>2.1.2</b> The use or inability to use the Service, the Platform, the Application and/or the Software; </div>
                                    <div className={classes.innerinnerlist}><b>2.1.3</b> Any reliance placed by you on the completeness, accuracy or existence of any advertising in relation to a Service; or </div>
                                    <div className={classes.innerinnerlist}><b>2.1.4</b> As a result of any relationship or transaction between End-User and Merchant Partner; </div>
                                    <div className={classes.innerinnerlist}>even if AINQA and/or its licensors have been previously advised of the possibility of such damages.</div>
                                <div className={classes.innerlist}><b>2.2</b> AINQA does not warrant and represent that it assesses or monitors the suitability, legality, ability, movement or location of any End-Users or Merchant Partners and you expressly waive and release AINQA from any and all liability, claims or damages arising from or in any way related to the End-Users or Merchant Partners (as may be applicable); </div>
                                <div className={classes.innerlist}><b>2.3</b> AINQA will not be a party to disputes, or negotiations of disputes between End-Users and Merchant Partners. Responsibility for the decisions made regarding  Services offered via the Platform and/or the Application (with all its implications) rests solely with and on you. You expressly waive and release AINQA from any and all liability, claims, causes of action, or damages arising from your use of the Service, the Platform and/or the Application.</div>
                                    {/* <div className={classes.innerinnerlist}><b>2.3.1</b> Loss, damage or injury arising out of, or in any way connected with the Service, the Platform, Application and/or the software;</div> */}
                                    {/* <div className={classes.innerinnerlist}><b>2.3.2</b> The use or inability to use the Service, the Platform, the Application and/or the Software;</div> */}
                                    {/* <div className={classes.innerinnerlist}><b>2.3.3</b> Any reliance placed by you on the completeness, accuracy or existence of any advertising;or</div> */}
                                    {/* <div className={classes.innerinnerlist}><b>2.3.4</b> As a result of any relationship or transaction between End-User and Third Party Provider ;,</div> */}
                                    {/* <div className={classes.innerinnerlist}>even if the Company and/or its licensors have been previously advised of the possibility of such damages.</div> */}
                                <div className={classes.innerlist}><b>2.4</b> The quality of the Services offered via the Platform and/or Application is entirely the responsibility of the Merchant Partner who ultimately provides such Services to the End-Users. You understand, therefore, that by using the service, you may be exposed to Services that are potentially dangerous, offensive, harmful to minors, unsafe or otherwise objectionable, and that you use the Service at your own risk.</div>
                                {/* <div className={classes.innerlist}><b>2.5</b> The Company will not be a party to disputes, or negotiations of disputes between End- Users and Third Party Providers. Responsibility for the decisions made regarding services and products offered via the Service, the Platform and/or the Application (with all its implications) rests solely with and on you. You expressly waive and release the Company from any and all liability, claims, causes of action, or damages arising from your use of the Service, the Platform and/or the Application.</div> */}
                                {/* <div className={classes.innerlist}><b>2.6</b> The quality of the products and services offered via the Service is entirely the responsibility of the Third Party Provider who ultimately provides such products or services to the End-Users. You understand, therefore, that by using the service, you may be exposed to products and services that are potentially dangerous, offensive, harmful to minors, unsafe or otherwise objectionable, and that you use the Service at your own risk.</div> */}

                        </div>

                    <div class={classes.alphanumber}>M. INDEMNITY</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> You agree to indemnify and hold harmless AINQA, its affiliate and associated companies, its shareholders, directors, officers, employees, agents, advisers and representatives (collectively “the Indemnified Parties”), from and against any and all claims, demands, actions, judgments, losses, liabilities, damages, costs and expenses of whatever nature (including legal costs on a full indemnity basis) which any or all of the Indemnified Parties may incur or suffer as a result of, arising out of or in connection with your access to or use of any Platforms, your breach of any of these Terms, or your infringement or violation of any rights of any third party.</div>
                            <div className={classes.list}><b>2.</b> The obligations set out in paragraph M1 shall survive any termination of your relationship with AINQA or your access to or use the Application or the Platform. AINQA reserves the right to assume the defence and control of any matter subject to indemnification by you, in which event you will cooperate with AINQA in asserting any available defences.</div>
                        </div>
                    
                    <div class={classes.alphanumber}>N. SUSPENSION AND TERMINATION</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> You agree that we may do any of the following, at any time, without notice: (i) to modify, suspend or terminate operation of or access to the Platform, the Application, or any portion thereof (including access to your Account and/or the availability of any Services), for any reason; (ii) to modify or change any applicable policies or terms; and (ii) to interrupt the operation of the Platform, the Application, or any portion thereof (including access to your Account and/or the availability of any Service), as necessary to perform routine or non-routine maintenance, error correction, or other changes. We shall not be required to compensate you for any suspension or termination.</div>
                            <div className={classes.list}><b>2.</b> AINQA reserves the right to terminate access to the Platform, the Application or any portion thereof (including access to your Account and/or the availability of any Service) in the event of any violation on your part of these Terms without prejudice to any rights or remedies AINQA may have in law or equity.</div>
                        </div>

                    <div class={classes.alphanumber}>O. MISCELLANEOUS</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> Notice</div>
                                <div className={classes.innerlist}><b>1.1</b> AINQA may give notice through the Application or the Platform, electronic mail to your email address in the records of AINQA, or by written communication sent by registered mail or pre-paid post to your address in the record of AINQA. Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing or posting (if sent by registered mail or pre-paid post) or 1 hour after sending (if sent by email). You may give notice to AINQA (such notice shall be deemed given when received by AINQA) by email, letter sent by courier or registered mail to AINQA using the contact details as you have provided in the Application and the Platform.</div>
                            <div className={classes.list}><b>2.</b> Assignment</div>
                                <div className={classes.innerlist}><b>2.1</b> These Terms as modified from time to time may not be assigned by you without the prior written approval of AINQA but may be assigned without your consent by AINQA. Any purported assignment by you in violation of this section shall be void.</div>
                            <div className={classes.list}><b>3.</b> Dispute Resolution</div>
                                <div className={classes.innerlist}><b>3.1</b> These Terms shall be governed by Malaysian law, without regard to the choice or conflicts of law provisions of any jurisdiction, and any disputes, actions, claims or causes of action arising out of or in connection with these Terms, the use of the Application or the Platform shall be referred to the Asian International Arbitration Centre (“AIAC”), in accordance with the Rules of the AIAC as modified or amended from time to time (the “Rules”) by a sole arbitrator appointed by the mutual agreement of you and AINQA (the “Arbitrator”). If you and AINQA are unable to agree on an arbitrator, the Arbitrator shall be appointed by the President of AIAC in accordance with the Rules. The seat and venue of the arbitration shall be Kuala Lumpur, in the English language and the fees of the Arbitrator shall be borne equally by you and AINQA, provided that the Arbitrator may require that such fees be borne in such other manner as the Arbitrator determines is required in order for this arbitration clause to be enforceable under applicable law.</div>
                            <div className={classes.list}><b>4.</b> Relationship</div>
                                <div className={classes.innerlist}><b>4.1</b> Nothing contained in these Terms shall be construed as creating any agency, partnership, or other form of joint enterprise with AINQA.</div>
                            <div className={classes.list}><b>5.</b> Severability</div>
                                <div className={classes.innerlist}><b>5.1</b> If any provision of these Terms is held to be invalid or unenforceable, the legality, validity and enforceability of the remaining provisions shall not be affected or impaired.</div>
                            <div className={classes.list}><b>6.</b> No Waiver</div>
                                <div className={classes.innerlist}><b>6.1</b> The failure of AINQA to enforce any right or provision in these Terms shall not constitute a waiver of such right or provision.</div>
                            <div className={classes.list}><b>7.</b> Entire Agreement</div>
                                <div className={classes.innerlist}><b>7.1</b> These Terms comprise the entire agreement between you and AINQA and supersedes any prior or contemporaneous negotiations or discussions.</div>
                            <div className={classes.list}><b>8.</b> No Third Party Rights or Assignment</div>
                                <div className={classes.innerlist}>This agreement does not give rights to any third parties who are not party to this Agreement.</div>
                        </div>

                    <div class={classes.alphanumber}>P. ACKNOWLEDGEMENT</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> By reading this policy, you acknowledge acceptance of these Terms on the Application or the Platform or on downloading the Software, you agree to be bound by these Terms as a valid and enforceable agreement between you and AINQA and further agree to be bound by all applicable tanya.care Policies which are applicable to you as End-User or Merchant Partner respectively as a valid and enforceable agreement between you and AINQA.</div>
                            <div className={classes.list}>* no approaching End-Users outside the Platform once secured via the Platform</div>
                            <div className={classes.list}>* acknowledgement of Policies to be read and construed as part of this Agreement</div>
                        </div>


                </div>
                
            </Grid> 
            
            
          </Grid>
          
          <Grid container xs={12} style={{ height: "5%" }}></Grid>
        </div>
        {/* <Footer/> */}
      </Container>
      <Footer/>
      </>
    );
  }
}

TermsAndConditions.contextType = AuthContext;

const mapStateToProps = (state) => ({
  loginState: state?.authState?.loginAuth,
  resetPassword: state?.authState?.resetPasswordFromEmail
});

export default connect(
  mapStateToProps,
  actions
)(withAlertAndDialog(withStyles(styles, { withTheme: true })(withRouter(TermsAndConditions))));
