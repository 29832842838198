import React from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'
// import { NavLink } from 'react-router-dom'
// import { NavLink } from 'react-router-dom'


const styles = makeStyles((theme) => ({
    root: {
        background: theme.palette.secondary.light,
        borderRadius: 8,
        padding: 10
    },
    textMain: {
        color: theme.palette.secondary.main,
        paddingTop:10
    },
    callBack: {
        color: theme.palette.ternary.main,
    },
    title: {
        color: "black"
    }
}))
export const CardWithTextAndCallBack = (props) => {
    const classes = styles()
    const {
      mainText,
      title,
      buttonText,
    //   navigationLink,
      viewButtonCallback,
    } = props.data;
    // buttonText,navigationLink
    return (
      <Grid container className={classes.root}>
        <Grid item xs={6} className={classes.title}>
          {title}
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          {/* <NavLink
            className={classes.callBack}
            to={navigationLink}
            style={{ textDecoration: "none" }}
          >
            {buttonText}
          </NavLink> */}
          <Button
            className={classes.callBack}
            onClick={() => viewButtonCallback(props?.parentState)}
          >
            {buttonText}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.textMain}>
          {mainText}
        </Grid>
      </Grid>
    );
}