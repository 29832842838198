import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import {Button, Grid} from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ImgDialog from "./ImgDialog";
import getCroppedImg from "./getCroppedImg";
import { styles } from "./styles";

const Demo = ({ classes, ...props }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  let {image, callBackFunction} = props
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      console.log("donee", { croppedImage });
      callBackFunction(croppedImage)
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line
  }, [croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  return (
    <>
      <div className={classes.cropContainer}>
        <Cropper
          image={props.image}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={1}
          cropSize={{
              width:200,height:200
          }}
          cropShape={"round"}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <Grid container className={classes.controls} spacing={2}>
        <Grid item xs={12} sm={6} className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            Zoom
          </Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ container: classes.slider }}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            Rotation
          </Typography>
          {/* <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            classes={{ container: classes.slider }}
            onChange={(e, rotation) => setRotation(rotation)}
          /> */}
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            scale={(x) => x ** 10}
            onChange={(e, rotation) => setRotation(rotation)}
            aria-labelledby="Rotation"
          />
        </Grid>
        <Button
          onClick={showCroppedImage}
          variant="contained"
          color="primary"
          classes={{ root: classes.cropButton }}
        >
          Click to Crop
        </Button>
      </Grid>
      <ImgDialog img={croppedImage} onClose={onClose} />
    </>
  );
};

export const ImageEditor = withStyles(styles)(Demo);
