import React from "react";
import { makeStyles, Typography, Divider, Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { textClampStyleGenerator } from "../../utils";
import MultipleChip from "./multipleChip";
import moment from "moment";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  // itemGender: {
  //   color: "inherit"
  // },

  stretchItem: {
    alignSelf: "stretch",
  },
}));

const CustomTypography = styled(Typography)({
  color: "inherit",
  userSelect: "none",
});

export function Underline({ type, gender, age, category, loading, ...props }) {
  const classes = useStyles();

  return (
    <>
      {/* Grid item to render TYPE of the card */}
      {Array.isArray(type) && type?.length > 0 && type?.[0]?.label && (
        <>
          <Grid item>
            <ItemTypeRenderer type={type?.[0]?.label} loading={loading} />
          </Grid>
          <Grid item className={classes.stretchItem}>
            <Divider orientation="vertical" />
          </Grid>
        </>
      )}

      {/* Grid item to render GENDER of the card */}
      {gender && gender?.label && (
        <>
          <Grid item>
            <ItemGenderRenderer gender={gender?.label} loading={loading} />
          </Grid>
          <Grid item className={classes.stretchItem}>
            <Divider orientation="vertical" />
          </Grid>
        </>
      )}

      {/* Grid item to render AGE of the card */}
      {!!age && moment(age?.toString())?.isValid() && (
        <>
          <Grid item>
            <ItemAgeRenderer age={age} loading={loading} />
          </Grid>
          <Grid item className={classes.stretchItem}>
            <Divider orientation="vertical" />
          </Grid>
        </>
      )}

      {/* Grid item to render CATEGORY of the card */}
      {Array.isArray(category) && category?.length > 0 && (
        <Grid item>
          <MultipleChip category={category} loading={loading} />
        </Grid>
      )}
    </>
  );
}

const itemTypeStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 700,
  },
}));

function ItemTypeRenderer({ type, loading }) {
  const classes = itemTypeStyles();
  return (
    <>
      {loading && (
        <Skeleton variant="text" width={40} />
      )}
      {!loading && (
        <CustomTypography variant="body2" className={classes.root}>
          {!!type ? type : "N/A"}
        </CustomTypography>
      )}
    </>
  );
}

function ItemGenderRenderer({ gender, loading }) {
  return (
    <>
      {loading && <Skeleton variant="text" width={40} />}
      {!loading && (
        <CustomTypography variant="body2">{gender}</CustomTypography>
      )}
    </>
  );
}

const itemAgeStyles = makeStyles((theme) => ({
  root: {
    ...textClampStyleGenerator(),
  },
}));

function ItemAgeRenderer({ age, loading }) {
  const classes = itemAgeStyles();
  return (
    <>
      {loading && <Skeleton variant="text" width={40} />}
      {!loading && (
        <CustomTypography variant="body2" noWrap className={classes.root}>
          {moment().diff(age, "years") === 0
            ? moment().diff(age, "days") + " days old"
            : moment().diff(age, "years") + " years old"}
        </CustomTypography>
      )}
    </>
  );
}
