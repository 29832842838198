import React from "react";
import { routes } from "../../router/routes";

import {
  DashboardNewIcon,
  ManagementNewIcon,
  AdministrationNewIcon,
  SalesOperationNewIcon,
  // MonitoringNewIcon,
  // AboutNewIcon,
  // HelpNewIcon,
  // SettingsNewIcon,
  MonitoringNewIcon,
  LogoutNewIcon,
} from "../../assets";
import { OTHER_ROLES, user_roles } from "../../utils";

export const drawerWidth = 240;
export const appBarHeight = 60;

const navBarTopSubMens = {
  management: {
    persona_management: {
      name: "Partner Management",
      id: `subMenuPartnerManagement`,
      icon: "",
      link: routes.partner_management_provider,
    },
    resource_management: {
      name: "Resource Management",
      id: `subMenuResourceManagement`,
      icon: "",
      link: routes.manage_supervisors,
    },
    product_management: {
      name: "Product Management",
      id: `subMenuProductManagement`,
      icon: "",
      link: routes.manage_service,
    },
    service_professional_management: {
      name: "Availability Management",
      id: `subMenuAvailabilityManagement`,
      icon: "",
      link: routes.availability_calendar_main + "day",
    },
    client_management: {
      name: "Client Management",
      id: `subMenuClientManagement`,
      icon: "",
      link: routes.manage_clients,
    },
    report_management: {
      name: "Reports",
      id: `subMenuReportManagement`,
      icon: "",
      link: routes.transactionReport,
    },
  },
  monitoring: {
    service_professionals: {
      name: "Service Professionals",
      id: `subMenuServiceProfessionalMonitoring`,
      icon: "",
      link: routes.monitor_service_professionals,
    },
    clients: {
      name: "Clients",
      id: `subMenuClientsMonitoring`,
      icon: "",
      link: routes.monitor_clients,
    },
    subscription: {
      name: "Subscriptions",
      id: `subMenuSubscriptionMonitoring`,
      icon: "",
      link: routes.monitor_subscription,
    },
  },
};

const navBarTopObj = () => {
  return {
    dashboard: {
      id: 1,
      name: "Dashboard",
      button_id: `SideNavDashboard`,
      icon: <DashboardNewIcon />,
      selectedIcon: <DashboardNewIcon color="white" />,
      link: routes.dashboard,
      sub_menus: [],
    },
    management: {
      id: 2,
      name: "Management",
      button_id: `SideNavManagement`,
      icon: <ManagementNewIcon />,
      selectedIcon: <ManagementNewIcon color="#FFBE9F" />,
      link: routes.management,
      sub_menus: Object.values(navBarTopSubMens.management),
    },
    // partner_management: {
    //   id: 3,
    //   name: "Partner Management",
    //   icon: <MonitoringNewIcon />,
    //   selectedIcon: <MonitoringNewIcon color="#FFBE9F" />,
    //   link: routes.partner_management,
    //   sub_menus: Object.values(navBarTopSubMens.partner_management),
    // },
    // product_management: {
    //   id: 3,
    //   name: "Product Management",
    //   icon: <MonitoringNewIcon />,
    //   selectedIcon: <MonitoringNewIcon color="#FFBE9F" />,
    //   link: routes.product_management,
    //   sub_menus: Object.values(navBarTopSubMens.product_management),
    // },
    // resource_management: {
    //   id: 4,
    //   name: "Resource Management",
    //   icon: <AdministrationNewIcon />,
    //   selectedIcon: <AdministrationNewIcon color="white" />,
    //   link: routes.resource_management_list,
    //   sub_menus: [],
    // },
    // availability_management: {
    //   id: 5,
    //   name: "Availability Management",
    //   icon: <AdministrationNewIcon />,
    //   selectedIcon: <AdministrationNewIcon color="white" />,
    //   link: routes.availability_calendar_main + "day",
    //   sub_menus: [],
    // },
    // client_management: {
    //   id: 6,
    //   name: "Client Management",
    //   icon: <AdministrationNewIcon />,
    //   selectedIcon: <AdministrationNewIcon color="white" />,
    //   link: routes.manage_clients,
    //   sub_menus: [],
    // },
    monitoring: {
      id: 3,
      name: "Monitoring",
      button_id: `SideNavMonitorinng`,
      icon: <MonitoringNewIcon />,
      selectedIcon: <MonitoringNewIcon color="#FFBE9F" />,
      link: routes.monitoring,
      sub_menus: Object.values(navBarTopSubMens.monitoring),
    },
    administrator: {
      id: 4,
      name: "Administration",
      button_id: `SideNavAdministration`,
      icon: <AdministrationNewIcon />,
      selectedIcon: <AdministrationNewIcon color="white" />,
      link: routes.administration_masters,
      sub_menus: [],
    },

    form_configurator: {
      id: 5,
      name: "Form Configurator",
      button_id: `SideNavFormConfigurator`,
      icon: <AdministrationNewIcon />,
      selectedIcon: <AdministrationNewIcon color="white" />,
      link: routes.manage_forms,
      sub_menus: [],
    },
    sales_operation: {
      id: 6,
      name: "Sales Operations",
      button_id: `SideNavSalesOperation`,
      icon: <SalesOperationNewIcon />,
      link: routes.enquiry_list,
      selectedIcon: <SalesOperationNewIcon color="white" />,
      sub_menus: [],
    },
    // about: {
    //   id: 6,
    //   name: "About",
    //   icon: <AboutNewIcon />,
    //   selectedIcon: <AboutNewIcon color="white" />,
    //   sub_menus: [],
    // },
    // help: {
    //   id: 7,
    //   name: "Help",
    //   icon: <HelpNewIcon />,
    //   selectedIcon: <HelpNewIcon color="white" />,
    //   sub_menus: [],
    // },
  };
};

export const navBarTopSubMensBasedOnId = {
  partnerBranch: {
    name: "Branch Management",
    id: `BranchManagement`,
    icon: "",
    link: routes.partner_management_branch,
  },
  management: {
    resource_management: {
      name: "Resource Management",
      icon: "",
      link: routes.manage_care_coordinators,
    },

    product_management: {
      name: "Product Management",
      icon: "",
      link: routes.manage_service,
    },
    service_professional_management: {
      name: "Availability Management",
      icon: "",
      link: routes.availability_calendar_main + "day",
    },
    client_management: {
      name: "Client Management",
      icon: "",
      link: routes.manage_clients,
    },
    resource_management_coord: {
      name: "Resource Management",
      icon: "",
      link: routes.manage_care_givers,
    },
    report_management: {
      name: "Reports",
      id: `subMenuReportManagement`,
      icon: "",
      link: routes.transactionReport,
    },
  },
  monitoring: {
    service_professionals: {
      name: "Service Professionals",
      icon: "",
      link: routes.monitor_service_professionals,
    },
    clients: {
      name: "Clients",
      icon: "",
      link: routes.monitor_clients,
    },
    subscription: {
      name: "Subscriptions",
      icon: "",
      link: routes.monitor_subscription,
    },
  },
};
export const navBarTopOptions = (user_role, loginData, other_roles) => {
  switch (user_role) {
    case user_roles.super_admin:
      return Object.values(navBarTopObj());
    case user_roles.provider:
      let {
        dashboard,
        management,
        monitoring,
        sales_operation,
        // about, help,
      } = navBarTopObj();
      management.sub_menus = [];
      if (loginData?.data?.branch) {
        // check if the provider is not a branch
        management.sub_menus.push(navBarTopSubMensBasedOnId.partnerBranch);
      }

      management.sub_menus.push(
        navBarTopSubMens.management.resource_management,
        navBarTopSubMens.management.product_management,
        navBarTopSubMensBasedOnId.management.client_management,
        navBarTopSubMens.management.service_professional_management,
        navBarTopSubMensBasedOnId.management.report_management
      );
      // if (loginData?.data?.other_roles?.includes("cross-bundling")) {
      //   management.sub_menus.push(
      //     navBarTopSubMensBasedOnId.management.report_management
      //   );
      // }

      monitoring.sub_menus = [
        navBarTopSubMens.monitoring.service_professionals,
        navBarTopSubMens.monitoring.clients,
        navBarTopSubMens.monitoring.subscription,
      ];

      const sidebarMenus = [
        dashboard,
        management,
        monitoring,
        // about,
        // help
      ];

      if (other_roles.includes(OTHER_ROLES.crossBundling)) {
        sidebarMenus.push(sales_operation);
      }

      return sidebarMenus;

    case user_roles.supervisor:
      let sup_dashboard = navBarTopObj().dashboard;
      let sup_management = navBarTopObj().management;
      let sup_monitoring = navBarTopObj().monitoring;
      // let sup_about = navBarTopObj().about;
      // let sup_help = navBarTopObj().help;

      sup_management.sub_menus = [
        navBarTopSubMensBasedOnId.management.resource_management,
        navBarTopSubMensBasedOnId.management.product_management,
        navBarTopSubMensBasedOnId.management.client_management,
        navBarTopSubMensBasedOnId.management.service_professional_management,
      ];

      sup_monitoring.sub_menus = [
        navBarTopSubMensBasedOnId.monitoring.service_professionals,
        navBarTopSubMensBasedOnId.monitoring.clients,
        navBarTopSubMensBasedOnId.monitoring.subscription,
      ];

      return [
        sup_dashboard,
        sup_management,
        sup_monitoring,
        // sup_about,
        // sup_help
      ];

    case user_roles.service_coordinator:
      let sc_dashboard = navBarTopObj().dashboard;
      let sc_management = navBarTopObj().management;
      let sc_monitoring = navBarTopObj().monitoring;
      // let sc_about = navBarTopObj().about;
      // let sc_help = navBarTopObj().help;

      sc_management.sub_menus = [
        // navBarTopSubMens.management.persona_management,
        navBarTopSubMensBasedOnId.management.resource_management_coord,
        navBarTopSubMens.management.product_management,
        navBarTopSubMensBasedOnId.management.client_management,
        navBarTopSubMens.management.service_professional_management,
      ];

      sc_monitoring.sub_menus = [
        navBarTopSubMens.monitoring.service_professionals,
        navBarTopSubMens.monitoring.clients,
        navBarTopSubMens.monitoring.subscription,
      ];

      return [
        sc_dashboard,
        sc_management,
        sc_monitoring,
        // sc_about,
        // sc_help
      ];

    case user_roles.help_desk:
      let hd_sales_operation = navBarTopObj().sales_operation;
      let hd_monitoring = navBarTopObj().monitoring;
      let hd_management = navBarTopObj().management;
      hd_management.sub_menus = [
        navBarTopSubMens.management.product_management,
        navBarTopSubMens.management.service_professional_management,
      ];
      hd_monitoring.sub_menus = [
        navBarTopSubMens.monitoring.service_professionals,
        navBarTopSubMens.monitoring.clients,
        navBarTopSubMens.monitoring.subscription,
      ];
      return [hd_management, hd_monitoring, hd_sales_operation];

    case user_roles.business_admin:
      let ba_dashboard = navBarTopObj().dashboard;
      let ba_management = navBarTopObj().management;
      let ba_sales = navBarTopObj().sales_operation;

      ba_management.sub_menus = [];
      ba_management.sub_menus.push(
        navBarTopSubMens.management.persona_management,
        navBarTopSubMens.management.resource_management,
        navBarTopSubMens.management.product_management,
        navBarTopSubMens.management.report_management

        // navBarTopSubMensBasedOnId.management.client_management,
        // navBarTopSubMens.management.service_professional_management
      );
      return [ba_dashboard, ba_management, ba_sales];
    default:
      return [hd_sales_operation];
  }
};

export const navBarBottomOptions = {
  1: [
    // {
    //   id: 1,
    //   name: "Settings",
    //   icon: <SettingsNewIcon />,
    //   icselectedIconon: <SettingsNewIcon color="white" />,
    // },
    {
      id: 2,
      name: "Logout",
      icon: <LogoutNewIcon />,
      selectedIcon: <LogoutNewIcon color="white" />,
      link: routes.login,
    },
  ],
};
