import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import { SimpleSelect } from "./select";
import { Grid, Typography } from "@material-ui/core";
import { getMobileLimitBasedOnCC } from "../../../../utils/validations";
import  countryCode  from "../../../../utils/countryCode.json";
import PropTypes from "prop-types";

const BootstrapInput = withStyles((theme) => ({
  root: {
    width: "100%",
  },
  input: {
    position: "relative",
    width: "100%",
    padding: "9px 12px",
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    // margin: "8px 0px 8px 0px",
    marginBottom: 0,
    "& .MuiInputLabel-asterisk": {
      color: theme.palette.error.main,
    },
  },
  wrapper: (props) => ({
    border: `1px solid ${props?.errorValidation?.error ? 'red' : 'rgba(0, 0, 0, 0.23)'}`,
    borderRadius: 4,
    height: 40
  }),
  removeSpinInput: {
    '& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button': {
      margin: 0,
      display: 'none'
    }
  },

  label: {
    color: theme.palette.text.label,
  },
  borderRight: props => ({
    borderRight: `1px solid ${props?.errorValidation?.error ? 'red' : 'rgba(0, 0, 0, 0.23)'}`,
  }),
  errorText: {
    color: 'red',
    fontSize: "0.8rem",
  },
  switchRoot: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.ternary.main,
    },
    "& .MuiSwitch-track": {
      background: theme.palette.secondary.main,
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {

      background: "#80f10e",

    },
    "& .MuiSwitch-switchBase": {
      padding: 13,
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "white",
    },
    "&:not($checked) .MuiIconButton-label:after": {
      padding: 14,
    },
  },
  switch: {
    "& .MuiSwitch-track": {
      borderRadius: 13,
    },
    "& .MuiSwitch-thumb": {
      width: 12,
      height: 12,
    },

    marginLeft: 0,
    display: "flex",

    "& .MuiSwitch-root": { padding: 10 },
  },
  formControlLabel: {
    fontSize: "0.86rem",
    color: "#40444f",
  },
}));

export default function Mobile({ required, ...props }) {
  const classes = useStyles(props);

  const mobileNoValidation = (limitValue, inputLengthValue) => {
    if (limitValue) {
      if (limitValue === inputLengthValue?.length && props?.value?.number?.length) {
        setIsvalid(true);
        // return true;
      } else if (limitValue !== inputLengthValue?.length && props?.value?.number?.length) {
        setIsvalid(false);
        // return false;
      } else {
        setIsvalid(true);
      }
    }
  }


  const handleChangeSelect = (value = {}) => {
    let obj = {
      number: inputLength,
      phoneCode: value
    }
    props.handleChange(obj);

    setSelectValue(value);

    if (Object.keys(value).length > 0) {
      const limit = getMobileLimitBasedOnCC(value);
      setLimits(limit);
      mobileNoValidation(limits, inputLength);
      return limit
    }
    else {
      mobileNoValidation(null, inputLength);
    }
  }
  const handleChange = (e, limitVal, inputLengthVal) => {
    // console.log(e, limitVal, inputLengthVal);
    if (e?.length <= limitVal) {
      let obj = {
        number: e,
        phoneCode: selectValue
      }
      props.handleChange(obj);
      mobileNoValidation(limitVal, inputLengthVal);
    }

  }

  const [limits, setLimits] = React.useState(null);
  const [selectValue, setSelectValue] = React.useState({});
  const [isvalid, setIsvalid] = React.useState(true);


  let inputLength = document.getElementById(`${props?.data?.id}-input`)?.value;

  const blur = () => {
    mobileNoValidation(limits, inputLength);
  }

  React.useEffect(() => {
    mobileNoValidation(limits, inputLength);
    // eslint-disable-next-line
  }, [handleChangeSelect, handleChange, blur])

  const getLabel = (props) => {
    return <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom >{props?.label} {props?.isrequired && <Typography variant="caption" style={{ color: "red", marginLeft: "2px" }}>*</Typography>}</Typography>
  }

  return (
    <div className={classes.root} noValidate>
      {/* <InputLabel
        shrink
        htmlFor="bootstrap-input"
        className={classes?.label}
        required={required}
      >
        {props?.label}
        {props?.isrequired && <Typography variant="caption" style={{ color: "red", marginLeft: "2px" }}>*</Typography>}
      </InputLabel> */}
      {getLabel(props)}
      <FormControl>
        <div>
          <Grid container className={classes.wrapper}>
            <Grid
              item
              xs={5}
              sm={5}
              md={5}
              // lg={4}
              className={classes.borderRight}
            >
              <SimpleSelect
                {...props}
                className={classes.select}
                placeholder={"Country Code"}
                options={countryCode}
                idSelect={props.idSelect}
                handleChangeSelect={handleChangeSelect}
                value={props.value?.phoneCode}
                disabled={(props?.isReadonly || props?.disabled) ?? false}
                errorValidation={props?.errorValidation}
              />
            </Grid>
            <Grid
              item
              xs={7}
              sm={7}
              md={7}
              // lg={8}
              className={classes.Number}
            >
              <div className={classes.removeSpinInput}>
                <BootstrapInput
                  inputProps={{ maxLength: limits }}
                  type={"number"}
                  id={`${props?.data?.id}-input`}
                  disabled={(props?.isReadonly || props?.disabled) ?? false}
                  onInvalid={(e) => {
                    e.preventDefault();
                  }}
                  placeholder="Mobile No."
                  onChange={(e) => handleChange(e.target.value, limits, inputLength)}
                  value={props?.value?.number}
                  onBlur={blur}
                  error={props?.errorValidation && props?.errorValidation?.error ? props?.errorValidation?.error : false}
                />
              </div>

            </Grid>
          </Grid>
        </div>
        <Grid container direction={"row"}>
          {(isvalid === false && limits !== null && selectValue.length > 0) && (
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="caption"
                component="div"
                className={classes.errorText}
              >
                {"Please fill atleast " + limits + " No (Re-check the Number you have Entered)"}
              </Typography>
            </Grid>
          )
          }

          {props?.errorValidation?.error &&
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="caption"
                component="div"
                className={classes.errorText}
              >
                {((Object.keys(selectValue).length === 0 || inputLength === 0) && props?.errorValidation?.errorMessage) ? props?.errorValidation?.errorMessage : ''}
              </Typography>
            </Grid>
          }

        </Grid>
      </FormControl>
    </div>
  );
}



// Specifies the required props for the component:
Mobile.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
};
