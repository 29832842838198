import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function MobileIconV2(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.968 15.979v2.409a1.605 1.605 0 01-1.75 1.606 15.891 15.891 0 01-6.93-2.465 15.657 15.657 0 01-4.817-4.817A15.89 15.89 0 014.006 5.75 1.606 1.606 0 015.604 4h2.409a1.606 1.606 0 011.606 1.381c.102.77.29 1.528.562 2.256a1.606 1.606 0 01-.361 1.694l-1.02 1.02a12.846 12.846 0 004.817 4.817l1.02-1.02a1.606 1.606 0 011.694-.361c.728.272 1.485.46 2.256.562a1.606 1.606 0 011.381 1.63v0z"
        stroke="#0063E7"
        fill="#ffffff"
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default MobileIconV2;
