/* eslint-disable eqeqeq */
import React from 'react';
import { withAlertAndDialog } from '../../../HOCs';
import { Calender } from '../../../components';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import { SpeedDial } from '@material-ui/lab';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Clock from '../../../assets/AddEditScreenIcons/clock';
import { CalenderEditIcon } from '../../../assets';
import { withRouter } from 'react-router-dom';
import AddEditActivity from './addEditActivity/index';
import AddEditAvailability from './addEditAvailability/index';
import AvailabilityList from './availableList';
import ActivityList from './activityList';
import Filter from './filter';

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { routes } from '../../../router/routes';
import ActivitySummary from './activitySummary';
import AddEditRescheduleActivity from './addEditActivity/activityReschedule';
import ActivityLog from './addEditActivity/activityLog'

const styles = (theme) => ({
    root: {
        margin: '16px',
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04)",
        borderRadius: "4px",
        background: "#ffffff",
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        '& .MuiSpeedDialAction-staticTooltipLabel': {
            whiteSpace: 'nowrap',
            backgroundColor: 'black',
            color: 'white'
        },
        '& .MuiSpeedDialAction-fab': {
            background: theme.palette.ternary.main,
            "&:hover": {
                background: theme.palette.ternary.dark
            }
        },
        '& .MuiSpeedDial-fab': {
            background: theme.palette.ternary.main,
            "&:hover": {
                background: theme.palette.ternary.dark
            }
        }
    },
    fabIcon: {
        fill: 'white',
        fontSize: 10
    },
})

// const fabId = {
//     'ADD_ACTIVITY': "addActivityButtonFromViewActivities",
//     'ADD_AVAILABILITY': "addVailabilityButtonFromViewActivities"
// }

const fabId = {
    'ADD_ACTIVITY': 1,
    'ADD_AVAILABILITY': 2
}
class AvailabilityCalendar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            variant: this.props?.match?.params?.variant ?? 'day',

            currendDate: moment(),
            selectedDate: moment(),

            /* category: {
                label: 'All Category',
                value: 0
            }, */
            serviceProfessional: {
                label: 'All Service Professional',
                value: 0
            },

            selectedCategory:[],

            selectedProvider: 0,
            selectedServiceProfessional: 0,

            isFilterApplied: false,
            openFilter: false,

            hideShowFAB: false,
        }
    }

    //Getting the calendar data
    componentDidMount() {
        let provider = ''
        if(this.props.auth_data.data.user_role == 'provider_admin')
        {
            provider = this.props.auth_data.data.user_id
        }
        if(this.props.auth_data.data.user_role == 'provider_supervisor')
        {
            provider = this.props.auth_data.data.partnerId
        }
        this.getCalendarData();
        this.getAllPartner();
        this.getAllProfessionals({'provider': provider});
    }

    getAllProfessionals = async(provider='') => {
        let _ = await this.props.GET_ALL_CARE_GIVER(provider)
        _ = _?.payload?.data
        this.setState({serviceProfessional: _})
    }

    getAllPartner = async() => {
        //let _ = await this.props.GET_ALL_CARE_PROVIDER({
        let _ = await this.props.GET_ALL_CARE_PROVIDER_DROPDOWN({
            searchKey:"",
            parentPartnerId: ""
        })
        _ = _?.payload?.data
        _ = _?.map((x) => {
            return {
                value : x.id,
                label : x.name,
            }
        })
        this.setState({partner:_})
        // console.log(this.state.category)
    }

    //On Edit Activity/Availability
    /**
     * @param {any} selectedDate Current Selected Date
     * @param {Boolean} isActivity Whether it is an activity (Available options: Availability & Activity).
     * @param {Boolean} isEdit Whether it is an Edit reload (Available options: Add & Edit).
     */
    reload = (selectedDate, isActivity, isEdit) => {
        
        if (selectedDate) {
            this.setState({
                selectedDate
            }, () => {
                this.getCalendarData();
                setTimeout(() => {
                    this.scrollToCurrentTime()
                }, 3000);
            })
        } else {
            this.getCalendarData();
        }
        // if(isActivity)this.openSummary(isActivity, isEdit);
    }

    onBackBtnClicked = () => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: false
        })
    }

    openSummary = (isActivity, isEdit) => {
        
        if (isActivity) {   
            // let objArr = []
            // if(isEdit){
            //     objArr = this.props?.editActivity?.data?.data?.split('=')
            // }
            // else{
            //     if(Array.isArray(this.props?.addActivity?.data)){
            //         objArr.fill("",0,1)
            //         objArr[1] = this.props?.addActivity?.data[0]
            //     } else {
            //       objArr = this.props?.addActivity?.data?.split(':')
            //     }
            // }         
            let data = {
                activity_id: this.props?.addActivity?.data?.appointmentId
            }
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                component: <ActivitySummary
                    onBackBtnClicked={this.onBackBtnClicked}
                    onEditBtnClicked={this.onEditBtnClicked}
                    drawer={this.props.drawer}
                    data={data} />
            })
        }
    }

    scrollToCurrentTime = () => {
        const currentTime = document.getElementById(this.state.selectedDate.format('hh-A').toString());

        if (currentTime) currentTime.scrollIntoView({ block: "center" })
    }

    //Getting the calendar data function
    getCalendarData = () => {
            
        const {
            variant,
        } = this.state;

        let payload = {
            // req_role: 'cc'
        }

        const {
            from_datetime,
            to_datetime
        } = this.giveMeFromToTime(variant);

        // this.state.selectedServiceProfessional 
        // ? payload["serviceProfessional"] = this.state.selectedServiceProfessional
        // : payload["serviceProfessional"] = ""
        // this.state.selectedProvider 
        // ? payload["provider"] = this.state.selectedProvider 
        // : payload["provider"] = ""

        payload.from_datetime = from_datetime;
        payload.to_datetime = to_datetime;
        if(this.state.selectedProvider !== "")
        {
           // payload["id"] = this.state.selectedProvider;
            //payload["req_role"] =   'provider_admin'
        }
        if(this.state.selectedServiceProfessional !== "")
        {
            // payload["id"] = this.state.selectedServiceProfessional
          // payload["req_role"]=   'cc'
        }

        // console.log("Service Professional \n" + this.state.selectedServiceProfessional)
        // console.log("Provider \n"+this.state.selectedProvider)

        if(this.state.selectedServiceProfessional || this.state.selectedProvider)
        {
            payload.req_role = this.state.selectedServiceProfessional ? 'cc' : this.state.selectedProvider ? 'provider_admin' : 'cc';
            payload.id = this.state.selectedServiceProfessional ? this.state.selectedServiceProfessional : this.state.selectedProvider ;
        }
        

        this.props.GET_ALL_CALENDER_ACTIVITIES({
            // from_datetime,
            // to_datetime,
            ...payload,
            role: 'cc',
            // req_role: 'cc',
            // req_role: this.state.selectedServiceProfessional ? 'cc' : 'provider_admin',
            // id: this.state.selectedServiceProfessional ? this.state.selectedServiceProfessional : this.state.selectedProvider,
            timeline: variant,
            
        })
    }

    //Get the from and to time based on variant
    giveMeFromToTime = (variant) => {
        const {
            selectedDate,
        } = this.state;

        this.props.history.replace(routes.availability_calendar_main + variant);

        if (variant === 'day') {
            return {
                from_datetime: moment(selectedDate).startOf(variant),
                to_datetime: moment(selectedDate).endOf(variant)
            }
        } else if (variant === 'week') {
            return {
                from_datetime: moment(selectedDate).startOf(variant).add(1, 'day'),
                to_datetime: moment(selectedDate).endOf(variant).add(1, 'day')
            }
        } else if (variant === 'month') {
            return {
                from_datetime: moment(selectedDate).startOf(variant),
                to_datetime: moment(selectedDate).endOf(variant)
            }
        } else {
            return {
                from_datetime: moment(selectedDate).startOf(variant),
                to_datetime: moment(selectedDate).endOf(variant)
            }
        }
    }

    onFilterChange = (key, value, hitAPI) => {
        if(value){
            if(key == 'selectedProvider')
            {
                this.getAllProfessionals({'provider': value.value})
            }
            if(typeof(value) == 'object')
            {
                value = value.value
            }
            // console.log(value)
            this.setState({
                [key]: value
            }, () => {
                if (hitAPI) {
                    this.getCalendarData();
                }
            })
        }
            else{
                let provider = ''
                // console.log(this.state.selectedProvider)
                // console.log(value)
                if(this.state.selectedProvider)
                {
                    provider = this.state.selectedProvider
                }
                else
                {
                    if(this.props.auth_data.data.user_role == 'provider_admin')
                    {
                        provider = this.props.auth_data.data.user_id
                    }
                    if(this.props.auth_data.data.user_role == 'provider_supervisor')
                    {
                        provider = this.props.auth_data.data.partnerId
                    }
                }
                this.setState({
                    [key]: value
                }, () => {
                    if (hitAPI) {
                        this.getCalendarData();
                        this.getAllPartner();
                        this.getAllProfessionals({'provider': provider});
                    }
                })
            }
    }

    //Generice function to change the state which gets key and value
    onChange = (key, value, hitAPI) => {
        this.setState({
            [key]: value
        }, () => {
            if (hitAPI) {
                this.getCalendarData();
            }
        })
    }

    //On Next/Prev month clicked
    onNextPrevMonthBtnClicked = (isNext) => {
        let { selectedDate } = this.state;
        const today = moment()
        
        if (isNext) {
            let selected = moment(selectedDate)
            let isCurrentMonth = selected.add(1, 'M').get('M') === today.get('M')
            if(isCurrentMonth){
                selectedDate.add(1, 'M').set('date', today.get('date'));
            } else {
                selectedDate.add(1, 'M').startOf('M');
            }
        } else {
            let selected = moment(selectedDate)
            let isCurrentMonth = selected.subtract(1, 'M').get('M') === today.get('M')
            if(isCurrentMonth){
                selectedDate.subtract(1, 'M').set('date', today.get('date'));
            } else {
                selectedDate.subtract(1, 'M').startOf('M')
            }
        }
        
        this.setState({
            selectedDate
        }, () => {
            this.getCalendarData()
        })
    }

    //On Filter clicked
    onFilterClicked = () => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: <Filter {...this.props} reload={this.getCalendarData} />
        })
    }

    //On Availability card clicked
    onAvailabilityClicked = (data) => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: <AvailabilityList
                data={data}
                drawer={this.props.drawer}
                onEditBtnClicked={this.onEditBtnClicked}
                onAvailabilityClicked={this.onAvailabilityClicked}
                reload={this.getCalendarData}
                {...this.props} />
        })
    }

    //On Un Availability card clicked
    onUnAvailabilityClicked = (data) => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: <AvailabilityList
                isUnavailable={true}
                data={data}
                drawer={this.props.drawer}
                {...this.props}
                onEditBtnClicked={this.onEditBtnClicked}
                onUnAvailabilityClicked={this.onUnAvailabilityClicked}
                reload={this.getCalendarData}
            />
        })
    }

    //On Activity card clicked
    onActivityClicked = (data) => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: <ActivityList
                data={data}
                {...this.props}
                onEditBtnClicked={this.onEditBtnClicked}
                onActivityClicked={this.onActivityClicked} 
                onRescheduleBtnClicked={this.onRescheduleBtnClicked}
                activityLogBtnClicked={this.activityLogBtnClicked}
                reload={this.getCalendarData}
                />
        })
    }

    //On Edit Activity/Availability
    /**
     * @param {Number} selectedFabId ID of the fabIcon
     * @param {Object} data Required Data, usually the payload.
     */
    onEditBtnClicked = (selectedFabId, data) => {

        if (selectedFabId === fabId.ADD_ACTIVITY) {
           
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                component: <AddEditActivity {...this.props} data={data} reload={this.reload} isEdit={true} id={data?.activity_id} field_id={"EditActivity"}/>
            })
        } else if (selectedFabId === fabId.ADD_AVAILABILITY) {
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                component: <AddEditAvailability {...this.props} data={data} reload={this.reload} isEdit={true} id={data?.availability_id} field_id={"EditAvailability"}/>
            })
        }
    }

    onRescheduleBtnClicked = (selectedFabId, data) => {
        if (selectedFabId === fabId.ADD_ACTIVITY) {
           
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                // component: <AddEditActivity {...this.props} data={data} reload={this.reload} isEdit={true} id={data?.activity_id} field_id={"EditActivity"}/>
                component: <AddEditRescheduleActivity {...this.props} isReschedule={true} data={data} reload={this.reload} isEdit={true} id={data?.activity_id} field_id={"EditActivity"}/>
            })
        } else if (selectedFabId === fabId.ADD_AVAILABILITY) {
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                component: <AddEditAvailability {...this.props} data={data} isReschedule={true} reload={this.reload} isEdit={true} id={data?.availability_id} field_id={"EditAvailability"}/>
            })
        }
    }

    activityLogBtnClicked = (selectedFabId, data) => {
        if (selectedFabId === fabId.ADD_ACTIVITY) {
           
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                //component: <AddEditActivity reload={props.getClientActivities} data={data} isEdit={true} id={data?.activity_id} />
                component: <ActivityLog id={data?.activity_id} {...this.props}/>
            })
        } else if (selectedFabId === fabId.ADD_AVAILABILITY) {
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                component: <AddEditAvailability reload={this.reload} data={data} isEdit={true} id={data?.availability_id} />
            })
        }
    }

    //On Fab Icon clicked
    onFabIconClicked = (action) => {
        if (action.id === fabId.ADD_ACTIVITY) {
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                component: <AddEditActivity {...this.props} reload={this.reload} isEdit={false} field_id={"AddActivity"}/>
            })
        } else if (action.id === fabId.ADD_AVAILABILITY) {
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: true,
                component: <AddEditAvailability {...this.props} reload={this.reload} isEdit={false} field_id={"AddAvailability"}/>
            })
        }
    }

    render() {

        const {
            classes
        } = this.props;

        const actions = [
            { id: fabId.ADD_ACTIVITY, icon: <CalenderEditIcon className={classes.fabIcon} width="20px" height="20px" />, name: 'Add Activity' , button_id: "addActivityButtonFromViewActivities" },
            { id: fabId.ADD_AVAILABILITY, icon: <Clock className={classes.fabIcon} width="20px" height="20px" />, name: 'Mark Unavailability', button_id: "addVailabilityButtonFromViewActivities" }
        ];

        const {
            currendDate,
            selectedDate,
            partner,
            serviceProfessional,
            variant,
            isFilterApplied,
            hideShowFAB
        } = this.state;

        let { data, loading, error } = this.props?.getAllActivities;

        // console.log(this.props.auth_data?.data?.user_role)

        return <div className={classes.root}>
            <Calender

                calendarType="availability"
                from_id={"ViewAvailability"}
                hideFilters={false}
                currentDate={currendDate}
                selectedDate={selectedDate}
                onDateSelected={(e) => this.onChange('selectedDate', e, true)}

                user_role = {this.props.auth_data?.data?.user_role}

                partner={partner}
                serviceProfessional={serviceProfessional}

                variant={variant}
                onVariantChange={(e) => this.onChange('variant', e.target.value, true)}

                // selectedProvider = {this.state.selectedProvider}
                //  selectedServiceProfessional = {this.state.selectedServiceProfessional}

                onCategorySelected={(e) => {this.onFilterChange('selectedProvider', e, true)}}
                onServieProfessionalSelected={(e) => this.onFilterChange('selectedServiceProfessional', e, true)}

                onNextClicked={() => this.onNextPrevMonthBtnClicked(true)}
                onPrevClicked={() => this.onNextPrevMonthBtnClicked(false)}

                onFilterClicked={this.onFilterClicked}
                isFilterApplied={isFilterApplied}

                data={data}
                loading={loading}
                error={error}
                onRetry={this.getCalendarData}

                onActivityClicked={this.onActivityClicked}
                onAvailabilityClicked={this.onAvailabilityClicked}
                onUnAvailabilityClicked={this.onUnAvailabilityClicked}
            />
            {/* (Boolean(this.props?.auth_data?.data?.user_role !== user_roles.service_coordinator)) &&  */}
            {
                this.state.variant === "month" ? ( 
                    (moment().diff(selectedDate, 'months') == 0) &&
            <SpeedDial
                ariaLabel="Add Activity/Available button"
                className={classes.speedDial}
                id={"addActivityAvailabilityFromViewEnquiry"}
                icon={<SpeedDialIcon />}
                onClose={() => this.onChange('hideShowFAB', false)}
                onOpen={() => this.onChange('hideShowFAB', true)}
                open={hideShowFAB}
                onClick={() => this.onChange('hideShowFAB', true)}
            >
                {actions.map((action) => (
                    // console.log(action)
                    <SpeedDialAction
                        key={action.name}
                        
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        FabProps={{
                            color: "primary",
                            size: "large",
                            id:action.button_id
                        }}
                        onClick={() => this.onFabIconClicked(action)}
                    />
                ))}
            </SpeedDial>) : 
            ( 
                (moment().diff(selectedDate, 'days') <= 0) &&
        <SpeedDial
            ariaLabel="Add Activity/Available button"
            className={classes.speedDial}
            id={"addActivityAvailabilityFromViewEnquiry"}
            icon={<SpeedDialIcon />}
            onClose={() => this.onChange('hideShowFAB', false)}
            onOpen={() => this.onChange('hideShowFAB', true)}
            open={hideShowFAB}
            onClick={() => this.onChange('hideShowFAB', true)}
        >
            {actions.map((action) => (
                // console.log(action)
                <SpeedDialAction
                    key={action.name}
                    
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    FabProps={{
                        color: "primary",
                        size: "large",
                        id:action.button_id
                    }}
                    onClick={() => this.onFabIconClicked(action)}
                />
            ))}
        </SpeedDial>)
            }
          {/* {(moment().diff(selectedDate, 'days') <= 0) &&
            <SpeedDial
                ariaLabel="Add Activity/Available button"
                className={classes.speedDial}
                id={"addActivityAvailabilityFromViewEnquiry"}
                icon={<SpeedDialIcon />}
                onClose={() => this.onChange('hideShowFAB', false)}
                onOpen={() => this.onChange('hideShowFAB', true)}
                open={hideShowFAB}
                onClick={() => this.onChange('hideShowFAB', true)}
            >
                {actions.map((action) => (
                    // console.log(action)
                    <SpeedDialAction
                        key={action.name}
                        
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        FabProps={{
                            color: "primary",
                            size: "large",
                            id:action.button_id
                        }}
                        onClick={() => this.onFabIconClicked(action)}
                    />
                ))}
            </SpeedDial>} */}
        </div>
    }
}

AvailabilityCalendar.propTypes = {
};

AvailabilityCalendar.defaultProps = {
};
const mapStateToProps = (state) => ({
    getAllActivities: state?.cg_cc_calender?.getAllActivities,
    addActivity: state?.activity_app?.add_activity,
    editActivity: state?.activity_app?.edit_activity,
    auth_data: state?.authState?.loginAuth,
});

export default connect(mapStateToProps, actions)(withStyles(styles)(
    withAlertAndDialog(
        withRouter(
            AvailabilityCalendar
        )
    )
)
);