import { createTestProps } from "../../../../utils/common";
import { filterType } from "../../../../utils";

export const filterComponent = (state, props) => {
  let id = state?.id;
  return {
    section_1: {
      label: "Filter By",
      fields: {
        status: {
          key: "status",
          id: createTestProps(`select banner using status filter`, id).id,
          type: filterType.STATUS,
          component: "SimpleSelect",
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          label: "STATUS",
          value: state.status,
        },
        createdBy: {
          key: "createdBy",
          id: createTestProps(`select banner using createdBy filter`, id).id,
          type: filterType.CREATED_BY,
          breakPoints: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
          label: "Created By",
          value: state.createdBy,
        },
      },
    },
  };
};
