import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const styles = makeStyles((theme) => ({
    circle: props => ({
        width: 40,
        height: 40,
        borderRadius: '50%',
        position: 'relative',
        backgroundColor: props?.value ? props?.value : '#000',
        cursor: 'pointer',
        border: '1px solid',
        '& input': {
            position: 'absolute',
            cursor: 'pointer',
            inset: 0,
            opacity: 0
        }
    }),
    text: {
        marginTop: theme.spacing(3),
        '& .MuiTypography-root': {
            fontSize: 12,
            color: '#6F6F6F',
            fontWeight: 400
        }
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}));

export const ColorCodes = (props) => {

    const classes = styles(props);

    const {
        value = "",
        text = "",
        handleChange = null,
        id = "",
    } = props;

    return (
        <div className={classes.root}>
            <div className={classes.circle}>
                <input
                    id={id}
                    type="color"
                    value={value}
                    onChange={e => handleChange(e.target.value)}
                />
            </div>
            {text &&
                <div className={classes.text}>
                    <Typography>{text}</Typography>
                </div>
            }
        </div>
    )
}

ColorCodes.propTypes = {
    value: PropTypes.string,
    text: PropTypes.string,
    handleChange: PropTypes.func,
    id: PropTypes.string,
}