import {
    TableRow,
    withStyles,
  } from "@material-ui/core";


export const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: "rgba(0,0,0, 0.03)",
      },
    },
  }))(TableRow);