import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Button } from '@material-ui/core'
// Grid
import { HybridTypography } from '../hybridTypography'
import { ICONS } from '../hybridTypography/typography'
import { customAddress, formComponentTypes } from '../../utils';
import withAlelrtAndDialog from '../../HOCs/withAlelrtAndDialog';
import ViewAllCards from './viewAllCards';

class ViewEnquiryAddress extends Component {
    customConverter = (data) => {

        if (data) {

            switch (this.props.element) {
                case formComponentTypes.ADD_ADDRESS:
                    return customAddress(
                        data.addressLine1,
                        data.addressLine2,
                        data.area,
                        data.state,
                        data.country,
                        data.postalCode
                    )
                case formComponentTypes.ADD_EMAIL:
                    return data.email
                case formComponentTypes.ADD_PHONE_NUMBER:
                    return `${data.mobileNoIsdCode} ${data.mobileNo}`
                case formComponentTypes.ADD_ID_TYPE:
                    return data.id_type
                default: return <></>
            }
        }
        else {
            return 'N/A'
        }
    }

    handleOpenMoreDrawer = () => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: (
                <ViewAllCards
                    isTypeId={formComponentTypes.ADD_ID_TYPE === this.props.element}
                    elementType={this.props.element}
                    items={this.props.data}
                />
            ),
        });
    }


    render() {
        const { data, label, nolabel, nolabelColor, labels } = this.props;
        return (
            <>
                <HybridTypography
                    icon={ICONS.BUSINESS(nolabel ? nolabelColor ? nolabelColor : "white" : "#28a9c1")}
                    nolabel={nolabel}
                    text={
                        <>
                            <div style={{ display: "flex" }}>
                                {this.customConverter(data?.[0])}
                                {(nolabel && <span onClick={() => { this.handleOpenMoreDrawer() }} style={{ padding: 0, marginLeft: 10, cursor: "pointer", textTransform: "capitalize" }}>View More</span>)}
                            </div>
                            {(!nolabel && data?.length > 1) && <Button variant="text" color="primary" style={{ padding: 0, marginLeft: 10 }} onClick={() => { this.handleOpenMoreDrawer() }}>View More</Button>}
                        </>
                    }
                    header={label ? (`${label}`).toUpperCase() : labels ? (`${labels}`).toUpperCase() : nolabel ? "" : "N/A"}
                    // loading={loading}
                    islistbottom={nolabel ? false : true}
                    islisttop={nolabel ? false : true}
                />

            </>
        )
    }
}

ViewEnquiryAddress.propTypes = {

};

export default withAlelrtAndDialog(ViewEnquiryAddress);


