import * as React from "react"

export function CurrentLocation(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" fill={props.color ? props.color : "#28a9c1"} {...props}>
      <g data-name="Current Location">
        <path
          d="M18 0a4 4 0 00-4 4c0 2.857 4 7 4 7s4-4.143 4-7a4 4 0 00-4-4zm0 5.429A1.429 1.429 0 1119.429 4 1.429 1.429 0 0118 5.429z"
          className="prefix__cls-1"
          data-name="Path 713"
        />
        <path
          d="M19.99 11.793a27.82 27.82 0 01-.551.6L17.65 14.3a8.062 8.062 0 01-.836 1.866A2 2 0 0015 15h-1v-2a1 1 0 00-1-1H7v-2h1a1 1 0 001-1V7.023l2.015-.016a1.984 1.984 0 001.466-.67A8.136 8.136 0 0112.1 5l-4.112.031a1 1 0 00-.992 1V8H6a1 1 0 00-1 1v1.186L2.981 8.167A8 8 0 0110 4h2a5.942 5.942 0 01.258-1.733A10 10 0 1020 12c0-.07-.009-.138-.01-.207zM9 19.931a7.965 7.965 0 01-6.793-9.711L7 15.014V16a2 2 0 002 2zm6-1.7a7.952 7.952 0 01-4 1.695V17a1 1 0 00-1-1H9v-1.4a.99.99 0 00-.221-.6H12v2a1 1 0 001 1h2z"
          className="prefix__cls-1"
          data-name="Path 714"
        />
      </g>
    </svg>
  )
}

export default CurrentLocation
