import React from "react";
import {
  Button,
  Grid,
  IconButton,
  Typography,
  withStyles,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
// import ActivityAndMembersCard from "../../components/availabilityActivityComponents/ActivityAndMembersCard";
import ProfileCardActivitySummary from "../../components/availabilityActivityComponents/activitySummaryCards/ProfileCardActivitySummary";
import RequestProfileCardActivitySummary from "../../components/availabilityActivityComponents/activitySummaryCards/RequestProfileCardActivitySummary";
// import CheckInCheckOutCard from '../../components/availabilityActivityComponents/activitySummaryCards/checkInCheckOutCard'
// import SummaryTextCard from '../../components/availabilityActivityComponents/activitySummaryCards/summaryTextCard'
import moment from "moment";
// import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LoadingSection } from "../../components/integerateHelper/loading";
import { InternatlServerError } from "../../components/integerateHelper/500";
// import { colorCode } from "../../components/notifications/ActivityList";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
import { alertProps } from "../../utils";
import { loadScript } from "../../components/map/loadScriptFile";
import { TextField } from "../../components";
// import { SwitchComponent } from '../../components/switch';
// import { RadioButtonGroup } from 'material-ui';
// import { Radio } from '@material-ui/icons';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from '@material-ui/core/FormControl';
import FormLabel from "@material-ui/core/FormLabel";
import { DateTimePickersCustom } from "../../components";
import { metaType } from "../../utils/index";
import { InputAdornmentComponent } from "../../components/inputs/inputAdornments";

const styles = (theme) => ({
  icon: {
    width: 20,
    height: 20,
  },
  cancelButton: {
    boxShadow: "unset !important",
  },
});

const filter_options = [
  {
    label: "Percentage",
    value: true,
    id: "All",
  },
  {
    label: "Amount",
    value: false,
    id: "Cancel",
  },
];

const CustomRadio = withStyles((theme) => ({
  root: {
    color: theme.palette.ternary.main,
    "&$checked": {
      color: theme.palette.ternary.dark,
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

class SubscriptionRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientLocation: null,
      addressLoading: false,
      refund_type: true,
      set_error: false,
      final_amount: 0,
      percentage_amount: "",
      btn_loading: false,
    };
  }

  handleScriptLoad = (latitude, longitude) => {
    // Google Location - Geocoder API
    const geocoder = new window.google.maps.Geocoder();
    const latlng = {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          //   results[0].formatted_address;
          this.setState({
            addressLoading: false,
            clientLocation: results[0].formatted_address,
          });
        } else {
          this.setState({
            clientLocation: null,
          });
        }
      } else {
        this.setState({
          clientLocation: null,
        });
      }
    });
  };

  componentDidMount = () => {
    this.getSummaryDetail();
  };

  getSummaryDetail = async () => {
    // const activityID = this.props.data;
    await this.props.GET_SUBSCRIPTION_DETAILS({
      subscription_id: this.props.subscriptionId,
    });
    // const { data } = this.props?.summaryDetail;
    // if (data?.client_info?.latitude && data?.client_info?.longitude) {
    //   this.getLocationFromLatlong();
    // }
  };

  getLocationFromLatlong = () => {
    this.loadGoogleApi();
  };

  loadGoogleApi = () => {
    const { data } = this.props?.summaryDetail;
    const latitude = data?.client_info?.latitude;
    const longitude = data?.client_info?.longitude;
    if (
      latitude &&
      longitude &&
      latitude > -90 &&
      latitude < 90 &&
      longitude > -180 &&
      longitude < 180
    ) {
      this.setState(
        {
          addressLoading: true,
        },
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=AIzaSyBqeACJgp12OLU6EkHeUtFgya2naH1LhrU&callback=initMap&libraries=&v=weekly`,
          () => this.handleScriptLoad(latitude, longitude)
        )
      );
    }
  };

  closeDrawer = async () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
    await this.props.getSpecficData();
    // this.props.changeRequest(false, {target: {value: "cancel_request"}})
  };

  handleActivityDelete = async (activityId) => {
    if (
      this.state.final_amount <
      parseInt(this.props?.sub_details?.data?.subscription_amount)
    ) {
      this.setState({ btn_loading: true });
      if (!this?.props?.isReschedule) {
        if (this.state?.checked === "No") {
          // reject activity
          const param = {
            partnerId:
              this.props?.sub_details?.data?.service_coord?.[0]?.provider
                ?.provider_id,
            clientId: this.props?.sub_details?.data?.client_info?.client_id,
            subscriptionId: this.props.subscriptionId,
            remarks: this.state?.cancelation_reason,
            amount: this.state?.final_amount,
            status: "refund_not_approved",
            //   isAdd
          };

          // alert("Hi")

          await this.props.UPDATE_SUBSCRIPTION_REFUND_STATUS(param);
          this.closeDrawer();
        } else {
          //approve
          const param = {
            partnerId:
              this.props?.sub_details?.data?.service_coord?.[0]?.provider
                ?.provider_id,
            clientId: this.props?.sub_details?.data?.client_info?.client_id,
            subscriptionId: this.props.subscriptionId,
            remarks: "",
            status: "refund_approved",
            amount: this.state?.final_amount,
            // isAdd
          };

          // console.log(this.props?.sub_details?.data)
          // alert(this.props?.sub_details?.data?.transaction_id)

          let res_data = await this.props.REFUND_PAYMENT_REFUND({
            amount: this.state.final_amount,
            transaction_id: this.props?.sub_details?.data?.transaction_id,
            reason: this.state?.cancelation_reason
              ? this.state?.cancelation_reason
              : "Testing",
          });
          if (
            res_data?.payload?.data?.status === "succeeded" ||
            res_data?.payload?.data?.status === "pending"
          ) {
            await this.props.UPDATE_SUBSCRIPTION_REFUND_STATUS(param);
            this.closeDrawer();
          } else {
            this.setState({ btn_loading: false });
            this.props.alert.setSnack({
              open: true,
              horizontal: alertProps.horizontal.center,
              vertical: alertProps.vertical.top,
              msg:
                res_data?.payload?.data?.status === "failed"
                  ? "Your refund request is failed, due to customer's bank or card issuer unable to process it"
                  : res_data?.payload?.data?.error ||
                    res_data?.payload?.data?.message ||
                    "Something went wrong. Please try again later",
              severity: alertProps.severity.error,
            });
          }

          // this.props.dialog.setDialog({
          //   open: true,
          //   title: "Confirmation",
          //   body: "Are you sure, you want to cancel this Activity?",
          //   positiveBtn: "Confirm",
          //   negativeBtn: "Cancel",
          //   onOk: () => {
          //     this.props.dialog.setDialog({ open: false });
          //     this.props.backDrop.setBackDrop({
          //       ...this.props.backDrop,
          //       open: true,
          //       message: "Deleting Activity...",
          //     });
          //     this.props.DELETE_ACTIVITY({ activityId: this.props?.summaryDetail?.data?.activity_id });
          //   },
          // });
        }
      } else {
        if (this.state?.checked === "No") {
          // reject activity
          const param = {
            partnerId:
              this.props?.sub_details?.data?.service_coord?.[0]?.provider
                ?.provider_id,
            clientId: this.props?.sub_details?.data?.client_info?.client_id,
            subscriptionId: this.props.subscriptionId,
            remarks: this.state?.cancelation_reason,
            status: "refund_not_approved",
            amount: this.state?.final_amount,
          };
          await this.props.UPDATE_SUBSCRIPTION_REFUND_STATUS(param);
          this.closeDrawer();
        } else {
          const param = {
            partnerId:
              this.props?.sub_details?.data?.service_coord?.[0]?.provider
                ?.provider_id,
            clientId: this.props?.sub_details?.data?.client_info?.client_id,
            subscriptionId: this.props.subscriptionId,
            remarks: "",
            status: "refund_approved",
            amount: this.state?.final_amount,
          };

          let res_data = await this.props.REFUND_PAYMENT_REFUND({
            amount: this.state.final_amount,
            transaction_id: this.props?.sub_details?.data?.transaction_id,
            reason: this.state?.cancelation_reason
              ? this.state?.cancelation_reason
              : "Testing",
          });
          if (
            res_data?.payload?.data?.status === "succeeded" ||
            res_data?.payload?.data?.status === "pending"
          ) {
            await this.props.UPDATE_SUBSCRIPTION_REFUND_STATUS(param);
            this.closeDrawer();
          } else {
            this.setState({ btn_loading: false });
            this.props.alert.setSnack({
              open: true,
              horizontal: alertProps.horizontal.center,
              vertical: alertProps.vertical.top,
              msg:
                res_data?.payload?.data?.status === "failed"
                  ? "Your refund request is failed, due to customer's bank or card issuer unable to process it"
                  : res_data?.payload?.data?.error ||
                    res_data?.payload?.data?.message ||
                    "Something went wrong. Please try again later",
              severity: alertProps.severity.error,
            });
          }

          const payload = {
            activityId: this.props?.summaryDetail?.data?.activity_id,
            title: this.props?.summaryDetail?.data?.activity_name,
            cliendId: this.props?.summaryDetail?.data?.client_id,
            serviceprofessionalId:
              this.props?.summaryDetail?.data?.service_professional_info.id,
            activity_type_id:
              this.props?.summaryDetail?.data?.activity_type.value,
            activityTypeName:
              this.props?.summaryDetail?.data?.activity_type.label,
            // from_timestamp: moment(data.from_timestamp).set("second", "00"),
            // to_timestamp: moment(data.to_timestamp).set("second", "00"),
            resourceId:
              this.props?.summaryDetail?.data?.service_professional_info.id,
            occurence: this.props?.summaryDetail?.data?.occurence,
            occurence_type: this.props?.summaryDetail?.data?.occurence,
            repeat_on: this.props?.summaryDetail?.data?.occurence,
            from_timestamp: new Date(
              new Date(this.props.dataSum?.from_date)?.setSeconds(0)
            ),

            // this.getFromTimeStampPayload(data),

            to_timestamp: new Date(
              new Date(this.props.dataSum?.to_date)?.setSeconds(0)
            ),

            //  this.getToTimeStampPayload(data),

            // occurence_type: data?.occurence_type ? data?.occurence_type : "",
            mode: this.props?.summaryDetail?.data?.mode
              ? this.props?.summaryDetail?.data?.mode
              : "VIDEO_CALL",
            videoCallUrl: this.props?.summaryDetail?.data?.videoCallUrl
              ? this.props?.summaryDetail?.data?.videoCallUrl
              : "",
            description: this.props?.summaryDetail?.data.activity_description,
            selectedVenue: this.props?.summaryDetail?.data?.selectedVenue,
            address: this.props?.summaryDetail?.data?.address
              ? this.props?.summaryDetail?.data?.address
              : null,
            longitude: this.props?.summaryDetail?.data?.longitude
              ? this.props?.summaryDetail?.data?.longitude?.toString()
              : null,
            latitude: this.props?.summaryDetail?.data?.latitude
              ? this.props?.summaryDetail?.data?.latitude?.toString()
              : null,
            subscriptionId: this.props?.summaryDetail?.data?.subscription?.value
              ? this.props?.summaryDetail?.data.subscription.value
              : "",
            subscriptionType: this.props?.summaryDetail?.data?.subscription
              ?.type_id
              ? this.props?.summaryDetail?.data?.subscription?.type_id
              : "",
            serviceId: this.props?.summaryDetail?.data?.service?.value
              ? this.props?.summaryDetail?.data?.service?.value
              : "",
          };
          // console.log(
          //   " 🛺payload_EDITACTIVITY---------------------------- ",
          //   payload
          // );
          console.log("------------------------------------------\n");
          console.log(payload);
          this.props.EDIT_ACTIVITY({ ...payload });

          this.closeDrawer();
        }
      }
    } else {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Amount is Greater than Subscription Amount",
        severity: alertProps.severity.error,
      });
    }
  };

  shouldComponentUpdate(nextProps) {
    // Delete Activity
    const { subscription_status } = nextProps;
    const oldsubscription_status = this.props.subscription_status;

    if (oldsubscription_status?.loading !== subscription_status?.loading) {
      if (!subscription_status.loading && !subscription_status.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Request Updated Successfully!",
          severity: alertProps.severity.success,
        });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
        });
        // TODO:RELOAD and CLOSE DRAWER
        // this.props.reload();
        this.closeDrawer();
      } else if (subscription_status.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Delete Activity.",
          severity: alertProps.severity.error,
        });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
        });
      }
    }

    return true;
  }

  handleDisableApproveButton = () => {
    if (this.state?.checked === "No" && this.state?.cancelation_reason) {
      return false;
    } else if (
      this.state.btn_loading ||
      (!this.state.final_amount &&
        !this.state.percentage_amount &&
        Number(this.state.final_amount) === 0 &&
        this.state.percentage_amount?.length === 0)
    ) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    const { hideServiceProfessional, hideClient, classes } = this.props;

    const { data, loading, error } = this.props?.sub_details;

    return (
      <>
        {loading && (
          <LoadingSection message="Loading Subscription summary..." />
        )}
        {!loading && error && (
          <InternatlServerError
            message="Something went wrong, Try Again!"
            onRetry={this.getSummaryDetail}
          />
        )}
        {!loading && !error && !data && (
          <InternatlServerError
            message="Something went wrong, Try Again!"
            onRetry={this.getSummaryDetail}
          />
        )}
        {!loading && !error && data && (
          <>
            <Grid container>
              <Grid
                item
                container
                style={{ padding: "12px 12px 12px 0px", background: "white" }}
              >
                <Grid item>
                  <IconButton onClick={this.props.onBackBtnClicked}>
                    {/* <ChevronLeft /> */}
                  </IconButton>
                </Grid>
                <Grid item xs>
                  <Typography variant="h6" style={{ marginTop: 8 }}>
                    Refund Request
                  </Typography>
                </Grid>
              </Grid>

              {/* {console.log(data)} */}
              {/* <ActivityAndMembersCard
                hideProfilePicture
                title={data?.subscription_name}
                // date={moment(data?.from_time_stamp).format('dddd, MMMM D')}
                timing={`Cancelled on - ${moment(data?.requested_time).format(
                  "Do MMM hh:mm A"
                )}`}
                hideIsActive
                activityStatus={data?.requestChange} //activity_type will be a object with {value, label}
                activityStatusColor="#28a9c1"
                currentStatus={data?.activity_status}
                currentStatusColor={"#28a9c1"}
                // useActivityAsSummaryCard
                hideEndIcon
                padding={2}
              /> */}
              <RequestProfileCardActivitySummary
                profilePicture={data?.subscription_picture}
                name={data?.subscription_name}
                type={data?.subscription_type}
                gender={`Cancelled on - ${moment(data?.requested_time).format(
                  "Do MMM hh:mm A"
                )}`}
                age={`MYR: ${data.subscription_amount}`}
                purchase_date={`Purchased on - ${moment(
                  data?.subscribed_on
                ).format("Do MMM hh:mm A")}`}
                address={this.state.clientLocation}
                //videoCallUrl
                meetingUrl={
                  data?.mode === "VIDEO_CALL" ? data?.videoCallUrl : false
                }
                addressLoading={this.state.addressLoading}
                padding={2}
                hideQuickIcons
                hideAddressSection={true}
              />

              <div
                style={{
                  display: "block",
                  width: "100%",
                  padding: "16px 16px 0px",
                }}
              >
                <Typography
                  variant="caption"
                  color="textSecondary"
                  gutterBottom
                >
                  CANCELLED ON :{" "}
                  <Typography
                    variant="caption"
                    style={{ fontSize: 15, color: "#363a57", fontWeight: 400 }}
                  >
                    {`${moment(data?.requested_time).format("Do MMM hh:mm A")}`}
                  </Typography>
                </Typography>
              </div>

              {!hideClient && (
                <div style={{ display: "block", padding: "16px 16px 0px" }}>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                  >
                    REQUESTED BY:
                  </Typography>
                  <ProfileCardActivitySummary
                    profilePicture={data?.client_info?.client_profile_picture}
                    name={data?.client_info?.client_name}
                    gender={data?.client_info?.client_gender}
                    //   age={data?.client_info?.client_age}
                    address={this.state.clientLocation}
                    //videoCallUrl
                    meetingUrl={
                      data?.mode === "VIDEO_CALL" ? data?.videoCallUrl : false
                    }
                    addressLoading={this.state.addressLoading}
                    padding={2}
                    hideQuickIcons
                    hideAddressSection={true}
                  />
                </div>
              )}

              {!hideServiceProfessional && (
                <div style={{ display: "block", padding: "16px 16px 0px" }}>
                  {console.log(data?.activity_cancellation_reason)}
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                  >
                    ASSIGNED COORDINATOR:
                  </Typography>

                  {data?.service_coord?.[0]?.name ? (
                    <ProfileCardActivitySummary
                      profilePicture={data?.service_coord?.[0]?.profile_pic}
                      name={data?.service_coord?.[0]?.name}
                      gender={data?.service_coord?.[0]?.gender}
                      // age={data?.service_coord?.[0]?.gender}
                      address={this.state.clientLocation}
                      //videoCallUrl
                      meetingUrl={
                        data?.mode === "VIDEO_CALL" ? data?.videoCallUrl : false
                      }
                      addressLoading={this.state.addressLoading}
                      padding={2}
                      hideQuickIcons
                      hideAddressSection={true}
                    />
                  ) : (
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      gutterBottom
                      style={{ paddingTop: 16 }}
                    >
                      Unassigned
                    </Typography>
                  )}
                </div>
              )}

              <Grid item xs={12}>
                {!this?.props?.isReschedule ? (
                  <>
                    <div style={{ display: "block", padding: 16 }}>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        gutterBottom
                      >
                        REASON:
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        style={{ wordWrap: "break-word" }}
                      >
                        {/* {data?.activity_cancellation_reason} */}
                        {data?.activity_cancellation_reason !== "" ||
                        data?.activity_cancellation_reason !== null ||
                        data?.activity_cancellation_reason !== undefined
                          ? data?.activity_cancellation_reason
                          : "N/A"}
                      </Typography>
                    </div>

                    {/* <SwitchComponent
                        data={""}
                        id={'switch_component'}
                        size={'sm'}
                        checked={this.state.checked}
                        //  handleOnChange={component.handleChange}
                        handleOnChange={(newValue) => {
                          this.setState({checked: !this.state?.checked});
                        }}
                        label={"Do you wish to Reject ?"}
                        fixMargin={false}
                    /> */}
                    {/* <Grid container> */}
                    <div
                      style={{
                        padding: "12px 16px 12px 16px",
                        background: "white",
                      }}
                    >
                      <FormLabel component="legend">
                        {this?.props?.isReschedule
                          ? "Do you approve this refund ?"
                          : "Do you approve this refund?"}
                      </FormLabel>
                      <RadioGroup
                        aria-label="approve"
                        style={{ flexDirection: "row" }}
                        name="approve"
                        value={this.state.checked || "Yes"}
                        onChange={(event) => {
                          this.setState({ checked: event?.target.value });
                        }}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<CustomRadio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<CustomRadio />}
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                    {/* </Grid> */}

                    {this.state?.checked === "No" ? (
                      <Grid container>
                        <Grid
                          item
                          container
                          style={{
                            padding: "12px 12px 12px 12px",
                            background: "white",
                          }}
                        >
                          <TextField
                            id={`reasonForCancellation`}
                            required={true}
                            type={"text"}
                            onlyText={false}
                            rowsMax={5}
                            multiline={true}
                            title={"Reason For Reject"}
                            placeholder={"Reason For Reject"}
                            defaultValue=""
                            value={this.state.cancelation_reason}
                            handleOnChange={(e) => {
                              console.log(e.target?.value);
                              this.setState({
                                cancelation_reason: e.target?.value,
                              });
                            }}
                            error={false}
                            errorText={"Reason Needed"}
                            disabled={false}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <div style={{ padding: 16 }}>
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              value={this.state.refund_type}
                              onChange={() =>
                                this.setState({
                                  percentage_amount: "",
                                  final_amount: "",
                                  refund_type: !this.state.refund_type,
                                })
                              }
                              style={{
                                height: 50,
                                // width: 200,
                                overflow: "hidden",
                              }}
                            >
                              {filter_options?.map((item, index) => {
                                return (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          {this.state.refund_type ? (
                            <>
                              <TextField
                                id={`reasonForCancellation`}
                                required={true}
                                type={"text"}
                                onlyText={false}
                                rowsMax={1}
                                multiline={true}
                                title={"Percentage of Amount"}
                                placeholder={"Percentage of Amount"}
                                defaultValue=""
                                value={this.state.percentage_amount}
                                handleOnChange={(e) => {
                                  console.log(e.target.value);
                                  if (
                                    parseInt(e.target.value) < 100 ||
                                    e.target.value === ""
                                  ) {
                                    this.setState({
                                      percentage_amount: e.target?.value,
                                      final_amount:
                                        (data?.subscription_amount *
                                        (parseInt(e.target.value) / 100)).toFixed(2),
                                    });
                                  }
                                }}
                                error={false}
                                errorText={"Percentage Needed"}
                                disabled={false}
                              />
                              {this.state.percentage_amount ? (
                                <Typography variant="body1">{`The Refund Amount will be - ${`MYR ${(
                                  data?.subscription_amount *
                                  (parseInt(this.state.percentage_amount) / 100)
                                ).toFixed(2)}`}`}</Typography>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              <InputAdornmentComponent
                                // id={component?.componentProps?.id}
                                // select_id={component?.componentProps?.select_id}
                                meta={metaType.currency}
                                required={true}
                                title={"Amount to be Refunded"}
                                options={[
                                  {
                                    label: "MYR",
                                    value: "MYR",
                                    symbol: "RM",
                                    name: "Malaysian Ringgit",
                                    symbol_native: "RM",
                                    decimal_digits: 2,
                                    rounding: 0,
                                    code: "MYR",
                                    name_plural: "Malaysian ringgits",
                                  },
                                ]}
                                type={"number"}
                                // onlyText={component?.onlyText}
                                disabled={false}
                                disableDropdown={true}
                                // customsize={component?.customsize}
                                // codeSize={component?.componentProps?.codeSize}
                                helperFlagNeeded={false}
                                helperFlagControllers={""}
                                valueMObileNo={this.state.percentage_amount}
                                valuePhoneCode={"MYR"}
                                handleChangeSelect={(e) => {}}
                                handleMobileNo={(e) => {
                                  // if (
                                  //   parseFloat(e.target.value) <
                                  //   parseFloat(data?.subscription_amount)
                                  // ) {
                                  this.setState({
                                    percentage_amount: e.target?.value,
                                    final_amount: parseFloat(e.target?.value).toFixed(2),
                                    set_error:
                                      parseFloat(e.target?.value) <
                                      parseFloat(data?.subscription_amount),
                                  });
                                  // }
                                }}
                                error={false}
                                errorText={
                                  "Amount Should Not Be greater than the subscription amount"
                                }
                              />
                            </>
                          )}
                        </div>
                      </>
                    )}
                    <Grid container>
                      <Grid
                        item
                        container
                        style={{
                          padding: "12px 16px 12px 16px",
                          background: "white",
                        }}
                        justify={"flex-end"}
                      >
                        <div className={classes.saveBtnContainer}>
                          {/* {
            !this.state.openDrawer.open && */}
                          <Button
                            variant={"outlined"}
                            color={"primary"}
                            onClick={this.closeDrawer}
                            style={
                              this.props.cancelButtonStyles
                                ? {
                                    marginRight: 12,
                                    ...this.props.cancelButtonStyles,
                                  }
                                : { marginRight: 12 }
                            }
                            id={this.props?.cancelButtonId}
                          >
                            {"Cancel"}
                          </Button>
                          {/* } */}
                          <Button
                            variant={"contained"}
                            color={"primary"}
                            className={classes.saveBtn}
                            style={
                              this.props.saveButtonStyles
                                ? {
                                    margin: 16,
                                    padding: "5px 40px",
                                    ...this.props.saveButtonStyles,
                                  }
                                : { margin: 16, padding: "5px 40px" }
                            }
                            disabled={this.handleDisableApproveButton()}
                            id={this.props?.saveButtonId}
                            onClick={this.handleActivityDelete}
                          >
                            {this.state?.checked === "No"
                              ? this.state.btn_loading
                                ? "Rejecting ..."
                                : "Reject"
                              : this.state.btn_loading
                              ? "Approving ..."
                              : " Approve "}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "block",
                        padding: "16px 16px 0px 16px",
                      }}
                    >
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        gutterBottom
                      >
                        PROPOSED NEW TIME SLOT:
                      </Typography>
                      {/* <Typography variant="body1" color="textPrimary">
                        {data?.service_professional_info?.label}
                      </Typography> */}
                    </div>
                    <div style={{ padding: "0px 16px 16px 16px" }}>
                      <DateTimePickersCustom
                        value={new Date(this.props.dataSum?.from_date)}
                        label={"From Date & Time"}
                        disabled={true}
                        isDateTime={true}
                        onDeleteRemainder={() => {}}
                        hideClearIcon
                      />
                      <DateTimePickersCustom
                        value={new Date(this.props.dataSum?.to_date)}
                        label={"To Date & Time"}
                        disabled={true}
                        isDateTime={true}
                        onDeleteRemainder={() => {}}
                        hideClearIcon
                      />
                    </div>
                    <div
                      style={{
                        padding: "12px 16px 12px 16px",
                        background: "white",
                      }}
                    >
                      <FormLabel component="legend">
                        {this?.props?.isReschedule
                          ? "Client requesting to cancel the Subscription. Do you want to approve ? "
                          : "Client requesting to cancel the Subscription. Do you want to approve?"}
                      </FormLabel>
                      <RadioGroup
                        aria-label="approve"
                        style={{ flexDirection: "row" }}
                        name="approve"
                        value={this.state.checked || "Yes"}
                        onChange={(event) => {
                          this.setState({ checked: event?.target.value });
                        }}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<CustomRadio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<CustomRadio />}
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                    {this.state?.checked === "No" && (
                      <Grid container>
                        <Grid
                          item
                          container
                          style={{
                            padding: "12px 16px 12px 16px",
                            background: "white",
                          }}
                        >
                          <TextField
                            id={`reasonForCancellation`}
                            required={true}
                            type={"text"}
                            onlyText={false}
                            rowsMax={5}
                            multiline={true}
                            title={"Reason For Reject"}
                            placeholder={"Reason For Reject"}
                            defaultValue=""
                            value={this.state.cancelation_reason}
                            handleOnChange={(e) => {
                              console.log(e.target?.value);
                              this.setState({
                                cancelation_reason: e.target?.value,
                              });
                            }}
                            error={false}
                            errorText={"Reason Needed"}
                            disabled={false}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid container>
                      <Grid
                        item
                        container
                        style={{
                          padding: "12px 16px 12px 16px",
                          background: "white",
                        }}
                        justify={"flex-end"}
                      >
                        <div className={classes.saveBtnContainer}>
                          {/* {
            !this.state.openDrawer.open && */}
                          <Button
                            variant={"outlined"}
                            color={"primary"}
                            onClick={this.closeDrawer}
                            style={
                              this.props.cancelButtonStyles
                                ? {
                                    marginRight: 12,
                                    ...this.props.cancelButtonStyles,
                                  }
                                : { marginRight: 12 }
                            }
                            id={this.props?.cancelButtonId}
                          >
                            {"Cancel"}
                          </Button>
                          {/* } */}
                          <Button
                            variant={"contained"}
                            color={"primary"}
                            className={classes.saveBtn}
                            style={
                              this.props.saveButtonStyles
                                ? {
                                    margin: 16,
                                    padding: "5px 40px",
                                    ...this.props.saveButtonStyles,
                                  }
                                : { margin: 16, padding: "5px 40px" }
                            }
                            disabled={false}
                            id={this.props?.saveButtonId}
                            onClick={this.handleActivityDelete}
                          >
                            {this.state.btn_loading
                              ? "Approving ..."
                              : " Approve "}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  summaryDetail: state?.clientActivity?.activitySummary,
  deleteActivity: state?.activity_app?.delete_activity ?? {
    data: [],
    loading: false,
    error: false,
  },
  subscription_status: state?.subscription_tracking?.subscriptionRefund,
  subscription_payment_refund:
    state?.subscription_tracking?.subscription_payment_refund,
  sub_details: state?.subscription_tracking?.sub_details ?? {
    data: [],
    loading: false,
    error: false,
  },
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(SubscriptionRequest))));
