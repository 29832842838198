import React from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || theme.palette.primary.main
  }),
  cls2: (props) => ({
    fill: "none",
    stroke: props.color || theme.palette.primary.main,
    strokeMiterlimit: "10",
    strokeWidth: "2px",
  })
}))

export const OfferingsIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
      style={props.style}
    >
      <g id="Offerings" transform="translate(-2 -2)">
        <path
          id="Path_591"
          d="M21.586 12.414a1.415 1.415 0 0 0-2 0L15 17h-4a.5.5 0 0 1 0-1h2a2 2 0 0 0 2-2v-1H8.657a4 4 0 0 0-2.828 1.172L2 18l4 4 2-2h7.172a2 2 0 0 0 1.414-.586l5-5a1.415 1.415 0 0 0 0-2z"
          data-name="Path 591"
          className={classes.cls1}
        />
        <path
          id="Path_592"
          d="M17 10h-7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-5-4h3"
          data-name="Path 592"
          className={classes.cls2}
        />
      </g>
    </SvgIcon>
  );
};

OfferingsIcon.propTypes = {
  htmlColor: PropTypes.string,
  style: PropTypes.object,
};
