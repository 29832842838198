import React from "react";
import { Grid, Typography, Button, withStyles } from "@material-ui/core";
import CardList from "../../../../components/userList";
import DetailsCard from "./detailCard";
// import QuickCards from "../../../../components/quickCards";
import { ICONS } from "../../../../components/quickCards";
import { GRAPH } from "../../../../components/quickCards";
import { routes } from "../../../../router/routes";
import { withRouter } from "react-router-dom";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { alertProps, scrollToTop } from "../../../../utils";
import SimpleAvatarCard from "../../../../components/simpleAvatarCard";
import AvatarStack from "../../../../components/avatarStack";

import ViewServiceCoordinators from "./ViewServiceCoordinators";
import ViewServiceSupervisors from "./ViewServiceSupervisors";

const sizes = {
  md: 12,
  sm: 6,
  xs: 12
};

const style = (theme) => ({
  sectionColumnTwo: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4)
    }
  }
});

class CareGiverDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.getCareGiverDetail();
    this.props.GET_SAL({});
    scrollToTop();
  }

  getCareGiverDetail = () => {
    const id = this.props?.match?.params?.id;
    this.props.GET_CARE_GIVER_INFO({ id });
  };

  onDeactivateClicked = () => {
    const Oldstatus = this.props?.careGiverInfo?.data?.isactive;
    let status = "Activate";
    if (Oldstatus === "Active") {
      status = "Deactivate";
    }
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body:
        "Do you want to " +
        status +
        " Caregiver: " +
        this?.props?.careGiverInfo?.data?.name,
      positiveBtn: status,
      negativeBtn: "Cancel",
      onOk: this.deactiveUser
    });
  };

  deactiveUser = () => {
    const id = this.props?.match?.params?.id;
    const Oldstatus = this.props?.careGiverInfo?.data?.isactive;
    let newStatus = "Active";
    if (Oldstatus === "Active") {
      newStatus = "InActive";
    }
    this.props.dialog.setDialog({ open: false });

    this.props.UPDATE_CARE_GIVER_STATUS({ id, status: newStatus });
  };

  giveMeNecessaryData = () => {
    const { location, careGiverInfo } = this.props;

    const { data } = careGiverInfo;

    const { data: salutationOptions } = this.props?.salutation ?? [{}];

    let necessaryData = {
      ...careGiverInfo
    };

    let bottomCard = [
      {
        count: "0",
        loading: true,
        label: "Clients",
        icon: ICONS.CLIENT("#28a9c1"),
        backgroundColor: "#28a9c1",
        color: "#000000", //#28a9c1
        graph: GRAPH.ONE
      },
      {
        count: "0",
        loading: true,
        label: "Upcoming",
        icon: ICONS.CALENDAR("#353596"),
        backgroundColor: "#353596",
        color: "#353596",
        graph: GRAPH.TWO
      },
      {
        count: "",
        loading: true,
        label: "Ratings",
        icon: ICONS.STAR("#f27d35"),
        color: "#f27d35",
        backgroundColor: "#f27d35",
        graph: GRAPH.THREE
      }
    ];

    if (location?.state) {
      necessaryData = {
        ...location.state,
        ...careGiverInfo,
        bottomCard
      };
    }
    if (data?.id) {
      const _ = data;
      const header = [
        {
          id: _?.id,
          name: _?.name,
          category: _?.category,
          isactive: _?.isactive,
          profile_pic: _?.profile_pic,
          salutation:
            salutationOptions?.length > 0
              ? salutationOptions?.filter(
                  (sal) => _?.salutation === sal?.label
                )?.[0]?.label
              : [{}]
        }
      ];

      const footer = [
        {
          name: `${_.clients_count ? _.clients_count : "0"} Clients`
        }
      ];

      bottomCard[0].count = _.clients_count ? _.clients_count : 0;
      bottomCard[0].loading = false;

      bottomCard[1].count = _.upcoming_activities_count
        ? _.upcoming_activities_count
        : 0;
      bottomCard[1].loading = false;

      bottomCard[2].count = _.average_rating ? _.average_rating : 0;
      bottomCard[2].loading = false;

      necessaryData = {
        ...careGiverInfo,
        header,
        footer,
        bottomCard
      };
    }

    return necessaryData;
  };

  shouldComponentUpdate(nextProps, nextState) {
    //For activate/deactivate
    const { updateCareGiverStatus } = nextProps;
    const oldupdateCareGiverStatus = this.props.updateCareGiverStatus;

    if (oldupdateCareGiverStatus?.loading !== updateCareGiverStatus?.loading) {
      if (!updateCareGiverStatus.loading && !updateCareGiverStatus.error) {
        this.getCareGiverDetail();
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Service Professional status updated successfully!",
          severity: alertProps.severity.success
        });
      } else if (updateCareGiverStatus.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to update.",
          severity: alertProps.severity.error
        });
      }
    }

    //For detach care provider
    const { detachProviderInfo } = nextProps;
    const olddetachProviderInfo = this.props.detachProviderInfo;

    if (olddetachProviderInfo?.loading !== detachProviderInfo?.loading) {
      if (!detachProviderInfo.loading && !detachProviderInfo.error) {
        this.getCareGiverDetail();
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Provider detached successfully!",
          severity: alertProps.severity.success
        });
      } else if (detachProviderInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to detach provider.",
          severity: alertProps.severity.error
        });
      }
    }
    return true;
  }

  detachCareProvider = (care_provider) => {
    this.props.DETACH_CARE_PROVIDER({
      careGiverId: this.props?.match?.params?.id,
      careProviderId: care_provider.id
    });
  };

  handleViewAllClick = (data) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewServiceCoordinators
          data={data?.service_coordinators ?? []}
          drawer={this.props.drawer}
          onEndIconClickHandler={this.onEndIconClickHandler}
          onRemoveAssigned={this.onRemoveAssigned}
        />
      )
    });
  };

  onRemoveAssigned = () => {
    alert("Removed ")
  }

  handleViewAllSupervisorClick = (data) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewServiceSupervisors
          data={data?.service_supervisors ?? []}
          drawer={this.props.drawer}
          onEndIconClickHandler={this.onSupervisorEndIconClickHandler}
          // onRemoveAssigned={this.onRemoveAssigned}
        />
      )
    });
  };

  onEndIconClickHandler = (id, isCareGiver) => {
    // window.open(`${isCareGiver ? routes.manage_care_givers : routes.manage_care_coordinators}/view/${id}`, '_blank').focus()
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    });
    this.props.history.push(
      `${
        isCareGiver
          ? routes.manage_care_givers
          : routes.manage_care_coordinators
      }/view/${id}`
    );
  };

  onSupervisorEndIconClickHandler = (id, isCareGiver) => {
    // window.open(`${isCareGiver ? routes.manage_care_givers : routes.manage_care_coordinators}/view/${id}`, '_blank').focus()
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    });
    this.props.history.push(
      `${
        isCareGiver ? routes.manage_care_givers : routes.manage_supervisors
      }/view/${id}`
    );
  };

  handleOnProviderClicked = (id) => {
    this.props.history.push(`${routes.manage_care_providers}/view/${id}`);
  };

  render() {
    let {
      header,
      data,
      error,
      loading
      // bottomCard,
    } = this.giveMeNecessaryData();
    const { classes } = this.props;

    const id = this.props?.match?.params?.id;

    return (
      <>
        {(error || !data) && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <Typography variant="h6" align="center">
                Something went wrong!
              </Typography>
            </Grid>
            <Grid item>
              <Button
                style={{ marginTop: 16, textAlign: "center" }}
                variant={"outlined"}
                onClick={this.getCareGiverDetail}
              >
                Try Again
              </Button>
            </Grid>
          </Grid>
        )}
        {!error && data && (
          <Grid style={{ padding: "25px" }}>
            <CardList
              list={header}
              componentOrder={2}
              sizes={sizes}
              isonlycard={true}
              link={routes.manage_care_givers + "/edit/" + id}
              onDeactivateClicked={this.onDeactivateClicked}
              isDeactivating={this.props?.updateCareGiverStatus?.loading}
              isSalutationNeeded={true}
            />
            <DetailsCard
              data={data}
              loading={loading}
              isBottomRounded={false}
              detachCareProvider={this.detachCareProvider}
              detachLoading={this.props?.detachProviderInfo?.loading}
            />

            {/* New Section here */}
            <div
              style={{
                padding: `25px 25px`,
                background: "white",
                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04)"
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <SimpleAvatarCard
                    id={data?.care_provider?.[0]?.id}
                    onAvatarClicked={(id) => this.handleOnProviderClicked(id)}
                    title={"PROVIDER"}
                    name={data?.care_provider?.[0]?.name}
                    src={data?.care_provider?.[0]?.profile_pic}
                    loading={loading}
                    height={16}
                    width={16}
                    emptyText="Not Mapped"
                    style={{ position: "relative", top: 2 }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} className={classes.sectionColumnTwo}>
                  <AvatarStack
                    title={"SERVICE COORDINATORS"}
                    loading={loading}
                    data={data?.service_coordinators}
                    onAvatarClicked={(id) =>
                      this.onEndIconClickHandler(id, false)
                    }
                    height={16}
                    width={16}
                    emptyMessage="Not Mapped"
                    style={{ position: "relative", top: 2 }}
                    onButtonClickHandler={() => this.handleViewAllClick(data)}
                    view_care_givers
                  />
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                padding: `25px 25px`,
                background: "white",
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04)"
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={6} className={classes.sectionColumnTwo}>
                  <AvatarStack
                    title={"SERVICE SUPERVISORS"}
                    loading={loading}
                    data={data?.service_supervisors}
                    onAvatarClicked={(id) =>
                      this.onSupervisorEndIconClickHandler(id, false)
                    }
                    height={16}
                    width={16}
                    emptyMessage="Not Mapped"
                    style={{ position: "relative", top: 2 }}
                    onButtonClickHandler={() =>
                      this.handleViewAllSupervisorClick(data)
                    }
                    // viewButtonName={"VIEW DETAILS"}
                  />
                </Grid>
              </Grid>
            </div>

            {/* <QuickCards items={bottomCard} isBottom={true}></QuickCards> */}
          </Grid>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  careGiverInfo: state.careGiver?.careGiverInfo,
  updateCareGiverStatus: state.careGiver?.updateCareGiverStatus,
  detachProviderInfo: state.careGiver?.detachProviderInfo,
  salutation: state?.masterModule?.getsal ?? {
    data: [],
    loading: false,
    error: false
  }
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAlelrtAndDialog(withStyles(style)(CareGiverDetails))));
