import React from "react";
import PropTypes from "prop-types";
import { Avatar, Grid, Typography, withStyles } from "@material-ui/core";
import { avatarColorBgById, getIdForAvatarColor } from "../../../utils";

const styles = (theme) => ({
  root: {
    position: "absolute",
    width: 264,
  },
  activities: {
    padding: 8,
    backgroundColor: "#f9f9f9",
    borderLeft: "4px solid #252b43",
    borderRadius: 4,
  },
  available: {
    padding: 8,
    backgroundColor: "#dbeef3",
    borderLeft: "4px solid #28a9c1",
    borderRadius: 4,
  },
  unavailable: {
    padding: 8,
    backgroundColor: "#fdece1",
    borderLeft: "4px solid #f27d35",
    borderRadius: 4,
  },
  avatar: {
    height: 30,
    width: 30,
    marginLeft: -6,
    border: "1px solid white",
  },
  moreAvator: {
    height: 30,
    width: 30,
    marginLeft: -6,
    border: "1px solid white",
  },
  activitiesAvatar: {
    padding: 4,
    backgroundColor: "#252b43",
  },
  availableAvatar: {
    padding: 4,
    backgroundColor: "#28a9c1",
  },
  unavailableAvatar: {
    padding: 4,
    backgroundColor: "#f27d35",
  },
});

class CalenderCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      variant,
      title,
      subTitle,
      height,
      cards,
      style,
    } = this.props;
    return (
      <div className={classes.root} style={style}>
        <Grid
          container
          direction="column"
          justify="space-between"
          className={classes[variant]}
          style={{ height }}
        >
          <Grid item>
            <Typography variant="body1" component="span" color="textPrimary">
              {title}
            </Typography>
            <br />
            <Typography
              variant="caption"
              component="span"
              color="textSecondary"
            >
              {subTitle}
            </Typography>
          </Grid>
          <Grid item style={{ display: "flex", paddingTop: 8, paddingLeft: 6 }}>
            {cards.map((card, key) => {
              let data =
                card?.service_professional_info?.length > 0
                  ? card?.service_professional_info[0]
                  : card?.service_professional_info;
              const fallbackText = data?.name
                ?.toString()
                ?.toUpperCase()
                ?.trim()?.[0];
              const fallbackId = getIdForAvatarColor(data?.id)
                
              return (
                <>
                  {key < 4 && (
                    <Avatar
                      className={classes.avatar}
                      src={data?.profile_pic}
                      key={key}
                      style={{
                        backgroundColor: !!!data?.profile_pic
                          ? avatarColorBgById(fallbackId)
                          : "#bdbdbd",
                      }}
                      // data?.name
                    >
                      {fallbackText}
                    </Avatar>
                  )}
                </>
              );
            })}
            {cards.length > 4 && (
              <Avatar
                className={`${classes.moreAvator} ${
                  classes[`${variant}Avatar`]
                }`}
              >
                <Typography variant="caption" color="initial">{`+${
                  cards.length - 4
                }`}</Typography>
              </Avatar>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

CalenderCard.propTypes = {
  variant: PropTypes.oneOf(["activities", "available", "unavailable"]),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  cards: PropTypes.array,
};

export default withStyles(styles)(CalenderCard);
