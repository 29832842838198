import React from "react";
import {
    withStyles,
    Grid, Typography,
} from "@material-ui/core";
import CertificationcardList from "./index";
import CertificationcardsLoading from "./CertificationCardLoaders";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
export * from "./CertificationcardsLoading";
export * from "./error";
const styles = (theme) => ({
    title: {
        fontSize: "20px",
        fontWeight: 600,
        textAlign: "left",
        marginBottom: 20,
        marginTop: 8,
    },
    add_title: {
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "left",
        // color: "#f27d35",
        color: theme.palette.ternary.main,
        textTransform: "uppercase",
        cursor: "pointer",
        width: "max-content"
    }
});
class Certificationcard extends React.Component {
    render() {
        const { classes, certification, handledrawer, ondltList, onchanged,onDragEnd } = this.props;
        return (
            <Grid>
                <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                {certification?.length > 0 && <Typography className={classes.title}>{"Certifications"}</Typography>}
                {certification && <CertificationcardList DroppableProvided={provided} data={certification} onchanged={onchanged} ondltList={ondltList} handledrawer={handledrawer} />}
                {!certification && <CertificationcardsLoading count={2} />}
                <Typography id={this.props?.id?.add_certificate_id} onClick={() => handledrawer("Add Certification",  certification)} style={{ marginTop: certification?.length > 0 ? 30 : 10 }} className={classes.add_title}>{"+ Add Certification"}</Typography>
                </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
        );
    }
}
Certificationcard.propTypes = {
 
};

Certificationcard.defaultProps = {
  classes:{},
  certification:[],
  handledrawer:()=>{},
  ondltList:()=>{},
  onDragEnd1:()=>{}

};
export default withAlelrtAndDialog(withStyles(styles)(Certificationcard));