import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    "& .MuiFormLabel-root": {
      color: `${theme.palette.secondary.main} !important`,
    },
    margin: theme.spacing(1),
    "& .MuiChip-root": {
      borderRadius: "3px !important",
      background: `${theme.palette.secondary.light} !important`,
      border: "unset",
    },
    "& .MuiAutocomplete-inputRoot.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.ternary.main}`,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      "& .MuiAutocomplete-input": {
        padding: "0px !important",
      },
    },
  },
  textField: {
    "& legend": {
      display: "none",
    },
  },

  autocompleteCustom: {
    borderRadius: "3px !important",
  },
  chipsCustom: {
    height: "16px !important",
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
  errorInputBorder: {
    border: `1.8px solid ${theme.palette.error.main}`,
  },
}));
