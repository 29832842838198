/* eslint-disable eqeqeq */
// REFER : https://next.material-ui-pickers.dev/api/DatePicker
import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import sub from 'date-fns/sub'
import {
  DatePicker,
  MobileDatePicker,
  TimePicker,
  // DateTimePicker,
  MobileDateTimePicker,
  LocalizationProvider,
} from "@material-ui/pickers";
import moment from 'moment'
import { Clock } from "../../assets/AddEditScreenIcons/clock";
// import Calendar from "../../assets/product Management/addEditScreen/calendar.svg";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CloseIcon from '@material-ui/icons/Close';
import {
  TextField,
  InputLabel,
  FormControl,
  makeStyles,
  Typography,
  InputAdornment,
  ClickAwayListener,
  IconButton,
} from "@material-ui/core";
import PropTypes from "prop-types";
// import { Data } from "@react-google-maps/api";

// Here goes the styles of the component

const styles = makeStyles((theme) => ({
  root: {
    borderRadius: "8px !important",
    margin: (props) =>
      props.marginTop ? `${props.marginTop}px 0px 8px 0px` : "8px 0px 8px 0px",
    width: (props) => props?.width ? props?.width : "100%",
    [theme.breakpoints.down(400)]: {
      width: (props) => props?.widthBelow400 ? props?.widthBelow400 : "100%"
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 12px",
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.secondary.borderColor}`,
      borderRadius: 8,
    },
    "& .MuiOutlinedInput-root:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`,
      },
    },
    "& .MuiInputLabel-asterisk": {
      color: theme.palette.error.main
    }
  },

  textField: {
    marginTop: (props) =>
      props.marginTop ? `${props.marginTop}px` : theme.spacing(2),

    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      // border: '1.8px solid rgba(204,204,204, 0.3)',
      border: "none",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      height: 43.6
    },

    "& .MuiOutlinedInput-root.Mui-disabled ": {
      backgroundColor: "#f2f2f2",
      borderRadius: "8px !important",
    },
  },
  mobilePicker: {
    "& .MuiPickersToolbar-dateTitleContainer": {
      justifyContent: "space-between !important",
      "& h4": {
        color: "white"
      }
    },
  },
  svgColor: { 
    fill: theme.palette.secondary.main,
    fontSize: 20,
  },
  // label: {
  //   color: theme.palette.text.label,
  //   textTransform: "uppercase",
  // },
  errorBorder: {
    //   "& .MuiOutlinedInput-root:hover": {
    //     "& .MuiOutlinedInput-notchedOutline": {
    //       border: `1.8px solid ${theme.palette.error.light} !important`,
    //     },
    // },
    // "& .Mui-focused": {
    //   "& .MuiOutlinedInput-notchedOutline": {
    //     border: `1.8px solid ${theme.palette.error.light} !important`,
    //   },
    // },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.error.light} !important`,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
  root2: {
    "& .MuiPickersModalDialog-dialogRoot": {
      "&:focus": {
        outline: "unset !important",
      },
    },
    "& .MuiDialog-scrollPaper": {
      "&:focus": {
        outline: "unset !important",
      },
    },
  },
  timePickerRoot: {
    "& .MuiPickersClock-amButton": {
      display: "none"
    },
    "& .MuiPickersClock-pmButton": {
      display: "none"
    },
  }
}));

/*
// This component returns either date picker or time picker by default it return date picker
// It receives three props - VALUE, ONCHANGE, ISDAT E
*/

export const DateTimePickersCustom = ({ required, ...props }) => {
  const [open, setOpen] = React.useState(false)
  // eslint-disable-next-line
  let picker = React.useRef(null);
  const classes = styles(props);
  let {
    isDateTime,
    isDate,
    value,
    handleOnChange,
    // state,
    label,
    error,
    errorText,
    withoutLabel,
    hidden,
    minDate,
    isHideFutureDate,
    isHidePastDate,
    // hideMaxDateLogic,
    // minTime
    disabled,
    onAccept,
    variant,
    // hideMaxDateLogic,
    onDeleteRemainder,
    isEighteenYearsOld,
    id,
    dontCloseOnChange,
    handleOnDialogClose,
    hideClearIcon
  } = props;

  const [newValue] = useState(props.value)

  // const onChange = () => {
  //   // this.setState({newValue: ""})
  //   // console.log(newValue)
  //   setNewValue("")
  //   // console.log(newValue)
  // }
  const handleClose = (e) => {

    if (e) {
      setOpen(false)
    }
    else {
      setOpen(true)
    }
  }

  console.log(props)
  return (
    // node_modules\date-fns\esm\format\index.js ---- > PLEASE CHECK HERE FOR DATE FORMATS YOU NEED
    <ClickAwayListener onClickAway={() => { handleClose("S") }}>
      <FormControl
        className={`${classes.root} ${error ? classes.errorBorder : ""}`}
      >
        {!withoutLabel && (
          <InputLabel shrink htmlFor="date" required={required} >
            {label ? label?.toUpperCase() : "Date"}
          </InputLabel>
        )}
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          {isDateTime ? (
            <MobileDateTimePicker
              // disableFuture
              // disablePast={true}
              disabled={disabled}
              minDateTime={minDate}
              disablePast={isHidePastDate}

              onAccept={(e) => {
                onAccept && onAccept(e, props?.state)
              }}
              disableFuture={isHideFutureDate}
              inputFormat={
                props?.inputFormat ? props.inputFormat : "dd/MM/yyyy hh:mm a"
              }
              className={classes.mobilePicker}
              value={newValue == "" ? newValue : value}
              DialogProps={{
                className: classes.root2,
              }}
              id={props?.id}
              // id={props.label}
              onChange={handleOnChange}
              renderInput={(props) => (
                <>
                  <TextField
                    {...props}
                    // id={'date'}
                    // id={props?.id}
                    disabled={disabled}
                    variant="outlined"
                    inputProps={{
                      ...props.inputProps,
                      disabled: disabled,
                      placeholder: "",
                      id: label.replaceAll(" ", ""),
                      // id:props?.id,
                    }}
                    InputProps={{
                      ...props.InputProps,
                      endAdornment: (
                        <InputAdornment
                          position="end"

                          style={{ cursor: "pointer" }}
                        >
                          {
                            !hideClearIcon && value !== null
                              ? <IconButton disabled={disabled} onClick={() => { onDeleteRemainder() }}>
                                <CloseIcon />
                              </IconButton>
                              : <></>
                          }
                          <IconButton disabled={disabled} onClick={() => { document.getElementById(label.replaceAll(" ", "")).click() }}>
                            <CalendarTodayIcon color="secondary" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="off"
                    helperText={null}
                    style={{ ...props?.style }}
                    className={`${classes.textField} ${error ? classes.errorBorder : ""
                      }`}
                  />

                  {error && (
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.errorText}
                    >
                      {errorText}
                    </Typography>
                  )}
                </>
              )}
            />
          ) : isDate ? (
            variant !== "mobile" ?
              <>
                <DatePicker
                  openPickerIcon={
                    <CalendarTodayIcon className={classes.svgColor} />
                  }
                  initialFocusedDate={isEighteenYearsOld ? sub(new Date(), {years: 18}) : null}
                  ref={(c) => picker = c}
                  PopperProps={{ disablePortal: true }}
                  value={value ? value : null}

                  onChange={(e) => { handleClose(e); handleOnChange(e) }}
                  onOpen={(e) => { handleClose(e) }}
                  minDate={minDate ? minDate : new Date("1900-01-01")}
                  maxDate={isEighteenYearsOld ? moment().subtract(18, 'years') : null}
                  id={`date${props?.id}`}
                  // maxDate={!hideMaxDateLogic ? new Date() : null}
                  inputFormat={
                    props?.inputFormat ? props.inputFormat : "dd/MM/yyyy"
                  }
                  onDismiss={(e) => console.log("ON DISMISS", e)}
                  // onClose={(e)=>console.log("ON CLOSE", e)}
                  // onAccept={(e)=>{console.log(e,"ON ACCEPTED DATE")}}
                  onError={(e) => { console.log("error-picker", e) }}
                  disablePast={isHidePastDate}
                  disableFuture={isHideFutureDate}
                  disabled={disabled}
                  open={open}
                  // displayStaticWrapperAs="desktop"
                  // openTo="year"
                  renderInput={(props) => {
                    console.log("...props...", props);
                    if (isHideFutureDate) {
                      if (props.inputProps.value.length > 0) {
                        if (
                          props.inputProps.value.length === 2 ||
                          (props.inputProps.value.split("/").length > 1 &&
                            props.inputProps.value.split("/")[1] === "")
                        ) {
                          let date = props.inputProps.value;
                          if (date.length > 1) {
                            if (date.substring(0, 2)) {
                              if (!(Number(date.substring(0, 2)) <= 31)) {
                                props.inputProps.value = "";
                              }
                            }
                          }
                        } else if (props.inputProps.value.indexOf("/") > -1) {
                          if (
                            props.inputProps.value.split("/").length > 2 &&
                            props.inputProps.value.split("/")[2] !== ""
                          ) {
                            let date = props.inputProps.value.split("/")[2];
                            let current_year = new Date().getFullYear()
                            current_year.toString().substring(0, 2)
                            if (date.length > 2) {
                              if (date.length > 3) {
                                if (date.substring(0, 4)) {
                                  if (!((Number(date.substring(0, 4)) >= 1900) && Number(date.substring(0, 4)) <= current_year.toString())) {
                                    props.inputProps.value = props.inputProps.value
                                      .split("/")[0]
                                      .concat("/");
                                  }
                                }
                              }
                              else {
                                if (date.substring(0, 2)) {
                                  if (!((Number(date.substring(0, 2)) >= 19) && Number(date.substring(0, 2)) <= current_year.toString().substring(0, 2))) {
                                    props.inputProps.value = props.inputProps.value
                                      .split("/")[0]
                                      .concat("/");
                                  }
                                }
                              }
                            }

                            else if (date.length > 1) {

                              if (!(Number(date.substring(0, 2)) >= 19 && Number(date.substring(0, 2)) <= current_year.toString().substring(0, 2))) {
                                props.inputProps.value = props.inputProps.value
                                  .split("/")[0]
                                  .concat("/")
                                  .concat(props.inputProps.value.split("/")[1]);
                              }
                            }
                          } else if (props.inputProps.value.split("/").length > 1) {

                            let date = props.inputProps.value.split("/")[1];
                            if (date.length > 1) {

                              if (date.substring(0, 2)) {
                                if (!(Number(date.substring(0, 2)) < 13)) {
                                  props.inputProps.value = props.inputProps.value
                                    .split("/")[0]
                                    .concat("/");
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    if (isEighteenYearsOld) {
                      // if(!(Boolean(moment().diff(new Date(date), 'years') === 18))){

                      if (props?.inputProps?.value?.split("/")?.length > 1) {

                        let date = props?.inputProps?.value?.split("/")[2];
                        if (date?.length > 3) {
                          console.log(moment().diff(new Date(date), 'years'), "sdf")
                          // if (date.substring(0, 2)) {
                          if (moment().diff(new Date(date), 'years') >= 18) {
                            props.inputProps.value = props?.inputProps?.value

                          }
                          else {
                            props.inputProps.value = props?.inputProps?.value
                              .split("/")[0]
                              .concat("/");
                          }
                          // }
                        }
                        // }

                      }

                    }
                    return (
                      <TextField
                        disabled={disabled}
                        {...props}
                        variant="outlined"
                        // id="date" 
                        id={`datePickerTexField${id}`}
                        placeholder=" "
                        // id={"MOTTA MANDA"}
                        // InputProps={{
                        //   hidden:hidden ? true : false
                        // }}
                        // inputProps={{
                        //   hidden:hidden ? true : false
                        // }}
                        // hidden = {hidden ? true : false}
                        helperText={null}
                        autoComplete="off"
                        // onClick={handleOnChange}
                        className={hidden ? "" : classes.textField}
                      // required={false}
                      />
                    );
                  }}
                />
                {error && (
                  <Typography
                    variant="body1"
                    component="div"
                    className={classes.errorText}
                  >
                    {errorText}
                  </Typography>
                )}
              </>
              :
              <>
                <MobileDatePicker
                  openPickerIcon={
                    <CalendarTodayIcon className={classes.svgColor} />
                  }
                  PopperProps={{ disablePortal: true }}
                  value={value}
                  id={`mobileDatePicker${props?.id}`}
                  initialFocusedDate={isEighteenYearsOld ? sub(new Date(), {years: 18}) : null}
                  onChange={(e) => {
                    
                    if (!dontCloseOnChange) {
                      handleClose(e);
                    } 
                    handleOnChange(e)
                  }}
                  onOpen={(e) => { handleClose(e) }}
                  onClose={() => {
                    
                    if(dontCloseOnChange){
                      handleOnDialogClose ? handleOnDialogClose() : setOpen(false)
                    }
                    else setOpen(false)
                  }}
                  minDate={minDate ? minDate : new Date("1900-01-01")}
                  // maxDate={new Date()}
                  // maxDate={!hideMaxDateLogic ? new Date() : null}
                  inputFormat={
                    props?.inputFormat ? props.inputFormat : "dd/MM/yyyy"
                  }

                  onDismiss={() => {
                    
                    setOpen(false)
                  }}
                  // onClose={(e)=>console.log("ON CLOSE", e)}
                  // onAccept={(e)=>{console.log(e,"ON ACCEPTED DATE")}}
                  onError={(e) => { console.log("error-picker", e) }}
                  disablePast={isHidePastDate}
                  disableFuture={isHideFutureDate}
                  disabled={disabled}
                  open={open}
                  className={classes.mobilePicker}
                  renderInput={(props) => {
                    console.log("...props...", props);
                    if (isHideFutureDate) {
                      if (props.inputProps.value.length > 0) {
                        if (
                          props.inputProps.value.length === 2 ||
                          (props.inputProps.value.split("/").length > 1 &&
                            props.inputProps.value.split("/")[1] === "")
                        ) {

                          let date = props.inputProps.value;
                          if (date.length > 1) {
                            if (date.substring(0, 2)) {
                              if (!(Number(date.substring(0, 2)) <= 31)) {
                                props.inputProps.value = "";
                              }
                            }
                          }
                        } else if (props.inputProps.value.indexOf("/") > -1) {

                          if (
                            props.inputProps.value.split("/").length > 2 &&
                            props.inputProps.value.split("/")[2] !== ""
                          ) {

                            let date = props.inputProps.value.split("/")[2];
                            let current_year = new Date().getFullYear()
                            current_year.toString().substring(0, 2)
                            if (date.length > 2) {
                              if (date.length > 3) {
                                if (date.substring(0, 4)) {
                                  if (!((Number(date.substring(0, 4)) >= 1900) && Number(date.substring(0, 4)) <= current_year.toString())) {
                                    props.inputProps.value = props.inputProps.value
                                      .split("/")[0]
                                      .concat("/");
                                  }
                                }
                              }
                              else {
                                if (date.substring(0, 2)) {
                                  if (!((Number(date.substring(0, 2)) >= 19) && Number(date.substring(0, 2)) <= current_year.toString().substring(0, 2))) {
                                    props.inputProps.value = props.inputProps.value
                                      .split("/")[0]
                                      .concat("/");
                                  }
                                }
                              }
                            }

                            else if (date.length > 1) {

                              if (!(Number(date.substring(0, 2)) >= 19 && Number(date.substring(0, 2)) <= current_year.toString().substring(0, 2))) {
                                props.inputProps.value = props.inputProps.value
                                  .split("/")[0]
                                  .concat("/")
                                  .concat(props.inputProps.value.split("/")[1]);
                              }
                            }
                          } else if (props.inputProps.value.split("/").length > 1) {

                            let date = props.inputProps.value.split("/")[1];
                            if (date.length > 1) {

                              if (date.substring(0, 2)) {
                                if (!(Number(date.substring(0, 2)) < 13)) {
                                  props.inputProps.value = props.inputProps.value
                                    .split("/")[0]
                                    .concat("/");
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    return (
                      <TextField
                        disabled={disabled}
                        {...props}
                        variant="outlined"
                        id={`mobileDatePickerTextField${id}`}
                        // id={props.id}
                        placeholder=" "
                        // InputProps={{
                        //   hidden:hidden ? true : false
                        // }}
                        // inputProps={{
                        //   hidden:hidden ? true : false
                        // }}
                        // hidden = {hidden ? true : false}
                        helperText={null}
                        autoComplete="off"
                        // onClick={handleOnChange}
                        className={hidden ? "" : classes.textField}
                      // required={false}
                      />
                    );
                  }
                }
                />
                {error && (
                  <Typography
                    variant="body1"
                    component="div"
                    className={classes.errorText}
                  >
                    {errorText}
                  </Typography>
                )}
              </>

          ) : (
            <>
              <TimePicker
                // minTime={minTime ? minTime :null}
                inputFormat={props?.inputFormat ? props?.inputFormat : "hh:mm a"}
                disableIgnoringDatePartForTimeValidation
                openPickerIcon={<Clock className={classes.svgColor} />}
                disabled={disabled}
                id={`timePicker${props?.id}`}
                className={classes.timePickerRoot}
                renderInput={(props) => {
                  return (
                    <TextField
                      {...props}
                      // id={props?.id}
                      disabled={disabled}
                      variant="outlined"
                      id={`timePickerTexField${props?.id}`}
                      placeholder=""
                      autoComplete="off"
                      error={props.error}
                      helperText={null}
                      className={classes.textField}
                    />
                  );
                }}
                value={value}
                onChange={handleOnChange}
              />
              {error && (
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.errorText}
                >
                  {errorText}
                </Typography>
              )}
            </>
          )}
        </LocalizationProvider>
      </FormControl>
    </ClickAwayListener>
  );
};

// PROPTYPES

DateTimePickersCustom.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isDate: PropTypes.bool.isRequired,
  label: PropTypes.string,
  /**Sets the input format of the picker. Use ```dd``` for date, ```MM``` for month, ```yyyy``` for year. Defaults to ```MM/dd/yyyy```` */
  inputFormat: PropTypes.string,
};
