/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-02-18 12:49:41
 * @modify date 2022-02-18 12:49:41
 * @desc [description]
 */
import React from "react";
import { AppointmentCard, AppointmentCardLoader } from "./appointmentCardKit";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { isArrayWithElements } from "../../../../../utils/validations";
import { InternatlServerError, NotFoundSection } from "../../../../../components";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(1.5),
    maxWidth: 400,
    minWidth: 400,
    height: "100%",
  },
  title: {
    fontSize: "20px",
    fontWeight: 500,
    padding: "24px 16px 16px 16px",
  },
  appointmentsContainer: (props) => ({
    overflow: "scroll",
    height: `calc(${
      props?.leftPaneRef?.current?.offsetHeight ?? "777px"
    } - 71px)`,
  }),
}));

function Timeline({ loading, error, appointments = [] }) {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography className={classes.title}>Timeline</Typography>
      <Divider light />
      <div className={classes.appointmentsContainer}>
        {/* Error State */}
        {error && !loading && <InternatlServerError message="Unable to fetch appointments" hideRetryButton />}

        {/* Loading State */}
        {loading && !error && <AppointmentCardLoader />}

        {/* No Data State */}
        {!loading && !error && !isArrayWithElements(appointments) && (
          <NotFoundSection message="No appointments for the selected date" />
        )}

        {/* Success Data State */}
        {!loading &&
          !error &&
          isArrayWithElements(appointments) &&
          appointments?.map((appointment, index) => {
            return (
              <>
                <AppointmentCard appointmentDetail={appointment} />
                {index !== appointments.length - 1 && <Divider light />}
              </>
            );
          })}
      </div>
    </Paper>
  );
}

export default Timeline;
