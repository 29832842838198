import * as React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.htmlColor ? props.htmlColor : "#ABACB9" ,
  }),
}));

function VisibilityOutlinedIcon(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* <path
        d="M12 3C3.273 3 0 11.5 0 11.5S3.273 20 12 20s12-8.5 12-8.5S20.727 3 12 3Zm0 2.125c5.755 0 8.667 4.534 9.61 6.371-.944 1.822-3.876 6.379-9.61 6.379-5.756 0-8.667-4.534-9.61-6.371.945-1.825 3.877-6.379 9.61-6.379Zm0 2.125c-.863 0-1.707.25-2.424.716a4.276 4.276 0 0 0-1.607 1.908 4.147 4.147 0 0 0-.249 2.455 4.217 4.217 0 0 0 1.194 2.176 4.4 4.4 0 0 0 2.235 1.163c.846.164 1.724.08 2.52-.241a4.341 4.341 0 0 0 1.96-1.566c.479-.699.735-1.52.735-2.361 0-1.127-.46-2.208-1.278-3.005A4.424 4.424 0 0 0 12 7.25Zm0 2.125c.431 0 .853.125 1.212.358.359.234.639.566.804.954.165.388.208.815.124 1.228-.084.412-.292.79-.597 1.088a2.2 2.2 0 0 1-1.117.581c-.424.082-.862.04-1.26-.12a2.17 2.17 0 0 1-.98-.783 2.086 2.086 0 0 1 .271-2.684c.41-.398.964-.622 1.543-.622Z"
        fill="#ABACB9"
      /> */}
      <path
        d="M12 4C3.636 4 .5 12 .5 12s3.136 8 11.5 8 11.5-8 11.5-8S20.364 4 12 4Zm0 2c5.515 0 8.306 4.267 9.21 5.997C20.305 13.71 17.495 18 12 18c-5.516 0-8.306-4.267-9.21-5.997C3.696 10.286 6.506 6 12 6Zm0 2c-.827 0-1.636.235-2.323.674a4.044 4.044 0 0 0-1.54 1.795c-.317.731-.4 1.535-.238 2.311a3.95 3.95 0 0 0 1.144 2.048c.585.56 1.33.94 2.141 1.095a4.358 4.358 0 0 0 2.416-.228 4.147 4.147 0 0 0 1.877-1.473c.46-.657.705-1.43.705-2.222 0-1.06-.44-2.078-1.225-2.828A4.28 4.28 0 0 0 12 8Zm0 2c.414 0 .818.117 1.162.337.343.22.611.532.77.898.158.365.2.767.119 1.155-.08.388-.28.744-.572 1.024-.293.28-.665.47-1.071.548a2.18 2.18 0 0 1-1.208-.114 2.074 2.074 0 0 1-.938-.737A1.938 1.938 0 0 1 9.909 12c0-.53.22-1.04.612-1.414A2.14 2.14 0 0 1 12 10Z"
        className={classes.cls1}
      />
    </SvgIcon>
  );
}

VisibilityOutlinedIcon.propTypes = {
  htmlColor: PropTypes.string,
};

// VisibilityOutlinedIcon.defaultProps = {
//   htmlColor: "in"
// };

export default VisibilityOutlinedIcon;
