import * as React from "react"

export function NextArrow(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9.059" height="10" viewBox="0 0 9.059 17" color={props.color} {...props}>
    <g>
        <g>
            <path fill="#FFA811" stroke="#FFA811" d="M7.831 7.561L.98.181a.545.545 0 0 0-.812 0 .656.656 0 0 0 0 .877L6.613 8 .169 14.941a.656.656 0 0 0 0 .877.552.552 0 0 0 .405.182.552.552 0 0 0 .406-.181l6.851-7.38a.656.656 0 0 0 0-.878z" transform="translate(.559 .5)"/>
        </g>
    </g>
</svg>
  )
}