import React, { useRef, useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  Divider,
  useTheme,
} from "@material-ui/core";
import  {Doughnut }from "react-chartjs-2";
import { Chart } from 'react-chartjs-2';
// import AddRoundedIcon from "@material-ui/icons/AddRounded";
import PropTypes from "prop-types";
import { fontStyleClass, commonShadows, genericStyles } from "../../utils";

const useStyles = makeStyles((theme) => ({
  rowOneIcons: {
    border: "1px solid #E8E8EC",
    margin: "0px 4px",
    padding: 8,
  },
  customListItemIcon: {
    minWidth: 36,
  },
  legendWrapper: {
    listStyle: "none",
    // display: "flex",
    justifyContent: "space-around",
    padding: "8px 10px 8px 18px",
    flexWrap: "wrap",

    // --------
    maxHeight: "150px",
  },
  legendList: {
    display: "flex",
    // justifyContent: "center",
    // justifyContent: "flex-start",
    margin: "8px 0px",
  },

  legendListNorm:{
    display: "flex",
    justifyContent: "flex-start",
    margin: "8px 0px",
    // paddingLeft:"15px"
  },

  legendListAdmin:{
    display: "flex",
    justifyContent: "flex-start",
    margin: "8px 0px",
  },

  legendClass: {
    // maxHeight:"100px",
    overflow: "auto",
    justifyContent: "flex-start",
  },

  ...genericStyles.cardWrapperShadows(theme),
  ...commonShadows,
  ...fontStyleClass,
}));

var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function() {
    originalDoughnutDraw.apply(this, arguments);

    var chart = this.chart.chart;
    var ctx = chart.ctx;
    var width = chart.width;
    var height = chart.height;

    var fontSize = (height / 114).toFixed(2);
    ctx.font = fontSize + "em Verdana";
    ctx.textBaseline = "middle";

    var text = chart.config.data.total_count,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;

    ctx.fillText(text, textX, textY);
  }
});

class DonutWithText extends React.Component {

  render() {
    return (

        <Doughnut
          ref={this.props.inputEl}
          options={{
            responsive: true,
            // maintainAspectRatio: false,
            plugins: {
              datalabels: {
                display: true,
                align: "bottom",
                backgroundColor: "#ccc",
                borderRadius: 3,
                font: {
                  size: 18,
                },
              },
            },
            // elements: {
            //   arc: {
            //     borderWidth: 5,
            //   },
            // },
            // title: {
            //   display: false,
            //   text: this?.props?.name,
            //   fontSize: 16,
            //   position: "bottom",
            //   lineHeight: 1.8,
            // },
            legend: {
              display: false,
              // position: "bottom",
            },
            // animation: {
            //   animateScale: true,
            // },
            rotation: 2 * Math.PI,
          }}
          data={this.props.data}
        />
    );
  }
}

function MiniCharts({ cardLabel, ...props }) {
  const classes = useStyles(props);
  const inputEl = useRef(null);
  const [legend, setLegend] = useState([]);

  const theme = useTheme();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setLegend(inputEl?.current?.chartInstance?.legend?.legendItems);
  });



  return (
    <div
      style={{ height: "100%" }}
      className={`${classes.commonShadows} ${classes.cardWrapperShadows}`}
    >
      <Grid container>
        {/* Row 1 */}
        <Grid item container xs={12}>
          {/* Header */}
          <div style={{ padding: "12px 16px", width: "100%" }}>
            <Grid item container>
              <Grid item xs={12} container>
                <Grid item>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1" className={classes.mediumFont}>
                      {cardLabel ? cardLabel : "COUNT"}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs>
                  {/* This Grid is needed for the spacing in-between header text and the icon */}
                </Grid>
                {/* <Grid item>
                  <div style={{ display: "flex" }}>
                    <IconButton className={classes.rowOneIcons}>
                      <CloseRoundedIcon htmlColor={"#B0B4BE"} />
                    </IconButton>
                  </div>
                </Grid> */}
              </Grid>
            </Grid>
          </div>

          {/* Header Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Actual Doughnut Graph */}
          <Grid item xs>
            <div style={{ margin: "8px 16px" }}>
              {props?.data?.datasets?.[0]?.data === undefined ? (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" className={classes.mediumFont}>
                    No data Found
                  </Typography>
                </div>
              ) : props?.data?.datasets?.[0]?.data?.filter(
                (x) => x > 0
              ).length === 0 ? (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" className={classes.mediumFont}>
                    No data Found
                  </Typography>
                </div>
              ) : (
                // <Doughnut
                //   ref={inputEl}
                //   options={{
                //     responsive: true,
                //     // maintainAspectRatio: false,
                //     plugins: {
                //       datalabels: {
                //         display: true,
                //         align: "bottom",
                //         backgroundColor: "#ccc",
                //         borderRadius: 3,
                //         font: {
                //           size: 18,
                //         },
                //       },
                //     },
                //     // elements: {
                //     //   arc: {
                //     //     borderWidth: 5,
                //     //   },
                //     // },
                //     // title: {
                //     //   display: false,
                //     //   text: this?.props?.name,
                //     //   fontSize: 16,
                //     //   position: "bottom",
                //     //   lineHeight: 1.8,
                //     // },
                //     legend: {
                //       display: false,
                //       // position: "bottom",
                //     },
                //     // animation: {
                //     //   animateScale: true,
                //     // },
                //     rotation: 2 * Math.PI,
                //   }}
                //   data={props.data}
                // />
                <DonutWithText data={props.data} inputEl={inputEl}/>
              )}
            </div>
          </Grid>

          {/* Footer Divider */}
          {/* <Grid item xs={12}>
            <Divider />
          </Grid> */}

          {/* Footer Legends */}
          {props?.data?.datasets?.[0]?.data!== undefined && props?.data?.datasets?.[0]?.data?.filter(
                (x) => x > 0
              ).length !== 0  && (
            <Grid item xs>
              <div
                className={
                  theme.breakpoints.up("md") ? classes.legendClass : ""
                }
              >

                  <ul className={classes.legendWrapper}>
                    {(legend !== undefined && legend.length) &&
                      legend.map((item, index) => {
                        return (
                          <li
                            key={item.text}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className={classes.legendListNorm}
                          >
                            <div style={{display: 'flex', justifyContent: "center", alignItems: "center"}}>
                              <span
                                style={{
                                  marginRight: "8px",
                                  // marginTop: "6px",
                                  // marginLeft:"25%",
                                  width: "8px",
                                  height: "8px",
                                  borderRadius: "50%",
                                  backgroundColor: item.fillStyle,
                                  // position: "relative",
                                  // top: 2,
                                }}
                              ></span>
                              <Typography>{item.text}</Typography>
                            </div>
                            <Typography  style={{ margin: "0px 8px" }}>
                              {props?.data?.datasets?.[0]?.data?.[index]}
                            </Typography>
                          </li>
                        );
                      })}
                  </ul>

              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default MiniCharts;

MiniCharts.propTypes = {
  /**The color must be a hex code. */
  iconColor: PropTypes.string,
};
