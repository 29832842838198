import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyle = makeStyles((theme) => ({
  cls1: {
    fill: (props) => props.htmlColor
  },
  customIconStyle: {
    fontSize: "1.1rem"
  }
}))

export function HistoryIcon(props) {
  const classes = useStyle(props)
  return (
    <SvgIcon classes={{ root: classes.customIconStyle }}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> */}
      <path id="icons8-available-updates" className={classes.cls1} d="M15,3A12.014,12.014,0,0,0,3,15H5.667A9.307,9.307,0,0,1,22.211,9.122L19,12.333h8v-8L24.112,7.221A11.947,11.947,0,0,0,15,3Zm9.333,12A9.307,9.307,0,0,1,7.789,20.878L11,17.667H3v8l2.888-2.888A11.983,11.983,0,0,0,27,15Z" transform="translate(-3 -3)" />
      {/* </svg> */}
    </SvgIcon>
  )
}

HistoryIcon.defaultProps = {
  htmlColor: "#9297a5"
}

HistoryIcon.propTypes = {
  /**Sets the color of the icon. */
  htmlColor: PropTypes.string
}