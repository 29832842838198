import React from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Menu } from './menu';
import { SubMenu } from './subMenu';
import TuiImageEditor from "tui-image-editor";
import { createTestProps } from '../../../../../../utils/common';

const styles = (theme) => ({
    title: {
        padding: theme.spacing(3),
        '& .MuiTypography-root': {
            fontSize: 18,
            color: '#3B3F5B',
            fontWeight: 500
        }
    },
    editBanner: {
        display: 'flex',
        [theme.breakpoints.down('sm')]:{
            flexDirection:'column'
        },
    },
    tuiImageWrapper: {
        width: 554,
        height: 337,
        borderRadius: 8,
        overflow: 'hidden',
        margin: '24px 0 24px 24px',
        '& .tui-image-editor': {
            width: 'inherit',
            height: 'inherit',
            '& .tui-image-editor-canvas-container':{
                margin:'auto'
            }
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            margin:0,
            padding:theme.spacing(3),
        },
    },
    divider: {
        height: 1,
        backgroundColor: '#707070',
        opacity: 0.35
    },
    actionBtns:{
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-end',
        [theme.breakpoints.up('sm')]:{
            marginRight:theme.spacing(6)
        },
        '& .MuiButtonBase-root':{
            textTransform:'capitalize',
            height:40,
            marginBottom:theme.spacing(3),
            marginRight:theme.spacing(2)
        },
        '& .MuiButton-outlined':{
            minWidth:100,
            color:'#FF4D4A',
            borderColor:'#D8D8D8',
        },
        '& .MuiButton-contained':{
            minWidth:82,
        },
    }
});

class EditBanner extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeMenu: 'shapes'
        }
    }

    rootEl = React.createRef();
    imageEditorInst = null;

    componentDidMount() {
        this.initialize();
    }

    initialize = async () => {
        this.imageEditorInst = new TuiImageEditor(this.rootEl.current, {
            // ...this.props,
            cssMaxWidth: 700,
            cssMaxHeight: 500,
            // selectionStyle: {
            //     cornerSize: 20,
            //     rotatingPointOffset: 70,
            // },
            
        });

        if (this.props?.src) {
            await this.imageEditorInst.loadImageFromURL(
                // "https://cdn.rawgit.com/nhnent/tui.component.image-editor/1.3.0/samples/img/sampleImage.jpg",
                this.props?.src,
                "SampleImage"
            );
            //set image size 
            this.imageEditorInst.resize({width:554,height:337})

        }


        this.imageEditorInst && this.imageEditorInst.on({
            addText: (e) => {
                this.imageEditorInst.addText('Double Click', {
                    position: e.originPosition,
                    styles: {
                        fontSize: 40,
                        fontFamily: 'Roboto'
                    }
                })
            },
            objectActivated: (obj) => {
                this.onChangeState('activeObject', obj);
                if (obj.type === 'i-text') {
                    this.activateDrawingMode('TEXT');
                }
                else if (obj.type === 'rect' || obj.type === 'circle' || obj.type === 'triangle') {
                    this.activateDrawingMode('SHAPE');
                }
                else if (obj.type === 'icon') {
                    this.imageEditorInst.stopDrawingMode();
                }
            },
            objectScaled: (obj) => {
                if (obj.type === 'i-text') {
                    this.imageEditorInst.changeTextStyle(this.state.activeObject?.id, {
                        fontSize: parseInt(obj.fontSize, 10),
                    });
                }
            }
        });
    };

    componentWillUnmount() {
        this.imageEditorInst.destroy();
        this.imageEditorInst = null;
    }

    onChangeState = (key, value) => {
        this.setState({
            ...this.state, [key]: value
        })
    }

    handleMenu = (value) => {
        if (value === "text") {
            this.activateDrawingMode('TEXT')
        }
        else if (value === "star") {
            this.imageEditorInst.stopDrawingMode();
        }
        this.onChangeState('activeMenu', value)
    }

    activateDrawingMode = (key) => {
        if (this.imageEditorInst.getDrawingMode() !== key) {
            this.imageEditorInst.stopDrawingMode();
            this.imageEditorInst.startDrawingMode(key);
        }
    }

    handleTextSubMenu = (value, key) => {
        let styleObj = {}
        if (key === "fill") {
            styleObj = { [key]: value };
        }
        else {
            switch (value) {
                case 'Bold':
                    styleObj = { fontWeight: 'bold' };
                    break;
                case 'Italic':
                    styleObj = { fontStyle: 'italic' };
                    break;
                case 'Underline':
                    styleObj = { textDecoration: 'underline' };
                    break;
                case 'Left':
                    styleObj = { textAlign: 'left' };
                    break;
                case 'Center':
                    styleObj = { textAlign: 'center' };
                    break;
                case 'Right':
                    styleObj = { textAlign: 'right' };
                    break;
                default:
                    styleObj = {};
            }
        }

        this.imageEditorInst.changeTextStyle(this.state.activeObject?.id, styleObj);
        this.setState({
            ...this.state,
            [this.state.activeObject?.id]: { ...this.state[this.state.activeObject?.id], ...styleObj }
        })
    }

    handleShapeSubMenu = (value, key) => {
        if (this.state.activeObject?.id && key) {
            this.imageEditorInst.changeShape(this.state.activeObject?.id, {
                [key]: value,
            });
            this.setState({
                ...this.state,
                [this.state.activeObject?.id]: { ...this.state[this.state.activeObject?.id], ...{ [key]: value } }
            })
            this.activateDrawingMode('NORMAL')
        }
        else {
            let styleObj = {};
            let shapeType = "";
            if (value === "Rectangle") {
                shapeType = "rect"
                styleObj = {
                    // fill: '',
                    stroke: 'red',
                    strokeWidth: 1,
                    width: 100,
                    height: 200
                }
            }
            else if (value === "Circle") {
                shapeType = "circle"
                styleObj = {
                    // fill: 'red',
                    stroke: 'red',
                    strokeWidth: 1,
                    isRegular: true
                }
            }
            else if (value === "Triangle") {
                shapeType = "triangle"
                styleObj = {
                    // fill: 'red',
                    stroke: 'red',
                    strokeWidth: 1,
                    width: 1,
                    height: 1,
                }
            }
            this.activateDrawingMode('SHAPE');
            this.imageEditorInst.setDrawingShape(shapeType, styleObj);
        }
    }

    handleStarSubMenu = (value, key) => {
        if (this.state.activeObject?.id && key) {
            this.imageEditorInst.changeIconColor(this.state.activeObject?.id, value);

            this.setState({
                ...this.state,
                [this.state.activeObject?.id]: { ...this.state[this.state.activeObject?.id], ...{ [key]: value } }
            })
        }
        else {
            let customIcon, path;

            customIcon = value.text;
            path = value.path;

            if (customIcon && path) {
                this.imageEditorInst.registerIcons({
                    [customIcon]: path
                });

                this.imageEditorInst.once('mousedown',(e, originPointer)=>{
                    this.imageEditorInst
                      .addIcon(customIcon, {
                        left: originPointer.x,
                        top: originPointer.y,
                      })
                      .then(function (objectProps) {
                        // console.log(objectProps);
                      }).catch(e=>{
                          console.log('unable to add icon')
                      });
                  });

                  // this.imageEditorInst.addIcon(customIcon);


            }
        }

    }

    save = () =>{
        if(this.state.activeObject?.id){
            let imageURL = this.imageEditorInst.toDataURL();
            this.props.save(imageURL);
        }
        else{
            this.props.save()
        }
    }
    

    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.title}>
                    <Typography>Edit Banner</Typography>
                </div>
                <div className={classes.divider} />
                <div className={classes.editBanner}>
                    <div className={classes.tuiImageWrapper}>
                        <div className="tui-image-editor" ref={this.rootEl} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <SubMenu
                            activeMenu={this.state.activeMenu}
                            activeObject={this.state.activeObject}
                            activeObjectValues={this.state?.[this.state.activeObject?.id]}
                            handleTextSubMenu={this.handleTextSubMenu}
                            handleShapeSubMenu={this.handleShapeSubMenu}
                            handleStarSubMenu={this.handleStarSubMenu}
                        />
                    </div>
                    <Menu
                        // handleChange={(value)=>this.onChangeState('activeMenu',value)}
                        handleChange={(value) => this.handleMenu(value)}
                        activeMenu={this.state.activeMenu}
                    />
                </div>
                <div className={classes.actionBtns}>
                    <Button variant="outlined" onClick={()=>this.props.cancel(this.state.activeObject?.id)} {...createTestProps("cancel edit image")}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={()=>this.save()} {...createTestProps("save edit image")}>Save</Button>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(EditBanner);
