import React from "react";
import PropTypes from "prop-types";

import { SvgIcon } from "@material-ui/core";

function GpsIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.04 0v1.967a10.101 10.101 0 0 0-9.067 9.073H0v1.92h1.967a10.103 10.103 0 0 0 9.066 9.075V24h1.92v-1.967a10.1 10.1 0 0 0 9.067-9.073H24v-1.92h-1.967a10.101 10.101 0 0 0-9.066-9.067V0H11.04Zm1.92 3.901a8.133 8.133 0 0 1 7.139 7.139H18.72v1.92h1.379a8.133 8.133 0 0 1-7.139 7.139V18.72h-1.92v1.379a8.133 8.133 0 0 1-7.139-7.139H5.28v-1.92H3.901a8.133 8.133 0 0 1 7.139-7.139V5.28h1.92V3.901Z"
        fill={props.color}
      />
      <path
        d="M12 14.4a2.4 2.4 0 1 0 0-4.8 2.4 2.4 0 0 0 0 4.8Z"
        fill={props.color}
      />
    </SvgIcon>
  );
}

GpsIcon.propTypes = {
  color: PropTypes.string,
};

GpsIcon.defaultProps = {
  color: "#6F6F6F",
};

export default GpsIcon;
