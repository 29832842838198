import React from "react";
import { withStyles, Grid, Typography, Avatar } from "@material-ui/core";
import { FooterDetails } from './footer'
import { PriceTagIcon } from '../../../assets'
// import Icon from "../../../assets/product Management/listingScreen/premium.svg";

const styles = (theme) => ({
  root: {
    boxShadow: `0px 0.5px 2px 1px #e0e0e0d6`,
    borderRadius: 8,
    background: "white"
  },
  topPaddingAndBorder: {
    padding: "12px 20px",
    borderBottom: `0.5px solid ${theme.palette.secondary.light}`
  },
  topPaddingOnly: {
    padding: "12px 20px",
  },
  leftContainer: {
    display: "flex"
  },
  spaceIt: {
    marginLeft: 14
  },
  rightContainer: {
    display: "flex"
  },
  offerIcon: {
    alignSelf: "center",
    height: 15,
    marginRight: 8
  },
  priceText: {
    alignSelf: "center"
  },
  avatar: {
    height: 60,
    width: 60,
    backgroundColor: theme.palette.secondary.light
  },
  iconAvatar: {
    padding: 16,
  },
});

const status = {
  RECOMMENDED: 'recommended',
  CONSENTRECEIVED: 'consentreceived',
  CONSENTDENIED: 'consentdenied',
  PURCHASED: 'purchased'
}

class RecommendedProducts extends React.Component {
  render() {
    const { classes, hideFooter, isAvatar } = this.props;
    const { product_image, product_id, product_name, product_price, current_status, current_status_date } = this.props;
    const statusDesc = current_status.toLowerCase().trim()
    return (
      <Grid container className={classes.root}>
        {/* Top */}
        <Grid
          item
          container
          justify="space-between"
          className={!hideFooter ? classes.topPaddingAndBorder : classes.topPaddingOnly}
        >
          <Grid item className={classes.leftContainer}>
            {!isAvatar && <img
              src={product_image}
              alt={product_id}
              height="60"
              width="60"
              style={{ marginRight: 14 }}
            />}
            {isAvatar && <Avatar src={"/images/tanyaCare/Tanya.png"} className={`${classes.iconAvatar} ${classes.avatar}`} />}
            <Typography variant="body1" style={{ alignSelf: "center" }}
              className={isAvatar ? classes.spaceIt : ''}>
              {product_name ? product_name : 'N/A'}
            </Typography>
          </Grid>
          <Grid item className={classes.rightContainer}>
            <PriceTagIcon className={classes.offerIcon} />
            <Typography variant="body1" className={classes.priceText}>
              {product_price ? product_price : 'N/A'}
            </Typography>
          </Grid>
        </Grid>



        {/* Bottom */}
        {!hideFooter && <FooterDetails status_color={statusDesc === status.RECOMMENDED ? '#353596' : statusDesc === status.CONSENTRECEIVED ? '#31b198' : statusDesc === status.CONSENTDENIED ? '#bc2d4f' : statusDesc === status.PURCHASED ? '#f27d35' : 'gray'} current_status={current_status} current_status_date={current_status_date} />}
      </Grid>
    );
  }
}

export default withStyles(styles)(RecommendedProducts);