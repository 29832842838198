import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Grid,
  InputLabel,
  FormGroup,
  makeStyles,
  Typography,
} from "@material-ui/core";

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.ternary.main,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
  label: {
    color: theme.palette.text.label
  }
}));

export const CheckboxCustom = (props) => {
  const { data, value, handleOnChange, error, errorText, id } = props;

  const classes = styles();
  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        {data.withBoldHeading ? (
          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginBottom: 8 }}>
              {data.label}
            </Typography>
          </Grid>
        ) : (
            <>
              {data.label ? (
                <Grid item xs={1}>
                  <InputLabel shrink component="legend" className={classes.label}>
                    {data.label}
                  </InputLabel>
                </Grid>
              ) : null}
            </>
          )}

        <Grid item xs={data.withBoldHeading || !data.label ? 12 : 11}>
          <FormGroup row id={id}>
            {data.options.map((val) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value.indexOf(val.value) > -1}
                      value={val.value}
                      onChange={(e) => { handleOnChange(e) }}
                      name={data.key}
                    />
                  }
                  label={val.label}
                />
              );
            })}
          </FormGroup>
        </Grid>
      </Grid>
      {error && (
        <Typography
          variant="body1"
          component="div"
          className={classes.errorText}
        >
          {errorText}
        </Typography>
      )}
    </>
  );
};
