import React from "react";

import { HybridTypography } from "../../../../components";
import { ICONS } from "../../../../components/hybridTypography/typography";

import { Paper, withStyles, Grid } from "@material-ui/core";
import { customAddress } from "../../../../utils";

const styles = (theme) => ({
  paperCard: {
    borderRadius: "0px",
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
  },
  gridTopItems: {
    padding: "25px 25px 0px 32px",
  },
  gridMiddleItems: {
    padding: "0px 25px 0px 32px",
  },
});

class DetailsCard extends React.Component {
  render() {
    const { classes, data, loading } = this.props;

    return (
      <Paper className={classes.paperCard}>

        <Grid container>

          {/* Row 1 */}
          <Grid
            container
            item
            direction="row"
            md={12}
            sm={12}
            xs={12}
            className={classes.gridTopItems}
          >
            {/* Phone */}
            <Grid item xs={6} sm={6} md={6}>
              <HybridTypography
                icon={ICONS.CLIENTPHONE("#28a9c1")}
                text={data?.mobile_no_isd_code?.toString()?.includes("+") ? data?.mobile_no_isd_code : `+${data?.mobile_no_isd_code} ${data?.mobile_no}`}
                header="MOBILE"
                loading={loading}
                islistbottom={true}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={6} sm={6} md={6}>
              <HybridTypography
                text={data?.email}
                header="EMAIL ADDRESS"
                loading={loading}
                islistbottom={true}
                icon={ICONS.EMAIL("#28a9c1")}
              />
            </Grid>
          </Grid>

          {/* Row 2 */}
          <Grid
            container
            item
            direction="row"
            md={12}
            sm={12}
            xs={12}
            className={classes.gridMiddleItems}
          >
            <Grid item xs={6} sm={6} md={6}>
              <HybridTypography
                icon={ICONS.LOCATION("#28a9c1")}
                text={customAddress(data?.addressLine1, data?.addressLine2, data?.area, data?.state, data?.country, data?.postalCode)}
                header="ADDRESS"
                loading={loading}
                islistbottom={true}
              />
            </Grid>
          </Grid>

        </Grid>

      </Paper>
    );
  }
}

export default withStyles(styles)(DetailsCard);
