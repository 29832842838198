import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Component } from "react";
// import { FilterIcon } from '../../assets'
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import PropTypes from "prop-types";
import { fontStyleClass, commonShadows, genericStyles, } from "../../utils";
import { createTestProps } from "../../utils/common";
import { StyledTableRow } from "./styledTableRow";

const style = (theme) => ({
  rowOneIcons: {
    border: "1px solid #E8E8EC",
    margin: "0px 4px",
    padding: 8,
    width: 46,
  },
  customTableStickyHeader: {
    backgroundColor: "white",
  },
  formControl: {
    margin: "3px 8px",
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.ternary.main} !important`,
        borderWidth: "1.6px !important"
      }
    }
  },
  ...genericStyles.cardWrapperShadows(theme),
  ...commonShadows,
  ...fontStyleClass
});

class DashboardListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.props?.filterOptions?.[0].value || "", //Sets the first elements value from array of objects of filterOptions prop
    };
  }

  handleChange = (event) => {
    // console.log(event);
    this.setState(
      {
        filter: event.target.value,
      },
      () => {
        // console.log(this.state);
      }
    );
  };

  render() {
    const { classes } = this.props;
    const { filter } = this.state;
    const {
      // listTitle,
      filterOptions,
      totalCount,
      filterDateRange,
      tableData,
      hideFilterAndClose,
      hideTotalCount,
    } = this.props;
    // console.log(this.props.tableData)
    return (
      <div style={{ width: "100%" }} className={`${classes.commonShadows} ${classes.cardWrapperShadows}`} >
        <Grid container>
          {/* Header */}
          <div style={{ padding: 7 ,paddingLeft: 16, paddingRight: 16, width: "100%" }}>
            <Grid item container xs={12}>
              <Grid item container>
                <Grid item>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" className={classes.mediumFont}>
                      {/* {listTitle ? listTitle : "Resource(s) Count"} */}
                      Top {this.state.filter}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs>
                  {/* This Grid is needed for the spacing in-between header text and the icon */}
                </Grid>

                <>
                  <Grid item>
                    <div style={{ display: "flex" }}>
                      {/* <IconButton className={classes.rowOneIcons}> 
                      <FilterListRoundedIcon htmlColor={"#B0B4BE"} />
                    </IconButton> */}

                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Select
                          value={filter}
                          onChange={this.handleChange}
                          inputProps={{...createTestProps(`select top service`)}}
                          style={{
                            height: 30,
                            width: 200,
                            overflow: "hidden",
                          }}
                        >
                          {filterOptions?.map((item, index) => {
                            return (
                              <MenuItem key={item.value} value={item.value} {...createTestProps(`top service ${item.value}`)}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {!hideFilterAndClose && (
                        <>
                          <IconButton className={classes.rowOneIcons}>
                            <FilterListRoundedIcon htmlColor={"#B0B4BE"} />
                          </IconButton>
                          <IconButton className={classes.rowOneIcons}>
                            <CloseRoundedIcon htmlColor={"#B0B4BE"} />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </div>

          {/* Header Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Main container row 2 */}
          <div style={{  width: "100%" }}>
            <Grid item container xs={12}>
              {!hideTotalCount && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      {totalCount ? totalCount : 0}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="secondary"
                      style={{ marginBottom: 16 }}
                    >
                      {filterDateRange ? filterDateRange : ""}
                    </Typography>
                    {/**From Aug 01 to Aug 31, 2020 */}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              
              {/* Table Starts */}
              <Grid item xs={12} style={{ maxHeight: 350, overflow: "scroll" }}>
                {/* Table */}
                { tableData?.data[this.state.filter].length > 0 ?
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {tableData &&
                        tableData.header.map((item, index) => (
                          <TableCell
                            classes={{
                              stickyHeader: classes.customTableStickyHeader,
                            }}
                            style={{padding: "7px 16px"}}
                          >
                            <Typography color="secondary" style={{fontWeight:"bold"}}>{item}</Typography>
                          </TableCell>
                        ))}
                      {!tableData && (
                        <TableCell
                          classes={{
                            stickyHeader: classes.customTableStickyHeader,
                          }}
                        >
                          <Typography color="secondary">Category</Typography>
                        </TableCell>
                      )}
                      {/* The following two cells are for spacing purpose don't remove */}
                      {/* <TableCell classes={{ stickyHeader: classes.customTableStickyHeader }}></TableCell>
                      <TableCell classes={{ stickyHeader: classes.customTableStickyHeader }}></TableCell> */}

                      {/* <TableCell classes={{ stickyHeader: classes.customTableStickyHeader }} >
                        <Typography color="secondary">
                          Category
                        </Typography>
                      </TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {tableData &&
                      tableData?.data[this.state.filter]?.map((row, index) => (
                        <StyledTableRow key={index}>
                          {console.log(row)}
                          {Object.keys(row).map((columns) => (
                            <TableCell>
                              <Typography>{row[columns]?.toString()}</Typography>
                            </TableCell>
                          ))}

                          {/* The following two cells are for spacing purpose don't remove */}
                          {/* <TableCell></TableCell>
                        <TableCell></TableCell> */}
                        </StyledTableRow>
                      ))}
                    {!tableData && (
                      <TableCell>
                        <Typography>No Data</Typography>
                      </TableCell>
                    )}
                  </TableBody>
                </Table>: <><Typography align="center" variant="body1" color="textSecondary">No Data</Typography></>}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </div>
    );
  }
}

export default withStyles(style)(DashboardListView);

DashboardListView.propTypes = {
  /**Sets the title of the table. Renders <Typography></Typography> component with variant ```h6```. */
  listTitle: PropTypes.string,
  /**Uses the array of value and label to render filter options. */
  filterOptions: PropTypes.arrayOf(Object),
  /**Sets the total count of entity. */
  totalCount: PropTypes.number,
  /**Sets the filter date range. */
  filterDateRange: PropTypes.string,
  /**Renders the table header and table data. */
  tableData: PropTypes.array,
  // Hiding Elements Props
  /**Hides Filter and close card items. */
  hideFilterAndClose: PropTypes.bool,
  /**Hides the Total Count section. */
  hideTotalCount: PropTypes.bool,
};
