import {
  Button,
  Divider,
  Grid,
  // IconButton,
  makeStyles,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions } from "tanyacare-middleware";
import {
  DateTimePickersCustom,
  HybridTypography,
} from "../../../../components";
import { Information } from "../../../../components/information";
import { ListCard } from "../../../../components/subscriptions";
import CardSkeleton from "../../../../components/userList/cardSkeleton";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { routes } from "../../../../router/routes";
import {
  alertProps,
  capitalizeFirstLetter,
  scrollToTop,
  user_roles,
  // user_roles,
} from "../../../../utils";
import AddEditSubscription from "./addEditSubscription";
import DetailsCard from "./detailCard";
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AssignCoordinatorDrawer from "../../../monitoring/subscriptions/AssignCoordinator";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { MobileDatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { IdCardIconV2, DOBIconV2 } from "../../../../assets"
import { isTrialAccountType } from "../../../../utils/common";
// import { Clear as ClearIcon } from "@material-ui/icons";

// const sizes = {
//   md: 12,
//   sm: 6,
//   xs: 12,
// };

const styles = (theme) => ({
  sectionContainer: {
    padding: 25,
    background: "white",
  },
  calendarIconRoot: {
    fontSize: "1.05rem",
  },
  endDateClear: {
    color: theme.palette.ternary.main,
  },
});

class ClientDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      effectiveFrom: null,
      endsOn: null,
    };
  }

  componentDidMount() {
    this.getClientDetail();
    this.categories();
    this.props.GET_SAL({});
    scrollToTop();
  }

  categories = () => {
    this.props.GET_CATEGORIES({});
  };

  getClientDetail = async (isDrawerClose) => {
    const id = this.props?.match?.params?.id;

    await this.props.GET_CARE_GIVER_CLIENT_INFO({ id });

    this.closeDrawer();

    const { data } = this.props?.careGiverClientInfo;
    const effectiveFrom = data[0]?.resourceEffectiveFrom;
    const endsOn = data[0]?.resourceEndsOn ? data[0]?.resourceEndsOn : null;
    this.setState({
      effectiveFrom,
      endsOn,
    });
  };

  // onDeactivateClicked = () => {
  //   const Oldstatus = this.props?.careGiverClientInfo?.data?.[0]?.is_active;
  //   let status = "Activate";
  //   if (Oldstatus) {
  //     status = "Deactivate";
  //   }

  //   let hasActiveSubscription = 0;

  //   let arr = this?.props?.careGiverClientInfo?.data?.subscriptions?.map(_ => {
  //     if (_?.active) {
  //       hasActiveSubscription = hasActiveSubscription + 1;
  //     }
  //     return _;
  //   })

  //   this.props.dialog.setDialog({
  //     open: true,
  //     title: "Are you sure?",
  //     body: `Do you want to ${status} Caregiver Client: ${this?.props?.careGiverClientInfo?.data?.[0]?.name}
  //     ${hasActiveSubscription ? `(${this?.props?.careGiverClientInfo?.data?.name} has ${hasActiveSubscription} active subscription out of ${arr.length})` : ''} ?`,
  //     positiveBtn: !Oldstatus ? "ACTIVATE" : "DEACTIVATE",
  //     negativeBtn: "Cancel",
  //     onOk: this.deactiveUser,
  //   });
  // };

  // deactiveUser = () => {
  //   const id = this.props?.match?.params?.id;
  //   const Oldstatus = this.props?.careGiverClientInfo?.data[0]?.is_active;

  //   this.props.dialog.setDialog({ open: false });

  //   this.props.UPDATE_CLIENT_INFO_STATUS({ client_id: id, status: !Oldstatus }); //Problem here
  // };

  handleResend = async () => {
    const id = this.props?.match?.params?.id;
    let role = "client";
    // console.log(id)
    let data = await this.props.RESEND_INVITE({ user_id: id, role });
    if (data?.payload?.state === 200) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Invite Sent successfully!",
        severity: alertProps.severity.success,
      });
    } else {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Something went wrong! Unable to invite.",
        severity: alertProps.severity.error,
      });
    }
  };

  giveMeNecessaryData = () => {
    const { location, careGiverClientInfo } = this.props;
    const { data } = careGiverClientInfo;
    let necessaryData = {
      ...careGiverClientInfo,
    };

    if (location?.state) {
      necessaryData = {
        ...location.state,
        ...careGiverClientInfo,
      };
    }

    if (data?.[0]) {
      const _ = data[0];
      const header = [
        {
          id: _?.id,
          name: `${_?.salutationName}${". "}${_?.name}`,
          // salutation: salutationOptions?.length > 0 ? salutationOptions?.filter(sal => parseInt(_?.salutation) === sal?.value)?.[0]?.label : [{}],
          category: [
            {
              label: `${capitalizeFirstLetter(_?.gender)}, ${
                moment().diff(_?.dob, "years") === 0
                  ? moment().diff(_?.dob, "days") + " days old "
                  : moment().diff(_?.dob, "years") + " years old "
              }`,
              // , Client ID : ${_?.clientHealthId}
            },
          ],
          isactive: _?.is_active,
          emailVerified: _?.emailVerified,
          profile_pic: _?.profile_pic,
          aliasId: _?.aliasId,
          aliasLabel: "ID",
          trialExpiry: _?.resourceEndsOn ?? ""
        },
      ];

      const footer = [
        {
          name: `${_.care_giver_count ? _.care_giver_count : "0"} Caregivers`,
        },
      ];

      necessaryData = {
        ...careGiverClientInfo,
        header,
        footer,
      };
    }

    return necessaryData;
  };

  shouldComponentUpdate(nextProps, nextState) {
    //
    const { client_status_update } = nextProps;
    const oldclient_status_update = this.props.client_status_update;

    if (oldclient_status_update?.loading !== client_status_update?.loading) {
      if (!client_status_update.loading && !client_status_update.error) {
        this.getClientDetail();
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Client status updated successfully!",
          severity: alertProps.severity.success,
        });
      } else if (client_status_update.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to update.",
          severity: alertProps.severity.error,
        });
      }
    }

    //Deactivate/Activate Subscription
    const { status_update } = nextProps;
    const old_status_update = this.props.status_update;

    //Checking for Deactivate/Activate subscription
    if (old_status_update?.loading !== status_update?.loading) {
      if (!status_update.loading && !status_update.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Subscription Status updated successfully!",
          severity: alertProps.severity.success,
        });
        this.getClientDetail(true);
      } else if (status_update.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to update subscription status",
          severity: alertProps.severity.error,
        });
      }
    }

    //Activation/Deactivation Data
    const { upsert_client } = nextProps;
    const old_upsert_client = this.props.upsert_client;

    //Checking for Deactivate/Activate subscription
    if (old_upsert_client?.loading !== upsert_client?.loading) {
      if (!upsert_client.loading && !upsert_client.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Client activation/deactivation date updated successfully!",
          severity: alertProps.severity.success,
        });
        this.getClientDetail();
        this.props.dialog.setDialog({ open: false });
      } else if (upsert_client.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: upsert_client.message
            ? upsert_client.message
            : "Something went wrong! Unable to update client activation/deactivation date.",
          severity: alertProps.severity.error,
        });
        this.revertDateChanges();
      }
    }
    return true;
  }

  revertDateChanges = () => {
    let { effectiveFrom, endsOn } = this.state;
    const { data } = this.props?.careGiverClientInfo;
    const effectiveFromDb = data[0]?.resourceEffectiveFrom;
    const endsOnDb = data[0]?.resourceEndsOn;

    if (!moment(effectiveFrom)?.isSame(effectiveFromDb)) {
      effectiveFrom = effectiveFromDb;
    }
    if (!moment(endsOn)?.isSame(endsOnDb)) {
      endsOn = endsOnDb;
    }

    this.setState({
      effectiveFrom,
      endsOn,
    });
  };

  disableDeactivateBtn = (key, id) => {
    // const { data } = this.props.auth_data;

    if (key === "deactivateBtn") {
      // if (user_roles.provider === data.user_role && id !== data.user_id) {
      //   return false;
      // } else if (user_roles.super_admin === data.user_role) {
      //   return false;
      // } else {
      //   return false;
      // }
      return false;
    }
  };

  handleDeactivateSubscription = (subscription) => {
    const Oldstatus = subscription?.active;
    let status = "Activate";
    if (Oldstatus) {
      status = "Deactivate";
    }
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body: `Do you want to ${status} Subscription: ${subscription.subscriptionName}`,
      positiveBtn: status,
      negativeBtn: "Cancel",
      onOk: () => this.deactiveSubscription(subscription),
    });
  };

  deactiveSubscription = (subscription) => {
    this.props.dialog.setDialog({ open: false });

    this.props.UPDATE_SUBSCRIPTION_STATUS({
      subscriptionId: subscription.id,
      status: !subscription.active,
      clientEmail: this.props.careGiverClientInfo?.data[0].email,
      clientSalutation: this.props.careGiverClientInfo?.data[0]?.salutationName,
      subscriptionType: subscription?.subscriptionType,
      subscriptionName: subscription.subscriptionName,
      clientName: this.props.careGiverClientInfo?.data[0].name,
    });
  };

  onAddSubscriptionClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: <AddEditSubscription getClientDetail={this.getClientDetail} />,
      onclose: this.closeDrawer,
    });
  };

  onEditSubscriptionClicked = (data) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <AddEditSubscription
          isEdit={true}
          editData={data}
          getClientDetail={this.getClientDetail}
        />
      ),
      onclose: this.closeDrawer,
    });
  };

  assignCoordinatorDrawer = (partnerId, subscriptionId) => {
    console.log(subscriptionId);
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <AssignCoordinatorDrawer
          partnerId={partnerId}
          subscriptionId={subscriptionId}
          // coordinatorId={
          //   this.props.sub_details?.data?.service_coord?.[0]?.value
          //     ? this.props.sub_details?.data?.service_coord?.[0]?.value
          //     : ""
          // }
          assigncoordinator={this.assignCoordinator}
          onCancel={this.closeDrawer}
        />
      ),
      onclose: this.closeDrawer,
    });
  };

  assignCoordinator = async (error, data, url, subscriptionId) => {
    console.log("Hi");
    console.log(subscriptionId);
    console.log(data?.coordinator?.value);
    await this.props.UPDATE_SUBSCRIPTION({
      subscriptionId: subscriptionId,
      coordinatorId: data?.coordinator?.value,
    });
    this.closeDrawer();
    this.getClientDetail();
  };

  closeDrawer = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  // Effective From & ends on handler //
  handleOnChange = (value, key, handleOnDialogClose = () => {}) => {
    this.setState(
      {
        [key]: value,
      },
      () => handleOnDialogClose()
    );
  };

  handleOnDialogClose = () => {
    const { effectiveFrom, endsOn } = this.state;
    const { data } = this.props?.careGiverClientInfo;
    const actualData = data?.[0];

    let payload = {
      ...actualData,
      resourceEffectiveFrom: moment(effectiveFrom)
        ?.startOf("day")
        ?.utc()
        ?.format(),
      resourceEndsOn: endsOn
        ? moment(endsOn)?.endOf("day")?.utc()?.format()
        : "",
      isEffectiveFromToValidation:
        moment(effectiveFrom)?.isSame(actualData?.resourceEffectiveFrom) &&
        moment(endsOn)?.isSame(actualData?.resourceEndsOn)
          ? false
          : true,
      // EndsOn: moment(endsOn)?.endOf('day')?.utc()?.format()
      // resourceMeta: {
      //   ...actualData?.resourceMeta,
      //   resource_validity: {
      //     ...actualData?.resourceMeta?.resource_validity,
      //     value: {
      //       [actualData?.resourceMeta?.resource_validity?.key?.["startDate"]]: moment(effectiveFrom)?.startOf('day')?.utc()?.format(),
      //       [actualData?.resourceMeta?.resource_validity?.key?.["endDate"]]: moment(endsOn)?.endOf('day')?.utc()?.format()
      //     }
      //   }
      // }
    };

    this.props.UPSERT_CLIENT(payload);
  };

  handleEndsOnClear = () => {
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body: "Do you want to remove expiry date?",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => this.handleOnChange(null, "endsOn", this.handleOnDialogClose),
    });
  };

  render() {
    const { classes } = this.props;
    let { header, data, error, loading } = this.giveMeNecessaryData(); //footer

    const id = this.props?.match?.params?.id;

    // CTA Builder
    const ctas = [
      {
        label: "Resend",
        id: `AddResendButtonFrom${id ? id : ""}`,
        onClickHandler: this.handleResend,
        additionalProps: {
          variant: "outlined",
          display:
            header?.[0]?.emailVerified ||
            header?.[0]?.emailVerified === undefined ||
            header?.[0]?.emailVerified === null
              ? false
              : true,
        },
      },
      {
        label: "Edit Details",
        id: `EditButtonFrom${id ? id : ""}`,
        onClickHandler: () =>
          this.props.history.push(routes.manage_clients + "/edit/" + id),
        additionalProps: {
          variant: "contained",
          display: true,
        },
      },
      {
        label: "Add Subscription",
        onClickHandler: this.onAddSubscriptionClicked,
        id: `AddThirdButtonFrom${id ? id : ""}`,
        additionalProps: {
          variant: "contained",
          display: header?.[0]?.emailVerified ? true : false,
        },
      },
    ];

    return (
      <>
        {(error || !data) && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <Typography variant="h6" align="center">
                Something went wrong!
              </Typography>
            </Grid>
            <Grid item>
              <Button
                style={{ marginTop: 16, textAlign: "center" }}
                variant={"outlined"}
                onClick={this.getClientDetail}
              >
                Try Again
              </Button>
            </Grid>
          </Grid>
        )}
        {!error && data && (
          <>
            <Grid item style={{ padding: "25px" }}>
              {/* <CardList
                list={header}
                componentOrder={2}
                isShowEditButton={!(data?.[0]?.is_active && !Boolean(this.props?.auth_data?.data?.user_role === user_roles.service_coordinator))}
                isShowActivate={Boolean(this.props?.auth_data?.data?.user_role === user_roles.service_coordinator)}
                sizes={sizes}
                isonlycard={true}
                isCard={false} //to make the category as a card make it true
                link={routes.manage_clients + "/edit/" + id}
                data={data[0]}
                onDeactivateClicked={this.onDeactivateClicked}
                handleResend={this.handleResend}
                isDeactivating={this.props?.updateCareProviderStatus?.loading}
                isSalutationNeeded={true}
                showThirdBtn
                thirdBtnTitle="Add Subscription"
                onThirdBtnClicked={() => this.onAddSubscriptionClicked()}
                deactivateActivateButtonId={`ActivateDeactivateButtonFrom${id ? id : ""}`}
                editButtonId={`EditButtonFrom${id ? id : ""}`}
                addBranchButtonId={`AddBranchButtonFrom${id ? id : ""}`}
                resendButtonId={`AddResendButtonFrom${id ? id : ""}`}
                ThirdButtonId={`AddThirdButtonFrom${id ? id : ""}`}
              /> */}

              <Information
                details={header}
                ctas={ctas}
                isCategoryAChip={true}
                showTrialExpiry={isTrialAccountType() && user_roles.super_admin === this.props.auth_data.data.user_role}
                loading={loading}
              />

              <DetailsCard data={data[0]} loading={loading} />

              {/* Activation Details */}
              <Divider style={{ background: "rgba(244, 244, 245, 1)" }} />
              <div className={classes.sectionContainer}>
                <Grid container>
                  <Grid item sm={6} md={3} xs={4}>
                    <HybridTypography
                      icon={<IdCardIconV2 />}
                      loading={loading}
                      text={data[0]?.loginUserId ? data[0]?.loginUserId : "N/A"}
                      header="User ID"
                    />
                  </Grid>
                  {/* Created on */}
                  <Grid item sm={6} md={3} xs={4}>
                    <HybridTypography
                      icon={<DOBIconV2 />}
                      loading={loading}
                      text={
                        data[0]?.createdAt
                          ? moment(data[0]?.createdAt)?.format("DD/MM/YYYY")
                          : "N/A"
                      }
                      header="CREATED ON"
                    />
                  </Grid>
                  <Grid item sm={6} md={3} xs={4}>
                    <HybridTypography
                      icon={<DOBIconV2 />}
                      loading={loading}
                      // text={effectiveFrom ? moment(effectiveFrom)?.format("DD/MM/YYYY") : "N/A"}
                      text={
                        <DateTimePickersCustom
                          variant={"mobile"}
                          value={this.state.effectiveFrom}
                          handleOnChange={(value) =>
                            this.handleOnChange(value, "effectiveFrom")
                          }
                          isDate
                          withoutLabel
                          hidden
                          dontCloseOnChange
                          handleOnDialogClose={this.handleOnDialogClose}
                          width={"80%"}
                        />
                      }
                      isTextAComponent={true}
                      header="EFFECTIVE DATE"
                    />
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={3}
                    xs={12}

                    // className={classes.sectionContainer}
                  >
                    <HybridTypography
                      icon={<DOBIconV2 />}
                      loading={loading}
                      // text={endsOn ? moment(endsOn)?.format("DD/MM/YYYY") : "N/A"}
                      text={
                        <div
                          style={{ display: "flex", gap: 2, margin: "8px 0px" }}
                        >
                          {/* ExpiryDe */}
                          <DatePicker
                            value={this.state.endsOn}
                            handleOnChange={(value) =>
                              this.handleOnChange(value, "endsOn")
                            }
                            handleOnClose={this.handleOnDialogClose}
                          />
                          {/* <DateTimePickersCustom
                          variant={"mobile"}
                          value={this.state.endsOn}
                          handleOnChange={(value) =>
                            this.handleOnChange(value, "endsOn")
                          }
                          isDate
                          withoutLabel
                          hidden
                          dontCloseOnChange
                          handleOnDialogClose={this.handleOnDialogClose}
                          minDate={this.state.effectiveFrom}
                          width={"50%"}
                          widthBelow400={"70%"}
                        /> */}
                          {this.state.endsOn && (
                            <Button
                              style={{ height: 35, width: 35 }}
                              className={classes.endDateClear}
                              onClick={this.handleEndsOnClear}
                            >
                              Clear
                            </Button>
                          )}
                        </div>
                      }
                      isTextAComponent={true}
                      header="EXPIRY DATE"
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={12} style={{ padding: "0px 25px 25px 25px" }}>
              <Typography
                variant="h6"
                style={{
                  marginBottom: 15,
                }}
              >
                Subscriptions
              </Typography>
              {!loading && data?.[0]?.subscriptions?.length === 0 && (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="center"
                  style={{
                    marginBottom: 15,
                  }}
                >
                  {
                    "Once, a Subscription is added to this client that will display here"
                  }
                </Typography>
              )}
              {loading && <CardSkeleton />}
              {!loading &&
                !error &&
                data?.[0]?.subscriptions?.map((_, key) => {
                  // console.log(_)
                  return (
                    <div style={{ marginTop: 8 }}>
                      <ListCard
                        data={{
                          profile_pic: _.subscriptionProfile,
                          name: _.subscriptionName,
                          isactive: _.active,
                          category: _.category,
                          provider: _.provider?.name,
                          provider_id: _.provider?.value,
                          subscriptionType: _.subscriptionType,
                          isCancelled: _.isCancelled,
                          cancelledOn: _.cancelledOn,
                          subscriptionStatus: _.status,
                          profiles: [
                            {
                              name: _.careCoordiantor?.name,
                              profile_pic: _.careCoordiantor?.profile_pic,
                              role: "Service Coordinator",
                            },
                          ],
                        }}
                        isDisableEditButton={true}
                        assignCoordinatorDrawer={() =>
                          this.assignCoordinatorDrawer(
                            _.provider?.value,
                            _?.subscriptionId
                          )
                        }
                        onEditBtnClicked={() =>
                          this.onEditSubscriptionClicked(_)
                        }
                        handleClick={() => this.handleDeactivateSubscription(_)}
                        disableDeactivateBtn={this.disableDeactivateBtn(
                          "deactivateBtn",
                          _?.provider?.id
                        )}
                      />
                    </div>
                  );
                })}
            </Grid>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  careGiverClientInfo: state.careGiverClient?.careGiverClientInfo,
  upsert_client: state?.persona_client?.upsert_client,
  client_status_update: state.persona_client?.client_status_update,
  categories: state?.masterSet?.categories,
  providerBasedOnCategory: state?.careProvider?.providerBasedOnCategory,
  subscription_mapping: state?.dependency_api?.subscription_mapping,
  addService: state?.serviceState?.addService,
  recommended_caregivers: state?.dependency_api?.recommended_caregivers,
  recommended_care_coord: state?.dependency_api?.recommended_care_coord,
  add_client_subscription_mapping:
    state?.client_subscription_mapping?.add_client_subscription_mapping,
  status_update: state?.client_subscription_mapping?.status_update,
  auth_data: state?.authState?.loginAuth,
  salutation: state?.masterModule?.getsal ?? {
    data: [],
    loading: false,
    error: false,
  },
});

export default connect(
  mapStateToProps,
  actions
)(
  withRouter(
    withAlelrtAndDialog(withStyles(styles, { withTheme: true })(ClientDetails))
  )
);

const datePickerStyles = makeStyles((theme) => ({
  textField: {
    borderRadius: 4,
    "& .MuiOutlinedInput-root": {
      padding: "8px 12x !important",
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`,
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1.8px solid ${theme.palette.ternary.main}`,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.secondary.borderColor}`,
      borderRadius: 8,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 12px",
    },
  },
  mobilePicker: {
    "& .MuiPickersToolbar-dateTitleContainer": {
      justifyContent: "space-between !important",
      "& h4": {
        color: "white",
      },
    },
  },
  svgColor: {
    fill: theme.palette.secondary.main,
    fontSize: 20,
  },
  // label: {
  //   color: theme.palette.text.label,
  //   textTransform: "uppercase",
  // },
  errorBorder: {
    //   "& .MuiOutlinedInput-root:hover": {
    //     "& .MuiOutlinedInput-notchedOutline": {
    //       border: `1.8px solid ${theme.palette.error.light} !important`,
    //     },
    // },
    // "& .Mui-focused": {
    //   "& .MuiOutlinedInput-notchedOutline": {
    //     border: `1.8px solid ${theme.palette.error.light} !important`,
    //   },
    // },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1.8px solid ${theme.palette.error.light} !important`,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
  root2: {
    "& .MuiPickersModalDialog-dialogRoot": {
      "&:focus": {
        outline: "unset !important",
      },
    },
    "& .MuiDialog-scrollPaper": {
      "&:focus": {
        outline: "unset !important",
      },
    },
  },
  timePickerRoot: {
    "& .MuiPickersClock-amButton": {
      display: "none",
    },
    "& .MuiPickersClock-pmButton": {
      display: "none",
    },
  },
}));

function DatePicker({ value, handleOnChange, handleOnClose }) {
  const classes = datePickerStyles();
  return (
    <>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <MobileDatePicker
          openPickerIcon={<CalendarTodayIcon />}
          PopperProps={{ disablePortal: true }}
          value={value}
          onChange={(newDate) => {
            handleOnChange(newDate);
          }}
          onClose={handleOnClose}
          inputFormat={"dd/MM/yyyy"}
          disablePast={true}
          renderInput={(props) => {
            return (
              <TextField
                {...props}
                variant="outlined"
                id="date"
                placeholder=" "
                helperText={null}
                autoComplete="off"
                className={classes.textField}
              />
            );
          }}
          // onOpen={(e) => {
          //   handleClose(e);
          // }}
          // minDate={minDate ? minDate : new Date("1900-01-01")}
          // maxDate={new Date()}
          // maxDate={!hideMaxDateLogic ? new Date() : null}

          // onDismiss={() => {
          //   setOpen(false);
          // }}
          // onClose={(e)=>console.log("ON CLOSE", e)}
          // onAccept={(e)=>{console.log(e,"ON ACCEPTED DATE")}}
          // onError={(e) => {
          //   console.log("error-picker", e);
          // }}
          // disableFuture={isHideFutureDate}
          // disabled={disabled}
          // open={open}
          className={classes.mobilePicker}
        />
        {/* {error && (
        <Typography
          variant="body1"
          component="div"
          className={classes.errorText}
        >
          {errorText}
        </Typography>
      )} */}
      </LocalizationProvider>
    </>
  );
}
