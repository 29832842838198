import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import { routes } from "../../../../router/routes";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ListLoader from "../../../../components/listLoader";
import { InternatlServerError, NotFoundSection } from "../../../../components";
import { withAlertAndDialog } from "../../../../HOCs";

import SubHeader from "../../../../components/subHeader";
import { filterTagType, subHeaderSortOptionWithValue } from "../../../../utils";
import List from "../../../../components/userList/cardList";
import moment from "moment";
import { commonStyle } from '../../style'

const styles = (theme) => ({
  root: {
    padding: 25,
  },
  subHeader: {
    marginTop: 12,
    // marginBottom: 12,
  },
  ...commonStyle
});

const sizes = {
  md: 3,
  sm: 3,
  xs: 3,
};
const initialState = {
  pageNo: 0,
  pageSize: 25,
  careGiverName: "",
  category: [],
  provider: [],
  status: undefined,
  isFilterApplied: false,
  fromTimeStamp: moment().startOf("day").toISOString(),
  toTimeStamp: moment().endOf("day").toISOString(),
  loading: true,
  clientName: "",
}
class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      serviceProfessionId: this.props?.match?.params?.id,
    };
  }

  componentDidMount() {
    this.getClientList();
    window.addEventListener("scroll", this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  }

  scrolling = () => {
    let {
      loading,
      data,
      error,
    } = this.props?.careGiverClient?.allcareGiverClient;

    let listCount = data?.length ?? 0;
    let totalCount = data?.[0]?.total_count ?? 0;

    if (loading || error || totalCount === 0 || listCount === 0) {
      return false;
    }

    if (
      Math.round(window.scrollY + window.innerHeight) >=
      document.getElementsByTagName("body")[0].scrollHeight &&
      listCount !== totalCount
    ) {
      this.setState(
        (state, props) => ({
          pageNo: state.pageNo + 25,
        }),
        () => {
          this.getClientList();
        }
      );
    }
  };

  getClientList = () => {
    const id = this.props?.match?.params?.id;
    this.props.GET_ALL_CARE_GIVER_CLIENT({ searchValue: this.state.clientName, professionalId: id, isprofessional: true , offset:this.state?.pageNo,
      limit:this.state?.pageSize});
  };
 
  storeInputSearchValue = (value) => {
    this.setState({ clientName: value, pageNo: 0, pageSize: 25 }, () => {
      // if (value.length % 3 === 0) {
      this.getClientList();
      // }
    });
  };

  navigateToView = (id) => {
    this.props.history.push(routes.manage_clients + "/view/" + id);
  };
  handleChipDelete = (key, index) => {
    console.log("Key ", key);
    console.log("index ", index);
    if (key === filterTagType.Category || key === filterTagType.Provider) {
      let newState = this.state;

      newState[key].splice(index, 1);

      this.setState(
        {
          ...newState,
          pageNo: 0,
          pageSize: 25,
          isFilterApplied: this.checkIsThereAnyFilter(),
        },
        () => {
          this.getClientList();
        }
      );
    } else if (key === filterTagType.Status) {
      this.setState(
        {
          status: undefined,
          pageNo: 0,
          pageSize: 25,
        },
        () => {
          this.setState({
            isFilterApplied: this.checkIsThereAnyFilter(),
          });
          this.getClientList();
        }
      );
    }
    else if (key === filterTagType.SortDirection) {
      this.setState(
        {
          sortDirec: subHeaderSortOptionWithValue[2],
          pageNo: 0,
          pageSize: 25,
        },
        () => {
          this.setState({
            isFilterApplied: this.checkIsThereAnyFilter(),
          });
          this.getClientList();
        }
      );
    }
  };
  onFilterIconClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      // component: (
      //   <FilterBuilder
      //     components={filterComponent(this.state, this.props)}
      //     applyButtonLabel="Apply Filter"
      //     loading={this.props?.allPersonaResource?.loading}
      //     onChange={this.onFilterChange}
      //     onApplyButtonClicked={this.applyFilter}
      //     resetFilter={this.resetFilter}
      //     onCancelClick={this.closeFilter}
      //   />
      // ),
    });
  };
  onFilterChange = (key, value) => {

    this.setState({
      [key]: value,
    });
  };
  applyFilter = (state) => {

    this.setState(
      {
        ...state, //This is obtained from FilterBuilder Component
        pageNo: 0,
        pageSize: 25,
        isFilterApplied: true,
      },
      () => {
        this.getClientList();
        this.closeFilter();
      }
    );
  };
  resetFilter = () => {
    this.setState(
      {
        ...initialState,
      },
      () => {
        this.getClientList();
        this.closeFilter();
      }
    );
  };
  closeFilter = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };
  checkIsThereAnyFilter = () => {
    const newState = this.state;
    if (
      newState[filterTagType.Category].length === 0 &&
      newState[filterTagType.Provider].length === 0 &&
      newState[filterTagType.Status] === initialState.status
    ) {
      return false;
    } else return true;
  };
  render() {
    const { classes } = this.props;
    let {
      loading,
      data,
      error,
    } = this.props?.careGiverClient?.allcareGiverClient;

    return (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={3}
        >
          {/* Filter Bar */}
          <Grid item xs={12} className={classes.subHeader}>
            <SubHeader
              useNewFilterIcon
              placeSearchIconToLeft
              placeholder={"Search for Clients"}
              entityName={"Client"}
              countText={`Total Clients (${data?.[0]?.total_count ?? 0})`}
              handleSearch={this.getClientList}
              handleChangeInputSearch={this.storeInputSearchValue}
              value={this.state.clientName}
              hideFilter={true}
              id={"ClientsFromMonitoringProfessionalClientsTab"}
            />
          </Grid>

          {/*TOTAL COUNT  */}
          {/* <Grid item xs={12}>
            <Typography variant="h6" style={{ paddingTop: 25 }} className={classes.mediumFont} >Total Clients ({data?.total_count ?? 0})</Typography>
          </Grid> */}

          {/* If API response is loading */}
          {loading && (
            <Grid item xs={12}>
              <ListLoader />
            </Grid>
          )}

          {/* If API response is error */}
          {!loading && error && (
            <Grid item xs={12}>
              <InternatlServerError
                message={"Something Went Wrong!"}
                onRetry={this.getClientList}
                top={"20vh"}
              />
            </Grid>
          )}

          {/* If API response returns not list data */}
          {!loading && !error && !data?.length && (
            <Grid item xs={12}>
              <NotFoundSection message="You don't have any clients" top="25vh" />
            </Grid>
          )}

          {/* If API response return list data */}
          {!loading && !error && data?.length > 0 && (
            <Grid item xs={12} style={{ cursor: "pointer" }}>
              {data?.map((_, i) => {
                return (
                  <div key={i} onClick={() => this.navigateToView(_.id)}>
                    <List
                      list={_}
                      showCareProvider={false}
                      componentOrder={1}
                      isShowGenderAge={true}
                      sizes={sizes}
                      propsList={{
                        isCard: false,
                      }}
                      hideQuickSummaryLabel
                      hideQuickSummary={true}
                      showCareGiver={false}
                    />
                  </div>
                );
              })}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  careGiverClient: state.careGiverClient,
});

export default connect(
  mapStateToProps,
  actions
)(withAlertAndDialog(withRouter(withStyles(styles)(Clients))));
