import React from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || theme.palette.primary.main,
  }),
}))

export const ClientPhoneIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
      style={props.style}
    >
      <path id="Phone" className={classes.cls1} d="M20.023 15.488a15.077 15.077 0 0 1-1.924-.14 14.67 14.67 0 0 1-1.653-.425 1.017 1.017 0 0 0-1.019.253l-2.217 2.2a15.376 15.376 0 0 1-3.8-2.792 15.376 15.376 0 0 1-2.792-3.8l2.2-2.218a1.017 1.017 0 0 0 .253-1.019A14.6 14.6 0 0 1 8.653 5.9a15.067 15.067 0 0 1-.141-1.924A1 1 0 0 0 7.51 3H4a.992.992 0 0 0-1 1 17.179 17.179 0 0 0 4.966 12.034A17.179 17.179 0 0 0 20 21a.992.992 0 0 0 1-1v-3.51a1 1 0 0 0-.977-1.002z" transform="translate(-3 -3)" />
    </SvgIcon>
  )
}

ClientPhoneIcon.propTypes = {
  htmlColor: PropTypes.string,
  style: PropTypes.object,
};
