import * as React from "react"

export function MiniGraphFour(props) {
  return (
    <svg width={78.705} height={62.524} viewBox="0 0 78.705 62.524" {...props}>
      <defs>
        <style>
          {
            ".prefix__c,.prefix__e{fill:none}.prefix__b,.prefix__c{stroke:#f27d35}.prefix__b{fill:#fff}.prefix__c{stroke-width:.5px;opacity:.48}.prefix__d{stroke:none}"
          }
        </style>
      </defs>
      <path
        d="M2.925 46.591l8.651-7.187 4.658 3.677 7.491-10.879 10.6 2.4 11.321 2.117 4.156-8.327s10.031-1.553 10.461-1.694 4.877-10.693 4.877-10.693l6.987-7.354 2.998-5.015"
        strokeLinecap="round"
        strokeWidth={2}
        stroke="#f27d35"
        fill="none"
      />
      <g className="prefix__b" transform="translate(71.276)">
        <circle className="prefix__d" cx={3.714} cy={3.714} r={3.714} />
        <circle className="prefix__e" cx={3.714} cy={3.714} r={3.214} />
      </g>
      <g className="prefix__b" transform="translate(0 42.524)">
        <ellipse className="prefix__d" cx={4} cy={3.5} rx={4} ry={3.5} />
        <ellipse className="prefix__e" cx={4} cy={3.5} rx={3.5} ry={3} />
      </g>
      <path
        className="prefix__c"
        d="M9.118 41.476v21.048M14.071 41.476v21.048M19.023 39v23.524M23.976 32.809v29.714M28.928 32.809v29.714M33.881 35.286v27.238M38.833 35.286v27.238M43.785 35.286v27.238M48.737 30.333v32.19M53.689 27.857v34.667M58.643 27.857v34.667M63.595 17.952v44.571M68.547 13v49.524M73.499 6.809v55.714"
      />
    </svg>
  )
}
