import {
    Grid,
    Typography,
    withStyles,
    CircularProgress
  } from "@material-ui/core";
  import React, { Component } from "react";
  // import { FilterIcon } from '../../assets'
  import PropTypes from "prop-types";
  import { fontStyleClass, commonShadows, genericStyles } from "../../utils";
//   import { Grid, Typography, CircularProgress } from '@material-ui/core';
  
  const style = (theme) => ({
    rowOneIcons: {
      border: "1px solid #E8E8EC",
      margin: "0px 4px",
      padding: 8,
      width: 46,
    },
    customTableStickyHeader: {
      backgroundColor: "white",
    },
    formControl: {
      margin: "3px 8px",
      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.palette.ternary.main} !important`,
          borderWidth: "1.6px !important"
        }
      }
    },
    ...genericStyles.cardWrapperShadows(theme),
    ...commonShadows,
    ...fontStyleClass
  });
  
  class DashboardListviewLoadingSection extends Component {
    constructor(props) {
      super(props);
      this.state = {
        filter: this.props?.filterOptions?.[0].value || "", //Sets the first elements value from array of objects of filterOptions prop
      };
    }
  
    handleChange = (event) => {
      // console.log(event);
      this.setState(
        {
          filter: event.target.value,
        },
        () => {
          // console.log(this.state);
        }
      );
    };
  
    render() {
      const { classes } = this.props;
      // const { filter } = this.state;
      const {
        // listTitle,
        // filterOptions,
        // totalCount,
        // filterDateRange,
        // tableData,
        // hideFilterAndClose,
        // hideTotalCount,
        // top,
        // bottom,
        // left,
        message

      } = this.props;
      // console.log(this.props.tableData)
      return (
        <div style={{ width: "100%"  }} className={`${classes.commonShadows} ${classes.cardWrapperShadows}`} >
            <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    style={{height:"100%",textAlign: "center"}}
  >
    <Grid item>
      <CircularProgress />
    </Grid>
    <Grid item>
      <Typography variant="h6" align="center">
        {message}
      </Typography>
    </Grid>

  </Grid>
        </div>
      );
    }
  }
  
  export default withStyles(style)(DashboardListviewLoadingSection);
  
  DashboardListviewLoadingSection.propTypes = {
    /**Sets the title of the table. Renders <Typography></Typography> component with variant ```h6```. */
    listTitle: PropTypes.string,
    /**Uses the array of value and label to render filter options. */
    filterOptions: PropTypes.arrayOf(Object),
    /**Sets the total count of entity. */
    totalCount: PropTypes.number,
    /**Sets the filter date range. */
    filterDateRange: PropTypes.string,
    /**Renders the table header and table data. */
    tableData: PropTypes.array,
    // Hiding Elements Props
    /**Hides Filter and close card items. */
    hideFilterAndClose: PropTypes.bool,
    /**Hides the Total Count section. */
    hideTotalCount: PropTypes.bool,
  };
