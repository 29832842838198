import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import { routes } from "../../../router/routes";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ListLoader from "../../../components/listLoader";
import { FilterBuilder } from "../../../components";
// import CardList from "../../../components/userList";
import FilterTags from "../../../components/filters/filterTags";
import { InternatlServerError, NotFoundSection } from "../../../components";
import { withAlertAndDialog } from "../../../HOCs";
import ServiceProfessionalListingStats from "./stats";
import SubHeader from "../../../components/subHeader";
import moment from "moment";
import { scrollToTop, filterTagType, subHeaderSortOptionWithValue } from "../../../utils";
import { filterComponent } from "../../resourceManagement/resources/list/filterComponent";
import { getPersonaId, viewScreensBasedOnPersona } from '../../resourceManagement/resources/utils';
import { commonStyle } from '../style'
import { Catalogue } from "../../../components/catalogue/catalogue";

const styles = (theme) => ({
  root: {
    padding: 25,
  },
  subHeader: {
    marginTop: 12,
    // marginBottom: 12,
  },
  ...commonStyle,
});

// const sizes = {
//   md: 3,
//   sm: 3,
//   xs: 3,
// };
const initialState = {
  pageNo: 0,
  pageSize: 25,
  careGiverName: "",
  category: [],
  provider: [],
  status: undefined,
  isFilterApplied: false,
  fromTimeStamp: moment().startOf("day").toISOString(),
  toTimeStamp: moment().endOf("day").toISOString(),
}
class ServiceProfessionalListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      id: "MonitoringServiceProfessional"
    };
  }

  componentDidMount() {
    this.getEssentials();
    scrollToTop()
    window.addEventListener("scroll", this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  }

  scrolling = () => {
    let { data, loading, error } = this.props?.allPersonaResource;

    let listCount = data?.length ?? 0;
    let totalCount = data?.[0]?.total_count ?? 0;

    if (loading || error || totalCount === 0 || listCount === 0) {
      return false;
    }

    if (
      Math.round(window.scrollY + window.innerHeight) >=
      document.getElementsByTagName("body")[0].scrollHeight &&
      listCount !== totalCount
    ) {
      this.setState(
        (state, props) => ({
          pageNo: state.pageNo + 25,
        }),
        () => {
          this.getEssentials();
        }
      );
    }
  };

  getEssentials = () => {
    // this.props.GET_ALL_SERVICE_PROFESSIONAL_MONITOR({ ...this.state });

    let getIdNameType = getPersonaId(this.props?.match?.path)
    let _status = undefined
    if (this.state?.status) {
      if (this.state.status.toLowerCase() === "Active".toLowerCase()) _status = true
      if (this.state.status.toLowerCase() === "InActive".toLowerCase()) _status = false
    }
    this.props.GET_ALL_PERSONA_RESOURCE({
      filter: { category: this.state?.category?.length > 0 ? this.state.category.map((_) => _.value) : [], status: _status, partnerIds: this.state?.provider ? this.state.provider.map((_) => _.value) : [], sort: this.state?.sortDirec?.value },
      searchValue: this.state.careGiverName,
      isProfessional: true,
      resourceTypeId: getIdNameType.id, resourceTypeName: getIdNameType.name,
      offset:this.state?.pageNo,
      limit:this.state?.pageSize,
    });
  };
  onFilterIconClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <FilterBuilder
          components={filterComponent(this.state, this.props)}
          applyButtonLabel="Apply"
          loading={this.props?.allPersonaResource?.loading}
          onChange={this.onFilterChange}
          onApplyButtonClicked={this.applyFilter}
          resetFilter={this.resetFilter}
          onCancelClick={this.closeFilter}
          saveButtonId={`saveFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
          cancelButtonId={`cancelFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
          clearAllButtonId={`clearAllFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
        />
      ),
    });
  };
  onFilterChange = (key, value) => {

    this.setState({
      [key]: value,
    });
  };
  applyFilter = (state) => {

    this.setState(
      {
        ...state, //This is obtained from FilterBuilder Component
        pageNo: 0,
        pageSize: 25,
        isFilterApplied: true,
      },
      () => {
        this.getEssentials();
        this.closeFilter();
      }
    );
  };
  resetFilter = () => {
    this.setState(
      {
        ...initialState,
      },
      () => {
        this.getEssentials();
        this.closeFilter();
      }
    );
  };
  closeFilter = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };
  navigateToView = (id) => {
    this.props.history.push(
      routes.monitor_service_professionals_summary_parent + id
    );
  };

  storeInputSearchValue = (value) => {
    this.setState({ careGiverName: value, pageNo: 0, pageSize: 25 }, () => {
      // if (value.length % 3 === 0) {
      this.getEssentials();
      // }
    });
  };

  handlePopperClose = () => {

    this.setState({ open: false })
  }
  handlePopperOpen = (e, data) => {

    this.setState({ open: e.currentTarget, popperData: data })
  }
  handleChipDelete = (key, index) => {
    console.log("Key ", key);
    console.log("index ", index);
    if (key === filterTagType.Category || key === filterTagType.Provider) {
      let newState = this.state;

      newState[key].splice(index, 1);
      console.log(newState);

      this.setState(
        {
          ...newState,
          pageNo: 0,
          pageSize: 25,
          isFilterApplied: this.checkIsThereAnyFilter(),
        },
        () => {
          this.getEssentials();
        }
      );
    } else if (key === filterTagType.Status) {
      this.setState(
        {
          status: undefined,
          pageNo: 0,
          pageSize: 25,
        },
        () => {
          this.setState({
            isFilterApplied: this.checkIsThereAnyFilter(),
          });
          this.getEssentials();
        }
      );
    }
    else if (key === filterTagType.SortDirection) {
      this.setState(
        {
          sortDirec: subHeaderSortOptionWithValue[2],
          pageNo: 0,
          pageSize: 25,
        },
        () => {
          this.setState({
            isFilterApplied: this.checkIsThereAnyFilter(),
          });
          this.getEssentials();
        }
      );
    }
  };
  checkIsThereAnyFilter = () => {
    const newState = this.state;
    try {
      if (
        (!newState[filterTagType.Category] || newState[filterTagType.Category].length === 0) &&
        (!newState[filterTagType.Provider] || newState[filterTagType.Provider].length === 0) &&
        newState[filterTagType.Status] === initialState.status
      ) {
        return false;
      } else return true;
    } catch (e) {
      return false
    }
  };
  render() {
    const { classes } = this.props;

    let { data, loading, error } = this.props?.allPersonaResource;
    return (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={3}
        >
          {/* Count Cards */}
          <ServiceProfessionalListingStats
            loading={loading}
            error={error}
            data={[{ count: data?.[0]?.total_count ?? 0, label: "Service Professionals" }]}
          />

          {/* Filter Bar */}
          <Grid item xs={12} className={classes.subHeader}>
            <SubHeader
              useNewFilterIcon
              placeSearchIconToLeft
              placeholder={"Search for Service Professionals"}
              entityName={"Service Professional"}
              countText={`Total Service Professionals (${data?.[0]?.total_count ?? 0})`}
              handleSearch={this.getCareGiverList}
              handleChangeInputSearch={this.storeInputSearchValue}
              value={this.state.careGiverName}
              isFilterApplied={this.state.isFilterApplied}
              onFilterIconClicked={this.onFilterIconClicked}
              hideFilter={false}
              id={this.state?.id}
            />
          </Grid>
          {this.state.isFilterApplied && (
            <Grid item xs={12} style={{ marginTop: 25 }}>
              <FilterTags
                filterData={this.state}
                handleDelete={this.handleChipDelete}
              />
            </Grid>
          )}
          {/*TOTAL COUNT  */}
          {/* <Grid item xs={12}>
            <Typography variant="h6" style={{ paddingTop: 25 }} className={classes.mediumFont} >Total Service Professionals ({data?.list?.length ?? 0})</Typography>
          </Grid> */}


          {/* If API response is loading */}
          {loading && (
            <Grid item xs={12}>
              <ListLoader />
            </Grid>
          )}

          {/* If API response is error */}
          {!loading && error && (
            <Grid item xs={12}>
              <InternatlServerError
                message={"Something Went Wrong!"}
                onRetry={this.getEssentials}
                top={"15vh"}
              />
            </Grid>
          )}

          {/* If API response returns not list data */}
          {!loading && !error && !data?.length && (
            <Grid item xs={12}>
              <NotFoundSection message="You don't have any service professionals" top="15vh" />
            </Grid>
          )}
          <Grid item xs={12} style={{ paddingTop: "25px" }}>
          <Catalogue
                id={"MonitoringProfessionals"}
                catalogues={Array.isArray(data) ? data : []}
                redirectUrl={viewScreensBasedOnPersona(this.props.match.path)}
                loading={loading}
              />
            {/* <CardList
              isShowGender={data}
              isShowAge={data}
              isShowRole={data}
              list={data?.constructor === Array ? data : []}
              loading={loading}
              componentOrder={1}
              sizes={sizes}
              Url={viewScreensBasedOnPersona(this.props.match.path)}
              countName="Clients"
              isCard={true}
              hideQuickSummaryLabel={true}
              hideQuickSummary={true}
              displayProvider={true}
            /> */}
          </Grid>
          {/* If API response return list data */}
          {/* {!loading && !error && data?.list?.length > 0 &&
            <Grid item xs={12} style={{ cursor: "pointer" }}>
              {data?.list?.map((_, i) => {
                return (
                  <div key={i} onClick={() => this.navigateToView(_.id)}
                  onMouseLeave={(e)=> {
                    if(e?.relatedTarget?.id === "popper"){
                      return false
                    } else {
                      this.handlePopperClose()
                    }
                  }}
                  >
                    <List
                      list={_}
                      showCareProvider={false}
                      componentOrder={1}
                      sizes={sizes}
                      propsList={{
                        isCard: true,
                      }}
                      hideQuickSummaryLabel
                      hideQuickSummary
                      showCareGiver={true}
                      open={this.state.open}
                      handleCategoryPopoverClose={this.handlePopperClose}
                      handleCategoryPopoverOpen={this.handlePopperOpen}
                        popperData={this.state.popperData}
                    />
                  </div>
                );
              })}
              {loading && (
                <Grid item xs={12}>
                  <ListLoader />
                </Grid>
              )}
            </Grid>
          } */}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getAllServiceProfessionals:
    state?.service_professional_monitor?.getAllServiceProfessionals,
  allPersonaResource: state?.resourceReducer?.allPersonaResource,
  auth_data: state?.authState?.loginAuth,
});

export default connect(
  mapStateToProps,
  actions
)(
  withAlertAndDialog(withRouter(withStyles(styles)(ServiceProfessionalListing)))
);
