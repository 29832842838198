import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./style";

export const SimpleList = (props) => {
  const classes = useStyles();

  return (
    <Grid container item xs="12" className={classes.mainText}>
      <Grid item xs={10}>
        <Typography variant="body1" component="div" className={classes.text}>
          <span style={{ paddingRight: "4px" }}>&#8226;</span>
          {props.text}
        </Typography>
      </Grid>
      <Grid container item xs={2} justify="flex-end">
        <Typography
          variant="body1"
          component="div"
          shrink
          className={classes.removetext}
          onClick={props.handleRemove}
        >
          {"Remove".toUpperCase()}
        </Typography>
      </Grid>
    </Grid>
  );
};
