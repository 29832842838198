import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

const styles = theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        // padding: 50
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        color: "black",
        opacity: 1,
        borderBottom: 0,
        "&:before": {
            borderBottom: 0
        }
    },
    disabled: {
        color: "black",
        borderBottom: 0,
        "&:before": {
            borderBottom: 0
        }
    },
    btnIcons: {
        marginLeft: 10
    },
    inputStyle: {
        fontSize: "1.25rem",
    }
});

class EditableTextField extends React.Component {
    state = {
        editMode: false,
        mouseOver: false
    };

    handleMouseOver = event => {
        if (!this.state.mouseOver) {
            this.setState({ mouseOver: true });
        }
    };

    handleMouseOut = event => {
        // The problem is here!!!
        if (this.state.mouseOver) {
            this.setState({ mouseOver: false });
        }
    };

    handleClick = () => {
        this.setState((prevState) => ({
            editMode: !prevState.editMode,
            mouseOver: false
        }));
    };

    render() {
        const { classes, value, startAdornment = "", type = "text" } = this.props;

        return (
            <div className={classes.container}>
                <TextField
                    name="editableField"
                    value={value}
                    defaultValue={value}
                    type={type}
                    margin="normal"
                    onChange={this.props.handleChange}
                    disabled={!this.state.editMode}
                    className={classes.textField}
                    onMouseEnter={this.handleMouseOver}
                    onMouseLeave={this.handleMouseOut}
                    InputProps={{
                        classes: {
                            disabled: classes.disabled,
                            input: classes.inputStyle
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={this.handleClick} style={{ padding: 6 }}>
                                    <Edit fontSize="small" />
                                </IconButton>
                            </InputAdornment>),
                        startAdornment: startAdornment
                    }}
                    inputProps={{
                        step: "any"
                    }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(EditableTextField);
