import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { InputLabel, makeStyles, Typography } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  container: {
    margin: "8px 0px",
  },
  root: {
    marginTop: 0,
    "& .css-yk16xz-control": {
      borderWidth: `1.6px !important`,
      // borderColor: `${theme.palette.secondary.light} !important`,
      borderColor: `${theme.palette.secondary.borderColor} !important`,
      "&:hover": {
        borderColor: `${theme.palette.ternary.main} !important`,
      },
      borderRadius: "8px !important",
      // minHeight: '52px !important',
      // maxHeight:"62.4px !important",
      height: "43.6px !important",
      maxHeight: "43.6px !important",
      "& .css-g1d714-ValueContainer": {
        maxHeight: "32px !important",
        overflow: "auto",
      },
    },
    "& .css-1laao21-a11yText": {},
    "& .css-1okebmr-indicatorSeparator": {
      display: "none",
    },
    "& .css-yfb6aj-control":{
overflow:"scroll"
    },
    "& .css-1pahdxg-control": {
      borderWidth: `1.6px !important`,
      boxShadow: "none !important",
      borderColor: `${theme.palette.ternary.main} !important`,
      borderRadius: "8px !important",
      // minHeight: '52px !important',
      // maxHeight:"62.4px !important",
      height: "43.6px !important",
      maxHeight: "43.6px !important",
      "& .css-g1d714-ValueContainer": {
        maxHeight: "32px !important",
        overflow: "auto",
      },
    },
  },
  label: {
    color: theme.palette.text.label,
    textTransform: "uppercase",
  },
  errorBorder: {
    "& .css-yk16xz-control": {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
}));

export const AsyncPaginateSelect = ({required, ...props}) => {
  const {
    loadOptions,
    value,
    handleOnChange,
    label,
    error,
    errorText,
    isDisabled,
    isMulti,
    cacheUniqs,
    id,
  } = props;
  const classes = styles();
  return (
    <div className={classes.container}>
      <InputLabel shrink htmlFor={label} className={classes.label} required={required}>
        {label}
      </InputLabel>
      <AsyncPaginate
        // id={label}
        id={id}
        className={`${classes.root} ${error ? classes.errorBorder : ""}`}
        value={value}
        isMulti={isMulti}
        loadOptions={loadOptions}
        isOptionDisabled={(option) => option.isDisabled}
        placeholder=""
        onChange={handleOnChange}
        isDisabled={isDisabled}
        cacheUniqs={cacheUniqs}
        tabSelectsValue={false} // Pressing Tab moves to next field, previously selects value.
      />
      {error && (
        <Typography
          variant="body1"
          component="div"
          className={classes.errorText}
        >
          {errorText}
        </Typography>
      )}
    </div>
  );
};
