import { Chip, useTheme, withStyles } from "@material-ui/core";
import React from "react";

export const StyledChip = withStyles((props) => {
  const theme = useTheme();

  return {
    root: {
      height: 24,
      backgroundColor: (props) =>
        props.transparent ? "unset" : theme.palette.chip.main,
      // backgroundColor: "#D7E2E9",
      color: "unset",
      borderRadius: 8,
      // alignItems: (props) => props.transparent ? "flex-start" : "center",
      maxWidth: (props) => (props.maxWidth ? props.maxWidth : "unset"),
    },
    label: {
      paddingLeft: (props) => (props.transparent ? 0 : 12),
      paddingRight: (props) => (props.transparent ? 0 : 12),
    },
  };
})((props) => <Chip title={props?.label ? props?.label : "N/A"} {...props} />);
