import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ActivityAndMembersCard from '../availabilityActivityComponents/ActivityAndMembersCard'
import { withAlertAndDialog } from '../../HOCs';
// import ServiceProfessionalActivity from './serviceProfessionalActivity'
import ActivitySummary from './ActivitySummary';
import moment from 'moment';

export const colorCode = {
    "PENDING":"#bc2d4f",
    "COMPLETED": "#31b198"
}
class ActivityList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    onEndIconClickHandler = (data) => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: true,
            component: <ActivitySummary
                onBackBtnClicked={this.onBackBtnClicked}
                onEditBtnClicked={this.onEditBtnClicked}
                drawer={this.props.drawer}
                data={data} 
                reload={this.props.reload}
                />
        })
    }

    onBackBtnClicked = () => {
        this.props.onActivityClicked(this.props.data)
    }

    onEditBtnClicked = (fabId, data) => {
        this.props.onEditBtnClicked(fabId, data)
    }


    render() {

        const {
            data
        } = this.props;

        return <>
            <Grid container>
                <Grid item style={{ padding: 12 }}>
                    <Typography variant="h6">{`${data?.length ?? "0"} In Activity`}</Typography>
                </Grid>
                {
                    data?.length > 0 && data?.map((_, index) => {
                        return <ActivityAndMembersCard
                            {..._}
                            key={index}
                            profilePicture={_?.service_professional_info[0]?.profile_pic?.value}
                            title={_?.service_professional_info[0]?.name}
                            timing={`${moment(_?.fromTime).format('Do MMM hh:mm A')} - ${moment(_?.toTime).format('Do MMM hh:mm A')}`}
                            name={_?.client_info?.name}
                            activityStatus={_?.activity_type?.label}
                            activityStatusColor="#28a9c1"
                            currentStatus={_?.activity_status}
                            currentStatusColor={colorCode[_?.activity_status]}
                            onEndIconClickHandler={this.onEndIconClickHandler}
                            hideIsActive={true}
                        />
                    })
                }
            </Grid>
        </>
    }
}

export default withAlertAndDialog(ActivityList)