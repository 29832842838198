import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || "#28a9c1"
  })
}))

export const IntelligenceIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon style={props.style}>
      <path className={classes.cls1} d="M11 2a8 8 0 0 0-8 8c0 5 3 4.429 3 9.24V22h9v-3h2a2 2 0 0 0 2-2v-2.979l1.426-.6a.912.912 0 0 0 .416-1.332l-1.895-2.972A8 8 0 0 0 11 2zm0 2a5.987 5.987 0 0 1 5.961 5.338l.051.463.252.393 1.2 1.883-.242.1-1.222.516V17h-4v3H8v-.76a8.743 8.743 0 0 0-1.834-5.654A5.189 5.189 0 0 1 5 10a6.007 6.007 0 0 1 6-6zm-.6 2a.251.251 0 0 0-.25.229l-.076.934a2.981 2.981 0 0 0-1.064.611l-.846-.4a.245.245 0 0 0-.322.1l-.6 1.047a.249.249 0 0 0 .074.33l.758.525a2.938 2.938 0 0 0 0 1.242l-.758.525a.25.25 0 0 0-.074.33l.6 1.045a.249.249 0 0 0 .217.125.245.245 0 0 0 .105-.023l.844-.4a2.977 2.977 0 0 0 1.064.613l.078.932a.252.252 0 0 0 .25.235h1.2a.251.251 0 0 0 .25-.229l.078-.934a2.981 2.981 0 0 0 1.072-.61l.844.4a.245.245 0 0 0 .322-.1l.6-1.045a.249.249 0 0 0-.074-.33l-.758-.527a2.938 2.938 0 0 0 0-1.242l.758-.525a.25.25 0 0 0 .074-.33l-.6-1.045a.249.249 0 0 0-.217-.125.246.246 0 0 0-.106.023l-.844.4a2.987 2.987 0 0 0-1.066-.613l-.078-.934A.248.248 0 0 0 11.6 6zm.6 2.5A1.5 1.5 0 1 1 9.5 10 1.5 1.5 0 0 1 11 8.5z" transform="translate(-3 -2)" />
    </SvgIcon>
  )
}

IntelligenceIcon.propTypes = {
  /**Sets the color of the icon. */
  color: PropTypes.string,
  /**Adds additional icon styles. */
  style: PropTypes.object,
}
