/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-02-10
 * @modify date 2021-02-22
 * @desc Render the Sub Nav Bar
 */

 import React from 'react'
 import { makeStyles } from '@material-ui/core'
 import { TextRouter } from '../screens/formConfigurator/components'
 import { giveMeSubNavBars } from '../screens/formConfigurator/utils';
 import { useSelector } from 'react-redux';
 
 const useStyles = makeStyles(theme => ({
   root: {
     flexGrow: 1,
   },
   content: {
     [theme.breakpoints.down('xs')]: {
       padding: 10
     }
   },
   textRouter: {
 
   }
 }))
 
 const withSubNavBar = Component => props => {
 
   const classes = useStyles({ props });
 
   const { data } = useSelector(
     state => state?.authState?.loginAuth
   )
 
   const navBars = giveMeSubNavBars(data);
 
   return (
     <div className={classes.root}>
       {/* Your sub nav bars here */}
       <div className={classes.textRouter}>
         <TextRouter data={navBars} search />
       </div>
 
       {/* Content */}
       <div className={classes.content}>
         <Component {...props}>{props.children} </Component>
       </div>
     </div>
   )
 }
 
 export default withSubNavBar
 