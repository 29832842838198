import { Checkbox, FormControlLabel, Grid, Typography, withStyles, FormHelperText } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React from 'react'
import { options } from '../../utils'

// ToggleButtonGroup
const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  grouped: {
    border: 'none',
    height: 50,
    width: 50,
    '&:not(:first-child)': {
      margin: theme.spacing(1),
      borderRadius: "50%",
      background: "#fafafa",
    },
    '&:first-child': {
      // margin: theme.spacing(1, 1, 1, 0),
      margin: theme.spacing(1),
      borderRadius: "50%",
      background: "#fafafa"
    },
  },
}))(ToggleButtonGroup);


// Checkbox
const CustomCheckbox = withStyles((theme) => ({
  root: {
    '&.Mui-checked': {
      color: theme.palette.ternary.main,
    },
    '&.Mui-disabled': {
      color: "rgba(0, 0, 0, 0.26) !important"
    }
  },
}))((props) => <Checkbox color="default" {...props} />);


const style = theme => ({
  toggleButtonRoot: {
    // color: "rgba(0, 0, 0, 0.38) !important",
    textTransform: "none",
    "&.Mui-selected": {
      '&:not(:first-child)': {
        marginLeft: "8px !important",
      },
      border: `1px solid ${theme.palette.ternary.main} !important`,
      background: "white",
      "&:hover": {
        backgroundColor: "rgba(242, 125, 53, 0.15)"
      },
      // label
      '&>.MuiToggleButton-label': {
        '&>.MuiTypography-body1': {
          color: theme.palette.ternary.main
        }
      }
    },
    "&:hover": {
      backgroundColor: "rgba(242, 125, 53, 0.15)"
    },

    // Label color
    '&>.MuiToggleButton-label': {
      '&>.MuiTypography-body1': {
        color: "rgba(0, 0, 0, 0.38)"
      }
    }
  },
  toggleButtonLabel: {
    fontSize: "0.9rem",
  },
  disabled: {
    backgroundColor: "#f2f2f2 !important",
    '&>.MuiToggleButton-label': {
      '&>.MuiTypography-body1': {
        color: "#cccccc !important",
      }
    },
    "&.Mui-selected": {
      border: `none !important`,
    },
  },
  customFormControlLabel: {
    color: "#9297a5",
    fontSize: "0.860rem"
  },
})


class DaysToggleGroup extends React.Component {
  render() {
    const { classes, errorObject } = this.props
    return (
      <>
        <Grid item xs={12} alignItems="center">
          <Typography style={{ fontSize: "0.775rem" }} color="textSecondary" >SELECT OPERATING DAYS</Typography>
        </Grid>
        <Grid item xs={12} style={{ margin: "10px 0px" }}>
          <StyledToggleButtonGroup
            value={this.props.value}
            onChange={this.props.onChangeHandler}
          >
            {options.daysOfWeek.map((day, index) =>
              <ToggleButton value={day.value} classes={{ root: classes.toggleButtonRoot, label: classes.toggleButtonLabel, disabled: classes.disabled }} 
              disabled={this.props.isDisabled 
                //|| this.props.availableAllDay ? true : (!availableWeekDays?.[day.value] && !value.includes(day.value) ? true : false)
              }>
                {/* isDisabled will be true when available247 is checked */}
                {/* availableAllDay will be true when currentSlot availableAllDay is checked */}
                {/* If both of above these are not checked then proceed with my availableWeekDays logic */}
                <Typography>{day.label}</Typography>
              </ToggleButton>
            )}
          </StyledToggleButtonGroup>
          {errorObject?.error && <FormHelperText id="week-days" error={errorObject?.error}>{errorObject?.message ? errorObject?.message : ""}</FormHelperText>}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={this.props.isDisabled}
            control={<CustomCheckbox checked={this.props.availableAllDay} onChange={this.props.handleAvailableAllDayChange} />}
            label="Available All Day"
            style={{ color: "#9297a5 !important" }}
            classes={{ label: classes.customFormControlLabel }}
          />
        </Grid>
      </>

    )
  }
}

export default withStyles(style)(DaysToggleGroup)