import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { routes } from "../../router/routes";

import { withNavBars } from "./../../HOCs";
import { ClientListing } from "./clientListing";
import { ServiceProfessionalListing } from "./serviceProfessionalListing";
import SubscriptionMonitoring from "./subscriptions";
import SubscriptionDetail from "./subscriptions/detail";

class Monitoring extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateNavBarProps: true,
    };
  }

  render() {
    const { match } = this.props;
    // console.log(this.props.user_role)

    return (
      <>
        {/* Service Professional */}
        {match.path === routes.monitor_service_professionals && <ServiceProfessionalListing />}

        {/* Clients */}
        {match.path === routes.monitor_clients && <ClientListing user_role={this.props.user_role}/>}

        {/* Subscription */}
        {match.path === routes.monitor_subscription && <SubscriptionMonitoring />}

        {/* Subscription Detail */}
        {match.path === routes.monitor_subscription_detail && <SubscriptionDetail />}

      </>
    );
  }
}

Monitoring.propTypes = {
  title: PropTypes.string,
  withBackButton: PropTypes.bool,
  navBarArr: PropTypes.array,
};

Monitoring.defaultProps = {
  title: "Monitoring",
};

export default withRouter(withNavBars(Monitoring));

