import React from "react";
import PropTypes from "prop-types";

import { SvgIcon } from "@material-ui/core";

function MultiplyIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
        fill={props.color}
      />
      <path
        d="m7.868 6.94-.928.928L11.07 12l-4.13 4.13.928.928L12 12.928l4.13 4.13.927-.928-4.13-4.13 4.13-4.132-.927-.928L12 11.07 7.868 6.94Z"
        fill={props.secondaryColor}
      />
    </SvgIcon>
  );
}

MultiplyIcon.propTypes = {
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
};

MultiplyIcon.defaultProps = {
  color: "#FF4D4A",
  secondaryColor: "#fff",
};

export default MultiplyIcon;
