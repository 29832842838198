import React from "react";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { alertProps, formComponentTypes } from "../../utils";
import AddEditProductManagemet from "../../components/addEditForm";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
import { InternatlServerError, LoadingSection } from "../../components";
// import moment from 'moment'
// import moment from "moment";

class AddTodo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newValue: new Date(),
    };
  }

  componentDidMount() {
    if (this.props.isEdit) {
      this.getTodoDetail();
    }
  }

  getTodoDetail = () => {
    this.props.GET_TODO_INFO({
      todoId: this.props?.data?.id
    });
  };

  cancelForm = () => {
    if (this.props.cancelForm) {
      this.props.cancelForm();
    } else {
      if (this.props.open) {
        this.props.toggleDrawer(false, {});
      } else {
        this.props.history.goBack();
      }
    }
  };

  onSaveButtonClicked = async (isError, data, url) => {
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning
      });
    } else {
      let scheDt = this.state.newValue === "" ? "" : data?.select_date;
      console.log("NEW TIME \n")
      console.log(scheDt)
      const payload = {
        title: data?.title,
        // description: data?.add_description,
        created_by: this.props?.auth_data?.data?.user_id,
        // occurrence: data?.occurrence,
        // scheduled_time: scheDt ? moment(scheDt).utc() : null,
        scheduled_time : scheDt ? new Date(scheDt).toUTCString() : ""
        // select_date: scheDt ? new Date(scheDt).toUTCString() : null
      };
      if (this.props.isEdit) {
        payload.id = this.props?.data?.id;
        this.props.UPDATE_TODO({ ...payload });
      } else {
        this.props.ADD_TODO({ ...payload });
      }
    }
  };

  onDeleteRemainder = async () => {
    
    this.setState({newValue : ""})
    const payload = {
      scheduled_time : ""
    }
    payload.id = this.props?.data?.id;

    // this.props.UPDATE_TODO({ ...payload})
  }

  onCancelButtonClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    });
  };
  shouldComponentUpdate(nextProps, nextState) {
    //Add Todo
    const { addTodo } = nextProps;
    const oldAddTodo = this.props.addTodo;

    //Checking for Add Todo messages
    if (oldAddTodo?.loading !== addTodo?.loading) {
      if (!addTodo.loading && !addTodo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Todo Added successfully!",
          severity: alertProps.severity.success
        });
        this.props.reload();
        this.onCancelButtonClicked();
      } else if (addTodo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Add Todo.",
          severity: alertProps.severity.error
        });
      }
    }

    //Update Package
    const { updateTodo } = nextProps;
    const oldUpdateTodo = this.props.updateTodo;

    //Checking for Add Package messages
    if (oldUpdateTodo?.loading !== updateTodo?.loading) {
      if (!updateTodo.loading && !updateTodo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Todo Updated successfully!",
          severity: alertProps.severity.success
        });
        this.props.reload();
        this.onCancelButtonClicked();
      } else if (updateTodo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Update Todo.",
          severity: alertProps.severity.error
        });
      }
    }

    return true;
  }

  render() {
    const { loading: addLoading } = this.props?.addTodo ?? this.props;
    const { loading: editLoading } = this.props?.updateTodo ?? this.props;
    // const { loading, error, data: editData } = this.props?.todoInfo ?? this.props;
    const { data: editData } = this.props;
    let loading = false;
    let error = false;
    let isEdit = this.props.isEdit;
    // console.log(editData)
    // Defining Components
    const components = {
      section_1: {
        label: !isEdit ? "Add To-Do" : "Edit To-Do",
        fields: {
          title: {
            key: "title",
            id:"addTitleTodo",
            type: formComponentTypes.INPUT_BOX,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12
            },
            label: "Title",
            value: !isEdit ? "" : editData?.title,
            componentProps: {
              type: "text",
              multiline: false,
              placeholder: ""
            },
            isMandatory: true
          },
          // recurrence: {
          //   key: "occurrence",
          //   type: formComponentTypes.CUSTOMIZED_TOGGLE_GROUP,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 12,
          //     md: 12,
          //     lg: 12,
          //     xl: 12
          //   },
          //   label: "RECURRENCE",
          //   value: !isEdit ? "" : editData.occurrence,
          //   options: options.recurrence,
          //   componentProps: {
          //     type: "text"
          //   },
          //   isMandatory: true
          // },
          // select_date: {
          //   key: "select_date",
          //   type: formComponentTypes.DATE_TIME_PICKER,
          //   componentProps: {
          //     isHidePastDate: true
          //   },
          //   // If no inputFormat is passed, then the default will be MM/dd/yyyy.
          //   inputFormat: "dd/MM/yyyy",
          //   isDate: true,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 12,
          //     md: 12,
          //     lg: 12,
          //     xl: 12
          //   },
          //   label: "SELECT DATE & TIME",
          //   value: !isEdit ? null : editData.scheduled_date,
          //   // onChangeCallBack: setDateState,
          //   isMandatory: true
          // },
          select_date: {
            key: "select_date",
            id:"addRemainderTodo",
            type: formComponentTypes.DATE_TIME_PICKER,
            isDateTime: true,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            onDeleteRemainder: this.onDeleteRemainder,
            onChangeCallBack: this.handleCalBackDate,
            label: "REMINDER",
            value: !isEdit ? null : (editData?.scheduled_time ? editData?.scheduled_time : null),
            componentProps: {
              isHidePastDate: true
            },
            isMandatory: false,
            // cleanUpKeys: ["to_timestamp"],
          },
          // select_time: {
          //   key: "select_time",
          //   type: formComponentTypes.DATE_TIME_PICKER, 
          //   breakPoints: {
          //     xs: 12,
          //     sm: 12,
          //     md: 12,
          //     lg: 12,
          //     xl: 12
          //   },
          //   isDateTime: true,
          //   label: "SELECT TIME",
          //   // isDateTime: true, //don't give any to get time picker
          //   value: !isEdit
          //     ? null
          //     : new Date(moment(editData.scheduled_time).toString()),
          //   // onChangeCallBack: setDateState,
          //   isMandatory: true
          //   // isMulti: true,
          // },


          // ! TEMPORARILY DISABLED DESCRIPTION
          // add_description: {
          //   key: "add_description",
          //   type: formComponentTypes.INPUT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 12,
          //     md: 12,
          //     lg: 12,
          //     xl: 12
          //   },
          //   label: "ADD DESCRIPTION",
          //   value: !isEdit ? "" : editData?.description,
          //   componentProps: {
          //     type: "text",
          //     multiline: true,
          //     rowsMax: 5,
          //     placeholder: "Type here..."
          //   },
          //   isMandatory: true
          // }
        }
      }
    };

    return (
      <div>
        {loading && <LoadingSection message="Getting Todo Detail..." />}
        {error && (
          <InternatlServerError
            message="Something Went Wrong, Try Again!"
            onRetry={this.getTodoDetail}
          />
        )}
        {!error && !loading && this.props.isEdit && (
          <AddEditProductManagemet
            components={components}
            onSaveButtonClicked={this.onSaveButtonClicked}
            cancelForm={this.onCancelButtonClicked}
            loading={editLoading}
            saveButtonLabel={editLoading ? "Updating..." : "Update"}
            saveButtonId={"SaveTodo"}
            cancelButtonId={"CancelTodo"}
            withoutBoxShadow
            spacing={1}
            sectionGutterBottom={16}
          />
        )}
        {!this.props.isEdit && (
          <AddEditProductManagemet
            components={components}
            onSaveButtonClicked={this.onSaveButtonClicked}
            cancelForm={this.onCancelButtonClicked}
            loading={addLoading}
            saveButtonLabel={addLoading ? "Adding..." : "Add"}
            saveButtonId={"SaveTodo"}
            cancelButtonId={"CancelTodo"}
            withoutBoxShadow
            spacing={1}
            sectionGutterBottom={16}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  addTodo: state?.todo?.add_todo,
  updateTodo: state?.todo?.updateTodo,
  todoInfo: state?.todo?.todo_info,
  auth_data: state?.authState?.loginAuth
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(AddTodo)));
