import * as React from "react"
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: "none",
    stroke: props.color || theme.palette.primary.main,
    strokeMiterlimit: 10,
    strokeWidth: 2
  }),
  cls2: (props) => ({
    fill: props.color || theme.palette.primary.main,
  })
}))

export function ReceivedAndAssignedCall(props) {
  const classes = useStyles(props)
  return (
    <SvgIcon>
      <g data-name="Received and assigned call">
        <path
          d="M14.997 11.368v3.642a1 1 0 01-1 1h-3v3h-7v-1.76a7.785 7.785 0 00-1.656-5.085 5.979 5.979 0 01-1.309-3.3 7.108 7.108 0 015.842-7.768 6.992 6.992 0 018.077 6.14l.025.232.125.2 1.777 2.788a.093.093 0 01-.043.139zm-7-10.358v7"
          className={classes.cls1}
          data-name="Path 702"
        />
        <path
          className={classes.cls2}
          d="M7.997 7.01a2 2 0 102 2 2 2 0 00-2-2zm3.5 4a1.5 1.5 0 101.5 1.5 1.5 1.5 0 00-1.5-1.5z"
          data-name="Path 703"
        />
        <path
          d="M7.997 9.01l3.393 3.393"
          className={classes.cls1}
          data-name="Path 704"
        />
      </g>
    </SvgIcon>
  )
}

ReceivedAndAssignedCall.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object
};
