import React from "react";
import { FormControlLabel, makeStyles, Switch } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  switchRoot: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.ternary.main
    },
    "& .MuiSwitch-track": {
      background: theme.palette.secondary.main
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      // "& .MuiSwitch-track":{
      background: "#80f10e"
      // }
    },
    "& .MuiSwitch-switchBase": {
      padding: 13
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "white"
    },
    "&:not($checked) .MuiIconButton-label:after": {
      padding: 14
    }
  },
  switch: {
    "& .MuiSwitch-track": {
      borderRadius: 13,
    },
    "& .MuiSwitch-thumb": {
      width: 12,
      height: 12
    },
    // marginTop:20,
    marginLeft:0,
    display: "flex",

    "& .MuiSwitch-root": { padding: 10 }
  },
  formControlLabel: {
    fontSize: "0.86rem",
    color: "#40444f"
  }
}));

function DenseSwitch({ label, value, handleOnChange, ...props }) {
  const classes = useStyles(props)
  return (
    <FormControlLabel
      className={`${classes.switchRoot} ${classes.switch}`}
      classes={{ label: classes.formControlLabel }}
      control={
        <Switch
          id={props?.id}
          disableRipple
          checked={value}
          onChange={handleOnChange}
          inputProps={{
            "aria-label": `${label} checkbox`,
          }}
        />
      }
      label={label ?? ""}
      labelPlacement={"start"}
    />
  );
}

export default DenseSwitch