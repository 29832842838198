import React from 'react'
import { withStyles, Grid, Typography, IconButton } from '@material-ui/core'
import { VideoCallIcon, ChatIconIssueDetail, DirectionsIcon } from '../../assets'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
    padding: 25,
  },
  cardTop: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  },
  cardBottom: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  },
  iconsHolder: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      marginTop: 10
    }
  },
  individualIcons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: '50%',
    margin: '0px 4px',
  }
})

class IssueTopBar extends React.Component {
  render() {
    const { classes } = this.props
    // Props to add css styles
    const { isCardTop, isCardBottom } = this.props

    // Integration Props - Data layer
    const {  issue_tag } = this.props?.data
    // const { issue_title } = this.props?.data

    return (
      <Grid container className={`${classes.root} ${isCardTop ? classes.cardTop : isCardBottom ? classes.cardBottom : ''}`}>
        <Grid item xs>
          {/* <Typography variant="body1">{issue_title ? issue_title : 'N/A'}</Typography> */}
          <Typography variant="subtitle2" color={"textSecondary"} style={{ marginTop: 8 }}>{issue_tag ? issue_tag : 'N/A'}</Typography>
        </Grid>
        <Grid item xs={12} sm>
          <div className={classes.iconsHolder}>
            <IconButton className={classes.individualIcons}>
              <VideoCallIcon height={18} width={18} />
            </IconButton>
            <IconButton className={classes.individualIcons}>
              <ChatIconIssueDetail height={18} width={18} />
            </IconButton>
            <IconButton className={classes.individualIcons}>
              <DirectionsIcon height={18} width={18} />
            </IconButton>
          </div>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(IssueTopBar)

IssueTopBar.propTypes = {
  /**
   * To check if the card is placed at top to add respective css to Top
   */
  isCardTop: PropTypes.bool,
  /**
   * To check if the card is placed at Bottom to add respective css to Bottom
   */
  isCardBottom: PropTypes.bool
}