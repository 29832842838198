import React from "react";
// import { EffectiveDateExpiryDate } from "../../components";
import { formComponentTypes } from "../../utils";
import AddEditFormBuilder from "../../components/addEditForm";

const fullWidth = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
}
class TestingIndividualComponents extends React.Component {
  render() {

    const components = {
      section_1: {
        label: "Effective Expiry",
        fields: {
          effective_expiry: {
            key: {
              effective: "effective",
              noExpiry: "noExpiry",
              expiry: "expiry"
            },
            type: formComponentTypes.EFFECTIVE_EXPIRY,
            value: {
              effective: null,
              noExpiry: true,
              expiry: null
            },
            breakPoints: fullWidth,
            isMandatory: true,
          },
        },
      },
    };


    return (
      <>
        <AddEditFormBuilder
          components={components}
          onSaveButtonClicked={(isError, state) => { 
            
           }}
          cancelForm={() => { }}
          saveButtonLabel={"Update"}
        // saveButtonId= {`addSaveTaxButtonFrom${id ? id : ''}`}
        // cancelButtonId= {`addCancelTaxButtonFrom${id ? id : ''}`}
        />
      </>
    );
  }
}
export default (TestingIndividualComponents);
