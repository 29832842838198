import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  bottomContainer: {
    display: "flex",
    padding: '12px 16px'
  },
  status: (props) => ({
    backgroundColor: props.status_color || `${theme.palette.secondary.main}`,
    height: 8,
    width: 8,
    borderRadius: "50%",
    alignSelf: "center",
    marginRight: 8
  })
}))



export const FooterDetails = (props) => {
  const classes = useStyles(props)
  const date = props.current_status_date
  return (

    < Grid item xs={12} className={classes.bottomContainer} >
      <div className={classes.status}></div>
      <Typography variant="subtitle2" color={"textSecondary"}>
        {`${props.current_status} on ${date ? moment(date, "DD-MM-YYYY kk:mm:ss").format('MMM DD, YYYY').toString() : 'N/A'}`}
      </Typography>
    </Grid >
  )
}

