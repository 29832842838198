/* eslint-disable eqeqeq */
import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import SubHeader from "../../../../components/subHeader";
import { routes } from "../../../../router/routes";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ListLoader from "../../../../components/listLoader";
import { FilterBuilder, InternatlServerError, NotFoundSection, 
  // ListCard
} from "../../../../components";
import { withAlertAndDialog } from "../../../../HOCs";
import { alertProps, filterTagType, fontStyleClass, productSortDirectionOption, productSortFieldOption, scrollToTop, subHeaderSortOptionWithValue, user_roles } from "../../../../utils";
import { filterComponent } from "./filterComponent";
import FilterTags from "../../../../components/filters/filterTags";
import { ProductCatalogue } from "../../../../components/productCatalogue/productCatalogue";

const styles = (theme) => ({
  root: {
    padding: 25,
  },
  ...fontStyleClass,
});

const initialState = {
  pageNo: 0,
  pageSize: 25,
  anchorEl: null,
  searchText: "",
  // sortfield: productSortFieldOption[0],
  // sortDirec: productSortDirectionOption[1],
  sortDirec: subHeaderSortOptionWithValue[2],
  // Filter States
  category: [],
  provider: [],
  services: [],
  status: undefined,
  isFilterApplied: false,
  open: false,
  popperData: []
}
class ListPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      id:"AddPackage"
    };
  }

  componentDidMount() {
    this.getPackages();
    scrollToTop();
    window.addEventListener("scroll", this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  }

  scrolling = () => {
    let { loading, data, error } = this.props?.package;

    let listCount = data?.length ?? 0;
    let totalCount = data?.[0]?.totalCount ?? 0;

    if (loading || error || totalCount === 0 || listCount === 0) {
      return false;
    }

    if ((Math.round(window.scrollY + window.innerHeight) >= document.getElementsByTagName('body')[0].scrollHeight) && (listCount !== totalCount)) {
      this.setState((state, props) => ({
        pageNo: state.pageNo + 25,
      })
        , () => {
          this.getPackages();
        });
    }
  }

  getPackages = () => {
    let _status = undefined
    if(this.state?.status){
      if(this.state.status.toLowerCase() === "Active".toLowerCase()) _status = true
      if(this.state.status.toLowerCase() === "InActive".toLowerCase()) _status = false
    }
    
    const payload = {
      pageNo: this.state.pageNo,
      pageSize: this.state.pageSize,
      // sortDirec: this.state.sortDirec === null ? productSortDirectionOption[0].value : this.state.sortDirec.value,
      // sortfield: this.state.sortfield === null ? productSortFieldOption[0].value : this.state.sortfield.value,
      // category: this.state.category && this.state.category?.map(_ => _.value)?.join(','),
      // providerId: this.state.provider && this.state.provider?.map(_ => _.value)?.join(','),
      filter:{sort: this.state.sortDirec.value,
        category: this.state.category && this.state.category?.map(_ => _.value),
        partnerIds: this.state.provider && this.state.provider?.map(_ => _.value),
        status: _status},
      status: this.state.status,
      searchName: this.state.searchText,
      //TODO: services state is not USED.
      resourceTypeId: "3c968f29-e785-4910-b62a-b7a8a8960c23",
      limit:this.state.pageSize,
      offset:this.state.pageNo,
    }
    this.props.GET_ALL_PACKAGES(payload);
  };

  handleEdit = (id, versionId) => {
    this.props.history.push(routes.manage_package + "/edit/" + id + "/" + versionId);
  };

  handleDelete = (pg) => {
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body: "Do you want to delete the Package : " + pg?.name,
      positiveBtn: "Delete",
      negativeBtn: "Cancel",
      onOk: () => this.deletePackage(pg),
    });
  };

  deletePackage = (pg) => {
    this.props.dialog.setDialog({ open: false });
  };

  handleDeactivate = (pg) => {
    const Oldstatus = pg?.isactive;
    let status = "Activate";
    if (Oldstatus  === "active") {
      status = "Deactivate";
    }
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body: "Do you want to " + status + " Package : " + pg?.name,
      positiveBtn: status,
      negativeBtn: "Cancel",
      onOk: () => this.deactivatePackage(pg),
    });
  };

  deactivatePackage = (pg) => {
    const id = pg?.id;
    const status = pg?.isactive?.toLowerCase() === "active" ? true : false;
    this.props.dialog.setDialog({ open: false });
    this.props.UPDATE_STATUS_OF_PACKAGE({ packageID: id, status: !status, versionId: pg?.versionId });
  };

  navigateToView = (id, versionId) => {
    this.props.history.push(routes.manage_package + "/view/" + id + "/" + versionId);
  };

  storeInputSearchValue = (value) => {
    this.setState(
      { searchText: value, pageNo: 0, pageSize: 25 },
      () => {
        if (value.length % 3 === 0) {
          this.getPackages();
        }
      }
    );
  };


  shouldComponentUpdate(nextProps, nextState) {

    //Update Status
    const { updatePackageStatusInfo } = nextProps;
    const oldupdatePackageStatusInfo = this.props.updatePackageStatusInfo;

    //Checking for Update Status
    if (
      oldupdatePackageStatusInfo?.loading !== updatePackageStatusInfo?.loading
    ) {
      this.props.backDrop.setBackDrop({ open: updatePackageStatusInfo.loading, message: "Updating the package status..." })
      if (
        !updatePackageStatusInfo.loading &&
        !updatePackageStatusInfo.error
      ) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Package Status updated successfully!",
          severity: alertProps.severity.success,
        });
        this.getPackages();
      } else if (updatePackageStatusInfo.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to update package status.",
          severity: alertProps.severity.error,
        });
      }
    }

    return true;
  }

  // -----------------------FILTERS-------------------
  // FILTERS FUNCTION
  // Filter Handlers
  onFilterIconClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: <FilterBuilder
        components={filterComponent(this.state, this.props)}
        applyButtonLabel="Apply"
        loading={this.props?.package?.loading}
        onChange={this.onFilterChange}
        onApplyButtonClicked={this.applyFilter}
        resetFilter={this.resetFilter}
        onCancelClick={this.closeFilter}
        spacing={1}
        saveButtonId={"saveFilterButtonFromAddPackage"}
        cancelButtonId={"cancelFilterButtonFromAddPackage"}
        clearAllButtonId={"clearAllFilterButtonFromAddPackage"}
      />
    })
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  anyFilterApplied = (state) => {
    let { category = [], provider = [], status = "", sortDirec = productSortDirectionOption[0], sortfield = productSortFieldOption[0] } = state
    if (category === null) category = initialState.category
    if (provider === null) provider = initialState.provider
    if (JSON.stringify(category) === JSON.stringify(initialState.category) &&
      JSON.stringify(provider) === JSON.stringify(initialState.provider) &&
      status === initialState.status &&
      sortDirec.value === initialState.sortDirec.value &&
      sortfield.value === initialState.sortfield.value) {
      return false
    } else {
      return true
    }
  }

  applyFilter = (state) => {
    this.setState({
      ...state,
      pageNo: 0,
      pageSize: 25,
      isFilterApplied: this.anyFilterApplied(state),
    }, () => {
      this.getPackages();
      this.closeFilter()
    })
  }

  closeFilter = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    })
  }

  resetFilter = () => {
    this.setState({
      ...initialState
    }, () => {
      this.getPackages();
      this.closeFilter();
    })
  }


  // FILTER TAG FUNCTION
  // Filter Tag Utils
  checkIsThereAnyFilter = () => {
    let newState = {
      ...this.state,
      category: this.state.category === null ? initialState.category : this.state.category,
      provider: this.state.provider === null ? initialState.provider : this.state.provider,
    }

    if (newState[filterTagType.Category]?.length === 0 &&
      newState[filterTagType.Provider]?.length === 0 &&
      //TODO: //newState[filterTagType.Services].length === 0 && 
      newState[filterTagType.Status] === initialState.status &&
      newState[filterTagType.SortDirection]?.value === initialState.sortDirec?.value &&
      newState[filterTagType.SortField]?.value === initialState.sortfield?.value
    ) {

      return false
    } else {

      return true
    }
  }

  handleChipDelete = (key, index) => {
    if (key === filterTagType.Category ||
      key === filterTagType.Provider
      //TODO: //|| key === filterTagType.Services
    ) {
      let newState = this.state
      newState[key].splice(index, 1)
      this.setState({
        ...newState,
        pageNo: 0,
        pageSize: 25,
        isFilterApplied: this.checkIsThereAnyFilter()
      }, () => {
        this.getPackages()
      })

    }
    if (key === filterTagType.Status) {
      this.setState({
        status: initialState.status,
        pageNo: 0,
        pageSize: 25,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getPackages()
      })
    }
    if (key === filterTagType.SortDirection) {
      this.setState({
        sortDirec: initialState.sortDirec,
        pageNo: 0,
        pageSize: 25,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getPackages()
      })
    }
    if (key === filterTagType.SortField) {
      this.setState({
        sortfield: initialState.sortfield,
        pageNo: 0,
        pageSize: 25,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getPackages()
      })
    }
  }

  // -----------------------------------------------
  handlePopperClose = () => {

    this.setState({ open: false })
  }
  handlePopperOpen = (e, data) => {

    this.setState({ open: e.currentTarget, popperData: data })
  }

  roleBasedAccess = (id) => {
    const data = this.props?.auth_data?.data;
    
    // Return True disable edit button
    // Return False enable edit button

    let disable = null;
    if(Boolean(data?.user_role === user_roles.service_coordinator || data?.user_role === user_roles.help_desk)){
      /*SC can;t edit a Product */
      disable = true
    }

    if (data?.user_role == user_roles.provider) {
      if(data?.user_id == id?.partnerId){
        /*User can edit a Product*/
        disable = false
      }else{
        disable = true
      } 
    }
    if (data?.user_role == user_roles.supervisor) { 
        if (data?.partnerId == id?.partnerId) { 
            disable = false 
        } else{
          disable = true 
        } 
    }
    if (data?.user_role == user_roles.super_admin && data?.other_roles?.indexOf("cross-bundling") > -1) { 
      if (id?.care_provider?.[0]?.crossBundle) { 
        // id?.partnerId !== "5cd8f293-1e49-473e-af6b-bf27e986566f"
          /* no one can edit MC User's product except him*/
          disable = false
      }else{
          disable = true 
      }
    }

    if (data?.user_role == user_roles.super_admin && data?.other_roles?.indexOf("cross-bundling") === -1) { 
      if (id?.care_provider?.[0]?.crossBundle) { 
          /* no one can edit MC User's product except him*/
          disable = true
        }else{
          disable = false
      }
    }

    return disable;

  }

  addStyles = (packages) => {
    let styledPackages = packages.map((pckg) => {
      let orderList = pckg.orderList.map(list => {
        if(list?.label?.toString()?.toLowerCase() === "services"){
          return list
        }
        return {...list, style: {minWidth: 140}}
      })
      return {...pckg, orderList, isactive: pckg?.isactive ? "active" : "inactive"}
    })
    return Array.isArray(styledPackages) ? styledPackages : []
  }
    
  render() {
    let { loading, data, error } = this.props?.package;

    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          // direction="column"
          // justify="flex-start"
          // alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <SubHeader
              isbutton={!Boolean(this.props?.auth_data?.data?.user_role === user_roles.service_coordinator || this.props?.auth_data?.data?.user_role === user_roles.help_desk || this.props.auth_data.data.user_role === user_roles.business_admin)}
              placeholder={"Search for Packages"}
              button_name={"+ ADD PACKAGE"}
              countText={`Total Packages (${data?.[0]?.totalCount ?? 0})`}
              entityName={"Package"}
              link={routes.add_package}
              handleSearch={() => this.getPackages()}
              handleChangeInputSearch={this.storeInputSearchValue}
              value={this.state.searchText}
              useNewFilterIcon
              placeSearchIconToLeft
              // hideFilter
              // hideSearch

              isFilterApplied={this.state.isFilterApplied}
              onFilterIconClicked={this.onFilterIconClicked}
              id={"AddPackage"}
            />
          </Grid>

          {/* Filter Tag */}
          {
            this.state.isFilterApplied &&
            <Grid item xs={12} style={{ marginTop: 25 }} >
              <FilterTags
                filterData={this.state}
                handleDelete={this.handleChipDelete}
              />
            </Grid>
          }


          {/*TOTAL COUNT  */}
          {/* <Grid item xs={12}>
            <Typography variant="h6" style={{ paddingTop: 25 }} className={classes.mediumFont} >Total Packages ({data?.total_count ?? 0})</Typography>
          </Grid> */}

          {loading && (
            <Grid item xs={12}>
              <ListLoader />
            </Grid>
          )}
          {!loading && error && (
            <Grid item xs={12}>
              <InternatlServerError
                message="Unable to fetch packages"
                onRetry={this.getPackages}
                top="20vh"
              />
            </Grid>
          )}
          {!loading && !error && !data?.length && (
            <Grid item xs={12}>
              <NotFoundSection message="You don't have any packages" top="25vh" />
            </Grid>
          )}
          {!error && !loading && data?.length > 0 && (
            <Grid item xs={12}>
              <ProductCatalogue
                id={"package"}
                catalogues={this.addStyles(data)}
                redirectUrl={routes.manage_package + "/view/"}
                handleItemEdit={this.handleEdit}
                handleItemDeactivate={this.handleDeactivate}
                loading={loading}
                showProvider
              />
              {/* {data.map((list, key) => {
                return (
                  <div key={key} style={{ cursor: "pointer", marginBottom: 12 }}
                    // onMouseLeave={this.handlePopperClose}
                    onMouseLeave={(e) => {
                      if (e?.relatedTarget?.id === "popper") {
                        return false
                      } else {
                        this.handlePopperClose()
                      }
                    }}
                  >
                    <ListCard
                      data={list}
                      open={this.state.open}
                      handlePopperClose={this.handlePopperClose}
                      handlePopperOpen={this.handlePopperOpen}
                      popperData={this.state.popperData}
                      // isMoreBtn={true}
                      isMoreBtn={!this.roleBasedAccess(list)}
                      handleEdit={this.handleEdit}
                      handleDelete={this.handleDelete}
                      handleDeactivate={this.handleDeactivate}
                      navigateToView={this.navigateToView}
                      setPriceTrailingDecimal
                      // isCountLabelAPrice
                    />
                  </div>
                );
              })} */}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  package: state?.packageState?.allPackages,
  updatePackageStatusInfo: state?.packageState?.updatePackageStatusInfo,
  auth_data: state?.authState?.loginAuth,
});

export default connect(
  mapStateToProps,
  actions
)(withAlertAndDialog(withRouter(withStyles(styles)(ListPackages))));
