/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-01-27
 * @modify date 2021-06-25
 * @desc Form Renderer
 */
 import { makeStyles, Grid, Paper, } from '@material-ui/core'
 import React from 'react'
 import { NotFoundSection } from '../../components'
 import { SubHeader, CaseDefinition } from '../formConfigurator/components'
 import { FormTreeView } from './formTree'
 import { FormBuilder } from './formBuilder'
 import { useHistory } from 'react-router-dom'
 import { DrawerContext } from '../../contexts';
 import { drawerProps } from '../../utils';
//  import { PatientDetail } from './patientDetail';
//  import { PatientDetailCard } from '../../components/patientDetailCard'
 
 const useStyles = makeStyles(theme => ({
   root: {
     flexGrow: 1,
     padding: 16,
     [theme.breakpoints.only('xs')]: {
       padding: 10
     }
   },
   backBtn: {
     [theme.breakpoints.up('sm')]: {
       marginBottom: 12
     },
     boxShadow: '0px 15px 25px #110f4712',
     backgroundColor: '#fff',
     paddingRight: theme.spacing(2),
     '&:hover': {
       boxShadow: '0px 15px 25px #110f4712',
       backgroundColor: '#fff',
       paddingRight: theme.spacing(2)
     }
   },
   treePaper: {
     padding: theme.spacing(1),
     marginBottom: 10,
     overflow: 'auto',
     [theme.breakpoints.up('sm')]: {
       height: 'calc(100vh - 150px)',
       marginBottom: 0,
       borderBottomRightRadius: 0,
       borderTopRightRadius: 0
     },
     borderTopLeftRadius: 12,
     borderBottomLeftRadius: 12,
     borderBottomRightRadius: 12,
     borderTopRightRadius: 12,
     border: '1px solid #0000001A',
     boxShadow: '0px 15px 25px #110f4712',
     [theme.breakpoints.down('sm')]: {
       display: 'none'
     }
   },
   formBuilderPaper: {
     [theme.breakpoints.up('sm')]: {
       height: 'calc(100vh - 150px)',
       borderTopLeftRadius: 0,
       borderBottomLeftRadius: 0
     },
     overflow: 'auto',
     boxShadow: '0px 15px 25px #110f4712',
     border: '1px solid #0000001A'
   },
   subbacktext: {
     opacity: 0.6,
     fontSize: 12,
     paddingLeft: 4
   },
 }))
 
 export const FormRenderer = ({
   form_detail,
   tree_view,
   forms,
   parent_form_id,
   entry_id,
   isEdit,
   answer,
   isInvestigator = false,
   secToShow = [],
   secName = '',
   isReadyOnly = false,
   toggleButtonStatus = {},
   getPatientDetails = {},
   entryPatientDetails = {}
 }) => {
 
   const classes = useStyles();
   const history = useHistory();
   const drawerContext = React.useContext(DrawerContext);
   const [view, setView] = React.useState([]);
   const [historyOfViewedForms, setHistoryOfViewedForms] = React.useState([]);
   const [patientDetail, setPatientDetail] = React.useState(entryPatientDetails);
   const [showForm, setShowForm] = React.useState(isEdit);
 
   const [state, setState] = React.useState({
     selected_form: forms[0],
     form_id: forms[0]?.id,
     answer: answer,
   });
 
   const onInfoClicked = data => {
     drawerContext.setDrawer({
       ...drawerContext,
       open: true,
       direction: drawerProps.direction.right,
       variant: drawerProps.variant.temporary,
       component: <CaseDefinition data={form_detail?.mapped_diseases} />,
       onClose: () => onDrawerClosed()
     })
   };
 
  //  const onPatientInfoBtnClicked = () => {
  //    drawerContext.setDrawer({
  //      ...drawerContext,
  //      open: true,
  //      direction: drawerProps.direction.left,
  //      variant: drawerProps.variant.temporary,
  //      component: <PatientDetailCard {...patientDetail} />,
  //      onClose: () => onDrawerClosed()
  //    })
  //  }
 
   const onDrawerClosed = () => {
     drawerContext.setDrawer({
       ...drawerContext,
       direction: drawerProps.direction.right,
       open: false
     })
   };
 
   const getSectionId = data => {
     setState({
       ...state,
       selected_form: forms?.filter(_ => _.id === data.id)?.[0],
       form_id: data.id
     })
 
     drawerContext.setDrawer({
       ...drawerContext,
       direction: drawerProps.direction.left,
       open: false,
       onClose: () => onViewSectionDrawerClosed()
     })
 
   };
 
   const onBackBtnClicked = () => {
     history.goBack();
   };
 
   const viewSectionDrawer = () => {
     drawerContext.setDrawer({
       ...drawerContext,
       open: true,
       direction: drawerProps.direction.left,
       variant: drawerProps.variant.temporary,
       component: <div style={{ padding: 20 }}>
         <FormTreeView
           tree_view={tree_view}
           getSectionId={getSectionId}
           selected_section_id={state.form_id}
           selected_form={state.selected_form}
           isEdit={isEdit}
           view={view}
           historyOfViewedForms={historyOfViewedForms}
           setHistoryOfViewedForms={setHistoryOfViewedForms}
           setView={setView}
           isReadyOnly={isReadyOnly}
           secToShow={secToShow}
         />
       </div>,
       onClose: () => onViewSectionDrawerClosed()
     })
   };
 
   const onViewSectionDrawerClosed = () => {
     drawerContext.setDrawer({
       ...drawerContext,
       direction: drawerProps.direction.left,
       open: false
     })
   };
 
   const updateAnswer = (answer) => {
     setState({
       ...state,
       answer
     })
   };
 
  //  const onPatientSelected = (data) => {
  //    setPatientDetail({
  //      ...patientDetail,
  //      ...data
  //    });
 
  //    setShowForm(true);
  //  }
 
   const restForm = (data) => {
     setPatientDetail(null);
     setShowForm(false);
   }
 
   React.useEffect(() => {
     setHistoryOfViewedForms([...historyOfViewedForms, view]);
     // eslint-disable-next-line 
   }, [view])
 
   return (
     <div className={classes.root}>
       <Grid
         container
         direction='column'
         justify='flex-start'
         alignItems='stretch'
       >
         {/* Header Section */}
         <Grid item>
           <SubHeader
             title={form_detail?.name}
             subTitle={`(${form_detail?.master_form_type?.form_type ?? "Form Type"})`}
             onBackBtnClicked={onBackBtnClicked}
             onActionIconBtnClicked={onInfoClicked}
             showInfoButton
             secName={secName}
             showToogleButton
             toggleButtonData={
               isInvestigator ? toggleButtonStatus.toggleButtonData?.filter(l => l.value === 5 || l.value === 6) : toggleButtonStatus.toggleButtonData
             }
             selected={toggleButtonStatus.isSelected}
             exclusive={toggleButtonStatus.exclusive}
             onSelected={toggleButtonStatus.onSelected}
             statusUpdateData={toggleButtonStatus.statusUpdateData}
             statusPath={toggleButtonStatus.status}
            //  redirectPath={toggleButtonStatus?.redirectPath}
            //  showPatientDetails={true}
            //  getPatientDetails={patientDetail}
            //  view={view}
            //  setView={setView}
            //  isReadyOnly={isReadyOnly}
             secToShow={secToShow}
            //  onPatientInfoBtnClicked={onPatientInfoBtnClicked}
           />
         </Grid>
 
         {/* Main Content */}
         {forms.length > 0 && (
           <Grid
             item
             container
             direction='row'
             justify='flex-start'
             alignItems='flex-start'
           >
 
             {/* Form View */}
             <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
               <Paper className={classes.formBuilderPaper}>
 
                 {/* {!showForm && <PatientDetail
                   patientDetail={entryPatientDetails}
                   isEdit={isEdit}
                   onPatientSelected={onPatientSelected}
                 />} */}
 
                 {showForm && <FormBuilder
                   getSectionId={getSectionId}
                   form_id={state.form_id}
                   forms={forms}
                   parent_form_id={parent_form_id}
                   isEdit={isEdit}
                   entry_id={entry_id}
                   answer={answer}
                   isInvestigator={isInvestigator}
                   isReadyOnly={isReadyOnly}
                   secToShow={secToShow}
                   secName={secName}
                   onAnswerUpdated={updateAnswer}
                   view={view}
                   setView={setView}
                   viewSection={viewSectionDrawer}
                   setHistoryOfViewedForms={setHistoryOfViewedForms}
                   statusUpdateData={toggleButtonStatus.statusUpdateData}
                   patientDetail={patientDetail}
                   restForm={restForm}
                 />}
               </Paper>
             </Grid>
           </Grid>
         )}
 
         {forms.length === 0 && (
           <NotFoundSection message='No Forms Added Yet!' />
         )}
       </Grid>
     </div>
   )
 }
 