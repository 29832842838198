import React from 'react';
import {
    makeStyles,
    Grid,
    ListItem,
    List,
    ListItemText,
    ListItemSecondaryAction,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: "0px 0px 0px 1px #e0e0e0d6",
        borderRadius: 8,
        marginBottom: 12
    },
    list: {
        width: "100%",
        padding: "0px 12px",
        background: "#fafafa",
    },
    listItem: {
        padding: "0px 12px"
    },
    avatar: {
        width: "100px",
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        height: "100%",
        background: "rgba(54, 58, 87, 0.11)",
        display: "flex",
        "& img": {
            margin: "auto"
        }
    },
}))

const CertificationcardsLoading = (props) => {

    const classes = useStyles();

    return <Grid container className={classes.root}>

        {/* Certificationcards loading */}
        <Grid item xs={2} md={1} sm={1} lg={1}> <div /><Skeleton variant="rect" className={classes.avatar} /> </Grid>
        <Grid item xs={10} md={11} sm={11} lg={11}>
            <List className={classes.list}>
                <ListItem className={classes.listItem}>
                    <ListItemText primary={<Skeleton variant="text" width="70%" />} secondary={<React.Fragment>
                        <Skeleton variant="text" width="70%" />
                        <Skeleton variant="text" width="70%" />
                    </React.Fragment>} /> </ListItem>
                <ListItemSecondaryAction> <Skeleton variant="text" width="100%" height="100%" />
                </ListItemSecondaryAction>
            </List>
        </Grid>
        {/* end */}

    </Grid>
}
export default CertificationcardsLoading;
