import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function BloodgroupIconV2(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.817 11.149l-4.358-5.456-4.36 5.456a5.5 5.5 0 108.719 0h-.001z"
        fill="none"
        stroke="#0063E7"
      />
      <path
        d="M13.28 8.583L9.619 4 5.957 8.583a4.62 4.62 0 107.324 0h-.001z"
        fill="none"
        stroke="#0063E7"
      />
      <path
        d="M8.959 14.491a5.5 5.5 0 00.206 1.5 4.632 4.632 0 002.893-.672 4.613 4.613 0 001.223-6.731l-.566-.708-2.617 3.275a5.467 5.467 0 00-1.139 3.336z"
        fill="#0063E7"
        fillOpacity={0.43}
      />
    </SvgIcon>
  );
}

export default BloodgroupIconV2;
