import React from "react";
import { MultipleChip } from "../multipleChip";
import { makeStyles, Typography, Divider, Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { textClampStyleGenerator } from "../../utils";
import moment from "moment";
import Skeleton from "@material-ui/lab/Skeleton";


const useStyles = makeStyles((theme) => ({
  // itemGender: {
  //   color: "inherit"
  // },

  stretchItem: {
    alignSelf: "stretch",
  },
}));


const CustomTypography = styled(Typography)({
  color: "inherit",
  userSelect: "none",
});

export function InfoUnderline({ aliasId, category, isCategoryAChip, type, gender, age, loading, ...props }) {
  const classes = useStyles();
  return (
    <>
      {/* Grid item to render TYPE of the card */}
      {Array.isArray(type) && type?.length > 0 && type?.[0]?.label && (
        <>
          <Grid item>
            <ItemTypeRenderer type={type?.[0]?.label} loading={loading} />
          </Grid>
          <Grid item className={classes.stretchItem}>
            <Divider orientation="vertical" />
          </Grid>
        </>
      )}

      {/* Grid item to render GENDER & AGE of the card */}
      {gender && gender?.label && !!age && moment(age?.toString())?.isValid() ? (
        <>
          <Grid item>
            <ItemAgeGenderRenderer gender={gender?.label} age={age} loading={loading} />
          </Grid>
          <Grid item className={classes.stretchItem}>
            <Divider orientation="vertical" />
          </Grid>
        </>
      ) : gender && gender?.label ?
        <>
          {/* Grid item to render GENDER of the card */}
          <Grid item>
            <ItemGenderRenderer gender={gender?.label} age={age} loading={loading} />
          </Grid>

          <Grid item className={classes.stretchItem}>
            <Divider orientation="vertical" />
          </Grid>
        </>
        : !!age && moment(age?.toString())?.isValid() ?
          <>
            {/* Grid item to render AGE of the card */}
            <Grid item>
              <ItemAgeRenderer age={age} loading={loading} />
            </Grid>
            <Grid item className={classes.stretchItem}>
              <Divider orientation="vertical" />
            </Grid>
          </>
          : <></>
      }

      {/* Grid item to render CATEGORY of the card */}
      {!isCategoryAChip && Array.isArray(category) && category?.length > 0 && (
        <Grid item>
          <MultipleChip category={category} loading={loading} />
        </Grid>
      )}
      {isCategoryAChip && <InfoUnderlineChipFree text={Array.isArray(category) ? category?.[0] : []} />}
    </>
  );
}


// Chip free 
function InfoUnderlineChipFree({ text }) {

  const textStyle = {
    fontSize: "0.8125rem"
  }

  return (
    <>
      <Typography component={"span"} style={textStyle} noWrap >{text?.label}</Typography>
    </>
  )
}

// Item Type Renderer
const itemTypeStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 700,
  },
}));

function ItemTypeRenderer({ type, loading }) {
  const classes = itemTypeStyles();
  return (
    <>
      {loading && (
        <Skeleton variant="text" width={40} />
      )}
      {!loading && (
        <CustomTypography variant="body2" className={classes.root}>
          {!!type ? type : "N/A"}
        </CustomTypography>
      )}
    </>
  );
}

// Item AGE & GENDER Renderer
const itemAgeGenderStyles = makeStyles((theme) => ({
  root: {
    ...textClampStyleGenerator(),
  },
}));
function ItemAgeGenderRenderer({ gender, age, loading }) {
  const classes = itemAgeGenderStyles();
  return (
    <>
      {loading && <Skeleton variant="text" width={40} />}
      {!loading && (
        <CustomTypography variant="body2" className={classes.root}>{`${gender}, ${moment().diff(age, "years") === 0
          ? moment().diff(age, "days") + " days old"
          : moment().diff(age, "years") + " years old"}`}</CustomTypography>
      )}
    </>
  );
}

// Item GENDER Renderer
function ItemGenderRenderer({ gender, age, loading }) {
  return (
    <>
      {loading && <Skeleton variant="text" width={40} />}
      {!loading && (
        <CustomTypography variant="body2">{gender}</CustomTypography>
      )}
    </>
  );
}

// Item AGE Renderer
const itemAgeStyles = makeStyles((theme) => ({
  root: {
    ...textClampStyleGenerator(),
  },
}));

function ItemAgeRenderer({ age, loading }) {
  const classes = itemAgeStyles();
  return (
    <>
      {loading && <Skeleton variant="text" width={40} />}
      {!loading && (
        <CustomTypography variant="body2" noWrap className={classes.root}>
          {moment().diff(age, "years") === 0
            ? moment().diff(age, "days") + " days old"
            : moment().diff(age, "years") + " years old"}
        </CustomTypography>
      )}
    </>
  );
}
