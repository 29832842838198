import React from 'react';
import PropTypes from 'prop-types';
import { Grid,Typography } from '@material-ui/core';

export const NotFoundSection = (props) =>{

    const {
        message,    
        top   
    } = props;

    return <div style={{width: "100%"}}><Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    style={{ marginTop: top }}
  >    
    <Grid item>
      <Typography variant="h6" align="center">
        {message}
      </Typography>
    </Grid>   
    
  </Grid>
  </div>
}

NotFoundSection.propTypes = {
    message: PropTypes.string,
    top: PropTypes.string
}

NotFoundSection.defaultProps = {
    message: '404 - Not Found',
    top: '30vh'
}

