/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-02-18 12:49:37
 * @modify date 2022-02-18 12:49:37
 * @desc [description]
 */
import React, { useEffect, useState } from "react";
// import { Wrapper, Status } from "@googlemaps/react-wrapper";
// import { Map } from "./map";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import CustomMapMarker from "./customMapMarker";
import DoctorIcon from "../../../../../assets/icons/doctor.svg";
import CustomMarker from "../../../../../assets/icons/mapMarker.svg";
import copy from "fast-copy";
import withCustomMapUI from "./withCustomMapUI";
import CustomMapUIControls from "./customMapUIControls";
import { calculateDistanceTravelledPercentage } from "../../../../../utils";

// const drawerWidth = 400;

// const defaultMarkerIcon =
//   "https://mt.google.com/vt/icon/text=&psize=16&font=fonts/arialuni_t.ttf&color=ff330000&name=icons/spotlight/spotlight-waypoint-b.png&ax=44&ay=48&scale=1";

function TrackingMap({
  center,
  markers: clientMarkers = [],
  currentAppointmentData, //SP emitted data

  dateProps,

  // HOC Props
  fullScreen,
  initDateControl,
  ...props
}) {
  const selectedTimelineDate = dateProps?.date;

  // Map Instance
  const [map, setMap] = useState(null);
  const [mapClass, setMapClass] = useState(null);
  // const [origin, setOrigin] = useState({ lat: 3.1615473, lng: 101.696155 });
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [ongoingAppointmentId, setOngoingAppointmentId] = useState(null);
  const [permissionToRenderDirection, setPermissionToRenderDirection] =
    useState(false);

  const [markers, setMarkers] = useState([]);

  const [isZoomedToSPOnce, setIsZoomedToSPOnce] = useState(false);

  // const [destination, setDestination] = useState({
  //   lat: 3.1102762,
  //   lng: 101.6655838,
  // });

  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);

  const [originMarker, setOriginMarker] = useState(null);
  const [destinationMarker, setDestinationMarker] = useState(null);

  const [arrivalTime, setArrivalTime] = useState(null);

  const [initialDistance, setInitialDistance] = useState(null);
  const [travelledPercentage, setTravelledPercentage] = useState(0);

  const handleMapChange = (map) => {
    if (!directionsRenderer || !originMarker || !destinationMarker) return;
    directionsRenderer.setMap(map);
    originMarker.setMap(map);
    destinationMarker.setMap(map);
  };

  // useEffect(() => {
  //   setIsZoomedToSPOnce(false);
  // }, [ongoingAppointmentId]);

  useEffect(() => {
    if (!map) return;
    initDateControl(map);
  }, [map, selectedTimelineDate, initDateControl]);

  useEffect(() => {
    if (!ongoingAppointmentId) return;

    debugger;

    let isAppointmentHappeningToday = clientMarkers.find(
      (marker) => marker?.id === ongoingAppointmentId
    );

    if (!Boolean(isAppointmentHappeningToday)) {
      handleMapChange(null);
    }

    if (Boolean(isAppointmentHappeningToday)) {
      handleMapChange(map);
    }

    setPermissionToRenderDirection(Boolean(isAppointmentHappeningToday));
  }, [ongoingAppointmentId, clientMarkers]); // eslint-disable-line

  useEffect(() => {
    setMarkers(clientMarkers);
  }, [clientMarkers]);

  // Setting Origin and Destination states
  useEffect(() => {
    if (!markers) return;
    if (!currentAppointmentData) return;
    if (
      !currentAppointmentData?.latitude ||
      !currentAppointmentData?.longitude ||
      !currentAppointmentData?.appointmentId
    )
      return;
    // const appointmentDetails = markers?.filter(
    //   (marker) => marker?.id === currentAppointmentData?.appointmentId
    // )?.[0];

    // let appointmentDetails = {
    //   lat: 3.1102762,
    //   lng: 101.6655838,
    // };

    setOngoingAppointmentId(currentAppointmentData?.appointmentId);

    // Set SP Origin
    setOrigin({
      lat: currentAppointmentData?.latitude,
      lng: currentAppointmentData?.longitude,
    });

    // Set SP Destination / Client Location
    setDestination({
      lat: currentAppointmentData?.destinationLatitude,
      lng: currentAppointmentData?.destinationLongitude,
    });
  }, [currentAppointmentData]); // eslint-disable-line

  const calculateDirectionAndRender = () => {
    if (!map || !mapClass) return;
    // let directionsService = new mapClass.DirectionsService();
    // let directionsRenderer = new mapClass.DirectionsRenderer({
    //   // Set the map on the directionsRenderer object
    //   map: map,
    //   suppressMarkers: true,
    //   polylineOptions: {
    //     strokeColor: "#363A57",
    //   },
    // });

    // Pass the origin, destination, and travel mode to the directions service
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: mapClass.TravelMode.DRIVING,
      },
      function (response, status) {
        if (status === "OK") {
          console.log(response);

          // Set the directions on the directions renderer
          directionsRenderer.setDirections(response);

          // Create markers for start and end of directions
          const leg = response.routes[0].legs[0];

          // if (directionMarkers.length > 2) {
          //   clearMarkers();
          // }

          if (initialDistance) {
            const travelledPercentage = calculateDistanceTravelledPercentage(
              initialDistance,
              leg.distance.value
            );
            setTravelledPercentage(travelledPercentage);
          } else {
            setInitialDistance(leg.distance.value);
          }

          // Create a marker for the start location
          originMarker.setPosition(leg.start_location);
          destinationMarker.setPosition(leg.end_location);

          // Set Arrival Time
          setArrivalTime(leg.duration.text);

          if (!isZoomedToSPOnce) {
            props.zoomToFitMarker(map, new mapClass.LatLng(leg.start_location));
            setIsZoomedToSPOnce(true);
          }

          // let myMarkers = [];

          // myMarkers.push(
          //   new mapClass.Marker({
          //     position: leg.start_location,
          //     map: map,
          //     icon: DoctorIcon,
          //   })
          // );

          // myMarkers.push(
          //   new mapClass.Marker({
          //     position: leg.end_location,
          //     map: map,
          //     icon: CustomMarker,
          //   })
          // );

          // Create a marker for the end location
          // new mapClass.Marker({
          //   position: leg.end_location,
          //   map: map,
          //   icon: CustomMarker,
          // });

          // // Remove Previous Markers if set
          // if (directionMarkers) {
          //   const markers = [...directionMarkers];
          //   markers[0].setMap(leg.start_location);
          //   markers[1].setMap(leg.end_location);
          //   setDirectionMarkers([markers[0], markers[1]]);
          // } else {

          //   setDirectionMarkers([originMarker, destinationMarker]);
          // }
        } else if (status === "ZERO_RESULTS") {
          // Handle zero results but successful api call result from GOOGLE MAPS
        } else {
          // window.alert("Directions request failed due to " + status);
        }
      }
    );
  };

  useEffect(() => {
    if (!origin || !destination || !permissionToRenderDirection) return;

    calculateDirectionAndRender();
  }, [origin, destination, calculateDirectionAndRender]); // eslint-disable-line

  const handleApiLoaded = (currentMap, maps) => {
    // After my 3rd Party package successfully loaded, I can now use the maps object
    setDirectionsService(new maps.DirectionsService());
    setDirectionsRenderer(
      new maps.DirectionsRenderer({
        // Set the map on the directionsRenderer object
        map: currentMap,
        suppressMarkers: true,
        preserveViewport: true,
        polylineOptions: {
          strokeColor: "#363A57",
        },
      })
    );

    // If there is any marker, then zoom the first plotted marker from the array
    if (markers?.length > 0) {
      props.zoomToFitMarker(
        currentMap,
        new window.google.maps.LatLng(
          markers?.[0]?.latitude,
          markers?.[0]?.longitude
        )
      );
    }

    setOriginMarker(
      new maps.Marker({
        map: currentMap,
        icon: DoctorIcon,
      })
    );

    setDestinationMarker(
      new maps.Marker({
        map: currentMap,
        icon: CustomMarker,
      })
    );

    // Full Screen Controls
    props.initFullscreenControl(currentMap);

    // GPS + Zoom In & Out Controls
    props.initGpsZoomControl(currentMap);

    setMap(currentMap);
    setMapClass(maps);
  };

  useEffect(() => {
    if (!map) return;

    initDateControl(map);
  }, [map, fullScreen, initDateControl]);

  // onChildClick callback can take two arguments: key and childProps
  const onChildClickCallback = (key) => {
    const index = markers.findIndex((e) => e?.id === key);
    // const tempMarkers = [...markers];
    const tempMarkers = markers.map((marker) => copy(marker));
    tempMarkers[index].show = !markers[index].show;
    setMarkers(tempMarkers);
  };

  return (
    <div
      style={{
        height: "100%",
        boxShadow: "0px 20px 25px #0000003D",
        borderRadius: "4px",
        width: "100%",
        // width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBqeACJgp12OLU6EkHeUtFgya2naH1LhrU" }}
        options={props.createMapOptions}
        defaultCenter={center}
        defaultZoom={10}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onChildClick={onChildClickCallback}
      >
        {markers.map((marker) => (
          <CustomMapMarker
            key={marker.id}
            lat={parseFloat(marker.latitude)}
            lng={parseFloat(marker.longitude)}
            place={marker}
            show={marker.show}
            arrivalTime={arrivalTime}
            travelledPercentage={travelledPercentage}
          />
        ))}
      </GoogleMapReact>

      <div style={{ display: "none" }}>
        <CustomMapUIControls />
      </div>
    </div>
  );
}

export default withCustomMapUI(TrackingMap);

TrackingMap.defaultProps = {
  center: { lat: 3.0802246, lng: 101.5561806 },

  // OK Demo
  origin: { lat: 3.1615476, lng: 101.696153 },
  destination: { lat: 3.1102762, lng: 101.6655838 },

  // Zero Result Demo
  // origin: { lat: -10.332214, lng: 23.273436 },
  // destination: { lat: -44.210564, lng: 170.806644 },

  // currentAppointmentData: {
  //   latitude: 3.1615476,
  //   longitude: 101.696153,
  //   appointmentId: "f8e16cbc-a1f1-4913-a95c-20422482590d",
  // },
  markers: [],
};

TrackingMap.propTypes = {
  center: PropTypes.object,
  markers: PropTypes.array,
  currentAppointmentData: PropTypes.objectOf({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    appointmentId: PropTypes.string,
  }),
};
