import React from "react";
import { Grid, Typography, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  brandSlogan: {
    marginTop: 12,
    fontWeight: "bold",
  },
  // /* Position child elements relative to this element */
  // "aspect-ratio-box": {
  //   position: "relative",
  //   width: "100%",


  //   /* Create a pseudo element that uses padding-bottom to take up space */
  //   "&:after": {
  //     display: "block",
  //     content: `""`,
  //     /* 16:9 aspect ratio */
  //     paddingBottom: "56.25%",
  //   },

  //   /* Image is positioned absolutely relative to the parent element */
  //   "& img": {
  //     /* Image should match parent box size */
  //     position: "absolute",
  //     left: 0,
  //     top: 0,
  //     width: "100%",
  //     height: "100%",
  //     maxWidth: "100%",
  //   }
  // },

});

class LeftPane extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        // style={{ borderRight: `1px solid rgba(244, 244, 245, 1)` }}
        // style={{
        //     height: "90%",
        // }}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRight: `1px solid rgba(244, 244, 245, 1)`,
          gap: 24
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <img
            src="/images/tanyaCare/TanyaStacked.png"
            // src="/svg/tanyacareLogo/tanyacareLight.svg"
            alt="Tanya.Care"
            style={{ width: "100%", height: "auto", maxWidth: 312 }}
          />
        </div>
        {/* <div className={classes["aspect-ratio-box"]}>
          <img
            src="/svg/tanyacareLogo/tanyacareLight.svg"
            alt="Tanya.Care"
            // style={{ width: "100%", height: "auto", maxWidth: 312 }}
          />
        </div> */}
        <div>
          <Typography align="center">
            Leveraging the power of a connected community, <br /> towards the
            empowerment of health, wellness and <br /> care for individuals and
            families.
          </Typography>
          <Typography align="center" variant={"body1"} className={classes.brandSlogan}>
            TANYA is just an ask away for better care
          </Typography>
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(LeftPane);
