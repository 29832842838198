import React, { useState } from "react";
import {
  Popper,
  Fade,
  Paper,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import { genericStyles } from "../../../utils";
import PropTypes from "prop-types";

// // Function to find if a element is exceeding 200px width
// const isOverflowing = (element) => {
//     return element.scrollWidth > element.clientWidth;
// };

const popperStyle = makeStyles((theme) => ({
  cardPaper: {
    borderRadius: "8px",
    boxShadow: theme.palette.primary.boxShadow,
    maxHeight: 250,
    overflow: "auto",
    minWidth: 100,
  },
  typography: {
    padding: theme.spacing(1),
  },
  countStyle: {
    textAlign: "center",
    width: "fit-content",
    marginRight: "0px",
    borderRadius: "3px",
    color: theme.palette.text.secondary,
    padding: 0,
    fontSize: "14px",
    paddingTop: "0px",
  },
  popperZindex: {
    zIndex: theme.zIndex.modal,
  },
  ...genericStyles.cardWrapperShadows(theme),
}));

export function PopperList({ count, list, ...props }) {
  const classes = popperStyle();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    if (event) {
      setOpen(event.currentTarget);
      event.stopPropagation();
    }
    return true;
  };

  const handleClose = (e) => {
    setOpen(false);
    return true;
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton
        className={classes.countStyle}
        disableRipple
        size="small"
        onMouseEnter={handleOpen}
        onMouseLeave={(e) => {
          if (e?.relatedTarget?.id === "popper") {
            return false;
          } else {
            handleClose();
          }
        }}
      >
        +{count}
      </IconButton>
      <Popper
        open={open}
        anchorEl={open}
        placement={"bottom-start"}
        transition
        className={classes.popperZindex}
        onClick={(e) => {
          e.stopPropagation();
          return false;
        }}
        onMouseLeave={handleClose}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              className={`${classes.cardPaper} ${classes.cardWrapperShadows}`}
            >
              {list.map((val) => {
                return (
                  <Typography
                    id={`popper`}
                    className={classes.typography}
                    style={{ cursor: "default" }}
                  >
                    {val}
                  </Typography>
                );
              })}
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

PopperList.defaultProps = {
  count: 0,
  list: [],
};

PopperList.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  list: PropTypes.arrayOf(PropTypes.string),
};
