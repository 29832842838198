import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import  makeStyles  from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';
import { createTestProps } from '../../../../../utils/common';

const useStyles = makeStyles((theme)=>({
    title:{
        color:theme.palette.text.primary,
        fontSize:18,
        fontWeight:500,
        textAlign:'center',
        marginBottom:theme.spacing(3)
    },
    desc:{
        color:theme.palette.text.secondary,
        fontSize:16,
        fontWeight:400,
        letterSpacing:'-0.32px',
        textAlign:'center',
        marginBottom:theme.spacing(4)
    },
    btn:{
        textTransform:'capitalize',
        fontWeight:500,
        fontSize:16,
        padding:9,
        minWidth:105,
        borderRadius:8,
        marginBottom:theme.spacing(2)
    },
    icon:{
        marginBottom:theme.spacing(3)
    }
}));

export const WarningDialog = (props) =>{

    const classes = useStyles(props);

    const {
        icon="",
        title="",
        description="",
        buttonName="",
        buttonAction=null
    } = props;

    return(
        <div style={{textAlign:'center'}}>
            {icon && <div className={classes.icon}>{icon}</div>}
            {title && <Typography className={classes.title}>{title}</Typography>}
            {description && <Typography className={classes.desc}>{description}</Typography>}
            {buttonName && <Button variant={"contained"} color="primary" {...createTestProps("warning primary button")} className={classes.btn} onClick={buttonAction}>{buttonName}</Button>}
        </div>
    )
}

WarningDialog.propTypes={
    icon:PropTypes.node,
    title:PropTypes.string,
    description:PropTypes.string,
    buttonName:PropTypes.string,
    buttonAction:PropTypes.func
}