/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-02-13
 * @modify date 2021-06-29
 * @desc Sub Header with back button,title and subtitle.
 */

 import React from 'react';
 import { Grid, IconButton, Typography, makeStyles, Divider, Button, TextField } from '@material-ui/core';
 import { KeyboardBackspace } from '@material-ui/icons';
 import { ToggleButtonComponent } from '../../../../components';
 import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
 import MenuIcon from '@material-ui/icons/Menu';
 import { ArchivedDialog } from '../../../../components';
 // store
 import { actions } from 'tanyacare-middleware'
 import { useDispatch } from 'react-redux'
 import { Message, notifyTypeBesedOnEntryStatus } from '../../utils'
 
 import { AlertContext } from '../../../../contexts'
 import { alertProps } from '../../../../utils';
import { useTheme } from '@material-ui/core';
//  import { Tooltip } from '@material-ui/core';
 
 // Store Action's list
 const { UPDATE_ENTRY_STATUS } = actions
 
 
 const useStyles = makeStyles((theme) => ({
   root: {
 
   },
   subTitle: {
     marginLeft: theme.spacing(1)
   },
   header: {
     display: "flex",
     alignItems: "center",
     flexWrap: 'wrap'
 
   },
   divider: {
     margin: 10
   },
   viewSection: {
     padding: '5px 12px',
     background: '#FFFFFF 0% 0% no-repeat padding-box',
     boxShadow: '0px 3px 8px #110F471A',
     border: '1px solid #110F471A',
     borderRadius: 7
     // [theme.breakpoints.up('md')]: {
     //     display: 'none'
     // }
   },
   patientDetail: {
     marginLeft: 15,
     '& p': {
       display: 'flex',
       alignItems: 'center'
     },
     '& span': {
       fontSize: '1rem'
     },
     '& hr': {
       margin: 7,
       width: '1.5px',
       backgroundColor: '#000'
     }
   },
 }))
 
 export const SubHeader = ({
   hideBackBtn = false,
   hideTitle = false,
   hideSubTitle = false,
   title = '',
   subTitle = '',
   onBackBtnClicked = () => false,
   showToogleButton = false,
   toggleButtonData = [],
   selected = {},
   exclusive = false,
   onSelected = () => false,
   onActionIconBtnClicked = () => false,
   showInfoButton = false,
   showDivider = false,
   drawerButton = false,
   drawerButtonAction = () => false,
   statusUpdateData = {},
   statusPath = "",
  //  view,
  //  isReadyOnly,
   secName = "",
  //  redirectPath = "",
  //  getPatientDetails,
  //  showPatientDetails = false,
  //  onPatientInfoBtnClicked = () => false
   // setView
 
 }) => {
 
   const classes = useStyles();
   const alert = React.useContext(AlertContext)
  const theme = useTheme()
   // Declaring Store dispatch
   const dispatch = useDispatch();
 
   //show or hide dialog
   const [open, setOpen] = React.useState(false);
 
 
   const [selectedData, setSelectedData] = React.useState(selected ?? {});
 
   
   //dialog inside textField
   const [state, setState] = React.useState({
     archivedText: "",
     error: {
       archivedText: false
     }
   })
 
   //set state the data and status
   const [statusData, setStatusData] = React.useState({
     status: '',
     data: {}
   })
 
 
   const onChangeInput = e => {
     setState({ ...state, archivedText: e.target.value })
   }
 
   //changing status
   const statusUpdateFunc = (status) => {
     
     setStatusData({
       ...statusData,
       status,
       data: statusUpdateData
     })
     if (status === 4 || status === 10 || status === 11) {
       setOpen(true);
 
     }
     else {
 
       onStatusUpdate(status, statusUpdateData)
      
       setSelectedData(status);
     }
   }
 
   //dialog close
   const handleClose = () => {
     setOpen(false);
     setState({ ...state, archivedText: '' })
   };
 
   const giveMeNotifyType = (newStatus) => {
     switch (secName) {
       case 0:
         return notifyTypeBesedOnEntryStatus.notify[newStatus]
 
       case 1:
         return notifyTypeBesedOnEntryStatus.verify[newStatus]
 
       case 2:
         return notifyTypeBesedOnEntryStatus.register[newStatus]
 
       case 3:
         return notifyTypeBesedOnEntryStatus.investigate[newStatus]
 
       default:
         return "";
     }
   }
 
   const onStatusUpdate = (status, data, message) => {
 
     let reason;
 
     if (status === 4) {
       reason = "reason_for_archived"
     } else if (status === 11) {
       reason = "reason_for_transfer"
     } else if (status === 10) {
       reason = "reason_for_reject"
     }
 
     let __params = {
       ...data,
       notifyType: giveMeNotifyType(status),
       secName,
       [statusPath]: status,
       [reason]: message,
       reason: message
     }
 
     Promise.resolve(dispatch(UPDATE_ENTRY_STATUS(__params))).then(res => {
       let resStatus = res.payload.error;
       alert.setSnack({
         ...alert,
         open: true,
         severity: resStatus ? alertProps.severity.error : alertProps.severity.success,
         msg: resStatus ? "Status update Unsuccessful !" : "Status update Successful !",
         vertical: alertProps.vertical.top,
         horizontal: alertProps.horizontal.center
       })
 
       // if (secName === 2 && (status === 11 || status === 8 || status === 10)) {
       //   history.push({
       //     pathname: Routes.case_registration,
       //     search: redirectPath
       //   });
       // }
 
     });
   }
 
 
 
 
   //clicking confirm button on dialog
   const confirmUpdateStatus = () => {
     
     if (state.archivedText.trim().length === 0) {
       state.error = {
         archivedText: state?.archivedText?.trim()?.length === 0 ? true : false,
       }
       setState({ ...state })
     }
     else {
       onStatusUpdate(statusData.status, statusData.data, state.archivedText)
       setSelectedData(statusData.status);
       handleClose()
 
       alert.setSnack({
         ...alert,
         open: true,
         severity: alertProps.severity.success,
         msg: "Status updated !",
         vertical: alertProps.vertical.top,
         horizontal: alertProps.horizontal.center
       })
 
     }
 
   }
 
   //clicking cancel button on dialog
   const cancelUpdateStatus = () => {
     state.error = {
       archivedText: false,
     }
     setState({ ...state })
     handleClose();
 
     alert.setSnack({
       ...alert,
       open: true,
       severity: alertProps.severity.success,
       msg: "Status update cancelled !",
       vertical: alertProps.vertical.top,
       horizontal: alertProps.horizontal.center
     })
   }
 
   const returnDisabled = () => {
     if (statusUpdateData?.verifier_status === 3 && secName === 0) {
       return true
     }
     else if (statusUpdateData?.registration_status === 8 && secName === 1) {
       return true
     }
     else if (statusUpdateData?.investigator_status === 6 && secName === 2) {
       return true
     }
     else {
       return false
     }
   }
 
   return <div className={classes.root}>
     <Grid
       container
       direction="row"
       // direction='column'
       // justify="center"
       alignItems="center"
     >
       <Grid item style={{ flex: 1 }}>
         <div className={classes.header}>
           {!hideBackBtn && <Grid item>
             <IconButton onClick={onBackBtnClicked} style={{ padding: drawerButton }}>
               <KeyboardBackspace htmlColor="rgba(17, 15, 71, 1)" />
             </IconButton>
           </Grid>}
 
           {!hideTitle && <Grid item>
             <Typography variant="body1" color="textPrimary">
               {title}
             </Typography>
 
           </Grid>}
 
           {/* {showPatientDetails && <Grid item className={classes.patientDetail}>
             <Typography variant="body1" color="textPrimary">
               {
                 getPatientDetails && getPatientDetails?.patient_name &&
                 <Typography variant="h6" color="textPrimary">
                   {`(${getPatientDetails?.patient_name?.title?.label ?? ""} ${getPatientDetails?.patient_name?.first_name ?? ""} ${getPatientDetails?.patient_name?.last_name?.[0] ?? ""})`}
                   <Tooltip title={"View Patient Details"}>
                     <IconButton
                       onClick={onPatientInfoBtnClicked}
                     >
                       <InfoOutlinedIcon />
                     </IconButton>
                   </Tooltip>
                 </Typography>
               }
               {
                 getPatientDetails && getPatientDetails?.icNumber &&
                 <Typography variant="caption" color="textPrimary" style={{ marginLeft: 10 }}>
                   {getPatientDetails?.icNumber ?? ''}
                 </Typography>
               }
               {
                 getPatientDetails && getPatientDetails?.passportNumber &&
                 <>
                   <Divider orientation="vertical" flexItem />
                   <Typography variant="caption" color="textPrimary">
                     {getPatientDetails?.passportNumber ?? ''}
                   </Typography>
                 </>
               }
             </Typography>
 
           </Grid>} */}
 
           {/* {!hideSubTitle && <Grid item className={classes.subTitle}>
                         <Typography variant="caption">
                             {subTitle}
                         </Typography>
                     </Grid>} */}
         </div>
         {drawerButton && <Button
           variant="text"
           className={classes.viewSection}
           onClick={drawerButtonAction}
           color={theme.palette.ternary.main}
         >
           <div style={{ marginRight: 5, display: 'flex' }}> <MenuIcon /> </div>View Sections
         </Button>
         }
 
       </Grid>
 
 
 
 
       {showToogleButton && <Grid item>
         <ToggleButtonComponent
           toggleButtonData={toggleButtonData}
           isSelected={selectedData}
           exclusive={exclusive}
           // onSelected={onSelected}
           onSelected={statusUpdateFunc}
           disabled={returnDisabled()}
           borderStyle
         />
       </Grid>}
 
       {<ArchivedDialog
         open={open}
         dialogTitle={statusData.status === 4 ? Message.archive_reason : statusData.status === 10 ? Message.reject_reason : Message.transfer_reason}
         dialogContent={
           <TextField
             autoFocus
             onChange={e => onChangeInput(e)}
             value={state.archivedText}
             fullWidth
             name="archivedText"
             label="Reason ?"
             margin="dense"
             error={state.error.archivedText}
             helperText={
               state.error.archivedText === true ? Message.requiredMessage : ''
             }
           />
         }
         negativeButton
         negativeButtonText="Cancel"
         negativeButtonAction={cancelUpdateStatus}
         positiveButton
         positiveButtonText={statusData.status === 4 ? "Confirm Archived" : statusData.status === 10 ? "Confirm Reject" : "Confirm Transfered"}
         positiveButtonAction={confirmUpdateStatus}
       />}
 
       {showDivider && <Divider className={classes.divider} orientation="vertical" flexItem />}
 
       {showInfoButton && <Grid item>
         <IconButton
           onClick={onActionIconBtnClicked}
         >
           <InfoOutlinedIcon />
         </IconButton>
       </Grid>}
     </Grid>
 
 
 
   </div>
 }
 