import React from 'react'

// Material-UI Component Imports, NOTE: withStyles is an HOC.
import { Grid, IconButton, Typography, withStyles } from '@material-ui/core'
//Custom Component Imports
import ActivityAndMembersCard from '../../../../components/availabilityActivityComponents/ActivityAndMembersCard';
import SummaryTextCard from '../../../../components/availabilityActivityComponents/activitySummaryCards/summaryTextCard';
// import ProfileCardActivitySummary from '../../../../components/availabilityActivityComponents/activitySummaryCards/ProfileCardActivitySummary';
// import CheckInCheckOutCard from '../../../../components/availabilityActivityComponents/activitySummaryCards/checkInCheckOutCard';

// Custom Utils
import { alertProps } from '../../../../utils';

// 3rd Party Libraries
import moment from 'moment'

// Icon Imports
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { ChevronLeft } from '@material-ui/icons'

// HOC imports
import withAlelrtAndDialog from '../../../../HOCs/withAlelrtAndDialog'
import { withRouter } from 'react-router-dom'

// Store Imports
import { connect } from 'react-redux'
import { actions } from 'tanyacare-middleware'
import { InternatlServerError, LoadingSection } from '../../../../components';

// import EventBusyIcon from '@material-ui/icons/EventBusy';




const style = (theme) => ({
  icon: {
    width: 20,
    height: 20
  },
  cancelButton: {
    boxShadow: "unset !important"
  },
  label: {
    alignItems: 'unset'
  }
})

class AvailabilitySummary extends React.Component {

  componentDidMount() {
    this.getAvailabilitySummary()
    this.getEssentials()
  }

  getEssentials = () => {
    this.props.GET_MASTER_LEAVE_TYPES({})
    this.props.GET_MASTER_UNAVAILABLE_TYPES({})
  }

  //ACTION CALL FUNCTION: Usually Executes on component mount
  getAvailabilitySummary = () => {
    const availabilityId = this.props?.availabilityData?.availability_id
    this.props.GET_AVAILABILITY({ availabilityId: availabilityId })
  }

  closeDrawer = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  }

  handleAvailabilityDelete(id) {
    // console.log(id)
    // const id = this.props.data[index]?.availability_id
    this.props.dialog.setDialog({
      open: true,
      title: "Confirmation",
      body: "Are you sure, you want to cancel this unavailability?",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => {
        this.props.dialog.setDialog({ open: false })
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: true,
          message: "Deleting Availability..."
        });
        this.props.DELETE_AVAILABILITY({ availabilityId: id })
      }
    })
  }

  shouldComponentUpdate(nextProps) {
    // Delete Availability
    const { deleteAvailability } = nextProps
    const oldDeleteAvailability = this.props.deleteAvailability

    if (oldDeleteAvailability?.loading !== deleteAvailability?.loading) {
      if (!deleteAvailability.loading && !deleteAvailability.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Unavailability Canceled Successfully!",
          severity: alertProps.severity.success
        })
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false
        })
        this.props.reload();
        this.closeDrawer();
      } else if (deleteAvailability.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to Delete Availability.",
          severity: alertProps.severity.error
        });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false
        });
      }
    }

    return true
  }

  render() {
    const { classes } = this.props

    // const {
    //   hideServiceProfessional,
    //   hideClient,
    // } = this.props;

    const { data, loading, error } = this.props?.unavailabilitySummary
    const { 
      data: leaveTypeOptions 
      // loading:leaveTypeLoading 
    } = this.props?.leaveTypes 
    const {
      data: unavailable_types_options
    } = this.props.unavailable_types


    const getLeaveType = () => {
      let res = leaveTypeOptions?.filter(x => x.value === data?.leaveType)?.[0]?.label;
      if(res === undefined)
      {
        return  unavailable_types_options?.filter(x => x.value === data?.leaveType)?.[0]?.label
      }
      return res
    }
    return (
      <>
        {/* If data is loading. */}
        { loading && <LoadingSection message="Loading unavailability summary..." />}

        {/* If any error occured. */}
        { !loading && error && <InternatlServerError message="Something went wrong, Try Again!" onRetry={this.getAvailabilitySummary} />}

        {/* If there is no data. */}
        { !loading && !error && !data && <InternatlServerError message="Something went wrong, Try Again!" onRetry={this.getAvailabilitySummary} />}

        {/* If data received. */}
        {
          !loading && !error && data &&
          <>
            <Grid container>
              <Grid item container style={{ padding: '12px 12px 12px 0px', background: 'white' }}>
                <Grid item>
                  <IconButton onClick={this.props.onBackBtnClicked}>
                    <ChevronLeft />
                  </IconButton>
                </Grid>
                <Grid item xs>
                  <Typography variant="h6" style={{ marginTop: 8 }}>{data?.service_professional?.label ? data?.service_professional?.label : " "}</Typography>
                </Grid>
                <Grid item style={{ marginLeft: 20, display: 'flex', alignItems: 'center' }}>
                  <IconButton onClick={() => this.props.onEditBtnClicked(this.props.availabilityData)}>
                    <CreateOutlinedIcon htmlColor="#9297a5" classes={{ root: classes.icon }} />
                  </IconButton>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <IconButton onClick={() => this.handleAvailabilityDelete(data?.availability_id)}>
                    <DeleteOutlineIcon htmlColor="#f27d35" classes={{ root: classes.icon }} />
                  </IconButton>
                  {/* <Button
                    onClick={() => this.handleAvailabilityDelete(data?.availability_id)}
                    style={{ color: "#f27d35" }}
                    className={classes.cancelButton}
                    classes={{root: classes.root, label: classes.label}}
                  >
                    Cancel
                  </Button> */}
                </Grid>
              </Grid>

              <ActivityAndMembersCard
                hideProfilePicture
                // title={data?.activity_name}
                // date={moment(data?.from_time_stamp).format('dddd, MMMM D')}
                timing={`${moment(data?.from).format('ddd, MMM D [•] hh:mm A')} - ${moment(data?.to).format('ddd, MMM D [•] hh:mm A')}`}
                hideIsActive
                // activityStatus={data?.activity_type?.label} //activity_type will be a object with {value, label}
                // activityStatusColor="#28a9c1"
                // currentStatus={data?.activity_status}
                // currentStatusColor={colorCode[data?.activity_status]}
                // useActivityAsSummaryCard
                hideEndIcon
                padding={2}
                
              />

              <div style={{ display: "block", padding: 16 }}>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                  >
                    LEAVE TYPE:
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    {getLeaveType()}
                    {/* {leaveTypeOptions?.filter(x => x.value === data?.leaveType)?.[0]?.label ? unavailable_types_options?.filter(x => x.value === data?.leaveType)?.[0]?.label : "N/A"} */}
                  </Typography>
                </div>
              
              <SummaryTextCard description={data?.description} padding={2} hideBorders={true} />





              {/* Commented Temporarily */}
              {/* {!hideClient && <ProfileCardActivitySummary
                profilePicture={data?.client_info?.profile_pic}
                name={data?.client_info?.client_name}
                gender={data?.client_info?.gender}
                age={data?.client_info?.age}
                address={data?.client_info?.address}
                padding={2}
                hideQuickIcons
                hideAddressSection
              />}

              {!hideServiceProfessional && <div style={{ display: "block", padding: 16 }}>
                <Typography variant="caption" color="textSecondary" gutterBottom>
                  SERVICE PROFESSIONAL:
                        </Typography>
                <Typography variant="body1" color="textPrimary">
                  {data?.service_professional?.label}
                </Typography>
              </div>}

              <Grid item xs={12}>
                <CheckInCheckOutCard
                  leftText="Check - In"
                  leftSubText={data?.check_in ? moment(data?.check_in).format('hh:mm A') : " NA "}
                  rightText="Check - Out"
                  rightSubText={data?.check_out ? moment(data?.check_out).format('hh:mm A') : " NA "}
                  padding={2}
                />
              </Grid> */}
            </Grid>
          </>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  // Take the needed data from store and assign to a prop. Since we use summary now, we additionally need the delete availability API call and data. Prepare for that.
  unavailabilitySummary: state?.availability_app?.get_availability,
  deleteAvailability: state?.availability_app?.delete_availability ?? { data: [], error: false, loading: false },
  leaveTypes: state?.masterSet?.leave_types,
  unavailable_types: state?.masterSet?.unavailable_types,
})

export default connect(mapStateToProps, actions)(withAlelrtAndDialog(withRouter(withStyles(style)(AvailabilitySummary))))