import * as React from "react"

export function UploadToCloudIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={69.3}
      height={46.2}
      viewBox="0 0 69.3 46.2"
      {...props}
    >
      <path
        d="M34.65 0c-6.741 0-12.415 3.608-16.084 8.7a18.75 18.75 0 00.2 37.5h34.65a15.854 15.854 0 00.288-31.706A20.007 20.007 0 0034.65 0zm0 5.775a14.4 14.4 0 0114.24 12.17l.44 2.775 2.786-.361c.645-.084 1.057-.147 1.3-.147a10.107 10.107 0 010 20.213H37.538v-11.55H46.2l-11.55-11.55-11.55 11.55h8.663v11.55H18.769a12.994 12.994 0 010-25.988c.1 0 .336.02.722.039l1.765.09.88-1.534A14.4 14.4 0 0134.65 5.775z"
        fill="#9297a5"
        opacity={0.3}
      />
    </svg>
  )
}
