import React from "react";
import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

export const QuickSummaryIcon = (props) => {
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
      stroke={props.htmlColor}
    >
      <path
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M19,20c0,0.551-0.449,1-1,1H6c-0.551,0-1-0.449-1-1V4c0-0.551,0.449-1,1-1h7.586L19,8.414V20z"
      />
      <path d="M18.5 9L13 9 13 3.5z" />
      <path
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M16 12L12 12"
      />
      <path d="M8 11H10V13H8z" />
      <path
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M16 16L12 16"
      />
      <path d="M8 15H10V17H8z" />
    </SvgIcon>
  );
};

QuickSummaryIcon.propTypes = {
  htmlColor: PropTypes.string,
};
