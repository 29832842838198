import React from "react";
import { Grid, makeStyles, Typography, Divider, useTheme } from "@material-ui/core";
import { textClampStyleGenerator } from "../../utils";
// import { Skeleton } from "@material-ui/lab";
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    // root: {
    //     gap: 20
    // },
    itemTitle: {
        fontSize: "1.1rem",
        ...textClampStyleGenerator(),
    },
    caption: {
        color: theme.palette.success.main
    },
    topLine: {
        gap: 20,
        marginTop: 12
    },
    bottomLine: {
        gap: 20,
    },
    visibilityOff: {
        visibility: "hidden"
    }
}));

const getActivationText = ({ resourceEffectiveFrom={}, resourceEndsOn={} }) => {
    if(moment(resourceEffectiveFrom)?.isValid && moment(resourceEndsOn)?.isValid){
        if(moment().isBefore(resourceEffectiveFrom)){
            return {
                isActive: false,
                date: moment(resourceEffectiveFrom).format("DD/MM/YYYY") ?? "N/A",
                text: `Activating in ${Math.abs(moment().diff(moment(resourceEffectiveFrom), 'days')) + 1} days`,
            }
        } else if(moment().isSameOrAfter(resourceEffectiveFrom) && moment().isBefore(resourceEndsOn)){
            return {
                isActive: true,
                date: moment(resourceEndsOn).format("DD/MM/YYYY") ?? "N/A",
                text: `Deactivating in ${Math.abs(moment().diff(moment(resourceEndsOn), 'days')) + 1} days`
            }
        }
    } else return {
        isActive: true,
        text: "N/A"
    }
}

export function InfoMeta({ details, loading = false, createdAt, activation, ...props }) {
    const classes = useStyles(props);
    const theme = useTheme()

    

    return (
        <Grid
            item
            xs={12}
            container
            //   direction="column"
            // justify="center"
            alignItems="center"
        >
            <Grid item container xs={12} wrap="nowrap" className={classes.topLine}>
                <Divider orientation={"vertical"} light flexItem></Divider>
                <Grid item>
                    <MetaCard createdAt={createdAt} />
                </Grid>
                <Divider orientation={"vertical"} light flexItem></Divider>
                <Grid item>
                    <ActivationCard activation={activation} />
                </Grid>
            </Grid>
            <Grid item container xs={12} wrap="nowrap" className={classes.bottomLine} >
                <Divider orientation={"vertical"} light flexItem className={classes.visibilityOff}></Divider>
                <Grid item>
                    <Typography noWrap className={classes.visibilityOff} style={{fontSize: !createdAt && "0.75rem"}} >{(createdAt && moment(createdAt)?.isValid) ? moment(createdAt).format("DD/MM/YYYY") : "CREATED ON"}</Typography>
                </Grid>
                <Divider orientation={"vertical"} light flexItem className={classes.visibilityOff}></Divider>
                <Grid item>
                {activation?.resourceEffectiveFrom && activation?.resourceEndsOn && <Typography noWrap variant={"caption"} className={classes.caption} style={{color: getActivationText(activation??{})?.isActive ? theme.palette.error.main : theme.palette.success.main}}>{getActivationText(activation)?.text}</Typography>}
                {!activation?.resourceEffectiveFrom && !activation?.resourceEndsOn && <Typography noWrap variant={"caption"} className={classes.caption} style={{color: theme.palette.success.main}}>{"N/A"}</Typography>}
                </Grid>
            </Grid>
        </Grid>
    );
}

const metaCardStyles = makeStyles((theme) => ({
    title: {
        fontSize: "0.75rem",
    },
}))

function MetaCard({createdAt}) {
    const classes = metaCardStyles()
    return (
        <>
            <Typography noWrap variant={"body2"} color={"textSecondary"} className={classes.title}>CREATED ON</Typography>
            <Typography noWrap >{(createdAt && moment(createdAt)?.isValid) ? moment(createdAt).format("DD/MM/YYYY") : "N/A"}</Typography>
        </>
    )
}

const activationCardStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center"
    },
    title: {
        fontSize: "0.75rem"
    },
    subTitle: {},
    caption: {
        color: theme.palette.success.main
    },
    // button: {
    //     boxShadow: "none",
    //     color: theme.palette.ternary.main,
    //     textTransform: "unset"
    // }
}))

function ActivationCard({activation}) {
    const classes = activationCardStyles()
    return (
        <div className={classes.root}>
            <div>
                <Typography noWrap variant={"body2"} color={"textSecondary"} className={classes.title}>ACTIVATION</Typography>
                <Typography noWrap>{activation?.resourceEffectiveFrom && activation?.resourceEndsOn ? getActivationText(activation)?.date : "N/A"}</Typography>
            </div>
            {/* <div>
                <Button size={"small"} className={classes.button} >Change</Button>
            </div> */}
        </div>
    )
}
