import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function IdCardIconV2(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.996 5a.422.422 0 00-.3.126.431.431 0 00-.124.303v1.853H5.12a2.11 2.11 0 00-1.498.628A2.157 2.157 0 003 9.425v8.433c0 .569.225 1.113.622 1.515a2.11 2.11 0 001.5.627H18.88a2.11 2.11 0 001.498-.628c.398-.402.622-.947.622-1.515V9.424c0-.568-.224-1.113-.622-1.515a2.11 2.11 0 00-1.498-.628h-4.452V5.428a.432.432 0 00-.125-.303.422.422 0 00-.3-.125H9.997zm.425.857h3.159V7.71c-.01.417-.18.813-.476 1.105a1.571 1.571 0 01-2.208 0 1.605 1.605 0 01-.476-1.105V5.857zm-5.3 2.28h4.49c.099.568.393 1.082.83 1.453a2.411 2.411 0 003.118 0c.437-.37.731-.885.83-1.452h4.49c.337 0 .66.136.899.377.238.24.372.567.373.908v8.434c0 .34-.135.667-.373.908a1.267 1.267 0 01-.9.377H5.122c-.337 0-.66-.136-.899-.377a1.294 1.294 0 01-.373-.908V9.424c0-.34.135-.667.373-.908.239-.241.562-.377.9-.377v-.001zm.814 2.849a.422.422 0 00-.3.125.431.431 0 00-.125.304v5.663a.433.433 0 00.262.396.42.42 0 00.163.032h4.713a.42.42 0 00.392-.264.433.433 0 00.033-.164v-5.663a.43.43 0 00-.125-.303.422.422 0 00-.3-.126H5.935zm.424.857h3.863v4.804H6.36v-4.804zm6.995.684a.42.42 0 00-.409.26.433.433 0 00.241.57c.054.02.111.03.168.027h2.367a.421.421 0 00.409-.26.432.432 0 00-.241-.57.42.42 0 00-.168-.027h-2.367zm0 2.736a.422.422 0 00-.287.132.431.431 0 00.287.724h4.486a.422.422 0 00.288-.132.431.431 0 00-.288-.724h-4.486z"
        fill="#0063E7"
        stroke="#0063E7"
        strokeWidth={0.5}
      />
    </SvgIcon>
  );
}

export default IdCardIconV2;
