import * as React from "react"
import { makeStyles, SvgIcon } from "@material-ui/core"
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  cls1: (props) => ({
    fill: props.color || '#000000'
  })
})

export function StarFilledRating(props) {
  const classes = useStyles(props)
  return (
    <SvgIcon width={20} height={20} viewBox="0 0 25.044 24" {...props}>
      <path class={classes.cls1} d="M 12 1.5 L 8.5878906 8.4121094 L 0.9609375 9.5214844 L 6.4804688 14.902344 L 5.1777344 22.5 L 12 18.912109 L 18.822266 22.5 L 17.519531 14.902344 L 23.039062 9.5214844 L 15.412109 8.4121094 L 12 1.5 z" />
    </SvgIcon>
  )
}

StarFilledRating.propTypes = {
  color: PropTypes.string
}