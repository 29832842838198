import {maxMobileNumberLimit} from './validations'

export const errorMessage = {
  requiredMessage: "This field is required",
  newRequiredMessage: "This field is mandatory if “Is Bookable“ field is turned on",
  emailMessage: "Please enter a valid email address",
  mobileNoMessage: `Please enter atleast ${maxMobileNumberLimit} digits`,
  mobileNoValidMessage: `Please enter a valid mobile number`,
  mobileNoLimitMessage: (limit) => `Please enter atleast ${limit} digits`,
  mobileNoExceedsMessage: (limit) => `Please enter not more than ${limit} digits`,
  mobileFixedLimitMessage: (limits) => {
      return `Please enter either ${limits[0]} digits or ${limits[1]} digits`
  },
  promptMessage: "Do you want to leave this page? Changes you made may not be saved.",
  notFoundFilter: "No result found for the applied filter, Try another",
  notFoundSearch: "No result found for your search, Try another",
  notFoundFilterSearch: "No result found for your search and filter, Try another",
  icNumberPassportNeeded: "I/C Number or Passport Number is required",
  icNumberPassport:(x=1) => {
    if(x === 1)
    {
      return "I/C Number or Passport Number is required"
    }
    else
    {
      return "I/C Number or Passport Number should be 12 digit"
    }
  } ,
  futureDateMessage:"Please do not enter future dates",
  pastDateMessage:"Please do not enter past dates",
  pastFutureDateMessage:'Please enter current date',
  validDate:"Date is not valid",
  validateyears:"Enter Valid Date",
  locationInvalid: "Please select a correct location",
  timeDifferenceError: "Please choose timeslot atleast 30mins",
  minTime: "Date should not be before from date ",
  alphaNumeric: "This field should be alpha-numeric",
  validUrl: "Please enter a valid URL",
  postalCode: "Postal code should not exceed 10 digits",
  mobileRequired: "Mobile Number is required",
  isdCodeRequired: "ISD Code is required",
  passwordMismatch: "Passwords do not match",
  strongerPassword: "Please choose a stronger password.",
  atleastOne: (item) => `Please add atleast one ${item}`,
  hourRequired: "Please select a hour",
  minuteRequired: "Please select a minute",
  dateIsBeforeError: (start,end) => `${end} date should be after ${start} date`
};
