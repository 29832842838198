import * as React from "react"

export function Person(props) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" {...props}>
      <path
        fill="#0063E7"
        d="M12.189 11.392a3.966 3.966 0 01-6.378 0C2.994 12.016 0 13.385 0 15.5V18h18v-2.5c0-2.115-2.994-3.484-5.811-4.108zM16 16H2v-.5c0-.313.932-1.213 3.183-1.882a5.962 5.962 0 007.634 0C15.068 14.287 16 15.187 16 15.5zM9 0a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"
      />
    </svg>
  )
}

