import React, { Component } from "react";
import { AddEditPB } from './addEditPB';
import { withRouter } from "react-router-dom";

class AddEditPromotionalBanner extends Component {
    render() {
        const { isEdit,match } = this.props;
        return (
            <AddEditPB 
                isEdit={isEdit}
                id={match.params.id}
                // isReadOnly={true}
                {...this.props}
            />
        )
    }
}

export default withRouter(AddEditPromotionalBanner)