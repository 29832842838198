import React from 'react';
import { makeStyles, Avatar, Typography, } from '@material-ui/core';
// import Icon from "../../assets/product Management/listingScreen/premium.svg";


const useStyle = makeStyles((theme) => ({
    avatar: {
        height: 60,
        width: 60,
        padding: 16,
        backgroundColor: theme.palette.secondary.light
    },
    textArea: {
        marginLeft: 16,
        marginTop:17
    },
    textAreaHeader: {
        display: 'flex'
    },
    
    activecircle: {
        marginLeft: 10,
        marginTop: 6,
        width: 9,
        height: 9,
        background: theme.palette.success.main,
        borderRadius: "50%"
    },
   
    active_name: {
        display: "inline",
        paddingLeft: "6px",
        fontSize: "14px",
        color: theme.palette.success.main
    },
}))

const Avatarhead = (props) => {

    const classes = useStyle();
    return <>
        <Avatar src={"/images/tanyaCare/Tanya.png"} className={classes.avatar} />
        <div className={classes.textArea}>
            <div className={classes.textAreaHeader}>
                <Typography variant="body1"  >{props.report_name}</Typography> 
            </div>
        </div>
    </>
}
export default Avatarhead;