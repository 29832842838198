import React from "react";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import withAlelrtAndDialog from "../../../HOCs/withAlelrtAndDialog";
import { withRouter } from "react-router-dom";
import {
  FormControl,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  withStyles,
} from "@material-ui/core";
import {
  alertProps,
  genericStyles,
  productSortDirectionOption,
  TaxMaster,
} from "../../../utils";
// import AdminTable from "../../../components/adminTable";

// import {
//   // actionsCall,
//   // addMasterCall,
//   // editMasterCall,
//   // stateCall,
//   // updateStateCall,
//   // tableColumn,
//   // addStateCall,
// } from "./utils";
// import {
//   // careProviderDetailsUrl,
//   filterTagType,
//   subHeaderSortOptionWithValue,
// } from "../../../utils";
import TaxTable from "../../../components/adminTable/taxmanagement";
import SubHeader from "../../../components/subHeader";
// import FilterTags from "../../../components/filters/filterTags";
import {
  // FilterBuilder,
  LoadingSection,
  NotFoundSection,
  // ListCard
} from "../../../components";
import TaxDrawer from "./taxDrawer";
// import { filterComponent } from "./filterComponent";

const styles = (theme) => ({
  root: {
    padding: 24,
  },
  list: {
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    backgroundColor: "white",
    borderRadius: 8,
    height: "calc(100vh - 108px)",
    overflow: "scroll",
  },
  masterSelect: {
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    backgroundColor: "white",
    borderRadius: 8,
    padding: "12px 30px 12px 12px",
    // height: "calc(100vh - 108px)",
    // overflow: "scroll"
  },
  table: {
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    backgroundColor: "white",
    borderRadius: 8,
    height: "calc(100vh - 108px)",
    overflow: "scroll",
  },
  menuList: {
    padding: 8,
    "& .Mui-selected": {
      borderRadius: 16,
      backgroundColor: theme.palette.primary.main,
      color: `${theme.palette.common.white} !important`,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  menuListItem: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 16,
    padding: "4px 16px",
  },
  // typography: {
  //   "&:hover": {
  //     color: `${theme.palette.ternary.main} !important`
  //   }
  // },
  // typoSelected: {
  //   color: `${theme.palette.ternary.main} !important`
  // },
  formControl: {
    margin: "0px 8px",
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.ternary.main} !important`,
        borderWidth: "1.6px !important",
      },
    },
  },
  customSelect: {
    padding: "10.500px 14px",
  },
  label: {
    color: theme.palette.text.label,
    textTransform: "uppercase",
  },
  ...genericStyles.cardWrapperShadows(theme),
});

const initialState = {
  pageNo: 0,
  pageSize: 25,
  careProviderName: "",
  category: [],
  provider: [],
  status: undefined,
  products: [],
  date: null,
  isFilterApplied: false,
  // sortDirec: subHeaderSortOptionWithValue[1],
  sortDirec: productSortDirectionOption[1].value,
  searchValue: "",
  role: [],
  users: [],
  filterRoles: [],
  ind_sort: "desc",
  page: 1,
  rowsPerPage: 8,
  limit: 8,
  sortField: "createdAt",
  selected: 1,
  sortKey: 1,
};

class Tax extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      id: "AdminstrationTaxManagement",
    };
  }

  componentDidMount(){
    this.getEssentials()
  }

  getEssentials = () => {
    // console.log(this.state.sortKey)
    this.props.GET_TAX_SETUP({searchKey: this.state.searchValue, sortKey: this.state.sortKey})
    // console.log(this.props?.getTax)
  }

  showSnack = (status, message) => {
    this.props.alert.setSnack({
      open: true,
      horizontal: alertProps.horizontal.center,
      vertical: alertProps.vertical.top,
      msg: message,
      severity: status ? alertProps.severity.success : alertProps.severity.error ,
    });
  }

  onAddDrawer = () => {
    console.log("HAI")
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <TaxDrawer onCloseDrawer={this.onCloseDrawer}/>
      ),
    });
  };

  onResendClick = (id) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <TaxDrawer tax_id={id} isEdit={true} onCloseDrawer={this.onCloseDrawer}/>
      ),
    });
  }

  onRearrange = (id) => {
    console.log(id);
    // let dir = this.state.ind_sort
    this.setState({sortField: id})
    this.setState({ sortKey: this.state.ind_sort === "asc" ? 1 : 2})
    this.setState({ ind_sort: this.state.ind_sort === "asc" ? "desc" : "asc" })
    this.props.GET_TAX_SETUP({searchKey: this.state.searchValue, sortKey: this.state.sortKey, sortField: id})
    // this.setState({ ind_sort: this.state.ind_sort === "asc" ? "desc" : "asc" } , () => { this.handleSort(id)});
    
  };

  handleSort(id) {

  }

  onCloseDrawer = (status, message) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
    });
    this.showSnack(status, message)
    this.getEssentials()
  }

  storeInputSearchValue = (value) => {
    this.setState(
      { searchValue: value},
      () => {
        // if (value.length % 3 === 0) {
        this.getEssentials();
        // }
      }
    );
  };
  getUserList = () => {
    console.log("INSIDE")
    this.setState({users: this.props.getTax?.data})
  };

  getPlaceholder = (isSingularName) => {
    const objectKey = isSingularName ? "singularVersion" : "tableColumnName";
    const currentMenu = TaxMaster.filter(
      (_) => _.id === this.state.selected
    )[0];
    if (!("additionalFields" in currentMenu)) {
      return [currentMenu?.[objectKey]];
    } else {
      let placeholders = [currentMenu?.[objectKey]];
      let additionalPlaceholders = currentMenu?.additionalFields?.map(
        (x) => x?.[objectKey]
      );
      return [...placeholders, ...additionalPlaceholders];
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            {/* List component here */}
            <Hidden xsDown>
              <div className={`${classes.list} ${classes.cardWrapperShadows}`}>
                {/* <Typography
                  variant="h6"
                  style={{ padding: 20 }}
                  color="textPrimary"
                >
                  Master Data
                </Typography>
                <Divider variant="fullWidth" /> */}
                <List component="nav" className={classes.menuList}>
                  {TaxMaster.map((md, key) => {
                    return (
                      <ListItem
                        className={classes.menuListItem}
                        key={key}
                        button
                        selected={this.state.selected === md.id}
                        onClick={(event) => this.onMenuItemSelected(md.id, md)}
                        id={`${md?.label}`}
                      >
                        <ListItemText
                          primary={md.label}
                          disableTypography
                          className={`${classes.typography} ${
                            this.state.selected === md.id
                              ? classes.typoSelected
                              : ""
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </Hidden>

            <Hidden smUp>
              <div
                className={`${classes.masterSelect} ${classes.cardWrapperShadows}`}
              >
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ marginLeft: 8, width: "100%" }}
                >
                  {/* <InputLabel
                    shrink
                    htmlFor="masterData"
                    className={classes.label}
                  >
                    Master Data
                  </InputLabel> */}
                  <Select
                    value={this.state.selected}
                    // onChange={(e) =>
                    //   ("sortKey", e.target.value)
                    // }
                    onChange={(e) =>
                      this.onMenuItemSelected(
                        e.target.value,
                        TaxMaster.filter((x) => x.id === e.target.value)[0]
                      )
                    }
                    style={{
                      width: "100%",
                    }}
                    name="masterData"
                    classes={{ outlined: classes.customSelect }}
                  >
                    {TaxMaster?.map((item, index) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </Hidden>
          </Grid>

          <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
            {/* Table component here */}
            <div className={`${classes.table} ${classes.cardWrapperShadows}`}>
              <div className={classes.root}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <div style={{ width: "100%" }}>
                    <SubHeader
                      isbutton={false}
                      isDrawerButton = {true}
                      onDrawerButtonClicked = {this.onAddDrawer}
                      placeholder={"Search Tax"}
                      // , Email and Mobile Number
                      entityName={"Search"}
                      countText={`Tax Setup`}
                      button_name={"Search"}
                      link={""}
                      //   (this.props.match.path)
                      handleSearch={this.getUserList}
                      handleChangeInputSearch={this.storeInputSearchValue}
                      value={this.state.searchValue}
                      useNewFilterIcon
                      placeSearchIconToLeft
                      isFilterApplied={this.state.isFilterApplied}
                      onFilterIconClicked={this.onFilterIconClicked}
                      showSortOption={true}
                      sort={""}
                      // handleSortChange={this.handleSort("name")}
                      handleSortChange={() => console.log("DATA")}
                      loading={false}
                      id={this.state.id}
                      // Temporarily hidden
                      hideFilter
                      showSort={false}
                      onSortChange={(key,data) => this.setState({sortKey: data}, () => this.getEssentials())}
                      // onSortChange={(key,data) => console.log(data)}
                      // hideSearch
                    />

                    <div style={{ paddingTop: "1%" }}>
                      {this.props.getTax?.loading && <LoadingSection message="Fetch Tax Data..."/>}
                      {!this.props.getTax?.loading && this.props.getTax?.data !== [] ? (
                        <TaxTable
                          users={this.props.getTax?.data || []}
                          page={this.state.page}
                          total={10}
                          rowsPerPage={this.state.rowsPerPage}
                          ind_sort={this.state.ind_sort}
                          sortDirec={this.state.ind_sort}
                          sortField={this.state.sortField}
                          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                          handleChangePage={this.handleChangePage}
                          onResendClick={this.onResendClick}
                          onRearrange={this.onRearrange}
                          // onRearrange={this.onRearrange}
                          loadNewData={this.loadNewData}
                        />
                      ) : (
                        <NotFoundSection />
                      )}
                    </div>
                  </div>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  masterUsers: state?.masterUser?.master_user,
  getTax: state?.masterTax?.get_tax_setup,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(Tax))));
