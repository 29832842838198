import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { RecommendEquipmentLoading } from './loading';

export const RecommendedEquipmentLoaders = (props) => {


  const {
    count
  } = props;


  return <>
    {
      Array.apply(null, Array(count)).map((value, index) => <Grid key={index} item xs={12} sm={6} md={3} lg={3} xl={3}>
        <RecommendEquipmentLoading />
      </Grid>)
    }
  </>
}

RecommendedEquipmentLoaders.propTypes = {
  count: PropTypes.number
}

RecommendedEquipmentLoaders.defaultProps = {
  count: 1
}