import React from "react";
import { Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import { InfoUnderline } from "./infoUnderline";
import { textClampStyleGenerator } from "../../utils";
import { Skeleton } from "@material-ui/lab";
// import { styled } from "@material-ui/core/styles";
import { CustomTypography } from "../niceComponents";
import { getTrialText } from "../../utils/common";

const statusStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
  },
  dot: {
    height: "8px",
    width: "8px",
    borderRadius: "50%",
    display: "inline-block",
  },
  activeDot: {
    backgroundColor: theme.palette.success.main,
  },
  inactiveDot: {
    backgroundColor: theme.palette.error.main,
  },
  invitedDot: {
    backgroundColor: theme.palette.warning.main,
  },
  active: {
    color: theme.palette.success.main,
  },
  inactive: {
    color: theme.palette.error.main,
  },
  invited: {
    color: theme.palette.warning.light,
  },
  status: {
    lineHeight: 1.5,
  },
}));

export function ItemStatus({ isactive, emailVerified, loading = false }) {
  const classes = statusStyles();
  const theme = useTheme();

  // Handlers
  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return theme.palette.success.main;
      case 2:
        return theme.palette.error.main;
      case 3:
        return theme.palette.warning.light;
      default: {
        throw new Error(`Unexpected status: ${status}`);
      }
    }
  };

  // Component Integration
  const activeStatus = emailVerified === false ? 3 : isactive ? 1 : 2;
  const statusText = ["ACTIVE", "INACTIVE", "INVITED"];

  return (
    <>
      {loading && <></>}
      {!loading && (
        <div className={classes.root}>
          <span
            className={classes.dot}
            style={{ backgroundColor: getStatusColor(activeStatus) }}
          ></span>
          <Typography
            variant="body2"
            className={classes.status}
            style={{ color: getStatusColor(activeStatus) }}
          >
            {statusText[activeStatus - 1]}
          </Typography>
        </div>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  overline: {
    display: "flex",
    // alignItems: "center",
    gap: 8,
  },
  itemTitle: {
    fontSize: "1.1rem",
    ...textClampStyleGenerator(),
  },
  underline: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    color: theme.palette.text.secondary,
  },
  aliasIdLine: {
    // display: "flex",
    // alignItems: "center",
    // gap: 8,
    marginTop: 4,
    color: theme.palette.text.secondary,
  },
}));

export function InfoDetail({
  details,
  loading = false,
  isCategoryAChip,
  showTrialExpiry,
  ...props
}) {
  const classes = useStyles(props);
  let cl_name = details?.name?.split(".");
  // alert(cl_name?.[0])
  return (
    <Grid
      item
      xs={12}
      container
      direction="column"
      justify="center"
      wrap="nowrap"
    >
      <Grid item xs={12} className={classes.overline}>
        {loading && (
          <Skeleton variant="text" className={classes.skeleton} width={120} />
        )}
        {!loading && (
          <CustomTypography
            variant="row1"
            weight="bold"
            styles={{ fontSize: "1.1rem" }}
          >
            {details?.salutation
              ? `${details?.salutation}. ${details?.name}`
              : cl_name?.[0] === "other"
              ? cl_name?.[1]
              : details?.name}
          </CustomTypography>
        )}
        <ItemStatus
          isactive={details?.isactive}
          emailVerified={details?.emailVerified}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} className={classes.underline}>
        <InfoUnderline
          type={details?.role}
          gender={details?.gender}
          age={details?.dob}
          category={Array.isArray(details?.category) ? details?.category : []}
          isCategoryAChip={isCategoryAChip}
          loading={loading}
        />
      </Grid>

      {details?.aliasId && (
        <Grid item xs={12} className={classes.aliasIdLine} zeroMinWidth>
          {/* Grid item to render ALIASID of the card */}
          <AliasIdRenderer
            aliasId={details?.aliasId}
            label={details?.aliasLabel}
            loading={loading}
          />
        </Grid>
      )}
      {showTrialExpiry && details?.trialExpiry && (
        <Grid item xs={12} className={classes.aliasIdLine} zeroMinWidth>
          {/* Grid item to render TrialExpiry of the card */}
          <ItemExpiryRenderer
            trialExpiry={details?.trialExpiry}
            loading={loading}
          />
        </Grid>
      )}
    </Grid>
  );
}

// const aliasIdStyles = makeStyles((theme) => ({
//   root: {
//     ...textClampStyleGenerator(),
//   },
// }));

function AliasIdRenderer({ aliasId = "N/A", label, loading }) {
  // const classes = aliasIdStyles();
  return (
    <>
      {loading && <Skeleton variant="text" width={40} />}
      {!loading && (
        <CustomTypography variant="row3" styles={{ fontStyle: "italic" }}>
          {`${label ? label + " : " : ""}${aliasId}`}
        </CustomTypography>
      )}
    </>
  );
}

const itemExpiryStyles = makeStyles((theme) => ({
  root: {
    ...textClampStyleGenerator(),
    color: "#F03E50",
    // fontStyle: "italic",
  },
}));

function ItemExpiryRenderer({ trialExpiry, loading }) {
  const classes = itemExpiryStyles();

  return (
    <>
      {loading && (
        <Skeleton variant="text" className={classes.skeleton} width={120} />
      )}
      {!loading && (
        <CustomTypography
          variant="row3"
          styles={{
            fontStyle: "italic",
            color: "#F03E50",
            ...textClampStyleGenerator(),
          }}
        >
          {getTrialText(trialExpiry)}
        </CustomTypography>
      )}
    </>
  );
}
