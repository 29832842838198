import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function DeleteIconV2(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.65 5a2.458 2.458 0 00-2.422 2.1H6.833a.472.472 0 00-.168 0h-1.14a.525.525 0 100 1.05h.749l.881 9.11A1.932 1.932 0 009.071 19h5.158a1.932 1.932 0 001.916-1.74l.882-9.11h.749a.525.525 0 100-1.05h-1.14a.525.525 0 00-.168 0h-2.4A2.459 2.459 0 0011.65 5zm0 1.05A1.387 1.387 0 0113 7.1h-2.7a1.387 1.387 0 011.35-1.05zm-4.322 2.1h8.644l-.872 9.009a.868.868 0 01-.871.791H9.071a.869.869 0 01-.871-.791L7.328 8.15zm3.089 1.742a.525.525 0 00-.517.533v5.25a.525.525 0 101.05 0v-5.25a.524.524 0 00-.533-.533zm2.45 0a.525.525 0 00-.517.533v5.25a.525.525 0 101.05 0v-5.25a.524.524 0 00-.533-.533z"
        fill="#FF4D4A"
      />
    </SvgIcon>
  );
}

export default DeleteIconV2;
