import React,{useEffect, useRef} from 'react';
import Dialog from "@material-ui/core/Dialog";
import PropTypes from 'prop-types';

export const CustomDialog = (props) =>{

    const ref = useRef(null);

    const {
        open=false,
        onClose=null,
        children="",
        disableEnforceFocus=false,
        onCloseCallBack=null,
        className={
            Dialog:''
        }
    } = props;

    const returnTarget = (node) =>{
        return node?.offsetParent?.getAttribute('check-id') 
                ? node.offsetParent.getAttribute('check-id')
                : node?.offsetParent?.offsetParent?.getAttribute('check-id')
    }

    const handleClick = (e) =>{
        if(ref?.current?.contains(e.target) || returnTarget(e.target) === "closeDialogDesc"){
            return
        }
        else{
            onCloseCallBack && onCloseCallBack()
        }
    }

    useEffect(()=>{
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
        // eslint-disable-next-line
    },[])

    return(
        <>
        {open &&
            <Dialog
            open={open}
            onClose={!onCloseCallBack && onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                root:className?.Dialog
            }}
            disableEnforceFocus={disableEnforceFocus}
            >   
            <div ref={ref}>
                {children}
            </div>
            </Dialog>
        }
        </>
    )
}

CustomDialog.propTypes={
    open:PropTypes.bool,
    onClose:PropTypes.func,
    children:PropTypes.node,
    disableEnforceFocus:PropTypes.bool,
    onCloseCallBack:PropTypes.func,
    className:PropTypes.object
}