import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const Container = styled.div`
  padding: 16px;
`;

const ProfessionalInfo = styled.div`
  display: flex;
  gap: 16px;
  margin: 16px 0px;
`;

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 16,
  },
  professionalName: {
    marginBottom: 8,
  },
  gutterBottom: {
    marginBottom: 12,
  },
}));

function AppointmentCardLoader() {
  const classes = useStyles();

  return (
    <Container>
      <Skeleton variant="text" className={classes.title} />
      <Skeleton variant="text" width={80} />
      <ProfessionalInfo>
        <Skeleton variant="circle" width={50} height={50} />
        <div>
          <Skeleton
            variant="text"
            width={120}
            className={classes.professionalName}
          />
          <Skeleton variant="text" width={120} />
        </div>
      </ProfessionalInfo>

      <Skeleton variant="text" width={"85%"} className={classes.gutterBottom} />
      <Skeleton variant="text" width={"85%"} className={classes.gutterBottom} />
      <Skeleton variant="rect" width={"100%"} height={80} />
    </Container>
  );
}

export default AppointmentCardLoader;
