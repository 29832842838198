import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import withAlelrtAndDialog from "../../../HOCs/withAlelrtAndDialog";
import AddressComponent from "../addressComponent";
import { withTheme } from "@material-ui/core/styles";
import ViewAllCards from "./viewAllCard";
import { customAddress, textClampStyleGenerator } from "../../../utils";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const styles = (theme) => ({
  title: {
    fontSize: "20px",
    // fontWeight: 600,
    textAlign: "left",
    marginBottom: 20,
    marginTop: 8,
  },
  add_title: {
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "left",
    // color: "#f27d35",
    color: theme.palette.ternary.main,
    textTransform: "uppercase",
    cursor: "pointer",
    width: "max-content",
  },
  detail_title: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    textAlign: "left",
    // color: "#f27d35",
    color: theme.palette.ternary.main,
    textTransform: "uppercase",
    width: "max-content",
  },
  view_more_title: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    color: theme.palette.ternary.main,
    textTransform: "uppercase",
    width: "max-content",
  },
  helperTextRoot: {
    color: "#bc2d4f",
    fontSize: "0.8rem",
  },
  listItemGutters: {
    paddingLeft: "0px",
  },
  primaryText: {
    fontWeight: 500,
    ...textClampStyleGenerator(2),
  },
  listTextPrimary: {
    fontWeight: "600",
  },
  listItemSecondaryAction: {
    paddingRight: 90,
  },
});

class ModernViewAddressCardDetail extends React.Component {
  state = {
    address: [],
  };

  componentDidMount() {
    this.executeMountInitialization();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.editData !== this.props?.editData) {
      this.executeMountInitialization();
    }
  }

  executeMountInitialization = () => {
    if (
      Array.isArray(this.props?.editData) &&
      this.props?.editData?.length > 0
    ) {
      //
      this.setState({
        address: [...this.props?.editData],
      });
    }
  };

  onEdit = (editData) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <AddressComponent
          id={this.props?.id?.add_address_id}
          editData={editData}
          isEdit={true}
          list={this.state.address}
          callBackOnSave={this.props.callBackOnSave}
        />
      ),
    });
  };

  handleOpenMoreDrawer = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewAllCards
          id={this.props?.id?.add_address_id}
          items={this.props.editData}
          onEdit={this.onEdit}
          onRemove={this.props.callBackOnSave}
          onAdd={() =>
            this.props.handledrawer("Add Address", null, this.props.editData)
          }
        />
      ),
    });
  };

  onEdit = (editData) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <AddressComponent
          id={this.props?.id?.add_address_id}
          editData={editData}
          isEdit={true}
          list={this.state.address}
          callBackOnSave={this.props.callBackOnSave}
          backNeeded={this.props.backNeeded}
          backHandler={this.props.goBackHandler}
        />
      ),
    });
  };

  handleOpenMoreDrawer = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewAllCards
          id={this.props?.id?.add_address_id}
          items={this.props.editData}
          onEdit={this.onEdit}
          onRemove={this.props.callBackOnSave}
          onAdd={() =>
            this.props.handledrawer("Add Address", null, this.props.editData)
          }
          backNeeded={this.props.backNeeded}
          backHandler={this.props.goBackHandler}
        />
      ),
    });
  };
  callBackOnSaveAddress = (data, isremove) => {
    if (isremove) {
      this.props.dialog.setDialog({
        ...this.props.dialog,
        open: true,
        title: "Confirm Action",
        body: "Are you sure to delete this address?",
        positiveBtn: "CONFIRM",
        negativeBtn: "CANCEL",
        dontShowButtons: false,
        onOk: () => {
          let isUpdateData = this.state.address?.filter(
            (val) => val.value !== data?.value
          );

          this.setState({ address: isUpdateData }, () => {
            this.props.callBackOnSave(data, true);
          });
          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: false,
          });
        },
        onCancel: () => {
          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: false,
          });
        },
      });
    }
  };

  render() {
    const {
      classes,
      handledrawer,
      // ondltList,
      // onchanged,
      // editData,
      // cardmd,
      // cardsm,
      disabled,
    } = this.props;
    const { theme } = this.props;
    const { address } = this.state;
    return (
      <>
        {address?.length > 0 ? (
          <>
            {/* <Typography className={classes.title}>{"ID Types"}</Typography> */}
            <Grid container>
              <Grid item container xs={12}>
                <Grid item xs style={{ display: "flex", alignItems: "center" }}>
                  {address?.length >= 1 ? (
                    <Typography className={classes.detail_title}>
                      {"Address"}
                    </Typography>
                  ) : (
                    <Typography
                      onClick={() =>
                        handledrawer("Add New Address", null, address)
                      }
                      className={classes.add_title}
                      id={this.props?.id?.add_address_id}
                    >
                      {"+ Add Address"}{" "}
                      {!this.props.hideRequired && (
                        <span
                          style={{
                            color: theme.palette.error.main,
                            fontWeight: "normal",
                            fontSize: "0.85rem",
                          }}
                        >
                          {"*"}
                        </span>
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <IconButton
                    id={this.props?.id?.add_address_icon_id}
                    onClick={() =>
                      handledrawer("Add New Address", null, address)
                    }
                    size={"small"}
                    style={{
                      border: "1px solid #E8E8EC",
                      margin: "0px 4px",
                      padding: "4px",
                    }}
                  >
                    <AddIcon fontSize={"small"} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {address?.map((item, index) => {
                  if (index < 1) {
                    return (
                      <div style={{ display: "flex" }}>
                        <List
                          dense={true}
                          style={{ width: "100%" }}
                          disablePadding
                        >
                          <ListItem
                            divider
                            classes={{
                              secondaryAction: classes.listItemSecondaryAction,
                              gutters: classes.listItemGutters,
                            }}
                          >
                            <ListItemText
                              classes={{ primary: classes.primaryText }}
                              primary={item?.label ? item?.label : null}
                              secondary={customAddress(
                                item?.addressLine1,
                                item?.addressLine2,
                                item?.area,
                                item?.state,
                                item?.country,
                                item?.postalCode
                              )}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                id={`edit${item?.id}`}
                                onClick={() => this.onEdit(item)}
                                edge="end"
                                aria-label="edit"
                                style={{
                                  border: "1px solid #E8E8EC",
                                  margin: "0px 4px",
                                  padding: "4px",
                                }}
                              >
                                <EditOutlinedIcon fontSize={"small"} />
                              </IconButton>
                              <IconButton
                                // id={this.props?.id?.delete_address_icon_id}
                                id={`delete${item?.id}`}
                                onClick={() => {
                                  this.callBackOnSaveAddress(item, true);
                                }}
                                edge="end"
                                aria-label="delete"
                                style={{
                                  border: "1px solid #E8E8EC",
                                  margin: "0px 4px",
                                  padding: "4px",
                                }}
                              >
                                <DeleteOutlinedIcon fontSize={"small"} />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </Grid>
              {address?.length > 1 && (
                <Typography
                  id={this.props?.id?.view_more_address_icon_id}
                  onClick={() => this.handleOpenMoreDrawer()}
                  className={classes.view_more_title}
                  style={{ cursor: "pointer", paddingTop: 8, width: "100%" }}
                  align="right"
                >
                  {"VIEW MORE"}
                </Typography>
              )}
            </Grid>
          </>
        ) : (
          <>
            {!disabled && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Typography>
                  {!this.props.hideActionBtn && (
                    <Typography
                      id={this.props?.id?.add_address_id}
                      onClick={() =>
                        handledrawer("Add New Address", null, address)
                      }
                      className={classes.add_title}
                    >
                      {"+ Add Address"}{" "}
                      {!this.props.hideRequired && (
                        <span
                          style={{
                            color: theme.palette.error.main,
                            fontWeight: "normal",
                            fontSize: "0.85rem",
                          }}
                        >
                          {"*"}
                        </span>
                      )}
                    </Typography>
                  )}
                </Typography>
              </div>
            )}
            {this.props.error && (
              <FormHelperText classes={{ root: classes.helperTextRoot }}>
                {this.props.errorText}
              </FormHelperText>
            )}
          </>
        )}
      </>
    );
  }
}

export default withAlelrtAndDialog(
  withStyles(styles)(withTheme(ModernViewAddressCardDetail))
);
