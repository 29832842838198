// Autocomplete.js
import React, { Component } from "react";
import { LocationIcon } from "../../../assets/locationIcon";
import { TextFieldAdornment } from "../../inputs/textfieldWithAdornment";

class AutoCompleteSearchLocationInGoogleMap extends Component {
  constructor(props) {
    super(props);
    this.clearSearchBox = this.clearSearchBox.bind(this);
  }

  componentDidMount({ map, mapApi } = this.props) {
    const options = {
      // restrict your search to a specific type of result
      types: ["address"],
      // restrict your search to a specific country, or an array of countries
      // componentRestrictions: { country: ['gb', 'us'] },
    };
    this.autoComplete = new mapApi.places.Autocomplete(
      this.searchInput,
      options
    );
    this.autoComplete.addListener("place_changed", this.onPlaceChanged);
    this.autoComplete.bindTo("bounds", map);
  }

  componentWillUnmount({ mapApi } = this.props) {
    mapApi.event.clearInstanceListeners(this.searchInput);
  }

  onPlaceChanged = ({ map, addplace } = this.props) => {
    const place = this.autoComplete.getPlace();

    if (!place.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    addplace(place);
    this.searchInput.blur();
  };
 
  clearSearchBox() {
    this.searchInput.value = "";
  }

  render() {
    return (
      <TextFieldAdornment
        inputRef={(ref) => {
          this.searchInput = ref;
        }}
        onFocus={this.clearSearchBox}
        placeholder=""
        label={this.props.label}
        handleChange={(e) => {}}
        adornment={<LocationIcon />}
      />
    );
  }
}

export default AutoCompleteSearchLocationInGoogleMap;
