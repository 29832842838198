import * as React from "react"

export function PriceTagIcon(props) {
  return (
    <svg width={19} height={19} viewBox="0 0 19 19" {...props}>
      <defs>
        <style>{".prefix__cls-1{fill:#28a9c1}"}</style>
      </defs>
      <g id="prefix__Price" transform="translate(-2 -3)">
        <path
          id="prefix__Path_668"
          d="M10 22a1.986 1.986 0 01-1.414-.586l-6-6a2 2 0 010-2.828L12.172 3H21v8.828l-9.586 9.586A1.986 1.986 0 0110 22zm3-17l-9 9 6 6 9-9V5z"
          className="prefix__cls-1"
          data-name="Path 668"
        />
        <path
          id="prefix__Path_669"
          d="M16 7a1 1 0 101 1 1 1 0 00-1-1z"
          className="prefix__cls-1"
          data-name="Path 669"
        />
      </g>
    </svg>
  )
}

