import {
  // Avatar,
  Divider,
  Grid,
  // Link,
  Typography,
  withStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React from "react";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import {
  CustomAvatar,
  CustomTypography,
  // HybridTypography,
  InternatlServerError,
  LoadingSection,
  NotFoundSection,
  TextField,
} from "../../../components";
import { withRouter } from "react-router-dom";
import moment from "moment";
// import AvatarStack from "../../../components/avatarStack";
import ViewServiceCoordinators from "../../userManagement/careProvider/details/ViewServiceCoordinators";
import withAlelrtAndDialog from "../../../HOCs/withAlelrtAndDialog";
import { routes } from "../../../router/routes";
// import SimpleAvatarCard from "../../../components/simpleAvatarCard";
// import { ICONS } from "../../../components/hybridTypography/typography";
// import List from "../../../components/userList/cardList";
import AddEditActivity from "../../availability/calendar/addEditActivity";
import ViewServiceProfessionals from "../../userManagement/careProvider/details/ViewServiceProfessional";
import { commonStyle } from "../style";
// import DetailsCard from "../../../components/monitorCard/detailsCard";
// import DetailsDescription from "../../../components/monitorCard/detailsDescription";
// import SubscribedServices from "../../../components/monitorCard/serviceProduct";

import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
// import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { formatDate } from "../../../utils/common";
import { alertProps, textClampStyleGenerator } from "../../../utils";
import { CustomChip } from "../../../components/niceComponents/customChip";
import { CustomStatus } from "../../../components/niceComponents/customStatus";
import SubscriptionRefundLogs from "./subscriptionsRefundLogs";
import AssignCoordinator from "./AssignCoordinator";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = (theme) => ({
  root: {
    padding: 24,
  },
  headerSection: {
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    backgroundColor: "white",
    borderRadius: 8,
  },
  ...commonStyle,
});

/* const sizes = {
  md: 3,
  sm: 3,
  xs: 3,
};
 */
class SubscriptionDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getSubscriptionDetail();
  }

  getSubscriptionDetail = async() => {
    const subscription_id = this.props?.match?.params?.id;

    await this.props.GET_SUBSCRIPTION_DETAILS({
      subscription_id,
    });
  };

  onEndIconClickHandler = (id, isCareGiver) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
    this.props.history.push(
      `${
        isCareGiver
          ? routes.manage_care_givers
          : routes.manage_care_coordinators
      }/view/${id}`
    );
  };

  handleViewAllClick = (data) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewServiceCoordinators
          data={data ?? []}
          drawer={this.props.drawer}
          onEndIconClickHandler={this.onEndIconClickHandler}
        />
      ),
    });
  };

  handleViewAllServiceProfessionals = (data) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewServiceProfessionals
          data={data ?? []}
          drawer={this.props.drawer}
          onEndIconClickHandler={this.onEndIconClickHandler}
        />
      ),
    });
  };

  navigateToView = (id) => {
    this.props.history.push(routes.manage_service + "/veiw/" + id);
  };

  mapperToList = (_) => {
    return {
      id: _.service_id,
      isactive: undefined,
      name: `${_.service_name} (${_.quantity})`,
      orderList: [
        {
          count: _.pending,
          name: "Pending",
        },
        {
          count: _.completed,
          name: "Completed",
        },
      ],
      profile_pic: _.subscription_picture,
      care_provider: {
        name: `${
          moment(_.expired_on).diff(moment(), "days") > 0
            ? `Expires on ${moment(_.expired_on).format("Do MMM YYYY")}`
            : `Expired on ${moment(_.expired_on).format("Do MMM YYYY")}`
        }`,
      },
      ..._,
    };
  };

  onAddActivityClicked = (_, data) => {
    console.log("_____________________________");
    console.log(data);
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <AddEditActivity
          {...this.props}
          // isEdit={true}
          parentState={{
            cliendId: {
              label: data?.client_info?.client_name,
              value: data?.client_info?.client_id,
            },
            subscriptions: {
              label: data.subscription_name,
              value: data.subscription_id,
              type: data.subscription_type_id,
            },
            partnerId: {
              label: data?.service_coord[0]?.provider?.provider_name,
              value: data?.service_coord[0]?.provider?.provider_id,
            },
            services: {
              label: _?.service_name,
              value: _?.service_id,
            },
          }}
          reload={this.getSubscriptionDetail}
          // serviceProfessionalID={this.state.id}
        />
      ),
    });
  };

  shouldComponentUpdate(nextProps) {
    //Update Subscription Status
    const { subscriptionStatus } = nextProps;
    const oldSubscriptionStatus = this.props.subscriptionStatus;

    if (oldSubscriptionStatus?.loading !== subscriptionStatus?.loading) {
      if (!subscriptionStatus.loading && !subscriptionStatus.error) {
        this.getSubscriptionDetail();
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: subscriptionStatus.message
            ? subscriptionStatus.message
            : "Subscription Cancelled Successfully!",
          severity: alertProps.severity.success,
        });
        this.getSubscriptionDetail();
      } else if (subscriptionStatus.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: subscriptionStatus.message,
          severity: alertProps.severity.error,
        });
      }
    }

    const { deleteActivity } = nextProps;
    const oldDeleteActivity = this.props.deleteActivity;

    if (oldDeleteActivity?.loading !== deleteActivity?.loading) {
      if (!deleteActivity.loading && !deleteActivity.error) {
        // this.props.alert.setSnack({
        //   open: true,
        //   horizontal: alertProps.horizontal.center,
        //   vertical: alertProps.vertical.top,
        //   msg: deleteActivity.message
        //     ? deleteActivity.message
        //     : "Subscription Cancelled Successfully!",
        //   severity: alertProps.severity.success,
        // });
        this.getSubscriptionDetail();
      } else if (deleteActivity.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: deleteActivity.message,
          severity: alertProps.severity.error,
        });
      }
    }
    // deleteActivity
    return true;
  }

  componentDidUpdate(prevProps) {
    if(prevProps?.match?.params?.id !== this.props?.match?.params?.id){
      this.getSubscriptionDetail();
    }
  }

  handleViewMore = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <SubscriptionRefundLogs
          title={this.props?.sub_details?.data?.refundId ? `Refund Id - ${this.props?.sub_details?.data?.refundId}` : 'Refund Details'}
          subscriptionId={this.props?.match?.params?.id}
          transaction_id={this.props?.sub_details?.data?.transaction_id}
          isCancelled = {this.props?.sub_details?.data?.isCancelled}
        />
      ),
    });
  };

  handleAssignCoordinator = () => {
    // console.log(this.props.sub_details?.data?.service_coord?.[0]?.value)
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <AssignCoordinator
          partnerId={this.props.sub_details?.data?.provider}
          coordinatorId={
            this.props.sub_details?.data?.service_coord?.[0]?.value
              ? this.props.sub_details?.data?.service_coord?.[0]?.value
              : ""
          }
          assigncoordinator={this.assigncoordinator}
          onCancel={this.handleCloseDrawer}
        />
      ),
    });
  };

  handleCloseDrawer = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
    });
    this.getSubscriptionDetail();
  };

  handleCancelSubscription = async (remarks) => {

    if (remarks?.length > 0) {
      this.props.dialog.setDialog({ open: false });

      let { data } = this.props?.sub_details;

      // alert(remarks)
      const refundPayload = {
        partnerId: data?.provider,
        clientId: data?.client_info?.client_id,
        subscriptionId: data?.subscription_id,
        remarks: remarks,
        status: "subscription_cancelled",
        isAdd: true,
      };

      await this.props.UPDATE_SUBSCRIPTION_REFUND_STATUS(refundPayload);

      // 
      console.log(data);


      if (data?.appointmentIds?.length > 0) {
        const appointmentPayload = {
          activityId:
            data?.appointmentIds?.length > 0 ? data?.appointmentIds : [],
          remarks:
            "This appointment is cancelled due to subscription cancellation",
          isCancelMultipleAppointments: true,
        };

        await this.props.DELETE_ACTIVITY(appointmentPayload);
      }
      this.getSubscriptionDetail()

      // this.props.UPDATE_SUBSCRIPTION_STATUS_MONITORING({ subscriptionId: this.props?.match?.params?.id })

    }

    else{
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the remarks",
        severity: alertProps.severity.warning,
      });
    }



  };

  handleCancelDialog = () => {
    // if(this.props.sub_details?.data?.pending_count === 0)

      this.props.dialog.setDialog({
        open: true,
        title: "Are you sure you want to cancel this subscription?",
        body: (
          <>
            <TextField
              required={true}
              type={"text"}
              onlyText={false}
              rowsMax={3}
              multiline={true}
              title={"Reason For Cancellation"}
              placeholder={"Reason For Cancellation"}
              value={this.state.cancelation_reason}
              handleOnChange={(e) => {
                console.log(e.target?.value);
                this.setState({
                  cancelation_reason: e.target?.value,
                });
              }}
            />
          </>
        ),
        negativeBtn: "Cancel",
        positiveBtn: "Confirm",
        onOk: () => {
          this.handleCancelSubscription(this.state.cancelation_reason)
        },
      });

    // else{
    //   this.props.alert.setSnack({
    //     open: true,
    //     horizontal: alertProps.horizontal.center,
    //     vertical: alertProps.vertical.top,
    //     msg: "You cannot Cancel a subscription with a pending activity.",
    //     severity: alertProps.severity.warning,
    //   });
    // }
  };

  assigncoordinator = async (error, data) => {
    console.log("Hi");
    console.log(data?.coordinator?.value);
    console.log(this.props.UPDATE_SUBSCRIPTION);
    await this.props.UPDATE_SUBSCRIPTION({
      subscriptionId: this.props?.match?.params?.id,
      coordinatorId: data?.coordinator?.value,
    });
    this.handleCloseDrawer();
  };

  render() {
    const { classes } = this.props;

    let { data, loading, error } = this.props.sub_details;

    // const id = this.props.match?.params?.id;

    const sc_arr =
      data?.service_coord?.length > 0
        ? data?.service_coord?.map((_) => {
            return {
              ..._,
              profile_pic: _.profile_picture,
            };
          })
        : [];

    return (
      <div className={classes.root}>
        {loading && !error && (
          <div>
            <LoadingSection message="Fetching the subscription details..." />
          </div>
        )}

        {!loading && error && (
          <div>
            <InternatlServerError
              message="Something went wrong! Try Again"
              onRetry={this.getSubscriptionDetail}
            />
          </div>
        )}

        {!loading && !error && !data && (
          <div>
            <NotFoundSection message="Subscription Detail Not Found" />
          </div>
        )}

        {!loading && !error && data && (
          <>
            {/* Update */}
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {/* <DetailsCard data={data} /> */}
                  <SubscriptionDisplay
                    avatar={data?.subscription_picture}
                    name={data?.subscription_name}
                    status={data?.active_status}
                    type={data?.subscription_type}
                    expiry={data?.expired_on}
                    handleViewMore={this.handleViewMore}
                    showCancelButton={
                      moment(new Date()).diff(
                        moment(new Date(data?.subscribed_on)),
                        "days"
                      ) <= 14
                        ? true
                        : false
                    }
                    handleCancelSubscription={this.handleCancelDialog}
                    cancellationStatus={data?.subscription_status}
                    isCancelled={data?.isCancelled}
                    cancelledOn={data?.cancelledOn}
                  />
                </Grid>

                <Grid item xs={12} lg={6} style={{ alignSelf: "stretch" }}>
                  <UserDisplay
                    title={"Service Co-ordinator"}
                    title2={"Client"}
                    name={sc_arr?.[0]?.label}
                    name2={data?.client_info?.client_name}
                    contactNumber={sc_arr?.[0]?.mobile_no}
                    contactNumber2={data?.client_info?.client_mobile_no}
                    showAssignButton={
                      sc_arr?.[0]?.label !== undefined ? false : true
                    }
                    hideReassignButton={!data?.isCancelled}
                    isCoordinator={true}
                    handleAssignCoordinator={this.handleAssignCoordinator}
                  />
                </Grid>
                {data?.subscription_description && (
                  <Grid item xs={12} lg={6} alignSelf={"stretch"}>
                    <NewDescriptionDisplay
                      title={"Description"}
                      content={data?.subscription_description}
                      terms_and_conditions={data?.terms_and_conditions}
                      setInnerHtml={true}
                    />
                  </Grid>
                )}
                {/* {data?.terms_and_conditions &&
                  Array.isArray(data?.terms_and_conditions) && (
                    <Grid item xs={12} lg={6} alignSelf={"stretch"}>
                      <DescriptionDisplay
                        title={"Terms & Conditions"}
                        content={data?.terms_and_conditions}
                      />
                    </Grid>
                  )} */}

                {/* <Grid item xs={12} lg={6}>
                  <UserDisplay
                    title={"Client"}
                    name={data?.client_info?.client_name}
                    contactNumber={data?.client_info?.client_mobile_no}
                    isCoordinator={false}
                    showAssignButton={false}
                  />
                </Grid> */}
                {data?.subscription_type !== "Service" ? (
                  <Grid item xs={12}>
                    <ServiceDisplay
                      services={data?.mapped_service}
                      data={data}
                      isService={false}
                      hideBookAServiceButton={data?.isCancelled}
                      bookServiceHandler={this.onAddActivityClicked}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <ServiceDisplay
                      services={data?.mapped_service}
                      data={data}
                      isService={true}
                      hideBookAServiceButton={data?.isCancelled}
                      bookServiceHandler={this.onAddActivityClicked}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          </>
        )}
      </div>
    );
  }
}

SubscriptionDetail.propTypes = {};

const mapStateToProps = (state) => ({
  sub_details: state?.subscription_tracking?.sub_details ?? {
    data: [],
    loading: false,
    error: false,
  },
  // subscriptionStatus: state?.subscription_tracking?.subscription_status_update,
  subscriptionStatus: state?.subscription_tracking?.subscriptionRefund,
  subscription_coordinator_update:
    state?.subscription_tracking?.subscription_coordinator_update,
  deleteActivity: state?.activity_app?.delete_activity,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(SubscriptionDetail))));

const descriptionDisplayStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    boxShadow: "none",
    border: `1px solid #d8dce5`,
  },
  fullHeight: {
    height: "100%",
  },
  displayWrapper: {
    margin: theme.spacing(0, 2.5, 2, 1.25),
    //paddingTop: theme.spacing(2),

    overflowY: "hidden",
  },
  description: {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflowY: "hidden",
  },
  actionButtonsWrapper: {
    display: "flex",
    gap: theme.spacing(1.25),
  },
  actionButton: {
    border: "solid 1px rgba(112, 112, 112, 0.1)",
  },
  actionButtonIcon: {
    fontSize: "1.10rem",
  },
  dialogPaper: {
    width: "100%",
  },
  newClass: {
    maxHeight: theme.spacing(6),
  },
}));

/* const DescriptionDisplay = ({ title, content, setInnerHtml = false }) => {
  const classes = descriptionDisplayStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  // const [descriptionHeight, setDescriptionHeight] = React.useState(0);
  const [showViewMore, setShowViewMore] = React.useState(false);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

    const element = document.getElementById(
      `${title?.replace(/\s/g, "")}_wrapper`
    );
    if (setInnerHtml) {
      setShowViewMore(true);
    } else {
      setShowViewMore(
        element.children[0].scrollHeight > element.children[0].offsetHeight
          ? true
          : false
      );
    }
  }, [open, setInnerHtml, title]);

  return (
    <div className={classes.fullHeight}>
      <Paper className={`${classes.root} ${classes.fullHeight}`}>
        <CustomTypography
          variant="row1"
          color={theme.palette.text.secondary}
          styles={{ padding: theme.spacing(2, 2.5, 1.5) }}
        >
          {title}
        </CustomTypography>
        <Divider light />
        <div className={classes.displayWrapper}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <div
                id={`${title?.replace(/\s/g, "")}_wrapper`}
                style={{ marginLeft: theme.spacing(1.5) }}
              >
                {setInnerHtml ? (
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: `<div style="width: 100%;overflow-wrap: anywhere;">${content}</div>`,
                    }}
                    className={classes.description}
                  ></Typography>
                ) : Array.isArray(content) ? (
                  <Typography className={classes.description}>
                    <ul style={{ margin: 0 }}>
                      {content?.map((row) => (
                        <li>{row}</li>
                      ))}
                    </ul>
                  </Typography>
                ) : (
                  <Typography className={classes.description}>
                    {content}
                  </Typography>
                )}
              </div>
              {showViewMore && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant={"text"}
                    style={{
                      color: theme.palette.ternary.main,
                      fontSize: "0.775rem",
                    }}
                    onClick={handleClickOpen("paper")}
                  >
                    {"+ View More"}
                  </Button>
                </div>
              )}
              <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                classes={{ paper: classes.dialogPaper }}
                maxWidth="xs"
              >
                <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                    {setInnerHtml ? (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: `<div style="width: 100%;overflow-wrap: anywhere;">${content}</div>`,
                        }}
                      ></Typography>
                    ) : Array.isArray(content) ? (
                      <Typography>
                        <ul style={{ margin: 0 }}>
                          {content?.map((row) => (
                            <li>{row}</li>
                          ))}
                        </ul>
                      </Typography>
                    ) : (
                      <Typography>{content}</Typography>
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
}; */

const NewDescriptionDisplay = ({
  title,
  content,
  terms_and_conditions,
  setInnerHtml = false,
}) => {
  const classes = descriptionDisplayStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  // const [descriptionHeight, setDescriptionHeight] = React.useState(0);
  const [showViewMore, setShowViewMore] = React.useState(false);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

    const element = document.getElementById(
      `${title?.replace(/\s/g, "")}_wrapper`
    );
    if (setInnerHtml) {
      setShowViewMore(true);
      console.log(showViewMore)
    } else {
      setShowViewMore(
        element.children[0].scrollHeight > element.children[0].offsetHeight
          ? true
          : false
      );
    }
  }, [open, setInnerHtml, showViewMore, title]);

  return (
    <div className={classes.fullHeight}>
      <Paper className={`${classes.root} ${classes.fullHeight}`}>
        <CustomTypography
          variant="row1"
          color={theme.palette.text.secondary}
          styles={{ padding: theme.spacing(2, 2.5, 1.5) }}
        >
          {title}
        </CustomTypography>
        <Divider light />
        <div className={classes.displayWrapper}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <div
                id={`${title?.replace(/\s/g, "")}_wrapper`}
                className={classes.newClass}
                style={{ marginLeft: theme.spacing(1.5) }}
              >
                {/* {setInnerHtml ? ( */}
                {/*  <Typography
                    dangerouslySetInnerHTML={{
                      __html: `<div style="width: 100%;overflow-wrap: anywhere;">${content}</div>`,
                    }}
                    className={classes.description}
                  ></Typography> */}
                {/* ) : Array.isArray(content) ? (
                  <Typography className={classes.description}>
                    <ul style={{ margin: 0 }}>
                      {content?.map((row) => (
                        <li>{row}</li>
                      ))}
                    </ul>
                  </Typography>
                      ) : (*/}
                {/* <Typography className={classes.description}>
                    {content}
                  </Typography> */}
                {/* )} */}
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: `<div className={${classes.styleText}} style="width: 100%;overflow-wrap: anywhere;">${content}</div>`,
                  }}
                  className={classes.description}
                ></Typography>
              </div>
              {/* {showViewMore && ( */}

              {/* )} */}
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant={"text"}
                  style={{
                    color: theme.palette.ternary.main,
                    fontSize: "0.775rem",
                  }}
                  id={`ViewMoreDescriptionButton`}
                  onClick={handleClickOpen("paper")}
                >
                  {"+ View More"}
                </Button>
              </div>
              <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                classes={{ paper: classes.dialogPaper }}
                maxWidth="xs"
              >
                <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                    {/* {setInnerHtml ? ( */}
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: `<div style="width: 100%;overflow-wrap: anywhere;">${content}</div>`,
                      }}
                    ></Typography>
                    {/* ) : Array.isArray(content) ? ( */}

                    {/* ) : ( */}
                    {/* <Typography>{content}</Typography> */}
                    {Array.isArray(terms_and_conditions) ? (
                      <>
                        <CustomTypography variant="row1" weight="bold">
                          {"Terms & Conditions"}
                        </CustomTypography>
                        <Typography>
                          <ul style={{ margin: 0 }}>
                            {terms_and_conditions?.map((row) => (
                              <li>{row}</li>
                            ))}
                          </ul>
                        </Typography>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* )} */}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary" id="CloseMoreDescriptionButton">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
};

const userDisplayStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    boxShadow: "none",
    border: `1px solid #d8dce5`,
  },
  displayWrapper: {
    margin: theme.spacing(2.5, 2.5, 2, 1.25),
  },
  actionButtonsWrapper: {
    display: "flex",
    gap: theme.spacing(1.25),
  },
  actionButton: {
    border: "solid 1px rgba(112, 112, 112, 0.1)",
  },
  actionButtonIcon: {
    fontSize: "1.10rem",
  },
}));

const UserDisplay = ({
  title,
  title2,
  name,
  name2,
  contactNumber,
  contactNumber2,
  isCoordinator,
  handleAssignCoordinator,
  showAssignButton,
  hideReassignButton,
}) => {
  const classes = userDisplayStyles();
  const theme = useTheme();
  return (
    <div>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item lg={6} style={{width: "100%"}}>
            <Grid container>
              <Grid item sm={9} xs={9} md={8} >
                <CustomTypography
                  variant="row1"
                  color={theme.palette.text.secondary}
                  // styles={{ padding: theme.spacing(2, 2.5, 1.5) }}
                  styles={{ padding: theme.spacing(2, 0, 1.5, 2.5)}}
                >
                  {title}
                </CustomTypography>
              </Grid>
              <Grid item alignItems="flex-end">
                {isCoordinator && hideReassignButton ? (
                  showAssignButton ? (
                    <Button
                      variant="text"
                      color="primary"
                      id="AssignCoordinator"
                      style={{ padding: "16px 20px 12px" }}
                      onClick={() => handleAssignCoordinator()}
                    >
                      Assign
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      color="primary"
                      id="ReassignCoordinator"
                      style={{ padding: "16px 20px 12px" }}
                      onClick={() => handleAssignCoordinator()}
                    >
                      Reassign
                    </Button>
                  )
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>

            <Divider light />
            <div className={classes.displayWrapper}>
              {!showAssignButton ? (
                <Grid container alignItems="center">
                  <Grid item>
                    <CustomAvatar src={null} name={name} />
                  </Grid>
                  <Grid item xs>
                    <CustomTypography
                      variant="row1"
                      weight="bold"
                      styles={{ ...textClampStyleGenerator(1) }}
                    >
                      {name}
                    </CustomTypography>
                    <CustomTypography variant="row2">
                      {contactNumber}
                    </CustomTypography>
                  </Grid>
                  <Grid item>
                    <div className={classes.actionButtonsWrapper}>
                      <a href={`tel:${contactNumber}`}>
                        <IconButton className={classes.actionButton}>
                          <CallOutlinedIcon
                            fontSize={"small"}
                            htmlColor={theme.palette.text.primary}
                            className={classes.actionButtonIcon}
                          />
                        </IconButton>
                      </a>
                      {/* <a href={`sms:${contactNumber}&body=Hi%2520there`}>
                  <IconButton className={classes.actionButton}>
                    <ChatBubbleOutlineOutlinedIcon fontSize={"small"} htmlColor={theme.palette.text.primary} className={classes.actionButtonIcon} />
                  </IconButton>
                </a> */}
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Typography color="secondary">Not Assigned yet</Typography>
              )}
            </div>
          </Grid>
          <Grid item lg={6} style={{width: "100%"}}>
            <Grid container>
              <Grid item sm={9} xs={9} md={10}>
                <CustomTypography
                  variant="row1"
                  color={theme.palette.text.secondary}
                  styles={{ padding: theme.spacing(2, 2.5, 1.5) }}
                >
                  {title2}
                </CustomTypography>
              </Grid>
            </Grid>

            <Divider light />
            <div className={classes.displayWrapper}>
              {/* {!showAssignButton ? ( */}
              <Grid container alignItems="center">
                <Grid item>
                  <CustomAvatar src={null} name={name} />
                </Grid>
                <Grid item xs>
                  <CustomTypography
                    variant="row1"
                    weight="bold"
                    styles={{ ...textClampStyleGenerator(1) }}
                  >
                    {name2}
                  </CustomTypography>
                  <CustomTypography variant="row2">
                    {contactNumber2}
                  </CustomTypography>
                </Grid>
                <Grid item>
                  <div className={classes.actionButtonsWrapper}>
                    <a href={`tel:${contactNumber2}`}>
                      <IconButton className={classes.actionButton}>
                        <CallOutlinedIcon
                          fontSize={"small"}
                          htmlColor={theme.palette.text.primary}
                          className={classes.actionButtonIcon}
                        />
                      </IconButton>
                    </a>
                    {/* <a href={`sms:${contactNumber}&body=Hi%2520there`}>
                  <IconButton className={classes.actionButton}>
                    <ChatBubbleOutlineOutlinedIcon fontSize={"small"} htmlColor={theme.palette.text.primary} className={classes.actionButtonIcon} />
                  </IconButton>
                </a> */}
                  </div>
                </Grid>
              </Grid>
              {/* } */}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const serviceDisplayStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    boxShadow: "none",
    border: `1px solid #d8dce5`,
  },
  displayWrapper: {
    margin: theme.spacing(2.5, 0, 2),
  },
  serviceOverLine: {
    display: "flex",
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(0.75),
  },
  serviceStats: {
    display: "flex",
    gap: theme.spacing(1.25),
  },
}));

const ServiceDisplay = ({
  services,
  data,
  isService = false,
  hideBookAServiceButton,
  bookServiceHandler,
}) => {
  const classes = serviceDisplayStyles();
  const theme = useTheme();

  console.log(data?.services);
  console.log(services);
  return (
    <div>
      <Paper className={classes.root}>
        <CustomTypography
          variant="row1"
          color={theme.palette.text.secondary}
          styles={{ padding: theme.spacing(2, 2.5, 1.5) }}
        >
          Services
        </CustomTypography>
        <Divider light />
        <div className={classes.displayWrapper}>
          <Grid container alignItems="center">
            {!isService ? (
              <>
                {services?.map((service, index) => (
                  <>
                    <Grid item container xs={12}>
                      <Grid item xs>
                        <div style={{ marginLeft: theme.spacing(2.5) }}>
                          <div className={classes.serviceOverLine}>
                            <CustomTypography
                              variant="row1"
                              weight="bold"
                              styles={{
                                letterSpacing: "0.3px",
                                ...textClampStyleGenerator(1),
                              }}
                            >
                              {service?.label
                                ? service?.label
                                : service.service_name
                                ? service.service_name
                                : "N/A"}
                            </CustomTypography>
                            <CustomChip
                              label={
                                service?.entityType
                                  ? service?.entityType
                                  : "Service"
                              }
                            />
                          </div>

                          <div className={classes.serviceStats}>
                            <CustomTypography variant="row2">
                              Completed:{" "}
                              {
                                data?.services?.[index]
                                  ?.completed_services_count
                              }
                            </CustomTypography>
                            <Divider flexItem orientation="vertical" light />
                            <CustomTypography variant="row2">
                              Booked:{" "}
                              {data?.services?.[index]?.booked_services_count}
                            </CustomTypography>
                            <Divider flexItem orientation="vertical" light />
                            <CustomTypography variant="row2">
                              Remaining:{" "}
                              {data?.services?.[index]?.pending_services_count}
                            </CustomTypography>
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <div style={{ marginRight: theme.spacing(2.5) }}>
                          {hideBookAServiceButton ? (
                            <></>
                          ) : parseInt(
                              data?.services?.[index]?.pending_services_count
                            ) !== 0 ? (
                            <Button
                              color="primary"
                              id={`BookAServiceButton${index}`}
                              onClick={() => bookServiceHandler(service, data)}
                            >
                              + Book a Service
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider light flexItem />
                    </Grid>
                    {services?.length - 1 !== index && (
                      <Grid item xs={12}>
                        <Divider
                          light
                          style={{ margin: theme.spacing(2, 0) }}
                        />
                      </Grid>
                    )}
                  </>
                ))}
              </>
            ) : (
              <>
                <Grid item container xs={12}>
                  <Grid item xs>
                    <div style={{ marginLeft: theme.spacing(2.5) }}>
                      <div className={classes.serviceOverLine}>
                        <CustomTypography
                          variant="row1"
                          weight="bold"
                          styles={{
                            letterSpacing: "0.3px",
                            ...textClampStyleGenerator(1),
                          }}
                        >
                          {data?.subscription_name}
                        </CustomTypography>
                        <CustomChip label={"Service"} />
                      </div>

                      <div className={classes.serviceStats}>
                        <CustomTypography variant="row2">
                          Completed:{" "}
                          {data?.services?.[0]?.completed_services_count}
                        </CustomTypography>
                        <Divider flexItem orientation="vertical" light />
                        <CustomTypography variant="row2">
                          Booked: {data?.services?.[0]?.booked_services_count}
                        </CustomTypography>
                        <Divider flexItem orientation="vertical" light />
                        <CustomTypography variant="row2">
                          Remaining:{" "}
                          {data?.services?.[0]?.pending_services_count}
                        </CustomTypography>
                      </div>
                    </div>
                  </Grid>
                  <Grid item>
                    <div style={{ marginRight: theme.spacing(2.5) }}>
                      {hideBookAServiceButton ? (
                        <></>
                      ) : parseInt(
                          data?.services?.[0]?.pending_services_count
                        ) !== 0 ? (
                        <Button
                          color="primary"
                          id={`BookAServiceButtonService`}
                          onClick={() => bookServiceHandler(data, data)}
                        >
                          + Book a Service
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider light flexItem />
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </Paper>
    </div>
  );
};

const subscriptionDisplayStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    boxShadow: "none",
    border: `1px solid #d8dce5`,
  },
  displayWrapper: {
    margin: theme.spacing(2.5, 2.5, 2.5, 1),
  },
  overline: {
    display: "flex",
    gap: theme.spacing(1),
  },
  underLine: {
    display: "flex",
    gap: theme.spacing(1),
  },
  quickAccess: {
    display: "flex",
    gap: theme.spacing(1),
  },
}));

const SubscriptionDisplay = ({
  avatar,
  name = "N/A",
  status,
  type,
  expiry,
  isCancelled,
  showCancelButton = false,
  cancellationStatus,
  cancelledOn,
  handleViewMore,
  handleCancelSubscription,
}) => {
  const classes = subscriptionDisplayStyles();
  const theme = useTheme();
  return (
    <div>
      <Paper className={classes.root}>
        <div className={classes.displayWrapper}>
          <Grid container alignItems="center">
            <Grid item>
              <CustomAvatar src={avatar} name={name} fallbackAsImage />
            </Grid>
            <Grid item xs>
              <div className={classes.overline}>
                <CustomTypography variant="row1" weight="bold">
                  {name}
                </CustomTypography>
                <CustomStatus active={status} loading={false} />
              </div>
              <div className={classes.underLine}>
                {/* <CustomTypography variant="row2">{type}</CustomTypography> */}
                <CustomChip label={type ?? "N/A"} />
                <Divider orientation="vertical" light flexItem />
                <CustomTypography variant="row2">{`Expire${
                  moment().isAfter(expiry) ? "d" : "s"
                } on ${formatDate(expiry)}`}</CustomTypography>
              </div>
            </Grid>
            <Grid item>
              <div className={classes.quickAccess}>
                {/* subscription_status */}
                {!isCancelled ? (
                  showCancelButton ? (
                    <Button
                      color="primary"
                      id={`CancelSubscriptionButton`}
                      onClick={handleCancelSubscription}
                    >
                      Cancel Subscription
                    </Button>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                {isCancelled && (
                  <>
                    <div>
                      <CustomTypography
                        variant="row2"
                        weight="bold"
                        color={theme.palette.error.main}
                        align={"flex-end"}
                        textAlign={"right"}
                      >{`Cancelled on ${formatDate(
                        cancelledOn
                      )}`}</CustomTypography>
                      <CustomTypography
                        variant="row3"
                        color={theme.palette.success.main}
                        align={"flex-end"}
                        textAlign={"right"}
                      >
                        {cancellationStatus?.label}
                      </CustomTypography>
                    </div>
                    <Divider light flexItem orientation="vertical" />
                    <Button
                      color="primary"
                      id={`ViewSubscriptionLogMore`}
                      onClick={handleViewMore}
                    >
                      VIEW MORE
                    </Button>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
};
