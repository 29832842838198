import {
  Button,
  Grid,
  // InputLabel,
  Typography,
  // withStyles
} from "@material-ui/core";
// import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React from "react";
// import DaysToggleGroup from "../../../../components/daysToggleGroup";
import { SimpleSelect } from "../../../../components/inputs";
import HeaderWithSwitch from "../../../../components/managedAvailability/addEditAvailability/headerWithSwitch";
// import { ExceptionTimeSlot } from "../../../../components/managedAvailability/exceptions/exceptionTimeSlot";
import TimeSlot from "../../../../components/managedAvailability/addEditAvailability/timeSlot";
import {
  alertProps,
  options,
  exceptionAvailableWeekDays,
} from "../../../../utils";
import { v4 as uuidv4 } from "uuid";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { connect } from "react-redux";
import { actions } from "tanyacare-middleware";
import { withRouter } from "react-router-dom";
import { routes } from "../../../../router/routes";
import { LoadingSection, InternatlServerError } from "../../../../components";
import moment from "moment";

class AddEditException extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exceptionBasedOn: "category",
      category: [],
      personas: [],
      individuals: [],

      //Operational Hours State
      available247: false,
      availableWeekDays: JSON.parse(JSON.stringify(exceptionAvailableWeekDays)),
      slots: [
        {
          id: uuidv4(),
          isAllDayChecked: false,
          days: [],
          timeSlot: [null, null],
          timeZone: null,
        },
      ],
      exceptionError: [],
      isError:false
    };
  }

  // Lifecycle Method Handlers
  async componentDidMount() {
    this.props.GET_SERVICE_PROFESSIONAL_BY_PROVIDER_ID({
      providerId: this.props?.authdata?.user_id,
    });
    if (this.props.isEdit) {
      await this.props.GET_EXCEPTION({
        exceptionId: this.props?.match?.params?.id,
      });
      this.prefillFormData();
    }
  }

  shouldComponentUpdate(nextProps) {
    // Create Exception
    
    const { createException } = nextProps;
    const oldCreateException = this.props.createException;

    if (oldCreateException?.loading !== createException?.loading) {
      if (!createException?.loading && !createException?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Exception Added successfully!",
          severity: alertProps.severity.success,
        });
        
        this.props.history.push(routes.manage_availability);
      } else if (createException?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: createException?.message
            ? createException?.message
            : "Failed to add Exception!",
          severity: alertProps.severity.error,
        });
      }
    }

    // Update Exception
    const { updateException } = nextProps;
    const oldUpdateException = this.props.updateException;

    if (oldUpdateException?.loading !== updateException?.loading) {
      if (!updateException?.loading && !updateException?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Exception Added successfully!",
          severity: alertProps.severity.success,
        });
        this.props.history.push(routes.manage_availability);
      } else if (updateException?.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: updateException?.message
            ? updateException?.message
            : "Failed to add Exception!",
          severity: alertProps.severity.error,
        });
      }
    }

    return true;
  }

  prefillFormData = () => {
    const data = JSON.parse(JSON.stringify(this.props?.getException?.data));

    if (data?.[0]?.exceptions?.available247) {
      this.setState({
        available247: data?.[0]?.exceptions?.available247,
        personas:
          Array.isArray(data?.[0]?.exceptions?.personas?.data) &&
          data?.[0]?.exceptions?.personas?.data?.length > 0
            ? data?.[0]?.exceptions?.personas?.data
            : [],
      });
    } else {
      const getAvailableWeekDays = (operationalHours) => {
        if (Array.isArray(operationalHours)) {
          if (operationalHours?.length > 0) {
            let currentAvailable = JSON.parse(
              JSON.stringify(exceptionAvailableWeekDays)
            );
            let usedDays = operationalHours.map((slot) => slot?.days)?.flat();
            usedDays.forEach((day) => (currentAvailable[day] = false));
            return currentAvailable;
          } else {
            return exceptionAvailableWeekDays;
          }
        } else {
          return exceptionAvailableWeekDays;
        }
      };
      const payload = {
        available247: data?.[0]?.exceptions?.available247,
        slots:
          Array.isArray(data?.[0]?.exceptions?.operationalHours) &&
          data?.[0]?.exceptions?.operationalHours?.length > 0
            ? data?.[0]?.exceptions?.operationalHours
            : this.state.slots,
        availableWeekDays:
          getAvailableWeekDays(data?.[0]?.exceptions?.operationalHours) ??
          exceptionAvailableWeekDays,
        personas:
          Array.isArray(data?.[0]?.exceptions?.personas?.data) &&
          data?.[0]?.exceptions?.personas?.data?.length > 0
            ? data?.[0]?.exceptions?.personas?.data
            : [],
      };
      this.setState(payload);
    }
  };


//validations 
validationsForSameTime = (mapIndex, slots) => {
  
  return slots.filter((item, index) => {
    let hasDays = item.days.filter(val=>slots?.[mapIndex]?.days.includes(val))
    
    if (mapIndex !== index && slots?.[mapIndex]?.timeSlot?.[0] && slots?.[mapIndex]?.timeSlot?.[1] && hasDays?.length > 0) {
      
      // item.timeSlot ===
      let _currentObjectFromTime = new Date(
        slots[mapIndex].timeSlot[0]
      ).setSeconds(0,0); // from time
      let _totalObjectFromTime = new Date(item.timeSlot[0]).setSeconds(0,0); // from time
      let _currentObjectToTime = new Date(
        slots[mapIndex].timeSlot[1]
      ).setSeconds(0,0); // to time
      let _totalObjectToTime = new Date(item.timeSlot[1]).setSeconds(0,0); // to time

      const checkIsBetween = (selectedTime, fromTime, toTime) =>
        moment(selectedTime).isBetween(fromTime, toTime);
      const checkIsSame = (date, data) => moment(date).isSame(data);
      return (
        checkIsSame(_currentObjectFromTime, _totalObjectFromTime) ||
        checkIsSame(_currentObjectToTime, _totalObjectToTime) ||
        checkIsBetween(
          _currentObjectFromTime,
          _totalObjectFromTime,
          _totalObjectToTime
        ) ||
        checkIsBetween(
          _currentObjectToTime,
          _totalObjectFromTime,
          _totalObjectToTime
        )
      );
    }
    else{
      return false
    }
  });
};


  // Handlers
  handleSwithIconToggle(event) {
    this.setState({
      available247: event.target.checked,
    });
  }

  // isAllDayCheckedHandler
  handleAvailableAllDayChange(event, mapIndex) {
    // persist function is needed to retrieve the target
    event.persist();
    let { slots, availableWeekDays } = this.state;

    // Setting allDayAvailable
    if (event.target.checked === false) {
      slots[mapIndex].days = [];
      for (const [key] of Object.entries(availableWeekDays)) {
        availableWeekDays = { ...availableWeekDays, [key]: true };
      }
    } else {
      const checkAnyOtherSlotsWithDays = () => {
        return slots[mapIndex].days?.length === Object.values(availableWeekDays)?.filter((day) => !day)?.length ? false : true
      };

      // Check if there are any other slots than the current one and if any days selected in those slots
      if (slots?.length > 1 && checkAnyOtherSlotsWithDays()) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Remove other slots to set available all day",
          severity: alertProps.severity.warning,
        });
        return;
      }

      slots[mapIndex].days = options.daysOfWeek.map((day) => day.value);
      // availableWeekDays = Object.keys(availableWeekDays).map(day => ({[day]: false}))
      for (const [key] of Object.entries(availableWeekDays)) {
        availableWeekDays = { ...availableWeekDays, [key]: false };
      }
    }
    slots[mapIndex].isAllDayChecked = event.target.checked;

    this.setState(
      {
        slots: slots,
        availableWeekDays: availableWeekDays,
      },
      () => console.log(this.state.slots)
    );
  }

  // daysHandler
  handleDaysChange(event, newDays, mapIndex) {
    let { slots } = this.state;
    // Check if days exists in master state of 7 days. Then proceed with updating state
    // 1. Adding/Toggling extra day
    if (newDays?.length > slots[mapIndex].days?.length) {
      let { availableWeekDays } = this.state;
      // Block the newly added days from the resource
      newDays.forEach((day) => {
        availableWeekDays[day] = false;
      });

      slots[mapIndex].days = [...newDays];
      if (newDays?.length === 7) {
        slots[mapIndex].isAllDayChecked = true;
      }
      this.setState({
        slots: slots,
        availableWeekDays: availableWeekDays,
      });
    }
    // 2. Removing a day
    else if (newDays?.length < slots[mapIndex].days?.length) {
      let { availableWeekDays } = this.state;
      let removedDay = slots[mapIndex].days?.filter(
        (day) => !newDays.includes(day)
      );
      // Unblock the removed days from the resource
      removedDay.forEach((day) => {
        availableWeekDays[day] = true;
      });

      slots[mapIndex].days = [...newDays];
      this.setState({
        slots: slots,
        availableWeekDays: availableWeekDays,
      });
    }
  }

  // timeZonehandler
  handleTimeZoneChange(zone, mapIndex) {
    let { slots } = this.state;
    console.log(slots);


    if (Array.isArray(slots)) {
      slots[mapIndex].timeZone = zone;
      this.setState({
        slots: slots,
      });
    }
    if(this.validationsForSameTime(mapIndex, slots) && this.validationsForSameTime(mapIndex, slots).length > 0){
      this.setState({isError:true})
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "You already selected this time on this day",
        severity: alertProps.severity.error,
      });
    }
    else{
      this.setState({isError:false})
    }
  }

  addSlotHandler = (event, mapIndex) => {
    this.setState((prevState) => ({
      slots: [
        ...prevState.slots,
        {
          id: uuidv4(),
          isAllDayChecked: false,
          days: [],
          timeSlot: [null, null],
          timeZone: null,
        },
      ],
    }));
  };

  removeSlotHandler(mapIndex) {
    let { slots } = this.state;

    // Before removing the slot from the array. Do the cleanup first.
    let { availableWeekDays } = this.state;
    slots[mapIndex].days.forEach((day) => {
      availableWeekDays[day] = true;
    });

    // Now, remove the current slot from the array.
    slots.splice(mapIndex, 1);

    this.setState({
      slots: slots,
      availableWeekDays: availableWeekDays,
    });
  }

  // Handle Time slot change (using seperate fields for time slots)
  timeSlotHandler = (newTime, mapIndex, index) => {
    let { slots } = this.state;
    if (Array.isArray(slots)) {
      slots[mapIndex].timeSlot[index] = newTime;
      this.setState({
        slots: slots,
      });
    }
    if(this.validationsForSameTime(mapIndex, slots) && this.validationsForSameTime(mapIndex, slots).length > 0){
      this.setState({isError:true})
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "You already selected this time on this day",
        severity: alertProps.severity.error,
      });
    }
    else{
      this.setState({isError:false})
    }
  };

  handlePersonaChange = (newPersonas) => {
    this.setState({
      personas: [newPersonas] ?? [],
    });
  };

  checkSlotsInDaysHaveSameTime = (slots) => {
    let objectByDays = {};
    if (slots.length > 0) {
      slots.map((val) => {
        val.days.map((days) => {
          objectByDays[days] = objectByDays[days]
            ? [...objectByDays[days]]
            : [];
          objectByDays[days] = [...objectByDays[days], val];
          return true;
        });
        return val;
      });

      let isDaysExistsToCompareTime = Object.keys(objectByDays).filter(
        (val) => objectByDays[val]?.length > 1
      ); // Eg: ["monday", "tuesday"]
      console.log(isDaysExistsToCompareTime);

      if (isDaysExistsToCompareTime?.length > 0) {
        return isDaysExistsToCompareTime.map((day, i) => {
          //day = monday
          return objectByDays[day].map((data, i) => {
            // data ={id:"",...}
            let _updateObj = objectByDays.monday.filter(
              (val1) => data.id !== val1.id
            );
            let result = _updateObj.filter((val2) => {
              let _valTime = new Date(data.timeSlot[0]).setSeconds(0);
              let _val2Time = new Date(val2.timeSlot[0]).setSeconds(0);
              let _valToTime = new Date(data.timeSlot[1]).setSeconds(0);
              let _val2ToTime = new Date(val2.timeSlot[1]).setSeconds(0);
              const checkIsBetween = (selectedTime, fromTime, toTime) =>
                moment(selectedTime).isBetween(fromTime, toTime);
              const checkIsSame = (date, data) => moment(date).isSame(data);

              console.log(checkIsSame(_valTime, _val2Time));
              console.log(checkIsSame(_valToTime, _val2ToTime));
              console.log(checkIsBetween(_valTime, _val2Time, _val2ToTime));
              console.log(checkIsBetween(_valToTime, _val2Time, _val2ToTime));
              if (
                checkIsSame(_valTime, _val2Time) ||
                checkIsSame(_valToTime, _val2ToTime) ||
                checkIsBetween(_valTime, _val2Time, _val2ToTime) ||
                checkIsBetween(_valToTime, _val2Time, _val2ToTime)
              ) {
                this.props.alert.setSnack({
                  open: true,
                  horizontal: alertProps.horizontal.center,
                  vertical: alertProps.vertical.top,
                  msg: "You already selected this time on this day",
                  severity: alertProps.severity.error,
                });
                return false;
              } else {
                return true;
              }
            });
            return result;
          });
        }); // {mon:[{},{}]}
      } else return true;
    } else {
      return false;
    }
  };

  // Validation Handler
  handleValidation = () => {
    // Needed to return to avoid error Checking
    // If available247 is true, then don't validate other fields. Just return true.
    // if(this.state.available247){
    //   // Cleanup for exception errors
    //   this.setState({
    //     exceptionError: [],
    //   })
    // }
    if (this.state.available247 && this.state.personas?.length !== 0) {
      return true;
    }
    const { slots } = this.state;
    // console.log((this.checkSlotsInDaysHaveSameTime(slots)).flat(), this.checkSlotsInDaysHaveSameTime(slots).flat().length > 0, "this.checkSlotsInDaysHaveSameTime(slots)")
    // 
    // if(this.checkSlotsInDaysHaveSameTime(slots) && this.checkSlotsInDaysHaveSameTime(slots).flat().length > 0){
    //   return true
    // }
    let globalError = false;
    let error = {};
    let exceptionError = Array.from(new Array(slots?.length));
    exceptionError = exceptionError.map((slot, mapIndex) => {
      let thisError = {
        toggleGroupError: {
          error: false,
          message: "",
        },
        fromTimeError: {
          error: false,
          message: "",
        },
        toTimeError: {
          error: false,
          message: "",
        },
        timeZoneError: {
          error: false,
          message: "",
        },
      };

      // Checking Toggle group error
      if (slots?.[mapIndex]?.days?.length === 0) {
        thisError["toggleGroupError"] = {
          error: true,
          message: "Please choose atleast one operating day.",
        };
        globalError = true;
      }

      if(slots?.[mapIndex]?.timeSlot?.[0] && slots?.[mapIndex]?.timeSlot?.[1]){
        let fromTime = moment(slots?.[mapIndex]?.timeSlot?.[0])
        let toTime = moment(slots?.[mapIndex]?.timeSlot?.[1])
        
        if(toTime.diff(fromTime, 'minutes') < 30){
          thisError["fromTimeError"] = {
            error: true,
            message: "Start Time should have atleast 30 mins difference."
          };
          thisError["toTimeError"] = {
            error: true,
            message: "End Time should have atleast 30 mins difference."
          };
          globalError = true;
        }
      }

      // Checking From time error
      if (slots?.[mapIndex]?.timeSlot?.[0] === null) {
        thisError["fromTimeError"] = {
          error: true,
          message: "Please choose start time.",
        };
        globalError = true;
      }

      // Checking To time error
      if (slots?.[mapIndex]?.timeSlot?.[1] === null) {
        thisError["toTimeError"] = {
          error: true,
          message: "Please choose end time.",
        };
        globalError = true;
      }

      // Checking time Zone error
      if (slots?.[mapIndex]?.timeZone === null) {
        thisError["timeZoneError"] = {
          error: true,
          message: "Please choose a time zone.",
        };
        globalError = true;
      }

      return thisError;
    });

    if (this.state.personas?.length === 0) {
      globalError = true;
      error["personas"] = true;
    }

    if (!globalError) {
      if (!this.state.available247 && this.state.slots?.length === 0) {
        globalError = true;
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Please provide a time slot or select available 24/7",
          severity: alertProps.severity.error,
        });
      }
    }

    this.setState({
      exceptionError: exceptionError,
      error: error,
    });

    // Validation Succeeded returns true
    // Validation Failed returns false
    return !globalError;
  };

  // Submit Handlers
  handleExceptionSubmit = () => {
    if (this.handleValidation()) {
      if (this.props.isEdit) {
        const data = JSON.parse(JSON.stringify(this.props?.getException?.data));
        let dbPersonas = [...data?.[0]?.exceptions?.personas?.data];
        let payload = {
          available247: this.state.available247,
          operationalHours: this.state.available247 ? [] : this.state.slots,
          exceptionId: this.props?.match?.params?.id,
          personas: {
            added:
              this.state.personas.filter(
                (o1) => !dbPersonas.some((o2) => o1.value === o2.value)
              ) ?? [],
            removed:
              dbPersonas.filter(
                (o1) => !this.state.personas.some((o2) => o1.value === o2.value)
              ) ?? [],
            data: this.state.personas,
          },
        };
        
        // Change to Exception action
        this.props.UPDATE_EXCEPTION(payload);
      } else {
        
        let payload = {
          available247: this.state.available247,
          operationalHours: this.state.available247 ? [] : this.state.slots,
          providerId: this.props?.authdata?.user_id,
          personas: {
            added: [],
            removed: [],
            data: this.state.personas,
          },
        };
        
        // Change to Exception action
        this.props.CREATE_EXCEPTION(payload);
      }
    } else {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please clear field errors.",
        severity: alertProps.severity.error
      });
    }
  };

  render() {
    const {
      data:  personasOptions ,
    } = this.props?.getServiceProfByProviderId;
    const { data, loading, error } = this.props?.getException;

    return (
      <div style={{ padding: 20 }}>
        {error && !loading && <InternatlServerError />}
        {loading && !error && <LoadingSection message="Fetching data..." />}
        {!error && !loading && data && (
          <div style={{ padding: 20, background: "white" }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant={"h6"} style={{ marginBottom: 8 }}>
                  Exception
                </Typography>
              </Grid>

              <Grid item xs={12} container spacing={1}>
                {/* Personas Field */}
                <>
                  <Grid item xs={12} md={6}>
                    <SimpleSelect
                      placeholder=""
                      label="Service Professional"
                      options={personasOptions}
                      value={this.state.personas}
                      handleOnChange={this.handlePersonaChange}
                      // isMulti={true}
                      error={
                        this.state.error?.["personas"]
                          ? this.state.error?.["personas"]
                          : false
                      }
                      errorText={"Please choose a persona"}
                    />
                  </Grid>
                </>
              </Grid>

              <Grid item xs={12} style={{ margin: "16px 0px 4px 0px" }}>
                <HeaderWithSwitch
                  checked={this.state.available247}
                  handleSwithIconToggle={(e) => this.handleSwithIconToggle(e)}
                  headerTitle={"Operational Hours"}
                  switchLabel={"Available 24/7"}
                  responsive
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  margin: "12px 0px",
                  borderBottom: `0.5px dashed rgba(146, 151, 165, 0.5)`,
                }}
              ></Grid>

              {this.state.slots?.length === 0 ? (
                <Grid item xs={12} sm={5} md={5} lg={4} xl={4}>
                  <Button
                    disabled={this.state.available247}
                    variant="contained"
                    color="primary"
                    onClick={this.addSlotHandler}
                    style={{ margin: "20px 0px" }}
                  >
                    + Add Time Slot
                  </Button>
                </Grid>
              ) : null}

              {/* Add Time slots here */}
              {this.state.slots.map((slots, mapIndex) => {
                return (
                  <Grid item xs={12}>
                    {/* { console.log(this.state.slots[mapIndex])} */}
                    <TimeSlot
                      availableWeekDays={this.state.availableWeekDays}
                      availableAllDay={
                        this.state.slots[mapIndex]?.isAllDayChecked
                      }
                      handleAvailableAllDayChange={(e) =>
                        this.handleAvailableAllDayChange(e, mapIndex)
                      }
                      daysGroup={this.state.slots[mapIndex]?.days}
                      handleDaysChange={(e, newDays) =>
                        this.handleDaysChange(e, newDays, mapIndex)
                      }
                      timeSlot={this.state.timeSlot}
                      handleTimeSlotChange={() => this.handleTimeSlotChange()}
                      // timeZone={this.state.timeZone}
                      timeZone={this.state.slots[mapIndex]?.timeZone}
                      handleTimeZoneChange={(e) =>
                        this.handleTimeZoneChange(e, mapIndex)
                      }
                      disableTimeZone={this.state.available247}
                      showAddSlotButton={
                        mapIndex === this.state.slots.length - 1 ? true : false
                      }
                      disableAddSlotButton={
                        this.state.available247
                          ? true
                          : typeof this.state.availableWeekDays === "object" &&
                            Object.values(this.state.availableWeekDays).some(
                              (day) => day
                            )
                          ? false
                          : true
                      }
                      // hideRemoveButton={mapIndex === 0 ? true : false}
                      hideRemoveButton={false}
                      addSlotHandler={(e) => this.addSlotHandler(e, mapIndex)}
                      removeSlotHandler={() => this.removeSlotHandler(mapIndex)}
                      timeSlotTitle={`Time Slot ${mapIndex + 1}`}
                      isDisabled={this.state.available247}
                      disableTime={this.state.slots[mapIndex]?.isAllDayChecked}
                      useSeperateFieldsForTimeRange={true}
                      disableFromTimeField={this.state.available247}
                      fromTimeLabel={"START TIME"}
                      fromTimeValue={this.state.slots[mapIndex]?.timeSlot?.[0]}
                      onFromTimeChange={(e) =>
                        this.timeSlotHandler(e, mapIndex, 0)
                      }
                      disableFromTimePast={null}
                      fromTimeMin={null}
                      disableToTimeField={this.state.available247}
                      toTimeLabel={"END TIME"}
                      toTimeValue={this.state.slots[mapIndex]?.timeSlot?.[1]}
                      onToTimeChange={(e) =>
                        this.timeSlotHandler(e, mapIndex, 1)
                      }
                      disableToTimePast={null}
                      toTimeMin={this.state.slots[mapIndex]?.timeSlot?.[0]}
                      toggleGroupError={
                        this.state.exceptionError[mapIndex]?.toggleGroupError
                      }
                      fromTimeError={
                        this.state.exceptionError[mapIndex]?.fromTimeError
                      }
                      toTimeError={
                        this.state.exceptionError[mapIndex]?.toTimeError
                      }
                      timeZoneError={
                        this.state.exceptionError[mapIndex]?.timeZoneError
                      }
                    />
                  </Grid>
                );
              })}

              <Grid item xs={12} container>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "40px 0px 20px",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ width: 100, marginRight: 24, padding: "5px 15px" }}
                    onClick={() =>
                      this.props.history.push(routes.manage_availability)
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: 100, padding: "5px 15px" }}
                    onClick={this.handleExceptionSubmit}
                    disabled={this.state.isError}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authdata: state?.authState?.loginAuth?.data,
  getException: state?.exception?.get_exception,
  createException: state?.exception?.add_exception,
  updateException: state?.exception?.update_exception,
  getServiceProfByProviderId: state?.exception?.get_service_professional_by_id,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAlelrtAndDialog(AddEditException)));
