import * as React from "react"

export function StarIcon(props) {
  return (
    <svg width={22.08} height={21} viewBox="0 0 22.08 21" {...props}>
      <path
        fill="#f27d35"
        d="M22.08 8h-8.27l-2.77-8-2.77 8L0 8.021l6.559 5.043L4.217 21l6.823-4.679L17.863 21l-2.342-7.935zm-11.04 5.9V5.974L12.384 10h4.014l-3.183 2.315 1.137 3.851z"
      />
    </svg>
  )
}

