import React from "react";

import {
  Divider,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { NotFoundSection } from "../../../../components";
import ProductTimeline from "../../../../components/productTimeline";

const styles = (theme) => ({});

class ViewSequence extends React.Component {
  render() {
    const { sequenceData: data } = this.props;
    // 
    console.log(data?.order_sequence)
    const orderData = data?.order_sequence?.length > 0 ? data?.order_sequence?.filter(val => val.strict) : [];
    const bookingData = data?.order_sequence?.length > 0 ? data?.order_sequence?.filter(val => !val.strict) : [];

    return (
      <div>
        {!data && <NotFoundSection message="No data Found" />}

        {data && (
          <>
            <Grid container style={{ padding: "8px 25px " }}>
              <Grid item xs={12}>
                <Typography variant={"h6"}>Product Sequence</Typography>
              </Grid>
            </Grid>

            <Divider light />

            {orderData?.length > 0 && 
            <Grid container style={{ padding: "8px 0px 25px 25px" }}>
              <Grid item xs={12}>
                <Typography variant={"subtitle1"} >Strict Sequential order</Typography>
              </Grid>

              <Grid item xs={12}>
                <ProductTimeline sequenceData={orderData ?? []} />
              </Grid>
            </Grid>
            }

            {bookingData?.length > 0 && 
            <Grid container style={{ padding: "8px 0px 25px 25px" }}>
              <Grid item xs={12}>
                <Typography variant={"subtitle1"} >Booking Sequence</Typography>
              </Grid>

              <Grid item xs={12}>
                <ProductTimeline lineHidden  sequenceData={bookingData ?? []} />
              </Grid>
            </Grid>
            }
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ViewSequence);
