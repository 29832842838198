import React from "react";

import { Grid, Typography, makeStyles, Button, fade } from "@material-ui/core";

import DoneIcon from "@material-ui/icons/Done";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useHistory } from "react-router-dom";
import { routes } from "../../router/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    // background: 'white',
    borderRadius: 8,
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04)",
    height: 475,
    backgroundImage: props => `url('/svg/productDetailSvg/${props.background}.svg')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor: 'white'
  },
  title: {
    textAlign: "center",
    fontSize: 18,
    textTransform: "uppercase",
    letterSpacing: 1.5,
    color: '#353596',
    "&:after": {
      content: `""`,
      display: "block",
      position: "absolute",
      height: 3,
      width: 50,
      background: (props) => fade(props.color,0.8), //do this inline so that it can be dynamic
      left: "50%",
      transform: `translateX(-50%)`,
      marginTop: 8,
    },
  },
  amount: {
    textAlign: 'center',
    fontSize: 28,
    fontWeight: 700,
    marginTop: 32,
  },
  duration: {
    textAlign: 'center',
    fontSize: 14,
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
  flexContainerForList: {
    display: "flex",
    justifyContent: "center",
    height: 264,
    overflow: "scroll",
  },
  listContainer: {
    marginTop: 32,
  },
  descSingleItemContainer: {
    display: 'flex',
    margin: '8px 0 8px 0',
    justifyContent: 'flex-start',
  },
  iconContainer: {
    marginRight: 10,
  },
  icon: {
    fill: "#31B198",
  },
  descItem: {
    fontSize: 14
  },
  buttonGridContainer: {
    display: "flex",
    justifyContent: "center",
  },
  validityType:{
    textAlign: 'center',
    fontSize: 18,
  },
  viewButton: {
    // marginTop: 80,
  },
}));

const PriceCard = ({versionId=0, ...props}) => {
  const classes = useStyles(props);

  const history = useHistory();
  
  return (
    <Grid
      container
      // direction="column"
      alignItems={"stretch"}
      justify="space-between"
      className={classes.root}
    >
      <Grid item xs={12} style={{ position: "relative" }}>
        <Typography className={classes.title}>{props.label}</Typography>
        <Typography className={classes.amount}>
          {`${props?.currency ?? "RM"} ${parseFloat(props.total).toFixed(2)}`}
        </Typography>
        {props?.periodicity && props?.periodicity?.length > 0 ?
        <Typography className={classes.validityType}>
          {"( " +props?.periodicity+ " )"}
        </Typography>: ""}
        {/* <Typography className={classes.duration}>{props.type}</Typography> */}

        {/* Features list */}
        <div className={classes.flexContainerForList}>
          <div className={classes.listContainer}>
            {props.services &&
              props.services.map((service) => {
                return (
                  <div className={classes.descSingleItemContainer}>
                    <span className={classes.iconContainer}>
                      <DoneIcon className={classes.icon} />
                    </span>
                    <Typography className={classes.descItem}>
                      {service.label}
                    </Typography>
                  </div>
                );
              })}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.buttonGridContainer}>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowRightAltIcon />}
          fullWidth
          className={classes.viewButton}
          onClick={()=>history.push(routes.manage_package+"/view/"+props.value + "/" + versionId)}
        >
          View Package
        </Button>
      </Grid>
    </Grid>
  );
};

export default PriceCard;
