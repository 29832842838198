import * as React from "react"
import { SvgIcon, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || theme.palette.primary.main,
  })
}))

function MonitorCallIcon(props) {
  const classes = useStyles(props)
  return (
    <SvgIcon fontSize={"small"}
      {...props}
      style={props.style}>
      <path
        className={classes.cls1}
        d="M17.023 12.488a15.077 15.077 0 01-1.924-.14 14.67 14.67 0 01-1.653-.425 1.017 1.017 0 00-1.019.253l-2.217 2.2a15.376 15.376 0 01-3.8-2.792 15.376 15.376 0 01-2.792-3.8l2.2-2.218a1.017 1.017 0 00.253-1.019A14.6 14.6 0 015.653 2.9 15.067 15.067 0 015.512.976 1 1 0 004.51 0H1a.992.992 0 00-1 1 17.179 17.179 0 004.966 12.034A17.179 17.179 0 0017 18a.992.992 0 001-1v-3.51a1 1 0 00-.977-1.002z"
      />
    </SvgIcon>
  )
}

export default MonitorCallIcon

MonitorCallIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};