import * as React from "react"

export const TickIcon = (props) => {
    let width = props.width ? props.width : "74";
    let height = props.height ? props.height : "74";
    let tickColor = props.color ? props.color : "#fff";
    let bgColor = props.color ? props.color : "#31b198";
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 74 74" {...props}>
            <g id="Group_1819" data-name="Group 1819" transform="translate(0.209 0.092)">
                <circle id="Ellipse_10" data-name="Ellipse 10" cx="37" cy="37" r="37" transform="translate(-0.209 -0.092)" fill={bgColor} />
                <path id="Path_41" data-name="Path 41" d="M-1568.565,3883.33l9.637,9.636,20.416-20.416" transform="translate(1589.919 -3845.522)" fill="none" stroke={tickColor} stroke-linecap="round" stroke-linejoin="round" stroke-width="5" />
            </g>
        </svg>
    );
};
