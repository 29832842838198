import React from "react";
import {
    FormControl,
    IconButton,
    InputAdornment,
    InputBase,
    withStyles,
    makeStyles,
    Popper,
    Typography,
    Fade,
    Paper,
    Button,
    Slider,
    Grid,
    // TextField,
} from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ClickAwayListener from "material-ui/internal/ClickAwayListener";

const BootstrapInput = withStyles((theme) => ({
    root: {
        // "label + &": {
        //     marginTop: theme.spacing(2),
        // },
        borderRadius: 10,
        border: (props) =>
            `${theme.form.borderSize} solid ${props.error
                ? theme.palette.error.main
                : theme.palette.secondary.borderColor
            }`,
        "&$focused": {
            border: (props) =>
                `${theme.form.borderSize} solid ${props.error ? theme.palette.error.main : theme.palette.ternary.main
                }`,
        },
        "&$disabled": {
            background: '#ECECEC',
            border:0
        },
        '& .Mui-disabled': {
            background: '#ECECEC',
        }
    },
    // Don't remove this class "focused","disabled"
    focused: {},
    disabled: {},
    input: {
        borderRadius: 10,
        position: "relative",
        backgroundColor: theme.palette.common.white,
        // border: `${theme.form.borderSize} solid ${theme.palette.secondary.borderColor}`,
        fontSize: 14,
        color:'#252B43',
        fontWeight:500,
        width: "100%",
        padding: (props) => (props.padding ? props.padding : "12.2px 12px"),
        height: "20.4px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
    },
}))(InputBase);

const DurationSlider = withStyles((theme) => ({
    root: {
        color: '#707070',
        height: 5,
    },
    thumb: {
        height: 9,
        width: 9,
        backgroundColor: "#FFA511",
        marginTop: -2,
        marginLeft: -5,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        height: 5,
        borderRadius: 2,
        backgroundColor:'#FFA511'
    },
    rail: {
        height: 5,
        borderRadius: 2,
    },
}))(Slider);

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: "8px 0px 8px 0px",
        "& .MuiFormLabel-root.Mui-focused": {
            color: "unset !important",
        },
        "& .MuiInputLabel-asterisk": {
            color: theme.palette.error.main,
        },
    },
    showTime: {
        width: "100%",
        textAlign: "center",
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        marginBottom:theme.spacing(2)
    },
    timeRoot: {
        display: "inline-block",
        borderRadius: 3,
    },
    time: {
        minWidth:48,
        height: 48,
        fontSize: 18,
        backgroundColor: theme.palette.primary.main,
        textAlign: "center",
        color: "white",
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        fontWeight:500
    },
    seperator: {
        fontSize: 32,
        fontWeight: 700,
        color: theme.palette.primary.main,
        minWidth:46,
        height: 48,
        textAlign: "center",
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
    },
    labelStyle: {
        fontSize: 14,
        fontWeight: 400,
        color: '#9297A5',
        flex: 1,
        '& .MuiTypography-caption': {
            color: '#F76E8E',
            fontWeight: 400
        }
    },
    editText: {
        fontSize: 14,
        fontWeight: 400,
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    cancelBtn:{
        borderColor:'#E0E0E0',
        color:'#FF4D4A',
        minWidth:101,
        textTransform:'capitalize'
    },
    saveBtn:{
        minWidth:84,
        textTransform:'capitalize'
    },
    sliderLabel:{
        fontSize:14,
    },
    actionBtns:{
        marginTop:theme.spacing(3),
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        gap:12
    },
    paper:{
        width:320,
        boxShadow:'0px 15px 50px #0000002e',
        borderRadius:8
    }
}));

const sliderType = {
    hours: "hours",
    minutes: "minutes",
};

export const DurationPicker = ({
    label,
    value,
    handleOnSave,
    isRequired,
    error,
    id,
    isEdit=false,
    disabled = false,
    isReadOnly = false,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [hours, setHours] = React.useState(0);
    const [minutes, setMinutes] = React.useState(0);
    const [durationText, setDurationText] = React.useState("");
    //isEdit State
    const [isDisabled,setIsDisabled] = React.useState(disabled);
    const classes = useStyles();

    React.useEffect(() => {
        const durationInt = parseInt(value ? value : 0);
        const hours = parseInt(durationInt / 60);
        const minutes = parseInt(durationInt % 60);
        setHours(hours);
        setMinutes(minutes);
        let duration = `${hours > 0 ? hours : ""}${hours > 0 ? (hours <= 1 ? " Hr" : " Hrs") : ""
            }${minutes > 0 ? (hours > 0 ? " " : "") + minutes : ""}${minutes > 0 ? (minutes <= 1 ? " Min" : " Mins") : ""
            }`;
        setDurationText(duration);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePopperStatus = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        if (anchorEl) {
            setAnchorEl(null);
        }
    };

    const handleSliderClick = (whichSlider, newValue) => {
        switch (whichSlider) {
            case sliderType.hours: {
                setHours(newValue);
                break;
            }
            case sliderType.minutes: {
                setMinutes(newValue);
                break;
            }
            default:
                return false;
        }
    };

    const handleDurationInputChange = (e) => {

        // 19
        // 7
        // 11 Hours 22 Minutes

        let value = e?.target?.value ?? "";

        const validator = (value) => {

            if (value?.length > 8) {
                if (value?.length > 10) {
                    if (value?.length === 11) {
                        let minutes = value.split(" ")[2];
                        let minutes_appender =
                            minutes === 0 ? " minutes" : minutes === 1 ? " Min" : " Mins";
                        return value?.[11] > 9 ? value?.[10] : value.concat(minutes_appender);
                    } else return value.slice(0, 1);
                }
                else if (value?.length === 10) {
                    return value?.[10] > 6 ? value?.[9] : value
                }
                else return value;
            } else if (value?.length === 8) return value.slice(0, 1);
            else if (value?.length === 2) {
                let hours = value.split(" ")[0];
                let hours_appender =
                    hours === 0 ? " hours " : hours === 1 ? " Hr " : " Hrs ";
                return value?.[1] > 4 ? value?.[0] : value.concat(hours_appender);
            } else return value?.[0] > 2 ? "" : value;
        };

        if (value?.length < 20) {
            let _getValue = validator(value);
            let hours = _getValue.split(" ")[0];
            let minutes = _getValue.split(" ")[2];
            setHours(hours);
            setMinutes(minutes);
            setDurationText(_getValue);
        }
    };

    const handleKeyPress = (e) => {

        if (
            !(
                (e.which >= 48 && e.which <= 57) ||
                e.which === 8 ||
                (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true))
            )
        )
            e.preventDefault();
    };

    const onDurationSave = () => {
        setDurationText(
            `${hours > 0 ? hours : ""}${hours > 0 ? (hours <= 1 ? " Hr" : " Hrs") : ""
            }${minutes > 0 ? (hours > 0 ? " " : "") + minutes : ""}${minutes > 0 ? (minutes <= 1 ? " Min" : " Mins") : ""
            }`
        );
        handlePopperStatus();
        let duration = hours * 60 + minutes;
        handleOnSave(parseInt(duration) === 0 ? "" : duration + "");
    };

    const open = Boolean(anchorEl);
    return (
        <>
            <FormControl className={classes.root}>
                {label &&
                    <div className={classes.header}>
                        <Typography
                            className={classes.labelStyle}
                        >
                            {label}{isRequired && <Typography variant="caption">&nbsp;*</Typography>}
                        </Typography>
                        {isEdit && <Typography
                            className={classes.editText}
                            onClick={()=>setIsDisabled(false)}
                            id={`${id}Edit`}
                        >
                            {'Edit'}
                        </Typography>}

                    </div>
                }
                <BootstrapInput
                    id={id}
                    // onClick={handlePopperStatus}
                    onKeyDown={(e) => {
                        handleKeyPress(e);
                    }}
                    onKeyPress={(e) => {
                        handleKeyPress(e);
                    }}
                    onChange={(e) => {
                        handleDurationInputChange(e);
                    }}
                    readOnly={isReadOnly}
                    disabled={isDisabled}
                    value={durationText}
                    endAdornment={
                        <>{(!isDisabled || isReadOnly) &&
                            <InputAdornment position="end">
                                <IconButton onClick={handlePopperStatus} id={`${id}CalenderIcon`}>
                                    <ScheduleIcon htmlColor="rgba(0, 0, 0, 0.24)" fontSize="small"/>
                                </IconButton>
                            </InputAdornment>
                        }</>
                    }
                    error={error}
                />
            </FormControl>

            <Popper
                open={open}
                anchorEl={anchorEl}
                placement={"bottom"}
                transition
                modifiers={{
                    flip: {
                        enabled: true,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: "scrollParent",
                    },
                }}
                disablePortal
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper classes={{
                                root:classes.paper
                            }}>
                                <div style={{ padding: 20 }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className={classes.showTime}>
                                                <span className={`${classes.time} ${classes.timeRoot}`}>
                                                    {hours}
                                                </span>
                                                <span className={classes.seperator}>:</span>
                                                <span className={`${classes.time} ${classes.timeRoot}`}>
                                                    {minutes}
                                                </span>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={classes.sliderLabel} color="textSecondary">Hours:</Typography>
                                            <DurationSlider
                                                // valueLabelDisplay="auto"
                                                value={hours}
                                                onChange={(event, newValue) =>
                                                    handleSliderClick(sliderType.hours, newValue)
                                                }
                                                max={24}
                                                id={`${id}HoursSlider`}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={classes.sliderLabel} color="textSecondary">Minutes:</Typography>
                                            <DurationSlider
                                                // valueLabelDisplay="auto"
                                                aria-label="pretto slider"
                                                value={minutes}
                                                onChange={(event, newValue) =>
                                                    handleSliderClick(sliderType.minutes, newValue)
                                                }
                                                max={59}
                                                id={`${id}minutesSlider`}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className={classes.actionBtns}>
                                                <Button
                                                    onClick={handlePopperStatus}
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.cancelBtn}
                                                    id={`${id}CancelButton`}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={onDurationSave}
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.saveBtn}
                                                    id={`${id}SaveButton`}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
}
