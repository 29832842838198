import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles((theme)=>({
    root:{

    },
    labelStyle:{
        fontSize:14,
        fontWeight:400,
        marginBottom:8,
        color:'#878C9A',
        '& .MuiTypography-caption':{
            color:'#F76E8E',
            fontWeight:400
        }
    },
    textField:{
        '&:hover':{
            '& .MuiOutlinedInput-notchedOutline':{
                borderColor:theme.palette.primary.main
            }   
           },
        '& input':{
            padding:'15.69px 12.190px !important',
            fontWeight:500,
            fontSize:14,
            '&::placeholder':{
                fontWeight:400,
            }
        },
        '& .Mui-disabled':{
            background:'#F1F1F1',
            borderRadius:10,
            '& .MuiOutlinedInput-notchedOutline':{
                 borderColor:'#F1F1F1',
            },
            '&:hover':{
             '& .MuiOutlinedInput-notchedOutline':{
                 borderColor:'transparent',
            },  
            }
        },
        '& .MuiOutlinedInput-notchedOutline':{
            borderColor:'#C7C7C7',
            borderRadius:10,
        },
        '& .MuiFormHelperText-root':{
            marginInline:0
        }
    }
}));

export const Input = (props) =>{

    const classes = useStyles(props);

    const {
        handleChange=null,
        id="",
        value="",
        disabled=false,
        label="",
        isRequired=false,
        multiline=false,
        placeHolder="",
        variant="outlined",
        error=false,
        helperText="",
        maxLength=null,
        isReadOnly=false
    } = props;
    

    return(
        <div className={classes.root}>
            {label &&
            <Typography
                className={classes.labelStyle}
            >
                {label}{isRequired && <Typography variant="caption">&nbsp;*</Typography>}
            </Typography>}
            <TextField
                id={`textField${id}`}
                value={value}
                onChange={(e)=>handleChange(e.target.value)}
                variant={variant}
                className={classes.textField}
                disabled={disabled}
                multiline={multiline}
                placeholder={placeHolder}                
                error={error} 
                helperText={error ? helperText : ''}
                fullWidth
                inputProps={{
                    maxLength:maxLength,
                    readOnly:isReadOnly
                }}
            />
        </div>
    )
}

Input.propTypes={
    handleChange:PropTypes.func,
    id:PropTypes.string,
    value:PropTypes.string,
    disabled:PropTypes.bool,
    label:PropTypes.bool,
    isRequired:PropTypes.bool,
    multiline:PropTypes.bool,
    placeHolder:PropTypes.string,
    variant:PropTypes.oneOf(['filled','outlined','standard']),
    error:PropTypes.bool,
    helperText:PropTypes.string,
    maxLength:PropTypes.number,
    isReadOnly:PropTypes.bool,
}