import * as React from "react";
import { SvgIcon } from "@material-ui/core";

export const TextIcon = (props) => {

    const {
        color = "#3b3f5b" //#0063e7
    } = props;

    return (
        <SvgIcon
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="Group_95543" data-name="Group 95543" transform="translate(-54.44)">
                <g id="Rectangle_48009" data-name="Rectangle 48009" transform="translate(54.44)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path id="icons8-text" d="M4,4V6A1,1,0,0,0,5,7H5A1,1,0,0,0,6,6V5h5V19H10a1,1,0,0,0-1,1H9a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H13V5h5V6a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1H5A1,1,0,0,0,4,4Z" transform="translate(54.44)"
                    fill={color} />
            </g>
        </SvgIcon>
    );
}

