import * as React from "react";
import { SvgIcon } from "@material-ui/core";

export const ShapesIcon = (props) => {

    const {
        color="#3b3f5b"
    } = props;

    return (
        <SvgIcon
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="Group_95507" data-name="Group 95507" transform="translate(-1071 -366)">
                <g id="Component_93_2" data-name="Component 93 – 2" transform="translate(1071 366)">
                    <g id="Rectangle_48007" data-name="Rectangle 48007" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                        <rect width="24" height="24" stroke="none" />
                        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                    </g>
                    <path id="icons8-shapes" d="M26.173,72.29H32.5a5.338,5.338,0,0,0,10.562,0h2.709a.268.268,0,0,0,.262-.274V63.473a.268.268,0,0,0-.262-.274H37.587a.268.268,0,0,0-.262.274v2.1l-3.693-6.582a.257.257,0,0,0-.453,0l-7.232,12.89a.284.284,0,0,0,0,.275A.261.261,0,0,0,26.173,72.29ZM37.778,76.3a5.07,5.07,0,1,1,4.851-5.065A4.962,4.962,0,0,1,37.778,76.3Zm7.728-12.557v8H43.132c.014-.166.022-.334.022-.5a5.509,5.509,0,0,0-5.305-5.612V63.747Zm-12.1-4.079,3.4,6.051a5.563,5.563,0,0,0-4.4,5.52c0,.17.008.338.022.5H26.631Z" transform="translate(-23.965 -55.852)" 
                        fill="#3b3f5b"
                        stroke={color}
                        stroke-width="1"
                    />
                </g>
            </g>
        </SvgIcon>
    );
}