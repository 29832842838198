import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui//styles/makeStyles';
import PreviewIcon from '../../../../../assets/previewIcon.svg';
import PropTypes from 'prop-types';
import { FullScreenIcon,CloseRoundedIcon } from '../../../../../assets';
import { DialogContext } from '../../../../../contexts';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import EditBanner from './editBanner';
import { CustomDialog } from './editBanner/dialog';
import { createTestProps } from '../../../../../utils/common';
import { ConfirmationRequired } from '../../../../../components';

const useStyles = makeStyles((theme)=>({
    labelStyle:{
        fontSize:14,
        fontWeight:400,
        color:'#878C9A'
    },
    description:{
        color:'#878C9A',
        fontWeight:400,
        fontSize:14,
        margin:theme.spacing(3),
        textAlign:'center'
    },
    imgView:{
        width:'100%',
        height:'175px',
        objectFit:'cover',
        // objectPosition:'center',
        borderRadius:6
    },
    imgSection:{
        position:'relative',
        '&:hover':{
            '& img':{
                filter:'blur(2.5px) brightness(0.8)',
                transition:'all 0.2s'
            },
            '& $hoverOnShow':{
                display:'block'
            }
        }
    },
    imgFluid:{
        maxWidth:'100%',
        height:'auto',
    },
    fullViewText:{
        color:theme.palette.primary.main,
        fontSize:14,
        fontWeight:400,
        paddingLeft:theme.spacing(1)
    },
    hoverOnShow:{
        position:'absolute',
        cursor:'pointer',
        top:'50%',
        left:'50%',
        transform:'translate(-50%,-50%)',
        display:'none'
    },
    rowCenter:{
        display:'flex',
        alignItems:'center',
        paddingBlock:'10px',
        paddingInline:theme.spacing(2),
        backgroundColor:'#FFFFFF',
        opacity:0.8,
        justifyContent:'center',
        borderRadius:8
    },
    dialogImgPreview:{
        height:284,
        width:'100%',
        objectFit:'cover',
        objectPosition:'center',
        borderRadius:6
    },
    dialogTitle:{
        fontWeight:500,
        fontSize:18,
        color:'#3B3F5B'
    },
    header:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        // marginBottom:theme.spacing(3)
    },
    editLabel:{
        fontSize:14,
        fontWeight:500,
        color:'#FFA811',
    },
    flexRow:{
        display:'flex',
        alignItems:'center',
        cursor:'pointer'
    },
    editIcon:{
        fontSize:16,
        marginRight:theme.spacing(1),
        color:'#FFA811'
    },
    customDialog:{
        '& .MuiDialogTitle-root':{
            display:'none'
        },
        '& .MuiDialogContent-root':{
            padding:0
        },
        '& .MuiPaper-root':{
            [theme.breakpoints.up('md')]:{
                minWidth:900,
                minHeight:484,
            },
            minWidth:'90%',
        }
    },
    fullViewDialog:{
        '& .MuiDialogTitle-root':{
            display:'none'
        },
        '& .MuiDialogContent-root':{
            padding:24,
            paddingBottom:0
        }
    },
    closeSectionWrapper:{
        '& .title':{
            fontSize:18,
            fontWeight:500,
            marginBottom:theme.spacing(3),
            textAlign:'center'
        },
        '& .desc':{
            fontSize:16,
            fontWeight:400,
            marginBottom:theme.spacing(3),
            textAlign:'center'
        },
    },
    actionBtns:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        gap:theme.spacing(2),
        '& .MuiButtonBase-root':{
            textTransform:'capitalize',
            height:40,
        },
        '& .MuiButton-outlined':{
            minWidth:84,
            // color:'#FF4D4A',
            // borderColor:'#D8D8D8',
        },
        '& .MuiButton-contained':{
            minWidth:113,
        },
    },
    closeDialog:{
        '& .MuiDialogTitle-root':{
            display:'none'
        },
        '& .MuiDialogContent-root':{
            padding:'30px 25px'
        },
        '& .MuiPaper-root':{
            borderRadius:10,
            '& .MuiDialogContentText-root':{
                marginBottom:0
            },
            [theme.breakpoints.up('sm')]:{
                minWidth:386,
                width:386,
            },
            minWidth:'90%',
        }
    },
}));

export const ImagePreview = (props) =>{
    const classes = useStyles(props);

    const dialog = React.useContext(DialogContext);

    const [open,setOpen] = React.useState(false);

    const {
        preview="",
        handleChange=null,
        isReadOnly=false
    } = props;

    const showFullView = () =>{
        dialog.setDialog({
            ...dialog,
            open: true,
            className:{
                Dialog:classes.fullViewDialog
            },
            body: (
              <div style={{paddingBottom:'24px',textAlign:'center'}}>
                  <div className={classes.header} style={{marginBottom:24}}>
                      <Typography className={classes.dialogTitle}>Preview</Typography>
                      <div style={{cursor:'pointer',display:'flex'}} onClick={closeFullView} {...createTestProps("close full view image")}><CloseRoundedIcon/></div>
                  </div>
                  <div style={{display:'flex'}}>
                      <img src={preview} className={classes.dialogImgPreview} alt="img-preview" />
                  </div>
              </div>
            ),
            dontShowButtons:true,
            hideAllButtons:true,
          });
    }

    const editBanner = () =>{
        setOpen(true);
    }

    const closeCustomDialog = () =>{
        setOpen(false)
    }

    const closeFullView = () =>{
        dialog.setDialog({
            open: false
            })
    }

    const cancelEditor = () =>{
        closeFullView();
        closeCustomDialog();
    }

    const onCloseCallback = () =>{
        dialog.setDialog({
            ...dialog,
            open: true,
            checkId:"closeDialogDesc",
            body: (
            <ConfirmationRequired 
                positiveButton={{
                    onClick:cancelEditor
                }}
                negativeButton={{
                    onClick:closeFullView
                }}
            />
            ),
            className:{
                Dialog:classes.closeDialog
            },
            dontShowButtons:true,
            hideAllButtons:true,
          });
    }

    const save = (dataUrl) =>{
        if(dataUrl){
            handleChange(dataUrl)
        }
        closeCustomDialog()
    }

    const cancel = (id) =>{
        if(id){
            onCloseCallback()
        }
        else{
            closeCustomDialog()
        }
    }

    return(
        <div>
            <div className={classes.header} style={{marginBottom:12}}>
                <Typography className={classes.labelStyle}>Preview</Typography>
                {
                (preview && !isReadOnly) &&
                <div className={classes.flexRow} onClick={editBanner} {...createTestProps("edit upload image")}>
                    <CreateOutlinedIcon className={classes.editIcon} />
                    <Typography className={classes.editLabel}>Edit</Typography>
                </div>
                }
            </div>
            {
                preview ? (
                    <div className={classes.imgSection} id="imgSection">
                        <img src={preview} className={classes.imgView} alt="img-preview" />
                        <div className={classes.hoverOnShow} onClick={showFullView} {...createTestProps("full view image")}>
                            <div className={classes.rowCenter}>
                                <FullScreenIcon />
                                <Typography className={classes.fullViewText}>Full View</Typography>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{textAlign:'center'}}>
                        <img src={PreviewIcon} className={classes.imgFluid} alt="preview" />
                        <Typography className={classes.description}>Upload image to preview.</Typography>
                    </div>
                )
            }
            {open && 
            <CustomDialog 
                open={open}
                disableEnforceFocus={true}
                className={{Dialog:classes.customDialog}}
                onClose={closeCustomDialog}
                onCloseCallBack={onCloseCallback}
            >
                <EditBanner
                    src={preview}
                    cancel={cancel}
                    save={save}
                />
            </CustomDialog>
            }
        </div>
    )
}

ImagePreview.propTypes={
    preview:PropTypes.string,
    handleChange:PropTypes.func,
    isReadOnly:PropTypes.bool,
}