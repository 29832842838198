import { masterData } from "../../../utils";

export const actionsCall = (props, state) => {
    
  return {
    1: () => props.GET_SKILLS({ ...state }),
    2: () => props.GET_MASTER_CERT_ORGANIZATION({ ...state }),
    3: () => props.GET_MASTER_EXP_ORGANIZATION({ ...state }),
    4: () => props.GET_CATEGORIES({ ...state }),
    5: () => props.GET_MASTER_CERTIFICATES({ ...state }),
    6: () => props.GET_MASTER_EXP_ROLES({ ...state }),
    7: () => props.GET_MASTER_SPECIALIZATION({ ...state }),
    // 8: () => props.GET_CC({ ...state }),
    8: () => props.GET_LANG({ ...state }),
    // 9: () => props.GET_FREQ({ ...state }),
    9: () => props.GET_SAL({ ...state }),
    10: () => props.GET_LIC({ ...state }),
    // 12: () => props.GET_ALL_VALDITY({ ...state }),
    11: () => props.GET_MASTER_ADDRESS_TYPE({ ...state }), //Address
    12: () => props.GET_MASTER_EMAIL_TYPE({ ...state }), //Email
    13: () => props.GET_MASTER_PHONE_NO_TYPE({ ...state }), //Phone number
    14: () => props.GET_ID_TYPES({ ...state}),
    15: () => props.GET_MASTER_ENQUIRY_SOURCE_OF_REFERRAL({ ...state}),
    // 18: () => props.GET_MASTER_ENQUIRY_STATUS({ ...state}),
    16: () => props.GET_MASTER_LEAD_TYPES({ ...state}),
    17: () => props.GET_MASTER_PRODUCT_MANAGEMENT_GROUP_TYPES({ ...state}),
    18: () => props.GET_MASTER_LEAVE_TYPES({ ...state}),
    19: () => props.GET_MASTER_TAX({ ...state}),
    // 21: () => props.GET_MASTER_UNAVAILABLE_TYPES({ ...state}),
  };
};

export const addMasterCall = (props, state) => {
  console.log(props)
  return {
    1: () => props.ADD_SKILL({ name: state.newMaster }),
    2: () => props.ADD_ISNG_AUTH({ name: state.newMaster }),
    3: () => props.ADD_EMP_ORG({ name: state.newMaster }),
    4: () => props.ADD_CATEGORY({ name: state.newMaster }), //Not exact one //Updated API
    5: () => props.ADD_CERT({ name: state.newMaster }), //Not exact one //Updated API
    6: () => props.ADD_JOB_ROLE({ name: state.newMaster }), //Not exact one //Updated API
    7: () => props.ADD_SPECIALIZATION({ name: state.newMaster }),
    // 8: () => props.ADD_CC({ name: state.countryName, code: state.newMaster }),
    8: () => props.ADD_LANG({ name: state.newMaster }),
    // 9: () => props.ADD_FREQ({ name: state.newMaster }),
    9: () => props.ADD_SAL({ name: state.newMaster }),
    10: () => props.ADD_LIC({ name: state.newMaster }),
    // 12: () => props.ADD_VALDITY({ validityName: state.newMaster, validityDay: state.newMaster2 }),
    11: () => props.ADD_ADDRESS_TYPE({ name: state.newMaster }), 
    12: () => props.ADD_EMAIL_TYPE({ name: state.newMaster }), 
    13: () => props.ADD_PHONE_NO_TYPE({ name: state.newMaster }),
    14: () => props.ADD_ID_TYPES({name: state.newMaster }),
    15: () => props.ADD_MASTER_ENQUIRY_SOURCE_OF_REFERRAL({name: state.newMaster}),
    // 18: () => props.ADD_MASTER_ENQUIRY_STATUS({name: state.newMaster}),
    16: () => props.ADD_LEAD_TYPES({ name: state.newMaster}),
    17: () => props.ADD_MASTER_PRODUCT_MANAGEMENT_GROUP_TYPES({name: state.newMaster}),
    18: () => props.ADD_LEAVE_TYPES({name: state.newMaster}),
    19: () => props.ADD_TAX({name: state.newMaster}),
    // 21: () => props.ADD_UNAVAILABLE_TYPES({name: state.newMaster}),
  };
};

export const editMasterCall = (props, updatedData, previousData) => {
  // const countryPayload = {
  //   id: previousData?.countryId,
  //   name: updatedData?.countryName,
  //   code: updatedData?.countryCode,
  // };
  console.log(previousData.value)
  const payload = { name: updatedData.label, id: previousData.value, isActive:updatedData.isActive };
  // const validityPayload = {
  //   validityId: previousData?.value,
  //   validityName: updatedData?.label,
  //   validityDay: updatedData?.days,
  //   isActive: updatedData?.isActive,
  // }
  
  return {
    1: () => props.UPDATE_SKILL(payload),
    2: () => props.UPDATE_ISNG_AUTH(payload),
    3: () => props.UPDATE_EMP_ORG(payload),
    4: () => props.UPDATE_CATEGORY(payload), //Working Fine
    5: () => props.UPDATE_CERT(payload), //Not exact one
    6: () => props.UPDATE_JOB_ROLE(payload), //Working Fine
    7: () => props.UPDATE_SPECIALIZATION(payload),
    // 8: () => props.UPDATE_CC(countryPayload),
    8: () => props.UPDATE_LANG(payload),
    // 9: () => props.UPDATE_FREQ(payload),
    9: () => props.UPDATE_SAL(payload),
    10: () => props.UPDATE_LIC(payload),
    // 12: () => props.UPDATE_VALDITY(validityPayload),
    11: () => props.UPDATE_ADDRESS_TYPE(payload), //Address
    12: () => props.UPDATE_EMAIL_TYPE(payload), //Email
    13: () => props.UPDATE_PHONE_NO_TYPE(payload), //Phone
    14: () => props.UPDATE_ID_TYPES(payload),
    15: () => props.UPDATE_MASTER_ENQUIRY_SOURCE_OF_REFERRAL(payload),
    // 18: () => props.UPDATE_MASTER_ENQUIRY_STATUS(payload),
    16: () => props.UPDATE_LEAD_TYPES(payload),
    17: () => props.UPDATE_MASTER_PRODUCT_MANAGEMENT_GROUP_TYPES(payload),
    18: () => props.UPDATE_LEAVE_TYPES(payload),
    19: () => props.UPDATE_TAX(payload),
    // 21: () => props.UPDATE_UNAVAILABLE_TYPES(payload)
  };
};

export const deleteMasterCall = (props, rowData) => {
  
  return {
    1: () => props.DELETE_SKILL({ id: rowData.value }),
    2: () => props.DELETE_ISNG_AUTH({ id: rowData.value }),
    3: () => props.DELETE_EMP_ORG({ id: rowData.value }),
    4: () => props.DELETE_CATEGORY({ id: rowData.value }), //Not exact one //Updated API
    5: () => props.DELETE_CERT({ id: rowData.value }), //Not exact one     //Updated API
    6: () => props.DELETE_JOB_ROLE({ id: rowData.value }), //Not exact one //Updated API
    7: () => props.DELETE_SPECIALIZATION({ id: rowData.value }),
    // 8: () => props.DELETE_CC({ id: rowData.countryId }),
    8: () => props.DELETE_LANG({ id: rowData.value }),
    // 9: () => props.DELETE_FREQ({ id: rowData.value }),
    9: () => props.DELETE_SAL({ id: rowData.value }),
    10: () => props.DELETE_LIC({ id: rowData.value }),
    // 12: () => props.DELETE_VALDITY({ validityId: rowData.value }),
    11: () => props.DELETE_ADDRESS_TYPE({ id: rowData.value }), //Address
    12: () => props.DELETE_EMAIL_TYPE({ id: rowData.value }), //Email
    13: () => props.DELETE_PHONE_NO_TYPE({ id: rowData.value }), //Phone
    14: () => props.DELETE_ID_TYPES({ id: rowData.value}),
    15: () => props.DELETE_MASTER_ENQUIRY_SOURCE_OF_REFERRAL({id: rowData.value}),
    // 18: () => props.DELETE_MASTER_ENQUIRY_STATUS({id: rowData.value}),
    16: () => props.DELETE_LEAD_TYPES({ id: rowData.value}),
    17: () => props.DELETE_MASTER_PRODUCT_MANAGEMENT_GROUP_TYPES({ id: rowData.value}),
    18: () => props.DELETE_LEAVE_TYPES({ id: rowData.value}),
    19: () => props.DELETE_TAX({ id: rowData.value})
    // 21: () => props.DELETE_UNAVAILABLE_TYPES({ id: rowData.value})
  };
};

// These StateCalls are used to get the {data, loading, error} from the redux store for specific entities.
// Get State Call
export const stateCall = (props) => {
  return {
    1: () => props.skills,
    2: () => props.cert_organization,
    3: () => props.exp_organization,
    4: () => props.categories,
    5: () => props.certificates,
    6: () => props.exp_roles,
    7: () => props.specialization,
    // 8: () => props.countryCode,
    8: () => props.language,
    // 9: () => props.frequency,
    9: () => props.salutation,
    10: () => props.license,
    // 12: () => props.validity,
    11: () => props.address,
    12: () => props.email,
    13: () => props.phone,
    14: () => props.idType,
    15: () => props.enquiry_source_of_referral,
    // 18: () => props.enquiry_status,
    16: () => props.lead_types,
    17: () => props.product_management_group_types,
    18: () => props.leave_types,
    19: () => props.tax,
    // 21: () => props.unavailable_types
  };
};

// Update/Edit State Call
export const updateStateCall = (props) => {
  return {
    1: () => props.updateSkill,
    2: () => props.updateCertOrg,
    3: () => props.updateExpOrg,
    4: () => props.updateCategory,
    5: () => props.updateCertificate,
    6: () => props.updateExpRole,
    7: () => props.updateSpecialization,
    // 8: () => props.updateCountryCode,
    8: () => props.updateLanguage,
    // 9: () => props.updateFrequency,
    9: () => props.updateSalutation,
    10: () => props.updateLicense,
    // 12: () => props.updateValidity,
    11: () => props.updateAddress,
    12: () => props.updateEmail,
    13: () => props.updatePhone,
    14: () => props.updateIdType,
    15: () => props.update_master_enquiry_source_of_referral,
    // 18: () => props.update_enquiry_status,
    16: () => props.update_lead_types,
    17: () => props.update_product_management_group_types,
    18: () => props.update_leave_types,
    19: () => props.update_tax,
    // 21: () => props.update_unavailable_types
  };
};

// Add State Call
export const addStateCall = (props) => {
  return {
    1: () => props.addSkill,
    2: () => props.addCertOrg,
    3: () => props.addExpOrg,
    4: () => props.addCategory,
    5: () => props.addCertificate,
    6: () => props.addExpRole,
    7: () => props.addSpecialization,
    // 8: () => props.addCountryCode,
    8: () => props.addLanguage,
    // 9: () => props.addFrequency,
    9: () => props.addSalutation,
    10: () => props.addLicense,
    // 12: () => props.addValidity,
    11: () => props.addAddress,
    12: () => props.addEmail,
    13: () => props.addPhone,
    14: () => props.addIdType,
    15: () => props.add_master_enquiry_source_of_referral,
    // 18: () => props.add_enquiry_status,
    16: () => props.add_lead_types,
    17: () => props.add_product_management_group_types,
    18: () => props.add_leave_types,
    19: () => props.add_tax,
    // 21: () => props.add_unavailable_types,
  };
};

// Delete State Call
export const deleteStateCall = (props) => {
  return {
    1: () => props.deleteSkill,
    2: () => props.deleteCertOrg,
    3: () => props.deleteExpOrg,
    4: () => props.deleteCategory,
    5: () => props.deleteCertificate,
    6: () => props.deleteExpRole,
    7: () => props.deleteSpecialization,
    // 8: () => props.deleteCountryCode,
    8: () => props.deleteLanguage,
    // 9: () => props.deleteFrequency,
    9: () => props.deleteSalutation,
    10: () => props.deleteLicense,
    // 12: () => props.deleteValidity,
    11: () => props.deleteAddress,
    12: () => props.deleteEmail,
    13: () => props.deletePhone,
    14: () => props.deleteIdType,
    15: () => props.delete_master_enquiry_source_of_referral,
    // 18: () => props.delete_enquiry_status,
    16: () => props.delete_lead_types,
    17: () => props.delete_product_management_group_types,
    18: () => props.delete_leave_types,
    19: () => props.delete_tax,
    // 21: () => props.delete_unavailable_types
  };
};

let commonColumnSpans = {
  minWidth: {
    xs: "fit-content",
    sm: "fit-content",
    md: "fit-content",
    lg: "fit-content",
    xl: "fit-content",
  },
}

let defaultColumn = [
  {
    id: "sno",
    label: "S.No",
    ...commonColumnSpans
  },
];

export const tableColumn = (props) => {
  return {
    1: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[0].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    2: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[1].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    3: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[2].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    4: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[3].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    5: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[4].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    6: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[5].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    7: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[6].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    8: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[7].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    // 9: () => {
    //   return [
    //     ...defaultColumn,
    //     {
    //       id: "label",
    //       label: masterData[8].tableColumnName,
    //     },
    //   ];
    // },
    9: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[8].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    10: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[9].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    // 12: () => {
    //   return [
    //     ...defaultColumn,
    //     {
    //       id: "label",
    //       label: masterData[11].tableColumnName,
    //     },
    //     {
    //       id: "days",
    //       label: masterData[11].additionalFields[0].tableColumnName
    //     }
    //   ];
    // },
    11: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[10].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    12: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[11].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    13: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[12].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    14: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[13].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    15: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[14].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    // 18: () => {
    //   return [
    //     ...defaultColumn,
    //     {
    //       id: "label",
    //       label: masterData[17].tableColumnName,
    //     },
    //   ];
    // },
    16: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[15].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    17: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[16].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },

    18: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[17].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    },
    19: () => {
      return [
        ...defaultColumn,
        {
          id: "label",
          label: masterData[18].tableColumnName,
        },
        {
          id:"aliasId",
          label:"Code",
        }
      ];
    }
    // 21: () => {
    //   return [
    //     ...defaultColumn,
    //     {
    //       id: "label",
    //       label: masterData[20].tableColumnName,
    //     },
    //   ];
    // }



  };
};

export const componentData = {
  1: { key: "skill", label: "SKILL NAME" },
  2: { key: "cert_organization", label: "ISSUING AUTHORITY" },
  3: { key: "emp_organization", label: "EMPLOYER" },
  4: { key: "categories", label: "CATEGORY" },
  5: { key: "certificates", label: "CERTIFICATE" },
  6: { key: "exp_roles", label: "JOB ROLE" },
  7: { key: "specialization", label: "SPECIALIZATION" },
};
