export const styles = (theme) => ({
  root: {
    padding: 20,
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  rootCard: {
    margin: "auto",
    width: "100%",
    boxShadow: "0 25px 50px 0 rgba(0, 0, 0, 0.08) !important",
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mainContent: {
    display: "flex",
    alignItems: "center",
  },
  mainHeading: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  subHeading: {
    fontSize: "1rem",
    color: theme.palette.secondary.main,
  },
  formGrid: {
    textAlign: "center",
  },
  formHeader: {
    textAlign: "center",
    marginBottom: 50,
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
    },
  },
  sendCodeButton: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10,
    },
    width: "100%",
    marginTop: 40,
    marginBottom: 10,
    padding: "8px",
    background: `${theme.palette.primary.main}`,
    transition: "0.5s",
    "& button": {
      padding: 8,
    },
    "&:hover": {
      transition: "0.5s",
      background: `${theme.palette.primary.main}`,
      opacity: 0.9,
    },
  },
  errorMessage: {
    color: `${theme.palette.error.main}`,
  },
  backToSigninButton: {
    textTransform: 'unset'
  },
  svg_icon: {
    fontSize: "6rem"
  }
});
