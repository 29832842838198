import { filterType, user_roles } from "../../../../utils"

export const filterComponent = (state, props) => {

  switch (props?.userRole) {
    case user_roles.super_admin: {
      return {
        section_1: {
          label: "Supervisor Filters",
          fields: {
            category: {
              key: "category",
              type: filterType.CATEGORY,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY CATEGORY",
              value: state.category,
              isMulti: true
            },
            provider: {
              key: "provider",
              type: filterType.PROVIDER,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY PROVIDER",
              value: state.provider,
              isMulti: true
            },
            status: {
              key: "status",
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SUPERVISOR STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
          }
        }
      }
    }

    case user_roles.provider: {
      return {
        section_1: {
          label: "Supervisor Filters",
          fields: {
            category: {
              key: "category",
              type: filterType.CATEGORY,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY CATEGORY",
              value: state.category,
              isMulti: true
            },
            status: {
              key: "status",
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SUPERVISOR STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
          }
        }
      }
    }

    default: return {}
  }
}