import * as React from "react"

export const PolygonIcon = (props) => {

    const {
        width = "24",
        height = "22",
        borderColor = "#6f6f6f",
        bgColor = "#fff"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 22"
        >
            <g id="Polygon_46" data-name="Polygon 46" fill={bgColor}>
                <path d="M 17.49541473388672 21.14999961853027 L 6.504584312438965 21.14999961853027 L 0.9682208895683289 11 L 6.504584312438965 0.8500000238418579 L 17.49541473388672 0.8500000238418579 L 23.03177833557129 11 L 17.49541473388672 21.14999961853027 Z" stroke="none" />
                <path d="M 7.009174346923828 1.700000762939453 L 1.936447143554688 11 L 7.009174346923828 20.29999923706055 L 16.99082565307617 20.29999923706055 L 22.06355285644531 11 L 16.99082565307617 1.700000762939453 L 7.009174346923828 1.700000762939453 M 6 0 L 18 0 L 24 11 L 18 22 L 6 22 L 0 11 L 6 0 Z" stroke="none" 
                    fill={borderColor}
                />
            </g>
        </svg>
    );
};

