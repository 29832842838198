import React from 'react';
import { routes } from '../../../../router/routes';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from 'tanyacare-middleware';
import { 
    // TopLinkCard, 
    ToggleGroup, Bottomcard } from '../../../../components/activity'
import { ActivityErrors, ActivityLoaders } from '../../../../components/activity/errorsAndLoaders'
import { Typography } from '@material-ui/core';

class Activities extends React.Component {
    constructor(props) {
        super(props);
        const type = this.props?.match?.params?.type;
        const user_id = this.props?.match?.params?.client_id

        this.state = {
            type: type ? type : 'upcoming',
            toggleTypes: [
                {
                    label: 'Past',
                    id: `MonitoringViewClientActivitiesPast${user_id ? user_id : ""}`,
                    value: 'past'
                },
                {
                    label: 'UpComing',
                    id: `MonitoringViewClientActivitiesUpcoming${user_id ? user_id : ""}`,
                    value: 'upcoming',
                },
            ]
        }
    }

    componentDidMount() {
        this.getClientActivities();
    }

    getClientActivities = () => {
        const clientID = this.props?.match?.params?.client_id
        console.log(this.state.type)
        this.props.GET_CLIENT_ALL_ACTIVITIES({ clientID, event: this.state.type })
    };

    handleToggleClick = (event, selected) => {
        if (selected != null) {
            const clientID = this.props?.match?.params?.client_id;

            this.setState({
                type: selected
            }, () => {
                this.props.history.replace(routes.monitor_client_activitie_parent + clientID + "/" + selected);
                this.getClientActivities();
            })
        }
    }

    render() {
        const { data, loading, error } = this.props?.activity;

        return (
            <div style={{ maxWidth: "100%", margin: 25 }}>
                {/* <TopLinkCard /> */}
                <ToggleGroup selected={this.state.type} toggleTypes={this.state.toggleTypes} handleToggleClick={this.handleToggleClick} />
                {loading && <ActivityLoaders count={data?.activities?.length} />}
                {error && <ActivityErrors count={data?.activities?.length} />}
                {
                    !loading && !error && data?.activities
                    &&
                    (data?.activities?.length > 0
                        ?
                        data?.activities.map((activity, index) => {
                            return <Bottomcard key={index} {...activity} getClientActivities={this.getClientActivities}/>
                        })
                        :
                        <Typography align="center">The client doesn't have any {this.state.type} activity!</Typography>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    activity: state?.clientActivity?.clientAllActivities
});

export default connect(mapStateToProps, actions)(withRouter(Activities))