import React, { Component } from "react";
import PropTypes from "prop-types";
import AddEditDynamicFromRenderer from "../../../addEditDynamicFromRenderer";
// import { components } from "../../../addEditDynamicFromRenderer/components";
import { withRouter } from "react-router-dom";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import {
  alertProps,
  base64MimeType,
  formComponentTypes,
  upload_file,
} from "../../../../utils";
// options
// import { routes } from "../../../../router/routes";
import { Grid, Paper, withStyles } from "@material-ui/core";
import {
  LoadingSection,
  SelectFromPlaceholder,
  SimpleSelect,
} from "../../../../components";
import {
  allPersonaIds,
  allResourceLabel,
  generateDropDownLabel,
  getPersonaId,
  labelforresource,
  redirectURL,
} from "../utils";
import moment from "moment";

const style = (theme) => ({
  // "& .MuiPaper-elevation1": (props) => ({ boxShadow: !props.withBoxShadow ? 'none !important' : '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }),
  root: {
    width: "100%",
    // padding:"10px",
    padding: "8px 22px 16px 22px",
    marginBottom: 50,
    boxShadow: (props) =>
      props.withoutBoxShadow
        ? "none !important"
        : "0 3px 6px 0 rgb(0 0 0 / 4%)",
    background: (props) =>
      props.withoutBoxShadow ? "transparent !important" : "white",
  },
});

class AddEditResource extends Component {
  constructor(props) {
    super();
    this.state = {
      editFormJson: {},
      componentForm: {},
      loading: false,
      professionalRole: null,
      roles: [],
      prev_key: "",
      id: this.props?.id,
    };
  }

  async componentDidMount() {
    if (this.props.isEdit) {
      this.setState({ loading: true });
      this.props.GET_PERSONA_INFO({ resoureId: this.props?.match?.params?.id });
    } else {
      if (this.props?.match?.params?.id) {
        const id = this.props?.match?.params?.id;
        await this.props.GET_PERSONA_INFO({ resoureId: id });
      } else {
        this.setState({ loading: true });
        let getIdNameType = getPersonaId(this.props?.match?.path);
        await this.props.COLLECT_PERSONA_META({
          resourceTypeId: getIdNameType.id,
          resourceTypeName: getIdNameType.name,
        });
      }
    }
    this.getEssentials();
  }

  // ComponentDidMount End
  getEssentials = async () => {
    this.props.GET_ALL_DOMAIN();
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Add

    const { addPersonaResource } = nextProps;
    const oldaddPersonaResource = this.props.addPersonaResource;
    //Checking for Add resource messages
    if (oldaddPersonaResource?.loading !== addPersonaResource?.loading) {
      if (!addPersonaResource.loading && !addPersonaResource.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `${allResourceLabel(
            this.props?.match?.path
          )} Added successfully!`,
          severity: alertProps.severity.success,
        });
        this.props.history.push(redirectURL(this.props?.match?.path));
      } else if (addPersonaResource.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `${
            addPersonaResource?.message
              ? addPersonaResource.message
              : `Something went wrong! Unable to Add ${allResourceLabel(
                  this.props?.match?.path
                )}.`
          }`,
          severity: alertProps.severity.error,
        });
      }
    }

    //PRELOAD DATA IN THE FORM...
    const { personaResourceInfo } = nextProps;
    const oldpersonaResourceInfo = this.props.personaResourceInfo;
    //Checking for Add Package messages
    if (oldpersonaResourceInfo?.loading !== personaResourceInfo?.loading) {
      if (!personaResourceInfo.loading && !personaResourceInfo.error) {
        // console.log(personaResourceInfo?.data);
        let { data: personaResourceInfoData } = personaResourceInfo;
        console.log(personaResourceInfoData);
        this.setState({
          roles: [],
          componentForm: this.dropDownCallBacks(
            null,
            null,
            personaResourceInfoData?.[0]?.resourceMeta,
            null
          ),
        });
      }
    }

    //GET FORM BASED ON PERSONA
    const { getFormBasedOnPersona } = nextProps;
    const oldgetFormBasedOnPersona = this.props.getFormBasedOnPersona;
    //Checking for Add Package messages
    if (oldgetFormBasedOnPersona?.loading !== getFormBasedOnPersona?.loading) {
      if (!getFormBasedOnPersona.loading && !getFormBasedOnPersona.error) {
        console.log(getFormBasedOnPersona?.data);
        let { data: formData } = getFormBasedOnPersona;
        if (formData?.length > 0) {
          this.setState({
            loading: false,
            roles: formData?.[0]?.roles ?? [],
            componentForm:
              formData?.[0]?.roles?.length > 0
                ? null
                : this.dropDownCallBacks(
                    null,
                    null,
                    formData?.[0]?.enrollment,
                    null
                  ),
          });
        } else {
          this.setState({
            roles: [],
            componentForm: {},
            // loading:false,
          });
        }
      }
    }

    // UPDATE
    const { updatePersonaResource } = nextProps;
    const oldupdatePersonaResource = this.props.updatePersonaResource;
    //Checking for Add resource messages
    if (oldupdatePersonaResource?.loading !== updatePersonaResource?.loading) {
      if (!updatePersonaResource.loading && !updatePersonaResource.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: `${allResourceLabel(
            this.props?.match?.path
          )} updated successfully!`,
          severity: alertProps.severity.success,
        });
        this.props.history.push(
          `${redirectURL(this.props?.match?.path)}/view/${
            this.props?.match?.params?.id
          }`
        );
      } else if (updatePersonaResource.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: updatePersonaResource?.message
            ? updatePersonaResource?.message
            : `Something went wrong! Unable to update ${allResourceLabel(
                this.props?.match?.path
              )}.`,
          severity: alertProps.severity.error,
        });
      }
    }

    return true;
  }

  onSave = async (formWithData, data, partnerDomain, partnerCategory) => {
    let actionToDispatch = {};

    // actionToDispatch['actionName'] = "ADD_CARE_PROVIDER"
    actionToDispatch["bodyParams"] = {};

    actionToDispatch["bodyParams"]["resourceName"] = data?.first_name_last_name
      ? data?.first_name_last_name
        ? data?.first_name_last_name?.constructor === String
          ? data?.first_name_last_name
          : data?.first_name_last_name?.label
        : ""
      : data?.machine_name ?? "";
    actionToDispatch["bodyParams"]["primaryEmail"] =
      data?.primary_email_address;
    actionToDispatch["bodyParams"]["effectiveFrom"] = new Date();
    actionToDispatch["bodyParams"]["effectiveTo"] = new Date();
    actionToDispatch["bodyParams"]["resourceTypeCategory"] = this.state
      ?.professionalRole?.value
      ? [this.state?.professionalRole?.value]
      : [];
    actionToDispatch["bodyParams"]["subResources"] = this.state.professionalRole
      ? [this.state.professionalRole]
      : [];
    actionToDispatch["bodyParams"]["resourceCategory"] = data?.select_category
      ? data?.select_category?.map((val) => val.value)
      : [];
    actionToDispatch["bodyParams"]["partnerId"] = data?.select_provider?.value;
    let getIdNameType = getPersonaId(this.props?.match?.path);
    actionToDispatch["bodyParams"]["resourceType"] = [getIdNameType.id];

    // resourceName,
    // primaryEmail
    // effectiveFrom,
    // effectiveTo,
    // resourceType,
    // resourceTypeCategory,
    // resourceMeta,
    // subResources,
    // resourceCategory,

    // disabling fields here ....

    if (Object.keys(formWithData).includes("primary_email_address")) {
      let { primary_email_address } = formWithData;
      primary_email_address.componentProps = {
        ...primary_email_address.componentProps,
        disabled: true,
      };

      actionToDispatch["bodyParams"]["resourceMeta"] = formWithData;
    } else {
      actionToDispatch["bodyParams"]["resourceMeta"] = formWithData;
    }

    // UPLOADING IMAGE BEFORE SAVING THE DATA
    if (formWithData?.uploadImage?.value) {
      if (base64MimeType(formWithData?.uploadImage?.value)?.length > 0) {
        let { fileName, withoutType } = upload_file(
          formWithData?.uploadImage?.value
        );
        await this.props
          .FILE_UPLOAD({
            file: withoutType,
            fileName: fileName,
            isbase64: true,
          })
          .then(async (val) => {
            actionToDispatch["bodyParams"]["resourceMeta"]["uploadImage"][
              "value"
            ] = await val?.payload?.data?.fileid;
          });
      } else {
        actionToDispatch["bodyParams"]["resourceMeta"]["uploadImage"]["value"] =
          formWithData?.uploadImage?.value?.split("/")[
            formWithData?.uploadImage?.value?.split("/").length - 1
          ];
      }
    }

    // CHECK IS EDIT OR NOT AND CALL THE ACTION
    if (this.props.isEdit) {
      const { data } = this.props?.personaResourceInfo || {};
      actionToDispatch["bodyParams"]["resourceId"] =
        this.props?.match?.params?.id;

      const getFlagValue = () => {
        const editedEffective =
          actionToDispatch["bodyParams"]["resourceMeta"]["resource_validity"]
            ?.value?.resourceEffectiveFrom;
        const dbEffective =
          data?.[0]?.["resourceMeta"]?.["resource_validity"]?.value
            ?.resourceEffectiveFrom;

        const editedEndsOn =
          actionToDispatch["bodyParams"]["resourceMeta"]["resource_validity"]
            ?.value?.resourceEndsOn;
        const dbEndsOn =
          data?.[0]?.["resourceMeta"]?.["resource_validity"]?.value
            ?.resourceEndsOn;

        if (!moment(editedEffective)?.isSame(dbEffective)) {
          return true;
        } else if (editedEndsOn && dbEndsOn) {
          if (!moment(editedEndsOn)?.isSame(dbEndsOn)) {
            return true;
          }
        } else if ((!editedEndsOn && dbEndsOn) || (editedEndsOn && !dbEndsOn)) {
          return true;
        }

        return false;
      };

      let payload = {
        ...actionToDispatch.bodyParams,
        effectiveFrom:
          actionToDispatch["bodyParams"]["resourceMeta"]["resource_validity"]
            ?.value?.resourceEffectiveFrom,
        EndsOn:
          actionToDispatch["bodyParams"]["resourceMeta"]["resource_validity"]
            ?.value?.resourceEndsOn,
        isEffectiveFromToValidation: getFlagValue(),
      };

      if (!data?.[0]?.emailVerified) {
        if (
          payload.effectiveFrom.getTime() ===
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          ).getTime()
        ) {
          // alert("Hi")
          payload.isActive = true;
        }
      }
      console.clear();
      console.log(data?.[0]?.emailVerified);

      this.props.UPDATE_PERSONA_RESOURCE(payload);
    } else {
      const isErrorFree = await this.handleActivateDeactivateValidations(
        actionToDispatch["bodyParams"]["partnerId"],
        actionToDispatch["bodyParams"]["resourceMeta"]["resource_validity"]
      );

      if (isErrorFree) {
        this.props.ADD_PERSONA_RESOURCE(actionToDispatch.bodyParams);
      }
    }
  };

  handleActivateDeactivateValidations = async (
    partnerId,
    resource_validity
  ) => {
    let currentUser = getPersonaId(this.props?.match?.path)?.id;
    const startDate = resource_validity?.value?.resourceEffectiveFrom
      ? new Date(resource_validity?.value?.resourceEffectiveFrom)?.toISOString()
      : null;
    let partnerData = this.props.providerBasedOnCategory?.data?.find(
      (partner) => partner.value === partnerId
    );
    const endDate =
      allPersonaIds.other_resources === currentUser
        ? partnerData?.validity?.resourceEndsOn ?? ""
        : resource_validity?.value?.resourceEndsOn
        ? new Date(resource_validity?.value?.resourceEndsOn)?.toISOString()
        : "";
    await this.props?.GET_PARTNER_ACTIVE_DATE_BY_SUPERVISOR({
      partnerId: partnerId,
      startDate: startDate,
      endDate: endDate,
    });
    const { data } = this.props?.getPartnerActiveStatusBySupervisor;

    switch (currentUser) {
      case allPersonaIds.service_supervisor:
      case allPersonaIds.service_coordinator:
      case allPersonaIds.service_professional:
      case allPersonaIds.other_resources: {
        if (Array.isArray(data) && data?.length > 0) {
          // No issue in adding supervisor, coordinator, professional & other resource
          return true;
        } else {
          this.props.alert.setSnack({
            open: true,
            horizontal: alertProps.horizontal.center,
            vertical: alertProps.vertical.top,
            msg: `Selected Partner may not be active on the selected dates. Please select different date.`,
            severity: alertProps.severity.error,
          });
        }
        break;
      }
      default:
        return null;
    }
  };

  // DROPDOWN CALLBACKS AND DROPDOWN PRELOADING
  //  value -> PARAMETER WILL GET CURRENT ON CHANGED VALUE
  // CurrentOnChangeKey -> PARAMETER WILL GET THE KEY TO BE UPDATED
  // formJson -> WILL GIVE US THE RENDERED FORM
  // currentDynamicFormState -> WILL GIVE US CURRENT STATE OF THE RENDERED FORM
  dropDownCallBacks = (
    value,
    currentOnChangeKey,
    formJson,
    currentDynamicFormState
  ) => {
    if (value) {
      let __callBackBodyParams =
        value.constructor === Array
          ? value.map((val) => val.value)
          : value.value;
      let _componentJSON = formJson;
      let _componentJSONKeys = Object.keys(formJson);

      // APPENDING THE VALUES FROM THE CURRENT STATE TO THE FORM OBJECT
      // BECAUSE WE ARE HAVE *"CATEGORY -> PROVIDER"* RELATIONSHIP DROPDOWN
      // SO WE NEED TO RESET THE STATE WE WILL USE THE BELOW SECTION
      _componentJSONKeys.map((val) => {
        let _obj = { ..._componentJSON[val] };
        if (currentOnChangeKey === val) _obj["value"] = value;
        else {
          if (_obj["element"] === formComponentTypes.MOBILE_INPUT_BOX) {
            let __mobileObj = { ..._obj["value"] };
            // let __mobileObjOptions = {..._obj["options"]}
            // __mobileObjOptions = []
            __mobileObj[_obj["key"]["select_key"]] =
              currentDynamicFormState[_obj["key"]["select_key"]];
            __mobileObj[_obj["key"]["input_value_key"]] =
              currentDynamicFormState[_obj["key"]["input_value_key"]];
            _obj["value"] = __mobileObj;
            // _obj["options"] = __mobileObjOptions;
          } else if (_obj["element"] === formComponentTypes.RESOURCE_VALIDITY) {
            let __resourceValidityObj = { ..._obj["value"] };
            __resourceValidityObj[_obj["key"]["startDate"]] =
              currentDynamicFormState[_obj["key"]["startDate"]];
            __resourceValidityObj[_obj["key"]["endDate"]] =
              currentDynamicFormState[_obj["key"]["endDate"]];
            _obj["value"] = __resourceValidityObj;
          } else {
            _obj["value"] = currentDynamicFormState[val];
          }
        }
        _componentJSON = { ..._componentJSON, [val]: _obj };
        return true;
      });
      //

      // PRELOADING DEPENDENT OPTIONS HERE...
      let promises = [];
      if (_componentJSON?.[currentOnChangeKey]?.["depend"]?.length > 0) {
        // console.log("")
        promises = _componentJSON[currentOnChangeKey]["depend"].map(
          (subKeys) => {
            // console.log(subKeys)
            if (_componentJSON[subKeys]?.requiredAction) {
              let _actionName = _componentJSON[subKeys]?.requiredAction[0];
              let _obj = _componentJSON[subKeys].additionalParams
                ? _componentJSON[subKeys]?.additionalParams
                : {};
              // console.log()
              // console.log(_componentJSON[subKeys])

              let payloadObjectinDB = null;
              if (
                typeof _componentJSON[subKeys]?.requiredPayload === "object" &&
                _componentJSON[subKeys]?.requiredPayload !== null
              ) {
                payloadObjectinDB = {
                  ..._componentJSON[subKeys]?.requiredPayload,
                };
              }

              return this.props[_actionName](
                {
                  ..._obj,
                  ...payloadObjectinDB,
                  [_componentJSON[subKeys]?.requiredPayload[0]]:
                    __callBackBodyParams,
                } ?? {}
              ).then((val) => {
                let _obj = { ..._componentJSON[subKeys] };

                // This section remove a select box depends on other box
                // eg -> provider depends on category
                if (
                  _componentJSON[currentOnChangeKey]
                    ?.isCheckKeyBeforeClearDependent?.length > 0
                ) {
                  if (Object.keys(_obj["value"])?.length > 0) {
                    let _removeProviderBasedOnCategoryChange = _obj["value"][
                      _componentJSON[currentOnChangeKey]
                        ?.isCheckKeyBeforeClearDependent
                    ].filter(
                      (val) => value.map((val) => val.value).indexOf(val) > -1
                    );
                    console.log(_removeProviderBasedOnCategoryChange, "aaaa");
                    _obj["options"] = Array.isArray(val?.payload)
                      ? val?.payload
                      : val?.payload?.data;
                    _obj["value"] =
                      _removeProviderBasedOnCategoryChange.length > 0
                        ? _obj["value"]
                        : [];
                  } else {
                    _obj["options"] = Array.isArray(val?.payload)
                      ? val?.payload
                      : val?.payload?.data;
                    _obj["value"] = [];
                  }
                } else {
                  _obj["options"] = Array.isArray(val?.payload)
                    ? val?.payload
                    : val?.payload?.data;
                  _obj["value"] = [];
                }
                _componentJSON = { ..._componentJSON, [subKeys]: _obj };
                return val;
              });
            }
            return true;
          }
        );
      }
      Promise.all(promises).then((results) => {
        this.setState({ loading: false, componentForm: _componentJSON });
      });
      //
    } else {
      // IN THIS ELSE CONDITON WE WILL BE CHECKING TWO THINGS
      //1 -> SETTING THE DROPDOWN OPTIONS ON COMPONENT MOUNTS
      //2 -> IF WE REMOVE ALL THE VALUES FROM THE DROPDOWN THIS WILL DONE HERE
      //
      let _componentJSON = formJson?.["meta"] ? formJson["meta"] : formJson;
      let _componentJSONKeys = Object.keys(_componentJSON) ?? [];

      // ***1***-->
      let promises = _componentJSONKeys.map((key, index) => {
        // console.log(key)
        if (_componentJSON[key]?.requiredAction) {
          let _actionName = _componentJSON[key]?.requiredAction[0];

          if (
            _componentJSON[key]?.options?.constructor === Array &&
            _componentJSON[key]?.options?.length > 0
          ) {
            // console.log("INSIDE")
            return true;
          }

          let payloadValue;
          _componentJSONKeys.map((internalKey) => {
            if (_componentJSON[internalKey]?.depend?.includes(key)) {
              payloadValue = _componentJSON[internalKey]?.value;
            }
            return key;
          });

          let payloadObjectinDB = null;
          if (
            typeof _componentJSON[key]?.requiredPayload === "object" &&
            _componentJSON[key]?.requiredPayload !== null
          ) {
            payloadObjectinDB = { ..._componentJSON[key]?.requiredPayload };
          }

          return this.props[_actionName](
            {
              [_componentJSON[key]?.requiredPayload?.[0]]: Array.isArray(
                payloadValue
              )
                ? payloadValue?.map((x) => x.value)
                : payloadValue,
              ...payloadObjectinDB,
              ..._componentJSON[key]["additionalParams"],
            } ?? {}
          ).then((val) => {
            let _obj = { ..._componentJSON[key] };
            _obj["options"] = val?.payload?.data ?? [];
            _componentJSON = { ..._componentJSON, [key]: _obj };
            return val;
          });
        }
        return true;
      });
      Promise.all(promises).then((results) => {
        this.setState({ loading: false, componentForm: _componentJSON });
      });

      // ***2***-->
      if (_componentJSON?.[currentOnChangeKey]?.["depend"]?.length > 0) {
        _componentJSON[currentOnChangeKey]["depend"].map(
          (removingDependantOptions) => {
            let __newobj = { ..._componentJSON[removingDependantOptions] };
            let currentDropDownChanged = {
              ..._componentJSON[currentOnChangeKey],
            };
            __newobj["options"] = [];
            __newobj["value"] = [];
            currentDropDownChanged["value"] = value;
            _componentJSON = {
              ..._componentJSON,
              [currentOnChangeKey]: currentDropDownChanged,
              [removingDependantOptions]: __newobj,
            };
            return true;
          }
        );
      }
    }
  };

  // FOR CHOOSING DOMAIN AND DOMAINCATEGORY

  onChangeDropDown = (value, key, cleanUpKeys) => {
    if (value) {
      if (this.state?.roles?.length > 0) {
        let selectedFromBasedOnRole = this.state.roles.filter(
          (val) => val.value === value.value
        );
        this.setState({
          loading: true,
          professionalRole: value,
          componentForm: selectedFromBasedOnRole
            ? this.dropDownCallBacks(
                null,
                null,
                selectedFromBasedOnRole[0]?.enrollment,
                null
              )
            : null,
        });
      }
    } else {
      this.setState({ componentForm: null });
    }
  };
  render() {
    const { classes } = this.props;

    return (
      <div style={{ padding: 25 }}>
        {!this.props?.match?.params?.id && this.state?.roles?.length > 0 && (
          <Paper className={classes.root}>
            {/* <Typography
              variant="h6"
              component={"h6"}
            >
              Please choose the role
            </Typography> */}
            <Grid
              container
              direction="row"
              // justify="space-around"
              // alignItems="center"
              spacing={3}
            >
              <Grid item xs={12} sm={12}>
                <SimpleSelect
                  label={generateDropDownLabel(this?.props?.match?.path)}
                  isMulti={false}
                  // id="domain"
                  id={`selectDomainDropDown`}
                  options={this.state.roles}
                  value={this.state?.professionalRole}
                  handleOnChange={(e) => {
                    this.onChangeDropDown(e);
                  }}
                  // error={this.state.error.provider ? true : false}
                  // errorText={errorMessage.requiredMessage}
                />
              </Grid>
            </Grid>
          </Paper>
        )}
        {this.state.loading && <LoadingSection message="Fetching data..." />}
        {!this.state.loading &&
          this.state?.roles?.length > 0 &&
          !this.state?.componentForm && (
            <SelectFromPlaceholder message="Please choose the form type" />
          )}
        {!this.state.loading && this.state?.componentForm && (
          <AddEditDynamicFromRenderer
            actionToDispatch={this.onSave}
            dropDownCallBacks={this.dropDownCallBacks}
            componentForm={this.state.componentForm}
            saveButtonLabel={labelforresource(
              this.props?.match?.path,
              this.props?.addPersonaResource?.loading,
              this.props?.updatePersonaResource?.loading
            )}
            loading={
              this.props?.addPersonaResource?.loading ||
              this.props?.updatePersonaResource?.loading
            }
            {...this.props}
            cancelButtonId={`CancelButtonFrom${
              this.props.id ? this.props.id : ""
            }`}
            saveButtonId={`SaveButtonFrom${this.props.id ? this.props.id : ""}`}
          />
        )}
      </div>
    );
  }
}

AddEditResource.propTypes = {
  data: PropTypes.array,
};
const mapStateToProps = (state) => ({
  addPersonaResource: state?.resourceReducer?.addPersonaResource,
  getFormBasedOnPersona: state?.careProvider?.collectPersonaMeta,
  auth_data: state?.authState?.loginAuth,
  updatePersonaResource: state?.resourceReducer?.updatePersonaResource,
  personaResourceInfo: state.resourceReducer?.personaResourceInfo,
  getPartnerActiveStatusBySupervisor:
    state.resourceReducer?.getPartnerActiveStatusBySupervisor,
  providerBasedOnCategory: state?.careProvider?.providerBasedOnCategory,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withStyles(style)(withRouter(AddEditResource))));
