import React from "react";
import {
  Avatar,
  Badge,
  Button,
  Chip,
  Grid,
  // IconButton,
  makeStyles,
  Typography,
  useTheme,
  withStyles,
  Hidden,
} from "@material-ui/core";
// import { CameraSvg } from "../../assets";
// import CameraAltRoundedIcon from "@material-ui/icons/CameraAltRounded";
// import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {
  textClampStyleGenerator,
  user_roles,
  user_roles_name,
} from "../../utils";
import { CameraSvg } from "../../assets";

import { UploadProfileImage } from "../";
import { getTrialText, isTrialAccountType } from "../../utils/common";

const CustomChip = withStyles((theme) => ({
  root: {
    borderRadius: 4,
    backgroundColor: "rgba(233, 233, 233, 0.48)",
    margin: "4px 0px 16px 0px",
    color: theme.palette.text.secondary,
  },
  colorPrimary: {
    backgroundColor: theme.palette.quaternary.main,
  },
}))((props) => <Chip {...props} />);

const useStyle = makeStyles((theme) => ({
  centerIt: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarSpacing: {
    margin: "20px 32px 12px 32px",
  },
  largeAvatar: {
    // width: theme.spacing(12),
    // height: theme.spacing(12),
    width: 112,
    height: 112,
    position: "relative",
  },
  badgeContentSpacing: {
    background: `rgb(244, 244, 245) !important`,
    border: "2px solid white",
    padding: 0,
  },
  buttonStyle: {
    padding: "6px 32px",
  },
  cameraContainer: {
    textAlign: "center",
    cursor: "pointer",
    "& svg": {
      fill: theme.palette.secondary.main,
    },
  },
  textContainer: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: 5,
    "& p": {
      color: theme.palette.text.label,
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
      },
    },
  },
}));

export default function ProfilePicture({
  profilePicture,
  loginData,
  user_data,
  isEditProfileButtonNeeded,
  ...props
}) {
  const classes = useStyle(props);
  const theme = useTheme();

  const trialExpiry = [user_roles.provider]?.includes(loginData?.user_role)
    ? user_data?.partnerMeta?.resource_validity?.value?.resourceEndsOn ?? ""
    : [user_roles.supervisor, user_roles.service_coordinator]?.includes(
        loginData?.user_role
      )
    ? user_data?.resourceMeta?.resource_validity?.value?.resourceEndsOn
    : "";

  const tempCategory = loginData?.category?.filter((x, index) => {
    if (index < 2) {
      return x;
    }
    //return any value in arrow function
    return true;
  });

  return (
    <>
      <Grid item xs={12} className={classes.centerIt}>
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          // badgeContent={
          //   (loginData?.user_role === 'super-admin')
          //   ? (<></>)
          //   :
          //     (
          //       <IconButton
          //         style={{ padding: 4 }}
          //         onClick={props.handleProfilePictureRemove}
          //         className={classes.badgeContentSpacing}
          //       >
          //         <CloseRoundedIcon htmlColor={theme.palette.ternary.main} />
          //       </IconButton>
          //     )
          // }
          className={classes.avatarSpacing}
        >
          <div>
            {profilePicture ? (
              // <Avatar className={classes.largeAvatar} src={profilePicture} onClick={() => document.getElementById("profilePic").click()} />
              <UploadProfileImage
                profile_pic={profilePicture}
                // uncomment this to enable edit option

                // value={(loginData?.user_role !== 'super-admin' && profilePicture) ? profilePicture : ''}
                handleFileChange={(e) => {
                  if (loginData?.user_role !== "super-admin")
                    document.getElementById("profilePic").click();
                }}
                label={"Admin"}
              />
            ) : (
              <Avatar
                className={classes.largeAvatar}
                style={{ background: "rgba(244, 244, 245, 1)" }}
                onClick={() => document.getElementById("profilePic").click()}
              >
                {/* {loginData?.profile_name?.[0]?.toUpperCase()} */}
                <Grid container>
                  <Grid item xs={12} className={classes.cameraContainer}>
                    <CameraSvg htmlColor={theme.palette.ternary.main} />
                  </Grid>
                  <Grid item xs={12} className={classes.textContainer}>
                    <Hidden mdUp>
                      <Typography
                        style={{ color: theme.palette.text.main }}
                        variant="body1"
                        component="p"
                      >
                        {props.label ? props.label : "UPLOAD PHOTO"}
                      </Typography>
                    </Hidden>
                    <Hidden smDown>
                      <Typography
                        style={{ color: theme.palette.text.main }}
                        variant="body1"
                        component="p"
                      >
                        {props.label ? props.label : "UPLOAD PHOTO"}
                      </Typography>
                    </Hidden>
                  </Grid>
                </Grid>
              </Avatar>
            )}
            {/* <div
              style={{
                position: "absolute",
                width: 10,
                height: 10,
                background: "green",
              }}
            ></div> */}
          </div>
        </Badge>
        <input
          id="profilePic"
          // id={props.id}
          type="file"
          hidden
          accept="image/*"
          onChange={props.handleProfilePictureUpload}
          value={props.profilePicture}
        />
      </Grid>
      <Grid item xs={12} className={classes.centerIt}>
        <Typography style={{ fontSize: "1.1rem" }}>
          {user_data?.partnerName
            ? user_data?.partnerName
            : user_data?.resourceName
            ? user_data?.resourceName
            : loginData?.profile_name}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.centerIt}>
        <Typography style={{ fontSize: "0.9rem" }} color="textSecondary">
          {user_roles_name?.[loginData?.user_role]}
        </Typography>
      </Grid>

      {/* Trial Expiry Text - shown only in Trial Accounts */}
      {[
        user_roles.provider,
        user_roles.supervisor,
        user_roles.service_coordinator,
      ].includes(loginData?.user_role) &&
        isTrialAccountType() &&
        trialExpiry && (
          <Grid item xs={12} className={classes.centerIt}>
            <Typography
              style={{
                fontSize: "0.9rem",
                // fontStyle: "italic",
                color: "#F03E50",
                ...textClampStyleGenerator(),
              }}
            >
              {getTrialText(trialExpiry)}
            </Typography>
          </Grid>
        )}

      <Grid
        container
        item
        xs={12}
        justify={"center"}
        alignItems={"center"}
        spacing={1}
        style={{ margin: "0px 8px" }}
      >
        {tempCategory?.map((category) => (
          <Grid item className={classes.centerIt}>
            <CustomChip label={category?.label} />
          </Grid>
        ))}
      </Grid>

      {isEditProfileButtonNeeded && (
        <Grid item xs={12} className={classes.centerIt}>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
          >
            edit profile
          </Button>
        </Grid>
      )}
    </>
  );
}
