import * as React from "react"

export function Phone(props) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" {...props}>
      <path
        fill={props.color ? props.color : "#28a9c1"}
        d="M17.023 12.488a15.077 15.077 0 01-1.924-.14 14.67 14.67 0 01-1.653-.425 1.017 1.017 0 00-1.019.253l-2.217 2.2a15.376 15.376 0 01-3.8-2.792 15.376 15.376 0 01-2.792-3.8l2.2-2.218a1.017 1.017 0 00.253-1.019A14.6 14.6 0 015.653 2.9 15.067 15.067 0 015.512.976 1 1 0 004.51 0H1a.992.992 0 00-1 1 17.179 17.179 0 004.966 12.034A17.179 17.179 0 0017 18a.992.992 0 001-1v-3.51a1 1 0 00-.977-1.002z"
      />
    </svg>
  )
}

export default Phone
