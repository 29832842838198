import React, { Component } from 'react'
import ActivityAndMembersCard from '../../../../components/availabilityActivityComponents/ActivityAndMembersCard'
import { Typography } from '@material-ui/core'

export default class ViewServiceProfessionals extends Component {

  onEndIconClickHandler = (id) => {
    if(id) this.props.onEndIconClickHandler(id,true)
  }

  render() {
    const { data } = this.props

    return (
      <div>
        {/* Heading */}
        <Typography variant="h6" style={{ padding: "14px 14px 14px 14px" }}>
          {data?.length ?? 0} Service Professionals(s)
        </Typography>

        {/* Service Professional List */}
        {data?.map((item, index) => {
          return (
            <ActivityAndMembersCard
              key={index}
              profilePicture={item?.profile_pic}
              title={item?.name}
              name={item?.care_provider?.map(_=>_.name).toString()}
              onEndIconClickHandler={()=>this.onEndIconClickHandler(item?.id)}
              status={item?.isactive}
              // hideEndIcon
            />
          )
        })}
        {/* <Typography>View all Service Professional Screen Needed</Typography> */}
      </div>
    )
  }
}
