import React from "react";
import {
  Paper,
  withStyles,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import { HybridTypography } from "..";
import { ICONS } from "../hybridTypography/typography";
import PropTypes from "prop-types";
import ViewCardAddressEmailPhone from "../../components/viewAddressEmailPhone/viewCard";
const styles = (theme) => ({
  paperCard: {
    position: "relative",
    borderRadius: "0px",
    boxShadow: `none`,
  },
  strong_name: {
    paddingLeft: 0,
    paddingRight: 12,
    color: theme.palette.ternary.main,
    marginLeft: "28px",
    boxShadow: "unset !important",
  },
  gridItems: {
    padding: "25px 25px 40px 32px",
  },
  gridTopItems: {
    padding: "25px 25px 0px 32px",
  },
  gridMiddleItems: {
    padding: "0px 25px 0px 32px",
  },
  gridBottomItems: {
    padding: "0px 25px 40px 32px",
  },
  dividerBackground: {
    backgroundColor: theme.palette.secondary.light,
  },
  divider: {
    // marginTop: 8,
    marginBottom: 26,
  },
  sectionLabel: {
    marginBottom: 26,
  },
  sectionSubHeader: {
    color: theme.palette.primary.main,
    marginBottom: 26,
    paddingLeft: 15,
    textTransform: "upperCase",
  },
});

class InformationDetailCard extends React.Component {
  handleIcons = (iconName) => {
    const { theme } = this.props
    switch (iconName) {
      case "RECEIVED BY":
        return ICONS.RECEIVEDANDASSIGNEDCALL(theme.palette.primary.main);
      case "ASSIGNED TO":
        return ICONS.RECEIVEDANDASSIGNEDCALL(theme.palette.primary.main);
      case "NAME":
        return ICONS.PERSON(theme.palette.primary.main);
      case "MOBILE":
        return ICONS.CLIENTPHONE(theme.palette.primary.main);
      case "ADDRESS":
        return ICONS.LOCATION(theme.palette.primary.main);
      case "RELATIONSHIP":
        return ICONS.RELATION(theme.palette.primary.main);

      default:
        return ICONS.CLIENTID(theme.palette.primary.main);
    }
  };
  render() {
    const { classes, loading, data } = this.props;
    return (
      <Paper className={classes.paperCard}>
        <Grid container>
          {/* Block-1 */}

          <Grid container item xs={12}>
            <Grid
              container
              item
              direction="row"
              md={12}
              sm={12}
              xs={12}
              className={classes.gridTopItems}
            >
              {Object.keys(data).map((sections, index) => {
                return (
                  <Grid
                    container
                    alignItems={"flex-start"}
                    className={classes.container}
                    direction="row"
                    // spacing={2}
                    key={index}
                  >
                    {data?.[sections]?.label && (
                      <Grid container item xs={12} alignItems="center">
                        <Typography
                          variant="h6"
                          component="h6"
                          className={classes.sectionLabel}
                        >
                          {data[sections].label}
                        </Typography>

                        {data[sections]?.subHeader && (
                          <Typography
                            variant="caption"
                            className={classes.sectionSubHeader}
                          >
                            {data[sections].subHeader}
                          </Typography>
                        )}
                      </Grid>
                    )}
                    {Object.values(data[sections]?.fields)?.map((data) => {
                      return (
                        <Grid item xs={12} sm={6} md={4}>
                          {data.keydata === "ADD_ADDRESS" || data.keydata === "ADD_ID_TYPE" || data.keydata === "ADD_PHONE_NUMBER" ?
                            <ViewCardAddressEmailPhone
                              title={""}
                              height={16}
                              width={16}
                              style={{ position: "relative", top: 2 }}
                              data={data.dataList}
                              emptyMessage="Not yet added"
                              element={data.keydata}
                              labels={"Address"}
                              onButtonClickHandler={() =>
                                data.functions(
                                  data.dataList,
                                  data.keydata,
                                  "Address"
                                )
                              }
                            /> : <HybridTypography
                              text={data.value ? data.value : "N/A"}
                              header={data.label ? data.label : ""}
                              loading={loading}
                              islistbottom={true}
                              icon={this.handleIcons(data.label)}
                            />}
                        </Grid>
                      );
                    })}

                    {Object.keys(data).length - 1 > index && (
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles, {withTheme: true})(InformationDetailCard);

InformationDetailCard.propTypes = {
  data: PropTypes.object,
};
