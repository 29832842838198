import { routes } from "../../../router/routes";
import { errorMessage } from "../../../utils/errorMessages";

export const allPersonaIds = {
  service_professional: "5d07b0ce-f64f-451b-9917-81d4b55d92d5",
  service_supervisor: "cd76f5e5-643a-40a9-b313-74c171ed8aec",
  service_coordinator: "50c071c3-f6b8-48d4-b9ba-4a0ae4f9149c",
  other_resources: "877f9d78-8488-4904-b944-8f90599e25f3",
  service: "b6aea2a2-f752-4e82-9db4-bc6514f9f2ea",
  package: "3c968f29-e785-4910-b62a-b7a8a8960c23",
  product: "8641fabf-feb6-4b14-b060-5ffe75388d1c"
};
export const resourceTypeName = {
  persona: "Persona",
  item: "Item",
};
export const getPersonaId = (url) => {
  let id = null;
  let name = null;
  switch (url) {

    // COORD
    case routes.add_care_coordinators:
    case routes.edit_care_coordinators:
    case routes.manage_care_coordinators:
    case routes.view_care_coordinators:
      id = allPersonaIds.service_coordinator;
      name = resourceTypeName.persona;
      break;


    // OTHER RESOURCES
    case routes.add_other_resources:
    case routes.edit_other_resources:
    case routes.manage_other_resources:
    case routes.view_other_resources:
      id = allPersonaIds.other_resources;
      name = resourceTypeName.item;
      break;

    // SUPERVISORS
    case routes.add_supervisors:
    case routes.edit_supervisors:
    case routes.manage_supervisors:
    case routes.view_supervisors:
      id = allPersonaIds.service_supervisor;
      name = resourceTypeName.persona;
      break;

    // CARE GIVERS
    case routes.add_care_givers:
    case routes.edit_care_givers:
    case routes.manage_care_givers:
    case routes.view_care_givers:
    case routes.monitor_service_professionals:
      id = allPersonaIds.service_professional;
      name = resourceTypeName.persona;
      break;
    
      
    // product management
    // service
    case routes.add_service:
    case routes.manage_service:
      id = allPersonaIds.service;
      name = resourceTypeName.persona;
      break;

    // package
    case routes.add_package:
    case routes.manage_package:
      id = allPersonaIds.package;
      name = resourceTypeName.persona;
      break;

    // product
    case routes.add_product:
    case routes.manage_product:
      id = allPersonaIds.product;
      name = resourceTypeName.persona;
      break;

    default:
      id = null;
      name = null;
  }
  return { id, name };
};

export const viewScreensBasedOnPersona = (url) => {
  switch (url) {
    case routes.manage_supervisors:
      return `${routes.manage_supervisors}/view/`;
    case routes.manage_care_coordinators:
      return `${routes.manage_care_coordinators}/view/`;
    case routes.manage_care_givers:
      return `${routes.manage_care_givers}/view/`;
    case routes.manage_other_resources:
      return `${routes.manage_other_resources}/view/`;
    case routes.monitor_service_professionals:
      return `${routes.monitor_service_professionals}/summary/`;
    default:
      return url;
  }
  // `${routes.partner_management_provider}view/`
};


export const viewScreensBasedOnPersonaByResourceTypeId = (resourceTypeId) => {

  switch (resourceTypeId) {
    case allPersonaIds.service_supervisor:
      return `${routes.manage_supervisors}/view/`;
    case allPersonaIds.service_coordinator:
      return `${routes.manage_care_coordinators}/view/`;
    case allPersonaIds.service_professional:
      return `${routes.manage_care_givers}/view/`;
    case allPersonaIds.other_resources:
      return `${routes.manage_other_resources}/view/`;
    default:
      return `${routes.partner_management_branch}view/`;
  }
  // `${routes.partner_management_provider}view/`
};

export const actionbuttonNameBasedOnPersona = (url) => {
  switch (url) {
    case routes.manage_supervisors:
      return `+ ADD SERVICE SUPERVISOR`;
    case routes.manage_care_coordinators:
      return `+ ADD SERVICE COORDINATOR`;
    case routes.manage_care_givers:
      return `+ ADD SERVICE PROFESSIONAL`;
    case routes.manage_other_resources:
      return `+ ADD OTHER RESOURCE`;
    default:
      return url;
  }
  // "+ ADD PROVIDER"
};
export const personaName = (url) => {
  switch (url) {
    case routes.manage_supervisors:
      return `Service Supervisor`;
    case routes.manage_care_coordinators:
      return `Service Coordinator`;
    case routes.manage_care_givers:
      return `Service Professional`;
    case routes.manage_other_resources:
      return `Other Resource`;
    case routes.view_supervisors:
      return `Service Supervisor`;
    case routes.view_care_coordinators:
      return `Service Coordinator`;
    case routes.view_care_givers:
      return `Service Professional`;
    case routes.view_other_resources:
      return `Other Resource`;
    default:
      return url;
  }
  // "+ ADD PROVIDER"
};

export const actionbuttonUrlBasedOnPersona = (url, id) => {
  if (id) {
    switch (url) {
      case routes.view_supervisors:
        return `${routes.manage_supervisors}/edit/${id}`;
      case routes.view_care_coordinators:
        return `${routes.manage_care_coordinators}/edit/${id}`;
      case routes.view_care_givers:
        return `${routes.manage_care_givers}/edit/${id}`;
      case routes.view_other_resources:
        return `${routes.manage_other_resources}/edit/${id}`;
      default:
        return url;
    }
  } else {
    switch (url) {
      case routes.manage_supervisors:
        return `${routes.manage_supervisors}/add`;
      case routes.manage_care_coordinators:
        return `${routes.manage_care_coordinators}/add`;
      case routes.manage_care_givers:
        return `${routes.manage_care_givers}/add`;
      case routes.manage_other_resources:
        return `${routes.manage_other_resources}/add`;
      default:
        return url;
    }
  }
  // `${routes.partner_management_provider}view/`
};

export const totalCountLabel = (url) => {
  switch (url) {
    case routes.manage_supervisors:
      return `Total Service Supervisor`;
    case routes.manage_care_coordinators:
      return `Total Service Coordinator`;
    case routes.manage_care_givers:
      return `Total Service Professional`;
    case routes.manage_other_resources:
      return `Total Other Resource`;
    default:
      return url;
  }
};

export const allResourceLabel = (url) => {
  switch (url) {
    case routes.add_supervisors:
      return `Service Supervisor`;
    case routes.add_care_coordinators:
      return `Service Coordinator`;
    case routes.add_care_givers:
      return `Service Professional`;
    case routes.add_other_resources:
      return `Other Resource`;
    case routes.edit_supervisors:
      return `Service Supervisor`;
    case routes.edit_care_coordinators:
      return `Service Coordinator`;
    case routes.edit_care_givers:
      return `Service Professional`;
    case routes.edit_other_resources:
      return `Other Resource`;
    case routes.view_supervisors:
      return `Service Supervisor`;
    case routes.view_care_coordinators:
      return `Service Coordinator`;
    case routes.view_care_givers:
      return `Service Professional`;
    case routes.view_other_resources:
      return `Other Resource`;
    default:
      return url;
  }
  // "+ ADD PROVIDER"
};

export const allResourceAliasLabel = (url) => {
  switch (url) {
    case routes.view_supervisors:
      return `Supervisor ID`;
    case routes.view_care_coordinators:
      return `Coordinator ID`;
    case routes.view_care_givers:
      return `Professional ID`;
    case routes.view_other_resources:
      return `OtherResource ID`;
    default:
      return ``;
  }
};

export const redirectURL = (url) => {
  switch (url) {
    case routes.add_supervisors:
      return routes.manage_supervisors;
    case routes.add_care_coordinators:
      return routes.manage_care_coordinators;
    case routes.add_care_givers:
      return routes.manage_care_givers;
    case routes.add_other_resources:
      return routes.manage_other_resources;
    case routes.edit_supervisors:
      return routes.manage_supervisors;
    case routes.edit_care_coordinators:
      return routes.manage_care_coordinators;
    case routes.edit_care_givers:
      return routes.manage_care_givers;
    case routes.edit_other_resources:
      return routes.manage_other_resources;
    default:
      return url;
  }
  // "+ ADD PROVIDER"
};

export const labelforresource = (url, loadingAdd, loadingEdit) => {
  switch (url) {
    case routes.add_supervisors:
      return loadingAdd ? "Adding..." : "Add";
    case routes.add_care_coordinators:
      return loadingAdd ? "Adding..." : "Add";
    case routes.add_care_givers:
      return loadingAdd ? "Adding..." : "Add";
    case routes.add_other_resources:
      return loadingAdd ? "Adding..." : "Add";
    case routes.edit_supervisors:
      return loadingEdit ? "Updating..." : "Update";
    case routes.edit_care_coordinators:
      return loadingEdit ? "Updating..." : "Update";
    case routes.edit_care_givers:
      return loadingEdit ? "Updating..." : "Update";
    case routes.edit_other_resources:
      return loadingEdit ? "Updating..." : "Update";
    default:
      return url;
  }
};

export const generateDropDownLabel = (url) => {
  let label = "";
  let { id } = getPersonaId(url);
  switch (id) {
    case allPersonaIds.other_resources:
      label = "Please choose Resource Type";

      break;

    case allPersonaIds.service_professional:
      label = "Please choose Role Type";
      break;

    default:
      label = "";
  }
  return label;
};


//Error Messages
export const giveMeEmptyMessage = (isFilterApplied, isSearchApplied, url) => {
  if (isFilterApplied && isSearchApplied) {
    return errorMessage.notFoundFilterSearch;
  } else if (isSearchApplied) {
    return errorMessage.notFoundSearch;
  } else if (isFilterApplied) {
    return errorMessage.notFoundFilter;
  } else {
    switch (url) {
      case routes.manage_supervisors:
        return `You don't have any supervisors yet`;
      case routes.manage_care_coordinators:
        return `You don't have any coordinators yet`;
      case routes.manage_care_givers:
        return `You don't have any professionals yet`;
      case routes.manage_other_resources:
        return `You don't have any other resources yet`;
      default:
        return "No Results Found";
    }
  }
};


export const getResourceName = (url) => {
  switch (url) {
    case routes.manage_supervisors:
      return `Service Supervisor`;
    case routes.manage_care_coordinators:
      return `Service Coordinator`;
    case routes.manage_care_givers:
      return `Service Professional`;
    case routes.manage_other_resources:
      return `Other Resource`;
    default:
      return '';
  }
}

