const routes = {
  //Testing Components
  testing_route: "/testing_route",

  //Auth routes
  login: "/login",
  forgotPassword: "/forgot_password",
  resetPassword: "/reset_password/:id",
  manageResetPassword: "/reset_password/",
  expiredLink: "/expired/:id",

  changePassword: "/change_password/:id",
  privacypolicy: "/privacy_policy",
  termsandconditions: "/terms_and_conditions",

  //Dashboard routes
  dashboard: "/dashboard",

    // Manage Forms
    manage_forms: "/manage_forms",
    manage_form_detail_parent: "/manage_forms/",
    manage_form_detail: "/manage_forms/:id",

  //Management routes
  management: "/management",
  //1. User Management routes
  user_management: "/management/user",

  //1.a Care Provider routes
  manage_care_providers: "/management/user/care_provider",
  add_care_providers: "/management/user/care_provider/add",
  edit_care_providers: "/management/user/care_provider/edit/:id",
  view_care_providers: "/management/user/care_provider/view/:id",

  //1.b Care Giver routes
  manage_care_givers: "/management/resource_management/care_giver",
  add_care_givers: "/management/resource_management/care_giver/add",
  edit_care_givers: "/management/resource_management/care_giver/edit/:id",
  view_care_givers: "/management/resource_management/care_giver/view/:id",

  //1.c Care Coordinator routes
  manage_care_coordinators: "/management/resource_management/care_coordinator",
  add_care_coordinators: "/management/resource_management/care_coordinator/add",
  edit_care_coordinators: "/management/resource_management/care_coordinator/edit/:id",
  view_care_coordinators: "/management/resource_management/care_coordinator/view/:id",


//1.other Resources
manage_other_resources: "/management/resource_management/other_resources",
add_other_resources: "/management/resource_management/other_resources/add",
edit_other_resources: "/management/resource_management/other_resources/edit/:id",
view_other_resources: "/management/resource_management/other_resources/view/:id",

  //1.d Client routes
  manage_clients: "/management/user/clients",
  add_clients: "/management/user/clients/add",
  edit_clients: "/management/user/clients/edit/:id",
  view_clients: "/management/user/clients/view/:id",

  //1.e Supervisor routes
  manage_supervisors: "/management/resource_management/supervisor",
  add_supervisors: "/management/resource_management/supervisor/add",
  edit_supervisors: "/management/resource_management/supervisor/edit/:id",
  view_supervisors: "/management/resource_management/supervisor/view/:id",

  //2. Product Management
  product_management: "/management/product_management",

  //2.a Service routes
  manage_service: "/management/product_management/services",
  add_service: "/management/product_management/services/add",
  edit_service: "/management/product_management/services/edit/:id/:versionId",
  view_service: "/management/product_management/services/view/:id/:versionId",

  //2.b Package routes
  manage_package: "/management/product_management/packages",
  add_package: "/management/product_management/packages/add",
  edit_package: "/management/product_management/packages/edit/:id/:versionId",
  view_package: "/management/product_management/packages/view/:id/:versionId",

  //2.c Products routes
  manage_product: "/management/product_management/products",
  add_product: "/management/product_management/products/add",
  edit_product: "/management/product_management/products/edit/:id/:versionId",
  view_product: "/management/product_management/products/view/:id/:versionId",

  //2.d Promotional Banner routes
  manage_promotional_banner: "/management/product_management/promotional_banner",
  add_promotional_banner: "/management/product_management/promotional_banner/add",
  edit_promotional_banner: "/management/product_management/promotional_banner/edit/:id",
  view_promotional_banner: "/management/product_management/promotional_banner/view/:id",

  //3. Availability
  availability_calendar: "/management/availability/calendar/:variant",
  availability_calendar_main: "/management/availability/calendar/",
  manage_availability: "/management/availability/manage",

  add_exception: "/management/availability/manage/exception/add",
  edit_exception: "/management/availability/manage/exception/edit/:id",
  edit_exception_main: "/management/availability/manage/exception/edit/",

  set_availability: "/management/availability/manage/availability/add",
  edit_availability: "/management/availability/manage/availability/edit/:id",
  edit_availability_main: "/management/availability/manage/availability/edit/",

  view_availabiliy_history:
    "/management/availability/manage/availability/history",

  //Monitoring routes
  monitoring: "/monitoring",

  //1. Service Professionals routes
  monitor_service_professionals: "/monitoring/service_professionals",

  //1.a Service Professional Summary
  monitor_service_professionals_summary:
    "/monitoring/service_professionals/summary/:id",
  monitor_service_professionals_summary_parent:
    "/monitoring/service_professionals/summary/",

  //1.b Service Professional Schedules
  monitor_service_professionals_schedules:
    "/monitoring/service_professionals/schedules/:id/:variant",
  monitor_service_professionals_schedules_parent:
    "/monitoring/service_professionals/schedules/",

  //1.c Service Professional Tracking
  monitor_service_professionals_tracking:
    "/monitoring/service_professionals/tracking/:id",
  monitor_service_professionals_tracking_parent:
    "/monitoring/service_professionals/tracking/",

  //1.d Service Professional Clients
  monitor_service_professionals_clients:
    "/monitoring/service_professionals/clients/:id",
  monitor_service_professionals_clients_parent:
    "/monitoring/service_professionals/clients/",

  //1.e Service Professional Feebacks
  monitor_service_professionals_feebacks:
    "/monitoring/service_professionals/feebacks/:id",
  monitor_service_professionals_feebacks_parent:
    "/monitoring/service_professionals/feebacks/",

  //2. Clients routes
  monitor_clients: "/monitoring/client",

  //2.a Details routes
  monitor_client_details: "/monitoring/client/details/:client_id",
  monitor_client_detail_parent: "/monitoring/client/details/",

  //2.b Report routes
  monitor_client_reports: "/monitoring/client/reports/:client_id",
  monitor_client_report_parent: "/monitoring/client/reports/",

  //2.c Issues routes
  monitor_client_issues: "/monitoring/client/issues/:client_id",
  monitor_client_issue_parent: "/monitoring/client/issues/",
  monitor_client_issue_detail: "/monitoring/client/issues/:client_id/:issue_id",

  //2.d Recommendation routes
  monitor_client_recommendation:
    "/monitoring/client/recommendations/:client_id",
  monitor_client_recommendation_parent: "/monitoring/client/recommendations/",
  monitor_client_recommended_products:
    "/monitoring/client/recommendations/products/:client_id",
  monitor_client_recommended_product_tracking:
    "/monitoring/client/recommendations/tracking/:client_id/:product_id",

  //2.e Activities routes
  monitor_client_activities: "/monitoring/client/activities/:client_id/:type",
  monitor_client_activitie_parent: "/monitoring/client/activities/",

  //2.f Rating routes
  monitor_client_ratings: "/monitoring/client/ratings/:client_id",
  monitor_client_rating_parent: "/monitoring/client/ratings/",

  //3. Subscriptions
  monitor_subscription: "/monitoring/subscription",
  monitor_subscription_detail_parent: "/monitoring/subscription/detail/",
  monitor_subscription_detail: "/monitoring/subscription/detail/:id",

  // Sales Operations
  sales_operation: "/sales_operations",
  enquiry_list: "/sales_operations/enquiries",
  add_enquiry: "/sales_operations/enquiries/add",
  edit_enquiry: "/sales_operations/enquiries/edit/:id",
  view_enquiry: "/sales_operations/enquiries/view/:id",

  // Administration
  administration_masters: "/administration/masters",
  administration_users: "/administration/users",
  administation_tax: "/administration/tax",
  administation_configuration: "/administration/configuration",

  // Partner management
  partner_management_provider: "/management/partner_management/provider/",

  partner_management_provider_add: "/management/partner_management/provider/add",
  partner_management_provider_edit: "/management/partner_management/provider/edit/:id",
  partner_management_provider_detail: "/management/partner_management/provider/view/:id",

    

  partner_management_branch: "/management/partner_management/branch/",

    partner_management_branch_add: "/management/partner_management/branch/add/:id",
    partner_management_branch_edit: "/management/partner_management/branch/edit/:id",
    partner_management_branch_detail: "/management/partner_management/branch/view/:id",

  //Resource Management

  resource_management: "/management/resource_management",

  //2.a Service routes
  resource_management_add: "/management/resource_management/add",
  resource_management_edit: "/management/resource_management/edit/:id",
  resource_management_detail: "/management/resource_management/view/:id",

    //Availability Management

    availability_management: "/availability_management",

    //2.a Service routes
    availability_management_list: "/availability_management/list/id",
    availability_management_add: "/availability_management/add/:id",
    availability_management_edit: "/availability_management/edit/:id",
    availability_management_detail: "/availability_management/view/:id",


    // form configurator
    form_configurator: "/form_configurator",    
    form_configurator_detail_parent: "/form_configurator/",    
    form_configurator_detail: "/form_configurator/:id",

    // payment redirect
    mobile_payment_redirect: "/paymentRedirect",
    mobile_payment_redirect_ios: "/paymentRedirect/:id",

    //Reports
    transactionReport: "/management/reports/transactionReport",
    orderReport: "/management/reports/orderReport",
    enquiryReport: "/management/reports/enquiryReports",

};

export { routes };
