import React from "react";
import {
  withStyles,
  Grid,
  Avatar,
  Typography,
  Hidden,
} from "@material-ui/core";
// import CallIcon from '@material-ui/icons/Call';
// import MailIcon from '@material-ui/icons/Mail';
// import CallIcon from '../../assets/monitorClientDetailsIcons/index'
import MonitorCallIcon from "../../assets/monitorClientDetailsIcons/monitorCallIcon";
import MonitorMailIcon from "../../assets/monitorClientDetailsIcons/monitorMailIcon";
import { avatarColorBgById, capitalizeFirstLetterOfString } from "../../utils";
import moment from "moment";

// import { CallIcon, EmailIcon } from '../../assets'
// export const ICONS = {
//   CALL: (colorCode) => {
//     return (
//       <CallIcon
//         color={colorCode ? colorCode : "secondary"}
//         style={{
//           height: 16,
//           width: 16,
//           // margin: `4px 8px 8px 0px`,
//         }}
//       />
//     );
//   },
//   EMAIL: (colorCode) => {
//     return (
//       <EmailIcon
//         color={colorCode ? colorCode : "secondary"}
//         style={{
//           height: 16,
//           width: 16,
//           // margin: `10px 8px 8px 0px`,
//         }}
//       />
//     );
//   },
// }

const styles = (theme) => ({
  firstElement: {
    paddingLeft: 10,
    margin: "auto",
  },
  centerFirstElement: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      left: -30,
    },
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: 15,
  },
  clientName: {
    marginTop: 4,
  },
  subHeader: {
    color: "#9297a5",
    marginTop: 2,
  },
  absoluteLine: {
    position: "relative",
    "&::before": {
      content: `''`,
      width: 0.5,
      height: "30px",
      position: "absolute",
      background: theme.palette.secondary.light,
      top: "25%",
      left: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      paddingTop: 20,
      "&::before": {
        content: `''`,
        width: "30vw",
        height: 0.5,
        position: "absolute",
        background: theme.palette.secondary.light,
        top: 0,
        left: "33%",
      },
    },
  },
  centerParent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  flexIconText: {
    display: "flex",
  },
  icon1: {
    marginRight: 10,
  },
  icon2: {
    marginRight: 10,
    position: "relative",
    top: 7,
  },
  lastContainer: {
    width: "100%",
  },
  emergency_text: {
    textTransform: "uppercase",
    color: "#9297a5",
    fontSize: 14,
  },
  emergency_number: {
    background: "#e9e9e9",
    width: "fit-content",
    margin: "auto",
    padding: "4px 12px",
    borderRadius: 8,
    marginTop: 4,
  },
  additionalLastElement: {
    // [theme.breakpoints.only('md')]: {
    //   position: 'unset',
    //   marginTop: 10
    // },
    [theme.breakpoints.between("sm", "md")]: {
      position: "unset",
      marginTop: 10,
    },
  },
});

class Monitor extends React.Component {
  getAge = (birthday) => {
    // let age =
    //   data ?? moment().diff(data, "years") === 0
    //     ? moment().diff(data, "days") + " days old"
    //     : moment().diff(data, "years") + " years old";
    // return age;
    if (birthday) {
      let age = moment().diff(new Date(birthday), "years");

      if (age <= 0) {
        let month = 12 - (new Date(birthday).getMonth() + 1);
        if (month > 0) {
          age = month + (month === 1 ? " Month" : " Months");
        } else {
          let days = moment().diff(new Date(birthday), "days");
          if (days === 0) {
            age = "New Born";
          } else {
            age = days + (days > 1 ? " Days" : " Day");
          }
        }
      } else {
        age =
          moment().diff(new Date(birthday), "years") +
          (age > 1 ? " Years" : " Year");
      }
      return age;
    } else {
      return "";
    }
  };

  render() {
    const { classes, data } = this.props;
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          container
          className={`${classes.firstElement} + ${classes.centerFirstElement}`}
        >
          <Grid item>
            <Avatar
              className={classes.avatar}
              src={data?.profile_pic}
              style={{
                backgroundColor: !data?.profile_pic
                  ? avatarColorBgById(
                      data?.id ??
                        Math.floor(Math.random() * (999 - 100 + 1) + 100)
                    )
                  : "",
              }}
            >
              {data?.name?.trim()?.[0]?.toUpperCase()}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={classes.clientName}>
              {data?.salutationName
                ? data?.salutationName + ". " + data?.name
                : data?.name}
            </Typography>
            <Typography className={classes.subHeader} variant="subtitle2">
              {`${capitalizeFirstLetterOfString(data?.gender)}${this.getAge(data?.dob) !== "" ? `, ${this.getAge(data?.dob)}` : ``}`}
              {/*  */}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          container
          direction={"column"}
          className={classes.absoluteLine}
        >
          {/* className={classes.border_left} */}
          <div className={classes.centerParent}>
            {/* Following div is used for making the text start at left */}
            <div>
              <div className={classes.flexIconText}>
                <span>
                  <MonitorCallIcon className={classes.icon1} />
                </span>{" "}
                {/*{this.props.icons.icon1} */}
                <Typography variant="body1">
                  {data?.mobile_no_isd_code + "-" + data?.mobile_no}
                </Typography>
              </div>
              <div className={classes.flexIconText}>
                <span>
                  <MonitorMailIcon className={classes.icon2} />
                </span>{" "}
                {/*className={classes.icon2} */}
                <Typography variant="body1">{data?.email}</Typography>
              </div>
            </div>
          </div>

          {/* </Grid> */}
          {/* <Grid item xs={12}> */}

          {/* </Grid> */}
          {/* </Grid> */}
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12} sm={2} md={2}></Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={4}
          xl={4}
          container
          className={`${classes.absoluteLine} + ${classes.additionalLastElement} `}
        >
          <div className={classes.lastContainer}>
            {/* Top */}
            <div>
              <Typography align="center" className={classes.emergency_text}>
                emergency contact number
              </Typography>
            </div>
            {/* Bottom */}
            <div>
              <Typography align="center" className={classes.emergency_number}>
                {/* <span>{data?.emergency_contact?.contact_name + ":"}</span>{" "}
                <span className={classes.emergency_number_span}>
                  {data?.emergency_contact?.mobile_no_isd_code +
                    "-" +
                    data?.emergency_contact?.mobile_no}
                </span> */}
                {data?.emergency_contact === {} ? (
                  <>
                  
                    {" "}
                    <span>
                      {data?.emergency_contact?.contact_name + ":"}
                    </span>{" "}
                    <span className={classes.emergency_number_span}>
                      {data?.emergency_contact?.mobile_no_isd_code +
                        " - " +
                        data?.emergency_contact?.mobile_no}
                    </span>{" "}
                  </>
                ) : (
                  <>
                    {data?.familyMembers?.length > 0 ? (
                      <>
                      {/* {
                        console.log(data?.familyMembers)
                      } */}
                        {data?.familyMembers?.map((x) => {
                          if (x?.isEmergencyContact) {
                            return (
                              <>
                                <span>
                                  {x?.otherPhoneNumbers.map((z) => {
                                    if (z.isPrimary) {
                                      return x.name;
                                    }
                                    return ""
                                  }) + ":"}
                                </span>{" "}
                                <span className={classes.emergency_number_span}>
                                  {x?.otherPhoneNumbers.map((z) => {
                                    if (z.isPrimary) {
                                      return (
                                        z?.mobileNoIsdCode + ` - ` + z?.mobileNo
                                      );
                                    }
                                    return ""
                                  })}
                                </span>
                              </>
                            );
                          }
                          return ""
                        })}
                      </>
                    ) : (
                      <>
                      No Emergency Contact Added
                      </>
                    )}
                  </>
                )}
              </Typography>
            </div>
          </div>
          {/* <Grid item xs={12}>
          </Grid>
          <Grid item xs={12}>
            
          </Grid> */}
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12} sm={2} md={2}></Grid>
        </Hidden>
      </Grid>
    );
  }
}

export default withStyles(styles)(Monitor);
