import React, { useRef, useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";
import DateFnsUtils from "@date-io/date-fns";
import { startOfDay, endOfDay } from "date-fns";
import TextField from "@material-ui/core/TextField";
import { LocalizationProvider, DatePicker } from "@material-ui/pickers";
import { IconComponent } from "../../../assets";

const useStyles = makeStyles((theme) => ({
  root: {},
  labelStyle: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 8,
    color: "#878C9A",
    "& .MuiTypography-caption": {
      color: "#F76E8E",
      fontWeight: 400,
    },
  },
  datePicker: {
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        // Added conditional rendering to avoid error in Real time location map, 
        // Where the date picker is rendered using ReactDOM.render() which will not have access to material UI styles
        borderColor: theme?.palette?.primary?.main || "#0063E7",
      },
    },
    "& input": {
      padding: "15.69px 14px !important",
      fontWeight: 500,
      fontSize: 14,
      "&::placeholder": {
        fontWeight: 400,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C7C7C7",
      borderRadius: 10,
    },
    "& .Mui-disabled": {
      background: "#F1F1F1",
      borderRadius: 10,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F1F1F1",
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      },
    },
    "& .MuiFormHelperText-root": {
      marginInline: 0,
      // background:'transparent'
    },
  },
}));

export const DatePickers = (props) => {
  const classes = useStyles(props);

  const ref = useRef(null);

  const {
    handleChange = null,
    variant = "outlined",
    format = "dd/MM/yyyy",
    id = "",
    value = null,
    label = "",
    isRequired = false,
    disabled = false,
    disableFuture = false,
    disablePast = false,
    maxDate = null,
    minDate = null,
    placeHolder = "",
    error = false,
    helperText = "",
    isReadOnly = false,
    isStartOfDay = false,
    isEndOfDay = false,
    popperProps = false,
  } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line
  }, []);

  const handleClick = (e) => {
    if (ref?.current?.popper?.contains(e.target)) return;
    setOpen(false);
  };

  const handleChangeDate = (value) => {
    let val = value;
    if (isStartOfDay) {
      val = startOfDay(val);
    } else if (isEndOfDay) {
      val = endOfDay(val);
    }
    handleChange(val);
  };

  return (
    <div className={classes.root}>
      {label && (
        <Typography className={classes.labelStyle}>
          {label}
          {isRequired && <Typography variant="caption">&nbsp;*</Typography>}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <div className={classes.datePicker}>
          <DatePicker
            open={open}
            onAccept={() => setOpen(false)}
            inputFormat={format}
            value={value}
            onChange={handleChangeDate}
            openPickerIcon={
              <IconComponent
                onClick={() => !isReadOnly && setOpen(true)}
                name={"calendar"}
                color={"#9297a5"}
              />
            }
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            maxDate={maxDate}
            minDate={minDate}
            readOnly={isReadOnly}
            PopperProps={{
              popperRef: ref,
              ...popperProps,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={`datePicker${id}`}
                variant={variant}
                inputProps={{
                  ...params.inputProps,
                  readOnly: isReadOnly,
                }}
                placeholder={placeHolder}
                error={error}
                helperText={error ? helperText : ""}
                fullWidth
              />
            )}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
};

DatePickers.propTypes = {
  handleChange: PropTypes.func,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  format: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  maxDate: PropTypes.bool,
  minDate: PropTypes.bool,
  placeHolder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isStartOfDay: PropTypes.bool,
  isEndOfDay: PropTypes.bool,
};
