import React from "react";
import {
  Paper,
  withStyles,
  Grid,
  Avatar,
  ListItemText,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";

import { HybridTypography } from "../../../../components";
import { ICONS } from "../../../../components/hybridTypography/typography";
import Divider from "@material-ui/core/Divider";
// import SimpleAvatarCard from "../../../../components/simpleAvatarCard";
import { routes } from "../../../../router/routes";
import { withRouter } from "react-router-dom";
import { customDateConverter, formComponentTypes } from "../../../../utils";
import { CertificateIcon, ExperienceIcon } from "../../../../assets";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import moment from "moment";
// customAddress
import ViewAddressEmailPhone from "../../../../components/viewAddressEmailPhone";
import ViewCardAddressEmailPhone from "../../../../components/viewAddressEmailPhone/viewCard";
// import AvatarStack from "../../../../components/avatarStack";
// import ViewProviderBranches from "./ViewProviderBranches";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";

// customAddress
const styles = (theme) => ({
  paperCard: {
    borderRadius: "0px",
    // boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    boxShadow: `none`,
  },
  strong_name: {
    paddingLeft: 0,
    paddingRight: 12,
    color: theme.palette.ternary.main,
    marginLeft: "28px",
    boxShadow: "unset !important",
  },
  gridItems: {
    padding: "25px 25px 40px 32px",
  },
  gridTopItems: {
    padding: "25px 25px 0px 32px",
  },
  gridMiddleItems: {
    padding: "0px 25px 0px 32px",
  },
  gridBottomItems: {
    padding: "0px 25px 40px 32px",
  },
  dividerBackground: {
    backgroundColor: theme.palette.secondary.light,
  },

  isNotBottom: {
    borderRadius: "0px",
    padding: "25px 25px 0px 32px",
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
  },

  attachroot: {
    padding: 10,
    marginLeft: 30,
    border: "1px dashed " + theme.palette.primary.light,
    background: theme.palette.secondary.light,
    marginTop: "10px",
    borderRadius: "6px",
  },
  user_list: {
    display: "grid",
    gridTemplateColumns: "80px 1fr",
  },
  avatarhead: {
    display: "flex",
  },
  user_name: {
    display: "inline-block",
    paddingRight: "12px",
    fontSize: "1rem",
  },
  avatar: {
    width: "50px",
    height: "50px",
    margin: "auto",
  },
  secondary: {
    color: theme.palette.secondary.main,
    fontSize: "14px",
    paddingTop: "7px",
  },
  marginHeight: {
    marginTop: "auto",
    marginBottom: "auto",
    background: "white",
  },
  bottomRounded: {
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  avatar__: {
    width: "100%",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    height: "100%",
    display: "flex",
    "& .MuiAvatar-root": {
      margin: "25% auto 0%",
      background: "rgba(0, 0, 0, 0.03)",
      "& img": {
        objectFit: "scale-down",
      },
    },
  },

  title_: {
    fontSize: 15,
    lineHeight: 1.7,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  sub_text: {
    display: "inline-block",
    fontSize: 12,
    margin: "0px 0px 8px 0px",
    lineHeight: 1.6,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  footer: {
    fontWeight: 400,
    fontSize: 12,
  },
  title__: {
    fontSize: "17px",
    fontWeight: 600,
    textAlign: "left",
    marginBottom: 8,
    marginTop: 8,
  },
  add_title__: {
    marginTop: 20,
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "left",
    // color: "#f27d35",
    // color: '#FFA811',
    color: theme.palette.ternary.main,
    cursor: "pointer",
    width: "max-content",
  },
  list: {
    "& li": {
      padding: 0,
    },
    "& .MuiListItem-gutters": {
      borderBottom: "1px solid #f3f3f3",
    },
  },
  listWithOutBorderBottok: {
    "& li": {
      padding: 0,
    },
    // "& .MuiListItem-gutters": {
    //   borderBottom: "1px solid #f3f3f3",
    // }
  },

  arr: {
    marginBottom: -6,
    fontSize: 20,
  },
  listTextSpacing: {
    marginBottom: theme.spacing(1),
  },

  certificateExperienceAvatarWrapper: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  certificateExperienceAvatar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 60,
    width: 60,
  },
});

class DetailsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slice_start: 0,
      slice_end: 2,
      slice_start_e: 0,
      slice_end_e: 2,
    };
  }
  dateformat = (st,snd,present) => {
    if (present) {
      const a = moment(st).format("DD MMM, YYYY");
      const c = a + " - Present";
      return c;
    } else {
      const a = moment(st).format("DD MMM, YYYY");
      const b = moment(snd).format("DD MMM, YYYY");
      const a1 = moment(st).format("YYYY");
      const a2 = moment(snd).format("YYYY");
      const a3 = JSON.parse(a2) - JSON.parse(a1);
      const b1 = moment(st).format("l").split("/")[0];
      const b2 = moment(snd).format("l").split("/")[0];
      const b3 = JSON.parse(b2) - JSON.parse(b1);
      const c = a + " - " + b + " • " + a3 + " yr " + b3 + " mos ";
      return c;
    }
  };
  dateformat1 = (st, snd) => {
    const b11 = moment(st).format("YYYY").split("/")[0];
    const b21 = moment(snd).format("YYYY").split("/")[0];
    const ck = JSON.parse(b21) - JSON.parse(b11);
    const cks = ck > 100;
    if (cks) {
      const a = moment(st).format("DD MMM, YYYY");
      const c = a + " - No Expiration date";
      return c;
    } else {
      const a = moment(st).format("DD MMM, YYYY");
      const b = moment(snd).format("DD MMM, YYYY");
      const a1 = moment(st).format("YYYY");
      const a2 = moment(snd).format("YYYY");
      const a3 = JSON.parse(a2) - JSON.parse(a1);
      const b1 = moment(st).format("l").split("/")[0];
      const b2 = moment(snd).format("l").split("/")[0];
      const b3 = JSON.parse(b2) - JSON.parse(b1);
      const c = a + " - " + b + " • " + a3 + " yr " + b3 + " mos ";
      return c;
    }
  };
  handleoffset = (end) => {
    if (end === this.state.slice_end) {
      return this.setState({
        ...this.state,
        slice_start: 0,
        slice_end: 2,
      });
    } else {
      this.setState({
        ...this.state,
        slice_start: 0,
        slice_end: end,
      });
    }
  };
  handleoffset_e = (end) => {
    if (end === this.state.slice_end_e) {
      return this.setState({
        ...this.state,
        slice_start_e: 0,
        slice_end_e: 2,
      });
    } else {
      this.setState({
        ...this.state,
        slice_start_e: 0,
        slice_end_e: end,
      });
    }
  };
  handleOnSupervisorClicked = (id) => {
    this.props.history.push(`${routes.manage_supervisors}/view/${id}`);
  };

  handleServiceProfessionalsViewAllClick = (
    data,
    type,
    label,
    isDontAppendString
  ) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewAddressEmailPhone
          data={data ?? []}
          drawer={this.props.drawer}
          onEndIconClickHandler={this.onClickIcon}
          type={type}
          label={
            label
              ? label?.toLowerCase()?.replace("select ", "").toUpperCase()
              : ""
          }
          isDontAppendString={isDontAppendString}
        />
      ),
    });
  };
  checkConditionsBeforeRender = (data, elementType, elementObj) => {
    let _data = data ?? "N/A";

    if (
      elementType === formComponentTypes.DATE_RANGE_PICKER ||
      elementType === formComponentTypes.DATE_TIME_PICKER
    ) {
      return customDateConverter(_data);
    } else if (elementType === formComponentTypes.MOBILE_INPUT_BOX) {
      if (elementObj["key"]["select_key"] === "salutation") {
        return `${data[elementObj["key"]["input_value_key"]]}`;
      } else {
        return `${data[elementObj["key"]["select_key"]]} ${data[elementObj["key"]["input_value_key"]]
          }`;
      }
    } else if (elementObj?.["value"]?.constructor === Object) {
      return elementObj?.["value"]?.label;
    } else return _data;
  };

  isShowCertificateExperienceSection = (data, key) => {
    if (data && Object.keys(data)?.length > 0) {
      switch (key) {
        case formComponentTypes.ADD_CERTIFICATION:
          return Boolean(
            Object.keys(data).filter(
              (val) =>
                data[val].element === formComponentTypes.ADD_CERTIFICATION
            ).length > 0
          );
        case formComponentTypes.ADD_EXPERIENCE:
          return Boolean(
            Object.keys(data).filter(
              (val) => data[val].element === formComponentTypes.ADD_EXPERIENCE
            ).length > 0
          );
        default:
          return false;
      }
    } else {
      return false;
    }
  };

  render() {
    const { classes, data, loading, theme } = this.props;
    let { slice_end, slice_start_e, slice_start, slice_end_e } = this.state;
    const MultipleAddressEmailPhoneNumber = (props, idx) => {
      switch (props.element) {
        case "ADD_ADDRESS":
          return (
            <ViewCardAddressEmailPhone
              title={props?.label}
              id={`ViewMapped${props?.label?.replace(/\s/g, "")}${this.props?.id}`}
              height={16}
              width={16}
              style={{ position: "relative", top: 2 }}
              data={props.data}
              emptyMessage="Not yet added"
              element={props.element}
              label={`${props?.data?.[0]?.label} Address`}
              onButtonClickHandler={() =>
                this.handleServiceProfessionalsViewAllClick(
                  props?.data,
                  formComponentTypes.ADD_ADDRESS,
                  "Address"
                )
              }
              isListMiddle={true}
            />
            // <></>
          );
        case "ADD_EMAIL":
          return (
            <ViewCardAddressEmailPhone
              title={props?.label}
              id={`ViewMapped${props?.label?.replace(/\s/g, "")}${this.props?.id}`}
              height={16}
              width={16}
              style={{ position: "relative", top: 2 }}
              data={props.data}
              emptyMessage="Not yet added"
              element={props.element}
              label={`${props?.data?.[0]?.label} Email`}
              onButtonClickHandler={() =>
                this.handleServiceProfessionalsViewAllClick(
                  props?.data,
                  formComponentTypes.ADD_EMAIL,
                  "Email"
                )
              }
              // isListTop={ idx < 3 ? true : false}
              // isListMiddle={idx > 2 && idx < Object.keys(data[0]?.resourceMeta)?.length-3}
              isListMiddle={true}
            />
          );
        case "ADD_PHONE_NUMBER":
          return (
            <ViewCardAddressEmailPhone
              title={props?.label}
              id={`ViewMapped${props?.label?.replace(/\s/g, "")}${this.props?.id}`}
              height={16}
              width={16}
              style={{ position: "relative", top: 2 }}
              data={props.data}
              emptyMessage="Not yet added"
              element={props.element}
              label={`${props?.data?.[0]?.label} Phone Number`}
              onButtonClickHandler={() =>
                this.handleServiceProfessionalsViewAllClick(
                  props?.data,
                  formComponentTypes.ADD_PHONE_NUMBER,
                  "Phone Number"
                )
              }
              isListMiddle={true}
            />
          );
        case formComponentTypes.ADD_ID_TYPE:
          return (
            <ViewCardAddressEmailPhone
              title={props?.label}
              id={`ViewMapped${props?.label?.replace(/\s/g, "")}${this.props?.id}`}
              height={16}
              width={16}
              style={{ position: "relative", top: 2 }}
              data={props.data}
              emptyMessage="Not yet added"
              element={props.element}
              label={`${props?.data?.[0]?.label}`}
              onButtonClickHandler={() =>
                this.handleServiceProfessionalsViewAllClick(
                  props?.data,
                  formComponentTypes.ADD_ID_TYPE,
                  "Id Type"
                )
              }
              isListMiddle={true}
            />
          );
        default:
          return (
            <HybridTypography
              icon={ICONS.BUSINESS(theme.palette.primary.main)}
              list={
                props?.data
                  ? props.data?.map((val) => {
                    return val?.label ?? "";
                  })
                  : []
              }
              header={
                props?.label
                  ? props?.label
                    ?.toLowerCase()
                    ?.replace("select ", "")
                    ?.toUpperCase()
                  : ""
              }
              loading={loading}
              // islistbottom={true}
              // islisttop={true}
              // islisttop={ idx < 3 ? true : false}
              // isListMiddle={idx > 2 && idx < Object.keys(data[0]?.resourceMeta)?.length-3}
              isListMiddle={true}
              handleViewMore={() =>
                this.handleServiceProfessionalsViewAllClick(
                  props?.data,
                  "array_list",
                  props?.label,
                  true
                )
              }
            />
            // <></>
          );
      }
    };
    return (
      <Paper className={classes.paperCard}>
        <Grid container>
          <Grid
            container
            item
            direction="row"
            md={12}
            sm={12}
            xs={12}
            className={classes.gridMiddleItems}
          >
            {data?.length > 0 &&
              Object.keys(data[0].resourceMeta).map((key, idx) => {
                if (!data?.[0]?.resourceMeta[key]?.isDontShowInDetail) {
                  if (
                    data[0].resourceMeta[key].element !==
                    formComponentTypes.PROFILE_UPLOAD_BUTTON &&
                    // data[0].resourceMeta[key].element !==
                    //   formComponentTypes.SELECT_BOX &&
                    data[0].resourceMeta[key].element !==
                    formComponentTypes.ADD_CERTIFICATION &&
                    data[0].resourceMeta[key].element !==
                    formComponentTypes.ADD_EXPERIENCE &&
                    data[0].resourceMeta[key].element !== formComponentTypes.RESOURCE_VALIDITY
                  ) {
                    if (
                      data?.[0]?.resourceMeta[key]?.value?.constructor === Array
                    ) {
                      if (data?.[0]?.resourceMeta[key]?.value?.length > 0) {
                        return (
                          <Grid item xs={12} sm={6} md={4}>
                            {
                              <MultipleAddressEmailPhoneNumber
                                id={this?.props?.id}
                                data={data?.[0]?.resourceMeta[key]?.value}
                                element={data?.[0]?.resourceMeta[key]?.element}
                                label={data?.[0]?.resourceMeta[key]?.label}
                                idx={idx}
                              />
                            }
                          </Grid>
                        );
                      } else return null;
                    } else {
                      if (
                        data?.[0]?.resourceMeta[key]?.element ===
                        formComponentTypes.RICHTEXTEDITOR
                      ) {
                        return (
                          <Grid item xs={12} sm={6} md={4}>
                            <HybridTypography
                              icon={ICONS.BUSINESS(theme.palette.primary.main)}
                              text={this.checkConditionsBeforeRender(
                                data?.[0]?.resourceMeta[key]?.value,
                                data[0].resourceMeta[key].element,
                                data?.[0]?.resourceMeta[key]
                              )}
                              header={
                                data?.[0]?.resourceMeta[
                                  key
                                ]?.label?.toUpperCase() ?? "N/A"
                              }
                              loading={loading}
                              // islisttop={ idx < 3 ? true : false}
                              // isListMiddle={idx > 2 && idx < Object.keys(data[0]?.resourceMeta)?.length-3}
                              isListMiddle={true}
                              isdangerouslySetInnerHTML={true}
                            />
                          </Grid>
                        );
                      }
                      return (
                        <Grid item xs={12} sm={6} md={4} style={{ "overflow-wrap": "anywhere" }}>
                          <HybridTypography
                            icon={ICONS.BUSINESS(theme.palette.primary.main)}
                            text={this.checkConditionsBeforeRender(
                              data?.[0]?.resourceMeta[key]?.value,
                              data[0].resourceMeta[key].element,
                              data?.[0]?.resourceMeta[key]
                            )}
                            header={
                              data?.[0]?.resourceMeta[
                                key
                              ]?.label?.toUpperCase() ?? "N/A"
                            }
                            loading={loading}
                            // islistbottom={true}
                            // islisttop={ idx < 3 ? true : false}
                            // isListMiddle={idx > 2 && idx < Object.keys(data[0]?.resourceMeta)?.length-3}
                            isListMiddle={true}
                          />
                          {/* <></> */}
                        </Grid>
                      );
                    }
                  } else return null;
                } else {
                  return <></>;
                }
              })}
          </Grid>

          {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider className={classes.dividerBackground} />
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            className={classes.gridBottomItems}
            style={{ marginTop: 26 }}
          >
            <SimpleAvatarCard
              id={
                data?.service_supervisor?.[0]?.id
                  ? data?.service_supervisor?.[0]?.id
                  : data?.service_supervisor?.[0]?.value
              }
              onAvatarClicked={(id) => this.handleOnSupervisorClicked(id)}
              title={"SERVICE SUPERVISOR NAME"}
              loading={loading}
              name={data?.service_supervisor?.[0]?.label ?? ""}
              src={data?.service_supervisor?.[0]?.profile_pic ?? ""}
              isactive={
                data?.service_supervisor?.[0]?.isactive === undefined
                  ? data?.service_supervisor?.[0]?.is_active
                  : data?.service_supervisor?.[0]?.isactive
              }
            />
          </Grid> */}
          {this.isShowCertificateExperienceSection(
            data[0]?.resourceMeta,
            formComponentTypes.ADD_CERTIFICATION
          ) && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Divider className={classes.dividerBackground} />
                </Grid>

                <div style={{ padding: "8px 25px", width: "100%" }}>
                  <Grid container direction="row" spacing={4}>
                    <div style={{ padding: "20px", width: "100%" }}>
                      <Typography className={classes.title__}>
                        {`Certification ${data[0]?.resourceMeta?.certifications?.value?.length !== 0 ? `(${data[0]?.resourceMeta?.certifications?.value?.length})` :`` } `}
                      </Typography>
                      {data[0]?.resourceMeta?.certifications?.value
                        ?.slice(slice_start, slice_end)
                        .map((_list, key) => {
                          return (
                            <Grid container direction="row" spacing={4} xs={12}>
                              <Grid item className={classes.certificateExperienceAvatarWrapper}>
                                {/* mapping avatar */}
                                <div className={classes.avatar__}>
                                  {/* src="/images/icons8-certificate-24.png" */}
                                  <Avatar className={classes.certificateExperienceAvatar}>
                                    <CertificateIcon
                                      style={{ height: 24, width: 24 }}
                                    />
                                  </Avatar>
                                </div>
                                {/* mapping avatar end*/}
                              </Grid>
                              <Grid item xs>
                                {/* mapping details */}
                                <List
                                  className={
                                    data[0]?.resourceMeta?.certifications?.value
                                      ?.length ===
                                      key + 1
                                      ? classes.listWithOutBorderBottok
                                      : classes.list
                                  }
                                >
                                  <ListItem className={classes.listItem}>
                                    <ListItemText
                                      classes={{
                                        primary: classes.listTextSpacing,
                                      }}
                                      primary={
                                        <span className={classes.title_}>
                                          {_list?.certificateName}
                                        </span>
                                      }
                                      secondary={
                                        <React.Fragment>
                                          <span className={classes.sub_text}>
                                            {_list?.organizationName}
                                          </span>
                                          <br />
                                          <span className={classes.footer}>
                                            {(_list?.startingFrom,
                                              _list?.endingIn) && (
                                                <>
                                                  {this.dateformat1(
                                                    _list?.startingFrom,
                                                    _list?.endingIn
                                                  )}
                                                </>
                                              )}
                                          </span>
                                        </React.Fragment>
                                      }
                                    />
                                  </ListItem>
                                </List>
                                {/* mapping details end */}
                              </Grid>
                            </Grid>
                          );
                        })}

                      {data[0]?.resourceMeta?.certifications?.value?.length ===
                        0 && (
                          <Typography variant="body2" color="textSecondary">
                            {"No certificate added yet"}
                          </Typography>
                        )}
                      {/* Removed the show more section, if, the items length is less than or equal to 2 */}
                      {data[0]?.resourceMeta?.certifications?.value?.length !== 0 && data[0]?.resourceMeta?.certifications?.value?.length > 2 && (
                        <>
                          <Typography
                            className={classes.add_title__}
                            id={`showMoreCertificates`}
                            onClick={() =>
                              this.handleoffset(
                                data[0]?.resourceMeta?.certifications?.value
                                  ?.length
                              )
                            }
                          >
                            {slice_end > 2 ? (
                              <>
                                Show Less Certificates{" "}
                                <ExpandLess className={classes.arr} />
                              </>
                            ) : (
                              <>
                                Show More Certificates
                                <ExpandMore className={classes.arr} />
                              </>
                            )}
                          </Typography>
                        </>
                      )}
                    </div>
                  </Grid>
                </div>
              </>
            )}
          {this.isShowCertificateExperienceSection(
            data[0]?.resourceMeta,
            formComponentTypes.ADD_EXPERIENCE
          ) && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Divider className={classes.dividerBackground} />
                </Grid>
                <div style={{ padding: "8px 25px", width: "100%" }}>
                  <Grid container direction="row" spacing={4}>
                    <div style={{ padding: "20px", width: "100%" }}>
                      <Typography className={classes.title__}>
                        {"Experience"}
                      </Typography>
                      {data[0]?.resourceMeta?.experience?.value
                        ?.slice(slice_start_e, slice_end_e)
                        .map((_list, key) => {
                          return (
                            <Grid container direction="row" spacing={4} xs={12}>
                              <Grid item className={classes.certificateExperienceAvatarWrapper}>
                                {/* mapping avatar */}
                                <div className={classes.avatar__}>
                                  {/* src="/images/icons8-toolbox-24.png" */}
                                  <Avatar className={classes.certificateExperienceAvatar}>
                                    <ExperienceIcon
                                      style={{ height: 24, width: 24 }}
                                    />
                                  </Avatar>
                                </div>
                                {/* mapping avatar end*/}
                              </Grid>
                              <Grid item xs>
                                {/* mapping details */}
                                <List
                                  className={
                                    data[0]?.resourceMeta?.experience?.value
                                      ?.length ===
                                      key + 1
                                      ? classes.listWithOutBorderBottok
                                      : classes.list
                                  }
                                >
                                  <ListItem className={classes.listItem}>
                                    <ListItemText
                                      classes={{
                                        primary: classes.listTextSpacing,
                                      }}
                                      primary={
                                        <span className={classes.title_}>
                                          {_list?.organizationName}
                                        </span>
                                      }
                                      secondary={
                                        <React.Fragment>
                                          <span className={classes.sub_text}>
                                            {_list?.roleName}
                                          </span>
                                          <br />
                                          <span className={classes.footer}>
                                            {(_list?.startingFrom) && (
                                                <>
                                                  {this.dateformat(
                                                    _list?.startingFrom,
                                                    _list?.endingIn,
                                                    _list?.present
                                                  )}
                                                </>
                                              )}
                                          </span>
                                        </React.Fragment>
                                      }
                                    />
                                  </ListItem>
                                </List>
                                {/* mapping details end */}
                              </Grid>
                            </Grid>
                          );
                        })}

                      {data[0]?.resourceMeta?.experience?.value?.length === 0 && (
                        <Typography variant="body2" color="textSecondary">
                          {"No experience added yet"}
                        </Typography>
                      )}

                      {/* Removed the show more section, if, the items length is less than or equal to 2 */}
                      {/* {slice_end_e > 2 && ( */}
                      {data[0]?.resourceMeta?.experience?.value?.length !== 0 && data[0]?.resourceMeta?.experience?.value?.length > 2 && (
                        <Typography
                          id={`showLessExperienceButton`}
                          className={classes.add_title__}
                          onClick={() =>
                            this.handleoffset_e(
                              data[0]?.resourceMeta?.experience?.value?.length
                            )
                          }
                        >
                          <>
                            {slice_end_e > 2 ? (
                              <>
                                Show Less Experience{" "}
                                <ExpandLess className={classes.arr} />
                              </>
                            ) : (
                              <>
                                Show More Experience
                                <ExpandMore className={classes.arr} />
                              </>
                            )}
                          </>
                        </Typography>
                      )}
                    </div>
                  </Grid>
                </div>
              </>
            )}
        </Grid>
      </Paper>
    );
  }
}

export default withAlelrtAndDialog(withRouter(withStyles(styles, {withTheme: true})(DetailsCard)));
