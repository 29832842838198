import * as React from "react"

export function EmailIcon(props) {
  return (
    <svg viewBox="0 0 24 24" style={props.style} fill={props.color} {...props}>
      <path d="M4 4a2 2 0 00-2 2v1.732l1 .627 1 .627L12 14l8-4.98 1-.624 1-.62V6a2 2 0 00-2-2H4zm0 2h16v.77l-8 4.978-8-5.014V6zm-2 4.092V18a2 2 0 002 2h16a2 2 0 002-2v-7.87l-2 1.245V18H4v-6.654l-2-1.254z" />
    </svg>
  )
}

