import * as React from "react"

export const Rarrow = (props) => {

    const {
        width = "34.7",
        height = "23.137",
        color="#6f6f6f"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 34.7 23.137"
        >
            <path id="icons8-arrow_2_" data-name="icons8-arrow (2)" d="M23.714,11a.825.825,0,0,0-.7.827V18.44H4.827A.828.828,0,0,0,4,19.267v6.614a.828.828,0,0,0,.827.827H23.016v6.614a.826.826,0,0,0,1.318.646L38.39,23.22a.828.828,0,0,0,0-1.292L24.334,11.18A.829.829,0,0,0,23.714,11Zm.956,2.48,11.885,9.095L24.67,31.669V25.881a.828.828,0,0,0-.827-.827H5.654V20.094H23.843a.828.828,0,0,0,.827-.827Z" transform="translate(-4 -10.993)" 
                fill={color}
            />
        </svg>
    );
};
