import React from "react";
import {
  Avatar,
  Button,
  Grid,
  makeStyles,
  withStyles,
} from "@material-ui/core";

export const CustomButton = withStyles((props) => {
  // const theme = useTheme()
  return {
    root: {
      lineHeight: 1.35,
    },
  };
})((props) => <Button color="primary" {...props} />);

const customAvatarStyle = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 1),
  },
  avatarSize: {
    width: theme.spacing(6.25),
    height: theme.spacing(6.25),
  },
}));

function CustomAvatarPlaceholder({ src, name, id, loading = false }) {
  const classes = customAvatarStyle();
  return (
    <>
      <Avatar
        src={src}
        className={`${classes.root} ${classes.avatarSize}`}
        style={{
          opacity: 0,
        }}
      ></Avatar>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: theme.spacing(1.5),
    margin: theme.spacing(1, 0, 1, 0),
  },
}));

export function MobileViewInfoCtas({ ctas, ...props }) {
  const classes = useStyles();
  return (
    <>
      <Grid item>
        <CustomAvatarPlaceholder />
      </Grid>
      <Grid item xs>
        <div className={classes.root}>
          {ctas?.map((cta, index) => {
            const {
              display,
              loading,
              loadingLabel = "Loading..",
            } = cta?.additionalProps;
            if (display) {
              return (
                <CustomButton
                  key={cta?.label}
                  onClick={cta?.onClickHandler}
                  variant={cta?.additionalProps?.variant}
                >
                  {loading ? loadingLabel : cta?.label}
                </CustomButton>
              );
            } else {
              return <></>;
            }
          })}
        </div>
      </Grid>
    </>
  );
}
