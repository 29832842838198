import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: `0px 0.5px 2px 1px #e0e0e0d6`,
    padding: 16,
    backgroundColor: "white",
    height: 90,
    borderRadius: 8,
  }
}))

export const ActivityLoading = (props) => {

  const classes = useStyles();

  return <div className={classes.root}>
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item container xs>
        <Grid item style={{ marginRight: 10 }}>
          <Skeleton variant="circle" width="50px" height="50px" />
        </Grid>
        <Grid item>
          <Skeleton variant="text" width="150px" />
          <Skeleton variant="text" width="110px" style={{ marginTop: 8 }} />
        </Grid>
      </Grid>
      <Grid item>
        <Skeleton variant="text" width="120px" />
      </Grid>
    </Grid>
  </div>
}
