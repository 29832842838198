import { filterType, user_roles } from "../../../utils";

export const filterComponent = (state, props) => {
  let id = state?.id
  switch (props?.user_role) {
    case user_roles.super_admin: {
      return {
        section_1: {
          label: "Client Filters",
          fields: {
            provider: {
              key: "provider",
              id:`selectProviderFilterFrom${id ? id : ""}`,
              type: filterType.PROVIDER,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY PARTNER",
              value: state.provider,
              isMulti: true,
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SUPERVISOR STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            professional: {
              key: "professional",
              id:`selectServiceProfessionalFilterFrom${id ? id : ""}`,
              type: filterType.SERVICE_PROFESSIONAL,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SERVICE PROFESSIONAL",
              value: state.professional,
              isMulti: true,
            },
            coordinator: {
              key: "coordinator",
              id:`selectServiceCoordinaorFilterFrom${id ? id : ""}`,
              type: filterType.SERVICE_CORDINATOR,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SERVICE COORDINATOR",
              value: state.coordinator,
              isMulti: true,
            },
            gender: {
              key: "gender",
              id:`selectGenderFilterFrom${id ? id : ""}`,
              type: filterType.GENDER,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY GENDER",
              value: state.gender,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMandatory: true,
            },
          },
        },
      };
    }
    case user_roles.help_desk: {
      return {
        section_1: {
          label: "Client Filters",
          fields: {
            provider: {
              key: "provider",
              id:`selectProviderFilterFrom${id ? id : ""}`,
              type: filterType.PROVIDER,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY PARTNER",
              value: state.provider,
              isMulti: true,
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SERVICE SUPERVISOR STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            professional: {
              key: "professional",
              id:`selectServiceProfessionalFilterFrom${id ? id : ""}`,
              type: filterType.SERVICE_PROFESSIONAL,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SERVICE PROFESSIONAL",
              value: state.professional,
              isMulti: true,
            },
            coordinator: {
              key: "coordinator",
              id:`selectServiceCoordinaorFilterFrom${id ? id : ""}`,
              type: filterType.SERVICE_CORDINATOR,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SERVICE COORDINATOR",
              value: state.coordinator,
              isMulti: true,
            },
            gender: {
              key: "gender",
              id:`selectGenderFilterFrom${id ? id : ""}`,
              type: filterType.GENDER,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY GENDER",
              value: state.gender,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMandatory: true,
            },
          },
        },
      };
    }

    case user_roles.provider: {
      return {
        section_1: {
          label: "Client Filters",
          fields: {
            provider: {
              key: "provider",
              id:`selectProviderFilterFrom${id ? id : ""}`,
              type: filterType.PROVIDER,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY PARTNER",
              value: state.provider,
              isMulti: true,
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SERVICE SUPERVISOR STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            professional: {
              key: "professional",
              id:`selectServiceProfessionalFilterFrom${id ? id : ""}`,
              type: filterType.SERVICE_PROFESSIONAL,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SERVICE PROFESSIONAL",
              value: state.professional,
              isMulti: true,
            },
            coordinator: {
              key: "coordinator",
              id:`selectServiceCoordinatorFilterFrom${id ? id : ""}`,
              type: filterType.SERVICE_CORDINATOR,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SERVICE COORDINATOR",
              value: state.coordinator,
              isMulti: true,
            },
            gender: {
              key: "gender",
              id:`selectGenderFilterFrom${id ? id : ""}`,
              type: filterType.GENDER,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY GENDER",
              value: state.gender,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMandatory: true,
            },
          },
        },
      };
    }

    case user_roles.supervisor: {
      return {
        section_1: {
          label: "Client Filters",
          fields: {
            provider: {
              key: "provider",
              id:`selectProviderFilterFrom${id ? id : ""}`,
              type: filterType.PROVIDER,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY PARTNER",
              value: state.provider,
              isMulti: true,
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SERVICE SUPERVISOR STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            professional: {
              key: "professional",
              id:`selectServiceProfessionalFilterFrom${id ? id : ""}`,
              type: filterType.SERVICE_PROFESSIONAL,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SERVICE PROFESSIONAL",
              value: state.professional,
              isMulti: true,
            },
            coordinator: {
              key: "coordinator",
              id:`selectServiceCoordinatorFilterFrom${id ? id : ""}`,
              type: filterType.SERVICE_CORDINATOR,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SERVICE COORDINATOR",
              value: state.coordinator,
              isMulti: true,
            },
            gender: {
              key: "gender",
              id:`selectGenderFilterFrom${id ? id : ""}`,
              type: filterType.GENDER,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY GENDER",
              value: state.gender,
            },
            sort: {
              key: "sortDirec",
              id:`selectDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMandatory: true,
            },
          },
        },
      };
    }

    case user_roles.service_coordinator: {
      return {
        section_1: {
          label: "Client Filters",
          fields: {
            provider: {
              key: "provider",
              type: filterType.PROVIDER,
              id:`selectProviderFilterFrom${id ? id : ""}`,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY PARTNER",
              value: state.provider,
              isMulti: true,
            },
            status: {
              key: "status",
              id:`selectStatusFilterFrom${id ? id : ""}`,
              type: filterType.STATUS,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SERVICE SUPERVISOR STATUS",
              value: state.status,
              isMulti: true,
              isMandatory: true,
            },
            professional: {
              key: "professional",
              id:`selectServiceProfessionalFilterFrom${id ? id : ""}`,
              type: filterType.SERVICE_PROFESSIONAL,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SERVICE PROFESSIONAL",
              value: state.professional,
              isMulti: true,
            },
            coordinator: {
              key: "coordinator",
              id:`selectServiceCoordinatorFilterFrom${id ? id : ""}`,
              type: filterType.SERVICE_CORDINATOR,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY SERVICE COORDINATOR",
              value: state.coordinator,
              isMulti: true,
            },
            gender: {
              key: "gender",
              id:`selectGenderFilterFrom${id ? id : ""}`,
              type: filterType.GENDER,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "BY GENDER",
              value: state.gender,
            },
            sort: {
              key: "sortDirec",
              id:`selectSortDirectionFilterFrom${id ? id : ""}`,
              type: filterType.SORTDIRECTION,
              breakPoints: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
              label: "SORT BY",
              value: state.sortDirec,
              isMandatory: true,
            },
          },
        },
      };
    }

    default: {
      return null
    }
  }
};
