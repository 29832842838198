import React from "react";
import { Grid, makeStyles, Switch, Typography } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiSwitch-track": {
      background: "red",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      "& .MuiSwitch-track":{
      background: 'red',
      }
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "white",
    },
  },
  switch: {
    "& .MuiSwitch-track": {
      borderRadius: 13,
    },

    "& .MuiSwitch-root": { padding: 6 },
  },
}));

export const ServiceConfiguration = (props) => {
  const classes = styles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.switch}>
          Pre-assessment Required{" "}
          <span>
            <Switch
              disableRipple
              onChange={(e) => {}}
            //   color="secondary"
              inputProps={{
                "aria-label": "checkbox with default color",
              }}
            />
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};
