import * as React from "react"

export const FullScreenIcon = (props) => {
 let width = props.width ? props.width : "13.999";
 let height = props.height ? props.height : "14";
 let color = props.color ? props.color : "#0063e7";
 return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13.999 14">
      <path id="icons8-fullscreen" d="M19.386,5.986a.583.583,0,0,0-.063.005H15.512a.583.583,0,1,0,0,1.166H17.99L7.157,17.99V15.511a.583.583,0,1,0-1.166,0v3.812a.583.583,0,0,0,.657.656H10.46a.583.583,0,1,0,0-1.166H7.981L18.814,7.981V10.46a.583.583,0,1,0,1.166,0V6.648a.583.583,0,0,0-.594-.662ZM6.574,5.991a.572.572,0,0,0-.208.039l-.017.007h0a.583.583,0,0,0-.087.046l-.009.006a.583.583,0,0,0-.086.07l0,0,0,0a.583.583,0,0,0-.069.085l-.005.008a.581.581,0,0,0-.082.2l0,.013a.583.583,0,0,0-.01.107V10.46a.583.583,0,1,0,1.166,0V7.981l4.455,4.455.824-.824L7.981,7.157H10.46a.583.583,0,1,0,0-1.166Zm7.785,7.544-.824.824,4.455,4.455H15.512a.583.583,0,1,0,0,1.166H19.4a.583.583,0,0,0,.109-.011h0a.583.583,0,0,0,.095-.028l.018-.007h0a.583.583,0,0,0,.082-.043l.017-.01a.583.583,0,0,0,.082-.068l.006-.005a.582.582,0,0,0,.065-.078l.012-.017,0,0a.583.583,0,0,0,.043-.08l.008-.017a.583.583,0,0,0,.027-.094s0-.009,0-.014a.583.583,0,0,0,.01-.107V15.511a.583.583,0,1,0-1.166,0V17.99Z" transform="translate(-5.986 -5.986)"
         fill={color}
    />
    </svg>
  );
};
