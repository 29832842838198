import React from "react";
import { DialogContext } from "./contexts";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  fitContentWidth: { minWidth: "unset" },
  dialog: { minWidth: 600 },
  closeBtn: {
    margin: "16px 24px",
    position: "absolute",
    right: 0,
  },
});

class AppDialog extends React.Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      open: false,
      title: "",
      body: "",
      positiveBtn: "Ok",
      negativeBtn: "Cancel",
      negativeBtnDontShow: false,
      positiveBtnDontShow: false,
      dontShowButtons: false,
      hideAllButtons: false,
      fitContentWidth: false,
      onOk: () => null,
      onCancel: this.close,
      style: {
        Dialog: {},
        DialogContent: {},
      },
      className:{
        Dialog:'',
        DialogContent:'',
      },
      disableEnforceFocus:false,
      checkId:""
}

    this.state = {
      ...this.defaultState
    };
  }

  close = () => {
    this.setState({
      ...this.defaultState,open: false,

      // THIS PART IS COMMENTED ON A CAUSE

      // title: "",
      // body: "",
      // positiveBtn: "Ok",
      // negativeBtn: "Cancel",
    });
  };

  set = (props) => {
    // this.setState({ ...props });
    this.setState({
      ...this.defaultState,...props
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <DialogContext.Provider
        value={{
          ...this.state,
          setDialog: this.set,
        }}
      >
        {this.props.children}
        {this.state.open &&
          <Dialog
          open={this.state.open}
          onClose={this.state.onCancel}
          check-id={this.state.checkId} //custom accessibility
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{
            paper: this.state.fitContentWidth ? classes.fitContentWidth : classes.dialog,
            root:this.state.className?.Dialog
          }}
          disableEnforceFocus={this.state.disableEnforceFocus}
          style={{...this.state.style.Dialog}}
        >
          {this.state.title && <DialogTitle id="alert-dialog-title">{this.state.title}</DialogTitle>}
          <DialogContent style={{...this.state.style.Dialog}}>
            <DialogContentText id="alert-dialog-description">
              {typeof this.state.body === "function"
                ? this.state.body(this.state.onCancel)
                : this.state.body}
            </DialogContentText>
          </DialogContent>
          {!this.state.dontShowButtons && (
            <DialogActions>
              {!Boolean(this.state?.negativeBtnDontShow) && (
                <Button onClick={this.state.onCancel}>
                  {this.state.negativeBtn}
                </Button>
              )}
              {!Boolean(this.state?.positiveBtnDontShow) && (
                <Button onClick={this.state.onOk} color="primary" autoFocus>
                  {this.state.positiveBtn}
                </Button>
              )}
            </DialogActions>
          )}

          {this.state.dontShowButtons && !this.state.hideAllButtons && (
            <Button
              variant="contained"
              color="primary"
              className={classes.closeBtn}
              onClick={this.state.onCancel}
            >
              CLOSE
            </Button>
          )}
        </Dialog>
      }
      </DialogContext.Provider>
    );
  }
}

export default withStyles(styles)(AppDialog);
