import * as React from "react";

export function Relation(props) {
  return (
    <svg
      width={22}
      height={16}
      viewBox="0 0 22 16"
      fill="none"
      stroke="#0063E7"
      strokeMiterlimit={10}
      strokeWidth={2}
      {...props}
    >
      <path
        d="M7.5 1A2.5 2.5 0 1010 3.5 2.5 2.5 0 007.5 1zM16 4a2 2 0 102 2 2 2 0 00-2-2zm-4.5 11H1v-2.433a.991.991 0 01.508-.871A12.935 12.935 0 017.5 10a12.637 12.637 0 015.5 1.434"
        className="prefix__cls-1"
        data-name="Path 608"
      />
      <path
        d="M11 15v-1.869a.973.973 0 01.516-.845A9.583 9.583 0 0116 11a9.6 9.6 0 014.484 1.285.974.974 0 01.516.846V15z"
        className="prefix__cls-1"
        data-name="Path 609"
      />
    </svg>
  );
}
