import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function BmiIconV2(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="bmi icon">
        <path
          data-name="Path 97286"
          d="M5.187 5.238H3.502v12.787a1.937 1.937 0 001.875 1.993h12.262a1.937 1.937 0 001.875-1.993V5.238z"
          fill="none"
          stroke="#0063e7"
        />
        <path
          data-name="Path 97289"
          d="M18.027 2.999H4.993A1.993 1.993 0 003 4.992v.246h17.02v-.246a1.993 1.993 0 00-1.993-1.993z"
          fill="#0063e7"
        />
        <path
          data-name="Path 97290"
          d="M15.912 8.242c1.183.361 1.762 1.9 1.292 3.438a3.594 3.594 0 01-.716 1.309 4.623 4.623 0 00-.95 1.842l-.023.079a1.617 1.617 0 11-3-.915l.025-.079a4.616 4.616 0 00.241-2.058 3.592 3.592 0 01.138-1.486c.471-1.537 1.81-2.491 2.993-2.13z"
          fill="#fff"
          stroke="#0063e7"
          strokeWidth={0.8}
        />
        <path
          data-name="Path 97291"
          d="M10.538 14a1.617 1.617 0 11-3 .915l-.023-.079a4.623 4.623 0 00-.95-1.842 3.588 3.588 0 01-.716-1.309c-.469-1.538.109-3.077 1.292-3.438s2.522.593 2.991 2.131a3.577 3.577 0 01.137 1.486 4.622 4.622 0 00.241 2.058.39.39 0 01.028.078z"
          fill="#fff"
          stroke="#0063e7"
          strokeWidth={0.8}
        />
        <path
          data-name="Path 97294"
          d="M5.216 8.68a.448.448 0 11-.567-.283.445.445 0 01.567.283z"
          fill="#fff"
        />
        <path
          data-name="Path 97296"
          d="M7.126 11.871a.448.448 0 01-.428-.317 2.129 2.129 0 01.022-1.361 1.451 1.451 0 01.917-.905.448.448 0 11.261.857.576.576 0 00-.34.364 1.249 1.249 0 000 .785.448.448 0 01-.429.579z"
          fill="rgba(0,99,231,0.22)"
        />
        <path
          data-name="Path 97297"
          d="M19.513 18.103v-.124H5.629c-.247 0-.448.055-.448.124s.2.124.448.124h13.874a.162.162 0 00.01-.056z"
          fill="none"
          stroke="rgba(0,99,231,0.47)"
        />
        <path
          data-name="Path 97298"
          d="M18.228 15.539a.448.448 0 01-.448-.448v-1.792a.45.45 0 01.9 0v1.792a.448.448 0 01-.452.448z"
          fill="#fff"
        />
        <path
          data-name="Path 97299"
          d="M18.228 18.226a.448.448 0 01-.448-.448v-1.344a.45.45 0 01.9 0v1.344a.448.448 0 01-.452.448z"
          fill="#fff"
        />
        <path
          data-name="Path 97300"
          d="M4.792 7.925a.448.448 0 01-.448-.448V5.238a.45.45 0 11.9 0v2.239a.448.448 0 01-.452.448z"
          fill="#fff"
        />
        <path
          data-name="Path 97301"
          d="M14.645 2.999v1.344a3.135 3.135 0 11-6.27 0V2.999z"
          fill="#0063e7"
        />
      </g>
    </SvgIcon>
  );
}

export default BmiIconV2;
