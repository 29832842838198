import React from 'react';
import IssueTopBar from '../../../../components/issueDetail/topBar'
import IssueProfileBar from '../../../../components/issueDetail/profileBar'
import IssueMessageBar from '../../../../components/issueDetail/messageArea'
import { InternatlServerError } from '../../../../components/integerateHelper'
import IssueDetailLoading from '../../../../components/issueDetail/IssueDetailLoading'
import { Grid, Divider, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { actions } from 'tanyacare-middleware'
import { withRouter } from 'react-router-dom';

const style = (theme) => ({
    dividerStyle: {
        backgroundColor: theme.palette.secondary.light,
        height: 1.3
    }
})

function giveToggleTypes() {
    return [
        {
            label: 'Open',
            value: 'open'
        },
        {
            label: 'Pending',
            value: 'pending'
        },
        {
            label: 'Resolved',
            value: 'resolved'
        },
    ]
}

class IssueDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: '',
            toggleTypes: giveToggleTypes()
        }
    }

    handleStatus = (event, selected) => {
        if (selected !== null) {
            this.setState({
                status: selected
            })
        }
    }

    componentDidMount() {
        this.getIssueDetail()
    }

    getIssueDetail = () => {
        const id = this.props?.match?.params?.issue_id
        this.props.GET_CLIENT_ISSUE_INFO({ issue_id: id })
    }


    render() {
        const { classes } = this.props
        let { data, error, loading } = this.props?.issueDetail
        // loading = true
        // error = true
        return (
            <div style={{ margin: 25 }}>
                {error && <InternatlServerError message={"Unable to fetch Issue Details"} onRetry={this.getIssueDetail} />}
                {loading && <IssueDetailLoading />}
                {!loading && !error && <Grid container>
                    <Grid item xs={12}>
                        <IssueTopBar isCardTop data={data} />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid item xs={12}>
                        <IssueProfileBar data={data} />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid item xs={12}>
                        <IssueMessageBar
                            isCardBottom
                            toggleTypes={this.state.toggleTypes}
                            selected={this.state.status}
                            handleStatus={this.handleStatus}
                            data={data} />
                    </Grid>
                </Grid>}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    issueDetail: state?.clientIssue?.clientIssueInfo
})

export default connect(mapStateToProps, actions)(withRouter(withStyles(style)(IssueDetails)));