import React from "react";
import JoditEditor from "jodit-react";
import { FormControl, InputLabel, makeStyles } from "@material-ui/core";

const USEDBUTTONS = ['bold', 'italic', 'underline', 'strikethrough', '|', 'ul', 'ol', '|', 'superscript', 'subscript']

const useStyles = makeStyles((theme) => ({
  root: {
    "& .jodit-container": {
      marginTop: 24
    }
  }
}))

export default function RichTextEditor(props) {
  const { keys, isReadOnly = false, saveOnBlur = () => { }, label = "", limit = 5000 } = props;
  const classes = useStyles(props)
  const config = {
    readonly: isReadOnly,
    toolbar: !isReadOnly,
    height: "300px",
    width: "100%",
    uploader: {
      insertImageAsBase64URI: true,
    },
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: "insert_clear_html",
    buttons: USEDBUTTONS,
    buttonsMD: USEDBUTTONS,
    buttonsSM: USEDBUTTONS,
    buttonsXS: USEDBUTTONS,
    showCharsCounter: !isReadOnly,
    showWordsCounter: !isReadOnly,
    showXPathInStatusbar: false,
    // iframe: true,
    iframeStyle: "body{font-size:20px;}",
    limitChars: limit,
    disablePlugins: "about,class-span,clean-html,copy-format,clipboard,color,font,format-block,fullsize,indent,print,preview,table,file,video,image,search,redo-undo,link,symbols,hr,source,justify"
  };

  // 
  console.log(props?.isReadOnly)

  const onBlurSave = () => {
    if (isReadOnly) {
      return false;
    } else {
      saveOnBlur(document.getElementById(`desc_editor" ${keys ? keys : ''}`)?.value ?? "")
    }
  };

  return (
    <div className={classes.root}>
      <FormControl style={{ width: "100%" }} >
        <InputLabel shrink style={{ fontSize: props.labelSize }}>{label}</InputLabel>
        <JoditEditor
          id={`desc_editor" ${keys ? keys : ''}`}
          // id={`${props?.id}`}
          value={props.value}
          config={config}
          onBlur={onBlurSave}
          onPaste={onBlurSave}
        />
      </FormControl>
    </div>
  );
}
