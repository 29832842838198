/* eslint-disable eqeqeq */
import React from 'react';
import { Grid, makeStyles, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SimpleSelect } from "../../inputs";
import MonthNavigation from './monthNavigation';
import moment from 'moment';
import { user_roles } from '../../../utils';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%' 
    },
    filterBtn: {
        borderRadius: 4,
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        height: 43,
        "&:hover": {
            border: `1px solid ${theme.palette.secondary.main} !important`,
        }
    },
    variant: {
        width: 150,
        height: 43,
        overflow: 'hidden',
    },
    badge: {
        '& .MuiBadge-anchorOriginTopRightCircle': {
            top: 0,
            right: 0,
        },
        '& .MuiBadge-colorError': {
            backgroundColor: theme.palette.ternary.main
        }
    }
}))

const CalenderHeader = (props) => {

    const classes = useStyles();

   /*  const mapProfessional = (data) => {
        if(props.partner){
        props?.partner.map((provider) => {
            if(provider.value === data)
            {
                return provider.label
            }
        })
    }
    }; */

    const {
        partner=[],
        selectedProvider,
        onCategorySelected,

        serviceProfessional,
        selectedServiceProfessional,
        onServieProfessionalSelected,



        selectedDate,
        onNextClicked,
        onPrevClicked,

        variant,
        onVariantChange,
        hideFilters,

        user_role

    } = props;

    // console.log(user_role)
    // console.log(categoryList)

   /*  const [filterCategory, setFilterCategory] = useState("");
    const [filterProfessionals, setFilterProfessionals] = useState("");

    useEffect(() => {}, []) */
    // console.log(mapProfessional(selectedProvider))

    return <div className={classes.root}>
        <Grid container direction="row" justify="space-between" alignItems="center">
            {/* Filters */}
            <Grid container
                direction="row"
                spacing={2}
                item xs={12} sm={6} md={6} lg={6} xl={6}
                justify="flex-start"
                alignItems="center">

                {/* Category Filter */}
                {user_role == 'super-admin'  || user_role === user_roles.help_desk
                ?
                    !hideFilters && <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                        <SimpleSelect
                            placeholder="Partner"
                            id="selectPartnerForCalenderFromViewActivities"
                            options={partner}
                            value={selectedProvider}
                            handleOnChange={onCategorySelected}
                            height={43}
                            borderColor={'rgba(0, 0, 0, 0.23)'}
                            hoverBorderColor={'#9297a5'}
                            borderRadius={"4px"}
                            isNotClearable={false}
                        />
                    </Grid>
                : 
                    <></>
                }

                {/* Service Professional Filter */}
                {!hideFilters && <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                    <SimpleSelect
                        placeholder="Service Professionals"
                        id="selectServiceProfessionalForCalenderFromViewActivities"
                        options={serviceProfessional}
                        value={selectedServiceProfessional}
                        handleOnChange={onServieProfessionalSelected}
                        height={43}
                        borderColor={'rgba(0, 0, 0, 0.23)'}
                        hoverBorderColor={'#9297a5'}
                        borderRadius={"4px"}
                        isNotClearable={false}
                    />
                </Grid>}

                {/* More Filter IconButton */}
               {/*  {!hideFilters && <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Badge color="error" variant="dot" invisible={!isFilterApplied} overlap="circle" className={classes.badge}>
                        <IconButton onClick={onFilterClicked} className={classes.filterBtn}>
                            <FilterIcon color="black" />
                        </IconButton>
                    </Badge>
                </Grid>} */}

            </Grid>

            <Grid
                container
                item xs={12} sm={12} md={12} lg={6} xl={6}
                direction="row"
                alignItems="center"
                justify="flex-end">

                <MonthNavigation
                    previous_button_id={ `selectPreviousMonthFrom${props?.from_id}`}
                    next_button_id= {`selectNextMonthFromViewActivities${props.from_id}`}
                    selectedDate={selectedDate}
                    onNextClicked={onNextClicked}
                    onPrevClicked={onPrevClicked} />

                <Select
                    value={variant}
                    id={`selectViewVarientFrom${props.from_id}`}
                    variant="outlined"
                    onChange={onVariantChange}
                    className={classes.variant}
                    color="secondary"
                >
                    <MenuItem value={'day'}>Day</MenuItem>
                    <MenuItem value={'week'}>Week</MenuItem>
                    <MenuItem value={'month'}>Month</MenuItem>
                </Select>
            </Grid>
        </Grid>
    </div>
}

CalenderHeader.propTypes = {
    selectedDate: PropTypes.instanceOf(moment),

    categoryList: PropTypes.array,
    category: PropTypes.object,
    onCategorySelected: PropTypes.func,

    serviceProfessionalList: PropTypes.array,
    serviceProfessional: PropTypes.object,
    onServieProfessionalSelected: PropTypes.func,

    onFilterClicked: PropTypes.func,
    isFilterApplied: PropTypes.bool,

    currentDate: PropTypes.instanceOf(moment()),
    onNextClicked: PropTypes.func,
    onPrevClicked: PropTypes.func,

    variant: PropTypes.string,
    onVariantChange: PropTypes.func,

    hideFilters: PropTypes.bool
}

CalenderHeader.defaultProps = {
    categoryList: [],
    serviceProfessionalList: []
}

export default CalenderHeader;