import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { SimpleSelect } from "./select";
import { Grid, Typography, FormControlLabel, Switch } from "@material-ui/core";
import {
  isNumberKey,
  isNumberKeyDown,
  isTextKeyDown,
  restrictToNumbers
} from "../../../utils/validations";
import PropTypes from "prop-types";
import { metaType } from "../../../utils";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(),
    },
    width: "100%",
    "& .jss71": {
      // fontFamily: "manage_care_semibold",
    },
  },
  input: {
    borderRadius: 8,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    height: "7.6px",

    // border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "16px 12px",
    // transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.

    // "&:focus": {
    //   boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main
    // }
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    margin: "8px 0px 8px 0px",
    marginBottom: 0,
    "& .MuiInputLabel-asterisk": {
      color: theme.palette.error.main,
    },
  },
  margin: {
    margin: theme.spacing(0,0,1,0),
    marginLeft: 0,
    marginRight: 0,
  },
  select: {
    border: "none",
    borderRight: `1.8px solid ${theme.palette.secondary.borderColor}`,
  },
  selectGrid: {
    display: "flex",
    border: `1.8px solid ${theme.palette.secondary.borderColor}`,
    borderRadius: 8,
    // borderRadius:3,
  },
  selectGridError: {
    display: "flex",
    border: `1.8px solid ${theme.palette.error.main}`,
    borderRadius: 8,
    // borderRadius:3,
  },
  label: {
    color: theme.palette.text.label,
  },
  borderRight: {
    borderRight: `1.8px solid ${theme.palette.secondary.borderColor}`,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
  switchRoot: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.ternary.main,
    },
    "& .MuiSwitch-track": {
      background: theme.palette.secondary.main,
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      // "& .MuiSwitch-track":{
      background: "#80f10e",
      // }
    },
    "& .MuiSwitch-switchBase": {
      padding: 13,
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "white",
    },
    "&:not($checked) .MuiIconButton-label:after": {
      padding: 14,
    },
  },
  switch: {
    "& .MuiSwitch-track": {
      borderRadius: 13,
    },
    "& .MuiSwitch-thumb": {
      width: 12,
      height: 12,
    },
    // marginTop:20,
    marginLeft: 0,
    display: "flex",

    "& .MuiSwitch-root": { padding: 10 },
  },
  formControlLabel: {
    fontSize: "0.86rem",
    color: "#40444f",
  },
}));

export function InputAdornmentComponent({ required, ...props }) {
  const classes = useStyles();
  const allScreenCustomSize = props?.allScreenCustomSize;
  const {
    helperFlagNeeded = false,
    helperFlagControllers = null,
    codeSize,
  } = props;

  console.log(props)

  return (
    <div className={classes.root} noValidate>
      <InputLabel
        shrink
        htmlFor="bootstrap-input"
        className={classes.label}
        required={required}
      >
        {props.title.toUpperCase()}
      </InputLabel>
      <FormControl className={classes.margin}>
        <div
          className={props.error ? classes.selectGridError : classes.selectGrid}
        >
          <Grid container>
            <Grid
              item
              xs={
                codeSize?.xs
                  ? codeSize?.xs
                  : allScreenCustomSize?.xs
                  ? allScreenCustomSize?.xs
                  : 5
              }
              sm={
                codeSize?.sm
                  ? codeSize?.sm
                  : allScreenCustomSize?.sm
                  ? allScreenCustomSize?.sm
                  : 5
              }
              md={
                codeSize?.md
                  ? codeSize?.md
                  : allScreenCustomSize?.md
                  ? allScreenCustomSize?.md
                  : 5
              }
              lg={
                codeSize?.lg
                  ? codeSize?.lg
                  : !allScreenCustomSize?.lg
                  ? props?.customsize
                    ? props.customsize
                    : 4
                  : allScreenCustomSize?.lg
              }
              xl={
                codeSize?.xl
                  ? codeSize?.xl
                  : !allScreenCustomSize?.xl
                  ? props?.customsize
                    ? props.customsize
                    : 4
                  : allScreenCustomSize?.xl
              }
              className={classes.borderRight}
            >
              <SimpleSelect
                {...props}
                className={classes.select}
                id={props?.select_id}
                options={props?.options ? props?.options : []}
                idSelect={props.idSelect}
                handleChangeSelect={props.handleChangeSelect}
                value={props.valuePhoneCode}
                disabled={props?.disabled || props?.disableDropdown}
              />
            </Grid>
            <Grid
              item
              // xs={8}
              // sm={8}
              // md={10}
              xs={
                allScreenCustomSize?.xs
                  ? 12 - Number(allScreenCustomSize?.xs)
                  : 7
              }
              sm={
                allScreenCustomSize?.sm
                  ? 12 - Number(allScreenCustomSize?.sm)
                  : 7
              }
              md={
                allScreenCustomSize?.md
                  ? 12 - Number(allScreenCustomSize?.md)
                  : 7
              }
              lg={
                !allScreenCustomSize?.lg
                  ? props?.customsize
                    ? 12 - Number(props?.customsize)
                    : 8
                  : 12 - Number(allScreenCustomSize?.lg)
              }
              xl={
                !allScreenCustomSize?.xl
                  ? props?.customsize
                    ? 12 - Number(props?.customsize)
                    : 8
                  : 12 - Number(allScreenCustomSize?.xl)
              }
            >
              {props.meta === metaType.currency ? (
                <BootstrapInput
                  type={props.type}
                  id={props.id}
                  disabled={props?.disabled}
                  onInvalid={(e) => {
                    e.preventDefault();
                  }}
                  autoComplete={"no"}
                  onChange={props.handleMobileNo}
                  value={props.valueMObileNo}
                  onKeyPress={(event) => restrictToNumbers(event)}
                />
              ) : (
                <BootstrapInput
                  type={props.type}
                  id={props.id}
                  disabled={props?.disabled}
                  onInvalid={(e) => {
                    e.preventDefault();
                  }}
                  autoComplete={"no"}
                  onKeyPress={(event) => {
                    if (props.onlyText)
                      isTextKeyDown(event, props.onlyText ? true : false);
                    if (props?.type === "number")
                      isNumberKey(
                        event,
                        props.type === "number" ? true : false
                      );
                  }}
                  onKeyDown={(event) => {
                    isNumberKeyDown(
                      event,
                      props.type === "number" ? true : false
                    );
                  }}
                  onChange={props.handleMobileNo}
                  value={props.valueMObileNo}
                />
              )}
            </Grid>
          </Grid>
        </div>
        <Grid container direction={helperFlagNeeded ? "row-reverse" : "row"}>
          {helperFlagNeeded && helperFlagControllers && (
            <Grid item xs={12} sm={12} md={6}>
              <FormControlLabel
                className={`${classes.switchRoot} ${classes.switch}`}
                classes={{ label: classes.formControlLabel }}
                control={
                  <Switch
                    disableRipple
                    checked={helperFlagControllers?.value}
                    onChange={helperFlagControllers?.handleOnChange}
                    id={helperFlagControllers?.id}
                    inputProps={{
                      "aria-label": `${helperFlagControllers?.label} checkbox`,
                    }}
                  />
                }
                label={helperFlagControllers?.label}
                labelPlacement={"start"}
              />
            </Grid>
          )}
          {props.error && (
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="body1"
                component="div"
                className={classes.errorText}
              >
                {props.errorText}
              </Typography>
            </Grid>
          )}
        </Grid>
      </FormControl>
    </div>
  );
}

// // Specifies the default values for props:
// InputAdornmentComponent.defaultProps = {
//   type: "number",
// };

// Specifies the required props for the component:
InputAdornmentComponent.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
};
