import React from "react";
import {
  makeStyles,
  Avatar,
  Typography,
  Divider,
  Grid,
  withStyles,
  Chip,
  Hidden,
  Badge,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import Icon from "../../assets/product Management/listingScreen/premium.svg";
import PopperPopupState from "../popper/PopperPopupState";

const useStyle = makeStyles((theme) => ({
  iconAvatar: {
    padding: 16,
  },
  avatar: {
    height: 50,
    width: 50,
    backgroundColor: theme.palette.secondary.light,
    margin: theme.spacing(0, 1),
  },
  textArea: {
    // marginLeft: 16
  },
  textAreaHeader: {
    display: "flex",
    paddingBottom: 4,
  },
  textAreaFooter: {
    display: "flex",
    marginTop: 6,
  },
  activecircle: {
    marginLeft: 10,
    // marginTop: 6,
    alignSelf: "center",
    width: 9,
    height: 9,
    background: theme.palette.success.main,
    borderRadius: "50%",
  },
  inactivecircle: {
    marginLeft: 10,
    marginTop: 6,
    width: 9,
    height: 9,
    background: theme.palette.error.main,
    borderRadius: "50%",
  },
  active_name: {
    display: "inline",
    paddingLeft: "6px",
    fontSize: "14px",
    color: theme.palette.success.main,
    alignSelf: "center",
  },
  inactive_name: {
    display: "inline",
    paddingLeft: "6px",
    fontSize: "14px",
    color: theme.palette.error.main,
  },
  careProviderName: {
    backgroundColor: theme.palette.secondary.light,
    padding: "4px 8px 4px 8px",
    borderRadius: 4,
  },
  divider: {
    margin: "0px 12px",
    height: 24,
    [theme.breakpoints.down(450)]: {
      display: "none",
    },
  },
  footerDiv: {
    display: "flex",
    [theme.breakpoints.down(450)]: {
      width: "100%",
      justifyContent: "center",
      margin: "8px 0px",
    },
  },
  category: {
    // padding: "0px 12px",
    height: 24,
    // marginLeft: 16
  },
  categoryForSupervisor: {
    backgroundColor: theme.palette.secondary.light,
    padding: "4px 8px 4px 8px",
    borderRadius: 4,
  },
  careProviderNameForSupervisor: {
    padding: "4px 0px 4px 0px",
    marginLeft: 16,
  },
  activeBadge: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  inactiveBadge: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));

export const StyledChip = withStyles((props) => {
  const theme = useTheme()
  
  return {
    root: {
      height: 24,
      backgroundColor: (props) =>
        props.transparent ? "unset" : theme.palette.chip.main,
      // backgroundColor: "#D7E2E9",
      color: "unset",
      borderRadius: 8,
      // alignItems: (props) => props.transparent ? "flex-start" : "center",
    },
    label: {
      paddingLeft: (props) => (props.transparent ? 0 : 12),
      paddingRight: (props) => (props.transparent ? 0 : 12),
    },
  };
})((props) => <Chip {...props} />);

const StyledBadge = withStyles((theme) => ({
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  anchorOriginBottomRightCircle: {
    right: "22%",
  },
}))(Badge);

const DetailSection = (props) => {
  const classes = useStyle();

  const data = props?.data;
  const hideStatus = props.hideStatus;
  const hideDivider = props.hideDivider;

  const isActive =
    data?.isactive === true || data?.isactive === "Active" ? true : false;

  const getSplicedData = (data) => {
    let x = [...data]
    x.splice(0,1)
    
    return x
  }

  return (
    <>
      {/* Screens above sm  */}
      <Hidden smDown>
        <Grid item>
          <Avatar
            src={data?.profile_pic ? data.profile_pic : "/images/tanyaCare/Tanya.png"}
            className={`${!data?.profile_pic ? classes.iconAvatar : ""} ${
              classes.avatar
            }`}
          />
        </Grid>
        <Grid item>
          <div className={classes.textArea}>
            <div className={classes.textAreaHeader}>
              <Typography variant="body1">{data?.name}</Typography>
              {!hideStatus && (
                <div
                  className={
                    isActive ? classes.activecircle : classes.inactivecircle
                  }
                />
              )}

              {!hideStatus && (
                <Typography
                  variant="h6"
                  className={
                    isActive ? classes.active_name : classes.inactive_name
                  }
                >
                  {isActive ? "ACTIVE" : "INACTIVE"}
                </Typography>
              )}
            </div>
            <div className={classes.textAreaFooter}>
              {data?.category?.constructor === Array ? (
                data?.category?.length > 0 ? (
                  <>
                    {[...data?.category].splice(0, 1)?.map((category, key) => {
                      return (
                        <>
                          {/* <Typography
                          variant="body2"
                          color="textSecondary"
                          className={classes.category}
                        >
                          {category?.label ? category?.label.toString() : "N/A"}
                        </Typography> */}
                          <StyledChip
                            label={
                              category?.label
                                ? category?.label.toString()
                                : "N/A"
                            }
                          />
                        </>
                      );
                    })}
                    {data?.category?.length > 1 && (
                      <PopperPopupState
                        open={props.open}
                        withoutBackground={true}
                        handleClose={() => props.handlePopperClose()}
                        handleOpen={(e) =>
                          props.handlePopperOpen(
                            e,
                            getSplicedData(data?.category)
                          )
                        }
                        data={props.popperData}
                        count={data?.category?.length - 1}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {/* <Typography className={classes.careProviderName}>
                    N/A
                  </Typography> */}
                    <StyledChip label={"N/A"} />
                  </>
                )
              ) : (
                <>
                  {/* <Typography variant="caption" color="textSecondary">
                  {data?.category}
                </Typography> */}
                  {data?.category && (
                    <StyledChip label={data?.category ?? "N/A"} />
                  )}
                </>
              )}

              {!hideDivider && (
                <Divider
                  orientation="vertical"
                  flexItem
                  className={
                    // data?.care_provider?.length > 0 &&
                    // data?.category?.length > 0 &&
                    classes.divider
                  }
                />
              )}

              {/* {data?.care_provider?.map((cp, key) => <Typography key={key} variant="caption"
                    className={cp.name && classes.careProviderName} color="textSecondary">{cp.name}</Typography>)} */}
              {data?.care_provider?.constructor === Array ? (
                data?.care_provider?.length > 0 ? (
                  <>
                    {[...data?.care_provider].splice(0, 1)?.map((cp, key) => {
                      return cp?.name ? (
                        // <Typography
                        //   key={key}
                        //   variant="caption"
                        //   className={cp.name && classes.careProviderName}
                        //   style={{ marginRight: "8px" }}
                        //   color="textSecondary"
                        // >
                        //   {cp?.name ?? "N/A"}
                        // </Typography>
                        <StyledChip label={cp?.name ?? "N/A"} transparent />
                      ) : (
                        // <Typography
                        //   variant="body2"
                        //   color="textSecondary"
                        //   className={classes.category}
                        // >
                        //   {"N/A"}
                        // </Typography>
                        <StyledChip label={"N/A"} transparent />
                      );
                    })}
                    {data?.care_provider?.length > 1 && (
                      <PopperPopupState
                        open={props.open}
                        handleClose={() => props.handlePopperClose()}
                        handleOpen={(e) =>
                          props.handlePopperOpen(
                            e,
                            getSplicedData(data?.care_provider)
                          )
                        }
                        data={props.popperData}
                        count={data?.care_provider?.length - 1}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {/* <Typography className={classes.careProviderName}>
                    N/A
                  </Typography> */}
                    <StyledChip label={"N/A"} transparent />
                  </>
                )
              ) : (
                <>
                  {/* <Typography variant="caption" color="textSecondary">
                  {data?.care_provider ? data?.care_provider : "N/A"}
                </Typography> */}
                  <StyledChip
                    label={data?.care_provider ? data?.care_provider : "N/A"}
                    transparent
                  />
                </>
              )}
            </div>
          </div>
        </Grid>
      </Hidden>

      {/* Screens below sm  */}
      <Hidden mdUp>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {!hideStatus && (
            <Tooltip
              title={isActive ? "ACTIVE" : "INACTIVE"}
              placement="right-end"
            >
              <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
                classes={{
                  badge: isActive ? classes.activeBadge : classes.inactiveBadge,
                }}
              >
                <Avatar
                  src={data?.profile_pic ? data.profile_pic : Icon}
                  className={`${!data?.profile_pic ? classes.iconAvatar : ""} ${
                    classes.avatar
                  }`}
                />
              </StyledBadge>
            </Tooltip>
          )}
          {hideStatus && (
            <Avatar
              src={data?.profile_pic ? data.profile_pic : Icon}
              className={`${!data?.profile_pic ? classes.iconAvatar : ""} ${
                classes.avatar
              }`}
              style={{ margin: "0px auto" }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.textArea}>
            <div
              className={classes.textAreaHeader}
              style={{ justifyContent: "center" }}
            >
              <Typography variant="body1">{data?.name}</Typography>
            </div>
            <div
              className={classes.textAreaFooter}
              style={{ justifyContent: "center", flexWrap: "wrap" }}
            >
              {data?.category?.constructor === Array ? (
                data?.category?.length > 0 ? (
                  <div className={classes.footerDiv}>
                    {[...data?.category].splice(0, 1)?.map((category, key) => {
                      return (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className={classes.category}
                        >
                          {category?.label ? category?.label.toString() : "N/A"}
                        </Typography>
                      );
                    })}
                    {data?.category?.length > 1 && (
                      <PopperPopupState
                        open={props.open}
                        withoutBackground={true}
                        handleClose={() => props.handlePopperClose()}
                        handleOpen={(e) =>
                          props.handlePopperOpen(
                            e,
                            getSplicedData(data?.category)
                          )
                        }
                        data={props.popperData}
                        count={data?.category?.length - 1}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    {/* <Typography className={classes.careProviderName}>
                    N/A
                  </Typography> */}
                    <StyledChip label={"N/A"} />
                  </>
                )
              ) : (
                <Typography variant="caption" color="textSecondary">
                  {data?.category}
                </Typography>
              )}

              {!hideDivider && (
                <Divider
                  orientation="vertical"
                  flexItem
                  className={
                    data?.care_provider?.length > 0 &&
                    data?.category?.length > 0 &&
                    classes.divider
                  }
                />
              )}

              {/* {data?.care_provider?.map((cp, key) => <Typography key={key} variant="caption"
                    className={cp.name && classes.careProviderName} color="textSecondary">{cp.name}</Typography>)} */}
              {data?.care_provider?.constructor === Array ? (
                data?.care_provider?.length > 0 ? (
                  <div className={classes.footerDiv}>
                    {[...data?.care_provider].splice(0, 1)?.map((cp, key) => {
                      return cp?.name ? (
                        // <Typography
                        //   key={key}
                        //   variant="caption"
                        //   className={cp.name && classes.careProviderName}
                        //   style={{ marginRight: "8px" }}
                        //   color="textSecondary"
                        // >
                        //   {cp?.name ?? "N/A"}
                        // </Typography>
                        <StyledChip label={cp?.name ?? "N/A"} transparent />
                      ) : (
                        // <Typography
                        //   variant="body2"
                        //   color="textSecondary"
                        //   className={classes.category}
                        // >
                        //   {"N/A"}
                        // </Typography>
                        <StyledChip label={"N/A"} transparent />
                      );
                    })}
                    {data?.care_provider?.length > 1 && (
                      <PopperPopupState
                        open={props.open}
                        handleClose={() => props.handlePopperClose()}
                        handleOpen={(e) =>
                          props.handlePopperOpen(
                            e,
                            getSplicedData(data?.care_provider)
                          )
                        }
                        data={props.popperData}
                        count={data?.care_provider?.length - 1}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    {/* <Typography className={classes.careProviderName}>
                    N/A
                  </Typography> */}
                    <StyledChip label={"N/A"} transparent />
                  </>
                )
              ) : (
                <StyledChip
                  label={data?.care_provider ? data?.care_provider : "N/A"}
                  transparent
                />
              )}
            </div>
          </div>
        </Grid>
      </Hidden>
    </>
  );
};

export default DetailSection;
