import React from 'react';
import RatingsModule from '../../../../components/ratingsComp'

import { actions } from 'tanyacare-middleware'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { NotFoundSection, InternatlServerError, LoadingSection } from '../../../../components';

const styles = (theme) => ({

})

class Ratings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterOption : 1
        }
    }

    componentDidMount() {
        this.getClientRatings()
    }

    getClientRatings = () => {
        const id = this.props?.match?.params?.client_id;
        this.props.GET_PRODUCT_RATING({ clientID: id, ...this.state })
    }

    onFilterChange = (filterOption) =>{
        this.setState({
            filterOption
        },()=>{
            this.getClientRatings()
        })
    }

    render() {
        let { data, loading, error } = this.props?.productRatings;
        
        return (<>
            {loading && <LoadingSection message="Loading client ratings..." />}
            {!loading && error && <InternatlServerError />}
            {!loading && !error && !data && <NotFoundSection message='No ratings found' />}
            {!loading && !error && data && <div>
                <RatingsModule {...data} onFilterChange = {this.onFilterChange} filter={this.state.filterOption}/>
            </div>}
        </>)
    }
}

const mapStateToProps = (state) => ({
    productRatings: state?.productRating?.allRatings
})

export default connect(mapStateToProps, actions)(withRouter(withStyles(styles)(Ratings)));