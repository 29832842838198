import React from 'react'

import { withStyles } from '@material-ui/core'

import Filter from './filter'

import RatingsCard from './ratingsCard'

import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    boxShadow: `0px 0.5px 2px 1px #e0e0e0d6`,
    background: 'white',
    borderRadius: 8,
    position: 'relative', //For filter to stay inside
  },
  filterContainer: {
    width: '100%'
  },
  ratingContainer: {
    overflowY: "scroll",
  }
})

class RatingsModule extends React.Component {
  render() {
    const { classes, ratings_list, onFilterChange, filter } = this.props
    return (
      <div className={classes.root} style={{ margin: 20 }}> {/*The styling used here is only for development purpose remove when adding into a screen */}
        <div className={classes.filterContainer}>
          <Filter onFilterChange={onFilterChange} filter={filter} count={ratings_list?.length}
            title={this.props.title} hideFilter/>
        </div>
        <div className={classes.ratingContainer}>
          {ratings_list?.map((rating, index) => {
            return (
              <RatingsCard index={index} {...rating} />
            )
          })}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(RatingsModule)

RatingsModule.propTypes = {
  /**To change the title of the component */
  title: PropTypes.string
}