import React from "react";
import { IconComponent } from "../../../../../../assets";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";

// InfoWithIcon component
const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 12px;
  margin-right: 30px;
  line-height: 24px;
`;

const InfoWithIcon = ({ iconName, title, fromTime, toTime, style }) => {
  const theme = useTheme();
  return (
    <InfoContainer style={{ ...style }}>
      <IconComponent name={iconName} style={{ fontSize: "1.4rem" }} />
      <div style={{ display: "flex", flexDirection: "column", rowGap: 6 }}>
        <Typography
          style={{
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "21px",
          }}
          align="left"
        >
          {title}
        </Typography>
        <Typography
          style={{
            fontSize: 12,
            fontWeight: 400,
            color: theme.palette.text.secondary,
            lineHeight: "16px",
          }}
          align="left"
        >
          {moment(fromTime).format("MMM DD, hh:mm A")} -{" "}
          {moment(toTime).format("MMM DD, hh:mm A")}
        </Typography>
      </div>
    </InfoContainer>
  );
};

InfoWithIcon.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  fromTime: PropTypes.instanceOf(Date),
  toTime: PropTypes.instanceOf(Date),
  style: PropTypes.object,
};

export default InfoWithIcon;
