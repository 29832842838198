import React from "react";
import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

export const ClientMedicalDiagnosisIcon = (props) => {
  return (
    <SvgIcon
      fontSize={"small"}
      {...props}
      htmlColor={props.color}
      style={props.style}
    >
      <path id="Path_623" d="M19 4h-4.184a2.982 2.982 0 0 0-5.632 0H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 16H5V6h7a1 1 0 1 1 1-1 1 1 0 0 1-1 1h7z" class="cls-1" data-name="Path 623" />
      <path id="Path_624" d="M11 9h2v8h-2z" class="cls-1" data-name="Path 624" />
      <path id="Path_625" d="M8 12h8v2H8z" class="cls-1" data-name="Path 625" />
    </SvgIcon>
  )
}

ClientMedicalDiagnosisIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};
