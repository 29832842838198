import React from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
    padding: 25,
  },
  cardTop: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  },
  cardBottom: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  },
  buttonStyle: {
    borderRadius: `8px !important`,
    width: `100px !important`
  },
  sectionName: {
    marginTop: 20,
    textTransform: 'uppercase'
  },
  toggleButtonGroup: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  }
})

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    '&:nth-child(2)': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    marginTop: theme.spacing(1.5)
  },
}))(ToggleButtonGroup);


const StyledButton = withStyles((theme) => ({
  root: {
    color: `${theme.palette.ternary.main} !important`,
    border: `1.6px solid rgba(0, 0, 0, 0.12) !important`,
    [theme.breakpoints.down('xs')]: {
      padding: 4
    }
  },
  label: {
    color: `${theme.palette.text.primary} !important`
  },
  selected: {
    border: `1.6px solid ${theme.palette.ternary.main} !important`,
    // background: `rgba(242, 125, 53, 0.12) !important`,
    background: `none !important`,
    '&:hover': {
      background: `rgba(242, 125, 53, 0.15) !important`,
      // background: `none !important`,
    }
  },

}))(ToggleButton)





class IssueMessageBar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const { classes, toggleTypes } = this.props
    const { isCardTop, isCardBottom, selected, handleStatus } = this.props

    //Data
    const { issue_description, issue_status } = this.props.data
    return (
      <Grid container className={`${classes.root} ${isCardTop ? classes.cardTop : isCardBottom ? classes.cardBottom : ''}`}>
        <Grid item>
          <Typography variant={"body1"}>
            {issue_description ? issue_description : ''}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary" className={classes.sectionName}>set status</Typography>
        </Grid>
        <Grid item container xs={12}>
          <StyledToggleButtonGroup value={issue_status && !(!!selected) ? issue_status.toLowerCase() : selected} exclusive onChange={handleStatus} className={classes.toggleButtonGroup}>
            {toggleTypes?.map((button, index) => {
              return <StyledButton className={classes.buttonStyle} value={button.value}>
                {button.label}
              </StyledButton>
            })}
          </StyledToggleButtonGroup>
        </Grid>
      </Grid >
    )
  }
}

export default withStyles(styles)(IssueMessageBar)

IssueMessageBar.propTypes = {
  /**
   * To check if the card is placed at top to add respective css to Top
   */
  isCardTop: PropTypes.bool,
  /**
   * To check if the card is placed at Bottom to add respective css to Bottom
   */
  isCardBottom: PropTypes.bool,
  /**
   * Array of buttons to be placed in a toggle group
   */
  toggleTypes: PropTypes.array
}