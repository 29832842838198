import React from 'react';
import selectStyles from './styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { InputLabel, makeStyles } from '@material-ui/core';

const useMaterialUISelectStyles = makeStyles((theme) => selectStyles(theme))

export function StyledMaterialUISelect({ label, value, handleChange, options, disabled=false }) {
    const materialUISelectClasses = useMaterialUISelectStyles();
    const iconComponent = (props) => {
        return (
            <ExpandMoreIcon className={props.className + " " + materialUISelectClasses.icon} />
        )
    };
    // moves the menu below the select input
    const menuProps = {
        classes: {
            paper: materialUISelectClasses.paper,
            list: materialUISelectClasses.list
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    };
    return (
        <FormControl classes={{root: materialUISelectClasses.formControl}} disabled={disabled}>
            {label && <InputLabel shrink classes={{focused: materialUISelectClasses.labelFocused}}>{label}</InputLabel>}
            <Select
                disableUnderline
                classes={{ root: materialUISelectClasses.select }}
                MenuProps={menuProps}
                IconComponent={iconComponent}
                value={value}
                onChange={handleChange}
            >
                {(options?.length > 0 ? options : [])?.map(item => {
                    return (
                        <MenuItem
                            key={item.value}
                            value={item.value}
                            name={item.name}
                        >
                            {item.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    )
}