import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  // Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Typography,
  useTheme,
  withStyles,
  withTheme,
  // TextField
} from "@material-ui/core";
import { TextField } from "../inputs";
import {
  capitalizeString,
  exoticColumnType,
  formComponentTypes,
  minColumnSizeStyles,
  TAXTYPE,
} from "../../utils";
import { quantityNumberCheck, validateOnPaste } from "../../utils/validations";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import PopperPopupState from "../popper/PopperPopupState";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DenseSwitch from "../switch/denseSwitch";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { StyledMaterialUISelect } from "../styledInputs";

const styles = (theme) => ({
  inputColor: {
    "&::after": {
      borderColor: theme.palette.ternary.main,
    },
  },
  textFieldStyle: {
    "& input": {
      paddingInline: 7,
      maxWidth: 80,
      "&:hover": {
        backgroundColor: "#F0F0F0",
      },
      "&:focus": {
        backgroundColor: "#F0F0F0",
      },
    },
  },
  root: (props) => ({
    width: "100%",
    "& .MuiTableBody-root": {
      "& .MuiTableRow-hover": {
        "&:hover": {
          backgroundColor: props?.tableHover ? "#0000000a" : "transparent",
        },
      },
    },
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#F0F6FF",
      padding: "8px",
      color: "#252B43",
      fontWeight: 500,
      fontSize: 14,
    },
    "& th": {
      borderBottom: "none !important",
      color: theme.palette.secondary.main,
    },
    "& td": {
      padding: 12,
      borderBottom: `1px solid ${theme.palette.secondary.light} !important`,
      fontWeight: 400,
    },
  }),
  inputField: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.secondary.light,
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.secondary.light,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 8px !important",
    },
  },
  select: {
    backgroundColor: "unset !important",
  },
  selectMenu: {
    // minWidth: 200
  },
  // tableCellClassName: {
  //   [theme.breakpoints.only("xs")]: {
  //     minWidth: (props) =>
  //       props.minWidth
  //         ? props?.minWidth?.constructor === Object
  //           ? props?.minWidth?.xs
  //           : props?.minWidth
  //         : "fit-content"
  //   },
  //   [theme.breakpoints.only("sm")]: {
  //     minWidth: (props) =>
  //       props.minWidth
  //         ? props?.minWidth?.constructor === Object
  //           ? props?.minWidth?.sm
  //           : props?.minWidth
  //         : "fit-content"
  //   },
  //   [theme.breakpoints.only("md")]: {
  //     minWidth: (props) =>
  //       props.minWidth
  //         ? props?.minWidth?.constructor === Object
  //           ? props?.minWidth?.md
  //           : props?.minWidth
  //         : "fit-content"
  //   },
  //   [theme.breakpoints.only("lg")]: {
  //     minWidth: (props) =>
  //       props.minWidth
  //         ? props?.minWidth?.constructor === Object
  //           ? props?.minWidth?.lg
  //           : props?.minWidth
  //         : "fit-content"
  //   },
  //   [theme.breakpoints.only("xl")]: {
  //     minWidth: (props) =>
  //       props.minWidth
  //         ? props?.minWidth?.constructor === Object
  //           ? props?.minWidth?.xl
  //           : props?.minWidth
  //         : "fit-content"
  //   }
  // },
  container: {
    // maxHeight: 440
  },
  remove: {
    color: theme.palette.error.main,
    fontSize: "0.7rem",
    boxShadow: "none !important",
  },
  removeIconButtonsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  removeIconButtons: {
    // margin: "0px 0px",
    padding: 4, //sets the ripple radius
  },
  iconColor: {
    color: theme.palette.secondary.main,
  },

  // Multiple dependent services styles
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 350,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },

  // tableCellMinWidths should be before the spreaded classes to avoid overriding the custom widths
  tableCellMinWidths: {
    minWidth: "fit-content",
  },
  ...minColumnSizeStyles(theme),
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledChip = withStyles((props) => {
  const theme = useTheme();
  return {
    root: {
      height: 24,
      backgroundColor: theme.palette.chip.main,
      color: "unset",
      borderRadius: 8,
      margin: 2,
      maxWidth: 100,
    },
  };
})((props) => <Chip {...props} />);

class QuoteTable extends React.Component {
  componentDidUpdate() {
    if (document.getElementById(this?.props?.id?.discount_id)) {
      document.getElementById(this?.props?.id?.discount_id).addEventListener(
        "wheel",
        function (e) {
          e.preventDefault();
        },
        { passive: false }
      );
    }
    if (document.getElementById(this?.props?.id?.tax_id)) {
      document.getElementById(this?.props?.id?.tax_id).addEventListener(
        "wheel",
        function (e) {
          e.preventDefault();
        },
        { passive: false }
      );
    }
  }

  componentWillUnmount() {
    // document.getElementById("discount") !== null ?? document.getElementById("discount").removeEventListener('wheel', function(e){e.preventDefault()}, {passive: false})
    // document.getElementById("tax") !== null ?? document.getElementById("tax").removeEventListener('wheel', function(e){e.preventDefault()}, {passive: false})
    document.getElementById(this?.props?.id?.discount_id) !== null ??
      document.getElementById(this?.props?.id?.discount_id).removeEventListener(
        "wheel",
        function (e) {
          e.preventDefault();
        },
        { passive: false }
      );
    document.getElementById(this?.props?.id?.tax_id) !== null ??
      document.getElementById(this?.props?.id?.tax_id).removeEventListener(
        "wheel",
        function (e) {
          e.preventDefault();
        },
        { passive: false }
      );
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    console.log(
      `dragEnd ${result.source.index} to  ${result.destination.index}`
    );
    const items = reorder(
      this.props.rows,
      result.source.index,
      result.destination.index
    );
    this.props.handleRowsReorder(
      items,
      result.source.index,
      result.destination.index
    );
  };

  checkTaxType = () => {
    const { taxType, taxTypeOptions = [] } = this.props;
    const selectedTaxType = taxTypeOptions?.filter(
      (tax) => tax?.value === taxType
    )?.[0];
    return selectedTaxType?.type === TAXTYPE.FIXED ? true : false;
  };

  getItemStyle = (isDragging, draggableStyle, row) => ({
    ...draggableStyle,
    cursor: row?.uniqueId ? "grab" : "auto",
  });

  render() {
    const { classes, theme } = this.props;
    const {
      rows,
      columns,
      handleRemove,
      discount,
      onDiscountChange,
      // withDiscount,
      tax,
      taxType,
      taxAmount,
      onTaxChange,
      // withTax,
      // withTaxAndTaxType,
      // withTotal,
      total,
      needActionsAsIcons,
      withoutRemove,
      editAction,
      deleteAction,
      // currency,
      isdownload,
      id,
      // isQuote,
      // hideTaxType,
      exoticRow = [],
    } = this.props;

    // const handleEmptyTableCell = (columns, alreadyOccupiedCells = 0, rowSpan = 1) => {
    //   let _lengthOfColumns = Object.keys(columns).length;
    //   let cell = [];
    //   for (var i = 0; i < _lengthOfColumns - alreadyOccupiedCells; i++) {
    //     cell.push(<TableCell rowSpan={rowSpan} />);
    //   }
    //   return cell;
    // };

    const renderEmptyCells = (count) => {
      let tableCells = new Array(count);
      return tableCells.fill(<TableCell rowSpan={1} />);
    };

    const TableHeadCustomRow = (props) => {
      const { classes } = this.props;
      const { column, i, tableType } = props;
      const columnClassName = `${column.id}-${tableType}-Style`;
      return (
        <>
          <TableCell
            key={column.id}
            align={column.align}
            // style={{ minWidth: column.minWidth }}
            // className={classes.tableCellClassName}
            className={`${classes.tableCellMinWidths}  ${classes[columnClassName]}`}
            style={{ whiteSpace: "nowrap" }}
          >
            {column.label}
          </TableCell>
          {!needActionsAsIcons && columns.length - 1 === i && (
            <TableCell key="remove" align="right"></TableCell>
          )}
        </>
      );
    };

    return (
      // <Paper className={classes.root}>
      <>
        <TableContainer className={`${classes.container} ${classes.root}`}>
          <Table stickyHeader aria-label="sticky table">
            {/* {props?.colWidths &&
            props.colWidths.map((colWidth, i) => (
              <col key={"colWidth_" + i} width={colWidth} />
            ))} */}
            <TableHead>
              <TableRow style={{ backgroundColor: !isdownload ? "red" : "" }}>
                {columns.map((column, i) => (
                  <TableHeadCustomRow
                    {...this.props}
                    column={column}
                    i={i}
                    minWidth={column.minWidth}
                  />
                ))}
                {needActionsAsIcons && (
                  <TableCell key="remove" align="center">
                    ACTIONS
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={this.onDragEnd} onDragStart={() => {}}>
              <Droppable droppableId={"tableBody"} direction="vertical">
                {(provided) => {
                  return (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {/* component={DroppableComponent(this.onDragEnd)} */}
                      {rows?.length > 0 ? (
                        rows.map((row, index) => {
                          return (
                            <Draggable
                              draggableId={row?.uniqueId}
                              index={index}
                              key={row?.uniqueId}
                            >
                              {(provided, snapshot) => (
                                <TableRow
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={this.getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                    row
                                  )}
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row?.uniqueId}
                                  // onMouseLeave={props?.handlePopperClose}
                                  onMouseLeave={(e) => {
                                    if (e?.relatedTarget?.id === "popper") {
                                      return false;
                                    } else {
                                      if (this?.handlePopperClose)
                                        this.handlePopperClose();
                                    }
                                  }}
                                >
                                  {/* component={DraggableComponent(
                                `${row.label}`,
                                index
                              )} */}
                                  {columns.map((column, i) => {
                                    let columnId = ["quantity", "price"];
                                    const value = row[column.id]
                                      ? row[column.id]
                                      : column.id === "total"
                                      ? ""
                                      : column.id === "strict"
                                      ? row?.[column.id]
                                      : columnId.includes(column.id)
                                      ? row[column.id]
                                      : "N/A";
                                    return (
                                      <>
                                        {column.id === "sno" ? (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {index + 1}
                                          </TableCell>
                                        ) : column.id === "drag" ? (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            <DragIndicatorIcon
                                              htmlColor={
                                                "rgba(146, 151, 165, 1)"
                                              }
                                            />
                                          </TableCell>
                                        ) : column.id === "type" ? (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {`${capitalizeString(value)} ${
                                              row.service_count
                                                ? `(${row.service_count} Services)`
                                                : ``
                                            }`}
                                          </TableCell>
                                        ) : !row.quantityEditable &&
                                          column.id === "quantity" ? (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {value}
                                          </TableCell>
                                        ) : column.type ===
                                          formComponentTypes.INPUT_BOX ? (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            <Input
                                              // value={ row.type !== "service" ? 1 : value}
                                              value={value}
                                              placeholder="1"
                                              className={classes.textFieldStyle}
                                              {...column.props}
                                              // disableUnderline={ row.type !== "service" }
                                              // disabled={row.type !== "service"}
                                              onKeyPress={(event) => {
                                                const isNumber = true;
                                                quantityNumberCheck(
                                                  event,
                                                  isNumber
                                                );
                                              }}
                                              onPaste={(e) => {
                                                validateOnPaste(e);
                                              }}
                                              onChange={(e) => {
                                                this.props.onTextFieldChange(
                                                  index,
                                                  column.id,
                                                  e.target.value
                                                );
                                              }}
                                              classes={{
                                                underline: classes.inputColor,
                                              }}
                                            />
                                          </TableCell>
                                        ) : column.type ===
                                          formComponentTypes.SELECT_BOX ? (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {index !== 0 &&
                                              this.props?.rows?.length > 1 && (
                                                <>
                                                  {/* <Select
                                                      disabled={row["strict"]}
                                                      value={value}
                                                      onChange={(e) =>
                                                        this.props.handleDependentServiceChange(
                                                          index,
                                                          column.id,
                                                          e.target.value
                                                        )
                                                      }
                                                      style={{ width: "100%" }}
                                                      classes={{
                                                        selectMenu:
                                                          classes.selectMenu,
                                                        underline:
                                                          classes.inputColor,
                                                      }}
                                                      color={
                                                        theme.palette.ternary.main
                                                      }
                                                    >
                                                      {this.props.rows
                                                        .filter(
                                                          (s, idx) => idx < index
                                                        )
                                                        .map((menuitem) => (
                                                          <MenuItem
                                                            value={JSON.stringify({
                                                              label: menuitem.label,
                                                              value: menuitem.value,
                                                            })}
                                                          >
                                                            {menuitem.label}
                                                          </MenuItem>
                                                        ))}
                                                    </Select> */}
                                                  <FormControl
                                                    className={
                                                      classes.formControl
                                                    }
                                                  >
                                                    <Select
                                                      multiple
                                                      disabled={row["strict"]}
                                                      value={
                                                        Array.isArray(value)
                                                          ? value?.map((_) =>
                                                              JSON.stringify(_)
                                                            )
                                                          : []
                                                      }
                                                      onChange={(e) =>
                                                        this.props.handleDependentServiceChange(
                                                          index,
                                                          column.id,
                                                          e.target.value
                                                        )
                                                      }
                                                      input={
                                                        <Input
                                                          id="select-multiple-chip"
                                                          classes={{
                                                            underline:
                                                              classes.inputColor,
                                                          }}
                                                        />
                                                      }
                                                      renderValue={(
                                                        selected
                                                      ) => {
                                                        if (
                                                          typeof selected ===
                                                          typeof ""
                                                        ) {
                                                          selected = [];
                                                        }
                                                        return (
                                                          <div
                                                            className={
                                                              classes.chips
                                                            }
                                                          >
                                                            {selected.map(
                                                              (
                                                                value,
                                                                index
                                                              ) => (
                                                                <StyledChip
                                                                  key={index}
                                                                  label={
                                                                    JSON.parse(
                                                                      value
                                                                    )?.label
                                                                  }
                                                                />
                                                              )
                                                            )}
                                                          </div>
                                                        );
                                                      }}
                                                      MenuProps={MenuProps}
                                                      classes={{
                                                        select: classes.select,
                                                      }}
                                                    >
                                                      {this.props.rows
                                                        .filter(
                                                          (s, idx) =>
                                                            idx !== index
                                                        )
                                                        .map((menuitem) => (
                                                          <MenuItem
                                                            value={JSON.stringify(
                                                              {
                                                                label:
                                                                  menuitem.label,
                                                                value:
                                                                  menuitem.value,
                                                              }
                                                            )}
                                                          >
                                                            <Checkbox
                                                              icon={
                                                                <CheckBoxOutlineBlankIcon
                                                                  htmlColor={
                                                                    theme
                                                                      .palette
                                                                      .ternary
                                                                      .main
                                                                  }
                                                                />
                                                              }
                                                              checkedIcon={
                                                                <CheckBoxIcon
                                                                  htmlColor={
                                                                    theme
                                                                      .palette
                                                                      .ternary
                                                                      .main
                                                                  }
                                                                />
                                                              }
                                                              checked={
                                                                value
                                                                  .map((_) =>
                                                                    JSON.stringify(
                                                                      _
                                                                    )
                                                                  )
                                                                  .indexOf(
                                                                    JSON.stringify(
                                                                      {
                                                                        label:
                                                                          menuitem.label,
                                                                        value:
                                                                          menuitem.value,
                                                                      }
                                                                    )
                                                                  ) > -1
                                                              }
                                                            />
                                                            {menuitem.label}
                                                          </MenuItem>
                                                        ))}
                                                    </Select>
                                                  </FormControl>
                                                </>
                                              )}
                                          </TableCell>
                                        ) : column.type ===
                                          formComponentTypes.CHECK_BOX ? (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            <Checkbox
                                              checked={value}
                                              onChange={(e) =>
                                                this.props.handleStrictStatusChange(
                                                  index,
                                                  column.id,
                                                  e.target.checked
                                                )
                                              }
                                              icon={
                                                <CheckBoxOutlineBlankIcon
                                                  htmlColor={
                                                    theme.palette.ternary.main
                                                  }
                                                />
                                              }
                                              checkedIcon={
                                                <CheckBoxIcon
                                                  htmlColor={
                                                    theme.palette.ternary.main
                                                  }
                                                />
                                              }
                                            />
                                          </TableCell>
                                        ) : row.price_editable &&
                                          (column.id === "total" ||
                                            column.id ===
                                              "serviceAmountPrice" ||
                                            column.id === "price") ? (
                                          <TableCell
                                            // key={uuidv4()}
                                            align={column.align}
                                          >
                                            <>
                                              <Input
                                                value={value}
                                                className={
                                                  classes.textFieldStyle
                                                }
                                                placeholder="1"
                                                {...column.props}
                                                type="number"
                                                onKeyPress={(event) => {
                                                  if (
                                                    event.key === "e" ||
                                                    event.which === 101 ||
                                                    event.which === 69
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onBlur={(e) =>
                                                  this.props.onBlurTextField(
                                                    index,
                                                    column.id,
                                                    e.target.value
                                                  )
                                                }
                                                onChange={(e) => {
                                                  this.props.onTextFieldChange(
                                                    index,
                                                    column.id,
                                                    e.target.value
                                                  );
                                                }}
                                                classes={{
                                                  underline: classes.inputColor,
                                                }}
                                              />
                                            </>
                                          </TableCell>
                                        ) : column.id === "providerDetails" ? (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {value?.constructor === Array ? (
                                              value.length > 1 ? (
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  {[...value]
                                                    .splice(0, 1)
                                                    .map((val) => val.label)}

                                                  {value?.length > 1 && (
                                                    <PopperPopupState
                                                      open={
                                                        this.props.openPopper
                                                      }
                                                      handleClose={() =>
                                                        this.props?.handlePopperClose()
                                                      }
                                                      handleOpen={(e) =>
                                                        this.props?.handlePopperOpen(
                                                          e,
                                                          value
                                                        )
                                                      }
                                                      data={
                                                        this.props.popperData
                                                      }
                                                      list={value}
                                                      count={value?.length}
                                                    />
                                                  )}
                                                </div>
                                              ) : (
                                                value.map((val) => val.label)
                                              )
                                            ) : (
                                              value
                                            )}
                                          </TableCell>
                                        ) : (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {column.format &&
                                            column.id === "amount"
                                              ? `${column.format(value)}`
                                              : value}
                                          </TableCell>
                                        )}

                                        {!withoutRemove &&
                                          !needActionsAsIcons &&
                                          columns.length - 1 === i && (
                                            <TableCell
                                              key="remove"
                                              align="right"
                                            >
                                              <Button
                                                variant="text"
                                                className={classes.remove}
                                                id={`removeButton${this.props?.parentId}`}
                                                disabled={
                                                  this.props.disableRemove
                                                }
                                                onClick={(e) => {
                                                  handleRemove(row);
                                                }}
                                              >
                                                REMOVE
                                              </Button>
                                            </TableCell>
                                          )}

                                        {!withoutRemove &&
                                          needActionsAsIcons &&
                                          columns.length - 1 === i && (
                                            <TableCell
                                              key="remove"
                                              align="left"
                                            >
                                              <div
                                                className={
                                                  classes.removeIconButtonsContainer
                                                }
                                              >
                                                <IconButton
                                                  onClick={() =>
                                                    editAction(row)
                                                  }
                                                  className={
                                                    classes.removeIconButtons
                                                  }
                                                >
                                                  <CreateOutlinedIcon
                                                    fontSize="small"
                                                    htmlColor={
                                                      theme.palette.secondary
                                                        .main
                                                    }
                                                  />
                                                </IconButton>
                                                <DenseSwitch
                                                  value={row?.isActive}
                                                  handleOnChange={(e) =>
                                                    deleteAction(e, row)
                                                  }
                                                />
                                              </div>
                                            </TableCell>
                                          )}
                                      </>
                                    );
                                  })}
                                  {provided.placeholder}
                                </TableRow>
                              )}
                            </Draggable>
                          );
                        })
                      ) : (
                        // If length of rows array of object is 0(zero), then render this
                        <TableRow style={{ textAlign: "center" }}>
                          <TableCell
                            key="remove"
                            colSpan={this.props.columns.length || 0}
                            align="center"
                            style={{ width: "100%" }}
                          >
                            No Records found
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow colSpan={columns?.length}>
                        {exoticRow?.map((col) => {
                          if (col) {
                            switch (col?.type) {
                              case exoticColumnType.TAX_TYPE:
                                return (
                                  <TableCell
                                    colSpan={1}
                                    style={{ minWidth: 100 }}
                                  >
                                    <TaxType
                                      label={"TAX TYPE"}
                                      value={taxType}
                                      handleChange={this.props.onTaxTypeChange}
                                      options={this.props.taxTypeOptions}
                                      disabled={rows?.length === 0}
                                    />
                                  </TableCell>
                                );

                              case exoticColumnType.TAX_PERCENTAGE:
                                return (
                                  <TableCell
                                    colSpan={1}
                                    style={{ minWidth: 100 }}
                                  >
                                    <div style={{ width: 84 }}>
                                      <TextField
                                        className={classes.inputField}
                                        type={"number"}
                                        disabled={
                                          rows?.length === 0
                                            ? true
                                            : this.checkTaxType()
                                            ? true
                                            : false
                                        }
                                        rowsMax={1}
                                        id={id?.tax_id}
                                        value={
                                          parseFloat(tax).toFixed(2) === "0.00"
                                            ? null
                                            : tax
                                        }
                                        title={"TAX"}
                                        padding={"5px 12px"}
                                        multiline={false}
                                        placeholder=""
                                        handleOnChange={(e) => {
                                          onTaxChange(e.target.value);
                                        }}
                                        overrideDecimalCheckForDiscount
                                        style={{ maxWidth: 78 }}
                                        endAdornmentText={"%"}
                                      />
                                    </div>
                                  </TableCell>
                                );

                              case exoticColumnType.DISCOUNT:
                                return (
                                  <TableCell
                                    colSpan={1}
                                    style={{ minWidth: 120, float: "right" }}
                                  >
                                    <div style={{ width: 84 }}>
                                      <TextField
                                        className={classes.inputField}
                                        type={"number"}
                                        disabled={
                                          rows?.length > 0 ? false : true
                                        }
                                        rowsMax={1}
                                        // id={"discount"}
                                        id={
                                          id?.discount_id ??
                                          this.props?.parentId
                                            ? `discount${this.props.parentId}`
                                            : ""
                                        }
                                        value={
                                          parseFloat(discount).toFixed(2) ===
                                          "0.00"
                                            ? null
                                            : discount
                                        }
                                        title={"DISCOUNT"}
                                        padding={"5px 12px"}
                                        multiline={false}
                                        placeholder=""
                                        handleOnChange={(e) => {
                                          onDiscountChange(e.target.value);
                                        }}
                                        overrideDecimalCheckForDiscount
                                        style={{ maxWidth: 78 }}
                                        endAdornmentText={"%"}
                                      />
                                    </div>
                                  </TableCell>
                                );

                              case exoticColumnType.TAX_AMOUNT:
                                return (
                                  <TableCell
                                    colSpan={1}
                                    style={{ minWidth: 120 }}
                                  >
                                    <div style={{ width: 84 }}>
                                      <TextField
                                        className={classes.inputField}
                                        type={"number"}
                                        disabled={true}
                                        rowsMax={1}
                                        // id={"discount"}
                                        // id={id?.discount_id}
                                        id={
                                          id?.tax_id ?? this.props?.parentId
                                            ? `taxInput${this.props.parentId}`
                                            : ""
                                        }
                                        value={
                                          parseFloat(taxAmount).toFixed(2) ===
                                          "0.00"
                                            ? ""
                                            : taxAmount
                                        }
                                        // value={taxAmount}
                                        title={"TAX"}
                                        padding={"5px 12px"}
                                        multiline={false}
                                        placeholder=""
                                        // handleOnChange={(e) => {
                                        //   onDiscountChange(e.target.value);
                                        // }}
                                        overrideDecimalCheckForDiscount
                                        style={{ maxWidth: 120 }}
                                        // endAdornmentText={"%"}
                                      />
                                    </div>
                                  </TableCell>
                                );

                              case exoticColumnType.TOTAL_TEXT:
                                return (
                                  <>
                                    <TableCell colSpan={1}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          
                                        }}
                                      >
                                        <Typography style={{ fontSize: 16, fontWeight: 400, }}>
                                          {this?.props?.totalLabel
                                            ? this?.props?.totalLabel
                                            : "TOTAL"}
                                        </Typography>
                                        <Typography style={{ fontSize: 16, fontWeight: 400, }}>
                                          :
                                        </Typography>
                                      </div>
                                    </TableCell>
                                  </>
                                );

                              case exoticColumnType.TOTAL_VALUE:
                                return (
                                  <TableCell colSpan={1}>
                                    <Typography
                                      // style={{ float: "right" }}
                                      noWrap
                                      style={{ fontSize: 16, fontWeight: 500 }}
                                    >
                                      {`${
                                        total
                                          ? parseFloat(total).toFixed(2)
                                          : "0.00"
                                      }`}
                                    </Typography>
                                  </TableCell>
                                );

                              default:
                                renderEmptyCells(1);
                            }
                          } else {
                            return renderEmptyCells(1);
                          }
                          return <></>;
                        })}
                      </TableRow>

                      {provided.placeholder}
                    </TableBody>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
        {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> */}
      </>
      // </Paper>
    );
  }
}

export default withTheme(withStyles(styles)(QuoteTable));

function TaxType({ label, value, handleChange, options, disabled }) {
  return (
    <div style={{ maxWidth: 150 }}>
      <StyledMaterialUISelect
        label={label}
        value={value}
        handleChange={handleChange}
        options={options}
        disabled={disabled}
      />
    </div>
  );
}
