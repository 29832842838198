import React from "react";
import {
  Grid,
  Typography,
  Button,
  Divider,
  withStyles
} from "@material-ui/core";
import CardList from "../../../../components/userList";
import DetailsCard from "./detailCard";
import { routes } from "../../../../router/routes";
import { withRouter } from "react-router-dom";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
// import QuickCards from "../../../../components/quickCards";
import { ICONS, GRAPH } from "../../../../components/quickCards";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { alertProps, scrollToTop } from "../../../../utils";
import SimpleAvatarCard from "../../../../components/simpleAvatarCard";
import AvatarStack from "../../../../components/avatarStack";
import { UserIcon } from "../../../../assets";
import ViewServiceProfessional from "./ViewServiceProfessional";

const sizes = {
  md: 12,
  sm: 6,
  xs: 12
};

const style = (theme) => ({
  simpleAvatarSection: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1.5)
    }
  }
});

class CareCoordinatorDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.getCareCoordinatorDetail();
    this.props.GET_SAL({});
    scrollToTop();
  }

  getCareCoordinatorDetail = () => {
    const id = this.props?.match?.params?.id;
    this.props.GET_CARE_COORDINATOR_INFO({ id });
  };

  onDeactivateClicked = () => {
    const Oldstatus = this.props?.careCoordinatorInfo?.data?.isactive;
    let status = "Activate";
    if (Oldstatus === "Active") {
      status = "Deactivate";
    }
    this.props.dialog.setDialog({
      open: true,
      title: "Are you sure?",
      body:
        "Do you want to " +
        status +
        " Care Coordinator : " +
        this?.props?.careCoordinatorInfo?.data?.name,
      positiveBtn: status,
      negativeBtn: "Cancel",
      onOk: this.deactiveUser
    });
  };

  deactiveUser = () => {
    const id = this.props?.match?.params?.id;
    const Oldstatus = this.props?.careCoordinatorInfo?.data?.isactive;
    let newStatus = "Active";
    if (Oldstatus === "Active") {
      newStatus = "InActive";
    }
    this.props.dialog.setDialog({ open: false });

    this.props.UPDATE_CARE_COORDINATOR_STATUS({ id, status: newStatus });
  };

  giveMeNecessaryData = () => {
    const { location, careCoordinatorInfo } = this.props;

    const { data } = careCoordinatorInfo;

    const {data: salutationOptions} = this.props?.salutation ?? [{}]

    let necessaryData = {
      ...careCoordinatorInfo
    };

    let bottomCard = [
      {
        count: "0",
        loading: true,
        label: "Clients",
        icon: ICONS.CLIENT("#28a9c1"),
        backgroundColor: "#28a9c1",
        color: "#000000", //#28a9c1
        graph: GRAPH.ONE
      },
      {
        count: "0",
        loading: true,
        label: "Upcoming",
        icon: ICONS.CALENDAR("#353596"),
        backgroundColor: "#353596",
        color: "#353596",
        graph: GRAPH.TWO
      },
      {
        count: "",
        loading: true,
        label: "Ratings",
        icon: ICONS.STAR("#f27d35"),
        backgroundColor: "#f27d35",
        color: "#f27d35",
        graph: GRAPH.THREE
      }
    ];

    if (location?.state) {
      necessaryData = {
        ...location.state,
        ...careCoordinatorInfo,
        bottomCard
      };
    }

    if (data.id) {
      const _ = data;
      const header = [
        {
          id: _?.id,
          name: _?.name,
          category: _?.category,
          isactive: _?.isactive,
          profile_pic: _?.profile_pic,
          salutation:
            salutationOptions?.length > 0
              ? salutationOptions?.filter(
                  (sal) => _?.salutation === sal?.label
                )?.[0]?.label
              : [{}]
        }
      ];

      const footer = [
        {
          name: `${_.clients_count ? _.clients_count : "0"} Clients`
        }
      ];

      bottomCard[0].count = _.clients_count ? _.clients_count : 0;
      bottomCard[0].loading = false;

      bottomCard[1].count = _.upcoming_activities_count
        ? _.upcoming_activities_count
        : 0;
      bottomCard[1].loading = false;

      bottomCard[2].count = _.average_rating ? _.average_rating : 0;
      bottomCard[2].loading = false;

      necessaryData = {
        ...careCoordinatorInfo,
        header,
        footer,
        bottomCard
      };
    }

    return necessaryData;
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { updateCareCoordinatorStatus } = nextProps;
    const oldupdateCareCoordinatorStatus = this.props
      .updateCareCoordinatorStatus;

    if (
      oldupdateCareCoordinatorStatus?.loading !==
      updateCareCoordinatorStatus?.loading
    ) {
      if (
        !updateCareCoordinatorStatus.loading &&
        !updateCareCoordinatorStatus.error
      ) {
        this.getCareCoordinatorDetail();
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Caregiver status updated successfully!",
          severity: alertProps.severity.success
        });
      } else if (updateCareCoordinatorStatus.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: "Something went wrong! Unable to update.",
          severity: alertProps.severity.error
        });
      }
    }

    return true;
  }

  handleViewAllClick = (data) => {
    // console.log("CareCoordinatorDetails -> handleViewAllClick -> handleViewAllClick", "Clicked")

    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewServiceProfessional
          data={data}
          drawer={this.props.drawer}
          onEndIconClickHandler={this.onEndIconClickHandler}
        />
      )
    });
  };

  onEndIconClickHandler = (id, isCareGiver) => {
    // window.open(`${isCareGiver ? routes.manage_care_givers : routes.manage_care_coordinators}/view/${id}`, '_blank').focus()
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    });
    this.props.history.push(
      `${
        isCareGiver
          ? routes.manage_care_givers
          : routes.manage_care_coordinators
      }/view/${id}`
    );
  };

  onProviderClicked = (id) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    });
    this.props.history.push(`${routes.manage_care_providers}/view/${id}`);
  };

  onSupervisorClicked = (id) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    });
    this.props.history.push(`${routes.manage_supervisors}/view/${id}`);
  };

  render() {
    const { classes } = this.props;
    let {
      header,
      data,
      error,
      loading
      // bottomCard,
    } = this.giveMeNecessaryData();

    const id = this.props?.match?.params?.id;

    return (
      <>
        {(error || !data) && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <Typography variant="h6" align="center">
                Something went wrong!
              </Typography>
            </Grid>
            <Grid item>
              <Button
                style={{ marginTop: 16, textAlign: "center" }}
                variant={"outlined"}
                onClick={this.getCareCoordinatorDetail}
              >
                Try Again
              </Button>
            </Grid>
          </Grid>
        )}
        {!error && data && (
          <div style={{ padding: "25px" }}>
            <CardList
              list={header}
              componentOrder={2}
              sizes={sizes}
              isonlycard={true}
              link={routes.manage_care_coordinators + "/edit/" + id}
              onDeactivateClicked={this.onDeactivateClicked}
              isDeactivating={this.props?.updateCareCoordinatorStatus?.loading}
              isSalutationNeeded={true}
            />
            <DetailsCard loading={loading} data={data} />

            <Divider style={{ background: "rgba(244, 244, 245, 1)" }} />

            <div
              style={{
                padding: "25px 25px 40px 32px",
                background: "white",
                boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.04)"
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <SimpleAvatarCard
                    id={data?.care_provider?.[0]?.id}
                    title={"PROVIDER"}
                    name={data?.care_provider?.[0]?.name}
                    src={data?.care_provider?.[0]?.profile_pic}
                    loading={loading}
                    onAvatarClicked={(id) => this.onProviderClicked(id)}
                    emptyText="Not Mapped"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classes.simpleAvatarSection}
                >
                  <SimpleAvatarCard
                    id={data?.service_supervisors?.[0]?.id}
                    title={"SERVICE SUPERVISOR"}
                    name={data?.service_supervisors?.[0]?.name}
                    src={data?.service_supervisors?.[0]?.profile_pic}
                    loading={loading}
                    emptyText="Not Mapped"
                    onAvatarClicked={(id) => this.onSupervisorClicked(id)}
                    icon={
                      <UserIcon
                        style={{
                          height: 16,
                          width: 16,
                          margin: `0px 8px 0px 0px`,
                          position: "relative",
                          top: "-1px"
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                padding: "25px 25px 40px 32px",
                background: "white",
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04)"
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <AvatarStack
                    title={"SERVICE PROFESSIONALS"}
                    data={data?.service_professionals}
                    loading={loading}
                    emptyMessage="Not Mapped"
                    onAvatarClicked={(id) =>
                      this.onEndIconClickHandler(id, true)
                    }
                    onButtonClickHandler={() =>
                      this.handleViewAllClick(data?.service_professionals)
                    }
                  />
                </Grid>
              </Grid>
            </div>

            {/* <CardList list={footer} sizes={sizes} isonlycard={true} isbottom={true} /> */}
            {/* <QuickCards items={bottomCard} isBottom={true}></QuickCards> */}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  careCoordinatorInfo: state.careCoordinator?.careCoordinatorInfo,
  updateCareCoordinatorStatus:
    state.careCoordinator?.updateCareCoordinatorStatus,
  salutation: state?.masterModule?.getsal ?? {
    data: [],
    loading: false,
    error: false
  }
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAlelrtAndDialog(withStyles(style)(CareCoordinatorDetails))));
