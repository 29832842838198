import React from "react";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withAlertAndDialog } from "../../../HOCs";
import { withStyles, Hidden } from "@material-ui/core";
import { appBarHeight } from "../../../components/navBars/utils";
import {
  TopNavbar,
  SideNavbar,
  LoadingSection,
  InternatlServerError,
} from "../../../components";
import { clientDetailTabs, scrollToTop } from "../../../utils";
import { routes } from "../../../router/routes";
import Details from "./details";
import Reports from "./reports";
import Issues from "./issues";
import Recommendations from "./recommendations";
import Activities from "./activities";
import Ratings from "./ratings";
import TrackRecommendedProducts from "./recommendations/trackRecommendedProduct";
import RecommendedProductDetails from "./recommendations/recommendedProductDetails";
import IssueDetails from "./issues/issueDetails";
import { withTheme } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  content: {
    paddingTop: appBarHeight + 48,
    [theme.breakpoints.up("md")]: {
      paddingTop: appBarHeight,
    },
    width: "100%",
  },
});
class ClientInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateNavBarProps: true,
    };
  }

  componentDidMount() {
    this.getClientDetail();
    scrollToTop();
  }

  getClientDetail = () => {
    const id = this.props?.match?.params?.client_id;

    this.props.GET_CARE_GIVER_CLIENT_INFO({ id });
  };

  onTitleIconClicked = () => {
    const id = this.props?.match?.params?.client_id;

    this.props.history.push(routes.manage_clients + "/edit/" + id);
  };

  render() {
    const { match } = this.props;

    const { data, loading, error } = this.props?.careGiverClientInfo;

    const { classes, title, backUrl } = this.props;

    const id = this.props?.match?.params?.client_id;
    const type = this.props?.match?.params?.type;


    const isInterRoute =
      [
        "",
        routes.monitor_client_recommended_products,
        routes.monitor_client_recommended_product_tracking,
        routes.monitor_client_issue_detail,
      ].indexOf(this.props?.match?.path) > 0
        ? true
        : false;

    return (
      <div className={classes.root}>
        {/* Top Navigation bar */}
        <TopNavbar
          title={loading ? "loading..." : isInterRoute ? title : data?.[0]?.name}
          withBackButton={true}
          navBarArr={
            isInterRoute ? [] : clientDetailTabs(id, type ? type : "upcoming")
          }
          backUrl={backUrl ? backUrl + id : routes.monitor_clients}
          isAvoidGoBack={!isInterRoute}
          titleIcon={
            // isInterRoute ? null : (
            //   <EditClientIcon
            //     htmlColor={this.props.theme.palette.ternary.main}
            //   />
            // )
            false
          }
          onTitleIconClicked={this.onTitleIconClicked}
        />

        {/* Side Navigation bar */}
        <Hidden smDown>
          <SideNavbar />
        </Hidden>

        {/* Content */}
        <div className={classes.content}>
          {loading && <LoadingSection message="Loading client details..." />}
          {!loading && error && (
            <InternatlServerError onRetry={this.getClientDetail} />
          )}
          {!loading && !error && data && (
            <div>
              {match.path === routes.monitor_client_details && (
                <Details
                  data={data}
                  loading={true}
                  getClientDetail={this.getClientDetail}
                />
              )}
              {match.path === routes.monitor_client_reports && (
                <Reports data={data} loading={true} />
              )}
              {match.path === routes.monitor_client_issues && (
                <Issues data={data} loading={true} />
              )}
              {match.path === routes.monitor_client_recommendation && (
                <Recommendations data={data} loading={true} />
              )}
              {match.path === routes.monitor_client_activities && (
                <Activities data={data} loading={true} />
              )}
              {match.path === routes.monitor_client_ratings && (
                <Ratings data={data} loading={true} />
              )}
              {match.path ===
                routes.monitor_client_recommended_product_tracking && (
                <TrackRecommendedProducts data={data} loading={true} />
              )}
              {match.path === routes.monitor_client_recommended_products && (
                <RecommendedProductDetails data={data} loading={true} />
              )}
              {match.path === routes.monitor_client_issue_detail && (
                <IssueDetails data={data} loading={true} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  careGiverClientInfo: state?.careGiverClient?.careGiverClientInfo,
});

export default connect(
  mapStateToProps,
  actions
)(withAlertAndDialog(withRouter(withTheme(withStyles(styles)(ClientInfo)))));
