import React from "react";
import AddEditForm from "../addEditForm";
import {
  formComponentTypes,
  alertProps,
  addressComponentTypes,
  // timezoneOld as timezone
} from "../../utils";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
// import ConflictList from "../../conflictList";
import PropTypes from "prop-types";
class AddressComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      add_location: "",
      add_location_details: null,
      [addressComponentTypes.country]: "",
      [addressComponentTypes.postalCode]: "",
    };
  }

  componentDidMount() {
    this.getActivityData();
    if (this.props?.editData) {
      this.setState({
        add_location: this.props?.editData?.add_location,
        add_location_details: {
          [addressComponentTypes.country]:
            this.props?.editData?.add_location?.address_components?.filter(
              (_) => _?.types?.includes("country")
            )?.[0]?.long_name ?? "",
          [addressComponentTypes.postalCode]:
            this.props?.editData?.add_location?.address_components?.filter(
              (_) => _?.types?.includes("postal_code")
            )?.[0]?.long_name ?? "",
        },
      });
    }
  }

  getActivityData = () => {
    this.props.GET_MASTER_ADDRESS_TYPE({});
  };

  onSaveButtonClicked = (isError, data, isUrl) => {
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      if (this.props?.editData) {
        data.id = this.props?.editData.id;
      }
      this.props.callBackOnSave(data);
    }
  };

  onCancelButtonClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  handleAutoAddressChange = (newValue, key) => {
    this.setState(
      {
        [key]: newValue,
      },
      () => {
        if (key === "add_location") {
          this.setState({
            add_location_details: {
              [addressComponentTypes.country]:
                newValue?.address_components?.filter((_) =>
                  _?.types?.includes("country")
                )?.[0]?.long_name ?? "",
              [addressComponentTypes.postalCode]:
                newValue?.address_components?.filter((_) =>
                  _?.types?.includes("postal_code")
                )?.[0]?.long_name ?? "",
            },
          });
        }
      }
    );
  };

  checkCountryPostalCodeDisability = () => {
    const { add_location } = this.state;
    if (typeof add_location === typeof "") {
      return false;
    } else {
      if (typeof add_location === "object" && add_location !== null) {
        if (typeof add_location?.address === typeof "") {
          return true;
        }
      }
    }
  };

  render() {
    let isEdit = this.props.isEdit;
    let list = this.props.list;
    let id = this.props?.id;
    let selectedList = [];
    let data = this.props?.editData;
    if (list && list.length > 0) {
      selectedList = list.map((val) => val.address_type.value);
    }
    if (isEdit) {
      if (data && selectedList.length > 0) {
        selectedList = selectedList.filter((val) => val !== data.value);
      }
    }

    const components = {
      section_1: {
        label: !isEdit ? "Add New Address" : "Edit Address",
        labelBreakPoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        fields: {
          address_type: {
            key: "address_type",
            type: formComponentTypes.SELECT_BOX,
            id: `selectAdditionalAddressTypeFrom${id ? id : ''}`,
            componentProps: {},
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "Address Type",
            value: !isEdit
              ? ""
              : this.props.addressType?.data?.filter(
                  (x) => x.value === data?.value
                )[0],
            options: this.props.addressType?.data
              ? selectedList.length > 0
                ? this.props.addressType?.data.filter(
                    (val) => selectedList.indexOf(val.value) === -1
                  )
                : this.props.addressType?.data
              : [],
            isMandatory: true,
          },
          googleMapLocationAutocomplete: {
            key: "add_location",
            type: formComponentTypes.GOOGLE_MAP_AUTOCOMPLETE,
            id: `selectAdditionalGoogleMapAutoCompleteFrom${id ? id : ''}`,
            // isDontShow: this.state.activityMode === "IN_PERSON" ? false : true,
            componentProps: {
              showLocationViaMaps: true,
            },
            onChangeCallBack: this.handleAutoAddressChange,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "SELECT ADDRESS",
            // value: !isEdit ? "" : {
            //   address: editData?.client_info?.address,
            //   latitude: editData?.client_info?.latitude,
            //   longitude: editData?.client_info?.longitude
            // },
            value: !isEdit ? "" : data?.add_location,
            isMandatory: true,
            // dependentKeys: ["addressLine1", "addressLine2", "area", "state", "country", "postalCode"],
            dependentKeys: Object.values(addressComponentTypes),
            // cleanUpKeys: ["to_timestamp"], //Current Change
            // isMulti: true, //Incoming Change
          },
          addressLine1: {
            key: addressComponentTypes.addressLine1,
            type: formComponentTypes.INPUT_BOX,
            id: `addAdditionalAddressLine1From${id ? id : ''}`,
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "ADDRESS LINE 1",
            value: !isEdit ? "" : data?.addressLine1,
            isMandatory: true,
          },
          addressLine2: {
            key: addressComponentTypes.addressLine2,
            type: formComponentTypes.INPUT_BOX,
            id: `addAdditionalAddressLine2From${id ? id : ''}`,
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "ADDRESS LINE 2",
            value: !isEdit ? "" : data?.addressLine2,
            // isMandatory: true,
          },
          area: {
            key: addressComponentTypes.area,
            type: formComponentTypes.INPUT_BOX,
            id: `addAdditionalAreaFrom${id ? id : ''}`,
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "CITY",
            value: !isEdit ? "" : data?.area,
            isMandatory: true,
          },
          state: {
            key: addressComponentTypes.state,
            type: formComponentTypes.INPUT_BOX,
            id: `addAdditionalStateFrom${id ? id : ''}`,
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "STATE",
            value: !isEdit ? "" : data?.state,
            isMandatory: true,
          },
          country: {
            key: addressComponentTypes.country,
            type: formComponentTypes.INPUT_BOX,
            id: `addAdditionalCountryFrom${id ? id : ''}`,
            componentProps: {
              type: "text",
            },
            disabled:
              this.checkCountryPostalCodeDisability() &&
              !!this.state.add_location_details?.[
                addressComponentTypes.country
              ],
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "COUNTRY",
            value: !isEdit ? "" : data?.country,
            isMandatory: true,
            onCallback: this.handleAutoAddressChange,
          },
          postalCode: {
            key: addressComponentTypes.postalCode,
            type: formComponentTypes.INPUT_BOX,
            id: `addAdditionalPostalCodeFrom${id ? id : ''}`,
            componentProps: {
              // type: "number",
            },
            limit: 10,
            disabled:
              this.checkCountryPostalCodeDisability() &&
              !!this.state.add_location_details?.[
                addressComponentTypes.postalCode
              ],
            //  &&
            // this.state[addressComponentTypes.postalCode]?.length > 10
            // disabled: true,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "POSTAL CODE",
            value: !isEdit ? "" : data?.postalCode,
            isMandatory: true,
            onCallback: this.handleAutoAddressChange,
          },
        },
      },
    };
    return (
      <>
        {isEdit && !this.props.addressType?.loading ? (
          <>
            <AddEditForm
              components={components}
              onSaveButtonClicked={this.onSaveButtonClicked}
              cancelForm={
                this.props.backHandler
                  ? this.props.backHandler
                  : this.onCancelButtonClicked
              }
              // loading={updateLoading}
              isUrl={true}
              saveButtonLabel={"Update"}
              activityEdit={isEdit}
              spacing={1}
              // sectionGutterBottom={16}
              saveButtonId= {`addSaveAddressButtonFrom${id ? id : ''}`}
              cancelButtonId= {`addCancelAddressButtonFrom${id ? id : ''}`}
              backNeeded={this.props.backNeeded}
              backHandler={this.props.backHandler}
            />
          </>
        ) : (
          <AddEditForm
            components={components}
            onSaveButtonClicked={this.onSaveButtonClicked}
            cancelForm={
              this.props.backHandler
                ? this.props.backHandler
                : this.onCancelButtonClicked
            }
            // loading={addLoading}
            saveButtonLabel={"Add Address"}
            withoutBoxShadow={true}
            spacing={1}
            // sectionGutterBottom={16}
            saveButtonId= {`addSaveAddressButtonFrom${id ? id : ''}`}
            cancelButtonId= {`addCancelAddressButtonFrom${id ? id : ''}`}
            backNeeded={this.props.backNeeded}
            backHandler={this.props.backHandler}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addressType: state?.masterSet?.addressType,
});

// export default AddEditAvailabiity
export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(AddressComponent));

AddressComponent.propTypes = {
  /**To persist State, after viewing all conflicts state and returning back to add/Edit activity screen. */
  persistState: PropTypes.bool,
  /**State to set while persisting. */
  parentState: PropTypes.object,
};
