import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || "#9297a5",
  }),
  cls2: (props) => ({
    fill: "none",
    stroke: props.color || "#9297a5",
    strokeMiterlimit: 10,
    strokeWidth: 2
  })
}))

export const SalesOperationNewIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon fontSize={'small'} >
      {/* <defs>
        <style>
          .cls-1{fill:#9297a5}
        </style>
      </defs> */}
      <g id="Sales_Operations" data-name="Sales Operations" transform="translate(-2 -2)">
        <path id="Path_553" d="M21.2 8.3L22 3l-5.3.8z" className={classes.cls1} data-name="Path 553" />
        <path id="Path_554" className={classes.cls2} d="M4 14.917L8 11l1.5 1.5 4.1-4.2 1.6 1.5 4.5-4.5M20 22V11m-4 11v-9m-4 9v-7m-4 7v-5m-4 5v-3" data-name="Path 554" />
        <path id="Path_555" d="M5.5 2A3.5 3.5 0 1 0 9 5.5 3.5 3.5 0 0 0 5.5 2zm.654 5.5h-1.36V5.007L4 5.314V4.277L5.9 3.5h.255v4z" className={classes.cls1} data-name="Path 555" />
      </g>
    </SvgIcon >
  )
}

SalesOperationNewIcon.propTypes = {
  color: PropTypes.string,
};