import React, { useState } from "react";
 import {
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Checkbox,
  TextField,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Grid,
  FormControl ,
  Select,
  MenuItem,
} from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import TextFieldsOutlinedIcon from '@material-ui/icons/TextFieldsOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CallIcon from '@material-ui/icons/Call';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {DrawerComponent} from '../drawer';
import Mobile  from '../mobile';
// import PropTypes from "prop-types";
 
 const useStyles = makeStyles((theme) => ({
   root: {
     '& .MuiTableCell-head':{
       backgroundColor:'#E5EAF4',
       padding:'5px 16px',
       minWidth:150,
     },
     '& .MuiSelect-select:focus':{
      background:'transparent'
    },
    '& .MuiFormControl-root':{
      width:'100%'
    }  
   },
   addColumn:{
     '& .MuiIconButton-root':{
       borderRadius:0,
       '&:hover':{
        backgroundColor:'transparent',
       }
     },
    },
    addColumnText: {
      color: theme.palette.ternary.main
    },
    addRowBtn:{
      minWidth:150,
      color: theme.palette.ternary.main
   },
    addRowBtnIcon:{
      color: theme.palette.ternary.main
   }
 }));
 const CustomTable = (props) => {
   const classes = useStyles();
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [selectIndex,setSelectIndex] = React.useState('');

   const [dropdownData,setDropdownData] = React.useState([]);

   const [drawerOpen,setDrawerOpen] = useState(false);

   const toggleDrawer = (val) =>{
     setDrawerOpen(val)
     if(!val){
       handleClose();
     }
   }

   const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
   };

   const openPopover = (event,index) =>{
    setSelectIndex(index)
    handleClick(event)
   }
 
   const handleClose = () => {
     setSelectIndex('')
     setAnchorEl(null);
   };
 
   const open = Boolean(anchorEl);
   const id = open ? "simple-popover" : undefined;
   
    const [toggleInput,setToggleInput] = useState({
      index:'',
      toggle:false
    });
    
    const [inputValue,setInputValue] = useState('');

    const uiComponentPopover=[
      {component_name:'Checkbox',ui_component:'check_box',icon:'CheckBoxOutlinedIcon'},
      {component_name:'Input Text',ui_component:'input_text',icon:'TextFieldsOutlinedIcon'},
      {component_name:'Date',ui_component:'date',icon:'CalendarTodayOutlinedIcon'},
      {component_name:'Dropdown',ui_component:'dropdown',icon:'ArrowDropDownIcon'},
      {component_name:'Mobile',ui_component:'mobile',icon:'CallIcon'},
    ]

    const giveMeIcon = (icon) =>{
      switch(icon){
        case 'CheckBoxOutlinedIcon':
          return <CheckBoxOutlinedIcon />
        case 'TextFieldsOutlinedIcon':
          return <TextFieldsOutlinedIcon />
        case 'CalendarTodayOutlinedIcon':
          return <CalendarTodayOutlinedIcon />
        case 'ArrowDropDownIcon':
          return <ArrowDropDownIcon />
        case 'CallIcon':
          return <CallIcon />
          default:
            return <CheckBoxOutlinedIcon />
      }
    }

    const addDropdownValues = (value) =>{
      setDropdownData(value)
    }
 
    const addColumn = (col) =>{

      //delete the validation key because of changing column type
      if(selectIndex && selectIndex>=0){
        props.data.tableScheme.row.filter(l=> {
          if(l?.validations?.hasOwnProperty(selectIndex)){
            let deleteObj = {...l.validations}
            delete deleteObj[selectIndex]
            l.validations=deleteObj
          }
          return l
        })
      }

      if(col?.component_name === 'Dropdown'){
        toggleDrawer(true)
        setDropdownData(props?.data?.tableScheme.column[selectIndex]?.options ?? [])
      }
      else{
      if(selectIndex!=='' && selectIndex >= 0){
        if(col?.component_name !== 'Dropdown'){
          let deleteOption = props?.data?.tableScheme.column[selectIndex];
          delete deleteOption['options'];
          Object.assign(deleteOption, col)  
        }
        else{
          Object.assign(props?.data?.tableScheme.column[selectIndex], col)
        }
      }
      else{
        props.data.tableScheme.column.push({
          name:'Column Type',
          ...col
        })
      }
      handleClose();
      props.onChangeComponents(props.data?.tableScheme);
    }
    } 

    const addDropdownOptions = {
      component_name:'Dropdown',
      ui_component:'dropdown',
      icon:'ArrowDropDownIcon',
      options:dropdownData
    }

    const saveFunc = () =>{
      if(selectIndex!=='' && selectIndex >= 0){
        Object.assign(props?.data?.tableScheme.column[selectIndex], addDropdownOptions)
      }
      else{
      props.data.tableScheme.column.push({
        name:'Column Type',
       ...addDropdownOptions
      })
    }
      props.onChangeComponents(props?.data?.tableScheme);
      handleClose();
      toggleDrawer(false);
    }

    const deleteColumn = () =>{
      const filteredData = props?.data?.tableScheme?.column.filter((l,i)=>i!==selectIndex)
      props.data.tableScheme.column=filteredData;
      handleClose()
      props.onChangeComponents(props?.data?.tableScheme);
    }

    const addRow = () =>{
      props.data.tableScheme.row.push({
        name:'Row Type'
      })
      props.onChangeComponents(props?.data?.tableScheme);
    }

    const deleteRow = (index) =>{
      const filteredData = props?.data?.tableScheme?.row?.filter((l,i)=>i!==index)
      props.data.tableScheme.row=filteredData;
      props.onChangeComponents(props?.data?.tableScheme);
    }

    const clickColumnName = (columnData,index) =>{
      setToggleInput({
        toggle:true,
        index
      })
      setInputValue(columnData.name)
    }

    const onChangeColumnName = (value,index) =>{
      setInputValue(value);
      props.data.tableScheme.column[index].name=value
      props.onChangeComponents(props?.data?.tableScheme);
    }

    const blur = () =>{
      setToggleInput({
        ...toggleInput,toggle:false,
      });
      setInputValue('');
    }

   return (
     <div className={classes.root}>
          <TableContainer>
            <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell variant="head" style={{width:20,minWidth:20}}>
                          <Typography variant="body1">
                              Actions
                          </Typography>
                    </TableCell>
                    {
                      props?.data?.tableScheme?.column && props?.data?.tableScheme?.column?.map((colData,index)=>{
                        return(
                          <TableCell key={index} variant="head">
                            <div style={{display:'flex',alignItems:'center'}}>
                              <Typography variant="body1" onClick={()=>clickColumnName(colData,index)}>
                                  {toggleInput.toggle && toggleInput.index===index  ? (
                                    <TextField 
                                    autoFocus
                                    value={inputValue}
                                    onChange={(e) => onChangeColumnName(e.target.value,index)}
                                    onBlur={blur}
                                />
                                  ) : colData.name}
                              </Typography>
                              <IconButton size="small" aria-describedby={id} onClick={(event)=>openPopover(event,index)}>
                                    <ExpandMoreOutlinedIcon />
                              </IconButton>
                              </div>
                          </TableCell>
                        )
                      })
                    }
                    <TableCell>
                  <Button variant="text" className={classes.addColumn}>
                      <IconButton aria-describedby={id} onClick={handleClick} disableTouchRipple disableFocusRipple disableRipple>
                      <Typography className={classes.addColumnText}>Add Column &nbsp;</Typography>
                        <AddCircleOutlineOutlinedIcon className={classes.addColumnText} />
                      </IconButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <List>
                          {
                            uiComponentPopover && uiComponentPopover.map(l=>{
                              return(
                                <ListItem button onClick={()=>addColumn(l)}>
                                <ListItemIcon>
                                  {giveMeIcon(l.icon)}
                                </ListItemIcon>
                                <ListItemText primary={l.component_name} />
                              </ListItem>
                              )
                            })
                          }
                          {
                            selectIndex && parseInt(selectIndex) > 0  ?(
                            <ListItem button onClick={()=>deleteColumn()}>
                              <ListItemIcon>
                                <DeleteOutlineOutlinedIcon/>
                              </ListItemIcon>
                              <ListItemText primary={'Delete Column'} />
                            </ListItem>):null
                          }
                        </List>
                </Popover>
                </Button>
                </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {
                props?.data?.tableScheme?.row && props?.data?.tableScheme?.row?.map((rowData,i1)=>{
                  return(
                    <TableRow key={i1}>
                        <TableCell>
                          <IconButton onClick={()=>deleteRow(i1)}>
                              <DeleteOutlineIcon />
                          </IconButton> 
                        </TableCell>
                      {
                        props?.data?.tableScheme?.column && props?.data?.tableScheme?.column?.map((colData,i2)=>{
                          switch (colData.ui_component) {
                            case 'check_box':
                              return(
                                  <TableCell aria-disabled  key={i2}>
                                      <Checkbox 
                                          // checked={false}
                                          disabled
                                          // onChange={handleChange}
                                          inputProps={{ 'aria-label': 'primary checkbox' }}
                                          color="primary"
                                      />
                                  </TableCell>
                              )
                              case 'input_text':
                              return(
                                <TableCell aria-disabled  key={i2}>
                                    <TextField 
                                     disabled
                                        // value={rowData[l][d].value}
                                        // onChange={(value) => onChange(index, data, value)}
                                        // label={rowData[l][d].label}
                                    />
                                </TableCell>
                              )
                              case 'date':
                              return(
                                <TableCell key={i2}>
                                   <form noValidate>
                                      <TextField
                                       disabled
                                        id="date"
                                        type="date"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </form>
                                </TableCell>
                              )
                              case 'dropdown':
                              return(
                                <TableCell key={i2}>
                                   <FormControl>
                                    <Select
                                     disabled
                                      // id="demo-dropdown-select"
                                      // value={colData?.component_name ?? 'Input Text'}
                                      // onChange={(e)=>changeDropDownColumn(e,colData,index)}
                                    >
                                    {
                                      colData?.options && colData?.options.map(l=>{
                                        return(
                                          <MenuItem value={l}>{l}</MenuItem>
                                        )
                                      })
                                    }
                                  </Select>
                                </FormControl>
                                </TableCell>
                              )
                              case 'mobile':
                                return(
                                  <TableCell key={i2} style={{minWidth:300}}>
                                     <Mobile
                                      disabled
                                        // value={value}
                                        // label={data?.question}
                                        // type={"number"}
                                        />
                                  </TableCell>
                                )
                              default:
                                return(
                                  <TableCell key={i2}>
                                      <TextField 
                                       disabled
                                        // value={rowData[l][d].value}
                                        // onChange={(value) => onChange(index, data, value)}
                                        // label={rowData[l][d].label}
                                    />
                                  </TableCell>
                              )
                          }
                        })
                      }
                    </TableRow>
                  )
                }) 
              }
                <Button variant="text" className={classes.addRowBtn} onClick={addRow}>
                  <Typography className={classes.addRowBtnIcon}>Add Row</Typography>
                  <IconButton>
                    <AddCircleOutlineOutlinedIcon className={classes.addRowBtnIcon} />
                  </IconButton>
              </Button>
            </TableBody>
            </Table>
          </TableContainer>
          {
            drawerOpen &&
          <DrawerComponent open={drawerOpen} onClose={()=>toggleDrawer(false)}>
            <Grid container style={{padding:16}}>
              <Grid item xs={12}>
                <Autocomplete
                    multiple
                    id="dropdown-tags-outlined"
                    size={"small"}
                    options={[]}
                    value={dropdownData}
                    freeSolo
                    onChange={(e, newValue) => addDropdownValues(newValue)}
                    renderTags={(value, getTagProps) =>
                      value?.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Options" placeholder="Options"
                            // error={val?.error?.optionError ?? false}
                            // helperText={val?.error?.optionError ? "Please add minimum two options required" : ""}
                        />
                    )}
                />
                 <Button color="primary" variant="contained" style={{marginTop:16}} onClick={saveFunc}>Save</Button>
              </Grid>
          </Grid>
          </DrawerComponent>
        }
     </div>
   );
 };
//  CustomTable.propTypes = {
 
//  };
 export default CustomTable;
