import React from "react";
import {
  Grid,
  withStyles,
  Card,
  Divider,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";
import {
  Header,
  ClientDetail,
  ICONS,
  SimpleListWithIconText,
  InformationDetailCard,
  CardWithText,
  CardWithSubText,
  ListWithDivider,
  ListWithBullets,
  HybridTypography,
  // CardTextWithList,
} from "../../../../components";
import { customMobileNumber, formComponentTypes, OTHER_ROLES, isCreatedByManagedCareAdminById, user_roles } from "../../../../utils";
import { TextWithIcon } from "../../../../components/enquiryDetails/cardedTextView/textWithIcon";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routes } from "../../../../router/routes";
import {
  bmiCalculation,
  capitalizeFirstLetter,
  fontStyleClass,
  options,
  customAddress,
  customDateConverter,
} from "../../../../utils";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { adlMapper } from "./mapper";
// import ViewCardAddressEmailPhone from "../../../../components/viewAddressEmailPhone/viewCard";
import ViewEnquiryAddress from "../../../../components/viewAddressEmailPhone/viewEnquiry";
import ViewAddressEmailPhone from "../../../../components/viewAddressEmailPhone";
const styles = (theme) => ({
  root: {
    borderRadius: 8,

    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.04)",
    // background: "white",
    // background: '-webkit-linear-gradient(180deg, #fdcd3b 60%, #ffed4b 60%)',
    // background: '-o-linear-gradient(180deg, #fdcd3b 60%, #ffed4b 60%)',
    // background: '-moz-linear-gradient(180deg, #fdcd3b 60%, #ffed4b 60%)',
    // background: `linear-gradient(180deg, ${theme.palette.primary.main} 20.5%, #fff 20%)`,
    background: `linear-gradient(180deg, ${theme.palette.primary.main} 300px, #fff 300px)`,
    // height:"76rem",
    height: "100%",
    // overflow: "hidden",
  },
  withBackgroundDiv: {
    // background: theme.palette.primary.main,
    // height: 390,
    // padding: 20,
  },
  card: {
    // minWidth: 275,
    // height: 300,
    boxShadow: "0 25px 50px 0 rgba(0, 0, 0, 0.08)",
    border: "1px solid #e9e9e9",
    borderRadius: 28,
    marginBottom: 26,
  },
  clientDetailsGrid: {
    marginTop: 30,
    marginBottom: 10,
  },
  divider: {
    backgroundColor: theme.palette.secondary.light,
    margin: "0px 60px",
  },
  dividerHeader: {
    backgroundColor: theme.palette.secondary.light,
    margin: "0px 60px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 30px 0px 0px",
    },
  },
  subHeader: {
    color: "white",
    marginBottom: 10,
  },
  ...fontStyleClass,
});

class ViewEnquiry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getDetail();
  }

  getDetail = () => {
    const id = this.props?.match?.params?.id;
    this.props.GET_ENQUIRY_INFO({ enquiry_id: id });
  };

  handleIcon = (iconName) => {
    switch (iconName) {
      // Previously used color: #28a9c1
      case "handleIcon":
        return ICONS.CALL("#ffffff");
      case "address":
        return ICONS.LOCATION("#ffffff");
      case "address1":
        return ICONS.CURRENTLOCATION("#ffffff");
      case "email":
        return ICONS.EMAIL("#ffffff");
      default:
        return ICONS.CALL("#ffffff");
    }
  };
  handleServiceProfessionalsViewAllClick = (data, type, label) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewAddressEmailPhone
          data={data ?? []}
          drawer={this.props.drawer}
          onEndIconClickHandler={this.onClickIcon}
          type={type}
          label={label}
        />
      ),
    });
  };
  render() {
    const { classes } = this.props;
    let { data, loading, error } = this.props?.enquiryList;

    const isBusinessAdmin = this.props?.auth_data?.data?.user_role === user_roles.business_admin
    const isMCAdmin = this.props?.auth_data?.data?.other_roles?.includes(OTHER_ROLES.crossBundling)
    const isCreatedByMC = isCreatedByManagedCareAdminById(data?.createdBy, this.props?.auth_data?.data);

    console.log(data)

    const viewDetailsEditDetails = (data) => {
      console.log(data)
      if (data) {
        let section1 = {
          section_1: {
            label: "Call Information",
            fields: {
              received_by: {
                key: "received_by",
                label: "RECEIVED BY",
                value: data?.call_received?.label
                  ? capitalizeFirstLetter(data?.call_received?.label)
                  : <Typography color="textSecondary">Unassigned</Typography>,
              },
              assigned_to: {
                key: "assigned_to",
                label: "ASSIGNED TO",
                value: data?.assigned_To?.label
                  ? capitalizeFirstLetter(data?.assigned_To?.label)
                  : <Typography color="textSecondary">Unassigned</Typography>,
              },
            },
          },
        };
        let section2 = {
          section_2: {
            label: "Caller Information",
            subHeader:
              data?.caller_information &&
              Object.values(data.caller_information)?.length > 0
                ? "* Caller and contact are not the same"
                : "",
            fields: {
              caller_name: {
                key: "caller_name",
                label: "NAME",
                value: data?.caller_information?.name
                  ? `${
                      data?.callerSname?.[0] + "." ?? ""
                    } ${capitalizeFirstLetter(data.caller_information.name)}`
                  : "N/A",
              },
              gender: {
                key: "gender",
                label: "GENDER",
                value: data?.caller_information?.gender
                  ? capitalizeFirstLetter(data?.caller_information?.gender)
                  : "N/A",
              },
              dob: {
                key: "dob",
                label: "DOB",
                value: data?.caller_information?.date_of_birth
                  ? customDateConverter(
                      new Date(data.caller_information.date_of_birth)
                    )
                  : "N/A",
              },
              mobile: {
                key: "mobile",
                label: "MOBILE",
                value: data?.caller_information?.mobNo
                  ? `${data.caller_information.mobNo}`
                  : "N/A",
              },

              address: {
                key: "address",
                label: "ADDRESS",
                keydata: formComponentTypes.ADD_ADDRESS,
                dataList: data?.caller_information?.address,
                functions: this.handleServiceProfessionalsViewAllClick,
                value:
                  data?.caller_information?.addressLine1 &&
                  data?.caller_information?.addressLine2 &&
                  data?.caller_information?.area &&
                  data?.caller_information?.state &&
                  data?.caller_information?.country &&
                  data?.caller_information?.postalCode
                    ? capitalizeFirstLetter(
                        customAddress(
                          data?.caller_information?.addressLine1,
                          data?.caller_information?.addressLine2,
                          data?.caller_information?.area,
                          data?.caller_information?.state,
                          data?.caller_information?.country,
                          data?.caller_information?.postalCode
                        )
                      )
                    : "N/A",
              },
              email: {
                key: "email",
                label: "EMAIL ADDRESS",
                value: data?.caller_information?.email
                  ? capitalizeFirstLetter(data.caller_information.email)
                  : "N/A",
              },
              id:{
                key: "ID",
                label: "ID",
                keydata: formComponentTypes.ADD_ID_TYPE,
                dataList: data?.caller_information?.idTypes,
                functions: this.handleServiceProfessionalsViewAllClick,
                value: data?.caller_information?.idTypes ? data?.caller_information?.idTypes : ""
              },
              otherPhoneNumber:{
                key: "Other Contact",
                label: "Other Contact",
                keydata: formComponentTypes.ADD_PHONE_NUMBER,
                dataList: data?.caller_information?.otherPhoneNumbers,
                functions: this.handleServiceProfessionalsViewAllClick,
                value: data?.caller_information?.otherPhoneNumbers ? customMobileNumber(data?.caller_information?.otherPhoneNumbers?.mobileNoIsdCode, data?.caller_information?.otherPhoneNumbers?.mobileNo)  : ""
              },
              relationship: {
                key: "relationship",
                label: "RELATIONSHIP",
                value: data?.relationship ? data.relationship : "N/A",
              },
            },
          },
        };

        if (
          data?.contact_information &&
          Object.keys(data.contact_information)?.length > 0
        ) {
          return {
            ...section1,
            ...section2,
          };
        } else {
          return { ...section1 };
        }
      }
    };

    const handlePrint = () => {
      var printContents = document.getElementById("enquiry").innerHTML;

      var a = window.open("", "", "resizable=yes,height=500, width=500");
      a.document.write("<html>");
      a.document.write("<body > <h1>Div contents are <br>");
      a.document.write(printContents);
      a.document.write("</body></html>");
      a.document.close();
      a.print();
    };
    const handleDownload = () => {
      alert("download");
    };

    const handleRedirectConvertAsClient = (id, exitClient) => {
      if (exitClient) {
        this.props.history.push({
          pathname: routes.manage_clients + "/view/" + id,
        });
      } else {
        this.props.history.push({
          pathname: routes.manage_clients + "/edit/" + id,
        });
      }
    };
    return (
      <div style={{ padding: 20 }}>
        {loading && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <CircularProgress />
            </Grid>
            <Grid item>
              <Typography variant="h6" align="center">
                Loading the Enquiry detail
              </Typography>
            </Grid>
          </Grid>
        )}
        {!loading && error && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <Typography variant="h6" align="center">
                Something went wrong!
              </Typography>
            </Grid>
            <Grid item>
              <Button
                style={{ marginTop: 16, textAlign: "center" }}
                variant={"outlined"}
                onClick={this.getDetail}
              >
                Try Again
              </Button>
            </Grid>
          </Grid>
        )}
        {!loading && !error && !data && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: `30vh` }}
          >
            <Grid item>
              <Typography variant="h6" align="center">
                404 - Enquiry detail not found
              </Typography>
            </Grid>
          </Grid>
        )}
        {!loading && !error && data && (
          <Grid container className={classes.root} id="enquiry">
            <Grid item xs={12} className={classes.withBackgroundDiv}>
              <div style={{ width: "100%", padding: 20 }}>
                <Grid item xs={12}>
                  <Header
                    data={data}
                    handlePrint={handlePrint}
                    handleDownload={handleDownload}
                    handleRedirect={handleRedirectConvertAsClient}
                    editkey={data.enquiryId}
                    enquiry_id={data.enquiryId}
                    showButtons={isBusinessAdmin ? false : isMCAdmin ? (isCreatedByMC ? true : false) : true}
                    editButtonId={`EditButtonIdFromEnquiryDetails${data.enquiryId}`}
                    ConvertAsClientButtonId = {`ConvertAsClientButtonIdFromEnquiryDetails${data.enquiryId}`}
                    handleRedirectButtonId = {`HandleRedirectButtonIdFromEnquiryDetails${data.enquiryId}`}
                  />
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.clientDetailsGrid}
                >
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <ClientDetail data={data} />
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    className={classes.dividerHeader}
                  />
                  <Grid item xs={12} sm={5} md={4} lg={4}>
                    {data?.contact_information?.mobNo ? (
                      <SimpleListWithIconText
                        text={
                          data?.contact_information?.mobNo
                            ? `${data?.contact_information?.mobNo}`
                            : "Not Updated"
                        }
                        icon={this.handleIcon("handleIcon")}
                      />
                    ) : (
                      <SimpleListWithIconText
                        text={
                          data?.caller_information?.mobNo
                            ? `${data?.caller_information?.mobNo}`
                            : "Not Updated"
                        }
                        icon={this.handleIcon("handleIcon")}
                      />
                    )}
                    {data?.contact_information?.email ? (
                      <SimpleListWithIconText
                        text={
                          data?.contact_information.email
                            ? data?.contact_information.email
                            : "Not Updated"
                        }
                        icon={this.handleIcon("email")}
                      />
                    ) : (
                      <SimpleListWithIconText
                        text={
                          data?.caller_information?.email
                            ? data?.caller_information?.email
                            : "Not Updated"
                        }
                        icon={this.handleIcon("email")}
                      />
                    )}
                    {/* {data?.contact_information?.addressLine1 && data?.contact_information?.addressLine2 && data?.contact_information?.area && data?.contact_information?.state && data?.contact_information?.country && data?.contact_information?.postalCode ? (
                      <SimpleListWithIconText
                        text={data?.contact_information?.addressLine1 && data?.contact_information?.addressLine2 && data?.contact_information?.area && data?.contact_information?.state && data?.contact_information?.country && data?.contact_information?.postalCode ? capitalizeFirstLetter(customAddress(data?.contact_information?.addressLine1, data?.contact_information?.addressLine2, data?.contact_information?.area, data?.contact_information?.state, data?.contact_information?.country, data?.contact_information?.postalCode)) : "N/A"}
                        icon={this.handleIcon("address")}
                      />
                    ) : (
                        <SimpleListWithIconText
                          text={data?.caller_information?.addressLine1 && data?.caller_information?.addressLine2 && data?.caller_information?.area && data?.caller_information?.state && data?.caller_information?.country && data?.caller_information?.postalCode ? capitalizeFirstLetter(customAddress(data?.caller_information?.addressLine1, data?.caller_information?.addressLine2, data?.caller_information?.area, data?.caller_information?.state, data?.caller_information?.country, data?.caller_information?.postalCode)) : "N/A"}
                          icon={this.handleIcon("address")}
                        />
                      )} */}
                    {!data.contact_information && data?.caller_information && (
                      <ViewEnquiryAddress
                        title={data?.caller_information?.address[0]?.type}
                        height={16}
                        width={16}
                        style={{ position: "relative", top: 2 }}
                        data={data?.caller_information?.address}
                        emptyMessage="Not yet added"
                        element={formComponentTypes.ADD_ADDRESS}
                        // label={"Address"}
                        nolabel={true}
                        nolabelColor={"#FFB638"}
                        onButtonClickHandler={() =>
                          this.handleServiceProfessionalsViewAllClick(
                            data?.caller_information?.address,
                            formComponentTypes.ADD_ADDRESS,
                            "Address"
                          )
                        }
                      />
                    )}
                    {data.contact_information && (
                      <ViewEnquiryAddress
                        title={data?.contact_information?.address[0]?.type}
                        height={16}
                        width={16}
                        style={{ position: "relative", top: 2 }}
                        data={data?.contact_information?.address}
                        emptyMessage="Not yet added"
                        element={formComponentTypes.ADD_ADDRESS}
                        // label={"Address"}
                        nolabel={true}
                        onButtonClickHandler={() =>
                          this.handleServiceProfessionalsViewAllClick(
                            data?.contact_information?.address,
                            formComponentTypes.ADD_ADDRESS,
                            "Address"
                          )
                        }
                      />
                    )}
                    {/* {data.contact_information?.idTypes?.length > 0 && (
                      <ViewEnquiryAddress
                        title={data?.contact_information?.idTypes?.[0]?.type}
                        height={16}
                        width={16}
                        style={{ position: "relative", top: 2 }}
                        data={data?.contact_information?.idTypes}
                        emptyMessage="Not yet added"
                        element={formComponentTypes.ADD_ID_TYPE}
                        // label={"Address"}
                        nolabel={true}
                        onButtonClickHandler={() =>
                          this.handleServiceProfessionalsViewAllClick(
                            data?.contact_information?.idTypes,
                            formComponentTypes.ADD_ID_TYPE,
                            "ID"
                          )
                        }
                      />
                    )} */}
                    {/* {data?.contact_information?.currentAddressLine1 && data?.contact_information?.currentAddressLine2 && data?.contact_information?.currentArea && data?.contact_information?.currentState && data?.contact_information?.currentCountry && data?.contact_information?.currentPostalCode ? (
                      <SimpleListWithIconText
                        text={data?.contact_information?.currentAddressLine1 && data?.contact_information?.currentAddressLine2 && data?.contact_information?.currentArea && data?.contact_information?.currentState && data?.contact_information?.currentCountry && data?.contact_information?.currentPostalCode ? capitalizeFirstLetter(customAddress(data?.contact_information?.currentAddressLine1, data?.contact_information?.currentAddressLine2, data?.contact_information?.currentArea, data?.contact_information?.currentState, data?.contact_information?.currentCountry, data?.contact_information?.currentPostalCode)) : "N/A"}
                        icon={this.handleIcon("address1")}
                      />
                    ) : (
                        <SimpleListWithIconText
                          text={data?.caller_information?.currentAddressLine1 && data?.caller_information?.currentAddressLine2 && data?.caller_information?.currentArea && data?.caller_information?.currentState && data?.caller_information?.currentCountry && data?.caller_information?.currentPostalCode ? capitalizeFirstLetter(customAddress(data?.caller_information?.currentAddressLine1, data?.caller_information?.currentAddressLine2, data?.caller_information?.currentArea, data?.caller_information?.currentState, data?.caller_information?.currentCountry, data?.caller_information?.currentPostalCode)) : "N/A"}
                          icon={this.handleIcon("address1")}
                        />
                      )} */}
                  </Grid>

                  <Grid 
                    container
                    direction="column-reverse"
                    justifyContent="flex-end"
                    alignItems="flex-end" 
                    item 
                    xs={12} 
                    sm={2} 
                    md={2} 
                    lg={2}>
                    <div>
                      <Typography variant="caption" component="div" className={classes.subHeader} >{`Source Type: ${data?.sourceType ? data?.sourceType?.toString()?.toUpperCase() : "PORTAL"}`}</Typography>
                      {data?.ticket_id ? <Typography variant="caption" component="div" className={classes.subHeader} >{`Ticket ID: ${data?.ticket_id ?? data?.ticket_id }`}</Typography> : null}
                      {data?.refPartner ? <Typography variant="caption" component="div" className={classes.subHeader} >{`Referred By: ${data?.refPartner ?? data?.refPartner }`}</Typography> : null}
                    </div>                   
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <InformationDetailCard
                      data={viewDetailsEditDetails(data)}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  {/* <CardWithText
                    title="Chief Complaint"
                    value={data?.complain ? data.complain : "N/A"}
                  /> */}
                  <HybridTypography
                    // icon={ICONS.OFFERING("#28a9c1")}
                    // loading={loading}
                    text={data?.complain ? data.complain : "Not Updated"}
                    header="Chief Complaint"
                    isnogrid={true}
                    bigFont={true}
                    // islisttop={true}
                    isdangerouslySetInnerHTML={true}
                  />
                  <HybridTypography
                    // icon={ICONS.OFFERING("#28a9c1")}
                    // loading={loading}
                    text={data?.medicalHistory ? data.medicalHistory : "Not Updated"}
                    header="Medical History"
                    isnogrid={true}
                    bigFont={true}
                    // islisttop={true}
                    isdangerouslySetInnerHTML={true}
                  />
                  {/* <CardWithText
                    title="Medical History"
                    value={data?.medicalHistory ? data.medicalHistory : "N/A"}
                  /> */}
                  <HybridTypography
                    text={
                      data?.contact_information?.occupation ?
                      data?.caller_information?.occupation : "Not Updated"
                    }
                    header="Occupation"
                    isnogrid={true}
                    isbigFont={true}
                    bigFont={true}
                    isdangerouslySetInnerHTML={true}
                  />
                  {/* <HybridTypography
                    text={data?.bloodGroup?.label}
                    header="Blood Group"
                    isnogrid={true}
                    bigFont={true}
                    isbigFont={true}
                    isdangerouslySetInnerHTML={true}
                  /> */}
                </Grid>
              </div>
              <Divider />
              <Grid container item xs={12} style={{ padding: 20 }}>
                <Grid item xs={12} sm={5}>
                  <CardWithSubText
                    title="Seen a doctor?"
                    value={
                      data?.seen_a_doctor_remarks
                        ? JSON.parse(data?.seen_a_doctor_remarks)
                            ?.constructor === Object
                          ? JSON.parse(data.seen_a_doctor_remarks).label
                          : data.seen_a_doctor_remarks
                        : "Not Updated"
                    }
                    subText={
                      data?.seenDoctor
                        ? capitalizeFirstLetter(data?.seenDoctor)
                        : "Not Updated"
                    }
                  />
                </Grid>

                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
                <Grid item xs={12} sm={5}>
                  <TextWithIcon
                    title="Height/Weight (CM/KG)"
                    list={
                      data?.weight && data?.height
                        ? [
                            { value: "height", label: data.height },
                            { value: "weight", label: data.weight },
                            {
                              value: "bmi",
                              label: bmiCalculation(data.height, data.weight),
                            },
                          ]
                        : data?.weight
                        ? [
                            { value: "height", label: "Not Updated" },
                            { value: "weight", label: data.weight },
                            { value: "bmi", label: "Not Updated" },
                          ]
                        : data?.height
                        ? [
                            { value: "height", label: data.height },
                            { value: "weight", label: "Not Updated"},
                            { value: "bmi", label: "Not Updated" },
                          ]
                        : [
                            { value: "height", label: "Not Updated" },
                            { value: "weight", label: "Not Updated" },
                            { value: "bmi", label: "Not Updated" },
                          ]
                    }
                  />
                  <HybridTypography
                    text={data?.bloodGroup?.label ? data?.bloodGroup?.label : "Not Updated"}
                    header="Blood Group"
                    isnogrid={true}
                    bigFont={true}
                    isbigFont={true}
                    isdangerouslySetInnerHTML={true}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container item xs={12} style={{ padding: 20 }}>
                <Grid item xs={12} sm={5}>
                  <ListWithDivider
                    title="Activities of Daily Living (ADL)"
                    listData={
                      data?.adl && Object.keys(JSON.parse(data.adl)).length > 0
                        ? Object.keys(JSON.parse(data.adl)).map((val) => {
                            return {
                              title: adlMapper?.[val] ?? val,
                              value:
                                options?.[val]?.filter(
                                  (_) => _.value === JSON.parse(data.adl)[val]
                                )?.[0]?.label ?? JSON.parse(data.adl)[val],
                            };
                          })
                        : []
                    }
                  />
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
                <Grid item xs={12} sm={5}>
                  {data.activities &&
                    (data?.activities.split(",")?.length <= 1
                      ? data?.activities.split(",").filter((_) => _ !== "other")
                          .length > 0
                      : data.activities.split(",").length > 0) && (
                      <ListWithBullets
                        title="Other Issues"
                        listData={
                          //eslint-disable-next-line
                          data?.activities?.length > 0
                            ? options.other_activities
                                .filter((val) => val.value !== "other")
                                .filter(
                                  (_) => data.activities.indexOf(_.value) > -1
                                )
                                .map((_) => _.label)
                            : []
                        }
                      />
                    )}
                  {data?.other_activities && (
                    <CardWithText
                      title="Other Issues / requests"
                      value={
                        data?.other_activities ? data.other_activities : []
                      }
                    />
                  )}
                </Grid>
              </Grid>
              <Divider />
              <Grid container item xs={12} style={{ padding: 20 }}>
                <Grid item xs={12} sm={5}>
                  <Typography
                    variant="h6"
                    style={{ marginBottom: 20 }}
                    className={classes.mediumFont}
                  >
                    Source of Referral
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{ padding: "10px 10px 10px 0px" }}
                  >
                    {data?.source_of_referreal?.label
                      ? data.source_of_referreal.label
                      : "Not Updated"}
                  </Typography>
                </Grid>

                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
                <Grid item xs={12} sm={5}>
                  {/* <CardWithText title="Remarks" value={data.remarks} /> */}
                  <HybridTypography
                    // icon={ICONS.OFFERING("#28a9c1")}
                    // loading={loading}
                    text={data?.remarks ? data.remarks : "Not Updated"}
                    header="Remarks"
                    isnogrid={true}
                    bigFont={true}
                    // islisttop={true}
                    isdangerouslySetInnerHTML={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  enquiryList: state.enquiry_management?.enquiry_details,
  auth_data: state?.authState?.loginAuth,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(ViewEnquiry))));
