import React from 'react';
import { makeStyles, Grid, Divider, Button, Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Avatarhead from './Avatarhead';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 16,
        borderRadius: 8,
        maxWidth: "100%",
        boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        fontSize: 15,
        color: "#9297a5",
    },
    button: {
        width: 130
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    detailSection: {
        display: 'flex'
    },
}))

const CardList = (props) => {

    const classes = useStyles();

    return <div className={classes.root}>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2} >
            <Grid item className={classes.detailSection} xs={12} sm={12} md={4} lg={8} xl={4} >
                <Avatarhead report_name={props.data.report_name}/>
            </Grid>
            <Grid item className={classes.detailSection} xs={12} sm={12} md={4} lg={2} xl={4} alignItems="center" justify="center" >
                <Typography className={classes.title} variant="body1" align="center">
                    {props.data.reported_at ? props.data.reported_at : 'N/A'}
                </Typography>
            </Grid>
            <Grid item container xs={12} sm={12} md={4} lg={2} xl={4} justify={"center"} alignItems={"center"}>
                <Divider orientation="vertical" style={{ height: 40, margin: "auto" }} />
                <Grid item>
                    <Button className={classes.button} variant="outlined" color="primary">
                        view
                    </Button>
                </Grid>
                <Fab className={classes.fab} color="primary" aria-label="add">
                    <img src={"/svg/Analytics.svg"} alt='analytic'></img>
                </Fab>
            </Grid>
        </Grid>


    </div>
}


export default CardList;