import { Avatar, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: props => props.isSelected ? theme.palette.ternary.main : theme.palette.secondary.light,
        color: props => props.isSelected ? 'white' : 'black',
        marginTop: 8,
        textAlign: "center",
        // left: 42
    },
    date: {
        marginTop: 8,
        marginBottom: 8,
        textAlign: "center",
        color: props => props.isSelected ? 'black' : theme.palette.secondary.main
    },
    divider: {
        height: 2,
        textAlign: "center",
        backgroundColor: theme.palette.primary.main
    }
}))

const Day = (props) => {

    const classes = useStyles(props);

    const {
        label,
        value,
        isSelected
    } = props;

    return <div>
        <Grid container direction="column" justify="center" alignItems="center">
            <Grid item>
                <Avatar className={classes.avatar}>{value}</Avatar>
            </Grid>
            <Grid item>
                <Typography variant="body2" className={classes.date}>{label}</Typography>
            </Grid>
        </Grid>
        {isSelected && <Divider variant={"fullWidth"} orientation={"horizontal"} className={classes.divider} />}
    </div>
}

Day.propTypes = {
    day: PropTypes.string,
    date: PropTypes.string,
    isSelected: PropTypes.bool
}

export default Day;