import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function CreateIconV2(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.424 5a2.57 2.57 0 00-1.824.752l-8.157 8.157a1.29 1.29 0 00-.328.559l-1.094 3.828a.553.553 0 00.683.683l3.83-1.089c.21-.06.402-.173.557-.328L18.248 9.4A2.576 2.576 0 0016.424 5zm0 1.1a1.468 1.468 0 011.042.435 1.465 1.465 0 010 2.084l-.715.715-2.084-2.086.715-.715a1.469 1.469 0 011.042-.434V6.1zm-2.538 1.93l2.085 2.085-6.662 6.66a.187.187 0 01-.08.047l-2.872.821.821-2.873a.18.18 0 01.047-.079l6.661-6.661z"
        fill="#0063E7"
      />
    </SvgIcon>
  );
}

export default CreateIconV2;
