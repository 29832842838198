import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { TrackerLoading } from './loading';


export const TrackerLoaders = (props) => {

  const {
    count
  } = props;


  return <>
    <Grid container direction='row' >
      {
        Array.apply(null, Array(count)).map((value, index) => <Grid key={index} item xs={12} >
          <TrackerLoading />
        </Grid>)
      }
    </Grid>
  </>
}
TrackerLoaders.propTypes = {
  count: PropTypes.number
}

TrackerLoaders.defaultProps = {
  count: 1
}