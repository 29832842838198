import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import {CopyIcon} from '../../assets';
import {
  alertProps,
} from "../../utils";

// import { InputAdornmentComponent } from "../inputs";
// import dynamicForm from "../dynamicForm";
// import { getCountryCodeOptions } from "../../utils";
// import AddEditForm from "../addEditForm";

// import {
//   formComponentTypes,
// } from "../../utils";
// import { TextField } from "material-ui";
// import { withAlertAndDialog } from "../../HOCs";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";

import EditAddressComponent from './editAddress'
import EditPhoneComponent from './editMobile'
import EditEmailComponent from "./editEmail";
// import EditIDComponent from "./editID";


const calculateMargin = ({hideMarginX, marginX}) => {
  if(hideMarginX){
    return 0
  } else {
    if(marginX){
      return `${marginX}px 0px`
    } else return "30px 0px 30px 0px"
  }
}

const useStyle = makeStyles((theme) => ({
  root: {
    margin: props => calculateMargin(props)
    // margin: (props) =>
    //   !props.hideMarginX
    //     ? `${props.marginX ? props.marginX : "30px 0px 30px 0px"}`
    //     : "0px",
  },
  positionVerifyButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  labelStyle: {
    marginLeft: 12,
    color: theme.palette.text.secondary,
    fontSize: "0.8rem",
  },
  labelDetailStyle: {
    marginLeft: 12,
    fontSize: "0.9rem",
  },
  verifyButton: {
    boxShadow: "none !important",
    color: theme.palette.ternary.main,
    fontSize: "0.8rem",
    padding: "0px !important",
  },
}));




function PersonalDetail(props) {
  // const [phoneNo, setPhoneNo] = useState(props.mobileNumber)
  // const [phoneCode, setPhoneCode] = useState(props.mobileCode)


  const classes = useStyle(props);
  const {
    icon,
    labelName,
    labelContent,
    verifyButtonNeeded,
    isAccordionNeeded,
    isEditable,
    isAddress,
    isEmail,
    isPhone,
    isID,
    isPrimary,
    rawData,
    isAccordionOpen,
    loginData,
    user_data,
    showTip,
    onLabelClick,
    showCopy,
    copyText
  } = props;

  let data = rawData;

  
  const onEditAddress = () => {
    
    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      component: <EditAddressComponent editData={data} isEdit={true}/> ,
    });
  }

 const onEditPhone = () => {

  data = {
    id : data?.id,
    label : data?.label,
    mobileNo : data?.value?.mobilenumber || data?.mobileNo,
    mobileNoIsdCode : data?.value?.mobile_code || data?.mobileNoIsdCode,
    mobiletype : {
      value: data?.mobile_type?.value,
      label: data?.mobile_type?.label,
      isActive: data?.mobile_type?.isActive
    },
    mobilenumber: data?.value?.mobilenumber || data?.mobilenumber,
    mobilenumberISDcode: data?.value?.mobile_code || data?.mobilenumberISDcode,
    value: data?.value,
  }

   props.drawer.setDrawer({
    ...props.drawer,
    open: true,
    component: <EditPhoneComponent editData={data} isEdit={true} loginData={loginData} user_data = {user_data} isPrimary={isPrimary}/> ,
   })
 }

 const onEditEmail = () => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      component: <EditEmailComponent editData={data} isEdit={true} loginData={loginData} user_data = {user_data}/> ,
    });
 }
 
 const onEditID = () => {
   return
    // props.drawer.setDrawer({
    //   ...props.drawer,
    //   open: true,
    //   component: <EditIDComponent editData={data} isEdit={true} loginData={loginData} user_data = {user_data}/> ,
    // });
 }
 const copyClipboar = () => {
  navigator.clipboard.writeText(copyText);
  props.alert.setSnack({
    open: true,
    horizontal: alertProps.horizontal.center,
    vertical: alertProps.vertical.top,
    msg: "Link copied successfully!",
    severity: alertProps.severity.success,
  });
 }


  return (
    <Grid item container justify="center" className={classes.root}>
      <Grid item>{icon}</Grid>
      <Grid item container xs>
        <Grid item xs={12}>
          <Typography className={classes.labelStyle}>{labelName}</Typography>
        </Grid>
        <Grid item xs={12}>
        {showTip ? 
        <Tooltip title="Click to reveal key" placement="top-start">
          {/* {
            isEditable 
            ? 
              <InputAdornmentComponent 
                required={true}
                title={"Phone"}
                options={getCountryCodeOptions()}
                valueMObileNo={ phoneNo }
                valuePhoneCode={ phoneCode }
                handleChangeSelect={(e) => { setPhoneCode(e.target.value) }}
                handleMobileNo={(e) => { setPhoneNo(e.target.value) }}
              /> 
            :  */}
              <Typography onClick={onLabelClick} className={classes.labelDetailStyle}>
                {labelContent}
              </Typography>
              </Tooltip> : <Typography style={{wordBreak: "break-word"}} className={classes.labelDetailStyle}>
                {labelContent}
              </Typography>
        }
          {/* } */}
        </Grid>
      </Grid>
      <Grid item>
          {
            showCopy && copyText ? <IconButton  className={classes.customHoverFocus} edge="end" disableRipple onClick={copyClipboar}><CopyIcon style={{width:20, height: 20}} /></IconButton> : null
          }
          </Grid>
      <Grid item className={classes.positionVerifyButton}>
        <div>
          {isAccordionNeeded && (
            <>
              {isAccordionOpen && (
                <IconButton disabled={props.accordionVisibility === "off" ? true : false} style={{opacity: props.accordionVisibility === "off" && 0 }}>
                  <ExpandLessRoundedIcon />
                </IconButton>
              )}
              {!isAccordionOpen && (
                <IconButton disabled={props.accordionVisibility === "off" ? true : false}>
                  <ExpandMoreRoundedIcon style={{opacity: props.accordionVisibility === "off" && 0 }} />
                </IconButton>
              )}
            </>
          )}
          {isEditable && (
            <IconButton
              className={classes.customHoverFocus}
              edge="end"
              onClick={() => isAddress ? onEditAddress() : isEmail ? onEditEmail() : isPhone ? onEditPhone() : isID ? onEditID() : () => {}  }
              disableRipple
            >
              <img src="/images/icons8-edit.svg" width="75%" alt="edit" />
            </IconButton>
          )}
          
          {verifyButtonNeeded && (
            <Button className={classes.verifyButton}>verify</Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default withAlelrtAndDialog(PersonalDetail)