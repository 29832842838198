import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';
import { CustomChoose } from '../../../../../assets';
import SubscriptionDetailCard from '../../view/components/subscriptionDetailCard';
import { routes } from "../../../../../router/routes";


const useStyles = makeStyles((theme)=>({
    heading:{
        color:'#3B3F5B',
        fontWeight:400,
        fontSize:18,
        marginBottom:theme.spacing(2)
    },
    description:{
        color:'#878C9A',
        fontWeight:400,
        fontSize:14,
        margin:theme.spacing(3),
        textAlign:'center'
    },
    card:{
        backgroundColor:'#fff',
        boxShadow:'0px 5px 35px #0000000F',
        border:'1px solid #C7C7C7',
        borderRadius:8,
        padding:theme.spacing(2)
    },
    rowCenter:{
        display:'flex',
        alignItems:'center'
    },
    title:{
        fontSize:14,
        color:'#252B43',
        fontWeight:400,
        marginBottom:theme.spacing(2) - theme.spacing(1/2)
    },
    amount:{
        fontSize:14,
        color:theme.palette.ternary.main,
        fontWeight:500,
    },
    viewMore:{
        fontSize:14,
        color:theme.palette.primary.main,
        fontWeight:400,
        cursor:'pointer',
        fontStyle:'italic'
    },
    spaceBtnCenter:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        flexWrap:'wrap'
    },
    columCenter:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        cursor:'pointer',
        marginTop:theme.spacing(3)
    }
}));

export const SubscriptionDetails = (props) =>{

    const classes = useStyles(props);

    const {
        label="",
        data={},
        description="",
        loading=false,
    } = props;

    const handleSubscriptionViewMoreClick = () => {
        const { id, versionId, type } = data;
    
        const link = `${routes.product_management}/${
          type === "Service"
            ? "services"
            : type === "Package"
            ? "packages"
            : "products"
        }/view/${id}/${versionId}`;
    
        const win = window.open(link, "_blank");
        win.focus();
  }

    return (
      <div>
        {label && <Typography className={classes.heading}>{label}</Typography>}
        {loading || (data && Object.keys(data)?.length) ? (
          <SubscriptionDetailCard
            id={data?.id}
            image={data?.image}
            title={data?.name}
            aliasId={data?.aliasId}
            currency={data?.currency}
            price={data?.amount}
            originalPrice={data?.price}
            discountPercentage={data?.discountPercentage}
            onViewMoreClick={handleSubscriptionViewMoreClick}
            loading={loading}
            hideAliasId
          />
        ) : (
          <div className={classes.columCenter}>
            <CustomChoose />
            <Typography className={classes.description}>
              {description}
            </Typography>
          </div>
        )}
      </div>
    );
}

SubscriptionDetails.propTypes={
    label:PropTypes.string,
    data:PropTypes.object,
    description:PropTypes.string,
    viewMore:PropTypes.bool,
    loading:PropTypes.bool,
}