import React from "react";
import { Paper, withStyles, Grid } from "@material-ui/core";
import { HybridTypography } from "../../../../components";
import { ICONS } from "../../../../components/hybridTypography/typography";
// import Divider from "@material-ui/core/Divider";
// import SimpleAvatarCard from "../../../../components/simpleAvatarCard";
import { routes } from "../../../../router/routes";
import { withRouter } from "react-router-dom";
import { customDateConverter, formComponentTypes } from "../../../../utils";
// customAddress
import ViewAddressEmailPhone from "../../../../components/viewAddressEmailPhone";
import ViewCardAddressEmailPhone from "../../../../components/viewAddressEmailPhone/viewCard";
import ViewTax from "../../../../components/viewAddressEmailPhone/viewTax";
// import AvatarStack from "../../../../components/avatarStack";
// import ViewProviderBranches from "./ViewProviderBranches";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";

// customAddress
const styles = (theme) => ({
  paperCard: {
    borderRadius: "0px",
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
  },
  strong_name: {
    paddingLeft: 0,
    paddingRight: 12,
    color: theme.palette.ternary.main,
    marginLeft: "28px",
    boxShadow: "unset !important",
  },
  gridItems: {
    padding: "25px 25px 40px 32px",
  },
  gridTopItems: {
    padding: "25px 25px 0px 32px",
  },
  gridMiddleItems: {
    padding: "0px 25px 0px 32px",
  },
  gridBottomItems: {
    padding: "0px 25px 40px 32px",
  },
  dividerBackground: {
    backgroundColor: theme.palette.secondary.light,
  },
});

class DetailsCard extends React.Component {
  handleOnSupervisorClicked = (id) => {
    this.props.history.push(`${routes.manage_supervisors}/view/${id}`);
  };

  handleServiceProfessionalsViewAllClick = (data, type, label) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewAddressEmailPhone
          data={data ?? []}
          drawer={this.props.drawer}
          onEndIconClickHandler={this.onClickIcon}
          type={type}
          label={label}
        />
      ),
    });
  };
  checkConditionsBeforeRender = (data, elementType) => {
    let _data = data ?? "N/A";

    if (data) {
      if (
        elementType === formComponentTypes.DATE_RANGE_PICKER ||
        elementType === formComponentTypes.DATE_TIME_PICKER
      ) {
        return customDateConverter(_data);
      } else if (elementType === formComponentTypes.MOBILE_INPUT_BOX) {
        return `${data.mobile_code} ${data.mobilenumber}`;
      } else return _data;
    } else return _data;
  };
  render() {
    const { classes, data, loading, theme } = this.props;

    const MultipleAddressEmailPhoneNumber = (props) => {
      switch (props.element) {
        case "ADD_ADDRESS":
          return (
            <ViewCardAddressEmailPhone
              title={props?.label}
              height={16}
              width={16}
              style={{ position: "relative", top: 2 }}
              data={props.data}
              emptyMessage="Not yet added"
              element={props.element}
              label={props.label}
              onButtonClickHandler={() =>
                this.handleServiceProfessionalsViewAllClick(
                  props?.data,
                  formComponentTypes.ADD_ADDRESS,
                  "Address"
                )
              }
              isListMiddle={true}
            />
          );
        case "ADD_EMAIL":
          return (
            <ViewCardAddressEmailPhone
              title={props?.label}
              height={16}
              width={16}
              style={{ position: "relative", top: 2 }}
              data={props.data}
              emptyMessage="Not yet added"
              element={props.element}
              label={props.label}
              onButtonClickHandler={() =>
                this.handleServiceProfessionalsViewAllClick(
                  props?.data,
                  formComponentTypes.ADD_EMAIL,
                  "Email"
                )
              }
              isListMiddle={true}
            />
          );
        case "ADD_PHONE_NUMBER":
          return (
            <ViewCardAddressEmailPhone
              title={props?.label}
              height={16}
              width={16}
              style={{ position: "relative", top: 2 }}
              data={props.data}
              emptyMessage="Not yet added"
              element={props.element}
              label={props.label}
              onButtonClickHandler={() =>
                this.handleServiceProfessionalsViewAllClick(
                  props?.data,
                  formComponentTypes.ADD_PHONE_NUMBER,
                  "Phone Number"
                )
              }
              isListMiddle={true}
            />
          );

        case formComponentTypes.ADD_TAX_ID:
          return (
            <ViewTax
              title={props?.label}
              height={16}
              width={16}
              style={{ position: "relative", top: 2 }}
              data={props.data}
              emptyMessage="Not yet added"
              element={props.element}
              label={props.label}
              onButtonClickHandler={() =>
                this.handleServiceProfessionalsViewAllClick(
                  props?.data,
                  formComponentTypes.ADD_TAX_ID,
                  "Tax"
                )
              }
              isListMiddle={true}
            />
          );
        default:
          return null;
      }
    };
    return (
      <Paper className={classes.paperCard}>
        <Grid container>
          <Grid
            container
            item
            direction="row"
            md={12}
            sm={12}
            xs={12}
            className={classes.gridTopItems}
          >
            {data?.length > 0 &&
              data?.[0]?.partnerMeta &&
              Object.keys(data[0].partnerMeta).map((key) => {
                
                if (
                  data[0].partnerMeta[key] && 
                  data[0].partnerMeta[key].element &&
                  data[0].partnerMeta[key].element !==
                  formComponentTypes.PROFILE_UPLOAD_BUTTON &&
                  data[0].partnerMeta[key].element !==
                  formComponentTypes.SELECT_BOX &&
                  data[0].partnerMeta[key].element !==
                  formComponentTypes.RICHTEXTEDITOR &&
                  data[0].partnerMeta[key].element !== formComponentTypes.RESOURCE_VALIDITY &&
                  data?.[0]?.partnerMeta?.[key]?.label?.toLowerCase() !==
                  "remarks"
                ) {
                  if (
                    data?.[0]?.partnerMeta[key]?.value?.constructor === Array
                  ) {
                    if (data?.[0]?.partnerMeta[key]?.value?.length > 0) {
                      if (
                        !data?.[0]?.partnerMeta[key]?.hasOwnProperty(
                          "required"
                        ) &&
                        !data?.[0]?.partnerMeta[key]?.value
                      ) {
                        return null;
                      } else {
                        return (
                          <Grid item xs={12} sm={4} md={4}>
                            <MultipleAddressEmailPhoneNumber
                              data={data?.[0]?.partnerMeta[key]?.value}
                              element={data?.[0]?.partnerMeta[key]?.element}
                              label={data?.[0]?.partnerMeta[key]?.label}
                            />
                          </Grid>
                        );
                      }
                    } else {
                      return null;
                    }
                  } else {
                    if (
                      !data?.[0]?.partnerMeta[key]?.hasOwnProperty(
                        "required"
                      ) &&
                      !data?.[0]?.partnerMeta[key]?.value
                    ) {
                      return null;
                    } else {
                      return (
                        <Grid item xs={12} sm={4} md={4}>
                          <HybridTypography
                            icon={ICONS.BUSINESS(theme.palette.primary.main)}
                            text={this.checkConditionsBeforeRender(
                              data?.[0]?.partnerMeta?.[key]?.value,
                              data[0].partnerMeta?.[key].element
                            )}
                            header={
                              data?.[0]?.partnerMeta[
                                key
                              ]?.label?.toUpperCase() ?? "N/A"
                            }
                            loading={loading}
                            // islistbottom={true}
                            // islisttop={true}
                            isListMiddle={true}
                          />
                        </Grid>
                      );
                    }
                  }
                }
                return true;
              })}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withAlelrtAndDialog(withRouter(withStyles(styles, {withTheme: true})(DetailsCard)));
