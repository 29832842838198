/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-01-27 13:36:44
 * @modify date 2022-01-27 13:36:44
 * @desc Component for Promotional Banner View Page - Info Summary Card
 */

import React from "react";
import PropTypes from "prop-types";

// Material UI Components
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Material UI Helpers
import { makeStyles, withStyles, alpha } from "@material-ui/core/styles";

// Custom Components
import { StatusLabel } from "../../../../../components";

// Custom Helpers
import {
  textClampStyleGenerator,
  isSuperAdmin,
  isManagedCareAdmin,
  isCreatedByManagedCareAdminById,
} from "../../../../../utils";
import { createTestProps } from "../../../../../utils/common";
import { useSelector } from "react-redux";

const CustomOutlinedButton = withStyles((theme) => {
  const error = "rgb(240, 62, 80)";
  return {
    root: {
      color: error,
      border: `1px solid ${error}`, //${alpha(error, 0.5)}
      padding: "14.5px 26px",
      textTransform: "unset",
      minWidth: "99px",
      "&:hover": {
        border: `1px solid ${error}`,
        backgroundColor: alpha(error, 0.04),
      },
      [theme.breakpoints.down("xs")]: {
        padding: "10.5px 26px",
      },
    },
    label: {
      lineHeight: "19px",
    },
  };
})(Button);

const CustomContainedButton = withStyles((theme) => {
  return {
    root: {
      padding: "14.5px 26px",
      textTransform: "unset",
      [theme.breakpoints.down("xs")]: {
        padding: "10.5px 26px",
      },
    },
    label: {
      lineHeight: "19px",
    },
  };
})(Button);

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "22px",
    fontWeight: 500,
    paddingBottom: 12,
    ...textClampStyleGenerator(1),
  },
  promoId: {
    fontSize: "16px",
    fontStyle: "italic",
    fontWeight: 400,
    paddingBottom: 12,
    color: "#6F6F6F",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "flex-start",
    margin: "10px",
    [theme.breakpoints.down("xs")]: {
      margin: "12px 0px 0px",
      justifyContent: "flex-end",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
  },
}));

function InfoSummaryCard(props) {
  const classes = useStyles();

  // State Selectors
  const authData = useSelector((state) => state?.authState?.loginAuth?.data);

  const { id, title, promoId, status, createdBy, onDeleteClick, onEditClick } =
    props;

  return (
    <div className={classes.container}>
      <Box>
        <Typography className={classes.title} title={title}>
          {title ? title : "-"}
        </Typography>
        <Typography color="textSecondary" className={classes.promoId}>
          Promo ID : {promoId ?? "-"}
        </Typography>
        <StatusLabel active={status} />
      </Box>
      {(isSuperAdmin(authData) ||
        (isManagedCareAdmin(authData) &&
          isCreatedByManagedCareAdminById(createdBy, authData))) && (
        <div className={classes.buttonWrapper}>
          <div className={classes.buttonContainer}>
            <CustomOutlinedButton
              variant="outlined"
              onClick={() => onDeleteClick(id)}
              {...createTestProps(`delete promotional banner ${id}`)}
            >
              Delete
            </CustomOutlinedButton>
            <CustomContainedButton
              variant="contained"
              color="primary"
              onClick={() => onEditClick(id)}
              {...createTestProps(`edit promotional banner ${id}`)}
            >
              Edit Details
            </CustomContainedButton>
          </div>
        </div>
      )}
    </div>
  );
}

InfoSummaryCard.propTypes = {
  title: PropTypes.string,
  promoId: PropTypes.string,
  status: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
};

InfoSummaryCard.defaultProps = {
  title: "-",
  promoId: "-",
  status: false,
  onDeleteClick: () => {},
  onEditClick: () => {},
};

export default InfoSummaryCard;
