import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import { genericStyles } from "../../utils";
// import Avatar from "@material-ui/core/Avatar";
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(1),
  },
  secondary: {
    color: "white",
    fontSize: "14px",
    paddingTop: "0px",
  },
  categoryAsCard: {
    // backgroundColor: theme.palette.primary.light,
    textAlign: "center",
    width: "fit-content",
    padding: "0px 10px",
    marginRight: "0px",
    borderRadius: "3px",
    color: theme.palette.primary.dark,
  },
  cardWithoutBackground: {
    // backgroundColor: theme.palette.primary.light,
    textAlign: "center",
    width: "fit-content",
    padding: "0px 10px",
    marginRight: "0px",
    borderRadius: "3px",
    color: theme.palette.primary.dark,
  },
  cardPaper: {
    borderRadius: "8px",
    boxShadow: theme.palette.primary.boxShadow,
    maxHeight:250,
    overflow:'auto'
  },
  ...genericStyles.cardWrapperShadows(theme)
}));

export default function PopperPopupState(props) {
  const classes = useStyles();

  return (
    <div style={{display: "flex", alignItems: "center"}}>
      {/* <Button
        variant="contained"
        color="primary"
        onMouseOver={props.handleOpen}
      >
        Toggle Popper
      </Button> */}
      <Typography
        className={`${
          props.withoutBackground
            ? classes.cardWithoutBackground
            : classes.categoryAsCard
        } ${classes.secondary}`}
        onMouseEnter={props.handleOpen}
        style={{cursor: "default"}}
      >
        +{props.count}
      </Typography>
      <Popper
        open={props.open}
        anchorEl={props.open}
        placement={"bottom-start"}
        transition
        style={{ zIndex: 1000 }}
        onMouseLeave={props.handleClose}
        onClick={(e) => {
          e.stopPropagation()
          return false
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={`${classes.cardPaper} ${classes.cardWrapperShadows}`}>
              {props.data.map((val) => {
                return (
                  <Typography
                    // id={`popper-${props?.id ? props.id : 0}`}
                    id={`popper`}
                    className={classes.typography}
                    // onMouseEnter={props.handleOpen}
                    // onClick={props.handleOpen}
                    // onMouseLeave={props.handleClose}
                    style={{cursor: "default"}}
                  >
                    {val.label ? val.label : val.name ? val.name : "N/A"}
                  </Typography>
                );
              })}
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
