import React from "react";

import { Grid, withStyles } from "@material-ui/core";
// import CardList from "../../../../components/userList";
import SubHeader from "../../../../components/subHeader";
import { clientDetailsUrl, filterTagType, fontStyleClass, options, productSortDirectionOption, productSortFieldOption, scrollToTop, subHeaderSortOption, subHeaderSortOptionWithValue, user_roles } from "../../../../utils";
import { routes } from "../../../../router/routes";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { FilterBuilder, InternatlServerError, NotFoundSection } from "../../../../components";
import { filterComponent } from "./filterComponent";
import FilterTags from "../../../../components/filters/filterTags";
import { errorMessage } from "../../../../utils/errorMessages";
import { Catalogue } from "../../../../components/catalogue/catalogue";
import { isTrialAccountType } from "../../../../utils/common";


// const sizes = {
//   md: 3,
//   sm: 3,
//   xs: 3,
// };

const style= theme => ({
  ...fontStyleClass
})
const initialState = {
  pageNo: 0,
  pageSize: 25,
  clientName: "",
  fromTimeStamp: moment().startOf('day').toISOString(),
  toTimeStamp: moment().endOf('day').toISOString(),
  loading: true,
  provider: [],
  professional: [],
  coordinator: [],
  status: "Both",
  date: null,
  isFilterApplied: false,
  sort: subHeaderSortOption[2].value,
  sortDirec: subHeaderSortOptionWithValue[2],
  gender: null
}

class ListClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      id: "ListClients"
    };
  }

  componentDidMount() {
    this.props.GET_CLIENT_ORDER_LISTS({filter: {
      fromDate: null,
      orderStatus: [],
      toDate: null,
     },
      limit: 10,
      offset: 0,
      searchValue: ""})
    this.getClientList();
    scrollToTop();
    window.addEventListener("scroll", this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  }

  scrolling = () => {
    let {
      loading,
      data,
      error,
    } = this.props?.careGiverClient?.allcareGiverClient;
    this.props.GET_CLIENT_ORDER_LISTS({filter: {
      fromDate: null,
      orderStatus: [],
      toDate: null,
     },
      limit: 10,
      offset: 10,
      searchValue: ""})
    let listCount = data?.length ?? 0;
    let totalCount = data?.[0]?.total_count ?? 0;

    if (loading || error || totalCount === 0 || listCount === 0) {
      return false;
    }

    if (
      Math.round(window.scrollY + window.innerHeight) >=
      document.getElementsByTagName("body")[0].scrollHeight &&
      listCount !== totalCount
    ) {
      this.setState(
        (state, props) => ({
          pageNo: state.pageNo + 25,
        }),
        () => {
          this.getClientList();
        }
      );
    }
  };

  getClientList = () => {
    let _status = undefined
    if(this.state?.status){
      if(this.state.status.toLowerCase() === "Active".toLowerCase()) _status = true
      if(this.state.status.toLowerCase() === "InActive".toLowerCase()) _status = false
      if(this.state.status.toLowerCase() === "Invited".toLowerCase()) _status = "Invited"
    }

    console.log(_status)
    let _  = this.props.GET_ALL_CARE_GIVER_CLIENT({
      ...this.state,
      searchValue:this.state.clientName,
      isprofessional:this.state.professional?.length>0 ? true : false,
      filter:{
        provider: this.state.provider?.map(_ => _.value),
        professional: this.state.professional?.map(_ => _.value),
        coordinator: this.state.coordinator?.map(_ => _.value),
        status: _status,
        sort:this.state?.sortDirec?.value,
        gender: this.state?.gender?.value,
      },
      offset:this.state?.pageNo,
    limit:this.state?.pageSize,
    });
    console.log(_)
  };

  // SETTING THE STATE FOR SEARCH AND CALLING THE SEARCH FUNCTION

  storeInputSearchValue = (value) => {
    this.setState({ clientName: value, pageNo: 0, pageSize: 25 }, () => {
      // if (value.length % 3 === 0) {
      this.getClientList();
      // }
    });
  };


  // Sort Handlers
  handleSortChange = (searchValue) => {
    this.setState({
      sort: searchValue,
      pageNo: 0,
      pageSize: 25
    }, () => {
      this.getClientList();
    })
  }


  // Filter Handlers
  onFilterIconClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: <FilterBuilder
        components={filterComponent(this.state, this.props)}
        applyButtonLabel="Apply"
        loading={this.props?.allCareSupervisor?.loading}
        onChange={this.onFilterChange}
        onApplyButtonClicked={this.applyFilter}
        resetFilter={this.resetFilter}
        onCancelClick={this.closeFilter}
        spacing={1}
        saveButtonId={`saveFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
        cancelButtonId={`cancelFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
        clearAllButtonId={`clearAllFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
      />
    })
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  anyFilterApplied = (state) => {
    let { provider = [], professional = [], coordinator = [], status = "", sortDirec = productSortDirectionOption[0], sortfield = productSortFieldOption[0] } = state
    if(provider === null) provider = initialState.provider
    if(professional === null) professional = initialState.professional
    if(coordinator === null) coordinator = initialState.coordinator
    if(
        JSON.stringify(professional) === initialState?.professional &&
        JSON.stringify(provider) === initialState?.provider &&
        JSON.stringify(coordinator) === initialState?.coordinator &&
        status === initialState.status &&
        sortDirec.value === initialState.sortDirec.value &&
        sortfield.value === initialState.sortfield.value){
      return false
    } else {
      return true
    }
  }

  applyFilter = (state) => {
    this.setState({
      ...state,
      pageNo: 0,
      pageSize: 25,
      isFilterApplied: this.anyFilterApplied(state),
    }, () => {
      this.getClientList();
      this.closeFilter()
    })
  }

  closeFilter = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    })
  }

  resetFilter = () => {
    this.setState({
      ...initialState
    }, () => {
      this.getClientList();
      this.closeFilter();
    })
  }


  // Filter Tag Utils
  checkIsThereAnyFilter = () => {
    // const newState = this.state
    let newState = {
      ...this.state,
      provider: this.state.provider === null ? initialState.provider : this.state.provider,
      professional: this.state.professional === null ? initialState.professional : this.state.professional,
      coordinator: this.state.coordinator === null ? initialState.coordinator : this.state.coordinator,
    }
    if (
      newState[filterTagType.Provider].length === 0 &&
      newState[filterTagType.Professional].length === 0 &&
      newState[filterTagType.Coordinator].length === 0 &&
      newState[filterTagType.Status] === initialState.status &&
      newState[filterTagType.Gender] === initialState.gender) {
      return false
    } else return true
  }

  handleChipDelete = (key, index) => {

    console.log("Key ", key)
    console.log("index ", index)
    if (
      key === filterTagType.Provider ||
      key === filterTagType.Professional ||
      key === filterTagType.Coordinator) {
      let newState = this.state
      newState[key].splice(index, 1)
      console.log(newState)

      this.setState({
        ...newState,
        pageNo: 0,
        pageSize: 25,
        isFilterApplied: this.checkIsThereAnyFilter()
      }, () => {
        this.getClientList()
      })

    } else if (key === filterTagType.Status) {
      this.setState({
        status: "Both",
        pageNo: 0,
        pageSize: 25,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getClientList()
      })
    } else if(key === filterTagType.Gender){
      this.setState({
        gender: initialState.gender,
        pageNo: 0,
        pageSize: 25,
      }, () => {
        this.setState({
          isFilterApplied: this.checkIsThereAnyFilter()
        })
        this.getClientList()
      })
    }
  }

  giveMeEmptyMessage = () => {
    if (this.state.clientName && this.state.isFilterApplied) {
      return errorMessage.notFoundFilterSearch;
    } else if (this.state.clientName) {
      return errorMessage.notFoundSearch;
    } else if (this.state.isFilterApplied) {
      return errorMessage.notFoundFilter;
    } else {
      return "You don't have any clients yet"
    }
  }

  // -----------------------------------------------

  formatData = (clients) => {
    if (Array.isArray(clients)) {
      return clients?.map((client) => ({
        ...client,
        isactive: client?.isactive ? "active": "inactive",
        gender: options.gender.filter(
          (gender) => gender.value === client?.gender?.toLowerCase()
        )?.[0]
      }));
    }
    return [];
  }


  render() {
    let {
      loading,
      data,
      error,
    } = this.props?.careGiverClient?.allcareGiverClient;
    // const {classes} = this.props
    return (
      <Grid style={{ padding: "25px" }}>
        <Grid>
          <SubHeader
            isbutton={Boolean(this.props?.auth_data?.data?.user_role === user_roles.service_coordinator) ? false : true}
            placeholder={"Search for Client Name"}
            id={this.state?.id}
            entityName={"Client"}
            countText={`Total Clients (${data?.[0]?.total_count ?? 0})`}
            button_name={"+ ADD CLIENT"}
            link={routes.add_clients}
            handleSearch={this.getClientList}
            handleChangeInputSearch={this.storeInputSearchValue}
            value={this.state.clientName}
            useNewFilterIcon
            placeSearchIconToLeft
            isFilterApplied={this.state.isFilterApplied}
            onFilterIconClicked={this.onFilterIconClicked}
            showSortOption={true}
            sort={this.state.sort}
            handleSortChange={this.handleSortChange}
            loading={loading}

            // Temporarily hidden
            // hideFilter
          // hideSearch
          />
        </Grid>

        {
          this.state.isFilterApplied &&
          <Grid item xs={12} style={{ marginTop: 25 }} >
            <FilterTags
              filterData={this.state}
              handleDelete={this.handleChipDelete}
            />
          </Grid>
        }

        {/*TOTAL COUNT  */}
        {/* <Grid item xs={12}>
          <Typography variant="h6" style={{ paddingTop: 25 }} className={classes.mediumFont} >Total Clients ({data?.[0]?.total_count ?? 0})</Typography>
        </Grid> */}


        {!loading && error && <InternatlServerError message="Something went wrong, Try Again!" onRetry={this.getClientList} />}
        {!loading && !error && !data?.length && <NotFoundSection message={this.giveMeEmptyMessage()} />}

        {/* List */}
        {!error && (
          <Grid style={{ paddingTop: "25px" }}>
            <Catalogue
                id={"Clients"}
                catalogues={this.formatData(data)}
                redirectUrl={clientDetailsUrl}
                loading={loading}
                showTrialExpiry={isTrialAccountType() && user_roles.super_admin === this.props.auth_data.data.user_role}
              />
            {/* <CardList
              isShowGenderAge={true}
              list={data?.length>0?data:[]}
              loading={loading}
              componentOrder={1}
              sizes={sizes}
              Url={clientDetailsUrl}
              isCard={true}
              hideQuickSummaryLabel
              showCareGiver={false}
              hideQuickSummary
              temporaryClientChip={true}
              client_listing_info={true}
            /> */}
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  careGiverClient: state.careGiverClient,
  auth_data: state?.authState?.loginAuth,
  orders:state?.clientOrders?.list
});

export default connect(mapStateToProps, actions)(withAlelrtAndDialog(withRouter(withStyles(style)(ListClient))));
