import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function DOBIconV2(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.989 4A3 3 0 004 6.989v10.575a3 3 0 002.989 2.989h10.575a3 3 0 002.989-2.989V6.989A3 3 0 0017.564 4H6.989zm0 1.379h10.575a1.6 1.6 0 011.609 1.609v.69H5.379v-.69a1.6 1.6 0 011.61-1.609zm-1.61 3.679h13.794v8.506a1.6 1.6 0 01-1.609 1.609H6.989a1.6 1.6 0 01-1.609-1.609l-.001-8.506zM8.368 10.9a1.15 1.15 0 100 2.299 1.15 1.15 0 000-2.299zm3.908 0a1.15 1.15 0 100 2.3 1.15 1.15 0 000-2.3zm3.908 0a1.149 1.149 0 10-.002 2.298 1.149 1.149 0 00.003-2.298h-.001zm-7.816 4.138a1.15 1.15 0 10.814.335 1.15 1.15 0 00-.814-.338v.003zm3.908 0a1.15 1.15 0 10.813.335 1.15 1.15 0 00-.813-.338v.003z"
        fill="#0063E7"
      />
    </SvgIcon>
  );
}

export default DOBIconV2;
