import React from "react";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withAlertAndDialog } from "../../../HOCs";
import { withStyles, Hidden } from "@material-ui/core";
import { appBarHeight } from "../../../components/navBars/utils";
import { TopNavbar, SideNavbar, LoadingSection, InternatlServerError } from "../../../components";
import { scrollToTop, serviceProfessionalTabs } from "../../../utils";
import { routes } from "../../../router/routes";
import Summary from "./summary";
import Schedules from "./schedule";
// import RealTimeLocation from "./realTimeLocation";
import Clients from "./clients";
import FeedBacks from "./feedbacks";
import { withTheme } from '@material-ui/core/styles';
import RealTimeLocationTracking from "./realTimeLocationTracking";

const styles = (theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    width: '100%',
    [theme.breakpoints.up("md")]: {
      paddingTop: appBarHeight,
      width: "calc(100% - 240px)",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: props => props?.navBarArr?.length ? appBarHeight + 48 : appBarHeight,
    }
  },
})
class SpInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateNavBarProps: true,
    };
  }

  componentDidMount() {
    this.getDetail();
    scrollToTop();
  }

  getDetail = async() => {
    const id = this.props?.match?.params?.id;

    // this.props.GET_CARE_GIVER_INFO({ id });

    await this.props.GET_PERSONA_INFO({ resoureId:id });
    // console.log(_?.payload?.data[0])
  }

  onTitleIconClicked = () => {
    const id = this.props?.match?.params?.id;

    this.props.history.push(routes.manage_care_givers + "/edit/" + id)
  }

  render() {
    const { match } = this.props;

    const {
      data,
      loading,
      error
    } = this.props?.personaResourceInfo;

    const { classes, backUrl } = this.props;

    // console.log(data)
    // const resourceData = this.props.careGiverInfo.data
    // console.log(resourceData)
    const id = this.props?.match?.params?.id;
    const variant = this.props?.match?.params?.variant ?? 'day';


    return (
      <div className={classes.root}>
        {/* Top Navigation bar */}
        <TopNavbar
          title={loading ? 'loading...' : data?.[0]?.resourceName}
          withBackButton={true}
          navBarArr={serviceProfessionalTabs(id, variant)}
          backUrl={backUrl ? backUrl + id : routes.monitor_service_professionals}
          isAvoidGoBack={true}
          // titleIcon={<EditClientIcon htmlColor={this.props.theme.palette.ternary.main} />}
          onTitleIconClicked={this.onTitleIconClicked}
        />

        {/* Side Navigation bar */}
        <Hidden smDown>
          <SideNavbar />
        </Hidden>

        {/* Content */}
        <div className={classes.content}>
          {loading && <LoadingSection message="Loading service professional details..." />}
          {!loading && error && <InternatlServerError onRetry={this.getDetail} />}
          {!loading && !error && data && <div>
            {match.path === routes.monitor_service_professionals_summary && <Summary personaResourceInfo={this.props?.personaResourceInfo} data={data} loading={loading} getDetail={this.getDetail} />}
            {match.path === routes.monitor_service_professionals_schedules && <Schedules data={data} loading={loading} />}
            {/* {match.path === routes.monitor_service_professionals_tracking && <RealTimeLocation data={data} loading={loading} />} */}
            {match.path === routes.monitor_service_professionals_tracking && <RealTimeLocationTracking data={data} loading={loading} />}
            {match.path === routes.monitor_service_professionals_clients && <Clients data={data} loading={loading} />}
            {match.path === routes.monitor_service_professionals_feebacks && <FeedBacks data={data} loading={loading} />}
          </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  careGiverInfo: state.careGiver?.careGiverInfo,
  personaResourceInfo: state.resourceReducer?.personaResourceInfo,
});

export default connect(
  mapStateToProps,
  actions
)(withAlertAndDialog(withRouter(withTheme(withStyles(styles)((SpInfo))))));
