/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-01-28 13:24:30
 * @modify date 2022-02-03 20:17:36
 * @desc Component for Promotional Banner View Page - Subscription Detail Card
 */
import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import MuiPaper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { textClampStyleGenerator } from "../../../../../utils";
import { createTestProps } from "../../../../../utils/common";
import { CustomAvatar } from "../../../../../components";

const Paper = withStyles((theme) => ({
  root: {
    borderRadius: "8px",
    border: "1px solid #C7C7C7",
    boxShadow: "0px 5px 35px #0000000F",
    padding: "16px 24px 16px 16px",
    display: "flex",
    gap: "12px",
    maxWidth: "300px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 10px",
      gap: "8px",
    },
  },
}))(
  forwardRef((props, ref) => <MuiPaper elevation={0} ref={ref} {...props} />)
);

const useDetailCardStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 400,
    fontSize: "14px",
    marginBottom: "14px",
    color: "#252B43",
    textTransform: "capitalize",
    ...textClampStyleGenerator(2),
  },
  aliasId: {
    fontWeight: 400,
    fontStyle: "italic",
    color: theme.palette.text.secondary,
    fontSize: "14px",
    marginBottom: "14px",
    [theme.breakpoints.down("xs")]: {
      whiteSpace: "nowrap",
    },
  },
  priceViewMore: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  price: {
    fontWeight: 500,
    color: theme.palette.ternary.main,
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  viewMore: {
    padding: "0px 0px",
    textTransform: "unset",
    fontStyle: "italic",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
}));

function SubscriptionDetailCard(props) {
  const classes = useDetailCardStyles(props);
  const {
    id,
    image,
    title,
    aliasId,
    currency,
    price,
    originalPrice,
    discountPercentage,
    onViewMoreClick,
    hideAliasId,
    loading,
  } = props;
  return (
    <Paper>
      <div>
        <CustomAvatar
          src={image}
          name={title}
          id={id}
          loading={loading}
          fallbackAsImage
          size={"40px"}
          classes={{
            root: {
              margin: "0px",
            },
          }}
        />
      </div>
      <div style={{ flex: 1 }}>
        <div>
          <Typography className={classes.title} title={title}>
            {title ? title : "-"}
          </Typography>
          {!hideAliasId && (
            <Typography className={classes.aliasId}>
              Service ID : <span>{aliasId ? aliasId : "-"}</span>
            </Typography>
          )}
          <div>
            <div className={classes.priceViewMore}>
              <Typography className={classes.price}>
                {`${currency ? currency : ""} ${price ? price : "-"}`}
              </Typography>
              {discountPercentage ? (
                <Typography className={classes.price}>
                  {`${currency ? currency : ""} ${
                    originalPrice ? originalPrice : "-"
                  }`}
                </Typography>
              ): ""}
              <Button
                onClick={onViewMoreClick}
                className={classes.viewMore}
                color="primary"
                disableRipple
                {...createTestProps(
                  "view more promotional banner subscription detail"
                )}
              >
                View More
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
}

SubscriptionDetailCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  aliasId: PropTypes.string,
  currency: PropTypes.string,
  price: PropTypes.string,
  onViewMoreClick: PropTypes.func,
  hideAliasId: PropTypes.bool,
};

SubscriptionDetailCard.defaultProps = {
  title: "-",
  aliasId: "-",
  currency: "-",
  price: "-",
  onViewMoreClick: () => {},
  hideAliasId: false,
};

export default SubscriptionDetailCard;
