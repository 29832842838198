import * as React from "react"

export function IssueChatIcon(props) {
  return (
    <svg width={20} height={21} viewBox="0 0 20 21" {...props}>
      <defs>
        <style>{".prefix__cls-1{fill:#9297a5}"}</style>
      </defs>
      <g
        id="prefix__Issue_Chat"
        data-name="Issue Chat"
        transform="translate(-2 -2)"
      >
        <path
          id="prefix__Path_654"
          d="M16 6H4a2 2 0 00-1.99 2L2 23l4-4h10a2.006 2.006 0 002-2V8a2.006 2.006 0 00-2-2zm0 11H5.172l-.586.586-.586.586V8h12z"
          className="prefix__cls-1"
          data-name="Path 654"
        />
        <path
          id="prefix__Path_655"
          d="M20 4v10h2V4a2 2 0 00-2-2H6v2z"
          className="prefix__cls-1"
          data-name="Path 655"
        />
      </g>
    </svg>
  )
}
