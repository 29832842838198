import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || "#28a9c1"
  })
}))

export const BusinessOrganizationIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon style={props.style}>
      <path className={classes.cls1} d="M4 3a2.015 2.015 0 0 0-2 2v16h20V9a2.015 2.015 0 0 0-2-2h-4V5a2.015 2.015 0 0 0-2-2zm0 2h10v14H4zm2 2v2h2V7zm4 0v2h2V7zm6 2h4v10h-4v-2h2v-2h-2v-2h2v-2h-2zM6 11v2h2v-2zm4 0v2h2v-2zm-4 4v2h2v-2zm4 0v2h2v-2z" transform="translate(-2 -3)" />
    </SvgIcon>
  )
}

BusinessOrganizationIcon.propTypes = {
  /**Sets the color of the icon. */
  color: PropTypes.string,
  /**Sets any additional styles added. */
  style: PropTypes.object
}