import React from "react";
import {
  Grid,
  Paper,
  withStyles,
  // Divider,
  Button,
  Typography,
  // Switch,
} from "@material-ui/core";
import { customBreakPointsComponents, exoticComponents, formComponentTypes } from "../../utils";
import {
  checkFirstZeroAndAllZero,
  getMobileLimitBasedOnCC,
  urlValidation,
  // checkLimit,
} from "../../utils/validations";
import { UploadProfileImage } from "../";
import PropTypes from "prop-types";
import {
  TextField,
  InputAdornmentComponent,
  SimpleSelect,
  RadioButtonsGroup,
  CheckboxCustom,
  CustomizedRadioButton,
} from "../inputs";
import { ButtonWithInputField } from "../buttonWithInputField";
import { SimpleList } from "../simpleList";
import { DateRangePicker } from "../datePicker";
import { errorMessage } from "../../utils/errorMessages";
import { Table } from "../table";
import Summary from "../summary/summary";
import { Recommended } from "../recommendedCard";
// import { DrawerComponent } from "../addContactDrawer";
import { DetailsCard } from "../detailCard";
import { SimpleLabel } from "../simpleLabel";
import { DateTimePickersCustom } from "../dateTimePicker";
import { Prompt, withRouter } from "react-router-dom";
import { CardWithTextAndCallBack } from "../cardWithTextAndCallBack";
import { AsyncPaginateSelect } from "../inputs/select/asyncPaginateSelect";
import { validateEmail } from "../../utils/validations";
import SearchLocationInput from "../map/inputFieldSearch/SearchLocationInput";
import { withAlertAndDialog } from "../../HOCs";
import MapDragabble from "../map/draggableMarker";
import { CustomizedToggleGroup } from "../inputs/radioButton/customizedToggleGroup";
import { Bmi } from "../bmiComponent";
import { ICNumberPassport } from "../../screens/userManagement/clients/addEdit/icNumberPassport";
import { ServiceConfiguration } from "../serviceConfiguration";
// import { parsePhoneNumber } from "libphonenumber-js";
import FromToDateTimePicker from "../fromToDatePicker";
import ActivityRecurrence from "../activityRecurrence";
import ActivityMode from "../activityMode";
import { SwitchComponent } from "../switch";
import ProductValidity from "../productValidity";
import SelectComponentWithSubComponent from "../SelectComponentWithSubComponent";
// import { components } from "../../screens/salesOperation/enquiry/addEdit/component";
import Experiencecard from "../../components/experience/experience_card";
import Certificationcard from "../../components/certification/certification_card";
// import AddressComponent from "../../components/SelectComponentWithSubComponent/addressComponent";
// import ViewCardDetails from "../../components/SelectComponentWithSubComponent/address/viewCard";
// import ViewCardDetailsEmail from "../../components/SelectComponentWithSubComponent/email/viewCard";
// import ViewCardDetailsMobile from "../../components/SelectComponentWithSubComponent/mobile/viewCard";
// import ViewCardDetailsIdType from "../../components/SelectComponentWithSubComponent/idType/viewCard";
import ModernViewAddressCardDetail from "../../components/SelectComponentWithSubComponent/address/modernViewCard";
import ModernViewEmailCardDetail from "../../components/SelectComponentWithSubComponent/email/modernViewCard";
import ModernViewMobileCardDetail from "../../components/SelectComponentWithSubComponent/mobile/modernViewCard";
import ModernViewIdCardDetail from "../../components/SelectComponentWithSubComponent/idType/modernViewCard";
import ModernViewTaxCardDetail from "../../components/SelectComponentWithSubComponent/tax/modernViewCard";
// import EmailComponent from "../../components/SelectComponentWithSubComponent/emailComponent";
// import PhoneComponent from "../../components/SelectComponentWithSubComponent/phoneComponent";
import RichTextEditor from "../richTextEditor";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";
// import { isValidPhoneNumber } from "libphonenumber-js";
// import { isValidPhoneNumber as phoneValidation} from "libphonenumber-js/mobile"
import countryCode from "../../utils/countryCode.json";
import ResourceValidity from "../resourceValidity";
import moment from 'moment'
import { isTrialAccountType } from "../../utils/common";

const style = (theme) => ({
  // "& .MuiPaper-elevation1": (props) => ({ boxShadow: !props.withBoxShadow ? 'none !important' : '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }),
  root: {
    boxShadow: (props) =>
      props.withoutBoxShadow
        ? "none !important"
        : "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    background: (props) =>
      props.withoutBoxShadow ? "transparent !important" : "white",
    "& .MuiSwitch-track": {
      background: theme.palette.secondary.main,
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      // "& .MuiSwitch-track":{
      background: "#80f10e",
      // }
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "white",
    },
  },
  container: {
    padding: "8px 22px 16px 22px",
  },
  item: {
    width: "100%",
  },
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
  saveBtnContainer: {
    width: "100%",
    textAlign: "end",
  },
  switch: {
    "& .MuiSwitch-track": {
      borderRadius: 13,
    },

    "& .MuiSwitch-root": { padding: 6 },
  },
  switchLabel: {
    color: theme.palette.secondary.main,
    textAlign: "right",
  },
  summaryCardLabel: {
    marginBottom: 15,
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
});

const setInitialState = (props) => {
  let initialState = {};
  let fieldsToValidate = [];
  let multipleFieldsToValidateWithRelation = {};
  //   Object.keys(props?.components).map((section) => {
  Object.values(props?.components).map((components) => {

    if (components?.key?.constructor === Object) {
      Object.values(components?.key).map((val) => {
        return (initialState = {
          ...initialState,
          [val]: components.value[val],
        });
      });
    } else {
      initialState = { ...initialState, [components.key]: components.value };
    }

    if (components.required) {

      if (components?.key?.constructor === Object) {
        Object.values(components.key)
          .filter((val) => components.element === "MOBILE_INPUT_BOX")
          .map((val) => {

            return fieldsToValidate.push({
              key: val,
              element: components.element,
              componentType: components?.componentProps?.element,
              mobilenumberISDcodeKey: components.key?.["select_key"],
              isFieldAPhoneNumber:
                val === components.key?.["input_value_key"] &&
                  components?.isFieldAPhoneNumber
                  ? components?.isFieldAPhoneNumber
                  : false,
            });
          });

          // Resource Validity
          Object.values(components.key)
            .filter((val) => components.element === formComponentTypes.RESOURCE_VALIDITY)
            .map((val) => {
              return fieldsToValidate.push({
                ...components
              });
            });
        // fromToDateTimePicker
        Object.values(components.key)
          .filter(
            () =>
              components.element === formComponentTypes.FROM_TO_DATE_TIME_PICKER
          )
          .map((val) => {
            return fieldsToValidate.push({
              key: val,
              element: components.element,
            });
          });
        Object.values(components.key)
          .filter(
            () => components.element === formComponentTypes.ACTIVITY_RECURRENCE
          )
          .map((val) => {
            return fieldsToValidate.push({
              key: val,
              element: components.element,
            });
          });
        Object.values(components.key)
          .filter(
            (val) => components.element === formComponentTypes.ACTIVITY_MODE
          )
          .map((val) => {
            return fieldsToValidate.push({
              key: val,
              element: components.element,
            });
          });
      } else {
        fieldsToValidate.push({
          key: components.key,
          element: components.element,
          componentType: components?.componentProps?.element,
          ...components,
        });
      }
    }
    // if (components.isMandatoryWithMultipleFieldsRelation) {
    //   multipleFieldsToValidateWithRelation[components.isMandatoryWithMultipleFieldsRelation] = multipleFieldsToValidateWithRelation[components.isMandatoryWithMultipleFieldsRelation] ? multipleFieldsToValidateWithRelation[components.isMandatoryWithMultipleFieldsRelation] : []
    //   if (components.key.constructor === Object) {
    //     Object.values(components.key)
    //       .filter((val) => components.type === "MOBILE_INPUT_BOX")
    //       .map((val) => {
    //         multipleFieldsToValidateWithRelation[components.isMandatoryWithMultipleFieldsRelation].push({
    //           key: val,
    //           type: components.type,
    //           componentType: components?.componentProps?.type,
    //           ...components,
    //         })
    //         return multipleFieldsToValidateWithRelation;
    //       });
    //   } else {

    //     multipleFieldsToValidateWithRelation[components.isMandatoryWithMultipleFieldsRelation].push({
    //       key: components.key,
    //       type: components.type,
    //       componentType: components?.componentProps?.type,
    //       ...components,
    //     })
    //   }
    // }
    return initialState;
  });
  // return components;
  //   });

  return {
    initialState,
    fieldsToValidate,
    multipleFieldsToValidateWithRelation,
  };
};

class DynamicForm extends React.Component {
  constructor(props) {
    super(props);
    let { initialState, fieldsToValidate } = setInitialState(props);
    this.state = {
      currentAllComponentState: {
        // validity_period: [null, null],
        ...initialState,
      },
      openDrawer: {
        open: false,
        component: {},
      },
      fieldsToValidate: fieldsToValidate,
      // multipleFieldsToValidateWithRelation:multipleFieldsToValidateWithRelation,
      error: {},
      isUrl: props.isUrl,
      isBlock: false,
      isErrorOccured: false,
      locationViaMap: "",
      timeDifferenceError: false,
    };
  }

  onChange = (key, value) => {
    const { components } = this.state;

    components[key].value = value;

    this.setState({
      components,
    });
  };

  // custom timerange
  update_time_slot_slot__from_to_time = (week, slotdata, key, time) => {
    let _obj = {
      id: slotdata.id,
      category_ids: slotdata.category_ids,
      timezone: slotdata.timezone,
      slot_from_time: time.startTime,
      slot_to_time: time.endTime,
    };
    console.log(_obj, "time range picker");
  };

  //UPLOAD PROFILE IMAGE
  updateImageDataInStateAndPreviewImage = (input, state) => {
    const { currentAllComponentState, error } = this.state;
    // currentAllComponentState[state] = input.target.files[0];

    error[state] = false;


    if (input) {
      if (input?.constructor === String) {

        currentAllComponentState[state] = input;
        this.props.dialog.setDialog({
          ...this.props.dialog,
          open: false,
          title: "Edit",
          body: "",
          positiveBtn: "Ok",
          negativeBtn: "CANCEL",
          dontShowButtons: true,
          onOk: () => { },
          onCancel: () => { },
        });
        this.setState({
          ...this.state,
          currentAllComponentState,
          isUrl: false,
          isBlock: true,
        });
      }
      else {
        const reader = new FileReader();

        reader.addEventListener(
          "load",
          async () => {
            // convert image file to base64 string
            console.log(reader.result, "base64url");
            currentAllComponentState[state] = await reader.result;
            this.setState({
              ...this.state,
              currentAllComponentState,
              isUrl: false,
              isBlock: true,
            });
          },
          false
        );

        if (input?.target?.files?.[0]) {
          reader.readAsDataURL(input?.target?.files?.[0]);
        }
      }
    }
    else {
      currentAllComponentState[state] = "";
      this.setState({
        ...this.state,
        currentAllComponentState,
        isUrl: false,
        isBlock: true,
      });
    }



  };

  dateValidation = (value, state, type, isFutureHideDate, isPastHideDate) => {
    let { currentAllComponentState, error, isErrorOccured } = this.state;
    isErrorOccured = false;
    var varDate = new Date(value); //dd-mm-YYYY
    var today = new Date();
    varDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (varDate) {
      if (isNaN(varDate.getTime())) {
        // d.valueOf() could also work
        // date is not valid
        currentAllComponentState[state] = varDate;
        error[state] = true;
        isErrorOccured = true;
        this.setState({
          ...this.state,
          error: error,
          isBlock: isErrorOccured,
          currentAllComponentState,
        });
      } else {
        if (isFutureHideDate && isPastHideDate) {
          if (varDate > today || varDate < today) {
            // date is valid
            currentAllComponentState[state] = varDate;
            error[state] = true;
            isErrorOccured = true;
            this.setState({
              ...this.state,
              error: error,
              isBlock: isErrorOccured,
              currentAllComponentState,
            });
          } else {
            // date is valid
            currentAllComponentState[state] = varDate;
            error[state] = false;
            this.setState({
              ...this.state,
              error: error,
              isBlock: isErrorOccured,
              currentAllComponentState,
            });
          }
        } else if (isFutureHideDate) {
          console.log(value);

          if (varDate.getFullYear() >= 1900) {
            if (varDate > today) {
              // date is valid
              currentAllComponentState[state] = varDate;
              error[state] = true;
              isErrorOccured = true;
              this.setState({
                ...this.state,
                error: error,
                isBlock: isErrorOccured,
                currentAllComponentState,
              });
            } else {
              // date is valid
              currentAllComponentState[state] = varDate;
              error[state] = false;
              this.setState({
                ...this.state,
                error: error,
                isBlock: isErrorOccured,
                currentAllComponentState,
              });
            }
          } else {
            currentAllComponentState[state] = varDate;
            error[state] = true;
            isErrorOccured = true;
            this.setState({
              ...this.state,
              error: error,
              isBlock: isErrorOccured,
              currentAllComponentState,
            });
          }
        } else if (isPastHideDate) {
          // var selelctedDate = new Date(value); //dd-mm-YYYY
          // var today = new Date();

          if (varDate < today) {
            // date is valid
            currentAllComponentState[state] = varDate;
            error[state] = true;
            isErrorOccured = true;
            this.setState({
              ...this.state,
              error: error,
              isBlock: isErrorOccured,
              currentAllComponentState,
            });
          } else {
            // date is valid
            currentAllComponentState[state] = varDate;
            error[state] = false;
            this.setState({
              ...this.state,
              error: error,
              isBlock: isErrorOccured,
              currentAllComponentState,
            });
          }
        } else {
          // date is valid
          currentAllComponentState[state] = varDate;
          error[state] = false;
          this.setState({
            ...this.state,
            error: error,
            isBlock: isErrorOccured,
            currentAllComponentState,
          });
        }
      }
    }
    return isErrorOccured;
  };

  //TEXT FIELD, INPUTADORNMENT, SIMPLE SELECT, DATE RANGE PICKER
  updateDataInState = (
    value,
    state,
    type,
    cleanUpKeys,
    limit,
    isDecimalCheckNeeded,
    isFutureHideDate,
    isPastHideDate,
    mobileISDCodeKey,
    component
  ) => {
    const { currentAllComponentState, error } = this.state;

    // if (state === component?.key?.["recurrence"]){
    // if (state === "recurrence") {
    //   ;
    //   let error = {
    //     ...this.state.error,
    //     from_timestamp: false,
    //     to_timestamp: false,
    //     daily_date_from: false,
    //     daily_date_to: false,
    //     daily_start_time: false,
    //     daily_end_time: false,
    //     weekly_date_range: false,
    //     weekly_start_time: false,
    //     weekly_end_time: false
    //   };

    //   this.setState({
    //     error
    //   });

    // this.setState((prevState) => {
    //   const {error} = this.state
    //   let finalError
    //   // const prevRecurrence = this.state.currentAllComponentState?.component?.key?.["recurrence"]
    //   const prevRecurrence = prevState.currentAllComponentState?.component?.key?.["recurrence"]
    //   if(prevRecurrence !== value){
    //     if(prevRecurrence === "DO NOT REPEAT" || prevRecurrence === "DAILY" || prevRecurrence === "WEEKLY"){
    //       finalError = {
    //         ...error,
    //         from_timestamp: false,
    //         to_timestamp: false,
    //         daily_date_from: false,
    //         daily_date_to: false,
    //         daily_start_time: false,
    //         daily_end_time: false,
    //         weekly_date_range: false,
    //         weekly_start_time: false,
    //         weekly_end_time: false,
    //       }
    //
    //     }
    //     // else if(prevRecurrence === "DAILY") {
    //     //   finalError = {
    //     //     ...error,
    //     //     daily_date_from: false,
    //     //     daily_date_to: false,
    //     //     daily_start_time: false,
    //     //     daily_end_time: false,
    //     //   }
    //     // } else if(prevRecurrence === "WEEKLY"){
    //     //   finalError = {
    //     //     ...error,
    //     //     weekly_date_range: false,
    //     //     weekly_start_time: false,
    //     //     weekly_end_time: false,
    //     //   }
    //     // }
    //
    //     return {
    //       error: finalError
    //     }
    //
    //   }
    // })
    // }

    if (Array.isArray(cleanUpKeys)) {
      cleanUpKeys.map((_) => {
        if (typeof currentAllComponentState[_] === typeof "") {
          currentAllComponentState[_] = "";
        } else if (Array.isArray(currentAllComponentState[_])) {
          currentAllComponentState[_] = [];
        } else if (typeof currentAllComponentState[_] === typeof {}) {
          currentAllComponentState[_] = {};
        }
        return _;
      });
    }

    if (type === "number" || type === "enquiryForm") {

      /* let isLimit = limit
        ? limit
        : getMobileLimitBasedOnCC(
            this.state.currentAllComponentState?.[mobileISDCodeKey]
          ); */
      if (isDecimalCheckNeeded) {
        const preDecimal = value.split(".")[0];
        const postDecimal = value.split(".")[1];
        const noFirstDigitZero = /^[^0]\d*/;
        if (value.length === 0) {
          currentAllComponentState[state] = value;
          error[state] = false;
          this.setState({
            ...this.state,
            currentAllComponentState,
            isBlock: true,
          });
        } else {
          if (noFirstDigitZero?.test(value)) {
            if (preDecimal.length <= 10) {
              if (postDecimal) {
                if (postDecimal.length <= 2) {
                  currentAllComponentState[state] = value;
                  error[state] = false;
                  this.setState({
                    ...this.state,
                    currentAllComponentState,
                    isBlock: true,
                  });
                } else {
                  // post decimal exceeds limit
                  return;
                }
              } else {
                currentAllComponentState[state] = value;
                error[state] = false;
                this.setState({
                  ...this.state,
                  currentAllComponentState,
                  isBlock: true,
                });
              }
            }
          } else {
            return;
          }
        }
      } else {
        /* if (value.length <= isLimit) {
          currentAllComponentState[state] = value;
          error[state] = false;
          this.setState({
            ...this.state,
            currentAllComponentState,
            isBlock: true,
          });
        } */
        //
        // console.log(this.state.currentAllComponentState)
        // if(Array.isArray(getMobileLimitBasedOnCC(this.state.currentAllComponentState?.[mobileISDCodeKey])))
        //   {
        //     console.log("INSIDE ARR")
        //     if(checkLimit(getMobileLimitBasedOnCC(this.state.currentAllComponentState?.[mobileISDCodeKey]), value) ||
        //     value.length <= getMobileLimitBasedOnCC(this.state.currentAllComponentState?.[mobileISDCodeKey])?.[1]  )
        //     {
        currentAllComponentState[state] = value;
        error[state] = false;
        this.setState({
          ...this.state,
          currentAllComponentState,
          isBlock: true,
        });
        //   }
        // }
        // else
        // {
        //   if (value.length <= isLimit)
        //   {
        //     currentAllComponentState[state] = value;
        //     error[state] = false;
        //     this.setState({
        //       ...this.state,
        //       currentAllComponentState,
        //       isBlock: true,
        //     });
        //   }
        // }
      }
    } else if (type === "array") {
      if (currentAllComponentState[state].indexOf(value) > -1) {
        let _array = currentAllComponentState[state];
        error[state] = false;
        currentAllComponentState[state] = _array.filter((val) => {
          return val !== value;
        });
      } else {
        error[state] = false;
        currentAllComponentState[state].push(value);
      }
      this.setState({ ...this.state, currentAllComponentState, isBlock: true });
    } else if (type === "date") {
      this.dateValidation(value, state, type, isFutureHideDate, isPastHideDate);
    } else if (state === "recurrence") {
      let finalerror = {
        from_timestamp: false,
        to_timestamp: false,
        daily_date_from: false,
        daily_date_to: false,
        daily_start_time: false,
        daily_end_time: false,
        weekly_date_range: false,
        weekly_start_time: false,
        weekly_end_time: false,
      };
      currentAllComponentState[state] = value;
      error[state] = false;
      this.setState({
        ...this.state,
        error: { ...error, ...finalerror },
        currentAllComponentState,
        isBlock: true,
      });
    } else if (state === "activity_mode") {
      let finalerror = {
        meeting_link: false,
        add_location: false,
      };
      currentAllComponentState[state] = value;
      error[state] = false;
      this.setState({
        ...this.state,
        error: { ...error, ...finalerror },
        currentAllComponentState,
        isBlock: true,
      });
    } else {
      currentAllComponentState[state] = value;
      error[state] = false;
      this.setState({ ...this.state, currentAllComponentState, isBlock: true });
    }
  };

  // ON SUBMIT VALIDATION
  handleValidation = () => {

    const { error, fieldsToValidate } = this.state;
    let isErrorOccured = this.state.isErrorOccured;
    const validators = (val) => {
      if (val.element === formComponentTypes.INPUT_BOX) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        } else if (val.elementType === "email") {
          if (!validateEmail(this.state.currentAllComponentState[val.key])) {
            error[val.key] = true;
            isErrorOccured = true;
          }
        } else if (val?.componentProps?.isUrlValidation) {
          if (!urlValidation(this.state.currentAllComponentState[val.key])) {
            error[val.key] = true;
            isErrorOccured = true;
          }
        }
      }
      if (val.element === formComponentTypes.PROFILE_UPLOAD_BUTTON) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }
      if (val.element === formComponentTypes.SELECT_BOX) {

        if (
          val?.isMulti
            ? !this?.state?.currentAllComponentState?.[val.key]?.length
            : !this?.state?.currentAllComponentState?.[val.key]?.value
        ) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }

      if (val.element === formComponentTypes.DATE_RANGE_PICKER) {
        if (!this.state.currentAllComponentState[val.key][0]) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }
      if (val.element === formComponentTypes.RADIO_BUTTON) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }

      if (val.element === formComponentTypes.SELECT_BOX_WITH_PAGINATION) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        }
        if (Array.isArray(this.state.currentAllComponentState[val.key])) {
          if (this.state.currentAllComponentState[val.key].length === 0) {
            error[val.key] = true;
            isErrorOccured = true;
          }
        } else if (!this.state.currentAllComponentState[val.key].value) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }
      if (val.element === formComponentTypes.DATE_TIME_PICKER) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        } else {
          if (val.isDate) {
            // isErrorOccured = true;
            isErrorOccured = this.dateValidation(
              this.state.currentAllComponentState[val.key],
              val.key,
              "date",
              val.componentProps.isHideFutureDate,
              val.componentProps.isHidePastDate
            );
          }
        }
      }
      if (val.element === formComponentTypes.CUSTOMIZED_RADIO_BUTTON) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }
      if (val.element === formComponentTypes.CHECK_BOX) {
        if (!this.state.currentAllComponentState[val.key].length) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }
      // if (val.element === formComponentTypes.DATE_TIME_PICKER) {
      //   if (!this.state.currentAllComponentState[val.key]) {
      //     error[val.key] = true;
      //     isErrorOccured = true;
      //   }
      // }
      if (val.element === formComponentTypes.ICNUMBERPASSPORT) {
        let obj = Object.keys(
          this.state.currentAllComponentState[val.key]
        ).filter((val) => {
          return this.state.currentAllComponentState.ic_passport[val];
        });
        if (!(obj.length > 0)) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }
      if (val.element === formComponentTypes.MOBILE_INPUT_BOX) {

        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        } else {
          if (val.isFieldAPhoneNumber) {
            // const limit = getMobileLimitBasedOnCC(
            //   this.state.currentAllComponentState?.[val?.mobilenumberISDcodeKey]
            // );
            console.log(this.state)
            console.log(">>>>>>>>>>--------------<<<<<<<<<<<<<<<<<<<<<<<<")
            const selectedCountry = countryCode?.filter(
              (cc) => cc.dial_code === this.state.currentAllComponentState?.[val?.mobilenumberISDcodeKey]
            );
            const ISO2Code = selectedCountry?.[0]?.code;
            // const isNumberValid = this.state.currentAllComponentState[val.key].length === limit && parsePhoneNumber(`${this.state.currentAllComponentState?.[val?.mobilenumberISDcodeKey]}${this.state.currentAllComponentState?.[val?.key]}`)?.isValid()
            if (
              // this.state.currentAllComponentState[val.key].length < limit ||
              // this.state.currentAllComponentState[val.key].length > limit ||
              // (this.state.currentAllComponentState[val.key].length === limit &&
              //   checkFirstZeroAndAllZero(
              //     this.state.currentAllComponentState?.[val?.key]
              //   ))

              // !checkLimit(limit, this.state.currentAllComponentState?.[val?.key])

              !isValidPhoneNumber(this.state.currentAllComponentState?.[val?.key].toString(), ISO2Code?.toString())
            ) {
              //|| (this.state.currentAllComponentState[val.key].length === limit && !isNumberValid)
              error[val.key] = true;
              isErrorOccured = true;
            }
          }
        }
      }
      if (val.element === formComponentTypes.FROM_TO_DATE_TIME_PICKER) {
        if (!this.state.currentAllComponentState[val.key]) {
          error[val.key] = true;
          isErrorOccured = true;
        }
      }

      // Location field validation
      if (val.element === formComponentTypes.GOOGLE_MAP_AUTOCOMPLETE) {
        const field = this.state.currentAllComponentState[val.key];
        if (!field) {
          error[val.key] = true;
          isErrorOccured = true;
        } else if (field) {
          if (typeof field !== "object") {
            error[val.key] = true;
            isErrorOccured = true;
          } else if (typeof field === "object") {
            if (
              !field.hasOwnProperty("latitude") &&
              !field.hasOwnProperty("longitude")
            ) {
              error[val.key] = true;
              isErrorOccured = true;
            }
          }
        }
      }

      //Recurrence Validation
      if (val.element === formComponentTypes.ACTIVITY_RECURRENCE) {
        if (val.key === "recurrence") {
          if (!this.state.currentAllComponentState[val.key]) {
            error[val.key] = true;
            isErrorOccured = true;
          }
        }
        if (val.key === "from_timestamp" || val.key === "to_timestamp") {
          if (!this.state.currentAllComponentState[val.key]) {
            if (
              this.state.currentAllComponentState["recurrence"] ===
              "DO NOT REPEAT"
            ) {
              error[val.key] = true;
              isErrorOccured = true;
            } else {
              error[val.key] = false;
            }
          }
        }
        if (
          val.key === "daily_date_from" ||
          val.key === "daily_date_to" ||
          val.key === "daily_start_time" ||
          val.key === "daily_end_time"
        ) {
          if (!this.state.currentAllComponentState[val.key]) {
            if (this.state.currentAllComponentState["recurrence"] === "DAILY") {
              error[val.key] = true;
              isErrorOccured = true;
            } else {
              error[val.key] = false;
            }
          }
        }
        if (
          val.key === "repeat_on" ||
          val.key === "weekly_date_range" ||
          val.key === "weekly_start_time" ||
          val.key === "weekly_end_time"
        ) {
          if (this.props.activityEdit) {
            if (val.key === "repeat_on") {
              error[val.key] = false;
              isErrorOccured = false;
            }
          } else {
            if (
              !this.state.currentAllComponentState[val.key] ||
              (val.key === "weekly_date_range" &&
                this.state.currentAllComponentState[val.key]?.[0] === "" &&
                this.state.currentAllComponentState[val.key]?.[1] === "")
            ) {
              if (
                this.state.currentAllComponentState["recurrence"] === "WEEKLY"
              ) {
                error[val.key] = true;
                isErrorOccured = true;
              } else {
                error[val.key] = false;
              }
            }
          }
        }
      }

      if (val.element === formComponentTypes.ACTIVITY_MODE) {
        if (val.key === "activity_mode") {
          if (!this.state.currentAllComponentState[val.key]) {
            error[val.key] = true;
            isErrorOccured = true;
          }
        }
        if (val.key === "meeting_link") {
          if (!this.state.currentAllComponentState[val.key]) {
            if (
              this.state.currentAllComponentState["activity_mode"] ===
              "VIDEO_CALL"
            ) {
              error[val.key] = true;
              isErrorOccured = true;
            } else {
              error[val.key] = false;
            }
          }
        }
        if (val.key === "add_location") {
          if (!this.state.currentAllComponentState[val.key]) {
            if (
              this.state.currentAllComponentState["activity_mode"] ===
              "IN_PERSON"
            ) {
              error[val.key] = true;
              isErrorOccured = true;
            } else {
              error[val.key] = false;
            }
          }
        }
      }

      if (val.element === formComponentTypes.RESOURCE_VALIDITY) {
        // If Start Date is null
        if (!this.state.currentAllComponentState[val?.key?.["startDate"]]) {
          error[val?.key?.["startDate"]] = true;
          isErrorOccured = true;
        }

        // If End Date is null
        if (isTrialAccountType() && !this.state.currentAllComponentState[val?.key?.["endDate"]]) {
          error[val?.key?.["endDate"]] = true;
          isErrorOccured = true;
        }

        // If start date is less than the end date
        if(this.state.currentAllComponentState[val?.key?.["startDate"]] && this.state.currentAllComponentState[val?.key?.["endDate"]]){
          if(moment(this.state.currentAllComponentState[val?.key?.["startDate"]]).isAfter(moment(this.state.currentAllComponentState[val?.key?.["endDate"]]))){
            error[val?.key?.["endDate"]] = true;
            isErrorOccured = true;
          }
        }
      }
    };
    fieldsToValidate.map((val) => {
      validators(val);
      return val;
    });

    //Time Difference Error
    let tdError = false;
    Object.keys(this.state.timeDifferenceError).map((x) => {
      if (this.state.timeDifferenceError[x] === true) {
        tdError = true;
      }
      return x;
    });

    this.setState({
      error: error,
      isBlock: isErrorOccured,
      isErrorOccured: isErrorOccured && tdError,
    });
    return isErrorOccured;
  };

  //On Save
  onSaveButtonClicked = () => {
    //
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
    console.log(this.state.currentAllComponentState)
    this.props.onSaveButtonClicked(
      this.handleValidation(),
      this.state.currentAllComponentState,
      this.state.isUrl
    );
  };

  toggleDrawer = (bool, component, callback) => {
    const { openDrawer } = this.state;
    const currentState = openDrawer;
    currentState["open"] = bool;
    currentState["component"] = component;
    currentState["callback"] = callback;
    this.setState({ openDrawer: currentState });
  };

  handleOnOkModal = () => {
    // console.log("okay", this.state.locationViaMap);
    let _obj = {};
    _obj["address"] = this.state.locationViaMap?.address;
    _obj["latitude"] = this.state.locationViaMap?.lat;
    _obj["longitude"] = this.state.locationViaMap?.lng;
    const { currentAllComponentState } = this.state;
    currentAllComponentState["add_location"] = _obj;
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: false,
      title: "",
      body: "",
      positiveBtn: "SAVE",
      negativeBtn: "CANCEL",
      onOk: this.handleOnOkModal,
      onCancel: this.handleOnClose,
    });
  };

  handleOnClose = () => {
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: false,
      title: "",
      body: "",
      positiveBtn: "SAVE",
      negativeBtn: "CANCEL",
      onOk: this.handleOnOkModal,
      onCancel: this.handleOnClose,
    });
  };
  openDialogMap = () => {
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: true,
      title: <div>Find Location</div>,
      body: (
        <div style={{ width: "100%", height: "500px" }}>
          <MapDragabble
            getState={(data) => {
              this.setState({ locationViaMap: data });
            }}
            address={this.state.locationViaMap.address}
          />
        </div>
      ),
      positiveBtn: "SAVE",
      negativeBtn: "CANCEL",
      onOk: this.handleOnOkModal,
      onCancel: this.handleOnClose,
    });
  };

  /**
   *
   * @param {object} component To send select key state value to the getMobileLimitBasedOnCC function to retrieve limit for the mobile field.
   * @returns {number} Limit based on the selected mobileISDcode to check for errors.
   */
  getLimit = (component) => {
    const limit = getMobileLimitBasedOnCC(
      this.state.currentAllComponentState?.[component?.key["select_key"]]
    );
    //
    return limit;
  };

  // getMobileNumberValidity = (component) => {
  //   //
  //     if(this.state.currentAllComponentState?.[component?.key["select_key"]] && this.state.currentAllComponentState?.[component?.key["input_value_key"]])
  //     {
  //       const isNumberValid = parsePhoneNumber(`${this.state.currentAllComponentState?.[component?.key["select_key"]]}${this.state.currentAllComponentState?.[component?.key["input_value_key"]]}`)?.isValid()
  //       return isNumberValid
  //     }
  //   }

  getErrorTextForMobileInputBox = (component) => {
    // console.log(this.state.currentAllComponentState?.[component.key["input_value_key"]])
    // console.log(this.state.currentAllComponentState?.[component.key["input_value_key"]])
    //
    if (
      this.state.currentAllComponentState[component?.key["input_value_key"]]
    ) {
      /*{
        if (
          this.state.currentAllComponentState?.[component?.key["input_value_key"]]
            ?.length < this.getLimit(component)
        ) {
          return errorMessage.mobileNoLimitMessage(this.getLimit(component));
        } else if (
          this.state.currentAllComponentState?.[component?.key["input_value_key"]]
            ?.length > this.getLimit(component)
        ) {
          return errorMessage.mobileNoExceedsMessage(this.getLimit(component));
        }  */
      /* if(!checkLimit(getMobileLimitBasedOnCC(this.state.currentAllComponentState?.["mobileISDCodeKey"]), this.state.currentAllComponentState?.[component?.key["input_value_key"]]))
      {
        console.log("INSIDE")
        if(Array.isArray(this.getLimit(component)))
        {
          return errorMessage.mobileFixedLimitMessage(this.getLimit(component))
        }
        else
        {
          if(
            this.state.currentAllComponentState?.[component?.key["input_value_key"]]
              ?.length < this.getLimit(component)
          )
          {
            return errorMessage.mobileNoLimitMessage(this.getLimit(component));
          } else if (
            this.state.currentAllComponentState?.[component?.key["input_value_key"]]
              ?.length > this.getLimit(component)
          ) {
            return errorMessage.mobileNoExceedsMessage(this.getLimit(component));
          }
        }

      } */
      const selectedCountry = countryCode?.filter(
        (cc) => cc.dial_code === this.state.currentAllComponentState?.["mobileISDCodeKey"]
      );
      const ISO2Code = selectedCountry?.[0]?.code;

      console.log(this.state.currentAllComponentState)
      if (!isValidPhoneNumber(this.state.currentAllComponentState?.[component?.key["input_value_key"]].toString(), ISO2Code?.toString())) {
        return errorMessage.mobileNoValidMessage;
      }

      else {
        // console.log("CHECKING LENGTH", this.state.currentAllComponentState?.[component.key["input_value_key"]]?.length === this.getLimit(component))
        // Handle a valid mobile number error message.
        // if(!this.getMobileNumberValidity()){
        //     return errorMessage.mobileNoValidMessage
        //   }

        //Check first digit zero and all digit zero
        let value = this.state.currentAllComponentState?.[
          component?.key["input_value_key"]
        ];
        if (!checkFirstZeroAndAllZero(value)) {
          return errorMessage.mobileNoValidMessage;
        }
      }
    } else {
      return errorMessage.requiredMessage;
    }
  };

  getLocationErrorMessage = (component, optionalKeySpecification) => {
    const field = optionalKeySpecification
      ? this.state.currentAllComponentState[optionalKeySpecification]
      : this.state.currentAllComponentState[component.key];
    if (!field) {
      return errorMessage.requiredMessage;
    } else if (field) {
      if (typeof field !== "object") {
        return errorMessage.locationInvalid;
      } else if (typeof field === "object") {
        if (
          !field.hasOwnProperty("latitude") &&
          !field.hasOwnProperty("longitude")
        ) {
          return errorMessage.locationInvalid;
        }
      }
    }
  };

  inputBoxErrorMessagesWithType = (value, componentProps) => {
    if (value) {
      if (componentProps?.elementType === "email") {
        return errorMessage.emailMessage;
      } else if (componentProps?.componentProps?.isUrlValidation) {
        return errorMessage.validUrl;
      } else {
        return errorMessage.requiredMessage;
      }
    } else {
      return errorMessage.requiredMessage;
    }
  };

  //   updateMobileNumberState = (value, state, type) =>{
  //
  //     if(value){
  //       let current_state = this.state.currentAllComponentState[state]
  //       if(type === "countryCode"){
  //         let getCountcode = current_state.split(' ')
  //         if(getCountcode.length > 1){
  //           getCountcode[0] = value
  //         }
  //         else{

  // return value
  //         }
  //       }
  //       else{
  //         let getCountcode = current_state.split(' ')
  //         if(getCountcode.length > 1){

  //         }
  //         else{

  // return value
  //         }
  //       }
  //     }
  //   }

  giveMeTheComponent = (component) => {
    switch (component.element) {
      case formComponentTypes.PROFILE_UPLOAD_BUTTON:
        return (
          <UploadProfileImage
            value={
              this.state.isUrl
                ? ""
                : this.state.currentAllComponentState[component.key]
            }
            label={component?.componentProps?.label}
            profile_pic={this.state.isUrl ? component.value : ""}
            handleFileChange={(e) => {
              this.updateImageDataInStateAndPreviewImage(e, component.key);
            }}
            id={component?.id}
            error={this.state.error[component.key] ? true : false}
            errorText={errorMessage.requiredMessage}
          />
        );

      case formComponentTypes.INPUT_BOX:

        if (!component.isDontShow) {
          return (
            <TextField
              id={component?.id}
              required={component?.required || component?.isMandatory}
              type={component.componentProps.type}
              withBoldLabel={component.withBoldLabel}
              withoutUppercase={component?.withoutUppercase}
              titleHelper={component?.componentProps?.titleHelper}
              onlyText={component?.onlyText}
              limit={component?.componentProps?.limit}
              state={component.key}
              isLimitHelperTextNeeded={
                component?.componentProps?.isLimitHelperTextNeeded
              }
              overrideDecimalCheckForDiscount={
                component?.componentProps?.overrideDecimalCheckForDiscount
              }
              rowsMax={
                component.componentProps.rowsMax
                  ? component.componentProps.rowsMax
                  : 1
              }
              multiline={component.componentProps.multiline}
              title={component.label}
              placeholder={
                component.componentProps.placeholder
                  ? component.componentProps.placeholder
                  : ""
              }
              defaultValue=""
              value={this.state.currentAllComponentState[component.key]}
              mandatory={component?.required}
              handleOnChange={(e, limit) => {
                const { isDecimalCheckNeeded } = component;
                if (component?.onCallback) {
                  component.onCallback(e.target.value, component.key);
                }
                this.updateDataInState(
                  e.target.value,
                  component.key,
                  component?.componentProps?.type,
                  () => { },
                  limit,
                  isDecimalCheckNeeded
                );
              }}
              error={this.state.error[component.key] ? true : false}
              errorText={
                this.inputBoxErrorMessagesWithType(
                  this.state.currentAllComponentState[component.key],
                  component
                )
                // this.state.currentAllComponentState[component.key]
                //   ? component?.elementType === "email"
                //     ? errorMessage.emailMessage
                //     : ""
                //   : errorMessage.requiredMessage
              }
              disabled={component?.componentProps?.disabled}
            />
          );
        } else {
          return null;
        }
      case formComponentTypes.ICNUMBERPASSPORT:
        if (!component.isDontShow) {
          return (
            <ICNumberPassport
              id={component?.id}
              type={component.componentProps.type}
              withBoldLabel={component.withBoldLabel}
              withoutUppercase={component?.withoutUppercase}
              onlyText={component?.onlyText}
              limit={component.limit}
              state={component.key}
              rowsMax={
                component.componentProps.rowsMax
                  ? component.componentProps.rowsMax
                  : 1
              }
              multiline={component.componentProps.multiline}
              title={component.label}
              placeholder={
                component.componentProps.placeholder
                  ? component.componentProps.placeholder
                  : ""
              }
              defaultValue=""
              key={component.key}
              ic_label="I/C NUMBER"
              passport_label="PASSPORT NUMBER"
              value={this.state.currentAllComponentState[component.key]}
              handleOnChange={(value, key, type, limit) => {
                let { currentAllComponentState } = this.state;
                currentAllComponentState.ic_passport[key] = value;
                this.updateDataInState(
                  currentAllComponentState.ic_passport,
                  "ic_passport",
                  type,
                  () => { },
                  limit
                );
              }}
              error={this.state.error[component.key] ? true : false}
              errorText={errorMessage.icNumberPassport}
              passport_disabled={component.passport_disabled}
              ic_disabled={component.ic_disabled}
            />
          );
        } else {
          return null;
        }
      case formComponentTypes.MOBILE_INPUT_BOX:

        return (
          <InputAdornmentComponent
            required={component?.required || component?.isMandatory}
            id={component?.componentProps?.id}
            select_id={component?.componentProps?.select_id}
            title={component.label}
            options={component.options}
            type={component?.componentProps?.type}
            onlyText={component?.onlyText}
            disabled={component?.disabled}
            customsize={component?.componentProps?.customsize}
            helperFlagNeeded={component?.componentProps?.helperFlagNeeded}
            helperFlagControllers={
              component?.componentProps?.helperFlagControllers
            }
            // allScreenCustomSize={component?.componentProps?.allScreenCustomSize}
            // allScreenCustomSize={{
            //   xs: 6,
            //   sm: 6,
            //   md: 6,
            //   lg: 6,
            //   xl: 6
            // }}
            valueMObileNo={
              this.state.currentAllComponentState[
              component.key["input_value_key"]
              ]
            }
            valuePhoneCode={
              this.state.currentAllComponentState[component.key["select_key"]]
            }
            handleChangeSelect={(e) => {

              this.updateDataInState(
                e,
                component.key["select_key"],
                null,
                component?.isComponentAMobileField
                  ? [component?.key?.["input_value_key"]] ?? null
                  : null,
                null,
                null,
                null,
                null,
                component?.key["select_key"]
              );
            }}
            handleMobileNo={(e) => {
              this.updateDataInState(
                e.target.value,
                component.key["input_value_key"],
                component?.componentProps?.type,
                null,
                null,
                null,
                null,
                null,
                component?.key["select_key"]
              );
            }}
            error={
              this.state.error[component.key["input_value_key"]] ? true : false
            }
            errorText={this.getErrorTextForMobileInputBox(component)}
          />
        );

      case formComponentTypes.SELECT_BOX:
        if (component.isDontShow) {
          return <></>;
        } else {
          return (
            <SimpleSelect
              {...component}
              title={component.label}
              label={component.label}
              disabled={component?.isDisabled}
              isMulti={component.isMulti}
              options={component.options}
              isLoading={component.isLoading}
              hideCustomValueContainerHeight={
                component?.componentProps?.hideCustomValueContainerHeight
              }
              value={this.state.currentAllComponentState[component.key]}
              handleOnChange={(e) => {
                // if (component.onSelectedCallback) {
                //   component.onSelectedCallback(
                //     e,
                //     this.state.currentAllComponentState
                //   );
                // }
                if (component?.requiredAction) {
                  this.props.dropDownCallBacks(
                    e,
                    component?.key,
                    this.props.components,
                    this.state.currentAllComponentState
                  );
                }
                this.updateDataInState(
                  e,
                  component.key,
                  component.type,
                  component.cleanUpKeys
                );
              }}
              error={this.state.error[component.key] ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          );
        }
      case formComponentTypes.SELECT_BOX_WITH_PAGINATION:
        return (
          <AsyncPaginateSelect
            {...component}
            title={component.label}
            label={component.label}
            id={component?.id}
            isMulti={component.isMulti}
            isDisabled={component.isDisabled}
            loadOptions={component.options}
            cacheUniqs={component.cacheUniqs}
            // options={component.options}
            value={this.state.currentAllComponentState[component.key]}
            handleOnChange={(e) => {
              if (component.onSelectedCallback) {
                component.onSelectedCallback(
                  e,
                  this.state.currentAllComponentState
                );
              }
              this.updateDataInState(
                e,
                component.key,
                component.type,
                component.cleanUpKeys
              );
            }}
            error={this.state.error[component.key] ? true : false}
            errorText={errorMessage.requiredMessage}
          />
        );

      case formComponentTypes.DATE_RANGE_PICKER:
        if (component.isDontShow) {
          return <></>;
        } else {
          return (
            <DateRangePicker
              id={component?.id}
              required={component?.required || component?.isMandatory}
              isHidePastDate={component?.componentProps?.isHidePastDate}
              isHideFutureDate={component?.componentProps?.isHideFutureDate}
              value={this.state.currentAllComponentState[component.key]}
              label={component?.label}
              handleDateRange={(e) => {
                this.updateDataInState(e, component.key, component.type);
              }}
              error={this.state.error[component.key] ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          );
        }
      case formComponentTypes.BUTTON:
        return (
          <Button id={component?.id} variant={"contained"} color={"primary"}>
            {component.label}
          </Button>
        );

      case formComponentTypes.LIST:
        return (
          <>
            {component.value.map((v, i) => (
              <div key={i}>
                <SimpleList title={v.title} />
              </div>
            ))}
          </>
        );
      case formComponentTypes.TABLE:
        return (
          <Table
            id={component?.id}
            columns={component.columns}
            rows={component.rows}
            state={component.key}
            disableRemove={component.disableRemove}
            handleRemove={(s) => {
              component.handleRemove(s);
              if (component.removeUpdateKey) {
                const _ = this.state.currentAllComponentState[
                  component.removeUpdateKey
                ].filter((_) => _.value !== s.value);
                let currentAllComponentState = this.state
                  .currentAllComponentState;
                currentAllComponentState[component.removeUpdateKey] = _;
                this.setState({
                  currentAllComponentState,
                });
              }
            }}
            withoutRemove={component?.withoutRemove ? true : false}
            discount={component.discount}
            total={component.total}
            onDiscountChange={component.onDiscountChange}
            onTotalChange={component.onDiscountChange}
            withDiscount={component.withDiscount}
            withTotal={component.withTotal}
            onTextFieldChange={component.onTextFieldChange}
            needActionsAsIcons={component.needActionsAsIcons}
            editAction={component.editAction}
            deleteAction={component.deleteAction}
            colWidths={component?.componentProps?.colWidths}
            handlePopperOpen={component?.handlePopperOpen}
            handlePopperClose={component?.handlePopperClose}
            popperData={component?.popperData}
            openPopper={component?.openPopper}
          />
        );
      case formComponentTypes.BUTTON_WITH_INPUT_FIELD:
        return (
          <ButtonWithInputField
            title={component.label}
            id={component?.id}
            arrOfValues={this.state.currentAllComponentState[component.key]}
            buttonValue={component.buttonLabel}
            componentState={component.key}
            removeListFunc={this.removeItemFromAnArrayCommonFunction}
            error={this.state.error[component.key] ? true : false}
            errorMessage={errorMessage.requiredMessage}
            handleButtonClick={(state) => {
              if (state) {
                let oldState =
                  this.state?.currentAllComponentState?.[component.key]
                    ?.length > 0
                    ? this.state.currentAllComponentState[component.key]
                    : [];
                oldState = [...oldState, state];
                let currAllCS = this.state.currentAllComponentState;
                currAllCS[component.key] = oldState;
                this.setState({
                  currentAllComponentState: currAllCS,
                });
              }
            }}
          />
        );
      case formComponentTypes.SUMMARY_CARD:
        const summaryData = this.state.currentAllComponentState[
          component.value
        ];

        const provider = this.state.currentAllComponentState.select_provider;
        const category = this.state.currentAllComponentState.select_category;

        return (
          <>
            {summaryData &&
              ((summaryData?.constructor === Array && summaryData?.length > 0) ||
                (summaryData?.constructor === Object &&
                  Object.keys(summaryData).length > 0)) ? (
              <>
                <Typography
                  variant="div"
                  component="div"
                  className={this.props.classes.summaryCardLabel}
                >
                  Summary
                </Typography>
                {summaryData?.constructor === Array ? (
                  summaryData.map((val) => {
                    return (
                      <div style={{ marginBottom: 10 }}>
                        <Summary
                          profile_pic={val.profile_pic}
                          withTextAvatar={component?.withTextAvatar}
                          item={val.label}
                          total={`${val.currency ? val.currency : "RM"
                            } ${parseFloat(val.amount)?.toFixed(2)} ${val.term ? `(${val.term})` : ""
                            }`}
                          packageCount={val.package_count}
                          servicesCount={val.service_count}
                          provider={provider.label}
                          category={category.label}
                        />
                      </div>
                    );
                  })
                ) : (
                  <>
                    {Object.keys(
                      this.state.currentAllComponentState[component.value]
                    ).length > 0 ? (
                      <Summary
                        profile_pic={summaryData.profile_pic}
                        item={summaryData.label}
                        total={`${summaryData.currency ? summaryData.currency : "RM"
                          } ${summaryData.amount} ${summaryData.term ? `(${summaryData.term})` : ""
                          }`}
                        packageCount={summaryData?.package_count}
                        servicesCount={summaryData?.service_count}
                        provider={provider?.label}
                        category={category?.label}
                      />
                    ) : null}
                  </>
                )}
              </>
            ) : null}
          </>
        );

      case formComponentTypes.RECOMMENDATIONS_CARD:
        const item = this.state.currentAllComponentState.select_an_item;
        const value = this.state.currentAllComponentState?.select_caregiver
          ?.value;

        return (
          <>
            {item && (
              <Recommended
                data={component.data}
                id={component?.id}
                loading={component.loading}
                error={component.error}
                value={value}
                onClick={(val) => {
                  if (component.onSelectedCallback) {
                    component.onSelectedCallback(
                      val,
                      this.state.currentAllComponentState
                    );
                  }
                  this.updateDataInState(
                    val,
                    component.value,
                    formComponentTypes.SELECT_BOX,
                    component.cleanUpKeys
                  );
                }}
              />
            )}
          </>
        );
      case formComponentTypes.ADD_CONTACT_DRAWER:
        if (component.isDontShow) {
          return <></>;
        } else {
          return (
            <SimpleLabel
              callback={component.callback}
              id={component?.id}
              component={component}
              isError={component.contactCallerError}
            />
            // toggleDrawer={this.toggleDrawer}
          );
        }
      case formComponentTypes.DETAILS_CARD:
        if (component.isDontShow) {
          return <></>;
        } else {
          return (
            <DetailsCard
              component={component}
              id={component?.id}
              callback={component?.callback}
              editcomponent={component?.editcomponent}
              isDontShowEdit={component?.isEdit}
            />
            // toggleDrawer={this.toggleDrawer}
          );
        }
      case formComponentTypes.RADIO_BUTTON:
        return (
          <RadioButtonsGroup
            data={component}
            id={component?.id}
            value={this.state.currentAllComponentState[component.key]}
            handleOnChange={(value, key) => {
              if (component?.onChangeCallBack) {
                component.onChangeCallBack(value, key);
              }
              this.updateDataInState(value, key);
            }}
            error={this.state.error[component.key] ? true : false}
            errorText={errorMessage.requiredMessage}
          />
        );
      case formComponentTypes.CUSTOMIZED_RADIO_BUTTON:
        return (
          <CustomizedRadioButton
            data={component}
            id={component?.id}
            value={this.state.currentAllComponentState[component.key]}
            handleOnChange={(value, key) => {
              if (component?.onChangeCallBack) {
                component.onChangeCallBack(value, key);
              }
              this.updateDataInState(value, key);
            }}
            error={this.state.error[component.key] ? true : false}
            errorText={errorMessage.requiredMessage}
          />
        );
      case formComponentTypes.CUSTOMIZED_TOGGLE_GROUP:
        return (
          <CustomizedToggleGroup
            data={component}
            id={component?.id}
            value={this.state.currentAllComponentState[component.key]}
            handleOnChange={this.updateDataInState}
            error={this.state.error[component.key] ? true : false}
            errorText={errorMessage.requiredMessage}
          />
        );
      case formComponentTypes.CHECK_BOX:
        if (component?.isHide) {
          return <></>;
        } else {
          return (
            <CheckboxCustom
              data={component}
              id={component?.id}
              isHide={component?.isHide}
              value={this.state.currentAllComponentState[component.key]}
              handleOnChange={(e) => {
                const value =
                  this.state.currentAllComponentState[component.key].indexOf(
                    e.target.value
                  ) > -1
                    ? false
                    : true;
                // val.value, data.key, "array"
                if (component.onChangeCallBack) {
                  component.onChangeCallBack(
                    value,
                    component.key,
                    e.target.value,
                    this.state.currentAllComponentState[component.key]
                  );
                }
                this.updateDataInState(e.target.value, component.key, "array");
              }}
              error={this.state.error[component.key] ? true : false}
              errorText={errorMessage.requiredMessage}
            />
          );
        }
      // IF  YOU ARE USING DATATIME PICKER FOR ADD FORM-
      // KEEP INITIAL VALUE AS **NULL** not as **EMPTY STRING**
      case formComponentTypes.DATE_TIME_PICKER:
        return (
          <DateTimePickersCustom
            required={component?.required || component?.isMandatory}
            state={component.key}
            id={component?.id}
            isEighteenYearsOld={component?.componentProps?.isEighteenYearsOld ? true : false}
            onAccept={component?.handleOnAccept}
            isDate={component?.componentProps?.isDate}
            isHideFutureDate={component?.componentProps?.isHideFutureDate}
            isHidePastDate={component?.componentProps?.isHidePastDate}
            inputFormat={component.inputFormat}
            maxDate
            minDate={component?.minDate ? component.minDate : null}
            isDateTime={component.isDateTime}
            label={component.label}
            error={this.state.error[component.key] ? true : false}
            disabled={component?.componentProps?.isDisable}
            errorText={
              component.isDate
                ? this.state.currentAllComponentState[component.key]
                  ? component?.componentProps?.isHideFutureDate &&
                    component?.componentProps?.isHidePastDate
                    ? errorMessage.pastFutureDateMessage
                    : component?.componentProps?.isHideFutureDate
                      ? new Date(
                        this.state.currentAllComponentState[component.key]
                      ).getFullYear() >= 1900
                        ? errorMessage.futureDateMessage
                        : errorMessage.validateyears
                      : component?.componentProps?.isHidePastDate
                        ? errorMessage.pastDateMessage
                        : errorMessage.validDate
                  : errorMessage.validDate
                : errorMessage.requiredMessage
            }
            value={this.state.currentAllComponentState[component.key]}
            handleOnChange={(newValue) => {
              if (component.onChangeCallBack) {
                component.onChangeCallBack(newValue, component.key);
              }
              this.updateDataInState(
                newValue,
                component.key,
                component?.isDate ? "date" : "",
                component.cleanUpKeys,
                null,
                null,
                component?.componentProps?.isHideFutureDate,
                component?.componentProps?.isHidePastDate
              );
            }}
          />
        );

      case formComponentTypes.CARD_WITH_TEXT_AND_CALLBACK:
        if (component.isShowAvailabilities) {
          return (
            <CardWithTextAndCallBack
              data={component}
              id={component?.id}
              parentState={{ ...this.state }}
            />
          );
        } else {
          return null;
        }
      case formComponentTypes.GOOGLE_MAP_AUTOCOMPLETE:
        return (
          <SearchLocationInput
            handleOnChange={(newValue) => {
              this.updateDataInState(newValue, component.key);
            }}
            label={component.label}
            value={this.state.currentAllComponentState[component.key]}
            openDialogMap={this.openDialogMap}
            showLocationViaMaps={component.componentProps?.showLocationViaMaps}
            error={this.state.error[component.key] ? true : false}
            errorText={this.getLocationErrorMessage(component)}
            id={component?.id}
          />
        );
      case formComponentTypes.BMI:
        if (!component.isDontShow) {
          return (
            <Bmi
              id={component?.id}
              type={component.componentProps.type}
              withBoldLabel={component.withBoldLabel}
              withoutUppercase={component?.withoutUppercase}
              onlyText={component?.onlyText}
              limit={component.limit}
              state={component.key}
              rowsMax={
                component.componentProps.rowsMax
                  ? component.componentProps.rowsMax
                  : 1
              }
              multiline={component.componentProps.multiline}
              title={component.label}
              placeholder={
                component.componentProps.placeholder
                  ? component.componentProps.placeholder
                  : ""
              }
              defaultValue=""
              data={component.data}
              value={component.data}
              handleOnChange={(e, limit) => {
                if (component?.onChangeCallBack) {
                  component.onChangeCallBack(e.target.value, component.key);
                }
                this.updateDataInState(
                  e.target.value,
                  component.key,
                  component?.componentProps?.type,
                  () => { },
                  limit
                );
              }}
              error={this.state.error[component.key] ? true : false}
              errorText={
                this.state.currentAllComponentState[component.key]
                  ? component?.componentProps?.type === "email"
                    ? errorMessage.emailMessage
                    : ""
                  : errorMessage.requiredMessage
              }
              disabled={component.componentProps.disabled}
            />
          );
        } else {
          return null;
        }
      case formComponentTypes.SERVICECONFIGURATION:
        return <ServiceConfiguration />;
      case formComponentTypes.FROM_TO_DATE_TIME_PICKER:
        return (
          <FromToDateTimePicker
            id={component?.id}
            fromDateLabel={
              this.state.currentAllComponentState?.[
              component?.label?.["fromDateKey"]
              ]
            } //TODO: CHANGE LABEL SOURCE
            toDateLabel={
              this.state.currentAllComponentState?.[
              component?.label?.["toDateKey"]
              ]
            } //TODO: CHANGE LABEL SOURCE
            fromDateValue={
              this.state.currentAllComponentState?.[
              component?.key?.["fromDateKey"]
              ]
            }
            toDateValue={
              this.state.currentAllComponentState?.[
              component?.key?.["toDateKey"]
              ]
            }
            onFromDateChange={(newFromDate) => {
              if (component?.componentProps?.onFromDateChange) {
                component.componentProps.onFromDateChange(
                  newFromDate,
                  component?.key?.["fromDateKey"]
                );
              }
              this.updateDataInState(
                newFromDate,
                component?.key?.["fromDateKey"]
              );
            }}
            onToDateChange={(newToDate) => {
              if (component?.componentProps?.onToDateChange) {
                component.componentProps.onToDateChange(
                  newToDate,
                  component?.key?.["toDateKey"]
                );
              }
              this.updateDataInState(newToDate, component?.key?.["toDateKey"]);
            }}
            onFromDateAccept={(newDate) => {
              if (component?.componentProps?.handleOnFromDateAccept) {
                component.componentProps.handleOnFromDateAccept(
                  newDate,
                  component?.key?.["fromDateKey"]
                );
              }
            }}
            onToDateAccept={(newDate) => {
              if (component?.componentProps?.handleOnToDateAccept) {
                component.componentProps.handleOnToDateAccept(
                  newDate,
                  component?.key?.["toDateKey"]
                );
              }
            }}
            disabled={component?.componentProps?.disabled}
            minDateTime={{ ...component?.componentProps?.minDateTime }}
            maxDateTime={{ ...component?.componentProps?.maxDateTime }}
            disableFuture={{ ...component?.componentProps?.disableFuture }}
            disablePast={{ ...component?.componentProps?.disablePast }}
            inputFormat={component?.componentProps?.inputFormat}
            mandatoryErrorFromDate={
              this.state.error[component?.key?.["fromDateKey"]] ? true : false
            }
            mandatoryErrorToDate={
              this.state.error[component?.key?.["toDateKey"]] ? true : false
            }
            fromDateParentErrorStateUpdate={(isDateError) =>
              this.updateErrorStateFromToDatePicker(
                isDateError,
                component?.key?.["fromDateKey"]
              )
            }
            toDateParentErrorStateUpdate={(isDateError) =>
              this.updateErrorStateFromToDatePicker(
                isDateError,
                component?.key?.["toDateKey"]
              )
            }
          />
        );
      case formComponentTypes.ACTIVITY_RECURRENCE:
        return (
          <ActivityRecurrence
            isEdit={component?.isEdit}
            editData={component?.editData}
            id={component?.id}
            //Value Props
            values={{
              recurrenceValue: this.state.currentAllComponentState?.[
                component?.key?.["recurrence"]
              ],
              dnrValue: {
                dnrFromValue: this.state.currentAllComponentState?.[
                  component?.key?.["dnrFrom"]
                ],
                dnrToValue: this.state.currentAllComponentState?.[
                  component?.key?.["dnrTo"]
                ],
              },
              repeatOnValue: this.state.currentAllComponentState?.[
                component?.key?.["repeatOn"]
              ],

              //Weekly
              // weeklyDateRangeValue: this.state.currentAllComponentState?.[
              //   component?.key?.["weeklyDateRange"]
              // ],
              weeklyDateFromValue: this.state.currentAllComponentState?.[
                component?.key?.["weeklyDateFrom"]
              ],
              weeklyDateToValue: this.state.currentAllComponentState?.[
                component?.key?.["weeklyDateTo"]
              ],
              weeklyStartTimeValue: this.state.currentAllComponentState?.[
                component?.key?.["startTime"]
              ],
              weeklyEndTimeValue: this.state.currentAllComponentState?.[
                component?.key?.["endTime"]
              ],

              //Daily
              dailyDateFromValue: this.state.currentAllComponentState?.[
                component?.key?.["dailyDateFrom"]
              ],
              dailyDateToValue: this.state.currentAllComponentState?.[
                component?.key?.["dailyDateTo"]
              ],
              // dailyDateValue: this.state.currentAllComponentState?.[
              //   component?.key?.["dailyDate"]
              // ],
              dailyStartTimeValue: this.state.currentAllComponentState?.[
                component?.key?.["dailyStartTime"]
              ],
              dailyEndTimeValue: this.state.currentAllComponentState?.[
                component?.key?.["dailyEndTime"]
              ],
            }}
            //RECURRENCE PROPS
            recurrenceProps={{
              key: component?.key?.["recurrence"],
              label: component?.recurrenceProps?.label,
              options: component?.recurrenceProps?.options,
              error: this.state.error[component?.key?.["recurrence"]]
                ? true
                : false,
              errorText: errorMessage.requiredMessage,
            }}
            //DO NOT REPEAT PROPS
            dnrProps={{
              dnrFromLabel: component?.dnrProps?.label?.dnrFrom, //TODO: CHANGE LABEL SOURCE
              dnrToLabel: component?.dnrProps?.label?.dnrTo, //TODO: CHANGE LABEL SOURCE
              handleOnFromDateAccept:
                component?.dnrProps?.handleOnFromDateAccept,
              handleOnToDateAccept: component?.dnrProps?.handleOnToDateAccept,
              minDateTime: { ...component?.dnrProps?.minDateTime },
              disablePast: { ...component?.dnrProps?.disablePast },
              mandatoryErrorFromDate: this.state.error[
                component?.key?.["dnrFrom"]
              ]
                ? true
                : false,
              mandatoryErrorToDate: this.state.error[component?.key?.["dnrTo"]]
                ? true
                : false,
              fromDateParentErrorStateUpdate: (isDateError) =>
                this.updateErrorStateFromToDatePicker(
                  isDateError,
                  component?.key?.["dnrFrom"]
                ),
              toDateParentErrorStateUpdate: (isDateError) =>
                this.updateErrorStateFromToDatePicker(
                  isDateError,
                  component?.key?.["dnrTo"]
                ),
            }}
            //WEEKLY PROPS
            weeklyProps={{
              repeatOnProps: {
                ...component?.weeklyProps?.repeatOnProps,
                label: component?.weeklyProps?.repeatOnProps?.label,
                options: component?.weeklyProps?.repeatOnProps?.options,
                isMulti: component?.weeklyProps?.repeatOnProps?.isMulti,
                error: this.state.error[component?.key?.["repeatOn"]]
                  ? true
                  : false,
                errorText: errorMessage.requiredMessage,
              },
              dateRangeProps: {
                // label: component?.weeklyProps?.dateRangeProps?.label,
                // type: component?.weeklyProps?.dateRangeProps?.type,
                // isHidePastDate:
                //   component?.weeklyProps?.dateRangeProps?.componentProps
                //     ?.isHidePastDate,
                // isHideFutureDate:
                //   component?.weeklyProps?.dateRangeProps?.componentProps
                //     ?.isHideFutureDate,
                // error: this.state.error[component?.key?.["weeklyDateRange"]]
                //   ? true
                //   : false,
                // errorText: errorMessage.requiredMessage,

                // NEW WEEKLY COMPONENT PROPS
                weeklyFromDateLabel:
                  component?.weeklyProps?.dateRangeProps?.label?.weeklyDateFrom, //TODO: CHANGE LABEL SOURCE
                weeklyToDateLabel:
                  component?.weeklyProps?.dateRangeProps?.label?.weeklyDateTo, //TODO: CHANGE LABEL SOURCE
                handleOnFromDateAccept:
                  component?.weeklyProps?.dateRangeProps
                    ?.handleOnFromDateAccept,
                handleOnToDateAccept:
                  component?.weeklyProps?.dateRangeProps?.handleOnToDateAccept,
                minDateTime: {
                  ...component?.weeklyProps?.dateRangeProps?.minDateTime,
                },
                disablePast: {
                  ...component?.weeklyProps?.dateRangeProps?.disablePast,
                },
                mandatoryErrorFromDate: this.state.error[
                  component?.key?.["weeklyDateFrom"]
                ]
                  ? true
                  : false,
                mandatoryErrorToDate: this.state.error[
                  component?.key?.["weeklyDateTo"]
                ]
                  ? true
                  : false,
                fromDateParentErrorStateUpdate: (isDateError) =>
                  this.updateErrorStateFromToDatePicker(
                    isDateError,
                    component?.key?.["weeklyDateFrom"]
                  ),
                toDateParentErrorStateUpdate: (isDateError) =>
                  this.updateErrorStateFromToDatePicker(
                    isDateError,
                    component?.key?.["weeklyDateTo"]
                  ),
                handleFromTimeError: (isError) => {
                  this.handleTimeError(
                    component?.key?.["weeklyDateFrom"],
                    isError
                  );
                },
                handleToTimeError: (isError) => {
                  this.handleTimeError(
                    component?.key?.["weeklyDateTo"],
                    isError
                  );
                },
              },
              timeRangeProps: {
                startLabel: component?.weeklyProps?.timeRangeProps?.startLabel,
                handleStartAccept:
                  component?.weeklyProps?.timeRangeProps?.handleStartAccept,
                startMin: component?.weeklyProps?.timeRangeProps?.startMin,
                disableStartPast:
                  component?.weeklyProps?.timeRangeProps?.disableStartPast,
                reqStartError: this.state.error[component?.key?.["startTime"]]
                  ? true
                  : false,
                endLabel: component?.weeklyProps?.timeRangeProps?.endLabel,
                handleEndAccept:
                  component?.weeklyProps?.timeRangeProps?.handleEndAccept,
                endMin: component?.weeklyProps?.timeRangeProps?.endMin,
                disableEndPast:
                  component?.weeklyProps?.timeRangeProps?.disableEndPast,
                reqEndError: this.state.error[component?.key?.["endTime"]]
                  ? true
                  : false,
              },
            }}
            //DAILY PROPS
            dailyProps={{
              dateProps: {
                dailyFromDateLabel:
                  component?.dailyProps?.dateProps?.label?.dailyDateFrom, //TODO: CHANGE LABEL SOURCE
                dailyToDateLabel:
                  component?.dailyProps?.dateProps?.label?.dailyDateTo, //TODO: CHANGE LABEL SOURCE
                handleOnFromDateAccept:
                  component?.dailyProps?.dateProps?.handleOnFromDateAccept,
                handleOnToDateAccept:
                  component?.dailyProps?.dateProps?.handleOnToDateAccept,
                minDateTime: {
                  ...component?.dailyProps?.dateProps?.minDateTime,
                },
                disablePast: {
                  ...component?.dailyProps?.dateProps?.disablePast,
                },
                mandatoryErrorFromDate: this.state.error[
                  component?.key?.["dailyDateFrom"]
                ]
                  ? true
                  : false,
                mandatoryErrorToDate: this.state.error[
                  component?.key?.["dailyDateTo"]
                ]
                  ? true
                  : false,
                fromDateParentErrorStateUpdate: (isDateError) =>
                  this.updateErrorStateFromToDatePicker(
                    isDateError,
                    component?.key?.["dailyDateFrom"]
                  ),
                toDateParentErrorStateUpdate: (isDateError) =>
                  this.updateErrorStateFromToDatePicker(
                    isDateError,
                    component?.key?.["dailyDateTo"]
                  ),
                handleFromTimeError: (isError) => {
                  this.handleTimeError(
                    component?.key?.["dailyDateFrom"],
                    isError
                  );
                },
                handleToTimeError: (isError) => {
                  this.handleTimeError(
                    component?.key?.["dailyDateTo"],
                    isError
                  );
                },
              },
              timeRangeProps: {
                startLabel: component?.dailyProps?.timeRangeProps?.startLabel,
                handleStartAccept:
                  component?.dailyProps?.timeRangeProps?.handleStartAccept,
                startMin: component?.dailyProps?.timeRangeProps?.startMin,
                disableStartPast:
                  component?.dailyProps?.timeRangeProps?.disableStartPast,
                reqStartError: this.state.error[
                  component?.key?.["dailyStartTime"]
                ]
                  ? true
                  : false,
                endLabel: component?.dailyProps?.timeRangeProps?.endLabel,
                handleEndAccept:
                  component?.dailyProps?.timeRangeProps?.handleEndAccept,
                endMin: component?.dailyProps?.timeRangeProps?.endMin,
                disableEndPast:
                  component?.dailyProps?.timeRangeProps?.disableEndPast,
                reqEndError: this.state.error[component?.key?.["dailyEndTime"]]
                  ? true
                  : false,
                fromDateParentErrorStateUpdate: (isDateError) =>
                  this.updateErrorStateFromToDatePicker(
                    isDateError,
                    component?.key?.["dailyStartTime"]
                  ),
                toDateParentErrorStateUpdate: (isDateError) =>
                  this.updateErrorStateFromToDatePicker(
                    isDateError,
                    component?.key?.["dailyEndTime"]
                  ),
              },
            }}
            //ON CHANGE PROPS
            onChanges={{
              handleRecurrenceChange: (selectedRecurrence, key) => {
                this.updateDataInState(
                  selectedRecurrence,
                  key,
                  null,
                  component?.recurrenceProps?.cleanUpKeys,
                  null,
                  null,
                  null,
                  null,
                  null,
                  component
                );
                // cleanUpKeys
              },

              // DO NOT REPEAT
              handleDnrFromDateChange: (newFromDate) => {
                if (component?.dnrProps?.onFromDateChange) {
                  component.dnrProps.onFromDateChange(new Date(newFromDate));
                }
                this.updateDataInState(
                  newFromDate,
                  component?.key?.["dnrFrom"]
                );
              },
              handleDnrToDateChange: (newToDate) => {
                if (component?.dnrProps?.onToDateChange) {
                  component.dnrProps.onToDateChange(new Date(newToDate));
                }
                this.updateDataInState(newToDate, component?.key?.["dnrTo"]);
              },

              //WEEKLY
              handleWeeklyRepeatOnChange: (value) => {
                this.updateDataInState(
                  value,
                  component?.key?.["repeatOn"],
                  component?.weeklyProps?.repeatOnProps?.type,
                  component?.weeklyProps?.repeatOnProps?.cleanUpKeys //Cleanupkeys
                );
              },
              // handleWeeklyDateRangeChange: (value) => {
              //   ;
              //   this.updateDataInState(
              //     value,
              //     component?.key?.["weeklyDateRange"],
              //     component?.weeklyProps?.dateRangeProps?.type
              //   );
              // },
              handleWeeklyFromDateChange: (newFromDate) => {
                if (component?.weeklyProps?.dateProps?.onFromDateChange) {
                  component.weeklyProps.dateProps.onFromDateChange(
                    new Date(newFromDate)
                  );
                }
                this.updateDataInState(
                  newFromDate,
                  component?.key?.["weeklyDateFrom"]
                );
              },
              handleWeeklyToDateChange: (newToDate) => {
                if (component?.weeklyProps?.dateProps?.onToDateChange) {
                  component.weeklyProps.dateProps.onToDateChange(
                    new Date(newToDate)
                  );
                }
                this.updateDataInState(
                  newToDate,
                  component?.key?.["weeklyDateTo"]
                );
              },
              weeklyStartChange: (newStartTime) => {
                if (component?.weeklyProps?.timeRangeProps?.startChange) {
                  component.weeklyProps.timeRangeProps.startChange(
                    new Date(newStartTime)
                  );
                }
                this.updateDataInState(
                  newStartTime,
                  component?.key?.["startTime"]
                );
              },
              weeklyEndChange: (newEndTime) => {
                if (component?.weeklyProps?.timeRangeProps?.endChange) {
                  component.weeklyProps.timeRangeProps.endChange(
                    new Date(newEndTime)
                  );
                }
                this.updateDataInState(newEndTime, component?.key?.["endTime"]);
              },

              //DAILY
              handleDailyFromDateChange: (newFromDate) => {
                if (component?.dailyProps?.dateProps?.onFromDateChange) {
                  component.dailyProps.dateProps.onFromDateChange(
                    new Date(newFromDate)
                  );
                }
                this.updateDataInState(
                  newFromDate,
                  component?.key?.["dailyDateFrom"]
                );
              },
              handleDailyToDateChange: (newToDate) => {
                if (component?.dailyProps?.dateProps?.onToDateChange) {
                  component.dailyProps.dateProps.onToDateChange(
                    new Date(newToDate)
                  );
                }
                this.updateDataInState(
                  newToDate,
                  component?.key?.["dailyDateTo"]
                );
              },

              // dailyDateChange: (newValue) => {
              //   if (component?.dailyProps?.dateProps?.onChange) {
              //     component.dailyProps.dateProps.onChange(new Date(newValue));
              //   }
              //   this.updateDataInState(
              //     newValue,
              //     component?.key?.["dailyDate"],
              //     component?.dailyProps?.dateProps?.isDate ? "date" : "",
              //     component?.dailyProps?.dateProps?.cleanUpKeys,
              //     null,
              //     null,
              //     component?.dailyProps?.dateProps?.hideFuture,
              //     component?.dailyProps?.dateProps?.hidePast
              //   );
              // },
              dailyStartChange: (newStartTime) => {
                if (component?.dailyProps?.timeRangeProps?.startChange) {
                  component.dailyProps.timeRangeProps.startChange(
                    new Date(newStartTime)
                  );
                }
                this.updateDataInState(
                  newStartTime,
                  component?.key?.["dailyStartTime"]
                );
              },
              dailyEndChange: (newEndTime) => {
                if (component?.dailyProps?.timeRangeProps?.endChange) {
                  component.dailyProps.timeRangeProps.endChange(
                    new Date(newEndTime)
                  );
                }
                this.updateDataInState(
                  newEndTime,
                  component?.key?.["dailyEndTime"]
                );
              },
            }}
          />
        );

      case formComponentTypes.ACTIVITY_MODE:
        return (
          <ActivityMode
            activityModeProps={{
              key: component?.key?.["activityMode"],
              label: component?.activityModeProps?.label,
              id: component?.activityModeProps?.id,
              value: this.state.currentAllComponentState?.[
                component?.key?.["activityMode"]
              ],
              options: component?.activityModeProps?.options,
              handleActivityModeChange: (selectedMode, key) => {
                this.updateDataInState(
                  selectedMode,
                  key,
                  null,
                  component?.activityModeProps?.cleanUpKeys
                );
              },
              error: this.state.error[component?.key?.["activityMode"]]
                ? true
                : false,
              errorText: errorMessage.requiredMessage,
            }}
            meetingProps={{
              id: component?.meetingProps?.id,
              type: component?.meetingProps?.componentProps?.type,
              limit: component?.meetingProps?.componentProps?.limit,
              state: component?.key?.["meetingLink"],
              title: component?.meetingProps?.label,
              placeholder: component?.meetingProps?.placeholder,
              value: this.state.currentAllComponentState?.[
                component?.key?.["meetingLink"]
              ],
              handleOnChange: (e, limit) => {
                this.updateDataInState(
                  e.target.value,
                  component?.key?.["meetingLink"],
                  component?.meetingProps?.componentProps?.type,
                  () => { },
                  limit
                );
              },
              error: this.state.error[component?.key?.["meetingLink"]]
                ? true
                : false,
              errorText: errorMessage.requiredMessage,
            }}
            locationProps={{
              id: component?.locationProps?.id,
              label: component?.locationProps?.label,
              value: this.state.currentAllComponentState?.[
                component?.key?.["location"]
              ],
              handleOnChange: (newValue) => {
                this.updateDataInState(newValue, component?.key?.["location"]);
              },
              openDialogMap: this.openDialogMap,
              showLocationViaMaps:
                component?.locationProps?.componentProps?.showLocationViaMaps,
              error: this.state.error[component.key?.["location"]]
                ? true
                : false,
              errorText: this.getLocationErrorMessage(
                null,
                component.key?.["location"]
              ),
            }}
          />
        );
      case formComponentTypes.SWITCH_COMPONENT:
        return (
          <SwitchComponent
            data={component}
            id={component?.id}
            checked={this.state.currentAllComponentState[component.key]}
            //  handleOnChange={component.handleChange}
            handleOnChange={(newValue) => {
              component.onChangeCallBack(
                newValue.target.checked,
                component.key
              );
              this.updateDataInState(newValue.target.checked, component.key);
            }}
            label={component.label}
          />
        );

      case formComponentTypes.PRODUCT_VALIDITY:
        return (
          <ProductValidity
            isEdit={component?.componentProps?.isEdit}
            dropdownProps={{
              id: component?.id?.validity_id,
              options: component?.componentProps?.dropdownProps?.options,
              label: component?.componentProps?.dropdownProps?.label,
              value: this.state.currentAllComponentState?.[
                component?.key?.["productValidity"]
              ],
              onChange: (selected) => {
                if (component?.componentProps?.dropdownProps?.callback) {
                  component.componentProps.dropdownProps.callback(selected);
                }
                this.updateDataInState(
                  selected,
                  component?.key?.["productValidity"]
                );
              },
              error: this.state.error[component?.key?.["productValidity"]]
                ? true
                : false,
            }}
            // checkboxProps={{
            //   label: component?.componentProps?.checkboxProps?.label,
            //   value: component?.componentProps?.checkboxProps?.value,
            //   error: component?.componentProps?.checkboxProps?.error,
            // }}
            startDateProps={{
              id: component?.id?.effectiveFrom_id,
              label: component?.componentProps?.startDateProps?.label,
              value: this.state.currentAllComponentState?.[
                component?.key?.["startDate"]
              ],
              onChange: (selectedDate) => {
                if (component?.componentProps?.startDateProps?.callback) {
                  component.componentProps.startDateProps.callback(
                    selectedDate
                  );
                }
                this.updateDataInState(
                  selectedDate,
                  component?.key?.["startDate"]
                );
              },
              min: component?.componentProps?.startDateProps?.min,
              max: component?.componentProps?.startDateProps?.max,
              disablePast:
                component?.componentProps?.startDateProps?.disablePast,
              disableFuture:
                component?.componentProps?.startDateProps?.disableFuture,
              inputFormat:
                component?.componentProps?.startDateProps?.inputFormat,
              error: this.state.error[component?.key?.["startDate"]]
                ? true
                : false,
              fromDateParentErrorStateUpdate: (isDateError) =>
                this.updateErrorStateFromToDatePicker(
                  isDateError,
                  component?.key?.["startDate"]
                ),
            }}
            endDateProps={{
              label: component?.componentProps?.endDateProps?.label,
              id: component?.id?.end_id,
              value: this.state.currentAllComponentState?.[
                component?.key?.["endDate"]
              ],
              onChange: (selectedDate) => {
                if (component?.componentProps?.endDateProps?.callback) {
                  component.componentProps.endDateProps.callback(selectedDate);
                }
                this.updateDataInState(
                  selectedDate,
                  component?.key?.["endDate"]
                );
              },
              min: component?.componentProps?.endDateProps?.min,
              max: component?.componentProps?.endDateProps?.max,
              disablePast: component?.componentProps?.endDateProps?.disablePast,
              disableFuture:
                component?.componentProps?.endDateProps?.disableFuture,
              inputFormat: component?.componentProps?.endDateProps?.inputFormat,
              error: this.state.error[component?.key?.["endDate"]]
                ? true
                : false,
              toDateParentErrorStateUpdate: (isDateError) =>
                this.updateErrorStateFromToDatePicker(
                  isDateError,
                  component?.key?.["endDate"]
                ),
            }}
          ></ProductValidity>
        );

        case formComponentTypes.RESOURCE_VALIDITY:
          return (
            <ResourceValidity
              isEdit={this.props?.isEdit}
              startDateProps={{
                id: component?.id?.effectiveFrom_id,
                label: component?.componentProps?.startDateProps?.label,
                value: this.state.currentAllComponentState?.[
                  component?.key?.["startDate"]
                ],
                onChange: (selectedDate) => {
                  if (component?.componentProps?.startDateProps?.callback) {
                    component.componentProps.startDateProps.callback(
                      selectedDate
                    );
                  }
                  this.updateDataInState(
                    selectedDate,
                    component?.key?.["startDate"]
                  );
                },
                min: component?.componentProps?.startDateProps?.min,
                max: component?.componentProps?.startDateProps?.max,
                disablePast:
                  component?.componentProps?.startDateProps?.disablePast,
                disableFuture:
                  component?.componentProps?.startDateProps?.disableFuture,
                inputFormat:
                  component?.componentProps?.startDateProps?.inputFormat,
                error: this.state.error[component?.key?.["startDate"]]
                  ? true
                  : false,
                fromDateParentErrorStateUpdate: (isDateError) =>
                  this.updateErrorStateFromToDatePicker(
                    isDateError,
                    component?.key?.["startDate"]
                  ),
              }}
              endDateProps={{
                label: component?.componentProps?.endDateProps?.label,
                id: component?.id?.end_id,
                value: this.state.currentAllComponentState?.[
                  component?.key?.["endDate"]
                ],
                onChange: (selectedDate) => {
                  if (component?.componentProps?.endDateProps?.callback) {
                    component.componentProps.endDateProps.callback(selectedDate);
                  }
                  this.updateDataInState(
                    selectedDate,
                    component?.key?.["endDate"]
                  );
                },
                min: component?.componentProps?.endDateProps?.min,
                max: component?.componentProps?.endDateProps?.max,
                disablePast: component?.componentProps?.endDateProps?.disablePast,
                disableFuture:
                  component?.componentProps?.endDateProps?.disableFuture,
                inputFormat: component?.componentProps?.endDateProps?.inputFormat,
                error: this.state.error[component?.key?.["endDate"]]
                  ? true
                  : false,
                toDateParentErrorStateUpdate: (isDateError) =>
                  this.updateErrorStateFromToDatePicker(
                    isDateError,
                    component?.key?.["endDate"]
                  ),
              }}
            ></ResourceValidity>
          );

        case formComponentTypes.SELECT_COMPONENT_WITH_SUB_COMPONENT:
        return (
          <SelectComponentWithSubComponent
            type="address"
            data={[1, 2]}
            error={[]}
          />
        );
      case formComponentTypes.ADD_EXPERIENCE:

        return (
          <Experiencecard
            type="address"
            error={[]}
            id={component?.id}
            {...this.props.experience}
            {...this.props.experienceCertification}
          />
        );
      case formComponentTypes.ADD_CERTIFICATION:
        return (
          <Certificationcard
            type="address"
            id={component?.id}
            error={[]}
            {...this.props.certificate}
            {...this.props.experienceCertification}
          />
        );
      // case formComponentTypes.ADD_ADDRESS:

      //   return (
      //     <ViewCardDetails
      //       // component={this.viewDetailsAddressEditDetails(
      //       //   this.state?.isEdit,
      //       //   this.state.address,
      //       //   this.state.address,
      //       //   [{}]
      //       // )}
      //       callBackOnSave={this.props?.addressEmailPhoneNumberfuntions?.callBackOnSaveAddress}
      //       editData={this.props?.addressEmailPhoneNumberfuntions?.address}
      //       error={this.props?.addressEmailPhoneNumberfuntions?.isAddressRequired}
      //       // isEdit={this.state?.isEdit}
      //       handledrawer={this.props?.addressEmailPhoneNumberfuntions?.onAddEditDrawerAddress}
      //       // error={this.state.error["add_address"]}
      //       errorText={errorMessage.requiredMessage}
      //     />
      //   );
      case formComponentTypes.ADD_ADDRESS:
        return (
          <ModernViewAddressCardDetail
            id={component?.id}
            callBackOnSave={this.props?.addressEmailPhoneNumberfuntions?.callBackOnSaveAddress}
            editData={this.props?.addressEmailPhoneNumberfuntions?.address}
            error={this.props?.addressEmailPhoneNumberfuntions?.isAddressRequired}
            handledrawer={this.props?.addressEmailPhoneNumberfuntions?.onAddEditDrawerAddress}
            errorText={errorMessage.requiredMessage}
          />
        );
      // case formComponentTypes.ADD_EMAIL:
      //   return (
      //     <ViewCardDetailsEmail
      //       // component={this.viewDetailsEmailEditDetails(
      //       //   this.state?.isEdit,
      //       //   this.state.email,
      //       //   this.state.email,
      //       //   [{}]
      //       // )}
      //       callBackOnSave={this.props?.addressEmailPhoneNumberfuntions?.callBackOnSaveEmail}
      //       editData={this.props?.addressEmailPhoneNumberfuntions?.email}
      //       // isEdit={this.state?.isEdit}
      //       handledrawer={this.props?.addressEmailPhoneNumberfuntions?.onAddEditDrawerEmail}
      //     />
      //   );
      case formComponentTypes.ADD_EMAIL:
        return (
          <ModernViewEmailCardDetail
            id={component?.id}
            callBackOnSave={this.props?.addressEmailPhoneNumberfuntions?.callBackOnSaveEmail}
            editData={this.props?.addressEmailPhoneNumberfuntions?.email}
            handledrawer={this.props?.addressEmailPhoneNumberfuntions?.onAddEditDrawerEmail}
          />
        );
      // case formComponentTypes.ADD_PHONE_NUMBER:
      //   return (
      //     <ViewCardDetailsMobile
      //       // component={this.viewDetailsPhoneEditDetails(
      //       //   this.state?.isEdit,
      //       //   this.state.phone,
      //       //   this.state.phone,
      //       //   [{}]
      //       // )}
      //       callBackOnSave={this.props?.addressEmailPhoneNumberfuntions?.callBackOnSavePhone}
      //       editData={this.props?.addressEmailPhoneNumberfuntions?.phone}
      //       handledrawer={this.props?.addressEmailPhoneNumberfuntions?.onAddEditDrawerPhone}
      //     // error={this.state.error["add_phone"]}
      //     // errorText={this.state.error["add_phone_errorMessage"]}
      //     />
      //   );
      case formComponentTypes.ADD_PHONE_NUMBER:
        return (
          <ModernViewMobileCardDetail
            id={component?.id}
            callBackOnSave={this.props?.addressEmailPhoneNumberfuntions?.callBackOnSavePhone}
            editData={this.props?.addressEmailPhoneNumberfuntions?.phone}
            handledrawer={this.props?.addressEmailPhoneNumberfuntions?.onAddEditDrawerPhone}
          />
        );
      // case formComponentTypes.ADD_ID_TYPE:
      //   return (
      //     <ViewCardDetailsIdType
      //       // component={this.viewDetailsPhoneEditDetails(
      //       //   this.state?.isEdit,
      //       //   this.state.phone,
      //       //   this.state.phone,
      //       //   [{}]
      //       // )}
      //       callBackOnSave={this.props?.addressEmailPhoneNumberfuntions?.callBackOnSaveIdType}
      //       editData={this.props?.addressEmailPhoneNumberfuntions?.idType}
      //       handledrawer={this.props?.addressEmailPhoneNumberfuntions?.onAddEditDrawerIdType}
      //       error={this.props?.addressEmailPhoneNumberfuntions?.isIdTypeRequired}
      //       errorText={errorMessage.requiredMessage}
      //     // error={this.state.error["add_phone"]}
      //     />
      //   );
      case formComponentTypes.ADD_ID_TYPE:
        return (
          <ModernViewIdCardDetail
            id={component?.id}
            callBackOnSave={this.props?.addressEmailPhoneNumberfuntions?.callBackOnSaveIdType}
            editData={this.props?.addressEmailPhoneNumberfuntions?.idType}
            handledrawer={this.props?.addressEmailPhoneNumberfuntions?.onAddEditDrawerIdType}
            error={this.props?.addressEmailPhoneNumberfuntions?.isIdTypeRequired}
            errorText={errorMessage.requiredMessage} />
        )

      case formComponentTypes.ADD_TAX_ID:
        return (
          <ModernViewTaxCardDetail
            id={component?.id}
            callBackOnSave={this.props?.addressEmailPhoneNumberfuntions?.callBackOnSaveTax}
            editData={this.props?.addressEmailPhoneNumberfuntions?.tax}
            handledrawer={this.props?.addressEmailPhoneNumberfuntions?.onAddEditDrawerTax}
            breakPoints={{
              xs: 12,
              sm: 6,
              md: 4,
            }}
          />
        );

      case formComponentTypes.RICHTEXTEDITOR:
        return (
          <RichTextEditor
            saveOnBlur={(value) => this.updateDataInState(
              value,
              component?.key
            )}
            id={component?.id}
            value={component.value}
            label={component.label}
            keys={component?.key}
            labelSize={component.labelSize}
            {...component?.componentProps}
          />
        );
      default:
        return <></>;
    }
  };

  // REMOVE A ITEM FROM A ARRAY
  removeItemFromAnArrayCommonFunction = (
    arrayOfItems,
    removeItem,
    state,
    deleteUsingID
  ) => {
    const { currentAllComponentState } = this.state;

    let filteredArray;

    // This if condition checks if it wants to delete an item using its index.
    if (deleteUsingID) {
      const tempArray = [...arrayOfItems];
      tempArray.splice(removeItem, 1);
      filteredArray = [...tempArray];
    } else {
      // This else condition checks if it wants to delete an item using its value.
      filteredArray = arrayOfItems.filter((val) => {
        return val !== removeItem;
      });
    }

    currentAllComponentState[state] = filteredArray;

    this.setState({ ...this.state, isBlock: true });
  };

  cancelForm = () => {
    if (this.props.cancelForm) {
      this.props.cancelForm();
    } else {
      if (this.props.open) {
        this.props.toggleDrawer(false, {});
      } else {
        this.props.history.goBack();
      }
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { isResetState } = nextProps;
    const oldIsResetState = this.props.isResetState;

    if (isResetState !== oldIsResetState) {
      let { initialState } = setInitialState(nextProps);
      this.setState({
        currentAllComponentState: {
          // validity_period: [null, null],
          ...initialState,
        },
      });
    }
    return true;
  }

  // FROM TO DATE PICKER COMPONENT CALLBACKS
  updateErrorStateFromToDatePicker = (isDateError, key) => {
    const { error } = this.state;
    let isErrorOccured = this.state.isErrorOccured;
    if (isDateError) {
      error[key] = true;
      isErrorOccured = true;
    } else {
      error[key] = false;
      isErrorOccured = false;
    }
    this.setState({
      error: error,
      isBlock: isErrorOccured,
      isErrorOccured: isErrorOccured,
    });
  };
  /**--------------------------------- */

  /**------------------------------------ */
  handleTimeError = (key, isError) => {
    let { timeDifferenceError } = this.state;
    timeDifferenceError = {
      [key]: isError,
    };
    this.setState({
      timeDifferenceError: timeDifferenceError,
    });
  };

  customBreakPoints = (element, componentObject) => {
    const breakPoints = {};
    breakPoints["xs"] = 12;
    breakPoints["sm"] = 6;
    breakPoints["md"] = 4;

    const currentComponent = exoticComponents.filter(x => x.componentType === element)[0]

    if(element === formComponentTypes.ADD_TAX_ID){
      return {
        xs: 12,
        sm: 12,
        md: 12
      }
    }
    if (this.props?.[currentComponent?.["propName"]]?.length === 0 || this.props?.[currentComponent?.["propName"]]?.["certification"]?.length === 0 || this.props?.[currentComponent?.["propName"]]?.["experience"]?.length === 0) {
      breakPoints["md"] = 4
    } else if (customBreakPointsComponents.includes(element)) {
      breakPoints["md"] = 12;
    } else if (element === formComponentTypes.RESOURCE_VALIDITY){
      breakPoints["md"] = 8;
    }
    return breakPoints;
  };
  render() {
    const { classes, loading } = this.props;

    const { components } = this.props;

    return (
      <>
        <Paper className={classes.root}>
          <Grid
            container
            alignItems={"flex-start"}
            className={`${!this.props.fixPadding ? classes.container : ""}`}
            direction="row"
            spacing={`${this.props.fixPadding ? 0 : 3}`}
          // xs={12}
          >
            {Object.values(components).map((component, index) => {
              return (
                <Grid
                  item
                  // style={{display: "flex", justifyContent: "center", alignSelf: "center"}}
                  {...this.customBreakPoints(component?.element, component)}
                  style={{ alignSelf: "stretch" }}
                // style={{display:"flex", justifyContent:"center", alignItems:"center", margin:"auto"}}
                // className={this.props.fullWidth ? classes.item : ""}
                >
                  {this.giveMeTheComponent(component)}
                </Grid>
              );
            })}
          </Grid>
          {!this.props.isCancelSaveButtonsNeeded && (
            <div className={classes.saveBtnContainer}>
              {/* {
            !this.state.openDrawer.open && */}
              <Button
                variant={"text"}
                color={"primary"}
                id={this.props?.cancelButtonId}
                onClick={this.cancelForm}
                style={
                  this.props.cancelButtonStyles
                    ? { marginRight: 12, ...this.props.cancelButtonStyles }
                    : { marginRight: 12 }
                }
              >
                {"Cancel"}
              </Button>
              {/* } */}
              <Button
                variant={"contained"}
                color={"primary"}
                className={classes.saveBtn}
                style={
                  this.props.saveButtonStyles
                    ? {
                      margin: 16,
                      padding: "5px 40px",
                      ...this.props.saveButtonStyles,
                    }
                    : { margin: 16, padding: "5px 40px" }
                }
                disabled={loading}
                onClick={this.onSaveButtonClicked}
                id={this.props?.saveButtonId}
              >
                {this.props.saveButtonLabel
                  ? this.props.saveButtonLabel
                  : "Save"}
              </Button>
            </div>
          )}
          {this.props.isShowOnly && (
            <div className={classes.saveBtnContainer}>
              <Button
                variant={"contained"}
                color={"primary"}
                className={classes.saveBtn}
                disabled={loading}
                onClick={this.onSaveButtonClicked}
                id={this.props?.saveButtonId}
              >
                {this.props.saveButtonLabel
                  ? this.props.saveButtonLabel
                  : "Save"}
              </Button>
            </div>
          )}
        </Paper>
        {/* <DrawerComponent
          open={this.state.openDrawer.open}

          component={this.state.openDrawer.component}
          callback={this.state.openDrawer.callback}
          toggleDrawer={this.toggleDrawer}
        /> */}
        <Prompt
          when={this.state.isBlock}
          message={(location) => errorMessage.promptMessage}
        />
      </>
    );
  }
}

DynamicForm.propTypes = {
  components: PropTypes.object,
};

DynamicForm.defaultProps = {
  components: {},
};

export default withRouter(withAlertAndDialog(withStyles(style)(DynamicForm)));
