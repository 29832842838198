import React from "react";
import { Paper, withStyles, Grid } from "@material-ui/core";
import { HybridTypography } from "../../../../components";
import { ICONS } from "../../../../components/hybridTypography/typography";
import { customAddress } from "../../../../utils";
const styles = (theme) => ({
  paperCard: {
    borderRadius: "0px",
    padding: "25px 25px 40px 32px",
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
  },
  strong_name: {
    display: "inline-block",
    paddingTop: 12,
    color: theme.palette.primary.dark,
    fontSize: 14,
    paddingLeft: "40px",
  },
});

class DetailsCard extends React.Component {
  render() {
    const { classes, loading, data } = this.props;

    return (
      <Paper className={classes.paperCard}>
        <Grid container>
          <Grid item md={6} sm={6} xs={12}>
            <HybridTypography
              icon={ICONS.CLIENTPHONE("#28a9c1")}
              text={data?.mobile_no_isd_code?.toString()?.includes("+") ? data?.mobile_no_isd_code : `+${data?.mobile_no_isd_code} ${data?.mobile_no}`}
              loading={loading}
              header="MOBILE"
              islistbottom={true}
            />

            <HybridTypography
              icon={ICONS.LOCATION("#28a9c1")}
              text={customAddress(data?.addressLine1, data?.addressLine2, data?.area, data?.state, data?.country, data?.postalCode)}
              loading={loading}
              header="ADDRESS"
              islistbottom={true}
            />
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <HybridTypography
              icon={ICONS.MAILNEW("#28a9c1")}
              text={data?.email}
              header="EMAIL ADDRESS"
              loading={loading}
              islistbottom={true}
            />

            <HybridTypography
              icon={ICONS.SKILL("#28a9c1")}
              loading={loading}
              list={data?.skills?.map((_) => _.label)}
              header="SKILLS"
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(DetailsCard);
