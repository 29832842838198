import * as React from "react"

export const LeftAlignIcon = (props) => {

    const {
        width = "20",
        height = "20",
        color = "#6f6f6f"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 20 20"
        >
             <defs>
                <clipPath id="clip-path">
                <rect id="Rectangle_48013" data-name="Rectangle 48013" width="20" height="20" fill="#6f6f6f"/>
                </clipPath>
            </defs>
            <g id="Mask_Group_6" data-name="Mask Group 6" clip-path="url(#clip-path)">
                <path id="icons8-left-align" d="M40.409-6.126H21.854a.6.6,0,0,0-.593.593.6.6,0,0,0,.593.593H40.409A.6.6,0,0,0,41-5.533.6.6,0,0,0,40.409-6.126ZM21.854-.02H34.9a.6.6,0,0,0,.593-.593.6.6,0,0,0-.593-.593H21.854a.6.6,0,0,0-.593.593A.6.6,0,0,0,21.854-.02ZM40.409,3.774H21.854a.593.593,0,0,0,0,1.186H40.409a.593.593,0,0,0,0-1.186Zm-10.2,4.92H21.854a.593.593,0,0,0,0,1.186h8.359a.593.593,0,1,0,0-1.186Z" transform="translate(-20.66 8.513)" 
                    fill={color}
                />
            </g>
        </svg>
    );
};
