import React from 'react';
import PropTypes from 'prop-types';
import { Grid,Typography } from '@material-ui/core';

export const SelectFromPlaceholder = (props) =>{

    const {
        message,
        top
    } = props;

    return <div><Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    style={{ marginTop: top }}
  >
    <Grid item>
      <Typography variant="h6" align="center">
        {message}
      </Typography>
    </Grid>

  </Grid>
  </div>
}

SelectFromPlaceholder.propTypes = {
    message: PropTypes.string,
    top: PropTypes.string
}

SelectFromPlaceholder.defaultProps = {
    message: 'Please choose you form type!',
    // retryBtnName: 'Try Again',
    top: '30vh'
}

