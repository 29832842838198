import countryCode from "./countryCode.json";
import { getExampleNumber } from "libphonenumber-js";
import examples from "libphonenumber-js/examples.mobile.json";
import { formComponentTypes, SelectComponentWithSubComponentJSON } from ".";

export let maxMobileNumberLimit = 14;

export const checkLimit = (limit, number) => {
    
  if(Array.isArray(limit))
  {
    // console.log("INSIDE CHECK LIMIT ARRAY")
    // console.log(number, typeof number)
    // console.log(checkFirstZeroAndAllZero(number))
    if(number?.toString()?.length === limit[0] || number?.toString()?.length === limit[1])
    {
      return !checkFirstZeroAndAllZero(number) ? true : false
    }
    // else if(number.length === limit[0] || number.length === limit[1])
    else
      return false
  }
  if(typeof limit === 'object' && limit !== null)
  {
      console.log("INSIDE OBJECT")
      console.log(number.length === limit.lower_limit || number.length === limit.upper_limit)
  }
  else
  {
    if(number.length === limit)
    {
      return !checkFirstZeroAndAllZero(number) ? true : false
    }
    else
      return false
  }
}


const custom_limit = (code) => {
  let phoneNumber = {};
  let limit;
  if(code === "MY"){
    limit = [9, 11]
  } else {
    phoneNumber = getExampleNumber(code, examples);
    limit = phoneNumber?.nationalNumber?.length ?? 10;
  }
  // switch(code){
  //   case "MY":
  //       limit = [9, 11]
  //       break;
  //   default:
  //     phoneNumber = getExampleNumber(code, examples);
  //     limit = phoneNumber?.nationalNumber?.length ?? 10;
  //     break;
  // }
  return limit
}

export const changeMobileLimitBasedOnCC = (
  country_dial_code,
  isReturnValueNeeded
) => {
  const selectedCountry = countryCode?.filter(
    (cc) => cc.dial_code === country_dial_code
  );
  const ISO2Code = selectedCountry?.[0]?.code;
  // const phoneNumber = getExampleNumber(ISO2Code, examples);
  // const limit = phoneNumber?.nationalNumber?.length ?? 10;

  let limit = custom_limit(ISO2Code);

  console.log(limit)

  if (isReturnValueNeeded) {
    return limit
  }
};

export function getMobileLimitBasedOnCC(selectedCountryValue) {
  let mobileNumberLimitBasedOnCC = changeMobileLimitBasedOnCC(
    selectedCountryValue,
    true
  );
  return mobileNumberLimitBasedOnCC;
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return Boolean(re.test(email?.trim()));
};

export const isNumberKey = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= maxMobileNumberLimit) {
      if (!(e.which >= 48 && e.which <= 57)) e.preventDefault();
    }
  }
};

export const isNumberDecimalAndNoE = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= 10) {
      if (e.which === 69 || e.which === 101 || e.which === 45)
        e.preventDefault();
    }
  }
};

export const isNumberKeyDown = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= maxMobileNumberLimit) {
      console.log(e)
      if (e.which === 38 || e.which === 40 || e.type === "wheel") e.preventDefault();
    }
  }
};

export const checkScroll = (e) => {
  console.log(e.nativeEvent)
  // 
  if(e.type === "wheel")
  {
    // console.log(e.target)
    // this.blur()
    e.preventDefault()
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }  
}


export const restrictToNumbers = (event) => {
  const acceptableCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
  if(!acceptableCharacters.includes(event.key)){
    event.preventDefault()
  }
  // /^[1-9][0-9]{0,9}(\.[0-9]{0,2})?$/
}

// Password CustomValidations
export const minCheck = (passwordValue) => {
  const exp = /^(?=.*[A-Za-z\d\W])(?!.*\s).{8,}$/;
  return exp.test(passwordValue);
};
export const minNumberAndSymbolCheck = (passwordValue) => {
  const exp = /(?=.*\W)(?=.*\d)/;
  return exp.test(passwordValue);
};
export const charCaseCheck = (passwordValue) => {
  const exp = /(?=.*[a-z])(?=.*[A-Z])/;
  return exp.test(passwordValue);
};

export const isTextKeyDown = (event, isOnlyText) => {
  var inputValue = event.which;

  if (isOnlyText) {
    if (
      !(
        (inputValue >= 65 && inputValue <= 90) ||
        (inputValue >= 97 && inputValue <= 122)
      ) &&
      inputValue !== 32 &&
      inputValue !== 0
    ) {
      event.preventDefault();
    }
  }
};

// Used for Quantity in products table
export const quantityNumberCheck = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length < 10) {
      if (!(e.which >= 48 && e.which <= 57)) e.preventDefault();
    } else {
      e.preventDefault();
    }
  }
};

// Used for Quantity in products table
export const validateOnPaste = (e) => {
  const value = e.clipboardData.getData("Text");
  let error = false;
  if (value) {
    [...value].forEach((char) => {
      if (!(char.charCodeAt(0) >= 48 && char.charCodeAt(0) <= 57)) error = true;
      return char;
    });
    if (error) {
      e.preventDefault();
    }
  } else {
    e.preventDefault();
  }
};

export const checkFirstZeroAndAllZero = (value) => {
  if (typeof value !== typeof "") value = value.toString();
  if (parseInt(value?.split("")[0]) === 0) {
    return true;
  } 
  else {
    // console.log("INSIDE ELSE")
    let valueArr = value?.toString()?.split("") ?? [];
    valueArr.splice(0, 1);
    const digitArr = valueArr?.map((digit) => parseInt(digit));
   
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    if(digitArr.length > 0){
      if (digitArr?.reduce(reducer) === 0) {
        return true;
      } else {
        return false;
      }
    }
    else{
      return true
    }
  }
};

export const SelectComponentWithSubComponentValidations = (data) => {
  let _;
  let isInitial = false;
  let isValidated = data?.map((val) => {
    if (val.isInitial) {
      isInitial = true;
      val['isInitial'] = false
    } else {
      
      _ = Object.values(val).filter((vals) => !vals);
      
    }
    return _;
  });
  
  return isInitial ? false : isValidated.flat()?.length > 0 ? true : false;
};

export const getFormattedMobileCodeState = (phoneCode) => {
  let flag = phoneCode.toString().includes("+");
  if (!flag) {
    return `+${phoneCode}`;
  } else {
    return phoneCode.toString();
  }
};

export const validateOnSave = {
  address: (data) => {
    
    let checkFields = Object.keys(SelectComponentWithSubComponentJSON.address)
    let isError = false
    data.map(d => {
      checkFields.map((field) => {
        if(!d?.[field]){
          isError= true
        } else {
          if(field === "postalCode"){
            if(d?.[field]?.toString()?.length > 10){
              isError= true
            }
          }
        }
        return field
      })
      return d
    })
    return isError
  },
  email: (data) => {
    
    let checkFields = Object.keys(SelectComponentWithSubComponentJSON.email)
    let isError = false
    data.map(d => {
      
      checkFields.map((field) => {
        if(!d?.[field]){
          isError= true
        } else {
          if(field === "emailId"){
            if(!validateEmail(d?.[field])){
              isError = true
            }
          }
        }
        return field
      })
      return d
    })
    return isError
  },
  phone: (data) => {
    
    let checkFields = Object.keys(SelectComponentWithSubComponentJSON.phone)
    let isError = false
    data.map(d => {
      checkFields.map((field) => {
        if(!d?.[field]){
          isError= true
        } else {
          if(field === "mobileNo"){
            let cc = getFormattedMobileCodeState(d?.["mobileNoIsdCode"])
            let limit = getMobileLimitBasedOnCC(cc)
            if(d?.[field]?.toString()?.length > limit || d?.[field]?.toString()?.length < limit){
              isError = true
            }
          }
        }
        return field
      })
      return d
    })
    return isError
  }
}


// export const newMultipleValidation = {
//   address: (data) => {
//     let checkFields = Object.keys(SelectComponentWithSubComponentJSON.address)
//     let isError = false
//     checkFields.map((field) => {
//       if(data?.[field] === ""){
        
//       }
//     })
//   }
// }



export const isAlphanumeric = (value)=> {
  
  const regex = /^[a-zA-Z0-9]+$/
  return value ? regex.test(value) : false
}

export const urlValidation = (url) => {
  
  // const regex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
  
  const regex = new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i')
  return url ? regex.test(url.toLowerCase()) : false
}

export const multipleTypeComponentValidation = [formComponentTypes.ADD_ADDRESS, formComponentTypes.ADD_EMAIL, formComponentTypes.ADD_PHONE_NUMBER]

// Function to check if the given argument is an array and has at least one element
export const isArrayWithElements = (value) => {
  return Array.isArray(value) && value.length > 0;
}
