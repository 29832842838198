import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { CameraSvg } from "../../assets";
import { Typography, Grid, Hidden, IconButton } from "@material-ui/core";
import { useStyles } from "./styles";
import PropTypes from "prop-types";
import { useTheme } from '@material-ui/core/styles';
import { ImageEditor } from "../imageEditor";
// import { withAlertAndDialog } from "../../HOCs";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";


function Component(props) {
  
  

  const classes = useStyles();
  const theme = useTheme()
const handleEdit = () =>{
  props.dialog.setDialog({
    ...props.dialog,
    open: true,
    title: "Edit Image",
    body:
      <ImageEditor image = {props.value} callBackFunction={props.handleFileChange}/>, 
    positiveBtn: "Ok",
    negativeBtn: "CANCEL",
    dontShowButtons:true,
    onOk: ()=>{},
    onCancel: ()=>{
      props.dialog.setDialog({

        ...props.dialog,
        open: false,
      })
    },
  });
}
  return (
    <>
      <div className={classes.root}>

        {props?.value ? (
          <>
          <Avatar
            alt="preview image"
            id="image"
            onClick={() => {
              if (document.getElementById(props?.id)) {
              document.getElementById(props?.id).click();
              }
            }}
            src={props.value}
          />
             
            <div>
            <IconButton
                    className={classes.customHoverFocus}
                    edge="end"
                    onClick={(l) =>handleEdit()}
                    id={`editImage${props?.id}`}
                    disableRipple
                  >
                    <img src="/images/icons8-edit.svg" width="75%" alt="edit" />
                  </IconButton>
                  <IconButton
                    edge="end"
                    onClick={() => props.handleFileChange()}
                    id={`deleteImage${props?.id}`}
                    className={classes.customHoverFocus}
                    disableRipple
                  >
                    <img
                      width="73%"
                      src="/images/icons8-trash-can.svg"
                      alt="delete"
                    />
                  </IconButton>
            </div>
            
            </>
        ) : (
            <Avatar
              id="image"
              onClick={() => {
                if (document.getElementById(props?.id)) {
                document.getElementById(props?.id).click();
                }
              }}
              
              src={
                
                
                // !base64MimeType(props.profile_pic)?.length ? 
                // props?.profile_pic
                // // props.profile_pic
                // :
                // props.profile_pic
                props?.profile_pic
              }
            >
              <Grid container>
                <Grid item xs={12} className={classes.cameraContainer}>
                  <CameraSvg htmlColor={theme.palette.ternary.main} />
                </Grid>
                <Grid item xs={12} className={classes.textContainer}>
                  <Hidden mdUp>
                    <Typography variant="body1" component="p">
                      {props.label ? props.label : "UPLOAD PHOTO"}
                    </Typography>
                  </Hidden>
                  <Hidden smDown>
                    <Typography variant="body1" component="p">
                      {props.label ? props.label : "UPLOAD PHOTO"}
                    </Typography>
                  </Hidden>
                </Grid>
              </Grid>
            </Avatar>
          )}

        <input 
          type="file"
          hidden
          accept="image/x-png,image/gif,image/jpeg"
          // id="upload"
          id={props?.id}
          onChange={props.handleFileChange}
        />
      </div>
      <div className={classes.errorRoot}>
        {props.error && (
          <Typography
            variant="body1"
            component="div"
            className={classes.errorText}
          >
            {props.errorText}
          </Typography>
        )}
      </div>
      
    </>
  );
}



export const UploadProfileImage = withAlelrtAndDialog((props) => {
  
  return <Component {...props}/>
  
})
UploadProfileImage.propTypes = {
  value: PropTypes.string,
  profile_pic: PropTypes.string,
  label: PropTypes.string, 
  handleFileChange: PropTypes.func,
  errorText: PropTypes.string,
};