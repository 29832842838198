import React, { Component } from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: (props) => ({
    padding: theme.spacing(props.padding) || theme.spacing(1.5),
  }),
  itemContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#FEF5EF',
    maxWidth: "100%",
    borderRadius: 8,
    width: "100%"
  },
  checkIn: {
    background: '#FDECE0',
    maxWidth: "56%",
    width: "56%",
    height: 70,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 8,
    [theme.breakpoints.down('xs')]: {
      clipPath: "polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%)",
    },
    [theme.breakpoints.up('sm')]: {
      clipPath: "polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%)",
    },
    [theme.breakpoints.up('md')]: {
      clipPath: "polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)",
    },
    [theme.breakpoints.up('lg')]: {
      clipPath: "polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%)",
    },
  },
  checkOut: {
    maxWidth: "44%",
    width: "44%",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 8
  },
  checkInText: {
    color: theme.palette.ternary.main
  },
  checkOutText: {
    color: theme.palette.ternary.main
  },
})


class CheckInCheckOutCard extends Component {
  render() {
    const { classes } = this.props
    const { leftText, leftSubText, rightText, rightSubText } = this.props
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} className={classes.itemContainer}>
            <div className={classes.container}>
              <div className={classes.checkIn}>
                <Typography className={classes.checkInText}>{leftText ? leftText : 'N/A'}</Typography>
                <Typography variant="body2">{leftSubText ? leftSubText : ''}</Typography>
              </div>
              <div className={classes.checkOut}>
                <Typography className={classes.checkOutText}>{rightText ? rightText : 'N/A'}</Typography>
                <Typography variant="body2">{rightSubText ? rightSubText : ''}</Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(CheckInCheckOutCard)

CheckInCheckOutCard.propTypes = {
  /** Sets the left pane main text, Which has variant ```body1``` */
  leftText: PropTypes.string,
  /** Sets the left pane sub text, Which has variant ```body2``` */
  leftSubText: PropTypes.string,
  /** Sets the right pane main text, Which has variant ```body1``` */
  rightText: PropTypes.string,
  /** Sets the right pane sub text, Which has variant ```body2``` */
  rightSubText: PropTypes.string,
  /**Set padding for CheckIn CheckOut card, example ```padding={2} will transform to 2*8px = 16px```. Consider this while setting the padding */
  padding: PropTypes.number
}