import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tabs, withStyles, Tab, Avatar } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        paddingTop: 4,
        position: 'relative'
    },
    avatarSelected: {
        backgroundColor: theme.palette.ternary.main,
        color: 'white',
        textAlign: "center",
    },
    avatarUnSelected: {
        backgroundColor: theme.palette.secondary.light,
        color: 'black',
        textAlign: "center",
    },
    tabs: {
        width: '100%',
    }
})

class DayContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClicked = (e, value) => {
        this.props.onDateSelected(moment(value,'DD/MM/YYYY'))
    }

    isSelected = (date) => {

        let {
            selectedDate
        } = this.props;

        selectedDate = moment(selectedDate.format('DD/MM/YYYY'), 'DD/MM/YYYY');
        date = moment(date.format('DD/MM/YYYY'), 'DD/MM/YYYY');

        if (selectedDate.isSame(date)) {
            return true
        } else {
            return false
        }
    }

    giveMeTheDays = () => {

        const {
            selectedDate
        } = this.props;

        let days = [];
        const month = selectedDate.get('month');
        const year = selectedDate.get('year');

        for (let day = 1; day <= selectedDate.daysInMonth(); day++) {
            let date = moment(`${day}/${month + 1}/${year}`, 'D/M/YYYY');
            days.push({
                value: date.format('DD'),
                label: date.format('ddd'),
                date,
            })
        }

        return days;
    }

    render() {

        const { classes,selectedDate } = this.props;

        const days = this.giveMeTheDays();

        return <div className={classes.root}>
            <Tabs
                variant="scrollable"
                value={selectedDate.format('DD/MM/YYYY')}
                onChange={this.onClicked}
                indicatorColor={"primary"}
                TabIndicatorProps={{
                    style: { height: 3 }
                }}
                className={classes.tabs}
            >
                {days?.map(day => {
                    return <Tab style={{ minWidth: 90, textTransform: 'unset' }} value={day.date.format('DD/MM/YYYY')} label={day.label} icon={<Avatar className={this.isSelected(day.date) ? classes.avatarSelected : classes.avatarUnSelected} id={`${day.value}_${moment(selectedDate).format('MMMM_YYYY')}`}>{day.value}</Avatar>} />
                })}
            </Tabs>
        </div>
    }
}

DayContainer.propTypes = {
    currentDate: PropTypes.instanceOf(moment()),
    selectedDate: PropTypes.instanceOf(moment()),
    onDateSelected: PropTypes.func
}

export default withStyles(styles)(DayContainer);
