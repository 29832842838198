import React from "react";
import { Grid, Typography, makeStyles, Divider } from "@material-ui/core";
import { fontStyleClass } from "../../../utils";

const styles = makeStyles((theme) => ({
  root: {
    marginBottom: 26,
  },
  header: {
    marginBottom: 10,
  },
  value: {
    padding: 10,

    width: "100%",
    color: theme.palette.secondary.main,
  },
  listcontainer: {
    background: theme.palette.secondary.light,
    borderRadius: 8,
    width: "100%",
  },
  ...fontStyleClass
}));

export const CardTextWithList = (props) => {
  const classes = styles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h6" component="h6" className={`${classes.header} ${classes.mediumFont}`}>
          {props.title}
        </Typography>
      </Grid>
      <div className={classes.listcontainer}>
        {props.value?.map((val, index) => {
          return (
            <Grid item xs={12}>
              <Typography
                variant="caption"
                component="div"
                className={classes.value}
              >
                {`${index + 1}. ${val.label}`}
              </Typography>
              {props.value.length - 1 > index && (
                <Divider style={{ width: "100%" }} />
              )}
            </Grid>
          );
        })}
      </div>
    </Grid>
  );
};
