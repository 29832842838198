import React from "react";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import withAlelrtAndDialog from "../../../HOCs/withAlelrtAndDialog";
import { withRouter } from "react-router-dom";
import { Grid, withStyles } from "@material-ui/core";
import { alertProps, genericStyles, productSortDirectionOption } from "../../../utils";
import {
  // careProviderDetailsUrl,
  filterTagType,
  subHeaderSortOptionWithValue,
} from "../../../utils";
import UserTable from "../../../components/adminTable/usermanagement";
import SubHeader from "../../../components/subHeader";
import FilterTags from "../../../components/filters/filterTags";
import {
  FilterBuilder,
  NotFoundSection,
  // ListCard
} from "../../../components";
import { filterComponent } from "./filterComponent";

const styles = (theme) => ({
  root: {
    padding: 24,
  },
  list: {
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    backgroundColor: "white",
    borderRadius: 8,
    height: "calc(100vh - 108px)",
    overflow: "scroll",
  },
  masterSelect: {
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    backgroundColor: "white",
    borderRadius: 8,
    padding: "12px 30px 12px 12px",
    // height: "calc(100vh - 108px)",
    // overflow: "scroll"
  },
  table: {
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
    backgroundColor: "white",
    borderRadius: 8,
    height: "calc(100vh - 108px)",
    overflow: "scroll",
  },
  menuList: {
    padding: 8,
    "& .Mui-selected": {
      borderRadius: 16,
      backgroundColor: theme.palette.primary.main,
      color: `${theme.palette.common.white} !important`,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  menuListItem: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 16,
    padding: "4px 16px",
  },
  // typography: {
  //   "&:hover": {
  //     color: `${theme.palette.ternary.main} !important`
  //   }
  // },
  // typoSelected: {
  //   color: `${theme.palette.ternary.main} !important`
  // },
  formControl: {
    margin: "0px 8px",
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.ternary.main} !important`,
        borderWidth: "1.6px !important",
      },
    },
  },
  customSelect: {
    padding: "10.500px 14px",
  },
  label: {
    color: theme.palette.text.label,
    textTransform: "uppercase",
  },
  ...genericStyles.cardWrapperShadows(theme),
});

const initialState = {
  pageNo: 0,
  pageSize: 25,
  careProviderName: "",
  category: [],
  provider: [],
  status: undefined,
  products: [],
  date: null,
  isFilterApplied: false,
  // sortDirec: subHeaderSortOptionWithValue[1],
  sortDirec: productSortDirectionOption[1].value,
  searchValue: "",
  role: [],
  users: [],
  filterRoles: [],
  ind_sort: "desc",
  page: 1,
  rowsPerPage: 8,
  limit: 8,
  sortField: "createdAt"
};

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      id:"AdminstrationUserManagement"
    };
  }

  componentDidMount() {
    this.getEssentials();
  }

  getEssentials = () => {
    this.props.GET_USERS_MASTER({
      searchKey: this.state.searchValue,
      offset: 0,
      filterRole: this.state?.role.map((x) => x.value),
      sort: {
        sortField: "createdAt",
        sortDirection: this.state?.sortDirec,
      },
      limit: this.state?.rowsPerPage,
    });
    // console.log(data?.payload?.data?.data);
    // this.setState({ users: data?.payload?.data?.data });
  };

  storeInputSearchValue = (value) => {
    this.setState(
      { searchValue: value, pageNo: this.state.page, pageSize: 25 },
      () => {
        // if (value.length % 3 === 0) {
        this.getEssentials();
        // }
      }
    );
  };

  getUserList = () => {
    console.log("INSIDE")
    this.setState({users: this.props?.masterUsers?.data?.items})
  };

  onResendClick = async (id, role) => {
    
    console.log("RESEND");
    console.log(id);
    console.log(role)
    let data = await this.props.RESEND_INVITE({user_id: id, role})
    console.log(data)
    if(data?.payload?.state === 200)
    {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Invite Sent successfully!",
        severity: alertProps.severity.success,
      });
    }
    else
    {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Something went wrong! Unable to invite.",
        severity: alertProps.severity.error,
      });
    }
  };

  onRearrange = (id) => {
    console.log("REARRANGE");
    this.setState({ ind_sort: this.state.ind_sort === "asc" ? "desc" : "asc" } , () => { this.handleSort(id)});
    // console.log(this.state.ind_sort);
    // this.handleSort(id);
  };

  getSortCatg(id) {
    switch (id) {
      case "email_id":
        return "emailId";
      case "user_name":
        return "name";
      // case "status":
      //   return "enabled";
      case "role":
          return "role"
      case "date_created":
        return "createdAt";
      default:
        return "";
    }
  }

  handleSort(id) {
    let catg = this.getSortCatg(id);
    let dir = this.state.ind_sort
    console.log(dir)
    this.setState({sortField: catg})
    this.props.GET_USERS_MASTER({
      searchKey: this.state.searchValue,
      offset: 0,
      filterRole: this.state?.role.map((x) => x.value),
      sort: {
        sortField: catg,
        // sortDirection: this.state?.sortDirec?.value,
        sortDirection: dir
      },
      limit: this.state?.rowsPerPage,
    });

    this.getUserList()
    
    // let catg = this.getSortCatg(id);
    // this.state.ind_sort
    //   ? this.setState({
    //       users: this.props.masterUsers?.data?.items.sort(function (a, b) {
    //         if (a[catg] > b[catg]) {
    //           return 1;
    //         } else if (a[catg] < b[catg]) {
    //           return -1;
    //         }
    //         return 0;
    //       }),
    //     })
    //   : this.setState({
    //       users: this.props.masterUsers?.data?.items.sort(function (a, b) {
    //         if (a[catg] < b[catg]) {
    //           return 1;
    //         } else if (a[catg] > b[catg]) {
    //           return -1;
    //         }
    //         return 0;
    //       }),
    //     });
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  applyFilter = (state) => {
    // console.log(state)
    // let filter_role =
    // this.setState({filterRoles: filter_role})

    this.setState(
      {
        ...state, //This is obtained from FilterBuilder Component
        pageNo: 0,
        pageSize: 25,
        isFilterApplied: true,
      },
      () => {
        this.getEssentials();
        this.closeFilter();
      }
    );
    // console.log(this.state)
  };

  resetFilter = () => {
    console.log("DATA")
    this.setState(
      {
        ...initialState,
      },
      () => {
        this.getEssentials();
        this.closeFilter();
      }
    );
  };

  onFilterIconClicked = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <FilterBuilder
          components={filterComponent(this.state, this.props)}
          applyButtonLabel="Apply"
          loading={false}
          onChange={this.onFilterChange}
          onApplyButtonClicked={this.applyFilter}
          resetFilter={this.resetFilter}
          onCancelClick={this.closeFilter}
          saveButtonId={`saveFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
          cancelButtonId={`cancelFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
          clearAllButtonId={`clearAllFilterButtonFrom${this.state?.id ? this.state?.id : ""}`}
        />
        // <p>Test</p>
      ),
    });
  };

  closeFilter = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  checkIsThereAnyFilter = () => {
    const newState = this.state;
    try {
      return (!newState[filterTagType.role] ||
        newState[filterTagType.role].length === 0) &&
      (!newState[filterTagType.SortDirection] ||
        newState[filterTagType.SortDirection] !== {}) ? false : true
    } catch (e) {
      return false;
    }
  };

  handleChipDelete = (key, index) => {
    console.log("Key ", key);
    console.log("index ", index);
    if (key === filterTagType.role) {
      let newState = this.state;
      console.log("INSIDE");
      newState[key].splice(index, 1);
      console.log(newState);

      this.setState(
        {
          ...newState,
          // sortDirec: newState.sortDirec,
          pageNo: 0,
          pageSize: 25,
          isFilterApplied: this.checkIsThereAnyFilter(),
        },
        () => {
          this.getEssentials();
        }
      );
    } else if (key === filterTagType.SortDirection) {
      this.setState(
        {
          sortDirec: subHeaderSortOptionWithValue[2],
          pageNo: 0,
          pageSize: 25,
        },
        () => {
          this.setState({
            isFilterApplied: this.checkIsThereAnyFilter(),
          });
          this.getEssentials();
        }
      );
    }
  };

  handleChangePage = async (event, page) => {
    // console.log(event)
    // console.log(page)
    // console.log(event.target.value)
    this.setState({ page: page });
    // 
    // console.log(this.state.page)
    let data = await this.props.GET_USERS_MASTER({
      searchKey: this.state.searchValue, 
      offset: page - 1,
      filterRole: this.state?.role.map((x)=> x.value),
      sort: {sortField: "createdAt", sortDirection: this.state?.sortDirec},
      limit:this.state?.rowsPerPage,
    });
    // console.log(data?.payload?.data?.data)
    this.setState({ users: data?.payload?.data?.data });
  };

  loadNewData = async (page) => {
    console.log(page);
  };

  handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(+event.target.value);
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 0 });
  };

  render() {
    const { classes } = this.props;


    return (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <div style={{ width: "100%" }}>
            <SubHeader
              isbutton={false}
              placeholder={"Search Email"}
              // , Email and Mobile Number
              entityName={"Search"}
              countText={`Total Users (${this.props.masterUsers?.data?.total})`}
              button_name={"Search"}
              link={""}
              //   (this.props.match.path)
              handleSearch={this.getUserList}
              handleChangeInputSearch={this.storeInputSearchValue}
              value={this.state.searchValue}
              useNewFilterIcon
              placeSearchIconToLeft
              isFilterApplied={this.state.isFilterApplied}
              onFilterIconClicked={this.onFilterIconClicked}
              showSortOption={true}
              sort={""}
              // handleSortChange={this.handleSort("name")}
              handleSortChange={() => console.log("DATA")}
              loading={false}
              id={this.state.id}
              // Temporarily hidden
              // hideFilter
              // hideSearch
            />

            {this.state.isFilterApplied && (
              <Grid item xs={12} style={{ marginTop: 25 }}>
                <FilterTags
                  filterData={this.state}
                  handleDelete={this.handleChipDelete}
                />
              </Grid>
            )}

            <div style={{ paddingTop: "1%" }}>
              {!this.state?.user ? (
                <UserTable
                  users={this.props.masterUsers?.data?.items || []}
                  page={this.state.page}
                  total={this.props.masterUsers?.data?.total}
                  rowsPerPage={this.state.rowsPerPage}
                  ind_sort={this.state.ind_sort}
                  sortDirec={this.state.ind_sort}
                  sortField={this.state.sortField}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleChangePage={this.handleChangePage}
                  onResendClick={this.onResendClick}
                  onRearrange={this.onRearrange}
                  loadNewData={this.loadNewData}
                />
              ) : (
                <NotFoundSection />
              )}
            </div>
          </div>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  masterUsers: state?.masterUser?.master_user,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(withStyles(styles)(Users))));
