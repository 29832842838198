import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { SelectandFilter } from "../../../../components/SelectandFilters/index";
import { DateTimePickersCustom } from "../../../../components/dateTimePicker/dateTimePicker";
import moment from "moment";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { alertProps } from "../../../../utils";
import { errorMessage } from "../../../../utils/errorMessages";

const style = (theme) => ({
  checkboxColor: {
    color: theme.palette.ternary.main,
  },
  checkboxColorChecked: {
    color: `${theme.palette.ternary.main} !important`,
  },
  addbtn: {
    marginTop: 16,
    padding: "8px 18px",
    fontSize: 15,
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      padding: "6px 10px",
    },
    "&:hover": {
      marginTop: 16,
      padding: "8px 18px",
      fontSize: 15,
      margin: "auto",
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
        padding: "6px 10px",
      },
    },
  },
  addbtn1: {
    marginTop: 16,
    padding: "8px 18px",
    fontSize: 15,
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      padding: "6px 10px",
    },
    "&:hover": {
      marginTop: 16,
      padding: "8px 18px",
      fontSize: 15,
      margin: "auto",
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
        padding: "6px 10px",
      },
    },
  },
  large: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    position: "fixed",
    right: "410px",
    cursor: "pointer",
    top: 10,
    "& img": {
      objectFit: "scale-down",
      backgroundColor: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      right: "313px",
    },
  },
  drawer_title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 12,
  },
  expiryLabel_root: {
    position: "relative",
    top: 3
  }
});

class CertificateDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      err: {},
      error: {},
      role: null,
      organisation_name: null,
      start_date: null,
      end_date: null,
      present: false,
      cc_isNew: null,
      co_isNew: null,
      eo_isNew: null,
      ec_isNew: null,
      isBlock: false,
      certificate: [],
      experience: [],
      isedit: null,
      name_ck: null,
      name_ckm: null,
      iseditexperience: null,
      // Additional I think
      isdelete: false,
      isdialog: true

      // Newly Added
      //     isBlock: false,
      // iseditexperience: null,
      // open: false,
      // drawer_title: null,
      // isedit: null,
      // name_ck: null,
      // isdelete: false,
      // isdialog: true,
      // name_ckm: null,
      // cc_isNew: null,
      // co_isNew: null,
      // eo_isNew: null,
      // ec_isNew: null,
      // role: null,
      // organisation_name: null,
      // start_date: null,
      // end_date: null,
      // certificate: [],
      // experience: [],
    };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = () => {
    this.setState({
      ...this.props.initialStateInParent
    });
  };

  handleValidation = () => {
    const { err } = this.state;

    let isErrorOccured = false

    let fieldsToValidate = [
      "organisation_name",
      "role",
      "start_date",
      "end_date"
    ];

    fieldsToValidate.map((val) => {
      if (this.state?.[val]?.constructor === Array) {
        if (this.state[val].length > 0) {
          err[val] = false;
        } else {
          err[val] = true;
          isErrorOccured=true;
        }

        // return true;
      } else if (this.state?.[val]?.constructor === Object) {
        if (Object.keys(this.state[val]).length > 0) {
          err[val] = false;
        } else {
          err[val] = true;
          isErrorOccured=true;
        }
      } else if (this.state[val]) {
        err[val] = false;
      } else {
        err[val] = true;
        isErrorOccured=true;
      }
      return val;
    });
    this.setState({ err: err });
    return isErrorOccured
  };

  updateDataInState = (value, key, type, certOrExp, cleanUpKeys = []) => {
    const state = this.state;
    if (certOrExp?.includes("Certification")) {
      if (key === "organisation_name") {
        state.cc_isNew = value?.__isNew__ ? 0 : null;
        this.setState({ ...state, [key]: value?.label }, () => {
          const { err } = this.state;
          err[key] = false;
          this.setState({ ...state });
        });
      }
      if (key === "role") {
        state.co_isNew = value?.__isNew__ ? 0 : null;
        this.setState({ ...state, [key]: value?.label }, () => {
          const { err } = this.state;
          err[key] = false;
          this.setState({ ...state });
        });
      }
    } else {
      if (key === "organisation_name") {
        state.ec_isNew = value?.__isNew__ ? 0 : null;
        this.setState({ ...state, [key]: value?.label }, () => {
          const { err } = this.state;
          err[key] = false;
          this.setState({ ...state });
        });
      }
      if (key === "role") {
        state.eo_isNew = value?.__isNew__ ? 0 : null;
        this.setState({ ...state, [key]: value?.label }, () => {
          const { err } = this.state;
          err[key] = false;
          this.setState({ ...state });
        });
      }
    }
    if (type === "number" && value.length > 10) {
      return false;
    }

    const { error } = this.state;
    error[key] = false;
    let newState = this.state;
    newState[key] = value;
    cleanUpKeys.map((_) => {
      newState[_] = "";
      return _;
    });

    this.setState({ ...newState, isBlock: true });
  };

  Checkboxhandle = () => {
    const state = this.state;
    if (state.present) {
      this.setState({
        ...this.state,
        end_date: null,
        present: !this.state.present
      });
    } else {
      this.setState({
        ...this.state,
        end_date: moment("01/01/2170"),
        present: !this.state.present
      });
    }
  };

  closeDrawer = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>
    });
  };

  // CERTIFICATE AND EXPERIENCE CALLBACKS
  handleCertExpAdd = (drawerName) => {
    const state = this.state;
    //Decide if it is certificate (or) experience
    let certOrExp =
      drawerName === "Edit Certification" || drawerName === "Add Certification"
        ? "certificate"
        : "experience";
    let orglabel = state?.organisation_name?.label;
    let orgvalue = state?.organisation_name?.value;
    let roleLabel = state?.role?.label;
    let roleValue = state?.role?.value;

    // Error Handling
    // Checking if Organisation name and role - label and value exists
    if (!state.organisation_name?.label) {
      state.err["organisation_name"] = true;
      state.err["organisation_namemsg"] = "Please enter Organisation Name";
      this.setState({ ...state });
      return false;
    } else if (!state.role?.label) {
      state.err["role"] = true;
      state.err["rolemsg"] = "Please enter Role";
      this.setState({ ...state });
      return false;
    } else if (!state.start_date) {
      state.err["start_date"] = true;
      state.err["start_datemsg"] = "Please enter Date";
      this.setState({ ...state });
      return false;
    } else if (!state.end_date) {
      state.err["end_date"] = true;
      state.err["end_datemsg"] = "Please enter Date";
      this.setState({ ...state });
      return false;
    } else {
      // if does not exists

      // Checking if isEdit is 0 (or) above 0
      if (state.isedit === 0 || state.isedit > 0) {
        state[certOrExp] = state[certOrExp].map(
          (certificateEditData, index) => {
            // Take copy of certificare Edit Data
            let tempCertificateStore = Object.assign({}, certificateEditData);

            // Check if the drawer name includes certification, then it is certification drawer
            if (drawerName.includes("Certification")) {
              if (
                tempCertificateStore.certificateId === state.isedit &&
                tempCertificateStore.organizationId === state.name_ck &&
                tempCertificateStore.certificateName === state.name_ckm
              ) {
                tempCertificateStore.certificate =
                  state.cc_isNew === 0 ? 0 : orgvalue;
                tempCertificateStore.certificateName = orglabel;
                tempCertificateStore.organizationName = roleLabel;
                tempCertificateStore.organizationId =
                  state.co_isNew === 0 ? 0 : roleValue;
                tempCertificateStore.startingFrom = state.start_date;
                tempCertificateStore.endingIn = state.end_date;
                tempCertificateStore.dnd = `"${tempCertificateStore.dnd}"`;
              }
              return tempCertificateStore;
            } else {
              // Else it is experience
              if (
                tempCertificateStore.roleId === state.isedit &&
                tempCertificateStore.organizationId === state.name_ck &&
                tempCertificateStore.organizationName === state.name_ckm
              ) {
                tempCertificateStore.roleId =
                  state.ec_isNew === 0 ? 0 : roleValue;
                tempCertificateStore.roleName = roleLabel;
                tempCertificateStore.organizationName = orglabel;
                tempCertificateStore.organizationId =
                  state.eo_isNew === 0 ? 0 : orgvalue;
                tempCertificateStore.startingFrom = state.start_date;
                tempCertificateStore.endingIn = state.end_date;
                tempCertificateStore.dnd = `"${tempCertificateStore.dnd}"`;
              }
              return tempCertificateStore;
            }
          }
        );
      } else {
        if (drawerName.includes("Certification")) {
          let arr = Object.assign([], state[certOrExp]);
          arr.push({
            certificateId: state.cc_isNew === 0 ? 0 : orgvalue,
            certificateName: orglabel,
            organizationName: roleLabel,
            organizationId: state.co_isNew === 0 ? 0 : roleValue,
            startingFrom: state.start_date,
            endingIn: state.end_date,
            dnd: `"${state.certificate?.length + 1}"`,
            orderNo: state.certificate?.length + 1
          });
          state[certOrExp] = arr;
        } else {
          let arr = Object.assign([], state[certOrExp]);
          arr.push({
            roleId: state.eo_isNew === 0 ? 0 : roleValue,
            roleName: roleLabel,
            organizationName: orglabel,
            organizationId: state.ec_isNew === 0 ? 0 : orgvalue,
            startingFrom: state.start_date,
            endingIn: state.end_date,
            orderNo: state.experience?.length + 1,
            dnd: `"${state.experience?.length + 1}"`
          });
          state[certOrExp] = arr;
        }
      }
    }
    this.setState(
      {
        ...state
      },
      () => {
        const { error } = this.state;
        if (drawerName?.includes("Certification")) {
          error["certificate"] = false;
        } else {
          error["experience"] = false;
        }
        this.setState({
          ...state,
          isedit: null,
          name_ck: null,
          name_ckm: null,
          open: !this.state.open
        });
      }
    );
  };

  handleAddAndDrawerClose = (drawer_title) => {
    
    if (this.handleValidation()) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      // First Add
      this.handleCertExpAdd(drawer_title);
      // Then Update the child state to parent
      this.props.updateStateInParent(this.state);
      // Finally, Close the drawer.
      this.closeDrawer();
    }
  };

  render() {
    const { classes, actionProps } = this.props;

    const { drawer_title, checklist } = this.props?.mandatoryProps;

    return (
      <>
        <Grid container style={{ padding: 20 }}>
          {/* <Avatar
            src="/images/close.svg"
            className={classes.large}
            onClick={() => {
              this.handledrawer();
            }}
          /> */}
          <Typography variant="h5" className={classes.drawer_title}>
            {drawer_title}
          </Typography>

          {/* select Organisation name */}
          <SelectandFilter
            label={checklist ? "certification name" : "Organisation name"}
            data={
              checklist
                ? actionProps?.certificates
                : actionProps?.exp_organization
            }
            // data={[{ label: 'a', value: 1 }, { label: 'b', value: 2 }, { label: 'c', value: 3 }]}
            value={this.state.organisation_name}
            handleOnChange={(e) => {
              this.updateDataInState(
                e,
                "organisation_name",
                null,
                drawer_title
              );
            }}
            error={this.state.err?.organisation_name ? true : false}
            errorText={this.state.err?.organisation_name && errorMessage.requiredMessage}
            isLoading={
              checklist
                ? actionProps?.certificatesLoading
                : actionProps?.cert_organizationLoading
            }
          />
          {/* end */}

          {/* select Role */}
          <SelectandFilter
            label={checklist ? "Issuing Organization" : "Role"}
            value={this.state.role}
            handleOnChange={(e) => {
              this.updateDataInState(e, "role", null, drawer_title);
            }}
            error={this.state.err?.role ? true : false}
            errorText={this.state.err?.role && errorMessage.requiredMessage}
            isLoading={
              checklist
                ? actionProps?.exp_organizationLoading
                : actionProps?.exp_rolesLoading
            }
            data={
              checklist
                ? actionProps?.cert_organization
                : actionProps?.exp_roles
            }
          />
          {/* end */}

          <FormControlLabel
            classes={{label: classes.expiryLabel_root}}
            control={
              <Checkbox
                color="primary"
                style={{ margin: "12px 0px 8px 0px" }}
                checked={this.state.present}
                onChange={(value) => {
                  this.Checkboxhandle(value, "present");
                }}
                classes={{root: classes.checkboxColor, checked: classes.checkboxColorChecked}}
                name="Present"
              />
            }
            label={checklist? "No Expiry" : "Present"}
          />

          {/* date picker */}
          <div style={{ margin: "8px 0px 12px 0px", width: "100%" }}>
            <DateTimePickersCustom
              isDate={true}
              label={checklist ? "Issue date" : "Start date"}
              value={this.state.start_date}
              error={this.state.err.start_date}
              errorText={this.state.err.start_date && errorMessage.requiredMessage}
              handleOnChange={(e) => {
                this.updateDataInState(e, "start_date");
              }}
              isHideFutureDate={true}
            />
          </div>

          {!this.state.present && (
            <div style={{ margin: "8px 0px 12px 0px", width: "100%" }}>
              <DateTimePickersCustom
                isDate={true}
                label={checklist ? "Expiry date" : "End date"}
                value={this.state.end_date}
                // minDate={moment(this.state?.start_date)?.add(1, 'days')} // Should not be before start date
                // minDate={ moment(this.state?.start_date).isValid() ? new Date(this.state?.start_date, this.state?.start_date?.getDate()+1) : null } // Should not be before start date
                // minDate={this.state?.start_date}
                minDate={moment(this.state?.start_date)
                  ?.add(1, "days")
                  ?.toDate()}
                error={this.state.err.end_date}
                // errorMessage.requiredMessage
                errorText={this.state.err.end_date && errorMessage.requiredMessage}
                handleOnChange={(e) => {
                  this.updateDataInState(e, "end_date");
                }}
                isHideFutureDate={!checklist ? true : false}
                // hideMaxDateLogic={true}
              />
            </div>
          )}
          {/* end */}

          {/* {add and edit experience and certification buttons} */}
          <Button
            style={{
              padding:
                drawer_title === "Edit Experience" ||
                drawer_title === "Edit Certification"
                  ? "8px 50px"
                  : ""
            }}
            variant="contained"
            color="primary"
            className={classes.addbtn}
            // TODO: Completed
            // onClick={() => this.handleCertExpAdd(drawer_title)}
            onClick={() => this.handleAddAndDrawerClose(drawer_title)}
          >
            {drawer_title === "Edit Experience" ||
            drawer_title === "Edit Certification"
              ? "Save"
              : " + " + drawer_title}
          </Button>
          <Button
            variant={"outlined"}
            color={"primary"}
            // TODO: Completed
            // onClick={() => this.handledrawer()}
            onClick={() => this.closeDrawer()}
            className={classes.addbtn1}
          >
            {"Cancel"}
          </Button>
          {/* end */}
          {/* {
                (this.state?.drawer_title === "Edit Experience" || this.state?.drawer_title === "Edit Certification") &&
                <Typography className={classes.dlt} onClick={() => this.ondltList(drawer_title)}><img width="6%" src="/images/icons8-trash-can.svg" alt="delete" /><span>Delete this experience</span></Typography>
              } */}
        </Grid>
      </>
    );
  }
}

export default withAlelrtAndDialog(withStyles(style)(CertificateDrawer));
