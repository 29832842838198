import React from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
const useStyles = makeStyles((theme) => ({
  oppositeContent: {
    flex: 0,
    padding: "6px"
  },
  connectorStyle: {
    backgroundColor: theme.palette.primary.main,
  },
  ellipsisLine: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    lineHeight: 1.8,
    paddingTop: "0px",
    paddingRight: 0,
  }
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    cursor: "pointer"
  },
}))(Tooltip);

export default function ProductTimeline({ sequenceData: data, lineHidden }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Timeline style={{paddingRight: 0}}>
        {data?.length > 0 && data?.map((x, index) => (
            <TimelineItem style={{minHeight: lineHidden ? "30px" : "70px"}}>
              <TimelineOppositeContent
                className={classes.oppositeContent}
              ></TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                {lineHidden ? '' : 
                  index+1 === data.length ? '' : <TimelineConnector classes={{ root: classes.connectorStyle }} />
                }
              </TimelineSeparator>
              <TimelineContent style={{padding: "0px 16px 6px 16px"}}>
                <HtmlTooltip disableHoverListener={x.label?.length > 50 ? false : true} title={x.label} placement="top-start" >
                     <Typography className={classes.ellipsisLine} >{x.label}</Typography>
                </HtmlTooltip>
                {x?.dependentService?.length > 0 && 
                    x?.dependentService?.map((val, i) => {
                      return (<>
                       {i === 0 && 
                        <TimelineItem style={{minHeight: "5px"}}>
                          <TimelineOppositeContent className={classes.oppositeContent} style={{paddingRight: "8px"}}></TimelineOppositeContent>
                          <TimelineSeparator>
                            {/* <TimelineConnector style={{width: "1.5px", backgroundColor: "#FFBE9F"}} />  */}
                          </TimelineSeparator>
                          <TimelineContent>{""}</TimelineContent>
                        </TimelineItem>
                        }
                        <TimelineItem style={{minHeight: "30px"}}>
                          <TimelineOppositeContent className={classes.oppositeContent}></TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot style={{ padding: "2px", backgroundColor: "#FFBE9F"}} />
                            {/* {i+1 === x?.dependentService?.length ? '' : <TimelineConnector style={{width: "1.5px", backgroundColor: "#FFBE9F"}} />}  */}
                          </TimelineSeparator>
                          <HtmlTooltip disableHoverListener={val.label?.length > 50 ? false : true} title={val.label} placement="top-start" >
                             <TimelineContent className={classes.ellipsisLine} >{val.label}</TimelineContent>
                          </HtmlTooltip>
                        </TimelineItem>
                        </>)
                    })
                }
                
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </React.Fragment>
  );
}
