import React from 'react';

import SectionName from '../../../../components/sectionNameBlock'
import { withStyles, Grid, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { routes } from '../../../../router/routes'
import { actions } from 'tanyacare-middleware'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { RecommendSection } from './recommendedSection'
import ListLoader from '../../../../components/listLoader';
import { ListCard, NotFoundSection } from '../../../../components';
import List from "../../../../components/userList/cardList";

const sizes = {
    md: 3,
    sm: 3,
    xs: 3,
};

const styles = (theme) => ({
    root: {
        padding: 25
    },
    trackRecommendation: {
        background: 'white',
        borderRadius: 8,
        padding: '8px 16px',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    textHolder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
})

class Recommendations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.getRecommendations()
    }

    getRecommendations = () => {
        const client_id = this.props?.match?.params?.client_id;
        this.props.CLIENT_RECOMMENDED_EQUIPMENTS({ client_id });
        this.props.CLIENT_RECOMMENDED_STUFFS({ client_id });
    }

    navigateToRecommendedProducts = () => {
        const id = this.props?.match?.params?.client_id;

        this.props.history.push(routes.monitor_client_recommendation_parent + 'products/' + id)
    }

    render() {
        let { classes } = this.props

        let {
            loading,
            error,
            data
        } = this.props?.client_rec_stuffs;

        let rec_equipments = this.props?.rec_equipments;

        return (
            <Grid container className={classes.root}>
                {/* Track Recommendations */}
                <Grid item container justify="space-between" className={classes.trackRecommendation}
                    onClick={this.navigateToRecommendedProducts}
                >
                    <Grid item className={classes.textHolder}>
                        <Typography variant="body1" >Track Recommendations</Typography>
                    </Grid>
                    <Grid item>
                        <ChevronRightIcon />
                    </Grid>
                </Grid>

                {/* Section 2 - Equipment Recommended */}
                <Grid item xs={12}>
                    <SectionName title={"Equipments"} hideViewBtn={!rec_equipments?.length} actionText={rec_equipments?.length ? "VIEW ALL" : ""} />
                </Grid>
                <Grid item xs={12}>
                    <RecommendSection data={rec_equipments} />
                </Grid>

                {/*Section-3 Packages  */}
                <Grid item xs={12}>
                    <SectionName title={"Recommendations"} hideViewBtn={!data?.length} actionText={data?.length ? "VIEW ALL" : ""} />
                </Grid>
                {/* Packages should be added here */}
                <Grid item xs={12}>
                    {loading && <ListLoader />}
                    {!loading && error && <div style={{ width: '100%' }}><Typography variant="body1" align="center">Something went wrong! Try again</Typography></div>}
                    {!loading && !error && data && data?.map((_, k) => {
                        if (_.product_service_package === 'Product') {
                            return <List
                                list={_}
                                showCareProvider={true}
                                componentOrder={1}
                                sizes={sizes}
                                propsList={{
                                    isCard: true,
                                }}
                                hideQuickSummaryLabel
                            />
                        } else {
                            return <ListCard data={_} />
                        }
                    })}
                    {!loading && !error && data?.length === 0 && <div style={{ width: "100%" }}>
                        <NotFoundSection message="No products to recommend" top="20px" />
                    </div>}
                </Grid>
            </Grid>
        )

    }
}

const mapStateToProps = (state) => ({
    client_rec_stuffs: state?.client_monitor?.client_rec_stuffs,
    rec_equipments: state?.client_monitor?.rec_equipments
})

export default connect(mapStateToProps, actions)(withRouter(withStyles(styles)(Recommendations)));