/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-01-27 20:47:30
 * @modify date 2022-01-27 20:47:30
 */
import React from "react";
import PropTypes from "prop-types";

import { SvgIcon } from "@material-ui/core";

function PriorityIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.59 4.856 11 8.846h2.872v1.596c0 4.838 3.545 8.777 7.898 8.777h2.155v-1.596h-2.154c-3.575 0-6.463-3.209-6.463-7.181V8.846h2.872l-3.59-3.99ZM0 19.333V17.5h11v1.833H0Zm0-3.666v-1.834h9.167v1.834H0ZM0 12v-1.833h7.333V12H0Zm0-3.667V6.5h5.5v1.833H0Z"
        fill={props.color}
      />
    </SvgIcon>
  );
}

PriorityIcon.propTypes = {
  color: PropTypes.string,
};

PriorityIcon.defaultProps = {
  color: "#0063E7",
};

export default PriorityIcon;
