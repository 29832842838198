import React from "react";
import Typography from "@material-ui/core/Typography";
import { CustomAvatar } from "../../../../../../components";
import { IconComponent } from "../../../../../../assets";
import styled from "styled-components";
import { makeStyles, useTheme, alpha } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import CheckInCheckOutCard from "../checkInCheckOutCard";
import {
  getFormattedGenderAgeString,
} from "../../../../../../utils/common";
import moment from "moment";

// InfoWithIcon component
const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 32px;
  margin-right: 30px;
  line-height: 24px;
`;

const InfoWithIcon = ({ iconName, children, style }) => {
  const theme = useTheme();
  return (
    <InfoContainer style={{ ...style }}>
      <IconComponent name={iconName} />
      <Typography
        style={{
          fontSize: 14,
          fontStyle: "italic",
          fontWeight: 400,
          color: theme.palette.text.secondary,
        }}
      >
        {children}
      </Typography>
    </InfoContainer>
  );
};

InfoWithIcon.propTypes = {
  iconName: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
};

const Container = styled.div`
  padding: 16px;
`;

const AppointmentStatusChip = styled.div`
  font-size: 12px;
  font-weight: 500;
  padding: 12px;
  background-color: ${(props) => alpha(props.color || "#eaeaea", 0.16)};
  color: ${(props) => props.color || "#eaeaea"};
  text-transform: uppercase;
  display: inline-block;
  border-radius: 6px;
`;

const ProfessionalInfo = styled.div`
  display: flex;
  gap: 16px;
  margin: 16px 0px;
`;

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 16,
    fontSize: "18px",
    fontWeight: 400,
  },
  professionalName: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 8,
  },
  professionalGenderAge: {
    fontSize: 14,
    fontWeight: 400,
  },
}));

const getFormattedTime = (date) => {
  if (!date || !moment(date).isValid()) return "--";
  return moment(date).format("hh:mm A");
};

function AppointmentCard({ appointmentDetail }) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    name,
    status,
    client: {
      name: clientName,
      profile_pic: clientProfilePicId,
      gender: clientGender,
      dob: clientDob,
    },
    address: clientAddress,
    activity_type: { activity_type: activityType },
    check_in: checkIn,
    check_out: checkOut,
  } = appointmentDetail;

  return (
    <Container>
      <Typography className={classes.title}>{name}</Typography>
      <AppointmentStatusChip color={status?.toLowerCase() === "scheduled" ? theme.palette.success.main : theme.palette.secondary.main}>
        {status}
      </AppointmentStatusChip>
      <ProfessionalInfo>
        <CustomAvatar
          src={clientProfilePicId}
          classes={{ root: { margin: 0 } }}
        />
        <div>
          <Typography className={classes.professionalName}>
            {clientName}
          </Typography>
          <Typography className={classes.professionalGenderAge}>
            {getFormattedGenderAgeString(clientGender, clientDob)}
          </Typography>
        </div>
      </ProfessionalInfo>
      {clientAddress && (
        <InfoWithIcon iconName={"location"} style={{ marginBottom: 20 }}>
          {clientAddress}
        </InfoWithIcon>
      )}
      {activityType && (
        <InfoWithIcon iconName={"healthcare"} style={{ marginBottom: 26 }}>
          {activityType}
        </InfoWithIcon>
      )}
      <CheckInCheckOutCard
        leftText={"Check - In"}
        leftSubText={getFormattedTime(checkIn)}
        rightText={"Check - Out"}
        rightSubText={getFormattedTime(checkOut)}
      />
    </Container>
  );
}

export default AppointmentCard;

AppointmentCard.defaultProps = {
  appointmentDetail: {},
};

AppointmentCard.propTypes = {
  appointmentDetail: PropTypes.object,
};
