import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  header: {
    height: "10%",
    // height: 44,
    [theme.breakpoints.down("xs")]: {
      height: 60,
      textAlign: "center",
      // marginTop: 64
      marginTop: 26
    }
  },
  logo: {
    width: 150,
  },
}))

export default function HeaderLogo(props) {
  const classes = useStyles(props);
  return (
    <>
      <Grid
        container
        xs={12}
        justify="flex-start"
        className={ props.customHeaderStyle ? props.customHeaderStyle : classes.header}
      >
        <Grid item xs={12} style={{marginTop: 8}}>
          <img
            src="/images/tanyaCare/TanyaHorizontal.png"
            // src="/svg/tanyacareLogo/tanyacareLight.svg"
            alt="Tanya.Care"
            className={classes.logo}
          />
        </Grid>
      </Grid>
    </>
  )
}
