/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-02-18 12:30:41
 * @modify date 2022-02-18 12:30:41
 * @desc Handle Data fetching in this component and pass those data to child components
 */

import React, { useState, useEffect } from "react";
import { MapWithTimeline } from "./components";
import { actions } from "tanyacare-middleware";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { io } from "socket.io-client";

const BASE_URI = `${process.env.REACT_APP_PROTOCAL}://${process.env.REACT_APP_API_SERVER_HOST}`;

// Actions
const { GET_ALL_SERVICE_PROFESSIONAL_ACTIVITIES } = actions;

const SOCKET_EVENTS = {
  CONNECT: "connect",
  CONNECT_ERROR: "connect_error",
  JOIN_ROOM: "JOIN_ROOM",
  RECEIVE_SP_LOCATION: "RECEIVE_SP_LOCATION",
  SP_LEFT: "SP_LEFT",
  LEAVE_ROOM: "LEAVE_ROOM",

  // SEND_SP_LOCATION: "SEND_SP_LOCATION",
  SP_JOINED: "SP_JOINED",
  DISCONNECT: "disconnect",
};

// const token =
//   "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJvX1N2UGFwcFhhRk9laTR1UjZhMFpQRmxRdXA5R2Q1akNGWXR4YXNUZWxrIn0.eyJleHAiOjE2NDU2NTExNjYsImlhdCI6MTY0NTYxNTE2NiwianRpIjoiMzc0YTlkZDYtYjE2Mi00YzI3LWI5OTYtNTQ5ZTliMjVjMWIwIiwiaXNzIjoiaHR0cDovL2tleWNsb2FrLmRldi5haW5xYXBsYXRmb3JtLmluL2F1dGgvcmVhbG1zL2RldnRjIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6IjRhYjMyZTM2LWRiOWMtNGZiYi1iNTFlLWJhYzE0NTJiMmNjZiIsInR5cCI6IkJlYXJlciIsImF6cCI6ImRldnRjLWNsaWVudCIsInNlc3Npb25fc3RhdGUiOiJlMzY4Y2JmYS02NmRjLTQ3ZTAtYjE0YS0wOThjZGJlZjBmNzEiLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbIioiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbInNlcnZpY2VfcHJvZmVzc2lvbmFsIiwiZGVmYXVsdC1yb2xlcy10YW55YWNhcmUiXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJzaWQiOiJlMzY4Y2JmYS02NmRjLTQ3ZTAtYjE0YS0wOThjZGJlZjBmNzEiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsIm5hbWUiOiJNYW5vamt1bWFyIFByb2Zlc3Npb25hbCIsInByZWZlcnJlZF91c2VybmFtZSI6Im1hbm9qa3VtYXIubTg0OTcrMDNAZ21haWwuY29tIiwiZ2l2ZW5fbmFtZSI6Ik1hbm9qa3VtYXIgUHJvZmVzc2lvbmFsIiwiZmFtaWx5X25hbWUiOiIiLCJlbWFpbCI6Im1hbm9qa3VtYXIubTg0OTcrMDNAZ21haWwuY29tIn0.GqqvMwbsJqJD9uuwcDgjV0_qXQOIaOnxQjY3Pphwmzl8CZYS6BA21fHl-sMbfUDn6escxYvIwWnN1QpU5C8ivJzWaWkpbTOVHm31KrXsDJUdFHuFk6o8-yKktoz6Q5WzrNnNtCr7PeRwmkBJ9WrhKsocB14P-XMCfvejZQEE-6aHCpuvQTZXllck8w-M_njvk1jeRVg_KH7zy2ZWEx-wrsJ8sp5mQyG7--n00xKandLiUohkXoa7071F91Cvb_9s7v0Y2cYjmeiaA1M0OWIakIXTxIb384Jw4eI5Hhu4b9ZSEFiLnHtrbN_k2DY6NS42q8DMKJm2K1Qy-91ODLZSTQ";

function RealTimeLocationTracking() {
  const [date, setDate] = useState(new Date()); //new Date("Mar 02 2022")
  const dispatch = useDispatch();
  const { id: professionalId } = useParams();

  // Store States
  const {
    loading,
    error,
    data: serviceProfessionalActivities,
  } = useSelector(
    (state) =>
      state?.service_professional_monitor?.getActivitiesOfServiceProfessional
  );
  const token = useSelector(
    (state) => state?.authState?.loginAuth?.data?.access_token
  );

  const [spEmittedData, setSPEmittedData] = useState([]);
  const [socket, setSocket] = useState(null);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [isSPJoinedRoom, setIsSPJoinedRoom] = useState(false); // eslint-disable-line
  const [joinedToRoom, setJoinedToRoom] = useState(false); // eslint-disable-line
  // Note: Add a useeffect with token as dependecy for reconnecting with new token and joining the room.

  useEffect(() => {
    if (!socket) {
      const socketInit = io(`${BASE_URI}/api/v1/realTimeLocation`, {
        auth: {
          token: token,
        },
      });
      setSocket(socketInit);
    }
  }, [socket, token]);

  useEffect(() => {
    // If Socket is not initialized return
    if (socket === null) return;

    const connectListener = () => {
      setIsSocketConnected(true);
    };

    const connectErrorListener = () => {
      setIsSocketConnected(false);
      console.error(
        "You are not authorized to join the WS connection. Refresh to try connecting again if it might be an system issue."
      );
    };

    const receiveSPLocationListener = (serviceProfessionalEmittedData) => {
      console.log("Received SP Location");
      console.log(serviceProfessionalEmittedData);
      // const latLongList = [...spEmittedData];
      // latLongList.push(serviceProfessionalEmittedData);

      // console.log("Appended SP Location", latLongList);
      // setSPEmittedData(latLongList);
      setSPEmittedData([serviceProfessionalEmittedData]);
    };

    const disconnectListener = (reason) => {
      console.log("DISCONNECTED FROM SERVER", reason);
    };

    // Listen for "connect" event which is emitted on successfull handshake with server
    socket.on(SOCKET_EVENTS.CONNECT, connectListener);

    // Listen for "connect_error" event which is emitted on rejected handshake with server
    socket.on(SOCKET_EVENTS.CONNECT_ERROR, connectErrorListener);

    // Listen for "RECEIVE_SP_LOCATION"
    socket.on(SOCKET_EVENTS.RECEIVE_SP_LOCATION, receiveSPLocationListener);

    // Listen for disconnect
    socket.on(SOCKET_EVENTS.DISCONNECT, disconnectListener);

    return () => {
      socket.off(SOCKET_EVENTS.CONNECT, connectListener);
      socket.off(SOCKET_EVENTS.CONNECT_ERROR, connectErrorListener);
      socket.off(SOCKET_EVENTS.RECEIVE_SP_LOCATION, receiveSPLocationListener);
      socket.disconnect();
      socket.off(SOCKET_EVENTS.DISCONNECT, disconnectListener);
    };
  }, [socket]);

  useEffect(() => {
    if (isSocketConnected) {
      socket.emit(
        SOCKET_EVENTS.JOIN_ROOM,
        professionalId,
        ({ message, error }) => {
          if (error) {
            setIsSPJoinedRoom(false);
          } else {
            console.log(message);

            // If my connection successfully joined the room, then the SP should have joined the room too.
            setIsSPJoinedRoom(true);
            setJoinedToRoom(true);
          }
        }
      );
    }
  });

  // Lifecycle
  useEffect(() => {
    if (professionalId) {
      const getServiceProfessionalActivities = (fromTime = "", toTime = "") => {
        dispatch(
          GET_ALL_SERVICE_PROFESSIONAL_ACTIVITIES({
            serviceProfessionalId: professionalId,
            fromTime:
              moment(fromTime).isValid &&
              moment(fromTime).startOf("day").toISOString(),
            toTime:
              moment(toTime).isValid &&
              moment(toTime).endOf("day").toISOString(),
            filter: { mode: "IN_PERSON", activityStatus: "" },
          })
        );
      };

      getServiceProfessionalActivities(date, date);
    }
  }, [dispatch, professionalId, date]);

  return (
    <div>
      {/* <div>isSPJoinedRoom : {isSPJoinedRoom ? "true" : "false"}</div>
      <div>joinedToRoom : {joinedToRoom ? "true" : "false"}</div> */}

      {/* {spEmittedData.map((data) => (
        <div>{data.latitude}</div>
      ))} */}
      <MapWithTimeline
        loading={loading}
        error={error}
        // Appointments Timeline Props
        selectedDate={date}
        onDateChange={setDate}
        appointments={serviceProfessionalActivities}
        // Map Props
        spEmittedData={spEmittedData?.[0]}
      />
    </div>
  );
}

export default RealTimeLocationTracking;
