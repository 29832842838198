import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { routes } from "../../router/routes";

import { withNavBars } from "./../../HOCs";
import AvailabilityCalendar from './calendar';
import ManageAvailability from './manageAvailability';
import AddEditAvailability from "./manageAvailability/addEditAvailability";
import AddEditException from "./manageAvailability/addEditException";
import ViewAvailabilityHistory from './manageAvailability/viewHistory'

class Availability extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { match } = this.props;

        return (
            <>
                {/* Availability Calendar */}
                {match.path === routes.availability_calendar && <AvailabilityCalendar />}

                {/* Manage Availability */}
                {match.path === routes.manage_availability && <ManageAvailability />}

                {/* Set New Availability */}
                {match.path === routes.set_availability && <AddEditAvailability />}

                {/* Edit Availability */}
                {match.path === routes.edit_availability && <AddEditAvailability isEdit={true} />}

                {/* Add Exception */}
                {match.path === routes.add_exception && <AddEditException />}

                {/* Edit Exception */}
                {match.path === routes.edit_exception && <AddEditException isEdit={true} />}

                {/* View History */}
                {match.path === routes.view_availabiliy_history && <ViewAvailabilityHistory />}

            </>
        );
    }
}

Availability.propTypes = {
    title: PropTypes.string,
    withBackButton: PropTypes.bool,
    navBarArr: PropTypes.array,
};

Availability.defaultProps = {
    title: "Availability",
};

export default withRouter(withNavBars(Availability));

