import React from "react";
import AddEditFormBuilder from "../addEditForm";
import {
  formComponentTypes,
  alertProps,
  // timezoneOld as timezone
} from "../../utils";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";
// import ConflictList from "../../conflictList";
import PropTypes from "prop-types";
import ProfileDrawer from "../profileDrawer";

const fullWidth = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
}
class TaxComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {

    };
  }

  componentDidMount() {
    this.getActivityData()
  }

  getActivityData = () => {
    try {
      this.props.GET_TAX_INTEGRATION({});
    } catch {
      console.error("Tax Master Options Network call Error")
    }
  };

  onSaveButtonClicked = (isError, data, isUrl) => {
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
    } else {
      if (this.props?.editData) {
        data.id = this.props?.editData.id
      }
      this.props.callBackOnSave(data)

      if(this.props.profileDrawerProps){
        this.props.drawer.setDrawer({
          ...this.props.drawer,
          component: (
            <ProfileDrawer
              loginData={{ ...this.props?.profileDrawerProps?.loginData }}
            />
          ),
          open: true,
        });
      }
    }
  };

  onCancelButtonClicked = () => {
    if (this.props.profileDrawerProps) {
      this.props.drawer.setDrawer({
        ...this.props.drawer,
        component: (
          <ProfileDrawer
            loginData={{ ...this.props?.profileDrawerProps?.loginData }}
          />
        ),
        open: true,
      });
    } else {
      this.props.drawer.setDrawer({
        ...this.props.drawer,
        open: false,
        component: <></>,
      });
    }
  };

  render() {
    const { data: options } = this.props?.taxTypeMaster
    const taxTypeOptions = options?.map(x => ({ ...x, label: x?.name }))
    let isEdit = this.props.isEdit;

    let list = this.props.list;
    let selectedList = []
    let data = this.props?.editData;
    let id = this.props?.id;
    if (list && list.length > 0) {
      selectedList = list.map(val => val.value)
    }
    if (data && data.length > 0 && selectedList.length > 0) {
      selectedList = selectedList.filter(val => val !== data.value)
    }
    const components = {
      section_1: {
        label: !isEdit ? "Add Tax ID" : "Edit Tax ID",
        fields: {
          tax_name: {
            key: "tax_name",
            type: formComponentTypes.SELECT_BOX,
            id: `selectAdditionalTaxTypeFrom${id ? id : ''}`,
            componentProps: {},
            breakPoints: fullWidth,
            label: "Tax Name",
            value: { label: data?.label, value: data?.value },
            // options: this.props.emailType?.data
            //   ? selectedList.length > 0 ? this.props.emailType?.data.filter(val => selectedList.indexOf(val.value) === -1)
            //     : this.props.emailType?.data
            //   : [],
            // options: [{label: "Sales Tax", value: "10%"}],
            options: taxTypeOptions ? selectedList.length > 0 ? taxTypeOptions?.filter(val => selectedList.indexOf(val.value) === -1)
              : taxTypeOptions
              : [],
            isMandatory: true,
          },
          taxRegistrationNumber: {
            key: "taxRegistrationNumber",
            type: formComponentTypes.INPUT_BOX,
            id: `addAdditionalTaxFrom${id ? id : ''}`,
            componentProps: {
              type: "text",
            },
            breakPoints: fullWidth,
            label: "Registration Number",
            value: data?.taxRegistrationNumber,
            isMandatory: true
          },
          effective_expiry: {
            key: {
              effective: "effective",
              noExpiry: "noExpiry",
              expiry: "expiry"
            },
            type: formComponentTypes.EFFECTIVE_EXPIRY,
            value: {
              effective: data?.effective ?? null,
              noExpiry: data?.noExpiry ?? false,
              expiry: data?.expiry ?? null
            },
            breakPoints: fullWidth,
            isMandatory: true,
          },
        },
      },
    };
    return (
      <>
        {isEdit ? (
          <>
            <AddEditFormBuilder
              components={components}
              onSaveButtonClicked={this.onSaveButtonClicked}
              cancelForm={this.onCancelButtonClicked}
              isUrl={true}
              saveButtonLabel={"Update"}
              activityEdit={isEdit}
              spacing={1}
              sectionGutterBottom={16}
              saveButtonId={`addSaveTaxButtonFrom${id ? id : ''}`}
              cancelButtonId={`addCancelTaxButtonFrom${id ? id : ''}`}
            />
          </>
        ) : (
          <AddEditFormBuilder
            components={components}
            onSaveButtonClicked={this.onSaveButtonClicked}
            cancelForm={this.onCancelButtonClicked}
            // loading={addLoading}
            saveButtonLabel={"Add Tax"}
            withoutBoxShadow={true}
            spacing={1}
            sectionGutterBottom={16}
            saveButtonId={`addSaveTaxButtonFrom${id ? id : ''}`}
            cancelButtonId={`addCancelTaxButtonFrom${id ? id : ''}`}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  taxTypeMaster: state?.masterTax?.get_tax_integration,
});

// export default AddEditAvailabiity
export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(TaxComponent));

TaxComponent.propTypes = {
  /**To persist State, after viewing all conflicts state and returning back to add/Edit activity screen. */
  persistState: PropTypes.bool,
  /**State to set while persisting. */
  parentState: PropTypes.object,
};
