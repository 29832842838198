import React from "react";
import { Grid, withStyles, Paper } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { genericStyles } from "../../utils";

const styles = (theme) => ({
  flex: {
    display: "flex",
  },
  displayInline: {
    display: "inline",
  },
  colorSecondary: {
    color: theme.palette.primary.dark,
    marginRight: "20px",
  },
  marginRight: {
    margin: "5px",
  },
  paddingRight: {
    paddingRight: "15px",
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  user_list: {
    display: "grid",
    gridTemplateColumns: "64px 1fr",
  },
  avatar: {
    width: "50px",
    height: "50px",
    margin: "auto auto auto 0",
  },
  avatarhead: {
    display: "flex",
  },
  user_name: {
    display: "inline-block",
    paddingRight: "12px",
    fontSize: "1rem",
  },
  isactive: {
    display: "inline",
    fontSize: "1rem",
    color: theme.palette.success.main,
  },
  inactive: {
    display: "inline",
    fontSize: "1rem",
    color: theme.palette.error.main,
  },
  isactive_name: {
    display: "inline",
    paddingLeft: "16px",
    fontSize: "14px",
  },
  secondary: {
    color: theme.palette.secondary.main,
    fontSize: "14px",
    paddingTop: "0px",
  },
  iconColor: {
    fill: theme.palette.secondary.main,
  },
  activecircle: {
    marginLeft: 10,
    width: 9,
    height: 9,
    background: theme.palette.success.main,
    borderRadius: "50%",
  },
  inactivecircle: {
    marginLeft: 10,
    width: 9,
    height: 9,
    background: theme.palette.error.main,
    borderRadius: "50%",
  },
  left_border: {
    borderLeft: "0.5px solid #f7f7f7",
    display: "flex",
    textAlign: "center",
  },
  marginAuto: {
    margin: "auto",
  },
  marginHeight: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  listPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
  },
  cardPaper: {
    marginBottom: "15px",
    // borderRadius: "8px",
    // boxShadow:
    //   "0px 12px 6px -10px rgb(0 0 0 / 9%), 0px 0px 0px 0px rgb(0 0 0 / 12%), 0px 0px 0px 0px rgba(0,0,0,0.12)",
  },
  noCardTop: {
    borderRadius: "0px",
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
    borderBottom: "1px solid #f3f3f3",
    // boxShadow: '0px 12px 6px -10px rgb(0 0 0 / 9%), 0px 0px 0px 0px rgb(0 0 0 / 12%), 0px 0px 0px 0px rgba(0,0,0,0.12)'
  },
  noCardBottom: {
    borderRadius: "0px",
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    borderTop: "1px solid #f3f3f3",
    // boxShadow: '0px 12px 6px -10px rgb(0 0 0 / 9%), 0px 0px 0px 0px rgb(0 0 0 / 12%), 0px 0px 0px 0px rgba(0,0,0,0.12)'
  },
  flexEnd: {
    justifyContent: "flex-end",
    display: "flex",
  },
  ...genericStyles.cardWrapperShadows(theme)
});

class CardList extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Paper className={`${classes.cardPaper} ${classes.cardWrapperShadows}`}>
        <Grid container style={{ padding: "12px 16px" }}>
          <Grid item md={6} sm={12} xs={12} className={classes.user_list}>
            <Grid item className={classes.avatarhead}>
              <Skeleton variant="circle" width={50} height={50} />
            </Grid>
            <Grid item style={{ display: "flex" }}>
              <Grid item style={{ marginTop: "auto", marginBottom: "auto" }}>
                {/* loading Skeleton */}
                <Skeleton variant="text" style={{ width: 160 }} />
                <Skeleton variant="text" style={{ width: 160 }} />
                {/* end */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={12} xs={12} className={classes.flexEnd}>
            {/* loading Skeleton */}
            <Skeleton
              variant="rect"
              width={80}
              height={40}
              style={{ margin: "5px" }}
            />
            <Skeleton
              variant="rect"
              width={80}
              height={40}
              style={{ margin: "5px" }}
            />
            <Skeleton
              variant="rect"
              width={80}
              height={40}
              style={{ margin: "5px" }}
            />
            {/* end */}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(CardList);
