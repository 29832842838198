/* File Url */
export const FILE_URI = `${process.env.REACT_APP_PROTOCAL}://${process.env.REACT_APP_FILE_HOST}/${process.env.REACT_APP_FILE_PORT_DB_NAME}`;

/* Quotation Company Details */
export const companyDetails = {
  name: "AINQA CARE SDN BHD",
  regNumber: "2446564564654",
  address:
    "Lot 7.01 B & C, Menara BRDB,285 Jalan Maarof, Bukit Bandaraya, 59000 Kuala Lumpur, Malaysia",
  termsAndConditions: [
    {
      condition: `The invoice is computer generated. No signature required.`,
    },
    {
      condition:
        "Please contact us for any queries at 1300-13-2511 / reach.us@tanya.care",
    },
  ],
};

export const companyDetailsQuotation = {
  name: "AINQA CARE SDN BHD",
  regNumber: "2446564564654",
  address:
    "Lot 7.01 B & C, Menara BRDB,285 Jalan Maarof, Bukit Bandaraya, 59000 Kuala Lumpur, Malaysia",
  termsAndConditions: [
    {
      condition: `The quotation is computer generated. No signature required.`,
    },
    {
      condition:
        "Please contact us for any queries at 1300-13-2511 / reach.us@tanya.care",
    },
  ],
};

// Activity via mode - video call or in person
export const activityMode = {
  VIDEO_CALL: "Video call",
  IN_PERSON: "In person",
};

export const relationOptions = [
  {
    value: "Friend",
    label: "Friend",
  },
  {
    value: "Father",
    label: "Father",
  },
  {
    value: "Mother",
    label: "Mother",
  },
  {
    value: "Son",
    label: "Son",
  },
  {
    value: "Daughter",
    label: "Daughter",
  },
  {
    value: "Husband",
    label: "Husband",
  },
  {
    value: "Wife",
    label: "Wife",
  },
  {
    value: "Brother",
    label: "Brother",
  },
  {
    value: "Sister",
    label: "Sister",
  },
  {
    value: "Grandfather",
    label: "Grandfather",
  },
  {
    value: "Grandmother",
    label: "Grandmother",
  },
  {
    value: "Grandson",
    label: "Grandson",
  },
  {
    value: "Granddaughter",
    label: "Granddaughter",
  },
  {
    value: "Uncle",
    label: "Uncle",
  },
  {
    value: "Aunt",
    label: "Aunt",
  },
  {
    value: "Nephew",
    label: "Nephew",
  },
  {
    value: "Niece",
    label: "Niece",
  },
  {
    value: "Cousin",
    label: "Cousin",
  },
  {
    value: "Others",
    label: "Others",
  },
];

export const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "other" },
];

export const trialPopupStorageIdentifier = "showTrialPopup";

export const storeTrialPopupState = (state) => {
  window.localStorage.setItem(
    trialPopupStorageIdentifier,
    state ? "true" : "false"
  );
};

export const SOURCE_TYPE = {
  PORTAL: "Portal",
}
