import React from 'react'
import { makeStyles, InputLabel, Typography, withStyles } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

// Custom Toggle Button Group
const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(0.5, 0.5, 0.5, 0),
    },
  }
}))((props) => <ToggleButtonGroup {...props} />);


// Styles
const styles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.label,
    textTransform: "uppercase",
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },

  // Custom ToggleButton Style
  toggleButtonRoot: {
    // color: "rgba(0, 0, 0, 0.38) !important",
    textTransform: "none",
    border: "1.5px solid rgba(244, 244, 245, 1) !important",
    "&:not(:first-child)": {
      marginLeft: "8px !important"
    },
    "&.Mui-selected": {

      border: `1px solid ${theme.palette.ternary.main} !important`,
      background: theme.palette.ternary.main,
      "&:hover": {
        // backgroundColor: "rgba(242, 125, 53, 0.15)"
        backgroundColor: theme.palette.ternary.dark
      },
      // label
      "&>.MuiToggleButton-label": {
        "&>.MuiTypography-body1": {
          color: "white"
        }
      }
    },
    "&:hover": {
      // backgroundColor: "rgba(242, 125, 53, 0.15)"
    },

    // Label color
    "&>.MuiToggleButton-label": {
      "&>.MuiTypography-body1": {
        // color: "rgba(0, 0, 0, 0.38)"
      }
    }
  },
  toggleButtonLabel: {
    fontSize: "0.9rem"
  },
}))

export const CustomizedToggleGroup = (props) => {
  const { data, handleOnChange, value } = props
  const classes = styles()

  const handleValueChange = (event, newValue) => {
    if (newValue !== null) {
      handleOnChange(newValue, data.key)
    }
  }

  return (
    <>
      <InputLabel shrink htmlFor={data.label} className={classes.label} >
        {data.label}
      </InputLabel>

      {/* Main UI */}
      <StyledToggleButtonGroup value={value} onChange={handleValueChange} exclusive>
        {data.options.map((opt, index) => (
          <ToggleButton value={opt.value} classes={{
            root: classes.toggleButtonRoot,
            label: classes.toggleButtonLabel,
          }}>
            <Typography>{opt.label}</Typography>
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>

      {/* ERROR */}
      {props.error && (
        <Typography
          variant="body1"
          component="div"
          className={classes.errorText}
        >
          {props.errorText}
        </Typography>
      )}
    </>
  )
}