import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || "#28a9c1"
  })
}))

export const TermsAndConditionsIcon = (props) => {
  const classes = useStyles(props)
  return (
    <SvgIcon style={props.style}>
      {/* <defs>
        <style>
          .cls-1{fill:#28a9c1}
        </style>
      </defs> */}
      <g id="Terms_and_Conditions" data-name="Terms and Conditions" transform="translate(-3 -1)">
        <path id="Path_647" d="M17 1H5a2.006 2.006 0 0 0-2 2v14h2V3h12z" class={classes.cls1} data-name="Path 647" />
        <path id="Path_648" d="M18 15h-7v-2h7zm0-4h-7V9h7zm0 8h-7v-2h7z" class={classes.cls1} data-name="Path 648" />
        <path id="Path_649" d="M20 7v14H9V7h11m0-2H9a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h11a2.006 2.006 0 0 0 2-2V7a2 2 0 0 0-2-2z" class={classes.cls1} data-name="Path 649" />
      </g>
    </SvgIcon>
  )
}

TermsAndConditionsIcon.propTypes = {
  color: PropTypes.string
}