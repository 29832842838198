import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ProfileCard } from "./components/profileCard";
import { Select } from "../../../../../components/v2";
import { errorMessage } from "../../../../../utils/errorMessages";
import {
  calcAge,
  Table,
  ConfirmationRequired,
  LoadingSection,
} from "../../../../../components";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "tanyacare-middleware";
import { SummaryCard } from "./components/summaryCard";
import {
  exoticColumnType,
  alertProps,
  fixTwoDecimal,
} from "../../../../../utils";
import { DialogContext, AlertContext } from "../../../../../contexts";
import { createTestProps } from "../../../../../utils/common";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import { FILE_URI } from "../../../../../utils/constants";
import DialogSuccess from "./dialogSuccess";
import { QuoteInvoiceRenderer } from "./quote";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  flexRow: {
    display: "flex",
    alignItems: "center",
  },
  robotoMedium: {
    fontSize: 20,
    color: "#3B3F5B",
    fontWeight: 500,
  },
  divider: {
    backgroundColor: "#1010101A",
    height: 2,
  },
  header: {},
  gridItem: {
    marginBottom: theme.spacing(2),
  },
  paddingStyle: {
    padding: theme.spacing(3),
  },
  addQuoteBtn: {
    textTransform: "capitalize",
    minWidth: 165,
    padding: "7.75px 30px",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#fff",
  },
  closeDialog: {
    "& .MuiDialogTitle-root": {
      display: "none",
    },
    "& .MuiDialogContent-root": {
      padding: "30px 25px",
    },
    "& .MuiPaper-root": {
      borderRadius: 10,
      "& .MuiDialogContentText-root": {
        marginBottom: 0,
      },
      [theme.breakpoints.up("sm")]: {
        minWidth: 386,
        width: 386,
      },
      minWidth: "90%",
    },
  },
}));

const {
  GET_PROVIDER_LIST_BASEDON_CATEGORY,
  PRDT_SER_PGK_MAPPING_LIST,
  UPDATE_DOC_STATUS_BY_ENQ_ID,
  ADD_QUOTE,
  CREATE_QUOTE_TAG,
  GET_RESOURCE_TYPE,
  GET_ENQUIRY_INFO,
  GET_DOCS_BY_ENQ,
  GET_CATEGORIES,
} = actions;

export const CreateQuote = (props) => {
  const classes = useStyles(props);

  const dialog = React.useContext(DialogContext);
  const alert = React.useContext(AlertContext);

  const dispatch = useDispatch();

  const categories = useSelector((state) => state?.masterSet?.categories);
  const providerBasedOnCategory = useSelector(
    (state) => state?.careProvider?.providerBasedOnCategory
  );
  const getResourceType = useSelector(
    (state) => state?.masterModule?.getResourceType
  );
  const subscription_mapping = useSelector(
    (state) => state?.dependency_api?.subscription_mapping
  );
  const authState = useSelector((state) => state?.authState?.loginAuth?.data);
  const getquoteid = useSelector((state) => state?.quotenq?.getquoteid);
  const quotes = useSelector((state) => state?.quotenq?.getdocsforenq);

  const { data, isEditForm } = props;

  const [state, setState] = useState({
    category: [],
    partner: null,
    type: null,
    item: [],
    services: [],
    selectedServices: [],
    tableData: [],
  });

  const [tableDetails, setTableDetails] = useState({
    discount: "",
    total: "",
    taxAmount: "",
  });

  const [error, setError] = useState({});

  const [loading, setLoading] = useState(null);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [props?.id]);

  const fetchData = async () => {
    setLoading("screen");
    await dispatch(CREATE_QUOTE_TAG({}));
    await dispatch(GET_CATEGORIES({}));
    const resourceType = await dispatch(GET_RESOURCE_TYPE({ type: "Service" }));
    if (props?.id) {
      await dispatch(GET_ENQUIRY_INFO({ enquiry_id: props?.id }));
      await dispatch(GET_DOCS_BY_ENQ({ enquiry_id: props?.id }));
    }
    if (props?.dataList) {
      // if (props?.dataList?.discount) {
      //     setTableDetails({ ...tableDetails, discount: props?.dataList?.discount })
      // }
      if (props?.dataList?.metaData) {
        const { categories, provider, services } = props?.dataList?.metaData;

        let category = Array.isArray(categories)
          ? categories?.map((val) => val?.value)
          : [categories?.value];
        let partner = Array.isArray(provider)
          ? provider?.map((val) => val?.value)
          : [provider?.value];

        await dispatch(
          GET_PROVIDER_LIST_BASEDON_CATEGORY({
            categories: category,
          })
        );

        const getservices = await dispatch(
          PRDT_SER_PGK_MAPPING_LIST({
            type: props?.dataList?.resourceType,
            categories: category,
            providers: partner,
            isenquiry: true,
          })
        );

        let tableData = JSON.parse(JSON.stringify(services));

        let service = getservices?.payload?.filter((_) =>
          tableData?.some((a) => a?.value === _?.value)
        );

        let type = resourceType?.payload?.data?.find(
          (_) => _?.value === props?.dataList?.resourceType
        );

        setState({
          ...state,
          category: categories,
          partner: provider
            ? provider?.constructor === Array
              ? provider?.[0]
              : provider
            : null,
          services: service,
          item: service,
          type,
          tableData,
        });

        if (tableData?.length) {
          // New Calculation
          const discountPercentage = props?.dataList?.discount
            ? parseFloat(props?.dataList?.discount)
            : 0;

          const grossAmount = calculateGrossAmount(tableData);
          const taxAmount = calculateTaxAmount(tableData, discountPercentage);
          const netAmount = calculateNetAmount(
            grossAmount,
            discountPercentage,
            taxAmount
          );

          setTableDetails({
            ...tableDetails,
            total: fixTwoDecimal(grossAmount),
            discount: discountPercentage,
            taxAmount: fixTwoDecimal(taxAmount),
            finalAmount: fixTwoDecimal(netAmount), //Discount Percentage of GrossAmount and Add TaxAmount to get NetAmount (or) Final Amount
          });
        }
      }
    }
    setLoading(null);
  };

  const returnName = (name) => {
    let splitName = data?.name.split(".");
    return splitName[0] === "other" ? splitName[1] : name ?? "-";
  };

  const genderAgeDetails = (data) => {
    return `${
      data?.gender
        ? data?.gender.charAt(0).toUpperCase() + data?.gender.slice(1)
        : "-"
    }${data?.dob ? `${", "}${calcAge(data?.dob)?.concat(" old")}` : "-"}`;
  };

  const checkOptionArray = (data) => {
    return Array.isArray(data) ? data : [];
  };

  const onChangeCategory = async (value) => {
    setLoading("partner");
    setState({
      ...state,
      category: value,
      partner: null,
      type: null,
      item: [],
      services: [],
    });

    if (value) {
      await dispatch(
        GET_PROVIDER_LIST_BASEDON_CATEGORY({
          categories: Array.isArray(value)
            ? value.map((_) => _.value)
            : [value?.value],
        })
      );
    }
    setLoading(null);
  };

  const onChangePartner = (value) => {
    setLoading("type");
    setState({
      ...state,
      partner: value,
      type: null,
      item: [],
      services: [],
    });
    setLoading(null);
  };

  const onChangeType = async (value) => {
    setLoading("item");
    setState({
      ...state,
      type: value,
      item: [],
      services: [],
    });

    if (value) {
      await dispatch(
        PRDT_SER_PGK_MAPPING_LIST({
          type: value?.value,
          categories: Array.isArray(state.category)
            ? state.category?.map((_) => _.value)
            : [state.category?.value],
          providers: [state.partner?.value],
          isenquiry: true,
        })
      );
    }
    setLoading(null);
  };

  const onChangeItem = (value) => {
    setLoading("item");

    let service = [];
    if (value?.length) {
      service = JSON.parse(JSON.stringify(value)).map((val) => {
        if (!val.type) {
          val.type = state?.type?.label;
          val.frequency =
            val?.resourceMeta?.resourceMeta?.frequencyObject?.label ?? "N/A";
        }
        return val;
      });
    }
    setState({
      ...state,
      services: service,
      selectedServices: [...state.selectedServices, ...service],
      item: value,
    });

    setLoading(null);
  };

  const checkValidation = () => {
    let toCheck = ["category", "partner", "type", "item"];

    const errorState = {};

    toCheck.forEach((key) => {
      if (
        !state[key] ||
        (state[key]?.constructor === Object &&
          !Object?.keys(state[key])?.length) ||
        (state[key]?.constructor === Array && !state[key]?.length)
      ) {
        errorState[key] = true;
      }
    });
    setError(errorState);
    return Boolean(Object.keys(errorState)?.length);
  };

  const addToQuote = () => {
    let check = checkValidation();
    if (!check) {
      onAddingQuote();
    }
  };

  const onAddingQuote = () => {
    const { services, tableData } = state;

    let service = services.map((val) => {
      var o = Object.assign({}, val);
      o.provider = state?.partner;
      o.category = val?.providerInfo?.category?.[0] ?? state?.category;
      o.quantity = val?.quantity ? +val?.quantity : 1;
      return o;
    });

    console.log(service)

    let checkDuplicates = [...service];
    //check duplicates

    if (tableData?.length) {
      let temp = tableData?.map((_) => {
        let findData = service?.filter((f) => f.value === _?.value);
        console.log(findData)
        if (findData?.length) {
          return findData?.[0];
        }
        return _;
      });
      checkDuplicates = [...checkDuplicates, ...temp];
    }

    // console.log(services)
    // console.log(tableData)
    // console.log(checkDuplicates)

    setState({
      ...state,
      services: [],
      item: [],
      tableData: checkDuplicates,
    });

    // New Calculation
    const discountPercentage = tableDetails?.discount
      ? parseFloat(tableDetails?.discount)
      : 0;

    const grossAmount = calculateGrossAmount(checkDuplicates);
    const taxAmount = calculateTaxAmount(checkDuplicates, discountPercentage);
    const netAmount = calculateNetAmount(
      grossAmount,
      discountPercentage,
      taxAmount
    );

    setTableDetails({
      ...tableDetails,
      total: fixTwoDecimal(grossAmount),
      discount: discountPercentage,
      taxAmount: fixTwoDecimal(taxAmount),
      finalAmount: fixTwoDecimal(netAmount), //Discount Percentage of GrossAmount and Add TaxAmount to get NetAmount (or) Final Amount
    });
  };

  const returnSummaryAmount = (_) => {
    return `${_?.currency ?? "RM"} ${
      _.defaultamount
        ? parseFloat(_.defaultamount)?.toFixed(2)
        : parseFloat(_.amount)?.toFixed(2)
    }
        ${_.term ? `(${_.term})` : ""}`;
  };

  const returnCategories = (_) => {
    return _?.providerInfo?.category?.map((_) => _.label);
  };

  const columns = [
    { id: "sno", label: "S.No" },
    {
      id: "serviceId",
      label: "Service ID",
      // minWidth: 100,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "description",
      label: "Description",
      minWidth: 200,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "quantity",
      type: "INPUT_BOX",
      label: "Quantity",
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      props: { ...createTestProps("quote table quantity") },
    },
    {
      id: "price",
      label: "Unit Price (MYR)",
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      props: { ...createTestProps("quote table price") },
    },
    {
      id: "discount",
      label: "Discount (MYR)",
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "amount",
      label: "Amount (MYR)",
      // minWidth: 100,
      align: "left",
      format: (value) => value,
    },
  ];

  const exoticRow = [
    null,
    null,
    null,
    {
      type: exoticColumnType.DISCOUNT,
    },
    {
      type: exoticColumnType.TAX_AMOUNT,
    },
    {
      type: exoticColumnType.TOTAL_TEXT,
    },
    {
      type: exoticColumnType.TOTAL_VALUE,
    },
  ];

  const multiplyQuantityWithPrice = (quantity, amount) => {
    // quantity = quantity ? parseInt(quantity) : 1;
    quantity =
      !!parseInt(quantity) || !!parseInt(quantity) ? parseInt(quantity) : 1;
    amount = amount ? parseFloat(amount) : 0;

    let totalQuantityAmt = quantity * amount;

    return totalQuantityAmt;
  };

  const createTableRows = (data) => {
    const rows = data?.map((val) => ({
      serviceId: val?.aliasId ?? "-",
      description: val?.label,
      quantity: val?.quantity,
      quantityEditable: true,
      price_editable: val?.isPriceEditable ?? false,
      price: val.isPriceEditable
        ? val.amount
        : val?.resourceMeta?.resourceMeta?.total
        ? parseFloat(val?.resourceMeta?.resourceMeta?.total).toFixed(2)
        : parseFloat(val?.resourceMeta?.resourceMeta?.price).toFixed(2),
      // discount: val?.resourceMeta?.resourceMeta?.discount
      //   ? val?.resourceMeta?.resourceMeta?.discount
      //   : "-",
      discount: "0.00",
      amount:
        parseFloat(
          multiplyQuantityWithPrice(
            val?.quantity,
            val.isPriceEditable
              ? val.amount
              : val?.resourceMeta?.resourceMeta?.total
              ? val?.resourceMeta?.resourceMeta?.total
              : val?.resourceMeta?.resourceMeta?.price
          )
        ).toFixed(2) ?? "-",
      value: val.value,
    }));
    return rows;
  };

  const returnAmount = (quantity, discount, amount) => {
    quantity = quantity ? parseInt(quantity) : 0;
    discount = discount ? parseFloat(discount) : 0;
    amount = amount ? parseFloat(amount) : 0;

    let totalQuantityAmt = quantity * amount;
    let returnAmt = totalQuantityAmt - (totalQuantityAmt * discount) / 100;

    return returnAmt;
  };

  const closeDialog = () => {
    dialog.setDialog({
      open: false,
    });
  };

  const removeSelectedData = (val) => {
    let filteredArray = state.tableData.filter((_) => _.value !== val?.value);

    setState({
      ...state,
      tableData: filteredArray,
    });

    // New Calculation
    const discountPercentage = tableDetails?.discount
      ? parseFloat(tableDetails?.discount)
      : 0;

    const grossAmount = calculateGrossAmount(filteredArray);
    const taxAmount = calculateTaxAmount(filteredArray, discountPercentage);
    const netAmount = calculateNetAmount(
      grossAmount,
      discountPercentage,
      taxAmount
    );

    setTableDetails({
      ...tableDetails,

      total: fixTwoDecimal(grossAmount),
      discount: discountPercentage,
      taxAmount: fixTwoDecimal(taxAmount),
      finalAmount: fixTwoDecimal(netAmount), //Discount Percentage of GrossAmount and Add TaxAmount to get NetAmount (or) Final Amount
    });

    closeDialog();
  };

  const handleRemove = (val) => {
    dialog.setDialog({
      ...dialog,
      open: true,
      body: (
        <ConfirmationRequired
          description="Are you sure want to delete ?"
          positiveButton={{
            onClick: () => removeSelectedData(val),
          }}
          negativeButton={{
            onClick: closeDialog,
          }}
        />
      ),
      className: {
        Dialog: classes.closeDialog,
      },
      dontShowButtons: true,
      hideAllButtons: true,
    });
  };

  const calculateAmountWithPercentageApplied = (amount, percentage) =>
    amount - (amount * percentage) / 100;

  const calculatePercentageAmount = (amount, percentage) =>
    (amount * percentage) / 100;

  const calculateGrossAmount = (rows) => {
    const tableRows = rows ? rows : state?.tableData;
    // [2 * 1899, 4 * 2000, ...quantity * amount ]
    const arrayOfQuantityMultipliedAmount = tableRows?.map((_) =>
      multiplyQuantityWithPrice(_?.quantity, _?.amount)
    );
    return arrayOfQuantityMultipliedAmount?.reduce(
      (previousAmount, currentAmount) => previousAmount + currentAmount,
      0
    );
  };

  const calculateTaxAmount = (rows, discountPercentage) => {
    const tableRows = rows ? rows : state?.tableData;
    // {
    //    tax: 3,
    //    rowTotal: 1899
    // }
    const tableDataWithRowTotal = tableRows?.map((_) => ({
      tax: _?.resourceMeta?.resourceMeta?.tax,
      rowTotal: multiplyQuantityWithPrice(_?.quantity, _?.amount),
    }));

    // {
    //   tax: 3,
    //   rowTotal: 1899,
    //   rowTotalWithDiscount: 1709.1  //discounted 10%
    // }
    const tableDataWithDiscountTotal = tableDataWithRowTotal?.map((_) => ({
      ..._,
      rowTotalWithDiscount: calculateAmountWithPercentageApplied(
        _?.rowTotal,
        discountPercentage
      ),
    }));

    const arrayOfTaxAmount = tableDataWithDiscountTotal?.map((_) =>
      calculatePercentageAmount(_?.rowTotalWithDiscount, _?.tax)
    );

    return arrayOfTaxAmount?.reduce(
      (previousAmount, currentAmount) => previousAmount + currentAmount,
      0
    );
  };

  const calculateNetAmount = (grossAmount, discountPercentage, taxAmount) => {
    debugger;
    const sumDiscount = calculatePercentageAmount(
      grossAmount,
      discountPercentage
    );
    const net = grossAmount - sumDiscount;
    const netTotal = net + taxAmount;

    return netTotal;
  };

  const onDiscountChange = (value) => {
    const setTableState = (discount) => {
      const discountPercentage = discount ? parseFloat(discount) : 0;

      const grossAmount = calculateGrossAmount(null);
      const taxAmount = calculateTaxAmount(null, discountPercentage);
      const netAmount = calculateNetAmount(
        grossAmount,
        discountPercentage,
        taxAmount
      );

      setTableDetails({
        ...tableDetails,
        total: fixTwoDecimal(grossAmount),
        discount: discountPercentage,
        taxAmount: fixTwoDecimal(taxAmount),
        finalAmount: fixTwoDecimal(netAmount), //Discount Percentage of GrossAmount and Add TaxAmount to get NetAmount (or) Final Amount
      });
    };

    const preDecimal = value.split(".")[0] ?? 0;
    const postDecimal = value.split(".")[1] ?? 0;
    const noFirstDigitZero = /^[^0]\d*/;
    if (value.length === 0) {
      if (Number(value) < 0 || Number(value) > 100) return;
      setTableState(value);
    } else if (noFirstDigitZero?.test(value)) {
      if (preDecimal.length <= 10) {
        if (postDecimal) {
          if (postDecimal.length <= 2) {
            if (Number(value) < 0 || Number(value) > 100) return;
            setTableState(parseFloat(value).toFixed(2));
          } else {
            // post decimal exceeds limit
            return;
          }
        } else {
          if (Number(value) < 0 || Number(value) > 100) return;
          setTableState(value);
        }
      }
    } else {
      return;
    }
  };

  const onTextFieldChange = (index, key, value) => {
    const tableData = [...state.tableData];

    if (key === "quantity" || key === "price") {
      if (key === "quantity") {
        // if (!value){
        //   tableData[index][key] = 1;
        // } else {
        tableData[index][key] = value;
        // }
      }
      if (key === "price") {
        tableData[index]["amount"] = value;
      }
      setState({ ...state, tableData });

      // New Calculation
      const discountPercentage = tableDetails?.discount
        ? parseFloat(tableDetails?.discount)
        : 0;

      const grossAmount = calculateGrossAmount(tableData);
      const taxAmount = calculateTaxAmount(tableData, discountPercentage);
      const netAmount = calculateNetAmount(
        grossAmount,
        discountPercentage,
        taxAmount
      );

      setTableDetails({
        ...tableDetails,
        total: fixTwoDecimal(grossAmount),
        discount: discountPercentage,
        taxAmount: fixTwoDecimal(taxAmount),
        finalAmount: fixTwoDecimal(netAmount), //Discount Percentage of GrossAmount and Add TaxAmount to get NetAmount (or) Final Amount
      });
    }
  };

  const onBlurTextField = (index, key, value) => {
    const tableData = [...state.tableData];

    if (key === "price") {
      tableData[index]["amount"] = parseFloat(value).toFixed(2);
      setState({ ...state, tableData });
    }
  };

  const shareQuote = async () => {
    if (
      state.tableData?.some((_) => !_?.quantity || parseInt(_?.quantity) <= 0)
    ) {
      alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please add atleast one quantity",
        severity: alertProps.severity.error,
      });
    } else {
      let filterFunc = (x) => {
        if (x.quoteStatus === "quote_finalized") return x.quoteId;
      };
      setLoading("shareQuote");
      let finalizeddQuoteId = quotes?.data?.filter((x) => filterFunc(x));
      if (data?.issue_status?.toLowerCase() === "quote finalized") {
        dialog.setDialog({
          ...dialog,
          open: true,
          title: "You already finalized the quote for this Enquiry",
          body: (
            <p>
              Do still want to create a new quote?
              <br /> If yes, we will remove "Finalized quote" and change to
              status as "Quote submitted".
            </p>
          ),
          positiveBtn: "CONFIRM",
          negativeBtn: "CANCEL",
          onOk: async () => {
            dialog.setDialog({ open: false });
            let _payload = {
              enquiry_id: props?.id,
              total: fixTwoDecimal(tableDetails.total),
              discount: fixTwoDecimal(tableDetails.discount)?.toString(),
              taxAmount: fixTwoDecimal(tableDetails.taxAmount),
              finalAmount: fixTwoDecimal(tableDetails.finalAmount),
              categories: state.category?.value, //doubt
              provider: state.partner?.value,
              services: state.services,
              selecType: state.type?.value,
              client_id: authState?.user_id,
              // downloadUrl: pdfUrl.split(",")[1],
              downloadUrl: "",
              quoteStatus: "quote_submitted",
              metaData: {
                categories: state.category,
                provider: state.partner,
                services: state.tableData,
              },
              emailData: {
                categories: state.category?.label, //doubt
                provider: state.partner?.label,
                total: fixTwoDecimal(tableDetails.total),
                discount: fixTwoDecimal(tableDetails.discount),
                email: data.email,
                mobile: data.mobile,
                file_url: "",
                // pdfUrl.split(",")[1],
                // email: "pkumaravelamutha@gmail.com",
                name: data.name,
                services: state.services.map((val) => val.label).join(", "),
              },
              quoteTag:
                typeof getquoteid?.data === "string" ? getquoteid?.data : "",
            };
            await dispatch(
              UPDATE_DOC_STATUS_BY_ENQ_ID({
                enquiryId: props?.id,
                quoteStatus: "quote_submitted",
                quoteId: finalizeddQuoteId?.[0]?.quoteId,
                // isEnquiry: true,
              })
            );
            const addQuoteResult = await dispatch(ADD_QUOTE(_payload));
            isQuoteAdded(addQuoteResult);
          },
          onCancel: () => {
            dialog.setDialog({ open: false });
          },
        });
      } else {
        let _payload = {
          enquiry_id: props?.id,
          total: fixTwoDecimal(tableDetails.total),
          discount: fixTwoDecimal(tableDetails.discount)?.toString(),
          taxAmount: fixTwoDecimal(tableDetails.taxAmount),
          finalAmount: fixTwoDecimal(tableDetails.finalAmount),
          categories: state.category?.value, //doubt
          provider: state.partner?.value,
          services: state.services,
          selecType: state.type?.value,
          client_id: authState?.user_id,
          downloadUrl: "",
          // pdfUrl.split(",")[1],
          quoteStatus: "quote_submitted",
          metaData: {
            categories: state.category,
            provider: state.partner,
            services: state.tableData,
          },
          emailData: {
            categories: state.category?.label, //label
            provider: state.partner?.label,
            total: fixTwoDecimal(tableDetails.total),
            discount: fixTwoDecimal(tableDetails.discount),
            email: data.email,
            mobile: data.mobile,
            file_url: "",
            name: data.name,
            services: state.services.map((val) => val.label).join(", "),
          },
          quoteTag:
            typeof getquoteid?.data === "string" ? getquoteid?.data : "",
        };
        const addQuoteResult = await dispatch(ADD_QUOTE(_payload));
        isQuoteAdded(addQuoteResult);
      }
      setLoading(null);
    }
  };

  const isQuoteAdded = (data) => {
    if (!data?.payload?.error) {
      setState({
        ...state,
        pdfDownloadData: data?.payload?.data,
        openDialog: true,
      });
      alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Quote Added successfully!",
        severity: alertProps.severity.success,
      });
    } else {
      alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Something went wrong! Unable to Add Quote.",
        severity: alertProps.severity.error,
      });
    }
  };

  const downloadData = async () => {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    var newdate = year + "_" + month + "_" + day;
    var filename = `tc_quote_${newdate}.pdf`;
    var a = document.createElement("a"); //Create <a>
    a.href = FILE_URI.concat(
      `/${state.pdfDownloadData?.data?.Result?.[0]?.properties?.doc?.downloadUrl}`
    ); //Image Base64 Goes here
    // data.data.Result[0].properties.doc.downloadUrl
    a.download = filename; //File name Here
    a.target = "_blank";
    a.click(); //Downloaded file
    // await printQuote("print-create");
  };

  const handleClose = () => {
    props?.closeDrawer && props.closeDrawer();
    setState({ ...state, openDialog: false });
    props.reloadListing && props.reloadListing();
  };

  const formatData = (data) => {
    let _address = (data) => {
      let address = data?.address;
      if (!data?.address) {
        address = data?.addressLine1?.concat(
          `, ${data?.addressLine2 ? data?.addressLine2?.concat(",") : ""} ${
            data?.area
          }, ${data?.state}. ${data?.postalCode}`
        );
      }
      return address;
    };

    return {
      dob: data?.dob,
      name: data?.name,
      gender: data?.gender,
      mobile: data?.mobile,
      email: data?.email,
      address: _address(data),
      //Changed
      clientId: data?.clientHealthId,
      _key: data?.id,
      _id: data?.id,
      _rev: data?.id,
      quotesId: data?.tag,
      // quotesTag: "Quote-" + uuidv4().substr(0, 8),
      // quoteTag: `Quote-${uuidv4().substr(0, 8)}`,
      // quotesTag: data?.tag,
      quotesTag: typeof getquoteid?.data === "string" ? getquoteid?.data : "",
      enquiryId: props?.id,
      resourceId: "",
      quoteStatus: "",
      discount: tableDetails.discount,
      taxAmount: tableDetails?.taxAmount,
      total: tableDetails.total,
      metaData: {
        categories: state.category,
        provider: state.partner,
        services: state.tableData,
      },
      clientHealthId: data?.clientHealthId,
      provider: "",
      selecType: "",
      isActive: "",
      createdAt: "",
      updatedAt: "",
      createdBy: "",
      downloadUrl: "",
      updatedBy: "",
      updatedby: "",
      updatedate: "",
      activestatus: "",
      quoteId: data?.tag,
      dateandTime: new Date(),
    };
  };

  const newColumnsToPrint = [
    { id: "sno", label: "S.No" },
    {
      id: "serviceId",
      label: "SERVICE ID",
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "description",
      label: "DESCRIPTION",
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "quantity",
      label: "QUANTITY",
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "unitPrice",
      label: "UNIT PRICE (RM)",
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "discount",
      label: "DISCOUNT %",
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "amount",
      label: "AMOUNT (RM)",
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];

  const createRows = (data, cat) => {
    return data?.metaData?.services?.map((val) => ({
      serviceId: val?.aliasId ?? "-",
      description: val.label,
      quantity: val?.quantity ? val?.quantity : 1,
      unitPrice:
        val.amount && val.isPriceEditable
          ? parseFloat(val.amount).toFixed(2)
          : val?.resourceMeta?.resourceMeta?.originalTotal
          ? parseFloat(val?.resourceMeta?.resourceMeta?.originalTotal).toFixed(
              2
            )
          : parseFloat(val?.resourceMeta?.resourceMeta?.price).toFixed(2),
      discount: val?.resourceMeta?.resourceMeta?.discount
        ? val?.resourceMeta?.resourceMeta?.discount
        : "-",
      amount:
        parseFloat(
          returnAmount(
            val?.quantity,
            val?.resourceMeta?.resourceMeta?.discount,
            val.isPriceEditable
              ? val.amount
                ? val.amount
                : 0
              : val?.resourceMeta?.resourceMeta?.originalTotal ??
                  val?.resourceMeta?.resourceMeta?.price
          )
        ).toFixed(2) ?? "-",
      value: val?.value,
    }));
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.stickyHeader}>
          <div className={classes.paddingStyle} style={{ paddingBlock: 16 }}>
            <Typography className={classes.robotoMedium}>
              {isEditForm ? "Edit Quote" : "Create Quote"}
            </Typography>
          </div>
          <div className={classes.divider} />
        </div>
        {loading === "screen" ? (
          <LoadingSection message="Loading Quotes..." />
        ) : (
          <div className={classes.paddingStyle}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.gridItem}>
                <ProfileCard
                  profilePicture={data?.profilePicture}
                  name={returnName(data?.name)}
                  genderAgeDetails={genderAgeDetails(data)}
                  mobile={data?.mobile}
                  email={data?.email}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} className={classes.gridItem}>
                <Select
                  value={state?.category}
                  handleChange={(value) => onChangeCategory(value)}
                  options={checkOptionArray(categories?.data) ?? []}
                  loading={categories?.loading}
                  label="Choose Category"
                  placeHolder="Select Category"
                  isRequired
                  error={error?.category}
                  helperText={errorMessage?.requiredMessage}
                  multiple
                  limitTags={2}
                  {...createTestProps("choose category for quote")}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} className={classes.gridItem}>
                <Select
                  value={state?.partner}
                  handleChange={(value) => onChangePartner(value)}
                  options={
                    state?.category?.length
                      ? checkOptionArray(providerBasedOnCategory?.data)
                      : [] ?? []
                  }
                  loading={
                    providerBasedOnCategory?.loading || loading === "partner"
                  }
                  label="Choose Partner"
                  placeHolder="Select Partner"
                  isRequired
                  error={error?.partner}
                  helperText={errorMessage?.requiredMessage}
                  {...createTestProps("choose partner for quote")}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} className={classes.gridItem}>
                <Select
                  value={state?.type}
                  handleChange={(value) => onChangeType(value)}
                  options={
                    state?.partner?.value
                      ? checkOptionArray(getResourceType?.data)
                      : [] ?? []
                  }
                  loading={getResourceType?.loading || loading === "type"}
                  label="Choose Type"
                  placeHolder="Select Type"
                  isRequired
                  error={error?.type}
                  helperText={errorMessage?.requiredMessage}
                  {...createTestProps("choose type for quote")}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <Select
                  value={state?.item}
                  handleChange={(value) => onChangeItem(value)}
                  options={
                    state?.type?.value
                      ? checkOptionArray(subscription_mapping?.data)
                      : [] ?? []
                  }
                  loading={subscription_mapping?.loading || loading === "item"}
                  label="Choose Item"
                  placeHolder="Select Item"
                  isRequired
                  multiple
                  limitTags={4}
                  error={error?.item}
                  helperText={errorMessage?.requiredMessage}
                />
              </Grid>

              <Grid item xs={12} className={classes.gridItem}>
                {(state?.services?.length > 0 ||
                  state?.tableData?.length > 0) && (
                  <Typography className={classes.robotoMedium}>
                    {"Summary "}
                  </Typography>
                )}

                {/* Summary Section */}
                {state?.services && state?.services?.length > 0 && (
                  <>
                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.gridItem}
                      spacing={2}
                    >
                      {state?.services?.map((_) => {
                        return (
                          <Grid item xs={12} md={6} key={_?.value}>
                            <SummaryCard
                              profile_pic={_?.profile_pic}
                              label={_?.label}
                              value={_?.value}
                              amount={returnSummaryAmount(_)}
                              partner={_?.providerInfo?.label}
                              categoryList={returnCategories(_)}
                              loading={loading === "item"}
                            />
                          </Grid>
                        );
                      })}
                      {/* {state?.tableData?.map((_) => {
                      return (
                        <Grid item xs={12} md={6} key={_?.value}>
                          <SummaryCard
                            profile_pic={_?.profile_pic}
                            label={_?.label}
                            value={_?.value}
                            amount={returnSummaryAmount(_)}
                            partner={_?.providerInfo?.label}
                            categoryList={returnCategories(_)}
                            loading={loading === "item"}
                          />
                        </Grid>
                      );
                    })} */}
                    </Grid>
                  </>
                )}

                {state?.tableData?.length > 0 &&
                  state?.services?.length > 0 && (
                    <Divider variant="middle" style={{ marginBottom: 20 }} />
                  )}

                {state?.tableData && state?.tableData?.length > 0 && (
                  <>
                    {/* <Typography className={classes.robotoMedium}>
                    {"Summary"}
                  </Typography> */}

                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.gridItem}
                      spacing={2}
                    >
                      {state?.tableData?.map((_) => {
                        return (
                          <Grid item xs={12} md={6} key={_?.value}>
                            <SummaryCard
                              profile_pic={_?.profile_pic}
                              label={_?.label}
                              value={_?.value}
                              amount={returnSummaryAmount(_)}
                              partner={_?.providerInfo?.label}
                              categoryList={returnCategories(_)}
                              loading={loading === "item"}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <div style={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.addQuoteBtn}
                    onClick={addToQuote}
                    disabled={!Boolean(state?.services?.length)}
                    {...createTestProps("add to quote button")}
                  >
                    Add to Quote
                  </Button>
                </div>
              </Grid>

              {/* Table Section */}
              <Grid item xs={12}>
                <Table
                  isQuoteVariantTable
                  columns={columns}
                  exoticRow={exoticRow}
                  rows={createTableRows(state?.tableData)}
                  totalLabel={"Grand Total (MYR)"}
                  state={"key"}
                  handleRemove={(value) => handleRemove(value)}
                  discount={tableDetails?.discount}
                  total={tableDetails?.finalAmount}
                  onDiscountChange={onDiscountChange}
                  onTotalChange={onDiscountChange}
                  withDiscount={true}
                  withTotal={true}
                  onTextFieldChange={onTextFieldChange}
                  isQuote={true}
                  taxAmount={tableDetails?.taxAmount}
                  withTaxAndTaxType={false}
                  currency={
                    state.tableData?.[0]?.resourceMeta?.resourceMeta?.currency
                  }
                  tableHover={false}
                  onBlurTextField={onBlurTextField}
                  parentId="QuoteTable"
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.addQuoteBtn}
                    onClick={shareQuote}
                    disabled={
                      !Boolean(state?.tableData?.length) ||
                      loading === "shareQuote"
                    }
                    style={{ paddingInline: 28 }}
                    {...createTestProps("share quote")}
                  >
                    <ShareOutlinedIcon
                      htmlColor={
                        !Boolean(state?.tableData?.length) ||
                        loading === "shareQuote"
                          ? "#0000001f"
                          : "#fff"
                      }
                      style={{ marginRight: 8 }}
                    />
                    {loading === "shareQuote" ? "Sharing" : "Share"} Quote
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        {state?.openDialog && (
          <DialogSuccess
            downloadData={downloadData}
            open={state?.openDialog}
            // pdfUrl={state?.pdfDownloadData?.url}
            // fileName={state?.pdfDownloadData?.destnationFileLocation}
            // data={state?.pdfDownloadData}
            handleClose={handleClose}
          />
        )}
        {/* PAGE TO PRINT */}
        <Grid container style={{ display: "none" }} id="print-create">
          {/* {console.log(data?.data)} */}
          <QuoteInvoiceRenderer
            data={
              isEditForm
                ? formatData({ ...data?.data, ...data })
                : formatData(data?.data)
            }
            columnsToPrint={newColumnsToPrint}
            createRows={createRows}
            isInvoice={false}
          />
        </Grid>
      </div>
    </>
  );
};
