import React from "react";
import { components } from "react-select";

// import CreatableSelect from 'react-select/creatable';
import AsyncSelect from "react-select";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: "12px 0px 12px 0px",
    width: "100%",
    "& .MuiInputBase-root": {
      width: "100% !important",
    },
    "& .css-2b097c-container": {
      marginTop: "8px !important",
    },
    "& .css-yk16xz-control": {
      minHeight: "53px !important",
      borderRadius: "8px !important",
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: "none",
    },
  },
  label: {
    color: "rgba(146, 151, 165, 1)",
    textTransform: "uppercase",
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
  labelsize: {
    fontSize: 17,
  },
}));
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};
export const SelectandFilter = (props) => {
  const classes = useStyles();
  const { label, data } = props;
  return (
    <div className={classes.margin}>
      <InputLabel
        shrink
        htmlFor="demo-customized-select-native"
        className={classes.label}
      >
        {label}
      </InputLabel>

      {/* NEWLY MODIFIED BY JAYASOORYA FROM CREATABLE REACT SELECT TO NORMAL REACT SELECT */}
      {/* <CreatableSelect 
            isClearable
            styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                control: (base) => ({
                    ...base,
                    border: props.error
                        ? "1.8px solid rgba(188, 45, 79, 1)"
                        : "1.8px solid rgba(244, 244, 245, 1)",
                    // This line disable the blue border 
                    boxShadow: 0,
                    borderRadius: "8px !important",
                    minHeight: 53,
                    "&:hover": {
                        border: "1.8px solid #f4975d !important",
                        minHeight: 53,
                        borderRadius: "8px !important",
                    },
                }),
            }}
            onChange={(e) => props.handleOnChange(e)}
            value={props.value}
            options={data}
            components={{ DropdownIndicator }}

        /> */}

      {/* NEWLY MODIFIED BY JAYASOORYA FROM CREATABLE REACT SELECT TO NORMAL REACT SELECT */}
      <AsyncSelect
        tabSelectsValue={false}
        inputId={props?.id}
        // hideSelectedOptions={props?.value?.length < 3 ? true : false}
        isSearchable
        //   onChange={props.handleOnChange}
        //   value={props.value}
        isClearable
        styles={{
          //   option: (base, value) => {
          //
          //     return (props?.value?.length < 3 ? { ...base } : { display: 'none'});
          // },
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          control: (base) => ({
            ...base,

            border: props.error
              ? "1.8px solid rgba(188, 45, 79, 1)"
              : `1.8px solid ${
                  props.borderColor ? props.borderColor : "#DFDFE2"
                }`,
            // This line disable the blue border
            boxShadow: 0,
            borderRadius: `${
              props.borderRadius ? props.borderRadius : "8px"
            } !important`,
            minHeight: !props.hideCustomValueContainerHeight
              ? props.height
                ? props.height
                : 53
              : "unset",
            maxHeight: 100,
            "& .css-g1d714-ValueContainer": {
              overflow: "auto",
              maxHeight: "90px",
            },
            "&:hover": {
              border: `1.8px solid ${
                props.hoverBorderColor ? props.hoverBorderColor : "#f4975d"
              } !important`,
              minHeight: !props.hideCustomValueContainerHeight
                ? props.height
                  ? props.height
                  : 53
                : "unset",
              maxHeight: 100,
              borderRadius: `${
                props.borderRadius ? props.borderRadius : "8px"
              } !important`,
            },
          }),
        }}
        cacheOptions
        defaultOptions={props.options}
        // loadOptions={props.promiseOptions}
        //   components={{ DropdownIndicator }}
        onChange={(e) => props.handleOnChange(e)}
        value={props.value}
        options={data}
        components={{ DropdownIndicator }}
        // {...props}
      />
      {props.error && (
        <Typography
          variant="body1"
          component="div"
          className={classes.errorText}
        >
          {props.errorText}
        </Typography>
      )}
    </div>
  );
};
