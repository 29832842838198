import React from "react";
import PropTypes from "prop-types";

import { SvgIcon } from "@material-ui/core";

function ZoomInIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.998 10.328H13.2v-8.8a1.468 1.468 0 1 0-2.932 0v8.8h-8.8a1.467 1.467 0 0 0 0 2.934h8.8v8.8a1.467 1.467 0 0 0 2.934 0V13.26h8.8a1.468 1.468 0 1 0 0-2.932h-.004Z"
        fill={props.color}
      />
    </SvgIcon>
  );
}

ZoomInIcon.propTypes = {
  color: PropTypes.string,
};

ZoomInIcon.defaultProps = {
  color: "#6F6F6F",
};

export default ZoomInIcon;
