import React from 'react';
import {Grid} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root:{
        boxShadow:'0px 15px 25px #110f4712',
        backgroundColor:'#fff',
        border:'1px solid rgba(0, 0, 0, 0.12)',
        borderRadius:10,
        padding:10,
        marginBottom:10
    },
    flexRow:{
        display:'flex',
        alignItems:'center',
    }
}))

export const CardListLoading = (props) =>{

    const classes = useStyles(props);

    return(
        <Grid container direction="row" justify="space-between" alignItems="center" className={classes.root}>
            <Grid item xs={12} sm={12} md={9} className={classes.flexRow}>
                <Skeleton variant="rect" width={70} height={40} />
                <Skeleton variant="text" width={100} height={20} style={{marginLeft:16}}/>
            </Grid>
            <Grid item xs={12} sm={10} md={3} className={classes.flexRow} style={{justifyContent:'space-between',}}>
                <Grid item>
                    <Skeleton variant="text" width={50} height={30}/>
                </Grid>
                <Grid item>
                    <Skeleton variant="text" width={50} height={30} />
                </Grid>
                <Grid item>
                    <Skeleton variant="text" width={50} height={30}/>
                </Grid>
                <Grid item>
                    <Skeleton variant="text" width={50} height={30}/>
                </Grid>
            </Grid>
        </Grid>
    )
}