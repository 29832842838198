import {
  Button,
  Checkbox,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import moment from 'moment';
import { Mobile } from '../../../components';
import { useTheme } from "@material-ui/core";
// import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-head": {
      backgroundColor: "#E5EAF4",
      minWidth: 150,
    },
  },
  addColumn: {
    "& .MuiIconButton-root": {
      borderRadius: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  addRowBtn: {
    minWidth: 150,
  },
  errorText:{
    color:'red',
    fontSize:'0.75rem'
  }
}));
const CustomTableFormRender = (props) => {
  let initialArray = []; 

  const classes = useStyles();
  const [tableData, setTableData] = React.useState(
    props?.tableData?.map((_) => _) ?? initialArray
  );

  const initialArrayFunc = () => {
    if (props?.data?.table_scheme?.row?.length < 0) {
      return [];
    } else {
      if (props.data?.table_scheme?.row) {
        props.data.table_scheme.row.map((l,i) => {
           initialArray.push(new Array(props?.data?.table_scheme?.column?.length));
           let findIndexKeys;
           if(l?.validations){
              findIndexKeys = Object.keys(l?.validations);
           }
           findIndexKeys.length > 0 && findIndexKeys.map((f)=>initialArray[i][f]=l?.validations[f]?.currentDate ?? '');
           return l
         });
      }
      props.handleChange({
        tableData:initialArray
      });
      return initialArray;
    }
  };

  React.useEffect(() => {
    if (props.data?.table_scheme?.row) {
      props.data.table_scheme.row.map((rowData, rowIndex) => {
        props.data.table_scheme.column.map((colData, columnIndex) => {
          let checkboxId = document.getElementById(
            `${props?.data?.id}-checkbox-${rowIndex}-${columnIndex}`
          );
          let inputId = document.getElementById(
            `${props?.data?.id}-input-${rowIndex}-${columnIndex}`
          );
          let dateId = document.getElementById(
            `${props?.data?.id}-date-${rowIndex}-${columnIndex}`
          );
          let dropdownId = document.getElementById(
            `${props?.data?.id}-dropdown-${rowIndex}-${columnIndex}`
          );
          let mobileId = document.getElementById(
            `${props?.data?.id}-mobile-${rowIndex}-${columnIndex}`
          );
          if (checkboxId) {
            checkboxId.value = "";
          }
          if (inputId) {
            inputId.value = "";
          }
          if (dateId) {
            dateId.value = "";
          }
          if (dropdownId) {
            dropdownId.value = "";
          }
          if (mobileId) {
            mobileId.value = "";
          }
          return colData;
        });
        return rowData;
      });
    }
    setTableData(props?.tableData?.map((_) => _) ?? initialArrayFunc());
    // eslint-disable-next-line
  }, [props?.tableData]);

  const onChangeValue = (value, rowIndex, columnIndex) => {    
    let data = tableData?.[rowIndex]?.map((_) => _) ?? [];
    data[columnIndex] = value;
    tableData[rowIndex] = data;
    setTableData(tableData);
    props.handleChange({
      tableData,
      table_scheme: props?.data?.table_scheme,
    });
  };

  const addRow = () => {
    tableData.push([]);
    props.handleChange({
      tableData,
      // table_scheme: props?.data?.table_scheme
    });
  };

  const deleteRow = (index) => {
    let filteredData = tableData.filter((l, i) => i !== index);
    setTableData(filteredData);

    props.handleChange({
      tableData: filteredData,
      // table_scheme: props?.data?.table_scheme,
    });
  };

  const tableRows = () => {
    let tableDataLength = props?.tableData?.length ?? 0;

    if (props?.data?.table_scheme?.row?.length >= tableDataLength) {
      return props?.data?.table_scheme?.row;
    } else {
      return props?.tableData ?? [];
    }
  };

  const minimunDate = (rowIndex,columnIndex) =>{
    let validation = props?.data?.table_scheme?.row?.[rowIndex]?.validations?.[columnIndex] ?? null;
    if(validation){
      if(validation?.fromDate){
        return validation?.fromDate ?? ''
      }
      else if(validation?.toggleSelectedData === 'Hide Past Dates'){
        return moment(new Date()).format("YYYY-MM-DD") ?? ''
      }
    }
  }
  
  const maximumDate = (rowIndex,columnIndex) =>{
    // Hide Future Dates
    let validation = props?.data?.table_scheme?.row?.[rowIndex]?.validations?.[columnIndex] ?? null;

    if(validation){
      if(validation?.toDate){
        return validation?.toDate ?? ''
      }
      else if(validation?.toggleSelectedData === 'Hide Future Dates'){
        return moment(new Date()).format("YYYY-MM-DD") ?? ''
      }
    }
  }
  const theme = useTheme()

  return (
    <div className={classes.root}>
      <div style={{ display: "flex" }}>
        {props.heading && (
          <Typography variant="body1" gutterBottom>
            {props.heading}
          </Typography>
        )}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell variant="head" style={{ width: 20, minWidth: 20 }}>
                <Typography variant="body1">Actions</Typography>
              </TableCell>
              {props?.data?.table_scheme?.column &&
                props?.data?.table_scheme?.column?.map((colData, index) => {
                  return (
                    <TableCell key={index} variant="head">
                      <Typography variant="body1">{colData.name}</Typography>
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows()?.map((rowData, rowIndex) => {
              return (
                <TableRow key={rowIndex}>
                  <TableCell>
                 {
                     rowIndex >= props?.data?.table_scheme?.row?.length  ? (
                    <IconButton onClick={() => deleteRow(rowIndex)}>
                      <DeleteOutlineIcon />
                    </IconButton>) : ''
                  }
                  </TableCell>
                  {props?.data?.table_scheme?.column &&
                    props?.data?.table_scheme?.column?.map(
                      (colData, columnIndex) => {
                        switch (colData.ui_component) {
                          case "check_box":
                            return (
                              <TableCell aria-disabled  key={columnIndex}>
                                <Checkbox
                                  id={`${props?.data?.id}-checkbox-${rowIndex}-${columnIndex}`}
                                  checked={
                                    tableData?.[rowIndex]?.[columnIndex] ??
                                    false
                                  }
                                  onChange={(event) =>
                                    onChangeValue(
                                      event.target.checked,
                                      rowIndex,
                                      columnIndex
                                    )
                                  }
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                  color="primary"
                                  disabled={(props?.isReadonly || props?.disabled) ?? false}
                        
                                />
                              </TableCell>
                            );
                          case "input_text":
                            return (
                              <TableCell>
                                <TextField
                                  id={`${props?.data?.id}-input-${rowIndex}-${columnIndex}`}
                                  value={tableData?.[rowIndex]?.[columnIndex]}
                                  onChange={(event) =>
                                    onChangeValue(
                                      event.target.value,
                                      rowIndex,
                                      columnIndex
                                    )
                                  }
                                  inputProps={{
                                    readOnly: props.isReadonly ?? false,
                                  }}
                                  disabled={props?.disabled ?? false}
                        
                                  error={props?.errorValidation?.length > 0 && props?.errorValidation?.[rowIndex]?.[columnIndex]?.error ? true : false}
                                  helperText={props?.errorValidation?.length > 0 && props?.errorValidation?.[rowIndex]?.[columnIndex]?.errorMessage ? props?.errorValidation?.[rowIndex]?.[columnIndex]?.errorMessage : ''}
                                />
                              </TableCell>
                            );
                          case "date":
                            return (
                              <TableCell>
                                <form noValidate>
                                  <TextField
                                    id={`${props?.data?.id}-date-${rowIndex}-${columnIndex}`}
                                    type="date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    value={tableData?.[rowIndex]?.[columnIndex]}
                          
                                    onChange={(event) =>
                                      onChangeValue(
                                        event.target.value,
                                        rowIndex,
                                        columnIndex
                                      )
                                    }
                                    disabled={props?.disabled ?? false}
                                    inputProps={{
                                      readOnly: props.isReadonly ?? false,
                                      min: minimunDate(rowIndex,columnIndex),
                                      max:maximumDate(rowIndex,columnIndex)
                                    }}
                                  />
                                </form>
                              </TableCell>
                            );
                          case "dropdown":
                            return (
                              <TableCell>
                                <Autocomplete
                                  id={`${props?.data?.id}-dropdown-${rowIndex}-${columnIndex}`}
                                  options={colData?.options ?? []}
                                  getOptionLabel={(option) => option}
                        
                                  // style={{ width: 300 }}
                                  onChange={(e, newValue) =>
                                    onChangeValue(
                                      newValue,
                                      rowIndex,
                                      columnIndex
                                    )
                                  }
                                  disabled={(props.isReadonly || props?.disabled) ?? false}
                                  value={tableData?.[rowIndex]?.[columnIndex]}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </TableCell>
                            );
                          case "mobile":
                            return (
                              <TableCell>
                                  <Mobile
                                    value={tableData?.[rowIndex]?.[columnIndex] ?? {}}
                                    handleChange={(value) => onChangeValue(value,rowIndex,columnIndex)}
                                    type={"number"}
                                    // isReadonly={isReadyOnly}
                                    // isrequired={isrequired}
                          
                                    data={{
                                      id:`${props?.data?.id}-mobile-${rowIndex}-${columnIndex}`
                                    }}
                                    isReadonly={props?.isReadonly ?? false}
                                    disabled={props?.disabled ?? false}
                                    // errorValidation={errorValidation ?? {}}  
                                    />
                              </TableCell>
                            );
                          default:
                            return (
                              <TableCell key={columnIndex}>
                                <TextField
                                  id={`${props?.data?.id}-input-${rowIndex}-${columnIndex}`}
                                  value={tableData?.[rowIndex]?.[columnIndex]}
                        
                                  onChange={(event) =>
                                    onChangeValue(
                                      event.target.value,
                                      rowIndex,
                                      columnIndex
                                    )
                                  }
                                  inputProps={{
                                    readOnly: props.isReadonly ?? false,
                                  }}
                                  disabled={props?.disabled ?? false}
                                  error={props?.errorValidation?.length > 0 && props?.errorValidation?.[rowIndex]?.[columnIndex]?.error ? true : false}
                                  helperText={props?.errorValidation?.length > 0 && props?.errorValidation?.[rowIndex]?.[columnIndex]?.errorMessage ? props?.errorValidation?.[rowIndex]?.[columnIndex]?.errorMessage : ''}
                                />
                              </TableCell>
                            );
                        }
                      }
                    )}
                </TableRow>
              );
            })}
            <Button
              variant="text"
              color={theme.palette.ternary.main}
              className={classes.addRowBtn}
              onClick={addRow}
              disabled={(props.isReadonly || props?.disabled) ?? false}
            >
              <Typography>Add Row</Typography>
              <IconButton>
                <AddCircleOutlineOutlinedIcon color={theme.palette.ternary.main} />
              </IconButton>
            </Button>
          </TableBody>
        </Table>
      </TableContainer>
      {/* {
          props?.errorValidation && props?.errorValidation?.error && 
          <div><Typography variant="caption" className={classes.errorText}>{props?.errorValidation?.errorMessage}</Typography></div>
      } */}
    </div>
  );
};
//  CustomTableFormRender.propTypes = {

//  };
export default CustomTableFormRender;
