/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-01-27
 * @modify date 2021-06-29
 * @desc Form Renderer Parent
 */

 import React from 'react';
 import { withNavBars } from '../../HOCs';
 import { FormRenderer } from './formRenderer';
 import { actions } from 'tanyacare-middleware';
 import { connect } from "react-redux";
 import { withRouter } from 'react-router-dom';
 import { LoadingSection, InternatlServerError, NotFoundSection } from '../../components';
 import { giveMeRoleDataSecurity, Message } from "../formConfigurator/utils" ;
 import { routes } from '../../router/routes';
 import copy from "fast-copy";
 
 class FormRendererParent extends React.Component {
     constructor(props) {
         super(props);
         this.state = {}
     }
 
     componentDidMount() {
         this.getFormDetail();
         this.getCountryList();
         this.getPatient()
     }
 
     getPatient = () => {
         this.props.GET_ALL_PATIENTS({ filter: "" });
     }
 
     getCountryList = (searchKey = "", created_at = false) => {
 
         const roleDataSecurity = giveMeRoleDataSecurity(this.props.userData);
         const createdBy = this.props.userData.user_profile_id;
 
         let sort = {
             sortField: "country_name",
             sortDirection: "ASC",
             diseases: roleDataSecurity?.disease?.[0] === 'all' ? [] : roleDataSecurity?.disease,
             countryIds: roleDataSecurity?.country?.[0] === 'all' ? [] : roleDataSecurity?.country
         };
 
         if (created_at) {
             sort.sortField = "updated_at";
             sort.sortDirection = "DESC";
         }
 
         if (createdBy) {
             sort.created_by = createdBy;
         }
 
         this.props.GET_ALL_COUNTRIES({
             countryName: searchKey,
             ...sort
         });
     };
 
     getFormDetail = () => {
 
         const roleDataSecurity = giveMeRoleDataSecurity(this.props.userData);
         const createdBy = this.props.userData.user_profile_id;
 
         let id = this.props.match.params.id;
         let entryId = this.props.match.params.entry_id;
         let isInvestigator = this.props?.location?.state?.isInvestigator ?? false;
 
         let params = {
             formId: id,
             entryId,
             isInvestigator,
             typeViewer: this?.props?.title,
             secToShow: this?.props?.secToShow,
             type: this.props?.secName,
             forms: roleDataSecurity.form?.[0] === 'all' ? [] : roleDataSecurity.form,
             countryIds: roleDataSecurity.country?.[0] === 'all' ? [] : roleDataSecurity.country,
             level: {
                 level1: roleDataSecurity.level1?.[0] === 'all' ? [] : roleDataSecurity.level1,
                 level2: roleDataSecurity.level2?.[0] === 'all' ? [] : roleDataSecurity.level2,
                 level3: roleDataSecurity.level3?.[0] === 'all' ? [] : roleDataSecurity.level3,
                 level4: roleDataSecurity.level4?.[0] === 'all' ? [] : roleDataSecurity.level4,
                 level5: roleDataSecurity.level5?.[0] === 'all' ? [] : roleDataSecurity.level5,
                 level6: roleDataSecurity.level6?.[0] === 'all' ? [] : roleDataSecurity.level6,
                 level7: roleDataSecurity.level7?.[0] === 'all' ? [] : roleDataSecurity.level7,
                 level8: roleDataSecurity.level8?.[0] === 'all' ? [] : roleDataSecurity.level8,
                 level9: roleDataSecurity.level9?.[0] === 'all' ? [] : roleDataSecurity.level9,
                 level10: roleDataSecurity.level10?.[0] === 'all' ? [] : roleDataSecurity.level10,
                 level11: roleDataSecurity.level11?.[0] === 'all' ? [] : roleDataSecurity.level11,
                 level12: roleDataSecurity.level12?.[0] === 'all' ? [] : roleDataSecurity.level12,
             }
         };
 
         if (createdBy) {
             params.created_by = createdBy;
         }
 
 
         this.props.GET_RENDER_JSON(params);
     };
 
     giveMeAnswer = (bulkAnswers) => {
         let answer = {};
         bulkAnswers.answers.map((ans, index) => {
             return answer[ans?.question_id] = ans.answer;
         })
 
         return answer;
     }
 
     getPatientDetails = (answers) => {
 
         let patientName = Object.values(answers).filter(l => l?.tag?.replace(/\s/g, "")?.toLowerCase() === 'patientname')?.[0]?.answer ?? '';
         let icNumber = Object.values(answers).filter(l => l?.tag?.replace(/\s/g, "")?.toLowerCase() === 'icnumber')?.[0]?.answer ?? '';
         let passportNumber = Object.values(answers).filter(l => l?.tag?.replace(/\s/g, "")?.toLowerCase() === 'passportnumber')?.[0]?.answer ?? '';
 
         return {
             patientName,
             icNumber,
             passportNumber
         }
     }
 
     giveMeStatus = () => {
         switch (this.props.secName) {
             case 0:
                 return "notifier_status"
             case 1:
                 return "verifier_status"
 
             case 2:
                 return "registration_status"
 
             case 3:
                 return "investigator_status"
 
             default:
                 return "";
         }
     }
 
     giveMeStatusData = (statusArray, entry) => {
         let toogleData = {
             toggleButtonData: statusArray?.map(_ => {
                 if (_.label === "For Confirmation") {
                     return {
                         ..._,
                         label: "Pending"
                     }
                 }
                 if (_.label === "For Investigation") {
                     return {
                         ..._,
                         label: "Review"
                     }
                 }
                 return _;
             }),
             isSelected: entry[this.giveMeStatus()],
             exclusive: true,
             statusUpdateData: entry,
             status: this.giveMeStatus(),
         }
         return toogleData;
     }
 
     canIshowForm = () => {
         if ([1, 2, 3].indexOf(this.props.secName) > 0) {
             return Object.values(this.props?.form_detail?.data?.answers ?? {}).length > 0;
         } else {
             return true
         }
     }
 
     render() {
 
         const {
             data,
             loading,
             error
         } = this.props?.form_detail;
 
         const {
             form = [],
             form_detail = [],
             tree = [],
             bulkAnswers = {},
             answers = {},
             statusArray = [],
             patientDetail = {}
         } = data;
 
         this.copiedForm = copy(form);
 
         const formId = this.props.match.params.id;
         const entryId = this.props.match.params.entry_id;
         const isInvestigator = this.props?.location?.state?.isInvestigator ?? false;
         const isReadyOnly = this.props.match.path === routes.view_form_notify;
 
         return <div>
             {loading && !error && <LoadingSection message={Message.form_detail_loading} />}
 
             {error && <InternatlServerError onRetry={this.getFormDetail} />}
             {!loading && !error && this.canIshowForm() && <FormRenderer
                 form_detail={form_detail}
                 tree_view={tree}
                 forms={this.copiedForm}
                 parent_form_id={formId}
                 entry_id={entryId}
                 isEdit={entryId ? true : false}
                 isReadyOnly={isReadyOnly}
                 answer={this.giveMeAnswer(bulkAnswers)}
                 isInvestigator={isInvestigator}
                 toggleButtonStatus={entryId && this.canIshowForm() ? this?.giveMeStatusData(statusArray, bulkAnswers) : {}}
                 secToShow={this?.props?.secToShow}
                 secName={this?.props?.secName}
                 getPatientDetails={this.getPatientDetails(answers)}
                 entryPatientDetails={copy(patientDetail)}
             />}
             {!loading && !error && !this.canIshowForm() && <NotFoundSection message="401 - Unauthorized Access" />}
         </div>
     }
 }
 
 const mapStateToProps = (state) => ({
     form_detail: state?.renderSlice?.getRenderJson,
     userData: state?.authSlice?.login?.data ?? {},
 });
 
 
 export default connect(mapStateToProps, actions)(withNavBars(withRouter(FormRendererParent)));