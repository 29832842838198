/**
 * @author sridharan
 * @email sridharan.vijaya@ainqa.com
 * @create date 2022-01-28 00:00:36
 * @modify date 2022-01-28 13:27:48
 * @desc Component for Promotional Banner View Page - Subscription Info Item
 */
import React from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { textClampStyleGenerator } from "../../../../../utils";
import { IconComponent } from "../../../../../assets";
import SubscriptionDetailCard from "./subscriptionDetailCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateArea: `
      'icon label'
      'value value'
    `,
    gridTemplateColumns: "24px 1fr",
    gridTemplateRows: "24px 1fr",
    gridRowGap: "12px",
    gridColumnGap: "16px",
  },
  gridChild1: {
    gridArea: "1 / 1 / 2 / 2",
  },
  gridChild2: {
    gridArea: "1 / 2 / 2 / 3",
  },
  gridChild3: {
    gridArea: "2 / 1 / 3 / 3",
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
    marginBottom: "12px",
  },
  value: {
    fontSize: "16px",
    fontWeight: 400,
    ...textClampStyleGenerator(2),
  },
}));

function SubscriptionInfoItem(props) {
  const classes = useStyles(props);
  const { label, iconName, hideIcon, subscriptionData } = props;
  return (
    <div className={classes.root}>
      {!hideIcon && (
        <div className={classes.gridChild1}>
          <IconComponent name={iconName} />
        </div>
      )}
      <div className={classes.gridChild2}>
        <Typography color="textSecondary" className={classes.label}>
          {label}
        </Typography>
      </div>
      <div className={classes.gridChild3}>
        <SubscriptionDetailCard {...subscriptionData} />
      </div>
    </div>
  );
}

SubscriptionInfoItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  iconName: PropTypes.string,
  hideIcon: PropTypes.bool,
};

SubscriptionInfoItem.defaultProps = {
  label: "-",
  value: "-",
  iconName: "application",
  hideIcon: false,
};

export default SubscriptionInfoItem;
