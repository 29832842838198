import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  // Button,
  IconButton,
} from "@material-ui/core";
import withAlelrtAndDialog from "../../../HOCs/withAlelrtAndDialog";
import IdTypeComponent from "../idTypeComponent";
import { withTheme } from "@material-ui/core/styles";
import { PreviewPngIcon } from "../../../assets";
import ViewAllCards from "./viewAllCard";
import { FILE_URI } from "../../../utils/constants";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const styles = (theme) => ({
  title: {
    fontSize: "20px",
    // fontWeight: 600,
    textAlign: "left",
    marginBottom: 20,
    marginTop: 8,
  },
  add_title: {
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "left",
    // color: "#f27d35",
    color: theme.palette.ternary.main,
    textTransform: "uppercase",
    cursor: "pointer",
    width: "max-content",
  },
  detail_title: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    textAlign: "left",
    // color: "#f27d35",
    color: theme.palette.ternary.main,
    textTransform: "uppercase",
    width: "max-content",
  },
  view_more_title: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    color: theme.palette.ternary.main,
    textTransform: "uppercase",
    width: "max-content",
  },
  helperTextRoot: {
    color: "#bc2d4f",
    fontSize: "0.8rem",
  },
  listItemGutters: {
    paddingLeft: "0px",
  },
  listItemSecondaryAction: {
    paddingRight: 90,
  },
});

class ModernViewIdCardDetail extends React.Component {
  state = {
    // previewImage: null,
    // imageSrcError: false,
    idType: [],
    previewImage: [],
    imageSrcError: {},
  };

  componentDidMount() {
    this.executeMountInitialization();
  }

  executeMountInitialization = () => {
    if (
      Array.isArray(this.props?.editData) &&
      this.props?.editData?.length > 0
    ) {
      //
      this.setState(
        {
          idType: [...this.props?.editData],
        },
        () => {
          this.renderPreviewImage();
        }
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps?.editData !== this.props?.editData) {
      this.executeMountInitialization();
    }
  }

  renderPreviewImage = () => {
    const { idType } = this.state;
    // const firstElement = editData?.[0]

    let previewImage = Array.from(
      new Array(idType?.length >= 2 ? 2 : idType?.length)
    );

    if (idType?.length > 0) {
      idType.map((item, index) => {
        if (index < 2) {
          if (item?.file && item?.file.constructor !== Object) {
            try {
              let tempObjURL = URL.createObjectURL(item?.file);
              previewImage[index] = tempObjURL;
            } catch (error) {
              console.log(error);
            }
          } else if (item?.id_photo) {
            previewImage[index] = `${FILE_URI}/${item?.id_photo}`;
          }
        }
        return item;
      });

      this.setState({
        previewImage: previewImage,
      });
    }

    // if (firstElement?.file && firstElement?.file.constructor !== Object) {
    //     try {
    //         let tempObjURL = URL.createObjectURL(firstElement?.file)
    //         this.setState({
    //             previewImage: tempObjURL
    //         })
    //     }
    //     catch (error) {
    //         console.log(error)
    //     }
    // } else if (firstElement?.id_photo) {
    //     this.setState({
    //         previewImage: `${FILE_URI}/${firstElement?.id_photo}`
    //     })
    // }
  };

  handleImageError = (index) => {
    let { imageSrcError } = this.state;
    imageSrcError[index] = true;
    this.setState({
      imageSrcError: imageSrcError,
    });
  };

  onEdit = (editData) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <IdTypeComponent
          id={this?.props?.id}
          editData={editData}
          isEdit={true}
          list={this.state.idType}
          callBackOnSave={this.props.callBackOnSave}
          backNeeded={this.props.backNeeded}
          backHandler={this.props.goBackHandler}
        />
      ),
    });
  };

  handleOpenMoreDrawer = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: true,
      component: (
        <ViewAllCards
          id={this?.props?.id}
          items={this.props.editData}
          onEdit={this.onEdit}
          onRemove={this.props.callBackOnSave}
          onAdd={() =>
            this.props.handledrawer("Add New ID", null, this.props.editData)
          }
          backNeeded={this.props.backNeeded}
          backHandler={this.props.goBackHandler}
        />
      ),
    });
  };
  callBackOnSaveIdType = (data, isremove) => {
    if (isremove) {
      this.props.dialog.setDialog({
        ...this.props.dialog,
        open: true,
        title: "Confirm Action",
        body: "Are you sure to delete this ID?",
        positiveBtn: "CONFIRM",
        negativeBtn: "CANCEL",
        dontShowButtons: false,
        onOk: () => {
          let isUpdateData = this.state.idType?.filter(
            (val) => val.value !== data?.value
          );

          this.setState({ idType: isUpdateData }, () => {
            this.props.callBackOnSave(data, true);
          });
          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: false,
          });
        },
        onCancel: () => {
          this.props.dialog.setDialog({
            ...this.props.dialog,
            open: false,
          });
        },
      });
    }
  };

  render() {
    const {
      classes,
      handledrawer,
      // ondltList,
      // onchanged,
      // editData,
      // cardmd,
      // cardsm,
      disabled,
    } = this.props;
    const { theme } = this.props;
    const { idType, previewImage, imageSrcError } = this.state;
    return (
      <>
        {idType?.length > 0 ? (
          <>
            {/* <Typography className={classes.title}>{"ID Types"}</Typography> */}
            <Grid container>
              <Grid item container xs={12}>
                <Grid item xs style={{ display: "flex", alignItems: "center" }}>
                  {idType?.length >= 1 ? (
                    <Typography className={classes.detail_title}>
                      {"ID details"}
                    </Typography>
                  ) : (
                    <Typography
                      onClick={() => handledrawer("Add New ID", null, idType)}
                      className={classes.add_title}
                      id={this.props?.id?.add_id_type_id}
                    >
                      {"+ Add ID"}{" "}
                      {!this.props.hideRequired && (
                        <span
                          style={{
                            color: theme.palette.error.main,
                            fontWeight: "normal",
                            fontSize: "0.85rem",
                          }}
                        >
                          {"*"}
                        </span>
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <IconButton
                    id={this.props?.id?.add_id_type_icon_id}
                    onClick={() => handledrawer("Add New ID", null, idType)}
                    size={"small"}
                    style={{
                      border: "1px solid #E8E8EC",
                      margin: "0px 4px",
                      padding: "4px",
                    }}
                  >
                    <AddIcon fontSize={"small"} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {idType?.map((item, index) => {
                  if (index < 2) {
                    return (
                      <div style={{ display: "flex" }}>
                        <List
                          dense={true}
                          style={{ width: "100%" }}
                          disablePadding
                        >
                          <ListItem
                            style={{ paddingLeft: 0 }}
                            divider
                            classes={{
                              secondaryAction: classes.listItemSecondaryAction,
                              gutters: classes.listItemGutters,
                            }}
                          >
                            {Boolean(item?.id_photo) && (
                              <ListItemIcon
                                style={{ justifyContent: "center" }}
                              >
                                {(imageSrcError?.[index]
                                  ? imageSrcError?.[index]
                                  : false) && (
                                  <PreviewPngIcon
                                    style={{
                                      visibility: Boolean(item?.id_photo)
                                        ? "unset"
                                        : "hidden",
                                    }}
                                  />
                                )}
                                {previewImage?.length >= index &&
                                  previewImage?.[index] &&
                                  !imageSrcError?.[index] && (
                                    <img
                                      src={previewImage[index]}
                                      alt={"Failed to show preview"}
                                      id={
                                        this.props?.id?.view_document_id_type_id
                                      }
                                      onLoad={() => {
                                        if (item?.file) {
                                          URL.revokeObjectURL(previewImage);
                                        }
                                      }}
                                      onError={() => {
                                        this.handleImageError(index, item);
                                      }}
                                      style={{
                                        // margin: "auto",
                                        // maxWidth: "100%",
                                        height: "34px",
                                        width: "34px",
                                        borderRadius: 4,
                                        objectFit: "cover",
                                      }}
                                    />
                                  )}
                              </ListItemIcon>
                            )}
                            <ListItemText
                              primary={item?.label ? item?.label : "NA"}
                              secondary={item?.id_type ? item?.id_type : null}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                id={this.props?.id?.edit_id_type_icon_id}
                                onClick={() => this.onEdit(item)}
                                edge="end"
                                aria-label="edit"
                                style={{
                                  border: "1px solid #E8E8EC",
                                  margin: "0px 4px",
                                  padding: "4px",
                                }}
                              >
                                <EditOutlinedIcon fontSize={"small"} />
                              </IconButton>
                              <IconButton
                                id={this.props?.id?.delete_id_type_icon_id}
                                onClick={() => {
                                  this.callBackOnSaveIdType(item, true);
                                }}
                                edge="end"
                                aria-label="delete"
                                style={{
                                  border: "1px solid #E8E8EC",
                                  margin: "0px 4px",
                                  padding: "4px",
                                }}
                              >
                                <DeleteOutlinedIcon fontSize={"small"} />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </Grid>
              {idType?.length > 2 && (
                <Typography
                  id={this.props?.id?.view_more_id_type_icon_id}
                  onClick={() => this.handleOpenMoreDrawer()}
                  className={classes.view_more_title}
                  style={{ cursor: "pointer", paddingTop: 8, width: "100%" }}
                  align="right"
                >
                  {"VIEW MORE"}
                </Typography>
              )}
            </Grid>
          </>
        ) : (
          <>
            {!disabled && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Typography>
                  {!this.props.hideActionBtn && (
                    <Typography
                      id={this.props?.id?.add_id_type_id}
                      onClick={() => handledrawer("Add New ID", null, idType)}
                      className={classes.add_title}
                    >
                      {"+ Add ID"}{" "}
                      {!this.props.hideRequired && (
                        <span
                          style={{
                            color: theme.palette.error.main,
                            fontWeight: "normal",
                            fontSize: "0.85rem",
                          }}
                        >
                          {"*"}
                        </span>
                      )}
                    </Typography>
                  )}
                </Typography>
              </div>
            )}
            {this.props.error && (
              <FormHelperText classes={{ root: classes.helperTextRoot }}>
                {this.props.errorText}
              </FormHelperText>
            )}
          </>
        )}
      </>
    );
  }
}

export default withAlelrtAndDialog(
  withStyles(styles)(withTheme(ModernViewIdCardDetail))
);
