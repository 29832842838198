import React, { useState } from "react";
import { IconComponent } from "../../../../../assets";
import { getFormmattedNameWithSalutation } from "../../../../../utils/common";
import { CustomAvatar } from "../../../../../components";
import Typography from "@material-ui/core/Typography";
import { CustomMapInfoWindow } from "./customMapInfoWindow";
import Styled from "styled-components";
import { usePopper } from "react-popper";

// const ArrowBox = Styled.div`
// 	position: relative;
// 	background: #fff;
//   margin-top: 50px;
//   height: 24px;
//   border: 0.3px solid #3B3F5B2E;
//   font-size: 10px;
//   font-weight: 400;

//   &:after {
//     bottom: 100%;
//     left: 50%;
//     border: solid transparent;
//     content: " ";
//     height: 0;
//     width: 0;
//     position: absolute;
//     pointer-events: none;
//     border-color: rgba(204, 204, 204, 0);
//     border-bottom-color: #CCC;
//     border-width: 30px;
//     margin-left: -30px;
//   }
// `;

// const CustomBox = Styled.div`
//   width: 150px;
//   height: 100px;
//   background-color: #3498db;
//   position: relative;
//   border-radius: 4px;
//   &:after {
//     content: "";
//     display: block;
//     border-width: 10px 10px 0;
//     border-style: solid;
//     border-color: #3498db transparent transparent;
//     margin-left: -10px;
//     position: absolute;
//     bottom: -10px;
//     left: 50%;

//     border-top-color: transparent;
//   border-bottom-color: #3498db;
//   top: -10px;
//   bottom: auto;

//   border-top-width: 0;
//   border-bottom-width: 10px;
//   }
// `;

// const PopBox = Styled.div`
//   background-color: orange;
// `;

// const PopArrow = Styled.div`
//   background-color: blue;
// `;

const PopperContainer = Styled.div`
  // box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: white;
  padding: 6px;
  text-align: center;
  white-space: nowrap;
  

  #arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    &:after {
      content: " ";
      background-color: white;
      // box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: -12px; // padding + popper height
      left: 0;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
    }
  }

  &[data-popper-placement^='top'] > #arrow {
    bottom: -30px;
    :after {
      // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
  // &[data-popper-placement^='bottom'] > #arrow {
  //   top: -30px;
  //   :after {
  //     // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  //   }
  // }
  // &[data-popper-placement^='left'] > #arrow {
  //   right: -30px;
  //   :after {
  //     // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  //   }
  // }
  // &[data-popper-placement^='right'] > #arrow {
  //   left: -30px;
  //   :after {
  //     // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  //   }
  // }
`;

const InfoPopperContainer = Styled.div`
  border-radius: 5px;
  background-color: white;
  text-align: center;
  white-space: nowrap;

  #info-arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: 101;
    &:after {
      content: " ";
      background-color: white;
      position: absolute;
      right: 0;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
    }
  }

  &[data-popper-placement^='left'] > #info-arrow {
    right: -5px;
  }
`;

function CustomMapMarker({ show, place, ...props }) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  // Info Popper Element States/Hooks
  const [infoPopperElement, setInfoPopperElement] = useState(null);
  const [infoArrowElement, setInfoArrowElement] = useState(null);

  const { styles: infoStyles, attributes: infoAttributes } = usePopper(
    referenceElement,
    infoPopperElement,
    {
      placement: "left-start",
      modifiers: [
        { name: "arrow", options: { element: infoArrowElement } },
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ],
    }
  );
  const iconName =
    place.status === "Scheduled"
      ? "clock"
      : place.status === "Completed" || place.status === "Closed"
      ? "check"
      : place.status === "Cancelled"
      ? "cancel"
      : "";
  const iconColor =
    place.status === "Scheduled"
      ? "grey"
      : place.status === "Completed" || place.status === "Closed"
      ? "green"
      : place.status === "Cancelled"
      ? "red"
      : "";
  const icon = () => (
    <IconComponent
      style={{ fontSize: "12px" }}
      name={iconName}
      color={iconColor}
      // name={
      //   place.status === "Scheduled"
      //     ? "clock"
      //     : place.status === "Completed" || place.status === "Closed"
      //     ? "check"
      //     : place.status === "In Progress"
      //     ? "check"
      //     : ""
      // }
      // color={
      //   place.status === "Scheduled"
      //     ? ""
      //     : place.status === "Completed" || place.status === "Closed"
      //     ? "green"
      //     : place.status === "In Progress"
      //     ? "yellow"
      //     : ""
      // }
    />
  );
  //   const icon = <IconComponent style={{ fontSize: "12px" }} name="multiply" />;
  return (
    <>
      <div
        ref={setReferenceElement}
        style={{
          // position: "relative",
          width: "40px",
          position: "absolute",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            right: "2px",
            zIndex: "1",
          }}
        >
          {icon}
        </div>
        <CustomAvatar
          src={place?.client?.profile_pic}
          size={40}
          classes={{ root: { margin: 0 } }}
        />
        {/* <CustomBox>Mr. Manojkumar</CustomBox> */}

        {/* name={place?.client?.name}
            salutation={place?.client?.salutation} */}
      </div>

      <PopperContainer
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <div ref={setArrowElement} style={styles.arrow} id="arrow" />
        <Typography style={{ fontSize: "10px", fontWeight: 500 }}>
          {getFormmattedNameWithSalutation(
            place?.client?.name,
            place?.client?.salutation
          )}
        </Typography>
      </PopperContainer>

      {show && (
        <InfoPopperContainer
          ref={setInfoPopperElement}
          style={infoStyles.popper}
          {...infoAttributes.popper}
        >
          <CustomMapInfoWindow
            appointmentDetails={place}
            arrivalTime={props.arrivalTime}
            travelledPercentage={props.travelledPercentage}
          />
          <div
            ref={setInfoArrowElement}
            style={infoStyles.arrow}
            id="info-arrow"
          />
        </InfoPopperContainer>
      )}
    </>
  );
}

export default CustomMapMarker;
