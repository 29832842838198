import React from "react";
import CardSkeleton from "../userList/cardSkeleton";

const ListLoader = (props) => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, key) => {
        return (
          <div key={key}>
            <CardSkeleton />
          </div>
        );
      })}
    </>
  );
};

export default ListLoader;
