import React from 'react';
import { CountCardLoaders, CountCardErrors, CountCards, InternatlServerError, NotFoundSection } from "../../../../components";
import { Grid, withStyles } from '@material-ui/core'
import { CountCardIcons } from '../../../../components/countCard/index'
import MainIssueCard from '../../../../components/clientIssueCard'
import { IssueCardLoaders } from '../../../../components/clientIssueCard'
import { routes } from '../../../../router/routes'
import { actions } from 'tanyacare-middleware'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
    root: {
        padding: 25
    },
    trackerCardsContainer: {
        marginBottom: 30
    },
    clickable: {
        cursor: 'pointer'
    }
})

class Issues extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
            error: false
        }
    }

    componentDidMount() {
        this.getIssues()
    }

    getIssues = () => {
        const id = this.props?.match?.params?.client_id;
        this.props.GET_CLIENT_ALL_ISSUES({ id })
    }

    navigateToIssueDetail = (issue) => {
        const id = this.props?.match?.params?.client_id
        this.props.history.push(routes.monitor_client_issue_parent + id + '/' + issue?.issue_id)
    }


    render() {
        const { classes } = this.props
        let {
            data,
            loading,
            error
        } = this.props.issues;

        loading = false;
        error = false;

        //This card data is needed to map the cards
        const cardData = [
            {
                count: data?.open_count + data?.resolved_count + data?.pending_count,
                label: 'Total Issues'
            },
            {
                count: data?.open_count,
                label: `Open Issues`
            },
            {
                count: data?.resolved_count,
                label: `Resolved Issues`
            },
            {
                count: data?.pending_count,
                label: `Pending Issues`
            },
        ]

        const graphs = [CountCardIcons.miniGraphOne, CountCardIcons.miniGraphTwo, CountCardIcons.miniGraphThree, CountCardIcons.miniGraphFour]



        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item container spacing={3} className={classes.trackerCardsContainer}>
                        {loading && <Grid item xs={12}>
                            <CountCardLoaders count={4} />
                        </Grid>}
                        {error && <Grid item xs={12}>
                            <CountCardErrors count={4} />
                        </Grid>}
                        {!loading && !error && cardData.map((card, index) => {
                            const mylooper = index > 3 ? (index % 4) : index
                            return (
                                <Grid key={index} item xs={12} sm={6} md={6} lg={3} xl={3}>
                                    <CountCards count={card.count} label={card.label} graph={graphs[mylooper]} index={mylooper} />
                                </Grid>
                            )
                        })}
                    </Grid>

                    {/* Main Issue Card */}
                    {loading && <IssueCardLoaders count={4} />}
                    {error && <Grid item xs={12}>
                        <InternatlServerError message="Unable to fetch Issues" onRetry={this.getIssues} top="15vh" />
                    </Grid>}
                    {!loading && !error && data?.issue_list?.map((issue, key) => {
                        return (
                            <Grid item xs={12} key={key} onClick={() => this.navigateToIssueDetail(issue)} className={classes.clickable}>
                                <MainIssueCard {...issue} />
                            </Grid>
                        )
                    })}
                    {!loading && !error && data?.issue_list?.length === 0 && <div style={{width:"100%"}}>
                        <NotFoundSection message="You don't have any issues" />
                    </div>}
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    issues: state?.clientIssue?.clientAllIssues


})

export default connect(mapStateToProps, actions)(withRouter(withStyles(styles)(Issues)));