import React from "react";
import { makeStyles } from "@material-ui/core";
import { CustomChip } from "./customChip";
import { PopperListRenderer } from "../hybridTypography/popperListRenderer";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles({
  root: {
    display: "flex",
    gap: 10,
    alignItems: "center",
    flexWrap: "wrap",
  },
});

export function MultipleChip({ category, loading }) {
  const classes = useStyles();
  return (
    <>
      {loading && <Skeleton variant="text" width={120} />}
      {!loading && (
        <div className={classes.root}>
          {category?.slice(0, 2)?.map((item) => (
            <CustomChip
              key={!!item?.value ? item?.value : item?.label}
              label={!!item?.label ? item?.label : "N/A"}
            />
          ))}
          <div>
            {category?.length > 2 && (
            <PopperListRenderer
              popperList={[...category.slice(2, category?.length)]?.map(
                (entity) => entity?.label
              )}
              count={category?.length - 2}
            />
          )}
          </div>
        </div>
      )}
    </>
  );
}
