import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { SimpleSelect } from "../inputs";
import { errorMessage } from "../../utils/errorMessages";
import { SwitchComponent } from "../switch";

class SwitchBoxWithDropDown extends Component {
  constructor(props) {
    super();
    this.state = {};
  }


  checkDataType = (value) =>{
      
    if(value?.constructor === Array){
        return !Boolean(value.length > 0) 
    }
    else if(value?.constructor === Object){
        return !Boolean(Object.keys(value).length > 0)
    }
    else {
        return !Boolean(value?.length > 0)
    }
  }

  render() {
    const {
      label,
      id,
      isChecked,
      // componentKey,
      dropdown1Label,
      // dropdown2Label,
      dropdown1Options,
      // dropdown2Options,
      dropdown1Value,
      // dropdown2Value,
      dropdown1Key,
      // dropdown2Key,
      onChange,
      error,
      tempeleteTypeId,
      onChangeCallBackDropDown,
      onChangeCallBack,
      isMulti
    } = this.props;
    
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={isChecked ? 4 : 12}>
          <SwitchComponent
            checked={isChecked}
            // id={componentKey}
            id={id}
            handleOnChange={(checked) => {
                
              onChange(checked.target.checked, 'isChecked', tempeleteTypeId, onChangeCallBack);
            }}
            label={label}
            fixMargin
            hideMarginTop={this.props.hideMarginTop}
          />
        </Grid>
        {isChecked && (
          <>
            <Grid item xs={12} sm={6}>
              <SimpleSelect
                label={dropdown1Label}
                // isMulti={true}
                id={dropdown1Key}
                options={dropdown1Options}
                value={dropdown1Value}
                handleOnChange={(e) => {
                  onChange(e, 'dropdown1Value',tempeleteTypeId, onChangeCallBackDropDown);
                }}
                isMulti={isMulti}
                error={error ? this.checkDataType(dropdown1Value) ? true: false : false}
                errorText={errorMessage?.requiredMessage}
              />
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <SimpleSelect
                label={dropdown2Label}
                // isMulti={true}
                id={dropdown2Key}
                options={dropdown2Options}
                value={dropdown2Value}
                handleOnChange={(e) => {
                  onChange(e, 'dropdown2Value', tempeleteTypeId, onChangeCallBackDropDown);
                }}
                error={error ? this.checkDataType(dropdown2Value) ? true: false : false}
                errorText={errorMessage?.requiredMessage}
              />
            </Grid> */}
          </>
        )}
      </Grid>
    );
  }
}

SwitchBoxWithDropDown.defaultProps = {
  label: "Label",
  isChecked: false,
  dropdown1Label: "DropDown1",
  dropdown2Label: "DropDown2",
  dropdown1Options: [],
  dropdown2Options: [],
  dropdown1Value: [],
  dropdown2Value: [],
  onChange: () => {},
  error: {},
};
SwitchBoxWithDropDown.propTypes = {
  switchLabel: PropTypes.string,
  isChecked: PropTypes.bool,
  dropdown1Label: PropTypes.string,
  dropdown2Label: PropTypes.string,
  dropdown1Options: PropTypes.array,
  dropdown2Options: PropTypes.array,
  dropdown1Value: PropTypes.object,
  dropdown2Value: PropTypes.object,
  onChange: PropTypes.func,
  error: PropTypes.object,
};

export default SwitchBoxWithDropDown;
