import React from "react";
import { IconButton, Grid, Typography, Tooltip } from "@material-ui/core";
import { routes } from "../../../../router/routes";
import { withRouter } from "react-router-dom";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import { alertProps, scrollToTop } from "../../../../utils";
import AddEditUserManagement from "../../../../components/addEditForm";
import { formComponentTypes } from "../../../../utils/index";
import { allPersonaIds, getPersonaId } from "../../../resourceManagement/resources/utils";
import { DeleteOutlined } from "@material-ui/icons";
import moment from "moment";

const subscriptionTypeOptions = [
    {
        label: "Product",
        value: allPersonaIds.product
    },
    {
        label: "Package",
        value: allPersonaIds.package
    },
    {
        label: "Service",
        value: allPersonaIds.service
    }
]


class AddEditSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            selectType: {}
        };
    }

    componentDidMount() {
        this.categories();
        if(!this.props?.isEnquiry)scrollToTop();
        if (this.props.isEdit)
            this.setEssentialsForEdit();
    }

    setEssentialsForEdit = () => {
        const { editData } = this.props;

        if (editData?.category)
            this.careProviderByCategory(
                editData?.category
            )

        let selectedType = subscriptionTypeOptions.filter(_ => _.label === editData?.subscriptionType)?.[0];
        if (selectedType.value) {
            this.careSelectedItem(selectedType, {
                select_category: editData?.category,
                select_provider: editData?.provider
            })
        }

        if (editData?.item)
            this.onItemSelected(editData?.item)
    }

    categories = () => {
        // if (!this.props?.categories?.data?.length) {
            this.props.GET_CATEGORIES({force: false});
        // }
    }

    careProviderByCategory = (categories) => {
        this.props.GET_PROVIDER_LIST_BASEDON_CATEGORY({ categories: Array.isArray(categories) ? categories.map(_ => _.value) : [categories?.value] })
    }

    careSelectedItem = (selecType, allValue) => {

        this.setState({ selectType :  selecType})
        this.props.PRDT_SER_PGK_MAPPING_LIST(
            {
                type: selecType?.value,
                categories: Array.isArray(allValue.select_category) ? allValue.select_category.map(_ => _.value) : [allValue.select_category?.value],
                providers: Array.isArray(allValue.select_provider) ? allValue.select_provider.map(_ => _.value) : [allValue.select_provider?.value],
            })
    }

    onItemSelected = (selectedType, allValue) => {

        this.setState({
            total: parseFloat(selectedType?.amount ?? 0).toFixed(2),
        })

        this.getRecommendedCoordinator(selectedType, allValue);
    }

    getRecommendedCoordinator = (selectType, allValue) => {
        let getIdNameType = getPersonaId(routes.manage_care_coordinators);

        let partnerId = selectType.providerInfo?.value;

        this.props.GET_COORD_BY_PARTNER({
            resourceTypeId: getIdNameType.id,
            partnerId
        })
    }

    shouldComponentUpdate(nextProps, nextState) {

        //Map Subscription
        const { add_client_subscription_mapping } = nextProps;
        const old_add_client_subscription_mapping = this.props.add_client_subscription_mapping;

        //Checking for Subscription map
        if (old_add_client_subscription_mapping?.loading !== add_client_subscription_mapping?.loading) {
            if (!add_client_subscription_mapping.loading && !add_client_subscription_mapping.error) {

                const {
                    isEdit
                } = this.props;

                this.props.alert.setSnack({
                    open: true,
                    horizontal: alertProps.horizontal.center,
                    vertical: alertProps.vertical.top,
                    msg: `Subscription ${isEdit ? "Updated" : "Mapped"} successfully!`,
                    severity: alertProps.severity.success,
                });
                this.props.getClientDetail(true);
            } else if (add_client_subscription_mapping.error) {
                this.props.alert.setSnack({
                    open: true,
                    horizontal: alertProps.horizontal.center,
                    vertical: alertProps.vertical.top,
                    msg: "Something went wrong! Unable to Map Subscription",
                    severity: alertProps.severity.error,
                });
            }
        }

        //Deactivate/Activate Subscription
        const { status_update } = nextProps;
        const old_status_update = this.props.status_update;

        //Checking for Deactivate/Activate subscription
        if (old_status_update?.loading !== status_update?.loading) {
            if (!status_update.loading && !status_update.error) {
                this.props.alert.setSnack({
                    open: true,
                    horizontal: alertProps.horizontal.center,
                    vertical: alertProps.vertical.top,
                    msg: "Subscription Deleted successfully!",
                    severity: alertProps.severity.success,
                });
                this.props.getClientDetail(true);
            } else if (status_update.error) {
                this.props.alert.setSnack({
                    open: true,
                    horizontal: alertProps.horizontal.center,
                    vertical: alertProps.vertical.top,
                    msg: "Something went wrong! Unable to update subscription status",
                    severity: alertProps.severity.error,
                });
            }
        }

        return true;
    }

    onDeleteSubscriptionClicked = () => {
        this.props.dialog.setDialog({
            open: true,
            title: "Are you sure?",
            body: `Do you want to delete Subscription: ${this.props?.editData?.subscriptionName}`,
            positiveBtn: "Delete",
            negativeBtn: "Cancel",
            onOk: () => this.deleteSubscription(),
        });
    }

    deleteSubscription = () => {
        this.props.dialog.setDialog({ open: false });

        const {
            editData
        } = this.props;

        this.props.UPDATE_SUBSCRIPTION_STATUS({
            subscriptionId: editData?.id,
            status: editData.active,
            isActive: false,
            clientEmail: this.props.careGiverClientInfo?.data[0].email,
            clientSalutation: this.props.careGiverClientInfo?.data[0]?.salutationName,
            subscriptionType: editData?.subscriptionType,
            subscriptionName: editData?.subscriptionName,
            clientName: this.props.careGiverClientInfo?.data[0].name,
        })
    }
    cancelForm = () => {
        this.props.drawer.setDrawer({
            ...this.props.drawer,
            open: false,
        })
        if (this.props.cancelForm) {
            this.props.cancelForm()
        }
        else{
            this.props.drawer.setDrawer({
                ...this.props.drawer,
                open: false,
                component: <></>,
              });
        }
    }
    onSaveButtonClicked = (isError, data) => {
        if (isError) {
            this.props.alert.setSnack({
                open: true,
                horizontal: alertProps.horizontal.center,
                vertical: alertProps.vertical.top,
                msg: "Please, fill the mandatory fields",
                severity: alertProps.severity.warning,
            });
        } else {
            const id = this.props?.clientId ?? this.props?.match?.params?.id;

            const itemCategory = data?.select_an_item?.resourceMeta?.resourceMeta?.selectedCategory

            const {
                isEdit,
                editData
            } = this.props;

            const payload = {
                clientId: id,
                // category: data.select_category?.map(_ => _.value),
                category: itemCategory,
                provider: data.select_provider.value,
                subscriptionType: data.select_type,
                resourceId: data.select_an_item.value,
                resourceType: data.select_an_item.resourceType,
                resourceVersionId: data.select_an_item.versionId,
                resourceMeta: data.select_an_item.resourceMeta,
                item: data.select_an_item,
                coordinator: data.select_coordinator.value,
                expired_on:moment(new Date()).add(90, "days").toDate()?.toString(),
                total: parseFloat(this.state.total),
                subscriptionStatus: false,
                subscriptionId: isEdit ? editData?.id : null,
                isActive: true,
                clientEmail: this.props.careGiverClientInfo?.data[0]?.email,
                clientName: this.props.careGiverClientInfo?.data[0]?.name
            };

            console.log(data?.resourceMeta?.resourceMeta)
            console.log(moment(new Date()).add(90, "days").toDate()?.toString())
            console.log(payload)

            this.props.ADD_CLIENT_SUB_MAPPING({ ...payload });
        }
    };

    categoryOptionsFromLoginAuth = () => {

        // if (this.props?.auth_data?.data?.user_role === user_roles.super_admin) {
            return this.props?.categories?.data ?? [];
        // }

        // if (this.props?.auth_data?.data?.category?.length > 0) {
        //     return this.props?.auth_data?.data?.category ?? [];
        // } else {
        //     return [];
        // }
    }

    onTotalChange = (e) => {
        this.setState({
            total: e?? 0
        })
    }

    render() {

        const {
            loading: mapLoading,
        } = this.props?.add_client_subscription_mapping;

        const {
            isEdit,
            editData
        } = this.props;
        const components = {
            section_1: {
                label: "",
                fields: {
                    select_category: {
                        key: "select_category",
                        id:`addCategoryFrom${this?.props?.id}`,
                        type: formComponentTypes.SELECT_BOX,
                        breakPoints: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                        label: "SELECT CATEGORY",
                        value: isEdit ? editData?.category : "",
                        onSelectedCallback: this.careProviderByCategory,
                        options: this.categoryOptionsFromLoginAuth(),
                        isLoading: this.props?.categories?.loading,
                        isMandatory: true,
                        cleanUpKeys: ['select_provider', 'select_type', 'select_an_item'],
                        isMulti: true
                    },
                    select_provider: {
                        key: "select_provider",
                        id:`addProviderFrom${this?.props?.id}`,
                        type: formComponentTypes.SELECT_BOX,
                        breakPoints: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                        label: "SELECT PARTNER",
                        value: isEdit ? editData?.provider : "",
                        options: this.props?.providerBasedOnCategory?.data,
                        isLoading: this.props?.providerBasedOnCategory?.loading,
                        isMandatory: true,
                        cleanUpKeys: ['select_type', 'select_an_item'],
                    },
                    select_type: {
                        key: "select_type",
                        id:`addTypeFrom${this?.props?.id}`,
                        type: formComponentTypes.SELECT_BOX,
                        breakPoints: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                        label: "SELECT TYPE",
                        value: isEdit ? subscriptionTypeOptions.filter(_ => _.label === editData?.subscriptionType)?.[0] : "",
                        options: subscriptionTypeOptions,
                        onSelectedCallback: this.careSelectedItem,
                        cleanUpKeys: ['select_an_item']
                    },
                    select_an_item: {
                        key: "select_an_item",
                        id:`addItemFrom${this?.props?.id}`,
                        type: formComponentTypes.SELECT_BOX,
                        breakPoints: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                        label: "SELECT AN ITEM",
                        value: isEdit ? editData?.item : "",
                        options: this.props?.subscription_mapping?.data?.filter(_ => this.state.selectType?.label === "Service" ? _.label === "Services" : this.state.selectType?.label === "Package" ? _.label === "Packages" :  _.label === "Products"),
                        isLoading: this.props?.subscription_mapping?.loading,
                        onSelectedCallback: this.onItemSelected,
                        cleanUpKeys: ['recommendations_card', 'select_caregiver', 'select_coordinator'],
                    },
                    summary_card: {
                        key: "summary_card",
                        id:`addCardFilterFrom${this?.props?.id}`,
                        type: formComponentTypes.SUMMARY_CARD,
                        breakPoints: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                        label: "SUMMARY CARD",
                        value: "select_an_item",
                        withTextAvatar: true,
                        isSmallScreen: true,
                        total: this.state.total,
                        onTotalChange: this.onTotalChange
                    },
                    select_coordinator: {
                        key: "select_coordinator",
                        id:`addCoordinatorFrom${this?.props?.id}`,
                        type: formComponentTypes.SELECT_BOX,
                        breakPoints: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                        label: "SELECT SERVICE COORDINATOR",
                        value: isEdit ? editData?.careCoordiantor : "",
                        options: this.props?.recommended_care_coord?.data,
                        isLoading: this.props?.recommended_care_coord?.loading,
                        isMandatory: true,
                    },
                },
            },
        };

        return (
            <div>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography
                            variant="h6"
                            component="h6"
                            style={{
                                padding: 16,
                                fontSize: "1.2rem",
                                fontWeight: "bold",
                            }}
                        >
                            {`${isEdit ? "Update" : "Add"} Subscription`}
                        </Typography>
                    </Grid>

                    {isEdit && <Grid item>
                        <Tooltip title="Delete Subscription">
                            <IconButton onClick={this.onDeleteSubscriptionClicked}>
                                <DeleteOutlined color="error" />
                            </IconButton>
                        </Tooltip>
                    </Grid>}

                </Grid>

                <AddEditUserManagement
                    cancelForm={this.cancelForm}
                    components={components}
                    onSaveButtonClicked={this.onSaveButtonClicked}
                    loading={mapLoading}
                    saveButtonLabel={
                        mapLoading
                            ? `${isEdit ? "Updating" : "Adding"} Subscription...`
                            : `${isEdit ? "Update" : "Add"} Subscription`}
                    isResetState={this.state.isResetState}
                    saveButtonId={`AddSubscriptionAddFromListingEnquiry`}
                    cancelButtonId={`AddCancelButtonFromListingEnquiry`}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    careGiverClientInfo: state.careGiverClient?.careGiverClientInfo,
    client_status_update: state.persona_client?.client_status_update,
    categories: state?.masterSet?.categories,
    providerBasedOnCategory: state?.careProvider?.providerBasedOnCategory,
    subscription_mapping: state?.dependency_api?.subscription_mapping,
    addService: state?.serviceState?.addService,
    recommended_caregivers: state?.dependency_api?.recommended_caregivers,
    // recommended_care_coord: state?.dependency_api?.recommended_care_coord,
    add_client_subscription_mapping: state?.client_subscription_mapping?.add_client_subscription_mapping,
    status_update: state?.client_subscription_mapping?.status_update,
    auth_data: state?.authState?.loginAuth,
    recommended_care_coord:state?.careCoordinator?.partnerCoord
});

export default connect(
    mapStateToProps,
    actions
)(withRouter(withAlelrtAndDialog(AddEditSubscription)));
