import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: "flex",
  //   boxShadow: "unset",
  //   //border: "1px solid #ebebeb",
  //   borderRadius: 8,
  //   marginBottom: 4,
  // },
  // content: {
  //   flex: "1 0 auto",
  //   padding: 0,
  //   "&:last-child": {
  //     paddingBottom: 0,
  //   },
  // },
  root: {
    display: "flex",
    gap: 4,
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
}));

export function QuickListMobileView({ item, loading, ...props }) {
  const classes = useStyles(props);
  return (
    <>
      {!loading && (
        <div className={classes.root}>
          <Typography variant="body1" align={"center"}>
            {/* {item?.value < 10 ? `0${item?.value}` : item?.value} */}
            {item?.value}
          </Typography>
          <Typography variant="body2" color="textSecondary" align={"center"} noWrap>
            {item?.label}
          </Typography>
        </div>
      )}
    </>
  );
}
