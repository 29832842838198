import React from 'react';
import { makeStyles, Avatar, Typography, Grid } from '@material-ui/core';
import Icon from "../../assets/product Management/listingScreen/Schedule.svg";
import Icon2 from "../../assets/product Management/listingScreen/next.svg";


const useStyle = makeStyles((theme) => ({
    avatar: {
        height: 50,
        width: 50,
        padding: 16,
        backgroundColor: "rgba(255, 255, 255, 0.24)",
    },
    textArea: {
        marginLeft: 15,
        marginTop: 2,

    },
    text: {
        display: 'flex',
        color: "white",
    },

    activecircle: {
        marginLeft: 10,
        marginTop: 6,
        width: 9,
        height: 9,
        background: theme.palette.success.main,
        borderRadius: "50%"
    },

    active_name: {
        display: "inline",
        paddingLeft: "6px",
        fontSize: "14px",
        color: theme.palette.success.main
    },
    next:
    {
        height: 13,
        width: 100,
    },
    topcards:
    {
        backgroundColor: "#353596",
        boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04)`,
        padding: 16,
        borderRadius: 8,
    },

}))

export const TopLinkCard = (props) => {

    const classes = useStyle();
    return (
        <div className={classes.topcards}>
            <Grid container>
                <Grid item container xs>
                    <Grid item>
                        <Avatar src={Icon} className={classes.avatar}></Avatar>
                    </Grid>
                    <Grid item className={classes.textArea}>
                        <div>
                            <Typography variant="body1" className={classes.text} >{"Pre-Session Tips"}</Typography>
                        </div>
                        <div className={classes.text}>
                            <Typography variant="body2" className={classes.text}>{"Share tips for appointment"}</Typography>
                        </div>
                    </Grid>
                </Grid>
                <Grid item style={{ margin: 'auto' }}>
                    <img src={Icon2} alt={'icon'} className={classes.next}></img>
                </Grid>
            </Grid>
        </div>
    )
}