import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
// import { Typography, InputLabel } from "@material-ui/core";
import AddEditProductManagement from "../addEditForm";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 350,
  },
  fullList: {
    width: "auto",
  },
  add: {
    color: theme.palette.ternary.main,
  },
}));

export const DrawerComponent = (props) => {
  const classes = useStyles();

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* <DialogTitle disableTypography className="drawerTitle">
        <IconButton >
          <CloseIcon />
        </IconButton>
      </DialogTitle> */}
      <AddEditProductManagement onSaveButtonClicked={props.callback} components={props.component.components} open={props.open} toggleDrawer={props.toggleDrawer}/>
    </div>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer
          anchor={"right"}
          open={props.open}
          onClose={() => {
            props.toggleDrawer(false, {});
          }}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
      {/* <InputLabel shrink className={classes.label}>
        Contact Not Found?
      </InputLabel>
      <Typography
        variant="body1"
        component="div"
        style={{ cursor: "pointer" }}
        className={classes.add}
        // onClick={props.toggleDrawer("right", true)}
      >
        {"+ Add Contact".toUpperCase()}
      </Typography> */}
    </div>
  );
};
