import * as React from "react"

export const BoldIcon = (props) => {

    const {
        width = "17.273",
        height = "20",
        color = "#6f6f6f"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 17.273 20"
        >
            <path id="icons8-bold_1_" data-name="icons8-bold (1)" d="M6.909,4a.909.909,0,1,0,0,1.818h.909V22.182H6.909a.909.909,0,1,0,0,1.818H17.364a5.905,5.905,0,0,0,2.431-11.289A4.993,4.993,0,0,0,16.455,4Zm5.455,1.818h2.273a3.182,3.182,0,1,1,0,6.364H12.364Zm0,8.182h2.273a4.091,4.091,0,0,1,0,8.182H12.364Z" transform="translate(-6 -4)" 
                fill={color}
            />
        </svg>
    );
};

