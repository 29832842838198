import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { IssueCardLoading } from './loading';

export const IssueCardLoaders = (props) => {


  const {
    count
  } = props;


  return <>
    {
      Array.apply(null, Array(count)).map((value, index) => <Grid key={index} item xs={12}>
        <IssueCardLoading />
      </Grid>)
    }
  </>
}

IssueCardLoaders.propTypes = {
  count: PropTypes.number
}

IssueCardLoaders.defaultProps = {
  count: 1
}