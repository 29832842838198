import React from "react";
import AddEditUserManagement from "../../../../components/addEditForm";
import {
  alertProps,
  base64MimeType,
  bloodGroupOptions,
  bmiCalculation as OtherBmiCalc,
  formComponentTypes,
  // getCountryCodeOptions,
  upload_file,
  // user_roles
} from "../../../../utils/index";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routes } from "../../../../router/routes";
import {
  calcAge,
  InternatlServerError,
  LoadingSection,
} from "../../../../components";
import moment from "moment";
import withAlelrtAndDialog from "../../../../HOCs/withAlelrtAndDialog";
import AddressComponent from "../../../../components/SelectComponentWithSubComponent/addressComponent";
import IdTypeComponent from "../../../../components/SelectComponentWithSubComponent/idTypeComponent";
import EmailComponent from "../../../../components/SelectComponentWithSubComponent/emailComponent";
import PhoneComponent from "../../../../components/SelectComponentWithSubComponent/phoneComponent";
import { v4 as uuidv4 } from "uuid";
import { errorMessage } from "../../../../utils/errorMessages";
import { FamilyMembersAddForm } from "../../../../components";

const familyMemberInitialState = {
  // Single Member State (Current State)
  member: {},

  // Family member ID
  memberIdType: [],
  // Family member phone
  memberPhone: [],
  // Family member Address
  memberAddress: [],
};
class AddEditClients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bmi: null,
      height: null,
      weight: null,
      role: 0,
      error: {},
      address: [],
      showAddress: false,
      idType: [],
      email: [],
      showEmail: false,
      phone: [],
      showPhone: false,
      familyMembers: [],
      clientState: {},
      isResetState: false,
      ...familyMemberInitialState,
    };
  }

  componentDidMount() {
    this.setState({ role: this.props.auth_data?.data?.user_role });
    // loginState?.data?.user_role
    this.initializeClientState();
    this.getEssentials();
  }

  mapperConverAsClientMapper = (data) => {
    if (data?.error || data?.status === 500) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Something went wrong! Unable to convert as client",
        severity: alertProps.severity.error,
      });
      this.props.history.push(routes.enquiry_list);
    } else {
      if (Object.keys(data).length > 0) {
        return {
          occupation: null,
          gender: data?.contact_information?.gender
            ? data?.contact_information?.gender?.[0].toUpperCase() +
              data?.contact_information?.gender?.slice(
                1,
                data?.contact_information?.gender?.length
              )
            : data?.caller_information?.gender?.[0].toUpperCase() +
              data?.caller_information?.gender?.slice(
                1,
                data?.caller_information?.gender?.length
              ),
          status_type: null,
          mobile_no: data?.contact_information?.mobNo
            ? data?.contact_information?.mobNo?.split(" ")?.[1]
            : data?.caller_information?.mobNo?.split(" ")?.[1],

          mobile_no_isd_code: data?.contact_information?.mobNo
            ? data?.contact_information?.mobNo?.split(" ")?.[0]
            : data?.caller_information?.mobNo?.split(" ")?.[0],
          health_information: {
            weight: data?.weight,
            blood_pressure: null,
            height: data?.height,
            bmi: OtherBmiCalc(data?.height, data?.weight),
          },
          languages: data?.languages ?? [],
          email: data?.contact_information?.email
            ? data?.contact_information?.email
            : data?.caller_information?.email,
          medical_history: data?.medical_history,
          chief_complaint_current_complaint:
            data?.chief_complaint_current_complaint,
          ic_number: null,
          // address: data?.contact_information?.address
          //   ? data?.contact_information?.address
          //   : data?.caller_information?.address,
          addressLine1:
            data?.contact_information?.addressLine1 ??
            data?.caller_information?.addressLine1,
          addressLine2:
            data?.contact_information?.addressLine2 ??
            data?.caller_information?.addressLine2,
          area:
            data?.contact_information?.area ?? data?.caller_information?.area,
          state:
            data?.contact_information?.state ?? data?.caller_information?.state,
          country:
            data?.contact_information?.country ??
            data?.caller_information?.country,
          postalCode:
            data?.contact_information?.postalCode ??
            data?.caller_information?.postalCode,
          profile_pic: null,
          dob: data?.contact_information?.date_of_birth
            ? moment(new Date(data?.contact_information?.date_of_birth))
                .format("YYYY-MM-DD")
                .toString()
            : moment(new Date(data?.caller_information?.date_of_birth))
                .format("YYYY-MM-DD")
                .toString(),
          passport_number: null,
          name: data?.contact_information?.name
            ? data?.contact_information?.name
            : data?.caller_information?.name,
          salutation: data?.contact_information?.salutation
            ? data?.contact_information?.salutation
            : data?.caller_information?.salutation,
          special_precaution: null,
          age: calcAge(
            data?.contact_information?.date_of_birth
              ? new Date(data?.contact_information?.date_of_birth)
              : new Date(data?.caller_information?.date_of_birth)
          ),
          emergency_contact: {
            contact_name:
              data?.contact_information !== null
                ? data?.caller_information?.name
                : "",
            mobile_no_isd_code:
              data?.caller_information !== null
                ? data?.caller_information?.mobNo?.split(" ")?.[0]
                : "",

            mobile_no:
              data?.contact_information !== null
                ? data?.caller_information?.mobNo?.split(" ")[1]
                : "",
            relationship:
              data?.contact_information !== null && data?.relationship
                ? data?.relationship
                : "",
            email:
              data?.contact_information !== null
                ? data?.caller_information?.email
                : "",
          },
        };
      } else return {};
    }
  };

  mapClientState = (data) => {
    // Salutation + name are same keys,
    // dob
    return {
      ...data,
      gender: {
        value: data?.gender,
        label: data?.gender,
      },
      emailid: data?.email,
      mobilenumberISDcode: this.getFormattedISDCode(data?.mobile_no_isd_code),
      mobilenumber: data?.mobile_no,
      languages: data?.languages ? (data?.languages && typeof data?.languages == "string") ? JSON.parse(data?.languages) : [] : "[]",
      resourceEffectiveFrom: data?.resourceEffectiveFrom ?? null,
      resourceEndsOn: data?.resourceEndsOn ?? null,
      height: data?.health_information?.height,
      weight: data?.health_information?.weight,
      bloodGroup: {
        value: data?.bloodGroup,
        label: data?.bloodGroup,
      },
      chief_complaint_current_complaint:
        data?.chief_complaint_current_complaint,
      medical_history: data?.medical_history,
      precaution: data?.special_precaution ?? [],
    };
  };

  initializeClientState = () => {
    const {
      data: clientData,
      // loading: clientLoading,
      // error: clientError,
    } = this.props?.careGiverClientInfo;

    let {
      data: enquiryData,
      // loading: enquiryLoading,
      // error: enquiryError,
    } = this.props?.enquiryData;

    const preFillData =
      this.props.isEdit || this.props?.location?.state?.isConvertAsClient
        ? true
        : false;

    let data = this.props?.location?.state?.isConvertAsClient
      ? this.mapperConverAsClientMapper(enquiryData)
      : clientData[0];

    if (preFillData) {
      this.setState({
        clientState: {
          ...this.mapClientState(data),
        },
      });
    }
  };

  getEssentials = async () => {
    // this.props.GET_CATEGORIES({searchKey: "", sortKey: 1});
    this.props.GET_PARTNER_DROPDOWN({});
    this.props.GET_SAL({});
    this.props.GET_LANG({});
    this.props.MASTER_PHONE_CODE({});

    if (this.props.isEdit) {
      const id = this.props?.match?.params?.id;

      this.props.GET_CARE_GIVER_CLIENT_INFO({ id });
    } else if (this.props?.location?.state?.isConvertAsClient) {
      this.getConverAsClientData();
    }
  };

  getConverAsClientData = () => {
    const id = this.props?.location?.state?.isConvertAsClient;
    this.props.GET_ENQUIRY_INFO({ enquiry_id: id });
  };

  careProviderByCategory = () => {};

  servicesByCareProvider = () => {};

  shouldComponentUpdate(nextProps, nextState) {
    //Add Package
    const { upsert_client, auth_data } = nextProps;
    const oldupsert_client = this.props.upsert_client;
    const oldauth_data = this.props.auth_data;

    if (oldauth_data.data !== auth_data.data) {
      this.setState({ role: this.props.auth_data?.data?.user_id });
    }

    //Checking for Add Package messages
    if (oldupsert_client?.loading !== upsert_client?.loading) {
      if (!upsert_client.loading && !upsert_client.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg: this.props.isEdit
            ? "Client Updated Successfully!"
            : "Client Added successfully!",
          severity: alertProps.severity.success,
        });

        if (this.props.isEdit) {
          const id = this.props?.match?.params?.id;

          this.props.history.push(routes.manage_clients + "/view/" + id);
        } else {
          this.props.history.push(routes.manage_clients);
        }
      } else if (upsert_client.error) {
        this.props.alert.setSnack({
          open: true,
          horizontal: alertProps.horizontal.center,
          vertical: alertProps.vertical.top,
          msg:
            upsert_client.message === "Request failed with status code 409"
              ? "Email ID already exits!"
              : upsert_client.message ??
                "Something went wrong! Unable to Add client.",
          severity:
            upsert_client.message === "Request failed with status code 409"
              ? alertProps.severity.warning
              : alertProps.severity.error,
        });
      }
    }

    //Get enquiry detail
    const { enquiryData } = nextProps;
    const oldEnquiryData = this.props.enquiryData;

    if (oldEnquiryData?.loading !== enquiryData?.loading) {
      if (!enquiryData.loading && !enquiryData.error) {
        this.setState({
          height: enquiryData?.data?.height,
          weight: enquiryData?.data?.weight,
          bmi: OtherBmiCalc(
            enquiryData?.data?.height,
            enquiryData?.data?.weight
          ),
        });
      } else if (enquiryData.error) {
      }
    }

    //Client Detail
    const { careGiverClientInfo } = nextProps;
    const oldCareGiverClientInfo = this.props.careGiverClientInfo;

    if (oldCareGiverClientInfo?.loading !== careGiverClientInfo.loading) {
      if (!careGiverClientInfo.loading && !careGiverClientInfo.error) {
        this.setState({
          height: careGiverClientInfo?.data?.[0]?.health_information?.height,
          weight: careGiverClientInfo?.data?.[0]?.health_information?.weight,
          bmi: OtherBmiCalc(
            careGiverClientInfo?.data?.[0]?.health_information?.height,
            careGiverClientInfo?.data?.[0]?.health_information?.weight
          ),
          address: careGiverClientInfo?.data?.[0]?.otherAddress,
          idType: careGiverClientInfo?.data?.[0]?.idTypes,
          phone: careGiverClientInfo?.data?.[0]?.otherPhoneNumbers,
          email: careGiverClientInfo?.data?.[0]?.otherEmailIds,
          familyMembers: careGiverClientInfo?.data?.[0]?.familyMembers,
        });
      }
    }

    return true;
  }

  handleAddressValidation = () => {
    const { error } = this.state;
    if (this.state.address?.length === 0 || this.state.address === null) {
      error["add_address"] = true;
      this.setState({ error });
      return true
    } else if (this.state.address?.length > 0) {
      error["add_address"] = false;
      this.setState({ error });
      return false
    }
  };

  handleIdTypeValidation = () => {
    const { error } = this.state;
    if (this.state.idType?.length === 0 || this.state.idType === null) {
      error["idType"] = true;
      this.setState({ error });
      return true;
    } else if (this.state.idType?.length > 0) {
      error["idType"] = false;
      this.setState({ error });
      return false;
    }
  };

  onSaveButtonClicked = async (isError, data, isUrl) => {
    if (this.handleAddressValidation() || this.handleIdTypeValidation() || isError) {
      this.props.alert.setSnack({
        open: true,
        horizontal: alertProps.horizontal.center,
        vertical: alertProps.vertical.top,
        msg: "Please, fill the mandatory fields",
        severity: alertProps.severity.warning,
      });
      return false
    }
    // else if (isError) {
    //   this.props.alert.setSnack({
    //     open: true,
    //     horizontal: alertProps.horizontal.center,
    //     vertical: alertProps.vertical.top,
    //     msg: "Please, fill the mandatory fields",
    //     severity: alertProps.severity.warning,
    //   });
    //   return false
    // }

    else {
      const { data: clientData } = this.props?.careGiverClientInfo;

      
      console.log(data)

      const getFlagValue = () => {

        // moment(data?.resourceEffectiveFrom)?.isSame(clientData?.[0]?.resourceEffectiveFrom) && moment(data?.resourceEndsOn)?.isSame(clientData?.[0]?.resourceEndsOn) ? false : true
        const editedEffective = data?.resourceEffectiveFrom
        const dbEffective = clientData?.[0]?.resourceEffectiveFrom

        const editedEndsOn = data?.resourceEndsOn
        const dbEndsOn = clientData?.[0]?.resourceEndsOn

        if (!moment(editedEffective)?.isSame(dbEffective)) {
          return true;
        } else if (editedEndsOn && dbEndsOn) {
          if (!moment(editedEndsOn)?.isSame(dbEndsOn)) {
            return true;
          }
        } else if ((!editedEndsOn && dbEndsOn) || (editedEndsOn && !dbEndsOn)) {
          return true;
        }

        return false
      };

      let payload = {
        ...data,
        bmi: this.state.bmi,
        otherAddress: this.state.address,
        otherPhoneNumbers: this.state.phone,
        otherEmailIds: this.state.email,
        familyMembers: this.state.familyMembers?.map((x) => ({...x, gender: x?.gender?.value})),
        provider: data?.provider?.value,
        languages: data?.languages ? JSON.stringify(data?.languages) : "[]",
        category:
          data?.category?.length > 0
            ? data?.category.map((val) => {
                return val.value;
              })
            : [],
        ic: data?.ic_passport?.ic_number ? data?.ic_passport?.ic_number : "",
        passport: data?.ic_passport?.passport
          ? data?.ic_passport?.passport
          : "",
        dob: moment(data.dob, "YYYY-MM-DD").format("YYYY/MM/DD").toString(),
        age: moment(new Date()).diff(data.dob, "years"),
        gender: data?.gender?.value,
        mobilenumberISDcode: data?.mobilenumberISDcode,
        relativeMobileISDcode: data?.relativeMobileISDcode,
        relationship: data?.relationship?.value,
        id: this.props?.match?.params?.id ?? null,
        clientSource:
          clientData?.[0] &&
          clientData?.[0]?.isOnboarded !== null &&
          clientData?.[0]?.isOnboarded === false
            ? "enq"
            : "",
        resourceEffectiveFrom: data?.resourceEffectiveFrom,
        resourceEndsOn: data?.resourceEndsOn,
        isEffectiveFromToValidation: getFlagValue()
        // enquiryData && Object.keys(enquiryData)?.length > 0 ? (enquiryData?.isOnboarded  ? this.props?.match?.params?.id : null) : this.props?.match?.params?.id
      };

      // UPLOADING IMAGE BEFORE SAVING THE DATA
      if (data?.uploadPhoto) {
        if (base64MimeType(data?.uploadPhoto)?.length > 0) {
          let { fileName, withoutType } = upload_file(data?.uploadPhoto);
          await this.props
            .FILE_UPLOAD({
              file: withoutType,
              fileName: fileName,
              isbase64: true,
            })
            .then(async (val) => {
              payload["uploadPhoto"] = await val?.payload?.data?.fileid;
            });
        } else {
          payload["uploadPhoto"] = data?.uploadPhoto?.split("/")[
            data?.uploadPhoto?.split("/").length - 1
          ];
        }
      }

      // Uploading ID images before saving the data
      let { idType } = this.state;

      // Need to upload photos from array of id_types && set fileId to id_photo
      const uploadIdImages = async (payloadNew) => {
        if (idType?.length > 0) {
          for (const [index, element] of idType?.entries()) {
            const photo = element?.id_photo;

            // If id_photo has a fileId already, then break.
            if (parseInt(element?.id_photo)) {
              continue;
            }

            // ID UPLOAD
            if (photo) {
              if (photo?.constructor === File && photo?.name) {
                let _filename = photo.name.trim();
                let error = false;
                await this.props
                  .FILE_UPLOAD({
                    file: photo,
                    fileName: _filename,
                    // Audit Log Payload
                    logFile: "userManagement/clients/addEdit/index.js",
                    // logMessage: auditGetMessage(
                    //   this.props.auth_data?.data?.email,
                    //   "FILE_UPLOAD",
                    //   "Client Add/Edit"
                    // ),
                  })
                  .then(async (val) => {
                    let _val = await val;
                    if (_val?.payload?.data?.fileid) {
                      idType[index]["id_photo"] =
                        _val?.payload?.data?.fileid ?? "";
                      idType[index]["id_photo_filetype"] =
                        _val?.payload?.data?.filetype ?? "";
                      idType[index]["id_photo_size"] = photo?.size ?? "";
                    } else {
                      console.log(val, "Id image upload error");
                      this.props.alert.setSnack({
                        open: true,
                        horizontal: alertProps.horizontal.center,
                        vertical: alertProps.vertical.top,
                        msg: `File Id not received after uploading ${element?.label} ID type. Try again!`,
                        severity: alertProps.severity.error,
                      });
                    }
                  })
                  .catch((val) => {
                    console.log(val, "Id image upload error");
                    this.props.alert.setSnack({
                      open: true,
                      horizontal: alertProps.horizontal.center,
                      vertical: alertProps.vertical.top,
                      msg: `Couldn't upload ${element?.label} ID type. Try again!`,
                      severity: alertProps.severity.error,
                    });
                    error = true;
                  });

                if (error) break;
              }
            }
          }
        }
      };

      await uploadIdImages();

      this.setState(
        {
          idType: idType,
        },
        () => {
          payload["idTypes"] = this.state.idType;
        }
      );

      debugger;
      this.props.UPSERT_CLIENT({ ...payload });
    }
  };

  bmiCalculation = (data, key) => {
    let BMI;
    const { weight, height } = this.state;
    if (key === "height") {
      if (this.state.weight && data) {
        BMI = Math.round((weight / Math.pow(data, 2)) * 10000);
      }
    } else {
      if (this.state.height && data) {
        BMI = Math.round((data / Math.pow(height, 2)) * 10000);
      }
    }

    this.setState({ bmi: BMI, [key]: data }, () => {
      this.updateClientState(data, key)
    });
  };

  getFormattedISDCode = (isdCode) => {
    let formattedISDCode =
      typeof isdCode === typeof "" ? isdCode : isdCode?.toString();
    return formattedISDCode?.[0] === "+"
      ? formattedISDCode
      : `+${formattedISDCode}`;
  };

  // Toggle isResetState
  toggleResetState = () => {
    this.setState((prevState) => ({
      isResetState: { ...this.state.clientState },
    }));
  };

  // Multiple Address, Email, phone handlers
  // Address
  callBackOnSaveAddress = (data, isremove) => {
    const { error } = this.state;
    if (isremove) {
      let isUpdateData = this.state.address?.filter(
        (val) => val.value !== data?.value
      );

      this.setState({ address: isUpdateData }, () => {
        console.log("addressaddress", this.state.address);
      });
    } else {
      this.props.drawer.setDrawer({
        component: <></>,
        open: false,
      });

      error["add_address"] = false;
      this.setState({ error });
      if (data) {
        if (this.state.address?.length > 0) {
          let isUpdateData = this.state.address?.findIndex(
            (val) => val.id === data?.id
          );
          if (isUpdateData > -1) {
            let _newData = {
              ...data,
              label: data.address_type.label,
              value: data.address_type.value,
              id: uuidv4(),
            };
            let { address } = this.state;
            let newArray = [...address];
            newArray[isUpdateData] = _newData;
            address = newArray;
            this.setState({ address }, () => {
              console.log("addressaddress", this.state.address);
            });
          } else {
            let _newData = {
              ...data,
              label: data.address_type.label,
              value: data.address_type.value,
              id: uuidv4(),
            };
            this.setState(
              { address: [...this.state.address, _newData] },
              () => {
                console.log("addressaddress", this.state.address);
              }
            );
          }
        } else {
          let _newData = {
            ...data,
            label: data.address_type.label,
            value: data.address_type.value,
            id: uuidv4(),
          };
          this.setState({ address: [...this.state.address, _newData] }, () => {
            console.log("addressaddress", this.state.address);
          });
        }
      }
    }
  };

  onAddEditDrawerAddress = (name, e, list) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <AddressComponent
          id="AddEditClient"
          type="address"
          list={list}
          callBackOnSave={this.callBackOnSaveAddress}
        />
      ),
      open: true,
      isMedium: false,
    });
  };

  viewDetailsAddressEditDetails = (isEdit, editData, data, salutation) => {
    let _arrayOfJson = [];

    if (data?.length > 0) {
      _arrayOfJson = data;
    }
    return _arrayOfJson;
  };

  // Id Handlers
  callBackOnSaveId = (data, isremove) => {
    if (isremove) {
      let isUpdateData = this.state.idType?.filter(
        (val) => val.value !== data?.value
      );

      this.setState({ idType: isUpdateData }, () => {
        // console.log("addressaddress", this.state.idType);
      });
    } else {
      this.props.drawer.setDrawer({
        component: <></>,
        open: false,
      });
      if (data) {
        if (this.state.idType?.length > 0) {
          let isUpdateData = this.state.idType?.findIndex(
            (val) => val.id === data?.id
          );
          if (isUpdateData > -1) {
            let _newData = {
              ...data,
              id_type: data.id_type,
              label: data.id_type_name.label,
              value: data.id_type_name.value,
              id_photo: data.id_photo,
              file: data.id_photo,
              id: uuidv4(),
            };
            let { idType } = this.state;
            let newArray = [...idType];
            newArray[isUpdateData] = _newData;
            idType = newArray;
            this.setState({ idType }, () => {
              console.log("addressaddress", this.state.idType);
            });
          } else {
            // let {idType} = this.state
            let _newData = {
              ...data,
              id_type: data.id_type,
              label: data.id_type_name.label,
              value: data.id_type_name.value,
              id_photo: data.id_photo,
              file: data.id_photo,
              id: uuidv4(),
            };
            // this.state.idType.push(_newData)
            this.setState({ idType: [...this.state.idType, _newData] }, () => {
              console.log("addressaddress", this.state.idType);
            });
          }
        } else {
          let _newData = {
            ...data,
            id_type: data.id_type,
            label: data.id_type_name.label,
            value: data.id_type_name.value,
            id_photo: data.id_photo,
            file: data.id_photo,
            id: uuidv4(),
          };
          this.setState({ idType: [...this.state.idType, _newData] }, () => {
            console.log("addressaddress", this.state.idType);
          });
        }
      }
    }
  };

  onAddEditDrawerId = (name, e, list) => {
    console.log(list);
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <IdTypeComponent
          id="AddEditClient"
          list={list}
          callBackOnSave={this.callBackOnSaveId}
        />
      ),
      open: true,
      isMedium: false,
    });
  };

  viewDetailsIdEditDetails = (isEdit, editData, data, salutation) => {
    let _arrayOfJson = [];

    if (data?.length > 0) {
      _arrayOfJson = data;
    }
    return _arrayOfJson;
  };

  // Email Handlers
  callBackOnSaveEmail = (data, isremove) => {
    //
    if (isremove) {
      let isUpdateData = this.state.email?.filter(
        (val) => val.value !== data?.value
      );

      this.setState({ email: isUpdateData }, () => {
        console.log("addressaddress", this.state.email);
      });
    } else {
      this.props.drawer.setDrawer({
        component: <></>,
        open: false,
      });
      if (data) {
        if (this.state.email?.length > 0) {
          let isUpdateData = this.state.email?.findIndex(
            (val) => val.id === data?.id
          );
          if (isUpdateData > -1) {
            let _newData = {
              ...data,
              emailId: data.email,
              label: data.email_type.label,
              value: data.email_type.value,
              id: uuidv4(),
            };
            let { email } = this.state;
            let newArray = [...email];
            newArray[isUpdateData] = _newData;
            email = newArray;
            this.setState({ email }, () => {
              console.log("addressaddress", this.state.email);
            });
          } else {
            let _newData = {
              ...data,
              emailId: data.email,
              label: data.email_type.label,
              value: data.email_type.value,
              id: uuidv4(),
            };
            this.setState({ email: [...this.state.email, _newData] }, () => {
              console.log("addressaddress", this.state.email);
            });
          }
        } else {
          let _newData = {
            ...data,
            emailId: data.email,
            label: data.email_type.label,
            value: data.email_type.value,
            id: uuidv4(),
          };

          if (!Array.isArray(this.state.email)) {
            this.setState({ email: [] }, () => {
              this.setState({ email: [...this.state.email, _newData] }, () => {
                console.log("addressaddress", this.state.email);
              });
            });
          } else {
            this.setState({ email: [...this.state.email, _newData] }, () => {
              console.log("addressaddress", this.state.email);
            });
          }
        }
      }
    }
  };

  onAddEditDrawerEmail = (name, e, list) => {
    console.log(list);
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <EmailComponent
          id="AddEditClient"
          type="email"
          list={list}
          callBackOnSave={this.callBackOnSaveEmail}
        />
      ),
      open: true,
      isMedium: false,
    });
  };

  viewDetailsEmailEditDetails = (isEdit, editData, data, salutation) => {
    let _arrayOfJson = [];

    if (data?.length > 0) {
      _arrayOfJson = data;
    }
    return _arrayOfJson;
  };

  // Phone Handlers
  callBackOnSavePhone = (data, isremove) => {
    const { error } = this.state;

    if (isremove) {
      let isUpdateData = this.state.phone?.filter(
        (val) => val.value !== data?.value
      );

      this.setState({ phone: isUpdateData }, () => {
        console.log("addressaddress", this.state.phone);
      });
    } else {
      this.props.drawer.setDrawer({
        component: <></>,
        open: false,
      });
      error["add_phone"] = false;
      this.setState({ error });
      if (data) {
        if (this.state.phone?.length > 0) {
          let isUpdateData = this.state.phone?.findIndex(
            (val) => val.id === data?.id
          );
          if (isUpdateData > -1) {
            let _newData = {
              ...data,
              mobileNo: data?.mobilenumber,
              mobileNoIsdCode: data?.mobilenumberISDcode,
              label: data.mobile_type.label,
              value: data.mobile_type.value,
              id: uuidv4(),
            };
            let { phone } = this.state;
            let newArray = [...phone];
            newArray[isUpdateData] = _newData;
            phone = newArray;
            this.setState({ phone }, () => {
              console.log("addressaddress", this.state.phone);
            });
          } else {
            let _newData = {
              ...data,
              mobileNo: data?.mobilenumber,
              mobileNoIsdCode: data?.mobilenumberISDcode,
              label: data.mobile_type.label,
              value: data.mobile_type.value,
              id: uuidv4(),
            };
            this.setState({ phone: [...this.state.phone, _newData] }, () => {
              console.log("addressaddress", this.state.phone);
            });
          }
        } else {
          let _newData = {
            ...data,
            mobileNo: data?.mobilenumber,
            mobileNoIsdCode: data?.mobilenumberISDcode,
            label: data.mobile_type.label,
            value: data.mobile_type.value,
            id: uuidv4(),
          };
          
          if (!Array.isArray(this.state.phone)) {
            this.setState({ phone: [] }, () => {
              this.setState({ phone: [...this.state.phone, _newData] }, () => {
                console.log("addressaddress", this.state.phone);
              });
            });
          } else {
            this.setState({ phone: [...this.state.phone, _newData] }, () => {
              console.log("addressaddress", this.state.phone);
            });
          }
        }
      }
    }
  };

  onAddEditDrawerPhone = (name, e, list) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <PhoneComponent
          type="Phone"
          id="AddEditClient"
          list={list}
          callBackOnSave={this.callBackOnSavePhone}
        />
      ),
      open: true,
      isMedium: false,
    });
  };

  viewDetailsPhoneEditDetails = (isEdit, editData, data, salutation) => {
    let _arrayOfJson = [];

    if (data?.length > 0) {
      _arrayOfJson = data;
    }
    return _arrayOfJson;
  };


  // Family Members Callbacks
  updateParentMember = (editData) => {
    debugger;
    this.setState({
      member: { ...editData },
      memberIdType: editData?.idTypes,
      memberPhone: editData?.otherPhoneNumbers,
      memberAddress: editData?.otherAddress,
    });
  };

  updateFamilyMembers = (list) => {
    this.setState({
      familyMembers: [...list],
    }, () => {
      this.toggleResetState();
    });
  };

  // Id Handlers
  callBackOnSaveFamilyMembersId = (data, isremove) => {
    if (isremove) {
      let isUpdateData = this.state.memberIdType?.filter(
        (val) => val.value !== data?.value
      );

      this.setState(
        {
          memberIdType: isUpdateData,
          member: {
            ...this.state.member,
            idTypes: isUpdateData,
          },
        },
        () => {
          // console.log("addressaddress", this.state.memberIdType);
          this.onFamilyMemberAddClick();
        }
      );
    } else {
      if (data) {
        if (this.state.memberIdType?.length > 0) {
          let isUpdateData = this.state.memberIdType?.findIndex(
            (val) => val.id === data?.id
          );
          if (isUpdateData > -1) {
            let _newData = {
              ...data,
              id_type: data.id_type,
              label: data.id_type_name.label,
              value: data.id_type_name.value,
              id_photo: data.id_photo,
              file: data.id_photo,
              id: uuidv4(),
            };
            let { memberIdType } = this.state;
            let newArray = [...memberIdType];
            newArray[isUpdateData] = _newData;
            memberIdType = newArray;
            this.setState(
              {
                memberIdType,
                member: {
                  ...this.state.member,
                  idTypes: memberIdType,
                },
              },
              () => {
                this.onFamilyMemberAddClick();
                console.log("addressaddress", this.state.memberIdType);
              }
            );
          } else {
            // let {memberIdType} = this.state
            let _newData = {
              ...data,
              id_type: data.id_type,
              label: data.id_type_name.label,
              value: data.id_type_name.value,
              id_photo: data.id_photo,
              file: data.id_photo,
              id: uuidv4(),
            };
            // this.state.memberIdType.push(_newData)
            this.setState(
              {
                memberIdType: [...this.state.memberIdType, _newData],
                member: {
                  ...this.state.member,
                  idTypes: [...this.state.memberIdType, _newData],
                },
              },
              () => {
                this.onFamilyMemberAddClick();
                console.log("addressaddress", this.state.memberIdType);
              }
            );
          }
        } else {
          let _newData = {
            ...data,
            id_type: data.id_type,
            label: data.id_type_name.label,
            value: data.id_type_name.value,
            id_photo: data.id_photo,
            file: data.id_photo,
            id: uuidv4(),
          };
          this.setState(
            {
              memberIdType: [...this.state.memberIdType, _newData],
              member: {
                ...this.state.member,
                idTypes: [...this.state.memberIdType, _newData],
              },
            },
            () => {
              this.onFamilyMemberAddClick();
              console.log("addressaddress", this.state.memberIdType);
            }
          );
        }
      }
    }
  };

  onAddEditDrawerFamilyMemberId = (name, e, list) => {
    console.log(list);
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <IdTypeComponent
          id="AddEditClient"
          list={list}
          callBackOnSave={this.callBackOnSaveFamilyMembersId}
          backNeeded
          backHandler={this.onFamilyMemberAddClick}
        />
      ),
      open: true,
      isMedium: false,
    });
  };

  // Contact Handlers
  callBackOnSaveFamilyMemberPhone = (data, isremove) => {
    if (isremove) {
      let isUpdateData = this.state.memberPhone?.filter(
        (val) => val.value !== data?.value
      );

      this.setState(
        {
          memberPhone: isUpdateData,
          member: {
            ...this.state.member,
            otherPhoneNumbers: isUpdateData,
          },
        },
        () => {
          this.onFamilyMemberAddClick();
          console.log("addressaddress", this.state.memberPhone);
        }
      );
    } else {
      if (data) {
        if (this.state.memberPhone?.length > 0) {
          let isUpdateData = this.state.memberPhone?.findIndex(
            (val) => val.id === data?.id
          );
          if (isUpdateData > -1) {
            let _newData = {
              ...data,
              mobileNo: data?.mobilenumber,
              mobileNoIsdCode: data?.mobilenumberISDcode,
              label: data.mobile_type.label,
              value: data.mobile_type.value,
              id: uuidv4(),
            };
            let { memberPhone } = this.state;
            let newArray = [...memberPhone];
            newArray[isUpdateData] = _newData;
            memberPhone = newArray;
            this.setState(
              {
                memberPhone,
                member: {
                  ...this.state.member,
                  otherPhoneNumbers: memberPhone,
                },
              },
              () => {
                this.onFamilyMemberAddClick();
                console.log("addressaddress", this.state.memberPhone);
              }
            );
          } else {
            let _newData = {
              ...data,
              mobileNo: data?.mobilenumber,
              mobileNoIsdCode: data?.mobilenumberISDcode,
              label: data.mobile_type.label,
              value: data.mobile_type.value,
              id: uuidv4(),
            };
            this.setState(
              {
                memberPhone: [...this.state.memberPhone, _newData],
                member: {
                  ...this.state.member,
                  otherPhoneNumbers: [...this.state.memberPhone, _newData],
                },
              },
              () => {
                this.onFamilyMemberAddClick();
                console.log("addressaddress", this.state.memberPhone);
              }
            );
          }
        } else {
          let _newData = {
            ...data,
            mobileNo: data?.mobilenumber,
            mobileNoIsdCode: data?.mobilenumberISDcode,
            label: data.mobile_type.label,
            value: data.mobile_type.value,
            id: uuidv4(),
          };
          
          if (!Array.isArray(this.state.memberPhone)) {
            this.setState({ memberPhone: [] }, () => {
              this.setState(
                {
                  memberPhone: [...this.state.memberPhone, _newData],
                  member: {
                    ...this.state.member,
                    otherPhoneNumbers: [...this.state.memberPhone, _newData],
                  },
                },
                () => {
                  this.onFamilyMemberAddClick();
                  console.log("addressaddress", this.state.memberPhone);
                }
              );
            });
          } else {
            this.setState(
              {
                memberPhone: [...this.state.memberPhone, _newData],
                member: {
                  ...this.state.member,
                  otherPhoneNumbers: [...this.state.memberPhone, _newData],
                },
              },
              () => {
                this.onFamilyMemberAddClick();
                console.log("addressaddress", this.state.memberPhone);
              }
            );
          }
        }
      }
    }
  };

  onAddEditDrawerFamilyMemberPhone = (name, e, list) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <PhoneComponent
          type="Phone"
          id="AddEditClient"
          list={list}
          callBackOnSave={this.callBackOnSaveFamilyMemberPhone}
          backNeeded
          backHandler={this.onFamilyMemberAddClick}
        />
      ),
      open: true,
      isMedium: false,
    });
  };

  // Address Handlers
  callBackOnSaveFamilyMemberAddress = (data, isremove) => {
    debugger;
    if (isremove) {
      let isUpdateData = this.state.memberAddress?.filter(
        (val) => val.value !== data?.value
      );

      this.setState(
        {
          memberAddress: isUpdateData,
          member: {
            ...this.state.member,
            otherAddress: isUpdateData,
          },
        },
        () => {
          this.onFamilyMemberAddClick();
          console.log("addressaddress", this.state.memberAddress);
        }
      );
    } else {
      if (data) {
        if (this.state.memberAddress?.length > 0) {
          let isUpdateData = this.state.memberAddress?.findIndex(
            (val) => val.id === data?.id
          );
          if (isUpdateData > -1) {
            let _newData = {
              ...data,
              label: data.address_type.label,
              value: data.address_type.value,
              id: uuidv4(),
            };
            let { memberAddress } = this.state;
            let newArray = [...memberAddress];
            newArray[isUpdateData] = _newData;
            memberAddress = newArray;
            this.setState(
              {
                memberAddress,
                member: {
                  ...this.state.member,
                  otherAddress: memberAddress,
                },
              },
              () => {
                this.onFamilyMemberAddClick();
                console.log("addressaddress", this.state.memberAddress);
              }
            );
          } else {
            let _newData = {
              ...data,
              label: data.address_type.label,
              value: data.address_type.value,
              id: uuidv4(),
            };
            this.setState(
              {
                memberAddress: [...this.state.memberAddress, _newData],
                member: {
                  ...this.state.member,
                  otherAddress: [...this.state.memberAddress, _newData],
                },
              },
              () => {
                this.onFamilyMemberAddClick();
                console.log("addressaddress", this.state.memberAddress);
              }
            );
          }
        } else {
          let _newData = {
            ...data,
            label: data.address_type.label,
            value: data.address_type.value,
            id: uuidv4(),
          };
          this.setState(
            {
              memberAddress: [...this.state.memberAddress, _newData],
              member: {
                ...this.state.member,
                otherAddress: [...this.state.memberAddress, _newData],
              },
            },
            () => {
              this.onFamilyMemberAddClick();
              console.log("addressaddress", this.state.memberAddress);
            }
          );
        }
      }
    }
  };

  onAddEditDrawerFamilyMemberAddress = (name, e, list) => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <AddressComponent
          id="AddEditClient"
          type="address"
          list={list}
          callBackOnSave={this.callBackOnSaveFamilyMemberAddress}
          backNeeded
          backHandler={this.onFamilyMemberAddClick}
        />
      ),
      open: true,
      isMedium: false,
    });
  };

  updateState = (value, key) => {
    this.setState((prevState) => {
      return {
        member: {
          ...prevState.member,
          [key]: value,
        },
      };
    });
  };

  onFamilyMemberAddClick = (members = []) => {
    debugger;
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      component: (
        <FamilyMembersAddForm
          list={members}
          callBackOnSave={this.handleFamilyMemberSave}
          persistData={this.state.member}
          updateState={this.updateState}
          updateFamilyMembers={this.updateFamilyMembers}
          // BACK
          goBackHandler={this.onFamilyMemberAddClick}
          // ID Handlers
          callBackOnSaveId={this.callBackOnSaveFamilyMembersId}
          onAddEditDrawerId={this.onAddEditDrawerFamilyMemberId}
          // Contact Handlers
          callBackOnSavePhone={this.callBackOnSaveFamilyMemberPhone}
          onAddEditDrawerPhone={this.onAddEditDrawerFamilyMemberPhone}
          // Address Handlers
          callBackOnSaveAddress={this.callBackOnSaveFamilyMemberAddress}
          onAddEditDrawerAddress={this.onAddEditDrawerFamilyMemberAddress}
        />
      ),
      open: true,
      isMedium: true,
    });
  };

  resetFamilyMemberFormState = () => {
    this.setState({
      ...familyMemberInitialState,
    });
  };

  handleFamilyMemberSave = (data, isremove) => {
    debugger;
    if (isremove) {
      let isUpdateData = this.state.familyMembers?.filter(
        (val) => val.id !== data?.id
      );

      this.setState({ familyMembers: isUpdateData }, () => {
        this.resetFamilyMemberFormState();
        this.toggleResetState();
        console.log("addressaddress", this.state.familyMembers);
      });
    } else {
      this.props.drawer.setDrawer({
        component: <></>,
        open: false,
      });

      if (data) {
        // If there is already member exists
        if (this.state.familyMembers?.length > 0) {
          let isUpdateData = this.state.familyMembers?.findIndex(
            (val) => val.id === data?.id
          );

          // If the added data is same as the one created before
          if (isUpdateData > -1) {
            let selectedSalutation = this.props?.salutation?.data?.filter(x => x?.value === data?.salutation)?.[0]
            let _newData = {
              ...data,
              salutation: selectedSalutation,
              // TODO: ADD - ID, CONTACT, ADDRESS FIELDS
              id: uuidv4(),
            };
            let { familyMembers } = this.state;
            let newArray = [...familyMembers];
            newArray[isUpdateData] = _newData;
            familyMembers = newArray;
            this.setState({ familyMembers }, () => {
              this.resetFamilyMemberFormState();
              this.toggleResetState();
              console.log("addressaddress", this.state.familyMembers);
            });
          } else {
            // check if the current user has isEmergencyContact flag set to true, then change all other member flag to false

            let newArray = Array.from(this.state.familyMembers);

            if (data?.isEmergencyContact) {
              newArray = newArray?.map((mem) => {
                return {
                  ...mem,
                  isEmergencyContact: false,
                };
              });
            }

            // If added data is new
            let selectedSalutation = this.props?.salutation?.data?.filter(x => x?.value === data?.salutation)?.[0]
            let _newData = {
              ...data,
              salutation: selectedSalutation,
              // TODO: ADD - ID, CONTACT, ADDRESS FIELDS
              id: uuidv4(),
            };
            this.setState({ familyMembers: [...newArray, _newData] }, () => {
              this.resetFamilyMemberFormState();
              this.toggleResetState();
              console.log("addressaddress", this.state.familyMembers);
            });
          }
        } else {
          // If there is no member exists in state
          let selectedSalutation = this.props?.salutation?.data?.filter(x => x?.value === data?.salutation)?.[0]
          let _newData = {
            ...data,
            salutation: selectedSalutation,
            // TODO: ADD - ID, CONTACT, ADDRESS FIELDS
            id: uuidv4(),
          };
          
          this.setState(
            {
              familyMembers: [...this.state.familyMembers, _newData],
            },
            () => {
              this.resetFamilyMemberFormState();
              this.toggleResetState();
              console.log(this.state.familyMembers);
            }
          );
        }
      }
    }
  };

  updateClientState = (value, key) => {
    this.setState((prevState) => {
      return {
        clientState: {
          ...prevState.clientState,
          [key]: value,
        },
      };
    });
  };

  render() {
    const { isEdit } = this.props;

    const { data: salutation } = this.props?.salutation;
    let { data: masterPhoneCodeData } = this.props?.masterPhoneCode;

    const preFillData =
      isEdit || this.props?.location?.state?.isConvertAsClient ? true : false;

    const {
      data: clientData,
      loading: clientLoading,
      error: clientError,
    } = this.props?.careGiverClientInfo;

    let {
      data: enquiryData,
      loading: enquiryLoading,
      error: enquiryError,
    } = this.props?.enquiryData;

    let data = this.props?.location?.state?.isConvertAsClient
      ? this.mapperConverAsClientMapper(enquiryData)
      : clientData[0];

    let loading = clientLoading ? clientLoading : enquiryLoading;

    let error = clientError ? clientError : enquiryError;

    const components = {
      section_1: {
        label: "",
        fields: {
          uploadPhoto: {
            key: "uploadPhoto",
            id: "uploadPictureFromAddClient",
            type: formComponentTypes.PROFILE_UPLOAD_BUTTON,
            componentProps: {
              type: "text",
              label: "UPLOAD PHOTO",
            },
            breakPoints: {
              xs: 12,
              sm: 6,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: !preFillData ? "" : data?.profile_pic,
            isUrl: data?.profile_pic ? true : false,
          },
          name: {
            key: {
              select_key: "salutation",
              input_value_key: "name",
            },
            label: "CLIENT NAME",
            value: {
              salutation: this.state.clientState?.salutation, //
              name: this.state.clientState?.name,
            },
            type: formComponentTypes.MOBILE_INPUT_BOX,
            selectCallback: this.updateClientState,
            inputCallback: this.updateClientState,
            options:
              salutation && Object.keys(salutation).length > 0
                ? salutation?.filter((x) => x.label !== "other")
                : [{}],
            isMandatory: true,
            disabled:
              this.props?.location?.state?.isConvertAsClient && preFillData
                ? true
                : false,
            onlyText: true,
            customsize: 3,
            componentProps: {
              type: "text",
              id:"addClientNameFromAddClinet",
              select_id: "selectSalutationFromAddClient",
              style: {
                margin: "auto 0",
              },
            },
            breakPoints: {
              xs: 12,
              sm: 6,
              md: 4,
              lg: 4,
              xl: 4,
            },
          },
          dob: {
            key: "dob",
            type: formComponentTypes.DATE_TIME_PICKER,
            id: "addDateOfBirthFromAddClient",
            onChangeCallBack: this.updateClientState,
            componentProps: {
              isHideFutureDate: true,
              isDisable:
                this.props?.location?.state?.isConvertAsClient && preFillData
                  ? true
                  : false,
              style: {
                margin: "auto 0",
              },
            },
            isDate: true,
            breakPoints: {
              xs: 12,
              sm: 6,
              md: 4,
              lg: 4,
              xl: 4,
            },
            label: "DATE OF BIRTH",
            value: this.state.clientState?.dob,
            isMandatory: true,
          },
          gender: {
            key: "gender",
            type: formComponentTypes.SELECT_BOX,
            id: "selectGenderFromAddClient",
            onSelectedCallback: (value, parentState, key) =>
              this.updateClientState(value, key),
            isDisabled:
              this.props?.location?.state?.isConvertAsClient && preFillData
                ? true
                : false,
            breakPoints: {
              xs: 12,
              sm: 6,
              md: 4,
              lg: 4,
              xl: 4,
            },
            label: "GENDER",
            value: this.state.clientState?.gender,
            options: [
              { value: "Male", label: "Male" },
              { value: "Female", label: "Female" },
              { value: "Other", label: "other" },
            ],
            isMandatory: true,
          },
          // ic: {
          //   key: "ic_passport",
          //   type: formComponentTypes.ICNUMBERPASSPORT,
          //   id:{
          //     ic_id: "addICNumberFromAddClinet",
          //     passport_id: "addPassportNumberFromAddClient"
          //   },
          //   ic_disabled:
          //     this.props?.location?.state?.isConvertAsClient && data?.ic_number
          //       ? true
          //       : false,
          //   passport_disabled:
          //     this.props?.location?.state?.isConvertAsClient &&
          //     data?.passport_number
          //       ? true
          //       : false,
          //   componentProps: {
          //     type: "text",
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: 6,
          //     md: 8,
          //     lg: 8,
          //     xl: 8,
          //   },
          //   ic_label: "I/C NUMBER",
          //   passport_label: "PASSPORT NUMBER",
          //   // isMandatoryWithMultipleFieldsRelation:"ic_number_passport",
          //   value: !preFillData
          //     ? { passport: "", ic_number: "" }
          //     : {
          //         ic_number: data?.ic_number ? data?.ic_number : "",
          //         passport: data?.passport_number ? data?.passport_number : "",
          //       },
          //   isMandatory: true,
          // },
          emailid: {
            key: "emailid",
            type: formComponentTypes.INPUT_BOX,
            id: "addEmailIdFromAddClients",
            onCallback: this.updateClientState,
            componentProps: {
              type: "email",
            },
            breakPoints: {
              xs: 12,
              sm: 6,
              md: 4,
              lg: 4,
              xl: 4,
            },
            disabled:
              this.props?.location?.state?.isConvertAsClient && preFillData
                ? true
                : isEdit,
            // disabled: this.props?.location?.state?.isConvertAsClient
            //   ? false
            //   : isEdit,
            label: "PRIMARY EMAIL ADDRESS",
            value: this.state.clientState?.emailid,
            isMandatory: true,
          },
          mobilenumber: {
            key: {
              select_key: "mobilenumberISDcode",
              input_value_key: "mobilenumber",
            },
            isFieldAPhoneNumber: true,
            selectCallback: this.updateClientState,
            inputCallback: this.updateClientState,
            disabled:
              this.props?.location?.state?.isConvertAsClient && data?.mobile_no
                ? true
                : false,
            value: {
              mobilenumberISDcode: this.state.clientState?.mobilenumberISDcode,
              mobilenumber: this.state.clientState?.mobilenumber,
            },
            type: formComponentTypes.MOBILE_INPUT_BOX,
            componentProps: {
              type: "number",
              id:"addClientNumberFromAddClinet",
              select_id: "selectCountryCodeFromAddClient",
              allScreenCustomSize: {
                xs: 6,
                sm: 4,
                md: 6,
              },
            },
            customsize: 4,
            breakPoints: {
              xs: 12,
              sm: 6,
              md: 4,
              lg: 4,
              xl: 4,
            },
            isComponentAMobileField: true,
            label: "PRIMARY MOBILE NUMBER",
            //   value: [null, null],
            // options: [
            //   {
            //     label: "+60",
            //     value: "60",
            //   },
            // ],
            options: masterPhoneCodeData,
            isMandatory: true,
          },
          // addressLine1: {
          //   key: "addressLine1",
          //   type: formComponentTypes.INPUT_BOX,
          //   disabled:
          //     this.props?.location?.state?.isConvertAsClient &&
          //       data?.addressLine1
          //       ? true
          //       : false,
          //   componentProps: {
          //     type: "text"
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: 6,
          //     md: 6,
          //     lg: 6,
          //     xl: 6
          //   },
          //   label: "ADDRESS LINE 1",
          //   value: !preFillData ? "" : data?.addressLine1,
          //   isMandatory: true
          // },
          // addressLine2: {
          //   key: "addressLine2",
          //   type: formComponentTypes.INPUT_BOX,
          //   disabled:
          //     this.props?.location?.state?.isConvertAsClient &&
          //       data?.addressLine2
          //       ? true
          //       : false,
          //   componentProps: {
          //     type: "text"
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4
          //   },
          //   label: "ADDRESS LINE 2",
          //   value: !preFillData ? "" : data?.addressLine2
          //   // isMandatory: true,
          // },
          // area: {
          //   key: "area",
          //   type: formComponentTypes.INPUT_BOX,
          //   disabled:
          //     this.props?.location?.state?.isConvertAsClient && data?.area
          //       ? true
          //       : false,
          //   componentProps: {
          //     type: "text"
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4
          //   },
          //   label: "CITY",
          //   value: !preFillData ? "" : data?.area,
          //   isMandatory: true
          // },
          // state: {
          //   key: "state",
          //   type: formComponentTypes.INPUT_BOX,
          //   disabled:
          //     this.props?.location?.state?.isConvertAsClient && data?.state
          //       ? true
          //       : false,
          //   componentProps: {
          //     type: "text"
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4
          //   },
          //   label: "STATE",
          //   value: !preFillData ? "" : data?.state,
          //   isMandatory: true
          // },
          // country: {
          //   key: "country",
          //   type: formComponentTypes.INPUT_BOX,
          //   disabled:
          //     this.props?.location?.state?.isConvertAsClient && data?.country
          //       ? true
          //       : false,
          //   componentProps: {
          //     type: "text"
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4
          //   },
          //   label: "COUNTRY",
          //   value: !preFillData ? "" : data?.country,
          //   isMandatory: true
          // },
          // postalCode: {
          //   key: "postalCode",
          //   type: formComponentTypes.INPUT_BOX,
          //   disabled:
          //     this.props?.location?.state?.isConvertAsClient && data?.postalCode
          //       ? true
          //       : false,
          //   componentProps: {
          //     type: "number"
          //   },
          //   limit: 10,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4
          //   },
          //   label: "POSTAL CODE",
          //   value: !preFillData ? "" : data?.postalCode,
          //   isMandatory: true
          // },
          occupation: {
            key: "occupation",
            type: formComponentTypes.INPUT_BOX,
            id: "addOccupationFromAddClient",
            onCallback: this.updateClientState,
            disabled:
              this.props?.location?.state?.isConvertAsClient && data?.occupation
                ? true
                : false,
            componentProps: {
              type: "text",
            },
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            label: "OCCUPATION",
            value: this.state.clientState?.occupation,
            isMandatory: false,
          },
          // languages: {
          //   key: "languages",
          //   type: formComponentTypes.INPUT_BOX,
          //   disabled:
          //     this.props?.location?.state?.isConvertAsClient && data?.languages
          //       ? true
          //       : false,
          //   componentProps: {
          //     type: "text"
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: 4,
          //     md: 4,
          //     lg: 4,
          //     xl: 4
          //   },
          //   label: "PREFERRED LANGUAGE",
          //   value: !preFillData ? "" : data?.languages,
          //   isMandatory: true
          // },
          languages: {
            key: "languages",
            type: formComponentTypes.SELECT_BOX,
            onSelectedCallback: (value, parentState, key) =>
              this.updateClientState(value, key),
            id: "selectLanguagesFromAddClient",
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            isMulti: true,
            label: "PREFERRED LANGUAGE",
            value: this.state.clientState?.languages,
            options:
              this.props.language?.data &&
              Object.keys(this.props.language?.data).length > 0
                ? this.props.language?.data
                : [{}],
            isMandatory: false,
          },
          addId: {
            key: "addId",
            type: formComponentTypes.ADD_ID_TYPE,
            id: {
              add_email_id: "addIdButtonFromAddClient",
            },
            breakPoints: {
              xs: 12,
              sm: 6,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              component: this.viewDetailsIdEditDetails(
                this.state?.isEdit,
                this.state.idType,
                this.state.idType,
                [{}]
              ),
              callBackOnSave: this.callBackOnSaveId,
              editData: this.state.idType,
              isEdit: this.state?.isEdit,
              handledrawer: this.onAddEditDrawerId,
              error: this.state.error["idType"],
              // errorText: this.state.error["add_address_errorMessage"]
            },
          },
          addAddress: {
            key: "addAddress",
            type: formComponentTypes.ADD_ADDRESS,
            id:{
              add_address_id: "addAddressButtonFromAddClient",
            },
            componentProps: {
              component: this.viewDetailsAddressEditDetails(
                this.state?.isEdit,
                this.state.address,
                this.state.address,
                [{}]
              ),
              callBackOnSave: this.callBackOnSaveAddress,
              editData: this.state.address,
              isEdit: this.state?.isEdit,
              handledrawer: this.onAddEditDrawerAddress,
              error: this.state.error["add_address"],
              errorText: errorMessage.atleastOne("address"),
            },
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
          },
          addEmail: {
            key: "addEmail",
            type: formComponentTypes.ADD_EMAIL,
            id:{
              add_email_id:"addEmailButtonFromAddClient"
            },
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              component: this.viewDetailsEmailEditDetails(
                this.state?.isEdit,
                this.state.email,
                this.state.email,
                [{}]
              ),
              callBackOnSave: this.callBackOnSaveEmail,
              editData: this.state.email,
              isEdit: this.state?.isEdit,
              handledrawer: this.onAddEditDrawerEmail,
              // error: this.state.error["add_address"],
              // errorText: this.state.error["add_address_errorMessage"]
            },
          },
          addPhoneNumber: {
            key: "addPhoneNumber",
            type: formComponentTypes.ADD_PHONE_NUMBER,
            id:{
              add_phone_id:"addPhoneButtonFromAddClient"
            },
            breakPoints: {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            componentProps: {
              component: this.viewDetailsPhoneEditDetails(
                this.state?.isEdit,
                this.state.phone,
                this.state.phone,
                [{}]
              ),
              callBackOnSave: this.callBackOnSavePhone,
              editData: this.state.phone,
              isEdit: this.state?.isEdit,
              handledrawer: this.onAddEditDrawerPhone,
              // error: this.state.error["add_address"],
              // errorText: this.state.error["add_address_errorMessage"]
            },
          },
          resource_validity: {
            type: formComponentTypes.RESOURCE_VALIDITY,
            key: {
              startDate: "resourceEffectiveFrom",
              endDate: "resourceEndsOn",
            },
            id:{
              effectiveFrom_id: "addEffectiveFromAddClient",
              end_id: "addEndFromAddClient"
            },
            value: {
              resourceEffectiveFrom: this.state.clientState
                ?.resourceEffectiveFrom,
              resourceEndsOn: this.state.clientState?.resourceEndsOn,
            },
            breakPoints: {
              xs: 12,
              sm: 8,
              md: 8,
              lg: 8,
              xl: 8,
            },
            isMandatory: true,
            componentProps: {
              isEdit: isEdit,
              startDateProps: {
                label: "EFFECTING FROM",
                // value: isEdit ? preFillData?.[0]?.effectiveFrom : null,
                effectiveFrom_id: "addEffectiveFromAddService",
                callback: this.updateClientState,
              },
              endDateProps: {
                label: "ENDS ON",
                // value: isEdit ? preFillData?.[0]?.effectiveTo : null,
                end_id: "addEndFromAddService",
                callback: this.updateClientState,
              },
            },
          },
        },
      },

      section_2: {
        label: "Family Member Details",
        fields: {
          familyMembers: {
            key: "familyMembers",
            type: formComponentTypes.FAMILY_MEMBERS,
            id: "familyMembersFromAddEditClient",
            breakPoints: {
              xs: 12,
            },
            componentProps: {
              onAddClick: this.onFamilyMemberAddClick,
              handleSave: this.handleFamilyMemberSave,
              editData: this.state.familyMembers,
              style: {
                alignSelf: "unset !important",
              },

              updateState: this.updateState,
              updateFamilyMembers: this.updateFamilyMembers,
              updateParentMember: this.updateParentMember,
              // Back
              goBackHandler: this.onFamilyMemberAddClick,
              // Id
              callBackOnSaveId: this.callBackOnSaveFamilyMembersId,
              onAddEditDrawerId: this.onAddEditDrawerFamilyMemberId,
              // Phone
              callBackOnSavePhone: this.callBackOnSaveFamilyMemberPhone,
              onAddEditDrawerPhone: this.onAddEditDrawerFamilyMemberPhone,
              // Address
              callBackOnSaveAddress: this.callBackOnSaveFamilyMemberAddress,
              onAddEditDrawerAddress: this.onAddEditDrawerFamilyMemberAddress,
            },
          },
          // relativeName: {
          //   key: "relativeName",
          //   type: formComponentTypes.INPUT_BOX,
          //   id:"addRelativeNameFromAddEditClient",
          //   disabled:
          //     this.props?.location?.state?.isConvertAsClient &&
          //     data?.emergency_contact?.contact_name
          //       ? true
          //       : false,
          //   componentProps: {
          //     type: "text",
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: 6,
          //     md: 6,
          //     lg: 6,
          //     xl: 6,
          //   },
          //   label: "name",
          //   value: !preFillData ? "" : data?.emergency_contact?.contact_name,
          //   isMandatory: true,
          // },
          // relationship: {
          //   key: "relationship",
          //   type: formComponentTypes.SELECT_BOX,
          //   id:"selectRelationshipFromAddEditClient",
          //   isDisabled:
          //     this.props?.location?.state?.isConvertAsClient &&
          //     data?.emergency_contact?.contact_name
          //       ? true
          //       : false,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 6,
          //     md: 6,
          //     lg: 6,
          //     xl: 6,
          //   },
          //   label: "RELATIONSHIP",
          //   value: !preFillData
          //     ? ""
          //     : data?.emergency_contact?.relationship
          //     ? {
          //         value: data?.emergency_contact?.relationship,
          //         label: data?.emergency_contact?.relationship,
          //       }
          //     : "",
          //   options: [
          //     {
          //       value: "Friend",
          //       label: "Friend",
          //     },
          //     {
          //       value: "Father",
          //       label: "Father",
          //     },
          //     {
          //       value: "Mother",
          //       label: "Mother",
          //     },
          //     {
          //       value: "Son",
          //       label: "Son",
          //     },
          //     {
          //       value: "Daughter",
          //       label: "Daughter",
          //     },
          //     {
          //       value: "Husband",
          //       label: "Husband",
          //     },
          //     {
          //       value: "Wife",
          //       label: "Wife",
          //     },
          //     {
          //       value: "Brother",
          //       label: "Brother",
          //     },
          //     {
          //       value: "Sister",
          //       label: "Sister",
          //     },
          //     {
          //       value: "Grandfather",
          //       label: "Grandfather",
          //     },
          //     {
          //       value: "Grandmother",
          //       label: "Grandmother",
          //     },
          //     {
          //       value: "Grandson",
          //       label: "Grandson",
          //     },
          //     {
          //       value: "Granddaughter",
          //       label: "Granddaughter",
          //     },
          //     {
          //       value: "Uncle",
          //       label: "Uncle",
          //     },
          //     {
          //       value: "Aunt",
          //       label: "Aunt",
          //     },
          //     {
          //       value: "Nephew",
          //       label: "Nephew",
          //     },
          //     {
          //       value: "Niece",
          //       label: "Niece",
          //     },
          //     {
          //       value: "Cousin",
          //       label: "Cousin",
          //     },
          //     {
          //       value: "Others",
          //       label: "Others",
          //     },
          //   ],
          //   isMandatory: true,
          // },
          // relativeEmailid: {
          //   key: "relativeEmailid",
          //   type: formComponentTypes.INPUT_BOX,
          //   id:"addRelativeEmailAddressFromAddEditClient",
          //   disabled:
          //     this.props?.location?.state?.isConvertAsClient &&
          //     data?.emergency_contact?.email
          //       ? true
          //       : false,
          //   componentProps: {
          //     type: "email",
          //   },
          //   breakPoints: {
          //     xs: 12,
          //     sm: 6,
          //     md: 6,
          //     lg: 6,
          //     xl: 6,
          //   },
          //   label: "EMAIL ADDRESS",
          //   value: !preFillData ? "" : data?.emergency_contact?.email,
          //   isMandatory: true,
          // },
          // relativeMobilenumber: {
          //   key: {
          //     select_key: "relativeMobileISDcode",
          //     input_value_key: "relativeMobilenumber",
          //   },
          //   isFieldAPhoneNumber: true,
          //   disabled:
          //     this.props?.location?.state?.isConvertAsClient &&
          //     data?.emergency_contact?.mobile_no
          //       ? true
          //       : false,
          //   value: {
          //     relativeMobileISDcode: !preFillData
          //       ? ""
          //       : this.getFormattedISDCode(
          //           data?.emergency_contact?.mobile_no_isd_code
          //         ),
          //     relativeMobilenumber: !preFillData
          //       ? ""
          //       : data?.emergency_contact?.mobile_no,
          //   },
          //   type: formComponentTypes.MOBILE_INPUT_BOX,
          //   componentProps: {
          //     type: "number",
          //     id:"addRelativeNumberFromAddClinet",
          //     select_id: "selectRelativeCountryCodeFromAddClient",
          //     allScreenCustomSize: {
          //       xs: 6,
          //       sm: 4,
          //       md: 6,
          //     },
          //   },
          //   isComponentAMobileField: true,
          //   customsize: 4,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 6,
          //     md: 6,
          //     lg: 6,
          //     xl: 6,
          //   },
          //   label: "MOBILE NUMBER",
          //   //   value: [null, null],
          //   // options: [
          //   //   {
          //   //     label: "+60",
          //   //     value: "60",
          //   //   },
          //   // ],
          //   options: masterPhoneCodeData,
          //   isMandatory: true,
          // },
        },
      },

      /* section_3: {
        isDontShow: !Boolean(
          // this.state.role === user_roles.service_coordinator ||
          // this.state.role === user_roles.supervisor
          this.state.role === user_roles.super_admin
        ),
        label: "",
        fields: {
          // select_category: {
          //   key: "category",
          //   type: formComponentTypes.SELECT_BOX,
          //   breakPoints: {
          //     xs: 12,
          //     sm: 6,
          //     md: 6,
          //     lg: 6,
          //     xl: 6
          //   },
          //   isDisabled:
          //     this.props?.location?.state?.isConvertAsClient &&
          //       data?.category?.[0]
          //       ? true
          //       : false,
          //   label: "SELECT CATEGORY",
          //   value: !preFillData
          //     ? this.props?.auth_data?.data?.category?.length > 1
          //       ? ""
          //       : this.props?.auth_data?.data?.category?.[0]
          //     : data?.category?.[0],
          //   // onSelectedCallback: this.careProviderByCategory,
          //   options: this.props?.auth_data?.data?.category,
          //   isLoading: this.props?.categories?.loading,
          //   isMandatory: Boolean(
          //     this.state.role === user_roles.service_coordinator ||
          //     this.state.role === user_roles.supervisor
          //   )
          //   // cleanUpKeys: ['select_provider']
          // },
          select_provider: {
            key: "provider",
            type: formComponentTypes.SELECT_BOX,
            breakPoints: {
              xs: 12,
              sm: 6,
              md: 6,
              lg: 6,
              xl: 6
            },
            isDisabled:
              this.props?.location?.state?.isConvertAsClient &&
                this.props?.auth_data?.data?.providerDetails[0]?.provider_name
                ? true
                : false,
            label: "SELECT PROVIDER",
            value: !preFillData
              ? this.props?.auth_data?.data?.providerDetails?.length > 1
                ? ""
                : {
                  value: this.props?.auth_data?.data?.providerDetails[0]
                    ?.provider_id,
                  label: this.props?.auth_data?.data?.providerDetails[0]
                    ?.provider_name
                }
              : data?.provider !== null
                ? this.props?.dropPartner?.data?.filter(x => x?.value === data?.provider)[0]
                : null,
            options: this.props?.dropPartner?.data ?? [],
            isLoading: this.props?.dropPartner?.loading,
            isMandatory: Boolean(
              this.state.role === user_roles.super_admin
            )
            // isMulti: true,
          }
        }
      },*/
      section_4: {
        label: "Health Information",
        fields: {
          height: {
            key: "height",
            type: formComponentTypes.INPUT_BOX,
            id:"addHeightFromAddEditClient",
            disabled:
              this.props?.location?.state?.isConvertAsClient &&
              data?.health_information?.height
                ? true
                : false,
            componentProps: {
              type: "number",
              limit: 3,
            },
            breakPoints: {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            onCallback: this.bmiCalculation,
            label: "HEIGHT (CM)",
            value: this.state.clientState?.height,
            isMandatory: false,
          },
          weight: {
            key: "weight",
            type: formComponentTypes.INPUT_BOX,
            id:"addWeightFromAddEditClient",
            disabled:
              this.props?.location?.state?.isConvertAsClient &&
              data?.health_information?.weight
                ? true
                : false,
            componentProps: {
              type: "number",
              limit: 3,
            },
            breakPoints: {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            onCallback: this.bmiCalculation,
            label: "WEIGHT (KG)",
            value: this.state.clientState?.weight,
            isMandatory: false,
          },
          BMI: {
            key: "BMI",
            type: formComponentTypes.BMI,
            id:"addBmiFromAddEditClient",
            componentProps: {
              type: "number",
              disabled: true,
            },
            breakPoints: {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            label: "BMI (KG/M2)",
            value: this.state.bmi,
            data: this.state.bmi,
            // isMandatory: true,
          },
          bloodGroup: {
            key: "bloodGroup",
            type: formComponentTypes.SELECT_BOX,
            onSelectedCallback: (value, parentState, key) =>
              this.updateClientState(value, key),
            id:"selectBloodGroupFromAddEditClient",
            isDisabled:
              this.props?.location?.state?.isConvertAsClient && preFillData
                ? true
                : false,
            breakPoints: {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            label: "BLOOD GROUP",
            value: this.state.clientState?.bloodGroup,
            options: bloodGroupOptions(),
          },
          complaint: {
            key: "chief_complaint_current_complaint",
            id:"addChiefComplaintFromAddEditClient",
            type: formComponentTypes.RICHTEXTEDITOR,
            onChangeCallBack: this.updateClientState,
            componentProps: {
              multiline: true,
              rowsMax: 4,
              type: "textArea",
              isLimitHelperTextNeeded: true,
            },
            limit: 5000,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "CHIEF COMPLAINT",
            value: this.state.clientState?.chief_complaint_current_complaint,
            isMandatory: true,
          },
          medical_history: {
            key: "medical_history",
            id:"addMedicalHistoryFromAddEditClient",
            type: formComponentTypes.RICHTEXTEDITOR,
            onChangeCallBack: this.updateClientState,
            componentProps: {
              multiline: true,
              rowsMax: 4,
              type: "textArea",
              isLimitHelperTextNeeded: true,
            },
            limit: 5000,
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "MEDICAL HISTORY",
            value: this.state.clientState?.medical_history,
            isMandatory: true,
          },
          precaution: {
            key: "precaution",
            type: formComponentTypes.BUTTON_WITH_INPUT_FIELD,
            id:{
              text_id:"addTermsConditionsFromAddEditClient",
              button_id:"addTermsConditionsButtonFromAddEditClient",
            },
            onSaveCallback: this.updateClientState,
            title: "SPECIAL PRECAUTION",
            buttonLabel: "+ ADD",
            breakPoints: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            label: "SPECIAL PRECAUTION",
            value: this.state.clientState?.precaution,
            isMandatory: true,
          },
        },
      },
    };

    let { loading: addLoading } = this.props?.upsert_client;

    let { loading: updateLoading } = this.props?.upsert_client;

    return (
      <div style={{ padding: 20 }}>
        {this.props?.isEdit && (
          <>
            {loading && <LoadingSection message="Fetching client detail..." />}

            {!loading && error && (
              <InternatlServerError
                message="Something went wrong! Unable to fetch"
                onRetry={this.getEssentials}
              />
            )}

            {!loading && !error && data && (
              <AddEditUserManagement
                components={components}
                onSaveButtonClicked={this.onSaveButtonClicked}
                loading={updateLoading}
                isUrl={true}
                saveButtonLabel={updateLoading ? "Updating..." : "Update"}
                saveButtonId={"saveButtonFromAddEditClient"}
                cancelButtonId={"cancelButtonFromAddEditClient"}
                isResetState={this.state.isResetState}
              />
            )}
          </>
        )}

        {this.props?.location?.state?.isConvertAsClient && (
          <>
            {loading && <LoadingSection message="Fetching client detail..." />}

            {!loading && error && (
              <InternatlServerError
                message="Something went wrong! Unable to fetch"
                onRetry={this.getEssentials}
              />
            )}

            {!loading && !error && data && (
              <AddEditUserManagement
                isUrl={true}
                components={components}
                onSaveButtonClicked={this.onSaveButtonClicked}
                loading={addLoading}
                saveButtonLabel={addLoading ? "Saving..." : "Save"}
                saveButtonId={"saveButtonFromAddEditClient"}
                cancelButtonId={"cancelButtonFromAddEditClient"}
                isResetState={this.state.isResetState}
              />
            )}
          </>
        )}

        {!this.props?.location?.state?.isConvertAsClient &&
          !this.props?.isEdit && (
            <AddEditUserManagement
              components={components}
              onSaveButtonClicked={this.onSaveButtonClicked}
              loading={addLoading}
              saveButtonLabel={addLoading ? "Saving..." : "Save"}
              saveButtonId={"saveButtonFromAddEditClient"}
              cancelButtonId={"cancelButtonFromAddEditClient"}
              isResetState={this.state.isResetState}
            />
          )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  // categories: state?.masterSet?.categories,
  careGiverClientInfo: state?.careGiverClient?.careGiverClientInfo,
  upsert_client: state?.persona_client?.upsert_client,
  enquiryData: state.enquiry_management?.enquiry_details,
  auth_data: state?.authState?.loginAuth,
  salutation: state?.masterModule?.getsal ?? {
    data: [],
    loading: false,
    error: false,
  },
  language: state?.masterModule?.getlang ?? {
    data: [],
    loading: false,
    error: false,
  },
  dropPartner: state?.careProvider?.dropPartner,
  masterPhoneCode: state?.resourceReducer?.masterPhoneCode,
});

export default connect(
  mapStateToProps,
  actions
)(withAlelrtAndDialog(withRouter(AddEditClients)));
