import * as React from "react"

export const HeartIcon = (props) => {

    const {
        width = "26.868",
        height = "23.826",
        color="#6f6f6f"
    } = props;


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 26.868 23.826"
        >
              <path id="icons8-heart_2_" data-name="icons8-heart (2)" d="M9.31,7A7.319,7.319,0,0,0,2,14.31c0,8.3,9.389,12.4,12.582,15.06l.351.3.351-.3c3.194-2.662,12.582-6.759,12.582-15.06A7.264,7.264,0,0,0,14.934,9.724,7.25,7.25,0,0,0,9.31,7Zm0,1.125A6.165,6.165,0,0,1,14.46,10.9l.474.7.475-.7A6.178,6.178,0,0,1,26.743,14.31c0,7.253-8.1,11.007-11.809,13.936C11.226,25.318,3.125,21.564,3.125,14.31A6.178,6.178,0,0,1,9.31,8.125Z" transform="translate(-1.5 -6.5)" 
                fill="#6f6f6f"
                stroke={color}
                stroke-width="1"
            />
        </svg>
    );
};

