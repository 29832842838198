import React from 'react'

import { withStyles, Grid, Typography, FormControl, Select, MenuItem } from '@material-ui/core'


const styles = (theme) => ({
  upperContainer: {
    width: '100%',
    padding: theme.spacing(2),
    borderBottom: `0.5px solid ${theme.palette.secondary.light}`,
    position: 'sticky',
    top: 0,
  },
  flexIt: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formControl: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

class Filter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  handleChange = (event) => {
    this.props.onFilterChange(event.target.value)
  }

  render() {
    const { classes, filter, count, title, hideFilter } = this.props

    return (
      <div className={classes.upperContainer}>
        <Grid container direction="row" justify="space-between">
          <Grid item className={classes.flexIt} xs>
            <Typography variant="body1" align="left" style={{ width: '100%' }}>{`${count === null || count === undefined ? 0 : count}  ${title ? title : 'Rating(s)'}`}</Typography>
          </Grid>
          <Grid item>

            {!hideFilter && <FormControl variant="outlined" className={classes.formControl}>
              <Select
                value={filter}
                onChange={this.handleChange}
                style={{
                  height: 40,
                  width: 180,
                  overflow: 'hidden'
                }}
              >
                <MenuItem value={1}>Most Recent</MenuItem>
                <MenuItem value={2}>Most Rated</MenuItem>
                <MenuItem value={3}>Least Rated</MenuItem>
              </Select>
            </FormControl>}
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(Filter)