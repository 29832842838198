import React from "react";
import PropTypes from "prop-types";

import { SvgIcon } from "@material-ui/core";

function MaximizeIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.665 0A2.684 2.684 0 0 0 0 2.665v5.329h2.665v-5.33h5.329V0h-5.33Zm13.321 0v2.665h5.33v5.329h2.664v-5.33A2.684 2.684 0 0 0 21.315 0h-5.329ZM0 15.986v5.33a2.684 2.684 0 0 0 2.665 2.664h5.329v-2.665h-5.33v-5.329H0Zm21.315 0v5.33h-5.329v2.664h5.33a2.684 2.684 0 0 0 2.664-2.665v-5.329h-2.665Z"
        fill={props.color}
      />
    </SvgIcon>
  );
}

MaximizeIcon.propTypes = {
  color: PropTypes.string,
};

MaximizeIcon.defaultProps = {
  color: "#000",
};

export default MaximizeIcon;
