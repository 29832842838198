import { Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
// import EmailIcon from '@material-ui/icons/Email';
// import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles((theme) => ({
  header: {
    height: "10%",
    // height: 44,
    [theme.breakpoints.down("xs")]: {
    //   height: 60,
      textAlign: "center",
    //   marginTop: 64
    }
  },
  logo: {
    width: 150,
  },
  ButtonAlike:{
      padding: "6px 16px",
      marginRight: 10,
      border: "1px solid black",
      borderRadius: "8px"
  },
  icons:{
      marginBottom:-8
  },
}))

export default function Footoer(props) {
  const classes = useStyles(props);
  return (
    <>
      <Grid
        container
        xs={12}
        justify="flex-start"
        className={ props.customHeaderStyle ? props.customHeaderStyle : classes.header}
        style={{background:"#0063E7", maxWidth:1280, marginLeft:"auto", marginRight: "auto"}}
      >
          {/* <Grid item xs={4} style={{marginTop: 8}}></Grid> */}
        <Grid item xs={12} style={{marginTop: 8, textAlign:"center"}}>
          {/* <img
            src="/images/tanyaCare/login_top_logo/tanyaCareTopLogo-light.png"
            alt="Tanya.Care"
            className={classes.logo}
          /> */}
          <Typography variant="subtitle2" style={{color: "white"}}>&#169; tanya.care. All Rights Reserved</Typography>
          <Typography variant="caption" style={{color: "white"}}>Lot 7.01 B & C Menara BRDB, 285 Jalan Maarof, Bukit Bandaraya 59000 Kuala Lumpur, Malaysia</Typography>
          
        </Grid>
        {/* <Grid item xs={4} style={{marginTop: 8}}></Grid> */}
       
      </Grid>
    </>
  )
}
