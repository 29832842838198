import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  IconButton,
  withStyles,
  withTheme,
  Hidden,
  useMediaQuery,
  Avatar
} from "@material-ui/core";
import { Calendar } from "../../../assets";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "../../../utils";
import moment from "moment";
import PersonIcon from '@material-ui/icons/Person';

// formatString

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  detailSection: {
    marginTop: 20,
  },
  customActionButtons: {
    padding: 8,
    border: "0.5px solid #e9e9e9",
  },
  accordionRoot: {
    boxShadow: (props) =>
      props.withCustomBoxShadow
        ? "0 3px 6px 0 rgba(0, 0, 0, 0.04) !important"
        : "none !important",
  },
  days: {
    marginLeft: 8,
  },
  dayRow: {
    margin: "8px 0px",
  },
  dayRowMargin: {
    margin: "8px 0px",
  },
  exceptionRoot: {
    padding: 20,
    background: "#fafafa",
    borderRadius: 8,
    width: "100%",
  },
  exceptionWithoutAccordianRoot: {
    padding: 20,
    background: "white",
    borderRadius: 8,
    width: "100%",
  },
}));

const CustomAccordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: (props) => props.withCustomBoxShadow || "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
  },
})(Accordion);

const CustomAccordionSummary = withStyles((theme) => ({
  root: {
    padding: 20,
    "&.Mui-expanded": {
      borderBottom: `0.3px dashed rgba(0, 0, 0, 0.1)`,
    },
  },
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
}))((props) => <AccordionSummary {...props} />);

const CustomAccordionDetails = withStyles((theme) => ({
  root: {
    padding: "20px 20px 28px 20px",
  },
}))((props) => <AccordionDetails {...props} />);

const CustomChip = withStyles((theme) => ({
  root: {
    borderRadius: 4,
    backgroundColor: "rgba(233, 233, 233, 0.48)",
  },
  colorPrimary: {
    backgroundColor: theme.palette.quaternary.main,
  },
}))((props) => <Chip {...props} />);

function ExceptionAccordion(props) {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isBelow500 = useMediaQuery(theme.breakpoints.down(500));
  const classes = useStyles(props);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const calendarStyle = {
    fontSize: "1.3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const { exceptionData, handleEdit, handleDelete, isAccordian=true } = props;



  const getFormattedTime = (slot) => {
    
    let fromTime = slot?.timeSlot?.[0];
    let toTime = slot?.timeSlot?.[1];
    const getTimeString = (time) => {
      return moment(time).isValid() ? moment(time)?.format("hh:mm A") : "NA";
    };
    return `${getTimeString(fromTime)} - ${getTimeString(toTime)}`;
    //(${slot?.timeZone?.country})
  };

  const weekDays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  return (
    <div className={classes.root}>
    {isAccordian ?  <CustomAccordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        classes={{ root: classes.accordionRoot }}
      >
        <CustomAccordionSummary>
          <Grid container alignItems="center" spacing={1}>
            <Grid item container xs={12} sm={8} md={9}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <div style={{ padding: 12, background: "#f7f7fb", display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: "50%" }}>
                  <div style={{ position: 'relative', top: 1, left: 2 }}> */}
                    <Avatar style={{backgroundColor:"#f7f7fb"}}> 
                    <PersonIcon htmlColor={"#28a9c1"} style={calendarStyle} />
                    </Avatar>
                  {/* </div>
                </div> */}
                <Typography
                  variant="h6"
                  style={{ margin: "0px 16px", fontSize: "1.4rem" }}
                >
                  {exceptionData?.data?.service_professional?.value[0] ?? ``}
                </Typography>
                {/* <Hidden xsDown><CustomChip color="primary" label={`Based on ${exceptionData.based_on}`} /></Hidden> */}
              </div>
            </Grid>
            {/* <Grid item container xs></Grid> */}
            {
              <Hidden xsDown>
                {" "}
                <Grid item container xs={12} sm={4} md={3} justify="flex-end">
                  <Grid item>
                    {!props.hideEdit && (
                      <IconButton
                        classes={{ root: classes.customActionButtons }}
                        onClick={(e) => handleEdit(e, props?.exceptionId)}
                      >
                        <CreateOutlinedIcon
                          fontSize="small"
                          htmlColor={props.theme.palette.ternary.main}
                        />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item>
                    {!props.hideDelete && (
                      <IconButton
                        classes={{ root: classes.customActionButtons }}
                        style={{ margin: "0px 8px" }}
                        onClick={(e) => handleDelete(e, props?.exceptionId)}
                      >
                        <DeleteOutlinedIcon
                          fontSize="small"
                          htmlColor={props.theme.palette.ternary.main}
                        />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item>
                    <IconButton classes={{ root: classes.customActionButtons }}>
                      {/* {expanded === 'panel1' ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />} */}
                      <ExpandMoreIcon
                        fontSize="small"
                        style={{
                          transform:
                            expanded === "panel1"
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          transition:
                            "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Hidden>
            }

            {
              <Hidden smUp>
                <Grid
                  item
                  container
                  xs={12}
                  sm={3}
                  md={3}
                  justify="space-between"
                >
                  <Grid
                    item
                    xs={6}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <CustomChip
                      color="primary"
                      label={`Based on ${exceptionData.based_on}`}
                    />
                  </Grid>
                  <Grid item container xs={6} justify="flex-end">
                    <Grid item>
                      {!props.hideEdit && (
                        <IconButton
                          classes={{ root: classes.customActionButtons }}
                          onClick={(e) => handleEdit(e, props?.exceptionId)}
                        >
                          <CreateOutlinedIcon
                            fontSize="small"
                            htmlColor={props.theme.palette.ternary.main}
                          />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid item>
                      {!props.hideDelete && (
                        <IconButton
                          classes={{ root: classes.customActionButtons }}
                          style={{ margin: "0px 8px" }}
                          onClick={(e) => handleDelete(e, props?.exceptionId)}
                        >
                          <DeleteOutlinedIcon
                            fontSize="small"
                            htmlColor={props.theme.palette.ternary.main}
                          />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid item>
                      <IconButton
                        classes={{ root: classes.customActionButtons }}
                      >
                        {/* {expanded === 'panel1' ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />} */}
                        <ExpandMoreIcon
                          fontSize="small"
                          style={{
                            transform:
                              expanded === "panel1"
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            transition:
                              "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                          }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
            }
          </Grid>
        </CustomAccordionSummary>

        <CustomAccordionDetails>
          {/* TODO: While mapping apply class ```detailSection``` to the grid items except the first one */}
          {/* <Grid container>
            {Object.keys(exceptionData?.data).map((ele, index) => (
              <Grid item xs={12} key={index} className={classes.detailSection} >
                <Grid item xs={12}>
                  <Typography color="textSecondary" style={{ fontSize: "0.8rem" }}>{exceptionData?.data[ele]?.label ? exceptionData?.data[ele]?.label : null}</Typography>
                </Grid>
                <Grid item container xs={12} spacing={1} style={{ marginTop: 5 }}>
                  {exceptionData?.data[ele]?.value?.map((name, index) => (
                    <Grid item>
                      <CustomChip label={name} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
         */}
          <div className={classes.exceptionRoot}>
            {weekDays.map((days) => {
              let isShowWeek = exceptionData?.operationalHours
                ?.map((val) => val?.days)
                .flat();
              
              if (isShowWeek.includes(days)) {
                return (
                  <Grid
                    item
                    container
                    alignItems="center"
                    className={`${isSmDown && classes.dayRow} ${
                      classes.dayRowMargin
                    }`}
                  >
                    <Grid item>
                      <Calendar htmlColor="#28a9c1" style={calendarStyle} />
                    </Grid>
                    <Grid item xs>
                      <Typography className={classes.days}>
                        {/* {slot?.days?.join(", ")} */}
                        {/* {getFormattedDays(slot)} */}
                        {capitalizeFirstLetter(days)}
                      </Typography>
                    </Grid>
                    <Grid item xs={isBelow500 ? 12 : true}>
                      {isBelow500 && (
                        <Calendar
                          htmlColor="#28a9c1"
                          style={{ ...calendarStyle, opacity: 0 }}
                        />
                      )}
                      {exceptionData?.operationalHours?.map((val) => {
                        if (val?.days?.includes(days)) {
                          return (
                            <Typography
                              style={{ paddingLeft: isBelow500 ? 8 : 16 }}
                            >
                              {/* {getFormattedTime(slot)} */}
                              {getFormattedTime(val)}
                            </Typography>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Grid>
                  </Grid>
                );
              } else {
                return null;
              }
            })}
          </div>
      
        </CustomAccordionDetails>
      </CustomAccordion>
  :
  <div className={classes.exceptionWithoutAccordianRoot}>
  {weekDays.map((days) => {
    let isShowWeek = exceptionData?.operationalHours
      ?.map((val) => val?.days)
      .flat();
    
    if (isShowWeek.includes(days)) {
      return (
        <Grid
          item
          container
          alignItems="center"
          className={`${isSmDown && classes.dayRow} ${
            classes.dayRowMargin
          }`}
        >
          <Grid item>
            <Calendar htmlColor="#28a9c1" style={calendarStyle} />
          </Grid>
          <Grid item xs>
            <Typography className={classes.days}>
              {/* {slot?.days?.join(", ")} */}
              {/* {getFormattedDays(slot)} */}
              {capitalizeFirstLetter(days)}
            </Typography>
          </Grid>
          <Grid item xs={isBelow500 ? 12 : true}>
            {isBelow500 && (
              <Calendar
                htmlColor="#28a9c1"
                style={{ ...calendarStyle, opacity: 0 }}
              />
            )}
            {exceptionData?.operationalHours?.map((val) => {
              if (val?.days?.includes(days)) {
                return (
                  <Typography
                    style={{ paddingLeft: isBelow500 ? 8 : 16 }}
                  >
                    {/* {getFormattedTime(slot)} */}
                    {getFormattedTime(val)}
                  </Typography>
                );
              } else {
                return null;
              }
            })}
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  })}
</div>

  }
    </div>
  );
}

export default withTheme(ExceptionAccordion);

ExceptionAccordion.propTypes = {
  /**Handles the edit button of the accordion. */
  handleEdit: PropTypes.func,
  /**Handles the delete button of the accordion. */
  handleDelete: PropTypes.func,
};
