import React from "react";
import ReportComponent from "./report";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "tanyacare-middleware";
import { OTHER_ROLES, user_roles } from "../../utils";

const { GET_TRANSACTION_REPORT } = actions;

const TransactionReport = () => {
  const ref = React.useRef(null);
  const [fromDate, setFrom] = React.useState();
  const [toDate, setTo] = React.useState();
  const [applied, setApplied] = React.useState(false);

  const dispatch = useDispatch();

  const getData = useSelector(
    (state) => state?.reportManagementSlice?.getTransation
  );

  const authData = useSelector((state) => state?.authState?.loginAuth?.data);

  const isManagedCareProviderAdmin = authData?.other_roles?.includes(
    OTHER_ROLES.crossBundling
  );

  const isBusinessAdmin = authData?.user_role === user_roles.business_admin;
  const isSuperAdmin = authData?.user_role === user_roles.super_admin;

  // const isProviderAdminExceptMC =
  //   authData?.user_role === user_roles.provider && !isManagedCareProviderAdmin;

  const type = {
    // "#Seq.No": {
    //   type: "number"
    // },
    "Parent ID": {
      type: "string",
    },
    "Partner Name": {
      type: "string",
    },
    "Partner Type": {
      type: "string",
    },
    "Partner ID": {
      type: "string",
    },
    Country: {
      type: "string",
    },
    Region: {
      type: "string",
    },
    State: {
      type: "string",
    },
    Industry: {
      type: "string",
    },
    "Business Nature": {
      type: "string",
    },
    "Service ID": {
      type: "string",
    },
    "Service Name": {
      type: "string",
    },
    "Service Category": {
      type: "string",
    },
    "Service Type": {
      type: "string",
    },
    "Service Group": {
      type: "string",
    },
    "Unit Price": {
      type: "number",
    },
    Quantity: {
      type: "string",
    },
    "Invoice Date": {
      type: "date string",
    },
    "Invoice No": {
      type: "string",
    },
    "Payment Date": {
      type: "date string",
    },
    "Payment Mode": {
      type: "string",
    },
    "Receipt No.": {
      type: "string",
    },
    "Order ID": {
      type: "string"
    },
    "Payment Status": {
      type: "string",
    },
    "Payment Service Provider": {
      type: "string",
    },
    "Transaction ID": {
      type: "string",
    },
    "Transaction Type": {
      type: "string",
    },
    Currency: {
      type: "string",
    },
    "Gross Amount": {
      type: "number",
    },
    Discount: {
      type: "number",
    },
    "Net Amount (before tax)": {
      type: "number",
    },
    Tax: {
      type: "number",
    },
    "Total Amount": {
      type: "number",
    },
    "Payment Gateway Fees": {
      type: "number",
    },
    "Payment Method": {
      type: "string",
    },

    // Show Ainqa Care Settlement & Provider Settlement for Business Admin and Super Admin
    ...(isBusinessAdmin || isSuperAdmin
      ? {
          "Ainqa Care Settlement": {
            type: "number",
          },
          "Provider Settlement": {
            type: "number",
          },
        }
      : {}),

      // Show Provider Settlement for MC Admin 
    ...(isManagedCareProviderAdmin
      ? {
          "Provider Settlement": {
            type: "number",
          },
        }
      : {}),

    // Show "Ainqa Care Settlement" only for Managed Care Provider Admin
    // ...(isManagedCareProviderAdmin
    //   ? {
    //       "Ainqa Care Settlement": {
    //         type: "number",
    //       },
    //     }
    //   : {}),

    // Show "Provider Settlement" only for Provider Admin except MC
    // ...(isProviderAdminExceptMC
    //   ? {
    //       "Ainqa Care Settlement": {
    //         type: "number",
    //       },
    //       "Provider Settlement": {
    //         type: "number",
    //       },
    //     }
    //   : {}),
    "Grand Total": {
      type: "number",
    },
    "Client ID": {
      type: "string",
    },
    "Client Name": {
      type: "string",
    },
  };

  const fetchData = () => {
    dispatch(
      GET_TRANSACTION_REPORT({
        fromDate: moment(fromDate).startOf("day").toISOString(),
        toDate: moment(toDate).endOf("day").toISOString(),
      })
    );
  };
  return (
    <ReportComponent
      type={type}
      getData={getData}
      fromDate={fromDate}
      toDate={toDate}
      fetchData={fetchData}
      applied={applied}
      setApplied={setApplied}
      setTo={setTo}
      setFrom={setFrom}
      tableRef={ref}
    />
  );
};
export default TransactionReport;
