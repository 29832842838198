import React, { Component } from "react";
import { Typography, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const color = "rgba(255, 165, 17, 0.15)";

const styles = (theme) => ({
  root: (props) => ({
    padding: theme.spacing(props.padding) || theme.spacing(1.5),
    borderRadius: 8,
    display: "flex",
  }),
  itemContainer: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: color,
    maxWidth: "100%",
    borderRadius: 8,
    width: "100%",
  },
  checkIn: {
    flex: 1,
    fontWeight: 400,
    background: color,
    maxWidth: "56%",
    width: "56%",
    height: 70,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 8,
    [theme.breakpoints.down("xs")]: {
      clipPath: "polygon(85% 0, 100% 50%, 85% 100%, 0 100%, 0 0)",
    },
    [theme.breakpoints.up("sm")]: {
      clipPath: "polygon(85% 0, 100% 50%, 85% 100%, 0 100%, 0 0)",
    },
    [theme.breakpoints.up("md")]: {
      clipPath: "polygon(85% 0, 100% 50%, 85% 100%, 0 100%, 0 0)",
    },
    [theme.breakpoints.up("lg")]: {
      //   clipPath: "polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%)",
      clipPath: "polygon(85% 0, 100% 50%, 85% 100%, 0 100%, 0 0)",
    },
  },
  checkOut: {
    flex: 1,
    fontWeight: 400,
    background: color,
    maxWidth: "56%",
    width: "56%",
    height: 70,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 8,
    [theme.breakpoints.down("xs")]: {
      clipPath: "polygon(100% 0, 100% 100%, 0 100%, 15% 50%, 0 0)",
    },
    [theme.breakpoints.up("sm")]: {
      clipPath: "polygon(100% 0, 100% 100%, 0 100%, 15% 50%, 0 0)",
    },
    [theme.breakpoints.up("md")]: {
      clipPath: "polygon(100% 0, 100% 100%, 0 100%, 15% 50%, 0 0)",
    },
    [theme.breakpoints.up("lg")]: {
      //   clipPath: "polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%)",
      clipPath: "polygon(100% 0, 100% 100%, 0 100%, 15% 50%, 0 0)",
    },
  },
  //   checkOut: {
  //     fontWeight: 400,
  //     maxWidth: "44%",
  //     width: "44%",
  //     display: "flex",
  //     justifyContent: "center",
  //     flexDirection: "column",
  //     alignItems: "center",
  //     borderRadius: 8,
  //   },
  checkInText: {
    color: theme.palette.ternary.main,
    fontWeight: 400,
  },
  checkOutText: {
    color: theme.palette.ternary.main,
    fontWeight: 400,
  },
  timeText: {
    fontWeight: 400,
  }
});
// clip-path: polygon(65% 0, 100% 50%, 64% 100%, 0 100%, 0 0);

class CheckInCheckOutCard extends Component {
  render() {
    const { classes } = this.props;
    const { leftText, leftSubText, rightText, rightSubText } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.checkIn}>
          <Typography className={classes.checkInText}>
            {leftText ? leftText : "N/A"}
          </Typography>
          <Typography variant="body2" className={classes.timeText}>
            {leftSubText ? leftSubText : ""}
          </Typography>
        </div>
        <div className={classes.checkOut}>
          <Typography className={classes.checkOutText}>
            {rightText ? rightText : "N/A"}
          </Typography>
          <Typography variant="body2" className={classes.timeText}>
            {rightSubText ? rightSubText : ""}
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CheckInCheckOutCard);

CheckInCheckOutCard.propTypes = {
  /** Sets the left pane main text, Which has variant ```body1``` */
  leftText: PropTypes.string,
  /** Sets the left pane sub text, Which has variant ```body2``` */
  leftSubText: PropTypes.string,
  /** Sets the right pane main text, Which has variant ```body1``` */
  rightText: PropTypes.string,
  /** Sets the right pane sub text, Which has variant ```body2``` */
  rightSubText: PropTypes.string,
  /**Set padding for CheckIn CheckOut card, example ```padding={2} will transform to 2*8px = 16px```. Consider this while setting the padding */
  padding: PropTypes.number,
};
