import React from "react";
import {
  // Button,
  Grid,
  Typography,
  Container,
//   TextField,
//   IconButton,
//   FormControlLabel,
//   Checkbox,
//   Hidden
} from "@material-ui/core";
import {  withRouter } from "react-router-dom";
// import { routes } from "../../router/routes";
import { withStyles } from "@material-ui/core/styles";
// import { styles } from "./style";

// import { NavLink } from "react-router-dom";
// import Card from "@material-ui/core/Card";

// SubScreenComponents
// import HeaderLogo from "../../components/login/headerLogo";
import HeaderLogo from "./headerLogo"
import Footer from "./Footoer"
// import LeftPane from "../../components/login/LeftPane";
// import LeftPane from "../login/leftPane";

import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { AuthContext } from "../../contexts";
// import { alertProps, localStorageKeys } from "../../utils";

// // validation - utils

// import {
//   minCheck,
//   minNumberAndSymbolCheck,
//   charCaseCheck
// } from "../../utils/validations";

// // Icons
// import DoneIcon from "@material-ui/icons/Done";
// import CloseIcon from "@material-ui/icons/Close";
// import VisibilityIcon from "@material-ui/icons/Visibility";
// import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// import { errorMessage } from "../../utils/errorMessages";
import { withAlertAndDialog } from "../../HOCs";
import { white } from "material-ui/styles/colors";
import IconButton from '@material-ui/core/IconButton';
import  ArrowBack  from "@material-ui/icons/ArrowBack";


const styles = (theme) => ({
  
    
  wrapper: {
    height: "100%",
    background: "white"
  },
  root: {
    height: "100%"
  },
  hero: {
    height: "85%"
  },
  rightPaneWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
        padding: "0.25rem",
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    paddingBottom: "2rem",
    paddingLeft: "24px",
    paddingRight: "24px",
    // alignItems: "center"
  },
  rightPane: {
    width: "100%"
  },
  mainHeading: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 4
    },
    marginTop: 68,
    fontSize: "1.5rem",
    fontWeight: "bold"
  },
  subHeading: {
    fontSize: "1rem",
    color: theme.palette.secondary.main
  },
  passwordField: {
    width: "100%",
    marginTop: 32
  },
  //   passwordField: {
  //     width: "100%",
  //     marginTop: 24
  //   },
  errorMessage: {
    color: `${theme.palette.error.main}`
  },
  resetButton: {
    marginTop: 32,
    padding: 8,
    "&:hover": {
      transition: "0.5s",
      background: `${theme.palette.primary.main}`,
      opacity: 0.9
    }
  },
  validationListContainer: {
    listStyle: "none",
    textAlign: "left",
    paddingLeft: 0
  },
  validationListItemStartIcon: {
    display: "inline",
    margin: "0px 3px"
  },
  
  // Custom Header Style
  header: {
    height: "10%",
    // height: 44,
    [theme.breakpoints.down("sm")]: {
      // height: 60,
      textAlign: "center",
      // marginTop: 32
    }
  },
  list:{
      paddingLeft:"1.5rem",
      paddingBottom:5,
      paddingTop:5,
      fontSize:18,
      [theme.breakpoints.down("sm")]: {
        paddingLeft:"0.5rem",
      }
  },
  listText:{
    marginLeft:"1.5rem",
    paddingBottom:5,
    paddingTop:5,
    fontSize:18,
    [theme.breakpoints.down("sm")]: {
        marginLeft:"0.5rem",
    }
  },
  innerlist:{
    paddingLeft:"3rem",
    paddingBottom:5,
    paddingTop:5,
    fontSize:18,
    [theme.breakpoints.down("sm")]: {
      paddingLeft:"1rem",
    }
  },
  innerText:{
    marginLeft:"3rem",
    paddingBottom:5,
    paddingTop:5,
    fontSize:18,
    [theme.breakpoints.down("sm")]: {
        marginLeft:"1rem",
    }
  },
  innerinnerlist:{
    paddingLeft:"4.5rem",
    paddingBottom:5,
    paddingTop:5,
    fontSize:18,
    [theme.breakpoints.down("sm")]: {
      paddingLeft:"1.5rem",
    }
  },
  ul:{
    paddingLeft:"2rem",
    fontSize:18,
      [theme.breakpoints.down("sm")]: {
        paddingLeft:"0rem",
      }
  },
  alphanumber:{
    listStyle: "none",
    fontWeight: "bold",
    paddingTop: "1rem",
    fontSize:18,
},
bulletpoints:{
  marginLeft:25,
  [theme.breakpoints.down("sm")]: {
    marginLeft: 10,
  },
},
backButton:{
  // marginTop: 0,
  // marginBottom:"1rem",
  // marginTop: "1rem",
  // width:"10%",
  // background:"#74b1b5",
  // color:"black",
  // background:"white",
  // color:"#74b1b5",
  [theme.breakpoints.down("sm")]: {
    // width:"35%",
    marginBottom: "0.25rem"
  },
  [theme.hover]:{
    background:"#74b1b5",
    color:"white",
  },
  position:"sticky", 
  top:10, 
  // marginLeft:"auto"
},
navType:{
  position: "sticky",
  top: 1,
  background: white
},

});

class PrivacyPolicy extends React.Component {
  


  render() {
    // const { username, password, passwordVisibility } = this.state;
    // const { classes } = this.props;

    // const { loading, error, message } = this.props?.loginState;
    const { classes } = this.props;
    // console.log(this.props.history)

    return (
      <>
      <Container maxWidth="lg" className={classes.wrapper}>
        <div className={classes.root}>
          {/* <Hidden smDown> */}
            <HeaderLogo />
          {/* </Hidden> */}
          <Grid container alignItems={"stretch"} className={classes.hero}>
            {/* <Hidden smDown>

              <LeftPane />
            </Hidden> */}
            
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.rightPaneWrapper}
            >
              <div className={classes.navType}>
                <Grid container>
                  <Grid item xs={2} sm={2} md={6} lg={5} xl={5}>
                <IconButton color="primary" variant="contained" className={classes.backButton}  id="sendCodeButton" disableElevation onClick={() => this.props.history.goBack()}>
                  <ArrowBack/>
                  
                </IconButton>
                </Grid>
                <Grid item xs={10} sm={10} md={6} lg={7} xl={7}>
                <div textAlign="center" style={{padding:12}}>
                    <Typography variant="h5">Privacy Policy</Typography>
                  </div>
                  </Grid>
                </Grid>
                
                </div>
                
                <div className={classes.ul}>
                    <div className={classes.alphanumber}>A. GENERAL</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> All defined terms in tanya.care Terms of Use shall have the same meaning in this Privacy Policy unless otherwise expressly stated otherwise here. This Privacy Policy which shall be read and construed as part of the Terms.</div>
                            <div className={classes.list}><b>2.</b> We are Ainqa Care Sdn Bhd (Company No. 202001019330 (1375650-U) and its subsidiary, holding, associate and related companies. Further information can be found here</div>
                            <div className={classes.list}><b>3.</b> We collect, store, use and disclose your Personal Data through the use of the Platform, the Application and the Service, which is any information or data, whether true or not, about an individual or any entity which can be used to identify you or from which you are identifiable including but not limited to your name, nationality, telephone number, identity card or passport number, entity name and number, address, bank and credit card details, health information, medical information, biometric data, race, date of birth, marital status, religion, vehicle and insurance information, employment information, financial information as well as your next of kin and emergency contact details, which the Company has or is likely to have access whether stored in electronic or other form.</div>
                            <div className={classes.list}><b>4.</b> Please note that the Platform, Application and Service may contain links to other websites provided and maintained exclusively by third parties which are and shall not be subject to this Privacy Policy. Please review the privacy policies on any third party websites to determine their information handling policies. We shall not be liable under any circumstances for any damages, direct or indirect, resulting from any use of the Service or any third party website linked to the Platform, Application or Service.</div>
                            <div className={classes.list}><b>5.</b> This Privacy Policy applies to Third Party Providers and End-Users (collectively, “you”, “your” or “yours”).</div>
                            <div className={classes.list}><b>6.</b> We are committed to ensuring that your personal information is kept private and confidential. Any data stored will be encrypted with the highest encryption (AES-256). If you have any additional concerns or questions, you are encouraged to contact us and we will address your concerns to the best of its ability. If you have any queries, please address them to datapolicy@tanya.care</div>
                            <div className={classes.list}><b>7.</b> Please read this Privacy Policy carefully and check back periodically for updates and modification. We reserve the right to update, vary, modify, add and remove terms and conditions contained in this Privacy Policy.</div>
                        </div>
                    <div class={classes.alphanumber}>B. COLLECTION AND USE OF PERSONAL DATA</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> We collect Personal Data from you in the ways listed below. We may combine the collected Personal Data with other Personal Data in our possession. If you have or are a party to multiple relationships with us (for example if you use our Service across multiple business verticals or if you are both a Third Party Provider and an End-User), we will link your Personal Data collected across your various capacities to facilitate your use of our Service and for the Purpose described below.</div>
                            <div className={classes.list}><b>2.</b> We collect Personal Data as follows which you provide to us voluntarily in the following scenarios:-</div>
                                <ul className={classes.bulletpoints}>
                                    <li>when you register and complete a Third Party Provider profile or an End-User profile. This information is stored, collected, used and disclosed to facilitate your use of the Service, the Platform and the Application.</li>
                                    <li>when you complete a transaction, your complete transaction information such as payment information, product purchase, service utilisation. We collect inter alia credit card and bank account information as needed to process payments for products and services made available on the Platform and the Application as part of the Service. </li>
                                    <li>when you contact the helpdesk for assistance, we may request for personal health information to facilitate in aiding you.</li>
                                    <li>your purchase history information in order to gain a better understanding of your needs and interest so that we may develop and recommend suitable products and services and to determine your eligibility for certain products and services.</li>
                                    <li>if you participate in any contests or promotional activities, we collect your information to administer the contest or promotions including for the purposes of contacting, announcing and promoting participants and winners.</li>
                                    <li>for purposes of verification of your identity through login details, passwords, images which may include personal pictures.</li>
                                    <li>for the purposes of improving the Service, information will be obtained pertaining to qualifications, products quality, warranties, review of products and services provided, customer service and support.</li>
                                    <li>when you choose to opt out of any use of Personal Data, we will keep your contact information for regulatory compliance purposes.</li>
                                    <li>when you fill up demographic details through surveys or any other information you may volunteer on our request from time to time.</li>
                                    <li>from your interaction with our social media pages such as your social media account ID, profile photo any other publicly available data.</li>
                                    <li>If you are a Third Party Provider, we collect your personal and/or business identification details for tax reporting purposes and your contract information and financial information including credit history in order to pay you through our third party payment processor, for us to assess credit risk, and sales data to prepare revenue sharing reports and other information in evaluating our Services.</li>
                                    <li>additional purposes for the collection, use or disclosure of Personal Data will be set out at the time of collection. We will obtain your consent to such additional purposes no later than at the time of such collection.</li>
                                </ul>
                            <div className={classes.list}><b>3.</b> Personal Data may be collected through the normal operation of the Platform, the Application or the Service for example:</div>
                                <ul className={classes.bulletpoints}>
                                    <li>your location (for the provision of services or delivery of products)</li>
                                    <li>your health and fitness data (for access to health and wellness products and services)</li>
                                    <li>your medical information (for access to purchase products and services)</li>
                                    <li>information about how you interact with the Platform, the Application and the Service (such as features used and content viewed)</li>
                                    <li>device information (such as hardware model and serial number, IP addresses, file names and versions and advertising identifiers or any information that may provide indication of device or app modification)</li>
                                    <li>Personal Data you enter in messages when you use or in-app communication features;</li>
                                    <li>Personal Data that may be captured through your interaction with us, with End-Users or Third Party Providers</li>
                                </ul>
                            <div className={classes.list}><b>4.</b> In certain circumstances you may be required to provide Personal Data to comply with legal requirements or contractual obligations. We reserve the right not to contract with you if your failure to provide Personal Data is in breach of legal requirements or contractual obligations as the case may be</div>
                            <div className={classes.list}><b>5.</b> When we collect Personal Data from other sources we make sure that such Personal Data is transferred in accordance with applicable laws. Such other sources include:-</div>
                                <ul className={classes.bulletpoints}>
                                    <li>Third Party Providers</li>
                                    <li>Insurance and Financial Providers</li>
                                    <li>Credit Bureaus and Credit Reporting Agencies</li>
                                    <li>Governmental sources of data</li>
                                    <li>When users add you as an emergency contact</li>
                                    <li>Marketing Services providers and partners</li>
                                </ul>
                            <div className={classes.list}><b>6.</b> As a parent or legal guardian, please do not allow minors under your care to submit Personal Data to us. In the event any Personal Data relating to a minor is submitted to us, you hereby consent to the processing of the minor’s  Personal Data and accept and agree to be bound by this Policy and take responsibility for his or her actions.</div>
                            <div className={classes.list}><b>7.</b> We may place advertising or other links on the Platform, the Application or the Service that originate from external advertisers. If you view these advertisements, the advertisers may assign a cookie. We do not control these cookies, nor is it responsible for any marketing or other use of your Personal Data by these advertisers</div>
                            <div className={classes.list}><b>8.</b> Your Personal Data will be used to provide, personalise, maintain and improve our Platform, Application and Service. This includes using your Personal Data to;</div>
                                <ul className={classes.bulletpoints}>
                                    <li>Provide you with the Service across our various business verticals</li>
                                    <li>Engage you to provide products and services</li>
                                    <li>Create, Administer and Update your Account </li>
                                    <li>Conduct Due Diligence Checks</li>
                                    <li>Verify your Identity</li>
                                    <li>Verify your Age (where necessary)</li>
                                    <li>Validate your usage and process payments</li>
                                    <li>Offer, obtain, provide, facilitate or maintain insurance or financing solutions</li>
                                    <li>Track progress of the provision of services and products</li>
                                    <li>Enable features that personalise your Application such as favourite products or services utilised</li>
                                    <li>Make your experience seamless such as automatically filling your registration information from one service to another</li>
                                    <li>Perform internal operations necessary to provide our Service, including troubleshooting software bugs and operational problems, conducting data analysis, testing and research, monitoring and analysing usage and activity trends;</li>
                                    <li>Protect the security or integrity of the Service and any facilities or equipment used to make the Service available</li>
                                    <li>Process, manage or verify your application for promotions, rewards and subscriptions</li>
                                    <li>Enable Third Party Providers to manage and allocate the delivery of their products and services</li>
                                    <li>Fulfil the services to you as a data processor, where you have provided consent to the data controller (i.e. the organisation you have purchased goods or service from and for whom the Company is providing services on behalf of) for such products and services to be rendered or sold</li>
                                </ul>
                            <div className={classes.list}><b>9.</b> We may use your Personal Data to resolve customer support issues. For example:</div>
                                <ul className={classes.bulletpoints}>
                                    <li>We may identify and address concerns</li>
                                    <li>Monitor and improve our customer support responses</li>
                                    <li>Respond to questions, comments and feedback</li>
                                    <li>Inform you about steps taken to resolve customer support issues</li>
                                </ul>
                            <div className={classes.list}><b>10.</b> We may use Personal Data collected to improve and enhance the safety and security of the Service, develop new features, products and services and facilitate insurance and finance solutions</div>
                            <div className={classes.list}><b>11.</b> We may use Personal Data collected to investigate and resolve complaints, criminal investigations, civil claims or disputes or as allowed or required by applicable laws.</div>
                            <div className={classes.list}><b>12.</b> We may also use your Personal Data when we are required, advised, recommended, expected or requested to do so by our legal advisers or any local or foreign legal, regulatory, governmental or other authority. For example:</div>
                                <ul className={classes.bulletpoints}>
                                    <li>To comply with court orders or other legal, governmental or regulatory authority directives</li>
                                    <li>Enforce our Terms or other agreements</li>
                                    <li>Protect our rights or property in the event of a claim or dispute</li>
                                </ul>
                            <div className={classes.list}><b>13.</b> We may also use your Personal Data in connection with mergers, acquisitions, valuations, joint ventures, sale of company assets, consolidation, restructuring, financing, business asset transactions or acquisition of all or part of our business by another company.</div>
                            <div className={classes.list}><b>14.</b> We may use your Personal Data to market the Services, any products and services provided as part of the Service, Third Party Provider’s promotional activities, sponsors, advertisers, events or promotions. For example</div>
                                <ul className={classes.bulletpoints}>
                                    <li>We may send you email alerts, newsletters, updates, mailers, promotional materials, special privileges, festive greetings; and</li>
                                    <li>Notify, invite and manage your participation in our events and activities</li>
                                </ul>
                            <div className={classes.list}><b>15.</b> We may communicate such marketing to you by post, telephone call, short message service, online messaging service, push notification by hand and by email</div>
                        </div>
                    <div class={classes.alphanumber}>C. DISCLOSURE OF PERSONAL DATA</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> We need to share your Personal Data to our affiliate companies, Third Party Providers, marketing partners, research firms, other service providers, sponsors, advertisers and business partners. This includes:</div>
                                <ul className={classes.bulletpoints}>
                                    <li>Payment processors and facilitators</li>
                                    <li>Debt collectors</li>
                                    <li>Credit bureaus and other credit reporting agencies</li>
                                    <li>Background check and anti-money laundering agencies</li>
                                    <li>Cloud storage providers</li>
                                    <li>Marketing partners and marketing platform providers</li>
                                    <li>Data analytics providers</li>
                                    <li>Research partners, including those performing surgeys or research project in partnership with the Company and any of its affiliate companies or on the Company’s behalf</li>
                                    <li>Third Party Providers</li>
                                    <li>Insurance and financing partners</li>
                                    <li>Third party intermediaries involved in the management of investment funds, brokers, fund managers, funds, asset managers and custodians</li>
                                    <li>Service providers who perform identity veirification services</li>
                                    <li>Healthcare solution providers, vendors or third party suppliers</li>
                                </ul>
                            <div className={classes.list}><b>2.</b> We may share your Personal Data with our legal advisers, law enforcement officials, government authorities, tax authorities and other third parties. This may take place to fulfil legal purposes or any of the following circumstances (not exhaustive)</div>
                                <ul className={classes.bulletpoints}>
                                    <li>Where it is necessary to respond to an emergency that threatens the life, health or safety of a person; or</li>
                                    <li>Where it is necessary in the public interest</li>
                                    <li>Where it is required to enforce or defend our rights and avail ourselves of any remedies under applicable laws or equity</li>
                                </ul>
                            <div className={classes.list}><b>3.</b> We may disclose your Personal Data to parties connected with the contemplated or actual financing, insuring, sale, assignment or other disposal of all or part of our business or assets, including for the purposes of determining whether to proceed or continue with such transaction or business relationship or fulfilling any records or reporting requirements to such parties. We will require such parties to treat your Personal Data in accordance with this Privacy Policy and to use and disclose it only for the purposes for which it is collected.</div>
                        </div>
                    <div class={classes.alphanumber}>D. RETENTION OF PERSONAL DATA</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> We retain your Personal Data for the period necessary to fulfil the purposes set out in this Privacy Policy unless a longer period is required by law. Once your Personal Data is no longer necessary for the Platform, the Application or the Service or any purpose set out in this Privacy Policy, or we no longer have a legal or business obligation to retain your Personal Data, we take steps to erase, destroy, anonymise or prevent access or use of such Personal Data for any purpose other than compliance with this Policy or for the purposes of safety, security, fraud prevention and detection, in accordance with the requirements of applicable laws.</div>
                        </div>
                    <div class={classes.alphanumber}>E. INTERNATIONAL TRANSFERS OF PERSONAL DATA</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> Your Personal Data may be transferred and stored from the country, state and city  (“Home Country”) in which you are located while using the Service to another country, state and city (“Foreign Country”).</div>
                            <div className={classes.list}><b>2.</b> When we transfer your Personal Data from your Home Country to a Foreign Country, we will comply with our legal and regulatory obligations in relation to your Personal Data including having a lawful basis for transferring Personal Data and putting appropriate safeguards in place to ensure an adequate level of protection for Personal Data. We will also ensure that the recipient Foreign Country is obliged to protect your Personal Data at a standard comparable to the protection under applicable laws.</div>
                            <div className={classes.list}><b>3.</b> Our lawful basis will be either consent (obtaining your consent to transfer your Personal Data from the Home Country to a Foreign Country at the time you provide your Personal Data) or one of the safeguards permissible by laws.</div>
                        </div>
                    <div class={classes.alphanumber}>F. COOKIES AND ADVERTISING ON THIRD PARTY SOLUTIONS</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> We may use cookies, web beacons, tags, scripts, local shared objects such as HTML5 and Flash (sometimes called “flash cookies”), advertising identifiers (including mobile identifiers such as Apple’s IDFA or Google’s Advertising ID and other similar technologies for storing information to help provide you with a better, faster, safer and personalised experience when you use the Platform, the Application and the Service (“Cookies”). When you visit the Platform or the Application, our servers will automatically record information that your browser sends whenever you visit a website. This data may include:</div>
                                <ul className={classes.bulletpoints}>
                                    <li>Your computer’s IP address</li>
                                    <li>Browser type</li>
                                    <li>Webpage you were visiting before you came to the Platform or the Application</li>
                                    <li>The pages withing the Platform or the Application which you visit</li>
                                    <li>The time spent on those pages, items and information search for on the Platform or the Application, access times and dates and other statistics</li>
                                    <li>Search preferences</li>
                                    <li>Data relating to advertisements that have been displayed to you or that you have clicked on</li>
                                    <li>Date and time of your use of the Service</li>
                                </ul>
                            <div className={classes.list}><b>2.</b> Cookies may have unique identifiers, and reside, among other places, on your computer or mobile device, in emails we send you and on our web pages.  Cookies may transmit Personal Data about you and your use of the Service such as set out in F1 above. Cookies may be persistent or stored only during an individual session.</div>
                            <div className={classes.list}><b>3.</b> We may allow third parties to use Cookies on the Platform and the Application to collect the same type of Personal Data for the same purposes as we do. Third parties may be able to associate the Personal Data they collect with other Personal Data they may have about you from other sources. We do not necessarily have access to or control over the Cookies they use.</div>
                            <div className={classes.list}><b>4.</b> We may also share non-personally identifiable Personal Data with third parties, such as location data, advertising identifiers or a cryptographic hash of a common account identifier (such as an email address), to facilitate the display of targeted advertising on third party platforms</div>
                            <div className={classes.list}><b>5.</b> If you are concerned about Cookies you may disable them by adjusting the privacy and security settings of your device or browser, to block or deactivate cookie, by deleting your browser history and clearing the cache from your internet browser. You may also limit our sharing of some of this Personal Data through the Platform or App. However, please be aware that by disabling cookies, you may not be to use all portions of the Platform, the Application or the Service and may not have optimal user experience.</div>
                        </div>
                    <div class={classes.alphanumber}>G. PROTECTION OF PERSONAL DATA</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> We maintain your Personal Data in a combination of paper and electronic files. We take reasonable legal, organisational, contractual, administrative, physical and technical measures to ensure that your Personal Data is protected.</div>
                            <div className={classes.list}><b>2.</b> Protection includes measures to prevent Personal Data from getting lost or accessed in an unauthorised way. We limit access to your Personal Data to our employees on a need to know basis. Those processing your Personal Data will only do so in an authorised manner and are required to treat your information with confidentiality.</div>
                            <div className={classes.list}><b>3.</b> Note however, that the transmission of information via the internet is not completely secure and could be intercepted. While we do our best to protect your Personal Data by anonymising it, we cannot guarantee the security of your Personal Data transmitted through any online means, therefore, any transmission remains at your risk.</div>
                            <div className={classes.list}><b>4.</b> If your communication is very sensitive, or includes information such as your credit card number and/or bank account information, you should not send it electronically unless the email is encrypted or the access to the Platform or the Application is secure. Materials posted to online forums such as bulletin boards or chat rooms are public, not secure and may be viewed by anyone. Any personal information you post may be collected and used by anyone and may result in unsolicited messages from other internet users</div>
                            <div className={classes.list}><b>5.</b> In accordance with applicable laws, you may be entitled to:</div>
                                <ul className={classes.bulletpoints}>
                                    <li>Ask us about processing of your Personal Data, including to be provided with a copy of your Personal Data</li>
                                    <li>Request the correction and/or (in some cases) the deletion of your Personal Data</li>
                                    <li>In some cases, request that restriction of your Personal Data (where we are processing your Personal Data based on your consent)</li>
                                    <li>Request receipt or transmission to another organisation, in a machine readable form, of the Personal Data that you have provided to us where we are using your Personal Data that you have provided to us where we are using your Personal Data based on consent or performance of a contract</li>
                                    <li>    • Complain to the relevant data privacy authority if your data privacy rights are violated, or if you have suffered as a result of unlawful processing of your Personal Data.</li>
                                </ul>
                            <div className={classes.list}><b>6.</b> Where you are given an option to share your Personal Data with us, you can always choose not to do so. If we have requested your consent to processing and you later choose to withdraw it, we will respect that choice in accordance with our legal obligations.</div>
                            <div className={classes.list}><b>7.</b> Note that choosing not to share your Personal Data with us or withdrawing your consent could mean that we are unable to perform certain actions necessary to achieve the purposes of processing set out in B above or you may not be able to use the Service. After you have withdrawn your consent, we may be able to continue to process your Personal Data to the extent required or otherwise permitted by applicable laws and regulations.</div>
                            <div className={classes.list}><b>8.</b> If you wish to request to exercise your rights, you can contact us through our contact details set out in Section I (CONTACT US) below.</div>
                            <div className={classes.list}><b>9.</b> We will screen and verify all requests beforehand. In order to verify your authority to make a request, we may require you to provide supporting information or documentation to corroborate your request.</div>
                            <div className={classes.list}><b>10.</b> Once verified, we will give effect to your request within the timelines prescribed by applicable laws.</div>
                        </div>
                    <div class={classes.alphanumber}>H. AMENDMENTS AND UPDATES</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> We shall have the right to modify, update, vary, add and remove any terms of this Policy at any time by placing the updated Policy on the Platform and the Application respectively.</div>
                            <div className={classes.list}><b>2.</b> By continuing to use the Service, the Platform and the Application, or continuing to communicate or engage with us, you signify your acceptance of such modifications, updates and amendments.</div>
                        </div>
                    <div class={classes.alphanumber}>I. CONTACT US</div>
                        <div className={classes.ol}>
                            <div className={classes.list}><b>1.</b> If you have any queries about this Policy or if you would like to exercise your rights under this Policy please contact our Data Protection Officer at:-</div>
                        </div>

                        <p>tanya.care Data Protection Officer</p>
                        <p>Address: Lot 7.01 B & C, Menara BRDB, 285 Jalan Maarof, Bukit Bandaraya, 59000 Kuala Lumpur Malaysia</p>
                        <p>Email Address: datapolicy@tanya.care</p>

                </div>
                
                {/* <Button onClick={() => this.props.history.goBack()}> TEST</Button> */}
                {/* <Grid container alignContent="center" alignItems="center">
                    <Grid item alignItems="center" alignContent="center">
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    id="sendCodeButton"
                    // disabled={loading}
                    onClick={() => this.props.history.goBack()}
                    className={classes.sendCodeButton}
                    style={{marginTop: 0, }}
                >
                    Go Back
                   
                </Button>
                </Grid>
                </Grid> */}
            </Grid> 
            
            
          </Grid>
                
          <Grid container xs={12} style={{ height: "5%" }}></Grid>
        </div>
      </Container>
      <Footer/>
      </>
    );
  }
}

PrivacyPolicy.contextType = AuthContext;

const mapStateToProps = (state) => ({
  loginState: state?.authState?.loginAuth,
  resetPassword: state?.authState?.resetPasswordFromEmail
});

export default connect(
  mapStateToProps,
  actions
)(withAlertAndDialog(withStyles(styles, { withTheme: true })(withRouter(PrivacyPolicy))));
