import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';
import { CustomAvatar } from "../../../../../../components/niceComponents/customAvatar";
// import PhoneIcon from "@material-ui/icons/Phone";
// import EmailIcon from "@material-ui/icons/Email";
import { useTheme } from '@material-ui/core/styles';
import { EmailIconV2, MobileIconV2 } from '../../../../../../assets';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#F0F6FE',
        borderRadius: 10,
        // boxShadow: '0px 5px 35px #0063E780',
        padding:theme.spacing(2.75, 3),
        flexWrap:'wrap'
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]:{
            '&:not(:last-child)':{
                marginBottom:theme.spacing(2)
            }
        }
    },
    robotoMedium: {
        fontSize: 18,
        color: '#324052',
        fontWeight: 500,
        wordBreak:'break-word'
    },
    robotoRegular: {
        fontSize: 14,
        color: '#363A57',
        fontWeight: 400,
        wordBreak:'break-word'
    },
    iconWrapper: {
        display: 'flex',
        marginRight:theme.spacing(1)
    },
    avatarWrapper:{
        flex: 1,
        marginRight: theme.spacing(2),
        '& .MuiAvatar-root':{
            margin:0
        }
    }
}))

export const ProfileCard = (props) => {

    const classes = useStyles(props);
    const theme = useTheme();

    const {
        profilePicture = "",
        name = "",
        genderAgeDetails = "",
        mobile = "",
        email = ""
    } = props;

    return (
        <div className={classes.root}>
            <div className={classes.flexRow}>
                <div className={classes.avatarWrapper}>
                    <CustomAvatar
                        src={profilePicture}
                        name={name}
                        size={56}
                    />
                </div>
                <div>
                    <Typography className={classes.robotoMedium} style={{marginBottom:8}}>{name ?? '-'}</Typography>
                    <Typography className={classes.robotoRegular} style={{ color: '#324052' }}>{genderAgeDetails ?? '-'}</Typography>
                </div>
            </div>
            <div className={classes.flexRow}>
                <div className={classes.iconWrapper}>
                    <MobileIconV2
                        htmlColor={theme.palette.primary.main}
                        align="center"
                    />
                </div>
                <Typography className={classes.robotoRegular}>{mobile ?? '-'}</Typography>
            </div>
            <div className={classes.flexRow}>
                <div className={classes.iconWrapper}>
                    <EmailIconV2
                        secondaryColor={"none"}
                        align="center"
                    />
                </div>
                <Typography className={classes.robotoRegular}>{email ?? "-"}</Typography>
            </div>
        </div>
    )
}

ProfileCard.propTypes = {
    profilePicture:PropTypes.string,
    name:PropTypes.string,
    genderAgeDetails:PropTypes.string,
    mobile:PropTypes.string,
    email:PropTypes.string
}