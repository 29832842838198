import React from 'react'
import { Grid, Typography } from '@material-ui/core'
// import { AddressIcon } from '../../../assets'
// import { Skeleton } from '@material-ui/lab'
import DuoIcon from '@material-ui/icons/Duo';

// const useStyles = makeStyles((theme) => ({
//   // root: (props) => ({
//   //   background: 'white',
//   //   padding: theme.spacing(props.padding) || theme.spacing(1.5),
//   // }),
//   icon: {
//     margin: theme.spacing(0, 1, 0, 0.4),
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'flex-start',
//     position: 'relative',
//     top: -1
//   }
// }))

export function MeetingComponent(props) {
  // const classes = useStyles()
  return (
    <Grid container alignItems="center"> {/*className={classes.root} */}
      <Grid item>
        {/* <div className={classes.icon} >
          <AddressIcon color={color ? color : ''} />
  </div> */}
            <DuoIcon htmlColor={"#bdbdbd"} style={{marginTop: 2, display: 'flex', alignItems: 'center'}} />
            {/* color={"secondary"} */}
      </Grid>
      <Grid item xs>
         <Typography variant="body1" style={{marginLeft:12, display: 'flex', alignItems: 'center',overflowWrap:"anywhere"}} >Video Call</Typography>
         {/* meetingLink ? meetingLink : '' */}
         {/* <Link
  component="button"
  variant="body2"
  style={{marginLeft:12, display: 'flex', alignItems: 'center'}}
  onClick={() => {
    window.open(`${meetingLink ? meetingLink : '/'}`, '_blank')
  }}
>
  Click to join meeting
</Link> */}
      </Grid>
    </Grid>
  )
}
