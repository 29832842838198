import { makeStyles } from "@material-ui/core"
import * as React from "react"

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.color || "#28a9c1"
  })
}))


export function MailNew(props) {
  const classes = useStyles()
  return (
    <svg width={20} height={16} viewBox="0 0 20 16" {...props}>
      <g id="prefix__Mail" transform="translate(-2 -4)">
        <path
          id="prefix__Path_589"
          d="M2 10.092V18a2 2 0 002 2h16a2 2 0 002-2v-7.87l-2 1.245V18H4v-6.654zm2-1.106V6h16v3.02l2-1.245V6a2 2 0 00-2-2H4a2 2 0 00-2 2v1.732z"
          className={classes.cls1}
          data-name="Path 589"
        />
        <path
          id="prefix__Path_590"
          d="M12 11.749L3 6.108v2.251L12 14l9-5.6V6.146z"
          className={classes.cls1}
          data-name="Path 590"
        />
      </g>
    </svg>
  )
}

