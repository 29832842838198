import React from "react";
import { Grid } from "@material-ui/core";
import EditCard from "./editCard";

class CareCoordinatorEdit extends React.Component {
  render() {
    return (
      <Grid style={{ padding: "25px" }}>
        {/* <CardList list={list} componentOrder={2} sizes={sizes} isonlycard={true} /> */}
        <EditCard />
        {/* <CardList list={list1} componentOrder={3} sizes={sizes} isonlycard={true} isbottom={true} /> */}
      </Grid>
    );
  }
}

export default CareCoordinatorEdit;
