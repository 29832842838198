import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: `0px 0.5px 2px 1px #e0e0e0d6`,
    padding: 16,
    backgroundColor: "white",
    height: 250,
    borderRadius: 8,
  }
}))

export const EquipmentError = (props) => {

  const classes = useStyles();

  return <div className={classes.root}>
    <Grid container direction="column" justify="center" alignItems="center" style={{ height: '100%' }}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Typography variant="h6" color="textSecondary" align="center">{"Something went wrong!"}</Typography>
        <Typography variant="body2" color="textSecondary" align="center">{"Unable to Fetch"}</Typography>
      </Grid>
    </Grid>
  </div>
}
